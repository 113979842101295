import React, { FC } from 'react';

import { AlertResponse, NotificationFor } from '@ariksa/notification/api';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { AlertsTableSubComponent } from 'containers/Findings/Alerts/Components/AlertsTable/AlertsTableSubComponent';
import { useAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useAlerts';
import { useIndividualAlertsTableColumns } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useIndividualAlertsTableColumns';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { SnoozeAlert } from 'containers/Findings/Alerts/Components/SnoozeAlert';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { RemediationModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationModal';

export const IndividualAlertsTable: FC = () => {
  const dispatch = useDispatch();
  const { alerts, filters, sortByField } = useSelector(selectAlerts);

  const {
    columns,
    handleRowClick,
    ticketModal,
    notificationModal,
    currentRecord,
    snooze,
    exceptionModal,
    remediate,
  } = useIndividualAlertsTableColumns();
  const { getAlerts } = useAlerts();

  return (
    <>
      <Table
        data={alerts.data}
        columns={columns}
        isLoading={alerts.isLoading}
        /*onRowClick={row => {
          handleRowClick(row, 0);
        }}*/
        pagination={{
          pageInfo: alerts.page.info,
          onChange: info => dispatch(actions.updateAlertsPagination(info)),
          totalCount: alerts.page.totalCount,
        }}
        sortBy={sortByField}
        onSort={sortInfo => {
          if (!isEqual(sortByField, sortInfo)) {
            dispatch(actions.updateSortInfo(sortInfo));
          }
        }}
        subComponent={({ row, expanded }) =>
          expanded && (
            <AlertsTableSubComponent row={row} isExpanded={expanded} />
          )
        }
        styles={{ header: { zIndex: 800 } }}
      />
      {snooze.isOpen && (
        <SnoozeAlert
          alert={currentRecord as AlertResponse}
          onClose={snooze.onClose}
          isOpen={snooze.isOpen}
          type={filters.alertType === 'Snoozed' ? 'unsnooze' : 'snooze'}
          onSuccess={() => getAlerts()}
        />
      )}
      {remediate.isOpen && (
        <RemediationModal
          alert={currentRecord as AlertResponse}
          state={remediate}
        />
      )}
      {exceptionModal.isOpen && (
        <AddException
          alert={currentRecord as AlertResponse}
          onClose={exceptionModal.onClose}
          isOpen={exceptionModal.isOpen}
          onSuccess={() => getAlerts()}
        />
      )}

      {ticketModal.isOpen && (
        <CreateTicket
          onClose={ticketModal.onClose}
          isOpen={ticketModal.isOpen}
          alert={currentRecord}
          onCreate={() => getAlerts()}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {notificationModal.isOpen && (
        <CreateNotification
          onClose={notificationModal.onClose}
          isOpen={notificationModal.isOpen}
          alert={currentRecord}
          onCreate={() => getAlerts()}
          notification_for={NotificationFor.Alerts}
        />
      )}
    </>
  );
};
