import React from 'react';

import { NativeResources } from '@ariksa/cloud-policy-engine';
import { components, OptionProps } from 'react-select';

import { AlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertStatus';

export const showDependencies = (resourceType, obj) => {
  return [
    NativeResources.Ec2Instance,
    NativeResources.ApiGatewayRestApi,
    NativeResources.SimpleStorageService,
    NativeResources.NetworkAcl,
    NativeResources.RelationalDatabaseService,
    NativeResources.ElasticKubernetesService,
    NativeResources.OrganizationUnit,
    NativeResources.AmazonMachineImage,
    NativeResources.KeyManagementService,
    NativeResources.IamGroup,
    NativeResources.IamRole,
    NativeResources.IamPolicy,
    NativeResources.RedshiftCluster,
  ].includes(resourceType)
    ? [obj]
    : [];
};

export const alertStatusColors = {
  todo: 'red',
  in_progress: 'blue',
  done: 'green',
};

export const AlertStatusOption = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <AlertStatus status={props.label} />
    </components.Option>
  );
};

export const AlertStatusValueContainer = props => {
  return (
    <components.ValueContainer {...props}>
      {props.getValue().map(v => (
        <AlertStatus status={v.label} />
      ))}
    </components.ValueContainer>
  );
};

export const alertStatusOptions = ['todo', 'in_progress'].map(v => ({
  label: v,
  value: v,
}));

export const formatRemediationCommandInput = (command: string) => {
  if (command.indexOf('--') === 0) {
    return command.slice(2);
  }

  return command;
};
