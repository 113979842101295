import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AttachedTo,
  FirewallIDs,
  InterfaceDeleteOnTermination,
  IPOwner,
  MACAddress,
  PrivateDNS,
  PrivateIP,
  PublicIP,
  SourceDestinationCheck,
  SubnetID,
} from '../../../Components/MetadataField/MetaGridFields';

export const NetworkInterfaceMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {SubnetID(resource)}
        {PublicIP(resource)}
        {IPOwner(resource)}
        {PrivateIP(resource)}
        {PrivateDNS(resource)}
        {AttachedTo(resource)}
        {MACAddress(resource)}
        {InterfaceDeleteOnTermination(resource)}
        {SourceDestinationCheck(resource)}
        {FirewallIDs(resource)}
      </Grid>
    </Stack>
  );
};
