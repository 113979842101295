import { ContextType } from '@ariksa/inventory-core';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { filter, isEmpty } from 'lodash';
import { Assets, Container, Sprite, Text } from 'pixi.js';

import { IconTypes } from 'components/Icons';
import { getBlackMapIcon } from 'components/Icons/MapIcons/getBlackMapIcon';
import { getColoredIcons } from 'components/Icons/MapIcons/getColoredIcons';
import { getPurpleMapIcon } from 'components/Icons/MapIcons/getPurpleMapIcon';
import { getWhiteMapIcon } from 'components/Icons/MapIcons/getWhiteMapIcon';
import { NodeType } from 'components/Visualization/PixiGraph/common/NodeType';
import {
  nodeInfo,
  nodeTitle,
} from 'components/Visualization/PixiGraph/common/utils/nodeInfo';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import {
  infoTextStyle,
  LIMIT_INNER_INFO_LEN,
  LIMIT_INNER_NAME_LEN,
  LIMIT_OUTER_INFO_LEN,
  nameTextStyle,
  SEVERITY_COLORS,
} from 'components/Visualization/PixiGraph/style';
import { isIdentity } from 'components/Visualization/PixiGraph/utils';
import { limitedString } from 'utils/string';

export class NodeElements {
  static iconRadius = 5;

  static renderVulnerabilitySeverities(
    node: AGraphNode,
    g: Graphics,
    container: Container,
  ) {
    const { data = {}, x, y, w, h } = node;

    const severities = [
      'critical_vulnerability',
      'high_vulnerability',
      'medium_vulnerability',
      'low_vulnerability',
    ];

    const visibleSeverities = severities.filter(s => data.properties[s]);
    const r = Math.sqrt(Math.pow(w / 2, 2) + Math.pow(h / 2, 2)) - 10;
    visibleSeverities.forEach((s, i) => {
      const color = s.split('_')[0]?.toUpperCase();
      g.beginFill(SEVERITY_COLORS[color], 1);
      const x1 = x + w / 2 + r * Math.sin((i * Math.PI) / 4);
      const y1 = y + h / 2 - r * Math.cos((i * Math.PI) / 4);
      g.drawCircle(x1, y1, NodeElements.iconRadius);
      g.endFill();
    });
  }

  static renderTrafficIcon(node: AGraphNode, g: Graphics) {
    const { x, y, w, h } = node;
    const { data = {} } = node;
    const { properties } = data;
    // if (!properties?.has_traffic) return;

    // render traffic icon
    g.lineStyle(1, 0xffffff);
    // g.beginFill(SEVERITY_COLORS[s], 1);
    // g.drawCircle(x, y -  30 ),
    //   NodeElements.iconRadius,
    // );
    g.endFill();
  }

  static renderSeverities(node: AGraphNode, g: Graphics) {
    const { x, y, w, h } = node;
    const isSquare = w === h;
    const hasRiskContext = !isEmpty(node?.data?.riskContext);

    // render severities
    const { severities = {} } = node.data ?? {};
    const severityArray = filter(severities, o => !!o);
    if (isEmpty(severities) || isEmpty(severityArray)) return;
    let cx = 6;
    if (severityArray?.length === 3) cx = 2;
    if (severityArray?.length === 4) cx = -4;

    const addX = isSquare ? 16 / severityArray?.length + cx : 5;

    Array.from(['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'])
      .filter(s => severities[s])
      .forEach((s, index) => {
        g.lineStyle(1, 0xffffff);
        g.beginFill(SEVERITY_COLORS[s], 1);
        g.drawCircle(
          x + index * 14 + addX,
          y - (isSquare && hasRiskContext ? 30 : 12),
          NodeElements.iconRadius,
        );
        g.endFill();
      });
  }

  static addInteractiveSeverityElement(
    graph: AGraph<AGraphNode, AGraphEdge>,
    node: AGraphNode,
    index: number,
  ) {
    const { x, y, w, h, data } = node;
    const isSquare = w === h;
    const hasRiskContext = !isEmpty(node?.data?.riskContext);
    // render severities
    const { severities = {} } = node.data ?? {};
    const severityArray = filter(severities, o => !!o);
    if (isEmpty(severities) || isEmpty(severityArray)) return;

    let cx = 6;
    if (severityArray?.length === 3) cx = 2;
    if (severityArray?.length === 4) cx = -4;

    const addX = isSquare ? 16 / severityArray?.length + cx : 5;

    Array.from(['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'])
      .filter(s => severities[s])
      .forEach((s, index) => {
        graph.addInteractiveElement({
          minX: x + addX + index * 14 - NodeElements.iconRadius,
          maxX: x + addX + index * 14 + NodeElements.iconRadius,
          minY:
            y -
            (isSquare && hasRiskContext ? 24 : 10) -
            NodeElements.iconRadius,
          maxY:
            y -
            (isSquare && hasRiskContext ? 24 : 10) +
            NodeElements.iconRadius,
          minZ: index,
          maxZ: index,
          id: `severity:icon:${s.toLowerCase()}:${node.id}`,
          data: data,
        });
      });
  }

  static addInteractiveVulnerabilitySeverityElement(
    graph: AGraph<AGraphNode, AGraphEdge>,
    node: AGraphNode,
    index: number,
  ) {
    const { data = {}, x, y, w, h } = node;

    const severities = [
      'critical_vulnerability',
      'high_vulnerability',
      'medium_vulnerability',
      'low_vulnerability',
    ];

    const visibleSeverities = severities.filter(s => data.properties[s]);
    const r = Math.sqrt(Math.pow(w / 2, 2) + Math.pow(h / 2, 2)) - 5;
    visibleSeverities.forEach((s, i) => {
      const cx = x + w / 2 + r * Math.sin((i * Math.PI) / 3);
      const cy = y + h / 2 - r * Math.cos((i * Math.PI) / 3);
      const text = String(data.properties[s] ?? '');
      graph.addInteractiveElement({
        minX: cx - NodeElements.iconRadius,
        maxX: cx + NodeElements.iconRadius,
        minY: cy - NodeElements.iconRadius,
        maxY: cy + NodeElements.iconRadius,
        minZ: index,
        maxZ: index,
        id: `context:icon:${node.id}:${s}`,
        data: {
          displayName: `${s}: ${text}`,
        },
      });
    });
  }

  static addInteractiveRiskContextElement(
    graph: AGraph<AGraphNode, AGraphEdge>,
    node: AGraphNode,
    index: number,
  ) {
    const { x, y, w, data } = node;
    const { riskContext } = data;
    if (isEmpty(riskContext)) return;

    const x1 = x + w - 20;
    const y1 = y;
    const w1 = 30;

    riskContext?.forEach((ctx, index) => {
      const cy = y1 - w1 / 2; //x + 35 - index * 14
      const cx = x1 - w1 / 2 - index * 20 + 26;
      graph.addInteractiveElement({
        minX: cx - NodeElements.iconRadius,
        maxX: cx + NodeElements.iconRadius,
        minY: cy - NodeElements.iconRadius,
        maxY: cy + NodeElements.iconRadius,
        minZ: index,
        maxZ: index,
        id: `context:icon:${node.id}:${ctx.type}`,
        data: {
          node,
          ...ctx,
          displayName: ctx.type,
        },
      });
    });
  }

  static renderIcon(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
    color: 'default' | 'white' | 'black' | 'purple' = 'default',
  ) {
    const { x, y, w, h, data } = node;
    const isInternet = NodeType.isInternet(node);
    const isSeverity = NodeType.isSeverity(node);
    const isCredential = NodeType.isCredentialWithCount(node);
    const isOs = NodeType.isOs(node);
    const isTechnology = NodeType.isTech(node);
    const isEndpoint = NodeType.isEndpoint(node);
    const isAccount = data?.header === 'Account';
    const isPostgres =
      data?.name?.toLowerCase() === 'postgres' ||
      data?.name?.toLowerCase() === 'postgresql';
    const isRegion = data?.native_name === 'Region';
    const isProcess = NodeType.isProcess(node);

    let iconPath = '';
    let iconKey = data.native_name;

    if (data?.agnostic_name?.toLowerCase() === 'Credential') {
      iconKey = 'LeakedCredential';
    } else if (isProcess) {
      if (data?.name?.toLowerCase() === 'openai') iconKey = IconTypes.OpenAI;
      else iconPath = IconTypes.Process;
      color = 'purple';
    } else if (isRegion) {
      iconPath = 'Region';
      color = 'black';
    } else if (isPostgres) {
      iconKey = IconTypes.PostgreSql;
      color = 'black';
    } else if (isEndpoint) {
      iconKey = IconTypes.Api;
      color = 'white';
    } else if (isOs || isTechnology) {
      iconKey = data.name;
      color = 'default';
    } else if (isAccount) {
      color = 'default';
    }

    // console.log(node.data.native_name, iconKey);

    if (color === 'black' || color === 'default') {
      iconPath = getBlackMapIcon(iconKey);
    } else if (color === 'white') {
      iconPath = getWhiteMapIcon(iconKey);
    } else if (color === 'purple') {
      iconPath = getPurpleMapIcon(iconKey);
    }

    if (NodeType.isContext(node)) {
      iconPath = getWhiteMapIcon(data.type);
    }

    if (isSeverity || isCredential) {
      iconKey = '';
      iconPath = '';
      color = 'white';
    }

    const isResource = w === h;
    Assets.loader.load(iconPath).then(texture => {
      if (!texture) return;
      const icon = Sprite.from(texture);

      let scale = isSeverity
        ? 0.18
        : ((isInternet ? 0.95 : isResource ? 0.5 : 0.4) * h) / icon.width;
      if (isOs) {
        scale = 0.38;
      }

      if (isEndpoint) {
        scale = 0.13;
      } else if (isRegion) {
        scale = 0.12;
      }

      scale *= 1.2;

      icon.scale.set(scale, scale);
      icon.x = x + (isInternet ? -3.9 : isResource ? (w - icon.width) / 2 : 13);
      icon.y =
        y + (isInternet ? -2.9 : isResource ? (h - icon.height) / 2 : 14);

      container.addChild(icon);
    });
  }

  static renderTitleInside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
  ) {
    const { x, y, data } = node;
    const title = nodeTitle(node);

    const name = new Text(
      limitedString(title, LIMIT_INNER_NAME_LEN),
      nameTextStyle,
    );
    name.x = x + 40;
    if (isIdentity(data.native_name)) {
      name.y = y + 12;
    } else {
      name.y = y + 4;
    }
    container.addChild(name);
  }

  static renderInfoInside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
  ) {
    const { x, y } = node;

    const resourceId = new Text(
      limitedString(nodeInfo(node), LIMIT_INNER_INFO_LEN),
      infoTextStyle,
    );
    resourceId.x = x + 40;
    resourceId.y = y + 22;
    container.addChild(resourceId);
  }

  static renderCountInside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
  ) {
    const { x, y, w, h, data } = node;
    const { count } = data.properties ?? {};
    const countText = new Text(count, {
      ...nameTextStyle,
      fill: 0xffffff,
    });
    countText.x = x + w / 2 - countText.width / 2;
    countText.y = y + h / 2 - countText.height / 2;
    container.addChild(countText);
  }

  static renderTextInside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
    opts: any = {},
  ) {
    const { data } = node;
    const { name: text } = data;
    const { x = 0, y = 0 } = opts;
    const name = new Text(
      limitedString(text, LIMIT_INNER_NAME_LEN),
      nameTextStyle,
    );
    name.x = x;
    name.y = y;
    container.addChild(name);
  }

  static renderTitleOutside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
  ) {
    const { x, y, h, w, data } = node;

    let title = data.name?.trim();
    if (!title) {
      title = data.resource_id;
    }
    const name = new Text(
      limitedString(title ?? '', LIMIT_OUTER_INFO_LEN),
      nameTextStyle,
    );
    name.x = x - name.width / 2 + w / 2;
    name.y = y + h + 4;
    container.addChild(name);
    if (NodeType.isSeverity(node)) {
      // console.debug('xxxxxxx', node.data.identity, node.data.name);
    }
  }

  static renderInfoOutside(
    node: AGraphNode,
    _g: Graphics,
    container: Container,
  ) {
    if (!node.showResourceType) return;

    const { x, y, h, w } = node;
    const regionOrId = new Text(
      limitedString(nodeInfo(node), LIMIT_OUTER_INFO_LEN),
      infoTextStyle,
    );
    //console.log(node.info)
    regionOrId.x = x - regionOrId.width / 2 + w / 2;
    regionOrId.y = y + h + 22;
    container.addChild(regionOrId);
  }

  static renderContext(node: AGraphNode, g: Graphics, container: Container) {
    const { x, y, w, h, data = {} } = node;
    const { riskContext } = data ?? {};
    if (isEmpty(riskContext)) return;
    const x1 = x + w;
    const y1 = y;
    const h1 = 30;
    const w1 = 30;
    const isSquare = w === h;

    riskContext?.forEach((ctx, i) => {
      const cx =
        x1 - w1 / 2 - i * 20 - (isSquare ? 0 : NodeElements.iconRadius);
      const cy = y1 - h1 / 2 - NodeElements.iconRadius - 6;
      // render vulnerability icons
      let iconPath = getColoredIcons(ctx.type);

      if (iconPath) {
        Assets.loader.load(iconPath).then(texture => {
          if (!texture) return;
          const icon = Sprite.from(texture);

          const scale = (NodeElements.iconRadius + 10) / icon.width;
          icon.scale.set(scale, scale);
          icon.x = cx - NodeElements.iconRadius + 10;
          icon.y = cy - NodeElements.iconRadius + 12;
          container.addChild(icon);
        });
      }

      // g.lineStyle(1, 0xffffff);
      // g.beginFill(SEVERITY_COLORS[ctx.severity], 1);
      //g.drawCircle(cx + 2, cy + 2, 8);
      // g.drawRoundedRect(cx + 2, cy + 4, 18, 18, 3);
      g.endFill();
    });
  }

  static renderTraffic(node: AGraphNode, g: Graphics, container: Container) {
    const { x, y, w, h, data = {} } = node;

    const x1 = x + w;
    const y1 = y;
    const h1 = 30;
    const w1 = 30;
    const isSquare = w === h;

    // UnencryptedData
    let iconPath = getBlackMapIcon(ContextType.UnencryptedData);
    const cx = x1 - w / 2 - (isSquare ? 0 : NodeElements.iconRadius);
    const cy = y1 - h1 / 2 - NodeElements.iconRadius - 6;
    if (iconPath) {
      Assets.loader.load(iconPath).then(texture => {
        if (!texture) return;
        const icon = Sprite.from(texture);

        const scale = (NodeElements.iconRadius + 7) / icon.width;
        icon.scale.set(scale, scale);
        icon.x = cx - NodeElements.iconRadius + 10;
        icon.y = cy - NodeElements.iconRadius + 12;
        container.addChild(icon);
      });
    }
  }
}
