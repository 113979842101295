import React, { FC, useRef } from 'react';

import { AggregatedAlertDetails } from '@ariksa/notification/api';
import { Box } from '@chakra-ui/react';

import { Drawer } from 'components/Overlay';
import { AggregatedAlertDrawerHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AggregatedAlertDrawerHeader';
import { AlertDrawerContent } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AlertDrawerContent';
import { alertDrawerSaga } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/saga';
import {
  reducer,
  sliceKey,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/slice';
import { useInjector } from 'utils/inject';

interface IAggregatedAlertDrawer {
  alert: AggregatedAlertDetails;
  isOpen: boolean;

  onClose();

  tabIndex: number;
  setTabIndex: (value: number) => void;
}

export const AggregatedAlertDrawer: FC<IAggregatedAlertDrawer> = props => {
  useInjector(sliceKey, reducer, alertDrawerSaga);
  const { alert, isOpen, onClose, tabIndex, setTabIndex } = props;

  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={<AggregatedAlertDrawerHeader alert={alert} />}
      body={
        <Box h="full" w="full" pt={3}>
          <AlertDrawerContent
            alert={alert}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1440px' },
        drawer: {
          portalProps: {
            containerRef: ref as any,
          },
        },
      }}
    />
  );
};
