import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  cloneDeep,
  isEmpty,
  isNull,
  isObject,
  isString,
  isUndefined,
} from 'lodash';
import LRUCache from 'lru-cache';
import { call, put } from 'redux-saga/effects';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { errorToast, infoToast, successToast } from 'components/Toast';

import { isDevEnv } from '../../../utils/env';
import {
  CallFn,
  ErrFn,
  MapFn,
  QueryError,
  QueryParams,
  QueryStatus,
  ResponseFn,
  SuccessFn,
} from '../types';

export type Fetcher<P> = () => Promise<P>;

export const isStatusUnknown = (status?: QueryStatus) => !status;

export interface FetchOptions<P> {
  map?: MapFn<any, P>;
  errorMsg?: string;
  successMsg?: string;
  cacheKey?: string;
  // cache time in ms
  cacheTime?: number;
}

const CacheTime = 0.1 * 60 * 60 * 1000; // 5min

// QueryContext wraps a dispatched action and calls it on success/failure of the fetcher
// P => expected api payload
// Q => data required to do the api call
export class QueryContext<P, Q = any> {
  action: ActionCreatorWithPayload<QueryParams<P, Q>>;

  state: QueryParams<P, Q>;

  private sync: boolean;

  private callFn: CallFn[];

  private successFn: SuccessFn<P>[];

  private errorFn: ErrFn[];

  private responseFn: ResponseFn<P>[];

  // static cache = new Cacheables({
  //   logTiming: true,
  //   log: true,
  //   enabled: true,
  // });
  static cache = new LRUCache({
    max: 500,

    // for use with tracking overall storage size
    maxSize: 5000,
    sizeCalculation: (value, key) => {
      return 1;
    },

    ttl: CacheTime,

    allowStale: false,

    updateAgeOnGet: false,
    updateAgeOnHas: false,
  });

  static hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  get params() {
    const params = cloneDeep(this.state.q ?? '');
    if (isObject(params)) {
      Object.keys(params).forEach(key => {
        const value = isNull(params[key]);
        if (isNull(value) || isUndefined(value)) {
          delete params[key];
        }
      });
    }

    return params;
  }

  get page() {
    return this.state.page ?? INIT_PAGE_INFO;
  }

  get isRunning() {
    return this.status !== QueryStatus.uninitialized;
  }

  get status() {
    return this.state.status;
  }

  get data() {
    return this.state.data;
  }

  get error() {
    return this.state.error;
  }

  constructor(
    action: ActionCreatorWithPayload<QueryParams<P, Q>>,
    state: QueryParams<P, Q>,
  ) {
    this.sync = true;

    this.action = action;
    this.state = {
      ...state,
      status: QueryStatus.uninitialized,
    };

    this.callFn = state.onCall ? [state.onCall] : [];
    this.successFn = state.onSuccess ? [state.onSuccess] : [];
    this.errorFn = state.onError ? [state.onError] : [];
    this.responseFn = state.onResponse ? [state.onResponse] : [];

    this.fetch = this.fetch.bind(this);
    this.updateSlice = this.updateSlice.bind(this);
  }

  *updateSlice() {
    const status = this.error ? QueryStatus.failed : QueryStatus.fulfilled;
    this.updateState({ status } as any);
    yield put(this.action(this.state));
  }

  updateState(state: Partial<QueryParams<P, Q>>) {
    this.state = { ...this.state, ...state };
  }

  *fetch(fetcher: Fetcher<any>, opts?: FetchOptions<P>) {
    const ctx = this;
    let {
      map: mapResponse = r => r.data,
      cacheTime, // 5 sec
      cacheKey = '',
      errorMsg = '',
      successMsg = '',
    } = opts ?? {};

    let data = null;
    try {
      this.updateState({ status: QueryStatus.pending } as any);

      // dispatch action with pending status
      yield put(ctx.action(ctx.state));
      ctx.callFn.forEach(r => r());

      let fromCache = false;
      // try to cache the api call response
      const cachedFetcher = () => {
        if (cacheKey) {
          // avoid calling empty request by aggressively caching empty request
          let isEmptyRequest = isEmpty(ctx.params);
          if (isEmptyRequest) {
            cacheKey = 'empty::' + cacheKey;
          }

          const key = cacheKey + '::' + JSON.stringify([ctx.params, ctx.page]);
          fromCache = QueryContext.cache.has(key);
          const cacheData = QueryContext.cache.get(key);
          if (fromCache) {
            console.info('hit in cache', key, cacheData);
          }
          if (fromCache && cacheData) {
            return QueryContext.cache.get(key);
          }

          return fetcher().then(res => {
            QueryContext.cache.set(key, res, { ttl: CacheTime });
            return res;
          });
        } else {
          return fetcher();
        }
      };

      // do the actual api call
      const payload = yield call(cachedFetcher);
      data = mapResponse(payload);
      this.updateState({ data, fromCache } as any);
      this.emitSuccess();

      successMsg && successToast({ title: successMsg });
    } catch (err: any) {
      this.updateState({ status: QueryStatus.failed } as any);

      ctx.state.error = this.intoQueryError(err, errorMsg);

      // state error must exist as set just above
      ctx.errorFn.forEach(r => r(ctx.state.error!));

      // by default always show error on the screen
      if (ctx.errorFn.length === 0) {
        if (ctx.state.error.code === 409) {
          infoToast({
            title: errorMsg || ctx.state.error?.title,
            description: ctx.state.error.description,
          });
        } else {
          errorToast({
            title: errorMsg || ctx.state.error?.title,
            description: ctx.state.error?.description,
          });
        }
      }
      console.log(err);
    }

    ctx.responseFn.forEach(r => r(ctx.state.data, ctx.state.error));

    if (ctx.sync) {
      // dispatch action with failed or fulfilled status
      yield call(ctx.updateSlice);
    }

    return data;
  }

  intoQueryError(err, msg): QueryError {
    const { errMsg } = this.state;
    let title = errMsg ?? msg ?? err.toString();
    let description = isString(err.response?.data)
      ? err.response?.data
      : err.message ?? err.response?.data?.detail?.toString();

    console.log(err);
    console.dir(err);

    return {
      title,
      description,
      code: err.response?.status,
      error: err,
    };
  }

  showOnError(error: QueryError) {
    this.onError(() => {
      errorToast({
        title: error.title,
        description: error.description ?? '',
      });
    });
  }

  private emitSuccess() {
    this.successFn.forEach(r => r(this.state.data));
  }

  noAutoSync() {
    this.sync = false;
  }

  onCall(fn: CallFn) {
    this.callFn.push(fn);
  }

  onSuccess(fn: SuccessFn<P>) {
    this.successFn.push(fn);
  }

  onError(fn: ErrFn) {
    this.errorFn.push(fn);
  }

  onResponse(fn: ResponseFn<P>) {
    this.responseFn.push(fn);
  }
}
