/* --- STATE --- */

import { WorkflowRead } from '@ariksa/awm/api';
import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import {
  AlertWorkflowResponse,
  ClientResponse,
} from '@ariksa/notification/api';
import { IQueryState } from 'services/utils/QueryState';

import {
  IdentityProvider,
  IdentityProviderNameConfig,
  IdentityProviderServiceCreateRequest,
  IdentityProviderServiceDeleteRequest,
} from 'api/auth/api.pb';
import { ApiCallState, ApiCallStatus } from 'api/types';

export interface IRepositoryAccountInfo {
  id: string;
  username: string;
  totalRepos: number;
}
export type IRepositoryAccount = Pick<
  IRepositoryAccountInfo,
  'id' | 'username'
>;

export interface IRepositoryData {
  name: string;
  id: string;
}

export interface IBranchData {
  name: string;
}

export interface IRepositoryInfo {
  id: string;
  editing: boolean;
  deleted: boolean;
  account: IRepositoryAccount;
  repository: IRepositoryData;
  branch: IBranchData;
  policy: IRepositoryData;
}

export interface IRepository {
  id: string;
  name: string;
  branches: ApiCallStatus & {
    data: IBranchData[];
  };
}

/*export interface IProject {
  id: string;
  name: string;
  repos: ApiCallStatus & {
    data: IRepository[];
  };
}*/

export interface IDevToolIntegrationProps {
  authId: string;
  account: ApiCallStatus & {
    data: IRepositoryAccountInfo;
  };
  repos: ApiCallStatus & {
    data: IRepository[];
  };
  selectedRepos: IRepositoryInfo[];
}

export interface DeleteProviderProps
  extends IdentityProviderServiceDeleteRequest {
  onSuccess: () => void;
}

export interface CreateProviderProps
  extends IdentityProviderServiceCreateRequest {
  onRedirect: () => void;
}

export interface SSOProviderForm {
  createProvider: ApiCallStatus;
  formData: {
    providerConfig: IdentityProviderNameConfig;
  };
}

export interface SSO {
  providers: ApiCallState<IdentityProvider[]>;
  providerNames: ApiCallState<IdentityProviderNameConfig[]>;
  providerForm: SSOProviderForm;
  deleteProvider: ApiCallStatus;
}

export interface Account {
  data: CloudAccountGetResponse;
  snapshotStatus: WorkflowRead;
}

export interface IntegrationsState {
  github: IDevToolIntegrationProps;
  gitlab: IDevToolIntegrationProps;
  bitbucket: IDevToolIntegrationProps;

  activeClient: IQueryState<ClientResponse>;
  client: IQueryState<Record<string, any>>;
  clients: ApiCallStatus & {
    //needed in alert workflow
    slack: Record<string, any>[];
    teams: Record<string, any>[];
    servicenow: Record<string, any>[];
    jira: Record<string, any>[];
    email: Record<string, any>[];
    sns: Record<string, any>[];
    elasticsearch: Record<string, any>[];
  };
  client_name: string;
  currentTab: string;
  clientInfo: IQueryState<Record<string, any>>;
  workflows: IQueryState<AlertWorkflowResponse[]>;

  rediscoverAccount: IQueryState<any>;
  account: IQueryState<Record<string, any>>;

  codeRepositories: IQueryState<any>;

  sso: SSO;
}

export const DUMMY_API_KEY = '******************';

export type ContainerState = IntegrationsState;
