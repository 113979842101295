import React, { FC, useEffect } from 'react';

import { Box, FormControl, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { CustomFieldWithFormControl, FieldStyles, useStyles } from '../index';
import { FieldProps } from '../types';

import { FormLabel } from './FormLabel';

export const CustomField: FC<FieldProps<CustomFieldWithFormControl>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    isRequired,
    helpIcon,
    isVisible = true,
    error,
    fieldStyles,
    value = null,
    tooltip,
    helpTooltip,
    styles,
    onClickHelpIcon,
  } = field;

  const baseFieldStyles = useStyles<FieldStyles>('customField', {
    ...fieldStyles,
    ...styles,
  });

  const { setValue, register } = useFormContext();

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value, name, setValue]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isInvalid={!!error}
      {...baseFieldStyles?.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={baseFieldStyles?.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Box key={`${name}-container`} className={name} ref={register}>
        {field.component}
        {!error && tooltip && (
          <Text color="gray.200" mt="1" fontSize="12">
            {tooltip}
          </Text>
        )}
        {!!error && (
          <Text color="red.400" mt="1" fontSize="12">
            {error}
          </Text>
        )}
      </Box>
    </FormControl>
  ) : null;
};
