import React, { FC, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarLayout } from 'components/Layout';
import { EmptySecurityGraph } from 'containers/Visibility/SecurityGraph/Components/EmptySecurityGraph';
import { Evidence } from 'containers/Visibility/SecurityGraphNext/Components/Evidence';
import { useEvidence } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/hooks/useEvidence';
import { Investigate } from 'containers/Visibility/SecurityGraphNext/Components/Investigate';
import { SecurityGraphContent } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphContent';
import { SecurityGraphSidebar } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphSidebar';
import { getIsRedirected } from 'containers/Visibility/SecurityGraphNext/hooks/useCollectRedirectInfo';
import { useGraphComponents } from 'containers/Visibility/SecurityGraphNext/hooks/useGraphComponents';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSearchTerm } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchTerm';
import { useTableComponents } from 'containers/Visibility/SecurityGraphNext/hooks/useTableComponents';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { ViewType } from 'containers/Visibility/SecurityGraphNext/types';
import { useSearchParams } from 'hooks/useSearchParams';

interface ISecurityGraphLayout {}

export const SecurityGraphLayout: FC<ISecurityGraphLayout> = props => {
  const { viewType, evidence } = useSelector(selectSecurityGraphNext);
  const { alertDetails } = evidence;
  const { activeSearchQueryTerm } = useSearchTerm();
  const { graphComponent, sidebarComponent } = useGraphComponents();
  const { searchResult } = useSearchResult();
  const { tableComponent } = useTableComponents();
  const { isRedirected } = useRedirectInfo();
  const { getAlertDetails } = useEvidence();
  const params = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getAlertDetails();
  }, [getAlertDetails]);

  return (
    <Box h={'full'}>
      {((!activeSearchQueryTerm &&
        !isRedirected &&
        !params.alert_id &&
        !params.investigate) ||
        (!!params?.alert_id && alertDetails.isLoading)) && (
        <SidebarLayout
          sidebar={<Box />}
          content={
            <SecurityGraphContent>
              <EmptySecurityGraph
                isLoading={searchResult.isLoading || alertDetails.isLoading}
              />
            </SecurityGraphContent>
          }
        />
      )}

      {(activeSearchQueryTerm || isRedirected) &&
        !params.alert_id &&
        !params.investigate && (
          <SidebarLayout
            sidebar={
              <SecurityGraphSidebar>{sidebarComponent}</SecurityGraphSidebar>
            }
            content={
              <SecurityGraphContent>
                {viewType === ViewType.Map ? graphComponent : tableComponent}
              </SecurityGraphContent>
            }
          />
        )}

      {!!params.alert_id && !params.investigate && !alertDetails.isLoading && (
        <Evidence />
      )}

      {!!params.investigate && <Investigate />}
    </Box>
  );
};
