import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { routeConfig } from './routeConfig';

export const useRouteConfig = (type?: string) => {
  const [data, setData] = useState(undefined);
  let { pathname: path = '' } = useLocation();

  if (type && type === 'navBar') {
    path = `/${path.split('/')[1]}`;
  }

  useEffect(() => {
    let config = routeConfig[path];
    if (!config) {
      config = routeConfig['/'];
    }

    if (type) {
      setData(config[type]);
    } else {
      setData(config);
    }
  }, [type, path]);

  return data;
};
