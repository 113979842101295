import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIconColor } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { GlobeIcon, IconTypes } from 'components/Icons';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import {
  AllEntitiesDetailsProps,
  SearchQueryMapping,
} from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { CountCards } from 'containers/Dashboard/utils/CountCards';

export const TopPubliclyAccessibleMachinesAndDataSources: React.FC = () => {
  const { publiclyAccessibleSources } = useSelector(selectCloudPostureOverview);
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <CountCards
        isLoading={publiclyAccessibleSources.isLoading}
        label="Top publicly accessible entities"
        icon={<GlobeIcon />}
        iconBgColor="critical"
        items={map(
          orderBy(
            publiclyAccessibleSources.data,
            ['resource_count'],
            ['desc'],
          )?.slice(0, 4),
          o => ({
            label: o.agnostic_name!,
            iconType: o.agnostic_class!,
            count: formatNumber(o?.resource_count),
            iconColor: getIconColor(o.agnostic_class!),
            borderColor: getIconColor(o.agnostic_class!),
            onClick: () => {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.PubliclyAccessibleEntities,
                iconType: o.agnostic_class!,
                resourceType: o.agnostic_class,
                resourceTypeClass: 'agnostic',
                total: o?.resource_count ?? 0,
              });
              /*navigate(
              `/visibility/security-graph?query=${getSearchQuery(
                SearchQueryMapping.Show_resource_type_with_internet_accessible,
                [o.agnostic_class!],
              )}`,
            );*/
            },
          }),
        )}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
