import { CloudProviders } from '@ariksa/cloud-account/api';
import { ComplianceStandards } from '@ariksa/compliance-policies/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Clients } from '@ariksa/notification';
import { ApplicationEnum } from '@ariksa/scan-analysis';

import { CustomIconTypes, IconType, IconTypes } from 'components/Icons/types';

export const isIconFilled = (type: IconType) => {
  let isFilled = true;
  switch (type) {
    case IconTypes.Okta:
    case IconTypes.Snowflake:
    case NativeResources.SnowflakeSchemas:
    case NativeResources.SnowflakePrivileges:
    case NativeResources.OktaDomain:
    case NativeResources.OktaPolicy:
    case NativeResources.OktaIdentityProvider:
    case NativeResources.OktaRoleSubscription:
    case NativeResources.AzureAdDirectoryRoles:
    case NativeResources.AzureAdsamlRole:
    case NativeResources.AzureAdDomain:
    case CustomIconTypes.Internet:
    case CustomIconTypes.Sagemaker:
    case ComplianceStandards.CisV8:
    case ComplianceStandards.Soc2:
    case ComplianceStandards.PciDssV321:
    case ComplianceStandards.Iso27001:
    case IconTypes.AmazonLinux:
    case ApplicationEnum.MongoDb:
    case ApplicationEnum.Memcached:
    case ApplicationEnum.ApacheHttp:
    case ApplicationEnum.Cassandra:
    case ApplicationEnum.CouchDb:
    case ApplicationEnum.HaProxy:
    case ApplicationEnum.PostgreSql:
    case ApplicationEnum.RabbitMq:
    case ApplicationEnum.Redis:
    case IconTypes.Ubuntu:
    case CloudProviders.Gitlab:
    case CloudProviders.Github:
    case CloudProviders.Bitbucket:
    case NativeResources.ElasticSearch:
    case Clients.Elasticsearch:
      //case IconTypes.Debian:
      isFilled = false;
  }
  return isFilled;
};
