import React from 'react';

import { Icon } from '@chakra-ui/react';

export const APIGatewayApiIcon = props => (
  <Icon viewBox="0 0 32 32" w="full" h="full" color="inherit" {...props}>
    <path
      id="api_2_"
      d="M12.44,15.996c-0.42,0-0.721-0.112-0.903-0.336s-0.296-0.504-0.343-0.84h-0.07
	c-0.159,0.448-0.42,0.784-0.784,1.008s-0.798,0.336-1.302,0.336c-0.766,0-1.36-0.196-1.785-0.588s-0.637-0.924-0.637-1.596
	c0-0.681,0.25-1.204,0.749-1.568c0.499-0.364,1.276-0.546,2.331-0.546h1.428v-0.714c0-0.513-0.14-0.905-0.42-1.176
	C10.424,9.705,9.995,9.57,9.416,9.57c-0.439,0-0.805,0.098-1.099,0.294s-0.539,0.457-0.735,0.784l-0.672-0.63
	c0.196-0.392,0.508-0.726,0.938-1.001c0.429-0.275,0.97-0.413,1.624-0.413c0.877,0,1.559,0.214,2.044,0.643s0.728,1.024,0.728,1.788
	v3.967h0.826v0.994C13.07,15.996,12.44,15.996,12.44,15.996z M9.22,15.211c0.28,0,0.537-0.032,0.77-0.098
	c0.233-0.065,0.434-0.159,0.602-0.28c0.168-0.121,0.299-0.261,0.392-0.42s0.14-0.331,0.14-0.518v-1.19H9.64
	c-0.644,0-1.113,0.093-1.407,0.28c-0.294,0.187-0.441,0.457-0.441,0.812v0.294c0,0.354,0.128,0.63,0.385,0.826
	C8.434,15.114,8.781,15.211,9.22,15.211z M14.666,8.771h1.12v1.163h0.056c0.187-0.453,0.459-0.788,0.819-1.005
	c0.359-0.217,0.791-0.326,1.295-0.326c0.448,0,0.854,0.089,1.219,0.266c0.363,0.177,0.674,0.429,0.931,0.756s0.452,0.724,0.588,1.19
	c0.135,0.467,0.203,0.99,0.203,1.568s-0.068,1.101-0.203,1.568c-0.136,0.467-0.331,0.863-0.588,1.19s-0.567,0.579-0.931,0.756
	s-0.771,0.266-1.219,0.266c-0.98,0-1.685-0.443-2.114-1.331h-0.056v3.962h-1.12C14.666,18.794,14.666,8.771,14.666,8.771z
	 M17.648,15.169c0.635,0,1.134-0.199,1.498-0.598s0.546-0.921,0.546-1.569v-1.238c0-0.647-0.182-1.17-0.546-1.569
	s-0.863-0.598-1.498-0.598c-0.252,0-0.492,0.035-0.721,0.105c-0.229,0.072-0.427,0.168-0.595,0.29s-0.301,0.27-0.399,0.443
	c-0.098,0.174-0.147,0.359-0.147,0.556v2.702c0,0.234,0.049,0.443,0.147,0.626c0.098,0.183,0.231,0.338,0.399,0.464
	s0.366,0.223,0.595,0.289C17.156,15.137,17.396,15.169,17.648,15.169z M23.346,7.078c-0.242,0-0.418-0.058-0.524-0.175
	c-0.107-0.117-0.161-0.269-0.161-0.455V6.266c0-0.187,0.054-0.338,0.161-0.455c0.106-0.117,0.282-0.175,0.524-0.175
	c0.243,0,0.418,0.058,0.525,0.175s0.161,0.269,0.161,0.455v0.182c0,0.187-0.054,0.338-0.161,0.455S23.589,7.078,23.346,7.078z
	 M22.786,8.771h1.12v7.224h-1.12V8.771z M31,31.36H1v-0.72h30V31.36z M31,24.36H1c-0.199,0-0.36-0.161-0.36-0.36V1
	c0-0.199,0.161-0.36,0.36-0.36h30c0.199,0,0.36,0.161,0.36,0.36v23C31.36,24.199,31.199,24.36,31,24.36z M1.36,23.64h29.28V1.36
	H1.36V23.64z"
    />
  </Icon>
);
