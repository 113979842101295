import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';

import { VMIdentity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/VMIdentity';
import { SnowflakeDatabaseIdentity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/Snowflake/SnowflakeDatabaseIdentity';

export const getIdentityComponent = resourceType => {
  switch (resourceType) {
    case NativeResources.SnowflakeDatabases:
    case NativeResources.SnowflakeTables:
      return <SnowflakeDatabaseIdentity />;
    default:
      return <VMIdentity />;
  }
};
