import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { AriksaLogoIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoIcon';
import { NetconDarkLogo } from 'components/Icons/ReactCustomIcons/NetconLogoDark';
import { selectApp } from 'containers/App/selectors';

export const CompanyLogoDarkIcon = props => {
  const location = useLocation();
  const { organization } = useSelector(selectApp);

  return (
    <>
      {location.host?.startsWith('netcon') ||
      organization.toLowerCase() === 'netcon' ? (
        <NetconDarkLogo />
      ) : (
        <AriksaLogoIcon />
      )}
    </>
  );
};
