import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ContextLabelIcon = props => (
  <Icon viewBox="6 6 24 24" color="inherit" h="full" w="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="586" y="437" width="35" height="35" />
      </clipPath>
      <clipPath id="clip1">
        <path
          d="M587.078 453.589 605.463 438.162 620.89 456.547 602.505 471.974Z"
          fillRule="nonzero"
          clipRule="nonzero"
        />
      </clipPath>
      <clipPath id="clip2">
        <path
          d="M587.078 453.589 605.463 438.162 620.89 456.547 602.505 471.974Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </clipPath>
      <clipPath id="clip3">
        <path
          d="M587.078 453.589 605.463 438.162 620.89 456.547 602.505 471.974Z"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-586 -437)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M605.899 453.461C606.431 454.096 607.378 454.178 608.012 453.646 608.646 453.113 608.729 452.168 608.197 451.533 607.665 450.898 606.718 450.815 606.084 451.348 605.449 451.88 605.366 452.826 605.899 453.461ZM606.727 452.114C606.938 451.937 607.253 451.964 607.431 452.175 607.608 452.387 607.581 452.702 607.369 452.88 607.158 453.057 606.842 453.03 606.665 452.818 606.487 452.607 606.515 452.291 606.727 452.114ZM611.305 448.463 603.556 447.785C603.424 447.774 603.293 447.815 603.191 447.901L594.765 454.971C593.497 456.037 593.332 457.928 594.395 459.198L598.252 463.794C599.318 465.061 601.208 465.227 602.478 464.164L610.905 457.093C611.006 457.008 611.07 456.886 611.081 456.754L611.759 449.005C611.759 449.005 611.759 449.005 611.759 449.005 611.783 448.73 611.58 448.487 611.305 448.463ZM610.103 456.46 601.835 463.398C600.989 464.106 599.728 463.996 599.018 463.151L595.161 458.555C594.452 457.708 594.563 456.448 595.408 455.737L603.675 448.8 610.72 449.416 610.103 456.46Z"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
