import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';

import { ECSContainers } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/ECS/ECSContainers';
import { ContainerVulnerability } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/ContainerVulnerability/ContainerVulnerability';

export const getContainers = resourceType => {
  switch (resourceType) {
    case NativeResources.Ec2Instance:
      return <ContainerVulnerability />;
    case NativeResources.ElasticContainerService:
    case NativeResources.EcsCluster:
    case NativeResources.ElasticKubernetesService:
      return <ECSContainers />;
    default:
      return null;
  }
};
