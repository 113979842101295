import React, { FC, useCallback, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box } from '@chakra-ui/react';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';

import { renderSeverityBar, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { DataTypesBySourceSubComponent } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DataTypesBySourceSubComponent';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { renderDataTypeLabels } from 'containers/Visibility/Data/Components/hooks/utils';

interface Props {
  type: 'Data' | 'Document';
}

export const DataTypesBySource: FC<Props> = props => {
  const { type } = props;
  const dispatch = useDispatch();
  const { resourceUuid, data, resourceType } = useSelector(
    selectActiveResource,
  );
  const { dataTypesBySource, documentTypesBySource } = data;

  const getDataTypes = useCallback(() => {
    type === 'Data'
      ? dispatch(
          actions.getDataTypesBySource({
            q: { sourceUuid: resourceUuid },
          }),
        )
      : dispatch(
          actions.getDocumentTypesBySource({
            q: { id: resourceUuid },
          }),
        );
  }, [dispatch, resourceUuid, type]);

  useEffect(() => {
    getDataTypes();
  }, [getDataTypes]);

  const columns: TableColumnProps[] = [
    {
      header: <Box pl={10}>{type} type</Box>,
      accessor: 'data_type',
      render: ({ row }) => {
        const value = row?.data_type || row?.type_name || '';
        return (
          <WithResourceIcon
            resourceType={IconTypes.SensitiveData}
            bgColor="sensitiveDataBg"
            useCustomColor
            tooltip={value}
          >
            {value}
          </WithResourceIcon>
        );
      },
    },
    ...(type === 'Data'
      ? [
          {
            header: 'Sensitivity',
            accessor: 'sensitivity_level',
            align: 'left',
            render: ({ value }) =>
              renderSeverityBar({ value, isInline: false }),
          },
          {
            header: 'Geo',
            accessor: 'geo',
            align: 'left',
          },
        ]
      : [
          {
            header: 'Sub-Types',
            accessor: 'subtypes',
          },
        ]),

    {
      header: 'Found In',
      render: ({ row }) => {
        const amount = type === 'Data' ? row?.found_in_amount : 1;
        return (
          amount +
          (resourceType === NativeResources.SimpleStorageService
            ? ' source'
            : ' file') +
          (amount === 1 ? '' : 's')
        );
      },
      align: 'left',
    },
    {
      header: 'Count',
      render: ({ row }) =>
        formatNumber(row?.count) +
        ' (' +
        round(row?.percent || row?.percentage || 0) +
        '%)',
      align: 'left',
    },
    {
      header: 'Label(s)',
      render: renderDataTypeLabels,
      align: 'left',
    },
  ];

  return (
    <Box w="full" h="full" flex={1}>
      <Table
        columns={columns}
        data={
          type === 'Document'
            ? documentTypesBySource.data
            : dataTypesBySource.data
        }
        isLoading={
          dataTypesBySource.isLoading || documentTypesBySource.isLoading
        }
        subComponent={({ row, expanded }) =>
          expanded && (
            <DataTypesBySourceSubComponent
              type={type}
              row={row}
              isExpanded={expanded}
            />
          )
        }
        {...(type === 'Document'
          ? {
              pagination: {
                totalCount: documentTypesBySource.page.totalCount,
                pageInfo: documentTypesBySource.page.info,
                onChange: pageInfo =>
                  dispatch(
                    actions.updateDocumentTypesBySourcePagination(pageInfo),
                  ),
              },
            }
          : {})}
        cursor="pointer"
      />
    </Box>
  );
};
