import React, { FC, useCallback, useEffect, useMemo } from 'react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  NumberInput,
  NumberInputField,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { useErrorMessage, useStyles } from '../index';
import {
  FieldProps,
  NumberFieldSchema,
  NumberInputFieldStyles,
} from '../types';

import { FormLabel } from './FormLabel';

export const NumberField: FC<FieldProps<NumberFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    isDisabled,
    helpIcon,
    defaultValue,
    styles = {},
    tooltip,
    value,
    placeholder,
    onChange,
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const [focused, setFocused] = React.useState(false);
  const fieldStyles = useStyles<NumberInputFieldStyles>('numberField', styles);

  const {
    register,
    watch,
    errors,
    setValue,
    getValues,
    control,
  } = useFormContext();

  const values = watch({ nest: true });

  const errorMessage = useErrorMessage(name, label);

  useEffect(() => {
    value && setValue(name, value);
  }, [value, setValue, name, defaultValue]);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnChange = e => {
    onChange?.(e);
  };

  const onKeyDown = e => {
    setValue(name, e.target.value);
  };

  const inputProps = useCallback(
    () => ({
      type: 'number',
      id: id,
      name: name,
      key: id || `${name}-input`,
      'data-test': name,
      'aria-label': name,

      value: getValues(name),
      defaultValue,

      placeholder: placeholder,
      isDisabled: isDisabled || isLoading,
      onChange: handleOnChange,
      onKeyDown: onKeyDown,

      isInvalid: errorMessage,
      onFocus: () => setFocused(true),
      onBlur: () => setFocused(false),
      ...fieldStyles.numberInput,
    }),
    [
      errorMessage,
      fieldStyles.numberInput,
      getValues,
      handleOnChange,
      id,
      isDisabled,
      name,
      onKeyDown,
      placeholder,
    ],
  );

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Controller
        name={name}
        control={control}
        as={
          <Box flex="1" h={tooltip && '12'}>
            <Skeleton isLoaded={!isLoading}>
              <NumberInput ref={register} {...inputProps()}>
                <NumberInputField
                  {...fieldStyles.inputField}
                  size={1}
                  h="8"
                  _hover={{ borderColor: 'blue.200' }}
                  _focus={{ borderWidth: '1', borderColor: 'primary' }}
                />
              </NumberInput>
            </Skeleton>

            {!errorMessage && tooltip && focused && (
              <Text color="gray.200" mt="1" fontSize="12">
                {tooltip}
              </Text>
            )}
          </Box>
        }
      />
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
