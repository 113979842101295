import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from '../../Components/MetadataCommonFields';

export interface Props {}

export const ResourceMetadataAndInsights: FC<Props> = () => {
  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={3}>
        <MetadataCommonFields />
      </Grid>
    </Stack>
  );
};
