import React from 'react';

import { Icon } from '@chakra-ui/react';

export const UserIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    p={0.5}
    {...props}
  >
    <g>
      <path
        d="M466.1,512c-10.9,0-19.7-8.8-19.7-19.7v-52.5c0-47.1-38.3-85.3-85.3-85.3H151c-47.1,0-85.3,38.3-85.3,85.3
		v52.5c0,10.9-8.8,19.7-19.7,19.7s-19.7-8.8-19.7-19.7v-52.5c0-68.8,55.9-124.7,124.7-124.7H361c68.8,0,124.7,55.9,124.7,124.7v52.5
		C485.7,503.2,476.9,512,466.1,512z M256,249.4c-68.8,0-124.7-55.9-124.7-124.7S187.2,0,256,0s124.7,55.9,124.7,124.7
		S324.8,249.4,256,249.4z M256,39.4c-47.1,0-85.3,38.3-85.3,85.3s38.3,85.3,85.3,85.3s85.3-38.3,85.3-85.3S303.1,39.4,256,39.4z"
      />
    </g>
  </Icon>
);
