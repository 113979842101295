import React from 'react';

import { Icon } from '@chakra-ui/react';

export const EventLogIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" w="full" h="full" {...props}>
    <path
      d="M3 12H9L12 5L14 18.5L17.5 12H21.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
