import React, { FC, useEffect, useState } from 'react';

import { TourProvider, ProviderProps } from '@reactour/tour';
import map from 'lodash/map';

import { contentComponent } from 'components/Tour/ContentComponent';
import { tourStyles } from 'components/Tour/styles';

interface Props extends ProviderProps {
  fieldName: string;
}

export const Tour: FC<Props> = props => {
  const { steps, fieldName, ...rest } = props;
  const [tour, setTour] = useState({});

  useEffect(() => {
    setTour(
      localStorage.getItem(`tour`)
        ? JSON.parse(localStorage.getItem(`tour`) || '')
        : {},
    );
  }, []);

  useEffect(
    () => () => {
      if (tour[fieldName])
        localStorage.setItem(
          `tour`,
          JSON.stringify({ ...tour, [fieldName]: 'false' }),
        );
    },
    [fieldName, tour],
  );

  const onClose = setIsOpen => {
    setIsOpen(false);
    localStorage.setItem(
      `tour`,
      JSON.stringify({ ...tour, [fieldName]: 'false' }),
    );
  };
  return (
    <TourProvider
      steps={map(steps, ({ content, ...rest }) => ({
        content: contentProps =>
          contentComponent(content, contentProps, fieldName, onClose),
        ...rest,
      }))}
      styles={tourStyles}
      onClickClose={({ setIsOpen }) => {
        onClose(setIsOpen);
      }}
      //defaultOpen={!tour[fieldName]}
      scrollSmooth
      {...rest}
    />
  );
};
