import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  BlockBucketPolicy,
  BlockPublicACLs,
  BucketKeyEnabled,
  Grants,
  IgnorePublicACLs,
  LastUpdatedAt,
  Policy,
  RestrictPublicBuckets,
  ServerSideEncryption,
  VersioningEnabled,
  WebsiteEnabled,
} from '../../../Components/MetadataField/MetaGridFields';

export const ObjectStorageMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields state="Active" />
      {LastUpdatedAt(insight)}
      {ServerSideEncryption(resource)}
      {BucketKeyEnabled(resource)}
      {VersioningEnabled(resource)}
      {WebsiteEnabled(resource)}
      {BlockPublicACLs(resource)}
      {IgnorePublicACLs(resource)}
      {BlockBucketPolicy(resource)}
      {RestrictPublicBuckets(resource)}
      {Grants(resource)}
      {Policy(insight)}
    </Grid>
  );
};
