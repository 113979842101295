/**
 *
 * Slack Form
 *
 */

import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import find from 'lodash/find';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { Form, FormSchema, QuestionCircleIcon } from 'app/components';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { formStyles } from '../../../styles';

import { CreateOAuthToken } from './Help/CreateOAuthToken';

interface Props {
  handleSubmit: (date: any) => void;
  validate?: boolean;
}

export const SlackForm = (props: Props) => {
  const { handleSubmit, validate } = props;
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [channels, setChannels] = useState<Record<string, any>[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Record<string, any>>(
    {},
  );
  const { client, clientInfo } = useSelector(selectIntegrations);
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();
  const [isEdit, setIsEdit] = useState(actionType === 'Update');

  //set isEdit true if opened in edit
  useEffect(() => {
    setIsEdit(actionType === 'Update');
  }, [actionType]);

  const toggleIsHelpOpen = () => setIsHelpOpen(!isHelpOpen);

  //set channel options
  useEffect(() => {
    let options = map(clientInfo.data?.slack, o => ({
      label: o?.name,
      value: o?.id,
    }));
    setChannels(options);
    setSelectedChannel(options[0]);
  }, [clientInfo.data]);

  //set selected channel in update mode
  useEffect(() => {
    isEdit &&
      setSelectedChannel(
        find(channels, o => o?.value === currentRecord?.slack?.channel_id) ||
          {},
      );
  }, [channels, currentRecord, isEdit]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  const slackChannel: FormSchema = validate
    ? {
        slack_channel: {
          type: 'react-select',
          label: 'Channels',
          isRequired: true,
          defaultValue: selectedChannel,
          options: channels,
          isLoading: clientInfo.isLoading,
          isDisabled: clientInfo.isLoading || client.isLoading,
        },
      }
    : {};

  const token: FormSchema = isEdit
    ? {}
    : {
        token: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'OAuth Token',
          isRequired: true,
          isDisabled: validate,
          helpComponent: <CreateOAuthToken />,
          isHelpOpen: isHelpOpen,
          helpIcon: (
            <Box
              onClick={toggleIsHelpOpen}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          /*error:
        clientInfo.isError && !validate
          ? 'Please enter valid OAuth token!'
          : '',*/
        },
      };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          defaultValue: isEdit ? currentRecord?.name : '',
          isDisabled: client.isLoading,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
        },
        ...token,
        ...slackChannel,
      }}
      showHelp
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: isEdit ? 'Update' : validate ? 'Okay' : 'Get Channels',
          isLoading: client.isLoading || clientInfo.isLoading,
          isDisabled: validate && !channels.length,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading || clientInfo.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
