import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.securityGraph || initialState;

export const selectSecurityGraph = createSelector(
  [selectDomain],
  securityGraphState => securityGraphState,
);
