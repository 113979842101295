import React from 'react';

import { HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AccountIcon, CloudIcon, CloudServiceIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { renderResourceCount } from 'containers/Dashboard/utils/utils';

export const SummaryOfAllDataSources: React.FC = () => {
  const { summaryOfAllDataSources } = useSelector(selectDashboard);
  const navigate = useNavigate();

  const handleRedirect = () => navigate('/setup/accounts');

  const legends = [
    {
      icon: <CloudIcon />,
      count: summaryOfAllDataSources.data.providers?.length ?? 0,
      label: 'Clouds',
      isLoading: summaryOfAllDataSources.isLoading,
      onClick: handleRedirect,
    },
    {
      icon: <CloudServiceIcon />,
      count: summaryOfAllDataSources.data?.saas?.length ?? 0,
      label: 'SaaS',
      isLoading: summaryOfAllDataSources.isLoading,
      onClick: handleRedirect,
    },
    {
      icon: <AccountIcon />,
      count: summaryOfAllDataSources.data?.accounts ?? 0,
      label: 'Accounts',
      isLoading: summaryOfAllDataSources.isLoading,
      onClick: handleRedirect,
    },
  ];

  return (
    <SummaryCard
      content={
        <HStack w="full" justify="space-between">
          {map(legends, o => renderResourceCount(o))}
        </HStack>
      }
    />
  );

  /*return (
    <DashboardOverviewCard
      label="Summary of data footprint"
      icon={<SummaryIcon bg={customTheme.colors.primary} />}
      rightHeader={renderCloudIcons(summaryOfAllDataSources.data.providers)}
      content={
        <HStack w="full" h="full" justify="space-between" spacing={1}>
          {renderSummaryCount(
            <CloudIcon />,
            summaryOfAllDataSources.data.providers?.length ?? 0,
            'Clouds',
            summaryOfAllDataSources.isLoading,
          )}
          {renderSummaryCount(
            <AccountIcon />,
            summaryOfAllDataSources.data?.accounts ?? 0,
            'Accounts',
            summaryOfAllDataSources.isLoading,
            handleRedirect,
          )}
          {renderSummaryCount(
            <RegionIcon />,
            summaryOfAllDataSources.data?.regions ?? 0,
            'Regions',
            summaryOfAllDataSources.isLoading,
            handleRedirect,
          )}
          {renderSummaryCount(
            <BlockStorageIcon p={0.5} />,
            summaryOfAllDataSources.data.resources ?? 0,
            'Data',
            summaryOfAllDataSources.isLoading,
            handleRedirect,
          )}
        </HStack>
      }
    />
  );*/
};
