import React from 'react';

import { Icon } from '@chakra-ui/react';

export const Neo4jIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <g>
      <path
        fill="#008CC1"
        d="M460.7,232.4c0,118.9-96.4,215.3-215.3,215.3S30,351.3,30,232.4c0-118.9,96.4-215.3,215.3-215.3
		C364.3,16.9,460.7,113.4,460.7,232.4"
      />
      <path
        fill="#FFFFFF"
        d="M125.5,161.7c0,9.5-7.7,17.2-17.2,17.2c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2
		C117.7,144.5,125.4,152.2,125.5,161.7C125.5,161.7,125.5,161.7,125.5,161.7 M128.4,209c0,9.5-7.7,17.2-17.2,17.2S94,218.5,94,209
		c0-9.5,7.7-17.2,17.2-17.2C120.7,191.8,128.4,199.4,128.4,209C128.4,208.9,128.4,209,128.4,209 M138.9,259.1
		c0,9.5-7.7,17.2-17.2,17.2s-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C131.2,241.8,138.9,249.5,138.9,259.1 M162.5,303.9
		c0,9.5-7.7,17.2-17.2,17.2c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C154.8,286.6,162.5,294.3,162.5,303.9 M346.8,366.6
		c0,9.5-7.7,17.2-17.2,17.2c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C339.1,349.4,346.8,357.1,346.8,366.6 M378.1,329.6
		c0,9.5-7.7,17.2-17.2,17.2c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C370.4,312.4,378.1,320.1,378.1,329.6 M287.4,67.8
		c0,9.5-7.7,17.2-17.2,17.2c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C279.7,50.5,287.4,58.3,287.4,67.8 M242.1,51.9
		c0,9.5-7.7,17.2-17.2,17.2s-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2C234.4,34.7,242.1,42.4,242.1,51.9"
      />
      <path
        fill="#66B245"
        d="M364.2,66.3c63.4,0,114.8,51.4,114.8,114.8s-51.4,114.8-114.8,114.8c-63.4,0-114.8-51.4-114.8-114.8
		S300.8,66.3,364.2,66.3"
      />
      <path
        fill="#FFFFFF"
        d="M364.2,298.9c-65,0-117.8-52.9-117.8-117.8c0-65,52.9-117.8,117.8-117.8c65,0,117.8,52.9,117.8,117.8
		C482,246,429.1,298.9,364.2,298.9L364.2,298.9z M364.2,69.4c-61.6,0-111.7,50.1-111.7,111.7c0,61.6,50.1,111.7,111.7,111.7
		S475.8,242.6,475.8,181S425.7,69.4,364.2,69.4L364.2,69.4z"
      />
      <path
        fill="#66B245"
        d="M206.9,321.1c51.9,0,93.9,42,93.9,93.9c0,51.9-42,93.9-93.9,93.9c-51.9,0-93.9-42-93.9-93.9
		C113.1,363.2,155.1,321.1,206.9,321.1"
      />
      <path
        fill="#FFFFFF"
        d="M206.9,512c-53.5,0-97-43.5-97-97c0-53.5,43.5-97,97-97c53.5,0,97,43.5,97,97C303.9,468.5,260.4,512,206.9,512
		z M206.9,324.2c-50.1,0-90.8,40.7-90.8,90.8c0,50.1,40.7,90.8,90.8,90.8s90.8-40.7,90.8-90.8S257,324.2,206.9,324.2L206.9,324.2z"
      />
      <path
        fill="#66B245"
        d="M129.6,3.1c34.6,0,62.6,28,62.6,62.6c0,34.6-28,62.6-62.6,62.6s-62.6-28-62.6-62.6S95.1,3.1,129.6,3.1"
      />
      <path
        fill="#FFFFFF"
        d="M129.6,131.3c-36.2,0-65.7-29.5-65.7-65.7S93.4,0,129.6,0s65.7,29.5,65.7,65.7S165.9,131.3,129.6,131.3z
		 M129.6,6.1c-32.8,0-59.5,26.7-59.5,59.5s26.7,59.5,59.5,59.5s59.5-26.7,59.5-59.5C189.2,32.8,162.5,6.1,129.6,6.1L129.6,6.1z"
      />
    </g>
  </Icon>
);
