import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { AppIcon } from 'components/Icons';
import { DataProtectionSummary } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DataProtectionSummary';
import { DataSummary } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DataSummary';
import { DataTypesBySource } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DataTypesBySource';
import { DistributionByDataLabels } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DistributionByDataLabels';
import { DistributionByDocumentType } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DistributionByDocumentTypes';
import { DistributionByTypesOfFiles } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/DistributionByTypesOfFiles';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

export const SensitiveData: FC = () => {
  const dispatch = useDispatch();
  const {
    resourceType,
    resourceInsight,
    resourceUuid,
    resourceAccountId,
  } = useSelector(selectActiveResource);
  const [byType, setByType] = useState<Record<string, any>>({
    label: 'By data type',
    value: 'Data',
  });

  useEffect(() => {
    if (!!resourceInsight.data) {
      dispatch(
        actions.getDataSummary({
          q: {
            accountIds: [resourceAccountId],
            sourceUUIDs: [resourceUuid],
          },
        }),
      );
      dispatch(
        actions.getDataLabels({
          q: {
            accountIds: [resourceAccountId],
            sourceUUIDs: [resourceUuid],
          },
        }),
      );
      dispatch(
        actions.getDistributionByTypeOfFiles({
          q: {
            accountIds: [resourceAccountId],
            sourceUUIDs: [resourceUuid],
          },
        }),
      );
      if (
        resourceType === NativeResources.SimpleStorageService ||
        resourceType === NativeResources.RelationalDatabaseService
      )
        dispatch(
          actions.getDistributionByDocumentType({
            q: {
              id: resourceUuid,
            },
          }),
        );
    }
  }, [
    dispatch,
    resourceInsight.data,
    resourceUuid,
    resourceAccountId,
    resourceType,
  ]);

  return (
    <Stack spacing={6} h="full">
      <Grid templateColumns="repeat(4, 1fr)" gap={4} h={40}>
        <GridItem colSpan={1}>
          <DataSummary />
        </GridItem>
        {resourceType !== NativeResources.GitHubRepository &&
          resourceType !== NativeResources.GitLabProject &&
          resourceType !== NativeResources.BitBucketRepository && (
            <GridItem colSpan={1}>
              <DataProtectionSummary />
            </GridItem>
          )}
        <GridItem colSpan={2}>
          {resourceType === NativeResources.SimpleStorageService ||
          resourceType === NativeResources.RelationalDatabaseService ? (
            byType?.value === 'Data' ? (
              <DistributionByTypesOfFiles />
            ) : (
              <DistributionByDocumentType />
            )
          ) : (
            <DistributionByDataLabels />
          )}
        </GridItem>
      </Grid>
      {resourceType === NativeResources.SimpleStorageService && (
        <Flex w="full" justifyContent="flex-end">
          <Box w={48}>
            <Select
              options={[
                { label: 'By data type', value: 'Data' },
                { label: 'By document type', value: 'Document' },
              ]}
              value={byType}
              onChange={val => setByType(val)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              showIconInValueContainer
              valueContainerIcon={<AppIcon />}
            />
          </Box>
        </Flex>
      )}
      <DataTypesBySource type={byType?.value} />
    </Stack>
  );
};
