import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NetworkACLIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M78.3,253.8h36.1v59.9h6.7c0,0,0,0,0,0h6.7v-59.9h36.9v-11.1h179.9v11.6H378v59.4h6.5c0,0,0,0,0,0h6.5v-59.4
		h39.3v-9c0-0.1,0.1-0.2,0.1-0.3c0-0.8,0-1.5,0-2.3h60.1v-13.8h-60.1c0-0.6,0-1.1,0-1.7c0-0.1-0.1-0.2-0.1-0.3V218h-85.7v10.8h-83.1
		V201h-7.2c0,0,0,0,0,0h-7.1v27.9h-82.4v-11.6H78.3v11.6H11.7v13.8h66.6V253.8z"
      />
      <path
        d="M225.8,177.6h-29.4v5.6h113.6v-5.6h-29.5v-28h75V16.3H153.3v133.3h72.5C225.8,149.6,225.8,170,225.8,177.6z
		 M173.8,129.1V36.8h161.1v92.3H173.8z"
      />
      <path d="M0,462.1h72.5v28H43v5.6h113.6V490h-29.5v-28h75V328.8H0V462.1z M20.5,349.3h161.1v92.3H20.5V349.3z" />
      <path
        d="M512,459V325.7H309.9V459h72.5v28h-29.4v5.6h113.6v-5.6H437v-28H512z M330.4,438.5v-92.3h161.1
		c0,0,0,92.3,0,92.3H330.4z"
      />
    </g>
  </Icon>
);
