/**
 *
 * Setup
 *
 */

import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'app/components';
import { DataConfiguration } from 'containers/Setup/DataConfiguration/Loadable';
import { Environment } from 'containers/Setup/Environment/Loadable';
import { groupsSaga } from 'containers/Setup/Groups/saga';
import * as groupSlice from 'containers/Setup/Groups/slice';
import { Jit } from 'containers/Setup/Jit/Loadable';
import { Outpost } from 'containers/Setup/Outpost/Loadable';
import { rolesSaga } from 'containers/Setup/Roles/saga';
import * as rolesSlice from 'containers/Setup/Roles/slice';
import { setupUsersSaga } from 'containers/Setup/Users/saga';
import * as userSlice from 'containers/Setup/Users/slice';
import { useInjector } from 'utils/inject';

import { CloudAccountWizard } from './CloudAccounts/CloudAccountWizard/Loadable';
import { MemberAccountPage } from './CloudAccounts/Components/MemberAccountPage';
import { CloudAccounts } from './CloudAccounts/Loadable';
import { Groups } from './Groups';
import { Integrations } from './Integrations/Loadable';
import { IPWhitelisting } from './IPWhitelisting';
import { IPWhitelistingWizard } from './IPWhitelisting/IPWhitelistingWizard';
import { Roles } from './Roles/Loadable';
import { setupSaga } from './saga';
import { reducer, sliceKey } from './slice';
import { SetupUsers } from './Users/Loadable';
import { SetupUserProfile } from './Users/SetupUserProfile';
import { CreateUser } from './Users/SetupUsersWizard/CreateUser';
import { EditUser } from './Users/SetupUsersWizard/EditUser';

export const Setup = memo(() => {
  useInjector(sliceKey, reducer, setupSaga);
  useInjector(groupSlice.sliceKey, groupSlice.reducer, groupsSaga);
  useInjector(userSlice.sliceKey, userSlice.reducer, setupUsersSaga);
  useInjector(rolesSlice.sliceKey, rolesSlice.reducer, rolesSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/setup/data" />}>
        <Route path="data" element={<DataConfiguration />} />
      </Route>
      <Route element={<FilterRouter path="/setup/outpost" />}>
        <Route path="outpost/*" element={<Outpost />} />
      </Route>

      <Route element={<FilterRouter path="/setup/accounts/add/:provider" />}>
        <Route path="accounts/add/:provider" element={<CloudAccountWizard />} />
      </Route>
      <Route element={<FilterRouter path="/setup/accounts/add" />}>
        <Route path="accounts/add" element={<CloudAccountWizard />} />
      </Route>
      <Route
        element={
          <FilterRouter path="/setup/accounts/edit/:provider/:id/:accountType" />
        }
      >
        <Route
          path="accounts/edit/:provider/:id/:accountType"
          element={<CloudAccountWizard />}
        />
      </Route>
      <Route
        element={<FilterRouter path="/setup/accounts/edit/:provider/:id" />}
      >
        <Route
          path="accounts/edit/:provider/:id"
          element={<CloudAccountWizard />}
        />
      </Route>
      <Route
        element={<FilterRouter path="/setup/accounts/:uuid/member_accounts" />}
      >
        <Route
          path="accounts/:uuid/member_accounts"
          element={<MemberAccountPage />}
        />
      </Route>
      <Route element={<FilterRouter path="/setup/accounts" />}>
        <Route path="accounts" element={<CloudAccounts />} />
      </Route>

      <Route element={<FilterRouter path="/setup/jit" />}>
        <Route path="jit/*" element={<Jit />} />
      </Route>

      <Route element={<FilterRouter path="/setup/ip-whitelisting/add" />}>
        <Route
          path="/setup/ip-whitelisting/add"
          element={<IPWhitelistingWizard />}
        />
      </Route>
      <Route element={<FilterRouter path="/setup/ip-whitelisting" />}>
        <Route path="ip-whitelisting" element={<IPWhitelisting />} />
      </Route>

      <Route element={<FilterRouter path="/setup/roles" />}>
        <Route path="roles" element={<Roles />} />
      </Route>
      <Route element={<FilterRouter path="/setup/integrations" />}>
        <Route path="integrations" element={<Integrations />} />
      </Route>
      <Route element={<FilterRouter path="/setup/users/profile" />}>
        <Route path="users/profile" element={<SetupUserProfile />} />
      </Route>

      <Route element={<FilterRouter path="/setup/users/edit/:user_id" />}>
        <Route path="users/edit/:user_id" element={<EditUser />} />
      </Route>
      <Route element={<FilterRouter path="/setup/users/add" />}>
        <Route path="users/add" element={<CreateUser />} />
      </Route>
      <Route element={<FilterRouter path="/setup/users" />}>
        <Route path="users" element={<SetupUsers />} />
      </Route>

      <Route element={<FilterRouter path="/setup/groups" />}>
        <Route path="groups/*" element={<Groups />} />
      </Route>

      <Route element={<FilterRouter path="/setup/environment" />}>
        <Route path="environment/*" element={<Environment />} />
      </Route>

      {/*<FilterRouter path="/setup" component={Organizations} />*/}
    </Routes>
  );
});
