/**
 *
 * Reports
 *
 */

import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { AllReports } from 'containers/Reports/AllReports/Loadable';

import { ReportsDashboard } from './ReportsDashboard';

export const Reports = () => {
  return (
    <Routes>
      <Route path="all" element={<AllReports />} />
      <Route path="dashboard/*" element={<ReportsDashboard />} />
    </Routes>
  );
};
