import React, { FC, useEffect, useMemo, useReducer, useRef } from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { each, find, map, replace, toArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  cloudProviderOptionsWithAll,
  CustomTooltip,
  DetailsIdentifier,
  renderCloudIcon,
  renderRiskContext,
  renderTableHeaderWithLoader,
  StackedCell,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { ActionButton } from 'components/DataEntry';
import { GraphIcon, NotificationIcon, TicketIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { renderHighPrivileges } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { actions } from 'containers/Dashboard/slice';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { NotificationResource } from 'containers/Findings/Alerts/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { renderDataTypeLabels } from 'containers/Visibility/Data/Components/hooks/utils';
import { useRedirectToSecurityGraph } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectToSecurityGraph';

interface Props extends AllEntitiesDetailsProps {
  isOpen: boolean;
  onClose();
  vulnerable?: boolean;
}

export const AllEntitiesDrawer: FC<Props> = props => {
  const {
    isOpen,
    onClose,
    panel,
    iconType,
    resourceType,
    resourceTypeClass = 'native',
    total,
    iconBgColor = 'critical',
    vulnerable = false,
  } = props;
  const {
    allEntities,
    alertCount,
    drawerConfig,
    securityGraphAvailability,
  } = useSelector(selectDashboard);
  const { riskContext } = useSelector(selectSharedState);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const { environmentId, accountId } = useAccessBoundary();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const { redirectToSecurityGraph } = useRedirectToSecurityGraph();
  const {
    resourceTypeAliasOptionsWithAll,
    agnosticResourceTypeOptionsWithAll,
  } = useResourceType();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: {},
      cloud: cloudProviderOptionsWithAll[0],
      currentRow: {},
      resourceTypeOptions: [],
      allFilters: {},
    },
  );
  const navigate = useNavigate();

  //set resource type options
  useEffect(() => {
    updateState({
      resourceTypeOptions:
        resourceTypeClass === 'native'
          ? resourceTypeAliasOptionsWithAll
          : agnosticResourceTypeOptionsWithAll,
    });
  }, [
    resourceTypeAliasOptionsWithAll,
    agnosticResourceTypeOptionsWithAll,
    resourceTypeClass,
  ]);

  //set selected resource type
  useEffect(() => {
    let selected = state.resourceTypeOptions?.[0];
    if (!!resourceType)
      selected =
        find(state.resourceTypeOptions, o => o.value === resourceType) ??
        state.resourceTypeOptions?.[0];
    updateState({ resourceType: selected });
  }, [state.resourceTypeOptions, resourceType]);

  useEffect(() => {
    if (isOpen) {
      dispatch(
        actions.getDrawerConfig({
          q: {
            panel,
            ...(resourceTypeClass === 'agnostic'
              ? { sourceAgnosticClass: resourceType as Resources }
              : {}),
          },
          onSuccess: r => {
            let filters: Record<string, any> = {};
            each(r?.filters, o => (filters[o.name] = o));
            updateState({ allFilters: filters });

            dispatch(
              actions.getAllEntities({
                q: {
                  panel,
                  environmentId,
                  accountId: !!accountId ? [accountId] : [],
                  page: allEntities.page.info.page_number,
                  size: allEntities.page.info.page_size,
                  ...(resourceTypeClass === 'agnostic'
                    ? { sourceAgnosticClass: resourceType as Resources }
                    : {}),
                  total,
                },
                onSuccess: res => {
                  dispatch(
                    sharedStateActions.getRiskContext({
                      q: {
                        riskContextRequest: {
                          uuids: map(res, o => o?.uuid),
                        },
                      },
                    }),
                  );
                  dispatch(
                    actions.getAlertCount({
                      q: {
                        environmentId: environmentId!,
                        accountIds: !!accountId ? [accountId] : [],
                        resourceUuids: map(res, o => o?.uuid),
                      },
                    }),
                  );

                  let resourceTypes = new Set<string>();
                  each(res, o => resourceTypes.add(o?.native_class));
                  dispatch(
                    actions.getSecurityGraphAvailability({
                      q: {
                        resources: toArray(resourceTypes) as string[],
                      },
                    }),
                  );
                },
              }),
            );
          },
        }),
      );
    }
  }, [
    dispatch,
    isOpen,
    environmentId,
    accountId,
    panel,
    resourceType,
    resourceTypeClass,
    allEntities.page.info,
    total,
  ]);

  const renderColumn = (primary, secondary) => {
    return !!secondary ? (
      <StackedCell
        upper={primary}
        lower={secondary}
        showLowerTooltip={false}
        showUpperTooltip={false}
      />
    ) : (
      primary ?? '-'
    );
  };

  const columns = useMemo(
    () => [
      ...map(drawerConfig.data.columns, o => ({
        header:
          o.header === 'Risk Context'
            ? renderTableHeaderWithLoader('Risk Context', riskContext.isLoading)
            : o.header === 'Alerts'
            ? renderTableHeaderWithLoader('Alerts', alertCount.isLoading)
            : o.header,
        accessor: o?.primary_field,
        align:
          o.header === 'CLOUD' || o.header === 'Alerts' ? 'center' : 'left',
        render: ({ row }) => {
          const primary = row?.table_items?.[o.header]?.primary_field;
          const secondary = row?.table_items?.[o.header]?.secondary_field;
          const hover_1 =
            row?.table_items?.[o.header]?.primary_field_hover_1 ??
            row?.table_items?.[o.header]?.primary_field;
          const hover_2 =
            row?.table_items?.[o.header]?.primary_field_hover_2 ??
            row?.table_items?.[o.header]?.secondary_field;

          return o?.primary_field === 'name' ? (
            <WithResourceIcon resourceType={row?.native_class} iconSize="sm">
              <StackedCell upper={primary} lower={secondary} />
            </WithResourceIcon>
          ) : o.header === 'cloud' ? (
            <Center>
              {renderCloudIcon({
                provider: primary,
              })}
            </Center>
          ) : o.header === 'Risk Context' ? (
            renderRiskContext(riskContext.data?.[row?.uuid])
          ) : o.header === 'Alerts' ? (
            alertCount.data?.[row?.uuid]
          ) : o.header === 'Data Labels' ? (
            renderDataTypeLabels({ row: { labels: primary } })
          ) : o.header === 'Privileges' ? (
            renderHighPrivileges({ value: primary })
          ) : !!hover_1 ? (
            <CustomTooltip
              label={
                <Stack>
                  <Box color={!!hover_2 ? 'orange' : 'inherit'}>{hover_1}</Box>
                  {!!hover_2 && <Box>{hover_2}</Box>}
                </Stack>
              }
            >
              {renderColumn(primary, secondary)}
            </CustomTooltip>
          ) : (
            renderColumn(primary, secondary)
          );
        },
      })),
      {
        header: 'Actions',
        render: ({ row }) => {
          const options: Record<string, any> = {};
          each(drawerConfig.data?.actions, a => (options[a?.label] = a));
          return (
            <Center onClick={e => e.stopPropagation()}>
              {options?.['access_graph']?.enabled && (
                <ActionButton
                  label="Show security graph"
                  icon={<GraphIcon />}
                  isDisabled={
                    //securityGraphAvailability.isLoading ||
                    !securityGraphAvailability.data?.[row?.native_class]
                  }
                  onClick={() => {
                    const { native_class, uuid, resource_id, account } = row;
                    if (!!options?.['access_graph']?.query) {
                      const searchTerm = replace(
                        replace(
                          options?.['access_graph']?.query,
                          '$UUID',
                          uuid,
                        ),
                        '$native_name/$agnostic_class',
                        native_class,
                      );
                      navigate(
                        '/visibility/security-graph?query=' +
                          searchTerm +
                          '&redirected=true&source_uuid=' +
                          uuid,
                      );
                    } else {
                      redirectToSecurityGraph({
                        mapType: 'posture',
                        resourceType: native_class,
                        resourceUuid: uuid,
                        resourceId: resource_id,
                        account: account ?? row?.account_uuid,
                        ...(vulnerable
                          ? { sourceTab: MetadataTabs.Vulnerabilities }
                          : {}),
                      });
                    }
                    updateState({ currentRow: row });
                  }}
                />
              )}

              {options?.['ticket_create']?.enabled && (
                <ActionButton
                  label="Create ticket"
                  icon={<TicketIcon />}
                  onClick={() => {
                    updateState({ currentRow: row });
                    ticketModal.onOpen();
                  }}
                />
              )}
              {options?.['notify_owner']?.enabled && (
                <ActionButton
                  label="Create notification"
                  icon={<NotificationIcon />}
                  onClick={() => {
                    updateState({ currentRow: row });
                    notificationModal.onOpen();
                  }}
                />
              )}
            </Center>
          );
        },
      },
    ],
    [
      drawerConfig.data,
      ticketModal.onOpen,
      notificationModal.onOpen,
      riskContext.data,
      alertCount.data,
      riskContext.isLoading,
      alertCount.isLoading,
      redirectToSecurityGraph,
      vulnerable,
      securityGraphAvailability.data,
      securityGraphAvailability.isLoading,
      navigate,
    ],
  );

  const tableActionModals = useMemo(() => {
    const alert: NotificationResource = {
      entity_uuid: state.currentRow?.uuid,
      entity_id: state.currentRow?.resource_id,
      entity_type:
        state.currentRow?.native_name ?? state.currentRow?.native_class,
      account_id: state.currentRow?.account,
      resource: state.currentRow?.native_name ?? state.currentRow?.native_class,
      entity_name: state.currentRow?.name,
    };

    return (
      <>
        {ticketModal.isOpen && (
          <CreateTicket
            onClose={ticketModal.onClose}
            isOpen={ticketModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Alerts}
          />
        )}
        {notificationModal.isOpen && (
          <CreateNotification
            onClose={notificationModal.onClose}
            isOpen={notificationModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Alerts}
          />
        )}
      </>
    );
  }, [
    state.currentRow,
    notificationModal.isOpen,
    notificationModal.onClose,
    ticketModal.isOpen,
    ticketModal.onClose,
  ]);

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1450px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <StackedHeader
            upper={drawerConfig.data?.drawer_header}
            lower={drawerConfig.data?.category}
            type={iconType}
            bg={iconBgColor}
            isLoading={drawerConfig.isLoading}
          />
        }
        body={
          drawerConfig.isLoading ? (
            <CustomSpinner size="md" />
          ) : (
            <Stack h="full" pt={3} spacing={4}>
              <HStack spacing={6}>
                <DetailsIdentifier
                  label="Description"
                  value={drawerConfig.data?.description}
                  direction="column"
                  w="55%"
                  pb={1}
                  styles={{ label: { color: 'black' } }}
                />
                <Divider orientation="vertical" borderColor="gray.200" />
                <DetailsIdentifier
                  label="More information"
                  value={
                    <Center h="full" w="full">
                      No details
                    </Center>
                  }
                  direction="column"
                  h="full"
                  w="45%"
                  pb={1}
                  styles={{ label: { color: 'black' } }}
                />
              </HStack>
              <Divider borderColor="primary" />
              <HStack justify="space-between">
                <Box fontWeight={600} fontSize="15px">
                  Entities
                </Box>
                {/*{!isEmpty(state.allFilters) && (
                  <HStack>
                    <Box color="primary">FILTERS:</Box>
                    {!!state.allFilters?.resource_type && (
                      <Box w={64} zIndex={900}>
                        <Select
                          options={state.resourceTypeOptions}
                          value={state.resourceType}
                          onChange={s => {
                            updateState({ osAndTechnology: s });
                          }}
                          showIconInValueContainer
                        />
                      </Box>
                    )}
                    {!!state.allFilters?.provider && (
                      <Box w={48} zIndex={900}>
                        <Select
                          options={cloudProviderOptionsWithAll}
                          value={state.cloud}
                          onChange={s => {
                            updateState({ cloud: s });
                          }}
                          showIconInValueContainer
                        />
                      </Box>
                    )}
                  </HStack>
                )}*/}
              </HStack>
              <Box flex={1}>
                <Table
                  data={allEntities.data ?? []}
                  isError={allEntities.isError}
                  columns={columns}
                  isLoading={allEntities.isLoading}
                  styles={{ header: { position: 'relative', zIndex: 800 } }}
                  onRowClick={row => {
                    updateActiveResource({
                      resourceType: row?.native_class,
                      uuid: row.uuid,
                      accountId: row.account,
                      resourceId: row.resource_id,
                    });
                    onOpenMetadata();
                  }}
                  pagination={{
                    pageInfo: allEntities.page.info,
                    onChange: info =>
                      dispatch(actions.updateAllEntitiesPagination(info)),
                    totalCount: allEntities.page.totalCount,
                  }}
                />
              </Box>
              {tableActionModals}
            </Stack>
          )
        }
      />
    </Box>
  );
};
