import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CloudProvider, NativeResources } from '@ariksa/inventory-core';
import {
  RHSSuggestionType,
  SearchSuggestResponse,
  Suggestions,
} from '@ariksa/inventory-core/api';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { cloneDeep, filter, isArray, snakeCase, startCase } from 'lodash';
import { BiPlus } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { components, GroupHeadingProps } from 'react-select';
import { useDeepCompareEffect, usePrevious } from 'react-use';
import { colorMap } from 'theme';
import { Optional } from 'types/utils';

import { Select } from 'app/components/DataEntry/Select';
import { getIcon, intoOptions } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ActionButton,
  reactSelectFieldStyles,
  textFieldStyles,
} from 'components/DataEntry';
import { RemoveIcon } from 'components/Icons/ReactResourceIcons/RemoveIcon';
import { cleanUpObject } from 'containers/PolicyHub/Policies/utils';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import {
  AriksaQueryBuildId,
  AriksaQueryResourceRelationship,
  AriksaSearchQuery,
  AriksaSearchQueryAttribute,
  AriksaSearchQueryAttributeList,
  AriksaSearchQueryChange,
  AriksaSearchQueryResource,
  AriksaSearchQuerySuggestFor,
  AriksaSearchQueryThat,
  QueryOptionsStatus,
  SearchApiSearchSuggestionsRequestParams,
  SuggestForField,
} from 'containers/PolicyHub/types';
import {
  queryRelationshipId,
  queryResourceAttributeId,
  queryResourceAttributeListId,
  queryResourceId,
  queryThatId,
} from 'containers/PolicyHub/utils';
import { toTitleCase } from 'utils/string';

import {
  actions,
  findObjectByBuildId,
  updateSearchQueryObject,
} from '../../slice';

const selectStyles = {
  ...reactSelectFieldStyles.select,
  control: (styles, props) => {
    return {
      borderColor: props.hasValue ? '#ddd' : '#eee',
    };
  },
  menu: provided => ({
    ...provided,
    width: '280px',
  }),
};

interface IAriksaQueryBuilder {
  action?: string;
}

export const AddConditionButton = props => {
  const { label = '', ...rest } = props;
  return (
    <CustomTooltip label={label}>
      <ActionButton
        styles={{
          bg: colorMap.primary(100),
          _hover: { bg: colorMap.primary(), color: 'white' },
        }}
        icon={<BiPlus />}
        {...rest}
        zIndex={800}
      />
    </CustomTooltip>
  );
};

export const useGetQuerySuggestion = () => {
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(selectPolicyHub);

  // update search query in slice based on api response
  const updateSearchQuery = useCallback(
    (suggestFor: AriksaSearchQuerySuggestFor, suggestions: Suggestions) => {
      const {
        attributes = [],
        ariksa_context = [] as any[],
        cloud_tags = [] as any[],
        relationship = [],
        resource = [],
        operator = [],
        rhs = [],
      } = suggestions;

      // this payload updates the query in the slice
      const payload: AriksaSearchQueryChange = {
        op: 'set',
        build_id: suggestFor.build_id,
        field: 'unknown',
        value: null,
      };

      switch (suggestFor.for_field) {
        case 'resource_attributes':
          payload.field = 'attribute_options';
          payload.value = [
            ...attributes.map(a => ({
              name: a,
              alias: a,
              type: 'attribute',
            })),
            ...ariksa_context.map(a => ({ ...a, type: 'context' })),
            ...cloud_tags.map(a => ({ name: a, alias: a, type: 'cloud_tag' })),
          ];
          break;
        case 'attribute_operators':
          payload.field = 'operator_options';
          payload.value = operator;
          break;
        case 'attribute_values':
          payload.field = 'value_options';
          payload.value = rhs;
          break;
        case 'resource_relationships':
          payload.field = 'relationship_options';
          payload.value = relationship;
          break;
        case 'relationship_resources':
          payload.field = 'resource_options';
          payload.value = resource;
          break;
      }

      dispatch(actions.updateSearchQuery(payload));
    },
    [dispatch],
  );

  // build query suggestion payload using the path and target build.id
  const buildPayload = useCallback(
    (query: any, path: string[], suggestFor: AriksaSearchQuerySuggestFor) => {
      if (!path.length) {
        // last level reached
        const { name } = query;
        const curr: any = { name };

        switch (suggestFor.for_field) {
          case 'resource_attributes':
            curr.clauses = [{ attributes: [] }];
            break;
          case 'resource_relationships':
            curr.that = [];
            break;
          case 'relationship_attributes':
            curr.attributes = [];
            break;
          case 'attribute_values':
            curr.operator = query.operator;
            curr.value = [];
            break;
          case 'relationship_resources':
            curr.relationship = { name: query.relationship?.name };
            break;
        }
        console.log('LAST LEVEL', curr, query);
        return curr;
      }

      const key = path.slice(0, 1)[0];
      if (!key) return;
      const obj = query[key];
      if (!obj) return;

      // more levels to go
      const ret = buildPayload(obj, path.slice(1), suggestFor);
      console.log(ret, obj, path.slice(1), suggestFor);
      if (isArray(query)) {
        console.log('isArray', key, obj, ret);
        return [ret];
      }
      const { build: _1, ...restQuery } = query;
      const clonedRest = cleanUpObject(cloneDeep(restQuery));

      console.groupEnd();
      if (key === 'attributes') {
        const { that: _2, ...rest } = clonedRest;
        return {
          ...rest,
          [key]: ret,
        };
      } else if (key === 'that') {
        const { attribute: _2, ...rest } = clonedRest;
        return {
          ...rest,
          [key]: ret,
        };
      } else {
        return {
          ...clonedRest,
          [key]: ret,
        };
      }
    },
    [],
  );

  const getQuerySuggestions = useCallback(
    (
      suggestFor: AriksaSearchQuerySuggestFor,
      cloudProvider?: Optional<CloudProvider>,
    ) => {
      const { build_id } = suggestFor;
      const path = [];
      findObjectByBuildId(build_id, searchQuery, path);

      // console.log(path, build_id, for_field);
      // walk along the path and create a payload for the query suggestion
      const payload = buildPayload(searchQuery, path, suggestFor);
      console.log('payload for query suggestion', payload, path, suggestFor);

      const request: SearchApiSearchSuggestionsRequestParams = {
        req: {
          searchWrapperOptionalCategory: {
            wrapper: payload,
          },
        },
        suggestFor,
      };

      if (cloudProvider) {
        // @ts-ignore
        request.req.provider = cloudProvider;
      }

      //construct current query to search suggestion req
      dispatch(
        actions.getSearchQuerySuggestion({
          q: request,
          // depending on askingFor process the response
          onSuccess: (res: Optional<SearchSuggestResponse>) => {
            const { suggestions = {} } = res ?? {};
            updateSearchQuery(suggestFor, suggestions);
          },
        }),
      );
    },
    [buildPayload, dispatch, searchQuery, updateSearchQuery],
  );

  return {
    getQuerySuggestions,
  };
};

const useUpdateSearchQuery = () => {
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(selectPolicyHub);

  const updateSearchQuery = useCallback(
    (update: AriksaSearchQueryChange, checkValidity = true) => {
      const query = cloneDeep(searchQuery);

      const { field } = update;
      // console.log(
      //   'update field',
      //   field,
      //   ['values', 'that', 'attribute', 'resource', 'name'].includes(field),
      // );
      if (['values', 'that', 'attribute', 'resource', 'name'].includes(field)) {
        // need to check if the query is complete
        const updatedQuery = updateSearchQueryObject(query, update);
        // console.log(updatedQuery);
        const clonedQuery = cleanUpObject(cloneDeep(updatedQuery));
        // console.log('check query completion', clonedQuery);
        if (checkValidity) {
          dispatch(actions.checkQueryValidity({ q: clonedQuery }));
        }
      }

      dispatch(actions.updateSearchQuery(update));
    },
    [dispatch, searchQuery],
  );

  return { updateSearchQuery };
};

interface RemoveItemProps {
  buildId?: string;
  fieldBuildId?: string;
  field?: AriksaSearchQueryChange['field'];

  onClick?();
}

const RemoveIconButton = props => {
  const { onClick } = props;
  return (
    <>
      <Box pos={'absolute'} right={-3} top={-3.5}>
        <IconButton
          isRound
          size={'xs'}
          aria-label="Remove"
          icon={<RemoveIcon />}
          onClick={onClick}
          border={'1px solid red'}
        />
      </Box>
    </>
  );
};

const RemoveItem = (props: RemoveItemProps) => {
  const { buildId, field, fieldBuildId, onClick } = props;
  const { updateSearchQuery } = useUpdateSearchQuery();
  const removeKeyword = () => {
    onClick?.();
    if (!buildId || !field) {
      return;
    }
    updateSearchQuery({
      op: 'remove',
      build_id: buildId,
      field_build_id: fieldBuildId,
      field: field,
    });
  };

  return (
    <>
      {((buildId && field) || onClick) && (
        <RemoveIconButton onClick={() => removeKeyword()} />
      )}
    </>
  );
};

interface KeywordProps extends RemoveItemProps {
  text: string;
}

const Keyword = (props: KeywordProps) => {
  const { text, buildId, field, fieldBuildId, onClick } = props;

  return (
    <Flex
      bg={'#eee'}
      borderRadius={4}
      px={2}
      py={1}
      h={'32px'}
      align={'center'}
      border={'1px solid'}
      borderColor={'primary'}
      pos={'relative'}
      overflow={'visible'}
    >
      <RemoveItem
        buildId={buildId}
        field={field}
        fieldBuildId={fieldBuildId}
        onClick={onClick}
      />
      <Text whiteSpace={'nowrap'}>{text.toUpperCase()}</Text>
    </Flex>
  );
};

interface ResourceTypeProps {
  resourceTypes: NativeResources[];
  // selected resource type
  value?: NativeResources;

  onChange?(resource: string);

  isLoading?: boolean;
  isDisabled?: boolean;
}

export const ResourceType = (props: ResourceTypeProps) => {
  const {
    resourceTypes = [],
    value,
    isLoading,
    onChange,
    isDisabled = false,
  } = props;
  const options = isDisabled
    ? [
        {
          label: startCase(snakeCase(String(value)).split('_').join(' ')),
          value: value as string,
          icon: getIcon(value as string),
        },
      ]
    : intoOptions(resourceTypes).map(r => ({
        ...r,
        label: startCase(snakeCase(String(r.value)).split('_').join(' ')),
        icon: getIcon(r?.value as string),
      }));

  /*useEffect(() => {
    if (!!value) {
      const option = options.filter(opt => opt.value === value);
      if (!!option) setType(option);
      else
        setType({
          label: startCase(snakeCase(String(value)).split('_').join(' ')),
          value: value,
          icon: getIcon(value),
        });
    }
  }, [value, options]);*/

  return (
    <Flex pos={'relative'}>
      <Box w={'200px'} pos={'relative'}>
        <Select
          styles={selectStyles}
          options={options}
          isDisabled={isDisabled}
          value={filter(options, opt => opt.value === value)}
          onChange={opt => onChange?.(opt.value)}
          isLoading={isLoading}
          menuPortalTarget={document.body}
        />
      </Box>
    </Flex>
  );
};

interface AttributesProps {
  resource: AriksaSearchQueryResource;
  isEmpty?: boolean;

  onChange?();
}

interface AttributeListProps {
  resource: AriksaSearchQueryResource;
  clause: AriksaSearchQueryAttributeList;
  showOr?: boolean;
}

const AttributeList = (props: AttributeListProps) => {
  const { clause, resource, showOr } = props;
  const { updateSearchQuery } = useUpdateSearchQuery();

  const onAddAttr = () => {
    updateSearchQuery({
      op: 'set',
      field: 'attributes',
      build_id: clause.build.id,
      value: [
        ...(clause.attributes ?? []),
        {
          build: {
            id: queryResourceAttributeId(),
            value_options: { type: RHSSuggestionType.Str },
            operator_options: [],
            value_options_status: QueryOptionsStatus.unknown,
            operator_options_status: QueryOptionsStatus.unknown,
          },
        },
      ],
    });
  };

  const handleRemoveClause = () => {
    updateSearchQuery({
      op: 'set',
      field: 'clauses',
      build_id: resource.build.id,
      value:
        resource.clauses?.length === 1
          ? null
          : resource.clauses?.filter(c => c.build.id !== clause.build.id),
    });
  };

  return (
    <HStack spacing={3}>
      {showOr && (
        <Flex pos={'absolute'} left={'-50px'}>
          <Keyword text={'OR'} onClick={handleRemoveClause} />
        </Flex>
      )}

      {clause.attributes.map((attr, index) => {
        return (
          <>
            {index > 0 && <Keyword text={'AND'} />}
            <Attribute
              clause={clause}
              resource={resource}
              attribute={attr}
              key={attr.build.id}
            />
          </>
        );
      })}
      <Flex pl={6}>
        <AddConditionButton onClick={onAddAttr} label={'Add Condition'} />
      </Flex>
    </HStack>
  );
};

const AttributeClause = (props: AttributesProps) => {
  const { resource } = props;
  const { updateSearchQuery } = useUpdateSearchQuery();

  // adds one clause item
  const addAttributes = useCallback(() => {
    const clauses = [
      ...(resource.clauses ?? []),
      {
        build: {
          id: queryResourceAttributeListId(),
        },
        attributes: [
          {
            build: {
              id: queryResourceAttributeId(),
              value_options: { type: RHSSuggestionType.Str },
              operator_options: [],
              value_options_status: QueryOptionsStatus.unknown,
              operator_options_status: QueryOptionsStatus.unknown,
            },
          },
        ],
      },
    ];
    updateSearchQuery({
      op: 'set',
      field: 'clauses',
      build_id: resource.build.id!,
      value: clauses,
    });
  }, [resource.build.id, resource.clauses, updateSearchQuery]);

  return (
    <Stack spacing={10} justify={'start'} pos={'relative'}>
      {resource.clauses?.map((attrs, index) => (
        <>
          {/*{index > 0 && <Keyword text={'AND'} />}*/}
          {/*<Attribute resource={resource} attribute={attr} key={attr.build.id} />*/}
          <HStack>
            <AttributeList
              clause={attrs}
              resource={resource}
              showOr={index > 0}
            />
          </HStack>
        </>
      ))}

      <Flex pos={'absolute'} bottom={'-68px'} left={'-50px'} zIndex={100}>
        <AddConditionButton
          onClick={addAttributes}
          label={'Add OR Condition'}
        />
      </Flex>
    </Stack>
  );
};

interface AttributeProps {
  resource: AriksaSearchQueryResource;
  clause: AriksaSearchQueryAttributeList;
  attribute?: AriksaSearchQueryAttribute;
  isEmpty?: boolean;
}

const intoDateTimeOption = (time: string) => {
  if (!time) return null;
  if (time[0] === '-') {
    return {
      label: `${time.slice(1)} ago`.toLowerCase(),
      value: time,
    };
  } else {
    return {
      label: `${time} away`.toLowerCase(),
      value: time,
    };
  }
};

const intoOperatorOption = value => {
  const labelMap = {
    '=': 'is',
    '!=': 'is not',
    '<': 'less than',
    '>': 'greater than',
    '<=': 'less than equal to',
    '>=': 'greater than equal to',
  };
  return {
    label: labelMap[value] ?? value,
    value: value,
  };
};

const Attribute = (props: AttributeProps) => {
  const { resource, clause, attribute } = props;
  const [attributeValue, setAttributeValue] = useState<any>(
    attribute?.values?.[0],
  );
  const { getQuerySuggestions } = useGetQuerySuggestion();
  const queryBuildStatus = useAriksaQueryBuildContext();
  const { updateSearchQuery } = useUpdateSearchQuery();

  const attributeMap = useMemo(() => {
    const map = new Map<string, any>();
    const updateMap = attr => {
      map.set(attr.name, attr);
      attr.sub_tags?.forEach(updateMap);
    };

    resource.build.attribute_options.forEach(updateMap);

    return map;
  }, [resource.build.attribute_options]);

  const selectAttributeName = useCallback(
    (attributeValue: Partial<AriksaSearchQueryAttribute>) => {
      if (!attribute) {
        console.error('should not reach here');
        return;
      }

      updateSearchQuery(
        {
          op: 'set',
          field: 'name',
          build_id: attribute.build.id!,
          value: attributeValue.name,
        },
        false,
      );

      updateSearchQuery(
        {
          op: 'set',
          field: 'alias',
          build_id: attribute.build.id!,
          value: attributeValue.alias,
        },
        false,
      );

      if (!attributeMap.get(attributeValue.name ?? '')?.sub_tags?.length) {
        updateSearchQuery({
          op: 'set',
          field: 'operator',
          build_id: attribute.build.id!,
          value: undefined,
        });

        // reset the value and value_options
        updateSearchQuery({
          op: 'set',
          field: 'values',
          build_id: attribute.build.id!,
          value: undefined,
        });
      }
    },
    [attribute, attributeMap, updateSearchQuery],
  );

  const selectAttributeOperator = (operator: string) => {
    if (!attribute) {
      console.error('should not reach here');
      return;
    }
    updateSearchQuery({
      op: 'set',
      field: 'operator',
      build_id: attribute.build.id!,
      value: operator,
    });
  };

  const selectAttributeValue = (value: (string | boolean)[]) => {
    if (!attribute) {
      console.error('should not reach here');
      return;
    }
    updateSearchQuery({
      op: 'set',
      field: 'values',
      build_id: attribute.build.id!,
      value: value,
    });
  };

  useEffect(() => {
    if (
      attribute?.name &&
      attribute.build.operator_options_status === QueryOptionsStatus.unknown
    ) {
      getQuerySuggestions({
        for_field: 'attribute_operators',
        build_id: attribute?.build.id!,
      });
    }
  }, [attribute, attributeMap, getQuerySuggestions]);

  useEffect(() => {
    if (
      attribute?.operator &&
      attribute.build.value_options_status === QueryOptionsStatus.unknown
    ) {
      getQuerySuggestions({
        for_field: 'attribute_values',
        build_id: attribute?.build.id!,
      });
    }
  }, [attribute, getQuerySuggestions]);

  const GroupHeading = useCallback((props: GroupHeadingProps) => {
    const groupStyles = {
      color: 'red',
    };
    return (
      <Box style={groupStyles} py={2}>
        <components.GroupHeading {...props} />
      </Box>
    );
  }, []);

  const attributeOptions = useMemo(() => {
    return [
      {
        label: 'Ariksa Context',
        options: resource.build.attribute_options
          ?.filter(a => a.type === 'context')
          .map(a => ({
            label: a.alias,
            value: a,
          })),
      },
      {
        label: 'Cloud Tags',
        options: resource.build.attribute_options
          ?.filter(a => a.type === 'cloud_tag')
          .map(a => ({
            label: a.alias,
            value: a,
          })),
      },
      {
        label: 'Attribute',
        options: resource.build.attribute_options
          ?.filter(a => a.type === 'attribute')
          .map(a => ({
            label: a.alias,
            value: a,
          })),
      },
    ];
  }, [resource.build.attribute_options]);

  const isLeafAttribute = !attributeMap.get(attribute?.name ?? '')?.sub_tags
    ?.length;

  const attributeSelections = useMemo(() => {
    const attributeKeys = attribute?.name?.split('.') ?? [];
    const attributeStart = attributeMap.get(attributeKeys[0]);

    // recursively render child tag-options and values
    const SubTag = ({ attribute, size }) => {
      const name = attributeKeys.slice(0, size).join('.');
      // console.log(name, size, attributeKeys);
      const parentTag = attributeMap.get(name);
      if (!parentTag?.sub_tags?.length) {
        return null;
      }

      const childTagName =
        size < attributeKeys.length
          ? attributeKeys.slice(0, size + 1).join('.')
          : '';
      const childTag = attributeMap.get(childTagName);

      return (
        <>
          <Box w={'200px'}>
            <Select
              styles={selectStyles}
              options={parentTag?.sub_tags?.map(st => ({
                label: st.alias.split('.')?.[size],
                value: st,
              }))}
              value={
                childTag
                  ? {
                      label: childTag?.alias.split('.')?.[size] ?? '',
                      value: childTag,
                    }
                  : null
              }
              onChange={opt => {
                console.log(opt.value);
                selectAttributeName(opt.value);
              }}
              menuPortalTarget={document.body}
            />
          </Box>
          {size < attributeKeys.length && (
            <SubTag size={size + 1} attribute={attribute} />
          )}
        </>
      );
    };

    return (
      <HStack>
        <Box w={'200px'} pos={'relative'}>
          <Select
            styles={selectStyles}
            options={attributeOptions}
            value={
              attributeStart
                ? {
                    label: attributeStart?.alias ?? '',
                    value: attributeStart,
                  }
                : null
            }
            onChange={opt => {
              selectAttributeName(opt.value);
            }}
            components={{ GroupHeading }}
            menuPortalTarget={document.body}
          />
          <Flex pos={'absolute'} top={-0} right={0}>
            <RemoveItem
              buildId={clause.build.id}
              field={'attribute'}
              fieldBuildId={attribute?.build.id}
            />
          </Flex>
        </Box>
        <SubTag size={1} attribute={attribute} />
      </HStack>
    );
  }, [
    GroupHeading,
    attribute,
    attributeMap,
    attributeOptions,
    clause.build.id,
    selectAttributeName,
  ]);

  return (
    <HStack borderRadius={4} spacing={4}>
      <Box pos={'relative'}>{attributeSelections}</Box>

      {attribute?.name && isLeafAttribute && (
        <Box w={'150px'}>
          <Select
            styles={selectStyles}
            options={attribute?.build.operator_options?.map(e =>
              intoOperatorOption(e),
            )}
            value={
              attribute?.operator
                ? intoOperatorOption(attribute?.operator)
                : null
            }
            onChange={opt => selectAttributeOperator(opt.value)}
            isLoading={queryBuildStatus.isLoading(
              attribute.build.id,
              'attribute_operators',
            )}
            menuPortalTarget={document.body}
          />
        </Box>
      )}
      {attribute?.operator && isLeafAttribute && (
        <>
          {[RHSSuggestionType.Str, RHSSuggestionType.Number].includes(
            attribute?.build.value_options.type as any,
          ) && (
            <Box w={'100px'}>
              <Input
                {...textFieldStyles.input}
                onBlur={e => selectAttributeValue([e.target.value.trim()])}
                onChange={e => setAttributeValue(e.target.value)}
                value={attributeValue}
              />
            </Box>
          )}

          {attribute?.build.value_options.type === RHSSuggestionType.Bool && (
            <Box w={'150px'}>
              <Select
                styles={selectStyles}
                options={intoOptions(['True', 'False'])}
                value={intoOptions([attributeValue])[0]}
                onChange={opt => {
                  setAttributeValue(opt.value);
                  selectAttributeValue([opt.value]);
                }}
                isLoading={queryBuildStatus.isLoading(
                  attribute.build.id,
                  'attribute_values',
                )}
                menuPortalTarget={document.body}
              />
            </Box>
          )}

          {(attribute?.build.value_options.type === RHSSuggestionType.Enum ||
            attribute?.build.value_options.type === 'existence') && (
            <Box w={'150px'}>
              <Select
                styles={selectStyles}
                options={intoOptions(
                  attribute.build.value_options?.values ?? [],
                  l => l,
                )}
                value={{
                  label: toTitleCase(attributeValue),
                  value: attributeValue,
                }}
                onChange={opt => {
                  setAttributeValue(opt.value);
                  selectAttributeValue([opt.value]);
                }}
                isLoading={queryBuildStatus.isLoading(
                  attribute.build.id,
                  'attribute_values',
                )}
                menuPortalTarget={document.body}
              />
            </Box>
          )}
          {attribute?.build.value_options.type ===
            RHSSuggestionType.Datetime && (
            <Box w={'150px'}>
              <Select
                styles={selectStyles}
                options={(attribute.build.value_options?.values ?? []).map(
                  intoDateTimeOption,
                )}
                value={intoDateTimeOption(attributeValue)}
                onChange={opt => {
                  setAttributeValue(opt.value);
                  selectAttributeValue([opt.value]);
                }}
                isLoading={queryBuildStatus.isLoading(
                  attribute.build.id,
                  'attribute_values',
                )}
                menuPortalTarget={document.body}
              />
            </Box>
          )}
        </>
      )}
    </HStack>
  );
};

interface ResourceQueryProps {
  resource: AriksaSearchQueryResource;
}

export const ResourceAttributes = (props: ResourceQueryProps) => {
  const { resource } = props;
  const { getQuerySuggestions } = useGetQuerySuggestion();
  const { updateSearchQuery } = useUpdateSearchQuery();

  // update query using resource.build.id
  const onAddWith = useCallback(() => {
    updateSearchQuery({
      op: 'set',
      field: 'clauses',
      build_id: resource.build.id!,
      value: [
        {
          build: {
            id: queryResourceAttributeListId(),
          },
          attributes: [
            {
              build: {
                id: queryResourceAttributeId(),
                value_options: { type: RHSSuggestionType.Str },
                operator_options: [],
                value_options_status: QueryOptionsStatus.unknown,
                operator_options_status: QueryOptionsStatus.unknown,
              },
            },
          ],
        },
      ],
    });
  }, [resource.build.id, updateSearchQuery]);

  useDeepCompareEffect(() => {
    // get resource attributes options
    if (
      resource.name &&
      resource.build.attribute_options_status === QueryOptionsStatus.unknown
    ) {
      getQuerySuggestions({
        for_field: 'resource_attributes',
        build_id: resource.build.id,
      });
    }
  }, [getQuerySuggestions, resource]);

  return (
    <HStack spacing={3} align={'start'}>
      {resource.clauses && (
        <Keyword text={'WITH'} buildId={resource.build.id} field={'clauses'} />
      )}
      {resource.clauses && <AttributeClause resource={resource} />}
      {!resource.clauses && (
        <AddConditionButton onClick={onAddWith} label={'Add Condition'} />
      )}
    </HStack>
  );
};

interface ResourceRelationsProps {
  resource: AriksaSearchQueryResource;
}

export const ResourceRelations = (props: ResourceRelationsProps) => {
  const { resource } = props;
  const prevResource = usePrevious(resource);
  const { getQuerySuggestions } = useGetQuerySuggestion();

  useEffect(() => {
    // get resource attributes options
    if (
      resource.that &&
      resource.build.relationship_options_status === QueryOptionsStatus.unknown
    ) {
      getQuerySuggestions({
        for_field: 'resource_relationships',
        build_id: resource.build.id,
      });
    }
  }, [getQuerySuggestions, prevResource, resource]);

  return (
    <Box pos={'relative'}>
      <Box
        h={'calc(100% - 0px)'}
        w={'1px'}
        bg={'#eee'}
        pos={'absolute'}
        left={'26px'}
        top={-10}
      />
      <Stack spacing={10}>
        <Stack spacing={10} pl={10} pt={16}>
          {resource.that?.map(rel => {
            return (
              <ResourceThatRelation
                resource={resource}
                that={rel}
                key={rel.build.id}
              />
            );
          })}
        </Stack>
        <Stack spacing={0} pl={3}>
          <ResourceThatRelation resource={resource} isEmpty />
        </Stack>
      </Stack>
    </Box>
  );
};

interface ThatOption {
  resource: AriksaSearchQueryResource;
  that?: AriksaSearchQueryThat;
  // isEmpty is true for the last relation
  // selecting THAT clause adds a new relation
  isEmpty?: boolean;
}

// renders one element form "that" array
export const ResourceThatRelation = (props: ThatOption) => {
  const { resource, that, isEmpty } = props;
  const dispatch = useDispatch();
  const { updateSearchQuery } = useUpdateSearchQuery();
  const onAddThat = useCallback(() => {
    const that: AriksaSearchQueryThat[] = [...(resource.that ?? [])];

    if (isEmpty) {
      that.push({
        relationship: undefined,
        resource: undefined,
        build: {
          id: queryThatId(),
          resource_options: [],
          resource_options_status: QueryOptionsStatus.unknown,
        },
      });
    }
    updateSearchQuery({
      op: 'set',
      field: 'that',
      build_id: resource.build.id!,
      value: that,
    });
  }, [resource.that, resource.build.id, isEmpty, updateSearchQuery]);

  return (
    <HStack spacing={3} alignItems={'flex-start'} pos={'relative'}>
      {/* should show if THAT is selected*/}
      {!isEmpty && that && (
        <Box pos={'relative'}>
          <Box
            h={'1px'}
            w={'14px'}
            bg={'#eee'}
            pos={'absolute'}
            left={'-14px'}
            top={4}
          />
          <ResourceRelation resource={resource} that={that} />
        </Box>
      )}
      {isEmpty && (
        <Box pos={'relative'}>
          <Box
            h={'16px'}
            w={'1px'}
            bg={'#eee'}
            pos={'absolute'}
            left={'50%'}
            top={-4}
          />
          <AddConditionButton onClick={onAddThat} label={'Add Relationship'} />
        </Box>
      )}
    </HStack>
  );
};

interface ResourceRelationProps {
  resource: AriksaSearchQueryResource;
  that: AriksaSearchQueryThat;
}

export const ResourceRelation = (props: ResourceRelationProps) => {
  const { resource, that } = props;
  const dispatch = useDispatch();
  const queryBuildStatus = useAriksaQueryBuildContext();
  const { getQuerySuggestions } = useGetQuerySuggestion();
  const relationshipOptions = resource.build.relationship_options.map(el => ({
    label: el,
    value: el,
  }));
  const { updateSearchQuery } = useUpdateSearchQuery();

  const updateRelationship = (relationship: string) => {
    const value: AriksaQueryResourceRelationship = {
      build: {
        id: queryRelationshipId(),
        attribute_options: [],
        attribute_options_status: QueryOptionsStatus.unknown,
      },
      name: relationship,
    };

    updateSearchQuery({
      op: 'set',
      field: 'relationship',
      build_id: that.build.id,
      value,
    });
  };

  useEffect(() => {
    if (
      that.relationship &&
      that.build.resource_options_status === QueryOptionsStatus.unknown
    ) {
      getQuerySuggestions({
        build_id: that?.build.id,
        for_field: 'relationship_resources',
      });
    }
  }, [
    getQuerySuggestions,
    that.build.id,
    that.build.resource_options_status,
    that.relationship,
  ]);

  const updateRelationshipResource = (resource: string) => {
    const value = {
      name: resource,
      build: {
        id: queryResourceId(),
        attribute_options: [],
        attribute_options_status: QueryOptionsStatus.unknown,
        relationship_options: [],
        relationship_options_status: QueryOptionsStatus.unknown,
      },
    };

    updateSearchQuery({
      op: 'set',
      field: 'resource',
      build_id: that.build.id,
      value: value,
    });
  };

  return (
    <HStack alignItems={'flex-start'} spacing={3}>
      <Keyword
        text={'THAT'}
        buildId={resource.build.id}
        field={'that'}
        fieldBuildId={that.build.id}
      />
      {/*relationship query*/}
      <Box w={'150px'}>
        <Select
          styles={selectStyles}
          options={relationshipOptions}
          value={{
            label: that.relationship?.name,
            value: that.relationship?.name,
          }}
          onChange={opt => updateRelationship(opt.value)}
          isLoading={queryBuildStatus.isLoading(
            resource.build.id,
            'resource_relationships',
          )}
          menuPortalTarget={document.body}
        />
      </Box>

      {/*resource query*/}
      <Stack spacing={10}>
        <HStack spacing={3} alignItems={'flex-start'}>
          <ResourceType
            resourceTypes={
              !!that.build.resource_options.length
                ? that.build.resource_options
                : ([that.resource?.name ?? ''] as NativeResources[])
            }
            value={that.resource?.name as NativeResources}
            onChange={updateRelationshipResource}
            isLoading={queryBuildStatus.isLoading(
              that.build.id,
              'relationship_resources',
            )}
          />

          {that.resource && <ResourceAttributes resource={that.resource} />}
        </HStack>
        {that.resource && <ResourceRelations resource={that.resource} />}
      </Stack>
    </HStack>
  );
};

interface ShowProps {
  query: AriksaSearchQuery;
  action?: string;
  isLoading?: boolean;
}

// renders the full ariksa query
export const AriksaQueryComp = (props: ShowProps) => {
  const { query, action, isLoading } = props;
  const { resource } = query ?? {};
  const { updateSearchQuery } = useUpdateSearchQuery();

  // update query using query build_id
  const updateResourceType = useCallback(
    (resource: NativeResources) => {
      const value = {
        name: resource,
        build: {
          id: queryResourceId(),
          attribute_options: [],
          attribute_options_status: QueryOptionsStatus.unknown,
          relationship_options: [],
          relationship_options_status: QueryOptionsStatus.unknown,
        },
      };
      updateSearchQuery({
        op: 'set',
        field: 'resource',
        build_id: query.build.id,
        value: value,
      });
    },
    [updateSearchQuery, query.build.id],
  );

  const { getQuerySuggestions } = useGetQuerySuggestion();
  // get initial resources
  useEffect(() => {
    console.log('testt', query.build);
    if (query.build.resource_options_status === QueryOptionsStatus.unknown) {
      getQuerySuggestions({
        for_field: 'relationship_resources',
        build_id: query.build.id,
      });
    }
  }, [getQuerySuggestions, query]);

  return (
    <HStack alignItems={'flex-start'} spacing={3}>
      <Keyword text={'SHOW'} />
      <Stack spacing={10} w={'full'}>
        <HStack spacing={3} align={'start'}>
          <ResourceType
            resourceTypes={query.build.resource_options ?? []}
            value={resource?.name}
            onChange={updateResourceType}
            isDisabled={action !== 'Create'}
            isLoading={isLoading}
            // isLoading={
            //   query.build.resource_options_status === QueryOptionsStatus.loading
            // }
          />
          {resource && <ResourceAttributes resource={resource} />}
        </HStack>
        {resource && <ResourceRelations resource={resource} />}
      </Stack>
    </HStack>
  );
};

export const AriksaQueryBuilder: FC<IAriksaQueryBuilder> = props => {
  const {
    searchQuery,
    searchQuerySuggestion,
    suggestFor,
    policyById,
    queryById,
  } = useSelector(selectPolicyHub);
  const elementRef = useRef(null);

  return (
    <Box>
      <AriksaQueryBuildContext.Provider
        value={{
          isLoading: (buildId, field) =>
            searchQuerySuggestion.isLoading &&
            suggestFor.build_id === buildId &&
            suggestFor.for_field === field,
        }}
      >
        <Stack spacing={10} w={'full'} py={4} ref={elementRef}>
          <AriksaQueryComp
            query={searchQuery}
            action={props.action}
            isLoading={policyById.isLoading || queryById.isLoading}
          />
        </Stack>
      </AriksaQueryBuildContext.Provider>
    </Box>
  );
};

const AriksaQueryBuildContext = createContext<{
  isLoading: (buildId: AriksaQueryBuildId, field: SuggestForField) => boolean;
}>({
  isLoading: () => false,
});

const useAriksaQueryBuildContext = () => useContext(AriksaQueryBuildContext);
