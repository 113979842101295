import React, { useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { usePageContentContext } from 'components/Layout';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation';
import { useRemediation } from 'containers/PolicyHub/Remediation/Components/hooks/useRemediation';
import { selectRemediation } from 'containers/PolicyHub/Remediation/selector';
import { toTitleCase } from 'utils/string';

import { actions } from '../../slice';

import { RemediationTable } from './RemediationTable';

export const RemediationSideNav: React.FC = () => {
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState<SideNavMenuItemProps[]>([]);
  const { contentHeight } = usePageContentContext();
  const { currentCategory, remediationCategories } = useSelector(
    selectRemediation,
  );
  const { getRemediationCategories } = useRemediation();

  useEffect(() => {
    getRemediationCategories();
  }, [getRemediationCategories]);

  useEffect(() => {
    setMenuItems(
      map(remediationCategories.data, o => ({
        title: (
          <HStack justify="space-between" w="full">
            <Box>{toTitleCase(o?.category)}</Box>
            <Box>{o?.count}</Box>
          </HStack>
        ),
        key: o?.category as string,
        component: <RemediationTable />,
      })),
    );
  }, [dispatch, remediationCategories]);

  return (
    <Box h={contentHeight + 'px'} w="full">
      <SideNav
        menuItems={menuItems}
        navHeader="Categories"
        isLoading={remediationCategories.isLoading}
        showContentHeader={false}
        current={currentCategory}
        onChange={tab => dispatch(actions.updateRemediationCategory(tab))}
        showPrimaryInBold={false}
      />
    </Box>
  );
};
