import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, HStack, Text, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import Draggable from 'react-draggable';

import { WithParentSize } from 'components/Container/WithParentSize';

interface ITimeline {
  // a unix seconds timestamp
  startTime?: number;
  // timeSpan in seconds
  timeSpan?: number;
  // in seconds
  totalTimeSpan: number;
  // startTime is in seconds
  onChange?(startTime: number);
}

export const Timeline: FC<ITimeline> = props => {
  const { timeSpan = 4 * 60, totalTimeSpan, startTime, onChange } = props;
  const [startTimeObj, setStartTimeObj] = useState(dayjs(startTime ?? dayjs()));
  useEffect(() => {
    if (startTime) {
      setStartTimeObj(dayjs(startTime));
    }
  }, [startTime]);

  const handleDrag = useCallback(
    (e, data, maxLeft) => {
      const { x } = data;
      const startTime = -(totalTimeSpan - timeSpan) * (x / maxLeft);
      onChange?.(startTime);
    },
    [onChange, timeSpan, totalTimeSpan],
  );

  const endTimeObj = startTimeObj.add(timeSpan, 'seconds');
  const ticks = [startTimeObj, endTimeObj];

  return (
    <Box pos={'absolute'} bottom={0} w={'full'} h={'60px'}>
      <WithParentSize>
        {(h, w) => (
          <Draggable
            axis={'x'}
            bounds={{ left: 0, right: w - 100 }}
            onDrag={(e, data) => handleDrag(e, data, w - 100)}
          >
            <Box
              h={'full'}
              w={'100px'}
              border={'1px solid'}
              borderColor={'#aaa'}
              bg={'#aaa'}
              opacity={0.2}
              cursor={'pointer'}
              pos={'relative'}
            />
          </Draggable>
        )}
      </WithParentSize>
      <Box w={'full'} h={'2px'} bg={'primary'}>
        <HStack w={'full'} justify={'space-between'}>
          {sortBy(ticks, t => t.unix()).map(t => {
            return <Flex pos={'relative'}>{t.format('HH:mm:ss a')}</Flex>;
          })}
        </HStack>
      </Box>
    </Box>
  );
};
