import React, { useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/scan-analysis';
import { each, entries, sortBy } from 'lodash';
import { Text } from 'recharts';
import { useEc2Summary } from 'services/ScanAnalysis/useScanAnalysisItemService';

import { BarChart } from 'components/Visualization';

interface CVSSData {
  score: number;
  severity: number;
}

export const ByCvssScoreOnVulnerabilities: React.FC = () => {
  const { ec2Summary } = useEc2Summary(SummaryType.CvssDistribution);

  const [graphData, setGraphData] = useState<CVSSData[]>([]);

  useEffect(() => {
    const result: any[] = [];
    console.log(ec2Summary.data?.items);
    for (let i = 1; i <= 10; i++) {
      const severity = i;
      const point = { severity: `${severity - 1}-${severity}`, score: 0 };

      each(
        sortBy(entries(ec2Summary.data?.items ?? {}), e => e[0]).map(e => [
          parseFloat(e[0]),
          e[1],
        ]),
        ([k, v]) => {
          if (severity - 1 <= k && k <= severity - 0.001) {
            point.score += v;
          }
        },
      );
      result.push(point);
    }

    setGraphData(result);
  }, [ec2Summary.data?.items]);

  return (
    <BarChart
      data={graphData}
      xField={'severity'}
      yField={['score']}
      labelListKey={'score'}
      colors={[
        '#7eaa55',
        '#cadfb8',
        '#e5efdb',
        '#f6c142',
        '#f9d978',
        '#f1ccb1',
        '#eab38a',
        '#df8244',
        '#b02318',
        '#b02318',
      ]}
      tooltip={false}
      styles={{
        yAxis: {
          label: (
            <Text dx={10} dy={110} offset={0} angle={-90}>
              Count
            </Text>
          ),
        },
        xAxis: {
          height: 50,
          label: (
            <Text offset={0} dx={190} dy={210}>
              Score
            </Text>
          ),
        },
        root: {},
        bar: {
          barSize: 14,
        },
        barChart: {
          margin: {
            top: 20,
            right: 20,
            left: 0,
            bottom: 0,
          },
        },
        legend: {
          margin: {
            left: -10,
          },
        },
      }}
      legend={false}
    />
  );
};
