import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyExceptionResourceIcon = props => (
  <Icon viewBox="0 0 440 522" h="full" w="full" color="inherit" {...props}>
    <g transform="translate(-2250 -1173)">
      <g>
        <path
          d="M2352.97 1332.8 2397.84 1332.8C2404.04 1332.8 2409.06 1327.78 2409.06 1321.6 2409.06 1315.42 2404.04 1310.4 2397.84 1310.4L2352.97 1310.4C2346.77 1310.4 2341.75 1315.42 2341.75 1321.6 2341.75 1327.78 2346.77 1332.8 2352.97 1332.8ZM2610.13 1317.29C2609.56 1315.93 2608.74 1314.7 2607.71 1313.67L2473.09 1179.29C2472.07 1178.26 2470.84 1177.44 2469.48 1176.87 2468.12 1176.31 2466.66 1176 2465.16 1176L2319.31 1176C2282.15 1176.04 2252.04 1206.1 2252 1243.2L2252 1556.8C2252.04 1593.9 2282.15 1623.96 2319.31 1624L2543.69 1624C2580.85 1623.96 2610.96 1593.9 2611 1556.8L2611 1321.6C2611 1320.1 2610.7 1318.64 2610.13 1317.29ZM2476.38 1214.24 2572.7 1310.4 2506.28 1310.4C2489.77 1310.39 2476.39 1297.03 2476.38 1280.54L2476.38 1214.24ZM2588.56 1556.8C2588.53 1581.53 2568.46 1601.57 2543.69 1601.6L2319.31 1601.6C2294.54 1601.57 2274.47 1581.53 2274.44 1556.8L2274.44 1243.2C2274.47 1218.47 2294.54 1198.43 2319.31 1198.4L2453.94 1198.4 2453.94 1280.54C2453.97 1309.39 2477.39 1332.76 2506.28 1332.8L2588.56 1332.8 2588.56 1556.8ZM2341.75 1411.2C2341.75 1417.38 2346.77 1422.4 2352.97 1422.4L2510.03 1422.4C2516.23 1422.4 2521.25 1417.38 2521.25 1411.2 2521.25 1405.02 2516.23 1400 2510.03 1400L2352.97 1400C2346.77 1400 2341.75 1405.02 2341.75 1411.2ZM2510.03 1489.6 2352.97 1489.6C2346.77 1489.6 2341.75 1494.62 2341.75 1500.8 2341.75 1506.98 2346.77 1512 2352.97 1512L2510.03 1512C2516.23 1512 2521.25 1506.98 2521.25 1500.8 2521.25 1494.62 2516.23 1489.6 2510.03 1489.6Z"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity="1"
        />
        <path
          d="M2398.31 1548.86C2398.31 1468.22 2463.23 1402.86 2543.31 1402.86 2623.4 1402.86 2688.31 1468.22 2688.31 1548.86 2688.31 1629.49 2623.4 1694.86 2543.31 1694.86 2463.23 1694.86 2398.31 1629.49 2398.31 1548.86Z"
          fill="#FFFFFF"
          fillRule="evenodd"
          fillOpacity="1"
        />
        <path
          d="M2435 1612.76 2435 1500 2542 1556.38 2542 1666 2435 1612.76Z"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity="0.701961"
        />
        <path
          d="M2665 1500 2665 1612.76 2557 1666 2557 1556.38 2665 1500Z"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity="0.4"
        />
        <path
          d="M2549.5 1431 2659 1487.5 2549.5 1544 2440 1487.5 2549.5 1431Z"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity="1"
        />
      </g>
    </g>
  </Icon>
);
