import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { errorToast } from 'app/components';
import { selectUser } from 'containers/App/selectors';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

import { selectGroups } from '../selectors';
import { actions } from '../slice';

import { GroupDetails } from './GroupDetails';

export interface IEditGroup {}

export const EditGroup = (props: IEditGroup) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info } = useSelector(selectUser);
  const { groupUpdate, loadGroupById } = useSelector(selectGroups);
  const { success: updated, failure } = useTrackApiCall(groupUpdate);
  const params = useParams<{ group_id: string }>();

  useEffect(() => {
    if (params.group_id) {
      dispatch(
        actions.getGroupById({
          id: params.group_id,
          organization: info.org_id,
        }),
      );
    }
  }, [dispatch, info.org_id, params]);

  const handleSubmit = data => {
    console.log(data);
    const {
      roles = [],
      admin,
      name,
      accounts,
      users,
      auto_add_accounts,
    } = data;

    let admins: any = [];
    if (admin) {
      admins = [{ ...admin.value }];
    }
    const payload = {
      organization: info.org_id,
      user_group: {
        ...groupUpdate.payload,
        admins,
        roles: roles.map(r => r.value),
        name,
        account_ids: accounts.map(a => a.value.uuid!) ?? [],
        users: users.map(u => ({ id: u.value.id })),
        auto_add_accounts,
      },
    };

    dispatch(actions.updateGroup(payload));
  };

  useEffect(() => {
    if (updated) {
      // console.log(updated);

      navigate('/setup/groups');
    }

    if (failure) {
      errorToast({
        title: 'Failed to update group',
        description: groupUpdate.error,
      });
    }
  }, [navigate, updated, failure, groupUpdate.error]);

  const formData = {
    name: groupUpdate.payload.name,
    admins: groupUpdate?.payload?.admins,
    roles: groupUpdate?.payload?.roles,
    account_ids: groupUpdate?.payload.account_ids,
    user_ids: groupUpdate?.payload.users?.map(u => u.id),
    auto_add_accounts: groupUpdate?.payload.auto_add_accounts,
  };

  return (
    <GroupDetails
      isDataLoading={loadGroupById.loading}
      isLoading={groupUpdate.loading}
      formData={groupUpdate?.payload}
      onSubmit={handleSubmit}
      action={'update'}
    />
  );
};
