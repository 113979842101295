import React from 'react';

import {
  Box,
  Link,
  Stack,
  Image,
  Center,
  HStack,
  Text,
} from '@chakra-ui/react';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';
import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

import github_1 from './GitHub1.png';
import github_2 from './GitHub2.png';
import github_3 from './GitHub3.png';
import github_4 from './GitHub4.png';
import github_5 from './GitHub5.png';
import github_6 from './GitHub6.png';

interface Props {
  onClose: () => void;
}

export const GitHubTokenHelp = (props: Props) => {
  const { onClose } = props;

  const renderImage = (src, alt) => (
    <Center>
      <Box border="1px solid" borderRadius={0} borderColor="gray.200">
        <Image src={src} w="full" fit="cover" alt={alt} />
      </Box>
    </Center>
  );

  return (
    <Stack h="full">
      <HelpHeader>Get GitHub Access Token</HelpHeader>
      <Stack px={4} spacing={6}>
        <HStack align={'start'}>
          <HelpCount count={1} />
          <Box>
            Log in to{' '}
            <Link color="primary" href="https://github.com/login" isExternal>
              GitHub
            </Link>
            .
          </Box>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={2} />
          <Stack spacing={4}>
            <Box>
              Go to settings {'->'} Developer Settings {'->'} Personal Access
              Tokens {'->'} Fine-grained tokens. Then click on Generate new
              token button.
            </Box>
            {renderImage(github_1, 'github_1')}
            {renderImage(github_2, 'github_2')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={3} />
          <Stack spacing={4}>
            <Text>
              Provide token name, Expiration. Under Repository Access choose
              option (public, all, or one repository).
            </Text>
            {renderImage(github_3, 'github_3')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={3} />
          <Stack spacing={4}>
            <Text>
              Under Repository Permissions, provide{' '}
              <Text as="b">Read-Only</Text> access for{' '}
              <Text as="b">Administrations</Text>, <Text as="b">Contents</Text>,{' '}
              <Text as="b">Metadata</Text>, <Text as="b">Variables</Text> and{' '}
              <Text as="b">Webhooks</Text>.
            </Text>
            {renderImage(github_4, 'github_4')}
            {renderImage(github_5, 'github_5')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={4} />
          <Stack spacing={4}>
            <Box>Click on Generate Token button.</Box>
            {renderImage(github_6, 'github_6')}
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  );
};
