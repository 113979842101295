import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PrincipalAccessIcon = props => (
  <Icon viewBox="0 0 20 20" h="full" w="full" color={'inherit'} {...props}>
    <path d="M15,17a1,1,0,0,1-.71-1.71L17.59,12,14.29,8.71a1,1,0,0,1,1.41-1.41l4,4a1,1,0,0,1,0,1.41l-4,4A1,1,0,0,1,15,17Z" />
    <path d="M19 13H9a1 1 0 0 1 0-2H19a1 1 0 0 1 0 2zM5 20a1 1 0 0 1-1-1V5A1 1 0 0 1 6 5V19A1 1 0 0 1 5 20z" />
  </Icon>
);
