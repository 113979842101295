import React, { useMemo } from 'react';

import { ToDoStatus } from '@ariksa/profile/api';
import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map, values } from 'lodash';
import { useSelector } from 'react-redux';

import { sortByOrderProvided } from 'components/DataDisplay';
import { CheckmarkIcon } from 'components/Icons';
import { useMyTasks } from 'components/Navigation/NavBar/TopNavBar/RightComponent/MyTasks/useMyTasks';
import { selectApp } from 'containers/App/selectors';
import { DashboardOverviewTooltip } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { selectSharedState } from 'containers/SharedState/selectors';

export const Explore: React.FC = () => {
  const { journey } = useSelector(selectSharedState);
  const { myTasks } = journey;
  const { myTasksMapping, taskOrder } = useMyTasks();
  const { cloudAccounts } = useSelector(selectApp);

  const list = useMemo(
    () =>
      map(sortByOrderProvided(values(myTasks.data), taskOrder), o => ({
        title: o.name,
        ...myTasksMapping?.[o.id],
        ...o,
      })),
    [myTasks.data, myTasksMapping, taskOrder],
  );

  const renderDescription = item => (
    <HStack spacing={4}>
      <Box boxSize={6} color="primary">
        <Center>{item.icon}</Center>
      </Box>
      <Box flex={1}>{item.title}</Box>
    </HStack>
  );

  return (
    <Stack h="full" fontSize="md" spacing={1} overflow="scroll" pt={6}>
      {list.map(o => (
        <HStack
          spacing={4}
          py={3}
          px={4}
          _hover={{ bg: 'gray.50' }}
          borderRadius={6}
          onClick={() =>
            !!o?.description &&
            !!cloudAccounts.data?.length &&
            o?.onClickFooter()
          }
          cursor={
            !!o?.description && !!cloudAccounts.data?.length
              ? 'pointer'
              : 'default'
          }
          justify="space-between"
        >
          {!!o.description ? (
            <DashboardOverviewTooltip
              {...o}
              button={renderDescription(o)}
              tooltip={
                <Stack>
                  {map(myTasksMapping?.[o?.id]?.description, d => (
                    <Box>{d}</Box>
                  ))}
                </Stack>
              }
            />
          ) : (
            renderDescription(o)
          )}
          <Box boxSize={4}>
            <Center>
              {o?.user_todo_status === ToDoStatus.Complete ? (
                <CheckmarkIcon color="green.300" />
              ) : (
                ''
              )}
            </Center>
          </Box>
        </HStack>
      ))}
    </Stack>
  );
};
