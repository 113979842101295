import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AlertWorkflowResponse } from '@ariksa/notification';
import { DataTypeForES } from '@ariksa/reporting';
import {
  Box,
  Center,
  Flex,
  HStack,
  Input,
  Spinner,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import { identity } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon, Tag } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import {
  CancelButton,
  PrimaryButton,
  textFieldStyles,
} from 'components/DataEntry';
import { AnalyticsIcon, ElasticsearchIcon, ExportIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { successToast } from 'components/Toast';
import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';
import { useSharedApis } from 'containers/SharedState/hooks/useSharedApis';
import { selectSharedState } from 'containers/SharedState/selectors';

interface IExportWorkflowAlertsModal {
  workflow: AlertWorkflowResponse;
  isOpen: boolean;
  onClose();
}

export const ExportWorkflowAlertsModal: FC<IExportWorkflowAlertsModal> = props => {
  const { getBlueprints } = useSharedApis();
  const { blueprints } = useSelector(selectSharedState);
  const { workflowAlertCounts } = useSelector(selectAlertWorkflow);
  const { isOpen, onClose, workflow } = props;
  const [confirmInput, updateConfirmInput] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getBlueprints();
  }, [getBlueprints]);

  useEffect(() => {
    dispatch(
      actions.getWorkflowAlertCounts({ q: { workflowId: workflow.uuid } }),
    );
  }, [dispatch, workflow.uuid]);

  const exportAlerts = useCallback(() => {
    const { uuid } = workflow;

    dispatch(
      actions.exportAlert({
        q: {
          eSLogRequest: {
            workflow_id: uuid,
          },
        },
        onSuccess: () => {
          successToast({
            title: 'Alert export initiated',
          });
          onClose();
        },
      }),
    );
  }, [dispatch, onClose, workflow]);

  const confirmed = useMemo(() => {
    return confirmInput === 'CONFIRM';
  }, [confirmInput]);

  const workflowPolicies = useMemo(() => {
    return workflow.policy
      ?.map(p => blueprints.data.find(b => b.id === p.policy_id))
      .filter(identity);
  }, [blueprints.data, workflow.policy]);

  console.log(workflowAlertCounts);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      styles={{ content: { zIndex: 10000 } }}
      header={
        <PageHeaderWithIcon
          label={'Export Alerts'}
          icon={<ExportIcon />}
          reversed
        />
      }
      body={
        <Stack spacing={6}>
          <Stack>
            <Box>Workflow currently attached to (Blueprint / Environment):</Box>
            {blueprints.isLoading && (
              <Box>
                <CustomSpinner loading={blueprints.isLoading} />
              </Box>
            )}
            {blueprints.isSuccess && (
              <Flex>
                {workflowPolicies?.map(wp => (
                  <Tag
                    label={wp?.name}
                    key={wp?.id}
                    styles={{ tag: { mr: 1 } }}
                  />
                ))}
              </Flex>
            )}
          </Stack>
          <DetailsField
            align={'center'}
            spacing={4}
            label={'Total Open Alerts'}
            value={
              <Box>
                {workflowAlertCounts.isLoading ? (
                  <CustomSpinner
                    loading={workflowAlertCounts.isLoading}
                    justify={'start'}
                  />
                ) : (
                  workflowAlertCounts.data.open_count ?? '-'
                )}
              </Box>
            }
          />
          <DetailsField
            align={'center'}
            spacing={4}
            label={'Total Resolved Alerts'}
            value={
              <Box>
                {workflowAlertCounts.isLoading ? (
                  <CustomSpinner
                    loading={workflowAlertCounts.isLoading}
                    justify={'start'}
                  />
                ) : (
                  workflowAlertCounts.data.resolved_count ?? '-'
                )}
              </Box>
            }
          />
          <DetailsField
            align={'center'}
            spacing={4}
            label={'Actions'}
            value={
              <Center w={'28px'}>
                <ElasticsearchIcon />
              </Center>
            }
          />
          <DetailsField
            align={'center'}
            spacing={4}
            label="Please type 'CONFIRM'"
            value={
              <Input
                w={200}
                {...textFieldStyles.input}
                onChange={e => {
                  updateConfirmInput(e.target?.value?.toUpperCase());
                }}
                color={confirmed ? 'green' : 'default'}
                borderColor={confirmed ? 'green' : 'default'}
                value={confirmInput}
              />
            }
          />
          <Text color={'primary'}>
            NOTE: Ariksa will manually export all open alerts that meet workflow
            criteria to the specified targets. Duplicate alerts will be
            automatically suppressed from export
          </Text>
          <HStack justify={'space-between'} pt={8}>
            <CancelButton onClick={onClose} />
            <PrimaryButton
              isDisabled={!confirmed}
              onClick={() => exportAlerts()}
            >
              Export
            </PrimaryButton>
          </HStack>
        </Stack>
      }
    />
  );
};
