import { inlineObjectFieldStyles } from 'app/components/DataEntry/Form/styles';
import { FormStyles } from 'app/components/DataEntry/Form/types';

export const formStyles: FormStyles = {
  form: { container: { w: 'full', maxW: 'formWidth' } },
  objectField: {
    objectContainer: {
      ...inlineObjectFieldStyles.objectContainer,
      direction: 'row',
    },
    propertyContainer: [{ w: '90%' }],
  },
  textAreaField: {
    textArea: {
      height: 48,
    },
  },
};

export const editFormCardStyles = { card: { pl: 32 } };
