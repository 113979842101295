/**
 *
 * Jit Requests
 *
 */
import React from 'react';

import { HStack, Stack } from '@chakra-ui/react';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { PermissionsActionsIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { JitRequestsTable } from 'containers/Visibility/JitRequests/Components/JitRequestsTable';
import { useInjector } from 'utils/inject';

import { jitRequestsSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const JitRequests = () => {
  useInjector(sliceKey, reducer, jitRequestsSaga);
  const { contentHeight } = usePageContentContext();

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <HStack w="full" justify="space-between">
          <PageHeaderWithIcon
            label="JIT Requests"
            icon={<PermissionsActionsIcon />}
          />
        </HStack>
        <JitRequestsTable />
      </Stack>
    </Card>
  );
};
