import React, { ReactNode } from 'react';

import { Stack, Box, HStack, Input } from '@chakra-ui/react';

import { textFieldStyles } from 'components/DataEntry';
import {
  CheckmarkCircleOutlineIcon,
  CloseCircleOutlineIcon,
} from 'components/index';

export interface Props {
  confirmationText: string;
  label: ReactNode;
  command: string;
  setCommand: (val: string) => void;
}

export const ConfirmationInput = (props: Props) => {
  const { confirmationText, label, command, setCommand } = props;

  return (
    <Stack pt={2}>
      <Box>{label}</Box>
      <HStack>
        <Box w={80}>
          <Input
            placeholder={confirmationText}
            onChange={e => setCommand(e.target.value)}
            value={command}
            color={
              !!command
                ? command === confirmationText
                  ? 'green'
                  : 'red'
                : 'inherit'
            }
            {...textFieldStyles?.input}
          />
        </Box>
        <Box w={6}>
          {command === '' ? (
            <Box />
          ) : command === confirmationText ? (
            <CheckmarkCircleOutlineIcon color="green" />
          ) : (
            <CloseCircleOutlineIcon color="red" />
          )}
        </Box>
      </HStack>
    </Stack>
  );
};
