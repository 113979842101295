import React, { useState } from 'react';

import { Steps } from 'app/components/Navigation/Steps';
import { Card } from 'components/DataDisplay/Card';

import { AdminConfiguration } from './Steps/AdminConfiguration';
import { OrganizationOverview } from './Steps/OrganizationOverview';

interface OrgDetailsProps {
  action: 'Create' | 'Update';
  organization;
  admin_user;
  isLoading: boolean;
  isOrgDataLoading?: boolean;

  updateOrgDetailsFieldAction(data);
  updateOrgUserDetailFieldAction(data);

  handleOrgFormSubmit?();
  handleUserFormSubmit?();
  handleSubmit?();
  handleReset();
}

export function OrgDetails(props: OrgDetailsProps) {
  const {
    action,
    isLoading,
    isOrgDataLoading,
    organization,
    admin_user,
    updateOrgDetailsFieldAction,
    updateOrgUserDetailFieldAction,
    handleUserFormSubmit,
    handleOrgFormSubmit,
    handleReset,
    handleSubmit,
  } = props;
  const [currentStep, setCurrentStep] = useState(0);

  const handleFormSubmit = data => {
    if (currentStep === 0) {
      handleOrgFormSubmit?.();
    }

    if (currentStep === 1) {
      handleUserFormSubmit?.();
      handleSubmit?.();
    }

    if (currentStep < 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleFormReset = () => {
    handleReset();
    setCurrentStep(0);
  };

  const handleFormBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const stepsOptions = [
    {
      label: 'Organization Details',
      component: () => (
        <OrganizationOverview
          action={action}
          isLoadingData={isOrgDataLoading}
          organization={organization}
          updateOrgDetailsFieldAction={updateOrgDetailsFieldAction}
          handleSubmit={handleFormSubmit}
        />
      ),
    },
    {
      label: 'Admin Details',
      component: () => (
        <AdminConfiguration
          action={action}
          isLoading={isLoading}
          org_user={admin_user}
          handleReset={handleFormReset}
          updateOrgUserFieldAction={updateOrgUserDetailFieldAction}
          handleSubmit={handleFormSubmit}
          handleBack={handleFormBack}
        />
      ),
    },
  ];

  return (
    <Card styles={{ cardContent: { pl: 32 } }}>
      <Steps current={currentStep} steps={stepsOptions} />
    </Card>
  );
}
