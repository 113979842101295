import React, { FC, useEffect, useState } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { Accordion, Box } from '@chakra-ui/react';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceAccessPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ResourceAccessPatternAccordion';
import { renderHighPrivilegesStackedCell } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useRedirectToGraph } from 'containers/ActiveCloudResource/hooks/useRedirectToGraph';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

export const IAMUserAccess: FC = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { canAccess } = canAccessSummary;
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const { redirectToGraph } = useRedirectToGraph();

  const [internal, setInternal] = useState<Dict<any>[]>([]);
  const [external, setExternal] = useState<Dict<any>[]>([]);

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.CanAccess);
  }, [getCanAccessSummary]);

  useEffect(() => {
    setInternal(filter(canAccess.data, o => !o?.external));
    setExternal(filter(canAccess.data, o => o?.external));
  }, [canAccess.data]);

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: 'permission_entity_name',
    render: ({ row, value }) => (
      <WithResourceIcon resourceType={row?.permission_entity_native_name}>
        <StackedCell
          upper={value}
          lower={
            getCloudNativeName(row?.permission_entity_native_name) +
            ' / ' +
            getCloudAgnosticName(row?.permission_entity_agnostic_name)
          }
        />
      </WithResourceIcon>
    ),
  };

  const managedType = {
    header: 'Type',
    accessor: 'permission_entity_managed_type',
    align: 'left',
  };
  const highPrivileges_service = [
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    {
      header: 'Services',
      accessor: 'total_service_count',
      align: 'left',
    },
  ];

  const internalColumns = [
    name,
    managedType,
    ...highPrivileges_service,
    {
      header: '# Excess / # Permissions',
      align: 'left',
      render: ({ row }) =>
        (row?.show_excess_counts
          ? (row?.total_permission_count ?? 0) -
            (row?.used_permission_count ?? 0)
          : '-') +
        ' / ' +
        (row?.total_permission_count ?? 0),
    },
  ];
  const externalColumns = [
    name,
    managedType,
    {
      header: 'Account',
      accessor: 'permission_entity_account',
      render: ({ value }) => <CloudAccountName accountId={value} />,
      align: 'left',
    },
    ...highPrivileges_service,
    {
      header: '# Permissions',
      accessor: 'total_permission_count',
      align: 'left',
    },
  ];

  console.log(internal);

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <ResourceAccessPatternAccordion />

        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="PRIVILEGES FOR THIS IDENTITY TO MANAGE INTERNAL RESOURCES"
          totalCount={internal?.length}
          onClickDownload={() => {}}
          isLoading={canAccess.isLoading}
          panel={
            <Table
              columns={internalColumns}
              data={internal}
              isLoading={canAccess.isLoading}
            />
          }
          iconBGColor="primary"
          onShowGraph={() => redirectToGraph('access', MetadataTabs.Access)}
        />
        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="PRIVILEGES FOR THIS IDENTITY TO MANAGE EXTERNAL RESOURCES"
          totalCount={external?.length}
          isLoading={canAccess.isLoading}
          onClickDownload={() => {}}
          panel={
            <Table
              columns={externalColumns}
              data={external}
              isLoading={canAccess.isLoading}
            />
          }
          iconBGColor="low"
          onShowGraph={() => redirectToGraph('access')}
        />
      </Accordion>
    </>
  );
};
