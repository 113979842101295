/**
 *
 * Steps
 *
 */
import React, { FC, useMemo } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import merge from 'lodash/merge';

import { Step } from './Step';
import { defaultStepsStyles } from './styles';
import { StepProps, StepsStyles } from './types';

interface StepsProps {
  steps: StepProps[];
  current: number;
  vertical?: boolean;
  styles?: StepsStyles;
  overwriteDefaultStyles?: boolean;
  onChange?: (current: number) => void;
  handleSubmit?: (data: any) => void;
}

export const Steps: FC<StepsProps> = props => {
  const {
    steps,
    vertical = true,
    current,
    onChange,
    styles,
    overwriteDefaultStyles,
  } = props;

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStepsStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  const onStepClick = (next: number) => {
    if (onChange && current !== next) {
      onChange(next);
    }
  };

  return (
    <Stack direction={vertical ? 'row' : 'column'} {...baseStyles?.root}>
      <Box {...baseStyles?.rootStepsWrapper}>
        <Stack
          direction={vertical ? 'column' : 'row'}
          {...baseStyles?.stepsWrapper}
        >
          {map(steps, (each, index) =>
            !isEmpty(each) ? (
              <Step
                {...each}
                vertical={vertical}
                stepIndex={index}
                active={index === current}
                completed={index < current}
                key={`${index}-step`}
                isLast={index === steps.length - 1}
                isFirst={index === 0}
                onStepClick={onChange && onStepClick}
              />
            ) : (
              ''
            ),
          )}
        </Stack>
      </Box>

      <Box {...baseStyles?.componentWrapper} mx={vertical ? '' : 'auto'}>
        {steps[current]?.description && (
          <Box {...baseStyles?.description}>{steps[current]?.description}</Box>
        )}

        <Box w={'full'}>{steps[current]?.component?.()}</Box>
      </Box>
    </Stack>
  );
};
