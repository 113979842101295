import { CloudAccountStatus } from '@ariksa/cloud-account';
import { EnvironmentRead } from '@ariksa/compliance-policies';
import {
  CloudInsight,
  CloudInsightApiGetCloudInsightsRequest,
  ResourceMapping,
} from '@ariksa/inventory-core/api';
import { identity } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { authOptions } from 'api/auth';
import {
  UserAuthService,
  UserAuthServiceListRequest,
  UserAuthServiceListResponse,
} from 'api/auth/api.pb';
import {
  CloudAccountService,
  ComplianceService,
  InventoryService,
} from 'app/api';
import { errorToast } from 'components/Toast';

import { actions } from './slice';

export function* appSaga() {
  yield takeLatest(actions.loadOnboardedCloudAccounts.type, doGetAccounts);
  yield takeLatestAction(actions.getUserRoles, doGetUserRoles);
  yield takeLatestAction(actions.loadResourceTypes, doGetResourceTypes);
  yield takeLatestAction(actions.loadEnvironments, doLoadEnvironments);
  yield takeLatest(actions.loadEnvAndAccounts, doLoadEnvAndAccounts);
  yield takeLatestAction(actions.getCloudInsights, doGetCloudInsights);
}

export function* doLoadEnvAndAccounts() {
  yield put(actions.loadEnvironments({}));
  yield put(actions.loadOnboardedCloudAccounts());
}

export function* doLoadEnvironments(ctx: QueryContext<EnvironmentRead[]>) {
  yield call(ctx.fetch, () => ComplianceService.Environment.getEnvironments(), {
    errorMsg: 'Failed to get environments!',
  });
}

export function* doGetUserRoles(
  ctx: QueryContext<UserAuthServiceListResponse, UserAuthServiceListRequest>,
) {
  yield call(ctx.fetch, () => UserAuthService.List(ctx.params, authOptions()), {
    map: identity,
  });
}

export function* doGetAccounts() {
  try {
    const res = yield call(() =>
      CloudAccountService.CloudAccount.getCloudAccounts({
        //status: CloudAccountStatus.Success,
        members: true,
        page: 1,
        size: 100,
      }),
    );

    yield put(actions.loadOnboardedCloudAccountsSuccess(res.data.items));
  } catch (err) {
    errorToast({
      title: `Failed to get accounts.`,
      description: (err as any).message,
    });
    yield put(actions.loadOnboardedCloudAccountsError(err));
  }
}

function* doGetResourceTypes(ctx: QueryContext<ResourceMapping[]>) {
  yield call(ctx.fetch, () => InventoryService.Resource.getResourceMapping());
}

function* doGetCloudInsights(
  ctx: QueryContext<CloudInsight[], CloudInsightApiGetCloudInsightsRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.CloudInsight.getCloudInsights(ctx.params),
  );
}
