import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PieChartIcon = props => (
  <Icon color="inherit" viewBox="0 0 512 512" h="full" w="full" {...props}>
    <g>
      <g>
        <path
          d="M256,0C114.84,0,0,114.84,0,256s114.84,256,256,256s256-114.84,256-256S397.16,0,256,0z M452.401,126.217L269.925,235.703
			l36.476-209.735C367.104,39.258,419.108,76.003,452.401,126.217z M256,491.52C126.135,491.52,20.48,385.865,20.48,256
			S126.135,20.48,256,20.48c10.243,0,20.315,0.73,30.216,2.005l-40.306,231.76c-0.179,1.025-0.171,2.046-0.044,3.047
			c0.024,0.191,0.105,0.37,0.14,0.56c0.191,1.03,0.546,2,1.038,2.921c0.085,0.16,0.081,0.339,0.176,0.496
			c0.108,0.179,0.263,0.305,0.378,0.475c0.245,0.358,0.516,0.684,0.806,1.01c0.344,0.387,0.701,0.745,1.091,1.07
			c0.156,0.13,0.265,0.299,0.43,0.42l189.31,139.493C396.024,457.225,329.956,491.52,256,491.52z M274.448,256.874L463,143.741
			c18.181,33.39,28.52,71.64,28.52,112.259c0,48.57-14.785,93.749-40.084,131.285L274.448,256.874z"
        />
      </g>
    </g>
  </Icon>
);
