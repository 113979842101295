import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const useInfoLoader = () => {
  const { resourceAlerts } = useSelector(selectSecurityGraphNext);
  const isLoadingInfo = useMemo(() => {
    return resourceAlerts.isLoading;
  }, [resourceAlerts.isLoading]);

  return {
    isLoadingInfo,
  };
};
