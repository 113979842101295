import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { find } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { actions } from 'containers/Inventory/Whitelist/slice';

import { toTitleCase } from '../../../../../../../../utils/string';

interface Props {}

export const StatusComponent: FC<Props> = () => {
  const { formData, whitelistStatus } = useSelector(selectWhitelist);
  const { updateFormData, hasEC2Criteria } = useWhitelistContext();
  const dispatch = useDispatch();

  const [status, setStatus] = useState<Record<string, any>>({});
  const [statusOptions, setStatusOptions] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const payload = hasEC2Criteria
      ? { resourceType: NativeResources.Ec2Instance }
      : {};
    dispatch(actions.getAllowListStatus({ q: payload }));
  }, [dispatch, hasEC2Criteria]);

  useEffect(() => {
    setStatusOptions(
      map(whitelistStatus.data, o => ({ label: toTitleCase(o), value: o })),
    );
  }, [whitelistStatus.data]);

  useEffect(() => {
    setStatus(
      find(statusOptions, o => o?.value === formData.status) ??
        statusOptions[0],
    );
  }, [formData.status, statusOptions]);

  return (
    <Stack isInline w="full">
      <Box whiteSpace="nowrap" pt={1}>
        Allow list status is
      </Box>
      <Box minW={64}>
        <Select
          options={statusOptions}
          value={status}
          isLoading={whitelistStatus.isLoading}
          onChange={selected => {
            setStatus(selected);
            updateFormData('status', selected);
          }}
        />
      </Box>
    </Stack>
  );
};
