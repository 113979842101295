import React, { FC } from 'react';

import { GridItem } from '@chakra-ui/react';

import { MetadataField, MetadataFieldProps } from './MetadataField';

export interface MetadataGridFieldProps extends MetadataFieldProps {
  colSpan?: number;
}

export const MetaGridField: FC<MetadataGridFieldProps> = props => {
  const { colSpan = 1, ...rest } = props;

  return (
    <GridItem colSpan={colSpan}>
      <MetadataField {...rest} />
    </GridItem>
  );
};
