import React, { FC, useEffect, useState } from 'react';

import { Categories } from '@ariksa/inventory-core';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  Resources,
} from '@ariksa/inventory-core/api';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { AddWhitelist } from 'containers/Inventory/CloudInventory/Components/AddWhitelist';
import { useCategoryTableColumns } from 'containers/Inventory/CloudInventory/Components/hooks/useCategoryTableColumns';
import { InstanceInventory } from 'containers/Inventory/CloudInventory/Components/InstanceInventory';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';
import { InventoryTableTitle } from '../InventoryTableTitle';

export const ResourceTypeInventory: FC = () => {
  const dispatch = useDispatch();
  const {
    currentCategory,
    deploymentSummary,
    instanceResourceType,
  } = useSelector(selectInventory);

  const { withAccessBoundary } = useAccessBoundary();
  const { getCloudAgnosticName, getCloudNativeName } = useResourceType();

  const openWhitelist = useDisclosure();
  const [data, setData] = useState<
    AppSchemasInventoryResourceCountDeploymentSummary[]
  >([]);
  const { filterDeploymentSummary } = useInventory();
  const {
    deployedInAccounts,
    entityCount,
    cloud,
    deploymentType,
  } = useCategoryTableColumns();

  //get resource type inventory
  useEffect(() => {
    const type = currentCategory.split('_');
    const params = withAccessBoundary({
      category: type[0] as Categories,
      resourceType: type[1] as Resources,
    });
    dispatch(actions.getDeploymentSummary({ q: params }));
  }, [dispatch, currentCategory, withAccessBoundary]);

  useEffect(() => {
    setData(filterDeploymentSummary());
  }, [filterDeploymentSummary]);

  const handleRowClick = row => {
    dispatch(actions.setNativeResource(row.native_resource));
    dispatch(actions.resetSearchItems());
  };

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'native_resource',
      align: 'left',
      render: ({ value }) => (
        <WithResourceIcon resourceType={value}>
          <Box>{getCloudNativeName(value)}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Entity Type',
      accessor: 'native_resource',
      align: 'left',
      render: ({ value }) => getCloudAgnosticName(value),
    },
    cloud,
    deployedInAccounts,
    entityCount,
    deploymentType,
  ];

  return !!instanceResourceType ? (
    <InstanceInventory />
  ) : (
    <Stack spacing={4} h="full">
      <InventoryTableTitle
        title={getCloudAgnosticName(currentCategory?.split('_')[1])}
        iconType={currentCategory?.split('_')[1]}
        value={
          <Box>
            {deploymentSummary.isLoading ? 0 : data?.length}
            {data?.length > 1 ? ' Entities' : ' Entity'}
          </Box>
        }
        onOpenAddWhitelist={openWhitelist.onOpen}
      />
      <Table
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
        isLoading={deploymentSummary.isLoading}
      />
      {openWhitelist.isOpen && (
        <AddWhitelist
          isMulti
          data={data}
          isOpen={openWhitelist.isOpen}
          onClose={openWhitelist.onClose}
        />
      )}
    </Stack>
  );
};
