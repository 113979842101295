/**
 *
 * Details Page Header
 *
 */

import React, { FC } from 'react';

import { HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import {
  DetailsField,
  DetailsFieldProps,
} from 'components/DataDisplay/Utils/DetailsField';
import { BackIconButton } from 'components/DataEntry';

interface Props extends DetailsFieldProps {}

export const DetailsPageHeader: FC<Props> = props => {
  const navigate = useNavigate();
  return (
    <HStack align="center" w="full">
      <BackIconButton onClick={() => navigate(-1)} mr={2} />
      <DetailsField
        styles={{ label: { fontSize: 'lg' }, value: { fontSize: 'lg' } }}
        placeholder="-"
        {...props}
      />
    </HStack>
  );
};
