import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Stack, Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  isEmpty,
  startCase,
  map,
  some,
  sum,
  uniq,
  isString,
  find,
  split,
} from 'lodash';

import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

import { toTitleCase } from '../../../../../../../utils/string';

import { MetadataGridFieldProps, MetaGridField } from './MetaGridField';

// meta field components which can be reused for different resources

export const Name = r =>
  MetaField('Name', r?.name ?? r?.identifier?.name ?? '-');
export const Description = r =>
  MetaField(
    'Description',
    r?.description ||
      r?.config?.Description ||
      r?.config?.description ||
      r?.config?.KeyMetadata?.Description,
    { colSpan: 2 },
  );
export const DescriptionWith1ColSpan = r =>
  MetaField(
    'Description',
    r?.config?.profile?.description ||
      r?.config?.description ||
      r?.config?.Description,
  );
export const AccountID = r => {
  const { toCloudAccountId } = useCloudAccountId();
  return MetaField('Account ID', toCloudAccountId(r?.account));
};
export const AccountLocator = r => {
  const { toCloudAccountId } = useCloudAccountId();
  return MetaField('Account Locator', toCloudAccountId(r?.account));
};
export const CloudID = (r, colSpan = 2) =>
  MetaField('Cloud ID', r?.resource_id, { colSpan: colSpan });
export const ARN = r =>
  MetaField(
    'ARN',
    r?.arn ??
      r?.identifier?.containerInstanceArn ??
      r?.identifier?.clusterArn ??
      r?.identifier?.CertificateArn ??
      r?.identifier?.Arn ??
      r?.identifier?.taskDefinitionArn,
    { colSpan: 2 },
  );
export const Region = r => MetaField('Region', r?.region);
export const VPCId = r =>
  MetaField(
    'VPC ID',
    r?.vpc ||
      r?.vpc_id ||
      r?.connection?.SuperNet ||
      r?.network?.VpcId ||
      r?.network?.DBSubnetGroup?.VpcId ||
      r?.network?.DBSubnetGroup ||
      r?.identifier?.VpcId ||
      r?.identifier?.VPCId ||
      r?.connection?.VirtualPrivateCloud ||
      map(r?.network?.VpcConfig, e => e.VpcId) ||
      map(r?.network?.TransitGatewayVpcAttachments, e => e.VpcId),
    { linkResourceType: NativeResources.VirtualPrivateCloud },
  );
export const CreatedAt = (r, time) =>
  MetaField(
    'Created at',
    isString(r)
      ? r
      : r?.created_at ||
          r?.createdAt ||
          r?.config?.createdAt ||
          r?.config?.created_on ||
          r?.config?.createdDate ||
          r?.config?.InstanceCreateTime ||
          r?.config?.StartTime ||
          r?.config?.CreateDate ||
          r?.config?.CreateTime ||
          r?.config?.CreatedTime ||
          r?.config?.CreationTime ||
          r?.config?.CreationDate ||
          r?.config?.CreatedDate ||
          r?.config?.CreationDateTime ||
          r?.config?.LaunchTime ||
          r?.config?.SnapshotCreateTime ||
          r?.config?.KeyMetadata?.CreationDate ||
          r?.config?.Attachment?.AttachTime ||
          r?.config?.Status?.Timeline?.CreationDateTime ||
          r?.config?.CreationTime ||
          r?.config?.created ||
          r?.config?.createdDateTime ||
          r?.config?.CreatedTimestamp ||
          r?.config?.ClusterCreateTime ||
          r?.config?.JoinedTimestamp ||
          r?.config?.registeredAt,
    isString(r) ? {} : { time },
  );
export const LastUpdatedAtUnix = r =>
  MetaField('Last updated at', r?.config?.LastModifiedTimestamp, {
    time: 'unix',
  });
export const LastUpdatedAt = r =>
  MetaField(
    'Last updated at',
    r?.last_activity ||
      r?.config?.lastUpdated ||
      r?.config?.renewedDateTime ||
      r?.config?.lastPasswordChangeDateTime,
    { time: 'utc' },
  );
export const CreatedBy = r => MetaField('Created by', r?.created_by);
export const State = r =>
  MetaField(
    'State',
    isString(r)
      ? r
      : toTitleCase(
          r?.state ||
            r?.status ||
            r?.config?.State?.Name ||
            r?.config?.State?.Code ||
            r?.config?.TableStatus ||
            r?.config?.State ||
            r?.config?.state ||
            r?.config?.ClusterStatus ||
            r?.config?.Status?.State ||
            r?.config?.Status ||
            r?.config?.status ||
            r?.compute?.status ||
            r?.config?.NotebookInstanceStatus ||
            r?.config?.DBInstanceStatus ||
            r?.config?.OrganizationAccountDetails?.Status ||
            r?.config?.KeyMetadata?.KeyState ||
            r?.config?.ConfigRuleState ||
            r?.config?.LifeCycleState,
        ),
  );
export const Status = r =>
  MetaField('Status', r?.config?.Status || r?.config?.status);

/*export const HasInlinePolicies = r =>
  MetaField('Inline policies', r?.has_inline_policy);*/
export const InlinePolicies = r =>
  MetaField(
    'Inline policies',
    r?.config?.GroupInlinePolicies?.map(o => o?.PolicyName),
  );
export const MonitoringEnabled = r =>
  MetaField(
    'Monitoring enabled',
    r?.config?.Monitoring?.State || r?.monitoring,
  );
export const AvailabilityZone = r =>
  MetaField(
    'Availability zone',
    isString(r)
      ? r
      : r?.compute?.Ec2InstanceAttributes?.Ec2AvailabilityZone ||
          r?.config?.AvailabilityZone ||
          r?.config?.availabilityZone ||
          r?.config?.Placement?.AvailabilityZone ||
          r?.network?.VPCOptions?.AvailabilityZones ||
          r?.config?.AvailabilityZones?.map(o => o?.ZoneName ?? o),
  );

// VirtualMachine metadata fields
export const SubnetID = r =>
  MetaField('Subnet ID', r?.connection?.Subnet || r?.network?.SubnetId, {
    linkResourceType: NativeResources.Subnet,
  });
export const SubnetIDs = r =>
  MetaField('Subnet IDs', r?.connection?.Subnet, {
    linkResourceType: NativeResources.Subnet,
  });
export const PublicIP = r =>
  MetaField(
    'Public IP',
    r?.public_ip_address ||
      r?.publicIpAddress ||
      r?.config?.Association?.PublicIp,
  );
export const PrivateIP = r =>
  MetaField('Private IP', r?.network?.PrivateIpAddress);
export const PrivateDNS = r =>
  MetaField('Private DNS', r?.network?.PrivateDnsName);
export const ExternalExposure = r =>
  MetaField('External exposure', r?.external_access);
export const SecurityGroups = r =>
  MetaField(
    'Security groups',
    r?.connection?.SecurityGroup ||
      r?.security?.SecurityGroups ||
      r?.network?.SecurityGroups,
    {
      linkResourceType: NativeResources.SecurityGroup,
    },
  );
export const VMLoggingEnabled = r =>
  MetaField('Logging enabled', r?.config?.Logging?.State || 'false');
export const OpenPorts = r =>
  MetaField('Open ports', r?.security?.SecurityGroups?.length);
export const Interfaces = r =>
  MetaField('Interfaces', r?.connection?.Interface?.length);
export const CrossAccountAccess = r =>
  MetaField('Cross account access', r?.cross_account_access);
/*export const RolesAssigned = r => MetaField('Roles assigned', '~');
export const UsersWithAccess = r => MetaField('Users with access', '~');
export const EBSDisks = r =>
  MetaField('EBS disks', r?.connection?.BlockStorage?.length);*/
export const KeyName = r =>
  MetaField(
    'Key name',
    r?.compute?.Ec2InstanceAttributes?.Ec2KeyName || r?.config?.KeyName,
  );
export const KeyPair = r => MetaField('Key pair', r?.config?.KeyName);
export const ImageID = r => MetaField('Image ID', r?.image);
export const Hypervisor = r => MetaField('Hypervisor', r?.compute?.Hypervisor);
export const IAMInstanceProfileARN = r =>
  MetaField('IAM instance profile ARN', r?.config?.IamInstanceProfile?.Arn);
export const InstanceProfileID = r =>
  MetaField('Instance profile ID', r?.config?.IamInstanceProfile?.Id);
export const InstanceLifecycle = r =>
  MetaField('Instance lifecycle', r?.compute?.InstanceLifecycle);
export const EBSOptimized = r =>
  MetaField('EBS optimized', r?.storage?.EbsOptimized);
export const EBSDisks = r =>
  MetaField(
    'EBS Disks',
    map(r?.ebs_disks, o => o?.volume_id),
    { linkResourceType: NativeResources.ElasticBlockStorage },
  );
export const PublicDNS = r =>
  MetaField('Public DNS', r?.network?.PublicDnsName);
export const GPUID = r =>
  MetaField(
    'GPU ID',
    r?.config?.ElasticGpuAssociations?.map(o => o?.ElasticGpuId),
  );
export const AMIProductCode = r =>
  MetaField(
    'AMI product code',
    r?.config?.ProductCodes?.map(o => o?.ProductCodeId),
  );
export const AMIProductType = r =>
  MetaField(
    'AMI product type',
    uniq(r?.config?.ProductCodes?.map(o => o?.ProductCodeType)),
  );
export const RAMDiskID = r =>
  MetaField('RAM disk ID', r?.identifier?.RamdiskId);
export const VirtualizationType = r =>
  MetaField('Virtualization type', r?.compute?.VirtualizationType);
export const CoreCount = r =>
  MetaField('Core count', r?.compute?.CpuOptions?.CoreCount);
export const ThreadsPerCore = r =>
  MetaField('Threads per core', r?.compute?.CpuOptions?.ThreadsPerCore);
export const RootDeviceName = r =>
  MetaField(
    'Root device name',
    r?.storage?.RootDeviceName ??
      map(r?.config?.Attachments, o => o?.InstanceId + ': ' + o?.Device)?.join(
        ', ',
      ),
  );
export const EBSDeleteOnTermination = r =>
  MetaField(
    'Delete on termination',
    map(
      r?.config?.Attachments,
      o => o?.InstanceId + ': ' + o?.DeleteOnTermination,
    ),
  );
export const InstanceType = r =>
  MetaField('Instance type', r?.instance_type || r?.instanceType);
export const EBSVpcID = r => MetaField('VPC ID', r?.vpc || r?.vpc_id);
export const TerminationProtection = r =>
  MetaField('Termination protection', r?.disable_api_termination);
export const DeleteOnTermination = r =>
  MetaField(
    'Delete on termination',
    map(
      r?.storage?.BlockDeviceMappings,
      o => o?.Ebs?.VolumeId + ': ' + o?.Ebs?.DeleteOnTermination,
    ),
  );
export const HttpTokens = r =>
  MetaField('HTTP tokens', r?.config?.MetadataOptions?.HttpTokens);
export const HttpPutHopLimit = r =>
  MetaField(
    'HTTP put hop limit',
    r?.config?.MetadataOptions?.HttpPutResponseHopLimit,
  );
export const HttpEndpoint = r =>
  MetaField('HTTP endpoint', r?.config?.MetadataOptions?.HttpEndpoint);

// SecurityGroup metadata
export const Rules = r =>
  MetaField(
    'Rules',
    r?.network?.Rules ??
      r?.network?.IpPermissions?.length ??
      0 + r?.network?.IpPermissionsEgress?.length ??
      0,
  );
export const Ingress = r =>
  MetaField('Ingress CIDR 0.0.0.0/0', r?.network?.IngressCIDR);
export const Egress = r =>
  MetaField('Egress CIDR 0.0.0.0/0', r?.network?.EgressCIDR);
export const NetworkInterfaceIDs = r =>
  MetaField('Network interface IDs', r?.network_interface_ids, {
    linkResourceType: NativeResources.ElasticNetworkInterface,
  });

//Gitlab
export const Visibility = r => MetaField('Visibility', r?.security?.visibility);
export const Subgroups = r =>
  MetaField(
    'Shared groups',
    r?.config?.shared_with_groups?.map(
      o => o?.group_full_path + ' : ' + o?.group_access_level,
    ),
  );
export const Projects = r => MetaField('Projects', '~');
export const AccessProtocols = r => MetaField('Access protocols', '~');
export const Webhooks = r =>
  MetaField(
    'Webhooks',
    r?.network?.webhooks?.map(o => o?.hook_url),
  );
export const CICDRunners = r =>
  MetaField('CI/CD Runners', r?.config?.ci_cd_runners ? 'Enabled' : 'Disabled');
export const VariableKey = r => MetaField('Key', r?.config?.variables?.key);
export const VariableValue = r =>
  MetaField('Value', r?.config?.variables?.value);
export const VariableAttributes = r =>
  MetaField('Attributes', r?.config?.variables?.attributes);

// Interface metadata
export const InterfaceDeleteOnTermination = r =>
  MetaField(
    'Delete on termination',
    r?.config?.Attachment?.DeleteOnTermination,
  );
export const Owner = r => {
  const name =
    r?.owner ??
    r?.identifier?.Owner ??
    r?.identifier?.OwnerId ??
    r?.config?.owner;
  return MetaField(
    'Owned by',
    !!name
      ? [
          {
            name,
            id: r?.owner_id ?? r?.identifier?.OwnerId,
          },
        ]
      : [],
    {
      linkResourceType: NativeResources.IamUser,
    },
  );
};
export const IPOwner = r =>
  MetaField('IP owner', r?.config?.Association?.IpOwnerId);
export const AttachedTo = r =>
  MetaField('Attached to', r?.config?.Attachment?.InstanceId);
export const FirewallIDs = r =>
  MetaField(
    'Firewall IDs',
    r?.config?.Groups?.map(o => o?.GroupName),
  );
export const MACAddress = r => MetaField('MAC address', r?.network?.MacAddress);
export const SourceDestinationCheck = r =>
  MetaField('Source destination check', r?.config?.SourceDestCheck);

// NetworkACL metadata
export const AssociatedIDs = r =>
  MetaField(
    'Associated IDs',
    r?.config?.Associations?.map(o => o?.NetworkAclAssociationId),
  );
export const Default = r =>
  MetaField(
    'Default',
    r?.is_default ?? r?.config?.Assoications?.NetworkAclAssociationId,
  );
export const NACLOwner = r => MetaField('Owner', r?.owner_id);

// VPC metadata
export const IPv4CIDRBlock = r =>
  MetaField('IPv4 CIDR block', r?.network?.CidrBlock);
export const IPv4CIDRBlockState = r =>
  MetaField(
    'IPv4 CIDR block state',
    r?.network?.CidrBlockAssociationSet?.[0]?.CidrBlockState?.State,
  );
export const DHCPOptionID = r =>
  MetaField('DHCP options ID', r?.connection?.DHCPOptions, {
    linkResourceType: NativeResources.DhcpOptions,
  });
export const VPCDefault = r => MetaField('Default', r?.config?.IsDefault, {});

// Subnet metadata
export const DefaultForAz = r =>
  MetaField('Default for Az', r?.config?.DefaultForAz);
export const AvailableIPs = r =>
  MetaField('Available IPs', r?.network?.AvailableIpAddressCount);
export const AvailableIPsForIPV6 = r => MetaField('Available IPs', '~');
export const AttachIPv6 = r =>
  MetaField('Attach IPv6', r?.assign_ipv6_address_on_creation);
export const IPv6Native = r => MetaField('IPv6 Native', r?.network?.Ipv6Native);
export const IPv6CIDRBlock = r =>
  MetaField(
    'IPv6 CIDR block state',
    map(
      r?.network?.Ipv6CidrBlockAssociationSet,
      o => o?.Ipv6CidrBlock + ' : ' + o?.Ipv6CidrBlockState?.State,
    ),
  );
export const IPv6CIDRBlockState = r => MetaField('IPv6 CIDR block state', '~');
export const SubnetState = r => MetaField('State', r?.config?.State);

// KMS metadata
export const KeySpec = r =>
  MetaField('Key spec', r?.config?.KeyMetadata?.KeySpec);
export const Aliases = r =>
  MetaField(
    'Aliases',
    r?.config?.Aliases?.map(o => o?.AliasName),
  );
export const KeyUsage = r =>
  MetaField('Key usage', r?.config?.KeyMetadata?.KeyUsage);
export const KeyState = r =>
  MetaField('State', r?.config?.KeyMetadata?.KeyState);
export const KeyManager = r => MetaField('Key manager', r?.key_manager);
export const MultiRegion = r =>
  MetaField(
    'Multi-region',
    r?.config?.KeyMetadata?.MultiRegion ?? r?.config?.IsMultiRegionTrail,
  );
export const KeyRotation = r =>
  MetaField('Key rotation', r?.config?.KeyRotationEnabled);
export const RotationPeriod = r =>
  MetaField(
    'Rotation period',
    r?.config?.KeyRotatio || r?.config?.RotationRules?.Duration,
  );

// EBS metadata
export const Encrypted = r =>
  MetaField(
    'Encrypted',
    r?.encrypted ?? r?.security?.Encrypted ?? r?.storage?.StorageEncrypted,
  );
export const KeyID = r =>
  MetaField('Key ID', r?.identifier?.KmsKeyId || r?.connection?.Credential);
export const Size = r => MetaField('Size', r?.storage?.Size);
export const IOPS = r => MetaField('IOPS', r?.storage?.Iops ?? r?.config?.Iops);
export const Type = r =>
  MetaField(
    'Type',
    r?.storage?.VolumeType ||
      r?.config?.groupType ||
      r?.config?.type ||
      r?.config?.KeyType ||
      r?.config?.kind ||
      r?.config?.AccountType ||
      r?.config?.HealthCheckConfig?.Type,
  );
export const MultiAttachAllowed = r =>
  MetaField('Multi-attach Allowed', r?.storage?.MultiAttachEnabled);
export const VirtualMachines = r =>
  MetaField('Virtual machines', r?.connection?.EC2Instance, {
    linkResourceType: NativeResources.Ec2Instance,
  });

// S3 metadata
export const Grants = r =>
  MetaField(
    'Grants',
    map(r?.config?.Grants, g => `${g?.Grantee?.ID}/${g?.Permission}`),
    { colSpan: 2 },
  );
export const ServerSideEncryption = r =>
  MetaField(
    'Server-side encryption',
    r?.security?.ServerSideEncryptionConfiguration?.Rules?.map(
      a => a?.ApplyServerSideEncryptionByDefault?.SSEAlgorithm,
    ),
  );
export const BucketKeyEnabled = r =>
  MetaField(
    'Bucket key enabled',
    r?.security?.ServerSideEncryptionConfiguration?.Rules?.map(a =>
      startCase(a?.BucketKeyEnabled),
    ),
  );
export const VersioningEnabled = r =>
  MetaField(
    'Versioning enabled',
    r?.config?.Versioning?.Status === 'Enabled' ? 'Yes' : 'No',
  );
export const WebsiteEnabled = r =>
  MetaField('Website enabled', isEmpty(r?.config?.Website) ? 'No' : 'Yes');
export const BlockPublicACLs = r =>
  MetaField(
    'Block public ACLs',
    r?.config?.PublicAccessBlockConfiguration?.BlockPublicAcls,
  );
export const IgnorePublicACLs = r =>
  MetaField(
    'Ignore public ACLs',
    r?.config?.PublicAccessBlockConfiguration?.IgnorePublicAcls,
  );
export const BlockBucketPolicy = r =>
  MetaField(
    'Block bucket policy',
    r?.config?.PublicAccessBlockConfiguration?.BlockPublicPolicy,
  );
export const RestrictPublicBuckets = r =>
  MetaField(
    'Restrict public buckets',
    r?.config?.PublicAccessBlockConfiguration?.RestrictPublicBuckets,
  );
export const Policy = r => MetaField('Resource policy', r?.has_resource_policy);
export const S3State = r => MetaField('State', 'Active');

// EFS metadata
export const EncryptionKey = r =>
  MetaField(
    'Encryption key',
    r?.connection?.Credential ?? r?.identifier?.KmsKeyId,
    { colSpan: 2 },
  );
export const LifecycleState = r =>
  MetaField('Lifecycle state', r?.config?.LifeCycleState);
export const DataInInfrequentAccess = r =>
  MetaField(
    'Data in infrequent access (ValueInIA)',
    r?.storage?.SizeInBytes?.ValueInIA,
  );
export const DataInStandard = r =>
  MetaField(
    'Data in standard (ValueInStandard)',
    r?.storage?.SizeInBytes?.ValueInStandard,
  );
export const PerformanceMode = r =>
  MetaField('Performance mode', r?.config?.PerformanceMode);
export const ThroughputMode = r =>
  MetaField('Throughput mode', r?.config?.ThroughputMode);
export const MountTargets = r =>
  MetaField('Mount targets', r?.storage?.NumberOfMountTargets);
export const CreationToken = r =>
  MetaField('Creation token', r?.config?.CreationToken, { colSpan: 2 });
export const EFSSize = r => MetaField('Size', r?.storage?.SizeInBytes?.Value);

// IAMUser metadata
export const Groups = r =>
  MetaField(
    'Groups',
    map(r?.config?.Groups, o => o?.GroupId),
    { linkResourceType: NativeResources.IamGroup },
  );
export const MFAEnabled = r =>
  MetaField(
    'MFA',
    some(r?.contexts, o => o.key === 'mfa') ? 'Enabled' : 'Disabled',
  );
export const UserState = r => MetaField('State', 'Active');
export const PasswordLastUsed = r =>
  MetaField(
    'Password last used',
    r?.password_enabled?.password_last_used,
    { time: 'dayjs' },
    //dayjs(r?.security?.PasswordLastUsed).format('llll'),
  );
export const PasswordReset = r =>
  MetaField(
    'Password reset required',
    r?.security?.LoginProfile?.PasswordResetRequired,
  );
export const AccessKeys = r => MetaField('Access keys', r?.active_access_keys);
export const AccessKeysList = r =>
  MetaField(
    'Access key names',
    r?.security?.AccessKeys?.map(k => k?.AccessKeyId),
  );
export const UserAttachedPolicies = r =>
  MetaField('Attached policies', r?.config?.UserAttachedPolicies?.length);
export const UserAttachedPoliciesList = r =>
  MetaField(
    'Attached policies',
    r?.config?.UserAttachedPolicies?.map(p => p?.PolicyName),
  );
export const PermissionBoundary = r =>
  MetaField('Permission boundary', r?.config?.PermissionsBoundary?.PolicyName);
export const UserInlinePolicies = r =>
  MetaField(
    'Inline policies',
    map(r?.config?.UserInlinePolicies, o => o?.PolicyName),
    { withListAsTooltip: true },
  );

//Snowflake
export const Username = r => MetaField('Username', r?.config?.name);
export const LoginName = r => MetaField('Login name', r?.config?.login_name);
export const SnowflakeID = r => MetaField('ID', r?.resource_id);
export const Comment = r => MetaField('Comment', r?.config?.comment);
export const IdentityProvider = r =>
  MetaField('Identity Provider', r?.provider);
export const Emails = r => MetaField('Emails', '');
export const Active = r => MetaField('Active', r?.config?.disabled === 'false');
export const Roles = r =>
  MetaField('Roles', r?.connection?.SnowflakeRoles || r?.config?.roles);
export const Privileges = r => MetaField('Privileges', '');
export const AssignedUser = r =>
  MetaField('Assigned user', r?.config?.assigned_to_users);
export const IsDefault = r =>
  MetaField('Is default', r?.config?.is_default === 'Y');
export const IsCurrent = r =>
  MetaField('Is current', r?.config?.is_current === 'Y');
export const IsInherited = r =>
  MetaField('Is inherited', r?.config?.is_inherited === 'Y');
export const AssignedToOtherRoles = r =>
  MetaField('Assigned to other roles', r?.config?.granted_to_roles);
export const GrantedRoles = r =>
  MetaField('Granted roles', r?.config?.granted_roles);
export const Schemas = r =>
  MetaField(
    'Schemas',
    map(r?.config?.schemas, o => o.name),
    { withListAsTooltip: true },
  );
export const Schema = r => MetaField('Schema', r?.config?.schema_name);
export const Tables = r => MetaField('Tables', r?.tables?.length);
export const Views = r => MetaField('Views', '');
export const IsTransient = r => MetaField('Is transient', '');
export const SnowflakeDatabase = r =>
  MetaField('Database', r?.config?.db_name || r?.config?.database_name);
export const ClusteringKey = r =>
  MetaField('Clustering key', r?.config?.cluster_by);
export const RowCount = r => MetaField('Row count', r?.config?.rows);
export const Bytes = r => MetaField('Bytes', r?.config?.bytes);
export const RetentionTime = r =>
  MetaField('Retention time', r?.config?.retention_time);
export const AutoClusteringOn = r =>
  MetaField('Auto Clustering On', r?.config?.automatic_clustering);

// AccountRoot metadata
export const VirtualMFAEnabled = r =>
  MetaField('Virtual MFA enabled', r?.virtual_mfa?.virtual_mfa_enabled);
export const HardwareMFAEnabled = r =>
  MetaField('Hardware MFA enabled', r?.hardware_mfa_enabled);
export const AccessKeysUsed = r =>
  MetaField(
    'Access keys used',
    'Key 1: ' +
      (r?.access_keys?.access_key_1_active ? 'Yes' : 'No') +
      ', Key 2: ' +
      (r?.access_keys?.access_key_2_active ? 'Yes' : 'No'),
  );
export const LastUsedRegion = r =>
  MetaField('Access keys last used region', '~');
export const LastUsedAt = r => MetaField('Access keys last used at', '~');
export const ServiceControlPolicy = r =>
  MetaField('Service control policy', r?.scp_list);

// IAMRole metadata
export const CrossAccount = r =>
  MetaField(
    'Cross account',
    r?.config?.CrossAccountFlagAriksa ?? r?.cross_account_access,
  );
export const Path = r => MetaField('Path', r?.config?.Path);
export const MaxSessionDuration = r =>
  MetaField('Max session duration', r?.config?.MaxSessionDuration);
// export const RoleAttachedPolicies = (r) => MetaField('Attached Policies', r?.config?.RoleAttachedPolicies?.length);
export const RoleAttachedPoliciesList = r =>
  MetaField(
    'Attached policies',
    r?.config?.RoleAttachedPolicies?.map(p => p?.PolicyName),
  );
export const RoleInlinePoliciesList = r =>
  MetaField(
    'Inline policies',
    r?.config?.RoleInlinePolicies?.map(p => p?.PolicyName),
  );
export const PolicyType = r => MetaField('Type', r?.managed_by);
export const LatestVersion = r =>
  MetaField('Latest version', r?.latest_version);
export const RoleLastUsedAt = r =>
  MetaField('Last used at', r?.config?.RoleLastUsed?.LastUsedDate);
export const RoleLastUsedRegion = r =>
  MetaField('Last used region', r?.config?.RoleLastUsed?.Region);
export const RoleState = r =>
  MetaField(
    'State',
    !!r?.config?.RoleLastUsed?.LastUsedDate ? 'Used' : 'Unused',
  );

//IAM Policy metadata
export const AllowedActions = r =>
  MetaField('Allowed actions', r?.allowed_action_count);
export const UpdatedAt = r =>
  MetaField('Updated at', r?.config?.UpdateDate, { time: 'dayjs' });
export const DeniedActions = r =>
  MetaField('Denied actions', r?.denied_action_count);
export const AllowedServices = r =>
  MetaField('Allowed services', r?.allowed_service_count);
export const DeniedServices = r =>
  MetaField('Denied services', r?.denied_service_count);
export const AttachedToRoles = r =>
  MetaField(
    'Attached roles',
    r?.config?.PolicyRoles?.map(o => ({ id: o?.RoleId, name: o?.RoleName })),
    { linkResourceType: NativeResources.IamRole, colSpan: 2 },
  );
export const Conditional = r => MetaField('Conditional', r?.conditional);
export const DefaultVersion = r =>
  MetaField('Default Version', r?.identifier?.DefaultVersionId);
export const AllServiceActionsPermitted = r =>
  MetaField('All service sections permitted?', '~');
export const AllResources = r => MetaField('All resources', '~');
export const WildcardForResources = r =>
  MetaField('Wildcard for resources', r?.wildcard_resource);
export const WildcardForActions = r =>
  MetaField('Wildcard for actions', r?.wildcard_action);
export const PolicyDescription = r => MetaField('Description', '~');
export const IAMPolicyState = r =>
  MetaField('State', r?.attachment_count > 0 ? 'Attached' : 'Unattached');

// Policy
export const CloudManaged = r => MetaField('Cloud managed', '~');
export const AttachedUsers = r =>
  MetaField(
    'Attached users',
    r?.config?.PolicyUsers?.map(o => ({ name: o?.UserName, id: o?.UserId })),
    { linkResourceType: NativeResources.IamUser },
  );
export const AttachedGroups = r =>
  MetaField(
    'Attached groups',
    r?.config?.PolicyGroups?.map(o => ({ name: o?.GroupName, id: o?.GroupId })),
    { linkResourceType: NativeResources.IamGroup },
  );
export const WildcardResources = r => MetaField('Wildcard resources', '~');
export const WildcardActions = r => MetaField('Wildcard actions', '~');
export const Conditions = r => MetaField('Conditions', '~');
export const Permissions = r =>
  MetaField('Permissions', '~', { withListAsTooltip: true });
export const Principals = r =>
  MetaField('Principal(s)', '~', { withListAsTooltip: true });

// ElasticSearch
export const AvailabilityZones = r =>
  MetaField(
    'Availability zones',
    r?.network?.VPCOptions?.AvailabilityZones || r?.config?.AvailabilityZones,
  );
export const SecurityGroupsList = r =>
  MetaField('Security groups', r?.network?.VPCOptions?.SecurityGroupIds);
export const Endpoints = r =>
  MetaField('Endpoint', r?.config?.Endpoint, { colSpan: 2 });
export const Version = r =>
  MetaField('Version', r?.config?.ElasticsearchVersion || r?.config?.version);
export const ClusterInstanceType = r =>
  MetaField(
    'Cluster instance type',
    r?.config?.ElasticsearchClusterConfig?.InstanceType,
  );
export const DedicatedMaster = r =>
  MetaField(
    'Dedicated master?',
    r?.config?.ElasticsearchClusterConfig?.DedicatedMasterEnabled,
  );
export const MasterInstanceType = r =>
  MetaField(
    'Master instance type',
    r?.config?.ElasticsearchClusterConfig?.DedicatedMasterType,
  );
export const WarmStorageEnabled = r =>
  MetaField(
    'Warm storage enabled?',
    r?.config?.ElasticsearchClusterConfig?.WarmEnabled,
  );
export const WarmInstanceType = r =>
  MetaField(
    'Warm instance type',
    r?.config?.ElasticsearchClusterConfig?.WarmType,
  );
export const WarmNodesInCluster = r => MetaField('Warm nodes in cluster', '~');
export const ColdStorage = r =>
  MetaField(
    'Cold storage?',
    r?.config?.ElasticsearchClusterConfig?.ColdStorageOptions?.Enabled,
  );
export const AttachedVolumeType = r =>
  MetaField('Attached volume type', r?.storage?.EBSOptions?.VolumeType);
export const AttachedVolumeSize = r =>
  MetaField('Attached volume size', r?.storage?.EBSOptions?.VolumeSize);
export const VolumeIOPS = r =>
  MetaField('Volume IOPS', r?.storage?.EBSOptions?.Iops);
export const EncryptionAtRest = r =>
  MetaField(
    'Encryption at rest?',
    r?.security?.EncryptionAtRestOptions?.Enabled,
  );
export const EncryptionKeyID = r =>
  MetaField(
    'Encryption key ID',
    r?.security?.EncryptionAtRestOptions?.KmsKeyId,
  );
export const LogsOptions = r =>
  MetaField(
    'Logs option',
    map(r?.config?.LogPublishingOptions, (value, key) => key),
  );
export const CloudWatchLogARN = r =>
  MetaField(
    'Cloud watch Logs ARN',
    map(r?.config?.LogPublishingOptions, o => o.CloudWatchLogsLogGroupArn),
  );
export const UpdateAvailable = r =>
  MetaField(
    'Update available?',
    r?.config?.ServiceSoftwareOptions?.UpdateAvailable,
  );
export const UpdateStatus = r =>
  MetaField('Update status', r?.config?.ServiceSoftwareOptions?.UpdateStatus);
export const EnforceHTTPSOnEndpoint = r =>
  MetaField(
    'Enforce HTTPS on endpoint',
    r?.config?.DomainEndpointOptions?.EnforceHTTPS,
  );
export const TLSPolicyOnEndpoint = r =>
  MetaField(
    'TLS policy on endpoint',
    r?.config?.DomainEndpointOptions?.TLSSecurityPolicy,
  );
export const CustomEndpoint = r =>
  MetaField(
    'Custom endpoint?',
    r?.config?.DomainEndpointOptions?.CustomEndpointEnabled,
  );
export const CustomEndpointCertificateARN = r =>
  MetaField(
    'Custom endpoint certificate ARN',
    r?.config?.DomainEndpointOptions?.CustomEndpointCertificateArn,
  );
export const SAMLEntityID = r =>
  MetaField(
    'SAML - Entity ID',
    r?.security?.AdvancedSecurityOptions?.SAMLOptions?.Idp?.EntityId,
  );
export const SAMLSubjectKey = r =>
  MetaField(
    'SAML - Subject key',
    r?.security?.AdvancedSecurityOptions?.SAMLOptions?.SubjectKey,
  );

//SNS
export const SNSFIFO = r => MetaField('FIFO', r?.config?.FifoTopic === 'true');
export const SubscriptionsConfirmed = r =>
  MetaField('Subscriptions confirmed', r?.config?.SubscriptionsConfirmed);
export const SubscriptionsPending = r =>
  MetaField('Subscriptions pending', r?.config?.SubscriptionsPending);
export const SubscriptionsDeleted = r =>
  MetaField('Subscriptions deleted', r?.config?.SubscriptionsDeleted);
export const MessagesEncrypted = r =>
  MetaField('Messages encrypted', !!r?.identifier?.KmsMasterKeyId);
export const KeyIDForEncryption = r =>
  MetaField('Key ID for encryption', r?.identifier?.KmsMasterKeyId);
export const NumberOfAttachedPolicies = r =>
  MetaField(
    'Attached policies',
    sum([
      !!r?.config?.Policy ? 1 : 0,
      !!r?.config?.EffectiveDeliveryPolicy ? 1 : 0,
    ]),
  );
export const AttachedPolicies = r =>
  MetaField('Attached policies', [
    !!r?.config?.Policy ? 'Access Policy' : null,
    !!r?.config?.EffectiveDeliveryPolicy ? 'Delivery Policy' : null,
  ]);
export const Endpoint = r =>
  MetaField(
    'Endpoint',
    r?.config?.resources?.map(o => o?.resourceMethods?.methodIntegration?.uri),
  );
export const Protocol = r => MetaField('Protocol', '~');
export const TopicARN = r =>
  MetaField('Topic ARN', r?.config?.NotificationConfiguration?.TopicArn);
export const RedrivePolicy = r => MetaField('Redrive policy', '~');
export const SNSEncryptionAtRest = r =>
  MetaField(
    'Encryption at rest',
    find(r?.contexts, o => o.key === 'encryption')?.value,
  );
export const EncryptionInTransit = r =>
  MetaField('Encryption in transit', 'Enabled');
export const ContentBasedDedupe = r =>
  MetaField(
    'Content based dedupe',
    r?.config?.ContentBasedDeduplication === 'true',
  );

//SQS
export const SQSFIFO = r => MetaField('FIFO', r?.config?.FifoQueue);
export const WhoCanSend = r => MetaField('Who can send', r?.config?.WhoCanSend);
export const WhoCanReceive = r =>
  MetaField('Who can receive', r?.config?.WhoCanReceive);
export const SQSType = r =>
  MetaField('Type', r?.config?.FifoQueue ? 'FIFO' : 'Standard');
export const ReceiveMessageWaitTime = r =>
  MetaField(
    'Receive message wait time (seconds)',
    r?.config?.ReceiveMessageWaitTimeSeconds,
  );
export const DeliveryDelay = r =>
  MetaField('Delivery delay (seconds)', r?.config?.DelaySeconds);
export const VisibilityTimeout = r =>
  MetaField('Visibility timeout (seconds)', r?.config?.VisibilityTimeout);
export const DeadLetterQueue = r =>
  MetaField('Dead letter queue', r?.config?.DeadLetterQueue);
export const FIFOThroughputLimit = r =>
  MetaField('FIFO throughput limit', r?.config?.FifoThroughputLimit);
export const HasServerSideEncryption = r =>
  MetaField(
    'Server-side encryption',
    !!r?.identifier?.KmsMasterKeyId || r?.security?.SqsManagedSseEnabled,
  );
export const SQSServerSideEncryption = r =>
  MetaField('Server-side encryption', '~');
export const KeyReusePeriod = r =>
  MetaField(
    'Key reuse period (seconds)',
    r?.config?.KmsDataKeyReusePeriodSeconds,
  );
export const NumberOfMessages = r =>
  MetaField('Number of messages', r?.config?.ApproximateNumberOfMessages);
export const DelayedMessages = r =>
  MetaField('Delayed messages', r?.config?.ApproximateNumberOfMessagesDelayed);
export const MessagesNotVisible = r =>
  MetaField(
    'Messages not visible',
    r?.config?.ApproximateNumberOfMessagesNotVisible,
  );
export const MaxMessageSize = r =>
  MetaField('Max message size (in KB)', r?.config?.MaximumMessageSize);
export const MessageRetentionPeriod = r =>
  MetaField(
    'Message retention period (seconds)',
    r?.config?.MessageRetentionPeriod,
  );

//Organization
export const AccountARN = r => MetaField('Account ARN', r?.arn, { colSpan: 2 });
export const Email = r =>
  MetaField(
    'Email',
    r?.identifier?.MasterAccountEmail ||
      r?.config?.email ||
      r?.config?.Email ||
      r?.config?.OrganizationAccountDetails?.Email,
  );
export const FeatureSet = r => MetaField('Feature set', r?.config?.FeatureSet);
export const NumberOfUsers = r =>
  MetaField('Users', r?.total_user_count || r?.users?.length);
export const NumberOfRoles = r =>
  MetaField('Roles', r?.total_role_count || r?.roles?.length);
export const NumberOfGroups = r =>
  MetaField(
    'Groups',
    r?.total_group_count ??
      r?.config?.DefaultCacheBehavior?.TrustedKeyGroups?.Quantity ??
      r?.groups?.length,
  );
export const NumberOfPolicies = r =>
  MetaField('Policies', r?.total_policy_count || r?.connection?.Policy?.length);
export const OrgUnits = r =>
  MetaField(
    'Organization units',
    r?.config?.OrganizationalUnitsAtRoot?.map(o => ({
      name: o?.Name ?? o?.Id,
      id: o?.Id,
    })) || r?.config?.ChildOrganizationalUnits?.map(o => o.Id),
    { linkResourceType: NativeResources.OrganizationUnit },
  );
export const IdentityPolicies = r =>
  MetaField('Identity policies', r?.identity_policies?.length);
export const ResourcePolicies = r =>
  MetaField('Resource policies', r?.resource_policies?.length);
export const TagPolicies = r =>
  MetaField('Tag policies', r?.tag_policies?.length);
export const SCP = r => MetaField('SCP', r?.scp_policies?.length);
export const OrgAccounts = r =>
  MetaField(
    'Accounts',
    r?.config?.AccountsAtRoot?.map(o => ({
      name: o?.Name ?? o?.Id,
      id: o?.Id,
    })),
    { linkResourceType: NativeResources.AwsAccount },
  );

// Organization Unit
export const Organization = r =>
  MetaField('Organization', r?.identifier?.organizationId);
export const ParentOrganization = r =>
  MetaField('Parent organization', r?.parent_organization, {
    colSpan: 2,
    linkResourceType: NativeResources.AwsOrganization,
  });
export const OrgUnitPolicies = r =>
  MetaField(
    'Organization',
    map(r?.config?.PoliciesAtOU, o => o?.Name),
  );
export const Accounts = r =>
  MetaField(
    'Accounts',
    r?.accounts || map(r?.config?.ChildAccounts, o => o?.Id),
  );
export const OrgUnitAccounts = r =>
  MetaField(
    'Accounts',
    r?.config?.ChildAccounts?.map(o => o?.Id),
    {
      linkResourceType: NativeResources.AwsAccount,
    },
  );

// AWS Account
export const IsOnboarded = r =>
  MetaField('Is onboarded', r?.config?.IsOnboarded);
export const ManagementAccountId = r =>
  MetaField('Management account', r?.identifier?.ManagementAccountId, {
    linkResourceType: NativeResources.AwsAccount,
  });

// RDS Instance
export const InternetAccessible = r =>
  MetaField('Internet accessible', r?.config?.PubliclyAccessible);
export const DNSAddress = r =>
  MetaField(
    'DNS address',
    r?.identifier?.Endpoint?.Address + ' : ' + r?.identifier?.Endpoint?.Port,
    { colSpan: 2 },
  );
export const RDSUrl = r => MetaField('URL', r?.identifier?.Address?.Endpoint);
export const InstanceClass = r =>
  MetaField('Instance class', r?.config?.DBInstanceClass);
export const ClusterId = r =>
  MetaField('Cluster Id', r?.identifier?.DBClusterIdentifier);
export const AssociatedStorage = r =>
  MetaField('Allocated storage', r?.storage?.AllocatedStorage);
export const StorageType = r =>
  MetaField('Storage Type', r?.storage?.StorageType);
export const RDSKeyId = r => MetaField('Key ID', r?.identifier?.KmsKeyId);
export const StorageEncrypted = r =>
  MetaField('Storage encrypted', r?.storage?.StorageEncrypted);
export const CaCertificate = r =>
  MetaField('CA Certificate', r?.identifier?.CACertificateIdentifier);
export const BackupWindow = r =>
  MetaField('Backup window', r?.config?.PreferredBackupWindow);
export const BackupRetentionPeriod = r =>
  MetaField('Backup retention period', r?.config?.BackupRetentionPeriod);
export const DatabaseEngine = r =>
  MetaField('Database engine', r?.config?.Engine);
export const DatabaseEngineVersion = r =>
  MetaField('Database engine version', r?.config?.EngineVersion);
export const MasterUsername = r =>
  MetaField('Master username', r?.security?.MasterUsername);
export const LicenseModel = r =>
  MetaField('License model', r?.config?.LicenseModel);
export const IAMAuthEnabled = r =>
  MetaField('IAM auth enabled', r?.security?.IAMDatabaseAuthenticationEnabled);
export const DeleteProtection = r =>
  MetaField('Delete protection', r?.config?.DeletionProtection);
export const MultiAZ = r => MetaField('Multi AZ', r?.config?.MultiAZ);
export const RDSSecurityGroupList = r =>
  MetaField(
    'Security groups',
    r?.network?.VpcSecurityGroups?.map(e => e?.VpcSecurityGroupId),
    { linkResourceType: NativeResources.SecurityGroup },
  );

// RDSCluster
export const RDSClusterMembers = r =>
  MetaField(
    'Members',
    r?.secuirty?.DBClusterMembers?.map(e => e.DBInstanceIdentifier),
  );
export const RDSClusterUrl = r => MetaField('URL', r?.identifier?.Endpoint);
export const PreferredBackupWindow = r =>
  MetaField('Preferred backup window', r?.config?.PreferredBackupWindow);
export const ReaderUrl = r =>
  MetaField('Reader URL', r?.config?.ReaderEndpoint);
export const HttpEndpointEnabled = r =>
  MetaField('HTTP endpoint enabled', r?.config?.HttpEndpointEnabled);
export const LastRecoverableTimestamp = r =>
  MetaField('Last Recoverable Timestamp', r?.config?.LatestRestorableTime);

// DynamoDB
export const LastIncreaseDateTime = r =>
  MetaField(
    'Last increase date',
    r?.config?.ProvisionedThroughput?.LastIncreaseDateTime,
  );
export const LastDecreaseDateTime = r =>
  MetaField(
    'Last decrease date',
    r?.config?.ProvisionedThroughput?.LastDecreaseDateTime,
    { time: 'dayjs' },
  );
export const NumberOfDecreasesToday = r =>
  MetaField(
    'Number of decreases today',
    r?.config?.ProvisionedThroughput?.NumberOfDecreasesToday,
  );
export const ReadCapacityUnits = r =>
  MetaField(
    'Read capacity units',
    r?.config?.ProvisionedThroughput?.ReadCapacityUnits,
  );
export const WriteCapacityUnits = r =>
  MetaField(
    'Write capacity units',
    r?.config?.ProvisionedThroughput?.WriteCapacityUnits,
  );
export const TableSize = r =>
  MetaField('Table size (bytes)', r?.config?.TableSizeBytes);
export const StreamsEnabled = r =>
  MetaField('Streams enabled', r?.config?.StreamSpecification?.StreamEnabled);
export const LatestStreamsARN = r =>
  MetaField('Latest streams ARN', r?.identifier?.LatestStreamArn);
export const Replicas = r => MetaField('Replicas', r?.config?.Replicas);
export const DynamoDBServerSideEncryption = r =>
  MetaField(`Server-side encryption`, r?.security?.SSEDescription?.Status);
export const RestoredFromBackup = r =>
  MetaField('Restored from backup', r?.config?.RestoredFromBackup);

// Lambda
export const VPCIds = r =>
  MetaField(
    'VPC Ids',
    map(r?.network?.VpcConfig, e => e.VpcId),
  );
export const LastModified = r =>
  MetaField(
    'Last modified',
    r?.config?.LastModified ||
      r?.config?.LastModifiedTime ||
      r?.config?.last_modified,
    { time: 'dayjs' },
  );
export const CodeSha256 = r => MetaField('Code SHA-256', r?.config?.CodeSha256);
export const Runtime = r => MetaField('Runtime', r?.config?.Runtime);
export const LambdaLastModified = r =>
  MetaField('Last Modified', r?.config?.LastModified, { time: 'utc' });
export const LambdaDescription = r =>
  MetaField('Description', r?.config?.Description);
export const MemorySize = r => MetaField('Memory size', r?.config?.MemorySize);
export const CodeSize = r => MetaField('Code Size', r?.config?.CodeSize);
export const Role = r => MetaField('Role', r?.config?.Role, { colSpan: 2 });
export const Timeout = r => MetaField('Timeout', r?.config?.Timeout);
export const Handler = r => MetaField('Handler', r?.config?.Handler);
export const Location = r => MetaField('Location', '~');

// EKS
export const PlatformVersion = r => MetaField('Platform Version', '~');
export const PublicCIDRs = r => MetaField('Public CIDRs', '~');
export const PrivateAccessWithinVPC = r =>
  MetaField('PrivateAccess Within VPC', '~');
export const EncryptionKeyARN = r => MetaField('Encryption Key ARN', '~');
export const Certificate = r =>
  MetaField('Certificate', r?.config?.certificateAuthority?.data);

// RedshiftCluster
export const NamespaceARN = r =>
  MetaField('Namespace ARN', r?.identifier?.ClusterNamespaceArn, {
    colSpan: 2,
  });
export const TotalSize = r =>
  MetaField('Total size (in MB)', r?.storage?.TotalStorageCapacityInMegaBytes);
export const IAMRoles = r =>
  MetaField(
    'IAM roles',
    map(r?.security?.IamRoles, o => o?.IamRoleArn),
    { colSpan: 2 },
  );
export const NodeType = r => MetaField('Node type', r?.config?.NodeType);
export const NumberOfNodes = r =>
  MetaField('Number of nodes', r?.config?.NumberOfNodes);
export const ElasticIP = r =>
  MetaField('Elastic IP', r?.network?.ElasticIpStatus?.ElasticIp);
export const EndpointURL = r =>
  MetaField(
    'Elastic URL (URL : port)',
    r?.identifier?.Endpoint?.Address + ' : ' + r?.identifier?.Endpoint?.Port,
  );
export const EndpointID = r =>
  MetaField(
    'Endpoint IDs',
    r?.identifier?.Endpoint?.VpcEndpoints?.map(o => o?.VpcEndpointId),
  );
export const RedshiftVersion = r =>
  MetaField('Version', r?.config?.ClusterVersion);
export const KMSID = r =>
  MetaField('KMS ID', r?.identifier?.KmsKeyId ?? r?.identifier?.KmsMasterKeyId);
export const AvailabilityStatus = r =>
  MetaField('Availability status', r?.config?.ClusterAvailabilityStatus);
export const AllowVersionUpgrade = r =>
  MetaField('Allow version upgrade', r?.config?.AllowVersionUpgrade);
export const SnapshotRetentionPeriod = r =>
  MetaField(
    'Snapshot retention period',
    'Automated: ' +
      r?.config?.AutomatedSnapshotRetentionPeriod +
      ', Manual: ' +
      r?.config?.ManualSnapshotRetentionPeriod,
    { colSpan: 2 },
  );
export const DatabaseName = r =>
  MetaField('Database name', r?.identifier?.DBName);
export const PublicKey = r =>
  MetaField('Public key', r?.security?.ClusterPublicKey, { colSpan: 3 });

// Group
export const Federation = r => MetaField('Federation', r?.federated);

// Kubernetes
export const EndpointPublicURL = r =>
  MetaField('Endpoint public URL', r?.config?.endpoint, { colSpan: 2 });
export const PublicAccessEnabled = r =>
  MetaField(
    'Public Access Enabled',
    r?.network?.resourcesVpcConfig?.endpointPublicAccess,
  );
export const PrivateAccessEnabled = r =>
  MetaField(
    'Private Access Enabled',
    r?.network?.resourcesVpcConfig?.endpointPrivateAccess,
  );
export const PublicAccessCIDR = r =>
  MetaField(
    'Public access CIDR',
    r?.network?.resourcesVpcConfig?.publicAccessCidrs,
  );
export const RoleARN = r =>
  MetaField(
    'Role ARN',
    r?.connection?.IAMRole ||
      r?.identifier?.RoleArn ||
      r?.identifier?.roleArn ||
      r?.identifier?.taskRoleArn,
    {
      colSpan: 2,
    },
  );
export const InterfaceSecurityGroups = r =>
  MetaField(
    'Interface security groups',
    r?.network?.resourcesVpcConfig?.securityGroupIds,
  );
export const ClusterSecurityGroups = r =>
  MetaField(
    'Cluster security group',
    r?.network?.resourcesVpcConfig?.clusterSecurityGroupId,
  );
export const IPFamily = r =>
  MetaField('IP family', r?.network?.kubernetesNetworkConfig?.ipFamily);
export const ServiceIPv4CIDR = r =>
  MetaField(
    'Service IPv4 CIDR',
    r?.network?.kubernetesNetworkConfig?.serviceIpv4Cidr,
  );
export const AttachedVMs = r =>
  MetaField(
    'Attached VMs',
    r?.instances?.map(o => {
      const items = o?.split(',');
      const name = split(items[1], ': ')?.[1];
      const id = split(items[0], ': ')?.[1];
      return {
        name: name ? name + ' (' + id + ')' : id,
        id,
      };
    }),
    {
      linkResourceType: NativeResources.Ec2Instance,
      colSpan: 2,
    },
  );

// User
export const DesignatedAdmin = r =>
  MetaField('Designated admin', r?.is_designated_admin);
export const ConsoleAccess = r =>
  MetaField('Console access', r?.has_console_access, {
    withDot: true,
    oppositeColor: true,
  });
const getPrivilegeLevel = r => {
  const value = r?.unused_services?.total;
  let level = '';
  if (value < 5) level = '< 5';
  else if (value >= 5 && value <= 25) level = '5-25';
  else if (value >= 25 && value <= 50) level = '25-50';
  else level = '> 50';
  return level + ' services';
};
export const PrivilegeLevel = r =>
  MetaField('Console access', getPrivilegeLevel(r));
export const UserGroupsAndPolicies = r =>
  MetaField(
    'Groups / Policies',
    r?.group_count + ' / ' + r?.group_policy_count,
  );
export const ResourcesOwned = r => MetaField('Resources owned', '~');
export const ConfidentialDataAccess = r =>
  MetaField('Confidential data access?', '~');
export const KMSKeysOwned = r => MetaField('KMS keys owned', '~');
export const KMSKeysUsed = r => MetaField('KMS keys used', '~');
export const GrantsGiven = r => MetaField('Grants given', '~');
export const BusinessCriticalAccess = r =>
  MetaField('Business critical access?', '~');

//ECSCluster
export const RegisteredContainers = r =>
  MetaField('Registered container', r?.config?.containerInstances?.length);
export const TaskDefinitions = r => MetaField('Task definitions', '');
export const RunningPendingTasks = r =>
  MetaField(
    'Running / Pending tasks',
    r?.compute?.runningTasksCount + ' / ' + r?.compute?.pendingTasksCount,
  );

//ECSCluster
export const ECSServices = r => MetaField('Services', '');
export const ClusterArn = r =>
  MetaField('Cluster ARN', r?.identifier?.clusterArn, { colSpan: 2 });
export const ServiceArn = r => MetaField('Service ARN', '');
export const ECSPlatformVersion = r => MetaField('Platform version', '');
export const ECSPlatformFamily = r => MetaField('Platform family', '');
export const PendingCount = r =>
  MetaField('Pending count', r?.compute?.pendingCount);
export const DesiredCount = r =>
  MetaField('Desired count', r?.compute?.desiredCount);
export const RunningCount = r =>
  MetaField('Running count', r?.compute?.runningCount);
export const RunningPendingCount = r =>
  MetaField(
    'Running / Pending count',
    r?.compute?.runningCount + ' / ' + r?.compute?.pendingCount,
  );
export const NetworkConfiguration = r => MetaField('Network configuration', '');
export const Subnets = r =>
  MetaField('Subnets', r?.connection?.Subnet ?? r?.config?.SubnetIds, {
    linkResourceType: NativeResources.Subnet,
  });
export const LoadBalancers = r => MetaField('Load balancers', '');
export const ServiceRegistries = r => MetaField('Service registries', '');
/* eslint-enable */

//ECS Service
export const AssignPublicIP = r =>
  MetaField(
    'Assign public IP',
    r?.network?.networkConfiguration.awsvpcConfiguration.assignPublicIp,
  );
export const DeploymentController = r =>
  MetaField('Deployment controller', r?.config?.deploymentController?.type);

//ECS Task Definition
export const NetworkMode = r =>
  MetaField('Network mode', r?.network?.networkMode);
export const IPCMode = r => MetaField('IPC mode', r?.config?.ipcMode);
export const PIDMode = r => MetaField('PID mode', r?.config?.pidMode);
export const ExecutionRoleARN = r =>
  MetaField('Execution role ARN', r?.identifier?.executionRoleArn, {
    colSpan: 2,
  });
export const Family = r => MetaField('Family', r?.config?.family);
export const Revision = r => MetaField('Revision', r?.config?.revision);
export const DNSServers = r => MetaField('DNS servers', r?.dnsServers);
export const ReadOnlyRootFilesystem = r =>
  MetaField('Read-only root filesystem', r?.readonlyRootFilesystem);
export const InitProcessEnabled = r =>
  MetaField('Init process enabled', r?.linuxParameters?.initProcessEnabled);
export const Condition = r => MetaField('Condition', r?.dependsOn?.condition);
export const Secrets = r => MetaField('Secrets', r?.secrets, { colSpan: 2 });
export const Environment = r => MetaField('Environment', '', { colSpan: 4 });
export const MountPoints = r =>
  MetaField(
    'Mount points',
    r?.mountPoints?.map(
      o => o?.sourceVolume + ': ' + (o?.readOnly ? 'Yes' : 'No'),
    ),
  );
export const HealthCheck = r => MetaField('Health check', r?.command);

//ECS Task
export const LaunchType = r =>
  MetaField('Launch type', r?.config?.launchType || r?.compute?.launchType);
export const DesiredStatus = r =>
  MetaField('Desired status', r?.compute?.desiredStatus);
export const HealthStatus = r =>
  MetaField('Health status', r?.compute?.healthStatus);
export const LastStatus = r => MetaField('Last status', r?.compute?.lastStatus);
export const Group = r => MetaField('Group', r?.config?.group);
export const ECSTaskStorage = r =>
  MetaField('Storage', r?.config?.ephemeralStorage);
export const ECSTaskAllocation = r =>
  MetaField(
    'Allocation',
    r?.config?.cpu + ' CPU units, ' + r?.config?.memory + ' MiB',
  );
export const Connectivity = r =>
  MetaField('Connectivity', r?.config?.connectivity);
export const TaskDefinitionARN = r =>
  MetaField(
    'Task definition ARN',
    r?.identifier?.taskDefinitionArn || r?.config?.taskDefinition,
    {
      colSpan: 2,
    },
  );
export const PlatformFamilyAndVersion = r =>
  MetaField(
    'Platform family / version',
    (r?.config?.platformFamily ?? '-') +
      ' / ' +
      (r?.config?.platformVersion ?? '-'),
  );
export const DesiredAndRunningCount = r =>
  MetaField(
    'Desired / Running count',
    (r?.config?.desiredCount || 0) + ' / ' + (r?.config?.runningCount || 0),
  );

//ECS Container Instance
export const VirtualMachineInstanceID = r =>
  MetaField('Virtual machine instance ID', r?.identifier?.ec2InstanceId, {
    linkResourceType: NativeResources.Ec2Instance,
  });
export const CapacityProviderName = r =>
  MetaField('Capacity provider name', r?.identifier?.capacityProviderName);
export const Allocation = r =>
  MetaField(
    'Allocation',
    find(r?.config?.registeredResources, o => o.name === 'CPU')?.integerValue +
      ' CPU units, ' +
      find(r?.config?.registeredResources, o => o.name === 'MEMORY')
        ?.integerValue +
      ' MiB',
  );
export const Ports = r =>
  MetaField(
    'Ports',
    find(
      r?.config?.registeredResources,
      o => o.name === 'PORTS',
    )?.stringSetValue?.join(', '),
  );
export const AgentConnected = r =>
  MetaField('Agent connected', r?.config?.agentConnected);
export const Agent = r =>
  MetaField(
    'Agent (version / hash)',
    r?.config?.versionInfo?.agentVersion +
      ' / ' +
      r?.config?.versionInfo?.agentHash,
  );
export const DockerVersion = r =>
  MetaField('Docker version', r?.config?.versionInfo?.dockerVersion);
export const RegisteredAt = r =>
  MetaField('Registered at', r?.config?.registeredAt, { time: 'dayjs' });

//Container
export const ContainerID = r => MetaField('Container ID', r?.container_id);
export const DeploymentRegion = r => MetaField('Deployment region', r?.region);
export const DeploymentVPC = r => MetaField('Deployment VPC', r?.vpc);
export const DeploymentType = r => MetaField('Deployment Type', '');
export const DeployedOn = r => MetaField('Deployed on', '');
export const DeploymentID = r => MetaField('Deployment ID', '');
export const Namespace = r => MetaField('Namespace', r?.config?.namespace);
export const Pod = r => MetaField('Pod', r?.config?.pod);
export const IpAddress = r => MetaField('IP address', '');
export const Technologies = r => MetaField('Technologies', '');

//Elastic Container Repository
export const RegistryID = r =>
  MetaField('Registry ID', r?.identifier?.registryId);
export const ImageId = r => MetaField('Image', '');
export const ScanOnPush = r =>
  MetaField('Scan on push', r?.config?.imageScanningConfiguration?.scanOnPush, {
    enableLabel: true,
  });
export const ImageTagMutability = r =>
  MetaField('Image tag mutability', r?.config?.imageTagMutability);
export const EncryptionType = r =>
  MetaField(
    'Encryption type',
    r?.security?.encryptionConfiguration?.encryptionType,
  );
export const ContainerImages = r =>
  MetaField('Container images', r?.container_image_count);

//Container

//ECR Image
export const PushedAt = r =>
  MetaField('Pushed at', r?.config?.imagePushedAt, { time: 'dayjs' });
export const RepositoryName = r => MetaField('Repository name', '');
export const ImageManifestMediaType = r =>
  MetaField('Image manifest media type', r?.config?.imageManifestMediaType);
export const ImageSize = r =>
  MetaField('Image size (in Bytes)', r?.storage?.imageSizeInBytes);
export const ImageDigest = r =>
  MetaField('Image digest', r?.identifier?.imageDigest, { colSpan: 2 });
export const ImageScanStatus = r =>
  MetaField('Image scan status', r?.compute?.imageScanStatus?.status);
export const FindingsSeverityCount = r =>
  MetaField(
    'Findings severity count',
    r?.compute?.imageScanFindingsSummary?.findingSeverityCounts
      ?.values()
      ?.sum(),
  );
export const LastRecordedPullTime = r =>
  MetaField('Last recorded pull time', r?.config?.lastRecordedPullTime, {
    time: 'dayjs',
  });
export const ArtifactMediaType = r =>
  MetaField('Artifact media type', r?.config?.artifactMediaType);

//ElastiCache
export const ElastiCacheEngine = r =>
  MetaField(
    'Engine',
    r?.config?.Engine + ' (' + r?.config?.EngineVersion + ')',
  );
export const PreferredAZ = r =>
  MetaField('Preferred AZ', r?.config?.PreferredAvailabilityZone);
export const ParameterGroups = r =>
  MetaField(
    'Parameter group',
    r?.config?.CacheParameterGroup?.CacheParameterGroupName,
  );
export const ReplicationGroupID = r =>
  MetaField('Replication group ID', r?.identifier?.ReplicationGroupId);
export const SnapshotWindow = r =>
  MetaField('Snapshot window', r?.config?.SnapshotWindow);
export const SnapshotRetention = r =>
  MetaField('Snapshot retention', r?.config?.SnapshotRetentionLimit);
export const TopicStatus = r =>
  MetaField('Topic status', r?.config?.NotificationConfiguration?.TopicStatus);
export const AuthToken = r =>
  MetaField('Auth token enabled', r?.security?.AuthTokenEnabled);
export const EncryptInTransit = r =>
  MetaField('Encrypt in transit', r?.security?.TransitEncryptionEnabled);
export const EncryptAtRest = r =>
  MetaField('Encrypt at rest', r?.security?.AtRestEncryptionEnabled);
export const PreferredMaintenanceWindow = r =>
  MetaField(
    'Preferred maintenance window',
    r?.config?.PreferredMaintenanceWindow,
  );
export const AutoMinorVersionUpgrade = r =>
  MetaField('Auto minor version upgrade', r?.config?.AutoMinorVersionUpgrade);

//ELB
export const Scheme = r => MetaField('Scheme', r?.config?.Scheme);
export const Instances = r =>
  MetaField(
    'Instances',
    map(r?.compute?.Instances, o => o?.InstanceId),
  );
export const SourceSecurityGroup = r =>
  MetaField(
    'Source security group',
    r?.network?.SourceSecurityGroup?.GroupName,
  );

//ElasticMapReduce
export const OSReleaseLabel = r =>
  MetaField('OS release label', r?.config?.ReleaseLabel);
export const LogURI = r => MetaField('Log URI', r?.config?.LogUri);
export const EC2Instances = r =>
  MetaField(
    'EC2 instances',
    r?.compute?.Instances?.map(o => o?.Ec2InstanceId),
    {
      linkResourceType: NativeResources.Ec2Instance,
    },
  );
export const CustomAMIID = r =>
  MetaField('Custom AMI ID', r?.identifier?.CustomAmiId);
export const ServiceRole = r =>
  MetaField('Service role', r?.identifier?.ServiceRole);
export const TerminationProtected = r =>
  MetaField('Termination protected', r?.config?.TerminationProtected);
export const Applications = r =>
  MetaField(
    'Applications',
    r?.config?.Applications?.map(o => o?.Name + '/' + o?.Version),
  );
export const VisibleToAllUsers = r =>
  MetaField('Visible to all users', r?.config?.VisibleToAllUsers);
export const MasterDNSName = r =>
  MetaField('Master DNS name', r?.identifier?.MasterPublicDnsName);
export const AutoTerminate = r =>
  MetaField('Auto terminate', r?.config?.AutoTerminate);
export const IAMInstanceProfile = r =>
  MetaField(
    'IAM instance profile',
    r?.compute?.Ec2InstanceAttributes?.IamInstanceProfile,
  );
export const MasterSecurityGroup = r =>
  MetaField(
    'Master security group',
    r?.compute?.Ec2InstanceAttributes?.EmrManagedMasterSecurityGroup,
  );
export const SlaveSecurityGroup = r =>
  MetaField(
    'Slave security group',
    r?.compute?.Ec2InstanceAttributes?.EmrManagedSlaveSecurityGroup,
  );
export const ServiceAccessSecurityGroup = r =>
  MetaField(
    'Service access security group',
    r?.compute?.Ec2InstanceAttributes?.ServiceAccessSecurityGroup,
  );

//Virtual Private Gateway
export const TypeOfVPNConnection = r =>
  MetaField('Type of VPN connection', '~');
export const VPCAttachments = r => MetaField('VPC attachments', '~');

//API Gateway
export const EndpointType = r =>
  MetaField('Endpoint type', r?.config?.endpointConfiguration?.types);
export const CrossOriginResourceSharing = r =>
  MetaField('Cross-origin resource sharing', '~');
export const Proxy = r => MetaField('Proxy', r?.config?.proxy);
export const IntegrationType = r =>
  MetaField('Integration type', r?.methodIntegration?.type);
export const UseDefaultTimeOut = r =>
  MetaField('Use default time out', r?.methodIntegration?.timeoutInMillis);
export const APIGatewayEndpoint = r =>
  MetaField('Endpoint', r?.methodIntegration?.uri, { colSpan: 2 });
export const Authorization = r =>
  MetaField('Authorization', r?.authorizationType);
export const RequestValidator = r =>
  MetaField('Request validator', r?.requestValidatorId);
export const APIKeyRequired = r =>
  MetaField('API key required', r?.apiKeyRequired);
export const DetailedMetrics = r =>
  MetaField('Detailed metrics', r?.metricsEnabled);
export const ClientCertificates = r =>
  MetaField(
    'Client certificates',
    r?.config?.stages?.map(o => o?.clientCertificateId),
  );

//Snapshot
export const SnapshotDescription = r =>
  MetaField('Description', r?.config?.Description, { colSpan: 2 });
export const OwnerAlias = r => MetaField('Owner alias', r?.config?.OwnerAlias);
export const VolumeID = r => MetaField('Volume ID', r?.identifier?.VolumeId);
export const VolumeSize = r => MetaField('Volume size (GiB)', r?.volume_size);
export const EncryptedKeyID = r =>
  MetaField('Encryption key ID', r?.identifier?.DataEncryptionKeyId);
export const KMSKeyID = r =>
  MetaField(
    'KMS key ID',
    r?.identifier?.KmsKeyId ||
      r?.identifier?.KmsMasterKeyId ||
      r?.security?.encryptionConfiguration?.kmsKey,
  );
export const SnapshotProgress = r => MetaField('Progress', r?.config?.Progress);
export const StorageTier = r => MetaField('Storage tier', r?.storage_tier);
export const DBInstanceID = r =>
  MetaField('DB instance ID', r?.identifier?.DBInstanceIdentifier);
export const DBEngine = r =>
  MetaField(
    'DB Engine (type / version)',
    r?.config?.Engine + ' / ' + r?.config?.EngineVersion,
  );
export const SnapshotType = r =>
  MetaField('Snapshot type', r?.config?.SnapshotType);
export const Storage = r =>
  MetaField(
    'Storage (type / size)',
    r?.storage?.StorageType + ' / ' + r?.storage?.AllocatedStorage,
  );
export const Port = r => MetaField('Port', r?.network?.Port);
export const IAMDBAuthentication = r =>
  MetaField(
    'IAM DB authentication',
    r?.security?.IAMDatabaseAuthenticationEnabled,
  );

//Secret
export const RotationEnabled = r =>
  MetaField('Rotation enabled', r?.config?.RotationEnabled);
export const LastRotationDate = r =>
  MetaField('Last rotation date', r?.config?.LastRotatedDate);
export const RotationFunction = r =>
  MetaField('Rotation function (Lambda ARN)', r?.config?.RotationLambdaARN, {
    colSpan: 2,
  });
export const RotationRules = r =>
  MetaField(
    'Rotation rules (Automatically after days)',
    r?.config?.RotationRules?.AutomaticallyAfterDays,
  );
export const LastChangeDate = r =>
  MetaField(
    'Last change date',
    `${dayjs(r?.config?.LastChangedDate).format('llll')}`,
  );
export const LastAccessedDate = r =>
  MetaField('Last accessed date', r?.config?.LastAccessedDate, {
    time: 'dayjs',
  });
export const DeleteDate = r =>
  MetaField('Delete date', r?.config?.DeletedDate, { time: 'dayjs' });
export const ReplicationStatus = r => MetaField('Replication status', '~');
export const VersionIDCurrent = r =>
  MetaField('Version ID – Current', r?.config?.CurrentVersionIds);
export const VersionIDPrevious = r =>
  MetaField('Version ID – Previous', r?.config?.PreviousVersionIds);
export const VersionIDPending = r =>
  MetaField('Version ID – Pending', r?.config?.PendingVersionIds);

//ACM Certificate
export const Issuer = r => MetaField('Issuer', r?.config?.Issuer);
export const IssuedAt = r => MetaField('Issued at', r?.config?.IssuedAt);
export const KeyAlgorithm = r =>
  MetaField('Key algorithm', r?.security?.KeyAlgorithm);
export const Serial = r =>
  MetaField('Serial', r?.identifier?.Serial, { colSpan: 2 });
export const Subject = r => MetaField('Subject', r?.config?.Subject);
export const CertificateType = r =>
  MetaField('Type', r?.config?.Type || r?.config?.CertificateType);
export const AcmCertificateUsedBy = r => MetaField('Used by', '~');
export const CertificateAuthority = r =>
  MetaField('Certificate authority', '~');
export const KeyUsages = r =>
  MetaField(
    'Key usages',
    r?.config?.KeyUsages?.map(o => o.Name),
  );
export const RenewalStatus = r =>
  MetaField('Renewal status', r?.config?.RenewalEligibility);
export const AcmCertificateUpdatedAt = r => MetaField('Updated at', '~');
export const SignatureAlgorithm = r =>
  MetaField('Signature algorithm', r?.security?.SignatureAlgorithm);

//RDS Certificate
export const Thumbprint = r => MetaField('Thumbprint', r?.config?.Thumbprint);
export const Validity = r =>
  MetaField('Validity', `${r?.config?.ValidFrom} - ${r?.config?.ValidTill}`);
export const CustomerOverride = r =>
  MetaField('Customer override', r?.config?.CustomerOverride);
export const CustomerOverrideExpiry = r =>
  MetaField('Customer override expiry', r?.config?.CustomerOverrideValidTill);

//WAF
export const ManagedByFirewallManager = r =>
  MetaField('Managed by firewall manager', r?.config?.ManagedByFirewallManager);
export const ManagedRuleGroups = r =>
  MetaField(
    'Managed rule groups',
    r?.config?.ManagedRuleGroups?.map(o => o?.Name),
  );
export const RuleGroups = r =>
  MetaField(
    'Rule groups',
    r?.config?.RuleGroups?.map(o => o?.Name),
  );
export const WebACLs = r => MetaField('Web ACLs', '~');
export const WafRules = r => MetaField('Rules', r?.config?.Rules?.length);
export const WafV2Rules = r =>
  MetaField(
    'Rules',
    r?.config?.Rules?.map(o => o?.Name),
  );

//Cloud Front
export const DomainName = r => MetaField('Domain name', r?.network?.DomainName);
export const WAFACLID = r =>
  MetaField('WAF ACL ID', r?.identifier?.WebACLId, { colSpan: 2 });
export const HTTPVersion = r =>
  MetaField('HTTP version', r?.network?.HttpVersion);
export const IPv6Enabled = r =>
  MetaField('IPv6 enabled', r?.network?.IsIPV6Enabled);
export const Stage = r => MetaField('Stage', '~');
export const TargetOriginID = r =>
  MetaField(
    'Target origin ID',
    r?.config?.DefaultCacheBehavior?.TargetOriginId,
    { colSpan: 2 },
  );
export const AllowedMethods = r =>
  MetaField(
    'Allowed methods',
    r?.config?.DefaultCacheBehavior?.AllowedMethods?.Items,
  );
export const Compress = r =>
  MetaField('Compress', r?.config?.DefaultCacheBehavior?.Compress);
export const TrustedKeyGroups = r =>
  MetaField(
    'Trusted key groups',
    r?.config?.DefaultCacheBehavior?.TrustedKeyGroups?.Enabled,
  );

//sagemaker
export const PlatformID = r =>
  MetaField('Platform ID', r?.identifier?.PlatformIdentifier);
export const Url = r =>
  MetaField('URL', r?.config?.Url || r?.identifier?.repositoryUri);
export const VolumeSizeInGB = r =>
  MetaField('Volume size (in GB)', r?.storage?.VolumeSizeInGB);
export const DefaultRepository = r =>
  MetaField('Default repository', r?.config?.DefaultCodeRepository);
export const RootAccess = r => MetaField('Root access', r?.config?.RootAccess);
export const MetaDataConfiguration = r =>
  MetaField(
    'Meta data configuration',
    r?.config?.InstanceMetadataServiceConfiguration,
  );

//Transit gateway
export const TransitGatewayVPCIds = r =>
  MetaField(
    'VPC Ids',
    map(r?.network?.TransitGatewayVpcAttachments, e => e.VpcId),
  );
export const AmazonSideASN = r =>
  MetaField('Amazon side ASN', r?.config?.Options?.AmazonSideAsn);
export const AutoAcceptSharedAttachments = r =>
  MetaField(
    'Auto accept shared attachments',
    r?.config?.Options?.AutoAcceptSharedAttachments,
  );
export const DefaultRouteTableAssociation = r =>
  MetaField(
    'Default route table association',
    r?.config?.Options?.DefaultRouteTableAssociation,
  );
export const DefaultRouteTableID = r =>
  MetaField(
    'Default route table ID',
    r?.config?.Options?.AssociationDefaultRouteTableId,
  );
export const DefaultRouteTablePropagation = r =>
  MetaField(
    'Default route table propagation',
    r?.config?.Options?.DefaultRouteTablePropagation,
  );
export const VPNECMPSupport = r =>
  MetaField('VPN ECMP support', r?.config?.Options?.VpnEcmpSupport);
export const DNSSupport = r =>
  MetaField(
    'DNS support',
    r?.config?.Options?.DnsSupport ??
      (r?.config?.DnsSupport === 'enable' ? 'Enabled' : 'Disabled'),
  );

//NAT gateway
export const ConnectivityType = r =>
  MetaField('Connectivity type', r?.network?.ConnectivityType);

//Transit gateway attachment
export const TransitGatewayID = r =>
  MetaField('Transit gateway ID', r?.transit_gateway_attachment_id);
export const TransitGatewayOwnerID = r =>
  MetaField('Transit gateway owner ID', r?.transit_gateway_owner_id);
export const VPCOwnerID = r => MetaField('VPC owner ID', r?.config?.VpcOwnerId);
export const IPv6Support = r =>
  MetaField(
    'IPv6 support',
    r?.config?.Ipv6Support === 'enable' ? 'Enabled' : 'Disabled',
  );

//Route table
export const MainRouteTable = r =>
  MetaField(
    'Main route table',
    r?.config?.MainRouteTable ||
      r?.config?.Associations?.find(o => o?.Main)?.RouteTableId,
  );
export const RouteTableSubnetID = r =>
  MetaField(
    'Subnet IDs',
    r?.config?.SubnetIds || r?.config?.Associations?.map(o => o?.SubnetId),
    { linkResourceType: NativeResources.Subnet },
  );

//Route
export const DestinationCIDRBlock = r =>
  MetaField('Destination CIDR block', '~');
export const DestinationPrefixID = r => MetaField('Destination prefix ID', '~');
export const EgressOnlyInternetGatewayID = r =>
  MetaField('Egress only internet gateway ID', '~');
export const GatewayID = r => MetaField('Gateway ID', '~');
export const NATInstanceID = r => MetaField('NAT instance ID', '~');
export const NATGatewayID = r => MetaField('NAT gateway ID', '~');
export const RouteTransitGatewayID = r => MetaField('Transit gateway ID', '~');
export const LocalGatewayID = r => MetaField('Local gateway ID', '~');
export const NetworkInterfaceID = r => MetaField('Network interface ID', '~');
export const RouteCreatedBy = r => MetaField('Route created by', '~');
export const VPCPeeringID = r => MetaField('VPC peering ID', '~');
export const CoreNetworkARN = r => MetaField('core network ARN', '~');

//Okta User
export const TransitioningToStatus = r =>
  MetaField('Transitioning to status', r?.config?.transitioningToStatus);
export const Login = r => MetaField('Login', r?.config?.login);
export const FullName = r => MetaField('Full name', r?.config?.fullName);
export const DisplayName = r =>
  MetaField(
    'Display name',
    r?.identifier?.displayName || r?.config?.display_name,
  );
export const SecondaryEmail = r =>
  MetaField('Secondary email', r?.config?.secondEmail || r?.config?.otherMails);
export const ProfileURL = r => MetaField('Profile URL', r?.config?.profileUrl);
export const UserType = r => MetaField('User type', r?.config?.userType);
export const OktaOrganization = r =>
  MetaField('Organization', r?.config?.organization);
export const Division = r => MetaField('Division', r?.config?.division);
export const Title = r =>
  MetaField('Title', r?.config?.title || r?.config?.jobTitle);
export const Department = r => MetaField('Department', r?.config?.department);
export const CostCenter = r => MetaField('Cost center', r?.config?.costCenter);
export const EmployeeNumber = r =>
  MetaField('Employee number', r?.config?.employeeNumber);
export const ActivatedAt = r =>
  MetaField('Activated at', r?.config?.activated, { time: 'utc' });
export const LastLogin = r =>
  MetaField('Last login', r?.config?.lastLogin, { time: 'utc' });
export const PasswordChangedAt = r =>
  MetaField(
    'Password changed at',
    r?.config?.passwordChanged || r?.config?.las,
    { time: 'utc' },
  );
export const StateChangedAt = r =>
  MetaField('State changed at', r?.config?.statusChanged, { time: 'utc' });
export const PasswordPolicies = r =>
  MetaField('Password policies', r?.config?.passwordPolicies);
export const OktaDomains = r =>
  MetaField('Okta domains', r?.connection?.OktaDomain, {
    linkResourceType: NativeResources.OktaDomain,
  });

//Okta Group
export const OktaGroupType = r => MetaField('Type', r?.config?.type);
export const ID = r => MetaField('ID', r?.resource_id ?? r?.identifier?.id);
export const DistinguishedName = r =>
  MetaField('Distinguished name', r?.config?.dn);
export const WindowsDomainQualifiedName = r =>
  MetaField(
    'Windows domain qualified name',
    r?.config?.windowsDomainQualifiedName,
  );
export const ExternalID = r => MetaField('External ID', r?.config?.externalId);
export const SAMAccountName = r =>
  MetaField('SAM account name', r?.config?.samAccountName);
export const ObjectSid = r => MetaField('Object Sid', r?.config?.objectSid);
export const ObjectClass = r =>
  MetaField('Object class', r?.config?.objectClass);
export const GroupMembers = r =>
  MetaField(
    'Group members',
    r?.config?.members?.map(o => o?.id),
    { linkResourceType: NativeResources.OktaUser },
  );
export const LastMemberUpdated = r =>
  MetaField('Last member updated', r?.config?.lastMembershipUpdated, {
    time: 'utc',
  });

//Okta Domain
export const OktaDomain = r => MetaField('Domain', r?.identifier?.domain);
export const ValidatedStatus = r =>
  MetaField('Validated status', r?.config?.validationStatus);
export const CertificateSourceType = r =>
  MetaField('Certificate source type', r?.config?.certificateSourceType);

//Okta Organization
export const CompanyName = r =>
  MetaField('Company name', r?.identifier?.companyName);
export const OktaSubDomain = r => MetaField('Sub-domain', r?.config?.subdomain);
export const Country = r => MetaField('Country', r?.config?.country);
export const Website = r => MetaField('Website', r?.config?.website);
export const ExpiresAt = r =>
  MetaField('Expires at', r?.config?.expiresAt, { time: 'utc' });

//AzureAd User
export const UserPrincipalName = r =>
  MetaField('User principal name', r?.config?.userPrincipalName);
export const SignInName = r => MetaField('Sign-in name', '~');
export const AccountEnabled = r =>
  MetaField('Account enabled', r?.config?.accountEnabled);
export const AzureAdUsername = r => MetaField('Username', '~');
export const AzureAdName = r => MetaField('Name', r?.identifier?.name);
export const MFAPhoneNumber = r =>
  MetaField('MFA phone number', r?.config?.mfaPhoneNumber);
export const NetID = r => MetaField('Net ID', '~');
export const CreationType = r =>
  MetaField('Creation type', r?.config?.creationType);

//AzureAd group
export const MembershipType = r => MetaField('Membership type', '~');
export const Owners = r => MetaField('Owners', r?.config?.owners?.length);
export const UserMembers = r =>
  MetaField('User members', r?.config?.member_users?.length);

//AzureAd Tenant
export const OrganizationName = r => MetaField('Organization name', '~');
export const OrganizationID = r => MetaField('Organization ID', '~');
export const InitialDomainName = r => MetaField('Initial domain name', '~');

//Route53 Hosted Zone
export const CallerReference = r =>
  MetaField('Caller reference', r?.identifier?.CallerReference);
export const DelegationSetID = r => MetaField('ID', '');
export const NameServers = r =>
  MetaField('Name Servers', r?.config?.DelegationSet?.NameServers);
export const PrivateZone = r =>
  MetaField('Private zone', r?.config?.Config?.PrivateZone);
export const Route53HostedZoneComment = r =>
  MetaField('Comment', r?.config?.Config?.Comment, { colSpan: 2 });
export const RecordSetCount = r =>
  MetaField('Record set count', r?.config?.ResourceRecordSetCount);
export const LinkedServices = r =>
  MetaField(
    'Linked services',
    r?.config?.LinkedService?.map(o => o?.ServicePrincipal),
  );
export const VPCs = r => MetaField('VPCs', '~');
export const ResourceRecordSets = r => MetaField('Resource record sets', '~');
export const TrafficPolicies = r =>
  MetaField('Traffic policies', r?.config?.TrafficPolicyInstances);

//Route53 Record Set
export const GeoLocation = r => MetaField('Geo Location', '~');
export const CountryCode = r => MetaField('Country code', '~');
export const SetIdentities = r => MetaField('Set identities', '~');
export const Weight = r => MetaField('Weight', '~');
export const Failover = r => MetaField('Failover', '~');
export const MultiValueAnswer = r => MetaField('Multi value answer', '~');
export const TTL = r => MetaField('TTL', '~');
export const DNSName = r => MetaField('DNS name', r?.identifier?.DNSName);
export const HealthCheckID = r => MetaField('Health check ID', '~');
export const TrafficPolicyInstanceID = r =>
  MetaField('Traffic policy instance ID', '~');
export const IsTruncated = r => MetaField('Is truncated', '~');

//Route53 Traffic Policy
export const ServicePrincipal = r => MetaField('Service principal', '~');

//Route53 Health check
export const Route53HealthCheckEndpoint = r =>
  MetaField(
    'Endpoint',
    r?.config?.HealthCheckConfig?.IPAddress +
      ':' +
      r?.config?.HealthCheckConfig?.Port,
  );
export const ResourcePath = r =>
  MetaField('Resource path', r?.config?.HealthCheckConfig?.ResourcePath);
export const FQDN = r =>
  MetaField('FQDN', r?.config?.HealthCheckConfig?.FullyQualifiedDomainName);
export const SearchString = r =>
  MetaField('Search string', r?.config?.HealthCheckConfig?.SearchString);
export const RequestInterval = r =>
  MetaField('Request interval', r?.config?.HealthCheckConfig?.RequestInterval);
export const FailureThreshold = r =>
  MetaField(
    'Failure threshold',
    r?.config?.HealthCheckConfig?.FailureThreshold,
  );
export const MeasureLatency = r =>
  MetaField('Measure latency', r?.config?.HealthCheckConfig?.MeasureLatency);
export const Disabled = r =>
  MetaField('Disabled', r?.config?.HealthCheckConfig?.Disabled);
export const Inverted = r =>
  MetaField('Inverted', r?.config?.HealthCheckConfig?.Inverted);
export const HealthThreshold = r =>
  MetaField('Health threshold', r?.config?.HealthCheckConfig?.HealthThreshold);
export const EnableSNI = r =>
  MetaField('Enable SNI', r?.config?.HealthCheckConfig?.EnableSNI);

//Key pair
export const Fingerprint = r =>
  MetaField('Fingerprint', r?.config?.KeyFingerprint, { colSpan: 2 });
export const AssociatedVMs = r =>
  MetaField(
    'Associated VMs',
    r?.compute?.Instances?.map(o => o?.InstanceId),
    { linkResourceType: NativeResources.Ec2Instance },
  );

//Auto Scaling Group
export const LaunchTemplateName = r =>
  MetaField(
    'Launch template name',
    r?.config?.LaunchTemplate?.LaunchTemplateName,
  );
export const LaunchTemplateID = r =>
  MetaField('Launch template ID', r?.config?.LaunchTemplate?.LaunchTemplateId);
export const MinimumMaximumSize = r =>
  MetaField(
    'Minimum / Maximum size',
    r?.config?.MinSize + ' / ' + r?.config?.MaxSize,
  );
export const DesiredCapacity = r =>
  MetaField('Desired capacity', r?.config?.DesiredCapacity);
export const DefaultCoolDown = r =>
  MetaField('Default cool down', r?.config?.DefaultCooldown);
export const AsgLoadBalancers = r =>
  MetaField('Load balancers', r?.config?.LoadBalancers);
export const VPCZoneID = r =>
  MetaField('VPC zone ID', r?.identifier?.VPCZoneIdentifier);
export const TargetGroupARNs = r =>
  MetaField('Target group ARNs', r?.identifier?.TargetGroupARNs);
export const HealthCheckType = r =>
  MetaField('Health check type', r?.config?.HealthCheckType);
export const HealthCheckGracePeriod = r =>
  MetaField('Health check grace period', r?.config?.HealthCheckGracePeriod);
export const NewInstancesProtectedFromScale = r =>
  MetaField(
    'New instances protected from scale',
    r?.config?.NewInstancesProtectedFromScaleIn,
  );
export const ServiceLinkedRoleARN = r =>
  MetaField('Service linked role ARN', r?.identifier?.ServiceLinkedRoleARN);

//Prefix List
export const PrefixListID = r =>
  MetaField('Prefix list ID', r?.resource_id, { colSpan: 2 });
export const ResourceAssociations = r =>
  MetaField(
    'Resource associations',
    r?.config?.ResourceAssociations?.map(o => o?.ResourceId),
  );
export const AddressFamily = r =>
  MetaField('Address family', r?.network?.AddressFamily);

//DHCP Options
export const DHCPOptionsID = r =>
  MetaField('DHCP options ID', r?.resource_id, { colSpan: 2 });

//IAM Group
export const Users = r => MetaField('Users', r?.attached_user_names);
export const IAMState = r =>
  MetaField('State', r?.user_count > 0 ? 'Attached' : 'Unattached');
export const IAMGroupAttachedPolicies = r =>
  MetaField(
    'Attached policies',
    r?.config?.GroupAttachedPolicies?.map(o => o?.PolicyName),
  );

//Document DB
export const HostedZoneID = r =>
  MetaField('Hosted zone ID', r?.idetifier?.Endpoint?.HostedZoneId);
export const EndpointAddress = r =>
  MetaField('Endpoint address', r?.idetifier?.Endpoint?.Address);
export const EndpointPort = r =>
  MetaField('Endpoint port', r?.idetifier?.Endpoint?.Port);
export const Engine = r =>
  MetaField('Engine', r?.config?.Engine + ' ' + r?.config?.EngineVersion);
export const Cluster = r =>
  MetaField('Cluster', r?.identifier?.DBClusterIdentifier);
export const CaCertificateID = r =>
  MetaField('CA certificate ID', r?.identifier?.CACertificateIdentifier);

//Elastic Container Registry
export const Repositories = r => MetaField('Repositories', '~');

//Neptune
export const License = r => MetaField('License', r?.config?.LicenseModel);
export const NeptuneEndpoint = r =>
  MetaField('Endpoint', r?.identifier?.Endpoint?.Address, { colSpan: 2 });
export const NeptuneClusterEndpoint = r =>
  MetaField('Endpoint', r?.config?.ReaderEndpoint);
export const ReaderEndpoint = r =>
  MetaField('Reader endpoint', r?.config?.ReaderEndpoint);
export const ListenPort = r =>
  MetaField('Listen port', r?.identifier?.Endpoint?.Port);
export const BackupRetentionDays = r =>
  MetaField('Backup retention (days)', r?.config?.BackupRetentionPeriod);
export const PerformanceInsights = r =>
  MetaField('Performance insights', r?.config?.PerformanceInsightsEnabled);
export const MonitoringARN = r =>
  MetaField('Monitoring ARN', r?.identifier?.MonitoringRoleARN);
export const ParameterGroup = r =>
  MetaField('Parameter group', r?.config?.DBClusterParameterGroup);
export const SubnetGroup = r =>
  MetaField('Subnet group', r?.network?.DBSubnetGroup);
export const NeptuneClusterInstances = r =>
  MetaField(
    'Instances',
    r?.security?.DBClusterMembers?.map(o => o?.DBInstanceIdentifier),
  );
export const AssociatedRoles = r =>
  MetaField(
    'Associated roles',
    r?.security?.AssociatedRoles?.map(o => o?.RoleArn),
  );
export const CrossAccountClone = r =>
  MetaField('Cross account clone', r?.config?.CrossAccountClone);

//Cloud Trail
export const LogFileValidation = r =>
  MetaField('Log file validation', r?.config?.LogFileValidationEnabled);
export const CloudWatchLogGroupARN = r =>
  MetaField(
    'CloudWatch log group ARN',
    r?.identifier?.CloudWatchLogsLogGroupArn,
  );
export const CloudWatchLogRoleARN = r =>
  MetaField('CloudWatch log role ARN', r?.identifier?.CloudWatchLogsRoleArn);
export const SNSTopicName = r =>
  MetaField('SNS Topic name', r?.identifier?.SnsTopicName);
export const SNSTopicARN = r =>
  MetaField('SNS Topic ARN', r?.identifier?.SnsTopicARN);
export const HasCustomEventSelectors = r =>
  MetaField('Has custom event selectors', r?.config?.HasCustomEventSelectors);
export const HasInsightSelectors = r =>
  MetaField('Has insight selectors', r?.config?.HasInsightSelectors);
export const IsOrganizationTrail = r =>
  MetaField('Is organization trail', r?.config?.IsOrganizationTrail);
export const LatestNotificationAt = r =>
  MetaField('Latest notification at', r?.config?.LatestNotificationTime);
export const IsLogging = r => MetaField('Is logging', r?.config?.IsLogging);
export const StartLoggingTime = r =>
  MetaField('Start logging time', r?.config?.StartLoggingTime);
export const StopLoggingTime = r =>
  MetaField('Stop logging time', r?.config?.StopLoggingTime);
export const S3Bucket = r =>
  MetaField('S3 bucket', r?.identifier?.S3BucketName, {
    linkResourceType: NativeResources.SimpleStorageService,
  });
export const IncludeGlobalServiceEvents = r =>
  MetaField(
    'Include global service events',
    r?.config?.IncludeGlobalServiceEvents,
  );

export function MetaField(
  field,
  value,
  props: Omit<MetadataGridFieldProps, 'label' | 'value'> = {},
) {
  // TODO: we can check if we are showing some value here and
  // automatically avoid showing that in show more section
  return <MetaGridField label={field} value={value} {...props} />;
}
