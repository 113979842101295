import React, { useCallback } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Card, PageHeaderWithIcon, renderTime } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
} from 'components/DataEntry';
import { CheckmarkCircleOutlineIcon, EditIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import {
  renderWhitelistCriteria,
  renderWhitelistEnvironments,
} from 'containers/Inventory/Whitelist/Components/utils';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';

import { actions } from '../slice';

export const WhitelistTable: React.FC = () => {
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { whitelists } = useSelector(selectWhitelist);

  const getWhitelist = useCallback(() => {
    dispatch(actions.getAllowLists({}));
  }, [dispatch]);

  useDeepCompareEffect(() => {
    getWhitelist();
    dispatch(actions.resetFormData());
  }, [getWhitelist]);

  const columns = [
    {
      header: <Box pl={0}>Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <HStack spacing={4}>
          {/*<Flex> {getCloudIcon(row?.cloud)}</Flex>*/}
          <Box>{row.name}</Box>
        </HStack>
      ),
    },
    {
      header: 'Environment',
      accessor: 'ids',
      align: 'left',
      render: renderWhitelistEnvironments,
    },
    /*{
      header: 'Accounts',
      accessor: 'account_ids',
      render: ({ value }) =>
        value?.length ? (
          <CustomTooltip
            label={
              <Stack>
                <Box>Accounts</Box>
                {map(value, o => (
                  <Box>{toCloudAccountId(o)}</Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        ) : (
          0
        ),
    },*/
    {
      header: 'Criteria',
      accessor: 'criteria',
      align: 'left',
      render: renderWhitelistCriteria,
    },
    {
      header: 'Status',
      accessor: 'status',
      align: 'left',
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Created At',
      accessor: 'created_at',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: ({ row }) => {
        return (
          <Center>
            <ActionButton
              label={'Edit'}
              icon={<EditIcon />}
              //isDisabled={row?.created_by === 'system'}
              isDisabled
              onClick={() => navigate(`/inventory/allowlist/edit/${row?.id}`)}
            />
            <DeleteActionButton
              onConfirm={() => {
                dispatch(
                  actions.deleteAllowList({
                    q: { allowlistId: row?.id },
                    onSuccess: () => getWhitelist(),
                  }),
                );
              }}
              name={row?.name}
              type="whitelist"
            />
          </Center>
        );
      },
    },
  ];

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon
            label="Inventory Allow List"
            icon={<CheckmarkCircleOutlineIcon />}
          />
          <Flex>
            <AddButton
              label="Add Allow List"
              onClick={() => {
                navigate('/inventory/allowlist/add');
              }}
            />
          </Flex>
        </Stack>
        <Box flex={1}>
          <Table
            data={whitelists.data}
            columns={columns}
            isLoading={whitelists.isLoading}
          />
        </Box>
      </Stack>
    </Card>
  );
};
