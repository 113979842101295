import React, { FC, useEffect } from 'react';

import { HStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { DeleteActionButton } from 'components/DataEntry';
import { selectUser } from 'containers/App/selectors';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions } from 'containers/Setup/Integrations/slice';

interface ISSOProvidersTable {}

export const SSOProvidersTable: FC<ISSOProvidersTable> = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(selectUser);
  const { sso } = useSelector(selectIntegrations);
  const { providers, deleteProvider } = sso;

  useEffect(() => {
    dispatch(actions.getSSOProviders());
  }, [dispatch]);

  const onDeleteSuccess = () => {
    dispatch(actions.getSSOProviders());
  };

  const onDelete = row => {
    dispatch(
      actions.deleteSSOProvider({
        organization: info.org_id,
        provider: row.provider,
        onSuccess: onDeleteSuccess,
      }),
    );
  };

  const renderAction = ({ row }) => {
    return (
      <HStack justify={'end'}>
        <DeleteActionButton
          onConfirm={() => onDelete(row)}
          isLoading={deleteProvider.loading}
          name={row?.config.client_id}
          type="SSO provider"
        />
      </HStack>
    );
  };

  const columns = [
    {
      header: 'Identity Provider Name',
      accessor: 'provider',
    },
    {
      header: 'Client ID',
      accessor: 'config.client_id',
      align: 'left',
    },
    {
      header: 'Action',
      styles: {
        textAlign: 'right',
        cell: { textAlign: 'right', overflow: 'visible' },
      },

      render: renderAction,
    },
  ];

  return (
    <Table
      columns={columns}
      data={providers.data}
      isLoading={providers.loading}
    />
  );
};
