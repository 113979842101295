import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CodeSha256,
  CodeSize,
  Handler,
  LambdaLastModified,
  MemorySize,
  Role,
  Runtime,
  SecurityGroups,
  Timeout,
} from '../../../Components/MetadataField/MetaGridFields';

export const LambdaMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {LambdaLastModified(resource)}
        {SecurityGroups(resource)}
        {CodeSha256(resource)}
        {Runtime(resource)}
        {MemorySize(resource)}
        {CodeSize(resource)}
        {Timeout(resource)}
        {Role(resource)}
        {Handler(resource)}
        <PolicyDocument
          label={'Lambda Policy'}
          value={resource?.config?.Policy?.Policy}
          isString
        />
      </Grid>
    </Stack>
  );
};
