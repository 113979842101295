import { Application, ICanvas } from 'pixi.js';

export const downloadMap = async (
  app?: Application<ICanvas> | null,
  name: string = 'graph',
) => {
  const image = await app?.renderer.plugins.extract.image(
    app?.stage,
    'image/wbem',
    1,
  );

  const link = document.createElement('a');

  document.body.appendChild(link);

  link.setAttribute('href', image.src);
  link.setAttribute('download', name);
  link.click();
};

export const isIdentity = type => {
  return ['IAMUser', 'IAMRole', 'IAMPolicy'].includes(type);
};
