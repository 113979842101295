import { useCallback } from 'react';

import { AlertsApiAlertReportRequest } from '@ariksa/notification/api';
import { useSelector } from 'react-redux';

import { NotificationService } from 'api/services';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { saveAsPDF } from 'containers/Visibility/Network/utils';

export const useAlertDownloader = () => {
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);

  const downloadReport = useCallback(
    (req: Omit<AlertsApiAlertReportRequest, 'accountId' & 'environmentId'>) => {
      NotificationService.Alerts.alertReport({
        accountId,
        environmentId,
        ...req,
      }).then(res => {
        saveAsPDF(res.data, 'alert-report');
      });
    },
    [accountId, environmentId],
  );

  return { downloadReport };
};
