import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountLocator,
  SnowflakeID,
  Type,
  Comment,
  Owner,
  Name,
  DisplayName,
  Privileges,
  CreatedAt,
  Description,
  AssignedUser,
  IsDefault,
  IsCurrent,
  IsInherited,
  AssignedToOtherRoles,
  GrantedRoles,
} from '../../../Components/MetadataField/MetaGridFields';

export const SnowflakeRoleMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(resource)}
      {AccountLocator(resource)}
      {SnowflakeID(resource)}
      {Comment(resource)}
      {Type(resource)}
      {Description(resource)}
      {Owner(resource)}
      {Privileges(resource)}
      {AssignedUser(resource)}
      {IsDefault(resource)}
      {DisplayName(resource)}
      {IsCurrent(resource)}
      {IsInherited(resource)}
      {AssignedToOtherRoles(resource)}
      {GrantedRoles(resource)}
      {CreatedAt(resource, '')}
    </Grid>
  );
};
