import React, { ReactNode } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { isEmpty, map, slice } from 'lodash';

import { AriksaIcon, Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

export const renderRiskContextIcon = (item, tooltips = {}) => {
  return (
    <CustomTooltip label={tooltips?.[item?.type] ?? item?.type}>
      <AriksaIcon type={item?.type} bg={item?.severity} />
    </CustomTooltip>
  );
};

export const renderRiskContext = (
  data: Record<string, any>[],
  tooltips?: Record<string, ReactNode>,
) => {
  const renderIcon = item => renderRiskContextIcon(item, tooltips);
  return !isEmpty(data) ? (
    <Stack spacing={1}>
      <HStack spacing={1}>
        {data?.[0] && renderIcon(data?.[0])}
        {data?.[1] && renderIcon(data?.[1])}
        {data?.[2] && renderIcon(data?.[2])}
      </HStack>
      <HStack spacing={1}>
        {data?.[4] && renderIcon(data?.[4])}
        {data?.[5] && renderIcon(data?.[5])}
        {data?.length > 5 && (
          <CustomTooltip
            label={
              <Stack>
                {map(slice(data, 6), (o, index) => (
                  <Box key={index + '-' + o?.type + '-risk-context'}>
                    {o?.type}
                  </Box>
                ))}
              </Stack>
            }
          >
            <Tag label={<Box>+ {data?.length - 5}</Box>} />
          </CustomTooltip>
        )}
      </HStack>
    </Stack>
  ) : (
    '-'
  );
};
