import React, { FC, useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';

import { selectAlertWorkflow } from '../../selectors';

import { useAlertWorkflowWizardContext } from './AlertWorkflowWizardContext';

interface Props {}

export const AlertWorkflowSettings: FC<Props> = () => {
  const { handleSubmit, isEdit, onCancel } = useAlertWorkflowWizardContext();
  const { formData, workflow } = useSelector(selectAlertWorkflow);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      description: '',
    },
  );

  useEffect(() => {
    updateState({ name: formData.name, description: formData.description });
  }, [formData]);

  return (
    <Box pt={4}>
      <Form
        title="Add Workflow Settings"
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            placeholder: 'Workflow name',
            isRequired: true,
            isDisabled: isEdit,
            onChange: value => updateState({ name: value }),
            value: state.name,
          },
          description: {
            type: 'textArea',
            label: 'Description',
            isRequired: true,
            placeholder:
              'This is a description of the workflow if needed to share with others',
            value: state.description,
            onChange: value => updateState({ description: value }),
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Next',
            isDisabled: !state.description || !state.name,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: onCancel,
          },
        }}
        isLoading={workflow.isLoading}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
