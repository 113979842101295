import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AmazonLinuxIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <g>
      <path
        fill="#232F3E"
        d="M159,379.7c0,6.5,0,12.8,0,19.1c0,5.1,0,10.3,0,15.4c0,1.3-0.3,2.7-0.6,4.4c-4.7-0.1-9,0.2-13.6-0.2
		c-0.7-4.4-0.3-8.7-0.3-12.8c-0.1-4.1,0-8.2,0-12.4c0-4.2,0-8.5,0-12.7c0-4.1,0-8.2,0-12.6c1.5-0.3,2.7-0.5,3.9-0.7
		c0.6-0.1,1.3,0,1.9,0c5.2,0,5.2,0,7.8,4.6c1.1-0.6,2.2-1.1,3.2-1.8c4.4-2.7,9.2-4.1,14.2-4.3c4.7-0.2,9.2,0.9,12.4,4.9
		c0.3,0.4,0.6,0.9,0.9,1.3c1.5-0.8,2.8-1.4,4-2.1c4.3-2.5,9-3.9,13.9-4.1c2.6-0.1,5.4,0.3,7.9,1.3c5.4,2,7.9,6.3,8,12
		c0.1,12,0,23.9,0,35.9c0,1-0.1,2-0.2,2.9c-2.7,1-8.5,1.1-13.1,0.5c-1.3-1.3-1.2-3-1.2-4.7c0-7.7,0-15.4,0-23.2c0-1.9-0.1-3.9,0-5.8
		c0.3-5.6-1.6-8.2-8.1-7.7c-3.1,0.2-6,1.3-9,2.6c-0.3,12.8,0.2,25.7-0.3,38.7c-4.5,0.7-8.7,0.2-12.7,0.4c-2-1.1-1.7-2.9-1.7-4.6
		c0-9.7,0-19.3,0-29c0-1,0-2.1-0.1-3.1c-0.3-2.9-2.4-4.9-5.3-5.1C166.8,376.7,162.9,377.6,159,379.7z"
      />
      <path
        fill="#232F3E"
        d="M136.7,418.5c-5.1,0.2-9.7,0.1-13.9,0.1c-2-0.8-2.5-2.3-2.9-3.7c-1.1-3.4-2.1-6.9-3.2-10.3
		c-0.2-0.6-0.5-1.2-0.8-1.9c-7.9,0-15.7,0-23.9,0c-0.5,1.6-1.2,3.2-1.7,4.9c-0.8,2.7-1.5,5.4-2.4,8.1c-0.3,0.9-0.9,1.8-1.4,2.8
		c-4.5,0-8.9,0-13.2,0c-0.4,0-0.8-0.5-1.6-1c4.1-11.5,8-23,12.2-34.4c4.1-11.3,7.7-22.7,12.3-33.9c5.5,0,10.6,0,16.1,0
		c0.6,1.2,1.3,2.3,1.8,3.6c6,16.8,11.9,33.6,17.9,50.3c1.4,4,2.8,8,4.2,12C136.5,416.1,136.5,417.1,136.7,418.5z M104.2,363.3
		c-3.4,9.4-6.4,18.7-8.9,28.3c6,0,11.6,0,17.6,0C109.9,382,107.1,372.7,104.2,363.3z"
      />
      <path
        fill="#232F3E"
        d="M331.9,392.7c0.1-4.7,0.8-9.2,2.8-13.5c3-6.4,7.8-10.6,14.8-12.4c6.2-1.6,12.2-1.5,18.2,0.6
		c8.7,3.1,13.3,9.8,14.8,18.6c0.9,5.5,0.9,11.2-0.6,16.7c-2.6,9.2-8.4,15-18,16.8c-5.4,1.1-10.7,0.7-15.9-0.9
		c-8.1-2.6-12.7-8.5-14.6-16.4C332.4,399.1,332.3,395.9,331.9,392.7z M368.3,393.1c-0.4-3-0.5-6.2-1.4-9.1c-1.4-4.6-4.6-7.2-9.7-7.2
		c-3.3,0-7.4,2.3-8.9,6.2c-2,5.4-1.7,10.8-1,16.3c0.2,1.1,0.5,2.2,0.9,3.3c1.6,4,4.4,6.2,8.8,6.2c4.9,0,7.4-1.5,9.4-5.9
		C367.9,399.8,368,396.5,368.3,393.1z"
      />
      <path
        fill="#232F3E"
        d="M222.4,459.4c3.1-0.8,6.5-0.5,9.7-0.2c0.7,0.1,1.5,1.2,2,2c0.5,0.8,0.7,1.9,1.1,3.1c0.9-0.5,1.7-0.9,2.4-1.4
		c4.4-3,9.4-4.6,14.7-5.3c3-0.4,5.9,0,8.7,1c3.6,1.3,6.3,3.6,7.3,7.4c0.6,2.2,1.2,4.5,1.2,6.8c0.1,11.2,0.1,22.4,0.1,33.6
		c0,1.4,0.2,2.9-1.3,3.8c-4.4,0.7-10.4,0.5-13.3-0.5c0-1.2,0-2.6,0-4c0-9.1,0-18.3,0-27.4c0-1.8-0.3-3.6-0.7-5.3
		c-0.7-2.5-2.5-3.8-5.1-4c-4.5-0.5-8.7,0.6-12.7,3.3c0,12.2,0,24.5,0,37c-0.9,0.5-1.6,1.1-2.4,1.3c-2.6,0.5-10,0-11.7-0.7
		C221.7,506.6,221.7,462.9,222.4,459.4z"
      />
      <path
        fill="#232F3E"
        d="M438.9,418.5c-4.1,0-8.2,0-12.1,0c-1.5-1.2-1.1-2.7-1.1-4.1c0-9.1,0-18.3,0-27.4c0-1.7-0.2-3.3-0.5-4.9
		c-0.7-3.2-2.7-4.9-5.9-5.1c-4.4-0.3-8.4,0.8-12.2,3.5c0,1.1,0,2.2,0,3.4c0,10,0,20.1,0,30.1c0,1.5,0.3,3.1-1.4,4.7
		c-3.9,0-8.2,0.2-12.1-0.1c-0.5-0.5-0.6-0.5-0.7-0.6c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-15.4-0.2-30.8-0.2-46.2c0-1,0-2,0-3.4
		c1.3-0.3,2.3-0.6,3.4-0.7c1.3-0.1,2.6,0,3.9,0c4.1,0,4.1,0,5.5,3.8c0.2,0.5,0.4,0.9,0.7,1.6c3.5-2.4,7-4.5,11-5.5
		c4.1-1.1,8.2-1.7,12.5-0.8c5.7,1.2,9.6,5.2,10.2,11c0.3,3.6,0.5,7.2,0.5,10.7c0.1,8.5,0,17,0,25.5
		C440.3,415,440.4,416.7,438.9,418.5z"
      />
      <path
        fill="#232F3E"
        d="M313.1,497.2c0.1-12.6,0-25,0-37.3c3.1-1.2,10.7-1.3,14.3-0.2c0.4,4,0.1,8.2,0.2,12.4c0.1,4.2,0,8.5,0,12.7
		c0,4.1,0,8.2,0,12.3c0,4.2,0.2,8.4-0.1,12.9c-4.1,0.4-7.9,0.8-11.6-0.3c-0.6-1.6-1.1-3-1.7-4.6c-1.1,0.7-2,1.3-3,1.9
		c-5.1,3.1-10.7,5.2-16.8,4.8c-6.8-0.4-10.9-3.7-12.7-9.8c-0.4-1.5-0.5-3-0.5-4.6c0-11.7,0-23.4,0-35.1c0-1,0.2-2,0.3-2.8
		c3.2-0.9,9.8-0.9,13.6-0.1c0.1,1.2,0.2,2.6,0.2,3.9c0,8.7,0,17.5,0,26.2c0,2,0.2,4.1,0.6,6.1c0.5,2.5,2.5,4.4,4.9,4.8
		C305,501.2,309.3,500,313.1,497.2z"
      />
      <path
        fill="#232F3E"
        d="M275.5,418.2c-3.6,0.8-6.9,0.2-10.3,0.4c-0.7-1.3-1.4-2.5-2.2-4c-0.8,0.5-1.7,1.1-2.5,1.6
		c-4.2,2.6-8.7,4.2-13.8,4c-7.2-0.3-13.4-4.9-14.6-11.5c-1.3-7.6,0.7-13.6,7.8-17.7c2.9-1.7,6.2-2.6,9.6-2.5c3.3,0.1,6.6,0.5,10,0.7
		c0.9,0.1,1.8,0,2.8,0c0.9-3.2,0.3-6.3-0.6-9.3c-0.4-1.5-1.6-2.5-3-2.7c-2.4-0.4-4.8-1-7.2-0.8c-4.7,0.4-9.4,1.1-13.9,2.6
		c-0.3,0.1-0.8,0-1.4,0c-1.7-3-1.1-6-0.5-9.1c3.8-2.4,7.8-3.1,11.9-3.6c4.7-0.6,9.4-1.4,14.2-0.2c1.1,0.3,2.3,0.4,3.4,0.7
		c6.6,1.5,10.5,6,10.7,13.6c0.4,11.9,0.1,23.9,0.1,35.9C275.8,416.9,275.6,417.3,275.5,418.2z M262.4,397.3
		c-4.1-0.4-7.8-0.8-11.5-0.2c-4.9,0.8-7.1,6.7-4.2,10.8c0.9,1.3,2.2,2.1,3.8,2.3c4.8,0.5,8.7-1.1,12-4.1
		C262.4,403.1,262.4,400.5,262.4,397.3z"
      />
      <path
        fill="#232F3E"
        d="M141.6,441.8c3.4-2.1,6.5-1.1,9.5-1.3c1.8-0.1,3.6-0.3,5.4,1.3c0.5,3.4,0.1,7.1,0.2,10.8c0.1,3.7,0,7.5,0,11.2
		c0,3.9,0,7.7,0,11.6c0,3.7,0,7.5,0,11.2c0,3.7,0,7.4,0,11.4c1.2,0.1,2.1,0.3,3.1,0.3c7.6,0,15.2,0,22.8,0c1.4,0,2.7,0.2,4.2,0.3
		c0,3.9,0,7.5,0,11c-2.9,1-33.3,1.4-43.7,0.6c-1.2-0.6-1.7-1.5-1.6-2.8c0-0.9,0-1.8,0-2.7c0-19.6,0-39.1,0-58.7
		C141.6,444.7,141.6,443.3,141.6,441.8z"
      />
      <path
        fill="#232F3E"
        d="M359,474.4c2.5-4.3,5.1-8.3,7.3-12.5c1.2-2.2,2.7-3.2,5.2-3.1c2.4,0.1,4.9,0,7.3,0.1c0.9,0,1.9,0.4,3.3,0.7
		c-0.9,1.6-1.4,2.7-2.1,3.7c-4,5.8-8.1,11.6-12.1,17.4c-0.6,0.9-1.2,1.9-1.8,2.9c5.5,8.4,10.9,16.6,16.3,24.9c0.1,0.2,0,0.5,0,0.9
		c-1.9,1.8-4.3,0.7-6.5,0.9c-2.2,0.2-4.4,0-7,0c-0.8-1-1.8-2.1-2.6-3.3c-2.7-4.2-5.3-8.4-8-12.8c-4.1,5.2-6.1,11.3-10.2,15.8
		c-6,0.8-10.8,0.5-13.1-0.6c-0.5-1.7,0.9-2.8,1.7-4.1c4.4-6.6,8.9-13.2,13.4-19.8c0.2-0.3,0.4-0.6,0.7-1.1
		c-5-8.3-11.2-15.8-16.3-24.6c1.2-0.4,1.9-1,2.7-1c3.2-0.1,6.4,0,9.6-0.1c1.9,0,3,1,4,2.6c2.3,3.7,4.6,7.4,6.9,11.1
		C358.1,473.2,358.4,473.7,359,474.4z"
      />
      <path
        fill="#232F3E"
        d="M284.3,418.2c-0.8-4.4-1.6-8.7,1.9-12.6c7.6-8.5,15.1-17.2,22.6-25.8c0.4-0.4,0.7-1,1.3-1.9
		c-8.4,0-16.4,0-24.7,0c-0.8-3.5-0.5-6.7-0.2-10.2c1-0.2,1.9-0.5,2.7-0.6c11.3-0.6,35-0.2,38.1,0.7c0.3,3.3,0.3,6.7,0,10.2
		c-8.7,9.8-17.4,19.6-26.5,29.9c5,0,9.4,0,13.7,0c4.3,0,8.7-0.1,13.1,0.1c0.8,3.5,0.6,6.7,0.3,10c-0.9,0.2-1.6,0.6-2.3,0.6
		c-12.9,0-25.7,0-38.6,0C285.5,418.6,285.2,418.5,284.3,418.2z"
      />
      <path
        fill="#232F3E"
        d="M209.3,509.7c-0.6,0.3-1.1,0.7-1.6,0.7c-3.7,0-7.5,0-10.9,0c-1.4-0.7-1.9-1.7-1.9-3c0-0.5,0-1,0-1.5
		c0-14.1,0-28.3,0-42.4c0-1.5-0.4-3.2,1.3-4.2c4.5-0.7,10.3-0.5,13,0.5C209.3,476.4,209.3,493.1,209.3,509.7z"
      />
      <path
        fill="#232F3E"
        d="M202.2,435.6c3.5-0.2,6.4,0.8,7.8,4.2c1.2,2.9,1.1,5.8-1,8.3c-2.2,2.6-5.2,3.3-8.3,2.7
		c-5.9-1-8.5-6.4-5.9-11.5C196.4,436.3,199,435.4,202.2,435.6z"
      />
      <g>
        <path
          fill="#FF9900"
          d="M309.4,104.9c-3.2-11-8.8-20.6-17.1-28.3c-5.3-4.9-11.2-9-16.8-13.5c-4.9-4-10-7.7-14.6-12
			c-8.2-7.7-16.2-15.5-23.1-24.3c-5.4-6.9-10.6-13.9-14.7-21.7c-0.8-1.6-1.8-3-3.1-5c-0.1,1.3-0.1,1.9-0.1,2.5
			c-0.3,6.4,0.6,12.8,1.6,19.1c1.1,6.6,3.3,12.9,6.5,18.8c3.8,7.1,8.9,13.2,14.7,18.8c4.5,4.4,9,8.8,13.5,13.4
			c3.7,3.8,6.6,8.2,8.3,13.3c0.2,0.5,0,1-0.1,1.7c-2.5-2-4.7-4.2-7.2-5.6c-3.2-1.9-6.6-3.4-10-4.8c-7.5-3-14.2-7-19.9-12.9
			c-3.7-3.8-7-7.9-10-12.2c-0.6-0.9-1.1-2.1-2.8-2c0,2.2-0.3,4.4,0.1,6.5c1.6,9.5,5.6,17.7,12.3,24.8c6.5,6.8,14,12.1,21.7,17.3
			c5,3.4,9.9,6.9,14.7,10.6c3.3,2.5,5.9,5.7,7.9,9.4c1.6,2.9,2.7,5.9,2.6,9.9c-0.9-0.7-1.5-0.9-1.8-1.3c-2.1-2.9-4.5-5.5-7.1-8
			c-4.7-4.4-10.2-7.4-16-10c-9.6-4.3-18.9-8.8-26.5-16.3c1.6,7.5,5.2,13.8,10,19.4c6.8,7.8,15.1,13.9,23.9,19.3
			c8.2,5.1,16.5,9.8,24,16c7.1,5.9,12.6,12.7,16.2,21.2c0.5,1.3,1,2.6,1.7,4.3c0.6-1.2,1-1.9,1.4-2.7c1.9-4.3,4-8.6,5.8-13
			c3.5-8.5,5.5-17.4,6.3-26.4C312.4,122.1,311.9,113.4,309.4,104.9z M302.3,151.2c0,0,12.6-43.3-21.9-77.6
			C280.4,73.6,319.4,99.4,302.3,151.2z"
        />
        <path
          fill="#232F3E"
          d="M381,175.7c-2.4-3.6-4.6-7.2-7.2-10.5c-3.7-4.8-8.5-8.1-14.6-9.4c-5.4-1.2-10.7-0.7-16,0.8
			c-6.8,1.9-12.9,5.1-18.8,8.9c-9.5,6.1-18,13.4-26,21.2c-0.9,0.9-1.7,2.2-3.3,2.5c-0.4-1-0.6-1.9-1-2.7c-1.9-4.1-3.6-8.3-5.8-12.2
			c-7.3-12.5-17.5-22.2-29.4-30.2c-11.7-7.9-24.4-13.9-37.7-18.3c-3.3-1.1-6.5-2.4-9.3-4.6c-2.4-2-4.7-4-7-6.1
			c-8.5-8.1-15.8-17.3-22.5-26.9c-13.4-19.3-23.9-40.2-33.1-61.8c-0.6-1.3-1.1-2.6-1.7-3.9c-0.6-1.3-1.1-2.6-1.9-4.3
			c-0.8,1.8-1.3,3.1-1.8,4.4c-2.1,5.8-3.2,11.8-4,18c-1.2,9.1-0.4,18,1.3,26.8c2.8,14.7,9,28.1,17.1,40.7
			c6.5,10.1,14.1,19.4,22.5,27.9c0.8,0.8,1.5,1.7,2.3,2.6c-2.2-0.2-4-0.8-5.7-1.6c-7.5-3.7-14-8.7-19.9-14.5
			c-8.5-8.2-15.7-17.4-22-27.4c-3.1-4.9-6-9.9-9-14.8c1.5,49.9,26,85,69.9,107.6c-1.6-0.1-3.1-0.1-4.5-0.5
			c-5.3-1.4-10.7-2.7-15.9-4.5c-14-4.9-27-11.7-37-23.1c-0.2-0.3-0.5-0.4-0.8-0.7c-0.1,0.2-0.2,0.3-0.2,0.4c0.1,0.4,0.2,0.7,0.3,1.1
			c6.1,16.3,15.1,30.7,28.1,42.4c9.6,8.7,20.7,14.9,33.4,17.8c5.2,1.2,10.6,1.9,16.1,2.9c-15.6,2.8-30.7,2.9-45.1-5
			c7.8,9.4,16.6,17.4,27,23.6c8.7,5.2,18.1,8.8,28.2,9.9c10.7,1.1,21.1-0.4,31-4.7c0.8-0.3,1.5-0.6,2.9-1.2c-0.4,2-0.5,3.5-1,4.7
			c-1.6,4.1-3.1,8.2-5,12.1c-6.5,12.7-15.6,23.6-25.5,33.8c-11.9,12.3-25,23.1-38.8,33.1c-2.9,2.1-5.9,4.3-8.8,6.4
			c1.8,0,3.3-0.6,4.9-1.2c14-5.5,27.7-11.8,41.3-18.3c0.8-0.4,1.7-0.6,2.8-1c-2.6,6.2-6.9,10.6-11.8,14.6c1.4-0.3,2.9-0.4,4.1-1
			c4.4-2.1,8-5.2,11-8.9c2-2.5,3.9-5,6.2-7.9c0,4.2-2,7.3-3.4,10.7c2.3-1.4,4.5-2.9,6.5-4.7c6.1-5.6,11.7-11.6,16.8-18.2
			c1.8-2.3,3.8-4.1,6.5-5.3c3.3-1.5,6.4-3.2,9.6-5.1c24.2-14,45.6-31.4,63.9-52.6c8.5-9.9,16.1-20.4,22.6-31.7
			c1.2-2.1,2.4-4.3,3-6.7c1.4-5.3,4.9-8.3,9.8-10c4.2-1.5,8.6-1.4,13-0.6c1.4,0.2,2.7,0.6,4.1,0.3
			C391.2,181.3,389.8,179.5,381,175.7z M146,56.1c0,0,8.8,46.6,43.7,76.4C189.8,132.5,151.7,110.3,146,56.1z M136.1,110.3
			c0,0,17.9,45.2,63.5,70.7C199.6,181.1,147.6,160.7,136.1,110.3z M152.2,180.5c0,0,26.6,31.1,63.9,37.9
			C216.1,218.5,180.7,219.7,152.2,180.5z M192.5,234c0,0,31.6,16.7,66.3,8.6C258.8,242.6,227.8,260.4,192.5,234z M260.2,158.7
			c-23-18.7-40.7-25-40.7-25s26.5,6.5,47.4,24.9c20.9,18.4,23.6,37,23.6,37S283.3,177.4,260.2,158.7z M265.6,285.3
			c0,0,56.6-31.7,90.5-83.4C356.1,201.9,338.1,251,265.6,285.3z M360.9,171.6c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3
			c1.8,0,3.3,1.5,3.3,3.3C364.2,170.1,362.7,171.6,360.9,171.6z"
        />
      </g>
    </g>
  </Icon>
);
