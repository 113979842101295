import React, { useCallback, useEffect, useState } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { find, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { DetailsIdentifier, PageHeaderWithIcon } from 'components/DataDisplay';
import { CancelButton, PrimaryButton, Select } from 'components/DataEntry';
import { Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useRemediation } from 'containers/PolicyHub/Remediation/Components/hooks/useRemediation';
import { selectRemediation } from 'containers/PolicyHub/Remediation/selector';
import { actions } from 'containers/PolicyHub/Remediation/slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  row: Record<string, any>;
  actionType: 'Attach' | 'Detach';
  alertRuleId?: string;
}

export const AttachRemediationToAlert: React.FC<Props> = props => {
  const { isOpen, onClose, row, actionType, alertRuleId } = props;
  const dispatch = useDispatch();
  const [alertOptions, setAlertOptions] = useState<Record<string, any>[]>([]);
  const [selectedAlerts, setSelectedAlerts] = useState<Record<string, any>[]>(
    [],
  );
  const { getRemediationList } = useRemediation();
  const { getCloudNativeName } = useResourceType();
  const { remediationAction, alerts } = useSelector(selectRemediation);

  const handleSelectAlertChange = useCallback((selectedOptions: any[]) => {
    // if (selectedOptions.length < selectedAlerts.length) {
    // } else {
    //   const selected = alertOptions.filter(o =>
    //     selectedOptions.some(s => s.label === o.label),
    //   );

    setSelectedAlerts(selectedOptions);
    // }
  }, []);

  //get alerts
  useEffect(() => {
    actionType === 'Attach'
      ? dispatch(actions.getRules({ q: { resourceType: row?.resource_type } }))
      : dispatch(
          actions.getRulesAttachedToRemediation({
            q: { remediationId: row?.id },
          }),
        );
  }, [dispatch, actionType, row]);

  //set alert options
  useEffect(() => {
    setAlertOptions(
      alerts.data?.map(o => ({
        label: o?.alert_description ?? o?.rule_info?.alert_description,
        value: o?.policy_id ?? o?.id,
      })),
    );
  }, [alerts.data]);

  //set selected alert
  useEffect(() => {
    if (!!alertRuleId) {
      const option = find(alertOptions, o => o?.value === alertRuleId);
      setSelectedAlerts(option ? [option] : []);
    }
  }, [alertOptions, alertRuleId]);

  //on click ok, attach or detach remediation
  const handleOnClick = () => {
    const payload = {
      policy_ids: selectedAlerts?.map(o => o?.value),
      remediation_id: row?.id,
    };
    const onSuccess = () => {
      onClose();
      getRemediationList();
    };
    if (actionType === 'Attach')
      dispatch(
        actions.attachRemediationToAlert({
          q: {
            policyAttach: payload,
          },
          onSuccess,
        }),
      );
    else
      dispatch(
        actions.detachRemediationFromAlert({
          q: { policyDetach: payload },
          onSuccess,
        }),
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          label={
            actionType +
            ' Remediation Command ' +
            (actionType === 'Attach' ? 'To' : 'From') +
            ' Alert'
          }
          iconType={actionType}
          reversed
          iconBgColor={actionType === 'Attach' ? 'green' : 'red'}
        />
      }
      styles={{
        modal: { size: '3xl' },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <PrimaryButton
            onClick={handleOnClick}
            isDisabled={isEmpty(selectedAlerts)}
            isLoading={remediationAction.isLoading}
          >
            Ok
          </PrimaryButton>
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <DetailsIdentifier label="Name" value={row?.name} />
          <DetailsIdentifier label="Description" value={row?.description} />
          <DetailsIdentifier
            label="Command"
            value={
              <Box
                bg={customTheme.colors.gray['100']}
                py={1}
                px={3}
                borderRadius={6}
              >
                {row?.command}
              </Box>
            }
            direction="column"
          />
          <DetailsIdentifier
            label="Command applies to resource type"
            value={getCloudNativeName(row?.resource_type)}
          />
          <DetailsIdentifier
            label="Choose Alert"
            value={
              <Box w="80%">
                <Select
                  menuPortalTarget={document.querySelector('body')}
                  options={alertOptions}
                  value={selectedAlerts}
                  onChange={handleSelectAlertChange}
                  isLoading={alerts.isLoading}
                  isMulti
                  showTotalSelected
                />
              </Box>
            }
            align="baseline"
          />
          <Box pt={16} pb={3} color="primary">
            NOTE: This remediation will be shown as a possible action for each
            associated alert
          </Box>
        </Stack>
      }
    />
  );
};
