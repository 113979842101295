import React, { FC, ReactNode, useState } from 'react';

import { Box, BoxProps, Stack } from '@chakra-ui/react';

import { MetaGridValueBox } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridValueBox';
import { ShowMoreButton } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/ShowMoreButton';

interface Props extends BoxProps {
  label: ReactNode;
  value: ReactNode;
  showMoreHeight?: any;
}

export const ShowMoreField: FC<Props> = props => {
  const { value, showMoreHeight, ...rest } = props;
  const [showMore, setShowMore] = useState(true);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <MetaGridValueBox
      value={
        <Stack>
          <Box
            height={showMore ? 24 : showMoreHeight ?? 64}
            overflow={showMore ? 'hidden' : 'auto'}
          >
            {value}
          </Box>
          <ShowMoreButton showMore={showMore} toggleShowMore={toggleShowMore} />
        </Stack>
      }
      {...rest}
    ></MetaGridValueBox>
  );
};
