import React, { useEffect, useState } from 'react';

import { RemediationsbyCategory } from '@ariksa/compliance-policies/api';
import { Box, Stack, Center, useDisclosure } from '@chakra-ui/react';
import { forEach, isEmpty, map } from 'lodash';
import { useDeepCompareEffect } from 'react-use';
import { useRemediationService } from 'services/ComplianceService/Remediation';
import { useAlertService } from 'services/Notification/useAlerts';
import { colorMap } from 'theme';

import {
  FormFieldBox,
  renderSeverityBar,
  renderTableHeaderWithLoader,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { AttachRemediationToAlert } from 'containers/PolicyHub/Remediation/Components/RemediationTable/AttachRemediationToAlert';
import { toTitleCase } from 'utils/string';

interface Props {
  row: RemediationsbyCategory;
  isExpanded: boolean;
}

export const AlertsAssociatedToRemediation: React.FC<Props> = props => {
  const { row, isExpanded } = props;
  const detachRemediation = useDisclosure();
  const { rulesAttachedToRemediation } = useRemediationService();
  const { alertsByRuleIdSummary } = useAlertService();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const [alertsSummary, setAlertsSummary] = useState({});

  useDeepCompareEffect(() => {
    isExpanded &&
      rulesAttachedToRemediation.sync({
        remediationId: row?.id,
      });
  }, [rulesAttachedToRemediation]);

  useDeepCompareEffect(() => {
    isExpanded &&
      !isEmpty(rulesAttachedToRemediation.data) &&
      alertsByRuleIdSummary.sync({
        ruleIdSummaryRequest: {
          rule_ids: map(
            rulesAttachedToRemediation.data,
            o => o.rule_info.config_id,
          ),
        },
      });
  }, [alertsByRuleIdSummary, rulesAttachedToRemediation.data]);

  useEffect(() => {
    const data = {};
    forEach(alertsByRuleIdSummary.data, o => (data[o.rule_id] = o.alert_count));
    setAlertsSummary(data);
  }, [alertsByRuleIdSummary.data]);

  const renderAction = ({ row }) => {
    return (
      <Center
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionButton
          onClick={() => {
            detachRemediation.onOpen();
            setCurrentRecord(row);
          }}
          isDisabled={row?.created_by === 'Ariksa'}
          icon={<TrashIcon color="red" />}
        />
      </Center>
    );
  };

  const columns = [
    {
      header: 'Alert Description',
      accessor: 'rule_info.alert_description',
    },
    {
      header: 'Category',
      accessor: 'rule_info.alert_category',
      align: 'left',
      render: ({ value }) => toTitleCase(value),
    },
    {
      header: 'Severity',
      accessor: 'rule_info.severity',
      align: 'left',
      render: ({ value }) =>
        renderSeverityBar({ value: value, isInline: false }),
    },
    {
      header: renderTableHeaderWithLoader(
        'No. of Alerts',
        alertsByRuleIdSummary.isLoading,
      ),
      align: 'left',
      render: ({ row }) => alertsSummary[row.rule_info.config_id] ?? 0,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Stack h="400px" spacing={5} w="full">
      <FormFieldBox bg={colorMap.primary(30)} border="none">
        {row?.command}
      </FormFieldBox>
      <Box flex={1}>
        <Table
          data={rulesAttachedToRemediation.data ?? []}
          columns={columns}
          isLoading={rulesAttachedToRemediation.isLoading}
        />
      </Box>
      {detachRemediation.isOpen && (
        <AttachRemediationToAlert
          isOpen={detachRemediation.isOpen}
          onClose={detachRemediation.onClose}
          row={row}
          actionType="Detach"
          alertRuleId={currentRecord?.policy_id}
        />
      )}
    </Stack>
  );
};
