import {
  RulesApiCreatePolicyRequest,
  RulesApiDeletePolicyRequest,
  RulesApiGetPolicyRequest,
  RulesApiUpdatePolicyRequest,
  RulesApiGetAllRuleCategoryRequest,
  RuleRead,
  RulesApiGetAllRulesRequest,
  BlueprintApiAddRulesRequest,
  RulesApiCreateMetadataRequest,
  RulesApiDeleteMetadataRequest,
  PolicyMetaData,
  RulesApiGetMetadataRequest,
  PageRuleReadWithMetadata,
  RulesApiGetAttachedBlueprintsRequest,
} from '@ariksa/compliance-policies/api';
import {
  SearchApiSaveSearchTermRequest,
  SearchApiSearchSuggestionsRequest,
  SearchApiSearchTermsRequest,
  SearchSuggestResponse,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummaryResponse,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertWorkflowApiEditWorkflowRequest,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  ComplianceService,
  InventoryService,
  NotificationService,
} from 'api/index';
import { SearchApiSearchSuggestionsRequestParams } from 'containers/PolicyHub/types';

import { actions } from './slice';

export function* policyHubSaga() {
  // yield takeLatest(actions.loadResourceTypes.type, doGetResourceTypes);
  // yield takeLatest(actions.loadRules.type, doGetRules);
  yield takeLatestAction(
    actions.getSearchQuerySuggestion,
    doGetSearchQuerySuggestion,
  );
  yield takeLatestAction(actions.getPolicies, doGetPolicies);
  yield takeLatestAction(
    actions.getAggregatedAlertsSummary,
    doGetAggregatedAlertsSummary,
  );
  yield takeLatestAction(actions.createPolicy, doCreatePolicy);
  yield takeLatestAction(actions.saveQuery, doSaveQuery);

  yield takeLatestAction(actions.checkQueryValidity, doCheckQueryValidity);

  yield takeLatestAction(actions.deletePolicy, doDeletePolicy);
  yield takeLatestAction(actions.updatePolicy, doUpdatePolicy);
  yield takeLatestAction(actions.getPolicyById, doGetPolicyById);
  yield takeLatestAction(actions.getQueryById, doGetQueryById);
  yield takeLatestAction(actions.getRuleCategories, doGetRuleCategories);
  yield takeLatestAction(actions.addPolicyToBlueprint, doAddPolicyToBlueprint);

  yield takeLatestAction(actions.attachWorkflow, doAttachWorkflow);
  yield takeLatestAction(actions.getAlertCategories, doGetAlertCategories);

  yield takeLatestAction(actions.createPolicyContext, doCreatePolicyContext);
  yield takeLatestAction(actions.deletePolicyContext, doDeletePolicyContext);
  yield takeLatestAction(actions.getPolicyContexts, doGetPolicyContexts);
  yield takeLatestAction(
    actions.getAttachedBlueprints,
    doGetAttachedBlueprints,
  );
}

/*get alert categories*/
function* doGetAlertCategories(
  ctx: QueryContext<string[], RulesApiGetAllRuleCategoryRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAllRuleCategory(ctx.params),
  );
}

function* doGetQueryById(
  ctx: QueryContext<SearchWrapper[], SearchApiSearchTermsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.searchTerms(ctx.params));
}

function* doGetPolicyById(
  ctx: QueryContext<RuleRead, RulesApiGetPolicyRequest>,
) {
  yield call(ctx.fetch, () => ComplianceService.Rules.getPolicy(ctx.params));
}

function* doGetRuleCategories(
  ctx: QueryContext<string[], RulesApiGetAllRuleCategoryRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAllRuleCategory(ctx.params),
  );
}

function* doDeletePolicy(ctx: QueryContext<any, RulesApiDeletePolicyRequest>) {
  yield call(ctx.fetch, () => ComplianceService.Rules.deletePolicy(ctx.params));
}

function* doUpdatePolicy(ctx: QueryContext<any, RulesApiUpdatePolicyRequest>) {
  yield call(ctx.fetch, () => ComplianceService.Rules.updatePolicy(ctx.params));
}

function* doGetPolicies(
  ctx: QueryContext<PageRuleReadWithMetadata, RulesApiGetAllRulesRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAllRules({ ...ctx.params, ...ctx.page }),
  );
}

function* doGetAttachedBlueprints(
  ctx: QueryContext<string[], RulesApiGetAttachedBlueprintsRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAttachedBlueprints({ ...ctx.params }),
  );
}

export function* doGetAggregatedAlertsSummary(
  ctx: QueryContext<
    AggregatedAlertsSummaryResponse,
    AlertsApiGetAggregatedAlertsSummaryRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAggregatedAlertsSummary(ctx.params),
  );
}

export function* doCreatePolicy(
  ctx: QueryContext<RuleRead, RulesApiCreatePolicyRequest>,
) {
  yield call(ctx.fetch, () => ComplianceService.Rules.createPolicy(ctx.params));
}

export function* doSaveQuery(
  ctx: QueryContext<SearchWrapper, SearchApiSaveSearchTermRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Search.saveSearchTerm(ctx.params),
  );
}

function* doCheckQueryValidity(
  ctx: QueryContext<SearchSuggestResponse, SearchApiSearchSuggestionsRequest>,
) {
  const payload = ctx.params;
  yield call(ctx.fetch, () =>
    InventoryService.Search.searchSuggestions({
      searchWrapperOptionalCategory: {
        wrapper: payload,
      },
    }),
  );
}

function* doGetSearchQuerySuggestion(
  ctx: QueryContext<
    SearchSuggestResponse,
    SearchApiSearchSuggestionsRequestParams
  >,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Search.searchSuggestions(ctx.params.req),
  );
}

//include rules
export function* doAddPolicyToBlueprint(
  ctx: QueryContext<any, BlueprintApiAddRulesRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.addRules(ctx.params),
    {
      errorMsg: 'Failed to include rules!',
      successMsg: 'Successfully included rules!',
    },
  );
}

//attach workflow
export function* doAttachWorkflow(
  ctx: QueryContext<any, AlertWorkflowApiEditWorkflowRequest>,
) {
  yield call(
    ctx.fetch,
    async () => NotificationService.AlertWorkflow.editWorkflow(ctx.params),
    {
      errorMsg: 'Failed to attach workflow to blueprint!',
      successMsg: 'Successfully attached workflow to blueprint!',
    },
  );
}

/*create policy context*/
function* doCreatePolicyContext(
  ctx: QueryContext<any, RulesApiCreateMetadataRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Rules.createMetadata(ctx.params),
    {
      errorMsg: 'Failed to add policy context.',
      successMsg: 'Successfully added policy context.',
    },
  );
}

/*delete policy context*/
function* doDeletePolicyContext(
  ctx: QueryContext<any, RulesApiDeleteMetadataRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Rules.deleteMetadata(ctx.params),
    {
      errorMsg: 'Failed to delete policy context.',
      successMsg: 'Successfully deleted policy context.',
    },
  );
}

/*get policy contexts*/
function* doGetPolicyContexts(
  ctx: QueryContext<PolicyMetaData[], RulesApiGetMetadataRequest>,
) {
  yield call(ctx.fetch, () => ComplianceService.Rules.getMetadata(ctx.params), {
    errorMsg: 'Failed to get policy contexts.',
  });
}
