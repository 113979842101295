import React, { FC, useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import insertCss from 'insert-css';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/DataEntry/Form/fields/FormLabel';

import {
  FieldProps,
  ReactMultiSelectCheckboxFieldSchema,
  ReactMultiSelectFieldStyles,
  useErrorMessage,
  useStyles,
} from '../index';

export const ReactMultiSelectCheckboxField: FC<
  FieldProps<ReactMultiSelectCheckboxFieldSchema>
> = props => {
  const { name, field } = props;
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    styles = {},
    helpTooltip,
    helpIcon,
    onClickHelpIcon,
  } = field;

  insertCss(`
    .multi-select-checkbox button{
      width: 100%;
    }
  `);

  const { watch } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<ReactMultiSelectFieldStyles>(
    'multiSelectField',
    styles,
  );

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [shouldDisplay, values]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
      className={'multi-select-checkbox'}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      {/* <Controller
        name={name}
        data-testid={id}
        control={control}
        as={ReactMultiSelectCheckboxes}
        styles={fieldStyles.select}
        {...rest}
        onChange={([selected]) => {
          onChange?.(selected);
          //return selected;
        }}
      /> */}
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
