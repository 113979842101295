import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SecretsPasswordIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M84.1,389.6c-2-3.5-6.5-4.6-10-2.6l-18.3,10.6v-21.1c0-4-3.3-7.3-7.3-7.3s-7.3,3.3-7.3,7.3v21.1L23,387
		c-3.5-2-8-0.8-10,2.6c-2,3.5-0.8,8,2.6,10l18.4,10.7L15.7,421c-3.5,2-4.6,6.5-2.6,10c2,3.5,6.5,4.6,10,2.6L41.3,423v21.1
		c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3V423l18.3,10.6c3.5,2,8,0.8,10-2.6c2-3.5,0.8-8-2.6-10l-18.4-10.7l18.4-10.7
		C85,397.5,86.2,393.1,84.1,389.6z"
      />
      <path
        d="M151.3,430.9c2,3.5,6.5,4.6,10,2.6l18.3-10.6v21.1c0,4,3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3v-21.1l18.3,10.6
		c3.5,2,8,0.8,10-2.6c2-3.5,0.8-8-2.6-10l-18.4-10.7l18.4-10.7c3.5-2,4.6-6.5,2.6-10c-2-3.5-6.5-4.6-10-2.6l-18.3,10.6v-21.1
		c0-4-3.3-7.3-7.3-7.3s-7.3,3.3-7.3,7.3v21.1L161.3,387c-3.5-2-8-0.8-10,2.6c-2,3.5-0.8,8,2.6,10l18.4,10.7L154,420.9
		C150.5,422.9,149.4,427.4,151.3,430.9z"
      />
      <path
        d="M138.3,486v12.8c0,3.1,2.5,5.6,5.6,5.6H230c3.1,0,5.6-2.5,5.6-5.6V486c0-3.1-2.5-5.6-5.6-5.6h-86.1
		C140.7,480.5,138.3,483,138.3,486z"
      />
      <path
        d="M348.2,127.4h-0.6V98.8c0-49.7-40.7-92-90.3-91.2C209,8.4,169.9,48,169.9,96.5v2.7c0,4.1,3.4,7.5,7.5,7.5h23.9
		c4.1,0,7.5-3.4,7.5-7.5v-0.8c0-26.7,20.1-50.2,46.8-51.9c29-1.8,53.2,21.2,53.2,49.9v30.9h-73.6v0.1h-68
		c-12.6,0.4-22.8,10.6-22.8,23.3v129.4c0,12.9,10.5,23.5,23.5,23.5h180.5c12.9,0,23.5-10.5,23.5-23.5V150.8
		C371.7,137.9,361.2,127.4,348.2,127.4z M271.9,221.3c-1.8,1.3-2.3,2.6-2.3,4.7c0.1,9.5,0.1,18.9,0,28.5l0,0c0.2,4-1.8,7.8-5.4,9.6
		c-8.3,4.2-16.6-1.7-16.6-9.6c0,0,0,0,0-0.1c0-9.5,0-19,0-28.5c0-1.9-0.4-3.3-2.1-4.5c-8.6-6.3-11.5-17.1-7.2-26.7
		c4.2-9.3,14.4-14.7,24-12.7c10.7,2.1,18.2,10.8,18.3,21.5C280.9,210.9,277.9,217,271.9,221.3z"
      />
      <path
        d="M276.5,486v12.8c0,3.1,2.5,5.6,5.6,5.6h86.1c3.1,0,5.6-2.5,5.6-5.6V486c0-3.1-2.5-5.6-5.6-5.6h-86.1
		C279,480.5,276.5,483,276.5,486z"
      />
      <path
        d="M289.6,430.9c2,3.5,6.5,4.6,10,2.6l18.3-10.6v21.1c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3v-21.1l18.3,10.6
		c3.5,2,8,0.8,10-2.6c2-3.5,0.8-8-2.6-10l-18.4-10.7l18.4-10.7c3.5-2,4.6-6.5,2.6-10c-2-3.5-6.5-4.6-10-2.6l-18.3,10.6v-21.1
		c0-4-3.3-7.3-7.3-7.3s-7.3,3.3-7.3,7.3v21.1l-18.3-10.6c-3.5-2-8-0.8-10,2.6c-2,3.5-0.8,8,2.6,10l18.4,10.7l-18.4,10.7
		C288.7,422.9,287.6,427.4,289.6,430.9z"
      />
      <path
        d="M506.4,480.5h-86.1c-3.1,0-5.6,2.5-5.6,5.6v12.8c0,3.1,2.5,5.6,5.6,5.6h86.1c3.1,0,5.6-2.5,5.6-5.6V486
		C512,483,509.5,480.5,506.4,480.5z"
      />
      <path
        d="M5.6,504.4h86.1c3.1,0,5.6-2.5,5.6-5.6V486c0-3.1-2.5-5.6-5.6-5.6H5.6C2.5,480.5,0,483,0,486v12.8
		C0,501.9,2.5,504.4,5.6,504.4z"
      />
      <path
        d="M427.9,430.9c2,3.5,6.5,4.6,10,2.6l18.3-10.6v21.1c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3v-21.1l18.3,10.6
		c3.5,2,8,0.8,10-2.6c2-3.5,0.8-8-2.6-10l-18.4-10.7l18.4-10.7c3.5-2,4.6-6.5,2.6-10c-2-3.5-6.5-4.6-10-2.6l-18.3,10.6v-21.1
		c0-4-3.3-7.3-7.3-7.3s-7.3,3.3-7.3,7.3v21.1l-18.3-10.6c-3.5-2-8-0.8-10,2.6c-2,3.5-0.8,8,2.6,10l18.4,10.7l-18.4,10.7
		C427,422.9,425.8,427.4,427.9,430.9z"
      />
    </g>
  </Icon>
);
