import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CrossAccount,
  MaxSessionDuration,
  Path,
  PermissionBoundary,
  RoleAttachedPoliciesList,
  RoleInlinePoliciesList,
  RoleLastUsedAt,
  RoleLastUsedRegion,
} from '../../../Components/MetadataField/MetaGridFields';

interface IIAMRoleMetadataAndInsights {}

export const IAMRoleMetadata: FC<IIAMRoleMetadataAndInsights> = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {/*iam-role specific fields*/}
      {RoleAttachedPoliciesList(resource)}
      {RoleInlinePoliciesList(resource)}
      {CrossAccount(resource)}
      {Path(resource)}
      {MaxSessionDuration(resource)}
      {PermissionBoundary(resource)}
      {RoleLastUsedAt(resource)}
      {RoleLastUsedRegion(resource)}
    </Grid>
  );
};
