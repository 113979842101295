import React, { useCallback } from 'react';

import { HStack } from '@chakra-ui/react';
import { isEmpty, map, sum, values } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IconTypes, ThumbsUpIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { CloudComplianceCard } from 'containers/Dashboard/utils/CloudComplianceCard';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';

export const CloudCompliance: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const navigate = useNavigate();
  const {
    awsCis1_5,
    soc2,
    blueprints,
    cisV8,
    ariksaBestPractices,
  } = stateOfYourCloud;

  const getPassedPercent = useCallback(data => {
    if (isEmpty(data)) return 0;
    const passed = sum(values(data?.check_stats?.passed_details)) ?? 0;
    const failed = sum(values(data?.check_stats?.failed)) ?? 0;
    if (passed + failed === 0) return 0;
    return ((passed / (passed + failed)) * 100).toFixed(0);
  }, []);

  const onClick = id => navigate('/compliance?blueprint_id=' + id);

  const items = [
    {
      label: 'CIS AWS 1.5',
      count: getPassedPercent(awsCis1_5.data),
      isLoading: blueprints.isLoading || awsCis1_5.isLoading,
      iconType: IconTypes.CisAws14,
      bg: '#fcf1d3',
      iconPaddingTop: '14px',
      onClick: () => onClick(awsCis1_5.data.id),
    },
    {
      label: 'SOC 2',
      count: getPassedPercent(soc2.data),
      isLoading: blueprints.isLoading || soc2.isLoading,
      iconType: IconTypes.Soc2,
      bg: '#d2edfd',
      onClick: () => onClick(soc2.data.id),
    },
    {
      label: 'CIS V8',
      count: getPassedPercent(cisV8.data),
      isLoading: blueprints.isLoading || cisV8.isLoading,
      iconType: IconTypes.CisV8,
      bg: '#f2f2f2',
      onClick: () => onClick(cisV8.data.id),
    },
    {
      label: 'Ariksa Best Practices',
      count: getPassedPercent(ariksaBestPractices.data),
      isLoading: blueprints.isLoading || ariksaBestPractices.isLoading,
      iconType: IconTypes.AriksaBestPractices,
      bg: '#d2f7e9',
      onClick: () => onClick(ariksaBestPractices.data.id),
    },
  ];

  return (
    <DashboardOverviewCard
      label="Cloud compliance"
      icon={<ThumbsUpIcon />}
      iconBgColor="skyBlue.300"
      content={
        <HStack w="full" px={1} h="full" spacing={6} pt={2}>
          {map(items, (o, index) => (
            <CloudComplianceCard key={index + '-key-cloud-compliance'} {...o} />
          ))}
        </HStack>
      }
    />
  );
};
