import React from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { renderSeverityBar } from 'components/DataDisplay';
import { toTitleCase } from 'utils/string';

export const renderSeverityInSelect = severity => (
  <HStack w="full">
    <Box w={16}>{toTitleCase(severity)}</Box>
    <Box>
      {renderSeverityBar({
        value: severity?.toLowerCase(),
        showLabel: false,
      })}
    </Box>
  </HStack>
);

export const severityOptions = [
  {
    label: renderSeverityInSelect('critical'),
    value: 'critical',
  },
  {
    label: renderSeverityInSelect('high'),
    value: 'high',
  },
  {
    label: renderSeverityInSelect('medium'),
    value: 'medium',
  },
  {
    label: renderSeverityInSelect('low'),
    value: 'low',
  },
];

export const severityOptionsWithAll = [
  { label: 'All severities', value: undefined },
  ...severityOptions,
];
