import React, { FC } from 'react';

import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { TextComponent } from 'containers/Visibility/Components/MapsHeader';

import { colorMap } from '../../../../theme';

interface IOverflowTooltip {
  labels?: string[];
}

export const OverflowTooltip: FC<IOverflowTooltip> = props => {
  const { labels = [] } = props;

  return (
    <Flex alignItems="center" justifyContent="center">
      <TextComponent>
        {labels.map((el, idx) => {
          if (idx > 2) {
            return null;
          }

          if (idx === labels.length - 1) {
            return <Box as="span">{el}</Box>;
          }
          return <Box as="span">{el}, </Box>;
        })}
        {isEmpty(labels) && '-'}
      </TextComponent>
      {!isEmpty(labels) && labels.length > 2 && (
        <Tooltip hasArrow placement="top" label={labels.toString()}>
          <Box
            color={colorMap.primary(500)}
            h="18px"
            w="18px"
            border="1px solid"
            borderColor={colorMap.primary(500)}
            borderRadius="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
          >
            +
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};
