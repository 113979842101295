/**
 *
 * Jira
 *
 */

import React, { memo } from 'react';

import { JiraWizard } from 'containers/Setup/Integrations/Components/Jira/Components/JiraWizard';

import { useIntegrationsContext } from '../../context';

import { JiraTable } from './Components/JiraTable';

export const Jira = memo(() => {
  const { isOpen } = useIntegrationsContext();

  return <>{isOpen ? <JiraWizard /> : <JiraTable />}</>;
});
