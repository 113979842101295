import {
  CategoryResponse,
  CommandsApiAttachPolicyRequest,
  CommandsApiCreateRemediationRequest,
  CommandsApiDeleteRemediationRequest,
  CommandsApiDetachPolicyRequest,
  CommandsApiGetCommandsRequest,
  CommandsApiGetPoliciesByRemediationIdRequest,
  CommandsApiGetRemediationByIdRequest,
  CommandsApiGetRemediationRequest,
  CommandsApiUpdateRemediationRequest,
  CommandsResponse,
  PageRemediationsbyCategory,
  PolicyResponse,
  RemediationInfo,
  RuleReadWithMetadata,
  RulesApiGetAllRulesWithoutPaginationRequest,
} from '@ariksa/compliance-policies/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ComplianceService } from 'api/services';

import { actions } from './slice';

export function* remediationSaga() {
  yield takeLatestAction(actions.getRemediationList, doGetRemediationList);
  yield takeLatestAction(actions.getRemediation, doGetRemediation);
  yield takeLatestAction(actions.addRemediation, doCreateRemediation);
  yield takeLatestAction(actions.updateRemediation, doUpdateRemediation);
  yield takeLatestAction(actions.deleteRemediation, doDeleteRemediation);
  yield takeLatestAction(actions.getCommands, doGetCommands);
  yield takeLatestAction(
    actions.getRulesAttachedToRemediation,
    doGetRulesAttachedToRemediation,
  );
  yield takeLatestAction(
    actions.getRemediationCategories,
    doGetRemediationCategories,
  );
  yield takeLatestAction(actions.getRules, doGetRules);
  yield takeLatestAction(
    actions.attachRemediationToAlert,
    doAttachRemediationToAlerts,
  );
  yield takeLatestAction(
    actions.detachRemediationFromAlert,
    doDetachRemediationFromAlert,
  );
}

export function* doGetRemediationList(
  ctx: QueryContext<
    PageRemediationsbyCategory,
    CommandsApiGetRemediationRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.getRemediation(ctx.params),
    { errorMsg: 'Failed to get remediation list!' },
  );
}

export function* doGetRemediation(
  ctx: QueryContext<RemediationInfo, CommandsApiGetRemediationByIdRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.getRemediationById(ctx.params),
    { errorMsg: 'Failed to get remediation!' },
  );
}

export function* doGetRemediationCategories(
  ctx: QueryContext<CategoryResponse[]>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.getRemediationCategories(),
    { errorMsg: 'Failed to get remediation categories!' },
  );
}

export function* doGetCommands(
  ctx: QueryContext<CommandsResponse[], CommandsApiGetCommandsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.getCommands(ctx.params),
    { errorMsg: 'Failed to get commands!' },
  );
}

export function* doCreateRemediation(
  ctx: QueryContext<any, CommandsApiCreateRemediationRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.createRemediation(ctx.params),
    {
      errorMsg: 'Failed to create remediation!',
      successMsg: 'Successfully created remediation!',
    },
  );
}

export function* doDeleteRemediation(
  ctx: QueryContext<any, CommandsApiDeleteRemediationRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.deleteRemediation(ctx.params),
    {
      errorMsg: 'Failed to delete remediation!',
      successMsg: 'Successfully deleted remediation!',
    },
  );
}

export function* doUpdateRemediation(
  ctx: QueryContext<any, CommandsApiUpdateRemediationRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.updateRemediation(ctx.params),
    {
      errorMsg: 'Failed to update remediation!',
      successMsg: 'Successfully updated remediation!',
    },
  );
}

export function* doGetRules(
  ctx: QueryContext<
    RuleReadWithMetadata[],
    RulesApiGetAllRulesWithoutPaginationRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Rules.getAllRulesWithoutPagination(ctx.params),
    {
      errorMsg: 'Failed to get policies!',
    },
  );
}
export function* doGetRulesAttachedToRemediation(
  ctx: QueryContext<
    PolicyResponse[],
    CommandsApiGetPoliciesByRemediationIdRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.RemediationCommands.getPoliciesByRemediationId(
        ctx.params,
      ),
    {
      errorMsg: 'Failed to get policies!',
    },
  );
}

export function* doAttachRemediationToAlerts(
  ctx: QueryContext<any, CommandsApiAttachPolicyRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.attachPolicy(ctx.params),
    {
      errorMsg: 'Failed to attach remediation command to alerts!',
      successMsg: 'Successfully attached remediation command to alerts!',
    },
  );
}

export function* doDetachRemediationFromAlert(
  ctx: QueryContext<any, CommandsApiDetachPolicyRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.RemediationCommands.detachPolicy(ctx.params),
    {
      errorMsg: 'Failed to detach remediation command from alerts!',
      successMsg: 'Successfully detached remediation command from alerts!',
    },
  );
}
