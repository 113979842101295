import React from 'react';

import { Icon } from '@chakra-ui/react';

export const WorkflowIcon = props => (
  <Icon viewBox="0 0 216 220" color="inherit" h="full" w="full" {...props}>
    <g transform="translate(-1663 -1833)">
      <g>
        <path
          d="M1858.31 1999.82 1858.31 1959.28 1807.41 1959.28 1777.55 1929.3 1777.55 1908.09C1796.86 1905.29 1810.26 1887.3 1807.47 1867.91 1804.68 1848.52 1786.77 1835.07 1767.45 1837.87 1748.14 1840.67 1734.74 1858.66 1737.53 1878.05 1739.77 1893.62 1751.95 1905.85 1767.45 1908.09L1767.45 1929.11 1737.41 1959.28 1686.69 1959.28 1686.69 1999.82 1666.5 1999.82 1666.5 2050.5 1716.98 2050.5 1716.98 1999.82 1696.79 1999.82 1696.79 1969.41 1736.74 1969.41 1772.4 2005.22 1808.08 1969.41 1848.22 1969.41 1848.22 1999.82 1828.02 1999.82 1828.02 2050.5 1878.5 2050.5 1878.5 1999.82ZM1706.88 2040.36 1676.6 2040.36 1676.6 2009.96 1706.88 2009.96ZM1747.26 1873.12C1747.26 1859.13 1758.56 1847.78 1772.5 1847.78 1786.44 1847.78 1797.74 1859.13 1797.74 1873.12 1797.74 1887.12 1786.44 1898.46 1772.5 1898.46 1758.56 1898.46 1747.26 1887.12 1747.26 1873.12ZM1772.35 1991.03 1746.1 1964.68 1771.34 1939.34 1773.36 1939.34 1798.6 1964.68ZM1868.41 2040.36 1838.12 2040.36 1838.12 2009.96 1868.41 2009.96Z"
          stroke="currentColor"
          strokeWidth="1.14583"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="8"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="evenodd"
          fillOpacity="1"
        />
      </g>
    </g>
  </Icon>
);
