import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.integrations || initialState;

export const selectIntegrations = createSelector(
  [selectDomain],
  integrationsState => integrationsState,
);

export const selectGithubIntegrations = createSelector(
  [selectDomain],
  integrationsState => integrationsState.github,
);

export const selectGitLabIntegrations = createSelector(
  [selectDomain],
  integrationsState => integrationsState.gitlab,
);

export const selectBitbucketIntegrations = createSelector(
  [selectDomain],
  integrationsState => integrationsState.bitbucket,
);
