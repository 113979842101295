import React, { ReactNode } from 'react';

import { Box, Center, CenterProps, HStack, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { IconSize, IconType } from 'components/Icons';
import {
  DashboardOverviewTooltip,
  DashboardOverviewTooltipProps,
} from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { dashboardCardHoverStyles } from 'containers/Dashboard/utils/styles';

export interface DashboardOverviewCardProps {
  content: ReactNode;
  showHeader?: boolean;
  label?: ReactNode;
  iconType?: IconType;
  icon?: ReactNode;
  rightHeader?: ReactNode;
  width?: string | number;
  height?: string | number;
  tooltip?: DashboardOverviewTooltipProps;
  iconBgColor?: string;
  iconFilled?: boolean;
  isLoading?: boolean;
  iconSize?: IconSize;
  px?: number;
  showHeaderDivider?: boolean;
  styles?: { contentWrapper?: CenterProps };
}

export const DashboardOverviewCard: React.FC<DashboardOverviewCardProps> = props => {
  const {
    label,
    iconType,
    content,
    icon,
    width = 'full',
    height = 'full',
    tooltip,
    iconBgColor,
    iconFilled,
    rightHeader,
    isLoading = false,
    showHeader = true,
    showHeaderDivider = true,
    iconSize = 'small',
    px = 3,
    styles,
  } = props;

  return (
    <Box
      py={3}
      px={px}
      borderRadius={8}
      w={width}
      h={height}
      bg="white"
      {...dashboardCardHoverStyles}
    >
      <Stack w="full" h="full">
        {showHeader && (
          <Stack>
            <HStack w="full" justify="space-between">
              <HStack>
                <PageHeaderWithIcon
                  label={label}
                  iconType={iconType}
                  icon={icon}
                  fontSize="16px"
                  reversed
                  iconBgColor={iconBgColor}
                  useCustomColor
                  iconFilled={iconFilled}
                  spacing={2}
                  iconSize={iconSize}
                />
                {tooltip && <DashboardOverviewTooltip {...tooltip} />}
              </HStack>
              <Box>{rightHeader}</Box>
            </HStack>
            {showHeaderDivider && (
              <Box
                borderBottom="1px solid"
                borderColor={customTheme.colors.gray['200']}
              />
            )}
          </Stack>
        )}
        <Center h="full" py={2} {...styles?.contentWrapper}>
          {isLoading ? <CustomSpinner /> : content}
        </Center>
      </Stack>
    </Box>
  );
};
