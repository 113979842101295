import React, { ReactNode } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import {
  IdentityCard,
  IdentityCardProps,
} from 'containers/Dashboard/utils/IdentityCard';
import { DashboardOverviewTooltipProps } from 'containers/Dashboard/utils/OverviewDashboardTooltip';

interface Props {
  label: string;
  icon: ReactNode;
  iconBgColor?: string;
  items: IdentityCardProps[];
  jit?: boolean;
  placeholder?: ReactNode;
  tooltip?: DashboardOverviewTooltipProps;
  contentTooltip?: ReactNode;
  jitEnabled?: boolean;
  isLoading?: boolean;
  cardW?: string | number;
  cardH?: string | number;
  iconBoxSize?: string | number;
  contentSpacing?: string | number;
  labelFontSize?: string | number;
  countFontSize?: string | number;
}

export const IdentityCards: React.FC<Props> = props => {
  const {
    label,
    icon,
    items,
    jit,
    tooltip,
    iconBgColor,
    jitEnabled,
    isLoading,
    contentTooltip,
    ...rest
  } = props;
  const navigate = useNavigate();

  return (
    <DashboardOverviewCard
      label={label}
      icon={icon}
      tooltip={tooltip}
      iconBgColor={iconBgColor}
      content={
        isLoading ? (
          <CustomSpinner />
        ) : !jitEnabled && jit ? (
          <Stack px={10} spacing={4}>
            <Box>JIT access is not enabled for any accounts</Box>
            <Box
              color="primary"
              cursor="pointer"
              onClick={() => navigate('/setup/jit')}
            >
              Enable now
            </Box>
          </Stack>
        ) : (
          contentTooltip ?? (
            <HStack w="full" px={1}>
              {map(items, (o, index) => (
                <IdentityCard
                  key={index + '-key-identity-cards'}
                  {...o}
                  jit={jit}
                  {...rest}
                />
              ))}
            </HStack>
          )
        )
      }
    />
  );
};
