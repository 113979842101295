import React, { FC, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { GraphIncreaseIcon } from 'components/Icons';
import { PieChart } from 'components/Visualization';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const VulnerabilitiesBySeverity: FC = () => {
  const { vulnerabilitiesBySeverityCounts } = useSelector(
    selectVulnerabilities,
  );
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!!vulnerabilitiesBySeverityCounts?.data) {
      let severities = vulnerabilitiesBySeverityCounts.data;
      setData([
        { name: 'Critical', value: severities?.critical ?? 0 },
        { name: 'High', value: severities?.high ?? 0 },
        { name: 'Medium', value: severities?.medium ?? 0 },
        { name: 'Low', value: severities?.low ?? 0 },
        { name: 'Unknown', value: severities?.unknown ?? 0 },
      ]);
    }
  }, [vulnerabilitiesBySeverityCounts.data]);

  const colors = {
    Critical: customTheme.colors.critical,
    High: customTheme.colors.high,
    Medium: customTheme.colors.medium,
    Low: customTheme.colors.low,
    Unknown: customTheme.colors.gray['100'],
  };

  return (
    <>
      <DashboardOverviewCard
        label="By severity"
        icon={<GraphIncreaseIcon />}
        iconBgColor="critical"
        content={
          <Box flex={1} w="full" h="full">
            <PieChart
              isLoading={vulnerabilitiesBySeverityCounts.isLoading}
              data={data}
              dataKey="value"
              total={formatNumber(
                data?.reduce((s, el) => s + el?.value || 0, 0),
                1,
              )}
              colorMap={colors}
              outerRadius="85%"
              innerRadius="75%"
              activeShape={false}
              renderLegend={props => {
                console.log(props);
                return (
                  <Center w="full" h="full" justifyContent="flex-start">
                    <Stack>
                      {map(props?.payload, o => (
                        <HStack w="full">
                          <Box
                            boxSize={2.5}
                            bg={colors[o?.value]}
                            borderRadius={3}
                          />
                          <Box>
                            {o?.value} ({formatNumber(o?.payload?.value, 1)})
                          </Box>
                        </HStack>
                      ))}
                    </Stack>
                  </Center>
                );
              }}
              styles={{
                pie: { cx: '45%' },
                legend: {
                  wrapperStyle: {
                    width: '35%',
                  },
                },
              }}
            />
          </Box>
        }
      />
    </>
  );
};
