import React, { useCallback } from 'react';

import { Box, Center, Flex, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Card, PageHeaderWithIcon, renderTime } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
} from 'components/DataEntry';
import { EditIcon, TagIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { EnvironmentName } from 'containers/App/utils';
import { selectInventoryContext } from 'containers/Inventory/InventoryContext/selector';

import { actions } from '../slice';

export const ContextTable: React.FC = () => {
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contextList, createContext } = useSelector(selectInventoryContext);

  const getContextList = useCallback(() => {
    dispatch(actions.getContextList({ q: {} }));
  }, [dispatch]);

  useDeepCompareEffect(() => {
    getContextList();
  }, [getContextList]);

  const columns = [
    {
      header: 'Name',
      accessor: 'key',
      align: 'left',
    },
    {
      header: 'Description',
      accessor: 'notes',
      align: 'left',
    },
    {
      header: 'Type',
      accessor: 'type',
      align: 'left',
    },
    {
      header: 'Environments',
      accessor: 'scope',
      align: 'left',
      render: ({ value }) =>
        !!value?.length ? (
          <CustomTooltip
            label={
              <Stack>
                {map(value, o => (
                  <Box>
                    <EnvironmentName environmentId={o} />
                  </Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        ) : (
          0
        ),
    },
    {
      header: 'ENTITIES ATTACHED',
      accessor: 'attached_entities_count',
      align: 'left',
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Created At',
      accessor: 'created_at',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: ({ row }) => {
        return (
          <Center>
            <ActionButton
              label={'Edit'}
              icon={<EditIcon />}
              isDisabled={
                createContext.isLoading || row?.created_by === 'system'
              }
              onClick={() => navigate(`/inventory/context/edit/${row?.id}`)}
            />
            <DeleteActionButton
              onConfirm={() =>
                dispatch(
                  actions.deleteContext({
                    q: { labelId: row?.id },
                    onSuccess: () => getContextList(),
                  }),
                )
              }
              name={row?.key}
              type="context"
              isDisabled={
                createContext.isLoading || row?.created_by === 'system'
              }
              isLoading={createContext.isLoading}
            />
          </Center>
        );
      },
    },
  ];

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon label="Context" icon={<TagIcon />} />
          <Flex>
            <AddButton
              label="Add Label"
              onClick={() => {
                navigate('/inventory/context/add');
              }}
            />
          </Flex>
        </Stack>
        <Box flex={1}>
          <Table
            data={contextList.data}
            columns={columns}
            isLoading={contextList.isLoading}
          />
        </Box>
      </Stack>
    </Card>
  );
};
