import React from 'react';

import { Icon } from '@chakra-ui/react';

export const Route53HealthCheckIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <rect x="329" y="329" width="183" height="36.6" />
      <rect x="329" y="402.2" width="109.8" height="36.6" />
      <path
        d="M512,274.1v-18.3C511.9,114.4,397.1-0.1,255.8,0C114.4,0.1-0.1,114.9,0,256.2S114.9,512.1,256.2,512
		c19.7,0,39.4-2.3,58.6-6.9l-8.4-35.6c-16.6,3.9-33.6,5.9-50.7,5.9c-3.5,0-6.9-0.3-10.3-0.5c-39.7-59.5-61.4-129.3-62.4-200.8H512z
		 M474.6,237.5H365.1c-2-67.4-19.4-133.5-50.8-193.2C402.9,68.8,466.8,145.9,474.6,237.5z M266.1,36.6
		c39.7,59.5,61.4,129.3,62.4,200.8H183c1-71.6,22.7-141.3,62.4-200.8c3.4-0.2,6.8-0.5,10.3-0.5C259.2,36.1,262.6,36.5,266.1,36.6z
		 M197.2,44.2c-31.4,59.7-48.7,125.8-50.8,193.2H36.9C44.7,145.9,108.6,68.8,197.2,44.2z M197.2,467.3
		C108.6,442.7,44.7,365.6,36.9,274.1h109.5C148.4,341.5,165.8,407.6,197.2,467.3z"
      />
    </g>
  </Icon>
);
