import React, { FC, useCallback, useState } from 'react';

import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Grid,
  GridItem,
  Center,
} from '@chakra-ui/react';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { useComparePolicies } from 'containers/Compliance/Components/ComparePolicies/context';
import {
  getTotalFailed,
  getTotalPassed,
} from 'containers/Compliance/Components/ComparePolicies/utils';
import { selectCompliance } from 'containers/Compliance/selectors';

export const ComparePolicy: FC = () => {
  const { comparePolicies } = useSelector(selectCompliance);
  const { policy1, policy2 } = comparePolicies;
  const { firstBlueprint, secondBlueprint } = useComparePolicies();

  const renderTableColumns = useCallback(() => {
    const renderColumn = label => (
      <GridItem colSpan={1}>
        <Center flex={1} pr={24}>
          <Box>{label}</Box>
        </Center>
      </GridItem>
    );

    return (
      <Box flex={1} py={2}>
        <Grid templateColumns="repeat(2, 1fr)" gap={3} w={'full'}>
          {renderColumn(firstBlueprint?.value?.name)}
          {renderColumn(secondBlueprint?.value?.name)}
        </Grid>
      </Box>
    );
  }, [firstBlueprint, secondBlueprint]);

  const [stats] = useState<TableColumnProps[]>([
    {
      header: 'Entity types assessed',
      render: ({ row }) => row?.check_stats?.resource_types_executed,
    },
    {
      header: 'Entities assessed',
      render: ({ row }) => {
        return row?.check_stats?.total_entities;
      },
    },
    {
      header: 'No. of rules for checks',
      render: ({ row }) => row?.check_stats?.rules_executed,
    },
    {
      header: 'Total checks performed',
      render: ({ row }) => getTotalPassed(row) + getTotalFailed(row),
    },
    {
      header: 'Total checks passed / failed',
      render: ({ row }) => getTotalPassed(row) + ' / ' + getTotalFailed(row),
    },
    {
      header: '% of checks passed',
      render: ({ row }) =>
        round(
          (getTotalPassed(row) / (getTotalPassed(row) + getTotalFailed(row))) *
            100,
        ) + '%',
    },
    {
      header: 'No. of rules that have been excluded',
      render: ({ row }) => row?.check_stats?.rule_exceptions,
    },
    {
      header: 'No. of entities that have been excluded',
      render: ({ row }) => row?.check_stats?.resource_exceptions,
    },
  ]);

  const renderRowValue = (policy, column) => {
    return (
      <GridItem colSpan={2}>
        <Flex flex={1}>
          <Skeleton isLoaded={!policy?.isLoading} w={'full'}>
            <Center pr={24}>
              <Box>{column.render?.({ row: policy?.data }) ?? '-'}</Box>
            </Center>
          </Skeleton>
        </Flex>
      </GridItem>
    );
  };

  const renderTableRows = useCallback(() => {
    return stats.map(s => {
      return (
        <Grid
          w={'full'}
          templateColumns="repeat(5, 1fr)"
          gap={3}
          borderBottom="1px solid"
          borderColor={customTheme.colors.gray['100']}
          py={3}
        >
          <GridItem colSpan={1}>
            <Flex flex={1}>
              <Box fontWeight={600} color={'gray'}>
                {s.header?.toString()}
              </Box>
            </Flex>
          </GridItem>
          {renderRowValue(policy1, s)}
          {renderRowValue(policy2, s)}
        </Grid>
      );
    });
  }, [policy1, policy2, stats]);

  return (
    <Stack w={'full'}>
      <Grid
        w={'full'}
        templateColumns="repeat(5, 1fr)"
        gap={3}
        bg={customTheme.colors.gray['100']}
      >
        <GridItem colSpan={1}>
          <Box flex={1} />
        </GridItem>
        <GridItem colSpan={4}>{renderTableColumns()}</GridItem>
      </Grid>
      <Stack w={'full'} spacing={0}>
        {renderTableRows()}
      </Stack>
    </Stack>
  );
};
