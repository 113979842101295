/**
 *
 * Email Integration
 *
 */

import React, { memo } from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { CreateEmailGroup } from './CreateEmailGroup';
import { EditEmail } from './EditEmail';
import { EmailTable } from './EmailTable';

interface Props {}

export const EmailGroup = memo((props: Props) => {
  const { actionType, isOpen } = useIntegrationsContext();

  return isOpen ? (
    actionType === 'Add' ? (
      <CreateEmailGroup />
    ) : (
      <EditEmail />
    )
  ) : (
    <EmailTable />
  );
});
