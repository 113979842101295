import React from 'react';

import { Center, Icon, Image } from '@chakra-ui/react';
import ariksa from 'images/ariksa-logo-only.png';

/*export const AriksaLogoOnlyIcon = props => (
  <Center w="full" h="full">
    <Image
      src={ariksa}
      alt="Ariksa Inc."
      px={0.5}
      w="full"
      h="full"
      {...props}
    />
  </Center>
);*/

export const AriksaLogoOnlyIcon = props => (
  <Icon
    aria-label="Ariksa"
    viewBox="0 0 878 1072"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <path
      fill="currentColor"
      opacity="1.000000"
      stroke="none"
      d="
M393.504700,135.556671
	C405.138672,121.070305 416.549377,106.864662 427.947723,92.649071
	C432.490845,86.983032 437.006561,81.295029 441.889435,75.172279
	C448.751160,83.735390 455.484161,92.107895 462.185303,100.505836
	C490.669250,136.202255 519.147522,171.903214 547.620728,207.608200
	C578.685364,246.562759 609.731812,285.531799 640.805298,324.479309
	C668.975281,359.787628 697.173889,395.073059 725.354187,430.373169
	C741.725769,450.881104 758.061768,471.417480 774.491150,491.878967
	C776.040710,493.808868 776.189026,495.536652 775.575928,497.815674
	C762.325623,547.063538 749.127075,596.325256 735.939514,645.589966
	C719.230835,708.008789 702.558960,770.437378 685.834229,832.851868
	C678.914185,858.676697 671.898254,884.475891 664.591125,911.526123
	C661.235352,899.207031 658.157166,888.077515 655.172974,876.922913
	C641.678955,826.481934 628.259155,776.021118 614.721619,725.591858
	C599.768066,669.888000 584.718994,614.209717 569.691650,558.525696
	C569.134583,556.461426 568.311829,554.577515 566.897522,552.809021
	C526.135620,501.840454 485.436432,450.821716 444.759033,399.785645
	C442.823547,397.357269 441.689331,396.470398 439.193207,399.646088
	C421.263702,422.456940 403.121643,445.101013 385.007324,467.766266
	C365.964874,491.592926 346.876007,515.382507 327.812592,539.192505
	C323.882416,544.101135 319.973236,549.026672 316.057281,553.946716
	C314.216827,556.259033 314.021484,559.181396 313.297729,561.863403
	C298.970734,614.952820 284.707916,668.059631 270.446136,721.166626
	C253.561630,784.039734 236.690430,846.916321 219.598083,910.593872
	C217.003784,905.851624 216.424896,901.152161 215.228104,896.755981
	C205.761963,861.982910 196.471664,827.161926 187.187927,792.339478
	C172.031372,735.488464 156.972473,678.611450 141.773148,621.771973
	C130.888855,581.069031 119.904686,540.392456 108.770424,499.757477
	C107.675888,495.762909 108.333725,493.133636 110.908653,489.941467
	C132.664902,462.969727 154.219666,435.835388 175.821808,408.739410
	C190.892197,389.836273 205.923721,370.902161 221.006027,352.008545
	C235.998611,333.227325 251.053070,314.495544 266.043671,295.712738
	C282.693237,274.851288 299.307434,253.961594 315.925873,233.075272
	C340.374542,202.347824 364.813202,171.612411 389.256378,140.880615
	C390.595245,139.197281 391.936371,137.515747 393.504700,135.556671
z"
    />
    <path
      fill="currentColor"
      opacity="1.000000"
      stroke="none"
      d="
M333.368042,761.464966
	C345.515198,716.327881 357.535706,671.580566 369.554230,626.832764
	C371.665192,618.973267 374.302368,611.208374 375.746704,603.227844
	C377.837372,591.676086 383.849518,582.580383 391.111572,573.741882
	C406.975739,554.434143 422.413452,534.775269 437.967407,515.213745
	C441.790588,510.405457 441.644165,510.359314 445.453522,515.128418
	C464.100891,538.474060 482.734222,561.831177 501.468811,585.106689
	C505.537018,590.160950 505.913269,596.427490 507.480896,602.219727
	C525.519714,668.871887 543.319519,735.588684 561.234863,802.274353
	C572.160095,842.940735 583.121887,883.597656 594.264282,924.204773
	C595.057190,927.094543 594.293701,927.975220 592.026917,929.020264
	C560.883484,943.377747 529.743469,957.743103 498.645691,972.199158
	C481.356445,980.236328 464.121307,988.391479 446.925262,996.625916
	C443.738007,998.152161 441.055725,998.486938 437.657074,996.888245
	C412.358276,984.988220 386.948608,973.324036 361.593811,961.542725
	C338.493103,950.808655 315.438568,939.974854 292.304626,929.313171
	C289.421234,927.984314 288.627838,926.776306 289.533752,923.452698
	C304.207825,869.615295 318.704132,815.729492 333.368042,761.464966
z"
    />
    <path
      fill={props?.bg ?? 'white'}
      opacity="1.000000"
      stroke="none"
      d="
M569.924316,339.068512
	C598.585571,375.071106 627.043213,410.783661 655.521057,446.480194
	C671.569275,466.596497 687.625366,486.706818 703.756653,506.756470
	C705.356445,508.744720 705.188965,510.571503 704.605103,512.748352
	C693.976868,552.374207 683.386780,592.010254 672.781128,631.642212
	C670.171082,641.395508 667.534790,651.141724 664.609558,662.009521
	C656.161377,630.834717 648.032593,600.809326 639.885803,570.788879
	C635.995544,556.453674 631.999634,542.146667 628.207153,527.785767
	C627.118225,523.662292 624.026306,521.032532 621.588806,517.969971
	C571.744507,455.344177 521.820801,392.781586 471.901703,330.215302
	C462.788513,318.793274 453.546265,307.472931 444.548370,295.961243
	C442.536652,293.387512 441.247742,293.323273 439.313782,295.765320
	C417.812042,322.915436 396.346863,350.094910 374.743500,377.164032
	C350.349609,407.729614 325.802643,438.173004 301.365204,468.703918
	C286.971771,486.686310 272.658203,504.732666 258.319550,522.758850
	C256.864502,524.588135 255.900894,526.620667 255.280182,528.932739
	C243.651230,572.246460 231.954697,615.542114 220.260330,658.838318
	C220.092804,659.458557 219.772690,660.037598 219.211395,661.386780
	C216.012833,649.599854 212.963516,638.472473 209.974579,627.328796
	C199.783600,589.333435 189.632584,551.327271 179.398727,513.343506
	C178.621811,510.459900 178.801102,508.377228 180.870819,505.801788
	C213.891891,464.711853 246.781067,423.515717 279.636749,382.293121
	C308.034698,346.663391 336.315308,310.940094 364.716919,275.313293
	C389.502838,244.221970 414.417877,213.233551 439.171692,182.116791
	C441.513672,179.172760 442.439819,179.265366 444.822815,182.266678
	C485.913208,234.019547 527.154297,285.652802 568.368042,337.307678
	C568.782471,337.827087 569.273804,338.285095 569.924316,339.068512
z"
    />
    <path
      fill={props?.bg ?? 'white'}
      opacity="1.000000"
      stroke="none"
      d="
M469.548553,919.476929
	C461.248718,923.758484 453.216614,927.752441 445.286469,931.939514
	C442.870697,933.215088 440.912262,933.329468 438.394684,932.022766
	C414.790771,919.770752 391.147156,907.593811 367.423523,895.575806
	C364.507629,894.098633 364.805634,892.428955 365.459747,890.009521
	C376.343719,849.753357 387.190399,809.487061 398.032562,769.219543
	C412.369598,715.972107 426.695312,662.721558 441.030579,609.473633
	C441.116455,609.154663 441.268738,608.853516 441.840363,607.394653
	C443.689362,613.784241 445.365204,619.228577 446.840057,624.726807
	C458.355743,667.657410 469.798920,710.607483 481.331085,753.533691
	C493.593964,799.179871 505.896454,844.815552 518.320496,890.417969
	C519.027832,893.014343 518.846008,894.121582 516.357300,895.379150
	C500.804626,903.237549 485.345551,911.281189 469.548553,919.476929
z"
    />
  </Icon>
);
