import React from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../slice';

import { TeamsForm } from './TeamsForm';

export const EditTeams: React.FC = () => {
  const dispatch = useDispatch();
  const { onCancel, currentRecord } = useIntegrationsContext();

  const handleSubmit = data => {
    const { name, channel, team } = data;
    if (currentRecord?.teams?.channel) {
      const payload: ClientsApiEditClientRequest = {
        uuid: currentRecord?.teams?.channel,
        editClientConfig: {
          name,
          status: true,
          teams: {
            channel,
            team,
          },
        },
      };

      dispatch(
        actions.updateClientById({ q: payload, onSuccess: () => onCancel() }),
      );
    }
  };

  return <TeamsForm handleSubmit={handleSubmit} />;
};
