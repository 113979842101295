import { identity } from 'lodash';
import map from 'lodash/map';

export function intoOptions(
  list: string[] | Record<string, string>,
  label: (label: string) => string = identity,
) {
  return map(list, l => {
    if (l === 'All' || l === 'all') {
      return { label: label(l), value: '' };
    }
    return { label: label(l as string), value: l };
  });
}
