import React, { ReactNode } from 'react';

import { Center, HStack, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import map from 'lodash/map';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import {
  CountCard,
  CountCardProps,
} from 'containers/Dashboard/utils/CountCard';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { DashboardOverviewTooltipProps } from 'containers/Dashboard/utils/OverviewDashboardTooltip';

export interface CountCardsProps {
  label: string;
  icon: ReactNode;
  iconBgColor?: string;
  items: CountCardProps[];
  tooltip?: DashboardOverviewTooltipProps;
  isLoading?: boolean;
  contentTooltip?: ReactNode;
  stackAllVertically?: boolean;
  spacing?: number;
  onClick?();
}

export const CountCards: React.FC<CountCardsProps> = props => {
  const {
    items,
    label,
    icon,
    iconBgColor,
    tooltip,
    isLoading,
    contentTooltip,
    stackAllVertically = false,
    spacing = 10,
  } = props;

  return (
    <DashboardOverviewCard
      label={label}
      icon={icon}
      tooltip={tooltip}
      iconBgColor={iconBgColor}
      content={
        isLoading ? (
          <CustomSpinner />
        ) : isEmpty(items) ? (
          <Center>
            <NoDataAvailable />
          </Center>
        ) : (
          contentTooltip ?? (
            <Stack w="full" h="full" spacing={spacing} pt={4} px={2}>
              {stackAllVertically ? (
                map(items, o => <CountCard {...o} rightAlignNumbers />)
              ) : (
                <>
                  <HStack w="full" h="full" spacing={6}>
                    {items[0] && <CountCard {...items[0]} />}
                    {items[1] && <CountCard {...items[1]} />}
                  </HStack>
                  <HStack w="full" h="full" spacing={6}>
                    {items[2] && <CountCard {...items[2]} />}
                    {items[3] && <CountCard {...items[3]} />}
                  </HStack>
                </>
              )}
            </Stack>
          )
        )
      }
    />
  );
};
