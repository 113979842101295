/**
 *
 * Jit Requests
 *
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AccessRequestGetResponse } from '@ariksa/jit/api';
import {
  Box,
  Center,
  HStack,
  Square,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Optional } from 'types/utils';

import {
  CustomTooltip,
  dateLabel,
  formatTooltip,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { ActionButton } from 'components/DataEntry';
import { SwitchOnOff } from 'components/Elements/SwitchIO/SwitchOnOff';
import {
  CommentIcon,
  IconTypes,
  SlackIcon,
  TimelineIcon,
} from 'components/Icons';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { ApproveDenyModal } from 'containers/Visibility/JitRequests/Components/ApproveDenyModal';
import { AutoApproveModal } from 'containers/Visibility/JitRequests/Components/AutoApproveModal';
import { JitRequestSubComponent } from 'containers/Visibility/JitRequests/Components/JitRequestSubComponent';
import { PolicyDocumentModal } from 'containers/Visibility/JitRequests/Components/PolicyDocumentModal';
import { ResourceActionsDrawer } from 'containers/Visibility/JitRequests/Components/ResourceActionsDrawer';
import {
  getCloudUser,
  JitApprovalStatus,
  renderCloudIcon,
} from 'containers/Visibility/JitRequests/Components/utils';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { actions } from 'containers/Visibility/JitRequests/slice';

export const JitRequestsTable = () => {
  const dispatch = useDispatch();
  const [activeRow, setActiveRow] = useState<
    Optional<AccessRequestGetResponse>
  >(null);
  const approveDenyModal = useDisclosure();
  const policyDocumentModal = useDisclosure();
  const autoApprove = useDisclosure();
  const metadataDrawer = useDisclosure();

  const { jitRequests } = useSelector(selectJitRequests);

  useEffect(() => {
    dispatch(
      actions.getJitRequests({
        q: {
          page: jitRequests.page.info.page_number,
          size: jitRequests.page.info.page_size,
        },
      }),
    );
  }, [dispatch, jitRequests.page.info]);

  const requestAccount = useCallback(row => {
    return row?.access_request_details?.[row.cloud_type.toLowerCase()] ?? {};
  }, []);

  const handleAutoApprove = useCallback(
    (e, row) => {
      e.preventDefault();
      e.stopPropagation();
      setActiveRow(row);
      autoApprove.onOpen();
    },
    [autoApprove],
  );

  const columns: TableColumnProps<AccessRequestGetResponse>[] = useMemo(() => {
    return [
      {
        header: <Box pl="thLeftPaddingWithIcon">Requester</Box>,
        render: ({ row }) => {
          return (
            <WithResourceIcon resourceType={IconTypes.User}>
              {getCloudUser(row, requestAccount(row))}
            </WithResourceIcon>
          );
        },
      },
      {
        header: 'Account',
        align: 'left',
        render: ({ row }) => {
          return (
            <StackedCell
              upper={
                <Box boxSize={5}>
                  <Center bg={'transparent'}>
                    {renderCloudIcon(row.cloud_type?.toLowerCase() ?? '')}
                  </Center>
                </Box>
              }
              lower={requestAccount(row)?.cloud_account_id ?? ''}
            />
          );
        },
      },
      {
        header: 'Role',
        align: 'left',
        render: ({ row }) => {
          const cloudDetails = requestAccount(row);
          if (!!cloudDetails?.role_uuid && !!cloudDetails?.role_name) {
            return (
              <StackedCell
                upper={cloudDetails.role_name ?? '-'}
                lower={cloudDetails.role ?? '-'}
                showLowerTooltip={false}
              />
            );
          }

          return 'Custom';
        },
      },
      /*{
        header: 'Role Permissions',
        align: 'left',
        sortKey: 'x',
        render: ({ row }) => {
          const permissions = requestAccount(row)?.crud_counts ?? {};
          return (
            <Flex align={'start'} gap={1} overflow={'wrap'}>
              {renderHighPrivileges({
                value: permissions,
              })}
            </Flex>
          );
        },
      },*/
      {
        header: 'Received',
        accessor: 'request_creation_time',
        align: 'left',
        render: ({ row, value }) => {
          return (
            <StackedCell
              upper={dateLabel(value)}
              lower={
                <HStack gap={2}>
                  <Text>{dayjs.utc(value).local().format('hh:mm A')}</Text>
                  <Square size={4}>
                    <SlackIcon />
                  </Square>
                </HStack>
              }
              showLowerTooltip={false}
              showUpperTooltip={false}
            />
          );
        },
      },
      {
        header: 'Duration',
        accessor: 'duration',
        render: ({ row }) => {
          const { requested_end_time, requested_start_time } = row;
          if (!requested_start_time || !requested_end_time) return;
          const start = dayjs(requested_start_time);
          const end = dayjs(requested_end_time);
          const duration = end.diff(start, 'minute');

          const hours = Math.floor(duration / 60);
          const minute = duration % 60;
          const durationLabel =
            minute === 0 ? `${hours}h` : `${hours}h ${minute}`;
          return (
            <Stack spacing={1}>
              <Text>{durationLabel}</Text>
            </Stack>
          );
        },
      },
      {
        header: 'Status',
        accessor: 'status',
        align: 'center',
        render: ({ row, value }) => {
          return (
            <Center w="full" fontSize="xs">
              <JitApprovalStatus status={value} />
            </Center>
          );
        },
      },
      {
        header: 'Auto-Approval',
        render: ({ row }) => {
          return (
            <Center>
              <CustomTooltip
                label={formatTooltip({
                  header: 'AUTO-APPROVAL',
                  content:
                    'Automatically approve future requests of exactly same resource privileges that are needed on a recurring basis',
                  width: 48,
                })}
              >
                <SwitchOnOff
                  value={row.auto_approve ? 1 : 0}
                  onClick={e => handleAutoApprove(e, row)}
                />
              </CustomTooltip>
            </Center>
          );
        },
      },
      {
        header: 'Actions',
        render: ({ row }) => {
          return (
            <Center w="full">
              <ActionButton
                label="View"
                icon={<CommentIcon />}
                onClick={() => {
                  metadataDrawer.onOpen();
                  setActiveRow(row);
                }}
                p={0.5}
              />
              <ActionButton
                label="Approve or Deny"
                icon={<ManualIcon />}
                onClick={() => {
                  setActiveRow(row);
                  approveDenyModal.onOpen();
                }}
              />
              <ActionButton
                label="View Policy Document"
                icon={<TimelineIcon />}
                onClick={() => {
                  setActiveRow(row);
                  policyDocumentModal.onOpen();
                }}
              />
            </Center>
          );
        },
      },
    ];
  }, [
    requestAccount,
    handleAutoApprove,
    metadataDrawer,
    approveDenyModal,
    policyDocumentModal,
  ]);

  return (
    <>
      <Box flex={1}>
        <Table
          columns={columns}
          subComponent={({ row, expanded }) => {
            return (
              expanded && (
                <JitRequestSubComponent
                  row={row}
                  requestAccount={requestAccount}
                />
              )
            );
          }}
          data={jitRequests.data ?? []}
          pagination={{
            totalCount: jitRequests.page.totalCount ?? 0,
            pageInfo: jitRequests.page.info,
            onChange: pageInfo =>
              dispatch(actions.updateJitRequestsPagination(pageInfo)),
          }}
          isLoading={jitRequests.isLoading}
          noDataMessage={<Center h={'160px'}>No access requests</Center>}
        />
      </Box>
      {activeRow && approveDenyModal.isOpen && (
        <ApproveDenyModal
          request={activeRow}
          isOpen={approveDenyModal.isOpen}
          onClose={approveDenyModal.onClose}
        />
      )}
      {activeRow && policyDocumentModal.isOpen && (
        <PolicyDocumentModal
          request={activeRow}
          isOpen={policyDocumentModal.isOpen}
          onClose={policyDocumentModal.onClose}
        />
      )}
      {activeRow && autoApprove.isOpen && (
        <AutoApproveModal
          request={activeRow}
          isOpen={autoApprove.isOpen}
          onClose={autoApprove.onClose}
        />
      )}
      {activeRow && metadataDrawer.isOpen && (
        <ResourceActionsDrawer
          resource={{
            cloud_type: activeRow?.cloud_type ?? '',
            cloud_id: requestAccount(activeRow)?.cloud_account_id ?? '',
            name: requestAccount(activeRow)?.aws_username ?? '',
            type: NativeResources.IamUser,
            id: requestAccount(activeRow)?.account_id ?? '',
          }}
          isOpen={metadataDrawer.isOpen}
          onClose={metadataDrawer.onClose}
        />
      )}
    </>
  );
};
