import React, { memo, useEffect, useMemo, useState } from 'react';

import { Box, useColorModeValue } from '@chakra-ui/react';
import { cloneDeep, isEmpty, merge, map, forEach } from 'lodash';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceDot,
} from 'recharts';
import { ResponsiveContainer } from 'recharts';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { DARK_COLORS, LIGHT_COLORS } from '../utils';

import { defaultStyles } from './styles';
import { ILineChart } from './types';

export const CustomLineChart: React.FC<ILineChart> = ({
  data,
  xField,
  yField,
  legend = true,
  tooltip = true,
  styles,
  overwriteDefaultStyles = false,
  customTooltipContent,
  cartesianGrid = false,
  isLoading = false,
  referenceLines = [],
  referenceDots = [],
  colorMap,
  ignoreZero = false,
  tinyChart = false,
}) => {
  const COLORS = useColorModeValue(LIGHT_COLORS, DARK_COLORS);
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  const [filterData, setFilterData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (ignoreZero)
      setFilterData(
        map(data, o => {
          const obj: Record<string, any> = {};
          forEach(o, (i, key) => (obj[key] = i === 0 ? null : i));
          return obj;
        }),
      );
    else setFilterData(data);
  }, [data, ignoreZero]);

  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : isEmpty(data) ? (
        <NoDataAvailable />
      ) : (
        <ResponsiveContainer>
          <LineChart data={filterData} {...baseStyles?.lineChart}>
            {!tinyChart && <XAxis dataKey={xField} {...baseStyles?.xAxis} />}
            {!tinyChart && (
              <YAxis {...baseStyles?.yAxis} allowDecimals={false} />
            )}

            {map(referenceLines, o => (
              <ReferenceLine {...o} />
            ))}
            {map(referenceDots, o => (
              <ReferenceDot {...o} />
            ))}

            {cartesianGrid && (
              <CartesianGrid {...(baseStyles?.cartesianGrid as any)} />
            )}
            {tooltip && (
              <Tooltip
                {...baseStyles?.tooltip}
                content={customTooltipContent}
              />
            )}
            {legend && <Legend {...(baseStyles?.legend as any)} />}
            {yField.map((each: string, index: number) => {
              const color =
                colorMap?.[each] ||
                baseStyles?.lineColors?.[index] ||
                COLORS[index];
              return (
                <Line
                  key={`line-${each}`}
                  dataKey={each}
                  stroke={color}
                  {...(baseStyles?.line as any)}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export const MemoLineChart = memo<ILineChart>(CustomLineChart);
