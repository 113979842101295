import React from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import {
  AiOutlineCloudServer,
  AiOutlineFileProtect,
  AiOutlineMore,
} from 'react-icons/ai';
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineEdit,
  AiOutlineRight,
  BsDownload,
  AiOutlineCheck,
  IoCalendarClearOutline,
  IoShareSocialOutline,
  MdContentCopy,
  VscArrowLeft,
  IoIosArrowRoundUp,
  IoIosArrowRoundDown,
  FiFilter,
  IoRefreshOutline,
  AiOutlineClose,
  BiLinkExternal,
  BsQuestionCircle,
  AiOutlinePlus,
  RiHeartPulseLine,
  RiHistoryLine,
  AiOutlineDisconnect,
  AiOutlineSearch,
  AiOutlineMinus,
  IoBarChartOutline,
  BsTable,
  RiNodeTree,
  MdOutlineClear,
  BsArrowLeftRight,
  BsTerminal,
  BsCardChecklist,
  BsQuestion,
  VscArrowRight,
} from 'react-icons/all';
import { BsInfoCircle } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';

import { RediscoverIcon as CustomRediscoverIcon } from 'components/Icons/ReactCustomIcons/RediscoverIcon';

//resource blackIcons
export * from './ReactResourceIcons';

//custom blackIcons
export * from './ReactCustomIcons';

export * from './SvgResourcesIcon/icon-map';
export * from './SvgWhiteResourceIcons/icon-map';

export * from './types';

const IconContainer = (props: BoxProps) => {
  return (
    <Box as={AiOutlineEdit} w="full" h="full" color="inherit" {...props} />
  );
};

export const AddIcon = props => <IconContainer as={MdAdd} {...props} />;
/*export const ShareIcon = props => (
  <IconContainer as={IoShareSocialOutline} {...props} />
);*/
export const PlusIcon = props => (
  <IconContainer as={AiOutlinePlus} {...props} />
);
/*export const ClockIcon = props => (
  <IconContainer as={AiOutlineClockCircle} {...props} />
);*/
export const RightIcon = props => (
  <IconContainer as={AiOutlineRight} {...props} />
);
export const DownIcon = props => (
  <IconContainer as={AiOutlineDown} {...props} />
);
export const UpIcon = props => <IconContainer as={AiOutlineUp} {...props} />;
export const CheckmarkIcon = props => (
  <IconContainer as={AiOutlineCheck} {...props} />
);
export const CopyIcon = props => (
  <IconContainer as={MdContentCopy} {...props} />
);
/*export const DownloadIcon = props => (
  <IconContainer as={BsDownload} {...props} />
);*/
export const TableIcon = props => <IconContainer as={BsTable} {...props} />;
export const CalenderIcon = props => (
  <IconContainer as={IoCalendarClearOutline} {...props} />
);
/*export const OptionsIcon = props => (
  <IconContainer as={AiOutlineMore} {...props} />
);*/
export const LeftArrowIcon = props => (
  <IconContainer as={VscArrowLeft} {...props} />
);
export const RightArrowIcon = props => (
  <IconContainer as={VscArrowRight} {...props} />
);
export const ShowIcon = props => (
  <IconContainer as={BiLinkExternal} {...props} />
);
export const ArrowUpIcon = props => (
  <IconContainer as={IoIosArrowRoundUp} {...props} />
);
export const ArrowDownIcon = props => (
  <IconContainer as={IoIosArrowRoundDown} {...props} />
);
export const QuestionCircleIcon = props => (
  <IconContainer as={BsQuestionCircle} {...props} />
);
export const QuestionIcon = props => (
  <IconContainer as={BsQuestion} {...props} />
);
export const InfoCircleIcon = props => (
  <IconContainer as={BsInfoCircle} {...props} />
);
export const RefreshIcon = props => (
  <IconContainer as={IoRefreshOutline} {...props} />
);
export const CloseIcon = props => (
  <IconContainer as={AiOutlineClose} {...props} />
);
export const HeartPulseLineIcon = props => (
  <IconContainer as={RiHeartPulseLine} {...props} />
);
export const HistoryIcon = props => (
  <IconContainer as={RiHistoryLine} {...props} />
);
export const DisconnectIcon = props => (
  <IconContainer as={AiOutlineDisconnect} {...props} />
);
export const SearchIcon = props => (
  <IconContainer as={AiOutlineSearch} {...props} />
);
export const MinusIcon = props => (
  <IconContainer as={AiOutlineMinus} {...props} />
);
export const ResourcesIcon = props => (
  <IconContainer as={AiOutlineCloudServer} {...props} />
);
export const PolicyOutlineIcon = props => (
  <IconContainer as={AiOutlineFileProtect} {...props} />
);
export const BarChartIcon = props => (
  <IconContainer as={IoBarChartOutline} {...props} />
);
export const ReportIcon = props => (
  <IconContainer as={RiFileExcel2Line} {...props} />
);
export const TreeIcon = props => <IconContainer as={RiNodeTree} {...props} />;
export const CrossIcon = props => (
  <IconContainer as={MdOutlineClear} {...props} />
);
export const CompareIcon = props => (
  <IconContainer as={BsArrowLeftRight} {...props} />
);
export const TerminalIcon = props => (
  <IconContainer as={BsTerminal} {...props} />
);
export const CardChecklistIcon = props => (
  <IconContainer as={BsCardChecklist} {...props} />
);
