import React from 'react';

import { SlackForm } from './SlackForm';

interface Props {
  onSubmit: (data) => void;
}

export const EditSlack: React.FC<Props> = props => {
  return <SlackForm handleSubmit={props.onSubmit} validate />;
};
