import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SuppressAlarmIcon = props => (
  <Icon
    viewBox="0 0 461.843 461.843"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <g>
      <g>
        <path
          d="M150.754,223.538v-36.441c0-51.393,41.811-93.203,93.203-93.203c22.717,0,43.559,8.174,59.748,21.73l21.667-21.667
				c-14.53-12.711-32.059-22.074-51.388-26.894V30.028C273.985,13.444,260.541,0,243.957,0S213.93,13.444,213.93,30.028v37.035
				c-53.813,13.419-93.685,62.067-93.685,120.035v36.441c0,38.96-15.477,76.324-43.026,103.873
				c-7.373,7.373-11.593,17.009-12.292,26.99l77.776-77.776C147.998,259.664,150.754,241.814,150.754,223.538z"
        />
        <path
          d="M367.67,223.538v-36.441c0-6.44-0.493-12.765-1.442-18.939l-29.067,29.067v26.313c0,47.388,18.454,91.939,51.962,125.447
				c3.396,3.396,4.402,8.456,2.564,12.893s-6.128,7.303-10.93,7.303H165.204l-30.509,30.509h47.109
				c0,34.326,27.827,62.153,62.153,62.153c34.326,0,62.153-27.827,62.153-62.153h74.646c17.125,0,32.564-10.316,39.117-26.137
				c6.553-15.821,2.931-34.033-9.178-46.142C383.146,299.862,367.67,262.498,367.67,223.538z M243.957,431.334
				c-17.449,0-31.644-14.195-31.644-31.644h63.288C275.601,417.139,261.406,431.334,243.957,431.334z"
        />
        <path
          d="M424.02,52.837c-5.957-5.957-15.616-5.957-21.573,0L37.823,417.461c-5.957,5.957-5.957,15.616,0,21.573
				c2.979,2.978,6.883,4.468,10.787,4.468s7.808-1.49,10.786-4.468L424.02,74.41C429.978,68.453,429.978,58.794,424.02,52.837z"
        />
      </g>
    </g>
  </Icon>
);
