/**
 *
 * Elastic For SIEM Integration
 *
 */

import React, { FC, useEffect } from 'react';

import { Clients } from '@ariksa/notification';
import { HStack, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CheckmarkCircleOutlineIcon,
  CloseCircleOutlineIcon,
  renderTime,
  StackedCell,
} from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DeleteClientModal } from 'containers/Setup/Integrations/DeleteClientModal';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { actions } from '../slice';

export const SIEMTable: FC = () => {
  const dispatch = useDispatch();
  const { clients, client } = useSelector(selectIntegrations);
  const {
    clientActionsColumn,
    isOpenDeleteClientModal,
  } = useIntegrationsContext();

  useEffect(() => {
    dispatch(
      actions.loadClients({
        clientName: Clients.Elasticsearch,
      }),
    );
  }, [dispatch]);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      styles: {
        maxWidth: 200,
      },
    },
    {
      header: 'URL',
      accessor: 'elastic_search.url',
      align: 'left',
    },
    {
      header: 'Used to Push Data',
      accessor: '',
      align: 'left',
      render: ({ value }) =>
        value ? (
          <HStack>
            <Box boxSize={4} mb={2}>
              {value ? (
                <CheckmarkCircleOutlineIcon color="green" />
              ) : (
                <CloseCircleOutlineIcon color="red" />
              )}
            </Box>
            <Box>{value ? 'Yes' : 'No'}</Box>
          </HStack>
        ) : (
          '-'
        ),
    },
    {
      header: 'Added by',
      accessor: 'created_by',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.created_by}
          lower={renderTime({ value: row?.created_at })}
          upperTooltip={false}
          lowerTooltip={false}
        />
      ),
    },
    {
      header: 'Last Update Sent',
      accessor: 'last_update',
      align: 'left',
    },
    clientActionsColumn,
  ];

  return (
    <>
      <Table
        columns={columns}
        data={clients?.elasticsearch}
        isLoading={clients.loading || client.isLoading}
        cursor="pointer"
        subComponent={({ row, expanded }) =>
          expanded && (
            <Box h={'100px'}>
              <DetailsField
                label="Description"
                value={row?.description}
                isInline={false}
              />
            </Box>
          )
        }
      />
      {isOpenDeleteClientModal && <DeleteClientModal />}
    </>
  );
};
