import React, { FC, useEffect, useState } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { Accordion, Box } from '@chakra-ui/react';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { renderHighPrivilegesStackedCell } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useRedirectToGraph } from 'containers/ActiveCloudResource/hooks/useRedirectToGraph';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export interface Props {}

export const VMIdentity: FC<Props> = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { accessByIdentity } = canAccessSummary;
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const { redirectToGraph } = useRedirectToGraph();

  const [internal, setInternal] = useState<Dict<any>[]>([]);
  const [external, setExternal] = useState<Dict<any>[]>([]);

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.AccessByIdentity);
  }, [getCanAccessSummary]);

  useEffect(() => {
    setInternal(filter(accessByIdentity.data, o => !o?.external));
    setExternal(filter(accessByIdentity.data, o => o?.external));
  }, [accessByIdentity.data]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Principal</Box>,
      accessor: 'source_name',
      render: ({ row, value }) => (
        <WithResourceIcon resourceType={row?.source_native_class}>
          <StackedCell
            upper={value}
            lower={
              getCloudNativeName(row?.source_native_class) +
              ' / ' +
              getCloudAgnosticName(row?.source_agnostic_name)
            }
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Mechanism',
      align: 'left',
      accessor: 'permission_entity_name',
      render: ({ row, value }) => (
        <StackedCell
          upper={value}
          lower={getCloudAgnosticName(row?.permission_entity_agnostic_name)}
        />
      ),
    },
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    { header: '# Permissions', accessor: 'total_permission_count' },
    { header: 'Last Activity' },
  ];
  return (
    <Accordion allowMultiple>
      <MetaAccordionItem
        iconType={IconTypes.Identity}
        label={'INTERNAL PRINCIPALS THAT CAN ACCESS THIS RESOURCE'}
        onClickDownload={() => {}}
        panel={<Table columns={columns} data={internal} />}
        iconBGColor="primary"
        isLoading={accessByIdentity.isLoading}
        totalCount={internal?.length}
        onShowGraph={() => redirectToGraph('identity', MetadataTabs.Identity)}
      />
      <MetaAccordionItem
        iconType={IconTypes.Identity}
        label={'EXTERNAL PRINCIPALS THAT CAN ACCESS THIS RESOURCE'}
        onClickDownload={() => {}}
        panel={<Table columns={columns} data={external} />}
        iconBGColor="critical"
        isLoading={accessByIdentity.isLoading}
        totalCount={external?.length}
        onShowGraph={() => redirectToGraph('identity', MetadataTabs.Identity)}
      />
    </Accordion>
  );
};
