import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SqlDatabaseIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M126.5,8.6h-106C9.1,8.6,0,17.8,0,29v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4V29
		C146.9,17.8,137.7,8.6,126.5,8.6z M106,114.7H40.8V49.5H106V114.7z"
      />
      <path
        d="M309,8.6H203c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4V29
		C329.4,17.8,320.3,8.6,309,8.6z M288.6,114.7h-65.2V49.5h65.2V114.7z"
      />
      <path
        d="M491.6,8.6h-106c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4V29
		C512,17.8,502.9,8.6,491.6,8.6z M471.2,114.7H406V49.5h65.2V114.7z"
      />
      <path
        d="M126.5,182.6h-106C9.1,182.6,0,191.7,0,203v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4V203
		C146.9,191.7,137.7,182.6,126.5,182.6z M106,288.6H40.8v-65.2H106V288.6z"
      />
      <path
        d="M309,182.6H203c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4V203
		C329.4,191.7,320.3,182.6,309,182.6z M288.6,288.6h-65.2v-65.2h65.2V288.6z"
      />
      <path
        d="M491.6,182.6h-106c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4
		V203C512,191.7,502.9,182.6,491.6,182.6z M471.2,288.6H406v-65.2h65.2V288.6z"
      />
      <path
        d="M126.5,356.5h-106C9.1,356.5,0,365.6,0,376.9v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4
		v-106C146.9,365.6,137.7,356.5,126.5,356.5z M106,462.5H40.8v-65.2H106V462.5z"
      />
      <path
        d="M309,356.5H203c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4v-106
		C329.4,365.6,320.3,356.5,309,356.5z M288.6,462.5h-65.2v-65.2h65.2V462.5z"
      />
      <path
        d="M491.6,356.5h-106c-11.3,0-20.4,9.1-20.4,20.4v106c0,11.3,9.1,20.4,20.4,20.4h106c11.3,0,20.4-9.1,20.4-20.4
		v-106C512,365.6,502.9,356.5,491.6,356.5z M471.2,462.5H406v-65.2h65.2V462.5z"
      />
    </g>
  </Icon>
);
