import React, { useEffect } from 'react';

import { Accordion, Checkbox, Stack } from '@chakra-ui/react';
import { isDeepEqual } from 'react-use/lib/util';

import {
  checkboxFieldStyles,
  ConfigurationIcon,
  MonitoringIcon,
  PageHeaderWithIcon,
} from 'app/components';
import { Card } from 'components/DataDisplay';
import { ContextFilter } from 'containers/Visibility/Components/Filter/ContextFilter';
import { ResourceFilter } from 'containers/Visibility/Components/Filter/ResourceFilter';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { SelectSourceEntity } from 'containers/Visibility/SecurityGraphNext/Components/SelectSourceEntity';
import { useGraphFilters } from 'containers/Visibility/SecurityGraphNext/hooks/useGraphFilters';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { SecurityGraphMapType } from 'containers/Visibility/SecurityGraphNext/types';

export const SecurityGraphFilters = () => {
  const { redirectInfo, prevRedirectInfo } = useRedirectInfo();
  const { mapType, searchResult } = useSearchResult();
  const {
    resourceFilters,
    setResourceFilters,
    contextFilters,
    setContextFilters,
    handleToggleAlerting,
  } = useGraphFilters();

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      redirectInfo != prevRedirectInfo &&
      !isDeepEqual(redirectInfo, prevRedirectInfo)
    ) {
      console.log('reset filters');
      console.log(redirectInfo, prevRedirectInfo);
      setContextFilters([]);
      setResourceFilters([]);
    }
  }, [prevRedirectInfo, redirectInfo, setContextFilters, setResourceFilters]);

  return (
    <>
      <Card styles={{ card: { p: 2 } }}>
        <Stack w="full" spacing={4}>
          <Stack>
            <PageHeaderWithIcon
              label="Refine Graph"
              icon={<ConfigurationIcon />}
              reversed
              fontSize="sm"
              fontColor="primary"
              spacing={2}
            />
          </Stack>

          <Accordion allowMultiple defaultIndex={[0, 1, 2, 4]}>
            <Stack spacing={4}>
              <SelectSourceEntity />

              {mapType === SecurityGraphMapType.Access && (
                <ResourceFilter
                  nodes={searchResult.data.nodes ?? []}
                  resourceFilters={resourceFilters}
                  setResourceFilters={setResourceFilters}
                />
              )}

              {mapType === SecurityGraphMapType.Posture && (
                <ContextFilter
                  nodes={searchResult.data.nodes ?? []}
                  resources={searchResult.data.resources ?? []}
                  contextFilters={contextFilters}
                  setContextFilters={setContextFilters}
                />
              )}

              <SidebarAccordion
                header={'Enrich insights'.toUpperCase()}
                icon={<MonitoringIcon />}
              >
                <Stack spacing={3}>
                  <Stack>
                    <Checkbox
                      {...checkboxFieldStyles.checkbox}
                      onChange={handleToggleAlerting}
                    >
                      Entities with findings
                    </Checkbox>
                  </Stack>
                </Stack>
              </SidebarAccordion>
            </Stack>
          </Accordion>
        </Stack>
      </Card>
    </>
  );
};
