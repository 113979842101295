import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ServiceNowIcon = props => (
  <Icon viewBox="0 0 64 64" h="full" w="full" {...props}>
    <path
      d="M32.195 3.312A32.267 32.267 0 0 0 9.949 58.883a6.346 6.346 0 0 0 8.264.43 23.035 23.035 0 0 1 27.445 0 6.364 6.364 0 0 0 8.389-.43A32.267 32.267 0 0 0 32.195 3.312m-.18 48.275a15.632 15.632 0 0 1-16.133-16.026 16.044 16.044 0 1 1 32.07 0 15.614 15.614 0 0 1-16.026 16.026"
      fillRule="evenodd"
      fill="#81b5a1"
    />
  </Icon>
);
