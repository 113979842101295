import React, { FC, useEffect, useState } from 'react';

import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { colorMapForCharts } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { pieChartLegendFormatter } from 'containers/Visibility/Data/Components/Dashboard/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SensitiveDataByRegionPieChart: FC = () => {
  const { sensitiveDataByRegion } = useSelector(selectDataDashboard);
  const { regions, total = 0 } = sensitiveDataByRegion.data;
  const { getResourceAlias } = useResourceType();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!!regions) {
      setData(
        map(regions, o => ({
          name: o.region,
          value: o.region_count,
        })),
      );
    }
  }, [regions, getResourceAlias]);

  return (
    <>
      <PieChart
        isLoading={sensitiveDataByRegion.isLoading}
        data={data}
        dataKey="value"
        total={formatNumber(total)}
        outerRadius="85%"
        innerRadius="76%"
        activeShape={false}
        colorMap={colorMapForCharts}
        styles={{
          pie: { cx: '45%' },
          legend: {
            wrapperStyle: {
              width: '135px',
            },
            formatter: pieChartLegendFormatter,
          },
        }}
      />
    </>
  );
};
