import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AnomalousBehaviourIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      d="M3 6C3 7.65685 4.34315 9 6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M3 18C3 19.6569 4.34315 21 6 21C7.65685 21 9 19.6569 9 18C9 16.3431 7.65685 15 6 15C4.34315 15 3 16.3431 3 18Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M15 6C15 7.65685 16.3431 9 18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
    <path
      d="M6 15V9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 9V12.3242C18 16.9982 16.9424 18 12.008 18H9"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      fill="none"
    />
  </Icon>
);
