import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CrossAccount,
  CloudManaged,
  PolicyType,
  Principals,
} from '../../Components/MetadataField/MetaGridFields';

export const ResourcePolicyMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields
          availabilityZone="None"
          vpc="None"
          createdAt="None"
        />
        {CrossAccount(insight)}
        {CloudManaged(insight)}
        {PolicyType(insight)}
        {Principals(insight)}
        <PolicyDocument
          label="Document"
          value={resource?.config?.PolicyDocument}
        />
      </Grid>
    </Stack>
  );
};
