import React, { FC, useEffect } from 'react';

import { Center, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { DeleteActionButton } from 'components/index';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';

interface Props {
  onClose: (isSubmit: boolean) => void;
}

export const DetachContext: FC<Props> = props => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { attachedContexts, createTag: detachContext } = useSelector(
    selectInventory,
  );
  const { currentInstance } = useInventoryContext();
  const { getResourceData } = useInventory();

  //get context list
  useEffect(() => {
    dispatch(
      actions.getAttachedContexts({
        q: {
          resourceId: currentInstance?.uuid,
          accountId: [currentInstance.account],
        },
      }),
    );
  }, [dispatch, detachContext, currentInstance]);

  const columns = [
    { header: 'Name', accessor: 'label_name' },
    { header: 'Value', accessor: 'value', align: 'left' },
    {
      header: 'Actions',
      render: ({ row }) => (
        <Center>
          <DeleteActionButton
            name={row?.id}
            type="context"
            label="Detach"
            onConfirm={() => {
              dispatch(
                actions.detachContext({
                  q: { tagId: row?.id },
                  onSuccess: () => getResourceData(),
                }),
              );
              onClose(true);
            }}
          />
        </Center>
      ),
    },
  ];

  return (
    <Box h="400px">
      <Table
        data={attachedContexts.data}
        columns={columns}
        isLoading={attachedContexts.isLoading}
      />
    </Box>
  );
};
