import React, { FC } from 'react';

import { Box, Stack, Accordion } from '@chakra-ui/react';

import { IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { Route53NetworkMapWrapper } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Network/Route53/Route53NetworkMapWrapper';

export const Route53HostedZoneNetworkMap: FC = () => {
  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={8}>
      <Box>
        <Accordion allowMultiple defaultIndex={[0]}>
          <MetaAccordionItem
            iconType={IconTypes.NetworkMap}
            label={'NETWORK MAP'}
            panel={<Route53NetworkMapWrapper />}
            iconBGColor="orange"
            height="570px"
          />
        </Accordion>
      </Box>
    </Stack>
  );
};
