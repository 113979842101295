import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Flex, Stack, Center, HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import {
  PageHeaderWithIcon,
  renderTime,
  StackedCell,
  Tag,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
} from 'components/DataEntry';
import { DataScanIcon, EditIcon } from 'components/Icons';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { EnvironmentName } from 'containers/App/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import {
  renderDataClassificationName,
  renderIncludedResources,
} from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassifcationTable/utils';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';

import { actions } from '../../../slice';

import { DataSources } from './DataSources';

export const DataClassificationTable: React.FC = () => {
  const dispatch = useDispatch();
  const { dataClassificationGroups } = useSelector(selectDataClassification);
  const { toCloudAccountId, toCloudAccountName } = useCloudAccountId();
  const { getResourceAlias } = useResourceType();
  const {
    onOpenForm,
    setCurrentRecord,
    setActionType,
  } = useDataConfigurationContext();

  useDeepCompareEffect(() => {
    dispatch(
      actions.getDataClassificationGroups({
        q: {},
      }),
    );
  }, [dispatch]);

  const getAccountType = row => {
    let type: string = CloudProviders.Aws;
    const service: string = row?.data_classifications?.[0]?.service;
    switch (service) {
      case NativeResources.SnowflakeDatabases:
        type = CloudProviders.Snowflake;
        break;
      case NativeResources.GitLabProject:
        type = SupportedServices.GitLabBranch;
        break;
      case NativeResources.GitHubRepository:
        type = SupportedServices.GitHubBranch;
        break;
      case NativeResources.BitBucketRepository:
        type = SupportedServices.BitBucketBranch;
        break;
    }
    dispatch(actions.updateAccountType(type));
    return type;
  };

  const renderAction = ({ row }) => {
    return (
      <Center
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionButton
          label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            setCurrentRecord(row);
            const type = getAccountType(row);
            setActionType('Update');
            onOpenForm();
            const data = row?.data_classifications[0];
            let sources = data.data_sources;
            if (data?.service === NativeResources.SnowflakeDatabases)
              sources = data.data_objects;
            /*we are sending repo name in data sources in repo_name:branch_name format
             * in case all is selected, we are sending data_sources = [repo_name]
             * so to set some or all we are checking is the data_sources length is 1
             * and if it includes :
             * if no we need to set the quantifier to some*/ else if (
              type !== CloudProviders.Aws &&
              data.data_sources?.length === 1 &&
              !data?.data_sources?.[0]?.includes(':')
            )
              sources = [];
            let service = data?.service;
            if (service === NativeResources.GitLabProject)
              service = SupportedServices.GitLabBranch;
            else if (service === NativeResources.GitHubRepository)
              service = SupportedServices.GitHubBranch;
            else if (service === NativeResources.BitBucketRepository)
              service = SupportedServices.BitBucketBranch;

            dispatch(
              actions.updateService({
                ...data,
                service,
                quantifier: !!sources?.length ? 'some' : 'all',
              }),
            );
          }}
        />
        <DeleteActionButton
          name={row?.name}
          type="data classification"
          onConfirm={() => {
            dispatch(
              actions.deleteDataClassification({
                q: { dataClassificationGroupId: row?.id },
                onSuccess: () =>
                  dispatch(actions.getDataClassificationGroups({ q: {} })),
              }),
            );
          }}
        />
      </Center>
    );
  };

  const renderAccounts = ({ row }) => {
    const accounts = row?.service_ids;
    return (
      <StackedCell
        upper={<EnvironmentName environmentId={row?.environment_id} />}
        lower={
          accounts?.length + (accounts?.length === 1 ? ' account' : ' accounts')
        }
        lowerTooltip={
          <Stack>
            <Box>Accounts:</Box>
            {map(row?.service_ids, (o, index) => (
              <Box pl={2}>
                {index + 1}.{'  '}
                {toCloudAccountName(o) + ' (' + toCloudAccountId(o) + ')'}
              </Box>
            ))}
          </Stack>
        }
        showUpperTooltip={!!row?.ids?.length}
        showLowerTooltip={!!accounts?.length}
      />
    );
  };

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      align: 'left',
      render: renderDataClassificationName,
    },
    {
      header: 'Accounts',
      align: 'left',
      render: renderAccounts,
    },
    {
      header: 'Resource Types',
      render: ({ row }) => (
        <HStack spacing={1}>
          {map(row?.data_classifications, o => (
            <Tag label={getResourceAlias(o?.service)} />
          ))}
        </HStack>
      ),
      align: 'left',
    },
    {
      header: 'Included Resources',
      render: renderIncludedResources,
    },
    {
      header: 'Last Scan',
      accessor: 'last_scanned',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Created At',
      align: 'left',
      accessor: 'created_at',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Stack h="full" spacing={5} w="full">
      <Stack w="full" justify="space-between" isInline>
        <PageHeaderWithIcon label="Data Sources" icon={<DataScanIcon />} />
        <Flex>
          <AddButton
            label="Add Data Source"
            onClick={() => {
              onOpenForm();
              setCurrentRecord({});
              dispatch(actions.updateAccountType(CloudProviders.Aws));
              setActionType('Add');
            }}
          />
        </Flex>
      </Stack>
      <ActiveResourceProvider>
        <Box flex={1}>
          <Table
            data={dataClassificationGroups.data}
            columns={columns}
            isLoading={dataClassificationGroups.isLoading}
            subComponent={({ row, expanded }) =>
              expanded && <DataSources row={row} isExpanded={expanded} />
            }
            cursor="pointer"
          />
        </Box>
      </ActiveResourceProvider>
    </Stack>
  );
};
