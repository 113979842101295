import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { appSaga } from 'containers/App/saga';
import { selectApp } from 'containers/App/selectors';
import { actions, reducer, sliceKey } from 'containers/App/slice';
import { useInjector } from 'utils/inject';

export function useApp() {
  useInjector(sliceKey, reducer, appSaga);
  const dispatch = useDispatch();
  const { organization } = useSelector(selectApp);

  const getUserAuth = useCallback(() => {
    dispatch(actions.getUserRoles({ q: {} }));
  }, [dispatch]);

  const isMasterOrg = useMemo(
    () => !!organization && organization === 'master',
    [organization],
  );

  return {
    getUserAuth,
    isMasterOrg,
  };
}
