/**
 *
 * AzureActiveDirectory
 *
 */

import React from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { AddAzureAD } from './AddAzureAD';
import { UpdateAzureAD } from './UpdateAzureAD';

export const AzureActiveDirectory = () => {
  const { actionType } = useIntegrationsContext();

  return actionType === 'Add' ? <AddAzureAD /> : <UpdateAzureAD />;
};
