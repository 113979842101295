import React, { FC, useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/scan-analysis';
import { map, sortBy, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useEc2Summary } from 'services/ScanAnalysis/useScanAnalysisItemService';
import { customTheme } from 'theme';

import { PieChart } from 'components/Visualization';
import { selectApp } from 'containers/App/selectors';

interface IEC2Summary {
  summaryType: SummaryType;
}

export const EC2Summary: FC<IEC2Summary> = props => {
  const { summaryType } = props;
  const { ec2Summary } = useEc2Summary(summaryType);
  const { data: summaryData } = ec2Summary;
  const [pieData, setPieData] = useState<{ name: string; value: number }[]>([]);
  const { resourceTypes } = useSelector(selectApp);

  useEffect(() => {
    let data: { name: string; value: number }[] = [];
    map(summaryData?.items, (value, key) =>
      data.push({
        name: key,
        value,
      }),
    );

    setPieData(
      sortBy(data, d =>
        ['critical', 'high', 'medium', 'low'].indexOf(d.name.toLowerCase()),
      ),
    );
  }, [resourceTypes.data, summaryData, summaryType]);

  return (
    <PieChart
      total={sum(pieData.map(a => a.value))}
      dataKey={'value'}
      data={pieData}
      isLoading={ec2Summary.isLoading}
      color={
        summaryType === SummaryType.VulnerabilitySeverity
          ? [
              customTheme.colors.critical,
              customTheme.colors.high,
              customTheme.colors.medium,
              customTheme.colors.low,
            ]
          : []
      }
      styles={{
        legend: { wrapperStyle: { width: 155 } },
      }}
    />
  );
};
