import React from 'react';

import {
  Box,
  Link,
  Stack,
  Image,
  Center,
  HStack,
  Flex,
  Text,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';
import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

import gitlab_1 from './GitLab1.png';
import gitlab_2 from './GitLab2.png';
import gitlab_3 from './GitLab3.png';
import gitlab_4 from './GitLab4.png';

interface Props {
  onClose: () => void;
}

export const GitLabTokenHelp = (props: Props) => {
  const { onClose } = props;

  const renderImage = (src, alt) => (
    <Center>
      <Box
        border="1px solid"
        borderRadius={0}
        borderColor={customTheme.colors.gray['200']}
      >
        <Image src={src} w="full" fit="cover" alt={alt} />
      </Box>
    </Center>
  );

  return (
    <Stack h="full">
      <HelpHeader>Get GitLab Access Token</HelpHeader>
      <Stack px={4} spacing={6}>
        <HStack align={'start'}>
          <HelpCount count={1} />
          <Box>
            Log in to{' '}
            <Link
              color="primary"
              href="https://gitlab.com/users/sign_in"
              isExternal
            >
              GitLab
            </Link>
            .
          </Box>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={2} />
          <Stack spacing={4}>
            <Box>Go to project</Box>
            {renderImage(gitlab_1, 'gitlab_1')}
            {renderImage(gitlab_2, 'gitlab_2')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={3} />
          <Stack spacing={4}>
            <Text>
              Go to Settings {'->'} Access Tokens and click on Add new Token
              button. Choose role as <Text as="b">Developer</Text>, click on{' '}
              <Text as="b">read_api</Text> in select scopes and create the
              token.
            </Text>
            {renderImage(gitlab_3, 'gitlab_3')}
            {renderImage(gitlab_4, 'gitlab_4')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={4} />
          <Stack spacing={4}>
            <Box>Copy and save the created token.</Box>
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  );
};
