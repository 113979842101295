import React, { FC, useRef, useState } from 'react';

import { ChecksSchemaStatusSeverityStatus } from '@ariksa/compliance-policies/api';
import { Stack, Box, HStack, Divider } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { customTheme } from 'theme';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { StackedHeader, Drawer, Select } from 'components/index';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { PoliciesTable } from 'containers/Compliance/Components/ComplianceTables/PoliciesTable';
import { complianceSeverityOptionsWithAll } from 'containers/Compliance/utils';

interface Props {
  subControl: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
}

export const SubControlDrawer: FC<Props> = props => {
  const { isOpen, onClose, subControl } = props;
  const dispatch = useDispatch();
  const { rules, selectedBlueprint } = useSelector(selectCompliance);
  const ref = useRef(document.querySelector('.portal-container'));
  const [severity, setSeverity] = useState(complianceSeverityOptionsWithAll[0]);

  const { withAccessBoundary } = useAccessBoundary();

  useDeepCompareEffect(() => {
    if (selectedBlueprint?.id) {
      dispatch(
        actions.loadRules({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            standardId: subControl?.id,
            //show: show ? 'FAILED' : 'ALL',
            page: rules.page.info.page_number,
            size: rules.page.info.page_size,
            severity: severity?.value?.toUpperCase() as ChecksSchemaStatusSeverityStatus,
          }),
        }),
      );
    }
  }, [
    dispatch,
    selectedBlueprint,
    rules.page.info,
    subControl,
    withAccessBoundary,
    severity,
  ]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={subControl?.compliance?.replace('_', ' ')}
          lower={
            'Control ' +
            subControl?.control +
            ' / ' +
            'Sub Control ' +
            subControl?.sub_control
          }
          type={subControl?.compliance}
          iconFilled={false}
        />
      }
      styles={{
        content: { maxW: '1500px' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
      closeButton
      body={
        <Stack h="full" w="full" mt={3} spacing={5}>
          <DetailsField
            label="Description"
            value={subControl?.sub_control_description}
          />
          <Divider color={customTheme.colors.gray['200']} />
          <HStack w="full" justify="flex-end">
            <Box w={48}>
              <Select
                options={complianceSeverityOptionsWithAll}
                value={severity}
                onChange={setSeverity}
                showIconInValueContainer
                menuPortalTarget={document.body}
              />
            </Box>
          </HStack>
          <PoliciesTable subControlRecord={subControl} />
        </Stack>
      }
    />
  );
};
