import {
  customizedXAxisTick,
  customizedYAxisTick,
} from 'components/Visualization/Charts/utils';

import { customTheme } from '../../../../theme';

export const commonStyles = {
  root: {
    h: '100%',
    w: '100%',
  },
  xAxis: {
    //axisLine: false,
    stroke: customTheme.colors.chartGridColor,
    padding: {
      //left: 10,
      right: 10,
    },

    tick: props => customizedXAxisTick(props),
  },
  yAxis: {
    //axisLine: false,
    //tickLine: false,
    stroke: customTheme.colors.chartGridColor,
    style: {
      textAlign: 'left',
    },
    tick: props => customizedYAxisTick(props),
  },
  cartesianGrid: {
    strokeDasharray: '2 2',
    stroke: customTheme.colors.chartGridColor,
    vertical: false,
  },
};

export const barStyles = {
  barSize: 10,
  radius: 0,
};
