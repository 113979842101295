import React, { FC, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { AggregatedAlertTable } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AggregatedAlertTable';

export const AlertDashboardAllFindings: FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Box
      p={5}
      bg={'#fff'}
      w={'full'}
      h={'full'}
      borderRadius={customTheme.radii.cardBorderRadius}
    >
      <AggregatedAlertTable
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        title="All Findings"
      />
    </Box>
  );
};
