import React, { FC, useState } from 'react';

import { Box, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  formatNumberWithComma,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { AddException } from 'containers/Compliance/Components/AddException';
import {
  renderPassedChecks,
  renderTableHeaderWithTooltip,
  renderViolations,
} from 'containers/Compliance/Components/ComplianceTables/utils';
import { History } from 'containers/Compliance/Components/History';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';
import { complianceSeverityOptionsWithAll } from 'containers/Compliance/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

import { ByResourceTypeSubComponent } from './ByResourceTypeSubComponent';

export const ResourceTable: FC = () => {
  const { byResourceType, resourceTypeSeverity: severity } = useSelector(
    selectCompliance,
  );

  const {
    resourceTypeRowIndex,
    renderRulesActions,
    isOpenSeverityModal,
    isOpenSuppress,
    onCloseSuppress,
    selectedResource,
    isOpenHistory,
  } = useCompliance();

  const dispatch = useDispatch();
  const { accountMapping } = useCloudAccountId();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const resources = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Entity Type</Box>,
      accessor: 'type',
      align: 'left',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={value}
          iconTooltip={<ResourceTypeIconTooltip resourceType={value} />}
          iconSize="sm"
        >
          <StackedCell
            upper={getCloudNativeName(value)}
            lower={getCloudAgnosticName(value)}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Entities',
      accessor: 'check_stats.total_entities',
      render: ({ value }) => formatNumberWithComma(value),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Account',
      accessor: 'account_id',
      align: 'left',
      render: ({ value }) => (
        <StackedCell
          upper={accountMapping[value]?.name}
          lower={accountMapping[value]?.cloud_account_id}
        />
      ),
    },
    {
      header: 'Policies',
      /*header: renderTableHeaderWithTooltip(
        'Policies',
        'Total number of checks applied to entities',
        'center',
      ),*/
      accessor: 'rules.total',
    },
    {
      header: renderTableHeaderWithTooltip(
        'Exceptions',
        'Entities that have policy exceptions',
        'center',
      ),
      accessor: 'check_stats.resource_exceptions',
    },
    {
      header: 'Violations',
      accessor: 'check_stats',
      render: renderViolations,
      align: 'left',
      //styles: { width: '300px', cell: { maxWidth: '300px' } },
    },
    {
      header: renderTableHeaderWithTooltip(
        'Passed policies',
        'Passed checks for this resource (incl. exceptions)',
        'left',
      ),
      accessor: 'check_stats',
      render: renderPassedChecks,
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Actions',
      accessor: '',
      render: renderRulesActions,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  return (
    <Stack h="full" spacing={4}>
      <HStack w="full" justify="flex-end">
        <Box w={48}>
          <Select
            options={complianceSeverityOptionsWithAll}
            value={find(
              complianceSeverityOptionsWithAll,
              o => o.value === severity,
            )}
            onChange={s =>
              dispatch(actions.changeResourceTypeSeverity(s?.value))
            }
            showIconInValueContainer
            menuPortalTarget={document.body}
          />
        </Box>
      </HStack>
      <Table
        data={byResourceType.data}
        columns={columns}
        cursor="pointer"
        isLoading={byResourceType.isLoading}
        onRowClick={row => {
          resources.onOpen();
          setCurrentRecord(row);
        }}
        rowIndex={resourceTypeRowIndex}
        pagination={{
          totalCount: byResourceType.page.totalCount,
          pageInfo: byResourceType.page.info,
          onChange: pageInfo => {
            dispatch(actions.updateByResourceTypePageInfo(pageInfo));
          },
        }}
      />
      {resources.isOpen && (
        <ByResourceTypeSubComponent
          row={currentRecord}
          isOpen={resources.isOpen}
          onClose={resources.onClose}
        />
      )}
      {isOpenHistory && <History />}
      {isOpenSeverityModal && <SeverityComponent />}
      {isOpenSuppress && (
        <AddException
          resource={selectedResource}
          isOpen={isOpenSuppress}
          onClose={onCloseSuppress}
        />
      )}
    </Stack>
  );
};
