/**
 *
 * Identity And Access
 *
 */

import React, { useEffect, useState } from 'react';

import { Box, Stack, HStack, Switch } from '@chakra-ui/react';
import { isEmpty, isEqual, map, values } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';

import { Card, sortTableData, Tag, WithResourceIcon } from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { selectApp } from 'containers/App/selectors';

export const ResourceTypes = () => {
  const [sortByField, setSortByField] = useState<Sorted>({
    sortField: '',
    sortOrder: '',
  });
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [searchedData, setSearchedData] = useState<Record<string, any>[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCollectionEnabled, setIsCollectionEnabled] = useState(true);

  const { resourceTypes } = useSelector(selectApp);

  useEffect(() => {
    setSearchedData(resourceTypes.data);
  }, [resourceTypes.data]);

  useEffect(() => {
    setData(
      sortTableData(
        filter(searchedData, o => o.collection_enable === isCollectionEnabled),
        sortByField,
      ),
    );
  }, [searchedData, sortByField, isCollectionEnabled]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Cloud Native</Box>,
      accessor: 'cloud_native_name',
      sortKey: 'cloud_native_class',
      align: 'left',
      render: ({ value, row }) => (
        <WithResourceIcon resourceType={row?.cloud_native_class}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Cloud Agnostic',
      accessor: 'cloud_agnostic_name',
      sortKey: 'cloud_agnostic_class',
      align: 'left',
    },
    {
      header: 'Internal Tags',
      accessor: 'internal_tags',
      align: 'left',
      render: ({ value }) =>
        !isEmpty(value) ? (
          <CustomTooltip
            label={
              <Stack>
                <Box>Internal Tags</Box>
                {map(value, (o, index) => (
                  <Box>
                    {index + 1}. {o}
                  </Box>
                ))}
              </Stack>
            }
          >
            <Box>
              <Tag label={value?.[0]} styles={{ tag: { mr: 1 } }} />
              {!!value?.[1] && (
                <Tag label={value?.[1]} styles={{ tag: { mr: 1 } }} />
              )}
              {value?.length > 2 && (
                <Tag
                  label={'+ ' + (value?.length - 2)}
                  styles={{ tag: { mr: 1 } }}
                />
              )}
            </Box>
          </CustomTooltip>
        ) : (
          '-'
        ),
    },
    {
      header: 'Category',
      accessor: 'cloud_agnostic_resource_category',
      sortKey: 'cloud_agnostic_resource_category',
      align: 'left',
    },
    {
      header: 'Alias',
      accessor: 'resource_alias',
      sortKey: 'resource_alias',
      align: 'left',
    },
  ];

  const handleSearch = val => {
    setSearchTerm(val);
    setSearchedData(
      resourceTypes.data?.filter(item => {
        return values(item).join('').toLowerCase().includes(val?.toLowerCase());
      }),
    );
  };

  return (
    <Card
      contentComponent={
        <Stack w="full" spacing={5}>
          <HStack justify="space-between">
            <Box w={'xl'}>
              <SearchBox
                value={searchTerm}
                onChange={handleSearch}
                onClickClose={() => setSearchTerm('')}
                placeholder="Search..."
              />
            </Box>
            <HStack>
              <Switch
                isChecked={isCollectionEnabled}
                onChange={e => setIsCollectionEnabled(e.target.checked)}
              />
              <Box>Collection Enabled</Box>
            </HStack>
          </HStack>
          <Table
            columns={columns}
            isLoading={resourceTypes.isLoading}
            data={data}
            sortBy={sortByField}
            onSort={sortInfo => {
              if (!isEqual(sortByField, sortInfo)) {
                setSortByField(sortInfo);
              }
            }}
          />
        </Stack>
      }
    />
  );
};
