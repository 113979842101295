import React, { useEffect, useState } from 'react';

import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { formatDate } from 'utils/date';

import { TotalCard } from './TotalCard';

export const DataSourcesAnalyzed = () => {
  const { dataSourcesAnalyzed } = useSelector(selectDataDashboard);
  const { total_sources } = dataSourcesAnalyzed.data;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setChartData(
      map(dataSourcesAnalyzed.data.sources, o => ({
        Date: formatDate(o?.created_at, 'D-MMM'),
        Count: o?.day_sources,
      })),
    );
  }, [dataSourcesAnalyzed.data]);

  return (
    <TotalCard
      label="Data Sources Analyzed"
      total={total_sources ?? 0}
      chartData={chartData}
      isLoading={dataSourcesAnalyzed.isLoading}
    />
  );
};
