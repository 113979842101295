import React, { FC } from 'react';

import { ButtonGroup, Skeleton } from '@chakra-ui/react';
import { isEqual } from 'lodash';

import { PrimaryButton } from 'components/DataEntry';
import { OptionItem } from 'components/DataEntry/Button/types';

interface IMapTabButtons {
  options: OptionItem[];
  value?: OptionItem;
  activeKey?: string;
  onChange?(button: any);
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const PrimaryButtonGroup: FC<IMapTabButtons> = props => {
  const { options, value, onChange, activeKey, isLoading, isDisabled } = props;

  return (
    <ButtonGroup variant="outline" isAttached>
      {options.map(b => (
        <PrimaryButton
          key={b.label}
          onClick={() => onChange?.(b)}
          isDisabled={isDisabled}
          isActive={
            (activeKey && b.key === activeKey) || isEqual(b.label, value?.label)
          }
          {...((activeKey && b.key === activeKey) ||
          isEqual(b.label, value?.label)
            ? { bg: 'primary', color: 'white' }
            : { color: 'primary', bg: 'white' })}
        >
          <Skeleton isLoaded={!isLoading}>{b.label}</Skeleton>
        </PrimaryButton>
      ))}
    </ButtonGroup>
  );
};
