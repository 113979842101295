import { entries, isString } from 'lodash';
import { Dict } from 'types/utils';

export const securityGraphRedirectUrl = (searchParams: Dict<any>) => {
  const params = new URLSearchParams();
  entries(searchParams).forEach(([key, value]) => {
    if (isString(value)) {
      params.append(key, value);
    }

    if (Array.isArray(value)) {
      value.forEach(v => {
        params.append(key, v);
      });
    }
  });

  params.set('redirected', 'true');

  return `/visibility/security-graph?${params.toString()}`;
};
