import {
  CheckDetailsInfo,
  CommandsApiGetPoliciesByRemediationIdRequest,
  PolicyResponse,
  RemediationApiAggregateRemediationRequest,
  RemediationApiCheckAggregateCommandRemediationRequest,
  RemediationApiCommandRemediationRequest,
  RemediationCheck,
} from '@ariksa/compliance-policies/api';

import { ComplianceService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useCheckAggregateCommandRemediation(
  checkDetailsInfo: CheckDetailsInfo,
) {
  const checkAggregateCommandRemediation = useAriksaQuery<
    RemediationApiCheckAggregateCommandRemediationRequest,
    RemediationCheck
  >({
    queryKey: [checkDetailsInfo],
    queryFn: () =>
      ComplianceService.Remediation.checkAggregateCommandRemediation({
        checkDetailsInfo: checkDetailsInfo,
      }).then(r => r.data),
  });

  return { checkAggregateCommandRemediation };
}

export function useAggregateRemediation() {
  const aggregateRemediation = useAriksaQuery<
    RemediationApiAggregateRemediationRequest,
    any
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Remediation.aggregateRemediation({
        ...req,
      }).then(r => r.data),
  });

  return { aggregateRemediation };
}

export function useCommandRemediation() {
  const commandRemediation = useAriksaQuery<
    RemediationApiCommandRemediationRequest,
    any
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Remediation.commandRemediation(req).then(r => r.data),
  });

  return { commandRemediation };
}

export function useRemediationService() {
  const rulesAttachedToRemediation = useAriksaQuery<
    CommandsApiGetPoliciesByRemediationIdRequest,
    PolicyResponse[]
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.RemediationCommands.getPoliciesByRemediationId(
        req,
      ).then(r => r.data),
  });

  const remediationCommands = useAriksaQuery<
    RemediationApiCommandRemediationRequest,
    any
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Remediation.commandRemediation(req).then(r => r.data),
  });
  return { rulesAttachedToRemediation, remediationCommands };
}
