import React, { FC } from 'react';

import { Box, HStack, Stack, UseDisclosureProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { SecurityGraphMapType } from 'containers/Visibility/SecurityGraphNext/types';

interface Props {
  setShowDependencies(): void;

  timeline: UseDisclosureProps;
}

export const Investigate: FC<Props> = props => {
  const { setShowDependencies, timeline } = props;
  const { mapType } = useSelector(selectSecurityGraphNext);

  const renderField = (label, iconType, onClick, tooltip) => (
    <HStack spacing={2}>
      <Box
        boxSize={5}
        p={iconType === IconTypes.Dependencies ? 0.5 : 0}
        color="primary"
      >
        {getIcon(iconType)}
      </Box>
      <HStack>
        <CustomTooltip label={tooltip} w={36} textAlign="center">
          <Box
            boxSize={5}
            onClick={onClick}
            color="primary"
            w={'full'}
            cursor={'pointer'}
          >
            <Box>{label}</Box>
          </Box>
        </CustomTooltip>
      </HStack>
    </HStack>
  );

  return (
    <Stack spacing={3}>
      <SidebarAccordion header="INVESTIGATE">
        <Stack spacing={3}>
          {mapType !== SecurityGraphMapType.Access &&
            renderField(
              'Show dependencies',
              IconTypes.Dependencies,
              setShowDependencies,
              'View blast radius of change',
            )}
          {renderField(
            'Show alert timeline',
            IconTypes.Timeline,
            () => timeline?.onOpen?.(),
            'What has been done with this alert',
          )}
        </Stack>
      </SidebarAccordion>
    </Stack>
  );
};
