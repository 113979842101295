import { colorMap, customTheme } from '../../../theme';

export const tourStyles = {
  badge: base => ({ ...base, background: customTheme.colors.primary }),
  dot: (base, current) => ({
    ...base,
    background: current ? customTheme.colors.primary : '#ccc',
  }),
  popover: base => ({
    ...base,
    background: '#fff',
    borderRadius: 10,
  }),
  close: base => ({
    ...base,
    padding: 4,
    marginLeft: 4,
    width: 16,
    height: 16,
    borderRadius: 4,
    boxShadow: 'none',
    borderColor: customTheme.colors.primary,
    '&:focus': {
      background: customTheme.colors.primary,
      color: '#fff',
      borderColor: customTheme.colors.primary,
    },
    '&:hover': {
      background: colorMap.primary(300),
      color: '#fff',
    },
    '&:active': {
      background: customTheme.colors.primary,
      color: '#fff',
    },
  }),
};
