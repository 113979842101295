import React from 'react';

import { JiraForm } from './JiraForm';

interface Props {
  onSubmit: (data) => void;
}

export const EditJira = (props: Props) => {
  return <JiraForm handleSubmit={props.onSubmit} validate />;
};
