import React, { ReactNode } from 'react';

import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Center,
  HStack,
  Square,
  Text,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

import { DownIcon, RightIcon } from 'components/Icons';
import { clearFormTextStyle } from 'containers/Visibility/Organization/styles';

interface ISidebarAccordion extends BoxProps {
  header: ReactNode | string;
  icon?: ReactNode;

  onClear?(): void;

  showClear?: boolean;
  iconPadding?: number;
}

export const SidebarAccordion = (props: ISidebarAccordion) => {
  const {
    header,
    onClear,
    showClear,
    children,
    icon,
    iconPadding = 0.5,
  } = props;
  return (
    <AccordionItem
      border={`1px solid ${customTheme.colors.gray['200']}`}
      px={0}
      borderRadius={8}
      bg="#f2f2f2"
      py={1}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton
            px={2}
            pt={2}
            pb={1}
            _hover={{ bg: 'inherit', borderRadius: 8 }}
            zIndex={800}
            fontWeight={600}
          >
            <HStack fontSize="sm" justify="space-between" w="full" zIndex={800}>
              <HStack flex={1} spacing={0}>
                <Box
                  textAlign="left"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  <HStack>
                    {icon && (
                      <Box boxSize={6} color="primary" p={iconPadding}>
                        <Center>{icon}</Center>
                      </Box>
                    )}
                    <Box>{header}</Box>
                  </HStack>
                </Box>
                {showClear && (
                  <Text
                    {...clearFormTextStyle}
                    w="50px"
                    onClick={() => onClear?.()}
                  >
                    Clear
                  </Text>
                )}
              </HStack>
              <Square ml={1} fontSize={'2xs'}>
                <Center>{isExpanded ? <DownIcon /> : <RightIcon />}</Center>
              </Square>
            </HStack>
          </AccordionButton>
          {isExpanded && (
            <Box px={2} py={2}>
              <Box borderBottom="1px solid" borderColor="gray.200" />
            </Box>
          )}
          <AccordionPanel pb={3} px={0}>
            <Box px={2}>{children}</Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
