import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';
import { TagsApiCreateTagsRequest } from '@ariksa/inventory-core';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  Catalogue,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  InventorySummary,
  ResourceApiGetDeploymentSummaryRequest,
  ResourceApiGetInventoryCatalogueRequest,
  ResourceApiGetInventorySummaryRequest,
  ResourceApiGetVpcsRequest,
  ResourceVulnerabilities,
  TagsApiGetTagsResourceRequest,
  TagsApiRemoveTagRequest,
  TagsRead,
  VPCInfo,
  VulnerabilitiesApiGetVulnerabilitiesRequest,
} from '@ariksa/inventory-core/api';
import { ResourceApiGetInventoryOverviewRequest } from '@ariksa/inventory-core/dist/api';
import {
  AllResourceCountResponse,
  InventoryApiGetAllResourceCountRequest,
} from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  InventoryService,
  ReportingService,
  WorkflowService,
} from 'api/services';

import { actions } from './slice';

export function* inventorySaga() {
  yield takeLatestAction(
    actions.getResourceCategories,
    doGetResourceCategories,
  );
  yield takeLatestAction(actions.getInstanceInventory, doGetInstanceInventory);
  yield takeLatestAction(actions.createTags, doCreateTags);
  yield takeLatestAction(actions.detachContext, doDetachContext);
  yield takeLatestAction(actions.getContextValues, doGetContextValues);
  yield takeLatestAction(actions.getAttachedContexts, doGetAttachedContexts);
  yield takeLatestAction(actions.getLastRefreshed, doGetLastRefreshed);
  yield takeLatestAction(actions.getDeploymentSummary, doGetDeploymentSummary);
  yield takeLatestAction(actions.getVulnerabilities, doGetVulnerabilities);
  yield takeLatestAction(actions.getInventoryOverview, doGetInventoryOverview);
  yield takeLatestAction(actions.getInventoryHistory, doGetInventoryHistory);
  yield takeLatestAction(actions.getVPCs, doGetVPCs);
}

export function* doGetResourceCategories(
  ctx: QueryContext<InventorySummary, ResourceApiGetInventorySummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getInventorySummary(ctx.params),
    {
      errorMsg: 'Failed to get resources categories!',
      cacheKey: 'inventoryResourceCategories',
    },
  );
}

export function* doGetVPCs(
  ctx: QueryContext<VPCInfo[], ResourceApiGetVpcsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Resource.getVpcs(ctx.params), {
    errorMsg: 'Failed to get VPCs!',
    cacheKey: 'vpcList',
  });
}

export function* doCreateTags(
  ctx: QueryContext<any, TagsApiCreateTagsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.createTags(ctx.params));
}

export function* doGetAttachedContexts(
  ctx: QueryContext<TagsRead[], TagsApiGetTagsResourceRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Tags.getTagsResource(ctx.params),
  );
}

export function* doDetachContext(
  ctx: QueryContext<any, TagsApiRemoveTagRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.removeTag(ctx.params));
}

export function* doGetContextValues(
  ctx: QueryContext<Catalogue[], ResourceApiGetInventoryCatalogueRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Resource.getInventoryCatalogue(ctx.params),
  );
}

export function* doGetInstanceInventory(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(ctx.fetch, () => InventoryService.InsightV2.insightV2(ctx.params));
}

export function* doGetDeploymentSummary(
  ctx: QueryContext<
    AppSchemasInventoryResourceCountDeploymentSummary[],
    ResourceApiGetDeploymentSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getDeploymentSummary(ctx.params),
    {
      errorMsg: 'Failed to get deployment summary!',
    },
  );
}

export function* doGetLastRefreshed(
  ctx: QueryContext<WorkflowRead[], WorkflowApiGetLatestRequest>,
) {
  yield call(ctx.fetch, () => WorkflowService.getLatest(ctx.params));
}

export function* doGetVulnerabilities(
  ctx: QueryContext<
    ResourceVulnerabilities[],
    VulnerabilitiesApiGetVulnerabilitiesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getVulnerabilities(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities!' },
  );
}

export function* doGetInventoryOverview(
  ctx: QueryContext<
    AppSchemasInventoryResourceCountDeploymentSummary[],
    ResourceApiGetInventoryOverviewRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getInventoryOverview(ctx.params),
    {
      errorMsg: 'Failed to get inventory overview!',
      cacheKey: 'inventoryOverview',
    },
  );
}

export function* doGetInventoryHistory(
  ctx: QueryContext<
    AllResourceCountResponse[],
    InventoryApiGetAllResourceCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getAllResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get inventory resource count and change!',
      cacheKey: 'inventoryResourceCountAndChange',
    },
  );
}
