import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { VulnerabilitiesPrioritizationComponent } from 'containers/Dashboard/utils/VulnerabilitiesPrioritizationComponent';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const VulnerabilitiesPrioritization: FC = () => {
  const { vulnerabilityPrioritization } = useSelector(selectVulnerabilities);

  return (
    <VulnerabilitiesPrioritizationComponent
      vulnerabilityPrioritization={vulnerabilityPrioritization}
    />
  );
};
