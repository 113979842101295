import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DNSSupport,
  IPv6Support,
  Subnets,
  TransitGatewayID,
  TransitGatewayOwnerID,
  VPCOwnerID,
} from '../../../Components/MetadataField/MetaGridFields';

export const TransitGatewayAttachmentMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const columns = [
    {
      header: 'Transit GW route table ID',
      accessor: 'TransitGatewayRouteTableId',
    },
    { header: 'State', accessor: 'State', align: 'left' },
  ];
  const data = [resource?.config?.Association];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {TransitGatewayID(insight)}
        {TransitGatewayOwnerID(insight)}
        {VPCOwnerID(resource)}
        {DNSSupport(resource)}
        {Subnets(resource)}
        {IPv6Support(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={data} />}
              iconType={IconTypes.Rules}
              label="Associations"
              totalCount={data?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
