import React from 'react';

import { Icon } from '@chakra-ui/react';
export const ClusterIcon = props => (
  <Icon viewBox="0 0 36 36" w="full" h="full" color="inherit" {...props}>
    <path
      d="M31.36,8H27.5v2H31V30H27.5v2H33V9.67A1.65,1.65,0,0,0,31.36,8Z"
      className="clr-i-outline clr-i-outline-path-1"
    />
    <path
      d="M5,10H8.5V8H4.64A1.65,1.65,0,0,0,3,9.67V32H8.5V30H5Z"
      className="clr-i-outline clr-i-outline-path-2"
    />
    <ellipse
      cx={18.01}
      cy={25.99}
      rx={1.8}
      ry={1.79}
      className="clr-i-outline clr-i-outline-path-3"
    />
    <path
      d="M24.32,4H11.68A1.68,1.68,0,0,0,10,5.68V32H26V5.68A1.68,1.68,0,0,0,24.32,4ZM24,30H12V6H24Z"
      className="clr-i-outline clr-i-outline-path-4"
    />
    <rect
      x={13.5}
      y={9.21}
      width={9}
      height={1.6}
      className="clr-i-outline clr-i-outline-path-5"
    />
    <rect x={0} y={0} width={36} height={36} fillOpacity={0} />
  </Icon>
);
