import React, { FC } from 'react';

import { Stack, Grid, GridItem, Box } from '@chakra-ui/react';

import {
  DetailsIdentifier,
  IconTypes,
  Modal,
  WithResourceIcon,
} from 'components/index';
import { EnvironmentName } from 'containers/App/utils';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';

import { AddWhitelistForm } from './AddWhitelistForm';

interface Props {
  isMulti?: boolean;
  data?: Record<string, any>[];
  isOpen: boolean;
  onClose: () => void;
}

export const AddWhitelist: FC<Props> = props => {
  const { isMulti = false, data, isOpen, onClose } = props;
  const { currentInstance } = useInventoryContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <WithResourceIcon resourceType={IconTypes.Whitelist} bgColor="primary">
          <Box>Allow list</Box>
        </WithResourceIcon>
      }
      size="xl"
      body={
        <Stack>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} pb={3}>
            <GridItem>
              {!isMulti && (
                <DetailsIdentifier label="Name" value={currentInstance.name} />
              )}
              {isMulti && (
                <DetailsIdentifier
                  label="Total Entities"
                  value={data?.length}
                />
              )}
            </GridItem>
            {!isMulti && (
              <GridItem>
                <DetailsIdentifier
                  label="Cloud ID"
                  value={currentInstance?.resource_id}
                />
              </GridItem>
            )}
            <GridItem>
              <DetailsIdentifier
                label="Entity type"
                value={currentInstance?.native_name}
              />
            </GridItem>
            <GridItem>
              <DetailsIdentifier
                label="Environment"
                value={
                  <EnvironmentName
                    environmentId={currentInstance?.environment_id}
                  />
                }
              />
            </GridItem>
          </Grid>
          <AddWhitelistForm
            isMulti={isMulti}
            data={data ?? []}
            onClose={onClose}
          />
        </Stack>
      }
    />
  );
};
