import {
  PageReportsResponse,
  ReportsApiAddReportRequest,
  ReportsApiDeleteReportRequest,
  ReportsApiDownloadReportRequest,
  ReportsApiEditReportRequest,
  ReportsApiGenerateReportRequest,
  ReportsApiGetJobsRequest,
} from '@ariksa/reporting';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Reports container
export const initialState: ContainerState = {
  reports: PagedQueryState.init([]),
  report: QueryState.init({}),
  pastReports: QueryState.init({}),
};

const allReportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    /*get reports*/
    getReports(state, action: QueryAction<PageReportsResponse>) {
      state.reports = PagedQueryState.next(state.reports, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    updateReportsPageInfo(state, action: PayloadAction<PageInfo>) {
      state.reports.page.info = action.payload;
    },

    /*add report*/
    addReport(state, action: QueryAction<any, ReportsApiAddReportRequest>) {
      state.report = QueryState.next(state.report, action);
    },

    /*update report*/
    updateReport(state, action: QueryAction<any, ReportsApiEditReportRequest>) {
      state.report = QueryState.next(state.report, action);
    },

    /*delete reports*/
    deleteReport(
      state,
      action: QueryAction<any, ReportsApiDeleteReportRequest>,
    ) {
      state.report = QueryState.next(state.report, action);
    },

    /*generate report*/
    generateReport(
      state,
      action: QueryAction<any, ReportsApiGenerateReportRequest>,
    ) {
      state.report = QueryState.next(state.report, action);
    },

    /*generate report*/
    downloadReport(
      state,
      action: QueryAction<any, ReportsApiDownloadReportRequest>,
    ) {
      state.report = QueryState.next(state.report, action);
    },

    /*get report types*/
    getPastReports(state, action: QueryAction<any, ReportsApiGetJobsRequest>) {
      state.pastReports = QueryState.next(state.pastReports, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = allReportsSlice;
