import React from 'react';

import { WidgetProps } from 'app/components/Visualization/CDashboard/types';
import { ThumbsDownIcon, ThumbsUpIcon } from 'components/Icons';
import { ComplianceFailedChecks } from 'containers/Compliance/Components/ComplianceDashboard/ComplianceFailedChecks';
import { PassedChecksHistoricalTrend } from 'containers/Compliance/Components/ComplianceDashboard/PassedChecksHistoricalTrend';
import { TotalViolations } from 'containers/Compliance/Components/ComplianceDashboard/TotalViolations';
import { ViolationsByAccounts } from 'containers/Compliance/Components/ComplianceDashboard/ViolationsByAccounts';

import {
  TotalEntities,
  Policies,
  EnforcedChecks,
  PassedChecks,
  FailedChecks,
  CheckExceptions,
} from './ComplianceSummary';

const styles = { root: { pb: 0 } };
export const availableWidgets1: { [key: string]: WidgetProps } = {
  totalEntities: {
    flex: true,
    components: {
      content: <TotalEntities />,
    },
    styles,
    className: 'compliance_summary',
  },
  policies: {
    flex: true,
    components: {
      content: <Policies />,
    },
    styles,
    className: 'compliance_summary',
  },
  enforcedChecks: {
    flex: true,
    components: {
      content: <EnforcedChecks />,
    },
    styles,
    className: 'compliance_summary',
  },
  passedChecks: {
    flex: true,
    components: {
      content: <PassedChecks />,
    },
    styles,
    className: 'compliance_summary',
  },
  failedChecks: {
    flex: true,
    components: {
      content: <FailedChecks />,
    },
    styles,
    className: 'compliance_summary',
  },
  checkExceptions: {
    flex: true,
    components: {
      content: <CheckExceptions />,
    },
    styles,
    className: 'compliance_summary',
  },
  violationsBySeverity: {
    name: 'Count of violations by severity',
    components: {
      content: <ComplianceFailedChecks />,
    },
    icon: <ThumbsDownIcon />,
    className: 'failed_check_by_severity',
    styles: { title: { iconBgColor: 'critical' } },
    showDivider: true,
  },
  totalViolations: {
    name: 'Violations by cloud accounts',
    components: {
      content: <ViolationsByAccounts />,
    },
    className: 'top_5_resource_types',
    icon: <ThumbsDownIcon />,
    styles: { title: { iconBgColor: 'critical' } },
    showDivider: true,
  },
  passedChecksHistoricalTrend: {
    name: 'Passed Checks',
    styles: { componentWrapper: { pt: 0 }, title: { iconBgColor: 'passed' } },
    components: {
      content: <PassedChecksHistoricalTrend />,
    },
    className: 'historical_trend_of_passed_checks',
    icon: <ThumbsUpIcon />,
    showDivider: true,
  },
};
