import React, { FC, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
  Box,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Select } from 'app/components/DataEntry/Select';
import { FormLabel } from 'components/DataEntry/Form/fields/FormLabel';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import {
  FieldProps,
  ReactSelectFieldSchema,
  ReactSelectFieldStyles,
} from '../types';

export const ReactSelectField: FC<FieldProps<ReactSelectFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    helpIcon,
    onChange,
    isMulti,
    defaultValue,
    styles = {},
    value,
    helpTooltip,
    onClickHelpIcon,
    ...rest
  } = field;
  const { register, watch, setValue, getValues } = useFormContext();
  const values = watch({ nest: true });
  const fieldStyles = useStyles<ReactSelectFieldStyles>(
    'reactSelectField',
    styles,
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    setValue(name, value || defaultValue || {});
  }, [value, defaultValue, name, setValue]);

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Skeleton isLoaded={!isLoading}>
        <Box {...fieldStyles.root}>
          <Select
            name={name}
            data-test={id}
            styles={fieldStyles.select}
            value={getValues(name)}
            // defaultValue={defaultValue}
            onChange={selected => {
              onChange?.(selected);
              setValue(name, selected);
            }}
            isLoading={isLoading}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            {...rest}
          />
        </Box>
      </Skeleton>
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      {!!errorMessage && (
        <FormErrorMessage {...fieldStyles.errorMessage}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  ) : null;
};
