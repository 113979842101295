import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { isCellEmpty } from 'components/DataDisplay/NewTable/Utils';

import { DATE_TIME_FORMAT } from './constants';

export const parseUtcDate = (date: string) => {
  if (!date) return null;

  if (date.slice(-1) === 'Z') {
    return dayjs(date).utc().tz();
  }

  return dayjs(date + 'Z')
    .utc()
    .tz();
};

export function formatDate(timestamp?: string, format = 'MM/DD/YY hh:mm A') {
  if (!timestamp) {
    return '-';
  }

  if (isCellEmpty(timestamp)) {
    return '-';
  }

  dayjs.extend(utc);
  return dayjs.utc(timestamp).local().format(format);
}

export function dateToSinceCounts(timestamp: string) {
  dayjs.extend(utc);

  const createdTime = dayjs.utc(timestamp);
  const now = dayjs.utc();

  const s = now.diff(createdTime, 'seconds');
  const m = now.diff(createdTime, 'minutes');
  const h = now.diff(createdTime, 'hours');
  const d = now.diff(createdTime, 'days');

  return {
    days: d,
    minutes: m,
    hours: h,
    seconds: s,
  };
}

export function dateToSince(timestamp: string) {
  if (isCellEmpty(timestamp)) {
    return '-';
  }
  dayjs.extend(utc);

  const createdTime = dayjs.utc(timestamp);
  const now = dayjs.utc();

  const s = now.diff(createdTime, 'seconds');
  const m = now.diff(createdTime, 'minutes');
  const h = now.diff(createdTime, 'hours');
  const d = now.diff(createdTime, 'days');

  if (s < 60) {
    return s + ' seconds';
  } else if (m < 60) {
    return m + ' minutes';
  } else if (h < 24) {
    return h + ' hours';
  } else {
    return d + ' days';
  }
}

export const utcFormatForBackend = timestamp => {
  return dayjs(timestamp).utc().format(DATE_TIME_FORMAT);
};
