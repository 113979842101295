import React, { useCallback, useMemo } from 'react';

import { Stack, Center } from '@chakra-ui/react';
import { identity, map, sortBy } from 'lodash';
import { useSelector } from 'react-redux';

import {
  getIcon,
  getResourceType as getResource,
  getResourceTypeOptions,
  getResourceTypeOptionsWithAlias,
  getResourceTypeOptionsWithAll,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { selectApp } from 'containers/App/selectors';

export const useResourceType = () => {
  const { resourceTypes } = useSelector(selectApp);

  const getResourceAlias = useCallback(
    (name: string) => {
      const data = getResource(name, resourceTypes.data);
      if (!!data) return data?.resource_alias ?? data?.cloud_native_name;
      return name;
    },
    [resourceTypes.data],
  );

  const getCloudAgnosticName = useCallback(
    (name: string) => {
      const data = getResource(name, resourceTypes.data);
      if (!!data) return data?.cloud_agnostic_name;
      return name;
    },
    [resourceTypes.data],
  );

  const getCloudAgnosticClass = useCallback(
    (name: string) => {
      const data = getResource(name, resourceTypes.data);
      if (!!data) return data?.cloud_agnostic_class;
      return name;
    },
    [resourceTypes.data],
  );

  const getCloudNativeName = useCallback(
    (name: string) => {
      const data = getResource(name, resourceTypes.data);
      if (!!data) return data?.cloud_native_name;
      return name;
    },
    [resourceTypes.data],
  );

  const getCloudNativeClass = useCallback(
    (name: string) => {
      const data = getResource(name, resourceTypes.data);
      if (!!data) return data?.cloud_native_class;
      return name;
    },
    [resourceTypes.data],
  );

  const getResourceType = useCallback(
    (name: string) => {
      return getResource(name, resourceTypes.data);
    },
    [resourceTypes.data],
  );

  const resourceTypeOptions = useCallback(
    () => sortBy(getResourceTypeOptions(resourceTypes?.data), o => o?.label),
    [resourceTypes?.data],
  );

  const resourceTypeOptionsWithAlias = useCallback(
    () =>
      sortBy(
        getResourceTypeOptionsWithAlias(resourceTypes?.data),
        o => o?.label,
      ),
    [resourceTypes?.data],
  );

  const resourceTypeAliasOptionsWithAll = useMemo(
    () => [
      { label: 'All resource types', value: undefined },
      ...sortBy(
        getResourceTypeOptionsWithAlias(resourceTypes?.data),
        o => o?.label,
      ),
    ],
    [resourceTypes?.data],
  );

  const agnosticResourceTypeOptionsWithAll = useMemo(
    () => [
      { label: 'All resource types', value: undefined },
      ...sortBy(
        map(resourceTypes.data, o => ({
          label: o?.cloud_agnostic_name,
          value: o.cloud_agnostic_class,
          //data: o,
          icon: getIcon(o.cloud_agnostic_class || ''),
        }))?.filter(identity),
        o => o?.label,
      ),
    ],
    [resourceTypes?.data],
  );

  const resourceTypeOptionsWithAll = useCallback(
    () => getResourceTypeOptionsWithAll(resourceTypes?.data),
    [resourceTypes?.data],
  );

  const getAlertResourceTypeOptions = useCallback(
    (types: string[]) => {
      return sortBy(
        map(types, o => ({
          label: getResourceAlias(o),
          value: o,
          icon: getIcon(o),
        })),
        s => s?.label,
      );
    },
    [getResourceAlias],
  );

  const getAlertResourceTypeOptionsWithAll = useCallback(
    (types: string[]) => {
      return [
        { label: 'All resource types', value: undefined },
        ...getAlertResourceTypeOptions(types),
      ];
    },
    [getAlertResourceTypeOptions],
  );

  return {
    getCloudAgnosticName,
    getCloudNativeName,
    getResourceType,
    getCloudAgnosticClass,
    getCloudNativeClass,
    getResourceAlias,
    getResourceTypeOptions: resourceTypeOptions,
    getResourceTypeOptionsWithAlias: resourceTypeOptionsWithAlias,
    resourceTypeOptionsWithAll,
    getAlertResourceTypeOptions,
    getAlertResourceTypeOptionsWithAll,
    resourceTypeAliasOptionsWithAll,
    agnosticResourceTypeOptionsWithAll,
  };
};

export const ResourceTypeIconTooltip = ({ resourceType }) => {
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  return (
    <Stack spacing={0}>
      <Center color="orange">
        {getCloudNativeName(resourceType) ?? resourceType}
      </Center>
      <Center>{getCloudAgnosticName(resourceType) ?? resourceType}</Center>
    </Stack>
  );
};

export const StackedResourceType = ({ resourceType }) => {
  const { getCloudAgnosticName, getResourceAlias } = useResourceType();
  return (
    <WithResourceIcon
      resourceType={resourceType}
      iconTooltip={<ResourceTypeIconTooltip resourceType={resourceType} />}
      tooltip={<ResourceTypeIconTooltip resourceType={resourceType} />}
      iconSize="sm"
    >
      <StackedCell
        upper={getResourceAlias(resourceType) ?? resourceType}
        lower={getCloudAgnosticName(resourceType) ?? resourceType}
        showLowerTooltip={false}
        showUpperTooltip={false}
      />
    </WithResourceIcon>
  );
};
