import React, { FC, useEffect, useState } from 'react';

import { HStack, Stack, Square } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';

import { Heading4, intoOptions } from 'components/DataDisplay';
import { Form } from 'components/DataEntry';
import { TreeIcon } from 'components/Icons';
import { IModalProps, Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';

import { randomString } from '../../../../utils/string';

interface IResourceFilterModal extends IModalProps {
  resources?: any[];

  onSubmit?(data: any): void;
}

export const ResourceFilterModal: FC<IResourceFilterModal> = props => {
  const { isOpen, onClose, onSubmit, resources = [] } = props;
  const [selectedResourceType, setSelectedResourceType] = useState<
    Record<string, any>
  >({});
  const [selectedResourceId, setSelectedResourceId] = useState<string>('');
  const [resourceTypes, setResourceTypes] = useState<string[]>([]);
  const [resourceTypeIds, setResourceTypeIds] = useState<
    Record<string, string[]>
  >({});
  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);
  const { getCloudNativeName } = useResourceType();

  useEffect(() => {
    const types = new Set<string>();
    const resourceTypeIds = {};
    resources
      // .filter(r => r.is_target)
      .forEach(r => {
        types.add(r.native_name);
        resourceTypeIds[r.native_name] = resourceTypeIds[r.native_name] ?? [];
        resourceTypeIds[r.native_name].push(r.resource_id);
      });

    setResourceTypes(Array.from(types));
    setResourceTypeIds(resourceTypeIds);
  }, [resources]);

  useEffect(() => {
    const options = map(resourceTypes, o => ({
      label: getCloudNativeName(o),
      value: o,
    }));
    setResourceTypeOptions(options);
    setSelectedResourceType(options?.[0]);
  }, [resourceTypes, getCloudNativeName]);

  const handleSubmit = () => {
    const key = randomString();

    if (!selectedResourceType?.value) {
      onClose();
      return;
    }
    if (isEmpty(selectedResourceId)) {
      onSubmit?.({
        key,
        resourceType: selectedResourceType?.value,
      });
    } else {
      onSubmit?.({
        key,
        resourceType: selectedResourceType?.value,
        resourceId: selectedResourceId,
      });
    }

    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <TreeIcon />
          </Square>
          <Heading4>Add Resource Constraint</Heading4>
        </HStack>
      }
      body={
        <Stack>
          <Form
            schema={{
              name: {
                type: 'react-select',
                label: 'Choose resource type',
                options: resourceTypeOptions,
                value: selectedResourceType,
                onChange: setSelectedResourceType,
                isRequired: true,
              },
              resource_id: {
                type: 'react-select',
                label: 'Choose resource ID',
                options: intoOptions(
                  resourceTypeIds[selectedResourceType?.value],
                ),
                value: {
                  label: selectedResourceId,
                  value: selectedResourceId,
                },
                onChange: option => setSelectedResourceId(option.value),
              },
            }}
            buttonOptions={{
              submit: {
                name: '+ Constraint',
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: handleReset,
              },
            }}
            handleSubmit={handleSubmit}
          />
        </Stack>
      }
    />
  );
};
