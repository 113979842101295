import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.activeResourceState || initialState;

export const selectActiveResource = createSelector(
  [selectDomain],
  activeResourceState => activeResourceState,
);
