import React from 'react';

import { ResourceMapping } from '@ariksa/inventory-core';
import { filter, map, sortBy } from 'lodash';

import { getIcon } from 'components/Icons/Components';

export const getResourceTypeOptions = (resourceTypes: ResourceMapping[]) => {
  const types = filter(resourceTypes, o => !!o.cloud_native_name);
  return map(types, o => ({
    label: o.cloud_native_name,
    value: o.cloud_native_class,
    data: o,
    icon: getIcon(o.cloud_native_class || ''),
  }));
};

export const getResourceTypeOptionsWithAlias = (
  resourceTypes: ResourceMapping[],
) => {
  const types = filter(resourceTypes, o => !!o.cloud_native_name);
  return sortBy(
    map(types, o => ({
      label: o?.resource_alias ?? o.cloud_native_name,
      value: o.cloud_native_class,
      data: o,
      icon: getIcon(o.cloud_native_class || ''),
    })),
    s => s?.label,
  );
};

export const getResourceTypeOptionsWithAll = (
  resourceTypes: ResourceMapping[],
) => {
  return [
    { label: 'All Resource Types', value: undefined },
    ...getResourceTypeOptionsWithAlias(resourceTypes),
  ];
};
