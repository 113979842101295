import { RefObject, useCallback, useEffect, useState } from 'react';

import { entries } from 'lodash';

import {
  pathToEdges,
  uniqEdges,
} from 'components/Visualization/Graphs/utils/path';
import { useGraphScaling } from 'components/Visualization/PixiGraph/hooks/useGraphScaling';
import { AccessMap } from 'components/Visualization/PixiGraph/maps/AccessMap/elements/AccessMap';
import { AccessMapLayout } from 'components/Visualization/PixiGraph/maps/AccessMap/elements/Layout';
import {
  AccessMapHeaderNode,
  AccessMapPaginationNode,
  AccessMapResourceNode,
} from 'components/Visualization/PixiGraph/maps/AccessMap/elements/Node';
import {
  NodePagination,
  useAccessMapContext,
} from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';

import { usePixiApp } from '../../../hooks/usePixiApp';
import { useRenderer } from '../../../hooks/useRenderer';
import { AccessMapEdge } from '../elements/Edge';

interface UsePostureMapProps {
  ref: RefObject<any>;
  findings?: boolean;
}

const intoHeaderNodeData = (n: any) => {
  return {
    id: n.name,
    header: n.name,
    col: n.horizontal_order ?? 1,
    row: n.vertical_order ?? 1,
    ...n,
  };
};

export const useAccessMap = (props: UsePostureMapProps) => {
  const { ref, findings } = props;
  const {
    nodes = [],
    paths,
    ordering = [],
    pagination = {},
  } = useAccessMapContext();

  const paginationNodes = useCallback(
    (pagination: Record<string, NodePagination>) => {
      return entries(pagination).map(([header, page]) => {
        return new AccessMapPaginationNode({
          id: header,
          header: header,
          visibleCount: page.visibleCount,
          totalCount: page.totalCount,
        });
      }, [] as AccessMapPaginationNode[]);
    },
    [],
  );

  const [graph] = useState(() => {
    const targetNodes = nodes.filter(n => n.is_target);
    const innerNodes = nodes.filter(n => !n.is_target);

    const layout = new AccessMapLayout();
    return new AccessMap({
      headers: ordering.map(
        n => new AccessMapHeaderNode(intoHeaderNodeData(n)),
      ),
      pagination: paginationNodes(pagination),
      nodes: [...innerNodes, ...targetNodes].map(
        n => new AccessMapResourceNode(n),
      ),
      edges: uniqEdges(pathToEdges(paths).map(e => new AccessMapEdge(e))),
      paths,
      layout,
      styles: {
        padding: [20, 10],
        scale: 1,
      },
      findings,
    });
  });

  const app = usePixiApp({ ref });
  useGraphScaling({ app, graph, ref });
  const renderer = useRenderer({ app, graph });

  useEffect(() => {
    const targetNodes = nodes.filter(n => n.is_target);
    const innerNodes = nodes.filter(n => !n.is_target);

    graph.updateData({
      headers: ordering.map(
        n => new AccessMapHeaderNode(intoHeaderNodeData(n)),
      ),
      pagination: paginationNodes(pagination),
      nodes: [...innerNodes, ...targetNodes].map(
        n => new AccessMapResourceNode(n),
      ),
      edges: uniqEdges(pathToEdges(paths).map(e => new AccessMapEdge(e))),
      paths,
      findings,
    });

    renderer.update();
  }, [
    app,
    nodes,
    graph,
    renderer,
    ordering,
    paths,
    paginationNodes,
    pagination,
    findings,
  ]);

  // apply graph padding
  useEffect(() => {
    const { left, top } = graph.offsets;
    app.stage.x = left + graph.styles.padding[0];
    app.stage.y = top + graph.styles.padding[1];
    renderer.update();
  }, [app, renderer, graph]);

  // @ts-ignore
  window.graph = graph;

  return {
    app,
    graph,
    renderer,
  };
};
