import { UUID } from 'api/types';

import { CloudAccountId } from '../inventory/types';
import { COLLECTOR_SERVICE } from '../service_urls';
import { RediscoverAWSAccountParams } from '../types';
import { withPrefixArgs } from '../utils';

import { SnapshotParams, SnapshotTasksParams } from './types';

const PREFIX = COLLECTOR_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
//const withNoArgs = withPrefixNoArgs(PREFIX);

export const collectorApis = {
  //snapshot
  getSnapshotStats: withArgs<CloudAccountId>(`/snapshot/latest`),
  getSnapshot: withArgs<SnapshotParams>(`/snapshot/`),
  getSnapshotTasks: withArgs<SnapshotTasksParams>(`/snapshot/tasks`),

  // rediscovery
  rediscoverAWSAccount: withArgs<RediscoverAWSAccountParams>('/aws/{uuid}'),
  rediscoverAWSMemberAccount: withArgs<UUID>('/aws/member/{uuid}'),
};
