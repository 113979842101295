/**
 *
 * AddRepository -> GitLab, Github
 *
 */

import React from 'react';

import { AccountType } from '@ariksa/cloud-account';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { AddRepositoryForm } from 'containers/Setup/Integrations/DataSources/Components/AddRepository/AddRepositoryForm';

export const AddRepository = () => {
  const {
    onCancel,
    getAccounts,
    selectedProvider,
    actionType,
    currentRecord,
  } = useIntegrationsContext();

  return (
    <>
      <AddRepositoryForm
        onCancel={onCancel}
        getAccounts={() => getAccounts(AccountType.Saas)}
        selectedProvider={selectedProvider}
        actionType={actionType}
        accountDetails={currentRecord}
      />
    </>
  );
};
