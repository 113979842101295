import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NatGatewayIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M512,469.3v-128H341.3v53.3h-42.7v-91.4h70.8c57.8,0,99.8-37.8,99.8-90c0-37.9-24.2-71.7-59-84.5
	c3.2-10.5,4.3-21.5,3.6-33.6C410.7,43.1,364.3,0.4,310.5,0c-42.8,0-81.3,25.2-97.3,62.7c-15.7-11.7-35.6-17.3-55.8-15.4
	c-29.1,2.7-54.6,22.1-66.4,50.6c-5.6,13.5-4.9,28.6-3.6,37.7c-27.4,16-44.6,45.7-44.6,77.7c0,51.3,42.9,90,99.8,90h70.8v91.4h-42.7
	v-53.3H0v128h74.7v21.3H32V512h106.7v-21.3H96v-21.3h74.7V416h64V303.3h42.7V416h64v53.3H416v21.3h-42.7V512H480v-21.3h-42.7v-21.3
	L512,469.3L512,469.3z M149.3,448h-128v-85.3h128V448z M142.5,282C97,282,64,253.1,64,213.3c0-26.6,15.6-51.1,39.7-62.3l8.1-3.8
	l-2.3-8.6c-0.1-0.2-4.8-18.5,1-32.5c8.8-21.2,27.5-35.6,48.7-37.5c1.9-0.2,3.8-0.3,5.7-0.3c17.4,0,33.9,7.4,44.9,20.5l13.8,16.3
	l4.7-20.9c8.3-36.5,42.3-62.9,81.2-62.9c0.2,0,0.4,0,0.6,0c42.8,0.3,79.7,34.1,82.2,75.2c0.8,13.2-1,23.9-6,34.9l-5.4,12l12.9,2.8
	c31.3,6.8,54,35,54,67.1c0,40.4-32.3,68.6-78.5,68.6H142.5z M362.7,448v-85.3h128V448L362.7,448L362.7,448z"
    />
  </Icon>
);
