import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BellIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      d="M13.479 5.74871C15.2378 6.36051 16.5 8.03285 16.5 10L16.5 10.0157V13.6999L18.5727 15.6345C18.9045 15.9441 18.6853 16.5 18.2315 16.5H5.76856C5.31474 16.5 5.09563 15.9441 5.42739 15.6345L7.49997 13.7001V10C7.49997 8.03285 8.76223 6.36051 10.521 5.74871C10.6405 5.03992 11.2572 4.5 12 4.5C12.7428 4.5 13.3595 5.03992 13.479 5.74871Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.5853 18.5C10.7912 19.0826 11.3469 19.5 12 19.5C12.6531 19.5 13.2087 19.0826 13.4146 18.5H10.5853Z"
      stroke="currentColor"
    />
  </Icon>
);
