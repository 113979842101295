//import { checkboxFieldStyles } from '../DataEntry/Form/styles';

export const tabStyle = {
  _active: {
    bg: 'selectedMenuBg',
    borderRight: '4px solid',
    borderColor: 'primary',
    color: 'primary',
  },
};

export const contentStyles = {
  root: {
    //pt: 24,
    //mt: 6,
    px: 16,
    spacing: 0,
    h: 'full',
    w: '1400px',
  },
  header: {
    fontSize: 'lg',
    alignItems: 'center',
    fontWeight: 600,
  },
  logoWrapper: {
    h: 32,
    //my: 6,
  },
  logo: {
    h: 20,
    //my: 6,
  },
  list: { pt: 6, pl: 1, pr: 0 },
  content: { fontSize: 'lg', w: 'full', pl: 6, spacing: 20 },
  buttonGroup: { justifyContent: 'flex-end', w: 'full', pt: 4 },
  button: { size: 'sm', bg: 'primary', color: 'white', px: 6, py: 5 },
  //checkbox: { ...checkboxFieldStyles.checkbox, size: 'md' },
};
