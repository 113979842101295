import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

export const accountInformation = (label, value) => (
  <Stack>
    <Box fontSize="xs">{label}</Box>
    <Box
      px={3}
      py={1}
      border="1px solid"
      borderColor={customTheme.colors.gray['200']}
      borderRadius={6}
      h={8}
    >
      {value}
    </Box>
  </Stack>
);
