import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AlertWorkflowIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      d="M12,15.625c0.3451538,0,0.625-0.2798462,0.625-0.625S12.3451538,14.375,12,14.375S11.375,
      14.6548462,11.375,15S11.6548462,15.625,12,15.625z M20.5,16h-4c-0.276123,0-0.5,0.223877-0.5,
      0.5s0.223877,0.5,0.5,0.5h2.9760132c-1.6599121,2.4746704-4.44104,3.9971313-7.4585571,
      4C7.0420532,21.0048218,3.0048218,16.9754028,3,12c-0.0004272-0.4752197,0.0374756-0.9497681,
      0.1133423-1.4188843c0.0447388-0.2723999-0.1397705-0.52948-0.4121094-0.5742188c-0.2723999-0.0447998-0.52948,
      0.1397095-0.5742188,0.4121094C2.0422363,10.9417114,1.9997559,11.470459,2,12c0.0064697,5.5201416,
      4.4798584,9.9935303,10,10c3.1759033,0.0013428,6.1253662-1.5065308,8-3.9985962v2.4991455C20.0001831,
      20.7765503,20.223999,21.0001831,20.5,21h0.0006104C20.7765503,20.9998169,21.0001831,20.776001,
      21,20.5v-4.0006104C20.9998169,16.2234497,20.776001,15.9998169,20.5,16z M12,13h0.0006104c0.2759399-0.0001831,
      0.4995728-0.223999,0.4993896-0.5v-3C12.5,9.223877,12.276123,9,12,9s-0.5,0.223877-0.5,
      0.5v3.0005493C11.5001831,12.7765503,11.723999,13.0001831,12,13z M16.7373047,3.1964722C12.3201904,
      0.817749,6.9207764,2.1072388,4,6.0029297V3.5C4,3.223877,3.776123,3,3.5,3S3,3.223877,3,3.5v4.0006104C3.0001831,
      7.7765503,3.223999,8.0001831,3.5,8h4C7.776123,8,8,7.776123,8,7.5S7.776123,7,7.5,
      7H4.5239868c1.6599121-2.4746704,4.44104-3.9971313,7.4585571-4C16.9579468,2.9951782,20.9951782,
      7.0245972,21,12c0.0004272,0.4752808-0.0374146,0.9497681-0.1132202,1.4189453c-0.0001831,
      0.0007935-0.0003052,0.0015259-0.0004272,0.0023193c-0.0440063,0.2718506,0.140686,0.5278931,
      0.4125366,0.5718994C21.3259277,13.9978638,21.3533936,14.0001221,21.3808594,14c0.2445068-0.0003662,
      0.4527588-0.1776733,0.4921265-0.4190063C21.9577637,13.0582886,22.0002441,12.529541,22,12C21.9970703,
      8.3212891,19.9761963,4.9407349,16.7373047,3.1964722z"
    />
  </Icon>
);
