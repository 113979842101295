import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';

import { UserService, UserServiceGetRequest } from 'api/auth/api.pb';
import { callApiFn } from 'utils/saga';

import { actions } from './slice';

export function* userProfileSaga() {
  yield takeLatest(actions.getUserById.type, doGetUserById);
}

export function* doGetUserById(action: PayloadAction<UserServiceGetRequest>) {
  yield call(callApiFn, {
    fn: UserService.Get,
    data: action.payload,
    onSuccess: actions.getUserByIdSuccess,
    onError: actions.getUserByIdError,
    errorTitle: 'Failed to get user.',
  });
}
