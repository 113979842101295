import React, { FC, useCallback } from 'react';

import {
  Accordion,
  Box,
  Center,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BackIconButton, IconTypes, PageHeaderWithIcon } from 'app/components';
import { Card } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { RemediationModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationModal';
import { TimelineModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Timeline/TimelineModal';
import { AlertDetails } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/AlertDetails';
import { Investigate } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/Investigate';
import { Respond } from 'containers/Visibility/SecurityGraphNext/Components/Evidence/Respond';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

interface Props {}

export const EvidenceGraphDetails: FC<Props> = props => {
  const { evidence, showDependencies } = useSelector(selectSecurityGraphNext);
  const { isRedirected, redirectInfo } = useRedirectInfo();
  const remediationModalState = useDisclosure();
  const timelineModalState = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { alert } = redirectInfo ?? {};

  const setShowDependencies = useCallback(() => {
    dispatch(actions.updateShowDependencies(!showDependencies));
  }, [dispatch, showDependencies]);

  return (
    <>
      <Card styles={{ card: { p: 2 } }}>
        <Stack w="full" spacing={3}>
          <Stack>
            <PageHeaderWithIcon
              label="Summary"
              iconType={
                evidence.alertDetails.data?.resource || IconTypes.Service
              }
              reversed
              fontSize="sm"
              fontColor="primary"
              spacing={2}
            />
            <Box borderBottom="1px solid" borderColor="gray.200" />
          </Stack>
          {evidence.alertDetails.isLoading && (
            <Center>
              <CustomSpinner />
            </Center>
          )}

          {!evidence.alertDetails.isLoading && (
            <Accordion allowMultiple defaultIndex={[0, 1, 2, 4]}>
              <Stack spacing={3}>
                <AlertDetails />
                <Investigate
                  setShowDependencies={setShowDependencies}
                  timeline={timelineModalState}
                />
                <Respond remediate={remediationModalState} />
              </Stack>
            </Accordion>
          )}

          {/*resource specific evidence info*/}

          {alert && (
            <RemediationModal state={remediationModalState} alert={alert} />
          )}
          {alert && <TimelineModal state={timelineModalState} alert={alert} />}
        </Stack>
      </Card>
    </>
  );
};
