import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountLocator,
  SnowflakeID,
  Username,
  Comment,
  Owner,
  IdentityProvider,
  Email,
  DisplayName,
  ExternalID,
  Active,
  Roles,
  Privileges,
  Groups,
  CreatedAt,
  LastModified,
  LoginName,
} from '../../../Components/MetadataField/MetaGridFields';

export const SnowflakeUserMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Username(resource)}
      {AccountLocator(resource)}
      {SnowflakeID(resource)}
      {Comment(resource)}
      {Owner(resource)}
      {IdentityProvider(insight)}
      {LoginName(resource)}
      {Email(resource)}
      {DisplayName(resource)}
      {ExternalID(resource)}
      {Active(resource)}
      {/*{Roles(resource)}*/}
      {Privileges(resource)}
      {Groups(resource)}
      {CreatedAt(resource, '')}
      {LastModified(resource)}
    </Grid>
  );
};
