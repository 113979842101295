import React, { useEffect, useState, FC } from 'react';

import { useSelector } from 'react-redux';

import { Card } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { DataTableComponent } from 'containers/Visibility/Data/Components/DataTable/DataTableComponent';
import { DataTypeSearchResult } from 'containers/Visibility/Data/Components/DataTable/DataTypeSearchResult';
import { SourcesByDataType } from 'containers/Visibility/Data/Components/DataTable/SourcesByDataType';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

interface Props {
  showDataTypeSources?: boolean;
  setShowDataTypeSources?(val: boolean);
}

export const DataTable: FC<Props> = props => {
  const { setShowDataTypeSources, showDataTypeSources } = props;
  const { selectedTab } = useSelector(selectDataDashboard);
  const { searchTerm } = useSelector(selectSharedState);
  const [currentDataType, setCurrentDataType] = useState<Record<string, any>>(
    {},
  );
  const { handleRowClick } = useDataTableColumns(MetadataTabs.Data);
  const params = useSearchParams<{
    account_id: string;
    uuid: string;
    resource_type: string;
    resource_id: string;
  }>();

  useEffect(() => {
    if (
      !!params.uuid &&
      !!params.resource_type &&
      !!params.account_id &&
      !!params.resource_id &&
      selectedTab === IconTypes.DataSources
    )
      handleRowClick({
        service: params.resource_type,
        source_UUID: params.uuid,
        account_id: params.account_id,
        resource_id: params.resource_id,
      });
  }, [
    params.uuid,
    params.resource_type,
    handleRowClick,
    selectedTab,
    params.account_id,
    params.resource_id,
  ]);

  return showDataTypeSources && searchTerm ? (
    <DataTypeSearchResult
      currentRecord={currentDataType}
      setShowDataTypeSources={setShowDataTypeSources}
    />
  ) : (
    <Card
      styles={{
        cardContent: { w: 'full' },
      }}
    >
      {showDataTypeSources ? (
        <SourcesByDataType
          setShowDataTypeSources={setShowDataTypeSources}
          currentRecord={currentDataType}
        />
      ) : (
        <DataTableComponent
          setShowDataTypeSources={setShowDataTypeSources}
          setCurrentDataType={setCurrentDataType}
        />
      )}
    </Card>
  );
};
