import { PageEventLogResponse } from '@ariksa/event-log';
import { EventLogApiListRequest } from '@ariksa/event-log/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { EventLogService } from 'api/services';

import { actions } from './slice';

export function* eventLogSaga() {
  yield takeLatestAction(actions.getEventLogs, doGetEventLogs);
}

export function* doGetEventLogs(
  ctx: QueryContext<PageEventLogResponse, EventLogApiListRequest>,
) {
  yield call(ctx.fetch, () => EventLogService.list(ctx.params), {});
}
