/**
 *
 * Snapshot Card
 *
 */

import React, { memo, useState } from 'react';

import { Box, Flex, Tr } from '@chakra-ui/react';
import map from 'lodash/map';

import { ActionButton, DownIcon, RightIcon } from 'app/components';
import { cardTableStyles } from 'app/components/DataDisplay/Table/CustomTable';
import { renderTime } from 'components/DataDisplay';
import { cellValue } from 'components/DataDisplay/Table/CustomTable/utils';

import { snapshotColumns } from '../utils';

import { SnapshotTasksTable } from './SnapshotTasksTable';

interface Props {
  row: any;
}

export const SnapshotCard = memo((props: Props) => {
  const { row } = props;

  return <SnapshotTasksTable snapshot_id={row.snapshot_id} />;
});
