import { inlineObjectFieldStyles } from 'components/DataEntry';

export const formStyles = {
  /*form: {
    formWidth: '600px',
    container: { pt: 2 },
  },*/
  objectField: {
    ...inlineObjectFieldStyles,
    propertyContainer: { w: '50%' },
  },
};
