import React, { BaseSyntheticEvent } from 'react';
import { createContext, useContext } from 'react';

import { useComplianceHook } from 'containers/Compliance/Components/hooks/useComplianceHook';

export interface ComplianceContextProps {
  toggleShowRules: (row, index) => void;
  toggleShowResource: (row, index) => void;
  renderResourceSubComponentActions: ({ row }) => any;
  renderResourceActions: ({ row, value }) => any;
  renderRulesActions: ({ row }) => any;
  isOpenSuppress: boolean;
  isOpenHistory: boolean;
  onCloseHistory: () => void;
  isOpenSeverityModal: boolean;
  onCloseSeverityModal: () => void;
  onOpenSeverityModal: () => void;
  onCloseSuppress: () => void;
  onOpenSuppress: () => void;
  rulesRowIndex: number;
  resourceTypeRowIndex: number;
  selectedResource: Record<string, any>;
  setSelectedResource: (value: Record<string, any>) => void;
  onClickSeverity: (e: BaseSyntheticEvent, row: Record<string, any>) => void;
  onClickSuppress: (e: BaseSyntheticEvent, row: Record<string, any>) => void;
}

export const ComplianceContext = createContext<ComplianceContextProps>({
  toggleShowRules: () => {},
  toggleShowResource: () => {},
  renderResourceSubComponentActions: () => {},
  renderResourceActions: () => {},
  renderRulesActions: () => {},
  isOpenSuppress: false,
  isOpenHistory: false,
  onOpenSuppress: () => {},
  onCloseHistory: () => {},
  isOpenSeverityModal: false,
  onCloseSeverityModal: () => {},
  onOpenSeverityModal: () => {},
  onCloseSuppress: () => {},
  rulesRowIndex: -1,
  resourceTypeRowIndex: -1,
  selectedResource: {},
  setSelectedResource: () => {},
  onClickSeverity: () => {},
  onClickSuppress: () => {},
});

export const ComplianceContextProvider = props => {
  const { children } = props;
  const value = useComplianceHook();
  return (
    <ComplianceContext.Provider value={value}>
      {children}
    </ComplianceContext.Provider>
  );
};

export const useCompliance = () => useContext(ComplianceContext);
