import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NetworkMisconfigurationIcon = props => (
  <Icon viewBox="0 0 32 32" color="inherit" h="full" w="full" {...props}>
    <polygon points="19 16 19 14 26.171 14 23.878 11.707 25.292 10.293 30 15 25.292 19.707 23.878 18.293 26.171 16 19 16" />
    <polygon points="17 12 15 12 15 5.828 12.707 8.121 11.293 6.707 16 2 20.707 6.707 19.293 8.121 17 5.828 17 12" />
    <path d="M17,20.1011V18a4.0045,4.0045,0,0,0-4-4H5.8281l2.293-2.293L6.707,10.293,2,15l4.707,4.707,1.4141-1.414L5.8281,16H13a2.0025,2.0025,0,0,1,2,2v2.1011a5,5,0,1,0,2,0ZM16,28a3,3,0,1,1,3-3A3.0033,3.0033,0,0,1,16,28Z" />
    <rect
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      className="cls-1"
      fill="none"
      width="32"
      height="32"
    />
  </Icon>
);
