import React, { FC, useEffect, useState } from 'react';

import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { LineChart } from 'components/Visualization';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { formatDate } from 'utils/date';

import { customTheme } from '../../../../../../theme';

export const CriticalAlertsChart: FC = () => {
  const { alertHistoryByTrend } = useSelector(selectFindingsOverview);

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const graphData = map(
      alertHistoryByTrend.data?.alert_history,
      (v, date) => ({
        date: formatDate(date, 'D-MMM'),
        day: formatDate(date, 'DD-MMM'),
        Critical: v.CRITICAL,
        High: v.HIGH,
      }),
    );
    setData(graphData);
  }, [alertHistoryByTrend.data]);

  const graphData = {
    data: data,
    xField: 'day',
    yField: ['Critical', 'High'],
  };

  return (
    <LineChart
      {...graphData}
      isLoading={alertHistoryByTrend.isLoading}
      styles={{
        lineChart: { margin: { bottom: -30, left: -10 } },
      }}
      colorMap={{
        Critical: customTheme.colors.critical,
        High: customTheme.colors.high,
      }}
    />
  );
};
