/**
 *
 * Jira
 *
 */

import React, { memo, useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { map, find } from 'lodash';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { QuestionCircleIcon } from 'app/components';
import { Form, FormSchema } from 'app/components/DataEntry/Form';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { formStyles } from '../../../styles';

import { JIRAHelp } from './JIRAHelp';

interface Props {
  handleSubmit: (data: any) => void;
  validate?: boolean;
}

export const JiraForm = memo((props: Props) => {
  const { handleSubmit, validate } = props;

  const { clientInfo, client } = useSelector(selectIntegrations);
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      projects: [],
      users: [],
      selectedProject: {},
      selectedUser: {},
      isEdit: false,
      isHelpOpen: false,
      name: '',
      error: '',
    },
  );

  const toggleIsHelpOpen = () => updateState({ isHelpOpen: !state.isHelpOpen });

  //set isEdit true if opened in edit
  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //set list of projects and users
  useEffect(() => {
    const { data: clientInfoData } = clientInfo;
    updateState({
      projects: map(clientInfoData?.jira?.projects, o => ({
        label: o.name,
        value: o.id,
      })),
      users: map(clientInfoData?.jira?.users, o => ({
        value: o.accountId,
        label: o.displayName,
      })),
    });
  }, [clientInfo]);

  //in case of update, set the selected user and project
  useEffect(() => {
    updateState({
      selectedUser:
        find(state.users, o => o.value === currentRecord?.jira?.account_id) ||
        state.users[0],
      selectedProject:
        find(
          state.projects,
          o => o.value === currentRecord?.jira?.project_id,
        ) || state.projects[0],
    });
  }, [client, state.users, state.projects, currentRecord]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
    url: yup.string().url('Url is not valid!'),
    user: yup.string().email('Email is not valid!'),
  });

  const restFields: FormSchema = validate
    ? {
        project_id: {
          type: 'react-select',
          label: 'Project',
          isRequired: true,
          defaultValue: state.selectedProject,
          options: state.projects,
          isLoading: clientInfo.isLoading,
          isDisabled: clientInfo.isLoading || client.isLoading,
        },
        account_id: {
          type: 'react-select',
          isRequired: true,
          label: 'Default Assignee',
          defaultValue: state.selectedUser,
          options: state.users,
          isLoading: clientInfo.isLoading,
          isDisabled: clientInfo.isLoading || client.isLoading,
        },
      }
    : {};

  const token: FormSchema = state.isEdit
    ? {}
    : {
        token: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'Api Token',
          isRequired: true,
          isDisabled: validate,
          helpIcon: (
            <Box
              onClick={toggleIsHelpOpen}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          isHelpOpen: state.isHelpOpen,
          helpComponent: (
            <JIRAHelp onClose={() => updateState({ isHelpOpen: false })} />
          ),
        },
      };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          defaultValue: currentRecord?.name,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
          isDisabled: clientInfo.isLoading || client.isLoading,
        },
        url: {
          type: 'text',
          label: 'URL',
          placeholder: 'https://example.atlassian.net/',
          isRequired: true,
          defaultValue: currentRecord?.jira?.url,
          isDisabled: validate,
          tooltip: 'Enter valid url',
        },
        user: {
          type: 'text',
          label: 'User ID',
          placeholder: 'example@mail.com',
          isRequired: true,
          defaultValue: currentRecord?.jira?.user,
          isDisabled: validate,
          tooltip: 'Enter valid email',
        },
        ...token,
        ...restFields,
      }}
      buttonOptions={{
        submit: {
          isVisible: true,
          isLoading: clientInfo.isLoading || client.isLoading,
          name: state.isEdit
            ? 'Update'
            : validate
            ? 'Okay'
            : 'Get Projects & Users',
          isDisabled:
            validate && (!state.projects.length || !state.users.length),
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: clientInfo.isLoading || client.isLoading,
          onClick: () => {
            onCancel();
          },
        },
      }}
      showHelp
      styles={formStyles}
      handleSubmit={handleSubmit}
    />
  );
});
