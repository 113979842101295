import React, { FC, useCallback } from 'react';

import { AccessRequestGetResponse } from '@ariksa/jit/api';
import { Box, Divider, HStack, Square, Stack, Text } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { DetailsIdentifier, H4 } from 'components/DataDisplay';
import { SlackIcon } from 'components/Icons';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';
import {
  renderJitPermissions,
  renderJitPermissionsTable,
  renderJitResourceTypes,
} from 'containers/Visibility/JitRequests/Components/utils';

interface Props {
  row: AccessRequestGetResponse;
  requestAccount(row);
}

export const JitRequestSubComponent: FC<Props> = props => {
  const { row, requestAccount } = props;
  const getRoleFiled = useCallback(row => {
    const details = row.access_request_details?.[row.cloud_type?.toLowerCase()];
    switch (row?.cloud_type) {
      case 'AWS':
        return { label: 'Role ARN', value: details.role ?? 'Custom' };
      case 'GCP':
        return { label: 'Role ID', value: details.role_id ?? 'Custom' };
      default:
        return {};
    }
  }, []);

  const roleField = getRoleFiled(row);

  return (
    <SubComponentContainer>
      <Stack px={10} w="full" spacing={3}>
        <H4>ADDITIONAL DETAILS</H4>
        <Divider
          left={-4}
          pos={'relative'}
          borderColor={customTheme.colors.gray['200']}
        />
        <HStack align="flex-start">
          <Stack spacing={3} pt={2} w="full">
            <DetailsIdentifier
              label="Request received from"
              value={
                <HStack>
                  <Square size={5}>
                    <SlackIcon />
                  </Square>
                  <Text>Slack channel (AriksaBot)</Text>
                </HStack>
              }
            />
            <DetailsIdentifier
              label={'Approvers'}
              value={
                <HStack spacing={2}>
                  <HStack spacing={1}>
                    {row.approvers?.map((a, i) => {
                      return (
                        <Text>
                          {a ?? '-'}
                          {i === row.approvers?.length - 1 ? '' : ','}
                        </Text>
                      );
                    })}
                  </HStack>
                </HStack>
              }
            />
            <DetailsIdentifier
              label="Reviewed by"
              value={
                <HStack spacing={2}>
                  <Box>{row.approved_by?.join(', ')}</Box>
                </HStack>
              }
            />
            <DetailsIdentifier label={'Approved from'} value={''} />

            {/*role arn*/}
            <DetailsIdentifier
              label={roleField.label ?? '-'}
              value={roleField.value ?? '-'}
            />
            <DetailsIdentifier
              label={'Resource Types'}
              value={renderJitResourceTypes(requestAccount(row)?.aws_resources)}
            />
          </Stack>
          <Box w="full">
            <DetailsIdentifier
              label="Permissions"
              direction="column"
              value={
                <Stack>
                  {!!requestAccount(row)?.role &&
                    renderJitPermissions(requestAccount(row)?.crud_counts)}
                  {renderJitPermissionsTable({
                    resources: requestAccount(row)?.aws_resources,
                  })}
                </Stack>
              }
            />
          </Box>
        </HStack>
      </Stack>
    </SubComponentContainer>
  );
};
