import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ShareIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" w="full" h="full" {...props}>
    <path
      fill="currentColor"
      d="M21.3536987,11.6466675c-0.000061-0.000061-0.0001221-0.0001221-0.0001831-0.0001831l-8.5-8.5c-0.1436157-0.1418457-0.3579712-0.1844482-0.5449219-0.1083984C12.1217041,3.1153564,11.9998779,3.2977295,12,3.5v4.0430298C6.3303223,8.0508423,1.9890137,12.8076172,2,18.5v1.9580078c-0.0003052,0.2758789,0.2231445,0.4997559,0.4990234,0.500061c0.1416626,0.0001831,0.2767334-0.0598145,0.371582-0.1651001C5.2153931,18.1976318,8.505249,16.6541138,12,16.5097656V20.5c-0.0001221,0.2022705,0.1217041,0.3846436,0.3085938,0.4619141C12.3693237,20.9869385,12.4343262,20.9998779,12.5,21c0.1326294,0,0.2597656-0.0526733,0.3535156-0.1464844l8.5-8.5C21.5487671,12.1583862,21.5488281,11.8419189,21.3536987,11.6466675z M13,19.2929688V16c0.0001831-0.276001-0.2234497-0.4998169-0.4993896-0.5c-0.0002441,0-0.0004272,0-0.0006104,0c-3.5245972,0.0074463-6.9168091,1.3433228-9.5,3.7412109V18.5c-0.0107422-5.3422852,4.1867676-9.7459717,9.5234375-9.9912109C12.7901611,8.496521,13.000061,8.2767334,13,8.0097656V4.7069702L20.2930298,12L13,19.2929688z"
    />
  </Icon>
);
