import {
  AggregatedAlertsPaginatedResponse,
  AlertHistoryResponse,
  AlertPaginatedResponse,
  AlertsApiGetAggregatedAlertsRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertsRequest,
  AlertsApiGetAlertTimelineRequest,
  AlertsApiGetRuleIdSummaryRequest,
  AlertTimelineResponse,
  RuleIdSummaryResponse,
} from '@ariksa/notification/api';

import { NotificationService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useAlertService() {
  const alerts = useAriksaQuery<
    AlertsApiGetAlertsRequest,
    AlertPaginatedResponse
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Alerts.getAlerts(req).then(d => d.data);
    },
  });

  const alertTimeline = useAriksaQuery<
    AlertsApiGetAlertTimelineRequest,
    AlertTimelineResponse[]
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Alerts.getAlertTimeline(req).then(d => d.data);
    },
  });

  const alertHistory = useAriksaQuery<
    AlertsApiGetAlertHistoryRequest,
    AlertHistoryResponse
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Alerts.getAlertHistory(req).then(d => d.data);
    },
  });

  const aggregatedAlerts = useAriksaQuery<
    AlertsApiGetAggregatedAlertsRequest,
    AggregatedAlertsPaginatedResponse
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Alerts.getAggregatedAlerts(req).then(
        d => d.data,
      );
    },
  });

  const alertsByRuleIdSummary = useAriksaQuery<
    AlertsApiGetRuleIdSummaryRequest,
    RuleIdSummaryResponse[]
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.Alerts.getRuleIdSummary(req).then(d => d.data);
    },
  });

  return {
    alertTimeline,
    alertHistory,
    aggregatedAlerts,
    alerts,
    alertsByRuleIdSummary,
  };
}
