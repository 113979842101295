import {
  CloudAccountApiChangeDiscoverInventoryIntervalRequest,
  CloudAccountApiGetCloudAccountsRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  GetSlasResponse,
  SlaApiUpdatePredefinedSlasRequest,
  SlaApiUpdateSlaRequest,
} from '@ariksa/notification/api';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  updateAccountDiscoveryInterval: QueryState.init({}),
  accounts: PagedQueryState.init([]),
  SLAs: QueryState.init([]),
  updateSLAs: QueryState.init({}),
  availableSLAs: QueryState.init([]),
  updatePredefinedSlas: QueryState.init({}),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updatePredefinedSlas(
      state,
      action: QueryAction<any, SlaApiUpdatePredefinedSlasRequest>,
    ) {
      state.updatePredefinedSlas = QueryState.next(
        state.updatePredefinedSlas,
        action,
      );
    },
    getAvailableSLAs(state, action: QueryAction<number[], any>) {
      state.availableSLAs = QueryState.next(state.availableSLAs, action);
    },
    getSLAs(state, action: QueryAction<GetSlasResponse[], any>) {
      state.SLAs = QueryState.next(state.SLAs, action);
    },
    updateSLAs(state, action: QueryAction<any, SlaApiUpdateSlaRequest>) {
      state.updateSLAs = QueryState.next(state.updateSLAs, action);
    },
    updateAccountDiscoveryInterval(
      state,
      action: QueryAction<
        any,
        CloudAccountApiChangeDiscoverInventoryIntervalRequest
      >,
    ) {
      state.updateAccountDiscoveryInterval = QueryState.next(
        state.updateAccountDiscoveryInterval,
        action,
      );
    },
    getCloudAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsRequest
      >,
    ) {
      state.accounts = PagedQueryState.next(state.accounts, action, {
        mapTotalCount: r => r.total || 0,
        mapData: r => r.items,
      });
    },
  },
});

export const { actions, reducer, name: sliceKey } = settingsSlice;
