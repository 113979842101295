/**
 *
 * Azure Active Directory
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';
import { HStack, Box, Switch } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Form, FormAction, FormSchema } from 'components/DataEntry/Form';
import { QuestionCircleIcon } from 'components/Icons';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { SnowflakeHelp } from 'containers/Setup/Integrations/DataSources/Components/Snowflake/SnowflakeHelp';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

interface Props {
  action?: FormAction;
  accountDetails?: any;
  accountDetailsIsLoading?: boolean;
  onClickCancel?();
}

export const Snowflake: FC<Props> = props => {
  const {
    action,
    accountDetails,
    accountDetailsIsLoading,
    onClickCancel,
  } = props;
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();
  const [usePrivateKey, setUsePrivateKey] = useState(false);
  const { account } = useSelector(selectIntegrations);
  const [isEdit] = useState(action === 'Update' || actionType === 'Update');
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const toggleIsHelpOpen = () => setIsHelpOpen(!isHelpOpen);
  const { updateAccount, onboardAccount } = useIntegrationsContext();

  useEffect(() => {
    if (
      !!accountDetails?.encrypted_private_key ||
      !!currentRecord?.encrypted_private_key
    )
      setUsePrivateKey(true);
  }, [currentRecord, accountDetails]);

  const handleReset = () => {
    onClickCancel?.();
    onCancel();
  };
  const handleSubmit = data => {
    const { encrypted_password, login, login_url, name } = data;

    if (isEdit) {
      updateAccount(
        {
          uuid: accountDetails?.uuid || currentRecord?.uuid,
          cloudAccountUpdate: {
            name,
            cloud_type: CloudProviders.Snowflake,
            snowflake: {
              encrypted_password,
            },
          },
        },
        AccountType.Saas,
      );
    } else {
      onboardAccount(
        {
          cloudAccountCreateRequest: {
            name,
            cloud_type: CloudProviders.Snowflake,
            snowflake: {
              encrypted_password,
              login,
              login_url,
            },
          },
        },
        AccountType.Saas,
      );
    }
  };

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          placeholder: 'Enter name to be used by Ariksa',
          isRequired: true,
          defaultValue: isEdit
            ? accountDetails?.name ?? currentRecord?.name
            : '',
        },
        login_url: {
          type: 'text',
          label: 'Login URL',
          //placeholder: 'https://account.us-east-2.aws.snowflakecomputing.com',
          isRequired: true,
          /*helpIcon: (
            <Box
              onClick={toggleIsHelpOpen}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          isHelpOpen,
          helpComponent: <SnowflakeHelp onClose={() => setIsHelpOpen(false)} />,*/
        },
        login: {
          type: 'text',
          label: 'Login name',
          placeholder: 'Enter login name',
          isRequired: true,
        },
        encrypted_password: {
          type: 'text',
          label: (isEdit ? 'New ' : '') + 'Password',
          htmlInputType: 'password',
          isRequired: true,
          placeholder: 'Enter password',
          //defaultValue: currentRecord?.encrypted_password,
        },
      }}
      showHelp
      buttonOptions={{
        submit: {
          name: 'Okay',
          isLoading: account.isLoading || accountDetailsIsLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: handleReset,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
