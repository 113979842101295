import React, { FC, useEffect, useReducer } from 'react';

import { filter, find, flatten, includes, isEmpty, map, union } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useReportingService } from 'services/Reporting/useReportingService';

import { getEnvironmentAccountIdsArray } from 'components/DataDisplay';
import { Form, FormAction } from 'components/DataEntry/Form';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { formStyles } from 'containers/Reports/AllReports/Components/styles';
import { useReportsDashboard } from 'containers/Reports/ReportsDashboard/hooks/useReportsDashboard';
import { selectReportsDashboard } from 'containers/Reports/ReportsDashboard/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

import { actions } from '../slice';

interface Props {
  currentRecord: Record<string, any>;
  setCurrentRecord: (value: Record<string, any>) => void;
  onClose: () => void;
  actionType: FormAction;
}

export const ReportingChartForm: FC<Props> = props => {
  const { currentRecord, onClose, actionType, setCurrentRecord } = props;
  const dispatch = useDispatch();
  const {
    getEnvironmentOptions,
    getGroupedAccountsUnderEnvironmentOptions,
  } = useEnvironmentOptions();
  const { toCloudAccountId } = useCloudAccountId();
  const { updateCharts } = useSelector(selectReportsDashboard);
  const { getReportingCharts, allCharts } = useReportsDashboard();
  const { reportingCharts } = useReportingService();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      environmentOptions: [],
      selectedEnvs: [],
      accountOptions: [],
      selectedAccounts: [],
      isEdit: false,
      name: '',
      chartType: allCharts[0],
      chartTypeOptions: [],
    },
  );

  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //get list of charts which are not enabled on dashboard
  useEffect(() => {
    if (actionType === 'Add') {
      reportingCharts.sync({ dashboardFlag: false });
    }
  }, [reportingCharts, actionType]);

  //set chart type options
  useEffect(() => {
    updateState({
      chartTypeOptions: [
        {
          label: 'Chart Types',
          options: allCharts,
        },
        {
          label: 'Existing Charts',
          options: map(reportingCharts.data, o => ({
            label: o?.name,
            value: o?.chart_type + '_custom',
            type: 'custom',
            data: o,
          })),
        },
      ],
    });
  }, [reportingCharts.data, allCharts]);

  /*set chart type*/
  useEffect(() => {
    if (state.isEdit)
      updateState({
        chartType:
          find(
            state.chartTypeOptions[0]?.options,
            o => o.value === currentRecord?.chart_type,
          ) ?? {},
      });
  }, [state.chartTypeOptions, currentRecord, state.isEdit]);

  /*set environment options*/
  useEffect(() => {
    updateState({ environmentOptions: getEnvironmentOptions() });
  }, [getEnvironmentOptions]);

  /*set selected environment options*/
  useEffect(() => {
    if (!!currentRecord?.environment_ids) {
      const envs = filter(state.environmentOptions, a =>
        includes(currentRecord?.environment_ids, a?.value),
      );
      updateState({ selectedEnvs: envs });
    }
  }, [currentRecord, state.environmentOptions]);

  /*set account options*/
  useEffect(() => {
    console.log(state.selectedEnvs);
    updateState({
      accountOptions: getGroupedAccountsUnderEnvironmentOptions(
        state.selectedEnvs,
      ),
    });
  }, [state.selectedEnvs, getGroupedAccountsUnderEnvironmentOptions]);

  /*set selected account options*/
  useEffect(() => {
    const accounts = map(currentRecord?.account_ids, (o, key) =>
      map(o, a => ({
        label: toCloudAccountId(a),
        value: a + '_' + key,
        data: {
          accountId: a,
          environmentId: key,
        },
      })),
    );
    updateState({ selectedAccounts: flatten(accounts) });
  }, [currentRecord, toCloudAccountId]);

  //set name
  useEffect(() => {
    currentRecord && updateState({ name: currentRecord?.name });
  }, [currentRecord]);

  const handleSubmit = data => {
    const { name, description, chartType } = data;
    const payload = {
      name,
      description,
      environment_ids: map(state.selectedEnvs, o => o.value),
      account_ids: getEnvironmentAccountIdsArray(state.selectedAccounts),
      enable_on_dashboard: true,
    };
    const onSuccess = () => {
      getReportingCharts();
      onClose();
      setCurrentRecord({});
    };
    if (state.isEdit || !isEmpty(currentRecord)) {
      dispatch(
        actions.updateChart({
          q: {
            editChartRequest: payload as any,
            chartId: currentRecord?.id,
          },
          onSuccess,
        }),
      );
    } else {
      dispatch(
        actions.addChart({
          q: {
            addChartRequest: {
              ...payload,
              chart_type: chartType?.value,
            } as any,
          },
          onSuccess,
        }),
      );
    }
  };

  return (
    <Form
      schema={{
        chartType: {
          type: 'react-select',
          label: state.isEdit ? 'Selected chart' : 'Choose chart',
          options: state.chartTypeOptions,
          isRequired: true,
          value: state.chartType,
          onChange: selected => {
            updateState({ chartType: selected });
            if (selected?.type === 'custom') setCurrentRecord(selected?.data);
          },
          isDisabled: state.isEdit || reportingCharts.isLoading,
          isLoading: reportingCharts.isLoading,
        },
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state.name,
          onChange: value => updateState({ name: value }),
        },
        description: {
          type: 'textArea',
          label: 'Description',
          value: currentRecord?.description,
        },
        filters: {
          type: 'object',
          label: 'SPECIFY FILTERS',
          properties: {
            environment: {
              type: 'react-select',
              label: 'Select environments',
              options: state.environmentOptions,
              value: state.selectedEnvs,
              isMulti: true,
              onChange: selected => updateState({ selectedEnvs: selected }),
              showTotalSelected: true,
              isRequired: true,
            },
            account: {
              type: 'react-select',
              label: 'Select accounts',
              options: state.accountOptions,
              value: state.selectedAccounts,
              onChange: selected => updateState({ selectedAccounts: selected }),
              isMulti: true,
              onClickGroupHeading: options =>
                updateState({
                  selectedAccounts: union([
                    ...state.selectedAccounts,
                    ...options,
                  ]),
                }),
              showTotalSelected: true,
            },
          },
        },
      }}
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: state.isEdit ? 'Save' : 'Add to dashboard',
          isDisabled:
            !state.chartType?.value ||
            !state.name ||
            isEmpty(state.selectedEnvs),
          isLoading: updateCharts.isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: onClose,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
