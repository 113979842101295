import React, { useCallback, useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import {
  OnSort,
  Sorted,
  SortOrder,
  TableColumnProps,
} from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from 'components/Icons';

export function useSortableHeader(onSort?: OnSort, sortBy?: Sorted) {
  const [sortField, setSortField] = useState(sortBy?.sortField ?? '');
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    sortBy?.sortOrder ?? '',
  );

  const handleSort = useCallback(
    accessor => {
      if (sortField === accessor) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setSortField(accessor);
        setSortOrder('asc');
      }
    },
    [sortField, sortOrder],
  );

  useEffect(() => {
    onSort?.({ sortField, sortOrder });
  }, [onSort, sortField, sortOrder]);

  const renderHeaderName = useCallback(
    column => (
      <Box
        textAlign={column?.align}
        style={{ ...column.styles, ...column.styles?.headerCell }}
      >
        <HStack
          onClick={() => handleSort(column.accessor ?? '')}
          cursor={'pointer'}
          spacing={1}
        >
          <Box>{column.header}</Box>
          {sortField === column.accessor && sortOrder === 'desc' && (
            <CustomTooltip label={'Sort Ascending'}>
              <ArrowUpIcon height={5} />
            </CustomTooltip>
          )}
          {sortField === column.accessor && sortOrder === 'asc' && (
            <CustomTooltip label={'Sort Descending'}>
              <ArrowDownIcon height={5} />
            </CustomTooltip>
          )}
          {sortField !== column.accessor && (
            <CustomTooltip label={'Sort Ascending'}>
              <MinusIcon size={10} pl={1} />
            </CustomTooltip>
          )}
        </HStack>
      </Box>
    ),
    [handleSort, sortField, sortOrder],
  );

  const sortableColumn = useCallback(
    (column: TableColumnProps) => {
      if (!column.sortKey) {
        return column;
      }

      return {
        renderHeader: () => renderHeaderName(column),
        ...column,
      };
    },
    [renderHeaderName],
  );

  return {
    sortableColumn,
  };
}
