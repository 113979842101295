import { Summary } from '@ariksa/compliance-policies';
import {
  BlueprintApiDeleteRulesRequest,
  CheckHistoryResponse,
  ComplianceApiGetComplianceSummaryByAccountRequest,
  ComplianceApiGetComplianceSummaryByComplianceRuleRequest,
  ComplianceApiGetComplianceSummaryByResourcesRequest,
  ComplianceApiGetComplianceSummaryByResourceTypeRequest,
  ComplianceApiGetComplianceSummaryByRuleRequest,
  ComplianceApiGetComplianceSummaryReportRequest,
  ComplianceApiGetExecutionHistorySummaryRequest,
  ComplianceApiGetIssueSummaryRequest,
  ComplianceApiGetSummaryRequest,
  ComplianceSummaryAccount,
  PageBluePrintsRead,
  PageResourceCompliance,
  PageResourceTypeCompliance,
  PageRuleCompliance,
  PageStandardRuleCompliance,
} from '@ariksa/compliance-policies/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ComplianceService } from 'api/services';
import { saveAsPDF } from 'containers/Visibility/Network/utils';

import 'utils/request';

import { actions } from './slice';

export function* complianceSaga() {
  yield takeLatestAction(actions.getBlueprints, doGetBlueprints);
  yield takeLatestAction(actions.loadRules, doGeRules);
  yield takeLatestAction(actions.loadRulesForException, doGeRulesForException);
  yield takeLatestAction(actions.downloadCompliance, doDownloadCompliance);
  yield takeLatestAction(
    actions.getControlStandardRules,
    doLoadControlStandardRules,
  );
  yield takeLatestAction(
    actions.getSubControlStandardRules,
    doLoadSubControlStandardRules,
  );
  yield takeLatestAction(actions.getComplianceSummary, doGetComplianceSummary);
  yield takeLatestAction(
    actions.getComplianceSummaryByResourceType,
    doGetComplianceSummaryByResourceType,
  );
  yield takeLatestAction(
    actions.getSummaryByResourceType,
    doGetSummaryByResourceType,
  );
  yield takeLatestAction(
    actions.getTop5ResourceByTypes,
    doLoadTop5ResourceByTypes,
  );
  yield takeLatestAction(actions.getHistoricalTrend, doLoadHistoricalTrend);
  yield takeLatestAction(
    actions.getActionHistoricalTrend,
    doLoadActionHistoricalTrend,
  );
  yield takeLatestAction(
    actions.getComplianceSummaryByRule,
    doGetComplianceSummaryByRule,
  );
  yield takeLatestAction(
    actions.getPolicyCompareInfoForPolicy1,
    doGetPolicyCompareInfoForPolicy1,
  );
  yield takeLatestAction(
    actions.getPolicyCompareInfoForPolicy2,
    doGetPolicyCompareInfoForPolicy2,
  );
  yield takeLatestAction(actions.addException, doAddException);
  yield takeLatestAction(
    actions.getViolationsByAccount,
    doGetViolationsByAccount,
  );
}

export function* doGetBlueprints(ctx: QueryContext<PageBluePrintsRead>) {
  yield call(ctx.fetch, () =>
    ComplianceService.Blueprint.getBlueprints(ctx.params),
  );
}

export function* doGetPolicyCompareInfoForPolicy1(
  ctx: QueryContext<Summary, ComplianceApiGetSummaryRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getSummary(ctx.params),
  );
}

export function* doGetPolicyCompareInfoForPolicy2(
  ctx: QueryContext<Summary, ComplianceApiGetSummaryRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getSummary(ctx.params),
  );
}

export function* doGetComplianceSummaryByRule(
  ctx: QueryContext<
    PageResourceCompliance,
    ComplianceApiGetComplianceSummaryByResourcesRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByResources(ctx.params),
  );
}

export function* doLoadActionHistoricalTrend(
  ctx: QueryContext<
    CheckHistoryResponse,
    ComplianceApiGetExecutionHistorySummaryRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getExecutionHistorySummary(ctx.params),
  );
}

export function* doDownloadCompliance(
  ctx: QueryContext<any, ComplianceApiGetComplianceSummaryReportRequest>,
) {
  ctx.onSuccess(res => saveAsPDF(res.data, 'compliance-report.pdf'));

  yield call(
    ctx.fetch,
    () => ComplianceService.Compliance.getComplianceSummaryReport(ctx.params),
    { map: res => res, errorMsg: 'Failed to download compliance report!' },
  );
}

export function* doLoadHistoricalTrend(
  ctx: QueryContext<
    CheckHistoryResponse,
    ComplianceApiGetExecutionHistorySummaryRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getExecutionHistorySummary(ctx.params),
  );
}

export function* doLoadTop5ResourceByTypes(
  ctx: QueryContext<
    PageResourceTypeCompliance,
    ComplianceApiGetIssueSummaryRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getIssueSummary(ctx.params),
  );
}

// ResourceByType API call for AggregatedAccounts
export function* doGetComplianceSummaryByResourceType(
  ctx: QueryContext<
    PageResourceTypeCompliance,
    ComplianceApiGetComplianceSummaryByResourceTypeRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByResourceType(ctx.params),
  );
}

export function* doGeRules(
  ctx: QueryContext<
    PageRuleCompliance,
    ComplianceApiGetComplianceSummaryByRuleRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByRule(ctx.params),
  );
}

export function* doGeRulesForException(
  ctx: QueryContext<
    PageRuleCompliance,
    ComplianceApiGetComplianceSummaryByRuleRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByRule(ctx.params),
  );
}

export function* doLoadControlStandardRules(
  ctx: QueryContext<
    PageStandardRuleCompliance,
    ComplianceApiGetComplianceSummaryByComplianceRuleRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByComplianceRule(
      ctx.params,
    ),
  );
}

export function* doLoadSubControlStandardRules(
  ctx: QueryContext<
    PageStandardRuleCompliance,
    ComplianceApiGetComplianceSummaryByComplianceRuleRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByComplianceRule(
      ctx.params,
    ),
  );
}

export function* doGetComplianceSummary(
  ctx: QueryContext<Summary, ComplianceApiGetSummaryRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getSummary(ctx.params),
  );
}

export function* doGetSummaryByResourceType(
  ctx: QueryContext<
    PageResourceCompliance,
    ComplianceApiGetComplianceSummaryByResourcesRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByResources(ctx.params),
  );
}

export function* doAddException(
  ctx: QueryContext<any, BlueprintApiDeleteRulesRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.deleteRules(ctx.params),
    {
      successMsg: `Successfully added ${
        ctx.params.ruleInstanceMultiCreate?.length || ''
      } rules as exception!`,
      errorMsg: 'Failed to add exception!',
    },
  );
}

export function* doGetViolationsByAccount(
  ctx: QueryContext<
    ComplianceSummaryAccount[],
    ComplianceApiGetComplianceSummaryByAccountRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Compliance.getComplianceSummaryByAccount(ctx.params),
  );
}
