import React, { useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { GraphScale } from 'components/Visualization/PixiGraph/common/GraphScale';
import { useGraphDnd } from 'components/Visualization/PixiGraph/hooks/useGraphDnd';
import { useGraphMenuTooltip } from 'components/Visualization/PixiGraph/hooks/useMenuTooltip';
import { useAccessMap } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMap';
import { AccessGraphMenu } from 'containers/Visibility/SecurityGraphNext/Components/Access/GraphMenuTooltip';
import { useDownloadMap } from 'containers/Visibility/SecurityGraphNext/hooks/useDownloadMap';

interface AccessGraphProps {}

interface AccessGraphProps {
  findings?: boolean;
}

export const AccessGraph = (props: AccessGraphProps) => {
  const ref = useRef<any>(null);
  const { app, graph, renderer } = useAccessMap({
    ref,
    findings: props.findings,
  });
  const graphMenu = useGraphMenuTooltip({ app, graph, renderer, ref });
  const { listeners } = useGraphDnd({ ref, app });
  useDownloadMap(app, 'access-map');

  return (
    <Box
      h={'full'}
      w={'full'}
      pos={'absolute'}
      onContextMenu={e => e.preventDefault()}
    >
      <Box
        className="graph-canvas-v1"
        ref={ref}
        {...listeners}
        {...graphMenu.listeners}
      ></Box>
      <AccessGraphMenu app={app} graph={graph} renderer={renderer} />
      <GraphScale app={app} graph={graph} />
    </Box>
  );
};
