import { PERMISSIONS_SERVICE } from '../service_urls';
import { withPrefixArgs, withPrefixNoArgs } from '../utils';

import { IMetadataPermissions } from './types';

const PREFIX = PERMISSIONS_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
const withNoArgs = withPrefixNoArgs(PREFIX);

export const permissionsApis = {
  // metadata drawer permissions
  getMetadataPermissions: withArgs<IMetadataPermissions>(
    `/entity/usage-service`,
  ),
};
