import React, { FC } from 'react';

import { Box, Center, Spinner } from '@chakra-ui/react';

import { customTheme } from '../theme';

interface IPageLoader {
  isLoading?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export const WithCenterLoader: FC<IPageLoader> = props => {
  const { isLoading } = props;
  return (
    <>
      {isLoading && (
        <Center h="full" w="full">
          <Spinner
            emptyColor="gray.100"
            color={customTheme.colors.primary}
            size="xl"
          />
        </Center>
      )}
      {!isLoading && props?.children}
    </>
  );
};

export const PageLoader: FC = () => {
  return (
    <>
      <Box
        pos={'fixed'}
        left={'50%'}
        top={'50%'}
        transform={'translate(-50%, calc(-50% - 40px))'}
      >
        <Spinner
          emptyColor="gray.100"
          color={customTheme.colors.primary}
          size="xl"
        />
      </Box>
    </>
  );
};

export const RingLoader = () => {
  return (
    <>
      <Box
        pos={'absolute'}
        top={0}
        left={0}
        w={'full'}
        h={'full'}
        bg={'white'}
        zIndex={10}
      >
        <Box
          pos={'absolute'}
          left={'50%'}
          top={'40%'}
          transform={'translate(-50%, calc(-50% - 40px))'}
        >
          <Spinner
            emptyColor="gray.100"
            color={customTheme.colors.primary}
            size="lg"
          />
        </Box>
      </Box>
    </>
  );
};
