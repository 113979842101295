import React, { FC, useEffect } from 'react';

import { find, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useDataClassificationWizard } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/useDataClassificationWizard';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

import { RepositoryDataClassificationForm } from './RepositoryDataClassificationForm';

interface Props {
  handleSubmit(value: Record<string, any>);
  handleRedirect();
}

export const RepositoryDataClassification: FC<Props> = props => {
  const { handleSubmit, handleRedirect } = props;
  const { state, updateState } = useDataClassificationWizard();
  const dispatch = useDispatch();
  const { repositories } = useSelector(selectDataClassification);
  const { currentRecord, actionType } = useDataConfigurationContext();

  //get repositories
  useEffect(() => {
    if (!!state.selectedAccount?.value) {
      dispatch(
        actions.getRepositories({
          q: {
            uuid: state.selectedAccount?.value,
          },
        }),
      );
    }
  }, [dispatch, state.selectedAccount]);

  //set repository options
  useEffect(() => {
    const options = map(repositories.data, o => ({ label: o.id, value: o.id }));
    updateState({ repositoryOptions: options, selectedRepository: options[0] });
  }, [repositories.data, updateState]);

  //set selected repository
  useEffect(() => {
    if (actionType === 'Update') {
      const repo = currentRecord?.data_classifications?.[0]?.data_sources?.[0]?.split(
        ':',
      )?.[0];
      updateState({
        selectedRepository: find(
          state.repositoryOptions,
          o => o.value === repo,
        ),
      });
    }
  }, [actionType, currentRecord, state.repositoryOptions, updateState]);

  //get branches
  useEffect(() => {
    if (!!state.selectedAccount?.value && !!state.selectedRepository?.value) {
      dispatch(
        actions.getRepoBranches({
          q: {
            uuid: state.selectedAccount?.value,
            repoId: state.selectedRepository?.value,
          },
        }),
      );
    }
  }, [dispatch, state.selectedAccount, state.selectedRepository]);

  return (
    <RepositoryDataClassificationForm
      handleSubmit={handleSubmit}
      handleRedirect={handleRedirect}
      updateValue={updateState}
      {...state}
    />
  );
};
