/**
 *
 * Asynchronously loads the component for Whitelist
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Whitelist = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Whitelist,
);
