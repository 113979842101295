import React, { FC, useEffect, useState } from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { find, includes, isEmpty, map } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';

import { getIcon } from 'components/Icons/Components';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { getFieldLabel } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/utils';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

import { AwsDataClassificationForm } from './AwsDataClassificationForm';

interface Props {
  handleSubmit(value: Record<string, any>);
  handleRedirect();
}

export const AwsDataClassification: FC<Props> = props => {
  const { handleSubmit, handleRedirect } = props;
  const dispatch = useDispatch();
  const { dataScannerInfo, dataClassificationServices } = useSelector(
    selectDataClassification,
  );
  const [dataSourceType, setDataSourceType] = useState<Record<string, any>>({});
  const [dataSourceTypeOptions, setDataSourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);
  const [accountOptions, setAccountOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [selectedAccounts, setSelectedAccounts] = useState<
    Record<string, any>[]
  >([]);
  const [selectedEnv, setSelectedEnv] = useState<Record<string, any>>({});
  const { toCloudAccountId, toCloudAccountName } = useCloudAccountId();
  const [environmentOptions, setEnvironmentOptions] = useState<
    Record<string, any>[]
  >([]);
  const { getEnvironmentOptions } = useEnvironmentOptions();
  const { getCloudNativeName } = useResourceType();
  const { currentRecord, actionType } = useDataConfigurationContext();

  /*get data classification available services*/
  useEffect(() => {
    dispatch(actions.getDataClassificationServices({}));
  }, [dispatch]);

  /*set available services*/
  useEffect(() => {
    const options = map(
      filter(
        dataClassificationServices.data,
        o =>
          !includes(
            [
              SupportedServices.GitLabBranch,
              SupportedServices.GitHubBranch,
              SupportedServices.BitBucketBranch,
            ],
            o,
          ),
      ),
      o => ({
        label: getCloudNativeName(o),
        value: o,
        native_resource: o,
        fieldLabel: getFieldLabel(o),
        icon: getIcon(o),
      }),
    );
    setDataSourceTypeOptions(options);
    setDataSourceType(options[0]);
  }, [dataClassificationServices.data, getCloudNativeName]);

  useEffect(() => {
    setEnvironmentOptions(getEnvironmentOptions());
  }, [getEnvironmentOptions]);

  //set account options
  useEffect(() => {
    if (actionType === 'Update')
      setSelectedEnv(
        find(
          environmentOptions,
          o => o.value === currentRecord?.environment_id,
        ) ?? {},
      );
    else setSelectedEnv(environmentOptions[0]);
  }, [environmentOptions, currentRecord, actionType]);

  useEffect(() => {
    !isEmpty(selectedEnv?.data?.account_ids) &&
      dispatch(
        actions.getDataScannerInfo({
          q: { accountsIds: selectedEnv?.data?.account_ids },
        }),
      );
  }, [selectedEnv, dispatch]);

  //set account options
  useEffect(() => {
    const filteredAccounts = filter(selectedEnv?.data?.account_ids, o =>
      includes(Object.keys(dataScannerInfo.data), o),
    );
    setAccountOptions(
      map(filteredAccounts, o => ({
        label: `${toCloudAccountName(o)} (AccountID: ${toCloudAccountId(o)})`,
        value: o,
        data: o,
        type: 'aws',
      })),
    );
  }, [selectedEnv, toCloudAccountId, toCloudAccountName, dataScannerInfo.data]);

  //set selected accounts
  useEffect(() => {
    if (actionType === 'Update')
      setSelectedAccounts(
        filter(accountOptions, o =>
          includes(currentRecord?.service_ids, o.value),
        ),
      );
    else {
      setSelectedAccounts(accountOptions);
    }
  }, [actionType, currentRecord, accountOptions, selectedEnv]);

  //get data sources
  useEffect(() => {
    if (!!dataSourceType?.value && !isEmpty(selectedAccounts)) {
      dispatch(
        actions.getDataSources({
          q: {
            insightV2RequestBase: {
              account_id: selectedAccounts?.map(o => o?.value),
              resource_type: dataSourceType?.value,
            },
          },
        }),
      );
    }
  }, [dispatch, selectedAccounts, dataSourceType]);

  return (
    <AwsDataClassificationForm
      handleSubmit={handleSubmit}
      handleRedirect={handleRedirect}
      dataSourceTypeOptions={dataSourceTypeOptions}
      accountOptions={accountOptions}
      setDataSourceType={setDataSourceType}
      dataSourceType={dataSourceType}
      selectedAccounts={selectedAccounts}
      setSelectedAccounts={setSelectedAccounts}
      selectedEnv={selectedEnv}
      setSelectedEnv={setSelectedEnv}
      environmentOptions={environmentOptions}
    />
  );
};
