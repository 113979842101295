import React from 'react';

import { Stack } from '@chakra-ui/react';

import { DeleteActionButton } from 'app/components';
import { EditIcon } from 'app/components/Icons';
import { ActionButton } from 'components/DataEntry';

export const renderActions = ({ row, onEdit, onDelete }) => (
  <>
    <Stack isInline spacing={0}>
      <ActionButton label="Edit" icon={<EditIcon />} onClick={onEdit} />
      {row.name !== 'master' && (
        <DeleteActionButton onConfirm={onDelete} name={row?.name} type="" />
      )}
    </Stack>
  </>
);
