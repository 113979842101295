/**
 *
 * Tickets
 *
 */

import React, { memo, useEffect } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { PrimaryIconButton } from 'components/DataEntry';
import { DownloadIcon, TicketIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { TicketFilters } from 'containers/Findings/Tickets/Components/TicketFilters';
import { TicketsTable } from 'containers/Findings/Tickets/Components/TicketsTable';
import { ticketSaga } from 'containers/Findings/Tickets/saga';
import { reducer, sliceKey } from 'containers/Findings/Tickets/slice';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useInjector } from 'utils/inject';

export const Tickets = memo(() => {
  useInjector(sliceKey, reducer, ticketSaga);

  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();

  useEffect(() => {
    dispatch(sharedStateActions.getAlertResourceTypes({ q: {} }));
  }, [dispatch]);

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack w="full" h="full" spacing={5}>
        <HStack w="full" justify="space-between">
          <Box>
            <PageHeaderWithIcon
              label="Response & Tickets"
              icon={<TicketIcon />}
            />
          </Box>
          <HStack>
            <TicketFilters />
            <PrimaryIconButton
              tooltip="Download information to CSV"
              aria-label="download"
              onClick={() => {}}
            >
              <DownloadIcon />
            </PrimaryIconButton>
          </HStack>
        </HStack>
        <TicketsTable />
      </Stack>
    </Card>
  );
});
