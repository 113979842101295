import { useEffect, useState } from 'react';

type onEvent = (e: any) => void;

export interface WebSocketProps {
  onMessage: onEvent;
  authToken?: string;
  onOpen?: onEvent;
  onClose?: onEvent;
}

export function useWebSocket(props: WebSocketProps) {
  const [url, setUrl] = useState('');
  const { onMessage, authToken } = props;
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (!socket && url && authToken) {
      const newSocket = new WebSocket(url);
      setSocket(newSocket);

      newSocket.onopen = event => {
        console.log(`[open] Connection established`);
        // send the jwt token for initial websocket auth
      };

      newSocket.onmessage = event => {
        // console.log('json', JSON.parse(event.data));
        onMessage(JSON.parse(event.data));
      };

      newSocket.onclose = event => {
        if (event.wasClean) {
          console.log(
            `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
          );
        } else {
          console.log('[close] Connection died', event);
        }
      };

      newSocket.onerror = event => {
        console.log(`[error]`, event);
      };
    }
  }, [onMessage, url, socket, authToken]);

  return { socket, props, setUrl };
}
