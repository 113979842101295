import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const PayIcon = createIcon({
  displayName: 'PayIcon',
  viewBox: '0 0 80 80',
  path: (
    <>
      <path
        d="M43.6781425,73c-0.5444336,0-1.0898438-0.0620117-1.628418-0.1870117l-24.7226563-4.8398438
				c-0.4692383-0.0917969-0.8076172-0.503418-0.8076172-0.9814453V47.2368164c0-0.5522461,0.4477539-1,1-1
				c7.3574219,0,10.6308594,2.4526367,11.605957,3.3798828l13.6948242,1.1376953
				c2.0141602,0.1186523,3.5922852,1.7226563,3.7124023,3.7202148c1.3012695-0.3139648,2.5551758-0.7490234,3.7421875-1.300293
				l15.8896523-7.7568359c1.3139648-0.7041016,2.8745117-0.6933594,4.1796875,0.0322266
				c1.2592773,0.6992188,2.0913086,1.9731445,2.2260742,3.4072266c0.1347656,1.4345703-0.4458008,2.8413086-1.5532227,3.7626953
				l-23.038578,18.9716797C46.6903496,72.5209961,45.1908379,73,43.6781425,73z M18.5194511,66.168457l23.9492188,4.6889648
				c1.5063477,0.3496094,3.0576172,0.0380859,4.2895508-0.8496094l22.9834023-18.9287109
				c0.6049805-0.503418,0.9116211-1.2460938,0.8374023-2.0351563c-0.0742188-0.7885742-0.5136719-1.4614258-1.2060547-1.8457031
				c-0.7104492-0.3955078-1.5615234-0.3989258-2.2749023-0.012207c-0.0126953,0.0068359-0.0253906,0.0136719-0.0385742,0.0195313
				l-15.9252968,7.7744141c-1.7700195,0.8217773-3.6518555,1.40625-5.6113281,1.7446289
				c-0.3505859,0.0605469-0.7080078-0.0698242-0.9375-0.34375c-0.2290039-0.2734375-0.2944336-0.6484375-0.1728516-0.9833984
				c0.0800781-0.21875,0.1210938-0.421875,0.1269531-0.6196289c0.0292969-1.0737305-0.7880859-1.965332-1.8613281-2.0288086
				l-14.0942383-1.1704102c-0.2753906-0.0229492-0.5292969-0.1586914-0.7006836-0.3754883
				c-0.0166016-0.0195313-2.3432617-2.7128906-9.3637695-2.949707V66.168457z M66.6210175,46.3071289h0.0097656H66.6210175z"
      />
      <path d="M42.8719902 58.6865234H31.1161308c-.5522461 0-1-.4477539-1-1s.4477539-1 1-1H42.814373c.9599609-.1235352 1.6977539-.9355469 1.7246094-1.9072266.0297852-1.0751953-.7875977-1.9667969-1.8608398-2.0302734l-14.0942383-1.1704102c-.550293-.0458984-.9594727-.5288086-.9135742-1.0795898s.5214844-.9667969 1.0795898-.9135742l14.0703125 1.1689453c2.1337891.1254883 3.777832 1.918457 3.7177734 4.0800781-.0546875 1.9853516-1.5859375 3.6391602-3.5615234 3.8466797C42.9418144 58.6845703 42.9066582 58.6865234 42.8719902 58.6865234zM15.9667168 70.6665039h-6.003418c-1.4077148 0-2.5527339-1.1450195-2.5527339-2.5527344V46.0307617c0-1.4077148 1.1450191-2.5527344 2.5527339-2.5527344h6.003418c1.4077148 0 2.5527344 1.1450195 2.5527344 2.5527344v22.0830078C18.5194511 69.5214844 17.3744316 70.6665039 15.9667168 70.6665039zM9.9632988 45.4780273c-.3046875 0-.5527344.2480469-.5527344.5527344v22.0830078c0 .3046875.2480469.5527344.5527344.5527344h6.003418c.3046875 0 .5527344-.2480469.5527344-.5527344V46.0307617c0-.3046875-.2480469-.5527344-.5527344-.5527344H9.9632988z" />
      <g>
        <path
          d="M46.9623222,46.0878906c-6.1987305,0-13.7807617-1.378418-16.4692383-5.2480469
				c-4.7382813-6.8193359,9.2978516-24.2172852,10.9135742-26.1782227c0.1899414-0.2304688,0.4731445-0.3642578,0.7719727-0.3642578
				h9.5673828c0.2988281,0,0.5820313,0.1337891,0.7719727,0.3642578
				c1.6157227,1.9609375,15.6518593,19.3588867,10.9135742,26.1782227l0,0
				C60.743084,44.7094727,53.1610527,46.0878906,46.9623222,46.0878906z M42.6532402,16.2973633
				c-4.2836914,5.2900391-13.7290039,18.7788086-10.5175781,23.4008789
				c1.8242188,2.6259766,7.7827148,4.3896484,14.8266602,4.3896484s13.0024414-1.7636719,14.8266602-4.3896484l0,0
				c3.2133827-4.6245117-6.2333984-18.1118164-10.5175781-23.4008789H42.6532402z"
        />
        <path
          d="M51.7460136,16.2973633h-9.5673828c-0.4018555,0-0.7646484-0.2407227-0.9213867-0.6108398l-3.0810547-7.2973633
				c-0.1303711-0.309082-0.097168-0.6625977,0.0878906-0.9418945C38.4496269,7.1679688,38.7626152,7,39.0975761,7H54.82658
				c0.3349609,0,0.6479492,0.1679688,0.8334961,0.4472656c0.1850586,0.2792969,0.2182617,0.6328125,0.0878906,0.9414063
				l-3.0805664,7.2973633C52.5111504,16.0566406,52.1478691,16.2973633,51.7460136,16.2973633z M42.8417168,14.2973633h8.2412109
				L53.3187675,9H40.6053886L42.8417168,14.2973633z"
        />
        <path
          d="M38.0702324,17.3212891c-0.8344727,0-1.6967773-0.137207-2.5078125-0.5058594
					c-0.5024414-0.2285156-0.7246094-0.8212891-0.4960938-1.3242188c0.2285156-0.5024414,0.8222656-0.7236328,1.3242188-0.4960938
					c2.1699219,0.9853516,5.2973633-0.5698242,5.3286133-0.5859375c0.4912109-0.2563477,1.09375-0.0615234,1.3476563,0.4287109
					c0.2539063,0.4907227,0.0620117,1.09375-0.4287109,1.3476563
					C42.5189629,16.2470703,40.4066582,17.3212891,38.0702324,17.3212891z"
        />
        <path
          d="M41.5409355,16.3041992c-0.8632813,0-3.3193359-0.2250977-4.3369141-2.6899414
					c-0.2109375-0.5107422,0.0322266-1.0952148,0.5424805-1.3061523c0.5107422-0.2119141,1.0952148,0.0327148,1.3061523,0.5424805
					c0.652832,1.581543,2.5371094,1.4545898,2.6171875,1.4501953c0.5429688-0.0463867,1.0302734,0.3662109,1.0761719,0.9140625
					c0.0454102,0.5478516-0.3564453,1.0292969-0.9038086,1.0786133
					C41.8192558,16.2954102,41.7108574,16.3041992,41.5409355,16.3041992z"
        />
        <g>
          <path
            d="M46.9623222,38.9584961c-2.1816406,0-3.956543-1.7749023-3.956543-3.956543c0-0.5522461,0.4477539-1,1-1
					s1,0.4477539,1,1c0,1.0786133,0.8779297,1.956543,1.956543,1.956543s1.956543-0.8779297,1.956543-1.956543
					c0-1.0791016-0.8779297-1.9570313-1.956543-1.9570313c-2.1816406,0-3.956543-1.7749023-3.956543-3.956543
					c0-2.1821289,1.7749023-3.9570313,3.956543-3.9570313s3.956543,1.7749023,3.956543,3.9570313c0,0.5522461-0.4477539,1-1,1
					s-1-0.4477539-1-1c0-1.0791016-0.8779297-1.9570313-1.956543-1.9570313s-1.956543,0.8779297-1.956543,1.9570313
					c0,1.0786133,0.8779297,1.956543,1.956543,1.956543c2.1816406,0,3.956543,1.7749023,3.956543,3.9570313
					C50.9188652,37.1835938,49.1439629,38.9584961,46.9623222,38.9584961z"
          />
          <path d="M46.9623222 27.1313477c-.5522461 0-1-.4477539-1-1v-1.6616211c0-.5522461.4477539-1 1-1s1 .4477539 1 1v1.6616211C47.9623222 26.6835938 47.5145683 27.1313477 46.9623222 27.1313477zM46.9623222 40.6206055c-.5522461 0-1-.4477539-1-1v-1.6621094c0-.5522461.4477539-1 1-1s1 .4477539 1 1v1.6621094C47.9623222 40.1728516 47.5145683 40.6206055 46.9623222 40.6206055z" />
        </g>
      </g>
    </>
  ),
});
