/**
 *
 * IP Whitelisting
 *
 */

import React, { memo } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'app/components/DataDisplay/Card/Card';
import { AddButton } from 'app/components/DataEntry/Button';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { usePageContentContext } from 'components/Layout';

import { renderActions } from './utils';

interface Props {}

export const IPWhitelisting = memo((props: Props) => {
  const navigate = useNavigate();
  const { contentHeight } = usePageContentContext();

  const onEdit = row => {
    navigate(``);
  };
  const handleDelete = row => {
    // dispatch(actions.);
  };

  const columns = [
    { header: 'Name', accessor: 'name' },
    { header: 'CIDR/IP', accessor: 'cidr_ip' },
    { header: 'Ports', accessor: 'ports' },
    { header: 'Created By', accessor: 'createdBy' },
    {
      header: 'Actions',
      accessor: 'id',
      render: ({ row }) =>
        renderActions({
          row,
          onDelete: () => handleDelete(row),
          onEdit: () => onEdit(row),
        }),
      align: 'center',
    },
  ];

  return (
    <Stack h={contentHeight + 'px'} spacing={5}>
      <Flex w="full" justify="flex-end">
        <AddButton
          label="Add IP Whitelist"
          onClick={() => navigate('/setup/ip-whitelisting/add')}
        />
      </Flex>
      <Box flex={1}>
        <Card>
          <Table data={[]} columns={columns} />
        </Card>
      </Box>
    </Stack>
  );
});
