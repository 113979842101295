import React, { FC, useEffect, useState } from 'react';

import { TagCondition } from '@ariksa/inventory-core';
import { Box, Wrap, WrapItem, Stack } from '@chakra-ui/react';
import { map, find, filter, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { tagKeyConditionOptions } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/utils';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { useWhitelistContext } from '../../../../../context';
import { actions } from '../../../../../slice';

interface Props {
  id: string;
}

export const TagKeyValueComponent: FC<Props> = props => {
  const { id } = props;
  const { tagKeyOptions, setIsDisabledCriteriaNext } = useWhitelistContext();
  const { tagKeyValueCriteria } = useSelector(selectWhitelist);
  const { tagValues } = useSelector(selectSharedState);
  const { tagKeys } = useSelector(selectSharedState);
  const [valueOptions, setValueOptions] = useState<Record<string, any>[]>([]);
  const [selectedKey, setSelectedKey] = useState<Record<string, any>>({});
  const [selectedCondition, setSelectedCondition] = useState<
    Record<string, any>
  >(tagKeyConditionOptions[0]);
  const [selectedValues, setSelectedValues] = useState<Record<string, any>[]>(
    [],
  );
  const dispatch = useDispatch();

  //set initial values
  useEffect(() => {
    setSelectedKey(
      find(tagKeyOptions, o => o?.value === tagKeyValueCriteria[id]?.key) ||
        tagKeyOptions[0],
    );
    setSelectedCondition(
      tagKeyValueCriteria[id]?.key_condition === TagCondition.Tagged
        ? tagKeyConditionOptions[0]
        : tagKeyConditionOptions[1],
    );
    setSelectedValues(
      filter(valueOptions, o =>
        includes(tagKeyValueCriteria[id]?.value, o?.value),
      ),
    );
  }, [tagKeyOptions, tagKeyValueCriteria, id, valueOptions]);

  const updateCriteria = (field, value) => {
    dispatch(
      actions.updateTagKeyValuesCriteria({
        id,
        value: {
          key: field === 'key' ? value : selectedKey?.value,
          key_condition:
            field === 'key_condition' ? value : selectedCondition?.value,
          value:
            field === 'key'
              ? []
              : map(field === 'value' ? value : selectedValues, o => o?.value),
          value_condition: TagCondition.Tagged,
        },
      }),
    );
  };

  //load values for selected key
  useEffect(() => {
    selectedKey?.value &&
      dispatch(
        sharedStateActions.getTagValues({ q: { keys: selectedKey?.value } }),
      );
  }, [selectedKey, dispatch]);

  //set value options based on key
  useEffect(() => {
    setValueOptions(
      map(tagValues.data[selectedKey?.value], o => ({ label: o, value: o })),
    );
  }, [selectedKey, tagValues]);

  useEffect(() => {
    setIsDisabledCriteriaNext(!selectedValues.length);
  }, [selectedValues, setIsDisabledCriteriaNext]);

  return (
    <Stack w="full">
      <Stack isInline>
        <Box whiteSpace="nowrap" pt={1}>
          If an entity
        </Box>
        <Box w={48}>
          <Select
            options={tagKeyConditionOptions}
            value={selectedCondition}
            onChange={selected =>
              updateCriteria('key_condition', selected?.value)
            }
          />
        </Box>
        <Box whiteSpace="nowrap" pt={1}>
          with tag key
        </Box>
        <Box w="full" flex={1}>
          <Select
            options={tagKeyOptions}
            value={selectedKey}
            onChange={selected => updateCriteria('key', selected?.value)}
            isLoading={tagKeys.isLoading}
            isDisabled={tagKeys.isLoading}
          />
        </Box>
      </Stack>
      <Stack isInline>
        <Box whiteSpace="nowrap" pt={1}>
          and value
        </Box>
        <Box w="full" flex={1}>
          <Select
            options={valueOptions}
            isMulti
            showTotalSelected={!!selectedValues.length}
            value={selectedValues}
            onChange={selected => updateCriteria('value', selected)}
            isLoading={tagValues.isLoading}
            isDisabled={tagValues.isLoading}
          />
        </Box>
      </Stack>
      <Wrap>
        {map(tagKeyValueCriteria[id]?.value, (o, index) => (
          <WrapItem>
            <Tag
              key={id + o + index}
              label={o}
              styles={{ label: { whiteSpace: 'pre-wrap' } }}
              closeButton
              onClose={() =>
                updateCriteria(
                  'value',
                  filter(tagKeyValueCriteria[id]?.value, l => l !== o),
                )
              }
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
