import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ListIcon = props => {
  return (
    <Icon viewBox="0 0 24 24" h="full" w="full" color={'inherit'} {...props}>
      <path
        id="Vector"
        d="M9 17H19M9 12H19M9 7H19M5.00195 17V17.002L5 17.002V17H5.00195ZM5.00195 12V12.002L5 12.002V12H5.00195ZM5.00195 7V7.002L5 7.00195V7H5.00195Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
