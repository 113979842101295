import React, { FC, useEffect, useReducer } from 'react';

import { RuleCreate } from '@ariksa/compliance-policies/api';
import { Resource } from '@ariksa/inventory-core/api';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomPolicyDetails } from 'containers/PolicyHub/Policies/CreatePolicy/CustomPolicyDetails';
import {
  categoryClassOptions,
  policyProviderOptions,
  policySeverityOptions,
} from 'containers/PolicyHub/Policies/CreatePolicy/utils';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';

export const CreatePolicy: FC = () => {
  const { searchQuery, saveQuery, ruleCategories } = useSelector(
    selectPolicyHub,
  );

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      alert_category: '',
      severity: policySeverityOptions[0]?.value,
      category_class: categoryClassOptions[0]?.value,
      compliance_description: '',
      alert_description: '',
      provider: policyProviderOptions[0],
    },
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //set alert categories
  useEffect(() => {
    if (!isEmpty(ruleCategories.data))
      updateState({
        alert_category: ruleCategories.data?.[0],
      });
  }, [ruleCategories.data]);

  const handleSubmit = data => {
    const { summary, description, category, severity, alertDescription } = data;
    const { alertCategory = {}, categoryClass = {} } = category ?? {};

    // first save the query
    const policy: RuleCreate = {
      config_id: summary,
      is_enabled: true,
      search_id: '',
      compliance_description: description,
      alert_description: alertDescription,
      alert_category: alertCategory.value,
      category_class: categoryClass.value,
      severity: severity.value,
      resource_type: searchQuery.resource?.name,
      provider: state.provider?.value,
    };

    dispatch(
      actions.saveQuery({
        q: {
          searchWrapper: {
            wrapper: {
              resource: searchQuery.resource as Resource,
            },
            name: summary ?? '-',
          },
        },
        onSuccess: res => {
          if (!res?.id) {
            console.error('failed to save custom search query');
          }

          policy.search_id = res?.id;
          dispatch(
            actions.createPolicy({
              q: { ruleCreate: policy },
              onSuccess: () => {
                navigate(`/policy-hub/policy?category=${category.value}`);
              },
            }),
          );
        },
      }),
    );
  };

  const updateForm = (field: any, value: any) => {
    updateState({ [field]: value });
  };

  return (
    <CustomPolicyDetails
      onSubmit={handleSubmit}
      action={'Create'}
      updateForm={updateForm}
      isLoading={saveQuery.isLoading}
      {...state}
    />
  );
};
