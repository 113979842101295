import React, { useEffect, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { Stack, Flex, Text, Center, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmationInput,
  DetailsIdentifier,
  H4,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, DeleteButton } from 'components/DataEntry';
import { DisconnectIcon, TrashIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { errorToast, successToast } from 'components/Toast';
import { actions as appActions } from 'containers/App/slice';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { actions } from '../slice';

import { attachedEnvironmentsColumns } from './utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  account: CloudAccountGetResponse;
  member?: boolean;
  getMemberAccounts?: () => void;
}

export const DeleteCloudAccountModal: React.FC<Props> = props => {
  const { isOpen, onClose, account, member, getMemberAccounts } = props;
  const dispatch = useDispatch();
  const [command, setCommand] = useState('');
  const { attachedEnvironments, apiCallStatus } = useSelector(
    selectCloudAccounts,
  );
  const { isLoading, data } = attachedEnvironments;
  const { getCloudAccounts } = useCloudAccountsFunctions();

  useEffect(() => {
    dispatch(
      actions.getAttachedEnvironments({
        q: { accountId: account?.uuid },
      }),
    );
  }, [dispatch, account]);

  const onClickDelete = () => {
    dispatch(
      actions.deleteAccount({
        q: { uuid: account?.uuid },
        onSuccess: () => {
          if (member) {
            successToast({ title: 'Account detached successfully.' });
            getMemberAccounts?.();
          } else {
            successToast({ title: 'Account deleted successfully.' });
            getCloudAccounts();
            dispatch(appActions.loadOnboardedCloudAccounts());
            dispatch(appActions.loadEnvironments({}));
          }
          onClose();
        },
        onError: err => {
          errorToast({
            title: `Failed to ${member ? 'detach' : 'delete'} account.`,
            description: err.description,
          });
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={(member ? 'Detach' : 'Delete') + ' Account'}
      styles={{
        modal: { size: '3xl' },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton
            onClick={onClickDelete}
            isDisabled={command !== account?.name}
            isLoading={apiCallStatus.isLoading}
            label={member ? 'Detach' : 'Delete'}
            leftIcon={member ? <DisconnectIcon /> : <TrashIcon />}
          />
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <Stack spacing={3}>
            <DetailsIdentifier label="Account Name" value={account?.name} />
            <DetailsIdentifier
              label="Account ID"
              value={account?.cloud_account_id}
            />
          </Stack>
          {!!data.length && !isLoading && (
            <Stack w="full" h="200px">
              <H4>Attached Environments</H4>
              <Table
                columns={attachedEnvironmentsColumns}
                data={data}
                isLoading={isLoading}
              />
            </Stack>
          )}
          {!data.length && !isLoading && (
            <ConfirmationInput
              confirmationText={account?.name}
              label={
                <Text>
                  Please type in the account name and{' '}
                  {member ? 'detach' : 'delete'}.
                </Text>
              }
              command={command}
              setCommand={setCommand}
            />
          )}
          {!!data.length && !isLoading && (
            <Box>
              Please detach the account <b>{account?.name}</b> from above
              mentioned environments before
              {member ? ' detaching' : ' deleting'} the account.
            </Box>
          )}
          {isLoading && (
            <Center pt={12}>
              <CustomSpinner loading={isLoading} />
            </Center>
          )}
        </Stack>
      }
    />
  );
};
