import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import {
  filter,
  flatten,
  includes,
  isEmpty,
  map,
  union,
  unionBy,
} from 'lodash';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Form } from 'components/DataEntry/Form';
import { selectApp } from 'containers/App/selectors';
import { WhitelistCriteria } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/Steps/WhitelistCriteria';
import { WhitelistStatus } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/Steps/WhitelistStatus';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props {}

export const WhitelistSettings: FC<Props> = () => {
  const {
    handleSubmit,
    onCancel,
    actionType,
    updateFormData,
  } = useWhitelistContext();
  const { regions, tagKeys } = useSelector(selectSharedState);
  const { toCloudAccountId } = useCloudAccountId();
  const { environments } = useSelector(selectApp);
  const { formData, whitelist, createWhitelist, whitelistStatus } = useSelector(
    selectWhitelist,
  );
  const [environmentOptions, setEnvironmentOptions] = useState<
    Record<string, any>[]
  >([]);
  const [selectedAccounts, setSelectedAccounts] = useState<
    Record<string, any>[]
  >([]);
  const [accountOptions, setAccountOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [selectedEnvs, setSelectedEnvs] = useState<Record<string, any>[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);

  //set environment options
  useEffect(() => {
    setEnvironmentOptions(
      map(environments.data, o => ({ label: o?.name, value: o?.id, data: o })),
    );
  }, [environments.data]);

  //set selected environments
  useEffect(() => {
    if (!isEmpty(formData?.ids)) {
      const envIds = map(formData.ids, o => o.environment_id);
      const envs = filter(environmentOptions, a => includes(envIds, a?.value));
      setSelectedEnvs(envs);
    }
  }, [environmentOptions, formData?.ids]);

  //set account options
  useDeepCompareEffect(() => {
    if (!!selectedEnvs.length) {
      let options = map(selectedEnvs, o => ({
        label: o?.data?.name,
        options: map(o?.data?.account_ids, a => ({
          label: toCloudAccountId(a),
          value: a + '_' + o?.data?.id,
          data: {
            accountId: a,
            environmentId: o?.data?.id,
          },
        })),
      }));
      setAccountOptions(options);
    } else {
      setAccountOptions([]);
      setSelectedAccounts([]);
    }
  }, [selectedEnvs]);

  /*set selected accounts
  toCloudAccountId is causing issue, do not include in dependency list*/
  useEffect(() => {
    const accounts = map(formData?.ids, o =>
      map(o?.account_ids, a => ({
        label: toCloudAccountId(a),
        value: a + '_' + o?.environment_id,
        data: {
          accountId: a,
          environmentId: o?.environment_id,
        },
      })),
    );
    setSelectedAccounts(flatten(accounts));
  }, [formData?.ids]);

  return (
    <Box pt={4}>
      <Form
        isLoading={whitelist.isLoading}
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            placeholder: 'Name',
            isRequired: true,
            value: formData?.name,
            onChange: value => updateFormData('name', value),
          },
          environment_ids: {
            type: 'react-select',
            label: 'Environment',
            isRequired: true,
            isDisabled: actionType === 'Update',
            isMulti: true,
            placeholder: 'Select environment...',
            options: environmentOptions,
            onChange: setSelectedEnvs,
            value: selectedEnvs,
          },
          account_ids: {
            type: 'react-select',
            label: 'Cloud Account',
            isRequired: true,
            isDisabled: actionType === 'Update',
            placeholder: 'Select account...',
            options: accountOptions,
            isMulti: true,
            value: selectedAccounts,
            onChange: selected => {
              updateFormData('account_ids', selected);
            },
            onClickGroupHeading: options => {
              updateFormData(
                'account_ids',
                unionBy(selectedAccounts, options, 'value'),
              );
            },
            showTotalSelected: true,
            blurInputOnSelect: false,
          },
          criteria: {
            type: 'custom-with-form-control',
            label: 'Criteria',
            component: <WhitelistCriteria setIsDisabled={setIsDisabled} />,
          },
          status: {
            type: 'custom-with-form-control',
            label: 'Status',
            component: <WhitelistStatus />,
          },
        }}
        styles={{ form: { container: { pl: 14 }, formWidth: '980px' } }}
        buttonOptions={{
          submit: {
            name: 'Okay',
            isDisabled:
              !formData?.name ||
              !formData?.ids?.length ||
              createWhitelist.isLoading ||
              tagKeys.isLoading ||
              regions.isLoading ||
              isDisabled ||
              whitelistStatus.isLoading,
            isLoading: createWhitelist.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: onCancel,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
