import React from 'react';

import { Box, HStack, Square, Stack, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { H1, Heading4, PageHeaderWithIcon } from 'components/DataDisplay';
import { DownloadIconButton, PrimaryButton } from 'components/DataEntry';
import { AIBrainIcon } from 'components/Icons/ReactCustomIcons/AIBrainIcon';
import { SidebarLayout } from 'components/Layout';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { InvestigateGraphDetails } from 'containers/Visibility/SecurityGraphNext/Components/Investigate/InventigateGraphDetails';
import { ViewPolicyModel } from 'containers/Visibility/SecurityGraphNext/Components/Policy/ViewPolicyModel';
import { SecurityGraphContent } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphContent';
import { useGraphComponents } from 'containers/Visibility/SecurityGraphNext/hooks/useGraphComponents';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useBus } from 'hooks/useBus';

export const Investigate = () => {
  const { graphComponent } = useGraphComponents();
  const info = useRedirectInfo();
  const bus = useBus();
  const { searchResult } = useSearchResult();
  const viewPolicy = useDisclosure({});

  return (
    <SidebarLayout
      sidebar={<InvestigateGraphDetails />}
      content={
        <SecurityGraphContent>
          <ActiveResourceProvider>
            <Stack h={'full'}>
              <HStack justify="space-between">
                <HStack>
                  <PageHeaderWithIcon
                    label={
                      <H1 pt={4} mb={2}>
                        Security Graph: Investigate
                      </H1>
                    }
                  />
                </HStack>
                <Box>
                  <DownloadIconButton
                    aria-label="download-access-map"
                    tooltip="Download Map"
                    onClick={async () => {
                      bus.emit('download:security-graph');
                    }}
                    isDisabled={
                      searchResult.isLoading || isEmpty(searchResult.data)
                    }
                    bg="white"
                  />
                </Box>
              </HStack>
              <HStack w={'full'}>
                <HStack bg="#fff" px={3} flex={1} borderRadius={6} spacing={4}>
                  <Square size={6} color={'primary'}>
                    <AIBrainIcon />
                  </Square>
                  <Heading4 py={3}>{info.redirectInfo?.ai_search}</Heading4>
                </HStack>
                <PrimaryButton
                  bg={'primary'}
                  color={'white'}
                  onClick={() => {
                    viewPolicy.onOpen();
                  }}
                >
                  View Policy
                </PrimaryButton>
              </HStack>
              <Box flex={1} pos={'relative'}>
                {graphComponent}
              </Box>
            </Stack>
            {
              <ViewPolicyModel
                isOpen={viewPolicy.isOpen}
                onClose={viewPolicy.onClose}
              />
            }
          </ActiveResourceProvider>
        </SecurityGraphContent>
      }
    />
  );
};
