import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { values } from 'lodash';
import { colorMap } from 'theme';
import { colors } from 'theme/theme2/colors';

import { resourceIcons } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { POLICY_NODE } from './nodes_types';

export function registerPolicyNode() {
  G6.registerNode(
    POLICY_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 45,
              height: 45,
              fill: colors.white,
              stroke: colorMap.primary(300),
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 18,
              lineWidth: 0.4,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        group.addShape('image', {
          attrs: {
            x: x + 12,
            y: y + 12,
            width: 20,
            height: 20,
            img: resourceIcons.policies,
          },
          name: 'img',
        });

        group.addShape('text', {
          attrs: {
            x: 0,
            y: height + 25 + 10,
            // @ts-ignore
            text: `Type: ${cfg?.data?.resource_type || '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'type',
        });

        const sum = values(
          // @ts-ignore
          cfg?.data?.excess_permission_access_counts,
          // @ts-ignore
        ).reduce((a, b) => a + b);

        group.addShape('text', {
          attrs: {
            x: 0,
            y: height + 45 + 10,
            // @ts-ignore
            text: `Name: ${
              // @ts-ignore
              cfg?.data?.name
                ? // @ts-ignore
                  `${cfg?.data?.name.slice(0, 25)} ${
                    // @ts-ignore
                    cfg?.data?.name.length > 25 ? '...' : ''
                  }`
                : '-'
            }`,
            // text: `Excess Permissions: ${cfg?.data?.excess_permissions}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'id',
        });

        group.addShape('text', {
          attrs: {
            x: 0,
            y: height + 45 + 30,
            // @ts-ignore
            text: `Excess Permissions: ${sum}`,
            // text: `Excess Permissions: ${cfg?.data?.excess_permissions}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'id',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
