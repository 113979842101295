import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import {
  AttachIPv6,
  AvailableIPs,
  DefaultForAz,
  IPv4CIDRBlock,
  IPv6CIDRBlock,
  IPv6Native,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

interface ISubnetMetadataAndInsights {}

export const SubnetMetadata: FC<ISubnetMetadataAndInsights> = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insights } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {DefaultForAz(resource)}
      {AttachIPv6(insights)}
      {IPv4CIDRBlock(resource)}
      {AvailableIPs(resource)}
      {IPv6CIDRBlock(resource)}
      {IPv6Native(insights)}
    </Grid>
  );
};
