import React, { CSSProperties } from 'react';

import { Icon } from '@chakra-ui/react';

export const DataClassificationIcon = props => {
  const style: CSSProperties = {
    fill: 'none',
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeMiterlimit: 10,
    stroke: 'currentColor',
  };
  return (
    <Icon viewBox="0 0 32 32" color="inherit" h="full" w="full" {...props}>
      <circle style={style} cx="16" cy="16" r="4" />
      <line style={style} x1="16" y1="12" x2="16" y2="3" />
      <polyline style={style} points="20,7 16,3 12,7 " />
      <line style={style} x1="12" y1="16" x2="3" y2="16" />
      <polyline style={style} points="7,12 3,16 7,20 " />
      <line style={style} x1="16" y1="20" x2="16" y2="29" />
      <polyline style={style} points="12,25 16,29 20,25 " />
      <line style={style} x1="20" y1="16" x2="29" y2="16" />
      <polyline style={style} points="25,20 29,16 25,12 " />
    </Icon>
  );
};
