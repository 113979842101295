import React from 'react';

import { Icon } from '@chakra-ui/react';

export const P2PIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M489.2,22.8c-30.4-30.4-79.8-30.4-110.2,0c-27.4,27.4-30.5,70.7-7.3,101.8L124.6,371.7
	c-31-23.1-74.3-20-101.7,7.3h0c-30.4,30.4-30.4,79.8,0,110.2c30.4,30.4,79.8,30.4,110.2,0c27.4-27.4,30.5-70.7,7.3-101.8
	l247.1-247.1c34.5,25.8,83.3,18.7,109-15.7C519.7,93.6,516.6,50.2,489.2,22.8L489.2,22.8z M117.3,473.4c-21.7,21.8-57,21.8-78.7,0.1
	c-21.8-21.7-21.8-57-0.1-78.7c0,0,0,0,0.1-0.1h0c21.7-21.7,57-21.7,78.7,0C139,416.5,139,451.7,117.3,473.4z M473.4,117.3
	c-21.7,21.7-56.9,21.7-78.7,0l0,0c-21.7-21.7-21.7-57,0-78.7c21.7-21.7,57-21.7,78.7,0C495.2,60.3,495.2,95.5,473.4,117.3
	L473.4,117.3z"
    />
  </Icon>
);
