import { Summary } from '@ariksa/compliance-policies';
import {
  BlueprintApiDeleteRulesRequest,
  BluePrintsRead,
  CheckHistoryResponse,
  ComplianceApiGetComplianceSummaryByAccountRequest,
  ComplianceApiGetComplianceSummaryByComplianceRuleRequest,
  ComplianceApiGetComplianceSummaryByResourcesRequest,
  ComplianceApiGetComplianceSummaryByResourceTypeRequest,
  ComplianceApiGetComplianceSummaryByRuleRequest,
  ComplianceApiGetComplianceSummaryReportRequest,
  ComplianceApiGetExecutionHistorySummaryRequest,
  ComplianceApiGetIssueSummaryRequest,
  ComplianceApiGetSummaryRequest,
  ComplianceSummaryAccount,
  PageBluePrintsRead,
  PageResourceCompliance,
  PageResourceTypeCompliance,
  PageRuleCompliance,
  PageStandardRuleCompliance,
} from '@ariksa/compliance-policies/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  blueprints: QueryState.init({}),
  selectedResourceType: {},
  selectedRuleCategory: {},
  timeInterval: {
    value: '6M',
    label: '6M',
  },
  rules: PagedQueryState.init([]),
  rulesForException: QueryState.init([]),
  controlStandardRules: PagedQueryState.init([]),
  subControlStandardRules: PagedQueryState.init([]),
  summary: QueryState.init({}),
  violationsByAccount: QueryState.init([]),

  byResourceTypeSummary: PagedQueryState.init([]),
  byResourceType: PagedQueryState.init([]),

  topFiveResourceTypes: PagedQueryState.init([]),
  historicalTrend: QueryState.init([]),
  actionHistory: QueryState.init([]),
  download: QueryState.init(''),
  rulesResources: PagedQueryState.init([]),

  selectedTab: 0,
  selectedBlueprint: {} as BluePrintsRead,
  comparePolicies: {
    policy1: QueryState.init({} as Summary),
    policy2: QueryState.init({} as Summary),
  },

  addException: QueryState.init(''),
};

const complianceSlice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    //get blueprints
    getBlueprints(state, action: QueryAction<PageBluePrintsRead>) {
      state.blueprints = QueryState.next(state.blueprints, action, {
        mapData: r => {
          let data = {};
          forEach(r?.items, o => (data[o?.id] = o));
          return data;
        },
      });
    },

    getPolicyCompareInfoForPolicy1(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.comparePolicies.policy1 = QueryState.next(
        state.comparePolicies.policy1,
        action,
      );
    },
    getPolicyCompareInfoForPolicy2(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.comparePolicies.policy2 = QueryState.next(
        state.comparePolicies.policy2,
        action,
      );
    },

    changeSelectedTab(state, action: PayloadAction<number>) {
      state.selectedTab = action.payload;
    },

    changeResourceTypeSeverity(state, action: PayloadAction<string>) {
      state.resourceTypeSeverity = action.payload;
    },

    changeValue(state, action: PayloadAction<{ field: string; value: any }>) {
      state[action.payload.field] = action.payload.value;
    },

    loadRules(
      state,
      action: QueryAction<
        PageRuleCompliance,
        ComplianceApiGetComplianceSummaryByRuleRequest
      >,
    ) {
      state.rules = PagedQueryState.next(state.rules, action, {
        mapData: r => r?.items,
        mapTotalCount: r => r?.total ?? 0,
      });
    },

    loadRulesForException(
      state,
      action: QueryAction<
        PageRuleCompliance,
        ComplianceApiGetComplianceSummaryByRuleRequest
      >,
    ) {
      state.rulesForException = QueryState.next(
        state.rulesForException,
        action,
        {
          mapData: r => r?.items,
        },
      );
    },

    getControlStandardRules(
      state,
      action: QueryAction<
        PageStandardRuleCompliance,
        ComplianceApiGetComplianceSummaryByComplianceRuleRequest
      >,
    ) {
      state.controlStandardRules = PagedQueryState.next(
        state.controlStandardRules,
        action,
        { mapData: r => r?.items, mapTotalCount: r => r?.total ?? 0 },
      );
    },

    getSubControlStandardRules(
      state,
      action: QueryAction<
        PageStandardRuleCompliance,
        ComplianceApiGetComplianceSummaryByComplianceRuleRequest
      >,
    ) {
      state.subControlStandardRules = PagedQueryState.next(
        state.subControlStandardRules,
        action,
        { mapData: r => r?.items, mapTotalCount: r => r?.total ?? 0 },
      );
    },

    //load compliance summary
    getComplianceSummary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.summary = QueryState.next(state.summary, action);
    },

    //load resource category stat
    getSummaryByResourceType(
      state,
      action: QueryAction<
        PageResourceCompliance,
        ComplianceApiGetComplianceSummaryByResourcesRequest
      >,
    ) {
      state.byResourceTypeSummary = PagedQueryState.next(
        state.byResourceTypeSummary,
        action,
        {
          mapData: r => r?.items,
          mapTotalCount: r => r?.total ?? 0,
        },
      );
    },
    updateByResourceTypeSummaryPageInfo(
      state,
      action: PayloadAction<PageInfo>,
    ) {
      state.byResourceTypeSummary.page.info = action.payload;
    },

    // Top 5 Resource by types
    getTop5ResourceByTypes(
      state,
      action: QueryAction<
        PageResourceTypeCompliance,
        ComplianceApiGetIssueSummaryRequest
      >,
    ) {
      state.topFiveResourceTypes = PagedQueryState.next(
        state.topFiveResourceTypes,
        action,
        { mapData: r => r?.items, mapTotalCount: r => r?.total ?? 0 },
      );
    },

    // get Compliance Summary By Resource Type
    getComplianceSummaryByResourceType(
      state,
      action: QueryAction<
        PageResourceTypeCompliance,
        ComplianceApiGetComplianceSummaryByResourceTypeRequest
      >,
    ) {
      state.byResourceType = PagedQueryState.next(
        state.byResourceType,
        action,
        { mapData: r => r?.items, mapTotalCount: r => r?.total ?? 0 },
      );
    },
    updateByResourceTypePageInfo(state, action: PayloadAction<PageInfo>) {
      state.byResourceType.page.info = action.payload;
    },

    // Historical trend
    getHistoricalTrend(
      state,
      action: QueryAction<
        CheckHistoryResponse,
        ComplianceApiGetExecutionHistorySummaryRequest
      >,
    ) {
      state.historicalTrend = QueryState.next(state.historicalTrend, action, {
        mapData: r => r?.history,
      });
    },

    getActionHistoricalTrend(
      state,
      action: QueryAction<
        CheckHistoryResponse,
        ComplianceApiGetExecutionHistorySummaryRequest
      >,
    ) {
      state.actionHistory = QueryState.next(state.actionHistory, action, {
        mapData: r => r?.history,
      });
    },

    getComplianceSummaryByRule(
      state,
      action: QueryAction<
        PageResourceCompliance,
        ComplianceApiGetComplianceSummaryByResourcesRequest
      >,
    ) {
      state.rulesResources = PagedQueryState.next(
        state.rulesResources,
        action,
        { mapData: r => r.items, mapTotalCount: r => r?.total ?? 0 },
      );
    },

    updateRulesPageInfo(state, action: PayloadAction<PageInfo>) {
      state.rules.page.info = action.payload;
    },
    updateStandardRulesPageInfo(state, action: PayloadAction<PageInfo>) {
      state.controlStandardRules.page.info = action.payload;
    },
    updateResourcesPageInfo(state, action: PayloadAction<PageInfo>) {
      state.byResourceTypeSummary.page.info = action.payload;
    },

    updateSelectedBlueprint(state, action: PayloadAction<BluePrintsRead>) {
      state.selectedBlueprint = action.payload;
    },

    downloadCompliance(
      state,
      action: QueryAction<any, ComplianceApiGetComplianceSummaryReportRequest>,
    ) {
      state.download = QueryState.next(state.download, action);
    },

    addException(
      state,
      action: QueryAction<any, BlueprintApiDeleteRulesRequest>,
    ) {
      state.addException = QueryState.next(state.addException, action);
    },

    getViolationsByAccount(
      state,
      action: QueryAction<
        ComplianceSummaryAccount[],
        ComplianceApiGetComplianceSummaryByAccountRequest
      >,
    ) {
      state.violationsByAccount = QueryState.next(
        state.violationsByAccount,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = complianceSlice;
