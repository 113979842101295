import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyAccountIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M423,147.5c-0.6-1.4-1.4-2.7-2.5-3.8L280.4,3.4c-1.1-1.1-2.3-1.9-3.8-2.5c-1.4-0.6-2.9-0.9-4.5-0.9H120.5
				c-38.6,0-70,31.4-70,70.1v327.3c0,38.7,31.4,70.1,70,70.1h141.8c-5.7-7.1-10.5-15-14.2-23.4H120.5c-25.8,0-46.6-21-46.7-46.8
				V70.1c0-25.8,20.9-46.7,46.7-46.8h140v85.7c0,30.1,24.4,54.5,54.4,54.6h85.6v137c8.5,4.6,16.3,10.2,23.3,16.8V152
				C423.9,150.4,423.5,148.9,423,147.5z M315,140.3c-17.2,0-31.1-14-31.1-31.2V39.9L384,140.3H315z M143.8,339
				c0,6.5,5.2,11.7,11.7,11.7H249c4-8.4,9.1-16.3,15-23.4H155.5C149.1,327.3,143.8,332.6,143.8,339z M155.5,163.7h46.7
				c6.4,0,11.7-5.2,11.7-11.7c0-6.5-5.2-11.7-11.7-11.7h-46.7c-6.4,0-11.7,5.2-11.7,11.7C143.8,158.4,149.1,163.7,155.5,163.7z
				 M318.8,233.8H155.5c-6.4,0-11.7,5.2-11.7,11.7c0,6.5,5.2,11.7,11.7,11.7h163.4c6.4,0,11.7-5.2,11.7-11.7
				C330.5,239.1,325.3,233.8,318.8,233.8z"
          />
        </g>
        <path
          d="M348.6,512c-62.2,0-112.9-50.9-112.9-113.4s50.6-113.4,112.9-113.4S461.5,336,461.5,398.6
			S410.9,512,348.6,512z M348.6,290.1c-59.5,0-107.9,48.6-107.9,108.4S289.2,507,348.6,507s107.9-48.6,107.9-108.4
			S408.1,290.1,348.6,290.1z"
        />
      </g>
      <path
        d="M406.5,448.6h-22.3c-4.6,0-8.4-3.8-8.4-8.4v-19.7h5.6c12.4,0,23.2-8.2,26.7-20.1c4.3-14.9-4.1-30.5-18.8-34.8
		c-5.5-12.8-17.3-21.7-30.9-23.4c-21.4-2.7-40.8,12.6-43.5,34.1c-10,2.4-17.1,11.5-17.1,21.9c0,12.4,10,22.4,22.3,22.4h11.1v19.7
		c0,4.7-3.7,8.4-8.4,8.4h-22.3c-1.5,0-2.8,1.3-2.8,2.8s1.2,2.8,2.8,2.8h22.3c7.7,0,13.9-6.3,13.9-14v-19.7h13.9v30.9
		c0,1.6,1.2,2.8,2.8,2.8h0c1.5,0,2.8-1.3,2.8-2.8v-30.9h13.9v19.7c0,7.8,6.2,14,13.9,14h22.3c1.5,0,2.8-1.3,2.8-2.8
		S408,448.6,406.5,448.6z M320.1,414.9c-8.4,0-15.5-6.2-16.6-14.6c-1.2-9.2,5.2-17.7,14.3-18.9c1.3-0.2,2.3-1.3,2.4-2.6
		c1.3-17.6,15.8-31.3,33.3-31.4c13.8,0,26.2,8.5,31.1,21.5c0.3,0.9,1.1,1.5,1.9,1.7c10,2.4,17.1,11.5,17.1,21.9
		c0,12.4-10,22.4-22.3,22.4H320.1z"
      />
    </g>
  </Icon>
);
