import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query/src/types';
import { isDeepEqual } from 'react-use/lib/util';

import { errorToast } from 'components/Toast';

interface AriksaQueryFetcher<TRequest> {
  fetcher?(req: TRequest): void;
}

interface ReactQueryFetcher<TRequest = any> {
  queryFn?(): void;
  __request?: TRequest;
}

export type AriksaQueryObject<TRequest, TResult, TError> = Omit<
  UseQueryOptions<TResult, TError>,
  'queryFn'
> &
  (AriksaQueryFetcher<TRequest> & ReactQueryFetcher<TRequest>);

export type AriksaQueryResult<TRequest, TResult, TError> = UseQueryResult<
  TResult,
  TError
> & {
  sync(req: TRequest): any;
};

export function useAriksaQuery<TRequest = any, TResult = any, TError = any>(
  config: AriksaQueryObject<TRequest, TResult, TError>,
): AriksaQueryResult<TRequest, TResult, TError> {
  const [queryData, setQueryData] = useState<TRequest | any>(null);

  const queryFn = useCallback(
    a => {
      return (queryData && config.fetcher?.(queryData)) ?? config.queryFn?.();
    },
    [config, queryData],
  );

  const queryConfig = useMemo(() => {
    const { queryKey, ...rest } = config;

    const ariksaQueryConfig: UseQueryOptions<TResult, TError> = {
      queryKey: [JSON.stringify(queryKey), queryData],
      queryFn,
      retry: false,
      ...rest,
    };

    return ariksaQueryConfig;
  }, [queryData, queryFn, config]);

  const sync = useCallback(
    (req: TRequest) =>
      setQueryData(old => {
        if (isDeepEqual(old, req)) return old;
        return req;
      }),
    [],
  );

  const queryResult = useQuery<TResult, TError>(queryConfig);

  const { error, isFetched } = queryResult as any;

  useEffect(() => {
    if (error) {
      const relativeUrl = error?.config?.url?.slice(
        window.location.origin.length,
      );
      const msg =
        error.response?.data?.detail ?? error.response?.data?.toString();
      errorToast({
        title: msg ?? error.message.toString(),
        description: msg ? '' : relativeUrl,
        position: 'top-left',
      });
    }
  }, [error, isFetched]);

  return {
    ...queryResult,
    sync,
  };
}
