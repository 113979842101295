import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { map, values } from 'lodash';
import { colorMap } from 'theme';

import { resourceIcons } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { MapType } from 'containers/Visibility/types';

import { colors } from '../../../../../theme/theme2/colors';
import { limitedString } from '../../../../../utils/string';

import { ROLE_POLICY_NODE } from './nodes_types';

export function registerRolePolicyNode() {
  G6.registerNode(
    ROLE_POLICY_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 200,
              height: 46,
              fill: colors.white,
              stroke: colorMap.primary(200),
              lineWidth: 0.5,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        group.addShape('rect', {
          attrs: {
            x,
            y,
            width: 20,
            height: 100,
            stroke: '',
          },
          name: 'id',
        });

        // @ts-ignore
        const mapType = cfg?.data?.mapType;
        let img = '';
        const data: any = cfg?.data;

        // @ts-ignore
        if (cfg?.data?.resource_type === 'Role') {
          img = resourceIcons.role1;
        } else {
          img = resourceIcons.policies;
        }

        group.addShape('image', {
          attrs: {
            x: x + 13,
            y: y + 13,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        group.addShape('text', {
          attrs: {
            x: width / 2 - 5,
            y: height / 2 - 5,
            // @ts-ignore
            text: cfg?.data?.resource_type,
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'role',
        });

        let sum =
          // @ts-ignore
          cfg?.data?.total_permission_access_counts &&
          values(
            // @ts-ignore
            cfg?.data?.total_permission_access_counts,
            // @ts-ignore
          ).reduce((a, b) => a + b);

        group.addShape('text', {
          attrs: {
            x: width / 2 - 30,
            y: height / 2 + 15,
            text: `[${
              // @ts-ignore
              cfg?.data?.total_permission_access_counts?.total ?? '-'
            } permissions]`,
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'label',
        });

        if (
          mapType === MapType.ResourceExcessPermissions ||
          mapType === MapType.ResourceNetAccess ||
          mapType === MapType.ResourceBehavior ||
          mapType === MapType.ResourceConnectedEntities
        ) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 80,
              // @ts-ignore
              text: `Name: ${limitedString(cfg?.data?.name ?? '-')}`,
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          // @ts-ignore

          // group.addShape('text', {
          //   attrs: {
          //     x: x,
          //     y: y + 100,
          //     text: `Excess Permissions: ${
          //       // @ts-ignore
          //       cfg?.data?.excess_permission_access_counts.Read ||
          //       0 +
          //         // @ts-ignore
          //         cfg?.data?.excess_permission_access_counts.List ||
          //       0 +
          //         // @ts-ignore
          //         cfg?.data?.excess_permission_access_counts.Write ||
          //       0 +
          //         // @ts-ignore
          //         cfg?.data?.excess_permission_access_counts?.[
          //           'Permissions management'
          //         ] ||
          //       0 +
          //         // @ts-ignore
          //         cfg?.data?.excess_permission_access_counts?.['Tagging'] ||
          //       0
          //     }`,
          //     fontSize: 12,
          //     // fontStyle: 'bold',
          //     opacity: 1,
          //     fill: '#000',
          //     cursor: 'pointer',
          //   },
          //   name: 'name',
          // });

          // @ts-ignore
          cfg?.data?.nodeType === 'Behaviour' &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 140,
                // @ts-ignore
                text: `ID: ${limitedString(cfg?.data?.id)}`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

          // @ts-ignore
          cfg?.data?.nodeType === 'User' &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 100,
                text: `Operation: ${map(
                  // @ts-ignore
                  cfg?.data?.total_permission_access_counts,
                  (value, key) => key,
                )}`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

          // @ts-ignore
          cfg?.data?.nodeType === 'Behaviour' &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 120,
                // @ts-ignore
                text: `Resource Types: ${cfg?.data?.connected_resource_type_count}`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
        } else if (
          mapType === MapType.RoleExcessPermissions ||
          mapType === MapType.RoleNetAccess ||
          mapType === MapType.RoleBehaviour
        ) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 75,
              textAlign: 'left',
              textBaseline: 'start',
              text:
                'Name:   ' +
                // @ts-ignore
                `${limitedString(cfg?.data?.name) ?? '-'}`,
              fontSize: 12,
              opacity: 1,
              fill: colors.black,
            },
          });
          group.addShape('text', {
            attrs: {
              x: 0,
              y: y + 95,
              text: `Excess Permissions: ${
                // @ts-ignore
                cfg?.data?.unused_permissions?.unused ?? '-'
              }`,
              // text: `Excess Permissions: ${cfg?.data?.excess_permissions}`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'id',
          });
          // @ts-ignore
          cfg?.data?.mapType !== MapType.RoleBehaviour &&
            group.addShape('text', {
              attrs: {
                x: 0,
                y: y + 115,
                text: `Excess Policies: ${
                  // @ts-ignore
                  cfg?.data?.unused_policies?.unused ?? '-'
                }`,
                // text: `Excess Permissions: ${cfg?.data?.excess_permissions}`, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'id',
            });

          // @ts-ignore
          cfg?.data?.mapType === MapType.RoleBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 135,
                // @ts-ignore
                text: `ID: ${
                  // @ts-ignore
                  limitedString(cfg?.data?.resource_id)
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

          // @ts-ignore
          cfg?.data?.mapType === MapType.RoleBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 115,
                // @ts-ignore
                text: `Resource Types: ${
                  // @ts-ignore
                  cfg?.data?.connected_resource_type_count ?? '-'
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
        } else if (
          mapType === MapType.MachineExcessPermissions ||
          mapType === MapType.MachineNetAccess ||
          mapType === MapType.MachineBehaviour
        ) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 80,
              // @ts-ignore
              text: `Name: ${
                // @ts-ignore
                cfg?.data?.name
                  ? // @ts-ignore
                    `${cfg?.data?.name.slice(0, 25)} ${
                      // @ts-ignore
                      cfg?.data?.name.length > 25 ? '...' : ''
                    }`
                  : '-'
              }`,
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          // @ts-ignore
          let excessSum: any = 0;
          excessSum =
            // @ts-ignore
            cfg?.data?.excess_permission_access_counts &&
            values(
              // @ts-ignore
              cfg?.data?.excess_permission_access_counts,
              // @ts-ignore
            ).reduce((a, b) => a + b);

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 100,
              text: `Excess Permissions: ${excessSum ?? '-'}`,
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          // @ts-ignore
          cfg?.data?.mapType === MapType.MachineBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 140,
                // @ts-ignore
                text: `ID: ${
                  // @ts-ignore
                  cfg?.data?.id
                    ? // @ts-ignore
                      `${cfg?.data?.id.slice(0, 25)} ${
                        // @ts-ignore
                        cfg?.data?.id.length > 25 ? '...' : ''
                      }`
                    : '-'
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

          // @ts-ignore
          cfg?.data?.nodeType === 'User' &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 100,
                text: `Operation: ${map(
                  // @ts-ignore
                  cfg?.data?.total_permission_access_counts,
                  (value, key) => key,
                )}`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
          // @ts-ignore
          cfg?.data?.mapType === MapType.MachineBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 120,
                text: `Resource Types: ${
                  // @ts-ignore
                  cfg?.data?.connected_resource_type_count || '-'
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
        } else if (
          mapType === MapType.UserExcessPermissions ||
          mapType === MapType.UserNetAccess ||
          mapType === MapType.UserBehaviour
        ) {
          const sum =
            // @ts-ignore
            cfg?.data?.total_permission_access_counts &&
            values(
              // @ts-ignore
              cfg?.data?.total_permission_access_counts,
              // @ts-ignore
            ).reduce((a, b) => a + b);

          // group.addShape('text', {
          //   attrs: {
          //     x: width / 2 - 30,
          //     y: height / 2 + 15,
          //     text: `[${sum} permissions 5]`,
          //     fontSize: 12,
          //     opacity: 1,
          //     fill: colors.black,
          //     cursor: 'pointer',
          //   },
          //   name: 'label',
          // });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 80,
              // @ts-ignore
              text: `Name: ${
                // @ts-ignore
                cfg?.data?.name
                  ? // @ts-ignore
                    `${cfg?.data?.name.slice(0, 25)} ${
                      // @ts-ignore
                      cfg.data?.name.length > 20 ? '...' : ''
                    }`
                  : '-'
              }`,
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 100,
              text: `Excess Permissions: ${
                // @ts-ignore
                cfg?.data?.excess_permission_access_counts?.total ?? '-'
              }`,
              fontSize: 12,
              // fontStyle: 'bold',
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          // @ts-ignore
          cfg?.data?.mapType === MapType.UserBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 140,
                // @ts-ignore
                text: `ID: ${
                  // @ts-ignore
                  cfg?.data?.id
                    ? // @ts-ignore
                      `${cfg?.data?.id.slice(0, 25)} ${
                        // @ts-ignore
                        cfg?.data?.id.length > 25 ? '...' : ''
                      }`
                    : '-'
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

          // @ts-ignore
          cfg?.data?.nodeType === 'User' &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 100,
                text: `Operation: ${map(
                  // @ts-ignore
                  cfg?.data?.total_permission_access_counts,
                  (value, key) => key,
                )}`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
          // @ts-ignore
          cfg?.data?.mapType === MapType.UserBehaviour &&
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 120,
                text: `Resource Types: ${
                  // @ts-ignore
                  cfg?.data?.connected_resource_type_count ?? '-'
                }`,
                fontSize: 12,
                // fontStyle: 'bold',
                opacity: 1,
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
        }
        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
