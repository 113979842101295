import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, WrapItem, Wrap } from '@chakra-ui/react';
import { map, filter, includes } from 'lodash';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { selectSharedState } from 'containers/SharedState/selectors';

import { useEnvironmentContext } from '../../../../context';
import { selectEnvironment } from '../../../../selectors';

export const RegionCriteria: FC = () => {
  const { formData } = useSelector(selectEnvironment);
  const { regions } = useSelector(selectSharedState);
  const { updateCriteria } = useEnvironmentContext();
  const { criteria } = formData;

  const [selectedRegions, setSelectedRegions] = useState<Record<string, any>[]>(
    [],
  );
  const [regionOptions, setRegionOptions] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setRegionOptions(map(regions.data, o => ({ label: o, value: o })));
  }, [regions]);

  useEffect(() => {
    setSelectedRegions(
      filter(regionOptions, o => includes(criteria?.region, o.value)),
    );
  }, [regionOptions, criteria?.region]);

  return (
    <Stack>
      <Stack isInline w="full">
        <Box whiteSpace="nowrap" pt={1}>
          If an entity has region
        </Box>
        <Box minW={48}>
          <Select
            options={regionOptions}
            isMulti
            value={selectedRegions}
            onChange={selected =>
              updateCriteria(
                'region',
                map(selected, o => o.value),
              )
            }
            showTotalSelected={!!selectedRegions.length}
            isLoading={regions.isLoading}
          />
        </Box>
      </Stack>
      <Wrap>
        {map(criteria?.region, o => (
          <WrapItem>
            <Tag
              label={o}
              closeButton
              onClose={() =>
                updateCriteria(
                  'region',
                  filter(criteria?.region, l => l !== o),
                )
              }
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
