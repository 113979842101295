import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { getIconColor } from 'components/DataDisplay/Utils';
import { resourceIconsWhite, iconSwitchWhite } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { MapType } from 'containers/Visibility/types';
import { limitedString } from 'utils/string';

import { colorMap } from '../../../../../theme';

import { RESOURCE_INSTANCE_NODE } from './nodes_types';

export function registerResourceInstanceNode() {
  G6.registerNode(
    RESOURCE_INSTANCE_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        // @ts-ignore
        const data: any = cfg?.data ?? {};
        const color = getIconColor(data?.service ?? data?.resource_type);

        const container = group.addShape(
          'circle',
          {
            attrs: {
              x: 30,
              y: 30,
              fill: data?.hasTraffic ? colorMap.primary() : color,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              r: 22,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();
        // @ts-ignore
        const mapType = data?.mapType;

        const { resource_type: resourceType } = data;

        // @ts-ignore
        const isLastNode = data?.isLastNode;

        // @ts-ignore
        let img = iconSwitchWhite(
          data?.resource_type ?? '',
          resourceIconsWhite,
        );

        group.addShape('image', {
          attrs: {
            x: x + 12,
            y: y + 12,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        const addText = ({ text, x, y }) => {
          group.addShape('text', {
            attrs: {
              x,
              y,
              // @ts-ignore
              text,
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'type',
          });
        };

        if (mapType === MapType.ResourceConnectedEntities) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 60 + 10,
              // @ts-ignore
              text: 'Type:   ' + cfg?.data?.resource_type, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'type',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 80 + 10,
              textAlign: 'left',
              // @ts-ignore
              text: 'Name: ' + limitedString(cfg?.data?.name || '-'), // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'name',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 100 + 10,
              textAlign: 'left',
              // @ts-ignore
              text: 'ID:   ' + `${cfg?.data?.resource_id}`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'guid',
          });
        } else if (
          mapType === MapType.ResourceExcessPermissions ||
          mapType === MapType.ResourceNetAccess ||
          mapType === MapType.ResourceBehavior
        ) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 70,
              // @ts-ignore
              text: `Name: ${cfg?.data?.name ? cfg?.data?.name : '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'name',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 90,
              text: `Excess Permissions: ${
                // @ts-ignore
                cfg?.data?.excess_permisions
                  ? // @ts-ignore
                    cfg?.data?.excess_permisions
                  : '-'
              }`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'permissions',
          });
        } else if (
          mapType === MapType.UserBehaviour ||
          mapType === MapType.UserNetAccess
        ) {
          group.addShape('text', {
            attrs: {
              x: x + 50,
              y: y + 5,
              // @ts-ignore
              text: 'Type:  ' + cfg?.data?.resource_type, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'type',
          });

          group.addShape('text', {
            attrs: {
              x: x + 50,
              y: y + 25,
              // @ts-ignore
              text: 'Name:   ' + cfg?.data?.name, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'name',
          });

          group.addShape('text', {
            attrs: {
              x: x + 50,
              y: y + 45,
              // @ts-ignore
              text: 'ID:   ' + cfg?.data?.id, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
            },
            name: 'guid',
          });
        } else if (
          mapType === MapType.MachineExcessPermissions ||
          mapType === MapType.MachineNetAccess ||
          mapType === MapType.MachineBehaviour
        ) {
          // @ts-ignore
          if (isLastNode) {
            group.addShape('text', {
              attrs: {
                x: x + 60,
                y: y + 10,
                // @ts-ignore
                text: 'Name: ' + (cfg?.data?.name ?? '-'), // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'type',
            });

            group.addShape('text', {
              attrs: {
                x: x + 60,
                y: y + 30,
                // @ts-ignore
                text: cfg?.data?.id ?? '-', // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });
          } else {
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 70,
                // @ts-ignore
                text: `Name: ${cfg?.data?.name ?? '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                opacity: 1,
                // fontStyle: 'bold',
                fill: colors.black,
                cursor: 'pointer',
              },
              name: 'name',
            });

            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 90,
                text: `Excess Roles: ${
                  // @ts-ignore
                  cfg?.data?.unused_roles?.unused || '-'
                }`, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                opacity: 1,
                // fontStyle: 'bold',
                fill: colors.black,
                cursor: 'pointer',
              },
              name: 'roles',
            });

            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 110,
                text: `Excess Policies: ${
                  // @ts-ignore
                  cfg?.data?.unused_policies?.unused || '-'
                }`, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                opacity: 1,
                // fontStyle: 'bold',
                fill: colors.black,
                cursor: 'pointer',
              },
              name: 'policies',
            });

            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 130,
                text: `Excess Permissions: ${
                  // @ts-ignore
                  cfg?.data?.unused_permissions?.unused || '-'
                }`, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                opacity: 1,
                // fontStyle: 'bold',
                fill: colors.black,
                cursor: 'pointer',
              },
              name: 'permissions',
            });
          }
        } else if (mapType === MapType.RoleBehaviour) {
          addText({
            x: x + 50,
            y: y + 5,
            text: 'Name: ' + (data?.name ?? '-'),
          });
          addText({
            x: x + 50,
            y: y + 25,
            text: 'GUID: ' + (data?.id ?? '-'),
          });
          addText({
            x: x + 50,
            y: y + 45,
            text: 'ARN: ' + (data?.arn ?? '-'),
          });
        } else if (mapType === MapType.Network) {
          if (resourceType === 'Gateway') {
            addText({
              x: x,
              y: y + 65,
              text: `${data?.resource_id ?? '~'}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Region: ${data?.region ?? '-'}`,
            });
            addText({
              x: x,
              y: y + 105,
              text: `Subnets: ${'~'}`,
            });
          }

          if (resourceType === 'Route' || resourceType === 'Local') {
            addText({
              x: x,
              y: y + 65,
              text: resourceType,
            });
            let id = '-';
            if (data?.resource_id) {
              const resource_id = (data?.resource_id).split('-');
              id = resource_id[resource_id?.length - 1];
            }
            addText({
              x: x,
              y: y + 85,
              text: id,
            });
          }

          if (resourceType === 'NetworkAttachment') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '-')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Routes: ${data?.routes ?? '~'}`,
            });
          }

          if (resourceType === 'RouteTable') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '~')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Routes: ${data?.route ?? '~'}`,
            });
            addText({
              x: x,
              y: y + 105,
              text: `NACLs: ${data?.nacl_count ?? '~'}`,
            });
          }

          if (resourceType === 'SuperNet') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '~')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Region: ${data?.region ?? '~'}`,
            });
            addText({
              x: x,
              y: y + 105,
              text: `Subnets: ${data?.subnets ?? '~'}`,
            });
          }

          if (resourceType === 'Subnet') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '~')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Routes: ${data?.routes ?? '~'}`,
            });
            addText({
              x: x,
              y: y + 105,
              text: `Host IPs: ${data?.subnets ?? '~'}`,
            });
          }

          if (resourceType === 'Access') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '~')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Subnets: ${data?.subnets ?? '~'}`,
            });
          }

          if (resourceType === 'Access') {
            addText({
              x: x,
              y: y + 65,
              text: `${limitedString(data?.name ?? data?.resource_id ?? '~')}`,
            });
            addText({
              x: x,
              y: y + 85,
              text: `Subnets: ${data?.subnets ?? '~'}`,
            });
          }
        }

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
