import React, { FC } from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions as clientActions } from '../../slice';

import { SMSForm } from './SMSForm';

export const EditSMS: FC = () => {
  const { onCancel, currentRecord } = useIntegrationsContext();
  const dispatch = useDispatch();

  const onSubmit = data => {
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name: data.name,
        status: true,
        sns: {
          account_id: data.account?.value,
          topic_arn: data?.topic?.value?.resource_id,
          region: data?.topic?.value?.region,
        },
      },
    };
    dispatch(
      clientActions.updateClientById({
        q: payload,
        onSuccess: () => onCancel(),
      }),
    );
  };

  return <SMSForm handleSubmit={onSubmit} />;
};
