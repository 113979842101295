import React, { FC, useEffect, useState } from 'react';

import { Entities } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';
import { map, startCase, toLower, filter, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions as policyHubActions } from 'containers/PolicyHub/slice';

export const AlertCategories: FC = () => {
  const { alertConditions } = useSelector(selectAlertWorkflow);
  const { alertCategories } = useSelector(selectPolicyHub);
  const [categories, setCategories] = useState<Record<string, any>[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    Record<string, any>[]
  >([]);
  const dispatch = useDispatch();

  /*get alert categories*/
  useEffect(() => {
    dispatch(policyHubActions.getAlertCategories({ q: {} }));
  }, [dispatch]);

  /*set alert category options*/
  useEffect(() => {
    setCategories(
      map(alertCategories.data, each => ({
        label: startCase(toLower(each)),
        value: each,
      })),
    );
  }, [alertCategories.data]);

  useEffect(() => {
    setSelectedCategories(
      filter(categories, o =>
        includes(alertConditions[Entities.Category]?.entity_list, o.value),
      ),
    );
  }, [categories, alertConditions]);

  return (
    <Stack w="full" isInline>
      <Box whiteSpace="nowrap" pt={1}>
        If alert or policy belongs to category
      </Box>
      <Box minW={48}>
        <Select
          options={categories}
          isMulti
          value={selectedCategories}
          onChange={selected =>
            dispatch(
              actions.updateAlertWorkflowConditionByID({
                name: Entities.Category,
                entity_list: map(selected, each => each.value),
              }),
            )
          }
        />
      </Box>
    </Stack>
  );
};
