import React from 'react';

import { Icon } from '@chakra-ui/react';

export const HealthIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      d="M17.25 7.00017C17.25 6.58595 16.9142 6.25017 16.5 6.25017C16.0858 6.25017 15.75 6.58595 15.75 7.00017V8.25018H14.5C14.0858 8.25018 13.75 8.58597 13.75 9.00018C13.75 9.4144 14.0858 9.75018 14.5 9.75018L15.75 9.75018V11.0002C15.75 11.4144 16.0858 11.7502 16.5 11.7502C16.9142 11.7502 17.25 11.4144 17.25 11.0002V9.75018H18.5C18.9142 9.75018 19.25 9.4144 19.25 9.00018C19.25 8.58597 18.9142 8.25018 18.5 8.25018H17.25V7.00017Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.75 9.31763C22.75 5.99224 21.2676 3.50983 18.9609 2.60661C16.8252 1.77035 14.2618 2.39603 12 4.51334C9.73815 2.39603 7.17479 1.77035 5.0391 2.60662C2.73242 3.50984 1.25 5.99226 1.25 9.31767C1.25 11.4356 2.38041 13.5198 3.78729 15.3141C5.20863 17.1269 6.99671 18.7501 8.48914 19.9612L8.62327 20.0701C9.82386 21.0458 10.6906 21.7502 12 21.7502C13.3094 21.7502 14.1762 21.0458 15.3767 20.0702L15.5109 19.9612C17.0033 18.7501 18.7914 17.1269 20.2127 15.3141C21.6196 13.5198 22.75 11.4356 22.75 9.31763ZM12.5478 6.08652C14.6596 3.82795 16.8491 3.39059 18.414 4.00335C19.9823 4.61747 21.25 6.41304 21.25 9.31763C21.25 10.9291 20.3707 12.6816 19.0323 14.3886C17.7084 16.0772 16.0156 17.6199 14.5657 18.7965C13.1731 19.9265 12.7229 20.2502 12 20.2502C11.2771 20.2502 10.8269 19.9265 9.43432 18.7964C7.98445 17.6199 6.29166 16.0771 4.96771 14.3886C3.62931 12.6816 2.75 10.929 2.75 9.31767C2.75 6.41306 4.01766 4.61747 5.58602 4.00336C7.15092 3.39059 9.34039 3.82795 11.4522 6.08652C11.594 6.2382 11.7923 6.32429 12 6.32429C12.2077 6.32429 12.406 6.2382 12.5478 6.08652Z"
      fill="currentColor"
    />
  </Icon>
);
