import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RouteTableIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M510.4,349c1,0.8,1.6,2,1.6,3.3c0,1.3-0.6,2.5-1.6,3.3l-103,79.2c-1.5,1.1-3.6,1.2-5.1,0
	c-1.5-1.1-2.1-3.1-1.4-4.9l17.2-43.4c-78-2.9-122.2-30.8-153.1-63.1c11.5-13.1,21.1-26.4,29.8-38.6c4.3-6,8.5-11.8,12.6-17.3
	c23.5,27.6,52,47.3,110.5,50l-16.9-42.8c-0.7-1.8-0.1-3.8,1.4-4.9c0.7-0.6,1.6-0.8,2.5-0.8c0.9,0,1.8,0.3,2.6,0.9L510.4,349z
	 M104.6,242.1c0.8,0.6,1.7,0.9,2.6,0.9c0.9,0,1.8-0.3,2.5-0.9c1.5-1.1,2.1-3.1,1.4-4.9l-16.8-42.5c58.2,3.7,86.8,23.8,110.4,51.5
	c4.1-5.4,8.2-11.1,12.5-17c8.8-12.2,18.4-25.6,30-38.8c-31-32.5-75.2-60.6-153.3-64.7L111.1,82c0.7-1.8,0.1-3.8-1.4-4.9
	c-1.5-1.1-3.6-1.1-5.1,0l-103,79.2c-1,0.8-1.6,2-1.6,3.3c0,1.3,0.6,2.5,1.6,3.3L104.6,242.1z M283.9,277.2
	c30.8-42.9,55.6-77.4,133.8-82.4l-16.8,42.5c-0.7,1.8-0.1,3.8,1.4,4.9c0.8,0.6,1.6,0.9,2.5,0.9c0.9,0,1.8-0.3,2.6-0.9l103-79.2
	c1-0.8,1.6-2,1.6-3.3c0-1.3-0.6-2.5-1.6-3.3l-103-79.2c-1.5-1.2-3.6-1.2-5.1,0c-1.5,1.1-2.1,3.1-1.4,4.9l17.3,43.7
	c-114.7,6-156.5,64.1-190.3,111.3c-30.7,42.8-55.3,77-133.7,80.6l16.9-42.8c0.7-1.8,0.1-3.8-1.4-4.9c-0.7-0.6-1.6-0.8-2.5-0.8
	c-0.9,0-1.8,0.3-2.6,0.9L1.6,349c-1,0.8-1.6,2-1.6,3.3c0,1.3,0.6,2.5,1.6,3.3l103,79.2c1.5,1.1,3.6,1.2,5.1,0
	c1.5-1.1,2.1-3.1,1.4-4.9l-17.2-43.4C208.5,382.3,250.2,324.2,283.9,277.2z"
    />
  </Icon>
);
