import { useEffect } from 'react';

import { Application, ICanvas } from 'pixi.js';

import { downloadMap } from 'components/Visualization/PixiGraph/utils';
import { useBus } from 'hooks/useBus';

export const useDownloadMap = (app: Application<ICanvas>, name: string) => {
  const bus = useBus();

  useEffect(() => {
    const onDownload = async () => {
      await downloadMap(app, name);
    };
    bus.on('download:security-graph', onDownload);

    return () => {
      bus.off('download:security-graph', onDownload);
    };
  }, [app, bus, name]);
};
