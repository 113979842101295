import { createContext, useContext } from 'react';

import { UserPermission } from 'containers/App/hooks/permissions/UserPermission';

interface FilterRouterContextProps {
  userPermission: UserPermission;
}

export const UserPermissionsContext = createContext<FilterRouterContextProps>({
  userPermission: new UserPermission(),
});

export const UserPermissionsProvider = UserPermissionsContext.Provider;

export const useUserPermissionsContext = () =>
  useContext(UserPermissionsContext);
