import { useCallback, useEffect } from 'react';

import { SearchTermCategory } from '@ariksa/inventory-core';
import { each, isEmpty, isEqual, union } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  pathToDirectedEdges,
  relationshipsToEdges,
  relationshipToDirectedEdges,
  reverseEdges,
} from 'components/Visualization/Graphs/utils/path';
import { ContextFilterItemEntry } from 'containers/Visibility/Components/Filter/ContextFilterItem';
import { ResourceFilterItemEntry } from 'containers/Visibility/Components/Filter/ResourceFilterItem';
import {
  filerGraphByContextFilters,
  filterNodesByResource,
} from 'containers/Visibility/SecurityGraph/utils/filter';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSecurityGraphContext } from 'containers/Visibility/SecurityGraphNext/hooks/useSecurityGraphContext';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import {
  NodeId,
  ResourceTypeFilterItemEntry,
  SecurityGraphMapType,
} from 'containers/Visibility/SecurityGraphNext/types';
import { isResourceNode } from 'containers/Visibility/SecurityGraphNext/utils/node';

export const useGraphFilters = () => {
  const dispatch = useDispatch();
  const { searchResult } = useSearchResult();
  const { filters, mapType, showVulnerability } = useSelector(
    selectSecurityGraphNext,
  );
  const { setDirty } = useSecurityGraphContext();

  const updateFilters = useCallback(
    (
      type: string,
      newFilters: (
        | ContextFilterItemEntry
        | ResourceFilterItemEntry
        | ResourceTypeFilterItemEntry
      )[],
    ) => {
      if (!isEqual(newFilters, filters[type])) {
        dispatch(
          actions.updateFilters({
            [type as any]: newFilters,
          }),
        );

        setDirty();
      }
    },
    [dispatch, setDirty],
  );

  const handleToggleAlerting = (e: any) => {
    dispatch(
      actions.updateEnrichInsights({
        alertingEntities: e.target.checked,
      }),
    );
  };

  // set the resource type filter when the search result is loaded and the result has only one target node
  useEffect(() => {
    if (searchResult.data?.category === SearchTermCategory.Permission) {
      const { nodes } = searchResult.data;
      const targetNodes = nodes?.filter(n => n.is_target) ?? [];
      if (targetNodes.length === 1) {
        const targetNode = targetNodes[0];
        const filter = [
          {
            key: targetNode.identity,
            resourceType: targetNode.native_name!,
            resourceId: targetNode.identity,
          },
        ];

        updateFilters('resource', filter);
      }
    }
  }, [searchResult.data, updateFilters]);

  /*const handleToggleContext = useCallback(
  e => {
    const { nodes = [] } = search.data ?? {};
    dispatch(
      actions.updateEnrichInsights({
        showContext: e.target.checked,
      }),
    );

    if (e.target.checked) {
      dispatch(
        sharedStateActions.getRiskContext({
          q: {
            uuid: (nodes
              ?.filter(
                node => node.native_name === NativeResources.Ec2Instance,
              )
              .map(node => node.uuid) ?? []) as string[],
          },
        }),
      );
    } else {
      dispatch(
        sharedStateActions.getRiskContext({
          q: {
            uuid: ['x'],
          },
        }),
      );
    }
  },
  [dispatch, search.data],
);*/

  const updateHideNodeIds = useCallback(
    (ids: Set<NodeId>) => {
      dispatch(actions.updateHiddenNodeIds(ids));
    },
    [dispatch],
  );

  //set hide ids by resource or context
  useEffect(() => {
    let hideIdsByContextFilter: string[] = [];
    let hideIdsByResourceFilter: string[] = [];
    const { context, resource, resourceType } = filters;

    // apply context filter
    if (!!context?.length) {
      const { nodes = [], relationships = [] } = searchResult.data ?? {};
      const forwardEdges = relationshipsToEdges(relationships);
      const backwardEdges = reverseEdges(forwardEdges);
      hideIdsByContextFilter = filerGraphByContextFilters(
        nodes,
        forwardEdges,
        backwardEdges,
        context,
      );
      dispatch(
        actions.updateEnrichInsights({
          filteringByContext: !!hideIdsByContextFilter.length,
        }),
      );
    }

    // apply resource filter
    if (!!resource?.length) {
      const { nodes = [], relationships = [], paths = [] } =
        searchResult.data ?? {};
      const visible = new Set();
      const edges = isEmpty(relationships)
        ? pathToDirectedEdges(paths)
        : relationshipToDirectedEdges(relationships);

      resource.forEach(filter => {
        const filteredNodes = filterNodesByResource(nodes, edges, filter);
        each(filteredNodes, n => visible.add(n.identity));
      });

      hideIdsByResourceFilter = nodes
        .map(n => n.identity)
        .filter(nid => !visible.has(nid));
    }

    const { nodes = [] } = searchResult.data ?? {};

    const hideIds = union(hideIdsByContextFilter, hideIdsByResourceFilter);
    const resourceNodes = nodes?.filter(isResourceNode);

    // NOTE: resource type filter is only applicable for access map
    // apply resource type filter
    if (!!resourceType?.length && mapType === SecurityGraphMapType.Access) {
      // show resource nodes only if they are not filtered out by resource type
      const showIds = new Set();

      resourceType.forEach(filter => {
        resourceNodes
          .filter(n => n.native_name === filter.resourceType)
          .forEach(n => showIds.add(n.identity));
      });

      // hide resource nodes that are not in showIds
      resourceNodes.forEach(n => {
        if (!showIds.has(n.identity)) {
          hideIds.push(n.identity);
        }
      });
    } else {
      // hide all resource nodes
      const { nodes = [] } = searchResult.data ?? {};
      const filteredNodes = nodes.filter(isResourceNode);
      hideIds.push(...filteredNodes.map(n => n.identity));
    }

    if (!showVulnerability) {
      nodes?.forEach(n => {
        if (
          n.native_name === 'Endpoint' ||
          n.native_name === 'OS' ||
          n.native_name === 'Technology' ||
          n.native_name === 'Severity'
        ) {
          hideIds.push(n.identity);
        }
      });
    }

    updateHideNodeIds(new Set(hideIds));
  }, [
    dispatch,
    updateHideNodeIds,
    searchResult.data,
    filters,
    mapType,
    showVulnerability,
  ]);

  return {
    resourceFilters: filters.resource,
    setResourceFilters: updateFilters.bind(null, 'resource'),
    contextFilters: filters.context,
    setContextFilters: updateFilters.bind(null, 'context'),
    resourceTypeFilters: filters.resourceType,
    setResourceTypeFilters: updateFilters.bind(null, 'resourceType'),
    updateHideNodeIds,
    handleToggleAlerting,
  };
};
