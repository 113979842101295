import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { filter, find, includes, map } from 'lodash';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { tagKeyConditionOptions } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/utils';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props {}

export const TagKeysCriteria: FC<Props> = () => {
  const { updateCriteria, tagKeyOptions } = useWhitelistContext();
  const { formData } = useSelector(selectWhitelist);
  const { tagKeys } = useSelector(selectSharedState);
  const { criteria } = formData;
  const [selectedKeys, setSelectedKeys] = useState<Record<string, any>[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<
    Record<string, any>
  >(tagKeyConditionOptions[0]);

  //set keys
  useEffect(() => {
    //criteria?.tag_keys_condition &&
    setSelectedKeys(
      filter(tagKeyOptions, o =>
        includes(criteria?.tag_keys_condition?.key, o.label),
      ),
    );
  }, [criteria?.tag_keys_condition, tagKeyOptions]);

  //set key condition
  useEffect(() => {
    //criteria?.tag_keys_condition &&
    setSelectedCondition(
      find(
        tagKeyConditionOptions,
        o => o?.value === criteria?.tag_keys_condition?.key_condition,
      ) ?? tagKeyConditionOptions[0],
    );
  }, [criteria?.tag_keys_condition, tagKeyOptions]);

  return (
    <Stack w="full">
      <Stack isInline w="full">
        <Box whiteSpace="nowrap" pt={1}>
          If an entity
        </Box>
        <Box w={48}>
          <Select
            options={tagKeyConditionOptions}
            value={selectedCondition}
            onChange={selected => setSelectedCondition(selected)}
          />
        </Box>
        <Box whiteSpace="nowrap" pt={1}>
          with tag key
        </Box>
        <Box w="full" flex={1}>
          <Select
            options={tagKeyOptions}
            isMulti
            value={selectedKeys}
            showTotalSelected={!!selectedKeys.length}
            onChange={selected => {
              updateCriteria('tag_keys_condition', {
                key_condition: selectedCondition?.value,
                key: map(selected, o => o?.value),
              });
            }}
            isLoading={tagKeys.isLoading}
          />
        </Box>
      </Stack>
      <Wrap>
        {map(criteria?.tag_keys_condition?.key, o => (
          <WrapItem>
            <Tag
              label={o}
              closeButton
              styles={{ label: { whiteSpace: 'pre-wrap' } }}
              onClose={() =>
                updateCriteria('tag_keys_condition', {
                  key: filter(criteria?.tag_keys_condition?.key, l => l !== o),
                  key_condition: selectedCondition?.value,
                })
              }
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
