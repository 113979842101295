import React from 'react';

import { ComplianceStandards } from '@ariksa/compliance-policies';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { includes, map } from 'lodash';

import {
  CloseCircleOutlineIcon,
  CheckmarkCircleOutlineIcon,
  CardChecklistIcon,
  IconTypes,
} from 'app/components/Icons';
import { renderTooltipFieldValue, StackedCell } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getIcon } from 'components/Icons/Components';
import { renderCustomTag } from 'containers/PolicyHub/Blueprints/Components/utils';

import { formatDate } from '../../../utils/date';
import { limitedString, randomString } from '../../../utils/string';

// decide blackIcons based on blueprint name
export const getBlueprintIcon = (
  isCustom: boolean,
  compliance?: ComplianceStandards | string,
  mode?: string,
) => {
  // default icon
  if (isCustom && !includes(ComplianceStandards, compliance)) {
    return <CardChecklistIcon size={35} color={'primary'} />;
  }

  return (
    <Box
      color={compliance === IconTypes.AriksaBestPractices ? 'black' : 'primary'}
    >
      {getIcon(compliance!)}
    </Box>
  );
};

export const formatBoolean = (value: boolean, boxSize?: number) => {
  if (value)
    return (
      <Box
        as={CheckmarkCircleOutlineIcon}
        boxSize={boxSize ?? 5}
        color="green.300"
      />
    );
  else
    return (
      <Box as={CloseCircleOutlineIcon} boxSize={boxSize ?? 5} color="red.300" />
    );
};

export const queryId = () => {
  return generateQueryId(`query`);
};

export const queryResourceId = () => {
  return `resource__${randomString()}`;
};

export const queryThatId = () => {
  return `that__${randomString()}`;
};

export const queryHavingId = () => {
  return `having__${randomString()}`;
};

export const queryRelationshipId = () => {
  return `relationship__${randomString()}`;
};

export const queryResourceAttributeId = () => {
  return generateQueryId(`resource-attribute`);
};

export const queryResourceAttributeListId = () => {
  return generateQueryId(`resource-attribute-list`);
};

export const queryRelationshipAttributeId = () => {
  return generateQueryId(`relationship-attribute`);
};

const generateQueryId = (name: string) => {
  return `${name}__${randomString()}`;
};

export const renderPolicyContext = ({ value }) => {
  return !!value?.length ? (
    <CustomTooltip
      label={
        <Stack>
          {map(value, o => (
            <Stack spacing={0}>
              <Box color="orange">{o?.key}</Box>
              <Box>{o?.value}</Box>
            </Stack>
          ))}
        </Stack>
      }
    >
      {value?.length}
    </CustomTooltip>
  ) : (
    '0'
  );
};

export const renderPolicyCreated = ({ row }) => (
  <CustomTooltip
    label={
      <Stack>
        {renderTooltipFieldValue('Created By', row?.created_by || 'Ariksa')}
        {renderTooltipFieldValue(
          'Type',
          !!row?.created_by && row?.created_by !== 'system'
            ? 'Custom'
            : 'Ariksa',
        )}
        {renderTooltipFieldValue('Created', formatDate(row.created_at))}
      </Stack>
    }
  >
    <StackedCell
      upper={
        <HStack>
          <Box>{limitedString(row?.created_by || 'Ariksa', 13)}</Box>
          {renderCustomTag(row?.created_by)}
        </HStack>
      }
      lower={formatDate(row.created_at)}
      showUpperTooltip={false}
      showLowerTooltip={false}
    />
  </CustomTooltip>
);
