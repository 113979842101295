/**
 *
 * EventLog
 *
 */

import React, { memo, useCallback } from 'react';

import { Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { selectApp } from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import { EventLogHeader } from 'containers/EventLog/EventLogHeader';
import { selectEventLog } from 'containers/EventLog/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { formatDate, utcFormatForBackend } from 'utils/date';
import { useInjector } from 'utils/inject';

import { DATE_TIME_FORMAT } from '../../../utils/constants';
import { Card, Layout, StackedCell } from '../../components';

import { eventLogSaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const EventLog = memo(() => {
  useInjector(sliceKey, reducer, eventLogSaga);

  const dispatch = useDispatch();
  const { eventLogs, eventLogFilters } = useSelector(selectEventLog);
  const { data, isLoading, page } = eventLogs;
  const { environments, user } = useSelector(selectApp);
  const { toCloudAccountName } = useCloudAccountId();

  const getEnvironments = useCallback(
    envId => {
      return environments.data.find(e => e.id === envId)?.name ?? envId;
    },
    [environments.data],
  );

  useDeepCompareEffect(() => {
    const q: any = {
      page: page.info.page_number,
      size: page.info.page_size,
      initiatedBy: eventLogFilters.user?.value ?? '',
      environmentId: eventLogFilters.environment?.value ?? '',

      searchQuery: eventLogFilters.searchTerm ?? '',
      // cloud: eventLogFilters.cloud?.value ?? '',
    };

    if (eventLogFilters.dates?.length > 0) {
      q.startTime = dayjs(eventLogFilters.dates?.from).format(DATE_TIME_FORMAT);
    }

    if (eventLogFilters.dates?.length > 1) {
      q.endTime = dayjs(eventLogFilters.dates?.to).format(DATE_TIME_FORMAT);
    }

    dispatch(
      actions.getEventLogs({
        q,
      }),
    );
  }, [dispatch, page.info, eventLogFilters]);

  const columns = [
    {
      header: 'Event type',
      accessor: 'type',
      align: 'left',
    },
    {
      header: 'Description',
      align: 'left',
      render: ({ row }) => {
        const message = `${row.resource?.type}:${row.resource?.name}: ${
          row.initiated_by
        } performed ${row.type} at ${formatDate(
          row.timestamp,
        )} in environment ${getEnvironments(row.environment_type)}`;

        return <CustomTooltip label={message}>{message}</CustomTooltip>;
      },
    },
    {
      header: 'Entity',
      render: ({ row }) => {
        return (
          <StackedCell
            upper={row.resource.name ?? '-'}
            lower={row.resource.type ?? '-'}
          />
        );
      },
      align: 'left',
    },
    {
      header: 'Environment',
      render: ({ row }) => {
        return (
          <StackedCell
            upper={
              row.environment_type === 'Account' ? (
                toCloudAccountName(row.environment_id)
              ) : row.environment_type === 'Organization' ? (
                user.info.org_id
              ) : (
                <EnvironmentName environmentId={row.environment_id} />
              )
            }
            lower={row.environment_type ?? '-'}
          />
        );
      },
      align: 'left',
    },
    { header: 'Action By', accessor: 'initiated_by', align: 'left' },
    {
      header: 'Timestamp',
      accessor: 'timestamp',
      align: 'left',
      render: ({ value }) => formatDate(value),
    },
  ];

  const handleOnChangePagination = pagination => {
    dispatch(actions.updatePageInfo(pagination));
  };

  return (
    <Card
      contentComponent={
        <Stack spacing={5} w="full">
          <EventLogHeader />
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            pagination={{
              totalCount: page.totalCount,
              pageInfo: page.info,
              onChange: handleOnChangePagination,
            }}
            styles={{ header: { zIndex: 800 } }}
          />
        </Stack>
      }
    />
  );
});
