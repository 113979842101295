import React, { useEffect } from 'react';

import { VulnerabilitySeverity } from '@ariksa/inventory-core/api';
import { VulnerabilitiesCharts } from '@ariksa/reporting';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { CDashboard } from 'components/Visualization';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';

import { actions } from '../slice';

import { dashboardWidgets1 } from './config';

export const VulnerabilityDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { environmentId, accountId } = useAccessBoundary();

  useEffect(() => {
    const payload = {
      environmentId,
      accountId: !!accountId ? [accountId] : [],
      startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    };
    dispatch(
      actions.getVulnerabilitiesBySeverity({
        q: {
          chartType: VulnerabilitiesCharts.CriticalAndHighVulnerabilities,
          ...payload,
        },
      }),
    );
    dispatch(
      actions.getVulnerabilitiesBySeverityCounts({
        q: {
          chartType: VulnerabilitiesCharts.CriticalAndHighVulnerabilities,
          ...payload,
        },
      }),
    );
    dispatch(
      actions.getHostsWithCriticalHighVulnerabilities({
        q: {
          ...payload,
          chartType:
            VulnerabilitiesCharts.HostsWithCriticalOrHighVulnerabilities,
        },
      }),
    );
    dispatch(
      actions.getVulnerabilityPrioritizationData({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );
    dispatch(
      actions.getVulnerabilitiesBySources({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );
    dispatch(
      actions.getMostDeployedVulnerableImages({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );
    dispatch(
      actions.getPackageVulnerabilities({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : [],
          isPatchable: true,
          page: 1,
          size: 3,
          severity: VulnerabilitySeverity.Critical,
          resolved: false,
          /*sortBy: 'severity',
          sortOrder: 'desc',*/
        },
      }),
    );
  }, [dispatch, environmentId, accountId]);

  return (
    <Box w="full" h="full" overflow="scroll">
      <CDashboard dashboardWidgets={dashboardWidgets1} />
    </Box>
  );
};
