import React from 'react';

import { Box } from '@chakra-ui/react';

export const addZeroPrefix = val => (val < 10 ? '0' : '');

export const renderHourMinute = value => (
  <Box>
    {addZeroPrefix(value)}
    {value}
  </Box>
);
