export const tdStyles = {
  border: 'none',
  px: 1.5,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 'md',
};

export const dashboardCardHoverStyles = {
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    boxShadow: `rgba(99, 99, 99, 0.2) 0px 5px 10px 2px`,
    border: '1px solid',
    borderColor: 'primary',
  },
};
