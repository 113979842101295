import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { resourceIcons } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { limitedString } from 'utils/string';

import { ACTION_NODE } from './nodes_types';

export function registerActionNode() {
  G6.registerNode(
    ACTION_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 200,
              height: 45,
              fill: colors.white,
              stroke: colors.green[200],
              lineWidth: 0.5,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        let img = resourceIcons.permissionAction;

        //@ts-ignore
        const data = cfg?.data ?? ({} as any);
        const tokens = data?.permission?.split(':');
        const [service, action, crudLevel] = tokens;

        group.addShape('image', {
          attrs: {
            x: x + 13,
            y: y + 13,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        const createText = props => {
          group.addShape('text', {
            attrs: {
              x: x + 110,

              textAlign: 'center',
              // @ts-ignore

              // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              opacity: 1,
              // fontStyle: 'bold',
              fill: colors.black,
              cursor: 'pointer',
              ...props,
            },
            name: 'type',
          });
        };

        createText({
          y: height / 2 - 4,
          text: limitedString(`${service}:${crudLevel}`, 20),
        });

        createText({
          y: height / 2 + 14,
          text: limitedString(`${action}`, 20),
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
