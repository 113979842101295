import React, { FC, useEffect, useState } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';
import map from 'lodash/map';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  NamespaceARN,
  CreatedBy,
  TotalSize,
  IAMRoles,
  NodeType,
  NumberOfNodes,
  ElasticIP,
  EndpointURL,
  EndpointID,
  RedshiftVersion,
  InternetAccessible,
  Encrypted,
  KMSID,
  AvailabilityStatus,
  MasterUsername,
  AllowVersionUpgrade,
  SnapshotRetentionPeriod,
  DatabaseName,
  PublicKey,
  RDSSecurityGroupList,
} from '../../../Components/MetadataField/MetaGridFields';

export const RedshiftClusterMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insights } = resourceInsight;
  const [interfaces, setInterfaces] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const data: Record<string, any>[] = [];
    map(resource?.config?.Endpoint?.VpcEndpointId, o => {
      map(o?.NetworkInterfaces, n => data.push({ ...n, VpcId: o?.VpcId }));
    });
    setInterfaces(data);
  }, [resource]);

  const columns = [
    { header: 'VPC ID', accessor: 'VpcId' },
    {
      header: 'Availability zone',
      accessor: 'AvailabilityZone',
      align: 'left',
    },
    { header: 'Interface ID', accessor: 'NetworkInterfaceId', align: 'left' },
    { header: 'Subnet ID', accessor: 'SubnetId', align: 'left' },
    {
      header: 'Private IP Address',
      accessor: 'PrivateIpAddress',
      align: 'left',
    },
  ];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {NamespaceARN(resource)}
        {CreatedBy(insights)}
        {TotalSize(resource)}
        {IAMRoles(resource)}
        {NodeType(resource)}
        {NumberOfNodes(resource)}
        {ElasticIP(resource)}
        {EndpointURL(resource)}
        {EndpointID(resource)}
        {RedshiftVersion(resource)}
        {InternetAccessible(resource)}
        {Encrypted(resource)}
        {KMSID(resource)}
        {AvailabilityStatus(resource)}
        {MasterUsername(resource)}
        {RDSSecurityGroupList(resource)}
        {AllowVersionUpgrade(resource)}
        {PublicKey(resource)}
        {SnapshotRetentionPeriod(resource)}
        {DatabaseName(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={interfaces} />}
              iconType={''}
              label="Interfaces"
              totalCount={interfaces?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
