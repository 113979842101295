import React, { FC, useEffect, useState } from 'react';

import { Clients } from '@ariksa/notification';
import { Box, Stack, HStack } from '@chakra-ui/react';
import { filter, find, some } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { ElasticsearchIcon } from 'components/Icons';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { actions } from '../../../../slice';

import { JiraComponent } from './JiraComponent';

interface Props {
  label: string;
  clientName: Clients;
}

export const ClientComponent: FC<Props> = props => {
  const { clientName, label } = props;
  const { clients } = useSelector(selectIntegrations);
  const { formData } = useSelector(selectAlertWorkflow);
  const [notificationClients, setNotificationClients] = useState<
    Record<string, any>[]
  >([]);
  const [selected, setSelected] = useState<Record<string, any>>([]);

  const dispatch = useDispatch();

  //set client options
  useEffect(() => {
    setNotificationClients(
      map(clients[clientName], o => ({ label: o?.name, value: o })),
    );
  }, [clients, clientName]);

  //set selected clients
  useEffect(() => {
    const list = find(formData.clients, o => o.client_name === clientName)
      ?.client_list;
    setSelected(
      filter(notificationClients, o => some(list, l => l?.name === o.label)),
    );
  }, [notificationClients, formData.clients, clientName]);

  const content = (name, value) => (
    <Stack isInline align="center" w="full">
      <Box fontSize="sm">{name}</Box>
      <Tag label={value} />
    </Stack>
  );

  const renderClientNameSelect = () => (
    <Box minW={48} flex={1}>
      <Select
        options={notificationClients}
        isMulti
        value={selected}
        isLoading={clients.loading}
        isDisabled={clients.loading}
        onChange={selectedOptions => {
          dispatch(
            actions.updateAlertWorkflowActions({
              client_name: clientName,
              client_list: map(selectedOptions, each => ({
                name: each.label,
                client_config: {},
              })),
            }),
          );
          if (clientName === Clients.Jira) {
            dispatch(
              actions.updateJiraClients({
                names: map(selectedOptions, o => o.label),
              }),
            );
          }
        }}
        showTotalSelected={
          (clientName === Clients.Slack ||
            clientName === Clients.Jira ||
            clientName === Clients.Teams) &&
          selected.length > 0
        }
      />
    </Box>
  );

  return (
    <Stack py={1}>
      <HStack w="full">
        <Box whiteSpace="nowrap">{label}</Box>
        {clientName !== Clients.Elasticsearch && renderClientNameSelect()}
        {clientName === Clients.Elasticsearch && (
          <>
            <Box w={32}>
              <Select
                options={[
                  {
                    label: 'Elastic',
                    value: Clients.Elasticsearch,
                    icon: <ElasticsearchIcon />,
                  },
                ]}
                defaultValue={{
                  label: 'Elastic',
                  value: Clients.Elasticsearch,
                  icon: <ElasticsearchIcon />,
                }}
              />
            </Box>
            <Box>with name</Box>
            {renderClientNameSelect()}
          </>
        )}
      </HStack>
      {(clientName === Clients.Slack || clientName === Clients.Teams) &&
        map(selected, o => (
          <Stack isInline>
            {content('Name', o?.value?.name)}
            {content('Channel', o?.value?.[clientName]?.channel_name)}
          </Stack>
        ))}
      {clientName === Clients.Jira &&
        map(selected, o => <JiraComponent clientInfo={o?.value} />)}
    </Stack>
  );
};
