import React, { FC } from 'react';

import {
  AccountID,
  Aliases,
  ARN,
  AvailabilityZone,
  CloudID,
  CreatedAt,
  Name,
  Owner,
  Region,
  State,
  VPCId,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { Tags } from './MetadataField/Tags';

type DataType = Record<string, any> | string;

interface Props {
  availabilityZone?: DataType;
  createdAt?: DataType;
  state?: DataType;
  arn?: DataType;
  vpc?: DataType;
  owner?: DataType;
  region?: DataType;
  aliases?: DataType;
  tags?: DataType;
  createdAtTime?: 'unix' | 'utc' | 'dayjs';
}

export const MetadataCommonFields: FC<Props> = props => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;
  const {
    availabilityZone = resource,
    createdAt = resource,
    state = resource,
    arn = insight,
    vpc = resource,
    owner = insight,
    region = insight,
    createdAtTime = 'dayjs',
    aliases = 'None',
    tags = resource,
  } = props;

  return (
    <>
      {Name(insight)}
      {AccountID(insight)}
      {aliases !== 'None' && Aliases(aliases)}
      {region !== 'None' && Region(region)}
      {availabilityZone !== 'None' && AvailabilityZone(availabilityZone)}
      {CloudID(insight)}
      {arn !== 'None' && ARN(arn)}
      {tags !== 'None' && Tags(resource)}
      {resource?.general?.resource_type !== 'VPC' &&
        vpc !== 'None' &&
        VPCId(vpc)}
      {state !== 'None' && State(state)}
      {createdAt !== 'None' && CreatedAt(createdAt, createdAtTime)}
      {Owner(owner)}
    </>
  );
};
