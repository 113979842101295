import React, { FC } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

import { FormLabelProps } from '../types';

export const FormLabel: FC<FormLabelProps> = props => {
  const {
    label,
    isRequired,
    helpIcon,
    styles = {},
    helpTooltip,
    htmlFor,
    onClickHelpIcon,
  } = props;

  return (
    <HStack mb={2}>
      <HStack spacing={0} align="center">
        <Box htmlFor={htmlFor} color="gray.300" {...styles}>
          {label}
        </Box>
        {isRequired ? <Box color="red.400">*</Box> : ''}
      </HStack>
      {helpIcon &&
        (helpTooltip ? (
          <CustomTooltip label={helpTooltip}>
            <Box
              boxSize={4}
              onClick={onClickHelpIcon}
              cursor={onClickHelpIcon ? 'pointer' : 'inherit'}
              color="primary"
              userSelect={'none'}
            >
              {helpIcon}
            </Box>
          </CustomTooltip>
        ) : (
          <Box>{helpIcon}</Box>
        ))}
    </HStack>
  );
};
