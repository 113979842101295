import { useEffect, useState } from 'react';

import { Viewport } from 'pixi-viewport';

export const useGraphScaling = ({ app, graph, ref }) => {
  useEffect(() => {
    const { offsetHeight: height, offsetWidth: width } = ref.current;
    // const viewport = new Viewport({
    //   screenWidth: window.innerWidth,
    //   screenHeight: window.innerHeight,
    //   worldWidth: width,
    //   worldHeight: height,
    //
    //   // interaction: app.renderer.plugins.interaction,
    //   events: app.renderer.events, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    // });
    //
    // viewport.drag().pinch().wheel().decelerate();
    // app.stage.addChild(viewport);

    const onWheel = e => {
      const { metaKey, deltaX, deltaY } = e;
      e.preventDefault();
      // console.log(e);
      if (metaKey) {
        const scale = Math.max(
          Math.min(1.5, app.stage.scale.y + deltaY / height),
          0.2,
        );
        app.stage.scale.set(scale);
      } else {
        app.stage.x -= deltaX;
        app.stage.y -= deltaY;
      }
      graph.emit('wheel', e);
    };

    app.renderer.view.addEventListener('wheel', onWheel);
    return () => {
      app.renderer.view.removeEventListener('wheel', onWheel);
    };
  }, [app, graph, ref]);
};
