/**
 *
 * Asynchronously loads the component for Findings
 *
 */

import { lazy } from 'react';

export const Findings = lazy(() =>
  import('./index').then(m => ({ default: m.Findings })),
);
