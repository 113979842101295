import React, { FC, useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { each, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import {
  AriksaIcon,
  formatNumberWithComma,
  sortByOrderProvided, WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { CustomIconTypes, IconTypes, OverviewIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components/getIcon';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation/SideNav';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { inventoryCategoryOrderList } from 'containers/Inventory/CloudInventory/Components/InventorySideNav/utils';
import { Overview } from 'containers/Inventory/CloudInventory/Components/Overview';
import { InventorySummary } from 'containers/Inventory/CloudInventory/Components/Summary/InventorySummary';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useSearchParams } from 'hooks/useSearchParams';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';
import { CategoryInventory } from '../CategoryInventory';
import { ResourceTypeInventory } from '../ResourceTypeInventory';

export const InventorySidebar: FC = () => {
  const { categories, currentCategory } = useSelector(selectInventory);
  const dispatch = useDispatch();
  const [items, setItems] = useState<SideNavMenuItemProps[]>([]);
  const { getCloudAgnosticName } = useResourceType();
  const params = useSearchParams<{
    tab: string;
    resource_type: string;
    category: string;
    resource_id: string;
    region: string;
  }>();

  const {
    showInventorySummary,
    setShowInventorySummary,
    setShowCategoryInventorySummary,
    showCategoryInventorySummary,
    sideNavItemsMapping: itemsMapping,
    setSideNavItemsMapping: setItemsMapping,
    handleCategoryChange,
    updateMenuItems,
    setSelectedRegion,
  } = useInventoryContext();

  const getResourceIcon = resource_type => {
    return <Box color="primary">{getIcon(resource_type)}</Box>;
  };

  useEffect(() => {
    const getItems = (r, category) => {
      const title = getCloudAgnosticName(r?.name);
      const icon = getResourceIcon(r?.category);
      return {
        data: r,
        title: (
          <HStack
            justify={'space-between'}
            w={'full'}
            pl={!r?.resource_types ? 8 : 0}
          >
            <CustomTooltip label={title}>
              <Box>{title}</Box>
            </CustomTooltip>
            <Box>
              {!r?.resource_types ? formatNumberWithComma(r?.total) : ''}
            </Box>
          </HStack>
        ),
        key: !r?.resource_types ? category + '_' + r?.name : r?.category,
        component: !!r?.resource_types ? (
          <CategoryInventory />
        ) : (
          <ResourceTypeInventory />
        ),
        ...(!!r?.resource_types
          ? {
              secondary: map(r?.resource_types, o => getItems(o, r?.category)),
              icon,
            }
          : {}),
      };
    };
    if (categories.data) {
      const items: SideNavMenuItemProps[] = map(categories.data, o =>
        getItems(o, ''),
      );
      const orderedItems = sortByOrderProvided(
        items,
        inventoryCategoryOrderList,
      ) as SideNavMenuItemProps[];

      setItems([
        {
          title: (
            <Box>
              <WithResourceIcon resourceType={IconTypes.Overview} spacing={2}>
                OVERVIEW
              </WithResourceIcon>
            </Box>
          ),
          key: 'Overview',
          data: { name: 'Overview' },
          component: <Overview />,
          showDivider: true,
        },
        {
          title: 'BY CATEGORIES',
          key: 'ByCategories',
          showArrow: false,
          styles: {
            menuItem: {
              fontWeight: 600,
              cursor: 'default',
              color: 'black',
              _hover: { color: 'black' },
            },
            menu: { index: undefined, defaultIndex: [] },
          },
          secondary: orderedItems,
        },
      ]);

      let mapping: Record<string, SideNavMenuItemProps> = {};
      each(orderedItems, o => {
        mapping[o.key] = o;
        each(o.secondary, s => (mapping[s.key] = s));
      });
      setItemsMapping(mapping);
    }
  }, [getCloudAgnosticName, categories.data, dispatch, setItemsMapping]);

  //if tab or category is provided set sidenav current tab
  useDeepCompareEffect(() => {
    let category = items[0]?.key;
    let title = items[0]?.data?.name;
    if (!!params.tab || !!params.category) {
      const item = itemsMapping[params.tab || params.category];
      if (!!item) {
        category = item?.key;
        title = item?.data?.name;
      }
      if (!!params.resource_type)
        dispatch(actions.setNativeResource(params.resource_type));
      if (!!params.resource_type && !!params.resource_id)
        dispatch(actions.setSearchWord(params.resource_id));
    }
    dispatch(actions.updateCurrentCategory(category as string));
    dispatch(actions.updateCurrentCategoryTitle(title));
  }, [
    params.tab,
    items,
    params.category,
    params.resource_type,
    params.resource_id,
    itemsMapping,
  ]);

  useEffect(() => {
    updateMenuItems(items);
  }, [items, updateMenuItems]);

  useEffect(() => {
    if (!!params.region)
      setSelectedRegion({ label: params.region, value: params.region });
  }, [setSelectedRegion, params.region]);

  return (
    <Box h={'full'}>
      <SideNav
        menuItems={items}
        isLoading={categories.isLoading}
        showContentHeader={false}
        onChange={(key, menuItem) => {
          handleCategoryChange(key, menuItem);
        }}
        current={currentCategory}
        styles={{
          menu: { index: [1], defaultIndex: [] },
        }}
        leftAlignSecondaryItems
      />

      {showInventorySummary && (
        <InventorySummary
          type={CustomIconTypes.CloudInventory}
          isOpen={showInventorySummary}
          onClose={() => setShowInventorySummary(false)}
          header={CustomIconTypes.CloudInventory}
        />
      )}
      {showCategoryInventorySummary && (
        <InventorySummary
          type={currentCategory?.split('_')?.[0]}
          isOpen={showCategoryInventorySummary}
          onClose={() => setShowCategoryInventorySummary(false)}
          header={currentCategory?.split('_')?.[0]}
        />
      )}
    </Box>
  );
};
