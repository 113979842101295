import { ICardTableStyles } from './types';

export const cardTableStyles: ICardTableStyles = {
  header: {
    w: 'full',
    maxWidth: '100%',
    // borderBottom: '1px solid',
    // borderColor: 'tableRowBorder',
    color: 'tableHeader',
  },
  headerCell: {
    userSelect: 'none',
    pos: 'relative',
    bg: 'tableHeaderBg',
    px: 3,
    flexGrow: 1,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minHeight: '42px',
    alignItems: 'center',
    justifyContent: 'center',
    minW: '150px',
    // maxW: '200px',
    fontWeight: 'bold',
    fontSize: 'xs',
    _first: {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    _last: {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
    //borderBottom: '1px solid',
    //borderColor: 'tableRowBorder',
  },

  body: {},
  bodyRow: {
    //boxShadow: '0 1px 4px #ddd',
    borderRadius: 6,
    border: '1px solid',
    borderColor: 'componentBorder',
    my: 2,
    w: 'full',
    bg: 'cardBg',
    /*_even: {
      bg: 'gray.50',
    },*/
    _hover: {
      bg: 'gray.50',
    },
    _active: {
      bg: 'tableSelectedRowBg',
    },
    _last: {
      mb: 0,
    },
  },
  bodyCell: {
    px: 3,
    // maxW: '200px',
    alignItems: 'center',
    justifyContent: 'flex-start',

    w: 'full',
    h: '44px',
    lineHeight: '44px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    //borderBottom: '1px solid',
    //borderColor: 'tableRowBorder',
  },
};

export const alignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const textAlignMap = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export const tablePlaceholderStyle = {
  color: 'gray.300',
  fontSize: 'xl',
  h: 'full',
};

export const scrollControleStyle = {
  size: 'xs',
  'aria-label': 'Send email',
  pos: 'absolute' as any,
  left: 'calc(100% + 0px)',
  p: 0,
  w: 0,
  h: 4,
  zIndex: 1000,
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  borderRadius: '3px',
};
