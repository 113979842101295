import {
  QueryFilterApiCreateQueryFilterRequest,
  QueryFilterApiDeleteQueryFilterRequest,
  QueryFilterApiGetQueryFiltersRequest,
  QueryFilterApiUpdateQueryFilterRequest,
} from '@ariksa/profile/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';

import { ProfileService, serviceClient } from 'api/services';

import { actions } from './slice';

export function* queryFilterSaga() {
  yield takeLatest(actions.createQueryFilter.type, doCreateQueryFilter);
  yield takeLatest(actions.updateQueryFilter.type, doUpdateQueryFilter);
  yield takeLatest(actions.deleteQueryFilter.type, doDeleteQueryFilter);
  yield takeLatest(actions.loadQueryFilters.type, doGetQueryFilters);
  yield takeLatest(actions.createQueryFilterSuccess.type, doGetQueryFilters);
  yield takeLatest(actions.deleteQueryFilterSuccess.type, doGetQueryFilters);
}

function* doGetQueryFilters(
  action: PayloadAction<QueryFilterApiGetQueryFiltersRequest>,
) {
  yield call(serviceClient, {
    fn: async () => ProfileService.QueryFilter.getQueryFilters(action.payload),
    onSuccess: actions.loadQueryFiltersSuccess,
    onError: actions.loadQueryFiltersError,
    mapResponse: res => res.data,
    errorTitle: 'Failed to get query filters!',
  });
}

function* doCreateQueryFilter(
  action: PayloadAction<QueryFilterApiCreateQueryFilterRequest>,
) {
  yield call(serviceClient, {
    fn: async () =>
      ProfileService.QueryFilter.createQueryFilter(action.payload),
    onSuccess: actions.createQueryFilterSuccess,
    onError: actions.createQueryFilterError,
    mapResponse: res => res.data,
    errorTitle: 'Failed to create query filter!',
  });
}

function* doUpdateQueryFilter(
  action: PayloadAction<QueryFilterApiUpdateQueryFilterRequest>,
) {
  const { queryFilterId, queryFilterUpdate } = action.payload;
  yield call(serviceClient, {
    fn: async () =>
      ProfileService.QueryFilter.updateQueryFilter({
        queryFilterId,
        queryFilterUpdate,
      }),
    onSuccess: actions.createQueryFilterSuccess,
    onError: actions.createQueryFilterError,
    mapResponse: res => res.data,
    errorTitle: 'Failed to update query filter!',
  });
}

function* doDeleteQueryFilter(
  action: PayloadAction<QueryFilterApiDeleteQueryFilterRequest>,
) {
  yield call(serviceClient, {
    fn: async () =>
      ProfileService.QueryFilter.deleteQueryFilter(action.payload),
    onSuccess: actions.deleteQueryFilterSuccess,
    onError: actions.deleteQueryFilterError,
    errorTitle: 'Failed to delete query filter!',
  });
}
