import React, { FC } from 'react';

import { KeycloakInstanceContext } from 'containers/App/keycloak/context';
import { useKeyCloak } from 'containers/App/keycloak/useKeyCloak';

interface IKeycloakContextProps {}

export const KeycloakContext: FC<IKeycloakContextProps> = props => {
  const { children } = props;
  const { keycloak, logOut, resetPassword, forceRefreshToken } = useKeyCloak();

  return (
    <KeycloakInstanceContext.Provider
      value={{
        keycloak,
        logOut,
        resetPassword,
        forceRefreshToken,
      }}
    >
      {children}
    </KeycloakInstanceContext.Provider>
  );
};
