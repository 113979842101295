import React from 'react';

import { AiOutlineApi, AiOutlineTeam } from 'react-icons/ai';
import { FiCheckCircle } from 'react-icons/fi';
import { IoIosCloudOutline } from 'react-icons/io';
import { RiUserSettingsLine } from 'react-icons/ri';

import {
  AlertWorkflowIcon,
  BellIcon,
  DataClassificationIcon,
  EnvironmentIcon,
  GroupIcon,
  PermissionsActionsIcon,
  RemediateIcon,
  TicketIcon,
  VulnerabilityIcon,
} from 'components/Icons';

export const routeConfig = {
  '/': {
    noBreadcrumb: true,
    navBar: {
      base: '/',
      list: [
        {
          url: '/',
          title: 'Overview',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/dashboard/data',
          title: 'Data',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/dashboard/identity',
          title: 'Identity',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/dashboard/infrastructure',
          title: 'Infrastructure',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/dashboard/vulnerability',
          title: 'Vulnerabilities',
          exact: true,
          icon: <VulnerabilityIcon />,
        },
      ],
    },
  },
  '/dashboard': {
    noBreadcrumb: true,
    navBar: {
      base: '/dashboard',
      list: [
        {
          url: '',
          title: 'Overview',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/data',
          title: 'Data',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/identity',
          title: 'Identity',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/infrastructure',
          title: 'Infrastructure',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/vulnerability',
          title: 'Vulnerabilities',
          exact: true,
          icon: <VulnerabilityIcon />,
        },
      ],
    },
  },
  '/findings': {
    noBreadcrumb: true,
    navBar: {
      base: '/findings',
      list: [
        {
          url: '',
          title: 'Overview',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/alerts',
          title: 'Issues',
          exact: true,
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/tickets',
          title: 'Tickets',
          exact: true,
          icon: <TicketIcon />,
        },
      ],
    },
  },
  '/inventory': {
    noBreadcrumb: true,
    navBar: {
      base: '/inventory',
      list: [
        {
          url: '/summary',
          title: 'Summary',
          //exact: true,
          //icon: <IoIosCloudOutline />,
        },
        {
          url: '/allowlist',
          title: 'Allow List',
          //icon: <IoIosCloudOutline />,
        },
        {
          url: '/context',
          title: 'Context',
          //icon: <IoIosCloudOutline />,
        },
      ],
    },
  },
  '/reports': {
    noBreadcrumb: true,
    navBar: {
      base: '/reports',
      list: [
        {
          url: '/dashboard',
          title: 'Dashboard',
          //exact: true,
          //icon: <IoIosCloudOutline />,
        },
        {
          url: '/all',
          title: 'Reports',
          //icon: <IoIosCloudOutline />,
        },
      ],
    },
  },
  '/policy-hub': {
    navBar: {
      base: '/policy-hub',
      list: [
        {
          url: '/blueprint',
          title: 'Blueprint',
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/policy',
          title: 'Policy',
          icon: <IoIosCloudOutline />,
          badgeLeft: '34px',
        },
        {
          url: '/remediation',
          title: 'Remediation',
          icon: <RemediateIcon />,
          badgeLeft: '75px',
        },
        {
          url: '/workflow',
          title: 'Workflow',
          icon: <AlertWorkflowIcon />,
        },
      ],
    },
  },
  '/setup': {
    navBar: {
      base: '/setup',
      list: [
        {
          url: '/accounts',
          title: 'Accounts',
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/outpost',
          title: 'Outpost',
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/jit',
          title: 'JIT Access',
          icon: <IoIosCloudOutline />,
        },
        {
          url: '/environment',
          title: 'Environment',
          icon: <EnvironmentIcon />,
        },
        {
          url: '/data',
          title: 'Data',
          icon: <DataClassificationIcon />,
        },
        {
          url: '/roles',
          title: 'Roles',
          icon: <RiUserSettingsLine />,
        },
        {
          url: '/groups',
          title: 'Groups',
          icon: <GroupIcon />,
        },
        {
          url: '/users',
          title: 'Users',
          icon: <AiOutlineTeam />,
        },
        {
          url: '/ip-whitelisting',
          title: 'IP Whitelisting',
          icon: <FiCheckCircle />,
        },
        {
          url: '/integrations',
          title: 'Integrations',
          icon: <AiOutlineApi />,
        },
      ],
    },
  },
  '/visibility': {
    navBar: {
      base: '/visibility',
      list: [
        {
          url: '/security-graph',
          title: 'Security Graph',
          icon: <AiOutlineTeam />,
        },
        {
          url: '/data',
          title: 'Data',
          icon: <AiOutlineTeam />,
        },
        {
          url: '/vulnerability',
          title: 'Vulnerabilities',
          icon: <VulnerabilityIcon />,
        },
        {
          url: '/jit-requests',
          title: 'JIT Requests',
          icon: <PermissionsActionsIcon />,
        },
        {
          url: '/security-graph-next',
          title: 'Security Graph Next',
          icon: <AiOutlineTeam />,
        },
      ],
    },
  },
};
