import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';
import {
  CloudAccountApiChangeDiscoverInventoryIntervalRequest,
  CloudAccountApiDeleteCloudAccountByUuidRequest,
  CloudAccountApiGetCloudAccountByUuidRequest,
  CloudAccountApiGetCloudAccountsByParentRequest,
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiPauseDiscoverInventoryRequest,
  CloudAccountApiReadConfigRequest,
  CloudAccountApiReadRemediationConfigRequest,
  CloudAccountApiResumeDiscoverInventoryRequest,
  CloudAccountGetResponse,
  CloudProviders,
  OnboardingConfigResponse,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  CheckDetailResponse,
  ChecklistApiGetCheckdetailRequest,
  ChecklistApiGetChecklistRequest,
  ChecklistApiGetGovernanceRemediationRequest,
  CheckListResponse,
  EnvironmentApiGetAttachedEnvironmentsOnAccountRequest,
  EnvironmentRead,
  GovernanceResponse,
  Remediation,
  ReportApiGetChecksReportRequest,
} from '@ariksa/compliance-policies/api';
import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import { ChartsApiGetGovernanceScoreChartRequest } from '@ariksa/reporting/dist/api';
import { ScannerInfo } from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
import map from 'lodash/map';
import { QueryAction } from 'services/types';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

import { SnapshotTasksParams } from 'api/collector/types';
import {
  apiCallStateInitialState,
  withPaginationInitialState,
} from 'api/initial_values';
import {
  onApiCall,
  onApiCallError,
  onApiCallSuccess,
} from 'app/api/call_status';
import { CloudAccountIds } from 'app/api/inventory/types';
import { PageInfo } from 'app/components';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  AssetSummaryEntry,
  ContainerState,
  UUID,
  RediscoverInventoryRequestParams,
} from './types';

// The initial state of the CloudAccounts container
export const initialState: ContainerState = {
  accounts: PagedQueryState.init([]),
  memberAccounts: PagedQueryState.init([]),
  management_account_uuid: '',
  accountRediscovery: QueryState.init({}),
  isEdit: false,
  accountDetails: QueryState.init<CloudAccountGetResponse>(
    {} as CloudAccountGetResponse,
  ),
  snapshotTasks: {
    loading: false,
    error: false,
    results: {},
    pagination: INIT_PAGE_INFO,
    total: 0,
  },
  apiCallStatus: QueryState.init({}),
  refreshAccount: {
    uuid: '',
    loading: false,
    error: null,
    loaded: false,
  },
  onboardMemberAccount: {
    uuid: '',
    loading: false,
    error: null,
    loaded: false,
  },
  selectedRow: {
    id: '',
  },

  assetSummary: apiCallStateInitialState<AssetSummaryEntry[]>([]),
  current_snapshot_id: '',

  alertSeverity: {
    loading: false,
    error: null,
    total_alerts: 0,
    severity_count: {},
  },
  snapshots: withPaginationInitialState<Dict<any>[]>([]),
  accountSnapshotStatus: QueryState.init({}),
  vulnerabilityScanner: QueryState.init({}),
  dataScanner: QueryState.init({}),
  accountAction: QueryState.init({}),

  governanceChecklist: QueryState.init<CheckListResponse>(
    {} as CheckListResponse,
  ),
  governanceCheckDetails: QueryState.init({}),
  governanceCheckRemediation: QueryState.init({}),
  checklistHistory: QueryState.init({}),
  downloadChecklist: QueryState.init({}),

  attachedEnvironments: QueryState.init([]),
  config: QueryState.init({ cloud_provider: CloudProviders.Aws }),
  updateAccountDiscoveryInterval: QueryState.init({}),
};

const cloudAccountsSlice = createSlice({
  name: 'cloudAccounts',
  initialState,
  reducers: {
    updateAccountDiscoveryInterval(
      state,
      action: QueryAction<
        any,
        CloudAccountApiChangeDiscoverInventoryIntervalRequest
      >,
    ) {
      state.updateAccountDiscoveryInterval = QueryState.next(
        state.updateAccountDiscoveryInterval,
        action,
      );
    },
    getAccountConfig(
      state,
      action: QueryAction<
        OnboardingConfigResponse,
        CloudAccountApiReadConfigRequest
      >,
    ) {
      state.config = QueryState.next(state.config, action);
    },

    getRemediationConfig(
      state,
      action: QueryAction<
        OnboardingConfigResponse,
        CloudAccountApiReadRemediationConfigRequest
      >,
    ) {
      state.config = QueryState.next(state.config, action);
    },

    onboardMemberAccounts(state, action: PayloadAction<any>) {
      onApiCall(state.onboardMemberAccount);
      state.onboardMemberAccount.uuid = action.payload.uuid;
    },
    onboardMemberAccountsSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.onboardMemberAccount);
    },
    onboardMemberAccountsError(state, action: PayloadAction<any>) {
      onApiCallError(state.onboardMemberAccount, action.payload);
    },

    refreshMemberAccounts(state, action: PayloadAction<UUID>) {
      onApiCall(state.refreshAccount);
      state.refreshAccount.uuid = action.payload.uuid;
    },
    refreshMemberAccountsSuccess(state, action: PayloadAction<UUID>) {
      onApiCallSuccess(state.refreshAccount);
    },
    refreshMemberAccountsError(state, action: PayloadAction<any>) {
      onApiCallError(state.refreshAccount, action.payload);
    },
    setIsEdit(state, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },

    /*Load All Accounts*/
    getCloudAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsRequest
      >,
    ) {
      state.accounts = PagedQueryState.next(state.accounts, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total || 0,
      });
    },
    updateCloudAccountPageInfo(state, action: PayloadAction<PageInfo>) {
      state.accounts.page.info = action.payload;
    },

    /*get snapshot status for single account*/
    getSnapshotStatusForAnAccount(
      state,
      action: QueryAction<WorkflowRead[], WorkflowApiGetLatestRequest>,
    ) {
      state.accountSnapshotStatus = QueryState.next(
        state.accountSnapshotStatus,
        action,
        {
          mapData: res => res[0],
        },
      );
    },

    /*get vulnerability scanner*/
    getVulnerabilityScanner(
      state,
      action: QueryAction<Record<string, ScannerInfo[]>>,
    ) {
      state.vulnerabilityScanner = QueryState.next(
        state.vulnerabilityScanner,
        action,
      );
    },

    /*get data scanner info*/
    getDataScannerInfo(
      state,
      action: QueryAction<Record<string, any>, FileDataApiGetScannersRequest>,
    ) {
      state.dataScanner = QueryState.next(state.dataScanner, action);
    },

    /*Delete Account*/
    deleteAccount(
      state,
      action: QueryAction<any, CloudAccountApiDeleteCloudAccountByUuidRequest>,
    ) {
      state.apiCallStatus = QueryState.next(state.apiCallStatus, action);
    },

    /*Load Member Accounts*/
    getMemberAccounts(
      state,
      action: QueryAction<
        PageCloudAccountGetResponse,
        CloudAccountApiGetCloudAccountsByParentRequest
      >,
    ) {
      state.memberAccounts = PagedQueryState.next(
        state.memberAccounts,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total || 0,
        },
      );
    },
    updateMemberAccountPageInfo(state, action: PayloadAction<PageInfo>) {
      state.memberAccounts.page.info = action.payload;
    },

    /*Rediscover Inventory*/
    rediscoverInventory(
      state,
      action: QueryAction<any, RediscoverInventoryRequestParams>,
    ) {
      state.accountRediscovery = QueryState.next(
        state.accountRediscovery,
        action,
      );
    },

    /*pause discovery*/
    pauseDiscovery(
      state,
      action: QueryAction<any, CloudAccountApiPauseDiscoverInventoryRequest>,
    ) {
      state.accountRediscovery = QueryState.next(
        state.accountRediscovery,
        action,
      );
    },

    /*resume discovery*/
    resumeDiscovery(
      state,
      action: QueryAction<any, CloudAccountApiResumeDiscoverInventoryRequest>,
    ) {
      state.accountRediscovery = QueryState.next(
        state.accountRediscovery,
        action,
      );
    },

    /*Load Cloud Account*/
    loadCloudAccountDetails(
      state,
      action: QueryAction<
        CloudAccountGetResponse,
        CloudAccountApiGetCloudAccountByUuidRequest
      >,
    ) {
      state.accountDetails = QueryState.next(state.accountDetails, action);
    },

    //*Load Asset Summary
    loadAssetSummary(state, action: PayloadAction<CloudAccountIds>) {
      onApiCall(state.assetSummary);
    },
    loadAssetSummarySuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.assetSummary);
      state.assetSummary.data = map(action.payload, o => ({
        name: o,
        value: action.payload[o],
      }));
    },
    loadAssetSummaryError(state, action: PayloadAction<any>) {
      onApiCallError(state.assetSummary, action.payload);
    },

    /*Load Snapshot Tasks*/
    loadSnapshotTasks(state, action: PayloadAction<SnapshotTasksParams>) {
      state.current_snapshot_id = action.payload.snapshot_id;
      onApiCall(state.snapshotTasks);
    },
    loadSnapshotTasksSuccess(state, action: PayloadAction<any>) {
      state.snapshotTasks.results[state.current_snapshot_id] =
        action.payload.results;
      state.snapshotTasks.total = action.payload.total;
      onApiCallSuccess(state.snapshotTasks);
    },
    loadSnapshotTasksError(state, action: PayloadAction<any>) {
      onApiCallError(state.snapshotTasks, action.payload);
    },

    updateSnapshotTasksPagination(state, action: PayloadAction<PageInfo>) {
      state.snapshotTasks.pagination = action.payload;
    },

    loadSelectedRow(state, action: PayloadAction<any>) {
      state.selectedRow = action.payload;
    },

    /*load snapshots*/
    loadSnapshots(state, action: PayloadAction<any>) {
      state.snapshots.data = [];
      onApiCall(state.snapshots);
    },
    loadSnapshotsSuccess(state, action: PayloadAction<any>) {
      state.snapshots.data = action.payload.results;
      state.snapshots.total = action.payload.total;
      onApiCallSuccess(state.snapshots);
    },
    loadSnapshotsError(state, action: PayloadAction<any>) {
      state.snapshots.data = [];
      onApiCallError(state.snapshots, action.payload);
    },
    updateSnapshotPagination(state, action: PayloadAction<PageInfo>) {
      state.snapshots.pageInfo = action.payload;
    },

    loadGovernanceChecklist(
      state,
      action: QueryAction<CheckListResponse, ChecklistApiGetChecklistRequest>,
    ) {
      state.governanceChecklist = QueryState.next(
        state.governanceChecklist,
        action,
      );
    },

    loadGovernanceCheckDetails(
      state,
      action: QueryAction<
        CheckDetailResponse[],
        ChecklistApiGetCheckdetailRequest
      >,
    ) {
      state.governanceCheckDetails = QueryState.next(
        state.governanceCheckDetails,
        action,
        {
          mapData: r => {
            const data: Record<string, CheckDetailResponse> = {};
            forEach(r, o => (data[o?.rule_id] = o));
            return data;
          },
        },
      );
    },

    loadGovernanceCheckRemediation(
      state,
      action: QueryAction<
        GovernanceResponse,
        ChecklistApiGetGovernanceRemediationRequest
      >,
    ) {
      state.governanceCheckRemediation = QueryState.next(
        state.governanceCheckRemediation,
        action,
        {
          mapData: r => {
            const data: Record<string, Remediation> = {};
            forEach(
              r?.checks_response,
              o => o?.check_name && (data[o?.check_name] = o),
            );
            return data;
          },
        },
      );
    },

    getAttachedEnvironments(
      state,
      action: QueryAction<
        EnvironmentRead[],
        EnvironmentApiGetAttachedEnvironmentsOnAccountRequest
      >,
    ) {
      state.attachedEnvironments = QueryState.next(
        state.attachedEnvironments,
        action,
      );
    },

    getChecklistHistoricalTrend(
      state,
      action: QueryAction<any, ChartsApiGetGovernanceScoreChartRequest>,
    ) {
      state.checklistHistory = QueryState.next(state.checklistHistory, action);
    },

    downloadChecklist(
      state,
      action: QueryAction<any, ReportApiGetChecksReportRequest>,
    ) {
      state.downloadChecklist = QueryState.next(
        state.downloadChecklist,
        action,
      );
    },
  },
});

function findAccountById(accounts, uuid: string) {
  return accounts.find(a => a.data.uuid === uuid);
}

function findAccountByCloudAccountId(accounts, uuid) {
  return accounts.find(
    a => a.data.uuid === uuid && a.data?.status?.code === 'SUCCESS',
  );
}

export const { actions, reducer, name: sliceKey } = cloudAccountsSlice;
