import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const useSearchTerm = () => {
  const {
    selectedSearchQueryTerm,
    activeSearchQueryTerm,
    currentSearchQuery,
  } = useSelector(selectSecurityGraphNext);

  const hasSelectedSearchTerm = useMemo(
    () => selectedSearchQueryTerm !== null,
    [selectedSearchQueryTerm],
  );
  const hasActiveSearchTerm = useMemo(() => activeSearchQueryTerm !== null, [
    activeSearchQueryTerm,
  ]);

  const isQueryChanged = useMemo(() => {
    return selectedSearchQueryTerm?.uuid !== activeSearchQueryTerm?.uuid;
  }, [activeSearchQueryTerm?.uuid, selectedSearchQueryTerm?.uuid]);

  return {
    hasSelectedSearchTerm,
    hasActiveSearchTerm,
    selectedSearchQueryTerm,
    activeSearchQueryTerm,
    currentSearchQuery,
    isQueryChanged,
  };
};
