import React from 'react';

import { Text } from '@chakra-ui/react';

export function T1({ children, ...rest }) {
  return (
    <Text fontSize="xs" {...rest}>
      {children}
    </Text>
  );
}

export function T2({ children, ...rest }) {
  return (
    <Text fontSize="sm" {...rest}>
      {children}
    </Text>
  );
}

export function T3({ children, ...rest }) {
  return (
    <Text fontSize="md" {...rest}>
      {children}
    </Text>
  );
}
