import { EventEmitter } from 'events';

import { Path } from '@ariksa/inventory-core';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { identity, max, merge, min } from 'lodash';
import { Container } from 'pixi.js';

import { EdgeIdMap } from 'components/Visualization/PixiGraph/core/EdgeIdMap';
import { ParentEdgeMap } from 'components/Visualization/PixiGraph/core/ParentEdgeMap';

import { ChildEdgeMap } from './ChildEdgeMap';
import { AGraphEdge } from './Edge';
import { AGraphLayout } from './Layout';
import { AGraphNode } from './Node';
import { NodeEdgeMap } from './NodeEdgeMap';
import { NodeIdMap } from './NodeIdMap';
import { RTree, RTreeEntry } from './RTree';

export interface AGraphProps<N extends AGraphNode, E extends AGraphEdge> {
  nodes: N[];
  edges: E[];
  paths?: Array<Path>;
  layout: AGraphLayout<AGraph<N, E>>;
  styles?: Record<string, any>;
}

export class AGraph<
  N extends AGraphNode,
  E extends AGraphEdge
> extends EventEmitter {
  protected layout: AGraphLayout<AGraph<N, E>>;
  protected rtree: RTree = new RTree();

  nodes: N[];
  edges: E[];
  paths: Path[];

  // used for layout algorithms
  edgeMap: NodeEdgeMap = new NodeEdgeMap();
  nodeIdMap: NodeIdMap = new NodeIdMap();
  edgeIdMap: EdgeIdMap = new EdgeIdMap();

  childMap: ChildEdgeMap = new ChildEdgeMap();
  nodeToPathMap: Map<string, Set<number>> = new Map();

  activeNodes: AGraphNode[] = [];
  hoverElements: RTreeEntry[] = [];
  styles = {
    padding: [10, 10],
    scale: 1,
  };

  constructor(props: AGraphProps<N, E>) {
    super();
    const { nodes, edges, layout, paths = [], styles = {} } = props;
    this.layout = layout;
    this.styles = merge(this.styles, styles);
    this.nodes = nodes;
    this.edges = [];
    this.paths = paths;
    this.update(nodes, edges, paths);
  }

  get offsets() {
    const { nodes } = this;
    const left = min(nodes.map(n => n.x)) ?? 0;
    const top = min(nodes.map(n => n.y)) ?? 0;
    const right = max(nodes.map(n => n.x)) ?? 0;
    const bottom = max(nodes.map(n => n.x)) ?? 0;

    return {
      left: Math.abs(left),
      top: Math.abs(top),
      right: Math.abs(right),
      bottom: Math.abs(bottom),
    };
  }

  update(nodes: N[], edges: E[], paths?: Path[]) {
    this.reset();

    nodes.forEach(n => this.addNode(n));
    edges.forEach(e => this.addEdge(e));
    paths?.forEach((p, pathIndex) => {
      p.path.forEach((nodeId, index) => {
        const pathIndexSet = this.nodeToPathMap.get(nodeId) ?? new Set();
        pathIndexSet.add(pathIndex);
        this.nodeToPathMap.set(nodeId, pathIndexSet);
      });
    });

    nodes.forEach(n => {
      this.addNode(n);
    });

    edges.forEach(e => {
      this.addEdge(e);
    });
    this.paths = paths ?? [];
  }

  reset() {
    this.nodes = [];
    this.edges = [];
    this.paths = [];

    this.nodeToPathMap.clear();
    this.edgeMap.clear();
    this.nodeIdMap.clear();
    this.edgeIdMap.clear();
    this.childMap.clear();
    this.rtree.clear();
    this.activeNodes = [];
  }

  getPreviousNodes(node: N) {
    return this.edges
      .filter(e => e.dest === node.id)
      .map(e => this.nodeIdMap.get(e.source))
      .filter(identity) as N[];
  }

  getNextNodes(node: N) {
    return this.edges
      .filter(e => e.source === node.id)
      .map(e => this.nodeIdMap.get(e.dest))
      .filter(identity) as N[];
  }

  addNode(node: N) {
    if (!node.id) {
      console.error('node without id', node);
    }

    if (this.nodeIdMap.has(node.id)) return;

    this.nodes.push(node);
    this.nodeIdMap.add(node);
    if (node.parentId) {
      this.childMap.add(node.parentId, node.id);
    }
  }

  removeNode(node: N) {
    this.nodes = this.nodes.filter(n => n.id === node.id);
    this.nodeIdMap.remove(node.id);
    if (node.parentId) {
      this.childMap.remove(node.parentId, node.id);
    } else {
      this.childMap.remove(node.id);
    }
  }

  addEdge(edge: E) {
    if (this.edgeMap.get(edge.source).includes(edge.dest)) return;
    if (this.edgeMap.get(edge.dest).includes(edge.source)) return;

    this.edges.push(edge);
    this.edgeMap.add(edge.source, edge.dest);
    this.edgeIdMap.add(edge);
  }

  removeEdge(edge: E) {
    this.edges = this.edges.filter(e => e.id === edge.id);
    this.edgeMap.remove(edge.source, edge.dest);
    this.edgeIdMap.remove(edge.id);
  }

  executeLayout() {
    this.layout.layoutNodes(this);
    this.layout.layoutEdges(this);
    this.nodes.forEach((n, index) => n.addInteractiveElement(this, index));
    this.edges.forEach((n, index) => n.addInteractiveElement(this, index));
  }

  addInteractiveElement(entry: RTreeEntry) {
    this.rtree.add(entry);
  }

  removeInteractiveElement(entry: RTreeEntry) {
    this.rtree.remove(entry);
  }

  renderEdges(g: Graphics, container: Container) {
    this.edges.forEach(e => e.render(g));
  }

  renderNodes(g: Graphics, container: Container) {
    this.nodes.forEach(n => {
      n.render(g, container);
    });
  }

  renderContext(g: Graphics, container: Container) {
    this.nodes.forEach(n => {
      n.renderContext(g, container);
    });
  }

  renderActiveNodes(g) {
    this.activeNodes.forEach(n => n.renderActive(g));
  }

  collides(x, y) {
    return this.rtree.search({
      minX: x - 2,
      maxX: x + 2,
      minY: y - 2,
      maxY: y + 2,
      minZ: -1000,
      maxZ: 1000,
    });
  }
}
