import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NeptuneInstanceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <path
      d="M508.3,78.8C508.3,35.4,473,0,429.5,0s-78.8,35.4-78.8,78.8c0,7.4,1,14.5,2.9,21.3L142.6,212.2
	c-14.5-17.1-36-27.9-60.1-27.9c-43.5,0-78.8,35.4-78.8,78.8c0,43.5,35.4,78.8,78.8,78.8c22.4,0,42.5-9.4,56.9-24.4l146.9,89.8
	c-2.8,8.1-4.4,16.8-4.4,25.8c0,43.5,35.4,78.8,78.8,78.8c43.5,0,78.8-35.4,78.8-78.8c0-32.4-19.7-60.3-47.7-72.4l39.5-203.3
	C473.9,156.6,508.3,121.7,508.3,78.8z M82.5,308.4c-25,0-45.3-20.3-45.3-45.3c0-25,20.3-45.3,45.3-45.3c25,0,45.3,20.3,45.3,45.3
	C127.7,288.1,107.4,308.4,82.5,308.4z M406,433.2c0,25-20.3,45.3-45.3,45.3s-45.3-20.3-45.3-45.3s20.3-45.3,45.3-45.3
	C385.6,387.9,406,408.2,406,433.2z M358.9,354.4c-21.6,0.5-41.1,9.7-55.1,24.3L156.9,289c2.8-8.1,4.4-16.8,4.4-25.8
	c0-7.4-1-14.5-2.9-21.3l211.1-112.1c7.8,9.2,17.7,16.6,28.9,21.5L358.9,354.4z M384.3,78.8c0-25,20.3-45.3,45.3-45.3
	c25,0,45.3,20.3,45.3,45.3c0,25-20.3,45.3-45.3,45.3S384.3,103.8,384.3,78.8z"
    />
  </Icon>
);
