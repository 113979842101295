import React, { FC, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Checkbox,
  Stack,
} from '@chakra-ui/react';
import { forEach } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, CheckboxFieldSchema, CheckboxFieldStyles } from '../types';

import { FormLabel } from './FormLabel';

export const CheckboxField: FC<FieldProps<CheckboxFieldSchema>> = ({
  id,
  name,
  field,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    isDisabled,
    helpIcon,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const { register, watch, setValue } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<CheckboxFieldStyles>('checkboxField', styles);

  const errorMessage = useErrorMessage(name, label);

  useEffect(() => {
    let data: Record<string, any> = {};
    forEach(
      field?.checkboxes,
      o => (data[o?.name] = o?.defaultChecked ?? o?.isChecked),
    );
    setValue(name, data);
  }, [field?.checkboxes, name, setValue]);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      mb={2}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Stack {...fieldStyles.checkboxGroup}>
        {field.checkboxes.map(checkbox => (
          <Checkbox
            key={checkbox.name}
            //name={checkbox.name}
            ref={register}
            isDisabled={isDisabled}
            data-testid={`${id}-${checkbox.name}`}
            onChange={e => {
              checkbox?.onChange?.(e);
              setValue(name, { [checkbox.name]: e.target.value });
            }}
            {...checkbox}
          >
            {checkbox.label || checkbox.name}
          </Checkbox>
        ))}
      </Stack>
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
