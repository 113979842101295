import { inlineObjectFieldStyles } from 'app/components/DataEntry/Form';

export const formStyles = (height?: any) => ({
  form: {
    formWidth: '700px',
    fieldSpacing: 6,
    container: { w: 'full', p: 4 },
    helpWrapper: { w: 'md', h: height ?? 'full' },
    //title: { pb: 4 },
  },
  objectField: {
    ...inlineObjectFieldStyles,
    propertyContainer: [{ flex: 1 }],
  },
  arrayField: {
    arrayContainer: {
      spacing: 0,
    },
    label: { fontSize: 'sm' },
    itemContainer: {
      bg: 'inherit',
      border: 'none',
      pb: 0,
      pl: 0,
    },
  },
});

export const cfsTemplateTypeStyles = {
  tag: {
    border: '1px solid',
    borderRadius: 'md',
    borderColor: 'gray.100',
    bg: 'white',
    px: 2,
    py: 1,
    fontSize: 'xs',
  },
  leftIcon: {
    color: 'green',
  },
};
