import React, { FC, useRef } from 'react';

import { FeatureStatus } from '@ariksa/profile';
import { ToDoStatus } from '@ariksa/profile/api';
import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { filter, map, values } from 'lodash';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  Drawer,
  PageHeaderWithIcon,
  CustomerJourneyBulbIcon,
  sortByOrderProvided,
} from 'components/index';
import { useNavBarContext } from 'components/Navigation/NavBar/context';
import { useMyTasks } from 'components/Navigation/NavBar/TopNavBar/RightComponent/MyTasks/useMyTasks';
import { DashboardOverviewTooltip } from 'containers/Dashboard/utils/OverviewDashboardTooltip';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const MyTasks: FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { journey } = useNavBarContext();
  const { isLoadingMyTasks, myTasks, taskRead } = journey;
  const ref = useRef(document.querySelector('.portal-container'));
  const { myTasksMapping, taskOrder } = useMyTasks();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          reversed
          label="Explore"
          icon={<CustomerJourneyBulbIcon />}
        />
      }
      styles={{
        content: { maxW: '2xl' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
      closeButton
      body={
        isLoadingMyTasks ? (
          <CustomSpinner />
        ) : (
          <Stack>
            {map(
              filter(
                sortByOrderProvided(values(myTasks), taskOrder),
                o => o.status === FeatureStatus.Active,
              ),
              o => (
                <Stack>
                  <HStack
                    w="full"
                    justify="space-between"
                    pt={2}
                    spacing={10}
                    alignItems="flex-start"
                  >
                    <Stack flex={1}>
                      <HStack>
                        <Box boxSize={5}>
                          <Center color="primary">
                            {myTasksMapping?.[o.id]?.icon}
                          </Center>
                        </Box>
                        <Box fontWeight={600} color="primary">
                          {o.name}
                        </Box>
                      </HStack>
                      <Box pl={8}>{o.description}</Box>
                      <Box pl={8}>
                        <Box w={20} color="primary">
                          <DashboardOverviewTooltip
                            {...myTasksMapping?.[o.id]}
                            onClickButton={() => {
                              myTasksMapping?.[o.id]?.onClickFooter();
                            }}
                            button="Learn more"
                            tooltip={
                              <Stack>
                                {map(myTasksMapping?.[o.id]?.description, d => (
                                  <Box>{d}</Box>
                                ))}
                              </Stack>
                            }
                          />
                        </Box>
                      </Box>
                    </Stack>
                    <Flex w={16}>
                      {taskRead?.[o.id]?.isLoading ? (
                        <CustomSpinner />
                      ) : taskRead?.[o.id]?.data?.user_todo_status ===
                        ToDoStatus.Complete ? (
                        <Box color="green.300">DONE</Box>
                      ) : taskRead?.[o.id]?.data?.user_todo_status ===
                        ToDoStatus.Incomplete ? (
                        <Box color="critical">TODO</Box>
                      ) : (
                        <CustomTooltip label="Can not determine. Please try again after sometime.">
                          <Box color="gray.300">-</Box>
                        </CustomTooltip>
                      )}
                    </Flex>
                  </HStack>
                  <Box borderBottom="1px solid" borderColor="gray.200" pt={1} />
                </Stack>
              ),
            )}
          </Stack>
        )
      }
    />
  );
};
