import React, { FC, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map, slice, sum } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import { limitedString } from '../../../../../../../../../utils/string';

export const DistributionByDocumentType: FC = () => {
  const { data } = useSelector(selectActiveResource);
  const { data: labels, isLoading } = data.distributionByDocumentType;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);
  console.log(labels);

  useEffect(() => {
    setTotal(sum(map(labels, o => o.count)));
    //const other = sum(map(slice(labels, 5, labels?.length), o => o.count));
    setChartData(
      map(slice(labels, 0, 5), o => ({
        name: o.type_name,
        value: o.count ?? 0,
        percent: o.percentage ?? 0,
      })),
    );
  }, [labels]);

  const colorMap = {
    'Table format': customTheme.colors.blue['300'],
    Documents: customTheme.colors.skyBlue['300'],
    Images: customTheme.colors.primary,
    Archive: customTheme.colors.green['300'],
    Other: customTheme.colors.orange['300'],
  };

  return (
    <FootprintBox
      content={
        <HStack w="full" h="full" justify="space-between" px={4} py={4}>
          <Box w="35%" h="full">
            <PieChart
              isLoading={isLoading}
              data={chartData}
              dataKey="value"
              total={formatNumber(total)}
              outerRadius="100%"
              innerRadius="88%"
              activeShape={false}
              colorMap={colorMap}
              legend={false}
            />
          </Box>
          <Stack w="65%" h="full" spacing={2} overflow="scroll">
            {map(chartData, o => (
              <HStack>
                <HStack w={32}>
                  <Center
                    boxSize={1}
                    bg={colorMap[o.name]}
                    borderRadius="full"
                  />
                  <Box>{limitedString(o.name, 20)}</Box>
                </HStack>
                <HStack flex={1}>
                  <Box w={20}>{o.value}</Box>
                  <Box w="full">
                    <Box
                      borderRadius="full"
                      h={1}
                      bg={colorOpacity(customTheme.colors.primary, 0.2)}
                      w={o.percent + '%'}
                    />
                  </Box>
                  <Box w={20}>{round(o.percent)}%</Box>
                </HStack>
              </HStack>
            ))}
          </Stack>
        </HStack>
      }
      label="Distribution by types of documents"
      isLoading={isLoading}
    />
  );
};
