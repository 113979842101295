import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useDataScanningService } from 'services/DataScanning';

import {
  formatTooltip,
  Heading4,
  renderTime,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { PageInfo } from 'components/Navigation';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';

interface Props {
  type: 'Data' | 'Document';
  row: Record<string, any>;
  isExpanded: boolean;
}

export const DataTypesBySourceSubComponent: FC<Props> = props => {
  const { type, row, isExpanded } = props;
  const { resourceUuid, resourceType } = useSelector(selectActiveResource);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page_size: 20,
    page_number: 1,
  });
  const { documentClassificationFileObject } = useDataScanningService();

  useEffect(() => {
    if (type === 'Document' && isExpanded)
      documentClassificationFileObject.sync({
        id: resourceUuid,
        docTypeId: row?.type_id,
        page: pageInfo.page_number,
        size: pageInfo.page_size,
      });
  }, [
    row,
    resourceUuid,
    pageInfo,
    documentClassificationFileObject,
    type,
    isExpanded,
  ]);

  const subComponentColumns = [
    {
      header: (
        <Box pl={10}>
          {resourceType === NativeResources.SimpleStorageService
            ? 'FILENAME PATH'
            : 'Table'}
        </Box>
      ),
      accessor: 'fetch_path',
      render: ({ row }) => {
        const value = row?.fetch_path || row?.file_path;
        return (
          <WithResourceIcon resourceType={IconTypes.Policy}>
            <CustomTooltip label={value}>{value}</CustomTooltip>
          </WithResourceIcon>
        );
      },
    },
    ...(resourceType === NativeResources.SimpleStorageService &&
    type === 'Document'
      ? []
      : [
          {
            header: 'Column name',
            align: 'left',
            render: ({ row }) => {
              return (
                <CustomTooltip
                  label={
                    <Stack w={'200px'}>
                      <Heading4 color={'orange'}>Column names</Heading4>
                      <Divider />
                      {row.column_names?.map(name => (
                        <Box>{name}</Box>
                      ))}
                    </Stack>
                  }
                >
                  <Text>
                    {row.column_names?.slice(0, 3).join(', ')}{' '}
                    {row.column_names.length > 3 ? '...' : ''}
                  </Text>
                </CustomTooltip>
              );
            },
          },
        ]),
    {
      header: 'Size',
      render: ({ row }) => formatNumber(row?.size || row?.file_size),
      align: 'right',
    },
    ...(type === 'Data'
      ? []
      : [
          {
            header: 'Sub-Types',
            align: 'left',
            render: ({ row }) => {
              return (
                <CustomTooltip
                  label={formatTooltip({
                    header: 'Sub-Types',
                    content: (
                      <Stack>
                        {row.sub_types?.map(name => (
                          <Box>{name}</Box>
                        ))}
                      </Stack>
                    ),
                    width: 'full',
                  })}
                  width={64}
                >
                  <HStack>
                    <Box>{row?.sub_types?.[0]}</Box>
                    {row?.sub_types?.length > 1 && (
                      <Tag
                        label={'+' + (row?.sub_types?.length - 1)}
                        styles={{
                          tag: { bg: 'primary' },
                          label: { color: 'white' },
                        }}
                      />
                    )}
                  </HStack>
                </CustomTooltip>
              );
            },
          },
        ]),
    ...(type === 'Data'
      ? [
          {
            header: 'Owner',
            render: ({ row }) => row?.source_owner?.replace('+', ','),
            align: 'left',
          },
        ]
      : []),
    {
      header: 'Last update',
      accessor: 'last_modified',
      render: ({ row }) =>
        renderTime({ value: row?.last_update || row?.last_modified }),
      align: 'left',
    },
  ];

  return (
    <SubComponentContainer>
      <Table
        columns={subComponentColumns}
        data={
          type === 'Data'
            ? row?.found_in
            : documentClassificationFileObject.data?.items
        }
        isLoading={documentClassificationFileObject.isLoading}
        {...(type === 'Document'
          ? {
              pagination: {
                totalCount: documentClassificationFileObject.data?.total ?? 0,
                pageInfo: pageInfo,
                onChange: setPageInfo,
              },
            }
          : {})}
      />
    </SubComponentContainer>
  );
};
