import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import {
  VolumeID,
  VolumeSize,
  Encrypted,
  EncryptedKeyID,
  KMSKeyID,
  SnapshotProgress,
  StorageTier,
  OwnerAlias,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const EbsSnapshotMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {OwnerAlias(resource)}
      {VolumeID(resource)}
      {VolumeSize(insight)}
      {Encrypted(insight)}
      {EncryptedKeyID(resource)}
      {KMSKeyID(resource)}
      {StorageTier(insight)}
      {SnapshotProgress(resource)}
    </Grid>
  );
};
