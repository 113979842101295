import { useCallback, useEffect, useReducer } from 'react';

import {
  Categories,
  NativeResources,
  Resources,
} from '@ariksa/inventory-core/api';
import dayjs from 'dayjs';
import { isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { getPublishedTime } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import {
  exploitableOptions,
  packageTypeOptions,
  patchAvailableOptions,
  vulnerabilitySeverityOptions,
} from 'containers/Visibility/Vulnerabilities/Components/utils';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';
import { CONTAINER_ON_VIRTUAL_MACHINE } from 'containers/Visibility/Vulnerabilities/types';
import { useSearchParams } from 'hooks/useSearchParams';

export const useVulnerabilities = () => {
  const {
    vulnerabilities,
    sort,
    tablesTabIndex,
    cves,
    selectedTab,
    publishedAt,
  } = useSelector(selectVulnerabilities);
  const { finalSearchTerm: searchTerm } = useSelector(selectSharedState);
  const { environmentId, accountId } = useAccessBoundary();
  const dispatch = useDispatch();

  const params = useSearchParams<{
    tab: string;
    search_term: string;
    cve_id: string;
  }>();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isExpanded: false,
      severity: vulnerabilitySeverityOptions[0],
      packageName: {},
      packageType: packageTypeOptions(selectedTab)[0],
      isExploitable: exploitableOptions[0],
      isInternetExposed: exploitableOptions[0],
      isPatchable: patchAvailableOptions[0],
    },
  );

  useEffect(() => {
    if (!!params.search_term || !!params.cve_id)
      dispatch(
        sharedStateActions.updateFinalSearchTerm(
          params.search_term || params.cve_id,
        ),
      );
  }, [params.search_term, dispatch, params.cve_id]);

  useEffect(() => {
    if (!!params.isPatchable)
      updateState({
        isPatchable:
          params.isPatchable === 'yes' || params.isPatchable === 'True'
            ? patchAvailableOptions[1]
            : patchAvailableOptions[2],
      });
    else updateState({ isPatchable: patchAvailableOptions[0] });
  }, [params.isPatchable]);

  useEffect(() => {
    if (!!params.isExploitable)
      updateState({
        isExploitable:
          params.isExploitable === 'yes' || params.isExploitable === 'True'
            ? exploitableOptions[1]
            : exploitableOptions[2],
      });
    else updateState({ isExploitable: exploitableOptions[0] });
  }, [params.isExploitable]);

  useEffect(() => {
    if (!!params.isInternetExposed)
      updateState({
        isInternetExposed:
          params.isInternetExposed === 'yes' ||
          params.isInternetExposed === 'True'
            ? exploitableOptions[1]
            : exploitableOptions[2],
      });
    else updateState({ isInternetExposed: exploitableOptions[0] });
  }, [params.isInternetExposed]);

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  const getVulnerabilities = useCallback(() => {
    const published =
      tablesTabIndex === 1
        ? { resolved_at: getPublishedTime(publishedAt?.value) }
        : { effective_start: getPublishedTime(publishedAt?.value) };
    const cvePublished = !!publishedAt?.value
      ? {
          publishedAt: dayjs()
            .subtract(publishedAt?.value, 'days')
            .utc()
            .format('YYYY-MM-DD'),
        }
      : {};
    const patchable =
      state.isPatchable?.value === 'yes'
        ? true
        : state.isPatchable?.value === 'no'
        ? false
        : undefined;
    const exploitable =
      state.isExploitable?.value === 'yes'
        ? true
        : state.isExploitable?.value === 'no'
        ? false
        : undefined;
    const internetExposedVm =
      state.isInternetExposed?.value === 'yes'
        ? true
        : state.isInternetExposed?.value === 'no'
        ? false
        : undefined;
    const type = { package_category: state.packageType?.value };
    const name = !!state.packageName?.value
      ? { package_category_name: state.packageName?.value }
      : undefined;
    const severityFilter = { severity: state.severity?.value };

    const commonPayload = {
      resolved: tablesTabIndex === 1,
      isPatchable: patchable,
      ...severityFilter,
      ...type,
      ...name,
      ...cvePublished,
    };

    switch (selectedTab) {
      case Resources.VirtualMachine:
      case Resources.ServerlessCompute:
      case Resources.Image:
      case NativeResources.ElasticContainerService:
      case CONTAINER_ON_VIRTUAL_MACHINE:
        let deployed = {};
        let resource = selectedTab as Resources;
        if (
          selectedTab === CONTAINER_ON_VIRTUAL_MACHINE ||
          selectedTab === NativeResources.ElasticContainerService
        ) {
          deployed = {
            deployed_in:
              selectedTab === CONTAINER_ON_VIRTUAL_MACHINE
                ? Resources.VirtualMachine
                : 'ContainerService',
          };
          resource = Resources.Containers;
        }
        dispatch(
          actions.getVulnerabilities({
            q: {
              vulnerabilityInsightRequest: {
                environment_id: environmentId,
                account_id: accountId ? [accountId] : [],
                agnostic_resource: resource,
                page: vulnerabilities.page.info.page_number,
                size: vulnerabilities.page.info.page_size,
                expanded: true,
                search_term: searchTerm,
                category:
                  selectedTab === NativeResources.ElasticContainerRegistry
                    ? Categories.ContainerService
                    : Categories.Compute,
                ...deployed,
                resolved: tablesTabIndex === 1,
                is_patchable: patchable,
                ...severityFilter,
                ...type,
                ...name,
                ...published,
              },
            },
            onSuccess: res => {
              if (!isEmpty(res?.items)) {
                if (selectedTab === Resources.VirtualMachine) {
                  dispatch(
                    sharedStateActions.getRiskContext({
                      q: {
                        riskContextRequest: {
                          uuids: map(res?.items, o => o?.uuid),
                        },
                      },
                    }),
                  );
                }
              }
            },
          }),
        );
        break;
      case NativeResources.ElasticContainerRegistry:
        const date =
          tablesTabIndex === 1
            ? { resolvedDate: getPublishedTime(publishedAt?.value) }
            : { effectiveStart: getPublishedTime(publishedAt?.value) };
        dispatch(
          actions.getEcrImages({
            q: {
              environmentId: environmentId,
              accountId: accountId ? [accountId] : [],
              ...date,
              isPatchable: patchable,
              ...severityFilter,
              searchTerm,
              resolved: tablesTabIndex === 1,
              page: vulnerabilities.page.info.page_number,
              pageSize: vulnerabilities.page.info.page_size,
            },
          }),
        );
        break;
      case 'cve':
        dispatch(
          actions.getCveVulnerabilities({
            q: {
              // environmentId,
              // accountId: accountId ? [accountId] : [],
              page: cves.page.info.page_number,
              pageSize: cves.page.info.page_size,
              searchTerm: !searchTerm ? undefined : searchTerm,
              sortOrder: sort.sortOrder,
              sortBy:
                sort.sortField === 'cvss_v3_score'
                  ? 'cvss_score'
                  : sort.sortField,
              exploitable,
              ...commonPayload,
            },
          }),
        );
        break;
      case IconTypes.Packages:
        dispatch(
          actions.getPackageVulnerabilities({
            q: {
              ...severityFilter,
              ...(tablesTabIndex === 1
                ? { resolvedAt: getPublishedTime(publishedAt?.value) }
                : { effectiveStart: getPublishedTime(publishedAt?.value) }),
              environmentId,
              accountId: accountId ? [accountId] : [],
              page: vulnerabilities.page.info.page_number,
              size: vulnerabilities.page.info.page_size,
              searchTerm: !searchTerm ? undefined : searchTerm,
              packageCategory: state.packageType?.value,
              packageCategoryName: state.packageName?.value,
              resolved: tablesTabIndex === 1,
              isPatchable: patchable,
              internetExposedVm,
              exploitable,
            },
          }),
        );
        break;
    }
  }, [
    dispatch,
    selectedTab,
    accountId,
    environmentId,
    vulnerabilities.page.info,
    cves.page.info,
    publishedAt,
    searchTerm,
    tablesTabIndex,
    state.isPatchable,
    state.severity,
    state.packageName,
    state.packageType,
    state.isExploitable,
    state.isInternetExposed,
    sort,
  ]);

  return {
    getVulnerabilities,
    searchTerm,
    isPatchable: state.isPatchable,
    packageType: state.packageType,
    packageName: state.packageName,
    severity: state.severity,
    isExpanded: state.isExpanded,
    isExploitable: state.isExploitable,
    isInternetExposed: state.isInternetExposed,
    updateValue,
  };
};
