import React, { FC, useCallback } from 'react';

import { Box, Stack, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { MultiLevelPieChart } from 'components/Visualization/Charts/PieChart';
import { selectCompliance } from 'containers/Compliance/selectors';

import {
  getChecksData,
  getSeverityData,
  innerPieProps,
  outerPieProps,
} from './utils';

export const PolicyPieCharts: FC = () => {
  const { comparePolicies } = useSelector(selectCompliance);
  const { policy1, policy2 } = comparePolicies;

  const renderLegend = props => {
    const { payload } = props;
    return (
      <ul style={{}}>
        {payload.map(
          (entry, index) =>
            (entry.value === 'Passed' || entry.value === 'Failed') && (
              <li
                key={`item-${index}`}
                style={{ listStyle: 'none', alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: entry?.color,
                    display: 'inline-block',
                    marginRight: '4px',
                  }}
                ></div>
                <span style={{ fontSize: '12px', color: '#666' }}>
                  {entry.value} ({entry?.payload?.value})
                </span>
              </li>
            ),
        )}
      </ul>
    );
  };

  const getPieChartData = useCallback(policy => {
    const data = policy?.data?.check_stats;
    const checks = getChecksData(data);
    const severityData = getSeverityData(data);
    return {
      pie: [
        { data: checks, ...innerPieProps },
        { data: severityData, ...outerPieProps },
      ],
      total: checks?.reduce((s, el) => s + el?.value || 0, 0),
      isLoading: policy?.isLoading ?? false,
      styles: {
        legend: {
          content: renderLegend,
          wrapperStyle: { height: '75%' },
          pie: { margin: { left: 100 } },
        },
      },
    };
  }, []);

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={3} w="full" height="300px">
      <GridItem colSpan={1}>
        <Stack color="gray.300" fontWeight={600}>
          <Box mt={10} pr={6} maxW={56}>
            Comparison of passed and failed checks categorized by severity
          </Box>
          <Box pt={6}>No. of entities assessed</Box>
        </Stack>
      </GridItem>
      <GridItem colSpan={2}>
        <Box h="100%" w="full" px={2}>
          <MultiLevelPieChart {...getPieChartData(policy1)} />
        </Box>
      </GridItem>
      <GridItem colSpan={2}>
        <Box h="100%" w="full" px={2}>
          <MultiLevelPieChart {...getPieChartData(policy2)} />
        </Box>
      </GridItem>
    </Grid>
  );
};
