import { Reducer } from 'redux';
import { Saga } from 'redux-saga';

import { useInjectReducer, useInjectSaga } from './redux-injectors';
import { RootStateKeyType } from './types/injector-typings';

export function useInjector<Key extends RootStateKeyType>(
  key: Key,
  reducer: Reducer,
  saga: Saga,
) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
}
