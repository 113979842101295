import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useLocation } from 'react-use';

import { sortByOrderProvided, WithResourceIcon } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconTypes } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation/SideNav';
import { useSearchParamFunctions } from 'containers/App/hooks/useSearchParamFunctions';
import { AlertDashboardAllFindings } from 'containers/Findings/FindingsOverview/AllFindings';
import { useAlertsContext } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertsContext';
import { sideNavCategoryOrder } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/utils';
import { IndividualCategoryDashboard } from 'containers/Findings/FindingsOverview/IndividualCategoryDashboard';
import { AlertDashboardOverview } from 'containers/Findings/FindingsOverview/Overview';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

export const AlertTables: FC = () => {
  const dispatch = useDispatch();
  const params = useSearchParams<{
    categoryClass: string;
    alert_category: string;
    alertCategories: string[];
    tab: string;
    redirect: string;
  }>();

  const { currentCategory, alertCategories } = useSelector(
    selectFindingsOverview,
  );
  const { updateValue } = useSearchParamFunctions();

  const [items, setItems] = useState<SideNavMenuItemProps[]>([]);

  const { toggleRefresh } = useAlertsContext();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const location = useLocation();
  const navigate = useNavigate();

  /*//set parameters from localstorage to url
  const setParamsFromLocalStorage = useCallback(
    tab => {
      const storageKey = 'findings_overview_' + camelcase(tab);
      const alertCategory = localStorage.getItem(
        storageKey + '_alert_category',
      );
      const severity = localStorage.getItem(storageKey + '_severity');
      const resourceType = localStorage.getItem(storageKey + '_resource_type');

      let searchParams = '?tab=' + tab;
      const setValue = (f, v) => {
        searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
      };

      if (!!alertCategory) setValue('alertCategory', alertCategory);
      if (!!severity) setValue('severity', severity);
      if (!!resourceType) setValue('resourceType', resourceType);
      if (!!searchParams) navigate(location.pathname + searchParams);
    },
    [history, location],
  );

  useEffect(() => {
    //if (!params.alertCategory && !params.severity && !params.resourceType)
      //setParamsFromLocalStorage(params?.tab);
  }, [
    currentCategory,
    setParamsFromLocalStorage,
    params.alertCategory,
    params.resourceType,
    params.severity,
    params.tab,
  ]);*/

  const redirectTo = useCallback(
    (tab: string) => {
      //updateValue('tab', tab, 'findings_overview_tab');
      navigate(`${location.pathname}?tab=${tab}`);
      //setParamsFromLocalStorage(tab);
      //dispatch(actions.updateCurrentCategory(tab))
    },
    [navigate, location.pathname],
  );

  // create sidebar items
  useEffect(() => {
    const categories = map(alertCategories.data, (each, key) => {
      return {
        // shows up in sidebar
        title: (
          <CustomTooltip label={key}>
            <Flex fontWeight={600}>{key}</Flex>
          </CustomTooltip>
        ),
        key: key,
        icon: <AriksaIcon type={key} />,
        component: <IndividualCategoryDashboard title={key} />,
        /*secondary: map(each, o => ({
          title: (
            <CustomTooltip
              label={alertCategoryTooltipInfo[toPascalCase(o?.category)]}
            >
              <Flex pl={8}>{startCase(o?.category?.toLowerCase())}</Flex>
            </CustomTooltip>
          ),
          key: o?.category + '?' + key,
          component: (
            <IndividualCategoryDashboard
              alertCategoryInfo={{ ...o, categoryClass: key }}
            />
          ),
        })),*/
      };
    });
    const orderedCategories = sortByOrderProvided(
      categories,
      sideNavCategoryOrder,
    ) as SideNavMenuItemProps[];

    const items: SideNavMenuItemProps[] = [
      {
        title: (
          <Box>
            <WithResourceIcon resourceType={IconTypes.Overview} spacing={2}>
              OVERVIEW
            </WithResourceIcon>
          </Box>
        ),
        key: 'Overview',
        component: <AlertDashboardOverview />,
      },
      {
        title: (
          <Box>
            <WithResourceIcon resourceType={IconTypes.AllFindings} spacing={2}>
              ALL
            </WithResourceIcon>
          </Box>
        ),
        key: 'AllFindings',
        component: <AlertDashboardAllFindings />,
        showDivider: true,
      },
      {
        title: <Box cursor={'pointer'}>FINDINGS CATEGORIES</Box>,
        key: 'findingsCategories',
        showArrow: false,
        styles: {
          menuItem: {
            fontWeight: 600,
            cursor: 'default',
            color: 'black',
            _hover: { color: 'black' },
          },
          menu: { index: undefined, defaultIndex: [0] },
        },
        secondary: orderedCategories,
      },
    ];
    setItems(items);
  }, [toggleRefresh, alertCategories.data]);

  //update tab based on search
  useEffect(() => {
    !!aiParamExtraction.data?.category_class &&
      dispatch(
        actions.updateCurrentCategory(aiParamExtraction.data?.category_class),
      );
  }, [aiParamExtraction.data?.category_class, dispatch]);

  //update tab based on url
  useEffect(() => {
    let tab = '';
    if (!!params?.tab) tab = params.tab;
    else if (!!params?.categoryClass) tab = params?.categoryClass;
    !!tab && dispatch(actions.updateCurrentCategory(tab));
  }, [params, dispatch]);

  return (
    <Box h="full">
      <SideNav
        leftAlignSecondaryItems
        menuItems={items}
        current={currentCategory}
        onChange={current => {
          redirectTo(current as string);
        }}
        height="full"
        styles={{
          contentOuterWrapper: { card: { p: 0, bg: 'transparent' } },
          menu: { index: [2] },
        }}
        showContentHeader={false}
        isLoading={alertCategories.isLoading}
      />
    </Box>
  );
};
