import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { PackagesIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getSumOfValues } from 'containers/Dashboard/utils/utils';

export const ComputeEntities: React.FC = () => {
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();
  const { computeCount } = useSelector(selectDashboard);

  return (
    <SummaryCard
      label="Compute"
      icon={<PackagesIcon />}
      change={{
        isLoading: false,
        first: computeCount.data.previous_count ?? 0,
        last: computeCount.data.current_count ?? 0,
        percentChange: computeCount.data?.percentage_change ?? 0,
      }}
      cloudProviders={[]}
      count={getSumOfValues(data?.items?.['Compute'])}
      isLoading={isLoading}
      onClick={() => navigate('/inventory/summary?tab=Compute')}
      showPercentChange={true}
      labelTooltip={formatTooltip({
        header: 'Compute',
        content: 'Compute resources in public resources',
      })}
    />
  );
};
