import { useCallback, useEffect, useState } from 'react';

import { useDndMonitor, useDraggable } from '@dnd-kit/core';

export const useGraphDnd = ({ app, ref }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'draggable-graph',
  });

  useEffect(() => {
    setNodeRef(ref.current);
  }, [ref, setNodeRef]);

  const onDragStart = useCallback(
    e => {
      setPosition({ x: app.stage.x, y: app.stage.y });
    },
    [app],
  );
  const onDragMove = useCallback(
    e => {
      const { delta } = e;
      app.stage.x = delta.x + position.x;
      app.stage.y = delta.y + position.y;
    },
    [position, app],
  );

  const onDragEnd = useCallback(
    e => {
      setPosition({ x: app.stage.x, y: app.stage.y });
    },
    [app],
  );

  useDndMonitor({
    onDragStart,
    onDragMove,
    onDragEnd,
  });

  return {
    listeners,
  };
};
