export const RESOURCE_INSTANCE_NODE = 'resource_instance_node';
export const HEADER_NODE = 'header-node';
export const HIDDEN_ROOT_NODE = 'hidden-root-node';
export const TESTING_RECT_NODE = 'testing_-rect_node';
export const TESTING_CIRCLE_NODE = 'testing_circle_node';
export const ACCOUNT_NODE = 'account_node';
export const PORT_NODE = 'port_node';
export const REGION_NODE = 'region_node';
export const INTERNET_NODE = 'internet_node';
export const ROLE_POLICY_NODE = 'role_policy_node';
export const COMMAND_NODE = 'command_node';
export const ACTION_NODE = 'action_node';
export const USER_NODE = 'user_node';
export const RESOURCE_TYPE_NODE = 'resource_type';
export const PERMISSION_NODE = 'permission_node';
export const POLICY_NODE = 'policy_node';
export const ROLE_ENTITY_NODE = 'role_entity_node';
export const BUSINESS_UNIT_NODE = 'business_unit_node';
export const ORG_UNIT_NODE = 'org_unit_node';
export const ORG_GROUP_NODE = 'org_group_node';
export const USER_GROUP_NODE = 'user_group_node';
export const ORG_ACCOUNT_NODE = 'org_account_node';
export const INGRESS_NODE = 'ingress_node';

export enum ResourceNode {
  Rect = 'resource-node-rect',
  Circle = 'resource-node-circle',
  Square = 'resource-node-square',
}
