import { useCallback, useState } from 'react';

import { SearchTerm } from '@ariksa/inventory-core';
import {
  SearchApiAiSuggestRequest,
  SearchSuggestResponse,
} from '@ariksa/inventory-core/api';
import { AxiosPromise } from 'axios';
import { flatten, identity, throttle, unionBy } from 'lodash';
import map from 'lodash/map';
import { useDispatch } from 'react-redux';

import { InventoryService } from 'api/services';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

export const useQuerySearchTerms = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const getSimpleSuggestions = (query: string) => {
    const payload = {
      searchWrapperOptionalCategory: {
        query,
      },
      saved: true,
    };
    return InventoryService.Search.searchSuggestions(payload);
  };

  const getAriksaAISuggestions = (query: string) => {
    const aiPayload: SearchApiAiSuggestRequest = {
      aISuggestQuery: {
        query,
        suggestion_count: 5,
      },
    };

    return InventoryService.Search.aiSuggest(aiPayload);
  };

  const querySuggestions = useCallback(
    async (
      promises: AxiosPromise<Pick<SearchSuggestResponse, 'search_terms'>>[],
    ) => {
      return Promise.all(promises).then(results => {
        return map(
          results,
          res =>
            res.data.search_terms?.map(st => {
              return {
                label: st.name ?? '-',
                value: {
                  uuid: st.id,
                  ...st,
                } as SearchTerm,
              };
            }) ?? [],
        );
      });
    },
    [],
  );

  const getSearchQuerySuggestion = useCallback(
    throttle((term: string, cb = identity) => {
      const query = term.trim();
      const promises: AxiosPromise<
        Pick<SearchSuggestResponse, 'search_terms'>
      >[] = [getSimpleSuggestions(query)];
      if (query.split(' ').length > 1) {
        promises.push(getAriksaAISuggestions(query));
      }
      setIsLoading(true);
      querySuggestions(promises)
        .then(suggestions => {
          const uniqSuggestions = unionBy(flatten(suggestions), 'value.id');
          dispatch(actions.setSearchTermOptions(uniqSuggestions));
          cb(uniqSuggestions);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }, 2000),
    [dispatch, querySuggestions],
  );

  return {
    getSearchQuerySuggestion,
    isLoadingSuggestion: isLoading,
  };
};
