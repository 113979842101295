import React from 'react';

import { SummaryType } from '@ariksa/inventory-core';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { EC2Summary } from 'containers/Inventory/CloudInventory/Components/Summary/EC2Summary/Components/EC2Summary';

import { widgetStyles } from '../styles';

import { ByCvssScoreOnVulnerabilities } from './Components/ByCvssScoreOnVulnerabilities';

export const availableWidgets: Record<string, WidgetProps> = {
  byOS: {
    name: 'By OS on Virtual Machines',
    styles: { ...widgetStyles },
    components: {
      content: <EC2Summary summaryType={SummaryType.OperatingSystem} />,
    },
  },
  byTechnology: {
    name: 'By Technology on Virtual Machines',
    styles: { ...widgetStyles },
    components: {
      content: <EC2Summary summaryType={SummaryType.Technology} />,
    },
  },
  byVulnerabilities: {
    name: 'By Vulnerabilities on Virtual Machines',
    styles: { ...widgetStyles },
    components: {
      content: <EC2Summary summaryType={SummaryType.VulnerabilitySeverity} />,
    },
  },
  byCvssScoreOnVulnerabilities: {
    name: 'By CVSS Score for Vulnerabilities',
    styles: { ...widgetStyles },
    components: {
      content: <ByCvssScoreOnVulnerabilities />,
    },
  },
};
