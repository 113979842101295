import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ChartType } from '@ariksa/reporting';
import dayjs from 'dayjs';
import {
  each,
  floor,
  forEach,
  includes,
  isArray,
  isEmpty,
  sumBy,
  values,
} from 'lodash';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { QueryStatus } from 'services/types';

import { ReportingService } from 'api/services';
import { FormAction } from 'components/DataEntry';
import {
  IDashboardLayout,
  WidgetProps,
} from 'components/Visualization/CDashboard/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { getWidgetChartType } from 'containers/Reports/ReportsDashboard/Components/config';
import { ReportWidget } from 'containers/Reports/ReportsDashboard/Components/ReportWidget';
import { availableWidgetMap } from 'containers/Reports/ReportsDashboard/Components/widget';
import { selectReportsDashboard } from 'containers/Reports/ReportsDashboard/selectors';
import { actions } from 'containers/Reports/ReportsDashboard/slice';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { toTitleCase } from 'utils/string';

export const useReportsDashboard = () => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();

  const [chartIds, setChartIds] = useState<string[]>([]);
  const [currentChart, setCurrentChart] = useState<Record<string, any>>({});
  const [editChart, setEditChart] = useState(false);

  const [actionType, setActionType] = useState<FormAction>('Add');
  const [isEditDashboard] = useState(pathname === '/reports/dashboard/edit');
  const [chartsData, setChartsData] = useState<Record<string, any>>({});
  const { toCloudAccountName } = useCloudAccountId();
  const { environmentId, accountId } = useAccessBoundary();

  const { reports, reportsInfo } = useSelector(selectReportsDashboard);

  //set chart ids
  useEffect(() => {
    setChartIds(reportsInfo.data.map(r => r.id!));
  }, [reportsInfo.data]);

  //set charts data
  useEffect(() => {
    let data = {};
    forEach(reportsInfo.data, (o, index) => {
      const report = reports[o?.id!];
      if (!isEmpty(report.data))
        data[o.id!] = {
          ...report.data,
          isLoading: report.isLoading,
          expanded: false,
        };
      else
        data[o.id!] = {
          ...o,
          index,
          expanded: false,
          chart_details: [],
          isLoading: report.isLoading,
        };
    });
    setChartsData(data);
  }, [reports, reportsInfo.data]);

  const getChartData = useCallback(
    (data, index) => {
      let chartDetails: Record<string, any> = {};
      let fields: string[] = [];
      const formatDate = date => dayjs(date).format('DD-MMM');

      switch (data.chart_type) {
        case ChartType.GovernanceScore:
          forEach(data.chart_details, (m, date) => {
            chartDetails[date] = {
              Date: formatDate(date),
            };
            forEach(m, s => {
              chartDetails[date][toCloudAccountName(s?.account_id)] = s?.score;
              if (!includes(fields, toCloudAccountName(s?.account_id)))
                fields.push(toCloudAccountName(s?.account_id));
            });
          });
          break;
        case ChartType.InstancesByRegion:
        case ChartType.InstancesByResourceType:
          forEach(data.chart_details, (m, date) => {
            chartDetails[date] = {
              Date: formatDate(date),
            };
            forEach(m, s => {
              chartDetails[date][s?.region] = s?.count;
              if (!includes(fields, s?.region)) fields.push(s?.region);
            });
          });
          break;
        case ChartType.HostsWithUnpatchedOsAndTechnology:
          const osData = data?.chart_details?.unpatched_os;
          const technologyData = data?.chart_details?.unpatched_technology;
          forEach(
            osData,
            (item, date) =>
              (chartDetails[date] = {
                Date: formatDate(date),
                'Unpatched OS': item,
                'Unpatched Technology': technologyData?.[date],
              }),
          );
          break;
        case ChartType.VulnerabilityOccurrences:
          forEach(data?.chart_details?.critical_resources, (item, date) => {
            chartDetails[date] = {
              Date: formatDate(date),
              Critical: item,
              High: data?.high_resources?.[date],
              Medium: data?.medium_resources?.[date],
              Low: data?.low_resources?.[date],
            };
          });
          break;
        case ChartType.TotalAlerts:
        case ChartType.TotalFindings:
          forEach(data.multi_chart_details, (findings, findingType) => {
            let type = 'Resolved';
            switch (findingType) {
              case 'active_findings':
              case 'active_alerts':
                type = 'Active';
                break;
              case 'open_findings':
              case 'open_alerts':
                type = 'Open';
                break;
            }

            fields.push(type);
            forEach(findings, (o, date) => {
              chartDetails[date] = {
                ...chartDetails[date],
                Date: formatDate(date),
                [type]:
                  o?.critical_alerts +
                  o?.medium_alerts +
                  o?.low_alerts +
                  o?.high_alerts,
              };
            });
          });
          break;
        case ChartType.ContainersWithHighVulnerabilities:
        case ChartType.MachinesWithHighVulnerabilities:
          forEach(
            data.multi_chart_details?.high_resources,
            (o, date) =>
              (chartDetails[date] = {
                'Containers with High Vulnerabilities': o,
                'Machines with High Vulnerabilities': o,
                Date: formatDate(date),
              }),
          );
          break;
        case ChartType.ContainersWithCriticalVulnerabilities:
        case ChartType.MachinesWithCriticalVulnerabilities:
          forEach(
            data.multi_chart_details?.critical_resources,
            (o, date) =>
              (chartDetails[date] = {
                'Containers with Critical Vulnerabilities': o,
                'Machines with Critical Vulnerabilities': o,
                Date: formatDate(date),
              }),
          );
          break;
        default:
          forEach(data.chart_details, (m, date) => {
            chartDetails[date] = {
              Critical: m?.critical_alerts,
              Medium: m?.medium_alerts,
              Low: m?.low_alerts,
              High: m?.high_alerts,
              Date: formatDate(date),
              Containers: m,
              Identities: m,
              Roles: m,
              'Data Sources': m,
              Resources: m,
              Machines: m,
              Value: m,
              Instances: isArray(m) ? sumBy(m, l => l?.count) : m,
            };
          });
      }

      return {
        ...data,
        index,
        fields,
        chart_details: values(chartDetails),
      };
    },
    [toCloudAccountName],
  );

  const getIndividualReport = useCallback(
    (chart: Record<string, any>, index: number, timeRange: string) => {
      const getStartDate = (value, unit) =>
        dayjs().subtract(value, unit).format('YYYY-MM-DD');

      let startDate = getStartDate(6, 'days');
      switch (timeRange) {
        case '1M':
          startDate = getStartDate(1, 'month');
          break;
        case '3M':
          startDate = getStartDate(3, 'months');
          break;
        case '1Y':
          startDate = getStartDate(1, 'year');
          break;
      }
      const payload = {
        id: chart?.id!,
        startDate,
        endDate: dayjs().format('YYYY-MM-DD'),
      };
      dispatch(
        actions.getReportingCharts({
          q: payload,
          status: QueryStatus.pending,
        }),
      );

      ReportingService.Charts.getChartDetails(payload).then(res => {
        let data = { ...chart, index, chart_details: [] };
        if (!isEmpty(res.data)) data = getChartData(res.data, index);

        dispatch(
          actions.getReportingCharts({
            q: payload,
            status: QueryStatus.fulfilled,
            data: { ...data, timeRange },
          }),
        );
      });
    },
    [dispatch, getChartData],
  );

  const getReportingCharts = useCallback(() => {
    dispatch(
      actions.getReportsInfo({
        q: {
          dashboardFlag: true,
          accountId: !!accountId ? [accountId] : [],
          environmentId,
        },
        onSuccess: res => {
          each(res, (chart, index) => {
            getIndividualReport(chart, index, '1W');
          });
        },
      }),
    );
  }, [dispatch, getIndividualReport, accountId, environmentId]);

  const onAddChart = useCallback(() => {
    setActionType('Add');
    setEditChart(true);
  }, []);

  //filter(allCharts, o => !includes(charts, o.key))

  const allCharts = useMemo(
    () => map(ChartType, o => ({ label: toTitleCase(o), value: o })),
    [],
  );

  const onRemoveChart = useCallback(
    id => {
      setChartIds(filter(chartIds, o => o !== id));
    },
    [chartIds],
  );

  const createWidgetConfig = useCallback(
    chart => {
      // extending default layout
      return {
        type: getWidgetChartType(chart?.chart_type),
        i: chart?.id,
        x: (chart?.index * 4) % 12,
        y: floor(chart?.index / 3) * 8,
        w: 4,
        h: 8,
        minH: 4,
        minW: 3,
        isResizable: false,
        //static: false,
        isDraggable: isEditDashboard,
        ...chart?.chart_position,
      };
    },
    [isEditDashboard],
  );

  const onClickEditChart = useCallback(chart => {
    setEditChart(true);
    setCurrentChart(chart);
    setActionType('Update');
  }, []);

  const createDashboardLayout = useCallback(
    (id: string, title: string): IDashboardLayout => {
      let availableWidgets: Record<string, WidgetProps> = {};
      forEach(chartIds, o => {
        const report = chartsData[o];
        if (!report) return;
        const chartType = getWidgetChartType(report?.chart_type);
        const widget = availableWidgetMap[chartType];

        if (widget) {
          availableWidgets[o] = {
            flex: true,
            components: {
              content: (
                <ReportWidget
                  onClickRemove={() => onRemoveChart(o)}
                  onClickEdit={() => onClickEditChart(report)}
                  currentRecord={report}
                  isLoading={report.isLoading}
                  getReportingChartDetails={getIndividualReport}
                />
              ),
            },
          };
        }
      });

      const widgetLayouts = map(chartIds, o =>
        createWidgetConfig(chartsData[o]),
      );

      return {
        id,
        title,
        widgets: widgetLayouts,
        available: availableWidgets,
      };
    },
    [
      chartIds,
      chartsData,
      onRemoveChart,
      onClickEditChart,
      createWidgetConfig,
      getIndividualReport,
    ],
  );

  return {
    allCharts,
    charts: chartIds,
    createDashboardLayout,
    createWidgetConfig,
    editChart,
    setEditChart,
    currentChart,
    actionType,
    onAddChart,
    getReportingCharts,
    onClickEditChart,
    setCurrentChart,
    getChartData,
  };
};
