import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RoleIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M20,9.6837158V9.5c0-4.4182739-3.5817261-8-8-8s-8,3.5817261-8,8v0.1837158C2.8374634,
        10.0969849,2,11.1956787,2,12.5c0,1.3260498,0.8661499,2.4382324,2.059082,2.8346558C4.4819946,
        19.3838501,7.8903198,22.4975586,12,22.5c4.1096191-0.0025024,7.5178833-3.1162109,
        7.9408569-7.1653442C21.1338501,14.9382935,22,13.8261108,22,12.5C22,11.1956787,
        21.1625366,10.0969849,20,9.6837158z M12.4145508,21.4877319C8.7549438,21.7050781,
        5.5910034,19.0657349,5.0823364,15.5h13.8375244C18.4540405,18.7602539,15.7550659,
        21.2893677,12.4145508,21.4877319z M19,14.5H5c-1.1045532,0-2-0.8954468-2-2s0.8954468-2,
        2-2h2.5C7.776123,10.5,8,10.276123,8,10S7.776123,9.5,7.5,9.5H5c0-2.9838867,1.8704224-5.5248413,
        4.5-6.5321655V7.5c0,0.0001831,0,0.0003662,0,0.0006104C9.5001831,7.7765503,9.723999,8.0001831,
        10,8c0.0001831,0,0.0003662,0,0.0006104,0C10.2765503,7.9998169,10.5001831,7.776001,10.5,
        7.5V2.6651611C10.9837036,2.5594482,11.4846191,2.5,12,2.5s1.0162964,0.0594482,1.5,
        0.1651611V7.5c0,0.0001831,0,0.0003662,0,0.0006104C13.5001831,7.7765503,13.723999,
        8.0001831,14,8c0.0001831,0,0.0003662,0,0.0006104,0C14.2765503,7.9998169,14.5001831,
        7.776001,14.5,7.5V2.9678345C17.1295776,3.9751587,19,6.5161133,19,9.5h-2.5C16.223877,
        9.5,16,9.723877,16,10s0.223877,0.5,0.5,0.5H19c1.1045532,0,2,0.8954468,2,2S20.1045532,14.5,19,14.5z"
      />
    </g>
  </Icon>
);
