import React from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';

import { H3 } from 'components/DataDisplay';

interface Props {
  onClose: () => void;
}

export const AboutAriksaOutpostForVulnerability = (props: Props) => {
  return (
    <Stack spacing={6}>
      <HStack justify="space-between">
        <H3>About Ariksa Outpost for Vulnerability</H3>
      </HStack>
      <Box>
        Ariksa Outpost for Vulnerability provides agentless, vulnerability
        management for 100% of your virtual machines and workloads. It detects
        vulnerabilities, user activity, network access, credential leaks of
        private keys and other risks associated with your virtual machines and
        workloads.
      </Box>
      <Stack>
        <H3>Deployment</H3>
        <Box>
          Ariksa Outpost for Vulnerability needs to be deployed in every region
          that contain virtual machines for complete coverage
        </Box>
      </Stack>
    </Stack>
  );
};
