import { SensitivityCategory } from '@ariksa/data-scanning';
import { ceil, includes } from 'lodash';

import { customizedYAxisTick } from 'components/Visualization/Charts/utils';
import { vulnerabilityLineChartStyles } from 'containers/Visibility/Vulnerabilities/Components/styles';

export const commonBarChartStyles = {
  ...vulnerabilityLineChartStyles,
  barChart: {
    margin: {
      top: 20,
      left: -25,
      right: 10,
    },
  },
};

export const formatYAxis = tick => {
  const value = ceil((tick ?? 0) / 1000);
  if (value === 0) return `${value}`;
  return value + 'K';
};

export const barChartStyles = (regions: string[] = []) => ({
  ...vulnerabilityLineChartStyles,
  yAxis: {
    tickLine: false,
    axisLine: false,
    tick: props => customizedYAxisTick(props, 1000),
    label: {
      value: 'Thousands',
      angle: -90,
      position: 'insideTopRight',
      offset: 55,
      fontSize: 12,
    },
  },
  tooltip: {
    formatter: (value, name, entry) => {
      if (name === 'Critical') return entry?.payload?.actualCritical;
      if (name === 'High') return entry?.payload?.actualHigh;
      if (name === SensitivityCategory.Confidential)
        return entry?.payload?.['actual' + SensitivityCategory.Confidential];
      if (name === SensitivityCategory.Restricted)
        return entry?.payload?.['actual' + SensitivityCategory.Restricted];
      if (name === SensitivityCategory.Public)
        return entry?.payload?.['actual' + SensitivityCategory.Public];
      if (name === SensitivityCategory.Internal)
        return entry?.payload?.['actual' + SensitivityCategory.Internal];
      if (includes(regions, name)) return entry?.payload?.['actual' + name];
      else return entry?.payload?.actualCount;
    },
  },
  barChart: {
    margin: {
      top: 20,
      left: -5,
      right: 0,
      bottom: 1,
    },
  },
});
