import {
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertResponse,
  AlertsApiGetAlertByIdRequest,
  AlertsApiUpdateAlertRequest,
  ClientsApiNotifyClientRequest,
  ClientsApiUpdateTicketRequest,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { reduce } from 'lodash';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { IGetAlertByUserUrl } from 'api/alerts/types';
import {
  AlertDrawer,
  ResourceDetails,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

export const initialState: AlertDrawer = {
  resourceId: '',
  resourceCloudId: '',
  resourceAccountId: '',
  resourceType: '',
  internetMapScale: 1,
  dependencyGraphScale: 1,
  resourceInsight: QueryState.init({}),
  createTicket: QueryState.init({}),
  createNotification: QueryState.init({}),
  updateDueDate: QueryState.init({}),
  alert: QueryState.init({} as AlertResponse),
  updateAlertStatus: QueryState.init({}),
  alertsByRuleId: PagedQueryState.init([]),
  alertListStatus: QueryState.init({}),
  alertStatus: QueryState.init({}),
};

const alertDrawerSlice = createSlice({
  name: 'alertDrawer',
  initialState,
  reducers: {
    getResourceInsight(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.resourceInsight = QueryState.next(state.resourceInsight, action, {
        mapData: r => r?.items?.[0],
      });
    },
    updateInternetMapScale(state, action: PayloadAction<number>) {
      state.internetMapScale = action.payload;
    },
    updateDependencyGraphScale(state, action: PayloadAction<number>) {
      state.dependencyGraphScale = action.payload;
    },
    updateResourceDetails(state, action: PayloadAction<ResourceDetails>) {
      state.resourceId = action.payload.resourceId;
      state.resourceCloudId = action.payload.resourceCloudId;
      state.resourceAccountId = action.payload.resourceAccountId;
      state.resourceType = action.payload.resourceType;
    },
    createTicket(
      state,
      action: QueryAction<any, ClientsApiNotifyClientRequest>,
    ) {
      state.createTicket = QueryState.next(state.createTicket, action);
    },
    createNotification(
      state,
      action: QueryAction<any, ClientsApiNotifyClientRequest>,
    ) {
      state.createNotification = QueryState.next(
        state.createNotification,
        action,
      );
    },
    updateDueDate(
      state,
      action: QueryAction<any, AlertsApiUpdateAlertRequest>,
    ) {
      state.updateDueDate = QueryState.next(state.updateDueDate, action);
    },
    getAlertById(
      state,
      action: QueryAction<AlertResponse, AlertsApiGetAlertByIdRequest>,
    ) {
      state.alert = QueryState.next(state.alert, action);
    },

    getAlertListStatus(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.alertListStatus = QueryState.next(state.alertListStatus, action, {
        mapData: r => {
          return reduce(
            r,
            (obj, v) => {
              return {
                ...obj,
                [v.alert_id]: v.logs,
              };
            },
            {},
          );
        },
      });
    },
    getAlertStatus(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.alertStatus = QueryState.next(state.alertStatus, action, {
        mapData: r => {
          return reduce(
            r,
            (obj, v) => {
              return {
                ...obj,
                [v.alert_id]: v.logs,
              };
            },
            {},
          );
        },
      });
    },
    updateAlertStatus(
      state,
      action: QueryAction<any, ClientsApiUpdateTicketRequest>,
    ) {
      state.updateAlertStatus = QueryState.next(
        state.updateAlertStatus,
        action,
      );
    },
    getAlertsByRuleId(
      state,
      action: QueryAction<AlertPaginatedResponse, IGetAlertByUserUrl>,
    ) {
      state.alertsByRuleId = PagedQueryState.next(
        state.alertsByRuleId,
        action,
        {
          mapData: r => r.results,
          mapTotalCount: r => r.total_results,
        },
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = alertDrawerSlice;
