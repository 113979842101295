import React from 'react';

import { Skeleton } from '@chakra-ui/react';
import startCase from 'lodash/startCase';

import { Tag } from 'components/DataDisplay';
import {
  AggregatedAlert,
  AlertCategoryClass,
} from 'containers/Findings/FindingsOverview/types';

export interface ITag {
  label: string;
  value: any;
  bg?: string;
  isActive?: boolean;
  isLoading?: boolean;
  onClick?(v: any);
}

export const tag = (props: ITag) => {
  const {
    label,
    value,
    bg = '',
    isActive = false,
    isLoading = false,
    onClick,
  } = props;

  const color = bg || label;
  return (
    <>
      {!isLoading && (
        <Tag
          // @ts-ignore
          onClick={() => onClick?.(value)}
          label={startCase(label) + `: ${value | 0}`}
          styles={{
            tag: {
              color: isActive ? 'white' : color,
              bg: isActive ? color : 'white',
              fontSize: 'sm',
              cursor: onClick && 'pointer',
              border: '1px solid',
              borderColor: color,
            },
          }}
        />
      )}
      {isLoading && (
        <Skeleton>
          <Tag
            // @ts-ignore
            label={startCase(label) + `: ${value | 0}`}
            styles={{
              tag: {
                color: 'white',
                bg: color,
                fontSize: 'sm',
              },
            }}
          />
        </Skeleton>
      )}
    </>
  );
};

export const alertCategoryTooltipInfo = {
  ExcessPermissions:
    'Findings in this category highlight identities – users, policies, roles and non-users that have permissions that are not being actively used. Excess permissions is detected by looking activity for each identity over the past 90 days',
  HighRiskEntities:
    'Findings in this category highlight risks from entities that have privileged roles or significant excess permissions. The risks in such cases are more elevated due to the expanded scope of permissions',
  SeparationOfDuties:
    'Findings in this category highlight risks due to poor separation of roles between users. In such cases, a single user identity has permissions to perform both administrative and user functions, which can lead privilege escalations and malicious use',
  InternetExposure:
    'Findings in this category highlight risks due to resources (infrastructure and platform services) that have accessibility from Internet. Internet accessibility elevates risk of malicious access and breach',
  DataExposure:
    'Findings in this category highlight risks due to exposure of data from sources such as object storage, databases etc. This is one of the primary causes of data exfiltration',
  InsecureAccess:
    'Findings in this category highlight risks due to network access. This can be risks due to open ports that makes resources accessible, lack of encryption for data in transit or end-\n' +
    'of-life versions of encryption standards',
  Vulnerabilities:
    'Findings in this category highlight risks due to vulnerabilities in workloads for operating systems (OS), packages, libraries and applications',
  InsecureCredentials:
    'Findings in this category highlight risks due to poor credentials governance, detection of credentials outside a secure credential management system',
  DataRecovery:
    'Findings in this category highlight risks due to absence of data protection and retention that would be critical in the event of breach and ransomware attacks',
  InactiveEntities:
    'Findings in this category highlight risks due to vulnerabilities in workloads for operating systems (OS), packages, libraries and applications.',
  PoorGovernance:
    'Findings in this category highlight risks due to poor cloud governance and lack of implementation of best practices that enable robust security, compliance and response',
  PoorAuditability:
    'Findings in this category highlight risks due to insufficient configurations to create an audit trail for various events, changes and activities associated with users, infrastructure and platform services',
};

export const getSeverity = (alert: AggregatedAlert) => {
  if (!alert) return '';
  const alertSeverity = {
    CRITICAL: alert.severity_critical,
    MEDIUM: alert.severity_medium,
    LOW: alert.severity_low,
  };
  return ['CRITICAL', 'MEDIUM', 'LOW'].find(e => alertSeverity[e]);
};

export const sideNavCategoryOrder = [
  AlertCategoryClass.DataSecurity,
  AlertCategoryClass.IdentityAuthorization,
  AlertCategoryClass.CloudPosture,
];
