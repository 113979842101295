import React, { FC, useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useVulnerabilityTableColumns } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilityTableColumns';
import { CVEDetailsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEDetailsDrawer';
import { CVEHostsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHostsDrawer';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

import { actions } from '../../slice';

export const CVETable: FC = () => {
  const dispatch = useDispatch();
  const { cves, sort } = useSelector(selectVulnerabilities);
  const {
    tableColumns,
    hostsDisclosure,
    currentCVE,
  } = useVulnerabilityTableColumns();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const cveDetailsDisclosure = useDisclosure();

  return (
    <>
      <Box h="full" maxW="100%" className={'table-wrapper'} overflow={'auto'}>
        <Table
          data={cves.data}
          isError={cves.isError}
          columns={tableColumns}
          onRowClick={row => {
            cveDetailsDisclosure.onOpen();
            setCurrentRecord(row);
          }}
          isLoading={cves.isLoading}
          cursor="pointer"
          styles={{ header: { position: 'relative', zIndex: 800 } }}
          pagination={{
            pageInfo: cves.page.info,
            pageSizes: [100, 500, 1000],
            onChange: info => dispatch(actions.updateCvePageInfo(info)),
            totalCount: cves.page.totalCount,
          }}
          sortBy={sort}
          onSort={sortInfo => {
            if (!isEqual(sort, sortInfo)) {
              dispatch(actions.updateSortInfo(sortInfo));
            }
          }}
        />
      </Box>
      {hostsDisclosure.isOpen && (
        <CVEHostsDrawer
          row={currentCVE}
          isOpen={hostsDisclosure.isOpen}
          onClose={hostsDisclosure.onClose}
          selectedTab="cve"
        />
      )}
      {cveDetailsDisclosure.isOpen && (
        <CVEDetailsDrawer
          isOpen={cveDetailsDisclosure.isOpen}
          onClose={cveDetailsDisclosure.onClose}
          currentRecord={currentRecord}
        />
      )}
    </>
  );
};
