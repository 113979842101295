import React, { FC, memo } from 'react';

import { Box, Center, Checkbox, CheckboxProps, Flex } from '@chakra-ui/react';

interface ISwitchIO extends CheckboxProps {
  onChange?(value: any);
  isInvalid?: boolean;
  count?: number;
  labels?: [string, string];
}

export const SwitchIO: FC<ISwitchIO> = memo(props => {
  const {
    onChange,
    isDisabled,
    isInvalid,
    value,
    count,
    labels = ['0', '1'],
    ...rest
  } = props;

  const handleClick = e => {
    if (!isDisabled) {
      onChange?.(!value);
    }
  };

  return (
    <Flex display={'flex-inline'}>
      <Box
        border={'1px solid'}
        h={5}
        w={10}
        borderColor={isDisabled || isInvalid ? '#ccc' : '#ccc'}
        borderRadius={4}
        overflow={'hidden'}
        cursor={isDisabled || isInvalid ? 'not-allowed' : 'pointer'}
        opacity={isInvalid ? 0.3 : 1}
        userSelect={'none'}
        onClick={handleClick}
      >
        {isDisabled || isInvalid ? (
          <Center
            pos={'relative'}
            w={'50%'}
            h={'full'}
            bg={value ? 'primary' : '#eee'}
            color={'#555'}
            left={value ? '50%' : 0}
            transition={'0.2s all'}
            fontSize={'xs'}
          >
            {value ? labels[0] : labels[1]}
          </Center>
        ) : (
          <Center
            pos={'relative'}
            w={'50%'}
            h={'full'}
            bg={value ? 'primary' : '#eee'}
            color={value ? '#fff' : '#555'}
            left={value ? '50%' : 0}
            transition={'0.2s all'}
            fontSize={'xs'}
          >
            {value ? labels[0] : labels[1]}
          </Center>
        )}
      </Box>
      <Checkbox {...rest} isChecked={!!value} display={'none'} />
    </Flex>
  );
});
