import React from 'react';

import { Icon } from '@chakra-ui/react';

export const HackerIcon = props => (
  <Icon viewBox="0 0 48 48" color="inherit" h="full" w="full" {...props}>
    <path
      fill="primary"
      d="M46.376 32.447c-.502-.201-1.115-.209-1.672-.176-4.471.27-8.937.696-13.413.838-7.74.245-15.472.013-23.177-.851-1.518-.17-2.593.94-2.282 2.359a22.562 22.562 0 0 0 2.264 6.1c.606 1.1 1.43 1.979 2.552 2.575 2.636 1.4 5.432 2.27 8.394 2.653 1.862.239 3.537-.04 4.92-1.42.229-.228.513-.401.775-.594.786-.58 1.661-.753 2.626-.63 1.205.155 2.01.99 2.87 1.692.933.764 1.974 1.033 3.509 1.024 2.972-.192 6.152-1.034 9.044-2.734.846-.497 1.601-1.303 2.14-2.135 1.29-1.99 2.023-4.235 2.515-6.55.206-.972-.152-1.784-1.065-2.151zM21.72 39.989c-.431.528-1.103 1.003-1.753 1.18-1.96.537-3.903.279-5.614-.831-.597-.39-1.081-1.107-1.369-1.78-.397-.933-.08-1.438.91-1.636.108-.021.217-.04.325-.06 1.552-.264 3.136-.25 4.694-.027.636.09 1.268.195 1.887.347 1.591.392 1.965 1.529.92 2.807zM40.27 38.52c-.251.547-.64 1.1-1.113 1.463-1.696 1.31-3.637 1.726-5.733 1.245-1.026-.236-1.812-.825-2.225-1.834-.406-.988-.184-1.728.831-2.038 1.089-.329 2.243-.442 3.371-.636.295-.05 3.051.017 4.094.236.915.193 1.167.715.775 1.564z"
    />
    <path
      fill="primary"
      d="M47.417 33.582c-.49 2.285-1.217 4.495-2.488 6.47-.544.823-1.291 1.634-2.145 2.125-2.894 1.709-6.066 2.542-9.045 2.734-1.537.01-2.573-.257-3.502-1.015-.865-.704-1.665-1.537-2.872-1.697-.972-.118-1.847.053-2.627.63-.267.192-.544.363-.78.598-1.377 1.377-3.053 1.655-4.91 1.42a24.467 24.467 0 0 1-8.404-2.66c-1.121-.597-1.943-1.473-2.552-2.572a22.797 22.797 0 0 1-2.242-5.99c-.086.299-.096.64-.022.993.47 2.135 1.207 4.174 2.264 6.096.609 1.1 1.43 1.986 2.552 2.574 2.637 1.41 5.435 2.274 8.403 2.658 1.858.235 3.534-.042 4.912-1.42.235-.224.512-.405.78-.598.779-.576 1.654-.747 2.626-.63 1.207.16 2.007.993 2.872 1.698.93.758 1.965 1.025 3.502 1.025 2.98-.192 6.15-1.036 9.045-2.733.854-.502 1.601-1.303 2.145-2.136 1.282-1.996 2.019-4.239 2.51-6.556.075-.363.075-.715-.022-1.014z"
    />
    <path
      fill="primary"
      d="M50.5 24.844a.855.855 0 0 1-.007.112c-.308 2.51-10.933 4.524-23.994 4.524-13.198 0-23.907-2.058-23.998-4.602l-.001-.034v-.006l.002-.054.005-.06c.18-1.37 3.43-2.59 8.47-3.418.43-.07.78-.383.893-.804.956-3.535 1.926-7.068 2.899-10.6.481-1.75 1.513-2.779 3.093-2.888.666-.046 1.378.146 2.027.36 1.476.482 2.893 1.187 4.394 1.538 2.177.508 4.36.308 6.446-.593.866-.375 1.742-.72 2.622-1.057 2.14-.816 4.161.173 4.78 2.379 1.015 3.613 2.002 7.236 2.982 10.859.113.42.463.734.893.804 5.196.852 8.494 2.12 8.494 3.54z"
    />
    <path
      fill="primary"
      d="M50.499 24.847c0 .03 0 .07-.01.11-.11.93-1.62 1.78-4.13 2.49 1.2-.54 1.9-1.13 1.98-1.74v-.11c0-1.42-3.29-2.69-8.49-3.54-.43-.07-.78-.39-.89-.81-.98-3.62-1.97-7.24-2.98-10.86-.54-1.92-2.15-2.92-3.97-2.6.44-.18.89-.35 1.34-.52 2.14-.82 4.16.17 4.78 2.37 1.02 3.62 2 7.24 2.98 10.86.12.42.47.74.9.81 5.19.85 8.49 2.12 8.49 3.54z"
    />
    <path
      fill="primary"
      d="M41.969 21.297c-.84.22-1.68.42-2.53.6-.23-.14-.41-.38-.48-.65l-.63-2.3a35.7 35.7 0 0 0 2.23-.5c.18.68.37 1.37.55 2.05.03.1.07.2.13.29.05.08.12.16.19.23.03.03.06.05.09.08.13.09.28.16.45.2z"
    />
    <path
      fill="primary"
      d="M41.979 21.297a62.143 62.143 0 0 1-31.21.05c.07-.02.14-.03.21-.04.43-.07.78-.38.89-.8l.54-1.96a58.906 58.906 0 0 0 28.15-.1c.18.68.37 1.37.55 2.05.12.41.45.72.87.8z"
    />
    <path
      fill="primary"
      d="M41.969 21.297c-.84.22-1.68.42-2.53.6-.23-.14-.41-.38-.48-.65l-.63-2.3a35.7 35.7 0 0 0 2.23-.5c.18.68.37 1.37.55 2.05.03.1.07.2.13.29.05.08.12.16.19.23.03.03.06.05.09.08.13.09.28.16.45.2z"
    />
    <path
      fill="#fff"
      d="M15.613 17.147a.5.5 0 0 1-.482-.632 3265.56 3265.56 0 0 1 1.662-6.055.5.5 0 1 1 .965.266c-.556 2.017-1.11 4.035-1.662 6.053a.5.5 0 0 1-.483.368zM8.354 25.818a.628.628 0 0 1-.141-.016 26.164 26.164 0 0 1-1.412-.376.601.601 0 0 1-.403-.747.603.603 0 0 1 .747-.404c.398.12.847.24 1.347.359a.602.602 0 0 1-.138 1.184zm18.145 1.662c-7.814 0-13.138-.678-16.228-1.249a.599.599 0 1 1 .216-1.18c3.041.561 8.288 1.23 16.012 1.23a.6.6 0 0 1 0 1.2zM18.606 34.957h-.02a156.731 156.731 0 0 1-7.98-.533.5.5 0 0 1 .091-.996c2.59.24 5.258.419 7.93.529a.501.501 0 0 1-.021 1zm-9.912-.728a.513.513 0 0 1-.055-.003l-.736-.08a.5.5 0 0 1-.44-.552.505.505 0 0 1 .552-.442l.731.08a.5.5 0 0 1-.052.997z"
      opacity=".3"
    />
  </Icon>
);
