import { Box } from '@chakra-ui/react';

import { Modal } from 'components/Overlay';
import { ViewPolicy } from 'containers/Visibility/SecurityGraphNext/Components/Policy/ViewPolicy';

interface ViewPolicyModelProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ViewPolicyModel = (props: ViewPolicyModelProps) => {
  const { isOpen, onClose } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      header={<>View policy</>}
      body={
        <Box minH={'500px'}>
          <ViewPolicy />
        </Box>
      }
      size="6xl"
    />
  );
};
