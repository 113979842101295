import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';

import { SensitiveData } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/Components/SensitiveData';

import { IAMUserData } from '../../Identity/IAMUser/IAMUserData';

export const getDataComponent = (resourceType, accountId, name) => {
  switch (resourceType) {
    case NativeResources.IamUser:
    case NativeResources.IamRole:
    case NativeResources.OktaUser:
    case NativeResources.SnowflakeUsers:
      return <IAMUserData />;
    case NativeResources.SimpleStorageService:
    case NativeResources.RedshiftCluster:
    case NativeResources.RelationalDatabaseService:
    case NativeResources.DynamoDb:
    case NativeResources.RdsCluster:
    case NativeResources.DocumentDb:
    case NativeResources.DocumentDbCluster:
    case NativeResources.SnowflakeDatabases:
    case NativeResources.SnowflakeTables:
    case NativeResources.GitLabProject:
    case NativeResources.GitHubRepository:
    case NativeResources.BitBucketRepository:
      return <SensitiveData />;
    //return <PIIByBucketName name={name} resourceType={resourceType} />;
    /*case NativeResources.SnowflakeDatabases:
      return <SnowflakeDatabaseData />;
    case NativeResources.SnowflakeTables:
      return <SnowflakeTablePiiData />;*/
    /*case NativeResources.GitLabProject:
    case NativeResources.GitHubRepository:
    case NativeResources.BitBucketRepository:
      return <RepositoryData repositoryName={name} />;*/
    default:
      return null;
  }
};
