import React, { useEffect, useState } from 'react';

import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { formatBytes } from 'components/DataDisplay';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { formatDate } from 'utils/date';

import { TotalCard } from './TotalCard';

export const DataAnalyzed = () => {
  const { dataAnalyzed } = useSelector(selectDataDashboard);
  const { total } = dataAnalyzed.data;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setChartData(
      map(dataAnalyzed.data.source_sizes, o => ({
        Date: formatDate(o?.created_at, 'D-MMM'),
        Count: o?.size,
        //actualCount: ceil((o?.day_size ?? 0) / 1000),
      })),
    );
  }, [dataAnalyzed.data]);

  return (
    <TotalCard
      label="Total Data Analyzed"
      total={formatBytes(total) ?? 0}
      chartData={chartData}
      isLoading={dataAnalyzed.isLoading}
    />
  );
};
