import {
  ContextApiGetRiskContextResRequest,
  DashboardApiGetResourcesByImagesForDashboardRequest,
  InsightV2Pagination,
  RiskContextResponse,
  SunburstDashboard,
  VulnerabilitiesApiGetCveResourcesRequest,
  VulnerabilitiesApiGetPackageResourcesRequest,
  VulnerabilitiesApiVulnerabilityInsightRequest,
} from '@ariksa/inventory-core/api';
import { ChartsApiGetVulnerabilitiesDetailsRequest } from '@ariksa/reporting';
import {
  BySourcesDashboard,
  CVEDetailPagination,
  DefaultApiGetBySourcesDashboardDataRequest,
  DefaultApiGetCvesByIdRequest,
  DefaultApiGetCvesRequest,
  DefaultApiGetEcrImageListRequest,
  DefaultApiGetVulnerabilityPriortizationDataRequest,
  DefaultApiGetVulnerablePackagesRequest,
  ECRImageVisibilityDashboard,
  ScannerInfo,
  VulnerabilityPriortization,
  VulnerablePackageResponse,
} from '@ariksa/scan-analysis/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService, ReportingService, ScanAnalysis } from 'api/services';

import { actions } from './slice';

export function* vulnerabilitySaga() {
  yield takeLatestAction(actions.getVulnerabilities, doGetVulnerabilities);
  yield takeLatestAction(actions.getEcrImages, doGetEcrImagesVulnerabilities);
  yield takeLatestAction(actions.getCveHosts, doGetCveHosts);
  yield takeLatestAction(
    actions.getVulnerabilitiesBySeverity,
    doGetVulnerabilitiesBySeverity,
  );
  yield takeLatestAction(
    actions.getVulnerabilitiesBySeverityCounts,
    doGetVulnerabilitiesBySeverityCounts,
  );
  yield takeLatestAction(
    actions.getHostsWithCriticalHighVulnerabilities,
    doGetHostsWithCriticalHighVulnerabilities,
  );
  yield takeLatestAction(
    actions.getCveVulnerabilities,
    doGetCveVulnerabilities,
  );
  /*yield takeLatestAction(
    actions.getCveHostsVulnerabilities,
    doGetCveHostsVulnerabilities,
  );*/
  yield takeLatestAction(
    actions.getPackageVulnerabilities,
    doGetPackageVulnerabilities,
  );
  yield takeLatestAction(actions.getPackageHosts, doGetPackageHosts);
  yield takeLatestAction(actions.getScannerInfo, doGetVulnerabilityScannerInfo);
  yield takeLatestAction(actions.getCVEDetails, doGetCVEDetailsById);
  yield takeLatestAction(
    actions.getVulnerabilityPrioritizationData,
    doGetVulnerabilityPrioritization,
  );
  yield takeLatestAction(
    actions.getCveHostsRiskContext,
    doGetCveHostsRiskContext,
  );
  yield takeLatestAction(
    actions.getVulnerabilitiesBySources,
    doGetVulnerabilitiesBySources,
  );
  yield takeLatestAction(
    actions.getMostDeployedVulnerableImages,
    doGetMostDeployedVulnerableImages,
  );
}

export function* doGetVulnerabilities(
  ctx: QueryContext<
    InsightV2Pagination,
    VulnerabilitiesApiVulnerabilityInsightRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.vulnerabilityInsight(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities!' },
  );
}

export function* doGetEcrImagesVulnerabilities(
  ctx: QueryContext<
    ECRImageVisibilityDashboard,
    DefaultApiGetEcrImageListRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getEcrImageList(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities!' },
  );
}

export function* doGetCveVulnerabilities(
  ctx: QueryContext<CVEDetailPagination, DefaultApiGetCvesRequest>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Default.getCves(ctx.params), {
    errorMsg: 'Failed to get vulnerabilities!',
  });
}

export function* doGetPackageVulnerabilities(
  ctx: QueryContext<
    VulnerablePackageResponse,
    DefaultApiGetVulnerablePackagesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getVulnerablePackages(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities!' },
  );
}

export function* doGetCveHosts(
  ctx: QueryContext<
    InsightV2Pagination,
    VulnerabilitiesApiGetCveResourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getCveResources(ctx.params),
    { errorMsg: 'Failed to get CVE Hosts!' },
  );
}

export function* doGetPackageHosts(
  ctx: QueryContext<
    InsightV2Pagination,
    VulnerabilitiesApiGetPackageResourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getPackageResources(ctx.params),
    { errorMsg: 'Failed to get package Hosts!' },
  );
}

export function* doGetVulnerabilitiesBySeverity(
  ctx: QueryContext<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getVulnerabilitiesDetails(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities by severity!' },
  );
}

export function* doGetVulnerabilitiesBySeverityCounts(
  ctx: QueryContext<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Vulnerability.getCountBySeverity(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities by severity!' },
  );
}

export function* doGetHostsWithCriticalHighVulnerabilities(
  ctx: QueryContext<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getVulnerabilitiesDetails(ctx.params),
    { errorMsg: 'Failed to get host vulnerabilities!' },
  );
}

export function* doGetCveHostsRiskContext(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextResRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextRes(ctx.params),
    {
      errorMsg: 'Failed to get risk context!',
    },
  );
}

export function* doGetVulnerabilityScannerInfo(
  ctx: QueryContext<Record<string, ScannerInfo[]>>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Item.getVulnerabilityScanners(), {
    errorMsg: 'Failed to get data scanner info!',
    cacheKey: 'vulnerabilityScannerInfo',
  });
}

export function* doGetCVEDetailsById(
  ctx: QueryContext<CVEDetailPagination, DefaultApiGetCvesByIdRequest>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Default.getCvesById(ctx.params), {
    errorMsg: 'Failed to get data scanner info!',
  });
}

export function* doGetVulnerabilityPrioritization(
  ctx: QueryContext<
    VulnerabilityPriortization,
    DefaultApiGetVulnerabilityPriortizationDataRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getVulnerabilityPriortizationData(ctx.params),
    {
      errorMsg: 'Failed to get vulnerability prioritization data!',
    },
  );
}

export function* doGetVulnerabilitiesBySources(
  ctx: QueryContext<
    BySourcesDashboard,
    DefaultApiGetBySourcesDashboardDataRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getBySourcesDashboardData(ctx.params),
    {
      errorMsg: 'Failed to get vulnerabilities by sources!',
    },
  );
}

export function* doGetMostDeployedVulnerableImages(
  ctx: QueryContext<
    SunburstDashboard,
    DashboardApiGetResourcesByImagesForDashboardRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.Dashboard.getResourcesByImagesForDashboard(ctx.params),
    {
      errorMsg: 'Failed to get most deployed vulnerable images!',
    },
  );
}
