import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, Stack } from '@chakra-ui/react';
import map from 'lodash/map';
import toLower from 'lodash/toLower';

import { CustomTooltip } from 'components/DataDisplay';
import {
  AwsIcon,
  AzureIcon,
  BitbucketIcon,
  CustomIconTypes,
  GithubIcon,
  GitLabIcon,
  GoogleCloudIcon,
  SnowflakeIcon,
  VmwareIcon,
} from 'components/Icons';
import { AriksaIcon, getIcon } from 'components/Icons/Components';

export const getCloudIcon = value => {
  let icon;
  switch (toLower(value)) {
    case 'aws':
      icon = <AwsIcon color="inherit" />;
      break;
    case 'azure':
      icon = <AzureIcon color="inherit" />;
      break;
    case 'vmware':
      icon = <VmwareIcon color="inherit" />;
      break;
    case 'gcp':
      icon = <GoogleCloudIcon color="inherit" />;
      break;
    case 'gitlab':
      icon = <GitLabIcon color="inherit" />;
      break;
    case 'gitlab_iac':
      icon = <GitLabIcon color="inherit" />;
      break;
  }

  return <AriksaIcon icon={icon} bg={value} />;
};

export const renderCloudIcons = ({ value }) =>
  map(value, each => {
    const icon = getCloudIcon(each);
    if (icon) return icon;
    else return each;
  });

export const getProviderName = provider => {
  switch (provider) {
    case CloudProviders.Aws:
    case CustomIconTypes.AWS:
      return 'Amazon Web Services';
    case CloudProviders.Okta:
    case CustomIconTypes.Okta:
      return 'Okta';
    case CloudProviders.Azure:
      return 'Microsoft Azure';
    case CloudProviders.AzureAd:
    case 'azuread':
    case 'AzureAD':
      return 'Azure Active Directory';
    case CloudProviders.Snowflake:
    case CustomIconTypes.Snowflake:
      return 'Snowflake';
    case CloudProviders.Gcp:
    case CustomIconTypes.GCP:
      return 'Google Cloud Platform';
    case CloudProviders.Gitlab:
    case CustomIconTypes.GitLab:
      return 'GitLab';
    case CloudProviders.Github:
    case CustomIconTypes.GitHub:
      return 'GitHub';
    case CloudProviders.Bitbucket:
    case CustomIconTypes.BitBucket:
      return 'Atlassian BitBucket';
  }
};

export const getProviderAlias = provider => {
  switch (provider) {
    case CloudProviders.Aws:
    case CustomIconTypes.AWS:
      return 'AWS';
    case CloudProviders.Okta:
    case CustomIconTypes.Okta:
      return 'Okta';
    case CloudProviders.Azure:
      return 'Azure';
    case CloudProviders.AzureAd:
    case 'azuread':
      return 'AzureAD';
    case CloudProviders.Snowflake:
    case CustomIconTypes.Snowflake:
      return 'Snowflake';
    case CloudProviders.Gcp:
      return 'GCP';
    case CloudProviders.Gitlab:
    case CustomIconTypes.GitLab:
      return 'GitLab';
    case CloudProviders.Github:
    case CustomIconTypes.GitHub:
      return 'GitHub';
    case CloudProviders.Bitbucket:
    case CustomIconTypes.BitBucket:
      return 'BitBucket';
  }
};

export const renderStackedCloudNameAndIcon = (
  provider,
  isInline = false,
  accountId = '',
) => (
  <Stack direction={isInline ? 'row' : 'column'} spacing={isInline ? 1 : 0}>
    {
      <Box boxSize={5}>
        {provider ? getIcon(provider) : '-'} {accountId ? accountId : ''}
      </Box>
    }
    <Box>{getProviderName(provider)}</Box>
  </Stack>
);

export const renderCloudIcon = ({ provider, boxSize = 5 }) => (
  <Box boxSize={boxSize}>
    <CustomTooltip label={getProviderName(provider)}>
      {provider ? getIcon(provider) : '-'}
    </CustomTooltip>
  </Box>
);

export const cloudOptions = [
  { label: 'AWS', value: CloudProviders.Aws, icon: <AwsIcon /> },
  { label: 'GCP', value: CloudProviders.Gcp, icon: <GoogleCloudIcon /> },
];

export const cloudProviderOptions = [
  { label: 'AWS', value: CloudProviders.Aws, icon: <AwsIcon /> },
  { label: 'GCP', value: CloudProviders.Gcp, icon: <GoogleCloudIcon /> },
  { label: 'Azure', value: CloudProviders.Azure, icon: <AzureIcon /> },
  {
    label: 'Snowflake',
    value: CloudProviders.Snowflake,
    icon: <SnowflakeIcon />,
  },
  { label: 'GitLab', value: CloudProviders.Gitlab, icon: <GitLabIcon /> },
  { label: 'GitHub', value: CloudProviders.Github, icon: <GithubIcon /> },
  {
    label: 'Atlassian BitBucket',
    value: CloudProviders.Bitbucket,
    icon: <BitbucketIcon />,
  },
];

export const cloudProviderOptionsWithAll = [
  { label: 'All clouds', value: undefined },
  ...cloudProviderOptions,
];
