import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { renderTime } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  RegisteredContainers,
  RunningPendingTasks,
} from '../../../Components/MetadataField/MetaGridFields';

export const ECSClusterMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const attachmentColumns = [
    { header: 'ID', accessor: 'id' },
    { header: 'Type', accessor: 'type', align: 'left' },
    { header: 'Status', accessor: 'status', align: 'left' },
    {
      header: 'Name',
      accessor: 'Name',
      align: 'left',
      render: ({ row }) => row?.details?.name + ': ' + row?.details?.value,
    },
  ];
  const servicesColumns = [
    { header: 'Name', accessor: 'serviceName' },
    { header: 'Status', accessor: 'status', align: 'left' },
    { header: 'ARN', accessor: 'serviceArn', align: 'left' },
    {
      header: 'Assign public IP',
      accessor: 'networkConfiguration.awsvpcConfiguration.assignPublicIp',
      align: 'left',
    },
    {
      header: 'Created at',
      accessor: 'createdAt',
      align: 'left',
      render: renderTime,
    },
  ];

  const attachments = resource?.config?.attachments ?? [];
  const services = resource?.config?.services ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields arn={resource} />
      {RegisteredContainers(resource)}
      {RunningPendingTasks(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={attachmentColumns} data={attachments} />}
            iconType={''}
            label="Attachments"
            totalCount={attachments?.length}
            height="300px"
          />
          <MetaAccordionItem
            panel={<Table columns={servicesColumns} data={services} />}
            iconType={IconTypes.Service}
            label="Services"
            totalCount={services?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
