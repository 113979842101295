import { formatDate } from 'utils/date';

export const intoOption = resource => {
  return {
    label: resource?.name || resource?.resource_id,
    value: resource?.uuid,
    data: resource,
  };
};

export function saveAsFile(content, filename, contentType) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  console.log(url);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export function saveAsPDF(content, filename) {
  // Create a link to download it
  const pom = document.createElement('a');
  pom.href =
    'data:application/pdf;charset=utf-8,' + encodeURIComponent(content);

  const fileName = `${formatDate(new Date().toISOString())}-${filename}`;
  pom.setAttribute('download', fileName);
  pom.click();
}
