import React, { useEffect, useState } from 'react';

import { Box, HStack, Divider, Stack, Input, Textarea } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { colorMap, customTheme } from 'theme';

import { PageHeaderWithIcon, renderTime } from 'components/DataDisplay';
import { Card } from 'components/DataDisplay/Card/Card';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import {
  ActiveSwitch,
  CancelButton,
  defaultFormStyles,
  PrimaryButton,
  PrimaryIconButton,
} from 'components/DataEntry';
import { EditIcon } from 'components/Icons';
import {
  renderCustomTag,
  renderEditLock,
} from 'containers/PolicyHub/Blueprints/Components/utils';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

import { getBlueprintIcon } from '../../../../utils';

interface Props {
  getBlueprint();
}

export const BlueprintGeneralDetails = (props: Props) => {
  const { getBlueprint } = props;
  const dispatch = useDispatch();
  const { blueprint, blueprintAction } = useSelector(selectBlueprints);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setName(blueprint.data.name);
    setDescription(blueprint.data?.description ?? '');
  }, [blueprint.data]);

  const handleOnClickSave = () => {
    dispatch(
      actions.updateBlueprint({
        q: {
          blueprintId: blueprint.data?.id,
          blueprintUpdate: {
            name,
            description: description ?? undefined,
          },
        },
        onSuccess: () => {
          getBlueprint();
          setIsEdit(false);
        },
      }),
    );
  };

  return (
    <Card
      styles={{
        card: { borderColor: colorMap.primary(300), h: '250px', w: '65%' },
      }}
    >
      <Stack w="full" spacing={3}>
        <HStack justify="space-between" w="full">
          <Box w="full">
            <PageHeaderWithIcon
              label={
                isEdit ? (
                  <Input
                    {...defaultFormStyles.textField?.input}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                ) : (
                  <HStack>
                    <Box>{blueprint.data?.name}</Box>
                    {renderCustomTag(blueprint.data?.created_by)}
                  </HStack>
                )
              }
              labelWidth="full"
              fontSize="md"
              icon={getBlueprintIcon(
                blueprint.data.created_by !== 'system',
                blueprint.data?.compliance ?? blueprint.data?.name,
              )}
              iconFilled={false}
              reversed
            />
          </Box>
          <HStack>
            {!isEdit && (
              <PrimaryIconButton
                aria-label="edit"
                isDisabled={blueprint.data?.created_by === 'system'}
                onClick={() => setIsEdit(true)}
                tooltip={
                  'Edit details' +
                  (blueprint.data?.created_by === 'system' ? ' disabled' : '')
                }
              >
                <EditIcon />
              </PrimaryIconButton>
            )}
            {isEdit && (
              <CancelButton
                label="Cancel"
                onClick={() => setIsEdit(false)}
                size="xs"
              />
            )}
            {isEdit && (
              <PrimaryButton
                size="xs"
                onClick={handleOnClickSave}
                isLoading={blueprintAction.isLoading}
              >
                Save
              </PrimaryButton>
            )}
          </HStack>
        </HStack>
        <Divider />
        <Box w="full" pt={1}>
          <DetailsField
            label="Description"
            isInline={false}
            width="full"
            spacing={2}
            value={
              isEdit ? (
                <Textarea
                  {...defaultFormStyles.textAreaField?.textArea}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              ) : (
                <Box
                  border="1px solid"
                  borderColor={customTheme.colors.gray['100']}
                  bg={customTheme.colors.gray['50']}
                  h={20}
                  w="full"
                  borderRadius={6}
                  py={2}
                  px={3}
                  color="#000"
                >
                  {blueprint.data.description}
                </Box>
              )
            }
          />
        </Box>
        <HStack justify="space-between">
          <DetailsField
            label="Active"
            value={<ActiveSwitch value={blueprint.data.is_enabled} />}
            align="end"
          />
          <DetailsField
            label="Edit"
            align="end"
            value={
              <Box pb={1}>{renderEditLock(blueprint.data.created_by, 5)}</Box>
            }
          />
          <DetailsField
            label="Created By"
            value={
              blueprint.data.created_by === 'system'
                ? 'Ariksa'
                : blueprint.data.created_by
            }
          />
          <DetailsField
            label="Created At"
            value={renderTime({ value: blueprint.data.created_at })}
          />
          <DetailsField
            label="Updated At"
            value={renderTime({ value: blueprint.data.last_updated_at })}
          />
        </HStack>
      </Stack>
    </Card>
  );
};
