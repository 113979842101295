import React, { FC, useMemo } from 'react';

import { SearchResponse } from '@ariksa/inventory-core/api';
import { Box } from '@chakra-ui/react';

import { AbsoluteSpinner } from 'components/DataDisplay/Spinner/AbsoluteSpinner';
import { AccessMapContextProvider } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';
import { useIntoGraphNodes } from 'containers/Visibility/SecurityGraph/hooks/useIntoGraphNodes';

import { AccessGraph } from './AccessGraph';

interface IAccessGraphWithContext {
  data: SearchResponse;
  menu?: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

export const AccessGraphWithContext: FC<IAccessGraphWithContext> = props => {
  const { data, menu, isLoading, isLoaded } = props;

  const { intoGraphNodes } = useIntoGraphNodes();
  const graphNodes = useMemo(() => {
    const mappedNodes = data.nodes?.map(n => ({
      ...n,
      id: n.identity,
    }));

    return intoGraphNodes(mappedNodes ?? []);
  }, [data.nodes, intoGraphNodes]);

  return (
    <AccessMapContextProvider
      value={{
        ordering: data.ordering ?? [],
        nodes: graphNodes,
        paths: data.paths ?? [],
        edges: data.relationships ?? [],
        source_items: data.source_items ?? [],
        isLoading: isLoading,
        isLoaded: isLoaded,
      }}
    >
      <Box w={'full'} h={'full'} pos={'relative'}>
        <AccessGraph />
        <AbsoluteSpinner isLoading={isLoading} />
      </Box>
    </AccessMapContextProvider>
  );
};
