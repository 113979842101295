import React, { FC, useEffect } from 'react';

import { SearchResponseLevel } from '@ariksa/inventory-core';
import { SearchTermCategory } from '@ariksa/inventory-core/api';
import { AlertResponse } from '@ariksa/notification';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useDependency } from 'services/CloudPolicyEngine/useCloudPolicyEngineSearchService';

import { AbsoluteSpinner } from 'components/DataDisplay/Spinner/AbsoluteSpinner';
import { AccessGraphWithContext } from 'components/Visualization/PixiGraph/components/AccessGraphWithContext';
import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { selectActiveEnvironment } from 'containers/App/selectors';

interface IEvidencePixiGraph {
  alert: AlertResponse;
}

export const DependencyPixiGraph = props => {
  return (
    <Box
      w={'full'}
      h={'400px'}
      borderRadius={5}
      boxShadow={'0 0 0 1px #eee'}
      pos={'relative'}
    >
      <InnerDependencyPixiGraph {...props} />
    </Box>
  );
};

export const InnerDependencyPixiGraph: FC<IEvidencePixiGraph> = props => {
  const { alert } = props;
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { resourceDependency } = useDependency();

  useEffect(() => {
    resourceDependency.sync({
      nativeResource: alert.resource!,
      searchQuery: {
        uuid: alert.entity_uuid,
        environment_id: environmentId,
        check_id: alert.alert_rule_id,
        response_level: SearchResponseLevel.Compact,
      },
    });
  }, [alert, environmentId, resourceDependency, resourceDependency.sync]);

  if (resourceDependency.isLoading) {
    return <AbsoluteSpinner isLoading={resourceDependency.isLoading} />;
  }

  if (resourceDependency.data?.category === SearchTermCategory.Permission) {
    return (
      <AccessGraphWithContext
        data={resourceDependency.data ?? {}}
        isLoading={resourceDependency.isLoading}
        isLoaded={resourceDependency.isSuccess}
      />
    );
  }

  if (resourceDependency.data?.category === SearchTermCategory.Posture) {
    return (
      <PostureGraphWithContext
        data={resourceDependency.data ?? {}}
        isLoading={resourceDependency.isLoading}
        isLoaded={resourceDependency.isSuccess}
      />
    );
  }

  return null;
};
