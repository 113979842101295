import React, { FC } from 'react';

import { RuleReadWithMetadata } from '@ariksa/compliance-policies/api';
import { Box, UseDisclosureProps } from '@chakra-ui/react';
import { noop } from 'lodash';

import { StackedHeader } from 'components/DataDisplay';
import { Drawer } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { PolicyRemediation } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/Policies/Remediation/PolicyRemediation';

interface IRemediationModal {
  state: UseDisclosureProps;
  policy: RuleReadWithMetadata;
}

export const RemediationModal: FC<IRemediationModal> = props => {
  const { state, policy } = props;
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();

  const { isOpen = false, onClose = noop } = state;

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={policy.config_id}
          lower={
            getCloudAgnosticName(policy.resource_type!) +
            ' / ' +
            getCloudNativeName(policy.resource_type!)
          }
          type={policy.resource_type}
        />
      }
      body={
        <Box h="full" w="full" pt={3}>
          <PolicyRemediation policy={policy} />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1200px' },
      }}
    />
  );
};
