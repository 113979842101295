import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CloudWatchMonitoringIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M74.9,211.1h52.7c3.1,0,5.9-1.7,7.3-4.5l40.5-84l48,185.5c1,3.6,4.2,6,7.9,6c3.8,0,7-2.5,7.9-6l44.6-175.3
		l42.5,175.3c0.8,3.3,3.6,5.7,6.9,6.1c0.3,0.1,0.6,0.1,1,0.1l0,0c3,0,5.8-1.7,7.1-4.7l50.4-98.6H437c4.4,0,8.1-3.7,8.1-8.1
		s-3.7-8.1-8.1-8.1h-50.1c-3,0-5.8,1.7-7.2,4.4l-42.8,83.6L291.8,97.2c-0.9-3.6-4.1-6.1-7.8-6.1c-3.8,0-7,2.5-7.9,6l-44.9,176.3
		L185.6,97.1c-0.9-3.3-3.7-5.7-7-6c-3.3-0.3-6.6,1.5-8.1,4.5l-48,99.3H74.9c-4.4,0-8.1,3.7-8.1,8.1S70.5,211.1,74.9,211.1z"
      />
      <path
        d="M503.9,13.5H8.1c-4.4,0-8.1,3.7-8.1,8.1v362.5c0,4.4,3.7,8.1,8.1,8.1h239.8v90.2h-95.2c-4.4,0-8.1,3.7-8.1,8.1
		s3.7,8.1,8.1,8.1h206.5c4.4,0,8.1-3.7,8.1-8.1s-3.7-8.1-8.1-8.1h-95.2v-90.2h239.8c4.4,0,8.1-3.7,8.1-8.1V21.6
		C512,17.1,508.3,13.5,503.9,13.5z M495.8,376H16.2V29.6h479.7V376z"
      />
    </g>
  </Icon>
);
