import React, { FC, useEffect, useMemo } from 'react';

import { SearchResponseLevel } from '@ariksa/inventory-core';
import { SearchTermCategory } from '@ariksa/inventory-core/api';
import { AlertResponse } from '@ariksa/notification';
import { Box } from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';
import {
  useDependency,
  useEvidence,
} from 'services/CloudPolicyEngine/useCloudPolicyEngineSearchService';

import { AbsoluteSpinner } from 'components/DataDisplay/Spinner/AbsoluteSpinner';
import { AccessGraphWithContext } from 'components/Visualization/PixiGraph/components/AccessGraphWithContext';
import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { useIntoGraphNodes } from 'containers/Visibility/SecurityGraph/hooks/useIntoGraphNodes';

interface IEvidencePixiGraph {
  alert: AlertResponse;
  showDependencies?: boolean;
}

export const EvidencePixiGraph = (props: IEvidencePixiGraph) => {
  return (
    <Box w={'full'} h={'full'} borderRadius={5} pos={'relative'}>
      <InnerEvidencePixiGraph {...props} />
    </Box>
  );
};

const InnerEvidencePixiGraph: FC<IEvidencePixiGraph> = props => {
  const { alert, showDependencies } = props;
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { resourceEvidence } = useEvidence();

  const { resourceDependency } = useDependency();
  const { intoGraphNodes } = useIntoGraphNodes();

  useEffect(() => {
    if (showDependencies) {
      resourceDependency.sync({
        nativeResource: alert.resource!,
        searchQuery: {
          uuid: alert.entity_uuid,
          environment_id: environmentId,
          check_id: alert.alert_rule_id,
          response_level: SearchResponseLevel.Compact,
        },
      });
    }
  }, [
    alert,
    environmentId,
    resourceDependency,
    resourceDependency.sync,
    showDependencies,
  ]);

  useEffect(() => {
    resourceEvidence.sync({
      nativeResource: alert.resource!,
      searchQuery: {
        uuid: alert.entity_uuid,
        environment_id: environmentId,
        check_id: alert.alert_rule_id,
        response_level: SearchResponseLevel.Compact,
      },
    });
  }, [alert, environmentId, resourceEvidence, resourceEvidence.sync]);

  const graphData = useMemo(() => {
    if (!showDependencies) {
      const graphNodes = resourceEvidence.data?.nodes?.map(n => {
        return {
          ...n,
          id: n.identity,
        };
      });

      return {
        ...resourceEvidence.data,
        nodes: intoGraphNodes(graphNodes ?? []),
      };
    }

    const nodes = new Map();
    const edges = new Map();

    each(resourceEvidence.data?.nodes, n => {
      nodes.set(n.identity, n);
    });

    each(resourceDependency.data?.nodes, n => {
      nodes.set(n.identity, n);
    });

    each(resourceEvidence.data?.relationships, r => {
      edges.set(`${r.source}-${r.dest}`, r);
    });

    each(resourceDependency.data?.relationships, r => {
      edges.set(`${r.source}-${r.dest}`, r);
    });

    const graphNodes = Array.from(nodes.values()).map(n => {
      return {
        ...n,
        id: n.identity,
      };
    });

    return {
      ...resourceEvidence.data,
      nodes: intoGraphNodes(graphNodes ?? []),
      relationships: Array.from(edges.values()),
    } as any;
  }, [
    intoGraphNodes,
    resourceDependency.data?.nodes,
    resourceDependency.data?.relationships,
    resourceEvidence.data,
    showDependencies,
  ]);

  if (resourceEvidence.isLoading) {
    return <AbsoluteSpinner isLoading={resourceEvidence.isLoading} />;
  }

  if (resourceEvidence.data?.category === SearchTermCategory.Permission) {
    return (
      <AccessGraphWithContext
        data={graphData ?? {}}
        isLoading={resourceEvidence.isLoading}
        isLoaded={resourceEvidence.isSuccess}
      />
    );
  }

  if (resourceEvidence.data?.category === SearchTermCategory.Posture) {
    return (
      <PostureGraphWithContext
        data={graphData ?? {}}
        isLoading={resourceEvidence.isLoading}
        isLoaded={resourceEvidence.isSuccess}
      />
    );
  }

  return null;
};
