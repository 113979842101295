import {
  AlertConditions,
  AlertWorkflowClients,
  Clients,
  Cloud,
  Entities,
  Quantifiers,
} from '@ariksa/notification';
import {
  AlertCountForWorkflow,
  AlertWorkflowApiAddWorkflowRequest,
  AlertWorkflowApiDeleteWorkflowRequest,
  AlertWorkflowApiEditWorkflowRequest,
  AlertWorkflowApiGetAlertCountForWorkflowRequest,
  AlertWorkflowApiGetWorkflowRequest,
  AlertWorkflowResponse,
  ClientsApiGetJiraProjectDetailsRequest,
} from '@ariksa/notification/api';
import { ReportsApiSendToEsRequest } from '@ariksa/reporting/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { findIndex, map, forEach, startCase, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the AlertWorkflow container
export const initialState: ContainerState = {
  alertWorkflow: QueryState.init({}),
  workflows: QueryState.init([]),
  workflow: QueryState.init<AlertWorkflowResponse>({} as any),
  jiraMandatoryFields: {},
  formData: {
    name: '',
    cloud: Cloud.Aws,
    trigger: [],
    trigger_quantifier: Quantifiers.Any,
    condition_quantifier: Quantifiers.Any,
    alert_conditions: [],
    clients: [],
    notification_interval: 0,
    status: true,
  },
  tagKeyValues: {},
  alertConditions: {},
  jiraClients: {},
  exportAlert: QueryState.init({}),
  workflowAlertCounts: QueryState.init({} as AlertCountForWorkflow),
};

const alertWorkflowSlice = createSlice({
  name: 'alertWorkflow',
  initialState,
  reducers: {
    /*create alert workflow*/
    createAlertWorkflow(
      state,
      action: QueryAction<any, AlertWorkflowApiAddWorkflowRequest>,
    ) {
      state.alertWorkflow = QueryState.next(state.alertWorkflow, action);
    },
    getWorkflowAlertCounts(
      state,
      action: QueryAction<
        AlertCountForWorkflow,
        AlertWorkflowApiGetAlertCountForWorkflowRequest
      >,
    ) {
      state.workflowAlertCounts = QueryState.next(
        state.workflowAlertCounts,
        action,
      );
    },

    /*update alert workflow*/
    updateAlertWorkflow(
      state,
      action: QueryAction<any, AlertWorkflowApiEditWorkflowRequest>,
    ) {
      state.alertWorkflow = QueryState.next(state.alertWorkflow, action);
    },

    /*delete alert workflow*/
    deleteAlertWorkflow(
      state,
      action: QueryAction<any, AlertWorkflowApiDeleteWorkflowRequest>,
    ) {
      state.alertWorkflow = QueryState.next(state.alertWorkflow, action);
    },

    exportAlert(state, action: QueryAction<any, ReportsApiSendToEsRequest>) {
      state.exportAlert = QueryState.next(state.exportAlert, action);
    },

    /*get alert workflow*/
    getAlertWorkflow(
      state,
      action: QueryAction<
        AlertWorkflowResponse[],
        AlertWorkflowApiGetWorkflowRequest
      >,
    ) {
      state.workflow = QueryState.next(state.workflow, action, {
        mapData: r => r?.[0],
      });
      state.tagKeyValues = {};

      forEach(action.payload.data?.[0], (value, key) => {
        if (
          key !== 'clients' &&
          key !== 'conditions' &&
          key !== 'uuid' &&
          key !== 'account_ids'
        )
          state.formData[key] = value;
      });
      state.formData.alert_conditions = action.payload.data?.[0]
        ?.conditions as [];
      forEach(action.payload.data?.[0]?.conditions, o => {
        state.alertConditions[o?.name] = o;
        if (o.name === Entities.Tags) {
          const id = nanoid();
          state.tagKeyValues[id] = o;
        }
      });
      let clients: Record<string, any> = {};
      forEach(action.payload.data?.[0]?.clients, o => {
        if (o.client_name === Clients.Jira) {
          state.jiraClients[o.name] = o.config_details;
        }
        clients[o.client_name]
          ? clients[o.client_name].push({
              name: o.name,
            })
          : (clients[o.client_name] = [{ name: o.name }]);
      });
      forEach(clients, (value, key) =>
        state.formData?.clients?.push({
          client_name: Clients[startCase(key)],
          client_list: value,
        }),
      );
    },

    /*get Alert workflows*/
    getAlertWorkflows(state, action: QueryAction<AlertWorkflowResponse[]>) {
      state.workflows = QueryState.next(state.workflows, action);
    },

    getMandatoryJiraFields(
      state,
      action: QueryAction<
        Record<string, any>,
        ClientsApiGetJiraProjectDetailsRequest & { name: string }
      >,
    ) {
      const clientId = action.payload.q.clientId;
      if (!state.jiraMandatoryFields[clientId]) {
        state.jiraMandatoryFields[clientId] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.jiraMandatoryFields[clientId] = QueryState.next(
          state.jiraMandatoryFields[clientId],
          action,
        );
        const name = action.payload.q.name;
        let clients: Record<string, any> = {};
        forEach(action.payload.data, o => (clients[o?.name] = []));
        isEmpty(state.jiraClients[name]) && (state.jiraClients[name] = clients);
      }
    },

    resetFormData(state) {
      state.formData = initialState.formData;
      state.tagKeyValues = {};
      state.alertConditions = {};
      state.jiraClients = {};
    },

    //update workflow fields
    updateAlertWorkflowData(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      state.formData[action.payload.field] = action.payload.value;
    },
    updateAlertWorkflowConditions(
      state,
      action: PayloadAction<Record<string, AlertConditions>>,
    ) {
      state.alertConditions = action.payload;
    },
    updateAlertWorkflowConditionByID(
      state,
      action: PayloadAction<AlertConditions>,
    ) {
      state.alertConditions[action.payload.name] = action.payload;
    },
    updateTagKeyValues(
      state,
      action: PayloadAction<Record<string, AlertConditions>>,
    ) {
      state.tagKeyValues = action.payload;
    },
    updateTagKeyValueByID(
      state,
      action: PayloadAction<{ id: string; value: AlertConditions }>,
    ) {
      state.tagKeyValues[action.payload.id] = action.payload.value;
    },
    addTagKeyValue(
      state,
      action: PayloadAction<{ id: string; tag_key: string }>,
    ) {
      state.tagKeyValues[action.payload.id] = {
        name: 'tags',
        entity_list: [],
        tag_key: action.payload.tag_key,
      };
    },
    updateJiraClientFields(
      state,
      action: PayloadAction<{ name: string; field: string; value: string[] }>,
    ) {
      const { name, field, value } = action.payload;
      state.jiraClients[name]
        ? (state.jiraClients[name][field] = value)
        : (state.jiraClients[name] = { [field]: value });
    },
    updateJiraClients(state, action: PayloadAction<{ names: string[] }>) {
      const { names } = action.payload;
      forEach(names, o => (state.jiraClients[o] = {}));
    },
    updateAlertWorkflowActions(
      state,
      action: PayloadAction<AlertWorkflowClients>,
    ) {
      const { clients } = state.formData;
      const { client_name, client_list } = action.payload;
      const index = findIndex(
        clients,
        o => o.client_name === action.payload.client_name,
      );

      //if client is present
      if (index > -1) {
        //if client_list is not empty, update client_list
        if (client_list.length > 0) {
          // @ts-ignore
          state.formData.clients[index].client_list = client_list;
        }

        //else remove client
        else {
          const options: AlertWorkflowClients[] = [];
          map(clients, each => {
            if (each.client_name !== client_name) options.push(each);
          });
          if (client_name === Clients.Jira) {
            state.jiraClients = {};
          }
          state.formData.clients = options;
        }
      } else
        client_list.length > 0 && state.formData?.clients?.push(action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = alertWorkflowSlice;
