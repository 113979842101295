import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { DetailsIdentifier, getIcon, Tag } from 'components/DataDisplay';
import { useGetDescription } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useGetDescription';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const SnowflakeSummary: FC = () => {
  const { resourceInsight, resourceDetails, resourceType } = useSelector(
    selectActiveResource,
  );
  const { data: insight } = resourceInsight;
  const { data: details } = resourceDetails;

  const { getDescription } = useGetDescription();

  return (
    <HStack spacing={6} align="flex-start">
      <Stack minW={64} spacing={3}>
        <DetailsIdentifier
          label="Cloud"
          value={<Box w={6}>{getIcon(insight?.provider ?? 'aws')}</Box>}
        />
        {resourceType === NativeResources.SnowflakeUsers && (
          <DetailsIdentifier label="Email" value={details?.config?.email} />
        )}
        {resourceType !== NativeResources.SnowflakeUsers && (
          <DetailsIdentifier
            label="Cloud ID"
            value={insight?.resourceId ?? details?.meta?.resource_id ?? 'None'}
          />
        )}
        <DetailsIdentifier
          label="Owner"
          value={insight?.owner ?? details?.config?.owner ?? 'None'}
        />
      </Stack>
      <Stack>
        <DetailsIdentifier
          label="Tags"
          value={
            !!insight?.tags?.length
              ? insight?.tags?.map((o, index) => {
                  const key = o[index]?.Key ?? o[index]?.key;
                  const value = o[index]?.Value ?? o[index]?.value;

                  const label = key + ': ' + value;
                  return <Tag label={label} />;
                })
              : 'None'
          }
        />
        <DetailsIdentifier
          label="Comment"
          value={
            resourceInsight.isLoading || resourceDetails.isLoading
              ? 'None'
              : getDescription()
          }
        />
        {resourceType === NativeResources.SnowflakeUsers && (
          <DetailsIdentifier
            label="Identity Provider"
            value={insight?.cloud_type}
          />
        )}
      </Stack>
    </HStack>
  );
};
