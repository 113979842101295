import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.organizationWizard || initialState;

export const selectOrganizationWizard = createSelector(
  [selectDomain],
  organizationWizardState => organizationWizardState,
);
