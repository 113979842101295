import { useMemo } from 'react';

import { Box, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { AccessMapContextProvider } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';
import { PostureGraphContextProvider } from 'components/Visualization/PixiGraph/maps/PostureMap/hooks/usePostureGraphContext';
import { AccessGraph } from 'containers/Visibility/SecurityGraphNext/Components/Access/AccessGraph';
import { AccessSidebar } from 'containers/Visibility/SecurityGraphNext/Components/Access/AccessSidebar';
import { PostureGraph } from 'containers/Visibility/SecurityGraphNext/Components/Posture/PostureGraph';
import { PostureSidebar } from 'containers/Visibility/SecurityGraphNext/Components/Posture/PostureSidebar';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSecurityGraphContext } from 'containers/Visibility/SecurityGraphNext/hooks/useSecurityGraphContext';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { SecurityGraphMapType } from 'containers/Visibility/SecurityGraphNext/types';

export const useGraphComponents = () => {
  const { mapType, searchResult } = useSearchResult();
  const { enrichInsights } = useSelector(selectSecurityGraphNext);
  const securityGraphContext = useSecurityGraphContext();

  const graphComponent = useMemo(() => {
    if (searchResult.isSuccess && mapType === SecurityGraphMapType.Access) {
      return (
        <AccessMapContextProvider value={securityGraphContext}>
          <AccessGraph findings={enrichInsights.alertingEntities} />
        </AccessMapContextProvider>
      );
    } else if (
      searchResult.isSuccess &&
      mapType === SecurityGraphMapType.Posture
    ) {
      return (
        <PostureGraphContextProvider value={securityGraphContext}>
          <PostureGraph />
        </PostureGraphContextProvider>
      );
    } else {
      if (searchResult.isLoading) {
        return (
          <Box h={'full'}>
            <CustomSpinner size="xl" />
          </Box>
        );
      } else {
        return (
          <Box h={'full'}>
            <Box pos={'absolute'} w={'full'} h={'full'} left={0} top={0}>
              <Center color={'#aaa'} h={'full'}>
                No data found
              </Center>
            </Box>
          </Box>
        );
      }
    }
  }, [
    enrichInsights.alertingEntities,
    mapType,
    searchResult.isLoading,
    searchResult.isSuccess,
    securityGraphContext,
  ]);

  const sidebarComponent = useMemo(() => {
    console.log('mapType', mapType);
    if (mapType === SecurityGraphMapType.Access) {
      return <AccessSidebar />;
    } else if (mapType === SecurityGraphMapType.Posture) {
      return <PostureSidebar />;
    } else {
      return (
        <Box h={'full'}>
          <CustomSpinner size="md" />
        </Box>
      );
    }
  }, [mapType]);

  return { graphComponent, sidebarComponent };
};
