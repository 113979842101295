import React, { useEffect, useState } from 'react';

import { filter, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Card } from 'app/components/DataDisplay/Card';
import { Form } from 'app/components/DataEntry/Form';
import { PageHeaderWithIcon } from 'components/DataDisplay';
import { GroupIcon } from 'components/Icons';
import { Layout } from 'components/Layout';
import { selectGroups } from 'containers/Setup/Groups/selectors';
import { selectRoles } from 'containers/Setup/Roles/selectors';
import * as rolesSlice from 'containers/Setup/Roles/slice';
import { selectSetupUsers } from 'containers/Setup/Users/selectors';
import * as usersSlice from 'containers/Setup/Users/slice';
import { form_styles } from 'containers/Setup/Users/styles';
import { toPascalCase } from 'utils/string';

import { getOptions } from '../../utils';
import { actions } from '../slice';

export interface IGroupDetails {
  action: 'create' | 'update' | 'view';
  isLoading?: boolean;
  formData: any;
  isDataLoading: boolean;

  onSubmit(data: any): void;
}

export const GroupDetails = (props: IGroupDetails) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { action, isLoading, isDataLoading, formData, onSubmit } = props;

  const { accounts } = useSelector(selectGroups);
  const { users } = useSelector(selectSetupUsers);
  const { roles: rolesData } = useSelector(selectRoles);

  const [actionType, setActionType] = useState(false);
  const [name, setName] = useState('');
  const [disabledEditRole, setDisableEditRole] = useState(false);
  const [autoAddAccounts, setAutoAddAccounts] = useState(false);
  const [formAccounts, setFormAccounts] = useState<Record<string, any>[]>([]);
  const [formAdmin, setFormAdmin] = useState<Record<string, any>>({});
  const [formUsers, setFormUsers] = useState<Record<string, any>[]>([]);
  const [roleOptions, setRoleOptions] = useState<Record<string, any>[]>([]);
  const [userOptions, setUserOptions] = useState<Record<string, any>[]>([]);
  const [adminOptions, setAdminOptions] = useState<Record<string, any>[]>([]);
  const [cloudAccountOptions, setCloudAccountOptions] = useState<
    Record<string, any>[]
  >([]);
  const [formRoles, setFormRoles] = useState<Record<string, any>[]>([]);

  /***********************get accounts, users, roles***************************/
  useEffect(() => {
    dispatch(
      actions.getCloudAccounts({
        q: { members: true },
        page: { page_number: 1, page_size: 100 },
      }),
    );
  }, [dispatch]);

  // get org users
  useEffect(() => {
    if (action !== 'view') dispatch(usersSlice.actions.loadUsers());
  }, [dispatch, action]);

  useEffect(() => {
    if (action !== 'view') dispatch(rolesSlice.actions.getRoles());
  }, [dispatch, action]);

  /********************set options*********************************************/
  useEffect(() => {
    setUserOptions([
      { label: 'Select all users', value: 'all' },
      ...map(users.payload, o => ({
        value: o,
        label: !!o.first_name ? o.first_name + ' ' + o.last_name : o.username,
        key: o.first_name,
      })),
    ]);
  }, [users.payload]);
  useEffect(() => {
    setRoleOptions([
      { label: 'Select all roles', value: 'all' },
      ...getOptions(rolesData.roles, 'name'),
    ]);
  }, [rolesData]);
  useEffect(() => {
    setCloudAccountOptions([
      { label: 'Select all accounts', value: 'all' },
      ...map(accounts.data, o => ({
        label: o.name,
        value: o,
        icon: o.cloud_type,
      })),
    ]);
  }, [accounts.data]);
  useEffect(() => {
    setAdminOptions(getOptions(users.payload, 'username'));
  }, [users.payload]);

  /****************************************************************************/
  useEffect(() => {
    setName(formData?.name);
  }, [formData?.name]);
  useEffect(() => {
    setFormRoles(getOptions(formData?.roles, 'name'));
  }, [formData?.roles]);

  useEffect(() => {
    const admin = ['update', 'view'].includes(action)
      ? getOptions(formData?.admins, 'username')?.[0]
      : {
          // Selecting the first user from the users list.
          value: users?.payload[0],
          label: users?.payload[0]?.username,
          key: users?.payload[0]?.username,
        };
    setFormAdmin(admin);
  }, [action, formData?.admins, users?.payload]);

  useEffect(() => {
    setFormUsers(
      map(formData?.users, o => ({
        value: o,
        label: !!o.first_name ? o.first_name + ' ' + o.last_name : o.username,
        key: o.first_name,
      })),
    );
  }, [formData?.users]);

  useEffect(() => {
    setFormAccounts(
      filter(cloudAccountOptions, o =>
        includes(formData?.account_ids, o.value.uuid),
      ),
    );
  }, [cloudAccountOptions, formData?.account_ids]);

  useEffect(() => {
    setAutoAddAccounts(formData?.auto_add_accounts);
  }, [formData?.auto_add_accounts]);

  useEffect(() => {
    if (action === 'view') {
      setActionType(true);
    }
    // Disable role edit if only one role is assigned
    // if (formData.roles?.length === 1 && action === 'update') {
    //   setDisableEditRole(true);
    // }
  }, [action]);

  const handleBack = () => {
    navigate('/setup/groups');
  };

  const handleSubmit = data => {
    onSubmit({
      ...data,
      accounts: formAccounts,
      users: formUsers,
      auto_add_user: autoAddAccounts,
      roles: formRoles,
      admin: formAdmin,
    });
  };

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  return (
    <Layout>
      <Card styles={{ card: { pl: 24, overflow: 'visible' } }}>
        <Form
          isLoading={isDataLoading}
          title={
            <PageHeaderWithIcon
              label={`${toPascalCase(action)} Groups`}
              icon={<GroupIcon />}
              reversed
            />
          }
          formValidation={schemaForm}
          schema={{
            name: {
              type: 'text',
              label: 'Group Name',
              isRequired: true,
              placeholder: 'Engineering-US',
              isDisabled: actionType,
              value: name,
              onChange: setName,
              tooltip:
                'Only alphanumeric, hyphen, underscore and space is allowed.',
            },
            admins: {
              type: 'react-select',
              label: 'Group Admin',
              isRequired: true,
              isDisabled: actionType,
              value: formAdmin,
              options: adminOptions,
              onChange: setFormAdmin,
              isLoading: users.loading,
            },
            ...(action === 'update' || action === 'view'
              ? {
                  roles: {
                    type: 'react-select',
                    label: 'Role',
                    isDisabled: actionType || disabledEditRole,
                    isMulti: true,
                    value: formRoles,
                    options: roleOptions,
                    onChange: setFormRoles,
                    isLoading: rolesData.loading,
                  },
                  users: {
                    type: 'react-select',
                    label: 'User',
                    isMulti: true,
                    isDisabled: actionType,
                    value: formUsers,
                    options: userOptions,
                    onChange: setFormUsers,
                    isLoading: users.loading,
                  },
                  accounts: {
                    type: 'react-select',
                    label: 'Accounts assigned',
                    isMulti: true,
                    isDisabled: actionType,
                    value: formAccounts,
                    options: cloudAccountOptions,
                    onChange: opts => {
                      setFormAccounts(opts);
                    },
                  },
                }
              : {}),
            auto_add_accounts: {
              type: 'switch',
              label: 'Automatically add newly onboarded accounts to group',
              isChecked: autoAddAccounts,
              onChange: val => {
                setAutoAddAccounts(!!val);
              },
            },
          }}
          styles={form_styles}
          buttonOptions={{
            submit: {
              name: 'Okay',
              isLoading,
            },
            reset: {
              isVisible: true,
              name: 'Cancel',
              onClick: () => navigate('/setup/groups'),
            },
          }}
          handleSubmit={action === 'view' ? handleBack : handleSubmit}
        />
      </Card>
    </Layout>
  );
};
