import { Box, Center, Flex, HStack, Square } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { BlueprintViewIcon, IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';

export const SidebarLink = ({ label, iconType, onClick, tooltip }) => {
  return (
    <HStack spacing={2}>
      <Square
        p={iconType === IconTypes.Dependencies ? 0.5 : 0}
        color="primary"
        cursor={'pointer'}
        size={'26px'}
      >
        {getIcon(iconType)}
      </Square>
      <CustomTooltip label={tooltip} w={36} textAlign="center">
        <Box onClick={onClick} color="primary" fontSize={'sm'}>
          {label}
        </Box>
      </CustomTooltip>
    </HStack>
  );
};
