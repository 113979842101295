import React from 'react';

import { customTheme } from 'theme';

import { commonStyles } from '../styles';
import { customizedLegend } from '../utils';

export const defaultStyles = {
  legend: {
    iconType: 'plainline',
    wrapperStyle: { top: 0, right: 10 },
    align: 'right',
    iconSize: 8,
    formatter: customizedLegend,
  },
  tooltip: {
    contentStyle: {
      fontSize: '12px',
    },
  },
  grid: {
    stroke: customTheme.colors.chartGridColor,
  },
  angleAxis: {
    tick: props => {
      const { x, y, payload, textAnchor } = props;
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            fill="#666"
            alignmentBaseline="middle"
            textAnchor={textAnchor}
            fontSize={12}
          >
            {payload.value}
          </text>
        </g>
      );
    },
  },
  ...commonStyles,
};
