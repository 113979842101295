/**
 *
 * NavBar
 *
 */
import React, { memo } from 'react';

import { Box } from '@chakra-ui/react';

import { useRouteConfig } from 'hooks/RouteConfig/useRouteConfig';

import { NavBarContextProps, NavBarContextProvider } from './context';
import SecondaryNavBar from './SecondaryNavBar';
import TopNavBar from './TopNavBar';

interface Props extends NavBarContextProps {}

export const NavBar: React.FC<Props> = props => {
  const config = useRouteConfig('navBar');

  return (
    <>
      <NavBarContextProvider value={props}>
        <Box pos={'sticky'} top={0} zIndex={5000}>
          <TopNavBar />
          <Box id="check" display={['none', 'none', 'none', 'block']}>
            <SecondaryNavBar config={config} />
          </Box>
        </Box>
        {/*<BreadcrumbsNavBar />*/}
      </NavBarContextProvider>
    </>
  );
};

export default memo(NavBar);
