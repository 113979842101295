import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  EBSDeleteOnTermination,
  Encrypted,
  IOPS,
  KeyID,
  MultiAttachAllowed,
  Owner,
  Region,
  RootDeviceName,
  Size,
  Type,
  VirtualMachines,
} from '../../../Components/MetadataField/MetaGridFields';

export const EBSMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields vpc={insight} />
      {Region(insight)}
      {Owner(insight)}

      {Encrypted(resource)}
      {KeyID(resource)}
      {RootDeviceName(resource)}
      {Size(resource)}
      {IOPS(resource)}
      {Type(resource)}
      {EBSDeleteOnTermination(resource)}
      {MultiAttachAllowed(resource)}
      {VirtualMachines(resource)}
    </Grid>
  );
};
