import { IGroup } from '@antv/g-base/lib/interfaces';
import G6, { ModelConfig } from '@antv/g6';

import { iconSwitch, resourceIcons } from 'components/Icons';
import { HEADER_NODE } from 'components/Visualization/Graphs/nodes/nodes_types';

import { colorMap } from '../../../../../theme';

export function registerHeaderNode() {
  G6.registerNode(
    HEADER_NODE,
    {
      setState: () => {},
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        // @ts-ignore
        const data: any = cfg?.data ?? {};
        const container = group?.addShape('rect', {
          attrs: {
            width: 190,
            height: 30,
            opacity: 1,
            cursor: 'pointer',
          },
          name: 'node',
        });
        const { x = 0, y = 0, height = 0, width = 0 } = container?.getBBox();

        group?.addShape('text', {
          attrs: {
            text: data.label,
            textAlign: 'center',
            x: x + width / 2,
            y: y + height / 2,
            fontSize: 14,
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
            fontFamily: 'MetroSans',
          },
          name: 'resource_name-1',
        });

        let img = iconSwitch(data?.label ?? '', resourceIcons);
        //add image
        group?.addShape('image', {
          attrs: {
            x: x + 4,
            y: y - 4,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        group?.addShape('rect', {
          attrs: {
            width: 190,
            height: 1,
            y: y + height - 2,
            fill: colorMap.primary(),
            cursor: 'pointer',
          },
          name: 'resource_name-1',
        });

        return container;
      },
      getAnchorPoints() {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );
}
