import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  Catalogue,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  InventorySummary,
  ResourceApiGetDeploymentSummaryRequest,
  ResourceApiGetInventoryCatalogueRequest,
  ResourceApiGetInventorySummaryRequest,
  ResourceApiGetVpcsRequest,
  ResourceVulnerabilities,
  TagsApiCreateTagsRequest,
  TagsApiGetTagsResourceRequest,
  TagsApiRemoveTagRequest,
  TagsRead,
  VPCInfo,
  VulnerabilitiesApiGetVulnerabilitiesRequest,
} from '@ariksa/inventory-core/api';
import { ResourceApiGetInventoryOverviewRequest } from '@ariksa/inventory-core/dist/api';
import {
  AllResourceCountResponse,
  InventoryApiGetAllResourceCountRequest,
} from '@ariksa/reporting/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { each, forEach } from 'lodash';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

import { apiCallStateInitialState } from 'api/initial_values';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Inventory container
export const initialState: ContainerState = {
  categories: QueryState.init([]),
  inventoryOverview: QueryState.init([]),
  instanceInventory: PagedQueryState.init<Dict<any>[]>([], {
    page: { info: { page_size: 100, page_number: 1 }, totalCount: 0 },
  }),
  tags: apiCallStateInitialState([]),
  deploymentSummary: QueryState.init([]),
  contextValues: QueryState.init([]),
  searchWord: '',
  currentCategory: '',
  currentCategoryTitle: '',
  instanceResourceType: '',
  instanceAnalysisSummary: QueryState.init({}),

  createTag: QueryState.init({}),
  attachedContexts: QueryState.init([]),
  lastRefreshed: QueryState.init({} as WorkflowRead),
  inventoryHistory: QueryState.init({}),
  vpcs: QueryState.init([]),
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    getResourceCategories(
      state,
      action: QueryAction<
        InventorySummary,
        ResourceApiGetInventorySummaryRequest
      >,
    ) {
      state.categories = QueryState.next(state.categories, action, {
        mapData: r => r?.categories ?? [],
      });
    },

    getInventoryOverview(
      state,
      action: QueryAction<
        AppSchemasInventoryResourceCountDeploymentSummary[],
        ResourceApiGetInventoryOverviewRequest
      >,
    ) {
      state.inventoryOverview = QueryState.next(
        state.inventoryOverview,
        action,
      );
    },

    updateCurrentCategory(state, action: PayloadAction<string>) {
      state.currentCategory = action.payload;
      localStorage.setItem('currentCategory', action.payload);
    },
    updateCurrentCategoryTitle(state, action: PayloadAction<string>) {
      state.currentCategoryTitle = action.payload;
    },
    resetCurrentCategory(state) {
      state.currentCategory = '';
      state.currentCategoryTitle = '';
      localStorage.removeItem('currentCategory');
    },
    getInstanceInventory(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.instanceInventory = PagedQueryState.next(
        state.instanceInventory,
        action,
        { mapData: r => r?.items, mapTotalCount: r => r?.total || 0 },
      );
    },

    updatePageInfo(state, action: PayloadAction<any>) {
      state.instanceInventory.page.info = action.payload;
    },

    createTags(state, action: QueryAction<any, TagsApiCreateTagsRequest>) {
      state.createTag = QueryState.next(state.createTag, action);
    },
    detachContext(state, action: QueryAction<any, TagsApiRemoveTagRequest>) {
      state.createTag = QueryState.next(state.createTag, action);
    },

    getLastRefreshed(
      state,
      action: QueryAction<WorkflowRead[], WorkflowApiGetLatestRequest>,
    ) {
      state.lastRefreshed = QueryState.next(state.lastRefreshed, action, {
        mapData: r => r?.[0],
      });
    },

    getContextValues(
      state,
      action: QueryAction<Catalogue[], ResourceApiGetInventoryCatalogueRequest>,
    ) {
      state.contextValues = QueryState.next(state.contextValues, action);
    },

    getDeploymentSummary(
      state,
      action: QueryAction<
        AppSchemasInventoryResourceCountDeploymentSummary[],
        ResourceApiGetDeploymentSummaryRequest
      >,
    ) {
      state.deploymentSummary = QueryState.next(
        state.deploymentSummary,
        action,
      );
    },

    setNativeResource(state, action: PayloadAction<string>) {
      state.instanceResourceType = action.payload;
    },
    resetNativeResource(state) {
      state.instanceResourceType = '';
    },

    setSearchWord(state, action: PayloadAction<string>) {
      state.searchWord = action.payload;
    },

    resetSearchItems(state) {
      state.searchWord = '';
    },

    getAttachedContexts(
      state,
      action: QueryAction<TagsRead[], TagsApiGetTagsResourceRequest>,
    ) {
      state.attachedContexts = QueryState.next(state.attachedContexts, action);
    },

    getVPCs(state, action: QueryAction<VPCInfo[], ResourceApiGetVpcsRequest>) {
      state.vpcs = QueryState.next(state.vpcs, action);
    },

    getInventoryHistory(
      state,
      action: QueryAction<
        AllResourceCountResponse[],
        InventoryApiGetAllResourceCountRequest
      >,
    ) {
      state.inventoryHistory = QueryState.next(state.inventoryHistory, action, {
        mapData: r => {
          let items = {};
          each(r, o => (items[o.resource_type] = o));
          return items;
        },
      });
    },

    getVulnerabilities(
      state,
      action: QueryAction<
        ResourceVulnerabilities[],
        VulnerabilitiesApiGetVulnerabilitiesRequest
      >,
    ) {
      state.instanceAnalysisSummary = QueryState.next(
        state.instanceAnalysisSummary,
        action,
        {
          mapData: r => {
            let data = {};
            forEach(r, o => (data[o?.resource_id || ''] = o));
            return data;
          },
        },
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = inventorySlice;
