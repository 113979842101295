import {
  CloudAccountApiChangeDiscoverInventoryIntervalRequest,
  CloudAccountApiDeleteCloudAccountByUuidRequest,
  CloudAccountApiGetCloudAccountByUuidRequest,
  CloudAccountApiGetCloudAccountsByParentRequest,
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiPauseDiscoverInventoryRequest,
  CloudAccountApiReadConfigRequest,
  CloudAccountApiReadRemediationConfigRequest,
  CloudAccountApiResumeDiscoverInventoryRequest,
  CloudAccountGetResponse,
  OnboardingConfigResponse,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  CheckDetailResponse,
  ChecklistApiGetCheckdetailRequest,
  ChecklistApiGetChecklistRequest,
  ChecklistApiGetGovernanceRemediationRequest,
  CheckListResponse,
  EnvironmentApiGetAttachedEnvironmentsOnAccountRequest,
  EnvironmentRead,
  GovernanceResponse,
  ReportApiGetChecksReportRequest,
} from '@ariksa/compliance-policies/api';
import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import { ChartsApiGetGovernanceScoreChartRequest } from '@ariksa/reporting/dist/api';
import { ScannerInfo } from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';
import { v4 as uuidv4 } from 'uuid';

import {
  Api,
  CloudAccountService,
  ComplianceService,
  DataScanningService,
  ReportingService,
  ScanAnalysis,
  SnapshotParams,
  SnapshotTasksParams,
  UUID,
} from 'app/api';
import { CloudAccountIds } from 'app/api/inventory/types';
import { errorToast } from 'app/components';
import { doGetCloudAccountSnapshotStatus } from 'containers/SharedState/saga';
import request from 'utils/request';

import { actions } from './slice';
import { RediscoverInventoryRequestParams } from './types';

export function* cloudAccountsSaga() {
  yield takeLatestAction(actions.getCloudAccounts, doGetAccounts);
  yield takeLatestAction(actions.getMemberAccounts, doGetMemberAccounts);
  yield takeLatestAction(actions.deleteAccount, doDeleteAccount);
  yield takeLatestAction(actions.rediscoverInventory, doRediscoverInventory);
  yield takeLatestAction(
    actions.getDataScannerInfo,
    doGetCloudAccountDataScannerInfo,
  );
  yield takeLatestAction(
    actions.loadCloudAccountDetails,
    doLoadCloudAccountDetails,
  );
  yield takeLatest(actions.loadSnapshotTasks.type, doGetSnapshotTasks);
  yield takeLatest(actions.loadAssetSummary.type, doGetAssetSummary);
  yield takeLatest(actions.refreshMemberAccounts.type, doRefreshMemberAccounts);
  yield takeLatest(actions.onboardMemberAccounts.type, doOnboardMemberAccounts);

  yield takeLatest(actions.loadSnapshots.type, doGetSnapshots);
  yield takeLatestAction(actions.pauseDiscovery, doPauseDiscovery);
  yield takeLatestAction(actions.resumeDiscovery, doResumeDiscovery);
  yield takeLatestAction(
    actions.getVulnerabilityScanner,
    doGetCloudAccountVulnerabilityScanner,
  );

  yield takeLatestAction(
    actions.loadGovernanceChecklist,
    doGetGovernanceChecklist,
  );
  yield takeLatestAction(
    actions.loadGovernanceCheckDetails,
    doGetGovernanceCheckDetails,
  );
  yield takeLatestAction(
    actions.getChecklistHistoricalTrend,
    doGetChecklistHistoricalTrend,
  );
  yield takeLatestAction(
    actions.loadGovernanceCheckRemediation,
    doGetGovernanceCheckRemediation,
  );
  yield takeLatestAction(actions.downloadChecklist, doDownloadChecklist);

  yield takeLatestAction(
    actions.getAttachedEnvironments,
    doGetAttachedEnvironments,
  );
  yield takeLatestAction(actions.getAccountConfig, doGetAccountConfig);
  yield takeLatestAction(
    actions.getSnapshotStatusForAnAccount,
    doGetCloudAccountSnapshotStatus,
  );
  yield takeLatestAction(
    actions.updateAccountDiscoveryInterval,
    doUpdateAccountDiscoveryInterval,
  );
  yield takeLatestAction(actions.getRemediationConfig, doGetRemediationConfig);
}

export function* doUpdateAccountDiscoveryInterval(
  ctx: QueryContext<any, CloudAccountApiChangeDiscoverInventoryIntervalRequest>,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.changeDiscoverInventoryInterval(
      ctx.params,
    ),
  );
}

export function* doGetAccountConfig(
  ctx: QueryContext<OnboardingConfigResponse, CloudAccountApiReadConfigRequest>,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.readConfig(ctx.params),
  );
}

export function* doOnboardMemberAccounts(action: PayloadAction<any>) {
  const { account } = action.payload;
  const { uuid = '', cloud_type = '', name = '' } = account;
  const getConfigUrl = Api.getCloudAccountConfig({ cloud_type });
  const onboardUrl = Api.onboardMemberAccounts({ uuid });

  try {
    const config = yield call(request, getConfigUrl);
    const { aws_config } = config;

    // save the account info in database
    const onboard = yield call(request, onboardUrl, {
      method: 'POST',
      data: {
        aws: aws_config,
      },
    });

    const redirectUrl = Api.awsOnboardRedirectURL({
      stackName: `Ariksa-${name}-${uuidv4()}`,
      param_ExternalID: aws_config?.external_id,
      templateURL: aws_config?.cfs_template_url,
      param_S3BucketForCUR: '',
      param_AriksaAdminAccountId: aws_config?.account_id,
      param_AriksaAdminSNSTopic: aws_config?.sns_topic,
    });

    window.open(redirectUrl, '_blank');

    // yield put(actions.onboardMemberAccountsSuccess(action.payload));
  } catch (err: any) {
    errorToast({
      title: `Failed to onboard member accounts.`,
      description: err.message,
    });
    yield put(actions.onboardMemberAccountsError(err));
  }
}

export function* doRefreshMemberAccounts(action: PayloadAction<UUID>) {
  const requestUrl = Api.refreshCloudAccount(action.payload);
  try {
    const refreshed = yield call(request, requestUrl, {
      method: 'POST',
    });

    yield put(actions.refreshMemberAccountsSuccess(action.payload));
  } catch (err: any) {
    errorToast({
      title: `Failed to refresh member accounts.`,
      description: err.message,
    });
    yield put(actions.refreshMemberAccountsError(err));
  }
}

function* doGetAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.getCloudAccounts(ctx.params),
    {
      errorMsg: 'Failed to get accounts!',
    },
  );
}

function* doGetMemberAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsByParentRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.getCloudAccountsByParent(ctx.params),
    {
      errorMsg: 'Failed to get member accounts for management account!',
    },
  );
}

export function* doGetCloudAccountVulnerabilityScanner(
  ctx: QueryContext<Record<string, ScannerInfo[]>>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Item.getVulnerabilityScanners(), {
    errorMsg: 'Failed to get vulnerability scanner info!',
  });
}

export function* doGetCloudAccountDataScannerInfo(
  ctx: QueryContext<Record<string, any>, FileDataApiGetScannersRequest>,
) {
  yield call(ctx.fetch, () => DataScanningService.FileData.getScanners(), {
    errorMsg: 'Failed to get data scanner info!',
  });
}

export function* doDeleteAccount(
  ctx: QueryContext<any, CloudAccountApiDeleteCloudAccountByUuidRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.deleteCloudAccountByUuid(ctx.params),
    { errorMsg: 'Failed to delete account!' },
  );
}

export function* doRediscoverInventory(
  ctx: QueryContext<any, RediscoverInventoryRequestParams>,
) {
  const { uuid, discoverAll = false } = ctx.params;
  yield call(
    ctx.fetch,
    () =>
      CloudAccountService.CloudAccount.discoverInventory({ uuid, discoverAll }),
    {
      errorMsg: 'Request to do rediscovery has failed!',
      successMsg: 'Rediscovery is in progress!',
    },
  );
}

export function* doLoadCloudAccountDetails(
  ctx: QueryContext<
    CloudAccountGetResponse,
    CloudAccountApiGetCloudAccountByUuidRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.getCloudAccountByUuid(ctx.params),
    { errorMsg: 'Failed to get cloud account details!' },
  );
}

export function* doGetSnapshotTasks(
  action: PayloadAction<SnapshotTasksParams>,
) {
  const requestUrl = Api.getSnapshotTasks(action.payload);
  try {
    const data = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadSnapshotTasksSuccess(data));
  } catch (err: any) {
    errorToast({
      title: 'Failed to get data.',
      description: err.message,
    });
    yield put(actions.loadSnapshotTasksError(err));
  }
}

export function* doGetAssetSummary(action: PayloadAction<CloudAccountIds>) {
  const requestUrl = Api.getResourceCount(action.payload);
  try {
    const data = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadAssetSummarySuccess(data.resources));
  } catch (err: any) {
    errorToast({
      title: 'Failed to get data.',
      description: err.message,
    });
    yield put(actions.loadAssetSummaryError(err));
  }
}

export function* doGetSnapshots(action: PayloadAction<SnapshotParams>) {
  const requestUrl = Api.getSnapshot(action.payload);
  try {
    const data = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadSnapshotsSuccess(data));
  } catch (err: any) {
    errorToast({
      title: 'Failed to get data.',
      description: err.message,
    });
    yield put(actions.loadSnapshotsError(err));
  }
}

function* doGetGovernanceChecklist(
  ctx: QueryContext<CheckListResponse, ChecklistApiGetChecklistRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Checklist.getChecklist(ctx.params),
    { map: res => res.data, errorMsg: 'Failed to get governance checklist!' },
  );
}

function* doGetGovernanceCheckDetails(
  ctx: QueryContext<CheckDetailResponse[], ChecklistApiGetCheckdetailRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Checklist.getCheckdetail(ctx.params),
    { errorMsg: 'Failed to get governance check details!' },
  );
}

function* doGetGovernanceCheckRemediation(
  ctx: QueryContext<
    GovernanceResponse,
    ChecklistApiGetGovernanceRemediationRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Checklist.getGovernanceRemediation(ctx.params),
    { errorMsg: 'Failed to get remediation for governance check!' },
  );
}

function* doGetAttachedEnvironments(
  ctx: QueryContext<
    EnvironmentRead[],
    EnvironmentApiGetAttachedEnvironmentsOnAccountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.Environment.getAttachedEnvironmentsOnAccount(
        ctx.params,
      ),
    { errorMsg: 'Failed to get attached environments!' },
  );
}

function* doGetChecklistHistoricalTrend(
  ctx: QueryContext<any, ChartsApiGetGovernanceScoreChartRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getGovernanceScoreChart(ctx.params),
    { errorMsg: 'Failed to get historical trend for governance checks!' },
  );
}

function* doDownloadChecklist(
  ctx: QueryContext<any, ReportApiGetChecksReportRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Reports.getChecksReport(ctx.params),
    {
      map: res => res,
      errorMsg: 'Failed to download governance checks report!',
    },
  );
}

function* doPauseDiscovery(
  ctx: QueryContext<any, CloudAccountApiPauseDiscoverInventoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.pauseDiscoverInventory(ctx.params),
    {
      map: res => res,
      errorMsg: 'Failed to pause discovery!',
    },
  );
}

function* doResumeDiscovery(
  ctx: QueryContext<any, CloudAccountApiResumeDiscoverInventoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.resumeDiscoverInventory(ctx.params),
    {
      map: res => res,
      errorMsg: 'Failed to resume discovery!',
    },
  );
}

function* doGetRemediationConfig(
  ctx: QueryContext<
    OnboardingConfigResponse,
    CloudAccountApiReadRemediationConfigRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.readRemediationConfig(ctx.params),
    {
      errorMsg: 'Failed to get remediation config!',
    },
  );
}
