import { SearchResponse } from '@ariksa/inventory-core';
import { SearchApiSearchRequest } from '@ariksa/inventory-core/api';
import {
  AlertsApiGetEntitySeverityListRequest,
  EntitySeverityListResponse,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, isNumber, reduce } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  getRolesPolicies,
  PolicyDetailsByIdProps,
  ResourceDetailsProps,
  ResourceHealthCheckProps,
  RoleDetailsProps,
} from 'api/inventory/types';
import {
  InventoryService,
  NotificationService,
  serviceClient,
} from 'api/services';
import { intoSnakeCaseObject } from 'api/utils';
import { Api, InsightParams, InventoryParams, WithFilters } from 'app/api';
import { selectActiveCloudAccount } from 'containers/App/selectors';
import request from 'utils/request';

import { actions } from './slice';

export function* visibilitySaga() {
  yield takeLatest(actions.loadInventory.type, doGetInventory);
  yield takeLatestAction(actions.loadInsights, doGetInsights);
  yield takeLatest(actions.loadResourceList.type, doGetResourceList);
  yield takeLatest(actions.loadPolicies.type, doGetPolicy);
  yield takeLatest(actions.loadResourceDetails.type, doGetResourceDetails);
  yield takeLatest(actions.getConnectedEntity.type, doGetConnectedEntity);
  yield takeLatest(actions.loadRoleDetails.type, doGetRoleDetails);
  yield takeLatest(actions.loadPolicyDetails.type, doGetPolicyDetails);
  yield takeLatest(
    actions.loadResourceHealthStatus.type,
    doGetResourceHealthStatus,
  );
  yield takeLatestAction(actions.loadAlerts, doGetAlerts);
  yield takeLatest(actions.getPolicyDocument.type, doGetPolicyDocument);

  // policy permission api call reducers
  yield takeLatest(
    actions.getPolicyPermissionAccounts.type,
    doGetPolicyPermissionAccounts,
  );
  yield takeLatest(
    actions.getPolicyPermissionServices.type,
    doGetPolicyPermissionServices,
  );
  yield takeLatest(
    actions.getPolicyPermissionResources.type,
    doGetPolicyPermissionResources,
  );
  yield takeLatest(
    actions.getPolicyPermissionActions.type,
    doGetPolicyPermissionActions,
  );
  yield takeLatest(actions.getRolePoliciesById.type, doGetRolePoliciesById);
  yield takeLatest(
    actions.getUnusedPermissionList.type,
    doGetUnusedPermissionList,
  );

  yield takeLatestAction(actions.getResourceInsight, doGetResourceInsight);
}

function* doGetResourceInsight(
  ctx: QueryContext<SearchResponse, SearchApiSearchRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.search(ctx.params), {
    cacheKey: 'resourceInsight',
  });
}

function* doGetUnusedPermissionList(action: PayloadAction<any>) {
  yield call(serviceClient, {
    fn: async () =>
      new Promise<any>((resolve, reject) => {
        setTimeout(() => {
          resolve({});
        }, 1000);
      }),
    onSuccess: actions.getUnusedPermissionListSuccess,
    onError: actions.getUnusedPermissionListError,
  });
}

function* doGetRolePoliciesById(action: PayloadAction<getRolesPolicies>) {
  const requestUrl = Api.getRolePoliciesById(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getRolePoliciesByIdSuccess(res.results ?? []));
  } catch (err) {
    yield put(actions.getRolePoliciesByIdError(err));
  }
}

function* doGetPolicyPermissionAccounts(action: PayloadAction<any>) {
  const requestUrl = Api.getPolicyPermissionAccounts(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getPolicyPermissionAccountsSuccess(res));
  } catch (err) {
    yield put(actions.getPolicyPermissionAccountsError(err));
  }
}

function* doGetPolicyPermissionServices(action: PayloadAction<any>) {
  const requestUrl = Api.getPolicyPermissionServices(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getPolicyPermissionServicesSuccess(res));
  } catch (err) {
    yield put(actions.getPolicyPermissionServicesError(err));
  }
}

function* doGetPolicyPermissionResources(action: PayloadAction<any>) {
  const requestUrl = Api.getPolicyPermissionResources(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getPolicyPermissionResourcesSuccess(res));
  } catch (err) {
    yield put(actions.getPolicyPermissionResourcesError(err));
  }
}

function* doGetPolicyPermissionActions(action: PayloadAction<any>) {
  const requestUrl = Api.getPolicyPermissionActions(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getPolicyPermissionActionsSuccess(res));
  } catch (err) {
    yield put(actions.getPolicyPermissionActionsError(err));
  }
}

function* doGetInventory(action: PayloadAction<InventoryParams>) {
  const { resource_type, ...payload } = action.payload;
  let requestUrl = Api.getResourceInventory(action.payload);
  if (resource_type === 'User') requestUrl = Api.getUserInventory(payload);
  else if (resource_type === 'Role') requestUrl = Api.getRoleInventory(payload);
  else if (resource_type === 'Organization')
    requestUrl = Api.getOrganizationInventory(payload);
  else if (resource_type === 'Credential')
    requestUrl = Api.getCredentialInventory(payload);

  try {
    const inventory = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadInventorySuccess(inventory));
  } catch (err) {
    yield put(actions.loadInventoryError(err));
  }
}

function* doGetAlerts(
  ctx: QueryContext<
    EntitySeverityListResponse,
    AlertsApiGetEntitySeverityListRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getEntitySeverityList(ctx.params),
  );
}

const getInsights = (props: WithFilters<InsightParams>) => {
  const {
    resource_category,
    resource_type,
    identities = false,
    filters = {},
    ...payload
  } = props;

  const params: any = intoSnakeCaseObject({
    resource_type,
    ...payload,
    ...filters,
  });

  //o: object, v: value, k: key
  const data: any = reduce(
    params,
    (o, v, k) => {
      if (!isNumber(v) && isEmpty(v)) return o;
      return {
        ...o,
        [k]: v,
      };
    },
    {},
  );

  // create proper getInsight function
  //   switch (resource_type) {
  //     case 'User':
  //       return async () => InventoryService.insight.userList(payload);
  //     case 'Role':
  //       return async () => InventoryService.insight.roleList(payload);
  //     case 'Organization':
  //       return async () => InventoryService.insight.organizationList(payload);
  //     case 'Credential':
  //       return async () => InventoryService.insight.credentialList(payload);
  //     default:
  //       return async () =>
  //         InventoryService.insight.resourceList(resource_type, payload);
  // };

  if (resource_category === 'Machine') {
    return Api.getInstanceInsights(data);
  }
  if (resource_category === 'WithRoles') {
    return Api.getResourceWithPolicy(data);
  }

  if (resource_type === 'User') {
    return Api.getUserInsights(data);
  } else if (resource_type === 'Role') {
    return Api.getRoleInsights(data);
  } else if (resource_type === 'Organization') {
    return Api.getOrganizationInsights(data);
  } else if (resource_type === 'Credential') {
    return Api.getCredentialInsights(data);
  } else if (resource_type === 'Tags') {
    return Api.getTagsInsights(data);
  } else if (identities) {
    return Api.getInstanceList(data);
  }

  return Api.getResourceInsights(data);
};

function* doGetInsights(ctx: QueryContext<WithFilters<InsightParams>>) {
  const { accountId, cloud } = yield select(selectActiveCloudAccount);
  if (!accountId) {
    return;
  }

  const payload = {
    ...ctx.params,
    account_ids: accountId,
    cloud_name: cloud,
  };

  const requestUrl = getInsights(payload);
  yield call(ctx.fetch, () => request(requestUrl), {
    map: r => r,
    // cacheKey: 'getInsightList',
    // cacheTime: 20000,
  });
}

function* doGetResourceList(action: PayloadAction<WithFilters<InsightParams>>) {
  const { accountId, cloud } = yield select(selectActiveCloudAccount);

  const payload = {
    ...action.payload,
    account_ids: accountId,
    cloud_name: cloud,
  };

  if (!accountId) {
    return;
  }

  try {
    const insights = yield call(request, getInsights(payload), {
      method: 'GET',
    });
    yield put(actions.loadResourceListSuccess(insights));
  } catch (err) {
    yield put(actions.loadResourceListError(err));
  }
}

function* doGetPolicy(action: PayloadAction<WithFilters<InventoryParams>>) {
  const { resource_type, filters = {}, ...payload } = action.payload;

  const { accountId, cloud } = yield select(selectActiveCloudAccount);

  const data = {
    ...payload,
    ...filters,
    resource_type,
    accountIds: accountId,
    cloudName: cloud,
  };

  let requestUrl;
  switch (resource_type) {
    case 'User':
      requestUrl = Api.getUserPolicies(data);
      break;
    case 'Organization':
      requestUrl = Api.getOrganizationPolicies(data);
      break;
    case 'Role':
      requestUrl = Api.getRolePolicies(data);
      break;
    case 'Tags':
      requestUrl = Api.getTagsPolicies(data);
      break;
    default:
      requestUrl = Api.getResourcePolicies(data);
  }

  try {
    const policies = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadPoliciesSuccess(policies));
  } catch (err) {
    yield put(actions.loadPoliciesError(err));
  }
}

function* doGetConnectedEntity(action: PayloadAction<ResourceDetailsProps>) {
  const requestUrl = Api.getResourceById(action.payload);
  try {
    const details = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getConnectedEntitySuccess(details));
  } catch (err) {
    yield put(actions.getConnectedEntityError(err));
  }
}

function* doGetResourceDetails(action: PayloadAction<ResourceDetailsProps>) {
  const requestUrl = Api.getResourceById(action.payload);
  try {
    const details = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadResourceDetailsSuccess(details));
  } catch (err) {
    yield put(actions.loadResourceDetailsError(err));
  }
}

function* doGetRoleDetails(action: PayloadAction<RoleDetailsProps>) {
  const requestUrl = Api.getRoleDetails(action.payload);
  try {
    const details = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadRoleDetailsSuccess(details));
  } catch (err) {
    yield put(actions.loadRoleDetailsError(err));
  }
}

function* doGetPolicyDetails(action: PayloadAction<PolicyDetailsByIdProps>) {
  const requestUrl = Api.getPolicyDetailsById(action.payload);
  try {
    const details = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.loadPolicyDetailsSuccess(details));
  } catch (err) {
    yield put(actions.loadPolicyDetailsError(err));
  }
}

function* doGetResourceHealthStatus(
  action: PayloadAction<ResourceHealthCheckProps>,
) {
  const requestUrl = Api.getResourceHealthStatus(action.payload);

  try {
    const health = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.resourceHealthStatusLoaded(health));
  } catch (err) {
    yield put(actions.loadResourceHealthStatusError(err));
  }
}

function* doGetPolicyDocument(action: PayloadAction<any>) {
  const requestUrl = Api.getPolicyDocument(action.payload);

  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    });
    yield put(actions.getPolicyDocumentSuccess(res));
  } catch (err) {
    yield put(actions.getPolicyDocumentError(err));
  }
}
