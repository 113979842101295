import { createContext, useContext } from 'react';

export interface EnvironmentContextProps {
  handleBack: () => void;
  handleSubmit: (value: any, accounts?: Record<string, any>) => void;
  actionType?: 'Update' | 'Create';
  onCancel: () => void;
  tagKeyOptions: Record<string, any>[];
  updateFormData: (field: string, value: any) => void;
  updateCriteria: (field: string, value: any) => void;
  resetCriteria: (field: string) => void;
}

export const EnvironmentContext = createContext<EnvironmentContextProps>({
  handleSubmit: () => {},
  onCancel: () => {},
  handleBack: () => {},
  tagKeyOptions: [],
  actionType: 'Create',
  updateFormData: (field: string, value: any) => {},
  updateCriteria: (field: string, value: any) => {},
  resetCriteria: (field: string) => {},
});

export const EnvironmentContextProvider = EnvironmentContext.Provider;

export const useEnvironmentContext = () => useContext(EnvironmentContext);
