import { useCallback, useEffect, useState } from 'react';

import { BluePrintsRead } from '@ariksa/compliance-policies/api';
import { forEach, map } from 'lodash';
import { useSelector } from 'react-redux';

import { selectSharedState } from 'containers/SharedState/selectors';

export const useBlueprintsMapping = () => {
  const { blueprints } = useSelector(selectSharedState);
  const [blueprintMapping, setBlueprintMapping] = useState<
    Record<string, BluePrintsRead>
  >({});

  useEffect(() => {
    const options: Record<string, any> = {};
    forEach(blueprints.data, o => (options[o.id] = o));
    setBlueprintMapping(options);
  }, [blueprints.data]);

  const getBlueprint = useCallback(
    (id: string) => {
      return blueprintMapping[id];
    },
    [blueprintMapping],
  );

  const getBlueprintOptions = useCallback(
    () => map(blueprints.data, o => ({ label: o.name, value: o.id, data: o })),
    [blueprints?.data],
  );

  const getBlueprintOptionsWithAll = useCallback(
    () => [
      { label: 'All Blueprints', value: undefined },
      ...getBlueprintOptions(),
    ],
    [getBlueprintOptions],
  );

  return {
    blueprintMapping,
    getBlueprint,
    getBlueprintOptions,
    getBlueprintOptionsWithAll,
  };
};
