import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CriticalDataIcon = props => {
  return (
    <Icon viewBox="0 0 720 720" h="full" w="full" color="inherit" {...props}>
      <g transform="translate(-280 0)">
        <g>
          <path
            d="M954.764 237.42C954.809 237.015 955 236.666 955 236.25 955 236.115 954.932 236.014 954.921 235.879 954.888 234.833 954.629 233.842 954.314 232.841 954.201 232.481 954.19 232.087 954.044 231.75 953.537 230.591 952.806 229.534 951.906 228.578 951.827 228.487 951.783 228.364 951.704 228.285L794.237 70.8075C793.203 69.7725 791.965 68.94 790.604 68.3662 789.22 67.8037 787.758 67.5 786.25 67.5L493.75 67.5C492.242 67.5 490.78 67.8037 489.419 68.3775 488.046 68.9513 486.809 69.7838 485.785 70.8187L328.319 228.296C328.24 228.375 328.195 228.499 328.116 228.589 327.216 229.545 326.474 230.614 325.967 231.772 325.821 232.11 325.81 232.504 325.698 232.852 325.371 233.854 325.112 234.855 325.079 235.901 325.079 236.025 325 236.137 325 236.273 325 236.689 325.191 237.037 325.236 237.443 325.326 238.32 325.45 239.175 325.754 240.019 325.979 240.637 326.283 241.166 326.609 241.717 326.845 242.145 326.957 242.606 327.25 243.011L631 648.011C631.011 648.023 631.023 648.023 631.034 648.034 631.979 649.283 633.205 650.227 634.555 650.981 634.87 651.161 635.174 651.307 635.511 651.454 636.917 652.073 638.403 652.511 640 652.511 641.597 652.511 643.082 652.073 644.489 651.454 644.826 651.307 645.13 651.161 645.445 650.981 646.795 650.227 648.021 649.283 648.966 648.034 648.977 648.023 648.989 648.023 649 648.011L952.75 243.011C953.042 242.617 953.155 242.145 953.391 241.729 953.706 241.166 954.021 240.637 954.246 240.019 954.55 239.152 954.674 238.309 954.764 237.42ZM770.23 247.5 640 608.13 509.77 247.5 770.23 247.5ZM519.546 225 640 95.2875 760.454 225 519.546 225ZM775 207.607 665.796 90 775 90 775 207.607ZM505 207.607 505 90 614.204 90 505 207.607ZM482.5 225 363.408 225 482.5 105.907 482.5 225ZM485.853 247.5 603.876 574.324 358.75 247.5 485.853 247.5ZM794.148 247.5 921.25 247.5 676.124 574.324 794.148 247.5ZM797.5 225 797.5 105.907 916.592 225 797.5 225Z"
            stroke="currentColor"
            strokeWidth="11.25"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            fill="#FFFFFF"
            fillRule="evenodd"
            fillOpacity="1"
          />
        </g>
      </g>
    </Icon>
  );
};
