import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { getIsRedirected } from 'containers/Visibility/SecurityGraphNext/hooks/useCollectRedirectInfo';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const useResetQuery = () => {
  const dispatch = useDispatch();
  const params = useSearchParams();
  const isRedirected = getIsRedirected(params);

  // Reset search query and filters when in redirected state and component is unmounted
  useEffect(() => {
    return () => {
      console.log('useResetQuery');
      if (isRedirected) {
        dispatch(actions.resetSearchQuery());
        dispatch(actions.updateFilters({}));
      }
    };
  }, [dispatch, isRedirected]);
};
