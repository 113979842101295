import { barStyles, commonStyles } from '../styles';
import { customizedLegend } from '../utils';

export const defaultStyles = {
  barChart: {
    margin: {
      top: 30,
      right: 30,
      left: 30,
    },
  },
  tooltip: {
    cursor: {
      opacity: 0.3,
      border: '1px solid black',
    },
    contentStyle: {
      fontSize: '12px',
      borderRadius: 5,
    },
    wrapperStyle: {
      zIndex: 9999999,
      position: 'absolute',
    },
  },
  legend: {
    wrapperStyle: {
      top: 0,
    },
    align: 'right',
    iconSize: 8,
    formatter: customizedLegend,
  },
  bar: barStyles,
  ...commonStyles,
};
