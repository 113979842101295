import React, { FC, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import {
  CancelButton,
  ConfirmationInput,
  IModalProps,
  Modal,
  PrimaryButton,
} from 'components/index';

export interface ConfirmationModalProps extends IModalProps {
  name: string;
  type?: string;
  confirmationText: React.ReactNode;
  onConfirm: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = props => {
  const {
    name,
    type,
    onConfirm,
    isDisabled,
    isLoading,
    onClose,
    isOpen,
    confirmationText,
    body,
    label,
    ...rest
  } = props;

  const [command, setCommand] = useState('');

  const handleOnClickConfirm = () => {
    if (name === command) {
      onConfirm();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        header={'Confirmation'}
        body={
          body ? (
            body
          ) : (
            <ConfirmationInput
              confirmationText={name}
              label={confirmationText}
              command={command}
              setCommand={setCommand}
            />
          )
        }
        size="xl"
        footer={
          <Flex justifyContent={'space-between'} w="full">
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <PrimaryButton
              onClick={handleOnClickConfirm}
              isDisabled={isDisabled || command !== name}
              isLoading={isLoading}
            >
              {label || 'Confirm'}
            </PrimaryButton>
          </Flex>
        }
        {...rest}
      />
    </>
  );
};
