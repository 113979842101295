import React, { FC } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { Box } from '@chakra-ui/react';
import { map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import {
  AwsIcon,
  BitbucketIcon,
  GithubIcon,
  GitLabIcon,
  IconTypes,
  SnowflakeIcon,
} from 'components/Icons';
import { SideNav } from 'components/Navigation';
import { AwsDataClassification } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/Aws/AwsDataClassification';
import { RepositoryDataClassification } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/CodeRepository/RepositoryDataClassification';
import { SnowflakeDataClassification } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/Snowflake/SnowflakeDataClassification';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

export const DataClassificationWizard: FC = () => {
  const dispatch = useDispatch();
  const { service, accountType } = useSelector(selectDataClassification);
  const {
    onCloseForm,
    currentRecord,
    actionType,
  } = useDataConfigurationContext();

  const handleRedirect = () => {
    onCloseForm();
    dispatch(actions.resetService());
  };

  const handleSubmit = data => {
    const quantifier = service.quantifier;
    /*if quantifier === all and gitlab, github, bitbucket is selected
     * send repo name in data_sources
     * for snowflake, send database names in data_sources and
     * tables are sent under data_objects*/

    const data_classifications = [
      {
        ...omit(service, 'quantifier'),
        ...(accountType === CloudProviders.Snowflake
          ? { data_sources: map(data?.databases, o => o?.value) }
          : accountType !== CloudProviders.Aws && quantifier === 'all'
          ? {
              data_sources: [data?.repository?.value],
            }
          : {}),
      },
    ];

    /*for snowflake, and code repositories (github, gitlab, bitbucket, etc.)
     * we need to select scanner as well*/
    const scanner =
      accountType !== CloudProviders.Aws
        ? {
            scanner_ids: [data?.scanner?.scanner_id?.value],
            scanner_environment_id:
              data?.scanner?.scanner_environment_id?.value,
            scanner_account_id: data?.scanner?.scanner_account_id?.value,
          }
        : {};

    const payload = {
      name: data?.name,
      environment_id: data?.environment_id?.value,
      data_classifications,
      ...scanner,
    };

    if (actionType === 'Update') {
      dispatch(
        actions.updateDataClassification({
          q: {
            dataClassificationGroupUpdate: {
              ...payload,
            },
            dataClassificationGroupId: currentRecord?.id!,
          },
          onSuccess: () => handleRedirect(),
        }),
      );
    } else {
      /*for aws we are selecting multiple accounts
       * but for others like snowflake only one account is selected,
       * for github, gitlab, bitbucket one repo is selected */
      dispatch(
        actions.enableDataClassification({
          q: {
            dataClassificationGroupCreate: {
              ...payload,
              service_ids:
                accountType === CloudProviders.Aws
                  ? data.account_ids?.map(o => o.value)
                  : [data.account_ids?.value],
            },
          },
          onSuccess: () => handleRedirect(),
        }),
      );
    }
  };

  const menuItems = [
    {
      title: 'AWS',
      key: CloudProviders.Aws,
      component: (
        <AwsDataClassification
          handleSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      ),
      icon: <AwsIcon />,
      isDisabled: accountType !== CloudProviders.Aws && actionType === 'Update',
    },
    {
      title: 'Snowflake',
      key: CloudProviders.Snowflake,
      component: (
        <SnowflakeDataClassification
          handleSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      ),
      icon: <SnowflakeIcon />,
      isDisabled:
        accountType !== CloudProviders.Snowflake && actionType === 'Update',
    },
    {
      title: 'GitLab',
      //using different key so that in select data sources we can directly store this in service
      key: SupportedServices.GitLabBranch,
      component: (
        <RepositoryDataClassification
          handleSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      ),
      icon: <GitLabIcon />,
      isDisabled:
        accountType !== SupportedServices.GitLabBranch &&
        actionType === 'Update',
    },
    {
      title: 'GitHub',
      //using different key so that in select data sources we can directly store this in service
      key: SupportedServices.GitHubBranch,
      component: (
        <RepositoryDataClassification
          handleSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      ),
      icon: <GithubIcon />,
      isDisabled:
        accountType !== SupportedServices.GitHubBranch &&
        actionType === 'Update',
    },
    {
      title: 'Bitbucket',
      //using different key so that in select data sources we can directly store this in service
      key: SupportedServices.BitBucketBranch,
      component: (
        <RepositoryDataClassification
          handleSubmit={handleSubmit}
          handleRedirect={handleRedirect}
        />
      ),
      icon: <BitbucketIcon />,
      isDisabled:
        accountType !== SupportedServices.BitBucketBranch &&
        actionType === 'Update',
    },
  ];

  return (
    <SideNav
      menuItems={menuItems}
      showContentHeader={false}
      current={accountType}
      onChange={val => {
        dispatch(actions.updateAccountType(val as string));
      }}
      navHeader={
        <WithResourceIcon
          resourceType={IconTypes.DataScan}
          bgColor="primary"
          spacing={3}
        >
          <Box>Data Sources</Box>
        </WithResourceIcon>
      }
    />
  );
};
