import React, { FC, ReactNode, useEffect } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { SortBy } from '@ariksa/notification/api';
import { Center, HStack, Stack, Text, Box } from '@chakra-ui/react';
import { useAlertService } from 'services/Notification/useAlerts';
import { customTheme } from 'theme';

import { renderSeverityBar, renderTime } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { BellIcon, JiraIcon, SlackIcon, SnoozeIcon } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { toTitleCase } from 'utils/string';

interface IAlertActivity {
  alert: AlertResponse;
}

export const AlertActivity: FC<IAlertActivity> = props => {
  const { alert } = props;
  const { alertTimeline } = useAlertService();

  useEffect(() => {
    alertTimeline.sync({ alertId: alert.uuid!, sortBy: SortBy.Desc });
  }, [alert.uuid, alertTimeline]);

  const getIconBg = item => {
    if (item.action === 'resolved') return customTheme.colors.green['300'];
    else if (!item.severity) return 'primary';
    else return item.severity?.toLowerCase();
  };

  const getActionDetails = item => {
    let title = item?.action;
    let lowerComponent: ReactNode = '';
    let category = '';
    let by = item?.created_by || '-';
    switch (item?.action) {
      case 'created':
        title = 'Alert Created';
        lowerComponent = renderSeverityBar({
          value: item?.severity,
          reversed: true,
        });
        category = toTitleCase(item?.category);
        break;
      case 'rediscovered':
        title = 'Alert Rediscovered';
        break;
      case 'resolved':
        title = 'Alert Resolved';
        break;
      case 'snoozed':
        title = 'Alert Snoozed';
        break;
      case 'unsnoozed':
        title = 'Alert Snooze Removed';
        break;
      case 'jira':
        title = 'Ticket Created';
        lowerComponent = (
          <HStack>
            <Box>JIRA:</Box>
            <Box>{item?.ticket_id}</Box>
          </HStack>
        );
        break;
      case 'slack':
        title = 'Notification Sent';
        lowerComponent = (
          <HStack>
            <Box>Slack:</Box>
            <Box>{item?.channel_name}</Box>
          </HStack>
        );
        break;
    }
    return { title, lowerComponent, category, by };
  };

  const getIcon = action => {
    switch (action) {
      case 'slack':
        return <SlackIcon fill={'white'} p={0.5} />;
      case 'jira':
        return <JiraIcon fill={'white'} p={0.5} />;
      case 'snoozed':
        return <SnoozeIcon p={0.5} />;
      default:
        return <BellIcon />;
    }
  };

  const renderItem = (el, index, len) => {
    const action = getActionDetails(el);
    return (
      <>
        <Center pos={'relative'}>
          {len !== 1 && index !== len - 1 && (
            <Box
              w={'1px'}
              h={'60px'}
              pos={'absolute'}
              bg={'gray.100'}
              left={'50%'}
              top={'50%'}
              zIndex={-1}
            />
          )}

          {len !== 1 && index !== 0 && (
            <Box
              w={'1px'}
              h={'60px'}
              pos={'absolute'}
              bg={'gray.100'}
              left={'50%'}
              bottom={'50%'}
              zIndex={-1}
            />
          )}
          <AriksaIcon
            icon={getIcon(el?.action)}
            size={'small'}
            bg={getIconBg(el)}
            useCustomColor
            circle
            style={{ w: 8, h: 8, p: 1 }}
          />
        </Center>
        <Stack
          w={'full'}
          border={'1px'}
          borderColor={'gray.100'}
          borderRadius={6}
          p={3}
        >
          <HStack w={'full'} justify={'space-between'} align="start">
            <Stack>
              <Text fontWeight={600}>{action.title}</Text>
              <Box>{action.lowerComponent}</Box>
            </Stack>
            <Stack>
              <Text>{renderTime({ value: el.created_at })}</Text>
              {action?.category && (
                <Text w={48}>Category: {action?.category}</Text>
              )}
              {!action?.category && <Text w={48}>By: {action?.by}</Text>}
            </Stack>
          </HStack>
        </Stack>
      </>
    );
  };

  return (
    <Stack px={3} spacing={10}>
      <CustomSpinner loading={alertTimeline.isLoading} />
      {alertTimeline.data?.map((el, i) => {
        return (
          <HStack w={'600px'} spacing={12}>
            {renderItem(el, i, alertTimeline.data?.length ?? 0)}
          </HStack>
        );
      })}
    </Stack>
  );
};
