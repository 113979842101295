import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Clients } from '@ariksa/notification';
import { Box, Center, HStack } from '@chakra-ui/react';

import { getIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';

export const getResourceIcon = type => (
  <Center w="full" h="full" color="primary">
    {getIcon(type)}
  </Center>
);

export const getLabel = type => {
  switch (type) {
    case IconTypes.Jira:
      return 'Atlassian JIRA';
    case IconTypes.Sms:
      return 'SMS';
    case IconTypes.Slack:
      return 'Slack';
    case IconTypes.Teams:
      return 'Microsoft Teams';
    case IconTypes.Email:
      return 'Email Group';
    case IconTypes.Servicenow:
      return 'Servicenow';
    case IconTypes.Sso:
      return 'Single Sign-On';
    case IconTypes.AzureAd:
      return 'Azure Active Directory';
    case IconTypes.Okta:
      return 'Okta';
    case IconTypes.Saas:
      return 'SaaS';
    case IconTypes.Snowflake:
      return 'Snowflake';
    case IconTypes.IdentityAndAccess:
      return 'Identity & Access';
    case IconTypes.SIEM:
      return 'SIEM';
    case Clients.Elasticsearch:
      return 'Elastic for SIEM';
    case CloudProviders.Gitlab:
      return 'GitLab';
    case CloudProviders.Github:
      return 'Github';
    case CloudProviders.Bitbucket:
      return 'Atlassian BitBucket';
  }
  return type;
};

export const renderNavTitle = type => (
  <HStack justify="space-between" w="full" h="full">
    <Box>{getLabel(type)}</Box>
    <Center boxSize={5}>{getResourceIcon(type)}</Center>
  </HStack>
);
