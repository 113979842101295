import React, { FC, useEffect, useState } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { Box, Accordion, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { CustomIconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import {
  renderHighPrivilegesStackedCell,
  renderTags,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useRedirectToGraph } from 'containers/ActiveCloudResource/hooks/useRedirectToGraph';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

export const IAMUserData: FC = () => {
  const { canAccessSummary, resourceType } = useSelector(selectActiveResource);
  const { canAccessData } = canAccessSummary;
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const { redirectToGraph } = useRedirectToGraph();

  const [internal, setInternal] = useState<Dict<any>[]>([]);
  const [external, setExternal] = useState<Dict<any>[]>([]);
  const [isSnowflake, setIsSnowflake] = useState(false);

  useEffect(() => {
    setIsSnowflake(resourceType?.startsWith('Snowflake'));
  }, [resourceType]);

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.CanAccessData);
  }, [getCanAccessSummary]);

  useEffect(() => {
    setInternal(filter(canAccessData.data, o => !o?.external));
    setExternal(filter(canAccessData.data, o => o?.external));
  }, [canAccessData.data]);

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: 'destination_name',
    render: ({ row, value }) => (
      <WithResourceIcon resourceType={row?.destination_native_name}>
        <StackedCell
          upper={value}
          lower={
            getCloudNativeName(row?.destination_native_name) +
            ' / ' +
            getCloudAgnosticName(row?.destination_agnostic_name)
          }
        />
      </WithResourceIcon>
    ),
  };

  const mechanism = {
    header: 'Mechanism',
    align: 'left',
    accessor: 'permission_entity_name',
    render: ({ row, value }) => (
      <Stack>
        <Box>{row?.permission_entity_natives_names?.[0]}</Box>

        <HStack>
          <Box>{row?.permission_entity_agnostic_name?.[1]}</Box>
          {row?.permission_entity_agnostic_name?.length - 2 && (
            <Box>{row?.permission_entity_agnostic_name?.length - 2}</Box>
          )}
        </HStack>
      </Stack>
    ),
  };

  const highPrivileges = {
    header: 'High Privileges',
    accessor: 'crud_level_counts_high',
    render: renderHighPrivilegesStackedCell,
  };

  const renderRestrictedData = value => (
    <StackedCell
      upper={!!value?.length ? 'Yes' : 'No'}
      lower={
        <HStack>
          {/*<Box w={3} bg="low">
            {getIcon(IconTypes.ExclamationTriangle)}
          </Box>*/}
          <Box>
            {value?.length} type{value?.length <= 1 ? '' : 's'} of data
          </Box>
        </HStack>
      }
      showLowerTooltip={false}
      showUpperTooltip={false}
    />
  );

  const columns = isInternal => [
    name,
    ...(isInternal
      ? [
          {
            header: 'Special Data',
            render: ({ row }) => {
              const len = row?.data_type_tags?.length ?? 0;
              return !!len ? (
                <CustomTooltip
                  label={
                    <Stack minW={16} spacing={1}>
                      {map(row?.data_type_tags, o => (
                        <Box>{o}</Box>
                      ))}
                    </Stack>
                  }
                >
                  {renderRestrictedData(row?.data_type_tags)}
                </CustomTooltip>
              ) : (
                renderRestrictedData(row?.data_type_tags)
              );
            },
            align: 'left',
            styles: { width: '60px', cell: { maxWidth: '60px' } },
          },
        ]
      : []),
    ...(!isSnowflake ? [mechanism] : []),
    ...(!isSnowflake && !isInternal
      ? [
          {
            header: 'Account',
            accessor: 'permission_entity_account',
            render: ({ value }) => <CloudAccountName accountId={value} />,
          },
        ]
      : []),
    ...(isSnowflake
      ? [
          {
            header: 'Owner',
            align: 'left',
            render: ({ row }) =>
              row?.destination_owner ?? row?.permission_entity_owner ?? '-',
          },
          {
            header: 'Access Using',
            render: ({ row }) => (
              <StackedCell
                upper={row?.permission_entity_name}
                lower={row?.permission_entity_agnostic_name}
              />
            ),
            align: 'left',
          },
        ]
      : []),
    highPrivileges,
    ...(!isSnowflake
      ? [
          {
            header: '# Permissions',
            accessor: 'total_permission_count',
          },
        ]
      : []),
    ...(isSnowflake
      ? [
          {
            header: '# of Privileges',
            accessor: 'total_permission_count',
          },
          {
            header: 'Tags',
            accessor: 'destination_tags',
            align: 'left',
            render: ({ value }) => (value?.length ? renderTags(value) : '-'),
          },
        ]
      : []),
  ];

  return (
    <Accordion allowMultiple>
      <MetaAccordionItem
        iconType={CustomIconTypes.DataScan}
        label="INTERNAL DATA SOURCES THAT THIS IDENTITY CAN MANAGE"
        totalCount={internal?.length}
        isLoading={canAccessData.isLoading}
        panel={
          <Table
            columns={columns(true)}
            data={internal}
            isLoading={canAccessData.isLoading}
          />
        }
        iconBGColor="primary"
        onShowGraph={() => redirectToGraph('access', MetadataTabs.Data)}
      />
      <MetaAccordionItem
        iconType={CustomIconTypes.DataScan}
        label="EXTERNAL DATA SOURCES THAT THIS IDENTITY CAN MANAGE"
        totalCount={external?.length}
        isLoading={canAccessData.isLoading}
        panel={
          <Table
            columns={columns(false)}
            data={external}
            isLoading={canAccessData.isLoading}
          />
        }
        iconBGColor="low"
        onShowGraph={() => redirectToGraph('identity', MetadataTabs.Data)}
      />
    </Accordion>
  );
};
