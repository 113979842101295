import React, { FC, useEffect } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Stack,
  HStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { find, isEmpty, map } from 'lodash';
import { useRemediationService } from 'services/ComplianceService/Remediation';
import { useComplianceRulesService } from 'services/ComplianceService/Rules';
import { useAlertLogsService } from 'services/Notification/useAlertLogs';
import { customTheme } from 'theme';

import {
  getIcon,
  PageHeaderWithIcon,
  renderTime,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { toTitleCase } from 'utils/string';

interface Props {
  row: Record<string, any>;
  isExpanded: boolean;
}

export const AlertsTableSubComponent: FC<Props> = props => {
  const { alertStatus } = useAlertLogsService();
  const { compliance } = useComplianceRulesService();
  const { remediationCommands } = useRemediationService();
  const { environmentMapping } = useEnvironmentOptions();

  const { row, isExpanded } = props;

  useEffect(() => {
    if (isExpanded) {
      alertStatus.sync({ alertId: [row.uuid] });
    }
  }, [isExpanded, alertStatus, row]);

  useEffect(() => {
    if (isExpanded) {
      compliance.sync({
        getRuleComplianceStandardRequest: { rules: [row.alert_rule_id] },
      });
    }
  }, [isExpanded, compliance, row]);

  useEffect(() => {
    if (isExpanded) {
      remediationCommands.sync({
        resourceType: row.resource,
        account: row.account_id,
        checkName: row.alert_rule_id,
        resourceUuid: row.entity_uuid,
      });
    }
  }, [isExpanded, remediationCommands, row]);

  const renderTicket = () => {
    const data = alertStatus.data?.[0]?.logs;
    const jira = find(data, o => o.action === 'jira')?.ticket_id ?? undefined;
    const serviceNow =
      find(data, o => o.action === 'servicenow')?.ticket_id ?? undefined;
    const renderItem = (item, label) => (
      <HStack>
        <Box boxSize={5}>{getIcon(label)}</Box>
        <Box>{item}</Box>
      </HStack>
    );
    return alertStatus.isLoading ? (
      <Box>
        <CustomSpinner />
      </Box>
    ) : (
      <Stack>
        {jira && renderItem(jira, 'jira')}
        {serviceNow && renderItem(serviceNow, 'servicenow')}
        {!jira && !serviceNow && <Box>None</Box>}
      </Stack>
    );
  };

  return (
    <Stack>
      <PageHeaderWithIcon label="ADDITIONAL DETAILS" fontSize="sm" />
      <Box
        borderBottom="1px solid"
        borderColor={customTheme.colors.gray['100']}
      />
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={1}>
          <DetailsField label="Cloud ID" value={row?.entity_id} />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Policy blueprints"
            value={
              <Wrap>
                {map(row?.blueprints, (o, index: number) => (
                  <WrapItem color="primary">
                    {o.blueprint_name}
                    {index < row.blueprints?.length - 1 ? ',' : ''}
                  </WrapItem>
                ))}
              </Wrap>
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Created"
            value={renderTime({ value: row.created_at })}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Environment"
            value={environmentMapping?.[row.environment_id]?.name}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Remediation commands"
            value={
              remediationCommands.isLoading ? (
                <CustomSpinner />
              ) : remediationCommands.data?.length ? (
                'Yes'
              ) : (
                'No'
              )
            }
          />
        </GridItem>
        {/*<GridItem colSpan={1}>
          <DetailsField label="Total alerts on resource" />
        </GridItem>*/}
        <GridItem colSpan={1}>
          <DetailsField
            label="Due by"
            value={
              row.due_date
                ? renderTime({
                    value: dayjs(row.due_date).utc().local(),
                  })
                : 'None'
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Is production environment"
            value={
              environmentMapping?.[row.environment_id]?.is_business_critical
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField label="Policy ID" value={row.alert_rule_id} />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Alert status"
            value={
              row.snooze
                ? 'Snoozed'
                : row.status === 'ACTIVE'
                ? 'Open'
                : toTitleCase(row.status)
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField label="Tickets" value={renderTicket()} />
        </GridItem>
        <GridItem colSpan={1}>
          <DetailsField
            label="Compliance standards"
            value={
              compliance.isLoading ? (
                <CustomSpinner />
              ) : isEmpty(compliance.data) ? (
                'None'
              ) : (
                <HStack spacing={1}>
                  {map(compliance.data?.[row.alert_rule_id], o => (
                    <CustomTooltip label={o}>
                      <Box boxSize={5}>{getIcon(o)}</Box>
                    </CustomTooltip>
                  ))}
                </HStack>
              )
            }
          />
        </GridItem>
        {row?.status === 'RESOLVED' && (
          <GridItem colSpan={1}>
            <DetailsField label="Reason" value={row?.resolved_by} />
          </GridItem>
        )}
      </Grid>
    </Stack>
  );
};
