import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CustomerJourneyBulbIcon, IconTypes } from 'components/Icons';
import { StopwatchIcon } from 'components/Icons/ReactCustomIcons/StopwatchIcon';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import { VerticalCountCard } from 'containers/Dashboard/utils/VerticalCountCard';

export const JITAccessRequests: React.FC = () => {
  const { jitSummary } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();

  const items = [
    {
      label: 'JIT Accounts',
      count: jitSummary.data?.jit_enabled_count,
      iconColor: customTheme.colors.gray['300'],
      iconType: IconTypes.Account,
      onClick: () => navigate('/setup/jit?tab=jit-accounts'),
      message: jitSummary.data?.jit_enabled_count + ' Enabled',
      messageBgColor: customTheme.colors.green['100'],
    },
    {
      label: 'JIT Requests',
      count: jitSummary.data?.requests_count,
      iconColor: 'orange',
      iconType: IconTypes.Clock,
      onClick: () => navigate('/setup/jit?tab=jit-access-request'),
      message:
        formatNumber(jitSummary?.data?.approved_requests_count) +
        ' Approved, ' +
        formatNumber(jitSummary.data?.denied_requests_count) +
        ' Denied',
      messageBgColor: colorOpacity(customTheme.colors.primary, 0.2),
    },
    /*{
      label: 'Approved',
      count: jitSummary.data?.approved_requests_count,
      borderColor: 'green.300',
      iconBgColor: colorOpacity(customTheme.colors.green['300'], 0.1),
      iconType: IconTypes.CheckmarkCircle,
      onClick: () => navigate('/setup/jit?tab=jit-access-request'),
    },*/
  ];

  return (
    <DashboardOverviewCard
      label="Just-in-Time (JiT) Access Management"
      icon={<StopwatchIcon p={0} />}
      iconBgColor="primary"
      //jitEnabled={!!jitSummary.data?.jit_enabled_count}
      rightHeader={
        <Box w="full" pr={2}>
          {renderCloudIcons(jitSummary.data?.cloud_providers)}
        </Box>
      }
      content={
        !jitSummary.data?.jit_enabled_count ? (
          <HStack spacing={4} align="flex-start" fontSize="md" justify="center">
            <Box boxSize={12} color="orange">
              <Center>
                <CustomerJourneyBulbIcon />
              </Center>
            </Box>
            <Box
              maxW="60%"
              color="primary"
              onClick={() => navigate('/setup/jit?tab=jit-accounts')}
              cursor="pointer"
            >
              Setup Just-in-Time (JiT) permissioning for users to minimize risks
              from long-standing permissions
            </Box>
          </HStack>
        ) : (
          <HStack w="full" h="full" spacing={10} p={2}>
            {map(items, o => (
              <VerticalCountCard {...o} />
            ))}
          </HStack>
        )
      }
      isLoading={jitSummary.isLoading}
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Just-in-time (JIT) access allows you to provide users and machines
              with permissions only for a limited duration when they need them
              (by cloud account)
            </Box>
            <Box>
              Reduce risks due to excess privileges from long-standing trusted
              entities and permissions using JIT
            </Box>
          </Stack>
        ),
        header: 'More about JIT access…',
        footerText: 'Ok',
      }}
    />
  );
};
