import React, { useCallback, useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { GraphScale } from 'components/Visualization/PixiGraph/common/GraphScale';
import { useGraphDnd } from 'components/Visualization/PixiGraph/hooks/useGraphDnd';
import { useGraphMenuTooltip } from 'components/Visualization/PixiGraph/hooks/useMenuTooltip';
import { usePostureMap } from 'components/Visualization/PixiGraph/maps/PostureMap/hooks/usePostureMap';
import { PostureGraphMenu } from 'containers/Visibility/SecurityGraphNext/Components/Posture/GraphMenuTooltip';
import { useDownloadMap } from 'containers/Visibility/SecurityGraphNext/hooks/useDownloadMap';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

interface PostureGraphProps {}

export const PostureGraph = (props: PostureGraphProps) => {
  const { showVulnerability } = useSelector(selectSecurityGraphNext);
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { app, graph, renderer } = usePostureMap({
    ref,
  });
  const graphMenu = useGraphMenuTooltip({ app, graph, renderer, ref });
  const { listeners } = useGraphDnd({ ref, app });
  useDownloadMap(app, 'posture-map');

  const handleToggleVulnerability = useCallback(() => {
    dispatch(actions.toggleVulnerability());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.resetShowVulnerability());
  }, [dispatch]);

  return (
    <Box
      h={'full'}
      w={'full'}
      pos={'absolute'}
      onContextMenu={e => e.preventDefault()}
    >
      <Box
        className="graph-canvas-v1"
        ref={ref}
        {...listeners}
        {...graphMenu.listeners}
      ></Box>
      <PostureGraphMenu app={app} graph={graph} renderer={renderer} />
      <GraphScale
        app={app}
        graph={graph}
        toggleVulnerability={handleToggleVulnerability}
        showVulnerability={showVulnerability}
      />
    </Box>
  );
};
