import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DatabaseIcon } from 'components/Icons';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Databases: React.FC = () => {
  const { dataSecurity } = useSelector(selectDataSecurityOverview);
  const { analyzedDataSourcesCount, summaryOfAllDataSources } = useSelector(
    selectDashboard,
  );
  const { dbCount } = dataSecurity;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Databases"
      icon={<DatabaseIcon />}
      onClick={() =>
        navigate('/inventory/summary?tab=Data_' + Resources.Database)
      }
      change={{
        isLoading: dbCount.isLoading,
        first: dbCount.data?.previous_count,
        last: dbCount.data?.current_count,
        percentChange: dbCount.data?.percentage_change,
      }}
      cloudProviders={
        summaryOfAllDataSources.data.resource_type_providers?.Databases ?? []
      }
      count={summaryOfAllDataSources.data.resource_type_counts?.Databases}
      isLoading={summaryOfAllDataSources.isLoading}
      info={{
        isLoading: analyzedDataSourcesCount.isLoading,
        message: !!analyzedDataSourcesCount.data?.DB ? (
          <HStack>
            <Box>Analyzing</Box>
            <Box>
              {analyzedDataSourcesCount.isLoading ? (
                <CustomSpinner />
              ) : (
                analyzedDataSourcesCount.data?.DB
              )}
            </Box>
          </HStack>
        ) : (
          'No Resources Analyzed'
        ),
        enabled: !!analyzedDataSourcesCount.data?.Buckets,
      }}
      labelTooltip={formatTooltip({
        header: 'Databases',
        content:
          'Structured, semi-structured databases and data warehouses in public clouds',
        width: 48,
      })}
    />
  );
};
