import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { useInjector } from 'utils/inject';

import { AddRemediation } from './Components/AddRemediation';
import { EditRemediation } from './Components/EditRemediation';
import { RemediationSideNav } from './Components/RemediationTable/RemediationSideNav';
import { remediationSaga } from './saga';
import { sliceKey, reducer } from './slice';

export const Remediation = memo(() => {
  useInjector(sliceKey, reducer, remediationSaga);
  return (
    <Routes>
      <Route element={<FilterRouter path="/policy-hub/remediation/add" />}>
        <Route path="add" element={<AddRemediation />} />
      </Route>
      <Route element={<FilterRouter path="/policy-hub/remediation/edit/:id" />}>
        <Route path="edit/:id" element={<EditRemediation />} />
      </Route>
      <Route element={<FilterRouter path="/policy-hub/remediation" />}>
        <Route index element={<RemediationSideNav />} />
      </Route>
    </Routes>
  );
});
