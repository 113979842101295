import { each } from 'lodash';

import { traverseBfsByDepth } from 'components/Visualization/Graphs/utils/graph';
import { ContextFilterItemEntry } from 'containers/Visibility/Components/Filter/ContextFilterItem';
import { ResourceFilterItemEntry } from 'containers/Visibility/Components/Filter/ResourceFilterItem';

export const filterNodesByResource = (
  nodes: any[],
  edges: Record<string, Set<string>>,
  filter: ResourceFilterItemEntry,
) => {
  const resourceIdToIdentity = {};
  const nodeIds = nodes.map(n => {
    resourceIdToIdentity[n.resource_id] = n.identity;
    resourceIdToIdentity[n.uuid] = n.identity;
    return n.identity;
  });

  const parentIds = new Map<string, string>();
  each(edges, (v, k) => {
    v.forEach(p => {
      parentIds.set(p, k);
    });
  });

  const visible: Set<string> = new Set();

  // take all children
  const { visited } = traverseBfsByDepth(
    resourceIdToIdentity[filter.resourceId] ?? filter.resourceId,
    nodeIds,
    edges,
    [],
  );

  visited.forEach(id => visible.add(id));

  // take all parents
  let currentId = resourceIdToIdentity[filter.resourceId] ?? filter.resourceId;

  let seen: Set<string> = new Set();
  while (currentId) {
    visible.add(currentId);
    currentId = parentIds.get(currentId);
    if (seen.has(currentId)) {
      console.error('loop in the graph is detected');
      break;
    }
    seen.add(currentId);
  }

  return nodes.filter(n => visible.has(n.identity));
};

export const filerGraphByContextFilters = (
  nodes: any[],
  backwardEdges: Record<string, Set<string>>,
  forwardEdges: Record<string, Set<string>>,
  filters: ContextFilterItemEntry[],
) => {
  const visible = new Set();
  if (filters.length === 0) {
    each(nodes, n => visible.add(n.identity));
  } else {
    filters.forEach(filter => {
      const filteredNodes = filterNodeByContext(nodes, forwardEdges, filter);
      each(filteredNodes, n => visible.add(n.identity));
    });
    filters.forEach(filter => {
      const filteredNodes = filterNodeByContext(nodes, backwardEdges, filter);
      each(filteredNodes, n => visible.add(n.identity));
    });
  }

  const hideIds = nodes.map(n => n.identity).filter(nid => !visible.has(nid));

  return hideIds;
};

export const filterNodeByContext = (
  nodes: any[],
  edges: Record<string, Set<string>>,
  filter: ContextFilterItemEntry,
) => {
  const visible: Set<string> = new Set();
  const resourceIdToIdentity = {};
  const nodeIds = nodes.map(n => {
    resourceIdToIdentity[n.resource_id] = n.identity;
    return n.identity;
  });

  const sourceIds: string[] = [];
  nodes.forEach(n => {
    if (nodeHasContext(n, filter)) {
      sourceIds.push(n.identity);
    }
  });

  sourceIds.forEach(sid => {
    const { visited } = traverseBfsByDepth(
      resourceIdToIdentity[sid] ?? sid,
      nodeIds,
      edges,
      [],
    );
    visited.forEach(id => visible.add(id));
  });

  return nodes.filter(n => visible.has(n.identity));
};

export const nodeHasContext = (
  node,
  filter: Omit<ContextFilterItemEntry, 'key'>,
) => {
  if (node.native_name !== filter.resourceType) return false;

  return node.contexts?.some(nc => {
    return filter.context?.some(
      o => nc.name === o?.name && nc.values?.[0] === o?.value,
    );
  });
};
