/**
 *
 * Slack Table
 *
 */

import React, { FC, useEffect } from 'react';

import { Clients } from '@ariksa/notification';
import { useDispatch, useSelector } from 'react-redux';

import { renderTime } from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DeleteClientModal } from 'containers/Setup/Integrations/DeleteClientModal';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions as clientActions } from 'containers/Setup/Integrations/slice';

export const SlackTable: FC = () => {
  const { clients, client } = useSelector(selectIntegrations);
  const dispatch = useDispatch();
  const {
    clientActionsColumn,
    isOpenDeleteClientModal,
  } = useIntegrationsContext();

  useEffect(() => {
    dispatch(
      clientActions.loadClients({
        clientName: Clients.Slack,
      }),
    );
  }, [dispatch]);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      align: 'left',
    },
    {
      header: 'Workspace',
      accessor: 'slack.workspace',
      align: 'left',
    },
    {
      header: 'Channel',
      accessor: 'slack.channel_name',
      align: 'left',
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Created On',
      accessor: 'created_at',
      render: renderTime,
      align: 'left',
    },
    clientActionsColumn,
  ];
  return (
    <>
      <Table
        columns={columns}
        data={clients.slack}
        isLoading={clients.loading || client.isLoading}
      />
      {isOpenDeleteClientModal && <DeleteClientModal />}
    </>
  );
};
