import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import { Login } from 'containers/Authentication/Login';
import { LoginOrganization } from 'containers/Authentication/LoginOrganization';

interface IAuthentication {}

export const Authentication: FC<IAuthentication> = props => {
  console.log('authentication');
  return (
    <Routes>
      <Route path={'login'} element={<Login />} />
      <Route index element={<LoginOrganization />} />
    </Routes>
  );
};
