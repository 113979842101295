import React from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

export const renderTableHeaderWithLoader = (label, isLoading) => (
  <HStack spacing={1}>
    <Box>{label}</Box>
    {isLoading && <CustomSpinner loading={isLoading} />}
  </HStack>
);
