import { useCallback } from 'react';

import { SearchResponseLevel } from '@ariksa/inventory-core';
import { useNavigate } from 'react-router-dom';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const useRedirectToGraph = () => {
  const {
    resourceType,
    resourceUuid,
    resourceId,
    resourceInsight,
  } = useActiveResourceContext();
  const navigate = useNavigate();

  const redirectToGraph = useCallback(
    (
      mapType: 'access' | 'posture' | 'identity',
      sourceTab?: string,
      level: SearchResponseLevel = SearchResponseLevel.Compact,
    ) => {
      const url = securityGraphRedirectUrl({
        resource_id: resourceId,
        resource_type: resourceType,
        source_resource_uuid: resourceUuid,
        account_id: resourceInsight.data?.account ?? '',
        map_type: mapType,
        source_tab: sourceTab ?? '',
        response_level: level,
      });

      navigate(url);
    },
    [
      history,
      resourceType,
      resourceId,
      resourceUuid,
      resourceInsight.data?.account,
    ],
  );

  return {
    redirectToGraph,
  };
};
