import React from 'react';

import { Accordion, Stack } from '@chakra-ui/react';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { GraphIcon } from 'components/Icons';
import { Details } from 'containers/Visibility/SecurityGraphNext/Components/Investigate/Details';
import { Risks } from 'containers/Visibility/SecurityGraphNext/Components/Investigate/Risks';
import { SelectEntity } from 'containers/Visibility/SecurityGraphNext/Components/Investigate/SelectEntity';

export const InvestigateGraphDetails = () => {
  return (
    <Card styles={{ card: { p: 2 } }}>
      <Stack w="full" spacing={3}>
        <Stack>
          <PageHeaderWithIcon
            label="Security Graph"
            icon={<GraphIcon />}
            reversed
            fontSize="sm"
            fontColor="primary"
            spacing={2}
          />
        </Stack>
        <Accordion allowMultiple defaultIndex={[0, 1, 2, 4]}>
          <Stack spacing={3}>
            <SelectEntity />
            <Details />
            {/*<Risks />*/}
          </Stack>
        </Accordion>
      </Stack>
    </Card>
  );
};
