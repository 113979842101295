import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RemoveIcon = props => (
  <Icon viewBox="0 0 40 39" color="inherit" h="full" w="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="304" y="337" width="40" height="39" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="305" y="338" width="38" height="38" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="305" y="338" width="38" height="38" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="305" y="338" width="38" height="38" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-304 -337)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M323.994 341.958C315.691 341.958 308.96 348.689 308.96 356.992 308.96 365.295 315.69 372.026 323.993 372.026 332.296 372.026 339.027 365.295 339.027 356.992 339.027 356.992 339.027 356.991 339.027 356.991 339.03 348.692 332.305 341.961 324.006 341.958 324.002 341.958 323.998 341.958 323.994 341.958ZM330.711 361.817 328.819 363.709 323.994 358.884 319.171 363.709 317.278 361.816 322.1 356.991 317.276 352.166 319.171 350.274 323.994 355.1 328.819 350.274 330.711 352.166 325.887 356.991Z"
              fill="#C00000"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
