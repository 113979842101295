import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AllowedActions,
  AllowedServices,
  AttachedGroups,
  AttachedToRoles,
  Conditional,
  DeniedActions,
  DeniedServices,
  AttachedUsers,
  UpdatedAt,
  WildcardForActions,
  WildcardForResources,
  DefaultVersion,
} from '../../Components/MetadataField/MetaGridFields';

export const IAMPolicyMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {UpdatedAt(resource)}
        {DefaultVersion(resource)}
        {AllowedActions(insight)}
        {DeniedActions(insight)}
        {AttachedUsers(resource)}
        {AttachedGroups(resource)}
        {AttachedToRoles(resource)}
        {AllowedServices(insight)}
        {DeniedServices(insight)}
        {Conditional(insight)}
        {WildcardForResources(insight)}
        {WildcardForActions(insight)}
      </Grid>
    </Stack>
  );
};
