import { each, uniqBy } from 'lodash';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';

export const forEachPathEdge = (paths, filter = (a, b) => false, cb) => {
  paths.forEach((p, i) => {
    let source = null;
    p.path.forEach((nodeId, j) => {
      if (j > 0 && source) {
        const dest = p.path[j];
        if (filter(source, dest)) return;
        cb(source, dest, i);
      }
      source = nodeId;
    });
  });
};

export const pathToEdges = (paths, filter = (a, b) => false) => {
  const edges: any[] = [];
  // console.log('generating edges', paths.length);

  forEachPathEdge(paths, filter, (source, dest, i) => {
    const key = `${i}:${source}:${dest}`;
    const gid = `${source}:${dest}`;
    edges.push({
      pathId: i,
      key,
      gid,
      source: source,
      dest: dest,
    });
  });

  return edges;
};

export const uniqEdges = (edges: AGraphEdge[]) => {
  return uniqBy(edges, e => `${e.source}#${e.dest}`);
};

export const nodeToPathIds = (paths, filter = (a, b) => false) => {
  const nodeToPathIds: Record<any, Set<number>> = {};

  forEachPathEdge(paths, filter, (source, dest, i) => {
    // node with `source` and `dest` id falls in `i` path
    nodeToPathIds[source] = nodeToPathIds[source] ?? new Set();
    nodeToPathIds[source].add(i);
    nodeToPathIds[dest] = nodeToPathIds[dest] ?? new Set();
    nodeToPathIds[dest].add(i);
  });

  return nodeToPathIds;
};

export const pathToDirectedEdges = (paths: { path: any[] }[]) => {
  const edges: Record<string, Set<string>> = {};

  paths.forEach((p: { path: any[] }) => {
    let source: string = '';
    p.path.forEach((nodeId: string, j: number) => {
      if (j > 0 && source) {
        edges[source] = edges[source] ?? new Set();
        const dest = p.path[j];
        edges[source].add(dest);
      }
      source = nodeId;
    });
  });

  return edges;
};

export const relationshipToDirectedEdges = (relationships: any[] = []) => {
  const edges: Record<string, Set<string>> = {};

  relationships.forEach(r => {
    edges[r.source] = edges[r.source] ?? new Set();
    edges[r.source].add(r.dest);
  });

  return edges;
};

export const pathToUndirectedEdges = paths => {
  const edges: Record<string, Set<string>> = {};

  paths.forEach((p, i) => {
    let source = null;
    p.path.forEach((nodeId, j) => {
      edges[nodeId] = edges[nodeId] ?? new Set();
      if (j > 0 && source) {
        const dest = p.path[j];
        edges[dest] = edges[dest] ?? new Set();
        edges[source].add(dest);
        edges[dest].add(source);
      }
      source = nodeId;
    });
  });

  return edges;
};

interface BiDirectedGraphEdges {
  forwardEdges: any[];
  backwardEdges: any[];
}

export const relationshipsToEdges = (
  relationships: any[] = [],
): Record<string, Set<string>> => {
  const forwardEdges: Record<string, Set<string>> = {};
  relationships?.forEach(r => {
    forwardEdges[r.source] = forwardEdges[r.source] ?? new Set();
    forwardEdges[r.source].add(r.dest);
  });

  return forwardEdges;
};

export const reverseEdges = (
  edges: Record<string, Set<string>> = {},
): Record<string, Set<string>> => {
  const backwardEdges: Record<string, Set<string>> = {};
  each(edges, (destinations, source) => {
    destinations.forEach(dest => {
      backwardEdges[dest] = backwardEdges[dest] ?? new Set();
      backwardEdges[dest].add(source);
    });
  });

  return backwardEdges;
};
