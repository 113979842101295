/*
 * UsersTable.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import * as React from 'react';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import 'sanitize.css/sanitize.css';
import './css/app.css';

import App from 'app';
import { configureAppStore } from 'store/configureStore';
// Initialize languages
import './locales/i18n';
import { SENTRY_DSN, VERSION, BUILT_AT, valueByEnv } from 'utils/env';

// use dayjs utc plugin
dayjs.extend(utc);

if (typeof (window as any).global === 'undefined') {
  (window as any).global = window;
}

const notInLocal =
  !window.location.origin.includes('localhost') &&
  !window.location.origin.includes('127.0');

if (notInLocal) {
  console.info('######################');
  console.info('UI version', VERSION);
  console.info('last updated at ', BUILT_AT);
  console.info('######################');
}

if (SENTRY_DSN && valueByEnv(notInLocal, false)) {
  // console.log('initializing sentry.');
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    release: VERSION,
    tracesSampleRate: 0.1,
    normalizeDepth: 10,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
  });
}

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(MOUNT_NODE);

const ConnectedApp = () => (
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
);

root.render(<ConnectedApp />);
