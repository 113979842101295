import React, { FC } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { Box, UseDisclosureProps } from '@chakra-ui/react';
import { noop } from 'lodash';

import { IconTypes } from 'components/Icons';
import { Drawer, usePortalRef } from 'components/Overlay';
import { AlertDrawerHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AlertDrawerHeader';
import { AlertRemediation } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/AlertRemediation';
import { findingsOverviewSaga } from 'containers/Findings/FindingsOverview/saga';
import { reducer, sliceKey } from 'containers/Findings/FindingsOverview/slice';
import { useInjector } from 'utils/inject';

interface IRemediationModal {
  state: UseDisclosureProps;
  alert: AlertResponse;
}

export const RemediationModal: FC<IRemediationModal> = props => {
  useInjector(sliceKey, reducer, findingsOverviewSaga);

  const { state, alert } = props;

  const { isOpen = false, onClose = noop } = state;

  const containerRef = usePortalRef();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <AlertDrawerHeader alert={alert} iconType={IconTypes.Remediate} />
      }
      body={
        <Box h="full" w="full" pt={3}>
          <AlertRemediation alert={alert} />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1300px' },
        drawer: { portalProps: { containerRef: containerRef as any } },
      }}
    />
  );
};
