import React, { useEffect, useState } from 'react';

import { SortOrder as NotificationSortOrder } from '@ariksa/notification';
import { CloudType, Severity, Status } from '@ariksa/notification/api';
import { Box, Center } from '@chakra-ui/react';
import { isEqual, toUpper } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIcon,
  renderSeverityBar,
  renderTime,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted, SortOrder } from 'components/DataDisplay/NewTable/types';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, RefreshIcon } from 'components/Icons';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectTickets } from 'containers/Findings/Tickets/selectors';
import { actions } from 'containers/Findings/Tickets/slice';
import { TicketsSearchParamsProps } from 'containers/Findings/Tickets/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

import { useAccessBoundary } from '../../../App/hooks/useAccessBoundary';
import { useCloudAccountId } from '../../../Setup/CloudAccounts/utils';

export const TicketsTable = () => {
  const dispatch = useDispatch();
  const { tickets } = useSelector(selectTickets);
  const { finalSearchTerm } = useSelector(selectSharedState);

  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const { getResourceAlias } = useResourceType();
  const { accountId, environmentId } = useAccessBoundary();
  const { accountMapping } = useCloudAccountId();
  const [sortByField, setSortByField] = useState<Sorted>({} as Sorted);
  const params = useSearchParams<TicketsSearchParamsProps>();

  useEffect(() => {
    if (!!params?.sortOrder && !!params?.sortField)
      setSortByField({
        sortOrder: params?.sortOrder as SortOrder,
        sortField: params?.sortField,
      });
  }, [params?.sortOrder, params?.sortField]);

  useEffect(() => {
    dispatch(
      actions.getTickets({
        q: {
          environmentId: environmentId as string,
          accountIds: !!accountId ? [accountId] : undefined,
          alertStatus: !!params?.status
            ? (params?.status as Status)
            : undefined,
          severity: !!params?.severity
            ? (params?.severity as Severity)
            : undefined,
          resourceType: !!params?.resourceType
            ? params?.resourceType
            : undefined,
          overDueDate: params?.isOverDueDate === 'true',
          cloud: !!params?.cloud ? (params?.cloud as CloudType) : undefined,
          sortField: !!sortByField?.sortField
            ? sortByField?.sortField
            : undefined,
          sortOrder: !!sortByField?.sortOrder
            ? (toUpper(sortByField?.sortOrder) as NotificationSortOrder)
            : undefined,
          search: finalSearchTerm,
        },
      }),
    );
  }, [
    environmentId,
    accountId,
    dispatch,
    params.severity,
    params.isOverDueDate,
    params.resourceType,
    params.status,
    params.cloud,
    sortByField,
    finalSearchTerm,
  ]);

  const handleRowClick = row => {
    updateActiveResource({
      resourceType: row?.resource,
      resourceId: row?.entity_id,
      uuid: row?.entity_uuid,
      accountId: row?.account_id,
    });
    onOpenMetadata();
  };

  const handleRowSync = row => {
    dispatch(
      actions.syncTicketInformation({ q: { ticketId: row?.ticket_id } }),
    );
  };

  const renderAction = ({ row }) => (
    <Center onClick={e => e.stopPropagation()}>
      <ActionButton
        label="Sync ticket information"
        icon={<RefreshIcon />}
        onClick={() => {
          handleRowSync(row);
        }}
      />
    </Center>
  );

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Ticket</Box>,
      accessor: 'name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={IconTypes.Ticket}
          iconSize="sm"
          bgColor={accountMapping?.[row?.account_id]?.cloud_type!}
        >
          <StackedCell
            upper={row?.ticket_id}
            lower={toTitleCase(row?.ticket_client ?? '-')}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Owner',
      accessor: 'owner',
      align: 'left',
      render: ({ row }) => (
        <StackedCell upper={row?.owner} lower={row?.project} />
      ),
    },
    {
      header: 'Issue',
      accessor: 'issue',
      align: 'left',
    },
    {
      header: 'Resource',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.resource_name ?? row?.resource_id}
          lower={getResourceAlias(row?.resource_type)}
        />
      ),
      sortKey: 'resource_id',
      accessor: 'resource_id',
    },
    {
      header: 'Account',
      accessor: 'account_id',
      align: 'left',
      render: ({ value }) => (
        <StackedCell
          upper={
            accountMapping?.[value]?.name ??
            accountMapping?.[value]?.cloud_account_id
          }
          lower={
            <Box boxSize={6}>
              {getIcon(accountMapping?.[value]?.cloud_type!)}
            </Box>
          }
        />
      ),
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
    },
    {
      header: 'State',
      accessor: 'state',
      align: 'left',
    },
    {
      header: 'Due Date',
      accessor: 'due_date',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Box flex={1}>
      <Table
        data={tickets.data}
        columns={columns}
        isLoading={tickets.isLoading}
        cursor="pointer"
        onRowClick={row => {
          handleRowClick(row);
        }}
        styles={{ header: { zIndex: 800 } }}
        sortBy={sortByField}
        onSort={sortInfo => {
          if (!isEqual(sortByField, sortInfo)) {
            setSortByField(sortInfo);
          }
        }}
      />
    </Box>
  );
};
