import {
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiUpdateCloudAccountRequest,
  CodeRepositoryApiIsTokenValidRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  Commands,
  ConfigApiGetCommandsConfigRequest,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { CloudAccountService, InventoryService } from 'api/services';
import { actions } from 'containers/Setup/slice';

export function* setupSaga() {
  yield takeLatestAction(actions.validateToken, doValidateToken);
  yield takeLatestAction(actions.onboardAccount, doOnboardAccount);
  yield takeLatestAction(actions.getAccounts, doGetAccounts);
  yield takeLatestAction(actions.updateCloudAccount, doUpdateCloudAccounts);
  yield takeLatestAction(actions.getConfigCommands, doGetConfigCommands);
}

/*onboard account*/
function* doOnboardAccount(
  ctx: QueryContext<any, CloudAccountApiOnboardCloudAccountRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.onboardCloudAccount(ctx.params),
    { errorMsg: 'Failed to add account!' },
  );
}

export function* doValidateToken(
  ctx: QueryContext<boolean, CodeRepositoryApiIsTokenValidRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.isTokenValid(ctx.params),
    {
      errorMsg: 'Failed to validate token!',
    },
  );
}

/*get accounts*/
function* doGetAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.getCloudAccounts(ctx.params),
    {
      errorMsg: 'Failed to get accounts!',
    },
  );
}

/*update account information*/
export function* doUpdateCloudAccounts(
  ctx: QueryContext<any, CloudAccountApiUpdateCloudAccountRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CloudAccount.updateCloudAccount(ctx.params),
    {
      errorMsg: 'Failed to update account!',
      successMsg: 'Account information updated successfully!',
    },
  );
}

export function* doGetConfigCommands(
  ctx: QueryContext<Commands, ConfigApiGetCommandsConfigRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Config.getCommandsConfig(ctx.params),
  );
}
