import { row } from 'mathjs';

import { CommonLayout } from 'components/Visualization/PixiGraph/common/Layout';
import { NodeType } from 'components/Visualization/PixiGraph/common/NodeType';
import { AGraphLayout } from 'components/Visualization/PixiGraph/core/Layout';
import {
  findMaxDepthPath,
  traverseDFS,
} from 'components/Visualization/PixiGraph/core/utils';
import { PostureMap } from 'components/Visualization/PixiGraph/maps/PostureMap/elements/Graph';

export class StemLayout extends AGraphLayout<PostureMap> {
  gapX = 160;
  gapY = 140;

  reset(graph: PostureMap) {}

  // find the path with maximum number of nodes
  layoutNodes(graph: PostureMap) {
    const { gapX, gapY } = this;

    const { path, end } = findMaxDepthPath(graph.edgeMap);

    const nodeColumnMap = new Map<string, number>();
    const nodeRowMap = new Map<string, number>();
    const columnRowMap = new Map<number, [number, number]>();

    const positioned = new Set<string>();

    path.forEach((id, index) => {
      const node = graph.nodeIdMap.get(id);
      if (node) {
        node.x = index * gapX;
        nodeColumnMap.set(id, nodeColumnMap.size);
        nodeRowMap.set(id, 0);
        columnRowMap.set(nodeColumnMap.size, [0, 0]);
        if (NodeType.isInternet(node)) {
          node.y = -15;
        }

        node.x += 100;

        positioned.add(id);
      }
    });

    const forwardEdges = graph.edgeMap;
    const backwardEdges = graph.edgeMap.inverse();

    const placeBackwardNodes = (nodeIds: string[], column: number) => {
      for (let index = 0; index < nodeIds.length; index++) {
        const nodeId = nodeIds[index];
        if (positioned.has(nodeId)) {
          continue;
        }
        positioned.add(nodeId);

        const node = graph.nodeIdMap.get(nodeId);
        if (node) {
          let rows = columnRowMap.get(column) ?? [0, 0];
          if (index % 2) {
            rows[1] += 1;
            node.x = column * gapX;
            node.y = rows[1] * gapY;
          } else {
            rows[0] -= 1;
            node.x = column * gapX;
            node.y = rows[0] * gapY;
          }

          node.x += 100;

          columnRowMap.set(column, rows);
        }
      }

      nodeIds.forEach(nodeId => {
        const backIds = backwardEdges.get(nodeId) ?? [];
        if (backIds.length > 0) {
          placeBackwardNodes(backIds, column - 1);
        }
      });

      nodeIds.forEach(nodeId => {
        const backIds = forwardEdges.get(nodeId) ?? [];
        if (backIds.length > 0) {
          placeForwardNodes(backIds, column + 1);
        }
      });
    };

    const placeForwardNodes = (nodeIds: string[], column: number) => {
      for (let index = 0; index < nodeIds.length; index++) {
        const nodeId = nodeIds[index];
        if (positioned.has(nodeId)) {
          continue;
        }
        positioned.add(nodeId);

        const node = graph.nodeIdMap.get(nodeId);
        if (node) {
          let rows = columnRowMap.get(column) ?? [0, 0];
          if (index % 2) {
            rows[1] += 1;
            node.x = column * gapX;
            node.y = rows[1] * gapY;
          } else {
            rows[0] -= 1;
            node.x = column * gapX;
            node.y = rows[0] * gapY;
          }

          node.x += 100;

          columnRowMap.set(column, rows);
          nodeColumnMap.set(nodeId, column);
        }
      }

      nodeIds.forEach(nodeId => {
        const backIds = forwardEdges.get(nodeId) ?? [];
        if (backIds.length > 0) {
          placeForwardNodes(backIds, column + 1);
        }
      });
    };

    // place forward nodes
    path.forEach((id, index) => {
      const nextIds = forwardEdges.get(id) ?? [];
      placeForwardNodes(nextIds, index + 1);
    });

    // place backward nodes
    graph.nodes.forEach(node => {
      const column = nodeColumnMap.get(node.id);
      if (column) {
        const backIds = backwardEdges.get(node.id) ?? [];
        placeBackwardNodes(backIds, column - 1);
      }
    });
  }

  layoutEdges(graph: PostureMap) {
    CommonLayout.layoutEdges(graph);
  }
}
