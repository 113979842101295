import { forEach, includes, map } from 'lodash';

export const getEnvironmentAccountIdsObject = value => {
  let account = new Set();
  forEach(value, o =>
    includes(Object.keys(account), o?.data?.environmentId)
      ? account[o?.data?.environmentId].push(o?.data?.accountId)
      : (account[o?.data?.environmentId] = [o?.data?.accountId]),
  );

  return account;
};

export const getEnvironmentAccountIdsArray = value => {
  return map(
    getEnvironmentAccountIdsObject(value),
    (account_ids, environment_id) => ({
      environment_id: environment_id,
      account_ids: account_ids,
    }),
  );
};
