import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { ShowMoreField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/ShowMoreField';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  CoreCount,
  DeleteOnTermination,
  HttpEndpoint,
  HttpPutHopLimit,
  HttpTokens,
  Hypervisor,
  ImageID,
  InstanceType,
  KeyPair,
  MonitoringEnabled,
  PrivateDNS,
  PrivateIP,
  PublicIP,
  RootDeviceName,
  SecurityGroups,
  SubnetID,
  TerminationProtection,
  ThreadsPerCore,
  VirtualizationType,
  VMLoggingEnabled,
  IAMInstanceProfileARN,
  InstanceProfileID,
  InstanceLifecycle,
  EBSOptimized,
  PublicDNS,
  GPUID,
  AMIProductCode,
  AMIProductType,
  RAMDiskID,
  EBSDisks,
} from '../../../Components/MetadataField/MetaGridFields';

export const VMMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {SubnetID(resource)}
        {IAMInstanceProfileARN(resource)}
        {InstanceProfileID(resource)}
        {InstanceType(insight)}
        {InstanceLifecycle(resource)}
        {EBSDisks(insight)}
        {EBSOptimized(resource)}
        {PrivateIP(resource)}
        {PrivateDNS(resource)}
        {PublicIP(insight)}
        {PublicDNS(resource)}
        {SecurityGroups(resource)}
        {KeyPair(resource)}
        {RootDeviceName(resource)}
        {TerminationProtection(insight)}
        {DeleteOnTermination(resource)}
        {VMLoggingEnabled(resource)}
        {MonitoringEnabled(insight)}
        {ImageID(insight)}
        {GPUID(resource)}
        {AMIProductCode(resource)}
        {AMIProductType(resource)}
        {Hypervisor(resource)}
        {VirtualizationType(resource)}
        {CoreCount(resource)}
        {ThreadsPerCore(resource)}
        {RAMDiskID(resource)}
        {HttpTokens(resource)}
        {HttpPutHopLimit(resource)}
        {HttpEndpoint(resource)}
        <ShowMoreField label="UserData" value={insight?.user_data ?? '-'} />
      </Grid>
    </Stack>
  );
};
