/**
 *
 * Elastic For SIEM Integration
 *
 */

import React, { FC } from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DUMMY_API_KEY } from 'containers/Setup/Integrations/types';

import { actions } from '../../slice';

import { ElasticForSIEMForm } from './ElasticForSIEMForm';

export const EditElasticForSIEM: FC = () => {
  const dispatch = useDispatch();
  const { onCancel, currentRecord } = useIntegrationsContext();

  const onSubmit = data => {
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        //name: data.name,
        //status: true,
        description: data?.description,
        /*elastic_search: {
          ...(data?.api_Key === DUMMY_API_KEY
            ? {}
            : { api_key: data?.api_key }),
        },*/
      },
    };
    dispatch(
      actions.updateClientById({
        q: payload,
        onSuccess: () => onCancel(),
      }),
    );
  };

  return <ElasticForSIEMForm onSubmit={onSubmit} />;
};
