import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  Description,
  ID,
  Name,
  Visibility,
  Subgroups,
  Webhooks,
  CICDRunners,
} from '../../../Components/MetadataField/MetaGridFields';

export const GitlabRepositoryMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const variables = resourceDetails.data?.config?.variables;

  const columns = [
    { header: 'Key', accessor: 'key' },
    { header: 'Value', accessor: 'value', align: 'left' },
  ];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {ID(resource)}
      {Description(resource)}
      {Visibility(resource)}
      {Subgroups(resource)}
      {Webhooks(resource)}
      {CICDRunners(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple defaultIndex={[0]}>
          <MetaAccordionItem
            panel={<Table columns={columns} data={variables} />}
            iconType={IconTypes.Service}
            label="Variables"
            totalCount={variables?.length}
            height="300px"
            iconBGColor="primary"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
