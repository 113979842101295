import React from 'react';

import { Icon } from '@chakra-ui/react';

export const IdentityCloudIcon = props => (
  <Icon viewBox="0 0 1765 1765" color="inherit" h="full" w="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="270" y="357" width="1765" height="1765" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="275" y="357" width="1760" height="1761" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="275" y="357" width="1760" height="1761" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="275" y="357" width="1760" height="1761" />
      </clipPath>
      <clipPath id="clip4">
        <rect x="840" y="1039" width="535" height="536" />
      </clipPath>
      <clipPath id="clip5">
        <rect x="840" y="1039" width="535" height="536" />
      </clipPath>
      <clipPath id="clip6">
        <rect x="840" y="1039" width="535" height="536" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-270 -357)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M1845.94 1221.5C1843.53 1055.81 1708.09 921.75 1541.72 921.75 1504.25 921.75 1467.12 928.625 1432.06 942.031 1405.25 860.906 1355.06 788.031 1288.03 733.719 1208.62 669.781 1108.94 634.375 1006.84 634.375 766.562 634.375 569.938 824.812 559.625 1062.69 483.312 1085.37 414.219 1131.09 363.688 1193.66 306.625 1263.78 275 1352.81 275 1443.91 275 1663.22 453.406 1841.62 672.719 1841.62L1710.16 1841.62C1889.25 1841.62 2035 1695.87 2035 1516.78 2035 1389.94 1960.06 1274.09 1845.94 1221.5ZM1710.16 1773.56 672.719 1773.56C491.219 1773.56 343.406 1625.75 343.406 1444.25 343.406 1368.97 369.531 1295.06 416.969 1236.62 463.719 1178.87 529.375 1138.66 601.219 1122.5 617.031 1119.06 628.375 1104.97 628.031 1088.47L628.031 1087.44C628.031 1085.72 628.031 1083.66 628.031 1081.94 628.031 872.937 798.188 702.781 1007.19 702.781 1094.84 702.781 1177.34 732 1245.41 787 1311.75 840.625 1358.5 915.219 1377.06 997.719 1379.47 1008.37 1386.69 1016.97 1396.31 1021.44 1406.28 1025.91 1417.62 1025.22 1426.91 1020.06 1461.97 1000.47 1501.5 990.156 1541.72 990.156 1671.66 990.156 1777.53 1096.03 1777.53 1225.97 1777.53 1230.78 1777.19 1235.59 1776.84 1241.78 1775.81 1256.91 1784.75 1270.66 1799.19 1276.16 1899.22 1313.28 1966.59 1410.22 1966.59 1516.78 1966.59 1658.41 1851.44 1773.56 1710.16 1773.56Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
      <g clipPath="url(#clip4)">
        <g clipPath="url(#clip5)">
          <g clipPath="url(#clip6)">
            <path
              d="M1327.04 1574C1315.65 1574 1306.45 1564.8 1306.45 1553.42L1306.45 1498.56C1306.45 1449.34 1266.43 1409.42 1217.32 1409.42L997.783 1409.42C948.568 1409.42 908.651 1449.45 908.651 1498.56L908.651 1553.42C908.651 1564.8 899.456 1574 888.067 1574 876.677 1574 867.482 1564.8 867.482 1553.42L867.482 1498.56C867.482 1426.67 925.893 1368.25 997.783 1368.25L1217.22 1368.25C1289.11 1368.25 1347.52 1426.67 1347.52 1498.56L1347.52 1553.42C1347.52 1564.8 1338.32 1574 1327.04 1574ZM1107.5 1299.6C1035.61 1299.6 977.198 1241.19 977.198 1169.3 977.198 1097.41 1035.61 1039 1107.5 1039 1179.39 1039 1237.8 1097.41 1237.8 1169.3 1237.8 1241.19 1179.39 1299.6 1107.5 1299.6ZM1107.5 1080.17C1058.28 1080.17 1018.37 1120.19 1018.37 1169.3 1018.37 1218.41 1058.39 1258.43 1107.5 1258.43 1156.61 1258.43 1196.63 1218.41 1196.63 1169.3 1196.63 1120.19 1156.72 1080.17 1107.5 1080.17Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
