import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, Layout } from 'app/components';
import { selectUser } from 'containers/App/selectors';
import { useTrackApiCall } from 'hooks/useTrackApiCall';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { setupUsersSaga } from '../saga';
import { selectSetupUsers } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';

import { UserDetails } from './UserDetails';

interface ICreateUser {}

export const CreateUser: FC<ICreateUser> = props => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: setupUsersSaga });

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { createUser } = useSelector(selectSetupUsers);
  const { success: created } = useTrackApiCall(createUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (created) {
      navigate('/setup/users');
    }
  }, [navigate, created]);

  const handleSubmit = data => {
    const { fullname, username, password, email } = data;
    const { first_name, last_name } = fullname;

    // update user form
    dispatch(
      actions.updateUserForm({ email, first_name, last_name, username }),
    );

    dispatch(
      actions.createUser({
        user: {
          first_name,
          last_name,
          username,
          password,
          email,
        },
        organization: user.info.org_id,
      }),
    );
  };

  return (
    <Layout
      middleComponent={
        <Card
          styles={{ card: { pl: 24 } }}
          contentComponent={
            <UserDetails
              passField
              action={'create'}
              isActionOnProgress={createUser.loading}
              onSubmit={handleSubmit}
            />
          }
        />
      }
    />
  );
};
