import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { Card } from 'components/DataDisplay/Card';
import { ComplianceHeader } from 'containers/Compliance/Components/ComplianceHeader';
import { ComplianceTableTabs } from 'containers/Compliance/Components/ComplianceTables/ComplianceTableTabs';

interface Props {
  showDashboard: boolean;
  setShowDashboard(val: boolean);
}

export const ComplianceTables: FC<Props> = props => {
  const { showDashboard, setShowDashboard } = props;
  return (
    <>
      <Card
        styles={{ cardContent: { flex: 1, h: 'full' } }}
        contentComponent={
          <Stack spacing={6} h="full" w="full">
            {!showDashboard && (
              <ComplianceHeader
                showDashboard={showDashboard}
                setShowDashboard={setShowDashboard}
              />
            )}
            <ComplianceTableTabs />
          </Stack>
        }
      />
    </>
  );
};
