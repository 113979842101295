import React, { FC, useEffect, useState } from 'react';

import { ResourceMapping } from '@ariksa/inventory-core/api';

import { StackedHeader } from 'components/DataDisplay';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export interface Props {
  onBack?();
}

export const MetadataHeader: FC<Props> = () => {
  const {
    resourceInsight,
    resourceType,
    resourceDetails,
  } = useActiveResourceContext();
  const { data: insight } = resourceInsight;
  const { data: resource } = resourceDetails;
  const [type, setType] = useState<ResourceMapping>({} as ResourceMapping);
  const { getResourceType } = useResourceType();

  useEffect(() => {
    setType(getResourceType(resourceType) || ({} as ResourceMapping));
  }, [getResourceType, resourceType]);

  return (
    <StackedHeader
      upper={
        insight?.name ||
        resource?.general?.name ||
        insight?.resource_id ||
        resource?.general?.resource_id
      }
      lower={`${type.cloud_native_name} / ${type.cloud_agnostic_name}`}
      type={resourceType}
    />
  );
};
