/**
 *
 * SMS Form
 *
 */

import React, { FC, useState, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { map, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import * as yup from 'yup';

import { Form } from 'components/DataEntry';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { formStyles } from '../../styles';

interface Props {
  handleSubmit: (data: any) => void;
}

export const SMSForm: FC<Props> = props => {
  const { handleSubmit } = props;
  const cloudAccounts = useSelector(selectOnboardedCloudAccounts);
  const { client } = useSelector(selectIntegrations);
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();
  const dispatch = useDispatch();
  const { inventoryInsights } = useInventoryInsightService();

  const [accountOptions, setAccountOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [topicOptions, setTopicOptions] = useState<Record<string, any>[]>([]);
  const [isEdit, setIsEdit] = useState(actionType === 'Update');

  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [selectedTopic, setSelectedTopic] = useState<any>({});

  //set isEdit true if opened in edit
  useEffect(() => {
    setIsEdit(actionType === 'Update');
  }, [actionType]);

  //set cloud account options
  useEffect(() => {
    setAccountOptions(
      map(cloudAccounts.data, o => ({ label: o?.name, value: o?.uuid })),
    );
  }, [cloudAccounts]);

  //in case of edit, set selected account and topic
  useEffect(() => {
    setSelectedAccount(
      find(accountOptions, o => o.value === currentRecord?.sns?.account_id) ||
        accountOptions[0],
    );
  }, [accountOptions, currentRecord]);

  //load all the sns topics
  useEffect(() => {
    !!selectedAccount?.value &&
      inventoryInsights.sync({
        insightV2RequestBase: {
          resource_type: NativeResources.SimpleNotificationService,
          account_id: [selectedAccount?.value],
        },
      });
  }, [dispatch, inventoryInsights, selectedAccount]);

  //set sns topic options
  useEffect(() => {
    setTopicOptions(
      map(inventoryInsights.data?.items, o => ({
        label: o.name,
        value: o,
      })),
    );
  }, [inventoryInsights.data]);

  //in case of edit, set selected topic
  useEffect(() => {
    setSelectedTopic(
      find(
        topicOptions,
        o => o?.value?.resource_id === currentRecord?.sns?.topic_arn,
      ) || topicOptions[0],
    );
  }, [currentRecord, topicOptions]);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: currentRecord?.name,
          isDisabled: client.isLoading,
          //tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
        },
        account: {
          type: 'react-select',
          label: 'Cloud Account',
          isRequired: true,
          options: accountOptions,
          defaultValue: selectedAccount,
          isDisabled: client.isLoading,
          onChange: setSelectedAccount,
        },
        topic: {
          type: 'react-select',
          label: 'Cloud Notification Topic',
          isRequired: true,
          isLoading: inventoryInsights.isLoading,
          options: topicOptions,
          defaultValue: selectedTopic,
          onChange: setSelectedTopic,
          isDisabled: client.isLoading || inventoryInsights.isLoading,
        },
      }}
      buttonOptions={{
        submit: {
          name: isEdit ? actionType : 'Okay',
          isLoading: client.isLoading,
          isDisabled: client.isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      showHelp
      styles={formStyles}
      handleSubmit={handleSubmit}
    />
  );
};
