import React, { FC, useEffect, useState } from 'react';

import { AlertResponse, NotificationFor } from '@ariksa/notification/api';
import { Box, useDisclosure } from '@chakra-ui/react';
import { find, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  renderAlertCategory,
  renderSeverityBar,
  renderTime,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  ComplianceCheckPassedIcon,
  NotificationIcon,
  PageInfo,
  RemediateIcon,
  SnoozeIcon,
  TableActionsMenu,
  AutomationIcon,
} from 'components/index';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { SnoozeAlert } from 'containers/Findings/Alerts/Components/SnoozeAlert';
import {
  renderAlertsStatus,
  renderAlertsStatusHeader,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/getAlertColumns';
import { RemediationModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationModal';
import { useAlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/hooks/useAlertStatus';

interface Props {}

// depends on ActiveResourceProvider
export const AlertDetailsTable: FC<Props> = () => {
  const { alerts, alertStatus } = useSelector(selectActiveResource);
  const { getAlerts } = useActiveResourceActions();
  const { getAlertListStatus } = useAlertStatus();
  const onCreateTicket = alert => {
    getAlertListStatus([alert.uuid!]);
  };

  const exceptionModal = useDisclosure();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const snooze = useDisclosure();
  const remediate = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const dispatch = useDispatch();

  // fetch alerts
  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  const renderActions = ({ row }) => {
    const tickets = alertStatus?.data?.[row?.uuid]?.logs;
    const ticketStatus = find(
      tickets,
      t => toLower(t.ticket_status) !== 'todo',
    );
    //console.log(ticketStatus);

    const menuItems = [
      {
        label: 'Create ticket',
        icon: <AutomationIcon />,
        onClick: () => {
          setCurrentRecord(row);
          ticketModal.onOpen();
        },
      },
      {
        label: 'Send notification',
        icon: <NotificationIcon />,
        onClick: () => {
          setCurrentRecord(row);
          notificationModal.onOpen();
        },
      },
      {
        label: 'Snooze',
        icon: <SnoozeIcon />,
        onClick: () => {
          setCurrentRecord(row);
          snooze.onOpen();
        },
      },
      {
        label: 'Add exception',
        icon: <ComplianceCheckPassedIcon />,
        onClick: () => {
          setCurrentRecord(row);
          exceptionModal.onOpen();
        },
      },
      {
        label: 'Remediate',
        icon: <RemediateIcon />,
        onClick: () => {
          setCurrentRecord(row);
          remediate.onOpen();
        },
      },
    ];

    return (
      <Box onClick={e => e.stopPropagation()}>
        <TableActionsMenu menuItems={menuItems} />
      </Box>
    );
  };

  const columns = [
    {
      header: 'Finding',
      accessor: 'message',
      align: 'left',
    },
    {
      header: 'Category',
      accessor: 'alert_category',
      render: renderAlertCategory,
      align: 'left',
      styles: { width: '200px', cell: { maxWidth: '200px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      align: 'left',
      styles: { width: 100 },
    },
    {
      header: 'Timestamp',
      accessor: 'created_at',
      render: renderTime,
      align: 'left',
      styles: { width: 100 },
    },
    {
      header: renderAlertsStatusHeader(),
      render: ({ row }) => {
        const tickets = alertStatus?.data?.[row?.uuid]?.logs;
        return renderAlertsStatus(tickets);
      },
      align: 'left',
      styles: { width: 100 },
    },
    {
      header: 'Actions',
      styles: { width: '60px', cell: { maxWidth: '60px' } },
      render: renderActions,
    },
  ];

  return (
    <Box h="xl">
      <Table
        columns={columns}
        data={alerts.data}
        isLoading={alerts.isLoading}
        styles={{ height: '33vh' }}
        pagination={{
          pageInfo: alerts.page.info,
          totalCount: alerts.page.totalCount,
          onChange: (info: PageInfo) =>
            dispatch(actions.updateAlertsPagination(info)),
        }}
      />
      {ticketModal.isOpen && (
        <CreateTicket
          onClose={ticketModal.onClose}
          isOpen={ticketModal.isOpen}
          alert={currentRecord}
          onCreate={onCreateTicket}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {notificationModal.isOpen && (
        <CreateNotification
          onClose={notificationModal.onClose}
          isOpen={notificationModal.isOpen}
          alert={currentRecord}
          onCreate={onCreateTicket}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {exceptionModal.isOpen && (
        <AddException
          onClose={exceptionModal.onClose}
          isOpen={exceptionModal.isOpen}
          alert={currentRecord as AlertResponse}
        />
      )}
      {snooze.isOpen && (
        <SnoozeAlert
          onClose={snooze.onClose}
          isOpen={snooze.isOpen}
          alert={currentRecord as AlertResponse}
          onSuccess={() => getAlerts()}
        />
      )}
      {remediate.isOpen && (
        <RemediationModal
          state={remediate}
          alert={currentRecord as AlertResponse}
        />
      )}
    </Box>
  );
};
