import {
  V1ActionsPerformedOnDayResponse,
  V1ResourceActionsDailyResponse,
} from '@ariksa/audittrail';
import {
  V1ActionsPerformedMonthlyResponse,
  V1ResourceActionsMonthlyResponse,
} from '@ariksa/audittrail/api';
import { CloudProviders } from '@ariksa/cloud-account/api';
import {
  S3CrossAccountAccess,
  S3InsecureACL,
  S3InternetActivity,
} from '@ariksa/compliance-policies/api';
import {
  DataAnalyzedRecords,
  DistributionByDocumentType,
  DocumentClassificationForSourceInfo,
  FileMetaDataInfo,
  ObjectsDistributionCount,
  RepositoryBranchesInfo,
  SensitiveDataInfo,
  SourceCountSummary,
} from '@ariksa/data-scanning/api';
import { ConnectionType } from '@ariksa/ebpf-data-collector/api';
import { NativeResources } from '@ariksa/inventory-core';
import {
  AppSchemasIacIacDeploymentSummary,
  Connection,
  CrudPermissions,
  DeployedResource,
  RiskContextResponse,
  SearchResponse,
} from '@ariksa/inventory-core/api';
import { AlertSeverityInitializer } from '@ariksa/notification';
import {
  AlertLogsResponse,
  AlertResponse,
  EntityTimelineResponse,
} from '@ariksa/notification/api';
import {
  ContainerInfo,
  ECRImageVisibility,
  InstanceSummary,
  LibraryType,
  Vulnerability,
} from '@ariksa/scan-analysis/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

export interface ActiveResourceState {
  resourceType: NativeResources;
  resourceUuid: string;
  resourceAccountId: string;
  resourceId: string;
  searchTerm: string;
  cloudProvider?: CloudProviders;
  alerts: IPagedQueryState<AlertResponse[]>;
  resourceDetails: IQueryState<Dict<any>>; // resource by id
  resourceInsight: IQueryState<Dict<any>>;
  riskContext: IQueryState<RiskContextResponse[]>;
  entityTimeline: IQueryState<EntityTimelineResponse[]>;
  activeTabIndex: number;
  activeTabName: string;
  vulnerabilitySummary: IQueryState<InstanceSummary>;
  vulnerabilityDetails: {
    os: Dict<IPagedQueryState<Vulnerability[]>>;
    application: Dict<IPagedQueryState<Vulnerability[]>>;
    library: Dict<IPagedQueryState<Vulnerability[]>>;
  };
  imageVulnerabilities: Dict<IPagedQueryState<Vulnerability[]>>;
  instanceVulnerabilityDetails: IQueryState<Dict<VulnerabilityDetails>>;

  resourceSeverity: IQueryState<AlertSeverityInitializer>;
  networkMap: IQueryState<any>;
  networkMapScale: number;
  piiByBucketName: IPagedQueryState<FileMetaDataInfo[]>;
  dataRiskContext: IQueryState<any>;
  canAccessSummary: {
    canAccess: IQueryState<Dict<any>[]>;
    accessByIdentity: IQueryState<Dict<any>[]>;
    canAccessData: IQueryState<Dict<any>[]>;
    privilegeSummary: IQueryState<Dict<any>[]>;
    accessMap: IQueryState<SearchResponse>;
    expandedAccessMap: IQueryState<SearchResponse>;
  };
  data: {
    dataTypesBySource: IQueryState<SensitiveDataInfo[]>;
    documentTypesBySource: IPagedQueryState<
      DocumentClassificationForSourceInfo[]
    >;
    summary: IQueryState<SourceCountSummary>;
    dataLabels: IQueryState<DataAnalyzedRecords>;
    typesOfFiles: IQueryState<ObjectsDistributionCount>;
    distributionByDocumentType: IQueryState<DistributionByDocumentType[]>;
  };
  permissions: {
    insecureAcl: IQueryState<S3InsecureACL[]>;
  };
  activity: {
    s3InternetActivity: IQueryState<S3InternetActivity[][]>;
    s3CrossAccount: IQueryState<S3CrossAccountAccess[]>;
  };

  instanceSummaryByID: IQueryState<Record<string, InstanceSummary>>;
  containerVulnerability: IQueryState<ContainerInfo[]>;

  linkedResourceDetails: IQueryState<Dict<any>>;

  ecrContainerImages: IPagedQueryState<ECRImageVisibility[]>;
  branches: IQueryState<RepositoryBranchesInfo[]>;
  alertStatus: IQueryState<Record<string, AlertLogsResponse>>;
  alertAction: IQueryState<any>;
  accessed: IQueryState<V1ActionsPerformedMonthlyResponse>;
  actionsOnDay: IQueryState<V1ActionsPerformedOnDayResponse>;
  libraryType?: LibraryType;
  showResolvedVulnerabilities?: boolean;
  vulnerabilityPublishedTime: Record<string, any>;
  vulnerabilitySeverity: string;
  isPatchable: string;

  deploymentSummary: IQueryState<AppSchemasIacIacDeploymentSummary[]>;
  accountDeployedResources: Record<
    string,
    IPagedQueryState<DeployedResource[]>
  >;
  permissionsMap: IQueryState<Array<CrudPermissions>>;
  resourceActionsMonthly: IQueryState<V1ResourceActionsMonthlyResponse>;
  resourceActionsDaily: IQueryState<V1ResourceActionsDailyResponse>;

  runningProcesses: IPagedQueryState<any>;
  processConnections: Dict<IPagedQueryState<Connection[]>>;
}

export interface VulnerabilityDetails {
  os: Vulnerability[];
  application: Vulnerability[];
  library: Vulnerability[];
}

export type ContainerState = ActiveResourceState;

export type ActiveResourceInfo = {
  resourceType: NativeResources;
  name?: string;
  uuid: string;
  accountId: string;
  resourceId?: string;
  tabIndex?: number;
  cloudProvider?: CloudProviders;
  showResolvedVulnerabilities?: boolean;
  vulnerabilityPublishedTime?: Record<string, any>;
  vulnerabilitySeverity?: string;
  isPatchable?: string;
  searchTerm?: string;
};

export const MetadataTabs = {
  Apps: 'Apps',
  Alerts: 'Alerts',
  Activity: 'Activity',
  Access: 'Access',
  Compliance: 'Compliance',
  Containers: 'Containers',
  Identity: 'Identity',
  Summary: 'Summary',
  Secrets: 'Secrets',
  Network: 'Network',
  Permissions: 'Permissions',
  Data: 'Data',
  ContainerImages: 'Container Images',
  Timeline: 'Timeline',
  Vulnerabilities: 'Vulnerabilities',
  Deployment: 'Service',
};
