import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import {
  getVulnerabilityIcon,
  getVulnerabilityIconBgColor,
  VulnerabilityTableHeading,
} from 'containers/Visibility/Vulnerabilities/Components/utils';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const VulnerabilityHeader: FC = () => {
  const { selectedTab } = useSelector(selectVulnerabilities);

  return (
    <PageHeaderWithIcon
      label={VulnerabilityTableHeading[selectedTab]}
      icon={getVulnerabilityIcon(selectedTab)}
      reversed
      fontSize="md"
      iconBgColor={getVulnerabilityIconBgColor(selectedTab)}
    />
  );
};
