import { SensitivityCategory } from '@ariksa/data-scanning';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { customTheme } from 'theme';

export const colorMapForCharts = {
  [SensitivityCategory.Internal]: customTheme.colors.critical,
  [SensitivityCategory.Restricted]: customTheme.colors.high,
  [SensitivityCategory.Confidential]: customTheme.colors.medium,
  [SensitivityCategory.Public]: customTheme.colors.low,
  'us-east-1': customTheme.colors.orange['500'],
  'us-east-2': customTheme.colors.orange['300'],
  'us-west-1': customTheme.colors.skyBlue['200'],
  'us-west-2': customTheme.colors.skyBlue['400'],
  'eu-east-1': customTheme.colors.yellow['300'],
  'eu-east-2': customTheme.colors.yellow['500'],
  'eu-west-1': customTheme.colors.red['200'],
  'eu-west-2': customTheme.colors.red['300'],
  'eu-west-3': customTheme.colors.red['400'],
  'eu-north-1': customTheme.colors.pink['200'],
  'eu-central-1': customTheme.colors.yellow['600'],
  'ap-northeast-1': customTheme.colors.green['300'],
  'ap-northeast-2': customTheme.colors.green['400'],
  'ap-northeast-3': customTheme.colors.green['500'],
  'ap-south-1': customTheme.colors.blue['300'],
  'ap-southeast-1': customTheme.colors.cyan['400'],
  'ap-southeast-2': customTheme.colors.cyan['600'],
  'ca-central-1': customTheme.colors.pink['500'],
  'sa-east-1': customTheme.colors.teal['500'],
  SaaS: customTheme.colors.teal['300'],
  [NativeResources.DynamoDb]: customTheme.colors.teal['500'],
  [NativeResources.RedshiftCluster]: customTheme.colors.pink['500'],
  [NativeResources.SimpleStorageService]: customTheme.colors.green['300'],
  S3: customTheme.colors.green['300'],
  [NativeResources.DocumentDbCluster]: customTheme.colors.green['500'],
  [SupportedServices.RelationalDatabaseService]: customTheme.colors.red['300'],
  RDS: customTheme.colors.red['300'],
  [SupportedServices.SnowflakeDatabases]: customTheme.colors.skyBlue['300'],
  [SupportedServices.GitLabBranch]: customTheme.colors.orange['300'],
  [SupportedServices.GitHubBranch]: customTheme.colors.yellow['300'],
};
