import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { ContentProps } from '@reactour/tour/src/types';

export const contentComponent = (
  description: any,
  props: ContentProps,
  fieldName: string,
  onClose: (setIsOpen) => void,
) => (
  <Stack pr={4}>
    <Box>{description}</Box>
    <Box
      color="primary"
      cursor="pointer"
      onClick={() => {
        onClose(props.setIsOpen);
      }}
    >
      Skip
    </Box>
  </Stack>
);
