import { Days } from '@ariksa/reporting';
import dayjs from 'dayjs';

import { OptionItem } from 'components/DataEntry/Button/types';

export const intoTimeRangeOptions = (options: any[]) => {
  return options.map(a => ({ label: a, value: a }));
};

export const intoTimeRangeOption = op => {
  return { label: op, value: op };
};

const timeRangeMap = () => {
  return {
    '1W': dayjs().subtract(1, 'week'),
    '1M': dayjs().subtract(1, 'month'),
    '3M': dayjs().subtract(3, 'month'),
  };
};

export const intoTimestamp = (range: string) => {
  return timeRangeMap()[range];
};

export const weekRangeOptions: OptionItem[] = [
  { label: 'S', value: Days.Sunday },
  { label: 'M', value: Days.Monday },
  { label: 'T', value: Days.Tuesday },
  { label: 'W', value: Days.Wednesday },
  { label: 'T', value: Days.Thursday },
  { label: 'F', value: Days.Friday },
  { label: 'S', value: Days.Saturday },
];
