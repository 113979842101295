import { CloudProvider } from '@ariksa/inventory-core';
import { map } from 'lodash';

import { getIcon, getProviderName, intoOptions } from 'components/DataDisplay';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const policySeverityOptions = intoOptions([
  'Critical',
  'High',
  'Medium',
  'Low',
]);

export const categoryClassOptions = map(AlertCategoryClass, o => ({
  label: o,
  value: o,
}));

export const policyProviderOptions = [
  {
    label: 'Cloud Agnostic',
    value: undefined,
  },
  {
    label: getProviderName(CloudProvider.Aws),
    value: CloudProvider.Aws,
    icon: getIcon(CloudProvider.Aws),
  },
  {
    label: getProviderName(CloudProvider.AzureAd),
    value: CloudProvider.AzureAd,
    icon: getIcon(CloudProvider.AzureAd),
  },
  {
    label: getProviderName(CloudProvider.Gcp),
    value: CloudProvider.Gcp,
    icon: getIcon(CloudProvider.Gcp),
  },
];
