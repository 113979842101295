/**
 *
 * Asynchronously loads the component for Reports
 *
 */

import { lazy } from 'react';

export const Reports = lazy(() =>
  import('./index').then(m => ({ default: m.Reports })),
);
