import React, { useEffect, useState } from 'react';

import { CloudAccountInfoGetResponse } from '@ariksa/jit/api';
import { HStack, Square, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { Heading4 } from 'components/DataDisplay';
import { Form } from 'components/DataEntry';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { Modal } from 'components/Overlay';
import { selectUser } from 'containers/App/selectors';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';

interface JitApproverModalProps {
  account: CloudAccountInfoGetResponse;
  isOpen: boolean;
  onClose: () => void;
}

export const JitApproverModal = (props: JitApproverModalProps) => {
  const { account } = props;
  const { jitApproverList, editJitApprover, jitUsers } = useSelector(selectJit);
  const dispatch = useDispatch();

  const { isOpen, onClose } = props;
  const { info } = useSelector(selectUser);

  const [approvers, setApprovers] = useState<{ label: string; value: any }[]>(
    [],
  );

  useEffect(() => {
    setApprovers(
      account.approver_details?.map(a => ({ label: a, value: a })) ?? [],
    );
  }, [account]);

  useEffect(() => {
    dispatch(
      actions.getJitUsers({
        q: {
          organization: info.org_id,
        },
      }),
    );
  }, [info, dispatch]);

  useEffect(() => {
    dispatch(
      actions.getJitApproverList({
        q: {
          organization: info.org_id,
        },
      }),
    );
  }, [dispatch, info]);

  // const existingApprovers = useMemo(() => {
  //   return new Set(account.approver_details?.map(a => a) || []);
  // }, [account.approver_details]);

  const handleSummit = data => {
    dispatch(
      actions.editJitApprover({
        q: {
          accountId: account.uuid ?? '',
          approverUpdate: {
            approvers: approvers.map(a => a.value),
          },
        },
        onSuccess: () => {
          onClose();
          dispatch(
            actions.getJitAccounts({
              q: {},
              page: INIT_PAGE_INFO,
            }),
          );
        },
      }),
    );
  };

  console.log(jitUsers.data);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <ManualIcon />
          </Square>
          <Heading4>Update JIT Approver</Heading4>
        </HStack>
      }
      styles={{
        modal: { size: 'lg' },
      }}
      body={
        <Stack w="full" h="full" spacing={5}>
          <Form
            schema={{
              approvers: {
                type: 'react-select',
                label: 'Approver',
                isMulti: true,
                isLoading: jitApproverList.isLoading,
                defaultValue: approvers,
                options:
                  jitUsers.data?.users?.map(u => ({
                    label: u.email,
                    value: u.email,
                  })) ?? [],
                onChange: options => {
                  setApprovers(options);
                },
                isDisabled: jitApproverList.isLoading,
              },
            }}
            handleSubmit={handleSummit}
            buttonOptions={{
              submit: {
                name: 'Update',
                isVisible: true,
                isLoading: editJitApprover.isLoading,
                // isDisabled: isEmpty(approvers),
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: onClose,
              },
            }}
            styles={{
              form: {
                submitButton: {
                  bg: 'primary',
                  color: 'white',
                },
              },
            }}
          />
        </Stack>
      }
    />
  );
};
