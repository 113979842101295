import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ApiIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M510.6,28.1L484,1.4c-1-1-2.3-1.4-3.6-1.4s-2.6,0.5-3.6,1.4l-47.8,47.8c-20.8-14.1-45.3-21.6-70.4-21.5
	c-32.1,0-64.3,12.2-88.8,36.8l-64,64c-1.9,2-1.9,5.1,0,7.1l170.5,170.5c1,1,2.3,1.4,3.6,1.4c1.3,0,2.6-0.5,3.6-1.4l64-64
	c43.2-43.3,48.3-110.3,15.3-159.1l47.8-47.8C512.6,33.2,512.6,30,510.6,28.1L510.6,28.1z M417.3,211.9l-37.3,37.3L262.8,131.9
	l37.3-37.3c15.6-15.6,36.5-24.3,58.6-24.3c22.2,0,42.9,8.6,58.6,24.3c15.6,15.6,24.3,36.5,24.3,58.6S433,196.2,417.3,211.9
	L417.3,211.9z M298,277.8c-2-1.9-5.1-1.9-7.1,0l-41.8,41.8l-56.7-56.7l41.9-41.9c1.9-1.9,1.9-5.1,0-7.1l-22.8-22.8
	c-2-1.9-5.1-1.9-7.1,0L162.4,233l-27-27c-0.9-0.9-2.2-1.5-3.6-1.4c-1.3,0-2.6,0.5-3.6,1.4l-63.9,64
	c-43.2,43.3-48.3,110.3-15.3,159.1L1.4,476.8c-1.9,2-1.9,5.1,0,7.1L28,510.6c1,1,2.3,1.4,3.6,1.4c1.3,0,2.6-0.5,3.6-1.4l47.8-47.8
	c21.2,14.4,45.8,21.5,70.4,21.5c32.1,0,64.3-12.2,88.8-36.8l64-64c1.9-1.9,1.9-5.1,0-7.1l-27-27l41.9-41.9c1.9-1.9,1.9-5.1,0-7.1
	L298,277.8L298,277.8z M211.8,417.4c-15.5,15.6-36.6,24.3-58.6,24.3c-22.2,0-42.9-8.6-58.6-24.3c-15.6-15.5-24.3-36.6-24.3-58.6
	c0-22.2,8.6-42.9,24.3-58.6l37.3-37.3l117.3,117.3L211.8,417.4L211.8,417.4z"
    />
  </Icon>
);
