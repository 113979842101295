import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AzureADRoleIcon = props => (
  <Icon viewBox="0 0 18 18" w="full" h="full" color="inherit" {...props}>
    <defs>
      <linearGradient
        id="a8c422b3-2be3-408c-9302-3a4621bbfd64"
        x1="-609.26"
        y1="-224.13"
        x2="-609.26"
        y2="-211.218"
        gradientTransform="matrix(1, 0, 0, -1, 617.126, -205.758)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5e9624" />
        <stop offset="1" stopColor="#b4ec36" />
      </linearGradient>
      <linearGradient
        id="b2f19e06-38a1-4f8e-8f30-7617c1e8ec45"
        x1="-609.223"
        y1="-214.978"
        x2="-609.223"
        y2="-203.218"
        gradientTransform="matrix(1, 0, 0, -1, 617.126, -205.758)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5e9624" />
        <stop offset="1" stopColor="#b4ec36" />
      </linearGradient>
    </defs>
    <g id="b6095ebf-3fbe-499c-8cb7-cebcf1848c87">
      <g>
        <path
          d="M14.239,17.221a1.363,1.363,0,0,0,1.383-1.341v-.031a.943.943,0,0,0,0-.168C15.077,11.335,12.606,7.8,7.872,7.8s-7.246,3-7.76,7.9a1.4,1.4,0,0,0,1.247,1.529Z"
          fill="url(#a8c422b3-2be3-408c-9302-3a4621bbfd64)"
        />
        <path
          d="M7.956,8.822a4.364,4.364,0,0,1-2.367-.691l2.283,6.105L10.2,8.173A4.4,4.4,0,0,1,7.956,8.822Z"
          fill="#fff"
          opacity="0.8"
        />
        <circle
          cx="7.904"
          cy="4.466"
          r="4.367"
          fill="url(#b2f19e06-38a1-4f8e-8f30-7617c1e8ec45)"
        />
        <polygon
          points="17.894 11.231 17.894 15.671 14.082 17.901 14.082 13.461 17.894 11.231"
          fill="#ef7100"
        />
        <polygon
          points="17.894 11.231 14.082 13.461 10.27 11.231 14.082 9 17.894 11.231"
          fill="#f78d1e"
        />
        <polygon
          points="14.082 13.461 14.082 17.901 10.27 15.671 10.27 11.231 14.082 13.461"
          fill="#faa21d"
        />
        <polygon
          points="10.27 15.671 14.082 13.461 14.082 17.901 10.27 15.671"
          fill="#ffb34d"
        />
        <polygon
          points="17.894 15.671 14.082 13.461 14.082 17.901 17.894 15.671"
          fill="#f78d1e"
        />
      </g>
    </g>
  </Icon>
);
