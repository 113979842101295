import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { TopFindings } from 'containers/Dashboard/utils/TopFindings';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const InfrastructureFindingsToPrioritize: React.FC = () => {
  const { infrastructurePostureFindingsCategorySummary } = useSelector(
    selectCloudPostureOverview,
  );

  return (
    <TopFindings
      categoryClass={AlertCategoryClass.CloudPosture}
      stringLength={12}
      accounts={infrastructurePostureFindingsCategorySummary.data}
      tooltip={{
        header: 'You can enrich key findings…',
        tooltip: (
          <Stack>
            <Box>
              Ariksa can uncover deeper insights such as anomalous user behavior
              and privilege escalation attempts, if you enable user activity
              logs (such as AWS CloudTrail) and store them for analysis
            </Box>
            <Box>
              We prioritize these findings based on severity, context and risk
              to your environment
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
    />
  );
};
