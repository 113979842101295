import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  LaunchTemplateName,
  LaunchTemplateID,
  MinimumMaximumSize,
  DesiredCapacity,
  DefaultCoolDown,
  AsgLoadBalancers,
  VPCZoneID,
  TargetGroupARNs,
  HealthCheckType,
  HealthCheckGracePeriod,
  NewInstancesProtectedFromScale,
  ServiceLinkedRoleARN,
} from '../../../Components/MetadataField/MetaGridFields';

export const AutoScalingGroupMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const columns = [
    { header: 'Instance ID', accessor: 'InstanceId' },
    { header: 'Type', accessor: 'InstanceType', align: 'left' },
    { header: 'Life cycle state', accessor: 'LifecycleState', align: 'left' },
    {
      header: 'Protected from scale',
      accessor: 'ProtectedFromScaleIn',
      render: ({ value }) => (value ? 'Yes' : 'No'),
    },
  ];
  const suspendedProcessesColumns = [
    { header: 'Process Name', accessor: 'ProcessName' },
    {
      header: 'Suspension Reason',
      accessor: 'SuspensionReason',
      align: 'left',
    },
  ];
  const enabledMetricsColumns = [
    { header: 'Metric', accessor: 'Metric' },
    { header: 'Granularity', accessor: 'Granularity', align: 'left' },
  ];

  const instances = resource?.compute?.Instances;
  const suspendedProcesses = resource?.config?.SuspendedProcesses;
  const enabledMetrics = resource?.config?.EnabledMetrics;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields vpc="None" state="None" />
        {LaunchTemplateName(resource)}
        {LaunchTemplateID(resource)}
        {MinimumMaximumSize(resource)}
        {DesiredCapacity(resource)}
        {DefaultCoolDown(resource)}
        {AsgLoadBalancers(resource)}
        {VPCZoneID(resource)}
        {TargetGroupARNs(resource)}
        {HealthCheckType(resource)}
        {HealthCheckGracePeriod(resource)}
        {NewInstancesProtectedFromScale(resource)}
        {ServiceLinkedRoleARN(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={instances} />}
              iconType={''}
              label="Instances"
              totalCount={instances?.length}
              height="300px"
            />
            <MetaAccordionItem
              panel={
                <Table
                  columns={suspendedProcessesColumns}
                  data={suspendedProcesses}
                />
              }
              iconType={''}
              label="Suspended processes"
              totalCount={suspendedProcesses?.length}
              height="300px"
            />
            <MetaAccordionItem
              panel={
                <Table columns={enabledMetricsColumns} data={enabledMetrics} />
              }
              iconType={''}
              label="Enabled metrics"
              totalCount={enabledMetrics?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
