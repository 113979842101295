import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ComplianceEnforcedChecksIcon = props => (
  <Icon color="inherit" viewBox={'0 0 136 140'} h="full" w="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="968" y="369" width="136" height="140" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="969" y="370" width="134" height="139" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="969" y="370" width="134" height="139" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="969" y="370" width="134" height="139" />
      </clipPath>
      <clipPath id="clip4">
        <rect x="969" y="370" width="134" height="139" />
      </clipPath>
      <clipPath id="clip5">
        <rect x="969" y="370" width="134" height="134" />
      </clipPath>
      <clipPath id="clip6">
        <rect x="969" y="370" width="134" height="134" />
      </clipPath>
      <clipPath id="clip7">
        <rect x="969" y="370" width="134" height="134" />
      </clipPath>
      <clipPath id="clip8">
        <rect x="1032" y="442" width="62" height="62" />
      </clipPath>
      <clipPath id="clip9">
        <rect x="1032" y="442" width="62" height="62" />
      </clipPath>
      <clipPath id="clip10">
        <rect x="1032" y="442" width="62" height="62" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-968 -369)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <g clipPath="url(#clip4)">
              <g clipPath="url(#clip5)">
                <g clipPath="url(#clip6)">
                  <g clipPath="url(#clip7)">
                    <path
                      d="M122.223 248.582 133.349 248.582C134.884 248.582 136.13 247.337 136.13 245.801 136.13 244.265 134.884 243.02 133.349 243.02L122.223 243.02C120.687 243.02 119.442 244.265 119.442 245.801 119.442 247.337 120.687 248.582 122.223 248.582ZM185.978 244.73C185.836 244.393 185.633 244.088 185.377 243.833L152.005 210.46C151.75 210.205 151.445 210.001 151.107 209.86 150.771 209.719 150.408 209.644 150.036 209.644L113.879 209.644C104.667 209.654 97.2016 217.119 97.1912 226.332L97.1912 304.209C97.2016 313.421 104.667 320.887 113.879 320.897L169.506 320.897C178.718 320.887 186.184 313.421 186.194 304.209L186.194 245.801C186.194 245.429 186.118 245.067 185.978 244.73ZM152.818 219.139 176.698 243.02 160.233 243.02C156.139 243.016 152.821 239.699 152.818 235.605L152.818 219.139ZM180.631 304.209C180.624 310.35 175.647 315.326 169.506 315.334L113.879 315.334C107.738 315.326 102.762 310.35 102.754 304.209L102.754 226.332C102.762 220.191 107.738 215.214 113.879 215.206L147.255 215.206 147.255 235.605C147.264 242.768 153.069 248.573 160.233 248.582L180.631 248.582 180.631 304.209ZM119.442 268.052C119.442 269.588 120.687 270.833 122.223 270.833L161.162 270.833C162.698 270.833 163.943 269.588 163.943 268.052 163.943 266.516 162.698 265.27 161.162 265.27L122.223 265.27C120.687 265.27 119.442 266.516 119.442 268.052ZM161.162 287.521 122.223 287.521C120.687 287.521 119.442 288.767 119.442 290.303 119.442 291.838 120.687 293.084 122.223 293.084L161.162 293.084C162.698 293.084 163.943 291.838 163.943 290.303 163.943 288.767 162.698 287.521 161.162 287.521Z"
                      fill="currentColor"
                      fillRule="nonzero"
                      fillOpacity="1"
                      transform="matrix(1 0 0 1.00027 894.556 171.429)"
                    />
                  </g>
                </g>
              </g>
              <path
                d="M132.015 301.5C132.015 281.628 148.124 265.519 167.996 265.519 187.868 265.519 203.978 281.628 203.978 301.5 203.978 321.372 187.868 337.481 167.996 337.481 148.124 337.481 132.015 321.372 132.015 301.5Z"
                fill="#FFFFFF"
                fillRule="evenodd"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00027 894.556 171.429)"
              />
              <g clipPath="url(#clip8)">
                <g clipPath="url(#clip9)">
                  <g clipPath="url(#clip10)">
                    <path
                      d="M169.821 276.892C174.384 281.888 180.673 284.966 187.417 285.505 188.671 285.622 189.625 286.679 189.616 287.937L189.616 294.076C189.616 307.42 181.129 319.628 169.345 327.181 168.535 327.701 167.497 327.701 166.688 327.181 154.903 319.628 146.416 307.42 146.416 294.076L146.416 287.937C146.407 286.679 147.362 285.622 148.615 285.505 155.36 284.966 161.649 281.888 166.212 276.892 167.072 275.895 168.577 275.784 169.574 276.644 169.662 276.72 169.745 276.803 169.821 276.892Z"
                      fill="currentColor"
                      fillRule="nonzero"
                      fillOpacity="1"
                      transform="matrix(1 0 0 1.00027 894.556 171.429)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
