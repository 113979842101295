import React, { FC, useEffect, useMemo, useState } from 'react';

import { HStack, Square, Stack } from '@chakra-ui/react';
import { each, map } from 'lodash';

import { Heading4 } from 'components/DataDisplay';
import { Form } from 'components/DataEntry';
import { FilterIcon } from 'components/Icons';
import { IModalProps, Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { ResourceContext } from 'containers/Visibility/Components/Filter/ContextFilterItem';
import { randomString } from 'utils/string';

interface IContextFilterModal extends IModalProps {
  nodes?: any[];
  resources?: string[];
  onSubmit?(data);
}

export const ContextFilterModal: FC<IContextFilterModal> = props => {
  const { isOpen, onClose, onSubmit, nodes = [], resources } = props;
  const [selectedResourceType, setSelectedResourceType] = useState<
    Record<string, any>
  >({});
  const [selectedContexts, setSelectedContexts] = useState<
    Record<string, any>[]
  >([]);
  const [resourceTypes, setResourceTypes] = useState<string[]>([]);
  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);
  const [resourceTypeAriksaContexts, setResourceTypeAriksaContexts] = useState<
    Record<string, ResourceContext[]>
  >({});
  const { getCloudNativeName } = useResourceType();

  const [resourceTypeCloudContexts, setResourceTypeCloudContexts] = useState<
    Record<string, ResourceContext[]>
  >({});

  useEffect(() => {
    const types = new Set<string>();

    const resourceTypeIds: Record<
      string,
      Record<string, ResourceContext[]>
    > = {};
    const resourceTypeAriksaContextMap: Record<string, Set<string>> = {};
    const resourceTypeCloudContextMap: Record<string, Set<string>> = {};
    resources?.forEach(native_name => {
      types.add(native_name);
      resourceTypeIds[native_name] = resourceTypeIds[native_name] ?? {};
      resourceTypeAriksaContextMap[native_name] =
        resourceTypeAriksaContextMap[native_name] ?? new Set();
      resourceTypeCloudContextMap[native_name] =
        resourceTypeCloudContextMap[native_name] ?? new Set();
    });
    nodes.forEach(r => {
      if (!types.has(r.native_name)) return;

      r.contexts?.forEach(e => {
        if (e.values?.length) {
          if (e.tag_type === 'Ariksa') {
            resourceTypeAriksaContextMap[r.native_name].add(
              `${e.name} = ${e.values[0]}`,
            );
          } else {
            resourceTypeCloudContextMap[r.native_name].add(
              `${e.name} = ${e.values[0]}`,
            );
          }
        }
      });
    });

    const ariksaContexts: any = {};
    each(resourceTypeAriksaContextMap, (contexts, resource) => {
      ariksaContexts[resource] = Array.from(contexts).map(v => {
        const [name, value] = v.split(' = ');
        return { name, value };
      });
    });
    const cloudContexts: any = {};
    each(resourceTypeCloudContextMap, (contexts, resource) => {
      cloudContexts[resource] = Array.from(contexts).map(v => {
        const [name, value] = v.split(' = ');
        return { name, value };
      });
    });

    setResourceTypes(
      Array.from(types).filter(t => {
        return ariksaContexts[t]?.length || cloudContexts[t]?.length;
      }),
    );

    setResourceTypeAriksaContexts(ariksaContexts);
    setResourceTypeCloudContexts(cloudContexts);
  }, [nodes, resources]);

  //set resource type options
  useEffect(() => {
    const options = map(resourceTypes, o => ({
      label: getCloudNativeName(o),
      value: o,
    }));
    setResourceTypeOptions(options);
    setSelectedResourceType(options?.[0]);
  }, [resourceTypes, getCloudNativeName]);

  const resourceContextsOptions = useMemo(() => {
    const getLabel = (name, value) =>
      name + (!!value && value !== 'True' ? ` = ${value}` : '');
    return [
      {
        label: 'Ariksa Context',
        options: resourceTypeAriksaContexts[selectedResourceType?.value]?.map(
          f => ({
            label: getLabel(f.name, f.value),
            value: f,
          }),
        ),
      },
      {
        label: 'Cloud Tags',
        options: resourceTypeCloudContexts[selectedResourceType?.value]?.map(
          f => ({
            label: getLabel(f.name, f.value),
            value: f,
          }),
        ),
      },
    ];
  }, [
    resourceTypeAriksaContexts,
    resourceTypeCloudContexts,
    selectedResourceType,
  ]);

  const handleSubmit = () => {
    if (!selectedResourceType?.value || !selectedContexts?.length) {
      onClose();
      return;
    }
    onSubmit?.({
      key: randomString(),
      resourceType: selectedResourceType?.value,
      context: map(selectedContexts, o => o?.value),
    });
    onClose();
  };

  const handleReset = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <FilterIcon />
          </Square>
          <Heading4>Add Filters</Heading4>
        </HStack>
      }
      body={
        <Stack>
          <Form
            schema={{
              name: {
                type: 'react-select',
                label: 'Resource type',
                options: resourceTypeOptions,
                value: selectedResourceType,
                onChange: setSelectedResourceType,
                isRequired: true,
              },
              context: {
                type: 'react-select',
                label: 'Context',
                options: resourceContextsOptions,
                isMulti: true,
                value: selectedContexts,
                onChange: setSelectedContexts,
              },
            }}
            buttonOptions={{
              submit: {
                name: '+ Filter',
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: handleReset,
              },
            }}
            handleSubmit={handleSubmit}
          />
        </Stack>
      }
    />
  );
};
