import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AutoScalingIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M11.5,12H2.4993896C2.2234497,12.0001831,1.9998169,12.223999,2,
    12.5v9.0005493C2.0001831,21.7765503,2.223999,22.0001831,2.5,22h9.0006104C11.7765503,
    21.9998169,12.0001831,21.776001,12,21.5v-9.0006104C11.9998169,12.2234497,11.776001,
    11.9998169,11.5,12z M11,21H3v-8h8V21z M21.5,2h-7C14.223877,2,14,2.223877,14,2.5S14.223877,
    3,14.5,3h5.7930908l-7.1526489,7.1526489c-0.1871948,0.1937256-0.1871948,0.5009766,
    0,0.6947021c0.1918335,0.1986084,0.5083618,0.2041016,0.7069702,0.0122681L21,
    3.7070312v5.7935181C21.0001831,9.7765503,21.223999,10.0001831,21.5,10h0.0006104C21.7765503,
    9.9998169,22.0001831,9.776001,22,9.5V2.4993896C21.9998169,2.2234497,21.776001,1.9998169,21.5,2z"
    />
  </Icon>
);
