import React from 'react';

import { Icon } from '@chakra-ui/react';

export const TagIcon = props => (
  <Icon viewBox="0 0 100 100" w="full" h="full" color="inherit" {...props}>
    <path d="M84 14H56.6c-.5 0-1 .2-1.4.6L14.6 55.1c-.8.8-.8 2.1 0 2.8L42 85.4c.8.8 2 .8 2.8 0l40.5-40.5c.4-.4.6-.9.6-1.4V16c.1-1.1-.8-2-1.9-2zm-2 28.6L43.5 81.2 18.8 56.6 57.4 18H82v24.6zM68 23.9c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm2.8 10.9c-1.5 1.5-4.2 1.5-5.7 0-1.6-1.6-1.6-4.1 0-5.7 1.6-1.6 4.1-1.6 5.7 0 1.6 1.6 1.6 4.1 0 5.7z" />
    <path d="M664-1070V614h-1784v-1684H664m8-8h-1800V622H672v-1700z" />
  </Icon>
);
