import React, { ReactNode } from 'react';

import { Box, Center, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getIcon } from 'components/Icons/Components';

export interface IdentityCardProps {
  label: string;
  count: ReactNode;
  isLoading?: boolean;
  iconType?: string;
  iconTooltip?: ReactNode;
  iconTooltipW?: number;
  borderColor?: string;
  iconBgColor?: string;
  iconPaddingTop?: string;
  iconColor?: string;
  onClick?();
  bg?: string;
  jit?: boolean;
  cardW?: string | number;
  cardH?: string | number;
  iconPadding?: string | number;
  iconBoxSize?: string | number;
  contentSpacing?: string | number;
  labelFontSize?: string | number;
  countFontSize?: string | number;
}

export const IdentityCard: React.FC<IdentityCardProps> = props => {
  const {
    jit,
    iconBgColor,
    borderColor,
    iconColor,
    iconTooltip,
    label,
    iconType,
    bg,
    onClick,
    count,
    isLoading,
    cardW = '7rem',
    cardH = '7.5rem',
    iconPadding = 1.5,
    iconBoxSize = 8,
    contentSpacing = 0,
    labelFontSize = '13px',
    countFontSize,
    iconTooltipW,
  } = props;

  return (
    <Center w="full">
      <Stack spacing={0}>
        <Center>
          <Box
            boxSize={iconBoxSize}
            position="absolute"
            borderRadius="full"
            bg="white"
          />
        </Center>
        <Center w="full" position="relative">
          <Box
            boxSize={iconBoxSize}
            bg={jit ? iconBgColor : iconBgColor ?? 'white'}
            color={jit ? borderColor : iconColor ?? 'primary'}
            p={iconPadding}
            position="absolute"
            borderRadius="full"
            pt={props.iconPaddingTop ?? 1.5}
          >
            <CustomTooltip label={iconTooltip ?? label} width={iconTooltipW}>
              <Center>{iconType && getIcon(iconType)}</Center>
            </CustomTooltip>
          </Box>
        </Center>
        <Center
          w={cardW}
          h={cardH}
          border="1px solid"
          borderColor={
            jit ? borderColor : borderColor ?? customTheme.colors.gray['200']
          }
          borderRadius={6}
          bg={jit ? 'white' : bg}
          fontSize="md"
          onClick={onClick}
          cursor={onClick ? 'pointer' : 'default'}
          overflow="visible"
        >
          <Stack spacing={contentSpacing}>
            <Center fontSize={labelFontSize} textAlign="center">
              {label}
            </Center>
            <Center fontWeight={600} fontSize={countFontSize}>
              {isLoading ? <CustomSpinner color="white" /> : count}
            </Center>
          </Stack>
        </Center>
      </Stack>
    </Center>
  );
};
