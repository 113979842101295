import React, { useEffect, useReducer } from 'react';

import { Level, RuleReadWithMetadata } from '@ariksa/compliance-policies/api';
import { HStack, Box, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import {
  getIcon,
  renderSeverityBar,
  StackedHeader,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { CancelButton, PrimaryButton, Select } from 'components/DataEntry';
import { ComplianceCheckPassedIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

interface Props {
  policy: RuleReadWithMetadata;
  isOpen: boolean;
  onClose();
  getAllPolicies();
}

export const AddPolicyConfirmationModal: React.FC<Props> = props => {
  const { isOpen, onClose, policy, getAllPolicies } = props;
  const {
    blueprint,
    updateRules,
    complianceStandardsOfPolicy,
    allComplianceControls,
    allComplianceSubControls,
  } = useSelector(selectBlueprints);
  const dispatch = useDispatch();
  const { getCloudAgnosticName, getResourceAlias } = useResourceType();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      controlOptions: [],
      subControlOptions: [],
      selectedControl: {},
      selectedSubControl: {},
    },
  );

  useEffect(() => {
    if (!blueprint.data.compliance)
      dispatch(
        actions.getComplianceStandardOfPolicy({
          q: {
            getRuleComplianceStandardRequest: { rules: [policy.config_id] },
          },
        }),
      );
  }, [dispatch, policy, blueprint.data]);

  //get compliance controls
  useEffect(() => {
    if (!!blueprint.data.compliance) {
      dispatch(
        actions.getAllComplianceControl({
          q: { standard: blueprint.data.compliance, level: Level.Control },
        }),
      );
    }
  }, [blueprint.data, dispatch]);

  //set control options
  useEffect(() => {
    const options: Record<string, any>[] = map(
      allComplianceControls.data,
      o => ({ label: o.control, value: o.control, data: o }),
    );
    updateState({ controlOptions: options, selectedControl: options[0] });
  }, [allComplianceControls.data]);

  //get compliance sub-controls
  useEffect(() => {
    if (!!blueprint.data.compliance) {
      dispatch(
        actions.getAllComplianceSubControl({
          q: {
            standard: blueprint.data.compliance,
            level: Level.SubControl,
            control: state.selectedControl?.value,
          },
        }),
      );
    }
  }, [blueprint.data, dispatch, state.selectedControl]);

  //set sub-control options
  useEffect(() => {
    const options: Record<string, any>[] = map(
      allComplianceSubControls.data,
      o => ({ label: o.sub_control, value: o.sub_control, data: o }),
    );
    updateState({ subControlOptions: options, selectedSubControl: options[0] });
  }, [allComplianceSubControls.data]);

  const handleIncludeRule = () => {
    if (blueprint.data.id)
      dispatch(
        actions.includeRules({
          q: {
            blueprintId: blueprint.data?.id!,
            ruleInstanceMultiCreate: {
              rules: [{ rule_id: policy?.id! }],
            },
          },
          onSuccess: () => {
            onClose();
            getAllPolicies();
          },
        }),
      );
  };

  const renderDescription = value => (
    <Box
      h={24}
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      borderRadius={6}
      px={3}
      py={1}
    >
      {value}
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper="Add Policy"
          lower={blueprint.data.name}
          icon={<ComplianceCheckPassedIcon />}
        />
      }
      styles={{
        modal: { size: '2xl' },
      }}
      footer={
        <HStack justifyContent="space-between" w="full">
          <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
          <PrimaryButton
            onClick={handleIncludeRule}
            isLoading={updateRules?.isLoading}
          >
            Add
          </PrimaryButton>
        </HStack>
      }
      body={
        <Stack spacing={5}>
          <DetailsField
            label="Policy Description"
            isInline={false}
            value={renderDescription(policy.alert_description)}
          />
          <DetailsField
            label="Applies to Resource Type"
            value={
              getCloudAgnosticName(policy.resource_type!) +
              ' / ' +
              getResourceAlias(policy.resource_type!)
            }
          />
          <DetailsField
            label="Severity"
            value={renderSeverityBar({
              value: policy.severity,
              reversed: true,
            })}
          />
          {!blueprint.data.compliance && (
            <DetailsField
              label="Applicable Compliance Standards"
              align="center"
              value={
                complianceStandardsOfPolicy.isLoading ? (
                  <CustomSpinner />
                ) : (
                  <HStack>
                    {map(complianceStandardsOfPolicy.data, o => (
                      <Box boxSize={5}>{getIcon(o)}</Box>
                    ))}
                  </HStack>
                )
              }
            />
          )}
          {!!blueprint.data.compliance && (
            <HStack w="full">
              <DetailsField
                label="Control"
                isInline={false}
                width="full"
                value={
                  <Box w="full">
                    <Select
                      options={state.controlOptions}
                      value={state.selectedControl}
                      onChange={s => updateState({ selectedControl: s })}
                      isLoading={allComplianceControls.isLoading}
                    />
                  </Box>
                }
              />
              <DetailsField
                label="Sub-Control"
                isInline={false}
                width="full"
                value={
                  <Box w="full">
                    <Select
                      options={state.subControlOptions}
                      value={state.selectedSubControl}
                      onChange={s => updateState({ selectedSubControl: s })}
                      isLoading={allComplianceSubControls.isLoading}
                    />
                  </Box>
                }
              />
            </HStack>
          )}
          {!!blueprint.data.compliance && (
            <DetailsField
              label="Sub-Control Description"
              isInline={false}
              value={renderDescription(
                state.selectedSubControl?.data?.sub_control_summary,
              )}
            />
          )}
        </Stack>
      }
    />
  );
};
