import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions } from '../slice';

import { RemediationWizard } from './RemediationWizard';

interface Props {}

export const EditRemediation: FC<Props> = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getRemediation({ q: { remediationId: params?.id } }));
    }
  }, [params, dispatch]);

  return <RemediationWizard actionType="Update" />;
};
