import { PayloadAction } from '@reduxjs/toolkit';

import {
  OrganizationServiceDeleteRequest,
  OrganizationServiceListRequest,
  OrganizationServiceListResponse,
  OrganizationServiceUpdateRequest,
  OrganizationServiceUpdateResponse,
} from 'api/auth/api.pb';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import {
  apiCallStateInitialState,
  apiCallStatusInitialValue,
} from 'api/initial_values';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Organizations container
export const initialState: ContainerState = {
  organizations: apiCallStateInitialState([]),
  enableOrganization: apiCallStatusInitialValue,
  deleteOrganization: apiCallStatusInitialValue,
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    // Enable or Disable Organization
    changeEnableOrganization(
      state,
      action: PayloadAction<OrganizationServiceUpdateRequest>,
    ) {
      onApiCall(state.enableOrganization);
    },
    changeEnableOrganizationSuccess(
      state,
      action: PayloadAction<OrganizationServiceUpdateRequest>,
    ) {
      onApiCallSuccess(state.enableOrganization);
    },
    changeEnableOrganizationError(
      state,
      action: PayloadAction<OrganizationServiceUpdateResponse>,
    ) {
      onApiCallError(state.enableOrganization, action.payload);
    },

    // Load Organization Details
    getOrganizations(
      state,
      action: PayloadAction<OrganizationServiceListRequest>,
    ) {
      onApiCall(state.organizations);
    },
    getOrganizationsSuccess(
      state,
      action: PayloadAction<OrganizationServiceListResponse>,
    ) {
      // console.log(action.payload);
      // const organizations = action.payload.organizations ?? [];
      // organizations.sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);

      state.organizations.data = action.payload.organizations ?? [];
      onApiCallSuccess(state.organizations);
    },
    getOrganizationsError(state, action: PayloadAction<any>) {
      onApiCallError(state.organizations, action.payload);
    },

    //Delete Organization

    deleteOrganization(
      state,
      action: PayloadAction<OrganizationServiceDeleteRequest>,
    ) {
      onApiCall(state.deleteOrganization);
    },
    deleteOrganizationSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.deleteOrganization);
    },
    deleteOrganizationError(state, action: PayloadAction<any>) {
      onApiCallError(state.deleteOrganization, action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = organizationsSlice;
