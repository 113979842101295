import { Summary } from '@ariksa/compliance-policies';
import {
  BlueprintApiAddConfigRequest,
  BlueprintApiAddRulesRequest,
  BlueprintApiCreateBlueprintRequest,
  BlueprintApiDeleteBlueprintRequest,
  BlueprintApiDeleteConfigRequest,
  BlueprintApiDeleteRulesRequest,
  BlueprintApiGetBlueprintByIdRequest,
  BlueprintApiGetBlueprintsRequest,
  BlueprintApiRemoveExceptionRequest,
  BlueprintApiUpdateBlueprintRequest,
  BlueprintApiUpdateConfigRequest,
  BlueprintConfigRead,
  BlueprintExceptionRead,
  BlueprintFull,
  CommandsApiGetRemediationByPolicyRequest,
  ComplianceApiGetComplianceSummaryByComplianceRuleRequest,
  ComplianceApiGetComplianceSummaryByResourcesRequest,
  ComplianceApiGetComplianceSummaryByRuleRequest,
  ComplianceApiGetSummaryRequest,
  ComplianceStandardsApiListStandardControlsRequest,
  PageBluePrintsRead,
  PageResourceCompliance,
  PageRuleCompliance,
  PageRuleReadWithMetadata,
  PageStandardRuleCompliance,
  Remediation,
  RemediationApiAutoRemediationRequest,
  RuleRead,
  RulesApiGetAllComplianceStandardRequest,
  RulesApiGetAllRulesRequest,
  RulesApiGetAllRulesWithoutPaginationRequest,
  StandardRead,
} from '@ariksa/compliance-policies/api';
import {
  AlertWorkflowApiGetWorkflowRequest,
  AlertWorkflowResponse,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ComplianceService, NotificationService } from 'api/index';

import { actions } from './slice';

export function* blueprintsSaga() {
  yield takeLatestAction(actions.getBlueprints, doGetBlueprints);
  yield takeLatestAction(actions.getBlueprintByID, doGetBlueprintByID);
  yield takeLatestAction(actions.createBlueprint, doCreateBlueprint);
  yield takeLatestAction(actions.updateBlueprint, doUpdateBlueprint);
  yield takeLatestAction(actions.deleteBlueprint, doDeleteBlueprint);
  yield takeLatestAction(actions.getAllRules, doGetAllRules);
  yield takeLatestAction(
    actions.getComplianceStandardOfPolicy,
    doGetComplianceStandardsOfPolicy,
  );
  yield takeLatestAction(
    actions.getAllBlueprintPolicies,
    doGetAllBlueprintPolicies,
  );
  yield takeLatestAction(actions.includeRules, doIncludeRules);
  yield takeLatestAction(actions.excludeRules, doExcludeRules);
  yield takeLatestAction(actions.attachEnvironment, doAttachEnvironment);
  yield takeLatestAction(actions.detachEnvironment, doDetachEnvironment);
  yield takeLatestAction(actions.updateAlertingConfig, doUpdateAlertingConfig);
  yield takeLatestAction(actions.getAttachedWorkflows, doGetAttachedWorkflows);
  yield takeLatestAction(actions.getBlueprintSummary, doGetBlueprintSummary);
  yield takeLatestAction(actions.getBlueprintPolicies, doGetBlueprintPolicies);
  yield takeLatestAction(actions.removeException, doRemoveException);
  yield takeLatestAction(
    actions.getComplianceControls,
    doGetComplianceControls,
  );
  yield takeLatestAction(
    actions.getPoliciesUnderSubControls,
    doGetPoliciesUnderSubControls,
  );
  yield takeLatestAction(
    actions.getResourcesUnderSubControlPolicy,
    doGetResourcesUnderSubControlPolicy,
  );
  yield takeLatestAction(
    actions.getAllComplianceControl,
    doGetAllComplianceControls,
  );
  yield takeLatestAction(
    actions.getAllComplianceSubControl,
    doGetAllComplianceSubControls,
  );
  yield takeLatestAction(actions.getAvailableRemediation, doGetRemediation);
  yield takeLatestAction(actions.autoRemediate, doAutoRemediate);
}

//get blueprints
export function* doGetBlueprints(
  ctx: QueryContext<PageBluePrintsRead, BlueprintApiGetBlueprintsRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.getBlueprints(ctx.params),
    { errorMsg: 'Failed to get blueprints!' /*cacheKey: 'blueprints'*/ },
  );
}

//get blueprints
export function* doGetBlueprintByID(
  ctx: QueryContext<BlueprintFull, BlueprintApiGetBlueprintByIdRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.getBlueprintById(ctx.params),
    { errorMsg: 'Failed to get blueprint!' },
  );
}

//get blueprint policies
export function* doGetBlueprintPolicies(
  ctx: QueryContext<PageRuleReadWithMetadata, RulesApiGetAllRulesRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Rules.getAllRules(ctx.params),
    { errorMsg: 'Failed to get blueprint policies!' },
  );
}
export function* doGetAllBlueprintPolicies(
  ctx: QueryContext<RuleRead[], RulesApiGetAllRulesWithoutPaginationRequest>,
) {
  yield call(
    ctx.fetch,
    async () =>
      ComplianceService.Rules.getAllRulesWithoutPagination(ctx.params), //getAllRulesWithoutPagination
    { errorMsg: 'Failed to get rules!' },
  );
}
export function* doGetAllRules(
  ctx: QueryContext<RuleRead[], RulesApiGetAllRulesWithoutPaginationRequest>,
) {
  yield call(
    ctx.fetch,
    async () =>
      ComplianceService.Rules.getAllRulesWithoutPagination(ctx.params), //getAllRulesWithoutPagination
    { errorMsg: 'Failed to get rules!' },
  );
}

//create blueprint
function* doCreateBlueprint(
  ctx: QueryContext<any, BlueprintApiCreateBlueprintRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.createBlueprint(ctx.params),
    {
      errorMsg: 'Failed to create blueprint!',
      successMsg: 'Blueprint created successfully!',
    },
  );
}

//update blueprint
export function* doUpdateBlueprint(
  ctx: QueryContext<any, BlueprintApiUpdateBlueprintRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.updateBlueprint(ctx.params),
    {
      errorMsg: 'Failed to update blueprint!',
      successMsg: 'Blueprint updated successfully!',
    },
  );
}

//delete blueprint
export function* doDeleteBlueprint(
  ctx: QueryContext<any, BlueprintApiDeleteBlueprintRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.deleteBlueprint(ctx.params),
    {
      errorMsg: 'Failed to delete blueprint!',
      successMsg: 'Blueprint deleted successfully!',
    },
  );
}

//include rules
export function* doIncludeRules(
  ctx: QueryContext<any, BlueprintApiAddRulesRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.addRules(ctx.params),
    {
      errorMsg: 'Failed to include rules!',
      successMsg: 'Successfully included rules!',
    },
  );
}

//exclude rules
export function* doExcludeRules(
  ctx: QueryContext<any, BlueprintApiDeleteRulesRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Blueprint.deleteRules(ctx.params),
    {
      errorMsg: 'Failed to exclude rules!',
      successMsg: 'Successfully excluded rules!',
    },
  );
}

function* doAttachEnvironment(
  ctx: QueryContext<any, BlueprintApiAddConfigRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.addConfig(ctx.params),
    {
      errorMsg: 'Failed to attach environment to blueprint!',
      successMsg: 'Successfully attached environment to blueprint!',
    },
  );
}

function* doDetachEnvironment(
  ctx: QueryContext<
    BlueprintApiDeleteConfigRequest,
    BlueprintApiDeleteConfigRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.deleteConfig(ctx.params),
    {
      errorMsg: 'Failed to detach environment!',
      successMsg: 'Environment detached successfully!',
    },
  );
}

function* doUpdateAlertingConfig(
  ctx: QueryContext<BlueprintConfigRead, BlueprintApiUpdateConfigRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.updateConfig(ctx.params),
    {
      errorMsg: 'Failed to update alerting config!',
      successMsg: 'Successfully updated alerting config!',
    },
  );
}

function* doRemoveException(
  ctx: QueryContext<BlueprintExceptionRead, BlueprintApiRemoveExceptionRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.removeException(ctx.params),
    {
      errorMsg: 'Failed to remove exception!',
      successMsg: 'Successfully removed exception!',
    },
  );
}

function* doGetAttachedWorkflows(
  ctx: QueryContext<
    AlertWorkflowResponse[],
    AlertWorkflowApiGetWorkflowRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertWorkflow.getWorkflow(ctx.params),
    { errorMsg: 'Failed to get attached workflows!' },
  );
}

export function* doGetBlueprintSummary(
  ctx: QueryContext<Summary, ComplianceApiGetSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Compliance.getSummary(ctx.params),
    { errorMsg: 'Failed to get blueprint summary!' },
  );
}

export function* doGetComplianceControls(
  ctx: QueryContext<
    PageStandardRuleCompliance,
    ComplianceApiGetComplianceSummaryByComplianceRuleRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.Compliance.getComplianceSummaryByComplianceRule(
        ctx.params,
      ),
    { errorMsg: 'Failed to get compliance controls!' },
  );
}

export function* doGetPoliciesUnderSubControls(
  ctx: QueryContext<
    PageRuleCompliance,
    ComplianceApiGetComplianceSummaryByRuleRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Compliance.getComplianceSummaryByRule(ctx.params),
    { errorMsg: 'Failed to get policies!' },
  );
}

export function* doGetResourcesUnderSubControlPolicy(
  ctx: QueryContext<
    PageResourceCompliance,
    ComplianceApiGetComplianceSummaryByResourcesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.Compliance.getComplianceSummaryByResources(ctx.params),
    { errorMsg: 'Failed to get policies!' },
  );
}

export function* doGetComplianceStandardsOfPolicy(
  ctx: QueryContext<
    Record<string, string[]>,
    RulesApiGetAllComplianceStandardRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Rules.getAllComplianceStandard(ctx.params),
    { errorMsg: 'Failed to get compliance standards!' },
  );
}

export function* doGetAllComplianceControls(
  ctx: QueryContext<
    StandardRead[],
    ComplianceStandardsApiListStandardControlsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.ComplianceStandards.listStandardControls(ctx.params),
    { errorMsg: 'Failed to get compliance controls!' },
  );
}

export function* doGetAllComplianceSubControls(
  ctx: QueryContext<
    StandardRead[],
    ComplianceStandardsApiListStandardControlsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.ComplianceStandards.listStandardControls(ctx.params),
    { errorMsg: 'Failed to get compliance sub-controls!' },
  );
}

//get remediation for policy
export function* doGetRemediation(
  ctx: QueryContext<Remediation[], CommandsApiGetRemediationByPolicyRequest>,
) {
  yield call(
    ctx.fetch,
    async () =>
      ComplianceService.RemediationCommands.getRemediationByPolicy(ctx.params),
    { errorMsg: 'Failed to get remediation!' },
  );
}

//auto remediation for policy
export function* doAutoRemediate(
  ctx: QueryContext<any, RemediationApiAutoRemediationRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Remediation.autoRemediation(ctx.params),
    { errorMsg: 'Failed to perform auto remediation!' },
  );
}
