import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  IdentityPolicies,
  NumberOfRoles,
  NumberOfUsers,
  ParentOrganization,
  ResourcePolicies,
  TagPolicies,
  NumberOfGroups,
  OrgUnitAccounts,
  OrgUnits,
  Name,
  AccountID,
  CloudID,
  ARN,
  CreatedAt,
  Owner,
  Region,
} from '../../Components/MetadataField/MetaGridFields';

export const OrgUnitMetadata: FC = () => {
  const { resourceInsight, resourceDetails } = useActiveResourceContext();
  const { data: insight } = resourceInsight;
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountID(insight)}
      {Region(insight)}
      {CloudID(insight)}
      {ARN(insight)}
      {Tags(resource)}
      {CreatedAt(resource, 'dayjs')}
      {Owner(insight)}
      {ParentOrganization(insight)}
      {NumberOfUsers(insight)}
      {NumberOfGroups(insight)}
      {NumberOfRoles(insight)}
      {IdentityPolicies(insight)}
      {ResourcePolicies(insight)}
      {TagPolicies(insight)}
      {OrgUnitAccounts(resource)}
      {OrgUnits(resource)}
    </Grid>
  );
};
