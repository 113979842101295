/**
 *
 * MenuItem
 *
 */
import React, { BaseSyntheticEvent, ReactNode } from 'react';

import { Box, Center, BoxProps, Badge } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface Props {
  selected?: boolean;
  badge?: ReactNode;
  selectedStyles?: BoxProps;
  url: string;
  badgeTop?: any;
  badgeLeft?: any;
}

const MenuItem: React.FC<Props & BoxProps> = props => {
  const {
    selected,
    selectedStyles,
    url,
    badge,
    children,
    badgeTop,
    badgeLeft = '34px',
    ...rest
  } = props;
  return (
    <>
      <Link to={url}>
        <Box {...rest} {...(selected ? selectedStyles : {})}>
          {children}
        </Box>
      </Link>

      {badge && (
        <Box position="relative" top={badgeTop} left={badgeLeft}>
          <Badge fontSize="7px" borderRadius="full" bg="primary" color="white">
            {badge}
          </Badge>
        </Box>
      )}
    </>
  );
};
export default MenuItem;
