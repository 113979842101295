import { useEffect, useMemo } from 'react';

import { SearchResponseLevel } from '@ariksa/inventory-core';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';

import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export interface SearchResultData {
  data: {
    resources?: any[];
    relationships?: any[];
    edges?: any[];
    nodes?: any[];
    paths?: any[];
    source_items?: any[];
    ordering?: any[];
    category?: string;
  };
  isLoading: boolean;
  isSuccess: boolean;
}

export const useSearchResult = () => {
  const { isRedirected, redirectInfo } = useRedirectInfo();

  const {
    responseLevel,
    compactMap,
    fullMap,
    table,
    mapType,
    resourceRedirectMap,
    resourceRedirectTable,
    evidenceMap,
    dependencyMap,
    showDependencies,
  } = useSelector(selectSecurityGraphNext);

  const searchResult = useMemo((): SearchResultData => {
    if (isRedirected) {
      if (redirectInfo?.alert_id) {
        if (showDependencies) {
          console.log(
            'evidenceMap',
            evidenceMap.data.relationships,
            dependencyMap.data.relationships,
          );

          const edges = [
            ...(evidenceMap.data.relationships ?? []),
            ...(dependencyMap.data.relationships ?? []),
          ];
          return {
            data: {
              ...evidenceMap.data,
              nodes: uniqBy(
                [
                  ...(evidenceMap.data.nodes ?? []),
                  ...(dependencyMap.data.nodes ?? []),
                ],
                'identity',
              ),
              relationships: edges,
              edges: edges,
            },
            isLoading: evidenceMap.isLoading || dependencyMap.isLoading,
            isSuccess: evidenceMap.isSuccess && dependencyMap.isSuccess,
          };
        }

        return evidenceMap;
      }

      if (redirectInfo?.query) {
        return fullMap;
      }

      return resourceRedirectMap;
    }

    return responseLevel === SearchResponseLevel.Compact ? compactMap : fullMap;
  }, [
    isRedirected,
    responseLevel,
    compactMap,
    fullMap,
    redirectInfo,
    resourceRedirectMap,
    showDependencies,
    evidenceMap,
    dependencyMap,
  ]);

  const tableResult = useMemo(() => {
    return isRedirected ? resourceRedirectTable : table;
  }, [isRedirected, resourceRedirectTable, table]);

  return { responseLevel, searchResult, tableResult, mapType };
};
