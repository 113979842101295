import React, { useEffect, useMemo, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { identity, map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { getIconColor, getProviderAlias } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { RegionIcon } from 'components/Icons';
import {
  GeoLocationMap,
  IGeoLocation,
} from 'containers/Dashboard/DataSecurityOverview/Components/GeoMap';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';

export const ActiveDataRegions: React.FC = () => {
  const { summaryOfAllDataSources } = useSelector(selectDashboard);
  const [activeProviders, setActiveProviders] = useState<string[]>();
  const [cloudProviders, setCloudProviders] = useState<Record<string, any>>([]);

  useEffect(() => {
    setCloudProviders(
      orderBy(
        map(
          summaryOfAllDataSources.data?.regions_by_provider ?? [],
          (o, key) => ({
            cloud: key,
            regions: o,
            name: getProviderAlias(key),
          }),
        ),
        ['cloud'],
      ),
    );
    setActiveProviders(
      Object.keys(summaryOfAllDataSources.data?.regions_by_provider ?? {}),
    );
  }, [summaryOfAllDataSources.data]);

  const cloudLocations = useMemo(() => {
    return orderBy(
      summaryOfAllDataSources.data.data_region_summary
        ?.filter(d => activeProviders?.includes(d.cloud_provider ?? ''))
        ?.map(r => {
          return {
            pos: [r.location?.longitude ?? 0, r.location?.latitude ?? 0],
            count: r.resource_count,
            provider: r.cloud_provider,
            name: r?.location?.name ?? r?.region,
            code: r?.region,
          };
        })
        .filter(identity) ?? [],
      ['count'],
      ['desc'],
    ) as IGeoLocation[];
  }, [activeProviders, summaryOfAllDataSources.data.data_region_summary]);

  const isLoading = useMemo(() => {
    return summaryOfAllDataSources.isLoading;
  }, [summaryOfAllDataSources.isLoading]);

  return (
    <DashboardOverviewCard
      label="Regions where data sources are deployed"
      icon={<RegionIcon />}
      content={
        <Stack w="full" h="full" pos="relative">
          <HStack spacing={4} px={4} minH={8}>
            {map(cloudProviders, o => (
              <HStack>
                <Box
                  boxSize={3}
                  bg={getIconColor(o.cloud)}
                  borderRadius={3}
                  border="1px solid"
                  borderColor={customTheme.colors.gray['200']}
                />
                <Box>
                  {o.name} ({o?.regions?.length})
                </Box>
              </HStack>
            ))}
          </HStack>
          <Box flex={1}>
            <GeoLocationMap locations={isLoading ? [] : cloudLocations ?? []} />
          </Box>

          {isLoading && (
            <Center flex={1} w="full" h="full" pos="absolute" left={0} top={0}>
              <CustomSpinner />
            </Center>
          )}
        </Stack>
      }
    />
  );
};
