import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';

import {
  OrganizationService,
  OrganizationServiceDeleteRequest,
  OrganizationServiceUpdateRequest,
} from 'api/auth/api.pb';
import { callApiFn } from 'utils/saga';

import { actions } from './slice';

export function* organizationsSaga() {
  yield takeLatest(actions.getOrganizations.type, goGetOrganizations);
  yield takeLatest(actions.deleteOrganization.type, doDeleteOrganization);
  yield takeLatest(
    actions.changeEnableOrganization.type,
    doChangeEnableOrganization,
  );
  // yield takeLatest(actions.updateOrganization.type, goUpdateOrganization);
}

function* goGetOrganizations() {
  yield call(callApiFn, {
    fn: OrganizationService.List,
    onSuccess: actions.getOrganizationsSuccess,
    onError: actions.getOrganizationsError,
  });
}

function* doChangeEnableOrganization(
  action: PayloadAction<OrganizationServiceUpdateRequest>,
) {
  yield call(callApiFn, {
    fn: OrganizationService.Update,
    data: action.payload,
    onSuccess: actions.changeEnableOrganizationSuccess,
    onError: actions.changeEnableOrganizationError,
  });
}

function* doDeleteOrganization(
  action: PayloadAction<OrganizationServiceDeleteRequest>,
) {
  yield call(callApiFn, {
    fn: OrganizationService.Delete,
    data: action.payload,
    onSuccess: actions.deleteOrganizationSuccess,
    onError: actions.deleteOrganizationError,
  });
}
