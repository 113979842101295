import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VpcGatewayIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M256,488.2
		C128,488.2,23.8,384,23.8,256S128,23.8,256,23.8S488.2,127.9,488.2,256S384,488.2,256,488.2z"
      />
      <path
        d="M467.7,224.1c-16.9,0-60.9,0-83.5,0c-7.3,0-12.1,0-12.1,0s0-17.6,0-28.6c0-11-8.3-2.7-8.3-2.7
		s-62.1,46.8-68.6,53.3c-4.8,4.7,0.5,9.3,0.5,9.3l66.5,55c0,0,7.9,8.4,7.9-0.7c0-9.1,0-31,0-31s5.3,0,13.5,0c23.4,0,65.9,0,83.3,0
		c0,0,4.7,1.2,4.7-5.9c0-7.2,0-38.5,0-43.6C471.6,224,467.7,224.1,467.7,224.1z"
      />
      <path
        d="M218.2,246.1c-6.5-6.5-68.7-53.3-68.7-53.3s-8.2-8.3-8.2,2.7s0,28.6,0,28.6s-4.8,0-12.1,0
		c-22.6,0-66.6,0-83.5,0c0,0-3.9-0.1-3.9,5c0,5.1,0,36.5,0,43.6c0,7.1,4.7,5.9,4.7,5.9c17.3,0,59.9,0,83.3,0c8.2,0,13.5,0,13.5,0
		s0,21.9,0,31c0,9,7.9,0.7,7.9,0.7l66.5-55C217.7,255.4,223,250.8,218.2,246.1z"
      />
      <path
        d="M262.5,293.5c-4.7-4.7-9.2,0.6-9.2,0.6l-55,66.5c0,0-8.4,7.9,0.7,7.9c9.1,0,31,0,31,0s0,5.3,0,13.5
		c0,23.4,0,66,0,83.3c0,0-1.2,4.7,5.9,4.7c7.2,0,38.5,0,43.6,0c5.1,0,5-3.9,5-3.9c0-16.8,0-60.9,0-83.4c0-7.3,0-12.2,0-12.2
		s17.6,0,28.6,0c11,0,2.7-8.2,2.7-8.2S269,300,262.5,293.5z"
      />
      <path
        d="M198.3,151.1l55,66.5c0,0,4.5,5.3,9.2,0.6c6.5-6.5,53.4-68.6,53.4-68.6s8.3-8.2-2.7-8.2s-28.6,0-28.6,0
		s0-4.8,0-12.1c0-22.6,0-66.6,0-83.5c0,0,0.1-3.9-5-3.9c-5.1,0-36.5,0-43.6,0c-7.1,0-5.9,4.7-5.9,4.7c0,17.3,0,59.9,0,83.3
		c0,8.2,0,13.5,0,13.5s-21.9,0-31,0C189.9,143.2,198.3,151.1,198.3,151.1z"
      />
    </g>
  </Icon>
);
