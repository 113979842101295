import React from 'react';

import { Box } from '@chakra-ui/react';
import { components } from 'react-select';

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      {props.getValue().length ? (
        <Box
          fontSize="xs"
          color="primary"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            props?.onReset();
          }}
          _hover={{ cursor: 'pointer' }}
          fontWeight={600}
        >
          Clear
        </Box>
      ) : (
        <Box />
      )}
    </components.DropdownIndicator>
  );
};
