import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BlockStorageIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M460,82.3c-5.8-13.4-15.9-24.9-28.6-34.9c-19.2-14.9-44.8-26.5-74.7-34.7C326.8,4.6,292.5,0,256,0
		c-55.6,0-106.1,10.5-143.8,28.4c-18.9,9-34.7,19.8-46.3,32.7C60.1,67.6,55.3,74.6,52,82.3c-3.3,7.6-5.1,15.9-5.1,24.3v298.9
		c0,8.4,1.8,16.7,5.1,24.3c5.8,13.4,15.9,24.9,28.6,34.9c19.2,14.9,44.8,26.5,74.7,34.7c29.9,8.1,64.2,12.7,100.7,12.7
		c55.6,0,106.1-10.5,143.8-28.4c18.9-9,34.7-19.8,46.3-32.7c5.8-6.5,10.5-13.5,13.8-21.2c3.3-7.6,5.1-15.9,5.1-24.3V106.6
		C465.1,98.1,463.3,89.9,460,82.3z M436.3,405.4c0,4.3-0.9,8.5-2.8,12.8c-3.3,7.6-9.8,15.8-19.9,23.5c-15,11.7-37.4,22.3-64.5,29.6
		c-27.2,7.4-59.1,11.7-93.1,11.7c-51.9,0-98.8-10.1-131.5-25.6c-16.4-7.7-29.1-16.8-37.1-25.9c-4.1-4.5-7-9.1-8.9-13.4
		c-1.9-4.4-2.8-8.5-2.8-12.8v-49.4c16.7,15.2,40.1,27.4,68.1,36.4c32.3,10.3,70.8,16.2,112.2,16.3c55.2,0,105.2-10.5,142.3-28
		c14.9-7.1,27.7-15.4,38-24.8L436.3,405.4L436.3,405.4z M436.3,321.2c-2.1,4.4-5,8.8-8.9,13.3c-12.8,14.4-35.9,27.9-65.8,37.4
		c-29.8,9.5-66.3,15.2-105.6,15.2c-52.4,0-99.7-10.1-133-26c-16.7-7.9-29.8-17.1-38.3-26.7c-4-4.5-6.9-8.9-8.9-13.3v-64.8
		c16.7,15.2,40.1,27.4,68.1,36.4c32.3,10.3,70.8,16.2,112.2,16.2c55.2,0,105.2-10.5,142.3-28c14.9-7.1,27.7-15.4,38-24.8
		L436.3,321.2L436.3,321.2z M436.3,221.6c-2.1,4.4-5,8.8-8.9,13.3c-12.8,14.4-35.9,28-65.8,37.4c-29.8,9.5-66.3,15.2-105.6,15.2
		c-52.4,0-99.7-10.1-133-25.9c-16.7-7.9-29.8-17.1-38.3-26.7c-4-4.4-6.9-8.9-8.9-13.3v-64.8c16.7,15.3,40.1,27.4,68.1,36.4
		c32.3,10.3,70.8,16.2,112.2,16.2c55.2,0,105.2-10.5,142.3-28c14.9-7.1,27.7-15.4,38-24.8L436.3,221.6L436.3,221.6z M436.3,121.9
		c-2.1,4.4-5,8.8-8.9,13.3c-12.8,14.4-35.9,28-65.8,37.5c-29.8,9.5-66.3,15.2-105.6,15.2c-52.4,0-99.7-10.1-133-25.9
		c-16.7-7.9-29.8-17.1-38.3-26.7c-4-4.4-6.9-8.9-8.9-13.3v-15.4c0-4.3,0.9-8.5,2.8-12.8c3.3-7.6,9.8-15.8,19.9-23.6
		c15-11.7,37.4-22.3,64.5-29.6c27.2-7.4,59.1-11.7,93.1-11.7c51.9,0,98.8,10.1,131.5,25.6c16.4,7.7,29.1,16.8,37.1,25.9
		c4.1,4.5,7,9.1,8.9,13.4c1.9,4.4,2.8,8.5,2.8,12.8V121.9z"
    />
  </Icon>
);
