import React, { FC, useEffect, useState } from 'react';

import { AlertSeverityInitializer } from '@ariksa/notification/api';
import { forEach, split } from 'lodash';
import { useSelector } from 'react-redux';

import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';

import { AlertCount } from './AlertCount';

interface Props {
  category?: string;
  severity?: AlertSeverityInitializer;
  categoryClass?: string;
}

export const AlertCountForEachCategory: FC<Props> = props => {
  const { category, severity, categoryClass } = props;
  const { alertCategories } = useSelector(selectFindingsOverview);
  const [severities, setSeverities] = useState<AlertSeverityInitializer>({});

  useEffect(() => {
    if (!!severity) setSeverities(severity);
    else if (categoryClass) {
      let items = { CRITICAL: 0, MEDIUM: 0, HIGH: 0, LOW: 0 };
      forEach(alertCategories.data?.[categoryClass], s => {
        items.CRITICAL! += s.severity.CRITICAL ?? 0;
        items.HIGH! += s.severity.HIGH ?? 0;
        items.MEDIUM! += s.severity.MEDIUM ?? 0;
        items.LOW! += s.severity.LOW ?? 0;
      });
      setSeverities(items);
    } else {
      const c = split(category, '?');
      setSeverities(alertCategories.data?.[c[1]]?.[c[0]]?.severity);
    }
  }, [alertCategories, category, severity, categoryClass]);

  return (
    <AlertCount
      critical={severities?.CRITICAL ?? 0}
      high={severities?.HIGH ?? 0}
      medium={severities?.MEDIUM ?? 0}
      low={severities?.LOW ?? 0}
      category={split(category, '?')?.[0]}
    />
  );
};
