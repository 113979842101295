import { RemediationApiCommandRemediationRequest } from '@ariksa/compliance-policies';
import {
  RemediationApiAggregateRemediationRequest,
  RemediationApiCheckAggregateCommandRemediationRequest,
  RemediationCheck,
  RulesApiGetAllComplianceStandardRequest,
  RulesApiGetDocumentsForCheckRequest,
} from '@ariksa/compliance-policies/api';
import {
  ContextApiGetRiskContextResRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  RelationshipApiGetConnectedEntitiesRequest,
  RiskContextResponse,
} from '@ariksa/inventory-core/api';
import { AggregateAlertsBy } from '@ariksa/notification';
import {
  AggregatedAlertsPaginatedResponse,
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertsApiGetAggregatedAlertsRequest,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertsRequest,
  AlertsApiGetEntitySeverityListRequest,
  AlertsApiGetNotificationStatusRequest,
  AlertsApiGetTopIssuesRequest,
  EntitySeverityListResponse,
  NotificationWorkflowStatus,
  TopIssuesResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardSummaryRequest,
  DashboardCount,
} from '@ariksa/reporting';
import dayjs from 'dayjs';
import { identity } from 'lodash';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';
import { Dict } from 'types/utils';

import {
  Api,
  ComplianceService,
  IGetAlertSummary,
  InventoryService,
  NotificationService,
  ReportingService,
} from 'api/index';

import request from '../../../../utils/request';

import { actions } from './slice';

export function* findingsOverviewSaga() {
  yield takeLatestAction(actions.loadResourceCount, doGetResourceCount);
  yield takeLatestAction(
    actions.loadAlertHistoryByTrend,
    doGetAlertHistoryByTrend,
  );
  yield takeLatestAction(
    actions.loadAlertHistoryByStatus,
    doGetAlertHistoryByStatus,
  );
  yield takeLatestAction(actions.getTopIssuesResolved, doGetTopIssuesResolved);
  yield takeLatestAction(
    actions.getAggregatedAlertRemediationAvailability,
    doGetAggregatedAlertRemediationAvailability,
  );
  yield takeLatestAction(
    actions.getAlertByRuleIdRemediationAvailability,
    doGetAlertByRuleIdRemediationAvailability,
  );

  yield takeLatestAction(actions.loadAlertCategories, doGetAlertCategories);
  yield takeLatestAction(actions.loadAlertsSummary, doGetAlertsSummary);
  yield takeLatestAction(actions.loadAlertsGroupedByAlertId, doGetAlerts);
  yield takeLatestAction(
    actions.loadResourceFailedChecks,
    doGetResourceFailedChecks,
  );
  yield takeLatestAction(
    actions.loadAlertDetailsSeverity,
    doLoadAlertDetailsSeverity,
  );
  yield takeLatestAction(
    actions.getRemediationCommands,
    doGetRemediationCommands,
  );
  //yield takeLatestAction(actions.getAlertCountSummary, doGetAlertCountSummary);
  yield takeLatestAction(actions.getAlertsByRuleId, doGetAlertsByRuleId);
  yield takeLatestAction(
    actions.getRemediationByAlertRuleId,
    doGetRemediationByAlertRuleId,
  );

  yield takeLatestAction(actions.getResourceVPC, doGetResourceVPC);
  yield takeLatestAction(
    actions.getFindingDescription,
    doGetFindingDescription,
  );
  yield takeLatestAction(actions.executeRemediation, doExecuteRemediation);
  yield takeLatestAction(
    actions.getAlertWorkflowStatus,
    doGetAlertWorkflowStatus,
  );
  yield takeLatestAction(actions.getConnectedEntities, doGetConnectedEntities);
  yield takeLatestAction(actions.getRiskContext, doGetRiskContext);
  yield takeLatestAction(actions.getCompliance, doGetCompliance);
  yield takeLatestAction(
    actions.getAggregatedAlertCompliance,
    doGetAggregatedAlertCompliance,
  );
}

function* doGetAggregatedAlertRemediationAvailability(
  ctx: QueryContext<
    RemediationCheck,
    RemediationApiCheckAggregateCommandRemediationRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Remediation.checkAggregateCommandRemediation(ctx.params),
  );
}

function* doGetAlertByRuleIdRemediationAvailability(
  ctx: QueryContext<
    RemediationCheck,
    RemediationApiCheckAggregateCommandRemediationRequest
  >,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Remediation.checkAggregateCommandRemediation(ctx.params),
  );
}

function* doGetTopIssuesResolved(
  ctx: QueryContext<TopIssuesResponse, AlertsApiGetTopIssuesRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getTopIssues(ctx.params),
  );
}

export function* doGetResourceCount(
  ctx: QueryContext<DashboardCount, ChartsApiGetDashboardSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardSummary(ctx.params),
    {
      errorMsg: 'Failed to get resource count!',
      cacheKey: 'findingsResourceCount',
    },
  );
}

export function* doGetAlertHistoryByTrend(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    {
      errorMsg: 'Failed to get alert history by trend!',
      cacheKey: 'alertHistoryByTrend',
    },
  );
}

export function* doGetAlertHistoryByStatus(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    {
      errorMsg: 'Failed to get alert history by status!',
      cacheKey: 'alertHistoryByStatus',
    },
  );
}

function* doGetConnectedEntities(
  ctx: QueryContext<
    InsightV2Pagination,
    RelationshipApiGetConnectedEntitiesRequest
  >,
) {
  yield call(ctx.fetch, async () =>
    InventoryService.Relationship.getConnectedEntities(ctx.params),
  );
}

function* doExecuteRemediation(
  ctx: QueryContext<any, RemediationApiCommandRemediationRequest>,
) {
  yield call(
    ctx.fetch,
    async () => ComplianceService.Remediation.commandRemediation(ctx.params),
    { successMsg: 'Remediation executed successfully' },
  );
}

function* doGetResourceVPC(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(ctx.fetch, async () =>
    InventoryService.InsightV2.insightV2(ctx.params),
  );
}

function* doGetAggregatedAlerts(
  ctx: QueryContext<any, RulesApiGetDocumentsForCheckRequest>,
) {
  yield call(ctx.fetch, async () =>
    ComplianceService.Rules.getDocumentsForCheck(ctx.params),
  );
}

function* doGetFindingDescription(
  ctx: QueryContext<any, RulesApiGetDocumentsForCheckRequest>,
) {
  yield call(ctx.fetch, async () =>
    ComplianceService.Rules.getDocumentsForCheck(ctx.params),
  );
}

function* doGetRemediationByAlertRuleId(
  ctx: QueryContext<any, RemediationApiAggregateRemediationRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Remediation.aggregateRemediation(ctx.params),
  );
}

function* doGetAlertsByRuleId(
  ctx: QueryContext<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAlerts({
      ...ctx.params,
      pageNumber: ctx.page.page_number,
      pageSize: ctx.page.page_size,
    }),
  );
}

/*function* doGetAlertCountSummary(
  ctx: QueryContext<
    AggregatedAlertsPaginatedResponse,
    AlertsApiGetAggregatedAlertsRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAggregatedAlerts(ctx.params),
  );
}*/

function* doGetRemediationCommands(
  ctx: QueryContext<any, RemediationApiCommandRemediationRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Remediation.commandRemediation(ctx.params),
  );
}

export function* doGetAlertCategories(
  ctx: QueryContext<
    AggregatedAlertsSummaryResponse,
    AlertsApiGetAggregatedAlertsSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAggregatedAlertsSummary(ctx.params),
    {
      errorMsg: 'Failed to get alert categories!',
      cacheKey: 'findingsAlertCategories',
    },
  );
}

export function* doGetAlertsSummary(ctx: QueryContext<any>) {
  const { accountId, environmentId, alertCategory } = ctx.params;

  const payload: IGetAlertSummary = {
    environment_id: environmentId,
    account_id: accountId,
    category: alertCategory,
    start_date: dayjs().utc().subtract(1, 'day').format('YYYY-MM-DD'),
    end_date: dayjs().utc().format('YYYY-MM-DD'),
  };

  yield call(ctx.fetch, () => request(Api.getAlertsSummary(payload)), {
    map: identity,
  });
}

export function* doGetAlerts(
  ctx: QueryContext<
    AggregatedAlertsPaginatedResponse,
    AlertsApiGetAggregatedAlertsRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAggregatedAlerts(ctx.params),
  );
}

export function* doGetResourceFailedChecks(
  ctx: QueryContext<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
) {
  yield call(ctx.fetch, () => NotificationService.Alerts.getAlerts(ctx.params));
}

export function* doLoadAlertDetailsSeverity(
  ctx: QueryContext<
    EntitySeverityListResponse,
    AlertsApiGetEntitySeverityListRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getEntitySeverityList(ctx.params),
  );
}

export function* doGetAlertWorkflowStatus(
  ctx: QueryContext<
    NotificationWorkflowStatus[],
    AlertsApiGetNotificationStatusRequest
  >,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getNotificationStatus(ctx.params),
  );
}

export function* doGetAlertLogs(
  ctx: QueryContext<AlertLogsResponse[], AlertLogsApiGetLogsForIdsRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertsLogs.getLogsForIds(ctx.params),
  );
}

export function* doGetRiskContext(
  ctx: QueryContext<
    Dict<RiskContextResponse>,
    ContextApiGetRiskContextResRequest
  >,
) {
  yield call(ctx.fetch, () =>
    InventoryService.Context.getRiskContextRes(ctx.params),
  );
}

export function* doGetCompliance(
  ctx: QueryContext<Dict<string[]>, RulesApiGetAllComplianceStandardRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAllComplianceStandard(ctx.params),
  );
}

export function* doGetAggregatedAlertCompliance(
  ctx: QueryContext<Dict<string[]>, RulesApiGetAllComplianceStandardRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Rules.getAllComplianceStandard(ctx.params),
  );
}
