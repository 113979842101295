import { NativeResources } from '@ariksa/inventory-core';
import {
  AppSchemasSearchWrapperModelsRelationship,
  Attribute,
  Field,
  Resource,
  RHSSuggestionType,
  SearchAttribute,
  Wrapper,
} from '@ariksa/inventory-core/api';
import { isArray, isEmpty, isObject, keys, map } from 'lodash';
import { Optional } from 'types/utils';

import {
  AriksaQueryResourceOptions,
  AriksaQueryResourceRelationship,
  AriksaSearchQuery,
  AriksaSearchQueryAttribute,
  AriksaSearchQueryResource,
  AriksaSearchQueryThat,
  QueryOptionsStatus,
} from 'containers/PolicyHub/types';
import {
  queryId,
  queryRelationshipId,
  queryResourceAttributeId,
  queryResourceAttributeListId,
  queryResourceId,
  queryThatId,
} from 'containers/PolicyHub/utils';

// mutates the argument
export const cleanUpObject = (obj: any) => {
  if (!isObject(obj)) return obj;
  if (isArray(obj)) {
    const cleaned = map(obj, el => {
      return cleanUpObject(el);
    });
    // console.log(obj);
    return cleaned.filter(a => a && !isEmpty(a));
    // console.log('X', obj);
  }

  if (isObject(obj)) {
    delete obj['build'];
    const objKeys = keys(obj as any);
    for (const key of objKeys) {
      const oldVal = obj[key];
      obj[key] = cleanUpObject(oldVal);
      if ((isObject(oldVal) && isEmpty(oldVal)) || oldVal == null) {
        delete obj[key];
      }
    }
    if (isEmpty(obj)) {
      return null;
    }
  }

  return obj;
};

export const parseQuery = (
  query: Wrapper,
  attributes: SearchAttribute[],
): AriksaSearchQuery => {
  const res = {
    build: { id: queryId() },
    resource: parseQueryResource(query.resource, attributes),
    is_complete: true,
  };
  return res as AriksaSearchQuery;
};

const parseQueryResource = (
  resource?: Resource,
  attributes?: SearchAttribute[],
): Optional<AriksaSearchQueryResource> => {
  if (!resource) return;
  const res: AriksaSearchQueryResource = {
    build: {
      id: queryResourceId(),
      attribute_options: [],
      attribute_options_status: QueryOptionsStatus.unknown,
      relationship_options: [],
      relationship_options_status: QueryOptionsStatus.unknown,
    },
    name: resource.name as NativeResources,
  };

  if (resource.that) {
    res.that = resource.that.map(that => parseThat(that));
  }

  if (resource.clauses) {
    res.clauses = resource.clauses.map(clause => {
      return {
        build: {
          id: queryResourceAttributeListId(),
        },
        attributes:
          clause.attributes?.map(attr =>
            parseResourceAttribute(attr, resource.name, attributes),
          ) ?? [],
      };
    });
  }

  return res;
};

const parseThat = (
  that: AppSchemasSearchWrapperModelsRelationship,
): AriksaSearchQueryThat => {
  const res: AriksaSearchQueryThat = {
    build: {
      id: queryThatId(),
      resource_options: [] as AriksaQueryResourceOptions,
      resource_options_status: QueryOptionsStatus.unknown,
    },
    relationship: undefined,
    resource: undefined,
  };

  if (that.resource) {
    res.resource = parseQueryResource(that.resource);
  }

  if (that.relationship) {
    res.relationship = parseRelationship(that.relationship);
  }
  return res;
};

const parseRelationship = (
  relationship: Field,
): Optional<AriksaQueryResourceRelationship> => {
  const res: AriksaQueryResourceRelationship = {
    build: {
      id: queryRelationshipId(),
      attribute_options: [],
      attribute_options_status: QueryOptionsStatus.unknown,
    },
    name: relationship.name,
  };

  return res;
};

const parseResourceAttribute = (
  attribute: Attribute,
  resource: string,
  attributes?: SearchAttribute[],
): AriksaSearchQueryAttribute => {
  const attributeInfo: Optional<SearchAttribute> = attributes?.find(
    a => a.resource === resource && a.name === attribute.name,
  );
  return {
    build: {
      id: queryResourceAttributeId(),
      operator_options: attributeInfo?.operators ?? [],
      operator_options_status: QueryOptionsStatus.loaded,
      value_options_status: QueryOptionsStatus.loaded,
      value_options: {
        type: attributeInfo?.type ?? ('' as RHSSuggestionType),
        values: attributeInfo?.values ?? [],
      },
    },
    name: attribute.name,
    alias: attribute.alias,
    operator: attribute.operator,
    values: attribute.values,
  };
};
