import React from 'react';

import {
  Box,
  Link,
  Stack,
  Image,
  Center,
  HStack,
  Text,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';
import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

import bitbucket_1 from './Bitbucket1.png';
import bitbucket_2 from './Bitbucket2.png';
import bitbucket_3 from './Bitbucket3.png';
import bitbucket_4 from './Bitbucket4.png';

interface Props {
  onClose: () => void;
}

export const BitbucketTokenHelp = (props: Props) => {
  const { onClose } = props;

  const renderImage = (src, alt) => (
    <Center>
      <Box
        border="1px solid"
        borderRadius={0}
        borderColor={customTheme.colors.gray['200']}
      >
        <Image src={src} w="full" fit="cover" alt={alt} />
      </Box>
    </Center>
  );

  return (
    <Stack h="full">
      <HelpHeader>Get Bitbucket Application Password</HelpHeader>
      <Stack px={4} spacing={6}>
        <HStack align={'start'}>
          <HelpCount count={1} />
          <Box>
            Log in to{' '}
            <Link
              color="primary"
              href="https://id.atlassian.com/login"
              isExternal
            >
              Bitbucket
            </Link>
            .
          </Box>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={2} />
          <Stack spacing={4}>
            <Box>Click on Settings {'->'} Personal BitBucket Settings </Box>
            {renderImage(bitbucket_1, 'bitbucket_1')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={3} />
          <Stack spacing={4}>
            <Box>
              Click on App passwords {'->'} Create app password. Provide{' '}
              <Text as="b">Read</Text> permissions and click on Create.
            </Box>
            {renderImage(bitbucket_2, 'bitbucket_2')}
            {renderImage(bitbucket_3, 'bitbucket_3')}
          </Stack>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={4} />
          <Stack spacing={4}>
            <Box>Copy and save the created app password.</Box>
            {renderImage(bitbucket_4, 'bitbucket_4')}
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  );
};
