import React, { FC, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const DistributionByTypesOfFiles: FC = () => {
  const { data } = useSelector(selectActiveResource);
  const { data: labels, isLoading } = data.typesOfFiles;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(labels?.total);
    setChartData([
      {
        name: 'Table format',
        value: labels?.table_format,
        percent: labels?.table_format_percentage,
      },
      {
        name: 'Documents',
        value: labels?.documents,
        percent: labels?.documents_percentage,
      },
      {
        name: 'Images',
        value: labels?.images,
        percent: labels?.images_percentage,
      },
      {
        name: 'Archive',
        value: labels?.archives,
        percent: labels?.archives_percentage,
      },
      {
        name: 'Other',
        value: labels?.other,
        percent: labels?.other_percentage,
      },
    ]);
  }, [labels]);

  const colorMap = {
    'Table format': customTheme.colors.blue['300'],
    Documents: customTheme.colors.skyBlue['300'],
    Images: customTheme.colors.primary,
    Archive: customTheme.colors.green['300'],
    Other: customTheme.colors.orange['300'],
  };

  return (
    <FootprintBox
      content={
        <HStack w="full" h="full" justify="space-between" px={4} py={4}>
          <Box w="35%" h="full">
            <PieChart
              isLoading={isLoading}
              data={chartData}
              dataKey="value"
              total={formatNumber(total)}
              outerRadius="100%"
              innerRadius="88%"
              activeShape={false}
              colorMap={colorMap}
              legend={false}
            />
          </Box>
          <Stack w="65%" h="full" justify="space-between" spacing={0}>
            {map(chartData, o => (
              <HStack>
                <HStack w={32}>
                  <Center
                    boxSize={1}
                    bg={colorMap[o.name]}
                    borderRadius="full"
                  />
                  <Box>{o.name}</Box>
                </HStack>
                <HStack w="full">
                  <Box w={20}>{o.value}</Box>
                  <Box w="full">
                    <Box
                      borderRadius="full"
                      h={1}
                      bg={colorOpacity(customTheme.colors.primary, 0.2)}
                      w={o.percent + '%'}
                    />
                  </Box>
                  <Box w={24}>{o.percent}%</Box>
                </HStack>
              </HStack>
            ))}
          </Stack>
        </HStack>
      }
      label="Distribution by types of files"
      isLoading={isLoading}
    />
  );
};
