import React, { useCallback, useMemo } from 'react';

import { AccessRequestGetResponse } from '@ariksa/jit';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { customTheme } from 'theme';

import {
  DetailsIdentifier,
  Heading4,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { PrimaryButton } from 'components/DataEntry';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { Modal } from 'components/Overlay';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { actions } from 'containers/Visibility/JitRequests/slice';

interface AutoApproveModalProps {
  isOpen: boolean;
  onClose: () => void;
  request: AccessRequestGetResponse;
}

export const AutoApproveModal = (props: AutoApproveModalProps) => {
  const { isOpen, onClose, request } = props;
  const dispatch = useDispatch();
  const accounts = useSelector(selectOnboardedCloudAccounts);
  const { updateAutoApprovals } = useSelector(selectJitRequests);

  const requestAccount = useMemo(() => {
    return request.access_request_details?.[request.cloud_type.toLowerCase()];
  }, [request]);

  const cloudAccount = accounts.data.find(
    account => account.uuid === requestAccount?.account_id,
  );

  const handleUpdate = useCallback(() => {
    console.log('Update', request);
    dispatch(
      actions.updateAutoApprovals({
        q: {
          requestId: request.request_id,
          toggleAutoApproveRequest: {
            auto_approve: !request.auto_approve,
          },
        },
        onSuccess: () => {
          onClose();
          dispatch(
            actions.getJitRequests({
              q: {},
              page: INIT_PAGE_INFO,
            }),
          );
        },
      }),
    );
  }, [dispatch, onClose, request]);

  const cloudUser = useMemo(() => {
    switch (request.cloud_type) {
      case 'AWS':
        return requestAccount.aws_username;
      case 'GCP':
        return requestAccount.aws_username;
      default:
        return '-';
    }
  }, [request.cloud_type, requestAccount.aws_username]);

  const styles = {
    label: { color: 'black' },
    value: { color: customTheme.colors.gray['300'] },
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          label="Change Auto-Approval Mode"
          icon={<ManualIcon />}
        />
      }
      body={
        <Stack w={'full'} spacing={10}>
          <Stack>
            <DetailsIdentifier
              label="Requester"
              value={request.requester_slack_email_id ?? '-'}
              styles={styles}
            />
            <DetailsIdentifier
              label="Cloud user"
              value={cloudUser}
              styles={styles}
            />
            <DetailsIdentifier
              label="Cloud account"
              value={cloudAccount?.cloud_account_id}
              styles={styles}
            />
            <DetailsIdentifier
              label="Role"
              value={requestAccount?.role}
              styles={styles}
            />
            <DetailsIdentifier
              label="Change auto-approval to"
              value={request.auto_approve ? 'No' : 'Yes'}
              styles={styles}
            />
          </Stack>

          <Stack color={'primary'}>
            <Heading4>Note</Heading4>
            <Text>
              For any future requests with the same information as above, you
              are changing how approval will be done. You can change this
              configuration at any time.
            </Text>
          </Stack>
        </Stack>
      }
      footer={
        <HStack w={'full'} justify={'space-between'}>
          <PrimaryButton onClick={onClose}>Cancel</PrimaryButton>
          <PrimaryButton
            onClick={handleUpdate}
            isLoading={updateAutoApprovals.isLoading}
          >
            Ok
          </PrimaryButton>
        </HStack>
      }
      styles={{
        modal: { size: 'xl' },
      }}
    />
  );
};
