import React, { FC } from 'react';

import { Tabs } from 'components/Navigation';
import { ResourceIcons } from 'containers/Meta/ResourceIcons';
import { ResourceTypes } from 'containers/Meta/ResourceTypes';

export const Meta: FC = () => {
  return (
    <Tabs
      items={[
        { title: 'Resource Types', component: <ResourceTypes />, key: 'types' },
        { title: 'Resource Icons', component: <ResourceIcons />, key: 'icons' },
      ]}
    />
  );
};
