import React, { FC } from 'react';

import { HStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectHistoricalOptions } from 'components/DataEntry';
import { VulnerabilityHeader } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityHeader';
import { VulnerabilityHeaderFilters } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityHeaderFilters';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';

export const CVEHeader: FC = () => {
  const { publishedAt, selectedTab } = useSelector(selectVulnerabilities);
  const dispatch = useDispatch();

  return (
    <HStack w="full" justify="space-between">
      <VulnerabilityHeader />
      <HStack pb={4}>
        <HStack>
          <VulnerabilityHeaderFilters />
          {/*<DownloadIconButton
        aria-label="download-vulnerabilities"
        //isLoading={download.isLoading}
        tooltip="Download Report"
        onClick={() => {}}
      />*/}
          <SelectHistoricalOptions
            historicalTime={publishedAt}
            setHistoricalTime={s => dispatch(actions.updatePublishedAt(s))}
            leftMessage={selectedTab === 'cve' ? 'Published: ' : ''}
          />
        </HStack>
      </HStack>
    </HStack>
  );
};
