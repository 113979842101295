import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface ICustomTooltip extends BoxProps {}

export const CustomTooltip: FC<ICustomTooltip> = props => {
  return (
    <Box
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      p="2"
      fontSize={14}
      bg={'#fff'}
      borderRadius={5}
      {...props}
    ></Box>
  );
};
