import React from 'react';

import { Box, Center, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { renderTime } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { getIcon } from 'components/Icons/Components';
import { selectConversationalAI } from 'containers/ConversationalAI/selectors';

export const Insights: React.FC = () => {
  const { cloudInsights } = useSelector(selectConversationalAI);

  return  (
    <Stack h="full" fontSize="md" spacing={1} overflow="scroll" pt={6}>
      {map(cloudInsights.data, o => (
        <HStack
          p={2}
          spacing={4}
          align="flex-start"
          _hover={{ bg: 'gray.50' }}
          borderRadius={6}
        >
          <Box
            pt={0.5}
            boxSize={5}
            color={o?.category === 'Informational' ? 'primary' : 'critical'}
          >
            <Center>{getIcon(o?.category!)}</Center>
          </Box>
          <Stack spacing={1} flex={1}>
            <HStack>
              <Text fontWeight={600}>{o?.category}:</Text>
              <Text color="gray.250">
                {renderTime({ value: o?.event_time })}
              </Text>
            </HStack>
            <Text display="inline">{o?.description}</Text>
          </Stack>
        </HStack>
      ))}
    </Stack>
  );
};
