import React, { FC, useMemo } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { Box, Stack } from '@chakra-ui/react';

import { StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';

interface IEc2TrafficSummaryModal {
  data: any;
  onClose: () => void;
  isOpen: boolean;
}

export const Ec2TrafficSummaryModal: FC<IEc2TrafficSummaryModal> = props => {
  const { onClose, isOpen, data } = props;
  const { getCloudNativeName } = useResourceType();

  const columns = useMemo(() => {
    return [
      {
        header: 'Type',
        render: ({ row }) => {
          return row.ingress ? 'Ingress' : 'Egress';
        },
      },
      {
        header: 'ports',
        accessor: 'count_of_ports',
      },
      {
        header: 'malicious',
        align: 'right',
        render: ({ row }) => {
          return row.is_malicious ? 'Yes' : 'No';
        },
      },
    ];
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          type={NativeResources.Ec2Instance ?? ''}
          upper={getCloudNativeName(NativeResources.Ec2Instance)}
          lower={'Traffic Summary'}
        />
      }
      body={
        <Stack minH={'400px'}>
          <Table
            columns={columns}
            data={data.properties?.traffic_summary ?? []}
          />
        </Stack>
      }
      size={'xl'}
    />
  );
};
