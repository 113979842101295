/**
 *
 * Blueprint Policy Card Footer
 *
 */
import React, { FC } from 'react';

import { Box, Flex, Text, HStack } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  EnvironmentIcon,
  LockCloseIcon,
  LockOpenIcon,
  PolicyIcon,
} from 'components/Icons';
import { renderEditLock } from 'containers/PolicyHub/Blueprints/Components/utils';

interface IPolicyFooter {
  data: Record<string, any>;
}

export const BlueprintCardFooter: FC<IPolicyFooter> = props => {
  const { data } = props;

  const renderTooltip = value => (
    <Box w={32} textAlign="center">
      {value}
    </Box>
  );

  return (
    <Flex
      justify="space-between"
      mt={8}
      className="blueprint_quick_overview"
      fontSize="xs"
    >
      <CustomTooltip
        label={renderTooltip(
          'This blueprint is ' +
            (data?.created_by === 'system' ? 'not ' : '') +
            'editable',
        )}
      >
        <HStack spacing={1}>
          {renderEditLock(data.created_by)}
          <Box mr={2}>Edit</Box>
        </HStack>
      </CustomTooltip>
      <CustomTooltip
        label={renderTooltip('Number of environments using this blueprint')}
      >
        <HStack spacing={1}>
          <Box as={EnvironmentIcon} boxSize={4} color="primary" />
          <Text ml={2}>Environment ({data?.environments?.length ?? 0})</Text>
        </HStack>
      </CustomTooltip>
      <CustomTooltip label={renderTooltip('Total number of enabled rules')}>
        <HStack spacing={1}>
          <Box as={PolicyIcon} boxSize={3.5} color="primary" />
          <Text ml={2}>Policies ({data?.rule_count})</Text>
        </HStack>
      </CustomTooltip>
    </Flex>
  );
};
