import React from 'react';

import { Center } from '@chakra-ui/react';
import startCase from 'lodash/startCase';

const styles = {
  px: 3,
  borderRadius: 3,
  color: 'white',
};

export const renderImportance = (value: string) => {
  return (
    <Center {...styles} bg={value?.toLowerCase()}>
      {value?.toUpperCase()}
    </Center>
  );
};

export const renderRemediationEffort = (value: string) => {
  let bg = value?.toLowerCase();
  if (bg === 'low') bg = 'green.300';
  return (
    <Center bg={bg} fontSize={13} {...styles}>
      {startCase(value)}
    </Center>
  );
};

export const getRatingBgColor = value => {
  switch (value) {
    case 'Good':
      return 'green';
    case 'Average':
      return 'orange';
    case 'Poor':
      return 'red';
  }
};
