import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { VMImageIcon } from 'components/Icons';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getVulnerabilityCountInfo } from 'containers/Dashboard/VulnerabilityOverview/Components/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

export const VMImages: React.FC = () => {
  const { vulnerabilitiesCount } = useSelector(selectVulnerabilityOverview);
  const { vmImage } = vulnerabilitiesCount;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Images"
      icon={<VMImageIcon />}
      change={{
        isLoading: vmImage.isLoading,
        first: vmImage.data.previous_count,
        last: vmImage.data.current_count,
        percentChange: vmImage.data.percentage_change,
      }}
      cloudProviders={[]}
      count={vmImage.data.current_count}
      isLoading={vmImage.isLoading}
      onClick={() =>
        navigate('/visibility/vulnerability?tab=' + Resources.Image)
      }
      info={{
        message: getVulnerabilityCountInfo(vmImage.data.vulnerability_count),
        enabled: false,
      }}
      labelTooltip={formatTooltip({
        header: 'VM Images',
        content:
          'Vulnerabilities found in images used to deploy VMs. This identifies the images to patch to resolve issues in a large of deployed VMs',
        width: 48,
      })}
    />
  );
};
