import React, { useEffect, useState } from 'react';

import { FeatureStatus, ToDoStatus } from '@ariksa/profile/api';
import { useToast } from '@chakra-ui/react';
import { each, filter, isEmpty, toArray } from 'lodash';
import { useSelector } from 'react-redux';

import { getRandomNumberInRange } from 'components/DataDisplay';
import { useMyTasks } from 'components/Navigation/NavBar/TopNavBar/RightComponent/MyTasks/useMyTasks';
import { TaskToast } from 'containers/CustomerJourney/TaskToast';
import { selectSharedState } from 'containers/SharedState/selectors';

export const TaskToastComponent = () => {
  const toast = useToast();
  const { journey } = useSelector(selectSharedState);
  const { myTasksMapping, getAllTasksStatus } = useMyTasks();
  const [visibleTasks, setVisibleTasks] = useState<string[]>([]);

  useEffect(() => {
    let items: string[] = [];
    each(
      filter(
        journey.myTasks?.data,
        o =>
          o.status === FeatureStatus.Active &&
          o.user_todo_status !== ToDoStatus.Complete,
      ),
      o => !!localStorage.getItem(o.id) && items.push(o.id),
    );
    setVisibleTasks(items);
  }, [journey.myTasks.data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let tasks = new Set(visibleTasks);
      //filter tasks which are active
      const filteredActiveTasks =
        filter(
          journey.myTasks?.data,
          o =>
            o.status === FeatureStatus.Active &&
            o.user_todo_status !== ToDoStatus.Complete &&
            !tasks.has(o.id),
        ) ?? [];

      if (
        isEmpty(filteredActiveTasks) ||
        visibleTasks.length === filteredActiveTasks?.length
      )
        return;

      //get task status
      getAllTasksStatus(filteredActiveTasks);

      //filter tasks which are not complete
      const filteredTasks = filter(
        filteredActiveTasks,
        o =>
          journey.taskStatus?.[o.id]?.data?.user_todo_status !==
          ToDoStatus.Complete,
      );

      const num = getRandomNumberInRange(0, filteredTasks?.length - 1);
      const task = filteredTasks[num];
      let alreadyAdded = tasks.has(task.id);

      // Display a toast with information
      if (!alreadyAdded) {
        tasks.add(task.id);
        setVisibleTasks(toArray(tasks) as string[]);
        toast({
          duration: 15000,
          isClosable: true,
          position: 'top-right',
          onCloseComplete: () => {
            localStorage.setItem(task.id, 'true');
          },
          render: renderProps => (
            <TaskToast
              {...renderProps}
              description={task?.description}
              onClick={() => {
                localStorage.setItem(task.id, 'true');
                myTasksMapping[task?.id]?.onClickFooter?.();
                renderProps.onClose();
              }}
              onCloseComplete={() => {
                localStorage.setItem(task.id, 'true');
                renderProps.onClose();
              }}
            />
          ),
        });
      }
    }, 30000); // Display a toast every 30 seconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [toast, journey.myTasks?.data, myTasksMapping, visibleTasks]); //do not include visibleTasks

  return <div></div>;
};
