import React from 'react';

import { Box, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { renderTime } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DataClassifierAction } from 'containers/Setup/DataConfiguration/types';

export const getDataClassifierIconColor = (type: DataClassifierAction) => {
  switch (type) {
    case 'Disable':
      return 'orange';
    case 'Delete':
      return 'red';
    default:
      return 'green';
  }
};

export const getDataClassifierText = (actionType, dataType) => {
  switch (actionType) {
    case 'Disable':
      return `NOTE: When Ariksa performs the next scan of data sources, results associated with this ${dataType} will be automatically cleared.`;
    case 'Delete':
      return `After deleting the data ${dataType}, Ariksa will no longer use the criteria for analysis and to associate labels. All open findings associated with this ${dataType} will be automatically cleared, and no new findings will be generated.`;
    default:
      return `NOTE: When Ariksa performs the next scan of the data sources, this data ${dataType} will be applied automatically.`;
  }
};

export const renderNextScan = (value, isLoading) => {
  const time = dayjs.unix(value).local();
  return (
    <HStack color="primary">
      <Box>Next scan:</Box>
      {isLoading && (
        <Box>
          <CustomSpinner loading={isLoading} />
        </Box>
      )}
      {!isLoading &&
        (!!value ? (
          <Box>
            {renderTime({ value: time })} ({dayjs().to(time, true)} from now)
          </Box>
        ) : (
          '-'
        ))}
    </HStack>
  );
};
