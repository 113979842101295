import React from 'react';

import {
  AggregatedAlertDetails,
  AlertResponse,
} from '@ariksa/notification/api';
import { Box, useDisclosure } from '@chakra-ui/react';

import { ActionButton, PolicyExceptionIcon } from 'app/components';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';

export interface AddExceptionActionButtonProps {
  alert?: AlertResponse;
  aggregatedAlert?: AggregatedAlertDetails;
}

export const AddExceptionActionButton = (
  props: AddExceptionActionButtonProps,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <ActionButton
        label="Add Exception"
        icon={<PolicyExceptionIcon />}
        onClick={e => {
          e.stopPropagation();
          onOpen();
        }}
      />
      {isOpen && <AddException isOpen={isOpen} onClose={onClose} {...props} />}
    </Box>
  );
};
