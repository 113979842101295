/**
 *
 * Custom Dashboard
 *
 */

import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { identity } from 'lodash';
// eslint-disable-next-line import/order
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

//import { useTranslation } from 'react-i18next';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { customTheme } from 'theme';

import { IDashboardLayout, IWidget } from './types';
import { Widget } from './Widget';

import './dashboard.css';

interface Props {
  dashboardWidgets: IDashboardLayout;
  onLayoutChange?: (currentLayout: IWidget[]) => void;
  margin?: [number, number];
  marginBottom?: number | string;
}
const ResponsiveReactGridLayout = WidthProvider(Responsive);
export function CDashboard(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const { t, i18n } = useTranslation();
  const { marginBottom = customTheme.sizes.interCardSpacing } = props;
  const { dashboardWidgets } = props;

  const [widgets, setWidgets] = useState<IWidget[]>([]);

  useEffect(() => {
    setWidgets(dashboardWidgets.widgets);
  }, [dashboardWidgets.widgets]);

  const onLayoutChange = (currentLayout: Layout[]) => {
    const updatedWidgets: IWidget[] = [];
    currentLayout?.forEach(item => {
      const w = widgets?.find(widget => item.i === widget.i);
      if (w) updatedWidgets.push({ ...w, ...item });
    });
    setWidgets(updatedWidgets);
    props?.onLayoutChange?.(updatedWidgets);
  };

  return (
    <Box w="100%" mb={marginBottom}>
      <ResponsiveReactGridLayout
        onLayoutChange={onLayoutChange}
        // onBreakpointChange={onBreakpointChange}
        // breakpoints={{ lg: 1200 }}
        cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 2 }}
        margin={
          props?.margin ?? [
            customTheme.sizes.dashboardCardSpacing,
            customTheme.sizes.dashboardCardSpacing,
          ]
        }
        rowHeight={30}
        containerPadding={[0, 0]}
      >
        {widgets
          ?.map((widget: IWidget) => {
            if (!dashboardWidgets.available[widget.i]) {
              console.error(`Widget: ${widget.i} is not available!`);
              return null;
            }
            return (
              <Box key={widget.i} data-grid={widget}>
                <Widget
                  widget={widget}
                  available={dashboardWidgets.available}
                />
              </Box>
            );
          })
          .filter(identity)}
      </ResponsiveReactGridLayout>
    </Box>
  );
}
