/**
 *
 * Elastic For SIEM Integration
 *
 */

import React, { FC, useState } from 'react';

import { useSelector } from 'react-redux';

import { Form } from 'components/index';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { DUMMY_API_KEY } from 'containers/Setup/Integrations/types';

interface Props {
  onSubmit(data);
}

export const ElasticForSIEMForm: FC<Props> = props => {
  const { onSubmit } = props;
  const { client } = useSelector(selectIntegrations);
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();
  const [error, setError] = useState('');

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          isDisabled: actionType === 'Update',
          defaultValue: actionType === 'Update' ? currentRecord?.name : '',
          tooltip:
            'Only alphanumeric, hyphen, underscore and space is allowed!',
          onChange: value => {
            if (value.match(/^[a-zA-Z0-9-_ ]*$/i)) setError('');
            else
              setError(
                'Only alphanumeric, hyphen, underscore and space is allowed!',
              );
          },
          error: error,
        },
        description: {
          type: 'textArea',
          label: 'Description',
          defaultValue: currentRecord?.description,
        },
        url: {
          type: 'text',
          label: 'URL',
          isRequired: true,
          isDisabled: actionType === 'Update',
          defaultValue: currentRecord?.elastic_search?.url,
        },
        api_Key: {
          type: 'text',
          label: 'API Access Key',
          isRequired: true,
          htmlInputType: 'password',
          passwordShow: false,
          isDisabled: actionType === 'Update',
          defaultValue: actionType === 'Update' ? DUMMY_API_KEY : '',
        },
      }}
      buttonOptions={{
        submit: {
          name: actionType === 'Add' ? 'Okay' : actionType,
          isLoading: client.isLoading,
          isDisabled: client.isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={onSubmit}
    />
  );
};
