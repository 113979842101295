import React, { useCallback, useMemo } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { identity, throttle } from 'lodash';

import { PrimaryButton, PrimaryIconButton } from 'components/DataEntry';
import { ServicesIcon, ViewAllIcon } from 'components/Icons';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { EntitySelect } from 'containers/Visibility/SecurityGraphNext/Components/EntitySelect';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSourceNodes } from 'containers/Visibility/SecurityGraphNext/hooks/useSourceNodes';

export const SelectEntity = () => {
  const { searchResult } = useSearchResult();
  const {
    sourceNodeOptions,
    selectedSourceNodeOption,
    updateSelectedSourceNodeOption,
  } = useSourceNodes();

  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnLoadOptions = useCallback(
    throttle((term: string, cb = identity) => {
      const options = {
        label: sourceNodeOptions?.[0]?.label,
        value: sourceNodeOptions?.[0]?.value,
        options: sourceNodeOptions?.[0]?.options?.filter(option => {
          return option?.label?.toLowerCase().includes(term.toLowerCase());
        }),
      };

      cb([options]);
    }, 0),
    [sourceNodeOptions],
  );

  const limitedOptions = useMemo(() => {
    return sourceNodeOptions?.map(group => {
      if (!group.options?.length) {
        return {
          ...group.options?.[0],
          options: group.options?.slice(0, group.options?.length ?? 1),
        };
      }

      return group;
    });
  }, [sourceNodeOptions]);

  return (
    <Stack spacing={3}>
      <SidebarAccordion
        header={'SELECT ENTITY'}
        icon={<ServicesIcon color="primary" />}
      >
        <Stack>
          <Stack spacing={6}>
            <EntitySelect
              searchResult={searchResult}
              selectedSourceNodeOption={selectedSourceNodeOption}
              defaultOptions={limitedOptions}
              handleOnLoadOptions={handleOnLoadOptions}
              updateSelectedSourceNodeOption={updateSelectedSourceNodeOption}
            />
          </Stack>
          <HStack>
            <Box>Show detailed info</Box>
            <PrimaryIconButton
              bg="white"
              aria-label="view details"
              tooltip="View resource details"
              onClick={() => {
                console.log(selectedSourceNodeOption);
                const resource = selectedSourceNodeOption?.value ?? {};
                updateActiveResource({
                  resourceType: resource?.native_name ?? '',
                  uuid: resource?.uuid,
                  accountId: resource.account,
                  resourceId: resource.resource_id,
                });
                onOpenMetadata();
              }}
            >
              <ViewAllIcon />
            </PrimaryIconButton>
          </HStack>
        </Stack>
      </SidebarAccordion>
    </Stack>
  );
};
