import React, { FC, useState } from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { usePageContentContext } from 'components/Layout';

import { AllIcons } from './AllIcons';

interface IMeta {}

export const ResourceIcons: FC<IMeta> = props => {
  const { contentHeight } = usePageContentContext();
  const [term, setTerm] = useState('');

  return (
    <Stack h={contentHeight + 'px'} spacing={6}>
      <Box w={'xl'}>
        <SearchBox
          value={term}
          onChange={setTerm}
          placeholder="Search icons"
          onClickClose={() => setTerm('')}
        />
      </Box>
      <Box flex={1} overflow={'auto'}>
        <AllIcons filterTerm={term} />
      </Box>
    </Stack>
  );
};
