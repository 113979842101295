import React, { FC, useEffect, useState } from 'react';

import { AggregatedAlertDetails } from '@ariksa/notification/api';

import { PageHeaderWithIcon } from 'components/DataDisplay';

interface IAlertDrawerHeader {
  alert: AggregatedAlertDetails;
}

export const AggregatedAlertDrawerHeader: FC<IAlertDrawerHeader> = props => {
  const { alert } = props;
  const [resourceType, setResourceType] = useState('');

  //get resourceType
  useEffect(() => {
    const resourceType = (alert as AggregatedAlertDetails).resource_types?.[0];
    setResourceType(resourceType ?? '-');
  }, [alert]);

  return (
    <PageHeaderWithIcon
      label={alert.description}
      iconType={resourceType}
      labelStyles={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        w: '90%',
      }}
      iconSize="medium"
    />
  );
};
