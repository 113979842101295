import { useCallback, useEffect, useReducer } from 'react';

import { SensitivityCategory } from '@ariksa/data-scanning';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { AlertHistoryBy } from '@ariksa/notification/api';
import dayjs from 'dayjs';
import { each, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { publishedAtOptions } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useCloudAccounts } from 'containers/App/hooks/useCloudAccounts';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { getDataSourceIcon } from 'containers/Visibility/Data/Components/hooks/utils';
import { cloudOptions } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

import { actions } from '../../slice';

export const useDataDashboard = () => {
  const { dataSources, dataTypeSensitiveSources, selectedTab } = useSelector(
    selectDataDashboard,
  );
  const { regions, searchTerm } = useSelector(selectSharedState);
  const dispatch = useDispatch();
  const { accountId, environmentId } = useAccessBoundary();
  const { getResourceAlias } = useResourceType();
  const { getAccountOptions } = useCloudAccounts();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cloudOptions: [
        { label: 'All clouds', value: undefined },
        ...cloudOptions,
      ],
      accountOptions: [],
      sensitivityOptions: [
        { label: 'All sensitivity', value: undefined },
        ...map(SensitivityCategory, o => ({ label: o, value: o })),
      ],
      regionOptions: [],
      sourceTypeOptions: [],
      labelOptions: [
        { label: 'All labels', value: undefined },
        { label: 'PII', value: 'PII' },
        { label: 'PCI', value: 'PCI' },
        { label: 'PHI', value: 'PHI' },
        { label: 'Sensitive', value: 'Sensitive' },
        { label: 'Custom', value: 'Custom' },
      ],
      selectedCloud: { label: 'All clouds', value: undefined },
      selectedRegion: { label: 'All regions', value: undefined },
      selectedAccount: { label: 'All accounts', value: undefined },
      selectedLabel: { label: 'All labels', value: undefined },
      selectedSourceType: { label: 'All source types', value: undefined },
      selectedSensitivity: { label: 'All sensitivity', value: undefined },
      time: publishedAtOptions[0],
      showDashboard: false,
      resourceType: { label: 'All resource types', value: undefined },
    },
  );

  //set region options
  useEffect(() => {
    let options: Record<string, any>[] = [
      { label: 'All regions', value: undefined },
    ];
    each(regions.data, o => options.push({ label: o, value: o }));
    updateState({ regionOptions: options });
  }, [regions.data]);

  useEffect(() => {
    updateState({
      accountOptions: [
        { label: 'All accounts', value: undefined },
        ...getAccountOptions(),
      ],
    });
  }, [getAccountOptions]);

  //set data type options
  useEffect(() => {
    let options: Record<string, any>[] = [
      { label: 'All data types', value: undefined },
    ];
    //each(regions.data, o => options.push({ label: o, value: o }));
    updateState({ dataTypesOptions: options, selectedDataType: options[0] });
  }, []);

  const getDataSourceAlias = useCallback(
    type => {
      switch (type) {
        case SupportedServices.GitLabBranch:
          return 'GitLab';
        case SupportedServices.GitHubBranch:
          return 'GitHub';
        case SupportedServices.BitBucketBranch:
          return 'BitBucket';
        default:
          return getResourceAlias(type);
      }
    },
    [getResourceAlias],
  );

  //set source type options
  useEffect(() => {
    let options: Record<string, any>[] = [
      { label: 'All source types', value: undefined },
    ];
    each(SupportedServices, o =>
      options.push({
        label: getDataSourceAlias(o),
        value: o,
        icon: getDataSourceIcon(o),
      }),
    );
    updateState({ sourceTypeOptions: options, selectedSourceType: options[0] });
  }, [getDataSourceAlias]);

  const getDashboardData = useCallback(() => {
    if (!!environmentId || !!accountId) {
      const payload = {
        environmentId,
        accountIds: !!accountId ? [accountId] : [],
      };
      const days = 7;
      const payloadWithDays = { ...payload, days };

      dispatch(
        actions.getAccountsAnalyzedInfo({
          q: { days },
        }),
      );
      dispatch(
        actions.getDataAnalyzedInfo({
          q: { days },
        }),
      );
      dispatch(
        actions.getDataSourcesAnalyzedInfo({
          q: { days },
        }),
      );
      dispatch(
        actions.getHistoricalTrend({
          q: payloadWithDays,
        }),
      );
      dispatch(
        actions.getSensitiveDataByRegionTrend({
          q: payloadWithDays,
        }),
      );
      dispatch(
        actions.getSensitiveDataByTypeTrend({
          q: payloadWithDays,
        }),
      );

      dispatch(
        actions.getDataSourcesPieChartData({
          q: payload,
        }),
      );
      dispatch(
        actions.getSensitiveDataByType({
          q: payload,
        }),
      );
      dispatch(
        actions.getSensitiveDataBySources({
          q: payload,
        }),
      );
      dispatch(
        actions.getSensitiveDataByRegion({
          q: payload,
        }),
      );

      const endDate = dayjs().format('YYYY-MM-DD');
      const startDate = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
      dispatch(
        actions.getDataFindings({
          q: {
            historyBy: AlertHistoryBy.Trend,
            categoryClass: 'Data Security',
            startDate,
            endDate,
          },
        }),
      );
      dispatch(
        actions.getTotalDataFindings({
          q: {
            environmentId,
            accountId,
          },
        }),
      );
    }
  }, [dispatch, environmentId, accountId]);

  const getDataSources = useCallback(() => {
    if (!environmentId && !accountId) return;
    const payload = {
      accountIds:
        !!accountId || !!state.selectedAccount?.value
          ? [state.selectedAccount?.value ?? accountId]
          : [],
      environmentId,
      page: dataSources.page.info.page_number,
      size: dataSources.page.info.page_size,
      days: state.time?.value,
    };
    dispatch(
      actions.getDataSources({
        q: {
          ...payload,
          region: state.selectedRegion?.value,
          sourceType: state.selectedSourceType?.value,
          cloud: state.selectedCloud?.value,
          label: state.selectedLabel?.value,
        },
        onSuccess: res => {
          !!res?.total &&
            dispatch(
              sharedStateActions.getRiskContext({
                q: {
                  riskContextRequest: {
                    uuids: map(res?.items, o => o?.source_UUID!),
                  },
                },
              }),
            );
        },
      }),
    );
  }, [
    dispatch,
    accountId,
    environmentId,
    dataSources.page.info,
    state.time,
    state.selectedRegion,
    state.selectedSourceType,
    state.selectedCloud,
    state.selectedLabel,
    state.selectedAccount,
  ]);

  const getDataTypes = useCallback(() => {
    if (!environmentId && !accountId) return;
    const payload = {
      accountIds: !!accountId ? [accountId] : [],
      environmentId,
      page: dataSources.page.info.page_number,
      size: dataSources.page.info.page_size,
      days: state.time?.value,
    };
    dispatch(
      actions.getDataTypes({
        q: {
          ...payload,
          cloud: state.selectedCloud?.value,
          label: state.selectedLabel?.value,
          sensitivity: state.selectedSensitivity?.value,
          searchTerm,
        },
        onSuccess: res => {
          !!res?.total &&
            dispatch(
              actions.getRiskContextForDataTypes({
                q: {
                  accountId: !!accountId ? [accountId] : [],
                  environmentId: environmentId!,
                  dataType: map(res?.items, o => o.sensitive_data_name),
                },
              }),
            );
        },
      }),
    );
  }, [
    dispatch,
    accountId,
    environmentId,
    dataSources.page.info,
    state.time,
    state.selectedCloud,
    state.selectedLabel,
    state.selectedSensitivity,
    searchTerm,
  ]);

  const getDocumentTypes = useCallback(() => {
    if (!environmentId && !accountId) return;
    const payload = {
      accountIds: !!accountId ? [accountId] : [],
      environmentId,
      page: dataSources.page.info.page_number,
      size: dataSources.page.info.page_size,
      days: state.time?.value,
    };
    dispatch(
      actions.getDocumentTypes({
        q: {
          ...payload,
          cloud: state.selectedCloud?.value,
          label: state.selectedLabel?.value,
          searchTerm,
        },
        onSuccess: res => {
          !!res?.total &&
            dispatch(
              actions.getRiskContextForDataTypes({
                q: {
                  accountId: !!accountId ? [accountId] : [],
                  environmentId: environmentId!,
                  dataType: map(res?.items, o => o.type_id),
                },
              }),
            );
        },
      }),
    );
  }, [
    dispatch,
    accountId,
    environmentId,
    dataSources.page.info,
    state.time,
    state.selectedCloud,
    state.selectedLabel,
    searchTerm,
  ]);

  const getDataFootprint = useCallback(() => {
    if (!environmentId && !accountId) return;
    const payload = {
      accountIds: !!accountId ? [accountId] : undefined,
      environmentId: environmentId as string,
    };
    dispatch(
      actions.getPubliclyExposedDataSources({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : undefined,
        },
      }),
    );
    dispatch(
      actions.getSummaryOfAllDataSources({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : undefined,
        },
      }),
    );
    dispatch(
      actions.getAnalyzedSources({
        q: payload,
      }),
    );
    dispatch(
      actions.getSensitiveDataSourceCount({
        q: payload,
      }),
    );
    dispatch(
      actions.getSensitiveDataLabelCount({
        q: payload,
      }),
    );
    dispatch(
      actions.getAlertingCount({
        q: { ...payload, forDataSources: true },
      }),
    );
  }, [dispatch, accountId, environmentId]);

  const getDataTypeSources = useCallback(
    dataType => {
      const payload = {
        accountIds: !!accountId ? [accountId] : [],
        environmentId,
        page: dataTypeSensitiveSources.page.info.page_number,
        size: dataTypeSensitiveSources.page.info.page_size,
        days: state.time?.value,
      };
      dispatch(
        actions.getDataTypeSources({
          q: {
            ...payload,
            region: state.selectedRegion?.value,
            sourceType: state.selectedSourceType?.value,
            cloud: state.selectedCloud?.value,
            label: state.selectedLabel?.value,
            dataType,
          },
          onSuccess: res => {
            !!res?.total &&
              dispatch(
                sharedStateActions.getRiskContext({
                  q: {
                    riskContextRequest: {
                      uuids: map(res?.items, o => o?.source_UUID!),
                    },
                  },
                }),
              );
          },
        }),
      );
    },
    [
      dispatch,
      accountId,
      environmentId,
      dataTypeSensitiveSources.page.info,
      state.time,
      state.selectedRegion,
      state.selectedSourceType,
      state.selectedCloud,
      state.selectedLabel,
    ],
  );

  const getAllDataResourceTypes = useCallback(() => {
    dispatch(
      actions.getAllDataResourceTypes({
        q: {
          accountIds: !!accountId ? [accountId] : [],
          environmentId,
        },
      }),
    );
  }, [dispatch, accountId, environmentId]);

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  const getTableData = useCallback(() => {
    selectedTab === IconTypes.DataSources
      ? getDataSources()
      : selectedTab === 'DataTypes'
      ? getDataTypes()
      : selectedTab === 'all'
      ? getAllDataResourceTypes()
      : getDocumentTypes();
  }, [
    selectedTab,
    getDataSources,
    getDataTypes,
    getDocumentTypes,
    getAllDataResourceTypes,
  ]);

  return {
    getDashboardData,
    getDataSources,
    getTableData,
    time: state.time,
    cloudOptions: state.cloudOptions,
    accountOptions: state.accountOptions,
    regionOptions: state.regionOptions,
    labelOptions: state.labelOptions,
    sourceTypeOptions: state.sourceTypeOptions,
    selectedRegion: state.selectedRegion,
    selectedAccount: state.selectedAccount,
    selectedCloud: state.selectedCloud,
    selectedLabel: state.selectedLabel,
    selectedSourceType: state.selectedSourceType,
    sensitivityOptions: state.sensitivityOptions,
    selectedSensitivity: state.selectedSensitivity,
    showDashboard: state.showDashboard,
    updateValue,
    getDataFootprint,
    getDataTypes,
    getDataTypeSources,
  };
};
