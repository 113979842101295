import React, { useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, HStack, Divider, Stack, Center } from '@chakra-ui/react';
import { forEach, map, toArray } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorMap, customTheme } from 'theme';

import {
  CustomTooltip,
  formatTooltip,
  getIcon,
  getProviderName,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { Card } from 'components/DataDisplay/Card/Card';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { PrimaryIconButton } from 'components/DataEntry';
import {
  BellIcon,
  BlueprintViewIcon,
  EditIcon,
  IconTypes,
  SummaryIcon,
  ViewAllIcon,
} from 'components/Icons';
import { selectApp } from 'containers/App/selectors';
import { useBlueprint } from 'containers/PolicyHub/Blueprints/hooks/context';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';

interface Props {
  getBlueprint();
}

export const BlueprintSummary = (props: Props) => {
  const navigate = useNavigate();
  const { blueprint, blueprintSummary, attachedWorkflows } = useSelector(
    selectBlueprints,
  );
  const { cloudAccounts, environments } = useSelector(selectApp);
  const [accounts, setAccounts] = useState(0);
  const [clouds, setClouds] = useState<string[]>([]);
  const [repositories, setRepositories] = useState<string[]>([]);
  const { setShowPolicies } = useBlueprint();

  useEffect(() => {
    let accountSet = new Set<string>();
    let cloudSet = new Set<string>();
    let envs = {};
    let accounts = {};
    forEach(environments.data, o => {
      envs[o.id] = o.account_ids;
    });

    forEach(cloudAccounts.data, o => {
      accounts[o.uuid] = o;
    });

    forEach(blueprint.data.configs, o => {
      forEach(envs[o.environment_id!], a => accountSet.add(a));
    });

    let accountCount = 0;
    let repos = new Set<string>();
    const allAccounts = toArray(accountSet) as string[];

    forEach(allAccounts, o => {
      const cloudType = accounts[o]?.cloud_type;
      if (
        cloudType === CloudProviders.Gitlab ||
        cloudType === CloudProviders.Bitbucket ||
        cloudType === CloudProviders.Github
      )
        repos.add(cloudType);
      else {
        accountCount += 1;
        cloudSet.add(cloudType);
      }
    });

    setAccounts(accountCount);
    setRepositories(toArray(repos) as string[]);
    setClouds(toArray(cloudSet) as string[]);
  }, [environments.data, cloudAccounts.data, blueprint.data]);

  const renderField = (iconType, label, value, padding = 0) => (
    <HStack align="start" w="full">
      <Box boxSize={5} p={padding} color="primary">
        <Center>{getIcon(iconType)}</Center>
      </Box>
      <Box>{label + ' (' + value + ')'}</Box>
    </HStack>
  );

  const renderCloudTooltip = (cloudTypes, header) =>
    formatTooltip({
      header,
      content: (
        <Stack spacing={1}>
          {map(cloudTypes, (o, index) => (
            <Box>
              {index + 1}. {getProviderName(o)}
            </Box>
          ))}
        </Stack>
      ),
      width: 52,
    });

  return (
    <Card
      styles={{
        card: {
          borderColor: colorMap.primary(300),
          border: '1px solid',
          h: '250px',
          w: 'full',
          flex: 1,
        },
      }}
    >
      <Stack w="full" spacing={3}>
        <HStack justify="space-between" w="full">
          <Box w="full">
            <PageHeaderWithIcon
              label="Summary"
              fontSize="md"
              icon={<SummaryIcon bg={customTheme.colors.primary} />}
              reversed
            />
          </Box>

          <HStack>
            <PrimaryIconButton
              p={1.5}
              aria-label="edit"
              onClick={() =>
                navigate(`/compliance?blueprint_id=${blueprint.data?.id}`)
              }
              tooltip={'Show compliance summary'}
            >
              <ViewAllIcon />
            </PrimaryIconButton>

            <PrimaryIconButton
              p={0.5}
              aria-label="edit"
              onClick={() =>
                navigate(`/findings/alerts?blueprint_id=${blueprint.data?.id}`)
              }
              tooltip={'Show findings'}
            >
              <BellIcon />
            </PrimaryIconButton>

            <PrimaryIconButton
              aria-label="edit"
              onClick={() => setShowPolicies(true)}
              tooltip={
                blueprint.data?.created_by === 'system'
                  ? 'View only. Policies not editable.'
                  : 'Edit policies'
              }
            >
              {blueprint.data?.created_by === 'system' ? (
                <BlueprintViewIcon />
              ) : (
                <EditIcon />
              )}
            </PrimaryIconButton>
          </HStack>
        </HStack>
        <Divider />
        <WithSpinner
          loadStatus={{
            loading: blueprintSummary.isLoading || attachedWorkflows.isLoading,
          }}
        >
          <Stack w="full" justify="space-between" h="full" py={2}>
            <HStack w="full">
              {renderField(
                IconTypes.EnforcedPolicies,
                'Enforced Policies',
                blueprint.data?.rules?.length,
              )}
              {renderField(
                IconTypes.PolicyResourceException,
                'Resource Exceptions',
                blueprintSummary.data?.check_stats?.resource_exceptions,
                0.5,
              )}
            </HStack>
            <HStack>
              <Box w="full">
                <CustomTooltip label={renderCloudTooltip(clouds, 'Clouds')}>
                  {renderField(IconTypes.Cloud, 'Clouds', clouds?.length)}
                </CustomTooltip>
              </Box>
              {renderField(IconTypes.Account, 'Cloud Accounts', accounts)}
            </HStack>
            <HStack>
              {renderField(
                IconTypes.Environment,
                'Environments',
                blueprint.data.configs?.length ?? 0,
              )}
              <Box w="full">
                <CustomTooltip
                  label={renderCloudTooltip(repositories, 'Repositories')}
                >
                  {renderField(
                    IconTypes.Code,
                    'Repositories',
                    repositories?.length,
                    0.5,
                  )}
                </CustomTooltip>
              </Box>
            </HStack>
            <HStack>
              {renderField(
                IconTypes.Automation,
                'Workflows',
                attachedWorkflows.data?.length,
                0.5,
              )}
            </HStack>
          </Stack>
        </WithSpinner>
      </Stack>
    </Card>
  );
};
