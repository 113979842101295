import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectRemediation } from 'containers/PolicyHub/Remediation/selector';

import { actions } from '../../slice';

export const useRemediation = () => {
  const dispatch = useDispatch();
  const { remediationList, currentCategory } = useSelector(selectRemediation);
  const getRemediationList = useCallback(() => {
    !!currentCategory &&
      dispatch(
        actions.getRemediationList({
          q: {
            categoryClass: currentCategory as string,
            page: remediationList.page.info.page_number,
            size: remediationList.page.info.page_size,
          },
        }),
      );
  }, [dispatch, currentCategory, remediationList.page.info]);

  const getRemediationCategories = useCallback(() => {
    dispatch(actions.getRemediationCategories({}));
  }, [dispatch]);

  return { getRemediationList, getRemediationCategories };
};
