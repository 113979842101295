import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DistributionCloudfrontIcon = props => (
  <Icon
    viewBox="0 0 43.422 43.422"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <g>
      <path d="M40.686,32.102l-3.268-2.809v2.001H25.836v-9.545h1.922v4.595h4.127v2.001l3.268-2.546l-3.268-2.811v2h-2.829v-3.239h4.093 c3.891,0,7.042-3.152,7.042-7.041c0-3.889-3.151-7.042-7.042-7.042c-0.012,0-0.021,0.002-0.029,0.002 c0.018-0.207,0.03-0.415,0.03-0.626C33.149,3.153,29.998,0,26.107,0c-2.635,0-4.927,1.449-6.135,3.591 c-0.81-0.324-1.691-0.508-2.616-0.508c-3.032,0-5.609,1.92-6.601,4.607c-0.16-0.011-0.319-0.024-0.482-0.024 c-3.889,0-7.042,3.153-7.042,7.042c0,3.889,3.152,7.041,7.042,7.041h4.094v3.239h-2.831v-2l-3.266,2.811l3.266,2.546v-2.001h4.128 v-4.595h1.922v9.545H6.002v-2.001l-3.266,2.809l3.266,2.547v-2h12.883V21.749h2.149v18.405h-2.001l2.677,3.268l2.678-3.268h-2 V21.749h2.148v10.897h12.881v2L40.686,32.102z" />
    </g>
  </Icon>
);
