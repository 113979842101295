import React, { ReactNode } from 'react';

import {
  Box,
  BoxProps,
  Circle,
  HStack,
  Stack,
  StackProps,
  SquareProps,
} from '@chakra-ui/react';
import { map } from 'lodash';

interface Props {
  options: { label: ReactNode; value: any; data?: any; isDisabled?: boolean }[];
  value?: any;
  onChange(option: Record<string, any>);
  allowClickOnIndividual?: boolean;
  styles?: {
    root?: StackProps;
    radioButton?: BoxProps;
    label?: BoxProps;
    circle?: SquareProps;
  };
}

export const RadioButtonGroup = (props: Props) => {
  const {
    options,
    value,
    onChange,
    styles,
    allowClickOnIndividual = false,
  } = props;

  return (
    <Stack {...styles?.root}>
      {map(options, (o, index) => (
        <HStack
          key={index}
          cursor={o?.isDisabled ? 'not-allowed' : 'pointer'}
          //w="full"
          spacing={1}
          onClick={() =>
            !o?.isDisabled && !allowClickOnIndividual && onChange(o)
          }
          opacity={o?.isDisabled ? 0.5 : 1}
        >
          <Box w={6} {...styles?.radioButton}>
            <Circle
              p={1}
              border={value === o?.value ? '1px solid' : '1px solid'}
              borderColor={value === o?.value ? 'primary' : 'gray.200'}
              size={3.5}
              onClick={e =>
                !o?.isDisabled && allowClickOnIndividual && onChange(o)
              }
              {...styles?.circle}
            >
              <Circle size={1.5} bg={value === o.value ? 'primary' : '#fff'} />
            </Circle>
          </Box>
          <Box
            fontSize="sm"
            fontWeight={400}
            onClick={e =>
              !o?.isDisabled && allowClickOnIndividual && onChange(o)
            }
            color="black"
            w="full"
            {...styles?.label}
          >
            {o.label}
          </Box>
        </HStack>
      ))}
    </Stack>
  );
};
