import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.roles || initialState;

export const selectRoles = createSelector(
  [selectDomain],
  rolesState => rolesState,
);
