/**
 *
 * Asynchronously loads the component for Settings
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Settings = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Settings,
);
