import React, { FC, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { CustomDayPicker } from 'components/DataEntry/Date';

import {
  DayPickerFieldSchema,
  DayPickerFieldStyles,
  useErrorMessage,
} from '../index';
import { useStyles } from '../index';
import { FieldProps } from '../types';

import { FormLabel } from './FormLabel';

import './styles.css';

export const DayPickerField: FC<FieldProps<DayPickerFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    helpIcon,
    dateProps,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const { register, watch, setValue } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<DayPickerFieldStyles>('dayPickerField', styles);

  useEffect(() => {
    register(name); // custom register Antd input
  }, [register, name]);

  useEffect(() => {
    dateProps?.selected && setValue(name, dateProps?.selected);
  }, [name, dateProps?.selected, setValue]);

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  useEffect(() => {}, []);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Skeleton isLoaded={!isLoading}>
        <CustomDayPicker
          data-test={id}
          button={false}
          styles={fieldStyles?.dayPicker}
          dateProps={{
            ...dateProps,
          }}
        />
      </Skeleton>

      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
