/**
 *
 * Asynchronously loads the component for Setup
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Setup = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Setup,
);
