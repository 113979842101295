/**
 *
 * Steps
 *
 */
import React, { FC, ReactNode } from 'react';

import { Box, Center, CenterProps, HStack, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { customTheme } from 'theme';

import { AriksaIcon, NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { TimelineCard } from 'components/DataDisplay/Timeline/TimelineCard';

interface Props {
  steps: {
    component: ReactNode;
    icon: ReactNode;
    styles?: {
      iconContainer?: CenterProps;
    };
  }[];
  timelineCardWidth?: any;
  isLoading?: boolean;
}

export const Timeline: FC<Props> = props => {
  const { steps, isLoading = false, timelineCardWidth } = props;

  return (
    <Box w="full" h="full">
      {isLoading && <CustomSpinner loading={isLoading} size="lg" />}
      {!isLoading && isEmpty(steps) && <NoDataAvailable />}
      {!isLoading && (
        <Stack ml={8} spacing={6}>
          {map(steps, (step, index) => (
            <HStack spacing={6}>
              <Center pos={'relative'}>
                {steps?.length !== 1 && index !== steps?.length - 1 && (
                  <Box
                    w={'1px'}
                    h={'80px'}
                    pos={'absolute'}
                    bg={customTheme.colors.gray['100']}
                    left={'50%'}
                    top={'50%'}
                    zIndex={-1}
                  />
                )}

                {steps?.length !== 1 && index !== 0 && (
                  <Box
                    w={'1px'}
                    h={'80px'}
                    pos={'absolute'}
                    bg={customTheme.colors.gray['100']}
                    left={'50%'}
                    bottom={'50%'}
                    zIndex={-1}
                  />
                )}
                <AriksaIcon
                  icon={step.icon}
                  size={'small'}
                  bg={(step.styles?.iconContainer?.bg ?? '') as string}
                  circle
                  style={{ w: 8, h: 8, p: 1 }}
                />
              </Center>
              {/*<Flex mr={4}>
                <Divider orientation="vertical" borderColor="componentBorder" />

                <Center
                  zIndex={9999}
                  position="relative"
                  left={-3}
                  w={6}
                  h={6}
                  mt={5}
                  rounded="50%"
                  border="1px solid"
                  borderColor="componentBorder"
                  color="white"
                  bg="primary"
                  p={1}
                  {...each?.styles?.iconContainer}
                >
                  <Center w="full" h="full">
                    {each.icon}
                  </Center>
                </Center>
              </Flex>*/}
              <Box w="full">
                <TimelineCard
                  content={step.component}
                  width={timelineCardWidth}
                />
              </Box>
            </HStack>
          ))}
        </Stack>
      )}
    </Box>
  );
};
