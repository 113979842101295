import React, { FC } from 'react';

import { TemplatePermissions } from '@ariksa/cloud-account/api';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { map } from 'lodash';

import { formatTitle } from 'components/DataDisplay';
import { collapseExpandIcon } from 'containers/Visibility/Components/InventoryRowDetails/utils';

import { permissionStyles } from '../styles';

interface Props {
  permission: TemplatePermissions;
}

export const PermissionComponent: FC<Props> = props => {
  const { permission } = props;

  return (
    <AccordionItem border="none" key={permission.service + '-permission'}>
      {({ isExpanded }) => (
        <>
          <AccordionButton {...permissionStyles.button}>
            {collapseExpandIcon(isExpanded)}
            <Flex {...permissionStyles.label}>
              {formatTitle(permission.service)} (Resource: {permission.resource}
              )
            </Flex>
          </AccordionButton>
          <AccordionPanel pb={2} pl={5}>
            <Stack spacing={1}>
              {map(permission.permission, p => (
                <Box>{p}</Box>
              ))}
            </Stack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
