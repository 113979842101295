import React, { useCallback, useEffect, useState } from 'react';

import {
  Center,
  Stack,
  HStack,
  Flex,
  Box,
  ButtonGroup,
} from '@chakra-ui/react';
import { includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  DetailsIdentifier,
  renderSeverityBubble,
  T1,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CancelButton, PrimaryButton } from 'components/DataEntry';
import { CheckmarkIcon, MinusIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';

import { resourceTypeRulesColumns } from '../utils';

interface Props {
  resource: Record<string, any>;
  blueprint?: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
  applyOn?: 'single' | 'multiple';
}

export const AddException: React.FC<Props> = props => {
  const {
    resource = {},
    isOpen,
    onClose,
    blueprint,
    applyOn = 'multiple',
  } = props;
  const dispatch = useDispatch();
  const { rulesForException, selectedBlueprint, addException } = useSelector(
    selectCompliance,
  );
  const { selectedResource } = useCompliance();
  const { withAccessBoundary } = useAccessBoundary();
  const [selectedRules, setSelectedRules] = useState<string[]>(
    !!resource?.rule_id ? [resource?.rule_id] : [],
  );

  useEffect(() => {
    if (selectedBlueprint?.id && applyOn === 'multiple')
      dispatch(
        actions.loadRulesForException({
          q: withAccessBoundary({
            blueprintId: blueprint?.id ?? selectedBlueprint?.id,
            resourceId: selectedResource?.resource_id,
            standardId: selectedResource?.id,
            resourceType: selectedResource.type,
          }),
        }),
      );
  }, [
    dispatch,
    selectedBlueprint,
    selectedResource,
    withAccessBoundary,
    blueprint,
    applyOn,
  ]);

  const handleSelectionChange = (selected: string[]) => {
    setSelectedRules(selected);
  };

  const handleToggleSelect = select => {
    if (select) {
      handleSelectionChange(rulesForException.data?.map(r => r?.rule_id) ?? []);
    } else {
      handleSelectionChange([]);
    }
  };

  const onClickAddException = useCallback(() => {
    dispatch(
      actions.addException({
        q: {
          blueprintId: blueprint?.id ?? selectedBlueprint?.id,
          ruleInstanceMultiCreate: {
            rules: selectedRules?.map(o => ({ rule_id: o })),
          },
        },
        onSuccess: () => {
          onClose();
        },
      }),
    );
  }, [dispatch, selectedRules, blueprint, selectedBlueprint, onClose]);

  const onRowClick = row => {
    if (includes(selectedRules, row?.rule_id))
      handleSelectionChange(selectedRules.filter(a => a !== row?.rule_id));
    else handleSelectionChange([...selectedRules, row?.rule_id]);
  };

  const renderCheckBox = ({
    isChecked,
    isIndeterminate = false,
    header = false,
  }) => (
    <Box
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      bg={isChecked || isIndeterminate ? 'primary' : 'white'}
      boxSize={5}
      borderRadius={3}
      onClick={() =>
        header &&
        handleToggleSelect(
          selectedRules.length < rulesForException?.data?.length,
        )
      }
      color="white"
    >
      <Center>
        {isIndeterminate ? (
          <MinusIcon />
        ) : isChecked ? (
          <CheckmarkIcon />
        ) : (
          <></>
        )}
      </Center>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          iconSize="medium"
          label="Apply Exception"
          iconType="Exception"
        />
      }
      styles={{
        modal: { size: '5xl' },
      }}
      body={
        <Stack h="lg">
          <HStack justifyContent="space-between" alignItems="flex-start">
            <Stack flex={0.4}>
              <DetailsIdentifier
                label={'Resource type'}
                value={resource?.native_resource ?? selectedResource?.type}
              />
              {/*<KeyValuePair
                label={'Environment'}
                value={resource?.environment}
              />*/}
              <DetailsIdentifier
                label={'Suppression rule selected'}
                value={selectedRules?.length}
              />
            </Stack>
            {/*<Stack
              flex={0.6}
              border="1px solid"
              borderColor={colorMap.primary(300)}
              borderRadius={6}
              p="4"
            >
              <T1 fontWeight="bold" fontSize="md">
                How will this affect your compliance for this environment?
              </T1>
              <Flex flexDir="row" justifyContent="space-between">
                <Stack flex={0.4}>
                  <T1>Compliance for resource type</T1>
                  <BarProgress max={100} value={100} width="full" />
                  <T1 textAlign="right">(40/40 - 100%)</T1>
                </Stack>
                <Stack flex={0.4}>
                  <T1>Overall compliance</T1>
                  <BarProgress max={100} value={80} width="full" />
                  <T1 textAlign="right">(2000/2500 - 80%)</T1>
                </Stack>
              </Flex>
            </Stack>*/}
          </HStack>

          <Stack h="full" pt="4">
            <Flex flexDir="column">
              <T1 fontWeight="bold" fontSize="md">
                Choose compliance checks to add as exception for this resource
                type
              </T1>
              <T1>
                (Checks will be added as exceptions for all resource instances
                of this type. Suppressing checks may reduce your compliance
                score)
              </T1>
            </Flex>

            <Box h="full">
              <Table
                data={
                  applyOn === 'multiple' ? rulesForException.data : [resource]
                }
                onRowClick={onRowClick}
                cursor="pointer"
                columns={[
                  {
                    header: renderCheckBox({
                      isChecked:
                        !!selectedRules?.length &&
                        selectedRules.length === rulesForException.data?.length,
                      isIndeterminate:
                        !!selectedRules.length &&
                        selectedRules.length !== rulesForException.data?.length,
                      header: true,
                    }),
                    render: ({ row }) =>
                      renderCheckBox({
                        isChecked: selectedRules.includes(row?.rule_id),
                      }),
                    styles: { width: 4, cell: { minWidth: 4 } },
                  },
                  ...resourceTypeRulesColumns,
                  {
                    header: 'Severity',
                    accessor: 'severity',
                    styles: {
                      'min-width': '100px',
                      cell: {
                        'min-width': '100px',
                      },
                    },
                    render: renderSeverityBubble,
                  },
                ]}
                isLoading={rulesForException.isLoading}
              />
            </Box>
          </Stack>
          <ButtonGroup
            variant="solid"
            spacing="6"
            justifyContent="space-between"
            pt="4"
          >
            <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
            <PrimaryButton
              onClick={onClickAddException}
              isLoading={addException?.isLoading}
            >
              Apply Exception
            </PrimaryButton>
          </ButtonGroup>
        </Stack>
      }
    />
  );
};
