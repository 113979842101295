import React from 'react';

import {
  Box,
  Stack,
  List,
  ListItem,
  ListIcon,
  HStack,
  Center,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H3 } from 'components/DataDisplay';

interface Props {
  onClose: () => void;
}

export const DataPostureManagement = (props: Props) => {
  const items = [
    `Ariksa provides turnkey data posture management, Ariksa can automatically classify data and analyze access policies for users and machines`,
    `Ariksa creates a graphical data access map that provides a real-time view of where sensitive data exists, who can access, what permissions exist and how permissions are being enabled`,
    `Ariksa allows customization through creation of additional data
          classifiers to capture business logic and organization context`,
  ];
  return (
    <Stack spacing={6}>
      <Box
        pb={3}
        borderBottom="1px solid"
        borderColor={customTheme.colors.gray['100']}
      >
        <H3>Data posture management</H3>
      </Box>
      <List spacing={6}>
        {items.map((o, index) => (
          <ListItem>
            <HStack align="flex-start" spacing={4}>
              <ListIcon
                as={() => (
                  <Box
                    border="1px solid"
                    borderColor="primary"
                    borderRadius="full"
                    mt={1}
                    w={6}
                    h={6}
                  >
                    <Center w="full" h="full" px={2}>
                      {index + 1}
                    </Center>
                  </Box>
                )}
              ></ListIcon>
              <Box>{o}</Box>
            </HStack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
