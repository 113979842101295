export enum OnboardingHelp {
  Permission,
  Automation,
  AutomatedOnboarding,
  ManualOnboarding,
  S3,
  AnalyzeS3,
  DoNotAnalyzeS3,
  None,
}
