import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DeleteProtection,
  IAMDBAuthentication,
  KMSKeyID,
  ReaderEndpoint,
  MasterUsername,
  ListenPort,
  BackupRetentionDays,
  ParameterGroup,
  SubnetGroup,
  Encrypted,
  AssociatedRoles,
  CrossAccountClone,
  NeptuneClusterEndpoint,
  NeptuneClusterInstances,
  ElastiCacheEngine,
} from '../../../Components/MetadataField/MetaGridFields';

export const NeptuneClusterMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const securityGroups = resource?.network?.VpcSecurityGroups;

  const columns = [
    { header: 'ID', accessor: 'VpcSecurityGroupId' },
    { header: 'Status', accessor: 'Status' },
  ];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields createdAtTime="utc" />
        {ElastiCacheEngine(resource)}
        {MasterUsername(resource)}
        {NeptuneClusterEndpoint(resource)}
        {ReaderEndpoint(resource)}
        {ListenPort(resource)}
        {ParameterGroup(resource)}
        {SubnetGroup(resource)}
        {NeptuneClusterInstances(resource)}
        {Encrypted(resource)}
        {KMSKeyID(resource)}
        {IAMDBAuthentication(resource)}
        {DeleteProtection(resource)}
        {AssociatedRoles(resource)}
        {CrossAccountClone(resource)}
        {BackupRetentionDays(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={securityGroups} />}
              iconType={IconTypes.SecurityGroup}
              label="Security groups"
              totalCount={securityGroups?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
