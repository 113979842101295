/**
 *
 * Asynchronously loads the component for Dashboard
 *
 */

import { lazy } from 'react';

export const Dashboard = lazy(() =>
  import('./index').then(m => ({ default: m.Dashboard })),
);
