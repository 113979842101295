/**
 *
 * Welcome To Ariksa
 *
 */
import React from 'react';

import { FeatureDemo } from 'components/FeatureDemo';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const WelcomeToAriksa: React.FC<Props> = props => {
  const { onClose } = props;

  return (
    <FeatureDemo
      onClose={() => {
        onClose();
        localStorage.setItem('showWelcomeScreen', 'false');
      }}
    />
  );
};
