import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DeadLetterQueue,
  DelayedMessages,
  DeliveryDelay,
  FIFOThroughputLimit,
  KeyReusePeriod,
  KMSKeyID,
  LastUpdatedAtUnix,
  MaxMessageSize,
  MessageRetentionPeriod,
  MessagesNotVisible,
  NumberOfMessages,
  ReceiveMessageWaitTime,
  ServerSideEncryption,
  SQSType,
  VisibilityTimeout,
  WhoCanReceive,
  WhoCanSend,
} from '../../../Components/MetadataField/MetaGridFields';

export const SQSMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields
        availabilityZone="None"
        state="None"
        vpc="None"
        createdAtTime="unix"
      />
      {WhoCanSend(resource)}
      {WhoCanReceive(resource)}
      {SQSType(resource)}
      {FIFOThroughputLimit(resource)}
      {LastUpdatedAtUnix(resource)}
      {DeadLetterQueue(resource)}
      {ServerSideEncryption(resource)}
      {KMSKeyID(resource)}
      {KeyReusePeriod(resource)}
      {NumberOfMessages(resource)}
      {DelayedMessages(resource)}
      {MessagesNotVisible(resource)}
      {MaxMessageSize(resource)}
      {MessageRetentionPeriod(resource)}
      {VisibilityTimeout(resource)}
      {DeliveryDelay(resource)}
      {ReceiveMessageWaitTime(resource)}
      <PolicyDocument label="Access policy" value={resource?.config?.Policy} />
    </Grid>
  );
};
