import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SnsNotificationIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M114,418.1c-7.9,0-15,0-22.2,0c-38.9-0.1-73.1-27.5-82.9-64.9c-1.8-6.7-2.7-13.6-2.7-20.6
		c0-70.1,0-140.2,0.1-210.3c0-12.4,0.9-25,5-36.8c10.2-28.9,30-47.7,60-55c6.3-1.5,12.9-2.2,19.3-2.6c45.4-2.7,90.8-0.6,136.2-1.2
		c1.6,0,3.5,0.2,4.9,0.9c4.2,2.1,4.5,5.4,1.5,10.7c-2.9,2.1-6.2,2-9.6,2c-41.8,0.1-83.7,0-125.5,0.4c-8.6,0.1-17.3,1.2-25.6,3.1
		C56.3,47.3,44,57,34.1,69.9c-7.5,9.7-12,20.7-12.9,33.1c-0.7,10.3-2,20.6-2.1,30.9C19,196.8,19,259.7,19.1,322.6
		c0,10.7,0.2,21.6,3.9,31.8c9.8,26.9,28.9,43.5,56.8,49.5c6.3,1.4,13,1.2,19.5,1.4c7.8,0.2,15.6,0.1,22.8,0.1
		c4.2,1.9,5.4,4.9,5.4,8.5c0,1.3,0,2.5,0,3.8c0,20.5,0,41,0,61.4c0,4,0,8,0.2,12c0.3,6.9,5,9.6,10.7,5.6c7.9-5.6,15.4-11.7,23-17.8
		c23-18.2,45.9-36.5,68.8-54.8c12.5-10,26.8-15.7,42.4-18.2c2.7-0.4,5.5-0.3,8.2-0.3c30.6-0.1,61.3,0.1,91.9-0.4
		c12.4-0.2,24.8-0.5,37.1-3.1c19.6-4.2,33.7-15.8,44.4-32c5.9-9,8.4-19.1,9.6-29.7c2.2-19.4,1-38.8,1.4-58.2
		c0.1-2.3,0.2-4.7,0.7-6.9c0.9-3.5,3.7-5.5,6.9-5.2c3,0.3,5.6,3.1,6,6.4c0.1,1.3,0,2.5,0,3.8c0,10.8,0.3,21.6-0.1,32.3
		c-0.5,12-1.1,24-2.6,35.9c-1.9,15.2-8.9,28.4-19,39.9c-11.9,13.5-26.3,23.2-44.2,26.5c-9.9,1.8-20.1,2.9-30.1,3.1
		c-32.1,0.4-64.2,0.2-96.3,0.4c-10.8,0.1-21.3,1.7-31.4,6c-7.1,3-13.3,7.2-19.3,12c-27.7,22.1-55.4,44.3-83.1,66.3
		c-3.4,2.7-7.2,5.1-11,7.3c-9.2,5.3-24.2-0.5-26.4-13.2c-0.7-4.1-1.2-8.4-1.2-12.5c-0.1-19.6-0.1-39.3-0.1-58.9
		C114,423.3,114,421.2,114,418.1z"
      />
      <path
        d="M418.3,0c47.8-0.7,87.6,39.8,87.5,87.7c-0.1,47.7-40,87.6-87.9,87.5c-47.7-0.1-87.6-39.9-87.5-87.9
		C330.5,39.6,370.4-0.8,418.3,0z M343.4,87.2c0.8,41.7,31.9,74.4,74,74.8c41.9,0.4,74.9-31.7,75.1-74.1
		c0.2-41.7-31.7-74.4-73.8-74.8C376.8,12.7,344.7,44.8,343.4,87.2z"
      />
    </g>
  </Icon>
);
