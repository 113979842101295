import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const useEvidence = () => {
  const dispatch = useDispatch();
  const params = useSearchParams();
  const { evidence } = useSelector(selectSecurityGraphNext);
  const { alertDetails } = evidence;

  const getAlertDetails = useCallback(() => {
    if (!!params.alert_id)
      dispatch(
        actions.getAlertDetails({
          q: { alertId: params.alert_id },
          onSuccess: res => {
            dispatch(
              actions.getAlertRiskContext({
                q: { riskContextRequest: { uuids: [res?.entity_uuid!] } },
              }),
            );
            dispatch(
              actions.getAlertCompliance({
                q: {
                  getRuleComplianceStandardRequest: {
                    rules: [res?.alert_rule_id!],
                  },
                },
              }),
            );
            dispatch(
              actions.getAlertStatus({
                q: {
                  alertId: [res?.uuid!],
                },
              }),
            );
          },
        }),
      );
  }, [dispatch, params.alert_id]);

  const getAlertStatus = useCallback(() => {
    dispatch(
      actions.getAlertStatus({
        q: {
          alertId: [alertDetails.data?.uuid!],
        },
      }),
    );
  }, [dispatch, alertDetails.data]);

  return {
    getAlertDetails,
    getAlertStatus,
  };
};
