import React, { FC, useEffect, useState } from 'react';

import { VulnerablePackage } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useCvesById } from 'services/ScanAnalysis/useScanAnalysisItemService';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';
import { useVulnerabilityTableColumns } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilityTableColumns';
import { CVEDetailsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEDetailsDrawer';
import { CVEHostsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHostsDrawer';

interface Props {
  currentRecord: VulnerablePackage;
  isExpanded: boolean;
}

export const CvesById: FC<Props> = props => {
  const { currentRecord, isExpanded } = props;
  const { cvesById } = useCvesById();
  const {
    packagesCVEColumns,
    hostsDisclosure,
    currentCVE,
  } = useVulnerabilityTableColumns();
  const [sortByField, setSortByField] = useState<Sorted>({
    sortField: 'severity',
    sortOrder: 'desc',
  });
  const [currentRow, setCurrentRow] = useState<Record<string, any>>({});
  const cveDetailsDisclosure = useDisclosure();

  useEffect(() => {
    isExpanded &&
      cvesById.sync({
        sortBy: sortByField.sortField,
        sortOrder: sortByField.sortOrder,
        cVEByIDRequest: { vulnerability_ids: currentRecord?.cve_ids },
      });
  }, [currentRecord, isExpanded, cvesById, sortByField]);

  return (
    <SubComponentContainer>
      <Table
        data={cvesById.data?.items ?? []}
        isError={cvesById.isError}
        columns={packagesCVEColumns}
        isLoading={cvesById.isLoading}
        styles={{ header: { position: 'relative', zIndex: 800 } }}
        sortBy={sortByField}
        onSort={sortInfo => {
          if (!isEqual(sortByField, sortInfo)) {
            setSortByField(sortInfo);
          }
        }}
        onRowClick={row => {
          cveDetailsDisclosure.onOpen();
          setCurrentRow(row);
        }}
      />
      {hostsDisclosure.isOpen && (
        <CVEHostsDrawer
          row={currentCVE}
          isOpen={hostsDisclosure.isOpen}
          onClose={hostsDisclosure.onClose}
          selectedTab="cve"
        />
      )}
      {cveDetailsDisclosure.isOpen && (
        <CVEDetailsDrawer
          isOpen={cveDetailsDisclosure.isOpen}
          onClose={cveDetailsDisclosure.onClose}
          currentRecord={currentRow}
        />
      )}
    </SubComponentContainer>
  );
};
