import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, WrapItem, Wrap } from '@chakra-ui/react';
import { map, filter, includes } from 'lodash';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { regionConditionOptions } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/utils';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { selectSharedState } from 'containers/SharedState/selectors';

import { useWhitelistContext } from '../../../../context';

export const RegionCriteria: FC = () => {
  const { formData } = useSelector(selectWhitelist);
  const { regions } = useSelector(selectSharedState);
  const { updateFormData } = useWhitelistContext();

  const [selectedRegions, setSelectedRegions] = useState<Record<string, any>>(
    {},
  );
  const [regionOptions, setRegionOptions] = useState<Record<string, any>[]>([]);

  const [selectedRegionCondition, setSelectedRegionCondition] = useState<
    Record<string, any>
  >({});

  //set region condition
  useEffect(() => {
    setSelectedRegionCondition(regionConditionOptions[0]);
  }, []);

  //set region options
  useEffect(() => {
    setRegionOptions(map(regions.data, o => ({ label: o, value: o })));
  }, [regions]);

  //set selected regions from form data
  useEffect(() => {
    setSelectedRegions(
      filter(regionOptions, o => includes(formData?.region, o?.value)),
    );
  }, [regionOptions, formData?.region]);

  const updateRegion = (field, value) => {
    updateFormData(field, value);
    if (field === 'region')
      updateFormData('region_condition', selectedRegionCondition);
  };

  return (
    <Stack>
      <Stack isInline w="full">
        <Box whiteSpace="nowrap" pt={1}>
          If an entity
        </Box>
        <Box minW={48}>
          <Select
            options={regionConditionOptions}
            value={selectedRegionCondition}
            onChange={selected => {
              setSelectedRegionCondition(selected);
              updateFormData('region_condition', selected);
            }}
          />
        </Box>
        <Box minW={64}>
          <Select
            options={regionOptions}
            value={selectedRegions}
            isMulti
            onChange={selected => {
              updateRegion('region', selected);
            }}
            showTotalSelected={!!selectedRegions.length}
            isLoading={regions.isLoading}
          />
        </Box>
      </Stack>
      <Wrap>
        {map(formData?.region, o => (
          <WrapItem>
            <Tag
              label={o}
              closeButton
              onClose={() =>
                updateFormData(
                  'region',
                  filter(selectedRegions, l => l?.value !== o),
                )
              }
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
