import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { HStack } from '@chakra-ui/react';
import { find, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { SelectHistoricalOptions } from 'components/DataEntry';
import { PrimaryButtonGroup } from 'components/DataEntry/Button/ButtonGroup/PrimaryButtonGroup';
import { OptionItem } from 'components/DataEntry/Button/types';
import { VulnerabilityFiltersMenu } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Vulnerability/VulnerabilityFiltersMenu';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { vulnerabilityButtonGroupOptions } from 'containers/ActiveCloudResource/utils';
import {
  patchAvailableOptions,
  vulnerabilitySeverityOptions,
} from 'containers/Visibility/Vulnerabilities/Components/utils';

export const VulnerabilityTabFilters: FC = () => {
  const {
    vulnerabilityDetails,
    vulnerabilityPublishedTime,
    showResolvedVulnerabilities,
    vulnerabilitySeverity,
    isPatchable,
    resourceType,
  } = useSelector(selectActiveResource);
  const dispatch = useDispatch();
  const [showResolved, setShowResolved] = useState<OptionItem>(
    vulnerabilityButtonGroupOptions[0],
  );

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isPatchable: patchAvailableOptions[0],
      severity: vulnerabilitySeverityOptions[0],
    },
  );

  useEffect(() => {
    updateState({
      isPatchable: find(patchAvailableOptions, o => o.value === isPatchable),
    });
  }, [isPatchable]);

  useEffect(() => {
    console.log(vulnerabilitySeverity, vulnerabilitySeverityOptions);
    updateState({
      severity: find(
        vulnerabilitySeverityOptions,
        o => o.value === vulnerabilitySeverity,
      ),
    });
  }, [vulnerabilitySeverity]);

  useEffect(() => {
    if (showResolvedVulnerabilities)
      setShowResolved(vulnerabilityButtonGroupOptions[1]);
    else setShowResolved(vulnerabilityButtonGroupOptions[0]);
  }, [showResolvedVulnerabilities]);

  const renderHistoricalOptions = () => (
    <SelectHistoricalOptions
      historicalTime={vulnerabilityPublishedTime}
      setHistoricalTime={s =>
        dispatch(actions.updateVulnerabilityPublishedAt(s))
      }
    />
  );

  const updateValue = useCallback(
    (field, value) => {
      switch (field) {
        case 'severity':
          dispatch(actions.updateVulnerabilitySeverity(value?.value));
          break;
        case 'isPatchable':
          dispatch(actions.updateIsPatchable(value?.value));
          break;
      }
    },
    [dispatch],
  );

  return (
    <HStack justify="space-between">
      <PrimaryButtonGroup
        options={vulnerabilityButtonGroupOptions}
        activeKey={showResolved?.key}
        onChange={s => {
          dispatch(
            actions.updateShowResolvedVulnerabilities(s.key === 'resolved'),
          );
        }}
        //isDisabled={some(vulnerabilityDetails, o => o?.isLoading)}
      />

      <HStack>
        <VulnerabilityFiltersMenu
          isLoading={false}
          isPatchable={state.isPatchable}
          severity={state.severity}
          updateValue={updateValue}
          showPackageType={false}
        />
        {renderHistoricalOptions()}
      </HStack>
    </HStack>
  );
};
