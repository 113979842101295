import { AGraphEdge } from './Edge';
import { AGraph } from './Graph';
import { AGraphNode } from './Node';

export class AGraphLayout<G extends AGraph<AGraphNode, AGraphEdge>> {
  cached = false;

  init(graph: AGraph<AGraphNode, AGraphEdge>) {
    throw new Error('Not implemented');
  }

  reset(graph: G) {
    throw new Error('Not implemented');
  }

  // calculated positions of the graph nodes
  layoutNodes(graph: G) {
    throw new Error('Not implemented');
  }

  // calculated positions of the graph edges
  layoutEdges(graph: G) {
    throw new Error('Not implemented');
  }
}
