import { SearchResponse } from '@ariksa/inventory-core';
import { SearchApiSearchRequest } from '@ariksa/inventory-core/api';
import {
  AlertsApiGetEntitySeverityListRequest,
  EntitySeverityListResponse,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { apiCallStateInitialState } from 'api/initial_values';
import {
  GetPolicyPermissionAccountsParams,
  GetPolicyPermissionActionsParams,
  GetPolicyPermissionResourcesParams,
  GetPolicyPermissionServicesParams,
  getRolesPolicies,
  InsightParams,
  InventoryParams,
  PolicyDetailsByIdProps,
  ResourceDetailsProps,
  ResourceHealthCheckProps,
  RoleDetailsProps,
} from 'api/inventory/types';
import { WithFilters } from 'api/types';
import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { valueByEnv } from '../../../utils/env';

import { ContainerState, MapType, Policy } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  inventory: {
    loading: false,
    error: null,
    data: {
      results: [],
      total: 0,
    },
  },
  alerts: QueryState.init({ severity: {} }),
  insights: {
    loading: false,
    error: null,
    loaded: false,
    results: [],
    total: 0,
    currentRecord: {},
  },
  policies: {
    loading: false,
    error: null,
    loaded: false,
    results: [],
    total: 0,
    currentRecord: {},
  },
  resourceDetails: {
    loading: false,
    loaded: false,
    error: null,
    results: {},
    policy_id: '',
  },
  connectedEntities: apiCallStateInitialState<any[]>([]),

  nodeDetails: {
    loading: false,
    loaded: false,
    error: null,
    results: {},
  },
  resourceHealth: {
    loading: false,
    error: null,
    results: [],
    pageInfo: {
      page_number: 1,
      page_size: 10,
    },
  },
  currentPolicy: {},
  detailsProps: {
    user_id: '',
    user_uuid: '',
    account_ids: '',
    resource_type: '',
    resource_id: '',
    name: '',
    role_id: '',
  },
  resourceList: {
    results: [],
    loading: false,
    error: null,
    total: 0,
    pageInfo: {
      page_number: 1,
      page_size: 50,
    },
    currentRecord: {},
  },
  policyDocument: apiCallStateInitialState<any>({}),
  rolePolicies: apiCallStateInitialState<Policy[]>([]),

  // Policy permission
  policyPermission: {
    accounts: apiCallStateInitialState([]),
    services: apiCallStateInitialState([]),
    resources: apiCallStateInitialState([]),
    actions: apiCallStateInitialState([]),
  },

  unusedPermissionList: apiCallStateInitialState({}),

  getAdjacentNodeForExcessPermissionMap: apiCallStateInitialState({}),
  getAdjacentNodeForResourceExcessPermissionMap: apiCallStateInitialState({}),
  getAdjacentNodeForCanAccessMap: apiCallStateInitialState({}),
  getAdjacentNodeForNetworkMap: QueryState.init({}),
  getAdjacentNodeForAccessedMap: apiCallStateInitialState({}),
  getAdjacentNodeForNetAccessMap: apiCallStateInitialState({}),
  getAdjacentNodeForBehaviourMap: apiCallStateInitialState({}),
  getAdjacentNodeForConnectedEntities: apiCallStateInitialState({}),

  // Map
  isMapOpen: valueByEnv(false, false),
  mapType: MapType.ResourceNetAccess,

  resourceInsight: PagedQueryState.init({}),
};

const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    resetConnectedEntity(state, action: PayloadAction<any>) {
      state.connectedEntities.data = [];
    },
    getConnectedEntity(state, action: PayloadAction<any>) {
      onApiCall(state.connectedEntities);
    },
    getConnectedEntitySuccess(state, action: PayloadAction<any>) {
      // state.connectedEntities.data = action.payload;
      onApiCallSuccess(state.connectedEntities);
    },
    getConnectedEntityError(state, action: PayloadAction<any>) {
      onApiCallError(state.connectedEntities, action.payload);
    },

    // Get connected node for NetAccess maps
    getAdjacentNodeForNetAccessMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForNetAccessMap);
    },
    getAdjacentNodeForNetAccessMapSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.getAdjacentNodeForNetAccessMap);
    },
    getAdjacentNodeForNetAccessMapError(state, action: PayloadAction<any>) {
      onApiCallError(state.getAdjacentNodeForNetAccessMap, action.payload);
    },

    // Get connected node for Behaviour maps
    getAdjacentNodeForBehaviourMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForBehaviourMap);
    },
    getAdjacentNodeForBehaviourMapSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.getAdjacentNodeForBehaviourMap);
    },
    getAdjacentNodeForBehaviourMapError(state, action: PayloadAction<any>) {
      onApiCallError(state.getAdjacentNodeForBehaviourMap, action.payload);
    },

    getAdjacentNodeForExcessPermissionMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForExcessPermissionMap);
    },
    getAdjacentNodeForExcessPermissionMapSuccess(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallSuccess(state.getAdjacentNodeForExcessPermissionMap);
    },
    getAdjacentNodeForExcessPermissionMapError(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallError(
        state.getAdjacentNodeForExcessPermissionMap,
        action.payload,
      );
    },
    getAdjacentNodeForExcessPermissionsMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForExcessPermissionMap);
    },
    getAdjacentNodeForExcessPermissionsMapSuccess(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallSuccess(state.getAdjacentNodeForExcessPermissionMap);
    },
    getAdjacentNodeForExcessPermissionsMapError(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallError(
        state.getAdjacentNodeForExcessPermissionMap,
        action.payload,
      );
    },

    getAdjacentNodeForResourceExcessPermissionMap(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCall(state.getAdjacentNodeForResourceExcessPermissionMap);
    },
    getAdjacentNodeForResourceExcessPermissionMapSuccess(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallSuccess(state.getAdjacentNodeForResourceExcessPermissionMap);
    },
    getAdjacentNodeForResourceExcessPermissionMapError(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallError(
        state.getAdjacentNodeForResourceExcessPermissionMap,
        action.payload,
      );
    },

    getAdjacentNodeForCanAccessMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForCanAccessMap);
    },
    getAdjacentNodeForCanAccessMapSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.getAdjacentNodeForCanAccessMap);
    },
    getAdjacentNodeForCanAccessMapError(state, action: PayloadAction<any>) {
      onApiCallError(state.getAdjacentNodeForCanAccessMap, action.payload);
    },

    getAdjacentNodeForNetworkMap(state, action: PayloadAction<any>) {
      state.getAdjacentNodeForNetworkMap = QueryState.next(
        state.getAdjacentNodeForNetworkMap,
        action,
      );
    },

    getAdjacentNodeForAccessedMap(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForAccessedMap);
    },

    getAdjacentNodeForAccessedMapSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.getAdjacentNodeForAccessedMap);
    },
    getAdjacentNodeForAccessedMapError(state, action: PayloadAction<any>) {
      onApiCallError(state.getAdjacentNodeForAccessedMap, action.payload);
    },

    getGetAdjacentNodeForConnectedEntities(state, action: PayloadAction<any>) {
      onApiCall(state.getAdjacentNodeForConnectedEntities);
    },
    getGetAdjacentNodeForConnectedEntitiesSuccess(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallSuccess(state.getAdjacentNodeForConnectedEntities);
    },
    getGetAdjacentNodeForConnectedEntitiesError(
      state,
      action: PayloadAction<any>,
    ) {
      onApiCallError(state.getAdjacentNodeForConnectedEntities, action.payload);
    },

    getUnusedPermissionList(state, action: PayloadAction<any>) {
      onApiCall(state.unusedPermissionList);
    },
    getUnusedPermissionListSuccess(state, action: PayloadAction<any>) {
      state.unusedPermissionList.data = action.payload;
      onApiCallSuccess(state.unusedPermissionList);
    },
    getUnusedPermissionListError(state, action: PayloadAction<any>) {
      onApiCallError(state.unusedPermissionList, action.payload);
    },

    getRolePoliciesById(state, action: PayloadAction<getRolesPolicies>) {
      onApiCall(state.rolePolicies);
    },
    getRolePoliciesByIdSuccess(state, action: PayloadAction<any>) {
      state.rolePolicies.data = action.payload;
      onApiCallSuccess(state.rolePolicies);
    },
    getRolePoliciesByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.rolePolicies, action.payload);
    },

    // Policy permission
    resetPolicyPermission(state) {
      state.policyPermission = initialState.policyPermission;
    },

    getPolicyPermissionAccounts(
      state,
      action: PayloadAction<GetPolicyPermissionAccountsParams>,
    ) {
      state.policyPermission.accounts.data = [];
      onApiCall(state.policyPermission.accounts);
    },
    getPolicyPermissionAccountsSuccess(state, action: PayloadAction<any>) {
      state.policyPermission.accounts.data = action.payload.results ?? [];
      onApiCallSuccess(state.policyPermission.accounts);
    },
    getPolicyPermissionAccountsError(state, action: PayloadAction<any>) {
      onApiCallError(state.policyPermission.accounts, action.payload);
    },

    getPolicyPermissionServices(
      state,
      action: PayloadAction<GetPolicyPermissionServicesParams>,
    ) {
      state.policyPermission.services.data = [];
      onApiCall(state.policyPermission.services);
    },
    getPolicyPermissionServicesSuccess(state, action: PayloadAction<any>) {
      state.policyPermission.services.data = action.payload.results ?? [];
      onApiCallSuccess(state.policyPermission.services);
    },
    getPolicyPermissionServicesError(state, action: PayloadAction<any>) {
      onApiCallError(state.policyPermission.services, action.payload);
    },

    getPolicyPermissionResources(
      state,
      action: PayloadAction<GetPolicyPermissionResourcesParams>,
    ) {
      onApiCall(state.policyPermission.resources);
    },
    getPolicyPermissionResourcesSuccess(state, action: PayloadAction<any>) {
      state.policyPermission.resources.data = action.payload.results ?? [];
      onApiCallSuccess(state.policyPermission.resources);
    },
    getPolicyPermissionResourcesError(state, action: PayloadAction<any>) {
      onApiCallError(state.policyPermission.resources, action.payload);
    },

    getPolicyPermissionActions(
      state,
      action: PayloadAction<GetPolicyPermissionActionsParams>,
    ) {
      onApiCall(state.policyPermission.actions);
    },
    getPolicyPermissionActionsSuccess(state, action: PayloadAction<any>) {
      state.policyPermission.actions.data = action.payload.results ?? [];
      onApiCallSuccess(state.policyPermission.actions);
    },
    getPolicyPermissionActionsError(state, action: PayloadAction<any>) {
      onApiCallError(state.policyPermission.actions, action.payload);
    },

    /*changeCurrentInventoryRecord(state, action: PayloadAction<any>) {
      state.inventory.currentRecord = action.payload;
    },*/
    loadInventory(state, action: PayloadAction<InventoryParams>) {
      state.inventory.data.results = [];
      state.inventory.data.total = 0;
      onApiCall(state.inventory);
    },
    loadInventorySuccess(state, action: PayloadAction<any>) {
      state.inventory.data = action.payload;
      onApiCallSuccess(state.inventory);
    },
    loadInventoryError(state, action: PayloadAction<any>) {
      state.inventory.data.results = [];
      state.inventory.data.total = 0;
      onApiCallError(state.inventory, action.payload);
    },

    // Get Alerts
    loadAlerts(
      state,
      action: QueryAction<
        EntitySeverityListResponse,
        AlertsApiGetEntitySeverityListRequest
      >,
    ) {
      state.alerts = QueryState.next(state.alerts, action);
    },

    changeCurrentInsightsRecord(state, action: PayloadAction<any>) {
      state.insights.currentRecord = action.payload;
      state.detailsProps.account_ids = action.payload.account;
      state.detailsProps.resource_type = action.payload.resource_type;
      state.detailsProps.name = action.payload.name;
      state.detailsProps.resource_id = action.payload.resource_id;
    },

    loadInsights(state, action: QueryAction<any, WithFilters<InsightParams>>) {
      const status = QueryState.emptyFromAction(action);
      state.insights.error = status.error;
      state.insights.loading = status.isLoading;
      state.insights.loaded = status.isSuccess;

      state.insights.results = action.payload.data?.results ?? [];
      state.insights.total = action.payload.data?.total || 0;
    },

    changeCurrentPolicyRecord(state, action: PayloadAction<any>) {
      state.policies.currentRecord = action.payload;
    },
    loadPolicies(state, action: PayloadAction<WithFilters<InventoryParams>>) {
      state.policies.results = [];
      state.policies.total = 0;
      onApiCall(state.policies);
    },
    loadPoliciesSuccess(state, action: PayloadAction<any>) {
      state.policies.results = action.payload.results;
      state.policies.total = action.payload.total;
      onApiCallSuccess(state.policies);
    },
    loadPoliciesError(state, action: PayloadAction<any>) {
      state.policies.results = [];
      state.policies.total = 0;
      onApiCallError(state.policies, action.payload);
    },

    loadResourceDetails(state, action: PayloadAction<ResourceDetailsProps>) {
      state.resourceDetails.results = {};
      onApiCall(state.resourceDetails);
    },
    loadResourceDetailsSuccess(state, action: PayloadAction<any>) {
      state.resourceDetails.results = action.payload.results;
      onApiCallSuccess(state.resourceDetails);
    },
    loadResourceDetailsError(state, action: PayloadAction<any>) {
      state.resourceDetails.results = {};
      onApiCallError(state.resourceDetails, action.payload);
    },

    loadNodeDetails(state, action: QueryAction<any>) {
      state.nodeDetails.results = {};
      onApiCall(state.nodeDetails);
    },
    loadNodeDetailsSuccess(state, action: PayloadAction<any>) {
      state.nodeDetails.results = action.payload;
      onApiCallSuccess(state.nodeDetails);
    },
    loadNodeDetailsError(state, action: PayloadAction<any>) {
      state.nodeDetails.results = {};
      onApiCallError(state.nodeDetails, action.payload);
    },

    //Get role details
    loadRoleDetails(state, action: PayloadAction<RoleDetailsProps>) {
      state.resourceDetails.results = {};
      onApiCall(state.resourceDetails);
    },
    loadRoleDetailsSuccess(state, action: PayloadAction<any>) {
      state.resourceDetails.results = action.payload;
      onApiCallSuccess(state.resourceDetails);
    },
    loadRoleDetailsError(state, action: PayloadAction<any>) {
      state.resourceDetails.results = {};
      onApiCallError(state.resourceDetails, action.payload);
    },

    //Get role details
    loadPolicyDetails(state, action: PayloadAction<PolicyDetailsByIdProps>) {
      state.resourceDetails.results = {};
      state.resourceDetails.policy_id = action.payload.uuid;
      onApiCall(state.resourceDetails);
    },
    loadPolicyDetailsSuccess(state, action: PayloadAction<any>) {
      state.resourceDetails.results = action.payload;
      onApiCallSuccess(state.resourceDetails);
    },
    loadPolicyDetailsError(state, action: PayloadAction<any>) {
      state.resourceDetails.results = {};
      onApiCallError(state.resourceDetails, action.payload);
    },

    resetResourceDetails(state) {
      state.resourceDetails.results = {};
    },

    //Get Resource Health Check WhitelistStatus
    loadResourceHealthStatus(
      state,
      action: PayloadAction<ResourceHealthCheckProps>,
    ) {
      onApiCall(state.resourceHealth);
      state.resourceHealth.results = [];
    },
    resourceHealthStatusLoaded(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.resourceHealth);
      state.resourceHealth.results = action.payload;
    },
    loadResourceHealthStatusError(state, action: PayloadAction<any>) {
      onApiCallError(state.resourceHealth, action.payload);
      state.resourceHealth.results = [];
    },
    updateResourceHealthPageInfo(state, action: PayloadAction<PageInfo>) {
      state.resourceHealth.pageInfo = action.payload;
    },

    updateCurrentPolicy(state, action: PayloadAction<Record<string, any>>) {
      state.currentPolicy = action.payload ?? {};
    },
    updateDetailsProps(
      state,
      action: PayloadAction<{ field: string; value: string }>,
    ) {
      state.detailsProps[action.payload.field] = action.payload.value;
    },

    //Get resource list for map
    loadResourceList(state, action: PayloadAction<WithFilters<InsightParams>>) {
      state.resourceList.results = [];
      onApiCall(state.resourceList);
    },
    loadResourceListSuccess(state, action: PayloadAction<any>) {
      state.resourceList.results = action.payload.results;
      state.resourceList.total = action.payload.total;
      onApiCallSuccess(state.resourceList);
    },
    loadResourceListError(state, action: PayloadAction<any>) {
      state.resourceList.results = [];
      onApiCallError(state.resourceList, action.payload);
    },
    changeSelectedResource(state, action: PayloadAction<any>) {
      state.resourceList.currentRecord = action.payload;
    },
    updateNodeDetails(state, action: PayloadAction<any>) {
      state.nodeDetails.results = action.payload;
    },

    getPolicyDocument(state, action: PayloadAction<any>) {
      onApiCall(state.policyDocument);
    },
    getPolicyDocumentSuccess(state, action: PayloadAction<any>) {
      state.policyDocument.data = action.payload;
      onApiCallSuccess(state.policyDocument);
    },
    getPolicyDocumentError(state, action: PayloadAction<any>) {
      onApiCallError(state.policyDocument, action.payload);
    },

    changeIsMapOpenValue(state) {
      state.isMapOpen = !state.isMapOpen;
    },
    closeMap(state) {
      state.isMapOpen = false;
    },
    openMap(state) {
      state.isMapOpen = true;
    },

    //update resource map type
    updateMapType(state, action: PayloadAction<MapType>) {
      state.mapType = action.payload;
    },

    getResourceInsight(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.resourceInsight = PagedQueryState.next(
        state.resourceInsight,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = visibilitySlice;
export const visibilityActions = actions;
