import { useCallback } from 'react';

import { SearchTermSubCategory } from '@ariksa/inventory-core/api';
import { useNavigate } from 'react-router-dom';

import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

interface UseRedirectToSecurityGraphProps {
  mapType: 'access' | 'posture' | 'identity';
  resourceType: string;
  resourceUuid: string;
  resourceId: string;
  resourceInsight?: string;
  account: string;
  sourceTab?: string;
  subCategory?: SearchTermSubCategory;
}

export const useRedirectToSecurityGraph = () => {
  const navigate = useNavigate();

  const redirectToSecurityGraph = useCallback(
    (props: UseRedirectToSecurityGraphProps) => {
      const {
        mapType,
        resourceType,
        resourceUuid,
        resourceId,
        resourceInsight,
        subCategory,
        sourceTab,
        account,
      } = props;

      const params = {
        map_type: mapType,
        resourceUuid: resourceType,
        source_resource_uuid: resourceUuid,
        resource_id: resourceId,
        resourceInsight,
        resource_type: resourceType,
        sub_category: subCategory,
        source_tab: sourceTab,
        account_id: account,
      };

      if (subCategory) {
        params.sub_category = subCategory;
      }
      const url = securityGraphRedirectUrl(params);

      navigate(url);
    },
    [navigate],
  );

  return {
    redirectToSecurityGraph,
  };
};
