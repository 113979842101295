import { BaseSyntheticEvent, createContext, useContext } from 'react';

export interface WhitelistContextProps {
  handleBack: () => void;
  handleSubmit: (value: any, e?: BaseSyntheticEvent) => void;
  actionType?: 'Update' | 'Create';
  onCancel: () => void;
  tagKeyOptions: Record<string, any>[];
  selectedConditions: Record<string, any>[];
  setSelectedConditions(val: Record<string, any>[]);
  updateFormData: (field: string, value: any) => void;
  updateCriteria: (field: string, value: any) => void;
  resetCriteria: (field: string) => void;
  resourceTypeOptions: Record<string, any>[];
  isDisabledCriteriaNext: boolean;
  setIsDisabledCriteriaNext: (value: boolean) => void;
  hasEC2Criteria: boolean;
  setHasEC2Criteria: (value: boolean) => void;
}

export const WhitelistContext = createContext<WhitelistContextProps>({
  handleSubmit: (value: any, e?: BaseSyntheticEvent) => {},
  onCancel: () => {},
  handleBack: () => {},
  tagKeyOptions: [],
  actionType: 'Create',
  updateFormData: (field: string, value: any) => {},
  updateCriteria: (field: string, value: any) => {},
  resetCriteria: (field: string) => {},
  resourceTypeOptions: [],
  isDisabledCriteriaNext: false,
  setIsDisabledCriteriaNext: () => {},
  setHasEC2Criteria: () => {},
  hasEC2Criteria: false,
  selectedConditions: [],
  setSelectedConditions(val: Record<string, any>[]) {},
});

export const WhitelistContextProvider = WhitelistContext.Provider;

export const useWhitelistContext = () => useContext(WhitelistContext);
