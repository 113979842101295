import React, { memo, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { cloneDeep, map, merge, isEmpty } from 'lodash';
import {
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  Legend,
  CartesianGrid,
  Cell,
  LabelList,
} from 'recharts';
import { ResponsiveContainer } from 'recharts';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { defaultStyles } from './styles';
import { IBarChart } from './types';

export const CustomBarChart = (props: IBarChart) => {
  const {
    data,
    xField,
    yField,
    tooltip = true,
    legend = true,
    colors,
    colorMap,
    stacked = false,
    customTooltipContent,
    styles,
    overwriteDefaultStyles = false,
    label,
    onClick,
    cartesianGrid = false,
    isLoading = false,
  } = props;
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);
  const [localColors] = useState(
    colors ?? yField.map(y => colorMap?.[y]) ?? [],
  );

  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : isEmpty(data) ? (
        <NoDataAvailable />
      ) : (
        <ResponsiveContainer>
          <BarChart data={data} {...baseStyles?.barChart} layout="vertical">
            <XAxis
              type={'number'}
              orientation={'bottom'}
              allowDecimals={false}
              {...baseStyles?.xAxis}
            />
            <YAxis
              type={'category'}
              orientation={'left'}
              dataKey={xField}
              textAnchor="left"
              {...baseStyles?.yAxis}
            />
            {cartesianGrid && (
              <CartesianGrid {...(baseStyles?.cartesianGrid as any)} />
            )}
            {tooltip && (
              <Tooltip
                {...baseStyles?.tooltip}
                content={customTooltipContent}
              />
            )}
            {map(yField, (each, index) => {
              const barColor = colorMap?.[each];
              return (
                <Bar
                  key={`bar-${index}`}
                  {...(baseStyles?.bar as any)}
                  dataKey={each}
                  stackId={stacked && 'id'}
                  width={100}
                  onClick={e => onClick?.(e)}
                >
                  {data.map((e, cellIndex) => {
                    const cellColor =
                      barColor ??
                      colors?.[cellIndex % colors.length] ??
                      `#${Math.floor(Math.random() * 16777215).toString(16)}`;

                    // console.log(cellColor, colors);

                    return <Cell key={`cell-${cellColor}`} fill={cellColor} />;
                  })}

                  {label && (
                    <LabelList dataKey={yField[each]} position="right" />
                  )}
                </Bar>
              );
            })}

            {/*{legend && <Legend {...(baseStyles?.legend as any)} />}*/}
            {legend && (
              <Legend
                {...(baseStyles?.legend as any)}
                payload={yField.map((item, index) => ({
                  id: item,
                  type: 'square',
                  value: `${item}`,
                  color: localColors?.[index % localColors?.length],
                }))}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export const MemoBarChart = memo<IBarChart>(CustomBarChart);
