/**
 *
 * Asynchronously loads the component for Visibility
 *
 */

import { lazy } from 'react';

export const Data = lazy(() =>
  import('./index').then(m => ({ default: m.Data })),
);
