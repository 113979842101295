import React, { FC, useRef } from 'react';

import { StandardRuleCompliance } from '@ariksa/compliance-policies/api';
import { Box, Stack, UseDisclosureProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { StackedHeader } from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { Drawer } from 'components/Overlay';
import { PoliciesUnderSubControl } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/ComplianceBlueprint/PoliciesUnderSubControl';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';

interface Props {
  isOpen: boolean;
  onClose();
  subControl: StandardRuleCompliance;
  addPolicy?: UseDisclosureProps;
  selectedPolicies?: string[];
  setSelectedPolicies?(value: string[]);
  action?: 'Create' | 'Show' | 'Update';
}

export const PoliciesUnderSubControlModal: FC<Props> = props => {
  const {
    isOpen,
    onClose,
    subControl,
    addPolicy,
    selectedPolicies,
    setSelectedPolicies,
    action,
  } = props;
  const { blueprint } = useSelector(selectBlueprints);
  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1200px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <StackedHeader
            upper={blueprint.data.name}
            lower={subControl.control + ' / ' + subControl.sub_control}
            type={subControl.compliance}
            iconFilled={false}
          />
        }
        body={
          <Stack h="full" pt={4} spacing={8}>
            <DetailsField
              label="Sub-Control Description"
              value={subControl.sub_control_description || 'None'}
            />
            <PoliciesUnderSubControl
              subControl={subControl}
              selectedPolicies={selectedPolicies}
              setSelectedPolicies={setSelectedPolicies}
              action={action}
            />
          </Stack>
        }
      />
    </Box>
  );
};
