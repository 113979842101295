import React from 'react';

import { Box, Center } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  renderTime,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { SummaryIcon } from 'components/Icons';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useIndividualAlertsTableColumns } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useIndividualAlertsTableColumns';
import { renderFindings } from 'containers/Findings/Alerts/Components/AlertsTable/utils';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';

export const AggregatedAlertsTable = () => {
  const dispatch = useDispatch();
  const { aggregatedFindings, sortByField } = useSelector(selectAlerts);
  const { getTabIndex } = useMetadataDrawerTabs();

  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const { getCloudNativeName } = useResourceType();
  const {
    blueprint,
    account,
    deployedIn,
    context,
  } = useIndividualAlertsTableColumns();

  const handleRowClick = (row, tabIndex) => {
    // const tabIndex = getTabIndex(row?.resource_type, tabType);
    updateActiveResource({
      tabIndex: tabIndex,
      resourceType: row?.resource,
      resourceId: row?.entity_id,
      uuid: row?.entity_uuid,
      accountId: row?.account_id,
    });
    onOpenMetadata();
  };

  const renderAction = ({ row }) => (
    <Center onClick={e => e.stopPropagation()}>
      {/*<ActionButton
        label="Show security graph"
        icon={<GraphIcon />}
        onClick={() => {
          const tabIndex = getTabIndex(row?.resource, MetadataTabs.Access);
          handleRowClick(row, tabIndex);
        }}
        //isDisabled={some(riskContext, { isLoading: true })}
      />*/}
      {/*<ActionButton label="Show age of open alerts" icon={<ClockIcon />} />*/}
      <ActionButton
        label="Show entity summary"
        icon={<SummaryIcon />}
        onClick={() => {
          handleRowClick(row, 0);
        }}
        //isDisabled={some(riskContext, { isLoading: true })}
      />
    </Center>
  );

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'entity_name',
      sortKey: 'entity_name',
      render: ({ row }) => (
        <WithResourceIcon resourceType={row.resource ?? ''} iconSize="sm">
          <StackedCell
            upper={row?.entity_name ?? row?.entity_id}
            lower={getCloudNativeName(row?.resource)}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Alerts',
      accessor: 'severity',
      render: renderFindings,
      align: 'left',
    },
    blueprint,
    account,
    deployedIn,
    {
      header: 'Last Alert',
      accessor: 'last_seen',
      align: 'left',
      render: renderTime,
    },
    context,
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Table
      data={aggregatedFindings.data}
      columns={columns}
      isLoading={aggregatedFindings.isLoading}
      cursor="pointer"
      onRowClick={row => {
        const tabIndex = getTabIndex(row?.resource, MetadataTabs.Alerts);
        handleRowClick(row, tabIndex);
      }}
      pagination={{
        pageInfo: aggregatedFindings.page.info,
        onChange: info =>
          dispatch(actions.updateAggregatedFindingsPagination(info)),
        totalCount: aggregatedFindings.page.totalCount,
      }}
      sortBy={sortByField}
      onSort={sortInfo => {
        if (!isEqual(sortByField, sortInfo)) {
          dispatch(actions.updateSortInfo(sortInfo));
        }
      }}
      styles={{ header: { zIndex: 800 } }}
    />
  );
};
