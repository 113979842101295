import LRU from 'lru-cache';
import { RBush3D, BBox } from 'rbush-3d';

export interface RTreeEntry extends BBox {
  id: string;
  data: any;
}

export type RemoveRtreeEntryFn = (a: RTreeEntry, b: RTreeEntry) => boolean;
export const defaultRTreeEntryRemover = (a: RTreeEntry, b: RTreeEntry) =>
  a.id === b.id;

export class RTree {
  private tree: RBush3D;

  remover: RemoveRtreeEntryFn;

  private cache: LRU<string, RTreeEntry[]>;

  constructor(remover: RemoveRtreeEntryFn = defaultRTreeEntryRemover) {
    this.tree = new RBush3D();
    this.remover = remover;
    this.cache = new LRU({
      max: 50,
      maxSize: 100,
      sizeCalculation: (value, key) => {
        return 1;
      },
    });
  }

  add(entry: RTreeEntry) {
    this.cache.clear();
    this.remove(entry);
    this.tree.insert(entry);
  }

  remove(entry: RTreeEntry): void {
    this.tree = this.tree.remove(entry, this.remover as any);
  }

  search(entry: BBox): RTreeEntry[] {
    const key = this.encodeBBox(entry);
    if (!this.cache.has(key)) {
      const results = this.tree.search(entry) as RTreeEntry[];
      this.cache.set(key, results);
      return results;
    } else {
      return this.cache.get(key) ?? [];
    }
  }

  clear() {
    this.tree = this.tree.clear();
    this.cache.clear();
  }

  all() {
    return this.tree.all();
  }

  private encodeBBox(box: BBox) {
    const { minX, minY, maxX, maxY, minZ, maxZ } = box;
    return `${minX},${maxX}-${minY},${maxY}-${minZ}:${maxZ}`;
  }
}
