import { IGroup } from '@antv/g-base/lib/interfaces';
import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import Internet from 'images/iconscout/images/internet.jpeg';
import { colors } from 'theme/theme2/colors';

import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { INTERNET_NODE } from './nodes_types';

export function registerInterNetNode() {
  G6.registerNode(
    INTERNET_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group?.addShape('rect', {
          attrs: {
            width: 46,
            height: 46,
            // fill: colors.white,
            shadowColor: '#999',
            shadowBlur: 21,
            shadowOffsetY: 10,
            opacity: 1,
          },
          name: 'container',
        });

        const { x = 0, y = 0, width = 0, height = 0 } = container?.getBBox();

        group?.addShape('text', {
          attrs: {
            x: x,
            y: y + 60 + 10,
            // @ts-ignore
            text: 'Internet',
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'type',
        });

        group?.addShape('image', {
          attrs: {
            x: x,
            y: y,
            width: 46,
            height: 46,
            img: Internet,
          },
          name: 'img',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
