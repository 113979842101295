import React, { FC, useMemo } from 'react';

import {
  Box,
  ButtonGroup,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  IconButton,
  Stack,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import isArray from 'lodash/isArray';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AiOutlineDown, AiOutlineLeft } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';

import { useFormComponent } from 'components/DataEntry/Form/hooks/useFormComponent';

import { useErrorMessage, useStyles } from '../index';
import {
  FieldProps,
  ArrayFieldStyles,
  ArrayFieldSchema,
  ObjectFieldStyles,
  ObjectFieldSchema,
} from '../types';

import { FormLabel } from './FormLabel';

export const ArrayField: FC<FieldProps<ArrayFieldSchema>> = ({
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    isRequired,
    isCollapsable,
    itemField,
    helperText,
    shouldDisplay,
    styles = {},
    helpTooltip,
    helpIcon,
  } = field;

  const { renderField } = useFormComponent();

  const { control, watch } = useFormContext();

  const values = watch({ nest: true });

  const { fields, append, remove } = useFieldArray({ name, control });

  const { isOpen, onOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const arrayStyles = useStyles<ArrayFieldStyles>('arrayField', styles);

  const errorMessage = useErrorMessage(name, label);

  const addItem = () => {
    append({});
    onOpen();
  };

  const tryRemove = (i: number) => {
    try {
      remove(i);
    } catch (e) {
      // console.warn(e);
    }
  };

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const renderFields = () => (
    <Stack {...arrayStyles.arrayContainer}>
      {fields.map((item, i) => (
        <Box key={`${name}-listitem-${i}`} {...arrayStyles.itemContainer}>
          {renderField(isLoading)([`${name}[${i}]`, itemField], item.id)}
          <Box {...arrayStyles.deleteItemContainer}>
            <IconButton
              icon={<FiTrash2 />}
              aria-label="Delete item"
              onClick={() => tryRemove(i)}
              {...arrayStyles.deleteButton}
            />
          </Box>
        </Box>
      ))}
    </Stack>
  );

  return isVisible ? (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...arrayStyles.control}
    >
      <Flex {...arrayStyles.toolbar}>
        {!!label && (
          <FormLabel
            htmlFor={name}
            label={label}
            helpIcon={helpIcon}
            styles={styles?.label}
            helpTooltip={helpTooltip}
            isRequired={isRequired}
          />
        )}
        <ButtonGroup {...arrayStyles.buttonGroup}>
          <IconButton
            icon={<MdAdd />}
            aria-label="Add item"
            onClick={addItem}
            {...arrayStyles.addButton}
          />
          <IconButton
            icon={<FiTrash2 />}
            aria-label="Clear items"
            onClick={() => remove()}
            {...arrayStyles.clearButton}
          />
          {isCollapsable && (
            <IconButton
              icon={isOpen ? <AiOutlineLeft /> : <AiOutlineDown />}
              aria-label={isOpen ? 'Hide items' : 'Show items'}
              onClick={onToggle}
              {...arrayStyles.collapseButton}
            />
          )}
        </ButtonGroup>
      </Flex>
      {isCollapsable ? (
        <Collapse in={isOpen}>{renderFields()}</Collapse>
      ) : (
        renderFields()
      )}
      {!!helperText && (
        <FormHelperText {...arrayStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...arrayStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};

export const ObjectField: FC<FieldProps<ObjectFieldSchema>> = ({
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    isCollapsable,
    isRequired,
    helperText,
    shouldDisplay,
    styles = {},
    helpIcon,
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const { renderField } = useFormComponent();

  const { watch } = useFormContext();

  const values = watch({ nest: true });

  const { isOpen, onToggle } = useDisclosure();

  const objectStyles = useStyles<ObjectFieldStyles>('objectField', styles);

  const errorMessage = useErrorMessage(name, field.label);

  const { colorMode } = useColorMode();

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const { propertyContainer } = objectStyles;

  const renderFields = () => (
    <Stack
      {...objectStyles.objectContainer}
      backgroundColor={
        colorMode === 'dark'
          ? 'inherit'
          : objectStyles.objectContainer?.backgroundColor
      }
    >
      {Object.entries(field.properties).map(
        ([fieldName, objectField], index) => (
          <Box
            key={`${name}-${fieldName}`}
            {...(isArray(propertyContainer)
              ? propertyContainer[index]
              : propertyContainer)}
          >
            {renderField(isLoading)([`${name}.${fieldName}`, objectField])}
          </Box>
        ),
      )}
    </Stack>
  );

  return isVisible ? (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...objectStyles.control}
    >
      <Flex {...objectStyles.toolbar}>
        {!!label && (
          <FormLabel
            htmlFor={name}
            label={label}
            helpIcon={helpIcon}
            styles={objectStyles?.label}
            helpTooltip={helpTooltip}
            isRequired={isRequired}
            onClickHelpIcon={onClickHelpIcon}
          />
        )}
        {isCollapsable && (
          <IconButton
            icon={isOpen ? <AiOutlineLeft /> : <AiOutlineDown />}
            aria-label={isOpen ? 'Hide items' : 'Show items'}
            onClick={onToggle}
            {...objectStyles.collapseButton}
          />
        )}
      </Flex>
      {isCollapsable ? (
        <Collapse in={isOpen}>{renderFields()}</Collapse>
      ) : (
        renderFields()
      )}
      {!!helperText && (
        <FormHelperText {...objectStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...objectStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
