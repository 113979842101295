import { RegionCondition, TagCondition } from '@ariksa/inventory-core';

export const regionConditionOptions = [
  { label: 'is in region', value: RegionCondition.InRegion },
  { label: 'is not in region', value: RegionCondition.NotInRegion },
];

export const tagKeyConditionOptions = [
  { label: 'is tagged', value: TagCondition.Tagged },
  { label: 'is not tagged', value: TagCondition.NotTagged },
];
