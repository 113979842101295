import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useLocation } from 'react-use';

import { selectApp } from 'containers/App/selectors';

import { UserPermission } from './UserPermission';
import { PermissionConfig } from './UserPermissionConfig';

export function useUserPermission() {
  const navigate = useNavigate();
  const { pathname = '/' } = useLocation();
  const { userAuth, user } = useSelector(selectApp);
  const [userPermission, setUserPermission] = useState<UserPermission>(
    new UserPermission([], PermissionConfig),
  );

  useEffect(() => {
    if (pathname !== '/' && !userPermission.includesPath(pathname)) {
      const subPath = pathname.split('/').slice(0, -1).join('/');
      const newPath = userPermission.firstIncludedPath(subPath);
      console.log(newPath, pathname);
      if (pathname !== newPath) {
        console.log('Redirecting to: ', newPath);
        // history.replace(newPath);
      }
    }
  }, [navigate, pathname, userPermission]);

  // console.log('userAuth', userAuth);
  useDeepCompareEffect(() => {
    if (!userPermission.isSameRoles(userAuth.data.roles ?? [])) {
      if (userAuth.data.roles?.length) {
        const permission = new UserPermission(
          userAuth.data.roles,
          PermissionConfig,
        );
        setUserPermission(permission);

        // @ts-ignore
        window.up = permission;
      }
    }
  }, [userPermission, userAuth.data.roles]);

  const resetUserPermission = () => {
    setUserPermission(UserPermission.default());
  };

  return { userPermission, resetUserPermission };
}
