import { createContext, useContext } from 'react';

import { CommonTabProps, SideNavProps } from './types';

export interface SideNavContextProps extends SideNavProps, CommonTabProps {
  isWide: boolean;
}

export const SideNavContext = createContext<SideNavContextProps>({
  menuItems: [],
  currentTab: {
    title: '',
    key: '',
  },
  currentPrimaryKeys: [],
  setCurrentPrimaryKeys: values => {},
  setCurrentTab: value => {},
  isWide: true,
  keyMap: {},
  isLoading: false,
});

export const SideNavContextProvider = SideNavContext.Provider;

export const useSideNavContext = () => useContext(SideNavContext);
