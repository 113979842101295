import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { VirtualMachineIcon } from 'components/Icons';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getVulnerabilityCountInfo } from 'containers/Dashboard/VulnerabilityOverview/Components/utils';

import { selectVulnerabilityOverview } from '../selectors';

export const VirtualMachines: React.FC = () => {
  const { vulnerabilitiesCount } = useSelector(selectVulnerabilityOverview);
  const { vm } = vulnerabilitiesCount;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Virtual Machines"
      icon={<VirtualMachineIcon />}
      change={{
        isLoading: vm.isLoading,
        first: vm.data.previous_count,
        last: vm.data.current_count,
        percentChange: vm.data.percentage_change,
      }}
      cloudProviders={[]}
      count={vm.data.current_count ?? 0}
      isLoading={vm.isLoading}
      onClick={() =>
        navigate(
          '/visibility/vulnerability?tab=' + Resources.VirtualMachine,
        )
      }
      info={{
        message: getVulnerabilityCountInfo(vm.data.vulnerability_count),
        enabled: false,
      }}
      labelTooltip={formatTooltip({
        header: 'Virtual machines',
        content:
          'Vulnerabilities found in virtual machines deployed in your public clouds',
        width: 48,
      })}
    />
  );
};
