/**
 *
 * Asynchronously loads the component for Alerts
 *
 */

import { lazy } from 'react';

export const Alerts = lazy(() =>
  import('./index').then(m => ({ default: m.Alerts })),
);
