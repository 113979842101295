import React from 'react';

import { Tr, Center } from '@chakra-ui/react';

import { TableCell } from 'app/components/DataDisplay/NewTable/Table';

export const RoleRowBody: React.FC<{
  row: any;
  columns: any;
  expanded?: boolean;
}> = ({ row, columns, expanded }) => {
  if (!expanded) {
    return null;
  }
  return (
    <Tr>
      {/* render row header */}
      {columns.map((column, i) => (
        <TableCell text>
          <Center>
            {column.body?.({
              row,
              value: row[column.accessor],
              column,
            })}
          </Center>
        </TableCell>
      ))}
    </Tr>
  );
};
