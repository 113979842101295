import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { MetaGridValueBox } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridValueBox';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DynamoDBServerSideEncryption,
  LastDecreaseDateTime,
  LastIncreaseDateTime,
  LatestStreamsARN,
  NumberOfDecreasesToday,
  ReadCapacityUnits,
  Replicas,
  RestoredFromBackup,
  StreamsEnabled,
  TableSize,
  WriteCapacityUnits,
} from '../../../Components/MetadataField/MetaGridFields';

interface IDynamoDBMetadataAndInsights {}

export const DynamoDBMetadata: FC<IDynamoDBMetadataAndInsights> = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const keySchema = resource?.config?.KeySchema;
  const columns = [
    { header: 'Attribute name', accessor: 'AttributeName', align: 'left' },
    { header: 'Type', accessor: 'KeyType' },
  ];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" vpc={insight} />
      {TableSize(resource)}
      {StreamsEnabled(resource)}
      {LatestStreamsARN(resource)}
      {Replicas(resource)}
      {DynamoDBServerSideEncryption(resource)}
      {RestoredFromBackup(resource)}
      <MetaGridValueBox
        label="Provisioned throughput"
        value={
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            {LastIncreaseDateTime(resource)}
            {LastDecreaseDateTime(resource)}
            {NumberOfDecreasesToday(resource)}
            {ReadCapacityUnits(resource)}
            {WriteCapacityUnits(resource)}
          </Grid>
        }
      />
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={keySchema} />}
            iconType={NativeResources.KeyManagementService}
            label="Key Schema"
            totalCount={keySchema?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
