import { CloudAccountCreate } from '@ariksa/cloud-account';
import {
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiReadConfigRequest,
  CloudAccountGetResponse,
  CodeRepositoryApiGetRepoBranchesRequest,
  CodeRepositoryApiGetRepositoriesByUuidRequest,
  CodeRepositoryApiOnboardCodeRepositoryRequest,
  GetTemplateResponse,
  OnboardingConfigResponse,
  RepositoryInfo,
} from '@ariksa/cloud-account/api';
import { IACApiValidateTfStateRequest } from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { Api, CloudAccountService, InventoryService } from 'app/api';
import { CloudAccountUUID } from 'app/api/inventory/types';
import { ExternalId } from 'app/api/onboarding/types';
import { MemberAccountCreateRequest } from 'containers/Setup/CloudAccounts/CloudAccountWizard/types';
import request from 'utils/request';

import { actions } from './slice';

export function* cloudAccountWizardSaga() {
  //yield takeLatestAction(actions.getTemplates, doGetTemplates);
  yield takeLatestAction(actions.getAccountConfig, doGetAccountConfig);
  yield takeLatestAction(actions.onboardCloudAccount, doOnboardCloudAccount);
  yield takeLatestAction(
    actions.onboardCloudMemberAccount,
    doOnboardCloudMemberAccount,
  );
  yield takeLatestAction(
    actions.getOnboardedCloudAccount,
    doGetOnboardedCloudAccount,
  );
  yield takeLatestAction(
    actions.loadTemplatePermissions,
    doGetTemplatePermissions,
  );
  yield takeLatestAction(actions.getRepositories, doGetRepositories);
  yield takeLatestAction(actions.onboardRepositories, doOnboardRepositories);
  yield takeLatestAction(actions.validateS3Arn, doValidateS3Arn);
  yield takeLatestAction(actions.getBranches, doGetRepoBranches);
  /*yield takeLatestAction(
    actions.onboardAllMemberAccounts,
    doOnboardAllMemberAccounts,
  );*/
}

/*export function* doOnboardAllMemberAccounts(
  ctx: QueryContext<
    any,
    CloudAccountApiOnboardAllSubAccountOfParentAccountRequest
  >,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.onboardAllSubAccountOfParentAccount(
      ctx.params,
    ),
  );
}*/

/*export function* doGetTemplates(ctx: QueryContext<TemplateTypeResponse>) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.getTemplateTypes(),
  );
}*/

export function* doGetAccountConfig(
  ctx: QueryContext<OnboardingConfigResponse, CloudAccountApiReadConfigRequest>,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.readConfig(ctx.params),
  );
}

export function* doOnboardCloudAccount(
  ctx: QueryContext<
    CloudAccountCreate,
    CloudAccountApiOnboardCloudAccountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      CloudAccountService.CloudAccount.onboardCloudAccount(ctx.params) as any,
  );
}

export function* doOnboardCloudMemberAccount(
  ctx: QueryContext<any, MemberAccountCreateRequest>,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.onboardMemberAccount(ctx.params),
  );
}

export function* doGetOnboardedCloudAccount(
  ctx: QueryContext<CloudAccountGetResponse, CloudAccountUUID>,
) {
  yield call(ctx.fetch, () =>
    CloudAccountService.CloudAccount.getCloudAccountByUuid(
      ctx.params.account_uuid,
    ),
  );
}

export function* doGetAWSSQSMessage(action: PayloadAction<ExternalId>) {
  const requestUrl = Api.getSQSMessage(action.payload);
  try {
    yield call(request, requestUrl, { method: 'GET' });
  } catch (err) {
    //yield put(actions.loadAWSConfigError(err));
  }
}

export function* doGetTemplatePermissions(
  ctx: QueryContext<GetTemplateResponse[]>,
) {
  yield call(ctx.fetch, () => CloudAccountService.Template.getPermission(), {
    errorMsg: 'Failed to get template permissions!',
  });
}

export function* doGetRepositories(
  ctx: QueryContext<
    RepositoryInfo[],
    CodeRepositoryApiGetRepositoriesByUuidRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.getRepositoriesByUuid(ctx.params),
    {
      cacheKey: 'doGetRepositories',
      errorMsg: 'Failed to get repositories!',
    },
  );
}

export function* doOnboardRepositories(
  ctx: QueryContext<any, CodeRepositoryApiOnboardCodeRepositoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.onboardCodeRepository(ctx.params),
    {
      errorMsg: 'Failed to onboard repositories!',
    },
  );
}

export function* doValidateS3Arn(
  ctx: QueryContext<boolean, IACApiValidateTfStateRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Iac.validateTfState(ctx.params),
    {
      errorMsg: 'Failed to validate tf state file!',
    },
  );
}

export function* doGetRepoBranches(
  ctx: QueryContext<string[], CodeRepositoryApiGetRepoBranchesRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.getRepoBranches(ctx.params),
    {
      errorMsg: 'Failed to get branches!',
    },
  );
}

export function* doUpdateIACAccount(
  ctx: QueryContext<string[], CodeRepositoryApiGetRepoBranchesRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.onboardCodeRepository(ctx.params),
    {
      errorMsg: 'Failed to get branches!',
    },
  );
}
