import React, { BaseSyntheticEvent, FC, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, SwitchFieldStyles, SwitchFieldSchema } from '../types';

import { FormLabel } from './FormLabel';

export const SwitchField: FC<FieldProps<SwitchFieldSchema>> = ({
  id,
  name,
  field,
}) => {
  const {
    label,
    helperText,
    helpIcon,
    isRequired,
    shouldDisplay,
    isDisabled,
    onChange,
    styles = {},
    defaultValue,
    helpTooltip,
    onClickHelpIcon,
    ...rest
  } = field;

  const { register, watch, setValue } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<SwitchFieldStyles>('switchField', styles);

  const errorMessage = useErrorMessage(name, label);

  React.useEffect(() => {
    setValue(name, defaultValue);
    // console.log(defaultValue);
  }, [defaultValue, name, setValue]);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnChange = (e: BaseSyntheticEvent) => {
    onChange && onChange(e.target.checked, e);
  };

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Switch
        name={name}
        data-testid={id}
        ref={register}
        isDisabled={isDisabled}
        //defaultIsChecked={defaultValue}
        onChange={handleOnChange}
        {...fieldStyles.switch}
        {...rest}
      />
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
