import React from 'react';

import { Stack, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { AriksaQueryComp } from 'containers/PolicyHub/Policies/AriksaQueryBuilder';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';

export const ViewPolicy = () => {
  const { searchQuery } = useSelector(selectPolicyHub);

  return (
    <Box>
      <Stack spacing={10} w={'full'} py={4}>
        <AriksaQueryComp query={searchQuery} />
      </Stack>
    </Box>
  );
};
