/**
 *
 * Alerts
 *
 */

import React, { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Card } from 'components/DataDisplay';
import { usePageContentContext } from 'components/Layout';
import { OpenAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/OpenAlerts';
import { actions } from 'containers/Findings/Alerts/slice';
import { integrationsSaga } from 'containers/Setup/Integrations/saga';
import * as integrationsSlice from 'containers/Setup/Integrations/slice';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useInjector } from 'utils/inject';

export const Alerts = memo(() => {
  /*Alerts reducer, saga is already injected in App*/
  useInjector(
    integrationsSlice.sliceKey,
    integrationsSlice.reducer,
    integrationsSaga,
  );

  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();

  useEffect(() => {
    dispatch(sharedStateActions.getAlertResourceTypes({ q: {} }));
    dispatch(actions.getAlertCategories({ q: {} }));
  }, [dispatch]);

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>{<OpenAlerts />}</Card>
  );
});
