import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authOptions } from 'api/auth';
import {
  OrganizationService,
  OrganizationServiceCreateRequest,
  OrganizationServiceUpdateRequest,
  OrganizationServiceGetRequest,
  UserGroupServiceGetRequest,
  UserGroupService,
} from 'api/auth/api.pb';
import { callApiFn } from 'utils/saga';

import { actions } from './slice';

export function* organizationWizardSaga() {
  yield takeLatest(actions.getOrganizationById.type, doGetOrgById);
  yield takeLatest(actions.updateOrganization.type, doUpdateOrganization);
  yield takeLatest(actions.createOrganization.type, doCreateOrganization);
  yield takeLatest(
    actions.getOrganizationAdminGroupById.type,
    goGetOrganizationAdminGroupById,
  );
}

function* goGetOrganizationAdminGroupById(
  action: PayloadAction<UserGroupServiceGetRequest>,
) {
  try {
    const res = yield call(UserGroupService.Get, action.payload, authOptions());
    yield put(actions.getOrganizationAdminGroupByIdSuccess(res));
  } catch (err) {
    yield put(actions.getOrganizationAdminGroupByIdError(err));
  }
}

function* doUpdateOrganization(
  action: PayloadAction<OrganizationServiceUpdateRequest>,
) {
  yield call(callApiFn, {
    fn: OrganizationService.Update,
    data: action.payload,
    onSuccess: actions.updateOrganizationSuccess,
    onError: actions.updateOrganizationError,
    errorTitle: 'Failed to update Organization.',
  });
}

function* doGetOrgById(action: PayloadAction<OrganizationServiceGetRequest>) {
  // console.log(action.payload, 'im inside of org saga');

  yield call(callApiFn, {
    fn: OrganizationService.Get,
    data: action.payload,
    onSuccess: actions.getOrgByIdSuccess,
    onError: actions.getOrganizationByIdError,
  });
}
function* doCreateOrganization(
  action: PayloadAction<OrganizationServiceCreateRequest>,
) {
  yield call(callApiFn, {
    fn: OrganizationService.Create,
    data: action.payload,
    onSuccess: actions.createOrganizationSuccess,
    onError: actions.createOrganizationError,
    errorTitle: 'Failed to create new organization',
  });
}
