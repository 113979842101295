import React, { useEffect } from 'react';

import { AccessRequestGetResponse } from '@ariksa/jit';
import { Stack } from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon, SyntaxHighlight } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { PolicyIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { actions } from 'containers/Visibility/JitRequests/slice';

interface PolicyDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  request: AccessRequestGetResponse;
}

// @ts-ignore
const json = value => jsonBeautify(value || {}, null, 2, 10);

export const PolicyDocumentModal = (props: PolicyDocumentModalProps) => {
  const { isOpen, onClose, request } = props;

  const dispatch = useDispatch();
  const { policyDocument } = useSelector(selectJitRequests);
  useEffect(() => {
    dispatch(
      actions.getPolicyDocument({
        q: {
          requestId: request.request_id,
        },
      }),
    );
  }, [dispatch, request.request_id]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon label="Policy Document" icon={<PolicyIcon />} />
      }
      body={
        <Stack h={400}>
          <WithSpinner loadStatus={{ loading: policyDocument.isLoading }}>
            <SyntaxHighlight language={'json'}>
              {json(policyDocument.data)}
            </SyntaxHighlight>
          </WithSpinner>
        </Stack>
      }
      styles={{
        modal: { size: '3xl' },
      }}
    />
  );
};
