import G6, { ModelConfig } from '@antv/g6';
import { NativeResources } from '@ariksa/inventory-core';
import { Dict } from 'types/utils';

import {
  iconSwitch,
  iconSwitchWhite,
  resourceIcons,
  resourceIconsWhite,
} from 'components/Icons';
import { TESTING_RECT_NODE } from 'components/Visualization/Graphs/nodes/nodes_types';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { limitedString } from 'utils/string';

import { customTheme } from '../../../../../theme';

export function registerTestingRectNode() {
  const colors = customTheme.colors;
  G6.registerNode(
    TESTING_RECT_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const data: any = cfg?.data ?? {};
        const { active, shape = 'rect', strLimit = 15, severities = {} } = data;
        // console.log(cfg);

        const {
          CRITICAL: critical = 0,
          MEDIUM: medium = 0,
          LOW: low = 0,
        } = severities;

        const isRect = shape === 'rect';
        const isSquare = !isRect;
        const nodeWidth = isRect ? 170 : 46;
        const nodeHeight = 46;
        const container = group.addShape('rect', {
          attrs: {
            width: nodeWidth,
            height: nodeHeight,
            stroke: active ? colors['primary'] : '#ccc',
            // lineWidth: 1,
            opacity: 1,
            radius: 8,
            r: 10,
            cursor: 'pointer',
            fill: isRect ? 'white' : colors['aws'],
          },
          name: 'node',
        });

        const { x = 0, y = 0, height = 0 } = container.getBBox();

        const severityMap = { critical, medium, low };
        const severityNames = ['critical', 'medium', 'low'];
        const severityGroup = group.addGroup({
          name: 'resource_severity_group',
        });
        severityNames.forEach((s, i) => {
          if (!severityMap[s]) return;
          severityGroup.addShape('circle', {
            attrs: {
              x: 8 + x + i * 15,
              y: y - 10,
              stroke: severityMap[s] ? colors[s] : '#ccc',
              fill: severityMap[s] ? colors[s] : 'white',
              opacity: 1,
              r: 4,
              cursor: 'pointer',
            },
            name: s,
          });
        });

        if (isRect) {
          // const blackIcons = [];
          // @ts-ignore
          let img = iconSwitch(data?.resource_type ?? '', resourceIcons);
          //add image
          group?.addShape('image', {
            attrs: {
              x: x + 13,
              y: y + 13,
              width: 20,
              height: 20,
              img: img,
            },
            name: 'img',
          });

          //name
          const { label = '', info = '' } = resourceInfo(data, strLimit);
          const resourceNameGroup = group.addGroup({
            name: 'resource_name_group',
          });
          resourceNameGroup.addShape('text', {
            attrs: {
              x: 46,
              y:
                data?.resource_type === 'Permission' || data.hide_info
                  ? height / 2 + 4
                  : height / 2 - 4,
              text: label,
              fontFamily: 'MetroSans',
              fontWeight: '700',
              fontSize: 12,
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'resource_name',
          });

          if (!data.hide_info) {
            const resourceIDGroup = group.addGroup({
              name: 'resource_id_group',
            });
            resourceIDGroup.addShape('text', {
              attrs: {
                x: 46,
                y: height / 2 + 16,
                // @ts-ignore
                text: info,
                fontSize: 12,
                opacity: 1,
                fill: colors.gray['300'],
                cursor: 'pointer',
              },
              name: 'resource_id',
            });
          }
        }

        if (isSquare) {
          // const blackIcons = [];
          let img = iconSwitchWhite(
            data?.resource_type ?? '',
            resourceIconsWhite,
          );

          //add image
          group?.addShape('image', {
            attrs: {
              x: x + 13,
              y: y + 13,
              width: 20,
              height: 20,
              img: img,
            },
            name: 'img',
          });

          const resourceLabelGroup = group.addGroup({
            name: 'resource_info_group',
          });
          const { label = '', info = '' } = resourceInfo(data, strLimit);
          resourceLabelGroup.addShape('text', {
            attrs: {
              x: x,
              y: height + 20,
              text: label,
              fontSize: 12,
              opacity: 1,
              fill: colors.gray['300'],
              cursor: 'pointer',
            },
            name: 'resource_label',
          });

          !(
            data?.resource_type === 'Egress' ||
            data?.resource_type === 'Ingress'
          ) &&
            resourceLabelGroup.addShape('text', {
              attrs: {
                x: x,
                y: height + 40,
                text: info,
                fontSize: 12,
                opacity: 1,
                fill: colors.gray['300'],
                cursor: 'pointer',
              },
              name: 'resource_info',
            });
        }

        const foregroundGroup = group.addGroup({
          name: 'foreground',
        });

        foregroundGroup.addShape('rect', {
          attrs: {
            width: nodeWidth,
            height: nodeHeight,
            // stroke: color,
            // lineWidth: hasTraffic || active ? 2 : 0.5,
            opacity: 0,
            radius: 8,
            r: 10,
            cursor: 'pointer',
            fill: 'white',
          },
          name: 'node-foreground',
        });

        return container;
      },
      getAnchorPoints() {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );
}

const resourceInfo = (data, strLimit = 15) => {
  let { resource_type, name, resource_id, label, info } = data;
  if (!resource_type) {
    resource_type = data.common?.resource_type;
  }

  if (!name) {
    name = data.common?.name;
  }

  if (!resource_id) {
    resource_id = data.common?.resource_id;
  }

  const firstText = limitedString(label ?? name ?? resource_id ?? '', strLimit);
  const secondText = limitedString(info ?? resource_id ?? '', strLimit);
  const ret = {
    label: firstText,
    info: secondText ?? '-',
  };

  switch (resource_type) {
    case NativeResources.Ec2Instance:
      return {
        ...ret,
        info: data.ec2?.publicIP ?? data.ec2?.private ?? '-',
      };
    case NativeResources.SimpleStorageService:
      return { ...ret, info: data.s3?.enabled ?? '-' };
    case 'Permission':
      return { ...ret, info: '' };
  }

  return ret;
};

export interface NodeResourceInfo extends Dict<any> {
  shape: string;
  common?: { name?: string; resource_type?: string; resource_id?: string };
  s3?: { enabled?: boolean };
  ec2?: { publicIP?: string; privateIP?: string };
}
