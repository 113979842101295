import React, { ReactNode } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

interface Props {
  upper: ReactNode;
  lower: ReactNode;
  upperTooltip?: ReactNode;
  lowerTooltip?: ReactNode;
  showUpperTooltip?: boolean;
  showLowerTooltip?: boolean;
}

export const StackedCell = (props: Props) => {
  const {
    upper,
    lower,
    lowerTooltip,
    upperTooltip,
    showLowerTooltip = true,
    showUpperTooltip = true,
  } = props;

  const commonStyles = {
    w: 'full',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  const upperTooltipValue =
    upperTooltip ?? (upper === '-' ? 'Unknown' : upper) ?? '-';
  const lowerTooltipValue =
    lowerTooltip ?? (lower === '-' ? 'Unknown' : lower) ?? '-';
  return (
    <Stack spacing={0} {...commonStyles}>
      {!!upper && upper !== '-' && showUpperTooltip ? (
        <CustomTooltip label={upperTooltipValue}>
          <Box fontWeight={600} {...commonStyles}>
            {upper}
          </Box>
        </CustomTooltip>
      ) : (
        <Box fontWeight={600} {...commonStyles}>
          {upper ?? '-'}
        </Box>
      )}
      {!!lower && lower !== '-' && showLowerTooltip ? (
        <CustomTooltip label={lowerTooltipValue}>
          <Box color={customTheme.colors.gray['300']} {...commonStyles}>
            {lower}
          </Box>
        </CustomTooltip>
      ) : (
        <Box color={customTheme.colors.gray['300']} {...commonStyles}>
          {lower ?? '-'}
        </Box>
      )}
    </Stack>
  );
};
