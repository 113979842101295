import { useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { Application } from 'pixi.js';
import { Optional } from 'types/utils';

import { usePageContentContext } from 'components/Layout';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { SecurityGraphLayout } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphLayout';
import { SecurityGraphContextProvider } from 'containers/Visibility/SecurityGraphNext/context/SecurityGraphContext';
import { useApplyFilters } from 'containers/Visibility/SecurityGraphNext/hooks/useApplyFilters';
import { useInfoLoader } from 'containers/Visibility/SecurityGraphNext/hooks/useInfoLoader';
import { usePagination } from 'containers/Visibility/SecurityGraphNext/hooks/usePagination';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useBus } from 'hooks/useBus';

export const SecurityGraphNextWrapper = () => {
  const bus = useBus();
  const { pagination } = usePagination(bus);
  const { contentHeight } = usePageContentContext();
  const { searchResult } = useSearchResult();
  const { isLoadingInfo } = useInfoLoader();
  const { nodes, edges, paths, source_items, ordering } = useApplyFilters();
  const [app, setApp] = useState<Optional<Application>>(null);
  const [dirtyCount, setDirtyCount] = useState(0);

  return (
    <ActiveResourceProvider>
      <SecurityGraphContextProvider
        value={{
          edges,
          nodes,
          ordering,
          paths,
          source_items,
          app,
          setApp,
          dirtyCount,
          setDirty() {
            setDirtyCount(count => count + 1);
          },

          isLoading: searchResult.isLoading,
          isLoaded: searchResult.isSuccess,
          isLoadingInfo: isLoadingInfo,
          bus,
          pagination,
        }}
      >
        <Stack height={contentHeight + 'px'}>
          <SecurityGraphLayout />
        </Stack>
      </SecurityGraphContextProvider>
    </ActiveResourceProvider>
  );
};
