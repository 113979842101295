import {
  PageReportsResponse,
  ReportsApiAddReportRequest,
  ReportsApiDeleteReportRequest,
  ReportsApiDownloadReportRequest,
  ReportsApiEditReportRequest,
  ReportsApiGenerateReportRequest,
  ReportsApiGetJobsRequest,
} from '@ariksa/reporting';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ReportingService } from 'api/services';

import { actions } from './slice';

export function* allReportsSaga() {
  yield takeLatestAction(actions.getReports, doGetReports);
  yield takeLatestAction(actions.addReport, doAddReport);
  yield takeLatestAction(actions.updateReport, doUpdateReport);
  yield takeLatestAction(actions.generateReport, doGenerateReport);
  yield takeLatestAction(actions.downloadReport, doDownloadReport);
  yield takeLatestAction(actions.deleteReport, doDeleteReport);
  yield takeLatestAction(actions.getPastReports, doGetPastReports);
}

/*get reports*/
function* doGetReports(ctx: QueryContext<PageReportsResponse>) {
  yield call(ctx.fetch, () => ReportingService.Reports.getAllReports(), {
    errorMsg: 'Failed to get reports!',
  });
}

/*add report*/
function* doAddReport(ctx: QueryContext<any, ReportsApiAddReportRequest>) {
  yield call(ctx.fetch, () => ReportingService.Reports.addReport(ctx.params), {
    errorMsg: 'Failed to add report!',
    successMsg: 'Successfully added report!',
  });
}

/*update report*/
function* doUpdateReport(ctx: QueryContext<any, ReportsApiEditReportRequest>) {
  yield call(ctx.fetch, () => ReportingService.Reports.editReport(ctx.params), {
    errorMsg: 'Failed to update report!',
    successMsg: 'Successfully updated report!',
  });
}

/*add report*/
function* doDeleteReport(
  ctx: QueryContext<any, ReportsApiDeleteReportRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Reports.deleteReport(ctx.params),
    {
      errorMsg: 'Failed to delete report!',
      successMsg: 'Successfully deleted report!',
    },
  );
}

/*generate report*/
function* doGenerateReport(
  ctx: QueryContext<any, ReportsApiGenerateReportRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Reports.generateReport(ctx.params),
    {
      errorMsg: 'Failed to send request for generate report!',
      successMsg: 'Successfully sent request to generate report!',
    },
  );
}

/*generate report*/
function* doDownloadReport(
  ctx: QueryContext<any, ReportsApiDownloadReportRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Reports.downloadReport(ctx.params),
    {
      errorMsg: 'Failed to download report!',
    },
  );
}

/*get reports*/
function* doGetPastReports(ctx: QueryContext<any, ReportsApiGetJobsRequest>) {
  yield call(ctx.fetch, () => ReportingService.Reports.getJobs(), {
    errorMsg: 'Failed to get past reports information!',
  });
}
