import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { OutpostWizard } from 'containers/Setup/Outpost/Components/OutpostWizard';
import { useInjector } from 'utils/inject';

import { OutpostTable } from './Components/OutpostTable';
import { outpostSaga } from './saga';
import { reducer, sliceKey } from './slice';

interface Props {}

export const Outpost = memo((props: Props) => {
  useInjector(sliceKey, reducer, outpostSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/setup/outpost/add/:type" />}>
        <Route path="add/:type" element={<OutpostWizard />} />
      </Route>
      <Route element={<FilterRouter path="/setup/outpost" />}>
        <Route index element={<OutpostTable />} />
      </Route>
    </Routes>
  );
});
