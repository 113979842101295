import React from 'react';

import { Box } from '@chakra-ui/react';

import basicInfo from 'app/images/slack/basicInfo.png';
import createApp from 'app/images/slack/createApp.png';
import installApp from 'app/images/slack/installApp.png';
import listOfScopes from 'app/images/slack/listOfScopes.png';
import permission from 'app/images/slack/permission.png';
import site from 'app/images/slack/site.png';
import token from 'app/images/slack/token.png';

import { CreateTokenStepProps } from './CreateTokenStep';

export const steps: CreateTokenStepProps[] = [
  {
    description: (
      <Box>
        First create your Slack app in the Slack API Control Panel. Log in to
        your workspace in Slack via a web browser and navigate to the{' '}
        <Box
          as="a"
          href="https://api.slack.com/apps"
          target="_blank"
          color="primary"
          borderBottom="1px"
          borderColor="primary"
        >
          API Control Panel
        </Box>
        . Now click on the "Create an App" button.
      </Box>
    ),
    image: site,
    alt: '',
  },
  {
    description: `Provide a name of your app and select a workspace you have
    admin access to. Click on Create App.`,
    image: createApp,
    alt: 'Create a Slack App',
  },
  {
    description: `Once your app is created you’ll be presented with
    the following default app dashboard. In order for your app to be
    able to post messages to a channel you need to grant the app permissions to
    send messages. To do this, click the Permissions button.`,
    image: basicInfo,
    alt: 'Basic Information',
  },
  {
    description:
      'When you arrive at the OAuth & Permissions page, scroll down ' +
      'until you findPath the Scopes section of the page. Then in User Bot Token ' +
      'Scopes subsection click on Add an OAuth Scope button' +
      'and select below mentioned permission.',
    image: listOfScopes,
    alt: 'List of Scopes',
  },
  {
    description:
      'Now that you’ve added the appropriate permissions, it is time ' +
      'to install your app into your Slack workspace. Scroll back up on the ' +
      'OAuth & Permissions page and click the Install App to Workspace ' +
      'button at the top.',
    image: installApp,
    alt: 'Install App Button',
  },
  {
    description: 'Click the Allow button to finish the installation.',
    image: permission,
    alt: 'Permissions',
  },
  {
    description:
      'Once the app is installed you’ll be presented with a ' +
      'User OAuth Access Token. Go ahead and copy this token.',
    image: token,
    alt: 'Token',
  },
];
