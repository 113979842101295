import React from 'react';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';

export const pieChartLegendFormatter = (value, entry, index) => {
  return (
    <span style={{ fontSize: '12px', color: '#666' }}>
      {value + ' (' + formatNumber(entry?.payload?.value) + ')'}
    </span>
  );
};
