import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Flex, HStack, Square } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import {
  getIconColor,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { AwsIcon, IconTypes } from 'components/Icons';
import { PageInfo } from 'components/Navigation';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

interface IIacAccountResources {
  repositoryId: string;
  repositoryUuid: string;
  accountId: string;
  cloud?: string;
  region_count?: number;
  vpcCount?: number;
  resource_count?: number;
  cloud_account_id?: string;
  resourceType?: NativeResources;
  isExpanded?: boolean;
}

const AccountSummary = ({ label, value }) => {
  return (
    <HStack align={'center'}>
      <Flex>{label.toUpperCase()}: </Flex>
      <Flex>{value}</Flex>
    </HStack>
  );
};

export const IacAccountResources: FC<IIacAccountResources> = props => {
  const {
    repositoryUuid,
    repositoryId,
    accountId,
    cloud_account_id,
    resource_count,
    region_count,
    vpcCount,
    isExpanded,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accountDeployedResources, resourceType } = useSelector(
    selectActiveResource,
  );
  const accountDeploymentId = `${repositoryUuid}-${accountId}`;

  // get resources for the target account
  const getResources = useCallback(
    (pageInfo = INIT_PAGE_INFO) => {
      dispatch(
        actions.getAccountDeployedResources({
          q: {
            uuid: repositoryUuid,
            cloudAccountUuid: accountId,
          },
          page: pageInfo,
        }),
      );
    },
    [accountId, dispatch, repositoryUuid],
  );

  useEffect(() => {
    getResources();
  }, [getResources, isExpanded]);

  const columns: TableColumnProps[] = useMemo(
    () => [
      {
        header: 'Resource',
        render: ({ row }) => {
          return (
            <WithResourceIcon resourceType={row.cloud_native_class}>
              <StackedCell
                upper={row.name ?? '-'}
                lower={row.cloud_service ?? '-'}
              />
            </WithResourceIcon>
          );
        },
      },
      {
        header: 'Account',
        accessor: 'cloud_account_id',
        render: ({ row, value }) => {
          return value ?? '-';
        },
      },
      {
        header: 'Region',
        accessor: 'region',
      },
      {
        header: 'VPC',
        accessor: 'vpc',
      },
      {
        header: 'Additional Info',
        accessor: '-',
      },
    ],
    [],
  );

  return (
    <MetaAccordionItem
      panel={
        <Table
          columns={columns}
          data={accountDeployedResources[accountDeploymentId]?.data ?? []}
          isLoading={accountDeployedResources[accountDeploymentId]?.isLoading}
          pagination={{
            totalCount:
              accountDeployedResources[accountDeploymentId]?.page?.totalCount,
            pageInfo:
              accountDeployedResources[accountDeploymentId]?.page?.info ??
              INIT_PAGE_INFO,
            onChange: (info: PageInfo) => {
              getResources(info);
            },
          }}
        />
      }
      iconType={IconTypes.Code}
      iconBGColor={getIconColor(IconTypes.Code)}
      header={
        <HStack justify={'space-between'}>
          <AccountSummary
            label={'cloud'}
            value={
              <Square size={'20px'}>
                <AwsIcon />
              </Square>
            }
          />
          <AccountSummary
            label={'account'}
            value={<CloudAccountName accountId={cloud_account_id ?? '-'} />}
          />
          <AccountSummary label={'region'} value={region_count ?? '-'} />
          <AccountSummary label={'VPC'} value={vpcCount ?? '-'} />
          {/*<AccountSummary label={'Roles'} value={} />*/}
          {/*<AccountSummary label={'Policies'} value={3} />*/}
          <AccountSummary label={'Resources'} value={resource_count ?? '-'} />
        </HStack>
      }
      onShowGraph={() => {
        const url = securityGraphRedirectUrl({
          resource_type: resourceType,
          resource_id: repositoryId,
          source_resource_uuid: repositoryUuid,
          account_id: cloud_account_id,
          map_type: 'posture',
        });
        navigate(url);
      }}
      height="360px"
    />
  );
};
