import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const DatabaseSecurityIcon = createIcon({
  displayName: 'DatabaseSecurityIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <path
        //fill="#0635c9"
        fill="#010101"
        d="M320.047,29.776c-28.969-10.484-67.393-16.258-108.138-16.258s-79.093,5.774-108.063,16.258C72.963,40.953,56,56.589,56,73.8V383.089c0,17.223,16.946,32.861,47.826,44.035,28.962,10.48,67.327,16.251,108.083,16.251,27.428,0,54.325-2.67,77.81-7.723l-2.531-11.732c-22.67,4.877-48.713,7.455-75.306,7.455-39.4,0-76.285-5.517-103.971-15.535C82.509,406.649,68,394.711,68,383.089V303.457c8,7.6,20.149,14.423,35.826,20.1,28.962,10.479,67.327,16.251,108.083,16.251a387.808,387.808,0,0,0,64.659-5.2l-2.016-11.831a376,376,0,0,1-62.67,5.031c-39.4,0-76.285-5.517-103.971-15.535C82.509,303.078,68,291.14,68,279.518V200.6c8,7.6,20.149,14.422,35.826,20.1,28.962,10.48,67.327,16.251,108.083,16.251s79.2-5.771,108.159-16.251C335.745,215.022,348,208.2,356,200.6V273h12V73.8C368,56.589,350.931,40.953,320.047,29.776Zm-4.078,179.636c-27.686,10.017-64.661,15.534-104.06,15.534s-76.3-5.517-103.984-15.534C82.523,200.22,68,188.283,68,176.661V97.734c8,7.6,20.151,14.422,35.831,20.1,28.97,10.484,67.333,16.258,108.078,16.258s79.184-5.774,108.153-16.258c15.68-5.675,27.938-12.5,35.938-20.1v78.927C356,188.283,341.371,200.22,315.969,209.412ZM315.9,106.547c-27.694,10.023-64.637,15.542-104.025,15.542s-76.33-5.519-104.024-15.542C82.449,97.353,67.878,85.418,67.878,73.8s14.571-23.55,39.976-32.744c27.693-10.022,64.637-15.542,104.024-15.542S288.209,31.038,315.9,41.06c25.4,9.194,39.975,21.128,39.975,32.744S341.308,97.353,315.9,106.547Z"
      />
      <path
        //fill="#0635c9"
        fill="#010101"
        d="M253.659 171.186a396.222 396.222 0 0 1-41.781 2.167 386.879 386.879 0 0 1-52.2-3.433 6 6 0 1 0-1.626 11.89 398.984 398.984 0 0 0 53.829 3.543 408.212 408.212 0 0 0 43.052-2.235 6 6 0 1 0-1.271-11.932zM298.907 149.509a17.922 17.922 0 1 0 17.922 17.922A17.942 17.942 0 0 0 298.907 149.509zm0 23.844a5.922 5.922 0 1 1 5.922-5.922A5.928 5.928 0 0 1 298.907 173.353zM253.659 272.521a396.547 396.547 0 0 1-41.781 2.167 386.824 386.824 0 0 1-52.2-3.433 6 6 0 1 0-1.626 11.89 398.918 398.918 0 0 0 53.829 3.543 408.269 408.269 0 0 0 43.052-2.235 6 6 0 1 0-1.271-11.932zM316.829 268.766a17.922 17.922 0 1 0-17.922 17.922A17.942 17.942 0 0 0 316.829 268.766zm-17.922 5.922a5.922 5.922 0 1 1 5.922-5.922A5.928 5.928 0 0 1 298.907 274.688zM253.659 373.855a396.278 396.278 0 0 1-41.781 2.167 386.716 386.716 0 0 1-52.2-3.433 6 6 0 1 0-1.626 11.89 398.984 398.984 0 0 0 53.829 3.543 408.272 408.272 0 0 0 43.052-2.234 6 6 0 0 0-1.271-11.933z"
      />
      <path
        //fill="#1ae5be"
        fill="#010101"
        d="M346.657,492.764l12.009,5.218a6.064,6.064,0,0,0,4.8.008l13.429-5.823A129.127,129.127,0,0,0,456,373.072V319.755a5.943,5.943,0,0,0-3.117-5.3L364.2,267.187a5.967,5.967,0,0,0-5.691.033l-86.325,47.273A6.063,6.063,0,0,0,269,319.755V374.3A129.506,129.506,0,0,0,346.657,492.764ZM281,323.309,361.312,279.3,444,323.356v49.716a117.153,117.153,0,0,1-71.79,108.052c-.014,0,0,.011-.011.018l-11.1,4.8-9.642-4.172A117.5,117.5,0,0,1,281,374.3Z"
      />
      <path
        //fill="#1ae5be"
        fill="#010101"
        d="M344.259,405.516a6,6,0,0,0,8.35.758l54.333-44.2a6,6,0,1,0-7.573-9.308l-49.788,40.506L330.056,370.4a6,6,0,0,0-9.126,7.793Z"
      />
    </>
  ),
});
