/**
 *
 * Blueprints
 *
 */

import React, { memo, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import { Blueprint } from 'containers/PolicyHub/Blueprints/Components/Blueprint';
import { BlueprintCards } from 'containers/PolicyHub/Blueprints/Components/BlueprintCards';
import { CreatePolicyBlueprint } from 'containers/PolicyHub/Blueprints/Components/BlueprintWizard/CreatePolicyBlueprint';
import { BlueprintContextProvider } from 'containers/PolicyHub/Blueprints/hooks/context';
import { blueprintsSaga } from 'containers/PolicyHub/Blueprints/saga';
import { useInjector } from 'utils/inject';

import { reducer, sliceKey } from './slice';

export const Blueprints = memo(() => {
  useInjector(sliceKey, reducer, blueprintsSaga);
  const [showPolicies, setShowPolicies] = useState(false);

  return (
    <BlueprintContextProvider value={{ showPolicies, setShowPolicies }}>
      <Routes>
        <Route path="add" element={<CreatePolicyBlueprint />} />
        <Route path=":id" element={<Blueprint />} />
        <Route index element={<BlueprintCards />} />
      </Routes>
    </BlueprintContextProvider>
  );
});
