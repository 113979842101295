import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PolicyMetaData } from '@ariksa/compliance-policies/api';
import {
  Stack,
  Box,
  Flex,
  HStack,
  Input,
  Circle,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { each, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { colorMap } from 'theme';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import {
  CancelButton,
  defaultFormStyles,
  PrimaryButton,
} from 'components/DataEntry';
import { PlusIcon, TagIcon, TrashIcon } from 'components/Icons';
import { ConfirmationModal, Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  row: Record<string, any>;
  blueprintId?: string;
  onSuccess();
}

export const EditPolicyContext: React.FC<Props> = props => {
  const { isOpen, onClose, row, blueprintId, onSuccess } = props;
  const dispatch = useDispatch();
  const confirmationModal = useDisclosure();
  const [contextKey, setContextKey] = useState('');
  const [contextValue, setContextValue] = useState('');
  const [action, setAction] = useState('');
  const [currentContext, setCurrentContext] = useState<Record<string, any>>({});
  const [contexts, setContexts] = useState<Record<string, PolicyMetaData[]>>(
    {},
  );
  const [inheritedContexts, setInheritedContexts] = useState<PolicyMetaData[]>(
    [],
  );
  const { getResourceAlias, getCloudAgnosticName } = useResourceType();
  const { policyContext, policyContexts, attachedBlueprints } = useSelector(
    selectPolicyHub,
  );
  const ref = useRef(document.querySelector('.portal-container'));

  const getContexts = useCallback(() => {
    dispatch(
      actions.getPolicyContexts({ q: { policyId: row?.id ?? row?.rule_id } }),
    );
  }, [dispatch, row]);

  useEffect(() => {
    getContexts();
  }, [getContexts]);

  useEffect(() => {
    dispatch(
      actions.getAttachedBlueprints({
        q: { policyId: row?.id ?? row?.rule_id },
      }),
    );
  }, [dispatch, row]);

  //set contexts
  useEffect(() => {
    let items: Record<string, PolicyMetaData[]> = {};
    let inherited: PolicyMetaData[] = [];
    if (blueprintId) items[blueprintId] = [];
    each(policyContexts.data, o => {
      if (!!o.blueprint_id) {
        if (blueprintId) {
          if (blueprintId === o.blueprint_id) items[blueprintId].push(o);
        } else
          items[o.blueprint_id]
            ? items[o.blueprint_id].push(o)
            : (items[o.blueprint_id] = [o]);
      } else inherited.push(o);
    });
    setContexts(items);
    setInheritedContexts(inherited);
  }, [blueprintId, policyContexts]);

  //on click delete, remove policy context
  const onClickDelete = () => {
    dispatch(
      actions.deletePolicyContext({
        q: {
          metadataId: currentContext?.id,
        },
        onSuccess: () => {
          getContexts();
          confirmationModal.onClose();
          onSuccess();
        },
      }),
    );
  };

  //on click delete, remove policy context
  const onClickAddContext = (closeOnSuccess = false) => {
    dispatch(
      actions.createPolicyContext({
        q: {
          policyMetaDataRequest: {
            rule_id: row?.id ?? row?.rule_id,
            key: contextKey,
            value: contextValue,
            blueprint_id: blueprintId,
          },
        },
        onSuccess: () => {
          setContextValue('');
          setContextKey('');
          getContexts();
          onSuccess();
          closeOnSuccess && onClose();
        },
      }),
    );
  };

  const contextFieldStyles = {
    border: '1px solid',
    borderColor: 'gray.200',
    py: 1,
    px: 3,
    borderRadius: '3px',
    bg: 'white',
  };

  const contextBoxStyles = { bg: colorMap.primary(50), borderRadius: 6, p: 3 };

  const renderContext = (items: PolicyMetaData[], showAddField) => {
    return (
      <Stack color="#000">
        {map(items, o => (
          <HStack>
            <Box w="45%" {...contextFieldStyles}>
              {o.key}
            </Box>
            <Box w="full" {...contextFieldStyles}>
              {o.value}
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              boxSize={6}
              color="critical"
              onClick={() => {
                setCurrentContext(o);
                confirmationModal.onOpen();
                setAction('delete');
              }}
            >
              <TrashIcon />
            </Box>
          </HStack>
        ))}
        {showAddField && (
          <HStack>
            <Input
              w="45%"
              {...defaultFormStyles.textField?.input}
              onChange={e => setContextKey(e.target.value)}
              value={contextKey}
              placeholder="Enter key..."
              bg="white"
            />
            <Input
              w="full"
              {...defaultFormStyles.textField?.input}
              onChange={e => setContextValue(e.target.value)}
              value={contextValue}
              placeholder="Enter value..."
              bg="white"
            />
            {policyContext.isLoading ? (
              <Box>
                <CustomSpinner />
              </Box>
            ) : (
              <Circle
                size={4}
                p={0.5}
                border="1px solid"
                borderColor="primary"
                onClick={() => {
                  onClickAddContext();
                }}
                _hover={{
                  cursor:
                    !!contextKey && !!contextValue ? 'pointer' : 'not-allowed',
                  bg: 'primary',
                  color: 'white',
                  opacity: !!contextKey && !!contextValue ? 1 : 0.6,
                }}
              >
                <PlusIcon />
              </Circle>
            )}
          </HStack>
        )}
      </Stack>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper="Edit Policy Context"
          lower={row?.config_id ?? row?.name}
          icon={<TagIcon />}
        />
      }
      styles={{
        modal: {
          size: '2xl',
          portalProps: {
            containerRef: ref as any,
          },
        },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <PrimaryButton
            bg="primary"
            color="white"
            w={16}
            onClick={() => {
              onClickAddContext(true);
            }}
            isLoading={policyContext.isLoading}
            tooltip="Please click on plus button to add context"
          >
            OK
          </PrimaryButton>
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <DetailsIdentifier
            label="Resource type"
            value={
              getResourceAlias(row?.resource_type ?? row?.native_resource) +
              ' / ' +
              getCloudAgnosticName(row.resource_type ?? row?.native_resource)
            }
          />
          <DetailsIdentifier
            label="Policy description"
            direction="column"
            value={row?.compliance_description ?? row?.description}
          />
          {!blueprintId && (
            <DetailsIdentifier
              label="This policy is present in blueprints"
              value={
                attachedBlueprints.isLoading ? (
                  <CustomSpinner />
                ) : (
                  <Text color="primary">
                    {attachedBlueprints.data?.join(', ')}
                  </Text>
                )
              }
            />
          )}
          {
            <Box {...contextBoxStyles}>
              <DetailsIdentifier
                label={
                  'Context inherited by all blueprints that contain this policy'
                }
                direction="column"
                value={
                  policyContexts.isLoading ? (
                    <Box h={16}>
                      <CustomSpinner />
                    </Box>
                  ) : (
                    renderContext(inheritedContexts, !blueprintId)
                  )
                }
              />
            </Box>
          }
          {map(contexts, o => (
            <Box {...contextBoxStyles}>
              <DetailsIdentifier
                label={
                  !!blueprintId
                    ? 'Context in this blueprint'
                    : 'Context created for ' + o?.[0]?.blueprint_name
                }
                direction="column"
                value={
                  policyContexts.isLoading ? (
                    <Box h={16}>
                      <CustomSpinner />
                    </Box>
                  ) : (
                    renderContext(o, blueprintId)
                  )
                }
              />
            </Box>
          ))}

          <Box color="primary">
            NOTE: Policy context shown here will apply to every blueprint that
            contains this policy
          </Box>
          {confirmationModal.isOpen && (
            <ConfirmationModal
              name="CONFIRM"
              confirmationText={
                <Text>
                  Type <Text as="b">CONFIRM</Text> to {action} context.
                </Text>
              }
              onConfirm={() => {
                action === 'add' ? onClickAddContext() : onClickDelete();
              }}
              isOpen={confirmationModal.isOpen}
              onClose={confirmationModal.onClose}
              isLoading={policyContext.isLoading}
            />
          )}
        </Stack>
      }
    />
  );
};
