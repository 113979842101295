/**
 *
 * Add Slack Client
 *
 */

import React from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

import { SlackForm } from './SlackForm';

interface Props {
  onSubmit: (data) => void;
  setValidate: (value: boolean) => void;
  validate: boolean;
}

export const CreateSlack = (props: Props) => {
  const { onSubmit, validate, setValidate } = props;
  const dispatch = useDispatch();
  const { setCurrentRecord } = useIntegrationsContext();

  const createClient = data => {
    const { token, name } = data;
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        client_name: Clients.Slack,
        name,
        status: true,
        slack: {
          token,
          channel_id: '',
          channel_name: '',
          workspace: '',
        },
      },
    };
    dispatch(
      actions.createClient({
        q: payload,
        onSuccess: res => {
          setValidate(true);
          setCurrentRecord(res);
          dispatch(actions.updateActiveClient(res));
        },
      }),
    );
  };

  const handleSubmit = data => {
    validate ? onSubmit(data) : createClient(data);
  };

  return <SlackForm handleSubmit={handleSubmit} validate={validate} />;
};
