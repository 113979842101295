import React, { FC, useState } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { Box, Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react';

import { H3 } from 'components/DataDisplay';
import { PrimaryIconButton } from 'components/DataEntry';
import {
  DependenciesIcon,
  RemediateIcon,
  TimelineIcon,
} from 'components/Icons';
import { EvidencePixiGraph } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/EvidencePixiGraph';
import { RemediationModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationModal';
import { TimelineModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Timeline/TimelineModal';

interface IEvidence {
  alert: AlertResponse;
}

export const Evidence: FC<IEvidence> = props => {
  const { alert } = props;

  const remediationModalState = useDisclosure();
  const timelineModalState = useDisclosure();
  const [showDependencies, setShowDependencies] = useState(false);

  return (
    <Stack h={'full'}>
      <HStack px={2} align={'center'} spacing={4}>
        <Flex>
          <H3>Evidence</H3>
        </Flex>
        <HStack>
          <PrimaryIconButton
            tooltip={'Show dependencies to consider for remediation'}
            icon={<DependenciesIcon />}
            onClick={() => setShowDependencies(!showDependencies)}
            aria-label={''}
          />
          <PrimaryIconButton
            tooltip={'Show remediation options'}
            icon={<RemediateIcon />}
            onClick={() => {
              remediationModalState.onOpen();
            }}
            aria-label={''}
          />
          <PrimaryIconButton
            tooltip={'Show timeline for this alert'}
            icon={<TimelineIcon />}
            onClick={() => {
              timelineModalState.onOpen();
            }}
            aria-label={''}
          />
        </HStack>
      </HStack>
      <Box flex={1}>
        <EvidencePixiGraph alert={alert} showDependencies={showDependencies} />
      </Box>

      {/*resource specific evidence info*/}

      <RemediationModal state={remediationModalState} alert={alert} />
      <TimelineModal state={timelineModalState} alert={alert} />
    </Stack>
  );
};
