import { IconTypes } from 'components/Icons';

const colorMap: { [key in string]: number } = {
  [IconTypes.ElasticMapReduce]: 0x5b9bd5,
  [IconTypes.SecurityGroup]: 0xf05050,
  [IconTypes.Wafv1]: 0xf05050,
  [IconTypes.Wafv2]: 0xf05050,
  [IconTypes.NetworkAcl]: 0xf05050,
  [IconTypes.ApiGateway]: 0x9c2ff,
  [IconTypes.ApiGatewayRestApi]: 0x9c2ff,
  [IconTypes.GcpapiGateway]: 0x9c2ff,
  [IconTypes.EcsService]: 0xe02947,
  [IconTypes.ECSTask]: 0xe02947,
  [IconTypes.ElastiCache]: 0x56147,
  [IconTypes.ElastiCacheReplicationGroup]: 0x56147,
  [IconTypes.GcpMemorystoreRedis]: 0x56147,
  [IconTypes.ElasticSearch]: 0x56147,
  [IconTypes.AzureAdGroup]: 0xffffff,
  [IconTypes.AzureAdUser]: 0xffffff,
  [IconTypes.AzureAdOrganization]: 0xffffff,
  [IconTypes.EcsCluster]: 0x623ce4,
  [IconTypes.Gcpgke]: 0x623ce4,
  [IconTypes.GcpContainer]: 0x623ce4,
  [IconTypes.ElasticKubernetesService]: 0x623ce4,
  [IconTypes.RdsCluster]: 0x623ce4,
  [IconTypes.CloudFormation]: 0x7bc8a,
  [IconTypes.ServerlessCompute]: 0x1862ff,
  [IconTypes.VirtualMachine]: 0x1862ff,
  [IconTypes.Ec2AutoScalingGroup]: 0x1862ff,
  [IconTypes.Ec2LaunchTemplate]: 0x1862ff,
  [IconTypes.Ec2Instance]: 0x1862ff,
  [IconTypes.GcpComputeEngine]: 0x1862ff,
  [IconTypes.GcpCloudFunctions]: 0x1862ff,
  [IconTypes.GcpComputeEngineAutoscaler]: 0x1862ff,
  [IconTypes.GcpCloudTpus]: 0x1862ff,
  [IconTypes.SystemsManagerDocument]: 0xdacff,
  [IconTypes.AcmCertificate]: 0xe02947,
  [IconTypes.IamUserAccessKey]: 0xe02947,
  [IconTypes.KeyManagementService]: 0xe02947,
  [IconTypes.RdsCertificate]: 0xe02947,
  [IconTypes.Secret]: 0xe02947,
  [IconTypes.GcpSecretManager]: 0xe02947,
  [IconTypes.GcpCloudKms]: 0xe02947,
  [IconTypes.GcpsqlsslCerts]: 0xe02947,
  [IconTypes.SshKeyPair]: 0xe02947,
  [IconTypes.Backup]: 0x7bc8a,
  [IconTypes.DocumentDb]: 0x7bc8a,
  [IconTypes.DocumentDbCluster]: 0x7bc8a,
  [IconTypes.DynamoDb]: 0x7bc8a,
  [IconTypes.EbsSnapshot]: 0x7bc8a,
  [IconTypes.ElasticBlockStorage]: 0x7bc8a,
  [IconTypes.ElasticFileSystem]: 0x7bc8a,
  [IconTypes.Neptune]: 0x7bc8a,
  [IconTypes.NeptuneCluster]: 0x7bc8a,
  [IconTypes.RdsSnapshot]: 0x7bc8a,
  [IconTypes.RdsInstance]: 0x7bc8a,
  [IconTypes.RdsInstance]: 0x7bc8a,
  [IconTypes.SimpleStorageService]: 0x07bc8a,
  [IconTypes.GcpBucket]: 0x07bc8a,
  [IconTypes.GcpBigQuery]: 0x07bc8a,
  [IconTypes.GcpFilestore]: 0x07bc8a,
  [IconTypes.GcpFirestore]: 0x07bc8a,
  [IconTypes.GcpBigtableInstances]: 0x07bc8a,
  [IconTypes.GcpCloudSql]: 0x07bc8a,
  [IconTypes.GcpPersistentDisk]: 0x07bc8a,
  [IconTypes.GcpFilestoreBackup]: 0x07bc8a,
  [IconTypes.GcpsqlInstances]: 0x07bc8a,
  [IconTypes.Route53RecordSet]: 0xedc543,
  [IconTypes.Route53HostedZone]: 0xedc543,
  [IconTypes.Route53HealthCheck]: 0xedc543,
  [IconTypes.Route53HealthCheck]: 0xedc543,
  [IconTypes.GcpCloudDnsZone]: 0xedc543,
  [IconTypes.GcpCloudDomainsRegistration]: 0xedc543,
  [IconTypes.Route53TrafficPolicy]: 0x31b616,
  [IconTypes.AccountRoot]: 0xd6221f,
  [IconTypes.Group]: 0x6d4eb,
  [IconTypes.Role]: 0x6d4eb,
  [IconTypes.Permission]: 0x31b616,
  [IconTypes.AmazonMachineImage]: 0xdacff,
  [IconTypes.GcpImage]: 0xdacff,
  [IconTypes.EcrImage]: 0xdacff,
  [IconTypes.ElasticContainerRegistry]: 0xdacff,
  [IconTypes.CloudTrail]: 0xb72a4d,
  [IconTypes.GcpCloudLogMetrics]: 0xb72a4d,
  [IconTypes.CloudWatchLogGroup]: 0x4724d,
  [IconTypes.Sns]: 0x9083af,
  [IconTypes.SimpleQueueService]: 0xe5b710,
  [IconTypes.GcpCloudTasksQueue]: 0xe5b710,
  [IconTypes.SagemakerEndpoints]: 0x9d63f7,
  [IconTypes.SagemakerNotebookInstances]: 0x9d63f7,
  [IconTypes.CloudFront]: 0xed7d32,
  [IconTypes.DatabaseSecurityGroup]: 0xed7d32,
  [IconTypes.DhcpOptions]: 0xed7d32,
  [IconTypes.ElasticIp]: 0xed7d32,
  [IconTypes.ElasticLoadBalancer]: 0xed7d32,
  [IconTypes.ElasticLoadBalancerv2]: 0xed7d32,
  [IconTypes.TransitGatewayAttachment]: 0xed7d32,
  [IconTypes.Ip]: 0xed7d32,
  [IconTypes.NatGateway]: 0xed7d32,
  [IconTypes.NetworkInterface]: 0xed7d32,
  [IconTypes.P2P]: 0xed7d32,
  [IconTypes.PrefixList]: 0xed7d32,
  [IconTypes.Subnet]: 0xed7d32,
  [IconTypes.RouteTable]: 0xed7d32,
  [IconTypes.Route]: 0xed7d32,
  [IconTypes.TransitGatewayAttachment]: 0xed7d32,
  [IconTypes.TransitGateway]: 0xed7d32,
  [IconTypes.VirtualPrivateCloud]: 0xf8b810,
  [IconTypes.VpcFlowLogs]: 0xed7d32,
  [IconTypes.VpcGateway]: 0xed7d32,
  [IconTypes.P2P]: 0xed7d32,
  [IconTypes.Vpn]: 0xed7d32,
  [IconTypes.VpnGateway]: 0xed7d32,
  [IconTypes.GcpVirtualPrivateCloud]: 0xed7d32,
  [IconTypes.GcpSubnetwork]: 0xed7d32,
  [IconTypes.GcpRoute]: 0xed7d32,
  [IconTypes.GcpurlMaps]: 0xed7d32,
  [IconTypes.GcpNetworkInterface]: 0xed7d32,
  [IconTypes.GcpCloudNat]: 0xed7d32,
  [IconTypes.GcpvpnGateway]: 0xed7d32,
  [IconTypes.Okta]: 0xffffff,
  [IconTypes.OktaGroup]: 0xffffff,
  [IconTypes.OktaOrganization]: 0xffffff,
  [IconTypes.OktaUser]: 0xffffff,
  [IconTypes.AwsOrganization]: 0x12966,
  [IconTypes.Account]: 0x595959,
  [IconTypes.OrganizationUnit]: 0x12966,
  [IconTypes.GcpOrganization]: 0x12966,
  [IconTypes.GcpFolder]: 0x12966,
  [IconTypes.GcpServiceAccount]: 0x12966,
  [IconTypes.ElasticBeanstalk]: 0xac2ff,
  [IconTypes.AccountPasswordIdentityPolicy]: 0x31b616,
  [IconTypes.AsgResourcePolicy]: 0x31b616,
  [IconTypes.IamPolicy]: 0x31b616,
  [IconTypes.ResourcePolicy]: 0x31b616,
  [IconTypes.GcpOrgPolicyService]: 0x31b616,
  [IconTypes.GcpiamPolicy]: 0x31b616,
  [IconTypes.GcpResourcePolicy]: 0x31b616,
  [IconTypes.Snowflake]: 0xffffff,
  [IconTypes.SnowflakeUsers]: 0xffffff,
  [IconTypes.SnowflakeDatabases]: 0xffffff,
  [IconTypes.SnowflakeTables]: 0xffffff,
  [IconTypes.SnowflakeSchemas]: 0xffffff,
  [IconTypes.SnowflakeRoles]: 0xffffff,
  [IconTypes.Snowflake]: 0xffffff,
  [IconTypes.Agent]: 0xdacff,
  [IconTypes.Kafka]: 0xffffff,
  [IconTypes.ApacheHttp]: 0xffffff,
  [IconTypes.MongoDb]: 0xffffff,
  [IconTypes.PostgreSql]: 0xffffff,
  [IconTypes.MySql]: 0xffffff,
  [IconTypes.Nginx]: 0xffffff,
  [IconTypes.Endpoint]: 0x1b43c9,
  [IconTypes.Technology]: 0xd0e0ff,
  [IconTypes.OperatingSystem]: 0xd0e0ff,
  critical: 0xc00000,
  high: 0xf05050,
  medium: 0xdd6b20,
  low: 0xed8936,
  normal: 0xaaaaaa,
};

export const nodeColor = (type: string) => {
  if (colorMap[type]) {
    return colorMap[type];
  }
  return 0xed7d31;
};
