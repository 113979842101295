import React, { useState, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Stack, Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import { useSelector, useDispatch } from 'react-redux';

import { ComposedChart } from 'app/components/Visualization/Charts';
import { Modal } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { ComplianceChartDetails } from 'containers/Compliance/Components/ComplianceChartDetails';
import { historyChartStyles } from 'containers/Compliance/Components/ComplianceDashboard/styles';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { selectCompliance } from 'containers/Compliance/selectors';

import { actions } from '../slice';

interface Props {
  ruleId?: string;
  resourceType?: string;
  control?: string;
  subControl?: string;
}

export const History: React.FC<Props> = props => {
  const { ruleId, resourceType, control, subControl } = props;
  const dispatch = useDispatch();
  const { selectedResource, isOpenHistory, onCloseHistory } = useCompliance();
  const { selectedBlueprint } = useSelector(selectCompliance);
  const { actionHistory } = useSelector(selectCompliance);
  const [data, setData] = useState<any>();

  const { withAccessBoundary } = useAccessBoundary();

  useEffect(() => {
    const startDate = dayjs().subtract(6, 'days').format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');

    if (!!selectedBlueprint && !isEmpty(selectedResource)) {
      dispatch(
        actions.getActionHistoricalTrend({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            resourceId: selectedResource?.resource_id,
            control: control ?? selectedResource?.control ?? undefined,
            standard_id: selectedResource?.id,
            subControl:
              subControl ?? selectedResource?.sub_control ?? undefined,
            ruleId: ruleId ?? selectedResource?.rule_id,
            startDate,
            endDate,
            resourceType: (resourceType ??
              selectedResource?.type) as NativeResources,
          }),
        }),
      );
    }
  }, [
    dispatch,
    selectedBlueprint,
    selectedResource,
    withAccessBoundary,
    ruleId,
    resourceType,
    control,
    subControl,
  ]);

  useEffect(() => {
    setData(
      map(actionHistory?.data, (o, key) => ({
        name: dayjs(key).format('ddd'),
        total_performed: o?.total_performed,
        passed: o?.passed,
        'Checks Performed': Number(o?.total_performed / 1000).toFixed(3),
        'Passed Checks': Number(
          ((o?.passed / o?.total_performed) * 100).toFixed(2),
        ),
      })),
    );
  }, [actionHistory.data]);

  return (
    <Modal
      isOpen={isOpenHistory}
      onClose={onCloseHistory}
      header="History"
      styles={{
        modal: { size: '5xl' },
      }}
      body={
        <Stack>
          <ComplianceChartDetails
            resource={selectedResource}
            blueprint={selectedBlueprint}
          />
          <Box h="300px" p={4}>
            <ComposedChart
              data={data}
              isLoading={actionHistory.isLoading}
              xField="name"
              bar={{ orientation: 'left', yField: 'Checks Performed' }}
              line={{ orientation: 'right', yField: 'Passed Checks' }}
              styles={historyChartStyles()}
            />
          </Box>
        </Stack>
      }
    />
  );
};
