import React from 'react';

import truncate from 'lodash/truncate';
import { customTheme } from 'theme';

const colors = customTheme.colors;
export const firstLayerData = [
  {
    value: 25,
    color: colors.green['300'],
  },
  {
    value: 50,
    color: colors.yellow['400'],
  },
  {
    value: 25,
    color: colors.red['400'],
  },
];

export const tickData = () => {
  let data: { value: number }[] = [];
  for (let i = 0; i <= 100; i++) {
    data.push({ value: 0.5 });
  }
  return data;
};

export const bigTicksData = () => {
  let data: { value: number }[] = [];
  for (let i = 0; i < 11; i++) {
    data.push({ value: 1 });
  }
  return data;
};

export const customizedPieChartLegend = (value, entry, index) => {
  return (
    <span style={{ fontSize: '14px', color: '#666' }}>
      {truncate(value + ' (' + entry?.payload?.value + ')', {
        length: 20,
      })}
    </span>
  );
};
