/**
 *
 * AddRepository -> GitLab, Github
 *
 */

import React, { useEffect, useReducer, useState } from 'react';

import { CloudProviders, RepositoryService } from '@ariksa/cloud-account/api';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Center,
  Stack,
  Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { FormAction, PrimaryButton } from 'components/DataEntry';
import { defaultStyles, Form } from 'components/DataEntry/Form';
import { CheckmarkIcon, QuestionCircleIcon } from 'components/Icons';
import { actions as appActions } from 'containers/App/slice';
import { BitbucketTokenHelp } from 'containers/Setup/Integrations/DataSources/Components/AddRepository/Help/Bitbucket/BitbucketGetTokenHelp';
import { GitHubTokenHelp } from 'containers/Setup/Integrations/DataSources/Components/AddRepository/Help/GitHub/GitHubGetTokenHelp';
import { GitLabTokenHelp } from 'containers/Setup/Integrations/DataSources/Components/AddRepository/Help/GitLab/GitLabGetTokenHelp';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';

interface Props {
  onCancel();
  getAccounts?();
  selectedProvider: string;
  actionType: FormAction;
  accountDetails: Record<string, any>;
}

export const AddRepositoryForm = (props: Props) => {
  const {
    onCancel,
    getAccounts,
    selectedProvider,
    actionType,
    accountDetails,
  } = props;
  const dispatch = useDispatch();
  const { validateToken, onboardAccount } = useSelector(selectSetup);
  const [isEdit] = useState(actionType === 'Update');

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isHelpOpen: false,
      isValid: false,
      disableValidateButton: false,
      token: '',
      accountNameError: '',
      name: '',
      username: '',
    },
  );

  const toggleIsHelpOpen = () => updateState({ isHelpOpen: !state.isHelpOpen });

  useEffect(() => {
    updateState({ isValid: validateToken.data });
  }, [validateToken.data]);

  useEffect(() => {
    isEdit &&
      updateState({
        name: accountDetails?.name,
        token:
          accountDetails?.gitlab?.access_token ??
          accountDetails?.github?.access_token ??
          accountDetails?.bitbucket?.application_password,
        username: accountDetails?.bitbucket?.username,
        isValid: true,
        disableValidateButton: true,
      });
  }, [isEdit, accountDetails]);

  const handleReset = () => {
    onCancel();
  };

  const handleSubmit = data => {
    const repo: Record<string, any> =
      selectedProvider === CloudProviders.Gitlab
        ? { gitlab: { access_token: state.token } }
        : selectedProvider === CloudProviders.Github
        ? { github: { access_token: state.token } }
        : {
            bitbucket: {
              username: state.username,
              application_password: state.token,
            },
          };

    if (isEdit)
      dispatch(
        setupActions.updateCloudAccount({
          q: {
            uuid: accountDetails.uuid,
            cloudAccountUpdate: {
              name: state.name,
              cloud_type: selectedProvider as CloudProviders,
              ...repo,
            },
          },
          onSuccess: () => {
            onCancel();
            getAccounts?.();
            dispatch(appActions.loadEnvAndAccounts());
          },
        }),
      );
    else
      dispatch(
        setupActions.onboardAccount({
          q: {
            cloudAccountCreateRequest: {
              cloud_type: selectedProvider as CloudProviders,
              name: state.name,
              ...repo,
            },
          },
          onSuccess: () => {
            onCancel();
            getAccounts?.();
            dispatch(appActions.loadEnvAndAccounts());
          },
        }),
      );
  };

  const onClickValidate = () => {
    updateState({ disableValidateButton: true });
    const repoType =
      selectedProvider === CloudProviders.Gitlab
        ? RepositoryService.Gitlab
        : selectedProvider === CloudProviders.Github
        ? RepositoryService.Github
        : RepositoryService.Bitbucket;
    const repo =
      selectedProvider === CloudProviders.Gitlab
        ? { gitlab: { access_token: state.token } }
        : selectedProvider === CloudProviders.Github
        ? { github: { access_token: state.token } }
        : {
            bitbucket: {
              username: state.username,
              application_password: state.token,
            },
          };
    dispatch(
      setupActions.validateToken({
        q: {
          repositoryRequest: {
            service_type: repoType,
            ...repo,
          },
        },
      }),
    );
  };

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          tooltip: 'Only Alphanumeric, hyphen is allowed.',
          error: state.accountNameError,
          onChange: value => {
            updateState({ name: value });
            if (value.match(/^[a-zA-Z0-9-]*$/i))
              updateState({ accountNameError: '' });
            else
              updateState({
                accountNameError: 'Only Alphanumeric, hyphen is allowed!',
              });
          },
          value: state.name,
        },
        ...(selectedProvider === CloudProviders.Bitbucket
          ? {
              username: {
                type: 'text',
                label: 'Username',
                isRequired: true,
                onChange: value =>
                  updateState({
                    username: value,
                    isValid: false,
                    disableValidateButton: false,
                  }),
                value: state.username,
              },
            }
          : {}),
        token: {
          type: 'custom-with-form-control',
          label:
            selectedProvider === CloudProviders.Bitbucket
              ? 'Application password'
              : 'API Key or Token',
          placeholder:
            selectedProvider === CloudProviders.Bitbucket
              ? 'Enter app password'
              : 'Enter API Key or Token',
          isRequired: true,
          component: (
            <Stack w="full" pb={4}>
              <InputGroup>
                <Input
                  {...defaultStyles?.textField?.input}
                  type="password"
                  value={state.token}
                  onChange={e => {
                    updateState({
                      isValid: false,
                      disableValidateButton: false,
                      token: e.target.value,
                    });
                  }}
                />
                {state.isValid && (
                  <InputRightElement w={8} h={8} p={2}>
                    <WithSpinner
                      loadStatus={{ loading: validateToken.isLoading }}
                    >
                      <Center
                        borderRadius="full"
                        bg="green.300"
                        color="white"
                        p={0.5}
                      >
                        <CheckmarkIcon />
                      </Center>
                    </WithSpinner>
                  </InputRightElement>
                )}
              </InputGroup>
              <Flex justify="flex-end" w="full">
                <PrimaryButton
                  onClick={onClickValidate}
                  isDisabled={
                    state.disableValidateButton ||
                    (selectedProvider === CloudProviders.Bitbucket &&
                      !state.username) ||
                    !state.token
                  }
                  isLoading={validateToken.isLoading}
                >
                  Validate
                </PrimaryButton>
              </Flex>
            </Stack>
          ),
          helpIcon: (
            <Box
              onClick={toggleIsHelpOpen}
              _hover={{ cursor: 'pointer' }}
              color="primary"
            >
              <QuestionCircleIcon />
            </Box>
          ),
          isHelpOpen: state.isHelpOpen,
          helpComponent:
            selectedProvider === CloudProviders.Gitlab ? (
              <GitLabTokenHelp
                onClose={() => updateState({ isHelpOpen: false })}
              />
            ) : selectedProvider === CloudProviders.Github ? (
              <GitHubTokenHelp
                onClose={() => updateState({ isHelpOpen: false })}
              />
            ) : (
              <BitbucketTokenHelp
                onClose={() => updateState({ isHelpOpen: false })}
              />
            ),
        },
        note: {
          type: 'custom',
          component: () => (
            <Box color="primary">
              Note: Ariksa will automatically populate details of the repository
              or project associated with the API key or token provided
            </Box>
          ),
        },
      }}
      showHelp
      buttonOptions={{
        submit: {
          name: 'Okay',
          isLoading: onboardAccount.isLoading,
          isDisabled:
            !state.isValid ||
            !state.name ||
            (selectedProvider === CloudProviders.Bitbucket && !state.username),
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: handleReset,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
