import React, { FC, useEffect, useMemo, useReducer, useRef } from 'react';

import { SensitivityCategory } from '@ariksa/data-scanning';
import { Resources } from '@ariksa/inventory-core/api';
import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectSharedState } from 'containers/SharedState/selectors';
import { DocumentTypeDataSources } from 'containers/Visibility/Data/Components/DataTable/DocumentTypeDataSources';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { cloudOptions } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  isOpen: boolean;
  onClose();
  currentRecord?: Record<string, any>;
}

export const DocumentSubTypes: FC<Props> = props => {
  const { isOpen, onClose, currentRecord } = props;
  const { documentSubTypes } = useSelector(selectDataDashboard);
  const { riskContext } = useSelector(selectSharedState);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const dataSourcesDisclosure = useDisclosure();
  const { documentSubTypeColumns, tableActionModals } = useDataTableColumns(
    MetadataTabs.Data,
  );
  const { accountId, environmentId } = useAccessBoundary();
  const { resourceTypeAliasOptionsWithAll } = useResourceType();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cloudOptions: [
        { label: 'All clouds', value: undefined },
        ...cloudOptions,
      ],
      selectedCloud: { label: 'All clouds', value: undefined },
      resourceType: { label: 'All resource types', value: undefined },
      resourceTypeOptions: [],
      currentRecord: {},
      searchTerm: '',
    },
  );

  useEffect(() => {
    updateState({ resourceTypeOptions: resourceTypeAliasOptionsWithAll });
  }, [resourceTypeAliasOptionsWithAll]);

  useEffect(() => {
    dispatch(
      actions.getDocumentSubTypes({
        q: {
          docTypeId: currentRecord?.type_id,
          //accountIds: !!accountId ? [accountId] : [],
          //environmentId,
          page: documentSubTypes.page.info.page_number,
          size: documentSubTypes.page.info.page_size,
          cloud: state.selectedCloud?.value,
          //resource: state.resourceType?.value,
          searchTerm: state.searchTerm,
        },
        /*onSuccess: res => {
          !!res?.total &&
            dispatch(
              actions.getRiskContextForDataTypes({
                q: {
                  accountId: !!accountId ? [accountId] : [],
                  environmentId: environmentId!,
                  dataType: map(res?.items, o => o.subtype_id!),
                },
              }),
            );
        },*/
      }),
    );
  }, [
    accountId,
    environmentId,
    documentSubTypes.page.info,
    state.selectedCloud,
    state.resourceType,
    state.searchTerm,
    currentRecord,
    dispatch,
  ]);

  const styles = {
    menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%' }),
  };

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1450px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <StackedHeader
            upper={currentRecord?.type_name}
            lower={''}
            type={IconTypes.Policy}
          />
        }
        body={
          <Stack h="full" pt={3} spacing={4}>
            <HStack spacing={6}>
              <DetailsIdentifier
                label="Description"
                value={
                  <Stack>
                    <Text>
                      This document type has been classified to be a{' '}
                      {currentRecord?.type_name} data source. Ariksa Outpost for
                      Data provides automatic document classification without
                      the need for any regex or manual inputs, which eliminates
                      manual efforts and friction in helping you understand the
                      footprint of different documents that you currently store.
                    </Text>
                    <Text>
                      Ariksa has detected{' '}
                      {currentRecord?.count + ' ' + currentRecord?.type_name}{' '}
                      documents.{' '}
                      {/*and currently have{' '}
                      {documentSubTypes.isLoading
                        ? '-'
                        : documentSubTypes?.page?.totalCount}{' '}
                      sub-types.*/}
                    </Text>
                  </Stack>
                }
                direction="column"
                w="55%"
                pb={1}
                styles={{ label: { color: 'black' } }}
              />
              <Divider orientation="vertical" borderColor="gray.200" />
              <DetailsIdentifier
                label="More information"
                value={
                  <Center h="full" w="full">
                    No details
                  </Center>
                }
                direction="column"
                h="full"
                w="45%"
                pb={1}
                styles={{ label: { color: 'black' } }}
              />
            </HStack>
            <Divider borderColor="primary" />
            <HStack justify="space-between">
              {/* <DetailsIdentifier
                label="Document Type"
                value={currentRecord?.type_name}
              />*/}
              <Box w={96}>
                <SearchBox
                  onChange={s => updateState({ searchTerm: s })}
                  value={state.searchTerm}
                />
              </Box>
              <HStack>
                <Box color="primary">FILTERS:</Box>
                {/*<Box w={48}>
                  <Select
                    options={state.resourceTypeOptions}
                    value={state.resourceType}
                    onChange={val => updateState({ resourceType: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>*/}
                <Box w={48}>
                  <Select
                    options={state.cloudOptions}
                    value={state.selectedCloud}
                    onChange={val => updateState({ selectedCloud: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>
              </HStack>
            </HStack>
            <Box flex={1}>
              <Table
                data={documentSubTypes.data ?? []}
                isError={documentSubTypes.isError}
                columns={documentSubTypeColumns}
                isLoading={documentSubTypes.isLoading}
                styles={{ header: { position: 'relative', zIndex: 800 } }}
                onRowClick={row => {
                  updateState({ currentRecord: row });
                  dataSourcesDisclosure.onOpen();
                }}
                pagination={{
                  pageInfo: documentSubTypes.page.info,
                  onChange: info =>
                    dispatch(actions.updateDocumentSubTypesPagination(info)),
                  totalCount: documentSubTypes.page.totalCount,
                }}
              />
            </Box>
            {tableActionModals}
            {dataSourcesDisclosure.isOpen && (
              <DocumentTypeDataSources
                {...dataSourcesDisclosure}
                currentRecord={state.currentRecord}
                type="sub-type"
              />
            )}
          </Stack>
        }
      />
    </Box>
  );
};
