import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { isArray, union, map, flatten, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  getEnvironmentAccountIdsArray,
  getResourceTypeOptions,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { CheckmarkCircleOutlineIcon } from 'components/Icons';
import { selectApp } from 'containers/App/selectors';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { actions } from 'containers/Inventory/Whitelist/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { WhitelistContextProps, WhitelistContextProvider } from '../../context';

import { WhitelistSettings } from './Steps/WhitelistSettings';

interface Props {
  actionType?: 'Create' | 'Update';
}

export const WhitelistWizard: FC<Props> = props => {
  const { actionType = 'Create' } = props;
  const { formData } = useSelector(selectWhitelist);
  const { tagKeys } = useSelector(selectSharedState);
  const { resourceTypes } = useSelector(selectApp);
  const [currentStep, setCurrentStep] = useState(0);
  const [isDisabledCriteriaNext, setIsDisabledCriteriaNext] = useState(false);
  const [hasEC2Criteria, setHasEC2Criteria] = useState(false);
  const [tagKeyOptions, setTagKeyOptions] = useState<Record<string, any>[]>([]);
  const [selectedConditions, setSelectedConditions] = useState<
    Record<string, any>[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    setResourceTypeOptions(getResourceTypeOptions(resourceTypes.data));
  }, [resourceTypes.data]);

  //load regions
  useEffect(() => {
    const account_ids = union(flatten(map(formData.ids, o => o.account_ids)));
    if (!isEmpty(account_ids)) {
      dispatch(
        sharedStateActions.getRegions({
          q: { accountId: account_ids },
        }),
      );
      dispatch(
        sharedStateActions.getTagKeys({
          q: { accountId: account_ids },
        }),
      );
    }
  }, [dispatch, formData.ids]);

  useEffect(() => {
    setTagKeyOptions(map(tagKeys.data, o => ({ label: o, value: o })));
  }, [tagKeys]);

  const updateCriteria = useCallback(
    (field, value) => {
      dispatch(
        actions.updateCriteria({
          field,
          value,
        }),
      );
    },
    [dispatch],
  );

  const resetCriteria = useCallback(
    field => {
      if (field === 'region') dispatch(actions.resetRegion());
      else {
        if (field === 'resource_type_criteria')
          dispatch(actions.resetResourceTypeCriteria());
        dispatch(actions.resetCriteria(field));
      }
    },
    [dispatch],
  );

  const updateFormData = useCallback(
    (field, value) => {
      if (field === 'environment_ids') return;
      let payload: any;
      /*if field is an array */
      if (field === 'account_ids') {
        payload = getEnvironmentAccountIdsArray(value);
      } else
        payload = isArray(value)
          ? map(value, o => o.value)
          : value?.value || value;
      dispatch(
        actions.updateFormData({
          field: field === 'account_ids' ? 'ids' : field,
          value: payload,
        }),
      );
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
    dispatch(actions.resetFormData());
    navigate('/inventory/allowlist');
  }, [navigate, dispatch]);

  const handleSubmit = useCallback(
    data => {
      const getWhitelists = () => {
        onCancel();
        dispatch(actions.getAllowLists({}));
      };
      let payload = {
        ...formData,
      };
      if (isEmpty(formData?.region))
        payload = {
          ...payload,
          region_condition: undefined,
          region: undefined,
        };
      if (isEmpty(formData?.criteria?.tag_keys_condition?.key))
        payload = {
          ...payload,
          criteria: {
            ...formData?.criteria,
            tag_keys_condition: undefined,
          },
        };
      //console.log(payload?.criteria?.resource_type_criteria?.[0])
      if (actionType === 'Create') {
        dispatch(
          actions.createAllowList({
            q: { allowlistCreate: payload },
            onSuccess: () => getWhitelists(),
          }),
        );
      } else {
        dispatch(
          actions.updateAllowList({
            q: {},
            onSuccess: () => getWhitelists(),
          }),
        );
      }
    },
    [
      //currentStep,
      //onSubmitData,
      dispatch,
      onCancel,
      actionType,
      //environment.data,
      formData,
    ],
  );

  const handleBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const contextProps: WhitelistContextProps = {
    handleBack,
    handleSubmit,
    actionType,
    onCancel,
    tagKeyOptions,
    updateFormData,
    updateCriteria,
    resetCriteria,
    resourceTypeOptions,
    isDisabledCriteriaNext,
    setIsDisabledCriteriaNext,
    hasEC2Criteria,
    setHasEC2Criteria,
    selectedConditions,
    setSelectedConditions,
  };

  return (
    <WhitelistContextProvider value={contextProps}>
      <Card
        headerComponent={
          <Box pl={14}>
            <PageHeaderWithIcon
              label={actionType + ' Allow List'}
              icon={<CheckmarkCircleOutlineIcon />}
              reversed
            />
          </Box>
        }
        styles={{ card: { pl: 32 } }}
        contentComponent={<WhitelistSettings />}
      />
    </WhitelistContextProvider>
  );
};
