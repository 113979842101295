import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';

import { selectAlertWorkflow } from '../../selectors';
import { intervalOptions } from '../../utils';

import { useAlertWorkflowWizardContext } from './AlertWorkflowWizardContext';

interface Props {}

export const AlertWorkflowNotificationInterval: FC<Props> = props => {
  const { formData, alertWorkflow } = useSelector(selectAlertWorkflow);
  const { notification_interval } = formData;
  const [interval, setInterval] = useState<Record<string, any>>(
    intervalOptions[0],
  );

  const {
    handleSubmit,
    handleBack,
    onCancel,
    updateFormData,
    actionType,
  } = useAlertWorkflowWizardContext();

  useEffect(() => {
    setInterval(
      intervalOptions.find(o => o.value === notification_interval) ||
        intervalOptions[0],
    );
  }, [notification_interval]);

  return (
    <Box pt={4}>
      <Form
        title="Set Notification Interval"
        schema={{
          notification_interval: {
            type: 'react-select',
            label: 'Perform action once for this workflow, every',
            placeholder: 'Time Interval',
            options: intervalOptions,
            value: interval,
            onChange: selected =>
              updateFormData('notification_interval', selected),
          },
        }}
        buttonOptions={{
          submit: {
            name: actionType + ' Workflow',
            isLoading: alertWorkflow.isLoading,
            isDisabled: alertWorkflow.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: onCancel,
          },
          back: {
            isVisible: true,
            onClick: () => handleBack?.(),
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
