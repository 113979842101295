import React from 'react';

import { customTheme } from 'theme';

const rightYAxisTickWithPercent = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y + 4})`}>
      <text fill="#666" fontSize={14}>
        {payload.value}%
      </text>
    </g>
  );
};

export const CustomTooltip = props => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    return (
      <div>
        <p className="label">{`${label} : ${payload[0].value * 1000}`}</p>
      </div>
    );
  }

  return null;
};

export const historyChartStyles = () => ({
  bar: {
    fill: customTheme.colors.primary,
  },
  line: {
    stroke: customTheme.colors.green['300'],
    dot: false,
  },
  composedChart: {
    margin: {
      top: 20,
      left: -15,
      right: -15,
    },
  },
  legend: {
    wrapperStyle: {
      top: -5,
      right: -5,
    },
  },
  secondYAxis: {
    domain: [0, 100],
    tickLine: false,
    axisLine: false,
    tick: props => rightYAxisTickWithPercent(props),
  },
  firstYAxis: {
    tickLine: false,
    axisLine: false,
    /*label: {
      value: 'Thousands',
      /!*angle: -90,
      position: 'insideBottomLeft',
      offset: 35,*!/
      style: { textAnchor: 'center' },
      angle: -90,
      position: 'left',
      offset: -30,
      fontSize: 12,
      allowDataOverflow: true,
    },*/
  },
  xAxis: {
    //tick: props => rotatedXAxisTick(props),
    tickLine: false,
    interval: 0,
    padding: { right: -10, left: -10 },
  },
  /*tooltip: {
    formatter: (value, name, entry) => {
      if (name === '% Passed' || name === '% With Vulnerability')
        return value + '%';
      if (name === 'Passed Checks') return entry?.payload?.passed;
      if (name === 'Vulnerable Hosts') return entry?.payload?.VulnerableHosts;
      else return entry?.payload?.total_performed ?? entry?.payload?.total;
    },
  },*/
});

export const totalViolationsChartStyles = {
  lineColors: [customTheme.colors.critical, customTheme.colors.high],
  line: {
    dot: false,
  },
  lineChart: {
    margin: {
      top: 20,
      left: -25,
      right: 10,
    },
  },
  tooltip: { formatter: value => value * 1000 },
  yAxis: {
    tickLine: false,
    axisLine: false,
    label: {
      value: 'Thousands',
      angle: -90,
      position: 'insideBottomLeft',
      offset: 35,
      fontSize: 12,
      //margin: { top: 100,  },
    },
  },
  xAxis: { tickLine: false, padding: { right: 5, left: 5 } },
};
