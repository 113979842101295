import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
} from '@ariksa/notification/api';

import { NotificationService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useAlertLogsService() {
  const alertStatus = useAriksaQuery<
    AlertLogsApiGetLogsForIdsRequest,
    AlertLogsResponse[]
  >({
    queryKey: [],
    fetcher: req => {
      return NotificationService.AlertsLogs.getLogsForIds(req).then(
        d => d.data,
      );
    },
  });

  return { alertStatus };
}
