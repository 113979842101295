import { useState } from 'react';

import { Application } from 'pixi.js';

import { AGraph } from '../core/Graph';
import { AGraphRenderer } from '../core/Renderer';

interface UseRendererProps {
  app: Application;
  graph: AGraph<any, any>;
}

export const useRenderer = (props: UseRendererProps) => {
  const { app, graph } = props;
  const [renderer] = useState(() => {
    return new AGraphRenderer(app, graph);
  });

  return renderer;
};
