import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import './styles.css';

interface ISyntaxHighlighter extends BoxProps {
  language: string;
}

export const SyntaxHighlight: FC<ISyntaxHighlighter> = props => {
  const { children, language } = props;

  return (
    <Box w={'full'} h={'full'} overflow={'auto'}>
      <SyntaxHighlighter language={language} className="syntax-highlighter">
        {children}
      </SyntaxHighlighter>
    </Box>
  );
};
