import { createSelector } from '@reduxjs/toolkit';
import { filter } from 'lodash';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.app || initialState;

export const selectApp = createSelector([selectDomain], appState => appState);

export const selectResourceTypes = createSelector(
  [selectDomain],
  appState => appState.resourceTypes,
);

export const selectUser = createSelector(
  [selectDomain],
  appState => appState.user,
);

export const selectCurrentOrg = createSelector(
  [selectDomain],
  appState => appState.user.info.org_id,
);

export const selectActiveCloudAccount = createSelector(
  [selectDomain],
  appState => ({
    accountId: appState.accessBoundary.account?.id ?? '',
    cloudAccountId: appState.accessBoundary.cloudId ?? '',
  }),
);

export const selectActiveEnvironment = createSelector(
  [selectDomain],
  appState => ({
    ...appState.accessBoundary,
    environmentId: appState.accessBoundary.environmentId ?? '',
  }),
);

export const selectOnboardedCloudAccounts = createSelector(
  [selectDomain],
  appState => ({
    ...appState.cloudAccounts,
    data: filter(
      appState.cloudAccounts.data,
      o => o?.status?.code === 'SUCCESS',
    ),
  }),
);

export const selectOnboardedCloudAccountsWithAccess = createSelector(
  [selectDomain],
  appState => ({
    ...appState.cloudAccounts,
    data: filter(
      appState.cloudAccounts.data,
      o =>
        o?.status?.code === 'SUCCESS' &&
        appState.user.info.account_ids?.includes(o.uuid),
    ),
  }),
);

export const selectEnvironmentOptions = createSelector(
  [selectDomain],
  appState =>
    appState.environments.data.map(a => {
      return {
        label: `${a.name}`,
        value: a.id,
        data: a,
      };
    }),
);

export const selectOnboardedCloudAccountOptions = createSelector(
  [selectDomain],
  appState =>
    appState.cloudAccounts.data.map(a => {
      return {
        label: `${a.cloud_type}:${a.cloud_account_id} (${a.name})`,
        value: a.uuid,
        data: a,
      };
    }),
);
