/**
 *
 * Asynchronously loads the component for JitRequests
 *
 */

import { lazy } from 'react';

export const JitRequests = lazy(() =>
  import('./index').then(m => ({ default: m.JitRequests })),
);
