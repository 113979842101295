import { ContextType } from '@ariksa/inventory-core';

import HighPrivileges from 'components/Icons/MapIcons/coloredIcons/high-privileges-red-128x128.png';
import CriticalData from 'components/Icons/MapIcons/purpleIcons/critical-data-purple.png';

import Exploitable from './coloredIcons/exploitable-vulnerability-red-128x128.png';
import InternetAccessibleIcon from './coloredIcons/internet-exposure-red-128x128.png';
import ExposedCredentials from './coloredIcons/Kms-Key-Black-128x128.png';
import Malicious from './coloredIcons/malicious-activity-red-128x128.png';
import Unencrypted from './coloredIcons/unencrypted-data-orange-128x128.png';
import Vulnerability from './coloredIcons/vulnerability-red-128x128.png';
import BruteForceAttack from './purpleIcons/brute-force-attack-purple.png';

export const getColoredIcons = (icon: string) => {
  switch (icon) {
    case ContextType.CredentialFound:
      return ExposedCredentials;
    case ContextType.UnencryptedDisk:
      return Unencrypted;
    case ContextType.InternetAccess:
      return InternetAccessibleIcon;
    case ContextType.BruteForceAttack:
      return BruteForceAttack;
    case ContextType.CriticalVulnerability:
      return Vulnerability;
    case ContextType.HighVulnerability:
      return Vulnerability;
    case ContextType.SensitiveData:
      return CriticalData;
    case ContextType.ExcessPermissions:
      return HighPrivileges;
    case ContextType.Privileged:
      return HighPrivileges;
    case ContextType.CrossAccount:
      return HighPrivileges;
    case ContextType.MaliciousIngress:
      return Malicious;
    case ContextType.UnencryptedData:
      return CriticalData;
    case ContextType.Exploitable:
      return Exploitable;
    default:
      return '';
  }
};
