import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SIEMIcon = props => (
  <Icon viewBox="0 0 24 26" color="inherit" h="full" w="full" {...props}>
    <g transform="translate(-628 -582)">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M642.5 585.5 649.5 585.5 649.5 592.5 642.5 592.5Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="none"
                fillRule="nonzero"
              />
              <path
                d="M642.5 597.5 649.5 597.5 649.5 604.5 642.5 604.5Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="none"
                fillRule="nonzero"
              />
              <path
                d="M630.5 585.5 637.5 585.5 637.5 592.5 630.5 592.5Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="none"
                fillRule="nonzero"
              />
              <path
                d="M630.5 597.5 637.5 597.5 637.5 604.5 630.5 604.5Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="none"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
