import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';
import { forEach } from 'lodash';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { LineChart } from 'components/Visualization';

interface Props {
  label: string;
  total: number | string;
  chartData: Record<string, any>[];
  isLoading: boolean;
}

export const TotalCard: FC<Props> = props => {
  const { label, total, isLoading, chartData } = props;
  const [max, setMax] = useState(0);
  const [date, setDate] = useState('');

  useEffect(() => {
    let maxValue = 0;
    let maxValueDate = '';

    forEach(chartData, o => {
      const date = o?.Date;
      const count = o?.Count || 0;

      if (count > maxValue) {
        maxValue = count;
        maxValueDate = date;
      }
    });
    setMax(maxValue);
    setDate(maxValueDate);
  }, [chartData]);

  return (
    <HStack
      justify="space-between"
      w="full"
      align="center"
      color="white"
      px={2}
    >
      <Stack spacing={1}>
        <Box fontSize="md">{label}</Box>
        {isLoading && <CustomSpinner color="white" loading={isLoading} />}
        {!isLoading && <Box>{total ? total : 0}</Box>}
      </Stack>
      <Box w={32} h={10}>
        <LineChart
          data={chartData}
          xField="Date"
          yField={['Count']}
          colorMap={{ Count: '#fff' }}
          legend={false}
          tinyChart
          tooltip={false}
          referenceLines={[
            {
              x: date,
              stroke: '#fff',
              strokeDasharray: '3 3',
            },
          ]}
          referenceDots={[
            {
              x: date,
              y: max,
              r: 5,
              fill: '#fff',
              stroke: 'none',
            },
          ]}
          styles={{
            xAxis: {
              tickLine: false,
              axisLine: false,
            },
            yAxis: {
              tickLine: false,
              axisLine: false,
            },
            lineChart: {
              margin: {
                top: 20,
                left: -25,
                right: 10,
              },
            },
          }}
        />
      </Box>
    </HStack>
  );
};
