export const searchQueryMapping = {
  Show_users_with_excess_permissions: {
    uuid: 'e0c1eb87-46dd-491c-b900-57becd42918f',
    category: 'permission',
    agnostic_resource: 'User',
    name: 'Show users with excess permissions',
    query: 'SHOW User THAT has Excess Permissions To ANY',
    get_started: true,
  },
  Show_roles_with_excess_permissions: {
    uuid: 'b48ac5e1-45ff-46ce-9384-d3aacf3ce5d3',
    category: 'permission',
    agnostic_resource: 'Role',
    name: 'Show roles with excess permissions',
    query: 'SHOW Role THAT has Excess Permissions To ANY',
    get_started: true,
  },
  Show_users_with_access_to_sensitive_data: {
    uuid: '04688029-a0e0-487f-abd8-fad4e9d86658',
    category: 'permission',
    agnostic_resource: 'User',
    name: 'Show users with access to sensitive data',
    query:
      'SHOW User THAT Can Access Data AND Data WITH sensitive_data.exist = true or PII = true',
    get_started: true,
  },
  Show_roles_with_access_to_sensitive_data: {
    uuid: '74f902a9-44be-496a-80b5-43fed0e6c646',
    category: 'permission',
    agnostic_resource: 'Role',
    name: 'Show roles with access to sensitive data',
    query:
      'SHOW Role THAT Can Access Data AND Data WITH sensitive_data.exist = true or PII = true',
  },
  Show_users_with_high_privileges: {
    uuid: '54703886-7155-4f98-a081-19ad0fe2c3ce',
    category: 'permission',
    agnostic_resource: 'User',
    name: 'Show users with high privileges',
    query: 'SHOW User with high_privileges = true AND User THAT Can Access ANY',
  },
  Show_roles_with_high_privileges: {
    uuid: '34240dd1-0136-46e1-a547-31c5fb83c873',
    category: 'permission',
    agnostic_resource: 'Role',
    name: 'Show roles with high privileges',
    query: 'SHOW Role with high_privileges = true AND Role THAT Can Access ANY',
  },
  Show_VMs_that_is_publicly_exposed_with_critical_high_vulnerabilities: {
    uuid: 'a3e0f2c9-c7d9-48cc-b5af-ccccbe2ae489',
    agnostic_resource: 'VirtualMachine',
    name:
      'Show VMs that is publicly exposed with critical/high vulnerabilities',
    category: 'posture',
    query:
      'SHOW VirtualMachine WITH internet_accessible = true AND vulnerabilities.exist = true AND vulnerabilities.severity.critical != 0 OR internet_accessible = true AND vulnerabilities.exist = true AND vulnerabilities.severity.high != 0',
  },
  Show_VMs_with_critical_high_vulnerabilities_and_that_has_access_to_sensitive_data: {
    uuid: '10e0f2c0-c7d0-48cc-b5a9-ccccbe2ae485',
    query:
      'SHOW VirtualMachine WITH vulnerabilities.exist = true AND vulnerabilities.severity.critical != 0 OR vulnerabilities.exist = true AND vulnerabilities.severity.high != 0 AND VirtualMachine THAT Can Access Data AND Data WITH sensitive_data.exist = true',
    category: 'permission',
    agnostic_resource: 'VirtualMachine',
    name:
      'Show VM with critical/high vulnerabilities and that has access to sensitive data',
  },
};
