import React, { useEffect, FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { AlertsTableHeader } from 'containers/Findings/Alerts/Components/AlertsTable/AlertsTableHeader';
import { useAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useAlerts';
import { IndividualAlertsTable } from 'containers/Findings/Alerts/Components/AlertsTable/IndividualAlertsTable';

export const IndividualAlerts: FC = () => {
  const { getAlerts } = useAlerts();

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  return (
    <Stack h="full" spacing={5} w="full">
      <AlertsTableHeader />
      <Box flex={1}>
        <ActiveResourceProvider>
          <IndividualAlertsTable />
        </ActiveResourceProvider>
      </Box>
    </Stack>
  );
};
