import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { map, isEmpty, isString } from 'lodash';
import {
  Pie,
  PieChart,
  Cell,
  Legend,
  Tooltip,
  Label,
  LabelProps,
} from 'recharts';
import { ResponsiveContainer } from 'recharts';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { customTheme } from '../../../../../theme';
import { LIGHT_COLORS } from '../utils';

import { defaultStyles } from './styles';
import { MultiLevelPieChartProps } from './types';

// for reference visit
// https://recharts.org/en-US/examples/TwoLevelPieChart
export const MultiLevelPieChart: React.FC<MultiLevelPieChartProps> = ({
  pie = [],
  isLoading = false,
  total,
  styles,
}) => {
  const [totalCount, setTotalCount] = useState(total);
  /*const [activeIndex, setActiveIndex] = useState(0);
  const [active, setActive] = useState(false);*/

  useEffect(() => {
    if (isString(total)) setTotalCount(parseInt(total));
    else setTotalCount(total);
  }, [total]);

  /*const onPieEnter = (e, data, dataKey, parentIndex) => {
    const index = findIndex(
      data,
      // @ts-ignore
      d => d && d?.[dataKey] === e.payload[dataKey],
    );
    setActiveIndex(index + parentIndex);
    setActive(true);
  };

  const onPieLeave = e => setActive(false);*/

  /*const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    //const RADIAN = Math.PI / 360;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * 10);
    const y = cy + radius * Math.sin(-midAngle * 10);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percent}
      </text>
    );
  };*/

  return isEmpty(pie) ? (
    <NoDataAvailable />
  ) : (
    <Box {...defaultStyles?.root} {...styles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : (
        <ResponsiveContainer>
          <PieChart {...defaultStyles?.pieChart} {...styles?.pieChart}>
            {map(pie, (o, i) => (
              <Pie
                //activeIndex={activeIndex}
                //activeShape={o?.activeShape && active ? renderActiveShape : {}}
                data={totalCount > 0 ? o.data : [{ name: 'No data', value: 1 }]}
                dataKey={o.dataKey}
                outerRadius={o?.outerRadius}
                innerRadius={o?.innerRadius}
                //onMouseEnter={e => onPieEnter(e, o.data, o.dataKey, i)}
                //onMouseLeave={onPieLeave}
                paddingAngle={1}
                startAngle={450}
                endAngle={90}
                label={o?.label}
                //labelLine={false}
                {...(defaultStyles?.pie as any)}
                {...(o?.styles?.pie as any)}
                onClick={e => o?.onClick?.(e)}
              >
                {o.data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      totalCount > 0
                        ? o?.color
                          ? o?.color[index % o?.color.length]
                          : LIGHT_COLORS?.[index % LIGHT_COLORS.length]
                        : customTheme.colors.gray['200']
                    }
                  />
                ))}
                {
                  <Label
                    value={total}
                    {...(defaultStyles?.label as LabelProps)}
                    {...(styles?.label as LabelProps)}
                  />
                }
              </Pie>
            ))}
            {map(pie, o => (
              <Tooltip
                {...defaultStyles?.tooltip}
                {...styles?.tooltip}
                content={o?.customTooltipContent}
              />
            ))}
            {/* @ts-ignore */}
            {map(
              pie,
              (o, i) =>
                o?.legend && (
                  <Legend
                    //onMouseEnter={e => onPieEnter(e, o.data, o.dataKey, i)}
                    //onMouseLeave={onPieLeave}
                    content={o?.renderLegend}
                    {...(defaultStyles?.legend as any)}
                    {...(styles?.legend as any)}
                  />
                ),
            )}
          </PieChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};
