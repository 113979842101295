import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const UserThinLineIcon = createIcon({
  displayName: 'UserThinLineIcon',
  viewBox: '0 0 100 100',
  path: (
    <>
      <g id="_x37_7_Essential_Icons">
        <path
          id="User"
          d="M59.3 52.2C65.8 48.2 70 40.3 70 33c0-10.4-8.5-22-20-22S30 22.6 30 33c0 7.3 4.2 15.2 10.7 19.2C25.3 56.3 14 70.4 14 87c0 1.1.9 2 2 2h68c1.1 0 2-.9 2-2 0-16.6-11.3-30.7-26.7-34.8zM34 33c0-10.3 8.5-18 16-18s16 7.7 16 18-8.5 18-16 18-16-7.7-16-18zM18.1 85c1-16.7 15-30 31.9-30s30.9 13.3 31.9 30H18.1z"
        />
      </g>
      <g id="Info">
        <path
          fill="#00F"
          d="M1224-790V894H-560V-790h1784m8-8H-568V902h1800V-798z"
          id="BORDER"
        />
      </g>
    </>
  ),
});
