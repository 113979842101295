import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { DetailsIdentifier, Modal, Tabs } from 'components/index';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';

import { AddContextForm } from './AddContextForm';
import { DetachContext } from './DetachContext';

interface Props {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
}

export const AddContext: FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { currentInstance } = useInventoryContext();
  const items = [
    {
      title: 'Attach Context',
      key: 'attach',
      component: <AddContextForm onClose={onClose} />,
    },
    {
      title: 'Detach Context',
      key: 'detach',
      component: <DetachContext onClose={onClose} />,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={`Context`}
      size="xl"
      body={
        <Stack>
          <DetailsIdentifier label="Name" value={currentInstance.name} />
          <DetailsIdentifier
            label="Cloud ID"
            value={currentInstance.resource_id}
          />
          <Tabs items={items} />
        </Stack>
      }
    />
  );
};
