import React, { FC, useEffect } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import {
  getIconColor,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, PermissionsActionsIcon } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { AccessGraph } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/AccessGraph';
import { ExpandedAccessGraph } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ExpandedAccessGraph';
import {
  getHighPrivilegeColor,
  highPrivilegesConst,
  renderHighPrivilege,
  renderHighPrivilegesStackedCell,
  renderList,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export const SnowflakeRoleAccess: FC = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { canAccess, privilegeSummary } = canAccessSummary;
  const {
    getCanAccessSummary,
    getPrivilegeSummary,
  } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.CanAccess);
    getPrivilegeSummary();
  }, [getCanAccessSummary, getPrivilegeSummary]);

  useEffect(() => {
    //setInternal(filter(canAccess.data, o => !o?.external));
  }, [canAccess.data]);

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: 'permission_entity_name',
    render: ({ row, value }) => (
      <WithResourceIcon resourceType={row?.permission_entity_native_name}>
        <StackedCell
          upper={value}
          lower={
            getCloudNativeName(row?.permission_entity_native_name) +
            ' / ' +
            getCloudAgnosticName(row?.permission_entity_agnostic_name)
          }
        />
      </WithResourceIcon>
    ),
  };
  const owner = {
    header: 'Owner',
    accessor: 'permission_entity_owner',
    align: 'left',
  };

  const privileges = [
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    {
      header: '# of Privileges',
      accessor: 'total_permission_count',
    },
  ];

  const renderAction = ({}) => (
    <Center onClick={e => e.stopPropagation()}>
      <ActionButton icon={<PermissionsActionsIcon />} label="Permissions" />
    </Center>
  );

  const action = {
    header: 'Actions',
    accessor: '',
    render: renderAction,
  };

  const type = {
    header: 'Type',
    align: 'left',
    render: ({ row }) =>
      row?.permission_entity_managed_type || row?.managed_type || '-',
  };

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.Permission}
          bgColor={getIconColor(NativeResources.SnowflakeUsers)}
        >
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Scope',
      accessor: 'scope',
      align: 'left',
      render: renderList,
    },
    type,
    {
      header: '# of Resources',
      accessor: 'destination_count',
    },
    {
      header: 'Actions',
      accessor: 'crud_levels',
      render: ({ value }) => {
        return (
          <Center>
            <HStack spacing={1}>
              {map(
                highPrivilegesConst,
                o =>
                  (value?.includes(o) || value?.includes(o.toLowerCase())) &&
                  renderHighPrivilege(
                    o,
                    getHighPrivilegeColor(o?.toLowerCase()),
                  ),
              )}
            </HStack>
          </Center>
        );
      },
    },
    {
      header: 'High Privileges',
      accessor: 'is_high_privilege',
      render: ({ value }) => (value ? 'Yes' : 'No'),
    },
  ];

  const inheritedColumns = [
    name,
    owner,
    type,
    {
      header: 'Granted on',
      accessor: 'permission_entity_granted_on',
      align: 'left',
      render: ({ value }) => (
        <CustomTooltip label={value?.join(', ')}>
          {value?.join(', ')}
        </CustomTooltip>
      ),
    },
    ...privileges,
  ];

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="ACCESS PRIVILEGES FOR THIS IDENTITY TO ACCESS RESOURCES"
          isLoading={canAccessSummary.accessMap.isLoading}
          panel={<AccessGraph />}
          expandedPanel={<ExpandedAccessGraph />}
          iconBGColor="primary"
          expandOnClickViewAll
        />
        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="ACCESS PRIVILEGES FOR THIS IDENTITY TO ACCESS RESOURCES"
          isLoading={privilegeSummary.isLoading}
          panel={
            <Table
              columns={columns}
              data={privilegeSummary.data}
              isLoading={privilegeSummary.isLoading}
            />
          }
          iconBGColor="primary"
          totalCount={privilegeSummary.data?.length}
        />
        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="INHERITED PRIVILEGES FOR THIS IDENTITY TO MANAGE DATABASES"
          totalCount={canAccess.data?.length}
          isLoading={canAccess.isLoading}
          panel={
            <Table
              columns={inheritedColumns}
              data={canAccess.data}
              isLoading={canAccess.isLoading}
            />
          }
          iconBGColor="primary"
        />
      </Accordion>
    </>
  );
};
