import { useEffect } from 'react';

import { forEach, toArray } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

export const useCallResourceAlertsApi = () => {
  const dispatch = useDispatch();
  const { searchResult } = useSearchResult();
  const { enrichInsights } = useSelector(selectSecurityGraphNext);

  //get resource alerts
  useEffect(() => {
    if (!isEmpty(searchResult.data.nodes) && enrichInsights?.alertingEntities) {
      let uuidsSet = new Set();
      forEach(
        searchResult?.data.nodes,
        o => !!o?.uuid && uuidsSet.add(o?.uuid),
      );
      const uuids = toArray(uuidsSet) as string[];
      dispatch(
        actions.getResourceAlerts({
          q: { requestBody: uuids },
        }),
      );
    }
  }, [dispatch, searchResult.data.nodes, enrichInsights?.alertingEntities]);
};
