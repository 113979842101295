import { useEffect, useReducer } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import {
  InsightV2ApiInsightV2Request,
  NativeResources,
} from '@ariksa/inventory-core/api';
import { find, includes, map, forEach, filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

export const useDataClassificationWizard = () => {
  const { actionType, currentRecord } = useDataConfigurationContext();
  const accounts = useSelector(selectOnboardedCloudAccounts);
  const { scannerInstances, dataScannerInfo, accountType } = useSelector(
    selectDataClassification,
  );

  const dispatch = useDispatch();
  const { toCloudAccountName, toCloudAccountId } = useCloudAccountId();
  const { getEnvironmentOptions } = useEnvironmentOptions();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      accountOptions: [],
      databaseOptions: [],
      selectedDatabases: [],
      scannerOptions: [],
      environmentOptions: [],
      selectedEnvironment: {},
      scannerEnvironmentOptions: [],
      scannerAccountOptions: [],
      selectedAccount: {},
      selectedScanner: {},
      selectedScannerEnvironment: {},
      selectedScannerAccount: {},
      repositoryOptions: [],
      selectedRepository: {},
    },
  );

  useEffect(() => {
    dispatch(
      actions.getDataScannerInfo({
        q: {},
      }),
    );
  }, [accounts, dispatch]);

  useEffect(() => {
    const envs = getEnvironmentOptions();
    updateState({
      environmentOptions: envs,
      scannerEnvironmentOptions: envs,
      selectedScannerEnvironment: envs[0],
      selectedEnvironment: envs[0],
    });
  }, [getEnvironmentOptions]);

  //set account options
  useEffect(() => {
    if (actionType === 'Update')
      updateState({
        selectedEnvironment:
          find(
            state.environmentOptions,
            o => o.value === currentRecord?.environment_id,
          ) ?? {},
      });
  }, [state.environmentOptions, currentRecord, actionType]);

  //set account locator options
  useEffect(() => {
    const type = () => {
      switch (accountType) {
        case SupportedServices.GitHubBranch:
          return CloudProviders.Github;
        case SupportedServices.GitLabBranch:
          return CloudProviders.Gitlab;
        case SupportedServices.BitBucketBranch:
          return CloudProviders.Bitbucket;
        default:
          return (accountType as string).toLowerCase();
      }
    };
    let filteredAccounts = filter(
      accounts.data,
      o =>
        includes(state.selectedEnvironment?.data?.account_ids, o?.uuid) &&
        o?.cloud_type === type(),
    );

    updateState({
      accountOptions: filteredAccounts?.map(o => ({
        label: `${o?.name}`,
        value: o?.uuid,
        data: o,
        type:
          accountType === CloudProviders.Snowflake
            ? NativeResources.SnowflakeDatabases
            : '',
      })),
    });
  }, [
    accountType,
    state.selectedEnvironment,
    dataScannerInfo.data,
    accounts.data,
  ]);

  /*set selected scanner environment*/
  useEffect(() => {
    if (actionType === 'Update')
      updateState({
        selectedScannerEnvironment: find(
          state.scannerEnvironmentOptions,
          o => o?.value === currentRecord?.scanner_environment_id,
        ),
      });
  }, [actionType, currentRecord, state.scannerEnvironmentOptions]);

  //set scanner account options
  useEffect(() => {
    const filteredAccounts: Record<string, any> = [];
    forEach(dataScannerInfo.data, (o, account_id) => {
      if (
        includes(
          state.selectedScannerEnvironment?.data?.account_ids,
          account_id,
        )
      )
        filteredAccounts.push({ scanners: o, accountId: account_id });
    });
    const options = map(filteredAccounts, o => ({
      label: `${toCloudAccountName(o.accountId)} (${toCloudAccountId(
        o.accountId,
      )})`,
      value: o.accountId,
      data: o,
    }));
    updateState({
      scannerAccountOptions: options,
      selectedScannerAccount: options[0],
    });
  }, [
    state.selectedScannerEnvironment,
    toCloudAccountId,
    toCloudAccountName,
    dataScannerInfo.data,
  ]);

  /*set selected scanner account*/
  useEffect(() => {
    if (actionType === 'Update')
      updateState({
        selectedScannerAccount: find(
          state.scannerAccountOptions,
          o => o?.value === currentRecord?.scanner_account_id,
        ),
      });
  }, [actionType, currentRecord, state.scannerAccountOptions]);

  //get scanner instances (EC2)
  useEffect(() => {
    if (!!state.selectedScannerAccount?.value) {
      const instances = map(
        state.selectedScannerAccount?.data?.scanners,
        i => i.instance_id,
      );
      const payload: InsightV2ApiInsightV2Request = {
        insightV2Request: {
          account_id: [state.selectedScannerAccount?.value],
          resource_type: NativeResources.Ec2Instance,
          resource_ids: instances,
          page: 1,
          size: instances.length,
        },
      };

      dispatch(actions.getScannerInstances({ q: payload }));
    }
  }, [state.selectedScannerAccount, dispatch]);

  //set scanner options
  useEffect(() => {
    if (!!state.selectedScannerAccount?.value) {
      const options = map(
        filter(
          state.selectedScannerAccount?.data?.scanners,
          o => o.status === 'Active',
        ),
        o => ({
          label:
            'ariksa-outpost-scanner / ' + o?.instance_id + ' / ' + o?.region,
          value: o?.instance_id,
          data: o,
        }),
      );
      updateState({
        scannerOptions: options,
        selectedScanner: options?.[0] ?? {},
      });
    }
  }, [scannerInstances, toCloudAccountName, state.selectedScannerAccount]);

  //set selected accounts
  useEffect(() => {
    if (actionType === 'Update') {
      updateState({
        selectedAccount:
          find(
            state.accountOptions,
            o => o?.value === currentRecord?.service_ids?.[0],
          ) ?? {},
      });
    } else {
      updateState({ selectedAccount: state.accountOptions[0] });
    }
  }, [actionType, currentRecord, state.accountOptions]);

  return {
    state,
    updateState,
  };
};
