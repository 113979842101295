import React, { useEffect, useState } from 'react';

import { Center, Spinner, Stack } from '@chakra-ui/react';

import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { AIBrainIcon } from 'components/Icons/ReactCustomIcons/AIBrainIcon';
import { AiSearchSuggestions } from 'containers/ConversationalAI/Components/AiSearchSuggestions';

interface AiSearchBoxProps {
  handleSearch(searchTerm: string, type: 'selected' | 'enter'): void;

  value?: string;

  onReset?(): void;

  isLoading: boolean;
}

export const AiSearchBox = (props: AiSearchBoxProps) => {
  const { handleSearch, value, isLoading, onReset } = props;

  const [currentInput, setCurrentInput] = useState(value ?? '');
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setCurrentInput(value?.trim() ?? '');
  }, [value]);

  return (
    <Stack pos={'relative'} boxShadow={'0 1px 2px 0 #eee'}>
      <SearchBox
        leftElement={
          isLoading ? (
            <Center>
              <Spinner size={'md'} />
            </Center>
          ) : (
            <AIBrainIcon color={'primary'} />
          )
        }
        value={currentInput}
        onChange={value => {
          setCurrentInput(value);
          setShowSuggestions(true);
        }}
        fontSize="lg"
        h={12}
        bg="white"
        border="none"
        placeholder="Use Ariksa AI for insights, to perform actions or for help"
        _active={{ border: 'none' }}
        _hover={{ border: 'none' }}
        style={{
          searchBoxSize: 8,
          searchIconColor: 'gray.250',
          pl: 16,
          leftElement: { w: 16 },
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            // @ts-ignore
            handleSearch(e.target.value, 'selected');
            setShowSuggestions(false);
          }
        }}
        onClickClose={() => {
          onReset?.();
        }}
        onBlur={() => {
          setTimeout(() => {
            setShowSuggestions(false);
          }, 300);
        }}
        onFocus={() => setShowSuggestions(true)}
      />

      {showSuggestions && (
        <AiSearchSuggestions
          searchTerm={currentInput}
          onSelect={term => handleSearch(term, 'enter')}
        />
      )}
    </Stack>
  );
};
