import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { Form, FormSchema } from 'components/DataEntry/Form';
import { ComplianceCheckPassedIcon } from 'components/Icons';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { BlueprintWizardStepProps } from 'containers/PolicyHub/Blueprints/types';
import { CreateFormType } from 'containers/PolicyHub/types';

interface Props extends BlueprintWizardStepProps {
  formType: CreateFormType;
  setFormType: (flag: CreateFormType) => void;
}

export const CreatePolicyDetails: FC<Props> = props => {
  const { handleSubmit, formType, setFormType, handleCancel } = props;

  const dispatch = useDispatch();

  const { blueprintForm, blueprints } = useSelector(selectBlueprints);
  const { name, description } = blueprintForm;
  const [blueprintOptions, setBlueprintOptions] = useState<
    Record<string, any>[]
  >([]);
  const [selectedBlueprint, setSelectedBlueprint] = useState<
    Record<string, any>
  >({});

  useEffect(() => {
    const options = blueprints.data?.map(b => ({
      label: b.name,
      value: b.id,
      data: b,
    }));
    setBlueprintOptions(options);
    setSelectedBlueprint(options[0]);
  }, [blueprints.data, dispatch]);

  const updateBlueprintFormField = useCallback(
    (field, value) => {
      dispatch(
        actions.updateBlueprintFormFields({
          field,
          value,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!!selectedBlueprint?.value) {
      dispatch(actions.updateSelectedBlueprintID(selectedBlueprint?.value));
      dispatch(
        actions.getBlueprintByID({
          q: { blueprintId: selectedBlueprint?.value },
        }),
      );
      updateBlueprintFormField(
        'compliance',
        selectedBlueprint?.data?.compliance,
      );
    }
  }, [dispatch, selectedBlueprint, updateBlueprintFormField]);

  const fields: FormSchema =
    formType === CreateFormType.FromExisting
      ? {
          blueprint: {
            type: 'react-select',
            label: 'Select Blueprint',
            isRequired: true,
            isLoading: blueprints.isLoading,
            options: blueprintOptions,
            value: selectedBlueprint,
            onChange: opt => {
              setSelectedBlueprint(opt);
            },
          },
        }
      : {};

  return (
    <Box mt={4}>
      <Form
        title={
          <Box>
            <PageHeaderWithIcon
              label={'Add Blueprint: Details'}
              reversed
              icon={<ComplianceCheckPassedIcon />}
            />
          </Box>
        }
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            value: name,
            isRequired: true,
            onBlur: name => {
              updateBlueprintFormField('name', name);
            },
          },
          description: {
            type: 'textArea',
            label: 'Description',
            value: description,
            isRequired: true,
            onBlur: description => {
              updateBlueprintFormField('description', description);
            },
          },
          options: {
            type: 'radio',
            fields: [
              {
                label: 'Use Existing Blueprint',
                value: CreateFormType.FromExisting,
              },
              { label: 'Build Blueprint', value: CreateFormType.BuildNew },
            ],

            defaultValue: formType,
            onChange: value => {
              setFormType(value as CreateFormType);
              if (value === CreateFormType.BuildNew) {
                dispatch(actions.updateSelectedBlueprintID(''));
                updateBlueprintFormField('rules', []);
                updateBlueprintFormField('compliance', null);
              }
            },
          },
          ...fields,
        }}
        buttonOptions={{
          reset: {
            isVisible: true,
            name: 'Cancel',
            onClick: handleCancel,
          },
          submit: {
            name: 'Next',
          },
        }}
        styles={{
          form: {
            container: { w: '600px' },
            inputWrapper: {
              minH: '300px',
            },
          },
        }}
        handleSubmit={data => {
          handleSubmit(data);
        }}
      />
    </Box>
  );
};
