/**
 *
 * Integrations
 *
 */

import React, { memo } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { SideNav } from 'app/components/Navigation/SideNav';
import { usePageContentContext } from 'components/Layout';
import {
  IntegrationsContextProps,
  IntegrationsContextProvider,
} from 'containers/Setup/Integrations/context';

import { useInjector } from '../../../../utils/inject';

import { useIntegrations } from './hooks/useIntegrations';
import { integrationsSaga } from './saga';
import { selectIntegrations } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const Integrations = memo(() => {
  useInjector(sliceKey, reducer, integrationsSaga);

  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();

  const { currentTab } = useSelector(selectIntegrations);

  const integrations = useIntegrations();
  const { integrationsMenuItems, onCancel } = integrations;
  const contextProps: IntegrationsContextProps = integrations;

  return (
    <Box h={contentHeight + 'px'}>
      <IntegrationsContextProvider value={contextProps}>
        <SideNav
          menuItems={integrationsMenuItems}
          current={currentTab}
          onChange={tab => {
            onCancel();
            dispatch(actions.updateCurrentTab(tab));
          }}
        />
      </IntegrationsContextProvider>
    </Box>
  );
});
