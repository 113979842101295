import React, { FC, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { getIconColor } from 'components/DataDisplay';
import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { PieChart } from 'components/Visualization';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const DistributionByDataLabels: FC = () => {
  const { data } = useSelector(selectActiveResource);
  const { data: labels, isLoading } = data.dataLabels;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(labels?.all - labels?.custom);
    setChartData([
      { name: 'PII', value: labels?.pii, percent: labels?.pii_percentage },
      { name: 'PCI', value: labels?.pci, percent: labels?.pci_percentage },
      { name: 'PHI', value: labels?.phi, percent: labels?.phi_percentage },
      {
        name: 'Sensitive',
        value: labels?.sensitive,
        percent: labels?.sensitive_percentage,
      },
    ]);
  }, [labels]);

  const colorMap = {
    PII: getIconColor(IconTypes.PII),
    PCI: getIconColor(IconTypes.PCI),
    PHI: getIconColor(IconTypes.PHI),
    Sensitive: getIconColor(IconTypes.Sensitive),
  };

  return (
    <FootprintBox
      content={
        <HStack w="full" h="full" justify="space-between" px={4} py={4}>
          <Box w="35%" h="full">
            <PieChart
              isLoading={isLoading}
              data={chartData}
              dataKey="value"
              total={formatNumber(total)}
              outerRadius="100%"
              innerRadius="88%"
              activeShape={false}
              colorMap={colorMap}
              legend={false}
            />
          </Box>
          <Stack w="65%" h="full" justify="space-between">
            {map(chartData, o => (
              <HStack>
                <HStack w={32}>
                  <Center
                    boxSize={1}
                    bg={colorMap[o.name]}
                    borderRadius="full"
                  />
                  <Box>{o.name}</Box>
                </HStack>
                <HStack w="full">
                  <Box w={20}>{o.value}</Box>
                  <Box w="full">
                    <Box
                      borderRadius="full"
                      h={1}
                      bg={colorOpacity(customTheme.colors.primary, 0.2)}
                      w={o.percent + '%'}
                    />
                  </Box>
                  <Box w={24}>{o.percent}%</Box>
                </HStack>
              </HStack>
            ))}
          </Stack>
        </HStack>
      }
      label="Distribution by data labels"
      isLoading={isLoading}
    />
  );
};
