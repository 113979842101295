import React, { ReactNode } from 'react';

import { Box } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface Props {
  content: ReactNode;
  width?: any;
}

export const TimelineCard = (props: Props) => {
  const { width } = props;
  return (
    <Box
      p={4}
      w={width ?? '75%'}
      h="full"
      border="1px solid"
      boxShadow={`5px 5px 2px ${customTheme.colors.gray['50']}`}
      borderRadius={6}
      borderColor="componentBorder"
    >
      {props.content}
    </Box>
  );
};
