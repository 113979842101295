import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AssociatedStorage,
  BackupRetentionPeriod,
  DatabaseEngine,
  DatabaseEngineVersion,
  DeleteProtection,
  HttpEndpointEnabled,
  LastRecoverableTimestamp,
  MasterUsername,
  MultiAZ,
  PreferredBackupWindow,
  PreferredMaintenanceWindow,
  PrivateIP,
  PublicIP,
  RDSClusterMembers,
  RDSClusterUrl,
  RDSKeyId,
  RDSSecurityGroupList,
  ReaderUrl,
  StorageEncrypted,
  StorageType,
  SubnetID,
} from '../../../Components/MetadataField/MetaGridFields';

export const RDSClusterMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {RDSClusterMembers(resource)}
      {RDSSecurityGroupList(resource)}
      {SubnetID(resource)}
      {PublicIP(resource)}
      {PrivateIP(resource)}
      {RDSClusterUrl(resource)}
      {ReaderUrl(resource)}
      {HttpEndpointEnabled(resource)}
      {AssociatedStorage(resource)}
      {StorageType(resource)}
      {StorageEncrypted(resource)}
      {RDSKeyId(resource)}
      {LastRecoverableTimestamp(resource)}
      {PreferredBackupWindow(resource)}
      {PreferredMaintenanceWindow(resource)}
      {BackupRetentionPeriod(resource)}
      {MultiAZ(resource)}
      {DatabaseEngine(resource)}
      {DatabaseEngineVersion(resource)}
      {MasterUsername(resource)}
      {DeleteProtection(resource)}
    </Grid>
  );
};
