import React, { ReactNode, useEffect, useState } from 'react';

import { DataCategory } from '@ariksa/inventory-core';
import { PublicResourceSummary, Resources } from '@ariksa/inventory-core/api';
import { Box, Stack, HStack, Center } from '@chakra-ui/react';
import { each, filter, includes, isEmpty, map, some } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorMap, customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  BlockStorageIcon,
  CloudIcon,
  CriticalDataIcon,
  DatabaseIcon,
  GlobeIcon,
  ObjectStorageIcon,
} from 'components/Icons';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { SearchQueryMapping } from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';
import { limitedString } from 'utils/string';

export const PubliclyAccessibleDataSources: React.FC = () => {
  const navigate = useNavigate();
  const { publiclyExposedDataSources } = useSelector(
    selectDataSecurityOverview,
  );
  const { riskContext } = useSelector(selectSharedState);
  const [categories, setCategories] = useState<
    Record<string, PublicResourceSummary & { icon: ReactNode }>
  >({});
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    const items = {};
    each(publiclyExposedDataSources.data, o => (items[o.category] = o));
    setCategories({
      Buckets: { ...items[DataCategory.Buckets], icon: <ObjectStorageIcon /> },
      Databases: {
        ...items[DataCategory.Databases],
        icon: <DatabaseIcon p={0.5} />,
      },
      Others: {
        ...items[DataCategory.Others],
        icon: <BlockStorageIcon p="1px" />,
      },
      SaaS: { ...items[DataCategory.SaaS], icon: <CloudIcon /> },
    });
  }, [publiclyExposedDataSources.data]);

  const getTopResources = resources => {
    let topResources: Record<string, any>[] = [];
    each(resources, o => {
      if (some(riskContext.data?.[o.uuid], { type: 'Sensitive data' })) {
        topResources.push({ ...o, hasSensitiveData: true });
      }
    });
    const filteredResources = filter(
      resources,
      o => !includes(topResources, t => t?.uuid),
    );

    return [...topResources, ...filteredResources];
  };

  const renderTag = resource => {
    const cloud = accountMapping?.[resource?.account]?.cloud_type ?? '';
    return (
      <CustomTooltip
        label={
          <Stack spacing={0}>
            <Box color="orange">{resource?.name || resource?.resource_id}</Box>
            <Box>
              {resource?.hasSensitiveData
                ? 'Contains sensitive data'
                : 'No sensitive data found'}
            </Box>
          </Stack>
        }
      >
        <HStack
          borderRadius={6}
          bg={
            cloud
              ? colorOpacity(getIconColor(cloud), 0.2)
              : colorOpacity(customTheme.colors.primary, 0.2)
          }
          border="1px solid"
          borderColor={colorMap.primary(200)}
          px={1}
          spacing={1}
          cursor="pointer"
          onClick={() => {
            if (resource?.hasSensitiveData)
              navigate(
                '/visibility/data?tab=DataSources&uuid=' +
                  resource?.uuid +
                  '&resource_id' +
                  resource?.resource_id +
                  '&account_id' +
                  resource?.account +
                  '&resource_type=' +
                  resource?.resource_type,
              );
            else
              navigate(
                '/inventory/summary?category=Data&resource_type=' +
                  resource?.resource_type +
                  '&resource_id=' +
                  resource?.resource_id,
              );
          }}
        >
          {resource?.hasSensitiveData && (
            <Box boxSize={4}>
              <Center>
                <CriticalDataIcon color="critical" />
              </Center>
            </Box>
          )}
          <Box>
            {limitedString(resource?.name || resource?.resource_id, 19)}
          </Box>
        </HStack>
      </CustomTooltip>
    );
  };

  const getResourceType = category => {
    switch (category) {
      case 'Buckets':
        return Resources.ObjectStorage;
      case 'Databases':
        return Resources.Database;
      case 'Disks':
      case 'Others':
        return Resources.BlockStorage;
      default:
        return 'Repository';
    }
  };

  const renderItem = (
    item: PublicResourceSummary & { icon: ReactNode },
    category: DataCategory,
  ) => {
    let resources = getTopResources(map(item?.resources, o => ({ ...o })));
    return (
      <Center h="full" justifyContent="left">
        <HStack fontSize="md">
          <HStack
            cursor="pointer"
            onClick={() => {
              const url = securityGraphRedirectUrl({
                query: getSearchQuery(
                  SearchQueryMapping.Show_resource_type_with_internet_accessible,
                  [getResourceType(item?.category)],
                ),
              });
              navigate(url);
            }}
          >
            <HStack w={32} spacing={4}>
              <Box boxSize={5}>
                <Center color="primary">{item?.icon}</Center>
              </Box>
              <Box>
                {category === DataCategory?.Others ? 'Disks' : category}
              </Box>
            </HStack>
            <Box w={16} fontWeight={600}>
              {item?.total ?? 0}
            </Box>
          </HStack>
          {!!item?.resources && (
            <HStack spacing={1}>
              {resources?.[0] && renderTag(resources?.[0])}
              {resources?.[1] && renderTag(resources?.[1])}
              {item?.total > 2 && (
                <Box
                  borderRadius={6}
                  border="1px solid"
                  borderColor={colorMap.primary(200)}
                  px={1}
                  bg="primary"
                  color="white"
                  cursor="pointer"
                  onClick={() => navigate('/inventory/summary?category=Data')}
                >
                  {'+' + (item?.total - 2)}
                </Box>
              )}
            </HStack>
          )}
        </HStack>
      </Center>
    );
  };

  return (
    <DashboardOverviewCard
      label="Data sources that are publicly exposed"
      icon={<GlobeIcon />}
      content={
        publiclyExposedDataSources.isLoading ? (
          <CustomSpinner />
        ) : isEmpty(publiclyExposedDataSources.data) ? (
          'No data sources are publicly accessible'
        ) : (
          <Box w="full" pl={1} h="full">
            <Stack h="full" justify="space-between">
              {renderItem(
                categories?.[DataCategory.Buckets],
                DataCategory.Buckets,
              )}
              {renderItem(
                categories?.[DataCategory.Databases],
                DataCategory.Databases,
              )}
              {renderItem(
                categories?.[DataCategory.Others],
                DataCategory.Others,
              )}
              {renderItem(categories?.[DataCategory.SaaS], DataCategory.SaaS)}
            </Stack>
          </Box>
        )
      }
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Ariksa Outpost for Data uses Machine Learning (ML) to classify,
              and catalog sensitive data in buckets, databases, code
              repositories etc.
            </Box>
            <Box>
              Deploy Outpost for Data in a region for deeper data insights and
              contextual correlation of risks to your sensitive data
            </Box>
          </Stack>
        ),
        header: 'Enable deeper data insights...',
        footerText: 'Enable now',
        onClickFooter: () => navigate('/setup/outpost/add/data'),
      }}
      iconBgColor="critical"
    />
  );
};
