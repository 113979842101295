import { inlineObjectFieldStyles } from 'components/DataEntry/Form';

export const formStyles = {
  form: {
    formWidth: '600px',
    container: { pt: 2 },
  },
  radioField: {
    radioGroupInnerWrapper: { isInline: false, w: 'full' },
  },
  objectField: {
    ...inlineObjectFieldStyles,
    propertyContainer: { w: '50%' },
  },
};
