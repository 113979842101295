import { createContext, useContext } from 'react';

import { FormAction } from 'components/DataEntry';
import { SideNavKey } from 'components/Navigation';

export interface DataConfigurationContextProps {
  selectedTab: SideNavKey;
  setSelectedTab(value: SideNavKey);
  actionType: FormAction;
  setActionType(value: FormAction);
  setCurrentRecord(value: Record<string, any>);
  currentRecord: Record<string, any>;
  isOpenForm: boolean;
  onCloseForm();
  onOpenForm();
}

export const DataConfigurationContext = createContext<DataConfigurationContextProps>(
  {
    selectedTab: '',
    setSelectedTab: () => {},
    actionType: 'Add',
    setActionType: () => {},
    setCurrentRecord: () => {},
    currentRecord: {},
    isOpenForm: false,
    onCloseForm: () => {},
    onOpenForm: () => {},
  },
);

export const DataConfigurationContextProvider =
  DataConfigurationContext.Provider;

export const useDataConfigurationContext = () =>
  useContext(DataConfigurationContext);
