/* --- STATE --- */
import { BaseSyntheticEvent } from 'react';

import {
  NewUserParams,
  Organization,
  OrganizationServiceCreateRequest,
  UserGroup,
} from 'api/auth/api.pb';
import { ApiCallState } from 'api/types';
import { ApiCallStatus } from 'app/api/types';

export interface OrganizationWizardState {
  createOrganization: ApiCallState<CreateOrganization>;
  getOrganizationById: GetOrganizationOnId;
  updateOrganization: ApiCallState<UpdateOrganization>;
  updateOrgUser: ApiCallState<UpdateOrgUser>;
  organizationOverview: ApiCallState<Organization>;
  orgAdminGroupById: ApiCallState<UserGroup>;
  activeOrganization: any;
}

export interface UpdateOrganization {
  organization: Organization;
}
export interface UpdateOrgUser {
  new_user: NewUserParams;
  formType: ADMIN_FORM_TYPE;
}

export interface CreateOrganization {
  organization: NewOrganizationParams;
  admin_user: NewUserParams;
}

export interface NewOrganizationParams
  extends Omit<OrganizationServiceCreateRequest, 'admin_user'> {}
export interface GetOrganizationOnId extends ApiCallStatus {}

export interface OrganizationWizardStepProps {
  handleReset?: () => void;
  handleSubmit: (values: any, e?: BaseSyntheticEvent) => void;
  handleBack?: () => void;
  action?: 'Create' | 'Update';
}

export type ContainerState = OrganizationWizardState;

export type OrganizationOverviewForm = {
  organizationName: string;
  organizationFullName: string;
  // username: string;
  address: string;
  addressDetails: Address;
  enabled?: boolean;
};

export type Address = {
  country: string;
  city: string;
  zip_code: string;
};

export type AdminConfigForm = {
  email: string;
  username: string;
  password: string;
  fullname: FullName;
  phone_number: PhoneNumber;
};

export type FullName = {
  firstName: string;
  lastName: string;
};

type PhoneNumber = {
  countryCode: string;
  phone: string;
};

export enum ADMIN_FORM_TYPE {
  view_admins = 'view_admins',
  add_new_user = 'add_new_user',
  add_existing_user = 'add_existing_user',
}

export interface FieldValue {
  field: string;
  value: any;
}
