import React from 'react';

import { Icon } from '@chakra-ui/react';

export const Ec2AutoScalingGroupIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M81,91.5H172c6.3,0,11.4-5.1,11.4-11.4c0-6.3-5.1-11.4-11.4-11.4H81c-6.3,0-11.4,5.1-11.4,11.4
		C69.6,86.4,74.7,91.5,81,91.5z"
      />
      <ellipse cx="308.6" cy="80.1" rx="14.2" ry="14.3" />
      <ellipse cx="376.9" cy="80.1" rx="14.2" ry="14.3" />
      <path
        d="M172,206H81c-6.3,0-11.4,5.1-11.4,11.4c0,6.3,5.1,11.4,11.4,11.4H172c6.3,0,11.4-5.1,11.4-11.4
		C183.4,211.1,178.3,206,172,206z"
      />
      <path
        d="M81,343.3c-6.3,0-11.4,5.1-11.4,11.4c0,6.3,5.1,11.4,11.4,11.4H172c6.3,0,11.4-5.1,11.4-11.4
		c0-6.3-5.1-11.4-11.4-11.4H81z"
      />
      <path
        d="M502.6,230.3h-46v-35.7c0-18.8-9.2-35.3-23.1-45.8c13.9-10.4,23.1-27,23.1-45.8V57.2
		c0-31.6-25.5-57.2-56.9-57.2H58.2C26.8,0,1.3,25.6,1.3,57.2V103c0,18.8,9.2,35.3,23.1,45.8c-13.9,10.4-23.1,27-23.1,45.8v45.8
		c0,18.8,9.2,35.3,23.1,45.8c-13.9,10.4-23.1,27-23.1,45.8v45.8c0,31.6,25.5,57.2,56.9,57.2H229v69.1c0,4.5,3.6,8.1,8.1,8.1h97.4
		c4.5,0,8.1-3.6,8.1-8.1v-97.4c0-4.4-3.7-8.1-8.1-8.1h-97.4c-4.5,0-8.1,3.6-8.1,8.1v5.4H58.2c-18.9,0-34.1-15.4-34.1-34.3v-45.8
		c0-19,15.3-34.3,34.1-34.3H229v68.2c0,4.5,3.6,8.1,8.1,8.1c4.5,0,8.1-3.6,8.1-8.1V246.5h249.3v249.3H374.6c-4.5,0-8.1,3.6-8.1,8.1
		c0,4.5,3.6,8.1,8.1,8.1h128c4.5,0,8.1-3.6,8.1-8.1V238.4C510.7,233.9,507.1,230.3,502.6,230.3z M245.2,414.6h81.2v81.2h-81.2V414.6
		z M24.1,103V57.2c0-18.9,15.3-34.3,34.1-34.3h341.5c18.9,0,34.1,15.4,34.1,34.3V103c0,18.9-15.3,34.3-34.1,34.3H58.2
		C39.4,137.3,24.1,121.9,24.1,103z M383.1,230.3c4.8-2.3,8.1-7.2,8.1-12.9c0-7.9-6.4-14.3-14.2-14.3c-7.9,0-14.2,6.4-14.2,14.3
		c0,5.7,3.3,10.6,8.1,12.9h-56.1c4.8-2.3,8.1-7.2,8.1-12.9c0-7.9-6.4-14.3-14.2-14.3c-7.9,0-14.2,6.4-14.2,14.3
		c0,5.7,3.3,10.6,8.1,12.9h-65.5c-4.5,0-8.1,3.6-8.1,8.1v36.2H58.2c-18.9,0-34.1-15.4-34.1-34.3v-45.8c0-19,15.3-34.3,34.1-34.3
		h341.5c18.9,0,34.1,15.4,34.1,34.3v35.7H383.1z"
      />
      <path
        d="M445.5,307v42.9c0,4.5,3.6,8.1,8.1,8.1c4.5,0,8.1-3.6,8.1-8.1v-62.6c0-4.5-3.6-8.1-8.1-8.1H391
		c-4.5,0-8.1,3.6-8.1,8.1c0,4.5,3.6,8.1,8.1,8.1H434l-77.3,77.4c-3.2,3.2-3.2,8.3,0,11.5c1.5,1.6,3.6,2.4,5.7,2.4
		c2,0,4.2-0.8,5.7-2.4L445.5,307z"
      />
    </g>
  </Icon>
);
