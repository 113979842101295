import { AwsIcon, PermissionsActionsIcon } from 'components/Icons';
import { AutomaticOnboardingHelp } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/AutomatedOnboardingHelp';
import { CreateAutomaticIAMRoleHelp } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/CreateAutomaticIAMRoleHelp';
import { ManualOnboardingHelp } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/ManualOnboardingHelp';
import { Permissions } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/Permissions';
import { OnboardingHelp } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/types';

export const helpHeaderMapping = (type: OnboardingHelp) => {
  switch (type) {
    case OnboardingHelp.Permission:
      return {
        header: 'Permissions required by Ariksa',
        content: <Permissions />,
        icon: <PermissionsActionsIcon />,
      };
    case OnboardingHelp.ManualOnboarding:
      return {
        header: 'Manual account onboarding',
        content: <ManualOnboardingHelp />,
        icon: <AwsIcon color="inherit" />,
      };
    case OnboardingHelp.AutomatedOnboarding:
      return {
        header: 'Automated onboarding of accounts',
        content: <AutomaticOnboardingHelp />,
        icon: <AwsIcon color="inherit" />,
      };
    case OnboardingHelp.Automation:
      return {
        header: 'For automatic management account onboarding',
        content: <CreateAutomaticIAMRoleHelp />,
        icon: <AwsIcon color="inherit" />,
      };
  }
};
