import React, { useEffect, useState } from 'react';

import {
  Box,
  Divider,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { BarChart } from 'components/Visualization';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { selectUser } from 'containers/App/selectors';

import { formatDate } from '../../../../../../utils/date';

export const ResourceActions = () => {
  const {
    resourceActionsMonthly,
    resourceActionsDaily,
    resourceInsight,
  } = useSelector(selectActiveResource);
  const { info } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [monthlyActionsData, setMonthlyActionsData] = useState<any[]>([]);
  const [average, setAverage] = useState(1);

  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [dataOnDay, setDataOnDay] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
      actions.getResourceActionsMonthly({
        q: {
          orgId: info.org_uuid,
          resourceId: resourceInsight.data?.id ?? resourceInsight.data?.arn,
        },
      }),
    );
  }, [
    dispatch,
    info.org_uuid,
    resourceInsight.data?.arn,
    resourceInsight.data?.id,
  ]);

  const customTooltipContent = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const { name, payload: obj } = payload[0] ?? {};

      return (
        <>
          <CustomTooltip w={'150px'}>
            <Stack color={obj?.fill} fontSize={'xs'}>
              {obj.count > average && (
                <>
                  <Text>Spike in activity</Text>
                </>
              )}
              <Text>{obj.count} permissions</Text>
            </Stack>
          </CustomTooltip>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    const actions =
      resourceActionsMonthly.data.results?.map(d => {
        return {
          date: dayjs(d.time?.toString()).format('MM/DD'),
          fullDate: dayjs(d.time?.toString()).format('YYYY/MM/DD'),
          count: parseInt(d.actions?.toString() ?? '0'),
        };
      }) ?? [];
    const sorted = sortBy(actions, 'fullDate');
    setMonthlyActionsData(sorted);
    if (sorted.length) {
      const sum = sorted.reduce(
        (a, b) => a + parseInt(b.count?.toString() ?? '0'),
        0,
      );
      setAverage(sum / sorted.length);
      setSelectedDay(sorted[sorted.length - 1].fullDate);
    }
  }, [resourceActionsMonthly.data.results]);
  console.log(selectedDay);
  useEffect(() => {
    if (selectedDay) {
      dispatch(
        actions.getResourceActionsDaily({
          q: {
            orgId: info.org_uuid,
            resourceId: resourceInsight.data?.id ?? resourceInsight.data?.arn,
            day: dayjs(selectedDay).format(),
          },
        }),
      );
    }
  }, [
    dispatch,
    info.org_uuid,
    resourceInsight.data?.arn,
    resourceInsight.data?.id,
    selectedDay,
  ]);

  useEffect(() => {
    const actions =
      resourceActionsDaily.data?.actions?.map((v, k) => {
        return {
          action: v.action,
          permission_type: v.permission_type,
          count: v.count,
          timestamp: v.first?.toString(),
        };
      }) ?? [];
    setDataOnDay(actions);
  }, [resourceActionsDaily.data?.actions]);

  const columns = [
    {
      header: 'Action',
      accessor: 'action',
      render: ({ value }) => {
        return value;
      },
    },
    {
      header: 'Permissions',
      accessor: 'permission_type',
      align: 'left',
    },
    {
      header: 'Count',
      accessor: 'count',
    },
    {
      header: 'Timestamp',
      accessor: 'timestamp',
      render: ({ value }) => {
        return formatDate(value);
      },
    },
  ];

  return (
    <Stack h={'full'} spacing={8}>
      <Box minH={200}>
        <BarChart
          data={monthlyActionsData}
          xField="date"
          yField={['count', 'over']}
          stacked
          legend={false}
          colors={monthlyActionsData.map(d =>
            d.count > average
              ? customTheme.colors.red['500']
              : customTheme.colors.primary,
          )}
          customTooltipContent={customTooltipContent}
          isLoading={resourceActionsMonthly.isLoading}
          onClick={(e, k, v) => {
            console.log(v);
            setSelectedDay(v.fullDate ?? null);
          }}
        />
      </Box>

      <Stack flex={1} spacing={10}>
        {selectedDay && monthlyActionsData.some(d => d.count > average) && (
          <Stack>
            <HStack>
              <AriksaIcon
                type={IconTypes.ExclamationTriangle}
                size={'sm'}
                bg={'critical'}
              />
              <Text>ANOMALIES DETECTED ON THE RESOURCE ON {selectedDay}</Text>
            </HStack>
            <Divider />
            <Stack pl={10}>
              <UnorderedList spacing={2}>
                <ListItem>
                  Number of actions were significantly higher than the rolling
                  average over the last 30 days
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        )}
        {/*<Stack flex={1} spacing={4}>*/}
        {/*  <HStack>*/}
        {/*    <AriksaIcon*/}
        {/*      type={IconTypes.Permission}*/}
        {/*      size={'sm'}*/}
        {/*      bg={'primary'}*/}
        {/*    />*/}
        {/*    <Text>ACTIONS PERFORMED ON {selectedDay}</Text>*/}
        {/*  </HStack>*/}
        {/*  <Divider />*/}
        {/*  <Table*/}
        {/*    columns={columns}*/}
        {/*    data={dataOnDay}*/}
        {/*    isLoading={resourceActionsMonthly.isLoading}*/}
        {/*  />*/}
        {/*</Stack>*/}
      </Stack>
    </Stack>
  );
};
