import React, { useEffect, useMemo, useState } from 'react';

import { CloudProvider } from '@ariksa/inventory-core';
import { ItemApiApproveRequestRequest } from '@ariksa/jit';
import {
  AccessRequestGetResponse,
  ItemApiDenyRequestRequest,
} from '@ariksa/jit/api';
import { Box, HStack, Stack, Text, Textarea } from '@chakra-ui/react';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import {
  CancelButton,
  PrimaryButton,
  textAreaFieldStyles,
} from 'components/DataEntry';
import { CustomDayPicker } from 'components/DataEntry/Date';
import { TimePicker } from 'components/DataEntry/Time/TimePicker';
import { ManualIcon } from 'components/Icons/ReactResourceIcons/ManualIcon';
import { Modal } from 'components/Overlay';
import { successToast } from 'components/Toast';
import { selectUser } from 'containers/App/selectors';
import {
  getCloudUser,
  renderJitPermissions,
  renderJitPermissionsTable,
  renderJitResourceTypes,
} from 'containers/Visibility/JitRequests/Components/utils';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { actions } from 'containers/Visibility/JitRequests/slice';
import { parseUtcDate } from 'utils/date';

interface ApproveDenyModalProps {
  request: AccessRequestGetResponse;
  isOpen: boolean;

  onClose(): void;
}

export const ApproveDenyModal = (props: ApproveDenyModalProps) => {
  const { request, isOpen, onClose } = props;
  const { info } = useSelector(selectUser);
  const { jitAccountAction } = useSelector(selectJitRequests);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<string>('00:00');
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<string>('00:00');

  const processed =
    request.status === 'APPROVED' || request.status === 'DENIED';

  const account =
    request.access_request_details?.[request.cloud_type.toLowerCase()];

  const requestAccount = useMemo(() => {
    return request.access_request_details?.[request.cloud_type.toLowerCase()];
  }, [request]);

  // parse the request start and end time to local state as dayjs object
  useEffect(() => {
    let requestStartTime: any = null;
    let requestEndTime: any = null;
    if (request.status === 'APPROVED') {
      requestStartTime = parseUtcDate(request.approved_start_time ?? '');
      requestEndTime = parseUtcDate(request.approved_end_time ?? '');
    } else {
      requestStartTime = parseUtcDate(request.requested_start_time);
      requestEndTime = parseUtcDate(request.requested_end_time);
    }

    setStartDate(requestStartTime?.startOf('day')!);
    setEndDate(requestEndTime?.startOf('day')!);

    if (requestStartTime) {
      setStartTime(
        `${requestStartTime.format('HH')}:${requestStartTime.format('mm')}`,
      );
    }

    if (requestEndTime) {
      setEndTime(
        `${requestEndTime.format('HH')}:${requestEndTime.format('mm')}`,
      );
    }
  }, [
    request.approved_end_time,
    request.approved_start_time,
    request.requested_end_time,
    request.requested_start_time,
    request.status,
  ]);

  // combine the date and time to form the start date time object
  const startDateTime = useMemo(() => {
    if (!startDate) return null;
    const [hour, minute] = startTime.split(':');
    let dateTime = startDate.clone();
    dateTime = dateTime.add(Number(hour), 'hour');
    dateTime = dateTime.add(Number(minute), 'minute');

    return dateTime;
  }, [startDate, startTime]);

  // combine the date and time to form the end date time object
  const endDateTime = useMemo(() => {
    if (!endDate) return null;
    const [hour, minute] = endTime.split(':');
    let dateTime = endDate.clone();
    dateTime = dateTime.add(Number(hour), 'hour');
    dateTime = dateTime.add(Number(minute), 'minute');

    return dateTime;
  }, [endDate, endTime]);

  const handleApprove = () => {
    if (!startDateTime || !endDateTime) {
      return;
    }
    const payload: ItemApiApproveRequestRequest = {
      requestId: request.request_id,
      approvalGrantedInfo: {
        approver: info.email ?? '',
        approver_comments: comment,
        cloud_type: request.cloud_type as CloudProvider,
        approved_start_time: dayjs(startDateTime).utc().format(),
        approved_end_time: dayjs(endDateTime).utc().format(),
      },
    };

    dispatch(
      actions.approveJitAccount({
        q: payload,
        onSuccess: () => {
          onClose();
          dispatch(actions.getJitRequests({ q: {} }));
          successToast({
            title: 'Success',
            description: 'Request approved successfully',
          });
        },
      }),
    );
  };

  const handleDeny = () => {
    const payload: ItemApiDenyRequestRequest = {
      requestId: request.request_id,
      approvalDeniedInfo: {
        reason: comment ?? '',
        denied_by: info.email ?? '',
      },
    };

    dispatch(
      actions.denyJitAccount({
        q: payload,
        onSuccess: () => {
          onClose();
          dispatch(actions.getJitRequests({ q: {} }));
          successToast({
            title: 'Success',
            description: 'Request denied successfully',
          });
        },
      }),
    );
  };

  const requestInfo = useMemo(() => {
    const info =
      request?.access_request_details?.[request.cloud_type.toLowerCase()];

    //console.log(info);
    return {
      cloud_id: info?.cloud_account_id ?? '',
      role: info?.role ?? '',
      aws_resources: info?.aws_resources ?? [],
    };
  }, [request?.access_request_details, request.cloud_type]);

  const cloudUser = useMemo(() => getCloudUser(request, requestAccount), [
    request,
    requestAccount,
  ]);

  const styles = {
    label: { color: 'black' },
    value: { color: customTheme.colors.gray['300'] },
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper="JIT Request - Review"
          lower={'Request ID: ' + requestInfo.cloud_id}
          icon={<ManualIcon />}
        />
      }
      body={
        <Stack w="full" spacing={4}>
          <DetailsIdentifier
            label="Access role"
            styles={styles}
            value={requestInfo?.role || 'Custom'}
            pb={1}
          />
          <DetailsIdentifier
            label="Requester"
            styles={styles}
            value={cloudUser}
            pb={1}
          />
          <DetailsIdentifier
            label="Permissions"
            styles={styles}
            direction={'column'}
            value={
              <Stack>
                {!!requestAccount?.role &&
                  renderJitPermissions(requestAccount?.crud_counts)}
                {renderJitPermissionsTable({
                  resources: requestInfo?.aws_resources,
                })}
              </Stack>
            }
            pb={1}
          />
          <DetailsIdentifier
            label="Resource Types"
            styles={styles}
            value={renderJitResourceTypes(requestAccount?.aws_resources)}
          />
          <DetailsIdentifier
            label="Access start time"
            styles={styles}
            align="center"
            value={
              <HStack>
                <CustomDayPicker
                  dateProps={{
                    mode: 'single',
                    onSelect: selected => {
                      setStartDate(dayjs(selected!).startOf('day'));
                    },
                    selected: startDate?.toDate(),
                  }}
                />
                <TimePicker onChange={setStartTime} value={startTime} />
                {startDateTime && (
                  <Text>
                    {startDateTime.utc().format('DD-MMM-YYYY HH:mm:ss')} (UTC)
                  </Text>
                )}
              </HStack>
            }
          />
          <DetailsIdentifier
            label="Access end time"
            styles={styles}
            align="center"
            value={
              <HStack>
                <CustomDayPicker
                  dateProps={{
                    mode: 'single',
                    onSelect: selected => {
                      setEndDate(dayjs(selected!).startOf('day'));
                    },
                    selected: endDate?.toDate(),
                  }}
                />
                <TimePicker onChange={setEndTime} value={endTime} />
                {endDateTime && (
                  <Text>
                    {endDateTime.utc().format('DD-MMM-YYYY HH:mm:ss')} (UTC)
                  </Text>
                )}
              </HStack>
            }
          />
          <DetailsIdentifier
            label="Requester Comments"
            styles={styles}
            direction="column"
            value={request.reason ?? ''}
            spacing={1}
            py={1}
          />
          <DetailsIdentifier
            label="Approver Comments"
            styles={styles}
            direction="column"
            value={
              <Textarea
                placeholder="Add comment to deny"
                value={comment}
                onChange={e => {
                  setComment(e.target.value);
                }}
                {...textAreaFieldStyles.textArea}
              />
            }
            spacing={1}
            pt={1}
          />
          {processed && (
            <DetailsIdentifier
              label="Approval Status"
              styles={styles}
              value={
                <Box
                  color={
                    request.status === 'APPROVED'
                      ? customTheme.colors.green['300']
                      : customTheme.colors.red['300']
                  }
                >
                  {request.status}
                </Box>
              }
            />
          )}
        </Stack>
      }
      footer={
        <HStack justify="space-between" w="full">
          <CancelButton onClick={onClose} />
          <HStack justify="flex-end">
            <PrimaryButton
              isLoading={jitAccountAction.isLoading}
              isDisabled={!comment.trim() || processed}
              onClick={handleDeny}
              bg={customTheme.colors.red['500']}
              color="white"
              _active={{ bg: customTheme.colors.red['400'] }}
              _focus={{ bg: customTheme.colors.red['500'] }}
              _hover={{ bg: customTheme.colors.red['500'] }}
            >
              Deny
            </PrimaryButton>
            <PrimaryButton
              isLoading={jitAccountAction.isLoading}
              isDisabled={
                !startDateTime ||
                !endDateTime ||
                startDateTime > endDateTime ||
                processed ||
                !comment.trim()
              }
              onClick={handleApprove}
              bg={customTheme.colors.green['300']}
              color="white"
              _active={{ bg: customTheme.colors.green['300'] }}
              _hover={{ bg: customTheme.colors.green['300'] }}
              _focus={{ bg: customTheme.colors.green['300'] }}
            >
              Approve
            </PrimaryButton>
          </HStack>
        </HStack>
      }
      styles={{
        modal: { size: '2xl' },
      }}
    />
  );
};
