import React, { FC } from 'react';

import { Box, OrderedList, ListItem, Link } from '@chakra-ui/react';

interface IConsoleCommands {
  steps: any;
}

export const AwsConsoleSteps: FC<IConsoleCommands> = props => {
  const { steps } = props;
  return (
    <OrderedList spacing={2}>
      {steps.map((s, index) => (
        <Step {...s} key={index} />
      ))}
    </OrderedList>
  );
};

const Step = props => {
  const { content_step = [], aws_steps = [] } = props;

  return (
    <ListItem>
      <Box>
        {content_step.map((cs, index) => (
          <ContentStep {...cs} key={index} />
        ))}
      </Box>
      {!!aws_steps.length && <AwsConsoleSteps steps={aws_steps} />}
    </ListItem>
  );
};

const ContentStep = props => {
  const { format = '', content_value = '' } = props;
  if (format === 'bold') {
    return (
      <span>
        <b>{content_value}</b>
      </span>
    );
  }
  if (format === 'aws_url' || format === 'resource_url') {
    return (
      <Link target="_blank" href={content_value.toString()} color={'primary'}>
        {content_value}
      </Link>
    );
  }
  return <span>{content_value} &nbsp;</span>;
};
