export const policyHubTourSteps = [
  {
    selector: '[className="policyHub_heading"]',
    content:
      'Policy Hub, as the name suggests, is a repository for all your security and compliance\n' +
      'policies. It provides easy customization of your policies so that you can focused on\n' +
      'discovery, assessment and remediation of issues that are important for you, instead of just\n' +
      'of bunch of generic best practice policies',
  },
  {
    selector: '[className="policy_blueprints"]',
    content: 'Review and customize any policy blueprint to your needs',
  },
  {
    selector: '[className="blueprint_quick_overview"]',
    content:
      'Get a quick overview – users using it, environment where it is applied and no. of rules',
  },
];
