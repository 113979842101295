import React, { FC, useCallback } from 'react';

import { Stack } from '@chakra-ui/react';

import { useResourceType } from 'containers/App/hooks/useResourceType';
import { FilterField } from 'containers/Visibility/Components/Filter/FilterField';
import { GraphFilterBox } from 'containers/Visibility/SecurityGraph/Components/utils';

export interface ResourceFilterItemEntry {
  key: string;
  resourceType: string;
  resourceId: string;
}

interface IResourceFilterItems {
  filters: ResourceFilterItemEntry[];
  nodes: any[];

  onRemove?(entry: ResourceFilterItemEntry);
}

export const ResourceFilterItems: FC<IResourceFilterItems> = props => {
  const { filters, nodes = [], onRemove } = props;
  const { getResourceAlias } = useResourceType();

  const findNode = useCallback(
    (id: string) => {
      return nodes?.find(
        n =>
          n.uuid?.includes(id ?? '') ||
          id.includes(n.uuid ?? '') ||
          n.resource_id === id,
      );
    },
    [nodes],
  );

  return (
    <Stack spacing={4} fontSize="xs">
      {filters.map(f => {
        return (
          <GraphFilterBox onRemove={() => onRemove?.(f)}>
            <Stack>
              <FilterField
                field={'Resource type'}
                value={getResourceAlias(f.resourceType)!}
              />
              {f.resourceId && (
                <FilterField
                  field={'Resource ID'}
                  value={findNode(f.resourceId)?.resource_id ?? '-'}
                />
              )}
            </Stack>
          </GraphFilterBox>
        );
      })}
    </Stack>
  );
};
