import React, { ReactNode, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import round from 'lodash/round';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CountFontSize } from 'containers/Dashboard/types';
import {
  DashboardOverviewTooltip,
  DashboardOverviewTooltipProps,
} from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import { dashboardCardHoverStyles } from 'containers/Dashboard/utils/styles';
import {
  renderCloudIcons,
  renderPercentChange,
} from 'containers/Dashboard/utils/utils';

interface Props {
  content?: ReactNode;
  label?: string;
  labelTooltip?: ReactNode;
  icon?: ReactNode;
  tooltip?: DashboardOverviewTooltipProps;
  cloudProviders?: string[];
  count?: number;
  showPercentChange?: boolean;

  onClick?();

  change?: {
    first?: number;
    last?: number;
    totalChange?: number;
    percentChange?: number;
    isLoading: boolean;
    changeColor?: string;
  };
  isLoading?: boolean;
  info?: {
    message: string | ReactNode;
    isLoading?: boolean;
    onClick?();
    enabled?: boolean;
    bg?: string;
  };
}

export const SummaryCard: React.FC<Props> = props => {
  const {
    label,
    labelTooltip,
    icon,
    isLoading,
    count,
    info,
    change,
    content,
    cloudProviders,
    tooltip,
    onClick,
    showPercentChange = true,
  } = props;

  const [percentChange, setPercentChange] = useState(0);
  const [totalChange, setTotalChange] = useState(0);

  useEffect(() => {
    if (!!change) {
      const val =
        change?.totalChange ?? (change?.last ?? 0) - (change?.first ?? 0);
      setTotalChange(val);
      if (val === 0) setPercentChange(0);
      else
        setPercentChange(
          !!change?.percentChange
            ? round(change?.percentChange, 1)
            : !!change?.first
            ? round((val / change?.first) * 100, 1)
            : 100,
        );
    }
  }, [change]);

  const renderTag = () => (
    <Box
      px={3}
      color={customTheme.colors.gray['300']}
      bg={info?.bg ?? (info?.enabled ? '#ebf5de' : '#f7dddd')}
      onClick={e => {
        e?.stopPropagation();
        info?.onClick?.();
      }}
      borderRadius={3}
    >
      <Center>{info?.message}</Center>
    </Box>
  );

  const renderContent = () => (
    <Stack
      w="full"
      h="full"
      justify="space-between"
      onClick={onClick}
      cursor={!!onClick ? 'pointer' : 'default'}
    >
      <Stack>
        <HStack w="full" justify="space-between">
          <HStack w="full">
            <HStack spacing={4}>
              <Box boxSize={6} color="primary">
                <Center>{icon}</Center>
              </Box>
              <Box fontSize={16}>{label}</Box>
            </HStack>
          </HStack>
          <Box>{renderCloudIcons(cloudProviders)}</Box>
        </HStack>
      </Stack>
      <HStack w="full" justify="space-between" spacing={0}>
        <Box fontSize={CountFontSize} fontWeight={600}>
          {isLoading ? <CustomSpinner /> : formatNumber(count ?? 0, 1)}
        </Box>
        {info &&
          (info?.isLoading ? (
            <Box w={32}>
              <CustomSpinner />
            </Box>
          ) : tooltip && !info?.enabled ? (
            <DashboardOverviewTooltip {...tooltip} button={renderTag()} />
          ) : (
            renderTag()
          ))}
      </HStack>
      <HStack w="full" justify="space-between">
        <Box>
          {showPercentChange &&
            (change?.isLoading ? (
              <CustomSpinner />
            ) : (
              renderPercentChange(percentChange, change?.changeColor)
            ))}
        </Box>
        <Box
          color={
            totalChange === 0
              ? customTheme.colors.gray['300']
              : change?.changeColor ??
                (totalChange < 0 ? customTheme.colors.green['300'] : 'critical')
          }
        >
          {(totalChange < 0 ? '' : '+') + formatNumber(totalChange)} in last 7
          days
        </Box>
      </HStack>
    </Stack>
  );

  return (
    <Box
      py={3}
      px={label ? 4 : 2}
      borderRadius={8}
      w="full"
      h="full"
      bg="white"
      {...dashboardCardHoverStyles}
    >
      {content ? (
        <Center h="full">{content}</Center>
      ) : labelTooltip ? (
        <CustomTooltip label={labelTooltip} styles={{ text: { h: 'full' } }}>
          <Box w="full" h="full">
            {renderContent()}
          </Box>
        </CustomTooltip>
      ) : (
        renderContent()
      )}
    </Box>
  );
};
