import {
  SearchResponse,
  SearchV2ApiNetworkMapRequest,
  SearchV2ApiSearchV2Request,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useInventorySearchV2Service() {
  const searchMap = useAriksaQuery<SearchV2ApiSearchV2Request, SearchResponse>({
    queryKey: [],
    fetcher: req =>
      InventoryService.SearchService.search(req).then(r => r.data),
  });

  const networkMap = useAriksaQuery<
    SearchV2ApiNetworkMapRequest,
    SearchResponse
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.SearchServiceV2.networkMap(req).then(r => r.data),
  });

  return { networkMap, searchMap };
}
