import React from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';

import { T1, T2 } from 'components/DataDisplay';

export function FieldValue({ field, value, ...rest }) {
  return (
    <HStack fontSize={'md'} align={'center'} {...rest}>
      <Text>{field}: </Text> <Box flex={1}>{value}</Box>
    </HStack>
  );
}

export function FieldValT1({ field, value = '', ...rest }) {
  return (
    <T1 {...rest}>
      {field}: {value}
    </T1>
  );
}

export function FieldValT2({ field, value = '', ...rest }) {
  return (
    <T2 {...rest}>
      {field}: {value}
    </T2>
  );
}
