import { barStyles, commonStyles } from '../styles';
import { customizedLegend, customizedRightYAxisTick } from '../utils';

export const defaultStyles = {
  composedChart: {
    margin: {
      top: 30,
      right: 10,
    },
  },
  tooltip: {
    cursor: {
      opacity: 0.3,
      border: '1px solid black',
    },
    contentStyle: {
      fontSize: '14px',
    },
  },
  legend: {
    wrapperStyle: {
      top: -5,
    },
    align: 'right',
    verticalAlign: 'top',
    iconSize: 8,
    formatter: customizedLegend,
  },
  bar: barStyles,
  ...commonStyles,
  firstYAxis: commonStyles.yAxis,
  secondYAxis: {
    ...commonStyles.yAxis,
    tick: props => customizedRightYAxisTick(props),
  },
};
