import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ThirdPartyIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M15 3.5H20.5M20.5 3.5V9M20.5 3.5L12.5 11.5"
      stroke="currentColor"
    />
    <path
      d="M11.5 5.5H7.5C6.39543 5.5 5.5 6.39543 5.5 7.5V16.5C5.5 17.6046 6.39543 18.5 7.5 18.5H16.5C17.6046 18.5 18.5 17.6046 18.5 16.5V12.5"
      stroke="currentColor"
      strokeLinecap="round"
      fill="none"
    />
  </Icon>
);
