import React from 'react';

import { Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { renderSeverityBubble } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';

import { selectCompliance } from '../../../selectors';
import { actions } from '../../../slice';
import { AddException } from '../../AddException';
import { SeverityComponent } from '../../SeverityComponent';

interface Props {
  showFailed: boolean;
  selectedResource: Record<string, any>;
  selectedBlueprint: Record<string, any>;
  tableStyles?: any;
  showRuleId?: boolean;
  fromCompliance?: boolean;
}

export const RulesForResource: React.FC<Props> = props => {
  const {
    showFailed,
    selectedResource,
    tableStyles,
    selectedBlueprint,
    showRuleId = true,
    fromCompliance = false,
  } = props;
  const dispatch = useDispatch();
  const { rules } = useSelector(selectCompliance);

  const {
    isOpenSeverityModal,
    isOpenSuppress,
    onCloseSuppress,
    renderResourceActions,
  } = useCompliance();

  const { withAccessBoundary } = useAccessBoundary();

  useDeepCompareEffect(() => {
    if (!isEmpty(selectedBlueprint))
      dispatch(
        actions.loadRules({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            resourceId: selectedResource?.resource_id,
            resourceType: selectedResource?.type,
            show: showFailed ? 'FAILED' : 'ALL',
            page: rules.page.info.page_number,
            size: rules.page.info.page_size,
          }),
        }),
      );
  }, [
    dispatch,
    selectedBlueprint,
    showFailed,
    rules.page.info,
    selectedResource,
    withAccessBoundary,
  ]);

  const ruleId = {
    header: 'Rule Id',
    accessor: 'name',
    align: 'left',
    styles: { width: '400px', cell: { maxWidth: '400px' } },
  };
  const description = {
    header: 'Rule Description',
    accessor: 'description',
  };
  const restColumns = [
    {
      header: 'Status',
      accessor: 'check_stats.passed',
      render: ({ value }) => {
        return value === 0 ? (
          <Text color="red">Fail</Text>
        ) : (
          <Text color="green">Pass</Text>
        );
      },
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      styles: { width: '60px', cell: { maxWidth: '60px' } },
      render: renderSeverityBubble,
    },
    {
      header: 'Actions',
      accessor: '',
      render: renderResourceActions,
      /*render: ({ row }) => (
        <Center>
          <ActionButton
            icon={<BarChartIcon />}
            label="Severity"
            onClick={e => onClickSeverity(e, row)}
          />
          <AddExceptionActionButton
            onConfirm={e => {
              onClickSuppress(e, row);
            }}
            description={row.description}
            //isLoading={addException.isLoading}
            resourceType={selectedResource?.type}
            resourceName={selectedResource?.name}
            resourceId={selectedResource?.resource_id}
            blueprintName={selectedBlueprint?.name}
            environment={selectedResource?.environment_id}
            alertCount={{}}
          />
        </Center>
      ),*/
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  return (
    <>
      <Table
        data={rules.data}
        columns={
          showRuleId
            ? [description, ruleId, ...restColumns]
            : [description, ...restColumns]
        }
        isLoading={rules.isLoading}
        pagination={{
          totalCount: rules.page.totalCount,
          pageInfo: rules.page.info,
          onChange: pageInfo => {
            dispatch(actions.updateRulesPageInfo(pageInfo));
          },
        }}
        styles={tableStyles}
      />
      {isOpenSeverityModal && !fromCompliance && (
        <SeverityComponent
          resource={selectedResource}
          blueprint={selectedBlueprint}
        />
      )}
      {isOpenSuppress && !fromCompliance && (
        <AddException
          isOpen={isOpenSuppress}
          onClose={onCloseSuppress}
          resource={selectedResource}
        />
      )}
    </>
  );
};
