import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { resourceIcons } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { REGION_NODE } from './nodes_types';

export function registerRegionNode() {
  G6.registerNode(
    REGION_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'circle',
          {
            attrs: {
              x: 30,
              y: 30,
              fill: colors.white,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              r: 20,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        let img = resourceIcons.region;

        group.addShape('image', {
          attrs: {
            x: x + 10,
            y: y + 10,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        group.addShape('text', {
          attrs: {
            x: x,
            y: y + 60 + 10,
            textAlign: 'left',
            // @ts-ignore
            text: cfg?.data?.name || 'None', // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'guid',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
