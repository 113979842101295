import React, { FC, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import Keycloak from 'keycloak-js';
import { isEmpty } from 'lodash';

import { BASE_URL, KEYCLOAK_SERVICE } from 'api/service_urls';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { useSearchParams } from 'hooks/useSearchParams';

import request from '../../../utils/request';

interface ILogin {}

export const Login: FC<ILogin> = props => {
  const queryParams = useSearchParams();

  // get callback url
  useEffect(() => {
    if (isEmpty(queryParams)) return;

    const keycloak = new Keycloak({
      clientId: 'app',
      realm: queryParams.organization,
      url: KEYCLOAK_SERVICE,
    });

    keycloak
      .init({
        onLoad: 'login-required',
        redirectUri: `${BASE_URL}/auth/login?callback_url=${queryParams.callback_url}&organization=${queryParams.organization}&auth_code=${queryParams.code}&auth_state=${queryParams.state}`,
        checkLoginIframe: false,
      })
      .then(res => {
        sessionStorage.setItem('authentication', keycloak.token as string);
        sessionStorage.setItem('refreshToken', keycloak.refreshToken as string);
        const {
          callback_url,
          organization,
          auth_code,
          auth_state,
        } = queryParams;

        request(callback_url, {
          method: 'POST',
          data: {
            organization,
            auth_code,
            auth_state,
          },
        }).then(res => {
          const { redirect_url = '' } = res.data;
          if (redirect_url) {
            window.open(redirect_url);
          }
        });
      })
      .catch(res => {
        console.error(res);
      });
  }, [queryParams]);

  return (
    <Box w={'full'} h={'full'} pos={'absolute'}>
      <CustomSpinner loading={true} />
    </Box>
  );
};
