import React, { FC, useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { getIcon } from 'components/Icons/Components';
import { getRegionOptionsWithAll } from 'containers/Inventory/CloudInventory/Components/utils';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { selectInventory } from 'containers/Inventory/CloudInventory/selector';
import { selectSharedState } from 'containers/SharedState/selectors';
import { limitedString } from 'utils/string';

export const RegionAndVPCFilter: FC = () => {
  const { instanceInventory, vpcs, instanceResourceType } = useSelector(
    selectInventory,
  );
  const {
    selectedRegion,
    selectedVpc,
    setSelectedRegion,
    setSelectedVpc,
  } = useInventoryContext();
  const { regions } = useSelector(selectSharedState);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      regionOptions: [],
      vpcOptions: [],
    },
  );

  //set region options
  useEffect(() => {
    updateState({
      regionOptions: getRegionOptionsWithAll(regions.data),
    });
  }, [regions.data]);

  useEffect(() => {
    updateState({
      vpcOptions: [
        {
          label: 'All VPC',
          value: undefined,
        },
        ...map(vpcs.data, o => ({
          label: limitedString(
            !!o.name ? o.name + ' (' + o.vpc_id + ')' : o.vpc_id,
            60,
          ),
          value: o.uuid,
          data: o,
          icon: getIcon(o.native_resource),
        })),
      ],
    });
  }, [vpcs.data]);

  const styles = {
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
    }),
  };

  return (
    <>
      <Box w={44} zIndex={900}>
        <Select
          options={state.regionOptions}
          value={selectedRegion}
          onChange={s => {
            setSelectedRegion(s);
            setSelectedVpc({
              label: 'All VPC',
              value: undefined,
            });
            updateState({ vpcOptions: [] });
          }}
          showIconInValueContainer
          styles={styles}
          isLoading={regions.isLoading}
          isDisabled={instanceInventory.isLoading}
        />
      </Box>
      <Box w={48}>
        <Select
          options={state.vpcOptions}
          value={selectedVpc}
          onChange={setSelectedVpc}
          //isMulti
          //showTotalSelected
          //selectedMessage="VPC selected"
          showIconInValueContainer
          styles={{
            menu: provided => ({
              ...provided,
              width: 'max-content',
              minWidth: '100%',
              right: !!instanceResourceType ? 0 : 'default',
            }),
            placeholder: props => ({ ...props, color: 'black' }),
          }}
          isLoading={vpcs.isLoading}
          placeholder="All VPC"
          menuPortalTarget={document.body}
          isDisabled={instanceInventory.isLoading}
        />
      </Box>
    </>
  );
};
