import React from 'react';

import { Box, Center, HStack } from '@chakra-ui/react';
import { colorMap } from 'theme';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IdeaGlitterIcon } from 'components/Icons';

export const renderHeader = label => (
  <HStack justify="space-between" w="full" align="center">
    <Box>{label}</Box>
    <CustomTooltip label="Click on severity to see alerts" w={24}>
      <Box
        boxSize={6}
        p={1}
        _hover={{ bg: colorMap.primary(100) }}
        color="primary"
        borderRadius={6}
      >
        <Center>
          <IdeaGlitterIcon />
        </Center>
      </Box>
    </CustomTooltip>
  </HStack>
);
