import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PrefixListIcon = props => (
  <Icon viewBox="0 0 512 512" h="full" w="full" color={'inherit'} {...props}>
    <g>
      <path
        d="M488.1,418.7H23.3c-12.6,0.4-22.6,10.6-22.6,23.2c0,12.6,10,22.9,22.6,23.2h0h464.8
		c12.6-0.4,22.6-10.6,22.6-23.2C510.7,429.3,500.6,419.1,488.1,418.7L488.1,418.7z"
      />
      <path
        d="M488.1,325.7H255.7c-12.6,0.4-22.6,10.6-22.6,23.2c0,12.6,10,22.9,22.6,23.2h0h232.4c0.2,0,0.4,0,0.6,0
		c12.8,0,23.3-10.4,23.3-23.3c0-12.8-10.4-23.3-23.3-23.3C488.5,325.7,488.3,325.7,488.1,325.7L488.1,325.7L488.1,325.7z"
      />
      <path
        d="M488.1,232.8H255.7c-12.6,0.4-22.6,10.6-22.6,23.2s10,22.9,22.6,23.2h0h232.4c0.2,0,0.4,0,0.6,0
		c12.8,0,23.3-10.4,23.3-23.3c0-12.8-10.4-23.3-23.3-23.3C488.5,232.8,488.3,232.8,488.1,232.8L488.1,232.8L488.1,232.8z"
      />
      <path
        d="M23.3,93.3h464.8c0.2,0,0.4,0,0.6,0c12.8,0,23.3-10.4,23.3-23.3s-10.4-23.3-23.3-23.3c-0.2,0-0.5,0-0.7,0h0
		H23.3C10.7,47.2,0.7,57.5,0.7,70.1S10.7,92.9,23.3,93.3C23.2,93.3,23.3,93.3,23.3,93.3z"
      />
      <path
        d="M488.1,139.8H255.7c-0.2,0-0.4,0-0.6,0c-12.8,0-23.3,10.4-23.3,23.3s10.4,23.3,23.3,23.3c0.2,0,0.5,0,0.7,0h0
		h232.4c0.2,0,0.4,0,0.6,0c12.8,0,23.3-10.4,23.3-23.3s-10.4-23.3-23.3-23.3C488.5,139.8,488.3,139.8,488.1,139.8L488.1,139.8
		L488.1,139.8z"
      />
      <path
        d="M14.3,370.5c2.6,1.1,5.7,1.8,8.9,1.8c6.4,0,12.2-2.6,16.4-6.8l93-93c4.2-4.2,6.8-10,6.8-16.4
		c0-6.4-2.6-12.2-6.8-16.4l-93-93c-4.2-4.2-10-6.8-16.4-6.8C10.4,139.8,0,150.2,0,163V349v0c0,9.6,5.9,17.9,14.2,21.4L14.3,370.5z"
      />
    </g>
  </Icon>
);
