import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  AttachedVMs,
  ClusterSecurityGroups,
  EndpointPublicURL,
  InterfaceSecurityGroups,
  IPFamily,
  PrivateAccessEnabled,
  PublicAccessCIDR,
  PublicAccessEnabled,
  RoleARN,
  ServiceIPv4CIDR,
  SubnetIDs,
} from '../../../Components/MetadataField/MetaGridFields';

export interface Props {}

export const KubernetesMetadata: FC<Props> = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" />
      {EndpointPublicURL(resource)}
      {PublicAccessEnabled(resource)}
      {PrivateAccessEnabled(resource)}
      {PublicAccessCIDR(resource)}
      {RoleARN(resource)}
      {SubnetIDs(resource)}
      {InterfaceSecurityGroups(resource)}
      {ClusterSecurityGroups(resource)}
      {IPFamily(resource)}
      {ServiceIPv4CIDR(resource)}
      {AttachedVMs(insight)}
    </Grid>
  );
};
