import { Box, BoxProps, Center, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { SecurityGraphContentControls } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphContentControls';
import { useCallSearchApi } from 'containers/Visibility/SecurityGraphNext/hooks/useCallSearchApi';
import { useCallResourceAlertsApi } from 'containers/Visibility/SecurityGraphNext/hooks/useResourceAlerts';
import { useCallRiskContextApi } from 'containers/Visibility/SecurityGraphNext/hooks/useRiskContext';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSecurityGraphContext } from 'containers/Visibility/SecurityGraphNext/hooks/useSecurityGraphContext';
import { useSearchParams } from 'hooks/useSearchParams';

interface SecurityGraphContentProps extends BoxProps {}

// graph type and search bar
export const SecurityGraphContent = (props: SecurityGraphContentProps) => {
  const { children } = props;
  const { isLoadingInfo } = useSecurityGraphContext();
  const { searchResult } = useSearchResult();
  const { nodes } = useSecurityGraphContext();
  const params = useSearchParams();

  return (
    <Stack h={'full'} spacing={4}>
      {!params.alert_id && !params.investigate && (
        <SecurityGraphContentControls />
      )}
      <Box flex={1} pos={'relative'} borderRadius={4} overflow={'hidden'}>
        {children}
        {searchResult.isLoading && (
          <Box pos={'absolute'} bottom={0} right={0}>
            <CustomSpinner loading={isLoadingInfo} size="lg" />
          </Box>
        )}
        {searchResult.isSuccess && isEmpty(nodes) && (
          <Box pos={'absolute'} w={'full'} h={'full'} left={0} top={0}>
            <Center color={'#aaa'} h={'full'}>
              No data found
            </Center>
          </Box>
        )}
      </Box>
    </Stack>
  );
};
