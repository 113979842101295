import React from 'react';

import { Clients, Severity } from '@ariksa/notification';
import { Box, Center, Circle, HStack, Stack, Tooltip } from '@chakra-ui/react';
import { includes, map } from 'lodash';

import { isCellEmpty } from 'components/DataDisplay/NewTable/Utils';
import { tooltipProps } from 'components/DataEntry';
import { getIcon } from 'components/Icons/Components';
import { IconType } from 'components/Icons/types';
import { toTitleCase } from 'utils/string';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function activeAssetsAlertUrl(resource_uuid, resource_type) {
  return `/alerts/active/assets?entity_uuid=${resource_uuid}&resource_type=${resource_type}`;
}

export function alertBySeverityUrl(resource_uuid, resource_type, severity) {
  return `/alerts/active/${severity.toLowerCase()}?entity_uuid=${resource_uuid}&resource_type=${resource_type}`;
}

export const addAlertIdToResponse = alert_id => (res, err) => ({
  alert_id,
  res,
  err,
});

export const renderSeverityDots = ({ value }) => {
  return (
    <Center>
      <Stack spacing={0} fontSize={'xs'}>
        {map(
          value,
          (v, k) =>
            k !== 'INFORMATIONAL' && (
              <HStack>
                <Circle size={2} bg={k.toLowerCase()} />
                <Box>[ {v} ]</Box>
              </HStack>
            ),
        )}
      </Stack>
    </Center>
  );
};

export const renderAlertCategories = ({ value, row }) => {
  if (isCellEmpty(value)) return '-';

  const minCount = 2;

  const renderMoreTooltip = () => {
    if (value?.length > minCount) {
      const cats = value.slice(minCount).map(v => <Box>{toTitleCase(v)}</Box>);
      return (
        <Tooltip {...tooltipProps} label={<Stack>{cats}</Stack>}>
          <Box textDecoration={'underline'} fontSize={'xs'} color={'primary'}>
            {value.length - minCount} more
          </Box>
        </Tooltip>
      );
    }

    return '';
  };

  return (
    <Box>
      <Box>
        {value?.slice(0, minCount).map(v => (
          <Box mr={2}>{toTitleCase(v)}</Box>
        ))}
      </Box>

      <Box>{value?.length > minCount && renderMoreTooltip()}</Box>
    </Box>
  );
};

export function isNotificationClient(client: Clients) {
  return includes(
    [Clients.Slack, Clients.Teams, Clients.Email, Clients.Sns],
    client,
  );
}

export const SeverityList = [
  Severity.Critical,
  Severity.High,
  Severity.Medium,
  Severity.Low,
];
export const SeverityListOptions = SeverityList.map(a => ({
  label: toTitleCase(a),
  value: a,
}));

export const alertStatusOptions = [
  { label: 'Open', value: 'Open' },
  { label: 'Snoozed', value: 'Snoozed' },
  { label: 'Resolved', value: 'Resolved' },
];

export const renderIcon = (type: IconType, color?: string) => (
  <Box w={4} color={color}>
    {getIcon(type)}
  </Box>
);
