import { createContext, useContext } from 'react';

export interface DataDashboardContextProps {
  time: Record<string, any>;
  regionOptions: Record<string, any>[];
  accountOptions: Record<string, any>[];
  sourceTypeOptions: Record<string, any>[];
  selectedSourceType: Record<string, any>;
  selectedAccount: Record<string, any>;
  selectedRegion: Record<string, any>;
  labelOptions: Record<string, any>[];
  cloudOptions: Record<string, any>[];
  selectedCloud: Record<string, any>;
  selectedLabel: Record<string, any>;
  selectedSensitivity: Record<string, any>;
  sensitivityOptions: Record<string, any>[];
  updateValue(field, value);
  getDashboardData();
  getDataFootprint();
  getTableData();
  getDataTypeSources(dataType);
}

export const DataDashboardContext = createContext<DataDashboardContextProps>({
  time: {},
  regionOptions: [],
  accountOptions: [],
  sourceTypeOptions: [],
  selectedSourceType: {},
  selectedAccount: {},
  selectedRegion: {},
  labelOptions: [],
  cloudOptions: [],
  selectedCloud: {},
  selectedLabel: {},
  selectedSensitivity: {},
  sensitivityOptions: [],
  updateValue: () => {},
  getDashboardData: () => {},
  getDataFootprint: () => {},
  getTableData: () => {},
  getDataTypeSources: () => {},
});

export const DataDashboardContextProvider = DataDashboardContext.Provider;

export const useDataDashboardContext = () => useContext(DataDashboardContext);
