import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ActivityIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M21.5,11.5h-4.4101562c-0.2102051,0.000061-0.3978882,0.1315918-0.4697266,
      0.3291016l-2.0712891,5.7080078L9.9199219,4.8291016C9.8479614,4.6316528,
      9.6603394,4.5001831,9.4501953,4.5c-0.210144,0.0001831-0.3977661,0.1316528-0.4697266,
      0.3291016L6.5498047,11.5H2.5C2.223877,11.5,2,11.723877,2,12s0.223877,0.5,0.5,
      0.5h4.3999023c0.210144-0.0001831,0.3977661-0.1316528,
      0.4697266-0.3290405l2.0805664-5.710022l4.6298828,12.7099609c0.0720825,
      0.1973267,0.2596436,0.3287964,0.4697266,0.3291016c0.2102661,0.0002441,
      0.3981323-0.1314087,0.4697266-0.3291016L17.4404297,12.5H21.5c0.276123,
      0,0.5-0.223877,0.5-0.5S21.776123,11.5,21.5,11.5z"
    />
  </Icon>
);
