import React, { FC, useMemo } from 'react';

import {
  Tag,
  TagCloseButton,
  TagLabel,
  Center,
  TagRightIcon,
} from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { defaultStyles, tagStylesConsolas } from './styles';
import { ITagProps } from './types';

export const CustomTag: FC<ITagProps> = props => {
  const {
    label,
    value,
    leftIcon,
    rightIcon,
    closeButton = false,
    onClose,
    custom,
    styles,
    overwriteDefaultStyles,
    useDeveloperFont = false,
    ...rest
  } = props;
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [overwriteDefaultStyles, styles]);

  return (
    <Tag
      {...baseStyles?.tag}
      {...(useDeveloperFont ? tagStylesConsolas.tag : {})}
      {...rest}
    >
      {leftIcon && (
        <Center {...(baseStyles?.leftIcon as any)}>{leftIcon}</Center>
      )}
      {custom}
      {label && (
        <TagLabel {...baseStyles?.label}>
          <Center>{label}</Center>
        </TagLabel>
      )}
      {rightIcon && (
        <TagRightIcon {...(baseStyles?.rightIcon as any)}>
          {rightIcon}
        </TagRightIcon>
      )}
      {closeButton && (
        <TagCloseButton
          {...baseStyles?.closeButton}
          onClick={e => {
            e.stopPropagation();
            onClose?.(value);
          }}
        />
      )}
    </Tag>
  );
};
