import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DropboxIcon = props => (
  <Icon viewBox="0 0 42.4 39.5" color="#007ee5" h="full" w="full" {...props}>
    <path
      fill="#0062ff"
      d="M10.6 1.7L0 8.5l10.6 6.7 10.6-6.7zm21.2 0L21.2 8.5l10.6 6.7 10.6-6.7zM0 22l10.6 6.8L21.2 22l-10.6-6.8zm31.8-6.8L21.2 22l10.6 6.8L42.4 22zM10.6 31l10.6 6.8L31.8 31l-10.6-6.7z"
    />
  </Icon>
);
