import React, { FC, useCallback, useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import {
  differenceBy,
  each,
  filter,
  flatten,
  includes,
  map,
  toArray,
  unionBy,
} from 'lodash';
import { useSelector } from 'react-redux';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  cloudProviderOptions,
  getProviderName,
} from 'components/DataDisplay/Utils/renderCloudIcons';
import { Form } from 'components/DataEntry/Form';
import { getIcon } from 'components/Icons/Components';
import { useCloudAccounts } from 'containers/App/hooks/useCloudAccounts';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectEnvironment } from 'containers/Setup/Environment/selectors';

import { useEnvironmentContext } from '../../../context';

export const EnvironmentDetails: FC = () => {
  const { handleSubmit, onCancel } = useEnvironmentContext();
  const { providerAccountMapping } = useCloudAccounts();
  const { accountMapping } = useCloudAccountId();
  const { formData, environment } = useSelector(selectEnvironment);

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      accountOptions: [],
      providers: cloudProviderOptions,
      selectedAccounts: formData.account_ids,
      name: formData.name,
    },
  );

  const getAccountOptions = useCallback(
    (accounts, provider) =>
      map(accounts, a => ({
        label: a.name + ' (Account ID: ' + a.cloud_account_id + ')',
        value: a.uuid,
        data: a,
        icon: getIcon(provider),
        provider: provider,
      })),
    [],
  );

  //set grouped account options based on provider
  useEffect(() => {
    updateState({
      accountOptions: map(providerAccountMapping, (o, key) => ({
        label: (
          <CustomTooltip label={'Click to select all accounts'}>
            {getProviderName(key)}
          </CustomTooltip>
        ),
        options: getAccountOptions(o, key),
      })),
    });
  }, [providerAccountMapping, getAccountOptions]);

  useEffect(() => {
    let options = new Set();
    each(toArray(formData.account_ids), o =>
      options.add(accountMapping?.[o as string]?.cloud_type),
    );
    updateState({
      providers: filter(cloudProviderOptions, o => options?.has(o.value)),
    });
  }, [accountMapping, formData, state.providerOptions]);

  useEffect(() => {
    updateState({
      selectedAccounts: filter(
        flatten(map(state.accountOptions, o => o.options)),
        a => includes(formData.account_ids, a?.value),
      ),
    });
  }, [state.accountOptions, formData.account_ids]);

  return (
    <Box pt={4}>
      <Form
        isLoading={environment.isLoading}
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            placeholder: 'Environment name',
            isRequired: true,
            value: state.name,
            onChange: value => updateState({ name: value }),
            defaultValue: formData.name,
          },
          cloud: {
            type: 'react-select',
            label: 'Providers',
            isRequired: true,
            placeholder: 'Choose a cloud provider',
            options: cloudProviderOptions,
            value: state.providers,
            onChange: s => {
              let newProvider = differenceBy(s, state.providers, 'value')?.[0];
              let accounts = state.selectedAccounts;
              if (!!newProvider)
                accounts = [
                  ...accounts,
                  ...getAccountOptions(
                    providerAccountMapping[newProvider?.value],
                    newProvider.value,
                  ),
                ];
              else {
                newProvider = differenceBy(state.providers, s, 'value')?.[0];
                if (!!newProvider)
                  accounts = filter(
                    state.selectedAccounts,
                    o => o.provider !== newProvider?.value,
                  );
              }
              updateState({
                providers: s,
                selectedAccounts: accounts,
              });
            },
            isMulti: true,
          },
          account_ids: {
            type: 'react-select',
            label: 'Accounts',
            isRequired: true,
            placeholder: 'Select accounts...',
            options: state.accountOptions,
            value: state.selectedAccounts,
            isMulti: true,
            onChange: s => {
              updateState({ selectedAccounts: s });
            },
            onClickGroupHeading: options =>
              updateState({
                selectedAccounts: unionBy(
                  state.selectedAccounts,
                  options,
                  'value',
                ),
              }),
            showTotalSelected: true,
            showSelectedAtBottom: true,
          },
          is_business_critical: {
            type: 'checkbox',
            checkboxes: [
              {
                name: 'is_business_critical',
                label: (
                  <Box fontSize="sm">
                    This is a business-critical environment, prioritize findings
                    for this
                  </Box>
                ),
                defaultChecked: formData.is_business_critical,
              },
            ],
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Next',
            isDisabled: !state.selectedAccounts?.length || !state.name,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: onCancel,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
