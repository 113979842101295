import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UUID } from 'api/types';

import { actions } from '../slice';

import { WhitelistWizard } from './WhitelistWizard';

interface Props {}

export const EditWhitelist: FC<Props> = () => {
  const params = useParams<UUID>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.uuid) {
      dispatch(actions.getAllowListByID({ q: { allowlistId: params.uuid } }));
    }
  }, [params, dispatch]);

  return <WhitelistWizard actionType="Update" />;
};
