import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colorMap } from 'theme';
import { colors } from 'theme/theme2/colors';

import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { resourceIcons } from '../../../Icons/SvgResourcesIcon/icon-map';

import { ACCOUNT_NODE } from './nodes_types';

export function registerAccountNode() {
  G6.registerNode(
    ACCOUNT_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 200,
              height: 45,
              fill: colors.white,
              stroke: colorMap.primary(200),
              lineWidth: 0.5,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        let img = resourceIcons.account;

        group.addShape('image', {
          attrs: {
            x: x + 10,
            y: y + 13,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        group.addShape('text', {
          attrs: {
            x: width / 2,
            y: height / 2 - 5,
            textAlign: 'center',
            textBaseline: 'start',
            text: 'Account',
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: colors.gray['200'],
            cursor: 'pointer',
          },
          name: 'id',
        });

        group.addShape('text', {
          attrs: {
            x: width / 2,
            y: height / 2 + 18,
            textAlign: 'center',
            // textBaseline: 'end',
            // @ts-ignore
            text: cfg?.data?.id ?? cfg?.data?.resource_id ?? '-', // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 15,
            // // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'label',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
