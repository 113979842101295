import React from 'react';

import { Icon } from '@chakra-ui/react';

export const APIGatewayIcon = props => (
  <Icon viewBox="0 0 431.2 431.2" w="full" h="full" color="inherit" {...props}>
    <path
      d="M423.2,98.2l-42.8-40c-4.4-4.4-10.4-6.8-16.4-6.8H210.8v-32c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8v96h-128
			c-6,0-12,2.4-16.4,6.4l-42.8,40C2.8,166.6,0,173,0,179.8c0,6.4,2.8,12.8,7.6,16.8l42.8,40c4.4,4.8,10.4,7.2,16.4,7.2h128v160H148
			c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h112c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8h-49.2v-224H364c6,0,12-2.4,16.4-6.4l42.8-40
			c4.8-4.4,7.6-10.4,8-17.2C430.8,109.4,428,103,423.2,98.2z M194.8,227.8h-128c-2,0-4-0.8-5.2-2.4l-42.8-40
			c-1.6-1.2-2.4-3.2-2.4-5.2c0-2.4,0.8-4.4,2.4-6l42.8-40c1.2-1.6,3.2-2.4,5.2-2.4h128V227.8z M412,121.4l-42.8,40
			c-1.2,1.2-3.2,2-5.2,2H210.8V67.8H364c2,0,4,0.8,5.2,2.4l42.8,40c1.6,1.6,2.8,3.6,2.4,6C414.8,118.2,414,120.2,412,121.4z"
    />
  </Icon>
);
