import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ContextIcon = props => (
  <Icon
    viewBox="0 0 281.488 281.488"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <g>
      <path d="M140.744,0C63.138,0,0,63.138,0,140.744s63.138,140.744,140.744,140.744s140.744-63.138,140.744-140.744   S218.351,0,140.744,0z M140.744,263.488C73.063,263.488,18,208.426,18,140.744S73.063,18,140.744,18   s122.744,55.063,122.744,122.744S208.425,263.488,140.744,263.488z" />
      <path d="M163.374,181.765l-16.824,9.849v-71.791c0-3.143-1.64-6.058-4.325-7.69c-2.686-1.632-6.027-1.747-8.818-0.299   l-23.981,12.436c-4.413,2.288-6.135,7.72-3.847,12.132c2.289,4.413,7.72,6.136,12.133,3.847l10.838-5.62v72.684   c0,3.225,1.726,6.203,4.523,7.808c1.387,0.795,2.932,1.192,4.477,1.192c1.572,0,3.143-0.411,4.546-1.232l30.371-17.778   c4.29-2.512,5.732-8.024,3.221-12.314S167.663,179.255,163.374,181.765z" />
      <circle cx="137.549" cy="86.612" r="12.435" />
    </g>
  </Icon>
);
