import React, { FC, useCallback, useEffect, useState } from 'react';

import { AlertConditions, Clients, Entities } from '@ariksa/notification';
import { AlertWorkflowClients } from '@ariksa/notification/api';
import { forEach, map, isArray, isEmpty } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, getEnvironmentAccountIdsArray } from 'components/DataDisplay';
import { Steps } from 'components/Navigation/Steps';
import { alertWorkflowSaga } from 'containers/PolicyHub/AlertWorkflow/saga';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { integrationsSaga } from 'containers/Setup/Integrations/saga';
import * as integrationsSlice from 'containers/Setup/Integrations/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

import { actions, reducer, sliceKey } from '../../slice';

import { AlertWorkflowConditions } from './AlertWorkflowConditions';
import { AlertWorkflowNotificationInterval } from './AlertWorkflowNotificationInterval';
import { AlertWorkflowSettings } from './AlertWorkflowSettings';
import { AlertWorkflowWizardContextProvider } from './AlertWorkflowWizardContext';

interface Props {
  actionType?: 'Create' | 'Update';
}

export const AlertWorkFlowWizard: FC<Props> = props => {
  const { actionType = 'Create' } = props;
  useInjector(sliceKey, reducer, alertWorkflowSaga);
  useInjector(
    integrationsSlice.sliceKey,
    integrationsSlice.reducer,
    integrationsSaga,
  );

  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    formData,
    workflow,
    alertConditions,
    tagKeyValues,
    jiraClients,
  } = useSelector(selectAlertWorkflow);
  const [tagKeyOptions, setTagKeyOptions] = useState<Record<string, any>[]>([]);
  const { tagKeys } = useSelector(selectSharedState);

  useEffect(() => {
    setTagKeyOptions(map(tagKeys.data, o => ({ label: o, value: o })));
  }, [tagKeys.data]);

  const updateFormData = useCallback(
    (field, value) => {
      let payload = {};
      if (field === 'account_ids') {
        payload = getEnvironmentAccountIdsArray(value);
      } else
        payload = isArray(value)
          ? map(value, o => o.value)
          : value?.value || value;
      dispatch(
        actions.updateAlertWorkflowData({
          field,
          value: payload,
        }),
      );
    },
    [dispatch],
  );

  const onSubmitData = useCallback(
    data => {
      forEach(data, (value, field) => updateFormData(field, value));
    },
    [updateFormData],
  );

  const onCancel = useCallback(() => {
    navigate('/policy-hub/workflow');
    dispatch(actions.getAlertWorkflows({}));
  }, [navigate, dispatch]);

  const handleSubmit = useCallback(
    data => {
      if (currentStep === 0) {
        setCurrentStep(currentStep + 1);
        onSubmitData(data);
      } else if (currentStep === 1) {
        setCurrentStep(currentStep + 1);
      } else if (currentStep === 2) {
        //onSubmitData(data);
        const conditions: AlertConditions[] = [];
        forEach(alertConditions, (o, key) => {
          if (key === Entities.Tags) {
            forEach(tagKeyValues, t => conditions.push(t));
          } else conditions.push(o);
        });

        //filter out jira clients
        // @ts-ignore
        const clients: AlertWorkflowClients[] = isEmpty(jiraClients)
          ? formData.clients
          : filter(formData.clients, o => o.client_name !== Clients.Jira);

        //set jira clients
        const jira_clients = isEmpty(jiraClients)
          ? []
          : [
              {
                client_name: Clients.Jira,
                client_list: map(jiraClients, (o, key) => ({
                  name: key,
                  client_config: o,
                })),
              },
            ];
        const payload = {
          ...formData,
          alert_conditions: conditions,
          clients: [...clients, ...jira_clients],
        };

        if (actionType === 'Create')
          dispatch(
            actions.createAlertWorkflow({
              q: { alertWorkflowRequest: payload },
              onSuccess: () => onCancel(),
            }),
          );
        else
          dispatch(
            actions.updateAlertWorkflow({
              q: {
                editAlertWorkflow: payload,
                workflowId: workflow.data.uuid,
              },
              onSuccess: () => onCancel(),
            }),
          );
      }
    },
    [
      currentStep,
      dispatch,
      onSubmitData,
      onCancel,
      actionType,
      formData,
      workflow.data,
      alertConditions,
      tagKeyValues,
      jiraClients,
    ],
  );

  const handleBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const createAlertWorkflowContextProps = {
    handleBack,
    updateFormData,
    handleSubmit,
    isEdit: false,
    onCancel,
    actionType,
    tagKeyOptions,
  };

  const stepsOptions = [
    {
      component: () => <AlertWorkflowSettings />,
      label: 'Settings',
    },
    {
      component: () => <AlertWorkflowConditions />,
      label: 'Criteria',
    },
    {
      component: () => <AlertWorkflowNotificationInterval />,
      label: 'Interval',
    },
  ];

  return (
    <AlertWorkflowWizardContextProvider value={createAlertWorkflowContextProps}>
      <Card
        contentComponent={<Steps current={currentStep} steps={stepsOptions} />}
      />
    </AlertWorkflowWizardContextProvider>
  );
};
