import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NetworkInterfaceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M151.3,96c8,0,14.5-6.5,14.5-14.5s-6.5-14.5-14.5-14.5c-8,0-14.5,6.5-14.5,14.5S143.2,96,151.3,96z
	 M151.3,206.5c-8,0-14.5,6.5-14.5,14.5s6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5S159.3,206.5,151.3,206.5z M477.1,442.2H313
	c-4.6-22.9-22.7-40.8-45.6-45.4c0-0.4,0.2-0.7,0.2-1.1v-93.1H384c32.1,0,58.2-26.1,58.2-58.2v-46.5c0-19.1-9.4-35.9-23.6-46.5
	c14.2-10.6,23.6-27.4,23.6-46.5V58.2C442.2,26.1,416.1,0,384,0H128C95.9,0,69.8,26.1,69.8,58.2v46.5c0,19.1,9.4,35.9,23.6,46.5
	c-14.2,10.6-23.6,27.4-23.6,46.5v46.5c0,32.1,26.1,58.2,58.2,58.2h116.4v93.1c0,0,0,0,0,0c0,0.4,0.2,0.7,0.2,1.1
	c-22.9,4.6-41,22.5-45.6,45.4H34.9c-6.4,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6H199c5.4,26.6,28.9,46.5,57,46.5
	c28.1,0,51.6-20,57-46.5h164.1c6.4,0,11.6-5.2,11.6-11.6C488.7,447.4,483.5,442.2,477.1,442.2z M93.1,104.7V58.2
	c0-19.3,15.6-34.9,34.9-34.9h256c19.3,0,34.9,15.6,34.9,34.9v46.5c0,19.3-15.6,34.9-34.9,34.9H128C108.7,139.6,93.1,124,93.1,104.7z
	 M128,279.3c-19.3,0-34.9-15.6-34.9-34.9v-46.5c0-19.3,15.6-34.9,34.9-34.9h256c19.3,0,34.9,15.6,34.9,34.9v46.5
	c0,19.3-15.6,34.9-34.9,34.9H128z M256,488.7c-19.3,0-34.9-15.6-34.9-34.9c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9
	C290.9,473.1,275.3,488.7,256,488.7z"
    />
  </Icon>
);
