import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UUID } from 'api/types';

import { actions } from '../slice';

import { AlertWorkFlowWizard } from './AlertWorkflowWizard';

export const EditAlertWorkFlow: FC = () => {
  const params = useParams<UUID>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.uuid) {
      dispatch(actions.getAlertWorkflow({ q: { workflowId: params?.uuid } }));
    }
  }, [params, dispatch]);

  return <AlertWorkFlowWizard actionType="Update" />;
};
