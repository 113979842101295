import React from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { forEach, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ExclamationTriangleIcon, MonitoringIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderSmallFindingCard } from 'containers/Dashboard/utils/utils';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const CriticalAndHighSeverityFindingsByCategory: React.FC = () => {
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();

  const getTotal = items => {
    let total = 0;
    forEach(items, s => {
      total += (s.severity.CRITICAL ?? 0) + (s.severity.HIGH ?? 0);
    });
    return total;
  };

  const onRedirect = tab =>
    navigate('/findings?tab=' + tab + '&severity=CRITICAL,HIGH&redirect=true');

  const categories = {
    [AlertCategoryClass.DataSecurity]: {
      count: getTotal(openFindings.data?.[AlertCategoryClass.DataSecurity]),
      isLoading: openFindings.isLoading,
      color: '#fcf1d3',
      borderColor: '#efb944',
      onClick: () => onRedirect(AlertCategoryClass.DataSecurity),
    },
    [AlertCategoryClass.IdentityAuthorization]: {
      count: getTotal(
        openFindings.data?.[AlertCategoryClass.IdentityAuthorization],
      ),
      isLoading: openFindings.isLoading,
      color: '#d3e0fc',
      borderColor: '#4867ab',
      onClick: () => onRedirect(AlertCategoryClass.IdentityAuthorization),
    },
    [AlertCategoryClass.CloudPosture]: {
      count: getTotal(openFindings.data?.[AlertCategoryClass.CloudPosture]),
      isLoading: openFindings.isLoading,
      color: '#d2edfd',
      borderColor: '#5ea5d7',
      onClick: () => onRedirect(AlertCategoryClass.CloudPosture),
    },
    Vulnerabilities: {
      count: getTotal(openFindings.data?.[AlertCategoryClass.Vulnerabilities]),
      isLoading: false,
      color: '#ded9f7',
      borderColor: '#5c57a0',
      onClick: () => onRedirect(AlertCategoryClass.Vulnerabilities),
    },
  };

  return (
    <DashboardOverviewCard
      label="Critical and high severity findings by category"
      icon={<MonitoringIcon />}
      content={
        <Stack spacing={3} w="full" h="full" justify="space-between">
          <HStack w="full" h="full">
            {map(
              [
                AlertCategoryClass.DataSecurity,
                AlertCategoryClass.IdentityAuthorization,
              ],
              o => renderSmallFindingCard(categories, o),
            )}
          </HStack>
          <HStack w="full" h="full">
            {map(
              [
                AlertCategoryClass.CloudPosture,
                AlertCategoryClass.Vulnerabilities,
              ],
              o => renderSmallFindingCard(categories, o),
            )}
          </HStack>
        </Stack>
      }
      iconBgColor="critical"
      tooltip={{
        header: 'Findings, issues and alerts…',
        icon: <ExclamationTriangleIcon />,
        tooltip: (
          <Stack>
            <Box>
              Ariksa delivers prioritized findings based on multi-factor
              analyses to reduce noise and increase your productivity.
            </Box>
            <Box>
              Unique issues are grouped into findings. Each issue indicates an
              area of investigation for a specific resource. Alerts are issues
              that you need your attention for resolution.
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
    />
  );
};
