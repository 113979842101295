/**
 *
 * Top Left Nav Bar Tabs
 *
 */
import React from 'react';

import { Box, Center } from '@chakra-ui/react';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import { useLocation } from 'react-router-dom';

import { FilterComponent } from 'components/FilterView';
import MenuItem from 'components/Navigation/MenuItem';

import { selectedTabStyles, tabStyles } from '../styles';
import { getMainMenuItems } from '../utils';

interface Props {}

export const LeftComponent: React.FC<Props> = props => {
  const current = useLocation().pathname;

  return (
    <>
      {map(getMainMenuItems(), each => {
        let path = each.url;
        const subMenu = localStorage.getItem([each.url] + '-top-nav-bar');
        return (
          <FilterComponent
            filterPath={`${each.url}`}
            key={`${each.url}-${each.key}-topNavBar`}
          >
            <MenuItem
              url={path + (subMenu ? subMenu : '')}
              badge={each?.badge}
              badgeTop="0px"
              badgeLeft={each?.badgeLeft}
              {...tabStyles}
              selected={
                startsWith(current, each.key) ||
                (current === '/' && each.key === '/dashboard')
              }
              selectedStyles={selectedTabStyles}
            >
              <Center h="full" p={0}>
                <Box fontSize="navBarHeader">{each.title}</Box>
              </Center>
            </MenuItem>
          </FilterComponent>
        );
      })}
    </>
  );
};
