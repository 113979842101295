import { IGroup } from '@antv/g-base/lib/interfaces';
import G6, { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

export const PAGINATION_NODE = 'pagination-node';

export function registerPaginationNode() {
  G6.registerNode(
    PAGINATION_NODE,
    {
      setState(name, value, item) {},
      draw(cfg?: ModelConfig, group?: IGroup) {
        const data: any = cfg?.data ?? {};
        const container = group?.addShape('rect', {
          attrs: {
            width: 160,
            height: 45,
            fill: colors.gray[100],
            stroke: colors.gray[200],
            lineWidth: 0.5,
            cursor: 'pointer',
            radius: 8,
          },
        });
        const { x = 0, y = 0, width = 0, height = 0 } =
          container?.getBBox() ?? {};

        const hiddenNodeCount =
          data.totalNodeCount ?? 0 - data.visibleNodeCount ?? 0;
        if (hiddenNodeCount) {
          group?.addShape('text', {
            attrs: {
              x: 10,
              y: height / 2 + 6,
              text: `Load ${Math.min(10, hiddenNodeCount)} more`,
              fontFamily: 'MetroSans',
              fontSize: 12,
              opacity: 1,
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'load-more',
          });
        }

        return container!;
      },
    },
    'rect',
  );
}
