import {
  LabelResponse,
  TagsApiCreateLabelsRequest,
  TagsApiDeleteLabelRequest,
  TagsApiEditLabelRequest,
  TagsApiGetLabelRequest,
  TagsApiGetLabelsRequest,
} from '@ariksa/inventory-core/api';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the whitelist container
export const initialState: ContainerState = {
  contextList: QueryState.init([]),
  createContext: QueryState.init({}),
  context: QueryState.init({} as LabelResponse),
};

const whitelistSlice = createSlice({
  name: 'inventoryContext',
  initialState,
  reducers: {
    getContextList(
      state,
      action: QueryAction<LabelResponse[], TagsApiGetLabelsRequest>,
    ) {
      state.contextList = QueryState.next(state.contextList, action);
    },

    getContextById(
      state,
      action: QueryAction<LabelResponse, TagsApiGetLabelRequest>,
    ) {
      state.context = QueryState.next(state.context, action);
    },

    createContext(state, action: QueryAction<any, TagsApiCreateLabelsRequest>) {
      state.createContext = QueryState.next(state.createContext, action);
    },
    deleteContext(state, action: QueryAction<any, TagsApiDeleteLabelRequest>) {
      state.createContext = QueryState.next(state.createContext, action);
    },
    updateContext(state, action: QueryAction<any, TagsApiEditLabelRequest>) {
      state.createContext = QueryState.next(state.createContext, action);
    },

    resetFormData(state) {
      state.context = initialState.context;
    },
  },
});

export const { actions, reducer, name: sliceKey } = whitelistSlice;
