import React, { useEffect, useMemo, useState } from 'react';

import { SearchApiAiSuggestRequest } from '@ariksa/inventory-core/api';
import { Box, Center, HStack, Spinner, Stack } from '@chakra-ui/react';
import { throttle } from 'lodash';

import { InventoryService } from 'api/services';
import { getIcon } from 'components/Icons/Components';

interface AiSearchSuggestionsProps {
  searchTerm: string;
  onSelect?: (term: string) => void;
}

export const AiSearchSuggestions = (props: AiSearchSuggestionsProps) => {
  const { searchTerm, onSelect } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<
    Record<string, any>[]
  >([]);

  const getAriksaAISuggestions = (query: string) => {
    const aiPayload: SearchApiAiSuggestRequest = {
      aISuggestQuery: {
        query,
        suggestion_count: 5,
      },
    };

    return InventoryService.Search.aiSuggest(aiPayload);
  };

  const getSearchQuerySuggestion = useMemo(() => {
    return throttle((term: string) => {
      const query = term.trim();

      setIsLoading(true);
      getAriksaAISuggestions(query)
        .then(res => {
          const searchTermOptions =
            res.data.search_terms?.map((term: any) => {
              return term;
            }) ?? [];

          setSearchSuggestions(searchTermOptions);
          console.log(searchTermOptions);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }, 2000);
  }, []);

  useEffect(() => {
    if (!searchTerm.trim()) return;
    getSearchQuerySuggestion(searchTerm);
  }, [getSearchQuerySuggestion, searchTerm]);

  return (
    <>
      {(searchSuggestions.length > 0 || isLoading) && (
        <Stack
          pos={'absolute'}
          top={'100%'}
          bg={'#fff'}
          px={2}
          py={4}
          w={'full'}
          spacing={1}
          borderRadius={5}
          zIndex={1}
          boxShadow={`0 0 4px #ddd`}
        >
          {isLoading && (
            <Box
              pos={'absolute'}
              w={'full'}
              left={0}
              top={0}
              p={4}
              borderRadius={6}
              boxShadow={`0 0 4px #ddd`}
              bg={'#fff'}
            >
              <Center h={'full'}>
                <Spinner />
              </Center>
            </Box>
          )}
          {!isLoading && (
            <Box>
              {searchSuggestions.map((term, i) => (
                <AiSearchSuggestItem
                  label={term.name ?? ''}
                  native_resource={term.native_resource}
                  agnostic_resource={term.agnostic_resource}
                  key={`${term} /${i}`}
                  onSelect={term => {
                    setSearchSuggestions([]);
                    onSelect?.(term);
                  }}
                />
              ))}
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

interface AiSearchSuggestItemProps {
  label: string;
  onSelect?: (label: string) => void;
  native_resource?: string;
  agnostic_resource?: string;
}

const AiSearchSuggestItem = (props: AiSearchSuggestItemProps) => {
  const { label, onSelect, native_resource, agnostic_resource } = props;

  return (
    <HStack
      p={2}
      borderRadius={4}
      _hover={{ color: 'primary' }}
      cursor={'pointer'}
      onClick={() => onSelect?.(label)}
      spacing={2}
    >
      <Center p={1} w={7} h={7} color="primary">
        {getIcon(native_resource ?? agnostic_resource ?? '')}
      </Center>
      <Box>{label}</Box>
    </HStack>
  );
};
