import { useCallback } from 'react';

import {
  VulnerabilitiesApiGetChartVulnerabilitiesRequest,
  VulnerabilitiesApiGetEolEntitiesDashboardExportRequest,
  VulnerabilityHistoryResponse,
} from '@ariksa/inventory-core/api';
import dayjs from 'dayjs';

import { InventoryService } from 'api/services';
import { saveAsPDF } from 'containers/Visibility/Network/utils';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useInventoryVulnerabilityService() {
  const vulnerabilityHistoricalTrend = useAriksaQuery<
    VulnerabilitiesApiGetChartVulnerabilitiesRequest,
    VulnerabilityHistoryResponse
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.Vulnerability.getChartVulnerabilities(req).then(
        r => r.data,
      ),
  });

  const downloadTechnologyEntities = useCallback(
    (req: VulnerabilitiesApiGetEolEntitiesDashboardExportRequest) => {
      InventoryService.Vulnerability.getEolEntitiesDashboardExport(req).then(
        res => {
          saveAsPDF(
            res.data,
            'EOL-software-' +
              req?.osAndTech +
              '-' +
              req?.version +
              '-' +
              dayjs().utc().format() +
              '.csv',
          );
        },
      );
    },
    [],
  );

  return { vulnerabilityHistoricalTrend, downloadTechnologyEntities };
}
