import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

export const ECSContainers: FC = () => {
  const {
    containerVulnerability,
    resourceInsight,
    resourceType,
    resourceDetails,
  } = useSelector(selectActiveResource);
  const dispatch = useDispatch();

  useEffect(() => {
    let instance_ids: string[] = [];
    if (resourceType === NativeResources.ElasticKubernetesService) {
      instance_ids = map(
        resourceInsight.data?.instances,
        o => o.split(',')?.[0]?.split('instance-id: ')[1],
      );
    } else if (
      resourceType === NativeResources.ElasticContainerService ||
      resourceType === NativeResources.EcsCluster
    )
      instance_ids = map(
        resourceDetails.data?.config?.containerInstances,
        o => o?.ec2InstanceId,
      );
    if (!!instance_ids?.length)
      dispatch(
        actions.getContainerVulnerability({
          q: { getContainersRequest: { instance_ids } },
        }),
      );
  }, [resourceType, resourceInsight.data, dispatch, resourceDetails.data]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">CONTAINER ID</Box>,
      accessor: 'container_id',
      render: ({ value }) => (
        <WithResourceIcon resourceType={NativeResources.Container}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'CONTAINER IMAGE',
      align: 'left',
      accessor: 'image',
    },
    {
      header: 'POD NAME',
      align: 'left',
      accessor: 'pod',
    },
    { header: 'NAMESPACE', align: 'left', accessor: 'namespace' },
  ];

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <MetaAccordionItem
        panel={<Table columns={columns} data={containerVulnerability.data} />}
        iconType={NativeResources.Container}
        label="CONTAINERS"
        totalCount={containerVulnerability.data?.length}
        isLoading={containerVulnerability.isLoading}
      />
    </Accordion>
  );
};
