import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container } from 'pixi.js';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';

import { ABound } from './Bound';
import { ADimension } from './Dimension';
import { Element } from './Element';
import { AGraphNodeId } from './types';

export interface AGraphNodeProps {
  id: AGraphNodeId;
  parentId?: AGraphNodeId;
  nodeType?: string;

  [k: string]: any;
}

export class AGraphNode extends Element {
  id: AGraphNodeId;
  parentId?: AGraphNodeId;
  nodeType: string = 'node';
  data: Record<string, any> = {};

  visible = true;
  showResourceType: boolean | undefined;

  get isResourceNode() {
    return false;
  }

  get isVisible() {
    return this.visible;
  }

  get hasMenu() {
    return true;
  }

  get x() {
    return this.dimension.x;
  }

  get y() {
    return this.dimension.y;
  }

  get w() {
    return this.dimension.w;
  }

  get h() {
    return this.dimension.h;
  }

  set x(x: number) {
    this.dimension.x = x;
  }

  set y(y: number) {
    this.dimension.y = y;
  }

  set w(w: number) {
    this.dimension.w = w;
  }

  set h(h: number) {
    this.dimension.h = h;
  }

  constructor(props: AGraphNodeProps) {
    super();
    const { id, parentId, nodeType, ...rest } = props;
    this.id = id;
    this.parentId = parentId;
    this.nodeType = nodeType ?? 'node';
    this.data = rest;
    this.bound = ABound.default();
  }

  render(g: Graphics, container: Container) {
    throw new Error('Not implemented');
  }

  renderContext(g: Graphics, container: Container) {
    // throw new Error('Not implemented');
  }

  addInteractiveElement(graph: AGraph<AGraphNode, AGraphEdge>, index: number) {
    if (this.data.disabled) return;
    const n = this;
    // add the node hover bound
    graph.addInteractiveElement({
      minX: n.x,
      maxX: n.x + n.w,
      minY: n.y,
      maxY: n.y + n.h,
      minZ: index,
      maxZ: index,
      id: n.id,
      data: n,
    });
  }

  renderHover(g: Graphics) {}

  renderActive(g: Graphics) {}
}
