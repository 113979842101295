import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  IAMGroupAttachedPolicies,
  InlinePolicies,
  Users,
} from '../../../Components/MetadataField/MetaGridFields';

export const IAMGroupMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {Users(insight)}
        {InlinePolicies(resource)}
        {IAMGroupAttachedPolicies(resource)}
      </Grid>
    </Stack>
  );
};
