import React, { useEffect, useState } from 'react';

import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { BarChart } from 'components/Visualization';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
import { selectUser } from 'containers/App/selectors';
import { selectJitRequests } from 'containers/Visibility/JitRequests/selectors';
import { actions } from 'containers/Visibility/JitRequests/slice';
import { formatDate } from 'utils/date';

interface AccountActionsProps {
  accountId: string;
}

export const ResourceActions = (props: AccountActionsProps) => {
  const { accountId } = props;
  const { info } = useSelector(selectUser);
  const { resourceActionOnDay, resourceActionPerHour } = useSelector(
    selectJitRequests,
  );
  const dispatch = useDispatch();
  const [monthlyActionsData, setMonthlyActionsData] = useState<any[]>([]);
  const [average, setAverage] = useState(1);

  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [dataOnDay, setDataOnDay] = useState<any[]>([]);

  useEffect(() => {
    dispatch(
      actions.getResourceActionPerHour({
        q: {
          orgId: info.org_uuid,
          resourceId: accountId,
          day: dayjs('2024-02-16').format(),
        },
      }),
    );
  }, [accountId, dispatch, info.org_uuid]);

  const customTooltipContent = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const { payload: obj } = payload[0] ?? {};

      return (
        <>
          <CustomTooltip w={'150px'}>
            <Stack color={obj?.fill} fontSize={'xs'}>
              {obj.count > average && (
                <>
                  <Text>Spike in activity</Text>
                </>
              )}
              <Text>{obj.count} permissions</Text>
            </Stack>
          </CustomTooltip>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    const actions =
      resourceActionPerHour.data.results?.map(d => {
        return {
          date: dayjs(d.time?.toString()).local().format('HH:mm'),
          fullDate: dayjs(d.time?.toString()).format('YYYY/MM/DD'),
          count: parseInt(d.actions?.toString() ?? '0'),
        };
      }) ?? [];
    const sorted = sortBy(actions, 'fullDate');
    setMonthlyActionsData(sorted);
    if (sorted.length) {
      const sum = sorted.reduce(
        (a, b) => a + parseInt(b.count?.toString() ?? '0'),
        0,
      );
      setAverage(sum / sorted.length);
    }
  }, [resourceActionPerHour.data.results]);

  useEffect(() => {
    dispatch(
      actions.getResourceActionOnDay({
        q: {
          orgId: info.org_uuid,
          resourceId: accountId,
          day: dayjs().format(),
        },
      }),
    );
  }, [accountId, dispatch, info.org_uuid, selectedDay]);

  useEffect(() => {
    const actions =
      resourceActionOnDay.data?.actions?.map((v, k) => {
        return {
          action: v.action,
          permission_type: v.permission_type,
          count: v.count,
          timestamp: v.first?.toString(),
        };
      }) ?? [];
    setDataOnDay(actions);
  }, [resourceActionOnDay.data?.actions]);

  const columns = [
    {
      header: 'Action',
      accessor: 'action',
      render: ({ value }) => {
        return value;
      },
    },
    {
      header: 'Permissions',
      accessor: 'permission_type',
      align: 'left',
    },
    {
      header: 'Count',
      accessor: 'count',
    },
    {
      header: 'Timestamp',
      accessor: 'timestamp',
      render: ({ value }) => {
        return formatDate(value);
      },
    },
  ];

  return (
    <Stack h={'full'} spacing={8}>
      <Box minH={200}>
        <BarChart
          data={monthlyActionsData}
          xField="date"
          yField={['count', 'over']}
          stacked
          legend={false}
          colors={monthlyActionsData.map(d =>
            d.count > average
              ? customTheme.colors.red['500']
              : customTheme.colors.primary,
          )}
          customTooltipContent={customTooltipContent}
          isLoading={resourceActionPerHour.isLoading}
        />
      </Box>

      <Stack flex={1} spacing={10}>
        <Stack flex={1} spacing={4}>
          <HStack>
            <AriksaIcon
              type={IconTypes.Permission}
              size={'sm'}
              bg={'primary'}
            />
            <Text>ACTIONS PERFORMED ON {selectedDay}</Text>
          </HStack>
          <Divider />
          <Table
            columns={columns}
            data={dataOnDay}
            isLoading={resourceActionPerHour.isLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
