import React, { FC, useEffect, useState } from 'react';

import { Box, Input, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import map from 'lodash/map';

import { Tag } from 'components/DataDisplay';
import { commonFieldStyles } from 'components/DataEntry';

import { EmailIDsProps } from './types';

export const EmailIDs: FC<EmailIDsProps> = props => {
  const {
    setErrorMessage,
    value,
    onChange,
    isDisabled,
    placeholder,
    defaultValue,
  } = props;
  const [email, setEmail] = useState('');
  const [emailIds, setEmailIDs] = useState<string[]>([]);

  useEffect(() => {
    if (defaultValue && !isEmpty(defaultValue)) setEmailIDs(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (value && !isEmpty(value)) setEmailIDs(value);
  }, [value]);

  const onRemoveEmail = value => {
    const ids = emailIds.filter(email => email !== value);
    setEmailIDs(ids);
    onChange?.(ids);
  };

  const isEmail = value => /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(value);

  const isValid = email => {
    if (!isEmail(email)) {
      setErrorMessage?.(`${email} is not a valid email address.`);
      return false;
    } else if (emailIds.includes(email)) {
      setErrorMessage?.(`${email} has already been added.`);
      return false;
    }
    return true;
  };

  const handleOnChange = evt => {
    setErrorMessage?.('');
    setEmail(evt.target.value);
  };

  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ',', ' '].includes(evt.key)) {
      evt.preventDefault();
      const value = email.trim();
      if (value && isValid(value)) {
        setEmail('');
        setEmailIDs([...emailIds, value]);
        onChange?.([...emailIds, value]);
      }
    }
  };

  const handlePaste = evt => {
    evt.preventDefault();
    const paste = evt.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);
    if (emails) {
      const toBeAdded = emails.filter(email => !emailIds.includes(email));
      setEmailIDs([...emailIds, ...toBeAdded]);
      onChange?.([...emailIds, ...toBeAdded]);
    }
  };

  return (
    <Stack>
      <Box>
        <Input
          {...commonFieldStyles}
          value={email}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          isDisabled={isDisabled}
          placeholder={placeholder}
        />
      </Box>
      {!!emailIds?.length && (
        <Box>
          {map(emailIds, each => (
            <Tag
              label={each}
              closeButton
              onClose={() => onRemoveEmail(each)}
              styles={{
                tag: {
                  fontSize: 'sm',
                  py: 0,
                  px: 2,
                  mr: 1,
                  my: 1,
                  bg: 'hover',
                  border: '1px solid',
                  borderColor: 'primary',
                },
                closeButton: { borderRadius: 'full', bg: 'white' },
              }}
            />
          ))}
        </Box>
      )}
    </Stack>
  );
};
