import { inlineObjectFieldStyles } from '../../components/DataEntry/Form';

export const form_styles = {
  objectField: {
    objectContainer: {
      ...inlineObjectFieldStyles.objectContainer,
      alignItems: 'flex-start',
    },
    propertyContainer: [{ w: '25%' }, { w: '50%' }],
  },
};

export const permissionsStyles = {
  form: {
    container: { w: 'full', maxW: 'formWidth' },
  },
  objectField: {
    objectContainer: {
      padding: 0,
      spacing: 2,
      border: 'none',
      bg: 'transparent',
    },
  },
};
