import React, { useCallback, useMemo } from 'react';

import { JourneyApiGetFeatureStatusRequest } from '@ariksa/profile/api';
import { Text } from '@chakra-ui/react';
import { forEach } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QueryStatus } from 'services/types';

import { ProfileService } from 'api/services';
import {
  BlueprintViewIcon,
  ClockIcon,
  CloudFormationIcon,
  DataScanIcon,
  EnvironmentIcon,
  OutpostForVulnerabilityIcon,
  PolicyIcon,
  RemediateIcon,
  ShareIcon,
  SSHKeyPairIcon,
  ThirdPartyIntegrationIcon,
  TicketIcon,
  UserIcon,
  WorkflowIcon,
} from 'components/Icons';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

export const useMyTasks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllTasksStatus = useCallback(
    tasks => {
      forEach(tasks, o => {
        if (!o.id) {
          return;
        }
        const payload: JourneyApiGetFeatureStatusRequest = {
          featureId: o.id,
        };
        dispatch(
          sharedStateActions.getTaskStatus({
            q: payload,
            status: QueryStatus.pending,
          }),
        );
        ProfileService.Journey.getFeatureStatus(payload).then(res => {
          dispatch(
            sharedStateActions.getTaskStatus({
              q: payload,
              status: QueryStatus.fulfilled,
              data: res.data,
            }),
          );
        });
      });
    },
    [dispatch],
  );

  const getMyTasks = useCallback(() => {
    dispatch(
      sharedStateActions.getMyTasks({
        q: {},
        onSuccess: r => getAllTasksStatus(r),
      }),
    );
  }, [dispatch, getAllTasksStatus]);

  const myTasksMapping = {
    prioritized_findings: {
      header: 'Review findings, and issues...',
      icon: <BlueprintViewIcon />,
      description: [
        'Ariksa delivers prioritized findings based on multi-factor analyses to reduce noise and increase your productivity.',
        'Findings are unique issues ' +
          'across several resources. ' +
          'Instead of chasing individual ' +
          'alerts, you can now focus on ' +
          'patterns and addressing them ' +
          'holistically.',
      ],
      footerText: 'Review findings',
      onClickFooter: () => navigate('/findings'),
    },
    product_help: {
      header: 'Use quick access to AI and search...',
      icon: <ShareIcon />,
      description: [
        'Our AI and search are ' +
          'immediately accessible using ' +
          'shortcuts. Just press \u2318 + K ' +
          'to access our AI interface.',
        'Have questions regarding ' +
          'Ariksa or want to execute ' +
          'specific actions, just press' +
          ' \u2318 + I.',
      ],
      footerText: 'Ok',
      //onClickFooter: () => navigate('/findings'),
    },
    third_party_integration: {
      header: '3rd party integrations...',
      icon: <ThirdPartyIntegrationIcon />,
      description: [
        'Ariksa provides several 3rd party integrations for ticketing, ' +
          'notifications etc. to seamlessly integrate alerts into your ' +
          'existing security process and workflows.',
        'You can set up alerting workflows to automatically use these ' +
          'integrations to accelerate response',
      ],
      footerText: 'Click to visit',
      onClickFooter: () => navigate('/setup/integrations'),
    },
    outpost_vulnerabilities: {
      header: 'Uncover threats from vulnerabilities...',
      icon: <OutpostForVulnerabilityIcon />,
      description: [
        'Ariksa Outpost for Vulnerability management identifies run-time' +
          ' exploitable vulnerabilities in apps, libraries and OS ' +
          'hosted on VMs, containers.',
        'Ariksa provides deep correlation with other related factors ' +
          'that create attack paths to data.',
      ],
      footerText: 'Deploy Outpost',
      onClickFooter: () => navigate('/setup/outpost/add/vulnerability'),
    },
    outpost_data: {
      header: 'Enable data classification...',
      icon: <DataScanIcon />,
      description: [
        'Ariksa Outpost for Data uses Machine Learning (ML) to automatically ' +
          'classify data and provides deeper data insights and contextual ' +
          'risk correlation to your sensitive data.',
        'Outpost for Data is deployed as a virtual machine in a VPC of your ' +
          'choice to scan all reachable data sources',
      ],
      footerText: 'Deploy Outpost',
      onClickFooter: () => navigate('/setup/outpost/add/data'),
    },
    least_privilege_management: {
      header: 'Enforce least privilege...',
      icon: <UserIcon />,
      description: [
        'Ariksa helps you enforce least' +
          'privilege management for' +
          'humans and non-human' +
          'identities to data.',
        'Use AI search or alert-based' +
          'evidence to visually inspect' +
          'and right-size permissions.' +
          'Ariksa also identifies advanced' +
          'TTPs such as privilege' +
          'escalation',
      ],
      footerText: 'Learn more',
      //onClickFooter: () => navigate('/setup/outpost/add/data'),
    },
    jit_access: {
      header: 'Enforce Just-in-Time permissions...',
      icon: <ClockIcon />,
      description: [
        'Just In Time (JIT) access is a policy-based framework to assign ' +
          'dynamic, granular privileges to users on-demand. These privileges ' +
          'are typically assigned through roles for a limited period.',
        'You can enable JIT access individually for every account',
      ],
      footerText: 'Setup JIT',
      onClickFooter: () => navigate('/setup/jit'),
    },
    context_labels: {
      header: 'Enhance context with labels...',
      icon: <TicketIcon />,
      description: [
        'Ariksa labels provide a way to store organizational context such as ' +
          'resource ownership, document classification, data classification,' +
          ' approved software etc.',
        'Create labels and use custom policies to uncover powerful insights ' +
          'unique to your cloud.',
      ],
      footerText: 'Create label',
      onClickFooter: () => navigate('/inventory/context/add'),
    },
    ai_insights: {
      header: 'Intent-based search (AI)…',
      //icon: <TicketIcon />,
      description: [
        <Text>
          Ariksa provides AI-based observability. Visit{' '}
          <Text color="primary">Visibility {'>>'} Security Graph</Text> and just
          describe what you are looking for in English – no SQL or query
          language
        </Text>,
        'We’ll map your intent to ' +
          'existing policies and generate ' +
          'visual graphs with embedded ' +
          'context for deep observability.',
      ],
      footerText: 'Use AI',
      onClickFooter: () => navigate('/visibility/security-graph'),
    },
    custom_policies: {
      header: 'Create custom policies...',
      icon: <PolicyIcon />,
      description: [
        'Ariksa provides a policy framework to create custom policies to ' +
          'generate unique insights for your cloud.',
        'You can use attributes, context, relationships and activity for ' +
          'these policies to get deep visibility into complex scenarios.',
      ],
      footerText: 'Create custom policy',
      onClickFooter: () => navigate('/policy-hub/policy/add'),
    },
    alerting_workflow: {
      header: 'Automate alerting response...',
      icon: <WorkflowIcon />,
      description: [
        'Ariksa alert ticketing and notification workflows provide ' +
          'granular control and criteria based routing of issues. ' +
          'Ariksa accelerates your response to issues that need immediate attention.',
        'Our 3rd party integrations make your workflows frictionless',
      ],
      footerText: 'Click to visit',
      onClickFooter: () => navigate('/policy-hub/workflow'),
    },
    custom_environments: {
      header: 'Create custom environment...',
      icon: <EnvironmentIcon />,
      description: [
        'Ariksa environments allow you to logically group resources and users ' +
          'in one or more cloud and accounts. Distinct policy blueprints can be ' +
          'applied for policy enforcement',
        'For example, you can enforce distinct policies for dev, production, ' +
          'staging environments',
      ],
      footerText: 'Create environment',
      onClickFooter: () => navigate('/setup/environment/add'),
    },
    infrastructure_as_code: {
      header: 'Scan your IAC repositories...',
      icon: <CloudFormationIcon />,
      description: [
        'Ariksa can integrate with your favorite repository used to deploy ' +
          'cloud infrastructure',
        'We proactively scan your Terraform files to identify misconfigurations ' +
          'and risky configurations that can fixed before deployment.',
      ],
      footerText: 'Enable IAC scanning',
      onClickFooter: () => navigate('/setup/accounts'),
    },
    code_repositories: {
      header: 'Detect credentials in repos...',
      icon: <SSHKeyPairIcon />,
      description: [
        'Ariksa can scan your code ' +
          'repositories such as GitHub,' +
          'GitLab and BitBucket for ' +
          'exposed credentials and other ' +
          'sensitive data that may ' +
          'inadvertently exposed.',
        'You can choose specific ' +
          'branches in each repos that ' +
          'need to be scanned',
      ],
      footerText: 'Connect repo',
      onClickFooter: () => navigate('/setup/accounts'),
    },
    automated_remediation: {
      header: 'Remediate with no manual effort...',
      icon: <RemediateIcon />,
      description: [
        'In addition to detection and ' +
          'investigation, Ariksa allows ' +
          'you to enable single-click ' +
          'remediation to eliminate ' +
          'manual work.',
        'For common scenarios, Ariksa ' +
          'also enables automated ' +
          'remediation to significantly ' +
          'lower MTTR',
      ],
      footerText: 'Enable remediation',
      onClickFooter: () => navigate('/setup/accounts'),
    },
    custom_data_classifiers: {
      header: 'Create custom data classifiers...',
      icon: <DataScanIcon />,
      description: [
        'While Ariksa provides a wide range of built-in classifiers, ' +
          'you can create custom classifiers for data',
        'Sensitive data from custom classifiers are automatically ' +
          'available for search and investigation.',
      ],
      footerText: 'Create Classifiers',
      onClickFooter: () => navigate('/setup/data'),
    },
  };

  const taskOrder = useMemo(() => Object.keys(myTasksMapping), [
    myTasksMapping,
  ]);

  return {
    myTasksMapping,
    getAllTasksStatus,
    getMyTasks,
    taskOrder,
  };
};
