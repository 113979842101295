import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

interface ILinkText extends BoxProps {}

export const LinkText: FC<ILinkText> = props => {
  return (
    <Box
      cursor={'pointer'}
      fontSize={'xs'}
      color={'primary'}
      textDecoration={'underline'}
      {...props}
    />
  );
};
