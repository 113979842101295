import React, { memo, useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { cloneDeep, isEmpty, merge } from 'lodash';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { defaultStyles } from './styles';
import { IComposedChart } from './types';

export const CustomComposedChart = memo((props: IComposedChart) => {
  const {
    data,
    overwriteDefaultStyles = false,
    styles,
    xField,
    legend = true,
    tooltip = true,
    bar,
    area,
    line,
    cartesianGrid = false,
    isLoading = false,
  } = props;

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : isEmpty(data) ? (
        <NoDataAvailable />
      ) : (
        <ResponsiveContainer>
          <ComposedChart data={data} {...baseStyles?.composedChart}>
            {cartesianGrid && (
              <CartesianGrid {...(baseStyles?.cartesianGrid as any)} />
            )}
            <XAxis dataKey={xField} {...baseStyles?.xAxis} />
            <YAxis
              yAxisId="left"
              orientation="left"
              {...baseStyles?.firstYAxis}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              {...baseStyles?.secondYAxis}
            />
            {tooltip && <Tooltip {...baseStyles?.tooltip} />}
            {legend && <Legend {...(baseStyles?.legend as any)} />}
            {area && (
              <Area
                type="monotone"
                dataKey={area.yField}
                stroke="#ff7300"
                yAxisId={area.orientation}
              />
            )}
            {bar && (
              <Bar
                yAxisId={bar.orientation}
                dataKey={bar.yField}
                fill="#82ca9d"
                {...(baseStyles?.bar as any)}
              />
            )}
            {line && (
              <Line
                type="monotone"
                dataKey={line.yField}
                stroke="#ff7300"
                yAxisId={line.orientation}
                {...(baseStyles?.line as any)}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
});
