import React from 'react';

import { Icon } from '@chakra-ui/react';

export const Route53HostedZoneIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M369.8,351.9c-0.1-1.9-0.2-3.9-0.4-5.8s-0.4-3.8-0.7-5.7c-0.3-1.8-0.6-3.7-1-5.6s-0.8-3.7-1.2-5.5
		c-0.5-1.8-0.9-3.5-1.5-5.4c-0.6-1.8-1.2-3.6-1.8-5.4c-0.7-1.8-1.3-3.6-2.1-5.2s-1.5-3.4-2.3-5.1s-1.6-3.2-2.5-4.9
		c-0.9-1.6-1.8-3.2-2.7-4.8s-1.9-3.1-2.9-4.6l-3.2-4.5c-1.1-1.4-2.2-2.7-3.3-4.2c-1.1-1.5-2.3-2.8-3.5-4.2c-1.2-1.3-2.5-2.6-3.7-3.9
		c-1.4-1.3-2.8-2.6-4.2-3.8c-1.4-1.2-2.9-2.5-4.2-3.6c-1.3-1.1-2.7-2.3-4.2-3.3s-2.9-2-4.4-3c-1.5-1-3.1-2-4.7-3
		c-1.5-0.9-3.1-1.8-4.7-2.7l-4.9-2.5c-1.6-0.8-3.2-1.5-4.9-2.2l-0.5-0.2c-1.7-0.7-3.4-1.4-5.1-2c-1.7-0.6-3.5-1.2-5.2-1.7
		c-1.7-0.5-3.5-1-5.3-1.5s-3.6-0.9-5.4-1.2l-5.6-1c-1.9-0.3-3.7-0.7-5.7-0.7c-3.8-0.5-7.7-0.5-11.6-0.5h-5.8
		c-1.9,0.1-3.9,0.2-5.8,0.4c-1.9,0.2-3.8,0.4-5.6,0.7l-5.6,1l-0.2,0.2c-16.8,3.4-32.2,10.5-45.4,20.4c0,0,0,0,0,0c0,0,0,0,0,0
		c-2.1,1.6-4.2,3.3-6.3,5.1c-0.7,0.6-1.4,1.2-2.1,1.8c-0.9,0.8-1.7,1.6-2.5,2.4c-21.3,20.7-34.6,49.6-34.6,81.7
		c0,11.9,1.8,23.4,5.2,34.1c0.5,1.7,1.1,3.5,1.8,5.2c0.7,1.8,1.3,3.5,2.1,5.2c0.3,0.6,0.5,1.2,0.8,1.7c0.8,1.9,1.7,3.7,2.7,5.5
		c0.4,0.9,0.9,1.8,1.4,2.7c0.9,1.7,1.8,3.3,2.7,4.8s2,3,3,4.6c1,1.5,2,3.1,3.1,4.5c1.1,1.3,2.2,2.7,3.4,4.2c1.1,1.5,2.4,2.9,3.5,4.2
		s2.4,2.6,3.7,3.9c1.5,1.2,2.8,2.6,4.2,3.8c1.4,1.2,2.7,2.4,4.2,3.6l4.2,3.3c1.5,1,2.9,2.1,4.4,3.1l4.7,3l1.9,1.1
		c0.2,0.1,0.4,0.2,0.6,0.3l2.2,1.3c1.6,0.9,3.2,1.7,4.8,2.5c1.6,0.8,3.3,1.5,4.9,2.2l0.5,0.2c1.7,0.7,3.5,1.4,5.2,2
		c1.7,0.6,3.5,1.2,5.2,1.7s3.5,1,5.3,1.5c1.8,0.5,3.5,0.8,5.4,1.2c1.9,0.4,3.8,0.7,5.6,1c1.8,0.3,3.7,0.5,5.7,0.7
		c1.9,0.2,3.8,0.4,5.7,0.4h5.8c3.9,0,7.8-0.2,11.6-0.5c1.9-0.2,3.8-0.4,5.7-0.7l5.6-1c1.9-0.4,3.7-0.8,5.5-1.2l5.4-1.5
		c1.8-0.6,3.5-1.2,5.3-1.8c1.8-0.7,3.6-1.4,5.2-2.1c1.7-0.7,3.4-1.5,5-2.3c1.7-0.8,3.2-1.7,4.9-2.5s3.3-1.8,4.8-2.7
		c1.5-0.9,3.1-1.9,4.6-2.9l4.5-3.1c1.4-1.1,2.7-2.2,4.2-3.4c1.5-1.1,2.8-2.3,4.2-3.5c1.3-1.2,2.6-2.4,3.9-3.7
		c1.3-1.5,2.5-2.8,3.8-4.2c1.2-1.4,2.4-2.7,3.6-4.2c1.1-1.3,2.2-2.7,3.3-4.2c1.1-1.5,2.1-2.9,3.1-4.4c1-1.5,2-3,2.9-4.6
		c0.9-1.5,1.8-3.2,2.7-4.8c0.9-1.6,1.7-3.2,2.5-4.9c0.8-1.6,1.5-3.3,2.2-5c0.1-0.2,0.2-0.4,0.2-0.5c0.7-1.7,1.4-3.4,2-5.1
		s1.2-3.5,1.7-5.2s1-3.5,1.5-5.3s0.8-3.5,1.2-5.4c0.4-1.9,0.7-3.7,1-5.6c0.3-1.9,0.5-3.8,0.7-5.7c0.2-1.9,0.4-3.8,0.4-5.7
		c0-0.9,0-1.9,0-2.8c0-1,0-1.9,0-2.9c0-0.6,0-1.2,0-1.8C369.8,354.7,369.8,353.3,369.8,351.9z M278,280.7c2.5,2.6,5.2,5.7,7.2,7.9
		s3.7,4.4,5.5,6.7h-28.1v-29.5c2.6,2.3,4.7,4.2,7.5,6.9C272.8,275.4,275.5,278.1,278,280.7z M273.6,258.6h0.8l2,0.4
		c1.6,0.3,3.2,0.7,4.8,1.1c1.6,0.4,3.1,0.8,4.7,1.3c1.6,0.5,3.1,1,4.6,1.5c1.5,0.6,3,1.1,4.5,1.7c0.1,0,0.2,0,0.3,0
		c1.6,0.7,3.1,1.4,4.6,2.1s3.1,1.5,4.4,2.2c1.3,0.8,2.7,1.6,4.2,2.5l4.2,2.6c1.3,0.9,2.6,1.8,3.9,2.8c1.3,1,2.6,2,3.8,3
		c1.2,1,2.5,2.1,3.6,3.1c1.2,1,2.3,2.2,3.5,3.3l2.7,2.8l2.5,2.7l1.9,2.2l0.9,1.2h-29.2c-2.3-3.2-4.7-6.5-7.3-9.8
		c-2.7-3.3-5.6-7-8.5-10c-2.8-3.1-6.2-6.7-9-9.3C278.9,263.6,276.3,261.1,273.6,258.6z M299.6,308c2.2,3.7,4.5,7.4,6.3,11.1
		c1.9,3.7,3.5,7.4,4.9,11.3c1.3,3.6,2.4,7.2,3.3,11c0.8,3.3,1.3,6.7,1.7,10h-53.3V308L299.6,308z M316.2,364.2
		c-0.2,3.5-0.6,6.9-1.3,10.3c-0.7,3.7-1.7,7.4-2.9,11c-1.3,3.8-2.9,7.6-4.6,11.2c-1.8,3.7-3.8,7.4-6,10.9h-38.9v-43.4H316.2z
		 M292.2,420.4c-1.8,2.2-3.4,4.2-5.7,6.8c-2.4,2.7-5,5.4-7.5,8c-2.6,2.7-5.7,5.7-8.3,8c-2.6,2.4-5.3,4.7-8,7v-30H292.2z
		 M282.4,449.7c3.3-3.1,6-5.8,9.3-9.3s6.5-6.9,9.2-10.2c2.7-3.3,5.2-6.5,7.5-9.8h27.3l-0.2,0.1l-0.9,1.1l-1.9,2.2l-2.5,2.7l-2.6,2.7
		c-1.1,1.1-2.3,2.2-3.5,3.3l-3.6,3.1c-1.2,1-2.3,2-3.8,3c-1.5,1-2.8,1.9-4.2,2.8l-4.2,2.6c-1.3,0.8-2.7,1.7-4.2,2.5
		c-1.5,0.8-3,1.5-4.4,2.2c-1.5,0.7-3,1.4-4.5,2.1c-1.5,0.7-3.1,1.2-4.6,1.8c-1.5,0.6-3.1,1.1-4.7,1.6l-4.8,1.3
		c-1.6,0.4-3.2,0.7-4.8,1.1l-2.3,0.4C276.9,454.8,279.1,452.8,282.4,449.7z M316,407.4c1.8-3.3,3.4-6.6,4.8-10
		c1.6-3.6,3-7.3,4.2-11.1c1.1-3.7,2-7.4,2.7-11.2c0.6-3.6,1-7.2,1.2-10.9h27.5c0,0.9,0.1,1.6,0,2.8c-0.1,1.2-0.3,2.3-0.3,3.5
		c-0.2,1.3-0.3,2.6-0.5,3.8c-0.2,1.2-0.4,2.2-0.7,3.8c-0.3,1.6-0.7,3.2-1.1,4.8c-0.4,1.6-0.8,3.2-1.3,4.7c-0.5,1.5-1,3.1-1.5,4.6
		c-0.5,1.5-1.1,3-1.8,4.5v0.3l-1.3,2.9l-1.4,2.9l-1.3,2.5l-1.1,2.1L316,407.4z M328.7,351.4c-0.7-7.5-2.3-14.9-4.7-22.1
		c-1.2-3.7-2.7-7.5-4.4-11.2c-1.6-3.4-3.3-6.7-5.1-10.1h29.6l0.2,0.2l1.2,2.2l1.3,2.6c0.5,1,0.9,1.9,1.3,2.8c0.4,1,0.9,2,1.2,2.9
		c0.7,1.5,1.2,3,1.8,4.6s1.1,3.1,1.6,4.7s0.9,3.2,1.3,4.8c0.4,1.6,0.8,3.2,1.1,4.8c0.2,1.3,0.5,2.5,0.7,3.8s0.5,2.5,0.5,3.8l0.4,3.5
		c0,0.9,0,1.9,0,2.7H328.7z M155.6,348.3c0.1-1.2,0.2-2.2,0.3-3.5s0.3-2.7,0.5-3.9c0.2-1.2,0.4-2.2,0.7-3.8c0.3-1.6,0.7-3.2,1.1-4.8
		c0.4-1.6,0.9-3.2,1.3-4.7s1-3.2,1.5-4.6c0.5-1.5,1.1-3,1.7-4.5c0.4-1,0.8-2,1.2-2.9c0.5-0.9,0.9-1.9,1.4-2.8l1.3-2.6l1.2-2.2h29.2
		c-1.9,3.4-3.6,6.7-5.1,10.1c-1.6,3.6-3,7.3-4.2,11.1c-2.4,7.2-4,14.6-4.7,22.1h-28.1l0.4-0.3C155.6,350.2,155.6,349.5,155.6,348.3z
		 M176.6,295.2l0-0.2l1-1.2c0.6-0.8,1.2-1.5,1.9-2.3l2.5-2.7l2.6-2.6c1.2-1.2,2.3-2.2,3.5-3.3c1.2-1.1,2.4-2.2,3.6-3.2
		c1.2-1,2.3-2,3.8-3c1.5-1,2.7-1.9,4.2-2.8c1.4-0.9,2.9-1.7,4.2-2.5c1.3-0.9,2.7-1.7,4.2-2.5c1.5-0.8,3-1.5,4.4-2.2
		c1.5-0.7,3.1-1.4,4.6-2.1s3-1.3,4.6-1.9c1.6-0.6,3.1-1.1,4.7-1.6s3.2-1,4.8-1.3l4.8-1.1l2-0.4h1.2c-3,2.6-5.2,4.6-8.3,7.7
		c-3.2,3.2-6,6.1-9,9.4c-3,3.3-5.9,6.7-8.6,10.1c-2.6,3.2-5.1,6.5-7.4,9.7H176.6z M182.9,364.2c0.2,3.6,0.6,7.2,1.2,10.7
		c0.7,3.9,1.6,7.7,2.7,11.5c1.2,3.8,2.6,7.5,4.2,11.2c1.4,3.3,3.1,6.7,4.9,10h-27.8l-0.3-0.2l-1.2-2.1l-1.3-2.6
		c-0.5-1-0.9-1.9-1.3-2.8l-1.2-2.9c-0.6-1.5-1.2-3-1.8-4.6c-0.6-1.6-1.1-3.2-1.6-4.7c-0.5-1.5-0.9-3.1-1.3-4.7s-0.8-3.2-1.1-4.8
		c-0.2-1.3-0.5-2.5-0.7-3.8s-0.4-2.7-0.5-3.8c-0.1-1.1-0.4-2.5-0.4-3.5c0-1,0-1.9,0-2.8H182.9z M204.2,420.4c2.1,3.3,4.5,6.6,7.2,10
		c2.7,3.3,5.9,7.1,8.8,10c2.9,3,6.6,6.6,9.2,9.1c2.6,2.4,5.3,4.9,8.1,7.3l-1.7-0.3c-1.6-0.3-3.2-0.7-4.8-1l-4.7-1.3
		c-1.5-0.5-3.1-1-4.6-1.6c-1.5-0.6-3-1.1-4.5-1.7h-0.3l-4.6-2.1c-1.5-0.7-3-1.5-4.4-2.2c-1.4-0.7-2.8-1.6-4.2-2.4l-4.2-2.6
		c-1.5-0.9-2.9-1.8-4.2-2.8c-1.3-1-2.6-2-3.8-3c-1.2-1-2.5-2-3.6-3.1c-1.2-1.1-2.3-2.2-3.5-3.3c-1.7-1.8-3.5-3.6-5.1-5.5l-1.9-2.2
		l-0.9-1.1H204.2z M233.1,435.3c-2.7-2.7-5.5-5.7-7.5-8c-2-2.2-3.9-4.5-5.7-6.8h29.7v30h-0.1c-2.8-2.3-5.2-4.3-8.1-7
		S235.8,437.9,233.1,435.3z M210.6,407.6c-2.2-3.6-4.2-7.2-6-10.9c-1.8-3.6-3.3-7.4-4.6-11.2c-1.2-3.6-2.2-7.3-2.9-11
		c-0.7-3.4-1.1-6.8-1.3-10.2h53.7v43.4H210.6z M196.3,351.4c0.4-3.4,0.9-6.7,1.7-10c0.9-3.7,2-7.4,3.3-11c1.4-3.9,3.1-7.6,4.9-11.3
		c1.9-3.7,4-7.4,6.3-11.1h37.1v43.4L196.3,351.4z M221.5,295.2c1.7-2.2,3.2-4,5.5-6.7s4.7-5.1,7.2-7.9c2.5-2.7,5.6-5.7,8-8
		s4.9-4.6,7.5-6.9v29.5H221.5z"
      />
      <path
        d="M492.1,174.6c-3.7-5.8-7.9-11.2-12.5-16.2c-19.6-21.4-47.3-33.5-76.3-33.3c-6.7,0-13.4,0.5-20,1.5
		C368.1,95.8,343,71,312,56.2c-26.6-12.7-56.2-17.9-85.6-15C197,43.9,169,54.9,145.5,72.8c-27,21.1-45.9,50.7-53.8,84
		c-7.8,1.8-15.5,4.2-23,7.1c-9.8,3.7-19.1,8.7-27.6,14.8c-6.7,4.8-12.8,10.4-18.1,16.7C7.8,213.9-0.4,237.3,0,261.3
		c0.1,23.8,7.6,47.1,21.6,66.4c5.3,7.3,11.4,13.9,18.3,19.7c0.9,0.8,1.9,1.5,2.8,2.3l0,0c0,0,0,0,0,0c6.8,5.3,14.4,9.6,22.4,12.9
		c1.5,0.6,3,1.2,4.5,1.7c13.7,5.4,26.5,7.7,35.3,8.3l0.2-31.8l-0.1,0c-4.5,0-14-1.4-24.8-5.6c-1.2-0.4-2.3-0.8-3.5-1.3
		c-4-1.7-7.9-3.7-11.5-6.1c-17.8-11.1-34.5-30.6-34.6-64.1c0-0.1,0-0.1,0-0.2c0-0.7-0.1-1.4-0.1-2.1c-0.2-8.6,1.2-17,4-25
		c6.2-19.1,19-31,33.7-38.7c3.7-2,7.5-3.8,11.5-5.2c8.8-3.4,18-5.8,27.3-7.4l5.3-0.9c1.7-0.3,3.5-0.6,5.1-0.9l2-10.5
		c5-29.9,20.9-56.8,44.6-75.6c18.9-14.4,41.4-23.3,65.1-25.4c23.9-2.4,47.9,1.8,69.6,12.1c22.9,10.9,41.8,28.7,54.2,50.8
		c-2.8,1.2-5.6,2.5-8.3,3.9c-6.9,3.2-13.5,6.9-19.9,11c-8,5.2-15.5,11.1-22.5,17.5l20.2,23.1c8.8-7.4,18.2-14,28-19.9
		c3.1-1.8,6.2-3.5,9.4-5.1c13.6-6.2,28.4-9.5,43.3-9.6c20.6-0.3,40.3,8.3,54.2,23.4c3.5,3.7,6.6,7.7,9.4,12
		c10.4,17.4,15.5,37.5,14.9,57.7c-0.2,20.3-5.3,39.7-15.7,53.6c-7.2,9.3-16.3,16.9-26.7,22.5c-10.3,5.6-21.2,9.9-32.5,12.9l0.9,31.4
		c16.2-3.7,31.9-9.6,46.5-17.6c14-7.6,26.2-18.1,35.9-30.7c14.5-19.2,21.6-45.5,21.9-71.7C512.6,223,505.7,197,492.1,174.6z"
      />
    </g>
  </Icon>
);
