import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ResourceAssociations,
  AddressFamily,
} from '../../../Components/MetadataField/MetaGridFields';

export const PrefixListMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const columns = [
    { header: 'Cidr', accessor: 'Cidr' },
    { header: 'Description', accessor: 'Description', align: 'left' },
  ];

  const entries = resource?.config?.Entries ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {AddressFamily(resource)}
      {ResourceAssociations(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={entries} />}
            iconType={NativeResources.PrefixList}
            label="Entries"
            totalCount={entries?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
