import React, { useContext } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { EnvironmentRead } from '@ariksa/compliance-policies/api';
import { FaqRead, FeatureRead } from '@ariksa/profile/api';
import { UseDisclosureProps } from '@chakra-ui/react';
import { IQueryState } from 'services/utils/QueryState';

import { AlertData } from 'api/alerts/types';
import { apiCallStateInitialState } from 'api/initial_values';
import { ApiCallState } from 'api/types';
import { SelectedEnvironment, UserState } from 'containers/App/types';

export interface NavBarContextProps {
  user: UserState;
  onLogout: (e: any) => void;
  resetPassword: () => void;
  alerts: AlertData[];
  environments: ApiCallState<EnvironmentRead[]>;
  accounts: ApiCallState<CloudAccountGetResponse[]>;
  search: {
    onChangeSearchTerm(value: string);
    onAiSearch(searchTerm: string);
    onSearch(searchTerm: string);
    value: string;
    onClearSearch();
    isLoading?: boolean;
    aiSearchModal?: UseDisclosureProps;
  };

  onSelectEnvironmentOrAccount(selectedEnvironment: SelectedEnvironment);

  faq: {
    data: FaqRead[];
    isLoading: boolean;
    searchTerm: string;
    setSearchTerm: (value: string) => void;
  };

  journey: {
    myTasks: FeatureRead[];
    taskRead: Record<string, IQueryState<FeatureRead>>;
    isLoadingMyTasks: boolean;
    getMyTasksStatus(tasks: FeatureRead[]);
  };

  showWelcomeScreen: boolean;
  cloudInsight: {
    onOpen(): void;
  };
}

export const NavBarContext = React.createContext<NavBarContextProps>({
  user: {
    loggedOut: false,
    creating: false,
    authenticating: false,
    authenticated: false,
    error: null,
    tokenInfo: {},
    info: {
      id: '',
      username: '',
      org_id: '',
      org_uuid: '',
      avatar: '',
      email: '',
    },
  },
  onLogout() {},
  resetPassword() {},
  alerts: [],
  environments: apiCallStateInitialState<EnvironmentRead[]>([]),
  accounts: apiCallStateInitialState<CloudAccountGetResponse[]>([]),
  onSelectEnvironmentOrAccount() {},
  search: {
    onChangeSearchTerm() {},
    onClearSearch() {},
    onAiSearch(searchTerm: string) {},
    onSearch(searchTerm: string) {},
    value: '',
  },

  faq: {
    data: [],
    isLoading: false,
    searchTerm: '',
    setSearchTerm: () => {},
  },

  journey: {
    myTasks: [],
    taskRead: {},
    isLoadingMyTasks: false,
    getMyTasksStatus() {},
  },

  showWelcomeScreen: false,

  cloudInsight: {
    onOpen() {},
  },
});

export const NavBarContextProvider = NavBarContext.Provider;

export const useNavBarContext = () => useContext(NavBarContext);
