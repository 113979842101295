import React, { FC, useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';
import { filter, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  renderTableHeaderWithLoader,
  renderTime,
  StackedCell,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { EnvironmentIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions } from 'containers/SharedState/slice';

interface IUpdateAccounts {
  accounts: Record<string, any>[];
  isOpen: boolean;
  onClose();
  environment: Record<string, any>;
}

export const AccountsUnderEnvironment: FC<IUpdateAccounts> = props => {
  const { accounts, isOpen, onClose, environment } = props;
  const dispatch = useDispatch();
  const { snapshotStatus } = useSelector(selectSharedState);
  const ref = useRef(document.querySelector('.portal-container'));

  useEffect(() => {
    isOpen &&
      dispatch(
        actions.getSnapshotStatus({
          q: {
            getLatestWorkflowRequest: {
              account_ids: filter(
                map(accounts, o => o?.uuid),
                o => !!o,
              ),
            },
          },
        }),
      );
  }, [dispatch, isOpen, accounts]);

  const columns = [
    {
      header: <Box pl={10}>Account Name</Box>,
      accessor: 'name',
      render: ({ value, row }) => (
        <WithResourceIcon
          resourceType={row?.cloud_type}
          iconFilled={false}
          iconSize="regular"
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Cloud',
      align: 'left',
      render: () => (
        <Box boxSize={5}>
          {environment?.environment_type?.replace('Provider', '')}
        </Box>
      ),
    },
    {
      header: 'Account ID',
      accessor: 'cloud_account_id',
      align: 'left',
    },
    {
      header: 'Added',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row?.created_by}
          lower={renderTime({ value: row?.created_date })}
          showLowerTooltip={false}
        />
      ),
    },
    {
      header: renderTableHeaderWithLoader(
        'Last Discovery',
        snapshotStatus.isLoading,
      ),
      render: ({ row }) =>
        renderTime({ value: snapshotStatus.data?.[row?.uuid]?.created_at }),
      align: 'left',
    },
  ];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      styles={{
        drawer: {
          portalProps: { containerRef: ref as any },
        },
        content: { px: 0, maxW: '1100px', h: 'full' },
      }}
      header={
        <StackedHeader
          upper="View Environment"
          lower={environment?.name}
          icon={<EnvironmentIcon />}
        />
      }
      body={
        <Box h="full">
          <Table data={accounts} columns={columns} />
        </Box>
      }
    />
  );
};
