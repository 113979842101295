import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';
import { map } from 'lodash';

import { DocumentItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Document';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  ClusterArn,
  CreatedAt,
  CreatedBy,
  DesiredCount,
  ECSPlatformFamily,
  ECSPlatformVersion,
  ECSServices,
  LoadBalancers,
  NetworkConfiguration,
  PendingCount,
  RunningCount,
  SecurityGroups,
  ServiceArn,
  ServiceRegistries,
  Status,
  Subnets,
  UpdatedAt,
} from '../../../Components/MetadataField/MetaGridFields';

export const ECSMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {CreatedBy(resource)}
      {ECSServices(resource)}
      <GridItem colSpan={4}>
        <Accordion w="full" allowToggle>
          {map(insight?.services, o => (
            <DocumentItem
              label={o?.name}
              content={
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                  {LoadBalancers(o)}
                  {ServiceRegistries(o)}
                  {ClusterArn(o)}
                  {ServiceArn(o)}
                  {ECSPlatformVersion(o)}
                  {ECSPlatformFamily(o)}
                  {Status(o)}
                  {PendingCount(o)}
                  {DesiredCount(o)}
                  {RunningCount(o)}
                  {CreatedAt(o, 'dayjs')}
                  {UpdatedAt(o)}
                  {NetworkConfiguration(o)}
                  {Subnets(o)}
                  {SecurityGroups(o)}
                </Grid>
              }
            />
          ))}
        </Accordion>
      </GridItem>
    </Grid>
  );
};
