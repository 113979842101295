import React from 'react';

import { Box } from '@chakra-ui/react';

import { EmailIDsField } from 'components/DataEntry/Form/fields/EmailIDsField';

import { CheckboxField } from '../fields/CheckboxField';
import { ArrayField, ObjectField } from '../fields/Containers';
import { CustomField } from '../fields/CustomField';
import { DayPickerField } from '../fields/DayPickerField';
import { NumberField } from '../fields/NumberField';
import { RadioField } from '../fields/RadioField';
import { ReactMultiSelectCheckboxField } from '../fields/ReactMultiSelectCheckboxField';
import { ReactSelectField } from '../fields/ReactSelectField';
import { SelectField } from '../fields/SelectField';
import { SwitchField } from '../fields/SwitchField';
import { TextAreaField } from '../fields/TextAreaField';
import { TextField } from '../fields/TextField';
import { TimePickerField } from '../fields/TimePickerField';
import { Field } from '../types';

export const useFormComponent = () => {
  const renderField = isLoading => (
    [name, field]: [string, Field],
    id?: string,
  ) => {
    let Component: any = null;

    switch (field.type) {
      case 'text':
        Component = TextField;
        break;

      case 'textArea':
        Component = TextAreaField;
        break;

      case 'number':
        Component = NumberField;
        break;

      case 'array':
        Component = ArrayField;
        break;

      case 'object':
        Component = ObjectField;
        break;

      case 'switch':
        Component = SwitchField;
        break;

      case 'checkbox':
        Component = CheckboxField;
        break;

      case 'select':
        Component = SelectField;
        break;

      case 'react-select':
        Component = ReactSelectField;
        break;

      case 'react-multi-select-checkbox':
        Component = ReactMultiSelectCheckboxField;
        break;

      case 'radio':
        Component = RadioField;
        break;

      case 'dayPicker':
        Component = DayPickerField;
        break;

      case 'timePicker':
        Component = TimePickerField;
        break;

      case 'emailIds':
        Component = EmailIDsField;
        break;

      case 'custom-with-form-control':
        Component = CustomField;
        break;

      case 'custom':
        Component = field.component;
        const visible = field.isVisible ? field.isVisible : true;
        return visible ? (
          <Box key={`${name}-container`} className={name}>
            <Component
              name={name}
              id={id}
              data-testid={id}
              field={field}
              {...field.props}
              isLoading={isLoading}
            />
          </Box>
        ) : null;

      default:
        break;
    }

    return (
      <Box key={`${name}-container`}>
        <Component
          id={id}
          data-testid={id}
          name={name}
          field={field}
          isLoading={isLoading}
        />
      </Box>
    );
  };

  return { renderField };
};
