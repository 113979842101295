import React, { useCallback, useState } from 'react';

import { DataClassifiers } from '@ariksa/data-scanning/api';
import {
  Box,
  Flex,
  Center,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton, AddButton } from 'components/DataEntry';
import {
  CheckmarkCircleOutlineIcon,
  DataClassificationIcon,
  DisableIcon,
  EditIcon,
  TrashIcon,
} from 'components/Icons';
import { DataClassifierModal } from 'containers/Setup/DataConfiguration/Components/DataClassifier/DataClassifierModal';
import { useDataClassifierTableColumns } from 'containers/Setup/DataConfiguration/Components/DataClassifier/hooks/useDataClassifierTableColumns';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';
import { DataClassifierAction } from 'containers/Setup/DataConfiguration/types';
import { toTitleCase } from 'utils/string';

export const DataClassifierTable: React.FC = () => {
  const dispatch = useDispatch();
  const { dataClassifiers } = useSelector(selectDataClassification);
  const action = useDisclosure();
  const {
    onOpenForm,
    selectedTab,
    currentRecord,
    setCurrentRecord,
    setActionType,
  } = useDataConfigurationContext();
  const [
    classifierAction,
    setClassifierAction,
  ] = useState<DataClassifierAction>('Enable');
  const {
    dataExclusionColumns,
    dataTypeColumns,
  } = useDataClassifierTableColumns();

  const getData = useCallback(() => {
    selectedTab === 'types'
      ? dispatch(actions.getDataTypes({ q: {} }))
      : dispatch(actions.getDataExclusions({ q: {} }));
  }, [dispatch, selectedTab]);

  useDeepCompareEffect(() => {
    getData();
  }, [getData]);

  const renderAction = ({ row }) => {
    return (
      <Center
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionButton
          label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            setCurrentRecord(row);
            setActionType('Update');
            onOpenForm();
          }}
        />
        {selectedTab !== 'types' && (
          <ActionButton
            label={row?.is_enabled ? 'Disable' : 'Enable'}
            icon={
              !row?.is_enabled ? (
                <CheckmarkCircleOutlineIcon color="green" />
              ) : (
                <DisableIcon color="red" />
              )
            }
            isDisabled={row?.created_by === 'Ariksa'}
            onClick={() => {
              setCurrentRecord(row);
              action.onOpen();
              setClassifierAction(row?.is_enabled ? 'Disable' : 'Enable');
            }}
          />
        )}
        <ActionButton
          label={'Delete'}
          icon={<TrashIcon color="red" />}
          isDisabled={row?.created_by === 'Ariksa'}
          onClick={() => {
            setCurrentRecord(row);
            action.onOpen();
            setClassifierAction('Delete');
          }}
        />
      </Center>
    );
  };

  const columns = [
    ...(selectedTab === 'types' ? dataTypeColumns : dataExclusionColumns),
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Stack h="full" spacing={5} w="full">
      <HStack w="full" justify="space-between">
        <PageHeaderWithIcon
          label={'Data ' + toTitleCase(selectedTab as string)}
          icon={
            selectedTab === 'types' ? (
              <DataClassificationIcon />
            ) : (
              <DisableIcon />
            )
          }
        />
        <Flex>
          <AddButton
            label={'Add Data ' + toTitleCase(selectedTab as string)}
            onClick={() => {
              setActionType('Add');
              onOpenForm();
            }}
          />
        </Flex>
      </HStack>
      <Box flex={1}>
        <Table
          data={dataClassifiers.data}
          columns={columns}
          isLoading={dataClassifiers.isLoading}
        />
      </Box>
      {action.isOpen && (
        <DataClassifierModal
          currentRecord={currentRecord as DataClassifiers}
          isOpen={action.isOpen}
          onClose={action.onClose}
          actionType={classifierAction}
        />
      )}
    </Stack>
  );
};
