import React, { FC, useEffect, useState } from 'react';

import {
  CloudAccountGetResponse,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { filter, isEmpty, map, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { Form } from 'components/DataEntry/Form';
import { QuestionCircleIcon } from 'components/Icons';
import { selectApp } from 'containers/App/selectors';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { DataClassificationWizardProps } from 'containers/Setup/DataConfiguration/types';

import { DataPostureManagement } from '../DataPostureManagement';
import { SelectDataSources } from '../SelectDataSources';
import { snowflakeFormStyles } from '../styles';
import { renderComponentHeader } from '../utils';

interface Props extends DataClassificationWizardProps {
  selectedDatabases: Dict<any>[];
}

export const SnowflakeDataClassificationForm: FC<Props> = props => {
  const {
    handleRedirect,
    handleSubmit,
    accountOptions,
    databaseOptions,
    selectedAccount,
    selectedDatabases,
    scannerOptions,
    selectedScanner,
    updateValue,
    scannerAccountOptions,
    selectedScannerAccount,
    scannerEnvironmentOptions,
    selectedScannerEnvironment,
    environmentOptions,
    selectedEnvironment,
  } = props;
  const [showHelp, setShowHelp] = useState(false);
  const { service, enableDataClassification, dataScannerInfo } = useSelector(
    selectDataClassification,
  );
  const [totalIncludedDataSources, setTotalIncludedDataSources] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState('');
  const { currentRecord, actionType } = useDataConfigurationContext();
  const { cloudAccounts } = useSelector(selectApp);

  //set name
  useEffect(() => {
    if (actionType === 'Update') {
      setName(currentRecord?.name);
    }
  }, [currentRecord, actionType]);

  //set total number of included data sources
  useEffect(() => {
    let sources = service?.data_objects;
    const totalResources = sum(
      map(selectedDatabases, o => o?.data?.tables?.length ?? 0),
    );
    let total = totalResources;

    if (service?.category === 'include') {
      if (service?.quantifier !== 'all') total = sources?.length ?? 0;
    } else total = totalResources - (sources?.length ?? 0);

    setTotalIncludedDataSources(total);
  }, [service, selectedDatabases]);

  //set isDisabled
  useEffect(() => {
    if (
      isEmpty(selectedDatabases) ||
      (service.quantifier === 'some' &&
        ((service.service !== NativeResources.SnowflakeDatabases &&
          isEmpty(service.data_sources)) ||
          (service.service === NativeResources.SnowflakeDatabases &&
            isEmpty(service.data_objects)))) ||
      !name
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [selectedDatabases, service, name]);

  return (
    <Box w="full">
      <Form
        styles={snowflakeFormStyles}
        title={renderComponentHeader()}
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            value: name,
            onChange: setName,
          },
          environment_id: {
            type: 'react-select',
            label: 'Environment',
            options: environmentOptions,
            value: selectedEnvironment,
            onChange: selected => {
              updateValue({ selectedEnvironment: selected });
            },
            /*isLoading:
              cloudAccounts.loading || dataClassificationGroups.isLoading,*/
            isDisabled: actionType === 'Update',
          },
          account_ids: {
            type: 'react-select',
            label: 'Account Locator',
            options: accountOptions,
            value: selectedAccount,
            onChange: s => updateValue({ selectedAccount: s }),
            isLoading: cloudAccounts.loading || dataScannerInfo.isLoading,
            isDisabled:
              cloudAccounts.loading ||
              actionType === 'Update' ||
              dataScannerInfo.isLoading,
          },
          databases: {
            type: 'react-select',
            label: 'Choose databases for automatic data classification',
            options: databaseOptions,
            value: selectedDatabases,
            onChange: s => updateValue({ selectedDataSources: s }),
            isMulti: true,
            helpComponent: (
              <DataPostureManagement onClose={() => setShowHelp(false)} />
            ),
            isHelpOpen: showHelp,
            helpIcon: (
              <Box
                as={QuestionCircleIcon}
                color="primary"
                cursor="pointer"
                onClick={() => setShowHelp(!showHelp)}
              />
            ),
          },
          dataSources: {
            type: 'custom-with-form-control',
            component: (
              <SelectDataSources
                dataSourceType={{
                  label: 'Snowflake',
                  value: NativeResources.SnowflakeDatabases,
                  native_resource: 'Snowflake',
                }}
                selectedDatabases={selectedDatabases}
              />
            ),
            label: 'Choose Snowflake database table(s)',
          },
          scanner: {
            type: 'object',
            label: 'Scanner Information',
            properties: {
              scanner_environment_id: {
                type: 'react-select',
                label: 'Select environment',
                options: scannerEnvironmentOptions,
                value: selectedScannerEnvironment,
                onChange: s => updateValue({ selectedScannerAccount: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
              scanner_account_id: {
                type: 'react-select',
                label: 'Select account for Ariksa Data Outpost',
                options: scannerAccountOptions,
                value: selectedScannerAccount,
                onChange: s => updateValue({ selectedScannerAccount: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
              scanner_id: {
                type: 'react-select',
                label: 'Select Ariksa Outpost for Data',
                options: scannerOptions,
                value: selectedScanner,
                onChange: s => updateValue({ selectedScanner: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
            },
          },
          numberOfDataSourcesIncluded: {
            type: 'custom',
            component: () => (
              <HStack color="primary">
                <Box>
                  Number of data sources included for automatic data
                  classification:
                </Box>
                <Box>{totalIncludedDataSources}</Box>
              </HStack>
            ),
          },
        }}
        showHelp={showHelp}
        buttonOptions={{
          submit: {
            name: 'Confirm',
            isDisabled: isDisabled || dataScannerInfo.isLoading,
            isLoading:
              enableDataClassification.isLoading || dataScannerInfo.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleRedirect,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
