import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NetworkMapIcon = props => (
  <Icon
    viewBox="0 0 65 65"
    w="full"
    h="full"
    color="inherit"
    bg="inherit"
    {...props}
  >
    <g>
      <g transform="translate(1.000000, 1.000000)">
        <path
          id="Shape_1_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M62.1,22c0,1.1-0.9,2-2,2h-23
			c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h23c1.1,0,2,0.9,2,2V22L62.1,22z"
        />
        <path
          id="Shape"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M35.1,18h27"
        />

        <rect
          id="Rectangle-path"
          x="38"
          y="4"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          width="21"
          height="14"
        ></rect>
        <path
          id="Shape_2_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M17.1,10h15"
        />
        <path
          id="Shape_3_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M14.1,13v7"
        />
        <path
          id="Shape_4_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M48.1,43h-13"
        />
        <path
          id="Shape_5_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          d="M51.1,40V27"
        />
        <circle
          id="Oval"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          cx="14"
          cy="10"
          r="3"
        ></circle>
        <circle
          id="Oval_1_"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          cx="51"
          cy="43"
          r="3"
        ></circle>
        <g id="Group" transform="translate(0.000000, 23.000000)">
          <ellipse
            id="Oval_2_"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            cx="16"
            cy="15.5"
            rx="16"
            ry="15.5"
          />
          <path
            id="Shape_6_"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M16.2-0.5c0,0,0.5,1.5-1,2c-0.5,0.5-1.5,0.5-1,1
				s0.5,1,0.5,1s-0.4,2-1.5,2.5c-1,0.5-3.2,0.4-3.7,0.4s0-2,0-2l2.1-1c0,0,0.5-1,0-1s-1.5-1.5-1.5-1.5"
          />
          <path
            id="Shape_7_"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M5.9,2.9c1.3,3.4,0,4.9,0,4.9s-1,0-1,1s-0.5,0,0,1s0,2,0,2
				l-1.5,1c0,0-1,0-1.5,0.5s-0.6,0.5-0.5,1c0.2,1.3-1,2-1,2"
          />
          <path
            id="Shape_8_"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M0.1,17.6c0.5,0.5,2.7,0.5,2.7,0.5s1-0.5,1.5,0
				s0.5,2,0.5,2s1,1.5,1.5,1.5s1,0.5,1.5,0.5s1,0.5,1,0.5s0.5,1.5,0,1.5s-0.5,0.5-0.5,0.5s0,2,1,2.4c1,0.5,3.6,1.5,3.6,2
				s1,1.5,3.1,1.5"
          />
          <path
            id="Shape_9_"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M32.1,16.7c0,0-1.5-1.5-1.5-1c0,0.5-0.5,1-0.5,2
				s-0.5,2.4-0.5,2.4l-1-1c0,0-2.6,1.5-2.6,2s-2.1,2-2.6,2.9c-0.5,1-1.5,1.5-2.1,2.4c-0.5,1-1,0.5-1,0s-1.4-2.2,0-3.4
				c0.5-0.5,1-0.5,0.5-1s-1-0.5-1-1v-2c0,0-1-1.5-1.5-1l-1,1c0,0-1.6,0-2.1-1.5c-0.4-1.4,1-2.4,1-2.4l-0.5-2c0,0,1.5-1.5,1.5-2
				s-0.5-3.4,0.5-2.9s3.1,1,3.1,0.5s0.5-1.5,1-1.5h1l2.1-2c0,0-2.1-0.5-2.1-1s0-1,0.5-1.5s1.5-0.5,1.5-0.5"
          />
        </g>
      </g>
    </g>
  </Icon>
);
