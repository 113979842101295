import React from 'react';

import { JitRole } from '@ariksa/jit';

export const roleNameOption = (request: JitRole, cloudType: string) => {
  switch (cloudType) {
    case 'AWS':
      return {
        label: `${request.role}`,
        value: request.role_arn,
      };
    case 'GCP':
      return {
        label: `${request.role} (${request.role_id
          ?.split('/')
          .slice(-2)
          .join('/')})`,
        value: request.role_id,
      };
    default:
      return null;
  }
};
