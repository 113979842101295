import { includes } from 'lodash';

export const sortByOrderProvided = (
  data: Record<string, any>[],
  orderList: string[],
) => {
  const remaining = data?.filter(o => !includes(orderList, o?.key ?? o?.id));

  let orderedValues: Record<string, any>[] = [];
  orderList.forEach(o => {
    const item = data?.find(r => r?.key === o || r?.id === o);
    if (item) orderedValues.push(item);
  });

  orderedValues = [...orderedValues, ...remaining];

  return orderedValues;
};
