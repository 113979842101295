import React, { FC, useEffect, useMemo, useState } from 'react';

import { Permission } from '@ariksa/inventory-core/api';
import {
  Accordion,
  Box,
  Center,
  Checkbox,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { WithResourceIcon } from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { CheckmarkIcon, CrossIcon, IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { toTitleCase } from 'utils/string';

interface IIdentityPermissions {}

interface IPermissionAccordion {
  crudLevel: string;
  permissions: Permission[];
  permissionType?: string;
}

const PermissionAccordion = (props: IPermissionAccordion) => {
  const { crudLevel, permissions, permissionType } = props;

  const columns: TableColumnProps[] = useMemo(() => {
    return [
      {
        header: 'Resource Type',
        accessor: 'native_name',
        render: ({ value, row }) => {
          return (
            <WithResourceIcon resourceType={row?.native_class}>
              <Box>{value}</Box>
            </WithResourceIcon>
          );
        },
      },
      {
        header: 'Permission',
        accessor: 'permission',
        align: 'left',
      },
      {
        header: 'High Privilege',
        accessor: 'permission',

        render: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        header: 'Used',
        accessor: 'used',

        render: ({ value }) => {
          return (
            <Center w={'full'} gap={1}>
              <Center
                w={4}
                borderRadius="full"
                bg={value ? 'green.300' : 'red.400'}
                color={'white'}
                p={0.4}
              >
                {value ? <CheckmarkIcon /> : <CrossIcon />}
              </Center>
              <Text>{value ? 'Yes' : 'No'}</Text>
            </Center>
          );
        },
      },
      {
        header: 'Usage',
      },
      {
        header: 'Last Use',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!!permissionType) {
      return permissions.filter(p =>
        permissionType === 'used' ? p.used : !p.used,
      );
    }

    return permissions;
  }, [permissionType, permissions]);

  return (
    <MetaAccordionItem
      iconType={IconTypes.Permission}
      label={`${toTitleCase(crudLevel)}: ${tableData?.length}`}
      onClickDownload={() => {}}
      panel={<Table columns={columns} data={tableData ?? []} />}
      iconBGColor="primary"
      height={'300px'}
    />
  );
};

export const IdentityPermissions: FC<IIdentityPermissions> = props => {
  const dispatch = useDispatch();
  const {
    resourceUuid,
    resourceAccountId,
    resourceType,
    permissionsMap,
  } = useSelector(selectActiveResource);
  const [permissionType, setPermissionType] = useState('');

  useEffect(() => {
    dispatch(
      actions.getCrudPermissions({
        q: {
          actionsRequest: {
            uuids: [resourceUuid],
            native_type: resourceType,
            account_id: resourceAccountId,
            //excess: showExcessPermission,
          },
        },
      }),
    );
  }, [dispatch, resourceAccountId, resourceType, resourceUuid]);

  const renderCheckBox = (value, label) => (
    <Checkbox
      isChecked={permissionType === value}
      onChange={e => setPermissionType(e.target.checked ? value : '')}
    >
      <Text fontSize="sm">{label}</Text>
    </Checkbox>
  );

  return (
    <Stack h="full">
      <WithSpinner
        loadStatus={{
          loading: permissionsMap.isLoading,
        }}
        spinnerSize="lg"
      >
        <HStack spacing={6}>
          {renderCheckBox('excess', 'Show Excess Permissions')}
          {renderCheckBox('used', 'Show Used Permissions')}
        </HStack>
        <Box>
          <Accordion allowMultiple>
            {permissionsMap.data
              // .filter(p => p.isSuccess && !isEmpty(p.data))
              .map(p => (
                <PermissionAccordion
                  permissions={p.permissions ?? []}
                  crudLevel={p.crud_level ?? '-'}
                  permissionType={permissionType}
                  key={p.crud_level ?? '-'}
                />
              ))}
          </Accordion>
        </Box>
      </WithSpinner>
    </Stack>
  );
};
