import React from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CloudServiceIcon } from 'components/Icons';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const SaaS: React.FC = () => {
  const { dataSecurity } = useSelector(selectDataSecurityOverview);
  const { analyzedDataSourcesCount, summaryOfAllDataSources } = useSelector(
    selectDashboard,
  );
  const { saasCount } = dataSecurity;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="SaaS"
      icon={<CloudServiceIcon />}
      onClick={() => navigate('/inventory/summary?tab=Data')}
      change={{
        isLoading: saasCount.isLoading,
        first: saasCount.data?.previous_count,
        last: saasCount.data?.current_count,
        percentChange: saasCount.data?.percentage_change,
      }}
      cloudProviders={
        summaryOfAllDataSources.data.resource_type_providers?.SaaS ?? []
      }
      count={summaryOfAllDataSources.data.resource_type_counts?.SaaS}
      isLoading={summaryOfAllDataSources.isLoading}
      info={{
        isLoading: analyzedDataSourcesCount.isLoading,
        message: !!analyzedDataSourcesCount.data?.SaaS ? (
          <HStack>
            <Box>Analyzing</Box>
            <Box>
              {analyzedDataSourcesCount.isLoading ? (
                <CustomSpinner />
              ) : (
                analyzedDataSourcesCount.data?.SaaS
              )}
            </Box>
          </HStack>
        ) : (
          'No Resources Analyzed'
        ),
        enabled: !!analyzedDataSourcesCount.data?.SaaS,
      }}
      labelTooltip={formatTooltip({
        header: 'SaaS',
        content:
          'Third-party SaaS data source such as Snowflake, GitLab, GitHub, Atlassian Bitbucket etc.',
        width: 48,
      })}
    />
  );
};
