/**
 *
 * AlertWorkflow
 *
 */

import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { AlertWorkflowTable } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowTable';
import { CreateAlertWorkFlow } from 'containers/PolicyHub/AlertWorkflow/Components/CreateAlertWorkflow';
import { EditAlertWorkFlow } from 'containers/PolicyHub/AlertWorkflow/Components/EditAlertWorkflow';
import { useInjector } from 'utils/inject';

import { alertWorkflowSaga } from './saga';
import { sliceKey, reducer } from './slice';

export const AlertWorkflow = memo(() => {
  useInjector(sliceKey, reducer, alertWorkflowSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/policy-hub/workflow/add" />}>
        <Route path="add" element={<CreateAlertWorkFlow />} />
      </Route>
      <Route element={<FilterRouter path="/policy-hub/workflow/edit/:uuid" />}>
        <Route path="edit/:uuid" element={<EditAlertWorkFlow />} />
      </Route>
      <Route element={<FilterRouter path="/policy-hub/workflow" />}>
        <Route index element={<AlertWorkflowTable />} />
      </Route>
    </Routes>
  );
});
