import { Application, ICanvas } from 'pixi.js';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { AGraphRenderer } from 'components/Visualization/PixiGraph/core/Renderer';
import { connectedEntities } from 'components/Visualization/PixiGraph/core/utils';

export const highlightEdges = (
  firstEntry: {
    data?: any;
    id?: any;
  },
  renderer: AGraphRenderer,
  app: Application<ICanvas>,
  graph: AGraph<AGraphNode, AGraphEdge>,
) => {
  const firstEntryData = firstEntry.data;
  const { id } = firstEntry;
  if (id.includes('edge:start')) {
    const node = graph.nodeIdMap.get(firstEntryData.source);
    if (node) {
      // activate connected nodes and edges
      const connected = connectedEntities(graph, node, 'forward');
      console.log(connected);
      connected.nodes.forEach(node => {
        node.renderActive(renderer.activeGfx);
      });
      connected.edges.forEach(edge => {
        edge.renderActive(renderer.activeGfx);
      });
    }
  } else if (id.includes('edge:end')) {
    const node = graph.nodeIdMap.get(firstEntryData.dest);
    if (node) {
      // activate connected nodes and edges
      const connected = connectedEntities(graph, node, 'backward');
      connected.nodes.forEach(node => {
        node.renderActive(renderer.activeGfx);
      });
      connected.edges.forEach(edge => {
        edge.renderActive(renderer.activeGfx);
      });
    }
  }
};
