/**
 *
 * Asynchronously loads the component for VulnerabilityOverview
 *
 */

import { lazy } from 'react';

export const VulnerabilityOverview = lazy(() =>
  import('./index').then(m => ({ default: m.VulnerabilityOverview })),
);
