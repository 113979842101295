/**
 *
 * Asynchronously loads the component for Compliance
 *
 */

import { lazy } from 'react';

export const Compliance = lazy(() =>
  import('./index').then(m => ({ default: m.Compliance })),
);
