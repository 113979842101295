import React, { useMemo } from 'react';

import { split } from 'lodash';
import { useSelector } from 'react-redux';

import {
  IDashboardLayout,
  WidgetProps,
} from 'components/Visualization/CDashboard/types';
import { AlertCountForAllCategories } from 'containers/Findings/FindingsOverview/Components/Cards/AlertCount/AlertCountForAllCategories';
import { AlertCountForEachCategory } from 'containers/Findings/FindingsOverview/Components/Cards/AlertCount/AlertCountForEachCategory';
import { CreatedVsResolvedIssues } from 'containers/Findings/FindingsOverview/Components/Widgets/CreatedVsResolvedIssues';
import { CriticalAlertsChart } from 'containers/Findings/FindingsOverview/Components/Widgets/CriticalAlertsChart';
import { TopOpenAlertsChart } from 'containers/Findings/FindingsOverview/Components/Widgets/TopOpenAlertsChart';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { renderHeader } from 'containers/Findings/FindingsOverview/utils';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

export const useUserDashboard = () => {
  const { alertFilters } = useSelector(selectFindingsOverview);
  const params = useSearchParams<{ tab: string }>();

  const availableWidgets: Record<string, WidgetProps> = useMemo(() => {
    const currentCategory = params.tab ?? 'Overview';
    return {
      AlertSummary: {
        name: renderHeader(
          currentCategory === 'Overview' || currentCategory === 'AllFindings'
            ? 'Findings Summary'
            : toTitleCase(split(currentCategory as string, '?')[0]),
        ),
        styles: { componentWrapper: { py: 4 }, title: { fontSize: 'md' } },
        components: {
          content:
            currentCategory === 'Overview' ||
            currentCategory === 'AllFindings' ? (
              <AlertCountForAllCategories />
            ) : (
              <AlertCountForEachCategory
                categoryClass={currentCategory as string}
                //category={alertFilters?.alertCategory}
              />
            ),
        },
        className: 'alert_summary',
        w: '18.6%',
      },
      top_open_issues: {
        name: 'Open Findings',
        styles: { title: { fontSize: 'md' } },
        components: {
          content: (
            <TopOpenAlertsChart
              categoryClass={currentCategory as string}
              //alertCategory={alertFilters?.alertCategory}
            />
          ),
        },
        className: 'top_open_issues',
        w: '24%',
      },
      critical_issues: {
        name: 'Critical and High Severity Findings',
        styles: { componentWrapper: { pt: 0 }, title: { fontSize: 'md' } },
        components: {
          content: <CriticalAlertsChart />,
        },
        className: 'critical_issues',
        w: '24%',
      },
      created_vs_resolved_issues: {
        name: 'Created vs. Resolved Issues',
        styles: { componentWrapper: { py: 0 }, title: { fontSize: 'md' } },
        components: {
          content: <CreatedVsResolvedIssues />,
        },
        className: 'created_vs_resolved_issues',
        w: '24%',
      },
    };
  }, [params.tab]);

  const dashboardWidgets: IDashboardLayout = useMemo(
    () => ({
      id: 'dashboard_1',
      title: 'Dashboard Title',
      widgets: [
        {
          type: 'card',
          i: 'AlertSummary',
          x: 0,
          y: 0,
          w: 2.5,
          h: 6.5,
          minH: 2,
          minW: 2,
          isResizable: false,
          static: true,
        },

        {
          type: 'line-chart',
          i: 'top_open_issues',
          x: 2.5,
          y: 0,
          w: 3.16,
          h: 6.5,
          minH: 2,
          minW: 2,
          isResizable: false,
          static: true,
        },
        {
          type: 'line-chart',
          i: 'critical_issues',
          x: 5.66,
          y: 0,
          w: 3.16,
          h: 6.5,
          minH: 4,
          minW: 2,
          isResizable: false,
          static: true,
        },
        {
          type: 'line-chart',
          i: 'created_vs_resolved_issues',
          x: 8.82,
          y: 0,
          w: 3.16,
          h: 6.5,
          minH: 4,
          minW: 2,
          isResizable: false,
          static: true,
        },
      ],
      available: availableWidgets,
    }),
    [availableWidgets],
  );

  return { dashboardWidgets, availableWidgets };
};
