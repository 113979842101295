import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CloudChartIcon = props => (
  <Icon viewBox="0 0 1155 1210" color="inherit" w="full" h="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="1507" y="545" width="1155" height="1210" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="1511" y="545" width="1151" height="1153" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="1511" y="545" width="1151" height="1153" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="1511" y="545" width="1151" height="1153" />
      </clipPath>
      <clipPath id="clip4">
        <rect x="1784" y="1146" width="606" height="606" />
      </clipPath>
      <clipPath id="clip5">
        <rect x="1784" y="1146" width="606" height="606" />
      </clipPath>
      <clipPath id="clip6">
        <rect x="1784" y="1146" width="606" height="606" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-1507 -545)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M2538.39 1110.69C2536.86 1002.29 2448.21 914.59 2339.46 914.59 2314.93 914.59 2290.59 919.166 2267.82 927.954 2250.3 874.825 2217.49 827.145 2173.61 791.67 2121.79 749.777 2056.49 726.705 1989.74 726.705 1832.59 726.705 1703.96 851.208 1697.32 1006.75 1647.3 1021.57 1602.28 1051.51 1569.12 1092.32 1531.64 1138.46 1511 1196.65 1511 1256.2 1511 1399.62 1627.67 1516.3 1771.09 1516.3L2449.46 1516.3C2566.65 1516.3 2662 1420.95 2662 1303.75 2662 1220.75 2612.95 1145.11 2538.39 1110.69ZM2449.46 1471.64 1771.09 1471.64C1652.3 1471.64 1555.66 1374.99 1555.66 1256.2 1555.66 1206.88 1572.75 1158.68 1603.79 1120.48 1634.4 1082.81 1677.19 1056.32 1724.27 1045.88 1734.63 1043.59 1741.94 1034.32 1741.76 1023.71L1741.75 1022.98C1741.73 1021.78 1741.71 1020.59 1741.71 1019.39 1741.71 882.631 1852.97 771.363 1989.74 771.363 2047.13 771.363 2101 790.396 2145.54 826.4 2188.81 861.387 2219.39 910.356 2231.62 964.283 2233.18 971.148 2237.88 976.879 2244.31 979.743 2250.75 982.607 2258.15 982.267 2264.3 978.826 2287.18 966.019 2313.17 959.249 2339.46 959.249 2424.54 959.249 2493.75 1028.47 2493.75 1113.54 2493.75 1116.63 2493.63 1119.93 2493.36 1123.91 2492.71 1133.77 2498.61 1142.89 2507.87 1146.33 2573.35 1170.63 2617.34 1233.9 2617.34 1303.75 2617.34 1396.33 2542.03 1471.64 2449.46 1471.64Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
      <rect
        x="1827"
        y="1197"
        width="523"
        height="503"
        fill={'white'}
        fillOpacity="1"
      />
      <g clipPath="url(#clip4)">
        <g clipPath="url(#clip5)">
          <g clipPath="url(#clip6)">
            <path
              d="M2086.5 1209.02C1954.24 1209.02 1847.02 1316.24 1847.02 1448.5 1847.02 1580.76 1954.24 1687.98 2086.5 1687.98 2218.76 1687.98 2325.98 1580.76 2325.98 1448.5 2325.98 1316.24 2218.76 1209.02 2086.5 1209.02ZM2313.22 1442.2 2092.8 1442.2 2092.8 1221.78C2213.06 1225.26 2309.74 1321.94 2313.22 1442.2ZM1859.63 1448.5C1859.78 1325.73 1957.48 1225.31 2080.2 1221.78L2080.2 1451.11 2242.29 1613.2C2151.42 1699.34 2007.94 1695.5 1921.8 1604.64 1881.84 1562.48 1859.58 1506.59 1859.63 1448.5ZM2251.2 1604.29 2101.84 1454.93C2101.78 1454.87 2101.8 1454.82 2101.88 1454.82L2313.22 1454.82C2311.7 1510.59 2289.61 1563.83 2251.2 1604.29Z"
              stroke="currentColor"
              enableBackground={1}
              //fill="white"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
