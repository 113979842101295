import { Status } from '@ariksa/notification/api';

export const ticketStatusOptions = [
  { label: 'All state', value: undefined },
  { label: 'To do', value: Status.Active },
  { label: 'In progress', value: Status.InProgress },
  { label: 'Done', value: Status.Resolved },
];

export const overDueFilterOptions = [
  { label: 'Over SLA', value: true },
  {
    label: 'Not Over SLA',
    value: false,
  },
];
