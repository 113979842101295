import React, { FC, useEffect } from 'react';

import {
  Box,
  Center,
  HStack,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { map } from 'lodash';
import { customTheme } from 'theme';

import {
  AriksaIcon,
  CustomTooltip,
  H3,
  H4,
  SyntaxHighlight,
} from 'components/DataDisplay';
import { CopyToClipboardIconButton, PrimaryButton } from 'components/DataEntry';
import { IconTypes, LearnMoreIcon } from 'components/Icons';
import { AutomatedRemediationOfIssuesHelp } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/Remediation/AutomatedRemediationOfIssuesHelp';
import { AwsConsoleSteps } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AwsConsoleSteps';

interface IRemediationCommand {
  type: string;
  steps?: string[];
  command?: string;
  console_path?: string;
  aws_console_steps?: any[];
  document?: string;
  note?: string;
  remediation?: any;
  onClickAutoRemediation?(remediation: any): void;
  autoRemediate?: boolean;
  setAutoRemediate?(val: boolean);
}
// @ts-ignore
const json = val => jsonBeautify(val || {}, null, 2, 10);

export const RemediationCommand: FC<IRemediationCommand> = props => {
  const {
    type,
    steps = [],
    command,
    console_path = '',
    aws_console_steps = [],
    document,
    note,
    remediation,
    onClickAutoRemediation,
    autoRemediate,
    setAutoRemediate,
  } = props;
  const helpDisclosure = useDisclosure();

  //set auto remediate switch value
  useEffect(() => {
    setAutoRemediate?.(remediation?.is_execution_enable);
  }, [remediation, setAutoRemediate]);

  const renderAutoRemediationSwitch = () => (
    <Switch
      isDisabled={!remediation?.execution}
      isChecked={autoRemediate}
      onChange={e => {
        setAutoRemediate?.(e.target.checked);
        onClickAutoRemediation?.(remediation);
      }}
    />
  );

  const renderInputField = (param, color) => (
    <HStack>
      <H4 minW={250}>{param.variable}</H4>
      <Box
        border="1px solid"
        borderColor={color} //customTheme.colors.gray['200']
        px={3}
        py={1}
        borderRadius="3px"
        w="full"
        bg="white"
        minH={8}
      >
        {param.key}
      </Box>
    </HStack>
  );

  return (
    <Stack px={4} py={3} spacing={4}>
      <HStack spacing={4}>
        <Center color={'primary'}>
          <AriksaIcon
            type={IconTypes.CommandLine}
            size={'regular'}
            bg={'primary'}
          />
        </Center>
        <H3>{type}</H3>
      </HStack>
      <Stack pl={10}>
        {!!steps.length && (
          <Box w={'full'} py={4}>
            <OrderedList spacing={2}>
              {steps.map((el, index) => (
                <ListItem key={index + '-remediation-command'}>
                  <Text>{el}</Text>
                </ListItem>
              ))}
            </OrderedList>
          </Box>
        )}

        {command && (
          <HStack>
            <Box
              w="full"
              border="1px solid"
              borderColor={customTheme.colors.gray['100']}
              px={3}
              py={2}
              bg="white"
              color={
                type === 'Command'
                  ? remediation?.remediate_input_prams?.length
                    ? 'red'
                    : customTheme.colors.green['300']
                  : 'primary'
              }
              borderRadius={6}
            >
              {command}
            </Box>
            <Box>
              <CopyToClipboardIconButton value={command} size="xs" />
            </Box>
          </HStack>
        )}
        {document && (
          <Stack spacing={4} pt={4}>
            <H3>Policy Document</H3>
            <Box p={2} bg={'#fff'}>
              <SyntaxHighlight language={'json'}>
                {json(JSON.parse(document))}
              </SyntaxHighlight>
            </Box>
          </Stack>
        )}
        {note && (
          <Stack spacing={4} pt={4}>
            <H3>Note</H3>
            <Box>
              <Text>{note ?? '-'}</Text>
            </Box>
          </Stack>
        )}

        <Stack spacing={8}>
          {aws_console_steps && <AwsConsoleSteps steps={aws_console_steps} />}
          {console_path && (
            <Box>
              <Text>Follow the link for more details</Text>
              <Link
                target="_blank"
                href={console_path.toString()}
                color={'primary'}
              >
                {console_path}
              </Link>
            </Box>
          )}
        </Stack>
        {type === 'Command' && (
          <Stack spacing={4} pr={8}>
            {map(remediation?.remediate_prams, (param: any) =>
              renderInputField(
                param,
                !!param.key ? customTheme.colors.green['300'] : 'red',
              ),
            )}
            {map(remediation?.remediate_input_prams, (param: any) =>
              renderInputField(param, 'red'),
            )}
            <HStack spacing={6} pt={12}>
              <HStack>
                <H4>Automatically remediate this issue:</H4>
                {remediation?.execution ? (
                  renderAutoRemediationSwitch()
                ) : (
                  <CustomTooltip
                    label={remediation?.disable_remediation_msg}
                    w={48}
                  >
                    {renderAutoRemediationSwitch()}
                  </CustomTooltip>
                )}
              </HStack>
              <PrimaryButton
                leftIcon={<LearnMoreIcon w={5} h={5} color="primary" />}
                color={customTheme.colors.gray['300']}
                _hover={{}}
                _active={{}}
                _focus={{}}
                onClick={() => helpDisclosure.onOpen()}
              >
                Learn more
              </PrimaryButton>
            </HStack>
          </Stack>
        )}
      </Stack>
      {helpDisclosure.isOpen && (
        <AutomatedRemediationOfIssuesHelp {...helpDisclosure} />
      )}
    </Stack>
  );
};
