import React from 'react';

import { Icon } from '@chakra-ui/react';

export const Ec2LaunchTemplateIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <g>
          <path
            d="M173.2,101.4H80.9c-6.4,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6h92.4c6.4,0,11.6-5.2,11.6-11.6
				C184.8,106.5,179.6,101.4,173.2,101.4z M381.1,98.5c-8,0-14.4,6.5-14.4,14.4c0,8,6.5,14.4,14.4,14.4c8,0,14.4-6.5,14.4-14.4
				C395.6,104.9,389.1,98.5,381.1,98.5z M311.9,98.5c-8,0-14.4,6.5-14.4,14.4c0,8,6.5,14.4,14.4,14.4c8,0,14.4-6.5,14.4-14.4
				C326.3,104.9,319.8,98.5,311.9,98.5z M381.1,237.1c-8,0-14.4,6.5-14.4,14.4c0,8,6.5,14.4,14.4,14.4c8,0,14.4-6.5,14.4-14.4
				C395.6,243.6,389.1,237.1,381.1,237.1z M462,136V89.8c0-31.9-25.9-57.7-57.7-57.8H57.7C25.9,32.1,0,57.9,0,89.8V136
				c0,19,9.3,35.7,23.4,46.2C9.3,192.7,0,209.4,0,228.4v46.2c0,19,9.3,35.7,23.4,46.2C9.3,331.4,0,348.1,0,367.1v46.2
				C0,445.2,25.9,471,57.7,471h243.4c-4.2-7.3-7.7-15-10.6-23.1H57.7c-19.1,0-34.6-15.5-34.6-34.7v-46.2c0-19.1,15.5-34.6,34.6-34.7
				h243.4c4.8-8.3,10.4-16,16.7-23.1H57.7c-19.1,0-34.6-15.5-34.6-34.7v-46.2c0-19.1,15.5-34.6,34.6-34.7h346.5
				c19.1,0,34.6,15.5,34.6,34.7v36c7.9,1.1,15.7,2.7,23.1,5v-41.1c0-19-9.3-35.7-23.4-46.2C452.7,171.7,462,155,462,136z M438.9,136
				c0,19.1-15.5,34.6-34.6,34.7H57.7c-19.1,0-34.6-15.5-34.6-34.7V89.8c0-19.1,15.5-34.6,34.6-34.7h346.5
				c19.1,0,34.6,15.5,34.6,34.7V136z M80.9,240c-6.4,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6h92.4c6.4,0,11.6-5.2,11.6-11.6
				c0-6.4-5.2-11.6-11.6-11.6H80.9z M80.9,378.6c-6.4,0-11.6,5.2-11.6,11.6c0,6.4,5.2,11.6,11.6,11.6h92.4c6.4,0,11.6-5.2,11.6-11.6
				c0-6.4-5.2-11.6-11.6-11.6H80.9z M311.9,237.1c-8,0-14.4,6.5-14.4,14.4c0,8,6.5,14.4,14.4,14.4c8,0,14.4-6.5,14.4-14.4
				C326.3,243.6,319.8,237.1,311.9,237.1z"
          />
        </g>
      </g>
      <g>
        <path
          d="M407.3,480l-14.8-4.5l46.7-154.8l14.8,4.5L407.3,480z M467.6,444.5l-10.9-11l33.4-33.2l-33.4-33.2l10.9-11
			l44.4,44.2L467.6,444.5z M378.9,444.5l-44.4-44.2l44.4-44.2l10.9,11l-33.4,33.2l33.4,33.2L378.9,444.5z"
        />
      </g>
    </g>
  </Icon>
);
