import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DebianIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <circle fill="#D70751" cx="256" cy="256" r="256" />
    <g>
      <path
        fill="#FFFEFE"
        d="M342.4,107.6c4.7,2.4,8.9,5.5,12.8,9c3.6,3.2,7.4,6.1,10.7,9.7c10,10.8,18.2,22.7,23.2,37
		c-0.5,0.3-1.1,0.7-1.7,1.1c0.2,3.5,0.4,7.1,0.7,10.6c-0.3,0.1-0.6,0.2-0.9,0.2c-1.9-3.7-3.9-7.4-5.7-11c-1.5,0.4-2.5,0.7-3.6,0.8
		c-1.1,0.1-2.2,0-3.6,0c-0.3,3.9-0.1,7.3,2.9,10c0.6-0.5,1-0.8,1.7-1.3c3.4,5.2,3.3,11.3,4.3,17.1l-0.3,0.2
		c-2.3,0.6-1.3-2.4-3.3-2.7c0,4.7,2.4,8.3,4.4,12.3c-1.6,3.1-0.9,6.6-0.8,10c0.1,2.4,2.3,2.3,3.9,2.8c-1,3.3-2.2,6.3-3,9.5
		c-0.7,3-1.1,6.2-1.6,9.1c-0.5-0.8-1.2-1.9-1.9-2.9c-0.6,1.6-1.2,3.2-1.9,4.7c-0.4,6.5-2.9,12.4-5.7,18.3c-2,4.3-3.7,8.7-5.7,13.3
		c-0.7-0.7-1.5-1.6-2.6-2.7c-4.7,3.7-4.6,9-4.4,14.2c-0.3-0.1-0.7-0.1-1-0.2c-0.1-0.7-0.3-1.3-0.4-2.1c-0.2,0-0.6-0.1-0.7,0
		c-2.3,2.1-4.6,4.3-6.9,6.5c-11.2,10.4-23.8,18.3-38.8,22.1c-15.8,4-31.5,3.3-47-1.9c-1.9-0.6-3.8-1.1-5.8-1.7
		c-0.6-0.7-1.1-1.8-2-2.5c-7.2-5.5-14.5-11-20.5-17.8c-5.2-6-10.1-12.4-12.4-20.3c-2.5-9-4.4-18-3-27.4c0.4-2.5,0.3-5,0.4-6.5
		c2.2-5.4,4.1-9.8,5.9-14.4c1.7-4.3,3.5-8.3,7.4-11.2c1.9-1.4,3-3.8,4.2-5.9c2.4-4.3,6.4-7,10.4-9.6c9.5-6.1,20.1-8.1,31.3-7.1
		c6,0.6,11.7,2.4,16.8,5.6c3.6,2.2,7.1,4.5,10.6,7.9c-1.1,0.2-1.8,0.5-2.1,0.3c-11-7.1-23-8.3-35.7-6c-9.6,1.7-17.7,6.4-24.6,12.7
		c-7.2,6.5-11.7,15.1-14,24.5c-0.9,3.7-1.1,7.5-1.4,11.3c-0.2,2.2,0.2,4.4,1.2,6.6c1.3,3,1.3,6.5,1.9,9.7c2.1,11,7.6,20.1,15.3,28.1
		c5.8,6,12.5,10.9,20.1,14c9.1,3.7,18.6,5.6,28.6,3.6c3.4-0.7,6.7-2.5,10-2.7c4.1-0.3,7.2-2.1,10.5-3.7c2.3-1.1,4.1-3.2,6.1-4.8
		c0.5,0.4,0.9,0.8,1.7,1.4c8.6-5.5,16.3-12.3,23.9-20.1c-0.2-5.1-0.6-10.9,6.6-12.5c2.4-6.3,5.6-12.1,5.7-18.1
		c-0.5-1.9-1.4-3.3-1.2-4.5c1.4-7.6,0.7-15.3,0.7-22.9c0-1.2,0-2.3,0-3.5c0-2.5,0.3-4.9-1-7.2c-0.5-0.8-0.5-1.9-0.6-2.9
		c-1.5-15.4-8.2-28.6-17.1-40.8c-5.9-8.1-13.1-15.3-21.2-21.5c-10.5-8-22.4-12.6-35.4-14.6c-6.7-1-13.5-1.9-20.3-1.7
		c-3.1,0.1-6.3,0.6-9.4,0.8c-4.5,0.4-9.1,0.4-13.6,1c-4.6,0.6-8.8,2.1-12.8,4.7c-3.8,2.4-8.2,3.8-12.4,5.7c-0.7,0.3-1.3,0.8-2.9,1.7
		c2.6,0,4.2,0,5.8,0c0,0.3,0.1,0.6,0.1,0.9c-3.3,0.6-6.7,1.5-10,1.9c-7,0.8-12.4,3.9-16.3,9.7c-0.5,0.3-1,0.6-1.5,0.9
		c0.1,0.2,0.2,0.4,0.3,0.6c0.6-0.3,1.1-0.5,1.7-0.8c0.5,0,0.9,0.1,2,0.1c-1,1.1-1.8,1.8-2.5,2.6c-6.4,0.2-11.3,3.6-16,7.1
		c-8.1,5.9-14.6,13.5-21.5,20.6c-3.4,3.6-6,8-9.1,12.1c1.1,0.4,1.8,0.7,3.1,1.2c-2.7,5.3-5.3,10.5-8,15.6
		c-3.3,6.1-4.9,12.4-5.3,19.3c-0.5,8.3-1.8,16.5-2.5,24.8c-0.7,8.5-0.9,17,1,25.3c0.6,2.5,2,4.8,3.4,7.9c-0.2,2.2-0.5,5.2-0.9,8.2
		c1,2,2,3.9,2.9,5.8c-1.7,6.5,2.7,10.9,5.1,16.3c10.7,0.4,12.8,8.9,16.2,16.8c-3.2,0.3-3.3-3-5.8-3.4c0.9,6.4,5.3,10.8,8.2,16.2
		c-0.4,0.6-0.8,1.3-1.2,2.1c1.1,3.1,1.1,3.1,4.3,6.3c0.2,1.7,0,3.3,0.7,4.4c2.5,4.2,5.3,8.2,8,12.3c0.6,0.9,1.2,1.9,2,2.8
		c1.5,1.7,2.8,3.6,5.3,3.8c1.4,5.1,4.9,10.6,9.2,14.6c1.3,0,2.8,0,4.1,0c2.3,2.5,4.4,4.8,6.6,7c2.1,2.1,4.1,4.3,7.5,4.3
		c-0.7,1.4-1.2,2.6-1.8,3.7c0.2,0.3,0.3,0.7,0.5,0.8c2.9,1.7,5.9,3.5,8.9,5.1c2.4,1.3,4.9,2.6,7.4,3.6c0.8,0.3,1.9,0,2.7-0.1
		c2.9,2.6,6,5.1,8.8,7.8c4.3,4.3,10,5.4,15.6,6.8c5.3,1.3,10.7,2.1,16,3.1c0.3,0.1,0.6,0.3,1.6,1c-6.3,0.3-11.9,0.3-17.4-0.4
		c-15.3-1.9-29.7-6.9-43.2-14.3c-9.1-5-17.7-10.9-25.8-17.6c-7.7-6.4-14.6-13.6-21.5-20.7c-4.1-4.2-7.4-9.1-10.9-13.9
		c-8.4-11.3-15.1-23.7-20.7-36.6c-5.2-12-8.7-24.5-11.2-37.4c-3.4-17.3-2.7-34.7-2.6-52.2c0-4.8,0.4-9.6,0.6-14.4
		c0-1.2,0.3-2.4-0.1-3.5c-1.7-5.3-0.2-10.1,1.4-15c2.9-9,5.8-18.1,9.8-26.7c1.2-2.7,2.3-5.4,3.5-8.1c4-0.8,6-3.9,7.5-7.2
		c1.8-3.9,3.3-7.8,5-11.7c0.8-1.8,1.3-3.7,3.4-4.6c0.7-0.3,1.3-1.5,1.5-2.4c0.8-3,2.6-5.2,4.7-7.4c5.1-5,10-10.2,15.2-15.2
		c2.8-2.7,6-5.1,9.1-7.8c0.3,0.2,0.8,0.4,1.6,0.8C196.6,99.5,215,92.5,232.9,85c5.5,2.8,10.6-1.5,16.7-0.8c-2.1,1.3-3.6,2.2-4.8,3
		c4.8,0.3,9.8,0.5,15.2,0.8c-0.9,0.8-1.4,1.2-2.2,1.9c3.2,0,6.2,0.5,9-0.1c4.5-0.9,8.8-1.2,13.4,0c0.5-1,0.9-1.8,1.4-2.6
		c0.3,0,0.7,0,1,0.1c4.1,1.4,8.1,2.8,12.2,4.3c5.6,2.1,11.4,4.1,16.8,6.7c3.4,1.7,6.6,2.4,10,1.4c3.2-1,6.2-0.6,9.4,0.6
		c2.5,4.2,6.1,7.3,10.8,9.5c0.1-0.8,0.1-1.2,0.2-1.7C342.1,107.9,342.2,107.8,342.4,107.6z"
      />
      <path
        fill="#FFFEFE"
        d="M244,293c2.6,1.7,5.2,3.5,7.7,5.2c2.5,1.7,5,3.1,8.1,3c5.8,7.2,14.1,8.7,22.5,9.8c5.7,0.7,11.5,0.9,17.9,1.4
		c-6.1,2.5-11.9,2.1-17.4,1.3c-9.6-1.4-18.9-4.1-27.4-8.9c-4.9-2.8-9.6-6-14.9-9.4c1.5-0.3,2.4-0.5,3.2-0.6c0-0.5,0-1,0-1.5L244,293
		z"
      />
      <path
        fill="#FFFEFE"
        d="M388.7,176.3c1.2,3.6,2.3,7.3,3.5,10.9C390.3,183.8,388.4,180.5,388.7,176.3z"
      />
      <path
        fill="#FFFEFE"
        d="M233.8,287.2c-2.5-0.6-3.7-2.3-4.1-4.8c0.1-0.2,0.3-0.4,0.4-0.6c1.7,1.4,3.6,2.6,3.9,5.1L233.8,287.2z"
      />
      <path
        fill="#FFFEFE"
        d="M228.3,407.9c-0.4-0.3-0.8-0.6-1.2-0.9c0.2-0.2,0.4-0.4,0.5-0.6C227.9,406.8,228.1,407.3,228.3,407.9z"
      />
      <path
        fill="#FFFEFE"
        d="M309.2,192.6c0-0.4,0-0.7,0-1.1c0,0,0.3-0.1,0.3-0.1c0.1,0.4,0.2,0.7,0.3,1.1
		C309.6,192.5,309.4,192.5,309.2,192.6z"
      />
      <path
        fill="#FFFEFE"
        d="M383,191c0.3,0.5,0.5,1,0.8,1.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1-0.5-0.3-1.1-0.4-1.6L383,191z"
      />
      <path
        fill="#FFFEFE"
        d="M229.7,282.5c-0.5-0.3-1-0.6-1.5-0.9c0.1-0.2,0.2-0.4,0.3-0.6c0.5,0.3,1.1,0.6,1.6,0.9
		C229.9,282.1,229.8,282.3,229.7,282.5z"
      />
      <path
        fill="#FFFEFE"
        d="M241.8,291c-0.5-0.1-1-0.1-1.5-0.2c0-0.2,0-0.3,0-0.5c0.5,0,1,0.1,1.5,0.1C241.8,290.6,241.8,290.8,241.8,291z
		"
      />
      <path
        fill="#FFFEFE"
        d="M239.1,291.9c0.3,0.5,0.7,1.1,1,1.6c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3-0.5-0.5-1-0.8-1.5
		C238.8,292.2,238.9,292.1,239.1,291.9z"
      />
      <path
        fill="#FFFEFE"
        d="M341,107c-0.3-0.1-0.5-0.3-0.8-0.4c0.2-0.1,0.4-0.2,0.5-0.3C340.9,106.5,340.9,106.8,341,107L341,107z"
      />
      <path
        fill="#FFFEFE"
        d="M341.9,108.1c-0.3-0.4-0.6-0.8-0.9-1.1c0,0-0.1,0.1-0.1,0.1c0.5,0.2,0.9,0.4,1.4,0.6
		C342.2,107.8,342.1,107.9,341.9,108.1z"
      />
      <path
        fill="#FFFEFE"
        d="M234.8,288.2c-0.3-0.3-0.7-0.7-1-1l0.3-0.2c0.3,0.3,0.7,0.7,1,1L234.8,288.2z"
      />
      <path
        fill="#FFFEFE"
        d="M235,288c0.3,0.3,0.7,0.7,1,1l-0.3,0.2c-0.3-0.3-0.7-0.7-1-1L235,288z"
      />
      <path
        fill="#FFFEFE"
        d="M236,289c0.3,0.3,0.7,0.7,1,1l-0.3,0.2c-0.3-0.3-0.7-0.7-1-1L236,289z"
      />
      <path
        fill="#FFFEFE"
        d="M237,290c0.4,0.3,0.7,0.6,1.1,0.9c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3-0.4-0.6-0.8-0.9-1.1L237,290z"
      />
      <path
        fill="#FFFEFE"
        d="M238.1,290.9c0.3,0.3,0.7,0.7,1,1c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3-0.3-0.7-0.7-1-1
		C237.8,291.2,237.9,291.1,238.1,290.9z"
      />
      <path
        fill="#FFFEFE"
        d="M243.8,293.2c-0.3-0.3-0.5-0.5-0.8-0.8c0,0,0.2-0.2,0.3-0.3c0.2,0.3,0.5,0.6,0.7,0.8L243.8,293.2z"
      />
    </g>
  </Icon>
);
