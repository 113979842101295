import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VpnGatewayIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <path
          d="M430,188.9H425C424.8,97.5,379.9,0,254,0C149.8,0,87.5,70.6,87.4,188.9H82c-28,0-50.8,22.9-50.8,50.8v221.4
			c0,28,22.9,50.8,50.8,50.8H430c28,0,50.8-22.9,50.8-50.8V239.7C480.9,211.8,458,188.9,430,188.9z M254,76.2
			c62.7,0,94.5,37.9,94.7,112.6H163.6C163.8,76.3,229.3,76.2,254,76.2z M194.2,277.4l59.5-59.5l57.6,59.2l-40.1,0.2v41.2h-34.7
			v-41.1H194.2z M89.9,367.3v-34.7h56.5l0.2-40.1l62.5,57.6l-62.8,59.5v-42.3H89.9z M253.6,482.4l-59.5-59.5h42.3v-41.1h34.7V423
			l40.1,0.2L253.6,482.4z M420.3,367.3h-56.4v42.3l-62.8-59.5l62.5-57.6l0.2,40.1h56.5V367.3z"
        />
      </g>
    </g>
  </Icon>
);
