import React from 'react';

import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';

export function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Box {...wrapperStyle}>
        <Box {...titleStyle}>
          4
          <Box role="img" aria-label="Crying Face" fontSize="3.125rem">
            😢
          </Box>
          4
        </Box>
        <p>Page not found.</p>
      </Box>
    </>
  );
}

const wrapperStyle = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column' as any,
  minH: '320px',
};

const titleStyle = {
  marginTop: '-8vh',
  fontWeight: 'bold',
  color: '',
  fontSize: '3.375rem',
  display: 'flex',
};
