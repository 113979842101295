import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.alertWorkflow || initialState;

export const selectAlertWorkflow = createSelector(
  [selectDomain],
  alertWorkflowState => alertWorkflowState,
);
