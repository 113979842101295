import React, { FC, useState } from 'react';

import { Box, Avatar, Flex, Text, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectApp } from 'containers/App/selectors';

import { Form, FormSchema, renderTime } from '../../../components';
import { form_styles } from '../styles';
import { ProfileProps } from '../types';

export const Profile: FC<ProfileProps> = props => {
  const {
    firstName,
    lastName,
    username,
    createdTimestamp,
    email,
    lastLogin,
    orgName,
    phone,
    source,
    guid,
  } = props;

  const { userAuth } = useSelector(selectApp);

  const phoneNumber = phone?.split('-');
  const [isEdit, setIsEdit] = useState(false);
  const onSubmit = data => {
    // console.log(data);
    setIsEdit(false);
  };
  const onClickEdit = data => setIsEdit(true);
  const onCancel = () => setIsEdit(false);
  const name: FormSchema = isEdit
    ? {
        fullName: {
          type: 'object',
          label: 'Full Name',
          properties: {
            firstName: {
              type: 'text',
              placeholder: 'First Name',
            },
            lastName: {
              type: 'text',
              placeholder: 'Last Name',
            },
          },
          styles: {
            propertyContainer: { w: '50%' },
          },
        },
      }
    : {};

  const renderTimeAndLogin = (label, value) => (
    <HStack w="full">
      <Text color="gray.300">{label}:</Text>
      <Text>{value ?? '-'}</Text>
    </HStack>
  );

  return (
    <Box>
      <Flex pb={3} align="center">
        <Avatar size="md" />
        {!isEdit && (
          <Text pl={4} fontSize={16}>
            {firstName && lastName
              ? `${firstName} ${lastName}`
              : firstName
              ? firstName
              : lastName
              ? lastName
              : 'First Name, Last Name'}
          </Text>
        )}
      </Flex>
      <Form
        schema={{
          ...name,
          userId: {
            type: 'text',
            label: 'User ID',
            isDisabled: !isEdit,
          },
          email: {
            type: 'text',
            label: 'Email ID',
            isDisabled: true,
          },
          phone: {
            type: 'object',
            label: 'Phone',
            properties: {
              countryCode: {
                type: 'react-select',
                options: [
                  { label: '+91', value: '+91' },
                  { label: '+1', value: '+1' },
                ],
                defaultValue: phoneNumber
                  ? { value: phoneNumber[0], label: phoneNumber[0] }
                  : {},
                isDisabled: !isEdit,
              },
              phone: {
                type: 'text',
                placeholder: 'Phone Number',
                //htmlInputType: 'tel',
                isDisabled: !isEdit,
              },
            },
          },
          org_name: {
            type: 'text',
            label: 'Organization',
            isDisabled: true,
          },
          source: {
            type: 'text',
            label: 'Source',
            isDisabled: !isEdit,
          },
          createdTimestamp: {
            type: 'custom',
            component: () => (
              <HStack>
                {renderTimeAndLogin(
                  'Created On',
                  renderTime({ value: createdTimestamp }),
                )}
                {renderTimeAndLogin(
                  'Last Login',
                  !!userAuth.data.sessions?.length
                    ? renderTime({
                        value:
                          userAuth.data.sessions[
                            userAuth.data.sessions?.length - 1
                          ].start_at,
                      })
                    : '-',
                )}
              </HStack>
            ),
          },
        }}
        formOptions={{
          defaultValues: {
            userId: username,
            fullName: {
              firstName,
              lastName,
            },
            guid,
            source: source,
            lastLogin: lastLogin,
            createdTimestamp,
            org_name: orgName,
            email,
            phone: {
              countryCode: phoneNumber && phoneNumber[0],
              phone: phoneNumber && phoneNumber[1],
            },
          },
        }}
        styles={form_styles}
        buttonOptions={{
          submit: {
            name: isEdit ? 'Save' : 'Edit',
          },
          reset: {
            name: 'Cancel',
            isVisible: isEdit,
            onClick: onCancel,
          },
        }}
        handleSubmit={isEdit ? onSubmit : onClickEdit}
      />
    </Box>
  );
};
