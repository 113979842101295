/**
 *
 * Add Okta
 *
 */

import React from 'react';

import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';

import { Form } from 'components/index';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { formStyles } from '../../../styles';

export const AddOkta = () => {
  const { onCancel, onboardAccount } = useIntegrationsContext();
  const handleReset = () => {
    onCancel();
  };
  const handleSubmit = data => {
    const { url, api_token, name } = data;
    onboardAccount(
      {
        cloudAccountCreateRequest: {
          name,
          cloud_type: CloudProviders.Okta,
          okta: { url, api_token },
        },
      },
      AccountType.IdentityProvider,
    );
  };

  return (
    <>
      <Form
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            isRequired: true,
          },
          url: {
            type: 'text',
            label: 'URL',
            isRequired: true,
          },
          api_token: {
            type: 'text',
            label: 'API Token',
            isRequired: true,
            htmlInputType: 'password',
            passwordShow: true,
          },
        }}
        buttonOptions={{
          submit: { name: 'Okay' },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={{ form: { ...formStyles.form } }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
