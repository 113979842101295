import { PageInfo } from '../../components';
import * as fm from '../fetch.pb';
import { AUTH_SERVICE } from '../service_urls';
import { withPrefixArgs, withPrefixNoArgs } from '../utils';

import {
  GroupId,
  GroupName,
  IUpdateGroup,
  RoleId,
  SessionId,
  UserId,
  Username,
} from './types';

const PREFIX = AUTH_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
const withNoArgs = withPrefixNoArgs(PREFIX);

export const authApis = {
  onboard: withNoArgs('/onboarding/'),

  // admin only
  enableOrganization: withArgs<UserId>('/root/verify/'),
  getOrganizations: withArgs<PageInfo>('/root/organizations/all/'),
  createSuperAdmin: withNoArgs('/root/user/'),
  getSuperAdmin: withArgs<UserId>('/root/user/'),
  getAllSuperAdmins: withNoArgs('/root/user/all/'),

  // session
  getUserSessions: withNoArgs('/sessions/'),
  removeUserSession: withArgs<SessionId>('/sessions/'),

  // users
  createUser: withNoArgs('/users/'),
  getUserById: withArgs<UserId>('/users/{user_id}'),
  getUser: withArgs<Username>('/users/'),
  getProfile: withNoArgs('/users/profile/'),
  updateUser: withNoArgs('/users/'),
  removeUser: withNoArgs('/users/'),
  getUsers: withNoArgs('/users/all/'),

  updatePassword: withNoArgs('/users/credentials/'),

  // token
  login: withNoArgs('/token/'),
  logout: withNoArgs('/token/logout'),
  refreshToken: withNoArgs('/token/refresh'),

  // roles
  getRoles: withNoArgs('/roles/'),
  deleteRole: withArgs<RoleId>('/roles/{role_id}'),
  updateRoles: withNoArgs('/roles/'),
  createRole: withNoArgs('/roles/'),
  getRole: withArgs<RoleId>('/roles/{role_id}'),
  removeRole: withArgs<RoleId>('/roles/{role_id}'),
  assignRole: withNoArgs('/roles/assign/'),
  userRoles: withArgs<UserId>('/roles/role/by/{user_id}'),

  // resources
  getResources: withNoArgs('/resources/all/'),

  createPermission: withNoArgs('/roles/permissions/'),
  getPermissions: withNoArgs('/roles/permissions/all/'),

  getGroup: withArgs<GroupId>('/groups/'),
  createGroup: withNoArgs<GroupName>(`/groups/`),
  updateGroup: withNoArgs<IUpdateGroup>(`/groups/`),
  deleteGroup: withArgs<GroupId>('/groups/'),
  getGroupMembers: withNoArgs('/groups/members/{group_id}'),
  getGroups: withNoArgs('/groups/all/'),
};

export function authOptions(): fm.InitReq {
  const access_token = sessionStorage.getItem('authentication');
  // console.log(access_token);
  return {
    pathPrefix: AUTH_SERVICE,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
}
