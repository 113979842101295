import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Grid, HStack, Stack } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getIcon } from 'components/Icons/Components';
import { MetaGridField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridField';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useResourceType } from 'containers/App/hooks/useResourceType';

import {
  AccountID,
  ContainerID,
  DeployedOn,
  DeploymentID,
  DeploymentRegion,
  DeploymentType,
  DeploymentVPC,
  ImageId,
  InternetAccessible,
  IpAddress,
  Namespace,
  Pod,
  Technologies,
} from '../../../Components/MetadataField/MetaGridFields';

export const ContainerMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;
  const { getCloudNativeName } = useResourceType();

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {ContainerID(insight)}
        {ImageId(resource)}
        {AccountID(insight)}
        {DeploymentRegion(insight)}
        {DeploymentVPC(insight)}
        {DeploymentType(resource)}
        {DeployedOn(resource)}

        {/*<MetaGridField
          label={'Deployment ID'}
          custom
          colSpan={1}
          value={() => {
            const value = insight?.deployed_in;console.log(insight);
            const renderValue = (val, type) => {
              return (
                <CustomTooltip
                  label={
                    <Stack>
                      <Box color="orange">{getCloudNativeName(type)}</Box>
                      <Box>{val}</Box>
                    </Stack>
                  }
                >
                  <HStack w="full" spacing={1} align="center">
                    <Box boxSize={6} color="primary">
                      {getIcon(type, 'inherit')}
                    </Box>
                    <Box w="full" textOverflow="ellipsis" overflow="hidden">
                      {val}
                    </Box>
                  </HStack>
                </CustomTooltip>
              );
            };

            return (
              <Stack>
                {(value?.gcpgke || value?.gcpgke_id) &&
                  renderValue(
                    value?.gcpgke ?? value?.gcpgke_id,
                    NativeResources.Gcpgke,
                  )}
                {(value?.eks || value?.eks_id) &&
                  renderValue(
                    value?.eks ?? value?.eks_id,
                    NativeResources.ElasticKubernetesService,
                  )}
                {(value?.ecs || value?.ecs_id) &&
                  renderValue(
                    value?.ecs ?? value?.ecs_id,
                    NativeResources.ElasticContainerService,
                  )}
                {(value?.ec2 || value?.ec2_id) &&
                  renderValue(
                    value?.ec2 ?? value?.ec2_id,
                    NativeResources.Ec2Instance,
                  )}
                {(value?.gce ?? value?.gce_id) &&
                  renderValue(
                    value?.gce ?? value?.gce_id,
                    NativeResources.GcpComputeEngine,
                  )}
              </Stack>
            );
          }}
        />*/}
        {DeploymentID(resource)}
        {Namespace(resource)}
        {Pod(resource)}
        {IpAddress(resource)}
        {InternetAccessible(resource)}
        {Technologies(resource)}
      </Grid>
    </Stack>
  );
};
