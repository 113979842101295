import React from 'react';

import { Icon } from '@chakra-ui/react';

export const FilterIcon = props => (
  <Icon viewBox="0 0 36 36" h="full" w="full" color={'inherit'} {...props}>
    <path d="M33,11H3a1,1,0,0,0,0,2H33a1,1,0,0,0,0-2Z" />
    <path d="M28,17H8a1,1,0,0,0,0,2H28a1,1,0,0,0,0-2Z" />
    <path d="M23,23H13a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
    <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
  </Icon>
);
