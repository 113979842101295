import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OrganizationIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M491.8,86.1L260.2,0.8c-2.7-1-5.7-1-8.4,0L20.2,86.1c-4.8,1.8-8,6.3-8,11.4v85.3c0,6.7,5.4,12.2,12.2,12.2
	c0,0,0,0,0,0h36.6v196.3c-28.4,5.8-48.7,30.8-48.8,59.7v48.8c0,6.7,5.4,12.2,12.2,12.2c0,0,0,0,0,0h463.2c6.7,0,12.2-5.4,12.2-12.2
	c0,0,0,0,0,0V451c0-29-20.4-53.9-48.8-59.7V195h36.6c6.7,0,12.2-5.4,12.2-12.2c0,0,0,0,0,0V97.5C499.8,92.4,496.6,87.8,491.8,86.1z
	 M475.4,451v36.6H36.6V451c0-20.2,16.4-36.5,36.6-36.6h365.7C459.1,414.5,475.4,430.9,475.4,451z M85.3,390.1V195h73.1v195.1H85.3z
	 M182.9,390.1V195h146.3v195.1H182.9z M353.5,390.1V195h73.1v195.1H353.5z M475.4,170.7H36.6V106L256,25.2L475.4,106V170.7z"
    />
  </Icon>
);
