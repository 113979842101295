/**
 *
 * Service Now Integration Form
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { find, map } from 'lodash';
import { useSelector } from 'react-redux';

import { Form, FormSchema } from 'components/index';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DUMMY_API_KEY } from 'containers/Setup/Integrations/types';

import { selectIntegrations } from '../../../selectors';
import { formStyles } from '../../../styles';

interface Props {
  handleSubmit: (data: any) => void;
  validate?: boolean;
}

export const ServiceNowForm: FC<Props> = props => {
  const { handleSubmit, validate } = props;
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();
  const { client, clientInfo } = useSelector(selectIntegrations);

  //const [isHelpOpen, setIsHelpOpen] = useState(false);
  //const toggleIsHelpOpen = () => setIsHelpOpen(!isHelpOpen);
  const [userOptions, setUserOptions] = useState<Record<string, any>[]>([]);
  const [selectedUser, setSelectUser] = useState<Record<string, any>>({});
  const [isEdit, setIsEdit] = useState(actionType === 'Update');

  //set isEdit true if opened in edit
  useEffect(() => {
    setIsEdit(actionType === 'Update');
  }, [actionType]);

  //set list of projects and users
  useEffect(() => {
    const { data: clientInfoData } = clientInfo;
    setUserOptions(
      map(clientInfoData?.service_now, o => ({
        value: o,
        label: o.username,
      })),
    );
  }, [clientInfo]);

  //in case of update, set the selected user and project
  useEffect(() => {
    setSelectUser(
      find(
        userOptions,
        o => o?.value?.username === currentRecord?.service_now?.assignee,
      ) || userOptions[0],
    );
  }, [client, userOptions, currentRecord]);

  const users: FormSchema = validate
    ? {
        assignee: {
          type: 'react-select',
          label: 'Default Assignee',
          isRequired: true,
          options: userOptions,
          isLoading: clientInfo.isLoading,
          value: selectedUser,
        },
      }
    : {};

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          defaultValue: currentRecord?.name ?? '',
        },
        user: {
          type: 'text',
          label: 'User',
          isRequired: true,
          isDisabled: isEdit,
          defaultValue: currentRecord?.service_now?.user ?? '',
        },
        client_id: {
          type: 'text',
          label: 'Client ID',
          isRequired: true,
          isDisabled: isEdit,
          defaultValue: currentRecord?.service_now?.client_id ?? '',
        },
        client_secret: {
          type: 'text',
          label: 'Client Secret',
          isRequired: true,
          htmlInputType: 'password',
          passwordShow: true,
          isDisabled: isEdit,
          defaultValue: isEdit ? DUMMY_API_KEY : '',
        },
        instance: {
          type: 'text',
          label: 'Instance',
          isRequired: true,
          isDisabled: isEdit,
          defaultValue: currentRecord?.service_now?.instance ?? '',
        },
        instance_password: {
          htmlInputType: 'password',
          passwordShow: true,
          type: 'text',
          label: 'Instance password',
          isRequired: true,
          isDisabled: isEdit,
          defaultValue: isEdit ? DUMMY_API_KEY : '',
        },
        ...users,
      }}
      showHelp
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: isEdit ? 'Update' : validate ? 'Okay' : 'Get Assignee',
          isLoading: clientInfo.isLoading || client.isLoading,
          isDisabled: clientInfo.isLoading || client.isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: clientInfo.isLoading || client.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
