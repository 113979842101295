import {
  MaliciousEgressResponse,
  NetworkApiGetResourceMaliciousEgressRequest,
  NetworkApiGetResourcePortUsageRequest,
  PortUsageResponse,
} from '@ariksa/network-analysis/api';

import { NetworkAssessmentService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useNetworkServiceResourcePortUsage() {
  const resourcePortUsage = useAriksaQuery<
    NetworkApiGetResourcePortUsageRequest,
    PortUsageResponse
  >({
    queryKey: [],
    fetcher: req =>
      NetworkAssessmentService.Network.getResourcePortUsage(req).then(
        d => d.data,
      ),
    retry: false,
  });

  return { resourcePortUsage };
}

export function useNetworkResourceMaliciousEgress(accountId, resourceId) {
  const resourceMaliciousEgress = useAriksaQuery<
    NetworkApiGetResourceMaliciousEgressRequest,
    MaliciousEgressResponse[]
  >({
    queryKey: [accountId, resourceId],
    queryFn: () => {
      console.log('useNetworkResourceMaliciousEgress');
      return NetworkAssessmentService.Network.getResourceMaliciousEgress({
        accountId,
        resourceId,
      }).then(d => d.data);
    },
    retry: false,
    gcTime: 0,
  });

  return { resourceMaliciousEgress };
}
