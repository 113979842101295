import React, { createContext, useContext } from 'react';

import {
  AppSchemasSearchModelsRelationship,
  Ordering,
  Path,
  SourceItems,
} from '@ariksa/inventory-core/api';
import { DndContext } from '@dnd-kit/core';

import { AGraphNodeProps } from 'components/Visualization/PixiGraph/core/Node';

export interface PostureGraphContextProps {
  paths: Path[];
  edges: AppSchemasSearchModelsRelationship[];
  ordering: Ordering[];
  nodes: AGraphNodeProps[];
  source_items: SourceItems[];

  // api call status
  isLoading: boolean;
  isLoaded: boolean;
}

export const PostureGraphContext = createContext<PostureGraphContextProps>({
  nodes: [],
  edges: [],
  paths: [],
  ordering: [],
  source_items: [],
  isLoading: false,
  isLoaded: false,
});

interface PostureGraphContextProviderProps {
  value: PostureGraphContextProps;
  children: React.ReactNode;
}

export const PostureGraphContextProvider = (
  props: PostureGraphContextProviderProps,
) => {
  return (
    <DndContext>
      <PostureGraphContext.Provider value={props.value}>
        {props.children}
      </PostureGraphContext.Provider>
    </DndContext>
  );
};

export const usePostureGraphContext = () => useContext(PostureGraphContext);
