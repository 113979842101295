import React, { FC } from 'react';

import { Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { colorMap } from 'theme';
import { colors } from 'theme/theme2/colors';

import { TableAlerts } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/TableAlerts';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectVisibility } from 'containers/Visibility/selectors';
import { MapType } from 'containers/Visibility/types';
import { limitedString } from 'utils/string';

interface MapsHeaderProps {
  map: 'Resource' | 'User' | 'ResourceUser' | 'Role' | MapType;
  name: string;
  role_policies?: string;
  alerts: any;
  unused_roles?: string;
  unused_policies?: string;
  excess_permissions?: string;
  id?: string;
  trusted_entities?: number;
  isPrivileged?: boolean | undefined;
  policies_count?: any;
  users?: number;
  accounts?: number;
  permissions?: number;
  groupsName?: any;
  roles?: any;
  machines?: any;
}

export const TextComponent = props => {
  const { withTooltip, value } = props;

  if (withTooltip) {
    return (
      <Tooltip label={value} hasArrow placement="top">
        <Text>{props.children}</Text>
      </Tooltip>
    );
  }
  return <Text {...props}>{props.children}</Text>;
};

const BoxComponent = props => {
  return (
    <Box
      bg={colorMap.primary(500)}
      color={colors.white}
      p="1"
      px="2"
      borderRadius="4"
      {...props}
    >
      {props.children}
    </Box>
  );
};

const TooltipComponent = props => {
  const { value } = props;
  const val1 = value.split('|')[0];
  const val2 = value.split('|')[1];
  const { mapToCloudId } = useCloudAccountId();

  return (
    <Flex>
      <Tooltip label={val1} hasArrow placement="top">
        <Text color={colorMap.primary(500)}>
          {val1 && `${val1.slice(0, 15)} ${val1.length > 15 ? '...' : ''}`}
        </Text>
      </Tooltip>
      <Text color={colorMap.primary(500)}>
        &nbsp;|&nbsp;{mapToCloudId([val2]).join('')}
      </Text>
    </Flex>
  );
};

export const MapsHeader: FC<MapsHeaderProps> = props => {
  const {
    map,
    name,
    role_policies,
    alerts,
    unused_roles,
    unused_policies,
    excess_permissions,
    id,
    trusted_entities,
    isPrivileged,
    policies_count,
    users,
    accounts,
    permissions,
    groupsName,
    roles,
    machines,
  } = props;

  const { resourceList } = useSelector(selectVisibility);
  const { currentRecord } = resourceList;

  const isResourceMap = [
    MapType.ResourceExcessPermissions,
    MapType.ResourceNetAccess,
    MapType.ResourceBehavior,
    MapType.ResourceConnectedEntities,
  ].includes(map as MapType);

  return (
    <>
      <Flex
        w="full"
        border="1px solid"
        borderColor={colorMap.primary(500)}
        borderRadius={4}
        py={1}
        px={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <TooltipComponent value={name} />

        {map === 'Resource' && (
          <>
            <TextComponent>ID: {id}</TextComponent>
            <TextComponent>Roles/Policies: {role_policies}</TextComponent>
          </>
        )}
        {map === 'User' && (
          <>
            <Flex alignItems="center" justifyContent="center">
              <TextComponent>
                Groups:{' '}
                {groupsName &&
                  groupsName.map((el, idx) => {
                    if (idx > 2) {
                      return null;
                    }

                    if (idx === groupsName.length - 1) {
                      return <Box as="span">{el}</Box>;
                    }
                    return <Box as="span">{el}, </Box>;
                  })}
                {isEmpty(groupsName) && '-'}
              </TextComponent>
              {!isEmpty(groupsName) && groupsName.length > 2 && (
                <Tooltip hasArrow placement="top" label={groupsName.toString()}>
                  <Box
                    color={colorMap.primary(500)}
                    h="18px"
                    w="18px"
                    border="1px solid"
                    borderColor={colorMap.primary(500)}
                    borderRadius="10px"
                    d="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                  >
                    +
                  </Box>
                </Tooltip>
              )}
            </Flex>
            <TextComponent>Roles/Policies: {role_policies}</TextComponent>
          </>
        )}

        {(map === 'ResourceUser' || isResourceMap) && (
          <>
            <TextComponent value={id} withTooltip={true}>
              ID: {limitedString(id ?? '')}
            </TextComponent>
            <TextComponent>Roles/Policies: {role_policies}</TextComponent>
          </>
        )}

        {map === 'Role' && (
          <>
            <TextComponent>
              Privileged: {isPrivileged ? 'Yes' : 'No'}
            </TextComponent>
            <TextComponent>
              Trusted Identities:{' '}
              {(trusted_entities && Object.keys(trusted_entities).length) || 0}
            </TextComponent>
            <TextComponent>Policies: {policies_count || 0}</TextComponent>
          </>
        )}

        <Flex alignItems="center">
          Alerts: &nbsp;
          <TableAlerts
            severities={alerts}
            uuid={currentRecord?.uuid}
            resourceType={currentRecord.resource_type}
            resourceName={currentRecord.name}
            resourceId={currentRecord.resource_id}
          />
        </Flex>

        {isResourceMap && (
          <>
            {map === MapType.ResourceExcessPermissions && (
              <HStack spacing={1}>
                <BoxComponent>
                  Unused Policies: {unused_policies || `- / -`}
                </BoxComponent>
                <BoxComponent>
                  Excess Permissions: {excess_permissions || `- / -`}
                </BoxComponent>
              </HStack>
            )}

            {(map === MapType.ResourceNetAccess ||
              map === MapType.ResourceBehavior ||
              map === MapType.ResourceConnectedEntities) && (
              <HStack spacing={1}>
                <BoxComponent>Users: {users ?? '-'}</BoxComponent>
                <BoxComponent>Machines: {machines ?? '-'}</BoxComponent>
                <BoxComponent>Roles: {roles ?? '-'}</BoxComponent>
              </HStack>
            )}
          </>
        )}

        {/*TODO: check for each map separately to decide what to show*/}
        {!isResourceMap && (
          <>
            {map !== 'Resource' && (
              <HStack spacing={1}>
                {map !== 'Role' && (
                  <BoxComponent>Unused Roles: {unused_roles}</BoxComponent>
                )}
                <BoxComponent>Unused Policies: {unused_policies}</BoxComponent>
                <BoxComponent>
                  Excess Permissions: {excess_permissions || `0 / 0`}
                </BoxComponent>
              </HStack>
            )}

            {map === 'Resource' && (
              <HStack spacing={1}>
                <BoxComponent>Users: {users ?? '-'}</BoxComponent>
                <BoxComponent>Accounts: {accounts ?? '-'}</BoxComponent>
                <BoxComponent>Permissions: {permissions ?? '-'}</BoxComponent>
              </HStack>
            )}
          </>
        )}
      </Flex>
    </>
  );
};
