import React from 'react';

import {
  Box,
  Stack,
  Circle,
  GridItem,
  Wrap,
  WrapItem,
  Center,
  Divider,
} from '@chakra-ui/react';
import {
  isArray,
  isBoolean,
  isObject,
  map,
  startCase,
  isUndefined,
} from 'lodash';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { getIcon } from 'components/Icons/Components/getIcon';

import { MetadataField } from '../ResourceMetadataDrawer/Components/MetadataField/MetadataField';

export const formatBooleanWithDot = (
  value: boolean | string,
  opposite?: boolean,
  enableLabel?: boolean,
) => {
  return (
    <Stack align="center" isInline>
      <Box h="full">
        <Circle
          size={'10px'}
          bg={
            opposite && value
              ? customTheme.colors.red['400']
              : customTheme.colors.green['300']
          }
        />
      </Box>
      <Box>
        {isBoolean(value) && value
          ? enableLabel
            ? 'Enabled'
            : 'Yes'
          : enableLabel
          ? 'Disabled'
          : 'No'}
      </Box>
    </Stack>
  );
};

export const formatBoolean = (value: boolean, enableLabel?: boolean) =>
  value
    ? enableLabel
      ? 'Enabled'
      : 'Yes'
    : isUndefined(value)
    ? '~'
    : enableLabel
    ? 'Disabled'
    : 'No';

export const getResourceIcon = (resource_type: string) => {
  return (
    <Center p={1} w={8} h={8}>
      {getIcon(resource_type)}
    </Center>
  );
};

export const commonIncludedFields = [
  'uuid',
  'resource_id',
  'resource_type',
  'is_active',
  'name',
  'has_inline_policy',
  'arn',
  'account',
  'created_by',
  'active',
  'security_score',
  'service',
  'logging',
  'monitoring',
  'last_activity',
  'region',
  'tags',
  'failure_event_count',
  'unauthorized_event_count',
  'total_event_count',
  'success_event_count',
  'access_denied_count',
  'inline_policies',
  //'external_access',
];

export const includedFields = {
  Kubernetes: [],
  User: [
    'federated',
    'is_designated_admin',
    'has_console_access',
    'mfa',
    'group_policy_count',
    'group_count',
    'unused_permissions',
    'unused_policies',
    'unused_roles',
    'unused_services',
    'console',
    'has_privileged_role',
    'has_privileged_role',
  ],
  ObjectStorage: ['cross_account_access'],
  Group: ['federated', 'cross_account_access', 'logging', 'monitoring'],
  Credential: [],
};

const resourceIncludedFields = [
  'vpc',
  'subnet',
  'resource_policy_count',
  'cross_account_access',
  'external_access',
];

export const remainingFields = (
  data: Record<string, any>,
  resource_type: string,
) => {
  const fields = new Set([
    ...commonIncludedFields,
    ...(includedFields[resource_type]
      ? includedFields[resource_type]
      : resourceIncludedFields),
  ]);

  return map(
    data,
    (value, key) =>
      !fields.has(key) &&
      (isArray(value) ? (
        <GridItem colSpan={1} key={key + '-metadata-drawer'}>
          <MetadataField
            label={startCase(key)}
            value={
              <Wrap>
                {map(value, o => (
                  <WrapItem>
                    <Tag
                      label={o}
                      styles={{ tag: { bg: 'primary', color: 'white' } }}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            }
          />
        </GridItem>
      ) : isObject(value) ? (
        remainingFields(value, resource_type)
      ) : (
        <GridItem colSpan={1}>
          <MetadataField
            label={startCase(key)}
            value={isBoolean(value) ? formatBoolean(value) : value}
          />
        </GridItem>
      )),
  );
};

export const metadataHeading = label => (
  <Box color="primary" borderColor="primary">
    <Box pb={2}>{label}</Box>
    <Divider />
  </Box>
);
