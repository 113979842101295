import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useInventoryMapService } from 'services/Inventory/useInventoryMapService';

import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const Route53NetworkMapWrapper: FC = () => {
  const { resourceUuid, resourceAccountId } = useSelector(selectActiveResource);
  const { route53NetworkMap } = useInventoryMapService();

  useEffect(() => {
    route53NetworkMap.sync({
      uuid: resourceUuid,
      accountId: resourceAccountId,
    });
  }, [resourceUuid, resourceAccountId, route53NetworkMap]);

  return (
    <>
      <PostureGraphWithContext
        data={route53NetworkMap.data ?? {}}
        isLoading={route53NetworkMap.isLoading}
        isLoaded={route53NetworkMap.isSuccess}
      />
    </>
  );
};
