import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyIcon = props => (
  <Icon viewBox="0 0 512 512" h="full" w="full" color={'inherit'} {...props}>
    <path
      d="M166.4,179.2h51.2c7.1,0,12.8-5.7,12.8-12.8c0-7.1-5.7-12.8-12.8-12.8h-51.2c-7.1,0-12.8,5.7-12.8,12.8
	C153.6,173.5,159.3,179.2,166.4,179.2z M459.8,161.5c-0.6-1.6-1.6-3-2.8-4.1L303.5,3.8c-1.2-1.2-2.6-2.1-4.1-2.8
	c-1.5-0.6-3.2-1-4.9-1H128C85.6,0,51.2,34.4,51.2,76.8v358.4c0,42.4,34.4,76.8,76.8,76.8h256c42.4,0,76.8-34.4,76.8-76.8V166.4
	C460.8,164.7,460.5,163,459.8,161.5z M307.2,43.7l109.9,109.9h-75.8c-18.8,0-34.1-15.3-34.1-34.1V43.7z M435.2,435.2
	c0,28.3-22.9,51.2-51.2,51.2H128c-28.3,0-51.2-22.9-51.2-51.2V76.8c0-28.3,22.9-51.2,51.2-51.2h153.6v93.9c0,33,26.8,59.7,59.7,59.7
	h93.9V435.2z M153.6,268.8c0,7.1,5.7,12.8,12.8,12.8h179.2c7.1,0,12.8-5.7,12.8-12.8c0-7.1-5.7-12.8-12.8-12.8H166.4
	C159.3,256,153.6,261.7,153.6,268.8z M345.6,358.4H166.4c-7.1,0-12.8,5.7-12.8,12.8c0,7.1,5.7,12.8,12.8,12.8h179.2
	c7.1,0,12.8-5.7,12.8-12.8C358.4,364.1,352.7,358.4,345.6,358.4z"
    />
  </Icon>
);
