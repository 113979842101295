import { AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest } from '@ariksa/audittrail/api';
import {
  ItemApiApproveRequestRequest,
  ItemApiListAllRequestsRequest,
  PageAccessRequestGetResponse,
} from '@ariksa/jit';
import {
  ItemApiDenyRequestRequest,
  ItemApiGetAssumeRolePolicyRequest, ItemApiToggleAutoApproveRequest,
  ResponseDenyRequestV1JitRequestsDenyRequestIdPost, ToggleAutoApproveResponse,
} from '@ariksa/jit/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Settings container
export const initialState: ContainerState = {
  jitRequests: PagedQueryState.init([]),
  resourceActionPerHour: QueryState.init({}),
  resourceActionOnDay: QueryState.init({}),
  jitAccountAction: QueryState.init(null),
  policyDocument: QueryState.init(null),
  updateAutoApprovals: QueryState.init({} as any),
};

const jitRequestsSlice = createSlice({
  name: 'jitRequests',
  initialState,
  reducers: {
    getJitRequests(
      state,
      action: QueryAction<
        PageAccessRequestGetResponse,
        ItemApiListAllRequestsRequest
      >,
    ) {
      state.jitRequests = PagedQueryState.next(state.jitRequests, action, {
        mapTotalCount: r => r.total || 0,
        mapData: r => r.items,
      });
    },

    updateJitRequestsPagination(state, action: PayloadAction<PageInfo>) {
      state.jitRequests.page.info = action.payload;
    },

    getResourceActionPerHour(
      state,
      action: QueryAction<
        any,
        AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest
      >,
    ) {
      state.resourceActionPerHour = QueryState.next(
        state.resourceActionPerHour,
        action,
      );
    },

    getResourceActionOnDay(state, action: QueryAction<any, any>) {
      state.resourceActionOnDay = QueryState.next(
        state.resourceActionOnDay,
        action,
      );
    },

    approveJitAccount(
      state,
      action: QueryAction<any, ItemApiApproveRequestRequest>,
    ) {
      state.jitAccountAction = QueryState.next(state.jitAccountAction, action);
    },
    denyJitAccount(
      state,
      action: QueryAction<
        ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
        ItemApiDenyRequestRequest
      >,
    ) {
      state.jitAccountAction = QueryState.next(state.jitAccountAction, action);
    },

    getPolicyDocument(
      state,
      action: QueryAction<any, ItemApiGetAssumeRolePolicyRequest>,
    ) {
      state.policyDocument = QueryState.next(state.policyDocument, action);
    },

    updateAutoApprovals(
      state,
      action: QueryAction<
        ToggleAutoApproveResponse,
        ItemApiToggleAutoApproveRequest
        >,
    ) {
      state.updateAutoApprovals = QueryState.next(
        state.updateAutoApprovals,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = jitRequestsSlice;
