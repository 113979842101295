import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state?.sharedState || initialState;

export const selectSharedState = createSelector(
  [selectDomain],
  sharedState => sharedState,
);
