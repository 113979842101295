import React, { useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { ResourceApiGetPolicyDocumentRequest } from '@ariksa/inventory-core/api';
import { AccessRequestGetResponse } from '@ariksa/jit';
import { Box, HStack, Square, Stack } from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { useDispatch, useSelector } from 'react-redux';
import { useQueue } from 'react-use';

import { InventoryService } from 'api/services';
import { Heading4, SyntaxHighlight } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { FilterIcon, PolicyIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { getPausedUntilTime } from 'containers/Setup/CloudAccounts/Components/AccountRediscovery/utils';
import { doGetPolicyDocument } from 'containers/Setup/Jit/saga';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';

import { useAriksaQuery } from '../../../../utils/query/useAriksaQuery';

interface PolicyDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  params: ResourceApiGetPolicyDocumentRequest;
}

// @ts-ignore
const json = value => jsonBeautify(value || {}, null, 2, 10);

export const PolicyDocumentModal = (props: PolicyDocumentModalProps) => {
  const { isOpen, onClose, params } = props;

  const policyDocument = useAriksaQuery<ResourceApiGetPolicyDocumentRequest>({
    queryKey: [params],
    fetcher: async params => {
      const r = await InventoryService.Resource.getPolicyDocument(params);
      return r.data;
    },
  });

  useEffect(() => {
    policyDocument.sync(params);
  }, [params, policyDocument]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <PolicyIcon p={'2px'} />
          </Square>
          <Heading4>Policy Document</Heading4>
        </HStack>
      }
      body={
        <Stack h={400}>
          <WithSpinner loadStatus={{ loading: policyDocument.isLoading }}>
            <SyntaxHighlight language={'json'}>
              {json(policyDocument.data)}
            </SyntaxHighlight>
          </WithSpinner>
        </Stack>
      }
      styles={{
        modal: { size: '3xl' },
      }}
    />
  );
};
