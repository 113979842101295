export const clearFormTextStyle: any = {
  size: 'xs',
  textAlign: 'right',
  color: 'primary',
  cursor: 'pointer',
};

export const tagStyle = {
  tag: { bg: 'primary', color: 'white' },
  label: { color: 'inherit' },
  closeButton: { color: 'inherit' },
};
