import React, { FC, useState } from 'react';

import { Box, useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  formatNumberWithComma,
  renderSeverityBar,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { AddException } from 'containers/Compliance/Components/AddException';
import {
  renderExceptionsHeader,
  renderPassedChecks,
  renderTableHeaderWithTooltip,
} from 'containers/Compliance/Components/ComplianceTables/utils';
import { History } from 'containers/Compliance/Components/History';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';

import { PoliciesSubComponent } from './PoliciesSubComponent';

interface Props {
  subControlRecord?: Record<string, any>;
}

export const PoliciesTable: FC<Props> = props => {
  const { subControlRecord } = props;
  const dispatch = useDispatch();
  const { rules } = useSelector(selectCompliance);
  const {
    renderRulesActions,
    isOpenSeverityModal,
    isOpenHistory,
    isOpenSuppress,
    onCloseSuppress,
    selectedResource,
  } = useCompliance();
  const policiesDrawer = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const [sortByField, setSortByField] = useState<Sorted>({
    sortField: '',
    sortOrder: '',
  });
  const { getResourceAlias, getCloudAgnosticName } = useResourceType();

  const rulesColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Description</Box>,
      accessor: 'description',
      render: ({ value, row }) => (
        <WithResourceIcon
          resourceType={row?.native_resource}
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_resource} />
          }
        >
          <CustomTooltip label={value}>{value}</CustomTooltip>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Type',
      accessor: 'native_resource',
      align: 'left',
      render: ({ value }) => (
        <CustomTooltip label={<ResourceTypeIconTooltip resourceType={value} />}>
          <StackedCell
            upper={getResourceAlias(value)}
            lower={getCloudAgnosticName(value)}
            showUpperTooltip={false}
            showLowerTooltip={false}
          />
        </CustomTooltip>
      ),
      styles: { width: '210px', cell: { maxWidth: '210px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      sortKey: 'severity',
      align: 'left',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Entities',
      accessor: 'check_stats.total_entities',
      render: ({ value }) => formatNumberWithComma(value),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: renderExceptionsHeader,
      accessor: 'exception',
      render: ({ row }) => row?.exception ?? 0,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: renderTableHeaderWithTooltip(
        'Passed policies',
        'Entities that passed this policy check',
        'left',
      ),
      accessor: 'check_stats',
      render: renderPassedChecks,
      styles: { width: '180px', cell: { maxWidth: '180px' } },
    },
    {
      header: 'Actions',
      render: renderRulesActions,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  return (
    <Box h="full" flex={1}>
      <Table
        data={rules.data}
        columns={rulesColumns}
        isLoading={rules.isLoading}
        onRowClick={row => {
          setCurrentRecord(row);
          policiesDrawer.onOpen();
        }}
        cursor="pointer"
        pagination={{
          totalCount: rules.page.totalCount,
          pageInfo: rules.page.info,
          onChange: pageInfo => {
            dispatch(actions.updateRulesPageInfo(pageInfo));
          },
        }}
        onSort={sortInfo => {
          if (!isEqual(sortByField, sortInfo)) {
            setSortByField(sortInfo);
          }
        }}
        sortBy={sortByField}
      />
      {isOpenSeverityModal && !policiesDrawer.isOpen && <SeverityComponent />}
      {isOpenSuppress && !policiesDrawer.isOpen && (
        <AddException
          resource={selectedResource}
          isOpen={isOpenSuppress}
          onClose={onCloseSuppress}
          applyOn="single"
        />
      )}
      {isOpenHistory && !policiesDrawer.isOpen && (
        <History
          control={subControlRecord?.control}
          subControl={subControlRecord?.sub_control}
        />
      )}
      {policiesDrawer.isOpen && (
        <PoliciesSubComponent
          row={currentRecord}
          isOpen={policiesDrawer.isOpen}
          onClose={policiesDrawer.onClose}
          subControl={subControlRecord}
        />
      )}
    </Box>
  );
};
