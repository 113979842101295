import React, { useEffect } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { PermissionComponent } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/Help/PermissionComponent';
import { permissionStyles } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/styles';
import { selectCloudAccountWizard } from 'containers/Setup/CloudAccounts/CloudAccountWizard/selectors';
import { collapseExpandIcon } from 'containers/Visibility/Components/InventoryRowDetails/utils';

import { actions } from '../../../slice';

export const Permissions = () => {
  const dispatch = useDispatch();
  const { templatePermissions } = useSelector(selectCloudAccountWizard);
  const { data, isLoading } = templatePermissions;

  useEffect(() => {
    dispatch(actions.loadTemplatePermissions({}));
  }, [dispatch]);

  const permissionItem = permission => {
    return (
      <AccordionItem border="none" key={permission.name + '-permission'}>
        {({ isExpanded }) => (
          <>
            <AccordionButton {...permissionStyles.button} py={1}>
              {collapseExpandIcon(isExpanded)}
              <Flex {...permissionStyles.label}>{permission?.name}</Flex>
            </AccordionButton>
            <AccordionPanel pb={2} pl={5}>
              <Stack>
                {map(permission.permissions, permission => (
                  <PermissionComponent permission={permission} />
                ))}
              </Stack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  };

  return (
    <Box h="full">
      {isLoading && <CustomSpinner loading={isLoading} />}
      {!isLoading && (
        <Accordion allowMultiple>{map(data, o => permissionItem(o))}</Accordion>
      )}
    </Box>
  );
};
