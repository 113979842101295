import React, { BaseSyntheticEvent, FC, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, SelectFieldSchema, SelectFieldStyles } from '../types';

import { FormLabel } from './FormLabel';

export const SelectField: FC<FieldProps<SelectFieldSchema>> = ({
  id,
  name,
  field,
  style,
}) => {
  const {
    label,
    helperText,
    isRequired,
    helpIcon,
    shouldDisplay,
    onChange,
    isDisabled,
    placeholder,
    helpTooltip,
    styles = {},
    onClickHelpIcon,
  } = field;

  const { register, watch } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<SelectFieldStyles>('selectField', styles);

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnChange = (e: BaseSyntheticEvent) => {
    onChange?.(e.target.value, e);
  };

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Select
        name={name}
        data-testid={id}
        ref={register}
        placeholder={placeholder}
        isDisabled={isDisabled}
        {...fieldStyles.select}
        {...style?.styles}
        onChange={handleOnChange}
      >
        {field.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </Select>
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
