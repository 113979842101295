import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colorMap } from 'theme';
import { colors } from 'theme/theme2/colors';

import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { INGRESS_NODE } from './nodes_types';

export function registerIngressNode() {
  G6.registerNode(
    INGRESS_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 100,
              height: 40,
              fill: colors.white,
              stroke: colorMap.primary(200),
              lineWidth: 0.5,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { width = 0, height = 0 } = container.getBBox();
        const data: any = cfg?.data ?? {};
        const { resource_type: resourceType } = data;

        group.addShape('text', {
          attrs: {
            x: width / 2,
            y: height / 2 + 2,
            textAlign: 'center',
            textBaseline: 'start',
            text: resourceType,
            fontSize: 12,
            fill: colors.gray['400'],
            cursor: 'pointer',
          },
          name: 'id',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
