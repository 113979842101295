/**
 *
 * Organizations
 *
 */

import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { OrganizationsRoot } from './Components/OrganizationsRoot';
import { CreateOrg } from './OrganizationWizard/CreateOrg';
import { EditOrg } from './OrganizationWizard/EditOrg';
import { organizationsSaga } from './saga';
import { reducer, sliceKey } from './slice';

interface Props {}

export const Organizations = memo((props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: organizationsSaga });

  return (
    <Routes>
      <Route path="new-organization" element={<CreateOrg />} />
      <Route path="edit/:org_id" element={<EditOrg />} />
      <Route index element={<OrganizationsRoot />} />
    </Routes>
  );
});
