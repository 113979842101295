import G6 from '@antv/g6';

import { treeEdgeColor } from 'components/Visualization/Graphs/elements/v1/styles';

export const MULTI_EDGE = 'multi-edge';

export function registerMultiConnector() {
  G6.registerEdge(
    MULTI_EDGE,
    {
      setState(name, value, item) {
        const group = item?.get('group');
        const model = item?.get('model');
        if (model.data.active) return;

        // const states = item?.getStates();
        const keyShape = group.find(el => el.get('name') === 'edge-shape');
        const states = item?.getStates();
        const isHovered = states?.includes('hover');
        const isSelected = states?.includes('selected');

        if (keyShape) {
          if (isSelected) {
            keyShape.attr({
              stroke: treeEdgeColor,
              lineWidth: 3,
              opacity: 0.5,
            });
            return;
          }

          if (isHovered) {
            keyShape.attr({
              stroke: treeEdgeColor,
              lineWidth: 3,
              opacity: 0.3,
            });
            return;
          }

          keyShape.attr({
            stroke: '#ddd',
            lineWidth: 1,
            opacity: 1,
          });
        }
      },
      draw(cfg, group) {
        const { data = {} } = cfg ?? ({} as any);
        const startPoint = cfg?.startPoint;
        const endPoint = cfg?.endPoint;

        const { count = 1, total = 1 } = data;

        if (!startPoint?.x || !startPoint?.y) return {} as any;
        if (!endPoint?.x || !endPoint?.y) return {} as any;

        const midPoint = {
          x: (startPoint?.x + endPoint?.x) / 2,
          y: (startPoint?.y + endPoint?.y) / 2,
        };

        // midPoint.x = midPoint.x;
        midPoint.y = midPoint.y + 60 * (count - 1 - total / 2);

        const shape = group?.addShape('path', {
          attrs: {
            stroke: '#ddd',
            path: [
              ['M', startPoint?.x, startPoint?.y],
              ['Q', midPoint.x, midPoint.y, endPoint?.x, endPoint?.y],
            ],
          },
          // must be assigned in G6 3.3 and later versions. it can be any value you want
          name: 'path-shape',
        });

        return shape!;
      },
      afterDraw(cfg, group) {
        const { data = {} } = cfg ?? ({} as any);
        // get the first shape in the graphics group of this edge, it is the path of the edge here
        const shape = group?.get('children')[0];
        // get the coordinate of the mid-point on the path
        const midPoint = shape.getPoint(0.5);

        const text = group?.addShape('text', {
          attrs: {
            x: midPoint.x,
            y: midPoint.y,
            text: data.label ?? '',
            fill: treeEdgeColor,
            fontSize: 12,
            opacity: 1,
            rotate: 10,
            cursor: 'pointer',
          },
          name: 'resource_id',
        });

        text?.rotateAtStart(0.2);
      },
      update: undefined,
    },
    'line',
  );
}
