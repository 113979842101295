/**
 *
 * Service Now Integration
 *
 */

import React, { memo } from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { ServiceNowTable } from './Components/ServiceNowTable';
import { ServicenowWizard } from './Components/ServicenowWizard';

export const ServiceNow = memo(() => {
  const { isOpen } = useIntegrationsContext();

  return <>{isOpen ? <ServicenowWizard /> : <ServiceNowTable />}</>;
});
