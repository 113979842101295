import {
  AuditTrailServiceApiAuditTrailServiceActionsPerformedOnDayRequest,
  AuditTrailServiceApiAuditTrailServiceResourceActionsDailyRequest,
  V1ActionsPerformedOnDayResponse,
  V1ResourceActionsDailyResponse,
} from '@ariksa/audittrail';
import {
  AuditTrailServiceApiAuditTrailServiceActionsPerformedMonthlyRequest,
  AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest,
  V1ActionsPerformedMonthlyResponse,
  V1ResourceActionsMonthlyResponse,
} from '@ariksa/audittrail/api';
import {
  AudittrailApiGetInsecureAclResponseRequest,
  AudittrailApiGetS3CrossAccountRequest,
  AudittrailApiGetS3InternetActivityRequest,
  BlueprintApiAddExceptionRequest,
  BlueprintExceptionRead,
  S3CrossAccountAccess,
  S3InsecureACL,
  S3InternetActivity,
} from '@ariksa/compliance-policies/api';
import {
  DataTypeInfo,
  DataTypesApiGetDataTypesBySourceRequest,
  DistributionByDocumentType,
  ObjectsDistributionCount,
  PageDocumentClassificationForSourceInfo,
  PageFileMetaDataInfo,
  RepositoryBranchesInfo,
  ResourcesApiGetObjectsDistributionCountRequest,
  ResourcesApiGetSummarySourcesCountRequest,
  SourceCountSummary,
  VisibilityDataApiGetDocumentClassificationByTypeForSourceRequest,
  VisibilityDataApiGetDocumentClassificationTypeDistributionForSourceRequest,
  VisibilityDataApiGetFileMetadataInfoRequest,
  VisibilityDataApiGetRepositoryBranchesInfoRequest,
} from '@ariksa/data-scanning/api';
import { ConnectionByProcessPayload } from '@ariksa/ebpf-data-collector/api';
import {
  DeployedResourcePagination,
  IACApiDeployedResourcesRequest,
  IACApiDeploymetSummaryRequest,
} from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextDataScanRequest,
  CrudPermissions,
  InsightApiGetCanAccessActionsRequest,
  InsightApiGetCanAccessRelationSummaryRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  MapApiGetAccessGraphRequest,
  ResourceApiGetResourceByUuidRequest,
  RiskContextResponse,
  SearchResponse,
  SearchV2ApiNetworkMapRequest,
  VulnerabilitiesApiGetInstanceSummaryByIdRequest,
  VulnerabilitiesApiGetVulnerabilityByIdRequest,
  InsightApiGetPrivilegeSummaryRequest,
  PrivilegeSummary,
  AppSchemasIacIacDeploymentSummary,
  AudittrailApiGetResourceActionsRequest,
  ResourceAction,
  ResourceApiGetResourceMetadataRequest,
  ResourceMetadata,
} from '@ariksa/inventory-core';
import {
  ConnectionResponse,
  EbpfApiGetUniqueProcessesRequest,
  ProcessResponse,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertsApiGetAlertsRequest,
  AlertsApiGetEntitySeverityListRequest,
  AlertsApiGetEntityTimelineRequest,
  EntitySeverityListResponse,
  EntityTimelineResponse,
  SnoozedAlertsApiAddSnoozedAlertRequest,
  SnoozeDetailsRead,
} from '@ariksa/notification';
import {
  ContainerInfo,
  DefaultApiGetEcrImageListRequest,
  ECRImageVisibilityDashboard,
  InstanceSummary,
  ItemApiGetContainersRequest,
  ItemApiGetInstanceSummaryByIdRequest,
  ItemApiGetVulnerabilityByIdRequest,
  VulnerabilityResponse,
} from '@ariksa/scan-analysis/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  AuditTrailApiService,
  authTokenHeader,
  ComplianceService,
  DataScanningService,
  EbpfCollector,
  InventoryService,
  NotificationService,
  ScanAnalysis,
} from 'api/index';
import { actions } from 'containers/ActiveCloudResource/slice';
import { doGetRiskContext } from 'containers/SharedState/saga';
import { doGetSensitiveDataLabelCount } from 'containers/Visibility/Data/saga';

export function* activeResourceSaga() {
  yield takeLatestAction(actions.getResourceDetails, doGetResourceDetails);
  yield takeLatestAction(actions.getResourceInsight, doGetResourceInsight);
  yield takeLatestAction(actions.getRiskContext, doGetRiskContext);
  yield takeLatestAction(
    actions.getLinkedResourceDetails,
    doGetLinkedResourceDetails,
  );
  yield takeLatestAction(actions.getAlerts, doGetAlerts);
  yield takeLatestAction(actions.getNetworkMap, doGetNetworkMap);
  yield takeLatestAction(actions.getEntityTimeline, doGetEntityTimeline);
  yield takeLatestAction(
    actions.getVulnerabilitySummary,
    doGetVulnerabilitySummary,
  );
  yield takeLatestAction(
    actions.getAMIVulnerabilitySummary,
    doGetAMIVulnerabilitySummary,
  );
  yield takeLatestAction(
    actions.getVulnerabilityDetails,
    doGetVulnerabilityDetails,
  );
  yield takeLatestAction(
    actions.getAMIVulnerabilityDetails,
    doGetAMIVulnerabilityDetails,
  );
  yield takeLatestAction(actions.getEntitySeverity, doGetEntitySeverity);
  yield takeLatestAction(actions.getPiiByBucketName, doGetPiiByBucketName);
  yield takeLatestAction(actions.getCanAccessSummary, doGetCanAccessSummary);
  yield takeLatestAction(actions.getAccessMapData, doGetAccessMapData);
  yield takeLatestAction(actions.getPermissions, doGetInsecureAclUsage);
  yield takeLatestAction(
    actions.getS3InternetActivity,
    doGetS3InternetActivity,
  );
  yield takeLatestAction(actions.getS3CrossAccount, doGetS3CrossAccount);
  yield takeLatestAction(actions.getInstanceSummary, doGetInstanceSummaryById);
  yield takeLatestAction(actions.getCrudPermissions, doGetCrudPermissions);
  yield takeLatestAction(
    actions.getECRContainerImages,
    doGetECRContainerImages,
  );
  yield takeLatestAction(
    actions.getContainerVulnerability,
    doGetContainerVulnerability,
  );
  yield takeLatestAction(
    actions.getECRContainerImageVulnerability,
    doGetVulnerabilityDetails,
  );
  yield takeLatestAction(actions.getPiiRiskContext, doGetPiiRiskContext);
  yield takeLatestAction(actions.getAlertStatus, doGetAlertStatus);
  yield takeLatestAction(actions.addException, doAddException);
  yield takeLatestAction(actions.snoozeAlert, doSnoozeAlert);
  yield takeLatestAction(actions.getDeploymentSummary, doGetDeploymentSummary);
  yield takeLatestAction(
    actions.getAccountDeployedResources,
    doGetAccountDeployedResources,
  );
  yield takeLatestAction(
    actions.getRepositoryBranches,
    doGetRepositoryBranches,
  );
  yield takeLatestAction(actions.getAccessed, doGetAccessed);
  yield takeLatestAction(
    actions.getActionsPerformedOnDay,
    doGetActionsPerformedOnDay,
  );
  yield takeLatestAction(
    actions.getResourceActionsMonthly,
    doGetResourceActionsMonthly,
  );
  yield takeLatestAction(
    actions.getResourceActionsDaily,
    doGetResourceActionsDaily,
  );
  yield takeLatestAction(
    actions.getGcpResourceDetails,
    doGetGcpResourceDetails,
  );

  //data tab
  yield takeLatestAction(actions.getDataTypesBySource, doGetDataTypesBySource);
  yield takeLatestAction(
    actions.getDocumentTypesBySource,
    doGetDocumentTypesBySource,
  );
  yield takeLatestAction(actions.getDataSummary, doGetDataSummary);
  yield takeLatestAction(actions.getDataLabels, doGetSensitiveDataLabelCount);
  yield takeLatestAction(
    actions.getDistributionByTypeOfFiles,
    doGetDistributionByTypeOfFiles,
  );
  yield takeLatestAction(actions.getRunningProcesses, doGetRunningProcesses);
  yield takeLatestAction(
    actions.getProcessConnections,
    doGetProcessConnections,
  );
  yield takeLatestAction(
    actions.getDistributionByDocumentType,
    doGetDistributionByDocumentType,
  );
}

function* doGetProcessConnections(
  ctx: QueryContext<ConnectionResponse, ConnectionByProcessPayload>,
) {
  yield call(
    ctx.fetch,
    () => EbpfCollector.Default.getConnections(ctx.params),
    { errorMsg: 'Failed to get unique processes!' },
  );
}

function* doGetRunningProcesses(
  ctx: QueryContext<ProcessResponse, EbpfApiGetUniqueProcessesRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Ebpf.getUniqueProcesses(ctx.params),
    { errorMsg: 'Failed to get unique processes!' },
  );
}

function* doGetS3InternetActivity(
  ctx: QueryContext<
    S3InternetActivity[],
    AudittrailApiGetS3InternetActivityRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.AuditTrail.getS3InternetActivity(ctx.params),
    { errorMsg: 'Failed to get internet activity!' },
  );
}

function* doGetS3CrossAccount(
  ctx: QueryContext<
    S3CrossAccountAccess[],
    AudittrailApiGetS3CrossAccountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.AuditTrail.getS3CrossAccount(ctx.params),
    { errorMsg: 'Failed to get cross account information!' },
  );
}

function* doGetPiiByBucketName(
  ctx: QueryContext<
    PageFileMetaDataInfo,
    VisibilityDataApiGetFileMetadataInfoRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getFileMetadataInfo(ctx.params),
    { errorMsg: 'Failed to get sensitive data information!' },
  );
}

export function* doGetPiiRiskContext(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextDataScanRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextDataScan(ctx.params),
    {
      errorMsg: 'Failed to get risk context!',
    },
  );
}

function* doGetAlerts(
  ctx: QueryContext<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlerts(ctx.params),
    { errorMsg: 'Failed to get alerts!' },
  );
}

function* doGetResourceDetails(
  ctx: QueryContext<any, ResourceApiGetResourceByUuidRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getResourceByUuid(ctx.params),
    {
      map: r => r.data.results,
      errorMsg: 'Failed to get resource details!',
    },
  );
}

function* doGetResourceInsight(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    { errorMsg: 'Failed to get resource insights!' },
  );
}

function* doGetLinkedResourceDetails(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    { errorMsg: 'Failed to get resource details!', map: r => r },
  );
}

function* doGetEntityTimeline(
  ctx: QueryContext<
    EntityTimelineResponse[],
    AlertsApiGetEntityTimelineRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getEntityTimeline(ctx.params),
    { errorMsg: 'Failed to get entity timeline!' },
  );
}

function* doGetVulnerabilitySummary(
  ctx: QueryContext<InstanceSummary, ItemApiGetInstanceSummaryByIdRequest>,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Item.getInstanceSummaryById(ctx.params),
    { errorMsg: 'Failed to get vulnerability summary!' },
  );
}

function* doGetAMIVulnerabilitySummary(
  ctx: QueryContext<
    InstanceSummary,
    VulnerabilitiesApiGetInstanceSummaryByIdRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getInstanceSummaryById(ctx.params),
    { errorMsg: 'Failed to get vulnerability summary!' },
  );
}

function* doGetVulnerabilityDetails(
  ctx: QueryContext<VulnerabilityResponse, ItemApiGetVulnerabilityByIdRequest>,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Item.getVulnerabilityById(ctx.params),
    { errorMsg: 'Failed to get vulnerability details!' },
  );
}

function* doGetAMIVulnerabilityDetails(
  ctx: QueryContext<
    VulnerabilityResponse,
    VulnerabilitiesApiGetVulnerabilityByIdRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Vulnerability.getVulnerabilityById(ctx.params),
    { errorMsg: 'Failed to get vulnerability details!' },
  );
}

function* doGetEntitySeverity(
  ctx: QueryContext<
    EntitySeverityListResponse,
    AlertsApiGetEntitySeverityListRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getEntitySeverityList(ctx.params),
    { errorMsg: 'Failed to get alert severity count!' },
  );
}

function* doGetCanAccessSummary(
  ctx: QueryContext<any, InsightApiGetCanAccessRelationSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Insight.getCanAccessRelationSummary(ctx.params),
    { errorMsg: 'Failed to get can access summary!' },
  );
}

function* doGetAccessMapData(
  ctx: QueryContext<SearchResponse, MapApiGetAccessGraphRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Map.getAccessGraph(ctx.params), {
    errorMsg: 'Failed to get access map!',
  });
}

function* doGetInsecureAclUsage(
  ctx: QueryContext<
    S3InsecureACL[],
    AudittrailApiGetInsecureAclResponseRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.AuditTrail.getInsecureAclResponse(ctx.params),
    { errorMsg: 'Failed to get insecure ACL usage for this bucket!' },
  );
}

function* doGetInstanceSummaryById(
  ctx: QueryContext<InstanceSummary, ItemApiGetInstanceSummaryByIdRequest>,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Item.getInstanceSummaryById(ctx.params),
    { errorMsg: 'Failed to get instance summary!' },
  );
}

function* doGetContainerVulnerability(
  ctx: QueryContext<ContainerInfo[], ItemApiGetContainersRequest>,
) {
  yield call(ctx.fetch, () => ScanAnalysis.Item.getContainers(ctx.params), {
    errorMsg: 'Failed to get vulnerabilities!',
  });
}

function* doGetECRContainerImages(
  ctx: QueryContext<
    ECRImageVisibilityDashboard,
    DefaultApiGetEcrImageListRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getEcrImageList(ctx.params),
    { errorMsg: 'Failed to get ECR images!' },
  );
}

/*function* doGetECRContainerImageVulnerability(
  ctx: QueryContext<
    Dict<any>[],
    VulnerabilitiesApiGetImageVulnerabilitiesRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Item.getVulnerabilityById(ctx.params),
    { errorMsg: 'Failed to get vulnerabilities!' },
  );
}*/

function* doGetNetworkMap(
  ctx: QueryContext<SearchResponse, SearchV2ApiNetworkMapRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.SearchServiceV2.networkMap(ctx.params),
    {
      errorMsg: 'Failed to get network map!',
    },
  );
}

function* doGetAlertStatus(
  ctx: QueryContext<AlertLogsResponse[], AlertLogsApiGetLogsForIdsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getLogsForIds(ctx.params),
    { errorMsg: 'Failed to alert status!' },
  );
}

export function* doAddException(
  ctx: QueryContext<BlueprintExceptionRead, BlueprintApiAddExceptionRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.addException(ctx.params),
    { errorMsg: 'Failed to add exception!' },
  );
}

export function* doSnoozeAlert(
  ctx: QueryContext<SnoozeDetailsRead, SnoozedAlertsApiAddSnoozedAlertRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.SnoozedAlerts.addSnoozedAlert(ctx.params),
    { errorMsg: 'Failed to snooze alert!' },
  );
}

export function* doGetRepositoryBranches(
  ctx: QueryContext<
    RepositoryBranchesInfo[],
    VisibilityDataApiGetRepositoryBranchesInfoRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Visibility.getRepositoryBranchesInfo(ctx.params),
    { errorMsg: 'Failed to get repository branches!' },
  );
}

export function* doGetPrivilegeSummary(
  ctx: QueryContext<PrivilegeSummary[], InsightApiGetPrivilegeSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Insight.getPrivilegeSummary(ctx.params),
    { errorMsg: 'Failed to get privilege summary!' },
  );
}

export function* doGetDeploymentSummary(
  ctx: QueryContext<
    AppSchemasIacIacDeploymentSummary[],
    IACApiDeploymetSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Iac.deploymetSummary(ctx.params),
    { errorMsg: 'Failed to get privilege summary!' },
  );
}

export function* doGetAccountDeployedResources(
  ctx: QueryContext<DeployedResourcePagination, IACApiDeployedResourcesRequest>,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.Iac.deployedResources({
        ...ctx.params,
        page: ctx.page.page_number,
        pageSize: ctx.page.page_size,
      }),
    { errorMsg: 'Failed to get deployment resources!' },
  );
}

function* doGetCrudPermissions(
  ctx: QueryContext<
    Array<CrudPermissions>,
    InsightApiGetCanAccessActionsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Insight.getCanAccessActions(ctx.params),
    {
      cacheKey: 'doGetCrudPermissions',
    },
  );
}

export function* doGetResourceActionsMonthly(
  ctx: QueryContext<
    V1ResourceActionsMonthlyResponse,
    AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      AuditTrailApiService.AuditTrail.auditTrailServiceResourceActionsMonthly(
        ctx.params,
        {
          ...authTokenHeader(),
        },
      ),
    {
      errorMsg: 'Failed to get monthly actions on resource!',
      // cacheKey: 'doGetAccessed',
    },
  );
}

export function* doGetResourceActionsDaily(
  ctx: QueryContext<
    V1ResourceActionsDailyResponse,
    AuditTrailServiceApiAuditTrailServiceResourceActionsDailyRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      AuditTrailApiService.AuditTrail.auditTrailServiceResourceActionsDaily(
        ctx.params,
        {
          ...authTokenHeader(),
        },
      ),
    {
      errorMsg: 'Failed to get daily actions on resource!',
      // cacheKey: 'doGetAccessed',
    },
  );
}

export function* doGetAccessed(
  ctx: QueryContext<
    V1ActionsPerformedMonthlyResponse,
    AuditTrailServiceApiAuditTrailServiceActionsPerformedMonthlyRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      AuditTrailApiService.AuditTrail.auditTrailServiceActionsPerformedMonthly(
        ctx.params,
        {
          ...authTokenHeader(),
        },
      ),
    {
      errorMsg: 'Failed to get monthly actions performed!',
      // cacheKey: 'doGetAccessed',
    },
  );
}

export function* doGetActionsPerformedOnDay(
  ctx: QueryContext<
    V1ActionsPerformedOnDayResponse,
    AuditTrailServiceApiAuditTrailServiceActionsPerformedOnDayRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      AuditTrailApiService.AuditTrail.auditTrailServiceActionsPerformedOnDay(
        ctx.params,
        {
          ...authTokenHeader(),
        },
      ),
    {
      errorMsg: 'Failed to get daily actions performed!',
      // cacheKey: 'doGetAccessed',
    },
  );
}

export function* doGetResourceActions(
  ctx: QueryContext<ResourceAction[], AudittrailApiGetResourceActionsRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.AudittrailApi.getResourceActions(ctx.params),
    {
      errorMsg: 'Failed to get actions performed on the resource!',
      cacheKey: 'doGetResourceActions',
    },
  );
}

export function* doGetDataTypesBySource(
  ctx: QueryContext<DataTypeInfo[], DataTypesApiGetDataTypesBySourceRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.getDataTypesBySource(ctx.params),
    {
      errorMsg: 'Failed to get data types by source!',
    },
  );
}
export function* doGetDocumentTypesBySource(
  ctx: QueryContext<
    PageDocumentClassificationForSourceInfo,
    VisibilityDataApiGetDocumentClassificationByTypeForSourceRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationByTypeForSource(
        ctx.params,
      ),
    {
      errorMsg: 'Failed to get document types by source!',
    },
  );
}

export function* doGetDataSummary(
  ctx: QueryContext<
    SourceCountSummary,
    ResourcesApiGetSummarySourcesCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getSummarySourcesCount(ctx.params),
    {
      errorMsg: 'Failed to get data summary!',
    },
  );
}

export function* doGetDistributionByTypeOfFiles(
  ctx: QueryContext<
    ObjectsDistributionCount,
    ResourcesApiGetObjectsDistributionCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getObjectsDistributionCount(ctx.params),
    {
      errorMsg: 'Failed to get distribution by type of files!',
    },
  );
}

export function* doGetDistributionByDocumentType(
  ctx: QueryContext<
    DistributionByDocumentType,
    VisibilityDataApiGetDocumentClassificationTypeDistributionForSourceRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDocumentClassificationTypeDistributionForSource(
        ctx.params,
      ),
    {
      errorMsg: 'Failed to get distribution by document type!',
    },
  );
}

export function* doGetGcpResourceDetails(
  ctx: QueryContext<ResourceMetadata, ResourceApiGetResourceMetadataRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getResourceMetadata(ctx.params),
    {
      errorMsg: 'Failed to get resource details!',
    },
  );
}
