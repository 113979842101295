import React from 'react';

import { Box, HStack, Stack, Center } from '@chakra-ui/react';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { renderSeverityBar } from 'components/DataDisplay';
import { ExclamationTriangleIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';
import { toTitleCase } from 'utils/string';

export const OpenFindings: React.FC = () => {
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();

  const renderSeverity = severity => (
    <Center h="full" key={severity + '-key-findings-severity'} fontSize="17px">
      <HStack
        w="full"
        h="full"
        cursor="pointer"
        onClick={() =>
          navigate(
            '/findings?tab=' +
              AlertCategoryClass.DataSecurity +
              '&severity=' +
              severity +
              '&redirect=true',
          )
        }
      >
        <Box w="full">{toTitleCase(severity)}</Box>
        <Box w="full">
          {renderSeverityBar({
            value: severity,
            spacing: 12,
            showLabel: false,
          })}
        </Box>
        <Box fontWeight={600} w="full">
          {sum(
            openFindings.data?.[AlertCategoryClass.DataSecurity]?.map(
              s => s.severity?.[severity],
            ),
          )}
        </Box>
      </HStack>
    </Center>
  );

  return (
    <DashboardOverviewCard
      label="Open findings"
      icon={<ExclamationTriangleIcon />}
      iconBgColor="critical"
      content={
        <HStack h="full" w="full" spacing={10} pl={4}>
          <Stack h="full" w="full">
            {renderSeverity('CRITICAL')}
            {renderSeverity('HIGH')}
          </Stack>
          <Stack h="full" w="full">
            {renderSeverity('MEDIUM')}
            {renderSeverity('LOW')}
          </Stack>
        </HStack>
      }
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Ariksa delivers prioritized findings based on multi-factor
              analyses to reduce noise and increase your productivity.
            </Box>
            <Box>
              Findings are unique issues across several resources. Instead of
              chasing individual alerts, you can now focus on patterns and
              addressing them holistically.
            </Box>
          </Stack>
        ),
        header: 'Review findings, enforce policies...',
        footerText: 'Ok',
        icon: <ExclamationTriangleIcon />,
      }}
    />
  );
};
