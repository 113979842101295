import {
  AlertLogsApiGetAllTicketsRequest,
  AllTicketResponse,
} from '@ariksa/notification/api';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Alerts container
export const initialState: ContainerState = {
  tickets: QueryState.init([]),
  syncTicketInformation: QueryState.init({}),
};

const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    getTickets(
      state,
      action: QueryAction<
        AllTicketResponse[],
        AlertLogsApiGetAllTicketsRequest
      >,
    ) {
      state.tickets = QueryState.next(state.tickets, action);
    },

    syncTicketInformation(
      state,
      action: QueryAction<any, { ticketId: string }>,
    ) {
      state.syncTicketInformation = QueryState.next(
        state.syncTicketInformation,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = ticketSlice;
