import { SearchResponse } from '@ariksa/inventory-core';
import {
  CrudPermissions,
  InsightApiGetCanAccessActionsRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  MapResponse,
  SearchApiMapRequest,
  SearchApiSearchRequest,
  SearchApiSearchSuggestionsRequest,
  SearchApiSearchTermsRequest,
  SearchSuggestionResponse,
  SearchTerm,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import {
  AlertsApiGetEntitySeverityListRequest,
  EntitySeverityListResponse,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';
import { Dict, Optional } from 'types/utils';

import { ContainerState } from 'containers/Visibility/SecurityGraph/types';
import { NodeId } from 'containers/Visibility/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

export const initialState: ContainerState = {
  searchTermOptions: QueryState.init({} as any),
  recentSearchOptions: QueryState.init([]),
  getStartedSearchOptions: QueryState.init([]),

  initialSearch: QueryState.init({}),
  search: QueryState.init({}),
  currentQueryId: '',

  resourceAlerts: QueryState.init({}), //QueryState.init({ severity: {} })
  headers: [],
  enrichInsights: {
    alertingEntities: false,
    filteringByContext: false,
  },
  activeNode: {},
  permissions: [],
  filterNodeIds: [],
  searchQueryTerm: null,
  showGraph: true,

  searchQuery: null,
  nodeContext: PagedQueryState.init([]),
  resourceSearchInsight: QueryState.init({}),

  accessPermissions: QueryState.init([]),
  sourceNodeInfo: {},
  searchMap: QueryState.init({} as any),
  searchMapQueryTerm: null,
  showIdentityGraph: false,
};

const securityGraphSlice = createSlice({
  name: 'securityGraph',
  initialState,
  reducers: {
    getSearchMap(state, action: QueryAction<MapResponse, SearchApiMapRequest>) {
      if (action.payload.status === QueryStatus.fulfilled) {
        state.searchMap.data = action.payload.data?.search_response ?? {};
        state.searchMapQueryTerm = action.payload.data?.search_term;
      }

      state.searchMap.status =
        action.payload.status ?? QueryStatus.uninitialized;
      state.searchMap.isLoading = action.payload.status === QueryStatus.pending;
      state.searchMap.isSuccess =
        action.payload.status === QueryStatus.fulfilled;
      state.searchQueryTerm = action.payload.data?.search_term ?? null;
    },
    getSearchResourceInsight(
      state,
      action: QueryAction<MapResponse, SearchApiMapRequest>,
    ) {
      state.resourceSearchInsight = QueryState.next(
        state.resourceSearchInsight,
        action,
        {
          mapData: r => r.search_response,
        },
      );
    },
    toggleIdentityGraph(state) {
      state.showIdentityGraph = !state.showIdentityGraph;
    },
    showGraph(state, action: PayloadAction<boolean>) {
      state.showGraph = action.payload;
    },

    initialSearch(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.initialSearch = QueryState.next(state.initialSearch, action);
      state.search = initialState.search;
      state.currentQueryId = action.payload.q.searchQuery.uuid ?? '';
    },

    search(state, action: QueryAction<SearchResponse, SearchApiSearchRequest>) {
      state.search = QueryState.next(state.search, action);
      state.currentQueryId = action.payload.q.searchQuery.uuid ?? '';
    },

    resetSearchResponse(state, action: PayloadAction<Optional<SearchTerm>>) {
      state.search = initialState.search;
      state.currentQueryId = '';
    },

    updateSearchQuery(state, action: PayloadAction<any>) {
      state.searchQuery = action.payload;
      state.searchMap = initialState.searchMap;
      state.searchMapQueryTerm = initialState.searchMapQueryTerm;
    },
    updateSearchTerm(state, action: PayloadAction<Optional<SearchTerm>>) {
      state.searchQueryTerm = action.payload;
      state.search = initialState.search;
      state.initialSearch = initialState.initialSearch;
      state.currentQueryId = '';
      state.searchMap = initialState.searchMap;
      state.searchMapQueryTerm = initialState.searchMapQueryTerm;
    },
    updateFilterNodeIds(state, action: PayloadAction<NodeId[]>) {
      state.filterNodeIds = action.payload;
    },
    updatePermissions(state, action: PayloadAction<string[]>) {
      state.permissions = action.payload || [];
    },
    updateActiveNode(state, action: PayloadAction<Dict<any>>) {
      state.activeNode = action.payload || {};
    },
    updateEnrichInsights(state, action: PayloadAction<Dict<boolean>>) {
      state.enrichInsights = { ...state.enrichInsights, ...action.payload };
    },

    getRecentSearchOptions(
      state,
      action: QueryAction<SearchWrapper[], SearchApiSearchTermsRequest>,
    ) {
      state.recentSearchOptions = QueryState.next(
        state.recentSearchOptions,
        action,
      );
    },

    getGetStartedSearchOptions(
      state,
      action: QueryAction<SearchWrapper[], SearchApiSearchTermsRequest>,
    ) {
      state.getStartedSearchOptions = QueryState.next(
        state.getStartedSearchOptions,
        action,
      );
    },

    getResourceAlerts(
      state,
      action: QueryAction<
        EntitySeverityListResponse,
        AlertsApiGetEntitySeverityListRequest
      >,
    ) {
      state.resourceAlerts = QueryState.next(state.resourceAlerts, action);
    },

    getSearchOptions(
      state,
      action: QueryAction<
        SearchSuggestionResponse,
        SearchApiSearchSuggestionsRequest
      >,
    ) {
      state.searchTermOptions = QueryState.next(
        state.searchTermOptions,
        action,
      );
    },

    updateHeaders(state, action: PayloadAction<any[]>) {
      state.headers = action.payload.map(h => ({ ...h, hide: false }));
    },
    toggleHeader(state, action: PayloadAction<any>) {
      state.headers = state.headers.map(h => {
        if (h.header === action.payload.header) {
          return { ...h, hide: !h.hide };
        }
        return h;
      });
    },

    getNodeContext(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.nodeContext = PagedQueryState.next(state.nodeContext, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
    },

    getPermissions(
      state,
      action: QueryAction<
        CrudPermissions[],
        InsightApiGetCanAccessActionsRequest
      >,
    ) {
      state.accessPermissions = QueryState.next(
        state.accessPermissions,
        action,
      );
    },

    updateSourceNodeInfo(state, action: PayloadAction<Record<string, any>>) {
      state.sourceNodeInfo = action.payload;
    },
  },
});
export const { actions, reducer, name: sliceKey } = securityGraphSlice;
