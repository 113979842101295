import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { availableWidgets1 } from './widget';

export const dashboardWidgets1: IDashboardLayout = {
  id: 'dashboard_1',
  title: 'Dashboard Title',
  widgets: [
    {
      type: 'card',
      i: 'accountsAnalyzed',
      x: 0,
      y: 0,
      w: 3,
      h: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataSourcesAnalyzed',
      x: 3,
      y: 0,
      w: 3,
      h: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataAnalyzed',
      x: 6,
      y: 0,
      w: 3,
      h: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'dataFindings',
      x: 9,
      y: 0,
      w: 3,
      h: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'dataSourcesWithSensitiveData',
      x: 0,
      y: 2,
      w: 3,
      h: 6,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'sensitiveDataBySources',
      x: 3,
      y: 2,
      w: 3,
      h: 6,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'sensitiveDataByType',
      x: 6,
      y: 2,
      w: 3,
      h: 6,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'sensitiveDataByRegion',
      x: 9,
      y: 2,
      w: 3,
      h: 6,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'line-chart',
      i: 'dataSourcesWithSensitiveDataTrend',
      x: 0,
      y: 8,
      w: 3,
      h: 6,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'countOfSensitiveDataTrend',
      x: 3,
      y: 8,
      w: 3,
      h: 6,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'sensitiveDataByTypeTrend',
      x: 6,
      y: 8,
      w: 3,
      h: 6,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'sensitiveDataByRegionTrend',
      x: 9,
      y: 8,
      w: 3,
      h: 6,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
  ],
  available: availableWidgets1,
};
