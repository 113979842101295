import {
  BlueprintApiDeleteRulesRequest,
  BlueprintApiGetExceptionsRequest,
  PageBlueprintExceptionResource,
} from '@ariksa/compliance-policies/api';

import { ComplianceService } from 'api/services';
import { errorToast } from 'components/Toast';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useBlueprintService() {
  const getExceptions = useAriksaQuery<
    BlueprintApiGetExceptionsRequest,
    PageBlueprintExceptionResource
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Blueprint.getExceptions(req).then(r => r.data),
    onError: err =>
      errorToast({
        title: 'Failed to get exceptions.',
        description: err?.message,
      }),
  });

  return { getExceptions };
}
