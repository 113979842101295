import React, { useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AlertResponse } from '@ariksa/notification';
import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { H2, Heading3, renderRiskContext } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { FieldValue } from 'components/DataDisplay/Text/FieldValueText';
import { renderCompliance } from 'components/DataDisplay/Utils/renderCompliance';
import { PrimaryButton } from 'components/DataEntry';
import { CustomDayPicker } from 'components/DataEntry/Date';
import {
  BellIcon,
  DisableIcon,
  GraphIcon,
  IconTypes,
  NotificationIcon,
  TicketIcon,
} from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { renderTags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { AlertRemediation } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/AlertRemediation';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';
import { formatDate } from 'utils/date';

interface AlertDetailsContentProps {
  alert: AlertResponse;
}

export const AlertDetailsContent = (props: AlertDetailsContentProps) => {
  const { alert } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { riskContext } = useSelector(selectFindingsOverview);
  const context = riskContext.data?.[alert.entity_uuid ?? ''] ?? [];
  const { compliance } = useSelector(selectFindingsOverview);

  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const exceptionModal = useDisclosure();

  useEffect(() => {
    dispatch(
      actions.getCompliance({
        q: {
          getRuleComplianceStandardRequest: {
            rules: [alert.alert_rule_id ?? ''],
          },
        },
      }),
    );
  }, [dispatch, alert]);

  // open resource metadata drawer
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const openMetadataDrawer = () => {
    updateActiveResource({
      uuid: alert.entity_uuid!,
      resourceId: alert.entity_id,
      resourceType: alert.resource as NativeResources,
      accountId: alert.account_id ?? '',
    });
    onOpenMetadata();
  };

  // open security graph
  const openSecurityGraph = () => {
    const redirect_url = location?.pathname + location?.search;

    const url = securityGraphRedirectUrl({
      alert_id: alert.uuid,
      resource_id: alert.entity_id,
      source_resource_uuid: alert.entity_uuid,
      resource_type: alert.resource as NativeResources,
      account_id: alert.account_id,
      alert_rule_id: alert.alert_rule_id,
      redirect_url: redirect_url,
      search_uuid: alert.search_uuid ?? '',
    });

    navigate(url, { state: alert });
  };

  const handleShowAllAlerts = () => {
    updateActiveResource({
      uuid: alert.entity_uuid!,
      resourceId: alert.entity_id,
      resourceType: alert.resource as NativeResources,
      accountId: alert.account_id ?? '',
    });
    onOpenMetadata(10);
  };

  const handleAddException = () => {
    exceptionModal.onOpen();
  };

  return (
    <Stack spacing={3}>
      <HStack pb={3}>
        <PrimaryButton
          leftIcon={<AriksaIcon type={IconTypes.Overview} iconFilled={false} />}
          onClick={openMetadataDrawer}
        >
          DETAILS
        </PrimaryButton>
        <PrimaryButton leftIcon={<GraphIcon />} onClick={openSecurityGraph}>
          SECURITY GRAPH
        </PrimaryButton>
        <PrimaryButton leftIcon={<DisableIcon />} onClick={handleAddException}>
          IGNORE
        </PrimaryButton>
        <PrimaryButton leftIcon={<BellIcon />} onClick={handleShowAllAlerts}>
          SHOW ALL ALERTS
        </PrimaryButton>
        <PrimaryButton
          leftIcon={<NotificationIcon />}
          onClick={() => {
            notificationModal.onOpen();
          }}
        >
          CREATE NOTIFICATION
        </PrimaryButton>
        <PrimaryButton
          leftIcon={<TicketIcon />}
          onClick={() => {
            ticketModal.onOpen();
          }}
        >
          CREATE TICKETS
        </PrimaryButton>
      </HStack>
      <Divider />
      <HStack py={1} alignItems={'start'} spacing={1}>
        <Stack spacing={4} flex={2} pr={4}>
          <Stack spacing={4}>
            <HStack justifyContent={'space-between'} h={30}>
              <H2>Resource details</H2>
              <PrimaryButton rightIcon={<FiExternalLink />}>AWS</PrimaryButton>
            </HStack>
            <HStack alignItems={'start'} w={'full'}>
              <Stack flex={1} spacing={3}>
                <FieldValue field="Name" value={props.alert.entity_name} />
                <FieldValue field="Cloud ID" value={props.alert.entity_id} />
                <FieldValue
                  field="Risk context"
                  value={renderRiskContext(context)}
                />
                <FieldValue field="Owned by" value={'-'} />
                <FieldValue field="Tags" value={renderTags([])} />
              </Stack>
              <Stack flex={1} spacing={3}>
                <FieldValue
                  field={'Account'}
                  value={
                    <CloudAccountName accountId={props.alert.account_id} />
                  }
                />
                <FieldValue field={'Region'} value={alert.region} />
                <FieldValue field={'VPC'} value={'~'} />
                <FieldValue
                  field={'Compliance standards'}
                  value={
                    compliance.isLoading ? (
                      <Box w={'30px'}>
                        <CustomSpinner />
                      </Box>
                    ) : (
                      renderCompliance(
                        compliance.data?.[alert.alert_rule_id ?? ''] ?? [],
                      )
                    )
                  }
                />
                <FieldValue
                  field={'Blueprint:'}
                  value={
                    <HStack>
                      {alert.blueprints?.map(bp => (
                        <Link to={'#'}>
                          <Text color={'primary'}>{bp.blueprint_name}</Text>
                        </Link>
                      ))}
                    </HStack>
                  }
                />
              </Stack>
            </HStack>
          </Stack>
        </Stack>
        <Stack flex={1} spacing={4} borderLeft={'1px solid #eee'} pl={5}>
          <HStack h={30}>
            <Heading3>Status</Heading3>
          </HStack>
          <Stack spacing={3}>
            <FieldValue field={'Ticket'} value={'-'} />
            <FieldValue
              field={'First seen'}
              value={formatDate(alert.created_at)}
            />
            <FieldValue
              field={'Last update'}
              value={formatDate(alert.updated_at)}
            />
            <FieldValue
              field={'Due date'}
              value={
                <Stack maxW={200}>
                  <CustomDayPicker />
                </Stack>
              }
            />
            <FieldValue field={'SLA violation'} value={'-'} />
          </Stack>
        </Stack>
      </HStack>
      <Divider />
      <Stack pt={2} spacing={5}>
        <H2>Remediation Options</H2>
        <AlertRemediation alert={alert} showNote={false} showHeader={false} />
      </Stack>

      {ticketModal.isOpen && (
        <CreateTicket
          onClose={ticketModal.onClose}
          isOpen={ticketModal.isOpen}
          alert={alert}
          onCreate={() => {
            // getAlertListStatus(alertsByRuleId.data.map(a => a.uuid ?? ''))
          }}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {notificationModal.isOpen && (
        <CreateNotification
          onClose={notificationModal.onClose}
          isOpen={notificationModal.isOpen}
          alert={alert}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {exceptionModal.isOpen && (
        <AddException
          onClose={exceptionModal.onClose}
          isOpen={exceptionModal.isOpen}
          alert={alert}
        />
      )}
    </Stack>
  );
};
