import { BoxProps, FlexProps } from '@chakra-ui/react';
import { customTheme } from 'theme';

export const tabStyles: BoxProps = {
  fontSize: 'navBarHeader',
  color: customTheme.colors.gray['300'],
  _hover: {
    color: customTheme.colors.gray['900'],
  },
  h: 'full',
  mr: '5',
  px: '2',
};

export const selectedTabStyles: BoxProps = {
  color: customTheme.colors.gray['300'],
  fontWeight: 600,
  boxShadow: `inset 0 -1px ${customTheme.colors.primary}`,
};

export const wrapperStyle: FlexProps = {
  height: 'secondaryNavBarHeight',
  bg: 'gray.0', //'secondaryNavBarBg',
  boxShadow: `inset 0 1px 0 0${customTheme.colors.primary}, 0 0 1px #aaa`,
  h: 'full',
  // borderBottom: '1px solid',
  // borderBottomColor: 'gray.100',
};
