import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GlobeIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      fill="currentColor"
      d="M12,2C6.4771729,2,2,6.4771729,2,12s4.4771729,10,10,10c5.5202026-0.0062866,9.9937134-4.4797974,10-10C22,6.4771729,17.5228271,2,12,2z M8.6616211,3.6469116C7.6134033,4.7984009,6.8104248,6.4903564,6.3721313,8.5H3.7061157C4.6392822,6.2913818,6.4317627,4.5390625,8.6616211,3.6469116z M3,12c0-0.8684692,0.1296997-1.7054443,0.3592529-2.5H6.18927C6.0700073,10.2980347,6,11.131958,6,12s0.0700073,1.7019653,0.18927,2.5H3.3592529C3.1296997,13.7054443,3,12.8684692,3,12z M3.7061157,15.5h2.6660156c0.4382935,2.0096436,1.241272,3.7015991,2.2894897,4.8530884C6.4317627,19.4609375,4.6392822,17.7086182,3.7061157,15.5z M11.5,20.9544067C9.6489868,20.6220093,8.0916138,18.4655151,7.3935547,15.5H11.5V20.9544067z M11.5,14.5H7.2003174C7.0723267,13.7052002,7,12.868103,7,12s0.0723267-1.7052002,0.2003174-2.5H11.5V14.5z M11.5,8.5H7.3935547C8.0916138,5.5344849,9.6489868,3.3779907,11.5,3.0455933V8.5z M20.2905884,8.5h-2.6627197c-0.4376221-2.0064697-1.2385864-3.6965942-2.2844238-4.8479614C17.5685425,4.5457153,19.3571777,6.2954712,20.2905884,8.5z M12.5,3.0455933C14.3510132,3.3779907,15.9083862,5.5344849,16.6064453,8.5H12.5V3.0455933z M12.5,9.5h4.2996826C16.9276733,10.2947998,17,11.131897,17,12s-0.0723267,1.7052002-0.2003174,2.5H12.5V9.5z M12.5,20.9544067V15.5h4.1064453C15.9083862,18.4655151,14.3510132,20.6220093,12.5,20.9544067z M15.3383789,20.3530884c1.0482178-1.1514893,1.8511963-2.8434448,2.2894897-4.8530884h2.6660156C19.3607178,17.7086182,17.5682373,19.4609375,15.3383789,20.3530884z M17.81073,14.5C17.9299927,13.7019653,18,12.868042,18,12s-0.0700073-1.7019653-0.18927-2.5h2.8274536C20.8684692,10.2944946,20.9990234,11.1314697,21,12c0,0.8684692-0.1296997,1.7054443-0.3592529,2.5H17.81073z"
    />
  </Icon>
);
