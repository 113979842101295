import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { selectApp } from 'containers/App/selectors';

import { colors } from '../../../../../theme/theme2/colors';
import { selectCloudAccounts } from '../selectors';
import { actions } from '../slice';
import { renderStatus } from '../utils';

interface Props {
  snapshot_id: string;
}

export const SnapshotTasksTable: FC<Props> = props => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectApp);
  const { snapshotTasks } = useSelector(selectCloudAccounts);
  const { pagination, results, total, loading } = snapshotTasks;
  const { snapshot_id } = props;
  console.log(results[snapshot_id]);
  useEffect(() => {
    dispatch(
      actions.loadSnapshotTasks({
        snapshot_id: snapshot_id,
        page_number: pagination.page_number,
        page_size: pagination.page_size,
      }),
    );
  }, [dispatch, pagination, user.info.org_id, snapshot_id]);

  const columns = [
    {
      header: 'service name',
      accessor: 'service_name',
      styles: {
        backgroundColor: colors.gray['50'],
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Region',
      accessor: 'region',
      styles: {
        backgroundColor: colors.gray['50'],
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Asset Count',
      accessor: 'task_result',
      align: 'right',
      styles: { backgroundColor: colors.gray['50'] },
    },
    {
      header: 'Status',
      accessor: 'status',
      render: renderStatus,
      styles: { backgroundColor: colors.gray['50'] },
    },
  ];

  return (
    <Table
      columns={columns}
      data={results[snapshot_id]}
      isLoading={loading}
      pagination={{
        pageInfo: pagination,
        totalCount: total,
        onChange: pageInfo =>
          dispatch(actions.updateSnapshotTasksPagination(pageInfo)),
      }}
    />
  );
};
