import React, { FC } from 'react';

import { Box, Center } from '@chakra-ui/react';

import { ApiCallStatus } from 'api/types';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

interface IWithSpinner {
  loadStatus: ApiCallStatus;
  spinnerSize?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const WithSpinner: FC<IWithSpinner> = props => {
  const { loadStatus, children, spinnerSize = 'sm' } = props;

  return (
    <>
      {loadStatus.loading && (
        <Center h="full" w="full">
          <CustomSpinner loading={loadStatus.loading} size={spinnerSize} />
        </Center>
      )}
      {(loadStatus.loaded || !loadStatus.loading) &&
        !loadStatus.error &&
        children}
      {loadStatus.error && (
        <Box h={'full'}>{loadStatus.error ?? 'Failed to load data'}</Box>
      )}
    </>
  );
};
