import React from 'react';

import { HStack, Box, Center, Stack, Wrap } from '@chakra-ui/react';
import { isEmpty, map, startCase, toLower, truncate } from 'lodash';

import {
  formatNumberWithComma,
  getIcon,
  StackedCell,
  Tag,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { toTitleCase } from 'utils/string';

export const renderVulnerabilities = (value, isLoading) => {
  const renderWithDot = (value, severity) => (
    <HStack spacing={1} align="center">
      <Box
        boxSize={3}
        bg={severity === 'unknown' ? 'gray.100' : severity}
        borderRadius={2}
      />
      <Box>{toTitleCase(severity === 'unknown' ? 'unknown' : severity)}</Box>
      <Box>({value ?? 0})</Box>
    </HStack>
  );
  const critical =
    value?.vulnerability?.critical || value?.CRITICAL || value?.critical;
  const high = value?.vulnerability?.high || value?.HIGH || value?.high;
  const medium = value?.vulnerability?.medium || value?.MEDIUM || value?.medium;
  const low = value?.vulnerability?.low || value?.LOW || value?.low;
  const unknown =
    value?.vulnerability?.unknown || value?.UNKNOWN || value?.unknown;
  const noVulnerability =
    value?.total === 0 || isLoading || (!critical && !medium && !high && !low);
  return noVulnerability ? (
    <Box color="gray.200">No Vulnerability Found</Box>
  ) : (
    <Wrap spacing={3}>
      {!!critical && <Box>{renderWithDot(critical, 'critical')}</Box>}
      {!!high && <Box>{renderWithDot(high, 'high')}</Box>}
      {!!medium && <Box>{renderWithDot(medium, 'medium')}</Box>}
      {!!low && <Box>{renderWithDot(low, 'low')}</Box>}
      {!!unknown && <Box>{renderWithDot(unknown, 'unknown')}</Box>}
    </Wrap>
  );
};

export const getHighPrivilegeColor = type => {
  switch (toLower(type)) {
    case 'update':
      return 'medium';
    case 'delete':
      return 'critical';
    case 'permissions_management':
    case 'permissions management':
    case 'permissions':
      return 'low';
    case 'create':
      return 'skyblue';
    case 'read':
      return 'green.300';
    default:
      return 'primary';
  }
};

export const renderHighPrivilege = (action, bg, count = 0) => (
  <CustomTooltip label={toTitleCase(action) + (count ? ': ' + count : '')}>
    <Center boxSize={4} bg={bg} color="white" borderRadius={3} fontSize={12}>
      {action[0]}
    </Center>
  </CustomTooltip>
);

export const highPrivilegesConst = [
  'Create',
  'Read',
  'Update',
  'Delete',
  'Write',
  'Permissions Management',
  'Permissions',
  'Tagging',
];

export const renderHighPrivileges = ({ value }) => {
  return (
    <Center>
      <HStack spacing={0.5}>
        {map(
          highPrivilegesConst,
          o =>
            (value?.[o] || value?.[o.toLowerCase()]) &&
            renderHighPrivilege(
              o,
              getHighPrivilegeColor(o.toLowerCase()),
              value?.[o] ?? value?.[o.toLowerCase()],
            ),
        )}
      </HStack>
    </Center>
  );
};

export const renderHighPrivilegesStackedCell = ({ value }) => {
  return (
    <StackedCell
      upper={isEmpty(value) ? 'No' : 'Yes'}
      lower={
        isEmpty(value) ? (
          '-'
        ) : (
          <Center>
            <HStack spacing={0.5}>
              {map(
                highPrivilegesConst,
                o =>
                  (value?.[o] || value?.[o.toLowerCase()]) &&
                  renderHighPrivilege(
                    o,
                    getHighPrivilegeColor(o.toLowerCase()),
                    value?.[o] ?? value?.[o.toLowerCase()],
                  ),
              )}
            </HStack>
          </Center>
        )
      }
      showLowerTooltip={false}
      showUpperTooltip={false}
    />
  );
};

export const renderActionPerformed = ({ row }) => (
  <StackedCell
    upper={
      row?.action ? (
        <HStack>
          {renderHighPrivilege(row?.action, 'primary')}
          <Box>{row?.action}</Box>
        </HStack>
      ) : (
        '-'
      )
    }
    lower={row?.action_type}
  />
);

export const renderAlert = (value: any, type: string) => (
  <HStack spacing={1}>
    <Box w={3} h={3} borderRadius={2} bg={type} />
    <Box>
      {startCase(type)} ({value})
    </Box>
  </HStack>
);

export const renderTags = (value, fontSize = 10, styles = {}) => (
  <Stack spacing={1}>
    <HStack spacing={1}>
      {value[0] && <Tag label={value[0]} styles={styles} />}
      {value[1] && <Tag label={value[1]} styles={styles} />}
    </HStack>
    <HStack spacing={1}>
      {value[2] && <Tag label={value[2]} styles={styles} />}
      {value?.length > 3 && (
        <Tag
          label={'+ ' + (value?.length - 3)}
          styles={{
            tag: { fontSize: fontSize, bg: 'primary' },
            label: { color: 'white' },
          }}
        />
      )}
    </HStack>
  </Stack>
);

export const renderResourceCount = (value, label) => {
  return !isEmpty(value) ? (
    <CustomTooltip
      label={
        <Stack spacing={1}>
          <Box>{label}</Box>
          {map(value, o => (
            <HStack>
              <Box color="orange">{o?.name}:</Box>
              <Box>{o?.count}</Box>
            </HStack>
          ))}
        </Stack>
      }
    >
      {renderTags(
        map(value, o => (
          <HStack fontSize={10} spacing={1}>
            <Box>{truncate(o?.name, { length: 12 })}</Box>
            <Box borderLeft="1px solid" borderColor="gray.200" h={2.5} />
            <Box>{o?.count}</Box>
          </HStack>
        )),
      )}
    </CustomTooltip>
  ) : (
    '-'
  );
};

export const renderCloudResourceCount = (value, label) => {
  return !isEmpty(value) ? (
    <CustomTooltip
      label={
        <Stack spacing={1}>
          <Box>{label}</Box>
          {map(value, o => (
            <HStack>
              <Box color="orange">{o?.name}:</Box>
              <Box>{formatNumberWithComma(o?.count)}</Box>
            </HStack>
          ))}
        </Stack>
      }
    >
      {renderTags(
        map(value, o => (
          <HStack fontSize={12} spacing={1}>
            <Center boxSize={4}>
              <Center>{getIcon(o?.cloud)}</Center>
            </Center>
            <Box>{truncate(o?.name, { length: 13 })}</Box>
            <Box borderLeft="1px solid" borderColor="gray.200" h={2.5} />
            <Box>{formatNumber(o?.count, 1)}</Box>
          </HStack>
        )),
        12,
        { label: { color: 'black' } },
      )}
    </CustomTooltip>
  ) : (
    '-'
  );
};

export const renderSensitiveDataCount = ({ row }) => {
  return renderResourceCount(row?.sensitive_data, 'Sensitive Data');
};

export const renderList = ({ value }) => (
  <CustomTooltip
    label={
      <Stack spacing={1}>
        {map(value, o => (
          <Box>{o}</Box>
        ))}
      </Stack>
    }
  >
    <Box textOverflow="ellipsis">{value?.join(', ')}</Box>
  </CustomTooltip>
);

export const renderDataSummaryField = (label, value) => (
  <HStack>
    <Box>{label}:</Box>
    <Box fontWeight={600}>{value}</Box>
  </HStack>
);
