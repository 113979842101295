import { EventEmitter } from 'events';

import React, { createContext } from 'react';

import {
  AppSchemasSearchModelsRelationship,
  Ordering,
  Path,
  SearchResponseNode,
  SourceItems,
} from '@ariksa/inventory-core/api';
import { DndContext } from '@dnd-kit/core';
import { Application } from 'pixi.js';
import { Optional } from 'types/utils';

import { AGraphNodeProps } from 'components/Visualization/PixiGraph/core/Node';
import { NodePagination } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';

export interface ISecurityGraphContext {
  paths: Path[];
  edges: AppSchemasSearchModelsRelationship[];
  ordering: Ordering[];
  nodes: (SearchResponseNode & AGraphNodeProps)[];
  source_items: SourceItems[];

  // api call status
  isLoading: boolean;
  isLoaded: boolean;

  isLoadingInfo?: boolean;
  app: Optional<Application>;

  setApp(app: Optional<Application>): void;

  dirtyCount?: number;

  setDirty(): void;

  bus: EventEmitter;
  pagination?: Record<string, NodePagination>;
}

export const SecurityGraphContext = createContext<ISecurityGraphContext>({
  source_items: [],
  ordering: [],
  paths: [],
  nodes: [],
  edges: [],
  app: null,
  setApp(app: Optional<Application>) {},
  bus: new EventEmitter(),
  pagination: {},
  dirtyCount: 0,
  setDirty() {},

  isLoadingInfo: false,

  isLoading: false,
  isLoaded: false,
});

interface ISecurityGraphContextProvider {
  value: ISecurityGraphContext;
  children?: React.ReactNode;
}

export const SecurityGraphContextProvider = (
  props: ISecurityGraphContextProvider,
) => {
  return (
    <DndContext>
      <SecurityGraphContext.Provider value={props.value}>
        {props.children}
      </SecurityGraphContext.Provider>
    </DndContext>
  );
};
