import { EventEmitter } from 'events';

import { useCallback, useEffect } from 'react';

import { SearchResponse } from '@ariksa/inventory-core';
import { useDispatch, useSelector } from 'react-redux';

import { NodePagination } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

export const usePagination = (bus: EventEmitter) => {
  const { pagination } = useSelector(selectSecurityGraphNext);
  const { searchResult } = useSearchResult();
  const dispatch = useDispatch();

  const initializePagination = useCallback(
    (res: SearchResponse) => {
      const { nodes } = res;
      const nodeMap =
        nodes?.reduce((acc, node) => {
          const header = node.header ?? '';
          acc[header] = acc[header] ?? 0;
          acc[header] += 1;
          return acc;
        }, {} as Record<string, number>) ?? {};

      const pagination: NodePagination[] = Object.entries(nodeMap).reduce(
        (acc, [k, v]) => {
          return [
            ...acc,
            {
              header: k,
              totalCount: v,
              visibleCount: Math.min(10, v + 1),
            },
          ];
        },
        [] as NodePagination[],
      );

      dispatch(actions.updatePagination(pagination));
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      ['permission', 'permission:excess'].includes(
        searchResult.data.category ?? '',
      )
    ) {
      initializePagination(searchResult.data as SearchResponse);
    }
  }, [initializePagination, searchResult.data]);

  useEffect(() => {
    const onUpdatePagination = (data: NodePagination) => {
      dispatch(actions.updatePagination([data]));
      console.log(data);
    };

    bus.on('update:security-graph-next:pagination', onUpdatePagination);
    return () => {
      bus.off('update:security-graph-next:pagination', onUpdatePagination);
    };
  }, [bus]);

  return { pagination };
};
