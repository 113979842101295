import React from 'react';

import { Icon } from '@chakra-ui/react';

export const LockOpenIcon = props => (
  <Icon
    viewBox="0 -5.32 122.88 122.88"
    h="full"
    w="full"
    color="inherit"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.222,78.594l-3.819,10.008h13.454l-3.541-10.143 c2.247-1.158,3.783-3.5,3.783-6.201c0-3.85-3.121-6.971-6.97-6.971c-3.849,0-6.971,3.121-6.971,6.971 C36.159,75.068,37.824,77.492,40.222,78.594L40.222,78.594z M8.07,41.656h49.502v-7.835c0-9.273,3.647-17.713,9.523-23.843 C72.998,3.822,81.146,0,90.116,0c8.918,0,16.905,3.775,22.742,9.864c5.864,6.117,9.547,14.583,9.789,23.889l0.231,8.911 c0.038,1.605-1.232,2.937-2.837,2.975c-1.604,0.038-2.937-1.233-2.974-2.838l-0.232-8.911c-0.203-7.803-3.276-14.887-8.171-19.992 c-4.771-4.978-11.287-8.063-18.549-8.063c-7.325,0-13.99,3.132-18.827,8.178c-4.863,5.073-7.882,12.084-7.882,19.809v7.835h14.782 c2.222,0,4.24,0.907,5.701,2.368c1.461,1.461,2.368,3.479,2.368,5.702v54.438c0,2.223-0.907,4.24-2.368,5.701 s-3.479,2.369-5.701,2.369H8.07c-2.223,0-4.241-0.908-5.702-2.369C0.907,108.404,0,106.387,0,104.164V49.726 c0-2.222,0.907-4.24,2.369-5.702C3.83,42.563,5.848,41.656,8.07,41.656L8.07,41.656z M78.189,47.491H8.07 c-0.612,0-1.17,0.252-1.576,0.659c-0.407,0.406-0.659,0.965-0.659,1.576v54.438c0,0.611,0.252,1.17,0.659,1.576 c0.406,0.406,0.964,0.658,1.576,0.658h70.119c0.611,0,1.17-0.252,1.576-0.658s0.659-0.965,0.659-1.576V49.726 c0-0.611-0.253-1.17-0.659-1.576C79.359,47.743,78.801,47.491,78.189,47.491L78.189,47.491z"
    />
  </Icon>
);
