import React from 'react';

import { DataClassifierType } from '@ariksa/data-scanning';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';

import {
  renderSeverityBar,
  StackedCell,
  timeLabel,
  timeTooltipLabel,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  CheckmarkCircleOutlineIcon,
  DisableIcon,
  IconTypes,
} from 'components/Icons';

export const useDataClassifierTableColumns = () => {
  const updated = {
    header: 'Updated',
    align: 'left',
    render: ({ row }) =>
      !!row?.last_updated_by ? (
        <StackedCell
          upper={row?.last_updated_by}
          lower={timeLabel(row?.last_updated_at)}
          lowerTooltip={timeTooltipLabel(row?.last_updated_at)}
        />
      ) : (
        '-'
      ),
  };

  const created = {
    header: 'Created',
    align: 'left',
    render: ({ row }) => (
      <StackedCell
        upper={row.created_by}
        lower={timeLabel(row.created_at)}
        lowerTooltip={timeTooltipLabel(row?.created_at)}
      />
    ),
  };

  const status = {
    header: 'Status',
    accessor: 'is_enabled',
    align: 'left',
    render: ({ value }) => (
      <HStack>
        <Box w={4} color={value ? 'green' : 'red'}>
          {value ? <CheckmarkCircleOutlineIcon /> : <DisableIcon />}
        </Box>
        <Box>{value ? 'Enabled' : 'Disabled'}</Box>
      </HStack>
    ),
  };

  const description = {
    header: 'Description',
    accessor: 'description',
    align: 'left',
  };

  const dataExclusionColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon resourceType={IconTypes.Disable} bgColor="primary">
          <Box>{row.name}</Box>
        </WithResourceIcon>
      ),
    },
    description,
    status,
    {
      header: 'Type',
      accessor: 'type',
      align: 'left',
    },
    created,
    updated,
  ];

  const dataTypeColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Data Type</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={
            row.type === DataClassifierType.Data
              ? IconTypes.RegexDataClassifier
              : IconTypes.Policy
          }
          bgColor="primary"
          tooltip={row.name}
        >
          {row.name}
        </WithResourceIcon>
      ),
    },
    description,
    {
      header: 'Category',
      accessor: 'sensitivity_category',
      align: 'left',
    },
    {
      header: 'Sensitivity Level',
      accessor: 'sensitivity_level',
      align: 'left',
      render: renderSeverityBar,
    },
    {
      header: 'Label(s)',
      accessor: 'labels',
      render: ({ value, row }) =>
        row?.labels?.length ? (
          <CustomTooltip
            label={
              <Stack>
                {map(value, o => (
                  <Box>{o}</Box>
                ))}
              </Stack>
            }
          >
            {row?.labels?.length ?? 0}
          </CustomTooltip>
        ) : (
          0
        ),
    },
    created,
  ];

  return { dataTypeColumns, dataExclusionColumns };
};
