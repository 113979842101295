import { flatten, isEqual, keys, reduce } from 'lodash';

import { UserPermissionConfig } from './types';

type Resource = 'Any' | 'All' | string;

const ARIKSA_ROLES = ['system', 'tenancy', 'policy', 'cloud_onboarding'];
type AriksaRole = 'system' | 'tenancy' | 'policy' | 'cloud_onboarding';

export const CLOUD_ROLES = ['reporting', 'remediation', 'policy', 'compliance'];
type CloudRole = 'reporting' | 'remediation' | 'policy' | 'compliance';

type Action = 'view' | 'create' | 'update' | 'delete';
const ACTIONS = ['view', 'create', 'update', 'delete'];

type AriksaPermission = {
  [k in Action]: () => boolean;
};

type AriksaPermissions = {
  [k in AriksaRole]: AriksaPermission;
};

type CloudPermission = {
  [k in Action]: (resource: string) => boolean;
};

export type CloudPermissions = {
  [k in CloudRole]: CloudPermission;
};

export class UserPermission {
  cloudResources: Set<string>;

  private readonly ariksaRoles: Set<string>;
  private readonly cloudRoles: Set<string>;
  private readonly permissions: UserPermissionConfig;

  readonly keys: string[];
  ariksa: AriksaPermissions;
  cloud: CloudPermissions;
  isDefault: boolean;

  private readonly roles: string[];

  static default() {
    return new UserPermission([], {} as UserPermissionConfig, true);
  }

  constructor(
    roles: string[] = [],
    config: UserPermissionConfig = {} as UserPermissionConfig,
    isDefault: boolean = false,
  ) {
    this.roles = roles;
    this.isDefault = isDefault;
    const cleaned = roles.filter(r => r.includes('::'));

    const ariksaRoles = cleaned.filter(r => r.indexOf('resource') === -1);
    this.ariksaRoles = new Set(ariksaRoles);

    const cloudRoles = cleaned.filter(r => r.indexOf('resource') === 0);
    this.cloudRoles = new Set(cloudRoles);

    this.cloudResources = new Set(
      flatten(cloudRoles.map(r => r.split('::')[1].split(','))).filter(r => r),
    );

    this.ariksa = this.createAriksaPermissions();
    this.cloud = this.createCloudPermissions();

    // console.log(roles);
    // console.log(this.cloudRoles, roles);

    this.permissions = reduce(
      config,
      (m, v, k) => ({ ...m, [k]: () => v(this) }),
      {} as UserPermissionConfig,
    );

    this.keys = keys(config).sort(
      (a, b) => a.split('/').length - b.split('/').length,
    );
  }

  get hasNoRoles() {
    return !this.ariksaRoles.size && !this.cloudRoles.size;
  }

  isSameRoles(roles: string[]): boolean {
    return isEqual(roles, this.roles);
  }

  allowedRoute(path: string): boolean {
    if (path.indexOf('/') !== 0) {
      return false;
    }

    return this.includesKey(path);
  }

  includesPath(path: string): boolean {
    return this.includesKey(path);
  }

  includesKey(key: string): boolean {
    const matchKey = this.findMatchKey(key);
    return !!this.permissions[matchKey]?.(this);
  }

  findMatchKey(key: string): string {
    return this.keys.find(k => this.matchKey(k, key)) || '';
  }

  matchKey(pattern: string, k2: string): boolean {
    const t1 = pattern.split('/');
    const t2 = k2.split('/');
    if (t1.length !== t2.length) {
      return false;
    }

    return t1.every((t, i) => {
      return t[0] === ':' || t === t2[i];
    });
  }

  lastIncludedPath(path: string, keys: string[] = this.keys): string {
    const allowedKeys = keys.filter(k => this.includesKey(k));
    allowedKeys.reverse();

    return allowedKeys[0] ?? '/';
  }

  firstIncludedPath(path: string, keys: string[] = this.keys): string {
    const allowedKeys = keys.filter(k => this.includesKey(k));

    return allowedKeys[0] ?? '/';
  }

  private createAriksaPermissions(): AriksaPermissions {
    return reduce(
      ARIKSA_ROLES,
      (ap, ar) => {
        return {
          ...ap,
          [ar]: reduce(
            ACTIONS,
            (actions, ac) => {
              return {
                ...actions,
                [ac]: () => {
                  return this.ariksaRoles.has(`${ar}::${ac}`);
                },
              };
            },
            {} as AriksaPermission,
          ),
        };
      },
      {} as AriksaPermissions,
    );
  }

  private createCloudPermissions(): CloudPermissions {
    return reduce(
      CLOUD_ROLES,
      (ap, cr) => {
        return {
          ...ap,
          [cr]: reduce(
            ACTIONS,
            (actions, ac) => {
              return {
                ...actions,
                [ac]: (resource: Resource = 'Any') => {
                  if (resource === 'Any') {
                    // TODO(sm): optimize, avoid converting set to array on each function call
                    return Array.from(this.cloudRoles).some(r =>
                      r.includes(cr),
                    );
                  }

                  return (
                    this.cloudRoles.has(`resource::*::${cr}::${ac}`) ||
                    this.cloudRoles.has(`resource::${resource}::${cr}::${ac}`)
                  );
                },
              };
            },
            {} as CloudPermission,
          ),
        };
      },
      {} as CloudPermissions,
    );
  }
}
