import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GoogleDriveIcon = props => (
  <Icon
    viewBox="-0.01999999999999999 -0.88 939.13 843.29"
    h="full"
    w="full"
    {...props}
  >
    <path
      d="M641.86 570.64H298.27l-147.13 257c17.7 9 37.8 14.77 50.8 14.77 135.16 0 411-2 547.93-2 14.08 0 27.26-4.8 39.08-12.32z"
      fill="#4185f3"
    />
    <path
      d="M151.14 827.65l147.13-257H.13c-.15 17.86 6.2 34.2 16.15 52.84C44 675.35 90.9 758.25 118.45 801.55c6.79 10.67 33.75 26.64 32.69 26.1z"
      fill="#1767d1"
    />
    <path
      d="M641.86 570.64L789 828.05c19.07-12.12 34.61-31.32 44.7-49 22.78-39.87 61.3-106.26 87.87-151.21 12.35-20.9 17.15-39.67 16.85-57.22z"
      fill="#e94235"
    />
    <path
      d="M298.3 570.66L469.92 272.3 316.54 16.58c-16.59 10.91-30.78 25.72-37.22 37C212.37 171 77.5 411.65 9.68 530.59a73.54 73.54 0 0 0-9.59 40.07z"
      fill="#30a753"
    />
    <path
      d="M641.85 570.66L469.92 272.3 619.09 16.87c16.59 10.92 34.8 25.43 41.24 36.72C727.28 171 862.15 411.65 930 530.59c7 12.23 9.11 26.07 8.43 40.07z"
      fill="#f9bc00"
    />
    <path
      d="M316.52 16.62l153.4 255.68L619.09 16.87c-15.4-9-37.21-14.33-58.33-15C502-.09 406.74-.88 355.47 1.35c-12.64.55-38.79 15.16-38.95 15.27z"
      fill="#0f8038"
    />
  </Icon>
);
