import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { StackedHeader } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { UpdateConfig } from 'containers/PolicyHub/Blueprints/Components/Utils/Configure/UpdateConfig';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';

interface Props {
  isOpen: boolean;
  onClose();
  onSuccess();
}

export const Configure: FC<Props> = props => {
  const { isOpen, onClose, onSuccess } = props;
  const { blueprint } = useSelector(selectBlueprints);
  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1200px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <StackedHeader
            upper="Configure"
            lower={blueprint.data?.name}
            type={IconTypes.Policy}
          />
        }
        body={
          <Box h="full">
            <UpdateConfig onCancel={onClose} onSuccess={onSuccess} />
          </Box>
        }
      />
    </Box>
  );
};
