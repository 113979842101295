import { PackageCategory, VulnerabilitySeverity } from '@ariksa/inventory-core';
import { NativeResources, Resources } from '@ariksa/inventory-core/api';
import { ApplicationEnum, VulnerabilityType } from '@ariksa/scan-analysis';
import { LibraryType } from '@ariksa/scan-analysis/api';
import { Box } from '@chakra-ui/react';
import { map } from 'lodash';

import { renderSeverityBar } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { CONTAINER_ON_VIRTUAL_MACHINE } from 'containers/Visibility/Vulnerabilities/types';

export const VulnerabilityTableHeading = {
  [Resources.VirtualMachine]: 'Virtual Machines',
  [CONTAINER_ON_VIRTUAL_MACHINE]: 'Containers Deployed on Virtual Machine',
  [Resources.ServerlessCompute]: 'Serverless',
  [NativeResources.ElasticContainerRegistry]: 'Container Images',
  [Resources.Image]: 'Machine Images',
  [NativeResources.Container]: 'Containers',
  [NativeResources.ElasticContainerService]:
    'Containers Deployed on Container Service',
  cve: 'CVE Findings – All Severities',
  [IconTypes.Packages]: 'CVE Findings by Packages',
};

export const vulnerabilitySideNavItemList = [
  {
    title: 'By Resources',
    key: 'byResources',
    showIcon: true,
    secondary: [
      {
        title: 'Virtual Machines',
        key: Resources.VirtualMachine,
        showIcon: true,
      },
      {
        title: 'Containers',
        key: NativeResources.Container,
        secondary: [
          {
            title: 'Container Service',
            key: NativeResources.ElasticContainerService,
            showIcon: false,
          },
          {
            title: 'Virtual Machine',
            key: CONTAINER_ON_VIRTUAL_MACHINE,
            showIcon: false,
          },
        ],
        showIcon: true,
      },
      {
        title: 'Images',
        key: 'Images',
        secondary: [
          {
            title: 'Container Images',
            key: NativeResources.ElasticContainerRegistry,
            showIcon: false,
          },
          {
            title: 'Machine Images',
            key: Resources.Image,
          },
        ],
        showIcon: true,
      },
      {
        title: 'Serverless',
        key: Resources.ServerlessCompute,
        showIcon: true,
      },
    ],
  },
  {
    title: 'By CVE',
    key: 'cve',
    showIcon: true,
  },
  {
    title: 'By Packages',
    key: IconTypes.Packages,
    showIcon: true,
  },
];

export const getVulnerabilityIcon = tab => {
  switch (tab) {
    case CONTAINER_ON_VIRTUAL_MACHINE:
    case NativeResources.ElasticContainerService:
      return getIcon(NativeResources.Container);
    case VulnerabilitySeverity.Critical:
    case VulnerabilitySeverity.Medium:
    case VulnerabilitySeverity.High:
    case VulnerabilitySeverity.Low:
      return getIcon(IconTypes.ExclamationTriangle);
    default:
      return getIcon(tab);
  }
};

export const getVulnerabilityIconBgColor = tab => {
  switch (tab) {
    case VulnerabilitySeverity.Critical:
    case VulnerabilitySeverity.Medium:
    case VulnerabilitySeverity.High:
    case VulnerabilitySeverity.Low:
      return tab;
    default:
      return 'primary';
  }
};

export const vulnerabilitySeverityOptions = [
  { label: 'All Severity', value: undefined },
  ...map(VulnerabilitySeverity, o => ({
    label: renderSeverityBar({ value: o }),
    value: o,
  })),
];

const renderBoolean = value => (
  <Box boxSize={3} bg={value ? 'green.300' : 'critical'} borderRadius={3} />
);

export const patchAvailableOptions = [
  { label: 'All', value: undefined },
  { label: 'Yes', value: 'yes', icon: renderBoolean(true) },
  { label: 'No', value: 'no', icon: renderBoolean(false) },
];

export const exploitableOptions = [
  { label: 'All', value: undefined },
  { label: 'Yes', value: 'yes', icon: renderBoolean(false) },
  { label: 'No', value: 'no', icon: renderBoolean(true) },
];

export const statusOptions = [
  { label: 'Open', value: false, icon: renderBoolean(false) },
  { label: 'Resolved', value: true, icon: renderBoolean(true) },
];

export const packageTypeOptions = selectedTab => [
  { label: 'All', value: undefined },
  ...(selectedTab !== NativeResources.ElasticContainerRegistry
    ? [
        {
          label: 'OS',
          value: PackageCategory.OsPackage,
        },
        {
          label: 'Library',
          value: PackageCategory.LibraryPackage,
        },
      ]
    : []),
  {
    label: 'Technology',
    value: PackageCategory.ApplicationPackage,
  },
];

export const packageNameOptions = packageType => [
  ...(packageType?.value === VulnerabilityType.Os
    ? osOptions
    : packageType?.value === VulnerabilityType.Application
    ? applicationOptions
    : packageType?.value === VulnerabilityType.Library
    ? libraryOptions
    : []),
];

export const libraryOptions = [
  {
    label: 'All Libraries',
    value: undefined,
  },
  ...map(LibraryType, o => ({
    label: o,
    value: o,
  })),
];

export const applicationOptions = [
  {
    label: 'All Applications',
    value: undefined,
  },
  ...map(ApplicationEnum, o => ({
    label: o,
    value: o,
  })),
];

export const osOptions = [
  {
    label: 'All OS',
    value: undefined,
  },
  {
    label: 'Ubuntu',
    value: 'Ubuntu',
  },
  {
    label: 'Amazon Linux',
    value: 'Amazon Linux',
  },
];
