import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';

import { LambdaAccess } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/Lambda/LambdaAccess';
import { IAMUserAccess } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/IAMUser/Access/IAMUserAccess';
import { SnowflakeRoleAccess } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/SnowflakeRole/Access/SnowflakeRoleAccess';
import { SnowflakeUserAccess } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/SnowflakeUser/Access/SnowflakeUserAccess';
import { CanAccess } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/CanAccess';

export const getAccess = (resourceType: NativeResources) => {
  switch (resourceType) {
    case NativeResources.LambdaFunction:
      return <LambdaAccess />;
    case NativeResources.AccountRoot:
    case NativeResources.IamUser:
    case NativeResources.OktaUser:
    case NativeResources.AzureAdUser:
    case NativeResources.IamPolicy:
      return <IAMUserAccess />;
    case NativeResources.IamGroup:
    case NativeResources.OktaGroup:
    case NativeResources.AzureAdGroup:
    case NativeResources.AzureAdsamlRole:
    case NativeResources.OktaRoleSubscription:
    case NativeResources.IamRole:
    case NativeResources.Ec2Instance:
    case NativeResources.ElasticKubernetesService:
    case NativeResources.ElasticContainerService:
    case NativeResources.EcsCluster:
      return <CanAccess />;
    case NativeResources.SnowflakeUsers:
      return <SnowflakeUserAccess />;
    case NativeResources.SnowflakeRoles:
      return <SnowflakeRoleAccess />;
    default:
      return null;
  }
};
