import { RefObject, useCallback, useMemo } from 'react';

import { throttle } from 'lodash';
import { Application } from 'pixi.js';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { AGraphRenderer } from 'components/Visualization/PixiGraph/core/Renderer';

interface UseMenuProps {
  app: Application;
  renderer: AGraphRenderer;
  graph: AGraph<AGraphNode, AGraphEdge>;
  ref: RefObject<any>;
}

export const useGraphMenuTooltip = (props: UseMenuProps) => {
  const { app, ref, graph, renderer } = props;

  const attributes = useMemo(() => {
    return {
      style: {},
    };
  }, []);

  const getCollidedNodes = useCallback(
    e => {
      const { clientX, clientY } = e;
      const scale = app.stage.scale.y;
      const refBound = ref.current.getBoundingClientRect();
      const x = clientX - refBound.left - app.stage.x;
      const y = clientY - refBound.top - app.stage.y;
      return graph.collides(x / scale, y / scale);
    },
    [app.stage.scale.y, app.stage.x, app.stage.y, graph, ref],
  );

  const onMouseDown = useCallback(
    e => {
      const collides = getCollidedNodes(e);
      if (collides.length) {
        const activeNodes = collides.map(c => c.data);
        graph.activeNodes = activeNodes;
        app.stage.removeChild(renderer.activeGfx);
        renderer.activeGfx.clear();
        if (activeNodes.length) {
          graph.emit('mousedown:element', collides);
          app.stage.addChild(renderer.activeGfx);
        } else {
          graph.emit('mousedown:element', []);
        }
      }
    },
    [app.stage, getCollidedNodes, graph, renderer.activeGfx],
  );

  const onMouseMove = useCallback(
    throttle(e => {
      app.stage.removeChild(renderer.hoverGfx);
      const collides = getCollidedNodes(e);
      const map = new Map();
      collides.forEach(c => {
        map.set(c.id, c);
      });

      // subtract the nodes that are already hovered
      const hoveredNodes = collides.map(c => c.id);
      const oldHovered = graph.hoverElements.filter(
        c => !hoveredNodes.includes(c.id),
      );

      if (oldHovered.length) {
        graph.emit(
          'mouseout:element',
          oldHovered.map(c => c),
        );
      }

      graph.hoverElements = collides;

      renderer.hoverGfx.clear();
      if (collides.length) {
        collides.forEach(c => c.data?.renderHover?.(renderer.hoverGfx, c.id));
        app.stage.addChild(renderer.hoverGfx);
      }

      graph.emit('mouseover:element', collides);
    }, 40),
    [app.stage, getCollidedNodes, graph, renderer.hoverGfx],
  );

  return {
    attributes,
    listeners: {
      onMouseDown,
      onMouseMove,
    },
  };
};
