import React, { FC } from 'react';

import { HStack, Square, Text, Box } from '@chakra-ui/react';
import { some, startCase } from 'lodash';

import { DetailsIdentifier } from 'components/DataDisplay';

export interface StatusMap {
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
}

interface IDrawerAlertCounts {
  statusMap: StatusMap;
  label?: boolean;
}

export const AlertCounts = (props: IDrawerAlertCounts) => {
  if (some(props.statusMap, o => !!o))
    return (
      <HStack align={'center'} spacing={3}>
        {Object.entries(props.statusMap)
          .filter(([_, v]) => v)
          .map(([k, v]) => (
            <HStack spacing={1}>
              <Square size={'16px'} bg={k} borderRadius={'4'}></Square>
              <Text>
                {startCase(k)} ({v})
              </Text>
            </HStack>
          ))}
      </HStack>
    );
  else return <Box color="gray.200">None</Box>;
};

export const DrawerAlertCounts: FC<IDrawerAlertCounts> = props => {
  const { statusMap } = props;
  return (
    <DetailsIdentifier
      label="Alerts"
      value={<AlertCounts statusMap={statusMap} />}
    />
  );
};
