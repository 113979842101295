import React from 'react';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { AccountsAnalyzed } from 'containers/Visibility/Data/Components/Dashboard/Cards/AccountsAnalyzed';
import { DataAnalyzed } from 'containers/Visibility/Data/Components/Dashboard/Cards/DataAnalyzed';
import { DataFindings } from 'containers/Visibility/Data/Components/Dashboard/Cards/DataFindings';
import { DataSourcesAnalyzed } from 'containers/Visibility/Data/Components/Dashboard/Cards/DataSourcesAnalyzed';
import { SensitiveDataByRegionPieChart } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataByRegionPieChart';
import { SensitiveDataByRegionTrend } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataByRegionTrend';
import { SensitiveDataBySourcesPieChart } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataBySourcesPieChart';
import { SensitiveDataByTypePieChart } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataByTypePieChart';
import { SensitiveDataByTypeTrend } from 'containers/Visibility/Data/Components/Dashboard/Charts/SensitiveDataByTypeTrend';

import { customTheme } from '../../../../../../theme';

import { CountOfSensitiveDataTrend } from './Charts/CountOfSensitiveDataTrend';
import { DataSourcesWithSensitiveData } from './Charts/DataSourcesWithSensitiveData';
import { DataSourcesWithSensitiveDataTrend } from './Charts/DataSourcesWithSensitiveDataTrend';

const styles = { title: { fontSize: 'sm' } };

export const availableWidgets1: { [key: string]: WidgetProps } = {
  accountsAnalyzed: {
    bg: customTheme.colors.primary,
    flex: true,
    components: {
      content: <AccountsAnalyzed />,
    },
  },
  dataSourcesAnalyzed: {
    bg: customTheme.colors.skyBlue['300'],
    flex: true,
    components: {
      content: <DataSourcesAnalyzed />,
    },
  },
  dataAnalyzed: {
    bg: customTheme.colors.blue['300'],
    flex: true,
    components: {
      content: <DataAnalyzed />,
    },
  },
  dataFindings: {
    bg: customTheme.colors.green['300'],
    flex: true,
    components: {
      content: <DataFindings />,
    },
  },
  dataSourcesWithSensitiveData: {
    name: 'Data Sources with Sensitive Data',
    components: {
      content: <DataSourcesWithSensitiveData />,
    },
    styles,
  },
  sensitiveDataBySources: {
    name: 'Sensitive Data by Sources',
    components: {
      content: <SensitiveDataBySourcesPieChart />,
    },
    styles,
  },
  sensitiveDataByType: {
    name: 'Sensitive Data by Type',
    components: {
      content: <SensitiveDataByTypePieChart />,
    },
    styles,
  },
  sensitiveDataByRegion: {
    name: 'Sensitive Data by Region',
    components: {
      content: <SensitiveDataByRegionPieChart />,
    },
    styles,
  },
  dataSourcesWithSensitiveDataTrend: {
    name: 'Data Sources with Sensitive Data - Trend',
    components: {
      content: <DataSourcesWithSensitiveDataTrend />,
    },
    styles,
  },
  countOfSensitiveDataTrend: {
    name: 'Count of Sensitive Data - Trend',
    components: {
      content: <CountOfSensitiveDataTrend />,
    },
    styles,
  },
  sensitiveDataByTypeTrend: {
    name: 'Sensitive Data By Type - Trend',
    components: {
      content: <SensitiveDataByTypeTrend />,
    },
    styles,
  },
  sensitiveDataByRegionTrend: {
    name: 'Sensitive Data By Region - Trend',
    components: {
      content: <SensitiveDataByRegionTrend />,
    },
    styles,
  },
};
