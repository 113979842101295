import React, { FC, useRef } from 'react';

import { AggregatedAlertDetails } from '@ariksa/notification';
import { AlertResponse } from '@ariksa/notification/api';
import { Box } from '@chakra-ui/react';
import { Optional } from 'types/utils';

import { Drawer } from 'components/Overlay';
import { AlertDrawerHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AlertDrawerHeader';

import { IndividualAlertDrawerContent } from './IndividualAlertDrawerContent';

interface IIndividualAlertDrawer {
  aggregatedAlert: Optional<AggregatedAlertDetails>;
  alert: AlertResponse;
  isOpen: boolean;
  onClose();
}

export const IndividualAlertDrawer: FC<IIndividualAlertDrawer> = props => {
  const { aggregatedAlert, alert, isOpen, onClose } = props;

  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={<AlertDrawerHeader alert={alert} individualAlert />}
      body={
        <Box h="full" w="full" pt={3}>
          <IndividualAlertDrawerContent
            aggregatedAlert={aggregatedAlert}
            alert={alert}
          />
        </Box>
      }
      closeButton
      styles={{
        content: { maxW: '1380px' },
        drawer: {
          portalProps: {
            containerRef: ref as any,
          },
        },
      }}
    />
  );
};
