import React from 'react';

import { HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AccountIcon, CloudIcon, RegionIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { renderResourceCount } from 'containers/Dashboard/utils/utils';

export const InfrastructurePostureSummary: React.FC = () => {
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const navigate = useNavigate();

  const handleRedirect = () => navigate('/setup/accounts');

  const legends = [
    {
      icon: <CloudIcon />,
      count: infrastructurePostureSummary.data.cloud_providers?.length ?? 0,
      label: 'Clouds',
      isLoading: infrastructurePostureSummary.isLoading,
      onClick: handleRedirect,
    },
    {
      icon: <AccountIcon />,
      count: infrastructurePostureSummary.data.items?.['Accounts'] ?? 0,
      label: 'Accounts',
      isLoading: infrastructurePostureSummary.isLoading,
      onClick: handleRedirect,
    },
    {
      icon: <RegionIcon />,
      count: infrastructurePostureSummary.data.items?.['Regions'] ?? 0,
      label: 'Regions',
      isLoading: infrastructurePostureSummary.isLoading,
      onClick: handleRedirect,
    },
  ];

  return (
    <SummaryCard
      content={
        <HStack w="full" justify="space-between">
          {map(legends, o => renderResourceCount(o))}
        </HStack>
      }
    />
  );
};
