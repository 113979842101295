import React, { FC, useEffect, useState } from 'react';

import {
  Stack,
  HStack,
  Box,
  Flex,
  Wrap,
  useDisclosure,
} from '@chakra-ui/react';
import { forEach, map, toArray } from 'lodash';

import { DetailsIdentifier, Tag } from 'components/DataDisplay';
import { renderVulnerabilitiesWithSeverityBar } from 'containers/Inventory/CloudInventory/Components/utils';
import { renderPackageType } from 'containers/Visibility/Vulnerabilities/Components/hooks/utils';
import { CVEDetailsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEDetailsDrawer';

interface Props {
  row: Record<string, any>;
}

export const PackagesHostsDrawerDetails: FC<Props> = props => {
  const { row } = props;
  const [currentCve, setCurrentCve] = useState('');
  const cveDetailsDisclosure = useDisclosure();
  const [cves, setCves] = useState<string[]>([]);

  useEffect(() => {
    const items = new Set();
    forEach(row?.cve_ids, o => items.add(o));
    setCves(toArray(items) as string[]);
  }, [row]);

  return (
    <>
      <HStack align="flex-start" spacing={10}>
        <HStack w="60%">
          <Stack spacing={5} w="full">
            <DetailsIdentifier
              label="Type"
              value={renderPackageType(row?.package_category)}
            />
            <DetailsIdentifier
              label="Severity"
              value={renderVulnerabilitiesWithSeverityBar({
                value: row?.vulnerability,
              })}
            />
            <DetailsIdentifier
              label="Latest version"
              value={row?.installed_version}
            />
          </Stack>
        </HStack>
        <Flex
          borderRight="1px solid"
          borderColor="gray.200"
          h="full"
          justify="flex-end"
        />
        <Box h={32} overflow="scroll" w="40%">
          <DetailsIdentifier
            label="CVEs"
            styles={{ label: { color: 'black' } }}
            direction="column"
            value={
              <Wrap>
                {map(cves, o => (
                  <Tag
                    label={o}
                    styles={{
                      label: { fontSize: '15px' },
                      tag: { cursor: 'pointer' },
                    }}
                    onClick={() => {
                      cveDetailsDisclosure.onOpen();
                      setCurrentCve(o as string);
                    }}
                  />
                ))}
              </Wrap>
            }
          />
        </Box>
        {cveDetailsDisclosure.isOpen && (
          <CVEDetailsDrawer
            isOpen={cveDetailsDisclosure.isOpen}
            onClose={cveDetailsDisclosure.onClose}
            cveId={currentCve}
          />
        )}
      </HStack>
    </>
  );
};
