import React, { FC, useEffect, useState } from 'react';

import { Clients, Entities } from '@ariksa/notification';
import { forEach, includes, isEmpty, map, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { useAlertWorkflowWizardContext } from '../AlertWorkflowWizardContext';

import { AlertActions } from './AlertActions';
import { AlertConditions } from './AlertConditions';
import { AlertTriggers } from './AlertTriggers';

interface Props {}

export const AlertWorkflowConditions: FC<Props> = props => {
  const {
    handleSubmit,
    handleBack,
    onCancel,
  } = useAlertWorkflowWizardContext();
  const dispatch = useDispatch();
  const {
    formData,
    alertConditions,
    tagKeyValues,
    jiraClients,
    jiraMandatoryFields,
  } = useSelector(selectAlertWorkflow);
  const { clients: integrationClients } = useSelector(selectIntegrations);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const { trigger, clients } = formData;

  //load and tags
  useEffect(() => {
    dispatch(
      sharedStateActions.getTagKeys({
        q: {},
      }),
    );
  }, [dispatch]);
  //console.log(map(jiraClients, o => o));

  useEffect(() => {
    const jira_client = some(clients, { client_name: Clients.Jira });
    let jira = false;
    forEach(map(jiraClients), o => {
      if (jira) return;
      forEach(o, j => {
        if (isEmpty(j)) {
          jira = true;
          return;
        }
      });
    });
    setIsDisabledNext(
      isEmpty(trigger) ||
        isEmpty(alertConditions) ||
        isEmpty(clients) ||
        some(
          alertConditions,
          o => isEmpty(o.entity_list) && o.name !== Entities.Tags,
        ) ||
        (jira && jira_client) ||
        (includes(Object.keys(alertConditions), Entities.Tags) &&
          (some(tagKeyValues, o => isEmpty(o.entity_list)) ||
            isEmpty(tagKeyValues))),
    );
  }, [trigger, alertConditions, clients, tagKeyValues, jiraClients]);

  return (
    <Form
      styles={{ form: { container: { w: '620px', my: 4 } } }}
      title="Set Conditions"
      buttonOptions={{
        submit: {
          name: 'Next',
          isDisabled:
            isDisabledNext ||
            some(jiraMandatoryFields, { isLoading: true }) ||
            integrationClients.loading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: onCancel,
        },
        back: {
          isVisible: true,
          onClick: () => handleBack?.(),
        },
      }}
      handleSubmit={handleSubmit}
    >
      {/*When Part*/}
      <AlertTriggers />

      {/*If Part*/}
      <AlertConditions />

      {/*Then Part*/}
      <AlertActions />
    </Form>
  );
};
