import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.alertDrawer || initialState;

export const selectAlertDrawer = createSelector(
  [selectDomain],
  alertsState => alertsState,
);
