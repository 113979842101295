import React, { FC, useEffect } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';

import { ActionButton, renderTime, TrashIcon } from '../../../components';
import { selectApp, selectUser } from '../../App/selectors';
import { selectUserProfile } from '../selectors';
import { actions } from '../slice';

interface IUserSession {}

export const UserSession: FC<IUserSession> = props => {
  const dispatch = useDispatch();
  const { userAuth, user } = useSelector(selectApp);
  /*const userProfile = useSelector(selectUserProfile);
  const user = useSelector(selectUser);
  const prevRemovingSession = usePrevious(userProfile.removeSession.loading);

  useEffect(() => {
    if (prevRemovingSession && !userProfile.removeSession.error) {
      dispatch(actions.getUserSessions());
    }
  }, [dispatch, userProfile.removeSession, prevRemovingSession]);

  const removeSession = sessionId => {
    dispatch(actions.removeSession({ session_id: sessionId }));
  };*/

  const columns = [
    {
      header: 'Username',
      accessor: 'username',
      render: () => user.info.username,
    },
    {
      header: 'IP Address',
      accessor: 'ip_address',
      align: 'left',
    },
    {
      header: 'Last Accessed',
      accessor: 'last_access',
      render: renderTime,
      align: 'left',
    },
    {
      header: 'Session Start Time',
      accessor: 'start_at',
      render: renderTime,
      align: 'left',
    },
    /*{
      header: 'Actions',
      align: 'right',
      accessor: 'id',
      render: ({ value }) => (
        <Stack isInline spacing={0} px={4}>
          <ActionButton
            icon={<TrashIcon />}
            label="Remove"
            onClick={() => removeSession(value)}
            isDisabled={value === user.tokenInfo.session_state}
          />
        </Stack>
      ),
    },*/
  ];

  return (
    <Box flex={1} w="full" h="full">
      <Table columns={columns} data={userAuth.data.sessions ?? []} />
    </Box>
  );
};
