import React from 'react';

import { Status } from '@ariksa/compliance-policies';
import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { truncate } from 'lodash';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ThumbsDownIcon, ThumbsUpIcon } from 'components/Icons';

export const renderDivider = () => (
  <Box h="full" pt={6}>
    <Center
      borderLeft="1px solid"
      borderColor={customTheme.colors.gray['200']}
      h={12}
    />
  </Box>
);

export const renderRating = (value, color, isLoading) =>
  isLoading ? (
    <CustomSpinner />
  ) : (
    <Center
      bg={color}
      color={value === 'None' ? customTheme.colors.gray['250'] : 'white'}
      px={3}
      py={0.5}
      borderRadius={6}
    >
      {value}
    </Center>
  );

const renderFlag = (flag: boolean) => {
  return flag ? 'Yes' : 'No';
};

export const renderRiskAnalysis = (value, state) => {
  if (value === Status.DataNotAvailable) return <Box>NA</Box>;
  const ok = value === Status.RulePass;
  return (
    <HStack align="center" spacing={1}>
      <Center>{state}</Center>
      <Box boxSize={4}>
        <Center>
          {ok ? (
            <ThumbsUpIcon color={customTheme.colors.green['300']} />
          ) : (
            <ThumbsDownIcon color="red" />
          )}
        </Center>
      </Box>
    </HStack>
  );
};

export const renderSpinner = () => (
  <Center h="130px">
    <CustomSpinner />
  </Center>
);

const renderTag = (value: string, length?: number, tooltipArray?: string[]) => (
  <CustomTooltip
    label={
      tooltipArray ? (
        <Stack>
          {tooltipArray?.map(o => (
            <Box>{o}</Box>
          ))}
        </Stack>
      ) : (
        <Box>{value}</Box>
      )
    }
  >
    <Tag
      label={truncate(value, { length: length || 10 })}
      styles={{
        tag: { bg: 'primary' },
        label: { color: 'white' },
      }}
    />
  </CustomTooltip>
);

export const renderLabels = data => {
  return (
    <HStack>
      {data?.[0] && renderTag(data?.[0], data?.length === 1 ? 25 : 10)}
      {data?.[1] && renderTag(data?.[1], data[0]?.length < 10 ? 25 : 10)}
      {data?.length > 2 &&
        renderTag('+ ' + (data?.length - 2), 10, data?.slice(1, data?.length))}
    </HStack>
  );
};
