import {
  Charts,
  ChartsApiAddChartRequest,
  ChartsApiEditDashboardLayoutRequest,
  ChartsApiGetChartDetailsRequest,
  ChartsApiGetChartsRequest,
} from '@ariksa/reporting';
import { ChartsApiEditChartRequest } from '@ariksa/reporting/dist/api';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Reports container
export const initialState: ContainerState = {
  reportsInfo: QueryState.init([]),
  reports: {},
  report: QueryState.init({}),
  updateCharts: QueryState.init({}),
};

const reportsDashboardSlice = createSlice({
  name: 'reportsDashboard',
  initialState,
  reducers: {
    /*get reports*/
    getReportsInfo(
      state,
      action: QueryAction<Charts[], ChartsApiGetChartsRequest>,
    ) {
      state.reportsInfo = QueryState.next(state.reportsInfo, action);
      if (action.payload.status === QueryStatus.pending) {
        state.reports = initialState.reports;
      }
    },

    getReportingCharts(
      state,
      action: QueryAction<Record<string, any>, ChartsApiGetChartDetailsRequest>,
    ) {
      const chartId = action.payload.q.id;
      if (!state.reports[chartId]) {
        state.reports[chartId] = QueryState.init({} as any, {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.reports[chartId] = QueryState.next(
          state.reports[chartId],
          action,
        );
      }
    },

    /*update layout*/
    updateLayout(
      state,
      action: QueryAction<any, ChartsApiEditDashboardLayoutRequest>,
    ) {
      state.updateCharts = QueryState.next(state.updateCharts, action);
    },

    /*update chart*/
    updateChart(state, action: QueryAction<any, ChartsApiEditChartRequest>) {
      state.updateCharts = QueryState.next(state.updateCharts, action);
    },

    /*add chart*/
    addChart(state, action: QueryAction<any, ChartsApiAddChartRequest>) {
      state.updateCharts = QueryState.next(state.updateCharts, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = reportsDashboardSlice;
