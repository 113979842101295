import React, { useEffect, useState } from 'react';

import { AggregatedAlertsSummary } from '@ariksa/notification/api';
import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { each, isEmpty, map, slice, toUpper } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { BlueprintViewIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { DashboardOverviewTooltipProps } from 'containers/Dashboard/utils/OverviewDashboardTooltip';
import {
  renderAccount,
  renderCloudIcons,
  renderEmptyRows,
} from 'containers/Dashboard/utils/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { toTitleCase } from 'utils/string';

interface Props {
  categoryClass?: string;
  tooltip?: DashboardOverviewTooltipProps;
  stringLength?: number;
  numberOfAccounts?: number;
  accounts: AggregatedAlertsSummary[];
  showOnlyCloudIcons?: boolean;
}

export const TopFindings: React.FC<Props> = props => {
  const {
    categoryClass,
    tooltip,
    stringLength = 20,
    numberOfAccounts = 1,
    accounts,
    showOnlyCloudIcons = false,
  } = props;
  const { openFindings } = useSelector(selectDashboard);
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState<Record<string, any>[]>();
  const [categoryAccountsMapping, setCategoryAccountsMapping] = useState<
    Record<string, any>[]
  >();
  const { accountMapping } = useCloudAccountId();

  useEffect(() => {
    if (!!categoryClass) return;
    let items: Record<string, any> = {};
    each(openFindings.data, o => {
      each(
        o,
        c =>
          (items[c.category] = {
            CRITICAL: c.severity?.CRITICAL + (items[c.category]?.CRITICAL ?? 0),
            MEDIUM: c.severity?.MEDIUM + (items[c.category]?.MEDIUM ?? 0),
            HIGH: c.severity?.HIGH + (items[c.category]?.HIGH ?? 0),
            LOW: c.severity?.LOW + (items[c.category]?.LOW ?? 0),
          }),
      );
    });

    setAllCategories(map(items, (s, c) => ({ category: c, severity: s })));
  }, [openFindings.data, categoryClass]);

  useEffect(() => {
    let mapping: Record<string, any>[] = [];
    each(accounts, o => {
      let items: Record<string, any> = {};
      each(o.account_ids, a => {
        !!items[accountMapping[a]?.cloud_type!]
          ? items[accountMapping[a]?.cloud_type!]?.push(a)
          : (items[accountMapping[a]?.cloud_type!] = [a]);
      });

      mapping[o.category] = map(items, (i, key) => ({
        cloud: key,
        accounts: i,
      }));
    });
    setCategoryAccountsMapping(mapping);
  }, [accounts, accountMapping]);

  const renderSeverity = (count, label) => (
    <HStack spacing={1}>
      <Box>{formatNumber(count)}</Box>
      <Box>
        {label} {showOnlyCloudIcons ? '' : 'severity'}
      </Box>
    </HStack>
  );

  const getSeverity = severity => {
    if (!!severity?.CRITICAL) return 'critical';
    if (!!severity?.MEDIUM) return 'medium';
    if (!!severity?.HIGH) return 'high';
    if (!!severity?.LOW) return 'low';
  };

  const getCount = severity => {
    const s = getSeverity(severity);
    if (!s) return 0;
    return renderSeverity(severity?.[toUpper(s)], s);
  };

  const renderLegend = (category, count) => (
    <Center
      h="full"
      justifyContent="left"
      w="full"
      key={category + '-key-top-finding-legend'}
    >
      <HStack spacing={1} w="full">
        <HStack
          onClick={() => {
            navigate(
              '/findings?tab=' +
                (!!categoryClass ? categoryClass : 'AllFindings') +
                '&alertCategory=' +
                category +
                '&severity=' +
                getSeverity(count)?.toUpperCase() +
                '&redirect=true',
            );
          }}
          cursor="pointer"
          w="40%"
        >
          <Box boxSize={6} color="critical">
            <Center>{getIcon(category)}</Center>
          </Box>
          <Box w={showOnlyCloudIcons ? 40 : 'full'}>
            {toTitleCase(category)}
          </Box>
        </HStack>

        <Box w="30%">{getCount(count)}</Box>
        {!showOnlyCloudIcons && (
          <HStack w="30%">
            {map(
              categoryAccountsMapping?.[category]?.slice(0, numberOfAccounts),
              (o, key) =>
                renderAccount(
                  o?.accounts?.length +
                    ' account' +
                    (o?.accounts?.length === 1 ? '' : 's'),
                  1,
                  o?.cloud,
                ),
            )}
            {categoryAccountsMapping?.[category]?.length &&
              categoryAccountsMapping[category]?.length > numberOfAccounts && (
                <CustomTooltip
                  label={
                    <Stack>
                      <Box color="orange">Accounts</Box>
                      {map(
                        categoryAccountsMapping?.[category]?.slice(
                          numberOfAccounts,
                          categoryAccountsMapping?.[category]?.length,
                        ),
                        (o, index) => (
                          <HStack
                            align="flex-start"
                            key={index + 'top-findings-account'}
                          >
                            <Box boxSize={4}>{getIcon(o?.cloud)}</Box>
                            <Box>
                              {o?.accounts?.length +
                                ' Account' +
                                (o?.accounts?.length === 1 ? '' : 's')}
                            </Box>
                          </HStack>
                        ),
                      )}
                    </Stack>
                  }
                >
                  {renderAccount(
                    '+' +
                      (categoryAccountsMapping[category]?.length -
                        numberOfAccounts),
                    categoryAccountsMapping[category]?.length,
                  )}
                </CustomTooltip>
              )}
          </HStack>
        )}
        {showOnlyCloudIcons && (
          <HStack w="30%">
            {renderCloudIcons(
              map(categoryAccountsMapping?.[category], o => o.cloud),
            )}
          </HStack>
        )}
      </HStack>
    </Center>
  );

  return (
    <DashboardOverviewCard
      label="Findings to prioritize (by highest severity)"
      icon={<BlueprintViewIcon />}
      tooltip={tooltip}
      iconBgColor="critical"
      content={
        <Flex w="full" h="full" pl={2}>
          {openFindings.isLoading ? (
            <CustomSpinner />
          ) : isEmpty(openFindings.data) ? (
            <NoDataAvailable />
          ) : (
            <Stack h="full" w="full" fontSize="md">
              {map(
                slice(
                  !!categoryClass
                    ? openFindings.data?.[categoryClass]
                    : allCategories,
                  0,
                  3,
                ),
                o => renderLegend(o.category, o.severity),
              )}
              {renderEmptyRows(
                (!!categoryClass
                  ? openFindings.data?.[categoryClass]
                  : allCategories
                )?.length,
              )}
            </Stack>
          )}
        </Flex>
      }
    />
  );
};
