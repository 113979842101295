/**
 *
 * Asynchronously loads the component for CloudPostureOverview
 *
 */

import { lazy } from 'react';

export const CloudPostureOverview = lazy(() =>
  import('./index').then(m => ({ default: m.CloudPostureOverview })),
);
