import { RemediationApiCommandRemediationRequest } from '@ariksa/compliance-policies';
import {
  RemediationApiAggregateRemediationRequest,
  RemediationApiCheckAggregateCommandRemediationRequest,
  RemediationCheck,
  RulesApiGetAllComplianceStandardRequest,
  RulesApiGetDocumentsForCheckRequest,
} from '@ariksa/compliance-policies/api';
import {
  ContextApiGetRiskContextResRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  RelationshipApiGetConnectedEntitiesRequest,
  RiskContextResponse,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsPaginatedResponse,
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertsApiGetAggregatedAlertsRequest,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertsRequest,
  AlertsApiGetEntitySeverityListRequest,
  AlertsApiGetNotificationStatusRequest,
  AlertsApiGetTopIssuesRequest,
  EntitySeverityListResponse,
  NotificationWorkflowStatus,
  TopIssuesResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardSummaryRequest,
  DashboardCount,
} from '@ariksa/reporting';
import { PayloadAction } from '@reduxjs/toolkit';
import { each, forEach } from 'lodash';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

import { SideNavKey } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Dashboard container
export const initialState: ContainerState = {
  resourceCount: QueryState.init({} as any),
  topIssuesResolved: QueryState.init({} as any),

  alertHistoryByTrend: QueryState.init({}),
  alertHistoryByStatus: QueryState.init({}),

  aggregatedAlertRemediationAvailability: QueryState.init(
    {} as RemediationCheck,
  ),
  alertByRuleIdRemediationAvailability: QueryState.init({} as RemediationCheck),

  topOpenAlerts: {},
  topAggregatedAlerts: {},

  alertCategories: QueryState.init({}),
  currentCategory: 'Overview',
  alertFilters: {},
  aggregatedAlerts: PagedQueryState.init([]),
  resourceFailedChecks: PagedQueryState.init([]),

  alertDetailsSeverities: QueryState.init({ severity: {} }),
  alertsSummaryByCategory: QueryState.init({}),
  remediationCommands: QueryState.init([]),
  alertCountSummary: QueryState.init({ critical: 0, medium: 0, low: 0 }),
  alertsByRuleId: PagedQueryState.init([]),
  remediationByAlertRuleId: QueryState.init({}),
  prepareRemediation: null,

  findingDescription: QueryState.init({} as any),
  resourceVPC: PagedQueryState.init([]),
  executeRemediation: QueryState.init({}),
  alertWorkflowStatus: QueryState.init({}),
  getAlertLogs: QueryState.init({}),
  connectedEntities: PagedQueryState.init([]),
  riskContext: QueryState.init({} as Dict<RiskContextResponse[]>),
  compliance: QueryState.init({}),
  aggregatedAlertCompliance: QueryState.init({}),
};

const findingsOverviewSlice = createSlice({
  name: 'findingsOverview',
  initialState,
  reducers: {
    setPrepareRemediation(state, action: PayloadAction<any>) {
      state.prepareRemediation = action.payload;
    },
    loadAlertCategories(
      state,
      action: QueryAction<
        AggregatedAlertsSummaryResponse,
        AlertsApiGetAggregatedAlertsSummaryRequest
      >,
    ) {
      state.alertCategories = QueryState.next(state.alertCategories, action, {
        mapData: res => {
          let data: Record<string, any> = {};
          forEach(res.summary, (o, key) => {
            const options: Record<string, any> = {};
            forEach(o, c => (options[c.category] = c));
            data[key] = options;
          });
          return data;
        },
      });
    },

    updateCurrentCategory(state, action: PayloadAction<SideNavKey>) {
      state.currentCategory = action.payload;
    },

    getTopIssuesResolved(
      state,
      action: QueryAction<TopIssuesResponse, AlertsApiGetTopIssuesRequest>,
    ) {
      state.topIssuesResolved = QueryState.next(
        state.topIssuesResolved,
        action,
      );
    },
    loadResourceCount(
      state,
      action: QueryAction<DashboardCount, ChartsApiGetDashboardSummaryRequest>,
    ) {
      state.resourceCount = QueryState.next(state.resourceCount, action);
    },

    loadAlertHistoryByTrend(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.alertHistoryByTrend = QueryState.next(
        state.alertHistoryByTrend,
        action,
      );
    },
    loadAlertHistoryByStatus(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.alertHistoryByStatus = QueryState.next(
        state.alertHistoryByStatus,
        action,
      );
    },

    getAggregatedAlertRemediationAvailability(
      state,
      action: QueryAction<
        RemediationCheck,
        RemediationApiCheckAggregateCommandRemediationRequest
      >,
    ) {
      state.aggregatedAlertRemediationAvailability = QueryState.next(
        state.aggregatedAlertRemediationAvailability,
        action,
      );
    },
    getAlertByRuleIdRemediationAvailability(
      state,
      action: QueryAction<
        RemediationCheck,
        RemediationApiCheckAggregateCommandRemediationRequest
      >,
    ) {
      state.alertByRuleIdRemediationAvailability = QueryState.next(
        state.alertByRuleIdRemediationAvailability,
        action,
      );
    },

    getTopOpenAlerts(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      const id = action.payload.q.alertCategory;
      if (!!id)
        if (!state.topOpenAlerts[id]) {
          state.topOpenAlerts[id] = QueryState.init({} as any, {
            status: QueryStatus.pending,
          });
          return;
        } else {
          state.topOpenAlerts[id] = QueryState.next(
            state.topOpenAlerts[id],
            action,
          );
        }
    },

    getTopAggregatedAlerts(
      state,
      action: QueryAction<
        AggregatedAlertsPaginatedResponse,
        AlertsApiGetAggregatedAlertsRequest
      >,
    ) {
      const id = action.payload.q.alertCategory;
      if (!!id)
        if (!state.topAggregatedAlerts[id]) {
          state.topAggregatedAlerts[id] = QueryState.init({} as any, {
            status: QueryStatus.pending,
          });
          return;
        } else {
          state.topAggregatedAlerts[id] = QueryState.next(
            state.topAggregatedAlerts[id],
            action,
            { mapData: res => res?.aggregated_alerts },
          );
        }
    },

    getConnectedEntities(
      state,
      action: QueryAction<
        InsightV2Pagination,
        RelationshipApiGetConnectedEntitiesRequest
      >,
    ) {
      state.connectedEntities = PagedQueryState.next(
        state.connectedEntities,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r.total ?? 0,
        },
      );
    },
    executeRemediation(
      state,
      action: QueryAction<any, RemediationApiCommandRemediationRequest>,
    ) {
      state.executeRemediation = QueryState.next(
        state.executeRemediation,
        action,
      );
    },
    getResourceVPC(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.resourceVPC = PagedQueryState.next(state.resourceVPC, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total ?? 0,
      });
      action.payload.data?.items.forEach(item => {
        const found = state.alertsByRuleId.data.find(alert => {
          return alert.entity_id === item.resource_id;
        });
        //@ts-ignore
        if (found) found.vpc_name = item.vpc_id;
      });
    },
    getRemediationByAlertRuleId(
      state,
      action: QueryAction<any, RemediationApiAggregateRemediationRequest>,
    ) {
      state.remediationByAlertRuleId = QueryState.next(
        state.remediationByAlertRuleId,
        action,
        {},
      );
    },
    getAlertsByRuleId(
      state,
      action: QueryAction<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
    ) {
      state.alertsByRuleId = PagedQueryState.next(
        state.alertsByRuleId,
        action,
        {
          mapData: r => r.results,
          mapTotalCount: r => r.total_results,
        },
      );
    },
    /*getAlertCountSummary(
      state,
      action: QueryAction<
        AggregatedAlertsPaginatedResponse,
        AlertsApiGetAggregatedAlertsRequest
      >,
    ) {
      state.alertCountSummary = QueryState.next(
        state.alertCountSummary,
        action,
        {
          mapData: r =>
            JSON.parse(JSON.stringify(r.severity_summary ?? {}).toLowerCase()),
        },
      );
    },*/
    getRemediationCommands(
      state,
      action: QueryAction<any, RemediationApiCommandRemediationRequest>,
    ) {
      state.remediationCommands = QueryState.next(
        state.remediationCommands,
        action,
      );
    },
    updateAlterTableFilters(state, action: PayloadAction<Dict<any>>) {
      state.alertFilters = { ...state.alertFilters, ...action.payload };
    },
    updateAllAlertTableFilters(state, action: PayloadAction<Dict<any>>) {
      state.alertFilters = action.payload;
    },
    resetAlterTableFilters(state) {
      state.alertFilters = initialState.alertFilters;
    },

    loadAlertsSummary(state, action: QueryAction<any>) {
      state.alertsSummaryByCategory = QueryState.next(
        state.alertsSummaryByCategory,
        action,
      );
    },

    loadAlertsGroupedByAlertId(
      state,
      action: QueryAction<
        AggregatedAlertsPaginatedResponse,
        AlertsApiGetAggregatedAlertsRequest
      >,
    ) {
      state.aggregatedAlerts = PagedQueryState.next(
        state.aggregatedAlerts,
        action,
        {
          mapData: r => r.aggregated_alerts,
          mapTotalCount: r => r.total_results,
        },
      );
    },

    //get resource failed checks
    loadResourceFailedChecks(
      state,
      action: QueryAction<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
    ) {
      state.resourceFailedChecks = PagedQueryState.next(
        state.resourceFailedChecks,
        action,
        {
          mapTotalCount: r => r.total_results,
          mapData: r => r.results,
        },
      );
    },

    //get alert details severities
    loadAlertDetailsSeverity(
      state,
      action: QueryAction<
        EntitySeverityListResponse,
        AlertsApiGetEntitySeverityListRequest
      >,
    ) {
      state.alertDetailsSeverities = QueryState.next(
        state.alertDetailsSeverities,
        action,
      );
    },

    getFindingDescription(
      state,
      action: QueryAction<any, RulesApiGetDocumentsForCheckRequest>,
    ) {
      state.findingDescription = QueryState.next(
        state.findingDescription,
        action,
      );
    },

    getAlertWorkflowStatus(
      state,
      action: QueryAction<
        NotificationWorkflowStatus[],
        AlertsApiGetNotificationStatusRequest
      >,
    ) {
      state.alertWorkflowStatus = QueryState.next(
        state.alertWorkflowStatus,
        action,
        {
          mapData: r => {
            const data = {};
            r?.forEach(o => (data[o.alert_id] = o));
            return data;
          },
        },
      );
    },
    getAlertLogs(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.getAlertLogs = QueryState.next(state.getAlertLogs, action, {
        mapData: r => {
          const data = {};
          r?.forEach(o => (data[o.alert_id] = o.logs));
          return data;
        },
      });
    },
    getRiskContext(
      state,
      action: QueryAction<
        Dict<RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      if (action.payload.status === 'fulfilled') {
        state.riskContext.status = QueryStatus.fulfilled;
        each(action.payload.data, (value, key) => {
          state.riskContext.data[key] = value;
        });
      } else {
        state.riskContext.status =
          action.payload.status || QueryStatus.uninitialized;
      }
    },
    getCompliance(
      state,
      action: QueryAction<
        Dict<string[]>,
        RulesApiGetAllComplianceStandardRequest
      >,
    ) {
      if (action.payload.status === 'fulfilled') {
        state.compliance.status = QueryStatus.fulfilled;
        each(action.payload.data, (value, key) => {
          state.compliance.data[key] = value;
        });
      } else {
        state.compliance.status =
          action.payload.status || QueryStatus.uninitialized;
      }
    },
    getAggregatedAlertCompliance(
      state,
      action: QueryAction<
        Dict<string[]>,
        RulesApiGetAllComplianceStandardRequest
      >,
    ) {
      state.aggregatedAlertCompliance = QueryState.next(
        state.aggregatedAlertCompliance,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = findingsOverviewSlice;
