import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ChecklistIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" w="full" h="full" {...props}>
    <path
      d="M8.5,9h2C10.776123,9,11,8.776123,11,8.5S10.776123,8,
    10.5,8h-2C8.223877,8,8,8.223877,8,8.5S8.223877,9,8.5,9z M19.9611206,
    8.3074951c-0.0253906-0.06073-0.0620117-0.1154785-0.1079102-0.1613159l-5.9993896-5.9993896c-0.0458374-0.0458984-0.1005859-0.0825195-0.1613159-0.1079102C13.632019,
    2.0136108,13.5667725,2,13.5,2H7C5.3438721,2.0018311,4.0018311,3.3438721,4,5v14c0.0018311,
    1.6561279,1.3438721,2.9981689,3,3h10c1.6561279-0.0018311,2.9981689-1.3438721,
    3-3V8.5C20,8.4332275,19.9863892,8.367981,19.9611206,8.3074951z M14,3.7069702L18.2930298,
    8h-2.960022C14.5970459,7.9993896,14.0006104,7.4029541,14,6.6669922V3.7069702z M19,
    19c-0.0014038,1.1040039-0.8959961,1.9985962-2,
    2H7c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,
    2-2h6v3.6669922C13.0016479,7.9547729,14.0452271,8.9983521,15.3330078,9H19V19z M8,
    12.5C8,12.776123,8.223877,13,8.5,13h7c0.276123,0,0.5-0.223877,0.5-0.5S15.776123,
    12,15.5,12h-7C8.223877,12,8,12.223877,8,12.5z M15.5,16h-7C8.223877,16,8,16.223877,
    8,16.5S8.223877,17,8.5,17h7c0.276123,0,0.5-0.223877,0.5-0.5S15.776123,16,15.5,16z"
    />
  </Icon>
);
