import React from 'react';

import { Icon } from '@chakra-ui/react';

export const EcrImageIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M409.6,409.6H0V0h409.6V409.6z M25.6,384H384V25.6H25.6V384z M512,102.4h-76.8V128h51.2v358.4H128v-51.2h-25.6
	V512H512V102.4z M179.2,179.2H76.8V76.8h102.4V179.2z M102.4,153.6h51.2v-51.2h-51.2V153.6z M332.8,179.2H230.4V76.8h102.4V179.2z
	 M256,153.6h51.2v-51.2H256V153.6z M179.2,332.8H76.8V230.4h102.4V332.8z M102.4,307.2h51.2V256h-51.2V307.2z M332.8,332.8H230.4
	V230.4h102.4V332.8z M256,307.2h51.2V256H256V307.2z"
    />
  </Icon>
);
