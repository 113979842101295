import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';

export class CommonLayout {
  static layoutEdges<G extends AGraph<any, any>>(graph: G) {
    const { edges } = graph;

    const { nodeIdMap } = graph;
    edges.forEach(e => {
      const { id, source, dest } = e;
      const sourceNode = nodeIdMap.get(source);
      const destNode = nodeIdMap.get(dest);
      if (!sourceNode?.visible || !destNode?.visible) {
        e.visible = true;
      }

      if (!sourceNode! || !destNode) return;
      e.start.x = sourceNode.x + sourceNode.w;
      e.start.y = sourceNode.y + sourceNode.h / 2;
      e.end.x = destNode.x;
      e.end.y = destNode.y + destNode.h / 2;
    });
  }
}
