import React, { FC, useEffect, useState } from 'react';

import {
  TagCondition,
  TagKeyValuesConditionBase,
} from '@ariksa/inventory-core';
import { Box, Button, Stack } from '@chakra-ui/react';
import { omit, isEmpty, map, filter } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { actions } from 'containers/Inventory/Whitelist/slice';
import { deleteIconStyles } from 'containers/PolicyHub/AlertWorkflow/styles';

import { TagKeyValueComponent } from './TagKeyValueComponent';

export const TagKeyValueCriteria: FC = () => {
  const { tagKeyValueCriteria } = useSelector(selectWhitelist);
  const {
    tagKeyOptions,
    setSelectedConditions,
    selectedConditions,
  } = useWhitelistContext();
  const [criteria, setCriteria] = useState<
    Record<string, TagKeyValuesConditionBase>
  >({});
  const dispatch = useDispatch();

  useEffect(() => {
    setCriteria(tagKeyValueCriteria);
    !isEmpty(tagKeyValueCriteria) &&
      dispatch(
        actions.updateCriteria({
          field: 'tag_key_values_condition',
          value: map(tagKeyValueCriteria, o => o),
        }),
      );
  }, [tagKeyValueCriteria, dispatch]);

  return (
    <Stack spacing={4}>
      <Box>
        <PrimaryButton
          onClick={() => {
            const id = nanoid();
            dispatch(
              actions.updateTagKeyValuesCriteria({
                id,
                value: {
                  key: tagKeyOptions[0]?.value,
                  key_condition: TagCondition.Tagged,
                  value: [],
                  value_condition: TagCondition.Tagged,
                },
              }),
            );
          }}
          size="xs"
        >
          + Add
        </PrimaryButton>
      </Box>
      {map(Object.keys(criteria).reverse(), id => (
        <Box
          border="1px solid"
          py={2}
          pl={2}
          borderRadius={6}
          borderColor="componentBorder"
          key={id + 'tag-key-value'}
        >
          <Stack isInline>
            <TagKeyValueComponent id={id} />
            <Button
              {...deleteIconStyles}
              onClick={() => {
                const rest = omit(tagKeyValueCriteria, id);
                if (isEmpty(rest)) {
                  setSelectedConditions(
                    filter(
                      selectedConditions,
                      o => o.value !== 'tag_key_values_condition',
                    ),
                  );
                  dispatch(
                    actions.updateCriteria({
                      field: 'tag_key_values_condition',
                      value: [],
                    }),
                  );
                }
                dispatch(actions.updateTagKeyValuesCriterion(rest));
              }}
            >
              <TrashIcon />
            </Button>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
