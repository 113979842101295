import React, { FC } from 'react';

import { GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectVisibility } from 'containers/Visibility/selectors';

import { MetadataField } from './MetadataField/MetadataField';

export interface Props {}

export const CommonAPIActivity: FC<Props> = props => {
  const { nodeDetails } = useSelector(selectVisibility);
  const { results } = nodeDetails;

  return (
    <>
      <GridItem colSpan={1}>
        <MetadataField
          label="Total API actions"
          value={results?.total_event_count}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <MetadataField label="Success" value={results?.success_event_count} />
      </GridItem>
      <GridItem colSpan={1}>
        <MetadataField label="Fail" value={results?.failure_event_count} />
      </GridItem>

      <GridItem colSpan={1}>
        <MetadataField
          label="Unauthorized actions"
          value={results?.unauthorized_event_count}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <MetadataField
          label="Access denied actions"
          value={results?.access_denied_count}
        />
      </GridItem>
    </>
  );
};
