import React, { useEffect, useState } from 'react';

import { forEach, map, toArray } from 'lodash';
import { useSelector } from 'react-redux';

import { colorMapForCharts } from 'components/DataDisplay';
import { BarChart } from 'components/Visualization/Charts';
import { barChartStyles } from 'containers/Visibility/Data/Components/Dashboard/Charts/styles';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { formatDate } from 'utils/date';

export const SensitiveDataByRegionTrend = () => {
  const { sensitiveDataByRegionTrend } = useSelector(selectDataDashboard);
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [regions, setRegions] = useState<string[]>([]);

  useEffect(() => {
    const d: Record<string, any> = {};
    const regionsObject = new Set();
    forEach(sensitiveDataByRegionTrend.data, (o, type) => {
      forEach(o.region_data, l => {
        const date = formatDate(l?.created_at, 'D-MMM');
        regionsObject.add(type);
        d[date] = {
          // [type]: ceil((l?.data_count ?? 0) / 1000),
          [type]: l?.data_count,
          ['actual' + type]: l?.data_count,
          ...d[date],
        };
      });
    });
    setData(
      map(d, (o, date) => ({
        Date: date,
        ...o,
      })),
    );
    setRegions(toArray(regionsObject) as string[]);
  }, [sensitiveDataByRegionTrend.data]);

  return (
    <BarChart
      data={data}
      isLoading={sensitiveDataByRegionTrend.isLoading}
      xField="Date"
      yField={regions}
      colorMap={colorMapForCharts}
      legend={false}
      stacked
      styles={barChartStyles(regions)}
    />
  );
};
