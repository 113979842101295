import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  Email,
  FeatureSet,
  OrgUnits,
  NumberOfRoles,
  NumberOfUsers,
  IdentityPolicies,
  ResourcePolicies,
  TagPolicies,
  SCP,
  OrgAccounts,
  NumberOfGroups,
  Name,
  AccountID,
  CloudID,
  ARN,
  CreatedAt,
  Owner,
  Region,
} from '../../Components/MetadataField/MetaGridFields';

export const OrganizationMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountID(insight)}
      {Region(insight)}
      {CloudID(insight)}
      {ARN(insight)}
      {Tags(resource)}
      {CreatedAt(resource, 'dayjs')}
      {Owner(insight)}
      {OrgUnits(resource)}
      {OrgAccounts(resource)}
      {Email(resource)}
      {FeatureSet(resource)}
      {NumberOfUsers(insight)}
      {NumberOfGroups(insight)}
      {NumberOfRoles(insight)}
      {/*<PolicyDocument
          label={'Identity Policies'}
          value={insight?.identity_policies}
          isString
          name={insight?.identity_policies?.length}
        />*/}
      {IdentityPolicies(insight)}
      {ResourcePolicies(insight)}
      {TagPolicies(insight)}
      {SCP(insight)}
    </Grid>
  );
};
