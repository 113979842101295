/**
 *
 * Update repo
 *
 */

import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { useParams } from 'react-router-dom';

import { EditRepository } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/IACRepository/EditRepository';
import { OnboardIACRepository } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/IACRepository/OnboardIACRepository';

export const EditIACRepository = () => {
  const params = useParams<{
    provider: string;
    id: string;
    accountType: string;
  }>();

  return params.accountType === 'iac' ? (
    <EditRepository />
  ) : (
    <OnboardIACRepository isEdit provider={params.provider as CloudProviders} />
  );
};
