import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container } from 'pixi.js';

import { CommonNode } from 'components/Visualization/PixiGraph/common/Node';
import { NodeElements } from 'components/Visualization/PixiGraph/common/NodeElements';
import { NodeType } from 'components/Visualization/PixiGraph/common/NodeType';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { SQUARE_NODE_SIZE } from 'components/Visualization/PixiGraph/style';

import { AGraph } from '../../../core/Graph';
import { AGraphNode, AGraphNodeProps } from '../../../core/Node';

export class PostureMapNode extends AGraphNode {
  constructor(props: AGraphNodeProps) {
    super(props);
    this.x = 0;
    this.y = 0;
    const isInternet = props.nodeType === 'Internet';
    const size = isInternet ? SQUARE_NODE_SIZE * 1.6 : SQUARE_NODE_SIZE;

    this.w = size;
    this.h = size;

    this.showResourceType = true;

    this.data.info =
      this.data.ariksaType?.resource_alias ??
      this.data.ariksaType?.cloud_native_name;
  }

  render(g: Graphics, container: Container): void {
    if (!this.isVisible) return;

    CommonNode.render(this, g, container);

    if (NodeType.isEndpoint(this)) {
      NodeElements.renderTrafficIcon(this, g);
    }
    NodeElements.renderSeverities(this, g);
  }

  renderContext(g: Graphics, container: Container) {
    NodeElements.renderContext(this, g, container);
  }

  renderActive(g: Graphics) {
    CommonNode.renderActive(this, g);
  }

  renderHover(g: Graphics) {
    CommonNode.renderHover(this, g);
  }

  addInteractiveElement(graph: AGraph<AGraphNode, AGraphEdge>, index: number) {
    super.addInteractiveElement(graph, index);

    const { x, y, w, h, data } = this;
    const { riskContext = [] } = data;

    if (data.severities) {
      NodeElements.addInteractiveSeverityElement(graph, this, index);
    }

    if (riskContext) {
      NodeElements.addInteractiveRiskContextElement(graph, this, index);
    }
  }
}
