import React from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import map from 'lodash/map';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { EnvironmentName } from 'containers/App/utils';

export const renderWhitelistEnvironments = ({ value }) => (
  <CustomTooltip
    label={
      <Stack spacing={1}>
        <Box>Environment(s):</Box>
        {map(value, (o, index) => (
          <HStack spacing={2}>
            <Box>{index + 1}.</Box>
            <Box>
              <EnvironmentName environmentId={o.environment_id} />
            </Box>
          </HStack>
        ))}
      </Stack>
    }
  >
    {value?.length}
  </CustomTooltip>
);

export const renderWhitelistCriteria = ({ row }) => {
  const region = !isEmpty(row?.region);
  const criteria = !isEmpty(row?.criteria);
  if (region || criteria) {
    return (
      <HStack spacing={1}>
        {!!row?.region && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(row?.region, r => (
                      <Box>{r}</Box>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {row?.region?.length} {'region'}
                  {row?.region?.length > 1 ? 's' : ''}
                </Box>
              </CustomTooltip>
            }
          />
        )}
        {criteria && !!row?.criteria?.tag_keys_condition?.key?.length && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    <Box>
                      Condition:{' '}
                      {row?.criteria?.tag_keys_condition?.key_condition}
                    </Box>
                    <Box>Keys</Box>
                    {map(row?.criteria?.tag_keys_condition?.key, r => (
                      <Box pl={2}>{r}</Box>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {row?.criteria?.tag_keys_condition?.key?.length} {'tag key'}
                  {row?.criteria?.tag_keys_condition?.key?.length > 1
                    ? 's'
                    : ''}
                </Box>
              </CustomTooltip>
            }
          />
        )}
        {criteria && !!row?.criteria?.resource_type_criteria?.length && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(row?.criteria?.resource_type_criteria, r => (
                      <Box>{r?.resource_type}</Box>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {row?.criteria?.resource_type_criteria?.length}{' '}
                  {'resource type'}
                  {row?.criteria?.resource_type_criteria?.length === 1
                    ? ''
                    : 's'}
                </Box>
              </CustomTooltip>
            }
          />
        )}
        {criteria && !!row?.criteria?.tag_key_values_condition?.length && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(row?.criteria?.tag_key_values_condition, r => (
                      <HStack>
                        <Box>{r?.key}:</Box>
                        <Box>{r?.value}</Box>
                      </HStack>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {row?.criteria?.tag_key_values_condition?.length}{' '}
                  {'tag key + value'}
                  {row?.criteria?.tag_key_values_condition?.length === 1
                    ? ''
                    : 's'}
                </Box>
              </CustomTooltip>
            }
          />
        )}
      </HStack>
    );
  } else return '-';
};
