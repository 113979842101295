import {
  CountSummary,
  ResourceSummaryApiGetSummaryByTypeRequest,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

interface Props
  extends Pick<
    ResourceSummaryApiGetSummaryByTypeRequest,
    'summaryType' | 'category' | 'nativeResource'
  > {}
export function useResourceSummaryService(props: Props) {
  const { accountId, environmentId } = useAccessBoundary();

  const resourceSummary = useAriksaQuery<
    ResourceSummaryApiGetSummaryByTypeRequest,
    CountSummary
  >({
    queryKey: [
      accountId,
      environmentId,
      props.summaryType,
      props.category,
      props.nativeResource,
    ],
    queryFn: () =>
      InventoryService.ResourceSummary.getSummaryByType({
        accountId: accountId ? [accountId] : [],
        environmentId,
        ...props,
      }).then(r => r.data),
  });

  return { resourceSummary };
}
