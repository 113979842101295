import React from 'react';

import { HStack, UseDisclosureProps } from '@chakra-ui/react';

import { BlueprintPolicies } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies';
import { BlueprintGeneralDetails } from 'containers/PolicyHub/Blueprints/Components/Blueprint/Details/BlueprintGeneralDetails';
import { BlueprintSummary } from 'containers/PolicyHub/Blueprints/Components/Blueprint/Details/BlueprintSummary';
import { useBlueprint } from 'containers/PolicyHub/Blueprints/hooks/context';

interface Props {
  getBlueprint();
  addPolicy: UseDisclosureProps;
}

export const BlueprintDetails = (props: Props) => {
  const { getBlueprint, addPolicy } = props;
  const { showPolicies } = useBlueprint();

  return showPolicies ? (
    <BlueprintPolicies addPolicy={addPolicy} />
  ) : (
    <HStack>
      <BlueprintGeneralDetails getBlueprint={getBlueprint} />
      <BlueprintSummary getBlueprint={getBlueprint} />
    </HStack>
  );
};
