/**
 *
 * Bar Progress
 *
 */
import React from 'react';

import { Box, HStack, BoxProps } from '@chakra-ui/react';

export interface BarProgressProps extends BoxProps {
  value: number;
  total: number;
}

export const BarProgress: React.FC<BarProgressProps> = props => {
  const { value, total, color = '#9cc960', ...rest } = props;
  const w = (value / total) * 100;

  return (
    <HStack
      w="full"
      borderRadius={1}
      h="10px"
      overflow="visible"
      spacing="1px"
      {...rest}
    >
      {!!value && (
        <Box
          w={`${w}%`}
          maxW="full"
          bg={color}
          h="full"
          borderRadius={1}
          position="relative"
        />
      )}
      <Box w={100 - w + '%'} h="full" bg="#cb776f" borderRadius={1} />
    </HStack>
  );

  /*return (
    <Progress
      colorScheme={'green'}
      size="md"
      min={0}
      borderRadius={6}
      border="1px solid"
      borderColor="white"
      {...props}
    />
  );*/
};
