import React, { FC } from 'react';

import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';

import { tooltipProps } from 'components/DataEntry/Button/styles';

export const CustomTooltip: FC<TooltipProps & { styles?: any }> = props => {
  const { children, styles, ...rest } = props;
  return (
    <Tooltip {...tooltipProps} {...rest}>
      <Box overflow="hidden" textOverflow="ellipsis" {...styles?.text}>
        {children}
      </Box>
    </Tooltip>
  );
};
