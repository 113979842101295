/**
 *
 * Asynchronously loads the component for Reports
 *
 */

import { lazy } from 'react';

export const SetupUsers = lazy(() =>
  import('./index').then(m => ({ default: m.SetupUsers })),
);
