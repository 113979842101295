import React from 'react';

import { Tooltip } from '@chakra-ui/react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { StackedCell } from 'components/DataDisplay';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const renderTime = ({ value }) => {
  if (value === '-') {
    return value;
  }

  if (value) {
    return (
      <Tooltip
        label={timeTooltipLabel(value)}
        hasArrow
        placement="top"
        borderRadius={6}
      >
        {timeLabel(value)}
      </Tooltip>
    );
  }

  return '-';
};

export const timeTooltipLabel = value =>
  dayjs.utc(value).local().format('LLLL');

export const timeLabel = value =>
  dayjs.utc(value).local().format('DD-MMM-YY hh:mm A');

export const dateLabel = value => dayjs.utc(value).local().format('DD-MMM-YY');

export const timeSince = value => dayjs.utc(value).local().fromNow();

export const renderTimeSince = ({ value }) => {
  if (value === '-') {
    return value;
  }

  if (value) {
    return (
      <Tooltip
        label={timeTooltipLabel(value)}
        hasArrow
        placement="top"
        borderRadius={6}
      >
        {timeSince(value)}
      </Tooltip>
    );
  }

  return '-';
};

export const renderTimeStackedCell = ({ value }) => {
  if (!value || value === '-') return '-';

  if (value) {
    return (
      <StackedCell
        upper={dateLabel(value)}
        lower={dayjs.utc(value).local().format('hh:mm A')}
        showLowerTooltip={false}
        showUpperTooltip={false}
      />
    );
  }

  return '-';
};
