import { min, max } from 'lodash';

import { AGraphNode } from './Node';

interface NodeBoundProps {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export class ABound {
  left: number;
  right: number;
  top: number;
  bottom: number;

  static default() {
    return new ABound({ left: 0, right: 0, top: 0, bottom: 0 });
  }

  constructor(props: NodeBoundProps) {
    const { left, top, right, bottom } = props;
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
  }

  update(node: AGraphNode, bounds: ABound[]) {
    const { x, y, w, h } = node;
    this.left = min([...bounds.map(c => c.left), x])!;
    this.right = max([...bounds.map(c => c.right), x + w])!;
    this.top = min([...bounds.map(c => c.top), y])!;
    this.bottom = max([...bounds.map(c => c.bottom), y + h])!;
  }
}
