import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RedHatIcon = props => (
  <Icon viewBox="0 -31 256 256" h="full" w="full" {...props}>
    <g>
      <path
        d="M170.758031,111.610687 C187.582343,111.610687 211.924474,108.140925 211.924474,88.1292728 C211.974775,86.5848226 211.834752,85.0400539 211.507565,83.5298204 L201.48829,40.009856 C199.175115,30.434388 197.144363,26.0904607 180.333499,17.6850288 C167.288269,11.014478 138.871184,0 130.465752,0 C122.638614,0 120.365785,10.0865183 111.032394,10.0865183 C102.048668,10.0865183 95.3781173,2.55525131 86.9726853,2.55525131 C78.9034707,2.55525131 73.6450325,8.05576597 69.5835277,19.3661152 C69.5835277,19.3661152 58.2731785,51.2664105 56.8207199,55.8927602 C56.5898643,56.7423115 56.4900906,57.6221338 56.5248487,58.5018063 C56.5248487,70.9014995 105.343597,111.556893 170.758031,111.556893 M214.506623,96.2522822 C216.833247,107.26676 216.833247,108.423348 216.833247,109.875806 C216.833247,128.703974 195.665007,139.153607 167.839665,139.153607 C104.953585,139.193953 49.8677466,102.344539 49.8677466,77.9889597 C49.8641113,74.5987697 50.555245,71.2437963 51.898499,68.1310691 C29.2778005,69.2607592 0,73.2953665 0,99.1437508 C0,141.48023 100.313787,193.661152 179.741757,193.661152 C240.63743,193.661152 255.995835,166.118232 255.995835,144.371699 C255.995835,127.264964 241.202275,107.845054 214.53352,96.2522822"
        fill="#EE0000"
      />
      <path
        d="M214.506623,96.2522822 C216.833247,107.26676 216.833247,108.423348 216.833247,109.875806 C216.833247,128.703974 195.665007,139.15364 167.839665,139.15364 C104.953585,139.193953 49.8677323,102.344539 49.8677323,77.9889597 C49.8641113,74.5987697 50.555245,71.2437963 51.898499,68.1310691 L56.8207199,55.946555 C56.5952566,56.7787916 56.4955298,57.6400686 56.5248487,58.5018063 C56.5248487,70.9014995 105.343597,111.556893 170.758031,111.556893 C187.582343,111.556893 211.924474,108.08713 211.924474,88.075478 C211.974775,86.5310279 211.834752,84.9862591 211.507565,83.4760257 L214.506623,96.2522822 Z"
        fill="#000000"
      />
    </g>
  </Icon>
);
