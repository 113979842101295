import G6, { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { MapType } from 'containers/Visibility/types';
import { toPascalCase } from 'utils/string';

import { COMMAND_NODE } from './nodes_types';

const nodeStyles = {
  Read: {
    fill: colors.yellow['50'],
    stroke: colors.yellow['200'],
  },
  Write: {
    fill: colors.blue['50'],
    stroke: colors.blue['200'],
  },
  Tagging: {
    fill: colors.red['50'],
    stroke: colors.red['200'],
  },
  List: {
    fill: colors.green['50'],
    stroke: colors.green['200'],
  },
  PermissionsManagement: {
    fill: colors.orange['50'],
    stroke: colors.orange['200'],
  },
};

export function registerCommandNode() {
  G6.registerNode(
    COMMAND_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const cr = 10;
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: cr,
              height: 40,
              // fill: colors.blue['50'],
              // stroke: colors.blue['200'],
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();
        // @ts-ignore
        const mapType = cfg?.data?.mapType;
        // @ts-ignore
        const data = cfg?.data ?? ({} as any);

        const cx = x + cr;
        const cy = y + height / 2;

        const createCircle = props => {
          group.addShape('circle', {
            attrs: {
              x: cx,
              y: cy,
              r: cr,

              ...props,
            },
            // must be assigned in G6 3.3 and later versions. it can be any value you want
            name: 'creat-shape',
          });
        };

        const createText = props => {
          group.addShape('text', {
            attrs: {
              textAlign: 'left',
              textBaseline: 'start',
              // @ts-ignore
              fontSize: 12,
              // // fontStyle: 'bold',
              opacity: 1,
              fill: colors.black,
              cursor: 'pointer',
              ...props,
            },
            name: 'label',
          });
        };

        createCircle({
          ...(nodeStyles[toPascalCase(data?.access_level ?? '-')] ?? {}),
        });

        createText({
          text: data?.access_level ?? '-',
        });

        const showExcessPermissionCount =
          mapType === MapType.ResourceExcessPermissions ||
          mapType === MapType.UserExcessPermissions ||
          mapType === MapType.MachineExcessPermissions ||
          mapType === MapType.RoleExcessPermissions ||
          mapType === MapType.RoleNetAccess;

        if (showExcessPermissionCount) {
          createText({
            text: `${data?.isTotal ? 'Total' : 'Excess'}: ${
              data?.excess_permission_count ?? '-'
            }`,
            x: width + 20,
            y: height + 50,
          });
        }

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
