import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CloudFormationIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M210.2,486.7l-43-12.9L301.8,25.3l43,12.9L210.2,486.7z M384,384l-31.7-31.7l96.3-96.3l-96.3-96.3L384,128
	l128,128L384,384z M128,384L0,256l128-128l31.7,31.7L63.4,256l96.3,96.3L128,384z"
    />
  </Icon>
);
