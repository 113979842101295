import { PayloadAction } from '@reduxjs/toolkit';
import { call, select, takeLatest } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { authOptions } from 'api/auth';
import {
  UserService,
  UserServiceCreateRequest,
  UserServiceDeleteRequest,
  UserServiceGetRequest,
  UserServiceUpdateRequest,
  UserServiceUpdateResponse,
} from 'api/auth/api.pb';
import { selectUser } from 'containers/App/selectors';
import { callApiFn } from 'utils/saga';

import { actions } from './slice';

export function* setupUsersSaga() {
  yield takeLatest(actions.createUser.type, doCreateUser);
  yield takeLatest(actions.deleteUser.type, doDeleteUser);
  yield takeLatestAction(actions.updateUser, doUpdateUser);
  yield takeLatest(actions.loadUsers.type, doGetUsers);
  yield takeLatest(actions.getUserById.type, doGetUserById);
  yield takeLatest(actions.getUserByEmail.type, doGetUserByEmail);
}

export function* doGetUserByEmail(
  action: PayloadAction<UserServiceGetRequest>,
) {
  yield call(callApiFn, {
    fn: UserService.GetByEmail,
    data: action.payload,
    onSuccess: actions.getUserByEmailSuccess,
    onError: actions.getUserByEmailError,
    errorTitle: 'Failed to get user.',
  });
}

export function* doGetUserById(action: PayloadAction<UserServiceGetRequest>) {
  yield call(callApiFn, {
    fn: UserService.Get,
    data: action.payload,
    onSuccess: actions.getUserByIdSuccess,
    onError: actions.getUserByIdError,
    errorTitle: 'Failed to get user.',
  });
}

export function* doUpdateUser(
  ctx: QueryContext<UserServiceUpdateResponse, UserServiceUpdateRequest>,
) {
  yield call(ctx.fetch, () => UserService.Update(ctx.params, authOptions()), {
    errorMsg: 'Failed to update user.',
  });
}

export function* doCreateUser(action: PayloadAction<UserServiceCreateRequest>) {
  yield call(callApiFn, {
    fn: UserService.Create,
    data: action.payload,
    onSuccess: actions.createUserSuccess,
    onError: actions.createUserError,
    errorTitle: 'Failed to create user.',
  });
}

export function* doDeleteUser(action: PayloadAction<UserServiceDeleteRequest>) {
  yield call(callApiFn, {
    fn: UserService.Delete,
    data: action.payload,
    onSuccess: actions.deleteUserSuccess,
    onError: actions.deleteUserError,
    errorTitle: 'Failed to delete user.',
  });
}

export function* doGetUsers(action: PayloadAction<any>) {
  const { info } = yield select(selectUser);
  const data = { organization: info.org_id };

  yield call(callApiFn, {
    fn: UserService.List,
    data,
    onSuccess: actions.loadUsersSuccess,
    onError: actions.loadUsersError,
    errorTitle: 'Failed to load users.',
  });
}
