import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.alerts || initialState;

export const selectAlerts = createSelector(
  [selectDomain],
  alertsState => alertsState,
);
