import React from 'react';

import { StackedCell } from 'components/DataDisplay';
import { renderVulnerabilities } from 'containers/Inventory/CloudInventory/Components/utils';

export const renderFindings = ({ value }) => {
  return renderVulnerabilities({ value });
};

export const renderDeployedIn = ({ row }) => (
  <StackedCell upper={row?.vpc} lower={row?.region} />
);
