/**
 *
 * Email Integration
 *
 */

import React, { memo } from 'react';

import { Clients } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { Elastic } from './Elastic';
import { SIEMTable } from './SIEMTable';

interface Props {}

export const SIEM = memo((props: Props) => {
  const { selectedProvider, isOpen } = useIntegrationsContext();

  const getComponent = () => {
    let component = <Box />;
    switch (selectedProvider) {
      case Clients.Elasticsearch:
        component = <Elastic />;
        break;
    }
    return component;
  };

  return (
    <Stack spacing={4} h="full">
      {isOpen ? getComponent() : <SIEMTable />}
    </Stack>
  );
});
