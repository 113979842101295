import React, { useState, FC, useMemo } from 'react';

import { Box, Stack, Button } from '@chakra-ui/react';
import { map, filter } from 'lodash';
//import { nanoid } from 'nanoid';

import { Select } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import {
  deleteIconStyles,
  valueContainerStyles,
} from 'containers/PolicyHub/AlertWorkflow/styles';

import { StatusComponent } from './StatusComponent';

export const WhitelistStatus: FC = () => {
  const { resetCriteria } = useWhitelistContext();

  const conditions = useMemo(
    () => [
      {
        label: 'allow list status is',
        value: 'status',
        component: <StatusComponent />,
      },
    ],
    [],
  );

  const [selectedConditions, setSelectedConditions] = useState<
    Record<string, any>[]
  >([conditions[0]]);

  //set selected conditions
  /*useEffect(() => {
    setSelectedConditions(
      filter(conditions, o => !isEmpty(criteria?.[o.value])),
    );
  }, [conditions, criteria]);*/

  return (
    <Stack>
      <Box mb={2}>
        <Select
          options={conditions}
          value={selectedConditions}
          isMulti
          showTotalSelected={selectedConditions.length > 0}
          placeholder="Choose conditions..."
          onChange={selected => {
            setSelectedConditions(selected);
            /*if (
                  some(selected, ['value', 'tag_key_values_condition']) &&
                  isEmpty(criteria?.tag_key_values_condition)
                )
                  dispatch(
                    actions.updateKeyValueCriteria({
                      id: nanoid(),
                      value: {
                        key: tagKeyOptions[0].value,
                        key_condition: TagCondition.Is,
                        value_condition: TagCondition.Is,
                        value: [],
                      },
                    }),
                  );*/
          }}
        />
      </Box>
      {map(selectedConditions, each => (
        <Stack
          {...valueContainerStyles}
          key={each?.value + '-environment-condition'}
        >
          <Box w="full">{each?.component}</Box>
          <Button
            {...deleteIconStyles}
            onClick={() => {
              setSelectedConditions(
                filter(selectedConditions, o => o?.value !== each?.value),
              );
              resetCriteria(each?.value);
            }}
          >
            <TrashIcon />
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};
