import React from 'react';

import { CheckmarkCircleIcon, Tag } from 'app/components';

export const ReadOnlyTag = () => {
  return (
    <Tag
      label="READ-ONLY"
      leftIcon={<CheckmarkCircleIcon w={6} h={6} />}
      styles={{
        tag: {
          border: '1px solid',
          borderRadius: 'md',
          borderColor: 'gray.100',
          bg: 'white',
          px: 4,
          py: 2,
          fontSize: 14,
        },
        leftIcon: {
          color: 'green.300',
          pr: 2,
        },
      }}
    />
  );
};
