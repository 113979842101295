import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import { Dashboard } from 'containers/Reports/ReportsDashboard/Components/Dashboard';
import { EditDashboard } from 'containers/Reports/ReportsDashboard/Components/EditDashboard';
import { useReportsDashboard } from 'containers/Reports/ReportsDashboard/hooks/useReportsDashboard';
import { useInjector } from 'utils/inject';

import { reportsDashboardSaga } from './saga';
import { sliceKey, reducer } from './slice';

export const ReportsDashboard = () => {
  useInjector(sliceKey, reducer, reportsDashboardSaga);
  const { getReportingCharts } = useReportsDashboard();

  useEffect(() => {
    getReportingCharts();
  }, [getReportingCharts]);

  return (
    <Routes>
      <Route path="edit" element={<EditDashboard />} />
      <Route index element={<Dashboard />} />
    </Routes>
  );
};
