import React, { FC, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { Box, HStack, Square, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCloudIcon,
  getProviderName,
  Heading4,
} from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { PrimaryButton, Select } from 'components/DataEntry';
import { StopwatchIcon } from 'components/Icons/ReactCustomIcons/StopwatchIcon';
import { IModalProps, Modal } from 'components/Overlay';
import { successToast } from 'components/Toast';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { actions } from '../slice';

interface IChangeAccountDiscoveryModal extends IModalProps {
  account: CloudAccountGetResponse;
}

export const ChangeAccountDiscoveryModal: FC<IChangeAccountDiscoveryModal> = props => {
  const { account, onClose, isOpen } = props;
  const dispatch = useDispatch();
  const [discoveryInterval, setDiscoveryInterval] = useState(
    account.discovery_interval ?? 3 * 60,
  );
  const { updateAccountDiscoveryInterval } = useSelector(selectCloudAccounts);
  const { getCloudAccounts } = useCloudAccountsFunctions();
  const [intervalOptions] = useState([
    { label: '3 hours', value: 3 * 60 },
    { label: '6 hours', value: 6 * 60 },
    { label: '12 hours', value: 12 * 60 },
    { label: '24 hours', value: 24 * 60 },
  ]);

  const handleUpdate = data => {
    dispatch(
      actions.updateAccountDiscoveryInterval({
        q: {
          cloudAccountConfigRequest: {
            discovery_interval: discoveryInterval ?? 120,
            uuid: [account.uuid],
          },
        },
        onSuccess: () => {
          successToast({
            title: 'Successfully updated cloud account discovery interval',
          });
          getCloudAccounts();
          onClose();
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={{ modal: { size: 'xl' } }}
      isCentered
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <StopwatchIcon />
          </Square>
          <Heading4>Change Discovery Schedule</Heading4>
        </HStack>
      }
      body={
        <Stack>
          <Stack spacing={6} pt={6} pb={16}>
            <DetailsField
              label={'Cloud Account'}
              value={account.cloud_account_id ?? '-'}
            />
            <DetailsField
              label={'Cloud'}
              value={
                <HStack spacing={2}>
                  {getCloudIcon(account.cloud_type ?? '-')}
                  <Text>{getProviderName(account.cloud_type ?? '-')}</Text>
                </HStack>
              }
            />
            <HStack spacing={6} w={'full'}>
              <Text flex={1}>Perform discovery of resources</Text>
              <Box w={'140px'}>
                <Select
                  options={[
                    { label: 'every', value: 'every' },
                    { label: 'at time', value: 'at time' },
                  ]}
                  value={{ label: 'every', value: 'every' }}
                />
              </Box>
              <Box w={'140px'}>
                <Select
                  options={intervalOptions}
                  value={{
                    label: discoveryInterval / 60 + ' hours',
                    value: discoveryInterval,
                  }}
                  onChange={opt => {
                    setDiscoveryInterval(opt.value);
                  }}
                />
              </Box>
            </HStack>
          </Stack>
          <HStack justifyContent={'space-between'}>
            <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
            <PrimaryButton
              isLoading={updateAccountDiscoveryInterval.isLoading}
              onClick={handleUpdate}
            >
              Update
            </PrimaryButton>
          </HStack>
        </Stack>
      }
    />
  );
};
