import React, { FC, useState } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Card, H3 } from 'components/DataDisplay';
import { BackIconButton, DownloadIconButton } from 'components/DataEntry';
import { ComparePolicy } from 'containers/Compliance/Components/ComparePolicies/ComparePolicy';
import { PolicyPieCharts } from 'containers/Compliance/Components/ComparePolicies/PolicyPieCharts';

import { ChooseBlueprints } from './ChooseBlueprints';
import {
  ComparePoliciesProvider,
  ComparePoliciesContextProps,
} from './context';

export const ComparePolicies: FC = () => {
  const navigate = useNavigate();
  const [firstEnvironment, setFirstEnvironment] = useState<Record<string, any>>(
    {},
  );
  const [secondEnvironment, setSecondEnvironment] = useState<
    Record<string, any>
  >({});

  const [firstBlueprint, setFirstBlueprint] = useState<Record<string, any>>({});
  const [secondBlueprint, setSecondBlueprint] = useState<Record<string, any>>(
    {},
  );

  const value: ComparePoliciesContextProps = {
    firstBlueprint,
    firstEnvironment,
    secondBlueprint,
    secondEnvironment,
    setFirstEnvironment,
    setFirstBlueprint,
    setSecondBlueprint,
    setSecondEnvironment,
  };

  return (
    <ComparePoliciesProvider value={value}>
      <Stack w="full" spacing={5} h={'full'}>
        <HStack justify="space-between" w="full">
          <HStack>
            <BackIconButton onClick={() => navigate(-1)} mr={2} />
            <H3>Policy Blueprint Comparison</H3>
          </HStack>
          <DownloadIconButton aria-label="download-compare-policies" />
        </HStack>
        <Box flex={1}>
          <Card styles={{ card: { h: 'full' } }}>
            <Stack w="7xl" spacing={10}>
              <ChooseBlueprints />
              <PolicyPieCharts />
              <ComparePolicy />
            </Stack>
          </Card>
        </Box>
      </Stack>
    </ComparePoliciesProvider>
  );
};
