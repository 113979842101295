import React from 'react';

import { Box, HStack, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { some } from 'lodash';
import map from 'lodash/map';

import {
  renderSeverityBarWithCount,
  renderSeverityCountBubble,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

export const renderLocatedIn = ({ value }) => {
  let len = value?.length;
  if (len === 1 && !value[0]) len = 0;
  return !!len ? (
    <CustomTooltip
      label={
        <Stack>
          {map(value, o => (
            <Box>{o}</Box>
          ))}
        </Stack>
      }
    >
      {len} region{len > 1 ? 's' : ''}
    </CustomTooltip>
  ) : (
    '0 regions'
  );
};

export const renderVulnerabilities = ({ value }) => {
  const hasVulnerability = some(value, o => o > 0);
  return hasVulnerability ? (
    <Wrap spacing={3}>
      <WrapItem>
        <HStack spacing={3}>
          {(value?.CRITICAL || value?.critical) && (
            <Box>
              {renderSeverityCountBubble(
                'critical',
                value?.critical ?? value?.CRITICAL,
              )}
            </Box>
          )}
          {(value?.HIGH || value?.high) && (
            <Box>
              {renderSeverityCountBubble('high', value?.high ?? value?.HIGH)}
            </Box>
          )}
          {(value?.MEDIUM || value?.medium) && (
            <Box>
              {renderSeverityCountBubble(
                'medium',
                value?.medium || value?.MEDIUM,
              )}
            </Box>
          )}
        </HStack>
      </WrapItem>
      <WrapItem>
        <HStack spacing={3}>
          {(value?.LOW || value?.low) && (
            <Box>
              {renderSeverityCountBubble('low', value?.low || value?.LOW)}
            </Box>
          )}
          {(value?.UNKNOWN || value?.unknown) && (
            <Box>
              {renderSeverityCountBubble(
                'unknown',
                value?.unknown || value?.UNKNOWN,
              )}
            </Box>
          )}
        </HStack>
      </WrapItem>
    </Wrap>
  ) : (
    <Box color="gray.200">No vulnerability found</Box>
  );
};

export const renderVulnerabilitiesWithSeverityBar = ({ value }) => {
  const hasVulnerability = some(value, o => o > 0);
  return hasVulnerability ? (
    <HStack spacing={3}>
      {(value?.CRITICAL || value?.critical) && (
        <Box>
          {renderSeverityBarWithCount({
            value: 'critical',
            count: value?.critical ?? value?.CRITICAL,
          })}
        </Box>
      )}
      {(value?.HIGH || value?.high) && (
        <Box>
          {renderSeverityBarWithCount({
            value: 'high',
            count: value?.high ?? value?.HIGH,
          })}
        </Box>
      )}
      {(value?.MEDIUM || value?.medium) && (
        <Box>
          {renderSeverityBarWithCount({
            value: 'medium',
            count: value?.medium ?? value?.MEDIUM,
          })}
        </Box>
      )}
      {(value?.LOW || value?.low) && (
        <Box>
          {renderSeverityBarWithCount({
            value: 'low',
            count: value?.low ?? value?.LOW,
          })}
        </Box>
      )}
      {(value?.UNKNOWN || value?.unknown) && (
        <Box>
          {renderSeverityBarWithCount({
            value: 'unknown',
            count: value?.unknown ?? value?.UNKNOWN,
          })}
        </Box>
      )}
    </HStack>
  ) : (
    <Box color="gray.200">No vulnerability found</Box>
  );
};

export const isEnabledSummaryIcon = type => {
  return false;
};

export const getRegionOptionsWithAll = regions => {
  return [
    { label: 'All regions', value: undefined },
    ...map(regions, o => ({
      label: o,
      value: o,
    })),
  ];
};
