import React from 'react';

import { Icon } from '@chakra-ui/react';

export const MaliciousIngressIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M468,319.6c-5.4-2.2-11.9-2.2-17.8-1.9c-47.7,2.9-95.3,7.4-143.1,9c-82.6,2.6-165,0.1-247.2-9.1
		c-16.2-1.8-27.7,10.1-24.3,25.2c4.9,22.8,13,44.8,24.1,65.2c6.5,11.8,15.3,21.2,27.2,27.5c28.1,15,57.9,24.3,89.5,28.4
		c19.9,2.6,37.7-0.4,52.5-15.2c2.4-2.4,5.5-4.3,8.3-6.4c8.4-6.2,17.7-8.1,28-6.7c12.9,1.7,21.4,10.6,30.6,18.1
		c10,8.2,21.1,11,37.4,11c31.7-2.1,65.6-11.1,96.5-29.2c9-5.3,17.1-13.9,22.8-22.8c13.8-21.3,21.6-45.3,26.8-70.1
		C481.6,332.2,477.8,323.5,468,319.6z M205,400.3c-4.6,5.6-11.8,10.7-18.7,12.6c-20.9,5.7-41.6,3-59.9-8.9
		c-6.4-4.2-11.5-11.8-14.6-19c-4.2-10-0.9-15.4,9.7-17.5c1.2-0.2,2.3-0.4,3.5-0.6c16.6-2.8,33.4-2.7,50.1-0.3
		c6.8,1,13.5,2.1,20.1,3.7C212.2,374.4,216.2,386.6,205,400.3z M402.9,384.6c-2.7,5.9-6.8,11.8-11.9,15.6
		c-18.1,14-38.8,18.5-61.2,13.3c-10.9-2.5-19.3-8.8-23.7-19.6c-4.3-10.6-2-18.5,8.9-21.8c11.6-3.5,23.9-4.7,36-6.8
		c3.1-0.5,32.5,0.2,43.7,2.5C404.4,369.9,407.1,375.5,402.9,384.6z"
      />
      <path
        d="M0,238.3c0,0.4,0,0.8,0.1,1.2c3.2,26.9,116.6,48.4,255.9,48.4c140.8,0,255-22,256-49.2v-0.4v-0.1v-0.6
		l-0.1-0.6c-1.9-14.6-36.6-27.7-90.3-36.5c-4.6-0.7-8.3-4.1-9.5-8.6c-10.2-37.8-20.5-75.6-30.9-113.4c-5.1-18.7-16.1-29.7-33-30.9
		c-7.1-0.4-14.7,1.6-21.6,3.9c-15.8,5.1-30.9,12.6-46.9,16.4c-23.3,5.5-46.5,3.3-68.8-6.3c-9.3-4-18.6-7.7-28-11.3
		c-22.8-8.8-44.4,1.8-51,25.4c-10.8,38.6-21.3,77.4-31.8,116.1c-1.2,4.5-4.9,7.9-9.5,8.6C35.2,209.5,0,223.1,0,238.3z M216.6,175
		l49.2-49.2l7.7,7.7l-36.1,36.1H324v10.9h-86.6l36.1,36.1l-7.7,7.7L216.6,175z M187.1,106.2h28.1v10.9H198v116.1h17.2v10.9h-28.1
		V106.2z"
      />
    </g>
  </Icon>
);
