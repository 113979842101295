import React, { FC, useEffect } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { Accordion, Box, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, PermissionsActionsIcon } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { AccessGraph } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/AccessGraph';
import { ExpandedAccessGraph } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ExpandedAccessGraph';
import { renderHighPrivilegesStackedCell } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export const SnowflakeUserAccess: FC = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.CanAccess);
  }, [getCanAccessSummary]);

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: 'permission_entity_name',
    render: ({ row, value }) => (
      <WithResourceIcon resourceType={row?.permission_entity_native_name}>
        <StackedCell
          upper={value}
          lower={
            getCloudNativeName(row?.permission_entity_native_name) +
            ' / ' +
            getCloudAgnosticName(row?.permission_entity_agnostic_name)
          }
        />
      </WithResourceIcon>
    ),
  };
  const owner = {
    header: 'Owner',
    accessor: 'permission_entity_owner',
    align: 'left',
  };

  const privileges = [
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    {
      header: '# of Privileges',
      accessor: 'total_permission_count',
    },
  ];

  const renderAction = ({ row }) => (
    <Center onClick={e => e.stopPropagation()}>
      <ActionButton icon={<PermissionsActionsIcon />} label="Permissions" />
    </Center>
  );

  const action = {
    header: 'Actions',
    accessor: '',
    align: 'center',
    render: renderAction,
  };

  const type = {
    header: 'Type',
    align: 'left',
    render: ({ row }) =>
      row?.permission_entity_managed_type || row?.managed_type || '-',
  };

  const accessTableColumns = [name, owner, type, ...privileges];

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="ACCESS PRIVILEGES FOR THIS IDENTITY TO RESOURCES"
          isLoading={canAccessSummary.accessMap.isLoading}
          panel={<AccessGraph />}
          expandedPanel={<ExpandedAccessGraph />}
          iconBGColor="primary"
          expandOnClickViewAll
          expandIconTooltip="View detailed graph"
        />

        <MetaAccordionItem
          iconType={IconTypes.Permission}
          label="ACCESS PRIVILEGES FOR THIS IDENTITY TO RESOURCES"
          totalCount={canAccessSummary.canAccess.data?.length}
          isLoading={canAccessSummary.canAccess.isLoading}
          panel={
            <Table
              columns={accessTableColumns}
              data={canAccessSummary.canAccess.data}
              isLoading={canAccessSummary.canAccess.isLoading}
            />
          }
          iconBGColor="primary"
        />
      </Accordion>
    </>
  );
};
