import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { ResourceMapping } from '@ariksa/inventory-core/api';
import { AlertResponse, NotificationFor } from '@ariksa/notification/api';
import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react';

import { StackedHeader } from 'components/DataDisplay';
import { ActionButton, PrimaryIconButton } from 'components/DataEntry';
import {
  AutomationIcon,
  ExceptionIcon,
  NotificationIcon,
  ShowIcon,
} from 'components/Icons';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { useAlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/hooks/useAlertStatus';

interface IAlertDrawerHeader {
  alert: AlertResponse;
  individualAlert?: boolean;
  iconType?: string;
}

export const AlertDrawerHeader: FC<IAlertDrawerHeader> = props => {
  const { alert, individualAlert = false, iconType } = props;
  const { getResourceType } = useResourceType();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const exceptionModal = useDisclosure();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();

  const [resourceTypeObject, setResourceTypeObject] = useState<ResourceMapping>(
    {} as ResourceMapping,
  );
  const [resourceType, setResourceType] = useState('');

  const { getAlertListStatus } = useAlertStatus();
  const onCreateTicket = () => {
    getAlertListStatus([alert.uuid!]);
  };

  //get resourceType
  useEffect(() => {
    const resourceType = alert.resource!;
    setResourceType(resourceType ?? '-');
  }, [alert]);

  //get resourceType info object
  useEffect(() => {
    setResourceTypeObject(
      getResourceType(resourceType ?? '-') || ({} as ResourceMapping),
    );
  }, [getResourceType, resourceType]);

  return (
    <HStack justify={'space-between'}>
      <StackedHeader
        upper={
          alert.entity_name
            ? alert.entity_name + ` (${alert.entity_id})`
            : alert.entity_id
        }
        lower={
          <HStack>
            <Text>{resourceTypeObject.cloud_agnostic_name}</Text>
            <ActionButton
              onClick={() => {
                updateActiveResource({
                  resourceType: alert?.resource as NativeResources,
                  uuid: alert.entity_uuid!,
                  accountId: alert.account_id!,
                  resourceId: alert.entity_id!,
                });
                onOpenMetadata();
              }}
              icon={<ShowIcon />}
              label={'Entity details'}
              noHover
              styles={{
                border: '1px solid',
                borderColor: 'gray.100',
                borderRadius: 8,
              }}
            />
          </HStack>
        }
        type={iconType ?? resourceType}
      />
      {individualAlert && (
        <HStack pr={6}>
          <PrimaryIconButton
            aria-label="Create ticket"
            tooltip="Create ticket"
            icon={<AutomationIcon />}
            onClick={() => ticketModal.onOpen()}
          />
          <PrimaryIconButton
            icon={<NotificationIcon />}
            aria-label="Send notification"
            tooltip="Send notification"
            onClick={() => notificationModal.onOpen()}
          />
          <PrimaryIconButton
            icon={<ExceptionIcon />}
            aria-label="Add exception"
            tooltip="Add exception"
            onClick={() => exceptionModal.onOpen()}
          />
        </HStack>
      )}
      {individualAlert && (
        <Box pos={'absolute'}>
          <CreateTicket
            onClose={ticketModal.onClose}
            isOpen={ticketModal.isOpen}
            alert={alert}
            onCreate={onCreateTicket}
            notification_for={NotificationFor.Alerts}
          />
          <CreateNotification
            onClose={notificationModal.onClose}
            isOpen={notificationModal.isOpen}
            alert={alert}
            onCreate={onCreateTicket}
            notification_for={NotificationFor.Alerts}
          />
          <AddException
            onClose={exceptionModal.onClose}
            isOpen={exceptionModal.isOpen}
            alert={alert}
          />
        </Box>
      )}
    </HStack>
  );
};
