import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NISTIcon = props => (
  <Icon viewBox="0 0 646 170.3" w="full" h="full" {...props}>
    <path
      d="M45,0c-25,0 -45,20 -45,45v125h40v-125c0,-4 5,-6 8.5-3.5l104.9,115.1c29,29 77,
      8 77-31v-126h-40v126c0,4 -5,6 -8.3,3.5l-104.5-115c-10,-10 -18,-14 -32.5-14 M250.2,
      0v125a45,45 0 0 0 45,45h167.8a52.5,52.5 0 0 0 0-105h-105.2a12.5,
      12.5 0 0 1 0-25h177.5v130h40v-130h70v-40h-287.5a52.5,52.5 0 0 0 0,
      105h105a12.5,12.5 0 0 1 0,25h-167.5a5,5 0 0 1 -5-5v-125z"
      color="#000"
    />
  </Icon>
);
