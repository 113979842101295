import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import { ThumbsDownIcon, ThumbsUpIcon } from 'components/Icons';
import { renderDataSummaryField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const DataProtectionSummary: FC = () => {
  const { resourceType, resourceDetails } = useSelector(selectActiveResource);

  const renderField = value => (
    <HStack>
      <Box>{value ? 'Enabled' : 'Disabled'}</Box>
      <Center boxSize={5}>
        <Center>
          {value ? (
            <ThumbsUpIcon color="green.300" />
          ) : (
            <ThumbsDownIcon color="red.300" />
          )}
        </Center>
      </Center>
    </HStack>
  );

  return (
    <FootprintBox
      content={
        <Stack w="full" h="full" justify="space-between" px={4} py={6}>
          {resourceType === NativeResources.SimpleStorageService ? (
            <>
              {renderDataSummaryField(
                'Versioning',
                renderField(
                  resourceDetails.data?.config?.Versioning?.Status ===
                    'Enabled',
                ),
              )}
              {resourceType === NativeResources.SimpleStorageService &&
                renderDataSummaryField(
                  'Object lock',
                  renderField(
                    resourceDetails.data?.config?.ObjectLockConfiguration
                      ?.ObjectLockEnabled === 'Enabled',
                  ),
                )}
              {resourceType === NativeResources.SimpleStorageService &&
                renderDataSummaryField(
                  'Lifecycle management',
                  renderField(resourceDetails.data?.config?.LifecyclePolicy),
                )}
            </>
          ) : (
            <>
              {renderDataSummaryField(
                'Backup',
                '-',
                //renderField(resourceDetails.data?.config?.LifecyclePolicy),
              )}
              {renderDataSummaryField(
                'Snapshots detected',
                '-',
                //resourceDetails.data?.config?.LifecyclePolicy,
              )}
              {renderDataSummaryField(
                'Snapshots older than 30 days',
                '-',
                //resourceDetails.data?.config?.LifecyclePolicy,
              )}
              {renderDataSummaryField(
                'Replication',
                '-',
                //renderField(resourceDetails.data?.config?.LifecyclePolicy),
              )}
            </>
          )}
        </Stack>
      }
      label="Data Protection"
      isLoading={resourceDetails.isLoading}
    />
  );
};
