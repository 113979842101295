import React, { FC } from 'react';

import { HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';

import { ComputeIcon } from 'components/Icons';
import { ResourceFilterItems } from 'containers/Visibility/Components/Filter/ResourceFilterItem';
import { ResourceFilterModal } from 'containers/Visibility/Components/ResourceFilterModal';
import { clearFormTextStyle } from 'containers/Visibility/Organization/styles';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';

interface IResourceFilter {
  nodes: any[];
  resourceFilters: any[];
  setResourceFilters: any;
}

export const ResourceFilter: FC<IResourceFilter> = props => {
  const { resourceFilters, setResourceFilters, nodes } = props;
  const resourceFilter = useDisclosure();

  return (
    <Stack spacing={3}>
      <SidebarAccordion
        header={'RESOURCE TYPES'}
        showClear={!!resourceFilters.length}
        onClear={() => setResourceFilters([])}
        icon={<ComputeIcon />}
        iconPadding={0}
      >
        <HStack>
          <Text {...clearFormTextStyle} onClick={() => resourceFilter.onOpen()}>
            + Choose Resource Type
          </Text>
        </HStack>
        <ResourceFilterItems
          filters={resourceFilters}
          nodes={nodes}
          onRemove={e => {
            setResourceFilters(resourceFilters.filter(f => f.key !== e.key));
          }}
        />

        {resourceFilter.isOpen && (
          <ResourceFilterModal
            onClose={resourceFilter.onClose}
            isOpen={resourceFilter.isOpen}
            resources={nodes ?? []}
            onSubmit={e => setResourceFilters([...resourceFilters, e])}
          />
        )}
      </SidebarAccordion>
    </Stack>
  );
};
