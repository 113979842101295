import { Configuration } from '@ariksa/inventory-core';
import * as coreAuth from '@azure/core-auth';

export class TokenCredential implements coreAuth.TokenCredential {
  getToken(
    scopes: string | string[],
    options?: coreAuth.GetTokenOptions,
  ): Promise<coreAuth.AccessToken> {
    return new Promise(resolve => {
      resolve({
        token: sessionStorage.getItem('authentication') || '',
        expiresOnTimestamp: 99999,
      });
    });
  }
}

export class TokenConfig extends Configuration {
  constructor() {
    super({
      accessToken: () => {
        return sessionStorage.getItem('authentication') ?? '---';
      },
    });
  }
}
