import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ComplianceCheckFailedIcon = props => (
  <Icon color="red" viewBox={'0 0 24 24'} h="full" w="full" {...props}>
    <path d="M13.5,21H7c-1.1040039-0.0014038-1.9985962-0.8959961-2-2V5c0.0014038-1.1040039,0.8959961-1.9985962,2-2h6v3.6669922C13.0016479,7.9547729,14.0452271,8.9983521,15.3330078,9H19v4.5c0,0.276123,0.223877,0.5,0.5,0.5s0.5-0.223877,0.5-0.5v-5c0-0.1326294-0.0526733-0.2597656-0.1464844-0.3535156l-6-6C13.7597656,2.0526733,13.6326294,2,13.5,2H7C5.3438721,2.0018311,4.0018311,3.3438721,4,5v14c0.0018311,1.6561279,1.3438721,2.9981689,3,3h6.5c0.276123,0,0.5-0.223877,0.5-0.5S13.776123,21,13.5,21z M14,3.7069702L18.2930298,8h-2.960022C14.5970459,7.9993896,14.0006104,7.4029541,14,6.6669922V3.7069702z M21.8534546,21.1465454L20.2069092,19.5l1.6465454-1.6465454c0.1871948-0.1937256,0.1871948-0.5009766,0-0.6947021c-0.1918335-0.1986694-0.5083618-0.2041016-0.7069702-0.0122681l-1.6465454,1.6465454l-1.6464844-1.6464844c-0.1937256-0.1871948-0.5009766-0.1871948-0.6947021,0c-0.1986694,0.1918335-0.2041016,0.5083618-0.0122681,0.7069702L18.7929688,19.5l-1.6464844,1.6464844c-0.09375,0.09375-0.1464233,0.2208862-0.1464233,0.3534546c0,0.276123,0.2238159,0.5,0.499939,0.500061c0.1326294,0.0001221,0.2598267-0.0526123,0.3534546-0.1465454l1.6464844-1.6464844l1.6465454,1.6465454C21.2401123,21.9474487,21.3673706,22.0001831,21.5,22c0.1325073-0.000061,0.2595825-0.0526733,0.3533325-0.1463623C22.048645,21.6583862,22.0487061,21.3417969,21.8534546,21.1465454z M15.5,12h-7C8.223877,12,8,12.223877,8,12.5S8.223877,13,8.5,13h7c0.276123,0,0.5-0.223877,0.5-0.5S15.776123,12,15.5,12z M8.5,8C8.223877,8,8,8.223877,8,8.5S8.223877,9,8.5,9h2C10.776123,9,11,8.776123,11,8.5S10.776123,8,10.5,8H8.5z M13.5,16h-5C8.223877,16,8,16.223877,8,16.5S8.223877,17,8.5,17h5c0.276123,0,0.5-0.223877,0.5-0.5S13.776123,16,13.5,16z" />
    {/*<defs>
      <clipPath id="clip0">
        <rect x="505" y="392" width="113" height="118" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="506" y="393" width="112" height="117" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="506" y="393" width="112" height="117" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="506" y="393" width="112" height="117" />
      </clipPath>
      <clipPath id="clip4">
        <rect x="506" y="393" width="112" height="117" />
      </clipPath>
      <clipPath id="clip5">
        <rect x="506" y="393" width="112" height="113" />
      </clipPath>
      <clipPath id="clip6">
        <rect x="506" y="393" width="112" height="113" />
      </clipPath>
      <clipPath id="clip7">
        <rect x="506" y="393" width="112" height="113" />
      </clipPath>
      <clipPath id="clip8">
        <rect x="554" y="451" width="55" height="55" />
      </clipPath>
      <clipPath id="clip9">
        <rect x="554" y="451" width="55" height="55" />
      </clipPath>
      <clipPath id="clip10">
        <rect x="554" y="451" width="55" height="55" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-505 -392)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <g clipPath="url(#clip4)">
              <g clipPath="url(#clip5)">
                <g clipPath="url(#clip6)">
                  <g clipPath="url(#clip7)">
                    <path
                      d="M175.994 129.785 185.293 129.785C186.576 129.785 187.617 128.744 187.617 127.461 187.617 126.177 186.576 125.136 185.293 125.136L175.994 125.136C174.71 125.136 173.669 126.177 173.669 127.461 173.669 128.744 174.71 129.785 175.994 129.785ZM229.281 126.565C229.163 126.283 228.993 126.029 228.779 125.815L200.886 97.922C200.673 97.7084 200.418 97.5383 200.136 97.4201 199.854 97.3027 199.551 97.2396 199.241 97.2396L169.02 97.2396C161.32 97.2479 155.08 103.488 155.071 111.188L155.071 176.279C155.08 183.979 161.32 190.219 169.02 190.227L215.514 190.227C223.214 190.219 229.453 183.979 229.462 176.279L229.462 127.461C229.462 127.15 229.398 126.847 229.281 126.565ZM201.566 105.176 221.525 125.136 207.763 125.136C204.341 125.133 201.568 122.36 201.566 118.938L201.566 105.176ZM224.812 176.279C224.806 181.412 220.647 185.571 215.514 185.578L169.02 185.578C163.887 185.571 159.727 181.412 159.721 176.279L159.721 111.188C159.727 106.055 163.887 101.896 169.02 101.889L196.916 101.889 196.916 118.938C196.923 124.926 201.775 129.778 207.763 129.785L224.812 129.785 224.812 176.279ZM173.669 146.058C173.669 147.342 174.71 148.383 175.994 148.383L208.539 148.383C209.823 148.383 210.864 147.342 210.864 146.058 210.864 144.775 209.823 143.733 208.539 143.733L175.994 143.733C174.71 143.733 173.669 144.775 173.669 146.058ZM208.539 162.331 175.994 162.331C174.71 162.331 173.669 163.372 173.669 164.656 173.669 165.939 174.71 166.98 175.994 166.98L208.539 166.98C209.823 166.98 210.864 165.939 210.864 164.656 210.864 163.372 209.823 162.331 208.539 162.331Z"
                      fill="currentColor"
                      fillRule="nonzero"
                      fillOpacity="1"
                      transform="matrix(1 0 0 1.00733 369.941 304.414)"
                    />
                  </g>
                </g>
              </g>
              <path
                d="M184.178 173.807C184.178 157.083 197.642 143.526 214.252 143.526 230.861 143.526 244.326 157.083 244.326 173.807 244.326 190.531 230.861 204.089 214.252 204.089 197.642 204.089 184.178 190.531 184.178 173.807Z"
                fill="#FFFFFF"
                fillRule="evenodd"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00733 369.941 304.414)"
              />
              <g clipPath="url(#clip8)">
                <g clipPath="url(#clip9)">
                  <g clipPath="url(#clip10)">
                    <path
                      d="M211.132 151.217C199.35 151.217 189.798 160.769 189.797 172.551 189.797 184.334 199.348 193.886 211.131 193.886 222.914 193.887 232.466 184.335 232.466 172.552 232.466 172.552 232.466 172.551 232.466 172.55 232.471 160.773 222.926 151.222 211.149 151.217 211.144 151.217 211.138 151.217 211.132 151.217ZM220.665 179.399 217.979 182.084 211.132 175.236 204.288 182.083 201.602 179.397 208.444 172.55 201.599 165.703 204.288 163.018 211.132 169.867 217.979 163.018 220.665 165.703 213.818 172.55Z"
                      fill="currentColor"
                      fillRule="nonzero"
                      fillOpacity="1"
                      transform="matrix(1 0 0 1.00733 369.941 304.414)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>*/}
  </Icon>
);
