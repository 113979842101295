/**
 *
 * Edit Snowflake
 *
 */

import React, { memo } from 'react';

import { Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { SnowflakeIcon } from 'components/Icons';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { Snowflake } from 'containers/Setup/Integrations/DataSources/Components/Snowflake';
import { integrationsSaga } from 'containers/Setup/Integrations/saga';
import * as integrationsSlice from 'containers/Setup/Integrations/slice';
import { useInjector } from 'utils/inject';

export const EditSnowflake = memo(() => {
  useInjector(
    integrationsSlice.sliceKey,
    integrationsSlice.reducer,
    integrationsSaga,
  );
  const { accountDetails } = useSelector(selectCloudAccounts);
  const navigate = useNavigate();

  return (
    <Stack spacing={6}>
      <PageHeaderWithIcon
        label="Update Snowflake"
        icon={<SnowflakeIcon />}
        iconFilled={false}
        reversed
      />
      <Snowflake
        action="Update"
        accountDetails={accountDetails.data}
        accountDetailsIsLoading={accountDetails?.isLoading}
        onClickCancel={() => navigate('/setup/accounts')}
      />
    </Stack>
  );
});
