import { IGroup, IShape } from '@antv/g-base';
import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';

import { treeEdgeColor } from '../styles';

export const TREE_CONNECTOR = 'tree-connector';

export function registerTreeConnector() {
  G6.registerEdge(
    TREE_CONNECTOR,
    {
      setState(name, value, item) {
        const group = item?.get('group');
        const model = item?.get('model');

        // const states = item?.getStates();
        const keyShape = group.find(el => el.get('name') === 'edge-shape');
        const states = item?.getStates();
        const isHovered = states?.includes('hover');
        const isSelected = states?.includes('selected');

        if (model.data.active) {
          keyShape.attr({
            stroke: treeEdgeColor,
            lineWidth: 1,
            opacity: 1,
          });
          return;
        }

        if (isSelected) {
          keyShape.attr({
            stroke: treeEdgeColor,
            lineWidth: 3,
            opacity: 0.5,
          });
          return;
        }

        if (isHovered) {
          keyShape.attr({
            stroke: treeEdgeColor,
            lineWidth: 3,
            opacity: 0.3,
          });
          return;
        }

        keyShape.attr({
          stroke: '#ddd',
          lineWidth: 1,
          opacity: 1,
        });
      },
      afterDraw(cfg?: ModelConfig, group?: IGroup, rst?: IShape) {
        // @ts-ignore
        if (cfg.data.active) {
          const keyShape = group?.find(el => el.get('name') === 'edge-shape');
          keyShape?.attr({
            stroke: treeEdgeColor,
            lineWidth: 1,
            opacity: 1,
          });
        }
        // const model = item?.get('model');
        //
        // // const states = item?.getStates();
        // const keyShape = group.findPath(el => el.get('name') === 'edge-shape');
      },
      update: undefined,
    },
    'cubic-horizontal',
  );
}
