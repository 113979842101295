import React, { FC, useEffect, useState } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { Accordion, Box, Center } from '@chakra-ui/react';
import { toLower } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import {
  renderStackedCloudNameAndIcon,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, PermissionsActionsIcon } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import {
  renderHighPrivilegesStackedCell,
  renderList,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useRedirectToGraph } from 'containers/ActiveCloudResource/hooks/useRedirectToGraph';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export const SnowflakeDatabaseIdentity: FC = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { accessByIdentity } = canAccessSummary;
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const { redirectToGraph } = useRedirectToGraph();

  const [users, setUsers] = useState<Dict<any>[]>([]);
  const [roles, setRoles] = useState<Dict<any>[]>([]);

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.AccessByIdentity);
  }, [getCanAccessSummary]);

  useEffect(() => {
    setUsers(
      filter(accessByIdentity.data, o => o?.source_agnostic_name === 'User'),
    );
    setRoles(
      filter(accessByIdentity.data, o => o?.source_agnostic_name === 'Role'),
    );
  }, [accessByIdentity.data]);

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: '',
    render: ({ row }) => (
      <WithResourceIcon resourceType={row?.source_native_class}>
        <StackedCell
          upper={row?.source_name}
          lower={
            getCloudNativeName(row?.source_native_class) +
            ' / ' +
            getCloudAgnosticName(row?.source_native_class)
          }
        />
      </WithResourceIcon>
    ),
  };
  const owner = {
    header: 'Owner',
    accessor: 'permission_entity_owner',
    align: 'left',
  };

  const privileges = [
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    {
      header: '# of Privileges',
      accessor: 'total_permission_count',
    },
  ];

  const renderAction = ({}) => (
    <Center onClick={e => e.stopPropagation()}>
      <ActionButton icon={<PermissionsActionsIcon />} label="Permissions" />
    </Center>
  );

  const action = {
    header: 'Actions',
    accessor: '',
    align: 'left',
    render: renderAction,
  };

  const usersColumns = [
    name,
    {
      header: 'Identity Provider',
      accessor: 'source_provider',
      align: 'left',
      render: ({ value }) => renderStackedCloudNameAndIcon(toLower(value)),
    },
    {
      header: 'Granted Roles',
      accessor: 'source_granted_role_count',
      //align: 'left',
      //render: renderList,
    },
    ...privileges,
  ];
  const rolesColumns = [
    name,
    owner,
    {
      header: 'Type',
      accessor: 'permission_entity_managed_type',
      align: 'left',
    },
    {
      header: 'Granted On',
      accessor: 'permission_entity_granted_on',
      align: 'left',
      render: renderList,
    },
    ...privileges,
  ];

  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <MetaAccordionItem
          iconType={IconTypes.User}
          label="USER IDENTITIES THAT CAN ACCESS THIS RESOURCE"
          totalCount={users?.length}
          onClickDownload={() => {}}
          isLoading={accessByIdentity.isLoading}
          panel={
            <Table
              columns={usersColumns}
              data={users}
              isLoading={accessByIdentity.isLoading}
            />
          }
          iconBGColor="primary"
          onShowGraph={() => redirectToGraph('identity', MetadataTabs.Identity)}
        />
        <MetaAccordionItem
          iconType={IconTypes.Role}
          label="ROLE IDENTITIES THAT CAN ACCESS THIS RESOURCE"
          totalCount={roles?.length}
          onClickDownload={() => {}}
          isLoading={accessByIdentity.isLoading}
          panel={
            <Table
              columns={rolesColumns}
              data={roles}
              isLoading={accessByIdentity.isLoading}
            />
          }
          iconBGColor="primary"
        />
      </Accordion>
    </>
  );
};
