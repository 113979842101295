import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RediscoverIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      d="M12 2.99988C16.9706 2.99988 21 7.02931 21 11.9999C21 16.9704 16.9706 20.9999 12 20.9999C7.02944 20.9999 3 16.9704 3 11.9999C3 9.17261 4.30367 6.64983 6.34267 4.99988"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      fill="none"
    />
    <path
      d="M3 4.49988H7V8.49988"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
      fill="none"
    />
  </Icon>
);
