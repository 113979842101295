import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { AccessGraphWithContext } from 'components/Visualization/PixiGraph/components/AccessGraphWithContext';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import 'components/Visualization/Graphs/nodes/register_graph_elements';

export const ExpandedAccessGraph = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { data, isLoading, isSuccess } = canAccessSummary.expandedAccessMap;
  const { getAccessMapData } = useActiveResourceActions();

  useEffect(() => {
    getAccessMapData(true);
  }, [getAccessMapData]);

  return (
    <Box pos={'relative'} h={'full'}>
      <AccessGraphWithContext
        data={data}
        menu
        isLoading={isLoading}
        isLoaded={isSuccess}
      />
    </Box>
  );
};
