import React, { ReactNode, useEffect } from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloudtrailIcon, RoleIcon } from 'components/Icons';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { actions } from 'containers/Dashboard/Overview/slice';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

interface Props {
  showLogEnabled?: boolean;
  icon?: ReactNode;
  label?: string;
  labelTooltip?: ReactNode;
}

export const Identities: React.FC<Props> = props => {
  const {
    showLogEnabled = true,
    icon = <RoleIcon />,
    label = 'Identities',
    labelTooltip,
  } = props;
  const { accountStatus, deploymentSummary } = useSelector(
    selectDashboardOverview,
  );
  const { inventorySummary, identityCount } = useSelector(selectDashboard);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { environmentId } = useSelector(selectActiveEnvironment);
  useEffect(() => {
    dispatch(
      actions.getDeploymentSummary({
        q: {
          category: Categories.Logging,
          resourceType: 'ActionLog',
          environmentId: environmentId,
        },
      }),
    );
  }, [dispatch, environmentId]);

  const isActivityLogEnabled = deploymentSummary.data?.length > 0;

  return (
    <SummaryCard
      label={label}
      icon={icon}
      onClick={() => navigate('/inventory/summary?tab=' + Categories.Identity)}
      change={{
        isLoading: identityCount.isLoading,
        first: identityCount.data.previous_count,
        last: identityCount.data.current_count,
        percentChange: identityCount.data.percentage_change,
      }}
      cloudProviders={inventorySummary.data?.cloud_providers}
      count={identityCount.data.current_count}
      isLoading={identityCount.isLoading}
      {...(showLogEnabled
        ? {
            info: {
              isLoading: accountStatus.isLoading,
              message: isActivityLogEnabled
                ? 'Activity Logs Enabled'
                : 'No Activity Logs',
              enabled: isActivityLogEnabled,
              onClick: () =>
                navigate(
                  '/inventory/summary?tab=' +
                    Categories.Logging +
                    '_' +
                    Resources.ActionLog,
                ),
            },
          }
        : {})}
      tooltip={{
        header: 'Enrich insights with Activity Logs…',
        icon: <CloudtrailIcon />,
        tooltip: (
          <Stack spacing={8}>
            <Box>
              Ariksa uncovers insights such as anomalous user behavior and
              privilege escalation attempts, if you enable and store user
              activity logs (such as AWS CloudTrail) in your cloud
            </Box>
            <Box
              color="primary"
              onClick={() =>
                navigate(
                  'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-getting-started.html',
                )
              }
            >
              Enabling AWS CloudTrail Trail
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
      labelTooltip={labelTooltip}
    />
  );
};
