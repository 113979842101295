export const sanitizeUrl = url => url.replace('//', '/');

export const matchUrlPattern = (base, config, path) => {
  const { match, url, exact } = config;
  // console.log(base, pattern, url);

  const t1 = sanitizeUrl(base + (match ?? url)).split('/');
  const t2 = path.split('/');

  if (exact && t1.length !== t2.length) {
    return false;
  }

  return t1.every((t, i) => {
    return t[0] === ':' || t === t2[i];
  });
};

// check if base url pattern is matching with url
export const matchUrlBase = (base, url) => {
  const t1 = base.split('/');
  const t2 = url.split('/');
  return t1.every((t, i) => {
    return t[0] === ':' || t === t2[i];
  });
};
