import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  KeyManager,
  KeyRotation,
  KeySpec,
  KeyUsage,
  MultiRegion,
  RotationPeriod,
} from '../../../Components/MetadataField/MetaGridFields';

export const KMSMetadata: FC = () => {
  const { resourceInsight, resourceDetails } = useActiveResourceContext();
  const { data: insight } = resourceInsight;
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields
          availabilityZone="None"
          vpc="None"
          aliases={resource}
        />
        {KeySpec(resource)}
        {KeyUsage(resource)}
        {KeyManager(insight)}
        {MultiRegion(resource)}
        {KeyRotation(resource)}
        {RotationPeriod(resource)}
      </Grid>
    </Stack>
  );
};
