import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

import {
  OptionsButtonGroup,
  OptionsButtonGroupStyles,
} from 'components/DataEntry';
import { OptionItem, TimeRange } from 'components/DataEntry/Button/types';

interface ITimeRangeButtonGroup {
  options?: OptionItem[];
  defaultValue?: OptionItem;
  value?: OptionItem;
  onChange?(value: any);
  styles?: OptionsButtonGroupStyles;
}

export const TimeRangeButtonGroup: FC<ITimeRangeButtonGroup> = props => {
  const { value, onChange, defaultValue, styles, options } = props;
  const [timeInterval, setTimeInterval] = useState<OptionItem>(
    defaultValue ?? {
      value: TimeRange.W1,
      label: TimeRange.W1,
    },
  );

  useEffect(() => {
    if (value) {
      setTimeInterval(value);
    }
  }, [value]);

  const handleIntervalSelection = item => {
    setTimeInterval(item);
    onChange?.(item);
  };

  return (
    <OptionsButtonGroup
      items={options ?? [timeInterval]}
      value={timeInterval}
      onClick={handleIntervalSelection}
      styles={{
        option: {
          fontSize: '2xs',
          ...styles?.option,
        },
        root: styles?.root,
      }}
    />
  );
};
