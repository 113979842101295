import React, { FC, useEffect, useReducer, useState } from 'react';

import { Category, SensitivityLevel } from '@ariksa/data-scanning';
import { Box } from '@chakra-ui/react';
import { find, forEach, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { FormFieldBox, renderSeverityBubble } from 'components/DataDisplay';
import { FormAction } from 'components/DataEntry';
import { Form } from 'components/DataEntry/Form';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

import { DataClassifierCondition } from './DataClassifierCondition';

interface Props {
  actionType: FormAction;
}

export const DataClassifierForm: FC<Props> = props => {
  const { actionType } = props;
  const { dataConfigurationAction, sensitivityInfo } = useSelector(
    selectDataClassification,
  );
  const dispatch = useDispatch();
  const [type, setType] = useState<Record<string, any>>({});
  const [labels, setLabels] = useState<Record<string, any>[]>([]);
  const {
    currentRecord: classifier,
    onCloseForm,
    selectedTab,
  } = useDataConfigurationContext();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      name: '',
      isEdit: false,
      pattern: '',
      category: Category.Include,
      type: {},
      labels: [],
      sensitivityCategories: [],
      sensitivityLevels: map(SensitivityLevel, o => ({
        label: o,
        value: o,
      })),
      sensitivity: {},
    },
  );

  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  useEffect(() => {
    const options: Record<string, any>[] = [];
    forEach(sensitivityInfo.data, (o, key) =>
      options.push({ label: key, value: key, level: o }),
    );
    updateState({ sensitivityCategories: options, sensitivity: options[0] });
  }, [sensitivityInfo.data]);

  useEffect(() => {
    updateState({
      category: selectedTab === 'types' ? Category.Include : Category.Exclude,
    });
  }, [selectedTab]);

  useEffect(() => {
    if (state.isEdit) {
      updateState({
        name: classifier.name,
        pattern: classifier.patterns?.[0] ?? '',
        sensitivity: find(
          state.sensitivityCategories,
          o => classifier.sensitivity_category === o?.value,
        ),
        category: classifier.category,
      });
    }
  }, [classifier, state.isEdit, state.sensitivityCategories]);

  const handleRedirect = () => onCloseForm();

  const handleSubmit = data => {
    const payload = {
      name: data.name,
      description: data.description,
      patterns: [state.pattern],
      ...(state.category === Category.Include
        ? { labels: map(labels, o => o.value) }
        : {}),
    };
    if (state.isEdit) {
      if (selectedTab === 'types')
        dispatch(
          actions.updateDataType({
            q: {
              dataTypesUpdate: {
                ...payload,
              },
              typeName: classifier.name!,
            },
            onSuccess: () => handleRedirect(),
          }),
        );
      else
        dispatch(
          actions.updateDataExclusion({
            q: {
              exclusionName: classifier.name!,
              dataExclusionsUpdate: {
                ...payload,
                is_enabled: classifier?.is_enabled,
              },
            },
            onSuccess: () => handleRedirect(),
          }),
        );
    } else {
      if (selectedTab === 'types')
        dispatch(
          actions.addDataType({
            q: {
              dataTypesCreate: {
                is_enabled: true,
                ...payload,
                category: state.category,
                type: type.value,
                sensitivity_category: state.sensitivity?.value,
                sensitivity_level: state.sensitivity?.level,
              },
            },
            onSuccess: () => handleRedirect(),
          }),
        );
      else
        dispatch(
          actions.addDataExclusion({
            q: {
              dataExclusionsCreate: {
                is_enabled: false,
                ...payload,
                category: state.category,
                type: type.value,
              },
            },
            onSuccess: () => handleRedirect(),
          }),
        );
    }
  };

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state.name,
          onChange: value => updateState({ name: value }),
        },
        description: {
          type: 'textArea',
          label: 'Description',
          defaultValue: state.isEdit ? classifier.description : '',
        },
        condition: {
          type: 'custom-with-form-control',
          label: 'Condition',
          component: (
            <DataClassifierCondition
              pattern={state.pattern}
              setPattern={val => updateState({ pattern: val })}
              isEdit={state.isEdit}
              type={type}
              setType={setType}
              setLabels={setLabels}
              selectedLabels={labels}
              category={state.category}
            />
          ),
          isRequired: true,
        },
        ...(selectedTab === 'types'
          ? {
              sensitivity: {
                type: 'object',
                styles: {
                  objectContainer: {
                    bg: 'white',
                    border: 'none',
                    p: 0,
                    isInline: true,
                    spacing: 10,
                  },
                  propertyContainer: { w: 64 },
                },
                properties: {
                  sensitivity_category: {
                    type: 'react-select',
                    options: state.sensitivityCategories,
                    value: state.sensitivity,
                    label: 'Sensitivity category',
                    isDisabled: state.isEdit,
                    onChange: val => {
                      updateState({ sensitivity: val });
                    },
                    isLoading: sensitivityInfo.isLoading,
                  },
                  sensitivity_level: {
                    type: 'custom-with-form-control',
                    label: 'Sensitivity Level',
                    component: (
                      <FormFieldBox isDisabled={state.isEdit}>
                        {renderSeverityBubble({
                          value: state.sensitivity?.level,
                        })}
                      </FormFieldBox>
                    ),
                  },
                },
              },
            }
          : {}),
        note: {
          type: 'custom',
          component: () => (
            <Box fontWeight={600} mt={5} color="primary">
              NOTE: This data{' '}
              {selectedTab === 'types' ? 'classifier' : 'exclusion'} will be
              applied to new and existing data, when Ariksa performs the next
              scan of data sources
            </Box>
          ),
        },
      }}
      styles={{ form: { container: { pl: 14, pt: 4 }, formWidth: '4xl' } }}
      buttonOptions={{
        submit: {
          name: state.isEdit ? 'Confirm' : 'Add',
          isLoading: dataConfigurationAction.isLoading,
          isDisabled:
            !state.name ||
            !state.pattern ||
            (state.category === 'include' && !labels.length),
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: () => handleRedirect(),
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
