import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { MetaGridValueBox } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridValueBox';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AllowedMethods,
  Compress,
  DomainName,
  HTTPVersion,
  IPv6Enabled,
  LastModified,
  NumberOfGroups,
  TargetOriginID,
  TrustedKeyGroups,
  WAFACLID,
} from '../../../Components/MetadataField/MetaGridFields';

export const CloudFrontMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const configurationColumns = [
    { header: 'Origin', accessor: 'S3OriginConfig.OriginAccessIdentity' },
    { header: 'ID', accessor: 'Id', align: 'left' },
    { header: 'Domain Name', accessor: 'DomainName', align: 'left' },
  ];

  const configuration = resource?.config?.Origins?.Items ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {LastModified(resource)}
      {DomainName(resource)}
      {WAFACLID(resource)}
      {HTTPVersion(resource)}
      {IPv6Enabled(resource)}
      {/*{Stage(resource)}*/}
      {TrustedKeyGroups(resource)}
      {NumberOfGroups(resource)}
      <MetaGridValueBox
        label="Default cache behavior"
        value={
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            {TargetOriginID(resource)}
            {Compress(resource)}
            {AllowedMethods(resource)}
          </Grid>
        }
      />
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={
              <Table columns={configurationColumns} data={configuration} />
            }
            iconType={IconTypes.Configuration}
            label="Configuration"
            totalCount={configuration?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
