import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AutoMinorVersionUpgrade,
  Cluster,
  DeleteProtection,
  Engine,
  IAMDBAuthentication,
  KMSKeyID,
  MultiAZ,
  SecurityGroups,
  StorageEncrypted,
  License,
  IOPS,
  MasterUsername,
  ListenPort,
  BackupWindow,
  BackupRetentionDays,
  PerformanceInsights,
  MonitoringARN,
  NeptuneEndpoint,
} from '../../../Components/MetadataField/MetaGridFields';

export const NeptuneInstanceMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {Engine(resource)}
      {License(resource)}
      {IOPS(resource)}
      {Cluster(resource)}
      {NeptuneEndpoint(resource)}
      {ListenPort(resource)}
      {MasterUsername(resource)}
      {MultiAZ(resource)}
      {StorageEncrypted(resource)}
      {KMSKeyID(resource)}
      {SecurityGroups(resource)}
      {IAMDBAuthentication(resource)}
      {DeleteProtection(resource)}
      {BackupWindow(resource)}
      {BackupRetentionDays(resource)}
      {AutoMinorVersionUpgrade(resource)}
      {PerformanceInsights(resource)}
      {MonitoringARN(resource)}
    </Grid>
  );
};
