import React, { FC, useCallback, useMemo } from 'react';

import { SearchResponseLevel } from '@ariksa/inventory-core';
import { Box, Center, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { identity, isString, throttle } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { customTheme } from 'theme';

import { defaultSelectStyles } from 'components/DataEntry';
import { DropdownIndicator } from 'components/DataEntry/Select/utils';
import { ServicesIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { useSourceNodes } from 'containers/Visibility/SecurityGraphNext/hooks/useSourceNodes';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { SecurityGraphMapType } from 'containers/Visibility/SecurityGraphNext/types';

interface ISelectSourceEntity {}

const formatGroupLabel = data => (
  <Box
    borderBottom="1px solid"
    borderColor={customTheme.colors.gray['200']}
    pb={1}
    fontSize={12}
    color={customTheme.colors.gray['250']}
  >
    {data.label}
  </Box>
);

export const SelectSourceEntity: FC<ISelectSourceEntity> = props => {
  const dispatch = useDispatch();
  const {
    sourceNodeOptions,
    selectedSourceNodeOption,
    updateSelectedSourceNodeOption,
  } = useSourceNodes();
  const { responseLevel, mapType, activeSearchQueryTerm } = useSelector(
    selectSecurityGraphNext,
  );
  const { searchResult } = useSearchResult();
  const { isQueryChanged } = useRedirectInfo();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const { redirectInfo } = useRedirectInfo();

  const limitedOptions = useMemo(() => {
    return sourceNodeOptions?.map(group => {
      if (!group.options?.length) {
        return {
          ...group.options?.[0],
          options: group.options?.slice(0, group.options?.length ?? 1),
        };
      }

      return group;
    });
  }, [sourceNodeOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnLoadOptions = useCallback(
    throttle((term: string, cb = identity) => {
      const options = {
        label: sourceNodeOptions?.[0]?.label,
        value: sourceNodeOptions?.[0]?.value,
        options: sourceNodeOptions?.[0]?.options?.filter(option => {
          return option?.label?.toLowerCase().includes(term.toLowerCase());
        }),
      };

      cb([options]);
    }, 0),
    [sourceNodeOptions],
  );

  const MenuList = props => {
    return (
      <Box
        as="ul"
        sx={{
          listStyle: 'none',
          p: 0,
          m: 0,
          maxHeight: '440px',
          overflowY: 'auto',
        }}
      >
        {props.children}
      </Box>
    );
  };

  const Option = props => {
    const { isSelected, data, isDisabled } = props;

    return (
      <components.Option {...props}>
        <Box opacity={isDisabled ? 0.4 : 1}>
          <HStack>
            {data?.icon && (
              <Center boxSize={4} color={isSelected ? 'white' : 'primary'}>
                {isString(data?.icon) ? getIcon(data?.icon) : data?.icon}
              </Center>
            )}
            <Box>{data?.label}</Box>
          </HStack>
        </Box>
      </components.Option>
    );
  };

  const SingleValue = props => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <HStack h="full">
          <Center h="full" boxSize={5}>
            <Center color="primary" w="full">
              {data?.icon}
            </Center>
          </Center>
          <Box flex={1} w="full" overflow="hidden" textOverflow="ellipsis">
            {props.children}
          </Box>
        </HStack>
      </components.SingleValue>
    );
  };

  const handleOnSelect = selected => {
    // for identity map, when the search query term is present, we need to pass it in next api call

    if (redirectInfo?.identity && activeSearchQueryTerm?.query) {
      params.set('source_resource_uuid', selected!.value.uuid);
      params.set('query', activeSearchQueryTerm.query);
    } else {
      params.set('source_resource_uuid', selected!.value.uuid);
    }

    // update redirect info with selected source node
    // NOTE: without this line, the selected source node will be updated after the api call for the search graph
    dispatch(
      actions.updateRedirectInfo({
        ...redirectInfo,
        source_uuid: selected!.value.uuid,
      }),
    );

    dispatch(actions.updateFilterChanged(true));

    const url = `${window.location.pathname}?${params.toString()}`;
    navigate(url);
    updateSelectedSourceNodeOption(selected);
  };

  return (
    <Stack spacing={3}>
      <SidebarAccordion
        header={'SELECT ENTITY'}
        icon={<ServicesIcon color="primary" />}
      >
        <Stack spacing={4}>
          <AsyncSelect
            isDisabled={searchResult.isLoading}
            value={selectedSourceNodeOption}
            defaultOptions={limitedOptions}
            loadOptions={handleOnLoadOptions}
            onChange={handleOnSelect}
            onMenuScrollToBottom={e => {}}
            formatGroupLabel={formatGroupLabel}
            menuPortalTarget={document.body}
            components={{
              DropdownIndicator,
              MenuList,
              Option,
              SingleValue,
            }}
            styles={{
              ...defaultSelectStyles(),
              menu: provided => ({
                ...provided,
                width: 'max-content',
                minWidth: '100%',
              }),
              control: (styles, props) => ({
                ...defaultSelectStyles().control(styles, props),
                backgroundColor: '#fff',
              }),
              valueContainer: (styles, props) => ({
                ...defaultSelectStyles().valueContainer(styles, props),
              }),
            }}
          />

          {mapType === SecurityGraphMapType.Access && (
            <Stack spacing={3} fontSize="sm">
              <HStack>
                <Switch
                  size={'sm'}
                  isChecked={responseLevel === SearchResponseLevel.Full}
                  onChange={e => {
                    dispatch(
                      actions.updateSearchResponseLevel(
                        e.target.checked
                          ? SearchResponseLevel.Full
                          : SearchResponseLevel.Compact,
                      ),
                    );
                    dispatch(
                      actions.updateRedirectInfoField({
                        response_level: e.target.checked
                          ? SearchResponseLevel.Full
                          : SearchResponseLevel.Compact,
                      }),
                    );
                    dispatch(actions.updateFilterChanged(true));
                    params.set(
                      'response_level',
                      e.target.checked ? 'full' : 'compact',
                    );

                    const url = `${
                      window.location.pathname
                    }?${params.toString()}`;
                    navigate(url);
                  }}
                />
                <Text>Show Detailed Map</Text>
              </HStack>
              {/*<HStack>*/}
              {/*  <Switch size={'sm'} />*/}
              {/*  <Text>Show Deny Map</Text>*/}
              {/*</HStack>*/}
            </Stack>
          )}
        </Stack>
      </SidebarAccordion>
    </Stack>
  );
};
