import { QueryAction } from 'services/types';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import {
  IQueryPage,
  IQueryState,
  MapFn,
  QueryState,
  QueryStateNextOpts,
  QueryStateOpts,
} from 'services/utils/QueryState';

export interface PagedQueryStateOpts extends QueryStateOpts {
  page: IQueryPage;
}

export interface PagedQueryStateNextOpts<P, D>
  extends QueryStateNextOpts<P, D> {
  mapTotalCount: MapFn<P, number>;
}

export type IPagedQueryState<T> = IQueryState<T> & {
  page: IQueryPage;
};

// slice state with pagination support
export class PagedQueryState<D> extends QueryState<D> {
  page: IQueryPage;

  public static init<T>(data: T, ops: QueryStateOpts = {}) {
    return new PagedQueryState(data, data, {
      page: {
        totalCount: ops.page?.totalCount ?? 0,
        info: ops.page?.info ?? INIT_PAGE_INFO,
      },
    }).toJSON();
  }

  // create next state from current state,
  // optionally using a mapper
  public static next<P, D, Q>(
    state: IPagedQueryState<D>,
    action: QueryAction<P, Q>,
    opts?: PagedQueryStateNextOpts<P, D>,
  ): IPagedQueryState<D> {
    const { mapTotalCount = () => 0 } = opts ?? {};

    const { payload } = action;
    let { data: payloadData, page = state.page.info } = payload;
    let totalCount = 0;
    if (payloadData) {
      totalCount = mapTotalCount(payloadData);
    }

    return {
      page: { totalCount, info: page },
      ...QueryState.next(state, action, opts),
    };
  }

  // WARNING: Does not work
  // private static update<P, D, Q>(
  //   state: IPagedQueryState<D>,
  //   action: QueryAction<P, Q>,
  //   opts?: PagedQueryStateNextOpts<P, D>,
  // ) {
  //   // @ts-ignore
  //   state = PagedQueryState.next(state, action, opts);
  // }

  protected constructor(initState: D, data: D, opts: PagedQueryStateOpts) {
    super(initState, data, opts);
    this.page = opts.page;
  }

  protected toJSON(): IPagedQueryState<D> {
    return {
      page: this.page,
      ...super.toJSON(),
    };
  }
}
