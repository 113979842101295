import { PayloadAction } from '@reduxjs/toolkit';

import { apiCallStateInitialState } from 'api/initial_values';
import {
  Organization,
  OrganizationServiceCreateRequest,
  OrganizationServiceGetRequest,
  OrganizationServiceGetResponse,
  OrganizationServiceUpdateRequest,
  UserGroup,
  UserGroupServiceGetRequest,
  UserGroupServiceGetResponse,
} from 'app/api/auth/api.pb';
import {
  onApiCall,
  onApiCallError,
  onApiCallSuccess,
} from 'app/api/call_status';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ADMIN_FORM_TYPE, ContainerState, FieldValue } from './types';

// The initial state of the OrganizationWizard container
export const initialState: ContainerState = {
  createOrganization: {
    data: {
      organization: {
        name: '',
        address: '',
        zip_code: '',
        country: '',
        city: '',
        enabled: false,
        full_name: '',
      },
      admin_user: {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: [],
        user_groups: [],
        account_ids: [],
      },
    },
    error: null,
    loading: false,
  },
  getOrganizationById: {
    error: null,
    loading: false,
    loaded: false,
  },
  updateOrganization: {
    data: {
      organization: {
        name: '',
        address: '',
        city: '',
        zip_code: '',
        country: '',
        enabled: false,
        full_name: '',
      },
    },
    error: null,
    loading: false,
    loaded: false,
  },
  updateOrgUser: {
    data: {
      new_user: {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roles: [],
        user_groups: [],
        account_ids: [],
      },
      formType: ADMIN_FORM_TYPE.view_admins,
    },
    error: null,
    loading: false,
    loaded: false,
  },
  orgAdminGroupById: apiCallStateInitialState<UserGroup>({}),
  organizationOverview: apiCallStateInitialState<Organization>({}),
  activeOrganization: {},
};

const organizationWizardSlice = createSlice({
  name: 'organizationWizard',
  initialState,
  reducers: {
    resetCreateOrganization(state) {
      state.createOrganization = initialState.createOrganization;
    },
    updateCreateOrganizationDetails(state, action: PayloadAction<FieldValue>) {
      const { field, value } = action.payload;
      state.createOrganization.data.organization[field] = value;
    },
    updateCreateAdminDetails(state, action: PayloadAction<FieldValue>) {
      const { field, value } = action.payload;
      state.createOrganization.data.admin_user[field] = value;
    },
    createOrganization(
      state,
      action: PayloadAction<OrganizationServiceCreateRequest>,
    ) {
      onApiCall(state.createOrganization);
    },
    createOrganizationSuccess(state) {
      onApiCallSuccess(state.createOrganization);
    },
    createOrganizationError(state, action: PayloadAction<any>) {
      onApiCallError(state.createOrganization, action.payload);
    },

    // org admin group
    getOrganizationAdminGroupById(
      state,
      action: PayloadAction<UserGroupServiceGetRequest>,
    ) {
      onApiCall(state.orgAdminGroupById);
    },
    getOrganizationAdminGroupByIdSuccess(
      state,
      action: PayloadAction<UserGroupServiceGetResponse>,
    ) {
      state.orgAdminGroupById.data = action.payload.user_group ?? {};
      onApiCallSuccess(state.orgAdminGroupById);
    },
    getOrganizationAdminGroupByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.orgAdminGroupById, action.payload);
    },

    // org by id
    getOrganizationById(
      state,
      action: PayloadAction<OrganizationServiceGetRequest>,
    ) {
      // state.getOrgById.name = action.payload.name;
      onApiCall(state.getOrganizationById);
      // state.updateOrganization.data.organization.name = action.payload.name;
    },
    getOrgByIdSuccess(
      state,
      action: PayloadAction<OrganizationServiceGetResponse>,
    ) {
      // state.activeOrganization = action.payload.organization;
      // state.organizationOverview.data = action.payload.organization;
      const {
        name = '',
        address = '',
        city = '',
        zip_code = '',
        country = '',
        full_name = '',
        admin_group_id,
        enabled,
      } = action.payload.organization || {};

      state.updateOrganization.data.organization = {
        name,
        full_name,
        address,
        country,
        city,
        zip_code,
        admin_group_id,
        enabled,
      };

      onApiCallSuccess(state.getOrganizationById);
    },

    getOrganizationByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.getOrganizationById, action.payload);
    },

    resetUpdateOrganization(state) {
      state.updateOrganization = initialState.updateOrganization;
      state.getOrganizationById = initialState.getOrganizationById;
    },
    updateUpdateOrganizationDetails(state, action: PayloadAction<FieldValue>) {
      const { field, value } = action.payload;
      state.updateOrganization.data.organization[field] = value;
    },
    updateUpdateNewOrgUserDetails(state, action: PayloadAction<FieldValue>) {
      const { field, value } = action.payload;
      state.updateOrgUser.data.new_user[field] = value;
    },
    updateUpdateFormType(state, action: PayloadAction<ADMIN_FORM_TYPE>) {
      state.updateOrgUser.data.formType = action.payload;
    },

    // Update Organization
    updateOrganization(
      state,
      action: PayloadAction<OrganizationServiceUpdateRequest>,
    ) {
      onApiCall(state.updateOrganization);
    },
    updateOrganizationSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.updateOrganization);
    },
    updateOrganizationError(state, action: PayloadAction<any>) {
      onApiCallError(state.updateOrganization, action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = organizationWizardSlice;
