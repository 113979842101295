/**
 *
 * Create blueprint
 *
 */

import React, { memo, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/DataDisplay/Card';
import { Steps } from 'components/Navigation/Steps';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { CreateFormType } from 'containers/PolicyHub/types';

import { actions } from '../../../slice';

import { CreatePolicyDetails } from './Steps/PolicyDetails';
import { PolicyRules } from './Steps/PolicyRules';
import { PolicyScope } from './Steps/PolicyScope';

export const CreatePolicyBlueprint = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [formType, setFormType] = useState(CreateFormType.FromExisting);

  const { blueprintForm, blueprints } = useSelector(selectBlueprints);

  useEffect(() => {
    return () => {
      dispatch(actions.resetBlueprintForm());
    };
  }, [dispatch]);

  //get all blueprints
  useEffect(() => {
    if (!blueprints.isSuccess && formType === CreateFormType.FromExisting) {
      dispatch(actions.getBlueprints({ q: {} }));
    }
  }, [blueprints.isSuccess, dispatch, formType]);

  const updateRules = (rules: string[]) => {
    dispatch(
      actions.updateBlueprintFormFields({
        field: 'rules',
        value: rules.map(r => ({ rule_id: r })),
      }),
    );
  };

  console.log(blueprintForm)

  const handleSubmit = data => {
    if (currentStep === 0) {
    }
    if (currentStep === 1) {
      updateRules(data);
    }

    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === 2) {
      dispatch(
        actions.createBlueprint({
          q: { blueprintCreate: blueprintForm },
          onSuccess: () => {
            navigate('/policy-hub');
            dispatch(actions.resetBlueprintForm());
          },
        }),
      );
    }
  };

  const handleChangeFormType = useCallback((value: CreateFormType) => {
    setFormType(value);
  }, []);

  const handleCancel = () => {
    navigate('/policy-hub');
    dispatch(actions.resetBlueprintForm());
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const stepsOptions = [
    {
      label: 'Details',
      component: () => (
        <CreatePolicyDetails
          handleSubmit={handleSubmit}
          setFormType={handleChangeFormType}
          formType={formType}
          handleCancel={handleCancel}
        />
      ),
    },
    {
      label: 'Policies',
      component: () => (
        <PolicyRules
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          formType={formType}
          handleCancel={handleCancel}
          selectedRules={blueprintForm?.rules?.map(r => r.rule_id) ?? []}
          onChange={updateRules}
        />
      ),
    },
    {
      label: 'Scope',
      component: () => (
        <PolicyScope
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          environmentIds={
            blueprintForm?.configs?.map(a => a.environment_id ?? '') ?? []
          }
        />
      ),
    },
  ];

  return (
    <Card
      contentComponent={<Steps current={currentStep} steps={stepsOptions} />}
    />
  );
});
