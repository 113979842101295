/**
 *
 * User Details
 *
 */

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { cloneDeep, filter, includes, merge } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Form, FormStyles } from 'app/components/DataEntry/Form';
import { PageHeaderWithIcon } from 'components/DataDisplay';
import { UserIcon } from 'components/Icons';
import {
  selectCurrentOrg,
  selectOnboardedCloudAccountOptions,
  selectUser,
} from 'containers/App/selectors';
import { cloudAccountsSaga } from 'containers/Setup/CloudAccounts/saga';
import * as accountSlice from 'containers/Setup/CloudAccounts/slice';
import { groupsSaga } from 'containers/Setup/Groups/saga';
import { selectGroups } from 'containers/Setup/Groups/selectors';
import * as groupSlice from 'containers/Setup/Groups/slice';
import { rolesSaga } from 'containers/Setup/Roles/saga';
import { selectRoles } from 'containers/Setup/Roles/selectors';
import * as roleSlice from 'containers/Setup/Roles/slice';
import { useInjector } from 'utils/inject';
import { toPascalCase } from 'utils/string';

import { passwordRegExp } from '../../../../../utils/regex';
import { getOptions } from '../../utils';
import { setupUsersSaga } from '../saga';
import { selectSetupUsers } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';
import { form_styles } from '../styles';
import { isMasterOrg } from '../utils';

interface Props {
  action?: 'create' | 'update' | '';
  isActionOnProgress?: boolean;
  isDataLoading?: boolean;

  onSubmit(data: any);

  passField?: boolean;
  noHeading?: boolean;

  styles?: FormStyles;
}

export const UserDetails = memo((props: Props) => {
  useInjector(sliceKey, reducer, setupUsersSaga);
  useInjector(roleSlice.sliceKey, roleSlice.reducer, rolesSaga);
  useInjector(groupSlice.sliceKey, groupSlice.reducer, groupsSaga);
  useInjector(accountSlice.sliceKey, accountSlice.reducer, cloudAccountsSaga);

  const {
    isActionOnProgress,
    onSubmit,
    action = '',
    passField = false,
    noHeading = false,
    isDataLoading,
    styles,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { groups } = useSelector(selectGroups);
  const { roles: rolesData } = useSelector(selectRoles);
  const cloudAccounts = useSelector(selectOnboardedCloudAccountOptions);

  const { userForm } = useSelector(selectSetupUsers);
  const [username, setUsername] = useState(userForm.data.username);
  const [email, setEmail] = useState(userForm.data.email);
  const [firstName, setFirstName] = useState(userForm.data.first_name);
  const [lastName, setLastName] = useState(userForm.data.last_name);
  const [password, setPassword] = useState('');
  const [autoAddAccounts, setAutoAddAccounts] = useState(
    userForm.data.auto_add_accounts,
  );

  const [groupOptions, setGroupOptions] = useState<Record<string, any>[]>([]);
  const [roleOptions, setRoleOptions] = useState<Record<string, any>[]>([]);
  const [cloudAccountOptions, setCloudAccountOptions] = useState<
    Record<string, any>[]
  >([]);

  const [selectedAccounts, setSelectedAccounts] = useState<
    Record<string, any>[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<Record<string, any>[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Record<string, any>[]>(
    [],
  );

  useEffect(() => {
    const {
      username,
      first_name,
      last_name,
      email,
      auto_add_accounts,
    } = userForm.data;
    setUsername(username);
    setFirstName(first_name);
    setLastName(last_name);
    setEmail(email);
    setAutoAddAccounts(auto_add_accounts);
  }, [userForm]);

  const formStyle = useMemo(() => {
    const props = cloneDeep(form_styles);
    return merge(props, styles);
  }, [styles]);

  const user = useSelector(selectUser);
  const orgName = useSelector(selectCurrentOrg);

  useEffect(() => {
    dispatch(groupSlice.actions.loadGroups({ organization: user.info.org_id }));
  }, [dispatch, user.info.org_id]);

  useEffect(() => {
    dispatch(roleSlice.actions.getRoles());
  }, [dispatch]);

  useEffect(() => {
    setGroupOptions([
      { label: 'Select all groups', value: 'all' },
      ...getOptions(groups.data, 'name'),
    ]);
  }, [groups]);

  useEffect(() => {
    setRoleOptions([
      { label: 'Select all roles', value: 'all' },
      ...getOptions(rolesData.roles, 'name'),
    ]);
  }, [rolesData]);

  useEffect(() => {
    setCloudAccountOptions([
      { label: 'Select all accounts', value: 'all' },
      ...cloudAccounts,
    ]);
  }, [cloudAccounts]);

  useEffect(() => {
    setSelectedAccounts(
      filter(cloudAccounts, o =>
        includes(userForm.data?.account_ids, o?.value),
      ),
    );
  }, [cloudAccounts, userForm]);

  useEffect(() => {
    const roles = userForm.data?.roles?.map(o => o.name);
    setSelectedRoles(filter(roleOptions, o => includes(roles, o?.label)));
  }, [userForm, roleOptions]);

  useEffect(() => {
    const groups = userForm.data?.user_groups?.map(o => o.name);
    setSelectedGroups(filter(groupOptions, o => includes(groups, o?.label)));
  }, [userForm, groupOptions]);

  const handleSubmit = data => {
    onSubmit(data);
  };

  useEffect(() => {
    if (action === 'create') {
      dispatch(actions.resetUserForm({}));
    }
  }, [action, dispatch]);

  const handleReset = useCallback(() => {
    navigate('/setup/users');
    dispatch(actions.resetUserForm({}));
  }, [dispatch, navigate]);

  const handleUpdateAutoAddAccounts = flag => {
    setAutoAddAccounts(flag);
  };

  // const allRoles: FormSchema = selectedGroup
  //   ? {
  //       allRoles: {
  //         type: 'custom',
  //         component: () => renderRoles(selectedGroup),
  //       },
  //     }
  //   : {};

  // console.log({
  //   username: userForm.data.username,
  //   password: userForm.data.password,
  //   teamGroup: userForm.data.teamGroup,
  //   name: {
  //     first_name: userForm.data.name.first_name,
  //     last_name: userForm.data.name.last_name,
  //   },
  // });

  const schemaForm = yup.object().shape({
    ...(action === 'create'
      ? {
          username: yup
            .string()
            .matches(
              /^[a-zA-Z0-9_.]*$/i,
              'Only alphanumeric, underscore, period allowed!',
            ),
        }
      : {}),
    email: yup.string().email('Email is not valid!'),
    password: yup
      .string()
      .matches(
        passwordRegExp,
        'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character!',
      ),
    fullname: yup.object().shape({
      first_name: yup
        .string()
        .matches(
          /^[a-zA-Z0-9-. ]*$/i,
          'Only alphanumeric, space, period, hyphen allowed!',
        ),
      last_name: yup
        .string()
        .matches(
          /^[a-zA-Z0-9-. ]*$/i,
          'Only alphanumeric, space, period, hyphen allowed!',
        ),
    }),
  });

  return (
    <Box pos={'relative'}>
      <Form
        isLoading={isDataLoading}
        title={
          <PageHeaderWithIcon
            label={noHeading ? '' : toPascalCase(action) + ' User'}
            icon={<UserIcon />}
            reversed
          />
        }
        formValidation={schemaForm}
        schema={{
          username: {
            type: 'text',
            label: 'Username',
            isRequired: true,
            placeholder: 'Alex',
            value: username,
            isDisabled: action === 'update',
            tooltip: 'Only alphanumeric, underscore, period allowed',
            onChange: v => setUsername(v),
          },
          email: {
            type: 'text',
            label: 'Email',
            isRequired: true,
            value: email,
            placeholder: 'example@abc.com',
            tooltip: 'Enter valid email',
            onChange: setEmail,
          },
          ...(passField
            ? {
                password: {
                  type: 'text',
                  label: 'Password',
                  isRequired: true,
                  htmlInputType: 'password',
                  value: password,
                  onChange: setPassword,
                  tooltip:
                    'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
                },
              }
            : {}),
          fullname: {
            type: 'object',
            properties: {
              first_name: {
                type: 'text',
                label: 'First Name',
                value: firstName,
                onChange: setFirstName,
                tooltip: 'Only alphanumeric, space, period, hyphen allowed',
              },
              last_name: {
                type: 'text',
                label: 'Last Name',
                value: lastName,
                onChange: setLastName,
                tooltip: 'Only alphanumeric, space, period, hyphen allowed',
              },
            },
          },
          ...(action === 'update'
            ? {
                ...(!isMasterOrg({ orgName })
                  ? {
                      clouds: {
                        type: 'react-select',
                        label: 'Cloud',
                        options: [
                          { label: 'AWS', value: 'aws' },
                          // {label: 'GCP', value: 'gcp'},
                          // {label: 'Azure', value: 'azure'},
                        ],
                        isRequired: true,
                        value: { label: 'AWS', value: 'aws' },
                        // onChange: selected => setSelectedRoles(selected),
                      },
                    }
                  : {}),
                roles: {
                  type: 'react-select',
                  label: 'Roles',
                  isMulti: true,
                  options: roleOptions,
                  value: selectedRoles,
                  onChange: selected => setSelectedRoles(selected),
                },
                user_groups: {
                  type: 'react-select',
                  label: 'Team/Group',
                  isMulti: true,
                  options: groupOptions,
                  value: selectedGroups,
                  onChange: selected => setSelectedGroups(selected),
                },
                ...(!isMasterOrg({ orgName })
                  ? {
                      account_ids: {
                        type: 'react-select',
                        label: 'Accounts',
                        isMulti: true,
                        options: cloudAccountOptions,
                        value: selectedAccounts,
                        onChange: selected => setSelectedAccounts(selected),
                      },
                    }
                  : {}),
              }
            : {}),
          auto_add_accounts: {
            type: 'switch',
            label: 'Automatically add newly onboarded accounts to user',
            isChecked: autoAddAccounts,
            onChange: handleUpdateAutoAddAccounts,
          },
          // ...allRoles,
        }}
        styles={formStyle}
        buttonOptions={{
          submit: {
            name: 'Okay',
            isLoading: isActionOnProgress,
            isDisabled: isDataLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
});
