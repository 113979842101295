import React, { useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { map, round, sum } from 'lodash';
import { useSelector } from 'react-redux';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  BellIcon,
  CriticalDataIcon,
  ExclamationTriangleIcon,
} from 'components/Icons';
import { renderDataSecuritySummaryCount } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const AnalysisOfDataSources: React.FC = () => {
  const {
    sensitiveDataSourceCount,
    publiclyExposedDataSources,
    summaryOfAllDataSources,
    alertingCount,
  } = useSelector(selectDataDashboard);
  const [exposedSources, setExposedSources] = useState(0);

  useEffect(() => {
    setExposedSources(sum(map(publiclyExposedDataSources.data, o => o.total)));
  }, [publiclyExposedDataSources.data]);

  const resources = summaryOfAllDataSources.data.resources ?? 0;

  const legends = [
    {
      label: 'Sensitive',
      count: sensitiveDataSourceCount.data ?? 0,
      percentChange: !!resources
        ? sensitiveDataSourceCount.data / resources
        : 0,
      icon: <CriticalDataIcon color="critical" />,
      isLoading: sensitiveDataSourceCount.isLoading,
    },
    {
      label: 'Exposed',
      count: exposedSources,
      percentChange: !!resources ? exposedSources / resources : 0,
      icon: <ExclamationTriangleIcon color="critical" />,
      isLoading:
        summaryOfAllDataSources.isLoading ||
        publiclyExposedDataSources.isLoading,
    },
    {
      label: 'Alerting',
      count: alertingCount.data ?? 0,
      percentChange: !!resources ? alertingCount.data / resources : 0,
      icon: <BellIcon color="critical" w={8} h={8} />,
      isLoading: alertingCount.isLoading,
    },
  ];

  return (
    <HStack w="full" h="full" px={3} py={5} spacing={1} justify="space-between">
      {map(legends, o =>
        renderDataSecuritySummaryCount(
          o.icon,
          <HStack spacing={1}>
            <Box>{formatNumber(o.count)}</Box>
            <Box fontWeight={400} fontSize="xs">
              ({round(o.percentChange * 100)}%)
            </Box>
          </HStack>,
          o.label,
          o.isLoading,
          () => {},
        ),
      )}
    </HStack>
  );
};
