import React, { ReactNode } from 'react';

import { Center } from '@chakra-ui/react';

interface NoDataAvailableProps {
  text?: ReactNode | string;
}

export const NoDataAvailable = (props: NoDataAvailableProps) => {
  const { text = 'No data available' } = props;
  return (
    <Center h="full" w="full" color="gray.400">
      {text}
    </Center>
  );
};
