import React from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { UserIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const FederatedUsers: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { federatedUsersChange } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Federated Users"
      icon={<UserIcon color="orange" />}
      change={{
        isLoading: federatedUsersChange.isLoading,
        first: federatedUsersChange.data?.previous_count,
        last: federatedUsersChange.data?.current_count,
        percentChange: federatedUsersChange?.data?.percentage_change,
      }}
      cloudProviders={
        !!inventorySummary.data.items?.['User']?.['Federated User']
          ? Object.keys(
              inventorySummary.data.items?.['User']?.['Federated User'],
            )
          : []
      }
      count={
        !!inventorySummary.data.items?.['User']?.['Federated User']
          ? sum(
              Object.values(
                inventorySummary.data.items?.['User']?.['Federated User'],
              ),
            )
          : 0
      }
      isLoading={inventorySummary.isLoading}
      onClick={() =>
        navigate(
          '/inventory/summary?tab=' +
            Categories.Identity +
            '_' +
            Resources.User,
        )
      }
      labelTooltip={formatTooltip({
        header: 'Federated Users',
        content:
          'Includes users in IdP such as Okta, Azure Entra etc. with access to public clouds, SaaS',
        width: 48,
      })}
    />
  );
};
