import React, { useState, FC, useMemo, useEffect } from 'react';

import { Entities } from '@ariksa/notification';
import { Box, Stack, Button, Center } from '@chakra-ui/react';
import { map, filter, some, omit, includes, forEach, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { TagKeyValueCondition } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowConditions/AlertConditions/TagKeyValueCondition';
import { useAlertWorkflowWizardContext } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowWizardContext';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';

import { selectAlertWorkflow } from '../../../../selectors';
import { deleteIconStyles, valueContainerStyles } from '../../../../styles';

import { AlertCategories } from './AlertCategories';
import { AlertConditionsLabel } from './AlertConditionsLabel';
import { IdentityTypes } from './IdentityTypes';
import { Severity } from './Severity';

interface Props {}

export const AlertConditions: FC<Props> = props => {
  const { formData, alertConditions, tagKeyValues } = useSelector(
    selectAlertWorkflow,
  );
  const { tagKeyOptions } = useAlertWorkflowWizardContext();
  const { trigger } = formData;
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any>[]>(
    [],
  );

  const dispatch = useDispatch();

  const conditions = useMemo(
    () => [
      {
        label: 'If alert or policy belongs to category',
        component: <AlertCategories />,
        value: Entities.Category,
      },
      {
        label: 'If alert or policy is for entity type',
        component: <IdentityTypes />,
        value: Entities.Resource,
      },
      {
        label: 'If alert or policy is for identity with tag',
        component: <TagKeyValueCondition />,
        value: Entities.Tags,
      },
      /*{
      label: 'If alert is for identity instance',
      component: <IdentityInstance />,
      value: 'identityInstance',
    },
    {
      label: 'If alert is for identity created by user',
      component: <IdentityCreatedBy />,
      value: 'user',
    },
    {
      label: 'If alert is for identity with tag',
      component: <IdentityWithTag />,
      value: 'tag',
    },*/
      {
        label: 'If alert or policy has severity of critical/high/medium/low',
        component: <Severity />,
        value: Entities.Severity,
      },
      /*{
      label:
        'If alert is for entity connected to number of other entities or identities',
      component: (
        <Stack w="full" isInline align="center">
          <Box whiteSpace="nowrap">If alert is for entity connected to</Box>
          <Input minW={10} {...commonFieldStyles} placeholder="value" />
          <Box whiteSpace="nowrap">other entities or identities</Box>
        </Stack>
      ),
      value: 'connectedTo',
    },
    {
      label: 'If alert count is less/greater than some value',
      component: <AlertCount />,
      value: 'alertCount',
    },
    {
      label: 'If alert has been seen for {times}',
      component: <AlertSeenFor />,
      value: 'seenFor',
    },
    {
      label: 'If alert has been active for {time}',
      component: (
        <Stack w="full" isInline align="center">
          <Box>If alert has been active for</Box>
          <Input w={10} {...commonFieldStyles} placeholder="value" />
          <Box w={32}>
            <Select
              options={intervalOptions}
              defaultValue={intervalOptions[0]}
            />
          </Box>
          <Box>or more</Box>
        </Stack>
      ),
      value: 'activeFor',
    },*/
    ],
    [],
  );

  useEffect(() => {
    setSelectedOptions(
      filter(conditions, o => includes(Object.keys(alertConditions), o.value)),
    );
  }, [alertConditions, conditions]);

  return (
    <Stack>
      <AlertConditionsLabel />

      <Stack>
        <Box mb={2}>
          <Select
            name="tempName4"
            options={trigger.length > 0 ? conditions : []}
            value={selectedOptions}
            onChange={selected => {
              let options: Record<string, any> = {};
              forEach(selected, o => {
                if (some(alertConditions, { name: o.value }))
                  options[o.value] = alertConditions[o.value];
                else options[o.value] = { name: o.value, entity_list: [] };
              });
              dispatch(actions.updateAlertWorkflowConditions(options));
              if (some(selected, { value: Entities.Tags })) {
                if (isEmpty(tagKeyValues)) {
                  const id = nanoid();
                  dispatch(
                    actions.addTagKeyValue({
                      id,
                      tag_key: tagKeyOptions?.[0]?.value ?? '',
                    }),
                  );
                }
              } else dispatch(actions.updateTagKeyValues({}));
            }}
            isMulti
            showTotalSelected={selectedOptions.length > 0}
            placeholder="Add a condition..."
          />
        </Box>
        {map(selectedOptions, each => (
          <Stack
            {...valueContainerStyles}
            key={each?.value + '-alert-condition'}
          >
            <Center flex={1}>{each?.component}</Center>
            <Button
              {...deleteIconStyles}
              onClick={() => {
                dispatch(
                  actions.updateAlertWorkflowConditions(
                    omit(alertConditions, each?.value),
                  ),
                );
                if (each.value === Entities.Tags)
                  dispatch(actions.updateTagKeyValues({}));
              }}
            >
              <TrashIcon />
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
