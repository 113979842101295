import React, { FC, useCallback, useReducer } from 'react';

import { Box, HStack, Stack, Checkbox } from '@chakra-ui/react';
import { filter, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useBlueprintPolicyColumns } from 'containers/PolicyHub/Blueprints/hooks/useBlueprintPolicyColumns';
import { PoliciesTableHeader } from 'containers/PolicyHub/Components/PoliciesTableHeader/PoliciesTableHeader';
import { policyTypeOptions } from 'containers/PolicyHub/Components/PoliciesTableHeader/utils';

import { selectBlueprints } from '../../../../../../selectors';
import { actions } from '../../../../../../slice';

interface Props {
  selectedPolicies: string[];
  setSelectedPolicies(value: string[]);
}

export const AllPoliciesTable: FC<Props> = props => {
  const { selectedPolicies, setSelectedPolicies } = props;
  const dispatch = useDispatch();
  const { rules, selectedBlueprintID } = useSelector(selectBlueprints);
  const { allPoliciesColumns } = useBlueprintPolicyColumns();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: {},
      alertCategory: {},
      currentRecord: {},
      searchTerm: '',
      severity: { label: 'All Severities', value: undefined },
      policyType: policyTypeOptions[0],
    },
  );

  //get policies
  const getBlueprintPolicies = useCallback(() => {
    dispatch(
      actions.getAllRules({
        q: {
          blueprintId: selectedBlueprintID || undefined,
          alertCategory: state?.alertCategory?.value || undefined,
          resourceType: state?.resourceType?.value || undefined,
          //severity: state?.alertCategory?.value || undefined,
          searchTerm: state.searchTerm || undefined,
        },
      }),
    );
  }, [
    dispatch,
    selectedBlueprintID,
    state?.alertCategory,
    state?.resourceType,
    state?.searchTerm,
  ]);

  useDeepCompareEffect(() => {
    getBlueprintPolicies();
  }, [getBlueprintPolicies]);

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  return (
    <Stack w="full" h="full" spacing={4}>
      <HStack w="full">
        <PoliciesTableHeader
          resourceType={state.resourceType}
          alertCategory={state.alertCategory}
          policyType={state.policyType}
          severity={state.severity}
          updateValue={updateValue}
          action="Show"
        />
      </HStack>
      <Box h="full" flex={1}>
        <Table
          data={rules.data}
          columns={[
            {
              header: (
                <HStack spacing={4}>
                  <Checkbox
                    isChecked={rules.data?.length === selectedPolicies?.length}
                    isIndeterminate={
                      !!selectedPolicies?.length &&
                      rules.data?.length !== selectedPolicies?.length
                    }
                    onChange={e =>
                      e.target.checked
                        ? setSelectedPolicies(map(rules.data, o => o.id!))
                        : setSelectedPolicies([])
                    }
                  />
                  <Box>Description</Box>
                </HStack>
              ),
              accessor: 'compliance_description',
              render: ({ row, value }) => (
                <HStack spacing={4}>
                  <Box onClick={e => e.stopPropagation()}>
                    <Checkbox
                      isChecked={includes(selectedPolicies, row.id)}
                      onChange={e => {
                        e.stopPropagation();
                        if (!includes(selectedPolicies, row.id)) {
                          setSelectedPolicies([...selectedPolicies, row.id]);
                        } else {
                          setSelectedPolicies(
                            filter(selectedPolicies, a => a !== row.id),
                          );
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    {row?.compliance_description || row?.alert_description}
                  </Box>
                </HStack>
              ),
            },
            ...allPoliciesColumns,
          ]}
          onRowClick={row => {
            if (includes(selectedPolicies, row.id))
              setSelectedPolicies(filter(selectedPolicies, a => a !== row.id));
            else setSelectedPolicies([...selectedPolicies, row.id]);
          }}
          isLoading={rules.isLoading}
          styles={{ header: { zIndex: 800 } }}
        />
      </Box>
    </Stack>
  );
};
