import React from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import map from 'lodash/map';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

export const renderCriteria = ({ row }) => {
  if (
    row?.criteria &&
    (row?.criteria?.region ||
      row?.criteria?.tag_keys_condition?.keys ||
      row?.criteria?.tag_key_values_condition)
  ) {
    const {
      region,
      tag_keys_condition,
      tag_key_values_condition,
    } = row?.criteria;
    return (
      <HStack spacing={1}>
        {!!region && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(region, r => (
                      <Box>{r}</Box>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {region?.length} {'region'}
                  {region?.length > 1 ? 's' : ''}
                </Box>
              </CustomTooltip>
            }
          />
        )}
        {!!tag_keys_condition?.key && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(tag_keys_condition?.key, r => (
                      <Box>{r}</Box>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {tag_keys_condition?.key?.length} {'tag key'}
                  {tag_keys_condition?.key?.length > 1 ? 's' : ''}
                </Box>
              </CustomTooltip>
            }
          />
        )}
        {!!tag_key_values_condition && (
          <Tag
            label={
              <CustomTooltip
                label={
                  <Stack spacing={1}>
                    {map(tag_key_values_condition, r => (
                      <HStack spacing={1}>
                        <Box color="orange.300">{r?.key}:</Box>
                        <Box>{r?.value?.join(', ')}</Box>
                      </HStack>
                    ))}
                  </Stack>
                }
              >
                <Box>
                  {tag_key_values_condition?.length} {'tag key + value'}
                </Box>
              </CustomTooltip>
            }
          />
        )}
      </HStack>
    );
  } else return '-';
};
