import {
  ContextApiGetRiskContextResRequest,
  RiskContextResponse,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useInventoryService() {
  const riskContext = useAriksaQuery<
    ContextApiGetRiskContextResRequest,
    Record<string, RiskContextResponse[]>
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.Context.getRiskContextRes(req).then(r => r.data),
  });

  return {
    riskContext,
  };
}
