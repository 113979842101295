import React, { FC, useMemo } from 'react';

import { Stack } from '@chakra-ui/react';

import { StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';

interface ITrafficInfoModal {
  data: any;
  onClose: () => void;
  isOpen: boolean;
}

export const TrafficInfoModal: FC<ITrafficInfoModal> = props => {
  const { data, onClose, isOpen } = props;

  const { getCloudNativeName } = useResourceType();
  console.log(data);

  const columns = useMemo(() => {
    return [
      {
        header: 'port',
        accessor: 'port',
      },
      {
        header: 'malicious',
        align: 'left',
        render: (row: any) => {
          return data.properties.is_malicious ? 'Yes' : 'No';
        },
      },
    ];
  }, [data.properties.is_malicious]);

  const tableData = useMemo(() => {
    return data.properties.ports.map((port: any) => {
      return {
        port: port,
        is_malicious: data.is_malicious,
      };
    });
  }, [data.is_malicious, data.properties.ports]);

  console.log(tableData);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          type={data.resourceType ?? ''}
          upper={getCloudNativeName(data.resourceType ?? '')}
          lower={data.name === 'Ingress' ? 'Ingress Traffic' : 'Egress Traffic'}
        />
      }
      body={
        <Stack minH={'400px'}>
          <Table columns={columns} data={tableData} />
        </Stack>
      }
      size={'3xl'}
    />
  );
};
