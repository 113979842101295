import { customTheme } from 'theme';

import { DrawerStyles } from './types';

export const defaultStyles: DrawerStyles = {
  button: {
    variant: 'outline',
    color: 'primary',
    border: '1px',
    borderColor: 'primary',
    _hover: {
      bg: 'primary',
      color: 'white',
    },
    _focus: {
      outline: 'none',
      bg: 'primary',
      color: 'white',
      boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
  },
  header: {
    borderBottom: '1px solid',
    borderColor: 'gray.100',
    fontSize: 'md',
  },
};
