import {
  CloudAccountApiChangeDiscoverInventoryIntervalRequest,
  CloudAccountApiGetCloudAccountsRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  GetSlasResponse,
  SlaApiUpdatePredefinedSlasRequest,
  SlaApiUpdateSlaRequest,
} from '@ariksa/notification/api';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { CloudAccountService, NotificationService } from 'api/services';
import { actions } from 'containers/Settings/slice';

export function* settingsSaga() {
  // yield takeLatest(actions.updatePassword.type, doUpdatePassword);
  yield takeLatestAction(
    actions.updateAccountDiscoveryInterval,
    doUpdateAccountDiscoveryInterval,
  );
  yield takeLatestAction(actions.getCloudAccounts, doGetCloudAccounts);
  yield takeLatestAction(actions.updateSLAs, doUpdateSLAs);
  yield takeLatestAction(actions.getSLAs, doGetSLAs);
  yield takeLatestAction(actions.getAvailableSLAs, doGetAvailableSLAs);
  yield takeLatestAction(actions.updatePredefinedSlas, doUpdatePredefinedSlas);
}

export function* doGetAvailableSLAs(ctx: QueryContext<number[], any>) {
  yield ctx.fetch(
    () => NotificationService.SLA.getPredefinedSlas(ctx.params),
    {},
  );
}

export function* doUpdatePredefinedSlas(
  ctx: QueryContext<any, SlaApiUpdatePredefinedSlasRequest>,
) {
  yield ctx.fetch(
    () => NotificationService.SLA.updatePredefinedSlas(ctx.params),
    {
      successMsg: 'Updated SLAs successfully',
    },
  );
}

export function* doGetSLAs(ctx: QueryContext<GetSlasResponse[], any>) {
  yield ctx.fetch(() => NotificationService.SLA.getSlas(ctx.params), {});
}

export function* doUpdateSLAs(ctx: QueryContext<any, SlaApiUpdateSlaRequest>) {
  yield ctx.fetch(() => NotificationService.SLA.updateSla(ctx.params), {
    successMsg: 'Updated SLAs successfully',
  });
}

export function* doGetCloudAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsRequest
  >,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.getCloudAccounts(ctx.params),
  );
}

export function* doUpdateAccountDiscoveryInterval(
  ctx: QueryContext<any, CloudAccountApiChangeDiscoverInventoryIntervalRequest>,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.changeDiscoverInventoryInterval(
      ctx.params,
    ),
  );
}
