/**
 *
 * Side Nav
 *
 */

import React from 'react';

import { Box, Center } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { H3 } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { Card, NoDataAvailable } from '../../DataDisplay';

import { NavBar } from './NavBar';
import { useSideNavContext } from './SideNavContext';

export const NavigationComponent: React.FC = () => {
  const {
    menuItems,
    height,
    styles,
    navHeader,
    isLoading,
    minHeight = 96,
    showNavDivider = false,
    showNavHeaderDivider = false,
  } = useSideNavContext();

  const content = () =>
    isEmpty(menuItems) || menuItems.every(m => m?.hideTitle) ? (
      <NoDataAvailable />
    ) : (
      <>
        <NavBar menuItems={menuItems} />
      </>
    );

  return (
    <Card
      styles={{
        card: {
          px: 0,
          pb: 0,
          h: height ? height : 'full',
          overflow: 'auto',
          minH: minHeight,
          pos: 'relative',
          border: showNavDivider ? '1px solid' : 'none',
          borderRightColor: showNavDivider ? 'gray.200' : 'none',
          borderRadius: showNavDivider ? 'none' : 6,
          pt: navHeader ? 0 : 4,
        },
        header: {
          px: 4,
          pt: 4,
          pb: showNavHeaderDivider ? 2 : 4,
          position: 'sticky',
        },
        ...styles?.menuWrapper,
      }}
      headerComponent={
        navHeader && (
          <Box
            w="full"
            borderBottom={showNavHeaderDivider ? '1px solid' : 'none'}
            pb={showNavHeaderDivider ? 2 : 0}
            borderBottomColor={showNavHeaderDivider ? 'gray.200' : 'none'}
          >
            <H3>{navHeader}</H3>
          </Box>
        )
      }
      contentComponent={
        <Box w="full" h="full" pos="relative">
          {isLoading ? (
            <Center h="full">
              <CustomSpinner loading={isLoading} />
            </Center>
          ) : (
            content()
          )}
        </Box>
      }
    />
  );
};
