import {
  CriteriaCondition,
  EnvironmentRead,
  TagKeyValuesCondition,
} from '@ariksa/compliance-policies';
import {
  EnvironmentApiCreateEnvironmentRequest,
  EnvironmentApiDeleteEnvironmentRequest,
  EnvironmentApiGetEnvironmentsByIdRequest,
  EnvironmentApiUpdateEnvironmentRequest,
} from '@ariksa/compliance-policies/api';
import {
  BluePrintRead,
  EnvironmentApiGetAttachedBlueprintsOnEnvironmentRequest,
} from '@ariksa/compliance-policies/dist/api';
import { PayloadAction } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the CloudAccountWizard container

// @ts-ignore
export const initialState: ContainerState = {
  environment: QueryState.init({} as EnvironmentRead),
  environments: QueryState.init([]),
  attachedBlueprints: QueryState.init([]),
  apiCallStatus: QueryState.init({}),

  formData: {
    name: '',
    cloud: 'aws',
    // @ts-ignore
    account_ids: [],
    // @ts-ignore
    scope_level: 'account',
    is_business_critical: true,
    criteria: {
      criteria_condition: CriteriaCondition.All,
    },
  },
  tagKeyValueCriteria: {},
};

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    /*create environment*/
    createEnvironment(
      state,
      action: QueryAction<any, EnvironmentApiCreateEnvironmentRequest>,
    ) {
      state.apiCallStatus = QueryState.next(state.apiCallStatus, action);
    },
    /*update environment*/
    updateEnvironment(
      state,
      action: QueryAction<any, EnvironmentApiUpdateEnvironmentRequest>,
    ) {
      state.apiCallStatus = QueryState.next(state.apiCallStatus, action);
    },
    /*delete environment*/
    deleteEnvironment(
      state,
      action: QueryAction<any, EnvironmentApiDeleteEnvironmentRequest>,
    ) {
      state.apiCallStatus = QueryState.next(state.apiCallStatus, action);
    },

    /*get environment*/
    getEnvironment(
      state,
      action: QueryAction<
        EnvironmentRead,
        EnvironmentApiGetEnvironmentsByIdRequest
      >,
    ) {
      state.environment = QueryState.next(state.environment, action);
      forEach(action.payload.data, (value, field) => {
        if (field !== 'id') state.formData[field] = value;
      });
      state.tagKeyValueCriteria = {};
      forEach(action.payload.data?.criteria?.tag_key_values_condition, o => {
        const id = nanoid();
        state.tagKeyValueCriteria[id] = o;
      });
    },

    /*get environments*/
    getEnvironments(state, action: QueryAction<any>) {
      state.environments = QueryState.next(state.environments, action);
    },

    updateFormData(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      state.formData[action.payload.field] = action.payload.value;
    },
    updateCriteria(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      if (state.formData?.criteria !== undefined) {
        state.formData.criteria[action.payload.field] = action.payload.value;
      }
    },
    updateKeyValueCriteria(
      state,
      action: PayloadAction<{ id: string; value: TagKeyValuesCondition }>,
    ) {
      state.tagKeyValueCriteria[action.payload.id] = action.payload.value;
    },
    updateKeyValueCriterion(
      state,
      action: PayloadAction<Record<string, TagKeyValuesCondition>>,
    ) {
      state.tagKeyValueCriteria = action.payload;
    },

    resetCriteria(state, action: PayloadAction<string>) {
      if (state.formData.criteria) {
        state.formData.criteria[action.payload] =
          initialState.formData.criteria?.[action.payload];
      }
    },
    resetFormData(state) {
      state.formData = initialState.formData;
      state.tagKeyValueCriteria = initialState.tagKeyValueCriteria;
    },

    /*get attached blueprints for an environment*/
    getAttachedBlueprints(
      state,
      action: QueryAction<
        BluePrintRead[],
        EnvironmentApiGetAttachedBlueprintsOnEnvironmentRequest
      >,
    ) {
      state.attachedBlueprints = QueryState.next(
        state.attachedBlueprints,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = environmentSlice;
