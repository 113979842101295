import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  LastModified,
  PlatformID,
  SubnetID,
  Url,
  RoleARN,
  KMSKeyID,
  VolumeSizeInGB,
  DefaultRepository,
  RootAccess,
  MetaDataConfiguration,
} from '../../../Components/MetadataField/MetaGridFields';

export const SagemakerMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" vpc="None" />
      {PlatformID(resource)}
      {LastModified(resource)}
      {SubnetID(resource)}
      {Url(resource)}
      {RoleARN(resource)}
      {KMSKeyID(resource)}
      {VolumeSizeInGB(resource)}
      {DefaultRepository(resource)}
      {RootAccess(resource)}
      {MetaDataConfiguration(resource)}
    </Grid>
  );
};
