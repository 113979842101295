import { theme } from '@chakra-ui/react';

export const colors = {
  ...theme.colors,
  background: '#F8F7F7',
  gray: {
    0: '#ffffff',
    50: '#f7f7f9',
    100: '#dcdde0',
    200: '#bdbec2',
    250: '#85858B',
    300: '#4c4c53',
    400: '#1d1e23',
    500: '#000000',
  },
  blue: {
    50: '#eff5ff',
    100: '#d0e0ff',
    200: '#66a2ff',
    300: '#1563ff',
    400: '#0d44cc',
    500: '#08368b',
  },
  skyBlue: {
    50: '#EBF8FF',
    100: '#BFE8FE',
    200: '#6BCEFF',
    300: '#00ACFF',
    400: '#0074BA',
    500: '#005283',
  },
  purple: {
    50: '#F5F3FF',
    100: '#DDD6FA',
    200: '#A28CE8',
    300: '#623CE4',
    400: '#3C2AA8',
    500: '#2A1C73',
  },
  green: {
    50: '#eefcf7',
    100: '#caefe1',
    200: '#87d5b6',
    300: '#54b884',
    400: '#337656',
    500: '#1d4b3b',
  },
  red: {
    50: '#f8dede',
    100: '#f5cecd',
    200: '#efaead',
    300: '#e98683',
    400: '#e4615c',
    500: '#cc575b',
  },
  yellow: {
    50: '#fdf2d2',
    100: '#fcebbc',
    200: '#fade91',
    300: '#f8cd61',
    400: '#f5bd43',
    500: '#dc9d38',
  },
  indigo: {
    50: '#948FF3',
    100: '#8882F1',
    200: '#7C76F0',
    300: '#7069EE',
    400: '#645DED',
    500: '#5850ec',
    600: '#453CE9',
    700: '#3128E7',
    800: '#2319E0',
    900: '#2017CB',
  },
  primaryShades: {
    50: '#9592ef',
    100: '#857fea',
    200: '#776be6',
    300: '#6956e1',
    500: '#5d3edb',
  },
};
