import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SuperNetIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M20,16c-0.8,0-1.6,0.3-2.1,0.9l-2.2-1.3c0.2-0.5,0.3-1,0.3-1.6c0-2-1.5-3.7-3.5-3.9V7.9C13.9,
    7.7,15,6.5,15,5c0-1.7-1.3-3-3-3S9,3.3,9,5c0,1.5,1.1,2.7,2.5,2.9v2.1C9.5,10.3,8,12,8,14c0,0.6,0.1,
    1.1,0.3,1.6l-2.2,1.3C5.6,16.3,4.8,16,4,16c-1.7,0-3,1.3-3,3s1.3,3,3,3c1.7,0,3-1.3,
    3-3c0-0.5-0.1-0.9-0.3-1.3l2.2-1.3C9.6,17.4,10.7,18,12,18c1.3,0,2.4-0.6,3.1-1.6l2.2,1.3C17.1,18.1,
    17,18.5,17,19c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3C23,17.3,21.7,16,20,16z M4,21c-1.1,0-2-0.9-2-2s0.9-2
    ,2-2c1.1,0,2,0.9,2,2C6,20.1,5.1,21,4,21z M10,5c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2S10,
    6.1,10,5z M12,17c-1.7,0-3-1.3-3-3s1.3-3,3-3c1.7,0,3,1.3,3,3C15,15.7,13.7,17,12,17z M20,21c-1.1,
    0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2C22,20.1,21.1,21,20,21z"
    />
  </Icon>
);
