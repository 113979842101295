import React from 'react';

import { Center, HStack } from '@chakra-ui/react';

import { SideNavMenuItemProps } from 'app/components/Navigation/SideNav';
import { JitAccounts } from 'app/containers/Setup/Jit/Components/Accounts';
import { H3 } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';

import { OrganizationConfig } from './Components/OrganizationConfig';

export const menuItems: SideNavMenuItemProps[] = [
  // { title: 'Accounts', key: 'accounts', component: <AccountSettings /> },
  // {
  //   title: 'Notifications',
  //   key: 'notifications',
  //   component: <NotificationsSettings />,
  // },
  {
    title: 'Organization Settings',
    key: 'organization',
    contentHeader: (
      <HStack>
        <Center>
          <AriksaIcon type={IconTypes.Configuration} size={'medium'} />
        </Center>
        <H3>Organization Settings</H3>
      </HStack>
    ),
    component: <OrganizationConfig />,
  },
];
