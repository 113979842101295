import { FlexProps } from '@chakra-ui/react';

export interface PageInfo {
  page_number: number;
  page_size: number;
}

export interface PaginationStyles {
  root?: FlexProps;
}

export interface IPagination {
  pageInfo: PageInfo;
  totalCount: number;
  pageSizes?: number[];

  styles?: FlexProps;
  text?: string;

  isLoading?: boolean;

  onChange(info: PageInfo): void;
}

export const DefaultPageSizes = [10, 20, 40, 80, 100];
