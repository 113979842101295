/**
 *
 * Modal
 *
 */
import React, { memo } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Heading,
} from '@chakra-ui/react';

import { modalCloseButtonIconStyles } from '../../DataEntry/Button/styles';

import { IModalProps } from './types';

export const CustomModal = memo((props: IModalProps) => {
  const {
    styles,
    body,
    header,
    isOpen,
    footer,
    onClose,
    closeButton = true,
    ...rest
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
      scrollBehavior="inside"
      size="sm"
      {...styles?.modal}
      {...rest}
    >
      <ModalOverlay {...styles?.overlay} />
      <ModalContent {...styles?.content}>
        {header && (
          <ModalHeader
            borderBottom={'1px solid'}
            borderColor={'gray.100'}
            {...styles?.header}
          >
            <Heading fontSize="md">{header}</Heading>
          </ModalHeader>
        )}
        {closeButton && <ModalCloseButton {...modalCloseButtonIconStyles} />}
        <ModalBody pt={4} pb={6} w="full" h="full" {...styles?.body}>
          {body}
        </ModalBody>
        {footer && <ModalFooter {...styles?.footer}>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
});
