import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CloudAccountInfoGetResponse } from '@ariksa/jit/api';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Square,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { Optional } from 'types/utils';

import {
  Card,
  PageHeaderWithIcon,
  renderTimeStackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { FieldValue } from 'components/DataDisplay/Text/FieldValueText';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { AddButton } from 'components/DataEntry';
import {
  AccountIcon,
  EditIcon,
  IdeaGlitterIcon,
  RoleIcon,
  TrashIcon,
} from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { PageInfo } from 'components/Navigation';
import { TableActionsMenu } from 'components/Overlay';
import { selectApp } from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { JitApproverModal } from 'containers/Setup/Jit/Components/JitApproverModal';
import JitRoleConfig from 'containers/Setup/Jit/Components/JitRoleConfig';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';
import { formatDate } from 'utils/date';

export const JitAccounts = () => {
  const dispatch = useDispatch();
  const jitAddApprover = useDisclosure();
  const jitRoleConfig = useDisclosure();
  const { jitAccounts } = useSelector(selectJit);
  const [activeAccount, setActiveAccount] = useState<
    Optional<CloudAccountInfoGetResponse>
  >(null);
  const app = useSelector(selectApp);
  const { environments } = app;
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (jitRoleConfig.isOpen && activeAccount) {
      dispatch(actions.getRoles({ q: { accountId: activeAccount.uuid } }));
    }
  }, [dispatch, activeAccount, jitRoleConfig.isOpen]);

  const renderAccountEnvironments = useCallback(
    accountId => {
      const envs = environments.data.filter(e => {
        return Array.from(e.account_ids).includes(accountId);
      });

      return (
        <Center>
          <CustomTooltip
            label={
              <Stack>
                {envs.map(e => (
                  <EnvironmentName environmentId={e.id} />
                ))}
              </Stack>
            }
          >
            {envs.length
              ? envs
                  .slice(0, 2)
                  .map(e => <EnvironmentName environmentId={e.id} />)
              : '-'}
          </CustomTooltip>
        </Center>
      );
    },
    [environments.data],
  );

  const getJitAccounts = useCallback(
    (info: PageInfo = INIT_PAGE_INFO) => {
      dispatch(
        actions.getJitAccounts({
          q: {},
          page: info,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    getJitAccounts();
  }, [getJitAccounts]);

  const handleDelete = useCallback(
    (row: CloudAccountInfoGetResponse) => {
      dispatch(
        actions.deleteJitAccount({
          q: {
            accountId: row.uuid ?? '',
          },
          onSuccess: () => {
            getJitAccounts();
          },
        }),
      );
    },
    [dispatch, getJitAccounts],
  );

  const handleEdit = useCallback(
    account => {
      jitAddApprover.onOpen();
      setActiveAccount(account);
    },
    [jitAddApprover],
  );

  const columns: TableColumnProps<CloudAccountInfoGetResponse>[] = useMemo(
    () => [
      {
        header: <Box pl={10}>Account</Box>,
        render: ({ row }) => (
          <WithResourceIcon
            resourceType={row.cloud_type.toLowerCase()}
            iconFilled={false}
            iconSize="regular"
          >
            <Stack spacing={1}>
              <CloudAccountName accountId={row.uuid} />
              <Text>{row.cloud_account_id}</Text>
            </Stack>
          </WithResourceIcon>
        ),
      },
      {
        header: 'Environment',
        accessor: 'environment',
        render: ({ row }) => {
          return renderAccountEnvironments(row.uuid);
        },
      },
      {
        header: 'Approver(s)',
        align: 'left',
        render: ({ row }) => {
          return (
            <Flex w={'full'} gap={1}>
              {row.approver_details?.map((email, index) => {
                return (
                  <Text>
                    {email}
                    {index < (row.approver_details?.length ?? 1) - 1 ? ',' : ''}
                  </Text>
                );
              })}
            </Flex>
          );
        },
      },
      {
        header: '# Of Approvers',
        render: ({ row }) => row.approver_details?.length ?? 0,
      },
      {
        header: 'Created',
        render: ({ row }) => {
          return renderTimeStackedCell({ value: row.created_date });
        },
      },
      {
        header: 'Last Update',
        render: ({ row }) => {
          return renderTimeStackedCell({ value: row.last_updated });
        },
      },
      {
        header: 'Actions',
        align: 'right',
        render: ({ row }) => (
          <Box onClick={e => e.stopPropagation()}>
            <TableActionsMenu
              menuItems={[
                {
                  label: 'Edit',
                  icon: <EditIcon />,
                  onClick: () => handleEdit(row),
                },
                {
                  label: 'Update Roles',
                  icon: <RoleIcon />,
                  onClick: () => {
                    setActiveAccount(row);
                    jitRoleConfig.onOpen();
                  },
                },
                {
                  label: 'Delete Account',
                  icon: <TrashIcon />,
                  onClick: () => {
                    handleDelete(row);
                  },
                },
              ]}
            />
          </Box>
        ),
      },
    ],
    [handleDelete, handleEdit, jitRoleConfig, renderAccountEnvironments],
  );

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack spacing={5} h="full" w="full">
        <HStack justify="space-between">
          <PageHeaderWithIcon label="JIT Accounts" icon={<AccountIcon />} />
          <AddButton
            label="Enable JIT"
            onClick={() => navigate('/setup/jit/add')}
          />
        </HStack>
        <Box flex={1}>
          <Table
            columns={columns}
            data={jitAccounts.data ?? []}
            isLoading={jitAccounts.isLoading}
            pagination={{
              totalCount: jitAccounts.page.totalCount,
              pageInfo: jitAccounts.page.info,
              onChange(info: PageInfo) {
                getJitAccounts(info);
              },
            }}
            onRowClick={row => {
              setActiveAccount(row);
              // metadataDrawer.onOpen();
            }}
            subComponent={({ row }) => {
              return (
                <Stack w={'full'} h={'full'}>
                  <Stack w={'180px'}>
                    <Text>ADDITIONAL DETAILS</Text>
                    <Divider left={-4} pos={'relative'} />
                  </Stack>
                  <Stack spacing={4}>
                    <FieldValue
                      field={'JIT enabled at'}
                      value={formatDate(row.created_date)}
                    />
                    <FieldValue
                      field={'Approvers'}
                      value={row.approver_details.join(', ')}
                    />
                    <FieldValue
                      field={'No. of roles enabled for JIT'}
                      value={row.jit_roles?.length ?? 0}
                    />
                    <FieldValue
                      field={'Roles enabled for JIT'}
                      value={
                        <Stack align={'start'}>
                          {row.jit_roles?.map(role => (
                            <Text>{role}</Text>
                          ))}
                        </Stack>
                      }
                      align={'start'}
                    />
                  </Stack>
                </Stack>
              );
            }}
            noDataMessage={
              <Center h={'160px'} color={'primary'}>
                <HStack spacing={6}>
                  <Square size={'30px'}>
                    <IdeaGlitterIcon />
                  </Square>
                  <Stack spacing={0} fontSize={'md'}>
                    <Text>Enable JIT access for one or more accounts </Text>
                    <Text>by clicking on the button above this table</Text>
                  </Stack>
                </HStack>
              </Center>
            }
          />
        </Box>
        {/*{jitAddAccount.isOpen && (
        <JitAddAccountModal
          isOpen={jitAddAccount.isOpen}
          onClose={jitAddAccount.onClose}
        />
      )}*/}
        {jitAddApprover.isOpen && activeAccount && (
          <JitApproverModal
            account={activeAccount}
            isOpen={jitAddApprover.isOpen}
            onClose={jitAddApprover.onClose}
          />
        )}
        {jitRoleConfig.isOpen && activeAccount && (
          <JitRoleConfig
            account={activeAccount}
            isOpen={jitRoleConfig.isOpen}
            onClose={jitRoleConfig.onClose}
          />
        )}
      </Stack>
    </Card>
  );
};
