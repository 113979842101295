import React, { FC, useEffect, useState } from 'react';

import { AlertResponse } from '@ariksa/notification/api';
import { Box, Stack, HStack, Textarea } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  DetailsIdentifier,
  renderRiskContext,
  renderSeverityBar,
  StackedHeader,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, PrimaryButton, Select } from 'components/DataEntry';
import { defaultStyles } from 'components/DataEntry/Form/styles';
import { IconTypes } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import {
  getSnoozedTime,
  snoozeDurationOptions,
} from 'containers/Findings/Alerts/Components/utils';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';

interface ISnoozeAlert {
  alert: AlertResponse;
  onClose();
  isOpen: boolean;
  type?: 'snooze' | 'unsnooze';
  onSuccess();
}

export const SnoozeAlert: FC<ISnoozeAlert> = props => {
  const { alert, isOpen, onClose, type = 'snooze', onSuccess } = props;
  const { environmentId } = useSelector(selectActiveEnvironment);
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(snoozeDurationOptions[0]);
  const { alertAction, individualRiskContext } = useSelector(selectAlerts);
  const [comments, setComments] = useState('');

  useEffect(() => {
    dispatch(
      actions.getIndividualRiskContext({
        q: {
          riskContextRequest: { uuids: [alert.entity_uuid!] },
        },
      }),
    );
  }, [dispatch, alert]);

  useEffect(() => {
    //setComments(alert?.snooze_comment);
  }, [alert]);

  const handleSubmit = () => {
    if (type === 'snooze') {
      dispatch(
        actions.snoozeAlert({
          q: {
            snoozeRequest: {
              snooze_duration: getSnoozedTime(duration),
              alert_id: alert.uuid!,
              status: true,
              snooze_comment: comments,
            },
          },
          onSuccess: () => {
            onSuccess();
            onClose();
          },
        }),
      );
    } else {
      dispatch(
        actions.stopSnooze({
          q: { alertId: alert.uuid! },
          onSuccess: () => {
            onSuccess();
            onClose();
          },
        }),
      );
    }
  };

  return (
    <Box>
      <Modal
        styles={{ modal: { size: '2xl' } }}
        isOpen={isOpen}
        onClose={onClose}
        header={
          <StackedHeader
            upper={
              type === 'snooze' ? 'Snooze Alert' : 'Remove Snooze for Alert'
            }
            lower={(alert.entity_name ?? '') + '( ' + alert.entity_id + ')'}
            type={type === 'snooze' ? IconTypes.Snooze : IconTypes.Alert}
          />
        }
        body={
          <Stack spacing={6} h="full" overflow="scroll">
            <DetailsIdentifier label={'Description'} value={alert.message} />
            <HStack align="flex-start">
              <Stack spacing={6} w="60%">
                <DetailsIdentifier
                  label={'Policy blueprint'}
                  value={
                    <Stack spacing={1}>
                      {map(alert?.blueprints, o => (
                        <Box>{o.blueprint_name}</Box>
                      ))}
                    </Stack>
                  }
                />
                <DetailsIdentifier
                  label={'Environment'}
                  value={<EnvironmentName environmentId={environmentId} />}
                />
                <DetailsIdentifier
                  label={'Severity'}
                  value={renderSeverityBar({ value: alert?.severity })}
                />
              </Stack>
              <Stack w="40%">
                <DetailsIdentifier
                  label={'Risk Context'}
                  value={
                    individualRiskContext.isLoading ? (
                      <CustomSpinner justifyContent="left" />
                    ) : (
                      renderRiskContext(
                        individualRiskContext.data[alert.entity_uuid!],
                      )
                    )
                  }
                  direction="column"
                />
              </Stack>
            </HStack>
            {type === 'snooze' && (
              <DetailsIdentifier
                label="Duration"
                h="full"
                value={
                  <Stack h="full">
                    <Box w={48}>
                      <Select
                        options={snoozeDurationOptions}
                        value={duration}
                        onChange={setDuration}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </Box>
                    <Box color="gray.200">
                      Will be snoozed until:{' '}
                      {dayjs()
                        .add(getSnoozedTime(duration), 'minutes')
                        .format('h:mm A, DD-MMM-YYYY')}
                    </Box>
                  </Stack>
                }
              />
            )}
            <DetailsIdentifier
              label="Comments"
              value={
                <Textarea
                  {...defaultStyles.textAreaField?.textArea}
                  value={comments}
                  onChange={e => setComments(e.target.value)}
                  placeholder="Reason for snoozing"
                  isDisabled={type === 'unsnooze'}
                />
              }
            />
            <HStack justify="space-between" pt={4}>
              <CancelButton onClick={() => onClose()} />
              <PrimaryButton
                onClick={() => handleSubmit()}
                isLoading={alertAction.isLoading}
                isDisabled={alertAction.isLoading}
              >
                Ok
              </PrimaryButton>
            </HStack>
          </Stack>
        }
      />
    </Box>
  );
};
