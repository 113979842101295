import React, { FC } from 'react';

import { EntityType } from '@ariksa/inventory-core/api';
import { Accordion } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceActionsPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActionsPatternAccordion';
import { ResourceAccessPattern } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ResourceAccessPattern';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const IAMRoleActivity: FC = () => {
  const { accessed } = useSelector(selectActiveResource);

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <ResourceActionsPatternAccordion />
      <MetaAccordionItem
        iconType={IconTypes.DataScan}
        label={'ACTIONS PERFORMED BY THIS IDENTITY DURING LAST 30 DAYS'}
        onClickDownload={() => {}}
        panel={<ResourceAccessPattern resourceType={EntityType.Role} />}
        iconBGColor="primary"
        height={'250px'}
        isLoading={accessed.isLoading}
      />
    </Accordion>
  );
};
