import { sortBy } from 'lodash';

import { CommonLayout } from 'components/Visualization/PixiGraph/common/Layout';
import { RECT_NODE_WIDTH } from 'components/Visualization/PixiGraph/style';

import { AGraphLayout } from '../../../core/Layout';

import { AccessMap } from './AccessMap';
import {
  AccessMapHeaderNode,
  AccessMapNode,
  AccessMapPaginationNode,
} from './Node';

export class AccessMapLayout extends AGraphLayout<AccessMap> {
  horizontalSpacing = 76;
  verticalSpacing = 40;
  resourceNodeVerticalSpacing = 60;
  padding = 20;

  layoutNodes(graph: AccessMap) {
    const { headers, nodes, pagination } = graph;
    const {
      verticalSpacing: vs,
      horizontalSpacing: hs,
      resourceNodeVerticalSpacing,
    } = this;
    const rvs = graph.findings
      ? resourceNodeVerticalSpacing + 30
      : resourceNodeVerticalSpacing;
    const containers: Map<string, AccessMapNode[]> = new Map();
    const paginationNodeMap: Map<string, AccessMapPaginationNode> = new Map();
    const reverseEdgeMap = graph.edgeMap.inverse();
    const nodeMap = graph.nodeIdMap;

    headers.forEach(n => {
      containers.set(n.headerName, [n]);
    });

    nodes.forEach(n => {
      containers.get(n.headerName)?.push(n);
    });
    pagination.forEach(n => {
      n.visible = false;
      paginationNodeMap.set(n.headerName, n);
    });

    const columns = new Map<number, AccessMapHeaderNode[]>();
    headers.forEach(h => {
      const entries = columns.get(h.col) ?? [];
      entries.push(h);
      columns.set(h.col, entries);
    });

    columns.forEach((headers, colIndex, header) => {
      const sortedHeaders = sortBy(headers, n => n.row);
      let row = 0;
      sortedHeaders.forEach((header, headerRowIndex) => {
        const nodes = containers.get(header.headerName);
        nodes?.forEach(n => {
          n.visible = false;
        });

        const pagination = paginationNodeMap.get(header.headerName);
        const visibleNodes = nodes?.slice(0, pagination?.visibleCount || 10);
        visibleNodes
          ?.filter(n => {
            const prevNodes = reverseEdgeMap.get(n.id);
            if (!prevNodes.length) {
              return true;
            }

            return prevNodes.some(nodeId => {
              return nodeMap.get(nodeId)?.visible;
            });
          })
          ?.forEach((node, nodeIndex) => {
            const isResource = node.w === node.h;
            node.visible = true;

            node.x =
              colIndex * (RECT_NODE_WIDTH + hs) +
              (isResource ? (RECT_NODE_WIDTH - node.w) / 2 : 0);
            node.y = row; //(node.h + vs); // + (node.isHeader ? 40 : 0);
            row += node.h + (isResource ? rvs : vs);
          });

        // if all nodes within a header are not visible, then show the pagination
        if (pagination) {
          // take the 2nd node as the first node is the header node
          const node = visibleNodes?.[1];
          if (!node || !node.visible) {
            return;
          }
          const isResource = node?.w === node?.h;
          if (pagination.visibleCount < pagination.totalCount) {
            // console.log('show pagination', row, pagination.header);
            pagination.visible = true;
            pagination.x =
              colIndex * (RECT_NODE_WIDTH + hs) +
              (isResource ? (RECT_NODE_WIDTH - node.w) / 2 : 0);
            pagination.y = row; //* (node.h + vs);
            row += node.h + (isResource ? rvs : vs);
          }
        }
      });
    });
  }

  layoutEdges(graph: AccessMap): void {
    CommonLayout.layoutEdges(graph);
  }
}
