import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GraphIncreaseIcon = props => (
  <Icon viewBox="0 0 512 512" color="inherit" h="full" w="full" {...props}>
    <polyline
      points="352 144 464 144 464 256"
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth="32px"
    />
    <polyline
      points="48 368 192 224 288 320 448 160"
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeMiterlimit={10}
      strokeWidth="32px"
    />
  </Icon>
);
