import React, { FC, useMemo } from 'react';

import { Box, Flex, HStack, Stack } from '@chakra-ui/react';

import { StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Modal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';

interface ITrafficConfigModal {
  data: any;
  onClose: () => void;
  isOpen: boolean;
}

export const TrafficConfigModal: FC<ITrafficConfigModal> = props => {
  const { data, onClose, isOpen } = props;

  const { getCloudNativeName } = useResourceType();
  console.log(data);

  const columns = useMemo(() => {
    return [
      {
        header: 'source',
      },
      {
        header: 'destination',
      },
      {
        header: 'port',
        accessor: 'port',
      },
      {
        header: 'Config',
        align: 'config',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    return [];
  }, []);

  console.log(tableData);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          type={data.resourceType ?? ''}
          upper={
            <HStack>
              <Box>
                {data?.parent?.name ?? data?.parent?.resource_id ?? '-'}
              </Box>
              <Flex>({getCloudNativeName(data.resourceType ?? '-')})</Flex>
            </HStack>
          }
          lower={
            data.name === 'Ingress'
              ? 'Ingress Configuration'
              : 'Egress Configuration'
          }
        />
      }
      body={
        <Stack minH={'400px'}>
          <Table columns={columns} data={tableData} />
        </Stack>
      }
      size={'3xl'}
    />
  );
};
