import React, { FC, useEffect, useState } from 'react';

import { AlertHistoryBy } from '@ariksa/notification';
import { Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { usePageContentContext } from 'components/Layout';
import { Tour } from 'components/Tour';
import { userDashboard } from 'components/Tour/Steps/dashboard';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { AlertsContextProvider } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertsContext';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { AlertTables } from './Components/AlertTables/Components/AlertTables';
import { actions } from './slice';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

export const FindingsOverview: FC = () => {
  const dispatch = useDispatch();
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { withAccessBoundary } = useAccessBoundary();
  const { currentCategory } = useSelector(selectFindingsOverview);
  const { contentHeight } = usePageContentContext();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const [refresh, setRefresh] = useState(false);
  const [
    individualAlertDrawerTabKey,
    setIndividualAlertDrawerTabKey,
  ] = useState('');
  const toggleRefresh = () => setRefresh(!refresh);

  //load alert categories
  useEffect(() => {
    if (!!environmentId || !!accountId)
      dispatch(
        actions.loadAlertCategories({
          q: withAccessBoundary({}),
        }),
      );
  }, [dispatch, withAccessBoundary, accountId, environmentId]);

  useEffect(() => {
    if (!!environmentId || !!accountId) {
      const categoryClass =
        currentCategory === 'AllFindings'
          ? undefined
          : (currentCategory as string);
      const categoryParams =
        currentCategory === 'Overview'
          ? {}
          : {
              categoryClass,
            };
      const endDate = aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD');

      const startDate = aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(6, 'day').format('YYYY-MM-DD');

      dispatch(
        actions.loadAlertHistoryByTrend({
          q: withAccessBoundary({
            startDate,
            endDate,
            historyBy: AlertHistoryBy.Trend,
            ...categoryParams,
          }),
        }),
      );
      dispatch(
        actions.loadAlertHistoryByStatus({
          q: withAccessBoundary({
            startDate,
            endDate,
            historyBy: AlertHistoryBy.Status,
            ...categoryParams,
          }),
        }),
      );
    }
  }, [
    dispatch,
    withAccessBoundary,
    accountId,
    environmentId,
    currentCategory,
    aiParamExtraction.data,
    //alertFilters.alertCategory,
  ]);

  //get alert resource types
  useEffect(() => {
    dispatch(sharedStateActions.getAlertResourceTypes({ q: {} }));
  }, [dispatch]);

  return (
    <>
      <Tour steps={userDashboard} fieldName="userDashboardTour">
        <Stack spacing={0} key={'1'} h={contentHeight + 'px'}>
          <AlertsContextProvider
            value={{
              refresh,
              toggleRefresh,
              individualAlertDrawerTabKey: individualAlertDrawerTabKey,
              setIndividualAlertDrawerTabKey: setIndividualAlertDrawerTabKey,
            }}
          >
            <AlertTables />
          </AlertsContextProvider>
        </Stack>
      </Tour>
    </>
  );
};
