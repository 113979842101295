import { SearchQueryMapping } from 'containers/Dashboard/types';

export const getSearchQuery = (query, variables: any[]) => {
  switch (query) {
    case SearchQueryMapping.Show_resource_type_with_internet_accessible:
      return `Show ${variables[0]} WITH internet_accessible = True`;
    case SearchQueryMapping.Show_vm_with_open_ports_and_protocol_tcp_udp_and_cidr:
      return `SHOW VirtualMachine WITH open_ports = ${variables[0]} AND protocol = tcp,udp AND cidr = any`;
    case SearchQueryMapping.Show_resource_type_with_high_privileges_and_resource_type_that_can_access_any:
      return `SHOW ${variables[0]} WITH high_privileges = true AND ${variables[1]} THAT can access ANY`;
    case SearchQueryMapping.Show_resource_type_with_excess_permissions:
      return `Show ${variables[0]} THAT has Excess Permissions To ANY`;
    case SearchQueryMapping.Show_resource_type_with_encryption_false:
      return `Show ${variables[0]} WITH encrypted = false`;
    case SearchQueryMapping.Show_resource_type_with_access_to_sensitive_data:
      return `SHOW ${variables[0]} THAT Can Access Data AND Data WITH sensitive_data.exist = true`;
  }
};
