import React from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';

export const includeExcludeOptions = [
  { label: 'include', value: 'include' },
  { label: 'exclude', value: 'exclude' },
];

export const getFieldLabel = type => {
  switch (type) {
    case NativeResources.SimpleStorageService:
      return 'Choose Simple Storage Service (S3) buckets';
    case NativeResources.RedshiftCluster:
      return 'Choose Redshift Clusters';
    default:
      return 'Choose databases';
  }
};

export const renderComponentHeader = () => (
  <PageHeaderWithIcon
    label="Add Data Sources for Classification"
    iconType={IconTypes.DataScan}
    reversed
    iconBgColor="green"
  />
);

export const getChooseDataSourceLabel = accountType => {
  switch (accountType) {
    case CloudProviders.Snowflake:
      return 'data tables';
    case SupportedServices.GitLabBranch:
    case SupportedServices.GitHubBranch:
    case SupportedServices.BitBucketBranch:
      return 'branches';
    default:
      return 'data sources';
  }
};
