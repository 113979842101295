/**
 *
 * Data Classification Form
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { forEach, includes, isEmpty, map } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Select, Tag } from 'components/index';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';

import { getChooseDataSourceLabel, includeExcludeOptions } from './utils';

interface Props {
  dataSourceType: Record<string, any>;
  selectedDatabases?: Record<string, any>[];
  repoName?: string;
}

export const SelectDataSources = (props: Props) => {
  const { dataSourceType, selectedDatabases, repoName } = props;

  const [selectedDataSources, setSelectedDataSources] = useState<
    Record<string, any>[]
  >([]);
  const [dataSourceOptions, setDataSourceOptions] = useState<
    Record<string, any>[]
  >([]);
  const [includeDataSource, setIncludeDataSource] = useState(true);
  const dispatch = useDispatch();
  const { dataSources, service, accountType } = useSelector(
    selectDataClassification,
  );
  const { currentRecord, actionType } = useDataConfigurationContext();

  useEffect(() => {
    if (!!dataSourceType?.value)
      dispatch(
        actions.updateServiceFields({
          field: 'service',
          value: dataSourceType?.value,
        }),
      );
  }, [dataSourceType, dispatch]);

  const quantifierOptions = useMemo(
    () => [
      { label: 'all', value: 'all', isDisabled: !includeDataSource },
      { label: 'some', value: 'some' },
    ],
    [includeDataSource],
  );

  const [quantifier, setQuantifier] = useState<Record<string, any>>(
    quantifierOptions[0],
  );

  //set data sources options
  useEffect(() => {
    const d = dataSources.data;

    let allOption: Record<string, any>[] = [];
    if (!isEmpty(d)) allOption = [{ label: 'All', value: 'all' }];
    if (accountType === CloudProviders.Snowflake) {
      const options: Record<string, any>[] = [];
      forEach(selectedDatabases, o => {
        forEach(o?.data?.tables, t =>
          options.push({ label: t?.name, value: t?.name, data: t }),
        );
      });
      setDataSourceOptions([...allOption, ...options]);
    } else if (accountType === CloudProviders.Aws) {
      setDataSourceOptions([
        ...allOption,
        ...map(d, j => ({
          label: j?.name + ' (' + j?.resource_id + ')',
          value:
            dataSourceType?.value === NativeResources.DynamoDb
              ? j?.name || j?.resource_id
              : j?.resource_id,
          data: j,
        })),
      ]);
    } else {
      setDataSourceOptions([
        ...allOption,
        ...map(d, o => ({
          label: o,
          value: repoName + ':' + o,
        })),
      ]);
    }
  }, [
    dataSourceType,
    dataSources.data,
    selectedDatabases,
    accountType,
    repoName,
  ]);

  //set selected data sources
  useEffect(() => {
    const d =
      accountType === CloudProviders.Snowflake
        ? service?.data_objects
        : service?.data_sources;
    setSelectedDataSources(
      filter(dataSourceOptions, o => includes(d, o?.value)),
    );
  }, [accountType, service, dataSourceOptions]);

  //set quantifier option (some or any)
  useEffect(() => {
    //const service =
    let d = currentRecord?.data_classifications?.[0]?.data_sources;
    if (accountType === CloudProviders.Snowflake)
      d = currentRecord?.data_classifications?.[0]?.data_objects;
    else if (
      accountType === SupportedServices.GitLabBranch ||
      accountType === SupportedServices.GitHubBranch ||
      accountType === SupportedServices.BitBucketBranch
    ) {
      if (d?.length === 1 && !d?.[0]?.includes(':')) d = [];
    }

    if (!isEmpty(d) && actionType === 'Update')
      setQuantifier(quantifierOptions[1]);
  }, [quantifierOptions, currentRecord, accountType, actionType]);

  useEffect(() => {
    if (!includeDataSource) setQuantifier(quantifierOptions[1]);
  }, [includeDataSource, quantifierOptions]);

  const updateServiceFields = useCallback(
    (field, value) => {
      dispatch(
        actions.updateServiceFields({
          field,
          value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Stack
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      p={4}
      borderRadius={6}
      spacing={6}
    >
      <HStack>
        <Box>I'd like to</Box>
        <Select
          options={includeExcludeOptions}
          value={
            includeDataSource
              ? includeExcludeOptions[0]
              : includeExcludeOptions[1]
          }
          onChange={selected => {
            setIncludeDataSource(selected.value === 'include');
            updateServiceFields('category', selected.value);
          }}
        />
        <Select
          options={quantifierOptions}
          value={quantifier}
          onChange={selected => {
            if (selected.value === 'all') {
              updateServiceFields('data_sources', []);
              setSelectedDataSources([]);
            }
            updateServiceFields('quantifier', selected.value);
            setQuantifier(selected);
          }}
          styles={{
            menu: provided => ({
              ...provided,
              width: 'max-content',
              minWidth: '100%',
            }),
          }}
        />
        <Box>
          {getChooseDataSourceLabel(accountType)} for automatic data
          classification
        </Box>
      </HStack>
      {quantifier.value === 'some' && (
        <Stack>
          <Box>
            Choose {getChooseDataSourceLabel(accountType)} to{' '}
            {includeDataSource ? 'include' : 'exclude'} for data classification
          </Box>
          <Select
            options={dataSourceOptions}
            isMulti
            showTotalSelected
            value={selectedDataSources}
            onChange={selected => {
              setSelectedDataSources(selected);
              updateServiceFields(
                dataSourceType?.value === NativeResources.SnowflakeDatabases
                  ? 'data_objects'
                  : 'data_sources',
                map(selected, o => o?.value),
              );
            }}
          />

          <Wrap w="full">
            {map(selectedDataSources, d => (
              <WrapItem>
                <Tag
                  label={d?.label}
                  styles={{ label: { whiteSpace: 'break-spaces' } }}
                  closeButton
                  onClose={() => {
                    const sources = filter(
                      selectedDataSources,
                      r => r.value !== d.value,
                    );
                    setSelectedDataSources(sources);
                    updateServiceFields(
                      dataSourceType?.value ===
                        NativeResources.SnowflakeDatabases
                        ? 'data_objects'
                        : 'data_sources',
                      map(sources, o => o?.value),
                    );
                  }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </Stack>
      )}
    </Stack>
  );
};
