import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { AlertHistoryResponse } from '@ariksa/notification/api';
import { map } from 'lodash';
import round from 'lodash/round';
import { customTheme } from 'theme';

import { ExclamationTriangleIcon } from 'components/Icons';
import { CardWithLineChart } from 'containers/Dashboard/utils/CardWithLineChart';
import { formatDate } from 'utils/date';

interface Props {
  isLoadingChart?: boolean;
  placeholder?: string;
  history: AlertHistoryResponse;
  legendW?: number;
  showLegends?: boolean;
  label?: ReactNode;
}

export const FindingsWithLineChart: React.FC<Props> = props => {
  const {
    isLoadingChart,
    placeholder,
    history,
    legendW,
    showLegends = true,
    label = 'Critical and high severity findings and alerts - Last 30 days',
  } = props;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [change, setChange] = useState(0);

  useEffect(() => {
    let data = map(history?.alert_history, (o, date) => ({
      Date: formatDate(date, 'D-MMM'),
      Critical: o.CRITICAL,
      High: o.HIGH,
    }));
    const first = (data?.[0]?.Critical ?? 0) + (data?.[0]?.High ?? 0);
    const last =
      (data?.[data?.length - 1]?.Critical ?? 0) +
      (data?.[data?.length - 1]?.High ?? 0);
    setChange(
      first === 0 && last > 0
        ? 100
        : first === 0
        ? 0
        : round(((last - first) / first) * 100, 0),
    );
    setChartData(data);
  }, [history]);

  const legends = useMemo(
    () => [
      {
        label: 'Critical',
        color: 'critical',
        labelSpacing: 2,
      },
      {
        label: 'High',
        color: 'high',
        labelSpacing: 2,
      },
    ],
    [],
  );

  return (
    <CardWithLineChart
      chartData={chartData}
      placeholder={placeholder}
      lineChartColor={{
        Critical: customTheme.colors.critical,
        High: customTheme.colors.high,
      }}
      yFields={['Critical', 'High']}
      legends={showLegends ? legends : undefined}
      label={label}
      showLegendAtTop
      icon={<ExclamationTriangleIcon />}
      iconBgColor="critical"
      isLoadingChart={isLoadingChart}
      legendW={legendW}
      change={change}
      legendsSpacing={10}
    />
  );
};
