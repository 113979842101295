import React from 'react';

import { SummaryType } from '@ariksa/inventory-core';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { SummaryByResource } from 'containers/Inventory/CloudInventory/Components/Summary/SummaryByResource';

import { widgetStyles } from '../styles';

export const availableWidgets: Record<string, WidgetProps> = {
  byCloudResources: {
    name: 'By Resources On Cloud',
    styles: { ...widgetStyles },
    components: {
      content: <SummaryByResource summaryType={SummaryType.NativeResource} />,
    },
  },
  cloudResourcesByRegion: {
    name: 'By Region',
    styles: { ...widgetStyles },
    components: {
      content: <SummaryByResource summaryType={SummaryType.Region} />,
    },
  },
  byIdentitiesOnCloud: {
    name: 'By Identities on Cloud',
    styles: { ...widgetStyles },
    components: {
      content: <SummaryByResource summaryType={SummaryType.Identity} />,
    },
  },
  byCloudType: {
    name: 'By Cloud Type',
    styles: { ...widgetStyles },
    components: {
      content: <SummaryByResource summaryType={SummaryType.CloudProvider} />,
    },
  },
};
