import React from 'react';

import { Icon } from '@chakra-ui/react';

export const HighPrivilegesIcon = props => (
  <Icon viewBox="0 0 512 512" color="inherit" h="full" w="full" {...props}>
    <g>
      <g>
        <polygon points="256,168.8 150.5,225.1 51.2,168.8 94.8,371.3 417.2,371.3 460.8,168.8 361.5,225.1 		" />
      </g>
      <g>
        <rect x="98.5" y="405.1" width="315.1" height="61.9" />
      </g>
      <g>
        <circle cx="45" cy="90" r="45" />
      </g>
      <g>
        <circle cx="467" cy="90" r="45" />
      </g>
      <g>
        <circle cx="256" cy="90" r="45" />
      </g>
    </g>
  </Icon>
);
