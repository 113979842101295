import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import {  useSelector } from 'react-redux';
import { customTheme } from 'theme';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

import { selectCompliance } from '../../selectors';

export const ViolationsByAccounts: FC = () => {
  const { violationsByAccount, blueprints } = useSelector(selectCompliance);
  const [data, setData] = useState<any>();
  const { toCloudAccountName } = useCloudAccountId();

  useEffect(() => {
    if (violationsByAccount.data) {
      setData(
        map(violationsByAccount.data, o => ({
          name: toCloudAccountName(o.account_id),
          value: o.failed,
        })),
      );
    }
  }, [violationsByAccount.data, toCloudAccountName]);

  return (
    <Box w="full" h="full">
      <PieChart
        isLoading={violationsByAccount.isLoading || blueprints.isLoading}
        data={data}
        dataKey="value"
        total={formatNumber(
          data?.reduce((s, el) => s + el?.value || 0, 0),
          1,
        )}
        activeShape={false}
        outerRadius="85%"
        innerRadius="76%"
        styles={{
          pie: { cx: isEmpty(data) ? '50%' : '35%' },
          legend: {
            /*onClick: payload => {
              dispatch(actions.changeSelectedTab(1));
              dispatch(
                actions.changeResourceTypeSeverity(
                  payload?.value?.toLowerCase(),
                ),
              );
            },*/
            wrapperStyle: {
              minWidth: 145,
              maxWidth: 200,
              fontSize: 'sm',
            },
          },
        }}
      />
    </Box>
  );
};
