import React, { FC } from 'react';

import { Circle, HStack, Text } from '@chakra-ui/react';

import { alertStatusColors } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/utils';
import { toPascalCase } from 'utils/string';

interface IAlertStatus {
  status: string;
  count?: number;
}

export const AlertStatus: FC<IAlertStatus> = props => {
  const { status, count } = props;
  return (
    <HStack>
      <Circle
        size={2}
        bg={alertStatusColors[status?.toLowerCase()] ?? '#000'}
      ></Circle>
      <HStack>
        <Text>{toPascalCase(status)}</Text>
        {count && <Text>({count})</Text>}
      </HStack>
    </HStack>
  );
};
