import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  MainRouteTable,
  RouteTableSubnetID,
} from '../../../Components/MetadataField/MetaGridFields';

export const RouteTableMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const routes = resource?.network?.Routes ?? resource?.config?.Routes;

  const columns = [
    { header: 'Origin', accessor: 'Origin' },
    {
      header: 'Destination Cidr Block',
      accessor: 'DestinationCidrBlock',
      align: 'left',
    },
    {
      header: 'GatewayId',
      accessor: 'GatewayId',
      align: 'left',
      render: ({ row }) => row?.GatewayId ?? row?.TransitGatewayId,
    },
    { header: 'State', accessor: 'State' },
  ];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {RouteTableSubnetID(resource)}
      {MainRouteTable(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={routes} />}
            iconType={IconTypes.Route}
            label="Routes"
            totalCount={routes?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
