import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ListOfInstancesIcon = props => (
  <Icon viewBox="0 0 57 57" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M44,30.5c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S51.168,30.5,44,30.5z M44,54.5c-6.065,0-11-4.935-11-11
		s4.935-11,11-11s11,4.935,11,11S50.065,54.5,44,54.5z"
      />
      <path
        d="M50,42.5h-5v-5c0-0.553-0.448-1-1-1s-1,0.447-1,1v5h-5c-0.552,0-1,0.447-1,1s0.448,1,1,1h5v5c0,0.553,0.448,1,1,1
		s1-0.447,1-1v-5h5c0.552,0,1-0.447,1-1S50.552,42.5,50,42.5z"
      />
      <path
        d="M27,43.5c-0.552,0-1-0.447-1-1s0.448-1,1-1h1.139c0.754-6.002,4.84-10.968,10.346-13H27c-0.552,0-1-0.447-1-1s0.448-1,1-1
		h18c0.552,0,1,0.447,1,1c0,0.048-0.021,0.089-0.027,0.135c3.014,0.373,5.77,1.582,8.027,3.395V12.676
		C54,5.962,48.538,0.5,41.824,0.5H12.176C5.462,0.5,0,5.962,0,12.676v29.648C0,49.038,5.462,54.5,12.176,54.5h20.236
		C29.685,51.629,28,47.762,28,43.5H27z M27,12.5h18c0.552,0,1,0.447,1,1s-0.448,1-1,1H27c-0.552,0-1-0.447-1-1S26.448,12.5,27,12.5z
		 M20.688,38.15l-6.857,7.999c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,42.87
		c-0.442-0.331-0.531-0.958-0.2-1.399c0.332-0.442,0.958-0.53,1.4-0.2l3.822,2.866l6.248-7.287c0.359-0.42,0.992-0.468,1.41-0.108
		C20.999,37.101,21.047,37.731,20.688,38.15z M20.688,23.15l-6.857,8c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2
		L7.9,27.871c-0.442-0.331-0.531-0.958-0.2-1.399c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866l6.248-7.288
		c0.359-0.42,0.992-0.468,1.41-0.108C20.999,22.101,21.047,22.731,20.688,23.15z M20.688,9.15l-6.857,8
		c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,13.871c-0.442-0.331-0.531-0.958-0.2-1.399
		c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866l6.248-7.288c0.359-0.42,0.992-0.468,1.41-0.108
		C20.999,8.101,21.047,8.731,20.688,9.15z"
      />
    </g>
  </Icon>
);
