import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Stack, Accordion, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { SGNetworkMapWrapper } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Network/SecurityGroup/SGNetworkMapWrapper';
import { NetworkMapWrapper } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Network/NetworkMapWrapper';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const SecurityGroupNetworkTab: FC = () => {
  const { resourceDetails, resourceInsight, resourceType } = useSelector(
    selectActiveResource,
  );
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const egress = resource?.network?.SecurityGroupRules?.filter(
    e => e?.IsEgress,
  );
  const ingress = resource?.network?.SecurityGroupRules?.filter(
    e => !e?.IsEgress,
  );

  const prefixListColumns = [
    { header: 'Name', accessor: 'Name' },
    { header: 'Cidr', accessor: 'Cidr' },
  ];

  const columns = [
    {
      header: 'Cidr',
      accessor: 'CidrIpv4',
      render: ({ row }) =>
        `IPV${row?.CidrIpv6 ? `6: ${row?.CidrIpv6}` : `4: ${row?.CidrIpv4}`}`,
    },
    { header: 'Protocol', accessor: 'IpProtocol' },
    {
      header: 'From/To Port',
      render: ({ row }) => row?.FromPort + ' / ' + row?.ToPort,
    },
    {
      header: 'Rule Id',
      accessor: 'SecurityGroupRuleId',
      align: 'left',
    },
  ];

  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={8}>
      <Box>
        <Accordion allowMultiple defaultIndex={[0]}>
          <MetaAccordionItem
            iconType={IconTypes.NetworkMap}
            label={'NETWORK MAP'}
            panel={
              resourceType === NativeResources.SecurityGroup ? (
                <SGNetworkMapWrapper />
              ) : (
                <NetworkMapWrapper />
              )
            }
            iconBGColor="orange"
            height="570px"
          />
          {resourceType === NativeResources.SecurityGroup && (
            <>
              <MetaAccordionItem
                panel={<Table columns={columns} data={ingress} />}
                iconType={IconTypes.Ingress}
                label={
                  <HStack>
                    <Box>INGRESS:</Box>
                    <Box>Rules ({ingress?.length}),</Box>
                    <Box>Open ports ({insight?.ingress_ports})</Box>
                  </HStack>
                }
                height="400px"
              />
              <MetaAccordionItem
                panel={
                  <Table
                    columns={columns}
                    data={egress}
                    subComponent={({ row }) => (
                      <Box height="250px">
                        <Table
                          data={row?.PrefixListIds}
                          columns={prefixListColumns}
                        />
                      </Box>
                    )}
                  />
                }
                iconType={IconTypes.Egress}
                label={
                  <HStack>
                    <Box>EGRESS:</Box>
                    <Box>Rules ({egress?.length}),</Box>
                    <Box>Open ports ({insight?.egress_ports})</Box>
                  </HStack>
                }
                height="400px"
              />
            </>
          )}
        </Accordion>
      </Box>
    </Stack>
  );
};
