import React from 'react';

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

import { IPopover } from './types';

import './styles.css';

export const CustomPopover = (props: IPopover) => {
  const {
    button,
    header,
    body,
    footer,
    styles,
    arrow = true,
    closeButton = true,
    portal = false,
    ...rest
  } = props;

  const renderContent = () => (
    <PopoverContent {...styles?.content}>
      {header && <PopoverHeader {...styles?.header}>{header}</PopoverHeader>}
      {arrow && <PopoverArrow {...styles?.arrow} />}
      {closeButton && (
        <PopoverCloseButton
          size="sm"
          color="primary"
          {...styles?.closeButton}
        />
      )}
      <PopoverBody {...styles?.body}>{body}</PopoverBody>
      {footer && <PopoverFooter {...styles?.footer}>{footer}</PopoverFooter>}
    </PopoverContent>
  );

  return (
    <Popover {...styles?.popover} {...rest}>
      <PopoverTrigger>{button}</PopoverTrigger>
      {portal ? <Portal>{renderContent()}</Portal> : renderContent()}
    </Popover>
  );
};
