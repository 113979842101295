import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Divider, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { startCase } from 'lodash';
import { Application } from 'pixi.js';
import { useDispatch, useSelector } from 'react-redux';

import { FieldValT2 } from 'components/DataDisplay/Text/FieldValueText';
import { GraphMenu } from 'components/Visualization/PixiGraph/components/GraphMenu';
import { GraphMenuItemTypes } from 'components/Visualization/PixiGraph/components/GraphMenuItem';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { AGraphRenderer } from 'components/Visualization/PixiGraph/core/Renderer';
import { getRootNodes } from 'components/Visualization/PixiGraph/core/utils';
import { AccessMapPaginationNode } from 'components/Visualization/PixiGraph/maps/AccessMap/elements/Node';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { PermissionsModal } from 'containers/Visibility/SecurityGraph/Components/Access/PermissionsModal';
import { useGraphFilters } from 'containers/Visibility/SecurityGraphNext/hooks/useGraphFilters';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { ResourceTypeFilterItemEntry } from 'containers/Visibility/SecurityGraphNext/types';
import { highlightEdges } from 'containers/Visibility/SecurityGraphNext/utils/highlight_edges';
import { isResourceTypeNode } from 'containers/Visibility/SecurityGraphNext/utils/node';
import { useBus } from 'hooks/useBus';

interface IGraphMenu {
  app: Application;
  graph: AGraph<AGraphNode, AGraphEdge>;
  renderer: AGraphRenderer;
}

export const AccessGraphMenu: FC<IGraphMenu> = props => {
  const { graph, app, renderer } = props;
  const dispatch = useDispatch();

  const bus = useBus();

  const { resourceTypeFilters, setResourceTypeFilters } = useGraphFilters();

  const { getTabIndex } = useMetadataDrawerTabs();
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<any>({
    left: 0,
    top: 0,
    width: 20,
    height: 30,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState('');

  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const { resourceAlerts, redirectInfo } = useSelector(selectSecurityGraphNext);
  const { riskContext } = useSelector(selectSharedState);
  const permissions = useDisclosure();
  const [showNodeOverview, setShowNodeOverview] = useState<AGraphNode | null>(
    null,
  );
  const { toCloudAccountName } = useCloudAccountId();

  useEffect(() => {
    const hideMenu = () => {
      //setShowMenu(false);
      setShowTooltip(false);
    };
    graph.on('wheel', hideMenu);
    return () => {
      graph.off('wheel', hideMenu);
    };
  }, [graph]);

  const handleShowOverview = useCallback(
    data => {
      if (data.header === 'Permission Type') {
        // get the root nodes for the resource
        const rootNodes = getRootNodes(
          graph.edgeMap,
          graph.nodeIdMap,
          data.identity,
        );
        if (rootNodes.length === 0) {
          return;
        }
        const rootNode = rootNodes[0];
        const { account, resource_id, uuid, native_name } = rootNode.data;
        updateActiveResource({
          resourceType: native_name,
          uuid: uuid,
          accountId: account,
          resourceId: resource_id,
        });
        const tabIndex = getTabIndex(native_name, 'Permissions');
        onOpenMetadata(tabIndex);
      } else if (data?.header !== 'Resource Type') {
        const { account, resource_id, uuid, native_name } = data;
        updateActiveResource({
          resourceType: native_name,
          uuid: uuid,
          accountId: account,
          resourceId: resource_id,
        });
        onOpenMetadata(getTabIndex(native_name, redirectInfo?.source_tab));
      }
      //setShowMenu(false);
    },
    [
      graph.edgeMap,
      graph.nodeIdMap,
      updateActiveResource,
      getTabIndex,
      onOpenMetadata,
      redirectInfo?.source_tab,
    ],
  );

  /*const handleOnClickPermission = useCallback(() => {
    permissions.onOpen();
  }, []);*/

  useEffect(() => {
    const onMouseDown = entries => {
      const entryDataList = entries.map(c => c.data) ?? [];
      if (!entryDataList.length) {
        //setShowMenu(false);
        return;
      }
      const firstEntryData = entryDataList[0];
      if (firstEntryData instanceof AccessMapPaginationNode) {
        console.log(bus);
        // emit event to update pagination
        bus.emit('update:security-graph-next:pagination', {
          header: firstEntryData.header,
          totalCount: firstEntryData.totalCount,
          visibleCount: Math.min(
            firstEntryData.visibleCount + 10,
            firstEntryData.totalCount,
          ),
        });
      } else if (isResourceTypeNode(firstEntryData?.data)) {
        // toggle resourceTypeFilters
        const filter: ResourceTypeFilterItemEntry = {
          resourceType: firstEntryData.data.native_name,
        };

        const index = resourceTypeFilters.findIndex(
          f => f.resourceType === filter.resourceType,
        );

        if (index > -1) {
          setResourceTypeFilters([]);
        } else {
          setResourceTypeFilters([filter]);
        }
      } else if (firstEntryData instanceof AGraphEdge) {
        const firstEntry = entries[0];
        highlightEdges(firstEntry, renderer, app, graph);
      } else {
        handleShowOverview(firstEntryData?.data);
      }
      //const hasMenu = node.hasMenu;
      //setShowMenu(hasMenu);
    };

    graph.on('mousedown:element', onMouseDown);
    return () => {
      graph.off('mousedown:element', onMouseDown);
    };
  }, [
    graph,
    handleShowOverview,
    renderer,
    app,
    resourceTypeFilters,
    setResourceTypeFilters,
    bus,
  ]);

  useEffect(() => {
    const onMouseOver = (entries: any[]) => {
      const elements = entries.map(e => e.data);
      if (!elements.length) {
        setShowTooltip(false);
        return;
      }
      const element = elements[0];
      const entry = entries[0];
      const { id, data } = entry;
      const [type, , severity] = id.split(':');
      if (type === 'severity') {
        setShowTooltip(true);
        setTooltipStyle({
          left: entry.minX + app.stage.x - 15 + 'px',
          top: entry.minY + app.stage.y - 35 + 'px',
          // width: 120 + 'px',
          height: 30,
        });
        setTooltipInfo(
          `${startCase(severity)}: ${data.severities[severity.toUpperCase()]}`,
        );

        return;
      }

      if (type === 'context') {
        setShowTooltip(true);
        setTooltipStyle({
          left: entry.minX + app.stage.x - 15 + 'px',
          top: entry.minY + app.stage.y - 40 + 'px',
          //width: 170 + 'px',
          height: 30,
        });
        setTooltipInfo(`${element.displayName ?? '-'}`);
        return;
      }

      if (
        element instanceof AGraphNode &&
        !(element instanceof AccessMapPaginationNode)
      ) {
        setShowNodeOverview(element);
      }
    };

    graph.on('mouseover:element', onMouseOver);
    return () => {
      graph.off('mouseover:element', onMouseOver);
    };
  }, [app.stage.x, app.stage.y, graph]);

  useEffect(() => {
    const onMouseOut = (entries: any[]) => {
      //console.log('mouse out', entries);
      if (!entries.length) {
        return;
      }

      const entry = entries[0];
      const { data } = entry;

      if (data instanceof AGraphNode) {
        setShowNodeOverview(null);
      }
    };

    graph.on('mouseout:element', onMouseOut);
    return () => {
      graph.off('mouseout:element', onMouseOut);
    };
  }, [graph]);

  const handleShowAlerts = useCallback(
    e => {
      const node = graph.activeNodes[0];

      if (!node) return;
      if (node.data.severities) {
        node.data.severities = null;
      } else {
        node.data.severities = resourceAlerts.data.severity?.[node.data.uuid];
      }
      renderer.update();
      setShowMenu(false);
    },
    [graph.activeNodes, renderer, resourceAlerts.data.severity],
  );

  const handleShowContexts = useCallback(
    e => {
      const node = graph.activeNodes[0];
      if (!node) return;
      const { data } = node;

      if (riskContext?.data[data.uuid]) {
        dispatch(
          sharedStateActions.getRiskContext({
            q: {
              riskContextRequest: { uuids: ['x'] },
            },
          }),
        );
      } else {
        dispatch(
          sharedStateActions.getRiskContext({
            q: {
              riskContextRequest: { uuids: [data.uuid] },
            },
          }),
        );
      }
      setShowMenu(false);
    },
    [dispatch, graph.activeNodes, riskContext?.data],
  );

  const graphMenuItems = useMemo(
    () => [
      {
        type: GraphMenuItemTypes.overview,
        onClick: handleShowOverview,
      },
      {
        type: GraphMenuItemTypes.alerts,
        onClick: handleShowAlerts,
      },
      {
        type: GraphMenuItemTypes.contexts,
        onClick: handleShowContexts,
      },
    ],
    [handleShowAlerts, handleShowContexts, handleShowOverview],
  );

  const isResourceNode = useCallback(() => {
    return showNodeOverview?.data?.header === 'Resource';
  }, [showNodeOverview?.data]);

  const isResourceType = useCallback(() => {
    return (
      showNodeOverview?.data?.header === 'Resource Type' ||
      showNodeOverview?.data?.header === 'Console' ||
      showNodeOverview?.data?.header === 'Permission Type'
    );
  }, [showNodeOverview?.data]);

  //for console, name, type, value: allow, deny
  //Permission Type: name, type
  //Policy name, type, cloud, id Same goes for Identity e.g Role, User, Group
  //Add groups for user (later)
  //change icon for all the types of policies (use policy standard)
  //role icon is small in header
  //policy and permission type change icon to white in header
  //remove cross account icon, add it in hover
  //findings dashboard: remove top count

  return (
    <>
      {showMenu && <GraphMenu items={graphMenuItems} styles={menuPosition} />}
      {showTooltip && (
        <Flex
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'#fff'}
          align={'center'}
          fontSize={'xs'}
          px={2}
          {...tooltipStyle}
        >
          {tooltipInfo}
        </Flex>
      )}
      {permissions.isOpen && (
        <PermissionsModal
          onClose={permissions.onClose}
          isOpen={permissions.isOpen}
        />
      )}
      {showNodeOverview && (
        <Box
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'#fff'}
          fontSize="sm"
          left={showNodeOverview.x + app.stage.x}
          top={showNodeOverview.y + showNodeOverview.h + app.stage.y + 10}
          w={240}
          px={2}
        >
          <Box color={'orange'} py={2} borderBottom={'1px solid orange'}>
            Overview
          </Box>

          <Stack color={'#fff'} py={2} spacing={1}>
            <FieldValT2
              field={'Name'}
              value={showNodeOverview.data.name ?? ''}
            />
            <FieldValT2
              field={'Type'}
              value={
                showNodeOverview.data?.ariksaType?.aliase ??
                showNodeOverview.data?.ariksaType?.cloud_native_name ??
                showNodeOverview.data?.header ??
                ''
              }
            />
            {!isResourceType() && <FieldValT2 field={'Cloud'} value={'AWS'} />}
            {!isResourceType() && (
              <FieldValT2
                field={'ID'}
                value={showNodeOverview.data.resource_id ?? ''}
              />
            )}
            {showNodeOverview.data.account_type !== 'Same' &&
              showNodeOverview.data.header === 'Account' && (
                <FieldValT2
                  field={'Account Type'}
                  value={showNodeOverview.data.account_type ?? ''}
                />
              )}
            {isResourceNode() && (
              <>
                <FieldValT2
                  field={'Account'}
                  value={
                    toCloudAccountName(showNodeOverview.data.account) ?? ''
                  }
                />
                <FieldValT2
                  field={'Region'}
                  value={showNodeOverview.data.region ?? ''}
                />
                <FieldValT2
                  field={'VPC'}
                  value={showNodeOverview.data.vpc ?? ''}
                />
                <FieldValT2
                  field={'Owner'}
                  value={showNodeOverview.data.owner ?? ''}
                />
              </>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
};
