import React, { FC, useEffect, useState } from 'react';

import { Accordion, Center } from '@chakra-ui/react';
import { flatten, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { IacAccountResources } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Deployment/IacAccountResources';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

interface IIacDeployments {}

export const IacDeployments: FC<IIacDeployments> = props => {
  const dispatch = useDispatch();
  const { deploymentSummary, resourceDetails, resourceInsight } = useSelector(
    selectActiveResource,
  );
  const { resourceUuid, resourceId, resourceAccountId } = useSelector(
    selectActiveResource,
  );
  const [expandedAccordion, setExpandedAccordion] = useState<number[]>([]);

  useEffect(() => {
    dispatch(
      actions.getDeploymentSummary({
        q: {
          uuid: resourceUuid,
        },
      }),
    );
  }, [dispatch, resourceUuid]);

  return (
    <WithSpinner
      loadStatus={{
        loading: deploymentSummary.isLoading,
      }}
    >
      {isEmpty(deploymentSummary.data) ? (
        <Center h="full" w="full" color="gray.200" minH={48}>
          Not an IAC repository
        </Center>
      ) : (
        <Accordion
          allowMultiple
          onChange={res => {
            setExpandedAccordion(flatten([res]));
          }}
        >
          {deploymentSummary.data.map((ds, index) => {
            return (
              <IacAccountResources
                repositoryUuid={resourceUuid}
                repositoryId={resourceId}
                accountId={ds.cloud_account_uuid}
                cloud={ds.cloud}
                cloud_account_id={resourceAccountId}
                resource_count={ds.resource_count}
                region_count={ds.region_count}
                vpcCount={ds.vpc_count}
                isExpanded={expandedAccordion.includes(index)}
              />
            );
          })}
        </Accordion>
      )}
    </WithSpinner>
  );
};
