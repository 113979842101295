import React from 'react';

import { Icon } from '@chakra-ui/react';

export const KeyIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    p={0.5}
    {...props}
  >
    <path
      d="M508.4,3.9c-4.9-5.1-13-5.2-18.1-0.3L351.2,142.7c-0.2,0.2-0.4,0.2-0.6,0.4c-0.2,0.2-0.2,0.4-0.4,0.6
	L186.8,307.1c-19.7-15.7-44.4-25.6-71.6-25.6C51.6,281.5,0,333.1,0,396.8S51.6,512,115.2,512c63.6-0.1,115.2-51.6,115.2-115.2
	c0-27.2-9.8-51.9-25.6-71.6l154.9-154.9l45.3,45.3c2.4,2.4,5.7,3.8,9.1,3.8v0c3.4,0,6.6-1.4,9-3.7c5-5,5-13.1,0-18.1l-45.3-45.3
	l54.3-54.3l45.3,45.3c2.4,2.4,5.7,3.8,9.1,3.8c3.4,0,6.7-1.3,9-3.7c5-5,5-13.1,0-18.1l-45.3-45.3l58-58
	C513.2,16.7,513.2,8.9,508.4,3.9z M115.2,486.4c-49.5,0-89.6-40.1-89.6-89.6s40.1-89.6,89.6-89.6c49.5,0.1,89.6,40.2,89.6,89.6
	C204.9,446.3,164.7,486.4,115.2,486.4z"
    />
  </Icon>
);
