import React, { FC, useEffect, useState } from 'react';

import {
  ConditionBase,
  ResourceTagCondition,
} from '@ariksa/inventory-core/api';
import { ApplicationType } from '@ariksa/inventory-core/dist/api';
import { Box, Button, Stack } from '@chakra-ui/react';
import { omit, isEmpty, map, filter } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { PrimaryButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { deleteIconStyles } from 'containers/PolicyHub/AlertWorkflow/styles';

import { useWhitelistContext } from '../../../../../context';
import { actions } from '../../../../../slice';

import { ResourceTypeComponent } from './ResourceTypeComponent';

export const ResourceTypeCriteria: FC = () => {
  const { resourceTypeCriteria, ec2Applications } = useSelector(
    selectWhitelist,
  );
  const {
    resourceTypeOptions,
    selectedConditions,
    setSelectedConditions,
  } = useWhitelistContext();
  const [criteria, setCriteria] = useState<Record<string, ConditionBase>>({});
  const [osOptions, setOsOptions] = useState<Dict<any>[]>([]);
  const [technologyOptions, setTechnologyOptions] = useState<Dict<any>[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getEC2Applications({ q: {} }));
  }, [dispatch]);

  //set os or technology options
  useEffect(() => {
    setOsOptions(
      map(ec2Applications.data[ApplicationType.Os], (v, k) => ({
        label: k,
        value: k,
        data: v,
      })),
    );
    setTechnologyOptions(
      map(ec2Applications.data[ApplicationType.Technology], (v, k) => ({
        label: k,
        value: k,
        data: v,
      })),
    );
  }, [ec2Applications.data]);

  useEffect(() => {
    setCriteria(resourceTypeCriteria);
    !isEmpty(resourceTypeCriteria) &&
      dispatch(
        actions.updateCriteria({
          field: 'resource_type_criteria',
          value: map(resourceTypeCriteria, o => o),
        }),
      );
  }, [resourceTypeCriteria, dispatch]);

  return (
    <Stack spacing={4}>
      <Box>
        <PrimaryButton
          onClick={() => {
            const id = nanoid();
            dispatch(
              actions.updateResourceTypeCriteria({
                id,
                value: {
                  resource_type: resourceTypeOptions[0]?.value,
                  key_condition: ResourceTagCondition.HasResourceType,
                },
              }),
            );
          }}
          size="xs"
        >
          + Add Resource Type Criteria
        </PrimaryButton>
      </Box>
      {map(Object.keys(criteria).reverse(), id => (
        <Box
          border="1px solid"
          py={2}
          pl={2}
          borderRadius={6}
          borderColor="componentBorder"
        >
          <Stack isInline>
            <ResourceTypeComponent
              id={id}
              osOptions={osOptions}
              technologyOptions={technologyOptions}
            />
            <Button
              {...deleteIconStyles}
              onClick={() => {
                const rest = omit(resourceTypeCriteria, id);
                if (isEmpty(rest)) {
                  setSelectedConditions(
                    filter(
                      selectedConditions,
                      o => o.value !== 'resource_type_criteria',
                    ),
                  );
                  dispatch(
                    actions.updateCriteria({
                      field: 'resource_type_criteria',
                      value: [],
                    }),
                  );
                }
                dispatch(
                  actions.updateResourceTypeCriterion(
                    omit(resourceTypeCriteria, id),
                  ),
                );
              }}
            >
              <TrashIcon />
            </Button>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
