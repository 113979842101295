import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RegexDataClassifierIcon = props => (
  <Icon viewBox="0 0 24 24" color="inherit" h="full" w="full" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      fill="none"
      d="m12 4 .005 7.993m0 0 7.603-2.465m-7.603 2.465 4.697 6.48m-4.697-6.48-4.707 6.48m4.707-6.48L4.392 9.528"
    />
  </Icon>
);
