import React, { FC, useEffect, useReducer } from 'react';

import { AlertWorkflowResponse } from '@ariksa/notification/api';
import { Box } from '@chakra-ui/react';
import { filter, find, forEach, includes, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Form, IconTypes, Modal, StackedHeader } from 'components/index';
import { EnvironmentName } from 'containers/App/utils';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import { formStyles } from 'containers/SharedState/Components/ExportAlerts/styles';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface Props {
  isOpen: boolean;
  onClose();
  workflow: AlertWorkflowResponse;
  loadData();
}

export const AttachWorkflow: FC<Props> = props => {
  const { isOpen, onClose, workflow, loadData } = props;
  const dispatch = useDispatch();
  const { attachWorkflow } = useSelector(selectPolicyHub);
  const { blueprints } = useSelector(selectSharedState);
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      environmentOptions: [],
      selectedEnvs: [],
      blueprintOptions: [],
      selectedBlueprint: {},
    },
  );

  useEffect(() => {
    dispatch(sharedStateActions.getBlueprints({ q: {} }));
  }, [dispatch]);

  //set blueprint options
  useEffect(() => {
    const options: Record<string, any>[] = [];
    forEach(blueprints.data, o => {
      options.push({ label: o?.name, value: o?.id, data: o });
    });
    updateState({ blueprintOptions: options, selectedBlueprint: options[0] });
  }, [blueprints.data, workflow]);

  //set environment options
  useEffect(() => {
    const options = map(state.selectedBlueprint?.data?.environments, o => ({
      label: <EnvironmentName environmentId={o} />,
      value: o,
    }));
    updateState({ environmentOptions: options, selectedEnvs: options });
  }, [state.selectedBlueprint]);

  const handleSubmit = data => {
    dispatch(
      actions.attachWorkflow({
        q: {
          workflowId: workflow.uuid,
          editAlertWorkflow: {
            policy: [
              ...(workflow?.policy || []),
              {
                policy_id: state.selectedBlueprint?.value,
                environment_ids: map(state.selectedEnvs, o => o.value),
              },
            ],
          },
        },
        onSuccess: () => {
          loadData();
          onClose();
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      styles={{ content: { zIndex: 10000 } }}
      header={
        <StackedHeader
          upper={'Attach Blueprint'}
          lower={workflow?.name}
          type={IconTypes.Automation}
        />
      }
      body={
        <Form
          schema={{
            workflow: {
              type: 'custom-with-form-control',
              label: 'Workflow',
              component: (
                <Box
                  border="1px solid"
                  borderRadius={3}
                  borderColor="gray.200"
                  px={3}
                  py={1}
                >
                  {workflow.name}
                </Box>
              ),
            },
            type: {
              type: 'react-select',
              label: 'Blueprint',
              options: state.blueprintOptions,
              value: state.selectedBlueprint,
              isLoading: blueprints.isLoading,
              onChange: selected =>
                updateState({ selectedBlueprint: selected }),
              isRequired: true,
            },
            environment: {
              type: 'react-select',
              label: 'Environment Name',
              options: state.environmentOptions,
              value: state.selectedEnvs,
              isMulti: true,
              onChange: selected => updateState({ selectedEnvs: selected }),
              isRequired: true,
            },
          }}
          buttonOptions={{
            submit: {
              name: 'Attach',
              isLoading: blueprints.isLoading || attachWorkflow.isLoading,
              isDisabled:
                blueprints.isLoading ||
                attachWorkflow.isLoading ||
                !state.selectedBlueprint?.value ||
                isEmpty(state.selectedEnvs),
            },
            reset: {
              name: 'Cancel',
              isVisible: true,
              onClick: () => onClose(),
            },
          }}
          handleSubmit={handleSubmit}
          styles={formStyles}
        />
      }
    />
  );
};
