import React from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { renderRiskContext } from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { ServicesIcon } from 'components/Icons';
import { EnvironmentName } from 'containers/App/utils';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const Details = () => {
  const { selectedSourceNodeOption } = useSelector(selectSecurityGraphNext);
  const { value: entity } = selectedSourceNodeOption ?? {};

  return (
    <Stack spacing={3}>
      <SidebarAccordion
        header={'DETAILS'}
        icon={<ServicesIcon color="primary" />}
      >
        <Stack spacing={4}>
          <DetailsField label="Name" value={entity?.name ?? ''} />
          <DetailsField
            label="Account"
            value={<CloudAccountName accountId={entity?.account ?? ''} />}
          />
          <DetailsField
            label="Environment"
            value={<EnvironmentName environmentId={entity?.environment_id} />}
          />
          {/*<DetailsField*/}
          {/*  label="Risk Context"*/}
          {/*  value={*/}
          {/*    <HStack>*/}
          {/*      {renderRiskContext(*/}
          {/*        entity?.contexts?.map(c => ({ type: c.name })),*/}
          {/*      )}*/}
          {/*    </HStack>*/}
          {/*  }*/}
          {/*/>*/}
        </Stack>
      </SidebarAccordion>
    </Stack>
  );
};
