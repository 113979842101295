import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { useGraphDnd } from 'components/Visualization/PixiGraph/hooks/useGraphDnd';
import { useGraphMenuTooltip } from 'components/Visualization/PixiGraph/hooks/useMenuTooltip';
import { useAccessMap } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMap';

interface IAccessGraph {}

export const AccessGraph: FC<IAccessGraph> = props => {
  const ref = useRef<any>(null);
  const { app, graph, renderer } = useAccessMap({
    ref,
  });
  const { listeners } = useGraphDnd({ ref, app });
  const graphMenu = useGraphMenuTooltip({ app, graph, renderer, ref });

  return (
    <Box
      h={'full'}
      w={'full'}
      pos={'absolute'}
      onContextMenu={e => e.preventDefault()}
    >
      <div
        className="graph-canvas-v1"
        ref={ref}
        {...listeners}
        {...graphMenu.listeners}
      ></div>
    </Box>
  );
};
