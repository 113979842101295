import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { actions } from '../slice';

import { AlertWorkFlowWizard } from './AlertWorkflowWizard';

export const CreateAlertWorkFlow: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetFormData());
  }, [dispatch]);

  return <AlertWorkFlowWizard actionType="Create" />;
};
