import { customTheme } from 'theme';

export const widgetStyles = {
  common: {
    rounded: customTheme.radii.cardBorderRadius,
    //borderWidth: '1px',
    px: 2,
    pb: 2,
    h: 'full',
    bg: 'cardBg',
    //borderColor: 'componentBorder',
  },
  dark: {
    //borderColor: 'whiteAlpha.400',
  },
  light: {
    //borderColor: 'gray.200',
    //boxShadow: '0 0 30px #e4e4e4',
  },
};
