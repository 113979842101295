import { StepType } from '@reactour/tour';

export const userDashboard: StepType[] = [
  {
    selector: '[className=cloud_accounts]',
    content:
      'This shows the cloud environment that the Dashboard is summarizing',
  },
  {
    selector: '[className=top_5_issues_to_resolve]',
    content:
      'Prioritize your efforts by focusing on resources with the most critical issues',
  },
  {
    selector: '[className=alert_summary]',
    content: 'Review your critical, medium and low priority alerts',
  },
  {
    selector: '[className=alerts_historical_trend]',
    content:
      'View historical alerting and correlate anomalies to changes in cloud environment',
  },
  {
    selector: '[className=alerts_breakdown]',
    content:
      'Ariksa automatically classifies issues to to reduce noise, increase ease of prioritization',
  },
  {
    selector: '[className=dashboard_alerts_table]',
    content: `For each category, easily investigate by using 360 view for every alerting entity`,
  },
];

export const alertsDashboardTourSteps: StepType[] = [
  {
    selector: '[className=cloud_accounts]',
    content:
      'This shows the cloud environment that the dashboard is summarizing',
  },
  {
    selector: '[className=dashboard_alerts_breakdown]',
    content: 'Immediate access to alerts breakdown',
  },
  {
    selector: '[className=alerts_historical_insights_by_severity]',
    content:
      'Get historical insights for how quickly findings are being addressed',
  },
  {
    selector: '[className=alerts_entity_type]',
    content:
      'Get insights into entity types with findings that require immediate\n' +
      'attention',
  },
  {
    selector: '[className=alerts_entities_with_highest_age]',
    content: 'Get insights into entities with long-standing alerts',
  },
];

export const activityDashboardTourSteps: StepType[] = [
  {
    selector: '[className=cloud_accounts]',
    content:
      'Choose the cloud environment for which you need activity insights',
  },
  {
    selector: '[className=dashboard_activity_breakdown]',
    content:
      'Assess API activity and investigate errors for potential breach attempts',
  },
  {
    selector: '[className=historical_error_by_event_type]',
    content: 'Get historical insights to detect any anomalous behavior',
  },
  {
    selector: '[className=user_auth_historical_trend]',
    content: 'Get insights into how much of your API activity is MFA enabled',
  },
  {
    selector: '[className=top_5_active_entities]',
    content:
      'Monitor the most significant source of activity to your data sources',
  },
  {
    selector: '[className=top_5_roles_access]',
    content:
      'Monitor your most API-active resources to maximize tight security controls',
  },
];
