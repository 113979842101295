import { extendTheme, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { LocalStoreService } from 'services/ZocalStore/local_store_service';

import { commonThemeProps } from './commonThemeProps';
import { fixedColors } from './fixedColors';
import { colors } from './theme2/colors';
import { dark } from './theme2/dark';
import { light } from './theme2/light';
import { colorOpacity } from './utils';

const colorMode = LocalStoreService.getChakraColorMode();
const primaryColor = props => mode(light.primary, dark.primary)(props);

export const customTheme = extendTheme({
  ...commonThemeProps,
  components: {
    Switch: {
      baseStyle: props => ({
        track: {
          background: theme.colors.gray['300'],
          borderRadius: 3,
          _checked: {
            background: primaryColor(props),
          },
        },
      }),
    },
    Progress: {
      baseStyle: props => ({
        filledTrack: {
          bg: mode(
            `${props.colorScheme}.300`,
            `${props.colorScheme}.200`,
          )(props),
        },
      }),
    },
    Checkbox: {
      baseStyle: props => ({
        control: {
          _disabled: {
            opacity: 0.3,
          },
          _focus: {
            borderColor: primaryColor(props),
            boxShadow: `0 0 0 1px ${primaryColor(props)}`,
          },
          _checked: {
            background: primaryColor(props),
            borderColor: primaryColor(props),
            _disabled: {
              opacity: 0.3,
              background: '#fff',
              borderColor: primaryColor(props),
            },
            _hover: {
              background: primaryColor(props),
              borderColor: primaryColor(props),
              boxShadow: `0 0 0 1px ${primaryColor(props)}`,
            },
          },
        },
      }),
    },
    Radio: {
      baseStyle: props => ({
        control: {
          _checked: {
            background: primaryColor(props),
            borderColor: primaryColor(props),
            _hover: {
              background: primaryColor(props),
              borderColor: primaryColor(props),
            },
          },
        },
      }),
    },
  },
  colors: {
    ...fixedColors,
    ...colors,
    ...(colorMode === 'dark' ? dark : light),
  },
});

export const colorMap = {
  primary(op: Opacity = 500) {
    return colorOpacity(customTheme.colors.primary, op / 500);
  },
};

type Opacity = 0 | 30 | 50 | 100 | 200 | 300 | 400 | 500;

//export const customTheme = light;
