import React, { FC, useState } from 'react';

import { Box, Tr, Td } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Modal } from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { selectApp } from 'containers/App/selectors';

import { selectCloudAccounts } from '../selectors';
import { actions } from '../slice';
import { snapshotColumns } from '../utils';

import { SnapshotCard } from './SnapshotCard';

interface Props {
  account_id: string;
  isOpen: boolean;
  onClose: () => void;
}

export const SnapshotTable: FC<Props> = props => {
  const { user } = useSelector(selectApp);
  const { snapshots } = useSelector(selectCloudAccounts);
  const { pageInfo, data, total, loading } = snapshots;
  const { account_id, isOpen, onClose } = props;
  const dispatch = useDispatch();
  useDeepCompareEffect(() => {
    dispatch(
      actions.loadSnapshots({
        account_id,
        page_number: pageInfo.page_number,
        page_size: pageInfo.page_size,
      }),
    );
  }, [dispatch, pageInfo, user.info.org_id, account_id]);

  const renderRow = ({ row }) => {
    return (
      <Tr>
        <Td colSpan={100} p="0">
          <Box h="300px" overflowX="auto">
            <SnapshotCard row={row} />
          </Box>
        </Td>
      </Tr>
    );
  };

  const [expanded, setExpanded] = useState(false);
  const [rowIndex, SetRowIndex] = useState(false);

  const toggleShow = (row, index) => {
    setExpanded(!expanded);
    SetRowIndex(index);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Inventory History Summary"
      // size={'medium'}
      body={
        <Box h="500px">
          <Table
            columns={snapshotColumns}
            data={data}
            subComponent={renderRow}
            isLoading={loading}
            onRowClick={toggleShow}
            expanded={expanded}
            rowIndex={rowIndex}
            styles={{
              height: '57vh',
            }}
            pagination={{
              pageInfo: pageInfo,
              totalCount: total,
              onChange: pageInfo =>
                dispatch(actions.updateSnapshotPagination(pageInfo)),
            }}
          />
        </Box>
      }
      styles={{ modal: { size: '3xl' } }}
    />
  );
};
