/**
 *
 * Update GCP Account
 *
 */

import React, { memo } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Form, GoogleCloudIcon } from 'app/components';
import { actions as setupActions } from 'containers/Setup/slice';

import { selectCloudAccounts } from '../../../selectors';

export const EditGCPAccount = memo(() => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { accountDetails } = useSelector(selectCloudAccounts);
  const { data, isLoading } = accountDetails;
  const { gcp = {} } = data;

  const handleSubmit = data => {
    const { account_name } = data;

    dispatch(
      setupActions.updateCloudAccount({
        q: {
          uuid: params.id!,
          cloudAccountUpdate: {
            cloud_type: CloudProviders.Gcp,
            name: account_name,
          },
        },
        onSuccess: () => {
          navigate('/setup/accounts');
        },
      }),
    );
  };

  const handleCancel = () => {
    navigate('/setup/accounts');
  };

  return (
    <Form
      title="Update GCP Account"
      titleIcon={<GoogleCloudIcon />}
      isTitleIconFilled={false}
      schema={{
        account_name: {
          type: 'text',
          label: 'Account Name',
          placeholder: 'Engineering-US',
          isRequired: true,
          defaultValue: data.name,
        },
        role_arn: {
          label: 'Service Account - Email ID',
          type: 'text',
          isDisabled: true,
          isRequired: true,
          defaultValue: gcp?.service_account_email,
        },
      }}
      buttonOptions={{
        submit: {
          name: 'Update',
          isLoading: isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: handleCancel,
        },
      }}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
});
