/**
 *
 * User Profile components
 *
 */

import React from 'react';

import { useSelector } from 'react-redux';

import { usePageContentContext } from 'components/Layout';
import { SideNav, SideNavMenuItemProps } from 'components/Navigation/SideNav';
import { selectApp } from 'containers/App/selectors';

import { UserProfileComponentProps } from '../types';

import { Permissions } from './Permissions';
import { Profile } from './Profile';
import { UserSession } from './UserSession';

export const UserProfileComponent = (props: UserProfileComponentProps) => {
  const {
    roles,
    apiToken,
    classification,
    memberships,
    allMemberships,
    allRoles,
    path,
    ...rest
  } = props;

  const { user, userAuth } = useSelector(selectApp);
  const {
    username,
    email,
    first_name,
    last_name,
    organization,
    id,
  } = user.info;

  const { contentHeight } = usePageContentContext();

  const menuItems: SideNavMenuItemProps[] = [
    {
      title: 'Profile',
      key: 'profile',
      component: (
        <Profile
          {...rest}
          firstName={first_name}
          lastName={last_name}
          orgName={organization}
          email={email}
          username={username}
          guid={id}
          createdTimestamp={userAuth.data.created_at}
        />
      ),
    },
    {
      title: 'Permissions',
      key: 'permissions',
      component: <Permissions />,
    },
    // { title: 'Timeline', key: 'timeline', component: <UserTimeline /> },
    { title: 'Sessions', key: 'sessions', component: <UserSession /> },
  ];
  return (
    <SideNav
      menuItems={menuItems}
      height={contentHeight + 'px'}
      styles={{ menuItem: { fontWeight: 400 } }}
      current={'profile'}
    />
  );
};
