import React, { FC, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Stack,
  RadioGroup,
  Radio,
  Box,
  HStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/DataEntry/Form/fields/FormLabel';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, RadioFieldSchema, RadioFieldStyles } from '../types';

export const RadioField: FC<FieldProps<RadioFieldSchema>> = ({
  id,
  name,
  field,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    helpIcon,
    isDisabled,
    onChange,
    defaultValue,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const { register, watch } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<RadioFieldStyles>('radioField', styles);

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnchange = value => onChange && onChange(value);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <RadioGroup
        {...fieldStyles.radioGroup}
        defaultValue={defaultValue}
        onChange={handleOnchange}
      >
        <Stack {...fieldStyles.radioGroupInnerWrapper}>
          <HStack pb={4} spacing={6}>
            {field.fields.map(radio => (
              <Radio
                key={radio.value}
                name={name}
                value={radio.value}
                ref={register}
                isDisabled={isDisabled}
                data-testid={`${id}-${radio.value}`}
                {...fieldStyles.radio}
              >
                {radio.label || radio.value}
              </Radio>
            ))}
          </HStack>
        </Stack>
      </RadioGroup>
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
