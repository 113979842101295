import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Center,
  HStack,
  Stack,
  useDisclosure,
  Button,
} from '@chakra-ui/react';

import { H4 } from 'components/DataDisplay';
import { PrimaryButton } from 'components/DataEntry';
import { defaultStyles } from 'components/DataEntry/Date/styles';
import { timeButtonStyles } from 'components/DataEntry/Time/styles';
import { TimePickerProps } from 'components/DataEntry/Time/types';
import {
  addZeroPrefix,
  renderHourMinute,
} from 'components/DataEntry/Time/utils';
import { ClockIcon } from 'components/Icons';
import { Popover } from 'components/Overlay';

export const TimePicker = (props: TimePickerProps) => {
  const { label, button, styles, value, onChange } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hour, setHour] = useState(10);
  const [minute, setMinute] = useState(0);
  const [ampm, setAmpm] = useState('AM');

  useEffect(() => {
    if (!!value) {
      const values = value?.split(':');
      let h = parseInt(values[0]);

      if (h >= 12) {
        h = h - 12;
        setAmpm('PM');
      } else setAmpm('AM');

      if (h === 0) h = 12;

      setHour(h);
      setMinute(parseInt(values[1]));
    }
  }, [value]);

  const updateTimeState = useCallback(
    (val, type) => {
      const getAddTwelve = (ampm, hour) => {
        return (ampm === 'PM' && hour !== 12) || (ampm === 'AM' && hour === 12);
      };

      let addTwelve = getAddTwelve(ampm, hour);

      switch (type) {
        case 'ampm':
          setAmpm(val);
          addTwelve = getAddTwelve(val, hour);
          break;
        case 'h':
          setHour(val + 1);
          addTwelve = getAddTwelve(ampm, val + 1);
          break;
        default:
          setMinute(val);
      }

      let hours = (type === 'h' ? val + 1 : hour) + (addTwelve ? 12 : 0);
      if (hours === 24) hours = 0;
      onChange?.(hours + ':' + (type === 'm' ? val : minute));
    },
    [hour, minute, onChange, ampm],
  );

  const RenderNumbers = ({ max, type }) => {
    const scrollTo = ref => {
      if (ref && ref.current /* + other conditions */) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };
    const value = type === 'h' ? hour : minute;

    return (
      <Box w="full">
        <Stack>
          <Center>{type === 'h' ? 'Hours' : 'Minutes'}</Center>
          <Box h={32} overflow="scroll" ref={scrollTo}>
            <Stack>
              {Array.from(Array(max).keys()).map(o => (
                <Button
                  {...timeButtonStyles}
                  ref={scrollTo}
                  isActive={(type === 'h' ? o + 1 : o) === value}
                  onClick={() => updateTimeState(o, type)}
                >
                  {addZeroPrefix(type === 'h' ? o + 1 : o)}
                  {type === 'h' ? o + 1 : o}
                </Button>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Popover
        styles={{ footer: { p: 0 }, content: { w: 56 }, ...styles?.popover }}
        isOpen={isOpen}
        onClose={onClose}
        header={<H4 outline={'none'}>Select time</H4>}
        button={
          button ? (
            <PrimaryButton onClick={onOpen}>
              {label ?? 'Select Date'}
            </PrimaryButton>
          ) : (
            <Box
              onClick={onOpen}
              {...defaultStyles.inputBox}
              {...styles?.inputBox}
              py={1}
            >
              <HStack>
                <Center boxSize={5}>
                  <ClockIcon />
                </Center>
                <HStack>
                  {renderHourMinute(hour)}
                  <Box>:</Box>
                  {renderHourMinute(minute)}
                  <Box>{ampm}</Box>
                </HStack>
              </HStack>
            </Box>
          )
        }
        body={
          <HStack align="flex-left">
            <RenderNumbers max={12} type="h" />
            <RenderNumbers max={60} type="m" />
            <Stack pt={5} mt="3px" w={32}>
              <Center></Center>
              <Button
                {...timeButtonStyles}
                w="full"
                isActive={ampm === 'AM'}
                onClick={() => updateTimeState('AM', 'ampm')}
              >
                AM
              </Button>
              <Button
                {...timeButtonStyles}
                w="full"
                isActive={ampm === 'PM'}
                onClick={() => updateTimeState('PM', 'ampm')}
              >
                PM
              </Button>
            </Stack>
          </HStack>
        }
      />
    </Box>
  );
};
