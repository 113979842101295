import {
  FormStyles,
  inlineObjectFieldStyles,
} from 'app/components/DataEntry/Form';

export const styles: FormStyles = {
  objectField: {
    ...inlineObjectFieldStyles,
  },
};
