import React, { createContext, useContext } from 'react';

import {
  AppSchemasSearchModelsRelationship,
  Ordering,
  Path,
  SearchResponseNode,
  SourceItems,
} from '@ariksa/inventory-core/api';
import { DndContext } from '@dnd-kit/core';

import '../../../style.css';
import { AGraphNodeProps } from 'components/Visualization/PixiGraph/core/Node';

export interface NodePagination {
  header: string;
  visibleCount: number;
  totalCount: number;
}

export interface IAccessMapContext {
  paths: Path[];
  edges: AppSchemasSearchModelsRelationship[];
  ordering: Ordering[];
  nodes: AGraphNodeProps[];
  source_items: SourceItems[];
  pagination?: Record<string, NodePagination>;
  dirtyCount?: number;
  setDirty?: () => void;

  // api call status
  isLoading: boolean;
  isLoaded: boolean;
}

export const AccessMapContext = createContext<IAccessMapContext>({
  source_items: [],
  ordering: [],
  paths: [],
  edges: [],
  nodes: [],
  pagination: {},
  dirtyCount: 0,
  setDirty: () => {},

  isLoading: false,
  isLoaded: false,
});

interface IAccessMapContextProviderProps {
  value: IAccessMapContext;
  children: React.ReactNode;
}

export const AccessMapContextProvider = (
  props: IAccessMapContextProviderProps,
) => {
  return (
    <DndContext>
      <AccessMapContext.Provider value={props.value}>
        {props.children}
      </AccessMapContext.Provider>
    </DndContext>
  );
};

export const useAccessMapContext = () => useContext(AccessMapContext);
