/**
 *
 * Admin Configuration
 *
 */

import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { each } from 'lodash';
import { AiOutlineCheck } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import * as yup from 'yup';

import { NewUserParams } from 'api/auth/api.pb';
import { Form } from 'components/DataEntry/Form';
import { CloseIcon } from 'components/Icons';
import { actions } from 'containers/Organizations/OrganizationWizard/slice';
import { getOptions } from 'containers/Setup/utils';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

import { passwordRegExp } from '../../../../../utils/regex';
import { actions as groupActions } from '../../../Setup/Groups/slice';
import { selectSetupUsers } from '../../../Setup/Users/selectors';
import { actions as userActions } from '../../../Setup/Users/slice';
import { selectOrganizationWizard } from '../selectors';
import { ADMIN_FORM_TYPE, OrganizationWizardStepProps } from '../types';

import { styles } from './styles';

interface AdminConfigurationProps extends OrganizationWizardStepProps {
  org_user: NewUserParams;
  isLoading: boolean;
  updateOrgUserFieldAction(data);
}

export function AdminConfiguration(props: AdminConfigurationProps) {
  const dispatch = useDispatch();

  const {
    handleReset,
    handleSubmit,
    handleBack,
    action,
    org_user,
    isLoading,
    updateOrgUserFieldAction,
  } = props;

  const { username, email, first_name, last_name, password } = org_user;

  const { activeUser, userForm } = useSelector(selectSetupUsers);
  const { updateOrganization, updateOrgUser, orgAdminGroupById } = useSelector(
    selectOrganizationWizard,
  );
  const { organization = {} } = updateOrganization.data;
  const { users: admin_users = [] } = orgAdminGroupById.data;
  const { formType } = updateOrgUser.data;

  const [isEmailDirty, setIsEmailDirty] = useState(true);
  const userByEmail = useTrackApiCall(userForm);

  const isCreateAction = action === 'Create';
  const isUpdateAction = action === 'Update';

  const isViewForm = isUpdateAction && formType === ADMIN_FORM_TYPE.view_admins;
  const isAddNewUserForm =
    isUpdateAction && formType === ADMIN_FORM_TYPE.add_new_user;
  const isAddExistingUserForm =
    isUpdateAction && formType === ADMIN_FORM_TYPE.add_existing_user;

  const params = useParams<{ org_id: string }>();

  const updateUserField = useCallback(
    field => value => {
      dispatch(updateOrgUserFieldAction({ field, value }));
    },
    [dispatch, updateOrgUserFieldAction],
  );

  const updateUserDetails = useCallback(
    user => {
      each(user, (v, k) => updateUserField(k)(v));
    },
    [updateUserField],
  );

  useDeepCompareEffect(() => {
    if (userByEmail.success) {
      const { first_name, last_name, username, email } = userForm.data;
      const user = {
        username,
        first_name,
        last_name,
        email,
      };

      updateUserDetails(user);
      userByEmail.done();
    }
  }, [userByEmail, userForm, updateUserDetails]);

  // Will select view admins when admin details opens
  // useEffect(() => {
  //   dispatch(actions.updateUpdateFormType(ADMIN_FORM_TYPE.view_admins));
  // }, [dispatch]);

  // load organization admin group
  useEffect(() => {
    const admin_group_id =
      updateOrganization.data?.organization?.admin_group_id;
    if (params.org_id) {
      // dispatch(actions.updateUpdateFormType(ADMIN_FORM_TYPE.view_admins));
      dispatch(
        actions.getOrganizationAdminGroupById({
          id: admin_group_id,
          organization: params.org_id,
        }),
      );
    }
  }, [dispatch, orgAdminGroupById.data.id, params, updateOrganization]);

  useEffect(() => {
    const isAdminUserNameSet = admin_users.find(a => a.username === username);
    if (isViewForm && admin_users.length && !isAdminUserNameSet) {
      const user = admin_users[0];
      if (user) {
        updateUserDetails(user);
      }
    }
  }, [admin_users, isViewForm, updateUserDetails, username]);

  const adminHandleSubmit = user => {
    if (isCreateAction) {
      handleSubmit(user);
    } else if (isAddNewUserForm) {
      handleAddNewUserFormSubmit(user);
    } else if (isAddExistingUserForm) {
      handleAddExistingUserFormSubmit(user);
    } else {
      handleSubmit(user);
    }
  };

  const handleAddNewUserFormSubmit = user => {
    const { full_name, username, password, email } = user;
    const { first_name, last_name } = full_name;

    dispatch(
      userActions.createUser({
        user: {
          first_name,
          last_name,
          username,
          password,
          email,
          user_groups: [{ ...orgAdminGroupById.data }],
        },
        organization: organization.name,
      }),
    );
    handleSubmit(user);
  };

  const handleAddExistingUserFormSubmit = user => {
    if (activeUser.id) {
      dispatch(
        groupActions.updateGroup({
          organization: organization.name,
          user_group: {
            ...orgAdminGroupById.data,
            users: [...(admin_users ?? []), activeUser],
          },
        }),
      );
    }
    handleSubmit(user);
  };

  const handleEmailValidation = () => {
    setIsEmailDirty(false);
    dispatch(
      userActions.getUserByEmail({
        email: email ?? '',
        organization: organization.name ?? '',
      }),
    );
  };

  const schemaForm = yup.object().shape({
    username: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_.]*$/i,
        'Only alphanumeric, underscore, period allowed!',
      ),
    email: yup.string().email('Email is not valid!'),
    ...(isAddNewUserForm || isCreateAction
      ? {
          password: yup
            .string()
            .matches(
              passwordRegExp,
              'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character!',
            ),
        }
      : {}),
    full_name: yup.object().shape({
      first_name: yup
        .string()
        .matches(
          /^[a-zA-Z0-9-. ]*$/i,
          'Only alphanumeric, space, period, hyphen allowed!',
        ),
      last_name: yup
        .string()
        .matches(
          /^[a-zA-Z0-9-. ]*$/i,
          'Only alphanumeric, space, period, hyphen allowed!',
        ),
    }),
  });

  let formWidth = ['full', 'full', 'full', 'formWidth'];

  const resetForm = () => {
    ['username', 'first_name', 'last_name', 'email', 'password'].forEach(key =>
      updateUserField(key)(''),
    );
  };

  return (
    <>
      <Box mt="4">
        {action === 'Update' && (
          <Box w={formWidth} mb="4">
            <RadioGroup
              onChange={e => {
                dispatch(actions.updateUpdateFormType(ADMIN_FORM_TYPE[e]));
                resetForm();
              }}
              value={formType}
            >
              <Stack direction="row" justifyContent="space-between">
                <Radio value={ADMIN_FORM_TYPE.view_admins}>
                  <Box display="flex" alignItems="center">
                    <Text mr="1">View admins</Text>
                  </Box>
                </Radio>
                <Radio value={ADMIN_FORM_TYPE.add_existing_user}>
                  <Box display="flex" alignItems="center">
                    <Text mr="1">Add Existing User</Text>
                  </Box>
                </Radio>
                <Radio value={ADMIN_FORM_TYPE.add_new_user}>
                  <Box display="flex" alignItems="center">
                    <Text mr="1">Add New User</Text>
                  </Box>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        )}

        <Form
          isLoading={orgAdminGroupById.loading}
          formValidation={schemaForm}
          schema={{
            ...(action === 'Update'
              ? {
                  admins: {
                    type: 'react-select',
                    label: 'Current Organization Admins',
                    isDisabled: formType !== ADMIN_FORM_TYPE.view_admins,
                    value: {
                      label: username,
                      value: admin_users.find(a => a.username === username),
                    },
                    onChange: e => {
                      if (isViewForm) {
                        updateUserDetails(e.value);
                      }
                    },
                    options: getOptions(admin_users, 'username'),
                    styles: { control: { h: '20', mt: '6', mb: '-1' } },
                  },
                }
              : {}),
            email: {
              type: 'text',
              htmlInputType: 'email',
              label: 'Email ID',
              placeholder: 'example@eg.com',
              value: email,
              isRequired: true,
              isDisabled: isViewForm,
              error:
                (isAddExistingUserForm || isAddNewUserForm) &&
                !isCreateAction &&
                admin_users.find(a => a.email === email)
                  ? 'Already an existing admin!'
                  : '',
              onChange: email => {
                setIsEmailDirty(true);
                updateUserField('email')(email);
              },
              rightInputElement:
                !isViewForm && !isCreateAction && !isAddNewUserForm
                  ? {
                      width: '3rem',
                      children: (
                        <Button
                          size={'xs'}
                          onClick={handleEmailValidation}
                          isLoading={userForm.loading}
                          _hover={{ cursor: 'pointer' }}
                          mr={1}
                        >
                          {isEmailDirty ? (
                            'Verify'
                          ) : userForm.error ? (
                            <CloseIcon />
                          ) : (
                            <AiOutlineCheck />
                          )}
                        </Button>
                      ),
                    }
                  : {},
              tooltip: 'Enter valid email',
            },

            ...(isAddNewUserForm || isCreateAction
              ? {
                  password: {
                    type: 'text',
                    htmlInputType: 'password',
                    label: 'Password',
                    placeholder: 'Password',
                    value: password,
                    tooltip:
                      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
                    onChange: updateUserField('password'),
                  },
                }
              : {}),
            full_name: {
              type: 'object',
              label: 'Name',
              isRequired: true,
              properties: {
                first_name: {
                  type: 'text',
                  placeholder: 'First Name',
                  isRequired: true,
                  isDisabled: isViewForm || isAddExistingUserForm,
                  value: first_name,
                  tooltip: 'Only alphanumeric, space, period, hyphen allowed',
                  onChange: updateUserField('first_name'),
                },
                last_name: {
                  type: 'text',
                  placeholder: 'Last Name',
                  isRequired: true,
                  isDisabled: isViewForm || isAddExistingUserForm,
                  value: last_name,
                  tooltip: 'Only alphanumeric, space, period, hyphen allowed',
                  onChange: updateUserField('last_name'),
                },
              },
              styles: {
                propertyContainer: {
                  w: 'full',
                },
              },
            },
            username: {
              type: 'text',
              label: 'Username',
              placeholder: 'Username',
              isDisabled: isViewForm || isAddExistingUserForm,
              isRequired: true,
              tooltip: 'Only alphanumeric, underscore, period allowed',
              value: username,
              onChange: updateUserField('username'),
            },
          }}
          buttonOptions={{
            reset: {
              isVisible: true,
              name: 'Cancel',
              onClick: () => handleReset?.(),
            },
            back: {
              isVisible: true,
              onClick: () => handleBack?.(),
            },
            submit: {
              name: action,
              isLoading,
            },
          }}
          styles={styles}
          handleSubmit={adminHandleSubmit}
        />
      </Box>
    </>
  );
}
