import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GroupIcon = props => (
  <Icon viewBox="0 0 32 32" w="full" h="full" color="inherit" {...props}>
    <path d="M30,30H28V25a5.0057,5.0057,0,0,0-5-5V18a7.0078,7.0078,0,0,1,7,7Z" />
    <path d="M22,30H20V25a5.0059,5.0059,0,0,0-5-5H9a5.0059,5.0059,0,0,0-5,5v5H2V25a7.0082,7.0082,0,0,1,7-7h6a7.0082,7.0082,0,0,1,7,7Z" />
    <path d="M20,2V4a5,5,0,0,1,0,10v2A7,7,0,0,0,20,2Z" />
    <path d="M12,4A5,5,0,1,1,7,9a5,5,0,0,1,5-5m0-2a7,7,0,1,0,7,7A7,7,0,0,0,12,2Z" />
  </Icon>
);
