import React, { FC, memo, useCallback, useEffect, useReducer } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { AddButton } from 'components/DataEntry';
import { PolicyIcon } from 'components/Icons';
import { PoliciesTableHeader } from 'containers/PolicyHub/Components/PoliciesTableHeader/PoliciesTableHeader';
import { policyTypeOptions } from 'containers/PolicyHub/Components/PoliciesTableHeader/utils';
import { PoliciesTable } from 'containers/PolicyHub/Policies/PoliciesTable';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const Policies: FC = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { policies } = useSelector(selectPolicyHub);

  const { category } = useSearchParams();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: {},
      alertCategory: {},
      currentRecord: {},
      searchTerm: '',
      severity: { label: 'All Severities', value: undefined },
      policyType: policyTypeOptions[0],
    },
  );

  const getPolicies = useCallback(() => {
    dispatch(
      actions.getPolicies({
        q: {
          alertCategory: state.alertCategory?.value || undefined,
          resourceType: state.resourceType?.value || undefined,
          severity: state.severity?.value || undefined,
          page: policies.page.info.page_number,
          size: policies.page.info.page_size,
          searchTerm: !!state.searchTerm ? state.searchTerm : undefined,
          isCustom:
            state.policyType?.value === 'custom'
              ? true
              : state.policyType?.value === 'system'
              ? false
              : undefined,
        },
      }),
    );
  }, [
    dispatch,
    state.searchTerm,
    state.alertCategory,
    state.resourceType,
    policies.page.info,
    state.policyType,
    state.severity,
  ]);

  // get policies
  useDeepCompareEffect(() => {
    getPolicies();
  }, [getPolicies]);

  useEffect(() => {
    if (!!category) updateState({ alertCategory: category });
  }, [category]);

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  return (
    <Card styles={{ card: { w: 'full', h: 'full', flex: 1 } }}>
      <Stack w="full" h="full" spacing={4}>
        <Box>
          <PageHeaderWithIcon
            label={'Policies'}
            reversed
            icon={<PolicyIcon />}
          />
        </Box>
        <HStack w="full">
          <PoliciesTableHeader
            resourceType={state.resourceType}
            alertCategory={state.alertCategory}
            policyType={state.policyType}
            severity={state.severity}
            updateValue={updateValue}
            action="Show"
          />
          <Box>
            <AddButton
              onClick={() => navigate('/policy-hub/policy/add')}
              label={'Add Policy'}
            />
          </Box>
        </HStack>
        <PoliciesTable />
      </Stack>
    </Card>
  );
});
