import {
  BlueprintApiAddExceptionRequest,
  BlueprintApiRemoveExceptionRequest,
  BlueprintExceptionRead,
} from '@ariksa/compliance-policies/api';
import {
  ContextApiGetRiskContextResRequest,
  RiskContextResponse,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsPaginatedResponse,
  AlertPaginatedResponse,
  AlertsApiGetAggregatedAlertsRequest,
  AlertsApiGetAlertsRequest,
  AlertsApiGetAlertSummaryRequest,
  AlertSummaryResponse,
  ClientResponse,
  ClientsApiGetClientDetailsRequest,
  ClientsApiGetJiraProjectDetailsRequest,
  ClientsApiGetTicketFieldsRequest,
  ClientsApiNotifyClientRequest,
  SnoozedAlertsApiAddSnoozedAlertRequest,
  SnoozedAlertsApiStopSnoozeRequest,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  ComplianceService,
  InventoryService,
  NotificationService,
} from 'api/index';

import { actions } from './slice';

export function* alertsSaga() {
  yield takeLatestAction(actions.getAlertCategories, doGetAlertCategories);
  yield takeLatestAction(actions.getTicketFields, doGetTicketFields);
  yield takeLatestAction(actions.getTicketInfo, doGetTicketInfo);
  yield takeLatestAction(actions.createTicket, doCreateTicket);
  yield takeLatestAction(actions.createNotification, doCreateNotification);
  yield takeLatestAction(actions.addException, doAddException);
  yield takeLatestAction(actions.getClients, doGetClients);

  yield takeLatestAction(actions.stopSnooze, doStopSnooze);

  yield takeLatestAction(
    actions.getAggregatedFindings,
    doGetAggregatedFindings,
  );
  yield takeLatestAction(actions.getAlerts, doGetAlerts);
  yield takeLatestAction(actions.snoozeAlert, doSnoozeAlert);
  yield takeLatestAction(actions.removeException, doRemoveException);
  yield takeLatestAction(
    actions.getIndividualRiskContext,
    doGetIndividualAlertRiskContext,
  );
}

export function* doGetIndividualAlertRiskContext(
  ctx: QueryContext<
    Record<string, RiskContextResponse[]>,
    ContextApiGetRiskContextResRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Context.getRiskContextRes(ctx.params),
    {
      errorMsg: 'Failed to get risk context!',
    },
  );
}

export function* doAddException(
  ctx: QueryContext<BlueprintExceptionRead, BlueprintApiAddExceptionRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Blueprint.addException(ctx.params),
    {
      errorMsg: 'Failed to add exception!',
      successMsg: 'Exception added successfully.',
    },
  );
}

function* doGetClients(
  ctx: QueryContext<ClientResponse[], ClientsApiGetClientDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.getClientDetails(ctx.params),
    { errorMsg: 'Failed to get clients.' },
  );
}

function* doGetTicketInfo(
  ctx: QueryContext<any, ClientsApiGetTicketFieldsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.getTicketFields(ctx.params),
    { errorMsg: 'Failed to get ticket info.' },
  );
}
function* doGetTicketFields(
  ctx: QueryContext<any, ClientsApiGetJiraProjectDetailsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.getJiraProjectDetails(ctx.params),
    { errorMsg: 'Failed to get ticket fields.' },
  );
}
function* doCreateTicket(
  ctx: QueryContext<any, ClientsApiNotifyClientRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.notifyClient(ctx.params),
    {
      //errorMsg: 'Failed to create ticket!',
      //successMsg: 'Ticket created successfully.',
    },
  );
}
function* doCreateNotification(
  ctx: QueryContext<any, ClientsApiNotifyClientRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.notifyClient(ctx.params),
    {
      //errorMsg: 'Failed to send notification.',
      //successMsg: 'Notification sent successfully.',
    },
  );
}

/*Get Aggregated Findings*/
export function* doGetAggregatedFindings(
  ctx: QueryContext<
    AggregatedAlertsPaginatedResponse,
    AlertsApiGetAggregatedAlertsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAggregatedAlerts(ctx.params),
    { errorMsg: 'Failed to get aggregate findings!' },
  );
}

/*Get Alerts*/
function* doGetAlerts(
  ctx: QueryContext<AlertPaginatedResponse, AlertsApiGetAlertsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlerts(ctx.params),
    { errorMsg: 'Failed to get alerts' },
  );
}

function* doStopSnooze(
  ctx: QueryContext<any, SnoozedAlertsApiStopSnoozeRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.SnoozedAlerts.stopSnooze(ctx.params),
    {
      errorMsg: 'Failed to remove snooze!',
      successMsg: 'Snooze removed successfully.',
    },
  );
}
function* doSnoozeAlert(
  ctx: QueryContext<any, SnoozedAlertsApiAddSnoozedAlertRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.SnoozedAlerts.addSnoozedAlert(ctx.params),
    {
      errorMsg: 'Failed to snooze alert!',
      successMsg: 'Alert snoozed successfully.',
    },
  );
}

function* doRemoveException(
  ctx: QueryContext<any, BlueprintApiRemoveExceptionRequest>,
) {
  yield call(ctx.fetch, () =>
    ComplianceService.Blueprint.removeException(ctx.params),
  );
}

export function* doGetAlertCategories(
  ctx: QueryContext<AlertSummaryResponse, AlertsApiGetAlertSummaryRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAlertSummary(ctx.params),
  );
}
