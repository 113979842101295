import React, { FC, useEffect, useState } from 'react';

import { Box, Center, Stack, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon, Tag } from 'components/DataDisplay';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { CloudtrailIcon } from 'components/Icons';
import { DayPicketModal } from 'containers/EventLog/DayPicketModal';
import { EventLogFilters } from 'containers/EventLog/EventLogFilters';
import { selectEventLog } from 'containers/EventLog/selectors';
import { actions } from 'containers/EventLog/slice';

interface IEventLogHeader {}

export const EventLogHeader: FC<IEventLogHeader> = props => {
  const [searchTerm, setSearchTerm] = useState('');
  const { eventLogFilters } = useSelector(selectEventLog);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.updateFilters({
        searchTerm,
      }),
    );
  }, [dispatch, searchTerm]);

  const handleDateRangeSelect = days => {
    dispatch(
      actions.updateFilters({
        dates: days,
      }),
    );
  };

  const renderDateTag = date => (
    <Tag label={dayjs(date).format('YYYY-MM-DD')} />
  );

  return (
    <Stack>
      <PageHeaderWithIcon
        label="Event Log"
        icon={
          <Center h="full" color="iconColor">
            <CloudtrailIcon color={'white'} />
          </Center>
        }
      />

      <HStack justify="space-between">
        <Box w="2xl">
          <SearchBox
            onChange={value => {
              setSearchTerm(value);
              if (!value) setSearchTerm('');
            }}
            value={searchTerm}
            // onSearch={() => dispatch(actions.updateSearchTerm(searchTerm))}
            placeholder={'Search...'}
            onClickClose={() => {
              setSearchTerm('');
            }}
          />
        </Box>
        <HStack pos={'relative'}>
          <HStack pos="absolute" right={0} zIndex={1000}>
            <EventLogFilters />
            {eventLogFilters.dates && (
              <HStack>
                {renderDateTag(eventLogFilters.dates?.from)}
                {renderDateTag(eventLogFilters.dates?.to)}
              </HStack>
            )}
            <DayPicketModal onSelect={handleDateRangeSelect} />
          </HStack>
        </HStack>
      </HStack>
    </Stack>
  );
};
