import React from 'react';

import { Icon } from '@chakra-ui/react';

export const MemCachedIcon = props => (
  <Icon viewBox="0 0 254 254" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M0,171.18976 L0,82.1710911 C0,10.2714062 10.2607175,2.63917525e-05 82.0863208,2.63917525e-05 L171.274821,2.63917525e-05 C243.10016,2.63917525e-05 253.360825,10.2714062 253.360825,82.1710911 L253.360825,171.18976 C253.360825,243.089471 243.10016,253.360851 171.274821,253.360851 L82.0863208,253.360851 C10.2607175,253.360851 0,243.089471 0,171.18976 L0,171.18976 Z"
        fill="#fff"
      ></path>
      <g transform="translate(45.789223, 47.097648)">
        <path
          d="M8.89120419,0.65492176 C-3.56241993,79.582765 2.95305984,153.479665 2.95305984,153.479665 L41.880895,153.479665 C38.1768996,133.776247 24.8889953,43.7562511 35.9427507,43.4590456 C41.8666989,44.3993442 68.9324415,119.83018 68.9324415,119.83018 C68.9324415,119.83018 74.8930954,119.087912 80.8912044,119.087912 C86.8893135,119.087912 92.8499674,119.83018 92.8499674,119.83018 C92.8499674,119.83018 119.915713,44.3993363 125.839658,43.4590456 C136.893437,43.75623 123.605496,133.776271 119.901514,153.479665 L158.829349,153.479665 C158.829349,153.479665 165.344826,79.5827413 152.891205,0.65492176 L116.849967,0.65492176 C109.98997,0.736057926 83.8902893,46.510592 80.8912044,46.510592 C77.8921196,46.510592 51.7924391,0.736057926 44.9324414,0.65492176 L8.89120419,0.65492176 L8.89120419,0.65492176 Z"
          fill="#fff"
        ></path>
        <path
          d="M73.2135967,144.266563 C73.2135967,149.354829 69.0887422,153.479684 64.0004764,153.479684 C58.9122105,153.479684 54.787356,149.354829 54.787356,144.266563 C54.787356,139.178297 58.9122105,135.053443 64.0004764,135.053443 C69.0887422,135.053443 73.2135967,139.178297 73.2135967,144.266563 L73.2135967,144.266563 Z"
          //fill="#fff"
        ></path>
        <path
          d="M106.995038,144.266563 C106.995038,149.354829 102.870184,153.479684 97.7819178,153.479684 C92.6936519,153.479684 88.5687974,149.354829 88.5687974,144.266563 C88.5687974,139.178297 92.6936519,135.053443 97.7819178,135.053443 C102.870184,135.053443 106.995038,139.178297 106.995038,144.266563 L106.995038,144.266563 Z"
          //fill="#fff"
        ></path>
        <path
          d="M151.056318,3.26419253 C156.674191,41.2901292 157.992284,77.7849008 157.888889,104.969467 C157.783132,132.775271 156.190174,150.840489 156.190174,150.840489 L123.423113,150.840489 L119.901514,153.479665 L158.829349,153.479665 C158.829349,153.479665 165.344826,79.5827413 152.891205,0.65492176 L151.056318,3.26419253 Z M47.9015136,2.3044063 C58.1279219,13.6798033 75.8152734,43.8714168 78.2520292,43.8714168 C71.7678052,35.5785712 55.8405197,9.12270769 47.9015136,2.3044063 L47.9015136,2.3044063 Z M33.3035754,40.8198704 C22.2498279,41.1170864 35.5377376,131.137064 39.2417198,150.840489 L5.25962777,150.840489 L2.95305984,153.479665 L41.880895,153.479665 C38.1956695,133.876095 25.0246515,44.6553996 35.7778022,43.4590456 C34.7764595,41.9551776 33.9115992,40.9163797 33.3035754,40.8198704 L33.3035754,40.8198704 Z M123.200483,40.8198704 C117.276537,41.760161 90.2107921,117.191005 90.2107921,117.191005 C90.2107921,117.191005 84.2501382,116.448737 78.2520292,116.448737 C74.7030589,116.448737 71.6477332,116.667419 69.5816681,116.859946 L68.9324415,119.83018 C68.9324415,119.83018 74.8930954,119.087912 80.8912044,119.087912 C86.8893135,119.087912 92.8499674,119.83018 92.8499674,119.83018 C92.8499674,119.83018 119.711897,44.8941711 125.757184,43.4590456 C125.105764,41.8088881 124.300927,40.8494661 123.200483,40.8198704 L123.200483,40.8198704 Z"
          //opacity="0.1"
          // fill="#000000"
        ></path>
        <path
          d="M8.89120419,0.65492176 C-3.56241993,79.582765 2.95305984,153.479665 2.95305984,153.479665 L5.24520996,150.903267 C4.07228657,133.468944 0.938595837,70.4220715 11.5303795,3.29409703 L47.5716167,3.29409703 C48.3351222,3.30307022 49.3589005,3.9286181 50.5406889,4.94358157 C48.1807489,2.31848894 46.218691,0.670133967 44.9324414,0.65492176 L8.89120419,0.65492176 Z M116.849967,0.65492176 C109.98997,0.736050008 83.8902893,46.510592 80.8912044,46.510592 C82.1142247,48.0747415 83.1074387,49.1497673 83.5303797,49.1497673 C86.5294646,49.1497673 112.629145,3.37517249 119.489143,3.29409703 L151.294812,3.29409703 L152.891205,0.65492176 L116.849967,0.65492176 Z M38.4169775,46.0982209 C47.171655,59.2464627 68.9324415,119.83018 68.9324415,119.83018 L69.5691795,116.875985 C64.1965312,102.143884 43.725214,46.9145996 38.5819259,46.0982209 C38.5259095,46.0997279 38.4717483,46.092127 38.4169775,46.0982209 L38.4169775,46.0982209 Z M128.478833,46.0982209 C134.351057,61.0754455 123.235098,135.74661 119.901514,153.479665 L123.432825,150.723373 C127.940463,124.276958 138.794686,46.375651 128.478833,46.0982209 L128.478833,46.0982209 Z"
          // opacity="0.3"
          //fill="#FFFFFF"
        ></path>
      </g>
    </g>{' '}
  </Icon>
);
