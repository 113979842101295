import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { useUserPermissionsContext } from './UserPermissionsContext';

interface IFilterComponent extends BoxProps {
  // if window path includes excludePath the children will not be rendered
  excludePaths?: string[];
  // if window path includes includePath the children will be rendered
  includePaths?: string[];
  // if the user has permission to visit the path the children will be rendered
  filterPath?: string;
  // if the user has permission to the key the children will be rendered
  filterKey?: string;

  // if show is false the children will not be rendered
  show?: boolean;

  showFn?(): boolean;
}

// hides component based on condition
export const FilterComponent: FC<IFilterComponent> = props => {
  const {
    excludePaths = [],
    includePaths = [],
    filterPath = '/',
    filterKey = '',
    show = true,
    showFn = () => true,
    ...rest
  } = props;

  const { userPermission } = useUserPermissionsContext();
  const { pathname } = window.location;

  if (!showFn?.()) return null;

  if (!show) return null;

  if (excludePaths.some(ep => pathname.includes(ep))) {
    return null;
  }

  if (!isEmpty(includePaths) && !includePaths.includes(pathname)) return null;

  const key = filterKey || filterPath;

  // console.log(key, '-', userPermission.includesPath(key));

  if (!userPermission.includesPath(key)) {
    return null;
  }

  return <Box {...rest} />;
};
