import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Assets, Container, Sprite, Text } from 'pixi.js';

import { getWhiteMapIcon } from 'components/Icons/MapIcons/getWhiteMapIcon';
import { CommonNode } from 'components/Visualization/PixiGraph/common/Node';
import { NodeElements } from 'components/Visualization/PixiGraph/common/NodeElements';
import { nodeColor } from 'components/Visualization/PixiGraph/common/utils/nodeColor';
import { nodeInfo } from 'components/Visualization/PixiGraph/common/utils/nodeInfo';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AccessMapEdge } from 'components/Visualization/PixiGraph/maps/AccessMap/elements/Edge';
import {
  colors,
  headerTextStyle,
  RECT_NODE_WIDTH,
  SQUARE_NODE_SIZE,
} from 'components/Visualization/PixiGraph/style';
import { limitedString } from 'utils/string';

import { AGraph } from '../../../core/Graph';
import { AGraphNode, AGraphNodeProps } from '../../../core/Node';

interface AccessMapHeaderNodeProps extends AGraphNodeProps {
  header: string;
  row: number;
  col: number;
}

export class AccessMapHeaderNode extends AGraphNode {
  header: string;
  row: number;
  col: number;

  get isPagination() {
    return false;
  }

  get isHeader() {
    return true;
  }

  get headerName() {
    return this.header;
  }

  constructor(props: AccessMapHeaderNodeProps) {
    super(props);
    this.header = props.header;
    this.row = props.row;
    this.col = props.col;
    this.w = RECT_NODE_WIDTH;
    this.h = SQUARE_NODE_SIZE * 0.8;
  }

  render(g: Graphics, container: Container): void {
    const { x, y, w, h, header, data } = this;

    const line_color = nodeColor('normal');
    g.lineStyle(1, line_color, 1);
    g.beginFill(0x5c3dde, 1);
    g.drawRoundedRect(x, y, w, h, 6);
    g.endFill();

    const name = new Text(
      limitedString(header ?? '', 18) + ` (${data.count ?? 0})`,
      { ...headerTextStyle, fontSize: 15, fill: '#fff' },
    );
    name.x = x + 40;
    name.y = y + h / 2 - name.height / 2;
    container.addChild(name);

    Assets.loader.load(getWhiteMapIcon(data.native_name)).then(texture => {
      if (!texture) return;
      const icon = Sprite.from(texture);
      container.addChild(icon);
      const scale = (0.5 * h) / icon.width;
      icon.scale.set(scale, scale);
      icon.x = x + 12;
      icon.y = y + h / 2 - icon.height / 2;
    });
  }
}

export class AccessMapResourceNode extends AGraphNode {
  showResourceType: boolean;

  constructor(props: AGraphNodeProps) {
    super(props);

    this.w = props.is_target ? SQUARE_NODE_SIZE : RECT_NODE_WIDTH;
    this.h = SQUARE_NODE_SIZE;
    if (this.data.header !== 'Resource Type') {
      if (props.is_target) {
        this.data.info =
          this.data.ariksaType?.resource_alias ?? this.data.native_name;
      }
    }

    this.showResourceType = false;
  }

  get hasMenu() {
    return this.data.header !== 'Resource Type';
  }

  get isPagination() {
    return false;
  }

  get isResourceNode() {
    return true;
  }

  get isHeader(): boolean {
    return false;
  }

  get headerName(): string {
    return this.data.header ?? '';
  }

  render(g: Graphics, container: Container): void {
    if (!this.isVisible) return;
    CommonNode.render(this, g, container);
    NodeElements.renderSeverities(this, g);
    NodeElements.renderContext(this, g, container);
  }

  renderActive(g: Graphics) {
    CommonNode.renderActive(this, g);
  }

  renderHover(g: Graphics) {
    CommonNode.renderHover(this, g);
  }

  addInteractiveElement(
    graph: AGraph<AccessMapNode, AccessMapEdge>,
    index: number,
  ) {
    if (!this.isVisible) return;
    super.addInteractiveElement(graph, index);

    const { data } = this;

    if (data.severities) {
      NodeElements.addInteractiveSeverityElement(graph, this, index);
    }

    if (data.riskContext) {
      NodeElements.addInteractiveRiskContextElement(graph, this, index);
    }
  }
}

export interface AccessMapPaginationNodeProps extends AGraphNodeProps {
  header: string;
  totalCount: number;
  visibleCount: number;
}

export class AccessMapPaginationNode extends AGraphNode {
  header: string;
  totalCount: number = 0;
  visibleCount: number = 10;

  get isPagination() {
    return true;
  }

  get isHeader() {
    return false;
  }

  get headerName() {
    return this.header;
  }

  constructor(props: AccessMapPaginationNodeProps) {
    super(props);
    this.header = props.header;
    this.totalCount = props.totalCount;
    this.visibleCount = props.visibleCount;
    this.w = SQUARE_NODE_SIZE;
    this.h = SQUARE_NODE_SIZE;
  }

  get hasMenu() {
    return false;
  }

  update() {}

  render(g: Graphics, container: Container): void {
    const { x, y, w, h, header, data } = this;
    g.lineStyle(1, 0xaaaaaa, 1);
    g.moveTo(x, y);
    g.lineTo(x + w * 0.6, y);
    g.lineTo(x + w, y + h / 2);
    g.lineTo(x + w * 0.6, y + h);
    g.lineTo(x, y + h);
    g.closePath();

    g.drawCircle(x + 12, y + h / 2, 1);
    g.drawCircle(x + 16, y + h / 2, 1);
    g.drawCircle(x + 20, y + h / 2, 1);
    g.endFill();

    // const name = new Text(limitedString('Show 10 more', 12), nameTextStyle);
    // name.x = x + 10;
    // name.y = y + 4;
    // container.addChild(name);

    // const count = new Text(
    //   `Showing: ${this.visibleCount}/${this.totalCount}`,
    //   nameTextStyle,
    // );
    // count.x = x + 10;
    // count.y = y + 26;
    // container.addChild(count);
  }

  renderHover(g: Graphics) {
    const { x, y, w, h, header, data } = this;
    g.lineStyle(1, colors.primary, 1);
    g.moveTo(x, y);
    g.lineTo(x + w * 0.6, y);
    g.lineTo(x + w, y + h / 2);
    g.lineTo(x + w * 0.6, y + h);
    g.lineTo(x, y + h);
    g.closePath();

    g.drawCircle(x + 12, y + h / 2, 1);
    g.drawCircle(x + 16, y + h / 2, 1);
    g.drawCircle(x + 20, y + h / 2, 1);
    g.endFill();
  }

  addInteractiveElement(graph: AGraph<AGraphNode, AGraphEdge>, index: number) {
    if (this.totalCount <= this.visibleCount) return;
    super.addInteractiveElement(graph, index);
  }
}

export type AccessMapNode =
  | AccessMapHeaderNode
  | AccessMapResourceNode
  | AccessMapPaginationNode;
