import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import capitalize from 'lodash/capitalize';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorMap } from 'theme';

import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';

interface Props {
  critical: number;
  high: number;
  medium: number;
  low: number;
  category?: string;
}

export const AlertCount: FC<Props> = props => {
  const { critical, medium, low, high, category } = props;
  const { currentCategory } = useSelector(selectFindingsOverview);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalAlerts = (type: string, value: number) => (
    <Box h="full">
      <HStack fontSize="md" w="full" h="full">
        <Box bg={type} w={1.5} h="full" />
        <HStack
          justify={'space-between'}
          w="full"
          h="full"
          _hover={{ bg: colorMap.primary(50), cursor: 'pointer' }}
          pl={3}
          onClick={() => {
            if (currentCategory === 'Overview') {
              dispatch(actions.updateCurrentCategory('AllFindings'));
            }

            navigate(
              `/findings?tab=AllFindings&severity=${type?.toUpperCase()}&redirect=true`,
            );
          }}
        >
          <Flex minW={16} align="center" w="full" fontWeight={600}>
            {capitalize(type)}
          </Flex>
          <Center w="full">{value}</Center>
        </HStack>
      </HStack>
    </Box>
  );

  return (
    <Stack justify="space-between" h="full" spacing={4}>
      {totalAlerts('critical', critical)}
      {totalAlerts('high', high)}
      {totalAlerts('medium', medium)}
      {totalAlerts('low', low)}
    </Stack>
  );
};
