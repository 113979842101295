import React, { FC } from 'react';

import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { PrimaryButton } from 'components/DataEntry';
import { contentStyles } from 'components/FeatureDemo/styles';
import {
  CloseCircleOutlineIcon,
  CriticalDataIcon,
  DataScanIcon,
  TriangleRightIcon,
} from 'components/Icons';
import { AriksaLogoOnlyIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoOnlyIcon';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';

export const NoDataScannerDeployed: FC = () => {
  const navigate = useNavigate();
  const { accountId } = useAccessBoundary();

  const list = [
    {
      header: 'DEPLOY OUTPOST FOR DATA',
      description:
        'Deploy Outpost for Data in every region where your data is present. ' +
        'Outpost scans buckets, databases, and data clouds such as Snowflake.',
      icon: <DataScanIcon />,
      button: {
        tooltip: 'Click here to deploy Outpost for Data',
        label: 'DEPLOY',
        onClick: () =>
          navigate(
            '/setup/accounts' +
              (!!accountId ? '/add/data-outpost/' + accountId : ''),
          ),
      },
    },
    {
      header: 'SECURE SENSITIVE DATA',
      description:
        'Ariksa automatically analyzes your documents and deep scans to classify ' +
        'data in your structured, semi-structured and unstructured data sources that are accessible',
      icon: <CriticalDataIcon />,
      button: {
        label: 'DATA ANALYZED',
      },
    },
  ];

  return (
    <Box px={32}>
      <Stack {...contentStyles.root} px={8} w="full">
        <HStack>
          <Flex {...contentStyles.logoWrapper}>
            {/* Logo */}
            <Center my="auto" w={12} h={12}>
              <AriksaLogoOnlyIcon />
            </Center>
          </Flex>
          <Box fontSize="2xl">Data Security With Ariksa</Box>
        </HStack>
        <Stack {...contentStyles.content}>
          <HStack w="full" spacing={8}>
            {map(list, (o, index) => (
              <>
                <Stack spacing={0} h="full">
                  <Box
                    w="20rem"
                    h="20rem"
                    //border="1px solid"
                    //borderColor="primary"
                    borderRadius={6}
                    bg="white"
                    pt={7}
                    pb={7}
                    pl={7}
                    pr={6}
                    boxShadow={`1px 6px 6px ${customTheme.colors.gray['100']}`}
                  >
                    <Stack w="full" justify="space-between" h="full">
                      <Stack spacing={4} w="full">
                        <HStack
                          fontWeight={600}
                          fontSize="md"
                          color="primary"
                          w="full"
                        >
                          <Center boxSize={6}>
                            <Center>{o.icon}</Center>
                          </Center>
                          <Center>{o.header}</Center>
                        </HStack>
                        <Box borderBottom="1px solid" borderColor="primary" />
                        <Box fontSize="sm" pt={2}>
                          {o.description}
                        </Box>
                      </Stack>
                      <Center>
                        <PrimaryButton
                          tooltip={o.button?.tooltip}
                          bg="critical"
                          color="white"
                          borderColor="critical"
                          _hover={{}}
                          _active={{}}
                          _focus={{}}
                          leftIcon={<CloseCircleOutlineIcon />}
                          iconSize={6}
                          cursor={o.button?.onClick ? 'pointer' : 'default'}
                          onClick={o.button?.onClick}
                        >
                          {o.button.label}
                        </PrimaryButton>
                      </Center>
                    </Stack>
                  </Box>
                </Stack>
                {index < list?.length - 1 && (
                  <Box color="primary" boxSize={16}>
                    <TriangleRightIcon />
                  </Box>
                )}
              </>
            ))}
          </HStack>
        </Stack>
      </Stack>
    </Box>
  );
};
