/**
 *
 * Email Integration
 *
 */

import React, { FC, useState } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions as clientActions } from '../../slice';

import { EmailForm } from './EmailForm';

export const CreateEmailGroup: FC = () => {
  const dispatch = useDispatch();
  const [emailIDs, setEmailIDs] = useState<string[]>([]);
  const { onCancel } = useIntegrationsContext();

  const onSubmit = data => {
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        name: data.name,
        client_name: Clients.Email,
        status: true,
        email: {
          email_group: emailIDs,
        },
      },
    };
    dispatch(
      clientActions.createClient({
        q: payload,
        onSuccess: () => onCancel(),
      }),
    );
  };

  return (
    <EmailForm
      onSubmit={onSubmit}
      emailIDs={emailIDs}
      setEmailIDs={setEmailIDs}
    />
  );
};
