import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { CreateRole } from './components/CreateRole';
import { RolesTable } from './components/RolesTable';
import { rolesSaga } from './saga';
import { reducer, sliceKey } from './slice';

interface IRoles {}

export const Roles: FC<IRoles> = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: rolesSaga });

  return (
    <Routes>
      <Route path="/setup/roles/add" element={<CreateRole />} />
      <Route path="/setup/roles/" element={<RolesTable />} />
    </Routes>
  );
};
