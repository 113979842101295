/**
 *
 * Mobile Main Menu
 *
 */
import React, { FC, useState } from 'react';

import {
  Box,
  Collapse,
  Flex,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import map from 'lodash/map';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-use';

import { routeConfig } from '../../../../../hooks/RouteConfig/routeConfig';
import { mobileMenuItemStyles as styles } from '../styles';
import { MainMenuProps } from '../utils';

interface Props {
  tab: MainMenuProps;
  onClose: () => void;
}

export const MainMenu: FC<Props> = props => {
  const { tab } = props;
  const current = useLocation().pathname;
  const [show, setShow] = useState(false);
  const secondary = routeConfig[tab.key]?.navBar;
  const modeStyles = useColorModeValue(styles.light, styles.dark);
  const handleToggle = () => setShow(!show);
  const title = item => (
    <Flex align="center">
      <Box px={4}>{item.icon}</Box>
      <Box fontSize="14px">{item.title}</Box>
    </Flex>
  );

  const selectedPrimary = current && current.startsWith(tab.key);
  const selectedSecondary = item => current && current.startsWith(item);
  return (
    <>
      <Box
        {...styles.primaryCommon}
        {...modeStyles.title}
        {...(selectedPrimary ? modeStyles.selected.primary : {})}
      >
        {secondary ? (
          <Flex onClick={handleToggle} align="center" justify="space-between">
            {title(tab)}
            {show ? <FiChevronUp /> : <FiChevronDown />}
          </Flex>
        ) : (
          <Link to={tab.url} key={tab.key}>
            {title(tab)}
          </Link>
        )}
      </Box>
      <Collapse in={show} {...modeStyles.collapse}>
        <Stack spacing={0}>
          {map(secondary?.list, (each, index) => (
            <Box
              key={`${index}-sec-menu`}
              {...styles.secondaryCommon}
              {...modeStyles.title}
              {...(selectedSecondary(`${secondary?.base}${each.url}`)
                ? modeStyles.selected.secondary
                : {})}
            >
              <Link to={`${secondary?.base}${each.url}`}>{title(each)}</Link>
            </Box>
          ))}
        </Stack>
      </Collapse>
    </>
  );
};
