import { useEffect } from 'react';

import { SearchTermCategory } from '@ariksa/inventory-core/api';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { parseQuery } from 'containers/PolicyHub/Policies/utils';
import { policyHubSaga } from 'containers/PolicyHub/saga';
import * as policyHubSlice from 'containers/PolicyHub/slice';
import { actions as policyActions } from 'containers/PolicyHub/slice';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { searchQueryMapping } from 'containers/Visibility/SecurityGraphNext/utils/queryMapping';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjector } from 'utils/inject';

export const getIsRedirected = (params: any) => {
  return params.redirected;
};

export const useCollectRedirectInfo = () => {
  useInjector(policyHubSlice.sliceKey, policyHubSlice.reducer, policyHubSaga);

  const dispatch = useDispatch();
  const {
    redirectInfo,
    isRedirected: prevIsRedirected,
    selectedSearchQueryTerm,
    activeSearchQueryTerm,
    redirectedSearchQueryTerm,
  } = useSelector(selectSecurityGraphNext);
  const { updateActiveResource } = useActiveResourceActions();

  const location = useLocation() as any;

  useEffect(() => {
    const { state } = location;
    const { wrapper } = state || {};
    if (isEmpty(wrapper)) return;

    const query = parseQuery(wrapper, []);

    console.log(wrapper?.resource?.name, query);
    dispatch(policyActions.setSearchQuery(query));
  }, [dispatch, location]);

  const params = useSearchParams();

  useEffect(() => {
    if (params.resource_type && params.source_resource_uuid) {
      dispatch(
        actions.updateFilters({
          resource: [
            {
              key: params.source_resource_uuid,
              resourceId: params.source_resource_uuid,
              resourceType: params.resource_type,
            },
          ],
        }),
      );
    }
  }, [
    dispatch,
    params.resource_id,
    params.resource_type,
    params.source_resource_uuid,
  ]);

  useEffect(() => {
    const isRedirected = getIsRedirected(params);
    const { alert } = location.state ?? ({} as any);
    // if (isRedirected && redirectInfo?.source_id !== params.resource_id) return;
    const category =
      params?.map_type === 'access'
        ? SearchTermCategory.Permission
        : SearchTermCategory.Posture;

    if (!isRedirected) {
      dispatch(actions.updateRedirectInfo(null));
      dispatch(actions.updateRedirected(false));
      return;
    }

    if (params.alert_id) {
      dispatch(
        actions.updateRedirectInfo({
          account_id: params.account_id,
          source_id: params.resource_id,
          resource_type: params.resource_type,
          alert_rule_id: params.alert_rule_id,
          resource_uuid: params.source_resource_uuid,
          map_type: '',
          identity: false,
          source_tab: '',
          alert_id: params?.alert_id,
          redirect_url: params?.redirect_url,
          alert: alert,
          query: params.query,
          response_level: params.response_level,
          ai_search: params.ai_search,
          search_uuid: params.search_uuid,
        }),
      );
    } else if (params.query) {
      dispatch(
        actions.updateRedirectInfo({
          query: params.query,
          redirect_url: params?.redirect_url,
          source_uuid: params.source_resource_uuid,
          response_level: params.response_level,
          ai_search: params.ai_search,
          search_uuid: params.search_uuid,
        }),
      );
    } else if (params.query_name) {
      dispatch(
        actions.updateSelectedSearchQueryTerm(
          searchQueryMapping?.[params.query_name],
        ),
      );
    } else {
      dispatch(
        actions.updateRedirectInfo({
          query: params.query,
          account_id: params.account_id,
          source_id: params.resource_id,
          source_uuid: params.source_resource_uuid,
          resource_type: params.resource_type,
          map_type: category,
          identity: params?.map_type === 'identity',
          source_tab: params?.source_tab,
          alert_id: params?.alert_id,
          redirect_url: params?.redirect_url,
          response_level: params.response_level,
          ai_search: params.ai_search,
          search_uuid: params.search_uuid,
          sub_category: params.sub_category,
        }),
      );
    }

    // select_entity is set when source entity is selected from the sidebar dropdown
    dispatch(actions.updateRedirected(true));
    dispatch(actions.updateQueryChanged(true));
  }, [location, dispatch, prevIsRedirected, params, redirectInfo?.map_type]);

  useEffect(() => {
    if (
      params.account_id &&
      params.resource_uuid &&
      params.resource_type &&
      params.resource_id
    ) {
      updateActiveResource({
        resourceType: params.resource_type,
        uuid: params.resource_uuid,
        accountId: params.account_id,
        resourceId: params.resource_id,
      });
    }
  }, [
    params.account_id,
    params.resource_id,
    params.resource_type,
    params.resource_uuid,
    updateActiveResource,
  ]);
};
