import React from 'react';

import { Icon } from '@chakra-ui/react';

export const TrustedEntitiesIcon = props => (
  <Icon viewBox="0 0 64 64" w="full" h="full" color="inherit" {...props}>
    <path
      d="M43.5068855,15.4409504c-0.2255974-0.2323999-0.5498009-0.3369007-0.8731003-0.2920008L30.956089,16.9790497
		c-0.1362,0.0214996-0.2616997,0.0696011-0.3719006,0.1380997l-9.164299-1.9574995
		c-0.3348999-0.0752001-0.6865005,0.0341997-0.9267006,0.2813005L8.486289,27.8296509
		c-0.8691001,0.8973999-1.1532998,2.1883984-0.7402,3.3701l1.7285004,4.9403973
		c0.3076,0.8789024,0.8339996,1.6748009,1.5225,2.3018036l1.6754999,1.5261993
		c-0.2772999,0.6707001-0.9285002,2.5587997-0.4352999,4.5830994c1.2909994,5.2979012,4.5928001,8.5370979,10.3906994,10.1944008
		c1.1328011,0.3241997,2.3241997,0.4296989,3.3955002,0.4296989c1.3812008,0,2.5513992-0.1743011,3.1448002-0.2812996
		c0.9283009,0.6287994,2.0076008,0.9745979,3.1188011,0.9745979c0.4766006,0,0.9570999-0.0604973,1.4316978-0.1844978
		c6.508297-1.7245026,10.7879982-5.9498024,12.7438965-12.5466003c0.2342033-0.0388985,0.4595032-0.1385994,0.6203041-0.3381996
		l5.122097-6.3828011c0.0811005-0.1016006,0.1581993-0.205101,0.2313995-0.3106003l3.089901-4.4531002
		c0.892601-1.2852001,0.75-3.0341988-0.3389015-4.1602001L43.5068855,15.4409504z M23.1777897,52.8227501
		c-5.1036015-1.4589996-7.8790007-4.1562004-8.9971008-8.7440987c-0.2414999-0.9911003-0.0801001-1.9682999,0.1051006-2.6408997
		L27.1245899,53.133049C25.9748898,53.21595,24.5036888,53.2020493,23.1777897,52.8227501z M44.7216873,41.9770508
		c-0.2604027,0.9735985-0.5762024,1.8815002-0.9360008,2.7387009l-10.2612991-9.1215019
		c-0.4130974-0.3690987-1.044899-0.3320007-1.4110985,0.0830002c-0.3671989,0.4121017-0.3300991,1.044899,0.0830002,1.4110985
		l10.4980965,9.3320999c0.0609016,0.0543022,0.1324005,0.0848999,0.2013016,0.1216011
		c-0.5214996,0.9244995-1.1152992,1.7673988-1.783802,2.5289993l-11.0815964-9.9729004
		c-0.4102001-0.3681984-1.0419998-0.3358994-1.4120998,0.0742035c-0.3691998,0.4101982-0.3360004,1.0429993,0.0741997,1.4120979
		l10.9942989,9.8944016c-0.7516975,0.6420975-1.5737991,1.2094994-2.4709015,1.6994972
		c-0.0522995-0.0907974-0.1086006-0.1806984-0.1913986-0.2539978l-10.4979992-9.3311005
		c-0.4130993-0.3680992-1.0439987-0.3310013-1.4110985,0.0830002c-0.3672009,0.4120979-0.330101,1.0449982,0.0830002,1.4111977
		l10.1039982,8.9808006c-0.6648979,0.2596016-1.3610992,0.4877014-2.0923004,0.6813011
		c-1.1903992,0.3134995-2.4374981,0.0028992-3.3437977-0.8241997L12.3437891,36.9633484
		c-0.4434004-0.4041977-0.7832003-0.9168968-0.9805002-1.4833984l-1.7294998-4.9403992
		c-0.1620998-0.4619007-0.0508003-0.9678001,0.2890997-1.3183002l11.6211004-11.9903011l11.0478001,2.3593998
		c0.6729012,0.1436005,1.2901001,0.4736004,1.7851982,0.9531002l3.4375,3.3320999
		c0.6708984,0.650301,1.0057983,1.5556011,0.919899,2.4853001c-0.0849991,0.9277-0.580101,1.7567997-1.357399,2.2734013
		l-0.8526001,0.5664997c-2.6542969-2.3799-4.7733974-4.2754002-4.7733974-4.2754002
		c-0.4111004-0.3672009-1.0391006-0.335001-1.4082012,0.0741997c-0.3690987,0.4071999-0.3397999,1.0361004,0.0654011,1.4081993
		l5.2763996,4.8389015c0.107399,0.097599,0.2313995,0.1688995,0.3633003,0.2127991
		c2.6581955,2.3838005,5.6610985,5.0791016,7.5780983,6.8125C44.6689873,39.2046509,45.0888863,40.6235504,44.7216873,41.9770508z
		 M53.8837852,30.5122509l-3.0907974,4.4540997c-0.0488014,0.0713005-0.1006012,0.1395988-0.1514015,0.2030983l-3.8837013,4.8386993
		c-0.2176971-1.2216988-0.8288956-2.3593979-1.7939987-3.2234993c-1.7558975-1.5858994-4.4218979-3.9813995-6.9014015-6.204998
		l0.4209023-0.2784004c1.2851982-0.8544998,2.1026039-2.2245998,2.2431984-3.7578011
		c0.1416016-1.535099-0.4120979-3.0301991-1.5195007-4.1034985l-3.4384956-3.3330002
		c-0.2825012-0.2737999-0.5979004-0.5041008-0.9267998-0.7117004l7.5898972-1.1897011L53.750988,28.8842506
		C54.1767845,29.3247509,54.2323875,30.0092506,53.8837852,30.5122509z"
    />
    <path
      d="M19.5068893,13.2387505l-4.8077002-4.8144007c-0.1875-0.1875-0.4422998-0.2930002-0.7069998-0.2930002
		c-0.0010004,0-0.0010004,0-0.0010004,0c-0.2645998,0-0.5194998,0.1055002-0.7069998,0.2920008l-12.79,12.7725
		c-0.3907,0.3906002-0.3907,1.0233994-0.001,1.414999l4.8075995,4.8145008c0.1875,0.1875,0.4424005,0.2929001,0.7070003,0.2929001
		c0.0009999,0,0.0009999,0,0.0009999,0c0.2646999,0,0.5195003-0.1054001,0.7070003-0.2919998l12.7901001-12.7724009
		C19.8964882,14.2632504,19.8964882,13.6303501,19.5068893,13.2387505z M6.009789,25.3042507L2.6152892,21.90485l11.375-11.3593998
		l3.3944998,3.3993998L6.009789,25.3042507z"
    />
    <path
      d="M63.5058861,21.1958504l-12.7901001-12.7725c-0.1875-0.1865005-0.4422989-0.2920008-0.7070007-0.2920008h-0.0009995
		c-0.2645988,0-0.5195007,0.1055002-0.7070007,0.2930002l-4.8075981,4.8144007
		c-0.3897018,0.3915997-0.3897018,1.0244999,0.0009995,1.4150991l12.7900009,12.7724009
		c0.1875,0.1865997,0.4423981,0.2919998,0.7069969,0.2919998h0.0010033c0.264698,0,0.5194969-0.1054001,0.7069969-0.2929001
		l4.8077011-4.8145008C63.8964844,22.2192497,63.8964844,21.5864506,63.5058861,21.1958504z M57.9902878,25.3042507
		l-11.375-11.3594007l3.3944969-3.3993998l11.375,11.3593998L57.9902878,25.3042507z"
    />
  </Icon>
);
