import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CompanyName,
  Country,
  ExpiresAt,
  LastUpdatedAt,
  CreatedAt,
  ID,
  Status,
  OktaSubDomain,
  Website,
} from '../../Components/MetadataField/MetaGridFields';

export const OktaOrganizationMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {CompanyName(resource)}
        {ID(insight)}
        {Status(resource)}
        {OktaSubDomain(resource)}
        {Country(resource)}
        {Website(resource)}
        {CreatedAt(resource, 'utc')}
        {LastUpdatedAt(resource)}
        {ExpiresAt(resource)}
      </Grid>
    </Stack>
  );
};
