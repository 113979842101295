import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NoSqlIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g transform="translate(1 1)">
      <g>
        <g>
          <path
            d="M237.933,348.948V161.215c0-14.507-11.093-25.6-25.6-25.6H24.6c-14.507,0-25.6,11.093-25.6,25.6v187.733
				c0,14.507,11.093,25.6,25.6,25.6h187.733C226.84,374.548,237.933,363.455,237.933,348.948z M16.067,348.948V161.215
				c0-5.12,3.413-8.533,8.533-8.533h187.733c5.12,0,8.533,3.413,8.533,8.533v187.733c0,5.12-3.413,8.533-8.533,8.533H24.6
				C19.48,357.482,16.067,354.068,16.067,348.948z"
          />
          <path
            d="M41.667,195.348H127c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H41.667c-5.12,0-8.533,3.413-8.533,8.533
				S36.547,195.348,41.667,195.348z"
          />
          <path
            d="M161.133,212.415H67.267c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533h93.867
				c5.12,0,8.533-3.413,8.533-8.533C169.667,215.828,166.253,212.415,161.133,212.415z"
          />
          <path
            d="M127,246.548H41.667c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533H127c5.12,0,8.533-3.413,8.533-8.533
				S132.12,246.548,127,246.548z"
          />
          <path
            d="M84.333,289.215c0-5.12-3.413-8.533-8.533-8.533H41.667c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533
				H75.8C80.92,297.748,84.333,294.335,84.333,289.215z"
          />
          <path
            d="M127,314.815H58.733c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533H127c5.12,0,8.533-3.413,8.533-8.533
				S132.12,314.815,127,314.815z"
          />
          <path
            d="M485.4,135.615H297.667c-14.507,0-25.6,11.093-25.6,25.6v187.733c0,14.507,11.093,25.6,25.6,25.6H485.4
				c14.507,0,25.6-11.093,25.6-25.6V161.215C511,146.708,499.907,135.615,485.4,135.615z M493.933,348.948
				c0,5.12-3.413,8.533-8.533,8.533H297.667c-5.12,0-8.533-3.413-8.533-8.533V161.215c0-5.12,3.413-8.533,8.533-8.533H485.4
				c5.12,0,8.533,3.413,8.533,8.533V348.948z"
          />
          <path
            d="M314.733,195.348h85.333c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533h-85.333
				c-5.12,0-8.533,3.413-8.533,8.533S309.613,195.348,314.733,195.348z"
          />
          <path
            d="M434.2,212.415h-93.867c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533H434.2
				c5.12,0,8.533-3.413,8.533-8.533C442.733,215.828,439.32,212.415,434.2,212.415z"
          />
          <path
            d="M400.067,246.548h-85.333c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h85.333
				c5.12,0,8.533-3.413,8.533-8.533S405.187,246.548,400.067,246.548z"
          />
          <path
            d="M357.4,289.215c0-5.12-3.413-8.533-8.533-8.533h-34.133c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533
				h34.133C353.987,297.748,357.4,294.335,357.4,289.215z"
          />
          <path
            d="M400.067,314.815H331.8c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h68.267c5.12,0,8.533-3.413,8.533-8.533
				S405.187,314.815,400.067,314.815z"
          />
          <path
            d="M118.467,92.948c0.853,0,2.56,0,3.413-0.853c48.64-23.893,87.04-33.28,133.12-33.28
				c39.918,0,74.077,7.052,114.118,24.469L331.8,95.508c-4.267,1.707-6.827,5.973-5.12,10.24c0.853,3.413,4.267,5.973,7.68,5.973
				c0.853,0,1.707,0,2.56-0.853l55.261-18.145c3.036-0.218,5.852-1.942,7.032-4.895c0.432-0.864,0.643-1.946,0.638-3.026
				c0.075-1.823-0.392-3.621-1.346-5.104L380.44,24.682c-1.707-4.267-5.973-6.827-10.24-5.973c-4.267,1.707-6.827,6.827-5.12,11.093
				l12.753,38.931C335.028,49.765,298.015,41.748,255,41.748c-48.64,0-89.6,10.24-139.947,34.987
				c-4.267,1.707-5.973,6.827-4.267,11.093C112.493,91.242,115.053,92.948,118.467,92.948z"
          />
          <path
            d="M388.12,418.068c-48.64,23.893-87.04,33.28-133.12,33.28c-39.673,0-73.658-6.968-113.381-24.151l38.288-12.543
				c4.267-1.707,6.827-6.827,5.12-11.093s-6.827-6.827-11.093-5.12l-57.173,18.773c-0.95,0.317-1.781,0.751-2.496,1.259
				c-1.529,0.831-2.786,2.132-3.478,3.861c-1.094,2.736-0.779,5.818,0.708,8.13l18.065,55.016c0.853,3.413,4.267,5.973,7.68,5.973
				c1.707,0,2.56,0,4.267,0c4.267-1.707,6.827-6.827,5.12-11.093l-12.465-38.051c41.965,18.338,78.492,26.104,120.838,26.104
				c48.64,0,89.6-10.24,139.947-34.987c4.267-1.707,5.973-6.827,4.267-11.093C397.507,418.068,392.387,416.362,388.12,418.068z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
