import React, { FC } from 'react';

import { Box, BoxProps, Center } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface Props {
  onChange?(value: boolean);
  value?: boolean;
  isDisabled?: boolean;
}

export const ActiveSwitch: FC<Props> = props => {
  const { onChange, value = false, isDisabled = false } = props;

  const commonStyles: BoxProps = {
    pos: 'relative',
    w: 3,
    h: 3,
    transition: '0.2s all',
    left: value ? 0 : '50%',
    borderRadius: 2,
  };

  return (
    <Box
      borderRadius={4}
      overflow="hidden"
      cursor={isDisabled ? 'not-allowed' : onChange ? 'pointer' : 'default'}
      w={8}
      userSelect={'none'}
      onClick={() => {
        if (!isDisabled && onChange) {
          onChange?.(!value);
        }
      }}
      bg={
        value ? customTheme.colors.green['300'] : customTheme.colors.gray['200']
      }
      p={1}
    >
      <Center bg="white" {...commonStyles}></Center>
    </Box>
  );
};
