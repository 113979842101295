import React from 'react';

import { Icon } from '@chakra-ui/react';

export const EcsServiceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M484.3,412c-10.2,10.1-20.1,19.9-30.5,30.1c-12.3-5.6-25-11.5-37.7-17.3c-4.6,1.9-9.2,3.8-14.2,5.9
		c-4.7,12.8-9.5,26.1-14.3,39.4c-14.3,0.2-28.4,0.3-42.6,0.5c-5-13.4-9.8-26.5-14.7-39.6c-4.8-2-9.5-3.9-14.3-5.9
		c-12.5,5.9-25.2,11.7-37.7,17.6c-2-1.2-28-26.9-30.5-30.1c5.8-12.6,11.6-25.4,17.4-37.9c-2-4.7-3.8-9.2-5.9-14.2
		c-12.8-4.6-26-9.5-39.4-14.3c-0.2-14.2-0.3-28.3-0.5-42.6c13.3-4.9,26.5-9.8,39.6-14.7c2-4.7,3.9-9.4,5.9-14.2
		c-5.9-12.6-11.8-25.3-17.8-38.2c10-10.1,20-20.1,30.1-30.2c12.9,5.9,25.6,11.7,38.2,17.5c4.7-2,9.3-3.9,14.2-5.9
		c4.7-12.9,9.4-26,14.3-39.4c14.2-0.2,28.2-0.3,42.6-0.5c4.9,13.3,9.8,26.4,14.7,39.6c4.7,1.9,9.3,3.8,14.3,5.9
		c12.5-5.8,25.2-11.8,38.1-17.8c10,10,20,19.9,30.2,30c-5.9,12.8-11.7,25.5-17.5,38.2c2,4.7,3.8,9.3,5.9,14.2
		c12.8,4.6,26,9.4,39.2,14.2c0.1,0.7,0.2,1.3,0.2,1.9c0.1,12.4,0.3,24.9,0.4,37.3c0,1.1,0,2.2,0,3.5c-13.3,4.9-26.3,9.8-39.5,14.6
		c-1.9,4.6-3.8,9.3-5.9,14.2C472.4,386.5,478.3,399.2,484.3,412z M498.3,336.1c0-8.1,0-16.1,0-24.2c-12.4-4.5-24.6-8.9-37-13.4
		c-2.4-8.5-6-16.4-10-24c5.5-12.1,11-23.9,16.2-35.3c-5.8-5.9-11.4-11.6-17-17.3c-11.8,5.5-23.7,11.1-35.5,16.6
		c-4.1-1.9-8-3.8-12-5.5c-4.1-1.7-8.3-3.1-12.2-4.6c-4.6-12.3-9.1-24.4-13.5-36.5c-8.2,0-16.1,0-24.2,0c-4.5,12.4-9,24.7-13.3,36.7
		c-4.4,1.6-8.5,3.1-12.4,4.7c-4,1.7-7.9,3.6-11.8,5.4c-12-5.5-23.8-10.9-35-16.1c-5.9,5.9-11.6,11.4-17.4,17.1
		c5.5,11.7,11,23.6,16.6,35.5c-4.2,7.8-7.5,15.8-10,24c-12.4,4.6-24.6,9.1-36.6,13.6c0,8.2,0,16.2,0,24.2
		c12.3,4.5,24.5,8.9,36.9,13.4c2.5,8.3,5.9,16.3,10,23.9c-5.5,12.1-11,24-16.3,35.5c5.8,5.9,11.3,11.5,17,17.2
		c11.9-5.5,23.8-11.1,35.7-16.7c7.8,4.2,15.8,7.5,23.9,10c4.6,12.5,9.1,24.6,13.6,36.7c8,0.4,21.8,0.2,24.3-0.4
		c4.4-12.1,8.8-24.3,13.3-36.6c8.4-2.5,16.3-5.9,23.9-10c12.1,5.5,23.9,11,35.4,16.3c5.8-5.7,11.5-11.3,17.3-17
		c-5.5-11.8-11.1-23.7-16.7-35.7c4.2-7.7,7.5-15.8,10-24C474.2,345,486.2,340.5,498.3,336.1z"
      />
      <path
        d="M365.8,260.8c34.9-0.4,63.8,28.9,63.7,63.7c-0.1,34.8-29,63.7-63.9,63.6c-34.8-0.1-63.5-28.9-63.5-63.7
		C302,289.6,330.9,260.3,365.8,260.8z M365.9,274c-27.8-0.2-50.4,22.6-50.6,50c-0.2,28,22.6,50.7,50.2,50.9
		c27.7,0.1,50.6-22.4,50.8-50.2C416.4,296.8,393.6,273.9,365.9,274z"
      />
      <g>
        <path
          d="M245.1,101.7c-5.7,0-10.3,4.6-10.3,10.3V165h20.6v-53.1C255.4,106.3,250.8,101.7,245.1,101.7z M387,41.3H25.8
			C11.6,41.3,0,52.8,0,67v257c0,14.2,11.6,25.7,25.8,25.7h180.6v-25.7H25.8V67H387V165h25.8V67C412.8,52.8,401.2,41.3,387,41.3z
			 M322.5,101.7c-5.7,0-10.3,4.6-10.3,10.3V165h20.6v-53.1C332.8,106.3,328.2,101.7,322.5,101.7z M156.3,111.9v167
			c0,5.7,4.6,10.3,10.3,10.3c5.7,0,10.3-4.6,10.3-10.3v-167c0-5.7-4.6-10.3-10.3-10.3C161,101.7,156.3,106.3,156.3,111.9z M80,111.9
			v167c0,5.7,4.6,10.3,10.3,10.3c5.7,0,10.3-4.6,10.3-10.3v-167c0-5.7-4.6-10.3-10.3-10.3C84.6,101.7,80,106.3,80,111.9z"
        />
      </g>
    </g>
  </Icon>
);
