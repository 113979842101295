import React, { FC, useEffect, useState } from 'react';

import { keyBy, map, merge, values } from 'lodash';
import { useSelector } from 'react-redux';

import { LineChart } from 'components/Visualization';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { formatDate } from 'utils/date';

import { customTheme } from '../../../../../../theme';

export const CreatedVsResolvedIssues: FC = () => {
  const [data, setData] = useState<any>([]);
  const { alertHistoryByStatus } = useSelector(selectFindingsOverview);

  useEffect(() => {
    const resolvedAlerts = map(
      alertHistoryByStatus.data?.resolved_history,
      (v, date) => ({
        key: date,
        date: formatDate(date, 'D-MMM'),
        day: formatDate(date, 'DD-MMM'),
        Resolved:
          (v.CRITICAL ?? 0) + (v.MEDIUM ?? 0) + (v.LOW ?? 0) + (v.HIGH ?? 0),
      }),
    );
    const newAlerts = map(
      alertHistoryByStatus.data?.created_history,
      (v, date) => ({
        key: date,
        date: formatDate(date, 'D-MMM'),
        day: formatDate(date, 'DD-MMM'),
        Created:
          (v.CRITICAL ?? 0) + (v.MEDIUM ?? 0) + (v.LOW ?? 0) + (v.HIGH ?? 0),
      }),
    );

    const merged = merge(keyBy(resolvedAlerts, 'key'), keyBy(newAlerts, 'key'));

    setData(values(merged).slice(-7));
  }, [alertHistoryByStatus.data]);

  const graphData = {
    data,
    xField: 'day',
    yField: ['Created', 'Resolved'],
  };

  return (
    <LineChart
      {...graphData}
      isLoading={alertHistoryByStatus.isLoading}
      styles={{
        lineChart: { margin: { bottom: -24, left: -10 } },
      }}
      colorMap={{
        Created: customTheme.colors.critical,
        Resolved: customTheme.colors.green['300'],
      }}
    />
  );
};
