import { useEffect } from 'react';

import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';

export const useMouseOutElement = (
  graph: AGraph<any, any>,
  cb: (entries: any[]) => void,
) => {
  useEffect(() => {
    const onMouseOut = (entries: any[]) => {
      //console.log('mouse out', entries);
      if (!entries.length) {
        return;
      }

      cb(entries);
    };

    graph.on('mouseout:element', onMouseOut);
    return () => {
      graph.off('mouseout:element', onMouseOut);
    };
  }, [cb, graph]);
};
