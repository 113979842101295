import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { CheckType } from '@ariksa/compliance-policies/api';
import { NativeResources } from '@ariksa/inventory-core';
import { AggregatedAlertDetails, ReportFor } from '@ariksa/notification';
import { AlertResponse, NotificationFor } from '@ariksa/notification/api';
import { Box, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useAlertDownloader } from 'services/Notification/useAlertDownloader';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { Optional } from 'types/utils';

import {
  DrawerAlertCounts,
  StatusMap,
} from 'components/DataDisplay/Misc/DrawerAlertCounts';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import {
  AutomationIcon,
  DetailsIdentifier,
  DownloadIconButton,
  ExceptionIcon,
  NotificationIcon,
  PageInfo,
  RemediateIcon,
  SnoozeIcon,
  TableActionsMenu,
} from 'components/index';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { SnoozeAlert } from 'containers/Findings/Alerts/Components/SnoozeAlert';
import { AlertDetailsDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AlertDetailsDrawer';
import { getAlertColumns } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/getAlertColumns';
import { IndividualAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/IndividualAlertDrawer';
import { RemediationModal } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationModal';
import { useAlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/hooks/useAlertStatus';
import { selectAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/selectors';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions as findingsOverviewActions } from 'containers/Findings/FindingsOverview/slice';

interface IAlertTableByRuleId {
  aggregatedAlert: Optional<AggregatedAlertDetails>;
  showSummary?: boolean;
}

export const IndividualAlertTable: FC<IAlertTableByRuleId> = props => {
  const dispatch = useDispatch();
  const { aggregatedAlert, showSummary } = props;
  const { alertsByRuleId, alertFilters, riskContext } = useSelector(
    selectFindingsOverview,
  );

  const { alertListStatus } = useSelector(selectAlertDrawer);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);

  const exceptionModal = useDisclosure();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const alertDrawer = useDisclosure();
  const snooze = useDisclosure();
  const remediationModalState = useDisclosure();
  const alertDetails = useDisclosure();

  const [currentRow, setCurrentRow] = useState<AlertResponse | null>(null);
  const [statusData, setStatusData] = useState<StatusMap>({
    critical: 0,
    medium: 0,
    low: 0,
  });
  const { getAlertListStatus } = useAlertStatus();

  const [sortBy, setSortBy] = useState<Sorted>({
    sortField: 'entity_name',
    sortOrder: 'asc',
  });

  const handleSort = (sortInfo: Sorted) => {
    if (!isEqual(sortBy, sortInfo)) {
      setSortBy(sortInfo);
    }
  };

  const getAlertRemediationAvailability = useCallback(() => {
    if (!aggregatedAlert?.alert_rule_ids?.length) return;
    dispatch(
      findingsOverviewActions.getAlertByRuleIdRemediationAvailability({
        q: {
          checkDetailsInfo: {
            check_type: CheckType.Instances,
            check_names: aggregatedAlert?.alert_rule_ids ?? [],
          },
        },
      }),
    );
  }, [aggregatedAlert?.alert_rule_ids, dispatch]);

  const getResourceVPCs = useCallback(
    (aggregatedAlert, alertsByRuleId) => {
      if (!aggregatedAlert?.resource_types.length) return;
      dispatch(
        findingsOverviewActions.getResourceVPC({
          q: {
            insightV2Request: {
              resource_type: aggregatedAlert
                ?.resource_types[0] as NativeResources,
              account_id: accountId ? [accountId] : [],
              environment_id: environmentId,
              resource_uuids: alertsByRuleId.results.map(a => a.entity_uuid),
            },
          },
          page: { page_size: 100, page_number: 1 },
        }),
      );
    },
    [accountId, dispatch, environmentId],
  );

  const loadAlertsByRuleId = useCallback(
    (page: PageInfo = INIT_PAGE_INFO) => {
      if (!aggregatedAlert) return;

      //const { description = '' } = aggregatedAlert;
      const filter = {};
      if (alertFilters.severity) {
        filter['severity'] = alertFilters.severity;
      }

      dispatch(
        findingsOverviewActions.getAlertsByRuleId({
          q: {
            accountId,
            environmentId,
            groupId: aggregatedAlert?.group_id,
            alertCategory: aggregatedAlert.alert_category,
            ...sortBy,
            ...filter,
          },
          page,
          onSuccess: res => {
            getAlertRemediationAvailability();
            getResourceVPCs(aggregatedAlert, res);
            dispatch(
              findingsOverviewActions.getAlertWorkflowStatus({
                q: { alertId: map(res?.results, o => o?.uuid ?? '') },
              }),
            );

            dispatch(
              findingsOverviewActions.getAlertLogs({
                q: { alertId: map(res?.results, o => o?.uuid ?? '') },
              }),
            );

            dispatch(
              findingsOverviewActions.getRiskContext({
                q: {
                  riskContextRequest: {
                    uuids: map(res?.results, o => o?.entity_uuid ?? '') ?? [],
                  },
                },
              }),
            );

            dispatch(
              findingsOverviewActions.getCompliance({
                q: {
                  getRuleComplianceStandardRequest: {
                    rules: aggregatedAlert.alert_rule_ids ?? [],
                  },
                },
              }),
            );

            getAlertListStatus(map(res?.results, o => o?.uuid ?? '') ?? []);
          },
        }),
      );
    },
    [
      aggregatedAlert,
      alertFilters.severity,
      dispatch,
      accountId,
      environmentId,
      sortBy,
      getAlertRemediationAvailability,
      getResourceVPCs,
      getAlertListStatus,
    ],
  );

  useEffect(() => {
    loadAlertsByRuleId();
  }, [loadAlertsByRuleId]);

  const renderActions = useCallback(
    ({ row }) => {
      const menuItems = [
        {
          label: 'Create ticket',
          icon: <AutomationIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            ticketModal.onOpen();
          },
        },
        {
          label: 'Send notification',
          icon: <NotificationIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            notificationModal.onOpen();
          },
        },
        {
          label: 'Snooze',
          icon: <SnoozeIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            snooze.onOpen();
          },
        },
        {
          label: 'Add exception',
          icon: <ExceptionIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            exceptionModal.onOpen();
          },
        },
        {
          label: 'Remediate',
          icon: <RemediateIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            remediationModalState.onOpen();
          },
        },
      ];

      return (
        <Box onClick={e => e.stopPropagation()}>
          <TableActionsMenu menuItems={menuItems} styles={{ item: { h: 8 } }} />
        </Box>
      );
    },
    [
      exceptionModal,
      notificationModal,
      remediationModalState,
      ticketModal,
      snooze,
    ],
  );

  const columns = useMemo(() => {
    return [
      ...getAlertColumns(aggregatedAlert, alertListStatus, riskContext),
      {
        header: 'Actions',
        render: renderActions,
      },
    ];
  }, [aggregatedAlert, alertListStatus, riskContext, renderActions]);

  //calculate status data
  useEffect(() => {
    let status = {
      critical: aggregatedAlert?.severity?.CRITICAL,
      high: aggregatedAlert?.severity?.HIGH,
      medium: aggregatedAlert?.severity?.MEDIUM,
      low: aggregatedAlert?.severity?.LOW,
    };

    setStatusData(status);
  }, [aggregatedAlert]);
  const { downloadReport } = useAlertDownloader();

  const alertDrawerComponent = useMemo(() => {
    return (
      <Box>
        {alertDrawer.isOpen && currentRow && (
          <IndividualAlertDrawer
            aggregatedAlert={aggregatedAlert}
            alert={currentRow}
            isOpen={alertDrawer.isOpen}
            onClose={alertDrawer.onClose}
          />
        )}
      </Box>
    );
  }, [aggregatedAlert, alertDrawer.isOpen, alertDrawer.onClose, currentRow]);

  return (
    <Stack h={'full'} spacing={5} id={'individual-alert-table-drawer'}>
      {showSummary && (
        <HStack align={'flex-start'} justify={'space-between'}>
          <Stack spacing={4}>
            <DetailsIdentifier
              label="Description"
              value={aggregatedAlert?.description ?? '-'}
            />
            <DetailsIdentifier
              label="Entity Type"
              value={aggregatedAlert?.resource_types?.join(', ') ?? '-'}
            />
            <DrawerAlertCounts statusMap={statusData} />

            <DetailsIdentifier
              label="Environment"
              value={<EnvironmentName environmentId={environmentId} />}
            />
          </Stack>
          <DownloadIconButton
            onClick={() => {
              downloadReport({
                reportType: ReportFor.IndividualAlerts,
                category: aggregatedAlert?.alert_category,
                groupId: aggregatedAlert?.group_id,
              });
            }}
            aria-label={''}
          />
        </HStack>
      )}
      <Box flex={1}>
        <Table
          data={alertsByRuleId.data}
          columns={columns}
          pagination={{
            pageInfo: alertsByRuleId.page.info,
            totalCount: alertsByRuleId.page.totalCount,
            onChange: loadAlertsByRuleId,
          }}
          isLoading={alertsByRuleId.isLoading}
          styles={{ height: '34rem' }}
          onSort={handleSort}
          sortBy={sortBy}
          headerClassName="dashboard_alerts_table"
          isRowActive={r => r.entity_uuid === currentRow?.entity_uuid}
          cursor={'pointer'}
          onRowClick={r => {
            // show alert details
            setCurrentRow(r);
            alertDetails.onOpen();

            // alertDrawer.onOpen();
            // updateActiveResource({
            //   resourceType: r.resource,
            //   uuid: r.entity_uuid!,
            //   accountId: r.account_id!,
            //   resourceId: r.entity_id!,
            // });*/
          }}
        />
      </Box>

      {currentRow && (
        <AddException
          onClose={exceptionModal.onClose}
          isOpen={exceptionModal.isOpen}
          alert={currentRow}
        />
      )}

      {ticketModal.isOpen && !!currentRow && (
        <CreateTicket
          onClose={ticketModal.onClose}
          isOpen={ticketModal.isOpen}
          alert={currentRow}
          onCreate={() =>
            getAlertListStatus(alertsByRuleId.data.map(a => a.uuid ?? ''))
          }
          notification_for={NotificationFor.Alerts}
        />
      )}
      {notificationModal.isOpen && !!currentRow && (
        <CreateNotification
          onClose={notificationModal.onClose}
          isOpen={notificationModal.isOpen}
          alert={currentRow}
          notification_for={NotificationFor.Alerts}
        />
      )}
      {!!currentRow && snooze.isOpen && (
        <SnoozeAlert
          onClose={snooze.onClose}
          isOpen={snooze.isOpen}
          alert={currentRow}
          onSuccess={() => loadAlertsByRuleId(alertsByRuleId.page.info)}
        />
      )}
      {!!currentRow && remediationModalState.isOpen && (
        <RemediationModal state={remediationModalState} alert={currentRow} />
      )}
      {/*{alertDrawerComponent}*/}
      {!!currentRow && alertDetails.isOpen && (
        <AlertDetailsDrawer
          alert={currentRow}
          isOpen={alertDetails.isOpen}
          onClose={alertDetails.onClose}
        />
      )}
    </Stack>
  );
};
