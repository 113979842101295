import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PermissionsActionsIcon = props => (
  <Icon
    viewBox="-20 -20 550.0 550.0"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <g>
      <path
        d="M404.938,316.45h-68.423V176.608h68.423c48.587,0,88.115-39.529,88.115-88.115c0-48.591-39.529-88.12-88.115-88.12
			s-88.115,39.529-88.115,88.12v68.423H176.231V88.493c0-48.591-39.529-88.12-88.115-88.12S0,39.901,0,88.493
			c0,48.587,39.529,88.115,88.115,88.115h68.423V316.45H88.115C39.529,316.45,0,355.978,0,404.565
			c0,48.587,39.529,88.115,88.115,88.115s88.115-39.529,88.115-88.115v-68.423h140.591v68.423c0,48.587,39.529,88.115,88.115,88.115
			s88.115-39.529,88.115-88.115C493.053,355.978,453.524,316.45,404.938,316.45z M336.514,88.493
			c0-37.731,30.692-68.428,68.423-68.428s68.423,30.697,68.423,68.428s-30.692,68.423-68.423,68.423h-68.423V88.493z
			 M156.538,404.565c0,37.731-30.692,68.423-68.423,68.423s-68.423-30.692-68.423-68.423s30.692-68.423,68.423-68.423h68.423
			V404.565z M156.538,156.916H88.115c-37.731,0-68.423-30.692-68.423-68.423s30.692-68.428,68.423-68.428
			s68.423,30.697,68.423,68.428V156.916z M316.822,316.45H176.231V176.608h140.591V316.45z M404.938,472.988
			c-37.731,0-68.423-30.692-68.423-68.423v-68.423h68.423c37.731,0,68.423,30.692,68.423,68.423S442.668,472.988,404.938,472.988z"
      />
    </g>
  </Icon>
);
