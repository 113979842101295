import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface Props extends BoxProps {
  isDisabled?: boolean;
}

export const FormFieldBox: FC<Props> = props => {
  const { isDisabled = false, ...rest } = props;
  return (
    <Box
      px={3}
      py={1}
      border="1px solid"
      borderRadius={4}
      borderColor={customTheme.colors.gray['200']}
      opacity={isDisabled ? 0.4 : 1}
      {...rest}
    ></Box>
  );
};
