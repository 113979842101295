import React, { FC, useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { find, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { Form } from 'components/DataEntry/Form';
import { QuestionCircleIcon } from 'components/Icons';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';

import { DataPostureManagement } from '../DataPostureManagement';
import { SelectDataSources } from '../SelectDataSources';
import { formStyles } from '../styles';
import { renderComponentHeader } from '../utils';

interface Props {
  handleSubmit(data: Record<string, any>);
  handleRedirect();
  dataSourceTypeOptions: Record<string, any>[];
  accountOptions: Record<string, any>[];
  dataSourceType: Dict<any>;
  setDataSourceType(value: Dict<any>);
  selectedAccounts: Dict<any>[];
  environmentOptions: Dict<any>[];
  setSelectedAccounts(value: Dict<any>[]);
  selectedEnv: Dict<any>;
  setSelectedEnv(value: Dict<any>);
}

export const AwsDataClassificationForm: FC<Props> = props => {
  const {
    handleRedirect,
    handleSubmit,
    accountOptions,
    dataSourceType,
    setDataSourceType,
    selectedAccounts,
    setSelectedAccounts,
    selectedEnv,
    setSelectedEnv,
    environmentOptions,
    dataSourceTypeOptions,
  } = props;
  const [showHelp, setShowHelp] = useState(false);
  const {
    service,
    dataSources,
    enableDataClassification,
    dataScannerInfo,
  } = useSelector(selectDataClassification);
  const { currentRecord, actionType } = useDataConfigurationContext();
  const cloudAccounts = useSelector(selectOnboardedCloudAccounts);
  const { data: data_sources } = dataSources;
  const [totalIncludedDataSources, setTotalIncludedDataSources] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState('');

  //set selected data source types
  useEffect(() => {
    if (actionType === 'Update') {
      const source = currentRecord?.data_classifications?.[0]?.service;
      setDataSourceType(
        find(dataSourceTypeOptions, o => source === o.value) ?? {},
      );
      setName(currentRecord?.name);
    }
  }, [currentRecord, actionType, setDataSourceType, dataSourceTypeOptions]);

  //set total number of included data sources
  useEffect(() => {
    let total = data_sources?.length ?? 0;

    if (service?.category === 'include' && service?.quantifier === 'some') {
      total = service?.data_sources?.length ?? 0;
    } else if (service?.category === 'exclude')
      total = total - (service?.data_sources?.length ?? 0);

    setTotalIncludedDataSources(isEmpty(selectedAccounts) ? 0 : total);
  }, [service, data_sources, selectedAccounts]);

  //set isDisabled
  useEffect(() => {
    if (
      (service.quantifier === 'some' && isEmpty(service.data_sources)) ||
      !name
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [service, name]);

  return (
    <Box w="full">
      <Form
        styles={formStyles}
        title={renderComponentHeader()}
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            value: name,
            onChange: setName,
          },
          environment_id: {
            type: 'react-select',
            label: 'Environment',
            options: environmentOptions,
            value: selectedEnv,
            onChange: selected => {
              setSelectedEnv(selected);
            },
            /*isLoading:
              cloudAccounts.loading || dataClassificationGroups.isLoading,*/
            isDisabled: actionType === 'Update',
          },
          account_ids: {
            type: 'react-select',
            label: 'Account name',
            options: accountOptions,
            value: selectedAccounts,
            onChange: selected => {
              setSelectedAccounts(selected);
            },
            isMulti: true,
            isLoading: cloudAccounts.loading || dataScannerInfo.isLoading,
            isDisabled: actionType === 'Update' || dataScannerInfo.isLoading,
          },
          dataSourceTypes: {
            type: 'react-select',
            label: 'Choose data sources by resource type(s)',
            options: dataSourceTypeOptions,
            value: dataSourceType,
            onChange: selected => {
              /*dispatch(
                actions.updateServiceFields({
                  field: 'service',
                  value: selected?.value,
                }),
              );*/
              setDataSourceType(selected);
            },
            helpComponent: (
              <DataPostureManagement onClose={() => setShowHelp(false)} />
            ),
            isHelpOpen: showHelp,
            helpIcon: (
              <Box
                as={QuestionCircleIcon}
                color="primary"
                cursor="pointer"
                onClick={() => setShowHelp(!showHelp)}
              />
            ),
          },
          dataSources: {
            type: 'custom-with-form-control',
            component: <SelectDataSources dataSourceType={dataSourceType} />,
            label: dataSourceType?.fieldLabel,
          },
          numberOfDataSourcesIncluded: {
            type: 'custom',
            component: () => (
              <HStack color="primary">
                <Box>
                  Number of data sources included for automatic data
                  classification:
                </Box>
                <Box>{totalIncludedDataSources}</Box>
              </HStack>
            ),
          },
        }}
        showHelp={showHelp}
        buttonOptions={{
          submit: {
            name: 'Confirm',
            isDisabled: isDisabled,
            isLoading:
              dataSources.isLoading ||
              enableDataClassification.isLoading ||
              dataScannerInfo.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleRedirect,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
