import React, { ReactNode } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getIcon } from 'components/Icons/Components';

export interface CountCardProps {
  label: string;
  count: ReactNode;
  iconType?: string;
  icon?: ReactNode;
  iconColor?: string;
  borderColor?: string;
  onClick?();
  isLoading?: boolean;
  message?: ReactNode;
  messageBgColor?: string;
  tooltip?: ReactNode;
  tooltipWidth?: number;
}

export const VerticalCountCard: React.FC<CountCardProps> = props => {
  const {
    iconColor,
    label,
    icon,
    iconType,
    onClick,
    count,
    borderColor,
    isLoading = false,
    message,
    messageBgColor,
    tooltip,
    tooltipWidth,
  } = props;

  const renderItem = () => (
    <Stack
      px={4}
      my="auto"
      spacing={0}
      onClick={onClick}
      border="1px solid"
      borderColor={borderColor ?? customTheme.colors.gray['200']}
      borderRadius={6}
      w="full"
      h="full"
      cursor={onClick ? 'pointer' : 'inherit'}
    >
      <HStack w="full" h="full">
        <Box boxSize={6} color={iconColor}>
          <Center>{iconType ? getIcon(iconType) : icon}</Center>
        </Box>
        <Box fontSize="md">{label}</Box>
      </HStack>
      <HStack
        w="full"
        h="full"
        align="flex-start"
        justify="space-between"
        pl={1}
      >
        <Box fontSize="lg" fontWeight={600}>
          {isLoading ? <CustomSpinner /> : count}
        </Box>
        {message && (
          <Box
            px={3}
            bg={messageBgColor}
            borderRadius={5}
            color={customTheme.colors.gray['300']}
          >
            {message}
          </Box>
        )}
      </HStack>
    </Stack>
  );

  return tooltip ? (
    <CustomTooltip
      label={tooltip}
      w={tooltipWidth}
      styles={{ text: { w: 'full', h: 'full' } }}
    >
      {renderItem()}
    </CustomTooltip>
  ) : (
    renderItem()
  );
};
