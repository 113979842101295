import {
  CriteriaCondition,
  Status,
  TagCondition,
} from '@ariksa/inventory-core';
import {
  AllowlistApiCreateAllowlistRequest,
  AllowlistApiGetAllowedStatusRequest,
  AllowlistApiGetAllowlistRequest,
  AllowlistApiGetApplicationsRequest,
  AllowlistApiRemoveAllowlistRequest,
  AllowlistResponse,
  ApplicationResponse,
  ConditionBase,
  TagKeyValuesConditionBase,
} from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { forEach } from 'lodash';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the whitelist container
export const initialState: ContainerState = {
  whitelists: QueryState.init([]),
  formData: {
    name: '',
    ids: [],
    criteria_condition: CriteriaCondition.Any,
    criteria: {
      tag_keys_condition: {
        key: [],
        key_condition: TagCondition.Tagged,
      },
    },
    status: Status.Approved,
  },
  resourceTypeCriteria: {},
  tagKeyValueCriteria: {},
  createWhitelist: QueryState.init({}),
  whitelist: QueryState.init({} as AllowlistResponse),
  whitelistStatus: QueryState.init([]),
  ec2Applications: QueryState.init({ os: {}, technology: {} }),
};

const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    getAllowLists(state, action: QueryAction<AllowlistResponse[]>) {
      state.whitelists = QueryState.next(state.whitelists, action);
    },

    getAllowListByID(
      state,
      action: QueryAction<AllowlistResponse, AllowlistApiGetAllowlistRequest>,
    ) {
      state.whitelist = QueryState.next(state.whitelist, action, {
        mapData: res => {
          state.formData = res;
          return res;
        },
      });
    },

    getEC2Applications(
      state,
      action: QueryAction<
        ApplicationResponse[],
        AllowlistApiGetApplicationsRequest
      >,
    ) {
      state.ec2Applications = QueryState.next(state.ec2Applications, action, {
        mapData: res => {
          let data = { os: {}, technology: {} };
          forEach(res, o => {
            data[o.type][o.name]
              ? data[o.type][o.name].push(o)
              : (data[o.type][o.name] = [o]);
          });
          return data;
        },
      });
    },

    createAllowList(
      state,
      action: QueryAction<any, AllowlistApiCreateAllowlistRequest>,
    ) {
      state.createWhitelist = QueryState.next(state.createWhitelist, action);
    },
    updateAllowList(state, action: QueryAction<any>) {
      state.createWhitelist = QueryState.next(state.createWhitelist, action);
    },
    deleteAllowList(
      state,
      action: QueryAction<
        AllowlistResponse,
        AllowlistApiRemoveAllowlistRequest
      >,
    ) {
      state.createWhitelist = QueryState.next(state.createWhitelist, action);
    },

    getAllowListStatus(
      state,
      action: QueryAction<Status[], AllowlistApiGetAllowedStatusRequest>,
    ) {
      state.whitelistStatus = QueryState.next(state.whitelistStatus, action);
    },

    updateFormData(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      state.formData[action.payload.field] = action.payload.value;
    },
    updateResourceTypeCriteria(
      state,
      action: PayloadAction<{ id: string; value: ConditionBase }>,
    ) {
      state.resourceTypeCriteria[action.payload.id] = action.payload.value;
    },
    updateResourceTypeCriteriaField(
      state,
      action: PayloadAction<{
        id: string;
        field: string;
        value: ConditionBase;
      }>,
    ) {
      state.resourceTypeCriteria[action.payload.id][action.payload.field] =
        action.payload.value;
    },
    updateResourceTypeCriterion(
      state,
      action: PayloadAction<Record<string, ConditionBase>>,
    ) {
      state.resourceTypeCriteria = action.payload;
    },
    updateTagKeyValuesCriteria(
      state,
      action: PayloadAction<{ id: string; value: TagKeyValuesConditionBase }>,
    ) {
      state.tagKeyValueCriteria[action.payload.id] = action.payload.value;
    },
    updateTagKeyValuesCriterion(
      state,
      action: PayloadAction<Record<string, TagKeyValuesConditionBase>>,
    ) {
      state.tagKeyValueCriteria = action.payload;
    },
    updateCriteria(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      if (state.formData?.criteria !== undefined) {
        state.formData.criteria[action.payload.field] = action.payload.value;
      }
    },

    resetCriteria(state, action: PayloadAction<string>) {
      if (state.formData.criteria) {
        state.formData.criteria[action.payload] =
          initialState.formData.criteria?.[action.payload];
      }
    },
    resetRegion(state) {
      state.formData.region = [];
      state.formData.region_condition = undefined;
    },
    resetResourceTypeCriteria(state) {
      state.resourceTypeCriteria = {};
    },
    resetFormData(state) {
      state.formData = initialState.formData;
    },
  },
});

export const { actions, reducer, name: sliceKey } = whitelistSlice;
