import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CloudWatchLogGroupARN,
  CloudWatchLogRoleARN,
  HasCustomEventSelectors,
  HasInsightSelectors,
  IncludeGlobalServiceEvents,
  IsLogging,
  IsOrganizationTrail,
  KMSKeyID,
  LatestNotificationAt,
  LogFileValidation,
  MultiRegion,
  S3Bucket,
  SNSTopicARN,
  SNSTopicName,
  StartLoggingTime,
  StopLoggingTime,
} from '../../../Components/MetadataField/MetaGridFields';

export const CloudTrailMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {LogFileValidation(resource)}
      {MultiRegion(resource)}
      {IncludeGlobalServiceEvents(resource)}
      {S3Bucket(resource)}
      {CloudWatchLogGroupARN(resource)}
      {CloudWatchLogRoleARN(resource)}
      {SNSTopicName(resource)}
      {SNSTopicARN(resource)}
      {KMSKeyID(resource)}
      {HasCustomEventSelectors(resource)}
      {HasInsightSelectors(resource)}
      {IsOrganizationTrail(resource)}
      {LatestNotificationAt(resource)}
      {IsLogging(resource)}
      {StartLoggingTime(resource)}
      {StopLoggingTime(resource)}
    </Grid>
  );
};
