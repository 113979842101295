import {
  DataClassificationApiGetDataClassificationSourcesDetailsRequest,
  DataClassificationSourceDetails,
  FileDataApiGetPiiBySourceRequest,
  PageDocumentClassificationInfo,
  PageFileDataDetailed,
  PageFileMetaDataInfo,
  SourceSensitiveData,
  VisibilityDataApiGetDocumentClassificationBySubtypesSourceAndObjectsRequest,
  VisibilityDataApiGetFileMetadataInfoRequest,
  VisibilityDataApiGetSourceSensitiveDataRequest,
} from '@ariksa/data-scanning/api';

import { DataScanningService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useDataScanningService() {
  const piiBySource = useAriksaQuery<
    FileDataApiGetPiiBySourceRequest,
    PageFileDataDetailed
  >({
    queryKey: [],
    fetcher: req =>
      DataScanningService.FileData.getPiiBySource(req).then(r => r.data),
    config: {
      retry: false,
    },
  });

  const fileMetadataInfo = useAriksaQuery<
    VisibilityDataApiGetFileMetadataInfoRequest,
    PageFileMetaDataInfo
  >({
    queryKey: [],
    fetcher: req =>
      DataScanningService.Visibility.getFileMetadataInfo(req).then(r => r.data),
    config: {
      retry: false,
    },
  });

  const dataClassificationSources = useAriksaQuery<
    DataClassificationApiGetDataClassificationSourcesDetailsRequest,
    DataClassificationSourceDetails[]
  >({
    queryKey: [],
    fetcher: req =>
      DataScanningService.DataClassification.getDataClassificationSourcesDetails(
        req,
      ).then(r => r.data),
    config: {
      retry: false,
    },
  });

  const dataSourceSensitiveInfo = useAriksaQuery<
    VisibilityDataApiGetSourceSensitiveDataRequest,
    SourceSensitiveData
  >({
    queryKey: [],
    fetcher: req =>
      DataScanningService.Visibility.getSourceSensitiveData(req).then(
        r => r.data,
      ),
  });

  const documentClassificationFileObject = useAriksaQuery<
    VisibilityDataApiGetDocumentClassificationBySubtypesSourceAndObjectsRequest,
    PageDocumentClassificationInfo
  >({
    queryKey: [],
    fetcher: req =>
      DataScanningService.Visibility.getDocumentClassificationBySubtypesSourceAndObjects(
        req,
      ).then(r => r.data),
    config: {
      retry: false,
    },
  });

  return {
    piiBySource,
    dataClassificationSources,
    dataSourceSensitiveInfo,
    fileMetadataInfo,
    documentClassificationFileObject,
  };
}
