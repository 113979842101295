import React, { useCallback, useEffect, useState } from 'react';

import { EnvironmentRead } from '@ariksa/compliance-policies';
import { CloudEnvironmentType } from '@ariksa/compliance-policies/api';
import { forEach, map } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { selectApp } from 'containers/App/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

export const useEnvironmentOptions = () => {
  const { toCloudAccountName } = useCloudAccountId();
  const { environments, cloudAccounts } = useSelector(selectApp);
  const [environmentMapping, setEnvironmentMapping] = useState<
    Record<string, EnvironmentRead>
  >();
  const [accountEnvironmentMapping, setAccountEnvironmentMapping] = useState<
    Record<string, any>
  >();

  useEffect(() => {
    let mapping: Record<string, EnvironmentRead> = {};
    forEach(environments.data, o => (mapping[o.id] = o));
    setEnvironmentMapping(mapping);
  }, [environments.data]);

  useEffect(() => {
    let mapping: Record<string, any> = {};
    forEach(environments.data, o => {
      forEach(o.account_ids, a => (mapping[a as string] = o));
    });
    setAccountEnvironmentMapping(mapping);
  }, [environments.data]);

  const getEnvironmentOptions = useCallback(
    (environmentType?: CloudEnvironmentType) => {
      return map(
        filter(
          environments.data,
          e =>
            e.environment_type ===
            (environmentType || CloudEnvironmentType.CloudProvider),
        ),
        o => ({
          label: `${o.name}`,
          value: o.id,
          data: o,
        }),
      );
    },
    [environments.data],
  );

  const getGroupedAccountsUnderEnvironmentOptions = useCallback(
    selectedEnvs => {
      if (!!selectedEnvs?.length) {
        return map(selectedEnvs, o => ({
          label: (
            <CustomTooltip label={'Click to select all accounts'}>
              {o?.data?.name}
            </CustomTooltip>
          ),
          options: map(
            o?.data?.account_ids,
            //filter(o?.data?.account_ids, f => toCloudAccountName(f) !== '-'),
            a => ({
              label: toCloudAccountName(a),
              value: a + '_' + o?.data?.id,
              data: {
                accountId: a,
                environmentId: o?.data?.id,
              },
            }),
          ),
        }));
      } else {
        return [];
      }
    },
    [toCloudAccountName],
  );

  const getEnvironmentAccountsMapping = useCallback(() => {
    const accounts: Record<string, any> = {};
    const environmentMapping = {};

    forEach(cloudAccounts.data, o => (accounts[o.uuid] = o));
    forEach(environments.data, o => {
      environmentMapping[o.id] = [];
      forEach(
        o.account_ids,
        a =>
          (environmentMapping[o.id] = [
            ...environmentMapping[o.id],
            accounts[a as string],
          ]),
      );
    });

    return environmentMapping;
  }, [cloudAccounts.data, environments.data]);

  return {
    getEnvironmentOptions,
    getGroupedAccountsUnderEnvironmentOptions,
    getEnvironmentAccountsMapping,
    environmentMapping,
    accountEnvironmentMapping,
  };
};
