import { useEffect } from 'react';

import { SecurityGraphNextWrapper } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphNext';
import { useCallSearchApi } from 'containers/Visibility/SecurityGraphNext/hooks/useCallSearchApi';
import { useCollectRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useCollectRedirectInfo';
import { useResetQuery } from 'containers/Visibility/SecurityGraphNext/hooks/useResetQuery';
import { useCallResourceAlertsApi } from 'containers/Visibility/SecurityGraphNext/hooks/useResourceAlerts';
import { useCallRiskContextApi } from 'containers/Visibility/SecurityGraphNext/hooks/useRiskContext';
import { securityGraphNextSaga } from 'containers/Visibility/SecurityGraphNext/saga';
import {
  reducer,
  sliceKey,
} from 'containers/Visibility/SecurityGraphNext/slice';
import { useInjector } from 'utils/inject';

export const SecurityGraphNext = () => {
  useInjector(sliceKey, reducer, securityGraphNextSaga);

  useResetQuery();

  return <ResetQuery />;
};

const ResetQuery = () => {
  useCollectRedirectInfo();

  useCallRiskContextApi();
  useCallResourceAlertsApi();
  useCallSearchApi();

  return <SecurityGraphNextWrapper />;
};
