import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PciIcon = props => (
  <Icon viewBox="0 0 104 68.4" w="full" h="full" {...props}>
    <g>
      <g>
        <polyline
          fill="#02797E"
          points="103.7,43.2 23.4,68.4 0,4.7 84.3,0 103.7,43.2 "
        />
      </g>
      <path
        fill="#FFFFFF"
        d="M43,30.4c0,1.6-0.4,3-1.1,4.3c-0.7,1.2-1.7,2.3-3,3.1c-1.3,0.8-2.9,1.4-4.7,1.8c-1.8,0.4-3.8,0.6-6.1,0.6h-1.6v8.2H16.1V21.1h12.2c4.6,0,8.2,0.8,10.8,2.3C41.7,24.9,43,27.3,43,30.4 M33,30.5c0-2.4-1.8-3.6-5.3-3.6h-1.3v7.3h1.2c1.7,0,3.1-0.3,4-0.9C32.5,32.8,33,31.8,33,30.5z"
      />
      <path
        fill="#FFFFFF"
        d="M68.8,48.4c-1.6,0.3-3.4,0.5-5.5,0.5c-2.9,0-5.5-0.3-7.7-1c-2.3-0.7-4.2-1.6-5.8-2.8c-1.6-1.2-2.8-2.7-3.6-4.4c-0.8-1.8-1.2-3.7-1.2-5.8c0-2.1,0.4-4,1.3-5.8c0.9-1.7,2.1-3.2,3.7-4.5c1.6-1.2,3.5-2.2,5.7-2.9c2.2-0.7,4.6-1,7.2-1c2,0,3.9,0.1,5.6,0.4c1.7,0.4,1.7,0.4,3,0.9l0,7.3c-1-0.6-2.2-1.1-3.5-1.4c-1.3-0.4-2.7-0.6-4.1-0.6c-2.4,0-4.3,0.7-5.8,1.9c-1.5,1.3-2.2,3.1-2.2,5.6c0,1.2,0.2,2.3,0.7,3.3c0.5,0.9,1.1,1.7,1.9,2.3c0.8,0.6,1.7,1.1,2.8,1.4c1.1,0.3,2.2,0.5,3.4,0.5c1.2,0,2.5-0.1,3.7-0.4c1.2-0.3,2.5-0.6,3.8-1.2l0,6.9C70.6,48,70.4,48.1,68.8,48.4"
      />
      <rect x="76.5" y="21.1" fill="#FFFFFF" width="10.5" height="27.4" />
      <path
        fill="#FFFFFF"
        d="M88.1,12.5c0,1.8-0.6,3.3-1.8,4.3c-1.2,1-2.7,1.6-4.5,1.6c-1.8,0-3.3-0.5-4.5-1.6c-1.2-1-1.8-2.5-1.8-4.3c0-1.9,0.6-3.3,1.8-4.4c1.2-1.1,2.7-1.6,4.5-1.6c1.8,0,3.3,0.5,4.5,1.6C87.5,9.1,88.1,10.6,88.1,12.5"
      />
    </g>
  </Icon>
);
