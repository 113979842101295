import React, { FC, useEffect, useState } from 'react';

import {
  Box,
  ListItem,
  Stack,
  Flex,
  Input,
  OrderedList,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { DetailsIdentifier, PageHeaderWithIcon } from 'components/DataDisplay';
import { CancelButton, PrimaryButton } from 'components/DataEntry';
import { defaultStyles as formDefaultStyles } from 'components/DataEntry/Form/styles';
import { IconTypes, Modal } from 'components/index';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

interface Props {
  isOpen: boolean;
  onClose(): void;
  selectedBlueprint: Dict<any>;
}

export const CopyBlueprint: FC<Props> = props => {
  const { isOpen, onClose, selectedBlueprint } = props;
  const { blueprintAction, blueprint } = useSelector(selectBlueprints);
  const dispatch = useDispatch();

  const [name, setName] = useState('');

  useEffect(() => {
    dispatch(
      actions.getBlueprintByID({ q: { blueprintId: selectedBlueprint.id } }),
    );
  }, [dispatch, selectedBlueprint]);

  const handleOnClickOK = () => {
    dispatch(
      actions.createBlueprint({
        q: {
          blueprintCreate: {
            name,
            is_enabled: true,
            rules: blueprint.data.rules,
            configs: [],
            description: blueprint.data.description,
            compliance: blueprint.data.compliance,
          },
        },
        onSuccess: () => {
          onClose();
          dispatch(actions.getBlueprints({ q: {} }));
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          label={'Copy Blueprint: ' + selectedBlueprint?.name}
          iconType={IconTypes.Copy}
          reversed
        />
      }
      body={
        <Stack spacing={4}>
          <DetailsIdentifier
            label="Original Blueprint Name"
            value={selectedBlueprint?.name}
          />
          {/*<DetailsIdentifier
            label="Environment(s)"
            value={
              blueprint.isLoading ? (
                <CustomSpinner />
              ) : (
                map(blueprint.data.configs, (o, index) => (
                  <>
                    <EnvironmentName environmentId={o.environment_id} />
                    {index + 1 !== blueprint.data.configs?.length && ', '}
                  </>
                ))
              )
            }
          />*/}
          <DetailsIdentifier
            label="New Blueprint Name"
            align="center"
            value={
              <Box>
                <Input
                  {...formDefaultStyles.textField?.input}
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
              </Box>
            }
          />
          <Stack spacing={0} color="primary">
            <Box>NOTE:</Box>
            <OrderedList pl={4}>
              <ListItem pl={2}>
                Only currently enforced policies will be copied to the new
                policy blueprint (exceptions will not be copied)
              </ListItem>
              <ListItem pl={2}>Existing workflows will not be copied</ListItem>
              <ListItem pl={2}>
                You’ll need to manually assign the new blueprint to environments
              </ListItem>
            </OrderedList>
          </Stack>
        </Stack>
      }
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <PrimaryButton
            onClick={() => handleOnClickOK()}
            isLoading={blueprintAction.isLoading || blueprint.isLoading}
            isDisabled={!name || name === selectedBlueprint?.name}
          >
            Okay
          </PrimaryButton>
        </Flex>
      }
      styles={{
        modal: {
          size: 'xl',
        },
      }}
    />
  );
};
