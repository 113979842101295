import React from 'react';

import { Icon } from '@chakra-ui/react';

export const MoneyDataIcon = props => (
  <Icon
    viewBox="0 0 203.76 203.76"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <path
      d="M122.607,154.819c0,5.393-4.387,9.78-9.779,9.78h-4.948v-19.561h4.948C118.221,145.039,122.607,149.427,122.607,154.819z
	 M97.88,115.479h-5.95c-5.392,0-9.777,4.386-9.777,9.777c0,5.396,4.386,9.782,9.777,9.782h5.95V115.479z M186.213,95.03v94.186
	c0,8.02-6.524,14.544-14.544,14.544H32.091c-8.02,0-14.544-6.524-14.544-14.544V95.03c0-8.021,6.524-14.545,14.544-14.545h5.034
	v-15.73C37.125,29.049,66.174,0,101.88,0s64.755,29.049,64.755,64.755v15.73h5.034C179.688,80.485,186.213,87.01,186.213,95.03z
	 M132.607,154.819c0-10.907-8.873-19.78-19.779-19.78h-4.948v-19.56h18.728c2.762,0,5-2.238,5-5s-2.238-5-5-5H107.88v-3.6
	c0-2.762-2.238-5-5-5s-5,2.238-5,5v3.6h-5.95c-10.905,0-19.777,8.872-19.777,19.782c0,10.905,8.872,19.777,19.777,19.777h5.95V164.6
	H78.152c-2.762,0-5,2.238-5,5s2.238,5,5,5H97.88v5.195c0,2.762,2.238,5,5,5s5-2.238,5-5V174.6h4.948
	C123.734,174.6,132.607,165.727,132.607,154.819z M156.635,64.755C156.635,34.563,132.072,10,101.88,10S47.125,34.563,47.125,64.755
	v15.73h109.51V64.755z"
    />
  </Icon>
);
