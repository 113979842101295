/**
 *
 * Asynchronously loads the component for Reports
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Roles = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Roles,
);
