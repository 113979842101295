import React, { FC, useEffect, useState } from 'react';

import { TaskStatus } from '@ariksa/awm';
import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { Flex, Text, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  PrimaryButton,
  CancelButton,
  PageHeaderWithIcon,
  RefreshIcon,
} from 'app/components';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { IModalProps } from 'components/Overlay/Modal/types';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { actions } from 'containers/Setup/CloudAccounts/slice';

interface Props extends IModalProps {
  onConfirm: () => void;
  cloudAccount: CloudAccountGetResponse;
  message: string;
}

export const RediscoverConfirmModal: FC<Props> = props => {
  const { isOpen, onClose, onConfirm, cloudAccount, message, ...rest } = props;
  const { accountSnapshotStatus: snapshotStatus } = useSelector(
    selectCloudAccounts,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    isOpen &&
      dispatch(
        actions.getSnapshotStatusForAnAccount({
          q: {
            getLatestWorkflowRequest: { account_ids: [cloudAccount?.uuid] },
          },
        }),
      );
  }, [dispatch, cloudAccount, isOpen]);

  const [isPendingSnapshot, setIsPendingSnapshot] = useState(false);

  useEffect(() => {
    setIsPendingSnapshot(
      snapshotStatus.data?.status !== TaskStatus.Failure &&
        snapshotStatus.data?.status !== TaskStatus.Success &&
        snapshotStatus.data?.status !== TaskStatus.Timeout,
    );
  }, [snapshotStatus.data]);

  const accountDetails = () => (
    <Stack>
      <DetailsField
        label="Account name"
        value={cloudAccount.name}
        placeholder="not available"
      />
      <DetailsField
        label="Account Id"
        value={cloudAccount.cloud_account_id}
        placeholder="not available"
      />
    </Stack>
  );

  const renderProgressMessage = () => (
    <Stack spacing={6}>
      {accountDetails()}
      <Text color="primary">
        NOTE: Previous snapshot is in progress so can not perform rediscovery.
      </Text>
    </Stack>
  );

  const renderConfirmationMessage = () => (
    <Stack spacing={6}>
      {accountDetails()}
      <Text>{message}</Text>
    </Stack>
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        header={
          <PageHeaderWithIcon
            label="Rediscover confirmation"
            reversed
            icon={<RefreshIcon />}
          />
        }
        footer={
          <Flex justifyContent={'space-between'} w="full">
            <CancelButton onClick={onClose} />
            <PrimaryButton onClick={onConfirm} isDisabled={isPendingSnapshot}>
              Yes
            </PrimaryButton>
          </Flex>
        }
        body={
          snapshotStatus.isLoading ? (
            <CustomSpinner loading={snapshotStatus.isLoading} />
          ) : isPendingSnapshot ? (
            renderProgressMessage()
          ) : (
            renderConfirmationMessage()
          )
        }
        {...rest}
      />
    </>
  );
};
