import { SearchResponseNode } from '@ariksa/inventory-core/api';

import { AGraphNodeProps } from 'components/Visualization/PixiGraph/core/Node';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export const useIntoGraphNodes = () => {
  const { getResourceType } = useResourceType();
  const intoGraphNodes = (
    nodes: SearchResponseNode[],
  ): (AGraphNodeProps & SearchResponseNode)[] => {
    return nodes?.map(node => {
      return {
        id: node.identity ?? '-',
        ariksaType:
          getResourceType(node.native_name ?? '') ?? node.native_name ?? '-',
        // used in map layout calculation
        nodeType: node.native_name ?? '-',
        ...node,
      };
    });
  };

  return {
    intoGraphNodes,
  };
};
