import { customTheme } from '../../../../../../theme';

export const defaultNodeStyle = {
  y: 16,
  x: 16,
  width: 120,
  height: 30,
  radius: 5,
};

export const images = {};

export const treeEdgeColor = customTheme.colors.primary;
