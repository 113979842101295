import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const HouseOrganizationIcon = createIcon({
  displayName: 'HouseOrganizationIcon',
  viewBox: '0 0 170 170',
  path: (
    <>
      <path d="M139.525 142.648H31.775V72.582h-7v73.567a3.5 3.5 0 0 0 3.5 3.5h114.75a3.5 3.5 0 0 0 3.5-3.5V72.582h-7v70.066z" />
      <path d="M86.701 20.792a3.5 3.5 0 0 0-3.401 0L4.999 64.324 8.4 70.442 85 27.856l76.6 42.586 3.401-6.118-78.3-43.532z" />
      <path d="M101.099 95.643H70.147a3.5 3.5 0 0 0-3.5 3.5v27.768h7v-24.268H97.6v24.268h7V99.143a3.501 3.501 0 0 0-3.501-3.5z" />
      <path d="M75.653 84.187l8.22 4.746a3.496 3.496 0 0 0 3.5 0l8.22-4.746a3.5 3.5 0 0 0 1.75-3.031v-9.492a3.5 3.5 0 0 0-1.75-3.031l-8.22-4.746a3.501 3.501 0 0 0-3.5 0l-8.22 4.746a3.5 3.5 0 0 0-1.75 3.031v9.492a3.5 3.5 0 0 0 1.75 3.031zm5.25-10.502l4.72-2.725 4.72 2.725v5.451l-4.72 2.725-4.72-2.725v-5.451z" />
    </>
  ),
});
