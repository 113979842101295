import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Stack,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BiPencil } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import {
  CheckmarkIcon,
  CopyIcon,
  DownIcon,
  H3,
  RightIcon,
  TrashIcon,
} from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { Select } from 'app/components/DataEntry/Select';
import { Modal } from 'app/components/Overlay/Modal';
import { cardTableStyles } from 'components/DataDisplay/Table';
import { ActionButton } from 'components/DataEntry/Button';
import { SwitchIO } from 'components/Elements/SwitchIO';
import { selectCurrentOrg } from 'containers/App/selectors';
import { toPascalCase } from 'utils/string';

import { RoleRowBody } from './RoleRowBody';

const { colors } = customTheme;

interface IRoleList {
  roles: Record<string, any>[];
  onUpdateName(role: any, name: string): void;
  onUpdateScope(role: any, resources: any);
  onUpdateResources(role: any, accounts: string[]);
  onDuplicate?(role: any);
  onSave?(role: any);
  onDelete?(role: any);
  onUpdate?(role: any);
  onToggle?: any;
  // onCreate?(role: any);
  onEdit?(role: any);
  resourcesTypes: Record<string, any>;
  accounts: Array<{ label: string; value: string }>;
  withActions?: boolean;
  isLoading?: boolean;
  isReset?: boolean;
  setIsReset?: (data: boolean) => void;
}

const enableMap = {
  create: ['view'],
  update: ['view'],
  delete: ['view'],
};

const disableMap = {
  view: ['update', 'create', 'delete'],
};
const scopes = ['view', 'create', 'update', 'delete'];

// RolesTable renders roles list
export const RoleList: FC<IRoleList> = props => {
  const {
    roles,
    withActions,
    isLoading,
    resourcesTypes,
    onToggle,
    isReset,
    setIsReset,
  } = props;
  const [currentOrganization, setCurrentOrganization] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [rowIndex, SetRowIndex] = useState(false);
  const organization = useSelector(selectCurrentOrg);
  const [selectedIdentities, setSelectedIdentities] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  //const [deleteIdentities, setDeleteIdentities] = useState<boolean>(false);

  useEffect(() => {
    if (isReset) {
      setSelectedIdentities([]);
      setIsReset && setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (selectedRow && selectedIdentities <= 0) {
      ['reporting', 'remediation', 'policy', 'compliance'].map(accessor => {
        props.onUpdateScope(selectedRow, {
          [accessor]: {
            view: false,
            create: false,
            update: false,
            delete: false,
          },
        });
      });
    }
  }, [selectedRow, selectedIdentities]);

  useEffect(() => {
    if (organization !== 'master') {
      // setCurrentOrganization(false);
    }
  }, [organization]);

  const renderName = useCallback(
    ({ value, row }) => {
      return (
        <Stack isInline ml={-3}>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            {row.expanded ? (
              <ActionButton icon={<DownIcon />} noHover />
            ) : (
              <ActionButton icon={<RightIcon />} noHover />
            )}
            <Box pl={2}>
              {row.editing || row.editingCopy ? (
                <Input
                  size={'sm'}
                  value={value}
                  onChange={e => props?.onUpdateName(row, e.target.value)}
                />
              ) : (
                <Box>{value}</Box>
              )}
            </Box>
          </Box>
        </Stack>
      );
    },
    [props],
  );

  const renderPermissionSummary = useCallback(
    ({ value, w, row, column }) => {
      const count = scopes.filter(s => value?.[s]).length;

      return (
        <Center
          w={w}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <SwitchIO
            value={count}
            count={count}
            isDisabled={column.isDisabled && column.isDisabled({ row })}
            onChange={val => {
              (count === 0 || count === 4 || row.expanded) &&
                !row.immutable &&
                props.onUpdateScope(row, {
                  [column.accessor]: scopes.reduce(
                    (o, s) => ({ ...o, [s]: val }),
                    {},
                  ),
                });
            }}
          />
        </Center>
      );
    },
    [props],
  );

  // helper function to prepare the data
  const onUpdateScope = useCallback(
    (role, resource, scope, value) => {
      let deps = {};
      if (value) {
        deps = (enableMap[scope] || []).reduce(
          (o, s) => ({ ...o, [s]: value }),
          {},
        );
      } else {
        deps = (disableMap[scope] || []).reduce(
          (o, s) => ({ ...o, [s]: value }),
          {},
        );
      }

      const updates = {
        [scope]: value,
        ...deps,
      };

      props?.onUpdateScope(role, {
        [resource]: { ...role[resource], ...updates },
      });
    },
    [props],
  );

  // permissions for one scope
  const renderPermissions = useCallback(
    ({ value, w, row, column }) => {
      return (
        <Stack onClick={e => e.stopPropagation()}>
          {scopes.map(s => (
            <Center {...cardTableStyles.bodyCell}>
              <SwitchIO
                value={value?.[s]}
                isDisabled={column.isDisabled && column.isDisabled({ row })}
                onChange={value =>
                  !row.immutable &&
                  onUpdateScope(row, column.accessor, s, value)
                }
              />
            </Center>
          ))}
        </Stack>
      );
    },
    [onUpdateScope],
  );

  useEffect(() => {
    selectedRow?.editing &&
      props?.onUpdateResources(
        selectedRow,
        selectedIdentities.map(v => v.value),
      );
  }, [selectedIdentities, selectedRow]);

  const handleSelect = (selected, row) => {
    setSelectedRow(row);
    if (selected.length >= 1) {
      setSelectedIdentities(selected);
    } else if (selected.length === 0) {
      setIsOpen(true);
    }
  };

  const allOption = {
    label: 'Select all',
    value: '*',
  };

  const renderResources = useCallback(
    ({ value, row }) => {
      let temp: any[] = [];
      if (value[0] === '*') {
        temp = resourcesTypes.map(res => res.value);
        temp.unshift('*');
      }

      value = temp.length > 0 ? temp : value;

      return (
        <Box w="full" onClick={e => e.stopPropagation()}>
          <Select
            height="32px"
            // isDisabled={!row.editing}
            showTotalSelected
            value={value.filter(a => a).map(v => ({ label: v, value: v }))}
            options={
              row.editing
                ? resourcesTypes
                : value.filter(a => a).map(v => ({ label: v, value: v }))
            }
            onChange={selected => {
              handleSelect(selected, row);
            }}
            isMulti
            allOption={row.editing && allOption}
            isClearable={false}
            isDisabled={organization === 'master'}
            styles={{
              option: provided => ({
                textAlign: 'left',
              }),
            }}
          />
        </Box>
      );
    },
    [allOption, organization, resourcesTypes],
  );

  const renderScopes = useCallback(() => {
    return (
      <Stack pl={10}>
        {scopes.map(s => (
          <Box {...cardTableStyles.bodyCell}>{toPascalCase(s)}</Box>
        ))}
      </Stack>
    );
  }, []);

  const renderActions = useCallback(
    ({ value, row }) => {
      return (
        <HStack>
          {row.editingCopy ? (
            <ActionButton
              label={'Save'}
              icon={<CheckmarkIcon />}
              onClick={e => {
                e.stopPropagation();
                props.onSave && props.onSave(row);
              }}
              key={'save'}
            />
          ) : (
            <ActionButton
              label={'Copy'}
              icon={<CopyIcon />}
              onClick={e => {
                e.stopPropagation();
                props.onDuplicate && props.onDuplicate(row);
              }}
              key={'copy'}
            />
          )}

          {row.editing ? (
            <ActionButton
              label={'Done'}
              icon={<CheckmarkIcon />}
              onClick={e => {
                e.stopPropagation();
                props.onUpdate && props.onUpdate(row);
              }}
              key={'done'}
            />
          ) : (
            <ActionButton
              label={'Edit'}
              icon={
                <BiPencil
                  style={{ color: row.immutable ? colors.gray[100] : '' }}
                />
              }
              onClick={e => {
                if (!row.immutable) {
                  props.onEdit && props.onEdit(row);
                }
              }}
              key={'edit'}
            />
          )}

          {
            <ActionButton
              label={'Delete'}
              icon={
                <TrashIcon
                  style={{ color: row.immutable ? colors.gray[100] : '' }}
                />
              }
              onClick={e => {
                if (!row.immutable) {
                  e.stopPropagation();
                  props?.onDelete?.(row);
                }
              }}
              key={'trash'}
            />
          }
        </HStack>
      );
    },
    [props],
  );

  const renderHeader = () => {
    return (
      <Thead
        style={{
          position: 'sticky',
          top: '0',
          background: 'white',
        }}
      >
        <Tr>
          <Th borderBottom="0px"></Th>
          <Th
            fontSize="14px"
            textAlign="center"
            borderColor={customTheme.colors.gray['100']}
            colSpan={2}
            borderRight="1px solid #E2E8F0"
            borderLeft="1px solid #E2E8F0"
          >
            Ariksa Permissions
          </Th>
          <Th
            fontSize="14px"
            textAlign="center"
            colSpan={8}
            align="center"
            borderLeft="1px solid #E2E8F0"
            borderRight="1px solid #E2E8F0"
          >
            Cloud Permissions
          </Th>
          {/* <Th borderBottom="0px"></Th> */}
        </Tr>
      </Thead>
    );
  };

  const handleIsDisabled = ({ row }) => {
    if (row?.resources.length > 0 && organization !== 'master') {
      return false;
    } else {
      return true;
    }
  };

  const roleColumns = [
    {
      header: 'Role',
      render: renderName,
      body: renderScopes,
      accessor: 'name',
      sortable: true,
      styles: {
        textAlign: 'left',
        width: '200px',
        justifyContent: 'flex-start',
        pl: 12,
      },
    },
    ...(currentOrganization
      ? [
          {
            header: 'System',
            render: renderPermissionSummary,
            body: renderPermissions,
            accessor: 'system',
            sortable: true,
            align: 'center',
            isDisabled: () => organization !== 'master',
            styles: {
              borderLeft: '1px solid #E2E8F0',
              cell: { borderLeft: '1px solid #E2E8F0' },
            },
          },
          {
            header: 'Tenancy',
            render: renderPermissionSummary,
            body: renderPermissions,
            accessor: 'tenancy',
            align: 'center',
            isDisabled: () => organization !== 'master',
            styles: {
              borderRight: '1px solid #E2E8F0',
              cell: { borderRight: '1px solid #E2E8F0' },
            },
          },
          {
            header: 'Cloud Onboarding',
            render: renderPermissionSummary,
            body: renderPermissions,
            accessor: 'cloud_onboarding',
            sortable: true,
            align: 'center',
            isDisabled: () => organization === 'master',
          },
          {
            header: 'Visibility',
            render: renderPermissionSummary,
            body: renderPermissions,
            accessor: 'visibility',
            sortable: true,
            align: 'center',
            isDisabled: () => organization === 'master',
          },
        ]
      : []),
    {
      header: 'Identities',
      render: renderResources,
      accessor: 'resources',
      sortable: true,
      align: 'center',
      styles: {
        cell: { 'min-width': '200px', overflow: 'visible' },
      },
    },
    {
      header: 'Reporting',
      render: renderPermissionSummary,
      body: renderPermissions,
      accessor: 'reporting',
      sortable: true,
      align: 'center',
      isDisabled: handleIsDisabled,
    },
    {
      header: 'Remediation',
      render: renderPermissionSummary,
      body: renderPermissions,
      accessor: 'remediation',
      sortable: true,
      align: 'center',
      isDisabled: handleIsDisabled,
    },
    {
      header: 'Policy',
      render: renderPermissionSummary,
      body: renderPermissions,
      accessor: 'policy',
      sortable: true,
      align: 'center',
      isDisabled: handleIsDisabled,
    },
    {
      header: 'Compliance',
      render: renderPermissionSummary,
      body: renderPermissions,
      accessor: 'compliance',
      sortable: true,
      align: 'center',
      isDisabled: handleIsDisabled,
      styles: {
        borderRight: '1px solid #E2E8F0',
        cell: { borderRight: '1px solid #E2E8F0' },
      },
    },
    ...(withActions
      ? [
          {
            header: 'Actions',
            render: renderActions,
            accessor: 'actions',
            styles: {
              justifyContent: 'center',
              minW: 90,
            },
          },
        ]
      : []),
  ];

  const subComponent = ({ row, columns, expanded }) => {
    return RoleRowBody({ row, columns, expanded });
  };

  const handleOnToggle = (role, rowIndex) => {
    setExpanded(!expanded);
    SetRowIndex(rowIndex);
    onToggle(role);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        body={
          <>
            <Stack spacing={8}>
              <H3>Warning</H3>
              <Text fontSize="16">Need to select at least one IDENTITES</Text>
              <Stack spacing={4} direction="row" justifyContent="space-between">
                <Button
                  colorScheme="red"
                  variant="solid"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="teal"
                  variant="outline"
                  onClick={() => {
                    setSelectedIdentities([]);
                    setIsOpen(false);
                  }}
                >
                  Ok
                </Button>
              </Stack>
            </Stack>
          </>
        }
      />
      <Table
        columns={roleColumns}
        data={roles}
        topHeader={renderHeader}
        isLoading={isLoading}
        subComponent={subComponent}
        onRowClick={handleOnToggle}
        expanded={expanded}
        rowIndex={rowIndex}
      />
    </>
  );
};
