import { customTheme } from 'theme';

import { customizedPieChartLegend } from './utils';

export const defaultStyles = {
  root: {
    h: '100%',
    overflow: 'auto',
    //position: 'relative',
  },
  pieChart: {
    margin: { right: 0 },
  },
  pie: {
    stroke: customTheme.colors.pieChartBorder,
    minAngle: 4,
    paddingAngle: 3,
  },
  legend: {
    align: 'right',
    layout: 'vertical',
    iconType: 'square',
    verticalAlign: 'bottom',
    iconSize: 10,
    formatter: customizedPieChartLegend,
    wrapperStyle: {
      //top: 0,
      //right: 0,
      height: '100%',
      overflow: 'auto',
    },
  },
  tooltip: {
    contentStyle: {
      fontSize: '14px',
    },
  },
  label: {
    position: 'center',
    fontSize: '20px',
    fontWeight: 600,
  },
};
