import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { GraphIncreaseIcon } from 'components/Icons';
import { LineChart } from 'components/Visualization/Charts';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { lineChartStylesVulnerabilityDashboard } from 'containers/Visibility/Vulnerabilities/Components/styles';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { formatDate } from 'utils/date';

export const HostsWithCriticalAndHighVulnerabilities = () => {
  const { hosts } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setData(
      map(hosts.data?.critical_vulnerabilities, (item, date) => ({
        Date: formatDate(date, 'D-MMM'),
        Critical: item,
        High: hosts.data?.high_vulnerabilities[date],
      })),
    );
  }, [hosts.data]);

  return (
    <DashboardOverviewCard
      label="Hosts with critical & high severity"
      icon={<GraphIncreaseIcon />}
      iconBgColor="critical"
      content={
        <Box w="full" h="full">
          <LineChart
            data={data}
            isLoading={hosts.isLoading}
            xField="Date"
            yField={['Critical', 'High']}
            styles={lineChartStylesVulnerabilityDashboard}
            ignoreZero
          />
        </Box>
      }
    />
  );
};
