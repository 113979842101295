import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CustomerJourneyBulbIcon = props => (
  <Icon viewBox="0 0 233 233" w="full" h="full" color="inherit" {...props}>
    <g transform="translate(-592 -312)">
      <g>
        <path
          d="M729.06 493.526 687.287 493.526C683.424 493.526 680.292 496.658 680.292 500.521 680.292 504.384 683.424 507.516 687.287 507.516L729.06 507.516C732.923 507.516 736.055 504.384 736.055 500.521 736.055 496.658 732.923 493.526 729.06 493.526Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M708.175 531.234C716.107 531.233 722.705 525.132 723.327 517.224L693.02 517.224C693.642 525.133 700.242 531.234 708.175 531.234Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M768.786 419.942 768.786 417.845C768.181 384.738 741.287 358.152 708.175 357.93 675.06 358.15 648.162 384.736 647.556 417.845L647.556 419.942C647.782 427.123 649.209 434.215 651.777 440.924 654.234 447.256 657.802 453.098 662.313 458.176 667.861 464.244 673.946 476.01 676.511 481.233 677.297 482.818 678.914 483.82 680.683 483.82L735.659 483.82C737.429 483.82 739.046 482.818 739.834 481.233 742.397 476.013 748.481 464.244 754.03 458.176 758.54 453.098 762.108 447.256 764.566 440.924 767.133 434.214 768.559 427.123 768.786 419.942ZM754.799 419.699C754.588 425.287 753.46 430.802 751.459 436.024 749.61 440.73 746.942 445.071 743.579 448.846 738.235 455.287 733.659 462.328 729.941 469.826L686.394 469.826C682.677 462.327 678.101 455.287 672.756 448.846 669.393 445.071 666.726 440.73 664.878 436.024 662.879 430.804 661.752 425.29 661.543 419.704L661.543 417.957C662.014 392.499 682.708 372.066 708.17 371.917 733.633 372.065 754.328 392.502 754.794 417.962Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M708.65 348.406C711.331 348.406 713.505 346.233 713.505 343.552L713.505 326.562C713.505 323.882 711.331 321.708 708.65 321.708 705.97 321.708 703.796 323.882 703.796 326.562L703.796 343.552C703.796 346.233 705.97 348.406 708.65 348.406Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M651.041 369.765C652.904 371.693 655.977 371.746 657.905 369.884 659.833 368.021 659.887 364.949 658.024 363.02 657.985 362.98 657.946 362.94 657.905 362.901L645.881 350.877C643.953 349.014 640.88 349.068 639.017 350.996 637.201 352.877 637.201 355.86 639.017 357.741Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M762.893 372.097C764.181 372.097 765.415 371.585 766.325 370.675L778.337 358.661C780.16 356.695 780.045 353.624 778.08 351.801 776.216 350.072 773.335 350.073 771.473 351.804L759.469 363.811C757.573 365.707 757.574 368.78 759.47 370.676 760.38 371.586 761.614 372.097 762.901 372.097Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M633.26 412.724 616.271 412.724C613.59 412.724 611.417 414.897 611.417 417.578 611.417 420.259 613.59 422.432 616.271 422.432L633.26 422.432C635.941 422.432 638.115 420.259 638.115 417.578 638.115 414.897 635.941 412.724 633.26 412.724Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M651.041 465.236 639.027 477.25C637.053 479.065 636.924 482.135 638.738 484.109 640.552 486.083 643.623 486.212 645.597 484.398 645.696 484.308 645.79 484.213 645.881 484.114L657.895 472.1C659.824 470.238 659.877 467.165 658.015 465.236 656.152 463.308 653.079 463.255 651.151 465.117 651.11 465.156 651.071 465.196 651.031 465.236Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M766.325 464.316C764.5 462.353 761.428 462.241 759.465 464.067 757.502 465.892 757.39 468.964 759.215 470.927 759.297 471.014 759.381 471.099 759.469 471.18L771.48 483.192C773.409 485.054 776.482 485.001 778.344 483.072 780.161 481.191 780.161 478.209 778.344 476.328Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
        <path
          d="M800.122 412.559 783.133 412.559C780.452 412.559 778.279 414.732 778.279 417.413 778.279 420.094 780.452 422.267 783.133 422.267L800.122 422.267C802.803 422.267 804.977 420.094 804.977 417.413 804.977 414.732 802.803 412.559 800.122 412.559Z"
          stroke="currentColor"
          strokeWidth="2.42708"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          fill="currentColor"
          fillRule="nonzero"
          fillOpacity="1"
        />
      </g>
    </g>
  </Icon>
);
