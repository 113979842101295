/**
 *
 * Cloud Account Wizard
 *
 */

import React, { useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box } from '@chakra-ui/react';
import { filter, find, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIcon } from 'components/Icons/Components';
import { usePageContentContext } from 'components/Layout';
import { SideNavKey } from 'components/Navigation';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { AriksaOutpostForData } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForData';
import { AriksaOutpost } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForVulnerability';
import { actions } from 'containers/Setup/Outpost/slice';
import { OutpostProps } from 'containers/Setup/Outpost/types';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const OutpostWizard = () => {
  const params = useParams<{
    type: string;
  }>();
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();
  const cloudAccounts = useSelector(selectOnboardedCloudAccounts);

  const [accountOptions, setAccountOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [selectedAccount, setSelectedAccount] = useState<Record<string, any>>(
    {},
  );
  const [regionOptions, setRegionOptions] = useState<Record<string, any>[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<Record<string, any>>({});
  const [selectedTab, setSelectedTab] = useState<SideNavKey>(
    CloudProviders.Aws,
  );
  const [zoneOptions, setZoneOptions] = useState<Record<string, any>[]>([]);
  const [selectedZone, setSelectedZone] = useState<Record<string, any>>({});

  useEffect(() => {
    let options = map(
      filter(cloudAccounts.data, o => o.cloud_type === selectedTab),
      o => ({
        label: o?.name + ' (Account ID: ' + o?.cloud_account_id + ')',
        value: o.uuid,
        data: o,
        icon: getIcon(selectedTab as string),
      }),
    );
    setAccountOptions(options);
    setSelectedAccount(options?.[0]);
  }, [cloudAccounts.data, selectedTab]);

  useEffect(() => {
    if (!!searchParams.account_id) {
      setSelectedAccount(
        find(accountOptions, o => o.value === searchParams.account_id) || {},
      );
    }
  }, [searchParams, accountOptions]);

  useEffect(() => {
    if (!!selectedAccount?.value) {
      dispatch(
        sharedStateActions.getRegions({
          q: { accountId: [selectedAccount?.value] },
          onSuccess: res => {
            const options = map(res, o => ({ label: o, value: o }));
            setRegionOptions(options);
            setSelectedRegion(options[0]);
            if (isEmpty(options)) {
              setZoneOptions([]);
              setSelectedZone({});
            } else if (selectedTab === CloudProviders.Gcp) {
              //get zones of a region
              dispatch(
                actions.getZones({
                  q: {
                    accountUuid: selectedAccount?.value,
                    project: selectedAccount?.data?.cloud_account_id,
                    region: options[0]?.value,
                  },
                  onSuccess: r => {
                    const items = map(r, o => ({ label: o, value: o }));
                    setZoneOptions(items);
                    setSelectedZone(items[0]);
                  },
                }),
              );
            }
          },
        }),
      );
    }
  }, [selectedAccount, dispatch, selectedTab]);

  useEffect(() => {
    if (
      !!selectedAccount?.value &&
      !!selectedRegion?.value &&
      selectedTab === CloudProviders.Gcp
    ) {
      //get zones of a region
      dispatch(
        actions.getZones({
          q: {
            accountUuid: selectedAccount?.value,
            project: selectedAccount?.data?.cloud_account_id,
            region: selectedRegion?.value,
          },
          onSuccess: r => {
            const items = map(r, o => ({ label: o, value: o }));
            setZoneOptions(items);
            setSelectedZone(items[0]);
          },
        }),
      );
    }
  }, [selectedAccount, dispatch, selectedTab, selectedRegion]);

  const getComponent = () => {
    const props: OutpostProps = {
      accountOptions,
      selectedAccount,
      setSelectedAccount,
      regionOptions,
      selectedRegion,
      setSelectedRegion,
      selectedTab,
      setSelectedTab,
      zoneOptions,
      selectedZone,
      setSelectedZone,
    };
    switch (params?.type) {
      case 'data':
        return <AriksaOutpostForData {...props} />;
      default:
        return <AriksaOutpost {...props} />;
    }
  };

  return <Box h={contentHeight + 'px'}>{getComponent()}</Box>;
};
