import React from 'react';

import { createStandaloneToast } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { ToastProps } from 'components/Toast/types';

import { CustomToast } from './CustomToast';
import { defaultConfig } from './utils';

const { toast } = createStandaloneToast({ theme: customTheme });

export const errorToast = (props: ToastProps) => {
  console.error(props);
  // toast({
  //   ...defaultConfig,
  //   render: renderProps => (
  //     <CustomToast
  //       {...defaultConfig}
  //       {...props}
  //       status={'error'}
  //       {...renderProps}
  //     />
  //   ),
  // });
};

export const successToast = (props: ToastProps) => {
  toast({
    ...defaultConfig,
    render: renderProps => (
      <CustomToast
        {...defaultConfig}
        {...props}
        status={'success'}
        {...renderProps}
      />
    ),
  });
};

export const infoToast = (props: ToastProps) => {
  toast({
    ...defaultConfig,
    render: renderProps => (
      <CustomToast
        {...defaultConfig}
        {...props}
        status={'info'}
        {...renderProps}
      />
    ),
  });
};
