import React, { useEffect, FC, useState, useCallback } from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack, Divider, Grid, GridItem } from '@chakra-ui/react';
import { forEach, lowerCase, map } from 'lodash';
import { useCompliancePermissionsService } from 'services/ComplianceService/useInventoryPermissionsService';
import { useDataScanningService } from 'services/DataScanning';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import { customTheme } from 'theme';

import { DetailsIdentifier, formatBytes, Tag } from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { S3RiskAnalysisRadarChart } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3RiskAnalysisRadarChart';
import {
  renderDivider,
  renderRating,
  renderRiskAnalysis,
  renderSpinner,
} from 'containers/Visibility/Data/Components/DataTable/utils';
import { SENSITIVITY_CATEGORIES } from 'containers/Visibility/Data/types';

interface Props {
  row: Record<string, any>;
  isExpanded: boolean;
}

export const DataSourcesSubcomponent: FC<Props> = props => {
  const { row, isExpanded } = props;
  const { dataSourceSensitiveInfo } = useDataScanningService();
  const { canAccessEntityCount } = useInventoryInsightService();
  const { riskAnalysis } = useCompliancePermissionsService();
  const [sensitiveDataCount, setSensitiveDataCount] = useState<
    Record<string, any>
  >({});
  const [canAccessData, setCanAccessData] = useState<Record<string, any>>({});
  const [isCodeRepo, setIsCodeRepo] = useState(false);
  const canAccess = {
    Groups: 'Group',
    Users: 'User',
    Machines: 'VirtualMachines',
    Roles: 'Role',
  };

  useEffect(() => {
    setIsCodeRepo(
      row?.source_type === SupportedServices.GitLabBranch ||
        row?.source_type === SupportedServices.GitHubBranch ||
        row?.source_type === SupportedServices.BitBucketBranch ||
        row?.source_type === NativeResources.GitLabProject ||
        row?.source_type === NativeResources.GitHubRepository ||
        row?.source_type === NativeResources.BitBucketRepository,
    );
  }, [row]);

  useEffect(() => {
    isExpanded &&
      row?.source &&
      dataSourceSensitiveInfo.sync({
        source: row?.source,
      });
  }, [dataSourceSensitiveInfo, row, isExpanded]);

  useEffect(() => {
    isExpanded &&
      !(
        row?.source_type === SupportedServices.GitLabBranch ||
        row?.source_type === SupportedServices.GitHubBranch ||
        row?.source_type === SupportedServices.BitBucketBranch ||
        row?.source_type === NativeResources.GitLabProject ||
        row?.source_type === NativeResources.GitHubRepository ||
        row?.source_type === NativeResources.BitBucketRepository
      ) &&
      canAccessEntityCount.sync({
        uuid: row?.source_UUID,
        accountId: row?.account_id,
        nativeResourceType: row?.source_type,
      });
  }, [canAccessEntityCount, row, isExpanded]);

  //get risk analysis info
  useEffect(() => {
    !!row?.account_id &&
      !(
        row?.source_type === SupportedServices.GitLabBranch ||
        row?.source_type === SupportedServices.GitHubBranch ||
        row?.source_type === SupportedServices.BitBucketBranch ||
        row?.source_type === NativeResources.GitLabProject ||
        row?.source_type === NativeResources.GitHubRepository ||
        row?.source_type === NativeResources.BitBucketRepository
      ) &&
      isExpanded &&
      riskAnalysis.sync({
        resourceUuid: row?.source_UUID,
        accountId: row?.account_id,
      });
  }, [riskAnalysis, row, isCodeRepo, isExpanded]);

  useEffect(() => {
    let d: Record<string, any> = {};
    forEach(
      dataSourceSensitiveInfo.data?.sensitive_data_categories,
      o => (d[o?.category] = o),
    );
    setSensitiveDataCount(d);
  }, [dataSourceSensitiveInfo.data]);

  useEffect(() => {
    let d: Record<string, any> = {};
    forEach(canAccessEntityCount.data, o => {
      d[o.access_type] = {};
      forEach(o.entity_counts, (c, key) => (d[o.access_type][key] = c));
    });
    setCanAccessData(d);
  }, [canAccessEntityCount.data]);

  const renderCanAccess = type => (
    <Stack h="150px" justify="space-between">
      {map(canAccess, (o, key) => (
        <HStack key={key + '-key'}>
          <Box>{key}:</Box>
          <Box>{canAccessData?.[type]?.[o] ?? 0}</Box>
        </HStack>
      ))}
    </Stack>
  );

  const getRating = useCallback(() => {
    return !isCodeRepo && !!riskAnalysis?.data?.overall_risk
      ? riskAnalysis?.data?.overall_risk?.replace(' Risk', '')
      : 'None';
  }, [isCodeRepo, riskAnalysis?.data]);

  console.log();

  return (
    <Box w="full" h="340px">
      <Stack w="full" spacing={3} h="full">
        <HStack justify="space-between" px={0}>
          <DetailsIdentifier
            label="Sensitivity Score"
            value={renderRating(
              dataSourceSensitiveInfo?.data?.sensitivity_score,
              'medium',
              dataSourceSensitiveInfo?.isLoading,
            )}
          />
          <DetailsIdentifier
            label="Risk Rating"
            value={renderRating(
              getRating(),
              isCodeRepo || !riskAnalysis?.data?.overall_risk
                ? 'gray.100'
                : lowerCase(getRating()),
              riskAnalysis.isLoading,
            )}
          />
          <DetailsField
            label="Total Size"
            value={formatBytes(dataSourceSensitiveInfo?.data?.total_size)}
          />
          <DetailsField
            label={
              row?.source_type === SupportedServices.SimpleStorageService
                ? 'Total Files'
                : 'Tables'
            }
            value={formatNumber(
              dataSourceSensitiveInfo?.data?.total_object_number,
            )}
          />
          <DetailsField
            label="Sensitive records"
            value={formatNumber(
              dataSourceSensitiveInfo?.data?.total_sensitive_data_number,
            )}
          />
        </HStack>
        <Divider color={customTheme.colors.gray['200']} />

        <Grid templateColumns="repeat(5, 1fr)" w="full" gap={4} h="full">
          <GridItem colSpan={1}>
            <Stack spacing={3}>
              <Grid
                fontWeight={600}
                gap={4}
                templateColumns="repeat(6, 1fr)"
                w="full"
              >
                <GridItem colSpan={2}>Category</GridItem>
                <GridItem colSpan={4}>Sensitivity level & count</GridItem>
              </Grid>
              <Divider color={customTheme.colors.gray['200']} />
              <Stack h="150px" justify="space-between">
                {dataSourceSensitiveInfo.isLoading && renderSpinner()}
                {!dataSourceSensitiveInfo.isLoading &&
                  map(SENSITIVITY_CATEGORIES, (o, key) => (
                    <Grid gap={4} templateColumns="repeat(6, 1fr)">
                      <GridItem colSpan={2}>{key}</GridItem>
                      <GridItem colSpan={4}>
                        <Tag
                          label={
                            <HStack spacing={1} w="full">
                              <Box py={0.5} w="full">
                                {o}
                              </Box>
                              <Box
                                borderLeft="1px solid"
                                borderColor="white"
                                h={3}
                              />
                              <Box py={0.5} w="full" pl={4}>
                                {formatNumber(
                                  sensitiveDataCount?.[key]?.count ?? 0,
                                )}
                              </Box>
                            </HStack>
                          }
                          styles={{
                            tag: { w: 'full', py: 0, bg: o?.toLowerCase() },
                            label: {
                              color: 'white',
                              w: 'full',
                              fontSize: 'sm',
                            },
                          }}
                        />
                      </GridItem>
                    </Grid>
                  ))}
              </Stack>
            </Stack>
          </GridItem>

          {!isCodeRepo && (
            <GridItem colSpan={1}>
              <HStack spacing={5}>
                {renderDivider()}
                <Stack w="full" spacing={3}>
                  <Box fontWeight={600} px={2}>
                    Key risk factors
                  </Box>
                  <Divider color={customTheme.colors.gray['200']} />
                  <Stack h="150px" justify="space-between" px={2}>
                    {riskAnalysis.isLoading && renderSpinner()}
                    {!riskAnalysis.isLoading &&
                      map(riskAnalysis?.data?.risk_factors, o => (
                        <HStack>
                          <Box>{o?.name}:</Box>
                          {renderRiskAnalysis(o?.status, o?.current_state)}
                        </HStack>
                      ))}
                  </Stack>
                </Stack>
                {renderDivider()}
              </HStack>
            </GridItem>
          )}

          {!isCodeRepo && (
            <GridItem colSpan={1}>
              <HStack spacing={5}>
                <Stack w="full" spacing={3}>
                  <Grid
                    fontWeight={600}
                    gap={6}
                    templateColumns="repeat(4, 1fr)"
                    px={2}
                  >
                    <GridItem colSpan={2}>Internal</GridItem>
                    <GridItem colSpan={2}>External</GridItem>
                  </Grid>
                  <Divider color={customTheme.colors.gray['200']} />
                  <Stack h="150px" justify="space-between" px={2}>
                    {canAccessEntityCount.isLoading && renderSpinner()}
                    {!canAccessEntityCount.isLoading && (
                      <Grid gap={6} templateColumns="repeat(4, 1fr)" w="full">
                        <GridItem colSpan={2}>
                          {renderCanAccess('internal')}
                        </GridItem>
                        <GridItem colSpan={2}>
                          {renderCanAccess('external')}
                        </GridItem>
                      </Grid>
                    )}
                  </Stack>
                </Stack>
                {row.source_type !== NativeResources.SnowflakeDatabases &&
                  renderDivider()}
              </HStack>
            </GridItem>
          )}
          <GridItem colSpan={2}>
            {!isCodeRepo &&
              row.source_type !== NativeResources.SnowflakeDatabases && (
                <Stack w="full" h="full">
                  <Box fontWeight={600} px={2}>
                    Risk map
                  </Box>
                  <Box flex={1} w="full" h="300px">
                    <S3RiskAnalysisRadarChart data={riskAnalysis?.data ?? {}} />
                  </Box>
                </Stack>
              )}
          </GridItem>
        </Grid>
      </Stack>
    </Box>
  );
};
