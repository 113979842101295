import React, { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { GraphIncreaseIcon } from 'components/Icons';
import { LineChart } from 'components/Visualization/Charts';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { lineChartStylesVulnerabilityDashboard } from 'containers/Visibility/Vulnerabilities/Components/styles';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { formatDate } from 'utils/date';

export const HostsWithUnpatchedOSAndTechnology = () => {
  const { hosts } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const osData = hosts.data?.unpatched_os;
    const technologyData = hosts.data?.unpatched_technology;
    const items = map(osData, (o, date) => ({
      Date: formatDate(date, 'D-MMM'),
      'Unpatched OS': o,
      'Unpatched Technology': technologyData?.[date],
    }));

    setData(items);
  }, [hosts.data]);

  return (
    <DashboardOverviewCard
      label="Hosts with unpatched technology"
      icon={<GraphIncreaseIcon />}
      iconBgColor="critical"
      content={
        <Box w="full" h="full">
          <LineChart
            data={data}
            isLoading={hosts.isLoading}
            xField="Date"
            yField={['Unpatched OS', 'Unpatched Technology']}
            colorMap={{
              'Unpatched OS': customTheme.colors.medium,
              'Unpatched Technology': customTheme.colors.low,
            }}
            styles={lineChartStylesVulnerabilityDashboard}
          />
        </Box>
      }
    />
  );
};
