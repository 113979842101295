import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container } from 'pixi.js';

import { ABound } from './Bound';
import { ADimension } from './Dimension';

export class Element {
  bound: ABound;
  dimension: ADimension;

  constructor() {
    this.bound = ABound.default();
    this.dimension = ADimension.default();
  }

  render(g: Graphics, container: Container) {}
}
