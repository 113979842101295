import React from 'react';

import { Show } from '@ariksa/compliance-policies';
import { Box, Flex, HStack, Center, Stack } from '@chakra-ui/react';

import { H4, renderSeverityCountTags } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { RadioButtonGroup } from 'components/DataEntry';
import {
  CheckmarkCircleOutlineIcon,
  CloseCircleOutlineIcon,
  FilterIcon,
  QuestionIcon,
} from 'components/Icons';
import { Popover } from 'components/Overlay';
import { BarProgress } from 'components/Visualization/Progress/BarProgress';
import { toTitleCase } from 'utils/string';

export const statusOptions = [
  { label: 'All', value: Show.All },
  { label: 'Violation', value: Show.Failed },
  { label: 'Passed', value: Show.Passed },
];

export const exceptionOptions = [
  { label: 'all values', value: '' },
  { label: 'has', value: 'yes' },
  { label: 'does not have', value: 'no' },
];

export const HeaderWithFilter = ({
  label,
  options,
  onChange,
  value,
  isOpen,
  onClose,
  onOpen,
}) => (
  <HStack>
    <Box>{label}</Box>
    <Box>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        styles={{
          content: { pos: 'relative', w: 'full' },
          footer: { p: 0 },
        }}
        button={
          <Box boxSize={3} onClick={() => onOpen()}>
            <FilterIcon />
          </Box>
        }
        header={
          <H4 outline={'none'} pr={10} color="black" fontWeight={400}>
            Filter by {toTitleCase(label)}
          </H4>
        }
        body={
          <>
            <RadioButtonGroup
              options={options}
              value={value}
              onChange={option => {
                onChange(option.value);
                onClose();
              }}
              styles={{ label: { textAlign: 'left' } }}
            />
          </>
        }
      />
    </Box>
  </HStack>
);

export const renderStatus = (upper, lower) => {
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Box pb="2">
        ({upper}/{lower} - {Math.round((upper / lower) * 100)}
        %)
      </Box>
      <Flex
        w="full"
        h="full"
        alignItems="center"
        justifyContent="space-between"
      >
        <BarProgress total={lower} value={upper} width="full" />
      </Flex>
    </Flex>
  );
};

export const renderCompliantEntities = ({ value }) => {
  const compliant = value?.total_entities - (value?.entities_with_issues ?? 0);
  const total = value?.total_entities;

  return (
    <Stack color="gray.250" spacing={1} align="start">
      <Box>
        {compliant || 0} / {total} ({Math.round((compliant / total) * 100)}
        %)
      </Box>
      <BarProgress total={total} value={compliant} width="full" />
    </Stack>
  );
};

export const renderTableHeaderWithTooltip = (
  label: string,
  tooltip: string,
  justify?: string,
) => {
  return (
    <HStack justify={justify}>
      <Box>{label}</Box>
      <CustomTooltip label={tooltip} w={24}>
        <Box boxSize={4} bg="primary" color="white" borderRadius="50%">
          <QuestionIcon />
        </Box>
      </CustomTooltip>
    </HStack>
  );
};

export const renderComplianceStatus = ({ row }) => {
  const value = row?.check_stats?.failed;
  const total = value?.CRITICAL + value?.MEDIUM + value?.LOW;
  return (
    <HStack align="center">
      <Box
        boxSize={5}
        //bg={!!total ? 'critical' : 'green.300'}
        color={!!total ? 'critical' : 'green.300'}
        //borderRadius="full"
        //p="2px"
      >
        <Center>
          {!!total ? (
            <CloseCircleOutlineIcon />
          ) : (
            <CheckmarkCircleOutlineIcon />
          )}
        </Center>
      </Box>
      <Box>{!!total ? 'Failed' : 'Passed'}</Box>
    </HStack>
  );
};

export const renderPassedChecks = ({ value }) => {
  const passed = value?.passed;
  const total = value?.total_performed;
  return (
    <Stack color="gray.250" spacing={1} align="start">
      <Box>
        {passed ? formatNumber(passed, 1) : 0} /{' '}
        {total ? formatNumber(total, 1) : 0} (
        {!total ? 0 : Math.round((passed / total) * 100)}
        %)
      </Box>
      <BarProgress total={total} value={passed} width="full" />
    </Stack>
  );
};
export const renderPassedFailedChecks = ({ value }) => {
  const passed = value?.passed;
  const total = value?.total_performed;
  return (
    <Stack spacing={1} align="start">
      <Box color="gray.250">
        {passed ? formatNumber(passed, 1) : 0} /{' '}
        {total ? formatNumber(total, 1) : 0} (
        {Math.round((passed / total) * 100)}
        %)
      </Box>
      <BarProgress total={total} value={passed} width="full" />
    </Stack>
  );
};

export const renderExceptionsHeader = renderTableHeaderWithTooltip(
  'Exceptions',
  'Entities with exception for this policy',
  'center',
);

export const renderPassedChecksHeader = renderTableHeaderWithTooltip(
  'Passed policies',
  'Exceptions are treated as Passed Checks',
  'left',
);

export const renderViolations = ({ value }) =>
  renderSeverityCountTags({ value: value?.failed });

export const complianceStatusOptions = [
  { label: 'All status', value: Show.All },
  { label: 'Passed', value: Show.Passed },
  { label: 'Failed', value: Show.Failed },
];

export const complianceExceptionOptions = [
  { label: 'All policies', value: undefined },
  { label: 'With exceptions', value: 'exception' },
];
