import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Tabs } from 'components/Navigation';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { CVEHeader } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHeader';
import { CVETable } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVETable';
import { vulnerabilityCardStyles } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/styles';
import { VulnerabilityTableComponent } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityTableComponent';
import { VulnerabilityTableHeader } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityTableHeader';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

import { actions } from '../../slice';

interface Props {
  showInCard?: boolean;
  isExpanded?: boolean;
  setIsExpanded?(val: boolean);
}

export const VulnerabilityTabs: FC<Props> = props => {
  const { showInCard = true, isExpanded, setIsExpanded } = props;
  const dispatch = useDispatch();
  const { tablesTabIndex, selectedTab } = useSelector(selectVulnerabilities);

  const renderTable = () => <VulnerabilityTableComponent />;

  const tabs = [
    { title: 'Open', key: 'open', component: renderTable() },
    { title: 'Resolved', key: 'resolved', component: renderTable() },
  ];

  const renderTabs = () => (
    <Stack h="full" spacing={3} overflow="scroll" w="full">
      {selectedTab === 'cve' || selectedTab === IconTypes.Packages ? (
        <CVEHeader />
      ) : (
        <VulnerabilityTableHeader
          isExpanded={isExpanded!}
          setIsExpanded={setIsExpanded!}
        />
      )}
      <ActiveResourceProvider>
        {selectedTab === 'cve' ? (
          <CVETable />
        ) : (
          <Tabs
            items={tabs}
            currentIndex={tablesTabIndex}
            onChange={index => dispatch(actions.updateCveTabIndex(index))}
          />
        )}
      </ActiveResourceProvider>
    </Stack>
  );
  return showInCard ? (
    <Card styles={vulnerabilityCardStyles}>{renderTabs()}</Card>
  ) : (
    renderTabs()
  );
};
