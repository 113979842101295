import React, { FC } from 'react';

import { Card } from 'components/DataDisplay';

import { Setup } from './Setup';

export const NoAccountsOnboarded: FC = () => {
  // FIXME: This is a temporary fix to prevent the Setup component from being rendered
  return <></>;

  return (
    <Card styles={{ card: { pl: 24, h: 'full' } }}>
      <Setup showSkip={false} />
    </Card>
  );
};
