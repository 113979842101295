import React, { FC } from 'react';

import { Stack, StackProps } from '@chakra-ui/react';

import {
  AlertsItem,
  ContextsItem,
  GraphMenuItemTypes,
  IGraphMenuItem,
  OverviewItem,
  RelationshipsItem,
} from 'components/Visualization/PixiGraph/components/GraphMenuItem';

interface IGraphMenu {
  styles?: StackProps;
  items: IGraphMenuItem[];
}

const menuItems = [
  [GraphMenuItemTypes.overview, OverviewItem],
  [GraphMenuItemTypes.relationships, RelationshipsItem],
  [GraphMenuItemTypes.alerts, AlertsItem],
  [GraphMenuItemTypes.contexts, ContextsItem],
];

export const GraphMenu: FC<IGraphMenu> = props => {
  const { styles = {}, items } = props;
  const menus = items.map(item => {
    const Comp = menuItems.find(mi => mi[0] === item.type)?.[1];
    if (!Comp) return null;
    // @ts-ignore
    return <Comp {...item} />;
  });

  return (
    <Stack
      pos={'absolute'}
      bg={'#fff'}
      borderRadius={6}
      boxShadow={'0 0 6px #aaa'}
      p={1}
      spacing={1}
      w={'100px'}
      {...styles}
    >
      {menus}
    </Stack>
  );
};
