import React, { FC, useEffect, useState } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';
import { forEach } from 'lodash';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const DHCPMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const [configurations, setConfigurations] = useState<Record<string, any>[]>(
    [],
  );

  useEffect(() => {
    const data: Record<string, any>[] = [];
    forEach(resource?.config?.DhcpOptions?.DhcpConfigurations, o =>
      forEach(o?.Values, v => data.push({ key: o?.Key, value: v?.Value })),
    );
    setConfigurations(data);
  }, [resource]);

  const columns = [
    { header: 'Values', accessor: 'key' },
    { header: 'Description', accessor: 'value', align: 'left' },
  ];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields
          availabilityZone="None"
          vpc="None"
          createdAt="None"
          state="None"
        />
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={configurations} />}
              iconType={IconTypes.Configuration}
              label="Configurations"
              totalCount={configurations?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
