import React, { FC, useEffect } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { StackedHeader } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { ExecuteCommand } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/ExecuteCommand';
import { RemediationAccordion } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationAccordion';
import { TextHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/TextHeader';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';

interface IIndividualAlertRemediation {
  alert: AlertResponse;
  showNote?: boolean;
  showHeader?: boolean;
}

export const AlertRemediation: FC<IIndividualAlertRemediation> = props => {
  const { alert, showNote = true, showHeader = true } = props;

  const { remediationCommands, prepareRemediation } = useSelector(
    selectFindingsOverview,
  );
  const dispatch = useDispatch();
  const remediationDisclosure = useDisclosure();

  useEffect(() => {
    const { resource, entity_uuid, account_id, alert_rule_id } = alert;
    console.assert(resource, 'alert resource is missing');
    console.assert(account_id, 'alert account_id is missing');
    console.assert(alert_rule_id, 'alert alert_rule_id is missing');
    console.assert(entity_uuid, 'alert entity_uuid is missing');

    if (!resource || !account_id || !alert_rule_id || !entity_uuid) {
      console.error('required field is missing');
      return;
    }

    dispatch(
      actions.getRemediationCommands({
        q: {
          resourceType: resource as any,
          resourceUuid: entity_uuid,
          account: account_id,
          checkName: alert_rule_id!,
          policyId: alert?.policy_id,
        },
      }),
    );
  }, [alert, dispatch]);

  const handleExecuteRemediation = (remediation: any) => {
    dispatch(actions.setPrepareRemediation(remediation));
  };

  return (
    <Stack spacing={4} pb={6}>
      <Stack spacing={4}>
        {showNote && (
          <Box bg={'gray.50'} p={4} color={'primary'}>
            <Text>
              NOTE: Ariksa can generate commands based on your choice of
              remediation steps. Ariksa also analyzes dependencies to consider
              as part of your remediation process. However, you are responsible
              for undertaking correct remediation steps for finding based on
              your organization policies and workflows.
            </Text>
          </Box>
        )}
        <Stack>
          {showHeader && (
            <TextHeader>
              Actions which are supported by Ariksa by generating commands
            </TextHeader>
          )}
          <CustomSpinner loading={remediationCommands.isLoading} />
          <RemediationAccordion
            remediations={remediationCommands.data}
            onExecuteRemediation={handleExecuteRemediation}
            remediateDisclosure={remediationDisclosure}
          />
        </Stack>
      </Stack>

      {remediationDisclosure.isOpen && (
        <Drawer
          isOpen={remediationDisclosure.isOpen}
          onClose={remediationDisclosure.onClose}
          header={
            <Box overflow="hidden" textOverflow="ellipsis">
              <StackedHeader
                upper={alert?.aggregate_message}
                type={IconTypes.Remediate}
                lower={
                  (alert?.entity_name || '') +
                  (!!alert?.entity_name
                    ? ` (${alert?.entity_id})`
                    : alert?.entity_id)
                }
                bg={customTheme.colors.green['300']}
                useCustomColor
                //labelStyles={{ w: '90%' }}
              />
            </Box>
          }
          body={
            <ExecuteCommand alert={alert} remediation={prepareRemediation} />
          }
          closeButton
          styles={{
            content: { maxW: '1200px' },
            drawer: {
              portalProps: {
                //containerRef: ref as any,
              },
            },
          }}
        />
      )}
    </Stack>
  );
};
