import React, { FC, useCallback, useEffect, useState } from 'react';

import { RuleReadWithMetadata } from '@ariksa/compliance-policies/api';
import { Box, UseDisclosureProps } from '@chakra-ui/react';
import { xorBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { AddPolicyConfirmationModal } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/AddPolicy/AddPolicyConfirmationModal';

import { selectBlueprints } from '../../../../selectors';
import { actions } from '../../../../slice';

interface Props {
  searchTerm: string;
  alertCategory: string;
  resourceType: string;
  columns: Record<string, any>[];
  policy: RuleReadWithMetadata;
  includePolicy: UseDisclosureProps;
}

export const AddPolicies: FC<Props> = props => {
  const {
    searchTerm,
    alertCategory,
    resourceType,
    columns,
    policy,
    includePolicy,
  } = props;
  const dispatch = useDispatch();
  const { rules, blueprint, allBlueprintPolicies } = useSelector(
    selectBlueprints,
  );
  const [allRules, setAllRules] = useState<RuleReadWithMetadata[]>([]);

  //get policies
  const getAllPolicies = useCallback(() => {
    dispatch(
      actions.getAllRules({
        q: {
          searchTerm,
          alertCategory,
          resourceType,
        },
      }),
    );
  }, [dispatch, alertCategory, resourceType, searchTerm]);

  //get all blueprint policies
  const getAllBlueprintPolicies = useCallback(() => {
    dispatch(
      actions.getAllBlueprintPolicies({
        q: {
          blueprintId: blueprint.data.id,
          alertCategory,
          resourceType,
          searchTerm,
        },
      }),
    );
  }, [dispatch, blueprint.data, alertCategory, resourceType, searchTerm]);

  useDeepCompareEffect(() => {
    getAllPolicies();
    getAllBlueprintPolicies();
  }, [getAllPolicies, getAllBlueprintPolicies]);

  //filter policies
  useEffect(() => {
    let policies: RuleReadWithMetadata[] = [];

    if (rules.isSuccess && allBlueprintPolicies.isSuccess)
      policies = xorBy(rules.data, allBlueprintPolicies.data, 'id');

    setAllRules(policies);
  }, [
    rules.data,
    allBlueprintPolicies.data,
    rules.isSuccess,
    allBlueprintPolicies.isSuccess,
  ]);

  return (
    <Box h="full" flex={1}>
      <Table
        data={allRules}
        columns={columns}
        isLoading={rules.isLoading || allBlueprintPolicies.isLoading}
        cursor="pointer"
        styles={{ header: { zIndex: 800 } }}
      />
      {includePolicy.isOpen && (
        <AddPolicyConfirmationModal
          policy={policy}
          isOpen={includePolicy.isOpen}
          onClose={includePolicy.onClose!}
          getAllPolicies={() => {
            getAllPolicies();
            getAllBlueprintPolicies();
          }}
        />
      )}
    </Box>
  );
};
