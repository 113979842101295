import { ReactNode } from 'react';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const setNameWithLocalTimestamp = (name: ReactNode) => {
  const time = dayjs.utc().tz().format('MM-DD-YYYY hh-mm-ss A');
  return name + '-' + time;
};

export const setFileNameWithLocalTimestamp = (
  prefix: string,
  resourceId: string,
) => {
  return setNameWithLocalTimestamp(prefix + '-' + resourceId + '-');
};
