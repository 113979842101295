import React, { useMemo } from 'react';

import merge from 'lodash/merge';

import { menuStyles } from 'components/Overlay/Menu/styles';

import { BurgerMenuIcon } from '../../Icons';

import { IMenuProps } from './types';

import { Menu } from './index';

interface Props extends IMenuProps {}

export const TableActionsMenu = (props: Props) => {
  const styles = useMemo(() => {
    return merge(props.styles, menuStyles);
  }, [props.styles]);

  return <Menu buttonLabel={<BurgerMenuIcon />} styles={styles} {...props} />;
};
