import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useLocation, useWindowSize } from 'react-use';
import { customTheme } from 'theme';

import { PageContentContextProvider } from 'components/Layout';

export function PageContent(props) {
  const { children } = props;
  const { height, width } = useWindowSize();
  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const { pathname } = useLocation();

  // calculate page content inner height
  // excluding the padding
  useEffect(() => {
    const el: any = ref.current;
    if (el) {
      const headerHeight =
        parseInt(customTheme.sizes.primaryNavBarHeight) +
        parseInt(customTheme.sizes.secondaryNavBarHeight);
      const { paddingTop, paddingBottom } = window.getComputedStyle(el);
      const newHeight =
        height - headerHeight - parseInt(paddingTop) - parseInt(paddingBottom);
      // quick fix by using static height
      setContentHeight(newHeight - 4);
    }
  }, [height]);

  return (
    <PageContentContextProvider
      value={{ contentHeight: contentHeight, contentWidth: width }}
    >
      <Box
        ref={ref}
        className="page-content"
        p={pathname === '/home' ? 0 : customTheme.sizes.bodyPadding}
      >
        {children}
      </Box>
    </PageContentContextProvider>
  );
}
