import React, { useState, FC, useEffect } from 'react';

import {
  Box,
  Stack,
  Button,
  Text,
  Wrap,
  WrapItem,
  Center,
} from '@chakra-ui/react';
import { map, pull, filter, includes } from 'lodash';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';

import { selectAlertWorkflow } from '../../../selectors';
import {
  deleteIconStyles,
  tagStyles,
  valueContainerStyles,
} from '../../../styles';
import { alertTriggers, quantifierOptions } from '../../../utils';
import { useAlertWorkflowWizardContext } from '../AlertWorkflowWizardContext';

interface Props {}

export const AlertTriggers: FC<Props> = props => {
  const { updateFormData } = useAlertWorkflowWizardContext();
  const { formData } = useSelector(selectAlertWorkflow);
  const { trigger, trigger_quantifier } = formData;
  const [selectedTriggers, setSelectedTriggers] = useState<
    Record<string, any>[]
  >([]);
  const [quantifier, setQuantifier] = useState<Record<string, any>>({});

  useEffect(() => {
    setSelectedTriggers(filter(alertTriggers, t => includes(trigger, t.value)));
  }, [trigger]);

  useEffect(() => {
    setQuantifier(
      quantifierOptions.find(o => o.value === trigger_quantifier) || {},
    );
  }, [trigger_quantifier]);

  return (
    <Stack>
      <Wrap align="center">
        <WrapItem>
          <Tag label="WHEN" styles={tagStyles} />
        </WrapItem>
        <WrapItem>
          <Text> an alert related event is detected and satisfies</Text>
        </WrapItem>
        <WrapItem>
          <Box w={24}>
            <Select
              options={quantifierOptions}
              value={quantifier}
              onChange={selected =>
                updateFormData('trigger_quantifier', selected)
              }
            />
          </Box>
        </WrapItem>
        <WrapItem>
          <Text>of the following conditions</Text>
        </WrapItem>
      </Wrap>

      <Stack>
        <Box mb={2}>
          <Select
            options={alertTriggers}
            value={selectedTriggers}
            isMulti
            showTotalSelected={selectedTriggers.length > 0}
            placeholder="Choose alert trigger..."
            onChange={selected => updateFormData('trigger', selected)}
          />
        </Box>
        {map(selectedTriggers, each => (
          <Stack {...valueContainerStyles} key={each?.value + '-alert-trigger'}>
            <Center>{each?.label}</Center>
            <Center>
              <Button
                {...deleteIconStyles}
                onClick={() =>
                  updateFormData('trigger', pull(selectedTriggers, each))
                }
              >
                <TrashIcon />
              </Button>
            </Center>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
