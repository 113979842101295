import {
  PermissionsApiEvaluateRiskAnalysisRequest,
  RiskAnalysisResponse,
} from '@ariksa/compliance-policies/dist/api';

import { ComplianceService } from 'api/services';
import { errorToast } from 'components/Toast';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useCompliancePermissionsService() {
  const riskAnalysis = useAriksaQuery<
    PermissionsApiEvaluateRiskAnalysisRequest,
    RiskAnalysisResponse
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Permissions.evaluateRiskAnalysis(req).then(r => r.data),
    config: {
      onError: error =>
        errorToast({
          title: 'Failed to get risk analysis!',
          description: error?.response?.data?.toString(),
        }),
    },
  });

  return { riskAnalysis };
}
