import { customTheme } from 'theme';

import { commonStyles } from '../styles';
import { customizedLegend } from '../utils';

export const defaultStyles = {
  lineColors: customTheme.colors,
  areaChart: {
    margin: {
      top: 30,
      right: 30,
    },
  },
  legend: {
    iconType: 'plainline',
    wrapperStyle: { top: 0, right: 10 },
    align: 'right',
    iconSize: 8,
    formatter: customizedLegend,
  },
  area: {
    type: 'monotone',
    dot: false,
  },
  tooltip: {
    contentStyle: {
      fontSize: '12px',
    },
  },
  ...commonStyles,
};
