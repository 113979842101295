import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DataCloudIcon = props => (
  <Icon viewBox="0 0 1764 1765" color="inherit" h="full" w="full" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="2218" y="435" width="1764" height="1765" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="2770" y="1137" width="577" height="574" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="2770" y="1137" width="577" height="574" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="2770" y="1137" width="577" height="574" />
      </clipPath>
      <clipPath id="clip4">
        <rect x="2220" y="437" width="1761" height="1761" />
      </clipPath>
      <clipPath id="clip5">
        <rect x="2220" y="437" width="1761" height="1761" />
      </clipPath>
      <clipPath id="clip6">
        <rect x="2220" y="437" width="1761" height="1761" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-2218 -435)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M157.85 444.85C149.89 444.85 143.5 451.24 143.5 459.2 143.5 467.16 149.89 473.55 157.85 473.55 165.81 473.55 172.2 467.16 172.2 459.2 172.2 451.24 165.81 444.85 157.85 444.85ZM157.85 272.65C149.89 272.65 143.5 279.04 143.5 287 143.5 294.96 149.89 301.35 157.85 301.35 165.81 301.35 172.2 294.96 172.2 287 172.2 279.04 165.81 272.65 157.85 272.65ZM515.479 103.028C504.043 44.3953 407.854 0 287 0 166.146 0 69.9562 44.3953 58.5211 103.028 57.7363 104.71 57.4 106.616 57.4 108.634L57.4 280.834 57.4 294.511 57.4 466.711C57.4 526.914 158.186 574 287 574 415.813 574 516.6 526.914 516.6 466.711L516.6 294.511 516.6 280.834 516.6 108.634C516.6 106.616 516.151 104.822 515.479 103.028ZM487.9 466.711C487.9 503.931 405.387 545.3 287 545.3 168.612 545.3 86.1 503.931 86.1 466.711L86.1 347.09C124.89 379.938 199.442 401.8 287 401.8 374.557 401.8 449.11 379.938 487.9 347.09L487.9 466.711ZM487.9 280.834 487.9 294.511C487.9 331.619 405.387 373.1 287 373.1 168.612 373.1 86.1 331.731 86.1 294.511L86.1 280.834 86.1 171.079C124.89 206.281 199.442 229.6 287 229.6 374.557 229.6 449.11 206.281 487.9 171.079L487.9 280.834ZM287 200.9C168.612 200.9 86.1 155.496 86.1 114.8 86.1 74.1043 168.612 28.7 287 28.7 405.387 28.7 487.9 74.1043 487.9 114.8 487.9 155.496 405.387 200.9 287 200.9Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
              transform="matrix(1.00174 0 0 1 2771 1137)"
            />
          </g>
        </g>
      </g>
      <g clipPath="url(#clip4)">
        <g clipPath="url(#clip5)">
          <g clipPath="url(#clip6)">
            <path
              d="M3791.94 1301.5C3789.53 1135.81 3654.09 1001.75 3487.72 1001.75 3450.25 1001.75 3413.12 1008.62 3378.06 1022.03 3351.25 940.906 3301.06 868.031 3234.03 813.719 3154.62 749.781 3054.94 714.375 2952.84 714.375 2712.56 714.375 2515.94 904.812 2505.62 1142.69 2429.31 1165.38 2360.22 1211.09 2309.69 1273.66 2252.62 1343.78 2221 1432.81 2221 1523.91 2221 1743.22 2399.41 1921.62 2618.72 1921.62L3656.16 1921.62C3835.25 1921.62 3981 1775.88 3981 1596.78 3981 1469.94 3906.06 1354.09 3791.94 1301.5ZM3656.16 1853.56 2618.72 1853.56C2437.22 1853.56 2289.41 1705.75 2289.41 1524.25 2289.41 1448.97 2315.53 1375.06 2362.97 1316.62 2409.72 1258.88 2475.38 1218.66 2547.22 1202.5 2563.03 1199.06 2574.38 1184.97 2574.03 1168.47L2574.03 1167.44C2574.03 1165.72 2574.03 1163.66 2574.03 1161.94 2574.03 952.937 2744.19 782.781 2953.19 782.781 3040.84 782.781 3123.34 812 3191.41 867 3257.75 920.625 3304.5 995.219 3323.06 1077.72 3325.47 1088.37 3332.69 1096.97 3342.31 1101.44 3352.28 1105.91 3363.62 1105.22 3372.91 1100.06 3407.97 1080.47 3447.5 1070.16 3487.72 1070.16 3617.66 1070.16 3723.53 1176.03 3723.53 1305.97 3723.53 1310.78 3723.19 1315.59 3722.84 1321.78 3721.81 1336.91 3730.75 1350.66 3745.19 1356.16 3845.22 1393.28 3912.59 1490.22 3912.59 1596.78 3912.59 1738.41 3797.44 1853.56 3656.16 1853.56Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
