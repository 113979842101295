import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StackedHeader } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { CVEDetails } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/CVEDetails';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';
import { toTitleCase } from 'utils/string';

interface Props {
  isOpen: boolean;
  onClose();
  currentRecord?: Record<string, any>;
  cveId?: string;
}

export const CVEDetailsDrawer: FC<Props> = props => {
  const { isOpen, onClose, currentRecord, cveId } = props;
  const dispatch = useDispatch();
  const { cveDetails } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<Record<string, any>>({});

  useEffect(() => {
    //!!currentRecord && setData(currentRecord);
  }, [currentRecord]);

  useEffect(() => {
    if (!!cveId)
      dispatch(
        actions.getCVEDetails({
          q: {
            cVEByIDRequest: { vulnerability_ids: [cveId] },
            page: 1,
            pageSize: 1,
          },
          onSuccess: res => {
            setData(res?.items?.[0] ?? {});
          },
        }),
      );
    else setData(currentRecord ?? {});
  }, [cveId, dispatch, currentRecord]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={data?.vulnerability_id ?? data?.cve_id}
          lower={
            (data?.cvss_score || data?.cvss_v3?.score
              ? 'CVSS 3.0 SCORE: ' +
                Number(data?.cvss_score || data?.cvss_v3?.score).toFixed(1) +
                ' '
              : '') +
            '(Severity: ' +
            toTitleCase(data?.severity) +
            ')'
          }
          type={IconTypes.ExclamationTriangle}
          bg={data?.severity}
          isLoading={cveDetails.isLoading}
        />
      }
      body={
        cveDetails.isLoading ? (
          <CustomSpinner size="lg" />
        ) : (
          <CVEDetails currentTechInstance={data} />
        )
      }
      styles={{ content: { maxW: '1200px' } }}
      closeButton
    />
  );
};
