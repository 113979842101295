import React, { FC, useCallback, useReducer } from 'react';

import {
  Box,
  Center,
  useDisclosure,
  Stack,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { ActionButton } from 'components/DataEntry';
import {
  ComplianceCheckPassedIcon,
  PolicyExceptionIcon,
  PolicyIcon,
  RemediateIcon,
  TagIcon,
} from 'components/Icons';
import { AddPolicies } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/AddPolicy/AddPolicies';
import { ComplianceBlueprintControls } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/ComplianceBlueprint/ComplianceBlueprintControls';
import { PoliciesTable } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/Policies/PoliciesTable';
import { RemediationModal } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/Policies/Remediation/RemediationModal';
import { useBlueprintPolicyColumns } from 'containers/PolicyHub/Blueprints/hooks/useBlueprintPolicyColumns';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { EditPolicyContext } from 'containers/PolicyHub/Components/EditPolicyContext';
import { PoliciesTableHeader } from 'containers/PolicyHub/Components/PoliciesTableHeader/PoliciesTableHeader';
import { policyTypeOptions } from 'containers/PolicyHub/Components/PoliciesTableHeader/utils';

import { selectBlueprints } from '../../../selectors';

interface Props {
  addPolicy?: UseDisclosureProps;
  action?: 'Create' | 'Show' | 'Add';
}

export const BlueprintPolicies: FC<Props> = props => {
  const { addPolicy } = props;
  const { blueprint, blueprintPolicies } = useSelector(selectBlueprints);
  const excludePolicy = useDisclosure();
  const remediation = useDisclosure();
  const editContext = useDisclosure();
  const resourceExceptions = useDisclosure();
  const { policiesTableColumns } = useBlueprintPolicyColumns();
  const dispatch = useDispatch();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: {},
      alertCategory: {},
      currentRecord: {},
      searchTerm: '',
      severity: { label: 'All Severities', value: undefined },
      policyType: policyTypeOptions[0],
    },
  );

  //get policies
  const getBlueprintPolicies = useCallback(() => {
    dispatch(
      actions.getBlueprintPolicies({
        q: {
          blueprintId: blueprint.data.id,
          alertCategory: state.alertCategory?.value,
          resourceType: state.resourceType?.value,
          searchTerm: state.searchTerm,
          severity: state.severity?.value,
          page: blueprintPolicies.page.info.page_number,
          size: blueprintPolicies.page.info.page_size,
          isCustom: !!state.policyType?.value
            ? state.policyType?.value === 'custom'
            : undefined,
        },
      }),
    );
  }, [
    dispatch,
    blueprint.data,
    state.alertCategory,
    state.resourceType,
    state.searchTerm,
    state.severity,
    state.policyType,
    blueprintPolicies.page.info,
  ]);

  const renderActions = ({ row }) => {
    return (
      <Center>
        {addPolicy?.isOpen && (
          <ActionButton
            label="Include policy"
            icon={<ComplianceCheckPassedIcon />}
            onClick={e => {
              e.stopPropagation();
              excludePolicy.onOpen();
              updateState({ currentRecord: row });
            }}
          />
        )}
        {!addPolicy?.isOpen && (
          <ActionButton
            label="Edit context"
            icon={<TagIcon w={5} h={5} />}
            //isDisabled={row.created_by === 'system'}
            mr={1}
            onClick={e => {
              e.stopPropagation();
              editContext.onOpen();
              updateState({ currentRecord: row });
            }}
          />
        )}
        {!addPolicy?.isOpen && (
          <ActionButton
            label="Exclude policy"
            icon={<PolicyExceptionIcon />}
            isDisabled={row.created_by === 'system'}
            onClick={e => {
              e.stopPropagation();
              excludePolicy.onOpen();
              updateState({ currentRecord: row });
            }}
          />
        )}
        {!addPolicy?.isOpen && (
          <ActionButton
            label="Remediation"
            icon={<RemediateIcon />}
            isDisabled={row.created_by === 'system'}
            onClick={e => {
              e.stopPropagation();
              remediation.onOpen();
              updateState({ currentRecord: row });
            }}
            tooltipWidth={32}
            p={0.5}
          />
        )}
        {/* {!addPolicy?.isOpen && (
          <ActionButton
            label="Show resource exceptions"
            icon={<ExclamationTriangleIcon />}
            onClick={e => {
              //e.stopPropagation();
              if (
                resourceExceptions.isOpen &&
                row.id === state.currentRecord?.id
              )
                resourceExceptions.onClose();
              else resourceExceptions.onOpen();
              updateState({ currentRecord: row });
            }}
          />
        )}*/}
      </Center>
    );
  };

  const columns = [
    ...policiesTableColumns,
    {
      header: 'Actions',
      render: renderActions,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  return (
    <Stack h="full" spacing={4}>
      <Box>
        <PageHeaderWithIcon
          label={(addPolicy?.isOpen ? 'Add ' : '') + 'Policies'}
          reversed
          icon={
            addPolicy?.isOpen ? <ComplianceCheckPassedIcon /> : <PolicyIcon />
          }
        />
      </Box>
      <PoliciesTableHeader
        resourceType={state.resourceType}
        alertCategory={state.alertCategory}
        severity={state.severity}
        policyType={state.policyType}
        updateValue={updateValue}
        addPolicy={addPolicy}
      />
      {!blueprint.data?.compliance && !addPolicy?.isOpen && (
        <Box h="full" flex={1}>
          <PoliciesTable
            columns={columns}
            currentRecord={state.currentRecord}
            excludePolicy={excludePolicy}
            resourceExceptions={resourceExceptions}
            getBlueprintPolicies={getBlueprintPolicies}
          />
        </Box>
      )}
      {!!blueprint.data.compliance && !addPolicy?.isOpen && (
        <Box h="full" flex={1}>
          <ComplianceBlueprintControls
            searchTerm={state.searchTerm}
            addPolicy={addPolicy!}
          />
        </Box>
      )}
      {addPolicy?.isOpen && (
        <AddPolicies
          searchTerm={state.searchTerm}
          alertCategory={state.alertCategory?.value}
          resourceType={state.resourceType?.value}
          columns={columns}
          policy={state.currentRecord}
          includePolicy={excludePolicy}
        />
      )}
      {remediation.isOpen && (
        <RemediationModal state={remediation} policy={state.currentRecord} />
      )}
      {editContext.isOpen && (
        <EditPolicyContext
          isOpen={editContext.isOpen}
          onClose={editContext.onClose}
          row={state.currentRecord}
          blueprintId={blueprint.data.id}
          onSuccess={getBlueprintPolicies}
        />
      )}
    </Stack>
  );
};
