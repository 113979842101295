import React, { FC } from 'react';

import { Circle } from '@chakra-ui/react';

interface IHelpCount {
  count?: number;
}

export const HelpCount: FC<IHelpCount> = props => {
  return (
    <Circle border={'1px solid'} borderColor={'primary'} size={6}>
      {props.count ?? 0}
    </Circle>
  );
};
