import React from 'react';

import { CloudProvider, OSTechEnum } from '@ariksa/inventory-core/dist/api';
import { map } from 'lodash';

import { formatNumberWithComma, getIcon } from 'components/DataDisplay';

export const getVulnerabilityCountInfo = vulnerabilities => {
  if (!!vulnerabilities?.critical)
    return formatNumberWithComma(vulnerabilities.critical) + ' Critical';
  if (!!vulnerabilities?.high)
    return formatNumberWithComma(vulnerabilities.high) + ' High';
  if (!!vulnerabilities?.medium)
    return formatNumberWithComma(vulnerabilities.medium) + ' Medium';
  if (!!vulnerabilities?.low)
    return formatNumberWithComma(vulnerabilities.low) + ' Low';
  return '0 Vulnerabilities';
};

export const osAndTechnologyOptions = [
  { label: 'All OS and technologies', value: undefined },
  ...map(OSTechEnum, o => ({ label: o, value: o, icon: getIcon(o) })),
];

export const technologyCloudOptions = [
  { label: 'All clouds', value: undefined },
  ...map(CloudProvider, o => ({
    label: o === CloudProvider.GoogleWorkspace ? 'Google Workspace' : o,
    value: o,
    icon: getIcon(o),
  })),
];
