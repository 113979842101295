import {
  AuditTrailServiceApiAuditTrailServiceAccountStatusRequest,
  V1AccountStatusResponse,
} from '@ariksa/audittrail/api';
import { Summary } from '@ariksa/compliance-policies';
import {
  BlueprintApiGetBlueprintsRequest,
  ComplianceApiGetSummaryRequest,
  PageBluePrintsRead,
} from '@ariksa/compliance-policies/api';
import {
  BlueprintApiGetAttachedBlueprintSummaryRequest,
  BlueprintAttachedSummary,
} from '@ariksa/compliance-policies/dist/api';
import {
  DataFootprintAnalyzed,
  ResourcesApiGetResourcesCategoriesCountRequest,
} from '@ariksa/data-scanning/api';
import {
  AppSchemasInventoryResourceCountDeploymentSummary,
  CountSummary,
  ResourceApiGetDeploymentSummaryRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetTicketHistoryRequest,
  AlertLogsApiGetTicketSummaryRequest,
  AlertWorkflowResponse,
  TicketSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDashboardResourcesRequest,
  ChartsApiGetGovernanceScoresRequest,
  ChartsApiGetTotalAlertsRequest,
  InventoryApiGetResourceCountRequest,
  OrgGovernanceReport,
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
  VulnerabilitiesApiGetVulnerabilityCountRequest,
  VulnerabilityCountResponse,
} from '@ariksa/reporting/api';
import { map } from 'lodash';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';
import { formatDate } from 'utils/date';

import { CloudComplianceSummary, ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //state of your cloud
  stateOfYourCloud: {
    cloudSummary: QueryState.init({} as CountSummary),
    resourcesCount: QueryState.init({} as ResourceCount),
    policyManagement: QueryState.init({} as BlueprintAttachedSummary),

    dataCount: QueryState.init({} as ResourceCountResponse),
    infrastructureCount: QueryState.init({} as ResourceCountResponse),

    entitiesHistory: QueryState.init({}),
    alertsHistory: QueryState.init({} as TotalAlertsResponse),
    analyzedDataSourcesCount: QueryState.init({}),
    blueprints: QueryState.init(0),
    workflows: QueryState.init(0),
    awsCis1_5: QueryState.init({} as CloudComplianceSummary),
    cisV8: QueryState.init({} as CloudComplianceSummary),
    soc2: QueryState.init({} as CloudComplianceSummary),
    ariksaBestPractices: QueryState.init({} as CloudComplianceSummary),

    governanceScore: QueryState.init({} as OrgGovernanceReport),
  },
  ticketSummary: QueryState.init({} as TicketSummaryResponse),
  ticketHistory: QueryState.init([]),
  accountStatus: QueryState.init({}),
  vpcStatus: QueryState.init({} as any),
  deploymentSummary: QueryState.init([]),
};

const dashboardOverviewSlice = createSlice({
  name: 'dashboardOverview',
  initialState,
  reducers: {
    getCloudSummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.stateOfYourCloud.cloudSummary = QueryState.next(
        state.stateOfYourCloud.cloudSummary,
        action,
      );
    },

    getResourcesCount(
      state,
      action: QueryAction<ResourceCount, ChartsApiGetDashboardResourcesRequest>,
    ) {
      state.stateOfYourCloud.resourcesCount = QueryState.next(
        state.stateOfYourCloud.resourcesCount,
        action,
      );
    },

    getPolicyManagementSummary(
      state,
      action: QueryAction<
        BlueprintAttachedSummary,
        BlueprintApiGetAttachedBlueprintSummaryRequest
      >,
    ) {
      state.stateOfYourCloud.policyManagement = QueryState.next(
        state.stateOfYourCloud.policyManagement,
        action,
      );
    },

    /*--------------------------State of your cloud------------------------*/
    getEntitiesHistory(
      state,
      action: QueryAction<
        Record<string, number>,
        ChartsApiGetDashboardResourcesHistoryRequest
      >,
    ) {
      state.stateOfYourCloud.entitiesHistory = QueryState.next(
        state.stateOfYourCloud.entitiesHistory,
        action,
      );
    },

    getDataCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.stateOfYourCloud.dataCount = QueryState.next(
        state.stateOfYourCloud.dataCount,
        action,
      );
    },
    getInfrastructureCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.stateOfYourCloud.infrastructureCount = QueryState.next(
        state.stateOfYourCloud.infrastructureCount,
        action,
      );
    },

    getAlertsHistory(
      state,
      action: QueryAction<TotalAlertsResponse, ChartsApiGetTotalAlertsRequest>,
    ) {
      state.stateOfYourCloud.alertsHistory = QueryState.next(
        state.stateOfYourCloud.alertsHistory,
        action,
      );
    },

    getBlueprints(
      state,
      action: QueryAction<PageBluePrintsRead, BlueprintApiGetBlueprintsRequest>,
    ) {
      state.stateOfYourCloud.blueprints = QueryState.next(
        state.stateOfYourCloud.blueprints,
        action,
        { mapData: r => r?.total ?? 0 },
      );
    },
    getAwsCis1_5Summary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.stateOfYourCloud.awsCis1_5 = QueryState.next(
        state.stateOfYourCloud.awsCis1_5,
        action,
        { mapData: r => ({ ...r, id: action.payload.q.blueprintId }) },
      );
    },
    getCisV8Summary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.stateOfYourCloud.cisV8 = QueryState.next(
        state.stateOfYourCloud.cisV8,
        action,
        { mapData: r => ({ ...r, id: action.payload.q.blueprintId }) },
      );
    },
    getSoc2Summary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.stateOfYourCloud.soc2 = QueryState.next(
        state.stateOfYourCloud.soc2,
        action,
        { mapData: r => ({ ...r, id: action.payload.q.blueprintId }) },
      );
    },
    getAriksaBestPracticesSummary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.stateOfYourCloud.ariksaBestPractices = QueryState.next(
        state.stateOfYourCloud.ariksaBestPractices,
        action,
        { mapData: r => ({ ...r, id: action.payload.q.blueprintId }) },
      );
    },

    getWorkflows(state, action: QueryAction<AlertWorkflowResponse[]>) {
      state.stateOfYourCloud.workflows = QueryState.next(
        state.stateOfYourCloud.workflows,
        action,
        { mapData: r => r?.length ?? 0 },
      );
    },

    getAnalyzedDataSourcesCount(
      state,
      action: QueryAction<
        DataFootprintAnalyzed,
        ResourcesApiGetResourcesCategoriesCountRequest
      >,
    ) {
      state.stateOfYourCloud.analyzedDataSourcesCount = QueryState.next(
        state.stateOfYourCloud.analyzedDataSourcesCount,
        action,
      );
    },

    getTicketSummary(
      state,
      action: QueryAction<
        TicketSummaryResponse,
        AlertLogsApiGetTicketSummaryRequest
      >,
    ) {
      state.ticketSummary = QueryState.next(state.ticketSummary, action);
    },

    getTicketHistory(
      state,
      action: QueryAction<
        Record<string, number>,
        AlertLogsApiGetTicketHistoryRequest
      >,
    ) {
      state.ticketHistory = QueryState.next(state.ticketHistory, action, {
        mapData: r => {
          return map(r, (count, date) => ({
            Date: formatDate(date, 'D-MMM'),
            Tickets: count,
          }));
        },
      });
    },

    getGovernanceScore(
      state,
      action: QueryAction<
        OrgGovernanceReport,
        ChartsApiGetGovernanceScoresRequest
      >,
    ) {
      state.stateOfYourCloud.governanceScore = QueryState.next(
        state.stateOfYourCloud.governanceScore,
        action,
      );
    },

    getAccountStatus(
      state,
      action: QueryAction<
        V1AccountStatusResponse,
        AuditTrailServiceApiAuditTrailServiceAccountStatusRequest
      >,
    ) {
      state.accountStatus = QueryState.next(state.accountStatus, action);
    },

    getVpcStatus(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.vpcStatus = QueryState.next(state.vpcStatus, action);
    },
    getDeploymentSummary(
      state,
      action: QueryAction<
        AppSchemasInventoryResourceCountDeploymentSummary[],
        ResourceApiGetDeploymentSummaryRequest
      >,
    ) {
      state.deploymentSummary = QueryState.next(
        state.deploymentSummary,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardOverviewSlice;
