import React, { useEffect, FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithBackButton } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CriticalDataIcon, SearchIcon } from 'components/Icons';
import { SideNav } from 'components/Navigation';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  currentRecord: Record<string, any>;
  setShowDataTypeSources?(val: boolean);
}

export const DataTypeSearchResult: FC<Props> = props => {
  const { currentRecord, setShowDataTypeSources } = props;
  const { searchTerm } = useSelector(selectSharedState);
  const { filesByDataType } = useSelector(selectDataDashboard);
  const { environmentId, accountId } = useAccessBoundary();
  const { dataTypeSearchResultColumns, fileColumns } = useDataTableColumns(
    MetadataTabs.Data,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.getFilesByDataType({
        q: {
          dataType: currentRecord?.sensitive_data_name,
          environmentId,
          accountIds: !!accountId ? [accountId] : [],
          dataSearch: searchTerm,
        },
      }),
    );
  }, [dispatch, environmentId, accountId, currentRecord, searchTerm]);

  return (
    <SideNav
      showContentHeader={false}
      onChange={() => setShowDataTypeSources?.(false)}
      menuItems={[
        {
          title: currentRecord?.sensitive_data_name,
          key: 'key',
          icon: (
            <Box color="primary">
              <CriticalDataIcon />
            </Box>
          ),
          component: (
            <Stack w={'full'} h={'full'}>
              <Box>
                <PageHeaderWithBackButton
                  label="Search Results"
                  onClickBack={() =>
                    dispatch(sharedStateActions.updateSearchTerm(''))
                  }
                  fontSize="sm"
                  reversed
                  icon={<SearchIcon />}
                  iconBgColor="sensitiveDataBg"
                  useCustomColor
                />
              </Box>
              <Box flex={1} pt={3}>
                <Table
                  data={filesByDataType.data}
                  columns={dataTypeSearchResultColumns}
                  isLoading={filesByDataType.isLoading}
                  cursor="pointer"
                  styles={{ header: { position: 'relative', zIndex: 800 } }}
                  subComponent={({ row, expanded }) =>
                    expanded && (
                      <SubComponentContainer>
                        <Table data={row.files} columns={fileColumns} />
                      </SubComponentContainer>
                    )
                  }
                  pagination={{
                    totalCount: filesByDataType?.page.totalCount,
                    pageInfo: filesByDataType.page.info,
                    onChange: pageInfo => {
                      dispatch(actions.updateFilesByDataTypePageInfo(pageInfo));
                    },
                  }}
                />
              </Box>
            </Stack>
          ),
        },
      ]}
    />
  );
};
