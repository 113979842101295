/**
 *
 * Asynchronously loads the component for Assessment
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Inventory = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Inventory,
);
