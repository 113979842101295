import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  SubnetIDs,
  SecurityGroupsList,
  Endpoints,
  Version,
  ClusterInstanceType,
  DedicatedMaster,
  MasterInstanceType,
  WarmStorageEnabled,
  WarmInstanceType,
  WarmNodesInCluster,
  ColdStorage,
  AttachedVolumeType,
  AttachedVolumeSize,
  VolumeIOPS,
  Policy,
  EncryptionAtRest,
  EncryptionKeyID,
  LogsOptions,
  CloudWatchLogARN,
  UpdateAvailable,
  UpdateStatus,
  EnforceHTTPSOnEndpoint,
  TLSPolicyOnEndpoint,
  CustomEndpoint,
  CustomEndpointCertificateARN,
  SAMLEntityID,
  SAMLSubjectKey,
} from '../../../Components/MetadataField/MetaGridFields';

export interface Props {}

export const ElasticSearchDomain: FC<Props> = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {SubnetIDs(resource)}
      {SecurityGroupsList(resource)}
      {Endpoints(resource)}
      {Version(resource)}
      {ClusterInstanceType(resource)}
      {DedicatedMaster(resource)}
      {MasterInstanceType(resource)}
      {WarmStorageEnabled(resource)}
      {WarmInstanceType({})}
      {WarmNodesInCluster({})}
      {ColdStorage(resource)}
      {AttachedVolumeType(resource)}
      {AttachedVolumeSize(resource)}
      {VolumeIOPS(resource)}
      {Policy(resource)}
      {EncryptionAtRest(resource)}
      {EncryptionKeyID(resource)}
      {LogsOptions(resource)}
      {CloudWatchLogARN(resource)}
      {UpdateAvailable(resource)}
      {UpdateStatus(resource)}
      {EnforceHTTPSOnEndpoint(resource)}
      {TLSPolicyOnEndpoint(resource)}
      {CustomEndpoint(resource)}
      {CustomEndpointCertificateARN(resource)}
      {SAMLEntityID(resource)}
      {SAMLSubjectKey(resource)}
    </Grid>
  );
};
