/**
 *
 * PageHeaderWithBackButton
 *
 */

import React, { FC } from 'react';

import { HStack } from '@chakra-ui/react';

import {
  PageHeaderWithIcon,
  PageHeaderWithIconProps,
} from 'components/DataDisplay';
import { BackIconButton } from 'components/DataEntry';

interface Props extends PageHeaderWithIconProps {
  onClickBack();
}

export const PageHeaderWithBackButton: FC<Props> = props => {
  const { onClickBack, ...rest } = props;

  return (
    <HStack h="full" align="center" spacing={8}>
      <BackIconButton onClick={onClickBack}></BackIconButton>
      <PageHeaderWithIcon {...rest} />
    </HStack>
  );
};
