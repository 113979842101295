import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { JitAccounts } from 'app/containers/Setup/Jit/Components/JitAccounts';
import { FilterRouter } from 'components/FilterView';
import { JitAddAccountModal } from 'containers/Setup/Jit/Components/JitAddAccountModal';
import { jitSaga } from 'containers/Setup/Jit/saga';
import { reducer, sliceKey } from 'containers/Setup/Jit/slice';
import { useInjector } from 'utils/inject';

export const Jit = () => {
  useInjector(sliceKey, reducer, jitSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/setup/jit" />}>
        <Route index element={<JitAccounts />} />
      </Route>
      <Route element={<FilterRouter path="/setup/jit/add" />}>
        <Route path="add" element={<JitAddAccountModal />} />
      </Route>
    </Routes>
  );
};
