import { useCallback } from 'react';

import {
  InsightV2ApiInsightV2Request,
  NativeResources,
} from '@ariksa/inventory-core/api';
import { SortOrder, Status } from '@ariksa/notification';
import { Severity } from '@ariksa/notification/api';
import dayjs from 'dayjs';
import { forEach, isEmpty, map, split, toUpper } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';

import { InventoryService } from 'api/services';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { FindingsIssuesSearchParamProps } from 'containers/Findings/Alerts/types';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useSearchParams } from 'hooks/useSearchParams';

export const useAlerts = () => {
  const dispatch = useDispatch();
  const {
    aggregatedFindings,
    filters,
    alerts,
    startDate,
    sortByField,
  } = useSelector(selectAlerts);
  const { withAccessBoundary, accountId, environmentId } = useAccessBoundary();
  const params = useSearchParams<FindingsIssuesSearchParamProps>();

  const getDetails = useCallback(
    results => {
      const data: Record<string, any[]> = {};
      forEach(results, o =>
        !!data[o?.resource!]
          ? data[o?.resource!].push(o)
          : (data[o.resource!] = [o]),
      );

      //get resource details
      forEach(data, (items, key) => {
        const insightPayload: InsightV2ApiInsightV2Request = {
          insightV2Request: {
            resource_type: key as NativeResources,
            resource_uuids: map(items, o => o?.entity_uuid!),
            account_id: map(items, o => o?.account_id),
          },
        };
        dispatch(
          actions.getEntityDetails({
            q: insightPayload,
            status: QueryStatus.pending,
          }),
        );
        InventoryService.InsightV2.insightV2(insightPayload).then(res => {
          dispatch(
            actions.getEntityDetails({
              q: insightPayload,
              status: QueryStatus.fulfilled,
              data: res.data?.items,
            }),
          );
        });
      });

      //get risk context
      if (params?.alertStatus !== 'Resolved')
        dispatch(
          sharedStateActions.getRiskContext({
            q: {
              riskContextRequest: {
                uuids: map(results, o => o.entity_uuid!),
              },
            },
          }),
        );
    },
    [dispatch, params.alertStatus],
  );

  const getAggregatedFindings = useCallback(() => {
    const date = !!startDate.value
      ? {
          startDate: dayjs()
            .subtract(startDate.value, 'days')
            .utc()
            .format('YYYY-MM-DD'),
          endDate: dayjs().utc().format('YYYY-MM-DD'),
        }
      : {};
    dispatch(
      actions.getAggregatedFindings({
        q: withAccessBoundary({
          search: !!filters.searchString ? filters.searchString : undefined,
          pageNumber: aggregatedFindings.page.info.page_number,
          pageSize: aggregatedFindings.page.info.page_size,
          alertCategories: params?.alertCategory
            ? split(params?.alertCategory, ',')
            : undefined,
          resourceTypes: params?.resourceType
            ? split(params?.resourceType, ',')
            : undefined,
          severities: params?.severity
            ? (split(params?.severity, ',') as Severity[])
            : undefined,
          sortField: sortByField.sortField,
          sortOrder: toUpper(sortByField.sortOrder) as SortOrder,
          ...date,
        }),
        onSuccess: res => {
          !isEmpty(res?.aggregated_alerts) &&
            getDetails(res?.aggregated_alerts);
        },
      }),
    );
  }, [
    dispatch,
    withAccessBoundary,
    aggregatedFindings.page.info,
    filters.searchString,
    startDate.value,
    getDetails,
    sortByField,
    params?.alertCategory,
    params?.severity,
    params?.resourceType,
  ]);

  const getAlerts = useCallback(() => {
    if (!!accountId || !!environmentId) {
      let date = !!startDate.value
        ? {
            startDate: dayjs()
              .subtract(startDate.value, 'days')
              .utc()
              .format('YYYY-MM-DD'),
            endDate: dayjs().utc().format('YYYY-MM-DD'),
          }
        : {};
      if (!!filters.startTime) {
        date = {
          startDate: dayjs(filters.startTime).utc().format('YYYY-MM-DD'),
          endDate: dayjs(filters.endTime).utc().format('YYYY-MM-DD'),
        };
      }
      const resolved =
        params?.alertStatus === 'Resolved'
          ? { status: Status.Resolved }
          : params?.alertStatus === 'Exception'
          ? { status: Status.Resolved, resolvedBy: 'exception' }
          : {};
      const snoozed = params?.alertStatus === 'Snoozed' ? { snooze: true } : {};
      const entityUuid = !!params.entity_uuid
        ? { entityUuid: params.entity_uuid }
        : {};
      const search = !!filters.searchString
        ? { searchString: filters.searchString }
        : {};
      const blueprint = !!params.blueprintId
        ? { blueprintId: [params.blueprintId] }
        : {};

      dispatch(
        actions.getAlerts({
          q: {
            accountId,
            environmentId,
            ...search,
            ...resolved,
            ...snoozed,
            alertCategories: params?.alertCategory
              ? split(params?.alertCategory, ',')
              : undefined,
            resourceTypes: params?.resourceType
              ? split(params?.resourceType, ',')
              : undefined,
            severities: params?.severity
              ? (split(params?.severity, ',') as Severity[])
              : undefined,
            ...entityUuid,
            ...date,
            region: params?.region,
            ...blueprint,
            pageNumber: alerts.page.info.page_number,
            pageSize: alerts.page.info.page_size,
            sortField: sortByField.sortField,
            sortOrder: sortByField.sortOrder,
          },
          onSuccess: res => {
            getDetails(res?.results);
            //get alert workflow status(for slack, jira etc.)
            /*dispatch(
              sharedStateActions.getAlertListStatus({
                q: { alertId: map(res?.results, o => o?.uuid ?? '') },
              }),
            );*/
          },
        }),
      );
    }
  }, [
    dispatch,
    accountId,
    environmentId,
    filters.startTime,
    filters.endTime,
    filters.searchString,
    alerts.page.info,
    startDate.value,
    getDetails,
    sortByField,
    params.alertStatus,
    params.alertCategory,
    params.region,
    params.resourceType,
    params.severity,
    params.blueprintId,
    params.entity_uuid,
  ]);

  return { getAggregatedFindings, getAlerts };
};
