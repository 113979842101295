import React from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { UserIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const SaaSUsers: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { saasUsersChange } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="SaaS Users"
      icon={<UserIcon color="skyBlue.300" />}
      change={{
        isLoading: saasUsersChange.isLoading,
        first: saasUsersChange.data?.previous_count,
        last: saasUsersChange.data?.current_count,
        percentChange: saasUsersChange.data?.percentage_change,
      }}
      cloudProviders={
        !!inventorySummary.data.items?.['User']?.['SaaS']
          ? Object.keys(inventorySummary.data.items?.['User']?.['SaaS'])
          : []
      }
      count={
        !!inventorySummary.data.items?.['User']?.['SaaS']
          ? sum(Object.values(inventorySummary.data.items?.['User']?.['SaaS']))
          : 0
      }
      isLoading={inventorySummary.isLoading}
      onClick={() =>
        navigate(
          '/inventory/summary?tab=' +
            Categories.Identity +
            '_' +
            Resources.User,
        )
      }
      labelTooltip={formatTooltip({
        header: 'SaaS Users',
        content:
          'Users provisioned natively in SaaS apps such Snowflake, GitLab etc.',
        width: 48,
      })}
    />
  );
};
