import React, { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { GraphIncreaseIcon } from 'components/Icons';
import { LineChart } from 'components/Visualization/Charts';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { formatDate } from 'utils/date';

import { criticalOrHighVulnerabilitiesChartStyles } from './styles';

export const CriticalOrHighVulnerabilities = () => {
  const { vulnerabilitiesBySeverity } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(
      map(vulnerabilitiesBySeverity.data, (item, date) => ({
        Date: formatDate(date, 'D-MMM'),
        Critical: item?.critical,
        High: item?.high,
      })),
    );
  }, [vulnerabilitiesBySeverity.data]);

  return (
    <DashboardOverviewCard
      label="Trend – critical & high severity"
      icon={<GraphIncreaseIcon />}
      iconBgColor="critical"
      content={
        <Box w="full" h="full">
          <LineChart
            data={data}
            isLoading={vulnerabilitiesBySeverity.isLoading}
            xField="Date"
            yField={['Critical', 'High']}
            styles={criticalOrHighVulnerabilitiesChartStyles}
          />
        </Box>
      }
    />
  );
};
