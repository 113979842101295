import React, { FC } from 'react';

import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { map } from 'lodash';

import {
  DetailsIdentifier,
  renderSeverityBar,
  StackedHeader,
} from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { limitedString, toTitleCase } from 'utils/string';

interface Props {
  currentTechInstance: Record<string, any>;
}

export const CVEDetails: FC<Props> = props => {
  const { currentTechInstance: data } = props;
  const showMoreLinks = useDisclosure();

  return (
    <Stack spacing={4} pt={3} h="full">
      <HStack spacing={8}>
        <HStack w="65%" h="full">
          <Stack spacing={5} w="40%">
            <DetailsIdentifier
              label="Finding"
              value={data?.cve_id || data?.vulnerability_id}
            />
            <DetailsIdentifier
              label="Severity"
              value={renderSeverityBar({ value: data?.severity })}
            />
            <DetailsIdentifier
              label="CVSS V3 SCORE"
              value={data?.cvss_v3?.score}
            />
          </Stack>
          <Stack spacing={5} w="60%">
            <DetailsIdentifier
              label="Requires network access"
              value={data?.cvss_v3?.attack_vector === 'Network' ? 'Yes' : 'No'}
            />
            <DetailsIdentifier
              label="Known Exploits"
              value={data?.exploitable ? 'Yes' : 'No'}
            />
            <DetailsIdentifier
              label="Additional Info"
              value={
                <>
                  <HStack>
                    {data?.references?.[0] && (
                      <Link href={data?.references?.[0]} target="_blank">
                        <Box color="primary" cursor="pointer">
                          {limitedString(data?.references?.[0])}
                        </Box>
                      </Link>
                    )}
                    {data?.references?.length > 1 && <Box>,</Box>}
                    {data?.references?.length > 1 && (
                      <Box
                        bg="primary"
                        color="white"
                        borderRadius={6}
                        px={1}
                        onClick={() => showMoreLinks.onOpen()}
                        cursor="pointer"
                      >
                        + {data?.references?.length - 1}
                      </Box>
                    )}
                  </HStack>
                  {showMoreLinks.isOpen && (
                    <Modal
                      isOpen={showMoreLinks.isOpen}
                      onClose={showMoreLinks.onClose}
                      size="2xl"
                      header={
                        <StackedHeader
                          upper={data?.vulnerability_id ?? data?.cve_id}
                          lower={
                            (data?.cvss_score || data?.cvss_v3?.score
                              ? 'CVSS 3.0 SCORE: ' +
                                Number(
                                  data?.cvss_score || data?.cvss_v3?.score,
                                ).toFixed(1) +
                                ' '
                              : '') +
                            '(Severity: ' +
                            toTitleCase(data?.severity) +
                            ')'
                          }
                          type={IconTypes.ExclamationTriangle}
                          bg={data?.severity}
                        />
                      }
                      body={
                        <Stack>
                          {map(data?.references, o => (
                            <Box color="primary">
                              <Link href={o} target="_blank">
                                {o}
                              </Link>
                            </Box>
                          ))}
                        </Stack>
                      }
                    />
                  )}
                </>
              }
            />
          </Stack>

          <Flex
            borderRight="1px solid"
            borderColor="gray.200"
            h="full"
            justify="flex-end"
          />
        </HStack>
        <Stack w="35%" spacing={5}>
          {/*<DetailsIdentifier
            label=""
            value={
              <HStack>
                <Box
                  boxSize={3}
                  borderRadius={2}
                  bg={!!data?.fixed_in_version ? 'green.300' : 'critical'}
                ></Box>
                <Box>{!!data?.fixed_in_version ? 'Yes' : 'No'}</Box>
              </HStack>
            }
          />*/}
          <DetailsIdentifier
            label="First Published"
            value={
              data?.published_date
                ? dayjs(data?.published_date).format('D MMMM YYYY')
                : '-'
            }
          />
          <DetailsIdentifier
            label="Last Modified"
            value={
              data?.last_modified_date
                ? dayjs(data?.last_modified_date).format('D MMMM YYYY')
                : '-'
            }
          />
        </Stack>
      </HStack>
      <Box borderBottom="1px solid" borderColor="gray.200" w="full" />
      <Stack spacing={6}>
        <DetailsIdentifier label="Title" value={data?.title} />
        <DetailsIdentifier
          label="Description"
          value={data?.description}
          direction="column"
          minH={48}
        />
      </Stack>
      <Box borderBottom="1px solid" borderColor="gray.200" w="full" />
      <DetailsIdentifier label="CVSS V3 Vector" value={data?.cvss_v3?.vector} />
      <DetailsIdentifier
        label="Attack Vector (AV)"
        value={data?.cvss_v3?.attack_vector}
      />
      <DetailsIdentifier
        label="Attack Complexity (AC)"
        value={data?.cvss_v3?.attack_complexity}
      />
      <DetailsIdentifier
        label="Privileges Required (PR)"
        value={data?.cvss_v3?.privileges_required}
      />
      <DetailsIdentifier
        label="User Interaction (UI)"
        value={data?.cvss_v3?.user_interaction}
      />
      <DetailsIdentifier label="Scope (S)" value={data?.cvss_v3?.scope} />
      <DetailsIdentifier
        label="Confidentiality Impact(C)"
        value={data?.cvss_v3?.confidentiality_impact}
      />
      <DetailsIdentifier
        label="Integrity Impact(I)"
        value={data?.cvss_v3?.integrity_impact}
      />
      <DetailsIdentifier
        label="Availability Impact (A)"
        value={data?.cvss_v3?.availability_impact}
      />
    </Stack>
  );
};
