import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/compliance-policies';
import { LabelType } from '@ariksa/inventory-core/api';
import { Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';
import { FormSchema } from 'components/index';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';
import { selectInventoryContext } from 'containers/Inventory/InventoryContext/selector';
import { actions as contextActions } from 'containers/Inventory/InventoryContext/slice';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';

interface Props {
  onClose: (isSubmit: boolean) => void;
}

export const AddContextForm: FC<Props> = props => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const { contextList } = useSelector(selectInventoryContext);
  const { contextValues, instanceResourceType, createTag } = useSelector(
    selectInventory,
  );
  const { withAccessBoundary } = useAccessBoundary();
  const [selectedContext, setSelectedContext] = useState<Record<string, any>>(
    {},
  );
  const [contextOptions, setContextOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [valueOptions, setValueOptions] = useState<Record<string, any>[]>([]);
  const { currentInstance } = useInventoryContext();
  const { getResourceData } = useInventory();

  //get context list
  useEffect(() => {
    dispatch(
      contextActions.getContextList({
        q: {
          nativeResource: instanceResourceType as NativeResources,
        },
      }),
    );
  }, [dispatch, instanceResourceType]);

  //get context value list
  useEffect(() => {
    if (!!selectedContext?.data?.dynamic_value) {
      dispatch(
        actions.getContextValues({
          q: withAccessBoundary({
            resourceType: selectedContext?.data?.dynamic_value,
          }),
        }),
      );
    }
  }, [withAccessBoundary, dispatch, selectedContext]);

  //set context value options
  useEffect(() => {
    if (!!selectedContext?.data?.dynamic_value)
      setValueOptions(
        map(contextValues.data, o => ({
          label: o?.name,
          value: o?.uuid,
          data: o,
        })),
      );
    else if (!!selectedContext?.data?.value)
      setValueOptions(
        map(selectedContext?.data?.value, o => ({ label: o, value: o })),
      );
  }, [contextValues.data, selectedContext]);

  //set context list
  useEffect(() => {
    const options = map(contextList.data, o => ({
      label: o?.key,
      value: o?.id,
      data: o,
    }));
    setContextOptions(options);
    setSelectedContext(options[0]);
  }, [contextList.data]);

  const schema: FormSchema =
    selectedContext?.data?.type === LabelType.KeyValue ||
    !!selectedContext?.data?.dynamic_value
      ? {
          value: {
            type: 'react-select',
            label: 'Choose value',
            options: valueOptions,
            defaultValue: valueOptions[0],
            isLoading: contextValues.isLoading,
          },
        }
      : {};

  const handleSubmit = data => {
    const { value } = data;
    dispatch(
      actions.createTags({
        q: {
          userTagCreate: [
            {
              label_id: selectedContext?.value,
              value:
                selectedContext?.data?.type === LabelType.KeyValue ||
                !!selectedContext?.data?.dynamic_value
                  ? value?.value
                  : null,
              resource_id: currentInstance?.uuid,
              account_id: currentInstance?.account,
              native_resource: instanceResourceType as NativeResources,
            },
          ],
        },
        onSuccess: () => {
          getResourceData();
          onClose(true);
        },
      }),
    );
  };

  return (
    <Form
      schema={{
        context: {
          type: 'react-select',
          isRequired: true,
          label: 'Choose context',
          options: contextOptions,
          value: selectedContext,
          onChange: setSelectedContext,
          isLoading: contextList.isLoading,
        },
        description: {
          type: 'custom-with-form-control',
          component: (
            <Box
              borderRadius="sm"
              border="1px solid"
              borderColor="gray.200"
              p={2}
              h={24}
            >
              {selectedContext?.data?.notes}
            </Box>
          ),
          label: 'Description',
        },
        ...schema,
      }}
      buttonOptions={{
        submit: {
          name: 'Submit',
          isLoading: createTag.isLoading,
        },
        reset: {
          isVisible: true,
          name: 'Cancel',
          onClick: onClose,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
