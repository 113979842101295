import React, { FC, useEffect } from 'react';

import { useInventorySearchV2Service } from 'services/Inventory/useInventorySearchV2Service';

import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const NetworkMapWrapper: FC = () => {
  const {
    resourceInsight,
    resourceUuid,
    resourceType,
  } = useActiveResourceContext();
  const { data: activeResource } = resourceInsight;
  const { searchMap } = useInventorySearchV2Service();

  useEffect(() => {
    searchMap.sync({
      searchQuery: {
        query: `Show ${resourceType} with ariksa_id = ${resourceUuid}`,
      },
    });
  }, [
    activeResource.account,
    activeResource.uuid,
    searchMap,
    resourceUuid,
    resourceType,
  ]);

  return (
    <>
      <PostureGraphWithContext
        data={searchMap.data ?? {}}
        isLoading={searchMap.isLoading}
        isLoaded={searchMap.isSuccess}
      />
    </>
  );
};
