import React, { FC, useEffect, useState } from 'react';

import { RuleReadWithMetadata } from '@ariksa/compliance-policies/api';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { RemediationAccordion } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationAccordion';
import { TextHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/TextHeader';
import { PolicyRemediationConfirmationModal } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/Policies/Remediation/PolicyRemediationConfirmationModal';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

interface Props {
  policy: RuleReadWithMetadata;
}

export const PolicyRemediation: FC<Props> = props => {
  const { policy } = props;
  const params = useParams<{ id: string }>();

  const { remediation } = useSelector(selectBlueprints);
  const remediateDisclosure = useDisclosure();
  const confirmationDisclosure = useDisclosure();
  const [currentRemediation, setCurrentRemediation] = useState<
    Record<string, any>
  >({});
  const [autoRemediate, setAutoRemediate] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.getAvailableRemediation({
        q: {
          id: policy.id!,
          blueprintId: params.id,
        },
      }),
    );
  }, [policy, dispatch, params.id]);

  return (
    <Stack spacing={4} pb={6}>
      <Box bg={customTheme.colors.gray['50']} p={4} color={'primary'}>
        <Text>
          NOTE: Ariksa provides contextual, but broad recommendations for
          remediation. Listed below are one or more remediation steps that may
          be relevant for this policy. However, you are responsible for
          undertaking the appropriate remediation steps depending on your
          organizational and/or security policies.
        </Text>
      </Box>
      <Stack>
        <TextHeader>Remediation options for this policy</TextHeader>
        <CustomSpinner loading={remediation.isLoading} />
        <RemediationAccordion
          remediations={remediation.data}
          onExecuteRemediation={r => {
            setCurrentRemediation(r);
            confirmationDisclosure.onOpen();
          }}
          remediateDisclosure={remediateDisclosure}
          showRemediateButton={false}
          autoRemediate={autoRemediate}
          setAutoRemediate={setAutoRemediate}
        />
      </Stack>
      {confirmationDisclosure.isOpen && (
        <PolicyRemediationConfirmationModal
          remediation={currentRemediation}
          policy={policy}
          blueprintId={params.id!}
          confirmationDisclosure={confirmationDisclosure}
          onCancel={() => setAutoRemediate(!autoRemediate)}
        />
      )}
    </Stack>
  );
};
