import React, { ReactNode } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import {
  Heading3,
  AriksaIcon,
  IAriksaIconProps,
  Heading2,
  CustomTooltip,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

interface Props extends IAriksaIconProps {
  upper: ReactNode;
  lower: ReactNode;
  cloudProvider?: string;
  isLoading?: boolean;
}

export const StackedHeader = (props: Props) => {
  const { upper, lower, type, cloudProvider, icon, isLoading, ...rest } = props;
  return (
    <HStack spacing={6} w={'full'}>
      {(type || icon) && (
        <AriksaIcon
          type={type}
          size="medium"
          bg={cloudProvider}
          icon={icon}
          {...rest}
        />
      )}
      {isLoading ? (
        <Box>
          <CustomSpinner />
        </Box>
      ) : (
        <Stack spacing={1} flex={1} w={'90%'}>
          <Heading2
            w={'90%'}
            fontFamily={'Rubik'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            <CustomTooltip label={upper} aria-label="A tooltip">
              {upper}
            </CustomTooltip>
          </Heading2>
          <Heading3
            color={customTheme.colors.gray['300']}
            fontWeight={400}
            //fontSize="md"
          >
            {lower}
          </Heading3>
        </Stack>
      )}
    </HStack>
  );
};
