import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/DataDisplay';
import { Layout } from 'components/Layout';
import { ContextForm } from 'containers/Inventory/InventoryContext/Components/ContextWizard/ContextForm';
import { actions } from 'containers/Inventory/InventoryContext/slice';

interface Props {
  actionType?: 'Create' | 'Update';
}

export const ContextWizard: FC<Props> = props => {
  const { actionType } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCancel = () => {
    dispatch(actions.resetFormData());
    navigate(`/inventory/context`);
  };

  return (
    <Layout
      middleComponent={
        <Card
          styles={{ card: { pl: 32 } }}
          contentComponent={
            <Box pl={14}>
              <ContextForm actionType={actionType} onCancel={onCancel} />
            </Box>
          }
        />
      }
    />
  );
};
