import {
  AllowlistApiCreateAllowlistRequest,
  AllowlistApiGetAllowedStatusRequest,
  AllowlistApiGetAllowlistRequest,
  AllowlistApiGetApplicationsRequest,
  AllowlistApiRemoveAllowlistRequest,
  AllowlistResponse,
  ApplicationResponse,
  Status,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService } from 'api/services';

import { actions } from './slice';

export function* whitelistSaga() {
  yield takeLatestAction(actions.getAllowLists, doGetAllowLists);
  yield takeLatestAction(actions.getAllowListByID, doGetAllowListByID);
  yield takeLatestAction(actions.createAllowList, doCreateAllowList);
  yield takeLatestAction(actions.deleteAllowList, doDeleteAllowList);
  yield takeLatestAction(actions.updateAllowList, doUpdateAllowList);
  yield takeLatestAction(actions.getEC2Applications, doGetEC2Applications);
  yield takeLatestAction(actions.getAllowListStatus, doGetAllowListStatus);
}

export function* doGetAllowLists(ctx: QueryContext<AllowlistResponse[]>) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.getAllowlists(ctx.params),
  );
}

export function* doGetAllowListStatus(
  ctx: QueryContext<Status[], AllowlistApiGetAllowedStatusRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.getAllowedStatus(ctx.params),
  );
}

export function* doGetAllowListByID(
  ctx: QueryContext<AllowlistResponse, AllowlistApiGetAllowlistRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.getAllowlist(ctx.params),
  );
}

export function* doGetEC2Applications(
  ctx: QueryContext<ApplicationResponse[], AllowlistApiGetApplicationsRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.getApplications(ctx.params),
  );
}

export function* doCreateAllowList(
  ctx: QueryContext<any, AllowlistApiCreateAllowlistRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.createAllowlist(ctx.params),
  );
}

export function* doDeleteAllowList(
  ctx: QueryContext<AllowlistResponse, AllowlistApiRemoveAllowlistRequest>,
) {
  yield call(ctx.fetch, () =>
    InventoryService.AllowList.removeAllowlist(ctx.params),
  );
}

export function* doUpdateAllowList(ctx: QueryContext<any>) {
  /*yield call(ctx.fetch, () =>
    InventoryService.AllowList.getAllowList(ctx.params),
  );*/
}
