const USER_INFO = 'user_info';
const BASIC_AUTH = 'basic_auth';
const AUTH_TOKENS = 'auth_token';

// use local storage to save data on the browser
export const LocalStoreService = {
  setUserInfo(userInfo) {
    localSet(USER_INFO, userInfo);
  },
  getUserInfo() {
    return localGet(USER_INFO);
  },
  deleteUserInfo() {
    return localDel(USER_INFO);
  },
  getUserBasicAuth() {
    return localGet(BASIC_AUTH);
  },
  setUserBasicAuth(v) {
    return localSet(BASIC_AUTH, v);
  },
  deleteUserBasicAuth() {
    localDel(BASIC_AUTH);
  },
  getUserOAuthTokens() {
    return localGet(AUTH_TOKENS);
  },
  setUserOAuthTokens(v) {
    return localSet(AUTH_TOKENS, v);
  },
  deleteUserOAuthTokens() {
    localDel(AUTH_TOKENS);
  },
  getChakraColorMode() {
    return localStorage.getItem('chakra-ui-color-mode');
  },
  clear() {
    this.deleteUserOAuthTokens();
    this.deleteUserBasicAuth();
    this.deleteUserInfo();
  },
};

function localSet(k, v) {
  if (!k) {
    throw Error(`No valid key was provided for local storage setItem`);
  }
  localStorage.setItem(k, JSON.stringify(v));
}

function localGet(k) {
  const item = localStorage.getItem(k);
  if (item) {
    return JSON.parse(item);
  }
}
function localDel(k) {
  if (!k) {
    throw Error(`No valid key was provided for local storage setItem`);
  }
  localStorage.removeItem(k);
}
