import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { usePageContentContext } from 'components/Layout';
import { SideNavMenuItemProps } from 'components/Navigation';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { InventorySidebar } from 'containers/Inventory/CloudInventory/Components/InventorySideNav/InventorySidebar';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useInjector } from 'utils/inject';

import {
  InventoryContextProvider,
  InventoryContextProps,
} from './hooks/context';
import { inventorySaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const CloudInventory = () => {
  useInjector(sliceKey, reducer, inventorySaga);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { contentHeight } = usePageContentContext();
  const dispatch = useDispatch();
  const { handleCategoryChange } = useInventory();

  const [showInventorySummary, setShowInventorySummary] = useState(false);
  const [
    showCategoryInventorySummary,
    setShowCategoryInventorySummary,
  ] = useState(false);
  const [isOpenAddContext, setIsOpenAddContext] = useState(false);
  const [isOpenAddWhitelist, setIsOpenAddWhitelist] = useState(false);
  const [sortBy, setSortBy] = useState<string>();
  const [currentInstance, setCurrentInstance] = useState<Record<string, any>>(
    {},
  );
  const [selectedRegion, setSelectedRegion] = useState<Record<string, any>>({
    label: 'All regions',
    value: undefined,
  });
  const [selectedVpc, setSelectedVpc] = useState<Record<string, any>>({
    label: 'All VPC',
    value: undefined,
  });
  const [sideNavItemsMapping, setSideNavItemsMapping] = useState<
    Record<string, SideNavMenuItemProps>
  >({});

  const [menuItems, updateMenuItems] = useState<SideNavMenuItemProps[]>([]);

  useEffect(() => {
    dispatch(sharedStateActions.getRegions({ q: {} }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.resetCurrentCategory());
    dispatch(actions.resetNativeResource());
    if (!!accountId || !!environmentId) {
      dispatch(
        actions.getResourceCategories({
          q: { accountId, environmentId },
        }),
      );
      dispatch(actions.resetSearchItems());
    }
  }, [dispatch, accountId, environmentId]);

  const contextValue: InventoryContextProps = {
    currentInstance,
    setCurrentInstance,
    isOpenAddContext,
    setIsOpenAddContext,
    showInventorySummary,
    setShowInventorySummary,
    showCategoryInventorySummary,
    setShowCategoryInventorySummary,
    isOpenAddWhitelist,
    setIsOpenAddWhitelist,
    sortBy,
    setSortBy,
    sideNavItemsMapping,
    setSideNavItemsMapping,
    handleCategoryChange,
    selectedRegion,
    selectedVpc,
    setSelectedVpc,
    setSelectedRegion,
    updateMenuItems,
    menuItems,
  };

  return (
    <ActiveResourceProvider>
      <InventoryContextProvider value={contextValue}>
        <Box h={contentHeight + 'px'}>
          <InventorySidebar />
        </Box>
      </InventoryContextProvider>
    </ActiveResourceProvider>
  );
};
