import React, { FC, useCallback, useEffect, useState } from 'react';

import { RuleReadWithMetadata } from '@ariksa/compliance-policies/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, Text, HStack } from '@chakra-ui/react';
import { forEach, isEmpty, map, toArray } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useBlueprintService } from 'services/ComplianceService/Blueprint';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import {
  renderTime,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { DeleteActionButton } from 'components/DataEntry';
import { CloseCircleOutlineIcon } from 'components/Icons';
import { renderTags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';
import { EnvironmentName } from 'containers/App/utils';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

import { selectBlueprints } from '../../../../selectors';

interface Props {
  row: RuleReadWithMetadata;
  isExpanded: boolean;
}

export const ResourceExceptions: FC<Props> = props => {
  const { row, isExpanded } = props;
  const { blueprint } = useSelector(selectBlueprints);
  const { getExceptions } = useBlueprintService();
  const { inventoryInsightV2 } = useInventoryInsightService();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { toCloudAccountId } = useCloudAccountId();
  const [pageInfo, setPageInfo] = useState(INIT_PAGE_INFO);

  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();

  const getResources = useCallback(() => {
    isExpanded &&
      getExceptions.sync({
        blueprintId: blueprint.data.id,
        ruleId: row.id,
        page: pageInfo.page_number,
        size: pageInfo.page_size,
      });
  }, [getExceptions, pageInfo, blueprint.data, row, isExpanded]);

  const getInsights = useCallback(() => {
    const accountIds = new Set<string>();
    forEach(getExceptions.data?.items, o => accountIds.add(o.account_id));
    const resourceUuids = map(getExceptions.data?.items, o => o.resource_id);

    !isEmpty(resourceUuids) &&
      inventoryInsightV2.sync({
        insightV2Request: {
          resource_uuids: resourceUuids,
          account_id: toArray(accountIds) as string[],
          resource_type: row.resource_type as NativeResources,
        },
      });
  }, [getExceptions.data, inventoryInsightV2, row]);

  useEffect(() => {
    getResources();
  }, [getResources]);

  useEffect(() => {
    isExpanded && getInsights();
  }, [isExpanded, getInsights]);

  useEffect(() => {
    let resources = {};
    forEach(
      getExceptions.data?.items,
      o => (resources[o.resource_id] = { ...o, exception_id: o.id }),
    );

    forEach(
      inventoryInsightV2.data?.items,
      o => (resources[o.uuid] = { ...resources[o.uuid], ...o }),
    );

    setData(map(resources, o => o));
  }, [getExceptions.data, inventoryInsightV2.data]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.native_name}
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_name} />
          }
        >
          <StackedCell upper={row?.name} lower={row?.resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Deployed in',
      accessor: 'account_id',
      align: 'left',
      render: ({ row }) => (
        <StackedCell upper={row?.vpc ?? row?.vpc_id} lower={row?.region} />
      ),
    },
    {
      header: 'Environment',
      accessor: 'account_id',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={<EnvironmentName environmentId={row?.environment_id} />}
          lower={toCloudAccountId(row?.account)}
        />
      ),
    },
    {
      header: 'Cloud Tags',
      accessor: 'tags',
      align: 'left',
      render: ({ value }) =>
        renderTags(map(value, o => o?.key + ':' + o?.value)),
    },
    {
      header: 'Added by',
      accessor: 'created_by',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Type',
      align: 'left',
      render: () => (
        <HStack>
          <Box boxSize={4}>
            <Center>
              <CloseCircleOutlineIcon color="red" />
            </Center>
          </Box>
          <Box>Exception</Box>
        </HStack>
      ),
    },
    {
      header: 'Actions',
      render: ({ row }) => (
        <Center onClick={e => e.stopPropagation()}>
          <DeleteActionButton
            label="Remove exception"
            header="Remove Exception"
            onConfirm={() => {
              dispatch(
                actions.removeException({
                  q: { exceptionId: row?.exception_id },
                }),
              );
            }}
            name="CONFIRM"
            confirmationText={
              <Text>
                Please type <Text as="b">CONFIRM</Text> to remove exception
              </Text>
            }
          />
        </Center>
      ),
      styles: { width: '150px', cell: { maxWidth: '150px' } },
    },
  ];

  return (
    <Box h="md">
      <ActiveResourceProvider>
        <Table
          noDataMessage={<Box fontSize="md">No Resource exception</Box>}
          data={data ?? []}
          columns={columns}
          cursor="pointer"
          isLoading={getExceptions.isLoading || inventoryInsightV2.isLoading}
          pagination={{
            totalCount: getExceptions.data?.total ?? 0,
            pageInfo,
            onChange: setPageInfo,
          }}
          styles={{ header: { zIndex: 800 } }}
          onRowClick={row => {
            updateActiveResource({
              resourceType: row?.native_name,
              uuid: row.uuid,
              accountId: row.account,
              resourceId: row.resource_id,
            });
            onOpenMetadata();
          }}
        />
      </ActiveResourceProvider>
    </Box>
  );
};
