import React, { FC, ReactElement } from 'react';

import { BoxProps, Center } from '@chakra-ui/react';
import { merge } from 'lodash';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconType } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';

import { actionButtonHoverStyles, actionButtonStyles } from './styles';

interface IActionButton extends Record<string, any> {
  label?: string;
  icon?: ReactElement;
  iconType?: IconType;
  text?: string;
  onClick?: (e: any) => void;
  noHover?: boolean;
  isDisabled?: boolean;
  styles?: BoxProps;
  isActive?: boolean;
  tooltipWidth?: string | number;
}

export const ActionButton: FC<IActionButton> = props => {
  const {
    label,
    icon,
    onClick,
    isActive,
    text,
    isDisabled,
    noHover,
    _hover = {},
    styles = {},
    iconType,
    tooltipWidth = 24,
    ...rest
  } = props;

  const handleClick = e => {
    if (!isDisabled) {
      e.preventDefault();
      e.stopPropagation();
      onClick?.(e);
    }
  };

  const hoverStyle = noHover ? {} : { ...actionButtonHoverStyles(isDisabled) };

  return (
    <CustomTooltip
      label={label}
      w={tooltipWidth}
      textAlign="center"
      onMouseOver={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={e => {
        e.stopPropagation();
      }}
      onMouseDown={e => {
        e.stopPropagation();
      }}
    >
      <Center
        {...actionButtonStyles}
        _hover={merge(hoverStyle, _hover)}
        opacity={isDisabled ? 0.4 : 1}
        onClick={handleClick}
        //border="1px solid"
        //borderColor="gray.200"
        {...styles}
        {...rest}
      >
        {!!iconType ? getIcon(iconType) : icon}
        {text && text}
      </Center>
    </CustomTooltip>
  );
};
