import React from 'react';

import { Box, HStack, ListItem, Stack, UnorderedList } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H3, StackedHeader } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';

interface AlertDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AutomatedRemediationOfIssuesHelp = (
  props: AlertDetailsDrawerProps,
) => {
  const { isOpen, onClose } = props;

  const remediation = [
    'Manual remediation that is initiated by the user on as-needed basis',
    'Automated remediation that is initiated by Ariksa on behalf of the user with guardrails',
  ];

  const manual = [
    'Choose from pre-defined or custom remediation options (custom options are tied to individual policies)',
    'Ariksa auto-fills inputs to remediation commands',
    'You’ll need to provide custom inputs where needed. Ariksa will automatically prompt',
    'Total transparency on the command being executed and all inputs being used for transparency',
  ];
  const automated = [
    'During manual remediation: You can choose to automatically remediation other existing and ' +
      'future issues due to violation of the same policy and restricted to the account where the ' +
      'manual remediation is being executed. This implicitly provides guardrails for automated remediation.',
    'From policy blueprint: For every remediation option available for a given policy violation, ' +
      'you can specify whether the remediation needs to be automatically executed. This will apply ' +
      'to all accounts where the policy blueprint is enforced. Given the broader scope of automated ' +
      'response, please ensure that you have the right approvals in place to automatically execute remediation.',
  ];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Stack justify={'space-between'}>
          <StackedHeader
            type={IconTypes.LearnMore}
            upper="Automated remediation of issues"
            lower="Learn more"
          />
        </Stack>
      }
      body={
        <Stack spacing={6} pt={4}>
          <Stack spacing={4}>
            <H3>REMEDIATION OPTIONS</H3>
            <Stack fontSize="md">
              <Box>
                To reduce mean-time-to-response or mean-time-to-remediation
                (MTTR), Ariksa provides an extensive pre-built library of
                remediation commands to address policy violations.
              </Box>
              <Box>Ariksa remediation can be carried out in two modes</Box>
              {remediation.map((o, index) => (
                <HStack spacing={4}>
                  <Box>({index + 1})</Box>
                  <Box>{o}</Box>
                </HStack>
              ))}
            </Stack>
          </Stack>
          <Box color="primary" fontSize="md">
            NOTE: Account must be enabled for remediation for Ariksa to execute
            remediation commands
          </Box>
          <Stack spacing={4}>
            <Box
              borderBottom="1px solid"
              borderColor={customTheme.colors.gray['200']}
              pb={1}
            >
              <H3>MANUAL, SINGLE-CLICK REMEDIATION</H3>
            </Box>
            <UnorderedList spacing={4} fontSize="md">
              {manual.map(o => (
                <ListItem pl={4}>{o}</ListItem>
              ))}
            </UnorderedList>
          </Stack>
          <Stack spacing={4}>
            <Box
              borderBottom="1px solid"
              borderColor={customTheme.colors.gray['200']}
              pb={1}
            >
              <H3>AUTOMATED REMEDIATION</H3>
            </Box>
            <Box fontSize="md">
              There are two ways you can set up automated remediation:
            </Box>
            <UnorderedList spacing={4} fontSize="md">
              {automated.map(o => (
                <ListItem pl={4}>{o}</ListItem>
              ))}
            </UnorderedList>
          </Stack>
          <Box color="primary" fontSize="md">
            NOTE: Automated remediation is not possible where user inputs are
            needed for execution
          </Box>
        </Stack>
      }
      closeButton
      styles={{
        content: { maxW: '4xl' },
        drawer: { portalProps: {} },
      }}
    />
  );
};
