import { useToast, Box, Stack } from '@chakra-ui/react';

import { defaultConfig } from './utils';

export function useToasts() {
  const toast = useToast();

  const errorToast = ({ title, description = '' }) => {
    toast({
      render: () => (
        <Stack bg="white">
          <Box>{title}</Box>
          <Box>{description}</Box>
        </Stack>
      ),
      //status: 'error',
      ...defaultConfig,
    });
  };

  const successToast = ({ title, description = '' }) => {
    toast({
      title,
      description,
      status: 'success',
      ...defaultConfig,
    });
  };

  return { errorToast, successToast };
}
