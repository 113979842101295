/**
 *
 * Update Aws Account
 *
 */

import React, { memo } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, Stack, Text, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AwsIcon, Form } from 'app/components';
import { Tag } from 'components/DataDisplay';
import { actions as setupActions } from 'containers/Setup/slice';

import { selectCloudAccounts } from '../../../selectors';

interface Props {
  //onOpenProgress: () => void;
}

export const EditAWSAccount = memo((props: Props) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { accountDetails } = useSelector(selectCloudAccounts);
  const { data, isLoading } = accountDetails;
  const { aws = {} } = data;

  const handleSubmit = data => {
    const { account_name } = data;

    dispatch(
      setupActions.updateCloudAccount({
        q: {
          uuid: params.id!,
          cloudAccountUpdate: {
            cloud_type: CloudProviders.Aws,
            name: account_name,
            aws: {
              onboard_member_account: true,
            },
          },
        },
        onSuccess: () => {
          navigate('/setup/accounts');
        },
      }),
    );
  };

  const handleCancel = () => {
    navigate('/setup/accounts');
  };
  return (
    <Box w="full">
      <Form
        title="Update AWS Account"
        titleIcon={<AwsIcon />}
        styles={{ form: { title: { iconFilled: false } } }}
        schema={{
          account_name: {
            type: 'text',
            label: 'Account Name',
            placeholder: 'Engineering-US',
            isRequired: true,
            defaultValue: data.name,
          },
          /*onboard_member_account: {
                type: 'switch',
                label:
                  'Onboard all member accounts associated with this account',
                defaultIsChecked: aws?.onboard_member_account,
              },*/
          account_id: {
            label: 'Account ID',
            type: 'text',
            placeholder: 'Create ARN and paste here...',
            isDisabled: true,
            defaultValue: data.cloud_account_id,
          },
          external_id: {
            label: 'External ID',
            type: 'text',
            placeholder: 'Create ARN and paste here...',
            isDisabled: true,
            defaultValue: aws?.external_id,
          },
          aws_cfs_template_type: {
            type: 'custom',
            component: () => (
              <Stack fontSize="xs">
                <Text>Cloud Access Permissions</Text>
                <Flex>
                  <Tag label="Read Only" />
                </Flex>
              </Stack>
            ),
          },
          role_arn: {
            label: 'Role ARN',
            type: 'text',
            placeholder: 'Create ARN and paste here...',
            isDisabled: true,
            defaultValue: aws?.role_arn,
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Update',
            isLoading: isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleCancel,
          },
        }}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
});
