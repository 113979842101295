import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { values } from 'lodash';
import { colors } from 'theme/theme2/colors';

import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { PERMISSION_NODE } from './nodes_types';

export function registerPermissionsNode() {
  G6.registerNode(
    PERMISSION_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'circle',
          {
            attrs: {
              x: 30,
              y: 30,
              fill: colors.black,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              r: 20,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        const sum = values(
          // @ts-ignore
          cfg?.data?.count,
          // @ts-ignore
        ).reduce((a, b) => a + b);

        group.addShape('text', {
          attrs: {
            x: x + 20,
            y: y + 27,
            textAlign: 'center',
            textBaline: 'middle',
            text: `${sum}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 14,
            // fontStyle: 'bold',
            opacity: 1,
            fill: colors.white,
            cursor: 'pointer',
          },
          name: 'label',
        });

        group.addShape('text', {
          attrs: {
            x: x + 20,
            y: height + 30 + 10,
            textAlign: 'center',
            // @ts-ignore
            text: 'Permissions', // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'id',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
