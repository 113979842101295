import React, { FC, memo, useCallback, useEffect, useState } from 'react';

import { AggregatedAlertDetails } from '@ariksa/notification';
import { AggregateAlertsBy } from '@ariksa/notification/api';
import { Box, Stack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';
import { Optional } from 'types/utils';

import { NotificationService } from 'api/services';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { renderSeverityBar, WithResourceIcon } from 'components/index';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';
import { AggregatedAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/AggregatedAlertDrawer';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';

interface Props {
  alertCategory: string;
  categoryClass?: string;
}

export const TopAggregatedAlertsTable: FC<Props> = memo(props => {
  const { alertCategory, categoryClass } = props;

  const { environmentId, accountId } = useAccessBoundary();
  const alertDrawer = useDisclosure();
  const [aggregatedAlert, setAggregatedAlert] = useState<
    Optional<AggregatedAlertDetails>
  >(undefined);
  const { topAggregatedAlerts } = useSelector(selectFindingsOverview);

  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const loadAlertsGroupedByRuleId = useCallback(() => {
    if (!alertCategory) return;

    const payload = {
      pageNumber: 1,
      pageSize: 3,
      environmentId,
      accountId,
      alertCategory,
      categoryClass,
      aggregateBy: AggregateAlertsBy.GroupId,
    };

    dispatch(
      actions.getTopAggregatedAlerts({
        q: payload,
        status: QueryStatus.pending,
      }),
    );

    NotificationService.Alerts.getAggregatedAlerts(payload).then(res => {
      dispatch(
        actions.getTopAggregatedAlerts({
          q: payload,
          status: QueryStatus.fulfilled,
          data: res.data,
        }),
      );
    });
  }, [alertCategory, environmentId, accountId, categoryClass, dispatch]);

  useEffect(() => {
    loadAlertsGroupedByRuleId();
  }, [loadAlertsGroupedByRuleId]);

  const columns: TableColumnProps[] = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Finding</Box>,
      render: ({ row }) => {
        const type = row?.multi_resource
          ? 'CloudService'
          : row?.resource_types?.[0];
        return (
          <WithResourceIcon
            resourceType={type}
            bgColor={row?.multi_resource ? 'primary' : row?.resource_types?.[0]}
            iconTooltip={<ResourceTypeIconTooltip resourceType={type} />}
            tooltip={row?.description}
          >
            {row?.description}
          </WithResourceIcon>
        );
      },
    },
    {
      header: 'Issues',
      accessor: 'severity',
      render: ({ row }) =>
        row.severity.CRITICAL +
        row.severity.MEDIUM +
        row.severity.LOW +
        row.severity.HIGH,
      styles: {
        width: '100px',
        cell: { maxW: '100px' },
      },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      render: ({ row }) => {
        let value = 'low';
        if (!!row.severity.MEDIUM) value = 'medium';
        if (!!row.severity.CRITICAL) value = 'critical';
        if (!!row.severity.HIGH) value = 'high';
        return renderSeverityBar({ value, isInline: false });
      },
      styles: {
        width: '100px',
        cell: { maxW: '100px' },
      },
    },
  ];

  return (
    <ActiveResourceProvider>
      <Stack h="full" spacing={4}>
        <Box flex={1}>
          <Table
            data={topAggregatedAlerts?.[alertCategory]?.data ?? []}
            columns={columns}
            isLoading={topAggregatedAlerts?.[alertCategory]?.isLoading}
            headerClassName="dashboard_alerts_table"
            onRowClick={r => {
              setAggregatedAlert(r);
              alertDrawer.onOpen();
            }}
            isRowActive={r =>
              r.alert_rule_id === aggregatedAlert?.alert_category
            }
            cursor={'pointer'}
            styles={{cell: {py: 3}}}
          />
        </Box>

        {alertDrawer.isOpen && aggregatedAlert && (
          <AggregatedAlertDrawer
            alert={aggregatedAlert}
            isOpen={alertDrawer.isOpen}
            onClose={alertDrawer.onClose}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        )}
      </Stack>
    </ActiveResourceProvider>
  );
});
