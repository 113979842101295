import { PayloadAction } from '@reduxjs/toolkit';
import { call, select, takeLatest, put } from 'redux-saga/effects';

import { authOptions } from 'api/auth';
import {
  RoleService,
  RoleServiceDeleteRequest,
  RoleServiceListRequest,
  RoleServiceUpdateRequest,
} from 'api/auth/api.pb';
import { selectUser } from 'containers/App/selectors';
import { callApiFn } from 'utils/saga';

import { selectRoles } from './selectors';
import { actions } from './slice';

export function* rolesSaga() {
  yield takeLatest(actions.createRole.type, doCreateRole);
  yield takeLatest(actions.saveCopy.type, doSaveCopyRole);
  yield takeLatest(actions.deleteRole.type, doDeleteRole);
  yield takeLatest(actions.updateRole.type, doUpdateRole);
  yield takeLatest(actions.getRoles.type, doGetRoles);
}

export function* doGetRoles() {
  const { info } = yield select(selectUser);
  const data: RoleServiceListRequest = {
    organization: info.org_id,
  };

  yield call(callApiFn, {
    fn: RoleService.List,
    data,
    onSuccess: actions.getRolesSuccess,
    onError: actions.getRolesError,
  });
}

export function* doUpdateRole(action: PayloadAction<any>) {
  const role = action.payload;
  const { info } = yield select(selectUser);
  const tempResources = role?.resources.filter(val => val === '*');

  const data: RoleServiceUpdateRequest = {
    role: {
      ...role,
      resources: tempResources.length ? tempResources : role.resources,
    },
    organization: info.org_id,
  };

  try {
    yield call(RoleService.Update, data, authOptions());
    yield put(actions.updateRoleSuccess(role));
  } catch (err) {
    yield put(actions.updateRoleError(err));
  }
}

export function* doDeleteRole(action: PayloadAction<any>) {
  const { name } = action.payload;
  const { id } = action.payload;
  const { info } = yield select(selectUser);

  const data: RoleServiceDeleteRequest = {
    id,
    organization: info.org_id,
  };

  try {
    yield call(RoleService.Delete, data, authOptions());
    yield put(actions.deleteRoleSuccess());
  } catch (err) {
    yield put(actions.deleteRoleError(err));
  }
}

export function* doSaveCopyRole(action: PayloadAction<any>) {
  console.log(action);

  const role = action.payload;
  const { info } = yield select(selectUser);

  const { editing, editingCopy, expanded, id, ...rest } = role;

  const data = {
    organization: info.org_id,
    role: rest,
  };

  yield call(callApiFn, {
    fn: RoleService.Create,
    data: data,
    onSuccess: actions.saveCopySuccess,
    onError: actions.saveCopyError,
    errorTitle: 'Failed to copy the role',
  });
}

export function* doCreateRole() {
  const { roleForm } = yield select(selectRoles);
  const { info } = yield select(selectUser);
  const { role } = roleForm;

  const { editing, expanded, id, ...rest } = role;

  const tempResources = rest?.resources.filter(val => val === '*');

  rest.resources = tempResources.length > 0 ? tempResources : rest?.resources;

  const data = {
    organization: info.org_id,
    role: rest,
  };

  yield call(callApiFn, {
    fn: RoleService.Create,
    data: data,
    onSuccess: actions.createRoleSuccess,
    onError: actions.createRoleError,
    errorTitle: 'Failed to create new role',
  });
}
