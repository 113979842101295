import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  RefObject,
} from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { select, hierarchy, partition, arc, format, pointer } from 'd3';
import { map } from 'lodash';

interface SunburstDataType extends Record<string, any> {
  name: string;
  value?: number;
  color?: string;
  children?: SunburstDataType[];
}

interface SunburstChartProps {
  data: SunburstDataType[];
  renderLegend: (item: SunburstDataType, index?: number) => ReactNode;
  height?: string | number;
  ref: RefObject<any>;
}

export const SunburstChart = (props: SunburstChartProps) => {
  const { data, renderLegend, height = '90%' } = props;
  const ref = useRef<any>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [tooltip, setTooltip] = useState({
    show: false,
    content: '',
    x: 0,
    y: 0,
  });

  // Resize chart when window size or parent container changes
  useEffect(() => {
    //if (!ref) return;
    const updateSize = () => {
      const {
        width,
        height,
      } = ref?.current?.parentNode?.getBoundingClientRect();
      setDimensions({ width, height });
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [ref]);

  useEffect(() => {
    const sunburstData = {
      name: 'root',
      children: data,
      value: 0,
      color: '',
    };
    const { width, height } = dimensions;
    if (width === 0 || height === 0) return;
    const radius = Math.min(width, height) / 2;

    // Create root hierarchy and partition layout
    const root = hierarchy(sunburstData)
      .sum(d => d?.value || 0)
      .sort((a, b) => (b?.value || 0) - (a?.value || 0));
    const partitionLayout = partition().size([2 * Math.PI, radius]);
    // @ts-ignore
    partitionLayout(root);

    // Create arc generator
    const arcGenerator = arc()
      .startAngle(d => d?.x0)
      .endAngle(d => d?.x1)
      .padAngle(d => Math.min((d?.x1 - d?.x0) / 2, 0.04))
      .padRadius(radius / 2)
      .innerRadius(d => d?.y0)
      .outerRadius(d => d?.y1 - 3);

    select(ref?.current!).selectAll('*').remove(); // Clear previous render

    // Set up SVG container
    const svg = select(ref?.current!)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Render arcs
    svg
      .selectAll('path')
      .data(root.descendants().filter(d => d.depth))
      .join('path')
      .attr('fill', d => d?.data?.color)
      .attr('d', arcGenerator)
      .append('title')
      .text(
        d =>
          `${d
            .ancestors()
            .map(d => d.data.name)
            .reverse()
            .join('/')}\n${format(d?.value?.toString() || '')}`,
      )
      .on('mousemove', (event, d) => {
        const [x, y] = pointer(event);
        setTooltip({
          show: true,
          content: `${d.data.name}: ${d.value}`,
          x: x + width / 2,
          y: y + height / 2,
        });
      })
      .on('mouseout', () => {
        setTooltip({ ...tooltip, show: false });
      });

    // Add rotated labels on the outer layer
    svg
      .selectAll('text')
      .data(
        root
          .descendants()
          .filter(
            d => d.depth === 2 && ((d.y0 + d.y1) / 2) * (d.x1 - d.x0) > 10,
          ),
      ) // Only outer layer
      .enter()
      .append('text')
      .attr('transform', d => {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = (d.y0 + d.y1) / 2 - 10;
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`;
      })
      .attr('dy', '0.35em')
      .attr('text-anchor', d => ((d.x0 + d.x1) / 2 > Math.PI ? 'end' : 'start')) // Flip based on angle
      .attr('font-size', '12px')
      .attr('fill', '#fff')
      .text(d => `${d.value}`);
  }, [data, dimensions, tooltip, ref]);

  return (
    <HStack w="full" h="full" spacing={1}>
      <Center w="65%" h="full">
        <svg ref={ref} />
        {tooltip.show && (
          <div
            className="absolute bg-white border border-gray-300 rounded px-2 py-1 text-sm shadow-md"
            style={{
              left: `${tooltip.x}px`,
              top: `${tooltip.y}px`,
              transform: 'translate(-50%, -100%)',
            }}
          >
            {tooltip.content}
          </div>
        )}
      </Center>
      <Box overflow="scroll" w="35%" h="90%">
        <Stack w="full">
          {map(data, (o, index: number) => renderLegend(o, index))}
        </Stack>
      </Box>
    </HStack>
  );
};
