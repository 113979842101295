import React from 'react';

import {
  Box,
  Center,
  Flex,
  HStack,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { filter, map, toUpper } from 'lodash';
import { customTheme } from 'theme';

import { CustomTooltip, formatTooltip, Tag } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { AwsIcon, CloudIcon, GoogleCloudIcon } from 'components/Icons';
import {
  getHighPrivilegeColor,
  highPrivilegesConst,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';

export const JitApprovalStatus = ({ status }: { status: string }) => {
  return (
    <Center
      borderRadius={4}
      color="white"
      w={20}
      bg={
        status === 'DENIED'
          ? 'critical'
          : status === 'APPROVED'
          ? customTheme.colors.green['300']
          : customTheme.colors.gray['250']
      }
    >
      {status?.toUpperCase()}
    </Center>
  );
};

export const getCloudUser = (request, requestAccount) => {
  switch (request.cloud_type) {
    case 'AWS':
      return requestAccount.aws_username;
    case 'GCP':
      return `user:${request.requester_slack_email_id}`;
    default:
      return '-';
  }
};

export const renderJitPermissions = crud_counts => (
  <Wrap>
    {filter(highPrivilegesConst, o => !!crud_counts?.[o]).map((o, index) => {
      return (
        <WrapItem key={index + '-jit-privileges'}>
          <Flex
            px={2}
            bg={getHighPrivilegeColor(o?.toLowerCase())}
            color="white"
            borderRadius={3}
          >
            {o} - {formatNumber(crud_counts?.[o] ?? 0)}
          </Flex>
        </WrapItem>
      );
    })}
  </Wrap>
);

export const renderJitResourceTypes = resources => (
  <HStack spacing={1}>
    {map(resources, (r, index) => (
      <Box key={index + '-jit-resource-type'}>
        {r.resource_type?.toUpperCase()}
        {index + 1 < resources?.length && ','}
      </Box>
    ))}
  </HStack>
);

export const renderCloudIcon = (cloudType: string) => {
  switch (cloudType) {
    case 'aws':
      return <AwsIcon />;
    case 'gcp':
      return <GoogleCloudIcon />;
    default:
      return <CloudIcon />;
  }
};

export const renderJitPermissionsTable = ({ resources, height = '200px' }) => (
  <Box h={height} overflow="scroll">
    <Table
      data={resources}
      columns={[
        {
          header: 'Type',
          accessor: 'resource_type',
          align: 'left',
          render: ({ value }) => toUpper(value),
        },
        {
          header: 'Resource',
          accessor: 'resource_arn',
          align: 'left',
        },
        {
          header: 'Actions',
          accessor: 'iam_actions',
          align: 'left',
          render: ({ value }) => (
            <CustomTooltip
              label={formatTooltip({
                header: 'Actions',
                content: (
                  <Stack>
                    {map(value, (o, index) => (
                      <Box>
                        {index + 1}. {o}
                      </Box>
                    ))}
                  </Stack>
                ),
                width: 72,
              })}
            >
              <HStack spacing={0}>
                {value?.[0] && <Box>{value?.[0]}</Box>}
                {value?.length > 1 && (
                  <>
                    <Box>,</Box>
                    <Tag
                      label={'+' + (value?.length - 1)}
                      styles={{
                        tag: { bg: 'primary' },
                        label: { color: 'white' },
                      }}
                    />
                  </>
                )}
              </HStack>
            </CustomTooltip>
          ),
        },
      ]}
    />
  </Box>
);
