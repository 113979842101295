import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { errorToast } from 'components/Toast';

export type MapFn<T = any> = (a) => T;

export interface CallApiOptions<A> {
  url?: string;
  fn: (...args) => Promise<A>;
  // response mapper
  mapResponse?: MapFn;
  // error mapper
  mapError?: MapFn<A>;
  // this title will show up in the errorToast
  errorTitle?: string;
  // response error message mapper
  mapErrorMsg?: (error: any) => string;
  onSuccess?: ActionCreatorWithPayload<any, string>;
  onError?: ActionCreatorWithPayload<any, string>;
}

const noopMapFn = a => a;

// handles proto generated api client functions
export function* serviceClient<A>(opts: CallApiOptions<A>) {
  const {
    fn,
    mapResponse = noopMapFn,
    mapError = noopMapFn,
    errorTitle = '',
  } = opts;

  const handleError = function* (error) {
    if (opts.onError) {
      console.log(error);

      errorToast({
        title: errorTitle,
        // without to doing string creates error
        description: error.toString(),
      });

      yield put(opts.onError(mapError(error)));
    }
  };

  try {
    const response = yield call(fn);
    if (response.error) {
      if (opts.onError) {
        yield call(handleError, response.message);
      }
      return;
    }
    if (opts.onSuccess) {
      yield put(opts.onSuccess(mapResponse(response)));
    }
  } catch (err) {
    yield call(handleError, err);
  }
}
