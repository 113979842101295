import { TextStyle } from 'pixi.js';

export const SQUARE_NODE_SIZE = 44;
export const RECT_NODE_WIDTH = 200;
export const LIMIT_INNER_NAME_LEN = 18;
export const LIMIT_INNER_INFO_LEN = 18;
export const LIMIT_OUTER_INFO_LEN = 17;
export const UNLIMITED_OUTER_INFO_LEN = 100;

export const SEVERITY_COLORS = {
  CRITICAL: 0xe53e3e,
  HIGH: 0xdd6b20,
  MEDIUM: 0xed8936,
  LOW: 0xecc94b,
};

export const colors = {
  primary: 0x7030a0,
  border: 0xaaaaaa,
};

export const headerTextStyle = new TextStyle({
  fontFamily: 'Rubik',
  fontSize: 13,
  fontWeight: 'normal',
  fill: colors.primary,
});

export const nameTextStyle = new TextStyle({
  fontFamily: 'Rubik',
  fontSize: 14,
  fontWeight: 'bold',
});

export const infoTextStyle = new TextStyle({
  fontFamily: 'Rubik',
  fontSize: 14,
  fontWeight: '500',
});
