/**
 *
 * Add Ariksa Outpost for Data
 *
 */

import React, { FC, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Outpost } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { QuestionCircleIcon } from 'components/Icons';
import { CustomIconTypes } from 'components/Icons/types';
import {
  SideNav,
  SideNavMenuItemProps,
  AwsIcon,
  PageHeaderWithIcon,
} from 'components/index';
import { AboutAriksaOutpostForData } from 'containers/Setup/Outpost/Components/OutpostWizard/AriksaOutpostForData/AboutAriksaOutpostForData';
import { actions } from 'containers/Setup/Outpost/slice';
import { OutpostProps } from 'containers/Setup/Outpost/types';

import { AddAriksaOutpostForDataForm } from './AddAriksaOutpostForDataForm';

export const AriksaOutpostForData: FC<OutpostProps> = props => {
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getOutpostConfig({ q: { outpost: Outpost.Data } }));
  }, [dispatch]);

  const menuItems: SideNavMenuItemProps[] = [
    {
      title: 'AWS',
      key: CloudProviders.Aws,
      component: <AddAriksaOutpostForDataForm {...props} />,
      icon: <AwsIcon />,
      contentHeader: (
        <PageHeaderWithIcon
          label={
            <HStack>
              <Box>Add Ariksa Outpost for Data</Box>
              <Box
                onClick={() => setShowHelp(!showHelp)}
                color="primary"
                cursor="pointer"
              >
                <QuestionCircleIcon />
              </Box>
            </HStack>
          }
          reversed
          iconType={CustomIconTypes.DataScan}
        />
      ),
      helpContent: (
        <AboutAriksaOutpostForData onClose={() => setShowHelp(false)} />
      ),
      showHelpContent: showHelp,
    },
  ];

  return (
    <SideNav
      menuItems={menuItems}
      styles={{ componentWrapper: { pos: 'relative' } }}
    />
  );
};
