import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CreatedBy,
  DeleteDate,
  LastAccessedDate,
  LastChangeDate,
  LastRotationDate,
  RotationEnabled,
  RotationFunction,
  RotationPeriod,
  RotationRules,
  EncryptionKey,
  VersionIDCurrent,
  VersionIDPending,
  VersionIDPrevious,
} from '../../../Components/MetadataField/MetaGridFields';

export const SecretMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const columns = [
    { header: 'Region', accessor: 'Region' },
    { header: 'Kms Key Id', accessor: 'KmsKeyId', align: 'left' },
    { header: 'Status', accessor: 'Status', align: 'left' },
    { header: 'Status Message', accessor: 'StatusMessage', align: 'left' },
  ];

  const replicationStatus = resource?.config?.ReplicationStatus ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {CreatedBy(insight)}
      {EncryptionKey(resource)}
      {RotationEnabled(resource)}
      {RotationFunction(resource)}
      {LastRotationDate(resource)}
      {RotationRules(resource)}
      {RotationPeriod(resource)}
      {LastChangeDate(resource)}
      {LastAccessedDate(resource)}
      {DeleteDate(resource)}
      {VersionIDCurrent(resource)}
      {VersionIDPrevious(resource)}
      {VersionIDPending(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={replicationStatus} />}
            iconType={''}
            label="ReplicationStatus"
            totalCount={replicationStatus?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
