import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AttachedPolicies,
  SNSEncryptionAtRest,
  MessagesEncrypted,
  SNSFIFO,
  SubscriptionsConfirmed,
  SubscriptionsDeleted,
  SubscriptionsPending,
  EncryptionInTransit,
  ContentBasedDedupe,
  KMSKeyID,
  Name,
  AccountID,
  Region,
  CloudID,
  ARN,
  State,
  CreatedAt,
  Owner,
} from '../../../Components/MetadataField/MetaGridFields';
import { PolicyDocument } from '../../../Components/MetadataField/PolicyDocument';

export const SNSTopicMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const subscriptions = resource?.config?.Subscriptions;

  const columns = [
    { header: 'ARN', accessor: 'SubscriptionArn' },
    { header: 'Owner', accessor: 'Owner', align: 'left' },
    { header: 'Endpoint', accessor: 'Endpoint', align: 'left' },
    { header: 'Protocol', accessor: 'Protocol', align: 'left' },
  ];

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      {Name(insight)}
      {AccountID(insight)}
      {Region(insight)}
      {CloudID(insight)}
      {ARN(insight)}
      {Tags(resource)}
      {State(resource)}
      {CreatedAt(resource, 'dayjs')}
      {Owner(insight)}

      {SNSFIFO(resource)}
      {SubscriptionsConfirmed(resource)}
      {SubscriptionsPending(resource)}
      {SubscriptionsDeleted(resource)}
      {SNSEncryptionAtRest(insight)}
      {EncryptionInTransit(resource)}
      {ContentBasedDedupe(resource)}
      {KMSKeyID(resource)}
      {MessagesEncrypted(resource)}
      {AttachedPolicies(resource)}
      <PolicyDocument
        label="Access policy"
        value={resource?.config?.Policy}
        isString
      />
      <PolicyDocument
        label="Delivery policy"
        value={resource?.config?.EffectiveDeliveryPolicy}
        isString
      />
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={subscriptions} />}
            iconType={IconTypes.SNSSubscription}
            label="SNS Subscriptions"
            totalCount={subscriptions?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
