import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { availableWidgets1 } from './widget';

export const dashboardWidgets1: IDashboardLayout = {
  id: 'dashboard_1',
  title: 'Dashboard Title',
  widgets: [
    {
      type: 'funnel-chart',
      i: 'vulnerabilitiesPrioritization',
      x: 0,
      y: 0,
      w: 6,
      h: 8,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'table',
      i: 'patchablePackages',
      x: 6,
      y: 0,
      w: 6,
      h: 8,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'vulnerabilitiesBySeverity',
      x: 0,
      y: 8,
      w: 4,
      h: 8,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'vulnerabilitiesBySources',
      x: 4,
      y: 8,
      w: 4,
      h: 8,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'sunburst-chart',
      i: 'mostDeployedVulnerableImages',
      x: 8,
      y: 8,
      w: 4,
      h: 8,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'line-chart',
      i: 'criticalAndHighVulnerabilities',
      x: 0,
      y: 16,
      w: 4,
      h: 8,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'hostsWithCriticalOrHighVulnerabilities',
      x: 4,
      y: 16,
      w: 4,
      h: 8,
      isResizable: false,
      static: true,
    },
    {
      type: 'line-chart',
      i: 'hostsWithUnpatchedOSAndTechnology',
      x: 8,
      y: 16,
      w: 4,
      h: 8,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
  ],
  available: availableWidgets1,
};
