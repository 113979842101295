import { camelCase, each, keys, merge, snakeCase } from 'lodash';
import { Dict } from 'types/utils';

export function withPrefixArgs(prefix: string) {
  return function <T = any>(
    path: string,
    suffix?: string,
  ): (args?: T) => string {
    const url = cleanUrl(prefix) + path;
    return buildPath<T>(url, suffix);
  };
}

export function withPrefixNoArgs(prefix: string, suffix?: string) {
  return function <T = any>(path: string, suffix?: string): () => string {
    const url = cleanUrl(prefix) + path;
    return buildPath<T>(url, suffix);
  };
}

export function buildPath<T>(url: string, suffix: string = '') {
  url = cleanUrl(url);
  return (object?: T) => {
    if (!object) {
      return url + suffix;
    } else {
      const pathVars = getUrlPathKeys(url);

      if (keys(pathVars).length) {
        const [pathParams, queryParams] = splitData(object, pathVars);
        const queryString = queryStringFromObject(queryParams);
        const newUrl = updateUrlWithPathParams(url, pathParams); //`${cleanUrl(url)}${pathValue}`;
        return newUrl + queryString;
      } else {
        const queryString = queryStringFromObject(object);
        const newUrl = `${cleanUrl(url)}`;
        return newUrl + queryString;
      }
    }
  };
}

function updateUrlWithPathParams(url, pathParams) {
  each(pathParams, (v, k) => {
    url = url.replaceAll(`{${k}}`, v);
  });
  return url;
}

function splitData(object, pathVars) {
  const pathParams = {};
  const queryParams = {};
  each(object, (v, k) => {
    if (pathVars[snakeCase(k)] !== undefined) {
      pathParams[snakeCase(k)] = v;
    } else if (pathVars[camelCase(k)] !== undefined) {
      pathParams[camelCase(k)] = v;
    } else if (pathVars[k] !== undefined) {
      pathParams[k] = v;
    } else {
      queryParams[k] = v;
    }
  });
  return [pathParams, queryParams];
}

function getUrlPathKeys(url: string) {
  const REG = /\{([^}])+\}/g;
  const matches = url.match(REG);
  if (matches) {
    return matches.reduce((o, m) => {
      m = m.replace('}', '');
      m = m.replace('{', '');
      return { [m]: 1, ...o };
    }, {});
  }
  return {};
}

export interface QueryStringFromObjectOpts {
  useSnakeCase?: boolean;
  filterEmpty?: boolean;
}
// convert query params object to string
export function queryStringFromObject(
  object,
  options?: QueryStringFromObjectOpts,
) {
  const { useSnakeCase, filterEmpty } = merge(
    { useSnakeCase: true, filterEmpty: true },
    options,
  );
  // if (keys(object).length === 0) return '';

  const q = Object.entries(object)
    .filter(el => (filterEmpty ? el[1] !== '' : true))
    .map(el => [useSnakeCase ? snakeCase(el[0]) : el[0], el[1]])
    .map(el => el.join('='))
    .join('&');

  return '?' + q;
}

function cleanUrl(url) {
  return url;
  // if (url.slice(-1)[0] === '/') {
  //   return url.slice(0, -1);
  // } else {
  //   return url;
  // }
}

export function intoSnakeCaseObject(
  obj: Record<string, any>,
): Record<string, any> {
  const ret = {};
  for (const key in obj) {
    ret[snakeCase(key)] = obj[key];
  }

  return ret;
}
