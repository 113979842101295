import { useCallback, useEffect, useState } from 'react';

import { isEmpty, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isDeepEqual } from 'react-use/lib/util';
import { Optional } from 'types/utils';

import { SelectOption } from 'components/DataEntry';
import { getIcon } from 'components/Icons/Components';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { SourceNodeOptions } from 'containers/Visibility/SecurityGraphNext/types';

export const useSourceNodes = () => {
  const dispatch = useDispatch();
  const {
    compactMap,
    sourceNodeOptions,
    selectedSourceNodeOption,
    activeSearchQueryTerm,
    prevActiveSearchQueryTerm,
  } = useSelector(selectSecurityGraphNext);
  const { searchResult } = useSearchResult();
  const [sourceNodeMap, setSourceNodeMap] = useState(new Map<string, any>());

  useEffect(() => {
    const nodeMap = new Map<string, Record<string, any>>();
    const newSourceNodeOptions: SourceNodeOptions =
      searchResult.data.source_items?.map(group => {
        group.items?.forEach(n => {
          nodeMap.set(n.uuid, n);
        });
        return {
          label: group.display_name,
          value: {
            display_name: group.display_name,
            native_name: group.native_type,
          },
          options: group.items?.map(n => ({
            label: n.name || n.resource_id,
            value: n,
            icon: getIcon(group.native_type),
          })),
        };
      }) ?? [];

    if (
      isDeepEqual(newSourceNodeOptions, sourceNodeOptions) &&
      isDeepEqual(activeSearchQueryTerm, prevActiveSearchQueryTerm)
    ) {
      return;
    }

    // if the search query term is not changed keep using the previous source node options
    if (
      activeSearchQueryTerm?.query === prevActiveSearchQueryTerm?.query ||
      prevActiveSearchQueryTerm?.uuid === activeSearchQueryTerm?.uuid
    ) {
      if (newSourceNodeOptions.length <= sourceNodeOptions.length) return;
    }

    console.log(activeSearchQueryTerm, prevActiveSearchQueryTerm);

    if (
      searchResult.isSuccess &&
      !isEmpty(searchResult.data.source_items) &&
      !isDeepEqual(newSourceNodeOptions, sourceNodeOptions)
    ) {
      setSourceNodeMap(nodeMap);
      dispatch(actions.updateSourceNodeOptions(newSourceNodeOptions ?? []));
    }
  }, [
    dispatch,
    compactMap,
    searchResult,
    sourceNodeOptions,
    activeSearchQueryTerm,
    prevActiveSearchQueryTerm,
  ]);

  const updateSelectedSourceNodeOption = useCallback(
    (option: Optional<SelectOption>) => {
      dispatch(actions.updateSelectedSourceNodeOption(option));
    },
    [dispatch],
  );

  return {
    selectedSourceNodeOption,
    updateSelectedSourceNodeOption,
    sourceNodeOptions,
  };
};
