import { INIT_PAGE_INFO } from 'services/utils/constants';

import {
  ApiCallState,
  ApiCallStateWithPagination,
  ApiCallStatus,
} from './types';

export const apiCallStatusInitialValue: ApiCallStatus = {
  loading: false,
  error: null,
  loaded: false,
};

export function apiCallStateInitialState<T>(v: T): ApiCallState<T> {
  return {
    data: v,
    loading: false,
    loaded: false,
    error: null,
  };
}

export function withPaginationInitialState<T>(
  v: T,
): ApiCallStateWithPagination<T> {
  return {
    data: v,
    pageInfo: INIT_PAGE_INFO,
    total: 0,
    loading: false,
    loaded: false,
    error: null,
  };
}
