import React, { FC, useEffect, useState } from 'react';

import { NetworkTrafficProcessInfo } from '@ariksa/ebpf-data-collector';
import { Box, Divider, HStack, Stack } from '@chakra-ui/react';
import { map, slice, sum } from 'lodash';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { limitedString } from 'utils/string';

interface IEc2TrafficSummaryModal {
  resourceId: string;
  accountUuid: string;
}

export const TrafficOverview: FC<IEc2TrafficSummaryModal> = props => {
  const { resourceId, accountUuid } = props;
  const { vmTrafficSummary, trafficByPorts, trafficByProcess } = useSelector(
    selectSecurityGraphNext,
  );
  const { data } = vmTrafficSummary;
  const dispatch = useDispatch();
  const { accountMapping } = useCloudAccountId();
  const [ports, setPorts] = useState<NetworkTrafficProcessInfo[]>([]);
  const [processes, setProcesses] = useState<NetworkTrafficProcessInfo[]>([]);

  useEffect(() => {
    const accountId = accountMapping[accountUuid]?.cloud_account_id;
    if (!!accountId) {
      dispatch(
        actions.getEc2TrafficSummary({
          q: { instanceId: resourceId, accountId },
        }),
      );
      dispatch(
        actions.getTrafficByPorts({
          q: { instanceId: resourceId, accountId },
        }),
      );
      dispatch(
        actions.getTrafficByProcess({
          q: { instanceId: resourceId, accountId },
        }),
      );
    }
  }, [resourceId, accountUuid, dispatch, accountMapping]);

  useEffect(() => {
    setPorts([
      ...map(slice(trafficByPorts.data, 0, 3), o => ({
        name: o?.port_no + ' (' + o?.protocol + ')',
        host_nw_used: o.host_nw_used,
      })),
      {
        name: 'Others',
        host_nw_used: sum(
          map(
            slice(trafficByPorts.data, 4, trafficByPorts?.data?.length),
            o => o.host_nw_used,
          ),
        ),
      },
    ]);
  }, [trafficByPorts.data]);

  useEffect(() => {
    setProcesses([
      ...slice(trafficByProcess.data, 0, 3),
      {
        name: 'Others',
        host_nw_used: sum(
          map(
            slice(trafficByProcess.data, 4, trafficByProcess?.data?.length),
            o => o.host_nw_used,
          ),
        ),
      },
    ]);
  }, [trafficByProcess.data]);

  const renderHeader = label => (
    <Box fontSize="sm" color="orange" alignItems="flex-start">
      {label}
    </Box>
  );

  const renderBar = (percent = 0) => (
    <HStack w={40} spacing={0}>
      <Box w={12}>{round(percent, 0)}%</Box>
      <Box flex={1}>
        <Box h={1.5} borderRadius="full" bg="#fff" w={percent + '%'}></Box>
      </Box>
    </HStack>
  );

  const renderFieldValue1 = (field, value = '') => (
    <HStack w="full">
      <Box w="30%">{field}</Box>
      <Box w="70%">{value}</Box>
    </HStack>
  );

  const renderPortsAndProcess = (data, isLoading) => {
    return isLoading ? (
      <Box h="110px">
        <CustomSpinner />
      </Box>
    ) : (
      <HStack w="full">
        <Stack>
          {map(data, o => (
            <Box minW={24}>{limitedString(o?.name, 14)}</Box>
          ))}
        </Stack>
        <Divider orientation="vertical" h={'110px'} borderColor="#fff" />
        <Stack>{map(data, o => renderBar(o.host_nw_used))}</Stack>
      </HStack>
    );
  };

  return (
    /*<Modal
      isOpen={true}
      onClose={() => {}}
      size="2xl"
      body={*/
    <Stack p={3} h="full">
      {renderHeader('TRAFFIC OVERVIEW')}
      <HStack w="full">
        <HStack w="full">
          <Stack>
            <Box>Internet ingress</Box>
            <Box>Internet egress</Box>
            <Box>In-cloud ingress</Box>
            <Box>In-cloud egress</Box>
          </Stack>
          <Divider orientation="vertical" h={'110px'} borderColor="#fff" />
          {vmTrafficSummary.isLoading ? (
            <Box flex={1}>
              <CustomSpinner />
            </Box>
          ) : (
            <Stack>
              {renderBar(data?.internet_ingress)}
              {renderBar(data?.internet_egress)}
              {renderBar(data?.internal_ingress)}
              {renderBar(data?.internal_egress)}
            </Stack>
          )}
        </HStack>
        <Stack w="full">
          {renderFieldValue1('Data access')}
          {renderFieldValue1('Data sources')}
          {renderFieldValue1('Encryption')}
          {renderFieldValue1('APIs exposed')}
        </Stack>
      </HStack>
      <Divider w="full" borderColor="#fff" />
      <HStack w="full">
        <Stack w="full">
          <Box w="full">{renderHeader('TRAFFIC BY PORTS')}</Box>
          {renderPortsAndProcess(ports, trafficByPorts.isLoading)}
        </Stack>
        <Stack w="full">
          <Box w="full">{renderHeader('TRAFFIC BY PROCESS')}</Box>
          {renderPortsAndProcess(processes, trafficByProcess.isLoading)}
        </Stack>
      </HStack>
    </Stack>
    /* }
    />*/
  );
};
