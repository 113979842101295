import React, { FC, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
} from '@chakra-ui/react';
import { CountryDropdown } from 'react-country-region-selector';
import { useFormContext } from 'react-hook-form';

// import { Select } from 'app/components/DataEntry/Select';

import { CustomFieldSchema, useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, ReactSelectFieldStyles } from '../types';

import { FormLabel } from './FormLabel';

import './styles.css';

export const CountryPicker: FC<FieldProps<CustomFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    helpIcon,
    onChange,
    defaultValue,
    value,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const { register, watch, setValue, getValues } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<ReactSelectFieldStyles>(
    'reactSelectField',
    styles,
  );

  useEffect(() => {
    register(name); // custom register Antd input
  }, [register, name]);

  useEffect(() => {
    value && setValue(name, value);
  }, [name, value, setValue]);

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
      marginBottom={'1rem'}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Skeleton isLoaded={!isLoading}>
        <CountryDropdown
          name={name}
          id={id}
          // style prop can be passed to this component, but typescript is throwing error
          // @ts-ignore
          style={{ ...fieldStyles }}
          // defaultValue={defaultValue}
          value={getValues(name) || defaultValue}
          onChange={selected => {
            console.log(name, selected);
            setValue(name, selected);
            onChange?.(selected);
          }}
        />
      </Skeleton>

      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
