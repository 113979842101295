import React, { FC, useEffect } from 'react';

import { Accordion, Text, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomIconTypes, IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceActionsPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActionsPatternAccordion';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { formatDate } from 'utils/date';

interface IS3Activity {}

export const S3Activity: FC<IS3Activity> = props => {
  const dispatch = useDispatch();

  const {
    resourceDetails,
    resourceAccountId,
    resourceInsight,
    activity,
  } = useSelector(selectActiveResource);
  const { s3InternetActivity, s3CrossAccount } = activity;

  useEffect(() => {
    const { data: detail } = resourceDetails;
    const { data: insight } = resourceInsight;
    dispatch(
      actions.getS3CrossAccount({
        q: {
          accountId: resourceAccountId,
          bucketArn: insight?.arn ?? insight?.identifier?.CertificateArn ?? '',
          region: detail?.general?.region ?? '',
        },
      }),
    );

    dispatch(
      actions.getS3InternetActivity({
        q: {
          accountId: resourceAccountId,
          bucketArn: insight?.arn ?? insight?.identifier?.CertificateArn ?? '',
          region: detail?.general?.region ?? '',
        },
      }),
    );
  }, [dispatch, resourceAccountId, resourceDetails, resourceInsight]);

  const None = <Text color={customTheme.colors.gray['400']}>None</Text>;

  const suspiciousInternetAccessColumns: TableColumnProps[] = [
    {
      header: 'COUNTRY',
      accessor: 'country',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.ExclamationTriangle}
          bgColor={'critical'}
        >
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'TIMESTAMP',
      accessor: 'timestamp',
      render: ({ value }) => formatDate(value),
      align: 'left',
    },
    {
      header: 'ACTION PERFORMED',
      accessor: '',
      render: () => '~',
      align: 'left',
    },
    {
      header: 'SERVICE',
      accessor: 'service',
      render: ({ value }) => value || None,
      align: 'left',
    },
    {
      header: 'FROM PROVIDER',
      accessor: 'provider',
      render: ({ value }) => value || None,
      align: 'left',
    },
    {
      header: 'REASON',
      accessor: '~',
    },
  ];
  const crossAccountColumns: TableColumnProps[] = [
    {
      header: 'ACCOUNT ID',
      accessor: 'account_id',
      render: ({ value }) => (
        <WithResourceIcon resourceType={IconTypes.Account}>
          {value !== 'anonymous' ? (
            <CloudAccountName accountId={value} />
          ) : (
            <Text>{value}</Text>
          )}
        </WithResourceIcon>
      ),
      align: 'left',
    },
    {
      header: 'TIMESTAMP',
      accessor: 'timestamp',
      render: ({ value }) => formatDate(value),
      align: 'left',
    },
    {
      header: 'ACTION PERFORMED',
      align: 'left',
      render: () => '~',
    },
    {
      header: 'SRC REGION',
      accessor: 'region',
      align: 'left',
    },
    {
      header: 'REASON',
      render: () => '~',
      align: 'left',
    },
  ];

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <ResourceActionsPatternAccordion />
      <MetaAccordionItem
        iconType={IconTypes.Hacker}
        label={'SUSPICIOUS INTERNET ACCESS DURING THE PAST 90 DAYS'}
        onClickDownload={() => {}}
        panel={
          <Table
            columns={suspiciousInternetAccessColumns}
            data={s3InternetActivity.data ?? []}
            isLoading={s3InternetActivity.isLoading}
          />
        }
        isLoading={s3InternetActivity.isLoading}
        totalCount={s3InternetActivity.data?.length ?? 0}
        iconBGColor="critical"
      />

      <MetaAccordionItem
        iconType={CustomIconTypes.Egress}
        label={'CROSS-ACCOUNT ACCESS DURING PAST 90 DAYS'}
        onClickDownload={() => {}}
        panel={
          <Table
            columns={crossAccountColumns}
            data={s3CrossAccount.data ?? []}
            isLoading={s3CrossAccount.isLoading}
          />
        }
        totalCount={s3CrossAccount.data?.length ?? 0}
        isLoading={s3CrossAccount.isLoading}
        iconBGColor="critical"
      />
    </Accordion>
  );
};
