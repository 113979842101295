import G6 from '@antv/g6';
import { sortBy } from 'lodash';

import { HEADER_NODE } from 'components/Visualization/Graphs/nodes/nodes_types';

export const registerLayout = () => {
  G6.registerLayout('AccessMap-layout', {
    getDefaultCfg: function getDefaultCfg() {
      return {
        headerSeparation: 80,
        verticalSeparation: 130,
        horizontalSeparation: 250,
      };
    },
    execute: function () {
      const self = this;
      const {
        headerSeparation,
        horizontalSeparation,
        verticalSeparation,
      } = self.getDefaultCfg();

      const headers = sortBy(
        self.nodes.filter(n => n.type === HEADER_NODE),
        h => h.data.order,
      );
      const nodes = self.nodes.filter(n => n.type !== HEADER_NODE);

      const buckets = {};
      nodes.forEach(n => {
        buckets[n.data.header_key] = buckets[n.data.header_key] ?? new Set();
        buckets[n.data.header_key].add(n);
      });

      let offsetY = 0;

      headers.forEach((n, i) => {
        n.x = i * horizontalSeparation;
        n.y = offsetY;
      });

      offsetY += headerSeparation;

      headers.forEach((h, col) => {
        const header = h.data.key;
        // console.log(header, buckets[header]?.size);
        Array.from(buckets[header] ?? []).forEach((n: any, row) => {
          // console.log(header, row, col);
          n.x =
            col * horizontalSeparation +
            (190 - (n.data.header_key === 'Resources' ? 46 : 170)) / 2 +
            (n.data.is_target ? 60 : 0);
          n.y = offsetY + row * verticalSeparation;
        });
      });
    },
  });
};
