import React, { FC, useCallback, useState } from 'react';

import {
  ComplianceApiGetComplianceSummaryByComplianceRuleRequest,
  Level,
  StandardRuleCompliance,
} from '@ariksa/compliance-policies/api';
import { Box, useDisclosure, UseDisclosureProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { QueryStatus } from 'services/types';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { ComplianceService } from 'api/services';
import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { PoliciesUnderSubControlModal } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/ComplianceBlueprint/PoliciesUnderSubControlModal';

import { selectBlueprints } from '../../../../selectors';
import { actions } from '../../../../slice';

interface Props {
  control: StandardRuleCompliance;
  addPolicy?: UseDisclosureProps;
  selectedPolicies?: string[];
  setSelectedPolicies?(value: string[]);
  action?: 'Create' | 'Show' | 'Update';
}

export const ComplianceSubControls: FC<Props> = props => {
  const {
    control,
    addPolicy,
    selectedPolicies,
    setSelectedPolicies,
    action,
  } = props;
  const dispatch = useDispatch();
  const { complianceSubControls, blueprint } = useSelector(selectBlueprints);
  const [pageInfo, setPageInfo] = useState(INIT_PAGE_INFO);
  const [total, setTotal] = useState(0);
  const [currentRecord, setCurrentRecord] = useState<StandardRuleCompliance>(
    {} as StandardRuleCompliance,
  );
  const policiesDisclosure = useDisclosure();

  //get policies
  const getComplianceSubControls = useCallback(() => {
    const payload: ComplianceApiGetComplianceSummaryByComplianceRuleRequest = {
      blueprintId: blueprint.data.id,
      level: Level.SubControl,
      control: control.control,
      page: pageInfo.page_number,
      size: pageInfo.page_size,
    };

    dispatch(
      actions.getComplianceSubControls({
        q: payload,
        status: QueryStatus.pending,
      }),
    );

    ComplianceService.Compliance.getComplianceSummaryByComplianceRule(
      payload,
    ).then(res => {
      setTotal(res?.data?.total ?? 0);
      dispatch(
        actions.getComplianceSubControls({
          q: payload,
          status: QueryStatus.fulfilled,
          data: res?.data,
        }),
      );
    });
  }, [dispatch, blueprint.data, control, pageInfo]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Sub-Control</Box>,
      accessor: 'sub_control',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={blueprint.data.compliance!}
          iconFilled={false}
          iconSize="regular"
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Description',
      accessor: 'sub_control_description',
      align: 'left',
    },
    {
      header: '# of Policies',
      accessor: 'rules',
      render: ({ value }) => value.total ?? '-',
      align: 'left',
    },
    {
      header: 'Entities',
      accessor: 'check_stats.entities_with_issues',
      align: 'left',
    },
    {
      header: 'Policy Exceptions',
      accessor: 'rules',
      render: ({ value }) => {
        if (value?.total && value?.selected) {
          return value?.total - value?.selected;
        } else {
          return '-';
        }
      },
      align: 'left',
    },
    /*{
      header: 'Actions',
      render: ({ row }) => (
        <Center>
          <ActionButton
            label="Exclude control"
            isDisabled={
              !blueprint.data.created_by ||
              blueprint.data.created_by === 'system'
            }
            icon={<PolicyExceptionIcon />}
          />
        </Center>
      ),
    },*/
  ];

  useDeepCompareEffect(() => {
    getComplianceSubControls();
  }, [getComplianceSubControls]);

  return (
    <Box h="310px" flex={1}>
      <Table
        data={complianceSubControls?.[control.control!]?.data ?? []}
        columns={columns}
        isLoading={complianceSubControls?.[control.control!]?.isLoading}
        cursor="pointer"
        styles={{ header: { zIndex: 800 } }}
        onRowClick={row => {
          setCurrentRecord(row);
          policiesDisclosure.onOpen();
        }}
        pagination={{
          totalCount: total,
          pageInfo: pageInfo,
          onChange: setPageInfo,
        }}
      />
      {policiesDisclosure.isOpen && (
        <PoliciesUnderSubControlModal
          isOpen={policiesDisclosure.isOpen}
          onClose={policiesDisclosure.onClose}
          subControl={currentRecord}
          addPolicy={addPolicy}
          setSelectedPolicies={setSelectedPolicies}
          selectedPolicies={selectedPolicies}
          action={action}
        />
      )}
    </Box>
  );
};
