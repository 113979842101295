import { useMemo } from 'react';

import { SecurityGraphTable } from 'containers/Visibility/SecurityGraphNext/Components/SecurityGraphTable';

export const useTableComponents = () => {
  const tableComponent = useMemo(() => {
    return <SecurityGraphTable />;
  }, []);

  return {
    tableComponent,
  };
};
