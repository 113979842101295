import {
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
} from '@ariksa/inventory-core/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertPaginatedResponse,
  AlertsApiGetAlertByIdRequest,
  AlertsApiUpdateAlertRequest,
  ClientsApiNotifyClientRequest,
} from '@ariksa/notification/api';
import { ClientsApiUpdateTicketRequest } from '@ariksa/notification/api';
import { identity } from 'lodash';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { IGetAlertByUserUrl } from 'api/alerts/types';
import { Api } from 'api/index';
import { InventoryService, NotificationService } from 'api/services';
import request from 'utils/request';

import { actions } from './slice';

export function* alertDrawerSaga() {
  yield takeLatestAction(actions.getResourceInsight, doGetResourceInsight);
  yield takeLatestAction(actions.createTicket, doCreateTicket);
  yield takeLatestAction(actions.createNotification, doCreateNotification);
  yield takeLatestAction(actions.updateDueDate, doUpdateDueDate);
  yield takeLatestAction(actions.getAlertById, doGetAlertById);
  yield takeLatestAction(actions.updateAlertStatus, doUpdateAlertStatus);
  yield takeLatestAction(actions.getAlertListStatus, doGetalertListStatus);
  yield takeLatestAction(actions.getAlertStatus, doGetAlertStatus);
  yield takeLatestAction(actions.getAlertsByRuleId, doGetAlertsByRuleId);
}

function* doGetResourceInsight(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    { errorMsg: 'Failed to get resource insights!' },
  );
}

function* doCreateTicket(
  ctx: QueryContext<any, ClientsApiNotifyClientRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.notifyClient(ctx.params),
    { errorMsg: 'Failed to create ticket.' },
  );
}

function* doCreateNotification(
  ctx: QueryContext<any, ClientsApiNotifyClientRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.notifyClient(ctx.params),
    { errorMsg: 'Failed to create notification.' },
  );
}

export function* doUpdateDueDate(
  ctx: QueryContext<any, AlertsApiUpdateAlertRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.updateAlert(ctx.params),
    { errorMsg: 'Failed to update notification.' },
  );
}

function* doGetAlertById(ctx: QueryContext<any, AlertsApiGetAlertByIdRequest>) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertById(ctx.params),
    { errorMsg: 'Failed to update notification.' },
  );
}

export function* doUpdateAlertStatus(
  ctx: QueryContext<any, ClientsApiUpdateTicketRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Clients.updateTicket(ctx.params),
    { errorMsg: 'Failed to update alert status!' },
  );
}

function* doGetalertListStatus(
  ctx: QueryContext<AlertLogsResponse[], AlertLogsApiGetLogsForIdsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getLogsForIds(ctx.params),
    { errorMsg: 'Failed to get alert status!' },
  );
}

export function* doGetAlertStatus(
  ctx: QueryContext<AlertLogsResponse[], AlertLogsApiGetLogsForIdsRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.AlertsLogs.getLogsForIds(ctx.params),
    { errorMsg: 'Failed to get alert status!' },
  );
}

function* doGetAlertsByRuleId(
  ctx: QueryContext<AlertPaginatedResponse, IGetAlertByUserUrl>,
) {
  yield call(
    ctx.fetch,
    () => request(Api.getAlertsByUserId({ ...ctx.params, ...ctx.page })),
    {
      map: identity,
      cacheKey: 'getAlertsByRuleId',
    },
  );
}
