import React from 'react';

import { Icon } from '@chakra-ui/react';

export const MysqlIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        fill="#00758F"
        d="M103.3,92.2c0,0,15.1,13,19.8,31.6c0,0,15.6-11,5-26.7C120.1,85.4,103.3,92.2,103.3,92.2z"
      />
      <path
        fill="#00758F"
        d="M459.9,448.5c-26-16.6-31.5-17.2-40.6-31.6c0,0,56.2-14.1,79-15.5c0,0-34-52.3-122.6-88.3
		c-3-1.2-6.4-4.9-9.7-10c-21.8-37.6-36.7-80.4-56-119.2c-47-72.4-112.8-143-205-145.5C39.9-12.1-42.5-5.6,25.5,78.7
		c5.9,8,13.5,17,17.7,26c9.8,35.2,22.7,69.7,45.1,98.3c-18.8,43.1-20.9,106.9,2.1,142.7c5.6,8.7,18.8,27.8,36.5,20.5
		c18.1-7,11.7-36,19.1-53.1c0,0.3,0,0.7,0,0.7c6.2,15.4,14.9,30.1,25.5,43.2l0,0c23.9,32.2,80.2,69.7,59.2,51.9
		c-20.8-17.7-44.8-53.7-50.3-62.8c-13.8-24-25.2-49.6-35.9-74c-19.4,19.3-23.4,42.9-25.9,70.6c-0.2,1.8,0.6,6.9-1.8,6.1l0,0
		c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l0,0c-31.8-19-31-81.9-21.5-116.2c2.4-7.3,12.9-30.2,8.7-37.2
		C76,166.3,70.7,132.4,56.4,96.7c-5.1-15.1-26.5-33.9-35-50.9l0,0c-4.7-7.9-7.8-27.4,10.9-22.7l0,0c3.2,0.5,6.6,1.6,9.3,2.6
		c15.9,5.2,32.9,14,54.4,30.7c89.1,0.3,158.8,72.6,199.7,146.5c10,25,22,50.4,33.3,74.7c3.8,8.2,6.8,15.4,9.7,21.8l0,0
		c0,0.1,0.1,0.1,0.1,0.2c1.1,2.5,2.3,4.8,3.4,7l0,0c5.2,10.5,13.7,23.1,39.4,30.6c32.1,9.3,63.6,26.8,90.1,54.9
		c0,0-61.9-0.5-81.6,18.4c0,0,8.5,28.4,49.2,49.7s53.6,28.4,72.7,45.4C512,505.8,495.2,470.9,459.9,448.5z"
      />
    </g>
  </Icon>
);
