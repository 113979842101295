import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountID,
  CloudID,
  CreatedAt,
  Owner,
  Region,
  State,
} from '../../../Components/MetadataField/MetaGridFields';

export const AccessKeyMetadata: FC = () => {
  const { resourceInsight, resourceDetails } = useActiveResourceContext();
  const { data: insight } = resourceInsight;
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {CloudID(insight, 1)}
        {AccountID(insight)}
        {Region(insight)}
        {State(resource)}
        {CreatedAt(resource, 'dayjs')}
        {Owner(insight)}
      </Grid>
    </Stack>
  );
};
