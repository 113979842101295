import React, { FC, useEffect, useMemo, useState } from 'react';

import { EntityType } from '@ariksa/inventory-core/api';
import {
  Box,
  Divider,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { map, range, sum } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { AriksaIcon } from 'components/Icons/Components';
import { BarChart } from 'components/Visualization';
import { CustomTooltip } from 'components/Visualization/Charts/CustomTooltip';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import {
  selectOnboardedCloudAccounts,
  selectUser,
} from 'containers/App/selectors';

interface IAccessGraph {
  resourceType: EntityType;
}

export const ResourceAccessPattern: FC<IAccessGraph> = props => {
  const dispatch = useDispatch();
  const { resourceType } = props;
  const {
    resourceAccountId,
    resourceDetails,
    accessed,
    actionsOnDay,
  } = useSelector(selectActiveResource);
  const { info } = useSelector(selectUser);

  const [data, setData] = useState<any[]>([]);
  const [average, setAverage] = useState(1000);

  const [selectedDay, setSelectedDay] = useState<any>(null);
  const [dataOnDay, setDataOnDay] = useState<any[]>([]);

  useEffect(() => {
    if (!selectedDay) return;
    dispatch(
      actions.getActionsPerformedOnDay({
        q: {
          orgId: info.org_uuid,
          day: dayjs.utc(selectedDay).toISOString(),
          identityArn: resourceDetails.data?.identifier?.Arn,
        },
      }),
    );
  }, [dispatch, info.org_uuid, resourceDetails.data, selectedDay]);

  useEffect(() => {
    console.log('resourceDetails', resourceDetails);
    if (!resourceDetails.data?.identifier?.Arn) return;
    if (!info.org_uuid) return;

    dispatch(
      actions.getAccessed({
        q: {
          identityArn: resourceDetails.data?.identifier?.Arn ?? '',
          orgId: info.org_uuid,
        },
      }),
    );
  }, [dispatch, info.org_uuid, resourceAccountId, resourceDetails]);

  useEffect(() => {
    const timeline: any[] = [];
    accessed.data?.actions?.forEach((v, k) => {
      if (!v.actions || !v.day) return;
      timeline.push({
        count: v.actions,
        date: dayjs(v.day.toString()).format('MM/DD'),
        fullDate: dayjs(v.day.toString()).format('YYYY/MM/DD'),
      });
    });

    // findPath latest actions
    const lastActiveDay = timeline.reverse().find(e => e.count > 0);
    setSelectedDay(lastActiveDay?.fullDate ?? null);
    setData(timeline);
  }, [accessed.data]);

  const customTooltipContent = props => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const { name, payload: obj } = payload[0] ?? {};

      return (
        <>
          <CustomTooltip w={'150px'}>
            <Stack color={obj?.fill} fontSize={'xs'}>
              {obj.count > average && (
                <>
                  <Text>Spike in activity</Text>
                </>
              )}
              <Text>{obj.count} permissions</Text>
            </Stack>
          </CustomTooltip>
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!selectedDay) return;
    const actions =
      actionsOnDay.data?.actions?.map((v, k) => {
        return {
          resource: v.resource,
          action: v.action,
          count: v.count,
        };
      }) ?? [];
    setDataOnDay(actions);
  }, [accessed.data, actionsOnDay.data, selectedDay]);

  const columns = [
    {
      header: 'resource type',
      accessor: 'resource',
      render: ({ value }) => {
        return value;
      },
    },
    {
      header: 'permission',
      accessor: 'action',
      align: 'left',
    },
    {
      header: 'count',
      accessor: 'count',
    },
  ];

  return (
    <Stack h={'full'} spacing={8}>
      <Box minH={200}>
        <BarChart
          data={data}
          xField="date"
          yField={['count', 'over']}
          stacked
          legend={false}
          colors={data.map(d =>
            d.count > average
              ? customTheme.colors.red['500']
              : customTheme.colors.primary,
          )}
          customTooltipContent={customTooltipContent}
          isLoading={accessed.isLoading}
          onClick={(e, k, v) => {
            setSelectedDay(v.fullDate ?? null);
          }}
        />
      </Box>

      <Stack flex={1} spacing={10}>
        {selectedDay && (
          <Stack>
            <HStack>
              <AriksaIcon
                type={IconTypes.ExclamationTriangle}
                size={'sm'}
                bg={'critical'}
              />
              <Text>ANOMALIES DETECTED ON {selectedDay}</Text>
            </HStack>
            <Divider />
            {/*<Stack pl={10}>*/}
            {/*  <UnorderedList spacing={2}>*/}
            {/*    <ListItem>*/}
            {/*      Number of user actions were significantly higher than the*/}
            {/*      rolling average over the last 30 days*/}
            {/*    </ListItem>*/}
            {/*  </UnorderedList>*/}
            {/*</Stack>*/}
          </Stack>
        )}
        <Stack flex={1} spacing={4}>
          <HStack>
            <AriksaIcon
              type={IconTypes.Permission}
              size={'sm'}
              bg={'primary'}
            />
            <Text>ACTIONS PERFORMED ON {selectedDay}</Text>
          </HStack>
          <Divider />
          <Table columns={columns} data={dataOnDay} />
        </Stack>
      </Stack>
    </Stack>
  );
};
