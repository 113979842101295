import dayjs, { OpUnitType } from 'dayjs';

export const pauseDiscoveryTimeIntervalOptions = [
  { label: '1 hour', value: '1H' },
  { label: '6 hours', value: '6H' },
  { label: '12 hours', value: '12H' },
  { label: '24 hours', value: '24H' },
  { label: '2 days', value: '2D' },
  { label: '1 week', value: '1W' },
  { label: 'Indefinitely', value: '' },
];

export const getPausedUntilTime = time => {
  if (!time) return 'None';

  let pauseUntilNumber = parseInt(time);
  let pauseUntilUnit: OpUnitType = 'hour';
  switch (time) {
    case '2D':
      pauseUntilUnit = 'days';
      break;
    case '1W':
      pauseUntilUnit = 'week';
      break;
  }

  return dayjs().add(pauseUntilNumber, pauseUntilUnit);
};

export const getRestartTime = time => {
  const t = getPausedUntilTime(time);
  if (t === 'None') return t;
  return dayjs(t).format('D-MMM-YYYY, h:mm A');
};
