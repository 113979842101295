import React, { useEffect, useState } from 'react';

import { InternalContext, SummaryType } from '@ariksa/inventory-core/api';
import { ProviderType } from '@ariksa/reporting';
import { ResourceSubCategory } from '@ariksa/reporting/api';
import { Center } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp } from 'containers/App/selectors';
import { IdentityAuthorization } from 'containers/Dashboard/IdentityOverview/Components';
import { NoAccountsOnboarded } from 'containers/Dashboard/Overview/Components/NoAccountsOnboarded';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

import { identityOverviewSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const IdentityOverview: React.FC = () => {
  useInjector(sliceKey, reducer, identityOverviewSaga);
  const dispatch = useDispatch();
  const { cloudAccounts, environments, user } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const { contentHeight } = usePageContentContext();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;
    const startDate_7 = aiParamExtraction.data?.start_date
      ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
      : dayjs().subtract(7, 'day').format('YYYY-MM-DD');

    const payloadWithDatesAccountIds = {
      environmentId: environmentId as string,
      accountIds,
      startDate,
      endDate,
    };
    const payloadWithDatesAccountId = {
      environmentId: environmentId as string,
      accountId: accountIds,
      startDate,
      endDate,
    };

    /*------------------------Identity Authorization-------------------------*/
    dispatch(
      actions.getIdentityAuthorizationFindingsCategorySummary({
        q: {
          environmentId: environmentId as string,
          accountId,
          categoryClass: AlertCategoryClass.IdentityAuthorization,
        },
      }),
    );
    dispatch(actions.getJitSummary({}));
    dispatch(
      actions.getIdentityAuthorizationOpenAlerts({
        q: {
          environmentId: environmentId as string,
          accountId,
          categoryClass: AlertCategoryClass.IdentityAuthorization,
        },
      }),
    );
    dispatch(
      actions.getIdentitiesHistory({
        q: payloadWithDatesAccountId,
      }),
    );
    dispatch(
      actions.getIdentitiesWithAccessToSensitiveData({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          context: InternalContext.SensitiveData,
        },
      }),
    );
    dispatch(
      actions.getIdentitiesWithExcessPermissions({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          context: InternalContext.ExcessPrivileges,
        },
      }),
    );
    dispatch(
      actions.getIdentitiesWithHighPrivileges({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          context: InternalContext.HighPrivileges,
        },
      }),
    );
    dispatch(
      actions.getIdentityAuthorizationResolvedAlerts({
        q: {
          ...payloadWithDatesAccountIds,
          categoryClass: AlertCategoryClass.IdentityAuthorization,
        },
      }),
    );
    dispatch(
      actions.getInsightsForNonHumanIdentities({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );

    //change
    dispatch(
      actions.getNonUsersChange({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
          resourceSubCategory: ResourceSubCategory.NonUsers,
        },
      }),
    );
    dispatch(
      actions.getFederatedUsersChange({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
          resourceSubCategory: ResourceSubCategory.Users,
          providerType: ProviderType.IdentityProvider,
        },
      }),
    );
    dispatch(
      actions.getCloudUsersChange({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
          resourceSubCategory: ResourceSubCategory.Users,
          providerType: ProviderType.Cloud,
        },
      }),
    );
    dispatch(
      actions.getSaaSUsersChange({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
          resourceSubCategory: ResourceSubCategory.Users,
          providerType: ProviderType.SaaS,
        },
      }),
    );
    dispatch(
      actions.getIdentityRolesChange({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
          resourceSubCategory: ResourceSubCategory.Roles,
        },
      }),
    );
  }, [
    environments,
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    user.info.org_uuid,
    aiParamExtraction.data,
    cloudAccounts.data,
  ]);

  return (
    <>
      {environments.isLoading || cloudAccounts.loading ? (
        <Center h={contentHeight + 'px'}>
          <CustomSpinner size="xl" />
        </Center>
      ) : (
        <>
          {!isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <IdentityAuthorization />
          )}
          {isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <NoAccountsOnboarded />
          )}
        </>
      )}
    </>
  );
};
