import React from 'react';

import { Box, Grid, GridItem, Stack } from '@chakra-ui/react';

import { formatTooltip } from 'components/DataDisplay';
import { BlockStorageIcon } from 'components/Icons';
import { ActiveDataRegions } from 'containers/Dashboard/DataSecurityOverview/Components/ActiveDataRegions';
import { Buckets } from 'containers/Dashboard/DataSecurityOverview/Components/Buckets';
import { Databases } from 'containers/Dashboard/DataSecurityOverview/Components/Databases';
import { DataSecurityFindingsHistory } from 'containers/Dashboard/DataSecurityOverview/Components/DataSecurityFindingsHistory';
import { DataSourcesAndSensitiveData } from 'containers/Dashboard/DataSecurityOverview/Components/DataSourcesAndSensitiveData';
import { Disks } from 'containers/Dashboard/DataSecurityOverview/Components/Disks';
import { InsightsForDataSources } from 'containers/Dashboard/DataSecurityOverview/Components/InsightsForDataSources';
import { OpenFindings } from 'containers/Dashboard/DataSecurityOverview/Components/OpenFindings';
import { PubliclyAccessibleDataSources } from 'containers/Dashboard/DataSecurityOverview/Components/PubliclyAccessibleDataSources';
import { SaaS } from 'containers/Dashboard/DataSecurityOverview/Components/SaaS';
import { SummaryOfAllDataSources } from 'containers/Dashboard/DataSecurityOverview/Components/SummaryOfAllDataSources';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

interface Props {
  isDataClassificationEnabled: boolean;
}

export const DataSecurity: React.FC<Props> = props => {
  return (
    <DashboardOverviewWrapperCard
      label="DATA SECURITY"
      height="1050px"
      content={
        <Stack spacing={OverviewCardSpacing} w="full" h="full">
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <SummaryOfAllDataSources />
                <DataSourcesSummaryCard
                  tooltip={formatTooltip({
                    header: 'Data sources',
                    content:
                      'Buckets, Disks, Databases, File stores and Data warehouses in public clouds and SaaS',
                    width: 48,
                  })}
                />
                <Buckets />
                <Databases />
                <Disks />
                <SaaS />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <InsightsForDataSources />
                <DataSourcesAndSensitiveData />
                <PubliclyAccessibleDataSources />
              </Stack>
            </GridItem>
            <GridItem colSpan={2} h="full" w="full">
              <Stack w="full" h="full" spacing={OverviewCardSpacing}>
                <Box h="20%" w="full">
                  <OpenFindings />
                </Box>
                <Box h="30%" w="full">
                  <DataSecurityFindingsHistory />
                </Box>
                <Box h="50%" w="full">
                  <ActiveDataRegions />
                </Box>
              </Stack>
            </GridItem>
          </Grid>
        </Stack>
      }
      icon={<BlockStorageIcon />}
    />
  );
};
