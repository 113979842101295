import React, { FC, useEffect, useRef, useState } from 'react';

import { ChecksSchemaStatusSeverityStatus } from '@ariksa/compliance-policies/api';
import { Box, HStack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import { DownloadIconButton, PrimaryIconButton } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { TabItemProps, Tabs } from 'components/Navigation/Tabs';
import { Drawer } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { ControlTable } from 'containers/Compliance/Components/ComplianceTables/ComplianceControlTable/ControlTable';
import { PoliciesTable } from 'containers/Compliance/Components/ComplianceTables/PoliciesTable';
import { ResourceTable } from 'containers/Compliance/Components/ComplianceTables/ResourceTable/ResourceTable';

export const ComplianceTableTabs: FC = () => {
  const {
    rules,
    controlStandardRules: standardRules,
    selectedTab,
    byResourceType,
    selectedBlueprint,
    download,
    resourceTypeSeverity,
  } = useSelector(selectCompliance);

  const dispatch = useDispatch();
  const { withAccessBoundary } = useAccessBoundary();
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef(document.querySelector('.portal-container'));

  // Api call for Rules and Control
  useEffect(() => {
    if (!isEmpty(selectedBlueprint)) {
      if (!selectedBlueprint?.compliance) {
        dispatch(
          actions.loadRules({
            q: withAccessBoundary({
              blueprintId: selectedBlueprint?.id,
              page: rules.page.info.page_number,
              size: rules.page.info.page_size,
            }),
          }),
        );
      } else {
        dispatch(
          actions.getControlStandardRules({
            q: withAccessBoundary({
              blueprintId: selectedBlueprint?.id,
              page: standardRules.page.info.page_number,
              size: standardRules.page.info.page_size,
              level: 'control',
            }),
          }),
        );
      }
    }
  }, [
    dispatch,
    selectedBlueprint,
    rules.page.info,
    standardRules.page.info,
    withAccessBoundary,
  ]);

  // Api call for compliance by resource type
  useEffect(() => {
    if (!isEmpty(selectedBlueprint)) {
      const severity = !!resourceTypeSeverity
        ? {
            severity: resourceTypeSeverity.toUpperCase() as ChecksSchemaStatusSeverityStatus,
          }
        : {};
      dispatch(
        actions.getComplianceSummaryByResourceType({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            page: byResourceType.page.info.page_number,
            size: byResourceType.page.info.page_size,
            ...severity,
          }),
        }),
      );
    }
  }, [
    dispatch,
    selectedBlueprint,
    byResourceType.page.info,
    withAccessBoundary,
    resourceTypeSeverity,
  ]);

  const tabItems: TabItemProps[] = [
    {
      title: 'By ' + (selectedBlueprint?.compliance ? 'Control' : 'Policies'),
      key: 'rules',
      component: selectedBlueprint?.compliance ? (
        <Box pt={4} h="full">
          <ControlTable />
        </Box>
      ) : (
        <Box pt={4} h="full">
          <PoliciesTable />
        </Box>
      ),
    },
    {
      title: 'By Resource Type',
      key: 'resourceType',
      component: (
        <Box h={'full'} flex={1}>
          <ResourceTable />
        </Box>
      ),
    },
  ];

  const renderTabs = () => (
    <Tabs
      items={tabItems}
      currentIndex={selectedTab}
      onChange={selected => dispatch(actions.changeSelectedTab(selected))}
      styles={{ tabPanel: { pt: 4 } }}
      componentHeader={
        <HStack flex={1} justify="end" pb={2} pr={isExpanded ? 10 : 0}>
          {!isExpanded && (
            <PrimaryIconButton
              aria-label="expand"
              onClick={() => setIsExpanded(true)}
            >
              {getIcon(IconTypes.ViewAll)}
            </PrimaryIconButton>
          )}
          <DownloadIconButton
            aria-label="download-compliance"
            isLoading={download.isLoading}
            onClick={() =>
              dispatch(
                actions.downloadCompliance({
                  q: { blueprintId: selectedBlueprint.id },
                }),
              )
            }
          />
        </HStack>
      }
    />
  );

  return isExpanded ? (
    <Drawer
      isOpen={isExpanded}
      onClose={() => setIsExpanded(false)}
      body={renderTabs()}
      closeButton
      styles={{
        content: { maxW: '1520px' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
    />
  ) : (
    renderTabs()
  );
};
