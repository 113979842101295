import { Resources, SearchResponse } from '@ariksa/inventory-core';
import { EntitySeverityListResponse } from '@ariksa/notification/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';

import { ApiCallState, ApiCallStatus } from 'api/types';
import { PageInfo } from 'components/Navigation';

import { Dict } from '../../types';

export interface MinimalGraphConfig {
  container: string;
  width: number;
  height: number;
}

export interface PagedData {
  data: {
    results: any[];
    total: number;
  };
  currentRecord: Record<string, any>;
}

export interface ListInventoryParams {
  resourceType: Resources;
  accountIds: string;
  snapshotId?: string;
}

export interface VisibilityState {
  inventory: ApiCallStatus & Pick<PagedData, 'data'>;
  alerts: IQueryState<EntitySeverityListResponse>;
  insights: ApiCallStatus & {
    results: Record<string, any>[];
    total: number;
    currentRecord: Record<string, any>;
  };
  policies: ApiCallStatus & {
    results: Record<string, any>[];
    total: number;
    currentRecord: Record<string, any>;
  };
  resourceDetails: ApiCallStatus & {
    policy_id: string;
    results: Record<string, any>;
  };
  connectedEntities: ApiCallState<Dict<any>[]>;
  nodeDetails: ApiCallStatus & {
    results: Record<string, any>;
  };
  resourceHealth: ApiCallStatus & {
    results: Record<string, any>[];
    pageInfo: PageInfo;
  };
  currentPolicy: Record<string, any>;
  detailsProps: {
    user_id: string;
    user_uuid: string;
    account_ids: string;
    resource_type: string;
    resource_id: string;
    name: string;
    role_id: string;
  };

  /*it is used to populate 360 dropdown in maps,
   * or resource name field in table filters
   * stores insights*/
  resourceList: ApiCallStatus & {
    results: Record<string, any>[];
    currentRecord: Record<string, any>;
    total: number;
    pageInfo: PageInfo;
  };

  policyDocument: ApiCallState<any>;
  rolePolicies: ApiCallState<Policy[]>;

  policyPermission: {
    accounts: ApiCallState<any>;
    services: ApiCallState<any>;
    resources: ApiCallState<any>;
    actions: ApiCallState<any>;
  };

  unusedPermissionList: ApiCallState<any>;
  getAdjacentNodeForNetworkMap: IQueryState<any>;
  getAdjacentNodeForAccessedMap: ApiCallStatus;
  getAdjacentNodeForNetAccessMap: ApiCallStatus;
  getAdjacentNodeForBehaviourMap: ApiCallStatus;
  getAdjacentNodeForCanAccessMap: ApiCallStatus;
  getAdjacentNodeForExcessPermissionMap: ApiCallStatus;
  getAdjacentNodeForResourceExcessPermissionMap: ApiCallStatus;
  getAdjacentNodeForConnectedEntities: ApiCallStatus;

  isMapOpen: boolean;
  mapType: MapType;

  resourceInsight: IPagedQueryState<SearchResponse>;
}

export interface Policy {
  uuid: string;
  policy_name: string;
  policy_identifier: string;
  attached_to: string;
  inline: true;
  attached_entity_id: string;
  attached_entity_name: string;
  managed_by: string;
  is_permission_boundary: true;
}

export type ContainerState = VisibilityState;

export enum MapType {
  Access = 'Access',
  Posture = 'Posture',
  Organization = 'Organization',
  ResourceExcessPermissions = 'Resource/ExcessPermissions',
  ResourceNetAccess = 'Resource/NetAccess',
  ResourceBehavior = 'Resource/Behavior',
  ResourceConnectedEntities = 'Resource/ConnectedEntities',
  UserExcessPermissions = 'User/ExcessPermissions',
  UserNetAccess = 'User/NetAccess',
  UserBehaviour = 'User/Behaviour',
  RoleExcessPermissions = 'Role/ExcessPermissions',
  RoleNetAccess = 'Role/NetAccess',
  RoleBehaviour = 'Role/Behaviour',
  MachineExcessPermissions = 'Machine/ExcessPermissions',
  MachineNetAccess = 'Machine/NetAccess',
  MachineBehaviour = 'Machine/Behaviour',
  Organisation = 'organisation',
  Network = 'Network',
  InternetAccess = 'InternetAccess',
  WhatIf = 'WhatIf',
  Unknown = 'Unknown',
}

export enum NodeType {
  Resource = 'Resource',
  ResourceWithCount = 'ResourceWithCount',
  Account = 'Account',
  Command = 'Command',
  Action = 'Action',
  PolicyRole = 'PolicyRole',
  User = 'User',
  AccessResource = 'AccessResource',
  Permissions = 'Permissions',
}

export type NodeId = string;
