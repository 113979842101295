import { createContext, useContext } from 'react';

import { AccountType } from '@ariksa/cloud-account';
import {
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiUpdateCloudAccountRequest,
} from '@ariksa/cloud-account/api';
import { Clients } from '@ariksa/notification';
import { ClientResponse } from '@ariksa/notification/api';

import { FormAction } from 'components/DataEntry';

export interface IntegrationsContextProps {
  onConfirmDeleteClient: (uuid: string, client_name: Clients) => void;
  onConfirmDeleteAccount: (uuid: string, accountType: AccountType) => void;
  onEdit: (row: ClientResponse) => void;
  onEditProvider: (provider: Record<string, any>) => void;
  onClickRediscoverInventory: (provider: Record<string, any>) => void;
  onConfirmRediscoverInventory: (accountType: AccountType) => void;
  onboardAccount: (
    payload: CloudAccountApiOnboardCloudAccountRequest,
    accountType: AccountType,
  ) => void;
  updateAccount: (
    payload: CloudAccountApiUpdateCloudAccountRequest,
    accountType: AccountType,
  ) => void;
  onAddClient: () => void;
  actionType: FormAction;
  setActionType: (value: FormAction) => void;
  isOpen: boolean;
  onCancel: () => void;
  onClickAddProvider: (value: string) => void;
  selectedProvider: string;
  currentRecord: Record<string, any>;
  setCurrentRecord(val: Record<string, any>);
  isOpenRediscoverModal: boolean;
  onCloseRediscoverModal: () => void;
  getAccounts: (accountType: AccountType) => void;
  clientActionsColumn: Record<string, any>;
  isOpenDeleteClientModal: boolean;
  onCloseDeleteClientModal();
}

export const IntegrationsContext = createContext<IntegrationsContextProps>({
  onConfirmDeleteClient: () => {},
  onConfirmDeleteAccount: () => {},
  onClickRediscoverInventory: () => {},
  onConfirmRediscoverInventory: () => {},
  onEdit: () => {},
  onEditProvider: () => {},
  updateAccount: () => {},
  onboardAccount: () => {},
  onAddClient: () => {},
  actionType: 'Add',
  isOpen: false,
  onCancel: () => {},
  setActionType: () => {},
  onClickAddProvider: () => {},
  setCurrentRecord: () => {},
  selectedProvider: '',
  currentRecord: {},
  isOpenRediscoverModal: false,
  onCloseRediscoverModal: () => {},
  getAccounts: () => {},
  clientActionsColumn: {},
  isOpenDeleteClientModal: false,
  onCloseDeleteClientModal() {},
});

export const IntegrationsContextProvider = IntegrationsContext.Provider;

export const useIntegrationsContext = () => useContext(IntegrationsContext);
