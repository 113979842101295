import React, { FC, ReactNode } from 'react';

import { Box, Center } from '@chakra-ui/react';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

interface Props {
  content: ReactNode;
  label: string;
  isLoading?: boolean;
}

export const FootprintBox: FC<Props> = props => {
  const { content, label, isLoading } = props;
  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      w="full"
      borderRadius={6}
      h="full"
    >
      <Center h="full">
        <Box w="full" h="full">
          <Center w="full">
            <Box position="absolute" px={3} bg="white">
              {label}
            </Box>
          </Center>
          <Center h="full" position="relative">
            {isLoading ? <CustomSpinner /> : content}
          </Center>
        </Box>
      </Center>
    </Box>
  );
};
