import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AutoMinorVersionUpgrade,
  CaCertificateID,
  Cluster,
  DeleteProtection,
  Engine,
  EndpointAddress,
  EndpointPort,
  HostedZoneID,
  IAMDBAuthentication,
  KMSKeyID,
  MultiAZ,
  SecurityGroups,
  StorageEncrypted,
} from '../../../Components/MetadataField/MetaGridFields';

export const DocumentDBMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {HostedZoneID(resource)}
      {EndpointAddress(resource)}
      {EndpointPort(resource)}
      {Engine(resource)}
      {Cluster(resource)}
      {MultiAZ(resource)}
      {StorageEncrypted(resource)}
      {KMSKeyID(resource)}
      {SecurityGroups(resource)}
      {IAMDBAuthentication(resource)}
      {CaCertificateID(resource)}
      {DeleteProtection(resource)}
      {AutoMinorVersionUpgrade(resource)}
    </Grid>
  );
};
