import React from 'react';

import { Accordion } from '@chakra-ui/react';

import { ResourceActionsPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActionsPatternAccordion';

export const LambdaAccess = () => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple>
      <ResourceActionsPatternAccordion />
    </Accordion>
  );
};
