import { get } from 'lodash';

import { Sorted } from 'components/DataDisplay/NewTable/types';

export const isCellEmpty = value => {
  return value === '-' || value === undefined || value === null;
};

export const cellValue = (row, column) => {
  const { accessor = '' } = column;

  if (!accessor) {
    return '';
  }

  const value = get(row, accessor, '-');
  if (value === null || value === undefined) {
    return '-';
  }

  return value;
};

const Severity = {
  CRITICAL: 4,
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
};

export const sortTableData = (data: Record<string, any>[], sortBy: Sorted) => {
  let sortedData = [...data];
  if (!!sortBy.sortField) {
    sortedData.sort((a, b) => {
      let value_a = get(a, sortBy.sortField, '');
      let value_b = get(b, sortBy.sortField, '');
      if (sortBy.sortField === 'severity') {
        value_a = Severity[value_a];
        value_b = Severity[value_b];
      }
      if (value_a < value_b) {
        return sortBy.sortOrder === 'asc' ? -1 : 1;
      }
      if (value_a > value_b) {
        return sortBy.sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortedData;
};
