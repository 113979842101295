/**
 *
 * Asynchronously loads the component for DataSecurityOverview
 *
 */

import { lazy } from 'react';

export const DataSecurityOverview = lazy(() =>
  import('./index').then(m => ({ default: m.DataSecurityOverview })),
);
