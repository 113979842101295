import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BackupIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M381.8,286.4c-32.7,0-63.5,13.7-84.6,37.6c-3.2,3.6-2.8,9.1,0.8,12.3c3.6,3.2,9.1,2.8,12.3-0.8
		c17.8-20.2,43.9-31.8,71.6-31.8c52.6,0,95.5,42.8,95.5,95.5s-42.8,95.5-95.5,95.5c-43.3,0-81.6-29.2-93.2-69.4h41.1v-17.4h-69.4
		v69.4h17.4v-31.8c18.2,39.3,58.8,66.5,104.1,66.5c62.2,0,112.8-50.6,112.8-112.8S444,286.4,381.8,286.4z"
      />
      <path
        d="M234.3,357.4c-7.2,0-14.2-0.1-21.1-0.4c-4.2-0.2-8.2-0.4-12.3-0.7c-2.6-0.1-5.2-0.2-7.7-0.4
		c-5.4-0.4-10.7-0.9-15.9-1.4c-1-0.1-2.1-0.2-3.1-0.3c-6-0.7-11.8-1.4-17.5-2.2c-0.2,0-0.4-0.1-0.6-0.1
		c-29.3-4.3-54.5-10.9-74.2-18.8c-0.1-0.1-0.3-0.1-0.4-0.1c-3.6-1.4-7-3-10.2-4.5c-0.4-0.2-0.8-0.4-1.2-0.6
		c-2.9-1.4-5.6-2.8-8.2-4.3c-0.6-0.4-1.3-0.7-1.9-1.1c-2.2-1.3-4.3-2.7-6.2-4c-0.8-0.5-1.5-1.1-2.2-1.6c-1.6-1.2-3.1-2.4-4.5-3.7
		c-0.8-0.7-1.6-1.4-2.3-2.1c-1.2-1.1-2.2-2.3-3.2-3.4c-0.7-0.8-1.3-1.6-1.9-2.4c-0.8-1.1-1.4-2.1-2-3.2c-0.5-0.9-0.9-1.7-1.2-2.6
		c-0.4-1.1-0.8-2.1-1-3.1c-0.3-1.4-0.7-2.8-0.7-4.2v-71.2c0.2,0.2,0.5,0.4,0.8,0.7c1.9,1.7,3.8,3.3,6,4.9
		c32.7,24.7,98.1,43.9,192.8,43.9c94.3,0,159.6-19.1,192.4-43.6c2.6-1.9,5-3.9,7.2-5.9h0v32.2c0,4.8,3.9,8.7,8.7,8.7
		c4.8,0,8.7-3.9,8.7-8.7v-60.7v-4.3V84v-4.3c0-1.4-0.4-2.7-1-3.9C439.9,32.7,348.8,0,234.3,0S28.7,32.7,18.4,75.8
		c-0.6,1.2-1,2.4-1,3.9V84v104.1v4.3v99.8v4.3v104.1c0,1.4,0.4,2.7,1,3.9c9.9,41.9,92.8,66.6,171.9,72.6c0.2,0,0.4,0,0.7,0
		c4.5,0,8.3-3.5,8.6-8c0.4-4.8-3.2-8.9-8-9.3C95.1,452.7,38.9,423.3,35,399.4c-0.1-0.4-0.2-0.8-0.3-1.1v-73c0.2,0.2,0.5,0.4,0.8,0.7
		c1.9,1.7,3.8,3.3,6,4.9c32.7,24.7,98.1,43.9,192.8,43.9c4.8,0,8.7-3.9,8.7-8.7C243,361.3,239.1,357.4,234.3,357.4z M39.9,121.3
		c2.6,2,5.4,4.1,8.5,6c0.6,0.4,1.3,0.8,2,1.2c2.6,1.6,5.3,3.2,8.1,4.7c1.1,0.6,2.1,1.1,3.2,1.7c3.2,1.6,6.5,3.2,10,4.7
		c0.6,0.3,1.1,0.5,1.7,0.8c4.1,1.7,8.4,3.4,12.9,5c1.2,0.4,2.5,0.9,3.8,1.3c3.6,1.2,7.3,2.4,11.1,3.5c1.4,0.4,2.7,0.8,4.1,1.2
		c5.1,1.4,10.3,2.7,15.8,4c0.8,0.2,1.6,0.3,2.3,0.5c4.9,1.1,9.9,2,15.1,3c1.8,0.3,3.5,0.6,5.3,0.9c4.9,0.8,10,1.6,15.2,2.2
		c1.3,0.2,2.6,0.4,4,0.5c6.5,0.8,13.1,1.4,20,2c1.7,0.1,3.5,0.2,5.2,0.4c5.5,0.4,11.1,0.7,16.9,1c2.1,0.1,4.2,0.2,6.3,0.3
		c7.5,0.3,15.1,0.4,23,0.4s15.5-0.2,23-0.4c2.1-0.1,4.2-0.2,6.3-0.3c5.7-0.3,11.4-0.6,16.9-1c1.7-0.1,3.5-0.2,5.2-0.4
		c6.9-0.6,13.5-1.2,20-2c1.4-0.2,2.6-0.4,4-0.5c5.2-0.7,10.3-1.4,15.2-2.2c1.8-0.3,3.6-0.6,5.3-0.9c5.2-0.9,10.2-1.9,15.1-3
		c0.8-0.2,1.6-0.3,2.3-0.5c5.5-1.2,10.7-2.6,15.8-4c1.4-0.4,2.8-0.8,4.1-1.2c3.8-1.1,7.5-2.3,11.1-3.5c1.3-0.4,2.6-0.9,3.8-1.3
		c4.5-1.6,8.8-3.2,12.9-5c0.6-0.2,1.1-0.5,1.7-0.8c3.5-1.5,6.8-3.1,10-4.7c1.1-0.6,2.1-1.1,3.2-1.7c2.8-1.5,5.5-3.1,8.1-4.7
		c0.7-0.4,1.4-0.8,2-1.2c3-2,5.9-4,8.5-6c0.7-0.5,1.2-1,1.9-1.6c1.1-0.9,2.3-1.9,3.4-2.8v71.2c0,1.4-0.4,2.8-0.7,4.2
		c-0.2,1.1-0.6,2.1-1,3.1c-0.3,0.9-0.8,1.7-1.2,2.6c-0.6,1.1-1.2,2.1-2,3.2c-0.6,0.8-1.2,1.6-1.9,2.4c-1,1.1-2,2.3-3.2,3.4
		c-0.7,0.7-1.5,1.4-2.3,2.1c-1.4,1.2-2.9,2.5-4.5,3.7c-0.7,0.5-1.5,1.1-2.2,1.6c-2,1.3-4,2.7-6.2,4c-0.6,0.4-1.2,0.7-1.9,1.1
		c-2.6,1.4-5.3,2.9-8.2,4.3c-0.4,0.2-0.8,0.4-1.2,0.6c-3.2,1.5-6.6,3-10.2,4.5c-0.1,0.1-0.3,0.1-0.4,0.1
		c-19.7,7.9-44.9,14.5-74.2,18.8c-0.2,0-0.4,0-0.6,0.1c-5.7,0.8-11.5,1.6-17.5,2.2c-1,0.1-2.1,0.2-3.1,0.3c-5.2,0.5-10.5,1-15.9,1.4
		c-2.5,0.2-5.2,0.3-7.7,0.4c-4.1,0.2-8.1,0.5-12.3,0.7c-6.9,0.2-13.9,0.4-21.1,0.4s-14.2-0.1-21.1-0.4c-4.2-0.2-8.2-0.4-12.3-0.7
		c-2.6-0.1-5.2-0.2-7.7-0.4c-5.4-0.4-10.7-0.9-15.9-1.4c-1-0.1-2.1-0.2-3.1-0.3c-6-0.7-11.8-1.4-17.5-2.2c-0.2,0-0.4-0.1-0.6-0.1
		c-29.3-4.3-54.5-10.9-74.2-18.8c-0.1-0.1-0.3-0.1-0.4-0.1c-3.6-1.4-7-3-10.2-4.5c-0.4-0.2-0.8-0.4-1.2-0.6
		c-2.9-1.4-5.6-2.8-8.2-4.3c-0.6-0.4-1.3-0.7-1.9-1.1c-2.2-1.3-4.3-2.7-6.2-4c-0.8-0.5-1.5-1.1-2.2-1.6c-1.6-1.2-3.1-2.4-4.5-3.7
		c-0.8-0.7-1.6-1.4-2.3-2.1c-1.2-1.1-2.2-2.3-3.2-3.4c-0.7-0.8-1.3-1.6-1.9-2.4c-0.8-1.1-1.4-2.1-2-3.2c-0.5-0.9-0.9-1.7-1.2-2.6
		c-0.4-1.1-0.8-2.1-1-3.1c-0.3-1.4-0.7-2.8-0.7-4.2V117c1,0.9,2.2,1.9,3.4,2.8C38.7,120.3,39.3,120.8,39.9,121.3z M234.3,17.4
		c117.6,0,199.6,35.1,199.6,66.7c0,30.8-82,65.1-199.6,65.1S34.7,114.8,34.7,84C34.7,52.5,116.7,17.4,234.3,17.4z"
      />
    </g>
  </Icon>
);
