import React, { FC } from 'react';

import { SSOAddProvider } from 'containers/Setup/Integrations/Components/SSO/SSOAddProvider';
import { SSOProvidersTable } from 'containers/Setup/Integrations/Components/SSO/SSOProvidersTable';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

interface ISSO {}

export const SSO: FC<ISSO> = props => {
  const { isOpen } = useIntegrationsContext();
  return <> {isOpen ? <SSOAddProvider /> : <SSOProvidersTable />}</>;
};
