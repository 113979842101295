import React, { FC, useEffect, useReducer, useRef } from 'react';

import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { cloudOptions } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  isOpen: boolean;
  onClose();
  currentRecord?: Record<string, any>;
}

export const DataSourcesByResourceType: FC<Props> = props => {
  const { isOpen, onClose, currentRecord } = props;
  const { dataSourcesByType } = useSelector(selectDataDashboard);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const dataSourcesDisclosure = useDisclosure();
  const { dataSourcesByResourceTypeColumns } = useDataTableColumns(
    MetadataTabs.Data,
  );
  const { accountId, environmentId } = useAccessBoundary();
  const {
    resourceTypeAliasOptionsWithAll,
    getResourceAlias,
    getCloudAgnosticName,
  } = useResourceType();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cloudOptions: [
        { label: 'All clouds', value: undefined },
        ...cloudOptions,
      ],
      selectedCloud: { label: 'All clouds', value: undefined },
      resourceType: { label: 'All resource types', value: undefined },
      resourceTypeOptions: [],
      currentRecord: {},
      searchTerm: '',
    },
  );

  useEffect(() => {
    updateState({ resourceTypeOptions: resourceTypeAliasOptionsWithAll });
  }, [resourceTypeAliasOptionsWithAll]);

  useEffect(() => {
    dispatch(
      actions.getDataSourcesByResourceType({
        q: {
          nativeResource: currentRecord?.native_resource,
          page: dataSourcesByType.page.info.page_number,
          size: dataSourcesByType.page.info.page_size,
        },
      }),
    );
  }, [
    accountId,
    environmentId,
    dataSourcesByType.page.info,
    state.selectedCloud,
    state.resourceType,
    state.searchTerm,
    currentRecord,
    dispatch,
  ]);

  const styles = {
    menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%' }),
  };

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1450px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <StackedHeader
            upper={getResourceAlias(currentRecord?.native_resource)}
            lower={getCloudAgnosticName(currentRecord?.native_resource)}
            type={currentRecord?.native_resource}
          />
        }
        body={
          <Stack h="full" pt={3} spacing={4}>
            {/*<HStack justify="space-between">
               <DetailsIdentifier
                label="Document Type"
                value={currentRecord?.type_name}
              />
              <Box w={96}>
                <SearchBox
                  onChange={s => updateState({ searchTerm: s })}
                  value={state.searchTerm}
                />
              </Box>
              <HStack>
                <Box color="primary">FILTERS:</Box>
                <Box w={48}>
                  <Select
                    options={state.resourceTypeOptions}
                    value={state.resourceType}
                    onChange={val => updateState({ resourceType: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>
                <Box w={48}>
                  <Select
                    options={state.cloudOptions}
                    value={state.selectedCloud}
                    onChange={val => updateState({ selectedCloud: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>
              </HStack>
            </HStack>*/}
            <Box flex={1}>
              <Table
                data={dataSourcesByType.data ?? []}
                isError={dataSourcesByType.isError}
                columns={dataSourcesByResourceTypeColumns}
                isLoading={dataSourcesByType.isLoading}
                styles={{ header: { position: 'relative', zIndex: 800 } }}
                onRowClick={row => {
                  updateState({ currentRecord: row });
                  dataSourcesDisclosure.onOpen();
                }}
                pagination={{
                  pageInfo: dataSourcesByType.page.info,
                  onChange: info =>
                    dispatch(actions.updateDataSourcesByTypePageInfo(info)),
                  totalCount: dataSourcesByType.page.totalCount,
                }}
              />
            </Box>
          </Stack>
        }
      />
    </Box>
  );
};
