import { createContext, useContext } from 'react';

export interface ComparePoliciesContextProps {
  firstEnvironment: Record<string, any>;
  secondEnvironment: Record<string, any>;

  firstBlueprint: Record<string, any>;
  secondBlueprint: Record<string, any>;

  setFirstEnvironment: (value: Record<string, any>) => void;
  setSecondEnvironment: (value: Record<string, any>) => void;

  setFirstBlueprint: (value: Record<string, any>) => void;
  setSecondBlueprint: (value: Record<string, any>) => void;
}

export const ComparePoliciesContext = createContext<ComparePoliciesContextProps>(
  {
    firstEnvironment: {},
    secondEnvironment: {},

    firstBlueprint: {},
    secondBlueprint: {},

    setFirstEnvironment: () => {},
    setFirstBlueprint: () => {},

    setSecondEnvironment: () => {},
    setSecondBlueprint: () => {},
  },
);

export const ComparePoliciesProvider = ComparePoliciesContext.Provider;

export const useComparePolicies = () => useContext(ComparePoliciesContext);
