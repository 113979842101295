/**
 *
 * Elastic for SIEM
 *
 */

import React from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { AddElasticForSIEM } from 'containers/Setup/Integrations/SIEM/Elastic/AddElasticForSIEM';
import { EditElasticForSIEM } from 'containers/Setup/Integrations/SIEM/Elastic/EditElasticForSIEM';

export const Elastic = () => {
  const { actionType } = useIntegrationsContext();

  return actionType === 'Add' ? <AddElasticForSIEM /> : <EditElasticForSIEM />;
};
