import { RulesApiGetAllComplianceStandardRequest } from '@ariksa/compliance-policies/api';
import {
  NetworkTrafficOverview,
  NetworkTrafficPortInfo,
  NetworkTrafficProcessInfo,
} from '@ariksa/ebpf-data-collector';
import { SearchResponse, SearchResponseLevel } from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextResRequest,
  MapResponse,
  RiskContextResponse,
  SearchApiGetContextRequest,
  SearchApiMapRequest,
  SearchApiSearchRequest,
  SearchApiSearchTermsRequest,
  SearchResponseNode,
  SearchTerm,
  SearchV2ApiDependencyRequest,
  SearchV2ApiEvidenceRequest,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import { SearchTermCategory } from '@ariksa/inventory-core/dist/api';
import {
  AlertLogsApiGetLogsForIdsRequest,
  AlertLogsResponse,
  AlertResponse,
  AlertsApiGetAlertByIdRequest,
  AlertsApiGetEntitySeverityListRequest,
  AlertsApiUpdateAlertRequest,
  ClientsApiUpdateTicketRequest,
  EntitySeverityListResponse,
} from '@ariksa/notification/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { each } from 'lodash';
import { QueryAction, QueryStatus } from 'services/types';
import { QueryState } from 'services/utils/QueryState';
import { Dict, Optional } from 'types/utils';

import { SelectOption } from 'components/DataEntry';
import { NodePagination } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';
import {
  ContainerState,
  InventorySearchResponse,
  RedirectInfo,
  SecurityGraphMapType,
  SourceNodeOptions,
  ViewType,
} from 'containers/Visibility/SecurityGraphNext/types';

export const initialState: ContainerState = {
  redirectInfo: null,
  isRedirected: false,
  isSelected: false,

  viewType: ViewType.Map,
  mapType: SecurityGraphMapType.Unknown,

  // search queries
  getStartedSearchOptions: QueryState.init([]),
  recentSearchOptions: QueryState.init([]),
  searchTermOptions: [],

  // filters and query selection
  prevActiveSearchQueryTerm: null,
  selectedSearchQueryTerm: null,
  activeSearchQueryTerm: null,
  sourceNodeOptions: [],
  selectedSourceNodeOption: null,
  redirectedSearchQueryTerm: null,
  initialSourceNodeId: '',
  filterChanged: false,
  queryChanged: false,

  hiddenNodeIds: new Set(),
  enrichInsights: {
    alertingEntities: false,
    filteringByContext: false,
  },
  resourceAlerts: QueryState.init({ severity: {} }),

  // the search results
  responseLevel: SearchResponseLevel.Compact,
  sourceNodes: QueryState.init([]),
  compactMap: QueryState.init({}),
  fullMap: QueryState.init({}),
  resourceRedirectMap: QueryState.init({}),

  evidenceMap: QueryState.init({}),
  dependencyMap: QueryState.init({}),

  table: QueryState.init({}),
  resourceRedirectTable: QueryState.init({}),
  activeRow: null,
  currentSearchQuery: null,
  filters: {
    resource: [],
    context: [],
    resourceType: [],
  },
  evidence: {
    alertDetails: QueryState.init({} as AlertResponse),
    riskContext: QueryState.init([]),
    compliance: QueryState.init([]),
    alertStatus: QueryState.init({}),
    evidenceAction: QueryState.init({}),
  },
  showVulnerability: true,
  showDependencies: false,
  pagination: {},
  mapContext: QueryState.init({}),

  vmTrafficSummary: QueryState.init({}),
  trafficByPorts: QueryState.init([]),
  trafficByProcess: QueryState.init([]),
};

const securityGraphNextSlice = createSlice({
  name: 'securityGraphNext',
  initialState,
  reducers: {
    updatePagination(state, action: PayloadAction<NodePagination[]>) {
      each(action.payload, p => {
        state.pagination[p.header] = p;
      });
    },
    resetPagination(state) {
      state.pagination = {};
    },
    toggleVulnerability(state) {
      state.showVulnerability = !state.showVulnerability;
    },
    resetShowVulnerability(state) {
      state.showVulnerability = true;
    },
    updateShowDependencies(state, action: PayloadAction<boolean>) {
      state.showDependencies = action.payload;
    },
    updateMapType(state, action: PayloadAction<SecurityGraphMapType>) {
      state.mapType = action.payload;
    },
    updateRedirectInfo(
      state,
      action: PayloadAction<ContainerState['redirectInfo']>,
    ) {
      state.redirectInfo = action.payload;
    },
    updateRedirected(state, action: PayloadAction<boolean>) {
      state.isRedirected = action.payload;
    },

    toggleViewType(state) {
      state.viewType =
        state.viewType === ViewType.Map ? ViewType.Table : ViewType.Map;
    },

    getGetStartedSearchOptions(
      state,
      action: QueryAction<SearchWrapper[], SearchApiSearchTermsRequest>,
    ) {
      state.getStartedSearchOptions = QueryState.next(
        state.getStartedSearchOptions,
        action,
      );
    },
    getRecentSearchOptions(
      state,
      action: QueryAction<SearchWrapper[], SearchApiSearchTermsRequest>,
    ) {
      state.recentSearchOptions = QueryState.next(
        state.recentSearchOptions,
        action,
      );
    },
    setSearchTermOptions(
      state,
      action: PayloadAction<{ label: string; value: SearchTerm }[]>,
    ) {
      state.searchTermOptions = action.payload;
    },

    updatePrevActiveSearchQueryTerm(
      state,
      action: PayloadAction<ContainerState['activeSearchQueryTerm']>,
    ) {
      state.prevActiveSearchQueryTerm = action.payload;
    },
    updateSelectedSearchQueryTerm(
      state,
      action: PayloadAction<ContainerState['selectedSearchQueryTerm']>,
    ) {
      state.selectedSearchQueryTerm = action.payload;
      state.activeSearchQueryTerm = null;
    },
    updateActiveSearchQueryTerm(
      state,
      action: PayloadAction<ContainerState['activeSearchQueryTerm']>,
    ) {
      state.activeSearchQueryTerm = action.payload;
    },
    updateSourceNodeOptions(state, action: PayloadAction<SourceNodeOptions>) {
      state.sourceNodeOptions = action.payload;
    },
    updateSelectedSourceNodeOption(
      state,
      action: PayloadAction<Optional<SelectOption>>,
    ) {
      state.selectedSourceNodeOption = action.payload;
    },
    updateInitialSourceNodeId(state, action: PayloadAction<Optional<string>>) {
      state.initialSourceNodeId = action.payload;
    },
    updateHiddenNodeIds(
      state,
      action: PayloadAction<ContainerState['hiddenNodeIds']>,
    ) {
      state.hiddenNodeIds = action.payload;
    },
    getResourceAlerts(
      state,
      action: QueryAction<
        EntitySeverityListResponse,
        AlertsApiGetEntitySeverityListRequest
      >,
    ) {
      state.resourceAlerts = QueryState.next(state.resourceAlerts, action);
    },
    updateEnrichInsights(state, action: PayloadAction<Dict<boolean>>) {
      state.enrichInsights = { ...state.enrichInsights, ...action.payload };
    },

    updateSearchResponseLevel(
      state,
      action: PayloadAction<ContainerState['responseLevel']>,
    ) {
      state.responseLevel = action.payload;
    },

    getSourceNodes(state, action: QueryAction<ContainerState['sourceNodes']>) {
      state.sourceNodes = QueryState.next(state.sourceNodes, action);
    },

    getMapContext(
      state,
      action: QueryAction<SearchResponse, SearchApiGetContextRequest>,
    ) {
      state.mapContext = QueryState.next(state.mapContext, action);
    },
    updateCompatMapContext(state, action: PayloadAction<SearchResponseNode[]>) {
      state.compactMap.data.nodes = action.payload;
    },

    updateFilterChanged(state, action: PayloadAction<boolean>) {
      state.filterChanged = action.payload;
    },
    updateQueryChanged(state, action: PayloadAction<boolean>) {
      state.queryChanged = action.payload;
    },
    resetFilterAndQueryChanged(state) {
      state.filterChanged = false;
      state.queryChanged = false;
    },
    updateRedirectInfoField(
      state,
      action: PayloadAction<Partial<RedirectInfo>>,
    ) {
      state.redirectInfo = {
        ...state.redirectInfo,
        ...action.payload,
      };
    },
    getCompactMap(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.compactMap = QueryState.next(state.compactMap, action);

      if (action.payload.status === QueryStatus.pending) {
        state.prevActiveSearchQueryTerm = state.activeSearchQueryTerm;
      }

      if (action.payload.status === QueryStatus.fulfilled) {
        const query = {
          uuid: action.payload.q.searchQuery.uuid!,
          name: action.payload.q.searchQuery.query!,
          description: action.payload.q.searchQuery.description!,
          query: action.payload.q.searchQuery.query,
        };

        state.currentSearchQuery = query;
        state.activeSearchQueryTerm = query;
        if (state.isRedirected) {
          state.selectedSearchQueryTerm = null;
        } else {
          state.selectedSearchQueryTerm = query;
        }
      }
    },
    getFullMap(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.fullMap = QueryState.next(state.fullMap, action);
      if (action.payload.status === QueryStatus.pending) {
        state.prevActiveSearchQueryTerm = state.activeSearchQueryTerm;
      }

      if (
        action.payload.status === QueryStatus.fulfilled &&
        state.activeSearchQueryTerm?.uuid !== action.payload.q.searchQuery.uuid
      ) {
        const query = {
          uuid: action.payload.q.searchQuery.uuid!,
          name: action.payload.q.searchQuery.query!,
          description: action.payload.q.searchQuery.description!,
          query: action.payload.q.searchQuery.query,
        };
        state.currentSearchQuery = query;
        state.activeSearchQueryTerm = query;
        if (state.isRedirected) {
          state.selectedSearchQueryTerm = null;
        } else {
          state.selectedSearchQueryTerm = query;
        }
      }
    },
    updateFullMapContext(state, action: PayloadAction<SearchResponseNode[]>) {
      state.fullMap.data.nodes = action.payload;
    },
    getTable(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.table = QueryState.next(state.table, action);

      if (
        action.payload.status === QueryStatus.fulfilled &&
        state.activeSearchQueryTerm?.uuid !== action.payload.q.searchQuery.uuid
      ) {
        state.activeSearchQueryTerm = {
          uuid: action.payload.q.searchQuery.uuid!,
          name: action.payload.q.searchQuery.query!,
          description: action.payload.q.searchQuery.description!,
          query: action.payload.q.searchQuery.query,
        };
      }
    },
    updateActiveRow(state, action: PayloadAction<ContainerState['activeRow']>) {
      state.activeRow = action.payload;
      state.selectedSourceNodeOption = {
        label: action.payload?.resource_name!,
        value: {
          uuid: action.payload?.source_uuid!,
          name: action.payload?.resource_name!,
          type: '',
        },
      };
    },
    resetSearchQuery(state) {
      state.activeSearchQueryTerm = null;
      state.selectedSearchQueryTerm = null;
      state.currentSearchQuery = null;
    },
    getResourceRedirectMap(
      state,
      action: QueryAction<MapResponse, SearchApiMapRequest>,
    ) {
      state.resourceRedirectMap = QueryState.next(
        state.resourceRedirectMap,
        action,
        {
          mapData: r => r.search_response,
        },
      );

      if (action.payload.status === QueryStatus.pending) {
        state.prevActiveSearchQueryTerm = state.activeSearchQueryTerm;
      }

      if (action.payload.status === QueryStatus.fulfilled) {
        const q = action.payload.q;
        state.currentSearchQuery = {
          source_id: q.mapQuery.source_id,
        };

        const query = {
          uuid: action.payload.data?.search_term?.uuid!,
          name: action.payload.data?.search_term?.name!,
          description: action.payload.data?.search_term?.description!,
          query: action.payload.data?.search_term?.query!,
        };
        state.selectedSearchQueryTerm = null;
        state.currentSearchQuery = query;
        state.redirectedSearchQueryTerm = query;
        state.activeSearchQueryTerm = query;
      }
    },

    updateResourceRedirectMapContext(
      state,
      action: PayloadAction<SearchResponseNode[]>,
    ) {
      state.resourceRedirectMap.data.nodes = action.payload;
    },

    getEvidenceMap(
      state,
      action: QueryAction<InventorySearchResponse, SearchV2ApiEvidenceRequest>,
    ) {
      if (action.payload.status === QueryStatus.pending) {
        state.prevActiveSearchQueryTerm = state.activeSearchQueryTerm;
      }

      if (action.payload.status === QueryStatus.fulfilled) {
        console.log('evidence map', action.payload.data);
      }
      state.evidenceMap = QueryState.next(state.evidenceMap, action);
      if (action.payload.status === QueryStatus.fulfilled) {
        const q = action.payload.q;
        state.currentSearchQuery = q.searchQuery;

        const category = action.payload.data?.category;
        if (category === SearchTermCategory.Posture) {
          state.mapType = SecurityGraphMapType.Posture;
        } else if (category === SearchTermCategory.Permission) {
          state.mapType = SecurityGraphMapType.Access;
        } else {
          state.mapType = SecurityGraphMapType.Unknown;
        }
        state.selectedSearchQueryTerm = initialState.selectedSearchQueryTerm;
        state.activeSearchQueryTerm = initialState.activeSearchQueryTerm;
      }
    },

    updateEvidenceMapContext(
      state,
      action: PayloadAction<SearchResponseNode[]>,
    ) {
      state.evidenceMap.data.nodes = action.payload;
    },

    getMapFromQuery(
      state,
      action: QueryAction<SearchResponse, SearchApiSearchRequest>,
    ) {
      state.fullMap = QueryState.next(state.fullMap, action);
      if (action.payload.status === QueryStatus.pending) {
        const query = {
          name: action.payload.q.searchQuery.query!,
          query: action.payload.q.searchQuery.query,
          uuid: action.payload.q.searchQuery.query!,
        };
        state.prevActiveSearchQueryTerm = state.activeSearchQueryTerm;
        state.selectedSearchQueryTerm = null;
        state.redirectedSearchQueryTerm = query;
        state.activeSearchQueryTerm = query;
        state.currentSearchQuery = query;
      }

      if (action.payload.status === QueryStatus.fulfilled) {
        const category = action.payload.data?.category;
        if (category === SearchTermCategory.Posture) {
          state.mapType = SecurityGraphMapType.Posture;
        } else if (
          category === SearchTermCategory.Permission ||
          category === SearchTermCategory.Permissionexcess
        ) {
          state.mapType = SecurityGraphMapType.Access;
        } else {
          state.mapType = SecurityGraphMapType.Unknown;
        }
      }
    },

    getDependencyMap(
      state,
      action: QueryAction<
        InventorySearchResponse,
        SearchV2ApiDependencyRequest
      >,
    ) {
      state.dependencyMap = QueryState.next(state.dependencyMap, action);
    },

    updateDependencyMapContext(
      state,
      action: PayloadAction<SearchResponseNode[]>,
    ) {
      state.dependencyMap.data.nodes = action.payload;
    },

    getResourceRedirectTable(
      state,
      action: QueryAction<MapResponse, SearchApiMapRequest>,
    ) {
      state.resourceRedirectTable = QueryState.next(
        state.resourceRedirectTable,
        action,
        {
          mapData: r => r.search_response,
        },
      );
      if (
        action.payload.status === QueryStatus.fulfilled &&
        state.activeSearchQueryTerm?.uuid !==
          action.payload.data?.search_term?.uuid
      ) {
        const query = {
          uuid: action.payload.data?.search_term?.query!,
          name: action.payload.data?.search_term?.query!,
          description: action.payload.data?.search_term?.description!,
          query: action.payload.data?.search_term?.query!,
        };
        state.selectedSearchQueryTerm = query;
        state.activeSearchQueryTerm = query;
      }
    },
    updateFilters(
      state,
      action: PayloadAction<Partial<ContainerState['filters']>>,
    ) {
      const { context, resourceType, resource } = action.payload;
      if (context) state.filters.context = context;
      if (resourceType) state.filters.resourceType = resourceType;
      if (resource) state.filters.resource = resource;
    },

    getAlertDetails(
      state,
      action: QueryAction<AlertResponse, AlertsApiGetAlertByIdRequest>,
    ) {
      state.evidence.alertDetails = QueryState.next(
        state.evidence.alertDetails,
        action,
      );
    },
    getAlertRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.evidence.riskContext = QueryState.next(
        state.evidence.riskContext,
        action,
        { mapData: r => r[action.payload.q.riskContextRequest.uuids[0]] },
      );
    },
    getAlertCompliance(
      state,
      action: QueryAction<
        Dict<string[]>,
        RulesApiGetAllComplianceStandardRequest
      >,
    ) {
      state.evidence.compliance = QueryState.next(
        state.evidence.compliance,
        action,
        {
          mapData: r => {
            const alertRuleID =
              action.payload.q.getRuleComplianceStandardRequest?.rules?.[0];
            if (alertRuleID) return r[alertRuleID];
            return [];
          },
        },
      );
    },
    getAlertStatus(
      state,
      action: QueryAction<
        AlertLogsResponse[],
        AlertLogsApiGetLogsForIdsRequest
      >,
    ) {
      state.evidence.alertStatus = QueryState.next(
        state.evidence.alertStatus,
        action,
        {
          mapData: r => r?.[0]?.logs ?? [],
        },
      );
    },
    updateAlertStatus(
      state,
      action: QueryAction<any, ClientsApiUpdateTicketRequest>,
    ) {
      state.evidence.evidenceAction = QueryState.next(
        state.evidence.evidenceAction,
        action,
      );
    },
    updateDueDate(
      state,
      action: QueryAction<any, AlertsApiUpdateAlertRequest>,
    ) {
      state.evidence.evidenceAction = QueryState.next(
        state.evidence.evidenceAction,
        action,
      );
    },

    getEc2TrafficSummary(
      state,
      action: QueryAction<
        NetworkTrafficOverview,
        { instanceId: string; accountId: string }
      >,
    ) {
      state.vmTrafficSummary = QueryState.next(state.vmTrafficSummary, action);
    },
    getTrafficByPorts(
      state,
      action: QueryAction<
        NetworkTrafficPortInfo[],
        { instanceId: string; accountId: string; limit?: number }
      >,
    ) {
      state.trafficByPorts = QueryState.next(state.trafficByPorts, action);
    },
    getTrafficByProcess(
      state,
      action: QueryAction<
        NetworkTrafficProcessInfo[],
        { instanceId: string; accountId: string; limit?: number }
      >,
    ) {
      state.trafficByProcess = QueryState.next(state.trafficByProcess, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = securityGraphNextSlice;
