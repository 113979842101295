import {
  NetworkTrafficOverview,
  NetworkTrafficPortInfo,
  NetworkTrafficProcessInfo,
} from '@ariksa/ebpf-data-collector/api';
import { SearchResponse } from '@ariksa/inventory-core';
import {
  MapResponse,
  SearchApiGetContextRequest,
  SearchApiMapRequest,
  SearchApiSearchRequest,
  SearchApiSearchTermsRequest,
  SearchV2ApiDependencyRequest,
  SearchV2ApiEvidenceRequest,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import {
  AlertResponse,
  AlertsApiGetAlertByIdRequest,
  AlertsApiGetEntitySeverityListRequest,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  EbpfCollector,
  InventoryService,
  NotificationService,
} from 'api/services';
import {
  doGetAlertStatus,
  doUpdateAlertStatus,
  doUpdateDueDate,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/saga';
import { doGetCompliance } from 'containers/Findings/FindingsOverview/saga';
import { doGetRiskContext } from 'containers/SharedState/saga';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { InventorySearchResponse } from 'containers/Visibility/SecurityGraphNext/types';

import { isDevEnv } from '../../../../utils/env';

export function* securityGraphNextSaga() {
  yield takeLatestAction(
    actions.getGetStartedSearchOptions,
    doGetGetStartedSearchOptions,
  );
  yield takeLatestAction(
    actions.getRecentSearchOptions,
    doGetRecentSearchOptions,
  );

  yield takeLatestAction(actions.getResourceAlerts, doGetResourceAlerts);
  yield takeLatestAction(actions.getCompactMap, doGetCompactMap);
  yield takeLatestAction(actions.getMapFromQuery, doGetFullMap);
  yield takeLatestAction(actions.getFullMap, doGetFullMap);
  yield takeLatestAction(
    actions.getResourceRedirectMap,
    doGetResourceRedirectMap,
  );
  yield takeLatestAction(actions.getEvidenceMap, doGetEvidenceMap);
  yield takeLatestAction(actions.getDependencyMap, doGetDependencyMap);

  yield takeLatestAction(actions.getTable, doGetTable);
  yield takeLatestAction(
    actions.getResourceRedirectTable,
    doGetResourceRedirectTable,
  );

  yield takeLatestAction(actions.getAlertDetails, doGetAlertDetails);
  yield takeLatestAction(actions.getAlertRiskContext, doGetRiskContext);
  yield takeLatestAction(actions.getAlertCompliance, doGetCompliance);
  yield takeLatestAction(actions.getAlertStatus, doGetAlertStatus);
  yield takeLatestAction(actions.updateAlertStatus, doUpdateAlertStatus);
  yield takeLatestAction(actions.updateDueDate, doUpdateDueDate);
  yield takeLatestAction(actions.getMapContext, doGetMapContext);

  yield takeLatestAction(actions.getEc2TrafficSummary, doGetEc2TrafficSummary);
  yield takeLatestAction(actions.getTrafficByPorts, doGetTrafficByPorts);
  yield takeLatestAction(actions.getTrafficByProcess, doGetTrafficByProcess);
}

function* doGetMapContext(
  ctx: QueryContext<SearchResponse, SearchApiGetContextRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.getContext(ctx.params), {
    errorMsg: 'Failed to get node contexts',
    cacheKey: 'security-graph-get-node-contexts',
  });
}

function* doGetGetStartedSearchOptions(
  ctx: QueryContext<SearchWrapper[], SearchApiSearchTermsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.searchTerms(ctx.params), {
    errorMsg: 'Failed to get search options!',
    cacheKey: isDevEnv() ? 'security-graph-get-started-search-options' : '',
  });
}

function* doGetRecentSearchOptions(
  ctx: QueryContext<SearchWrapper[], SearchApiSearchTermsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.searchTerms(ctx.params), {
    errorMsg: 'Failed to get recent search options!',
    cacheKey: 'security-graph-recent-search-options',
  });
}

//get resource alerts
function* doGetResourceAlerts(
  ctx: QueryContext<any, AlertsApiGetEntitySeverityListRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getEntitySeverityList(ctx.params),
    {
      errorMsg: 'Failed to get alerts!',
    },
  );
}

function* doGetCompactMap(
  ctx: QueryContext<SearchResponse, SearchApiSearchRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.search(ctx.params), {
    errorMsg: 'Failed to get compact map!',
  });
}

function* doGetFullMap(
  ctx: QueryContext<SearchResponse, SearchApiSearchRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.search(ctx.params), {
    errorMsg: 'Failed to get full map!',
    // cacheKey: isDevEnv() ? 'security-graph-full-map':'',
  });
}

function* doGetEvidenceMap(
  ctx: QueryContext<InventorySearchResponse, SearchV2ApiEvidenceRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.SearchServiceV2.evidence(ctx.params),
    {
      errorMsg: 'Failed to get evidence map!',
      // cacheKey: isDevEnv() ? 'security-graph-evidence-map':'',
    },
  );
}

function* doGetDependencyMap(
  ctx: QueryContext<InventorySearchResponse, SearchV2ApiDependencyRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.SearchServiceV2.dependency(ctx.params),
    {
      errorMsg: 'Failed to get dependency map!',
      // cacheKey: isDevEnv() ? 'security-graph-dependency-map':'',
    },
  );
}

function* doGetTable(
  ctx: QueryContext<SearchResponse, SearchApiSearchRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.search(ctx.params), {
    errorMsg: 'Failed to get full map!',
    // cacheKey: isDevEnv() ? 'security-graph-table' : '',
  });
}

function* doGetResourceRedirectMap(
  ctx: QueryContext<MapResponse, SearchApiMapRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.map(ctx.params), {
    cacheKey: isDevEnv() ? 'get resource redirect map' : '',
    cacheTime: 20 * 60 * 1000,
  });
}

function* doGetResourceRedirectTable(
  ctx: QueryContext<MapResponse, SearchApiMapRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Search.map(ctx.params), {
    cacheKey: isDevEnv() ? 'get resource redirect table' : '',
    // cacheTime: 20 * 60 * 1000,
  });
}

function* doGetAlertDetails(
  ctx: QueryContext<AlertResponse, AlertsApiGetAlertByIdRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.Alerts.getAlertById(ctx.params),
  );
}

function* doGetEc2TrafficSummary(
  ctx: QueryContext<
    NetworkTrafficOverview,
    { instanceId: string; accountId: string }
  >,
) {
  yield call(ctx.fetch, () =>
    EbpfCollector.Default.getTrafficOverview(
      ctx.params.instanceId,
      ctx.params.accountId,
    ),
  );
}
function* doGetTrafficByPorts(
  ctx: QueryContext<
    NetworkTrafficPortInfo[],
    { instanceId: string; accountId: string; limit?: number }
  >,
) {
  yield call(ctx.fetch, () =>
    EbpfCollector.Default.getNetworkTrafficByPort(
      ctx.params.instanceId,
      ctx.params.accountId,
    ),
  );
}
function* doGetTrafficByProcess(
  ctx: QueryContext<
    NetworkTrafficProcessInfo[],
    { instanceId: string; accountId: string; limit?: number }
  >,
) {
  yield call(ctx.fetch, () =>
    EbpfCollector.Default.getNwTrafficByProcess(
      ctx.params.instanceId,
      ctx.params.accountId,
    ),
  );
}
