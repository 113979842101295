import React, { FC, useEffect } from 'react';

import { Box, Button, Center, Stack } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { primaryButtonStyles } from 'components/DataEntry';

interface Props {
  onClose: () => void;
}

export const AwsOnboardingProgress: FC<Props> = props => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectToCloudAccounts = () => {
    // dispatch(actions.resetTrackOnboardingCloudAccountStatus());
    onClose();
    navigate('/setup/accounts');
  };

  //
  /*useEffect(() => {
    if (onboardingAccount.uuid) {
      const interval = setInterval(() => {
        // on onboard success redirect to cloud accounts page
        if (onboardingAccountStatus?.data?.status?.code === 'SUCCESS') {
          dispatch(actions.resetTrackOnboardingCloudAccountStatus());
          navigate('/setup/accounts/');
          return;
        }
        dispatch(
          actions.loadOnboardingCloudAccountStatus({
            account_uuid: onboardingAccount.uuid,
          }),
        );
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dispatch, history, onboardingAccount]);*/

  return (
    <Box pos={'absolute'} top={0} w={'full'} h={'full'} bg={'#fff'}>
      {/*<OnboardingProgress onClose={onClose} status={''} />*/}
      <Stack spacing={4} fontSize={18}>
        <Center>Yay!</Center>
        <Stack spacing={0}>
          <Center>We've just initiated a request in a new window.</Center>
          <Center>Once you approve our request for account access,</Center>
          <Center>we'll discover your cloud inventory.</Center>
        </Stack>
        <Center>Please click below to monitor status.</Center>
        <Center>
          <Button {...primaryButtonStyles} onClick={redirectToCloudAccounts}>
            Show Onboarding Status
          </Button>
        </Center>
      </Stack>
    </Box>
  );
};
