/**
 *
 * Okta
 *
 */

import React from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { AddOkta } from './AddOkta';
import { UpdateOkta } from './UpdateOkta';

export const Okta = () => {
  const { actionType } = useIntegrationsContext();

  return actionType === 'Add' ? <AddOkta /> : <UpdateOkta />;
};
