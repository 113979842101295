import {
  SearchResponse,
  SearchV2ApiDependencyRequest,
  SearchV2ApiEc2NetworkByUuidRequest,
  SearchV2ApiEc2SgSharedRequest,
  SearchV2ApiEvidenceRequest,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useCloudPolicyEngineSearchService() {
  const ec2NetworkByUuid = useAriksaQuery<
    SearchV2ApiEc2NetworkByUuidRequest,
    SearchResponse
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.SearchServiceV2.ec2NetworkByUuid(req).then(r => r.data),
  });

  return { ec2NetworkByUuid };
}

export function useEc2SgShared() {
  const ec2SgShared = useAriksaQuery<
    SearchV2ApiEc2SgSharedRequest,
    SearchResponse
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.SearchServiceV2.ec2SgShared(req).then(r => r.data),
  });

  return { ec2SgShared };
}

export function useEvidence() {
  const resourceEvidence = useAriksaQuery<
    SearchV2ApiEvidenceRequest,
    SearchResponse
  >({
    queryKey: ['resource-evidence'],
    fetcher: req =>
      InventoryService.SearchServiceV2.evidence(req).then(r => r.data),
  });

  return { resourceEvidence };
}

export function useDependency() {
  const resourceDependency = useAriksaQuery<
    SearchV2ApiDependencyRequest,
    SearchResponse
  >({
    queryKey: ['resource-dependency'],
    fetcher: req =>
      InventoryService.SearchServiceV2.dependency(req).then(r => r.data),
  });

  return { resourceDependency };
}
