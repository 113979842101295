import {
  CodeRepositoryApiGetRepoBranchesRequest,
  CodeRepositoryApiGetRepositoriesByUuidRequest,
  Repository,
} from '@ariksa/cloud-account/api';
import { SensitivityLevel } from '@ariksa/data-scanning';
import {
  DataClassificationApiAddDataClassificationGroupRequest,
  DataClassificationApiDeleteDataClassificationRequest,
  DataClassificationApiGetDataClassificationGroupsByFilterRequest,
  DataClassificationApiUpdateDataClassificationGroupRequest,
  DataClassificationGroupRead,
  DataClassifiers,
  DataExclusionsApiAddDataExclusionRequest,
  DataExclusionsApiDeleteDataExclusionRequest,
  DataExclusionsApiUpdateDataExclusionRequest,
  DataTypesApiAddDataTypeRequest,
  DataTypesApiDeleteDataTypeRequest,
  DataTypesApiUpdateDataTypeRequest,
  FileDataApiGetScannersRequest,
} from '@ariksa/data-scanning/api';
import {
  InsightV2ApiInsightV2AllRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  CloudAccountService,
  DataScanningService,
  InventoryService,
} from 'api/services';

import { actions } from './slice';

export function* dataClassificationSaga() {
  yield takeLatestAction(
    actions.getDataClassificationGroups,
    doGetDataClassificationGroups,
  );
  yield takeLatestAction(
    actions.getDataClassificationByID,
    doGetDataClassificationByID,
  );
  yield takeLatestAction(
    actions.enableDataClassification,
    doAddDataClassification,
  );
  yield takeLatestAction(
    actions.updateDataClassification,
    doUpdateDataClassification,
  );
  yield takeLatestAction(
    actions.deleteDataClassification,
    doDeleteDataClassification,
  );
  yield takeLatestAction(actions.getDataSources, doGetDataSources);
  yield takeLatestAction(actions.getRepoBranches, doGetRepoBranches);
  yield takeLatestAction(actions.getRepositories, doGetRepositories);
  yield takeLatestAction(actions.getScannerInstances, doGetScannerInstances);
  yield takeLatestAction(
    actions.getDataScannerInfo,
    doGetCloudAccountDataScannerInfo,
  );
  yield takeLatestAction(
    actions.getDataClassificationServices,
    doGetDataClassificationServices,
  );

  yield takeLatestAction(actions.getDataTypes, doGetDataTypes);
  yield takeLatestAction(actions.addDataType, doCreateDataType);
  yield takeLatestAction(actions.updateDataType, doUpdateDataType);
  yield takeLatestAction(actions.deleteDataType, doDeleteDataType);
  yield takeLatestAction(actions.getSensitivityInfo, doGetSensitivityInfo);

  yield takeLatestAction(actions.getDataExclusions, doGetDataExclusions);
  yield takeLatestAction(actions.addDataExclusion, doCreateDataExclusion);
  yield takeLatestAction(actions.updateDataExclusion, doUpdateDataExclusion);
  yield takeLatestAction(actions.deleteDataExclusion, doDeleteDataExclusion);
}

export function* doGetDataClassificationGroups(
  ctx: QueryContext<
    DataClassificationGroupRead[],
    DataClassificationApiGetDataClassificationGroupsByFilterRequest
  >,
) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.getDataClassificationGroupsByFilter(
      ctx.params,
    ),
  );
}

function* doGetCloudAccountDataScannerInfo(
  ctx: QueryContext<Record<string, any>, FileDataApiGetScannersRequest>,
) {
  yield call(ctx.fetch, () => DataScanningService.FileData.getScanners(), {
    errorMsg: 'Failed to get data scanner info!',
  });
}

export function* doGetDataClassificationByID(
  ctx: QueryContext<
    DataClassificationGroupRead[],
    DataClassificationApiGetDataClassificationGroupsByFilterRequest
  >,
) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.getDataClassificationGroupsByFilter(
      ctx.params,
    ),
  );
}

export function* doGetDataClassificationServices(ctx: QueryContext<string[]>) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.getAvailableServices(ctx.params),
  );
}

export function* doAddDataClassification(
  ctx: QueryContext<
    any,
    DataClassificationApiAddDataClassificationGroupRequest
  >,
) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.addDataClassificationGroup(
      ctx.params,
    ),
  );
}

export function* doUpdateDataClassification(
  ctx: QueryContext<
    any,
    DataClassificationApiUpdateDataClassificationGroupRequest
  >,
) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.updateDataClassificationGroup(
      ctx.params,
    ),
  );
}

export function* doDeleteDataClassification(
  ctx: QueryContext<any, DataClassificationApiDeleteDataClassificationRequest>,
) {
  yield call(ctx.fetch, () =>
    DataScanningService.DataClassification.deleteDataClassification(ctx.params),
  );
}

function* doGetDataSources(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2AllRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2All(ctx.params),
    {
      errorMsg: 'Failed to get data sources!',
    },
  );
}

function* doGetScannerInstances(
  ctx: QueryContext<InsightV2Pagination, InsightV2ApiInsightV2Request>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.InsightV2.insightV2(ctx.params),
    {
      errorMsg: 'Failed to get scanner instances!',
    },
  );
}

//data exclusion
export function* doGetDataExclusions(ctx: QueryContext<DataClassifiers[]>) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataExclusions.getDataExclusions(ctx.params),
    { errorMsg: 'Failed to get data exclusions!' },
  );
}

export function* doCreateDataExclusion(
  ctx: QueryContext<any, DataExclusionsApiAddDataExclusionRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataExclusions.addDataExclusion(ctx.params),
    { errorMsg: 'Failed to create data exclusion!' },
  );
}

export function* doDeleteDataExclusion(
  ctx: QueryContext<any, DataExclusionsApiDeleteDataExclusionRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataExclusions.deleteDataExclusion(ctx.params),
    { errorMsg: 'Failed to delete data exclusion!' },
  );
}

export function* doUpdateDataExclusion(
  ctx: QueryContext<any, DataExclusionsApiUpdateDataExclusionRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataExclusions.updateDataExclusion(ctx.params),
    { errorMsg: 'Failed to update data exclusion!' },
  );
}

// data types
export function* doGetDataTypes(ctx: QueryContext<DataClassifiers[]>) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.getDataTypes(ctx.params),
    { errorMsg: 'Failed to get data types!' },
  );
}

export function* doCreateDataType(
  ctx: QueryContext<any, DataTypesApiAddDataTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.addDataType(ctx.params),
    { errorMsg: 'Failed to create data type!' },
  );
}

export function* doDeleteDataType(
  ctx: QueryContext<any, DataTypesApiDeleteDataTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.deleteDataType(ctx.params),
    { errorMsg: 'Failed to delete data type!' },
  );
}

export function* doUpdateDataType(
  ctx: QueryContext<any, DataTypesApiUpdateDataTypeRequest>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.updateDataType(ctx.params),
    { errorMsg: 'Failed to update data type!' },
  );
}

export function* doGetSensitivityInfo(
  ctx: QueryContext<Record<string, SensitivityLevel>>,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.DataTypes.getDataTypesCategories(ctx.params),
    { errorMsg: 'Failed to update data types categories!' },
  );
}

export function* doGetRepoBranches(
  ctx: QueryContext<string[], CodeRepositoryApiGetRepoBranchesRequest>,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.getRepoBranches(ctx.params),
    { errorMsg: 'Failed to get branches!' },
  );
}

export function* doGetRepositories(
  ctx: QueryContext<
    Repository[],
    CodeRepositoryApiGetRepositoriesByUuidRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => CloudAccountService.CodeRepository.getRepositoriesByUuid(ctx.params),
    { errorMsg: 'Failed to get repositories!' },
  );
}
