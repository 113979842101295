import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RepoBranchIcon = props => (
  <Icon viewBox="0 0 111 112" w="full" h="full" color="inherit" {...props}>
    <g transform="translate(-193 -246)">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M87.0833 37.5936C86.3278 37.7364 85.5606 37.8096 84.7917 37.8125L84.7917 53.8542 56.1458 53.8542 56.1458 37.7541C55.7677 37.7884 55.3873 37.8125 55 37.8125 54.6127 37.8125 54.2323 37.7884 53.8542 37.7541L53.8542 53.8542 25.2083 53.8542 25.2083 37.8125C24.4394 37.8096 23.6722 37.7364 22.9167 37.5936L22.9167 56.1458 53.8542 56.1458 53.8542 72.2459C54.2323 72.2116 54.6127 72.1875 55 72.1875 55.3873 72.1875 55.7677 72.2116 56.1458 72.2459L56.1458 56.1458 87.0833 56.1458Z"
                stroke="currentColor"
                strokeWidth="1.14597"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00909 194 247)"
              />
              <path
                d="M25.2083 18.3333C29.0053 18.3333 32.0833 21.4114 32.0833 25.2083 32.0833 29.0053 29.0053 32.0833 25.2083 32.0833 21.4114 32.0833 18.3333 29.0053 18.3333 25.2083 18.3378 21.4132 21.4132 18.3378 25.2083 18.3333M25.2083 16.0417C20.1457 16.0417 16.0417 20.1457 16.0417 25.2083 16.0417 30.271 20.1457 34.375 25.2083 34.375 30.271 34.375 34.375 30.271 34.375 25.2083 34.375 20.1457 30.271 16.0417 25.2083 16.0417Z"
                stroke="currentColor"
                strokeWidth="1.14597"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00909 194 247)"
              />
              <path
                d="M55 18.3333C58.797 18.3333 61.875 21.4114 61.875 25.2083 61.875 29.0053 58.797 32.0833 55 32.0833 51.2031 32.0833 48.125 29.0053 48.125 25.2083 48.1295 21.4132 51.2049 18.3378 55 18.3333M55 16.0417C49.9374 16.0417 45.8333 20.1457 45.8333 25.2083 45.8333 30.271 49.9374 34.375 55 34.375 60.0626 34.375 64.1667 30.271 64.1667 25.2083 64.1667 20.1457 60.0626 16.0417 55 16.0417Z"
                stroke="currentColor"
                strokeWidth="1.14597"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00909 194 247)"
              />
              <path
                d="M84.7917 18.3333C88.5886 18.3333 91.6667 21.4114 91.6667 25.2083 91.6667 29.0053 88.5886 32.0833 84.7917 32.0833 80.9947 32.0833 77.9167 29.0053 77.9167 25.2083 77.9211 21.4132 80.9966 18.3378 84.7917 18.3333M84.7917 16.0417C79.729 16.0417 75.625 20.1457 75.625 25.2083 75.625 30.271 79.729 34.375 84.7917 34.375 89.8543 34.375 93.9583 30.271 93.9583 25.2083 93.9583 20.1457 89.8543 16.0417 84.7917 16.0417Z"
                stroke="currentColor"
                strokeWidth="1.14597"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00909 194 247)"
              />
              <path
                d="M55 77.9167C58.797 77.9167 61.875 80.9947 61.875 84.7917 61.875 88.5886 58.797 91.6667 55 91.6667 51.2031 91.6667 48.125 88.5886 48.125 84.7917 48.1295 80.9966 51.2049 77.9211 55 77.9167M55 75.625C49.9374 75.625 45.8333 79.729 45.8333 84.7917 45.8333 89.8543 49.9374 93.9583 55 93.9583 60.0626 93.9583 64.1667 89.8543 64.1667 84.7917 64.1667 79.729 60.0626 75.625 55 75.625Z"
                stroke="currentColor"
                strokeWidth="1.14597"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.00909 194 247)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
