import { theme } from '@chakra-ui/react';

import { colors } from './colors';

const primary = '#072AC8';
export const dark = {
  primary: primary,
  //secondary: '#8882F1',
  primaryNavBarBg: '#1f1f1f',
  secondaryNavBarBg: colors.gray['300'],

  cardBg: '#1f1f1f',
  componentBorder: colors.gray['300'],
  chartGridColor: colors.gray['300'],

  selectedMenuBg: colors.gray['400'],
  menuColor: colors.gray['50'],

  tableSelectedRowBg: colors.blue['400'],
  tableHeader: colors.gray['50'],
  tableHeaderBg: colors.gray['300'],
  tableRowBorder: colors.whiteAlpha['400'],

  edit: colors.green['300'],
  delete: colors.red['500'],
  add: primary,
  cancel: 'gray.300',
  critical: theme.colors.red['500'],
  high: 'orange',
  medium: theme.colors.orange['500'],
  low: theme.colors.yellow['500'],
  info: theme.colors.green['300'],
  hover: '#ebf8ff',
  success: theme.colors.green['300'],
  error: theme.colors.red['500'],

  formFieldBorder: colors.gray['200'],
  placeholder: colors.gray['250'],

  pieChartBorder: colors.gray['100'],
  gaugeChartBorder: colors.gray['400'],
};
