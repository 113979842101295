import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AMIIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M274.3,201.1v-128H109.7v292.6h292.6V201.1H274.3z M146.3,109.7h91.4v91.4h-91.4V109.7z M146.3,237.7h91.4
		v91.4h-91.4V237.7z M365.7,329.1h-91.4v-91.4h91.4V329.1z"
      />
      <path d="M475.4,164.6H310.9V0h164.6V164.6z M347.4,128h91.4V36.6h-91.4V128z" />
      <path
        d="M475.4,329.1h-36.6v36.6h36.6v109.7H36.6V365.7h36.6v-36.6H36.6C16.4,329.2,0,345.5,0,365.7v109.7
		C0,495.6,16.4,512,36.6,512h438.9c20.2,0,36.5-16.4,36.6-36.6V365.7C512,345.5,495.6,329.2,475.4,329.1z"
      />
      <circle cx="91.4" cy="420.6" r="18.3" />
    </g>
  </Icon>
);
