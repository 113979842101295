import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { CheckType } from '@ariksa/compliance-policies';
import { NativeResources } from '@ariksa/inventory-core';
import { AggregatedAlertDetails, AlertResponse } from '@ariksa/notification';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckAggregateCommandRemediation } from 'services/ComplianceService/Remediation';
import { useComplianceRulesService } from 'services/ComplianceService/Rules';
import { customTheme } from 'theme';
import { Optional } from 'types/utils';

import { Heading4, renderRiskContext } from 'components/DataDisplay';
import { renderCompliance } from 'components/DataDisplay/Utils/renderCompliance';
import { Select } from 'components/DataEntry';
import { CustomDayPicker } from 'components/DataEntry/Date';
import { IconTypes } from 'components/Icons';
import { TabItemProps } from 'components/Navigation';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { EnvironmentName } from 'containers/App/utils';
import { Dependencies } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Dependencies';
import { Evidence } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Evidence';
import { EvidenceControls } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/EvidenceControls/EvidenceControls';
import { AlertRemediation } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/AlertRemediation';
import { AlertActivity } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Timeline/AlertActivity';
import {
  AlertStatusOption,
  alertStatusOptions,
  AlertStatusValueContainer,
  showDependencies,
} from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/utils';
import { useAlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/hooks/useAlertStatus';
import { selectAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/selectors';
import { useAlertsContext } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertsContext';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions as findingsOverviewSlice } from 'containers/Findings/FindingsOverview/slice';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { formatDate } from 'utils/date';
import { toPascalCase } from 'utils/string';

import { actions } from '../../slice';

interface IIndividualAlertDrawerContent {
  aggregatedAlert: Optional<AggregatedAlertDetails>;
  alert: AlertResponse;
}

export const IndividualAlertDrawerContent: FC<IIndividualAlertDrawerContent> = props => {
  const { aggregatedAlert, alert } = props;
  const dispatch = useDispatch();
  const {
    individualAlertDrawerTabKey: tabKey,
    setIndividualAlertDrawerTabKey: setTabKey,
  } = useAlertsContext();
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const resourceType = alert.resource! as NativeResources;
  const { riskContext, compliance, alertsByRuleId } = useSelector(
    selectFindingsOverview,
  );
  const {
    alert: alertInfo,
    updateDueDate,
    alertStatus,
    updateAlertStatus: updateStatus,
  } = useSelector(selectAlertDrawer);
  const {
    updateAlertStatus,
    getAlertStatus,
    getAlertListStatus,
  } = useAlertStatus();

  const getAlertById = useCallback(() => {
    dispatch(
      actions.getAlertById({
        q: {
          alertId: alert.uuid!,
        },
      }),
    );
  }, [alert.uuid, dispatch]);

  const updateAlertDueDate = useCallback(
    date => {
      dispatch(
        actions.updateDueDate({
          q: {
            alertId: alert.uuid!,
            dueDate: date,
          },
          onSuccess: () => {
            getAlertById();
          },
        }),
      );
    },
    [getAlertById, alert.uuid, dispatch],
  );

  useEffect(() => {
    getAlertById();
  }, [getAlertById]);

  useEffect(() => {
    getAlertStatus(alert.uuid!);
  }, [getAlertStatus, alert]);

  useEffect(() => {
    dispatch(
      actions.getResourceInsight({
        q: {
          insightV2Request: {
            resource_type: resourceType,
            resource_uuids: [alert.entity_uuid!],
            ...(accountId
              ? { account_id: [accountId] }
              : { environment_id: environmentId }),
          },
        },
      }),
    );

    dispatch(
      findingsOverviewSlice.getRiskContext({
        q: {
          riskContextRequest: { uuids: [alert.entity_uuid!] },
        },
      }),
    );

    dispatch(
      findingsOverviewSlice.getCompliance({
        q: {
          getRuleComplianceStandardRequest: {
            rules: [alert.alert_rule_id!],
          },
        },
      }),
    );
  }, [accountId, alert, dispatch, environmentId, resourceType]);

  const {
    checkAggregateCommandRemediation,
  } = useCheckAggregateCommandRemediation({
    check_type: CheckType.Instances,
    check_names: [alert.alert_rule_id ?? ''],
  });

  const { documentsForCheck } = useComplianceRulesService();

  useEffect(() => {
    return () => {
      setTabKey('Evidence');
    };
  }, [setTabKey]);

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        title: 'Evidence',
        key: 'Evidence',
        isDisabled: alert.resource === NativeResources.EbsSnapshot,
        iconType: IconTypes.Evidence,
        component: (
          <Box h={'full'} pos={'relative'}>
            <Evidence alert={alert} />
            <EvidenceControls alert={alert} />
          </Box>
        ),
      },
      ...showDependencies(resourceType, {
        title: 'Dependencies',
        key: 'Dependencies',
        iconType: IconTypes.Dependencies,
        component: <Dependencies alert={alert} />,
      }),
      {
        title: 'Remediation',
        key: 'Remediation',
        iconType: IconTypes.Remediate,
        isDisabled: checkAggregateCommandRemediation.data?.remediation_not_available?.includes(
          alert.alert_rule_id ?? '',
        ),
        component: <AlertRemediation alert={alert} />,
      },
      {
        title: 'Timeline',
        key: 'Activity',
        iconType: IconTypes.Timeline,
        component: <AlertActivity alert={alert} />,
      },
    ],
    [
      alert,
      checkAggregateCommandRemediation.data?.remediation_not_available,
      resourceType,
    ],
  );

  const severity = useMemo(() => {
    const severity = alert.severity as any;
    if (severity === 'HIGH') {
      return {
        type: 'high',
        count: severity?.HIGH,
      };
    } else if (severity === 'MEDIUM') {
      return {
        type: 'medium',
        count: severity?.MEDIUM,
      };
    } else if (severity === 'LOW') {
      return {
        type: 'low',
        count: severity?.LOW,
      };
    } else
      return {
        type: 'critical',
        count: severity?.CRITICAL,
      };
  }, [alert.severity]);

  const onChangeAlertStatus = useCallback(
    data => {
      updateAlertStatus(alert.uuid!, data.label, () => {
        getAlertStatus(alert.uuid!);
        getAlertListStatus(alertsByRuleId.data.map(v => v.uuid ?? ''));
      });
    },
    [
      alert.uuid,
      alertsByRuleId.data,
      getAlertListStatus,
      getAlertStatus,
      updateAlertStatus,
    ],
  );

  const alertStatusValue =
    alertStatus.data[alert.uuid!]?.[0]?.ticket_status ?? 'todo';

  return (
    <Stack w="full" spacing={3} h={'full'} pb={4}>
      <HStack
        spacing={10}
        align="flex-start"
        bg={customTheme.colors.gray['50']}
        boxShadow={'0 1px 1px 0 #bbb'}
        px={2}
        py={4}
      >
        <Stack w="full" flex={8} spacing={4}>
          <Stack>
            <Heading4>Description</Heading4>
            <Box
              h={'80px'}
              borderRadius={4}
              border="1px solid"
              borderColor={customTheme.colors.gray['100']}
              p={2}
              background={'#fff'}
              overflow="auto"
            >
              {aggregatedAlert?.description}
            </Box>
          </Stack>

          <HStack w="full" align="flex-start">
            <Stack flex={1}>
              <Heading4>Severities</Heading4>
              <HStack spacing={4}>
                <HStack spacing={1}>
                  <Box w={3} h={3} borderRadius={2} bg={severity.type} />
                  <Box>{toPascalCase(severity.type)}</Box>
                </HStack>
              </HStack>
            </Stack>
            <Stack flex={1}>
              <Heading4>Created at</Heading4>
              <Text>{formatDate(alert.created_at)}</Text>
            </Stack>
            <Stack flex={1}>
              <Heading4>Last update at</Heading4>
              <Text>{formatDate(alert.updated_at)}</Text>
            </Stack>
            <Stack flex={1}>
              <Heading4>Status</Heading4>

              <Select
                components={{
                  Option: AlertStatusOption,
                  ValueContainer: AlertStatusValueContainer,
                }}
                options={alertStatusOptions}
                onChange={onChangeAlertStatus}
                value={{
                  label: alertStatusValue,
                  value: alertStatusValue,
                }}
                isLoading={updateStatus.isLoading}
              />
            </Stack>
          </HStack>
        </Stack>
        <Stack flex={4} h="full">
          <HStack h="full" align="flex-start">
            <Stack flex={1} h="full">
              <Stack flex={1}>
                <Heading4>Environment / Account</Heading4>
                <Stack h={'88px'}>
                  <EnvironmentName environmentId={alert.environment_id} />
                  <CloudAccountName accountId={alert.account_id} />
                </Stack>
              </Stack>
              <Stack flex={1} pr={4}>
                <Heading4>Due date</Heading4>

                <CustomDayPicker
                  isLoading={alertInfo.isLoading || updateDueDate.isLoading}
                  dateProps={{
                    disabled: { before: new Date() },
                    mode: 'single' as any,
                    onSelect: (selected: any) => {
                      const day = dayjs(selected).utc().format();
                      updateAlertDueDate(day);
                    },
                    selected: (alertInfo.data.due_date as String)
                      ? (dayjs(alertInfo.data.due_date).utc().local() as any)
                      : undefined,
                  }}
                />
              </Stack>
            </Stack>

            <Stack flex={1} h="full">
              <Stack flex={1}>
                <Heading4>Compliance</Heading4>
                <Text h={'88px'}>
                  {renderCompliance(
                    compliance.data?.[alert.alert_rule_id!] ?? [],
                  )}
                </Text>
              </Stack>

              <Stack flex={1}>
                <Heading4>Risk Context</Heading4>
                <Text>
                  {renderRiskContext(
                    (riskContext.data[alert.entity_uuid ?? ''] ?? []) as any,
                  )}
                </Text>
              </Stack>
            </Stack>
          </HStack>
        </Stack>
      </HStack>

      <Box flex={1}>
        <Evidence alert={alert} />
      </Box>
    </Stack>
  );
};
