import { useState } from 'react';

import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Application } from 'pixi.js';

import { AGraph } from './Graph';

export class AGraphRenderer {
  gfxEdges: Graphics;
  gfxNodes: Graphics;
  gfxNodesCtx: Graphics;
  hoverGfx: Graphics;
  activeGfx: Graphics;

  constructor(readonly app: Application, readonly graph: AGraph<any, any>) {
    this.gfxEdges = new Graphics();
    this.gfxNodes = new Graphics();
    this.gfxNodesCtx = new Graphics();
    this.hoverGfx = new Graphics();
    this.activeGfx = new Graphics();
  }

  update() {
    this.gfxEdges.clear();
    this.gfxNodes.clear();
    this.gfxNodesCtx.clear();

    this.graph.executeLayout();
    this.app.stage.removeChildren();

    this.app.stage.addChild(this.gfxEdges);
    this.app.stage.addChild(this.gfxNodes);

    this.graph.renderEdges(this.gfxEdges, this.app.stage);
    this.graph.renderNodes(this.gfxNodes, this.app.stage);

    this.graph.renderContext(this.gfxNodesCtx, this.app.stage);
    this.app.stage.addChild(this.gfxNodesCtx);
  }
}
