import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { QueryState } from 'services/utils/QueryState';

import {
  UserServiceCreateRequest,
  UserServiceGetRequest,
  UserServiceUpdateRequest,
  UserServiceDeleteRequest,
  UserServiceGetResponse,
  UserServiceGetByEmailRequest,
  UserServiceGetByEmailResponse,
  UserServiceListResponse,
  UserServiceUpdateResponse,
  NewUserParams,
} from 'api/auth/api.pb';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { PageInfo } from 'app/components';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the SetupUsers container
export const initialState: ContainerState = {
  currentRecord: {},
  allRoles: [
    'SaaS Admin',
    'Cloud Admin',
    'SaaS Admin -Read Only',
    'Cloud Admin -Read Only',
    'Org Admin',
    'AdminInfrastructure Admin',
    'Cloud Services Admin',
    'Monitoring Admin',
    'Compute Admin',
    'Data Admin',
    'Database Admin',
    'Network Admin',
    'DevOps Admin',
    'Compliance Admin',
  ],
  roles: {
    loading: false,
    error: null,
    data: [],
  },
  assignedRoles: {
    loading: false,
    error: null,
    data: [],
  },
  users: {
    loading: false,
    error: null,
    payload: [],
    pageInfo: INIT_PAGE_INFO,
    totalCount: 0,
  },
  createUser: {
    loading: false,
    error: null,
  },
  createdUserId: {
    id: '',
  },
  deleteUser: {
    loading: false,
    error: null,
  },
  updateUser: QueryState.init({}),
  userForm: {
    loading: false,
    error: null,
    data: {
      id: '',
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      roles: [],
      user_groups: [],
      account_ids: [],
      auto_add_accounts: false,
    },
  },
  activeUser: {},
};

const setupUsersSlice = createSlice({
  name: 'setupUsers',
  initialState,
  reducers: {
    changeValue(state, action: PayloadAction<any>) {
      state.currentRecord = action.payload;
    },

    // Get User by Email
    getUserByEmail(state, action: PayloadAction<UserServiceGetByEmailRequest>) {
      state.userForm.data.email = action.payload.email;
      onApiCall(state.userForm);
    },
    getUserByEmailSuccess(
      state,
      action: PayloadAction<UserServiceGetByEmailResponse>,
    ) {
      state.activeUser = action.payload.user;
      state.userForm.data = action.payload.user ?? {};
      onApiCallSuccess(state.userForm);
    },
    getUserByEmailError(state, action: PayloadAction<any>) {
      onApiCallError(state.userForm, action.payload);
      state.activeUser = {
        username: 'Unknown',
        first_name: 'Unknown',
        last_name: 'Unknown',
      };
    },

    /*Create User*/
    createUser(state, action: PayloadAction<UserServiceCreateRequest>) {
      state.userForm = initialState.userForm;
      onApiCall(state.createUser);
    },
    createUserSuccess(state, action: PayloadAction<any>) {
      state.createdUserId.id = action.payload.id;
      onApiCallSuccess(state.createUser);
    },
    createUserError(state, action: PayloadAction<any>) {
      onApiCallError(state.createUser, action.payload);
    },

    /*Delete User */
    deleteUser(state, action: PayloadAction<UserServiceDeleteRequest>) {
      onApiCall(state.deleteUser);
    },
    deleteUserSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.deleteUser);
    },
    deleteUserError(state, action: PayloadAction<any>) {
      onApiCallError(state.deleteUser, action.payload);
    },

    /*Update User*/

    updateUserForm(state, action: PayloadAction<NewUserParams>) {
      state.userForm.data = { ...state.userForm.data, ...action.payload };
    },
    updateUser(
      state,
      action: QueryAction<UserServiceUpdateResponse, UserServiceUpdateRequest>,
    ) {
      state.updateUser = QueryState.next(state.updateUser, action);
    },

    getUserById(state, action: PayloadAction<UserServiceGetRequest>) {
      state.userForm.data.id = action.payload.id;
      onApiCall(state.userForm);
    },
    getUserByIdSuccess(state, action: PayloadAction<UserServiceGetResponse>) {
      state.activeUser = action.payload.user;
      state.userForm.data = action.payload.user!;

      // state.userForm.data = {
      //   username,
      //   email,
      //   name: { firstName: first_name, lastName: last_name },
      //   roles: roles.map(r => ({ name: r.name!, id: r.id! })),
      //   userGroups: user_groups.map(r => ({ name: r.name!, id: r.id! })),
      //   accounts: account_ids,
      // };
      onApiCallSuccess(state.userForm);
    },
    getUserByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.userForm, action.payload);
    },

    /*Get Users*/
    loadUsers(state) {
      state.users.payload = [];
      onApiCall(state.users);
    },
    loadUsersSuccess(state, action: PayloadAction<UserServiceListResponse>) {
      state.users.payload = action.payload.users || [];
      state.users.totalCount = 100;
      onApiCallSuccess(state.users);
    },
    loadUsersError(state, action: PayloadAction<any>) {
      state.users.payload = [];
      onApiCallError(state.users, action.payload);
    },
    updateUserPageInfo(state, action: PayloadAction<PageInfo>) {
      state.users.pageInfo = action.payload;
    },

    // reset user form
    resetUserForm(state, action: PayloadAction<any>) {
      state.userForm = initialState.userForm;
    },
  },
});

export const { actions, reducer, name: sliceKey } = setupUsersSlice;
