import React, { FC, useCallback, useEffect } from 'react';

import { Center, Stack } from '@chakra-ui/react';
import { min } from 'lodash';
import { Application } from 'pixi.js';
import { MdCenterFocusWeak } from 'react-icons/all';
import { useDispatch } from 'react-redux';

import { PrimaryIconButton } from 'components/DataEntry';
import {
  OptionsIcon,
  ServicesIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'components/Icons';
import { SecurityGraphStack } from 'components/Icons/ReactResourceIcons/SecurityGraphStack';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

interface IGraphScale {
  app: Application;
  graph: AGraph<AGraphNode, AGraphEdge>;

  toggleVulnerability?();

  showVulnerability?: boolean;
}

export const GraphScale: FC<IGraphScale> = props => {
  const { app, graph, showVulnerability, toggleVulnerability } = props;
  const dispatch = useDispatch();

  const handleZoomOut = useCallback(() => {
    const scale = Math.max(app.stage.scale.y - 0.1, 0.2);
    app.stage.scale.set(scale);
  }, [app.stage.scale]);

  const handleZoomIn = useCallback(() => {
    const scale = Math.min(app.stage.scale.y + 0.1, 1.2);
    app.stage.scale.set(scale);
  }, [app.stage.scale]);

  const handleFocusGraph = useCallback(() => {
    const { nodes } = graph;
    const minX = min(nodes.map(n => n.x - n.w / 2));
    const maxY = min(nodes.map(n => n.y - n.h / 2));
    app.stage.x = -(minX ?? 0);
    app.stage.y = -(maxY ?? 0);
  }, [app.stage, graph]);

  return (
    <Stack pos={'absolute'} bottom={4} left={4} justify={'center'}>
      <Center mb={4}>
        <PrimaryIconButton
          icon={<SecurityGraphStack />}
          borderRadius={'50%'}
          aria-label={'focus'}
          onClick={() => toggleVulnerability?.()}
          size={'sm'}
          bg={showVulnerability ? 'primary' : 'white'}
          color={showVulnerability ? 'white' : 'primary'}
        />
      </Center>

      <Center>
        <PrimaryIconButton
          icon={<MdCenterFocusWeak />}
          borderRadius={'50%'}
          aria-label={'focus'}
          onClick={handleFocusGraph}
          size={'sm'}
        />
      </Center>
      <Center>
        <PrimaryIconButton
          icon={<ZoomOutIcon />}
          borderRadius={'50%'}
          aria-label={'zoom-out'}
          onClick={handleZoomOut}
          size={'sm'}
        />
      </Center>
      <Center>
        <PrimaryIconButton
          icon={<ZoomInIcon />}
          borderRadius={'50%'}
          aria-label={'zoom-in'}
          onClick={handleZoomIn}
          size={'md'}
        />
      </Center>
    </Stack>
  );
};
