import { ResourceMapping } from '@ariksa/inventory-core/api';

export const getResourceType = (
  name: string,
  resourceTypes: ResourceMapping[],
) => {
  return resourceTypes?.find(item => {
    return (
      item.cloud_native_class === name || item.cloud_agnostic_class === name
    );
  });
};
