import React, { useCallback, useMemo, useState } from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { SearchResponseLevel } from '@ariksa/inventory-core';
import { ContextType, NativeResources } from '@ariksa/inventory-core/api';
import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  Center,
  Flex,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { map, toLower, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorOpacity, customTheme } from 'theme';
import { Dict } from 'types/utils';

import {
  formatBytes,
  getIcon,
  getProviderName,
  renderRiskContext,
  renderSeverityBubble,
  renderTableHeaderWithLoader,
  renderTime,
  renderTimeStackedCell,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { ActionButton } from 'components/DataEntry';
import {
  AutomationIcon,
  DataScanIcon,
  GraphIcon,
  IconTypes,
  ListIcon,
  NotificationIcon,
  SummaryIcon,
} from 'components/Icons';
import { ContextLabelIcon } from 'components/Icons/ReactCustomIcons/ContextLabelIcon';
import { IMenuItemProps, TableActionsMenu } from 'components/Overlay';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { renderCloudResourceCount } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  ResourceTypeIconTooltip,
  StackedResourceType,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { EnvironmentName } from 'containers/App/utils';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { NotificationResource } from 'containers/Findings/Alerts/types';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import {
  renderDataScanned,
  renderObjectsScanned,
} from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassifcationTable/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import {
  renderDataTypeLabels,
  renderResources,
} from 'containers/Visibility/Data/Components/hooks/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const useDataTableColumns = tabType => {
  const navigate = useNavigate();
  const { selectedTab } = useSelector(selectDataDashboard);
  const { riskContext } = useSelector(selectSharedState);
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const { getTabIndex } = useMetadataDrawerTabs();
  const { getResourceAlias } = useResourceType();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const {
    toCloudAccountName,
    toCloudAccountId,
    accountMapping,
  } = useCloudAccountId();
  const { environmentId } = useAccessBoundary();

  const [currentRow, setCurrentRow] = useState<Dict<any> | null>(null);

  const handleRowClick = useCallback(
    (r, showAccessGraph = false) => {
      let resourceType: NativeResources = r?.source_type;
      if (r?.source_type === SupportedServices.GitLabBranch)
        resourceType = NativeResources.GitLabProject;
      else if (r?.source_type === SupportedServices.GitHubBranch)
        resourceType = NativeResources.GitHubRepository;
      else if (r?.source_type === SupportedServices.BitBucketBranch)
        resourceType = NativeResources.BitBucketRepository;

      updateActiveResource({
        resourceType,
        uuid: r?.extra?.repository_uuid ?? r?.source_UUID,
        accountId: r?.account_id,
        resourceId: r?.extra?.repository_resource_id ?? r?.resource_id,
        tabIndex: showAccessGraph ? getTabIndex(resourceType, tabType) ?? 0 : 0,
      });
      onOpenMetadata();
    },
    [onOpenMetadata, updateActiveResource, getTabIndex, tabType],
  );

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    render: ({ row }) => (
      <WithResourceIcon
        resourceType={row?.source_type}
        iconSize="sm"
        iconTooltip={
          <ResourceTypeIconTooltip resourceType={row?.source_type} />
        }
      >
        <StackedCell
          upper={row?.source}
          lower={getResourceAlias(row?.source_type)}
        />
      </WithResourceIcon>
    ),
  };

  const resources = {
    header: 'Sensitive Records',
    render: renderResources,
    align: 'left',
    //styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const owner = {
    header: 'Owner',
    accessor: 'owner',
    render: ({ value }) => {
      if (!value) return '-';
      const val = !value ? '-' : value?.replace('+', ', ');
      return <CustomTooltip label={val}>{val}</CustomTooltip>;
    },
    align: 'left',
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const cloud = {
    header: 'Cloud',
    //align: 'left',
    render: ({ row }) => (
      <StackedCell
        upper={
          <Center w="full">
            <CustomTooltip
              label={getProviderName(toLower(row?.cloud))}
              alignItems="center"
            >
              <Center boxSize={4}>{getIcon(toLower(row?.cloud))}</Center>
            </CustomTooltip>
          </Center>
        }
        lower={
          <CustomTooltip
            label={
              <Stack>
                <HStack>
                  <Box color="orange">Name: </Box>
                  <Box>{toCloudAccountName(row?.account_id)}</Box>
                </HStack>
                <HStack>
                  <Box color="orange">Account ID: </Box>
                  <Box>{toCloudAccountId(row?.account_id)}</Box>
                </HStack>
              </Stack>
            }
          >
            {toCloudAccountName(row?.account_id)}
          </CustomTooltip>
        }
        showUpperTooltip={false}
        showLowerTooltip={false}
      />
    ),
  };

  const sensitiveData = {
    header: <Box pl="thLeftPaddingWithIcon">Data Type</Box>,
    accessor: 'sensitive_data_name',
    render: ({ value }) => (
      <WithResourceIcon
        resourceType={IconTypes.SensitiveData}
        bgColor="sensitiveDataBg"
        useCustomColor
      >
        {value}
      </WithResourceIcon>
    ),
  };

  const sensitivity = {
    header: 'Sensitivity',
    accessor: '',
    render: ({ row }) => (
      <StackedCell
        upper={row?.sensitivity_category}
        lower={renderSeverityBubble({ value: row?.sensitivity_level })}
        upperTooltip={false}
        lowerTooltip={false}
      />
    ),
    align: 'left',
  };

  const noOfOccurrences = {
    header: 'Occurrences',
    render: ({ row }) =>
      formatNumber(row?.number_of_occurrences ?? row?.occurrences),
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const cloudSourceCount = {
    header: 'Cloud',
    render: ({ row }) =>
      renderCloudResourceCount(
        map(row?.clouds, (count, key) => ({
          count,
          name: key,
          cloud: key,
        })),
        'Sources count',
      ),
    align: 'left',
  };

  const renderActions = useCallback(
    ({ row }) => {
      const commonMenuItems = [
        {
          label: 'Create ticket',
          icon: <AutomationIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            ticketModal.onOpen();
          },
        },
        {
          label: 'Send notification',
          icon: <NotificationIcon />,
          onClick: e => {
            e.stopPropagation();
            setCurrentRow(row);
            notificationModal.onOpen();
          },
        },
      ];
      let menuItems: IMenuItemProps[] = [];

      if (selectedTab === IconTypes.DataSources) {
        menuItems = [
          {
            label: 'Show security graph',
            icon: <GraphIcon />,
            onClick: e => {
              const { source_type, account_id, resource_id, source_UUID } = row;
              const url = securityGraphRedirectUrl({
                account_id,
                source_id: resource_id,
                resource_id: resource_id,
                resource_type: getDataResourceType(source_type),
                source_resource_uuid: source_UUID,
                map_type: 'identity',
                source_tab: MetadataTabs.Data,
                response_level: SearchResponseLevel.Compact,
              });
              navigate(url, { state: row });
              e.stopPropagation();
            },
          },
          {
            label: 'Show details',
            icon: <SummaryIcon />,
            onClick: e => {
              e.stopPropagation();
              handleRowClick(row, false);
            },
          },
          ...commonMenuItems,
          {
            label: 'Edit context',
            icon: <ContextLabelIcon />,
            onClick: e => {
              e.stopPropagation();
              handleRowClick(row, false);
            },
          },
          {
            label: 'Enable classification',
            icon: <DataScanIcon />,
            isDisabled: true,
            onClick: e => {
              e.stopPropagation();
              //handleRowClick(row, false);
            },
          },
        ];
      } else {
        menuItems = [
          {
            label: 'Show all sources',
            icon: <ListIcon />,
            onClick: e => {
              e.stopPropagation();
              // handleRowClick(row, true);
            },
          },
          ...commonMenuItems,
        ];
      }

      return (
        <TableActionsMenu menuItems={menuItems} styles={{ item: { h: 8 } }} />
      );
    },
    [handleRowClick, notificationModal, selectedTab, ticketModal, history],
  );

  const actions = {
    header: 'Action',
    render: renderActions,
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  };

  const renderRiskContextTooltip = (label, values) => (
    <HStack spacing={2}>
      <Box>{label}:</Box>
      <Flex color="orange">{values?.join(', ')}</Flex>
    </HStack>
  );

  const context = {
    header: renderTableHeaderWithLoader('Risk Context', riskContext.isLoading),
    align: 'left',
    render: ({ row }) => {
      return renderRiskContext(riskContext?.data?.[row?.source_UUID], {
        [ContextType.SensitiveData]: (
          <Stack>
            <Box>{ContextType.SensitiveData}</Box>
            {renderRiskContextTooltip(
              'Categories',
              row?.sensitivity_categories,
            )}
            {renderRiskContextTooltip(
              'Labels',
              row?.source_labels ?? row?.labels,
            )}
          </Stack>
        ),
      });
    },
    styles: { width: '100px', cell: { maxWidth: '100px' } },
  };

  const labels = {
    header: 'Labels',
    render: renderDataTypeLabels,
    align: 'left',
  };

  const dataSources = [
    name,
    //dataSourcesSensitiveData,
    cloud,
    {
      header: 'Deployed In',
      accessor: 'region',
      align: 'left',
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    resources,
    owner,
    context,
    labels,
    actions,
  ];

  const dataTypes = [
    sensitiveData,
    cloudSourceCount,
    labels,
    {
      header: 'Regions',
      render: ({ row }) => row?.source_regions?.length ?? 0,
    },
    noOfOccurrences,
    sensitivity,
  ];

  const commonDocumentTypeColumns = [
    {
      header: 'Count',
      accessor: 'count',
      render: ({ value }) => formatNumber(value),
    },
    {
      header: 'Cloud',
      accessor: 'clouds',
      align: 'left',
      render: ({ value }) => renderCloudIcons(value),
    },
    {
      header: 'Sources',
      accessor: 'sources',
      render: ({ value }) => formatNumber(value),
    },
    {
      header: 'Found In',
      accessor: 'accounts',
      render: ({ value }) => value + ' account' + (value === 1 ? '' : 's'),
      align: 'left',
    },
    {
      header: 'Regions',
      accessor: 'regions',
      render: ({ value }) => value + ' region' + (value === 1 ? '' : 's'),
      align: 'left',
    },
    {
      header: 'Data Labels',
      accessor: 'labels',
      render: renderDataTypeLabels,
      align: 'left',
    },
  ];
  const documentTypes = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Type</Box>,
      accessor: 'type_name',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.Policy}
          bgColor="skyBlue.300"
          useCustomColor
        >
          {value}
        </WithResourceIcon>
      ),
    },
    ...commonDocumentTypeColumns,
  ];
  const documentSubTypes = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Sub-Type</Box>,
      accessor: 'subtype_name',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.Policy}
          bgColor={colorOpacity(customTheme.colors.primary, 0.4)}
          useCustomColor
        >
          {value}
        </WithResourceIcon>
      ),
    },
    ...commonDocumentTypeColumns,
  ];
  const dataTypeSearchResultColumns = [
    { header: 'Data', accessor: 'pii_data' },
    cloud,
    {
      header: 'Environment',
      render: ({ row }) => (
        <EnvironmentName environmentId={environmentId ?? ''} />
      ),
      align: 'left',
    },
    {
      header: 'Resource',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={row.resource}
          lower={getResourceAlias(row?.resource_type)}
        />
      ),
    },
    { header: 'Occurrence', accessor: 'occurrences' },
    { header: 'Region', accessor: 'region' },
  ];
  const dataTypeSourcesColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.source_type}
          iconSize="sm"
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.source_type} />
          }
        >
          <StackedCell
            upper={row?.source ?? row?.resource_id}
            lower={getResourceAlias(row?.source_type)}
          />
        </WithResourceIcon>
      ),
    },
    cloud,
    {
      header: 'Environment',
      align: 'left',
      render: () => <EnvironmentName environmentId={environmentId ?? ''} />,
      styles: { width: '180px', cell: { maxWidth: '180px' } },
    },
    {
      header: 'Deployed In',
      accessor: 'source_region',
      align: 'left',
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    noOfOccurrences,
    owner,
    context,
    labels,
    {
      header: 'Action',
      render: ({ row }) => (
        <Center>
          <ActionButton
            label="Create ticket to remediate"
            icon={<AutomationIcon />}
            onClick={() => {
              setCurrentRow(row);
              ticketModal.onOpen();
            }}
          />
          <ActionButton
            label="Send notification"
            icon={<NotificationIcon />}
            onClick={() => {
              setCurrentRow(row);
              notificationModal.onOpen();
            }}
          />
        </Center>
      ),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];
  const dataSourcesByDocTypeColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Data Source</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.source_type}
          iconSize="sm"
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.source_type} />
          }
        >
          <StackedCell
            upper={row?.source ?? row?.resource_id}
            lower={getResourceAlias(row?.source_type)}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Count',
      accessor: 'count',
      render: ({ value }) => formatNumber(value),
    },
    {
      header: 'Cloud',
      accessor: 'cloud',
      align: 'left',
      render: ({ value }) => renderCloudIcons([value]),
    },
    {
      header: 'Deployed In',
      accessor: 'region',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={toCloudAccountName(row?.account)}
          lower={row?.region}
        />
      ),
      //styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    labels,
    owner,
    {
      header: 'Action',
      render: ({ row }) => (
        <Center>
          <ActionButton
            label="Create ticket to remediate"
            icon={<AutomationIcon />}
            onClick={() => {
              setCurrentRow(row);
              ticketModal.onOpen();
            }}
          />
          <ActionButton
            label="Send notification"
            icon={<NotificationIcon />}
            onClick={() => {
              setCurrentRow(row);
              notificationModal.onOpen();
            }}
          />
        </Center>
      ),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];
  const dataSourcesByResourceTypeColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Data Source</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.native_name}
          iconSize="sm"
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_name} />
          }
        >
          <StackedCell upper={row?.name} lower={row?.resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Deployed In',
      accessor: 'region',
      align: 'left',
      render: ({ row }) => (
        <StackedCell upper={row?.region} lower={row?.scanner_id} />
      ),
      //styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Included',
      accessor: 'count',
      //render: ({ value }) => formatNumber(value),
    },
    {
      header: 'Objects',
      align: 'left',
      render: renderObjectsScanned,
    },
    {
      header: 'Data Scanned',
      align: 'left',
      render: renderDataScanned,
    },
    {
      header: 'Last Scanned At',
      accessor: 'last_scanned',
      align: 'left',
      render: renderTimeStackedCell,
    },
    {
      header: 'Added At',
      align: 'left',
      accessor: 'created_at',
      render: renderTimeStackedCell,
    },
    {
      header: 'Sensitive Data',
      align: 'left',
      accessor: 'labels',
      render: renderDataTypeLabels,
    },
  ];
  const allDataSourcesColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Resource Type</Box>,
      render: ({ row }) => (
        <StackedResourceType resourceType={row?.native_resource} />
      ),
    },
    {
      header: 'Cloud',
      accessor: 'account_ids',
      render: ({ value }) => {
        return renderCloudIcons(
          uniq(map(value, o => accountMapping[o]?.cloud_type)),
        );
      },
      align: 'left',
    },
    {
      header: 'Total Sources',
      accessor: 'total',
    },
    {
      header: 'Accounts',
      accessor: 'account_ids',
      render: ({ value }) =>
        value?.length + ' account' + (value?.length === 1 ? '' : 's'),
      align: 'left',
    },
    {
      header: 'Regions',
      accessor: 'regions',
      render: ({ value }) =>
        value?.length + ' region' + (value?.length === 1 ? '' : 's'),
      align: 'left',
    },
  ];

  const fileColumns = [
    {
      header: 'filename path',
      accessor: 'file_full_path',
      render: ({ value }) => (
        <WithResourceIcon resourceType="Policy">{value}</WithResourceIcon>
      ),
    },
    {
      header: 'size',
      accessor: 'file_size',
      render: ({ value }) => formatBytes(value),
      align: 'left',
    },
    {
      header: 'Owner',
      accessor: 'source_owner',
      align: 'left',
    },
    {
      header: 'Last Update',
      accessor: 'last_modified',
      render: renderTime,
      align: 'left',
    },
  ];

  let tableColumns =
    selectedTab === IconTypes.DataSources
      ? dataSources
      : selectedTab === 'DocumentTypes'
      ? documentTypes
      : selectedTab === 'all'
      ? allDataSourcesColumns
      : dataTypes;

  const getDataResourceType = useCallback(
    (source_type?: string) => {
      const type =
        source_type ?? currentRow?.service ?? currentRow?.source_type;
      switch (type) {
        case SupportedServices.GitLabBranch:
          return NativeResources.GitLabProject;
        case SupportedServices.BitBucketBranch:
          return NativeResources.BitBucketRepository;
        case SupportedServices.GitHubBranch:
          return NativeResources.GitHubRepository;
        default:
          return type;
      }
    },
    [currentRow],
  );

  const tableActionModals = useMemo(() => {
    const alert: NotificationResource = {
      entity_uuid: currentRow?.source_UUID ?? currentRow?.source_id,
      entity_id: currentRow?.resource_id,
      entity_type: getDataResourceType(),
      account_id: currentRow?.account_id ?? currentRow?.account,
      resource: getDataResourceType(),
      entity_name: currentRow?.source_name ?? currentRow?.source,
    };

    return (
      <>
        {currentRow && (
          <CreateTicket
            onClose={ticketModal.onClose}
            isOpen={ticketModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Data}
          />
        )}
        {currentRow && (
          <CreateNotification
            onClose={notificationModal.onClose}
            isOpen={notificationModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Data}
          />
        )}
      </>
    );
  }, [
    currentRow,
    notificationModal.isOpen,
    notificationModal.onClose,
    ticketModal.isOpen,
    ticketModal.onClose,
    getDataResourceType,
  ]);

  return {
    tableColumns,
    handleRowClick,
    tableActionModals,
    dataSourcesColumns: dataSources,
    dataTypeSourcesColumns,
    dataTypeSearchResultColumns,
    fileColumns,
    documentTypes,
    documentSubTypeColumns: documentSubTypes,
    dataSourcesByDocTypeColumns,
    dataSourcesByResourceTypeColumns,
  };
};
