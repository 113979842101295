import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { EntityType } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';

import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceAccessPattern } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ResourceAccessPattern';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

// conditionally render ResourceAccessPatternAccordion based on resourceType
export const ResourceAccessPatternAccordion = () => {
  const { resourceType } = useSelector(selectActiveResource);
  const { accessed } = useSelector(selectActiveResource);
  const entityType = mapToEntityType(resourceType as NativeResources);

  if (
    !ResourcesWithAccessPattern.includes(resourceType as NativeResources) ||
    !entityType
  )
    return null;

  return (
    <MetaAccordionItem
      iconType={IconTypes.DataScan}
      label={'ACTIONS PERFORMED BY THIS IDENTITY DURING LAST 30 DAYS'}
      onClickDownload={() => {}}
      panel={<ResourceAccessPattern resourceType={entityType!} />}
      iconBGColor="primary"
      height={'450px'}
      isLoading={accessed.isLoading}
    />
  );
};

const ResourcesWithAccessPattern: NativeResources[] = [
  NativeResources.IamUser,
  NativeResources.IamRole,
];

const mapToEntityType = (resourceType: NativeResources) => {
  switch (resourceType) {
    case NativeResources.IamUser:
      return EntityType.IamUser;
    case NativeResources.IamRole:
      return EntityType.Role;
  }

  console.error(
    'ResourceAccessPatternAccordion: Unknown resource type',
    resourceType,
  );
};
