/**
 *
 * Elastic For SIEM Integration
 *
 */

import React, { FC } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions as clientActions } from '../../slice';

import { ElasticForSIEMForm } from './ElasticForSIEMForm';

export const AddElasticForSIEM: FC = () => {
  const dispatch = useDispatch();
  const { onCancel } = useIntegrationsContext();

  const onSubmit = data => {
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        name: data.name,
        client_name: Clients.Elasticsearch,
        status: true,
        description: data?.description,
        elastic_search: {
          url: data.url,
          api_key: data.api_Key,
        },
      },
    };
    dispatch(
      clientActions.createClient({
        q: payload,
        onSuccess: () => onCancel(),
      }),
    );
  };

  return <ElasticForSIEMForm onSubmit={onSubmit} />;
};
