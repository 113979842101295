import React, { FC, useEffect } from 'react';

import { EntityTimelineActions } from '@ariksa/notification/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { renderTime, Timeline } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { BellIcon } from 'components/Icons';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

// interface AlertsTimelineProps {}
export const AlertsTimeline: FC = () => {
  const { entityTimeline } = useSelector(selectActiveResource);
  const { isLoading, data } = entityTimeline;

  const { getEntityTimeline } = useActiveResourceActions();

  useEffect(() => {
    getEntityTimeline();
  }, [getEntityTimeline]);

  const TimelineCard = props => {
    return (
      <Stack spacing={1}>
        <HStack justify="space-between">
          <Box fontSize={16} fontWeight="lg">
            {startCase(props.action)} {props.action_entity_type}
          </Box>
          <Box color={customTheme.colors.gray['300']}>
            {renderTime({ value: props.created_at })}
          </Box>
        </HStack>
        <Box>{props.entity_description}</Box>
        <Box>{props.created_by}</Box>
      </Stack>
    );
  };

  const getIconColor = type => {
    let color = 'primary';
    switch (type) {
      case EntityTimelineActions.Rediscovered:
        color = 'red';
        break;
      case EntityTimelineActions.Resolved:
        color = 'green';
        break;
    }
    return color;
  };

  return (
    <WithSpinner loadStatus={{ loading: isLoading }}>
      <Timeline
        isLoading={isLoading}
        steps={map(
          sortBy(data, d => -dayjs(d.created_at).unix()),
          (each, index) => ({
            component: <TimelineCard {...each} key={`${index}-timeline`} />,
            icon: <BellIcon />,
            styles: { iconContainer: { bg: getIconColor(each.action) } },
          }),
        )}
      />
    </WithSpinner>
  );
};
