import React, { FC } from 'react';

import { Box, Center, Spinner } from '@chakra-ui/react';
import { customTheme } from 'theme';

export interface IAbsoluteSpinner {
  isLoading?: boolean;
  envelop?: boolean;
}

export const AbsoluteSpinner: FC<IAbsoluteSpinner> = props => {
  const { isLoading, envelop } = props;
  return (
    <>
      {isLoading && (
        <Center
          pos={'absolute'}
          h={'full'}
          w={'full'}
          top={0}
          left={0}
          m={'0!important'}
          className={'123123'}
        >
          <Box
            pos={'absolute'}
            opacity={envelop ? 1 : 0.4}
            h={'full'}
            w={'full'}
            bg={envelop ? '#fff' : customTheme.colors.gray['100']}
            borderRadius={4}
            top={0}
            left={0}
          />
          <Spinner size={'md'} color={'primary'} />
        </Center>
      )}
    </>
  );
};
