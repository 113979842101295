import React, { FC, useEffect, useState } from 'react';

import { AccessSummaryTypes } from '@ariksa/inventory-core';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Box, Center, useDisclosure } from '@chakra-ui/react';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import { Dict } from 'types/utils';

import { StackedCell, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, PolicyIcon } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceAccessPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access/ResourceAccessPatternAccordion';
import { renderHighPrivilegesStackedCell } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useRedirectToGraph } from 'containers/ActiveCloudResource/hooks/useRedirectToGraph';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { PolicyDocumentModal } from 'containers/Components';
import { usePolicyDocumentModal } from 'containers/Components/PolicyDocumentModal/usePolicyDocumentModal';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

export interface Props {}

export const CanAccess: FC<Props> = () => {
  const {
    canAccessSummary,
    resourceType,
    resourceUuid,
    resourceAccountId,
  } = useSelector(selectActiveResource);
  const { canAccess } = canAccessSummary;
  const { getCanAccessSummary } = useActiveResourceActions();
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();
  const { roleTrustedEntities } = useInventoryInsightService();
  const { redirectToGraph } = useRedirectToGraph();

  const [internal, setInternal] = useState<Dict<any>[]>([]);
  const [external, setExternal] = useState<Dict<any>[]>([]);
  const [expandedGraph, setExpandedGraph] = useState(false);

  const policyDocumentModal = usePolicyDocumentModal();

  useEffect(() => {
    getCanAccessSummary(AccessSummaryTypes.CanAccess);
  }, [getCanAccessSummary]);

  useEffect(() => {
    resourceType === NativeResources.IamRole &&
      roleTrustedEntities.sync({
        uuid: resourceUuid,
        accountId: resourceAccountId,
      });
  }, [roleTrustedEntities, resourceUuid, resourceAccountId, resourceType]);

  useEffect(() => {
    setInternal(filter(canAccess.data, o => !o?.external));
    setExternal(filter(canAccess.data, o => o?.external));
  }, [canAccess.data]);

  const handleExpandMap = () => {
    setExpandedGraph(true);
  };
  const handleCollapseMap = () => {
    setExpandedGraph(false);
  };

  const name = {
    header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
    accessor: 'permission_entity_name',
    render: ({ row, value }) => (
      <WithResourceIcon resourceType={row?.permission_entity_native_name}>
        <StackedCell
          upper={value}
          lower={
            getCloudNativeName(row?.permission_entity_native_name) +
            ' / ' +
            getCloudAgnosticName(row?.permission_entity_agnostic_name)
          }
        />
      </WithResourceIcon>
    ),
  };

  const managedType = {
    header: 'Type',
    accessor: 'permission_entity_managed_type',
    align: 'left',
  };
  const highPrivileges_service = [
    {
      header: 'High Privileges',
      accessor: 'crud_level_counts_high',
      render: renderHighPrivilegesStackedCell,
    },
    {
      header: 'Services',
      accessor: 'total_service_count',
      align: 'left',
    },
  ];

  const internalColumns = [
    name,
    managedType,
    ...highPrivileges_service,

    {
      header: '# Excess / # Permissions',
      align: 'left',
      render: ({ row }) =>
        (row?.excess_permission_count >= 0
          ? row?.excess_permission_count
          : 'NA') +
        ' / ' +
        (row?.total_permission_count ?? 0),
    },
    {
      header: 'Last Use',
      render: () => {
        return '-';
      },
    },
    {
      header: 'Action',
      align: 'center',
      render: ({ row }) => {
        return (
          <Center>
            <ActionButton
              icon={<PolicyIcon />}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                policyDocumentModal
                  .setParams({
                    uuid: row?.permission_entity_uuid,
                    account: row?.permission_entity_account,
                    nativeType: row?.permission_entity_native_name,
                  })
                  .onOpen();
              }}
            />
          </Center>
        );
      },
    },
  ];

  const externalColumns = [
    name,
    managedType,
    {
      header: 'Account',
      accessor: 'permission_entity_account',
      render: ({ value }) => <CloudAccountName accountId={value} />,
      align: 'left',
    },
    ...highPrivileges_service,
    {
      header: '# Permissions',
      accessor: 'total_permission_count',
      align: 'left',
    },
    {
      header: 'Action',
      align: 'center',
      render: () => {
        return (
          <Center>
            <ActionButton
              icon={<PolicyIcon />}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </Center>
        );
      },
    },
  ];

  const trustedEntitiesColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      render: ({ row, value }) => (
        <WithResourceIcon resourceType={row?.entity_type}>
          <StackedCell
            upper={value}
            lower={
              row?.entity_type + ' / ' + getCloudAgnosticName(row?.entity_type)
            }
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Type',
      accessor: '',
      align: 'left',
    },
    {
      header: 'Environment',
      accessor: '',
      align: 'left',
    },
    {
      header: 'Usage',
      accessor: 'usage_count',
      align: 'left',
    },
    {
      header: 'Last Timestamp',
      accessor: '',
      align: 'left',
    },
  ];

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <ResourceAccessPatternAccordion />
      <MetaAccordionItem
        iconType={IconTypes.Permission}
        label="PRIVILEGES FOR THIS IDENTITY TO MANAGE INTERNAL RESOURCES"
        totalCount={internal?.length}
        onClickDownload={() => {}}
        isLoading={canAccess.isLoading}
        panel={
          <Table
            columns={internalColumns}
            data={internal}
            isLoading={canAccess.isLoading}
          />
        }
        iconBGColor="primary"
        onShowGraph={() => redirectToGraph('access', MetadataTabs.Access)}
      />
      <MetaAccordionItem
        iconType={IconTypes.Permission}
        label="PRIVILEGES FOR THIS IDENTITY TO MANAGE EXTERNAL RESOURCES"
        totalCount={external?.length}
        isLoading={canAccess.isLoading}
        onClickDownload={() => {}}
        panel={
          <Table
            columns={externalColumns}
            data={external}
            isLoading={canAccess.isLoading}
          />
        }
        iconBGColor="low"
        onShowGraph={() => redirectToGraph('access', MetadataTabs.Access)}
      />
      {resourceType === NativeResources.IamRole && (
        <MetaAccordionItem
          iconType={IconTypes.TrustedEntities}
          label="TRUSTED ENTITIES"
          totalCount={roleTrustedEntities?.data?.length}
          onClickDownload={() => {}}
          isLoading={roleTrustedEntities.isLoading}
          panel={
            <Table
              columns={trustedEntitiesColumns}
              data={roleTrustedEntities.data}
              isLoading={roleTrustedEntities.isLoading}
            />
          }
          iconBGColor="primary"
          onShowGraph={() => redirectToGraph('access', MetadataTabs.Access)}
        />
      )}
      {policyDocumentModal.component}
    </Accordion>
  );
};
