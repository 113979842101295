import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { actions } from 'containers/Setup/Environment/slice';

import { ContextWizard } from './index';

interface Props {}

export const CreateContext: FC<Props> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetFormData());
  }, [dispatch]);

  return <ContextWizard actionType="Create" />;
};
