import React, { ReactNode } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { usePageContentContext } from 'components/Layout';

interface Props {
  content: ReactNode;
  label: ReactNode;
  icon: ReactNode;
  height?: string | number;
  titleBgColor?: string;
  iconBgColor?: string;
  titleFontColor?: string;
}

export const DashboardOverviewWrapperCard: React.FC<Props> = props => {
  const {
    label,
    content,
    icon,
    height,
    titleBgColor = colorOpacity(customTheme.colors.primary, 0.2),
    iconBgColor = 'primary',
    titleFontColor = 'primary',
  } = props;
  const { contentHeight } = usePageContentContext();
  return (
    <Stack
      h={height || contentHeight + 'px'}
      minH={height || '1050px'}
      maxH={height || '1050px'}
      pb={2}
    >
      <Box color="primary" bg={titleBgColor} py={2} px={3} borderRadius={6}>
        <PageHeaderWithIcon
          label={label}
          icon={icon}
          fontSize="sm"
          reversed
          border
          iconSize="md"
          borderColor={customTheme.colors.gray['250']}
          useCustomColor
          iconBgColor={iconBgColor}
          iconStyles={{ borderRadius: 12 }}
          fontColor={titleFontColor}
        />
      </Box>
      <Box flex={1} h="full" py={2} px={0}>
        {content}
      </Box>
    </Stack>
  );
};
