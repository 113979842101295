import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { MetadataCommonFields } from '../../../Components/MetadataCommonFields';
import {
  AvailabilityZone,
  Description,
  SubnetID,
  VPCId,
} from '../../../Components/MetadataField/MetaGridFields';

export const VirtualInterfaceMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(3, 1fr)" gap={4}>
        <MetadataCommonFields />
        {Description(resource)}
        {VPCId(insight)}
        {SubnetID(resource)}
        {AvailabilityZone(resource)}
      </Grid>
    </Stack>
  );
};
