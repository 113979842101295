import React from 'react';

import { Icon } from '@chakra-ui/react';
export const ContainerRepositoryIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M503.5,0H8.5C3.4,0,0,3.4,0,8.5v42.7V256v42.7v204.8c0,5.1,3.4,8.5,8.5,8.5h494.9c5.1,0,8.5-3.4,8.5-8.5V298.7
		V256V51.2V8.5C512,3.4,508.6,0,503.5,0z M17.1,290.1v-25.6h477.9v25.6H17.1z M162.1,307.2v187.7H93.9V307.2H162.1z M179.2,119.5
		V59.7h68.3v59.7h17.1V59.7h68.3v59.7h17.1V59.7h68.3v187.7h-68.3v-42.7h-17.1v42.7h-68.3v-42.7h-17.1v42.7h-68.3v-42.7h-17.1v42.7
		H93.9V59.7h68.3v59.7H179.2z M494.9,247.5h-59.7V59.7h59.7V247.5z M76.8,247.5H17.1V59.7h59.7V247.5z M17.1,17.1h477.9v25.6H17.1
		V17.1z M17.1,307.2h59.7v187.7H17.1V307.2z M494.9,494.9h-59.7v-34.1h-17.1v34.1h-68.3v-34.1h-17.1v34.1h-68.3v-34.1h-17.1v34.1
		h-68.3V307.2h68.3v68.3h17.1v-68.3h68.3v68.3h17.1v-68.3h68.3v68.3h17.1v-68.3h59.7V494.9z"
      />
      <rect x="247.5" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="281.6" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="315.7" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="349.9" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="384" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="418.1" y="392.5" className="st0" width="17.1" height="17.1" />
      <rect x="247.5" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="281.6" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="315.7" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="349.9" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="384" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="418.1" y="426.7" className="st0" width="17.1" height="17.1" />
      <rect x="162.1" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="196.3" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="230.4" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="264.5" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="298.7" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="332.8" y="136.5" className="st0" width="17.1" height="17.1" />
      <rect x="162.1" y="170.7" className="st0" width="17.1" height="17.1" />
      <rect x="196.3" y="170.7" className="st0" width="17.1" height="17.1" />
      <rect x="230.4" y="170.7" className="st0" width="17.1" height="17.1" />
      <rect x="264.5" y="170.7" className="st0" width="17.1" height="17.1" />
      <rect x="298.7" y="170.7" className="st0" width="17.1" height="17.1" />
      <rect x="332.8" y="170.7" className="st0" width="17.1" height="17.1" />
    </g>
  </Icon>
);
