import { FileDataApiGetScannersRequest } from '@ariksa/data-scanning/api';
import {
  ConfigApiGetOutpostConfigRequest,
  ConfigData,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  ResourceApiGetZonesByRegionForAccountRequest,
} from '@ariksa/inventory-core/api';
import { ScannerInfo } from '@ariksa/scan-analysis/api';
import { each } from 'lodash';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the CloudAccountWizard container

// @ts-ignore
export const initialState: ContainerState = {
  dataOutposts: QueryState.init([]),
  vulnerabilityOutposts: QueryState.init([]),
  outpostActions: QueryState.init([]),
  resourceDetails: QueryState.init([]),
  resources: {},
  outpostToken: QueryState.init({}),
  zones: QueryState.init([]),
  outpostConfig: QueryState.init({}),
};

const outpostSlice = createSlice({
  name: 'outpost',
  initialState,
  reducers: {
    /*get vulnerability scanner*/
    getVulnerabilityScanners(
      state,
      action: QueryAction<Record<string, ScannerInfo[]>>,
    ) {
      state.vulnerabilityOutposts = QueryState.next(
        state.vulnerabilityOutposts,
        action,
      );
    },

    /*get data scanners*/
    getDataScanners(
      state,
      action: QueryAction<Record<string, any>, FileDataApiGetScannersRequest>,
    ) {
      state.dataOutposts = QueryState.next(state.dataOutposts, action);
    },

    /*get resource details*/
    getResourceDetails(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.resourceDetails = QueryState.next(state.resourceDetails, action, {
        mapData: r => {
          let items: Record<string, any> = {};
          each(r.items, o => (items[o?.resource_id] = o));
          return items;
        },
      });
    },

    generateOutpostToken(state, action: QueryAction<any>) {
      state.outpostToken = QueryState.next(state.outpostToken, action);
    },

    /*get zones*/
    getZones(
      state,
      action: QueryAction<
        string[],
        ResourceApiGetZonesByRegionForAccountRequest
      >,
    ) {
      state.zones = QueryState.next(state.zones, action);
    },

    getOutpostConfig(
      state,
      action: QueryAction<ConfigData, ConfigApiGetOutpostConfigRequest>,
    ) {
      state.outpostConfig = QueryState.next(state.outpostConfig, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = outpostSlice;
