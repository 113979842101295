import React, { FC } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { Box, UseDisclosureProps } from '@chakra-ui/react';
import { noop } from 'lodash';

import { Drawer, usePortalRef } from 'components/Overlay';
import { AlertDrawerHeader } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/AlertDrawerHeader';
import { AlertActivity } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Timeline/AlertActivity';

interface ITimelineModal {
  state: UseDisclosureProps;
  alert: AlertResponse;
}

export const TimelineModal: FC<ITimelineModal> = props => {
  const { state, alert } = props;
  const { isOpen = false, onClose = noop } = state;
  const containerRef = usePortalRef();
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={<AlertDrawerHeader alert={alert} />}
      body={
        <Box h="full" w="full" pt={3}>
          <AlertActivity alert={alert} />
        </Box>
      }
      closeButton
      styles={{
        drawer: {
          size: 'lg',
          portalProps: { containerRef: containerRef as any },
        },
      }}
    />
  );
};
