/**
 *
 * Teams Integration
 *
 */

import React, { memo } from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { CreateTeams } from './CreateTeams';
import { EditTeams } from './EditTeams';
import { TeamsTable } from './TeamsTable';

export const Teams = memo(() => {
  const { isOpen, actionType } = useIntegrationsContext();

  return isOpen ? (
    actionType === 'Add' ? (
      <CreateTeams />
    ) : (
      <EditTeams />
    )
  ) : (
    <TeamsTable />
  );
});
