import React, { ReactNode } from 'react';

import { Box, Center, useDisclosure, Spinner } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { TrashIcon } from 'components/Icons';

import {
  DeleteConfirmationModal,
  DeleteConfirmationModalProps,
} from '../../Overlay';

import {
  actionButtonHoverStyles,
  actionButtonStyles,
  tooltipProps,
} from './styles';

export interface Props
  extends Pick<
    DeleteConfirmationModalProps,
    | 'header'
    | 'confirmationText'
    | 'name'
    | 'type'
    | 'onConfirm'
    | 'body'
    | 'footer'
  > {
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: ReactNode | string;
}

export const DeleteActionButton = (props: Props) => {
  const {
    isDisabled = false,
    isLoading = false,
    label = 'Delete',
    ...rest
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnClick = () => {
    if (!isDisabled) {
      onOpen();
    }
  };

  return (
    <Box>
      <CustomTooltip label={label || 'Delete'} {...tooltipProps}>
        <Center
          {...actionButtonStyles}
          opacity={isDisabled ? 0.4 : 1}
          color={'red'}
          _hover={{ ...actionButtonHoverStyles(isDisabled) }}
          onClick={handleOnClick}
        >
          {isLoading ? <Spinner size="xs" color="primary" /> : <TrashIcon />}
        </Center>
      </CustomTooltip>
      {isOpen && (
        <DeleteConfirmationModal
          label={label}
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isLoading}
          {...rest}
        />
      )}
    </Box>
  );
};
