import React from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

import { WithResourceIcon } from './WithResourceIcon';

export const renderNameWithResourceIcon = (
  resourceType: string,
  value: string | React.ReactNode,
  tooltip?: string | React.ReactNode,
) => {
  return (
    <WithResourceIcon resourceType={resourceType}>
      <Stack spacing={1}>
        <CustomTooltip label={<Box>{tooltip ?? value}</Box>}>
          {value ?? '-'}
        </CustomTooltip>
      </Stack>
    </WithResourceIcon>
  );
};
