import { customTheme } from 'theme';

import { LayoutStyles } from './types';

export const defaultStyles: LayoutStyles = {
  outerWrapper: {
    px: 0,
    py: 0,
    mx: 'auto',
    pos: 'relative' as any,
    // overflow: 'auto',
    //maxW: '7xl',
    w: 'full',
    h: 'full',
  },
  innerWrapper: {
    spacing: customTheme.sizes.interCardSpacing,
    // pb: customTheme.sizes.interCardSpacing,
    w: 'full',
    h: 'full',
  },
};

export const collapseIconWrapperStyles = {
  pos: 'absolute' as any,
  top: 4,
  color: 'white',
  bg: 'primary',
  w: '26px',
  h: '26px',
  zIndex: 900,
  borderRadius: '50%',
  cursor: 'pointer',
  border: '1px solid',
  borderColor: 'componentBorder',

  _hover: {
    border: '1px solid',
    borderColor: 'primary',
    bg: 'primary',
    color: 'white',
  },
};
