import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DocumentDbClusterIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M136.4,168.2l-48.1-52.5c-1.7-1.9-4.2-3-6.7-3c-2.6,0-5,1.1-6.7,3l-48.1,52.5c-33.3,36.3-35.4,91.4-4.9,130.1
		l50.7,64.5v24.3h18.3v-24.3l50.7-64.5C171.7,259.6,169.6,204.5,136.4,168.2z M72.3,333.2L36,287.1c-24.9-31.7-23.2-76.8,4-106.5
		l32.2-35.2V333.2z M126.9,287.1l-36.3,46.2V145.4l32.2,35.2C150.1,210.3,151.8,255.4,126.9,287.1z"
      />
      <path
        d="M231.4,39.2l-34-37.1c-1.2-1.3-3-2.1-4.8-2.1c-1.8,0-3.5,0.8-4.8,2.1l-34,37.1c-23.5,25.7-25,64.6-3.5,92
		l35.8,45.6v17.2h12.9v-17.2l35.8-45.6C256.4,103.8,254.9,64.9,231.4,39.2z M186.2,155.8l-25.6-32.6c-17.6-22.4-16.4-54.2,2.9-75.2
		l22.8-24.9V155.8z M224.8,123.2l-25.6,32.6V23.1l22.8,24.9C241.1,68.9,242.3,100.8,224.8,123.2z"
      />
      <path
        d="M490.1,332.4l-39.5-43.1c-1.4-1.6-3.4-2.4-5.5-2.4c-2.1,0-4.1,0.9-5.5,2.4l-39.5,43.1
		c-27.3,29.8-29,75-4.1,106.7l41.6,52.9V512h15v-19.9l41.6-52.9C519.2,407.4,517.5,362.2,490.1,332.4z M437.6,467.8l-29.8-37.9
		c-20.4-26-19-63,3.3-87.3l26.4-28.9V467.8z M482.4,429.9l-29.8,37.9V313.7l26.4,28.9C501.4,367,502.8,403.9,482.4,429.9z"
      />
      <path
        d="M220.7,336.6l-34-37.1c-1.2-1.3-3-2.1-4.8-2.1c-1.8,0-3.5,0.8-4.8,2.1l-34,37.1c-23.5,25.7-25,64.6-3.5,92
		l35.8,45.6v17.2h12.9v-17.2l35.8-45.6C245.7,401.2,244.3,362.3,220.7,336.6z M175.5,453.2l-25.6-32.6
		c-17.6-22.4-16.4-54.2,2.9-75.2l22.8-24.9V453.2z M214.1,420.6l-25.6,32.6V320.5l22.8,24.9C230.5,366.4,231.7,398.2,214.1,420.6z"
      />
      <path
        d="M481.9,50.7L440.9,6c-1.5-1.6-3.6-2.5-5.7-2.5c-2.2,0-4.3,0.9-5.7,2.5l-41,44.7
		c-28.3,30.9-30.1,77.8-4.2,110.7l43.1,54.9V237H443v-20.7l43.1-54.9C512,128.5,510.2,81.6,481.9,50.7z M427.4,191.1l-30.9-39.3
		c-21.2-27-19.7-65.3,3.4-90.6l27.4-29.9V191.1z M473.9,151.8L443,191.1V31.3l27.4,29.9C493.6,86.5,495.1,124.9,473.9,151.8z"
      />
      <path
        d="M359.9,183.2l-51.9-56.6c-1.9-2-4.5-3.2-7.3-3.2c-2.8,0-5.4,1.2-7.3,3.2l-51.9,56.6
		c-35.9,39.2-38.2,98.6-5.3,140.3l54.6,69.6v26.2h19.7v-26.2l54.6-69.6C398,281.7,395.8,222.3,359.9,183.2z M290.8,361.1l-39.1-49.8
		c-26.9-34.2-25-82.8,4.4-114.8l34.8-37.9V361.1z M349.7,311.3l-39.1,49.8V158.6l34.8,37.9C374.7,228.5,376.5,277.1,349.7,311.3z"
      />
    </g>
  </Icon>
);
