import React from 'react';

import { Heading, Flex, HeadingProps } from '@chakra-ui/react';

export function H1({ children, ...rest }) {
  return (
    <H fontSize="xl" {...rest}>
      {children}
    </H>
  );
}

export function H2({ children, ...rest }) {
  return (
    <H fontSize="lg" {...rest}>
      {children}
    </H>
  );
}

export function H3({ children, ...rest }) {
  return (
    <H fontSize="md" fontWeight="bold" w="full" {...rest}>
      {children}
    </H>
  );
}

export function H4({ children, ...rest }) {
  return (
    <H fontSize="sm" {...rest}>
      {children}
    </H>
  );
}

export function H5({ children, ...rest }) {
  return (
    <H fontSize="xs" {...rest}>
      {children}
    </H>
  );
}

export function H({ children, ...rest }) {
  return (
    <Heading fontSize="xs" fontWeight="bold" display={'inline-block'} {...rest}>
      {children}
    </Heading>
  );
}

export const FormHeading = (props: HeadingProps) => (
  <Heading size="md" {...props} />
);

export const Heading1 = (props: HeadingProps) => (
  <Heading fontSize="xl" {...props} />
);

export const Heading2 = (props: HeadingProps) => (
  <Heading fontSize="lg" {...props} />
);

export const Heading3 = (props: HeadingProps) => (
  <Heading fontSize="md" {...props} />
);

export const Heading4 = (props: HeadingProps) => (
  <Heading fontSize="sm" {...props} />
);

export const Heading5 = (props: HeadingProps) => (
  <Heading fontSize="xs" {...props} />
);
