import React, { FC, useCallback, useEffect, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useLocation } from 'react-use';

import { severityOptionsWithAll } from 'components/DataDisplay/Utils/utils';
import { cloudProviderOptionsWithAll, getIcon, Select } from 'components/index';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import {
  overDueFilterOptions,
  ticketStatusOptions,
} from 'containers/Findings/Tickets/Components/utils';
import { TicketsSearchParamsProps } from 'containers/Findings/Tickets/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

export const TicketFilters: FC = () => {
  const dispatch = useDispatch();
  const { alertResourceTypes } = useSelector(selectSharedState);
  const {
    getResourceAlias,
    getAlertResourceTypeOptionsWithAll,
  } = useResourceType();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useSearchParams<TicketsSearchParamsProps>();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cloud: cloudProviderOptionsWithAll[0],
      severity: severityOptionsWithAll[0],
      status: ticketStatusOptions[0],
      resourceType: {},
      resourceTypeOptions: [],
      isOverDueDate: false,
    },
  );

  //set parameters from localstorage to url
  useDeepCompareEffect(() => {
    const status = localStorage.getItem('findings_tickets_status');
    const severity = localStorage.getItem('findings_tickets_severity');
    const resourceType = localStorage.getItem('findings_tickets_resource_type');
    const cloud = localStorage.getItem('findings_tickets_cloud');
    const isOverDueDate = localStorage.getItem(
      'findings_tickets_is_over_due_date',
    );
    let searchParams = '';
    const setValue = (f, v) => {
      searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
    };

    if (!!status) setValue('status', status);
    if (!!cloud) setValue('cloud', cloud);
    if (!!severity) setValue('severity', severity);
    if (!!resourceType) setValue('resourceType', resourceType);
    if (!!isOverDueDate) setValue('isOverDueDate', isOverDueDate);
    if (!!searchParams) navigate(location.pathname + '?' + searchParams);
  }, [history, location.pathname]);

  const updateValueInURL = useCallback(
    (field, value) => {
      let searchParams = '';
      const setValue = (f, v) => {
        searchParams =
          searchParams +
          (!!searchParams ? '&' : '') +
          f +
          '=' +
          (f === field ? value : v);
      };
      if (!!params.status || field === 'status') {
        setValue('status', params.status);
      }
      if (!!params.cloud || field === 'cloud') {
        setValue('cloud', params.cloud);
      }
      if (!!params.severity || field === 'severity') {
        setValue('severity', params.severity);
      }
      if (!!params.resourceType || field === 'resourceType') {
        setValue('resourceType', params.resourceType);
      }
      if (!!params.isOverDueDate || field === 'isOverDueDate') {
        setValue('isOverDueDate', params.isOverDueDate);
      }
      navigate(
        location.pathname + (!!searchParams ? '?' : '') + searchParams,
      );
    },
    [
      location.pathname,
      history,
      params.status,
      params.severity,
      params.cloud,
      params.resourceType,
      params.isOverDueDate,
    ],
  );

  //set ticket status
  useEffect(() => {
    const status = params?.status;
    updateState({
      status:
        find(ticketStatusOptions, o => o.value === status) ??
        ticketStatusOptions[0],
    });
  }, [dispatch, params.status]);

  //set resource type options
  useEffect(() => {
    const options = getAlertResourceTypeOptionsWithAll(alertResourceTypes.data);
    updateState({
      resourceTypeOptions: options,
    });
  }, [alertResourceTypes.data, getAlertResourceTypeOptionsWithAll]);

  //set resource type
  useEffect(() => {
    const resourceType = !!params?.resourceType
      ? {
          label: getResourceAlias(params?.resourceType),
          value: params?.resourceType,
          icon: getIcon(params?.resourceType),
        }
      : state.resourceTypeOptions?.[0];
    updateState({
      resourceType,
    });
  }, [
    dispatch,
    params.resourceType,
    getResourceAlias,
    state.resourceTypeOptions,
  ]);

  //set severity
  useEffect(() => {
    const severity = params?.severity?.toLowerCase();
    updateState({
      severity:
        find(severityOptionsWithAll, o => o.value === severity) ??
        severityOptionsWithAll[0],
    });
  }, [dispatch, params.severity]);

  //set severity
  useEffect(() => {
    const cloud = params?.cloud;
    updateState({
      cloud:
        find(cloudProviderOptionsWithAll, o => o.value === cloud) ??
        cloudProviderOptionsWithAll[0],
    });
  }, [dispatch, params.cloud]);

  //set is over sla
  useEffect(() => {
    const isOverDueDate = params?.isOverDueDate === 'true';
    updateState({
      isOverDueDate: isOverDueDate
        ? overDueFilterOptions[0]
        : overDueFilterOptions[1],
    });
  }, [dispatch, params.isOverDueDate]);

  //push value to local storage and set url params
  const updateValue = useCallback(
    (field, value, localStorageFieldName) => {
      localStorage.setItem(localStorageFieldName, !!value ? value : '');
      updateValueInURL(field, !!value ? value : '');
    },
    [updateValueInURL],
  );

  return (
    <>
      <Box w="180px" zIndex={900}>
        <Select
          options={cloudProviderOptionsWithAll}
          value={state.cloud}
          onChange={s =>
            updateValue('cloud', s?.value, 'findings_tickets_cloud')
          }
          showIconInValueContainer
          styles={{
            menu: provided => ({
              ...provided,
              width: 'max-content',
              minWidth: '100%',
            }),
          }}
        />
      </Box>
      <Box w="180px" zIndex={900}>
        <Select
          options={ticketStatusOptions}
          value={state.status}
          onChange={s =>
            updateValue('status', s?.value, 'findings_tickets_status')
          }
          showIconInValueContainer
        />
      </Box>
      <Box w={48} zIndex={900}>
        <Select
          options={severityOptionsWithAll}
          value={state.severity}
          onChange={s =>
            updateValue(
              'severity',
              s?.value?.toUpperCase(),
              'findings_tickets_severity',
            )
          }
          showIconInValueContainer
        />
      </Box>
      <Box w="200px" zIndex={900}>
        <Select
          options={overDueFilterOptions}
          value={state?.isOverDueDate}
          onChange={s =>
            updateValue(
              'isOverDueDate',
              s?.value,
              'findings_tickets_is_over_due_date',
            )
          }
          showIconInValueContainer
        />
      </Box>
      <Box w="200px" zIndex={900}>
        <Select
          options={state.resourceTypeOptions}
          value={state.resourceType}
          onChange={s =>
            updateValue(
              'resourceType',
              s?.value,
              'findings_tickets_resource_type',
            )
          }
          showIconInValueContainer
          styles={{
            menu: provided => ({
              ...provided,
              width: 'max-content',
              minWidth: '100%',
              right: 0,
            }),
          }}
        />
      </Box>
      {/*<SelectHistoricalOptions
        historicalTime={startDate}
        setHistoricalTime={s => {
          dispatch(actions.updateStartDate(s));
        }}
        isDisabled={
          aggregatedFindings.isLoading ||
          alerts.isLoading ||
          aiParamExtraction.isLoading
        }
        width={48}
      />*/}
    </>
  );
};
