import React, { FC } from 'react';

import { Box, Button, Stack, HStack } from '@chakra-ui/react';
import { map, omit } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { useAlertWorkflowWizardContext } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowWizardContext';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';
import { deleteIconStyles } from 'containers/PolicyHub/AlertWorkflow/styles';

import { TagKeyValueComponent } from './TagKeyValueComponent';

interface Props {}

export const TagKeyValueCondition: FC<Props> = props => {
  const { tagKeyOptions } = useAlertWorkflowWizardContext();
  const { tagKeyValues } = useSelector(selectAlertWorkflow);
  const dispatch = useDispatch();

  /*useEffect(() => {
    !isEmpty(tagKeyValues) &&
    dispatch(
      actions.up({
        field: 'tags',
        value: map(tagKeyValues, o => o),
      }),
    );
  }, [tagKeyValues, dispatch]);*/

  return (
    <Stack spacing={4} w="full">
      <Box>
        <PrimaryButton
          onClick={() => {
            const id = nanoid();
            dispatch(
              actions.addTagKeyValue({
                id,
                tag_key: tagKeyOptions?.[0]?.value,
              }),
            );
          }}
          size="xs"
        >
          + Add
        </PrimaryButton>
      </Box>
      {map(Object.keys?.(tagKeyValues)?.reverse(), id => (
        <Box
          border="1px solid"
          py={2}
          pl={2}
          borderRadius={6}
          borderColor="componentBorder"
          key={id + 'tag-key-value'}
          w="full"
        >
          <HStack align="start">
            <TagKeyValueComponent id={id} />
            <Button
              {...deleteIconStyles}
              onClick={() => {
                dispatch(actions.updateTagKeyValues(omit(tagKeyValues, id)));
              }}
            >
              <TrashIcon />
            </Button>
          </HStack>
        </Box>
      ))}
    </Stack>
  );
};
