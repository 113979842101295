import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AggregatedAlertDetails } from '@ariksa/notification';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { filter, isEmpty } from 'lodash';
import map from 'lodash/map';
import { IQueryState } from 'services/utils/QueryState';
import { Optional } from 'types/utils';

import {
  renderRiskContext,
  renderSeverityBar,
  renderTimeStackedCell,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { EnvironmentName } from 'containers/App/utils';
import { AlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertStatus';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { limitedString, toTitleCase } from 'utils/string';

export const renderAlertsStatusHeader = () => (
  <CustomTooltip
    label={
      <Stack>
        <HStack justify={'center'}>
          <Text>Status</Text>
        </HStack>
        <HStack>
          <Text color="orange">To do</Text>
          <Text>- Issue raised</Text>
        </HStack>
        <HStack>
          <Text color="orange">Open</Text>
          <Text>- Ticket created</Text>
        </HStack>
        <HStack>
          <Text color="orange">In progress </Text>
          <Text>- Ticket WIP</Text>
        </HStack>
      </Stack>
    }
  >
    Status
  </CustomTooltip>
);

export const renderAlertsStatus = tickets => {
  const visibleTickets = filter(tickets, t => {
    return t.action !== 'slack';
  });

  const actions = visibleTickets?.map(t => (
    <Text>{toTitleCase(t.action)}</Text>
  ));
  return isEmpty(visibleTickets) ? (
    <AlertStatus status={'todo'} />
  ) : (
    <CustomTooltip
      label={
        <Stack spacing={1}>
          {map(visibleTickets, (ticket, k) => (
            <AlertStatus status={toTitleCase(ticket.ticket_status ?? 'todo')} />
          ))}
        </Stack>
      }
    >
      <Stack spacing={1}>
        {map(visibleTickets, (ticket, k) => (
          <AlertStatus status={toTitleCase(ticket.ticket_status ?? 'todo')} />
        ))}
        <HStack>{actions}</HStack>
      </Stack>
    </CustomTooltip>
  );
};

export function getAlertColumns(
  aggregatedAlert: Optional<AggregatedAlertDetails>,
  alertStatus: IQueryState<Record<string, any>>,
  riskContext: IQueryState<Record<string, any>>,
): TableColumnProps[] {
  const resourceType: NativeResources | string =
    aggregatedAlert?.resource_types?.[0] ?? '';
  const multiResource = aggregatedAlert?.multi_resource ?? false;

  const entityName = {
    header: <Box pl="thLeftPaddingWithIcon">Entity Name</Box>,
    accessor: 'entity_name',
    sortKey: 'entity_name',
    align: 'left',
    render: ({ row }) => {
      return (
        <WithResourceIcon resourceType={row.resource}>
          <StackedCell
            upper={row.entity_name ?? row.ariksa_name ?? ''}
            lower={row.entity_id}
          />
        </WithResourceIcon>
      );
    },
  };

  const workflowStatus = {
    header: renderAlertsStatusHeader(),
    accessor: 'workflow_status',
    align: 'left',
    render: ({ row }) => {
      const tickets = alertStatus?.data?.[row?.uuid];
      return renderAlertsStatus(tickets);
    },
  };

  const riskContextView = {
    header: 'Risk Context',
    accessor: 'risk_context',
    align: 'left',
    render: ({ row }) => {
      const context = riskContext.data?.[row.entity_uuid] ?? [];
      return renderRiskContext(context);
    },
  };

  const totalAlerts = {
    header: 'Total Alerts',
    align: 'left',
    render: ({ row }) => 1, //alertStatus?.[row?.uuid]?.alerts_on_resource,
  };

  const pii = {
    header: 'PII',
    accessor: 'pii',
    align: 'left',
    render: ({ row }) => {
      return row?.extras?.pii ? 'Yes' : 'No';
    },
  };

  const ports = {
    header: 'Port(s)',
    align: 'left',
    render: ({ row }) => {
      const ports = row.extras?.ports ?? [];
      return (
        <CustomTooltip
          label={
            <Stack>
              <Box>
                {row.extras.traffic_state
                  ? 'Ingress traffic received on port(s)'
                  : 'No ingress traffic on port(s)'}
              </Box>
              {ports.map(v => <Box>{v}</Box>) ?? ''}
            </Stack>
          }
        >
          {limitedString(ports.join(', '))}
        </CustomTooltip>
      );
    },
  };

  const severity = {
    header: 'Severity',
    accessor: 'severity',
    align: 'left',
    sortKey: 'severity',
    render: ({ value }) => renderSeverityBar({ value, isInline: false }),
  };

  const createdAt = {
    header: 'Created At',
    accessor: 'created_at',
    align: 'left',
    render: renderTimeStackedCell,
  };

  const traffic = {
    header: 'Traffic',
    render: ({ row }) => (
      <StackedCell
        upper={row.extras.traffic_type}
        lower={row.extras.traffic_state ? 'Yes' : 'No'}
      />
    ),
    align: 'left',
  };

  const deployedIn = {
    header: 'Deployed In',
    accessor: 'account_id',
    sortKey: 'account_id',
    align: 'left',
    render: ({ value, row }) => (
      <StackedCell
        upper={<EnvironmentName environmentId={row.environment_id} />}
        lower={<CloudAccountName accountId={value} />}
      />
    ),
  };

  let columns = [
    entityName,
    deployedIn,
    severity,
    workflowStatus,
    createdAt,
    totalAlerts,
  ];

  switch (resourceType) {
    case NativeResources.Ec2Instance:
      if (!multiResource) {
        return [
          entityName,
          severity,
          deployedIn,
          traffic,
          ports,
          workflowStatus,
          createdAt,
          riskContextView,
        ];
      }
  }

  if (!multiResource) {
    return [
      entityName,
      severity,
      deployedIn,
      workflowStatus,
      createdAt,
      riskContextView,
    ];
  }

  return columns;
}
