import React, { FC, useCallback, useEffect, useReducer } from 'react';

import { StandardRuleCompliance } from '@ariksa/compliance-policies/api';
import {
  Box,
  Stack,
  HStack,
  Center,
  useDisclosure,
  Text,
  UseDisclosureProps,
  Checkbox,
} from '@chakra-ui/react';
import { filter, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  renderSeverityBar,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ActionButton, Select } from 'components/DataEntry';
import { PolicyExceptionIcon, TagIcon } from 'components/Icons';
import { ConfirmationModal, DeleteConfirmationModal } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useBlueprintPolicyColumns } from 'containers/PolicyHub/Blueprints/hooks/useBlueprintPolicyColumns';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { EditPolicyContext } from 'containers/PolicyHub/Components/EditPolicyContext';
import { SeverityListOptions } from 'containers/Findings/Alerts/utils';

interface Props {
  subControl: StandardRuleCompliance;
  addPolicy?: UseDisclosureProps;
  selectedPolicies?: string[];
  setSelectedPolicies?(value: string[]);
  action?: 'Create' | 'Show' | 'Update';
}

export const PoliciesUnderSubControl: FC<Props> = props => {
  const {
    subControl,
    addPolicy,
    selectedPolicies,
    setSelectedPolicies,
    action,
  } = props;
  const {
    blueprint,
    policiesUnderSubControls: policies,
    updateRules,
  } = useSelector(selectBlueprints);
  const excludePolicy = useDisclosure();
  const includePolicy = useDisclosure();
  const showResources = useDisclosure();
  const editContext = useDisclosure();
  const dispatch = useDispatch();
  const { context } = useBlueprintPolicyColumns();
  const {
    getCloudNativeName,
    getCloudAgnosticName,
    getResourceTypeOptions,
  } = useResourceType();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceType: {},
      severity: { label: 'All Severity', value: undefined },
      severityOptions: [
        { label: 'All Severity', value: undefined },
        ...SeverityListOptions,
      ],
      resourceTypeOptions: [],
      currentRecord: {},
      rules: [],
      selectedPolicies: [],
    },
  );

  useEffect(() => {
    updateState({ rules: map(policies.data, o => o.rule_id) });
  }, [policies.data]);

  useEffect(() => {
    updateState({
      selectedPolicies: filter(selectedPolicies, o => includes(state.rules, o)),
    });
  }, [selectedPolicies, state.rules]);

  //set resource type options
  useEffect(() => {
    const options = [
      { label: 'All Resource Types', value: undefined },
      ...getResourceTypeOptions(),
    ];
    updateState({
      resourceTypeOptions: options,
      resourceType: options[0],
    });
  }, [getResourceTypeOptions]);

  const getPolicies = useCallback(() => {
    dispatch(
      actions.getPoliciesUnderSubControls({
        q: {
          blueprintId: blueprint.data.id,
          standardId: subControl.id,
          page: policies.page.info.page_number,
          size: policies.page.info.page_size,
          severity: state.severity?.value,
          resourceType: state.resourceType?.value,
        },
      }),
    );
  }, [
    blueprint.data,
    subControl,
    policies.page.info,
    dispatch,
    state.severity,
    state.resourceType,
  ]);

  useEffect(() => {
    getPolicies();
  }, [getPolicies]);

  const handleExcludeRule = () => {
    dispatch(
      actions.excludeRules({
        q: {
          blueprintId: blueprint.data?.id!,
          ruleInstanceMultiCreate: {
            rules: [{ rule_id: state.currentRecord.rule_id }],
          },
        },
        onSuccess: () => {
          excludePolicy.onClose?.();
          getPolicies();
        },
      }),
    );
  };

  /*const handleIncludeRule = () => {
    if (blueprint.data.id)
      dispatch(
        actions.excludeRules({
          q: {
            blueprintId: blueprint.data?.id!,
            ruleInstanceMultiCreate: {
              rules: [{ rule_id: state.currentRecord.id }],
            },
          },
          onSuccess: () => {
            excludePolicy.onClose?.();
            getPolicies();
          },
        }),
      );
  };*/

  const columns = [
    {
      header: (
        <HStack spacing={0}>
          {action === 'Create' && (
            <Checkbox
              isChecked={state.rules?.length === state.selectedPolicies?.length}
              isIndeterminate={
                !!state.selectedPolicies?.length &&
                state.rules?.length !== state.selectedPolicies?.length
              }
              onChange={e =>
                e.target.checked
                  ? setSelectedPolicies?.(map(state.rules, o => o.rule_id!))
                  : setSelectedPolicies?.(
                      filter(
                        selectedPolicies,
                        o => !includes(state.selectedPolicies, o),
                      ),
                    )
              }
            />
          )}
          <Box pl="thLeftPaddingWithIcon">Description</Box>
        </HStack>
      ),
      accessor: 'description',
      render: ({ value, row }) => (
        <HStack spacing={2} w="full">
          {action === 'Create' && (
            <Checkbox
              isChecked={includes(selectedPolicies, row.rule_id)}
              onChange={e => {
                if (!e.target.checked) {
                  setSelectedPolicies?.([
                    ...(selectedPolicies ?? []),
                    row.rule_id,
                  ]);
                } else {
                  setSelectedPolicies?.(
                    filter(selectedPolicies, a => a !== row.rule_id),
                  );
                }
              }}
            />
          )}
          {action === 'Create' && (
            <CustomTooltip label={value}>
              <Box pl={8} w="full" overflow="hidden" textOverflow="ellipsis">
                {value}
              </Box>
            </CustomTooltip>
          )}
          {action !== 'Create' && (
            <WithResourceIcon resourceType={row?.native_resource}>
              <CustomTooltip label={value}>{value}</CustomTooltip>
            </WithResourceIcon>
          )}
        </HStack>
      ),
    },
    {
      header: 'Type',
      accessor: 'native_resource',
      render: ({ value }) => (
        <StackedCell
          upper={getCloudNativeName(value)}
          lower={getCloudAgnosticName(value)}
        />
      ),
      align: 'left',
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Entities',
      accessor: 'check_stats.entities_with_issues',
      align: 'left',
    },
    {
      header: 'Exceptions',
      accessor: 'check_stats.rule_exceptions',
      align: 'left',
    },
    context,
    ...(action === 'Create'
      ? []
      : [
          {
            header: 'Actions',
            render: ({ row }) => (
              <Center>
                {/* {addPolicy.isOpen && (
            <ActionButton
              label="Include policy"
              icon={<ComplianceCheckPassedIcon />}
              onClick={e => {
                e.stopPropagation();
                includePolicy.onOpen();
                updateState({ currentRecord: row });
              }}
              isDisabled={row.created_by === 'system'}
            />
          )}*/}

                <ActionButton
                  label="Edit context"
                  icon={<TagIcon />}
                  p={0.5}
                  onClick={e => {
                    e.stopPropagation();
                    editContext.onOpen();
                    updateState({ currentRecord: row });
                  }}
                  //isDisabled={row.created_by === 'system'}
                />
                <ActionButton
                  label="Exclude policy"
                  icon={<PolicyExceptionIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    excludePolicy.onOpen();
                    updateState({ currentRecord: row });
                  }}
                  isDisabled={row.created_by === 'system'}
                />
              </Center>
            ),
            styles: { width: '60px', cell: { maxWidth: '60px' } },
          },
        ]),
  ];

  return (
    <Stack h="full" spacing={3}>
      <HStack w="full" justify="flex-end">
        <Box w={48}>
          <Select
            options={state.severityOptions}
            value={state.severity}
            onChange={s => updateState({ severity: s })}
            showIconInValueContainer
          />
        </Box>
        <Box w={56}>
          <Select
            options={state.resourceTypeOptions}
            value={state.resourceType}
            onChange={s => updateState({ resourceType: s })}
            showIconInValueContainer
          />
        </Box>
      </HStack>
      <Box h="full" flex={1}>
        <Table
          data={policies.data}
          columns={columns}
          isLoading={policies.isLoading}
          onRowClick={row => {
            if (action === 'Create') {
              if (includes(selectedPolicies, row.rule_id))
                setSelectedPolicies?.(
                  filter(selectedPolicies, a => a !== row.rule_id),
                );
              else
                setSelectedPolicies?.([
                  ...(selectedPolicies ?? []),
                  row.rule_id,
                ]);
            }
          }}
          pagination={{
            totalCount: policies.page.totalCount,
            pageInfo: policies.page.info,
            onChange: pageInfo => {
              dispatch(
                actions.updatePoliciesUnderSubControlsPagination(pageInfo),
              );
            },
          }}
          styles={{ header: { zIndex: 800 } }}
        />
      </Box>
      {excludePolicy.isOpen && (
        <DeleteConfirmationModal
          confirmationText={
            <Text>
              Please type <Text as="b">CONFIRM</Text> to exclude policy.
            </Text>
          }
          name="CONFIRM"
          header="Exclude rule confirmation"
          label="Exclude"
          onConfirm={handleExcludeRule}
          isOpen={excludePolicy.isOpen}
          onClose={excludePolicy.onClose!}
          isLoading={updateRules.isLoading}
        />
      )}
      {includePolicy.isOpen && (
        <ConfirmationModal
          confirmationText={
            <Text>
              Please type <Text as="b">CONFIRM</Text> to include policy.
            </Text>
          }
          name="CONFIRM"
          header="Include rule confirmation"
          label="Include"
          onConfirm={handleExcludeRule}
          isOpen={includePolicy.isOpen}
          onClose={includePolicy.onClose!}
        />
      )}
      {/*{showResources.isOpen && (
        <ResourcesUnderSubControlPolicy
          isOpen={showResources.isOpen}
          onClose={showResources.onClose}
          subControl={subControl}
          policy={state.currentRecord}
        />
      )}*/}
      {editContext.isOpen && (
        <EditPolicyContext
          isOpen={editContext.isOpen}
          onClose={editContext.onClose}
          row={state.currentRecord}
          blueprintId={blueprint.data.id}
          onSuccess={getPolicies}
        />
      )}
    </Stack>
  );
};
