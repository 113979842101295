import { uniq } from 'lodash';

import { NODE_ENV } from 'utils/env';

let origin = window.location.origin;

const base_url = origin.split('://');

// const PROTOCOL = 'https';
// const WS_PROTOCOL = 'ws';
// export const BASE_DOMAIN = 'dev.ariksa.io';

const PROTOCOL = base_url[0];
const WS_PROTOCOL = PROTOCOL === 'https' ? 'wss' : 'ws';
export const BASE_DOMAIN = uniq(base_url[1].split('.')).join('.');

export const BASE_URL = `${PROTOCOL}://${BASE_DOMAIN}`;

export const COLLECTOR_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/collector`;
export const INVENTORY_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/inventory`;
export const POLICY_HUB_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/policies`;
export const ONBOARDING_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/cloud-account`;
export const ALERT_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/notification`;
export const ALERT_WEBSOCKET = `${WS_PROTOCOL}://${
  NODE_ENV === 'development' ? process.env.PROXY_TO : BASE_DOMAIN
}/v1/event-log/ws`;
export const AUTH_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}`;
export const AUDITLOG_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}`;
export const KEYCLOAK_SERVICE =
  process.env.PROXY_TO && NODE_ENV === 'development'
    ? `https://${process.env.PROXY_TO}/sso`
    : `${PROTOCOL}://${BASE_DOMAIN}/sso`;
export const PIZZLY_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/auth`;
export const PERMISSIONS_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/permissions`;
export const GRAPHQL_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/graphql`;
export const NETWORK_ANALYSIS_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/network-analysis`;
export const AI_ENGINE_SERVICE = `${PROTOCOL}://${BASE_DOMAIN}/v1/ai-engine`;
