import React, { FC } from 'react';

import { Flex, Spinner, SpinnerProps } from '@chakra-ui/react';

export interface ICustomSpinner extends SpinnerProps {
  loading?: boolean;
  color?: string;
  align?: string;
  justify?: string;
}

export const CustomSpinner: FC<ICustomSpinner> = props => {
  const {
    loading = true,
    color,
    align = 'center',
    justify = 'center',
    ...rest
  } = props;
  return (
    <>
      {loading && (
        <Flex h={'full'} w={'full'} align={align} justify={justify}>
          <Spinner size={'sm'} color={color || 'primary'} {...rest} />
        </Flex>
      )}
    </>
  );
};
