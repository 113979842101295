import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyResourceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M439.2,161.5c-0.6-1.6-1.6-3-2.8-4.1L283.2,3.8C282,2.6,280.6,1.6,279,1c-1.5-0.6-3.2-1-4.9-1H108
		C65.7,0,31.4,34.4,31.4,76.8v358.4c0,42.4,34.3,76.8,76.7,76.8h255.5c0.4,0,0.9,0,1.3,0l-42.2-25.6H108
		c-28.2,0-51.1-22.9-51.1-51.2V76.8c0-28.3,22.9-51.2,51.1-51.2h153.3v93.9c0,33,26.7,59.7,59.6,59.7h93.7V304l25.6,15.5V166.4
		C440.2,164.7,439.9,163,439.2,161.5z M321,153.6c-18.8,0-34-15.3-34.1-34.1V43.7l109.7,109.9H321z M338,268.8
		c0-7.1-5.7-12.8-12.8-12.8H146.4c-7.1,0-12.8,5.7-12.8,12.8c0,7.1,5.7,12.8,12.8,12.8h178.9C332.3,281.6,338,275.9,338,268.8z
		 M146.4,179.2h51.1c7.1,0,12.8-5.7,12.8-12.8c0-7.1-5.7-12.8-12.8-12.8h-51.1c-7.1,0-12.8,5.7-12.8,12.8
		C133.6,173.5,139.3,179.2,146.4,179.2z M133.6,371.2c0,7.1,5.7,12.8,12.8,12.8h142.4v-9.1c0-5.9,1.7-11.6,4.6-16.5h-147
		C139.3,358.4,133.6,364.1,133.6,371.2z"
      />
      <path
        d="M477.5,352.3l-82.1-30.2l-85.3,31.3v116.3l85.3,30.9l85.3-30.9V353.4L477.5,352.3L477.5,352.3z M392.2,492.8
		l-75.8-27.5V360.2l75.8,27.5V492.8z M395.4,382.1l-73-26.5l73-26.8l72.9,26.8L395.4,382.1z M474.3,465.3l-75.8,27.5V387.7
		l75.8-27.4V465.3z"
      />
    </g>
  </Icon>
);
