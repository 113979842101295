import React from 'react';

import { Box } from '@chakra-ui/react';
import { isNumeric } from 'mathjs';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

interface Props {
  label: any;
  value: any;
  placement?: any;
}

export const TableCellTooltip: React.FC<Props> = props => {
  const { label, value, ...rest } = props;
  return (
    <Box>
      {value !== '-' && !isNumeric(value) ? (
        <CustomTooltip label={label.toString()} placement="top-start" {...rest}>
          <Box overflow="hidden" textOverflow="ellipsis">
            {value.toString()}
          </Box>
        </CustomTooltip>
      ) : (
        value.toString()
      )}
    </Box>
  );
};
