import { BaseSyntheticEvent, createContext, useContext } from 'react';

import { AlertWorkflowStepProps } from '../../types';

export interface Props {
  actionType: 'Create' | 'Update';
  tagKeyOptions: Record<string, any>[];
}

export const AlertWorkflowWizardContext = createContext<
  AlertWorkflowStepProps & Props
>({
  handleSubmit: (value: any, e?: BaseSyntheticEvent) => {},
  onCancel: () => {},
  updateFormData: (field: string, value: any) => {},
  actionType: 'Create',
  tagKeyOptions: [],
});

export const AlertWorkflowWizardContextProvider =
  AlertWorkflowWizardContext.Provider;

export const useAlertWorkflowWizardContext = () =>
  useContext(AlertWorkflowWizardContext);
