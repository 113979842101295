import React, { FC, useEffect, useMemo, useReducer, useRef } from 'react';

import { NotificationFor } from '@ariksa/notification/api';
import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { filter, map, slice } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInventoryVulnerabilityService } from 'services/Inventory/useInventoryVulnerabilityService';

import {
  CustomTooltip,
  dateLabel,
  DetailsIdentifier,
  formatTooltip,
  renderCloudIcon,
  renderRiskContext,
  renderTableHeaderWithLoader,
  StackedCell,
  StackedHeader,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton, DownloadIconButton, Select } from 'components/DataEntry';
import { NotificationIcon, TicketIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { actions as dashboardActions } from 'containers/Dashboard/slice';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import { technologyCloudOptions } from 'containers/Dashboard/VulnerabilityOverview/Components/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { actions } from 'containers/Dashboard/VulnerabilityOverview/slice';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { NotificationResource } from 'containers/Findings/Alerts/types';
import { renderVulnerabilities } from 'containers/Inventory/CloudInventory/Components/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { limitedString } from 'utils/string';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentRecord: Record<string, any>;
}

export const IndividualEOLSoftwareDrawer: FC<Props> = props => {
  const { isOpen, onClose, currentRecord } = props;
  const { eolSoftwareEntities, riskContext } = useSelector(
    selectVulnerabilityOverview,
  );
  const { alertCount } = useSelector(selectDashboard);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const { environmentId, accountId } = useAccessBoundary();
  const { renderStackedAccountNameID } = useCloudAccountId();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const { downloadTechnologyEntities } = useInventoryVulnerabilityService();
  const { resourceTypeAliasOptionsWithAll } = useResourceType();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      currentRow: {},
      resourceType: resourceTypeAliasOptionsWithAll?.[0],
      cloud: technologyCloudOptions[0],
    },
  );

  useEffect(() => {
    dispatch(
      actions.getEOLSoftwareEntities({
        q: {
          size: eolSoftwareEntities.page.info.page_size,
          page: eolSoftwareEntities.page.info.page_number,
          environmentId,
          accountId: !!accountId ? [accountId] : undefined,
          version: currentRecord?.version,
          osAndTech: currentRecord?.name,
          nativeName: state?.resourceType?.value,
          cloud: state?.cloud?.value,
        },
        onSuccess: res => {
          dispatch(
            actions.getRiskContext({
              q: {
                riskContextRequest: {
                  uuids: map(res?.items, o => o?.uuid),
                },
              },
            }),
          );
          dispatch(
            dashboardActions.getAlertCount({
              q: {
                environmentId: environmentId!,
                accountIds: !!accountId ? [accountId] : [],
                resourceUuids: map(res?.items, o => o?.uuid),
              },
            }),
          );
        },
      }),
    );
  }, [
    eolSoftwareEntities.page.info,
    environmentId,
    accountId,
    dispatch,
    state?.resourceType,
    state?.cloud,
    currentRecord,
  ]);

  const columns = [
    {
      header: 'Name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.native_name}
          iconTooltip={
            <ResourceTypeIconTooltip resourceType={row?.native_name} />
          }
        >
          <StackedCell upper={row?.name} lower={row?.resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Cloud',
      accessor: 'provider',
      render: ({ value }) => (
        <Center>{renderCloudIcon({ provider: value })}</Center>
      ),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Account',
      accessor: 'account',
      align: 'left',
      render: ({ value }) => renderStackedAccountNameID(value),
    },
    {
      header: 'Deployed In',
      render: ({ row }) => (
        <StackedCell upper={row?.vpc ?? row?.vpc_id} lower={row?.region} />
      ),
      align: 'left',
      styles: { width: '150px', cell: { maxWidth: '150px' } },
    },
    {
      header: renderTableHeaderWithLoader('Alerts', alertCount.isLoading),
      render: ({ row }) => alertCount.data?.[row?.uuid] ?? 0,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: renderTableHeaderWithLoader(
        'Risk Context',
        riskContext.isLoading,
      ),
      align: 'left',
      render: ({ row }) => renderRiskContext(riskContext?.data?.[row?.uuid]),
    },
    {
      header: 'Tags',
      align: 'left',
      render: ({ row }) => {
        const value: Record<string, any>[] = row?.tags;
        const renderTooltip = (val, index = 0) => (
          <HStack spacing={1} align="flex-start">
            {!!index && <Text whiteSpace="nowrap">{index + '.'}</Text>}
            <Flex display="inline-block">
              <Text color="orange" display="inline-block">
                {val?.key + ': '}
              </Text>
              <Text display="inline-block" pl={1}>
                {val?.value}
              </Text>
            </Flex>
          </HStack>
        );
        const renderTag = val => (
          <CustomTooltip label={renderTooltip(val)}>
            <Tag label={limitedString(val?.key + ': ' + val?.value, 20)} />
          </CustomTooltip>
        );
        return (
          <Stack spacing={1}>
            <HStack spacing={1}>
              {value?.[0] && renderTag(value?.[0])}
              {value?.length > 2 && (
                <CustomTooltip
                  label={formatTooltip({
                    header: 'Tags',
                    content: (
                      <Stack>
                        {map(slice(value, 2, value?.length), (o, index) =>
                          renderTooltip(o, index + 1),
                        )}
                      </Stack>
                    ),
                    width: 64,
                  })}
                >
                  <Tag
                    label={'+' + (value?.length - 2)}
                    styles={{
                      label: { color: 'white' },
                      tag: { bg: 'primary' },
                    }}
                  />
                </CustomTooltip>
              )}
            </HStack>
            {value?.[1] && renderTag(value?.[1])}
          </Stack>
        );
      },
    },
    {
      header: 'Owner',
      accessor: 'owner',
    },
    {
      header: 'Actions',
      styles: { width: '60px', cell: { maxWidth: '60px' } },
      render: ({ row }) => (
        <Center onClick={e => e.stopPropagation()}>
          <ActionButton
            label="Create ticket"
            icon={<TicketIcon />}
            onClick={() => {
              updateState({ currentRow: row });
              ticketModal.onOpen();
            }}
          />
          <ActionButton
            label="Create notification"
            icon={<NotificationIcon />}
            onClick={() => {
              updateState({ currentRow: row });
              notificationModal.onOpen();
            }}
          />
        </Center>
      ),
    },
  ];

  const tableActionModals = useMemo(() => {
    const alert: NotificationResource = {
      entity_uuid: state.currentRow?.uuid,
      entity_id: state.currentRow?.resource_id,
      entity_type: state.currentRow?.native_name,
      account_id: state.currentRow?.account,
      resource: state.currentRow?.native_name,
      entity_name: state.currentRow?.name,
    };

    return (
      <>
        {ticketModal.isOpen && (
          <CreateTicket
            onClose={ticketModal.onClose}
            isOpen={ticketModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Vulnerabilities}
          />
        )}
        {notificationModal.isOpen && (
          <CreateNotification
            onClose={notificationModal.onClose}
            isOpen={notificationModal.isOpen}
            alert={alert}
            notification_for={NotificationFor.Vulnerabilities}
          />
        )}
      </>
    );
  }, [
    state.currentRow,
    notificationModal.isOpen,
    notificationModal.onClose,
    ticketModal.isOpen,
    ticketModal.onClose,
  ]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={currentRecord?.name + ' ' + currentRecord?.version}
          lower="End-of-life software"
          type={currentRecord?.name}
          iconFilled={false}
        />
      }
      closeButton
      styles={{
        drawer: { portalProps: { containerRef: ref as any } },
        content: { maxW: '1500px' },
      }}
      body={
        <Stack h="full" pt={3} spacing={5}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            <GridItem colSpan={1}>
              <Stack spacing={4}>
                <DetailsIdentifier
                  label="Technology"
                  value={currentRecord?.name + ' ' + currentRecord?.version}
                />
                <DetailsIdentifier
                  label="EOL date"
                  value={dateLabel(currentRecord?.end_of_life)}
                />
                <DetailsIdentifier
                  label="Latest version"
                  value={currentRecord?.latest_version}
                />
                <DetailsIdentifier
                  label="Occurrences"
                  value={currentRecord?.occurrences}
                />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <Stack spacing={4}>
                <DetailsIdentifier
                  label="Vulnerabilities"
                  value={renderVulnerabilities({
                    value: currentRecord?.vulnerabilities,
                  })}
                />
                <DetailsIdentifier
                  label="Clouds"
                  value={renderCloudIcons(
                    filter(
                      Object.keys(currentRecord?.entity_summary),
                      o => !!currentRecord?.entity_summary?.[o],
                    ),
                  )}
                />
                <DetailsIdentifier
                  label="Accounts"
                  value={currentRecord?.account_uuids?.length}
                />
                <DetailsIdentifier label="More information" value={''} />
              </Stack>
            </GridItem>
          </Grid>

          <Divider borderColor="primary" />
          <HStack justify="space-between">
            <Box fontWeight={600} fontSize="15px">
              Entities
            </Box>
            <HStack>
              <Box color="primary">FILTERS:</Box>
              <Box w={64} zIndex={900}>
                <Select
                  options={resourceTypeAliasOptionsWithAll}
                  value={state.resourceType}
                  onChange={s => {
                    updateState({ resourceType: s });
                  }}
                  showIconInValueContainer
                />
              </Box>
              <Box w={48} zIndex={900}>
                <Select
                  options={technologyCloudOptions}
                  value={state.cloud}
                  onChange={s => {
                    updateState({ cloud: s });
                  }}
                  showIconInValueContainer
                />
              </Box>
              <DownloadIconButton
                aria-label="download"
                borderRadius={5}
                onClick={() => {
                  downloadTechnologyEntities({
                    osAndTech: currentRecord?.name,
                    version: currentRecord?.version,
                    environmentId,
                    accountId: !!accountId ? [accountId] : undefined,
                    nativeName: state?.resourceType?.value,
                    cloud: state?.cloud?.value,
                    fileName:
                      'EOL-software-' +
                      currentRecord?.name +
                      '-' +
                      currentRecord?.version +
                      '-' +
                      dayjs().utc().format() +
                      '.csv',
                  });
                }}
              />
            </HStack>
          </HStack>
          <Box flex={1}>
            <Table
              data={eolSoftwareEntities.data}
              isError={eolSoftwareEntities.isError}
              columns={columns}
              isLoading={eolSoftwareEntities.isLoading}
              styles={{ header: { position: 'relative', zIndex: 800 } }}
              pagination={{
                pageInfo: eolSoftwareEntities.page.info,
                onChange: info =>
                  dispatch(actions.updateEOLSoftwareEntitiesPagination(info)),
                totalCount: eolSoftwareEntities.page.totalCount,
              }}
              onRowClick={r => {
                console.log(r);
                updateActiveResource({
                  resourceType: r?.native_name,
                  uuid: r?.uuid,
                  accountId: r?.account,
                  resourceId: r?.resource_id,
                });
                onOpenMetadata();
              }}
            />
          </Box>
          {tableActionModals}
        </Stack>
      }
    />
  );
};
