/**
 *
 * Settings
 *
 */

import React, { memo } from 'react';

import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Page, usePageContentContext } from 'components/Layout';
import { SideNav } from 'components/Navigation/SideNav';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { settingsSaga } from './saga';
import { selectSettings } from './selectors';
import { reducer, sliceKey } from './slice';
import { menuItems } from './utils';

interface Props {}

export const Settings = memo((props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: settingsSaga });
  const { contentHeight } = usePageContentContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const settings = useSelector(selectSettings);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  return (
    <Box h={contentHeight + 'px'}>
      <SideNav menuItems={menuItems} />
    </Box>
  );
});
