import {
  LabelRead,
  TagsApiCreateLabelsRequest,
  TagsApiDeleteLabelRequest,
  TagsApiEditLabelRequest,
  TagsApiGetLabelRequest,
  TagsApiGetLabelsRequest,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService } from 'api/services';

import { actions } from './slice';

export function* inventoryContextSaga() {
  yield takeLatestAction(actions.getContextList, doGetContextList);
  yield takeLatestAction(actions.createContext, doCreateContext);
  yield takeLatestAction(actions.deleteContext, doDeleteContext);
  yield takeLatestAction(actions.updateContext, doUpdateContext);
  yield takeLatestAction(actions.getContextById, doGetContextById);
}

export function* doGetContextList(
  ctx: QueryContext<LabelRead[], TagsApiGetLabelsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.getLabels(ctx.params));
}

export function* doCreateContext(
  ctx: QueryContext<any, TagsApiCreateLabelsRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.createLabels(ctx.params));
}

export function* doDeleteContext(
  ctx: QueryContext<any, TagsApiDeleteLabelRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.deleteLabel(ctx.params));
}

export function* doUpdateContext(
  ctx: QueryContext<any, TagsApiEditLabelRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.editLabel(ctx.params));
}

export function* doGetContextById(
  ctx: QueryContext<LabelRead, TagsApiGetLabelRequest>,
) {
  yield call(ctx.fetch, () => InventoryService.Tags.getLabel(ctx.params));
}
