import React, { FC, useCallback, useEffect, useState } from 'react';

import { ComplianceApiGetSummaryRequest } from '@ariksa/compliance-policies/api';
import { Box, Stack, Grid, GridItem } from '@chakra-ui/react';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Select } from 'components/DataEntry';
import { selectApp } from 'containers/App/selectors';
import { selectCompliance } from 'containers/Compliance/selectors';
import { actions } from 'containers/Compliance/slice';

import { useComparePolicies } from './context';

export const ChooseBlueprints: FC = () => {
  const dispatch = useDispatch();
  const {
    firstEnvironment,
    setFirstEnvironment,
    secondEnvironment,
    setSecondEnvironment,
    firstBlueprint,
    setFirstBlueprint,
    secondBlueprint,
    setSecondBlueprint,
  } = useComparePolicies();

  const { blueprints, comparePolicies } = useSelector(selectCompliance);
  const { policy1, policy2 } = comparePolicies;
  const { environments } = useSelector(selectApp);

  const [blueprintOptions, setBlueprintOptions] = useState<
    Record<string, any>[]
  >([]);
  const [environmentOptions, setEnvironmentOptions] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    dispatch(actions.getBlueprints({}));
  }, [dispatch]);

  //set blueprint options
  useEffect(() => {
    const options = map(blueprints.data, o => ({ label: o?.name, value: o }));
    setBlueprintOptions(options);
    setFirstBlueprint(options?.[0] || {});
    setSecondBlueprint(options?.[1] || {});
  }, [blueprints.data, setSecondBlueprint, setFirstBlueprint]);

  //set environment options
  useEffect(() => {
    const options = map(environments.data, o => ({ label: o?.name, value: o }));
    setEnvironmentOptions(options);
    setFirstEnvironment(options?.[0] || {});
    setSecondEnvironment(options?.[0] || {});
  }, [environments.data, setFirstEnvironment, setSecondEnvironment]);

  const getCompareInfo = useCallback(
    (environmentId, blueprintId, index) => {
      if (environmentId && blueprintId) {
        const payload: ComplianceApiGetSummaryRequest = {
          environmentId,
          blueprintId,
        };
        if (index === 1)
          dispatch(actions.getPolicyCompareInfoForPolicy1({ q: payload }));
        else dispatch(actions.getPolicyCompareInfoForPolicy2({ q: payload }));
      }
    },
    [dispatch],
  );

  useDeepCompareEffect(() => {
    if (firstBlueprint && firstEnvironment) {
      getCompareInfo(firstEnvironment?.value?.id, firstBlueprint?.value?.id, 1);
    }
  }, [firstEnvironment, firstBlueprint, getCompareInfo]);

  useDeepCompareEffect(() => {
    if (secondBlueprint && secondEnvironment) {
      getCompareInfo(
        secondEnvironment?.value?.id,
        secondBlueprint?.value?.id,
        2,
      );
    }
  }, [secondEnvironment, secondBlueprint, getCompareInfo]);

  const selectItem = (options, value, onChange) => {
    return (
      <GridItem colSpan={2}>
        <Box px={10}>
          <Box w="full">
            <Select
              options={options}
              value={value}
              onChange={onChange}
              isDisabled={policy1.isLoading || policy2.isLoading}
            />
          </Box>
        </Box>
      </GridItem>
    );
  };

  const getLabel = str => (
    <GridItem colSpan={1}>
      <Box color="gray.300" fontWeight={600}>
        {str}
      </Box>
    </GridItem>
  );

  return (
    <Stack spacing={4}>
      <Grid templateColumns="repeat(5, 1fr)" gap={3} w="full">
        {getLabel('Environment being assessed')}

        {selectItem(environmentOptions, firstEnvironment, setFirstEnvironment)}
        {selectItem(
          environmentOptions,
          secondEnvironment,
          setSecondEnvironment,
        )}
      </Grid>
      <Grid templateColumns="repeat(5, 1fr)" gap={3} w="full">
        {getLabel('Choose blueprint to compare')}

        {selectItem(blueprintOptions, firstBlueprint, setFirstBlueprint)}
        {selectItem(blueprintOptions, secondBlueprint, setSecondBlueprint)}
      </Grid>
    </Stack>
  );
};
