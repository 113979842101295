import React, { useMemo } from 'react';

import { NativeResources } from '@ariksa/inventory-core';

import { Ec2AppsTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Apps';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const AppsTab = () => {
  const { resourceType } = useActiveResourceContext();

  const appTab = useMemo(() => {
    switch (resourceType) {
      case NativeResources.Ec2Instance:
      case NativeResources.ElasticKubernetesService:
        return <Ec2AppsTab />;
    }
  }, [resourceType]);

  return <>{appTab}</>;
};
