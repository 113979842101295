import {
  MapApiGetRoute53MapRequest,
  SearchResponse,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useInventoryMapService() {
  const route53NetworkMap = useAriksaQuery<
    MapApiGetRoute53MapRequest,
    SearchResponse
  >({
    queryKey: [],
    fetcher: req => InventoryService.Map.getRoute53Map(req).then(r => r.data),
  });

  return { route53NetworkMap };
}
