import React, { memo, useState } from 'react';

import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Center,
  Portal,
  MenuOptionGroup,
  MenuItemOption,
  HStack,
  Checkbox,
  Stack,
} from '@chakra-ui/react';
import { includes, isEmpty } from 'lodash';
import map from 'lodash/map';
import { customTheme } from 'theme';

import { getIcon } from 'components/Icons/Components';

import { defaultStyles } from './styles';
import { IMenuItemOption, IMenuItemProps, IMenuProps } from './types';

export const CustomMenu = memo((props: IMenuProps) => {
  const {
    menuItems,
    buttonLabel,
    menuTransition = true,
    itemsComponent,
    menuItemOptions,
    styles,
    ...rest
  } = props;

  const [selected, setSelected] = useState<Record<string, any>>({});

  const menuItem = (item: IMenuItemProps) => {
    const { label, divider, icon, iconType, ...rest } = item;
    const props = {
      ...defaultStyles?.item,
      icon: (
        <Center {...defaultStyles?.icon} {...styles?.icon}>
          {!!iconType ? getIcon(iconType) : icon}
        </Center>
      ),
      ...rest,
      ...styles?.item,
    };
    return (
      <>
        <MenuItem {...props}>{label}</MenuItem>
        {divider && <MenuDivider />}
      </>
    );
  };

  const menuItemOption = (item: IMenuItemOption, groupKey: string) => {
    return (
      <MenuItemOption
        {...defaultStyles?.itemOption}
        iconSpacing={0}
        icon={<Box />}
        key={groupKey}
        {...item}
      >
        <HStack>
          <Checkbox
            isChecked={includes(selected[groupKey], item?.value)}
            size="sm"
          />
          <Box>{item.label}</Box>
        </HStack>
      </MenuItemOption>
    );
  };

  const menuList = () => (
    <MenuList {...styles?.list}>
      {itemsComponent
        ? itemsComponent
        : !isEmpty(menuItemOptions)
        ? map(menuItemOptions, (each, index) => (
            <Box key={`${index}-group-option`}>
              <MenuOptionGroup
                {...styles?.optionsGroup}
                {...each?.groupProps}
                onChange={value => {
                  const options = { ...selected, [each.key]: value };
                  setSelected(options);
                  each?.groupProps?.onChange?.(value);
                }}
              >
                {map(each.items, o => menuItemOption(o, each?.key))}
                {menuItemOptions?.length !== index + 1 && (
                  <Box px={3}>
                    <MenuDivider />
                  </Box>
                )}
              </MenuOptionGroup>
            </Box>
          ))
        : map(menuItems, (each, index) => (
            <Box key={`${each?.key ?? index}-item`}>
              {each.group ? (
                <>
                  <Stack spacing={0} {...styles?.group}>
                    {each?.group?.title && (
                      <Box px={3}>
                        <Box
                          py={1}
                          borderBottom="1px solid"
                          borderColor={customTheme.colors.gray['200']}
                          fontSize="xs"
                          color={customTheme.colors.gray['300']}
                          fontWeight={600}
                        >
                          {each?.group?.title}
                        </Box>
                      </Box>
                    )}
                    {map(each.group.list, menuItem)}
                  </Stack>

                  {/*<MenuGroup {...styles?.group} {...each?.group}>
                    {map(each.group.list, menuItem)}
                  </MenuGroup>*/}
                  {each?.divider && <MenuDivider />}
                </>
              ) : (
                menuItem(each)
              )}
            </Box>
          ))}
    </MenuList>
  );

  return (
    <Box fontSize="sm">
      <Menu {...styles?.menu} {...rest}>
        {buttonLabel && (
          <MenuButton {...styles?.button} onClick={e => e.stopPropagation()}>
            {buttonLabel}
          </MenuButton>
        )}
        {menuTransition ? <Portal>{menuList()}</Portal> : menuList()}
      </Menu>
    </Box>
  );
});
