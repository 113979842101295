import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { useInventorySearchV2Service } from 'services/Inventory/useInventorySearchV2Service';

import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const SGNetworkMapWrapper: FC = () => {
  const { resourceInsight } = useActiveResourceContext();
  const { data: activeResource } = resourceInsight;
  const { networkMap } = useInventorySearchV2Service();

  useEffect(() => {
    networkMap.sync({
      nativeResource: NativeResources.SecurityGroup,
      searchQuery: {
        uuid: activeResource.uuid,
        account_id: [activeResource.account],
      },
    });
  }, [activeResource.account, activeResource.uuid, networkMap]);

  return (
    <>
      <PostureGraphWithContext
        data={networkMap.data ?? {}}
        isLoading={networkMap.isLoading}
        isLoaded={networkMap.isSuccess}
      />
    </>
  );
};
