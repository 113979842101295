import React, { ReactElement } from 'react';

import {
  Button,
  Box,
  Center,
  Flex,
  Tooltip,
  useDisclosure,
  Spinner,
  Icon,
} from '@chakra-ui/react';

import { CheckmarkIcon } from 'app/components';
import { CloseIcon } from 'components/Icons';

import { colors } from '../../../../theme/theme2/colors';
import { IPopover, Popover } from '../../Overlay';

import {
  actionButtonHoverStyles,
  actionButtonStyles,
  deleteButtonStyles,
  deletePopConfirmStyles,
  tooltipProps,
} from './styles';

export interface Props
  extends Omit<IPopover, 'footer' | 'initialFocusRef' | 'button'> {
  onConfirm: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  icon?: ReactElement;
}

export const EnableButton = (props: Props) => {
  const {
    onConfirm,
    isDisabled = false,
    isLoading = false,
    label,
    icon,
    ...rest
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusRef = React.useRef(null);
  return (
    <Box>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialFocusRef}
        placement="bottom"
        button={
          <Tooltip label={label} {...tooltipProps}>
            <Center
              {...actionButtonStyles}
              _hover={{ ...actionButtonHoverStyles(isDisabled) }}
              onClick={onOpen}
            >
              {isLoading ? (
                <Spinner size="xs" color="primary" />
              ) : label === 'Enable' ? (
                <Box
                  background={colors.gray['50']}
                  borderRadius={10}
                  padding={1}
                >
                  <CheckmarkIcon />
                </Box>
              ) : (
                <Box
                  background={colors.gray['50']}
                  borderRadius={10}
                  padding={1}
                >
                  <CloseIcon />
                </Box>
              )}
            </Center>
          </Tooltip>
        }
        styles={deletePopConfirmStyles}
        footer={
          <Flex justify="flex-end">
            <Button size="xs" ref={initialFocusRef} onClick={onClose}>
              No
            </Button>
            <Button
              {...deleteButtonStyles}
              ml={3}
              size="xs"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Yes
            </Button>
          </Flex>
        }
        body={`Are you sure you want to ${label}?`}
        {...rest}
      />
    </Box>
  );
};
