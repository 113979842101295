import React, { FC } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { isEmpty, map, toLower } from 'lodash';
import { useSelector } from 'react-redux';

import {
  DetailsIdentifier,
  getIcon,
  renderRiskContext,
  renderRiskContextIcon,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { useGetDescription } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useGetDescription';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import { renderAlert } from '../../MetadataDrawers/ResourceMetadata/utils';

export const AwsSummary: FC = () => {
  const {
    resourceInsight,
    resourceSeverity,
    resourceDetails,
    riskContext,
  } = useSelector(selectActiveResource);
  const { data: insight } = resourceInsight;
  const { data: severity } = resourceSeverity;

  const { getDescription } = useGetDescription();

  return (
    <>
      <DetailsIdentifier
        label="Cloud"
        value={
          <Box w={6}>
            {resourceInsight.isLoading ? (
              <CustomSpinner />
            ) : (
              getIcon(insight?.provider?.toLowerCase() ?? '')
            )}
          </Box>
        }
      />
      <DetailsIdentifier
        label="Alerts"
        value={
          !severity?.CRITICAL &&
          !severity?.MEDIUM &&
          !severity?.LOW &&
          !severity?.HIGH ? (
            'None'
          ) : (
            <HStack spacing={4}>
              {map(
                ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'],
                o =>
                  !!severity[o] && (
                    <Box key={o + '-severity'}>
                      {renderAlert(severity[o], toLower(o))}
                    </Box>
                  ),
              )}
            </HStack>
          )
        }
      />
      <DetailsIdentifier
        label="Risk Context"
        align="center"
        value={
          riskContext.isLoading ? (
            <Box w={6}>
              <CustomSpinner />
            </Box>
          ) : isEmpty(riskContext.data) ? (
            'None'
          ) : (
            <HStack>
              {map(riskContext.data, (o, index) => (
                <Box key={index + '-risk-context'}>
                  {renderRiskContextIcon(o)}
                </Box>
              ))}
            </HStack>
          )
        }
      />
      <DetailsIdentifier
        label="Description"
        value={
          resourceInsight.isLoading || resourceDetails.isLoading
            ? 'None'
            : getDescription()
        }
      />
    </>
  );
};
