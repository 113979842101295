import React, { FC, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { TimePicker } from 'components/DataEntry/Time/TimePicker';

import {
  TimePickerFieldSchema,
  TimePickerFieldStyles,
  useErrorMessage,
} from '../index';
import { useStyles } from '../index';
import { FieldProps } from '../types';

import { FormLabel } from './FormLabel';

export const TimePickerField: FC<FieldProps<TimePickerFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    helperText,
    isRequired,
    shouldDisplay,
    helpIcon,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
    value,
    ...rest
  } = field;

  const { register, watch, setValue } = useFormContext();

  const values = watch({ nest: true });

  const fieldStyles = useStyles<TimePickerFieldStyles>(
    'timePickerField',
    styles,
  );

  useEffect(() => {
    register(name); // custom register Antd input
  }, [register, name]);

  useEffect(() => {
    value && setValue(name, value);
  }, [value, setValue, name]);

  /*useEffect(() => {
    //field?.value && setValue(name, field?.value);
  }, [name, field?.onChange, setValue]);*/

  const errorMessage = useErrorMessage(name, label);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  useEffect(() => {}, []);

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Skeleton isLoaded={!isLoading}>
        <TimePicker
          data-test={id}
          button={false}
          styles={fieldStyles?.timePicker}
          value={value}
          {...rest}
        />
      </Skeleton>

      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
