import React, { useEffect, useMemo, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map, orderBy, slice, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { formatTooltip, Tag } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CriticalDataIcon } from 'components/Icons';
import { AriksaLogoOnlyIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoOnlyIcon';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const Records: React.FC = () => {
  const { labelCount } = useSelector(selectDataDashboard);
  const [sortedLabels, setSortedLabels] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const items = {
      pii: labelCount.data?.pii,
      pci: labelCount.data?.pci,
      phi: labelCount.data?.phi,
      sensitive: labelCount.data?.sensitive,
      custom: labelCount.data?.custom,
    };
    const sortedLabels = orderBy(
      map(items, (count, key) => ({ label: key, count })),
      ['count'],
      ['desc'],
    );
    setSortedLabels(sortedLabels);
  }, [labelCount.data]);

  const labels = useMemo(
    () => [
      {
        label: sortedLabels?.[0]?.label?.toUpperCase(),
        count: sortedLabels?.[0]?.count ?? 0,
        bg: '#d2e9f8',
      },
      {
        label: sortedLabels?.[1]?.label?.toUpperCase(),
        count: sortedLabels?.[1]?.count ?? 0,
        bg: '#fbf1d4',
      },
      {
        label: 'Other',
        count:
          sum(map(slice(sortedLabels, 2, sortedLabels.length), o => o.count)) ??
          0,
        bg: '#f5ddde',
        tooltip: formatTooltip({
          header: 'Labels',
          content: (
            <Stack spacing={1}>
              {map(slice(sortedLabels, 2, sortedLabels.length), o => (
                <Box>
                  {o.label?.toUpperCase()}: {o.count}
                </Box>
              ))}
            </Stack>
          ),
        }),
      },
    ],
    [sortedLabels],
  );

  const renderTag = item => (
    <Tag
      label={
        <HStack justify="space-between" w="full">
          <Box>{item.label}</Box>
          <Box>{formatNumber(item.count, 1)}</Box>
        </HStack>
      }
      leftIcon={<AriksaLogoOnlyIcon />}
      styles={{
        label: { color: 'black', w: 'full', pl: 2, fontSize: 'sm' },
        tag: { bg: item.bg, pr: 3, w: 'full' },
      }}
    />
  );

  return (
    <Box w="full" h="full" px={2} py={5}>
      {labelCount.isLoading ? (
        <Center h="full">
          <CustomSpinner />
        </Center>
      ) : (
        <HStack>
          <Stack h="full" w="40%">
            <Center w="full" color="critical">
              <Box boxSize={7}>
                <CriticalDataIcon />
              </Box>
            </Center>
            <Stack spacing={0} w="full">
              <Center>
                <HStack spacing={1}>
                  <Box fontWeight={600} fontSize="md">
                    {formatNumber(labelCount.data?.sum, 1) ?? 0}
                  </Box>
                  {/*<Box>
                    (
                    {round(
                      (labelCount.data?.sensitive! / labelCount.data?.all!) *
                        100,
                    )}
                    %)
                  </Box>*/}
                </HStack>
              </Center>
              <Center color={customTheme.colors.gray['300']}>Sensitive</Center>
            </Stack>
          </Stack>
          <Stack w="60%">
            {map(labels, o =>
              o?.tooltip ? (
                <CustomTooltip label={o.tooltip}>
                  <Box w="full">{renderTag(o)}</Box>
                </CustomTooltip>
              ) : (
                renderTag(o)
              ),
            )}
          </Stack>
        </HStack>
      )}
    </Box>
  );
};
