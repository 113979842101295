import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Center,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Stack,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { textFieldStyles } from 'components/DataEntry';
import {
  CompanyLogoIcon,
  CloseIcon,
  PermissionsActionsIcon,
  SearchIcon,
} from 'components/Icons';
import { Modal } from 'components/Overlay';

export interface ISearchBox extends InputProps {
  onChange(value: any);

  onSearch?(searchTerm: string);

  placeholder?: string;
  style?: any;
  value?: string;
  isLoading?: boolean;
  aiSearchModal?: UseDisclosureProps;
  navBar?: boolean;
  leftElement?: ReactNode;

  onClickClose?();

  onBlur?();
}

export const SearchBox: FC<ISearchBox> = props => {
  const {
    onChange,
    onSearch,
    placeholder,
    style,
    value = '',
    onClickClose,
    isLoading = false,
    navBar = false,
    aiSearchModal,
    leftElement,
    onBlur,
    ...rest
  } = props;

  const inputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState(value);
  useEffect(() => {
    if (value !== searchTerm) {
      setSearchTerm(value);
    }
  }, [value, searchTerm]);

  useHotkeys(
    ['ctrl+k', 'meta+k'],
    () => {
      // @ts-ignore
      inputRef?.current?.focus();
      aiSearchModal?.onOpen?.();
    },
    [aiSearchModal],
  );

  const renderKey = value => (
    <Center
      boxSize={4}
      //p={0.5}
      bg={customTheme.colors.gray['100']}
      fontSize="xs"
      borderRadius={4}
      fontStyle="bold"
    >
      {value}
    </Center>
  );

  const renderSearchBox = useCallback(
    (inputProps: InputProps & { style?: any }) => {
      return (
        <InputGroup>
          <Input
            placeholder={placeholder}
            value={searchTerm}
            ref={inputRef}
            {...textFieldStyles.input}
            onChange={e => {
              onChange(e.target.value);
              setSearchTerm(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onSearch?.(searchTerm);
              }
            }}
            onClick={() => {
              aiSearchModal?.onOpen?.();
            }}
            size="sm"
            borderRadius={6}
            onBlur={() => onBlur?.()}
            //type={'search'}
            {...style}
            {...rest}
            {...inputProps}
          />
          <InputLeftElement h="full" w={10} {...style?.leftElement}>
            <Center boxSize={style?.searchBoxSize || 5}>
              {leftElement ? (
                leftElement
              ) : (
                <SearchIcon
                  color={
                    style?.searchIconColor ||
                    inputProps.style?.searchIconColor ||
                    customTheme.colors.gray['200']
                  }
                />
              )}
            </Center>
          </InputLeftElement>
          <InputRightElement h="full" w={24}>
            <HStack justify="flex-end" w="full" mr={2}>
              <>
                {!!value.length && !isLoading && (
                  <Center
                    color="primary"
                    boxSize={3}
                    onClick={() => {
                      onClickClose?.();
                    }}
                    cursor="pointer"
                  >
                    <CloseIcon />
                  </Center>
                )}
                {isLoading && <CustomSpinner />}
              </>
              {!aiSearchModal?.isOpen && (
                <Flex>
                  <HStack h={4} spacing={1} fontWeight={800} w="full">
                    {renderKey(
                      <Center w="full" h="full" p="3px">
                        <PermissionsActionsIcon />
                      </Center>,
                    )}
                    {renderKey(<Center>K</Center>)}
                  </HStack>
                </Flex>
              )}
            </HStack>
          </InputRightElement>
        </InputGroup>
      );
    },
    [
      aiSearchModal,
      isLoading,
      leftElement,
      onChange,
      onClickClose,
      onSearch,
      placeholder,
      rest,
      searchTerm,
      style,
      value.length,
    ],
  );

  const styles = {
    backgroundColor: customTheme.colors.topNavSearchBg,
    opacity: 6,
    color: 'white',
    border: 'none',
    _hover: {
      borderColor: 'white',
      bg: customTheme.colors.topNavSearchBg,
    },
    _active: { borderColor: 'white' },
    _focus: { borderColor: 'white' },
    borderRadius: 5,
    placeholder: 'Search...',
    _placeholder: { color: 'white', opacity: 1 },
    style: { searchIconColor: 'white' },
  };

  return (
    <>
      {renderSearchBox({ ...(navBar ? styles : {}) })}
      {aiSearchModal?.isOpen && (
        <Modal
          isOpen={aiSearchModal?.isOpen}
          onClose={() => aiSearchModal?.onClose?.()}
          body={
            <Stack spacing={6}>
              <Box h={20} color="#000">
                <CompanyLogoIcon />
              </Box>
              {renderSearchBox({
                bg: 'white',
                fontSize: '15px',
                borderColor: customTheme.colors.gray['250'],
                _hover: { borderColor: customTheme.colors.gray['250'] },
                _focus: { borderColor: customTheme.colors.gray['250'] },
                style: { searchIconColor: 'black', searchBoxSize: 7 },
                placeholder: 'Search...',
                h: 10,
              })}
            </Stack>
          }
          closeButton={false}
          size="2xl"
          styles={{
            content: { bg: 'transparent', boxShadow: 'none', pb: 40 },
            overlay: { bg: 'whiteAlpha.800' },
          }}
        />
      )}
    </>
  );
};
