import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Modal, PieChart } from '../../../../components';
import { selectCloudAccounts } from '../selectors';
import { actions } from '../slice';

interface Props {
  account_id: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AssetSummary: FC<Props> = props => {
  const { account_id, isOpen, onClose } = props;
  const dispatch = useDispatch();

  const { assetSummary } = useSelector(selectCloudAccounts);

  useDeepCompareEffect(() => {
    dispatch(
      actions.loadAssetSummary({
        account_ids: account_id,
      }),
    );
  }, [dispatch, account_id]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Asset Summary"
      body={
        <PieChart
          data={assetSummary.data}
          dataKey="value"
          styles={{ root: { height: 96 } }}
          legend
          total={`Entities: ${assetSummary.data.reduce(
            (s, el) => s + el?.value || 0,
            0,
          )}`}
          activeShape
        />
      }
      styles={{ modal: { size: '3xl' } }}
    />
  );
};
