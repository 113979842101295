import React, { FC } from 'react';

import {
  Box,
  Center,
  Flex,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'components/DataDisplay';
import { CancelButton, PrimaryButton } from 'components/DataEntry';
import { contentStyles } from 'components/FeatureDemo/styles';
import { CompanyLogoIcon } from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  isSaaSAccount,
  useCloudAccountId,
} from 'containers/Setup/CloudAccounts/utils';

export const NoVulnerabilityScannerDeployed: FC = () => {
  const navigate = useNavigate();
  const { accountId } = useAccessBoundary();
  const { accountMapping } = useCloudAccountId();

  const list = [
    'Virtual Machines',
    'Containers',
    'Applications and Libraries',
    'Machine Images',
    'Serverless',
  ];

  const renderIcon = () => (
    <Flex {...contentStyles.logoWrapper}>
      {/* Logo */}
      <Center my="auto" {...contentStyles.logo}>
        <CompanyLogoIcon />
      </Center>
    </Flex>
  );

  return (
    <Card styles={{ card: { pl: 24, h: 'full' } }}>
      {!!accountId && isSaaSAccount(accountMapping?.[accountId]) ? (
        <Center w="full" maxH="600px">
          <Stack fontSize="lg" spacing={4}>
            <Center>{renderIcon()}</Center>
            <Box pl={6}>
              Vulnerability Management is not Supported for this account.
            </Box>
          </Stack>
        </Center>
      ) : (
        <Stack {...contentStyles.root} pt={0} mt={0}>
          {renderIcon()}
          <Box {...contentStyles.content}>
            <Box fontWeight={600} pb={6}>
              Deploy Ariksa Outpost for Vulnerability
            </Box>
            <Box>
              Ariksa Outpost for Vulnerability provides real-time vulnerability
              detection and management for compute resources. In addition to
              vulnerabilities, Ariksa uniquely helps you prioritize your efforts
              to fix vulnerabilities, by providing deeper risk context that
              include Internet exposure, Unencrypted data, Privileged access,
              Access to sensitive data etc.. Ariksa identifies vulnerabilities
              for:
            </Box>
            <UnorderedList {...contentStyles.list} spacing={2}>
              {list.map(o => (
                <ListItem>
                  <Text fontWeight={600}>{o}</Text>
                </ListItem>
              ))}
            </UnorderedList>

            <Box pt={6}>
              Ariksa Outpost for Vulnerability is agentless, making its’
              deployment quick and seamless.
            </Box>
          </Box>

          <HStack {...contentStyles.buttonGroup} pt={32}>
            <CancelButton
              {...contentStyles.button}
              onClick={() => navigate('/dashboard')}
              label="Skip"
            />
            <PrimaryButton
              {...contentStyles.button}
              onClick={() => navigate('/setup/outpost/add/vulnerability/')}
            >
              Deploy Ariksa Outpost
            </PrimaryButton>
          </HStack>
        </Stack>
      )}
    </Card>
  );
};
