import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ALERT_WEBSOCKET } from 'api/service_urls';
import { Notification } from 'containers/App/hooks/notification/Notification';
import { useKeycloakContext } from 'containers/App/keycloak/context';
import { useWebSocket } from 'hooks/websocket';

import { selectUser } from '../../selectors';
import { actions } from '../../slice';

export function useAlertNotification() {
  const [notification] = useState(new Notification());

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keycloak, forceRefreshToken } = useKeycloakContext();

  const loadAccounts = useCallback(() => {
    dispatch(actions.loadEnvAndAccounts());
  }, [dispatch]);

  const handleReceiveMessage = useCallback(
    (msg: any) => {
      console.log('Received websocket msg', msg);
      switch (msg.type) {
        case 'Created':
          if (msg.resource.type === 'Account') {
            forceRefreshToken();
            loadAccounts();
            navigate('/setup/accounts');
          }
          return;
        case 'Deleted':
          if (msg.resource.type === 'Account') {
            loadAccounts();
          }
          return;
      }
      // if (msg.alert_type) {
      //   dispatch(actions.addUnreadAlerts(msg));
      // }
    },
    [forceRefreshToken, navigate, loadAccounts],
  );

  const { setUrl } = useWebSocket({
    onMessage: handleReceiveMessage,
    authToken: keycloak?.token || '',
  });

  useEffect(() => {
    if (user.authenticated && user.info.id && user.info.org_id) {
      setUrl(`${ALERT_WEBSOCKET}?token=${keycloak?.token ?? ''}`);
    }
  }, [keycloak?.token, setUrl, user.authenticated, user.info]);

  return { notification };
}
