import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { Card, FormHeading, WithResourceIcon } from 'components/DataDisplay';
import { FormAction } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';

import { RemediationForm } from './RemediationForm';

interface Props {
  actionType?: FormAction;
}

export const RemediationWizard: FC<Props> = props => {
  const { actionType = 'Add' } = props;

  return (
    <Card
      headerComponent={
        <Box pl={14}>
          <WithResourceIcon
            resourceType={IconTypes.Remediate}
            bgColor="green"
            iconSize="small"
          >
            <FormHeading>
              {actionType === 'Add' ? 'Create' : actionType + ' Remediation'}
            </FormHeading>
          </WithResourceIcon>
        </Box>
      }
      styles={{ card: { pl: 32 } }}
      contentComponent={<RemediationForm actionType={actionType} />}
    />
  );
};
