import { PageEventLogResponse } from '@ariksa/event-log';
import {
  EventLogApiListRequest,
  EventLogResponse,
} from '@ariksa/event-log/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { Dict } from 'types/utils';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the EventLog container
export const initialState: ContainerState = {
  eventLogs: PagedQueryState.init<EventLogResponse[]>([]),
  eventLogFilters: {},
};

const eventLogSlice = createSlice({
  name: 'eventLog',
  initialState,
  reducers: {
    updateFilters(state, action: PayloadAction<Dict<any>>) {
      state.eventLogFilters = { ...state.eventLogFilters, ...action.payload };
    },
    resetFilters(state) {
      state.eventLogFilters = {};
    },
    getEventLogs(
      state,
      action: QueryAction<PageEventLogResponse, EventLogApiListRequest>,
    ) {
      state.eventLogs = PagedQueryState.next(state.eventLogs, action, {
        mapData: r => r.items,
        mapTotalCount: r => r.total,
      });
    },

    updatePageInfo(state, action: PayloadAction<PageInfo>) {
      state.eventLogs.page.info = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = eventLogSlice;
