import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 48 48',
  path: (
    <>
      <path d="M47.2 20.2l-5.2-.8c-.4-1.7-1.1-3.3-2-4.8l3.1-4.2c.3-.5.2-1-.1-1.4l-4-4c-.3-.3-.9-.4-1.3-.1L33.4 8c-1.5-.9-3.1-1.5-4.8-2L27.8.8c-.1-.5-.5-.8-1-.8h-5.6c-.5 0-.9.4-1 .8L19.4 6c-1.7.4-3.3 1.1-4.8 2l-4.3-3.1c-.4-.3-.9-.2-1.3.1L5 9c-.3.3-.4.9-.1 1.3L8 14.6c-.9 1.5-1.6 3.1-2 4.8l-5.1.8c-.5.1-.8.5-.8 1v5.6c0 .5.4.9.8 1l5 .8c.4 1.7 1.1 3.4 2 5l-3 4.1c-.3.4-.2.9.1 1.3l4 4c.3.3.9.4 1.3.1l4.1-3c1.6.9 3.3 1.7 5 2.1l.8 4.9c.1.5.5.8 1 .8h5.6c.5 0 .9-.4 1-.8l.8-4.9c1.8-.4 3.5-1.2 5-2.1l4.1 3c.4.3.9.2 1.3-.1l4-4c.3-.3.4-.9.1-1.3l-3-4.1c.9-1.6 1.6-3.2 2-5l5-.8c.5-.1.8-.5.8-1v-5.6c.1-.5-.3-.9-.7-1zM46 26l-4.9.8c-.4.1-.7.4-.8.8-.4 2-1.2 3.8-2.3 5.6-.2.3-.2.8 0 1.1l2.9 4-2.8 2.8-3.9-2.9c-.3-.2-.8-.3-1.1 0-1.7 1.1-3.6 1.9-5.6 2.3-.4.1-.7.4-.8.8L26 46h-4l-.8-4.8c-.1-.4-.4-.7-.8-.8-2-.4-3.9-1.2-5.6-2.3-.3-.2-.8-.2-1.1 0L9.8 41 7 38.2l2.9-4c.2-.3.3-.8 0-1.1-1.1-1.7-1.9-3.6-2.3-5.6-.1-.4-.4-.7-.8-.8L2 26v-4l4.9-.8c.4-.1.7-.4.8-.8.4-1.9 1.2-3.8 2.3-5.4.2-.3.2-.8 0-1.1L7 9.8 9.8 7l4.1 3c.3.2.8.3 1.1 0 1.7-1.1 3.5-1.8 5.4-2.2.4-.1.7-.4.8-.8l.8-5h4l.8 5.1c.1.4.4.7.8.8 1.9.4 3.7 1.2 5.4 2.2.3.2.8.2 1.1 0l4.1-3L41 9.9 38 14c-.2.3-.3.8 0 1.1 1.1 1.7 1.9 3.5 2.3 5.4.1.4.4.7.8.8l4.9.7v4z" />
      <path d="M24 13.5c-5.8 0-10.5 4.7-10.5 10.5S18.2 34.5 24 34.5 34.5 29.8 34.5 24 29.8 13.5 24 13.5zm0 19c-4.7 0-8.5-3.8-8.5-8.5s3.8-8.5 8.5-8.5 8.5 3.8 8.5 8.5-3.8 8.5-8.5 8.5z" />
    </>
  ),
});
