import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AssociatedStorage,
  BackupRetentionPeriod,
  BackupWindow,
  CaCertificate,
  ClusterId,
  CreatedBy,
  DatabaseEngine,
  DatabaseEngineVersion,
  DeleteProtection,
  DNSAddress,
  IAMAuthEnabled,
  InstanceClass,
  InternetAccessible,
  LicenseModel,
  MasterUsername,
  MultiAZ,
  Owner,
  PrivateIP,
  PublicIP,
  RDSKeyId,
  RDSSecurityGroupList,
  RDSUrl,
  StorageEncrypted,
  StorageType,
  SubnetID,
} from '../../../Components/MetadataField/MetaGridFields';

export const RDSInstanceMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {SubnetID(resource)}
      {CreatedBy(insight)}
      {Owner(insight)}
      {RDSSecurityGroupList(resource)}
      {InternetAccessible(resource)}
      {DNSAddress(resource)}
      {PublicIP(insight)}
      {PrivateIP(insight)}
      {RDSUrl(resource)}
      {InstanceClass(resource)}
      {ClusterId(resource)}
      {AssociatedStorage(resource)}
      {StorageType(resource)}
      {StorageEncrypted(resource)}
      {RDSKeyId(resource)}
      {CaCertificate(resource)}
      {BackupWindow(resource)}
      {BackupRetentionPeriod(resource)}
      {MultiAZ(resource)}
      {DatabaseEngine(resource)}
      {DatabaseEngineVersion(resource)}
      {MasterUsername(resource)}
      {LicenseModel(resource)}
      {IAMAuthEnabled(resource)}
      {DeleteProtection(resource)}
    </Grid>
  );
};
