import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { LineChart } from 'components/Visualization';
import {
  customizedXAxisTick,
  customizedYAxisTick,
} from 'components/Visualization/Charts/utils';
import { LegendProps } from 'containers/Dashboard/types';
import {
  DashboardOverviewCard,
  DashboardOverviewCardProps,
} from 'containers/Dashboard/utils/DashboardOverviewCard';
import {
  renderChange,
  renderLegend,
  renderLegendsAtTop,
} from 'containers/Dashboard/utils/utils';

export interface CardWithLineChartProps
  extends Omit<DashboardOverviewCardProps, 'content'> {
  legends?: LegendProps[];
  chartData: Record<string, any>[];
  isLoadingChart?: boolean;
  yFields: string[];
  lineChartColor: Record<string, string>;
  placeholder?: string;
  change?: number;
  legendW?: number;
  showLegendAtTop?: boolean;
  legendsSpacing?: number;
}

export const CardWithLineChart: React.FC<CardWithLineChartProps> = props => {
  const {
    legends,
    chartData,
    isLoadingChart,
    yFields,
    lineChartColor,
    placeholder,
    change = 0,
    legendW = legends ? 30 : 0,
    showLegendAtTop = false,
    legendsSpacing,
    ...rest
  } = props;

  return (
    <DashboardOverviewCard
      content={
        placeholder && !chartData?.length ? (
          <Center color={customTheme.colors.gray['250']}>{placeholder}</Center>
        ) : (
          <Stack
            w="full"
            spacing={6}
            h="full"
            isInline={!showLegendAtTop}
            px={3}
          >
            {legends && !showLegendAtTop && (
              <Box w={legendW + '%'} h="full">
                {renderLegend(legends, 0)}
              </Box>
            )}
            {legends && showLegendAtTop && (
              <HStack justify="space-between">
                <Box>{renderLegendsAtTop(legends, legendsSpacing)}</Box>
                <Box>{renderChange(change)}</Box>
              </HStack>
            )}
            <Stack w={100 - (showLegendAtTop ? 0 : legendW) + '%'} h="full">
              {!showLegendAtTop && renderChange(change)}
              <Box w="full" h="full" py={0}>
                <LineChart
                  data={chartData}
                  xField="Date"
                  yField={yFields}
                  isLoading={isLoadingChart}
                  legend={false}
                  colorMap={lineChartColor}
                  styles={{
                    lineChart: {
                      margin: { bottom: 0, left: 0, top: 1, right: 0 },
                    },
                    yAxis: {
                      axisLine: false,
                      tickLine: false,
                      tickCount: 4,
                      tick: props =>
                        customizedYAxisTick({
                          ...props,
                          style: { fontSize: 14, x: -6 },
                        }),
                    },
                    xAxis: {
                      //axisLine: false,
                      //tickLine: false,
                      interval: 'preserveStartEnd',
                      ticks: [
                        chartData?.[0]?.Date,
                        chartData?.[chartData?.length - 1]?.Date,
                      ],
                      tick: props =>
                        customizedXAxisTick({
                          ...props,
                          style: { fontSize: 14, fontWeight: 600, y: 6 },
                        }),
                    },
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        )
      }
      {...rest}
    />
  );
};
