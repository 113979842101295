import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { CommonAPIActivity } from '../../Components/CommonAPIActivity';

export interface Props {}

export const ResourceAPIActivity: FC<Props> = () => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={4}>
      <CommonAPIActivity />
    </Grid>
  );
};
