import React, { ReactNode, useRef } from 'react';

import { Categories, NativeResources } from '@ariksa/inventory-core/api';
import { Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { H2, renderTime } from 'components/DataDisplay';
import { CustomIconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { CloudInventorySummary } from 'containers/Inventory/CloudInventory/Components/Summary/CloudInventorySummary';
import { DataSummary } from 'containers/Inventory/CloudInventory/Components/Summary/DataSummary';
import { EC2Summary } from 'containers/Inventory/CloudInventory/Components/Summary/EC2Summary';
import { S3Summary } from 'containers/Inventory/CloudInventory/Components/Summary/S3Summary';
import { selectInventory } from 'containers/Inventory/CloudInventory/selector';
import { actions } from 'containers/Inventory/CloudInventory/slice';

interface Props {
  type: string;
  isOpen: boolean;
  onClose: () => void;
  header: ReactNode;
}

export const getInventorySummary = type => {
  switch (type) {
    case NativeResources.Ec2Instance:
      return <EC2Summary />;
    case NativeResources.SimpleStorageService:
      return <S3Summary />;
    case Categories.Data:
      return <DataSummary />;
    case CustomIconTypes.CloudInventory:
      return <CloudInventorySummary />;
    default:
      return null;
  }
};

export const InventorySummary = (props: Props) => {
  const dispatch = useDispatch();
  const { lastRefreshed } = useSelector(selectInventory);
  const { accountId, environmentId } = useAccessBoundary();
  const { isOpen, onClose, type, header } = props;
  const ref = useRef(document.querySelector('.portal-container'));

  useDeepCompareEffect(() => {
    dispatch(
      actions.getLastRefreshed({
        q: {
          getLatestWorkflowRequest: {
            environment_id: environmentId,
            account_ids: accountId ? [accountId] : undefined,
          },
        },
      }),
    );
  }, [dispatch, environmentId, accountId]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      styles={{
        content: { maxW: '1400px' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
      closeButton
      header={<H2>Summary for {header}</H2>}
      body={
        <Stack spacing={5}>
          <Text color={'gray.200'}>
            Last refreshed:{' '}
            {renderTime({ value: lastRefreshed.data.created_at })}
          </Text>
          {getInventorySummary(type)}
        </Stack>
      }
    />
  );
};
