import React, { FC, useEffect, useState } from 'react';

import { Box, Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { filter, includes, map } from 'lodash';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { selectSharedState } from 'containers/SharedState/selectors';

import { useEnvironmentContext } from '../../../../context';
import { selectEnvironment } from '../../../../selectors';
import { tagQuantifierOptions } from '../../../../utils';

interface Props {}

export const TagKeysCriteria: FC<Props> = () => {
  const { updateCriteria, tagKeyOptions } = useEnvironmentContext();
  const { formData } = useSelector(selectEnvironment);
  const { tagKeys } = useSelector(selectSharedState);
  const { criteria } = formData;
  const [selectedKeys, setSelectedKeys] = useState<Record<string, any>[]>([]);
  const [selectedCondition, setSelectedCondition] = useState<
    Record<string, any>
  >(tagQuantifierOptions[0]);

  useEffect(() => {
    criteria?.tag_keys_condition &&
      setSelectedKeys(
        filter(tagKeyOptions, o =>
          includes(criteria?.tag_keys_condition?.key, o.label),
        ),
      );
  }, [criteria?.tag_keys_condition, tagKeyOptions]);

  useEffect(() => {
    criteria?.tag_keys_condition &&
      setSelectedCondition(
        tagQuantifierOptions[0].label ===
          criteria?.tag_keys_condition?.key_condition
          ? tagQuantifierOptions[0]
          : tagQuantifierOptions[1],
      );
  }, [criteria?.tag_keys_condition, tagKeyOptions]);

  return (
    <Stack w="full">
      <Stack isInline w="full">
        <Box whiteSpace="nowrap" pt={1}>
          If an entity has tag key
        </Box>
        <Box w={28}>
          <Select
            options={tagQuantifierOptions}
            value={selectedCondition}
            onChange={selected => setSelectedCondition(selected)}
          />
        </Box>
        <Box w="full" flex={1}>
          <Select
            options={tagKeyOptions}
            isMulti
            value={selectedKeys}
            showTotalSelected={!!selectedKeys.length}
            onChange={selected =>
              updateCriteria('tag_keys_condition', {
                key_condition: selectedCondition?.value,
                key: map(selected, o => o.value),
              })
            }
            isLoading={tagKeys.isLoading}
          />
        </Box>
      </Stack>
      <Wrap>
        {map(criteria?.tag_keys_condition?.key, o => (
          <WrapItem>
            <Tag
              label={o}
              closeButton
              styles={{ label: { whiteSpace: 'pre-wrap' } }}
              onClose={() =>
                updateCriteria('tag_keys_condition', {
                  key: filter(criteria?.tag_keys_condition?.key, l => l !== o),
                  key_condition: selectedCondition?.value,
                })
              }
            />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
