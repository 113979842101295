import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ActivatedAt,
  ID,
  CreatedAt,
  Department,
  DisplayName,
  Email,
  LastLogin,
  LastUpdatedAt,
  Organization,
  PasswordChangedAt,
  SecondaryEmail,
  Title,
  TransitioningToStatus,
  UserType,
  UserPrincipalName,
  SignInName,
  AccountEnabled,
  AzureAdUsername,
  AzureAdName,
  MFAPhoneNumber,
  NetID,
  Country,
  CreationType,
} from '../../../Components/MetadataField/MetaGridFields';

export const AzureADUserMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {UserPrincipalName(resource)}
      {SignInName(resource)}
      {ID(insight)}
      {AccountEnabled(resource)}
      {TransitioningToStatus(resource)}
      {AzureAdUsername(resource)}
      {AzureAdName(resource)}
      {DisplayName(resource)}
      {Email(resource)}
      {SecondaryEmail(resource)}
      {MFAPhoneNumber(resource)}
      {NetID(resource)}
      {UserType(resource)}
      {Organization(resource)}
      {Department(resource)}
      {Title(resource)}
      {Country(resource)}
      {CreationType(resource)}
      {CreatedAt(resource, 'utc')}
      {ActivatedAt(resource)}
      {LastLogin(resource)}
      {LastUpdatedAt(resource)}
      {PasswordChangedAt(resource)}
    </Grid>
  );
};
