/**
 *
 * Blueprint Cards
 *
 */

import React, { memo, useEffect } from 'react';

import {
  Center,
  Flex,
  SimpleGrid,
  Text,
  Stack,
  useColorMode,
} from '@chakra-ui/react';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorMap, customTheme } from 'theme';

import { Card, H1 } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { AddButton } from 'components/DataEntry/Button';
import { usePageContentContext } from 'components/Layout';
import { policyHubTourSteps, Tour } from 'components/Tour';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';

import { getBlueprintIcon } from '../../utils';
import { actions } from '../slice';

import { BlueprintCard } from './BlueprintCard';

export const BlueprintCards = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { blueprints } = useSelector(selectBlueprints);

  const { colorMode } = useColorMode();
  const { contentHeight } = usePageContentContext();

  useEffect(() => {
    dispatch(actions.getBlueprints({ q: {} }));
  }, [dispatch]);

  return (
    <Tour fieldName={'policy-hub'} steps={policyHubTourSteps}>
      <Stack w={'full'} spacing={5} h={contentHeight + 'px'}>
        <Center
          bg={colorMap.primary(300)}
          p={4}
          flexDirection={'column'}
          className="policyHub_heading"
          borderRadius={6}
        >
          <H1 size="md" color={'white'} mb={2}>
            Policy Hub
          </H1>
          <Text fontSize="lg" color={'white'}>
            Codify policy once. Any cloud.
          </Text>
        </Center>

        <Flex w="full" justify="flex-end">
          <AddButton
            label="Add Blueprint"
            onClick={() => navigate('/policy-hub/blueprint/add')}
            data-tour="4"
          />
        </Flex>

        <Card
          styles={{ card: { w: 'full', h: 'full', flex: 1 } }}
          contentComponent={
            <WithSpinner
              loadStatus={{
                loading: blueprints.isLoading,
                loaded: blueprints.isSuccess,
              }}
            >
              <SimpleGrid
                columns={[1, 2, 2, 4]}
                spacing={customTheme.sizes.interCardSpacing}
                className="policy_blueprints"
                gridAutoRows="max-content"
              >
                {map(blueprints.data, (each, index) => (
                  <BlueprintCard
                    data={each}
                    icon={getBlueprintIcon(
                      each.created_by !== 'system',
                      each.compliance ?? each.name,
                      colorMode,
                    )}
                    title={each.name}
                    description={each.description}
                    key={`${index}-card`}
                    onClick={record => {
                      //dispatch(actions.setCurrentPolicy(record));
                      // @ts-ignore
                      navigate(`/policy-hub/blueprint/${record?.id}`);
                    }}
                  />
                ))}
              </SimpleGrid>
            </WithSpinner>
          }
        ></Card>
      </Stack>
    </Tour>
  );
});
