import { extendTheme, theme } from '@chakra-ui/react';

import { colors } from './theme2/colors';

export const commonThemeProps = extendTheme({
  ...theme,
  styles: {
    global: props => ({
      body: {
        fontSize: '15px',
        bg: props.colorMode === 'dark' ? '#141414' : colors.background,
        fontFamily: 'Rubik, Roboto, system-ui, sans-serif',
        //fontStyle: 'normal',
      },
      heading: {
        fontFamily: 'Rubik, Roboto, Georgia, serif',
      },
    }),
  },
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          top: '40px',
        },
      }),
    },
    Popover: {
      variants: {
        responsive: {
          popper: {
            maxWidth: 'unset',
            width: 'unset',
          },
        },
      },
    },
  },
  fonts: {
    ...theme.fonts,
    body: 'Rubik, Roboto, system-ui, sans-serif',
    heading: 'Rubik, Roboto, Georgia, serif', //Gilmer
  },
  config: {
    //useSystemColorMode: false,
    initialColorMode: 'light',
  },
  fontWeights: {
    xs: 300,
    sm: 400,
    md: 500,
    lg: 600,
    bold: 700,
  },
  //breakpoints: [...theme.breakpoints, '90em'],
  sizes: {
    ...theme.sizes,
    '7xl': '80rem',
    '8xl': '90rem',
    '9xl': '100rem',
    primaryNavBarHeight: '50px',
    secondaryNavBarHeight: '36px',
    breadcrumbHeight: '60px',
    totalHeaderHeight: '80px',
    layoutLeftWidth: '254px',
    formWidth: '600px',
    bodyPadding: 4,
    interCardSpacing: 4,
    dashboardCardSpacing: 14, //this is same as interCardSpacing
    minScreenWidth: '1024px',
    minUIWidth: '110rem',
    tableCell: '15px',
  },
  fontSizes: {
    ...theme.fontSizes,
    navBarHeader: '15px',
    '2xs': '10px',
    tableCell: '15px',
  },
  space: {
    ...theme.space,
    layoutLeftWidth: '254px',
    thLeftPaddingWithIcon: theme.space['12'],
  },
  radii: {
    ...theme.radii,
    fieldBorderRadius: '3px',
    cardBorderRadius: '8px',
  },
});
