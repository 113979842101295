import G6, { ModelConfig } from '@antv/g6';

import { TESTING_CIRCLE_NODE } from 'components/Visualization/Graphs/nodes/nodes_types';

import { colorMap } from '../../../../../theme';
import { colors } from '../../../../../theme/theme2/colors';

export function registerTestingCircleNode() {
  G6.registerNode(
    TESTING_CIRCLE_NODE,
    {
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 40,
              height: 40,
              radius: 20,
              fill: 'red',
              stroke: colorMap.primary(200),
              lineWidth: 0.5,
              opacity: 1,

              cursor: 'pointer',
            },
          },
          'container',
        );

        const { x, y, width = 0, height = 0 } = container.getBBox();

        console.log(x, y, width, height);
        group.addShape('rect', {
          attrs: {
            x,
            y,
            width: 100,
            height: 100,
            stroke: 'pink',
          },
          name: 'id',
        });

        const textStyle = {
          // x: x + -width / 2,

          textAlign: 'left',
          textBaseline: 'start',
          fontSize: 12,
          // fontStyle: 'bold',
          opacity: 1,
          fill: colors.gray['200'],
          cursor: 'pointer',
        };

        group.addShape('text', {
          attrs: {
            y: y + height + 20,
            text: `NodeId: ${cfg?.id}`,
            ...textStyle,
          },
          name: 'id',
        });

        group.addShape('text', {
          attrs: {
            y: height + 40,
            text: ``,
            ...textStyle,
          },
          name: 'id',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );
}
