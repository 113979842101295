import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CrossAccount,
  CloudManaged,
  PolicyType,
  AttachedUsers,
  AttachedGroups,
  WildcardResources,
  WildcardActions,
  Conditions,
  Permissions,
} from '../../Components/MetadataField/MetaGridFields';

export const IdentityPolicyMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields availabilityZone="None" vpc="None" />
        {AttachedUsers(insight)}
        {AttachedGroups(insight)}
        {CrossAccount(insight)}
        {PolicyType(insight)}
        {CloudManaged(resource)}
        {WildcardResources(resource)}
        {WildcardActions(resource)}
        {Conditions(resource)}
        {Permissions(resource)}
        <PolicyDocument
          label="Policy document"
          value={resource?.config?.PolicyDocument}
        />
      </Grid>
    </Stack>
  );
};
