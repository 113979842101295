import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyOrganizationIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g transform="translate(-1274 -1086)">
        <g>
          <g>
            <path
              d="M1417.8,1425c0,6.5,5.2,11.7,11.7,11.7h90.8c3.9-8.4,8.8-16.2,14.6-23.4h-105.4
					C1423.1,1413.3,1417.8,1418.6,1417.8,1425z M1697,1233.5c-0.6-1.4-1.4-2.7-2.5-3.8l-140-140.3c-1.1-1.1-2.3-1.9-3.8-2.5
					c-1.4-0.6-2.9-0.9-4.5-0.9h-151.7c-38.6,0-70,31.4-70,70.1v327.3c0,38.7,31.4,70.1,70,70.1h138.7c-5.5-7.2-10.2-15-13.8-23.4
					h-124.8c-25.8,0-46.6-21-46.7-46.8v-327.3c0-25.8,20.9-46.7,46.7-46.8h140v85.7c0,30.1,24.4,54.5,54.4,54.6h85.6v134.2
					c8.5,4.4,16.3,9.9,23.3,16.2V1238C1697.9,1236.4,1697.5,1234.9,1697,1233.5z M1589,1226.3c-17.2,0-31.1-14-31.1-31.2v-69.2
					l100.2,100.4H1589z M1592.8,1319.8h-163.4c-6.4,0-11.7,5.2-11.7,11.7c0,6.5,5.2,11.7,11.7,11.7h163.4c6.4,0,11.7-5.2,11.7-11.7
					C1604.5,1325.1,1599.3,1319.8,1592.8,1319.8z M1429.5,1249.7h46.7c6.4,0,11.7-5.2,11.7-11.7c0-6.5-5.2-11.7-11.7-11.7h-46.7
					c-6.4,0-11.7,5.2-11.7,11.7C1417.8,1244.4,1423.1,1249.7,1429.5,1249.7z"
            />
          </g>
        </g>
        <g>
          <path
            d="M1622.6,1598c-62.2,0-112.9-50.9-112.9-113.4c0-62.5,50.6-113.4,112.9-113.4c62.2,0,112.9,50.9,112.9,113.4
				C1735.5,1547.1,1684.9,1598,1622.6,1598z M1622.6,1376.1c-59.5,0-107.9,48.6-107.9,108.4c0,59.8,48.4,108.4,107.9,108.4
				s107.9-48.6,107.9-108.4C1730.5,1424.8,1682.1,1376.1,1622.6,1376.1z"
          />
        </g>
        <g>
          <path
            d="M1681.3,1466.7c1.2,0,2.2-1,2.2-2.2V1442c0-0.9-0.6-1.8-1.5-2.1l-53.5-17.6c-0.4-0.1-0.9-0.1-1.4,0
				l-53.5,17.6c-0.9,0.3-1.5,1.1-1.5,2.1v22.5c0,1.2,1,2.2,2.2,2.2h9.2v45.9h-4c-1.2,0-2.2,1-2.2,2.2v3.7h-3c-1.2,0-2.2,1-2.2,2.2
				v17.3c0,1.2,1,2.2,2.2,2.2h107c1.2,0,2.2-1,2.2-2.2v-17.3c0-1.2-1-2.2-2.2-2.2h-3v-3.7c0-1.2-1-2.2-2.2-2.2h-4v-45.9H1681.3z
				 M1576.5,1443.6l51.3-16.9l51.3,16.9v18.8h-9.2h-22.8h-38.5h-22.8h-9.2V1443.6z M1610.7,1512.6v-45.9h34.1v45.9H1610.7z
				 M1587.9,1466.7h18.5v45.9h-18.5V1466.7z M1679.1,1535.7h-102.7v-12.9h3h96.6h3V1535.7z M1673.9,1518.5h-92.2v-1.5h4h22.8h38.5
				h22.8h4V1518.5z M1667.7,1512.6h-18.5v-45.9h18.5V1512.6z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
