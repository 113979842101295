import { registerTreeConnector } from 'components/Visualization/Graphs/elements/v1';
import { registerHeaderNode } from 'components/Visualization/Graphs/nodes/register_header_node';
import { registerHiddenRootNode } from 'components/Visualization/Graphs/nodes/register_invisible_root_node';
import { registerMultiConnector } from 'components/Visualization/Graphs/nodes/register_multi_edge';
import { registerPaginationNode } from 'components/Visualization/Graphs/nodes/register_pagination_node';
import { registerTestingCircleNode } from 'components/Visualization/Graphs/nodes/register_test_circle_node';
import { registerTestingRectNode } from 'components/Visualization/Graphs/nodes/register_test_rect_node';

import { registerAccountNode } from './register_account_nodes';
import { registerActionNode } from './register_action_node';
import { registerBusinessUnittNode } from './register_business_unit_node';
import { registerCommandNode } from './register_command_node';
import { registerIngressNode } from './register_ingress_node';
import { registerInterNetNode } from './register_internet_nodes';
import { registerOrgAccountNode } from './register_org_account_node';
import { registerOrgGroupNode } from './register_org_group_node';
import { registerOrgUnitNode } from './register_org_unit_node';
import { registerPermissionsNode } from './register_permission_node';
import { registerPolicyNode } from './register_policy_node';
import { registerPortNode } from './register_port_nodes';
import { registerRegionNode } from './register_region_nodes';
import { registerResourceInstanceNode } from './register_resource_instance_node';
import { registerResourceNode } from './register_resource_node';
import { registerResourceTypeNode } from './register_resource_type_node';
import { registerEntityNode } from './register_role_entity_node';
import { registerRolePolicyNode } from './register_role_policy_node';
import { registerUserGroupNode } from './register_user_group_node';
import { registerUserNode } from './register_user_node';

registerTestingRectNode();
registerTestingCircleNode();

registerAccountNode();
registerBusinessUnittNode();
registerActionNode();
registerCommandNode();
registerInterNetNode();
registerOrgUnitNode();
registerOrgGroupNode();
registerPermissionsNode();
registerRolePolicyNode();
registerPortNode();
registerRegionNode();
registerResourceNode();
registerOrgAccountNode();
registerEntityNode();
registerPolicyNode();
registerResourceTypeNode();
registerUserGroupNode();
registerUserNode();
registerTreeConnector();
registerIngressNode();
registerResourceInstanceNode();
registerHeaderNode();
registerHiddenRootNode();

registerMultiConnector();
registerPaginationNode();
