import React, { FC, useEffect, useState } from 'react';

import { HStack, Center } from '@chakra-ui/react';
import map from 'lodash/map';
import { colorMap, customTheme } from 'theme';

import { OptionItem } from 'components/DataEntry/Button/types';
import { weekRangeOptions } from 'components/DataEntry/Button/utils';

interface Props {
  options?: OptionItem[];
  value?: OptionItem;
  onChange?(value: any);
}

export const WeekRangeButtonGroup: FC<Props> = props => {
  const { value, onChange, options } = props;
  const [day, setDay] = useState(options?.[0] ?? weekRangeOptions[1]);

  useEffect(() => {
    if (value) {
      setDay(value);
    }
  }, [value]);

  const handleIntervalSelection = item => {
    setDay(item);
    onChange?.(item);
  };

  return (
    <HStack spacing={1}>
      {map(options ?? weekRangeOptions, o => (
        <Center
          onClick={() => handleIntervalSelection(o)}
          bg={
            day?.value === o?.value
              ? colorMap.primary(50)
              : customTheme.colors.gray['50']
          }
          boxSize={6}
          borderRadius="full"
          fontSize="12px"
          cursor={o?.isDisabled ? 'not-allowed' : 'pointer'}
        >
          {o?.label}
        </Center>
      ))}
    </HStack>
  );
};
