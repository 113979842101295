import React, { FC } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import { AwsSummary } from './AwsSummary';
import { SnowflakeSummary } from './SnowflakeSummary';

export const MetadataDrawerSummary: FC = () => {
  const { cloudProvider } = useSelector(selectActiveResource);

  return (
    <Stack w="full" spacing={3}>
      {cloudProvider === CloudProviders.Snowflake ? (
        <SnowflakeSummary />
      ) : (
        <AwsSummary />
      )}
    </Stack>
  );
};
