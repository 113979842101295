import {
  customizedXAxisTick,
  customizedYAxisTick,
  rotatedXAxisTick,
} from 'components/Visualization/Charts/utils';

import { customTheme } from '../../../../../theme';

export const commonVulnerabilityLineChartStyles = {
  lineColors: [customTheme.colors.critical, customTheme.colors.high],
  line: {
    dot: false,
  },
  lineChart: {
    margin: {
      top: 20,
      left: -25,
      right: 10,
      bottom: 1,
    },
  },
  xAxis: {
    tickLine: false,
    tick: rotatedXAxisTick,
    interval: 0,
    padding: { right: 0 },
  },
  yAxis: {
    tickLine: false,
    axisLine: false,
  },
};

export const vulnerabilityLineChartStyles = {
  ...commonVulnerabilityLineChartStyles,
  lineChart: {
    margin: {
      top: 20,
      left: -25,
      right: 10,
      bottom: -18,
    },
  },
};

export const lineChartStylesVulnerabilityDashboard = {
  ...commonVulnerabilityLineChartStyles,
  lineChart: {
    margin: {
      top: 20,
      left: 5,
      right: 30,
      bottom: -18,
    },
  },
  xAxis: {
    ...commonVulnerabilityLineChartStyles.xAxis,
    tick: props =>
      customizedXAxisTick({
        ...props,
        style: { fontSize: 14, fontWeight: 600, y: 6 },
      }),
  },
  yAxis: {
    ...commonVulnerabilityLineChartStyles.yAxis,
    tick: props =>
      customizedYAxisTick({
        ...props,
        style: { fontSize: 14, x: -6 },
      }),
  },
};

export const criticalOrHighVulnerabilitiesChartStyles = {
  ...lineChartStylesVulnerabilityDashboard,
  lineChart: {
    margin: {
      top: 20,
      left: 5,
      right: 30,
      bottom: -18,
    },
  },
  yAxis: {
    ...lineChartStylesVulnerabilityDashboard.yAxis,
    tickLine: false,
    axisLine: false,
  },
};
