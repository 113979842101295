import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { ConnectivityType } from '../../../Components/MetadataField/MetaGridFields';

export const NATGatewayMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const addresses = resource?.network?.NatGatewayAddresses;

  const columns = [
    { header: 'Public IP', accessor: 'PublicIp' },
    { header: 'Private IP', accessor: 'PrivateIp', align: 'left' },
    { header: 'Interface ID', accessor: 'NetworkInterfaceId', align: 'left' },
    { header: 'Allocation ID', accessor: 'AllocationId', align: 'left' },
  ];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {ConnectivityType(resource)}
      <PolicyDocument
        label="Provisioned bandwidth"
        value={resource?.network?.ProvisionedBandwidth}
      />
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={addresses} />}
            iconType={NativeResources.ElasticIp}
            label="Gateway addresses"
            totalCount={addresses?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
