import { IDashboardLayout } from 'app/components/Visualization/CDashboard/types';

import { availableWidgets1 } from './widget';

export const dashboardWidgets1: IDashboardLayout = {
  id: 'dashboard_1',
  title: 'Dashboard Title',
  widgets: [
    {
      type: 'card',
      i: 'totalEntities',
      x: 0,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'policies',
      x: 2,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'enforcedChecks',
      x: 4,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'passedChecks',
      x: 6,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'failedChecks',
      x: 8,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'card',
      i: 'checkExceptions',
      x: 10,
      y: 0,
      w: 2,
      h: 2.5,
      isResizable: false,
      static: true,
    },
    {
      type: 'pie-chart',
      i: 'violationsBySeverity',
      x: 0,
      y: 2.5,
      w: 4,
      h: 7,
      minH: 2,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'line-chart',
      i: 'totalViolations',
      x: 4,
      y: 2.5,
      w: 4,
      h: 7,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
    {
      type: 'bar-chart',
      i: 'passedChecksHistoricalTrend',
      x: 8,
      y: 2.5,
      w: 4,
      h: 7,
      minH: 4,
      minW: 2,
      isResizable: false,
      static: true,
    },
  ],
  available: availableWidgets1,
};
