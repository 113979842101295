/**
 *
 * Logo
 *
 */
import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { CompanyLogoDarkIcon } from 'components/Icons/ReactCustomIcons/CompanyLogoDarkIcon';

interface Props extends FlexProps {}

export const LogoDark: React.FC<Props> = props => {
  return (
    <Flex align="left" w="100px" {...props} py={4}>
      <Link to="/home">
        <CompanyLogoDarkIcon />
      </Link>
    </Flex>
  );
};
