export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return { r, g, b }; //return 23,14,45 -> reformat if needed
  }
  return {};
}

export function colorShade(hex, shade: number) {
  const { r = 0, g = 0, b = 0 } = hexToRgb(hex);
  console.log(
    `rgb(${round(r * shade)},${round(g * shade)},${round(b * shade)})`,
  );
  return `rgb(${round(r * shade)},${round(g * shade)},${round(b * shade)})`;
}

export function colorOpacity(hex, shade: number) {
  const { r = 0, g = 0, b = 0 } = hexToRgb(hex);

  return `rgba(${r},${g},${b},${shade})`;
}

const round = Math.round;
