import G6 from '@antv/g6';

export const ROLE_CHAINING = 'ROLE_CHAINING';

G6.registerCombo(
  ROLE_CHAINING,
  {
    drawShape: function drawShape(cfg, group) {
      const self = this;
      if (cfg) {
        // Get the padding from the configuration
        cfg.padding = cfg.padding || [10, 0, 10, 0];
      }

      console.log(cfg);

      // Get the shape's style, where the style.width and style.height correspond to the width and height in the figure of Illustration of Built-in Rect Combo
      const style = self.getShapeStyle(cfg);
      console.log(style);
      // Add a rect shape as the keyShape which is the same as the extended rect Combo
      const rect = group?.addShape('rect', {
        attrs: {
          ...style,
          width: cfg?.width ?? style.width,
          height: cfg?.height ?? style.height,
          radius: 4,
        },
        name: 'combo-keyShape',
      });
      // Add the circle on the right
      return rect!;
    },
    // Define the updating logic of the right circle
    afterUpdate: function afterUpdate(cfg, combo) {
      const group = combo?.get('group');
      // Find the circle shape in the graphics group of the Combo by name
    },
  },
  'rect',
);
