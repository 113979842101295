import React from 'react';

import { Icon } from '@chakra-ui/react';

export const TrashIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      clipRule="evenodd"
      d="M2.25 6.12195C2.25 5.70773 2.58579 5.37195 3 5.37195H21C21.4142 5.37195 21.75 5.70773 21.75 6.12195C21.75 6.53616 21.4142 6.87195 21 6.87195H3C2.58579 6.87195 2.25 6.53616 2.25 6.12195Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M19.1083 15.6335C18.8151 18.2884 18.6685 19.6158 17.9191 20.5322C17.657 20.8526 17.3469 21.1304 16.9996 21.3557C16.0065 22 14.671 22 11.9999 22C9.32893 22 7.99343 22 7.00028 21.3557C6.65302 21.1304 6.34284 20.8526 6.0808 20.5322C5.33138 19.6158 5.18477 18.2883 4.89155 15.6335L3.76465 5.43042H20.2352L19.1083 15.6335ZM13.9999 10.4652C14.4141 10.4652 14.7499 10.801 14.7499 11.2152V16.2152C14.7499 16.6294 14.4141 16.9652 13.9999 16.9652C13.5857 16.9652 13.2499 16.6294 13.2499 16.2152V11.2152C13.2499 10.801 13.5857 10.4652 13.9999 10.4652ZM10.7499 11.2152C10.7499 10.801 10.4141 10.4652 9.99988 10.4652C9.58567 10.4652 9.24988 10.801 9.24988 11.2152V16.2152C9.24988 16.6294 9.58567 16.9652 9.99988 16.9652C10.4141 16.9652 10.7499 16.6294 10.7499 16.2152V11.2152Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M10.3379 1.25C10.3539 1.25 10.3699 1.25001 10.386 1.25001H13.614C13.6301 1.25001 13.6462 1.25 13.6621 1.25C14.1832 1.24995 14.6495 1.24991 15.0265 1.3024C15.4394 1.3599 15.854 1.49282 16.1901 1.84089C16.5227 2.18528 16.6462 2.6038 16.7001 3.01872C16.7501 3.40382 16.75 3.88196 16.75 4.42482L16.75 6.1424H15.25V4.47091C15.25 3.86802 15.2485 3.48874 15.2126 3.21184C15.1791 2.9539 15.1279 2.9003 15.1111 2.88284C15.1108 2.88253 15.1105 2.88221 15.1101 2.88187C15.0958 2.86692 15.0512 2.82031 14.8196 2.78807C14.5585 2.75171 14.1984 2.75001 13.614 2.75001H10.386C9.80166 2.75001 9.44152 2.75171 9.18037 2.78807C8.94884 2.82031 8.90419 2.86692 8.88988 2.88187C8.88955 2.88221 8.88924 2.88253 8.88894 2.88284C8.87208 2.9003 8.82093 2.9539 8.78744 3.21184C8.75149 3.48874 8.75 3.86802 8.75 4.47091V6.1424H7.25V4.47091C7.25 4.45549 7.25 4.44012 7.25 4.42481C7.24996 3.88195 7.24993 3.40382 7.29992 3.01872C7.35379 2.6038 7.47734 2.18528 7.8099 1.84089C8.146 1.49282 8.56059 1.3599 8.97352 1.3024C9.35054 1.24991 9.81684 1.24995 10.3379 1.25Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);
