import { PayloadAction } from '@reduxjs/toolkit';
import { Optional } from 'types/utils';

import { PageInfo } from 'components/Navigation';

import { QueryContext } from './utils/QueryContext';

export enum QueryStatus {
  uninitialized = 'uninitialized',
  pending = 'pending',
  fulfilled = 'fulfilled',
  failed = 'failed',
  unknown = 'unknown',
}

export interface QueryError {
  code?: number;
  title?: string;
  description?: string;
  error?: any;
}

type IfAnyOptions<Q> = Q extends never ? { q?: any } : { q: Q };

export type QueryParams<P, Q = any> = IfAnyOptions<Q> & {
  // q stands for request params for the api call
  data?: P;
  page?: PageInfo;
  status?: QueryStatus;
  error?: QueryError;
  reset?: boolean;
  fromCache?: boolean;

  onCall?: CallFn;
  onSuccess?: SuccessFn<P>;
  onError?: ErrFn;
  onResponse?: ResponseFn<P>;
  errMsg?: string;
  successMsg?: string;
};

export type QueryGenerator<P, Q> = (
  ctx: QueryContext<P, Q>,
) => Generator<any, any, any>;

// P => expected api payload (i.e. response)
// Q => data required to do the api call
export interface QueryAction<P, Q = any>
  extends PayloadAction<QueryParams<P, Q>> {}

export type PartialAction<T> = PayloadAction<Partial<T>>;

export type CallFn = () => void;
export type SuccessFn<T> = (res: Optional<T>) => void;
export type ErrFn = (error: QueryError) => void;
export type ResponseFn<P> = (
  res: Optional<P>,
  err: Optional<QueryError>,
) => void;

export type MapFn<A, B> = (a: A) => B;
