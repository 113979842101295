import { findLast } from 'lodash';
import round from 'lodash/round';

export default function formatNumber(num, precision = 0) {
  if (num < 10000) return num?.toLocaleString();

  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    return (
      round(num / found.threshold, precision)?.toLocaleString() + found.suffix
    );
  }

  return num?.toLocaleString();
}

export const formatNumberWithComma = num => {
  return num?.toLocaleString();
};

export const chartNumberFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = findLast(lookup, item => num >= item.value);
  return item
    ? (num / item?.value)
        .toFixed(digits)
        .replace(regexp, '')
        .concat(item?.symbol)
    : '0';
};
