import React from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { IconType } from 'components/Icons';

export interface MetaHeaderWithIconProps {
  iconType: IconType;
  iconBGColor?: string;
  label?: React.ReactNode;
  header?: React.ReactNode;
  totalCount?: number;
  isLoading?: boolean;
}

export const MetaHeaderWithIcon = (props: MetaHeaderWithIconProps) => {
  const {
    iconType,
    label,
    iconBGColor,
    totalCount = null,
    isLoading = false,
    header,
  } = props;

  return (
    <WithResourceIcon
      resourceType={iconType}
      bgColor={iconBGColor}
    >
      {header ? (
        header
      ) : (
        <HStack flex={1}>
          <HStack spacing={0}>
            <Box>{label}</Box>
            <Box>{totalCount !== null && ':'}</Box>
          </HStack>
          {totalCount !== null && (
            <Box>
              {isLoading ? <CustomSpinner loading={isLoading} /> : totalCount}
            </Box>
          )}
        </HStack>
      )}
    </WithResourceIcon>
  );
};
