import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RdsClusterIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M315,66.6c0-31.7-70.5-57.4-157.5-57.4C70.5,9.2,0,34.9,0,66.6v47.1c0,31.7,70.5,57.4,157.5,57.4
		c5.9,0,11.8-0.1,17.6-0.4c3.1-41,67.5-65.1,139.9-71V66.6z"
      />
      <path
        d="M0,145.5l0,4.3v73.6c0,31.7,70.5,57.4,157.5,57.4c5.9,0,11.7-0.1,17.4-0.4v-82.1c-5.7,0.2-11.5,0.4-17.4,0.4
		C74.5,198.6,6,175.2,0,145.5z"
      />
      <path
        d="M0.1,256.3l0,4.3v73.6c0,31.7,70.5,57.4,157.5,57.4c5.9,0,11.7-0.1,17.4-0.4v-60.7v-21.4
		c-5.7,0.2-11.5,0.4-17.4,0.4C74.5,309.5,6,286,0.1,256.3z"
      />
      <path
        d="M354.5,120.4c-87,0-157.5,25.7-157.5,57.4V225c0,31.7,70.5,57.4,157.5,57.4S512,256.7,512,225v-47.1
		C512,146.1,441.5,120.4,354.5,120.4z"
      />
      <path
        d="M354.5,309.9c-83,0-151.5-23.4-157.4-53.2l-0.1,4.3v73.6c0,31.7,70.5,57.4,157.5,57.4S512,366.2,512,334.5
		v-73.6c0-1.4-0.3-2.8-0.6-4.3C505.4,286.4,437.6,309.9,354.5,309.9z"
      />
      <path
        d="M354.5,420.7c-83,0-151.4-23.5-157.4-53.2l-0.1,4.2v73.6c0,31.7,70.5,57.4,157.5,57.4S512,477.1,512,445.4
		v-73.6c0-1.4-0.3-2.9-0.6-4.2C505.4,397.3,437.6,420.7,354.5,420.7z"
      />
    </g>
  </Icon>
);
