/**
 *
 * Side Nav
 *
 */

import React, { useMemo } from 'react';

import { Box, Flex, Icon, HStack } from '@chakra-ui/react';
import { isFunction } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import { H3, PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { customTheme } from '../../../../theme';
import { Card, NoDataAvailable } from '../../DataDisplay';

import { useSideNavContext } from './SideNavContext';
import { defaultStyles, menuItemStyles } from './styles';
import { MiddleComponentProps } from './types';

export const MiddleComponent = (props: MiddleComponentProps) => {
  const {
    menuItems,
    minHeight = 96,
    height,
    styles,
    showContentHeader = true,
    isLoading,
    isWide,
    contentOutsideCard,
  } = useSideNavContext();
  const { currentTab } = props;

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return merge(props, styles);
  }, [styles]);

  const baseMenuItemStyles = useMemo(() => {
    const props = cloneDeep(menuItemStyles);
    return merge(props, currentTab?.styles);
  }, [currentTab?.styles]);

  const content = () => (
    <Flex
      pos={isWide ? 'absolute' : 'relative'}
      {...baseStyles?.componentWrapper}
    >
      <Box w="full" h="full">
        {isFunction(currentTab?.component)
          ? currentTab.component()
          : currentTab?.component}
      </Box>
    </Flex>
  );

  const header = () => (
    <HStack justify="space-between" w="full" pb={5}>
      {showContentHeader &&
        (currentTab?.contentHeader ? (
          <Box {...baseMenuItemStyles?.titleWrapper}>
            <H3>{currentTab?.contentHeader}</H3>
          </Box>
        ) : (
          <HStack {...baseMenuItemStyles?.titleWrapper}>
            <PageHeaderWithIcon
              label={currentTab?.title}
              icon={currentTab?.customIcon || currentTab?.icon}
            />
          </HStack>
        ))}
      {currentTab?.rightContentHeader && (
        <Flex {...baseMenuItemStyles?.titleWrapper}>
          {currentTab?.rightContentHeader}
        </Flex>
      )}
    </HStack>
  );

  return (
    <>
      {contentOutsideCard ? (
        content()
      ) : (
        <HStack
          minH={isWide ? minHeight : 'auto'}
          h={height ? height : 'full'}
          spacing={
            styles?.layout?.spacing ?? customTheme.sizes.interCardSpacing
          }
        >
          <Card
            styles={{
              card: {
                w: 'full',
                overflow: 'auto',
              },
              header: { pb: 0 },
              ...styles?.contentOuterWrapper,
            }}
            headerComponent={
              !showContentHeader || isEmpty(menuItems) ? '' : header()
            }
            contentComponent={
              <Box w="full" h="full" pos="relative">
                {isLoading ? (
                  <Box w="full" h="full">
                    <CustomSpinner loading={isLoading} size="xl" />
                  </Box>
                ) : isEmpty(menuItems) ? (
                  <NoDataAvailable />
                ) : (
                  content()
                )}
              </Box>
            }
          />
          {currentTab?.showHelpContent && (
            <Card
              styles={{
                card: {
                  w: 'md',
                  //h: 'full',
                },
              }}
              contentComponent={currentTab?.helpContent}
            />
          )}
        </HStack>
      )}
    </>
  );
};
