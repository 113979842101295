import { MenuStyles } from './types';

export const defaultStyles: MenuStyles = {
  item: {
    _hover: { outline: 'none' },
    _focus: { outline: 'none', bg: 'hover', color: 'primary' },
  },
  itemOption: {
    py: '0.2rem',
    _hover: { outline: 'none' },
    _focus: { outline: 'none', bg: 'hover', color: 'primary' },
  },
  icon: {
    boxSize: 5,
    color: 'primary',
  },
};

export const menuStyles = {
  button: {
    borderRadius: 'full',
    w: 6,
    h: 6,
    bg: 'transparent',
    space: 0,
    cursor: 'pointer',
    color: 'primary',
    borderColor: 'none',
    _hover: {
      backgroundColor: 'none',
      color: 'primary',
      cursor: 'pointer',
    },
  },
};
