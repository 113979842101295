import { pizzlyPkey } from '../config';
import { PIZZLY_SERVICE } from '../service_urls';
import { withPrefixArgs } from '../utils';

import { IPizzlyPkey, IRepoID } from './types';

const withArgs = withPrefixArgs(PIZZLY_SERVICE);

export const gitlabApis = {
  getGitLabUserInfo() {
    return withArgs<IPizzlyPkey>(`/proxy/gitlab/user`)({
      pizzly_pkey: pizzlyPkey,
    });
  },
  getGitLabRepos() {
    return withArgs<any>(`/proxy/gitlab/projects`)({
      pizzly_pkey: pizzlyPkey,
      membership: true,
    });
  },
  getGitLabRepoBranches(params: IRepoID) {
    return withArgs<IPizzlyPkey & IRepoID>(
      `/proxy/gitlab/projects/{repoId}/repository/branches`,
    )({
      pizzly_pkey: pizzlyPkey,
      ...params,
    });
  },
};
