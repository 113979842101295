import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ManagedByFirewallManager,
  ManagedRuleGroups,
  RuleGroups,
  WafV2Rules,
} from '../../../Components/MetadataField/MetaGridFields';

export const WafV2Metadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields availabilityZone="None" />
        {ManagedByFirewallManager(resource)}
        {ManagedRuleGroups(resource)}
        {RuleGroups(resource)}
        {WafV2Rules(resource)}
      </Grid>
    </Stack>
  );
};
