import React, { useEffect, useState } from 'react';

import { Stack } from '@chakra-ui/react';
import { filter, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { DetailsIdentifier, PageHeaderWithIcon } from 'components/DataDisplay';
import { Form } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  row: Record<string, any>;
}

export const AddPolicyToBlueprint: React.FC<Props> = props => {
  const { isOpen, onClose, row } = props;
  const dispatch = useDispatch();
  const { blueprints } = useSelector(selectSharedState);
  const { policyAction } = useSelector(selectPolicyHub);
  const [selected, setSelected] = useState<Record<string, any>>({});
  const [blueprintOptions, setBlueprintOptions] = useState<
    Record<string, any>[]
  >([]);

  //get blueprints
  useEffect(() => {
    dispatch(sharedStateActions.getBlueprints({ q: {} }));
  }, [dispatch, row]);

  useEffect(() => {
    const options: Record<string, any>[] = map(
      filter(
        blueprints.data,
        o => !(o?.created_by === 'system' || !!o?.compliance),
      ),
      o => ({
        label: o?.name,
        value: o?.id,
      }),
    );
    setBlueprintOptions(options);
    setSelected(options[0]);
  }, [blueprints.data]);

  //on click ok, add policy to blueprint
  const handleOnClickOk = () => {
    dispatch(
      actions.addPolicyToBlueprint({
        q: {
          blueprintId: selected?.value,
          ruleInstanceMultiCreate: { rules: [{ rule_id: row?.id }] },
        },
        onSuccess: () => onClose(),
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <PageHeaderWithIcon
          label={'Add Policy to Blueprint'}
          iconType={IconTypes.Attach}
          reversed
          iconBgColor={'green'}
        />
      }
      styles={{
        modal: { size: 'xl' },
      }}
      body={
        <Stack w="full" h="full" spacing={5}>
          <DetailsIdentifier label="Summary" value={row?.config_id} />
          <Form
            schema={{
              blueprint: {
                type: 'react-select',
                label: 'Select blueprint',
                value: selected,
                options: blueprintOptions,
                onChange: setSelected,
                isLoading: blueprints.isLoading,
              },
            }}
            buttonOptions={{
              submit: {
                name: 'Ok',
                isLoading: blueprints.isLoading || policyAction.isLoading,
                isDisabled: !selected?.value,
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: () => onClose(),
              },
            }}
            handleSubmit={handleOnClickOk}
          />
        </Stack>
      }
    />
  );
};
