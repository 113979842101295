import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.cloudAccountWizard || initialState;

export const selectCloudAccountWizard = createSelector(
  [selectDomain],
  cloudAccountWizardState => cloudAccountWizardState,
);
