import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RemediateIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" p={0.5} {...props}>
    <path fill="none" d="M9 4h6v2H9zm4 5h-2v3H8v2h3v3h2v-3h3v-2h-3z" />
    <path d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zM9 4h6v2H9V4zm7 10h-3v3h-2v-3H8v-2h3V9h2v3h3v2z" />
  </Icon>
);
