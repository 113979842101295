import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AgentIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M109.7,283.4C49.2,283.4,0,332.7,0,393.2s49.2,109.7,109.7,109.7s109.7-49.2,109.7-109.7
		S170.2,283.4,109.7,283.4z M109.7,484.6c-50.4,0-91.4-41-91.4-91.4s41-91.4,91.4-91.4s91.4,41,91.4,91.4S160.1,484.6,109.7,484.6z"
      />
      <path
        d="M164.6,384h-45.7v-45.7c0-5.1-4.1-9.1-9.1-9.1c-5.1,0-9.1,4.1-9.1,9.1v54.9c0,5.1,4.1,9.1,9.1,9.1h54.9
		c5.1,0,9.1-4.1,9.1-9.1C173.7,388.1,169.6,384,164.6,384z"
      />
      <path
        d="M506.7,383.1c-18.4-34.7-82.1-72.3-84.9-74c-11.7-6.6-23.8-8.7-34-5.9c-7.7,2.1-14,7-18.2,14.1
		c-9.3,11.2-20.9,24.2-23.7,26.2c-21.7,14.7-38.9,12.9-57.5-5.8l-105-105c-18.7-18.7-20.4-35.9-5.8-57.5c2-2.8,15.1-14.4,26.2-23.7
		c7.1-4.2,12-10.5,14.1-18.2c2.8-10.3,0.7-22.3-6-34.1c-1.6-2.7-39.2-66.5-73.9-84.8c-17.6-9.3-38.9-6.1-53,8L61.8,45.6
		c-55.2,55.2-57,122.5-5.2,194.9c2.9,4.1,8.6,5,12.8,2.1c4.1-2.9,5.1-8.6,2.1-12.8c-47.1-65.9-46.1-121.9,3.3-171.3l23.2-23.2
		c8.4-8.4,21.1-10.3,31.5-4.7c30.1,15.9,66.3,77.3,66.6,77.8c4.1,7.2,5.7,14.7,4.2,20.1c-0.9,3.3-3,5.8-6.3,7.6l-1.4,1
		c-7.8,6.5-26.4,22.3-30.2,28c-5.6,8.3-9.3,16.4-11.2,24.4c-6.5-13-11.8-28-13.5-44.6c-0.5-5-4.9-8.7-10-8.2c-5,0.5-8.7,5-8.2,10
		c6.6,63.6,56,104.9,58.1,106.7c0.2,0.2,0.5,0.3,0.8,0.4l97,97c0.4,0.4,0.8,0.8,1.2,1.1c0.3,0.5,0.5,1,1,1.4
		c1.7,1.7,43,41.8,106,48.3c0.3,0,0.6,0,1,0c4.6,0,8.6-3.5,9.1-8.2c0.5-5-3.1-9.5-8.2-10c-19.3-2-36.5-7.7-50.7-14.3
		c7-2.1,14.1-5.6,21.3-10.5c5.7-3.9,21.5-22.4,28-30.2l1-1.4c1.8-3.3,4.3-5.3,7.6-6.2c5.4-1.5,12.9,0.1,20,4.1
		c17.2,10.1,64.7,41.7,77.9,66.7c5.5,10.5,3.6,23.1-4.7,31.5l-23.2,23.2c-49.3,49.3-107.8,49.8-173.8,1.5c-4.1-3-9.8-2.1-12.8,2
		c-3,4.1-2.1,9.8,2,12.8c36,26.3,70.7,39.5,103.4,39.5c34,0,65.7-14.3,94.2-42.7l23.2-23.2C512.8,422,516,400.7,506.7,383.1z"
      />
    </g>
  </Icon>
);
