import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { resourceIcons, iconSwitch } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { ROLE_ENTITY_NODE } from './nodes_types';

export function registerEntityNode() {
  G6.registerNode(
    ROLE_ENTITY_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 200,
              height: 45,
              fill: colors.white,
              // stroke: colorMap.primary(300),
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        // @ts-ignore
        let img = iconSwitch(cfg?.data?.resource_type, resourceIcons);
        // console.log(cfg?.data?.resourceType);

        group.addShape('image', {
          attrs: {
            x: x + 10,
            y: y + 13,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        group.addShape('text', {
          attrs: {
            x: width / 2 + 5,
            y: height / 2 + 5,
            textAlign: 'center',
            textBaseline: 'start',
            // @ts-ignore
            text: 'Trusted Entity',
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'role',
        });

        group.addShape('text', {
          attrs: {
            x: x,
            y: y + 70,
            textAlign: 'left',
            // @ts-ignore
            text: `Name: ${cfg?.data?.name}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            opacity: 1,
            // fontStyle: 'bold',
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'type',
        });

        group.addShape('text', {
          attrs: {
            x: x,
            y: y + 90,
            // @ts-ignore
            text: `GUID: ${cfg?.data?.guid ?? '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            opacity: 1,
            // fontStyle: 'bold',
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'guid',
        });

        group.addShape('text', {
          attrs: {
            x: x,
            y: y + 110,
            // @ts-ignore
            text: `ARN: ${cfg?.data?.arn ?? '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            opacity: 1,
            // fontStyle: 'bold',
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'guid',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
