import { useCallback } from 'react';

import { identity } from 'lodash';
import { useSelector } from 'react-redux';

import { selectApp } from 'containers/App/selectors';

export function useAccessBoundary() {
  const { accessBoundary } = useSelector(selectApp);
  const { account, environmentId } = accessBoundary;

  const withAccessBoundary = useCallback(
    <T>(params: T) => {
      return {
        ...params,
        accountId: account?.id as any,
        environmentId: environmentId as any,
      };
    },
    [account?.id, environmentId],
  );

  const { account: _1, environment, ...rest } = accessBoundary;

  return {
    withAccessBoundary,
    // return trimmed version of accessBoundary
    // this creates encapsulation
    accessBoundary: rest,
    accountId: account?.id,
    environmentId: environmentId,
  };
}
