import React, { useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Flex, UseDisclosureProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationInput } from 'components/DataDisplay';
import { CancelButton, PrimaryButton } from 'components/DataEntry';
import { Modal } from 'components/Overlay';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

interface Props {
  remediation: any;
  policy: any;
  blueprintId: string;
  confirmationDisclosure: UseDisclosureProps;
  onCancel();
}

export const PolicyRemediationConfirmationModal = (props: Props) => {
  const {
    remediation,
    policy,
    blueprintId,
    confirmationDisclosure,
    onCancel,
  } = props;
  const [command, setCommand] = useState('');
  const dispatch = useDispatch();
  const { autoRemediation } = useSelector(selectBlueprints);

  const onConfirm = () => {
    dispatch(
      actions.autoRemediate({
        q: {
          blueprintId: blueprintId,
          policyId: policy.id,
          resourceType: policy?.resource_type as NativeResources,
          remediationId: remediation?.id,
        },
        onSuccess: () => {
          dispatch(
            actions.getAvailableRemediation({
              q: {
                id: policy.id!,
                blueprintId: blueprintId,
              },
            }),
          );
        },
      }),
    );
  };

  const onClose = () => {
    confirmationDisclosure?.onClose?.();
    onCancel();
  };

  return (
    <Modal
      isOpen={confirmationDisclosure.isOpen!}
      onClose={onClose}
      isCentered
      header={'Auto Remediation Confirmation'}
      body={
        <ConfirmationInput
          confirmationText={'CONFIRM'}
          label={`Please type "CONFIRM"`}
          command={command}
          setCommand={setCommand}
        />
      }
      size="xl"
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <PrimaryButton
            onClick={onConfirm}
            isDisabled={command !== 'CONFIRM'}
            isLoading={autoRemediation.isLoading}
            bg="primary"
            color="white"
          >
            Okay
          </PrimaryButton>
        </Flex>
      }
    />
  );
};
