import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { authOptions } from 'api/auth';
import { errorToast } from 'components/Toast';

import request from './request';

export interface CallApiOptions {
  url?: string;
  fn?: (req: any, initReq?: any) => Promise<any>;
  data?: any;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  onSuccess?: ActionCreatorWithPayload<any, string>;
  onError?: ActionCreatorWithPayload<any, string>;
  onResponse?: (res: any, err: any) => any;
  errorTitle?: string;
  mapErrorMsg?: (error: any) => string;
}

const noop = (a, b?: any) => a || b;

export function* callApi(opts: CallApiOptions) {
  const {
    url = '',
    method = 'GET',
    data = {},
    onResponse = noop,
    mapErrorMsg = noop,
    errorTitle = '',
  } = opts;

  if (url === '') {
    if (opts.onError) {
      yield put(
        opts.onError(
          onResponse(null, `Please provide proper url, provided url: [${url}]`),
        ),
      );
    }
  }

  try {
    const response = yield call(request, url, {
      method,
      data,
    });

    if (opts.onSuccess) {
      yield put(opts.onSuccess(onResponse(response, null)));
    }
  } catch (err) {
    errorToast({
      title: errorTitle,
      description: mapErrorMsg(err),
    });

    if (opts.onError) {
      yield put(opts.onError(onResponse(null, err)));
    }
  }
}

// handles proto generated api client functions
export function* callApiFn(opts: CallApiOptions) {
  const { fn = noop, data = {}, onResponse = noop, errorTitle = '' } = opts;

  const handleError = function* (error) {
    if (opts.onError) {
      errorToast({
        title: errorTitle,
        description: error.toString(),
      });

      yield put(opts.onError(onResponse(null, error)));
    }
  };

  try {
    const response = yield call(fn, data, authOptions());
    if (response.error && opts.onError) {
      yield call(handleError, response.message);
      return;
    }

    if (opts.onSuccess) {
      yield put(opts.onSuccess(onResponse(response, null)));
    }
  } catch (err) {
    yield call(handleError, err.message);
  }
}
