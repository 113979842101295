import React from 'react';

import { SummaryType } from '@ariksa/inventory-core';
import { NativeResources } from '@ariksa/inventory-core/api';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { SummaryByResource } from 'containers/Inventory/CloudInventory/Components/Summary/SummaryByResource';

import { widgetStyles } from '../styles';

export const availableWidgets: Record<string, WidgetProps> = {
  byDataClassification: {
    name: 'By Data Classification',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.DataClassification}
          nativeResource={NativeResources.SimpleStorageService}
        />
      ),
    },
  },
  byRegion: {
    name: 'By Region',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.Region}
          nativeResource={NativeResources.SimpleStorageService}
        />
      ),
    },
  },
};
