/**
 *
 * Cloud Accounts
 *
 */

import React, { memo, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { PageInfo } from 'components/Navigation';
import { OutpostDeployedInformation } from 'containers/Setup/CloudAccounts/Components/OutpostDeployedInformation';
import { actions } from 'containers/Setup/CloudAccounts/slice';

import { selectCloudAccounts } from '../selectors';
import { isSaaSAccount, useCloudAccountTableColumns } from '../utils';

export const CloudAccountsTable = memo(() => {
  const { accounts, dataScanner } = useSelector(selectCloudAccounts);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    columns,
    showDeployedOutposts,
    currentRow,
  } = useCloudAccountTableColumns();

  const updateCloudAccountPageInfo = (info: PageInfo) => {
    dispatch(actions.updateCloudAccountPageInfo(info));
  };

  const isRowClickAllowed = useCallback(row => {
    return (
      (row?.aws?.account_type === 'Management' || isSaaSAccount(row)) &&
      row?.status?.code === 'SUCCESS'
    );
  }, []);

  return (
    <>
      <Table
        testName={'cloud-accounts'}
        columns={columns}
        data={accounts.data}
        pagination={{
          pageInfo: accounts.page.info,
          totalCount: accounts.page.totalCount,
          onChange: updateCloudAccountPageInfo,
        }}
        cursor={row => (isRowClickAllowed(row) ? 'pointer' : 'inherit')}
        onRowClick={row => {
          if (isRowClickAllowed(row))
            navigate(`/setup/accounts/${row?.uuid}/member_accounts`, {
              state: row,
            });
        }}
        isLoading={accounts.isLoading}
        expanded={true}
        // styles={{
        //   cell: {
        //     verticalAlign: 'top',
        //     border: 'none',
        //     ...cellStyles,
        //   },
        //   headerCell: cellStyles,
        //   row: {
        //     boxShadow: '0px -1px 0 #e2e8f0, inset 1px 0px 0 #e2e8f0',
        //   },
        // }}
      />
      {showDeployedOutposts.isOpen && (
        <OutpostDeployedInformation
          isOpen={showDeployedOutposts.isOpen}
          onClose={showDeployedOutposts.onClose}
          data={dataScanner.data?.[currentRow?.uuid] ?? []}
          accountName={currentRow?.name}
          type={IconTypes.Data}
        />
      )}
    </>
  );
});
