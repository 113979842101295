import React, { useEffect, useState } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, getResourceTypeOptions } from 'app/components';
import { PrimaryButton } from 'components/DataEntry/Button';
import { usePageContentContext } from 'components/Layout';
import { errorToast } from 'components/Toast';
import { selectApp } from 'containers/App/selectors';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

import { selectRoles } from '../selectors';
import { actions } from '../slice';

import { RoleList } from './RoleList';

export function CreateRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roleForm } = useSelector(selectRoles);
  const { success } = useTrackApiCall(roleForm);

  const { resourceTypes } = useSelector(selectApp);

  const [isValid, setIsValid] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    if (success) {
      navigate('/setup/roles');
    }
  }, [navigate, success]);

  useEffect(() => {
    // console.log(resourceTypes);
  });

  useEffect(() => {
    setResourceTypeOptions(getResourceTypeOptions(resourceTypes.data));
  }, [resourceTypes]);

  const handleResetRole = () => {
    setIsReset(true);
    dispatch(actions.resetRole());
  };

  const { contentHeight } = usePageContentContext();

  const checkValidatoin = name => {
    if (name.match(/^[a-zA-Z0-9-_ ]*$/i)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleCreateRole = () => {
    if (isValid) {
      dispatch(actions.createRole({}));
    } else {
      errorToast({
        title: 'Error',
        // without to doing string creates error
        description: 'Role name is not valid!',
      });
    }
  };

  const handleCancel = () => {
    dispatch(actions.resetRole());
    navigate('/setup/roles');
  };

  return (
    <Card styles={{ card: { p: 6, h: contentHeight + 'px' } }}>
      <Box d="flex" flexDir="column" w={'full'}>
        <Box h="full">
          <RoleList
            isReset={isReset}
            setIsReset={setIsReset}
            roles={[roleForm.role]}
            accounts={[]}
            resourcesTypes={resourceTypeOptions}
            onUpdateScope={(role, resources) =>
              dispatch(actions.updateFormScope(resources))
            }
            onUpdateName={(role, name) => {
              checkValidatoin(name);
              dispatch(actions.updateFormName(name));
            }}
            onUpdateResources={(role, resources) =>
              dispatch(actions.onUpdateFormResources(resources))
            }
            onToggle={role => dispatch(actions.onToggle(role))}
          />
        </Box>
        <Flex d="flex" justifyContent={'space-between'} mt="4">
          <Flex>
            <PrimaryButton onClick={handleCancel}>Cancel</PrimaryButton>
          </Flex>
          <Stack isInline spacing={6}>
            <PrimaryButton onClick={handleResetRole}>Reset</PrimaryButton>
            <PrimaryButton
              onClick={handleCreateRole}
              isLoading={roleForm.loading}
            >
              Create
            </PrimaryButton>
          </Stack>
        </Flex>
      </Box>
    </Card>
  );
}
