import React, { useCallback, useMemo } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box } from '@chakra-ui/react';
import { findIndex } from 'lodash';
import { useSelector } from 'react-redux';

import {
  ActivityIcon,
  AppIcon,
  BellIcon,
  ComplianceIcon,
  ContainerVolumeIcon,
  DataScanIcon,
  InterfaceIcon,
  KeyIcon,
  MonitoringIcon,
  NetworkACLIcon,
  PermissionsActionsIcon,
  ServicesIcon,
  TimelineIcon,
  UserIcon,
  VulnerabilityIcon,
} from 'components/Icons';
import { AlertsTimeline } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/AletsTimeline';
import { IdentityPermissions } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/Identity/IdentityPermissions';
import { S3Permissions } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3Permissions';
import { ECRContainerImages } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ECR/Components/ECRContainerImages';
import { AccessTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Access';
import { ActivityTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Activity';
import { AlertDetailsTable } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Alerts/AlertDetailsTable';
import { AppsTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Apps';
import { Compliance } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Compliance';
import { getContainers } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Containers/getContainers';
import { CredentialsTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Credentials';
import { getDataComponent } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Data/getDataTab';
import { IacDeployments } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Deployment';
import { getIdentityComponent } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Identity/getIdentityTab';
import { getNetworkTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Network/getNetworkTab';
import { CloudResourceOverview } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Summary/CloudResourceOverview';
import { VulnerabilityTab } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';

export const useMetadataDrawerTabs = () => {
  const {
    resourceType,
    resourceAccountId,
    resourceId,
    resourceInsight,
    resourceDetails,
  } = useSelector(selectActiveResource);

  const access = useMemo(
    () => ({
      title: 'Access',
      key: MetadataTabs.Access,
      component: <AccessTab />,
      icon: <InterfaceIcon />,
    }),
    [],
  );

  const activity = useMemo(
    () => ({
      title: 'Activity',
      key: MetadataTabs.Activity,
      icon: <ActivityIcon />,
      component: <ActivityTab />,
    }),
    [],
  );

  const summary = useMemo(
    () => ({
      title: 'Summary',
      key: MetadataTabs.Summary,
      component: <CloudResourceOverview />,
      icon: <MonitoringIcon />,
    }),
    [],
  );

  const alerts = useMemo(
    () => ({
      title: 'Alerts',
      key: MetadataTabs.Alerts,
      component: (
        <Box w="full" h="full" flex={1}>
          <AlertDetailsTable />
        </Box>
      ),
      icon: <BellIcon />,
    }),
    [],
  );

  const compliance = useMemo(
    () => ({
      title: 'Compliance',
      key: MetadataTabs.Compliance,
      component: <Compliance />,
      icon: <ComplianceIcon />,
    }),
    [],
  );

  const timeline = useMemo(
    () => ({
      title: 'Timeline',
      key: MetadataTabs.Timeline,
      component: (
        <Box w="full" h="full" flex={1}>
          <AlertsTimeline />
        </Box>
      ),
      icon: <TimelineIcon />,
    }),
    [],
  );

  const vulnerabilities = useMemo(
    () => ({
      title: 'Vulnerabilities',
      key: MetadataTabs.Vulnerabilities,
      component: (
        <Box w="full" h="full" flex={1}>
          <VulnerabilityTab />
        </Box>
      ),
      icon: <VulnerabilityIcon />,
    }),
    [],
  );

  const containers = useMemo(
    () => ({
      title: 'Containers',
      key: MetadataTabs.Containers,
      component: (
        <Box w="full" h="full" flex={1}>
          {getContainers(resourceType)}
        </Box>
      ),
      icon: <ContainerVolumeIcon />,
    }),
    [resourceType],
  );

  const identity = useMemo(
    () => ({
      title: 'Identity',
      key: MetadataTabs.Identity,
      icon: <UserIcon />,
      component: getIdentityComponent(resourceType),
    }),
    [resourceType],
  );

  const secrets = useMemo(
    () => ({
      title: 'Secrets',
      key: MetadataTabs.Secrets,
      component: <CredentialsTab />,
      icon: <KeyIcon />,
    }),
    [],
  );

  const network = useMemo(
    () => ({
      title: 'Network',
      key: MetadataTabs.Network,
      component: (
        <Box w="full" h="full" flex={1}>
          {getNetworkTab(resourceType)}
        </Box>
      ),
      icon: <NetworkACLIcon />,
    }),
    [resourceType],
  );

  const permissions = useMemo(
    () => ({
      title: 'Permissions',
      key: MetadataTabs.Permissions,
      component: <S3Permissions />,
      icon: <PermissionsActionsIcon />,
    }),
    [],
  );

  const identityPermissions = useMemo(
    () => ({
      title: 'Permissions',
      key: MetadataTabs.Permissions,
      component: <IdentityPermissions />,
      icon: <PermissionsActionsIcon />,
    }),
    [],
  );

  const data = useMemo(
    () => ({
      title: 'Data',
      key: MetadataTabs.Data,
      component: getDataComponent(
        resourceType,
        resourceAccountId,
        resourceType === NativeResources.DynamoDb ||
          resourceType === NativeResources.GitHubRepository ||
          resourceType === NativeResources.GitLabProject ||
          resourceType === NativeResources.BitBucketRepository
          ? resourceDetails?.data?.config?.path_with_namespace ??
              resourceInsight.data?.name ??
              resourceId
          : resourceId,
      ),
      icon: <DataScanIcon p={0.75} />,
    }),
    [
      resourceAccountId,
      resourceId,
      resourceType,
      resourceInsight.data,
      resourceDetails.data,
    ],
  );

  const deployment = useMemo(() => {
    return {
      title: 'Deployment',
      key: MetadataTabs.Deployment,
      icon: <ServicesIcon />,
      component: <IacDeployments />,
    };
  }, []);

  const containerImages = useMemo(
    () => ({
      title: 'Images',
      key: MetadataTabs.ContainerImages,
      icon: <ContainerVolumeIcon />,
      component: <ECRContainerImages />,
    }),
    [],
  );

  const appsTab = useMemo(() => {
    return {
      title: 'Apps',
      key: MetadataTabs.Apps,
      icon: <AppIcon />,
      component: <AppsTab />,
    };
  }, []);

  const lastCommonTabs = useMemo(() => [alerts, compliance, timeline], [
    alerts,
    compliance,
    timeline,
  ]);

  const getMetadataDrawerTabs = useCallback(
    type => {
      switch (type) {
        case NativeResources.Ec2Instance:
          return [
            summary,
            vulnerabilities,
            network,
            activity,
            identity,
            access,
            identityPermissions,
            secrets,
            containers,
            appsTab,
            ...lastCommonTabs,
          ];

        case NativeResources.SimpleStorageService:
          return [
            summary,
            permissions,
            data,
            identity,
            activity,
            ...lastCommonTabs,
          ];

        case NativeResources.RedshiftCluster:
        case NativeResources.RelationalDatabaseService:
        case NativeResources.DynamoDb:
        case NativeResources.RdsCluster:
        case NativeResources.DocumentDb:
        case NativeResources.DocumentDbCluster:
          return [summary, data, identity, ...lastCommonTabs];
        case NativeResources.IamPolicy:
          return [summary, identity, access, ...lastCommonTabs];
        case NativeResources.IamGroup:
          return [
            summary,
            identity,
            access,
            identityPermissions,
            ...lastCommonTabs,
          ];
        case NativeResources.SecurityGroup:
        case NativeResources.ElasticLoadBalancer:
        case NativeResources.ElasticLoadBalancerv2:
          return [summary, network, identity, ...lastCommonTabs];
        case NativeResources.AccountRoot:
        case NativeResources.IamUser:
          return [
            summary,
            access,
            identityPermissions,
            activity,
            data,
            ...lastCommonTabs,
          ];
        case NativeResources.IamRole:
          return [
            summary,
            access,
            identityPermissions,
            data,
            ...lastCommonTabs,
          ];
        case NativeResources.OktaUser:
        case NativeResources.AzureAdUser:
        case NativeResources.OktaGroup:
        case NativeResources.AzureAdGroup:
        case NativeResources.AzureAdsamlRole:
        case NativeResources.OktaRoleSubscription:
        case NativeResources.SnowflakeRoles:
          return [summary, access, identityPermissions];
        case NativeResources.SnowflakeUsers:
          return [summary, access, identityPermissions, data];
        case NativeResources.OktaDomain:
        case NativeResources.OktaOrganization:
        case NativeResources.OktaApplication:
        case NativeResources.OktaIdentityProvider:
        case NativeResources.OktaPolicy:
        case NativeResources.AzureAdApplication:
        case NativeResources.AzureAdDirectoryRoles:
        case NativeResources.AzureAdOrganization:
        case NativeResources.AzureAdDomain:
        case NativeResources.IdentityCentreInstance:
          return [summary];
        case NativeResources.ElasticKubernetesService:
          return [
            summary,
            vulnerabilities,
            network,
            identity,
            access,
            containers,
            appsTab,
            ...lastCommonTabs,
          ];
        case NativeResources.ElasticContainerService:
        case NativeResources.EcsCluster:
          return [
            summary,
            vulnerabilities,
            identity,
            access,
            containers,
            ...lastCommonTabs,
          ];
        case NativeResources.ElasticContainerRegistry:
          return [
            summary,
            //vulnerabilities,
            containerImages,
            identity,
            ...lastCommonTabs,
          ];
        case NativeResources.SnowflakeDatabases:
        case NativeResources.SnowflakeTables:
        case NativeResources.SnowflakeSchemas:
          return [summary, data, identity];
        case NativeResources.Route53HostedZone:
          return [summary, network, identity, ...lastCommonTabs];
        case NativeResources.LambdaFunction:
          return [
            summary,
            access,
            vulnerabilities,
            identity,
            ...lastCommonTabs,
          ];
        case NativeResources.AmazonMachineImage:
          return [summary, vulnerabilities, identity, ...lastCommonTabs];
        case NativeResources.Container:
          return [summary, vulnerabilities, network];
        case NativeResources.CloudFormation:
          return [summary, identity, compliance];
        case NativeResources.Ec2LaunchTemplate:
          return [summary, identity, compliance, timeline];
        case NativeResources.SystemsManager:
        case NativeResources.SystemsManagerDocument:
        case NativeResources.DhcpOptions:
        case NativeResources.SubnetRouteTable:
        case NativeResources.ElasticNetworkInterface:
        case NativeResources.NatGateway:
        case NativeResources.InternetGateway:
        case NativeResources.DatabaseSecurityGroup:
        case NativeResources.PrefixList:
        case NativeResources.VpcPeeringConnection:
        case NativeResources.TransitGateway:
        case NativeResources.TransitGatewayAttachment:
        case NativeResources.TransitGatewayRouteTable:
        case NativeResources.ApiGatewayDomain:
        case NativeResources.EcsContainerInstance:
        case NativeResources.VpcFlowLogs:
        case NativeResources.CloudWatchMetrics:
          return [summary, identity];
        case NativeResources.GitHubRepository:
        case NativeResources.GitLabProject:
        case NativeResources.BitBucketRepository:
          return [summary, data, deployment];
        case NativeResources.GcpComputeEngine:
        case NativeResources.GcpCloudFunctions:
        case NativeResources.GcpContainer:
        case NativeResources.Gcpgke:
          return [summary, vulnerabilities, alerts, compliance, timeline];
        default:
          if (type?.toLowerCase()?.startsWith('gcp'))
            return [summary, alerts, compliance, timeline];
          return [summary, identity, ...lastCommonTabs];
      }
    },
    [
      summary,
      vulnerabilities,
      network,
      activity,
      identity,
      access,
      secrets,
      containers,
      lastCommonTabs,
      permissions,
      data,
      identityPermissions,
      containerImages,
      compliance,
      timeline,
      deployment,
      alerts,
    ],
  );

  const getTabIndex = useCallback(
    (resourceType, tabKey) => {
      const index = findIndex(
        getMetadataDrawerTabs(resourceType),
        o => o.key === tabKey,
      );
      if (index < 0) return 0;
      return index;
    },
    [getMetadataDrawerTabs],
  );

  return {
    getMetadataDrawerTabs,
    getTabIndex,
  };
};
