import { createContext, useContext } from 'react';

import { SideNavKey, SideNavMenuItemProps } from 'components/Navigation';

export interface InventoryContextProps {
  currentInstance: Record<string, any>;
  setCurrentInstance: (value: Record<string, any>) => void;
  isOpenAddContext: boolean;
  setIsOpenAddContext: (value: boolean) => void;
  showInventorySummary: boolean;
  setShowInventorySummary: (value: boolean) => void;
  showCategoryInventorySummary: boolean;
  setShowCategoryInventorySummary: (value: boolean) => void;
  isOpenAddWhitelist: boolean;
  setIsOpenAddWhitelist: (value: boolean) => void;
  sortBy?: string;

  setSortBy(value?: string);

  sideNavItemsMapping: Record<string, SideNavMenuItemProps>;

  setSideNavItemsMapping(value: Record<string, SideNavMenuItemProps>);

  handleCategoryChange(category: SideNavKey, menuItem?: SideNavMenuItemProps);

  menuItems: SideNavMenuItemProps[];
  updateMenuItems: (items: SideNavMenuItemProps[]) => void;
  selectedRegion: Record<string, any>;

  setSelectedRegion(val: Record<string, any>);

  selectedVpc: Record<string, any>;

  setSelectedVpc(val: Record<string, any>);
}

export const InventoryContext = createContext<InventoryContextProps>({
  currentInstance: {},
  setCurrentInstance: () => {},
  isOpenAddContext: false,
  setIsOpenAddContext: () => {},
  showInventorySummary: false,
  setShowInventorySummary: () => {},
  showCategoryInventorySummary: false,
  setShowCategoryInventorySummary: () => {},
  isOpenAddWhitelist: false,
  setIsOpenAddWhitelist: () => {},
  setSortBy: () => {},
  sideNavItemsMapping: {},
  setSideNavItemsMapping: () => {},
  handleCategoryChange: () => {},
  menuItems: [],
  updateMenuItems: () => {},
  selectedRegion: {},
  selectedVpc: {},
  setSelectedRegion: () => {},
  setSelectedVpc: () => {},
});

export const InventoryContextProvider = InventoryContext.Provider;

export const useInventoryContext = () => useContext(InventoryContext);
