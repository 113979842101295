import React, { FC } from 'react';

import { DataTypeForES } from '@ariksa/reporting';
import { HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { PrimaryIconButton } from 'components/DataEntry';
import { PieChartIcon } from 'components/Icons';
import { RegionAndVPCFilter } from 'containers/Inventory/CloudInventory/Components/Overview/RegionAndVPCFilter';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { ExportAlerts } from 'containers/SharedState/Components/ExportAlerts/ExportAlerts';

import { selectInventory } from '../selector';

interface HeaderTableProps {
  onOpenAddWhitelist?: () => void;
}

export const HeaderTable: FC<HeaderTableProps> = ({ onOpenAddWhitelist }) => {
  const { instanceResourceType } = useSelector(selectInventory);
  const { setShowInventorySummary } = useInventoryContext();

  return (
    <HStack spacing={2}>
      {!!instanceResourceType && <RegionAndVPCFilter />}
      <ExportAlerts type={DataTypeForES.Resources} tooltip="Export inventory" />
      <PrimaryIconButton
        aria-label="summary"
        tooltip="Show Cloud Summary"
        onClick={() => setShowInventorySummary(true)}
      >
        <PieChartIcon />
      </PrimaryIconButton>
      {/*{!!instanceResourceType && (
            <PrimaryIconButton
              aria-label="whitelist"
              tooltip={`Whitelist`}
              p={1}
              isDisabled={deploymentSummary.loading}
              onClick={() => {
                onOpenAddWhitelist?.();
              }}
            >
              <CheckmarkCircleOutlineIcon />
            </PrimaryIconButton>
          )}*/}
      {/*<PrimaryIconButton
            aria-label="download"
            tooltip="Download inventory report"
          >
            <DownloadIcon />
          </PrimaryIconButton>
          <PrimaryIconButton
            aria-label="share"
            tooltip="Share with another user"
          >
            <ShareIcon />
          </PrimaryIconButton>
          <PrimaryIconButton
            aria-label="calender"
            tooltip="Show inventory on specific date"
          >
            <CalenderIcon />
          </PrimaryIconButton>*/}
    </HStack>
  );
};
