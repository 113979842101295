import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { ObjectStorageIcon } from 'components/Icons';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Buckets: React.FC = () => {
  const { dataSecurity } = useSelector(selectDataSecurityOverview);
  const { summaryOfAllDataSources } = useSelector(selectDashboard);
  const { analyzedDataSourcesCount } = useSelector(selectDashboard);
  const { bucketCount } = dataSecurity;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Buckets"
      icon={<ObjectStorageIcon />}
      onClick={() =>
        navigate('/inventory/summary?tab=Data_' + Resources.ObjectStorage)
      }
      change={{
        isLoading: bucketCount.isLoading,
        first: bucketCount.data?.previous_count,
        last: bucketCount.data?.current_count,
        percentChange: bucketCount.data?.percentage_change,
      }}
      cloudProviders={
        summaryOfAllDataSources.data.resource_type_providers?.Buckets ?? []
      }
      count={summaryOfAllDataSources.data.resource_type_counts?.Buckets}
      isLoading={summaryOfAllDataSources.isLoading}
      info={{
        isLoading: analyzedDataSourcesCount.isLoading,
        message: !!analyzedDataSourcesCount.data?.Buckets ? (
          <HStack>
            <Box>Analyzing</Box>
            <Box>
              {analyzedDataSourcesCount.isLoading ? (
                <CustomSpinner />
              ) : (
                analyzedDataSourcesCount.data?.Buckets
              )}
            </Box>
          </HStack>
        ) : (
          'No Resources Analyzed'
        ),
        enabled: !!analyzedDataSourcesCount.data?.Buckets,
      }}
      labelTooltip={formatTooltip({
        header: 'Buckets',
        content: 'Object storage in public clouds or SaaS',
      })}
    />
  );
};
