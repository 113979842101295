import React, { ReactNode } from 'react';

import {
  Box,
  HStack,
  useRadioGroup,
  UseRadioGroupProps,
  Flex,
} from '@chakra-ui/react';
import map from 'lodash/map';

import { HelpIconButton } from 'components/DataEntry';

import { RadioCard } from './RadioCard';

interface Props extends UseRadioGroupProps {
  options: {
    label: ReactNode;
    icon: ReactNode;
    value: any;
    showHelp?: boolean;
    onClick?(e);
  }[];
  cardWidth?: any;
  iconStyles?: any;
}

export const RadioWithIcon: React.FC<Props> = props => {
  const { options, cardWidth, iconStyles, ...rest } = props;

  const radioGroup = useRadioGroup(rest);
  const { getRootProps, getRadioProps } = radioGroup;
  const group = getRootProps();

  return (
    <HStack spacing={4} {...group}>
      {map(options, o => {
        const radio = getRadioProps({ value: o.value });

        return (
          <Box w={cardWidth ?? 'full'} pos={'relative'}>
            <RadioCard
              option={o}
              iconStyles={iconStyles}
              key={o.value}
              {...radio}
            />
            {o.showHelp && (
              <Flex pos={'absolute'} top={2} right={2}>
                <HelpIconButton
                  onClick={e => o.onClick?.(e)}
                  color={
                    (rest.value ?? rest.defaultValue) === o.value
                      ? 'white'
                      : 'primary'
                  }
                />
              </Flex>
            )}
          </Box>
        );
      })}
    </HStack>
  );
};
