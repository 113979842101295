import React, { useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/inventory-core';
import { ResourceSummaryApiGetSummaryByTypeRequest } from '@ariksa/inventory-core/api';
import { each, sum } from 'lodash';
import { useResourceSummaryService } from 'services/Inventory/useResourceSummaryService';

import { colorMapForCharts, getIconColor } from 'components/DataDisplay';
import { PieChart } from 'components/Visualization';
import { useResourceType } from 'containers/App/hooks/useResourceType';

export interface ISummaryByResource
  extends Pick<
    ResourceSummaryApiGetSummaryByTypeRequest,
    'summaryType' | 'category' | 'nativeResource'
  > {}

export const SummaryByResource = (props: ISummaryByResource) => {
  const { summaryType } = props;
  const { resourceSummary } = useResourceSummaryService(props);
  const { data: summaryData } = resourceSummary;
  const [pieData, setPieData] = useState<{ name: string; value: number }[]>([]);
  const [colorMap, setColorMap] = useState<Record<string, any>>({});
  const { getCloudNativeName } = useResourceType();

  useEffect(() => {
    let data: { name: string; value: number }[] = [];
    let colors: Record<string, any> = {};
    each(summaryData?.items, (value, key) => {
      const name = (summaryType === SummaryType.ResourceType
        ? getCloudNativeName(key)
        : key) as string;
      data.push({
        name,
        value: value as number,
      });
      colors[name] =
        summaryType === 'region'
          ? colorMapForCharts[key]
          : getIconColor(
              summaryType === 'cloud_provider' ? key?.toLowerCase() : key,
            );
    });

    setColorMap(colors);
    setPieData(data);
  }, [getCloudNativeName, summaryData, summaryType]);

  return (
    <PieChart
      outerRadius="80%"
      innerRadius="73%"
      total={sum(pieData.map(a => a.value))}
      dataKey={'value'}
      data={pieData}
      isLoading={resourceSummary.isLoading}
      colorMap={
        summaryType === 'region' || summaryType === 'cloud_provider'
          ? colorMap
          : undefined
      }
      styles={{
        legend: {
          wrapperStyle: {
            height: '95%',
            width: '250px',
          },
          formatter: (value, entry, index) => {
            return (
              <span style={{ fontSize: '12px', color: '#666' }}>
                {value + ' (' + entry?.payload?.value + ')'}
              </span>
            );
          },
        },
        pie: {
          minAngle: 2,
          paddingAngle: 1,
        },
        pieChart: {
          margin: {
            left: -10,
          },
        },
      }}
    />
  );
};
