import React, { FC, ReactNode } from 'react';

import { Box } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { PageHeaderWithIcon } from 'components/DataDisplay';

interface IHelpHeader {
  children: ReactNode | ReactNode[];
}

export const HelpHeader: FC<IHelpHeader> = props => {
  return (
    <Box
      mb={4}
      p={2}
      borderBottom={'1px solid'}
      borderColor={customTheme.colors.gray['100']}
    >
      <PageHeaderWithIcon label={props.children} />
    </Box>
  );
};
