import React from 'react';

import { Box } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { LockCloseIcon, LockOpenIcon } from 'components/Icons';

export const renderCustomTag = created_by =>
  !!created_by &&
  created_by !== 'system' && (
    <Tag
      label="Custom"
      styles={{
        tag: { color: 'white', bg: 'primary', py: 0.5 },
        label: { color: 'inherit' },
      }}
    />
  );

export const renderEditLock = (created_by, boxSize = 4) => (
  <Box
    as={created_by !== 'system' ? LockOpenIcon : LockCloseIcon}
    boxSize={boxSize}
    color={
      created_by !== 'system'
        ? customTheme.colors.green['300']
        : customTheme.colors.red['400']
    }
  />
);
