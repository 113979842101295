import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.vulnerability || initialState;

export const selectVulnerabilities = createSelector(
  [selectDomain],
  vulnerabilityState => vulnerabilityState,
);
