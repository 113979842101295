import React from 'react';

import { Icon } from '@chakra-ui/react';

export const LoadBalancerIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M472.6,298.1c-34.4,34.3-39.9,39.3-43.2,39.9c-11.4,2-20.6-7.6-18.4-19.2c0.6-3.2,3.9-7,21.4-24.6l20.7-20.8
	H333.2H213.3l59.2,59.2l59.2,59.2l0.5-28.4c0.5-27.8,0.6-28.4,2.9-32.3c2.5-4.1,8.6-7.6,13.1-7.6c4.5,0,10.6,3.5,13.1,7.6l2.4,3.9
	v55.2v55.2H308c-54.9,0-56,0-58.7-2.1c-5.2-3.9-6.9-7.2-6.9-13.3c0-4.3,0.6-6.5,2.2-8.8c4.4-6.1,6.1-6.4,37.6-6.4h28.5l-70.8-70.8
	l-70.8-70.8h-36.9H95.2l-1.8,4.3c-3.6,8.3-12,17.6-20.2,22.6c-11.1,6.7-27.9,8.3-40.4,3.9C4.6,294.1-8.3,261,5.7,234.8
	c3.6-6.7,13.8-16.9,20.2-20.1c24.3-12.3,53.3-3.3,65.8,20.5l3.7,7h36.9h36.8l72.3-72.3l72.3-72.3h-30c-28.7,0-30.1-0.1-33.8-2.1
	c-7.5-4.2-10.3-14.3-5.8-21.1c1.3-1.9,3.8-4.4,5.7-5.7l3.4-2.3l55.3,0.3l55.3,0.3l0.3,51.7c0.2,28.4,0,53.7-0.3,56.1
	c-0.4,3-1.7,5.6-4.2,8.3c-7.9,8.7-18.4,7-26.6-4.3c-0.1-0.1-0.4-12.6-0.7-27.6l-0.6-27.4L272.5,183l-59.2,59.2h119.5h119.5
	l-19.8-19.8c-10.9-10.9-20.2-21.1-20.8-22.7c-2.5-6.8-0.1-14.4,6-18.5c4.1-2.8,12.3-3.1,16.1-0.6c2.7,1.8,78.3,76.8,78.3,77.7
	C512,258.6,494.3,276.5,472.6,298.1z"
    />
  </Icon>
);
