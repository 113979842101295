import { useState, useEffect, useCallback } from 'react';

import { usePrevious } from 'react-use';

import { ApiCallStatus } from 'api/types';

export function useTrackApiCall(callStatus: ApiCallStatus) {
  const prevStatus = usePrevious(callStatus);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const done = () => {
    setSuccess(false);
    setFailure(false);
    setLoading(false);
    setIsDone(true);
  };

  useEffect(() => {
    if (!isDone) {
      setLoading(callStatus.loading);
    }

    // if previously loading and currently not loading i.e api all finished
    if (prevStatus?.loading && !callStatus.loading) {
      setSuccess(!callStatus.error);
      setFailure(!!callStatus.error);
      return;
    }

    // reset the local state when
    // currently loading but previously was not
    if (!prevStatus?.loading && callStatus.loading) {
      setSuccess(false);
      setFailure(false);
      setIsDone(false);
      setLoading(true);
    }
  }, [callStatus, isDone, prevStatus]);

  return { success, failure, loading, done };
}
