import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => {
  return state.securityGraphNext || initialState;
};

export const selectSecurityGraphNext = createSelector(
  [selectDomain],
  securityGraphState => securityGraphState,
);
