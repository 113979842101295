import { FormStyles } from 'components/DataEntry';

export const formStyles: FormStyles = {
  form: { formWidth: '700px' },
  objectField: {
    objectContainer: {
      bg: 'white',
      border: 'none',
      p: 0,
    },
  },
};

export const snowflakeFormStyles: FormStyles = {
  form: { formWidth: '700px' },
  objectField: {
    objectContainer: {
      bg: 'white',
    },
  },
};
