/**
 *
 * Azure Active Directory
 *
 */

import React from 'react';

import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';

import { Form } from 'components/DataEntry/Form';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { formStyles } from '../../../styles';

export const AddAzureAD = () => {
  const { onCancel, onboardAccount } = useIntegrationsContext();
  const handleReset = () => {
    onCancel();
  };
  const handleSubmit = data => {
    const { client_id, client_credential, name, authority } = data;
    onboardAccount(
      {
        cloudAccountCreateRequest: {
          cloud_type: CloudProviders.AzureAd,
          name,
          azure_ad: { client_id, client_credential, authority },
        },
      },
      AccountType.IdentityProvider,
    );
  };

  return (
    <>
      <Form
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            isRequired: true,
          },
          client_id: {
            type: 'text',
            label: 'Client ID',
            isRequired: true,
          },
          client_credential: {
            type: 'text',
            label: 'Client Credential',
            isRequired: true,
            htmlInputType: 'password',
            passwordShow: true,
          },
          authority: {
            type: 'text',
            label: 'Authority',
            isRequired: true,
          },
        }}
        //formOptions={{ defaultValues: { ...state } }}
        styles={formStyles}
        buttonOptions={{
          submit: {
            name: 'Okay',
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
