import { BoxProps, FlexProps, IconButtonProps } from '@chakra-ui/react';

import { DrawerStyles } from '../../../Overlay/Drawer/types';

export const notificationButtonStyles = {
  flex: 1,
  p: 2,
  justifyContent: 'center',
  cursor: 'pointer',
  fontWeight: 400,
  borderTop: '1px solid',
  borderColor: '#ddd',
  _hover: {
    color: 'primary',
  },
};

export const leftComponentStyle: FlexProps = {
  align: 'center',
  height: 'primaryNavBarHeight',
};

export const tabStyles: BoxProps = {
  px: 3,
  alignItems: 'center',
  color: 'menuColor',
  display: 'flex',
  h: 'primaryNavBarHeight',
  _hover: {
    color: 'white',
  },
  fontWeight: 400,
};

export const selectedTabStyles: BoxProps = {
  color: 'white',
  bg: 'selectedMenuBg',
};

export const profileMenuStyles = {
  root: {
    fontSize: 'sm',
  },
  menuButton: {
    borderRadius: 'full',
    //mx: 1,
    _hover: {
      color: 'white',
      bg: 'primary',
      //boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
    _focus: {
      outline: 'none',
      color: 'white',
      bg: 'primary',
      //boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
    _active: {
      outline: 'none',
      color: 'white',
      bg: 'primary',
      //boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
    },
  },
};
export const iconStyles: Omit<
  IconButtonProps,
  'children' | 'icon' | 'onClick' | 'aria-label'
> = {
  isRound: true,
  size: 'sm',
  bg: 'transparent',
  mx: 1,
  p: 2,
  color: 'white',
  _hover: {
    color: 'primary',
    bg: 'white',
    //boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
  },
  _focus: {
    outline: 'none',
    color: 'primary',
    bg: 'white',
    //boxShadow: `0 0 0 2px ${customTheme.colors.primary}`,
  },
};

export const topNavWrapperStyles: FlexProps = {
  height: 'primaryNavBarHeight',
  bg: 'primaryNavBarBg',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 99999,
  // borderBottom: '2px',
  // borderColor: 'gray.100',
};

export const mobileMenuDrawerStyles: DrawerStyles = {
  button: {
    borderRadius: 'full',
    size: 'sm',
    p: 1,
    /*_hover: {
      bg: 'white',
      color: 'primary',
    },
    _focus: {
      outline: 'none',
      bg: 'transparent',
      borderWidth: '2px',
      borderColor: 'white',
      color: 'white',
    },*/
  },
  closeButton: { color: 'white' },
  body: { p: 0, overflow: 'scroll' },
  drawer: {
    placement: 'left',
    size: 'xs',
    //scrollBehavior: 'inside',
  },
};

export const mobileMenuItemStyles = {
  primaryCommon: { py: 2, pl: 2, pr: 6 },
  secondaryCommon: { py: 2, pl: 10, pr: 4 },
  light: {
    title: {
      _hover: {
        color: 'primary',
        cursor: 'pointer',
      },
    },
    selected: {
      primary: {
        color: 'primary',
      },
      secondary: {
        color: 'primary',
        bg: 'blue.50',
      },
    },
    collapse: {},
  },
  dark: {
    title: {
      color: 'whiteAlpha.500',
      _hover: {
        color: 'white',
        cursor: 'pointer',
      },
    },
    selected: {
      primary: {
        color: 'white',
      },
      secondary: {
        color: 'white',
        bg: 'primary',
      },
    },
    collapse: { bg: 'gray.800' },
  },
};
