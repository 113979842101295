import { RefObject, useEffect, useState } from 'react';

import { useGraphScaling } from 'components/Visualization/PixiGraph/hooks/useGraphScaling';
import { StemLayout } from 'components/Visualization/PixiGraph/layouts/StemLayout';
import { usePostureGraphContext } from 'components/Visualization/PixiGraph/maps/PostureMap/hooks/usePostureGraphContext';

import { usePixiApp } from '../../../hooks/usePixiApp';
import { useRenderer } from '../../../hooks/useRenderer';
import { PostureMapEdge } from '../elements/Edge';
import { PostureMap } from '../elements/Graph';
import { PostureMapNode } from '../elements/Node';

interface UsePostureMapProps {
  ref: RefObject<any>;
}

export const usePostureMap = (props: UsePostureMapProps) => {
  const { ref } = props;
  const { nodes, edges } = usePostureGraphContext();

  const [graph] = useState(() => {
    const layout = new StemLayout();
    return new PostureMap({
      nodes: nodes?.map(n => new PostureMapNode(n)) ?? [],
      edges: edges.map(e => new PostureMapEdge(e)),
      layout,
    });
  });

  const app = usePixiApp({ ref });
  useGraphScaling({ app, graph, ref });
  const renderer = useRenderer({ app, graph });

  useEffect(() => {
    graph.update(
      nodes?.map(n => new PostureMapNode(n)) ?? [],
      edges.map(e => new PostureMapEdge(e)),
    );
    renderer.update();
  }, [nodes, edges, graph, renderer, app.renderer, app.stage, app.ticker]);

  // apply graph padding
  useEffect(() => {
    const { left, top } = graph.offsets;
    app.stage.x = left + graph.styles.padding[0];
    app.stage.y = top + graph.styles.padding[1];
    renderer.update();
  }, [renderer, graph, app]);

  // @ts-ignore
  window.graph = graph;

  return {
    app,
    graph,
    renderer,
  };
};
