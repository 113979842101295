import React, { useMemo } from 'react';

import { CloudAccountInfoGetResponse } from '@ariksa/jit/api';
import { HStack, Square, Stack } from '@chakra-ui/react';
import { identity, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { Heading4 } from 'components/DataDisplay';
import { Form } from 'components/DataEntry';
import { RoleIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { selectJit } from 'containers/Setup/Jit/selectors';
import { actions } from 'containers/Setup/Jit/slice';
import { roleNameOption } from 'containers/Setup/Jit/utils';

interface JitRoleConfigProps {
  isOpen: boolean;
  onClose: () => void;
  account: CloudAccountInfoGetResponse;
}

const JitRoleConfig = (props: JitRoleConfigProps) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, account } = props;
  const { roles, updateJitRoles } = useSelector(selectJit);
  const accounts = useSelector(selectOnboardedCloudAccounts);

  const roleOptions = useMemo(() => {
    return (
      roles.data.roles
        ?.map(role => roleNameOption(role, account.cloud_type))
        ?.filter(identity) ?? []
    );
  }, [account.cloud_type, roles.data.roles]);

  const cloudAccountName = useMemo(() => {
    return (
      accounts.data?.find(a => a.cloud_account_id === account.cloud_account_id)
        ?.name ?? ''
    );
  }, [account.cloud_account_id, accounts.data]);

  const handleSummit = data => {
    dispatch(
      actions.updateJitRoles({
        q: {
          accountId: account.uuid,
          jitRolesUpdateRequest: {
            jit_roles: data.roles.map(r => r.value),
          },
        },
        onSuccess: () => {
          onClose();
          dispatch(actions.getJitAccounts({ q: {}, page: INIT_PAGE_INFO }));
        },
      }),
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <HStack spacing={4}>
          <Square
            size={'32px'}
            bg={'primary'}
            color={'white'}
            p={1}
            borderRadius={4}
          >
            <RoleIcon />
          </Square>
          <Heading4>Configure JIT Roles</Heading4>
        </HStack>
      }
      styles={{
        modal: { size: 'lg' },
      }}
      body={
        <Stack w="full" h="full" spacing={5}>
          <Form
            schema={{
              account: {
                type: 'text',
                label: 'Account',
                value: `${cloudAccountName}(${account.cloud_account_id})`,
                isDisabled: true,
              },
              roles: {
                type: 'react-select',
                label: 'Choose Roles for JIT',
                isMulti: true,
                showTotalSelected: true,
                value:
                  roles.data.roles
                    ?.filter(r => r.jit_enabled)
                    ?.map(r => roleNameOption(r, account.cloud_type), []) ?? [],
                options: sortBy(roleOptions, [
                  'value.jit_enabled',
                  'label',
                ]).reverse(),
                isLoading: roles.isLoading,
              },
            }}
            handleSubmit={handleSummit}
            buttonOptions={{
              submit: {
                name: 'Ok',
                isVisible: true,
                isLoading: updateJitRoles.isLoading,
              },
              reset: {
                name: 'Cancel',
                isVisible: true,
                onClick: onClose,
              },
            }}
            styles={{
              form: {
                submitButton: {
                  bg: 'primary',
                  color: 'white',
                },
              },
            }}
          />
        </Stack>
      }
    />
  );
};

export default JitRoleConfig;
