import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.blueprints || initialState;

export const selectBlueprints = createSelector(
  [selectDomain],
  blueprintsState => blueprintsState,
);
