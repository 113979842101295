import React, { FC } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getIcon } from 'components/DataDisplay';
import {
  PrimaryIconButton,
  Select,
  publishedAtOptions,
} from 'components/DataEntry';
import { ClockIcon, IconTypes } from 'components/Icons';
import { useDataDashboardContext } from 'containers/Visibility/Data/Components/hooks/context';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

interface Props {
  isExpanded?: boolean;
  setIsExpanded?(val: boolean);
}

export const DataSecurityFilters: FC<Props> = props => {
  const { isExpanded, setIsExpanded } = props;
  const { selectedTab } = useSelector(selectDataDashboard);
  const width = '180px';
  const {
    time,
    regionOptions,
    accountOptions,
    sourceTypeOptions,
    selectedSourceType,
    selectedAccount,
    selectedRegion,
    labelOptions,
    cloudOptions,
    selectedCloud,
    selectedLabel,
    selectedSensitivity,
    sensitivityOptions,
    updateValue,
  } = useDataDashboardContext();

  const styles = {
    menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%' }),
  };

  return selectedTab === 'all' ? (
    <Box />
  ) : (
    <HStack
      justify="flex-end"
      pr={isExpanded ? 6 : 0}
      {...(selectedTab === 'DataSources' && !isExpanded ? { w: 'full' } : {})}
    >
      <Box color="primary" pr={3}>
        FILTERS:{' '}
      </Box>
      <>
        <Box w={width}>
          <Select
            options={cloudOptions}
            value={selectedCloud}
            onChange={val => updateValue('selectedCloud', val)}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            showIconInValueContainer
            styles={styles}
          />
        </Box>
        {selectedTab === 'DataSources' && (
          <Box w={width}>
            <Select
              options={accountOptions}
              value={selectedAccount}
              onChange={val => updateValue('selectedAccount', val)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
        )}
        {selectedTab === 'DataSources' && (
          <Box w={width}>
            <Select
              options={regionOptions}
              value={selectedRegion}
              onChange={val => updateValue('selectedRegion', val)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
        )}
        {selectedTab === 'DataSources' && (
          <Box w={width}>
            <Select
              options={sourceTypeOptions}
              value={selectedSourceType}
              onChange={val => updateValue('selectedSourceType', val)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
        )}
        {selectedTab === 'DataTypes' && (
          <Box w={width}>
            <Select
              options={sensitivityOptions}
              value={selectedSensitivity}
              onChange={val => updateValue('selectedSensitivity', val)}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              showIconInValueContainer
              styles={styles}
            />
          </Box>
        )}
        <Box w={width}>
          <Select
            options={labelOptions}
            value={selectedLabel}
            onChange={val => updateValue('selectedLabel', val)}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            showIconInValueContainer
            styles={styles}
          />
        </Box>
      </>
      <Box w={width}>
        <Select
          options={publishedAtOptions}
          value={time}
          onChange={val => updateValue('time', val)}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          showIconInValueContainer
          valueContainerIcon={<ClockIcon />}
          styles={styles}
        />
      </Box>
      {!isExpanded && (
        <PrimaryIconButton
          aria-label="expand"
          onClick={() => setIsExpanded?.(true)}
          tooltip="View all"
        >
          {getIcon(IconTypes.ViewAll)}
        </PrimaryIconButton>
      )}
      {/*<DownloadIconButton
            aria-label="download-vulnerabilities"
            //isLoading={download.isLoading}
            tooltip="Download Report"
            onClick={() => {}}
          />*/}
    </HStack>
  );
};
