/**
 *
 * Asynchronously loads the component for Remediation
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Remediation = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Remediation,
);
