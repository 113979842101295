import React, { FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { Modal } from 'components/Overlay';
import { BarChart } from 'components/Visualization';
import { ComplianceChartDetails } from 'containers/Compliance/Components/ComplianceChartDetails';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';

interface ISeverityComponentProps {
  resource?: Record<string, any>;
  blueprint?: Record<string, any>;
}

export const SeverityComponent: FC<ISeverityComponentProps> = props => {
  const { resource, blueprint } = props;
  const {
    isOpenSeverityModal,
    onCloseSeverityModal,
    selectedResource,
  } = useCompliance();

  const chartData = [
    {
      name: 'Pass',
      value: selectedResource?.check_stats?.passed,
    },
    {
      name: 'Critical',
      value: selectedResource?.check_stats?.failed?.CRITICAL,
    },
    {
      name: 'Medium',
      value: selectedResource?.check_stats?.failed?.MEDIUM,
    },
    {
      name: 'Low',
      value: selectedResource?.check_stats?.failed?.LOW,
    },
  ];

  return (
    <Modal
      isOpen={isOpenSeverityModal}
      onClose={onCloseSeverityModal}
      styles={{
        modal: { size: '2xl' },
      }}
      header="Severity"
      body={
        <Stack>
          <ComplianceChartDetails resource={resource} blueprint={blueprint} />
          <Box h="300px">
            <BarChart
              data={chartData}
              xField={'name'}
              yField={['value']}
              colors={[
                customTheme.colors.green['300'],
                customTheme.colors.red['300'],
                customTheme.colors.orange['300'],
                customTheme.colors.yellow['300'],
              ]}
              legend={false}
              styles={{
                bar: {
                  radius: 0,
                  barSize: 60,
                },
                xAxis: {
                  tick: { fontSize: 14, width: 250 },
                  tickSize: 12,
                },
                //   interval: 0,
              }}
            />
          </Box>
        </Stack>
      }
    />
  );
};
