import { QueryFilterCreate } from '@ariksa/profile';
import {
  QueryFilterApiCreateQueryFilterRequest,
  QueryFilterApiDeleteQueryFilterRequest,
  QueryFilterApiGetQueryFiltersRequest,
  QueryFilterApiUpdateQueryFilterRequest,
  QueryFilterRead,
} from '@ariksa/profile/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dict } from 'types/utils';

import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import {
  apiCallStateInitialState,
  withPaginationInitialState,
} from 'api/initial_values';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  QueryFilterGetProps,
  QueryFilterId,
  QueryFilterState,
  QueryFilterUpdateProps,
} from './types';

// The initial state of the Reports container
export const initialState: QueryFilterState = {
  queryFilters: withPaginationInitialState<QueryFilterRead[]>([]),
  apiCallState: apiCallStateInitialState<Dict<any>>({}),
  currentQueryFilter: {
    name: '',
    description: '',
    query: {},
    id: '',
    service: '',
  },
};

const queryFilterSlice = createSlice({
  name: 'queryFilter',
  initialState,
  reducers: {
    //query filter
    updateQueryFilter(
      state,
      action: PayloadAction<QueryFilterApiUpdateQueryFilterRequest>,
    ) {
      onApiCall(state.apiCallState);
    },
    createQueryFilter(
      state,
      action: PayloadAction<QueryFilterApiCreateQueryFilterRequest>,
    ) {
      onApiCall(state.apiCallState);
    },
    createQueryFilterSuccess(state, action: PayloadAction<any>) {
      state.currentQueryFilter = action.payload;
      onApiCallSuccess(state.apiCallState);
    },
    createQueryFilterError(state, action: PayloadAction<any>) {
      onApiCallError(state.apiCallState, action.payload);
    },

    //delete query filter
    deleteQueryFilter(
      state,
      action: PayloadAction<QueryFilterApiDeleteQueryFilterRequest>,
    ) {
      onApiCall(state.apiCallState);
    },
    deleteQueryFilterSuccess(state, action: PayloadAction<any>) {
      state.currentQueryFilter = initialState.currentQueryFilter;
      onApiCallSuccess(state.apiCallState);
    },
    deleteQueryFilterError(state, action: PayloadAction<any>) {
      onApiCallError(state.apiCallState, action.payload);
    },

    //get queryFilters
    loadQueryFilters(
      state,
      action: PayloadAction<QueryFilterApiGetQueryFiltersRequest>,
    ) {
      onApiCall(state.queryFilters);
    },
    loadQueryFiltersSuccess(state, action: PayloadAction<any>) {
      state.queryFilters.data = action.payload.items;
      state.queryFilters.pageInfo.page_size = action.payload.size;
      state.queryFilters.pageInfo.page_number = action.payload.page;
      state.queryFilters.total = action.payload.total;
      onApiCallSuccess(state.queryFilters);
    },
    loadQueryFiltersError(state, action: PayloadAction<any>) {
      onApiCallError(state.queryFilters, action.payload);
    },

    //update current queryFilter
    updateCurrentQueryFilter(state, action: PayloadAction<QueryFilterRead>) {
      state.currentQueryFilter = action.payload;
    },
    resetCurrentQueryFilter(state) {
      state.currentQueryFilter = initialState.currentQueryFilter;
    },
  },
});

export const { actions, reducer, name: sliceKey } = queryFilterSlice;
