import React from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { isEmpty, map, truncate } from 'lodash';
import filter from 'lodash/filter';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

export const renderContextTags = (
  row: Record<string, any>,
  truncateLength?: number,
) => {
  const whitelist = filter(
    row?.user_tags,
    o => o.key === 'whitelist',
  )?.map(o => ({ key: o?.value }));
  const data = [
    ...filter(row?.contexts, o => o.key),
    ...whitelist,
    ...filter(row?.user_tags, o => o.key !== 'owner' && o.key !== 'whitelist'),
    ...(!!row?.owner ? [{ key: 'Owner', value: row?.owner }] : []),
  ];
  const truncateTag = d => (
    <Box>
      {truncate(
        `${d?.display_name ?? d?.key}${
          d?.value && d?.value !== 'True' ? ':' : ''
        } ${!!d?.value && d?.value !== 'True' ? d?.value : ''}`,
        {
          length: truncateLength || 15,
        },
      )}
    </Box>
  );
  return !isEmpty(data) ? (
    <CustomTooltip
      label={
        <Stack>
          {map(data, o => (
            <HStack spacing={0}>
              <Box>{o?.display_name ?? o?.key}</Box>
              {!!o?.value && o.value !== 'True' && <Box>: {o?.value}</Box>}
            </HStack>
          ))}
        </Stack>
      }
    >
      <Stack spacing={1}>
        <HStack spacing={1}>
          {data?.[0] && <Tag label={truncateTag(data?.[0])} />}
          {data?.[1] && <Tag label={truncateTag(data?.[1])} />}
        </HStack>
        <HStack spacing={1}>
          {data?.[2] && <Tag label={truncateTag(data?.[2])} />}
          {data?.length > 3 && <Tag label={<Box>+ {data?.length - 3}</Box>} />}
        </HStack>
      </Stack>
    </CustomTooltip>
  ) : (
    '-'
  );
};
