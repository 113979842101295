import React, { useEffect, useState } from 'react';

import { ceil, map } from 'lodash';
import { useSelector } from 'react-redux';

import { BarChart } from 'components/Visualization/Charts';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { vulnerabilityLineChartStyles } from 'containers/Visibility/Vulnerabilities/Components/styles';
import { formatDate } from 'utils/date';

import { customTheme } from '../../../../../../../theme';

export const CountOfSensitiveDataTrend = () => {
  const { historicalTrend } = useSelector(selectDataDashboard);
  const { sensitive_data_trend } = historicalTrend.data;
  const [data, setData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setData(
      map(sensitive_data_trend, o => ({
        Date: formatDate(o?.created_at, 'D-MMM'),
        'Count of Sensitive Data': ceil((o?.data_count ?? 0) / 1000),
        actualCount: o?.data_count,
      })),
    );
  }, [sensitive_data_trend]);

  return (
    <BarChart
      data={data}
      isLoading={historicalTrend.isLoading}
      xField="Date"
      yField={['Count of Sensitive Data']}
      colorMap={{ 'Count of Sensitive Data': customTheme.colors.primary }}
      legend={false}
      styles={{
        ...vulnerabilityLineChartStyles,
        yAxis: {
          tickLine: false,
          axisLine: false,
          label: {
            value: 'Thousands',
            angle: -90,
            position: 'insideTopRight',
            offset: 55,
            fontSize: 12,
          },
        },
        tooltip: {
          formatter: (value, name, entry) => {
            if (name === 'Critical') return entry?.payload?.actualCritical;
            if (name === 'High') return entry?.payload?.actualHigh;
            else return entry?.payload?.actualCount;
          },
        },
        barChart: {
          margin: {
            top: 20,
            left: -5,
            right: 0,
            bottom: 1,
          },
        },
      }}
    />
  );
};
