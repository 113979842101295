/**
 *
 * Asynchronously loads the component for InventoryContext
 *
 */

import { lazyLoad } from 'utils/loadable';

export const InventoryContext = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.InventoryContext,
);
