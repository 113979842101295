import React, { useEffect, FC } from 'react';

import { ReportFor } from '@ariksa/notification';
import { DataTypeForES, Status } from '@ariksa/reporting';
import { Box, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { find, isEmpty, split } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useAlertDownloader } from 'services/Notification/useAlertDownloader';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { PrimaryIconButton, publishedAtOptions } from 'components/DataEntry';
import {
  AppIcon,
  BellIcon,
  CopyIcon,
  DownloadIcon,
  ExportIcon,
} from 'components/Icons';
import { IMenuItemProps, Menu } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { AlertFilters } from 'containers/Findings/Alerts/Components/AlertsTable/AlertFilters';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { AlertFilterFields } from 'containers/Findings/Alerts/types';
import { ExportAlertsModal } from 'containers/SharedState/Components/ExportAlerts/ExportAlertsModal';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useSearchParams } from 'hooks/useSearchParams';

interface Props {}

export const AlertsTableHeader: FC<Props> = props => {
  const dispatch = useDispatch();

  const { getCloudNativeName } = useResourceType();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const params = useSearchParams<AlertFilterFields>();
  const { groupByResource, aggregatedFindings, alerts, filters } = useSelector(
    selectAlerts,
  );
  const exportAlerts = useDisclosure();
  const { downloadReport } = useAlertDownloader();

  useEffect(() => {
    if (!isEmpty(params)) dispatch(actions.resetFilters());
  }, [params, dispatch]);

  useDeepCompareEffect(() => {
    const data = aiParamExtraction.data;
    dispatch(
      actions.updateAllFilters({
        severities: !!data?.severity
          ? [data?.severity]
          : !!data?.severities
          ? split(data?.severities, ',')
          : undefined,
        resourceType: !!data?.resource_type
          ? [data?.resource_type]
          : !!data?.resourceTypes
          ? split(data?.resourceTypes, ',')
          : undefined,
        alertCategories: !!data?.alert_category
          ? [data?.alert_category]
          : !!data?.alertCategories
          ? data?.alertCategories
          : undefined,
        searchString: data?.search || undefined,
        startTime: data?.start_date || undefined,
        endTime: data?.end_date || undefined,
        category_class: data?.category_class || undefined,
      }),
    );
  }, [aiParamExtraction.data, dispatch]);

  //set entity uuid and resource type from url params
  useEffect(() => {
    if (!!params?.days) {
      dispatch(
        actions.updateStartDate(
          find(publishedAtOptions, o => o.value === parseInt(params.days)) ??
            publishedAtOptions[0],
        ),
      );
    }
  }, [dispatch, params, getCloudNativeName]);

  const menuItems: IMenuItemProps[] = [
    {
      label: 'EXPORT ALERTS',
      group: {
        title: 'EXPORT ALERTS',
        list: [
          ...(filters.alertType !== 'Snoozed'
            ? [
                {
                  key: 'Export Alerts',
                  label: 'Export alerts to SIEM',
                  icon: <ExportIcon />,
                  onClick: () => exportAlerts.onOpen(),
                },
              ]
            : []),
          {
            key: 'download alerts',
            label: 'Download alerts in CSV',
            icon: <DownloadIcon />,
            onClick: () =>
              downloadReport({
                reportType: ReportFor.IndividualAlerts,
              }),
          },
        ],
      },
    },
  ];

  return (
    <Stack spacing={3} w="full">
      <HStack justify="space-between">
        <Box>
          <PageHeaderWithIcon label="Issues" icon={<BellIcon />} />
        </Box>
        <HStack>
          <AlertFilters />
          <PrimaryIconButton
            aria-label="Group by"
            tooltip={
              groupByResource
                ? 'Remove grouping by resources'
                : 'Group by resources'
            }
            isDisabled={
              aggregatedFindings.isLoading ||
              alerts.isLoading ||
              aiParamExtraction.isLoading
            }
            onClick={() =>
              dispatch(actions.updateGroupByResource(!groupByResource))
            }
          >
            <AppIcon />
          </PrimaryIconButton>
          <Menu
            menuItems={menuItems}
            buttonLabel={
              <PrimaryIconButton
                aria-label="Export alerts"
                tooltip="Export alerts"
                isDisabled={
                  aggregatedFindings.isLoading ||
                  alerts.isLoading ||
                  aiParamExtraction.isLoading
                }
              >
                <DownloadIcon />
              </PrimaryIconButton>
            }
          />
          {exportAlerts.isOpen && (
            <ExportAlertsModal
              isOpen={exportAlerts.isOpen}
              type={DataTypeForES.Alerts}
              alertStatus={
                filters.alertType === 'Open'
                  ? Status.Active
                  : (filters.alertType?.toUpperCase() as Status)
              }
              onClose={exportAlerts.onClose}
            />
          )}
        </HStack>
      </HStack>
      {groupByResource && (
        <HStack justify="flex-end" w="full">
          <Box boxSize={4} color="primary">
            <CopyIcon />
          </Box>
          <Box>Alerts grouped by resources</Box>
        </HStack>
      )}
    </Stack>
  );
};
