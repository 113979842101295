import React, { useCallback, useState } from 'react';

import { EditDashboardLayoutRequest } from '@ariksa/reporting';
import { Stack, HStack, Center } from '@chakra-ui/react';
import { includes } from 'lodash';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, SaveButton } from 'components/DataEntry';
import { usePageContentContext } from 'components/Layout';
import { CDashboard } from 'components/Visualization';
import { useReportsDashboard } from 'containers/Reports/ReportsDashboard/hooks/useReportsDashboard';
import { selectReportsDashboard } from 'containers/Reports/ReportsDashboard/selectors';

import { actions } from '../slice';

export const EditDashboard: React.FC = () => {
  const { reports, updateCharts, reportsInfo } = useSelector(
    selectReportsDashboard,
  );
  const { createDashboardLayout, getReportingCharts } = useReportsDashboard();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [layout, setLayout] = useState<EditDashboardLayoutRequest[]>();
  const { contentHeight } = usePageContentContext();

  // create dashboardWidgets list from configs
  const dashboardWidgets = createDashboardLayout(
    'reports_dashboard',
    'Reports Dashboard',
  );

  const handelRedirect = () => {
    navigate('/reports/dashboard');
    getReportingCharts();
  };

  const onLayoutChange = useCallback(
    layout => {
      const previousCharts = map(reports, (value, key) => key);
      const currentCharts = map(layout, o => o.i);
      const removedCharts = isEmpty(previousCharts)
        ? []
        : filter(previousCharts, o => !includes(currentCharts, o));
      setLayout([
        ...map(layout, o => ({
          chart_id: o?.i,
          chart_position: { x: o?.x, y: o?.y },
          remove_from_dashboard: false,
        })),
        ...map(removedCharts, o => ({
          chart_id: o,
          remove_from_dashboard: true,
        })),
      ]);
    },
    [reports],
  );

  return (
    <Stack spacing={5}>
      <HStack w="full" justify="end">
        <SaveButton
          isLoading={updateCharts.isLoading}
          onClick={() => {
            dispatch(
              actions.updateLayout({
                q: {
                  editDashboardLayoutRequest: layout ?? [],
                },
                onSuccess: () => handelRedirect(),
              }),
            );
          }}
        />
        <CancelButton
          onClick={() => {
            handelRedirect();
          }}
        />
      </HStack>
      {reportsInfo.isLoading && (
        <Center w="full" h={contentHeight + 'px'}>
          <CustomSpinner loading={reportsInfo.isLoading} size="lg" />
        </Center>
      )}
      {!reportsInfo.isLoading && (
        <CDashboard
          dashboardWidgets={dashboardWidgets}
          onLayoutChange={onLayoutChange}
        />
      )}
    </Stack>
  );
};
