import { Charts, ChartsApiGetChartsRequest } from '@ariksa/reporting';

import { ReportingService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useReportingService() {
  const reportingCharts = useAriksaQuery<ChartsApiGetChartsRequest, Charts[]>({
    queryKey: [],
    fetcher: req => {
      return ReportingService.Charts.getCharts(req).then(d => d.data);
    },
  });

  return { reportingCharts };
}
