import { createContext, useContext } from 'react';

export interface BlueprintContextProps {
  showPolicies: boolean;
  setShowPolicies(val: boolean);
}

export const BlueprintContext = createContext<BlueprintContextProps>({
  showPolicies: false,
  setShowPolicies() {},
});

export const BlueprintContextProvider = BlueprintContext.Provider;

export const useBlueprint = () => useContext(BlueprintContext);
