import { NodeEdgeMap } from './NodeEdgeMap';
import { AGraphNodeId } from './types';

export class ChildEdgeMap extends NodeEdgeMap {
  parents(): AGraphNodeId[] {
    return this.sourceIds();
  }

  children(parentId: AGraphNodeId): AGraphNodeId[] {
    return this.destIds(parentId);
  }
}
