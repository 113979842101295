/**
 *
 * Email Integration
 *
 */

import React, { FC, useEffect } from 'react';

import { Clients } from '@ariksa/notification';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DeleteClientModal } from 'containers/Setup/Integrations/DeleteClientModal';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { actions } from '../../slice';

export const EmailTable: FC = () => {
  const dispatch = useDispatch();
  const { clients, client } = useSelector(selectIntegrations);
  const {
    clientActionsColumn,
    isOpenDeleteClientModal,
  } = useIntegrationsContext();

  useEffect(() => {
    dispatch(
      actions.loadClients({
        clientName: Clients.Email,
      }),
    );
  }, [dispatch]);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      styles: {
        maxWidth: 200,
      },
    },
    {
      header: 'Members',
      accessor: 'email',
      render: ({ value }) => value?.email_group?.length,
    },
    {
      header: 'Associations',
      accessor: 'associations',
    },
    {
      header: 'Created By',
      accessor: 'created_by',
    },
    clientActionsColumn,
  ];

  return (
    <>
      <Table
        columns={columns}
        data={clients.email}
        isLoading={clients.loading || client.isLoading}
      />
      {isOpenDeleteClientModal && <DeleteClientModal />}
    </>
  );
};
