import {
  DataSourcesWithData,
  ResourceDistributionCount,
  ResourcesApiGetSourcesDistributionCountRequest,
  VisibilityDataApiGetDataSourcesWithSensitiveDataRequest,
} from '@ariksa/data-scanning/api';
import {
  CloudInsight,
  CloudInsightApiGetAnomaliesRequest,
  CloudInsightApiGetAnomalousDataSourcesRequest,
  CloudInsightSummary,
  DataSourcesSecurityAttributes,
  PublicResourceSummary,
  ResourceApiGetDataSourcesInsightsRequest,
  ResourceSummaryApiGetPublicExposedResSummaryRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AlertHistoryResponse,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
} from '@ariksa/notification/api';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDataSourcesCountRequest,
  DataSourcesCount,
  InventoryApiGetResourceCountRequest,
  ResourceCountResponse,
} from '@ariksa/reporting/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  DataScanningService,
  InventoryService,
  NotificationService,
  ReportingService,
} from 'api/services';
import { doGetDataSourcesAnalyzedInfo } from 'containers/Visibility/Data/saga';

import { actions } from './slice';

export function* dataSecurityOverviewSaga() {
  yield takeLatestAction(
    actions.getScannedDataSources,
    doGetScannedDataSources,
  );

  //data security
  yield takeLatestAction(actions.getBucketCount, doGetBucketCount);
  yield takeLatestAction(actions.getDBCount, doGetDBCount);
  yield takeLatestAction(
    actions.getOtherDataSourcesCount,
    doGetOtherDataSourcesCount,
  );
  yield takeLatestAction(
    actions.getDataSecuritySaaSCount,
    doGetDataSecuritySaaSCount,
  );
  yield takeLatestAction(
    actions.getDataSourcesSummaryCount,
    doGetDataSourcesSummaryCount,
  );
  yield takeLatestAction(
    actions.getDataSourcesAnalyzedInfo,
    doGetDataSourcesAnalyzedInfo,
  );
  yield takeLatestAction(
    actions.getDataSourcesHistory,
    doGetDataSourcesHistory,
  );
  yield takeLatestAction(
    actions.getPubliclyExposedDataSources,
    doGetPubliclyExposedDataSources,
  );
  yield takeLatestAction(
    actions.getSensitiveDataBySources,
    doGetSensitiveDataSourcesCount,
  );
  yield takeLatestAction(
    actions.getExposedAndUnencryptedDataSources,
    doGetExposedAndUnencryptedDataSources,
  );
  yield takeLatestAction(
    actions.getInactiveAndHasAnomalyDataSources,
    doGetAnomalousDataSources,
  );

  yield takeLatestAction(
    actions.getDataSecurityFindingsCategorySummary,
    doGetFindingsCategorySummary,
  );
}

export function* doGetBucketCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get buckets count!',
      cacheKey: 'bucketReportingResourcesCount',
    },
  );
}

export function* doGetDBCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get DB count!',
      cacheKey: 'dbReportingResourcesCount',
    },
  );
}

export function* doGetOtherDataSourcesCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get other data sources count!',
      cacheKey: 'otherDataSourcesReportingResourcesCount',
    },
  );
}

export function* doGetDataSecuritySaaSCount(
  ctx: QueryContext<ResourceCountResponse, InventoryApiGetResourceCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Inventory.getResourceCount(ctx.params),
    {
      errorMsg: 'Failed to get data security SaaS count!',
      cacheKey: 'dataSecuritySaaSReportingResourcesCount',
    },
  );
}

//get findings history
export function* doGetOpenFindingHistory(
  ctx: QueryContext<AlertHistoryResponse, AlertsApiGetAlertHistoryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertHistory(ctx.params),
    {
      errorMsg: 'Failed to get open finding history!',
      cacheKey: 'openFindingHistory',
    },
  );
}

//get scanned data sources
export function* doGetScannedDataSources(
  ctx: QueryContext<
    DataSourcesWithData,
    VisibilityDataApiGetDataSourcesWithSensitiveDataRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      DataScanningService.Visibility.getDataSourcesWithSensitiveData(
        ctx.params,
      ),
    {
      errorMsg: 'Failed to get scanned data sources!',
      cacheKey: 'scannedDataSources',
    },
  );
}

export function* doGetSensitiveDataSourcesCount(
  ctx: QueryContext<
    ResourceDistributionCount,
    ResourcesApiGetSourcesDistributionCountRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => DataScanningService.Resources.getSourcesDistributionCount(ctx.params),
    {
      errorMsg: 'Failed to get sensitive data sources count!',
      cacheKey: 'sensitiveDataSourcesCount',
    },
  );
}

//get data sources count
export function* doGetDataSourcesSummaryCount(
  ctx: QueryContext<DataSourcesCount, ChartsApiGetDataSourcesCountRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDataSourcesCount(ctx.params),
    {
      errorMsg: 'Failed to get data sources summary count!',
      cacheKey: 'dataSourcesSummaryCount',
    },
  );
}

//get data sources history
export function* doGetDataSourcesHistory(
  ctx: QueryContext<
    Record<string, any>,
    ChartsApiGetDashboardResourcesHistoryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.getDashboardResourcesHistory(ctx.params),
    {
      errorMsg: 'Failed to get data sources history!',
      cacheKey: 'dataSourcesHistory',
    },
  );
}

export function* doGetExposedAndUnencryptedDataSources(
  ctx: QueryContext<
    DataSourcesSecurityAttributes,
    ResourceApiGetDataSourcesInsightsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getDataSourcesInsights(ctx.params),
    {
      errorMsg: 'Failed to get exposed and unencrypted data sources!',
      cacheKey: 'exposedAndUnencryptedDataSources',
    },
  );
}

export function* doGetAnomalousDataSources(
  ctx: QueryContext<CloudInsightSummary[], CloudInsightApiGetAnomalousDataSourcesRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.CloudInsight.getAnomalousDataSources(ctx.params),
    {
      errorMsg: 'Failed to get data sources with anomaly!',
      //cacheKey: 'exposedAndUnencryptedDataSources',
    },
  );
  /* yield call(
    ctx.fetch,
    async () => {
      const url = Api.getAnomalies({
        account_id: ctx.params.body.account_ids,
      });
      return request(url);
    },
    {
      errorMsg: 'Failed to get data sources with anomaly or are inactive!',
      // cacheKey: 'inactiveAndHasAnomalyDataSources',
    },
  );*/
}

//get findings category summary
export function* doGetFindingsCategorySummary(
  ctx: QueryContext<
    AggregatedAlertsSummary[],
    AlertsApiGetFindingsCategoriesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getFindingsCategoriesSummary(ctx.params),
    {
      errorMsg: 'Failed to get findings category summary!',
      cacheKey: 'dataSecurityFindingsCategorySummary',
    },
  );
}

/*get publicly exposed data sources*/
export function* doGetPubliclyExposedDataSources(
  ctx: QueryContext<
    PublicResourceSummary[],
    ResourceSummaryApiGetPublicExposedResSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      InventoryService.ResourceSummary.getPublicExposedResSummary(ctx.params),
    {
      errorMsg: 'Failed to get publicly exposed data sources!',
      cacheKey: 'publiclyExposedDataSources',
    },
  );
}
