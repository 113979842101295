import React from 'react';

import { Image } from '@chakra-ui/react';
// images/blackIcons/aws/48/dark/Disk.svg

import {
  VirtualMachineIcon,
  UserIcon,
  RoleIcon,
  DiskIcon,
  WarningIcon,
  LayerGroupIcon,
} from 'components/Icons';

import Clock from './svg/clock.svg';
import GreenClock from './svg/clock_green.svg';
import Internet from './svg/internet.jpeg';
import Port from './svg/network-port-svgrepo-com.svg';
import NoAlarm from './svg/No_Alarm.svg';
import Region from './svg/region.svg';
import ShieldExclamation from './svg/shield-exclamation.svg';
import Subnet from './svg/Subnet.svg';
import Vpc from './svg/supernet-vpc.svg';
import SyncSlash from './svg/sync-slash.svg';

export const User = <UserIcon width="4" />;
export const Role = <RoleIcon width="4" />;
export const ClockIcon = () => <Image src={Clock} width={{ md: 4 }} />;
export const GreenClockIcon = () => (
  <Image src={GreenClock} width={{ md: 4 }} />
);
export const NoAlarmIcon = () => <Image src={NoAlarm} width={{ md: 4 }} />;
export const RegionIcon = () => <Image src={Region} width={{ md: 4 }} />;
export const VpcIcon = () => <Image src={Vpc} width={{ md: 4 }} />;
export const SubnetIcon = () => <Image src={Subnet} width={{ md: 4 }} />;
export const InternetIcon = () => <Image src={Internet} width={{ md: 4 }} />;
export const Warning = <WarningIcon width="4" />;
export const SyncSlashIcon = () => <Image src={SyncSlash} width={{ md: 4 }} />;
export const LayerGroup = <LayerGroupIcon width="4" />;
export const ShieldExclamationIcon = () => (
  <Image src={ShieldExclamation} width={{ md: 4 }} />
);
export const VirtualMachine = <VirtualMachineIcon width="4" />;

export const Disk = <DiskIcon width="4" />;
