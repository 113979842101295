export const SELECT_HEIGHT = '22px';

export const tagStyles = {
  tag: { borderRadius: 6, bg: 'primary', color: 'white' },
  label: { color: 'inherit' },
};

export const deleteIconStyles = {
  height: 8,
  bg: 'none',
  color: 'delete',
  _focus: {
    bg: 'none',
  },
  _hover: { bg: 'none' },
  _active: { bg: 'none' },
  p: 2,
};

export const valueContainerStyles = {
  justify: 'space-between',
  bg: 'gray.50',
  pl: 2,
  py: 1,
  minH: 10,
  w: 'full',
  isInline: true,
  spacing: 1,
  borderRadius: 6,
};

export const multiSelectStyles = {};
