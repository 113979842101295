import { ChartType } from '@ariksa/reporting';

export const getWidgetChartType = type => {
  switch (type) {
    case ChartType.CriticalAlerts:
    case ChartType.OpenAlerts:
    case ChartType.AlertResolutions:
    case ChartType.CloudInventory:
    case ChartType.CriticalFindings:
    case ChartType.OpenFindings:
    case ChartType.TotalFindings:
    case ChartType.TotalAlerts:
    case ChartType.ResourcesWithNoTags:
    case ChartType.GovernanceScore:
    case ChartType.VulnerabilityOccurrences:
    case ChartType.HostsWithUnpatchedOsAndTechnology:
      return 'lineChart';
    default:
      return 'barChart';
  }
};
