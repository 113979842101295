import React, { FC, useCallback, useEffect, useReducer, useRef } from 'react';

import { Clients } from '@ariksa/notification';
import { EnvironmentAccount } from '@ariksa/reporting';
import {
  ReportingTargets,
  ReportsResponse,
  ReportTypes,
  ScheduleInterval,
} from '@ariksa/reporting';
import { Box, Center, Flex, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
  filter,
  find,
  findIndex,
  forEach,
  includes,
  isEmpty,
  unionBy,
  without,
} from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEnvironmentAccountIdsArray,
  getEnvironmentAccountIdsObject,
  WithResourceIcon,
} from 'components/DataDisplay';
import { FormAction, RadioButtonGroup, Select } from 'components/DataEntry';
import { weekRangeOptions } from 'components/DataEntry/Button/utils';
import { WeekRangeButtonGroup } from 'components/DataEntry/Button/WeekRangeButtonGroup';
import { CustomDayPicker } from 'components/DataEntry/Date';
import { Form, FormSchema } from 'components/DataEntry/Form';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { formStyles } from 'containers/Reports/AllReports/Components/styles';
import {
  frequencyOptions,
  months,
  reportFormatOptions,
  reportTypeOptions,
  targetOptions,
  weekDaysOptions,
  weekNumberOptions,
} from 'containers/Reports/AllReports/Components/utils';
import { selectReports } from 'containers/Reports/AllReports/selectors';
import { actions } from 'containers/Reports/AllReports/slice';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface Props {
  currentRecord: ReportsResponse;
  isOpen: boolean;
  onClose: () => void;
  actionType: FormAction;
}

export const ReportForm: FC<Props> = props => {
  const { currentRecord, isOpen, onClose, actionType } = props;
  const { report } = useSelector(selectReports);
  const { clients } = useSelector(selectSharedState);
  const {
    getEnvironmentOptions,
    getGroupedAccountsUnderEnvironmentOptions,
  } = useEnvironmentOptions();
  const { toCloudAccountId } = useCloudAccountId();

  const currentDate = new Date();
  const dispatch = useDispatch();
  const ref = useRef(document.querySelector('.portal-container'));

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedReportType: reportTypeOptions[0],
      frequency: frequencyOptions[0],
      startDate: currentDate,
      endDate: dayjs().add(1, 'day').toDate(),
      dayOfWeek: weekRangeOptions[1],
      yearlyDate: currentDate,
      name: '',
      dayOfMonth: 0,
      month: months[dayjs(currentDate).month()],
      isEdit: false,
      emailIds: [],
      userIds: [],
      environmentOptions: [],
      accountOptions: [],
      selectedEnvs: [],
      selectedAccounts: [],
      scheduledTime: '10:00',
      userIdOptions: [],
      monthlyType: ScheduleInterval.Monthly,
      monthlyWeekNumber: weekNumberOptions[0],
      monthlyWeekDay: weekDaysOptions[0],
      target: targetOptions[0],
      elasticSearchOptions: [],
      selectedElasticSearch: {},
      reportFormat: reportFormatOptions[1],
    },
  );

  //const { insightV2All: users } = useSelector(selectSharedState);

  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //set environment options
  useEffect(() => {
    updateState({ environmentOptions: getEnvironmentOptions() });
  }, [getEnvironmentOptions]);

  //set account options
  useEffect(() => {
    updateState({
      accountOptions: getGroupedAccountsUnderEnvironmentOptions(
        state.selectedEnvs,
      ),
    });
  }, [state.selectedEnvs, getGroupedAccountsUnderEnvironmentOptions]);

  //set Target (SIEM or Email)
  useEffect(() => {
    let target =
      find(targetOptions, o => o?.value === currentRecord?.target) ??
      (state.selectedReportType?.value === ReportTypes.Vulnerabilities ||
        state.selectedReportType?.value === ReportTypes.DataClassification)
        ? targetOptions[1]
        : targetOptions[0];
    const reportFormat =
      target?.value === ReportingTargets.ElasticSearch
        ? reportFormatOptions[2]
        : state.selectedReportType?.value === ReportTypes.Inventory
        ? reportFormatOptions[0]
        : reportFormatOptions[1];
    updateState({ target, reportFormat });
  }, [state.selectedReportType, state.isEdit, currentRecord]);

  useEffect(() => {
    if (state?.target?.value === ReportingTargets.ElasticSearch)
      dispatch(
        sharedStateActions.getClients({ clientName: Clients.Elasticsearch }),
      );
  }, [dispatch, state?.target]);

  //set elastic search options
  useEffect(() => {
    const options = map(clients.elasticsearch, o => ({
      label: o?.name,
      value: o?.uuid,
    }));
    updateState({
      elasticSearchOptions: options,
      selectedElasticSearch: options?.[0],
    });
  }, [clients.elasticsearch]);

  //set selected elastic search client in update mode
  useEffect(() => {
    if (state.isEdit)
      updateState({
        selectedElasticSearch: find(
          state.elasticSearchOptions,
          o => o?.value === currentRecord?.client_id,
        ),
      });
  }, [state.elasticSearchOptions, currentRecord, state.isEdit]);

  //set user id options
  /*useEffect(() => {
    updateState({
      userIdOptions: map(users.data?.items, o => ({
        label: o?.name ?? o?.resource_id,
        value: o?.uuid,
      })),
    });
  }, [users.data]);

  //set selected user ids
  useEffect(() => {
    updateState({
      userIds: filter(state.userIdOptions, o =>
        includes(currentRecord.user_ids, o.value),
      ),
    });
  }, [state.userIdOptions, currentRecord]);*/

  useEffect(() => {
    if (state.isEdit) {
      const scheduled_time = currentRecord?.scheduled_time?.split(':');
      const time = dayjs
        .utc()
        .hour(parseInt(scheduled_time[0]))
        .minute(parseInt(scheduled_time[1]))
        .local()
        .format('HH:mm');
      let monthlyType: Record<string, any> = {
        yearlyDate: dayjs().date(currentRecord?.day_of_month!).toDate(),
        dayOfWeek:
          find(weekRangeOptions, o => o.value === currentRecord.day_of_week) ??
          weekRangeOptions[0],
      };
      let frequency = currentRecord.frequency;
      if (currentRecord.frequency === ScheduleInterval.NWeekly) {
        frequency = ScheduleInterval.Monthly;
        monthlyType = {
          monthlyType: ScheduleInterval.NWeekly,
          monthlyWeekNumber:
            find(
              weekNumberOptions,
              o => o.value === currentRecord?.day_of_month!,
            ) ?? {},
          monthlyWeekDay: find(
            weekDaysOptions,
            o => o.value === currentRecord?.day_of_week,
          ),
        };
      }
      const target =
        find(targetOptions, o => o?.value === currentRecord?.target) ??
        targetOptions[0];
      updateState({
        selectedReportType:
          find(reportTypeOptions, o => o.value === currentRecord.report_type) ??
          {},
        scheduledTime: time,
        emailIds: currentRecord.email_ids,
        frequency: find(frequencyOptions, o => o.value === frequency) ?? {},
        startDate: dayjs.utc(currentRecord?.start_date).local()?.toDate(),
        endDate: dayjs.utc(currentRecord?.end_date).local()?.toDate(),
        name: currentRecord?.name,
        //target: target,
        ...monthlyType,
        /*reportFormat:
          currentRecord?.target === ReportingTargets.ElasticSearch
            ? reportFormatOptions[2]
            : currentRecord.report_type === ReportTypes.Inventory
            ? reportFormatOptions[0]
            : reportFormatOptions[1],*/
      });
      if (state.isEdit && currentRecord.frequency === ScheduleInterval.Yearly) {
        updateState({
          yearlyDate: dayjs()
            .month(findIndex(months, o => o === currentRecord.month))
            .date(currentRecord?.day_of_month!)
            .toDate(),
        });
      }
    }
  }, [currentRecord, state.isEdit]);

  useEffect(() => {
    updateState({
      dayOfMonth: dayjs(state.yearlyDate).date(),
      month: months[dayjs(state.yearlyDate).month()],
    });
  }, [state.yearlyDate]);

  //set filters
  useEffect(() => {
    if (state.isEdit) {
      updateState({
        selectedEnvs: filter(state.environmentOptions, o =>
          includes(
            [
              ...map(currentRecord.ids, i => i.environment_id),
              currentRecord?.filters?.environment,
            ],
            o.value,
          ),
        ),
      });
    }
  }, [state.isEdit, state.environmentOptions, currentRecord]);

  const setSelectedAccounts = useCallback(
    ids => {
      const accounts: Record<string, any>[] = [];
      forEach(ids, o => {
        forEach(o?.account_ids, a => {
          accounts.push({
            label: toCloudAccountId(a),
            value: a + '_' + o?.environment_id,
            data: {
              accountId: a,
              environmentId: o?.environment_id,
            },
          });
        });
      });
      return accounts;
    },
    [toCloudAccountId],
  );

  //set selected accounts
  useEffect(() => {
    if (state.isEdit) {
      updateState({
        selectedAccounts: setSelectedAccounts(currentRecord.ids),
      });
    }
  }, [state.isEdit, currentRecord, setSelectedAccounts]);

  const onConfirm = data => {
    const { name, scheduled_time } = data;
    const scheduledTime = scheduled_time?.split(':');
    const time = dayjs()
      .hour(parseInt(scheduledTime[0]))
      .minute(parseInt(scheduledTime[1]))
      .utc()
      .format('HH:mm');
    const startTime = dayjs(state.startDate)
      .hour(parseInt(scheduledTime[0]))
      .minute(parseInt(scheduledTime[1]))
      .utc()
      .format('YYYY-MM-DD');

    const fields = frequency => {
      switch (frequency) {
        case ScheduleInterval.Weekly:
          return { day_of_week: state.dayOfWeek.value };
        case ScheduleInterval.Monthly:
          return { day_of_month: state.dayOfMonth };
        case ScheduleInterval.Yearly:
          return { day_of_month: state.dayOfMonth, month: state.month };
        case ScheduleInterval.NWeekly:
          return {
            day_of_month: state.monthlyWeekNumber.value,
            day_of_week: state.monthlyWeekDay.value,
            frequency: ScheduleInterval.NWeekly,
          };
      }
    };
    let accounts = getEnvironmentAccountIdsObject(state.selectedAccounts);
    const remainingEnvironments = without(
      map(state.selectedEnvs, o => o.value),
      ...Object.keys(accounts),
    );
    const ids = [
      ...map(remainingEnvironments, o => ({
        environment_id: o,
        account_ids: [],
      })),
      ...getEnvironmentAccountIdsArray(state.selectedAccounts),
    ];
    const siem =
      state?.target?.value === ReportingTargets.ElasticSearch
        ? {
            target: state?.target?.value,
            client_id: state.selectedElasticSearch?.value,
          }
        : {};

    const payload = {
      name,
      start_date: startTime,
      end_date: dayjs(state.endDate).utc().format('YYYY-MM-DD'),
      duration: state.isEdit ? currentRecord.duration : 0,
      description: state.isEdit ? currentRecord.description : '',
      scheduled_time: time,
      frequency: state.frequency?.value,
      email_ids: state.emailIds,
      user_ids: map(state.userIds, o => o.value),
      ids: ids as EnvironmentAccount[],
      report_format: state.reportFormat?.value,
      ...fields(state.frequency.value),
      ...fields(state.monthlyType),
      ...siem,
    };

    const onSuccess = () => {
      onClose();
      dispatch(actions.getReports({}));
    };

    if (!state.isEdit)
      dispatch(
        actions.addReport({
          q: {
            reportsCreate: {
              ...payload,
              only_diff:
                state.selectedReportType?.value === ReportTypes.Vulnerabilities,
              report_type: state.selectedReportType?.value,
            },
          },
          onSuccess: onSuccess,
        }),
      );
    else
      !!currentRecord?.id &&
        dispatch(
          actions.updateReport({
            q: { reportsUpdate: payload, reportId: currentRecord?.id },
            onSuccess: onSuccess,
          }),
        );
  };

  const weekly: FormSchema =
    state.frequency.value === ScheduleInterval.Weekly
      ? {
          day_of_week: {
            type: 'custom',
            component: () => (
              <Center mb={3}>
                <WeekRangeButtonGroup
                  value={state.dayOfWeek}
                  onChange={value => updateState({ dayOfWeek: value })}
                />
              </Center>
            ),
          },
        }
      : {};

  const monthly: FormSchema =
    state.frequency.value === ScheduleInterval.Monthly
      ? {
          weekDay: {
            type: 'custom',
            component: () => (
              <Flex align="start">
                <RadioButtonGroup
                  options={[
                    {
                      label: (
                        <CustomDayPicker
                          dateProps={{
                            mode: 'single',
                            onSelect: selected => {
                              updateState({
                                yearlyDate: selected ?? currentDate,
                                monthlyType: ScheduleInterval.Monthly,
                              });
                            },
                            selected: state.yearlyDate,
                          }}
                        />
                      ),
                      value: ScheduleInterval.Monthly,
                    },
                    {
                      label: (
                        <HStack>
                          <Box>
                            <Select
                              options={weekNumberOptions}
                              value={state.monthlyWeekNumber}
                              onChange={selected =>
                                updateState({
                                  monthlyWeekNumber: selected,
                                  monthlyType: ScheduleInterval.NWeekly,
                                })
                              }
                            />
                          </Box>
                          <Select
                            options={weekDaysOptions}
                            value={state.monthlyWeekDay}
                            onChange={selected =>
                              updateState({
                                monthlyWeekDay: selected,
                                monthlyType: ScheduleInterval.NWeekly,
                              })
                            }
                          />
                        </HStack>
                      ),
                      value: ScheduleInterval.NWeekly,
                    },
                  ]}
                  value={state.monthlyType}
                  allowClickOnIndividual={true}
                  onChange={option =>
                    updateState({ monthlyType: option.value })
                  }
                  styles={{
                    label: {
                      onClick: () => {},
                    },
                  }}
                />
              </Flex>
            ),
          },
        }
      : {};

  const yearly: FormSchema =
    state.frequency.value === ScheduleInterval.Yearly
      ? {
          yearly: {
            type: 'dayPicker',
            dateProps: {
              fromDate: currentDate,
              mode: 'single',
              onSelect: selected => {
                updateState({
                  yearlyDate: selected ?? currentDate,
                });
              },
              selected: state.yearlyDate,
            },
          },
        }
      : {};

  const siem: FormSchema =
    state.target?.value === ReportingTargets.ElasticSearch
      ? {
          recipients: {
            type: 'react-select',
            label: 'Recipient',
            isRequired: true,
            options: state.elasticSearchOptions,
            value: state.selectedElasticSearch,
            onChange: value => {
              updateState({ selectedElasticSearch: value });
            },
            isDisabled: clients.loading,
            isLoading: clients.loading,
          },
        }
      : {
          report_format: {
            type: 'react-select',
            label: 'Report format',
            //isRequired: true,
            options: reportFormatOptions,
            value: state.reportFormat,
            onChange: selected => {
              updateState({ target: selected });
            },
            isDisabled: true,
          },
          email_ids: {
            type: 'emailIds',
            label: 'Recipients (press enter to add)',
            isRequired: true,
            value: state.emailIds,
            onChange: value => {
              updateState({ emailIds: value });
            },
            placeholder: 'Enter email Ids...',
          },
        };

  console.log(state.target);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <WithResourceIcon
          resourceType={IconTypes.Clock}
          bgColor="primary"
          iconSize="medium"
        >
          <Box>{actionType} Report</Box>
        </WithResourceIcon>
      }
      styles={{
        drawer: { size: 'lg', portalProps: { containerRef: ref as any } },
      }}
      body={
        <Form
          schema={{
            name: {
              type: 'text',
              label: 'Name',
              isRequired: true,
              defaultValue: currentRecord.name,
              onChange: value => updateState({ name: value }),
            },
            reportType: {
              type: 'react-select',
              label: 'Report type',
              options: reportTypeOptions,
              isRequired: true,
              value: state.selectedReportType,
              onChange: selected => {
                console.log(selected);
                updateState({ selectedReportType: selected });
              },
              isDisabled: state.isEdit,
            },
            date: {
              type: 'object',
              properties: {
                start_date: {
                  type: 'dayPicker',
                  label: 'Start date',
                  dateProps: {
                    fromDate: currentDate,
                    mode: 'single',
                    onSelect: selected => {
                      updateState({ startDate: selected ?? currentDate });
                      if (dayjs(selected ?? currentDate).isAfter(state.endDate))
                        updateState({
                          endDate: dayjs(selected ?? currentDate)
                            .add(1, 'day')
                            .toDate(),
                        });
                    },
                    selected: state.startDate,
                  },
                },
                end_date: {
                  type: 'dayPicker',
                  label: 'End date',
                  dateProps: {
                    fromDate: dayjs(state.startDate).add(1, 'day').toDate(),
                    mode: 'single',
                    onSelect: selected =>
                      updateState({ endDate: selected ?? currentDate }),
                    selected: state.endDate,
                  },
                },
              },
            },
            frequency: {
              type: 'object',
              label: 'Frequency',
              properties: {
                frequency: {
                  type: 'react-select',
                  options: frequencyOptions,
                  value: state.frequency,
                  onChange: value => updateState({ frequency: value }),
                },
                ...weekly,
                ...monthly,
                ...yearly,
              },
              styles: {
                objectContainer: { align: 'start', isInline: true, spacing: 4 },
              },
            },
            scheduled_time: {
              type: 'timePicker',
              label: 'Scheduled time',
              isRequired: true,
              value: state.scheduledTime,
              onChange: value => {
                updateState({ scheduledTime: value });
              },
            },
            filters: {
              type: 'object',
              label: 'SPECIFY FILTERS',
              properties: {
                environment: {
                  type: 'react-select',
                  label: 'Select environments',
                  options: state.environmentOptions,
                  isMulti: true,
                  value: state.selectedEnvs,
                  onChange: selected => {
                    const ids = map(selected, o => ({
                      environment_id: o?.value,
                      account_ids: o?.data?.account_ids,
                    }));
                    const accounts = setSelectedAccounts(ids);
                    updateState({
                      selectedEnvs: selected,
                      selectedAccounts: accounts,
                    });
                  },
                  showTotalSelected: true,
                  isRequired: true,
                },
                account: {
                  type: 'react-select',
                  label: 'Select accounts',
                  options: state.accountOptions,
                  value: state.selectedAccounts,
                  onChange: selected =>
                    updateState({ selectedAccounts: selected }),
                  isMulti: true,
                  onClickGroupHeading: options =>
                    updateState({
                      selectedAccounts: unionBy(
                        state.selectedAccounts,
                        options,
                        'value',
                      ),
                    }),
                  showTotalSelected: true,
                },
              },
            },
            target: {
              type: 'react-select',
              label: 'Target',
              //isRequired: true,
              options: targetOptions,
              value: state.target,
              onChange: selected => {
                updateState({
                  target: selected,
                  reportFormat:
                    selected?.value === ReportingTargets.ElasticSearch
                      ? reportFormatOptions[2]
                      : state.selectedReportType?.value ===
                        ReportTypes.Inventory
                      ? reportFormatOptions[0]
                      : reportFormatOptions[1],
                });
              },
              isDisabled:
                state.selectedReportType?.value !== ReportTypes.Inventory,
            },
            ...siem,
          }}
          styles={formStyles}
          buttonOptions={{
            submit: {
              name: 'Confirm',
              isLoading: report.isLoading,
              isDisabled:
                report.isLoading ||
                !state.name ||
                (state.target?.value === ReportingTargets.Email &&
                  isEmpty(state.emailIds)) ||
                (state.target?.value === ReportingTargets.ElasticSearch &&
                  !state.selectedElasticSearch?.value) ||
                isEmpty(state.selectedEnvs),
            },
            reset: {
              name: 'Cancel',
              isVisible: true,
              onClick: onClose,
            },
          }}
          handleSubmit={onConfirm}
        />
      }
    />
  );
};
