import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VirtualMachineIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    p={0.5}
    {...props}
  >
    <path
      d="M192,243.2H89.6c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8H192c7.1,0,12.8-5.7,12.8-12.8
	S199.1,243.2,192,243.2z M422.4,393.6c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S431.2,393.6,422.4,393.6z M422.4,86.4
	c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S431.2,86.4,422.4,86.4z M345.6,393.6c-8.8,0-16,7.2-16,16s7.2,16,16,16
	s16-7.2,16-16S354.4,393.6,345.6,393.6z M192,396.8H89.6c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8H192
	c7.1,0,12.8-5.7,12.8-12.8S199.1,396.8,192,396.8z M345.6,240c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S354.4,240,345.6,240z
	 M345.6,86.4c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S354.4,86.4,345.6,86.4z M192,89.6H89.6c-7.1,0-12.8,5.7-12.8,12.8
	s5.7,12.8,12.8,12.8H192c7.1,0,12.8-5.7,12.8-12.8S199.1,89.6,192,89.6z M422.4,240c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16
	S431.2,240,422.4,240z M512,128V76.8c0-35.3-28.7-64-64-64H64c-35.3,0-64,28.7-64,64V128c0,21,10.3,39.5,26,51.2
	c-15.7,11.7-26,30.2-26,51.2v51.2c0,21,10.3,39.5,26,51.2C10.3,344.5,0,363,0,384v51.2c0,35.3,28.7,64,64,64h384
	c35.3,0,64-28.7,64-64V384c0-21-10.3-39.5-26-51.2c15.7-11.7,26-30.2,26-51.2v-51.2c0-21-10.3-39.5-26-51.2
	C501.7,167.5,512,149,512,128z M486.4,384v51.2c0,21.2-17.2,38.4-38.4,38.4H64c-21.2,0-38.4-17.2-38.4-38.4V384
	c0-21.2,17.2-38.4,38.4-38.4h384C469.2,345.6,486.4,362.8,486.4,384z M486.4,230.4v51.2c0,21.2-17.2,38.4-38.4,38.4H64
	c-21.2,0-38.4-17.2-38.4-38.4v-51.2c0-21.2,17.2-38.4,38.4-38.4h384C469.2,192,486.4,209.2,486.4,230.4z M486.4,128
	c0,21.2-17.2,38.4-38.4,38.4H64c-21.2,0-38.4-17.2-38.4-38.4V76.8c0-21.2,17.2-38.4,38.4-38.4h384c21.2,0,38.4,17.2,38.4,38.4V128z"
    />
  </Icon>
);
