/**
 *
 * Findings
 *
 */
import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { Alerts } from 'containers/Findings/Alerts/Loadable';
import { FindingsOverview } from 'containers/Findings/FindingsOverview';
import { findingsOverviewSaga } from 'containers/Findings/FindingsOverview/saga';
import { reducer, sliceKey } from 'containers/Findings/FindingsOverview/slice';
import { Tickets } from 'containers/Findings/Tickets/Loadable';
import { useInjector } from 'utils/inject';

export const Findings = () => {
  useInjector(sliceKey, reducer, findingsOverviewSaga);
  return (
    <Routes>
      <Route element={<FilterRouter path="/findings/alerts" />}>
        <Route path="alerts" element={<Alerts />} />
      </Route>
      <Route
        element={<FilterRouter path="/findings/alerts/:status/:severity" />}
      >
        <Route path="alerts/:status/:severity" element={<Alerts />} />
      </Route>
      <Route element={<FilterRouter path="/findings/overview" />}>
        <Route path="overview" element={<FindingsOverview />} />
      </Route>
      <Route element={<FilterRouter path="/findings/tickets" />}>
        <Route path="tickets" element={<Tickets />} />
      </Route>
      <Route element={<FilterRouter path="/findings" />}>
        <Route index element={<FindingsOverview />} />
      </Route>
    </Routes>
  );
};
