import { createContext, useContext } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { IQueryState, QueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

export interface ActiveResourceContextProps {
  resourceType: NativeResources;
  resourceUuid: string;
  resourceId: string;

  resourceDetails: IQueryState<Dict<any>>;
  resourceInsight: IQueryState<Dict<any>>;

  isOpenMetadata: boolean;
  tabIndex: number;
  onOpenMetadata: (tabIndex?: number) => void;
  onCloseMetadata: () => void;
}

export const ActiveResourceContext = createContext<ActiveResourceContextProps>({
  resourceType: '' as NativeResources,
  resourceUuid: '',
  resourceId: '',

  resourceDetails: QueryState.init({}),
  resourceInsight: QueryState.init({}),

  isOpenMetadata: false,
  tabIndex: 0,
  onOpenMetadata: () => {},
  onCloseMetadata: () => {},
});

export const useActiveResourceContext = () => useContext(ActiveResourceContext);
