import React, { useEffect, useState } from 'react';

import { EnvironmentRead } from '@ariksa/compliance-policies/api';
import { Box, Stack, Flex, Text, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmationInput,
  DetailsIdentifier,
  H4,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, DeleteButton } from 'components/DataEntry';
import { Modal } from 'components/Overlay';
import { actions as appActions } from 'containers/App/slice';
import { selectEnvironment } from 'containers/Setup/Environment/selectors';

import { actions } from '../slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  environment: EnvironmentRead;
}

export const AttachedBlueprints: React.FC<Props> = props => {
  const { isOpen, onClose, environment } = props;
  const dispatch = useDispatch();
  const [command, setCommand] = useState('');
  const { attachedBlueprints, apiCallStatus } = useSelector(selectEnvironment);
  const { isLoading, data } = attachedBlueprints;

  useEffect(() => {
    dispatch(
      actions.getAttachedBlueprints({ q: { environmentId: environment.id } }),
    );
  }, [dispatch, environment]);

  const onClickDelete = () => {
    dispatch(
      actions.deleteEnvironment({
        q: { environmentId: environment?.id },
        onSuccess: () => {
          dispatch(actions.getEnvironments({}));
          dispatch(appActions.loadEnvironments({}));
        },
      }),
    );
  };

  const columns = [
    {
      header: 'name',
      accessor: 'name',
      styles: { width: '200px', cell: { maxWidth: '200px' } },
    },
    { header: 'description', accessor: 'description', align: 'left' },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={`Delete Environment`}
      styles={{
        modal: { size: '3xl' },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton
            onClick={onClickDelete}
            isDisabled={
              !!data.length || !command || command !== environment?.name
            }
            isLoading={apiCallStatus.isLoading}
          />
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <DetailsIdentifier
            label="Environment Name"
            value={environment.name}
          />
          {!!data.length && !isLoading && (
            <Stack w="full" h="200px">
              <H4>Attached Blueprints</H4>
              <Table columns={columns} data={data} isLoading={isLoading} />
            </Stack>
          )}
          {!!data.length && !isLoading && (
            <Box>
              Please detach above mentioned blueprints before deleting the
              environment.
            </Box>
          )}
          {!data.length && !isLoading && (
            <ConfirmationInput
              command={command}
              setCommand={setCommand}
              label={
                <Text>Please type in the environment name and delete.</Text>
              }
              confirmationText={environment?.name}
            />
          )}
          {isLoading && (
            <Center pt={12}>
              <CustomSpinner loading={isLoading} />
            </Center>
          )}
        </Stack>
      }
    />
  );
};
