/**
 *
 * Asynchronously loads the component for Visibility
 *
 */

import { lazy } from 'react';

export const Visibility = lazy(() =>
  import('./index').then(m => ({ default: m.Visibility })),
);
