import React from 'react';

import { Grid, GridItem, Stack } from '@chakra-ui/react';

import { formatTooltip } from 'components/DataDisplay';
import { CloudIcon } from 'components/Icons';
import { ComputeEntities } from 'containers/Dashboard/CloudPostureOverview/Components/ComputeEntities';
import { CloudCompliance } from 'containers/Dashboard/Overview/Components/CloudCompliance';
import { CriticalAndHighSeverityFindingsByCategory } from 'containers/Dashboard/Overview/Components/CriticalAndHighSeverityFindingsByCategory';
import { DataSourcesSummaryCard } from 'containers/Dashboard/Overview/Components/DataSourcesSummaryCard';
import { Entities } from 'containers/Dashboard/Overview/Components/Entities';
import { FindingsToPrioritizeAcrossAllCategories } from 'containers/Dashboard/Overview/Components/FindingsToPrioritizeAcrossAllCategories';
import { GovernanceScore } from 'containers/Dashboard/Overview/Components/GovernanceScore';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { Infrastructure } from 'containers/Dashboard/Overview/Components/Infrastructure';
import { OpenAlertsAndResolutions } from 'containers/Dashboard/Overview/Components/OpenAlertsAndResolutions';
import { PolicyManagementAndAutomation } from 'containers/Dashboard/Overview/Components/PolicyManagementAndAutomation';
import { Summary } from 'containers/Dashboard/Overview/Components/Summary';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

interface Props {
  isDataClassificationEnabled: boolean;
  isNetworkLogEnabled: number;
  isActivityLogEnabled: boolean;
}

export const StateOfYourCloud: React.FC<Props> = props => {
  return (
    <DashboardOverviewWrapperCard
      label="STATE OF YOUR CLOUD"
      height="1050px"
      content={
        <Stack w="full" h="full" spacing={OverviewCardSpacing}>
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <Summary />
                <Entities />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <CriticalAndHighSeverityFindingsByCategory />
            </GridItem>
            <GridItem colSpan={2}>
              <FindingsToPrioritizeAcrossAllCategories />
            </GridItem>
          </Grid>
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <ComputeEntities />
                <DataSourcesSummaryCard
                  tooltip={formatTooltip({
                    header: 'Data sources',
                    content: 'Buckets, Disks, Databases and Data warehouses',
                    width: 48,
                  })}
                />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <CloudCompliance />
            </GridItem>
            <GridItem colSpan={2}>
              <GovernanceScore />
            </GridItem>
          </Grid>
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <Identities
                  labelTooltip={formatTooltip({
                    header: 'Identities',
                    content:
                      'Cloud users, federated users & machine identities',
                    width: 48,
                  })}
                />
                <Infrastructure
                  networkLogEnabledVPCs={props.isNetworkLogEnabled}
                />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <PolicyManagementAndAutomation />
            </GridItem>
            <GridItem colSpan={2}>
              <OpenAlertsAndResolutions />
            </GridItem>
          </Grid>
        </Stack>
      }
      icon={<CloudIcon />}
    />
  );
};
