/**
 *
 * Update Okta
 *
 */

import React from 'react';

import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';

import { Form } from 'components/index';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { formStyles } from '../../../styles';

export const UpdateOkta = () => {
  const { updateAccount, currentRecord, onCancel } = useIntegrationsContext();

  const handleReset = () => {
    onCancel();
  };
  const handleSubmit = data => {
    const { name } = data;
    updateAccount(
      {
        uuid: currentRecord?.uuid,
        cloudAccountUpdate: {
          name,
          cloud_type: CloudProviders.Okta,
        },
      },
      AccountType.IdentityProvider,
    );
  };

  return (
    <>
      <Form
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            isRequired: true,
            defaultValue: currentRecord?.name,
          },
        }}
        buttonOptions={{
          submit: { name: 'Update' },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={{ form: { ...formStyles.form } }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
