import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VulnerabilityIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M18.5,13.5H21c0.276123,0,0.5-0.223877,
    0.5-0.5s-0.223877-0.5-0.5-0.5h-2.5V11c-0.0006104-0.5911255-0.1220093-1.1523438-0.3295898-1.6693726C20.1085815,
    8.817688,21.5081177,7.0620117,21.5,5c0-0.276123-0.223877-0.5-0.5-0.5S20.5,4.723877,20.5,5c0.0072021,
    1.6860962-1.1983032,3.104248-2.8276367,
    3.4154053c-0.5499268-0.7792969-1.3435669-1.3616943-2.2671509-1.6679688c0.1531982-0.6799316,
    0.1100464-1.3927002-0.1480103-2.0488892c-0.7075195-1.7993774-2.7397461-2.6844482-4.5391235-1.9769287c-1.6300049,
    0.6409302-2.4962158,2.367981-2.1213989,4.0252075c-0.9243164,0.3060303-1.71875,
    0.8887939-2.269043,1.6685791C4.6983032,8.104248,3.4927979,6.6860962,3.5,5c0-0.276123-0.223877-0.5-0.5-0.5S2.5,
    4.723877,2.5,5C2.4918823,7.0620117,3.8914185,8.817688,5.8295898,9.3306274C5.6220093,
    9.8476562,5.5006104,10.4088745,5.5,11v1.5H3c-0.276123,0-0.5,0.223877-0.5,0.5s0.223877,0.5,0.5,
    0.5h2.5V15c0.0006714,0.5892334,0.0866089,1.1572876,0.2340698,1.6999512C3.848999,17.263855,
    2.5109253,18.987793,2.5,21v0.0005493C2.5001831,21.2765503,2.723999,21.5001831,3,21.5h0.0006104C3.2765503,
    21.4998169,3.5001831,21.276001,3.5,21c0.0078125-1.5834351,1.0736084-2.9364624,
    2.5674438-3.3579102C7.081665,19.9120483,9.3536377,21.4969482,12,21.5h0.0006104c0.000061,
    0,0.0001221-0.000061,0.0001831-0.000061c2.6459351-0.003418,4.9176025-1.5880737,
    5.9317017-3.8577881C19.4263916,18.0635986,20.4921265,19.4165649,20.5,21v0.0005493C20.5001831,
    21.2765503,20.723999,21.5001831,21,21.5h0.0006104C21.2765503,21.4998169,21.5001831,21.276001,
    21.5,21c-0.0109253-2.012207-1.348999-3.736084-3.2341309-4.2999878C18.4133301,16.1572876,18.4993286,
    15.5892334,18.5,15V13.5z M11.5,20.4747925C8.6971436,20.2218628,6.5,17.8687134,6.5,15v-4C6.5023193,
    9.0679932,8.0679932,7.5023193,10,7.5h1.5V20.4747925z M10,6.5c-0.1464844,0-0.2939453,0.0078125-0.4414062,
    0.0224609C9.5199585,6.3510132,9.5003052,6.1757812,9.5,6c0.0012817-1.380188,1.119812-2.4987183,
    2.5-2.5c1.380188,0.0012817,2.4987183,1.119812,2.5,2.5c-0.0001221,0.1758423-0.0201416,
    0.3510742-0.0595703,0.5224609C14.2940063,6.5078125,14.1464844,6.5,14,6.5H10z M17.5,
    15c0,2.8687134-2.1971436,5.2218628-5,5.4747925V7.5H14c1.9320068,0.0023193,3.4976807,
    1.5679932,3.5,3.5V15z"
    />
  </Icon>
);
