import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Assets, Sprite, Text } from 'pixi.js';

import { IconTypes } from 'components/Icons';
import { getBlackMapIcon } from 'components/Icons/MapIcons/getBlackMapIcon';
import { getPurpleMapIcon } from 'components/Icons/MapIcons/getPurpleMapIcon';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import {
  infoTextStyle,
  LIMIT_INNER_NAME_LEN,
  nameTextStyle,
} from 'components/Visualization/PixiGraph/style';

import { limitedString } from '../../../../../utils/string';

export const END_CIRCLE_RADIUS = 5;

export class EdgeElements {
  static renderEndCircle(
    edge: AGraphEdge,
    g: Graphics,
    opts: Record<string, any> = {},
  ) {
    const { color = 0xaaaaaa } = opts;
    const { start, end } = edge;
    g.lineStyle(1, color, 1);
    g.beginFill(0xffffff, 1);
    g.drawCircle(end.x - END_CIRCLE_RADIUS, end.y, END_CIRCLE_RADIUS);
    g.endFill();
  }

  static renderStartCircle(
    edge: AGraphEdge,
    g: Graphics,
    opts: Record<string, any> = {},
  ) {
    const { color = 0xaaaaaa } = opts;
    const { start, end } = edge;
    g.lineStyle(1, color, 1);
    g.beginFill(0xffffff, 1);
    g.drawCircle(
      start.x + 2 * END_CIRCLE_RADIUS - END_CIRCLE_RADIUS,
      start.y,
      END_CIRCLE_RADIUS,
    );
    g.endFill();
  }

  static renderEdgeText(edge: AGraphEdge, g: Graphics) {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const { db_user_name = '' } = properties;

    if (db_user_name) {
      const title = `Login: \n${db_user_name}`;
      const text = new Text(title, infoTextStyle);

      text.x = (start.x + end.x) / 2 - text.width / 2;
      text.y = (start.y + end.y) / 2 - text.height / 2;
      g.addChild(text);
    }
  }

  static renderEdgeIcon(edge: AGraphEdge, g: Graphics) {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const { db_user_name = '' } = properties;

    if (db_user_name) {
      const x = (start.x + end.x) / 2 - 5;
      const y = (start.y + end.y) / 2;
      const iconPath = getPurpleMapIcon(IconTypes.View);
      Assets.loader.load(iconPath).then(texture => {
        if (!texture) return;
        const icon = Sprite.from(texture);

        const scale = 20 / icon.width;
        icon.scale.set(scale, scale);

        icon.x = x - icon.width / 2;
        icon.y = y - icon.height / 2;

        g.addChild(icon);
      });
    }
  }

  static renderEdgeIconBoundary(
    edge: AGraphEdge,
  ): {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  } {
    const { start, end, data } = edge;
    const { properties = {} } = data ?? {};
    const { db_user_name = '' } = properties;

    if (db_user_name) {
      const x = (start.x + end.x) / 2;
      const y = (start.y + end.y) / 2;

      return {
        minX: x - 10,
        maxX: x + 10,
        minY: y - 10,
        maxY: y + 10,
      };
    }

    return {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    };
  }
}
