import React, { FC } from 'react';

import { Box, Center, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { PrimaryButton } from 'components/DataEntry';
import { IPagination } from 'components/Navigation';

export interface IResourceInfoTable {
  icon?: any;
  header?: any;
  columns: TableColumnProps[];
  data: any[];
  isLoading?: boolean;
  pagination?: IPagination;
  loadMore?: boolean;
  totalCount?: number;
  onLoadMore?();
}

export const ResourceInfoTable: FC<IResourceInfoTable> = props => {
  const { columns, data, isLoading, pagination, loadMore, onLoadMore } = props;

  return (
    <Stack w={'full'} h={'full'}>
      <Box flex={1}>
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          styles={{ height: '33vh' }}
          pagination={pagination}
        />
      </Box>
      {!pagination && loadMore && (
        <Center>
          <PrimaryButton onClick={() => onLoadMore?.()}>
            Load More
          </PrimaryButton>
        </Center>
      )}
    </Stack>
  );
};
