/**
 *
 * SMS Integration
 *
 */

import React from 'react';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { CreateSMS } from './CreateSMS';
import { EditSMS } from './EditSMS';
import { SMSTable } from './SMSTable';

export const SMS: React.FC = () => {
  const { isOpen, actionType } = useIntegrationsContext();

  return isOpen ? (
    actionType === 'Add' ? (
      <CreateSMS />
    ) : (
      <EditSMS />
    )
  ) : (
    <SMSTable />
  );
};
