/**
 *
 * AddRepository -> GitLab, Github, BitBucket
 *
 */

import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { AddRepositoryForm } from 'containers/Setup/Integrations/DataSources/Components/AddRepository/AddRepositoryForm';
import { getLabel } from 'containers/Setup/Integrations/hooks/utils';

export const EditRepository = () => {
  const navigate = useNavigate();
  const params = useParams<{ provider: string }>();
  const { accountDetails } = useSelector(selectCloudAccounts);

  return (
    <Stack spacing={6}>
      <Box>
        <PageHeaderWithIcon
          label={'Update ' + getLabel(params.provider)}
          iconType={params.provider}
          reversed
        />
      </Box>
      <AddRepositoryForm
        onCancel={() => navigate('/setup/accounts')}
        selectedProvider={accountDetails.data?.cloud_type}
        actionType="Update"
        accountDetails={accountDetails.data}
      />
    </Stack>
  );
};
