/**
 *
 * Mobile Main Menu
 *
 */
import React, { FC } from 'react';

import { Box, DarkMode, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import map from 'lodash/map';
import { AiOutlineDoubleLeft } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';

import { Drawer } from '../../../../Overlay/Drawer';
import { Logo } from '../../Logo';
import { mobileMenuDrawerStyles as styles } from '../styles';
import { getMainMenuItems } from '../utils';

import { MainMenu } from './MainMenu';

interface Props {}

export const MobileMenu: FC<Props> = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const header = () => (
    <Flex bg="primary" px={4} h={12} color="white" align="center" mb={2}>
      <Logo fontWeight="normal" px={2} />
    </Flex>
  );
  const body = () => (
    <>
      <Box>
        <Stack spacing={0}>
          {map(getMainMenuItems(), each => (
            <Box key={`${each.key}-main-menu`}>
              <MainMenu tab={each} onClose={onClose} />
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
  const label = isOpen ? <AiOutlineDoubleLeft /> : <FiMenu />;
  return (
    <DarkMode>
      <Box pl={4}>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          body={body()}
          header={header}
          buttonLabel={label}
          closeButton
          styles={styles}
        />
      </Box>
    </DarkMode>
  );
};
