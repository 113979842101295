import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RdsInstanceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M462.1,400.6c-3.8-36.1-33.9-64.5-70-64.5c-22.6,0-43.8,11.3-57,29.9c-2.6-0.4-5.2-0.7-7.8-0.7
		c-20.4,0-38.5,13.9-44.6,33.5c-16.8,9.9-27.4,28.5-27.4,48.4c0,30.9,24.5,55.9,54.6,55.9h94.8c0.5,0,1,0,1.5-0.1
		c0.5,0,1.1,0.1,1.7,0.1h37.9c28.4,0,51.5-23.6,51.5-52.7C497.3,427.7,482.8,407.7,462.1,400.6z M445.8,485.6l-38.3,0l-1.5-0.1
		l-96.1,0.1c-20.4,0-37-17.2-37-38.3c0-14.6,8.3-28.2,21.3-34.6l3.8-1.9l0.9-4.2c2.9-13.7,14.9-23.7,28.4-23.7c3,0,6.1,0.5,9,1.5
		l6.8,2.3l3.6-6.3c9.6-16.8,26.6-26.8,45.4-26.8c28.7,0,52.4,24.2,52.8,53.9l0.1,7.2l7,1.4c16,3.1,27.7,17.6,27.7,34.5
		C479.7,469.9,464.5,485.6,445.8,485.6z"
      />
      <path
        d="M234.7,379.3c9.6,0,18.9-0.2,28.1-0.6c12-23,34.8-38.2,60.5-39.6c18.2-18.7,43.1-29.4,68.8-29.4
		c17.1,0,33.4,4.7,47.6,13c7.5,4.8,12.9,8.5,14.9,11.5V329v-11.3v-4.4v-4.4v-62.6c-11.6,8-26.6,14.9-44.2,20.8
		c-37,12.9-88.4,22-152.8,23.8c-7.7,0.2-15.3,0.4-22.9,0.4c-7.6,0-15.3-0.1-23-0.4c-64.6-1.8-116-10.9-153.1-23.9
		c-17.4-5.9-32.3-12.8-43.8-20.7v62.6v4.4v4.4V329C39.8,355.1,123.8,379.3,234.7,379.3z"
      />
      <path
        d="M14.7,131v62.6v4.4v4.4v11.3c23.2,24.2,97.1,46.7,196.1,49.9c1,0,2,0,3.1,0.1c2.9,0.1,5.7,0.2,8.6,0.2
		c4,0.1,8.1,0.1,12.2,0.1c4.1,0,8.1,0,12.2-0.1c2.9,0,5.8-0.1,8.6-0.2c1,0,2.1,0,3.1-0.1c99-3.2,172.8-25.6,196.1-49.9v-11.3v-4.4
		v-4.4V131c-42.8,29.4-133.3,45-219.9,45S57.6,160.4,14.7,131z"
      />
      <path
        d="M234.7,149.6c111.6,0,195-24,219.9-50.1V83.6v-4.4c0-1.5-0.5-2.8-1.2-4C443.5,37.3,367.6,0,234.7,0
		C102,0,26.2,37.1,16,74.9c-0.7,1.3-1.3,2.7-1.3,4.3v4.4v15.9C39.7,125.5,123.1,149.6,234.7,149.6z"
      />
      <path
        d="M228.9,447.3c0-14.5,4-28.6,11-40.9c-1.8,0-3.5,0.1-5.3,0.1c-108.6,0-183.6-20.1-219.9-45.8v72.1
		c0,1.4,0.4,2.8,1,4C26.2,479.9,119.1,512,234.7,512c8.4,0,16.5-0.2,24.6-0.5C240.8,496.3,228.9,473.2,228.9,447.3z"
      />
    </g>
  </Icon>
);
