import React, { FC, useEffect, useReducer, useState } from 'react';

import {
  CheckType,
  NativeResources,
  ResourceTagCondition,
} from '@ariksa/inventory-core/api';
import { Check, Option } from '@ariksa/inventory-core/dist/api';
import { Box, Wrap, Stack, WrapItem, HStack } from '@chakra-ui/react';
import { map, find, findIndex, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import {
  containOptions,
  ec2OsTechOptions,
  ec2WithVersionOrVulnerabilityOptions,
  severityOptions,
  vulnerabilityCompareOptions,
} from 'containers/Inventory/Whitelist/Components/WhitelistWizard/Steps/WhitelistCriteria/ResourceTypeCriteria/utils';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';

import { useWhitelistContext } from '../../../../../context';
import { actions } from '../../../../../slice';

interface Props {
  id: string;
  osOptions: Record<string, any>[];
  technologyOptions: Record<string, any>[];
}

export const ResourceTypeComponent: FC<Props> = props => {
  const { id, osOptions, technologyOptions } = props;
  const { resourceTypeCriteria, ec2Applications } = useSelector(
    selectWhitelist,
  );
  const {
    resourceTypeOptions,
    setIsDisabledCriteriaNext,
    setHasEC2Criteria,
  } = useWhitelistContext();
  const [checkType, setCheckType] = useState<Record<string, any>>({});
  const [selectedResourceType, setSelectedResourceType] = useState<
    Record<string, any>
  >({});
  const [selectedOSTechnology, setSelectedOSTechnology] = useState<
    Record<string, any>
  >({});
  const [compare, setCompare] = useState<Record<string, any>>(
    vulnerabilityCompareOptions[0],
  );
  const [severity, setSeverity] = useState<Record<string, any>>(
    severityOptions[0],
  );

  const [isOSSelected, setIsOSSelected] = useState(false);
  const [isVersionSelected, setIsVersionSelected] = useState(true);
  const [version, setVersion] = useState<Record<string, any>>({});
  const [versionOptions, setVersionOptions] = useState<Record<string, any>[]>(
    [],
  );
  const dispatch = useDispatch();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      currentRecord: {},
      checkType: {},
      resourceType: {},
      os: {},
      technology: {},
      compare: {},
      severity: {},
      isOSSelected: true,
      isVersionSelected: true,
      version: {},
    },
  );

  useEffect(() => {
    updateState({ currentRecord: resourceTypeCriteria[id] });
  }, [id, resourceTypeCriteria]);

  //set initial state
  useEffect(() => {
    const item = state.currentRecord;
    updateState({
      resourceType:
        find(resourceTypeOptions, o => item?.resource_type === o?.value) || {},
      isOSSelected: !!item?.key ? item?.key === Option.Os : true,
      isVersionSelected: !!item?.check ? item?.check === Check.Version : true,
      compare:
        find(vulnerabilityCompareOptions, o => o?.value === item?.comparison) ||
        vulnerabilityCompareOptions[0],
      checkType:
        item?.check_type === CheckType.NotContains
          ? containOptions[1]
          : containOptions[0],
    });
  }, [state.currentRecord, resourceTypeOptions]);

  //set check type
  useEffect(() => {
    const item = resourceTypeCriteria[id];
    setCheckType(
      resourceTypeCriteria[id]?.check_type === CheckType.NotContains
        ? containOptions[1]
        : containOptions[0],
    );

    setIsVersionSelected(item?.check === Check.Version);
    setIsOSSelected(item?.key === Option.Os);

    setCompare(
      find(vulnerabilityCompareOptions, o => o?.value === item?.comparison) ??
        vulnerabilityCompareOptions[0],
    );
  }, [id, resourceTypeCriteria]);

  //set resource type
  useEffect(() => {
    setSelectedResourceType(
      find(
        resourceTypeOptions,
        o => resourceTypeCriteria[id]?.resource_type === o?.value,
      ) || resourceTypeOptions[0],
    );
  }, [id, resourceTypeCriteria, resourceTypeOptions]);

  //check if ec2 criteria is present
  useEffect(() => {
    setHasEC2Criteria(
      selectedResourceType?.value === NativeResources.Ec2Instance,
    );
  }, [selectedResourceType, setHasEC2Criteria]);

  //set selected os or technology
  useEffect(() => {
    const options = isOSSelected ? osOptions : technologyOptions;
    setSelectedOSTechnology(
      find(options, o => state.currentRecord?.value_1 === o?.value) ||
        options[0],
    );
  }, [state.currentRecord, osOptions, technologyOptions, isOSSelected]);

  useEffect(() => {
    if (isVersionSelected) {
      setVersionOptions(
        map(selectedOSTechnology?.data, o => ({
          label: (
            <HStack spacing={1}>
              <Box>{o.version}</Box>
              {o.eol && <Box fontSize={10}>(E.O.L. {o.eol})</Box>}
            </HStack>
          ),
          value: o.version,
        })),
      );
    }
  }, [isVersionSelected, selectedOSTechnology]);

  //set severity
  useEffect(() => {
    if (isVersionSelected) setSeverity({});
    else {
      const item = resourceTypeCriteria[id];
      const severityOption = findIndex(
        severityOptions,
        o => o?.value === item?.value_2,
      );
      const _severity = severityOptions?.[severityOption] ?? severityOptions[0];
      setSeverity(_severity);
    }
  }, [resourceTypeCriteria, id, isVersionSelected]);

  //set version
  useEffect(() => {
    if (isVersionSelected) {
      const item = resourceTypeCriteria[id];
      const index = findIndex(versionOptions, o => o?.value === item?.value_2);
      const _version = versionOptions?.[index] ?? versionOptions[0];
      setVersion(_version);
    } else {
      setVersion({});
    }
  }, [resourceTypeCriteria, id, isVersionSelected, versionOptions]);

  //disable next button if any field is empty
  useEffect(() => {
    setIsDisabledCriteriaNext(
      isEmpty(selectedOSTechnology || (isVersionSelected && !version)),
    );
  }, [
    selectedOSTechnology,
    setIsDisabledCriteriaNext,
    isVersionSelected,
    version,
  ]);

  const updateCriteria = (field, value) => {
    const payload =
      selectedResourceType?.value === NativeResources.Ec2Instance ||
      (field === 'resource_type' && value === NativeResources.Ec2Instance)
        ? {
            check_type: checkType?.value,
            key: isOSSelected ? Option.Os : Option.Technology,
            value_1: selectedOSTechnology?.value,
            check: isVersionSelected ? Check.Version : Check.Vulnerability,
            comparison: compare?.value,
            value_2: isVersionSelected ? version?.value : severity?.value,
          }
        : {
            check_type: undefined,
            key: undefined,
            value_1: undefined,
            check: undefined,
            comparison: undefined,
            value_2: undefined,
          };
    dispatch(
      actions.updateResourceTypeCriteria({
        id,
        value: {
          resource_type:
            field === 'resource_type' ? value : selectedResourceType?.value,
          key_condition:
            field === 'key_condition'
              ? value
              : ResourceTagCondition.HasResourceType,
          ...payload,
        },
      }),
    );
  };

  const updateCriteriaField = (field, value) => {
    dispatch(actions.updateResourceTypeCriteriaField({ id, field, value }));
  };

  return (
    <Stack w="full">
      <Wrap>
        <WrapItem>
          <Box whiteSpace="nowrap" pt={1}>
            If an entity has resource type
          </Box>
        </WrapItem>
        <WrapItem>
          <Box w={64}>
            <Select
              options={resourceTypeOptions}
              value={selectedResourceType}
              onChange={selected => {
                //setSelectedResourceType(selected);
                updateCriteria('resource_type', selected?.value);
              }}
            />
          </Box>
        </WrapItem>
        {selectedResourceType.value === NativeResources.Ec2Instance && (
          <>
            <WrapItem>
              <Box w="170px">
                <Select
                  options={containOptions}
                  defaultValue={containOptions[0]}
                  value={checkType}
                  onChange={selected => {
                    //setCheckType(selected);
                    updateCriteriaField('check_type', selected?.value);
                  }}
                />
              </Box>
            </WrapItem>
            <WrapItem>
              <Box w="180px">
                <Select
                  options={ec2OsTechOptions}
                  defaultValue={ec2OsTechOptions[0]}
                  value={
                    isOSSelected ? ec2OsTechOptions[0] : ec2OsTechOptions[1]
                  }
                  onChange={selected => {
                    if (selected?.value === Option.Os) {
                      updateCriteriaField('value_1', osOptions?.[0]?.value);
                      if (state.isVersionSelected)
                        updateCriteriaField(
                          'value_2',
                          osOptions?.[0]?.data?.[0]?.version,
                        );
                    } else {
                      updateCriteriaField(
                        'value_1',
                        technologyOptions?.[0]?.value,
                      );
                      if (state.isVersionSelected)
                        updateCriteriaField(
                          'value_2',
                          technologyOptions?.[0]?.data?.[0]?.version,
                        );
                    }
                    updateCriteriaField('key', selected?.value);
                  }}
                />
              </Box>
            </WrapItem>
            <WrapItem>
              <Box whiteSpace="nowrap" pt={1}>
                that is
              </Box>
            </WrapItem>
            <WrapItem>
              <Box w="175px">
                <Select
                  options={isOSSelected ? osOptions : technologyOptions}
                  value={selectedOSTechnology}
                  onChange={selected => {
                    updateCriteriaField('value_1', selected?.value);
                  }}
                  isLoading={ec2Applications.isLoading}
                  isDisabled={ec2Applications.isLoading}
                />
              </Box>
            </WrapItem>
            <WrapItem>
              <Box w={48}>
                <Select
                  options={ec2WithVersionOrVulnerabilityOptions}
                  value={
                    isVersionSelected
                      ? ec2WithVersionOrVulnerabilityOptions[0]
                      : ec2WithVersionOrVulnerabilityOptions[1]
                  }
                  onChange={selected => {
                    if (selected?.value === Check.Version)
                      updateCriteriaField(
                        'value_2',
                        selectedOSTechnology?.data?.[0]?.version,
                      );
                    else
                      updateCriteriaField('value_2', severityOptions[0]?.value);
                    updateCriteriaField('check', selected?.value);
                  }}
                />
              </Box>
            </WrapItem>
            <WrapItem>
              <HStack>
                {isVersionSelected && (
                  <>
                    <Box w="140px">
                      <Select
                        options={vulnerabilityCompareOptions}
                        value={compare}
                        onChange={selected => {
                          //setCompare(selected);
                          updateCriteriaField('comparison', selected?.value);
                        }}
                      />
                    </Box>
                    <Box w="230px">
                      <Select
                        options={versionOptions}
                        value={version}
                        onChange={selected => {
                          //setCompare(selected);
                          updateCriteriaField('value_2', selected?.value);
                        }}
                        isLoading={ec2Applications.isLoading}
                        isDisabled={ec2Applications.isLoading}
                      />
                    </Box>
                  </>
                )}
                {!isVersionSelected && (
                  <>
                    <Box whiteSpace="nowrap" pt={1}>
                      that has severity
                    </Box>
                    <Box w="150px">
                      <Select
                        options={vulnerabilityCompareOptions}
                        value={compare}
                        onChange={selected => {
                          //setCompare(selected);
                          updateCriteriaField('comparison', selected?.value);
                        }}
                      />
                    </Box>
                    <Box w={28}>
                      <Select
                        options={severityOptions}
                        value={severity}
                        onChange={selected => {
                          //setSeverity(selected);
                          updateCriteriaField('value_2', selected?.value);
                        }}
                      />
                    </Box>
                  </>
                )}
              </HStack>
            </WrapItem>
          </>
        )}
      </Wrap>
    </Stack>
  );
};
