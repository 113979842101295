import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OrganizationUnitIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M484.8,201.1H296.1c-16.8,0-19.3,17.6-19.3,19.3v286.9h224.8V224.6C503.3,206.1,489.9,201.1,484.8,201.1z
		 M372.4,457.7c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6
		c9.2,0,17.6,7.5,17.6,17.6V457.7z M372.4,369.7c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6
		c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V369.7z M372.4,281.6c0,9.2-7.5,17.6-17.6,17.6h-17.6
		c-9.2,0-17.6-7.5-17.6-17.6V264c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V281.6z M458.8,457.7
		c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V457.7z
		 M458.8,369.7c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6
		c9.2,0,17.6,7.5,17.6,17.6V369.7z M458.8,281.6c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6V264
		c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V281.6z"
      />
      <path
        d="M310.4,6.5H19.3C2.5,6.5,0,24.1,0,25.8v480.6h224.8V169.2c0,0,0-21,19.3-21h63.7c0,0,1.7,0,3.4,0
		c7.5-0.8,16.8-4.2,16.8-17.6V30C328.8,11.5,316.2,6.5,310.4,6.5z M95.6,450.2c0,9.2-7.5,17.6-17.6,17.6H60.4
		c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6H78c9.2,0,17.6,7.5,17.6,17.6V450.2z M95.6,361.3
		c0,9.2-7.5,17.6-17.6,17.6H60.4c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6H78c9.2,0,17.6,7.5,17.6,17.6V361.3z
		 M95.6,272.4c0,9.2-7.5,17.6-17.6,17.6H60.4c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6H78
		c9.2,0,17.6,7.5,17.6,17.6V272.4z M95.6,183.5c0,9.2-7.5,17.6-17.6,17.6H60.4c-9.2,0-17.6-7.5-17.6-17.6v-17.6
		c0-9.2,7.5-17.6,17.6-17.6H78c9.2,0,17.6,7.5,17.6,17.6V183.5z M95.6,95.4c0,9.2-7.5,17.6-17.6,17.6H60.4
		c-9.2,0-17.6-7.5-17.6-17.6V77.8c0-9.2,7.5-17.6,17.6-17.6H78c9.2,0,17.6,7.5,17.6,17.6V95.4z M190.4,450.2
		c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V450.2z
		 M190.4,361.3c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6
		c9.2,0,17.6,7.5,17.6,17.6V361.3z M190.4,272.4c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6v-17.6
		c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V272.4z M190.4,183.5c0,9.2-7.5,17.6-17.6,17.6h-17.6
		c-9.2,0-17.6-7.5-17.6-17.6v-17.6c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V183.5z M190.4,95.4
		c0,9.2-7.5,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6V77.8c0-9.2,7.5-17.6,17.6-17.6h17.6c9.2,0,17.6,7.5,17.6,17.6V95.4z
		 M286,95.4c0,9.2-8.4,17.6-17.6,17.6h-17.6c-9.2,0-17.6-7.5-17.6-17.6V77.8c0-9.2,7.5-17.6,17.6-17.6h17.6
		c9.2,0,17.6,7.5,17.6,17.6V95.4z"
      />
      <path
        d="M507.5,101.3c-5-4.2-13.4-3.4-17.6,1.7l-6.7,8.4c-5.9-51.2-43.6-72.1-72.1-78.8l6.7-4.2
		c5.9-3.4,7.5-11.7,4.2-17.6s-11.7-7.5-17.6-4.2l-33.6,21.8c-3.4,1.7-5,5-5.9,8.4c-0.8,3.4,0,6.7,2.5,10.1L395.1,82
		c2.5,3.4,5.9,5,10.1,5c2.5,0,5.9-0.8,7.5-2.5c5.9-4.2,6.7-12.6,2.5-17.6l-6.7-9.2c17.6,4.2,45.3,18.5,49.5,57l-5.9-6.7
		c-5-5-12.6-5-17.6,0c-5,5-5,12.6,0,17.6l27.7,28.5c2.5,2.5,5.9,3.4,9.2,3.4c0,0,0,0,0.8,0c3.4,0,6.7-1.7,9.2-4.2L510,118
		C513.3,113.8,512.5,105.5,507.5,101.3z"
      />
    </g>
  </Icon>
);
