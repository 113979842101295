import React, { useEffect, useState } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { each, isEmpty, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { VirtualMachineIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const NonUsers: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { nonUsersChange } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();
  const [nonUsers, setNonUsers] = useState({});

  useEffect(() => {
    let items: Record<string, any> = {};
    each(inventorySummary.data?.items?.['Non-users'], o =>
      each(o, (v, k) => (items[k] = v)),
    );
    setNonUsers(items);
  }, [inventorySummary.data]);

  return (
    <SummaryCard
      label="Non-users"
      icon={<VirtualMachineIcon color="blue.300" />}
      cloudProviders={!isEmpty(nonUsers) ? Object.keys(nonUsers) : []}
      change={{
        isLoading: nonUsersChange.isLoading,
        first: nonUsersChange.data?.previous_count,
        last: nonUsersChange.data?.current_count,
        percentChange: nonUsersChange.data?.percentage_change,
      }}
      labelTooltip={formatTooltip({
        header: 'Non-users',
        content:
          'Non—user identities such as VMs, Containers, Container Services, Serverless etc.',
        width: 48,
      })}
      count={!isEmpty(nonUsers) ? sum(Object.values(nonUsers)) : 0}
      //isLoading={inventorySummary.isLoading}
      /*onClick={() =>
        navigate(
          '/inventory/summary?tab=' +
            Categories.Identity +
            '_' +
            Resources.User,
        )
      }*/
    />
  );
};
