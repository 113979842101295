import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { PolicyDocument } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/PolicyDocument';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CreationToken,
  DataInInfrequentAccess,
  DataInStandard,
  EFSSize,
  Encrypted,
  EncryptionKey,
  MountTargets,
  PerformanceMode,
  ThroughputMode,
} from '../../../Components/MetadataField/MetaGridFields';

interface IEFSMetadataAndInsights {}

export const EFSMetadata: FC<IEFSMetadataAndInsights> = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {Encrypted(resource)}
      {EncryptionKey(resource)}
      {EFSSize(resource)}
      {DataInInfrequentAccess(resource)}
      {DataInStandard(resource)}
      {PerformanceMode(resource)}
      {ThroughputMode(resource)}
      {MountTargets(resource)}
      {CreationToken(resource)}
      <PolicyDocument
        label="Policy"
        value={resource?.config?.Policy}
        isString
      />
    </Grid>
  );
};
