import React from 'react';

import { Icon } from '@chakra-ui/react';

export const TriangleDownIcon = props => (
  <Icon viewBox="0 0 16 16" color="inherit" h="full" w="full" {...props}>
    <rect width="16" height="16" id="icon-bound" fill="none" />
    <polygon points="8,13 0,5 16,5" />
  </Icon>
);
