import React, { useState } from 'react';

import {
  CloudAccountGetResponse,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Optional } from 'types/utils';

import {
  EditIcon,
  errorToast,
  RediscoverIcon,
  successToast,
  TableActionsMenu,
  TrashIcon,
  AccountIcon,
  RemediateIcon,
} from 'app/components';
import { StopwatchIcon } from 'components/Icons/ReactCustomIcons/StopwatchIcon';
import { SyncPauseIcon } from 'components/Icons/ReactCustomIcons/SyncPauseIcon';
import { SyncRestartIcon } from 'components/Icons/ReactCustomIcons/SyncRestartIcon';
import { PauseDiscovery } from 'containers/Setup/CloudAccounts/Components/AccountRediscovery/PauseRediscovery';
import { AssetSummary } from 'containers/Setup/CloudAccounts/Components/AssetSummary';
import { ChangeAccountDiscoveryModal } from 'containers/Setup/CloudAccounts/Components/ChangeAccountDiscoveryModal';
import { DeleteCloudAccountModal } from 'containers/Setup/CloudAccounts/Components/DeleteCloudAccountModal';
import { GovernanceChecklist } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist';
import { EnableRemediation } from 'containers/Setup/CloudAccounts/Components/Remediation/EnableRemediation';
import { isSaaSAccount } from 'containers/Setup/CloudAccounts/utils';

import { actions } from '../slice';

import { RediscoverConfirmModal } from './RediscoverConfirmModal';
import { SnapshotTable } from './SnapshotTable';

interface Props {
  row: CloudAccountGetResponse;
}

export const CloudAccountCard = (props: Props) => {
  const { row } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenSnapshots, setIsOpenSnapshots] = useState(false);
  const [isOpenAssetDetails, setIsOpenAssetDetails] = useState(false);
  const [activeAccount, setActiveAccount] = useState<
    Optional<CloudAccountGetResponse>
  >(null);
  const discovery = useDisclosure();
  const changeDiscovery = useDisclosure();
  const remediation = useDisclosure();

  const [
    showRediscoveryConfirmation,
    setShowRediscoveryConfirmation,
  ] = useState(false);
  const [
    showRediscoveryAllConfirmation,
    setShowRediscoveryAllConfirmation,
  ] = useState(false);
  const [showGovernanceChecklist, setShowGovernanceChecklist] = useState(false);

  const isAccountOnboarded = account => account?.status?.code === 'SUCCESS';
  const isManagementAccount = row?.aws?.account_type === 'Management';
  const isAccountActive = account => activeAccount?.uuid === account.uuid;

  const onConfirmRediscover = () => {
    dispatch(
      actions.rediscoverInventory({
        q: { uuid: row.uuid },
        onSuccess: () =>
          successToast({
            title: 'Inventory rediscovery is in progress.',
          }),
        onError: error =>
          errorToast({
            title: 'Rediscover inventory failed.',
            description: error.description,
          }),
      }),
    );
    setActiveAccount(null);
    setShowRediscoveryConfirmation(false);
  };

  const onConfirmRediscoverAll = () => {
    dispatch(
      actions.rediscoverInventory({
        q: {
          uuid: row.uuid,
          discoverAll: true,
        },
      }),
    );
    setActiveAccount(null);
    setShowRediscoveryAllConfirmation(false);
  };

  const onConfirmRediscoveryClose = () => {
    setActiveAccount(null);
    setShowRediscoveryConfirmation(false);
  };

  const onConfirmRediscoveryAllClose = () => {
    setActiveAccount(null);
    setShowRediscoveryAllConfirmation(false);
  };

  const handleRefreshMemberAccount = () => {
    dispatch(actions.refreshMemberAccounts({ uuid: row?.uuid }));
  };

  const menuItems = [
    /* ...(isSaaSAccount(row)
      ? []
      : [
          {
            label: 'Deploy Outpost for Vulnerability',
            icon: <RedshiftIcon />,
            onClick: () => {
              navigate(
                `/setup/outpost/add/vulnerability?account_id=${row.uuid}`,
              );
            },
            isDisabled: !isAccountOnboarded(row),
          },
          {
            label: 'Deploy Outpost for Data',
            icon: <DataScanIcon />,
            onClick: () => {
              navigate(`/setup/outpost/add/data?account_id=${row.uuid}`);
            },
            isDisabled: !isAccountOnboarded(row),
          },
        ]),*/
    ...(isManagementAccount
      ? [
          {
            isDisabled: !isAccountOnboarded(row),
            label: <Box>Refresh Member Accounts</Box>,
            onClick: () => handleRefreshMemberAccount(),
            icon: <RediscoverIcon />,
          },
        ]
      : []),
    ...(isManagementAccount
      ? [
          {
            isDisabled: !isAccountOnboarded(row),
            label: (
              <Box>
                Rediscover
                {isAccountActive(row) && (
                  <RediscoverConfirmModal
                    action="Rediscover"
                    isOpen={showRediscoveryAllConfirmation}
                    onClose={onConfirmRediscoveryAllClose}
                    onConfirm={onConfirmRediscoverAll}
                    cloudAccount={row}
                    message={
                      'Are you sure you want to rediscover all connected accounts?'
                    }
                  />
                )}
              </Box>
            ),
            onClick: () => {
              setActiveAccount(row);
              setShowRediscoveryAllConfirmation(true);
            },
            icon: <RediscoverIcon />,
          },
        ]
      : [
          {
            isDisabled: !isAccountOnboarded(row),
            label: (
              <Box>
                Rediscover
                {isAccountActive(row) && (
                  <RediscoverConfirmModal
                    action="Rediscover"
                    isOpen={showRediscoveryConfirmation}
                    onClose={onConfirmRediscoveryClose}
                    onConfirm={onConfirmRediscover}
                    cloudAccount={row}
                    message={'Are you sure you want to rediscover?'}
                  />
                )}
              </Box>
            ),
            onClick: () => {
              setActiveAccount(row);
              setShowRediscoveryConfirmation(true);
            },
            icon: <RediscoverIcon />,
          },
        ]),
    {
      label: (
        <Box>
          {row?.is_paused ? 'Resume' : 'Pause'} Discovery{' '}
          {discovery.isOpen && (
            <PauseDiscovery
              isOpen={discovery.isOpen}
              onClose={discovery.onClose}
              row={row}
            />
          )}
        </Box>
      ),
      icon: row?.is_paused ? <SyncRestartIcon /> : <SyncPauseIcon />,
      onClick: () => {
        discovery.onOpen();
      },
      isDisabled: !isAccountOnboarded(row),
    },
    // {
    //   label: 'Inventory History',
    //   icon: <HistoryIcon />,
    //   onClick: () => {
    //     setIsOpenSnapshots(true);
    //   },
    //   isDisabled: !isAccountOnboarded(row?.data),
    // },
    // {
    //   label: 'Asset Summary',
    //   icon: <ResourcesIcon />,
    //   onClick: () => {
    //     setIsOpenAssetDetails(true);
    //   },
    //   isDisabled: !isAccountOnboarded(row?.data),
    // },
    {
      label: 'Edit',
      icon: <EditIcon />,
      onClick: () => {
        let path = `/setup/accounts/edit/${row.cloud_type}/${row.uuid}`;
        if (
          row.cloud_type === CloudProviders.Gitlab ||
          row.cloud_type === CloudProviders.Bitbucket ||
          row.cloud_type === CloudProviders.Github
        )
          path = path + `/iac`;
        dispatch(actions.setIsEdit(true));
        navigate(path);
      },
      isDisabled: !isAccountOnboarded(row),
    },

    /*...(!isSaaSAccount(row.data)
      ? [
          {
            label: 'Edit',
            icon: <EditIcon />,
            onClick: () => {
              dispatch(actions.setIsEdit(true));
              navigate(
                `/setup/accounts/edit/${row.data.cloud_type}/${row.data.uuid}`,
              );
            },
            isDisabled: !isAccountOnboarded(row?.data),
          },
        ]
      : []),*/
    {
      label: (
        <Box>
          Delete
          {isOpen && (
            <DeleteCloudAccountModal
              account={row}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
        </Box>
      ),
      onClick: onOpen,
      icon: <TrashIcon color="red" />,
    },
    ...(isSaaSAccount(row) || row?.cloud_type === CloudProviders.Gcp
      ? []
      : [
          {
            isDisabled: !isAccountOnboarded(row),
            label: 'Enable for Remediation',
            onClick: () => {
              setActiveAccount(row);
              remediation.onOpen();
            },
            icon: <RemediateIcon />,
          },
        ]),
    ...(isSaaSAccount(row)
      ? []
      : [
          {
            isDisabled: !isAccountOnboarded(row),
            label: (
              <Box>
                Assess Account Governance
                {showGovernanceChecklist && (
                  <GovernanceChecklist
                    isOpen={showGovernanceChecklist}
                    onClose={() => setShowGovernanceChecklist(false)}
                    row={row}
                  />
                )}
              </Box>
            ),
            onClick: () => {
              setActiveAccount(row);
              setShowGovernanceChecklist(true);
            },
            icon: <AccountIcon />,
          },
          {
            isDisabled: !isAccountOnboarded(row),
            label: <Box>Change Discovery Schedule</Box>,
            onClick: () => {
              setActiveAccount(row);
              changeDiscovery.onOpen();
            },
            icon: <StopwatchIcon />,
          },
        ]),
  ];

  const renderActions = () => (
    <Box onClick={e => e.stopPropagation()}>
      <TableActionsMenu menuItems={menuItems} />
    </Box>
  );

  return (
    <Box cursor={isManagementAccount ? 'pointer' : ''}>
      <Flex d="flex" justifyContent="center">
        {renderActions()}
      </Flex>

      {isOpenSnapshots && (
        <SnapshotTable
          account_id={row.uuid}
          isOpen={isOpenSnapshots}
          onClose={() => setIsOpenSnapshots(false)}
        />
      )}

      {isOpenAssetDetails && (
        <AssetSummary
          account_id={row.cloud_account_id ?? ''}
          isOpen={isOpenAssetDetails}
          onClose={() => setIsOpenAssetDetails(false)}
        />
      )}
      {changeDiscovery.isOpen && activeAccount && (
        <ChangeAccountDiscoveryModal
          onClose={changeDiscovery.onClose}
          account={activeAccount}
          isOpen={changeDiscovery.isOpen}
        />
      )}
      {remediation.isOpen && activeAccount && (
        <EnableRemediation
          onClose={remediation.onClose}
          account={activeAccount}
          isOpen={remediation.isOpen}
        />
      )}
    </Box>
  );
};
