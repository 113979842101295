import React, { memo } from 'react';

import { Box } from '@chakra-ui/react';
import { Cell, PieChart, Pie } from 'recharts';
import { ResponsiveContainer } from 'recharts';
import { customTheme } from 'theme';

import { defaultStyles } from './styles';
import { GaugeChartProps } from './types';
import { bigTicksData, firstLayerData, tickData } from './utils';

export const GaugeChart = memo((props: GaugeChartProps) => {
  const { chartValue } = props;
  const width = 500;
  const innerRadius = '70%';
  const outerRadius = '78%';
  const secondaryInnerRadius = '64%';
  const secondaryOuterRadius = '69%';
  const bigTickInnerRadius = '62%';
  /*const circleData = [
    { value: chartValue, color: customTheme.colors.babyBlue },
    { value: 100 - chartValue, color: customTheme.colors.gray[100] },
  ];*/

  const arrowData = [
    { value: chartValue },
    { value: 0 },
    { value: 100 - chartValue },
  ];

  const pieProps = {
    dataKey: 'value',
    startAngle: 210,
    endAngle: -30,
  };

  const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
    //eslint-disable-line react/no-multi-comp
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius - width * 0.05) * cos;
    const my = cy + (outerRadius - width * 0.05) * sin;
    const textX = cx;
    const textY = cy + 40;
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={7}
          fill={customTheme.colors.skyBlue['300']}
          stroke="none"
        />
        <path
          d={`M${cx},${cy}L${mx},${my}`}
          strokeWidth="3"
          stroke={customTheme.colors.skyBlue['300']}
          fill="none"
          strokeLinecap="round"
        />
        <circle
          cx={cx}
          cy={cy}
          r={4}
          fill={customTheme.colors.cardBg}
          stroke="none"
        />
        <text x={textX} y={textY - 10} textAnchor="middle" fontSize="14px">
          {chartValue <= 25
            ? 'Low Risk'
            : chartValue <= 75
            ? 'Medium Risk'
            : 'High Risk'}
        </text>
      </g>
    );
  };

  return (
    <Box {...defaultStyles.root}>
      <ResponsiveContainer>
        <PieChart margin={{ top: 20 }}>
          <Pie
            data={firstLayerData}
            fill="#8884d8"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            stroke={customTheme.colors.pieChartBorder}
            {...pieProps}
          >
            {firstLayerData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Pie
            data={tickData()}
            fill="#8884d8"
            paddingAngle={1}
            innerRadius={secondaryInnerRadius}
            outerRadius={secondaryOuterRadius}
            stroke={customTheme.colors.gaugeChartBorder}
            {...pieProps}
          >
            {firstLayerData.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Pie>
          <Pie
            data={bigTicksData()}
            fill="#8884d8"
            paddingAngle={22}
            innerRadius={bigTickInnerRadius}
            outerRadius={secondaryOuterRadius}
            stroke={customTheme.colors.gaugeChartBorder}
            {...pieProps}
          >
            {firstLayerData.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Pie>
          {/*<Pie
            data={circleData}
            innerRadius={secondaryInnerRadius}
            outerRadius={secondaryOuterRadius}
            {...pieProps}
          >
            {circleData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>*/}
          <Pie
            stroke="none"
            activeIndex={1}
            activeShape={props => <Arrow {...props} />}
            data={arrowData}
            outerRadius={innerRadius}
            fill="none"
            {...pieProps}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
});
