import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PolicyExceptionIcon = props => (
  <Icon viewBox="0 0 619 721" h="full" w="full" color="inherit" {...props}>
    <g transform="translate(-677 -12)">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M449.628 408.001 508.597 408.001C516.738 408.001 523.34 401.397 523.34 393.253 523.34 385.108 516.738 378.505 508.597 378.505L449.628 378.505C441.487 378.505 434.886 385.108 434.886 393.253 434.886 401.397 441.487 408.001 449.628 408.001ZM787.555 387.574C786.806 385.784 785.726 384.169 784.374 382.816L607.482 205.857C606.133 204.503 604.517 203.423 602.727 202.675 600.944 201.929 599.021 201.528 597.051 201.528L405.4 201.528C356.571 201.582 317.001 241.166 316.947 290.016L316.947 702.963C317.001 751.811 356.571 791.399 405.4 791.451L700.249 791.451C749.078 791.399 788.649 751.811 788.702 702.963L788.702 393.253C788.702 391.283 788.301 389.359 787.555 387.574ZM611.794 251.876 738.371 378.505 651.097 378.505C629.395 378.487 611.812 360.894 611.794 339.185L611.794 251.876ZM759.217 702.963C759.176 735.527 732.8 761.914 700.249 761.955L405.4 761.955C372.849 761.914 346.473 735.527 346.432 702.963L346.432 290.016C346.473 257.452 372.849 231.065 405.4 231.024L582.309 231.024 582.309 339.185C582.356 377.17 613.128 407.952 651.097 408.001L759.217 408.001 759.217 702.963ZM434.886 511.238C434.886 519.382 441.487 525.986 449.628 525.986L656.021 525.986C664.162 525.986 670.762 519.382 670.762 511.238 670.762 503.093 664.162 496.489 656.021 496.489L449.628 496.489C441.487 496.489 434.886 503.093 434.886 511.238ZM656.021 614.474 449.628 614.474C441.487 614.474 434.886 621.079 434.886 629.221 434.886 637.366 441.487 643.971 449.628 643.971L656.021 643.971C664.162 643.971 670.762 637.366 670.762 629.221 670.762 621.079 664.162 614.474 656.021 614.474Z"
                fill="currentColor"
                fillRule="evenodd"
                fillOpacity="1"
                transform="matrix(1.00232 0 0 1 360.318 -188.527)"
              />
              <path
                d="M492.824 682.901C492.824 571.347 583.256 480.916 694.809 480.916 806.362 480.916 896.794 571.347 896.794 682.901 896.794 794.454 806.362 884.885 694.809 884.885 583.256 884.885 492.824 794.454 492.824 682.901Z"
                fill="#FFFFFF"
                fillRule="evenodd"
                fillOpacity="1"
                transform="matrix(1.00232 0 0 1 360.318 -188.527)"
              />
              <path
                d="M237.178 49.4275C133.5 49.4248 49.4503 133.47 49.4472 237.148 49.4443 340.827 133.489 424.876 237.168 424.879 340.846 424.882 424.895 340.836 424.898 237.158 424.898 237.151 424.898 237.145 424.898 237.138 424.936 133.507 340.958 49.4656 237.326 49.4275 237.277 49.4275 237.227 49.4275 237.178 49.4275ZM321.056 297.4 297.425 321.031 237.178 260.775 176.951 321.022 153.319 297.39 213.527 237.138 153.294 176.891 176.951 153.26 237.178 213.527 297.425 153.26 321.056 176.891 260.809 237.138Z"
                stroke="#F05050"
                strokeWidth="4.94275"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="#F05050"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1.00425 0 0 1 818.478 257.58)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
