import React, { FC } from 'react';

import { Box, Text, UseDisclosureProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { DeleteConfirmationModal } from 'components/Overlay';
import { ResourceExceptions } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/Policies/ResourceExceptions';

import { selectBlueprints } from '../../../../selectors';
import { actions } from '../../../../slice';

interface Props {
  columns: Record<string, any>[];
  currentRecord: Record<string, any>;
  excludePolicy: UseDisclosureProps;
  resourceExceptions: UseDisclosureProps;
  getBlueprintPolicies();
}

export const PoliciesTable: FC<Props> = props => {
  const { columns, currentRecord, excludePolicy, getBlueprintPolicies } = props;
  const dispatch = useDispatch();
  const { blueprintPolicies, blueprint } = useSelector(selectBlueprints);

  useDeepCompareEffect(() => {
    getBlueprintPolicies();
  }, [getBlueprintPolicies]);

  const handleExcludeRule = () => {
    if (blueprint.data.id)
      dispatch(
        actions.excludeRules({
          q: {
            blueprintId: blueprint.data?.id!,
            ruleInstanceMultiCreate: {
              rules: [{ rule_id: currentRecord.id }],
            },
          },
          onSuccess: () => {
            excludePolicy.onClose?.();
            getBlueprintPolicies();
          },
        }),
      );
  };

  return (
    <Box h="full" flex={1}>
      <Table
        data={blueprintPolicies.data}
        columns={columns}
        isLoading={blueprintPolicies.isLoading}
        cursor="pointer"
        pagination={{
          totalCount: blueprintPolicies.page.totalCount,
          pageInfo: blueprintPolicies.page.info,
          onChange: pageInfo => {
            dispatch(actions.updatePoliciesPagination(pageInfo));
          },
        }}
        //expanded={resourceExceptions.isOpen}
        styles={{ header: { zIndex: 800 } }}
        subComponent={({ row, expanded }) =>
          expanded && <ResourceExceptions row={row} isExpanded={expanded} />
        }
      />
      {excludePolicy.isOpen && (
        <DeleteConfirmationModal
          confirmationText={
            <Text>
              Please type <Text as="b">CONFIRM</Text> to exclude policy.
            </Text>
          }
          name="CONFIRM"
          header="Exclude rule confirmation"
          label="Exclude"
          onConfirm={handleExcludeRule}
          isOpen={excludePolicy.isOpen}
          onClose={excludePolicy.onClose!}
        />
      )}
    </Box>
  );
};
