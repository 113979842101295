import React, { FC } from 'react';

import { MetadataAttributeDatatypeEnum } from '@ariksa/inventory-core/api';
import { Grid, Stack } from '@chakra-ui/react';
import { isArray, map, orderBy } from 'lodash';

import { MetaField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const GcpMetadata: FC = () => {
  const { resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceInsight;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {map(orderBy(resource.metadata_attributes, ['order'], ['asc']), o =>
          o.datatype === MetadataAttributeDatatypeEnum.Tags
            ? Tags({
                display_name: o.display_name,
                tags: map(o?.attribute_value, t => {
                  const [entries] = Object.entries(t);
                  return { key: entries?.[0], value: entries?.[1] };
                }),
              })
            : MetaField(
                o.display_name,
                o.datatype === MetadataAttributeDatatypeEnum.List ||
                  isArray(o.attribute_value)
                  ? !!o.hyperlink
                    ? map(o.attribute_value, a => ({
                        name: a?.value,
                        id: a?.redirect_resource_uuid,
                        accountId: a?.redirect_resource_account,
                      }))
                    : map(o.attribute_value, a => a?.value ?? a)
                  : o.attribute_value,
                {
                  ...(o.datatype === MetadataAttributeDatatypeEnum.Datetime
                    ? { time: 'dayjs' }
                    : {}),
                  ...(!!o?.hyperlink
                    ? {
                        linkResourceType: o?.redirect_resource_type,
                        linkResourceIDType: 'uuid',
                      }
                    : {}),
                },
              ),
        )}
      </Grid>
    </Stack>
  );
};
