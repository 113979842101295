import { AriksaBotApiChatRequest, ChatResponse } from '@ariksa/ai-engine/api';
import {
  CloudInsight,
  CloudInsightApiGetCloudInsightsRequest,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { AIEngineService, InventoryService } from 'api/services';
import { doGetEOLSoftware } from 'containers/Dashboard/VulnerabilityOverview/saga';

import { actions } from './slice';

export function* conversationalAISaga() {
  yield takeLatestAction(actions.getCloudInsight, doGetCloudInsights);
  yield takeLatestAction(actions.getEOLSoftware, doGetEOLSoftware);
  yield takeLatestAction(actions.askAI, doAskAI);
}

export function* doAskAI(
  ctx: QueryContext<ChatResponse, AriksaBotApiChatRequest>,
) {
  yield call(ctx.fetch, () => AIEngineService.AriksaBot.chat(ctx.params));
}

export function* doGetCloudInsights(
  ctx: QueryContext<CloudInsight[], CloudInsightApiGetCloudInsightsRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.CloudInsight.getCloudInsights(ctx.params),
    { errorMsg: 'Failed to get cloud insights!' },
  );
}
