import React from 'react';

import { Icon } from '@chakra-ui/react';

export const IdeaGlitterIcon = props => (
  <Icon viewBox="0 0 512 512" color="inherit" h="full" w="full" {...props}>
    <g>
      <path
        className="st0"
        d="M494.696,155.436l-75.664-19.918c-24.946-6.56-44.421-26.036-50.982-50.963L348.142,8.892
		C346.769,3.647,342.032,0,336.618,0c-5.424,0-10.161,3.647-11.542,8.892l-19.908,75.664c-6.56,24.927-26.036,44.402-50.982,50.963
		l-75.664,19.918c-5.245,1.381-8.892,6.109-8.892,11.533c0,5.424,3.647,10.151,8.892,11.534l75.664,19.917
		c24.946,6.552,44.422,26.036,50.982,50.963l19.908,75.665c1.381,5.245,6.119,8.892,11.542,8.892c5.414,0,10.151-3.647,11.524-8.892
		l19.907-75.665c6.561-24.927,26.036-44.411,50.982-50.963l75.664-19.917c5.244-1.382,8.892-6.11,8.892-11.534
		C503.587,161.546,499.94,156.818,494.696,155.436z"
      />
      <path
        className="st0"
        d="M207.001,402.949l-46.226-12.163c-15.236-4.004-27.136-15.904-31.14-31.13l-12.172-46.226
		c-0.837-3.215-3.723-5.432-7.041-5.432c-3.308,0-6.203,2.218-7.04,5.432l-12.163,46.226c-4.014,15.226-15.913,27.126-31.149,31.13
		l-46.226,12.163c-3.196,0.846-5.432,3.741-5.432,7.049c0,3.309,2.237,6.204,5.432,7.049l46.226,12.163
		c15.236,4.004,27.136,15.904,31.149,31.131l12.163,46.226c0.836,3.215,3.732,5.433,7.04,5.433c3.318,0,6.204-2.218,7.041-5.433
		l12.172-46.226c4.004-15.227,15.904-27.127,31.14-31.131l46.226-12.163c3.196-0.845,5.432-3.74,5.432-7.049
		C212.434,406.69,210.197,403.795,207.001,402.949z"
      />
    </g>
  </Icon>
);
