import React, { FC, useCallback, useEffect, useReducer } from 'react';

import {
  Badge,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { filter, find, includes, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { customTheme } from 'theme';

import { getResourceTypeOptions } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { FormAction, Select } from 'components/DataEntry';
import { Form } from 'components/DataEntry/Form';
import { MinusIcon, QuestionCircleIcon } from 'components/Icons';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectApp } from 'containers/App/selectors';
import { AddConditionButton } from 'containers/PolicyHub/Policies/AriksaQueryBuilder';
import { selectRemediation } from 'containers/PolicyHub/Remediation/selector';

import { actions } from '../../slice';

interface Props {
  actionType: FormAction;
}

export const RemediationForm: FC<Props> = props => {
  const { actionType } = props;
  const { remediation, remediationAction, commands } = useSelector(
    selectRemediation,
  );
  const { resourceTypes } = useSelector(selectApp);
  const { data } = remediation;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCloudNativeName } = useResourceType();
  const params = useParams<{ id: string }>();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      resourceTypeOptions: [],
      resourceType: {},
      isEdit: false,
      name: '',
      description: '',
      command: {},
      commandOptions: [],
      selectedParameters: [],
      parameterOptions: [],
      requiredParameterOptions: [],
      showParameterSelection: false,
    },
  );

  useEffect(() => {
    updateState({ isEdit: actionType === 'Update' });
  }, [actionType]);

  //set Resource Type Options
  useEffect(() => {
    const options = getResourceTypeOptions(resourceTypes.data);
    updateState({
      resourceTypeOptions: options,
      resourceType: state.isEdit
        ? find(options, o => o.value === data?.resource_type)
        : options[0],
    });
  }, [resourceTypes.data, data, state.isEdit, getCloudNativeName]);

  //get resource type specific commands
  useEffect(() => {
    !!state.resourceType?.value &&
      dispatch(
        actions.getCommands({
          q: { resourceType: state.resourceType?.value },
        }),
      );
  }, [dispatch, state.resourceType]);

  const getRequiredParameters = useCallback(command => {
    return map(command?.data?.required_parameters, o => ({
      label: o,
      value: o,
      isDisabled: true,
    }));
  }, []);

  const getParameters = useCallback(
    command => {
      let requiredParameterOptions = getRequiredParameters(command);
      return [
        ...requiredParameterOptions,
        ...map(command?.data?.parameters, o => ({
          label: o,
          value: o,
        })),
      ];
    },
    [getRequiredParameters],
  );

  //set command options
  useEffect(() => {
    const options = map(commands.data, o => ({
      label: o?.command,
      value: o?.id,
      data: {
        parameters: o?.parameters,
        required_parameters: o?.required_parameters,
      },
    }));
    let selectedCommand: Record<string, any> = options[0];
    let requiredParameterOptions = getRequiredParameters(selectedCommand);
    let parameterOptions = getParameters(selectedCommand);
    let selectedParameters: Record<string, any> = requiredParameterOptions;

    if (state.isEdit) {
      selectedCommand =
        find(
          options,
          o => o?.value === data?.command_id || o?.label === data?.command,
        ) ?? {};
      requiredParameterOptions = getRequiredParameters(selectedCommand);
      parameterOptions = getParameters(selectedCommand);
      selectedParameters = filter(
        parameterOptions,
        o =>
          includes(data.parameters, o?.value) ||
          includes(data.required_parameters, o?.value),
      );
    }
    updateState({
      commandOptions: options,
      command: selectedCommand,
      requiredParameterOptions,
      parameterOptions,
      selectedParameters,
    });
  }, [commands.data, state.isEdit, data, getParameters, getRequiredParameters]);

  //set name and description
  useEffect(() => {
    if (state.isEdit) {
      updateState({
        name: data?.name,
        description: data?.description,
      });
    }
  }, [data, state.isEdit]);

  const handleRedirect = () => navigate('/policy-hub/remediation');

  const handleSubmit = data => {
    const payload = {
      name: data.name,
      description: data.description,
      resource_type: state?.resourceType?.value,
      command: state?.command?.label,
      command_id: state?.command?.value,
      parameters: map(state.selectedParameters, o => o?.value),
    };

    if (state.isEdit) {
      dispatch(
        actions.updateRemediation({
          q: {
            remediationId: data?.id ?? params?.id,
            remediationUpdate: payload,
          },
          onSuccess: () => handleRedirect(),
        }),
      );
    } else {
      dispatch(
        actions.addRemediation({
          q: {
            remediationCreateRequest: payload,
          },
          onSuccess: () => handleRedirect(),
        }),
      );
    }
  };

  const renderCommands = () => (
    <Stack spacing={4}>
      <Wrap align="center">
        <WrapItem>
          <Box w={72}>
            <Select
              options={state.commandOptions}
              value={state.command}
              onChange={s => {
                const requiredParameterOptions = map(
                  s?.data?.required_parameters,
                  o => ({
                    label: o,
                    value: o,
                    isDisabled: true,
                  }),
                );
                updateState({
                  command: s,
                  requiredParameterOptions,
                  parameterOptions: [
                    ...requiredParameterOptions,
                    ...map(s?.data?.parameters, o => ({ label: o, value: o })),
                  ],
                  selectedParameters: requiredParameterOptions,
                });
              }}
              isLoading={commands.isLoading}
              isDisabled={commands.isLoading || state.isEdit}
            />
          </Box>
        </WrapItem>
        {!!state.selectedParameters?.length &&
          map(state.selectedParameters, o => (
            <WrapItem>
              <Flex>
                <Flex
                  border="1px solid"
                  borderRadius={6}
                  borderColor={customTheme.colors.gray['100']}
                  px={2}
                  py={1.5}
                >
                  {o?.value}
                </Flex>
                <Box position="relative" left={-2} top={-2}>
                  <Badge
                    w={4}
                    h={4}
                    borderRadius="full"
                    bg="red"
                    opacity={
                      (state.isEdit && includes(data?.parameters, o?.value)) ||
                      o?.isDisabled
                        ? 0.4
                        : 1
                    }
                    color="white"
                    cursor={
                      (state.isEdit && includes(data?.parameters, o?.value)) ||
                      o?.isDisabled
                        ? 'not-allowed'
                        : 'pointer'
                    }
                    onClick={() =>
                      !(state.isEdit && includes(data?.parameters, o?.value)) &&
                      !o?.isDisabled &&
                      updateState({
                        selectedParameters: filter(
                          state.selectedParameters,
                          s => o?.value !== s?.value,
                        ),
                      })
                    }
                  >
                    <MinusIcon />
                  </Badge>
                </Box>
              </Flex>
            </WrapItem>
          ))}
        {state.showParameterSelection && (
          <WrapItem>
            <Box w={56}>
              <Select
                options={state.parameterOptions}
                onChange={s =>
                  updateState({
                    selectedParameters: s,
                    showParameterSelection: false,
                  })
                }
                value={state.selectedParameters}
                isMulti
                alwaysShowPlaceholder
                isLoading={commands.isLoading}
                isDisabled={commands.isLoading}
                hideSelectedOptions
                showTotalSelected
              />
            </Box>
          </WrapItem>
        )}
        <AddConditionButton
          isDisabled={
            commands.isLoading ||
            isEmpty(state.commandOptions) ||
            state.selectedParameters?.length === state.parameterOptions?.length
          }
          onClick={() => updateState({ showParameterSelection: true })}
        />
      </Wrap>
      {!!state?.command?.label && (
        <Wrap py={1} px={2} bg={customTheme.colors.gray['50']} borderRadius={6}>
          <WrapItem>{state?.command?.label}</WrapItem>
          {map(state.selectedParameters, o => (
            <WrapItem>{o?.value}</WrapItem>
          ))}
        </Wrap>
      )}
      {commands.isSuccess && commands.data.length === 0 && (
        <Text color={customTheme.colors.gray['200']} top={-2} pos={'relative'}>
          No commands available
        </Text>
      )}
    </Stack>
  );

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          value: state.name,
          onChange: value => updateState({ name: value }),
          placeholder: 'Enter the name of this remediation',
        },
        description: {
          type: 'textArea',
          label: 'Description',
          value: state.description,
          onChange: value => updateState({ description: value }),
          placeholder: 'Enter the description of this remediation',
          isRequired: true,
        },
        resourceType: {
          type: 'react-select',
          label: (
            <HStack>
              <Box>Resource type</Box>
              <CustomTooltip label="Once you choose the resource type, you can choose from commands for each resource type and parameters that can be applied. For missing commands, please contact Ariksa support">
                <QuestionCircleIcon color="primary" />
              </CustomTooltip>
            </HStack>
          ),
          value: state.resourceType,
          options: state.resourceTypeOptions,
          onChange: value => updateState({ resourceType: value }),
          isDisabled: state.isEdit,
        },
        command: {
          type: 'custom-with-form-control',
          label: (
            <HStack>
              <Box>Command</Box>
              <CustomTooltip label="Please choose from the list of supported commands and parameters for this resource type. You can then apply this remediation to any alert">
                <QuestionCircleIcon color="primary" />
              </CustomTooltip>
            </HStack>
          ),
          component: renderCommands(),
        },
      }}
      styles={{ form: { container: { pl: 14, pt: 4 }, formWidth: '4xl' } }}
      buttonOptions={{
        submit: {
          name: state.isEdit ? 'Confirm' : 'Ok',
          isLoading: remediation.isLoading || remediationAction.isLoading,
          isDisabled:
            !state.name ||
            !state.description ||
            remediationAction.isLoading ||
            remediation.isLoading ||
            !state.command?.value ||
            !state.resourceType?.value,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: () => handleRedirect(),
        },
      }}
      isLoading={remediation.isLoading}
      handleSubmit={handleSubmit}
    />
  );
};
