import { AriksaBotApiChatRequest, ChatResponse } from '@ariksa/ai-engine/api';
import { VulnerabilitiesApiGetEolDashboardDetailsRequest } from '@ariksa/inventory-core';
import {
  CloudInsight,
  CloudInsightApiGetCloudInsightsRequest,
  EOLDashboard,
} from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the ConversationalAI container
export const initialState: ContainerState = {
  cloudInsights: QueryState.init([]),
  eolSoftware: QueryState.init({} as EOLDashboard),
  chatItems: [],
  chatSessionId: '',
  askAI: QueryState.init({}),
};

const userProfileSlice = createSlice({
  name: 'conversationalAI',
  initialState,
  reducers: {
    askAI(state, action: QueryAction<ChatResponse, AriksaBotApiChatRequest>) {
      state.askAI = QueryState.next(state.askAI, action);
    },
    updateChatSessionId(state, action: PayloadAction<string>) {
      state.chatSessionId = action.payload;
    },
    updateChatItems(state, action: PayloadAction<any[]>) {
      state.chatItems = action.payload;
    },
    getCloudInsight(
      state,
      action: QueryAction<
        CloudInsight[],
        CloudInsightApiGetCloudInsightsRequest
      >,
    ) {
      state.cloudInsights = QueryState.next(state.cloudInsights, action);
    },

    getEOLSoftware(
      state,
      action: QueryAction<
        EOLDashboard,
        VulnerabilitiesApiGetEolDashboardDetailsRequest
      >,
    ) {
      state.eolSoftware = QueryState.next(state.eolSoftware, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = userProfileSlice;
