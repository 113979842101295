import React from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { PrimaryButton } from 'components/DataEntry';
import { LineChartWithoutAxisIcon } from 'components/Icons';
import { selectCompliance } from 'containers/Compliance/selectors';
import { getBlueprintIcon } from 'containers/PolicyHub/utils';

interface Props {
  showDashboard: boolean;
  setShowDashboard(val: boolean);
}

export const ComplianceHeader: React.FC<Props> = props => {
  const { selectedBlueprint } = useSelector(selectCompliance);
  const { showDashboard, setShowDashboard } = props;

  return (
    <HStack spacing={4} justify="space-between" w="full">
      <PageHeaderWithIcon
        label={<Box>{selectedBlueprint?.name}</Box>}
        icon={getBlueprintIcon(
          selectedBlueprint?.created_at !== 'system',
          selectedBlueprint?.compliance ?? selectedBlueprint?.name,
        )}
        iconFilled={false}
        reversed
      />
      <CustomTooltip
        label={(showDashboard ? 'Hide' : 'Show') + ' compliance summary'}
        textAlign="center"
        w={24}
      >
        <PrimaryButton
          onClick={() => setShowDashboard(!showDashboard)}
          leftIcon={<LineChartWithoutAxisIcon />}
        >
          {showDashboard ? 'Hide ' : 'Show '}Summary
        </PrimaryButton>
      </CustomTooltip>
    </HStack>
  );
};
