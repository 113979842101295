export const accordionStyles = {
  button: {
    px: 0,
    py: 0,
    _hover: {},
    _active: {},
  },
  item: {
    border: 'none',
    p: 0,
  },
  panel: {
    p: 0,
  },
};
