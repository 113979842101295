import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RubyIcon = props => (
  <Icon viewBox="0 0 32 32" color="inherit" h="full" w="full" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5941 19.8407L8.8916 27.9998L26.6338 26.7925L28.0003 8.85254L22.5941 19.8407Z"
      fill="url(#paint0_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6631 26.7806L25.1381 16.2261L20.9844 21.7262L26.6631 26.7806Z"
      fill="url(#paint1_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6836 26.7803L15.5117 25.9009L8.95117 27.9769L26.6836 26.7803Z"
      fill="url(#paint2_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96674 27.9796L11.7576 18.811L5.61621 20.1279L8.96674 27.9796Z"
      fill="url(#paint3_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4176 11.8117L20.4706 6.12207L18.5361 12.3936L27.4176 11.8117Z"
      fill="url(#paint4_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1684 4.09336L20.0826 6.35755L17.5049 4.06299L24.1684 4.09336Z"
      fill="url(#paint5_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 23.2031L5.7116 20.0729L4.32706 16.3438L4 23.2031Z"
      fill="url(#paint6_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.23535 16.2259L5.62838 20.1883L11.6814 18.8265L18.592 12.3862L20.5422 6.17431L17.4714 4L12.2506 5.95931C10.6057 7.49347 7.41387 10.529 7.29879 10.5861C7.18493 10.6444 5.19109 14.4233 4.23535 16.2259Z"
      fill="white"
    />
    <path
      d="M11.7227 18.7882L18.592 12.3863L18.5951 12.3765L20.9832 21.7566L11.7227 18.7882Z"
      fill="url(#paint7_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12619 9.10774C12.6911 5.56325 17.2869 3.46911 19.0506 5.2535C20.8132 7.03789 18.944 11.3744 15.3791 14.9176C11.8141 18.4609 7.27528 20.6705 5.5128 18.8861C3.74911 17.1029 5.56126 12.651 9.12619 9.10774Z"
      fill="url(#paint8_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9668 27.9755L11.7359 18.7778L20.9323 21.7405C17.6072 24.8671 13.909 27.5103 8.9668 27.9755Z"
      fill="url(#paint9_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6035 12.3679L20.9644 21.7454C23.742 18.8167 26.2349 15.6682 27.4558 11.7739L18.6035 12.3679Z"
      fill="url(#paint10_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4317 11.8217C28.3765 8.96229 28.5945 4.86025 24.1392 4.09863L20.4834 6.12354L27.4317 11.8217Z"
      fill="url(#paint11_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 23.162C4.13082 27.8812 7.52617 27.9515 8.97249 27.9929L5.63166 20.1689L4 23.162Z"
      fill="#9E1209"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6172 12.3826C20.7515 13.6981 25.0531 16.3401 25.1403 16.3887C25.2758 16.4652 26.9948 13.4819 27.3849 11.7959L18.6172 12.3826Z"
      fill="url(#paint12_radial_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7324 18.7778L15.4342 25.9397C17.6231 24.7493 19.3371 23.2989 20.907 21.7453L11.7324 18.7778Z"
      fill="url(#paint13_radial_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61728 20.1783L5.09277 26.4413C6.08243 27.7969 7.44396 27.9147 8.87211 27.8091C7.83885 25.2303 5.77475 20.0739 5.61728 20.1783Z"
      fill="url(#paint14_linear_87_8208)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4619 6.1393L27.8159 7.17422C27.4234 5.50644 26.2181 4.43022 24.1637 4.09375L20.4619 6.1393Z"
      fill="url(#paint15_linear_87_8208)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_87_8208"
        x1="25.0863"
        y1="30.1825"
        x2="20.0019"
        y2="21.2307"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FB7655" />
        <stop offset="0.41" stopColor="#E42B1E" />
        <stop offset="0.99" stopColor="#990000" />
        <stop offset="1" stopColor="#990000" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_87_8208"
        x1="27.6086"
        y1="22.6528"
        x2="21.0723"
        y2="18.2786"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#871101" />
        <stop offset="0.99" stopColor="#911209" />
        <stop offset="1" stopColor="#911209" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_87_8208"
        x1="22.3878"
        y1="30.4542"
        x2="15.8517"
        y2="26.0802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#871101" />
        <stop offset="0.99" stopColor="#911209" />
        <stop offset="1" stopColor="#911209" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_87_8208"
        x1="8.68769"
        y1="19.4743"
        x2="9.70472"
        y2="26.0657"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.23" stopColor="#E57252" />
        <stop offset="0.46" stopColor="#DE3B20" />
        <stop offset="0.99" stopColor="#A60003" />
        <stop offset="1" stopColor="#A60003" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_87_8208"
        x1="21.8192"
        y1="7.10002"
        x2="22.9409"
        y2="11.9204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.18" stopColor="#E46342" />
        <stop offset="0.4" stopColor="#C82410" />
        <stop offset="0.99" stopColor="#A80D00" />
        <stop offset="1" stopColor="#A80D00" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_87_8208"
        x1="18.4117"
        y1="5.40177"
        x2="23.2251"
        y2="3.00175"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.54" stopColor="#C81F11" />
        <stop offset="0.99" stopColor="#BF0905" />
        <stop offset="1" stopColor="#BF0905" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_87_8208"
        x1="4.47282"
        y1="17.7935"
        x2="4.87073"
        y2="21.7663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.31" stopColor="#DE4024" />
        <stop offset="0.99" stopColor="#BF190B" />
        <stop offset="1" stopColor="#BF190B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_87_8208"
        x1="15.6452"
        y1="13.3187"
        x2="16.02"
        y2="20.0464"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.23" stopColor="#E4714E" />
        <stop offset="0.56" stopColor="#BE1A0D" />
        <stop offset="0.99" stopColor="#A80D00" />
        <stop offset="1" stopColor="#A80D00" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_87_8208"
        x1="1.75216"
        y1="22.8404"
        x2="20.4078"
        y2="3.71053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BD0012" />
        <stop offset="0.07" stopColor="white" />
        <stop offset="0.17" stopColor="white" />
        <stop offset="0.27" stopColor="#C82F1C" />
        <stop offset="0.33" stopColor="#820C01" />
        <stop offset="0.46" stopColor="#A31601" />
        <stop offset="0.72" stopColor="#B31301" />
        <stop offset="0.99" stopColor="#E82609" />
        <stop offset="1" stopColor="#E82609" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_87_8208"
        x1="16.0015"
        y1="24.7752"
        x2="10.3965"
        y2="23.3957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C0C01" />
        <stop offset="0.54" stopColor="#990C00" />
        <stop offset="0.99" stopColor="#A80D0E" />
        <stop offset="1" stopColor="#A80D0E" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_87_8208"
        x1="25.6251"
        y1="18.0314"
        x2="20.635"
        y2="13.5714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7E110B" />
        <stop offset="0.99" stopColor="#9E0C00" />
        <stop offset="1" stopColor="#9E0C00" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_87_8208"
        x1="27.4539"
        y1="9.82314"
        x2="24.9671"
        y2="7.17189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#79130D" />
        <stop offset="0.99" stopColor="#9E120B" />
        <stop offset="1" stopColor="#9E120B" />
      </linearGradient>
      <radialGradient
        id="paint12_radial_87_8208"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.4229 13.6432) scale(6.09995 6.11692)"
      >
        <stop stopColor="#A80D00" />
        <stop offset="0.99" stopColor="#7E0E08" />
        <stop offset="1" stopColor="#7E0E08" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_87_8208"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.9755 21.7042) scale(8.10906 8.13164)"
      >
        <stop stopColor="#A30C00" />
        <stop offset="0.99" stopColor="#800E08" />
        <stop offset="1" stopColor="#800E08" />
      </radialGradient>
      <linearGradient
        id="paint14_linear_87_8208"
        x1="7.23075"
        y1="27.9701"
        x2="5.19975"
        y2="21.0987"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8B2114" />
        <stop offset="0.43" stopColor="#9E100A" />
        <stop offset="0.99" stopColor="#B3100C" />
        <stop offset="1" stopColor="#B3100C" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_87_8208"
        x1="22.7321"
        y1="5.19037"
        x2="27.2663"
        y2="7.18628"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B31000" />
        <stop offset="0.44" stopColor="#910F08" />
        <stop offset="0.99" stopColor="#791C12" />
        <stop offset="1" stopColor="#791C12" />
      </linearGradient>
    </defs>
  </Icon>
);
