import React, { useState, FC, useEffect, useMemo } from 'react';

import { TagCondition } from '@ariksa/compliance-policies';
import { Box, Stack, Button } from '@chakra-ui/react';
import { map, filter, isEmpty, some } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Form } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import {
  deleteIconStyles,
  valueContainerStyles,
} from 'containers/PolicyHub/AlertWorkflow/styles';

import { useEnvironmentContext } from '../../../../context';
import { selectEnvironment } from '../../../../selectors';
import { actions } from '../../../../slice';

import { CriteriaQuantifier } from './CriteriaQuantifier';
import { RegionCriteria } from './RegionCriteria';
import { TagKeysCriteria } from './TagKeysCriteria';
import { TagKeyValueCriteria } from './TagKeyValueCriteria';

export const EnvironmentCriteria: FC = () => {
  const {
    handleSubmit,
    handleBack,
    onCancel,
    resetCriteria,
    tagKeyOptions,
    actionType,
  } = useEnvironmentContext();
  const { formData, apiCallStatus } = useSelector(selectEnvironment);
  const { criteria } = formData;

  const [selectedConditions, setSelectedConditions] = useState<
    Record<string, any>[]
  >([]);
  const dispatch = useDispatch();

  const conditions = useMemo(
    () => [
      {
        label: 'If an entity is in region',
        value: 'region',
        component: <RegionCriteria />,
      },
      {
        label: 'If an entity has tag key',
        value: 'tag_keys_condition',
        component: <TagKeysCriteria />,
      },
      {
        label: 'If an entity has tag key and value',
        value: 'tag_key_values_condition',
        component: <TagKeyValueCriteria />,
      },
    ],
    [],
  );

  //set selected conditions
  useEffect(() => {
    setSelectedConditions(
      filter(conditions, o => !isEmpty(criteria?.[o.value])),
    );
  }, [conditions, criteria]);

  return (
    <Form
      styles={{ form: { container: { pt: 8 } } }}
      buttonOptions={{
        submit: {
          name: actionType + ' Environment',
          isLoading: apiCallStatus.isLoading,
          //isDisabled: !selectedConditions.length,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: onCancel,
        },
        back: {
          isVisible: true,
          onClick: handleBack,
        },
      }}
      handleSubmit={handleSubmit}
    >
      <Stack>
        <CriteriaQuantifier />

        <Stack>
          <Box mb={2}>
            <Select
              options={conditions}
              value={selectedConditions}
              isMulti
              showTotalSelected={selectedConditions.length > 0}
              placeholder="Choose conditions..."
              onChange={selected => {
                setSelectedConditions(selected);
                if (
                  some(selected, ['value', 'tag_key_values_condition']) &&
                  isEmpty(criteria?.tag_key_values_condition)
                )
                  dispatch(
                    actions.updateKeyValueCriteria({
                      id: nanoid(),
                      value: {
                        key: tagKeyOptions[0].value,
                        key_condition: TagCondition.Is,
                        value_condition: TagCondition.Is,
                        value: [],
                      },
                    }),
                  );
              }}
            />
          </Box>
          {map(selectedConditions, each => (
            <Stack
              {...valueContainerStyles}
              key={each?.value + '-environment-condition'}
            >
              <Box w="full">{each?.component}</Box>
              <Button
                {...deleteIconStyles}
                onClick={() => {
                  setSelectedConditions(
                    filter(selectedConditions, o => o.value !== each.value),
                  );
                  resetCriteria(each.value);
                }}
              >
                <TrashIcon />
              </Button>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Form>
  );
};
