import React, { FC, useEffect, useState } from 'react';

import { forEach, map } from 'lodash';
import { useSelector } from 'react-redux';

import { colorMapForCharts } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { sensitiveCategories } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const SensitiveDataByTypePieChart: FC = () => {
  const { sensitiveDataByType } = useSelector(selectDataDashboard);
  const { category_types, total = 0 } = sensitiveDataByType.data;
  const { getResourceAlias } = useResourceType();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!!category_types) {
      const types: Record<string, any> = {};
      forEach(category_types, o => (types[o.category_type!] = o.type_count));
      setData(
        map(sensitiveCategories, o => ({
          name: o,
          value: types?.[o],
        })),
      );
    }
  }, [category_types, getResourceAlias]);

  return (
    <>
      <PieChart
        isLoading={sensitiveDataByType.isLoading}
        data={data}
        dataKey="value"
        total={formatNumber(total)}
        outerRadius="85%"
        innerRadius="76%"
        activeShape={false}
        colorMap={colorMapForCharts}
        styles={{
          pie: { cx: '45%' },
          legend: {
            wrapperStyle: {
              width: '135px',
            },
          },
        }}
      />
    </>
  );
};
