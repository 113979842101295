import React, { FC } from 'react';

import { Grid, Accordion, GridItem } from '@chakra-ui/react';

import { renderTime } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CertificateSourceType,
  OktaDomain,
  ID,
  ValidatedStatus,
} from '../../../Components/MetadataField/MetaGridFields';

export const OktaDomainMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const dnsRecordsColumns = [
    { header: 'Record type', accessor: 'recordType' },
    { header: 'FQDN', accessor: 'fqdn', align: 'left' },
    {
      header: 'Expiration',
      accessor: 'expiration',
      align: 'left',
      render: renderTime,
    },
  ];
  const publicCertificateColumns = [
    { header: 'Subject', accessor: 'subject' },
    { header: 'Fingerprint', accessor: 'fingerprint', align: 'left' },
    {
      header: 'Expiration',
      accessor: 'expiration',
      align: 'left',
      render: renderTime,
    },
  ];

  const dnsRecords = resource?.config?.dnsRecords;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {OktaDomain(resource)}
      {ID(insight)}
      {ValidatedStatus(resource)}
      {CertificateSourceType(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={dnsRecordsColumns} data={dnsRecords} />}
            iconType={''}
            label="DNS records"
            totalCount={dnsRecords?.length}
            height="300px"
          />
          <MetaAccordionItem
            panel={
              <Table
                columns={publicCertificateColumns}
                data={[resource?.config?.publicCertificate]}
              />
            }
            iconType={''}
            label="Public certificate"
            totalCount={1}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
