import React, { FC, useEffect, useState } from 'react';

import { Box, HStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';
import { selectApp } from 'containers/App/selectors';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { DataClassificationWizardProps } from 'containers/Setup/DataConfiguration/types';

import { SelectDataSources } from '../SelectDataSources';
import { snowflakeFormStyles } from '../styles';
import { renderComponentHeader } from '../utils';

interface Props extends DataClassificationWizardProps {}

export const RepositoryDataClassificationForm: FC<Props> = props => {
  const {
    handleRedirect,
    handleSubmit,
    accountOptions,
    selectedAccount,
    scannerOptions,
    selectedScanner,
    updateValue,
    scannerAccountOptions,
    selectedScannerAccount,
    scannerEnvironmentOptions,
    selectedScannerEnvironment,
    environmentOptions,
    selectedEnvironment,
    selectedRepository,
    repositoryOptions,
  } = props;
  const [showHelp, setShowHelp] = useState(false);
  const {
    service,
    enableDataClassification,
    dataScannerInfo,
    dataSources,
    accountType,
    repositories,
  } = useSelector(selectDataClassification);
  const { data: data_sources } = dataSources;
  const [totalIncludedDataSources, setTotalIncludedDataSources] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [name, setName] = useState('');
  const { currentRecord, actionType } = useDataConfigurationContext();
  const { cloudAccounts } = useSelector(selectApp);

  //set name
  useEffect(() => {
    if (actionType === 'Update') {
      setName(currentRecord?.name);
    }
  }, [currentRecord, actionType]);

  //set total number of included data sources
  useEffect(() => {
    let total = data_sources?.length ?? 0;

    if (service?.category === 'include' && service?.quantifier === 'some') {
      total = service?.data_sources?.length ?? 0;
    } else if (service?.category === 'exclude')
      total = total - (service?.data_sources?.length ?? 0);

    setTotalIncludedDataSources(!selectedAccount?.value ? 0 : total);
  }, [service, data_sources, selectedAccount]);

  //set isDisabled
  useEffect(() => {
    if (
      (service.quantifier === 'some' && isEmpty(service.data_sources)) ||
      !name
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [service, name]);

  return (
    <Box w="full">
      <Form
        styles={snowflakeFormStyles}
        title={renderComponentHeader()}
        schema={{
          name: {
            type: 'text',
            label: 'Name',
            value: name,
            onChange: setName,
          },
          environment_id: {
            type: 'react-select',
            label: 'Environment',
            options: environmentOptions,
            value: selectedEnvironment,
            onChange: selected => {
              updateValue({ selectedEnvironment: selected });
            },
            /*isLoading:
              cloudAccounts.loading || dataClassificationGroups.isLoading,*/
            isDisabled: actionType === 'Update',
          },
          account_ids: {
            type: 'react-select',
            label: 'SaaS name',
            options: accountOptions,
            value: selectedAccount,
            onChange: s =>
              updateValue({ selectedAccount: s, selectedRepository: {} }),
            isLoading: cloudAccounts.loading || dataScannerInfo.isLoading,
            isDisabled:
              cloudAccounts.loading ||
              actionType === 'Update' ||
              dataScannerInfo.isLoading,
          },
          repository: {
            type: 'react-select',
            label: 'Repository name or URL',
            options: repositoryOptions,
            value: selectedRepository,
            onChange: s => updateValue({ selectedRepository: s }),
            isLoading: repositories.isLoading,
            isDisabled:
              repositories.isLoading ||
              actionType === 'Update' ||
              dataScannerInfo.isLoading,
          },
          dataSources: {
            type: 'custom-with-form-control',
            component: (
              <SelectDataSources
                dataSourceType={{
                  label: accountType,
                  value: accountType,
                  //native_resource: '',
                }}
                repoName={selectedRepository?.value}
              />
            ),
            label: 'Choose Data Sources',
          },
          scanner: {
            type: 'object',
            label: 'Assign a Data Scanner for Data Sources',
            properties: {
              scanner_environment_id: {
                type: 'react-select',
                label: 'Select environment',
                options: scannerEnvironmentOptions,
                value: selectedScannerEnvironment,
                onChange: s => updateValue({ selectedScannerAccount: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
              scanner_account_id: {
                type: 'react-select',
                label: 'Select account for Ariksa Data Outpost',
                options: scannerAccountOptions,
                value: selectedScannerAccount,
                onChange: s => updateValue({ selectedScannerAccount: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
              scanner_id: {
                type: 'react-select',
                label: 'Select Ariksa Outpost for Data',
                options: scannerOptions,
                value: selectedScanner,
                onChange: s => updateValue({ selectedScanner: s }),
                isLoading: dataScannerInfo.isLoading,
                isDisabled: cloudAccounts.loading || dataScannerInfo.isLoading,
              },
            },
          },
          numberOfDataSourcesIncluded: {
            type: 'custom',
            component: () => (
              <HStack color="primary">
                <Box>
                  Number of data sources included for automatic data
                  classification:
                </Box>
                <Box>{totalIncludedDataSources}</Box>
              </HStack>
            ),
          },
        }}
        showHelp={showHelp}
        buttonOptions={{
          submit: {
            name: 'Confirm',
            isDisabled: isDisabled || dataScannerInfo.isLoading,
            isLoading:
              enableDataClassification.isLoading || dataScannerInfo.isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleRedirect,
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
