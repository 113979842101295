import {
  CloudAccountCreate,
  CloudAccountGetResponse,
  MemberAccountOnboardRequest,
} from '@ariksa/cloud-account';
import {
  GetTemplateResponse,
  OnboardingConfigResponse,
  RepositoryInfo,
} from '@ariksa/cloud-account/api';
import { IQueryState } from 'services/utils/QueryState';

/* --- STATE --- */
export interface CloudAccountWizardState {
  //templates: IQueryState<any[]>;
  config: IQueryState<OnboardingConfigResponse>;
  onboardingAccount: IQueryState<CloudAccountCreate>;
  onboardingMemberAccount: IQueryState<any>;
  onboardedAccount: IQueryState<CloudAccountGetResponse>;
  templateType: TemplateType;
  templatePermissions: IQueryState<GetTemplateResponse[]>;

  onboardMemberAccounts: IQueryState<any>;
  repositories: IQueryState<RepositoryInfo[]>;
  onboardRepository: IQueryState<string>;
  validateS3Arn: IQueryState<string>;
  branches: IQueryState<string[]>;
}

export type ContainerState = CloudAccountWizardState;

export enum TemplateType {
  // NOTE: values are specific to backend API request
  // changing the values will break API call
  AnalyzeS3 = 'read_with_s3',
  DoNotAnalyze = 'read',
}

export interface MemberAccountCreateRequest {
  uuid?: string;
  memberAccountOnboardRequest: MemberAccountOnboardRequest;
}

export const AWS = 'aws';
export const GCP = 'gcp';
