import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { MetaGridValueBox } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridValueBox';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  Applications,
  AutoTerminate,
  CustomAMIID,
  EC2Instances,
  LogURI,
  MasterDNSName,
  OSReleaseLabel,
  ServiceRole,
  TerminationProtected,
  VisibleToAllUsers,
  IAMInstanceProfile,
  KeyName,
  AvailabilityZone,
  MasterSecurityGroup,
  SlaveSecurityGroup,
  ServiceAccessSecurityGroup,
} from '../../../Components/MetadataField/MetaGridFields';

export const ElasticMapReduceMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" />
      {OSReleaseLabel(resource)}
      {LogURI(resource)}
      {EC2Instances(resource)}
      {CustomAMIID(resource)}
      {ServiceRole(resource)}
      {TerminationProtected(resource)}
      {Applications(resource)}
      {VisibleToAllUsers(resource)}
      {MasterDNSName(resource)}
      {AutoTerminate(resource)}
      <MetaGridValueBox
        label={`EC2 attributes`}
        value={
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            {KeyName(resource)}
            {AvailabilityZone(resource)}
            {IAMInstanceProfile(resource)}
            {MasterSecurityGroup(resource)}
            {SlaveSecurityGroup(resource)}
            {ServiceAccessSecurityGroup(resource)}
          </Grid>
        }
      />
    </Grid>
  );
};
