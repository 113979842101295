/**
 *
 * Top Nav Bar
 *
 */
import React from 'react';

import { Flex } from '@chakra-ui/react';

import { useNavBarContext } from 'components/Navigation/NavBar/context';

import { Logo } from '../Logo';
import { NavBarComponent } from '../NavBarComponent';

import { LeftComponent } from './LeftComponent';
import { MobileMenu } from './MobileMenu';
import { RightComponent } from './RightComponent';
import { leftComponentStyle, topNavWrapperStyles } from './styles';

interface Props {}

export const TopNavBar: React.FC<Props> = props => {
  const { showWelcomeScreen } = useNavBarContext();
  return (
    <NavBarComponent
      leftComponent={() =>
        !showWelcomeScreen && (
          <Flex {...leftComponentStyle}>
            <LeftComponent />
          </Flex>
        )
      }
      logo={<Logo mr={12} color="white" />}
      rightComponent={() => <RightComponent />}
      mobileMenuButton={() => <MobileMenu />}
      wrapperStyles={topNavWrapperStyles}
    />
  );
};

export default TopNavBar;
