import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { identity, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { WithParentSize } from 'components/Container/WithParentSize';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { createColumn } from 'containers/Visibility/Components/ResourceInsight/utils';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { WithCenterLoader } from 'utils/PageLoader';

interface ISecurityGraphTable {}

export const SecurityGraphTable: FC<ISecurityGraphTable> = props => {
  const dispatch = useDispatch();

  const { tableResult } = useSearchResult();

  const [activeRowData, setActiveRowData] = useState<{
    row: any;
    index: number;
  } | null>(null);
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();

  const updateActiveRowData = useCallback(
    (row, index) => {
      dispatch(
        actions.updateActiveRow({
          source_uuid: row.source_uuid,
          resource_name: row[row.source_native ?? '']?.[0] ?? '-',
        }),
      );
    },
    [dispatch],
  );

  const columns: TableColumnProps[] = useMemo(() => {
    const { source = '', column_names = [] } = tableResult.data ?? {};

    const columns =
      column_names
        ?.map((col, index) => {
          return createColumn(col, source, index);
        })
        .filter(identity) ?? [];

    return columns as TableColumnProps[];
  }, [tableResult.data]);

  return (
    <Flex h={'full'} w={'full'}>
      <WithCenterLoader isLoading={tableResult.isLoading}>
        <WithParentSize>
          {(h, w) => {
            return (
              <Box w={w + 'px'} h={h + 'px'}>
                <Table
                  columns={isEmpty(tableResult.data.table_rows) ? [] : columns}
                  data={tableResult.data.table_rows ?? []}
                  onRowClick={(row, index) => {
                    setActiveRowData({ row, index });
                    updateActiveResource({
                      resourceType: row.source_native,
                      uuid: row.source_uuid,
                      accountId: row.deployed_in[0],
                    });
                    onOpenMetadata();
                    updateActiveRowData(row, index);
                  }}
                  isRowActive={row => {
                    return row.source_uuid === activeRowData?.row?.source_uuid;
                  }}
                />
              </Box>
            );
          }}
        </WithParentSize>
      </WithCenterLoader>
    </Flex>
  );
};
