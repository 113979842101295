import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AlertResponse } from '@ariksa/notification';

import { EvidencePixiGraph } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/EvidencePixiGraph';
import { ResourceDependencies } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Dependencies/Dependencies';

interface IDependencies {
  alert: AlertResponse;
}

export const Dependencies: FC<IDependencies> = props => {
  const { alert } = props;

  switch (alert.resource) {
    case NativeResources.SimpleStorageService:
      return <EvidencePixiGraph alert={alert} />;
    case NativeResources.Ec2Instance:
      return <ResourceDependencies alert={alert} />;
    default:
      return <ResourceDependencies alert={alert} />;
  }
};
