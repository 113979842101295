import React, { useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import {
  Box,
  HStack,
  Stack,
  Center,
  Flex,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ActivityIcon,
  GraphDecreaseIcon,
  GraphIncreaseIcon,
} from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderAccount } from 'containers/Dashboard/utils/utils';
import { GovernanceChecklist } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { limitedString } from 'utils/string';

export const GovernanceScore: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { governanceScore } = stateOfYourCloud;
  const { accountMapping } = useCloudAccountId();
  const navigate = useNavigate();
  const governanceChecklist = useDisclosure();
  const [currentAccount, setCurrentAccount] = useState({});

  const getRiskRatingColor = rating => {
    switch (rating) {
      case 'Poor':
        return customTheme.colors.critical;
      case 'Average':
        return customTheme.colors.medium;
      default:
        return customTheme.colors.low;
    }
  };

  const renderAccountRow = item => (
    <>
      <Grid
        w="full"
        h="full"
        templateColumns="repeat(4, 1fr)"
        gap={1}
        pt={2}
        key={item?.account_id + 'account'}
      >
        <GridItem colSpan={2}>
          <Center h="full" justifyContent="left">
            {renderAccount(
              limitedString(
                accountMapping[item?.account_id]?.name ??
                  accountMapping[item?.account_id]?.cloud_account_id,
                20,
              ),
              1,
              accountMapping[item?.account_id]?.cloud_type,
              20,
              () => {
                governanceChecklist.onOpen();
                setCurrentAccount(accountMapping[item?.account_id]);
              },
            )}
          </Center>
        </GridItem>
        <GridItem colSpan={1}>
          <HStack h="full" w="full" fontWeight={600}>
            <Box>Score:</Box>
            <Center>
              {item?.score}
              {item?.score % 1 === 0 ? '.0' : ''}
            </Center>
          </HStack>
        </GridItem>
        <GridItem colSpan={1}>
          <Center h="full" pl={8}>
            <Box
              w={16}
              py={0}
              bg={getRiskRatingColor(item?.rating)}
              color="white"
              borderRadius={4}
            >
              <Center>{item?.rating?.toUpperCase()}</Center>
            </Box>
          </Center>
        </GridItem>
      </Grid>
    </>
  );

  return (
    <DashboardOverviewCard
      label="Account governance based on best practices"
      icon={<ActivityIcon />}
      content={
        governanceScore.isLoading ? (
          <CustomSpinner />
        ) : isEmpty(governanceScore.data) ? (
          <NoDataAvailable />
        ) : (
          <HStack w="full" h="full" justify="space-between" spacing={4}>
            <Box w="28%" h="full">
              <Box h="full" pt={6} pb={2} px={4}>
                <Box
                  h="full"
                  w="full"
                  border="1px solid"
                  borderColor={getRiskRatingColor(governanceScore.data?.rating)}
                  borderRadius={6}
                  fontSize="md"
                  onClick={() => navigate('/setup/accounts')}
                  cursor="pointer"
                >
                  <Stack spacing={0} h="full">
                    <Center h="full">All accounts</Center>
                    <Stack spacing={0} h="full">
                      <Center
                        fontWeight={600}
                        fontSize="xl"
                        color={getRiskRatingColor(governanceScore.data?.rating)}
                      >
                        {governanceScore.data?.score}
                      </Center>
                      <Center>{governanceScore.data?.rating}</Center>
                    </Stack>
                    <Flex
                      h="full"
                      w="full"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {governanceScore.isLoading ? (
                        <CustomSpinner />
                      ) : (
                        <CustomTooltip
                          label={
                            governanceScore.data?.percentage_change === '-'
                              ? 0
                              : round(
                                  governanceScore.data?.percentage_change,
                                  0,
                                ) + '% over last 7 days'
                          }
                        >
                          {governanceScore.data?.percentage_change === '-' ? (
                            '0%'
                          ) : (
                            <HStack
                              color={
                                !governanceScore.data?.percentage_change ||
                                governanceScore.data?.percentage_change === '-'
                                  ? 'gray.250'
                                  : governanceScore.data?.percentage_change > 0
                                  ? 'green.300'
                                  : 'critical'
                              }
                              spacing={1}
                            >
                              <Box>
                                {governanceScore.data?.percentage_change > 0
                                  ? '+'
                                  : ''}
                                {round(
                                  governanceScore.data?.percentage_change,
                                  0,
                                )}
                                %
                              </Box>
                              <Box boxSize={5}>
                                <Center h="full">
                                  {!governanceScore.data?.percentage_change ||
                                  governanceScore.data?.percentage_change ===
                                    '-' ? (
                                    <Center h="full" pb={1}>
                                      -
                                    </Center>
                                  ) : governanceScore.data?.percentage_change >
                                    0 ? (
                                    <GraphIncreaseIcon />
                                  ) : (
                                    <GraphDecreaseIcon />
                                  )}
                                </Center>
                              </Box>
                            </HStack>
                          )}
                        </CustomTooltip>
                      )}
                    </Flex>
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Stack h="full" w="72%">
              {map(governanceScore.data?.accounts_score, o =>
                renderAccountRow(o),
              )}
            </Stack>
            {governanceChecklist.isOpen && (
              <GovernanceChecklist
                isOpen={governanceChecklist.isOpen}
                onClose={governanceChecklist.onClose}
                row={currentAccount as CloudAccountGetResponse}
              />
            )}
          </HStack>
        )
      }
      tooltip={{
        tooltip: (
          <Stack>
            <Box>
              Based on cloud best practices, Ariksa automatically analyzes your
              account for guardrails and proactive governance.
            </Box>
            <Box>
              Ariksa prioritizes low-effort, high-value guardrails and controls
              that can help reduce risk to your cloud and improve your account
              governance.
            </Box>
          </Stack>
        ),
        header: 'Account governance…',
        footerText: 'Ok',
        onClickFooter: () => navigate('/setup/accounts'),
      }}
    />
  );
};
