import React, { useEffect } from 'react';

import { Box, Stack } from '@chakra-ui/react';

import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { AggregatedAlertsTable } from 'containers/Findings/Alerts/Components/AlertsTable/AggregatedAlertsTable';
import { AlertsTableHeader } from 'containers/Findings/Alerts/Components/AlertsTable/AlertsTableHeader';
import { useAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/hooks/useAlerts';

export const AggregatedAlerts = () => {
  const { getAggregatedFindings } = useAlerts();

  useEffect(() => {
    getAggregatedFindings();
  }, [getAggregatedFindings]);

  return (
    <Stack h="full" spacing={5} w="full">
      <AlertsTableHeader />
      <Box flex={1}>
        <ActiveResourceProvider>
          <AggregatedAlertsTable />
        </ActiveResourceProvider>
      </Box>
    </Stack>
  );
};
