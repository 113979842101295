import React, { FC, useCallback, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { forEach, isArray, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { EnvironmentIcon } from 'components/Icons';
import { Steps } from 'components/Navigation/Steps';
import { StepProps } from 'components/Navigation/Steps/types';
import { actions as appActions } from 'containers/App/slice';
import { selectEnvironment } from 'containers/Setup/Environment/selectors';
import { actions } from 'containers/Setup/Environment/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import {
  EnvironmentContextProps,
  EnvironmentContextProvider,
} from '../../context';

import { EnvironmentCriteria } from './Steps/EnvironmentCriteria';
import { EnvironmentDetails } from './Steps/EnvironmentDetails';

interface Props {
  actionType?: 'Create' | 'Update';
}

export const EnvironmentWizard: FC<Props> = props => {
  const { actionType = 'Create' } = props;
  const { formData, environment } = useSelector(selectEnvironment);
  const { tagKeys } = useSelector(selectSharedState);
  const { account_ids } = formData;
  const [currentStep, setCurrentStep] = useState(0);
  const [tagKeyOptions, setTagKeyOptions] = useState<Record<string, any>[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //load regions and tags
  useEffect(() => {
    if (!isEmpty(account_ids)) {
      dispatch(
        sharedStateActions.getRegions({ q: { accountId: [...account_ids] } }),
      );
      dispatch(
        sharedStateActions.getTagKeys({ q: { accountId: [...account_ids] } }),
      );
    }
  }, [dispatch, account_ids]);

  useEffect(() => {
    setTagKeyOptions(map(tagKeys.data, o => ({ label: o, value: o })));
  }, [tagKeys]);

  const updateCriteria = useCallback(
    (field, value) => {
      dispatch(
        actions.updateCriteria({
          field,
          value,
        }),
      );
    },
    [dispatch],
  );

  const resetCriteria = useCallback(
    field => {
      dispatch(actions.resetCriteria(field));
    },
    [dispatch],
  );

  const updateFormData = useCallback(
    (field, value) => {
      let payload = isArray(value)
        ? map(value, o => o.value)
        : value?.value || value;
      if (field === 'cloud') payload = 'aws';
      dispatch(
        actions.updateFormData({
          field,
          value: payload,
        }),
      );
    },
    [dispatch],
  );

  const onSubmitData = useCallback(
    data => {
      forEach(data, (value, field) => updateFormData(field, value));
    },
    [updateFormData],
  );

  const onCancel = useCallback(() => {
    dispatch(actions.resetFormData());
    navigate('/setup/environment');
  }, [navigate, dispatch]);

  const handleSubmit = useCallback(
    data => {
      const getEnvironments = () => {
        onCancel();
        dispatch(actions.getEnvironments({}));
        dispatch(appActions.loadEnvironments({}));
      };

      if (currentStep === 0) {
        setCurrentStep(currentStep + 1);
        onSubmitData(data);
      } else if (currentStep === 1) {
        if (actionType === 'Create') {
          dispatch(
            actions.createEnvironment({
              q: {
                environmentCreate: formData,
              },
              onSuccess: () => getEnvironments(),
            }),
          );
        } else
          dispatch(
            actions.updateEnvironment({
              q: {
                environmentUpdate: formData,
                environmentId: environment.data.id,
              },
              onSuccess: () => getEnvironments(),
            }),
          );
      }
    },
    [
      currentStep,
      onSubmitData,
      dispatch,
      onCancel,
      actionType,
      environment.data,
      formData,
    ],
  );

  const handleBack = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const contextProps: EnvironmentContextProps = {
    handleBack,
    handleSubmit,
    actionType,
    onCancel,
    tagKeyOptions,
    updateFormData,
    updateCriteria,
    resetCriteria,
  };

  const stepsOptions: StepProps[] = [
    {
      component: () => <EnvironmentDetails />,
      label: 'Details',
    },
    {
      component: () => <EnvironmentCriteria />,
      label: 'Criteria',
    },
  ];

  return (
    <EnvironmentContextProvider value={contextProps}>
      <Card
        headerComponent={
          <Box pl={32} ml={8}>
            <PageHeaderWithIcon
              label={actionType + ' Environment'}
              icon={<EnvironmentIcon />}
            />
          </Box>
        }
        contentComponent={<Steps current={currentStep} steps={stepsOptions} />}
      />
    </EnvironmentContextProvider>
  );
};
