import { policyHubApis } from 'api/policy-hub';

import { alertsApis } from './alerts';
import { authApis } from './auth';
import { clientsApis } from './clients';
import { collectorApis } from './collector';
import { bitbucketApis } from './dev_tools_integration/bitbucket';
import { githubApis } from './dev_tools_integration/github';
import { gitlabApis } from './dev_tools_integration/gitlab';
import { inventoryApis } from './inventory';
import { onboardApis } from './onboarding';
import { permissionsApis } from './permissions';

export const Api = {
  ...alertsApis,
  ...clientsApis,
  ...githubApis,
  ...gitlabApis,
  ...bitbucketApis,
  ...inventoryApis,
  ...onboardApis,
  ...authApis,
  ...collectorApis,
  ...policyHubApis,
  ...permissionsApis,

  // //TENANT
  // user_admin_login: () => `http://admin.${BASE_URL}/tenant/login`,
  // create_user: () => `http://admin.${BASE_URL}/tenant/clients/`,
  // user_login: () => `${DEV_PREFIX}/security/login`,
};

export * from './alerts/types';
export * from './auth/types';
export * from './clients/types';
export * from './collector/types';
export * from './dev_tools_integration/types';
export * from './inventory/types';
export * from './onboarding/types';
export * from './types';
export * from './services';
