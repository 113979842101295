import React from 'react';

import { SupportedServices } from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { forEach, isEmpty, map } from 'lodash';
import round from 'lodash/round';

import {
  formatBytes,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';

export const renderIncludedResources = ({ row }) => {
  const items: Record<string, any> = {};
  forEach(row?.data_classifications, o => {
    if (o.category === 'include') {
      /*for code repositories, we are sending repo name when all branches need to be scanned*/
      if (
        isEmpty(o.data_sources) ||
        (o.data_sources?.length === 1 && !o.data_sources?.[0]?.includes(':'))
      )
        items[o?.service] = 'ALL';
      else items[o?.service] = o.data_sources?.length;
    }
  });
  return map(items, (value, key) => value)?.join(', ');
};

export const renderDataClassificationName = ({ row }) => {
  const service = row?.data_classifications?.[0]?.service ?? '';
  return (
    <WithResourceIcon
      resourceType={service}
      iconTooltip={<ResourceTypeIconTooltip resourceType={service} />}
      tooltip={row?.name}
    >
      {row?.name}
    </WithResourceIcon>
  );
};

const getScannedText = type => {
  switch (type) {
    case NativeResources.SimpleStorageService:
      return 'objects';
    case NativeResources.GitLabProject:
    case NativeResources.GitHubRepository:
    case NativeResources.BitBucketRepository:
    case SupportedServices.GitLabBranch:
    case SupportedServices.GitHubBranch:
    case SupportedServices.BitBucketBranch:
      return 'files';
    default:
      return 'tables';
  }
};

export const renderObjectsScanned = ({ row }) => {
  return (
    <StackedCell
      upper={
        (row?.scanned_number ?? 0) +
        ' out of ' +
        (row?.total_number ?? 0) +
        ' ' +
        getScannedText(row?.resource_type ?? row?.native_name)
      }
      lower={
        !!row?.total_number
          ? round((row?.scanned_number / row?.total_number) * 100, 2) +
            '% complete'
          : '-'
      }
      showUpperTooltip={false}
      showLowerTooltip={false}
    />
  );
};

export const renderDataScanned = ({ row }) => {
  /*const scannedUnit = 'Bytes';
  const getUnit = (value) => {
    if (value > 1000000)
  }*/
  return (
    <StackedCell
      upper={
        formatBytes(row?.scanned_size) +
        ' out of ' +
        formatBytes(row?.total_size)
      }
      lower={
        row?.total_size
          ? round((row?.scanned_size / row?.total_size) * 100, 2) + '% complete'
          : '-'
      }
      showUpperTooltip={false}
      showLowerTooltip={false}
    />
  );
};

export const renderFileExtensionTags = value => (
  <Stack spacing={1}>
    <HStack spacing={1}>
      {value[0] && <Tag label={value[0]} />}
      {value[1] && <Tag label={value[1]} />}
      {value[2] && <Tag label={value[2]} />}
    </HStack>
    <HStack spacing={1}>
      {value[3] && <Tag label={value[3]} />}
      {value[4] && <Tag label={value[4]} />}
      {value?.length > 5 && <Tag label={'+ ' + (value?.length - 5)} />}
    </HStack>
  </Stack>
);

const renderValuesAsTags = (value, label) => {
  return !!value?.length ? (
    <CustomTooltip
      label={
        <Stack maxW={32}>
          <Box>{label}</Box>
          <Box color="orange" maxW={48}>
            {map(value, o => o).join(', ')}
          </Box>
        </Stack>
      }
    >
      {renderFileExtensionTags(value)}
    </CustomTooltip>
  ) : (
    '-'
  );
};

export const renderFileExtensions = ({ value }) => {
  return renderValuesAsTags(value, 'File Extensions');
};

export const renderDataFound = ({ value }) => {
  return renderValuesAsTags(value, 'Data Found');
};
