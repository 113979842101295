import map from 'lodash/map';

import { SideNavKey, SideNavMenuItemProps } from 'components/Navigation';

export const getKeyMap = (
  items: SideNavMenuItemProps[],
  keyMap: Record<string, any>,
  setKeyMap: (values: Record<string, any>) => void,
  keys: SideNavKey[],
) => {
  map(items, each => {
    let initialKeys: SideNavKey[] = [...keys];
    if (each?.secondary) {
      initialKeys.push(each?.key);
      getKeyMap(each?.secondary, keyMap, setKeyMap, initialKeys);
    } else {
      let leafKeys = keyMap;
      leafKeys[each?.key] = keys;
      setKeyMap(leafKeys);
      initialKeys.pop();
    }
  });
};
