import map from 'lodash/map';

//value is in seconds
export const intervalOptions = [
  {
    label: 'Immediately',
    value: 0,
  },
  {
    label: '15 minutes',
    value: 900,
  },
  {
    label: '30 minutes',
    value: 1800,
  },
  {
    label: '1 hour',
    value: 3600,
  },
  {
    label: '3 hours',
    value: 10800,
  },
  {
    label: '12 hours',
    value: 43200,
  },
  {
    label: '24 hours',
    value: 86400,
  },
  {
    label: '1 week',
    value: 604800,
  },
];

export const alertTriggers = [
  {
    label: 'When an alert is created, or a policy match is found',
    value: 'created',
  },
  {
    label: 'When an alert state changes from open to resolved',
    value: 'resolved',
  },
  {
    label: 'When an alert state changes from open to snoozed',
    value: 'snoozed',
  },
  {
    label: 'When an alert state changes from snoozed to open',
    value: 'unsnoozed',
  },
  /*{ label: 'When an alert is forwarded or assigned', value: 'forwarded' },*/
];

export const quantifierOptions = [
  { label: 'any', value: 'any' },
  { label: 'all', value: 'all' },
  { label: 'none', value: 'none' },
];

export const stringCompareOptions = [
  { label: 'equals', value: 'equals' },
  { label: 'contains', value: 'contains' },
  { label: 'starts with', value: 'starts_with' },
  { label: 'ends with', value: 'ends_with' },
];

export const compareOptions = [
  { label: 'greater than', value: 'greater_than' },
  { label: 'less than', value: 'less_than' },
  { label: 'equal to', value: 'equal_to' },
];

export const severityOptions = [
  { label: 'Critical', value: 'critical' },
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
];

export const removeOption = (option, selected) => {
  return map(selected, each => {
    if (each.value !== option.value) return each;
  });
};
