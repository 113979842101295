import React, { FC } from 'react';

import { Accordion } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons/types';
import { RoleChainingTimeline } from 'components/Visualization/RoleChainingTimeline';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceActionsPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActionsPatternAccordion';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const IAMUserActivity: FC = () => {
  const { resourceType, resourceAccountId, resourceUuid } = useSelector(
    selectActiveResource,
  );

  const columns = [
    { header: 'ACCESS KEY' },
    { header: 'SERVICE ACCESSED' },
    { header: 'TIMESTAMP' },
    { header: 'ACTION PERFORMED' },
    { header: 'SOURCE IP' },
    { header: 'ANOMALY' },
  ];

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <ResourceActionsPatternAccordion />
      <MetaAccordionItem
        iconType={IconTypes.DataScan}
        label={'ACTIONS USING ROLE CHAINING'}
        onClickDownload={() => {}}
        panel={
          <RoleChainingTimeline
            accountId={resourceAccountId}
            entityId={resourceUuid}
            resourceType={resourceType}
          />
        }
        iconBGColor="primary"
      />
      <MetaAccordionItem
        iconType={IconTypes.CloudActivity}
        label={'ACTIONS USING ROLE OVER PAST 30 DAYS'}
        onClickDownload={() => {}}
        panel={<Table columns={columns} data={[]} />}
        iconBGColor="primary"
      />
    </Accordion>
  );
};
