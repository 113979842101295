import React, { FC } from 'react';

import { Flex, HStack, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { CancelButton, PrimaryButton } from 'components/DataEntry';
import { usePageContentContext } from 'components/Layout';
import { BlueprintRules } from 'containers/PolicyHub/Blueprints/Components/BlueprintWizard/CreatePolicyBlueprint/Steps/Policies/BlueprintRules';
import { BlueprintWizardStepProps } from 'containers/PolicyHub/Blueprints/types';

import { CreateFormType } from '../../../../../types';

interface IPolicyRules extends BlueprintWizardStepProps {
  formType: CreateFormType;
  onChange: (ruleIds: string[]) => void;
  selectedRules: string[];
}

export const PolicyRules: FC<IPolicyRules> = props => {
  const { contentHeight } = usePageContentContext();
  const {
    handleBack,
    handleSubmit,
    formType,
    handleCancel,
    onChange,
    selectedRules,
  } = props;

  return (
    <Stack h={contentHeight - 82 + 'px'} spacing={5} mt={4}>
      <BlueprintRules
        setSelectedPolicies={onChange}
        selectedPolicies={selectedRules}
        buildBlueprint={formType === CreateFormType.BuildNew}
      />

      <Flex justify={'space-between'}>
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        <HStack>
          <PrimaryButton onClick={handleBack}>Back</PrimaryButton>
          <PrimaryButton
            onClick={() => handleSubmit(selectedRules)}
            isDisabled={isEmpty(selectedRules)}
          >
            Next
          </PrimaryButton>
        </HStack>
      </Flex>
    </Stack>
  );
};
