import React from 'react';

import { Icon } from '@chakra-ui/react';

export const EcsClusterIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path d="M512,170.7H341.3V0H512V170.7z M384,128h85.3V42.7H384V128z" />
      <path
        d="M277.3,234.7v-128H106.7v298.7h298.7V234.7H277.3z M149.3,149.3h85.3v85.3h-85.3V149.3z M234.7,362.7h-85.3
		v-85.3h85.3V362.7z M362.7,362.7h-85.3v-85.3h85.3V362.7z"
      />
      <path
        d="M469.3,512H42.7C19.1,512,0,492.9,0,469.3V42.7C0,19.1,19.1,0,42.7,0H256v42.7H42.7v426.7h426.7V256H512v213.3
		C512,492.9,492.9,512,469.3,512z"
      />
    </g>
  </Icon>
);
