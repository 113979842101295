import { Box, Flex, Square, Text } from '@chakra-ui/react';
import { RiChat3Line } from 'react-icons/all';

interface HumanRequestProps {
  item: {
    message: string;
  };
}

export const HumanRequest = (props: HumanRequestProps) => {
  const { item } = props;
  return (
    <Box>
      <Flex
        bg={'#fff'}
        display={'inline-flex'}
        py={1}
        px={2}
        align={'center'}
        borderRadius={6}
        boxShadow={'0 1px 2px rgba(0, 0, 0, 0.1)'}
      >
        <Square size={4} mr={1} color={'primary'}>
          <RiChat3Line />
        </Square>
        <Text color={'primary'}>{item.message}</Text>
      </Flex>
    </Box>
  );
};
