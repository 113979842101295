/**
 *
 * Aws On-Boarding
 *
 */

import React, { memo } from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { AwsOnboardingProgress } from './AwsOnboardingProgress';
import { OnboardAWSForm } from './OnboardAWSForm';

export const OnboardAws = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <OnboardAWSForm onOpenProgress={onOpen} />
      {isOpen && <AwsOnboardingProgress onClose={onClose} />}
    </>
  );
});
