import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useCloudPolicyEngineSearchService } from 'services/CloudPolicyEngine/useCloudPolicyEngineSearchService';

import { PostureGraphWithContext } from 'components/Visualization/PixiGraph/components/PostureGraphWithContext';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

interface IInternetAccessMapWrapper {}

export const InternetAccessMapWrapper: FC<IInternetAccessMapWrapper> = () => {
  const { resourceUuid, resourceAccountId } = useSelector(selectActiveResource);
  const { ec2NetworkByUuid } = useCloudPolicyEngineSearchService();

  useEffect(() => {
    ec2NetworkByUuid.sync({
      searchQuery: {
        uuid: resourceUuid,
        account_id: [resourceAccountId],
      },
    });
  }, [resourceAccountId, resourceUuid, ec2NetworkByUuid]);

  return (
    <>
      <PostureGraphWithContext
        data={ec2NetworkByUuid.data ?? {}}
        isLoading={ec2NetworkByUuid.isLoading}
        isLoaded={ec2NetworkByUuid.isSuccess}
      />
    </>
  );
};
