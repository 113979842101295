import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ElastiCacheReplicationGroupIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M403.3,200.2H271.6c-9.3,0-12.7,7.6-12.7,12.7v18.5c0,10.6,7.1,13.4,10.8,13.4H361c4.5,0.8,6.6,4.2,6.6,10.2
		v86.5l0.1,0.9c0.6,2.6,2.6,10.5,12.6,10.5h20.4c3.2,0,11.5-2.3,11.5-12.7V211C412.2,203.6,407.6,200.2,403.3,200.2z"
      />
      <path
        d="M323.8,279.8H192.1c-9.3,0-12.7,7.6-12.7,12.7v18.5c0,10.6,7.1,13.4,10.8,13.4h91.3c4.5,0.8,6.6,4.2,6.6,10.2
		V421l0.1,0.9c0.6,2.6,2.6,10.5,12.6,10.5h20.4c3.2,0,11.5-2.3,11.5-12.7v-129c0.4-4.1-1-6.7-2.3-8.1
		C327.9,280,324.7,279.8,323.8,279.8z"
      />
      <path
        d="M245.7,359.6l-0.9-0.3H112.5c-9.3,0-12.7,7.6-12.7,12.7v18.5c0,10.6,7.1,13.4,10.8,13.4h61.1
		c1.1,0,2,0.1,2.6,0.3c-0.3,0.4-0.8,1.1-1.6,1.9l-63.5,64.8c-0.5,0.4-4.7,4.5-4.8,9.9c-0.1,2.9,1.1,5.7,3.5,8.1l14.6,14.6
		c0.8,0.8,3,2.7,6.5,3.1c3.9,0.4,7.7-1.3,11.4-5l66.2-66.2c0.7-0.7,1.5-1.3,2.1-1.6c0.1,0.4,0.1,1,0.1,1.9v64.9l0.1,0.9
		c0.6,2.6,2.6,10.5,12.6,10.5h20.4c3.2,0,11.5-2.3,11.5-12.7V370.1C253.1,361.9,247.7,360.2,245.7,359.6z"
      />
      <path
        d="M213.5,26.4l55.1,0c2.8,0.5,4.2,2.4,4.2,6.1v51.4l0.1,0.6c0.4,1.5,1.6,6.2,7.6,6.2h12.3c2.8,0,6.9-2,6.9-7.6
		V6.4c0-4.4-2.8-6.4-5.3-6.4h-79.6C209,0,207,4.5,207,7.6v10.9C207,24.8,211.2,26.4,213.5,26.4z"
      />
      <path
        d="M165.4,73.6l55.1,0c2.8,0.5,4.2,2.4,4.2,6.1V131l0.1,0.6c0.4,1.5,1.6,6.2,7.6,6.2h12.3c2.8,0,6.9-2,6.9-7.6
		V53.7c0.2-2.4-0.6-3.9-1.4-4.8c-1.5-1.6-3.4-1.7-4-1.7h-79.6c-5.6,0-7.7,4.5-7.7,7.6v10.9C158.9,72,163.2,73.6,165.4,73.6z"
      />
      <path
        d="M117.3,120.8h36.9c0.7,0,1.2,0.1,1.6,0.2c-0.2,0.3-0.5,0.6-1,1.1l-38.4,38.4c-0.3,0.3-2.9,2.7-2.9,5.9
		c0,1.8,0.7,3.4,2.1,4.8l8.8,8.7c0.5,0.5,4.9,4.5,10.7-1.1l40-39.3c0.5-0.5,1-0.8,1.3-1c0,0.2,0.1,0.6,0.1,1.2v38.5l0.1,0.6
		c0.4,1.5,1.6,6.2,7.6,6.2h12.3c2.8,0,6.9-2,6.9-7.6v-76.6c0-4.9-3.3-5.9-4.8-6.3l-80.2-0.1c-5.6,0-7.7,4.5-7.7,7.6v11
		C110.8,119.2,115.1,120.8,117.3,120.8z"
      />
    </g>
  </Icon>
);
