import { Summary } from '@ariksa/compliance-policies';
import {
  BlueprintApiAddConfigRequest,
  BlueprintApiAddRulesRequest,
  BlueprintApiCreateBlueprintRequest,
  BlueprintApiDeleteBlueprintRequest,
  BlueprintApiDeleteConfigRequest,
  BlueprintApiDeleteRulesRequest,
  BlueprintApiGetBlueprintByIdRequest,
  BlueprintApiGetBlueprintsRequest,
  BlueprintApiGetExceptionsRequest,
  BlueprintApiRemoveExceptionRequest,
  BlueprintApiUpdateBlueprintRequest,
  BlueprintApiUpdateConfigRequest,
  BlueprintConfigRead,
  BlueprintCreate,
  BlueprintExceptionRead,
  BlueprintFull,
  BluePrintsRead,
  CommandsApiGetRemediationByPolicyRequest,
  ComplianceApiGetComplianceSummaryByComplianceRuleRequest,
  ComplianceApiGetComplianceSummaryByResourcesRequest,
  ComplianceApiGetComplianceSummaryByRuleRequest,
  ComplianceApiGetSummaryRequest,
  ComplianceStandardsApiListStandardControlsRequest,
  PageBlueprintExceptionResource,
  PageBluePrintsRead,
  PageResourceCompliance,
  PageRuleCompliance,
  PageRuleReadWithMetadata,
  PageStandardRuleCompliance,
  Remediation,
  RemediationApiAutoRemediationRequest,
  RuleRead,
  RulesApiGetAllComplianceStandardRequest,
  RulesApiGetAllRulesRequest,
  RulesApiGetAllRulesWithoutPaginationRequest,
  StandardRead,
} from '@ariksa/compliance-policies/api';
import {
  AlertWorkflowApiGetWorkflowRequest,
  AlertWorkflowResponse,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction, QueryStatus } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Blueprints container
export const initialState: ContainerState = {
  blueprints: QueryState.init([]),
  blueprint: QueryState.init({} as BluePrintsRead),
  blueprintAction: QueryState.init({}),
  attachEnvironment: QueryState.init(null),
  blueprintSummary: QueryState.init({} as Summary),
  attachedWorkflows: QueryState.init([]),
  blueprintPolicies: PagedQueryState.init([]),
  allBlueprintPolicies: QueryState.init([]),
  rules: QueryState.init([]),
  complianceStandardsOfPolicy: QueryState.init([]),
  complianceControls: PagedQueryState.init([]),
  complianceSubControls: {},
  policiesUnderSubControls: PagedQueryState.init([]),
  resourcesUnderSubControls: PagedQueryState.init([]),
  allComplianceControls: QueryState.init([]),
  allComplianceSubControls: QueryState.init([]),
  remediation: QueryState.init([]),
  autoRemediation: QueryState.init({}),
  blueprintForm: {} as BlueprintCreate,
  selectedBlueprintID: '',

  blueprintException: PagedQueryState.init([]),
  removeException: QueryState.init(null),
  updateRules: QueryState.init(null),
};

const blueprintsSlice = createSlice({
  name: 'blueprints',
  initialState,
  reducers: {
    getBlueprints(
      state,
      action: QueryAction<PageBluePrintsRead, BlueprintApiGetBlueprintsRequest>,
    ) {
      state.blueprints = QueryState.next(state.blueprints, action, {
        mapData: res => res.items,
      });
    },

    getBlueprintByID(
      state,
      action: QueryAction<BlueprintFull, BlueprintApiGetBlueprintByIdRequest>,
    ) {
      state.blueprint = QueryState.next(state.blueprint, action);
      state.blueprintForm.rules = action.payload.data?.rules;
    },

    getBlueprintPolicies(
      state,
      action: QueryAction<PageRuleReadWithMetadata, RulesApiGetAllRulesRequest>,
    ) {
      state.blueprintPolicies = PagedQueryState.next(
        state.blueprintPolicies,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },
    updatePoliciesPagination(state, action: PayloadAction<PageInfo>) {
      state.blueprintPolicies.page.info = action.payload;
    },

    getAllRules(
      state,
      action: QueryAction<
        RuleRead[],
        RulesApiGetAllRulesWithoutPaginationRequest
      >,
    ) {
      state.rules = QueryState.next(state.rules, action);
    },
    getAllBlueprintPolicies(
      state,
      action: QueryAction<
        RuleRead[],
        RulesApiGetAllRulesWithoutPaginationRequest
      >,
    ) {
      state.allBlueprintPolicies = QueryState.next(
        state.allBlueprintPolicies,
        action,
      );
    },

    getAllComplianceControl(
      state,
      action: QueryAction<
        StandardRead[],
        ComplianceStandardsApiListStandardControlsRequest
      >,
    ) {
      state.allComplianceControls = QueryState.next(
        state.allComplianceControls,
        action,
      );
    },
    getAllComplianceSubControl(
      state,
      action: QueryAction<
        StandardRead[],
        ComplianceStandardsApiListStandardControlsRequest
      >,
    ) {
      state.allComplianceSubControls = QueryState.next(
        state.allComplianceSubControls,
        action,
      );
    },

    includeRules(state, action: QueryAction<any, BlueprintApiAddRulesRequest>) {
      state.updateRules = QueryState.next(state.updateRules, action);
    },
    excludeRules(
      state,
      action: QueryAction<any, BlueprintApiDeleteRulesRequest>,
    ) {
      state.updateRules = QueryState.next(state.updateRules, action);
    },

    getComplianceStandardOfPolicy(
      state,
      action: QueryAction<
        Record<string, string[]>,
        RulesApiGetAllComplianceStandardRequest
      >,
    ) {
      state.complianceStandardsOfPolicy = QueryState.next(
        state.complianceStandardsOfPolicy,
        action,
        {
          mapData: res =>
            res?.[
              action.payload.q.getRuleComplianceStandardRequest?.rules?.[0]!
            ] ?? [],
        },
      );
    },

    createBlueprint(
      state,
      action: QueryAction<any, BlueprintApiCreateBlueprintRequest>,
    ) {
      state.blueprintAction = QueryState.next(state.blueprintAction, action);
    },
    updateBlueprint(
      state,
      action: QueryAction<any, BlueprintApiUpdateBlueprintRequest>,
    ) {
      state.blueprintAction = QueryState.next(state.blueprintAction, action);
    },
    deleteBlueprint(
      state,
      action: QueryAction<any, BlueprintApiDeleteBlueprintRequest>,
    ) {
      state.blueprintAction = QueryState.next(state.blueprintAction, action);
    },

    updateBlueprintFormFields(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      state.blueprintForm[action.payload.field] = action.payload.value;
    },
    updateSelectedBlueprintID(state, action: PayloadAction<string>) {
      state.selectedBlueprintID = action.payload;
    },

    resetBlueprintForm(state) {
      state.blueprintForm = initialState.blueprintForm;
    },

    getBlueprintException(
      state,
      action: QueryAction<
        PageBlueprintExceptionResource,
        BlueprintApiGetExceptionsRequest
      >,
    ) {
      state.blueprintException = PagedQueryState.next(
        state.blueprintException,
        action,
        {
          mapTotalCount: r => r.total ?? 0,
          mapData: r => r.items,
        },
      );
    },

    removeException(
      state,
      action: QueryAction<
        BlueprintExceptionRead,
        BlueprintApiRemoveExceptionRequest
      >,
    ) {
      state.removeException = QueryState.next(state.removeException, action);
    },

    getBlueprintSummary(
      state,
      action: QueryAction<Summary, ComplianceApiGetSummaryRequest>,
    ) {
      state.blueprintSummary = QueryState.next(state.blueprintSummary, action);
    },

    attachEnvironment(
      state,
      action: QueryAction<any, BlueprintApiAddConfigRequest>,
    ) {
      state.attachEnvironment = QueryState.next(
        state.attachEnvironment,
        action,
      );
    },
    detachEnvironment(
      state,
      action: QueryAction<
        BlueprintApiDeleteConfigRequest,
        BlueprintApiDeleteConfigRequest
      >,
    ) {
      state.attachEnvironment = QueryState.next(
        state.attachEnvironment,
        action,
      );
    },
    updateAlertingConfig(
      state,
      action: QueryAction<BlueprintConfigRead, BlueprintApiUpdateConfigRequest>,
    ) {
      state.attachEnvironment = QueryState.next(
        state.attachEnvironment,
        action,
      );
    },

    getAttachedWorkflows(
      state,
      action: QueryAction<
        AlertWorkflowResponse[],
        AlertWorkflowApiGetWorkflowRequest
      >,
    ) {
      state.attachedWorkflows = QueryState.next(
        state.attachedWorkflows,
        action,
      );
    },

    getComplianceControls(
      state,
      action: QueryAction<
        PageStandardRuleCompliance,
        ComplianceApiGetComplianceSummaryByComplianceRuleRequest
      >,
    ) {
      state.complianceControls = PagedQueryState.next(
        state.complianceControls,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },
    updateComplianceControlsPagination(state, action: PayloadAction<PageInfo>) {
      state.complianceControls.page.info = action.payload;
    },

    getComplianceSubControls(
      state,
      actions: QueryAction<
        PageStandardRuleCompliance,
        ComplianceApiGetComplianceSummaryByComplianceRuleRequest
      >,
    ) {
      const control = actions.payload.q.control!;
      if (!state.complianceSubControls[control]) {
        state.complianceSubControls[control] = QueryState.init([], {
          status: QueryStatus.pending,
        });
        return;
      } else {
        state.complianceSubControls[control] = QueryState.next(
          state.complianceSubControls[control],
          actions,
          {
            mapData: d => d.items,
          },
        );
      }
    },

    getPoliciesUnderSubControls(
      state,
      action: QueryAction<
        PageRuleCompliance,
        ComplianceApiGetComplianceSummaryByRuleRequest
      >,
    ) {
      state.policiesUnderSubControls = PagedQueryState.next(
        state.policiesUnderSubControls,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },
    updatePoliciesUnderSubControlsPagination(
      state,
      action: PayloadAction<PageInfo>,
    ) {
      state.policiesUnderSubControls.page.info = action.payload;
    },

    getResourcesUnderSubControlPolicy(
      state,
      action: QueryAction<
        PageResourceCompliance,
        ComplianceApiGetComplianceSummaryByResourcesRequest
      >,
    ) {
      state.resourcesUnderSubControls = PagedQueryState.next(
        state.resourcesUnderSubControls,
        action,
        { mapData: r => r.items, mapTotalCount: r => r.total ?? 0 },
      );
    },
    updateResourcesUnderSubControlPolicyPagination(
      state,
      action: PayloadAction<PageInfo>,
    ) {
      state.resourcesUnderSubControls.page.info = action.payload;
    },

    getAvailableRemediation(
      state,
      action: QueryAction<
        Remediation[],
        CommandsApiGetRemediationByPolicyRequest
      >,
    ) {
      state.remediation = QueryState.next(state.remediation, action);
    },
    autoRemediate(
      state,
      action: QueryAction<any, RemediationApiAutoRemediationRequest>,
    ) {
      state.autoRemediation = QueryState.next(state.autoRemediation, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = blueprintsSlice;
