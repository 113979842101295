import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UUID } from 'api/types';

import { actions } from '../../slice';

import { ContextWizard } from './index';

interface Props {}

export const EditContext: FC<Props> = () => {
  const params = useParams<UUID>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.uuid) {
      dispatch(actions.getContextById({ q: { labelId: params.uuid } }));
    }
  }, [params, dispatch]);

  return <ContextWizard actionType="Update" />;
};
