import { RediscoverAWSAccountParams, UUID } from 'api/types';

import { ONBOARDING_SERVICE } from '../service_urls';
import {
  queryStringFromObject,
  withPrefixArgs,
  withPrefixNoArgs,
} from '../utils';

import {
  ExternalId,
  ManagementUUID,
  MemberAccountUUID,
  GetCloudAccountsParams,
  AwsOnboardURLParamsV3,
} from './types';

const PREFIX = ONBOARDING_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
const withNoArgs = withPrefixNoArgs(PREFIX);

export const onboardApis = {
  // cloud-account
  onboardCloudAccount: withNoArgs(`/account/`),
  getCloudAccount: withArgs<UUID>(`/account/{uuid}`),
  getCloudAccounts: withArgs<GetCloudAccountsParams>(`/account/`),
  updateCloudAccount: withArgs<UUID>(`/account/{uuid}`),
  deleteCloudAccount: withArgs<UUID>(`/account/{uuid}`),
  refreshCloudAccount: withArgs<UUID>(`/account/{uuid}`),
  getCloudAccountConfig: withArgs(`/account/config/`),
  getSubCloudAccounts: withArgs<ManagementUUID>(
    `/account/sub-accounts/{management_uuid}`,
  ),
  onboardMemberAccounts: withArgs<UUID>(`/account/sub-accounts/{uuid}`),

  // aws
  getAwsConfig: withNoArgs(`/awsconfig/cfstemplate/`),
  getSQSMessage: withArgs<ExternalId>(`/awsmessage/sqs/{external_id}/`),
  onboardAWSAccount: withNoArgs(`/awsonboarding/`),
  getAWSAccount: withArgs<UUID>(`/awsonboarding/{uuid}`),
  deleteAWSAccount: withArgs<UUID>(`/awsonboarding/{uuid}`),
  getAWSAccounts: withNoArgs(`/awsonboarding/`),
  awsOnboardRedirectURL: (queryParams: AwsOnboardURLParamsV3) =>
    `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review${queryStringFromObject(
      queryParams,
      { filterEmpty: false, useSnakeCase: false },
    )}`,
  updateAWSAccount: withArgs<UUID>('/awsonboarding/{uuid}'),

  //aws member accounts
  getMemberAccounts: withArgs<ManagementUUID>(`/awsmember/{management_uuid}`),
  rediscoverMemberInventory: withArgs<MemberAccountUUID>(
    `/awsmember/refresh/{member_account_uuid}`,
  ),

  //rediscover inventory
  rediscoverInventory: withArgs<UUID>('/inventory_refresh/{uuid}'),

  //rediscovery
  rediscoverAccount: withArgs<RediscoverAWSAccountParams>(
    '/account/discover-inventory/{uuid}',
  ),
};
