import React from 'react';

import { Box } from '@chakra-ui/react';

import { renderSeverityBubble, WithResourceIcon } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  renderCompliantEntities,
  renderExceptionsHeader,
  renderTableHeaderWithTooltip,
} from 'containers/Compliance/Components/ComplianceTables/utils';

// These columns are being used on resourcePage.tsx
export const resourceTypesColumnsForMainTable = [
  {
    header: 'Name',
    accessor: 'name',
    align: 'left',
  },
  {
    header: 'Cloud',
    accessor: 'cloud_provider',
    align: 'left',
  },
  {
    header: 'Cloud ID',
    accessor: 'cloud_resource_id',
    align: 'left',
  },
  {
    header: 'Account',
    accessor: 'account_id',
    render: () => '-',
  },
];

export const resourceTypeRulesColumns = [
  {
    header: 'Rule Description',
    accessor: 'description',
    styles: {
      textAlign: 'left',
      'max-width': '400px',
      cell: {
        textAlign: 'left',
        'max-width': '400px',
      },
    },
  },
  {
    header: 'Rule Id',
    accessor: 'name',
    align: 'left',
  },
  {
    header: 'Sub-Control(s)',
    accessor: '',
    render: () => '-',
  },
];

export const rulesColumns = [
  {
    header: <Box pl="thLeftPaddingWithIcon">Description</Box>,
    accessor: 'description',
    render: ({ value }) => (
      <WithResourceIcon resourceType={''}>
        <CustomTooltip label={value}>{value}</CustomTooltip>
      </WithResourceIcon>
    ),
  },
  {
    header: 'Severity',
    accessor: 'severity',
    sortKey: 'severity',
    align: 'left',
    render: renderSeverityBubble,
    styles: { width: '150px', cell: { maxWidth: '150px' } },
  },
  {
    header: 'Entities',
    accessor: 'check_stats.total_entities',
    styles: { width: '60px', cell: { maxWidth: '60px' } },
  },
  {
    header: renderExceptionsHeader,
    accessor: 'exception',
    render: ({ row }) => row?.exception ?? 0,
  },
  {
    header: renderTableHeaderWithTooltip(
      'Compliant entities',
      'Entities that passed this policy check',
      'left',
    ),
    accessor: 'check_stats',
    render: renderCompliantEntities,
    styles: { width: '250px', cell: { maxWidth: '250px' } },
  },
];

export const complianceSeverityOptionsWithAll = [
  { label: 'All severities', value: undefined },
  { label: 'Critical', value: 'critical' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
];
