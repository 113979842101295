import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { colors } from 'components/Visualization/PixiGraph/style';

export class CommonEdge {
  static render(edge: AGraphEdge, g: Graphics, opts: Record<string, any> = {}) {
    const { start, end } = edge;
    const { color = 0xaaaaaa } = opts;
    // console.log(edge);

    g.lineStyle(2, color, 1);
    g.moveTo(start.x, start.y);
    g.bezierCurveTo(
      (end.x + start.x) / 2,
      start.y,
      start.x + (end.x - start.x) / 3,
      end.y,
      end.x,
      end.y,
    );
    g.beginFill(0xf5bb05, 0.5);
    g.endFill();
  }

  static renderActive(edge: AGraphEdge, g: Graphics) {
    CommonEdge.render(edge, g, { color: colors.primary });
  }

  static renderHover(edge: AGraphEdge, g: Graphics) {
    CommonEdge.render(edge, g, { color: colors.primary });
  }
}
