import { Days, ReportFormat } from '@ariksa/reporting';
import {
  Months,
  ReportingTargets,
  ReportTypes,
  ScheduleInterval,
} from '@ariksa/reporting';
import { filter, map } from 'lodash';

import { toTitleCase } from 'utils/string';

export const frequencyOptions = [
  { label: 'Daily', value: ScheduleInterval.Daily },
  { label: 'Weekly', value: ScheduleInterval.Weekly },
  { label: 'Monthly', value: ScheduleInterval.Monthly },
  { label: 'Yearly', value: ScheduleInterval.Yearly },
];

export const months = [
  Months.January,
  Months.February,
  Months.March,
  Months.April,
  Months.May,
  Months.June,
  Months.July,
  Months.August,
  Months.September,
  Months.October,
  Months.November,
  Months.December,
];

export const reportTypeOptions = map(
  filter(ReportTypes, o => o !== ReportTypes.MachineAccessReview),
  o => ({
    label: toTitleCase(o),
    value: o,
  }),
);

export const weekNumberOptions = [
  { label: '1st', value: 1 },
  { label: '2nd', value: 2 },
  { label: '3rd', value: 3 },
  { label: '4th', value: 4 },
];

export const weekDaysOptions = [
  { label: 'Monday', value: Days.Monday },
  { label: 'Tuesday', value: Days.Tuesday },
  { label: 'Wednesday', value: Days.Wednesday },
  { label: 'Thursday', value: Days.Thursday },
  { label: 'Friday', value: Days.Friday },
  { label: 'Saturday', value: Days.Saturday },
  { label: 'Sunday', value: Days.Sunday },
];

export const targetOptions = [
  { label: 'Email', value: ReportingTargets.Email },
  { label: 'SIEM', value: ReportingTargets.ElasticSearch },
];

export const reportFormatOptions = [
  { label: 'CSV', value: ReportFormat.Csv },
  { label: 'PDF', value: ReportFormat.Pdf },
  { label: 'None', value: undefined },
];
