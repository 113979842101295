import React, { ReactNode } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { Box, Center, HStack, Flex, Stack } from '@chakra-ui/react';
import { entries, isArray, map, slice } from 'lodash';
import { Dict } from 'types/utils';

import {
  getCloudIcon,
  AriksaIcon,
  renderSeverityCountBubble,
  renderStackedCloudNameAndIcon,
  WithResourceIcon,
  Tag,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { StackedCell } from 'components/DataDisplay/Utils';
import { CheckmarkCircleOutlineIcon } from 'components/Icons';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';
import { formatDate } from 'utils/date';

export const getResourceInsightColumns = (
  resourceType: NativeResources,
  opts: any = {},
) => {
  const { withPolicy = false } = opts;

  if (withPolicy) {
    return [
      nameAndCloudNativeService,
      accountWithSourceIcon,
      vpcRegion,
      policies,
      tags,
      owner,
      lastActivity,
      findings,
    ];
  }

  switch (resourceType) {
    case 'VirtualPrivateCloud':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpc,
        vpcPublicSubnet,
        tags,
        owner,
        findings,
      ];
    case 'Subnet':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpc,
        publicSubnet,
        tags,
        owner,
        lastActivity,
      ];
    case 'TransitGatewayAttachment':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        region,
        attachment,
        tags,
        owner,
        findings,
      ];
    case 'TransitGateway':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpcRegion,
        asn,
        shared,
        tags,
        owner,
        status,
        lastActivity,
      ];
    case 'IAMUser':
      return [
        userName,
        accountWithSourceIcon,
        console,
        groups,
        roles,
        excessPermissions,
        // persona,
        lastActivity,
        { header: 'Findings' },
      ];
    case 'IAMGroup':
      return [
        groupName,
        accountWithSourceIcon,
        policies,
        users,
        owner,
        lastActivity,
        findings,
      ];
    case 'EC2Instance':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpcSubnet,
        publicPrivateIP,
        roles,
        excessPermissions,
        tags,
        owner,
        lastActivity,
        findings,
      ];
    case 'InternetGateway':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpcRegion,
        tags,
        owner,
        lastActivity,
      ];
    case 'SecurityGroup':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpc,
        allowInternet,
        ingressPorts,
        egressPorts,
        tags,
        owner,
        lastActivity,
        findings,
      ];
    case 'NetworkACL':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        allowInternet,
        allowDenyRules,
        ingressPorts,
        egressPorts,
        tags,
        owner,
        lastActivity,
        findings,
      ];
    case 'NATGateway':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpcRegion,
        subnetIP,
        internetGW,
        tags,
        owner,
        lastActivity,
      ];
    case 'SimpleStorageService':
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        region,
        // size,
        resourcePolicy,
        tags,
        owner,
        lastActivity,
        findings,
      ];
    default:
      return [
        nameAndCloudNativeService,
        accountWithSourceIcon,
        vpcRegion,
        ...([
          'SimpleQueueService',
          'SimpleNotificationService',
          'LambdaFunction',
          'KeyManagementService',
          'Secret',
          'ElasticFileSystem',
        ].includes(resourceType)
          ? [resourcePolicy]
          : []),
        tags,
        owner,
        lastActivity,
        findings,
      ];
  }
};

export const userName: TableColumnProps = {
  header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
  accessor: 'name',
  render: ({ value }) => (
    <HStack>
      <Box w={'32px'} h={'32px'}>
        <AriksaIcon type={'User'} />
      </Box>
      <Center>{value ?? '-'}</Center>
    </HStack>
  ),
};

export const findings: TableColumnProps = {
  header: 'Findings',
  accessor: 'severity',
  align: 'center',
  render: ({ row }) => {
    return (
      <HStack spacing={2} px={6}>
        {entries({
          critical: row.severity.CRITICAL,
          medium: row.severity.MEDIUM,
          low: row.severity.LOW,
        }).map(
          ([v, k]) => !!k && <Flex>{renderSeverityCountBubble(v, k)}</Flex>,
        )}
      </HStack>
    );
  },
};

export const groupName: TableColumnProps = {
  header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
  accessor: 'name',
  render: ({ value }) => {
    return (
      <HStack>
        <Center w={'32px'} h={'32px'}>
          <AriksaIcon type={'Group'} />
        </Center>
        <Center>{value ?? '-'}</Center>
      </HStack>
    );
  },
};

export const accountWithSourceIcon: TableColumnProps = {
  header: 'Account',
  render: ({ row }) => {
    return (
      <Center>
        <HStack>
          <Box>{getCloudIcon('aws')}</Box>
          <CloudAccountName accountId={row.account ?? '-'} />
        </HStack>
      </Center>
    );
  },
};

export const nameAndCloudNativeService: TableColumnProps = {
  header: <Box pl="thLeftPaddingWithIcon">Name / Service</Box>,
  accessor: 'name',
  render: ({ row }) => {
    return (
      <HStack align={'center'}>
        <Center w={'32px'} h={'32px'}>
          <AriksaIcon type={row.native_name} />
        </Center>

        <StackedCell upper={row.name} lower={row.service} />
        {/*<Box>*/}
        {/*  {limitedString(row.name ?? '-', 10)}/{row.service ?? '-'}*/}
        {/*</Box>*/}
      </HStack>
    );
  },
};

export const policies: TableColumnProps = {
  header: 'Policies',
  accessor: 'policy_count',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const users: TableColumnProps = {
  header: 'Users',
  accessor: 'user_count',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const owner: TableColumnProps = {
  header: 'Owner',
  accessor: 'owner',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const lastActivity: TableColumnProps = {
  header: 'Last Activity',
  accessor: 'last_activity',
  render: ({ value }) => {
    return <Center>{value ? formatDate(value) : '-'}</Center>;
  },
};

export const groups: TableColumnProps = {
  header: 'Groups',
  accessor: 'group_count',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const roles: TableColumnProps = {
  header: 'Roles',
  render: ({ row }) => {
    return (
      <Center>{`${
        parseInt(row.total_role_count ?? '0') -
        parseInt(row.used_role_count ?? '0')
      } / ${row.total_role_count ?? '0'}`}</Center>
    );
  },
};
export const publicPrivateIP: TableColumnProps = {
  header: 'Public IP / Private IP',
  render: ({ row }) => {
    return (
      <Center>
        <StackedCell
          upper={row.public_ip_address ?? '-'}
          lower={row.private_ip_address ?? '-'}
        />
      </Center>
    );
  },
};

export const console: TableColumnProps = {
  header: 'Console',
  accessor: 'has_console_access',
  render: ({ value }) => {
    return (
      <Center>
        <Center w={'16px'}>
          {value ? <CheckmarkCircleOutlineIcon /> : '-'}
        </Center>
      </Center>
    );
  },
};

export const persona: TableColumnProps = {
  header: 'Persona',
  accessor: 'persona',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const status: TableColumnProps = {
  header: 'Status',
  accessor: 'state',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const tags: TableColumnProps = {
  header: 'Tags',
  accessor: 'tags',
  render: ({ row }) => {
    return <Center>{row.tags?.length ?? '-'}</Center>;
  },
};

export const resourcePolicy: TableColumnProps = {
  header: 'Resource Policy',
  accessor: 'has_resource_policy',
  render: ({ row }) => {
    return <Center>{row.has_resource_policy ? 'Yes' : 'No'}</Center>;
  },
};

export const size: TableColumnProps = {
  header: 'Size',
  accessor: 'size',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const publicSubnet: TableColumnProps = {
  header: 'Public Subnet',
  accessor: 'is_subnet_public',
  render: ({ row }) => {
    return <Center>{row.is_subnet_public ? 'Yes' : 'No'}</Center>;
  },
};

export const vpcPublicSubnet: TableColumnProps = {
  header: 'Public Subnet',
  accessor: 'has_public_subnet',
  render: ({ row }) => {
    return <Center>{row.has_public_subnet ? 'Yes' : 'No'}</Center>;
  },
};

export const shared: TableColumnProps = {
  header: 'Shared',
  accessor: 'shared',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const asn: TableColumnProps = {
  header: 'ASN',
  accessor: 'amazon_side_asn',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const attachment: TableColumnProps = {
  header: 'Attachment',
  accessor: 'attachment',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const region: TableColumnProps = {
  header: 'Region',
  accessor: 'region',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const excessPermissions: TableColumnProps = {
  header: 'Excess Permissions',
  accessor: '',
  render: ({ row }) => {
    return (
      <Center>{`${
        parseInt(row.total_permission_count ?? '0') -
        parseInt(row.used_permission_count ?? '0')
      } / ${row.total_permission_count ?? '0'}`}</Center>
    );
  },
};

export const vpcSubnet: TableColumnProps = {
  header: 'VPC / Subnet',
  render: ({ row }) => {
    return (
      <Center>
        <StackedCell
          upper={row.vpc_id ?? row.vpc ?? '-'}
          lower={row.subnet ?? '-'}
        />
      </Center>
    );
  },
};

export const vpc: TableColumnProps = {
  header: 'VPC',
  accessor: 'vpc',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const allowInternet: TableColumnProps = {
  header: 'Allow Internet',
  accessor: 'allow_internet',
  render: ({ row }) => {
    return <Center>{row.allow_internet ? 'Yes' : 'No'}</Center>;
  },
};

export const ingressPorts: TableColumnProps = {
  header: 'Ingress Ports',
  accessor: 'ingress_ports',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const allowDenyRules: TableColumnProps = {
  header: 'Allow / Deny',
  render: ({ row }) => {
    return (
      <StackedCell
        upper={row.allow_rules ?? '-'}
        lower={row.deny_rules ?? '-'}
      />
    );
  },
};

export const egressPorts: TableColumnProps = {
  header: 'Egress Ports',
  accessor: 'egress_ports',
  render: ({ value }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const vpcRegion: TableColumnProps = {
  header: 'VPC / Region',
  render: ({ row }) => {
    return (
      <Center>
        <StackedCell upper={row.vpc ?? '-'} lower={row.region ?? '-'} />
      </Center>
    );
  },
};

export const subnetIP: TableColumnProps = {
  header: 'Subnet / IP',
  render: ({ row }) => {
    return (
      <Center>
        <StackedCell upper={row.subnet ?? '-'} lower={row.public_ip ?? '-'} />
      </Center>
    );
  },
};

export const internetGW: TableColumnProps = {
  header: 'Internet GW',
  accessor: 'internet_gateway',
  render: ({ value, row }) => {
    return <Center>{value ?? '-'}</Center>;
  },
};

export const createColumn = (
  col,
  source: string,
  index: number,
): TableColumnProps | null => {
  if (col.key === 'total_findings') {
    return {
      header: col.label,
      accessor: col.key,
      render: ({ value }) => {
        return <Box color="gray.300">{value[0] ?? '-'}</Box>;
      },
    };
  }

  if (col.key === 'resource_count') {
    return {
      header: col.label,
      accessor: col.key,
      render: ({ value }) => {
        return (
          <Center>
            <Box px={4} py={1} borderRadius={4}>
              {value[0] ?? '-'}
            </Box>
          </Center>
        );
      },
    };
  }

  if (col.key === 'cloud') {
    return {
      header: col.label,
      accessor: col.key,
      align: 'left',
      render: ({ value, row }) => {
        // return <StackedCell upper={} lower={}/>
        return renderStackedCloudNameAndIcon(
          value[0]?.toLowerCase() ?? '-',
          false,
          row.deployed_in?.[0] ?? '',
        );
      },
    };
  }

  if (col.key === 'labels') {
    return {
      header: col.label,
      accessor: col.key,
      align: 'left',
      render: ({ value }) => {
        return (
          <CustomTooltip
            label={
              <Stack>
                {map(slice(value, 1), o => (
                  <Box>{o as string}</Box>
                ))}
              </Stack>
            }
          >
            <HStack>
              <HStack>
                {map(slice(value, 0, 1), o => (
                  <Tag label={o as string} />
                ))}
              </HStack>
              <Tag label={'+ ' + (value?.length - 1)} />
            </HStack>
          </CustomTooltip>
        );
      },
    };
  }

  if (col.label === source) {
    return {
      header: col.label,
      accessor: col.key,
      align: 'left',
      render: ({ value }) => {
        return (
          <WithResourceIcon resourceType={source}>
            <StackedCell upper={value[0] ?? '-'} lower={value[1] ?? '-'} />
          </WithResourceIcon>
        );
      },
    };
  }

  if (index === 0) {
    return {
      header: col.label,
      accessor: col.key,
      align: 'left',
      render: ({ value, row }) => {
        return (
          <WithResourceIcon resourceType={row.source_native ?? ''}>
            <StackedCell upper={value[0] ?? '-'} lower={value[1] ?? '-'} />
          </WithResourceIcon>
        );
      },
    };
  }

  if (col.key === 'deployed_in') {
    return null;
  }

  return {
    header: col.label,
    accessor: col.key,
    align: 'left',
    render: ({ value }) => {
      if (value?.length === 1) {
        return value[0] ?? '-';
      } else {
        return <StackedCell upper={value[0] ?? '-'} lower={value[1] ?? '-'} />;
      }
    },
  };
};
