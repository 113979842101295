import React from 'react';

import {
  AwsIcon,
  AzureActiveDirectoryIcon,
  AzureIcon,
  BitbucketIcon,
  GithubIcon,
  GitLabIcon,
  GoogleCloudIcon,
  OktaIcon,
  SnowflakeIcon,
} from 'components/Icons';

export const groupByOptions = [
  { label: 'Group by: Cloud', value: 'cloud_provider' },
  { label: 'Group by: Category', value: 'category' },
  { label: 'Group by: None', value: undefined },
];

export const cloudProviderOptions = [
  { label: 'AWS', value: 'AWS', icon: <AwsIcon /> },
  { label: 'GCP', value: 'GCP', icon: <GoogleCloudIcon /> },
  {
    label: 'Azure',
    value: 'Azure',
    icon: <AzureIcon />,
  },
  /*{
    label: 'AzureAD',
    value: 'AzureAd',
    icon: <AzureActiveDirectoryIcon />,
  },*/
  { label: 'Okta', value: 'Okta', icon: <OktaIcon /> },
  {
    label: 'Snowflake',
    value: 'Snowflake',
    icon: <SnowflakeIcon />,
  },
  { label: 'GitLab', value: 'GitLab', icon: <GitLabIcon /> },
  { label: 'GitHub', value: 'GitHub', icon: <GithubIcon /> },
  {
    label: 'Atlassian BitBucket',
    value: 'BitBucket',
    icon: <BitbucketIcon />,
  },
];

export const sortByOptions = [
  { label: 'Alphabetically', value: 'Alphabetically' },
  { label: 'Count', value: 'Count' },
];
