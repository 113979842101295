import React, { useEffect, useState } from 'react';

import { Stack, Flex, Text, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmationInput,
  DetailsIdentifier,
  H4,
  PageHeaderWithIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, DeleteButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import { actions } from './slice';

interface Props {}

export const DeleteClientModal: React.FC<Props> = props => {
  const {
    isOpenDeleteClientModal,
    onCloseDeleteClientModal,
    currentRecord,
    onConfirmDeleteClient,
  } = useIntegrationsContext();
  const dispatch = useDispatch();
  const [command, setCommand] = useState('');
  const { workflows, client } = useSelector(selectIntegrations);

  useEffect(() => {
    dispatch(
      actions.getAlertWorkflowAttachedToClient({
        q: { clientId: currentRecord?.uuid },
      }),
    );
  }, [dispatch, currentRecord]);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
    },
  ];

  return (
    <Modal
      isOpen={isOpenDeleteClientModal}
      onClose={onCloseDeleteClientModal}
      header={
        <PageHeaderWithIcon
          label="Delete Client"
          icon={<TrashIcon />}
          iconBgColor="red"
          reversed
        />
      }
      styles={{
        modal: { size: '3xl' },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onCloseDeleteClientModal}>Cancel</CancelButton>
          <DeleteButton
            onClick={() =>
              onConfirmDeleteClient(
                currentRecord?.uuid,
                currentRecord?.client_name,
              )
            }
            isDisabled={command !== currentRecord?.name}
            isLoading={client.isLoading}
            label={'Delete client'}
            leftIcon={<TrashIcon />}
          />
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <Stack spacing={3}>
            <DetailsIdentifier
              label="Client Name"
              value={currentRecord?.name}
            />
          </Stack>
          {!!workflows.data?.length && !workflows.isLoading && (
            <Stack w="full" h="230px">
              <H4>Attached Alert Workflows</H4>
              <Table
                columns={columns}
                data={workflows.data}
                isLoading={workflows.isLoading}
              />
              <Text>
                Please detach client from all above mentioned alert workflows to
                continue delete.
              </Text>
            </Stack>
          )}
          {!workflows.data?.length && !workflows.isLoading && (
            <ConfirmationInput
              confirmationText={currentRecord?.name}
              label={<Text>Please type in the client name and delete.</Text>}
              command={command}
              setCommand={setCommand}
            />
          )}
          {workflows.isLoading && (
            <Center pt={12}>
              <CustomSpinner />
            </Center>
          )}
        </Stack>
      }
    />
  );
};
