import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.environment || initialState;

export const selectEnvironment = createSelector(
  [selectDomain],
  selectEnvironmentState => selectEnvironmentState,
);
