import { BoxProps, Stack } from '@chakra-ui/react';

import { Card } from 'components/DataDisplay';

interface SecurityGraphSidebarProps extends BoxProps {}

export const SecurityGraphSidebar = (props: SecurityGraphSidebarProps) => {
  const { children } = props;

  return (
    <Card styles={{ card: { p: 0 } }}>
      <Stack w="full">{children}</Stack>
    </Card>
  );
};
