import React, { FC } from 'react';

import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { DateRange } from 'react-day-picker';

import { CancelButton, PrimaryButton } from 'app/components';
import { CustomDayPicker } from 'components/DataEntry/Date';

interface IDayPicketModal {
  label?: string;
  onSelect(dates?: DateRange);
}

export const DayPicketModal: FC<IDayPicketModal> = props => {
  const { label, onSelect } = props;
  const dayPicker = useDisclosure();
  const [days, setDays] = React.useState<DateRange | undefined>(
    {} as DateRange,
  );

  return (
    <Box position="relative">
      <CustomDayPicker
        popoverDisclosure={dayPicker}
        iconButton
        label={label}
        header={'Select date range'}
        dateProps={{
          mode: 'range',
          toDate: new Date(),
          selected: days,
          onSelect: setDays,
        }}
        styles={{
          popover: {
            popover: { placement: 'left', isLazy: true },
            content: { zIndex: 900 },
            footer: { border: 'none' },
          },
        }}
        footer={
          <HStack justify={'space-between'}>
            <CancelButton onClick={dayPicker.onClose} />
            <HStack>
              <PrimaryButton
                onClick={() => {
                  setDays(undefined);
                  onSelect(undefined);
                }}
              >
                Reset
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  onSelect(days);
                  dayPicker.onClose();
                }}
              >
                Apply
              </PrimaryButton>
            </HStack>
          </HStack>
        }
      />
    </Box>
  );
};
