import React, { FC, useEffect, useState } from 'react';

import { Box, Center, Flex, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConfirmationInput,
  DetailsIdentifier,
  H4,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CancelButton, DeleteButton } from 'components/DataEntry';
import { DisconnectIcon, TrashIcon } from 'components/Icons';
import { Modal } from 'components/Overlay';
import { attachedEnvironmentsColumns } from 'containers/Setup/CloudAccounts/Components/utils';
import { selectRoles } from 'containers/Setup/Roles/selectors';
import { actions } from 'containers/Setup/Roles/slice';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

interface IDeleteRoleModal {
  isOpen: boolean;
  onClose: () => void;
  role: any;
}

export const DeleteRoleModal: FC<IDeleteRoleModal> = props => {
  const { isOpen, onClose, role } = props;
  const [roleName, setRoleName] = useState('');
  const { deleteRole } = useSelector(selectRoles);
  const { success: deleted, loading } = useTrackApiCall(deleteRole);
  const dispatch = useDispatch();

  const onClickDelete = () => {
    dispatch(actions.deleteRole(role));
  };

  useEffect(() => {
    if (deleted) {
      onClose();
    }
  }, [deleted, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={'Delete Role'}
      styles={{
        modal: { size: '2xl' },
      }}
      footer={
        <Flex justifyContent={'space-between'} w="full">
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <DeleteButton
            onClick={onClickDelete}
            isDisabled={roleName !== role?.name}
            label={'Delete'}
            leftIcon={<TrashIcon />}
            isLoading={loading}
          />
        </Flex>
      }
      body={
        <Stack w="full" h="full" spacing={5}>
          <Stack spacing={3}>
            <DetailsIdentifier label="Role Name" value={role?.name} />
          </Stack>

          <ConfirmationInput
            confirmationText={role?.name}
            label={<Text>Please type in the role name and {'delete'}.</Text>}
            command={roleName}
            setCommand={setRoleName}
          />
        </Stack>
      }
    />
  );
};
