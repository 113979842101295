import React, { FC } from 'react';

import { Grid, Stack, Accordion, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AssociatedIDs,
  Default,
  Description,
  SubnetIDs,
} from '../../../Components/MetadataField/MetaGridFields';

export const NetworkACLMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  const egress = resource?.config?.Entries?.filter(e => e?.Egress);
  const ingress = resource?.config?.Entries?.filter(e => !e?.Egress);

  const columns = [
    { header: 'Cidr Block', accessor: 'CidrBlock' },
    { header: 'Protocol', accessor: 'Protocol' },
    { header: 'Rule Action', accessor: 'RuleAction' },
    { header: 'Rule Number', accessor: 'RuleNumber' },
  ];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields availabilityZone="None" />
        {Description(insight)}
        {Default(insight)}
        {SubnetIDs(resource)}
        {AssociatedIDs(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={egress} />}
              iconType={IconTypes.Egress}
              label="Egress Rules"
              totalCount={egress?.length}
              height="300px"
            />
            <MetaAccordionItem
              panel={<Table columns={columns} data={ingress} />}
              iconType={IconTypes.Ingress}
              label="Ingress Rules"
              totalCount={ingress?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
