import React, { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PieChart } from 'components/Visualization';
import { actions } from 'containers/Compliance/slice';

import { selectCompliance } from '../../selectors';

export const ComplianceFailedChecks: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  const [data, setData] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (summary?.data?.check_stats?.failed) {
      let failed = summary.data.check_stats.failed;
      setData([
        { name: 'Critical', value: failed?.['CRITICAL'] },
        { name: 'High', value: failed?.['HIGH'] },
        { name: 'Medium', value: failed?.['MEDIUM'] },
        { name: 'Low', value: failed?.['LOW'] },
      ]);
    }
  }, [summary]);

  return (
    <Box w="full" h="full">
      <PieChart
        isLoading={summary.isLoading || blueprints.isLoading}
        data={data}
        dataKey="value"
        total={formatNumber(
          data?.reduce((s, el) => s + el?.value || 0, 0),
          1,
        )}
        color={[
          customTheme.colors.critical,
          customTheme.colors.high,
          customTheme.colors.medium,
          customTheme.colors.low,
        ]}
        activeShape={false}
        outerRadius="85%"
        innerRadius="76%"
        styles={{
          pie: { cx: isEmpty(data) ? '50%' : '35%' },
          legend: {
            onClick: payload => {
              dispatch(actions.changeSelectedTab(1));
              dispatch(
                actions.changeResourceTypeSeverity(
                  payload?.value?.toLowerCase(),
                ),
              );
            },
            wrapperStyle: {
              width: 145,
              fontSize: 'sm',
            },
          },
        }}
      />
    </Box>
  );
};
