import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Grid, GridItem, Accordion } from '@chakra-ui/react';
import { forEach } from 'lodash';

import { renderTime } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountID,
  ARN,
  CloudID,
  CreatedAt,
  Email,
  HardwareMFAEnabled,
  IsOnboarded,
  ManagementAccountId,
  Name,
  Owner,
  Region,
  ServiceControlPolicy,
  State,
  Type,
  VirtualMFAEnabled,
} from '../../../Components/MetadataField/MetaGridFields';

export const AccountMetadata: FC = () => {
  const {
    resourceDetails,
    resourceInsight,
    resourceType,
  } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;
  const [accessKeys, setAccessKeys] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    let data: Record<string, any>[] = [];
    forEach(insight?.access_keys, (o, k) => data.push({ key: k, ...o }));
    setAccessKeys(data);
  }, [insight]);

  const columns = [
    { header: 'Key', accessor: 'key' },
    {
      header: 'last used service',
      accessor: 'last_used_service',
      align: 'left',
    },
    { header: 'last used region', accessor: 'last_used_region', align: 'left' },
    {
      header: 'last rotated',
      accessor: 'last_rotated',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'last used date',
      accessor: 'last_used_date',
      align: 'left',
      render: renderTime,
    },
  ];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountID(insight)}
      {Region(insight)}
      {CloudID(insight)}
      {ARN(insight)}
      {Tags(resource)}
      {State(resource)}
      {CreatedAt(resource, 'dayjs')}
      {Owner(insight)}
      {Email(resource)}

      {resourceType === NativeResources.AwsAccount && (
        <>
          {Type(resource)}
          {ManagementAccountId(resource)}
          {IsOnboarded(resource)}
        </>
      )}

      {VirtualMFAEnabled(insight)}
      {HardwareMFAEnabled(insight)}
      {ServiceControlPolicy(insight)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={columns} data={accessKeys} />}
            iconType={IconTypes.IamUserAccessKey}
            label="Access Keys"
            totalCount={accessKeys?.length}
            height="300px"
            iconBGColor="primary"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
