import React, { ReactNode } from 'react';

import { Badge, Box, Center, Stack } from '@chakra-ui/react';
import { components } from 'react-select';

import { CloseIcon } from 'components/Icons';

export const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <Stack spacing={1}>{props.children}</Stack>
    </components.MenuList>
  );
};

interface Props {
  onRemove(): void;
  children: ReactNode;
}

export const GraphFilterBox = (props: Props) => {
  const { children, onRemove } = props;

  return (
    <Box
      border={'1px solid'}
      borderColor={'gray.100'}
      borderRadius={4}
      p={2}
      pos={'relative'}
    >
      <Badge
        borderRadius="full"
        bg="red"
        color="white"
        w={4}
        h={4}
        p={0.5}
        pos={'absolute'}
        right={-2}
        top={-2}
        cursor={'pointer'}
        onClick={() => onRemove()}
      >
        <Center w="full" h="full">
          <CloseIcon />
        </Center>
      </Badge>
      {children}
    </Box>
  );
};
