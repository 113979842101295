import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SnapshotIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    p="1px"
    {...props}
  >
    <g>
      <path
        d="M89.6,52.3c-21.2,0-38.4,17.2-38.4,38.4s17.2,38.4,38.4,38.4S128,111.8,128,90.7S110.8,52.3,89.6,52.3z
		 M89.6,112c-11.8,0-21.3-9.6-21.3-21.3s9.6-21.3,21.3-21.3s21.3,9.6,21.3,21.3S101.4,112,89.6,112z"
      />
      <circle cx="426.7" cy="77.9" r="8.5" />
      <circle cx="392.5" cy="77.9" r="8.5" />
      <circle cx="443.7" cy="103.5" r="8.5" />
      <circle cx="409.6" cy="103.5" r="8.5" />
      <circle cx="358.4" cy="77.9" r="8.5" />
      <circle cx="375.5" cy="103.5" r="8.5" />
      <circle cx="324.3" cy="77.9" r="8.5" />
      <circle cx="341.3" cy="103.5" r="8.5" />
      <circle cx="290.1" cy="77.9" r="8.5" />
      <circle cx="307.2" cy="103.5" r="8.5" />
      <path
        d="M89.6,214.4c-21.2,0-38.4,17.2-38.4,38.4s17.2,38.4,38.4,38.4S128,274,128,252.8S110.8,214.4,89.6,214.4z
		 M89.6,274.1c-11.8,0-21.3-9.6-21.3-21.3s9.6-21.3,21.3-21.3s21.3,9.6,21.3,21.3S101.4,274.1,89.6,274.1z"
      />
      <path
        d="M89.6,376.5c-21.2,0-38.4,17.2-38.4,38.4c0,21.2,17.2,38.4,38.4,38.4s38.4-17.2,38.4-38.4
		C128,393.8,110.8,376.5,89.6,376.5z M89.6,436.3c-11.8,0-21.3-9.6-21.3-21.3c0-11.8,9.6-21.3,21.3-21.3s21.3,9.6,21.3,21.3
		C110.9,426.7,101.4,436.3,89.6,436.3z"
      />
      <path
        d="M512,140.9V40.4c0-21.7-17.6-39.3-39.3-39.3H39.3C17.6,1.1,0,18.7,0,40.4v100.5c0,12.5,6,23.6,15.2,30.8
		C6,178.9,0,190,0,202.5v100.5c0,12.5,6,23.6,15.2,30.8C6,341.1,0,352.1,0,364.7v100.5c0,21.7,17.6,39.3,39.3,39.3h237.9
		c22.9,4.2,48,6.4,72.7,6.4c58.5,0,119.4-12,147-35.1l0,0c0,0,3.4-2.2,7.7-7.8c0.5-0.7,1.1-1.4,1.5-2.1c0.1-0.1,0.2-0.3,0.3-0.4
		c5.1-7.5,5.5-12.6,5.5-12.6l0,0c0.1-0.5,0.2-1.1,0.2-1.6v-76.8v-3.2V368v-70.4v-3.2v-3.2v-73.6v-3.2c0-1.3-0.3-2.5-0.8-3.5
		c-3-12.5-14.1-23-30.4-31.4C498.6,175.7,512,159.9,512,140.9z M17.1,202.5c0-12.3,10-22.3,22.3-22.3h178
		c-15.4,8.3-25.9,18.5-28.8,30.6c-0.5,1.1-0.8,2.3-0.8,3.5v3.2v73.6v3.2v3.2v27.7H39.3c-12.3,0-22.3-10-22.3-22.3V202.5z
		 M39.3,487.5L39.3,487.5c-12.3,0-22.3-10-22.3-22.3V364.7c0-12.3,10-22.3,22.3-22.3h148.4V368v3.2v3.2v71.2
		c0.1,5.5,1,12.3,5.3,19.2c0.3,0.5,0.7,1,1.1,1.6c0.9,1.2,1.9,2.4,2.9,3.6c0.5,0.6,1.1,1.2,1.6,1.8c3.3,3.4,7.2,6.5,11.7,9.5
		c0.2,0.1,0.3,0.2,0.5,0.3c3.3,2.1,6.8,4.1,10.6,6H39.3z M494.8,449.8c-3.5,21.3-62.5,44-144.9,44c-26.3,0-50.1-2.3-70.6-6.2l0-0.1
		c-42.4-8-65.9-21.6-72.5-33c0,0,0,0,0,0c-0.3-0.5-0.5-1-0.7-1.6c-0.4-1.1-0.9-2.1-1-3.2l-0.1-49.2c0.3,0.2,0.7,0.5,1,0.7
		c1.1,0.8,2.2,1.6,3.4,2.4c0.9,0.6,1.9,1.2,2.9,1.9c1.5,0.9,3.1,1.9,4.7,2.8c0,0,0,0,0,0c17.8,9.7,42.3,16.7,69.3,20.9
		c0.2,0,0.4,0.1,0.6,0.1c8.7,1.4,17.7,2.4,26.8,3.2c1.9,0.2,3.9,0.3,5.8,0.5c1.9,0.1,3.8,0.3,5.7,0.4c2.6,0.1,5.2,0.3,7.7,0.4
		c1.5,0.1,3,0.1,4.5,0.2c4.2,0.1,8.3,0.2,12.4,0.2c4.1,0,8.3-0.1,12.4-0.2c1.5,0,3-0.1,4.5-0.2c2.6-0.1,5.1-0.2,7.7-0.4
		c1.9-0.1,3.8-0.2,5.8-0.4c1.9-0.1,3.8-0.3,5.7-0.5c9.2-0.8,18.2-1.9,27.1-3.2c0.1,0,0.3,0,0.4-0.1c29.6-4.6,56-12.6,74.1-23.7
		c1-0.6,2-1.2,2.9-1.8c1.2-0.8,2.3-1.6,3.3-2.4c0.4-0.3,0.8-0.5,1.2-0.8c0,0,0,48.5,0,48.5C494.9,449.3,494.8,449.6,494.8,449.8z
		 M494.9,366.3C494.9,366.3,494.9,366.3,494.9,366.3l0,4.9c0,0.9-0.3,1.9-0.5,2.8c-0.2,0.7-0.4,1.5-0.7,2.2c-0.3,0.6-0.6,1.3-1,1.9
		c-0.4,0.6-0.8,1.3-1.2,1.9c-0.7,0.9-1.4,1.8-2.2,2.7c-0.3,0.3-0.6,0.7-1,1c-18.1,17.5-71.4,33.3-138.4,33.3
		c-67,0-120.3-15.8-138.4-33.2c-0.4-0.4-0.7-0.7-1.1-1.1c-0.8-0.9-1.5-1.7-2.2-2.6c-0.5-0.7-0.9-1.3-1.3-2c-0.3-0.6-0.7-1.2-0.9-1.8
		c-0.1-0.1-0.1-0.3-0.2-0.4c-1.1-2.7-1-4.3-1-4.3v-1c0,0,0,0,0,0c0-0.7,0-1.8,0-3.1c0,0,0-0.1,0-0.1l-0.1-41.9v-1.6
		c0.3,0.3,0.7,0.5,1.1,0.8c1.1,0.8,2.2,1.6,3.4,2.4c0.9,0.6,1.9,1.2,2.9,1.9c1.5,0.9,3.1,1.9,4.7,2.8c0,0,0,0,0,0
		c17.8,9.7,42.3,16.7,69.3,20.9c0.2,0,0.4,0.1,0.6,0.1c8.7,1.4,17.7,2.4,26.8,3.2c1.9,0.2,3.9,0.3,5.8,0.5c1.9,0.1,3.8,0.3,5.7,0.4
		c2.6,0.1,5.2,0.3,7.7,0.4c1.5,0.1,3,0.1,4.5,0.2c4.2,0.1,8.3,0.2,12.4,0.2c4.1,0,8.3-0.1,12.4-0.2c1.5,0,3-0.1,4.5-0.2
		c2.6-0.1,5.1-0.2,7.7-0.4c1.9-0.1,3.8-0.2,5.8-0.4c1.9-0.1,3.8-0.3,5.7-0.5c9.2-0.8,18.2-1.9,27.1-3.2c0.1,0,0.3,0,0.4-0.1
		c29.6-4.6,56-12.6,74.1-23.7c1-0.6,2-1.2,2.9-1.8c1.2-0.8,2.3-1.6,3.3-2.4c0.4-0.3,0.8-0.5,1.2-0.8V366.3z M494.9,294.4
		c0,0.9-0.3,1.9-0.5,2.8c-0.2,0.7-0.4,1.5-0.7,2.2c-0.3,0.6-0.6,1.3-1,1.9c-0.4,0.6-0.8,1.3-1.2,1.9c-0.7,0.9-1.4,1.8-2.2,2.7
		c-0.3,0.3-0.6,0.7-1,1c-18.1,17.5-71.4,33.3-138.4,33.3c-67,0-120.3-15.8-138.4-33.2c-0.4-0.4-0.7-0.7-1.1-1.1
		c-0.8-0.9-1.5-1.7-2.2-2.6c-0.5-0.7-0.9-1.3-1.3-2c-0.3-0.6-0.7-1.2-0.9-1.8c-0.3-0.8-0.6-1.5-0.8-2.3c-0.2-0.9-0.5-1.9-0.5-2.8
		v-5.1v-16.1V247c0.1,0.1,0.2,0.1,0.3,0.2c1.6,1.2,3.3,2.4,5,3.5c0.4,0.2,0.8,0.5,1.2,0.7c1.5,0.9,3.1,1.9,4.7,2.8
		c0.5,0.3,0.9,0.5,1.4,0.8c2,1.1,4.1,2.1,6.2,3.1c0.3,0.2,0.7,0.3,1,0.5c1.9,0.9,3.8,1.7,5.8,2.5c0.6,0.2,1.2,0.5,1.8,0.7
		c2.4,0.9,4.8,1.8,7.2,2.7c0.1,0,0.3,0.1,0.4,0.1c2.4,0.8,4.8,1.6,7.3,2.3c0.7,0.2,1.3,0.4,2,0.6c2.5,0.7,4.9,1.4,7.5,2.1
		c0.2,0.1,0.4,0.1,0.6,0.2c2.7,0.7,5.6,1.4,8.4,2c0.7,0.2,1.4,0.3,2.2,0.5c2.4,0.5,4.9,1,7.4,1.5c0.5,0.1,1,0.2,1.5,0.3
		c2.9,0.5,5.9,1,8.9,1.5c0.7,0.1,1.4,0.2,2.1,0.3c2.5,0.4,5,0.7,7.5,1.1c0.7,0.1,1.4,0.2,2.1,0.3c3.1,0.4,6.1,0.7,9.2,1.1
		c0.6,0.1,1.3,0.1,1.9,0.2c2.6,0.3,5.2,0.5,7.8,0.7c0.8,0.1,1.6,0.1,2.4,0.2c3.1,0.2,6.3,0.4,9.4,0.6c0.4,0,0.9,0,1.3,0.1
		c2.8,0.1,5.6,0.2,8.3,0.3c0.8,0,1.7,0.1,2.5,0.1c3.2,0.1,6.4,0.1,9.5,0.1c3.2,0,6.4-0.1,9.5-0.1c0.8,0,1.7,0,2.5-0.1
		c2.8-0.1,5.6-0.2,8.3-0.3c0.5,0,0.9,0,1.3-0.1c3.1-0.2,6.3-0.4,9.4-0.6c0.8-0.1,1.6-0.1,2.4-0.2c2.6-0.2,5.2-0.4,7.8-0.7
		c0.6-0.1,1.3-0.1,1.9-0.2c3.1-0.3,6.2-0.7,9.2-1.1c0.7-0.1,1.4-0.2,2.1-0.3c2.5-0.3,5-0.7,7.5-1.1c0.7-0.1,1.4-0.2,2.1-0.3
		c3-0.5,6-1,8.9-1.5c0.5-0.1,1-0.2,1.5-0.3c2.5-0.5,5-1,7.4-1.5c0.7-0.2,1.4-0.3,2.2-0.5c2.8-0.6,5.7-1.3,8.4-2
		c0.2-0.1,0.4-0.1,0.6-0.2c2.6-0.7,5-1.4,7.5-2.1c0.7-0.2,1.3-0.4,2-0.6c2.5-0.8,4.9-1.5,7.3-2.3c0.1,0,0.3-0.1,0.4-0.1
		c2.5-0.9,4.9-1.8,7.2-2.7c0.6-0.2,1.2-0.5,1.8-0.7c2-0.8,3.9-1.6,5.8-2.5c0.3-0.2,0.7-0.3,1-0.5c2.2-1,4.2-2,6.2-3.1
		c0.5-0.3,0.9-0.5,1.4-0.8c1.6-0.9,3.2-1.8,4.7-2.8c0.4-0.2,0.8-0.5,1.2-0.7c1.8-1.1,3.4-2.3,5-3.5c0.1-0.1,0.2-0.1,0.3-0.2v29.7
		l0-0.1L494.9,294.4z M494.9,217.6c0,21.6-62,45.9-145.1,45.9c-83,0-145.1-24.2-145.1-45.9c0-1.1,0.3-2.2,0.6-3.3
		c0.3-1.1,0.8-2.2,1.5-3.4c0-0.1,0.1-0.1,0.1-0.2c2.8-4.7,8.6-9.8,17.4-14.8c0.4-0.2,0.8-0.4,1.1-0.6c1.5-0.8,3.1-1.6,4.8-2.4
		c2-0.9,4-1.8,6.1-2.7c0.4-0.2,0.9-0.3,1.3-0.5c9.9-4,21.9-7.5,35.3-10.3c4.2-0.9,8.5-1.7,12.9-2.5c1.2-0.2,2.3-0.4,3.5-0.6
		c38.2-5.9,82.8-5.9,121,0c1.2,0.2,2.4,0.4,3.5,0.6c4.4,0.7,8.6,1.6,12.8,2.4C468.3,188.2,494.9,203.4,494.9,217.6z M494.9,140.9
		c0,12.3-10,22.3-22.3,22.3h-39.5c-0.5-0.1-1-0.2-1.5-0.3c-5.6-1.2-11.4-2.3-17.2-3.2c-0.6-0.1-1.1-0.2-1.7-0.3
		c-20.1-3.1-41.7-4.7-62.8-4.7c-21.2,0-42.6,1.6-62.8,4.7c-0.6,0.1-1.2,0.2-1.8,0.3c-5.9,0.9-11.6,2-17.2,3.2
		c-0.5,0.1-1.1,0.2-1.6,0.3H39.3c-12.3,0-22.3-10-22.3-22.2V40.4c0-12.3,10-22.3,22.3-22.3h433.3c12.3,0,22.3,10,22.3,22.3V140.9z"
      />
    </g>
  </Icon>
);
