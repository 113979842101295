import React, { useEffect, useState } from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { each, isEmpty, sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { RoleIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const IdentityRoles: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { identityRolesChange } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();
  const [roles, setRoles] = useState({});

  useEffect(() => {
    let items: Record<string, any> = {};
    each(inventorySummary.data?.items?.['Role'], o =>
      each(o, (v, k) => (items[k] = v)),
    );
    setRoles(items);
  }, [inventorySummary.data]);

  return (
    <SummaryCard
      label="Roles"
      icon={<RoleIcon color="primary" />}
      change={{
        isLoading: identityRolesChange.isLoading,
        first: identityRolesChange.data?.previous_count,
        last: identityRolesChange.data?.current_count,
        percentChange: identityRolesChange.data?.percentage_change,
      }}
      cloudProviders={!isEmpty(roles) ? Object.keys(roles) : []}
      count={!isEmpty(roles) ? sum(Object.values(roles)) : 0}
      isLoading={inventorySummary.isLoading}
      onClick={() =>
        navigate(
          '/inventory/summary?tab=' +
            Categories.Identity +
            '_' +
            Resources.Role,
        )
      }
      labelTooltip={formatTooltip({
        header: 'Roles',
        content:
          'Cloud-native roles for public clouds and SaaS including service accounts',
        width: 48,
      })}
    />
  );
};
