import React from 'react';

import {
  Box,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

import { DownIcon, UpIcon } from 'components/Icons';

export const DocumentItem = ({ label, content }) => {
  return (
    <AccordionItem border="none" mb={4}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            p={0}
            border="none"
            _hover={{ bg: 'transparent' }}
            _focus={{ border: 'none' }}
            color={customTheme.colors.gray['300']}
            fontWeight={600}
            fontSize="sm"
          >
            <Box mr={4}>{label}</Box>
            <Box>{isExpanded ? <UpIcon /> : <DownIcon />}</Box>
          </AccordionButton>
          <AccordionPanel
            border="1px solid"
            borderColor={customTheme.colors.gray['100']}
            borderRadius={6}
            p={3}
            mt={1}
          >
            {content}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};
