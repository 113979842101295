import React from 'react';

import { Stack, HStack, Center } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { AddButton, PrimaryButton } from 'components/DataEntry';
import { EditIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { CDashboard } from 'components/Visualization';
import { ReportChartWizard } from 'containers/Reports/ReportsDashboard/Components/ReportChartWizard';
import { useReportsDashboard } from 'containers/Reports/ReportsDashboard/hooks/useReportsDashboard';
import { selectReportsDashboard } from 'containers/Reports/ReportsDashboard/selectors';

export const Dashboard = () => {
  const {
    onAddChart,
    createDashboardLayout,
    editChart,
    setEditChart,
    currentChart,
    actionType,
    setCurrentChart,
  } = useReportsDashboard();
  const navigate = useNavigate();
  const { reportsInfo } = useSelector(selectReportsDashboard);
  const { contentHeight } = usePageContentContext();

  // create dashboardWidgets list from configs
  const dashboardWidgets = createDashboardLayout(
    'reports_dashboard',
    'Reports Dashboard',
  );

  return (
    <Stack spacing={5}>
      <HStack w="full" justify="end">
        <PrimaryButton
          onClick={() => {
            navigate('/reports/dashboard/edit');
          }}
          leftIcon={<EditIcon />}
          isDisabled={reportsInfo.isLoading || isEmpty(reportsInfo.data)}
        >
          Edit Dashboard
        </PrimaryButton>
        <AddButton
          onClick={() => onAddChart()}
          isDisabled={reportsInfo.isLoading}
          label="Add Chart"
        />
      </HStack>
      {reportsInfo.isLoading && (
        <Center w="full" h={contentHeight + 'px'}>
          <CustomSpinner loading={reportsInfo.isLoading} size="lg" />
        </Center>
      )}
      {reportsInfo.isSuccess && !isEmpty(reportsInfo.data) && (
        <CDashboard dashboardWidgets={dashboardWidgets} />
      )}
      {reportsInfo.isSuccess && isEmpty(reportsInfo.data) && (
        <Center h={contentHeight - 100 + 'px'}>
          <NoDataAvailable />
        </Center>
      )}
      {editChart && (
        <ReportChartWizard
          currentRecord={currentChart}
          setCurrentRecord={setCurrentChart}
          isOpen={editChart}
          onClose={() => setEditChart(false)}
          actionType={actionType}
        />
      )}
    </Stack>
  );
};
