import React, { FC, Fragment } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { AriksaLogoIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoIcon';
import { NetconLogo } from 'components/Icons/ReactCustomIcons/NetconLogo';
import { selectApp } from 'containers/App/selectors';

export const CompanyLogoIcon = props => {
  const location = useLocation();
  const { organization } = useSelector(selectApp);

  return (
    <Fragment>
      {location.host?.startsWith('netcon') ||
      organization.toLowerCase() === 'netcon' ? (
        <NetconLogo />
      ) : (
        <AriksaLogoIcon />
      )}
    </Fragment>
  );
};
