import React, { useEffect, useState } from 'react';

import { ceil, map } from 'lodash';
import { useSelector } from 'react-redux';

import { selectOnboardedCloudAccounts } from 'containers/App/selectors';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { formatDate } from 'utils/date';

import { TotalCard } from './TotalCard';

export const AccountsAnalyzed = () => {
  const { accountsAnalyzed } = useSelector(selectDataDashboard);
  const accounts = useSelector(selectOnboardedCloudAccounts);
  const { total_accounts } = accountsAnalyzed.data;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setChartData(
      map(accountsAnalyzed.data.accounts, o => ({
        Date: formatDate(o?.created_at, 'D-MMM'),
        Count: o?.day_accounts,
        actualCount: ceil(o?.day_accounts ?? 0 / 1000),
      })),
    );
  }, [accountsAnalyzed.data]);

  return (
    <TotalCard
      label="Accounts Analyzed"
      total={(total_accounts ?? 0) + ' out of ' + accounts.data?.length}
      chartData={chartData}
      isLoading={accountsAnalyzed.isLoading}
    />
  );
};
