/**
 *
 * Slack
 *
 */

import React, { memo } from 'react';

import { SlackWizard } from 'containers/Setup/Integrations/Components/Slack/Components/SlackWizard';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { SlackTable } from './Components/SlackTable';

interface Props {}

export const Slack = memo((props: Props) => {
  const { isOpen } = useIntegrationsContext();

  return isOpen ? <SlackWizard /> : <SlackTable />;
});
