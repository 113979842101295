import { AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest } from '@ariksa/audittrail/api';
import {
  ItemApiApproveRequestRequest,
  ItemApiListAllRequestsRequest,
  PageAccessRequestGetResponse,
} from '@ariksa/jit';
import {
  ItemApiDenyRequestRequest,
  ItemApiGetAssumeRolePolicyRequest,
  ItemApiToggleAutoApproveRequest,
  ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
  ToggleAutoApproveResponse,
} from '@ariksa/jit/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  AuditTrailApiService,
  authTokenHeader,
  JitService,
} from 'api/services';

import { actions } from './slice';

export function* jitRequestsSaga() {
  yield takeLatestAction(actions.getJitRequests, doGetJitRequests);
  yield takeLatestAction(
    actions.getResourceActionPerHour,
    doGetResourceActionsPerHour,
  );
  yield takeLatestAction(
    actions.getResourceActionOnDay,
    doGetResourceActionsOnDay,
  );
  yield takeLatestAction(actions.approveJitAccount, doApproveJitAccount);
  yield takeLatestAction(actions.denyJitAccount, doDenyJitAccount);
  yield takeLatestAction(actions.getPolicyDocument, doGetPolicyDocument);
  yield takeLatestAction(actions.updateAutoApprovals, doUpdateAutoApprovals);
}

function* doGetJitRequests(
  ctx: QueryContext<
    PageAccessRequestGetResponse,
    ItemApiListAllRequestsRequest
  >,
) {
  yield call(ctx.fetch, () => JitService.Jit.listAllRequests(ctx.params));
}

export function* doGetResourceActionsPerHour(
  ctx: QueryContext<
    any,
    AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest
  >,
) {
  yield ctx.fetch(() =>
    AuditTrailApiService.AuditTrail.auditTrailServiceResourceActionsMonthly(
      ctx.params,
      authTokenHeader(),
    ),
  );
}

export function* doGetResourceActionsOnDay(ctx: QueryContext<any, any>) {
  yield ctx.fetch(() =>
    AuditTrailApiService.AuditTrail.auditTrailServiceResourceActionsDaily(
      ctx.params,
      authTokenHeader(),
    ),
  );
}

export function* doApproveJitAccount(
  ctx: QueryContext<any, ItemApiApproveRequestRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.approveRequest(ctx.params));
}

export function* doDenyJitAccount(
  ctx: QueryContext<
    ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
    ItemApiDenyRequestRequest
  >,
) {
  yield ctx.fetch(() => JitService.Jit.denyRequest(ctx.params), {});
}

export function* doGetPolicyDocument(
  ctx: QueryContext<any, ItemApiGetAssumeRolePolicyRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.getAssumeRolePolicy(ctx.params));
}

export function* doUpdateAutoApprovals(
  ctx: QueryContext<ToggleAutoApproveResponse, ItemApiToggleAutoApproveRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.toggleAutoApprove(ctx.params));
}
