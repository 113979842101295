import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CallerReference,
  Disabled,
  EnableSNI,
  FailureThreshold,
  FQDN,
  HealthThreshold,
  Inverted,
  LinkedServices,
  MeasureLatency,
  RequestInterval,
  ResourcePath,
  Route53HealthCheckEndpoint,
  SearchString,
  Type,
  Version,
} from '../../../Components/MetadataField/MetaGridFields';

export const Route53HealthCheckMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const configColumns = [
    { header: 'Region', accessor: '' },
    { header: 'CloudWatch alarm name', accessor: '' },
  ];

  const config = [];

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields
          availabilityZone="None"
          region="None"
          vpc="None"
          state="None"
        />
        {Version(resource)}
        {CallerReference(resource)}
        {LinkedServices(resource)}
        {Route53HealthCheckEndpoint(resource)}
        {Type(resource)}
        {ResourcePath(resource)}
        {FQDN(resource)}
        {SearchString(resource)}
        {RequestInterval(resource)}
        {FailureThreshold(resource)}
        {MeasureLatency(resource)}
        {Disabled(resource)}
        {Inverted(resource)}
        {HealthThreshold(resource)}
        {EnableSNI(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={configColumns} data={config} />}
              iconType={IconTypes.Configuration}
              label="Alarm config"
              totalCount={config?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
