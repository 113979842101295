import { useMemo } from 'react';

import { each, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { selectSharedState } from 'containers/SharedState/selectors';
import { useIntoGraphNodes } from 'containers/Visibility/SecurityGraphNext/hooks/useIntoGraphNodes';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const useApplyFilters = () => {
  const { searchResult } = useSearchResult();
  const { hiddenNodeIds, enrichInsights, resourceAlerts } = useSelector(
    selectSecurityGraphNext,
  );
  const { riskContext } = useSelector(selectSharedState);
  const { intoGraphNodes } = useIntoGraphNodes();

  const nodes = useMemo(() => {
    if (isEmpty(searchResult.data?.nodes)) return [];
    const contextMap = new Map<string, any>();
    each(riskContext.data, (v, k) => {
      contextMap.set(k, v);
    });

    const filteredNodes = searchResult.data.nodes
      ?.filter(n => !hiddenNodeIds.has(n.identity))
      .map(n => {
        return {
          showContext: enrichInsights.filteringByContext,
          id: n.identity,
          ...n,
        };
      });

    /*if (enrichInsights.criticalFindings) {
      filteredNodes?.forEach(n => {
        // @ts-ignore
        n.severities = {
          CRITICAL: resourceAlerts.data.severity?.[n.uuid!]?.CRITICAL ?? 0,
        };
      });
    }*/

    if (enrichInsights.alertingEntities) {
      filteredNodes?.forEach(n => {
        // @ts-ignore
        n.severities = resourceAlerts.data.severity?.[n.uuid];
      });
    }

    filteredNodes?.forEach(n => {
      // @ts-ignore
      n.riskContext = contextMap.get(n.uuid) ?? {};
    });

    return intoGraphNodes(filteredNodes ?? []);
  }, [
    searchResult.data.nodes,
    riskContext.data,
    enrichInsights,
    hiddenNodeIds,
    intoGraphNodes,
    resourceAlerts.data.severity,
  ]);

  const edges = useMemo(() => {
    return searchResult.data.relationships ?? [];
  }, [searchResult]);

  const paths = useMemo(() => {
    return searchResult.data.paths ?? [];
  }, [searchResult]);

  const source_items = useMemo(() => {
    return searchResult.data.source_items ?? [];
  }, [searchResult]);

  const ordering = useMemo(() => {
    return searchResult.data.ordering ?? [];
  }, [searchResult]);

  return {
    nodes,
    edges,
    paths,
    source_items,
    ordering,
  };
};
