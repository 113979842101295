import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';

import { VMActivity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/VMActivity';
import { S3Activity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3Activity';
import { IAMRoleActivity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/IAMRole/IAMActivity';
import { IAMUserActivity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Identity/IAMUser/Activity/IAMUserActivity';
import { ResourceAPIActivity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/ResourceAPIActivity';

export const getAPIActivity = (resourceType: NativeResources) => {
  switch (resourceType) {
    case NativeResources.SimpleStorageService:
      return <S3Activity />;
    case NativeResources.Ec2Instance:
      return <VMActivity />;
    case NativeResources.IamUser:
    case NativeResources.OktaUser:
      return <IAMUserActivity />;
    case NativeResources.IamRole:
      return <IAMRoleActivity />;
    default:
      return <ResourceAPIActivity />;
  }
};
