import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Accordion, Grid, GridItem, Stack, Box } from '@chakra-ui/react';
import { map } from 'lodash';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { MetaGridValueBox } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridValueBox';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CallerReference,
  LinkedServices,
  PrivateZone,
  RecordSetCount,
  Route53HostedZoneComment,
  TrafficPolicies,
} from '../../../Components/MetadataField/MetaGridFields';

export const Route53HostedZoneMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const resourceRecordSetColumns = [
    { header: 'Name', accessor: 'Name' },
    { header: 'Type', accessor: 'Type' },
    { header: 'Account ID', accessor: '' },
    { header: 'Region', accessor: 'Region' },
    { header: 'Geo location', accessor: 'GeoLocation' },
    { header: 'Country code', accessor: 'GeoLocation.CountyCode' },
    { header: 'Created at', accessor: 'CreatedAt' },
    { header: 'Set identities', accessor: '' },
    { header: 'Weight', accessor: '' },
    { header: 'Failover', accessor: '' },
    { header: 'Multi value answer', accessor: '' },
    { header: 'TTL', accessor: 'TTL' },
    { header: 'DNS name', accessor: '' },
    { header: 'Health check ID', accessor: '' },
    { header: 'Traffic policy instance ID', accessor: '' },
    { header: 'Is truncated', accessor: '' },
    /*{
      header: 'Resource Records',
      accessor: 'ResourceRecords',
      render: ({ value }) =>
        !!value?.length ? (
          <CustomTooltip
            label={
              <Stack>
                {map(value, o => (
                  <Box>{o.Value}</Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        ) : (
          '-'
        ),
    },*/
  ];
  const delegationSetColumns = [
    { header: 'ID', accessor: 'Id' },
    { header: 'Caller reference', accessor: 'CallerReference' },
    {
      header: 'Name Servers',
      accessor: 'NameServers',
      render: ({ value }) =>
        !!value?.length ? (
          <CustomTooltip
            label={
              <Stack>
                {map(value, o => (
                  <Box>{o}</Box>
                ))}
              </Stack>
            }
          >
            {value?.length}
          </CustomTooltip>
        ) : (
          0
        ),
    },
  ];

  const vpcColumns = [
    { header: 'ID', accessor: 'VPCId' },
    { header: 'Region', accessor: 'VPCRegion', align: 'left' },
  ];

  const resourceRecordSets = resource?.config?.ResourceRecordSets ?? [];
  const delegationSet = resource?.config?.DelegationSet ?? [];
  const vpcs = resource?.network?.VPCs ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {CallerReference(resource)}
      {RecordSetCount(resource)}
      {LinkedServices(resource)}
      {TrafficPolicies(resource)}
      <MetaGridValueBox
        label="Config"
        value={
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {PrivateZone(resource)}
            {Route53HostedZoneComment(resource)}
          </Grid>
        }
      />
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={vpcColumns} data={vpcs} />}
            iconType={NativeResources.VirtualPrivateCloud}
            label="VPCs"
            totalCount={vpcs?.length ?? 0}
            height="300px"
          />
          <MetaAccordionItem
            panel={
              <Table
                columns={resourceRecordSetColumns}
                data={resourceRecordSets}
              />
            }
            iconType={IconTypes.Route53RecordSet}
            label="Resource record sets"
            totalCount={resourceRecordSets?.length ?? 0}
            height="300px"
          />
          <MetaAccordionItem
            panel={
              <Table columns={delegationSetColumns} data={delegationSet} />
            }
            iconType={IconTypes.Route53RecordSet}
            label="Delegation set"
            totalCount={delegationSet?.length ?? 0}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
