import { Categories } from '@ariksa/inventory-core/api';

export const inventoryCategoryOrderList = [
  Categories.Identity,
  Categories.Code,
  Categories.Compute,
  Categories.Data,
  Categories.Network,
  Categories.App,
  Categories.Policy,
  //Categories.Cluster,
  Categories.Credential,
];
