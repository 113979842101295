import React, { useEffect, useState } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { CreateServicenow } from 'containers/Setup/Integrations/Components/ServiceNow/Components/CreateServicenow';
import { EditServicenow } from 'containers/Setup/Integrations/Components/ServiceNow/Components/EditServicenow';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

export const ServicenowWizard = () => {
  const dispatch = useDispatch();
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();

  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (!!currentRecord?.name) {
      dispatch(
        actions.loadClientInfo({
          q: {
            clientName: Clients.Servicenow,
            uuid: currentRecord?.uuid,
          },
        }),
      );
    }
  }, [dispatch, currentRecord]);

  const onUpdate = data => {
    const { name, assignee } = data;
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name,
        status: true,
        service_now: {
          assignee: assignee?.value?.username,
          //assignee_name: assignee?.label,
        },
      },
    };
    dispatch(
      actions.updateClientById({ q: payload, onSuccess: () => onCancel() }),
    );
  };

  return actionType === 'Add' ? (
    <CreateServicenow
      onSubmit={onUpdate}
      validate={validate}
      setValidate={setValidate}
    />
  ) : (
    <EditServicenow onSubmit={onUpdate} />
  );
};
