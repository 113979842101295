import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BurgerMenuIcon = props => (
  <Icon viewBox="0 0 512 512" color="inherit" h="full" w="full" {...props}>
    <path
      opacity={0.44}
      fill="#7130A0"
      d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256c141.4,0,256-114.6,256-256S397.4,0,256,0z M293,360.8h-53.7
	V307H293V360.8z M293,269.9h-53.7v-53.7H293V269.9z M293,178.9h-53.7v-53.7H293V178.9z"
    />
  </Icon>
);
