import { each } from 'lodash';

import { AGraphNodeId } from './types';

export class NodeEdgeMap {
  protected edges: Map<AGraphNodeId, AGraphNodeId[]> = new Map();

  inverse(): NodeEdgeMap {
    const map: NodeEdgeMap = new NodeEdgeMap();
    each(this.sourceIds(), sid => {
      each(this.get(sid), did => {
        map.add(did, sid);
      });
    });
    return map;
  }

  merge(other: NodeEdgeMap): NodeEdgeMap {
    const map: NodeEdgeMap = new NodeEdgeMap();
    each(this.sourceIds(), sid => {
      each(this.get(sid), did => {
        map.add(did, sid);
      });
    });

    each(other.sourceIds(), sid => {
      each(other.get(sid), did => {
        map.add(did, sid);
      });
    });
    return map;
  }

  sourceIds(): AGraphNodeId[] {
    return Array.from(this.edges.keys());
  }

  destIds(sourceId: AGraphNodeId): AGraphNodeId[] {
    return this.get(sourceId);
  }

  add(source: AGraphNodeId, dest: AGraphNodeId) {
    if (this.edges.get(source)?.includes(dest)) return;
    if (!this.edges.get(source)) {
      this.edges.set(source, []);
    }

    if (this.edges.get(source)?.push(dest)) return;
  }

  remove(source: AGraphNodeId, dest?: AGraphNodeId) {
    if (dest) {
      this.edges.set(
        source,
        this.get(source).filter(id => id !== dest),
      );
    } else {
      this.edges.delete(source);
    }
  }

  get(id: AGraphNodeId): AGraphNodeId[] {
    return this.edges.get(id) ?? [];
  }

  clear() {
    this.edges.clear();
  }
}
