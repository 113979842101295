import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { customTheme } from 'theme';

import { getIconColor } from 'components/DataDisplay/Utils';
import { resourceIconsWhite } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { MapType } from 'containers/Visibility/types';

import { limitedString } from '../../../../../utils/string';

import { USER_NODE } from './nodes_types';

export function registerUserNode() {
  G6.registerNode(
    USER_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        // @ts-ignore
        const bg = getIconColor(cfg?.data?.resource_type);

        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 46,
              height: 46,
              fill: customTheme.colors.white,
              stroke: bg,
              lineWidth: 0.5,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();
        // @ts-ignore
        const mapType = cfg.data.mapType;

        group.addShape(
          'rect',
          {
            attrs: {
              width: 46,
              height: 46,
              fill: bg,
              radius: 8,
            },
          },
          'Box',
        );

        group.addShape('image', {
          attrs: {
            x: width / 3.7,
            y: height / 3.7,
            width: 20,
            height: 20,
            img: resourceIconsWhite.user,
          },
          name: 'img',
        });

        if (
          mapType === MapType.ResourceNetAccess ||
          mapType === MapType.ResourceBehavior
        ) {
          // @ts-ignore
          if (!cfg?.data?.isFinalNode) {
            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 60,
                // @ts-ignore
                text: 'Type: User', // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'type',
            });

            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 80,
                // @ts-ignore
                text: 'Name: ' + cfg?.data?.name, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

            group.addShape('text', {
              attrs: {
                x: x,
                y: y + 100,
                // @ts-ignore
                text: cfg?.data?.uuid, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'id',
            });
          } else {
            group.addShape('text', {
              attrs: {
                x: x + 60,
                y: y + 10,
                // @ts-ignore
                text: 'Type: User', // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'type',
            });

            group.addShape('text', {
              attrs: {
                x: x + 60,
                y: y + 30,
                // @ts-ignore
                text: 'Name: ' + limitedString(cfg?.data?.name), // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'name',
            });

            group.addShape('text', {
              attrs: {
                x: x + 60,
                y: y + 50,
                // @ts-ignore
                text: cfg?.data?.uuid, // cfg.data : typescript throws Object is of type 'unknow'
                fontSize: 12,
                // fontStyle: 'bold',
                fill: '#000',
                cursor: 'pointer',
              },
              name: 'id',
            });
          }
        } else if (
          mapType === MapType.UserExcessPermissions ||
          mapType === MapType.UserNetAccess ||
          mapType === MapType.UserBehaviour
        ) {
          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 80,
              // @ts-ignore
              text: 'Name: ' + cfg?.data?.name, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              // fontStyle: 'bold',
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'name',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 100,
              // @ts-ignore
              text: `Excess Roles:  ${cfg?.data?.unused_roles?.unused ?? '-'} `, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              // fontStyle: 'bold',
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'role',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 120,
              text: `Excess Policies:   ${
                // @ts-ignore
                cfg?.data?.unused_policies?.unused ?? '-'
              }`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              // fontStyle: 'bold',
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'policies',
          });

          group.addShape('text', {
            attrs: {
              x: x,
              y: y + 140,
              text: `Excess Permissions: ${
                // @ts-ignore
                cfg?.data?.unused_permissions?.unused ?? '-'
              }`, // cfg.data : typescript throws Object is of type 'unknow'
              fontSize: 12,
              // fontStyle: 'bold',
              fill: '#000',
              cursor: 'pointer',
            },
            name: 'excess_permissions',
          });
        }
        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
