import React, { useEffect, useState } from 'react';

import { Center, CenterProps, TooltipProps } from '@chakra-ui/react';
import startsWith from 'lodash/startsWith';

import { CustomTooltip, getIconColor } from 'components/DataDisplay';
import { isIconFilled } from 'components/Icons/Components/isIconFilled';
import { IconSize, IconType } from 'components/Icons/types';

import { getIcon } from './getIcon';

export interface IAriksaIconProps {
  type?: IconType;
  bg?: string;
  size?: IconSize;
  iconFilled?: boolean;
  border?: boolean;
  borderColor?: string;
  style?: CenterProps;
  icon?: React.ReactNode;
  tooltip?: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  color?: string;
  useCustomColor?: boolean;
  circle?: boolean;

  onClick?();
}

export const AriksaIcon = (props: IAriksaIconProps) => {
  const {
    type,
    size = 'regular',
    bg,
    iconFilled = type ? isIconFilled(type) : true,
    style,
    icon,
    border = false,
    borderColor = 'primary',
    color,
    circle = false,
    useCustomColor = false,
    onClick,
    tooltipProps,
    tooltip,
  } = props;
  const [bgColor, setBgColor] = useState(type);
  let containerSize = {
    w: 6,
    h: 6,
    p: iconFilled || border ? 1 : 0,
    borderRadius: circle ? 'full' : 'lg',
  };
  switch (size) {
    case 'xs':
      containerSize = {
        w: 4,
        h: 4,
        p: iconFilled || border ? 1.5 : 0,
        borderRadius: circle ? 'full' : 'xl',
      };
      break;
    case 'small':
    case 'sm':
      containerSize = {
        w: 8,
        h: 8,
        p: iconFilled || border ? 1.5 : 0,
        borderRadius: circle ? 'full' : 'xl',
      };
      break;
    case 'medium':
    case 'md':
      containerSize = {
        w: 10,
        h: 10,
        p: iconFilled || border ? 2 : 1,
        borderRadius: circle ? 'full' : '2xl',
      };
      break;
  }

  useEffect(() => {
    if (!!bg) setBgColor(bg);
    else if (startsWith(type as string, 'Azure')) setBgColor('azure');
    else if (startsWith(type as string, 'Okta')) setBgColor('okta');
  }, [type, bg]);

  const renderIcon = () => (
    <Center
      bg={
        iconFilled
          ? getIconColor(bgColor as string, useCustomColor)
          : 'transparent'
      }
      color={iconFilled ? (color ? color : 'white') : 'inherit'}
      border={border ? '1px solid' : 'none'}
      borderColor={borderColor}
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      {...containerSize}
      {...style}
    >
      {type ? getIcon(type, bgColor as string) : icon}
    </Center>
  );

  return !!tooltip ? (
    <CustomTooltip label={tooltip} {...tooltipProps}>
      {renderIcon()}
    </CustomTooltip>
  ) : (
    renderIcon()
  );
};
