import { customTheme } from '../../../../theme';

import { TagStyles } from './types';

export const defaultStyles: TagStyles = {
  tag: {
    px: 2,
    py: 1,
    size: 'xs',
    borderRadius: 6,
    bg: customTheme.colors.gray['50'],
    border: `1px solid ${customTheme.colors.gray['200']}`,
    fontSize: 'xs',
  },
  closeButton: {
    color: 'inherit',
    fontSize: '12px',
    _focus: {
      outline: 'none',
    },
  },
  leftIcon: {
    //w: 4,
    //h: 4,
    maxW: 4,
    maxH: 4,
    mr: 1,
  },
  rightIcon: {
    //w: 4,
    //h: 4,
    ml: 1,
    //p: 0,
  },
  label: {
    color: 'primary',
  },
};

export const tagStylesConsolas = {
  tag: {
    fontSize: '13px',
    pt: '4px',
    pb: 0,
    fontFamily: 'Consolas, Monospace',
  },
};
