import React, { memo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { UserProfileComponent } from '../../UserProfile/components/UserProfileComponent';

import { selectSetupUsers } from './selectors';

export const SetupUserProfile = memo(() => {
  const { currentRecord, allRoles } = useSelector(selectSetupUsers);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();

  return (
    <UserProfileComponent
      {...currentRecord}
      allRoles={allRoles}
      path="/setup/users/profile"
    />
  );
});
