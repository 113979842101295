import React, { FC, ReactNode } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { MetaGridField } from './MetaGridField';

interface Props extends BoxProps {
  label: ReactNode;
  value: ReactNode;
}

export const MetaGridValueBox: FC<Props> = props => {
  const { label, ...rest } = props;

  return (
    <MetaGridField
      label={label}
      value={
        <Box
          p={3}
          borderRadius="md"
          border="1px solid"
          borderColor={customTheme.colors.gray['100']}
          {...rest}
        >
          {props.value}
        </Box>
      }
      custom
      colSpan={4}
    />
  );
};
