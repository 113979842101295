import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ActivatedAt,
  ID,
  CostCenter,
  CreatedAt,
  Department,
  DisplayName,
  Division,
  Email,
  EmployeeNumber,
  FullName,
  LastLogin,
  LastUpdatedAt,
  Login,
  Name,
  OktaOrganization,
  Status,
  PasswordChangedAt,
  PasswordPolicies,
  ProfileURL,
  SecondaryEmail,
  StateChangedAt,
  Title,
  TransitioningToStatus,
  UserType,
  OktaDomains,
} from '../../../Components/MetadataField/MetaGridFields';

export const OktaUserMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {ID(insight)}
      {Status(resource)}
      {TransitioningToStatus(resource)}
      {Login(resource)}
      {FullName(resource)}
      {DisplayName(resource)}
      {Email(resource)}
      {SecondaryEmail(resource)}
      {ProfileURL(resource)}
      {UserType(resource)}
      {OktaOrganization(resource)}
      {Division(resource)}
      {Title(resource)}
      {Department(resource)}
      {CostCenter(resource)}
      {EmployeeNumber(resource)}
      {CreatedAt(resource, 'utc')}
      {ActivatedAt(resource)}
      {LastLogin(resource)}
      {LastUpdatedAt(resource)}
      {PasswordChangedAt(resource)}
      {StateChangedAt(resource)}
      {PasswordPolicies(resource)}
      {OktaDomains(resource)}
    </Grid>
  );
};
