import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.whitelist || initialState;

export const selectWhitelist = createSelector(
  [selectDomain],
  whitelistState => whitelistState,
);
