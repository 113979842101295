import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { VulnerabilityType } from '@ariksa/scan-analysis';
import { ContainerInfo, PackageSummary } from '@ariksa/scan-analysis/api';
import { Accordion } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { VulnerabilitySummaryCard } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/VulnerabilitySummaryCard';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

interface Props {
  row: ContainerInfo;
  isExpanded: boolean;
}

export const InstanceSummaryByID: FC<Props> = props => {
  const { row, isExpanded } = props;
  const { instanceSummaryByID } = useSelector(selectActiveResource);
  const dispatch = useDispatch();

  useEffect(() => {
    isExpanded &&
      dispatch(
        actions.getInstanceSummary({
          q: {
            instanceId: row?.instance_id ?? '',
            containerId: row?.container_id,
          },
        }),
      );
  }, [dispatch, row, isExpanded]);

  return (
    <Accordion allowMultiple>
      <VulnerabilitySummaryCard
        type={VulnerabilityType.Os}
        summary={
          (row?.container_id
            ? instanceSummaryByID.data?.[row?.container_id]?.os_vulnerability
            : {}) as PackageSummary
        }
        containerId={row?.container_id}
        instanceId={row?.instance_id ?? ''}
      />
      <VulnerabilitySummaryCard
        type={VulnerabilityType.Library}
        summary={
          (row?.container_id
            ? instanceSummaryByID.data?.[row?.container_id]
                ?.library_vulnerability
            : {}) as PackageSummary
        }
        containerId={row?.container_id}
        instanceId={row?.instance_id ?? ''}
      />
    </Accordion>
  );
};
