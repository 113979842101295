import { pizzlyPkey } from '../config';
import { PIZZLY_SERVICE } from '../service_urls';
import { withPrefixArgs } from '../utils';

import {
  IBitbucketGetReposUrl,
  IPizzlyPkey,
  IRepoName,
  IUserName,
} from './types';

const withArgs = withPrefixArgs(PIZZLY_SERVICE);

export const bitbucketApis = {
  getBitbucketRepos(params: IBitbucketGetReposUrl) {
    return withArgs<IBitbucketGetReposUrl & IPizzlyPkey>(
      `/proxy/bitbucket/repositories/{username}`,
    )({
      pagelen: 100,
      pizzly_pkey: pizzlyPkey,
      ...params,
    });
  },
  getBitbucketUserInfo() {
    return withArgs<IPizzlyPkey>(`/proxy/bitbucket/user`)({
      pizzly_pkey: pizzlyPkey,
    });
  },
  getBitbucketRepoBranches(params: IUserName & IRepoName) {
    return withArgs<IPizzlyPkey & IUserName & IRepoName>(
      `/proxy/bitbucket/repositories/{userName}/{repoName}/refs/branches`,
    )({
      pizzly_pkey: pizzlyPkey,
      ...params,
    });
  },
};
