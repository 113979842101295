import React, { FC } from 'react';

import { Box, Center, Circle, Flex, HStack, Stack } from '@chakra-ui/react';
import { sum, values } from 'lodash';
import { useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { selectCompliance } from 'containers/Compliance/selectors';

export const complianceSummary = (label, value, isLoading, type, color) => (
  <HStack w="full">
    <Center>
      <Circle
        size={12}
        m={1}
        borderRadius="full"
        color="white"
        p={2}
        bg={color}
      >
        {getIcon(type)}
      </Circle>
    </Center>
    <Flex align="center" fontSize="md">
      <Stack spacing={0}>
        <Box>{label}</Box>
        {isLoading && <CustomSpinner mt={1} loading={isLoading} />}
        {!isLoading && <Box fontWeight={600}>{value ? value : 0}</Box>}
      </Stack>
    </Flex>
  </HStack>
);

export const TotalEntities: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  return complianceSummary(
    'Total Entities',
    formatNumber(summary.data?.total_entities, 1),
    summary.isLoading || blueprints.isLoading,
    IconTypes.Compute,
    'sensitiveDataBg',
  );
};

export const Policies: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  return complianceSummary(
    'Total Policies',
    formatNumber(summary.data?.check_stats?.rules_executed, 1),
    summary.isLoading || blueprints.isLoading,
    IconTypes.List,
    'blue.300',
  );
};

export const EnforcedChecks: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  const passed = sum(values(summary.data?.check_stats?.passed_details));
  const failed = sum(values(summary.data?.check_stats?.failed));

  return complianceSummary(
    'Enforced Checks',
    formatNumber(passed + failed, 1),
    summary.isLoading || blueprints.isLoading,
    IconTypes.List,
    'skyBlue.300',
  );
};

export const PassedChecks: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  const passed = sum(values(summary.data?.check_stats?.passed_details));
  const failed = sum(values(summary.data?.check_stats?.failed));
  const percent = ((passed / (passed + failed)) * 100).toFixed(0);

  return complianceSummary(
    'Passed Policies',
    formatNumber(passed, 1) + ' (' + percent + '%)',
    summary.isLoading || blueprints.isLoading,
    IconTypes.CheckmarkCircle,
    'passed',
  );
};

export const FailedChecks: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  const passed = sum(values(summary.data?.check_stats?.passed_details));
  const failed = sum(values(summary.data?.check_stats?.failed));
  const percent = ((failed / (passed + failed)) * 100).toFixed(0);

  return complianceSummary(
    'Failed Checks',
    formatNumber(failed, 1) + ' (' + percent + '%)',
    summary.isLoading || blueprints.isLoading,
    IconTypes.CloseCircle,
    'critical',
  );
};

export const CheckExceptions: FC = () => {
  const { summary, blueprints } = useSelector(selectCompliance);
  return complianceSummary(
    'Check Exceptions',
    formatNumber(summary.data?.check_stats?.rule_exceptions, 1),
    summary.isLoading || blueprints.isLoading,
    IconTypes.Disable,
    'yellow.300',
  );
};
