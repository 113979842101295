import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Stack } from '@chakra-ui/react';
import { usePrevious } from 'react-use';

interface IFullHeight {
  children(height: number, width: number): string | ReactNode;
}

export const WithParentSize: FC<IFullHeight> = props => {
  const { children, ...rest } = props;

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const prevHeight = usePrevious(height);
  const prevWidth = usePrevious(width);
  const ref = useRef(null);

  const updateDimension = useCallback(
    (el: Element) => {
      const { height, width } = window.getComputedStyle(el);
      if (prevHeight !== parseInt(height)) {
        setHeight(parseInt(height));
      }
      if (prevWidth !== parseInt(height)) {
        setWidth(parseInt(width));
      }
    },
    [prevHeight, prevWidth],
  );

  const [observer] = useState(
    new ResizeObserver(entries => {
      for (const e of entries) {
        // console.log(e.target);
        updateDimension(e.target);
      }
    }),
  );

  useEffect(() => {
    const el: any = ref.current;
    if (el) {
      observer.observe(el);
      updateDimension(el);
      return () => {
        el && observer.unobserve(el);
      };
    }
  }, [observer, prevHeight, prevWidth, updateDimension]);

  return (
    <Stack ref={ref} spacing={0} h={'full'} w={'full'} {...rest}>
      {children(height, width)}
    </Stack>
  );
};
