import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, HStack, Stack, Text } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import {
  renderRiskContextIcon,
  renderSeverityBar,
} from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { PrimaryIconButton } from 'components/DataEntry';
import { ViewAllIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { EnvironmentName } from 'containers/App/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { useBlueprintsMapping } from 'containers/SharedState/hooks/useBlueprintsMapping';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { limitedString, toTitleCase } from 'utils/string';

export const AlertDetails: FC = () => {
  const { evidence } = useSelector(selectSecurityGraphNext);
  const { alertDetails, riskContext, compliance } = evidence;
  const { data } = alertDetails;
  const { toCloudAccountId } = useCloudAccountId();
  const { getBlueprint } = useBlueprintsMapping();
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();

  return (
    <Stack spacing={3}>
      <SidebarAccordion header="DETAILS">
        <Stack spacing={3}>
          <DetailsField
            label="Name"
            align="flex-start"
            value={
              <HStack align="flex-start">
                <Box>
                  <CustomTooltip label={data.entity_name ?? data.entity_id}>
                    {limitedString(data.entity_name ?? data.entity_id, 20)}
                  </CustomTooltip>
                </Box>
                <PrimaryIconButton
                  aria-label="view details"
                  tooltip="View resource details"
                  isDisabled={alertDetails.isLoading}
                  size="xs"
                  borderRadius={6}
                  p={1}
                  onClick={() => {
                    updateActiveResource({
                      resourceType: alertDetails.data
                        .resource as NativeResources,
                      uuid: alertDetails.data.entity_uuid!,
                      accountId: alertDetails.data.account_id!,
                      resourceId: alertDetails.data.entity_id!,
                    });
                    onOpenMetadata();
                  }}
                >
                  <ViewAllIcon />
                </PrimaryIconButton>
              </HStack>
            }
          />
          <DetailsField
            label="Cloud"
            value={
              <HStack align="flex-start" spacing={1}>
                <Center>
                  <Box boxSize={5}>{getIcon(data.cloud?.toLowerCase()!)}</Box>
                </Center>
                <Box>{toCloudAccountId(data.account_id)}</Box>
              </HStack>
            }
          />
          <DetailsField
            label="State"
            value={
              data?.snooze
                ? 'Snoozed'
                : data.status === 'ACTIVE'
                ? 'Open'
                : toTitleCase(data.status)
            }
          />
          <DetailsField
            label="Severity"
            value={renderSeverityBar({ value: data.severity, reversed: true })}
          />
          <DetailsField
            label="Environment"
            value={
              <CustomTooltip
                label={<EnvironmentName environmentId={data.environment_id} />}
              >
                <EnvironmentName
                  environmentId={data.environment_id}
                  limitedStringNumber={18}
                />
              </CustomTooltip>
            }
            styles={{
              value: {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          />
          <DetailsField
            label="Blueprint"
            value={
              <HStack spacing={0} color="primary">
                {data?.blueprint_ids?.[0] && (
                  <Text>
                    {limitedString(
                      getBlueprint(data.blueprint_ids[0])?.name,
                      24,
                    )}
                  </Text>
                )}
                {data?.blueprint_ids?.length &&
                  data?.blueprint_ids?.length > 1 && (
                    <Text>, +{data.blueprint_ids?.length - 1}</Text>
                  )}
              </HStack>
            }
          />
          <DetailsField
            label="Risk Context"
            align="center"
            value={
              riskContext.isLoading ? (
                <CustomSpinner />
              ) : riskContext.data?.length ? (
                <HStack spacing={1}>
                  {map(riskContext.data, o => renderRiskContextIcon(o))}
                </HStack>
              ) : (
                'None'
              )
            }
          />
          <DetailsField
            label="Compliance"
            value={
              compliance.isLoading ? (
                <CustomSpinner />
              ) : (
                <HStack spacing={1}>
                  {map(compliance.data, o => (
                    <CustomTooltip label={o}>
                      <Box boxSize={5}>{getIcon(o)}</Box>
                    </CustomTooltip>
                  ))}
                </HStack>
              )
            }
          />
        </Stack>
      </SidebarAccordion>
    </Stack>
  );
};
