import React, { useEffect, useState } from 'react';

import { forEach, map } from 'lodash';
import { useSelector } from 'react-redux';

import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { formatDate } from 'utils/date';

import { TotalCard } from './TotalCard';

export const DataFindings = () => {
  const { dataFindings, totalDataFindings } = useSelector(selectDataDashboard);
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setChartData(
      map(dataFindings.data?.alert_history, o => ({
        Date: formatDate(o?.created_at, 'D-MMM'),
        Count: o?.CRITICAL + o?.MEDIUM + o?.HIGH + o?.LOW,
      })),
    );
  }, [dataFindings.data]);

  useEffect(() => {
    let t = 0;
    forEach(totalDataFindings.data?.summary?.['Data Security'], o => {
      t =
        t +
        (o?.severity?.CRITICAL ?? 0) +
        (o?.severity?.HIGH ?? 0) +
        (o?.severity?.MEDIUM ?? 0) +
        (o?.severity?.LOW ?? 0);
    });
    setTotal(t);
  }, [totalDataFindings.data]);

  return (
    <TotalCard
      label="Data Findings"
      total={total}
      chartData={chartData}
      isLoading={dataFindings.isLoading || totalDataFindings.isLoading}
    />
  );
};
