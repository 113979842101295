import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RdsCertificateIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <path
          d="M492.4,262.8h-123c-10.8,0-19.6,8.8-19.6,19.6v168.6c0,10.8,8.8,19.6,19.6,19.6H435v38.4l19.4-20.8l20.2,20
			v-37.6h17.8c10.8,0,19.6-8.8,19.6-19.6V282.5C512,271.6,503.2,262.8,492.4,262.8z M454.2,448.2c-6.6,0-11.9-5.3-11.9-11.9
			s5.3-11.9,11.9-11.9s11.9,5.3,11.9,11.9S460.8,448.2,454.2,448.2z M454.1,471.7l-7.5,8v-21.3c2.4,0.8,5,1.3,7.6,1.3
			c3.1,0,6.1-0.6,8.8-1.7v22.5L454.1,471.7z M500.4,451.1c0,4.5-3.6,8.1-8.1,8.1h-17.8v-11.2c2-3.4,3.1-7.4,3.1-11.6
			c0-12.9-10.5-23.5-23.5-23.5c-12.9,0-23.5,10.5-23.5,23.5c0,5,1.6,9.6,4.3,13.4v9.4h-65.7c-4.5,0-8.1-3.6-8.1-8.1V282.5
			c0-4.5,3.6-8.1,8.1-8.1h123c4.5,0,8.1,3.6,8.1,8.1V451.1z"
        />
        <rect x="399.1" y="299.5" width="65" height="11.6" />
        <rect x="374.7" y="333.3" width="112.3" height="11.6" />
        <rect x="374.7" y="355.7" width="112.3" height="11.6" />
        <rect x="374.7" y="379.2" width="112.3" height="11.6" />
      </g>
      <path
        d="M288,439.6H168.7V313.1H290v2.1c5.5-12.1,12.5-23.4,20.8-33.6V171h125.9v52.8c7.3,0.2,14.4,0.9,21.4,2.1v-75.7
		h-0.6v-123c0-13.4-10.9-24.3-24.3-24.3H310.8H147.9H24.3C10.9,2.9,0,13.8,0,27.2v409c0,13.4,10.9,24.3,24.3,24.3h123.6h150.6
		C294.5,453.8,291,446.8,288,439.6z M310.8,23.7h122.5c1.9,0,3.5,1.6,3.5,3.5v123H310.8V23.7z M168.7,23.7H290v126.5H168.7V23.7z
		 M168.7,171H290v121.3H168.7V171z M147.9,439.6H24.3c-1.9,0-3.5-1.6-3.5-3.5v-123h127.1V439.6z M147.9,292.3H21.4V171h126.5V292.3z
		 M147.9,150.2H20.8v-123c0-1.9,1.6-3.5,3.5-3.5h123.6V150.2z"
      />
    </g>
  </Icon>
);
