import {
  ContextApiGetContextResourceCountRequest,
  ContextResourceResponse,
  ResourceApiGetIdentityInsightsRequest,
} from '@ariksa/inventory-core/api';
import { GetSummaryResponse } from '@ariksa/jit/api';
import {
  AggregatedAlertsSummary,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertsApiGetResolvedAlertSummaryRequest,
  AlertSummaryResponse,
  ResolvedSummaryResponse,
} from '@ariksa/notification/api';
import {
  ChartsApiGetIdentitiesHistoryRequest,
  InventoryApiGetResourceCountRequest,
  ResourceCountResponse,
} from '@ariksa/reporting/api';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //identity authorization
  identityAuthorizationResolvedAlerts: QueryState.init(
    {} as ResolvedSummaryResponse,
  ),
  identityAuthorizationOpenAlerts: QueryState.init({} as AlertSummaryResponse),
  identityAuthorizationFindingsCategorySummary: QueryState.init([]),
  identitiesWithHighPrivileges: QueryState.init({} as ContextResourceResponse),
  identitiesWithExcessPermissions: QueryState.init(
    {} as ContextResourceResponse,
  ),
  identitiesWithAccessToSensitiveData: QueryState.init(
    {} as ContextResourceResponse,
  ),
  identitiesHistory: QueryState.init({}),
  jitSummary: QueryState.init({} as GetSummaryResponse),
  insightsForNonHumanIdentities: QueryState.init({}),

  federatedUsersChange: QueryState.init({} as ResourceCountResponse),
  saasUsersChange: QueryState.init({} as ResourceCountResponse),
  identityRolesChange: QueryState.init({} as ResourceCountResponse),
  nonUsersChange: QueryState.init({} as ResourceCountResponse),
  cloudUsersChange: QueryState.init({} as ResourceCountResponse),
};

const identityOverviewSlice = createSlice({
  name: 'identityOverview',
  initialState,
  reducers: {
    /*--------------------------Identity Authorization------------------------*/
    getIdentitiesWithHighPrivileges(
      state,
      action: QueryAction<
        ContextResourceResponse,
        ContextApiGetContextResourceCountRequest
      >,
    ) {
      state.identitiesWithHighPrivileges = QueryState.next(
        state.identitiesWithHighPrivileges,
        action,
      );
    },
    getIdentitiesWithExcessPermissions(
      state,
      action: QueryAction<
        ContextResourceResponse,
        ContextApiGetContextResourceCountRequest
      >,
    ) {
      state.identitiesWithExcessPermissions = QueryState.next(
        state.identitiesWithExcessPermissions,
        action,
      );
    },
    getIdentitiesWithAccessToSensitiveData(
      state,
      action: QueryAction<
        ContextResourceResponse,
        ContextApiGetContextResourceCountRequest
      >,
    ) {
      state.identitiesWithAccessToSensitiveData = QueryState.next(
        state.identitiesWithAccessToSensitiveData,
        action,
      );
    },
    //change apis
    getFederatedUsersChange(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.federatedUsersChange = QueryState.next(
        state.federatedUsersChange,
        action,
      );
    },
    getCloudUsersChange(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.cloudUsersChange = QueryState.next(state.cloudUsersChange, action);
    },
    getSaaSUsersChange(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.saasUsersChange = QueryState.next(state.saasUsersChange, action);
    },
    getNonUsersChange(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.nonUsersChange = QueryState.next(state.nonUsersChange, action);
    },
    getIdentityRolesChange(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.identityRolesChange = QueryState.next(
        state.identityRolesChange,
        action,
      );
    },

    getIdentityAuthorizationOpenAlerts(
      state,
      action: QueryAction<
        AlertSummaryResponse,
        AlertsApiGetAlertSummaryRequest
      >,
    ) {
      state.identityAuthorizationOpenAlerts = QueryState.next(
        state.identityAuthorizationOpenAlerts,
        action,
      );
    },

    getIdentityAuthorizationResolvedAlerts(
      state,
      action: QueryAction<
        ResolvedSummaryResponse,
        AlertsApiGetResolvedAlertSummaryRequest
      >,
    ) {
      state.identityAuthorizationResolvedAlerts = QueryState.next(
        state.identityAuthorizationResolvedAlerts,
        action,
      );
    },

    getIdentityAuthorizationFindingsCategorySummary(
      state,
      action: QueryAction<
        AggregatedAlertsSummary[],
        AlertsApiGetFindingsCategoriesSummaryRequest
      >,
    ) {
      state.identityAuthorizationFindingsCategorySummary = QueryState.next(
        state.identityAuthorizationFindingsCategorySummary,
        action,
      );
    },

    getIdentitiesHistory(
      state,
      action: QueryAction<
        Record<string, any>,
        ChartsApiGetIdentitiesHistoryRequest
      >,
    ) {
      state.identitiesHistory = QueryState.next(
        state.identitiesHistory,
        action,
      );
    },

    getJitSummary(state, action: QueryAction<GetSummaryResponse>) {
      state.jitSummary = QueryState.next(state.jitSummary, action);
    },

    getInsightsForNonHumanIdentities(
      state,
      action: QueryAction<
        Record<string, number>,
        ResourceApiGetIdentityInsightsRequest
      >,
    ) {
      state.insightsForNonHumanIdentities = QueryState.next(
        state.insightsForNonHumanIdentities,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = identityOverviewSlice;
