/**
 *
 * Email Integration
 *
 */

import React, { FC } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Form } from 'app/components';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

interface Props {
  onSubmit: (data: any) => void;
  setEmailIDs: (emailIDs: string[]) => void;
  emailIDs: string[];
}

export const EmailForm: FC<Props> = props => {
  const { onSubmit, emailIDs, setEmailIDs } = props;
  const { client } = useSelector(selectIntegrations);
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();

  return (
    <Form
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          defaultValue: currentRecord?.name,
          tooltip: 'Only alphanumeric, underscore,period allowed',
          isDisabled: client.isLoading,
        },
        email_group: {
          type: 'emailIds',
          label: 'Username: Email IDs (Press enter to add)',
          value: emailIDs,
          onChange: setEmailIDs,
          isRequired: true,
        },
      }}
      buttonOptions={{
        submit: {
          name: actionType === 'Add' ? 'Okay' : actionType,
          isLoading: client.isLoading,
          isDisabled: client.isLoading || isEmpty(emailIDs),
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      handleSubmit={onSubmit}
    />
  );
};
