import React from 'react';

import { Resources } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { ServerlessIcon } from 'components/Icons';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getVulnerabilityCountInfo } from 'containers/Dashboard/VulnerabilityOverview/Components/utils';

import { selectVulnerabilityOverview } from '../selectors';

export const Serverless: React.FC = () => {
  const { vulnerabilitiesCount } = useSelector(selectVulnerabilityOverview);
  const { data, isLoading } = vulnerabilitiesCount.serverless;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Serverless"
      icon={<ServerlessIcon />}
      change={{
        isLoading: isLoading,
        first: data.previous_count,
        last: data.current_count,
        percentChange: data.percentage_change,
      }}
      info={{
        message: getVulnerabilityCountInfo(data.vulnerability_count),
        enabled: false,
      }}
      cloudProviders={[]}
      count={data.current_count ?? 0}
      isLoading={isLoading}
      onClick={() =>
        navigate(
          '/visibility/vulnerability?tab=' + Resources.ServerlessCompute,
        )
      }
      labelTooltip={formatTooltip({
        header: 'Serverless',
        content:
          'Vulnerabilities found in packages used by serverless functions such as AWS Lambda, GCP Cloud Run and Cloud Functions and Azure Functions',
        width: 48,
      })}
    />
  );
};
