import { CheckType, Comparison, Option } from '@ariksa/inventory-core/api';
import { Check } from '@ariksa/inventory-core/dist/api';

import { renderSeverityInSelect } from 'components/DataDisplay/Utils/utils';

export const containOptions = [
  { label: 'contains', value: CheckType.Contains },
  { label: 'does not contain', value: CheckType.NotContains },
];

export const ec2OsTechOptions = [
  { label: 'OS', value: Option.Os },
  { label: 'technology', value: Option.Technology },
];

export const ec2WithVersionOrVulnerabilityOptions = [
  { label: 'with version', value: Check.Version },
  { label: 'with vulnerability', value: Check.Vulnerability },
];

export const vulnerabilityCompareOptions = [
  { label: 'equals to', value: Comparison.EqualTo },
  { label: 'greater than', value: Comparison.GreaterThan },
  { label: 'less than', value: Comparison.LessThan },
];

export const severityOptions = [
  { label: renderSeverityInSelect('critical'), value: 'Critical' },
  { label: renderSeverityInSelect('high'), value: 'High' },
  { label: renderSeverityInSelect('medium'), value: 'Medium' },
  { label: renderSeverityInSelect('low'), value: 'Low' },
];
