import React, { FC, useCallback, useEffect, useState } from 'react';

import { Button, Flex, IconButton, Select, Stack } from '@chakra-ui/react';
import { range } from 'lodash';
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi';
import { usePrevious } from 'react-use';

import { paginationStyles } from './styles';
import { DefaultPageSizes, IPagination } from './types';

export const Pagination: FC<IPagination> = props => {
  const { totalCount = 0, onChange, pageInfo, styles } = props;
  const [pageSizes] = useState(props.pageSizes || DefaultPageSizes);

  if (pageInfo.page_number < 1) {
    throw Error(
      `minimum pageNumber is 1, but the provided value is less than 1`,
    );
  }
  if (pageInfo.page_size < 1) {
    throw Error(`minimum pageSize is 1, but the provided value is less than 1`);
  }

  const [currentPage, setCurrentPage] = useState(pageInfo.page_number);
  const [itemsPerPage, setItemsPerPage] = useState(pageInfo.page_size);
  const prevPageSize = usePrevious(itemsPerPage);
  // const prevPageSizes = usePrevious(pageSizes);
  const prevCurrentPage = usePrevious(currentPage);
  const prevItemsPerPage = usePrevious(itemsPerPage);

  useEffect(() => {
    if (pageInfo.page_number !== prevCurrentPage) {
      setCurrentPage(pageInfo.page_number);
    }
  }, [pageInfo.page_number, prevCurrentPage]);

  useEffect(() => {
    if (pageInfo.page_size !== prevPageSize) {
      setItemsPerPage(pageInfo.page_size);
    }
  }, [pageInfo.page_size, prevPageSize]);

  // maxPage >= 1
  const [maxPage, setMaxPage] = useState(1);
  const [startItemNumber, setStartItemNumber] = useState(0);
  const [endItemNumber, setEndItemNumber] = useState(0);

  useEffect(() => {
    setMaxPage(Math.max(Math.ceil(totalCount / itemsPerPage), 1));
  }, [itemsPerPage, totalCount]);

  // handle page change
  useEffect(() => {
    // if there are no items set start and end number to zero
    if (totalCount === 0) {
      setStartItemNumber(0);
      setEndItemNumber(0);
    } else if (prevPageSize !== itemsPerPage) {
      // move to start page on page size change
      const currentPage = 1;
      const itemNumber = (currentPage - 1) * itemsPerPage;
      setStartItemNumber(itemNumber + 1);
      setEndItemNumber(Math.min(currentPage * itemsPerPage, totalCount));
      setCurrentPage(currentPage);
    } else {
      const itemNumber = (currentPage - 1) * itemsPerPage;
      setStartItemNumber(itemNumber + 1);
      setEndItemNumber(Math.min(currentPage * itemsPerPage, totalCount));
    }
  }, [currentPage, totalCount, itemsPerPage, prevPageSize]);

  function next() {
    setCurrentPage((currentPage: number) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage: number) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.min(Math.max(1, page), maxPage);
    setCurrentPage(pageNumber);
  }

  const handleUpdate = useCallback(
    (pageNumber, itemsPerPage) => {
      onChange({ page_number: pageNumber, page_size: itemsPerPage });
    },
    [onChange],
  );

  const handlePageChange = useCallback(
    pageNumber => handleUpdate(pageNumber, itemsPerPage),
    [handleUpdate, itemsPerPage],
  );

  useEffect(() => {
    if (prevCurrentPage !== currentPage || itemsPerPage !== prevItemsPerPage) {
      if (
        currentPage !== pageInfo.page_number ||
        itemsPerPage !== pageInfo.page_size
      ) {
        handleUpdate(currentPage, itemsPerPage);
      }
    }
  }, [
    currentPage,
    handleUpdate,
    itemsPerPage,
    pageInfo,
    prevCurrentPage,
    prevItemsPerPage,
  ]);

  const handleItemsPerPageChange = (e: any) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  if (props.isLoading || totalCount === 0) {
    return <></>;
  }

  const renderPageNumberButtons = (startNumber, endNumber) => {
    return range(startNumber, endNumber + 1).map(number => (
      <Button
        bg="transparent"
        size={'sm'}
        onClick={() => handlePageChange(number)}
        key={number}
        fontWeight={number === currentPage ? 'bold' : 'normal'}
      >
        {number}
      </Button>
    ));
  };

  const renderPageNumbers = () => {
    // if (maxPage <= 5) {
    return (
      <Stack isInline spacing={1}>
        {renderPageNumberButtons(currentPage, currentPage)}
      </Stack>
    );
    // }
  };

  return (
    <Flex {...paginationStyles} right={0} {...styles}>
      {/*<Flex>{text ? text : 'Rows per page'} &nbsp;</Flex>*/}

      <Stack isInline spacing={'1px'} alignItems={'center'}>
        <IconButton
          bg="transparent"
          size={'sm'}
          aria-label="Prev page"
          icon={<FiChevronsLeft />}
          onClick={() => jump(0)}
        />
        <IconButton
          bg="transparent"
          size={'sm'}
          aria-label="Prev page"
          icon={<FiChevronLeft />}
          onClick={() => prev()}
        />
        <Flex mx={2}>{renderPageNumbers()}</Flex>
        <IconButton
          bg="transparent"
          size={'sm'}
          aria-label="Next page"
          icon={<FiChevronRight />}
          onClick={() => next()}
        />
        <IconButton
          bg="transparent"
          size={'sm'}
          aria-label="Next page"
          icon={<FiChevronsRight />}
          onClick={() => jump(maxPage)}
        />
      </Stack>
      <Flex ml={4} w={70}>
        <Select
          value={itemsPerPage}
          size={'sm'}
          onChange={handleItemsPerPageChange}
          borderRadius={'md'}
        >
          {pageSizes.map(v => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex mx={4}>
        {startItemNumber}-{endItemNumber} of {totalCount}
      </Flex>
    </Flex>
  );
};
