import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.visibility || initialState;

export const selectVisibility = createSelector(
  [selectDomain],
  visibilityState => visibilityState,
);
