import {
  CountSummary,
  CVEDetailPagination,
  DefaultApiGetCvesByIdRequest,
  InstanceScanRead,
  InstanceSummary,
  ItemApiGetInstanceCountSummaryRequest,
  ItemApiGetInstanceScanByIdRequest,
  ItemApiGetInstanceSummaryByIdRequest,
  ItemApiGetVulnerabilityByIdRequest,
  LoginActivity,
  LoginActivityType,
  ScanTypes,
  SummaryType,
  Vulnerability,
} from '@ariksa/scan-analysis/api';
import { useSelector } from 'react-redux';

import { ScanAnalysis } from 'api/services';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useScanAnalysisItemService(
  accountId: string,
  instanceId: string,
  activityType: LoginActivityType,
) {
  // https://dev.ariksa.io/docs/scan-analysis#/item/get_instance_activity_by_id
  //const { accountId } = useSelector(selectActiveCloudAccount);

  const instanceTechnologyById = useAriksaQuery<any, LoginActivity[]>({
    queryKey: [instanceId, accountId, activityType],
    fetcher: req =>
      ScanAnalysis.Item.getInstanceActivityById({
        instanceId,
        accountId,
        activityType,
      }).then(r => r.data),
    config: {
      retry: false,
    },
  });

  return { instanceTechnologyById };
}

export function useInstanceScanById(instanceId: string, scanType: ScanTypes) {
  const instanceScanById = useAriksaQuery<
    ItemApiGetInstanceScanByIdRequest,
    InstanceScanRead
  >({
    queryKey: [instanceId],
    queryFn: () =>
      ScanAnalysis.Item.getInstanceScanById({
        instanceId,
        scanType,
      }).then(r => r.data),
    config: {
      retry: false,
    },
  });

  return { instanceScanById };
}

export function useEc2Summary(summaryType: SummaryType) {
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const ec2Summary = useAriksaQuery<
    ItemApiGetInstanceCountSummaryRequest,
    CountSummary
  >({
    queryKey: [environmentId, accountId, summaryType],
    queryFn: () =>
      ScanAnalysis.Item.getInstanceCountSummary({
        environmentId,
        accountId: accountId ? [accountId] : [],
        summaryType,
      }).then(r => r.data),
    config: {
      retry: false,
    },
  });

  return { ec2Summary };
}

export function useInstanceScamSummaryById() {
  const instanceScamSummary = useAriksaQuery<
    ItemApiGetInstanceSummaryByIdRequest,
    InstanceSummary
  >({
    queryKey: [],
    fetcher: req =>
      ScanAnalysis.Item.getInstanceSummaryById(req).then(r => r.data),
  });

  return { instanceScamSummary };
}

export function useCvesById() {
  const cvesById = useAriksaQuery<
    DefaultApiGetCvesByIdRequest,
    CVEDetailPagination
  >({
    queryKey: [],
    fetcher: req => ScanAnalysis.Default.getCvesById(req).then(r => r.data),
  });

  return { cvesById };
}
