import {
  ConfigApiGetOutpostConfigRequest,
  ConfigData,
  ResourceApiGetZonesByRegionForAccountRequest,
} from '@ariksa/inventory-core/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { InventoryService } from 'api/services';
import { doGetInstanceInventory } from 'containers/Inventory/CloudInventory/saga';
import {
  doGetCloudAccountDataScannerInfo,
  doGetCloudAccountVulnerabilityScanner,
} from 'containers/Setup/CloudAccounts/saga';

import { actions } from './slice';

export function* outpostSaga() {
  yield takeLatestAction(
    actions.getVulnerabilityScanners,
    doGetCloudAccountVulnerabilityScanner,
  );
  yield takeLatestAction(
    actions.getDataScanners,
    doGetCloudAccountDataScannerInfo,
  );
  yield takeLatestAction(actions.getResourceDetails, doGetInstanceInventory);
  yield takeLatestAction(actions.generateOutpostToken, doGenerateOutpostToken);
  yield takeLatestAction(actions.getZones, doGetZones);
  yield takeLatestAction(actions.getOutpostConfig, doGetOutpostConfig);
}

function* doGenerateOutpostToken(ctx: QueryContext<any>) {
  //yield call(ctx.fetch, () => );
}

export function* doGetZones(
  ctx: QueryContext<string[], ResourceApiGetZonesByRegionForAccountRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getZonesByRegionForAccount(ctx.params),
    {
      errorMsg: 'Failed to get zones!',
    },
  );
}

export function* doGetOutpostConfig(
  ctx: QueryContext<ConfigData, ConfigApiGetOutpostConfigRequest>,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Config.getOutpostConfig(ctx.params),
    {
      errorMsg: 'Failed to get outpost config!',
    },
  );
}
