import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SagemakerMachineLearningIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M189.3,25C189.3,25,189.3,25,189.3,25c-24.6,0-44.9,12.6-54.1,33.3h-8
	c-0.1,0-0.1,0-0.1,0c-21.2,0-41.3,7.1-56.6,20c-16.3,13.7-25.2,32.5-25.3,53c0,16.6,8.8,34.9,17.1,46.5C25.2,190.7,0.1,221.8,0,256
	c-0.1,34.1,25.1,65.2,62.2,78.1c-8.4,11.5-17.4,29.8-17.4,46.1c0,40.4,36.9,73.4,82.4,73.5h8c9.1,20.6,29.4,33.3,54.1,33.3
	c0,0,0.1,0,0.1,0c32.9,0,55.8-23,55.9-56V81C245.4,48.1,222.4,25.1,189.3,25z M189.3,308.3c-5.9,0-10.7-4.8-10.7-10.7
	s4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7S195.2,308.3,189.3,308.3z M233.3,225h-38V119.4c9.6-2.6,16.7-11.3,16.7-21.7
	c0-12.5-10.2-22.7-22.7-22.7c-12.5,0-22.7,10.2-22.7,22.7c0,10.4,7.1,19.1,16.7,21.7V225H156c0,0,0,0,0,0
	c-13.3,0-22.6,9.3-22.6,22.7v10.7H94.4c-2.6-9.6-11.3-16.7-21.7-16.7c-12.5,0-22.7,10.2-22.7,22.7S60.2,287,72.7,287
	c10.4,0,19.1-7.1,21.7-16.7h38.9v97.2c-9.6,2.6-16.7,11.3-16.7,21.8c0,12.5,10.2,22.7,22.7,22.7s22.7-10.2,22.7-22.7
	c0-10.4-7.1-19.1-16.7-21.8V247.7c0-6.7,4-10.7,10.6-10.7h0h77.3v194c-0.1,24.3-15.1,41-38,43.5v-155c9.6-2.6,16.7-11.4,16.7-21.8
	c0-12.5-10.2-22.7-22.7-22.7c-12.5,0-22.7,10.2-22.7,22.7c0,10.4,7.1,19.1,16.7,21.8v155.2c-18.4-1.9-32.5-12.4-38.3-28.9
	c-0.9-2.4-3.1-4-5.7-4h-12.1c-33.9-0.1-70.5-23.6-70.5-61.4c0-16.1,11.9-36.8,20.1-45c1.5-1.5,2.1-3.8,1.5-5.8
	c-0.6-2.1-2.2-3.7-4.3-4.2C37.5,315.7,12,287.3,12,256c0.1-31.3,25.4-59.5,61.6-69h44c2.7,9.6,11.3,16.7,21.8,16.7
	c12.5,0,22.7-10.2,22.7-22.7c0-12.5-10.2-22.6-22.7-22.6c-10.4,0-19.1,7.1-21.7,16.7H75.4c-8-9-18.2-28.2-18.2-43.6
	c0-16.9,7.5-32.5,21-43.9c13-10.9,30.8-17.2,48.9-17.2c0,0,0.1,0,0.1,0h12.1c2.5,0,4.8-1.6,5.6-4C151.5,48,168.1,37,189.2,37
	c0,0,0.1,0,0.1,0c26.4,0.1,44.1,17.7,44,44V225z M189.3,108.3c-5.9,0-10.7-4.8-10.7-10.7c0-5.9,4.8-10.7,10.7-10.7
	c5.9,0,10.7,4.8,10.7,10.7C200,103.6,195.2,108.3,189.3,108.3z M139.3,378.7c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7
	c-5.9,0-10.7-4.8-10.7-10.7C128.7,383.4,133.5,378.7,139.3,378.7z M83.3,264.3c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
	c0-5.9,4.8-10.7,10.7-10.7C78.6,253.7,83.3,258.5,83.3,264.3z M128.7,181c0-5.9,4.8-10.7,10.7-10.7S150,175.1,150,181
	c0,5.9-4.8,10.7-10.7,10.7C133.5,191.7,128.7,186.9,128.7,181z M512,256c0-34.1-25.1-65.1-62.2-78.1c8.4-11.5,17.4-29.8,17.4-46.1
	c0-20.5-9-39.4-25.4-53.2c-15.3-12.8-36-20.2-57-20.2h-8c-9.1-20.6-29.4-33.3-54-33.3c0,0-0.1,0-0.1,0c-16.2,0-30.1,5.4-40.3,15.5
	c-10.2,10.2-15.6,24.2-15.6,40.5v350c0,32.9,23,55.9,56,56c0.1,0,0.1,0,0.1,0c24.5,0,44.8-12.7,54-33.3h8c0.1,0,0.1,0,0.2,0
	c21.2,0,41.3-7.1,56.6-20c16.3-13.7,25.2-32.5,25.3-53c0-16.6-8.8-35-17.1-46.5C486.8,321.3,511.9,290.2,512,256z M322.7,203.7
	c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7S316.8,203.7,322.7,203.7z M438.4,325h-44
	c-2.6-9.6-11.4-16.7-21.8-16.7c-12.5,0-22.7,10.2-22.7,22.7c0,12.5,10.2,22.7,22.7,22.7c10.4,0,19.1-7.1,21.8-16.7h42.2
	c7.9,9,18.2,28.2,18.1,43.6c0,16.9-7.5,32.5-21,43.8c-13,11-31.1,16.8-49,17.2h-12.1c-2.5,0-4.8,1.6-5.7,4
	c-6.5,18.4-23.1,29.3-44.2,29.3c0,0-0.1,0-0.1,0c-13,0-24.1-4.2-32-12.1c-7.9-7.9-12.1-18.9-12-31.9V287h38v105.6
	c-9.6,2.6-16.7,11.3-16.7,21.8c0,12.5,10.2,22.7,22.7,22.7c12.5,0,22.7-10.2,22.7-22.7c0-10.4-7.1-19.1-16.7-21.8V287H356
	c0,0,0,0,0.1,0c13.3,0,22.6-9.3,22.6-22.7v-10.7h38.9c2.7,9.6,11.4,16.7,21.8,16.7c12.5,0,22.7-10.2,22.7-22.7S451.8,225,439.3,225
	c-10.4,0-19.1,7.1-21.8,16.7h-38.9v-97.2c9.6-2.7,16.7-11.3,16.7-21.7c0-12.5-10.2-22.7-22.7-22.7S350,110.2,350,122.7
	c0,10.4,7.1,19.1,16.7,21.7v119.9c0,6.7-4,10.7-10.6,10.7c0,0,0,0,0,0h-77.3V81c0-13,4.2-24.1,12.1-32c6.6-6.6,15.5-10.4,25.9-11.5
	v155.1c-9.6,2.7-16.7,11.4-16.7,21.8c0,12.5,10.2,22.7,22.7,22.7s22.7-10.2,22.7-22.7c0-10.4-7.1-19.1-16.7-21.8V37.4
	c18.4,1.9,32.5,12.4,38.3,28.9c0.9,2.4,3.1,4,5.7,4h12.1c18.2,0,36.2,6.4,49.3,17.4c13.7,11.5,21.2,27.1,21.1,44
	c0,16.1-11.9,36.8-20.1,45c-1.5,1.5-2.1,3.8-1.6,5.8c0.6,2.1,2.2,3.7,4.3,4.2c36.6,9.5,62.2,37.9,62.1,69.2
	C500,287.2,474.7,315.5,438.4,325z M383.3,331c0,5.9-4.8,10.7-10.7,10.7S362,336.9,362,331c0-5.9,4.8-10.7,10.7-10.7
	S383.3,325.1,383.3,331z M322.7,403.7c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7
	C312,408.4,316.8,403.7,322.7,403.7z M372.7,133.3c-5.9,0-10.7-4.8-10.7-10.7c0-5.9,4.8-10.7,10.7-10.7s10.7,4.8,10.7,10.7
	S378.5,133.3,372.7,133.3z M428.7,247.7c0-5.9,4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7
	C433.4,258.3,428.7,253.6,428.7,247.7z"
    />
  </Icon>
);
