import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ElasticacheIcon = props => (
  <Icon viewBox="0 0 52 52" w="full" h="full" color="inherit" {...props}>
    <path d="M50,3.2c0-1-0.6-1.2-0.9-1.2H28.4c-1.5,0-1.5,1.5-1.5,1.5v2.9c0,1.3,0.8,1.6,1.2,1.6h14.4 c0.6,0.1,1.5,0.5,1.5,2.1v13.6c0.1,0.4,0.3,1.3,1.5,1.3h3.2c0,0,1.3,0,1.3-1.5V3.2z" />
    <path d="M37.5,15.7c0.1-1-0.6-1.2-0.9-1.2H15.9c-1.5,0-1.5,1.5-1.5,1.5v2.9c0,1.3,0.8,1.6,1.2,1.6H30 c0.6,0.1,1.5,0.5,1.5,2.1v13.6c0.1,0.4,0.3,1.3,1.5,1.3h3.2c0,0,1.3,0,1.3-1.5V15.7z" />
    <path d="M5.3,48.8c0.2,0.2,1,0.8,2.1-0.3l10.4-10.4c0.3-0.3,1.2-1,1.2,0.4v10.2c0.1,0.4,0.3,1.3,1.5,1.3h3.2 c0,0,1.3,0,1.3-1.5V28.2c0-1-0.6-1.1-0.9-1.2H3.4c-1.5,0-1.5,1.5-1.5,1.5v2.9c0,1.3,0.8,1.6,1.2,1.6h9.6c0.4,0,1.6,0.1,0.5,1.2l0,0 l-10,10.2c0,0-1.2,1.1-0.2,2.1L5.3,48.8z" />
  </Icon>
);
