import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { selectCompliance } from 'containers/Compliance/selectors';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

interface Props {
  resource?: Record<string, any>;
  blueprint?: Record<string, any>;
}

export const ComplianceChartDetails: FC<Props> = props => {
  const { resource, blueprint } = props;
  const { selectedBlueprint } = useSelector(selectCompliance);
  const { selectedResource } = useCompliance();

  const { toCloudAccountId } = useCloudAccountId();

  return (
    <Stack>
      <DetailsField
        label="Blueprint"
        value={blueprint?.name ?? selectedBlueprint?.name}
      />
      {(resource?.account_id || selectedResource?.account_id) && (
        <DetailsField
          label="Account"
          value={toCloudAccountId(
            resource?.account_id ?? selectedResource?.account_id,
          )}
        />
      )}
      {(resource?.type || selectedResource?.type) && (
        <DetailsField
          label="Type"
          value={selectedResource?.type ?? resource?.type}
        />
      )}
      {(selectedResource?.sub_control || selectedResource?.control) && (
        <DetailsField
          label={selectedResource?.sub_control ? 'Sub-Control' : 'Control'}
          value={selectedResource?.sub_control || selectedResource?.control}
        />
      )}
    </Stack>
  );
};
