import { customTheme } from 'theme';

export const sunburstColors = [
  customTheme.colors.skyBlue['300'],
  customTheme.colors.primary,
  customTheme.colors.yellow['300'],
  customTheme.colors.blue['300'],
  customTheme.colors.red['300'],
  customTheme.colors.indigo['300'],
  customTheme.colors.green['300'],
  customTheme.colors.orange['300'],
  customTheme.colors.gray['300'],
];
