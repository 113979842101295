export const defaultStyles = {
  inputBox: {
    fontSize: 'fontSize',
    h: 'full',
    w: 'full',
    borderRadius: 'fieldBorderRadius',
    py: '6px',
    px: 3,
    border: '1px solid',
    borderColor: 'formFieldBorder',
    _hover: {
      borderColor: 'primary',
    },
  },
};
