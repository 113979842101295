import React from 'react';

import { NativeResources } from '@ariksa/inventory-core';

import { EC2Vulnerability } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Vulnerability';
import { ECRContainerImages } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ECR/Components/ECRContainerImages';
import { ContainerVulnerability } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/ContainerVulnerability/ContainerVulnerability';

export const getVulnerability = (resourceType: NativeResources) => {
  switch (resourceType) {
    case NativeResources.Ec2Instance:
    case NativeResources.LambdaFunction:
    case NativeResources.Container:
    case NativeResources.AmazonMachineImage:
    case NativeResources.GcpComputeEngine:
    case NativeResources.GcpCloudFunctions:
    case NativeResources.GcpContainer:
      return <EC2Vulnerability />;
    case NativeResources.ElasticKubernetesService:
    case NativeResources.ElasticContainerService:
    case NativeResources.EcsCluster:
    case NativeResources.Gcpgke:
      return <ContainerVulnerability vulnerability />;
    case NativeResources.ElasticContainerRegistry:
      return <ECRContainerImages vulnerability />;
    default:
      return null;
  }
};

export const resourceVulnerabilityKey = (
  instanceId: string,
  containerId: string,
) => {
  return `${instanceId}-${containerId}`;
};
