import React from 'react';

import { Box, Center, Flex, Wrap, WrapItem } from '@chakra-ui/react';
import map from 'lodash/map';

import { Tag } from 'app/components/DataDisplay/Tag';
import { MinusIcon, PlusIcon } from 'components/Icons';

export const formatTags = tags => (
  <Wrap>
    {map(tags, (each, index) => (
      <WrapItem key={index + 'tags'}>
        <Box>
          <Tag
            label={each.Key + ' : ' + each.Value}
            styles={{
              tag: {
                mb: 1,
                border: '1px solid',
                borderColor: 'primary',
              },
              label: {
                whiteSpace: 'normal',
              },
            }}
          />
        </Box>
      </WrapItem>
    ))}
  </Wrap>
);

export const collapseExpandIcon = isExpanded => {
  const collapseExpandStyles = {
    boxSize: 3,
    borderRadius: 'full',
    border: '1px solid',
    borderColor: 'primary',
    _hover: { bg: 'primary', color: 'white' },
  };
  return (
    <Box pr={2}>
      <Flex>
        {isExpanded ? (
          <Center
            {...collapseExpandStyles}
            as={MinusIcon}
            color="white"
            bg="primary"
          />
        ) : (
          <Center {...collapseExpandStyles} color="primary" as={PlusIcon} />
        )}
      </Flex>
    </Box>
  );
};
