import { useResourceType } from 'containers/App/hooks/useResourceType';

export const useIntoGraphNodes = () => {
  const { getResourceType } = useResourceType();
  const intoGraphNodes = (nodes: Record<string, any>[]) => {
    return nodes?.map((node: Record<string, any>) => {
      return {
        id: node.identity ?? '-',
        ariksaType: getResourceType(node.native_name ?? '') ?? {
          cloud_native_name: node.native_name ?? '-',
        },
        // used in map layout calculation
        nodeType: node.native_name ?? '-',
        ...node,
      };
    });
  };

  return {
    intoGraphNodes,
  };
};
