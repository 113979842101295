import React from 'react';

import { Icon } from '@chakra-ui/react';

export const UserCanAccessIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <ellipse cx="256.7" cy="161.6" rx="52.6" ry="53.5" />
      <path d="M165.9,320.7h181.6c0-51-40.7-92.3-90.8-92.3S165.9,269.7,165.9,320.7z" />
      <path
        d="M52,103.9c4.9,0,9.6-0.7,14-1.9l54.6,63.6l24.3-20.8l-52-60.6c7-8.8,11.1-19.9,11.1-31.9
		c0-28.5-23.3-51.7-52-51.7C23.3,0.5,0,23.7,0,52.2C0,80.7,23.3,103.9,52,103.9z M52,32.5c11,0,20,8.8,20,19.7s-9,19.7-20,19.7
		s-20-8.8-20-19.7C32,41.4,41,32.5,52,32.5z"
      />
      <path
        d="M52,511.5c28.7,0,52-23.2,52-51.7c0-12-4.2-23.1-11.1-31.9l52-60.6l-24.3-20.8L66,410c-4.5-1.2-9.2-1.9-14-1.9
		c-28.6,0-52,23.2-52,51.7S23.3,511.5,52,511.5z M52,440.1c11,0,20,8.8,20,19.7c0,10.9-9,19.7-20,19.7s-20-8.8-20-19.7
		C32,448.9,41,440.1,52,440.1z"
      />
      <path
        d="M391.5,165.6L446,102c4.5,1.2,9.2,1.9,14,1.9c28.7,0,52-23.2,52-51.7c0-28.5-23.3-51.7-52-51.7
		c-28.6,0-52,23.2-52,51.7c0,12,4.2,23.1,11.1,31.9l-52,60.6L391.5,165.6z M460,32.5c11,0,20,8.8,20,19.7s-9,19.7-20,19.7
		c-11,0-20-8.8-20-19.7S449,32.5,460,32.5z"
      />
      <path
        d="M419.2,427.9c-7,8.8-11.1,19.9-11.1,31.9c0,28.5,23.3,51.7,52,51.7c28.7,0,52-23.2,52-51.7s-23.3-51.7-52-51.7
		c-4.9,0-9.6,0.7-14,1.9l-54.6-63.6l-24.3,20.8L419.2,427.9z M480,459.8c0,10.9-9,19.7-20,19.7c-11,0-20-8.8-20-19.7
		c0-10.9,9-19.7,20-19.7C471,440.1,480,448.9,480,459.8z"
      />
    </g>
  </Icon>
);
