import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ComplianceResourceIcon = props => (
  <Icon
    color="#3063F6"
    viewBox={'0 0 445.555 445.555'}
    h="full"
    w="full"
    {...props}
  >
    <polygon points="222.777,0 50.463,108.506 222.777,217.012 395.092,108.506 	" />
    <polygon
      fillOpacity="0.701961"
      points="36.388,337.631 207.777,445.555 207.777,243.019 36.388,135.095 	"
    />
    <polygon
      fillOpacity="0.403922"
      points="237.777,243.019 237.777,445.555 409.166,337.631 409.167,135.095 	"
    />
  </Icon>
);
