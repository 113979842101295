import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AnalyticsIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M499.2,486.4h-38.4V64c0-7.1-5.7-12.8-12.8-12.8s-12.8,5.7-12.8,12.8v422.4h-76.8v-320
	c0-7.1-5.7-12.8-12.8-12.8s-12.8,5.7-12.8,12.8v320H256V268.8c0-7.1-5.7-12.8-12.8-12.8c-7.1,0-12.8,5.7-12.8,12.8v217.6h-76.8
	V371.2c0-7.1-5.7-12.8-12.8-12.8c-7.1,0-12.8,5.7-12.8,12.8v115.2H25.6V12.8C25.6,5.7,19.9,0,12.8,0S0,5.7,0,12.8v486.4
	c0,7.1,5.7,12.8,12.8,12.8h486.4c7.1,0,12.8-5.7,12.8-12.8C512,492.1,506.3,486.4,499.2,486.4z"
    />
  </Icon>
);
