import React from 'react';

import { Icon } from '@chakra-ui/react';

export const PackagesIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <polygon points="280.5,201.1 280.5,501 501,414.3 501,114.3   " />
        <polygon points="11,414.3 231.5,501 231.5,201.1 11,114.3   " />
        <polygon points="256,11 58.8,79.3 256,156.9 453.2,79.3   " />
      </g>
    </g>
  </Icon>
);
