import { ButtonProps, FlexProps } from '@chakra-ui/react';

export const s3BucketTagStyles = {
  tag: {
    fontSize: 'sm',
    py: 0,
    px: 2,
    mr: 2,
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'primary',
    borderRadius: 'md',
  },
  closeButton: {
    borderRadius: 'full',
    bg: 'white',
  },
};

export const permissionStyles: { button: ButtonProps; label: FlexProps } = {
  button: {
    _hover: { bg: 'none' },
    _focus: {},
    pl: 0,
    py: 0,
    fontSize: 'sm',
  },
  label: { color: 'gray.300', whiteSpace: 'nowrap', cursor: 'pointer' },
};
