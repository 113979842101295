import React from 'react';

import { JobStatus } from '@ariksa/reporting';
import { ScheduleInterval } from '@ariksa/reporting';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';

import { StackedCell, Tag } from 'components/DataDisplay';
import { toTitleCase } from 'utils/string';

export const renderSchedule = ({ row }) => {
  const scheduled_time = row?.scheduled_time?.split(':');

  let time = dayjs
    .utc()
    .hour(scheduled_time[0])
    .minute(scheduled_time[1])
    .local()
    .format('hh:mm A');

  const renderMonth = () => {
    switch (row?.day_of_month % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  switch (row?.frequency) {
    case ScheduleInterval.Weekly:
      time = toTitleCase(row?.day_of_week);
      break;
    case ScheduleInterval.Monthly:
      time = row?.day_of_month + renderMonth() + ' of Month';
      break;
    case ScheduleInterval.NWeekly:
      time =
        row?.day_of_month + renderMonth() + ' ' + toTitleCase(row?.day_of_week);
      break;
    case ScheduleInterval.Yearly:
      time = row?.day_of_month + renderMonth() + ' ' + capitalize(row?.month);
      break;
  }
  return (
    <StackedCell
      upper={
        dayjs(row.end_date).isBefore(row?.start_date, 'minutes') ||
        dayjs().isAfter(row?.end_date, 'minutes')
          ? 'Expired'
          : row?.frequency === ScheduleInterval.NWeekly
          ? ScheduleInterval.Monthly
          : row?.frequency
      }
      lower={time}
    />
  );
};

export const renderReportStatus = ({ row }) => {
  if (!row?.report_status) return '-';
  let color = 'primary';
  switch (row?.report_status) {
    case JobStatus.Successful:
      color = 'green.300';
      break;
    case JobStatus.Complete:
      color = 'green.300';
      break;
    case JobStatus.Failed:
      color = 'red';
      break;
    case JobStatus.Expired:
      color = 'gray.200';
      break;
  }

  return (
    <Box>
      <Tag
        label={row?.report_status?.toUpperCase()?.replaceAll('_', '-')}
        styles={{
          tag: {
            bg: color,
            border: 'none',
            //w: 32,
            justifyContent: 'center',
            opacity: row?.report_status === JobStatus.InProgress ? 0.8 : 1,
          },
          label: { color: 'white' },
        }}
      />
    </Box>
  );
};
