import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SubnetIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M22.5,15h-3v-3.0006104C19.4998169,11.7234497,19.276001,
    11.4998169,19,11.5h-6.5V9h3.0006104C15.7765503,8.9998169,16.0001831,8.776001,16,
    8.5V1.4993896C15.9998169,1.2234497,15.776001,0.9998169,15.5,1H8.4994507C8.2234497,
    1.0001831,7.9998169,1.223999,8,1.5v7.0005493C8.0001831,8.7765503,8.223999,9.0001831,
    8.5,9h3v2.5H4.9993896C4.7234497,11.5001831,4.4998169,11.723999,4.5,12v3H1.4993896C1.2234497,
    15.0001831,0.9998169,15.223999,1,15.5v7.0005493C1.0001831,22.7765503,1.223999,23.0001831,
    1.5,23h7.0006104C8.7765503,22.9998169,9.0001831,22.776001,9,22.5v-7.0006104C8.9998169,
    15.2234497,8.776001,14.9998169,8.5,15h-3v-2.5h13V15h-3.0005493C15.2234497,15.0001831,
    14.9998169,15.223999,15,15.5v7.0005493C15.0001831,22.7765503,15.223999,23.0001831,15.5,
    23h7.0006104C22.7765503,22.9998169,23.0001831,22.776001,23,22.5v-7.0006104C22.9998169,
    15.2234497,22.776001,14.9998169,22.5,15z M8,16v6H2v-6H8z M9,8V2h6v6H9z M22,22h-6v-6h6V22z"
    />
  </Icon>
);
