import React, { FC, memo, useEffect, useState } from 'react';

import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  CheckboxProps,
  Flex,
} from '@chakra-ui/react';
import { customTheme } from 'theme';

interface ISwitchOnOff extends CheckboxProps {
  onChange?(value: any);

  isInvalid?: boolean;
  showNumber?: boolean;
  size?: 'xs' | 'sm';
  fontSize?: string;

  onClick?(e: any): void;
}

export const SwitchOnOff: FC<ISwitchOnOff> = memo(props => {
  const {
    onChange,
    isDisabled,
    isInvalid,
    value,
    showNumber = false,
    size = 'sm',
    fontSize = 'xs',
    onClick,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState<boolean>(!!value);

  useEffect(() => {
    setLocalValue(!!value);
  }, [value]);

  const handleClick = e => {
    if (onClick) {
      onClick(e);
    } else if (!isDisabled) {
      setLocalValue(!localValue);
      onChange?.(!localValue);
    }
  };

  const renderText = () => {
    if (showNumber) return localValue ? 1 : 0;
    else return localValue ? 'ON' : 'OFF';
  };

  const getStyles = () => {
    switch (size) {
      case 'xs':
        return { h: 5, w: 10 };
      default:
        return { h: 6, w: 16 };
    }
  };

  const commonStyles: BoxProps = {
    pos: 'relative',
    w: '50%',
    h: 'full',
    fontSize: fontSize,
    transition: '0.2s all',
    left: localValue ? '50%' : 0,
    borderRight: localValue
      ? 'none'
      : `1px solid ${customTheme.colors.gray[100]}`,
  };

  return (
    <Flex display={'flex-inline'}>
      <Box
        border="1px solid"
        borderColor={localValue ? 'primary' : 'gray.100'}
        borderRadius={4}
        overflow="hidden"
        cursor={isDisabled || isInvalid ? 'not-allowed' : 'pointer'}
        opacity={isInvalid || isDisabled ? 0.5 : 1}
        userSelect={'none'}
        {...getStyles()}
        onClick={handleClick}
      >
        {isDisabled || isInvalid ? (
          <Center
            bg={localValue ? 'primary' : customTheme.colors.gray['50']}
            color={localValue ? customTheme.colors.gray['50'] : '#555'}
            {...commonStyles}
          >
            {renderText()}
          </Center>
        ) : (
          <Center
            bg={localValue ? 'primary' : customTheme.colors.gray['50']}
            color={localValue ? customTheme.colors.gray['50'] : '#555'}
            {...commonStyles}
          >
            {renderText()}
          </Center>
        )}
      </Box>
      <Checkbox {...rest} isChecked={localValue} display="none" />
    </Flex>
  );
});
