import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CommandLineIcon = props => (
  <Icon viewBox="6 6 12 12" w="full" h="full" color="inherit" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path d="M21 22.5H3A1.5 1.5 0 0 1 1.5 21V3A1.5 1.5 0 0 1 3 1.5h18A1.5 1.5 0 0 1 22.5 3v18a1.5 1.5 0 0 1-1.5 1.5z" />
      <path d="M8.5 11a1 1 0 10-2 0v2a1 1 0 102 0M16.5 14h-1v-4h1a1 1 0 011 1v2a1 1 0 01-1 1zM14 14v-3a1 1 0 10-2 0v3-3a1 1 0 10-2 0v3" />
    </g>
  </Icon>
);
