/* --- STATE --- */

import { PolicyMetaData, RuleRead } from '@ariksa/compliance-policies/api';
import { NativeResources } from '@ariksa/inventory-core';
import {
  CloudProvider,
  RHSSuggestions,
  SearchApiSearchSuggestionsRequest,
  SearchSuggestResponse,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import { AggregatedAlertsSummaryResponse } from '@ariksa/notification/api';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';
import { Optional } from 'types/utils';

import { ApiCallStatus } from 'api/types';

export interface PolicyHubState {
  selectedPolicy: Record<string, any>;

  rules: ApiCallStatus & {
    results: Record<string, any>[];
  };
  policyAction: IQueryState<any>;

  searchQuery: AriksaSearchQuery;
  searchQuerySuggestion: IQueryState<SearchSuggestResponse>;
  suggestFor: AriksaSearchQuerySuggestFor;
  policies: IPagedQueryState<any>;
  alertSummary: IQueryState<AggregatedAlertsSummaryResponse>;
  createPolicy: IQueryState<RuleRead>;
  policyById: IQueryState<RuleRead>;
  saveQuery: IQueryState<SearchWrapper>;
  deletePolicy: IQueryState<any>;
  updatePolicy: IQueryState<any>;

  queryById: IQueryState<any>;

  ruleCategories: IQueryState<string[]>;

  activeAlertCategory: string;

  attachWorkflow: IQueryState<any>;
  searchQueryCloudProvider: Optional<CloudProvider>;

  attachedBlueprints: IQueryState<string[]>;

  alertCategories: IQueryState<string[]>;

  policyContext: IQueryState<any>;
  policyContexts: IQueryState<PolicyMetaData[]>;
}

// ---- start AriksaQuery types

export interface AriksaSearchQueryChange {
  // update using this build_id
  build_id: string;
  field_build_id?: string;

  op: 'add' | 'remove' | 'update' | 'set';
  field:
    | 'name'
    | 'alias'
    | 'sub_tags'
    | 'clauses'
    | 'attributes'
    | 'attribute'
    | 'operator'
    | 'values'
    | 'value'
    | 'resource'
    | 'that'
    | 'having'
    | 'relationship'
    | 'attribute_options'
    | 'operator_options'
    | 'value_options'
    | 'resource_options'
    | 'relationship_options'
    | 'unknown';
  value?: any;
}

// used to identify objects inside the AriksaSearchQuery
export type AriksaQueryBuildId = string;

export interface AriksaSearchQuerySuggestFor {
  // get suggestion for this object
  build_id: AriksaQueryBuildId; // Resource.build_id | Relationship.build_id
  for_field: SuggestForField;
}

export type SuggestForField =
  | 'resource_attributes'
  | 'attribute_operators'
  | 'attribute_values'
  | 'resource_relationships'
  | 'relationship_attributes'
  | 'relationship_resources';

export interface AriksaSearchQuery {
  build: {
    id: AriksaQueryBuildId;
    resource_options: AriksaQueryResourceOptions;
    resource_options_status: QueryOptionsStatus;
  };
  resource?: AriksaSearchQueryResource;
  is_complete?: boolean;
}

export type AriksaQueryResourceOptions = NativeResources[];

export interface AriksaSearchQueryResource {
  name: NativeResources;
  // when attribute will be empty array when HAVING clause is selected
  clauses?: AriksaSearchQueryAttributeList[];
  that?: AriksaSearchQueryThat[];

  // identifies object
  build: {
    id: AriksaQueryBuildId;
    attribute_options: AriksaSearchQueryAttributeOptions;
    attribute_options_status: QueryOptionsStatus;
    relationship_options: AriksaQueryResourceRelationshipOptions;
    relationship_options_status: QueryOptionsStatus;
  };
}

export type AriksaSearchQueryHavingOptions = string[];

export type AriksaSearchQueryAttributeOptions = {
  type: string;
  name: string;
  alias: string;
}[];

export interface AriksaSearchQueryAttributeList {
  build: {
    id: AriksaQueryBuildId;
  };

  attributes: AriksaSearchQueryAttribute[];
}

export interface AriksaSearchQueryAttribute {
  build: {
    id: AriksaQueryBuildId;
    value_options: RHSSuggestions;
    value_options_status: QueryOptionsStatus;
    operator_options: string[];
    operator_options_status: QueryOptionsStatus;
  };

  name?: string;
  alias?: string;
  sub_tags?: Record<string, any>[];
  operator?: string;
  values?: Array<string>;
}

export interface AriksaSearchQueryThat {
  // identifies object
  build: {
    id: AriksaQueryBuildId;
    resource_options: AriksaQueryResourceOptions;
    resource_options_status: QueryOptionsStatus;
  };
  // must exist for every THAT clause
  relationship: Optional<AriksaQueryResourceRelationship>;
  //resource related to the relationship
  resource: Optional<AriksaSearchQueryResource>;
}

export type AriksaQueryResourceRelationshipOptions = string[];

export interface AriksaQueryResourceRelationship {
  // identifies object
  build: {
    id: AriksaQueryBuildId;
    attribute_options: AriksaSearchQueryAttributeOptions;
    attribute_options_status: QueryOptionsStatus;
  };

  name: string;
  // NOTE: relationship attributes are not supported yet
  // attributes?: AriksaSearchQueryAttribute[];
}

export interface SearchApiSearchSuggestionsRequestParams {
  req: SearchApiSearchSuggestionsRequest;
  suggestFor: AriksaSearchQuerySuggestFor;
}

// ---- end AriksaQuery types

export interface PolicyRule {
  config_id: string;
  alert_category: string;
  severity: string;
  alert_description: string;
  compliance_description: string;
  resource_type: string;
  id: string;
}

export type ContainerState = PolicyHubState;

// need string fields for chakra radio to work properly
export enum CreateFormType {
  FromExisting = '0',
  BuildNew = '1',
}

export enum QueryOptionsStatus {
  unknown,
  loading,
  loaded,
}
