import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DistinguishedName,
  ExternalID,
  GroupMembers,
  LastMemberUpdated,
  LastUpdatedAt,
  Name,
  ObjectClass,
  ObjectSid,
  CreatedAt,
  DescriptionWith1ColSpan,
  ID,
  OktaGroupType,
  SAMAccountName,
  WindowsDomainQualifiedName,
} from '../../../Components/MetadataField/MetaGridFields';

export const OktaGroupMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {DescriptionWith1ColSpan(resource)}
      {ID(insight)}
      {OktaGroupType(resource)}
      {DistinguishedName(resource)}
      {WindowsDomainQualifiedName(resource)}
      {ExternalID(resource)}
      {SAMAccountName(resource)}
      {ObjectSid(resource)}
      {ObjectClass(resource)}
      {GroupMembers(resource)}
      {CreatedAt(resource, 'utc')}
      {LastMemberUpdated(resource)}
      {LastUpdatedAt(resource)}
    </Grid>
  );
};
