import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountLocator,
  SnowflakeID,
  Comment,
  Owner,
  Name,
  CreatedAt,
  LastModified,
  Schema,
  SnowflakeDatabase,
  Type,
  ClusteringKey,
  RowCount,
  Bytes,
  RetentionTime,
  AutoClusteringOn,
} from '../../../Components/MetadataField/MetaGridFields';

export const SnowflakeTableMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountLocator(insight)}
      {SnowflakeID(insight)}
      {Comment(resource)}
      {Owner(resource)}
      {SnowflakeDatabase(resource)}
      {Schema(resource)}
      {Type(resource)}
      {ClusteringKey(resource)}
      {RowCount(resource)}
      {Bytes(resource)}
      {RetentionTime(resource)}
      {AutoClusteringOn(resource)}
      {CreatedAt(resource, 'dayjs')}
      {LastModified(resource)}
    </Grid>
  );
};
