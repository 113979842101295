import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccountLocator,
  SnowflakeID,
  Comment,
  Owner,
  Name,
  CreatedAt,
  LastModified,
  Schemas,
  Tables,
} from '../../../Components/MetadataField/MetaGridFields';

export const SnowflakeDatabaseMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountLocator(insight)}
      {SnowflakeID(insight)}
      {Comment(resource)}
      {Owner(resource)}
      {Schemas(resource)}
      {Tables(insight)}
      {CreatedAt(resource, 'dayjs')}
      {LastModified(resource)}
    </Grid>
  );
};
