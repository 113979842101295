import React, { FC } from 'react';

import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import { getVulnerability } from './utils';

export const VulnerabilityTab: FC = () => {
  const { resourceType } = useActiveResourceContext();

  return <>{getVulnerability(resourceType)}</>;
};
