import React, { FC, useEffect, useState } from 'react';

import { LabelType } from '@ariksa/inventory-core/api';
import { Box, Input, Stack } from '@chakra-ui/react';
import { find, includes, map } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getResourceTypeOptions,
  PageHeaderWithIcon,
  Tag,
} from 'components/DataDisplay';
import { commonFieldStyles, Form, FormSchema } from 'components/DataEntry/Form';
import { TagIcon } from 'components/Icons';
import { selectApp } from 'containers/App/selectors';
import { tagContainerStyles } from 'containers/Inventory/InventoryContext/Components/ContextWizard/styles';
import { contextTypeOptions } from 'containers/Inventory/InventoryContext/Components/ContextWizard/utils';
import { selectInventoryContext } from 'containers/Inventory/InventoryContext/selector';
import { actions } from 'containers/Inventory/InventoryContext/slice';
import { s3BucketTagStyles } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardAws/styles';

interface Props {
  actionType?: 'Create' | 'Update';
  onCancel: () => void;
}

export const ContextForm: FC<Props> = props => {
  const { onCancel, actionType } = props;
  const { environments, resourceTypes } = useSelector(selectApp);
  const { context } = useSelector(selectInventoryContext);
  const formData = context.data;
  const [environmentOptions, setEnvironmentOptions] = useState<
    Record<string, any>[]
  >([]);
  const [selectedScope, setSelectedScope] = useState<Record<string, any>[]>([]);
  const [contextType, setContextType] = useState<Record<string, any>>({});
  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);
  const [resourceType, setResourceType] = useState<Record<string, any>>({});
  const [keyValue, setKeyValue] = useState('');
  const [isBusinessCritical, setIsBusinessCritical] = useState(true);
  const [keyValues, setKeyValues] = useState<string[]>([]);
  const [name, setName] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //set resource type options
  useEffect(() => {
    setResourceTypeOptions([
      { label: 'All Resource Types', value: null },
      ...getResourceTypeOptions(resourceTypes.data),
    ]);
  }, [resourceTypes.data]);

  //set scope options
  useEffect(() => {
    setEnvironmentOptions(
      map(environments.data, o => ({
        label: o?.name,
        value: o?.id,
        data: o,
      })),
    );
  }, [environments.data]);

  //set default value for scope
  useEffect(() => {
    const envs = filter(environmentOptions, a =>
      includes(formData?.scope, a?.value),
    );
    setSelectedScope(envs || [environmentOptions[0]]);
  }, [environmentOptions, formData]);

  //set default value for context type
  useEffect(() => {
    setContextType(
      formData?.type === LabelType.KeyValue
        ? contextTypeOptions[1]
        : contextTypeOptions[0],
    );
    setKeyValues(formData?.value ?? []);
    setIsBusinessCritical(formData?.business_critical ?? true);
    setName(formData?.key ?? '');
    setNotes(formData?.notes ?? '');
  }, [formData]);

  useEffect(() => {
    setResourceType(
      find(resourceTypeOptions, o =>
        includes(formData.native_resources, o?.value),
      ) ?? resourceTypeOptions[0],
    );
  }, [formData, resourceTypeOptions]);

  const onRemoveKeyValue = value => {
    setKeyValues(keyValues.filter(o => o !== value));
  };

  const handleOnChange = e => {
    setKeyValue(e.target.value.trim());
  };

  const handleKeyDown = e => {
    if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
      e.preventDefault();
      const value = keyValue.trim();
      if (value) {
        setKeyValue('');
        setKeyValues([...keyValues, value]);
      }
    }
  };
  const handlePaste = e => {
    const value = e.target.value.trim();
    if (value) {
      setKeyValue('');
      setKeyValues([...keyValues, value]);
    }
  };

  const onSuccess = () => {
    navigate('/inventory/context');
    dispatch(actions.resetFormData());
  };

  const handleSubmit = data => {
    const { key, native_resource, notes } = data;
    if (actionType === 'Create') {
      const nativeResource = !!native_resource?.value
        ? { native_resources: [native_resource?.value] }
        : {};
      const values =
        contextType?.value === LabelType.Label ? {} : { value: keyValues };
      dispatch(
        actions.createContext({
          q: {
            userLabelCreate: {
              key,
              ...values,
              notes,
              ...nativeResource,
              scope: map(selectedScope, o => o?.value),
              is_multi: false,
              business_critical: isBusinessCritical,
            },
          },
          onSuccess: onSuccess,
        }),
      );
    } else {
      dispatch(
        actions.updateContext({
          q: { labelId: context.data?.id, userLabelUpdate: { key, notes } },
          onSuccess: onSuccess,
        }),
      );
    }
  };

  const values: FormSchema =
    contextType?.value === 'keyValues'
      ? {
          values: {
            type: 'custom-with-form-control',
            label: 'Indicate Values',
            isRequired: true,
            isDisabled: actionType === 'Update',
            component: (
              <Stack spacing={4}>
                {actionType === 'Create' && (
                  <Box>
                    <Input
                      {...commonFieldStyles}
                      value={keyValue}
                      onChange={handleOnChange}
                      onKeyDown={handleKeyDown}
                      onPaste={handlePaste}
                      placeholder="Enter values"
                    />
                  </Box>
                )}
                <Box {...(actionType === 'Update' ? tagContainerStyles : {})}>
                  {keyValues.length > 0 && (
                    <Box>
                      {map(keyValues, each => (
                        <Tag
                          label={each}
                          closeButton={actionType === 'Create'}
                          onClose={() => onRemoveKeyValue(each)}
                          styles={s3BucketTagStyles}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              </Stack>
            ),
          },
        }
      : {};

  return (
    <Form
      title={
        <PageHeaderWithIcon
          label={actionType + ' Context'}
          icon={<TagIcon />}
          reversed
        />
      }
      schema={{
        key: {
          type: 'text',
          label: 'Name',
          placeholder: 'Name',
          isRequired: true,
          //isDisabled: isEdit,
          value: formData.key,
          onChange: value => setName(value),
        },
        notes: {
          type: 'textArea',
          label: 'Description',
          placeholder:
            'This is a description of the reason for the label and future reference',
          isRequired: true,
          value: formData?.notes,
          onChange: value => setNotes(value),
        },
        native_resource: {
          type: 'react-select',
          label: 'Applies to',
          isRequired: true,
          isDisabled: actionType === 'Update',
          options: resourceTypeOptions,
          value: resourceType,
          onChange: setResourceType,
        },
        scope: {
          type: 'react-select',
          label: 'Scope',
          isRequired: true,
          isDisabled: actionType === 'Update',
          placeholder: 'Select environment...',
          options: environmentOptions,
          value: selectedScope,
          onChange: setSelectedScope,
          isMulti: true,
        },
        type: {
          type: 'react-select',
          label: 'Context type',
          isRequired: true,
          isDisabled: actionType === 'Update',
          options: contextTypeOptions,
          value: contextType,
          onChange: setContextType,
        },
        ...values,
        is_business_critical: {
          type: 'checkbox',
          checkboxes: [
            {
              name: '',
              label:
                'This context indicates that the resource is business-critical or contains sensitive information',
              isChecked: isBusinessCritical,
              onChange: e => setIsBusinessCritical(e.target.checked),
              isDisabled: actionType === 'Update',
            },
          ],
        },
      }}
      styles={{ form: { formWidth: '700px' } }}
      buttonOptions={{
        submit: {
          name: actionType,
          isDisabled:
            !selectedScope?.length ||
            (contextType?.value === 'keyValues' && !keyValues.length) ||
            !name ||
            !notes,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          onClick: onCancel,
        },
      }}
      isLoading={context.isLoading}
      handleSubmit={handleSubmit}
    />
  );
};
