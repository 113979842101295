import React, { FC, useState } from 'react';

import { DataClassifiers } from '@ariksa/data-scanning';
import { DataClassifierType } from '@ariksa/data-scanning/api';
import { Box, Center, HStack, Stack, Input } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Tag, WithResourceIcon } from 'components/DataDisplay';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import {
  CancelButton,
  CustomButton,
  textFieldStyles,
} from 'components/DataEntry';
import {
  CheckmarkCircleOutlineIcon,
  DisableIcon,
  IconTypes,
} from 'components/Icons';
import { Modal } from 'components/Overlay';
import {
  getDataClassifierText,
  getDataClassifierIconColor,
} from 'containers/Setup/DataConfiguration/Components/DataClassifier/utils';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectDataClassification } from 'containers/Setup/DataConfiguration/selector';
import { actions } from 'containers/Setup/DataConfiguration/slice';
import { DataClassifierAction } from 'containers/Setup/DataConfiguration/types';
import { toTitleCase } from 'utils/string';

interface Props {
  currentRecord: DataClassifiers;
  isOpen: boolean;
  onClose: () => void;
  actionType: DataClassifierAction;
}

export const DataClassifierModal: FC<Props> = props => {
  const { currentRecord, isOpen, onClose, actionType } = props;
  const dispatch = useDispatch();
  const { dataConfigurationAction } = useSelector(selectDataClassification);
  const [confirm, setConfirm] = useState(false);
  const { selectedTab } = useDataConfigurationContext();

  const onSuccess = () => {
    selectedTab === 'types'
      ? dispatch(actions.getDataTypes({ q: {} }))
      : dispatch(actions.getDataExclusions({ q: {} }));
    onClose();
  };

  const handleAction = () => {
    if (actionType === 'Enable' || actionType === 'Disable') {
      dispatch(
        actions.updateDataExclusion({
          q: {
            exclusionName: currentRecord.name!,
            dataExclusionsUpdate: { is_enabled: actionType === 'Enable' },
          },
          onSuccess,
        }),
      );
    } else {
      selectedTab === 'types'
        ? dispatch(
            actions.deleteDataType({
              q: { typeName: currentRecord.name! },
              onSuccess,
            }),
          )
        : dispatch(
            actions.deleteDataExclusion({
              q: { exclusionName: currentRecord.name! },
              onSuccess,
            }),
          );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <WithResourceIcon
          resourceType={
            selectedTab === 'types'
              ? IconTypes.DataClassification
              : IconTypes.Disable
          }
          bgColor={getDataClassifierIconColor(actionType)}
          iconSize="medium"
        >
          <Box>
            {actionType} Data {selectedTab === 'types' ? 'Type' : 'Exclusion'}
          </Box>
        </WithResourceIcon>
      }
      size="xl"
      body={
        <Stack w="full" spacing={16}>
          <Stack spacing={4}>
            <DetailsField label="Name" value={currentRecord?.name} />
            <HStack w="full">
              <Box w="full">
                <DetailsField
                  label="Status"
                  value={
                    <HStack spacing={2}>
                      <Box>
                        {currentRecord?.is_enabled ? 'Enabled' : 'Disabled'}
                      </Box>
                      <Center
                        w={4}
                        h={4}
                        color={currentRecord?.is_enabled ? 'green' : 'red'}
                      >
                        {currentRecord?.is_enabled ? (
                          <CheckmarkCircleOutlineIcon />
                        ) : (
                          <DisableIcon />
                        )}
                      </Center>
                    </HStack>
                  }
                />
              </Box>
              <Box w="full">
                <DetailsField
                  label="Criteria"
                  value={
                    currentRecord.category +
                    ' / ' +
                    toTitleCase(currentRecord.type)
                  }
                />
              </Box>
            </HStack>
            <DetailsField label="Pattern" value={currentRecord.patterns} />
            <DetailsField
              label="Labels"
              value={
                currentRecord.type === DataClassifierType.Filename ? (
                  'Not Applicable'
                ) : (
                  <HStack>
                    {currentRecord?.labels?.map(o => (
                      <Tag
                        label={o}
                        styles={{
                          tag: { bg: 'primary' },
                          label: { color: 'white' },
                        }}
                      />
                    ))}
                  </HStack>
                )
              }
            />
            <Box pt={4} fontWeight={600}>
              {getDataClassifierText(
                actionType,
                selectedTab === 'types' ? 'type' : 'exclusion',
              )}
            </Box>
            {actionType === 'Delete' && (
              <HStack pt={4}>
                <Box>Type ‘CONFIRM’ to proceed:</Box>
                <Input
                  {...textFieldStyles.input}
                  w={48}
                  color={confirm ? 'green' : 'red'}
                  onChange={e =>
                    e.target.value === 'CONFIRM'
                      ? setConfirm(true)
                      : setConfirm(false)
                  }
                />
              </HStack>
            )}
          </Stack>
          <HStack justify="space-between" w="full">
            <CancelButton onClick={() => onClose()}></CancelButton>
            <CustomButton
              buttonType={
                actionType === 'Delete'
                  ? 'delete'
                  : actionType === 'Disable'
                  ? 'otherColor'
                  : 'edit'
              }
              showIcon={false}
              label={
                actionType === 'Delete'
                  ? 'Delete'
                  : actionType === 'Disable'
                  ? 'Disable'
                  : 'Enable'
              }
              customColor="orange"
              isLoading={dataConfigurationAction.isLoading}
              onClick={() => handleAction()}
              isDisabled={actionType === 'Delete' && !confirm}
            />
          </HStack>
        </Stack>
      }
    />
  );
};
