import React from 'react';

import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import toLower from 'lodash/toLower';

import { formatNumberWithComma } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { toPascalCase, toTitleCase } from 'utils/string';

import { Tag } from '../Tag';

export const renderSeverity = ({ value }) => {
  return (
    <Tag
      label={toPascalCase(value)}
      styles={{ tag: { bg: toLower(value), color: 'white' } }}
    />
  );
};

//value: critical
export const renderSeverityBubble = ({ value }) => {
  return (
    <HStack spacing={2} w="full">
      <Box boxSize={2.5} bg={toLower(value)} borderRadius={2} />
      <Box>{toPascalCase(value)}</Box>
    </HStack>
  );
};

export const renderSeverityBar = ({
  value,
  isInline = true,
  reversed = false,
  showLabel = true,
  spacing = isInline ? 2 : 1,
  equalWidth = false,
  labelStyles = {},
}) => {
  const renderBox = index => {
    const severity = toLower(value);
    let bg = severity;
    if (
      (severity === 'high' && index > 3) ||
      (severity === 'medium' && index > 2) ||
      (severity === 'low' && index > 1) ||
      (severity === 'unknown' && index > 0)
    )
      bg = 'gray.200';
    return <Box boxSize={2.5} bg={bg} borderRadius={2} />;
  };

  return (
    <Stack spacing={spacing} w="full" isInline={isInline}>
      {(!isInline || reversed) && showLabel && (
        <Box w={equalWidth ? 'full' : 'default'} {...labelStyles}>
          {toPascalCase(value)}
        </Box>
      )}
      <HStack spacing={1} w={equalWidth ? 'full' : 'default'}>
        {renderBox(1)}
        {renderBox(2)}
        {renderBox(3)}
        {renderBox(4)}
      </HStack>
      {isInline && !reversed && showLabel && (
        <Box w={equalWidth ? 'full' : 'default'} {...labelStyles}>
          {toPascalCase(value)}
        </Box>
      )}
    </Stack>
  );
};

export const renderSeverityCountBubble = (severity, value) => {
  const color =
    toLower(severity) === 'unknown' ? 'gray.200' : toLower(severity);
  return (
    <CustomTooltip label={toTitleCase(severity)}>
      <HStack spacing={1} align="center" w="full">
        <Box boxSize={2.5} bg={color} borderRadius={2} />
        <Box>{formatNumber(value ?? 0)}</Box>
      </HStack>
    </CustomTooltip>
  );
};

// value => {CRITICAL: 1, MEDIUM: 2}
export const renderSeverityCountTags = ({ value }) => {
  const renderTag = (severity, value) => (
    <CustomTooltip label={toTitleCase(severity)}>
      <Tag
        label={value ? formatNumberWithComma(value) : 0}
        styles={{
          tag: {
            bg: severity?.toLowerCase(),
            color: 'white',
            mr: 0,
            py: 0,
            borderRadius: 6,
            px: 1,
          },
          label: { color: 'white' },
        }}
      />
    </CustomTooltip>
  );

  const critical = value?.CRITICAL || value?.critical || value?.Critical;
  const high = value?.HIGH || value?.high || value?.High;
  const medium = value?.MEDIUM || value?.medium || value?.Medium;
  const low = value?.LOW || value?.low || value?.Low;

  return (
    <HStack spacing={1} align={'center'}>
      {renderTag('critical', critical)}
      {renderTag('high', high)}
      {renderTag('medium', medium)}
      {renderTag('low', low)}
    </HStack>
  );
};

export const renderSeverityBarWithCount = ({ value, count = 0 }) => {
  return (
    <HStack spacing={1} w="full">
      <Box>{renderSeverityBar({ value })}</Box>
      <Box>({count})</Box>
    </HStack>
  );
};
