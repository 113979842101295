import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DetachIcon = props => (
  <Icon viewBox="0 0 20 20" color="inherit" h="full" w="full" {...props}>
    <g transform="translate(-688 -114)">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M10.5681 15.1586 10.5277 15.1586C9.53724 15.15 8.59002 14.7514 7.89137 14.0492L1.56457 7.72149C0.541398 6.69605 0.541398 5.03588 1.56457 4.01044L1.62558 3.94943C2.65101 2.92626 4.31118 2.92626 5.33662 3.94943L11.7704 10.3842C12.4126 11.0264 12.4126 12.0676 11.7704 12.7099 11.1282 13.3521 10.0869 13.3521 9.44464 12.7099L5.09072 8.35595C4.88753 8.15275 4.88753 7.82332 5.09072 7.62013 5.29392 7.41693 5.62335 7.41693 5.82655 7.62013L10.1805 11.975C10.4168 12.2109 10.7997 12.2104 11.0355 11.9741 11.2713 11.7378 11.2709 11.3549 11.0346 11.1191L4.60173 4.68433C3.98188 4.06946 2.98219 4.06946 2.36234 4.68433L2.3004 4.74627C1.68553 5.36612 1.68553 6.36581 2.3004 6.98567L8.62811 13.3134C9.13454 13.823 9.82147 14.1124 10.54 14.1187L10.5691 14.1187C11.9997 14.1303 13.1689 12.9801 13.1805 11.5495 13.1807 11.5255 13.1806 11.5015 13.1801 11.4776 13.1736 10.7592 12.8843 10.0723 12.3749 9.56579L5.7768 2.96771C5.57387 2.76452 5.57407 2.43528 5.77727 2.23235 5.98047 2.02942 6.30969 2.02962 6.51263 2.23282L13.1107 8.83088C13.8134 9.5292 14.2124 10.4766 14.221 11.4673 14.2547 13.472 12.6568 15.1244 10.6522 15.1581 10.6249 15.1586 10.5974 15.1588 10.57 15.1586Z"
                stroke="currentColor"
                strokeWidth="0.0837209"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="nonzero"
                fillOpacity="1"
                transform="matrix(1 0 0 1.03157 690.576 115.345)"
              />
              <path
                d="M0 0 13.3894 14.2247"
                stroke="currentColor"
                strokeWidth="1.15116"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="8"
                strokeOpacity="1"
                fill="currentColor"
                fillRule="evenodd"
                fillOpacity="1"
                transform="matrix(1 0 0 -1.03157 691.93 132.006)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
