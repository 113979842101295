import React from 'react';

import { Icon } from '@chakra-ui/react';

export const EcsTaskIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M255.3,260.9l-41.8-41.8c-4.3-4.7-11.7-5-16.4-0.7c-0.2,0.2-0.5,0.4-0.7,0.7l-16.5,17
		c-4.6,4.3-4.8,11.4-0.5,16c0.2,0.2,0.3,0.3,0.5,0.5l59.1,58c4.3,4.5,10.3,7.1,16.6,7.1c6.2,0,12.2-2.5,16.5-7.1l120.1-120.6
		c4.7-4.2,5.1-11.4,0.9-16.1c-0.3-0.3-0.6-0.6-0.9-0.9l-16.5-16.5c-4.3-4.6-11.5-4.8-16.1-0.5c-0.2,0.2-0.3,0.3-0.5,0.5L255.3,260.9
		z"
      />
      <path
        d="M441.7,291.2c4.5-24.2,4.5-49,0-73.2l39.1-33.2c13.1-11.1,16.7-29.9,8.5-45.1l-17-29.8
		c-6.4-10.7-18.1-17.2-30.6-17c-4.1-0.1-8.2,0.8-11.9,2.6l-49.4,17.9c-18.4-16.1-39.8-28.6-63-36.6l-8.5-50.2
		C305.5,10.2,290.6-1,274,0.1h-34c-16.7-1.4-31.7,10-34.9,26.4l-8.5,49.4c-23.5,7.8-45.2,20.2-63.8,36.6L82.5,94.6
		c-3.9-1.3-7.9-2.1-11.9-2.6C58.1,92,46.5,98.4,40,109l-17,29.8c-8.6,15.1-5,34.2,8.5,45.1l39.1,33.2c-2.4,12-3.5,24.3-3.4,36.6
		c-0.2,12.3,1,24.6,3.4,36.6l-39.1,33.6C18.3,335.1,14.8,353.9,23,369l17,29.8c6.4,10.7,18.1,17.2,30.6,17c4.1,0.1,8.2-0.8,11.9-2.6
		l49.4-17.9c18.4,16.1,39.8,28.6,63,36.6l8.5,51.1c2.9,16.9,17.7,29.2,34.9,28.9h34c17.3-0.1,32.1-12.7,34.9-29.8l8.5-51.1
		c24.6-8.3,47.2-21.6,66.4-39.1l46,17.9c4.2,1.3,8.4,2.1,12.8,2.6c12.5,0.1,24.1-6.4,30.6-17l16.2-28.1c9.4-13.7,6.4-32.5-6.8-42.6
		L441.7,291.2z M412.8,211.7l-38.7,38.8v6.6c0.2,65.1-52.3,118.1-117.4,118.3c-65.1,0.2-118.1-52.3-118.3-117.4
		c-0.2-65.1,52.3-118.1,117.4-118.3c21.6-0.1,42.8,5.8,61.3,16.9l20.8-20.8c16-16.2,42.1-16.4,58.2-0.4c0.1,0.1,0.3,0.3,0.4,0.4
		l17,17c8.3,8.3,12.7,19.7,12.2,31.5C425.2,194.8,420.6,204.6,412.8,211.7z"
      />
    </g>
  </Icon>
);
