import React, { useCallback } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { ChartType } from '@ariksa/reporting';
import { forEach, isEmpty } from 'lodash';
import { customTheme } from 'theme';

import { colorMapForCharts } from 'components/DataDisplay';
import { getIconColor } from 'components/Icons/Components/getIconColor';

export const useReportsDashboardWidgetConfigs = () => {
  const getWidgetConfig = useCallback((chart: Record<string, any>) => {
    const resourceTypeColors: Record<string, any> = {};
    forEach(NativeResources, o => (resourceTypeColors[o] = getIconColor(o)));
    const commonProps = {
      data: chart?.chart_details,
      xField: 'Date',
      colorMap: {
        Critical: customTheme.colors.critical,
        High: customTheme.colors.high,
        Medium: customTheme.colors.medium,
        Low: customTheme.colors.low,
        Identities: customTheme.colors.primary,
        Score: customTheme.colors.primary,
        Roles: customTheme.colors.primary,
        Value: customTheme.colors.primary,
        Inventory: customTheme.colors.primary,
        Machines: customTheme.colors.primary,
        'Data Sources': customTheme.colors.primary,
        Containers: customTheme.colors.primary,
        Resources: customTheme.colors.primary,
        Instances: customTheme.colors.primary,
        Open: customTheme.colors.primary,
        Resolved: customTheme.colors.green['300'],
        'Containers with High Vulnerabilities': customTheme.colors.high,
        'Machines with High Vulnerabilities': customTheme.colors.high,
        'Containers with Critical Vulnerabilities': customTheme.colors.critical,
        'Machines with Critical Vulnerabilities': customTheme.colors.critical,
        'Unpatched OS': customTheme.colors.medium,
        'Unpatched Technology': customTheme.colors.low,
        NA: customTheme.colors.primary,
        ...colorMapForCharts,
        ...resourceTypeColors,
      },
      styles: {
        legends: { pr: 4 },
        barChart: {
          margin: {
            top: 0,
            right: 20,
            bottom: 20,
          },
        },
        legend: {
          formatter: value => {
            return (
              <span style={{ fontSize: '12px', color: '#666' }}>{value}</span>
            );
          },
        },
      },
      legend: true,
    };
    let specificProps: Record<string, any> = { yField: ['Value'] };
    switch (chart?.chart_type) {
      case ChartType.CriticalAlerts:
      case ChartType.CriticalFindings:
        specificProps = { yField: ['Critical'] };
        break;
      case ChartType.OpenAlerts:
      case ChartType.AlertResolutions:
      case ChartType.OpenFindings:
      case ChartType.VulnerabilityOccurrences:
        specificProps = { yField: ['Critical', 'High', 'Medium', 'Low'] };
        break;
      case ChartType.IdentitiesWithExcessPermissions:
        specificProps = { yField: ['Identities'] };
        break;
      case ChartType.HostsWithUnpatchedOsAndTechnology:
        specificProps = { yField: ['Unpatched OS', 'Unpatched Technology'] };
        break;
      case ChartType.RolesWithExcessPermissions:
        specificProps = { yField: ['Roles'] };
        break;
      case ChartType.GovernanceScore:
      case ChartType.TotalAlerts:
      case ChartType.TotalFindings:
        specificProps = {
          yField: chart?.fields,
          ...(isEmpty(chart?.fields)
            ? {
                styles: {
                  lineChart: {
                    margin: {
                      top: 30,
                      right: 30,
                      bottom: 20,
                    },
                  },
                },
              }
            : {}),
        };
        break;
      case ChartType.InstancesByRegion:
      case ChartType.InstancesByResourceType:
        specificProps = {
          yField: chart?.fields,
          stacked: true,
          legend: false,
          styles: {
            barChart: {
              margin: {
                top: 30,
                right: 30,
                bottom: 20,
              },
            },
          },
        };
        break;
      case ChartType.DataSourcesWithSensitiveData:
        specificProps = { yField: ['Data Sources'] };
        break;
      case ChartType.ContainersWithCriticalVulnerabilities:
        specificProps = {
          yField: ['Containers with Critical Vulnerabilities'],
        };
        break;
      case ChartType.ContainersWithHighVulnerabilities:
        specificProps = { yField: ['Containers with High Vulnerabilities'] };
        break;
      case ChartType.ResourcesWithNoTags:
      case ChartType.InternetExposedResources:
      case ChartType.CloudInventory:
        specificProps = { yField: ['Resources'] };
        break;
      case ChartType.MachinesWithCriticalVulnerabilities:
        specificProps = { yField: ['Machines with Critical Vulnerabilities'] };
        break;
      case ChartType.MachinesWithHighVulnerabilities:
        specificProps = { yField: ['Machines with High Vulnerabilities'] };
        break;
    }
    return { ...commonProps, ...specificProps };
  }, []);

  return { getWidgetConfig };
};
