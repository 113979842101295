import React, { FC, useEffect, useReducer } from 'react';

import { Clients } from '@ariksa/notification';
import { DataTypeForES, Status } from '@ariksa/reporting';
import { filter, find, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  AnalyticsIcon,
  Form,
  Modal,
  PageHeaderWithIcon,
} from 'components/index';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { formStyles } from 'containers/SharedState/Components/ExportAlerts/styles';
import {
  exportAlertsTargetOptions,
  exportAlertsTypeOptions,
} from 'containers/SharedState/Components/ExportAlerts/utils';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions } from 'containers/SharedState/slice';

interface Props {
  type: DataTypeForES;
  alertStatus?: Status;
  isOpen: boolean;
  onClose();
}

export const ExportAlertsModal: FC<Props> = props => {
  const { type, alertStatus, isOpen, onClose } = props;
  const dispatch = useDispatch();
  const { clients, exportAlerts } = useSelector(selectSharedState);
  const { cloudAccounts } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { getEnvironmentOptions } = useEnvironmentOptions();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      environmentOptions: [],
      elasticSearchOptions: [],
      accountOptions: [],
      selectedEnv: {},
      selectedAccounts: [],
      selectedElasticSearch: {},
    },
  );

  //get elastic search clients
  useEffect(() => {
    isOpen &&
      dispatch(actions.getClients({ clientName: Clients.Elasticsearch }));
  }, [dispatch, isOpen]);

  //set elastic search options
  useEffect(() => {
    const options = map(clients.elasticsearch, o => ({
      label: o?.name,
      value: o?.uuid,
    }));
    updateState({
      elasticSearchOptions: options,
      selectedElasticSearch: options?.[0],
    });
  }, [clients.elasticsearch]);

  //set environment options
  useEffect(() => {
    updateState({ environmentOptions: getEnvironmentOptions() });
  }, [getEnvironmentOptions]);

  //set selected environment
  useEffect(() => {
    updateState({
      selectedEnv: find(
        state.environmentOptions,
        o => o?.value === environmentId,
      ),
    });
  }, [state.environmentOptions, environmentId, isOpen]);

  //set account options
  useEffect(() => {
    const options = map(
      filter(cloudAccounts.data, o =>
        includes(state.selectedEnv?.data?.account_ids, o?.uuid),
      ),
      o => ({ label: o?.name, value: o?.uuid }),
    );
    const optionsWithAll = [{ label: 'All', value: 'all' }, ...options];
    const account = find(options, o => o?.value === accountId);
    updateState({
      accountOptions: optionsWithAll,
      selectedAccounts: !!account ? [account] : options,
    });
  }, [state.selectedEnv, cloudAccounts, accountId, isOpen]);

  const handleSubmit = data => {
    dispatch(
      actions.exportAlertsToElasticSearch({
        q: {
          eSLogRequest: {
            client_id: data.recipients?.value,
            environment_id: state?.selectedEnv?.value,
            account_ids: state?.selectedAccounts?.map(o => o?.value),
            data_type: type,
            alert_status: alertStatus,
          },
        },
        onSuccess: () => onClose(),
      }),
    );
  };

  const renderHeader = () => {
    switch (type) {
      case DataTypeForES.Resources:
        return 'Inventory';
      case DataTypeForES.Vulnerabilities:
        return 'Vulnerabilities';
      case DataTypeForES.Alerts:
        return 'Open Alerts';
      default:
        return '';
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      styles={{ content: { zIndex: 10000 } }}
      header={
        <PageHeaderWithIcon
          label={'Export Data - ' + renderHeader()}
          icon={<AnalyticsIcon />}
          reversed
        />
      }
      body={
        <Form
          schema={{
            type: {
              type: 'react-select',
              label: 'Report type',
              options: exportAlertsTypeOptions,
              isDisabled: true,
              defaultValue: find(
                exportAlertsTypeOptions,
                o => o.value === type,
              ),
            },
            filters: {
              type: 'object',
              label: 'SPECIFY FILTERS',
              properties: {
                environment: {
                  type: 'react-select',
                  label: 'By environment',
                  options: state.environmentOptions,
                  value: state.selectedEnv,
                  onChange: selected => updateState({ selectedEnv: selected }),
                  isRequired: true,
                },
                account: {
                  type: 'react-select',
                  label: 'By accounts',
                  options: state.accountOptions,
                  value: state.selectedAccounts,
                  onChange: selected =>
                    updateState({ selectedAccounts: selected }),
                  isMulti: true,
                  onClickGroupHeading: options =>
                    updateState({
                      selectedAccounts: options,
                    }),
                  showTotalSelected: true,
                },
              },
            },
            target: {
              type: 'react-select',
              label: 'Target',
              options: exportAlertsTargetOptions,
              value: exportAlertsTargetOptions[0],
              isRequired: true,
              isDisabled: true,
            },
            recipients: {
              type: 'react-select',
              label: 'Recipient',
              options: state.elasticSearchOptions,
              value: state.selectedElasticSearch,
              onChange: selected =>
                updateState({ selectedElasticSearch: selected }),
              //isMulti: true,
              //showTotalSelected: true,
              isRequired: true,
            },
          }}
          buttonOptions={{
            submit: {
              name: 'OK',
              isLoading: exportAlerts.isLoading,
              isDisabled:
                exportAlerts.isLoading || !state.selectedElasticSearch?.value,
            },
            reset: {
              name: 'Cancel',
              isVisible: true,
              onClick: () => onClose(),
            },
          }}
          handleSubmit={handleSubmit}
          styles={formStyles}
        />
      }
    />
  );
};
