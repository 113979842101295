/* --- STATE --- */
import { RemediationCheck } from '@ariksa/compliance-policies/api';
import { RiskContextResponse } from '@ariksa/inventory-core';
import { Severity } from '@ariksa/notification';
import {
  AggregatedAlertDetails,
  AggregatedAlertsSummary,
  AlertHistoryResponse,
  AlertResponse,
  EntitySeverityListResponse,
  TopIssuesResponse,
} from '@ariksa/notification/api';
import { DashboardCount } from '@ariksa/reporting';
import { IPagedQueryState } from 'services/utils/PagedQueryState';
import { IQueryState } from 'services/utils/QueryState';
import { Dict } from 'types/utils';

import { SortOrder } from 'components/DataDisplay/NewTable/types';
import { SideNavKey } from 'components/Navigation';

export interface FindingsOverviewState {
  resourceCount: IQueryState<DashboardCount>;

  alertHistoryByTrend: IQueryState<AlertHistoryResponse>;
  alertHistoryByStatus: IQueryState<AlertHistoryResponse>;

  topIssuesResolved: IQueryState<TopIssuesResponse>;

  alertByRuleIdRemediationAvailability: IQueryState<RemediationCheck>;
  aggregatedAlertRemediationAvailability: IQueryState<RemediationCheck>;

  topOpenAlerts: Record<string, IQueryState<AlertHistoryResponse>>;
  topAggregatedAlerts: Record<string, IQueryState<AggregatedAlertDetails[]>>;

  alertCategories: IQueryState<Dict<Dict<AggregatedAlertsSummary>>>;
  currentCategory: SideNavKey;

  alertFilters: {
    resourceType?: string;
    resourceTypes?: string[];
    alertCategory?: string;
    alertCategories?: string[];
    severity?: Severity;
    severities?: Severity[];
    new_alerts?: boolean;
  };
  aggregatedAlerts: IPagedQueryState<AggregatedAlertDetails[]>;
  resourceFailedChecks: IPagedQueryState<Array<AlertResponse>>;
  alertDetailsSeverities: IQueryState<EntitySeverityListResponse>;

  alertsSummaryByCategory: IQueryState<any>;
  remediationCommands: IQueryState<any>;
  alertCountSummary: IQueryState<any>;
  alertsByRuleId: IPagedQueryState<AlertResponse[]>;
  remediationByAlertRuleId: IQueryState<any>;
  prepareRemediation: any;

  findingDescription: IQueryState<any>;
  resourceVPC: IPagedQueryState<any[]>;
  executeRemediation: IQueryState<any>;
  alertWorkflowStatus: IQueryState<Record<string, any>>;
  getAlertLogs: IQueryState<Record<string, any>>;
  connectedEntities: IPagedQueryState<any[]>;
  riskContext: IQueryState<Dict<RiskContextResponse[]>>;
  compliance: IQueryState<Dict<string[]>>;
  aggregatedAlertCompliance: IQueryState<Dict<string[]>>;
}

export type ContainerState = FindingsOverviewState;

export enum Change {
  Increase = 'Increase',
  Decrease = 'Decrease',
  Same = '',
}

export interface AggregatedAlert {
  alert_rule_id: string;
  description: string;
  policy_id: string;
  resource_types: string[];
  alert_category: string;
  entity_uuid_list: string[];
  severity_critical: number;
  severity_medium: number;
  severity_low: number;
  severity: any;
  multi_resource?: boolean;
}

export enum ResourceTableTabs {
  Insights = 'insights',
  Policies = 'policies',
}

export const AlertCategoryClass = {
  DataSecurity: 'Data Security',
  IdentityAuthorization: 'Identity Authorization',
  CloudPosture: 'Cloud Posture',
  Vulnerabilities: 'Vulnerabilities',
};

export interface FindingsOverviewSearchParamProps {
  tab?: string;
  resourceType?: string;
  alertCategory: string;
  severity?: string;
  categoryClass?: string;
  sortField: string;
  sortOrder: SortOrder;
  group_id: string;
}
