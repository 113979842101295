import React, { FC } from 'react';

import { Center } from '@chakra-ui/react';

export interface Props {
  showMore: boolean;
  toggleShowMore: () => void;
}

export const ShowMoreButton: FC<Props> = props => {
  const { showMore, toggleShowMore } = props;

  const buttonStyles = {
    bg: 'none',
    color: 'primary',
    p: 0,
    _hover: { bg: 'none', cursor: 'pointer' },
    _focus: { border: 'none' },
    _active: { border: 'none', bg: 'none' },
    onClick: toggleShowMore,
    w: 'full',
  };

  return (
    <Center {...buttonStyles}>{showMore ? 'show more' : 'show less'}</Center>
  );
};
