import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.reportsDashboard || initialState;

export const selectReportsDashboard = createSelector(
  [selectDomain],
  reportsDashboard => reportsDashboard,
);
