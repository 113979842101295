import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BruteForceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M251.8,254.9v-67.6c0-10.9-8.6-19.6-19.4-19.6c-10.8,0-19.4,8.7-19.4,19.6v67.2c0,10.9,8.6,19.6,19.4,19.6
	C243.2,274.5,251.8,265.8,251.8,254.9z M322.1,187.3v47.6h39.1v-47.6c0-10.9-8.6-19.6-19.4-19.6C331,167.6,322.1,176.3,322.1,187.3z
	 M197.3,254.9v-47.6c0-10.9-8.6-19.6-19.4-19.6c-10.8,0-19.4,8.7-19.4,19.6v47.6c0,10.9,8.6,19.6,19.4,19.6
	C188.7,274.5,197.3,265.8,197.3,254.9z M314.1,401.1l60.7-61.1c3.6-3.6,5.8-8.7,5.8-13.8v-55.7c0-10.9-8.6-19.6-19.4-19.6h-74.3
	c-10.8,0-19.4,8.7-19.4,19.6c0,10.9,8.6,19.6,19.4,19.6h42.9c4.4,0,8,3.6,8,8.1s-3.6,8.1-8,8.1c-47.3,0-85.6,39.3-85.6,86.9
	c0,4.5-3.6,8.1-8,8.1c-4.4,0-8-3.6-8-8.1c0-37.5,19.8-70.3,49.5-88.1c-8.2-2.6-15-7.7-20.2-14.6c0,0-69.3,0-80,0
	c-7.2,0-14-2.6-19.4-5.9v57.5c0,24.3,12.6,46.2,31.1,59.3V512h124.8V401.1z M267.6,187.3v53.5c5.4-4,12.6-5.9,19.4-5.9h19.4v-47.6
	c0-10.9-8.6-19.6-19.4-19.6C276.1,167.4,267.6,176.3,267.6,187.3z M175.5,31.8c3.8-2,8.6-0.4,10.6,3.4L228,118
	c2,3.8,0.4,8.7-3.4,10.7c-3.8,2-8.6,0.4-10.6-3.4l-41.9-82.8C170.2,38.6,171.8,33.8,175.5,31.8z M370.4,1c2.2-1.2,5-1.4,7.8,0
	c3.8,2,5.2,7.1,3.4,10.5l-54.7,100c-2,3.8-7,5.3-10.4,3.4c-3.8-2-5.2-7.1-3.4-10.5l54.1-100C367.8,2.8,369.2,1.8,370.4,1z M75.5,117
	c1.6-0.8,3.8-1.2,6-0.2l87.2,28.1c4.4,1.4,6.4,5.7,5,9.7c-1.4,4.5-5.6,6.5-9.6,5.1l-87.6-28.5c-4.4-1.4-6.4-5.7-5-9.7
	C72.3,119.4,73.5,118,75.5,117z M277.3,32.4c1.2-0.6,3-1.2,4.8-0.8c4.6,1,7.2,4.9,6.2,9.1l-9.2,58.1c-1,4.7-4.8,7.3-9,6.3
	c-4.2-1-7.2-4.9-6.2-9.1l9.2-58.1C273.8,35.4,275.4,33.4,277.3,32.4z M347.4,129.1l82-42.7c3.8-2,8.6-0.4,10.6,3.4
	c2,3.8,0.4,8.9-3.4,10.9l-82,42.7c-3.8,2-8.6,0.4-10.6-3.4C342.2,136,343.6,131.2,347.4,129.1z"
    />
  </Icon>
);
