import { IGroup } from '@antv/g-base/lib/interfaces';
import G6, { ModelConfig } from '@antv/g6';
import { colors } from 'theme/theme2/colors';

import { getIconColor } from 'components/DataDisplay/Utils';
import { iconSwitch, resourceIcons } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { nodeStyles } from 'components/Visualization/Graphs/nodes/styles';
import { limitedString } from 'utils/string';

import { ResourceNode } from './nodes_types';

export function registerResourceNode() {
  G6.registerNode(
    ResourceNode.Circle,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      drawShape(cfg?: ModelConfig, group?: IGroup) {
        // @ts-ignore
        const data: any = cfg?.data ?? {};
        const color = getIconColor(data?.service ?? data?.resource_type);

        const container = group?.addShape('circle', {
          attrs: {
            x: 30,
            y: 30,
            // shadowColor: '#999',
            shadowBlur: 21,
            shadowOffsetY: 10,
            opacity: 1,
            stroke: color,
            r: nodeStyles.size,
          },
          name: 'container',
        });

        const { x = 0, y = 0, width = 0, height = 0 } = container?.getBBox();
        // @ts-ignore
        let img = iconSwitch(data?.resource_type, resourceIcons);

        group?.addShape('image', {
          attrs: {
            x: x + 10,
            y: y + 10,
            width: 20,
            height: 20,
            img: img,
          },
          name: 'img',
        });

        const name = limitedString(data.name ?? data.id ?? '');
        group?.addShape('text', {
          attrs: {
            x: x,
            y: y + height + 20,
            textAlign: 'left',
            text: name,
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
          },
          name: 'node-name-id',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
