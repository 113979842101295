import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OktaIcon = props => {
  return (
    <Icon viewBox="0 0 512 512" color="#000" w="full" h="full" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M281.7,3.6l-10.5,129.7c-5-0.6-10-0.9-15.1-0.9c-6.4,0-12.7,0.4-18.8,1.4l-6-62.8
	c-0.1-2,1.4-3.7,3.4-3.7h10.7l-5.1-63.5c-0.1-2,1.4-3.7,3.3-3.7h34.9C280.4,0,281.9,1.7,281.7,3.6L281.7,3.6z M228.3,135.4
	c-11.2,2.6-21.9,6.7-31.7,12.1l-27.2-57c-0.8-1.7,0-3.8,1.9-4.6l10.1-3.7l-26.6-58c-0.8-1.7,0-3.8,1.9-4.6l32.7-12
	c1.8-0.7,3.8,0.4,4.4,2.3C193.9,10,228.3,135.4,228.3,135.4L228.3,135.4z M113.5,46.1l75.3,106.1c-9.5,6.3-18.2,13.7-25.6,22.2
	L118,130c-1.4-1.4-1.3-3.7,0.2-5l8.3-6.8L81.6,72.8c-1.4-1.4-1.3-3.7,0.3-5l26.6-22.3c1.5-1.3,3.7-1,4.8,0.6L113.5,46.1z
	 M157.5,181.5c-6.8,9-12.4,18.9-16.5,29.6l-57.5-26.3c-1.9-0.7-2.6-3-1.6-4.7l5.4-9.3l-57.8-27.3c-1.7-0.8-2.4-3-1.4-4.7l17.3-30.1
	c1-1.7,3.3-2.3,4.9-1.2L157.5,181.5z M7.7,189.4c0.3-2,2.3-3.1,4.1-2.7l125.9,32.9c-3.3,10.7-5.1,21.9-5.4,33.6l-63-5.1
	c-2-0.1-3.4-2-3-4l1.8-10.5l-63.6-6c-2-0.2-3.2-2-3-4l6-34.3L7.7,189.4z M2.9,274.5l129.6-12c0.6,11.5,2.9,22.8,6.4,33.3l-60.9,16.8
	c-1.9,0.4-3.9-0.7-4.1-2.7l-1.8-10.5l-61.8,16.1c-1.8,0.4-3.8-0.7-4.1-2.7l-6.1-34.3c-0.3-2,1-3.8,3-4L2.9,274.5z M23.3,364.7
	c-1-1.7-0.3-3.8,1.4-4.7l117.6-55.7c4.4,10.5,10.4,20.3,17.5,29.1l-51.5,36.7c-1.6,1.2-3.9,0.7-4.9-1l-5.4-9.4L45.5,396
	c-1.6,1.1-3.9,0.6-4.9-1.1C40.6,394.8,23.1,364.7,23.3,364.7z M192.2,361.7l-35.9,52c-1.1,1.7-3.4,2-5,0.7l-8.3-7l-37,52
	c-1.2,1.6-3.3,1.9-4.9,0.6l-26.8-22.3c-1.5-1.3-1.7-3.6-0.3-5l91.5-92.6C173.5,348.4,182.5,355.7,192.2,361.7L192.2,361.7z
	 M147.7,488.8c-1.9-0.7-2.7-2.9-1.9-4.6L200,366c10,5.1,20.8,9,32,11.1l-16,61.2c-0.4,1.8-2.5,3-4.4,2.3l-10.1-3.7l-16.9,61.6
	c-0.6,1.8-2.6,3-4.4,2.2l-32.7-11.9L147.7,488.8z M256.1,379.6c6.4,0,12.7-0.4,18.8-1.4l6,62.9c0.2,2-1.4,3.7-3.4,3.7h-10.7
	l5.1,63.6c0.3,2-1.3,3.7-3.2,3.7h-34.9c-1.8,0-3.4-1.7-3.3-3.7L241,378.6C246,379.3,250.9,379.6,256.1,379.6L256.1,379.6z
	 M312.1,145.6c-10-5-20.6-8.8-32-11.1L296,73.4c0.6-1.9,2.6-3,4.4-2.3l10.1,3.7l16.9-61.6c0.6-1.8,2.6-3,4.4-2.3l32.7,12
	c1.9,0.7,2.7,2.7,1.9,4.6L312.1,145.6z M437.9,79.1l-91.5,92.6c-7.7-8.3-16.5-15.5-26.3-21.5L356,98.1c1.2-1.5,3.4-2,5-0.7l8.3,7
	l37-52c1.1-1.6,3.4-1.9,4.8-0.6l26.8,22.3C439.4,75.4,439.4,77.6,437.9,79.1L437.9,79.1z M487.3,152l-117.6,55.8
	c-4.6-10.5-10.4-20.4-17.5-29.2l51.5-36.7c1.6-1.3,3.9-0.7,4.9,1l5.4,9.3l52.5-36.3c1.6-1,3.8-0.6,4.8,1.1l17.5,30.2
	c1,1.7,0.4,3.8-1.4,4.7L487.3,152z M506,199.1l6,34.3c0.3,2-1,3.7-3,4l-129.6,12.1c-0.6-11.7-2.9-22.7-6.4-33.3l60.9-16.8
	c1.8-0.6,3.8,0.7,4.1,2.7l1.9,10.5l61.7-16.1c1.9-0.4,3.9,0.7,4.1,2.7L506,199.1z M500,324.8L374,291.9c3.3-10.7,5.1-21.9,5.4-33.5
	l63,5.1c2,0.3,3.3,2,3,4l-1.9,10.5l63.6,6c2,0.3,3.3,2,3,4l-6,34.3c-0.3,2-2.3,3.1-4.1,2.7L500,324.8z M466.5,403.2
	c-1,1.7-3.3,2.1-4.9,1.1l-107.1-74c6.8-9,12.4-18.9,16.5-29.6l57.5,26.3c1.9,0.9,2.6,3,1.6,4.7l-5.4,9.3l57.8,27.3
	c1.7,0.9,2.4,3,1.4,4.7L466.5,403.2z M323.1,359.5c9.5-6.1,18.1-13.7,25.6-22.2l45.1,44.4c1.4,1.4,1.4,3.7-0.2,5l-8.3,6.8l44.8,45.4
	c1.3,1.4,1.3,3.7-0.3,4.9l-26.6,22.4c-1.4,1.3-3.7,1-4.9-0.6l-75.3-106.1L323.1,359.5z M322.5,504c-1.8,0.7-3.8-0.4-4.4-2.3
	l-34.4-125.4c11.2-2.6,21.9-6.7,31.7-12.1l27.2,57.1c0.8,1.8,0,4-1.9,4.5l-10.1,3.7l26.6,58.1c0.8,1.8,0,3.8-1.9,4.5l-32.7,12
	L322.5,504z"
      />
    </Icon>
  );
};
