import React, { FC, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { IconTypes, ListIcon } from 'components/Icons';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useVulnerabilityTableColumns } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilityTableColumns';
import { CVEHostsDrawer } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHostsDrawer';
import { CvesById } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CvesById';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

import { actions } from '../../slice';

export const VulnerabilityTableComponent: FC = () => {
  const dispatch = useDispatch();
  const { vulnerabilities, selectedTab } = useSelector(selectVulnerabilities);
  const {
    tableColumns,
    tableActionModals,
    handleRowClick,
  } = useVulnerabilityTableColumns();
  const hosts = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});

  return (
    <>
      <Box h="full" maxW="100%" className={'table-wrapper'} overflow={'auto'}>
        <Table
          data={vulnerabilities.data}
          isError={vulnerabilities.isError}
          columns={[
            ...tableColumns,
            ...(selectedTab === IconTypes.Packages
              ? [
                  {
                    header: 'Actions',
                    render: ({ row }) => (
                      <Center>
                        <ActionButton
                          label={'Show all entities'}
                          icon={<ListIcon />}
                          onClick={e => {
                            e.stopPropagation();
                            hosts.onOpen();
                            setCurrentRecord(row);
                          }}
                        />
                      </Center>
                    ),
                    styles: { width: '60px', cell: { maxWidth: '60px' } },
                  },
                ]
              : []),
          ]}
          isLoading={vulnerabilities.isLoading}
          cursor="pointer"
          styles={{ header: { position: 'relative', zIndex: 800 } }}
          onRowClick={r => {
            setCurrentRecord(r);
            selectedTab !== IconTypes.Packages &&
              handleRowClick(
                r,
                selectedTab === NativeResources.ElasticContainerRegistry
                  ? MetadataTabs.ContainerImages
                  : MetadataTabs.Vulnerabilities,
              );
          }}
          pagination={{
            pageInfo: vulnerabilities.page.info,
            onChange: info =>
              dispatch(actions.updateVulnerabilityPageInfo(info)),
            totalCount: vulnerabilities.page.totalCount,
          }}
          {...(selectedTab === IconTypes.Packages
            ? {
                subComponent: ({ row, expanded }) => (
                  <CvesById currentRecord={row} isExpanded={expanded} />
                ),
              }
            : {})}
        />
        {tableActionModals}
        {hosts.isOpen && (
          <CVEHostsDrawer
            row={currentRecord}
            isOpen={hosts.isOpen}
            onClose={hosts.onClose}
            selectedTab={selectedTab as string}
          />
        )}
      </Box>
    </>
  );
};
