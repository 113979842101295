import React, { FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { formatBytes } from 'components/DataDisplay';
import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import { renderDataSummaryField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { toTitleCase } from 'utils/string';

export const DataSummary: FC = () => {
  const { data, resourceType } = useSelector(selectActiveResource);
  const { data: summaryData, isLoading } = data.summary;

  const getLabel = () => {
    switch (resourceType) {
      case NativeResources.SimpleStorageService:
        return 'bucket';
      case NativeResources.GitHubRepository:
      case NativeResources.GitLabProject:
      case NativeResources.BitBucketRepository:
        return 'repository';
      default:
        return 'database';
    }
  };

  return (
    <FootprintBox
      content={
        <Stack w="full" h="full" justify="space-between" px={4} py={6}>
          {renderDataSummaryField(
            'Size of ' + getLabel(),
            formatBytes(summaryData?.source_size),
          )}
          {renderDataSummaryField(
            'Number of ' +
              (resourceType === NativeResources.SimpleStorageService
                ? 'files in bucket'
                : resourceType === NativeResources.GitLabProject ||
                  resourceType === NativeResources.GitHubRepository ||
                  resourceType === NativeResources.BitBucketRepository
                ? 'repositories'
                : 'tables'),
            summaryData?.object_count,
          )}
          {renderDataSummaryField(
            toTitleCase(getLabel()) + ' scanned',
            summaryData?.percentage_scanned + '%',
          )}
        </Stack>
      }
      label="Summary"
      isLoading={isLoading}
    />
  );
};
