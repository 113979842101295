import dayjs from 'dayjs';

export const ticketingServices = [
  { value: 'jira', label: 'Jira' },
  { value: 'servicenow', label: 'Service Now' },
];

export const notificationServices = [
  { value: 'slack', label: 'Slack' },
  // { value: 'servicenow', label: 'Service Now' },
];

export const snoozeDurationOptions = [
  { label: '15 mins', value: '15Min', time: 15, type: 'minutes' },
  { label: '30 mins', value: '30Min', time: 30, type: 'minutes' },
  { label: '60 mins', value: '60Min', time: 60, type: 'minutes' },
  { label: '3 hours', value: '3Hour', time: 3, type: 'hours' },
  { label: '6 hours', value: '6Hour', time: 6, type: 'hours' },
  { label: '24 hours', value: '24Hour', time: 24, type: 'hours' },
  { label: '7 days', value: '7Day', time: 7, type: 'days' },
  { label: '30 days', value: '30Day', time: 30, type: 'days' },
  { label: '3 months', value: '3Month', time: 3, type: 'months' },
  { label: '6 months', value: '6Month', time: 6, type: 'months' },
  { label: '12 months', value: '12Month', time: 12, type: 'months' },
];

export const getSnoozedTime = (value: Record<string, any>) => {
  return dayjs().utc().add(value.time, value.type).diff(dayjs(), 'minutes');
};
