import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ApacheCassandraIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <g>
      <path
        fill="#BBE6FB"
        d="M409.1,211.6c-3.8-43.9-78.9-73.3-167.8-65.6S83.2,195.3,87,239.2c3.8,43.9,78.9,73.3,167.8,65.6
		C343.8,297.2,412.8,255.4,409.1,211.6"
      />
      <path
        fill="#FFFFFF"
        opacity={0.35}
        d="M243.8,151.1c32.9,0.6,65.4,4.5,89,8.2c8.3,13.5,13,28.9,13,45.4c0,52-47,94.2-105,94.2s-105-42.2-105-94.2
		c0-9.5,1.6-19,4.7-28C170.2,164,209.9,150.5,243.8,151.1"
      />
      <path
        fill="#FFFFFF"
        d="M240.9,151.1c25,1.7,49.8,4.7,74.5,9c7.1,12,12.2,26,11.2,40.8c-3.2,45.5-41.6,82.6-85.1,82.6
		c-43.7,0-85.1-37-85.1-82.6c0-10.8,2.2-21.5,6.4-31.4C189.7,159.7,222.1,149.9,240.9,151.1"
      />
      <path
        fill="#373535"
        d="M192.7,194.9c-7.2-9.8-12-21.3-13.7-33.5c25.2-12.3,62.4-10.3,62.4-10.3s46.8-2.4,71,15.5
		c-1.3,5.8-3.2,11.4-5.8,16.8c-0.6-0.1-1.2-0.2-1.9-0.2c-4.7,0-8.4,3.8-8.4,8.4c0,2,1.3,6.2,2.5,7.6c-5.1,6.5-11.8,9.9-18.7,14.3
		c-2.2-13.2-13.7-23.3-27.6-23.3c-15.5,0-29.1,12.6-29.1,28c0,0.5-0.4,0.9-0.3,1.4c-10-3.8-17.7-6.5-26.3-14.1
		c2.1-5.6,6.4-14.5,9.8-19.1c1.1,0.4,2.2,0.6,3.3,0.6c5.1,0,8.4-1.2,8.4-6.4c0-1.3-1.3-4.4-1.8-5.6c15.4-12.4,38.1-19.6,58.2-16.8
		l-5.5-1.5c-19.5-4.4-40,0.1-55.9,12.3c-1.1-0.4-2.2-0.7-3.4-0.7c0,0,0,0,0,0c-5.1,0-9.3,4.2-9.3,9.3c0,1.1,0.8,4.5,1.2,5.5
		C198.5,187.3,195,189.8,192.7,194.9 M308.2,188c1.9-5.6,5.2-14.1,5.6-20.3c7.6,6.1,12.7,14.6,12.7,26c0,15-5.2,32.7-12.1,45
		c-9.4-7.7-21.2-12.8-33.7-14.8c8.2-5.3,17.3-15.8,22.6-24c0.5,0.1,0.9,0.1,1.4,0.1c4.7,0,9.2-3.7,6.9-7.8
		C310.8,190.6,309.6,189.1,308.2,188 M206.7,265.5c-3-3.8-5.6-8-7.5-12.5c2.4-2.2,5.5-7.3,5.5-10.9c0-5.9-4.2-10.9-10-12
		c-0.8-6.8-0.4-13.7,1-20.4l0.2-0.8c8.1,8.9,18.6,15.6,30.4,19.2c0.9,2.3,3,7.1,4.4,9C221.2,245.1,210.9,254,206.7,265.5 M206,275.1
		l-0.1,0.3c-29.6-13.3-49.6-47.2-49.6-81.8c0-15.6,9.4-25.6,22-31.9l0,1.5c0,14.2,4.8,28.5,12.2,39.4c-0.7,2.1-1.8,3.2-2.3,5.4
		c-1.7,7.8-1.5,18.6-0.6,26.1c-4.1,2-6.6,4.4-7.5,8c-1.6,6.6,5.5,12.2,12.2,12.2c0.6,0,1.2-0.1,1.9-0.2
		C197.6,260.3,201,270.2,206,275.1 M278.9,227.4c12.1,1.1,24,7.8,34.1,14.7c-15.2,23.3-41.6,36.6-71.5,36.6c-7.4,0-16.9,1.8-23.7,0
		c-2.3-2-3.7-3.8-5.7-5.9c4.5-11.6,13.4-25.1,23.2-32.7c4.9,3.9,11,6,17.3,6C264.7,246.1,275.1,238.2,278.9,227.4"
      />
      <path
        fill="#1287B1"
        d="M191.9,199c-7.2-9.8-12-21.4-13.7-33.5c25.2-12.3,62.4-10.3,62.4-10.3s46.8-2.4,71,15.5
		c-1.3,5.8-3.2,11.4-5.8,16.8c-0.6-0.2-1.3-0.2-1.9-0.2c-4.7,0-8.4,3.8-8.4,8.4c0,1.9,0.7,3.8,1.9,5.3c-5.1,6.5-11.2,12.1-18.2,16.6
		c-2.2-13.2-13.7-23.3-27.6-23.3c-15.5,0-28,12.5-28,28l0.1,1.4c-10-3.8-19-10-26.3-17.9c2.1-5.6,4.9-10.7,8.4-15.4
		c1.1,0.4,2.2,0.6,3.3,0.6c0,0,0,0,0,0c5.1,0,9.3-4.2,9.3-9.3c0-1.2-0.2-2.5-0.7-3.6c15.4-12.4,36.2-18.6,56.3-15.8l-5.5-1.5
		c-19.5-4.4-40,0.1-55.9,12.3c-1.1-0.4-2.2-0.7-3.4-0.7c-1.1,0-2.2,0.2-3.3,0.6c-4.8,1.8-7.2,7.1-5.4,11.9
		C197.1,189.2,194.2,193.9,191.9,199 M309.5,189.3c1.9-5.7,3.1-11.6,3.5-17.6c7.6,6.1,12.7,14.5,12.7,26c0,14.5-3.7,28.7-10.7,41.4
		c-9.4-7.8-20.7-12.9-32.8-14.7c8.2-5.3,15.1-12.3,20.4-20.5c0.5,0.1,0.9,0.1,1.4,0.1c4.7,0,8.4-3.8,8.4-8.4
		C312.3,193.1,311.2,190.9,309.5,189.3 M208,268.2c-3.1-4.1-5.8-8.5-8-13.1c2.5-2.3,3.9-5.6,3.9-9c0-6-4.3-10.9-10-12
		c-0.8-6.8-0.4-13.7,1-20.4l0.2-0.8c8.2,9,18.7,15.7,30.4,19.2c0.9,2.3,2,4.4,3.5,6.4C219.4,246.4,212.2,256.7,208,268.2 M205.9,275
		l-0.1,0.3c-29.6-13.3-50.3-43-50.3-77.6c0-15.6,9.4-25.6,22-31.9l0,1.5c0,14.2,4.4,27.3,11.8,38.2c-0.7,2.1-1.3,4.3-1.8,6.5
		c-1.7,7.6-2.1,15.4-1.1,23.1c-4.2,2-6.9,6.3-6.9,11c0,6.7,5.5,12.2,12.2,12.2c0.6,0,1.2-0.1,1.9-0.2C196.8,264.4,201,270,205.9,275
		 M278.1,231.3c12.1,1.1,23.8,5.6,33.9,12.6c-15.2,23.3-41.4,38.7-71.3,38.7c-7.3,0-14.5-0.9-21.5-2.7c-2.3-1.9-4.4-4-6.5-6.2
		c4.5-11.6,11.9-22.1,21.7-29.7c4.9,3.9,11,6,17.3,6C263.9,250.1,274.3,242.2,278.1,231.3"
      />
      <path
        fill="#FFFFFF"
        d="M304.9,234.1l-26.2-2.1l19.5,19.2l-25.5-10l15,23l-23.3-18l7.5,27.7l-12.7-24.2l-2.1,28.4l-8.6-28.3
		l-10.1,26.9L241,247l-17.7,25.1l8.3-30.9l-23,18.5l18.2-23l-26.2,9.5L226,230l-30.2-2.2l30.6-6.6l-30.5-7.9l29.9-0.9l-23.3-17.2
		l29.5,10.8l-18.6-23.4l23.8,17.3l-7.9-27.8l14.7,26.2l-0.5-29.2l8.5,24.8l10.2-24.5l0.4,27.5l15.5-21.8l-7.8,26.4l22.5-15
		l-16.3,22.6l24-8.6L279,216.7l26,1.3l-25.9,6.2L304.9,234.1z"
      />
      <g>
        <path
          fill="#373535"
          opacity={0.35}
          d="M3.7,273.6C79.7,232.7,109.8,172.4,252,167c107.6-4.1,157.8,45.6,174.4,46.3c50.6,2.2,82.4-46.8,82.4-46.8
			s-31.8,36.6-54.9,27.7c20.7-12.1,36.6-41.7,36.6-41.7s-40.4,41.7-68.4,35.5c25.3-19.9,37.7-54.4,37.7-54.4s-32.1,49.6-52.2,51.7
			c23.2-20.5,32.8-49.5,32.8-49.5s-26.4,38.8-46.8,41.5c27.5-24.8,28.5-40.4,28.5-40.4s-38,49.1-53.3,41.5
			c18.3-19.9,30.7-45.2,30.7-45.2s-31.8,43.1-40.4,36.1c-5.9-1.1,9.7-20.5,9.7-20.5s-19.4,17.2-24.8,12.9c9.7-5.9,26.4-35,26.4-35
			s-32.3,37.2-40.4,32.8l15.6-31.6c0,0-15.6,31.6-36.6,26.3c9.7-12.4,13.4-23.7,13.4-23.7s-16.1,25.9-36,18.9
			c-4.7-1.6,5.8-13.5,5.8-13.5s-13.4,13-22.5,11.4c-9.2-1.6,0.5-18.3,0.5-18.3s-16.1,25.3-19.4,24.2c-3.2-1.1,1.6-17.8,1.6-17.8
			s-11.8,19.4-18.3,18.3c-6.5-1.1,0-19.9,0-19.9s-7,21-12.4,19.4c-5.4-1.6-6.5-14-6.5-14s-1.5,16.8-9.2,18.3
			C148,168.7,83.9,193.4,3.7,273.6"
        />
        <path
          fill="#373535"
          d="M7,267.5c75.9-40.9,106.1-101.2,248.2-106.6c107.6-4.1,157.8,45.6,174.4,46.3c50.6,2.2,82.4-46.8,82.4-46.8
			s-31.8,36.6-54.9,27.7c20.7-12.1,36.6-41.7,36.6-41.7s-40.4,41.7-68.4,35.5c25.3-19.9,37.7-54.4,37.7-54.4s-32.1,49.6-52.2,51.7
			c23.2-20.5,32.8-49.5,32.8-49.5s-26.4,38.8-46.8,41.5c27.5-24.8,28.5-40.4,28.5-40.4s-38,49.1-53.3,41.5
			c18.3-19.9,30.7-45.2,30.7-45.2s-31.8,43.1-40.4,36.1c-5.9-1.1,9.7-20.5,9.7-20.5s-19.4,17.2-24.8,12.9c9.7-5.9,26.4-35,26.4-35
			s-32.3,37.1-40.4,32.8l15.6-31.6c0,0-15.6,31.6-36.6,26.2c9.7-12.4,13.4-23.7,13.4-23.7s-16.1,25.9-36,18.9
			c-4.7-1.6,5.8-13.5,5.8-13.5s-13.4,13-22.5,11.4c-9.2-1.6,0.5-18.3,0.5-18.3s-16.2,25.3-19.4,24.3s1.6-17.8,1.6-17.8
			s-11.8,19.4-18.3,18.3s0-19.9,0-19.9s-7,21-12.4,19.4c-5.4-1.6-6.5-14-6.5-14s-1.5,16.8-9.2,18.3C151.3,162.6,87.2,187.3,7,267.5"
        />
        <path
          fill="#373535"
          opacity={0.35}
          d="M1.6,282.4c9.8,7.8,21,15.3,53.3,15.4c11,0,46-1.7,52.5,3.9c6.5,5.7-21.8,32.3-21.8,32.3s47.8-40.4,52.5-26.6
			c2.9,8.6-13.7,31.5-13.7,31.5s16.7-22.6,29.1-27.5c8.5-3.3,15.7-2.9,21.8,5.6c4,5.6-19.4,33.1-19.4,33.1s31.5-30.7,37.2-29.1
			c5.6,1.6,0,29.1,0,29.1s11.6-27.8,17.8-29.9c8.5-2.8-19.4,64.6-19.4,64.6s34-62.4,40.4-63.8c10.9-2.4,18.2,43.6,18.2,43.6
			s-5.3-40.2,0.4-42.8c36.2-16.4,15.3,69.4,15.3,69.4s23.7-58.2,9.1-71.2c38.4,25.4,33.7,71.2,33.7,71.2s10.1-21.8-18.2-75.3
			c15.3-0.8,38.4,39.8,38.4,39.8s-25-44.4-8.1-45.2c33.7-1.6,37.2,59,37.2,59s10.1-8.5-17-67.8c17.2-11,59,54.9,59,54.9
			s-38.5-64.3-32.3-68.6c6.2-4.3,28.8,20.5,28.8,20.5s-17.2-23.7-12.6-26.1c4.6-2.4,50.4,47.1,50.4,47.1s-40.4-48.4-33.4-54.4
			c7-5.9,36.6,16.7,36.6,16.7s-45.5-29.9-36.6-34.5c12.4-6.4,47.6,15.4,47.6,15.4s-21.3-17.5-17.8-21.8c3.5-4.3,45.2,27.5,45.2,27.5
			s-36.9-29.1-38.8-36.3c-1.9-7.3,29.1,2.4,29.1,2.4s-34.2-15.9-34.7-20.5c-0.6-4.6,22.6,5.1,22.6,5.1s-31.8-26.1-39.6-4.8
			c-25,47.6-77.5,74.3-138.9,83.2s-106.6-4-143.7-14.5C82.8,279.2,44,303.7,1.6,282.4"
        />
        <path
          fill="#373535"
          d="M0,277.6c9.8,7.8,21,15.3,53.3,15.4c11,0,46-1.7,52.5,3.9c6.5,5.7-21.8,32.3-21.8,32.3s47.8-40.4,52.5-26.6
			c2.9,8.6-13.7,31.5-13.7,31.5s16.7-22.6,29.1-27.5c8.5-3.3,15.7-2.9,21.8,5.6c4,5.7-19.4,33.1-19.4,33.1s31.5-30.7,37.1-29.1
			c5.7,1.6,0,29.1,0,29.1s11.6-27.8,17.8-29.9c8.5-2.8-19.4,64.6-19.4,64.6s34-62.4,40.4-63.8c10.9-2.4,18.2,43.6,18.2,43.6
			s-5.3-40.2,0.4-42.8c36.2-16.4,15.3,69.4,15.3,69.4s23.7-58.2,9.1-71.2c38.4,25.4,33.7,71.2,33.7,71.2s10.1-21.8-18.2-75.3
			c15.3-0.8,38.4,39.8,38.4,39.8s-25-44.4-8.1-45.2c33.7-1.6,37.2,59,37.2,59s10.1-8.5-17-67.8c17.2-11,59,54.9,59,54.9
			s-38.5-64.3-32.3-68.6c6.2-4.3,28.8,20.5,28.8,20.5S377.4,280,382,277.6s50.3,47.1,50.3,47.1S392,276.2,399,270.3
			s36.6,16.7,36.6,16.7s-45.5-29.9-36.6-34.5c12.4-6.4,47.6,15.4,47.6,15.4s-21.3-17.5-17.8-21.8c3.5-4.3,45.2,27.5,45.2,27.5
			s-36.9-29.1-38.8-36.3c-1.9-7.3,29.1,2.4,29.1,2.4s-34.2-15.9-34.7-20.5c-0.6-4.6,22.6,5.1,22.6,5.1s-31.8-26.1-39.6-4.8
			c-25,47.6-77.5,74.3-138.9,83.2c-61.4,8.9-106.6-4-143.7-14.5C81.2,274.3,42.4,298.8,0,277.6"
        />
      </g>
    </g>
  </Icon>
);
