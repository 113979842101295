import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AriksaLogoIcon = props => (
  <Icon
    aria-label="Ariksa"
    viewBox="0 0 4000 2000"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <>
      <g>
        <g>
          <path
            d="M1885.04,1123.2h-213.76l-52.9,130.45H1543l210.14-507.27h50.75l210.14,507.27h-75.37L1885.04,1123.2z M1700.24,1054.36
			h156.55l-78.28-188.42L1700.24,1054.36z"
          />
          <path
            d="M2255.39,973.92c-7.24-1.93-16.16-2.9-26.81-2.9c-17.38,0-34.65,5.45-51.8,16.31c-17.15,10.88-30.55,24.52-40.22,40.95
			v225.37h-68.85V913.04h66.67v39.14c9.19-13.05,22.71-24.04,40.57-32.97c17.89-8.93,38.42-13.41,61.61-13.41
			c9.67,0,18.58,0.72,26.81,2.17L2255.39,973.92z"
          />
          <path
            d="M2330.68,759.78c8.71-8.95,19.33-13.41,31.9-13.41s23.31,4.46,32.26,13.41c8.95,8.93,13.41,19.69,13.41,32.24
			c0,12.57-4.46,23.19-13.41,31.9c-8.95,8.69-19.69,13.03-32.26,13.03s-23.19-4.34-31.9-13.03c-8.68-8.71-13.02-19.33-13.02-31.9
			C2317.66,779.47,2322,768.71,2330.68,759.78z M2328.52,913.04h68.85v340.61h-68.85V913.04z"
          />
          <path
            d="M2831.13,1253.64h-92.01l-168.85-166.68v166.68h-68.85V739.11h68.85v304.37l142.01-130.45h97.13l-170.32,153.64
			L2831.13,1253.64z"
          />
          <path
            d="M2896.93,1163.05c32.35,25.12,65.95,37.69,100.72,37.69c15.95,0,29.83-3.98,41.68-11.95
			c11.82-7.97,17.74-18.24,17.74-30.8c0-7.24-1.94-13.64-5.8-19.2c-3.86-5.57-9.78-10.14-17.74-13.78
			c-7.96-3.62-15.71-6.52-23.19-8.69c-7.48-2.17-17.38-4.83-29.71-7.97c-12.33-3.14-22.35-5.91-30.07-8.33
			c-52.18-17.38-78.28-46.62-78.28-87.69c0-34.29,12.57-60.62,37.7-78.98c25.1-18.36,54.58-27.54,88.39-27.54
			c44.46,0,82.14,12.57,113.05,37.69l-35.49,49.27c-23.67-17.86-48.08-26.81-73.19-26.81c-17.41,0-32.02,3.87-43.87,11.59
			c-11.82,7.73-17.74,18.12-17.74,31.16c0,10.64,4.97,18.6,14.87,23.92c9.9,5.31,25.22,10.62,45.99,15.93
			c16.43,4.35,30.7,8.83,42.76,13.41c12.09,4.59,24.9,11,38.42,19.21c13.52,8.21,24.03,18.72,31.51,31.52
			c7.51,12.81,11.25,27.41,11.25,43.84c0,30.92-12.45,56.04-37.31,75.37c-24.9,19.33-54.73,28.98-89.5,28.98
			c-53.14,0-99.04-15.69-137.7-47.1L2896.93,1163.05z"
          />
          <path
            d="M3458.75,1222.48c-28.52,25.61-63.53,38.41-105.09,38.41c-31.87,0-60.38-7.48-85.52-22.46
			c-26.57-15.47-47.7-36.83-63.41-64.14c-15.71-27.29-23.55-57.62-23.55-90.95c0-33.33,7.96-63.76,23.91-91.31
			c15.95-27.54,37.22-49.04,63.79-64.5c24.63-14.5,52.39-21.74,83.33-21.74c41.05,0,76.57,12.81,106.52,38.41v-31.16h66.67v340.61
			h-66.67V1222.48z M3456.56,1019.58c-24.63-32.38-57-48.56-97.1-48.56c-31.9,0-58.11,11-78.64,32.97
			c-20.53,21.99-30.79,48.44-30.79,79.35c0,30.92,10.74,57.38,32.26,79.35c21.48,21.98,48.41,32.97,80.79,32.97
			c38.18,0,69.33-16.19,93.48-48.55V1019.58z"
          />
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M1087.85,1457.31l-65.88-244.82l-25.59-94.68l-14.09-52.55l-79.21-99.12l-79.21,99.12l-33.7,125.01l-23.75,88.55
							l-59.75,221.38l76.14,35.54l83.96,39.07l36.31,16.85l92.84-43.2l103.56-48.26L1087.85,1457.31z M919.18,1500.05l-16.09,8.27
							l-16.09-8.27l-83.04-42.74l44.58-165.61l7.35-27.42l14.09-52.24l2.45-8.89l26.81-99.73l3.83-14.25l3.83,14.25l18.23,67.56
							l11.34,42.13l13.79,51.32l0.31,1.22l51.63,191.66L919.18,1500.05z"
                  />
                  <path
                    d="M903.25,408.33h-0.15L474.59,945.46l100.35,373.81l-0.15,0.15l30.33,113.22l12.72,47.19l0.15-0.15l42.9-159.33v-0.15
							l79.82-296.91l162.39-203.45l162.55,203.45l79.97,296.91l-0.15,0.15l42.9,159.48l0.15-0.15l12.72-47.03l30.33-113.22v-0.15
							l100.19-373.81L903.25,408.33z M1188.51,1160.25l-47.49-175.11L903.25,687.16l-0.15,1.23v-1.23L665.32,985.14l-47.34,175.11
							L565.43,964.3l337.81-423.3l337.66,423.3L1188.51,1160.25z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  </Icon>
);
