import React, { FC } from 'react';

import { AlertResponse } from '@ariksa/notification';
import { NotificationFor } from '@ariksa/notification/api';
import { HStack, IconButton, useDisclosure } from '@chakra-ui/react';

import { primaryButtonStyles, PrimaryIconButton } from 'components/DataEntry';
import {
  ExceptionIcon,
  NotificationIcon,
  AutomationIcon,
} from 'components/Icons';
import { AddException } from 'containers/Findings/Alerts/Components/AddException';
import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';
import { useAlertStatus } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/hooks/useAlertStatus';

interface IEvidenceControls {
  alert: AlertResponse;
}

export const EvidenceControls: FC<IEvidenceControls> = props => {
  const { alert } = props;
  const exceptionModal = useDisclosure();
  const ticketModal = useDisclosure();
  const notificationModal = useDisclosure();
  const { getAlertListStatus } = useAlertStatus();
  const onCreateTicket = () => {
    getAlertListStatus([alert.uuid!]);
  };

  return (
    <HStack pos={'absolute'} right={0} top={0} p={4}>
      <PrimaryIconButton
        icon={<AutomationIcon />}
        aria-label={''}
        onClick={() => ticketModal.onOpen()}
      />
      <PrimaryIconButton
        icon={<NotificationIcon />}
        aria-label={''}
        onClick={() => notificationModal.onOpen()}
      />

      <IconButton
        icon={<ExceptionIcon />}
        aria-label={''}
        {...primaryButtonStyles}
        size={'sm'}
        p={1}
      />
      <CreateTicket
        onClose={ticketModal.onClose}
        isOpen={ticketModal.isOpen}
        alert={alert}
        onCreate={onCreateTicket}
        notification_for={NotificationFor.Alerts}
      />
      <CreateNotification
        onClose={notificationModal.onClose}
        isOpen={notificationModal.isOpen}
        alert={alert}
        onCreate={onCreateTicket}
        notification_for={NotificationFor.Alerts}
      />
      <AddException
        onClose={exceptionModal.onClose}
        isOpen={exceptionModal.isOpen}
        alert={alert}
      />
    </HStack>
  );
};
