import React, { useState, FC, useEffect } from 'react';

import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { useAlertWorkflowWizardContext } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowWizardContext';

import { selectAlertWorkflow } from '../../../../selectors';
import { tagStyles } from '../../../../styles';
import { quantifierOptions } from '../../../../utils';

interface Props {}

export const AlertConditionsLabel: FC<Props> = props => {
  const { updateFormData } = useAlertWorkflowWizardContext();
  const { formData } = useSelector(selectAlertWorkflow);
  const { condition_quantifier } = formData;
  const [quantifier, setQuantifier] = useState<Record<string, any>>(
    quantifierOptions[0],
  );

  useEffect(() => {
    setQuantifier(
      quantifierOptions.find(o => o.value === condition_quantifier) || {},
    );
  }, [condition_quantifier]);

  return (
    <Wrap align="center">
      <WrapItem>
        <Tag label="IF" styles={tagStyles} />
      </WrapItem>
      <WrapItem>
        <Box w={24}>
          <Select
            name="tempName3"
            options={quantifierOptions}
            value={quantifier}
            onChange={selected => {
              updateFormData('condition_quantifier', selected);
            }}
          />
        </Box>
      </WrapItem>
      <WrapItem>
        <Text>of the following conditions are met</Text>
      </WrapItem>
    </Wrap>
  );
};
