import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DashboardIcon = props => (
  <Icon
    viewBox="0 0 281.488 281.488"
    w="full"
    h="full"
    color="inherit"
    {...props}
  >
    <path d="M15.6,10.6l-2.7,2.7c-0.3-0.2-0.6-0.3-0.9-0.3c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9c0-0.3-0.1-0.7-0.3-0.9l2.7-2.7c0.2-0.2,0.2-0.5,0-0.7C16.2,10.5,15.8,10.5,15.6,10.6z M12,15.9c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C12.9,15.5,12.5,15.9,12,15.9z M20.2,9.2C17,4.7,10.7,3.7,6.2,6.8C1.7,10,0.7,16.3,3.9,20.8c0,0,0,0,0,0C4,21,4.3,21.1,4.6,20.9c0.2-0.2,0.3-0.5,0.1-0.7C3.6,18.7,3,16.9,3,15c0-1.9,0.6-3.7,1.7-5.2c2.9-4,8.5-5,12.5-2.1c4,2.9,5,8.5,2.1,12.5c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3-0.1,0.4-0.2c1.2-1.7,1.9-3.7,1.9-5.8C22,12.9,21.4,10.9,20.2,9.2z" />
  </Icon>
);
