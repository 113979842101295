import React, { memo } from 'react';

import { Routes, Route } from 'react-router-dom';

import { ComparePolicies } from 'containers/Compliance/Components/ComparePolicies';
import { ComplianceContextProvider } from 'containers/Compliance/Components/hooks/context';
import { useInjector } from 'utils/inject';

import { ComplianceSummary } from './Components/ComplianceSummary';
import { complianceSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const Compliance = memo(() => {
  useInjector(sliceKey, reducer, complianceSaga);

  return (
    <ComplianceContextProvider>
      <Routes>
        <Route path="compare-policy" element={<ComparePolicies />} />
        <Route index element={<ComplianceSummary />} />
      </Routes>
    </ComplianceContextProvider>
  );
});
