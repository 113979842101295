import React, { FC } from 'react';

import { Stack, Text, useDisclosure } from '@chakra-ui/react';

import { TicketIcon } from 'components/Icons';
import { ContextFilterModal } from 'containers/Visibility/Components/ContextFilterModal';
import { ContextFilterItems } from 'containers/Visibility/Components/Filter/ContextFilterItem';
import { clearFormTextStyle } from 'containers/Visibility/Organization/styles';
import { SidebarAccordion } from 'containers/Visibility/SecurityGraph/SidebarAccordion';

interface IContextFilter {
  nodes: any[];
  resources: any[];
  contextFilters;
  setContextFilters;
}

export const ContextFilter: FC<IContextFilter> = props => {
  const { contextFilters, setContextFilters, nodes, resources } = props;

  const contextFilter = useDisclosure();

  return (
    <Stack spacing={3}>
      <SidebarAccordion
        header={'CONTEXT FILTERS'}
        showClear={!!contextFilters.length}
        onClear={() => setContextFilters([])}
        icon={<TicketIcon />}
      >
        <Text {...clearFormTextStyle} onClick={() => contextFilter.onOpen()}>
          + Add filters
        </Text>

        <ContextFilterItems
          filters={contextFilters}
          onRemove={e => {
            setContextFilters(contextFilters.filter(f => f.key !== e.key));
          }}
        />

        {contextFilter.isOpen && (
          <ContextFilterModal
            onClose={contextFilter.onClose}
            isOpen={contextFilter.isOpen}
            nodes={nodes ?? []}
            resources={resources ?? []}
            onSubmit={e => setContextFilters([...contextFilters, e])}
          />
        )}
      </SidebarAccordion>
    </Stack>
  );
};
