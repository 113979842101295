import React, { FC, useEffect, useState } from 'react';

import { EnvironmentRead } from '@ariksa/compliance-policies/api';
import { useLocation } from 'react-router-dom';

import { NavBar } from 'components/Navigation';
import { NavBarContextProps } from 'components/Navigation/NavBar/context';
import { useShowEnvironment } from 'containers/App/utils';

interface INavBarWrapper extends NavBarContextProps {}

export const NavBarWrapper: FC<INavBarWrapper> = props => {
  const { showEnvironment } = useShowEnvironment();
  const { environments } = props;
  const [environmentsData, setEnvironmentsData] = useState<EnvironmentRead[]>(
    [],
  );
  const current = useLocation().pathname;

  useEffect(() => {
    setEnvironmentsData(
      environments.data.filter(o => showEnvironment(o, current)),
    );
  }, [showEnvironment, environments.data, current]);

  return (
    <NavBar
      {...props}
      environments={{ ...environments, data: environmentsData }}
    />
  );
};
