import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { useInjector } from 'utils/inject';

import { CreateEnvironment } from './Components/CreateEnvironment';
import { EditEnvironment } from './Components/EditEnvironment';
import { EnvironmentTable } from './Components/EnvironmentTable';
import { environmentSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const Environment = memo(() => {
  useInjector(sliceKey, reducer, environmentSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/setup/environment/add" />}>
        <Route path="add" element={<CreateEnvironment />} />
      </Route>
      <Route element={<FilterRouter path="/setup/environment/edit/:uuid" />}>
        <Route path="edit/:uuid" element={<EditEnvironment />} />
      </Route>
      <Route element={<FilterRouter path="/setup/environment" />}>
        <Route index element={<EnvironmentTable />} />
      </Route>
    </Routes>
  );
});
