import React, { memo, useCallback } from 'react';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Card } from 'app/components/DataDisplay/Card/Card';
import { AddButton } from 'app/components/DataEntry/Button';
import { usePageContentContext } from 'components/Layout';
import { useInjector } from 'utils/inject';

import { organizationsSaga } from '../saga';
import { reducer, sliceKey } from '../slice';

import { OrganizationTable } from './OrganizationTable';

interface OrganizationsProps {}

export const OrganizationsRoot = memo((props: OrganizationsProps) => {
  useInjector(sliceKey, reducer, organizationsSaga);

  const navigate = useNavigate();
  const { contentHeight } = usePageContentContext();

  const openCreateOrgWizard = useCallback(() => {
    navigate('/organizations/new-organization');
  }, [history]);

  return (
    <Stack h={contentHeight + 'px'} spacing={0}>
      {/*<CDashboard dashboardWidgets={dashboardWidgets} />*/}
      <Flex justify="flex-end" mb={5}>
        <AddButton label="Add Organization" onClick={openCreateOrgWizard} />
      </Flex>
      <Box flex={1}>
        <Card styles={{ card: { p: 4 }, cardContent: { justify: 'center' } }}>
          <OrganizationTable />
        </Card>
      </Box>
    </Stack>
  );
});
