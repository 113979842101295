import React, { memo, useEffect } from 'react';

import { Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UUID } from 'api/types';
import { Card } from 'components/DataDisplay/Card';
import { DetailsPageHeader } from 'components/DataDisplay/Utils/DetailsPageHeader';
import { cloudAccountsSaga } from 'containers/Setup/CloudAccounts/saga';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { useInjector } from '../../../../../utils/inject';
import { actions, reducer, sliceKey } from '../slice';

import { MemberAccounts } from './MemberAccounts';

export const MemberAccountPage = memo(() => {
  useInjector(sliceKey, reducer, cloudAccountsSaga);

  const dispatch = useDispatch();
  const params = useParams<UUID>();
  const { accountDetails } = useSelector(selectCloudAccounts);

  useEffect(() => {
    if (params?.uuid) {
      dispatch(actions.loadCloudAccountDetails({ q: { uuid: params?.uuid } }));
    }
  }, [params, dispatch]);

  return (
    <Stack flex="1 1 0" spacing={5} h="full">
      <DetailsPageHeader
        label={'Cloud Account'}
        value={`${accountDetails?.data?.name ?? '-'} (${
          accountDetails?.data?.cloud_account_id ?? ''
        })`}
      />
      <Card
        contentComponent={
          <MemberAccounts managementAccountId={params?.uuid ?? ''} />
        }
      />
    </Stack>
  );
});
