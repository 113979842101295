import { TabStyles } from './types';

export const defaultStyles: TabStyles = {
  tabs: {
    size: 'sm',
    w: 'full',
    h: 'full',
    display: 'flex',
    flexDirection: 'column',
  },
  tabList: {
    borderBottom: '1px solid',
    borderColor: 'gray.100',
  },
  tab: {
    pb: 3,
    bg: 'transparent',
    borderBottom: '1px solid',
    borderColor: 'transparent',
    _hover: { color: 'primary' },
    _focus: {
      outline: 'none',
      bg: 'transparent',
    },
    _active: {
      outline: 'none',
      bg: 'transparent',
    },
    _selected: {
      color: 'primary',
      fontWeight: 'lg',
      borderColor: 'primary',
      borderBottom: '2px solid',
    },
  },
  tabPanel: {
    flex: 1,
    h: 'full',
    px: 0,
    pb: 0,
    pt: 8,
  },
};
