import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.cloudAccounts || initialState;

export const selectCloudAccounts = createSelector(
  [selectDomain],
  cloudAccountsState => cloudAccountsState,
);

export const selectCloudAccountOptions = createSelector(
  [selectDomain],
  cloudAccountsState =>
    cloudAccountsState.accounts.data.map(account => ({
      label: account.name,
      value: account.cloud_account_id,
    })),
);
