import { SearchResponse, SearchTermCategory } from '@ariksa/inventory-core';
import { Optional } from 'types/utils';

import { SecurityGraphMapType } from 'containers/Visibility/SecurityGraphNext/types';

export const getSecurityGraphType = (result: Optional<SearchResponse>) => {
  if (!result) return SecurityGraphMapType.Unknown;

  return result.category === SearchTermCategory.Permission ||
    result.category === SearchTermCategory.Permissionexcess
    ? SecurityGraphMapType.Access
    : SecurityGraphMapType.Posture;
};
