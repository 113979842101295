import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ElasticMapReduceIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <path d="M113.838,83.881h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C122.009,87.539,118.35,83.881,113.838,83.881z M105.668,135.081h-34.86v-34.86h34.86V135.081 z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M197.719,83.881h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C205.889,87.539,202.231,83.881,197.719,83.881z M189.549,135.081h-34.86v-34.86h34.86 V135.081z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M197.719,251.643h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C205.889,255.301,202.231,251.643,197.719,251.643z M189.549,302.843h-34.86v-34.86h34.86 V302.843z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M281.6,251.643h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C289.77,255.301,286.112,251.643,281.6,251.643z M273.43,302.843h-34.86v-34.86h34.86V302.843 z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M365.481,335.523h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C373.651,339.181,369.993,335.523,365.481,335.523z M357.311,386.723h-34.86v-34.86h34.86 V386.723z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M281.6,83.881h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2c4.512,0,8.17-3.658,8.17-8.17 v-51.2C289.77,87.539,286.112,83.881,281.6,83.881z M273.43,135.081h-34.86v-34.86h34.86V135.081z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M365.481,83.881h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C373.651,87.539,369.993,83.881,365.481,83.881z M357.311,135.081h-34.86v-34.86h34.86 V135.081z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M449.362,83.881h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2 c4.512,0,8.17-3.658,8.17-8.17v-51.2C457.532,87.539,453.874,83.881,449.362,83.881z M441.191,135.081h-34.86v-34.86h34.86 V135.081z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M197.719,0h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2c4.512,0,8.17-3.658,8.17-8.17 V8.17C205.889,3.658,202.231,0,197.719,0z M189.549,51.2h-34.86V16.34h34.86V51.2z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M281.6,0h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2c4.512,0,8.17-3.658,8.17-8.17V8.17 C289.77,3.658,286.112,0,281.6,0z M273.43,51.2h-34.86V16.34h34.86V51.2z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M449.362,0h-51.2c-4.512,0-8.17,3.658-8.17,8.17v51.2c0,4.512,3.658,8.17,8.17,8.17h51.2c4.512,0,8.17-3.658,8.17-8.17 V8.17C457.532,3.658,453.874,0,449.362,0z M441.191,51.2h-34.86V16.34h34.86V51.2z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M449.362,167.762H314.281c-4.512,0-8.17,3.658-8.17,8.17v43.03h-16.34v-43.03c0-4.512-3.658-8.17-8.17-8.17h-51.2 c-4.512,0-8.17,3.658-8.17,8.17v43.03h-16.34v-43.03c0-4.512-3.658-8.17-8.17-8.17h-51.2c-4.512,0-8.17,3.658-8.17,8.17v43.03 h-16.34v-43.03c0-4.512-3.658-8.17-8.17-8.17h-51.2c-4.512,0-8.17,3.658-8.17,8.17v135.081c0,4.512,3.658,8.17,8.17,8.17 s8.17-3.658,8.17-8.17v-75.711h243.472c4.512,0,8.17-3.658,8.17-8.17v-43.03h118.74v246.196H384c-4.512,0-8.17,3.658-8.17,8.17 v57.191H70.809V343.694c0-4.512-3.658-8.17-8.17-8.17s-8.17,3.658-8.17,8.17V503.83c0,4.512,3.658,8.17,8.17,8.17H384 c0.273,0,0.546-0.014,0.816-0.041c0.19-0.019,0.375-0.052,0.561-0.084c0.077-0.013,0.156-0.02,0.233-0.035 c0.221-0.045,0.436-0.102,0.65-0.163c0.041-0.012,0.083-0.02,0.124-0.032c0.217-0.066,0.427-0.145,0.636-0.228 c0.038-0.015,0.077-0.026,0.115-0.042c0.195-0.082,0.385-0.174,0.572-0.27c0.05-0.025,0.102-0.047,0.153-0.073 c0.169-0.09,0.33-0.192,0.491-0.294c0.064-0.04,0.132-0.076,0.196-0.119c0.145-0.098,0.282-0.205,0.42-0.312 c0.073-0.057,0.15-0.107,0.221-0.166c0.163-0.134,0.318-0.279,0.471-0.426c0.039-0.037,0.082-0.07,0.119-0.108l65.359-65.361 c0.188-0.188,0.368-0.387,0.537-0.594c0.044-0.053,0.081-0.11,0.123-0.164c0.122-0.156,0.243-0.313,0.353-0.477 c0.035-0.051,0.063-0.106,0.096-0.158c0.11-0.173,0.219-0.347,0.317-0.529c0.022-0.039,0.038-0.082,0.059-0.122 c0.101-0.197,0.199-0.397,0.284-0.601c0.012-0.029,0.021-0.059,0.033-0.088c0.087-0.217,0.169-0.437,0.237-0.662 c0.01-0.033,0.016-0.066,0.025-0.099c0.064-0.221,0.123-0.445,0.169-0.674c0.014-0.069,0.02-0.139,0.032-0.208 c0.034-0.194,0.068-0.387,0.087-0.585c0.027-0.27,0.041-0.542,0.041-0.816V175.932 C457.532,171.42,453.874,167.762,449.362,167.762z M105.668,218.962h-34.86v-34.86h34.86V218.962z M189.549,218.962h-34.86v-34.86 h34.86V218.962z M273.43,218.962h-34.86v-34.86h34.86V218.962z M392.17,484.106v-37.468h37.468L392.17,484.106z" />
      </g>
    </g>
  </Icon>
);
