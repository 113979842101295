import { IGroup } from '@antv/g-base/lib/interfaces';
import G6, { ModelConfig } from '@antv/g6';

import { HIDDEN_ROOT_NODE } from 'components/Visualization/Graphs/nodes/nodes_types';

export function registerHiddenRootNode() {
  G6.registerNode(
    HIDDEN_ROOT_NODE,
    {
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        return group?.addShape('rect', {
          attrs: {
            width: 45,
            height: 45,
            opacity: 1,
            radius: 8,
            r: 10,
            cursor: 'pointer',
            fill: 'transparent',
          },
          name: 'node',
        });
      },
      getAnchorPoints() {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },
    'rect',
  );
}
