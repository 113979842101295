import { ButtonProps } from '@chakra-ui/react';

import { colorMap } from '../../../../theme';

export const timeButtonStyles: ButtonProps = {
  bg: 'white',
  size: 'xs',
  _hover: {
    bg: colorMap.primary(50),
    color: 'black',
    _focus: { bg: 'primary', color: 'white' },
    _active: { bg: 'primary', color: 'white' },
  },
  _focus: { bg: 'primary', color: 'white' },
  _active: { bg: 'primary', color: 'white' },
};
