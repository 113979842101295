/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { NODE_ENV } from 'utils/env';

import { createReducer } from './reducers';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};

  // Saga middleware
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

  //Create Injectors Enhancer (for dynamically injecting reducers and sagas)
  const enhancers = [
    createInjectorsEnhancer({
      createReducer, // Function to create root reducer
      runSaga, // Saga runner
    }),
    sentryReduxEnhancer,
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(...middlewares),
    devTools: NODE_ENV !== 'production',
    enhancers: getDefaultEnhancers =>
      getDefaultEnhancers().concat(...enhancers),
  });

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // if (module.hot) {
  //   module.hot.accept('./reducers', () => {
  //     forceReducerReload(store);
  //   });
  // }

  // if (import.meta && import.meta.hot) {
  //   import.meta.hot?.accept('./reducers', () => {
  //     forceReducerReload(store);
  //   });
  // }

  return store;
}
