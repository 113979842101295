import React, { useCallback, useEffect, useState } from 'react';

import {
  CloudAccountGetResponse,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import { HStack, Box } from '@chakra-ui/react';
import { each, map } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';

import { getIcon } from 'components/Icons/Components';
import { selectOnboardedCloudAccounts } from 'containers/App/selectors';

export const useCloudAccounts = () => {
  const cloudAccounts = useSelector(selectOnboardedCloudAccounts);
  const [providerAccountMapping, setProviderAccountMapping] = useState<
    Record<string, CloudAccountGetResponse[]>
  >({});

  useEffect(() => {
    const items: Record<string, CloudAccountGetResponse[]> = {};
    each(cloudAccounts.data, o =>
      !!items?.[o.cloud_type!]
        ? items[o.cloud_type!].push(o)
        : (items[o.cloud_type!] = [o]),
    );
    setProviderAccountMapping(items);
  }, [cloudAccounts.data]);

  const getAccountOptions = useCallback(
    (cloudProvider?: CloudProviders) => {
      return map(
        filter(
          cloudAccounts.data,
          e => e.cloud_type === (cloudProvider || CloudProviders.Aws),
        ),
        o => ({
          label: (
            <HStack>
              <Box boxSize={5}>
                {getIcon(cloudProvider || CloudProviders.Aws)}
              </Box>
              <Box fontWeight={600}>{o?.name}</Box>
              <Box>({o?.cloud_account_id})</Box>
            </HStack>
          ),
          value: o.uuid,
          data: o,
        }),
      );
    },
    [cloudAccounts.data],
  );

  return {
    getAccountOptions,
    providerAccountMapping,
  };
};
