import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes, Modal, renderTime, StackedHeader } from 'components/index';

interface Props {
  isOpen: boolean;
  onClose();
  data: Record<string, any>[];
  accountName: string;
  type: 'Data' | 'Vulnerability';
}

export const OutpostDeployedInformation: FC<Props> = props => {
  const { isOpen, onClose, data, accountName, type } = props;

  const columns = [
    { header: 'Instance ID', accessor: 'instance_id' },
    { header: 'Region', accessor: 'region', align: 'left' },
    {
      header: 'Created',
      accessor: 'first_seen',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Last scan',
      accessor: 'last_seen',
      align: 'left',
      render: renderTime,
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
      styles={{ content: { zIndex: 10000 } }}
      header={
        <StackedHeader
          upper={'Ariksa Outpost for Data'}
          lower={accountName}
          type={type === 'Data' ? IconTypes.Data : IconTypes.Vulnerability}
        />
      }
      body={
        <Box h={72}>
          <Table data={data} columns={columns} />
        </Box>
      }
    />
  );
};
