import React, { useEffect, useState } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import round from 'lodash/round';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import { ExclamationTriangleIcon, LineChartIcon } from 'components/Icons';
import { ComposedChart } from 'components/Visualization';
import { customizedXAxisTick } from 'components/Visualization/Charts/utils';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import {
  renderChange,
  renderChartLegend,
} from 'containers/Dashboard/utils/utils';
import { formatDate } from 'utils/date';

export const OpenAlertsAndResolutions: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { alertsHistory } = stateOfYourCloud;
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);
  const [change, setChange] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    let data = map(alertsHistory?.data?.open_alerts, (o, date) => ({
      Date: formatDate(date, 'D-MMM'),
      'Critical / High severity':
        (o?.['critical_alerts'] ?? 0) + (o?.['high_alerts'] ?? 0),
      'Alerts resolved':
        (alertsHistory.data.resolved_alerts?.[date]?.critical_alerts ?? 0) +
        (alertsHistory.data.resolved_alerts?.[date]?.high_alerts ?? 0),
    }));
    const first =
      (data?.[0]?.['Critical / High severity'] ?? 0) +
      (data?.[0]?.['Alerts resolved'] ?? 0);
    const last =
      (data?.[data?.length - 1]?.['Critical / High severity'] ?? 0) +
      (data?.[data?.length - 1]?.['Alerts resolved'] ?? 0);
    setChange(
      first === 0 && last > 0
        ? 100
        : first === 0
        ? 0
        : round(((last - first) / first) * 100, 0),
    );
    setChartData(data);
  }, [alertsHistory.data]);

  return (
    <DashboardOverviewCard
      label="Open and resolved alerts – last 30 days"
      iconBgColor="critical"
      icon={<LineChartIcon />}
      content={
        <Stack w="full" h="full">
          <HStack w="full">
            <HStack w="70%" pl={6} spacing={5}>
              {renderChartLegend({
                label: 'Critical and high severity',
                color: 'critical',
                onClick: () =>
                  navigate(
                    '/findings/alerts?alertStatus=Open&days=30&severity=CRITICAL,HIGH',
                  ),
              })}
              {renderChartLegend({
                label: 'Alerts resolved',
                color: 'green.300',
                onClick: () =>
                  navigate('/findings/alerts?alertStatus=Resolved&days=30'),
              })}
            </HStack>
            <Box w="30%" pr={8}>
              {renderChange(change)}
            </Box>
          </HStack>
          <Box w="full" h="full" py={0} pl={6}>
            <ComposedChart
              data={chartData}
              xField="Date"
              legend={false}
              line={{
                yField: 'Critical / High severity',
                orientation: 'left',
              }}
              bar={{
                yField: 'Alerts resolved',
                orientation: 'left',
              }}
              isLoading={alertsHistory.isLoading}
              styles={{
                composedChart: {
                  onClick: e => {
                    navigate(
                      '/findings/alerts?alertStatus=Open&days=30&severity=CRITICAL,HIGH',
                    );
                  },
                  margin: { bottom: 0, left: 5, top: 20, right: -40 },
                },
                line: {
                  dot: false,
                  stroke: customTheme.colors.critical,
                },
                bar: {
                  stroke: customTheme.colors.green['300'],
                  onClick: (data, index, event) => {
                    event.stopPropagation();
                    navigate('/findings/alerts?alertStatus=Resolved&days=30');
                  },
                },
                /*firstYAxis: {
                  tick: props => customizedYAxisTickWithFormatNumber(props),
                },*/
                xAxis: {
                  axisLine: false,
                  tickLine: false,
                  interval: 'preserveStartEnd',
                  ticks: [
                    chartData?.[0]?.Date,
                    chartData?.[chartData?.length - 1]?.Date,
                  ],
                  tick: props =>
                    customizedXAxisTick({
                      ...props,
                      style: { fontSize: 14, y: 6 },
                    }),
                },
              }}
            />
          </Box>
        </Stack>
      }
      tooltip={{
        header: 'Findings, issues and alerts…',
        icon: <ExclamationTriangleIcon />,
        tooltip: (
          <Stack>
            <Box>
              Ariksa delivers prioritized findings based on multi-factor
              analyses to reduce noise and increase your productivity.
            </Box>
            <Box>
              Unique issues are grouped into findings. Each issue indicates an
              area of investigation for a specific resource. Alerts are issues
              that you need your attention for resolution.
            </Box>
          </Stack>
        ),
        footerText: 'Ok',
      }}
    />
  );
};
