import React, { FC } from 'react';

import { Grid, Stack } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CertificateType,
  CustomerOverride,
  CustomerOverrideExpiry,
  Thumbprint,
  Validity,
} from '../../../Components/MetadataField/MetaGridFields';

export const RdsCertificateMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {CertificateType(resource)}
        {Thumbprint(resource)}
        {Validity(resource)}
        {CustomerOverride(resource)}
        {CustomerOverrideExpiry(resource)}
      </Grid>
    </Stack>
  );
};
