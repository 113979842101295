import React, { FC } from 'react';

import { ScanTypes } from '@ariksa/scan-analysis/api';
import { Box, Stack } from '@chakra-ui/react';
import { useInstanceScanById } from 'services/ScanAnalysis/useScanAnalysisItemService';

import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { ResourceInfoTable } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceInfoTable';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const VMCredentials: FC = () => {
  const { resourceId } = useActiveResourceContext();
  const { instanceScanById } = useInstanceScanById(
    resourceId,
    ScanTypes.Secrets,
  );

  const columns: TableColumnProps[] = [
    { header: 'TYPE', accessor: 'title' },
    { header: 'PATH', accessor: 'target', align: 'left' },
    { header: 'CREATED AT', render: () => '-' },
    { header: 'LAST MODIFIED', render: () => '-' },
  ];

  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={5}>
      <Box flex={1} minH={'300px'}>
        <ResourceInfoTable
          header={'PRIVATE CREDENTIALS'}
          columns={columns}
          data={instanceScanById.data?.secrets ?? []}
          totalCount={instanceScanById.data?.secrets?.length}
          isLoading={instanceScanById.isLoading}
        />
      </Box>
    </Stack>
  );
};
