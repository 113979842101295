import { createContext, useContext } from 'react';

import Keycloak from 'keycloak-js';
import { identity } from 'lodash';

interface KeycloakInstanceProps {
  keycloak?: Keycloak.KeycloakInstance;
  logOut();
  resetPassword();
  forceRefreshToken();
}

export const KeycloakInstanceContext = createContext<KeycloakInstanceProps>({
  keycloak: undefined,
  logOut: identity,
  resetPassword: identity,
  forceRefreshToken: identity,
});

export const useKeycloakContext = () => useContext(KeycloakInstanceContext);
