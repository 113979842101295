import React, { useMemo } from 'react';

import { Box } from '@chakra-ui/react';
import { cloneDeep, isEmpty, merge } from 'lodash';
import {
  Legend,
  RadarChart,
  Tooltip,
  PolarAngleAxis,
  PolarRadiusAxis,
  PolarGrid,
} from 'recharts';
import { ResponsiveContainer } from 'recharts';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { defaultStyles } from './styles';
import { IRadarChart } from './types';

export const CustomRadarChart: React.FC<IRadarChart> = ({
  data,
  angleAxis,
  legend = true,
  tooltip = true,
  styles,
  overwriteDefaultStyles = false,
  customTooltipContent,
  isLoading = false,
  radar,
  domain,
}) => {
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  // @ts-ignore
  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : isEmpty(data) ? (
        <NoDataAvailable />
      ) : (
        <ResponsiveContainer>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
            <PolarGrid {...baseStyles?.grid} />
            {/* @ts-ignore*/}
            <PolarAngleAxis dataKey={angleAxis} {...baseStyles?.angleAxis} />
            {/* @ts-ignore*/}
            <PolarRadiusAxis
              angle={90}
              domain={domain}
              {...baseStyles?.radiusAxis}
            />

            {radar}
            {tooltip && (
              <Tooltip
                {...baseStyles?.tooltip}
                content={customTooltipContent}
              />
            )}
            {legend && <Legend {...(baseStyles?.legend as any)} />}
          </RadarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};
