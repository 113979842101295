import React, { useState, useEffect, FC, useCallback, useMemo } from 'react';

import { Clients } from '@ariksa/notification';
import { Box, Stack, Button, Text } from '@chakra-ui/react';
import { map, isEmpty, filter, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions as integrationActions } from 'containers/Setup/Integrations/slice';

import { selectAlertWorkflow } from '../../../../selectors';
import {
  deleteIconStyles,
  tagStyles,
  valueContainerStyles,
} from '../../../../styles';

import { ClientComponent } from './ClientComponent';

interface Props {}

export const AlertActions: FC<Props> = props => {
  const dispatch = useDispatch();
  const { clients } = useSelector(selectIntegrations);
  const { formData, alertConditions } = useSelector(selectAlertWorkflow);
  const { alert_conditions } = formData;
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any>[]>(
    [],
  );

  useEffect(() => {
    dispatch(integrationActions.loadClients({}));
  }, [dispatch]);

  const isDisabled = useCallback(client_name => isEmpty(clients[client_name]), [
    clients,
  ]);

  const getActionOptions = useMemo(
    () => [
      {
        label: 'Send notification to Slack',
        component: (
          <ClientComponent
            label="Send notification to Slack"
            clientName={Clients.Slack}
          />
        ),
        value: Clients.Slack,
        isDisabled: isDisabled(Clients.Slack),
      },
      {
        label: 'Send notification to Microsoft Teams',
        component: (
          <ClientComponent
            label="Send notification to Microsoft Teams"
            clientName={Clients.Teams}
          />
        ),
        value: Clients.Teams,
        isDisabled: isDisabled(Clients.Teams),
      },
      {
        label: 'Send notification to Email Group',
        component: (
          <ClientComponent
            label="Send notification to Email Group"
            clientName={Clients.Email}
          />
        ),
        value: Clients.Email,
        isDisabled: isDisabled(Clients.Email),
      },
      {
        label: 'Send notification to SMS',
        component: (
          <ClientComponent
            label="Send notification to SMS"
            clientName={Clients.Sns}
          />
        ),
        value: Clients.Sns,
        isDisabled: isDisabled(Clients.Sns),
      },
      {
        label: 'Create issue in Atlassian JIRA',
        component: (
          <ClientComponent
            label="Create issue in Atlassian JIRA"
            clientName={Clients.Jira}
          />
        ),
        value: Clients.Jira,
        isDisabled: isDisabled(Clients.Jira),
      },
      {
        label: 'Create issue in ServiceNow',
        component: (
          <ClientComponent
            label="Create issue in ServiceNow"
            clientName={Clients.Servicenow}
          />
        ),
        value: Clients.Servicenow,
        isDisabled: isDisabled(Clients.Servicenow),
      },
      {
        label: 'Send events to SIEM',
        component: (
          <ClientComponent
            label="Send events to SIEM"
            clientName={Clients.Elasticsearch}
          />
        ),
        value: Clients.Elasticsearch,
        isDisabled: isDisabled(Clients.Elasticsearch),
      },
    ],
    [isDisabled],
  );

  useEffect(() => {
    setSelectedOptions(
      filter(getActionOptions, o =>
        some(formData.clients, { client_name: o.value }),
      ),
    );
  }, [formData.clients, getActionOptions]);

  return (
    <Stack>
      <Box>
        <Tag label="THEN" styles={tagStyles} />
      </Box>

      <Stack w="full">
        <Box mb={2} flex={1} w="inherit">
          <Select
            name="tempName2"
            options={!isEmpty(alertConditions) ? getActionOptions : []}
            value={selectedOptions}
            isMulti
            showTotalSelected={selectedOptions.length > 0}
            onChange={setSelectedOptions}
            placeholder="Add an action..."
          />
        </Box>
        {map(selectedOptions, each => (
          <Stack {...valueContainerStyles} key={each?.value + '-alert-action'}>
            <Box w="full">{each?.component}</Box>
            <Button
              {...deleteIconStyles}
              onClick={() =>
                dispatch(
                  actions.updateAlertWorkflowActions({
                    client_name: each.value,
                    client_list: [],
                  }),
                )
              }
            >
              <TrashIcon />
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
