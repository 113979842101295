import {
  CloudAccountApiChangeDiscoverInventoryIntervalRequest,
  CloudAccountApiGetCloudAccountsRequest,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { UserServiceGetRequest, UserServiceGetResponse } from 'api/auth/api.pb';
import { SessionId } from 'api/auth/types';
import { onApiCall, onApiCallError, onApiCallSuccess } from 'api/call_status';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the SetupUserProfile container
export const initialState: ContainerState = {
  roles: ['DevOps Admin', 'Compliance Admin', 'Cloud Admin'],
  allRoles: [
    'SaaS Admin',
    'Cloud Admin',
    'SaaS Admin -Read Only',
    'Cloud Admin -Read Only',
    'Org Admin',
    'AdminInfrastructure Admin',
    'Cloud Services Admin',
    'Monitoring Admin',
    'Compute Admin',
    'Data Admin',
    'Database Admin',
    'Network Admin',
    'DevOps Admin',
    'Compliance Admin',
  ],
  memberships: [],
  allMemberships: [],
  apiToken: 'AHYKSTYD672GHSYT267',
  classification: 'Admin/User',
  permissions: {
    loading: false,
    error: null,
    data: {
      id: '',
      email: '',
      username: '',
      first_name: '',
      last_name: '',
      roles: [],
      user_groups: [],
      account_ids: [],
      auto_add_accounts: false,
    },
  },
  resetPassword: {
    error: null,
    loading: false,
  },
  removeSession: {
    error: null,
    loading: false,
  },
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    getUserById(state, action: PayloadAction<UserServiceGetRequest>) {
      state.permissions.data.id = action.payload.id;
      onApiCall(state.permissions);
    },
    getUserByIdSuccess(state, action: PayloadAction<UserServiceGetResponse>) {
      state.permissions.data = action.payload.user!;
      onApiCallSuccess(state.permissions);
    },
    getUserByIdError(state, action: PayloadAction<any>) {
      onApiCallError(state.permissions, action.payload);
    },

    removeSession(state, action: PayloadAction<SessionId>) {
      onApiCall(state.removeSession);
    },
    removeSessionSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.removeSession);
    },
    removeSessionError(state, action: PayloadAction<any>) {
      onApiCallError(state.removeSession, action.payload);
    },
    updatePassword(state, action: PayloadAction<any>) {
      onApiCall(state.resetPassword);
    },
    updatePasswordSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.resetPassword);
    },
    updatePasswordError(state, action: PayloadAction<any>) {
      onApiCallError(state.resetPassword, action.payload);
    },
  },
});

export const { actions, reducer, name: sliceKey } = userProfileSlice;
