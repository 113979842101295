import { join, map, startCase, toLower, toUpper, words } from 'lodash';

export const formatTitle = value => {
  const capital = new Set([
    'id',
    'http',
    'cpu',
    'ip',
    'dns',
    'mac',
    'sns',
    'sqs',
    'rds',
    'acm',
    'kms',
    'iam',
    'es',
    'eks',
    'ecs',
    'ssm',
    'sts',
    'waf',
    'arn',
  ]);
  const num = new Set(['ec2', 's3', 'wafv2']);

  if (num.has(toLower(value))) return toUpper(value);

  let values = words(startCase(value));

  let newString: string[] = [];
  map(values, each =>
    capital.has(toLower(each))
      ? newString.push(toUpper(each))
      : newString.push(each),
  );
  return join(newString, ' ');
};
