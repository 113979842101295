import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { cancel, fork, take } from 'redux-saga/effects';

import { QueryAction, QueryGenerator, QueryParams } from '../types';

import { isStatusUnknown, QueryContext } from './QueryContext';

export function takeLatestAction<P, Q = any>(
  sliceAction: ActionCreatorWithPayload<QueryParams<P, Q>>,
  generator: QueryGenerator<P, Q>,
) {
  return fork(function* () {
    let lastTask;
    while (true) {
      const action: unknown = yield take(sliceAction.type);
      const { payload = {} as QueryParams<P, Q> } = action as QueryAction<P, Q>;

      // initially when the action is dispatched, the status is unknown
      const unknownStatus = isStatusUnknown(payload?.status);
      if (lastTask && unknownStatus) {
        yield cancel(lastTask); // cancel is no-op if the task has already terminated
      }

      // first time the action is dispatched the status will be unknown
      // so a new context object will be created with the query state
      // if the context object has valid status that means this context is carrying info about the api call
      if (unknownStatus) {
        // console.log('---<>---');
        const ctx = new QueryContext(sliceAction, payload) as any;
        lastTask = yield fork(generator, ctx);
      } else {
        // console.log('---<><>---');
      }
    }
  });
}
