import React, { FC, useEffect } from 'react';

import { Categories } from '@ariksa/inventory-core/api';
import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { Card, FormHeading, WithResourceIcon } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { actions } from 'containers/Setup/DataConfiguration/slice';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { DataClassifierForm } from './DataClassifierForm';

export const DataClassifierWizard: FC = () => {
  const { selectedTab, actionType } = useDataConfigurationContext();
  const dispatch = useDispatch();

  useEffect(() => {
    selectedTab === 'types' && dispatch(actions.getSensitivityInfo({}));
  }, [selectedTab, dispatch]);

  useEffect(() => {
    selectedTab === 'types' &&
      dispatch(
        sharedStateActions.getLabels({ q: { category: Categories.Data } }),
      );
  }, [dispatch, selectedTab]);

  return (
    <Card
      headerComponent={
        <Box pl={14}>
          <WithResourceIcon
            resourceType={
              selectedTab === 'types'
                ? IconTypes.DataClassification
                : IconTypes.Disable
            }
            bgColor="green"
            iconSize="small"
          >
            <FormHeading>
              {actionType +
                ' Data ' +
                (selectedTab === 'types' ? 'Type' : 'Exclusion')}
            </FormHeading>
          </WithResourceIcon>
        </Box>
      }
      styles={{ card: { pl: 32 } }}
      contentComponent={<DataClassifierForm actionType={actionType} />}
    />
  );
};
