import React, { useEffect, useState } from 'react';

import { values } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { PackagesIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Entities: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const { entitiesHistory, cloudSummary } = stateOfYourCloud;
  const [firstValue, setFirstValue] = useState(0);
  const [lastValue, setLastValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const data = values(entitiesHistory.data);
    setFirstValue(data?.[0]);
    setLastValue(data?.[data?.length - 1]);
  }, [entitiesHistory.data]);

  return (
    <SummaryCard
      label="Entities"
      icon={<PackagesIcon />}
      change={{
        isLoading: entitiesHistory.isLoading,
        first: firstValue,
        last: lastValue,
      }}
      cloudProviders={cloudSummary.data?.cloud_providers}
      count={(cloudSummary.data.items?.['entities'] as number) ?? 0}
      isLoading={cloudSummary.isLoading}
      onClick={() => navigate('/inventory/summary')}
      labelTooltip={formatTooltip({
        header: 'All entities',
        content: 'All resources and identities being secured',
        width: 48,
      })}
    />
  );
};
