import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VpnIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M255.2,0c87,55,165.3,81.1,232.7,75c11.8,238-76,378.5-231.8,437C105.7,457.2,16.8,322.6,23.4,71.3
	C102.5,75.4,180.1,58.4,255.2,0L255.2,0z M107,186.7h29.6l20.6,66l20.3-66h28.8l-34,91.6h-30.7L107,186.7L107,186.7z M213.3,186.7
	h47c10.2,0,17.9,2.4,23,7.3c5.1,4.9,7.7,11.8,7.7,20.8c0,9.2-2.8,16.5-8.3,21.7c-5.6,5.2-14,7.8-25.5,7.8h-15.5v34h-28.4V186.7
	L213.3,186.7z M241.7,225.8h6.9c5.5,0,9.3-1,11.5-2.8c2.2-1.9,3.3-4.3,3.3-7.3c0-2.9-1-5.3-2.9-7.3c-1.9-2-5.5-3-10.8-3h-8.1
	L241.7,225.8L241.7,225.8z M303.9,186.7h26.5l34.5,50.7v-50.7h26.7v91.6h-26.7L330.5,228v50.3h-26.6V186.7L303.9,186.7z M255.2,29.3
	c77,48.7,146.4,71.7,206,66.4c10.5,210.6-67.4,335-205.2,387c-133-48.5-211.8-167.6-206-390c70,3.7,138.7-11.5,205.2-63.1
	L255.2,29.3L255.2,29.3z"
    />
  </Icon>
);
