import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const useGetDescription = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: r } = resourceDetails;
  const { data: insight } = resourceInsight;

  const getDescription = () =>
    insight?.description ||
    r?.config?.Description ||
    r?.config?.description ||
    r?.config?.KeyMetadata?.Description ||
    r?.config?.DBClusterParameterGroupDetails?.Description ||
    r?.config?.profile?.description ||
    r?.config?.comment ||
    'None';

  return {
    getDescription,
  };
};
