import {
  Charts,
  ChartsApiAddChartRequest,
  ChartsApiEditDashboardLayoutRequest,
  ChartsApiGetChartsRequest,
} from '@ariksa/reporting';
import { ChartsApiEditChartRequest } from '@ariksa/reporting/dist/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ReportingService } from 'api/services';

import { actions } from './slice';

export function* reportsDashboardSaga() {
  yield takeLatestAction(actions.getReportsInfo, doGetReportsInfo);
  yield takeLatestAction(actions.updateLayout, doUpdateLayout);
  yield takeLatestAction(actions.updateChart, doUpdateChart);
  yield takeLatestAction(actions.addChart, doAddChart);
}

/*get reports*/
function* doGetReportsInfo(
  ctx: QueryContext<Charts[], ChartsApiGetChartsRequest>,
) {
  yield call(ctx.fetch, () => ReportingService.Charts.getCharts(ctx.params), {
    errorMsg: 'Failed to get chart list!',
    //cacheKey: 'report-info',
  });
}

/*update reports dashboard layout*/
function* doUpdateLayout(
  ctx: QueryContext<any, ChartsApiEditDashboardLayoutRequest>,
) {
  yield call(
    ctx.fetch,
    () => ReportingService.Charts.editDashboardLayout(ctx.params),
    { errorMsg: 'Failed to edit dashboard!' },
  );
}

/*update chart*/
function* doUpdateChart(ctx: QueryContext<Charts, ChartsApiEditChartRequest>) {
  yield call(ctx.fetch, () => ReportingService.Charts.editChart(ctx.params), {
    errorMsg: 'Failed to update chart!',
  });
}

/*add chart*/
function* doAddChart(ctx: QueryContext<any, ChartsApiAddChartRequest>) {
  yield call(ctx.fetch, () => ReportingService.Charts.addChart(ctx.params), {
    errorMsg: 'Failed to add chart!',
  });
}
