import React from 'react';

import { Grid, GridItem, Stack } from '@chakra-ui/react';

import { VirtualMachineIcon } from 'components/Icons';
import { ComputeEntities } from 'containers/Dashboard/CloudPostureOverview/Components/ComputeEntities';
import { Credentials } from 'containers/Dashboard/CloudPostureOverview/Components/Credentials';
import { DataSources } from 'containers/Dashboard/CloudPostureOverview/Components/DataSources';
import { Domains } from 'containers/Dashboard/CloudPostureOverview/Components/Domains';
import { InfrastructureFindingsToPrioritize } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureFindingsToPrioritize';
import { InfrastructurePostureFindings } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureFindings';
import { InfrastructurePostureSummary } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructurePostureSummary';
import { InfrastructureThatLacksEncryption } from 'containers/Dashboard/CloudPostureOverview/Components/InfrastructureThatLacksEncryption';
import { InsightsForCloudNetworking } from 'containers/Dashboard/CloudPostureOverview/Components/InsightsForCloudNetworking';
import { NetworkEntities } from 'containers/Dashboard/CloudPostureOverview/Components/NetworkEntities';
import { TopPubliclyAccessibleMachinesAndDataSources } from 'containers/Dashboard/CloudPostureOverview/Components/TopPubliclyAccessibleMachinesAndDataSources';
import { WellKnownPorts } from 'containers/Dashboard/CloudPostureOverview/Components/WellKnownPorts';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

export const InfrastructurePosture: React.FC = () => {
  return (
    <DashboardOverviewWrapperCard
      label="INFRASTRUCTURE POSTURE"
      content={
        <Stack spacing={OverviewCardSpacing} w="full" h="full">
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <InfrastructurePostureSummary />
                <ComputeEntities />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <InfrastructurePostureFindings />
            </GridItem>
            <GridItem colSpan={2}>
              <InfrastructureFindingsToPrioritize />
            </GridItem>
          </Grid>
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <DataSources />
                <NetworkEntities />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <TopPubliclyAccessibleMachinesAndDataSources />
            </GridItem>
            <GridItem colSpan={2}>
              <InfrastructureThatLacksEncryption />
            </GridItem>
          </Grid>
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <Domains />
                <Credentials />
              </Stack>
            </GridItem>
            <GridItem colSpan={2}>
              <WellKnownPorts />
            </GridItem>
            <GridItem colSpan={2}>
              <InsightsForCloudNetworking />
            </GridItem>
          </Grid>
        </Stack>
      }
      icon={<VirtualMachineIcon />}
    />
  );
};
