import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.setupUsers || initialState;

export const selectSetupUsers = createSelector(
  [selectDomain],
  setupUsersState => setupUsersState,
);

export const selectUsersOptions = createSelector([selectDomain], appState =>
  appState.users.payload.map(a => {
    return {
      label: `${a.username}`,
      value: a.id,
      data: a,
    };
  }),
);
