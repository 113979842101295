/**
 *
 * Visibility
 *
 */
import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { Data } from 'containers/Visibility/Data/Loadable';
import { JitRequests } from 'containers/Visibility/JitRequests/Loadable';
import { SecurityGraphNext } from 'containers/Visibility/SecurityGraphNext';
import { actions } from 'containers/Visibility/slice';
import { Vulnerabilities } from 'containers/Visibility/Vulnerabilities';
import { useSearchParams } from 'hooks/useSearchParams';
import { useInjector } from 'utils/inject';

import { visibilitySaga } from './saga';
import { reducer, sliceKey } from './slice';

export const Visibility = () => {
  useInjector(sliceKey, reducer, visibilitySaga);

  const dispatch = useDispatch();

  const { map = false } = useSearchParams();
  useEffect(() => {
    if (map) {
      dispatch(actions.openMap());
    }
  }, [dispatch, map]);

  return (
    <Routes>
      <Route element={<FilterRouter path="/visibility/security-graph" />}>
        <Route path="security-graph" element={<SecurityGraphNext />} />
      </Route>
      <Route element={<FilterRouter path="/visibility/data" />}>
        <Route path="data" element={<Data />} />
      </Route>
      <Route element={<FilterRouter path="/visibility/vulnerability" />}>
        <Route path="vulnerability" element={<Vulnerabilities />} />
      </Route>
      <Route element={<FilterRouter path="/visibility/jit-requests" />}>
        <Route path="jit-requests" element={<JitRequests />} />
      </Route>
    </Routes>
  );
};
