import { colorMap } from '../../../../../theme';

export const optionsButtonGroupStyles = {
  root: {
    display: 'inline-flex',
    borderRadius: 4,
    border: '1px solid',
    borderColor: 'gray.100',
    bg: '#fff',
  },
  option: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    px: 1.5,
    fontSize: 'xs',
    py: '0.3em',
    _hover: {
      bg: colorMap.primary(50),
    },
    _active: {
      bg: colorMap.primary(50),
      // color: '#fff',
    },
    borderRight: '1px solid',
    borderColor: 'gray.100',
    _last: {
      borderRight: 'none',
    },
  },
};
