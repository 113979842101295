import React, { FC, useEffect, useState } from 'react';

import { Category } from '@ariksa/data-scanning';
import { Box, Stack, HStack, Input, Divider } from '@chakra-ui/react';
import { find, includes, map } from 'lodash';
import filter from 'lodash/filter';
import { useSelector } from 'react-redux';

import { FormFieldBox } from 'components/DataDisplay';
import { Select, textFieldStyles } from 'components/DataEntry';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';
import { selectSharedState } from 'containers/SharedState/selectors';
import { toTitleCase } from 'utils/string';

import { dataClassificationTypeOptions } from './utils';

interface Props {
  pattern: string;
  setPattern(value: string);
  isEdit: boolean;
  category: Category;
  type: Record<string, any>;
  setType(val: Record<string, any>);
  selectedLabels: Record<string, any>[];
  setLabels(val: Record<string, any>[]);
}

export const DataClassifierCondition: FC<Props> = props => {
  const {
    pattern,
    setPattern,
    isEdit,
    type,
    setType,
    selectedLabels,
    setLabels,
    category,
  } = props;
  const [labelOptions, setLabelOptions] = useState<Record<string, any>[]>([]);
  const {
    currentRecord: classifier,
    selectedTab,
  } = useDataConfigurationContext();
  const { labels } = useSelector(selectSharedState);

  //set label options
  useEffect(() => {
    setLabelOptions(
      map(
        filter(labels.data, l => l?.type === 'Label'),
        o => ({ label: o.key, value: o.key }),
      ),
    );
  }, [labels.data]);

  //set type
  useEffect(() => {
    setType(
      selectedTab === 'types'
        ? dataClassificationTypeOptions[1]
        : dataClassificationTypeOptions[0],
    );
  }, [setType, selectedTab]);

  //set type in edit mode
  useEffect(() => {
    isEdit &&
      setType(
        find(
          dataClassificationTypeOptions,
          o => o?.value === classifier.type,
        ) ?? {},
      );
  }, [isEdit, setType, classifier.type]);

  //set labels
  useEffect(() => {
    isEdit &&
      setLabels(
        filter(labelOptions, o => includes(classifier?.labels, o?.value)) ?? [],
      );
  }, [classifier?.labels, labelOptions, setLabels, isEdit]);

  return (
    <Stack spacing={0}>
      <HStack w="full">
        <Box w={10} color="primary">
          IF
        </Box>
        <Box w={48}>
          <Select
            options={dataClassificationTypeOptions}
            value={type}
            onChange={value => {
              setType(value);
            }}
            isDisabled //={isEdit || selectedTab === 'types'}
          />
        </Box>
        <Box>contains the pattern</Box>
        <Box flex={1}>
          <Input
            {...textFieldStyles.input}
            placeholder="Pattern-1"
            value={pattern}
            onChange={e => setPattern(e.target.value)}
            isDisabled={isEdit}
          />
        </Box>
      </HStack>
      <Box pl={1}>
        <Divider h={12} orientation="vertical" borderColor="primary"></Divider>
      </Box>
      <HStack w="full">
        <Box w={10} color="primary">
          THEN
        </Box>
        <Box w={48}>
          <FormFieldBox isDisabled={isEdit}>
            {toTitleCase(category)}{' '}
          </FormFieldBox>
        </Box>
        <Box>for data classification</Box>
        {category === Category.Include && (
          <>
            <Box color="primary">AND</Box>
            <Box>assign label(s)</Box>
            <Box flex={1}>
              <Select
                options={labelOptions}
                value={selectedLabels}
                isMulti
                showTotalSelected
                onChange={setLabels}
              />
            </Box>
          </>
        )}
      </HStack>
    </Stack>
  );
};
