import { createContext, useContext } from 'react';

interface AlertsContextProps {
  refresh: boolean;
  toggleRefresh: () => void;

  individualAlertDrawerTabKey: string;
  setIndividualAlertDrawerTabKey: (value: string) => void;
}

export const AlertsContext = createContext<AlertsContextProps>({
  refresh: false,
  toggleRefresh: () => {},

  individualAlertDrawerTabKey: '',
  setIndividualAlertDrawerTabKey: () => {},
});

export const AlertsContextProvider = AlertsContext.Provider;

export const useAlertsContext = () => useContext(AlertsContext);
