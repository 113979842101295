import React, { FC } from 'react';

import { useDisclosure, Box, HStack } from '@chakra-ui/react';
import jsonBeautify from 'json-beautify';
import { isArray } from 'lodash';
import map from 'lodash/map';

import { getIcon, SyntaxHighlight } from 'components/DataDisplay';
import { IconTypes } from 'components/Icons';
import { SideNav } from 'components/Navigation';
import { Modal } from 'components/Overlay';
import { MetaGridField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridField';

interface Props {
  label: string;
  value: any;
  isString?: boolean;
  name?: any;
}

export const PolicyDocument: FC<Props> = props => {
  const { isString, value, label, name = '' } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  // @ts-ignore
  const json = val => jsonBeautify(val || {}, null, 2, 10);

  const policies = () => {
    if (isArray(value)) {
      return (
        <SideNav
          menuItems={map(value, (o, index) => ({
            title: index,
            key: index,
            component: (
              <SyntaxHighlight language={'json'}>
                {o}
                {/*{json(JSON.parse(o)) || ''}*/}
              </SyntaxHighlight>
            ),
          }))}
          showContentHeader={false}
        />
      );
    }
    return (
      <SyntaxHighlight language={'json'}>
        {json(isString ? JSON.parse(value) : value) || ''}
      </SyntaxHighlight>
    );
  };

  return (
    <Box>
      <MetaGridField
        label={
          <HStack>
            <Box>{label}</Box>
            <Box w={4} color="primary" cursor="pointer" onClick={onOpen}>
              {getIcon(IconTypes.ViewAll)}
            </Box>
          </HStack>
        }
        value={name}
        custom
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header="Policy Document"
        styles={{ modal: { size: '4xl' } }}
        body={
          <Box h="400px" overflow="auto">
            {!!value ? policies() : '-'}
          </Box>
        }
      />
    </Box>
  );
};
