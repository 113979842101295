import React from 'react';

import { Box, Image, Stack, Center } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { H2 } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { registerLayout } from 'components/Visualization/ColumnarGraph/registerLayout';

import emptyGraph from './security-graph-dummy.svg';

registerLayout();

interface Props {
  isLoading: boolean;
}

export const EmptySecurityGraph = (props: Props) => {
  return (
    <WithSpinner loadStatus={{ loading: props.isLoading }}>
      <Stack onContextMenu={e => e.preventDefault()} w="full" h="full">
        <Center my="auto" pb={10}>
          <Stack spacing={5}>
            <Stack spacing={1}>
              <Center pb={6}>
                <H2 color={customTheme.colors.gray['250']}>
                  Ariksa Security Graph
                </H2>
              </Center>
              <Center color={customTheme.colors.gray['200']}>
                Security graph helps you visualize information for identity
                authorization and
              </Center>
              <Center color={customTheme.colors.gray['200']}>
                access management, infrastructure posture management, data
                security and
              </Center>
              <Center color={customTheme.colors.gray['200']}>
                access management and cloud governance
              </Center>
            </Stack>
            <Box pt={4}>
              <Center color="primary">
                ‘Search’ using the box above OR ‘Get Started’ with common
                scenarios on the left
              </Center>
            </Box>
            <Box>
              <Center>
                <Image w="700px" src={emptyGraph} alt="Data Security" />
              </Center>
            </Box>
          </Stack>
        </Center>
      </Stack>
    </WithSpinner>
  );
};
