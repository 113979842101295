import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { WithResourceIcon } from 'components/DataDisplay';
import { FormAction } from 'components/DataEntry/Form';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { ReportingChartForm } from 'containers/Reports/ReportsDashboard/Components/ReportingChartForm';

interface Props {
  currentRecord: Record<string, any>;
  setCurrentRecord: (value: Record<string, any>) => void;
  isOpen: boolean;
  onClose: () => void;
  actionType: FormAction;
}

export const ReportChartWizard: FC<Props> = props => {
  const {
    currentRecord,
    isOpen,
    onClose,
    actionType,
    setCurrentRecord,
  } = props;
  const ref = useRef(document.querySelector('.portal-container'));

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <WithResourceIcon
          resourceType={IconTypes.BarChart}
          bgColor="primary"
          iconSize="medium"
        >
          <Box>{actionType} Chart</Box>
        </WithResourceIcon>
      }
      styles={{
        drawer: { size: 'lg', portalProps: { containerRef: ref as any } },
      }}
      body={
        <ReportingChartForm
          currentRecord={currentRecord}
          setCurrentRecord={setCurrentRecord}
          onClose={onClose}
          actionType={actionType}
        />
      }
    />
  );
};
