import React from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  AccountIcon,
  CloudIcon,
  CloudServiceIcon,
  ObjectStorageIcon,
  RdsIcon,
} from 'components/Icons';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const DataFootprint: React.FC = () => {
  const { summaryOfAllDataSources } = useSelector(selectDataDashboard);
  const { data } = summaryOfAllDataSources;
  const providers = [
    {
      label: 'Clouds',
      count: data.providers?.length ?? 0,
      bg: '#ded9f7',
      icon: <CloudIcon />,
    },
    {
      label: 'Accounts',
      count: data?.accounts ?? 0,
      bg: '#f2f2f2',
      icon: <AccountIcon />,
    },
    {
      label: 'SaaS',
      count: data?.resource_type_counts?.SaaS ?? 0,
      bg: '#d2edfd',
      icon: <CloudServiceIcon />,
    },
  ];
  const resources = [
    {
      label: 'Databases',
      count: data?.resource_type_counts?.Databases ?? 0,
      bg: '#d2f7e9',
      icon: <RdsIcon />,
    },
    {
      label: 'Buckets',
      count: data?.resource_type_counts?.Buckets ?? 0,
      bg: '#fcf1d3',
      icon: <ObjectStorageIcon />,
    },
    /*{
      label: 'Disks',
      count: data?.resource_type_counts?.Others ?? 0,
      bg: '#d3e0fc',
      icon: <BlockStorageIcon />,
    },*/
    {
      label: 'SaaS',
      count: data?.resource_type_counts?.SaaS ?? 0,
      bg: '#d2edfd',
      icon: <CloudServiceIcon />,
    },
  ];

  const renderTag = (label, icon, bg, count) => (
    <Tag
      styles={{
        tag: { bg: bg },
        label: { color: 'black', fontSize: 'sm' },
        leftIcon: { color: 'primary' },
      }}
      label={
        <HStack spacing={1}>
          <Box>{label}</Box>
          <Box fontWeight={600}>{formatNumber(count)}</Box>
        </HStack>
      }
      leftIcon={icon}
    />
  );

  return (
    <HStack w="full" h="full" px={3} py={5} spacing={4}>
      <Stack w={16} spacing={4}>
        <Box color={customTheme.colors.gray['300']} w="full" textAlign="center">
          Data sources
        </Box>
        <Box fontSize="lg" textAlign="center">
          {data?.resources ?? 0}
        </Box>
      </Stack>
      <Stack justify="space-between" h="full" pb={1} pt={2} spacing={1}>
        <HStack>
          {map(providers, o => renderTag(o.label, o.icon, o.bg, o.count))}
        </HStack>
        <HStack pt={0}>
          {map(resources, o => renderTag(o.label, o.icon, o.bg, o.count))}
        </HStack>
      </Stack>
    </HStack>
  );
};
