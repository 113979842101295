import React from 'react';

import { HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AccountIcon, CloudIcon, CloudServiceIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { renderResourceCount } from 'containers/Dashboard/utils/utils';

export const Summary: React.FC = () => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);

  const { cloudSummary } = stateOfYourCloud;

  const navigate = useNavigate();

  const redirectToInventory = () => navigate('/setup/accounts');

  const legends = [
    {
      icon: <CloudIcon />,
      count: cloudSummary.data.cloud_providers?.length ?? 0,
      label: 'Clouds',
      isLoading: cloudSummary.isLoading,
      onClick: redirectToInventory,
    },
    {
      icon: <CloudServiceIcon />,
      count: cloudSummary.data.items?.['saas'] ?? 0,
      label: 'SaaS',
      isLoading: cloudSummary.isLoading,
      onClick: redirectToInventory,
    },
    {
      icon: <AccountIcon />,
      count: cloudSummary.data.items?.['cloud_account'] ?? 0,
      label: 'Accounts',
      isLoading: cloudSummary.isLoading,
      onClick: redirectToInventory,
    },
    /*{
      icon: <RegionIcon />,
      count: cloudSummary.data.items?.region ?? 0,
      label: 'Regions',
      isLoading: cloudSummary.isLoading,
      onClick: redirectToInventory,
    },
    {
      icon: <PackagesIcon p={0.5} />,
      count: resourcesCount.data.active_resources ?? 0,
      label: 'Entities',
      isLoading: resourcesCount.isLoading,
      onClick: redirectToInventory,
    },*/
  ];

  return (
    <SummaryCard
      content={
        <HStack w="full" justify="space-between">
          {map(legends, o => renderResourceCount(o))}
        </HStack>
      }
    />
  );
};
