import {
  CanAccessEntityCounts,
  InsightApiGetCanAccessEntityCountsRequest,
  InsightApiGetRoleTrustedEntitiesRequest,
  InsightV2ApiInsightV2AllRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
} from '@ariksa/inventory-core/api';

import { InventoryService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useInventoryInsightService() {
  const roleTrustedEntities = useAriksaQuery<
    InsightApiGetRoleTrustedEntitiesRequest,
    any
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.Insight.getRoleTrustedEntities(req).then(r => r.data),
  });

  const inventoryInsights = useAriksaQuery<
    InsightV2ApiInsightV2AllRequest,
    InsightV2Pagination
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.InsightV2.insightV2All(req).then(r => r.data),
  });

  const inventoryInsightV2 = useAriksaQuery<
    InsightV2ApiInsightV2Request,
    InsightV2Pagination
  >({
    queryKey: [],
    fetcher: req => InventoryService.InsightV2.insightV2(req).then(r => r.data),
  });

  const canAccessEntityCount = useAriksaQuery<
    InsightApiGetCanAccessEntityCountsRequest,
    CanAccessEntityCounts[]
  >({
    queryKey: [],
    fetcher: req =>
      InventoryService.Insight.getCanAccessEntityCounts(req).then(r => r.data),
  });

  return {
    roleTrustedEntities,
    inventoryInsights,
    canAccessEntityCount,
    inventoryInsightV2,
  };
}
