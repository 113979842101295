import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ChatIcon = props => (
  <Icon viewBox="0 0 48 48" color="inherit" h="full" w="full" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M36,21.9996 C34.897,21.9996 34,21.1026 34,19.9996 C34,18.8966 34.897,17.9996 36,17.9996 C37.103,17.9996 38,18.8966 38,19.9996 C38,21.1026 37.103,21.9996 36,21.9996 L36,21.9996 Z M36,15.9996 C33.794,15.9996 32,17.7936 32,19.9996 C32,22.2056 33.794,23.9996 36,23.9996 C38.206,23.9996 40,22.2056 40,19.9996 C40,17.7936 38.206,15.9996 36,15.9996 L36,15.9996 Z M24,21.9996 C22.897,21.9996 22,21.1026 22,19.9996 C22,18.8966 22.897,17.9996 24,17.9996 C25.103,17.9996 26,18.8966 26,19.9996 C26,21.1026 25.103,21.9996 24,21.9996 L24,21.9996 Z M24,15.9996 C21.794,15.9996 20,17.7936 20,19.9996 C20,22.2056 21.794,23.9996 24,23.9996 C26.206,23.9996 28,22.2056 28,19.9996 C28,17.7936 26.206,15.9996 24,15.9996 L24,15.9996 Z M12,21.9996 C10.897,21.9996 10,21.1026 10,19.9996 C10,18.8966 10.897,17.9996 12,17.9996 C13.103,17.9996 14,18.8966 14,19.9996 C14,21.1026 13.103,21.9996 12,21.9996 L12,21.9996 Z M12,15.9996 C9.794,15.9996 8,17.7936 8,19.9996 C8,22.2056 9.794,23.9996 12,23.9996 C14.206,23.9996 16,22.2056 16,19.9996 C16,17.7936 14.206,15.9996 12,15.9996 L12,15.9996 Z M44,32.9496 C44,34.0806 43.163,34.9996 42.121,34.9996 L37.988,35.0506 C37.44,35.0576 37,35.5036 37,36.0506 L37,40.2886 L31.455,35.2586 C31.271,35.0926 31.033,34.9996 30.785,34.9996 L6,34.9996 C4.831,34.9996 4,34.0306 4,32.9036 L4,9.0286 C4,7.9096 4.91,6.9996 6.029,6.9996 L41.971,6.9996 C43.09,6.9996 44,7.9096 44,9.0286 L44,32.9496 Z M41.971,4.9996 L6.029,4.9996 C3.807,4.9996 2,6.8066 2,9.0286 L2,32.9036 C2,35.1336 3.848,36.9486 6.118,36.9486 L30.396,36.9986 L37.328,43.2866 C37.516,43.4576 37.756,43.5456 38,43.5456 C38.137,43.5456 38.275,43.5176 38.405,43.4596 C38.767,43.2996 39,42.9416 39,42.5456 L39,37.0386 L42.133,36.9996 C44.265,36.9996 46,35.1826 46,32.9496 L46,9.0286 C46,6.8066 44.193,4.9996 41.971,4.9996 L41.971,4.9996 Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);
