/**
 *
 * Microsoft Active Directory
 *
 */

import React from 'react';

import { Form } from 'components/index';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { formStyles } from '../../styles';

export const MicrosoftAD = () => {
  const { onCancel } = useIntegrationsContext();
  const handleReset = () => {
    onCancel();
  };
  const handleSubmit = () => {
    onCancel();
  };

  return (
    <>
      <Form
        schema={{
          ldapUrl: {
            type: 'text',
            label: 'LDAP URL',
            placeholder: 'http://ldap.org.com',
          },
          userName: {
            type: 'text',
            label: 'User Name',
            placeholder: 'administrator',
          },
          password: {
            type: 'text',
            label: 'Password',
            placeholder: '123456789012345678901',
          },
          ldapDistinguishedNames: {
            type: 'object',
            label: 'LDAP Distinguished Names (DN)',
            properties: {
              bindDN: {
                type: 'text',
                label: 'Bind DN',
                placeholder: 'dc=org, dc=com',
              },
              baseDN: {
                type: 'text',
                label: 'Base DN',
                placeholder: 'ou=users, ou=groups, dc=org, dc=com',
              },
            },
          },
        }}
        buttonOptions={{
          submit: { name: 'Okay' },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        styles={{ form: { ...formStyles.form } }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
