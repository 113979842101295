import {
  CodeRepositoryApiGetRepoBranchesRequest,
  CodeRepositoryApiGetRepositoriesByUuidRequest,
  Repository,
} from '@ariksa/cloud-account/api';
import { Category, SensitivityLevel } from '@ariksa/data-scanning';
import {
  DataClassificationApiAddDataClassificationGroupRequest,
  DataClassificationApiDeleteDataClassificationRequest,
  DataClassificationApiGetDataClassificationGroupsByFilterRequest,
  DataClassificationApiUpdateDataClassificationGroupRequest,
  DataClassificationGroupRead,
  DataClassifiers,
  DataExclusionsApiAddDataExclusionRequest,
  DataExclusionsApiDeleteDataExclusionRequest,
  DataExclusionsApiUpdateDataExclusionRequest,
  DataTypesApiAddDataTypeRequest,
  DataTypesApiDeleteDataTypeRequest,
  DataTypesApiUpdateDataTypeRequest,
  FileDataApiGetScannersRequest,
} from '@ariksa/data-scanning/api';
import {
  InsightV2ApiInsightV2AllRequest,
  InsightV2ApiInsightV2Request,
  InsightV2Pagination,
  NativeResources,
} from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, DataClassificationServiceObject } from './types';

// The initial state of the whitelist container
export const initialState: ContainerState = {
  dataClassificationGroups: QueryState.init([]),
  dataClassification: QueryState.init({} as DataClassificationGroupRead),
  enableDataClassification: QueryState.init({}),
  dataScannerInfo: QueryState.init({}),
  dataClassificationServices: QueryState.init([]),
  dataSources: QueryState.init([]),
  repositories: QueryState.init([]),
  scannerInstances: QueryState.init([]),
  service: {
    data_sources: [],
    data_objects: [],
    quantifier: 'all',
    category: Category.Include,
    service: NativeResources.SimpleStorageService,
  },

  accountType: '',

  dataClassifiers: QueryState.init([]),
  dataConfigurationAction: QueryState.init({}),
  sensitivityInfo: QueryState.init({}),
};

const dataClassificationSlice = createSlice({
  name: 'dataClassification',
  initialState,
  reducers: {
    getDataClassificationGroups(
      state,
      action: QueryAction<
        DataClassificationGroupRead[],
        DataClassificationApiGetDataClassificationGroupsByFilterRequest
      >,
    ) {
      state.dataClassificationGroups = QueryState.next(
        state.dataClassificationGroups,
        action,
      );
    },

    getDataClassificationByID(
      state,
      action: QueryAction<
        DataClassificationGroupRead[],
        DataClassificationApiGetDataClassificationGroupsByFilterRequest
      >,
    ) {
      state.dataClassification = QueryState.next(
        state.dataClassification,
        action,
        { mapData: res => res?.[0] },
      );
    },

    getDataClassificationServices(state, action: QueryAction<string[]>) {
      state.dataClassificationServices = QueryState.next(
        state.dataClassificationServices,
        action,
      );
    },

    /*get data scanner info*/
    getDataScannerInfo(
      state,
      action: QueryAction<Record<string, any>, FileDataApiGetScannersRequest>,
    ) {
      state.dataScannerInfo = QueryState.next(state.dataScannerInfo, action);
    },

    enableDataClassification(
      state,
      action: QueryAction<
        any,
        DataClassificationApiAddDataClassificationGroupRequest
      >,
    ) {
      state.enableDataClassification = QueryState.next(
        state.enableDataClassification,
        action,
      );
    },
    updateDataClassification(
      state,
      action: QueryAction<
        any,
        DataClassificationApiUpdateDataClassificationGroupRequest
      >,
    ) {
      state.enableDataClassification = QueryState.next(
        state.enableDataClassification,
        action,
      );
    },
    deleteDataClassification(
      state,
      action: QueryAction<
        any,
        DataClassificationApiDeleteDataClassificationRequest
      >,
    ) {
      state.enableDataClassification = QueryState.next(
        state.enableDataClassification,
        action,
      );
    },

    updateServiceFields(
      state,
      action: PayloadAction<{ field: string; value: any }>,
    ) {
      state.service[action.payload.field] = action.payload.value;
    },
    updateService(
      state,
      action: PayloadAction<DataClassificationServiceObject>,
    ) {
      state.service = action.payload;
    },
    resetService(state) {
      state.service = initialState.service;
    },

    getDataSources(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2AllRequest>,
    ) {
      state.dataSources = QueryState.next(state.dataSources, action, {
        mapData: d => d?.items,
      });
    },

    getRepoBranches(
      state,
      action: QueryAction<string[], CodeRepositoryApiGetRepoBranchesRequest>,
    ) {
      state.dataSources = QueryState.next(state.dataSources, action);
    },

    getScannerInstances(
      state,
      action: QueryAction<InsightV2Pagination, InsightV2ApiInsightV2Request>,
    ) {
      state.scannerInstances = QueryState.next(state.scannerInstances, action);
    },

    getDataTypes(state, action: QueryAction<DataClassifiers[]>) {
      state.dataClassifiers = QueryState.next(state.dataClassifiers, action);
    },

    getDataExclusions(state, action: QueryAction<DataClassifiers[]>) {
      state.dataClassifiers = QueryState.next(state.dataClassifiers, action);
    },

    addDataType(
      state,
      action: QueryAction<any, DataTypesApiAddDataTypeRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },
    updateDataType(
      state,
      action: QueryAction<any, DataTypesApiUpdateDataTypeRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },
    deleteDataType(
      state,
      action: QueryAction<any, DataTypesApiDeleteDataTypeRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },

    addDataExclusion(
      state,
      action: QueryAction<any, DataExclusionsApiAddDataExclusionRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },
    updateDataExclusion(
      state,
      action: QueryAction<any, DataExclusionsApiUpdateDataExclusionRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },
    deleteDataExclusion(
      state,
      action: QueryAction<any, DataExclusionsApiDeleteDataExclusionRequest>,
    ) {
      state.dataConfigurationAction = QueryState.next(
        state.dataConfigurationAction,
        action,
      );
    },

    getSensitivityInfo(
      state,
      action: QueryAction<Record<string, SensitivityLevel>>,
    ) {
      state.sensitivityInfo = QueryState.next(state.sensitivityInfo, action);
    },

    getRepositories(
      state,
      action: QueryAction<
        Repository[],
        CodeRepositoryApiGetRepositoriesByUuidRequest
      >,
    ) {
      state.repositories = QueryState.next(state.repositories, action);
    },

    updateAccountType(state, action: PayloadAction<string>) {
      state.accountType = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = dataClassificationSlice;
