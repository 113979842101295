import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.compliance || initialState;

export const selectCompliance = createSelector(
  [selectDomain],
  complianceState => complianceState,
);
