import React, { useEffect, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { each, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { IconTypes, PermissionsActionsIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { SearchQueryMapping } from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { VerticalCountCard } from 'containers/Dashboard/utils/VerticalCountCard';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

export const IdentitiesWithExcessPermissions: React.FC = () => {
  const { identitiesWithExcessPermissions } = useSelector(
    selectIdentityOverview,
  );
  const navigate = useNavigate();
  const [data, setData] = useState<Record<string, any>>({});

  useEffect(() => {
    let items = {};
    each(
      identitiesWithExcessPermissions.data?.items,
      o => (items[o.provider_or_source_type + ' ' + o.agnostic] = o.count),
    );
    setData(items);
  }, [identitiesWithExcessPermissions.data]);

  const items = [
    {
      label: 'Users',
      count: formatNumber(data?.['Cloud User'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.IamUser,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['User'],
          ),
        });
        navigate(url);
      },
      iconColor: 'blue.300',
      borderColor: 'blue.300',
      tooltip: formatTooltip({
        header: 'Users',
        content: 'Cloud-native and federated users',
      }),
    },
    {
      label: 'Roles',
      count: formatNumber(data?.['Cloud Role'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.Role,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['Role'],
          ),
        });
        navigate(url);
      },
      iconColor: 'primary',
      borderColor: 'primary',
      tooltip: formatTooltip({
        header: 'Roles',
        content: 'Cloud-native roles for public clouds, SaaS',
        width: 32,
      }),
    },
    {
      label: 'Non-users',
      count: formatNumber(data?.['Cloud Non-users'] || 0),
      isLoading: identitiesWithExcessPermissions.isLoading,
      iconType: IconTypes.VirtualMachine,
      onClick: () => {
        const url = securityGraphRedirectUrl({
          query: getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_excess_permissions,
            ['Compute,Credential,ContainerService,App'],
          ),
        });
        navigate(url);
      },
      iconColor: 'blue.300',
      borderColor: 'blue.300',
      tooltip: formatTooltip({
        header: 'Non-users',
        content:
          'Non—user identities: VMs, Containers, Container Services, Serverless etc.',
        width: 48,
      }),
    },
  ];

  return (
    <DashboardOverviewCard
      label="Identities with excess permissions"
      icon={<PermissionsActionsIcon />}
      iconBgColor="critical"
      content={
        <HStack w="full" h="full" spacing={10} p={2}>
          {map(items, o => (
            <VerticalCountCard {...o} />
          ))}
        </HStack>
      }
    />
  );
};
