/**
 *
 * Teams Table
 *
 */

import React, { FC, useEffect } from 'react';

import { Clients } from '@ariksa/notification';
import { useDispatch, useSelector } from 'react-redux';

import { renderTime } from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { DeleteClientModal } from 'containers/Setup/Integrations/DeleteClientModal';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions as clientActions } from 'containers/Setup/Integrations/slice';

export const TeamsTable: FC = () => {
  const { clients, client } = useSelector(selectIntegrations);
  const dispatch = useDispatch();
  const {
    clientActionsColumn,
    isOpenDeleteClientModal,
  } = useIntegrationsContext();

  useEffect(() => {
    dispatch(
      clientActions.loadClients({
        clientName: Clients.Teams,
      }),
    );
  }, [dispatch]);

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      styles: {
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Team Name',
      accessor: 'teams.team',
      styles: {
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Channel',
      accessor: 'teams.channel',
      styles: {
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      styles: {
        textAlign: 'left',
        cell: { textAlign: 'left' },
      },
    },
    {
      header: 'Created On',
      accessor: 'created_at',
      render: renderTime,
    },
    clientActionsColumn,
  ];

  return (
    <>
      <Table
        columns={columns}
        data={clients.teams}
        isLoading={clients.loading || client.isLoading}
      />
      {isOpenDeleteClientModal && <DeleteClientModal />}
    </>
  );
};
