import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  CertificateType,
  AcmCertificateUpdatedAt,
  AcmCertificateUsedBy,
  CertificateAuthority,
  IssuedAt,
  Issuer,
  KeyAlgorithm,
  KeyUsages,
  RenewalStatus,
  Serial,
  SignatureAlgorithm,
  Subject,
} from '../../../Components/MetadataField/MetaGridFields';

export const AcmCertificateMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const columns = [
    {
      header: 'Domain Name',
      successor: 'DomainName',
      render: ({ row }) => row?.DomainName,
    },
    {
      header: 'Validation WhitelistStatus',
      successor: 'ValidationStatus',
      align: 'left',
      render: ({ row }) => row?.ValidationStatus,
    },
    {
      header: 'Validation Method',
      successor: 'ValidationMethod',
      align: 'left',
      render: ({ row }) => row?.ValidationMethod,
    },
    {
      header: 'Resource Record Type',
      successor: 'ResourceRecord',
      render: ({ row }) => row?.ResourceRecord?.Type,
      align: 'left',
    },
    {
      header: 'Validation Domain',
      successor: 'ValidationDomain',
      align: 'left',
      render: ({ row }) => row?.ValidationDomain,
    },
  ];
  const domainNames = resource?.config?.DomainValidationOptions;

  return (
    <Stack spacing={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <MetadataCommonFields />
        {CertificateType(resource)}
        {AcmCertificateUsedBy(resource)}
        {Issuer(resource)}
        {IssuedAt(resource)}
        {Serial(resource)}
        {KeyAlgorithm(resource)}
        {SignatureAlgorithm(resource)}
        {Subject(resource)}
        {KeyUsages(resource)}
        {RenewalStatus(resource)}
        {AcmCertificateUpdatedAt(resource)}
        {CertificateAuthority(resource)}
        <GridItem colSpan={4}>
          <Accordion allowMultiple>
            <MetaAccordionItem
              panel={<Table columns={columns} data={domainNames} />}
              iconType={''}
              label="Domain names"
              totalCount={domainNames?.length}
              height="300px"
            />
          </Accordion>
        </GridItem>
      </Grid>
    </Stack>
  );
};
