import React from 'react';

import { Icon } from '@chakra-ui/react';

export const InternetGatewayIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M346,299.4c2,0,4.1-0.8,5.7-2.3c3.1-3.1,3.1-8.2,0-11.3l-39.4-39.4v-0.1h-0.1l-2.3-2.3
		c-3.1-3.1-8.2-3.1-11.3,0l-2.3,2.3h-0.1v0.1l-39.4,39.4c-3.1,3.1-3.1,8.2,0,11.3c3.1,3.1,8.2,3.1,11.3,0l28.1-28.1v146h16V268.9
		l28.1,28.1C341.9,298.6,343.9,299.4,346,299.4z"
      />
      <path
        d="M220.2,421.6l-28.1,28.1V303.6h-16v146.1L148,421.6c-3.1-3.1-8.2-3.1-11.3,0s-3.1,8.2,0,11.3l39.4,39.4v0.1
		h0.1l2.3,2.3c1.6,1.6,3.6,2.3,5.7,2.3c2,0,4.1-0.8,5.7-2.3l2.3-2.3h0.1v-0.1l39.4-39.4c3.1-3.1,3.1-8.2,0-11.3
		C228.4,418.5,223.3,418.5,220.2,421.6z"
      />
      <path
        d="M512,249.7c0-67.2-54.6-121.7-121.7-121.7c-3.8,0-7.6,0.2-11.5,0.5C356.6,72.3,302.1,35,241.1,35
		c-77.3,0-140.7,58.5-147.5,134.6C41.4,173.4,0,217.1,0,270.3c0,40,23.4,74.6,57.1,91c0.3,0.1,0.5,0.2,0.8,0.4
		c10,4.7,20.9,7.9,32.4,9.1c3.7,0.4,7.7,0.6,11.8,0.6h22.5c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8H101c-46.9,0-85-38.1-85-85
		c0-46.9,38.1-85,85.1-85c4.3,0,7.8-3.4,8-7.7C112,106.6,170,51,241.1,51c56.3,0,106.5,35.7,124.9,88.8c1.3,3.6,5,5.8,8.7,5.3
		c5.2-0.8,10.4-1.1,15.6-1.1c58.3,0,105.7,47.4,105.7,105.7c0,55.8-43.5,101.7-98.4,105.4c-4.8,0.3-18.5,0.3-18.5,0.3h-27.3
		c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h23.9c0,0,17.9,0.5,27.4-0.7c0,0,0,0,0.1,0C464.3,364.2,512,312.4,512,249.7z"
      />
    </g>
  </Icon>
);
