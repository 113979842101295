import React from 'react';

import { Box, Stack, HStack } from '@chakra-ui/react';

import { H3 } from 'components/DataDisplay';

interface Props {
  onClose: () => void;
}

export const AboutAriksaOutpostForData = (props: Props) => {
  return (
    <Stack spacing={6}>
      <HStack justify="space-between">
        <H3>About Ariksa Outpost</H3>
      </HStack>
      <Box>
        Ariksa Outpost for Data provides data classification, cataloging and
        other advanced features to help to identify PII, PHI and other company
        confidential information.
      </Box>
      <Stack>
        <H3>Deployment</H3>
        <Box>
          Ariksa Outpost needs to be deployed in every region that contain data
          sources to ensure complete coverage.
        </Box>
      </Stack>
    </Stack>
  );
};
