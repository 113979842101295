import {
  PortUsageSummary,
  ResourceApiGetNetworkingInsightsRequest,
  ResourceApiGetNoEncryptionCountsRequest,
  ResourceApiGetPubliclyAccessibleCountsRequest,
  ResourceCountsForInternalTag,
  ResourceSummaryApiGetVmPortOpenSummaryRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertsApiGetResolvedAlertSummaryRequest,
  AlertSummaryResponse,
  ResolvedSummaryResponse,
} from '@ariksa/notification/api';
import {
  DefaultApiGetInstanceSshSummaryRequest,
  InstanceSSHSummary,
} from '@ariksa/scan-analysis/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import {
  InventoryService,
  NotificationService,
  ScanAnalysis,
} from 'api/services';

import { actions } from './slice';

export function* cloudPostureOverviewSaga() {
  yield takeLatestAction(actions.getCloudPostureOpenAlerts, doGetOpenAlerts);

  yield takeLatestAction(
    actions.getInfrastructurePostureResolvedAlerts,
    doGetResolvedAlertSummary,
  );
  yield takeLatestAction(
    actions.getInfrastructurePostureFindingsCategorySummary,
    doGetFindingsCategorySummary,
  );

  //infrastructure
  yield takeLatestAction(
    actions.getPubliclyAccessibleSources,
    doGetPubliclyAccessibleSources,
  );
  yield takeLatestAction(
    actions.getInfrastructureThatLacksEncryption,
    doGetInfrastructureThatLacksEncryption,
  );
  yield takeLatestAction(actions.getCloudPostureOpenAlerts, doGetOpenAlerts);
  yield takeLatestAction(actions.getVmPortUsage, doGetVMPortUsage);
  yield takeLatestAction(actions.getVmWithSSHTraffic, doGetVMWithSSHTraffic);
  yield takeLatestAction(
    actions.getInsightsForCloudNetworking,
    doGetInsightsForCloudNetworking,
  );
}

//get open alerts
export function* doGetOpenAlerts(
  ctx: QueryContext<AlertSummaryResponse, AlertsApiGetAlertSummaryRequest>,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getAlertSummary(ctx.params),
    {
      errorMsg: 'Failed to get open alert count!',
      cacheKey: 'cloudPostureOpenAlerts',
    },
  );
}

//get findings summary
export function* doGetResolvedAlertSummary(
  ctx: QueryContext<
    ResolvedSummaryResponse,
    AlertsApiGetResolvedAlertSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getResolvedAlertSummary(ctx.params),
    {
      errorMsg: 'Failed to get resolved findings summary!',
      cacheKey: 'cloudPostureResolvedAlertSummary',
    },
  );
}

//get findings category summary
export function* doGetFindingsCategorySummary(
  ctx: QueryContext<
    AggregatedAlertsSummary[],
    AlertsApiGetFindingsCategoriesSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => NotificationService.Alerts.getFindingsCategoriesSummary(ctx.params),
    {
      errorMsg: 'Failed to get findings category summary!',
      cacheKey: 'cloudPostureFindingsCategorySummary',
    },
  );
}

/*--------------------------Infrastructure---------------------------------*/

//get Infrastructure that lacks encryption
export function* doGetInfrastructureThatLacksEncryption(
  ctx: QueryContext<
    ResourceCountsForInternalTag[],
    ResourceApiGetNoEncryptionCountsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getNoEncryptionCounts(ctx.params),
    {
      errorMsg: 'Failed to get infrastructure that lacks encryption!',
      cacheKey: 'infrastructureThatLacksEncryption',
    },
  );
}

//get publicly accessible sources
export function* doGetPubliclyAccessibleSources(
  ctx: QueryContext<
    ResourceCountsForInternalTag[],
    ResourceApiGetPubliclyAccessibleCountsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getPubliclyAccessibleCounts(ctx.params),
    {
      errorMsg: 'Failed to get publicly accessible sources!',
      cacheKey: 'publiclyAccessibleSources',
    },
  );
}

//get well-known ports that are open and used on virtual machines
export function* doGetVMPortUsage(
  ctx: QueryContext<
    PortUsageSummary[],
    ResourceSummaryApiGetVmPortOpenSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.ResourceSummary.getVmPortOpenSummary(ctx.params),
    {
      errorMsg: 'Failed to get virtual machine port usage!',
      cacheKey: 'vmPortUsage',
    },
  );
}

//get virtual machines with SSH traffic
export function* doGetVMWithSSHTraffic(
  ctx: QueryContext<
    InstanceSSHSummary[],
    DefaultApiGetInstanceSshSummaryRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => ScanAnalysis.Default.getInstanceSshSummary(ctx.params),
    {
      errorMsg: 'Failed to get virtual machines with SSH traffic!',
      cacheKey: 'vmWithSSHTraffic',
    },
  );
}

//insights for cloud networking
export function* doGetInsightsForCloudNetworking(
  ctx: QueryContext<
    Record<string, number>,
    ResourceApiGetNetworkingInsightsRequest
  >,
) {
  yield call(
    ctx.fetch,
    () => InventoryService.Resource.getNetworkingInsights(ctx.params),
    {
      errorMsg: 'Failed to get insights for cloud networking!',
      cacheKey: 'insightsForCloudNetworking',
    },
  );
}
