import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { useInjector } from 'utils/inject';

import { ContextTable } from './Components/ContextTable';
import { CreateContext } from './Components/ContextWizard/CreateContext';
import { EditContext } from './Components/ContextWizard/EditContext';
import { inventoryContextSaga } from './saga';
import { reducer, sliceKey } from './slice';

export const InventoryContext = memo(() => {
  useInjector(sliceKey, reducer, inventoryContextSaga);

  return (
    <Routes>
      <Route element={<FilterRouter path="/inventory/context/add" />}>
        <Route path="add" element={<CreateContext />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/context/edit/:uuid" />}>
        <Route path="edit/:uuid" element={<EditContext />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/context" />}>
        <Route index element={<ContextTable />} />
      </Route>
    </Routes>
  );
});
