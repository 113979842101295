import { PageInfo } from '../../components';
import { INVENTORY_SERVICE } from '../service_urls';
import { withPrefixArgs, withPrefixNoArgs } from '../utils';

import {
  CloudAccountIds,
  CloudAccountId,
  OrganizationDetailsParams,
  OrgResourcesParams,
  GetPolicyDocumentParams,
  GetDuplicateSecurityGroupParams,
  GetPolicyPermissionAccountsParams,
  GetPolicyPermissionServicesParams,
  GetPolicyPermissionResourcesParams,
  GetPolicyPermissionActionsParams,
} from './types';
import {
  AssessActionsParams,
  AssessmentParams,
  IAMUserID,
  InsightParams,
  InventoryMapQueryParams,
  InventoryParams,
  InventoryQueryParams,
  OrgMapResourceParams,
  PolicyDetailsByIdProps,
  ResourceDetailsProps,
  ResourceFailedChecksProps,
  RoleDetailsProps,
  UserMapParams,
  UserMapAccountParams,
  UserPoliciesByUserIDParams,
  UserResourceTypeParams,
  UserRolesByUserIDParams,
  UserDetailsParams,
  ResourceDetailsParams,
  NetworkAssessmentParams,
  SingleUserDetailsParams,
  getTagsCharts,
  TagDetailsProps,
  GetBusinessUnitsParams,
  getPolicyMap,
  getPolicyMapResourceType,
  getInventorySummaryTable,
  UserResourceUserTypeParams,
  getRolesPolicies,
  getOrgMap,
  getTrustedEntities,
  getInstanceListProps,
  getInstanceListByIdProps,
  getCloudContextSummaryProps,
} from './types';

const PREFIX = INVENTORY_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
const withNoArgs = withPrefixNoArgs(PREFIX);

export const inventoryApis = {
  getPolicyPermissionAccounts: withArgs<GetPolicyPermissionAccountsParams>(
    `/permission/account`,
  ),
  getPolicyPermissionServices: withArgs<GetPolicyPermissionServicesParams>(
    `/permission/service`,
  ),
  getPolicyPermissionResources: withArgs<GetPolicyPermissionResourcesParams>(
    `/permission/resource`,
  ),
  getPolicyPermissionActions: withArgs<GetPolicyPermissionActionsParams>(
    `/permission/actions`,
  ),

  // org entity resource map
  getOrgResources: withArgs<OrgResourcesParams>(`/map/org/entity`),

  // network assessment
  getNetworkAssessment: withArgs<NetworkAssessmentParams>(
    `/network/assessment`,
  ),
  getNetworkAssessmentCommand: withArgs<NetworkAssessmentParams>(
    `/network/sg-command`,
  ),
  getVMNetworkAssessmentSummary: withArgs<any>(`/network/vm-summary`),

  getVMARuleReport: withArgs<CloudAccountId>(
    `/network/unused-vm-open-ports-report`,
  ),
  getSecurityGroupRules: withArgs<CloudAccountId>(
    `/network/subnets-communicating-with-vma-vms-report`,
  ),
  getStandardPorts: withNoArgs(`/network/standard-ports`),

  getDuplicateSecurityGroup: withArgs<GetDuplicateSecurityGroupParams>(
    `/network/duplicate`,
  ),

  //inventory
  getUserInventory: withArgs<InventoryQueryParams>(`/resource/user`),
  getResourceInventory: withArgs<InventoryParams>(`/resource/resource`),
  getCredentialInventory: withArgs<InventoryQueryParams>(
    `/resource/credential`,
  ),
  getOrganizationInventory: withArgs<InventoryQueryParams>(
    `/resource/organization`,
  ),
  getRoleInventory: withArgs<InventoryQueryParams>(`/resource/role`),

  // get details
  getUserDetails: withArgs<UserDetailsParams>(`/insight/user/{uuid}`),
  getResourceDetails: withArgs<ResourceDetailsParams>(
    `/insight/resource/{uuid}`,
  ),
  getOrgDetails: withArgs<OrganizationDetailsParams>(
    `/insight/organization/{uuid}`,
  ),

  getResourceById: withArgs<ResourceDetailsProps>(`/resource/{resource_uuid}`),
  getNodeDetails: withArgs<ResourceDetailsProps>(
    `/resource/summary/{resource_id}`,
  ),
  getResourceTypes: withNoArgs(`/resource/categories`),
  getResourceCount: withArgs<CloudAccountIds>(`/resource/count`),
  getResourceCountDiff: withArgs<CloudAccountIds>(`/resource/count-diff`),
  //getResourceHealthStatus: withArgs<ResourceHealthCheckProps>('/health/'),
  getResourceFailedChecks: withArgs<ResourceFailedChecksProps>(
    '/health/failed_checks',
  ),

  //map
  getOrgMapResources: withArgs<OrgMapResourceParams>(`/map/org/entity`),
  getInventoryMap: withArgs<InventoryMapQueryParams>(`/map/`),
  getUserMap: withArgs<UserMapParams>(`/map/user`),
  getUserMapAccount: withArgs<UserMapAccountParams>(`/map/user/accounts`),
  getUserResourceTypeMap: withArgs<UserResourceTypeParams>(
    `/map/user/resources`,
  ),
  getResourceUserTypeMap: withArgs<UserResourceUserTypeParams>(
    `/map/resource-user`,
  ),

  //insights
  getUserInsights: withArgs<PageInfo>(`/insight/user`),
  getBusinessUnitStructure: withArgs<GetBusinessUnitsParams>(
    `/organization/business-units`,
  ),
  getOrganizationInsights: withArgs<PageInfo>(`/insight/organization`),

  getRoleInsights: withArgs<PageInfo>(`/insight/role`),
  getCredentialInsights: withArgs<PageInfo>(`/insight/credential`),
  getInstanceInsights: withArgs<InsightParams>(`/insight/instance`),
  getResourceWithPolicy: withArgs<InsightParams>(
    `/insight/resource-with-policy/`,
  ),
  getResourceInsights: withArgs<InsightParams>(`/insight/resource`),
  getTagsInsights: withArgs<InsightParams>(`/insight/tags`),

  getTagDetailsById: withArgs<TagDetailsProps>(`/insight/tags/{uuid}`),
  getInstanceList: withArgs<getInstanceListProps>('/insight/instance'),
  getInstanceListById: withArgs<getInstanceListByIdProps>(
    '/insight/instance/{uuid}',
  ),

  //user
  getUserServices: withArgs<IAMUserID>('/user/user_unused_services'),
  getUsedUnusedUserPolicies: withArgs<IAMUserID>('/user/user_unused_policies'),
  getUserPermissions: withArgs<IAMUserID>('/user/user_unused_permissions'),
  getUserPoliciesByUserId: withArgs<UserPoliciesByUserIDParams>(
    '/user/user_policies',
  ),
  getUserRolesByUserId: withArgs<UserRolesByUserIDParams>('/user/user_roles'),
  getUserPolicyNetPermissions: withArgs<UserPoliciesByUserIDParams>(
    '/user/user_net_permissions',
  ),

  //policies
  getUserPolicies: withArgs<InventoryQueryParams>(`/policy/user`),
  getResourcePolicies: withArgs<InventoryQueryParams>(`/policy/resource`),
  getTagsPolicies: withArgs<InventoryQueryParams>(`/policy/tags`),
  getOrganizationPolicies: withArgs<InventoryQueryParams>(
    `/policy/organization`,
  ),
  getRoleDetails: withArgs<RoleDetailsProps>(`/insight/role_summary`),
  getPolicyDetailsById: withArgs<PolicyDetailsByIdProps>(`/policy/summary`),
  getRolePolicies: withArgs<InventoryQueryParams>(`/policy/all-policies`),

  getPolicyDocument: withArgs<GetPolicyDocumentParams>(
    `/policy/policy-document`,
  ),
  //dashboard alerts
  getAlertCategories: withNoArgs('/dashboard/category'),

  getAssessActions: withArgs<AssessActionsParams>('/assess/actions'),
  getAssessment: withArgs<AssessmentParams>('/assess/assessment'),

  // User Details
  getSingleUserDetails: withArgs<SingleUserDetailsParams>('/user/details'),

  // Tags charts
  getTagsCharts: withArgs<getTagsCharts>('/tags/chart/{tag_chart_type}'),

  // inventory summary
  getInventorySummaryTable: withArgs<getInventorySummaryTable>('/resource/'),

  // Roles Map
  getRolePoliciesById: withArgs<getRolesPolicies>('/policy/role-policies'),

  getPolicyMap: withArgs<getPolicyMap>('/policy/map-access'),

  getPolicyMapResourceType: withArgs<getPolicyMapResourceType>(
    '/policy/map-actions-resourcetypes',
  ),

  getPolicyMapResouceInstance: withArgs<getPolicyMapResourceType>(
    '/policy/map-resources',
  ),

  // Org Map
  getOrgMap: withArgs<getOrgMap>('/map/'),

  // Policy Principals
  getTrustedEntities: withArgs<getTrustedEntities>('/policy/principals'),

  // Cloud table context-summary
  getCloudContextSummary: withArgs<getCloudContextSummaryProps>(
    '/resource/count-summary',
  ),
  getAnomalies: withArgs<{
    account_id: string[];
  }>('/cloud-insight/anomalies'),
};
