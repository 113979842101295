import { customTheme } from 'theme';

export const metaAccordionStyles = {
  item: {
    my: 4,
    px: 0,
    border: 'none',
    borderColor: customTheme.colors.gray['200'],
    borderRadius: 6,
    boxShadow: `5px 5px 1px ${customTheme.colors.gray['50']}`,
    //h: 'full',
  },
  innerWrapper: {
    border: '1px solid',
    borderColor: customTheme.colors.gray['100'],
    borderRadius: 6,
    py: 0,
    h: 'full',
  },
  button: {
    fontSize: 'sm',
    px: 3,
    _focus: { border: 'none' },
    _hover: { bg: 'transparent' },
  },
};
