import React, { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { Form } from 'components/DataEntry/Form';
import { ComplianceCheckPassedIcon } from 'components/Icons';
import { selectEnvironmentOptions } from 'containers/App/selectors';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { BlueprintWizardStepProps } from 'containers/PolicyHub/Blueprints/types';

interface Props extends BlueprintWizardStepProps {
  environmentIds: string[];
}

export const PolicyScope: FC<Props> = props => {
  const dispatch = useDispatch();

  const { environmentIds, handleSubmit, handleBack, handleCancel } = props;
  const environmentOptions = useSelector(selectEnvironmentOptions);
  const { blueprintAction } = useSelector(selectBlueprints);

  return (
    <Box mt={4}>
      <Form
        title={
          <Box>
            <PageHeaderWithIcon
              label={'Add Blueprint: Scope'}
              reversed
              icon={<ComplianceCheckPassedIcon />}
            />
          </Box>
        }
        schema={{
          accounts: {
            type: 'react-select',
            isMulti: true,
            label: 'Environment Name',
            value: environmentOptions.filter(a =>
              environmentIds.includes(a.value),
            ),
            onChange: selectedOptions => {
              dispatch(
                actions.updateBlueprintFormFields({
                  field: 'configs',
                  value: selectedOptions.map(a => ({
                    environment_id: a?.value,
                  })),
                }),
              );
            },
            options: environmentOptions,
          },
        }}
        buttonOptions={{
          reset: {
            isVisible: true,
            name: 'Cancel',
            onClick: handleCancel,
          },
          back: {
            isVisible: true,
            name: 'Back',
            onClick: () => (handleBack ? handleBack() : 'back'),
          },
          submit: {
            name: 'Save',
            isLoading: blueprintAction.isLoading,
          },
        }}
        styles={{
          form: { container: { w: '600px' } },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
};
