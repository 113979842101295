import React from 'react';

import { Box } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import { ComposedChart } from 'app/components/Visualization/Charts';
import { historyChartStyles } from 'containers/Compliance/Components/ComplianceDashboard/styles';
import { selectCompliance } from 'containers/Compliance/selectors';
import { formatDate } from 'utils/date';

export const PassedChecksHistoricalTrend = () => {
  const { historicalTrend, blueprints } = useSelector(selectCompliance);
  const alertHistory = historicalTrend?.data;

  const data = map(alertHistory, (item, key) => ({
    name: formatDate(key, 'D-MMM'),
    'Passed Checks': item?.passed,
    passed: item?.passed,
    '% Passed': Number((item.passed / item.total_performed || 0) * 100).toFixed(
      0,
    ),
  }));

  return (
    <Box w="full" h="full" pt={10}>
      <ComposedChart
        data={data}
        isLoading={historicalTrend.isLoading || blueprints.isLoading}
        xField="name"
        bar={{ orientation: 'left', yField: 'Passed Checks' }}
        line={{ orientation: 'right', yField: '% Passed' }}
        styles={historyChartStyles()}
      />
    </Box>
  );
};
