import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { actions } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/slice';

export const useAlertStatus = () => {
  const dispatch = useDispatch();

  const getAlertListStatus = useCallback(
    (alertIds: string[]) => {
      dispatch(
        actions.getAlertListStatus({
          q: {
            alertId: alertIds,
          },
        }),
      );
    },
    [dispatch],
  );

  const getAlertStatus = useCallback(
    (alertId: string) => {
      dispatch(
        actions.getAlertStatus({
          q: {
            alertId: [alertId],
          },
        }),
      );
    },
    [dispatch],
  );

  const updateAlertStatus = useCallback(
    (alertId: string, label, onSuccess?: Function) => {
      dispatch(
        actions.updateAlertStatus({
          q: {
            alertId: alertId,
            ticketStatus: label,
          },
          onSuccess: () => {
            onSuccess?.();
          },
        }),
      );
    },
    [dispatch, getAlertStatus],
  );

  return {
    updateAlertStatus,
    getAlertListStatus,
    getAlertStatus,
  };
};
