import React, { FC } from 'react';

import { DataTypeForES } from '@ariksa/reporting';
import { HStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectHistoricalOptions } from 'components/DataEntry';
import { ExportAlerts } from 'containers/SharedState/Components/ExportAlerts/ExportAlerts';
import { VulnerabilityHeader } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityHeader';
import { VulnerabilityHeaderFilters } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/VulnerabilityHeaderFilters';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';

interface Props {
  isExpanded: boolean;
  setIsExpanded(val: boolean);
}

export const VulnerabilityTableHeader: FC<Props> = props => {
  const { publishedAt } = useSelector(selectVulnerabilities);
  const dispatch = useDispatch();

  return (
    <HStack justify="space-between">
      <VulnerabilityHeader />
      <HStack>
        <VulnerabilityHeaderFilters />
        {/*<DownloadIconButton
        aria-label="download-vulnerabilities"
        //isLoading={download.isLoading}
        tooltip="Download Report"
        onClick={() => {}}
      />*/}
        <SelectHistoricalOptions
          historicalTime={publishedAt}
          setHistoricalTime={s => dispatch(actions.updatePublishedAt(s))}
        />
        <ExportAlerts
          type={DataTypeForES.Vulnerabilities}
          tooltip="Export vulnerabilities"
          tooltipW={28}
        />
      </HStack>
    </HStack>
  );
};
