import React, { FC } from 'react';

import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useVulnerabilityTableColumns } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilityTableColumns';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';

interface Props {
  sortByField: Sorted;
  setSortByField(value: Sorted);
}

export const CVEHostsTable: FC<Props> = props => {
  const { sortByField, setSortByField } = props;
  const dispatch = useDispatch();
  const { cveHosts } = useSelector(selectVulnerabilities);

  const { tableActionModals, cveHostsColumns } = useVulnerabilityTableColumns();

  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const { getTabIndex } = useMetadataDrawerTabs();

  return (
    <>
      <Table
        data={cveHosts.data}
        columns={cveHostsColumns}
        cursor="pointer"
        isLoading={cveHosts.isLoading}
        pagination={{
          totalCount: cveHosts.page.totalCount,
          pageInfo: cveHosts.page.info,
          onChange: pageInfo =>
            dispatch(actions.updateCveHostsPageInfo(pageInfo)),
        }}
        onRowClick={row => {
          const tabIndex = getTabIndex(
            row?.native_name,
            MetadataTabs.Vulnerabilities,
          );
          updateActiveResource({
            resourceType: row?.native_name,
            uuid: row.uuid,
            accountId: row.account,
            resourceId: row.resource_id,
            tabIndex: tabIndex,
          });
          onOpenMetadata();
        }}
        onSort={sortInfo => {
          if (!isEqual(sortByField, sortInfo)) {
            setSortByField(sortInfo);
          }
        }}
        sortBy={sortByField}
      />
      {tableActionModals}
    </>
  );
};
