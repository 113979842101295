import { WorkflowApiGetLatestRequest, WorkflowRead } from '@ariksa/awm/api';

import { WorkflowService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useWorkflowService() {
  const latestSnapshot = useAriksaQuery<
    WorkflowApiGetLatestRequest,
    WorkflowRead
  >({
    queryKey: [],
    fetcher: req => WorkflowService.getLatest(req).then(r => r.data),
    config: {
      retry: false,
    },
  });

  return { latestSnapshot };
}
