import React, { useState } from 'react';

import { Box, Flex, Stack, Center, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import {
  PageHeaderWithIcon,
  renderStackedCloudNameAndIcon,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
} from 'components/DataEntry';
import {
  EditIcon,
  IconTypes,
  RemediateIcon,
  ServicesIcon,
} from 'components/Icons';
import { useRemediation } from 'containers/PolicyHub/Remediation/Components/hooks/useRemediation';
import { AlertsAssociatedToRemediation } from 'containers/PolicyHub/Remediation/Components/RemediationTable/AlertsAssociatedToRemediation';
import { AttachRemediationToAlert } from 'containers/PolicyHub/Remediation/Components/RemediationTable/AttachRemediationToAlert';
import { selectRemediation } from 'containers/PolicyHub/Remediation/selector';

import { actions } from '../../slice';

export const RemediationTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { remediationAction, remediationList } = useSelector(selectRemediation);
  const { getRemediationList, getRemediationCategories } = useRemediation();
  const attachRemediation = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState({});

  useDeepCompareEffect(() => {
    getRemediationList();
  }, [getRemediationList]);

  const handleDelete = row => {
    dispatch(
      actions.deleteRemediation({
        q: { id: row?.id },
        onSuccess: () => getRemediationCategories(),
      }),
    );
  };

  const renderAction = ({ row }) => {
    return (
      <Center
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionButton
          label="Attach alert to remediation"
          icon={<ServicesIcon />}
          onClick={() => {
            attachRemediation.onOpen();
            setCurrentRecord(row);
          }}
        />
        <ActionButton
          label="Edit"
          icon={<EditIcon />}
          onClick={() => {
            navigate(`/policy-hub/remediation/edit/${row?.id}`);
          }}
          isDisabled={row?.created_by === 'Ariksa'}
        />
        <DeleteActionButton
          onConfirm={() => handleDelete(row)}
          name={row?.name}
          type={'remediation'}
          isLoading={remediationAction.isLoading}
          isDisabled={row?.created_by === 'Ariksa'}
        />
      </Center>
    );
  };

  const columns = [
    {
      header: <Box pl={0}>Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={IconTypes.Policy}
          bgColor="primary"
          tooltip={row?.name}
        >
          {row?.name}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Description',
      accessor: 'description',
      align: 'left',
    },
    {
      header: 'Cloud',
      align: 'left',
      render: ({ row }) => renderStackedCloudNameAndIcon(row?.cloud),
    },
    {
      header: 'Used For',
      align: 'left',
      accessor: 'policy_count',
      render: ({ value }) => value + ' alert' + (value === 1 ? '' : 's'),
    },
    {
      header: 'Created By',
      align: 'left',
      accessor: 'created_by',
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  return (
    <Stack h="full" spacing={5} w="full">
      <Stack w="full" justify="space-between" isInline>
        <PageHeaderWithIcon
          label="Remediation"
          icon={<RemediateIcon />}
          reversed
        />
        <Flex>
          <AddButton
            label="Create Remediation"
            onClick={() => {
              navigate('/policy-hub/remediation/add');
            }}
          />
        </Flex>
      </Stack>
      <Box flex={1}>
        <Table
          data={remediationList.data}
          columns={columns}
          isLoading={remediationList.isLoading}
          cursor="pointer"
          pagination={{
            totalCount: remediationList.page.totalCount,
            pageInfo: remediationList.page.info,
            onChange: pageInfo => {
              dispatch(actions.updateRemediationListPagination(pageInfo));
            },
          }}
          subComponent={({ row, expanded }) =>
            expanded && (
              <AlertsAssociatedToRemediation row={row} isExpanded={expanded} />
            )
          }
        />
      </Box>
      {attachRemediation.isOpen && (
        <AttachRemediationToAlert
          isOpen={attachRemediation.isOpen}
          onClose={attachRemediation.onClose}
          row={currentRecord}
          actionType="Attach"
        />
      )}
    </Stack>
  );
};
