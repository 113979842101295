import React, { FC, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import {
  AggregatedAlertDetails,
  AlertResponse,
  Severity,
} from '@ariksa/notification/api';
import dayjs from 'dayjs';
import { each, filter, isString, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { AddExceptionModal } from 'components/Overlay';
import { errorToast, successToast } from 'components/Toast';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions } from 'containers/SharedState/slice';

interface IAddException {
  alert?: AlertResponse;
  aggregatedAlert?: AggregatedAlertDetails;

  onClose();

  onSuccess?();

  isOpen: boolean;
}

export const AddException: FC<IAddException> = props => {
  const { alert, onClose, isOpen, aggregatedAlert, onSuccess } = props;
  const { blueprints } = useSelector(selectSharedState);
  const [exceptionDescription, setExceptionDescription] = useState('');
  const [exceptionApplyTime, setExceptionApplyTime] = useState({
    label: '6 months',
    value: dayjs().utc().add(6, 'months').format(),
  });
  const [blueprint, setBlueprint] = useState<Record<string, any>[]>([]);
  const [blueprintOptions, setBlueprintOptions] = useState<
    Record<string, any>[]
  >([]);
  const dispatch = useDispatch();
  const [alertCount, setAlertCount] = useState<Record<string, any>>({});

  useEffect(() => {
    const allBlueprints = {};
    each(blueprints.data, o => {
      allBlueprints[o.id] = o.created_by === 'system';
    });
    const options = map(
      filter(
        alert?.blueprints || aggregatedAlert?.blueprints,
        o => !allBlueprints[o.blueprint_id],
      ),
      o => ({
        label: o.blueprint_name,
        value: o.blueprint_id,
      }),
    );
    setBlueprint(options);
    setBlueprintOptions(options);
  }, [alert, aggregatedAlert, blueprints.data]);

  useEffect(() => {
    if (!!alert)
      setAlertCount({
        critical: alert?.severity === Severity.Critical ? 1 : 0,
        medium: alert?.severity === Severity.Medium ? 1 : 0,
        low: alert?.severity === Severity.Low ? 1 : 0,
      });
    else
      setAlertCount({
        critical: aggregatedAlert?.severity?.CRITICAL,
        medium: aggregatedAlert?.severity?.MEDIUM,
        low: aggregatedAlert?.severity?.LOW,
      });
  }, [aggregatedAlert, alert]);

  const handleAddException = () => {
    const resource = alert?.entity_uuid
      ? { resource_id: alert?.entity_uuid }
      : {};
    dispatch(
      actions.addException({
        q: {
          blueprintExceptionCreate: {
            blueprint_ids: map(blueprint, o => o?.value),
            rule_id: alert?.uuid || aggregatedAlert?.policy_ids?.[0],
            native_resource: (alert?.resource ||
              (aggregatedAlert?.multi_resource
                ? 'CloudService'
                : aggregatedAlert?.resource_types?.[0])) as any,
            ...resource,
            description: exceptionDescription,
            expire_at: exceptionApplyTime.value,
          },
        },
        onSuccess: res => {
          onSuccess?.();
          if (isString(res)) {
            successToast({ title: res });
          } else {
            successToast({
              title: `Successfully added exception!`,
            });
          }
        },
        onError: error =>
          errorToast({
            title: `Failed to add exception.`,
            description: error.description,
          }),
      }),
    );
    onClose();
  };

  return (
    <AddExceptionModal
      description={alert?.aggregate_message}
      resourceName={alert?.entity_name}
      resourceId={alert?.entity_id}
      onConfirm={() => handleAddException()}
      isOpen={isOpen}
      onClose={onClose}
      isMulti={!!aggregatedAlert}
      resourceType={
        (alert?.resource! as NativeResources) ||
        ((aggregatedAlert?.multi_resource
          ? 'CloudService'
          : aggregatedAlert?.resource_types?.[0]) as NativeResources)
      }
      blueprintOptions={blueprintOptions}
      selectedBlueprint={blueprint}
      setSelectedBlueprint={setBlueprint}
      environment={alert?.environment_id || aggregatedAlert?.environment_id!}
      accountId={alert?.account_id ?? aggregatedAlert?.account_id_list ?? []}
      alertCount={alertCount}
      exceptionDescription={exceptionDescription}
      setExceptionDescription={setExceptionDescription}
      setExceptionApplyTime={setExceptionApplyTime}
      exceptionApplyTime={exceptionApplyTime}
    />
  );
};
