import React, { FC, useState, useEffect } from 'react';

import { Accordion, Grid, GridItem, Box } from '@chakra-ui/react';
import { forEach } from 'lodash';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  APIGatewayEndpoint,
  APIKeyRequired,
  Authorization,
  ClientCertificates,
  CrossOriginResourceSharing,
  DetailedMetrics,
  EndpointType,
  IntegrationType,
  Proxy,
  RequestValidator,
  Type,
  UseDefaultTimeOut,
} from '../../../Components/MetadataField/MetaGridFields';

export const APIGatewayRestAPIMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const [methods, setMethods] = useState<Record<string, any>[]>([]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Method</Box>,
      accessor: 'method',
      render: ({ value }) => (
        <WithResourceIcon resourceType={IconTypes.ApiGatewayRestApi}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
  ];

  useEffect(() => {
    const data: Record<string, any>[] = [];
    forEach(resource?.config?.resources, o => {
      if (!!o?.resourceMethods)
        forEach(o.resourceMethods, (v, k) =>
          data.push({ ...o, ...v, method: o?.path + '/' + k }),
        );
      else data.push({ ...o, method: o?.path });
    });
    setMethods(data);
  }, [resource]);

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" />
      {EndpointType(resource)}
      {Type(resource)}
      {CrossOriginResourceSharing(resource)}
      {Proxy(resource)}
      {DetailedMetrics(resource)}
      {ClientCertificates(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={
              <Table
                columns={columns}
                data={methods}
                subComponent={({ row }) => (
                  <Box pl={10}>
                    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                      {IntegrationType(row)}
                      {UseDefaultTimeOut(row)}
                      {Authorization(row)}
                      {RequestValidator(row)}
                      {APIKeyRequired(row)}
                      {APIGatewayEndpoint(row)}
                    </Grid>
                  </Box>
                )}
              />
            }
            iconType={IconTypes.ApiGatewayRestApi}
            label="RESOURCES AND METHODS"
            totalCount={methods?.length}
            height="300px"
            iconBGColor="primary"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
