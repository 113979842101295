import React, { FC } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions as clientActions } from '../../slice';

import { SMSForm } from './SMSForm';

export const CreateSMS: FC = () => {
  const { onCancel } = useIntegrationsContext();
  const dispatch = useDispatch();

  const onSubmit = data => {
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        client_name: Clients.Sns,
        name: data.name,
        status: true,
        sns: {
          account_id: data.account?.value,
          topic_arn: data?.topic?.value?.resource_id,
          region: data?.topic?.value?.region,
        },
      },
    };
    dispatch(
      clientActions.createClient({ q: payload, onSuccess: () => onCancel() }),
    );
  };

  return <SMSForm handleSubmit={onSubmit} />;
};
