import React from 'react';

import { Icon } from '@chakra-ui/react';

export const BucketDenyIcon = props => (
  <Icon viewBox="0 0 76 92" color="inherit" h="full" w="full" {...props}>
    <g clipPath="url(#clip0)" transform="translate(-1796 -1067)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M73.3148 29.6528C72.8652 33.1641 69.9755 34.6104 69.8535 34.6712L69.7574 34.7133C68.9281 35.0416 67.9864 35.1947 66.9559 35.1947 56.6563 35.1947 37.421 20.0334 31.2843 14.9717 31.04 15.0487 30.7864 15.1014 30.5169 15.1014 29.0957 15.1014 27.9434 13.9487 27.9434 12.5268 27.9434 11.105 29.0966 9.95243 30.5169 9.95243 31.9411 9.95243 33.0906 11.105 33.0906 12.5268 33.0906 12.6368 33.0714 12.7426 33.0593 12.8492 42.0018 20.2133 61.7701 34.7697 68.6748 32.1672 68.9185 32.0313 70.3566 31.1506 70.5771 29.2579 70.8137 27.2566 69.772 23.7809 63.9124 18.3366L62.7496 28.9195C56.5937 27.1627 45.6426 20.2087 35.8155 12.3953 35.744 9.52714 33.403 7.22372 30.5181 7.22372 27.5909 7.22372 25.2168 9.5976 25.2168 12.5267 25.2168 15.4557 27.5909 17.8283 30.5181 17.8283 30.6021 17.8283 30.683 17.82 30.7655 17.8162 38.4743 24.0236 51.9677 33.9473 61.9536 36.1698L57.8653 73.3577C57.7625 74.2926 56.973 74.9998 56.0314 74.9998L10.3859 74.9998C9.43709 74.9998 8.64239 74.2791 8.5505 73.3339L1.63575 2.02272C1.58437 1.5052 1.75476 0.990021 2.10474 0.604892 2.45455 0.220265 2.94914 0 3.47118 0L63.8694 0C64.3954 0 64.8932 0.222943 65.2432 0.613261 65.5933 1.00425 65.76 1.52495 65.7019 2.04649L64.2843 14.9529C70.799 20.6062 73.8414 25.5524 73.3148 29.6528Z"
              stroke="#7030A0"
              strokeWidth="0.167465"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              fillRule="nonzero"
              fillOpacity="1"
              transform="matrix(1 0 0 1.01333 1798 1068)"
            />
          </g>
        </g>
      </g>
      <g clipPath="url(#clip4)">
        <g clipPath="url(#clip5)">
          <g clipPath="url(#clip6)">
            <path
              d="M40.3333 22C40.3333 32.1252 32.1252 40.3333 22 40.3333 11.8748 40.3333 3.66667 32.1252 3.66667 22 3.66667 11.8748 11.8748 3.66667 22 3.66667 32.1252 3.66667 40.3333 11.8748 40.3333 22ZM14.87 29.13C14.512 28.7721 14.512 28.1917 14.87 27.8337L20.7037 22.0001 14.87 16.1664C14.512 15.8084 14.512 15.228 14.87 14.87 15.2279 14.512 15.8084 14.512 16.1663 14.87L22 20.7037 27.8337 14.8701C28.1916 14.5121 28.7721 14.5121 29.13 14.8701 29.488 15.228 29.488 15.8085 29.13 16.1664L23.2964 22.0001 29.13 27.8337C29.488 28.1916 29.488 28.7721 29.13 29.13 28.772 29.488 28.1916 29.488 27.8337 29.13L22 23.2964 16.1664 29.13C15.8084 29.4881 15.228 29.4881 14.87 29.13Z"
              stroke="#C00000"
              strokeWidth="0.916667"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              strokeMiterlimit="4"
              strokeOpacity="1"
              fill="#FFFFFF"
              fillRule="evenodd"
              fillOpacity="1"
              transform="matrix(1.02273 0 0 1 1824 1114)"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
