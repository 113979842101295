import React, { useCallback, useEffect, useState } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack, Stack, Center, Text } from '@chakra-ui/react';
import { isObject, map, replace, split } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { customTheme } from 'theme';

import { AriksaIcon, getIcon, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import {
  IconTypes,
  LockCloseIcon,
  MaliciousIngressIcon,
  UnencryptedDiskIcon,
} from 'components/Icons';
import { ProcessConnections } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/ProcessConnections';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { toTitleCase } from 'utils/string';

const styles = {
  menu: provided => ({
    ...provided,
    width: 'max-content',
    minWidth: '100%',
    right: 0,
  }),
};

export const Ec2AppsTab = () => {
  const dispatch = useDispatch();
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { resourceUuid, resourceType } = useActiveResourceContext();
  const { runningProcesses, processConnections, resourceInsight } = useSelector(
    selectActiveResource,
  );
  const [instanceOptions, setInstanceOptions] = useState<Record<string, any>[]>(
    [],
  );
  const [instance, setInstance] = useState<Record<string, any>>({});

  useEffect(() => {
    const options = map(resourceInsight.data?.instances, o => {
      const items = split(replace(o, ' ', ''), ', ');
      const name = split(items[1], ': ')?.[1];
      const id = split(items[0], ':')?.[1];
      const uuid = split(items[2], ': ')?.[1];
      const type = split(items[3], ': ')?.[1];
      const dns_name = split(items[4], ': ')?.[1];
      return {
        label: dns_name || name || id,
        value: uuid,
        icon: getIcon(type),
      };
    });
    setInstanceOptions([{ label: 'Nodes', options }]);
    setInstance(options?.[0]);
  }, [resourceInsight.data]);

  const columns = [
    {
      header: <Box pl={12}>Name</Box>,
      accessor: 'name',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={IconTypes.App}
          bgColor={'skyBlue.300'}
          useCustomColor
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Type',
      accessor: 'connection_type',
      render: ({ value }) => toTitleCase(value),
      align: 'left',
    },
    {
      header: 'System',
      render: ({ row }) => {
        return row.system_process ? 'Yes' : 'No';
      },
    },
    {
      header: 'External Access',
      render: ({ row }) => {
        return (
          <Center>
            <Text>{row.external_access ? 'Yes' : 'No'}</Text>
          </Center>
        );
      },
    },
    {
      header: 'Data Access',
      render: ({ row }) => {
        return row.data_access ? 'Yes' : 'No';
      },
    },
    {
      header: 'Context',
      render: ({ row }) => {
        return (
          <Center>
            {row.context.map(ctx => {
              if (isObject(ctx)) {
                if (ctx?.['encrypted'] === 'False') {
                  return (
                    <AriksaIcon
                      bg="critical"
                      icon={<UnencryptedDiskIcon />}
                      tooltip="Unencrypted connection"
                      tooltipProps={{ w: 32, textAlign: 'center' }}
                    />
                  );
                }
                if (ctx?.['encrypted'] === 'True') {
                  return (
                    <AriksaIcon
                      bg={customTheme.colors.green['300']}
                      useCustomColor
                      icon={<LockCloseIcon />}
                      tooltip="Encrypted connection"
                      tooltipProps={{ w: 32, textAlign: 'center' }}
                    />
                  );
                }

                if (ctx?.['malicious'] === 'True') {
                  return (
                    <AriksaIcon bg="critical" icon={<MaliciousIngressIcon />} />
                  );
                }
              }
              return '-';
            })}
          </Center>
        );
      },
    },
  ];

  const getRunningProcess = useCallback(
    (resourceUuid, pageInfo = INIT_PAGE_INFO) => {
      console.log('running process');
      dispatch(
        actions.getRunningProcesses({
          q: {
            environmentId,
            resourceUuid,
          },
          page: pageInfo,
        }),
      );
    },
    [dispatch, environmentId],
  );

  useEffect(() => {
    if (resourceType === NativeResources.ElasticKubernetesService) {
      !!instance?.value && getRunningProcess(instance?.value);
    } else getRunningProcess(resourceUuid);
  }, [getRunningProcess, resourceUuid, instance, resourceType]);

  return (
    <Stack w="full" h="full" spacing={4}>
      <HStack justify="end" w="full">
        {resourceType === NativeResources.ElasticKubernetesService && (
          <Box w={60} zIndex={900}>
            <Select
              options={instanceOptions}
              value={instance}
              onChange={setInstance}
              showIconInValueContainer
              styles={styles}
              menuPortalTarget={document.body}
              leftMessage="Node:"
            />
          </Box>
        )}
        {/*<Box w={56} zIndex={900}>
          <Select
            options={[
              {
                label: 'All Types',
                value: '',
              },
              {
                label: 'OS',
                value: PackageCategory.OsPackage,
              },
              {
                label: 'Library',
                value: PackageCategory.LibraryPackage,
              },
              {
                label: 'Technology',
                value: PackageCategory.ApplicationPackage,
              },
            ]}
            // value={filters.type ?? { label: 'All Types', value: '' }}
            // onChange={s => updateFilter('type', s)}
            showIconInValueContainer
            styles={styles}
            menuPortalTarget={document.body}
          />
        </Box>*/}
      </HStack>
      <Box flex={1} h="full">
        <Table
          isLoading={runningProcesses.isLoading}
          columns={columns}
          data={runningProcesses.data ?? []}
          subComponent={({ row, expanded }) => {
            return expanded && <ProcessConnections row={row} />;
          }}
        />
      </Box>
    </Stack>
  );
};
