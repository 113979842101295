/**
 *
 * Asynchronously loads the component for IdentityOverview
 *
 */

import { lazy } from 'react';

export const IdentityOverview = lazy(() =>
  import('./index').then(m => ({ default: m.IdentityOverview })),
);
