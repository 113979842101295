import React from 'react';

import { Box, Center, HStack, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IdeaGlitterIcon, VulnerabilityIcon } from 'components/Icons';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { tdStyles } from 'containers/Dashboard/utils/styles';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { formatDate } from 'utils/date';

export const TopNewVulnerabilities: React.FC = () => {
  const { topNewVulnerabilities } = useSelector(selectVulnerabilityOverview);
  const navigate = useNavigate();

  return (
    <DashboardOverviewCard
      label="Most recent software vulnerabilities (Last 30 days)"
      icon={<IdeaGlitterIcon />}
      isLoading={topNewVulnerabilities.isLoading}
      content={
        isEmpty(topNewVulnerabilities.data) ? (
          <NoDataAvailable />
        ) : (
          <Table w="full" h="full" size="sm">
            <Tbody>
              {map(topNewVulnerabilities.data, (o, index) => (
                <Tr key={index + '-key-top-new-vulnerabilities'}>
                  <Td {...tdStyles}>
                    <HStack spacing={2}>
                      <Box boxSize={5} color="critical">
                        <Center>
                          <VulnerabilityIcon />
                        </Center>
                      </Box>
                      <Box
                        fontWeight={600}
                        cursor="pointer"
                        onClick={() =>
                          navigate(
                            '/visibility/vulnerability?tab=cve&search_term=' +
                              o.vulnerability_id,
                          )
                        }
                      >
                        {o.vulnerability_id}
                      </Box>
                    </HStack>
                  </Td>
                  <Td {...tdStyles}>
                    <Box>{o?.cvss_score ?? '-'}</Box>
                  </Td>
                  <Td {...tdStyles}>
                    <HStack spacing={1}>
                      <Box fontWeight={600}>{o?.occurrences}</Box>
                      <Box>occurrences</Box>
                    </HStack>
                  </Td>

                  <Td {...tdStyles}>
                    <HStack>
                      <CustomTooltip
                        label={
                          o.patch_available
                            ? 'Patch available'
                            : 'Patch not available'
                        }
                        width={20}
                        textAlign="center"
                      >
                        <Box
                          boxSize={2.5}
                          borderRadius={2}
                          bg={o.patch_available ? 'green.300' : 'critical'}
                        />
                      </CustomTooltip>
                      <Box>{formatDate(o.last_seen, 'DD-MMM-YY')}</Box>
                    </HStack>
                  </Td>
                </Tr>
              ))}
              {renderEmptyRows(topNewVulnerabilities.data?.length)}
            </Tbody>
          </Table>
        )
      }
      iconBgColor="critical"
    />
  );
};
