/**
 *
 * Teams Form
 *
 */

import React, { FC } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiAddClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../slice';

import { TeamsForm } from './TeamsForm';

export const CreateTeams: FC = () => {
  const { onCancel } = useIntegrationsContext();
  const dispatch = useDispatch();

  const onSubmit = data => {
    const payload: ClientsApiAddClientRequest = {
      clientConfig: {
        client_name: Clients.Teams,
        name: data.name,
        status: true,
        teams: {
          ccard: data.ccard,
          team: data.team,
          channel: data.channel,
        },
      },
    };
    dispatch(actions.createClient({ q: payload, onSuccess: () => onCancel() }));
  };

  return <TeamsForm handleSubmit={onSubmit} />;
};
