import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { actions } from 'containers/Setup/Environment/slice';

import { EnvironmentWizard } from './EnvironmentWizard';

interface Props {}

export const CreateEnvironment: FC<Props> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetFormData());
  }, [dispatch]);

  return <EnvironmentWizard actionType="Create" />;
};
