import { each } from 'lodash';

export const traverseBfsByDepth = (
  sourceId: string,
  nodes: string[],
  edges: Record<string, Set<string>>,
  excludeIds: string[],
) => {
  const visited = new Set([sourceId]);
  const excluded = new Set(excludeIds);
  const Q: string[] = [sourceId];
  let count = 0;
  // console.log('excluded', excludeIds);
  // console.log(nodes, edges);
  while (Q.length) {
    if (count > 100) {
      console.error('exceeded maximum graph depth');
      break;
    }
    count += 1;

    const u = Q.shift()!;
    each(Array.from(edges[u] ?? []), v => {
      if (excluded.has(v)) return;
      if (visited.has(v)) return;
      visited.add(v);
      Q.push(v);
    });
  }

  return {
    visited,
  };
};
