import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AccountRootIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    bg="inherit"
    {...props}
  >
    <g>
      <g>
        <path
          d="M242.8,304.2H101.3C45.4,304.2,0,349.8,0,405.8v42.8c0,8.8,7.2,16,16,16s16-7.2,16-16v-42.8
			c0-38.4,31.1-69.6,69.3-69.6h132.5C235.7,325.1,238.8,314.4,242.8,304.2z"
        />
        <path
          d="M186.5,250.6c55.8,0,101.3-45.6,101.3-101.6c0-56-45.4-101.6-101.3-101.6S85.3,93,85.3,149
			C85.3,205,130.7,250.6,186.5,250.6z M186.5,79.4c38.2,0,69.3,31.2,69.3,69.6c0,38.4-31.1,69.6-69.3,69.6s-69.3-31.2-69.3-69.6
			C117.2,110.6,148.3,79.4,186.5,79.4z"
        />
      </g>
      <g>
        <path d="M395.5,324.8l-48,25.6l-45.2-25.6l19.9,92.1h146.6l19.9-92.1l-45.2,25.6L395.5,324.8z" />
        <path d="M323.9,432.3h143.3v28.1H323.9V432.3z" />
        <path
          d="M320,289c0,11.3-9.2,20.5-20.5,20.5c-11.3,0-20.5-9.2-20.5-20.5c0-11.3,9.2-20.5,20.5-20.5
			C310.9,268.5,320,277.7,320,289z"
        />
        <path
          d="M512,289c0,11.3-9.2,20.5-20.5,20.5c-11.3,0-20.5-9.2-20.5-20.5c0-11.3,9.2-20.5,20.5-20.5
			C502.8,268.5,512,277.7,512,289z"
        />
        <path
          d="M416,289c0,11.3-9.2,20.5-20.5,20.5c-11.3,0-20.5-9.2-20.5-20.5c0-11.3,9.2-20.5,20.5-20.5
			C406.9,268.5,416,277.7,416,289z"
        />
      </g>
    </g>
  </Icon>
);
