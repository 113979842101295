import React, { FC } from 'react';

import { useDispatch } from 'react-redux';

import { OrganizationLoginInput } from 'containers/HomePage/OrganizationLoginInput';

import { actions as appActions } from '../App/slice';

interface IOrganization {}

export const Organization: FC<IOrganization> = props => {
  const dispatch = useDispatch();

  const handleSubmit = data => {
    dispatch(appActions.setOrganization(data.organization));
  };

  return <OrganizationLoginInput handleSubmit={handleSubmit} />;
};
