import React, { FC, useEffect, useState } from 'react';

import { Categories } from '@ariksa/inventory-core';
import { AppSchemasInventoryResourceCountDeploymentSummary } from '@ariksa/inventory-core/api';
import { Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { InstanceInventory } from 'containers/Inventory/CloudInventory/Components/InstanceInventory';
import { InventoryTableTitle } from 'containers/Inventory/CloudInventory/Components/InventoryTableTitle';
import { InventorySummary } from 'containers/Inventory/CloudInventory/Components/Summary/InventorySummary';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';
import { useCategoryTableColumns } from '../hooks/useCategoryTableColumns';

export const CategoryInventory: FC = () => {
  const dispatch = useDispatch();
  const {
    deploymentSummary,
    currentCategory,
    categories,
    currentCategoryTitle,
    instanceResourceType,
  } = useSelector(selectInventory);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { filterDeploymentSummary } = useInventory();
  const {
    categoryTableColumns,
    isOpenEC2Summary,
    setIsOpenEC2Summary,
    type,
  } = useCategoryTableColumns();
  const { getCloudNativeName } = useResourceType();
  const { withAccessBoundary } = useAccessBoundary();
  const [data, setData] = useState<
    AppSchemasInventoryResourceCountDeploymentSummary[]
  >([]);

  useDeepCompareEffect(() => {
    if (!!accountId || !!environmentId) {
      dispatch(
        actions.getDeploymentSummary({
          q: withAccessBoundary({
            category: currentCategory as Categories,
          }),
        }),
      );
    }
  }, [dispatch, currentCategory, withAccessBoundary]);

  useEffect(() => {
    setData(filterDeploymentSummary());
  }, [filterDeploymentSummary]);

  const handleRowClick = row => {
    dispatch(actions.setNativeResource(row.native_resource));
    dispatch(actions.resetSearchItems());
  };

  return !!instanceResourceType ? (
    <InstanceInventory />
  ) : (
    <Stack spacing={4} h="full">
      <InventoryTableTitle
        title={currentCategoryTitle}
        iconType={currentCategory?.split('_')?.[0]}
        value={
          <Box>
            {deploymentSummary?.isLoading || categories?.isLoading
              ? 0
              : data?.length}
            {data?.length > 1 ? ' Entities' : ' Entity'}
          </Box>
        }
      />

      <Table
        columns={categoryTableColumns}
        data={data}
        onRowClick={handleRowClick}
        isLoading={deploymentSummary?.isLoading || categories?.isLoading}
        cursor="pointer"
      />
      {isOpenEC2Summary && (
        <InventorySummary
          type={type}
          isOpen={isOpenEC2Summary}
          onClose={() => setIsOpenEC2Summary(false)}
          header={getCloudNativeName(type)}
        />
      )}
    </Stack>
  );
};
