import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { useResourceType } from 'containers/App/hooks/useResourceType';
import { FilterField } from 'containers/Visibility/Components/Filter/FilterField';
import { GraphFilterBox } from 'containers/Visibility/SecurityGraph/Components/utils';

export interface ResourceContext {
  name: string;
  value: string;
}

export interface ContextFilterItemEntry {
  key: string;
  resourceType: string;
  context: ResourceContext[];
}

interface IContextFilterItem {
  filters: ContextFilterItemEntry[];
  onRemove?(entry: ContextFilterItemEntry);
}

export const ContextFilterItems: FC<IContextFilterItem> = props => {
  const { filters, onRemove } = props;
  const { getResourceAlias } = useResourceType();

  return (
    <Stack spacing={4} fontSize="xs">
      {filters.map(f => {
        return (
          <GraphFilterBox onRemove={() => onRemove?.(f)}>
            <Stack>
              <FilterField
                field={'Resource type'}
                value={getResourceAlias(f.resourceType)!}
              />
              {f.context && (
                <FilterField field={'Context tags'} value={f.context} />
              )}
            </Stack>
          </GraphFilterBox>
        );
      })}
    </Stack>
  );
};
