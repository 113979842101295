import React from 'react';

import { HStack, Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { getIcon } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';

export const renderCompliance = (data: string[]) => {
  return !isEmpty(data) ? (
    <HStack spacing={2}>
      {data.map((co, index) => (
        <CustomTooltip label={co} key={index + '-' + co}>
          <Box boxSize={6}>{getIcon(co)}</Box>
        </CustomTooltip>
      ))}
    </HStack>
  ) : (
    '-'
  );
};
