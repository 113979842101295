import React, { FC, useEffect } from 'react';

import { LoginActivityType } from '@ariksa/scan-analysis/api';
import { Box, Stack, Text, HStack, Accordion } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNetworkResourceMaliciousEgress } from 'services/Network/useNetworkService';
import { useScanAnalysisItemService } from 'services/ScanAnalysis/useScanAnalysisItemService';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { ResourceActionsPatternAccordion } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/ResourceActionsPatternAccordion';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { formatDate } from 'utils/date';

export const VMActivity: FC = () => {
  const { resourceId } = useActiveResourceContext();
  const { resourceUuid, resourceAccountId } = useSelector(selectActiveResource);
  const {
    instanceTechnologyById: successfulLogin,
  } = useScanAnalysisItemService(
    resourceAccountId,
    resourceId,
    LoginActivityType.Success,
  );
  const { instanceTechnologyById: vmLogin } = useScanAnalysisItemService(
    resourceAccountId,
    resourceId,
    LoginActivityType.VmLogin,
  );
  const { instanceTechnologyById: failedLogin } = useScanAnalysisItemService(
    resourceAccountId,
    resourceId,
    LoginActivityType.Failed,
  );

  const { resourceMaliciousEgress } = useNetworkResourceMaliciousEgress(
    resourceAccountId,
    resourceUuid,
  );

  const { accessed } = useSelector(selectActiveResource);

  useEffect(() => {
    successfulLogin.sync({});
    vmLogin.sync({});
    failedLogin.sync({});
  }, [failedLogin, resourceAccountId, successfulLogin, vmLogin]);

  const failedLoginColumns: TableColumnProps[] = [
    {
      header: 'Source IP',
      accessor: 'host',
    },
    { header: 'Username', accessor: 'username' },
    { header: 'No. Of Attempts', accessor: 'number_of_attempts' },
    {
      header: 'Last Timestamp',
      accessor: 'last_attempt_time',
      render: ({ value }) => formatDate(value),
    },
  ];

  const vmLoginColumns: TableColumnProps[] = [
    {
      header: 'Destination IP',
      accessor: 'host',
    },
    { header: 'Username', accessor: 'username' },
    {
      header: 'Last Timestamp',
      accessor: 'last_attempt_time',
      render: ({ value }) => formatDate(value),
    },
  ];

  const successFullLoginColumns: TableColumnProps[] = [
    {
      header: 'Source IP',
      accessor: 'host',
    },
    { header: 'Username', accessor: 'username' },
    {
      header: 'Login Timestamp',
      accessor: 'login_time',
      render: ({ value }) => formatDate(value),
    },
    {
      header: 'Logout Timestamp',
      accessor: 'logout_time',
      render: ({ value }) => formatDate(value),
    },
  ];

  const internetAccessColumns: TableColumnProps[] = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Destination IP</Box>,
      accessor: 'destination_ip',
      render: ({ value }) => (
        <WithResourceIcon resourceType={'Internet'}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    { header: 'Destination Port', accessor: 'destination_port' },
    { header: 'No. Of Sources', accessor: 'sources' },
    { header: 'Data Transfer', render: () => '-' },
    {
      header: 'Last Timestamp',
      accessor: 'last_timestamp',
      render: ({ value }) => {
        return formatDate(value);
      },
    },
  ];

  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={8}>
      {/*<HStack>*/}
      {/*  <AriksaIcon type={'EventLog'} bg="primary" />*/}
      {/*  <Text>*/}
      {/*    LATERAL MOVEMENT TO VIRTUAL MACHINES: {vmLogin.data?.length ?? 0}*/}
      {/*  </Text>*/}
      {/*</HStack>*/}
      {/*<Box h={'300px'} w={'full'}>*/}
      {/*  <VMActivityMap vms={vmLogin.data ?? []} />*/}
      {/*</Box>*/}

      <Box>
        <Accordion allowMultiple defaultIndex={[0]}>
          <ResourceActionsPatternAccordion />
          <MetaAccordionItem
            iconType={IconTypes.Credential}
            label={'SUCCESSFUL SSH LOGINS FROM THIS VIRTUAL MACHINE'}
            onClickDownload={() => {}}
            panel={
              <Table
                isLoading={vmLogin.isLoading}
                columns={vmLoginColumns}
                data={vmLogin.data ?? []}
              />
            }
            totalCount={vmLogin.data?.length}
            iconBGColor="green"
          />
          <MetaAccordionItem
            iconType={IconTypes.Credential}
            label={'UNSUCCESSFUL SSH LOGINS INTO THIS VIRTUAL MACHINE'}
            onClickDownload={() => {}}
            panel={
              <Table
                isLoading={failedLogin.isLoading}
                columns={failedLoginColumns}
                data={failedLogin.data ?? []}
              />
            }
            totalCount={failedLogin.data?.length}
            iconBGColor="critical"
          />
          <MetaAccordionItem
            iconType={IconTypes.Credential}
            label={'SUCCESSFUL SSH LOGINS INTO THIS VM'}
            onClickDownload={() => {}}
            panel={
              <Table
                columns={successFullLoginColumns}
                data={successfulLogin.data ?? []}
                isLoading={successfulLogin.isLoading}
              />
            }
            totalCount={successfulLogin.data?.length}
            iconBGColor="green"
          />
        </Accordion>
      </Box>
    </Stack>
  );
};
