import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CrossAccountIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M442.8,186.5c-7.5,0-14.8,1.2-21.7,3.5c-12.7-45.6-54.3-77.8-102.5-77.8
	c-47.6,0-89.5,32.1-102.3,77.3c-7.3-1.6-14.9-2.5-22.7-2.5c-48.2,0-89.9,32.2-102.5,77.8c-7-2.3-14.2-3.5-21.7-3.5
	C31.1,261.3,0,292.4,0,330.6c0,38.2,31.1,69.3,69.2,69.3H301c32,0,57.9-26,57.9-58c0-5.9-0.9-11.5-2.5-16.9h86.3
	c38.2,0,69.2-31.1,69.2-69.2S480.9,186.5,442.8,186.5z M301,383.8H69.2c-29.4,0-53.2-23.9-53.2-53.3c0-29.4,23.9-53.2,53.2-53.2
	c8.4,0,16.4,1.9,23.8,5.6c2.3,1.1,4.9,1.1,7.2,0c2.3-1.1,3.9-3.2,4.3-5.7c7.8-43,45.2-74.2,89-74.2c49.5,0,90.1,40.3,90.4,89.8
	c0,2.4,1.1,4.6,2.9,6.1c1.8,1.5,4.2,2.1,6.6,1.7c2.6-0.5,5.2-0.7,7.7-0.7c23.1,0,41.9,18.8,41.9,41.9C343,365,324.2,383.8,301,383.8
	z M442.8,309h-94c-10.7-15.5-28.9-25.5-49.3-25.1c-3.6-41.1-30.9-75.5-67.9-89.8c10.8-38.5,46.4-65.9,87-65.9
	c43.8,0,81.2,31.2,89,74.2c0.5,2.5,2,4.6,4.3,5.7c2.3,1.1,4.9,1.1,7.2,0c7.4-3.7,15.4-5.6,23.8-5.6c29.4,0,53.2,23.9,53.2,53.2
	C496,285.1,472.1,309,442.8,309z"
    />
  </Icon>
);
