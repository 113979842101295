import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AzureActiveDirectoryIcon = props => (
  <Icon viewBox="0 0 18 18" color="#00bef2" w="full" h="full" {...props}>
    <defs>
      <linearGradient
        id="ba2610c3-a45a-4e7e-a0c0-285cfd7e005d"
        x1="13.25"
        y1="13.02"
        x2="8.62"
        y2="4.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#1988d9" />
        <stop offset="0.9" stopColor="#54aef0" />
      </linearGradient>
      <linearGradient
        id="bd8f618b-4f2f-4cb7-aff0-2fd2d211326d"
        x1="11.26"
        y1="10.47"
        x2="14.46"
        y2="15.99"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="#54aef0" />
        <stop offset="0.29" stopColor="#4fabee" />
        <stop offset="0.51" stopColor="#41a2e9" />
        <stop offset="0.74" stopColor="#2a93e0" />
        <stop offset="0.88" stopColor="#1988d9" />
      </linearGradient>
    </defs>
    <title>Icon-identity-221</title>
    <polygon
      points="1.01 10.19 8.93 15.33 16.99 10.17 18 11.35 8.93 17.19 0 11.35 1.01 10.19"
      fill="#50e6ff"
    />
    <polygon
      points="1.61 9.53 8.93 0.81 16.4 9.54 8.93 14.26 1.61 9.53"
      fill="#fff"
    />
    <polygon points="8.93 0.81 8.93 14.26 1.61 9.53 8.93 0.81" fill="#50e6ff" />
    <polygon
      points="8.93 0.81 8.93 14.26 16.4 9.54 8.93 0.81"
      fill="url(#ba2610c3-a45a-4e7e-a0c0-285cfd7e005d)"
    />
    <polygon points="8.93 7.76 16.4 9.54 8.93 14.26 8.93 7.76" fill="#53b1e0" />
    <polygon
      points="8.93 14.26 1.61 9.53 8.93 7.76 8.93 14.26"
      fill="#9cebff"
    />
    <polygon
      points="8.93 17.19 18 11.35 16.99 10.17 8.93 15.33 8.93 17.19"
      fill="url(#bd8f618b-4f2f-4cb7-aff0-2fd2d211326d)"
    />
  </Icon>
);
