import React, { FC, useEffect } from 'react';

import { RiskRatingSeverity } from '@ariksa/compliance-policies/api';
import { Box, HStack, Stack, Center, Flex } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useCompliancePermissionsService } from 'services/ComplianceService/useInventoryPermissionsService';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { CheckmarkCircleOutlineIcon } from 'components/Icons';
import { CustomIconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { S3RiskAnalysisRadarChart } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3RiskAnalysisRadarChart';

interface Props {
  accountId: string;
  resourceUuid: string;
  snapshotId?: string;
  isLoading?: boolean;
}

export const S3RiskAnalysis: FC<Props> = props => {
  const { accountId, resourceUuid, isLoading } = props;
  const { riskAnalysis } = useCompliancePermissionsService();

  useEffect(() => {
    !!accountId &&
      !!resourceUuid &&
      riskAnalysis.sync({
        accountId,
        resourceUuid,
      });
  }, [riskAnalysis, accountId, resourceUuid]);

  const getRatingColor = type => {
    switch (type) {
      case RiskRatingSeverity.HighRisk:
      case 4:
        return customTheme.colors.critical;
      case RiskRatingSeverity.MediumRisk:
      case 3:
        return customTheme.colors.medium;
      case RiskRatingSeverity.LowRisk:
      case 2:
        return customTheme.colors.green['300'];
      default:
        return customTheme.colors.gray['100'];
    }
  };

  const renderRating = () => {
    const overallRisk = riskAnalysis.data?.overall_risk;
    return (
      <HStack w="full" justify="flex-end">
        {!!overallRisk && <Box whiteSpace="nowrap">Overall rating</Box>}
        <Center
          bg={getRatingColor(overallRisk)}
          borderRadius={4}
          color="white"
          minW={24}
        >
          {overallRisk}
        </Center>
      </HStack>
    );
  };

  return (
    <MetaAccordionItem
      iconType={CustomIconTypes.ExclamationTriangle}
      label="RISK ANALYSIS FOR THIS BUCKET"
      isLoading={riskAnalysis.isLoading || isLoading}
      onClickDownload={() => {}}
      height="400px"
      expandOnClickViewAll
      rightHeader={isExpanded =>
        isExpanded ? '' : <Flex>{renderRating()}</Flex>
      }
      panel={
        <>
          {riskAnalysis.isLoading && (
            <Center w="full" h="full">
              <CustomSpinner loading={riskAnalysis.isLoading} />
            </Center>
          )}
          {!riskAnalysis.isLoading && isEmpty(riskAnalysis.data) && (
            <Center w="full" h="full">
              <NoDataAvailable />
            </Center>
          )}
          {!riskAnalysis.isLoading && !isEmpty(riskAnalysis.data) && (
            <Stack
              w="full"
              h="full"
              justify="space-between"
              isInline
              overflow="auto"
            >
              <Box w="50%" h="full">
                <Stack>
                  {map(riskAnalysis.data?.risks, o => (
                    <HStack
                      borderRadius={6}
                      border="1px solid"
                      borderColor={customTheme.colors.gray['100']}
                      p={2}
                      w="full"
                    >
                      <Center boxSize={4}>
                        <CheckmarkCircleOutlineIcon
                          isChecked={o?.status === 'Rule_Pass'}
                          color={
                            o?.status === 'Rule_Pass'
                              ? 'green'
                              : customTheme.colors.gray['200']
                          }
                        />
                      </Center>
                      <Box>{o?.description}</Box>
                    </HStack>
                  ))}
                </Stack>
              </Box>
              <Stack w="50%" h="full" spacing={0}>
                <Stack align="flex-end" pr={6}>
                  {renderRating()}
                </Stack>
                <S3RiskAnalysisRadarChart data={riskAnalysis?.data ?? {}} />
              </Stack>
            </Stack>
          )}
        </>
      }
      iconBGColor="critical"
    />
  );
};
