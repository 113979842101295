import React, { FC, useCallback, useEffect, useReducer, useRef } from 'react';

import {
  NativeResources,
  VulnerabilitiesApiGetVulnerabilitiesRequest,
} from '@ariksa/inventory-core/api';
import { Stack, HStack, Divider, Box, Link } from '@chakra-ui/react';
import { forEach, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';

import { InventoryService } from 'api/services';
import {
  DetailsIdentifier,
  StackedHeader,
  timeLabel,
} from 'components/DataDisplay';
import {
  getPublishedTime,
  publishedAtOptions,
  Select,
  SelectHistoricalOptions,
} from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { Drawer, Modal } from 'components/Overlay';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectActiveEnvironment } from 'containers/App/selectors';
import { useVulnerabilityTableColumns } from 'containers/Visibility/Vulnerabilities/Components/hooks/useVulnerabilityTableColumns';
import { renderPackageType } from 'containers/Visibility/Vulnerabilities/Components/hooks/utils';
import {
  packageTypeOptions,
  patchAvailableOptions,
  statusOptions,
} from 'containers/Visibility/Vulnerabilities/Components/utils';
import { CVEHostsTable } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/CVEHostsTable';
import { PackagesHostsDrawerDetails } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilityTable/PackagesHostsDrawerDetails';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';
import { actions } from 'containers/Visibility/Vulnerabilities/slice';
import { limitedString, toTitleCase } from 'utils/string';

interface Props {
  row: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
  selectedTab: string;
}

export const CVEHostsDrawer: FC<Props> = props => {
  const { row, isOpen, onClose, selectedTab } = props;
  const dispatch = useDispatch();
  const { tablesTabIndex, cveHosts } = useSelector(selectVulnerabilities);

  const { withAccessBoundary } = useAccessBoundary();
  const { tableActionModals } = useVulnerabilityTableColumns();
  const { environmentId } = useSelector(selectActiveEnvironment);

  const ref = useRef(document.querySelector('.portal-container'));

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      historicalTime: publishedAtOptions[0],
      packageName: {},
      packageType: packageTypeOptions(selectedTab)[0],
      isPatchable: patchAvailableOptions[0],
      sortByField: {
        sortField: '',
        sortOrder: '',
      },
      showMoreLinks: false,
      status: statusOptions[0],
    },
  );

  const updateValue = useCallback((field, value) => {
    updateState({ [field]: value });
  }, []);

  //set resource type
  useEffect(() => {
    const onSuccess = res => {
      const data: Record<string, any[]> = {};
      forEach(res?.items, o =>
        o?.native_name
          ? !!data[o?.native_name]
            ? data[o?.native_name].push(o)
            : (data[o.native_name] = [o])
          : '',
      );

      forEach(data, (items, key) => {
        const payload: VulnerabilitiesApiGetVulnerabilitiesRequest = {
          resourceVulnerabilitiesRequest: {
            resource_ids: map(items, o => o?.uuid),
            native_resource: key as NativeResources,
          },
        };
        dispatch(
          actions.getCveHostsVulnerabilities({
            q: payload,
            status: QueryStatus.pending,
          }),
        );
        InventoryService.Vulnerability.getVulnerabilities(payload).then(res => {
          dispatch(
            actions.getCveHostsVulnerabilities({
              q: payload,
              status: QueryStatus.fulfilled,
              data: res.data,
            }),
          );
        });
      });

      /*get risk context*/
      dispatch(
        actions.getCveHostsRiskContext({
          q: { riskContextRequest: { uuids: map(res?.items, o => o?.uuid) } },
        }),
      );
    };

    const commonPayload = {
      resolved: state.status?.value,
      isPatchable: !!state.isPatchable?.value
        ? state.isPatchable?.value === 'yes'
        : undefined,
      packageCategory: state.packageType?.value,
      ...(state.status?.value
        ? { resolvedAt: getPublishedTime(state.historicalTime?.value) }
        : { effectiveStart: getPublishedTime(state.historicalTime?.value) }),
      page: cveHosts.page.info.page_number,
      size: cveHosts.page.info.page_size,
    };

    if (selectedTab === 'cve')
      dispatch(
        actions.getCveHosts({
          q: {
            environmentId: environmentId,
            cveId: row.vulnerability_id,
            ...commonPayload,
          },
          onSuccess,
        }),
      );
    else
      dispatch(
        actions.getPackageHosts({
          q: withAccessBoundary({
            name: row?.package_name,
            version: row?.installed_version,
            ...commonPayload,
          }),
          onSuccess,
        }),
      );
  }, [
    state.status,
    state.isPatchable,
    row,
    dispatch,
    withAccessBoundary,
    tablesTabIndex,
    state.historicalTime,
    cveHosts.page.info,
    selectedTab,
    state.packageType,
    environmentId,
  ]);

  const renderCVEHeader = () =>
    (row?.cvss_score
      ? 'CVSS 3.0 SCORE: ' + Number(row?.cvss_score).toFixed(1) + ' '
      : '') +
    '(Severity: ' +
    toTitleCase(row?.severity) +
    ')';

  const renderPackagesHeader = () =>
    'Version: ' +
    row?.installed_version +
    ', Type: ' +
    renderPackageType(row?.package_category);

  const styles = {
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
      right: 0,
    }),
  };

  useEffect(() => {
    dispatch(actions.resetFilters());
  }, [dispatch]);

  const header = (
    <StackedHeader
      upper={row?.vulnerability_id ?? row?.package_name}
      lower={selectedTab === 'cve' ? renderCVEHeader() : renderPackagesHeader()}
      type={
        selectedTab === 'cve'
          ? IconTypes.ExclamationTriangle
          : IconTypes.Packages
      }
      bg={row?.severity}
    />
  );

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        header={header}
        body={
          <Stack w="full" h="full" pt={3} spacing={5}>
            {selectedTab === 'cve' && (
              <HStack align="flex-start" spacing={10}>
                <Box w={'70%'} overflow="hidden">
                  <DetailsIdentifier
                    label={'Description'}
                    direction="column"
                    value={
                      <Box h="110px" overflow="scroll">
                        {row?.description}
                      </Box>
                    }
                  />
                </Box>
                <Stack w="30%" justify="space-between" h="full">
                  <DetailsIdentifier
                    label="Known Exploits"
                    value={!!row?.exploitable ? 'Yes' : 'No'}
                  />
                  <DetailsIdentifier
                    label="Requires network access"
                    value={
                      row?.cvss_v3?.attack_vector === 'Network' ? 'Yes' : 'No'
                    }
                  />
                  <DetailsIdentifier
                    label="Last Published"
                    value={timeLabel(row?.last_modified_date)}
                  />
                  <DetailsIdentifier
                    label="First seen at"
                    value={timeLabel(row?.first_seen_at)}
                  />
                  <DetailsIdentifier
                    label="More information"
                    value={
                      <>
                        <HStack>
                          {row?.references?.[0] && (
                            <Link href={row?.references?.[0]} target="_blank">
                              <Box color="primary" cursor="pointer">
                                {limitedString(row?.references?.[0])}
                              </Box>
                            </Link>
                          )}
                          {row?.references?.length > 1 && <Box>,</Box>}
                          {row?.references?.length > 1 && (
                            <Box
                              bg="primary"
                              color="white"
                              borderRadius={6}
                              px={1}
                              onClick={() => updateValue('showMoreLinks', true)}
                              cursor="pointer"
                            >
                              + {row?.references?.length - 1}
                            </Box>
                          )}
                        </HStack>
                        <Modal
                          isOpen={state.showMoreLinks}
                          onClose={() => updateValue('showMoreLinks', false)}
                          size="2xl"
                          header={header}
                          body={
                            <Stack>
                              {map(row?.references, o => (
                                <Box color="primary">
                                  <Link href={o} target="_blank">
                                    {o}
                                  </Link>
                                </Box>
                              ))}
                            </Stack>
                          }
                        />
                      </>
                    }
                  />
                </Stack>
              </HStack>
            )}
            {selectedTab === IconTypes.Packages && (
              <PackagesHostsDrawerDetails row={row} />
            )}
            <Divider />
            <HStack justify={'end'}>
              <Box w={40} zIndex={900}>
                <Select
                  options={statusOptions}
                  value={state?.status}
                  onChange={s => updateValue('status', s)}
                  showIconInValueContainer
                  styles={styles}
                  menuPortalTarget={document.body}
                />
              </Box>

              {selectedTab === 'cve' && (
                <>
                  {/*patch status*/}
                  <Box w={56} zIndex={900}>
                    <Select
                      options={patchAvailableOptions}
                      value={state.isPatchable}
                      onChange={s => updateValue('isPatchable', s)}
                      // isLoading={alertCategories.isLoading}
                      leftMessage="Patchable:"
                      showIconInValueContainer
                      styles={styles}
                      menuPortalTarget={document.body}
                    />
                  </Box>

                  {/*all types*/}
                  <Box w={56} zIndex={900}>
                    <Select
                      options={packageTypeOptions('cve')}
                      value={state.packageType}
                      onChange={s => updateValue('packageType', s)}
                      showIconInValueContainer
                      styles={styles}
                      leftMessage="Package type:"
                      menuPortalTarget={document.body}
                    />
                  </Box>
                </>
              )}

              <SelectHistoricalOptions
                historicalTime={state.historicalTime}
                setHistoricalTime={s => updateValue('historicalTime', s)}
                width={40}
              />
            </HStack>
            <ActiveResourceProvider>
              <CVEHostsTable
                sortByField={state.sortByField}
                setSortByField={s => updateValue('sortByField', s)}
              />
              {tableActionModals}
            </ActiveResourceProvider>
          </Stack>
        }
        closeButton
        styles={{
          content: { maxW: '1400px' },
          drawer: { portalProps: { containerRef: ref as any } },
        }}
      />
    </>
  );
};
