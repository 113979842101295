/**
 *
 * Setup Groups
 *
 */

import React, { memo, useCallback, useEffect } from 'react';

import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import {
  ActionButton,
  DeleteActionButton,
  FilterComponent,
  GroupIcon,
  IconTypes,
  PageHeaderWithIcon,
  renderNameWithResourceIcon,
  StackedCell,
  usePageContentContext,
} from 'app/components';
import { Card } from 'app/components/DataDisplay/Card';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { AddButton } from 'app/components/DataEntry/Button';
import { EditIcon } from 'app/components/Icons';
import { OverflowTooltip } from 'containers/Setup/Groups/OverFlowTooltip';
import { setupUsersSaga } from 'containers/Setup/Users/saga';
import * as userSlice from 'containers/Setup/Users/slice';
import { formatDate } from 'utils/date';

import { selectUser } from '../../App/selectors';

import { selectGroups } from './selectors';
import { actions } from './slice';

export const GroupsTable = memo(() => {
  const { groups, groupAction } = useSelector(selectGroups);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const getGroups = useCallback(() => {
    if (!user.info.org_id) return;
    console.log('load groups');
    dispatch(actions.loadGroups({}));
    dispatch(userSlice.actions.loadUsers());
  }, [dispatch]);

  useDeepCompareEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleDelete = row => {
    dispatch(actions.deleteGroup({ q: row, onSuccess: () => getGroups() }));
  };

  const isAdmin = row => row?.name === 'OrganizationAdmin';

  const renderActions = ({ row }) => {
    return (
      <HStack spacing={0} justifyContent="center">
        <ActionButton
          label="View"
          icon={<RiEyeLine />}
          onClick={() => navigate(`/setup/groups/view/${row.id}`)}
        />

        <ActionButton
          label="Edit"
          icon={<EditIcon />}
          onClick={() => navigate(`/setup/groups/edit/${row.id}`)}
          // isDisabled={row.immutable}
        />

        <DeleteActionButton
          name={row?.name}
          type="group"
          isDisabled={isAdmin(row)}
          label={
            isAdmin(row)
              ? 'Deleting this group is not permitted'
              : 'Delete this group'
          }
          isLoading={groupAction.isLoading}
          onConfirm={() => {
            if (!row.immutable) {
              handleDelete(row);
            }
          }}
        />
      </HStack>
    );
  };

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Group Name</Box>,
      accessor: 'name',
      align: 'left',
      render: ({ value, row }) => {
        return renderNameWithResourceIcon(
          IconTypes.Group,
          isAdmin(row) ? 'Organization Admin' : value,
        );
      },
    },
    {
      header: 'Roles',
      accessor: 'roles',
      render: ({ value }) => value.length,
      //align: 'right',
    },
    {
      header: 'Users',
      accessor: 'users',
      render: ({ value }) => value.length,
      //align: 'right',
    },
    {
      header: 'Accounts',
      accessor: 'users',
      render: ({ value, row }) => {
        return (
          <StackedCell
            upper={row?.account_ids.length ?? 0}
            lower={`Auto addition: ${row.auto_add_accounts ? 'On' : 'Off'}`}
          />
        );
      },
      align: 'left',
    },
    {
      header: 'Created',
      accessor: 'creator.username',
      align: 'left',
      render: ({ value, row }) => {
        return (
          <StackedCell
            upper={row.creator.username}
            lower={formatDate(row.creation_time)}
          />
        );
      },
    },
    {
      header: 'Updated',
      accessor: 'creator.username',
      align: 'left',
      render: ({ value, row }) => {
        return <StackedCell upper={'~'} lower={'~'} />;
      },
    },
    {
      header: 'Admin',
      accessor: 'admins',
      render: ({ value, row }) => {
        return (
          <StackedCell
            upper={row?.admins.length ?? 0}
            lower={
              <OverflowTooltip
                labels={
                  row?.admins?.map(a => a.first_name + ' ' + a.last_name) ?? []
                }
              />
            }
          />
        );
      },
    },
    {
      header: 'Actions',
      accessor: 'id',
      render: renderActions,
      align: 'center',
    },
  ];

  const { contentHeight } = usePageContentContext();

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <HStack w="full" justify="space-between">
          <PageHeaderWithIcon label="Groups" icon={<GroupIcon />} />
          <Flex>
            <FilterComponent filterPath={'/setup/groups/add'}>
              <AddButton
                label="Add Group"
                onClick={() => navigate('/setup/groups/add')}
              />
            </FilterComponent>
          </Flex>
        </HStack>

        <Box flex={1}>
          <Table
            data={groups.data}
            columns={columns}
            isLoading={groups.loading}
          />
        </Box>
      </Stack>
    </Card>
  );
});
