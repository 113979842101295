import {
  PortUsageSummary,
  ResourceApiGetNetworkingInsightsRequest,
  ResourceApiGetNoEncryptionCountsRequest,
  ResourceApiGetPubliclyAccessibleCountsRequest,
  ResourceCountsForInternalTag,
  ResourceSummaryApiGetVmPortOpenSummaryRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertsApiGetResolvedAlertSummaryRequest,
  AlertSummaryResponse,
  ResolvedSummaryResponse,
} from '@ariksa/notification/api';
import {
  DefaultApiGetInstanceSshSummaryRequest,
  InstanceSSHSummary,
} from '@ariksa/scan-analysis/api';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //infrastructure
  infrastructurePostureFindingsCategorySummary: QueryState.init([]),
  infrastructureThatLacksEncryption: QueryState.init([]),
  publiclyAccessibleSources: QueryState.init([]),
  vmPortUsage: QueryState.init([]),
  vmWithSSHTraffic: QueryState.init([]),
  insightsForCloudNetworking: QueryState.init({}),
  cloudPostureOpenAlerts: QueryState.init({} as AlertSummaryResponse),
  infrastructurePostureResolvedAlerts: QueryState.init(
    {} as ResolvedSummaryResponse,
  ),
};

const cloudPostureOverviewSlice = createSlice({
  name: 'cloudPostureOverview',
  initialState,
  reducers: {
    /*--------------------------Infrastructure posture------------------------*/
    getCloudPostureOpenAlerts(
      state,
      action: QueryAction<
        AlertSummaryResponse,
        AlertsApiGetAlertSummaryRequest
      >,
    ) {
      state.cloudPostureOpenAlerts = QueryState.next(
        state.cloudPostureOpenAlerts,
        action,
      );
    },

    getInfrastructurePostureResolvedAlerts(
      state,
      action: QueryAction<
        ResolvedSummaryResponse,
        AlertsApiGetResolvedAlertSummaryRequest
      >,
    ) {
      state.infrastructurePostureResolvedAlerts = QueryState.next(
        state.infrastructurePostureResolvedAlerts,
        action,
      );
    },

    getInfrastructurePostureFindingsCategorySummary(
      state,
      action: QueryAction<
        AggregatedAlertsSummary[],
        AlertsApiGetFindingsCategoriesSummaryRequest
      >,
    ) {
      state.infrastructurePostureFindingsCategorySummary = QueryState.next(
        state.infrastructurePostureFindingsCategorySummary,
        action,
      );
    },

    getInfrastructureThatLacksEncryption(
      state,
      action: QueryAction<
        ResourceCountsForInternalTag[],
        ResourceApiGetNoEncryptionCountsRequest
      >,
    ) {
      state.infrastructureThatLacksEncryption = QueryState.next(
        state.infrastructureThatLacksEncryption,
        action,
      );
    },

    getPubliclyAccessibleSources(
      state,
      action: QueryAction<
        ResourceCountsForInternalTag[],
        ResourceApiGetPubliclyAccessibleCountsRequest
      >,
    ) {
      state.publiclyAccessibleSources = QueryState.next(
        state.publiclyAccessibleSources,
        action,
      );
    },

    getVmPortUsage(
      state,
      action: QueryAction<
        PortUsageSummary[],
        ResourceSummaryApiGetVmPortOpenSummaryRequest
      >,
    ) {
      state.vmPortUsage = QueryState.next(state.vmPortUsage, action);
    },

    getVmWithSSHTraffic(
      state,
      action: QueryAction<
        InstanceSSHSummary[],
        DefaultApiGetInstanceSshSummaryRequest
      >,
    ) {
      state.vmWithSSHTraffic = QueryState.next(state.vmWithSSHTraffic, action);
    },

    getInsightsForCloudNetworking(
      state,
      action: QueryAction<
        Record<string, number>,
        ResourceApiGetNetworkingInsightsRequest
      >,
    ) {
      state.insightsForCloudNetworking = QueryState.next(
        state.insightsForCloudNetworking,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = cloudPostureOverviewSlice;
