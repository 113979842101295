import React from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';

import { Route53HostedZoneNetworkMap } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Network/Route53/Route53HostedZoneNetworkMap';

import { SecurityGroupNetworkTab } from '../../Network/SecurityGroup/SecurityGroupNetworkMap';

import { VPCNetworkTab } from './VPCNetworkTab';

export const getNetworkTab = resourceType => {
  switch (resourceType) {
    case NativeResources.VirtualPrivateCloud:
    case NativeResources.Ec2Instance:
      return <VPCNetworkTab />;
    case NativeResources.SecurityGroup:
    case NativeResources.ElasticKubernetesService:
    case NativeResources.Container:
    case NativeResources.ElasticLoadBalancerv2:
    case NativeResources.ElasticLoadBalancer:
      return <SecurityGroupNetworkTab />;
    case NativeResources.Route53HostedZone:
      return <Route53HostedZoneNetworkMap />;
    default:
      return null;
  }
};
