import React, { FC } from 'react';

import { Accordion, Grid, GridItem, Stack, Box } from '@chakra-ui/react';

import { StackedCell, timeLabel } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ClusterArn,
  RoleARN,
  TaskDefinitionARN,
  AssignPublicIP,
  DeploymentController,
  LaunchType,
  DesiredAndRunningCount,
  PendingCount,
} from '../../../Components/MetadataField/MetaGridFields';

export const ECSServiceMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const loadBalancersColumns = [
    { header: 'Name', accessor: 'name' },
    { header: 'Target group ARN', accessor: 'targetGroupArn', align: 'left' },
    { header: 'Container Name', accessor: 'containerName', align: 'left' },
    { header: 'Container Port', accessor: 'containerPort', align: 'left' },
  ];

  const renderList = ({ value }) => (
    <CustomTooltip
      label={
        <Stack>
          {value?.map(o => (
            <Box>{o}</Box>
          ))}
        </Stack>
      }
    >
      {value?.length}
    </CustomTooltip>
  );

  const deploymentsColumns = [
    { header: 'ID', accessor: 'id' },
    { header: 'Status', accessor: 'status', align: 'left' },
    { header: 'Launch type', accessor: 'launchType', align: 'left' },
    {
      header: 'Desired / Running Count',
      accessor: '',
      //align: 'left',
      render: ({ row }) => (
        <StackedCell upper={row?.desiredCount} lower={row?.runningCount} />
      ),
    },
    {
      header: 'Assign public IP',
      accessor: 'networkConfiguration.awsvpcConfiguration.assignPublicIp',
      align: 'left',
    },
    {
      header: 'Created / Updated at',
      accessor: '',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={timeLabel(row?.createdAt)}
          lower={timeLabel(row?.updatedAt)}
          //upperTooltip={timeTooltipLabel(row?.createdAt)}
          //lowerTooltip={timeTooltipLabel(row?.updatedAt)}
        />
      ),
    },
    { header: 'Task definition', accessor: 'taskDefinition', align: 'left' },
    {
      header: 'Platform',
      align: 'left',
      render: ({ row }) => (
        <StackedCell upper={row?.platformFamily} lower={row?.platformVersion} />
      ),
    },
    {
      header: 'Subnets',
      accessor: 'networkConfiguration.awsvpcConfiguration.subnets',
      align: 'right',
      render: renderList,
    },
    {
      header: 'Security groups',
      accessor: 'networkConfiguration.awsvpcConfiguration.securityGroups',
      align: 'right',
      render: renderList,
    },
  ];

  const loadBalancers = resource?.config?.loadBalancers ?? [];
  const deployments = resource?.config?.deployments ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields arn={resource} />
      {ClusterArn(resource)}
      {RoleARN(resource)}
      {TaskDefinitionARN(resource)}
      {DesiredAndRunningCount(resource)}
      {PendingCount(resource)}
      {LaunchType(resource)}
      {AssignPublicIP(resource)}
      {DeploymentController(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={
              <Table columns={loadBalancersColumns} data={loadBalancers} />
            }
            iconType={''}
            label="Load balancers"
            totalCount={loadBalancers?.length}
            height="300px"
          />
          <MetaAccordionItem
            panel={<Table columns={deploymentsColumns} data={deployments} />}
            iconType={''}
            label="Deployments"
            totalCount={deployments?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
