import React, { useEffect, useState, memo } from 'react';

import { JobStatus } from '@ariksa/reporting';
import {
  DataTypeForES,
  ReportingTargets,
  ReportsResponse,
  ReportTypes,
} from '@ariksa/reporting';
import { Box, HStack, Stack, useDisclosure, Center } from '@chakra-ui/react';
import { flatten, isEmpty, map, truncate, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card,
  PageHeaderWithIcon,
  renderTimeSince,
  setNameWithLocalTimestamp,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ActionButton,
  AddButton,
  DeleteActionButton,
  FormAction,
} from 'components/DataEntry';
import {
  ClockIcon,
  EditIcon,
  ExportIcon,
  PolicyIcon,
  TrashIcon,
} from 'components/Icons';
import { DownloadIcon } from 'components/Icons/ReactCustomIcons/DownloadIcon';
import { usePageContentContext } from 'components/Layout';
import { DeleteConfirmationModal, TableActionsMenu } from 'components/Overlay';
import { EnvironmentName } from 'containers/App/utils';
import { ReportForm } from 'containers/Reports/AllReports/Components/ReportForm';
import { allReportsSaga } from 'containers/Reports/AllReports/saga';
import { selectReports } from 'containers/Reports/AllReports/selectors';
import { reducer, sliceKey } from 'containers/Reports/AllReports/slice';
import {
  renderReportStatus,
  renderSchedule,
} from 'containers/Reports/AllReports/utils';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import { ExportAlertsModal } from 'containers/SharedState/Components/ExportAlerts/ExportAlertsModal';
import { useInjector } from 'utils/inject';
import { toTitleCase } from 'utils/string';

import { actions } from './slice';

export const AllReports = memo(() => {
  useInjector(sliceKey, reducer, allReportsSaga);

  const { contentHeight } = usePageContentContext();
  const dispatch = useDispatch();
  const { reports } = useSelector(selectReports);
  const updateReport = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<ReportsResponse>(
    {} as ReportsResponse,
  );
  const exportAlerts = useDisclosure();
  const [actionType, setActionType] = useState<FormAction>('Add');
  //const [users, setUsers] = useState<Record<string, any>>({});
  const deleteReport = useDisclosure();
  const { toCloudAccountName } = useCloudAccountId();

  useEffect(() => {
    dispatch(actions.getReports({}));
  }, [dispatch]);

  /*useEffect(() => {
    dispatch(
      sharedStateActions.getInsightV2All({
        q: { insightV2RequestBase: { resource_type: NativeResources.IamUser } },
        onSuccess: res => {
          let users = {};
          forEach(res?.items, o => (users[o.uuid] = o.name ?? o.resource_id));
          setUsers(users);
        },
      }),
    );
  }, [dispatch]);*/

  const onConfirmDelete = row => {
    !!row?.id &&
      dispatch(
        actions.deleteReport({
          q: { reportId: row?.id },
          onSuccess: () => {
            dispatch(actions.getReports({}));
            deleteReport.onClose();
          },
        }),
      );
  };

  const onClickEdit = row => {
    setCurrentRecord(row);
    updateReport.onOpen();
    setActionType('Update');
  };

  const renderAction = ({ row }) => {
    const menuItems = [
      {
        label: 'Edit',
        icon: <EditIcon />,
        onClick: () => onClickEdit(row),
      },
      ...(row?.target === ReportingTargets.ElasticSearch
        ? [
            {
              label: 'Export ' + toTitleCase(row?.report_type),
              icon: <ExportIcon />,
              onClick: () => {
                exportAlerts.onOpen();
                setCurrentRecord(row);
              },
            },
          ]
        : [
            {
              label: 'Download latest',
              icon: <DownloadIcon />,
              onClick: () => {
                dispatch(
                  actions.downloadReport({
                    q: { reportId: row?.id },
                    onSuccess: res => {
                      var pom = document.createElement('a');
                      pom.href = res.report_url;
                      pom.setAttribute(
                        'download',
                        setNameWithLocalTimestamp(row.name),
                      );
                      pom.click();
                    },
                  }),
                );
              },
              isDisabled: !(row.report_status === JobStatus.Complete),
            },
            {
              label: 'Download past reports',
              icon: <ClockIcon />,
              onClick: () => {
                dispatch(
                  actions.downloadReport({
                    q: { reportId: row?.id },
                    onSuccess: res => {
                      var pom = document.createElement('a');
                      pom.href = res.report_url;
                      pom.setAttribute(
                        'download',
                        setNameWithLocalTimestamp(row.name),
                      );
                      pom.click();
                    },
                  }),
                );
              },
              isDisabled: true,
            },
            {
              label: 'Generate Report',
              icon: <ClockIcon />,
              onClick: () => {
                dispatch(
                  actions.generateReport({
                    q: { reportId: row?.id },
                    onSuccess: () => dispatch(actions.getReports({})),
                  }),
                );
              },
            },
          ]),
      {
        label: 'Delete',
        icon: <TrashIcon color="delete" />,
        onClick: () => {
          deleteReport.onOpen();
          setCurrentRecord(row);
        },
      },
    ];

    return (
      <Box onClick={e => e.stopPropagation()}>
        <TableActionsMenu menuItems={menuItems} />
      </Box>
    );
  };

  const renderRecipients = ({ row }) => {
    return !!row?.email_ids?.length || !!row?.user_ids?.length ? (
      <CustomTooltip
        label={
          <Stack spacing={1}>
            {!!row?.email_ids?.length && (
              <Stack>
                <Box>Email Ids</Box>
                {row?.email_ids?.map((o, index) => (
                  <HStack spacing={4}>
                    <Box>{index + 1}.</Box>
                    <Box>{o}</Box>
                  </HStack>
                ))}
              </Stack>
            )}
            {/*{!!row?.user_ids?.length && (
              <Stack>
                <Box>Users</Box>
                {row?.user_ids?.map((o, index) => (
                  <HStack spacing={4}>
                    <Box>{index + 1}.</Box>
                    <Box>{users?.[o]}</Box>
                  </HStack>
                ))}
              </Stack>
            )}*/}
          </Stack>
        }
      >
        {row?.email_ids?.length + row?.user_ids?.length}
      </CustomTooltip>
    ) : (
      ''
    );
  };

  const renderFilters = ({ row }) => {
    const accounts = uniq(flatten(map(row?.ids, o => o.account_ids)));

    return (
      <StackedCell
        upper={
          <HStack>
            {row?.ids?.[0] && (
              <EnvironmentName environmentId={row?.ids?.[0]?.environment_id} />
            )}
            {row?.ids?.length > 1 && (
              <Tag label={'+ ' + (row?.ids?.length - 1)} />
            )}
          </HStack>
        }
        lower={
          !!accounts?.length ? (
            <HStack>
              {accounts?.[0] && (
                <Center>
                  {truncate(toCloudAccountName(accounts[0]), { length: 20 })}
                </Center>
              )}
              {accounts?.length > 1 && (
                <Box>
                  <Tag label={'+ ' + (accounts?.length - 1)} />
                </Box>
              )}
            </HStack>
          ) : (
            '-'
          )
        }
        upperTooltip={
          <Stack>
            <Box>Environments:</Box>
            {map(row?.ids, (o, index) => (
              <Box>
                {index + 1}.{'  '}
                <EnvironmentName environmentId={o.environment_id} />
              </Box>
            ))}
          </Stack>
        }
        lowerTooltip={
          <Stack>
            <Box>Accounts:</Box>
            {map(
              row?.ids,
              o =>
                !isEmpty(o.account_ids) && (
                  <Stack spacing={0}>
                    <Box color="orange">
                      <EnvironmentName environmentId={o.environment_id} />
                    </Box>
                    {map(o.account_ids, (a, index) => (
                      <Box pl={4}>
                        {index + 1}.{'  '}
                        {toCloudAccountName(a)}
                      </Box>
                    ))}
                  </Stack>
                ),
            )}
          </Stack>
        }
        showUpperTooltip={!!row?.ids?.length}
        showLowerTooltip={!!accounts?.length}
      />
    );
  };

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row.report_type ?? ''}
          bgColor="primary"
          iconSize="sm"
        >
          <StackedCell
            upper={row?.name}
            lower={toTitleCase(row?.report_type)}
          />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Schedule',
      render: renderSchedule,
      align: 'left',
    },
    /*{
      header: 'Filters',
      render: renderFilters,
      align: 'left',
    },*/
    {
      header: 'Recipient Type',
      align: 'left',
      render: ({ row }) =>
        !!row?.target && row?.target === ReportingTargets.ElasticSearch
          ? 'SIEM'
          : 'Email',
    },
    {
      header: 'Recipients',
      accessor: 'email_ids',
      render: ({ row }) =>
        row?.target === ReportingTargets.ElasticSearch
          ? 1
          : renderRecipients({ row }),
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Last Run',
      accessor: 'last_run_at',
      align: 'left',
      render: renderTimeSince,
    },
    {
      header: 'Last Run Status',
      render: renderReportStatus,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: renderAction,
    },
  ];

  const getExportDataType = reportType => {
    switch (reportType) {
      case ReportTypes.Vulnerabilities:
        return DataTypeForES.Vulnerabilities;
      case ReportTypes.Inventory:
        return DataTypeForES.Resources;
      default:
        return DataTypeForES.Alerts;
    }
  };

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon label="Reports" icon={<PolicyIcon />} />
          <>
            <AddButton
              label="Add Report"
              onClick={() => {
                updateReport.onOpen();
                setActionType('Add');
              }}
            />
          </>
        </Stack>
        <Box flex={1}>
          <Table
            data={reports.data}
            columns={columns}
            isLoading={reports.isLoading}
            onRowClick={row => setCurrentRecord(row)}
            pagination={{
              pageInfo: reports.page.info,
              onChange: info => dispatch(actions.updateReportsPageInfo(info)),
              totalCount: reports.page.totalCount,
            }}
          />
        </Box>
      </Stack>
      {updateReport.isOpen && (
        <ReportForm
          currentRecord={
            actionType === 'Add' ? ({} as ReportsResponse) : currentRecord
          }
          isOpen={updateReport.isOpen}
          onClose={updateReport.onClose}
          actionType={actionType}
        />
      )}
      {deleteReport.isOpen && (
        <DeleteConfirmationModal
          name={currentRecord.name}
          type="report"
          onConfirm={() => onConfirmDelete(currentRecord)}
          {...deleteReport}
        />
      )}
      {exportAlerts.isOpen && (
        <ExportAlertsModal
          type={getExportDataType(currentRecord?.report_type)}
          isOpen={exportAlerts.isOpen}
          onClose={exportAlerts.onClose}
        />
      )}
    </Card>
  );
});
