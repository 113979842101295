import React from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { VulnerabilityIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

interface Props {
  isOutpostEnabled: boolean;
  vulnerabilityScannerCount: number;
}

export const Vulnerability: React.FC<Props> = props => {
  const { vulnerabilityScannerInfo } = useSelector(selectDashboard);
  const { vulnerabilitiesCount } = useSelector(selectVulnerabilityOverview);
  const { data, isLoading } = vulnerabilitiesCount.totalVulnerabilities;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Vulnerabilities"
      icon={<VulnerabilityIcon />}
      change={{
        isLoading,
        first: data?.previous_count,
        last: data?.current_count,
        percentChange: data?.percentage_change,
      }}
      onClick={() => navigate('/visibility/vulnerability')}
      cloudProviders={[]}
      count={data?.current_count}
      isLoading={isLoading}
      info={{
        isLoading: vulnerabilityScannerInfo.isLoading,
        message: props.isOutpostEnabled
          ? props.vulnerabilityScannerCount + ' Outposts'
          : 'No outposts',
        enabled: props.isOutpostEnabled,
        onClick: () => navigate('/setup/outpost/add/vulnerability'),
      }}
      tooltip={{
        header: 'Deploy Ariksa Outpost for Vulnerabilities……',
        icon: <VulnerabilityIcon />,
        tooltip: (
          <Stack>
            <Box>
              Ariksa Outpost for Vulnerability identifies run-time exploitable
              vulnerabilities in apps, libraries and OS hosted on VMs &
              containers, lateral movement risks and data exfiltration attempts.
            </Box>
            <Box>
              Ariksa correlates these with other related factors that create
              attack paths to data
            </Box>
          </Stack>
        ),
        footerText: 'Deploy Outpost',
      }}
      labelTooltip={formatTooltip({
        header: 'Vulnerabilities',
        content:
          'All vulnerabilities detected in packages related to OS, apps and libraries',
        width: 48,
      })}
    />
  );
};
