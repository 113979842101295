import { Severity } from '@ariksa/notification';
import { Optional } from 'types/utils';

import { AGraphNode } from '../core/Node';

// check type of node
export class NodeType {
  static isMenuVisible(node: AGraphNode) {
    return !(
      NodeType.isResourceType(node) ||
      NodeType.isSeverity(node) ||
      NodeType.isOs(node) ||
      NodeType.isTech(node) ||
      NodeType.isPermission(node)
    );
  }

  static isContext(node: AGraphNode) {
    return (
      node.data?.native_name.toLowerCase() === 'ariksa context' ||
      node.data?.properties?.ariksa_context
    );
  }

  static isBlockPublicPolicy(node: AGraphNode) {
    return node.data?.native_name === 'Block public policy';
  }

  static isRestrictPublicBuckets(node: AGraphNode) {
    return node.data?.native_name === 'Restrict public buckets';
  }

  static isIgnorePolicyAcls(node: AGraphNode) {
    return node.data?.native_name === 'Ignore public acls';
  }

  static isBlockPublicAcl(node: AGraphNode) {
    return node.data?.native_name === 'Block public acls';
  }

  static isInternet(node: AGraphNode) {
    return node.data?.native_name === 'Internet';
  }

  static isSeverity(node: AGraphNode) {
    return node.data?.native_name === 'Severity';
  }

  static severityType(node: AGraphNode): Optional<Severity> {
    const { name } = node.data ?? {};
    if (name === 'Critical vulnerability') return Severity.Critical;
    if (name === 'High vulnerability') return Severity.High;
    if (name === 'Medium vulnerability') return Severity.Medium;
    if (name === 'Low vulnerability') return Severity.Low;

    if (NodeType.isSeverity(node)) {
      switch (name) {
        case 'Low':
          return 'LOW';
        case 'High':
          return 'HIGH';
        case 'Medium':
          return 'MEDIUM';
        case 'Critical':
          return 'CRITICAL';
      }
    }
  }

  static isOs(node: AGraphNode) {
    return node.data?.native_name === 'OS';
  }

  static isTech(node: AGraphNode) {
    return node.data?.native_name === 'Technology';
  }

  static isEndpoint(node: AGraphNode) {
    return node.data?.native_name === 'Endpoint';
  }

  static isPermission(node: AGraphNode) {
    return (
      node.data?.header === 'Permission' ||
      node.data?.header === 'Permission Type' ||
      node.data?.header === 'PermissionType'
    );
  }

  static isResourceType(node: AGraphNode) {
    return node.data?.header === 'Resource Type';
  }

  static isResource(node: AGraphNode) {
    return !!node.data?.ariksaType?.cloud_native_class;
  }

  static isProcess(node: AGraphNode) {
    return node.data.native_name === 'Process';
  }

  static isCredentialWithCount(node: AGraphNode) {
    return (
      node.data.agnostic_name === 'Credential' && !!node.data?.properties?.count
    );
  }

  static isCircular(node: AGraphNode) {
    return (
      NodeType.isInternet(node) ||
      NodeType.isSeverity(node) ||
      NodeType.isOs(node) ||
      NodeType.isTech(node) ||
      NodeType.isEndpoint(node)
    );
  }
}
