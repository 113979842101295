import React, { FC } from 'react';

import { Center } from '@chakra-ui/react';

import { PageLoader } from 'utils/PageLoader';

interface IAuthenticating {}

export const Authenticating: FC<IAuthenticating> = props => {
  return (
    <Center w={'full'} h={'full'} position="fixed" bg={'#eee'}>
      <PageLoader />
    </Center>
  );
};
