import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ObjectStorageIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    p={0.5}
    color="inherit"
    {...props}
  >
    <path
      d="M500.5,202.4c-3.1,24-22.8,33.8-23.6,34.3l-0.7,0.3c-5.7,2.2-12.1,3.3-19.1,3.3
	c-70.3,0-201.6-103.5-243.5-138.1c-1.7,0.5-3.4,0.9-5.2,0.9c-9.7,0-17.6-7.9-17.6-17.6c0-9.7,7.9-17.6,17.6-17.6
	c9.7,0,17.6,7.9,17.6,17.6c0,0.8-0.1,1.5-0.2,2.2c61,50.3,196,149.6,243.1,131.9c1.7-0.9,11.5-6.9,13-19.9
	c1.6-13.7-5.5-37.4-45.5-74.6l-7.9,72.2c-42-12-116.8-59.5-183.9-112.8C244,65,228,49.3,208.3,49.3c-20,0-36.2,16.2-36.2,36.2
	s16.2,36.2,36.2,36.2c0.6,0,1.1-0.1,1.7-0.1c52.6,42.4,144.7,110.1,212.9,125.3L395,500.8c-0.7,6.4-6.1,11.2-12.5,11.2H70.9
	c-6.5,0-11.9-4.9-12.5-11.4L11.2,13.8c-0.4-3.5,0.8-7,3.2-9.7C16.8,1.5,20.1,0,23.7,0H436c3.6,0,7,1.5,9.4,4.2
	c2.4,2.7,3.5,6.2,3.1,9.8l-9.7,88.1C483.3,140.7,504.1,174.4,500.5,202.4z"
    />
  </Icon>
);
