import React, { FC } from 'react';

import { Box, Center, HStack } from '@chakra-ui/react';
import { dispatch } from 'd3';
import { isString } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components, OptionsOrGroups } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Optional } from 'types/utils';

import { defaultSelectStyles, SelectOption } from 'components/DataEntry';
import { DropdownIndicator } from 'components/DataEntry/Select/utils';
import { getIcon } from 'components/Icons/Components';
import { SearchResultData } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

interface IEntitySelect {
  searchResult: SearchResultData;
  selectedSourceNodeOption: Optional<SelectOption>;
  defaultOptions?: OptionsOrGroups<SelectOption, any>;
  handleOnLoadOptions?: any;
  updateSelectedSourceNodeOption: any;
}

export const EntitySelect: FC<IEntitySelect> = props => {
  const dispatch = useDispatch();
  const {
    searchResult,
    selectedSourceNodeOption,
    defaultOptions,
    handleOnLoadOptions,
    updateSelectedSourceNodeOption,
  } = props;

  const { redirectInfo, activeSearchQueryTerm } = useSelector(
    selectSecurityGraphNext,
  );

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const handleOnSelect = selected => {
    // for identity map, when the search query term is present, we need to pass it in next api call
    console.log('redirectInfo', redirectInfo);
    console.log(activeSearchQueryTerm);

    if (redirectInfo?.identity && activeSearchQueryTerm?.query) {
      params.set('source_resource_uuid', selected!.value.uuid);
      params.set('query', activeSearchQueryTerm.query);
    } else {
      params.set('source_resource_uuid', selected!.value.uuid);
    }

    console.log('xxxxxxxx');

    dispatch(actions.updateFilterChanged(true));
    const url = `${window.location.pathname}?${params.toString()}`;
    navigate(url);
    updateSelectedSourceNodeOption(selected);
  };

  return (
    <AsyncSelect
      isDisabled={searchResult.isLoading}
      value={selectedSourceNodeOption}
      defaultOptions={defaultOptions}
      loadOptions={handleOnLoadOptions}
      onChange={handleOnSelect}
      formatOptionLabel={formatGroupLabel}
      onMenuScrollToBottom={e => {}}
      formatGroupLabel={formatGroupLabel}
      menuPortalTarget={document.body}
      components={{
        DropdownIndicator,
        MenuList,
        Option,
        SingleValue,
      }}
      styles={{
        ...defaultSelectStyles(),
        menu: provided => ({
          ...provided,
          width: 'max-content',
          minWidth: '100%',
        }),
        control: (styles, props) => ({
          ...defaultSelectStyles().control(styles, props),
          backgroundColor: '#fff',
        }),
        valueContainer: (styles, props) => ({
          ...defaultSelectStyles().valueContainer(styles, props),
        }),
      }}
    />
  );
};

const MenuList = props => {
  return (
    <Box
      as="ul"
      sx={{
        listStyle: 'none',
        p: 0,
        m: 0,
        maxHeight: '440px',
        overflowY: 'auto',
      }}
    >
      {props.children}
    </Box>
  );
};

const Option = props => {
  const { isSelected, data, isDisabled } = props;

  return (
    <components.Option {...props}>
      <Box opacity={isDisabled ? 0.4 : 1}>
        <HStack>
          {data?.icon && (
            <Center boxSize={4} color={isSelected ? 'white' : 'primary'}>
              {isString(data?.icon) ? getIcon(data?.icon) : data?.icon}
            </Center>
          )}
          <Box>{data?.label}</Box>
        </HStack>
      </Box>
    </components.Option>
  );
};

const SingleValue = props => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <HStack h="full">
        <Center h="full" boxSize={5}>
          <Center color="primary" w="full">
            {data?.icon}
          </Center>
        </Center>
        <Box flex={1} w="full" overflow="hidden" textOverflow="ellipsis">
          {props.children}
        </Box>
      </HStack>
    </components.SingleValue>
  );
};

const formatGroupLabel = data => (
  <Box borderColor="gray.200" pb={1} fontSize={12} color="gray.250">
    {data.label}
  </Box>
);
