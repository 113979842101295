import React, { FC } from 'react';

import { Stack, HStack, Text } from '@chakra-ui/react';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';

export const CreateAutomaticIAMRoleHelp: FC = () => {
  return (
    <Stack px={2} spacing={6} h="full">
      <HStack>
        <HelpCount count={1} />
        <Text>‘Enable All Features’ in the AWS Organization</Text>
      </HStack>
      <HStack>
        <HelpCount count={2} />
        <Text>Enable trusted access with AWS Organizations</Text>
      </HStack>
    </Stack>
  );
};
