import { useCallback, useMemo, useState } from 'react';

import { ResourceApiGetPolicyDocumentRequest } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { Optional } from 'types/utils';

import { PolicyDocumentModal } from 'containers/Components/PolicyDocumentModal';

export const usePolicyDocumentModal = () => {
  const disclosure = useDisclosure();

  const [queryParams, setQueryParams] = useState<
    Optional<ResourceApiGetPolicyDocumentRequest>
  >(null);

  const modal = useMemo(() => {
    if (!queryParams) return null;
    return (
      <PolicyDocumentModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        params={queryParams}
      />
    );
  }, [disclosure.isOpen, disclosure.onClose, queryParams]);

  const setParams = useCallback(
    (params: Optional<ResourceApiGetPolicyDocumentRequest>) => {
      setQueryParams(params);
      return disclosure;
    },
    [disclosure],
  );

  return { setParams, component: modal, isOpen: disclosure.isOpen };
};
