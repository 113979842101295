import React, { useEffect, useState } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { CDashboard } from 'components/Visualization';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { dashboardWidgets1 } from 'containers/Compliance/Components/ComplianceDashboard/config';
import { ComplianceHeader } from 'containers/Compliance/Components/ComplianceHeader';
import { selectCompliance } from 'containers/Compliance/selectors';

import { actions } from '../../slice';
import { ComplianceTables } from '../ComplianceTables/ComplianceTables';

export const ComplianceDashboard: React.FC = () => {
  const dispatch = useDispatch();

  const { selectedBlueprint } = useSelector(selectCompliance);
  const { withAccessBoundary } = useAccessBoundary();
  const [showDashboard, setShowDashboard] = useState(false);

  useEffect(() => {
    const startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    const endDate = new Date();
    if (!isEmpty(selectedBlueprint)) {
      dispatch(
        actions.getComplianceSummary({
          q: withAccessBoundary({ blueprintId: selectedBlueprint?.id }),
        }),
      );
      dispatch(
        actions.getHistoricalTrend({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            startDate: `${startDate.getFullYear()}-${
              startDate.getMonth() + 1
            }-${startDate.getDate()}`,
            endDate: `${endDate.getFullYear()}-${
              endDate.getMonth() + 1
            }-${endDate.getDate()}`,
          }),
        }),
      );
      dispatch(
        actions.getViolationsByAccount({
          q: withAccessBoundary({ blueprintId: selectedBlueprint?.id }),
        }),
      );
    }
  }, [dispatch, selectedBlueprint, withAccessBoundary]);

  return (
    <Stack spacing={6} h="full" w="full">
      {/*{showDashboard && (*/}
      {/*  <ComplianceHeader*/}
      {/*    showDashboard={showDashboard}*/}
      {/*    setShowDashboard={setShowDashboard}*/}
      {/*  />*/}
      {/*)}*/}
      <Stack align="flex-start" h="full" w="full" spacing={4}>
        {showDashboard && (
          <>
            <Box p={4} w="full" bg="#fff" borderRadius={6}>
              <ComplianceHeader
                setShowDashboard={setShowDashboard}
                showDashboard={showDashboard}
              />
            </Box>
            <CDashboard dashboardWidgets={dashboardWidgets1} />
          </>
        )}
        <ActiveResourceProvider>
          <ComplianceTables
            setShowDashboard={setShowDashboard}
            showDashboard={showDashboard}
          />
        </ActiveResourceProvider>
      </Stack>
    </Stack>
  );
};
