import React from 'react';

import { Icon } from '@chakra-ui/react';

export const GraphIcon = props => (
  <Icon viewBox="0 0 300 300" color="inherit" h="full" w="full" {...props}>
    <g>
      <path
        d="M199.922,210.005c-23.39,0-42.418,19.029-42.418,42.418s19.028,42.419,42.418,42.419s42.419-19.029,42.419-42.419
		S223.312,210.005,199.922,210.005z M199.922,282.843c-16.772,0-30.418-13.646-30.418-30.419s13.646-30.418,30.418-30.418
		c16.773,0,30.419,13.646,30.419,30.418S216.696,282.843,199.922,282.843z"
      />
      <path
        d="M129.567,109.567c1.535,0,3.071-0.586,4.242-1.757l35.781-35.78c7.708,7.894,18.454,12.808,30.333,12.808
		c23.39,0,42.419-19.029,42.419-42.418S223.312,0,199.922,0s-42.418,19.029-42.418,42.419c0,7.15,1.785,13.888,4.923,19.804
		l-37.102,37.101c-2.344,2.343-2.344,6.142-0.001,8.485C126.496,108.981,128.031,109.567,129.567,109.567z M199.922,12
		c16.773,0,30.419,13.646,30.419,30.419s-13.646,30.418-30.419,30.418c-16.772,0-30.418-13.646-30.418-30.418S183.15,12,199.922,12z
		"
      />
      <path
        d="M157.908,214.935l-35.364-35.363c9.048-7.785,14.794-19.306,14.794-32.151c0-23.39-19.028-42.419-42.418-42.419
		s-42.419,19.029-42.419,42.419S71.531,189.84,94.92,189.84c6.139,0,11.972-1.321,17.244-3.678l37.259,37.258
		c1.171,1.171,2.707,1.757,4.242,1.757s3.071-0.586,4.242-1.757C160.251,221.077,160.251,217.278,157.908,214.935z M64.501,147.421
		c0-16.773,13.646-30.419,30.419-30.419c16.772,0,30.418,13.646,30.418,30.419S111.693,177.84,94.92,177.84
		C78.147,177.84,64.501,164.194,64.501,147.421z"
      />
    </g>
  </Icon>
);
