import React from 'react';

import { Categories, Resources } from '@ariksa/inventory-core/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { Route53HostedZoneIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const Domains: React.FC = () => {
  const { infrastructurePostureSummary } = useSelector(selectDashboard);
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();
  const { domainsCount } = useSelector(selectDashboard);

  return (
    <SummaryCard
      label="Domains"
      icon={<Route53HostedZoneIcon />}
      change={{
        isLoading: false,
        first: domainsCount.data.previous_count ?? 0,
        last: domainsCount.data.current_count ?? 0,
        percentChange: domainsCount.data.percentage_change ?? 0,
      }}
      cloudProviders={[]}
      count={data?.items?.['Network']?.['Domains']}
      isLoading={isLoading}
      onClick={() =>
        navigate(
          '/inventory/summary?tab=' + Categories.Network + '_' + Resources.Dns,
        )
      }
      showPercentChange={true}
      labelTooltip={formatTooltip({
        header: 'Domains',
        content:
          'DNS related resources such as domains, sub-domains for your SaaS',
        width: 48,
      })}
    />
  );
};
