import React, { FC, useEffect, useReducer } from 'react';

import { Box, HStack, UseDisclosureProps } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton, Select } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { PolicyIcon } from 'components/Icons';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectApp } from 'containers/App/selectors';
import { severityOptions } from 'containers/Inventory/Whitelist/Components/WhitelistWizard/Steps/WhitelistCriteria/ResourceTypeCriteria/utils';
import { policyTypeOptions } from 'containers/PolicyHub/Components/PoliciesTableHeader/utils';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions as policyHubActions } from 'containers/PolicyHub/slice';
import { toTitleCase } from 'utils/string';

interface Props {
  addPolicy?: UseDisclosureProps;
  action?: 'Create' | 'Show' | 'Add';
  compliance?: string;
  createdBy?: string;
  resourceType: Record<string, any>;
  alertCategory: Record<string, any>;
  policyType: Record<string, any>;
  severity: Record<string, any>;
  updateValue(field: string, value: any);
}

export const PoliciesTableHeader: FC<Props> = props => {
  const {
    addPolicy,
    compliance = '',
    createdBy = '',
    resourceType,
    updateValue,
    alertCategory,
    policyType,
    severity,
  } = props;
  const dispatch = useDispatch();
  const { getCloudNativeName } = useResourceType();
  const { resourceTypes } = useSelector(selectApp);
  const { alertCategories } = useSelector(selectPolicyHub);
  const {resourceTypeAliasOptionsWithAll} = useResourceType();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      alertCategoryOptions: [],
      resourceTypeOptions: [],
      severityOptions: [
        { label: 'All Severities', value: undefined },
        ...severityOptions,
      ],
      searchTerm: '',
    },
  );

  //get alert categories
  useEffect(() => {
    dispatch(policyHubActions.getAlertCategories({ q: {} }));
  }, [dispatch]);

  //set resource type options
  useEffect(() => {
    const options = resourceTypeAliasOptionsWithAll;
    updateState({
      resourceTypeOptions: options,
    });
    updateValue('resourceType', options[0]);
  }, [resourceTypeAliasOptionsWithAll, updateValue]);

  //set alert category options
  useEffect(() => {
    const options = [
      { label: 'All Alert Categories', value: '' },
      ...map(alertCategories.data, o => ({
        label: toTitleCase(o),
        value: o,
      })),
    ];
    updateState({
      alertCategoryOptions: options,
    });
    updateValue('alertCategory', options[0]);
  }, [alertCategories.data, updateValue]);

  return (
    <HStack w="full" justify="space-between">
      <Box w="40%">
        <SearchBox
          value={state.searchTerm}
          onChange={s => {
            updateState({ searchTerm: s });
            if (!s) updateValue('searchTerm', '');
          }}
          onSearch={() => updateValue('searchTerm', state.searchTerm)}
          onClickClose={() => {
            updateState({ searchTerm: '' });
            updateValue('searchTerm', '');
          }}
        />
      </Box>
      <HStack>
        {!compliance && (
          <Box w="150px" zIndex={900}>
            <Select
              options={policyTypeOptions}
              value={policyType}
              onChange={s => updateValue('policyType', s)}
              showIconInValueContainer
            />
          </Box>
        )}
        {!compliance && (
          <Box w={48} zIndex={900}>
            <Select
              options={state.severityOptions}
              value={severity}
              onChange={s => updateValue('severity', s)}
              showIconInValueContainer
            />
          </Box>
        )}
        {!compliance && (
          <Box w="200px" zIndex={900}>
            <Select
              options={state.alertCategoryOptions}
              value={alertCategory}
              onChange={s => updateValue('alertCategory', s)}
              isLoading={alertCategories.isLoading}
              showIconInValueContainer
            />
          </Box>
        )}
        {!compliance && (
          <Box w="200px" zIndex={900}>
            <Select
              options={state.resourceTypeOptions}
              value={resourceType}
              onChange={s => updateValue('resourceType', s)}
              showIconInValueContainer
            />
          </Box>
        )}
        {!!addPolicy && !addPolicy?.isOpen && (
          <PrimaryButton
            leftIcon={<PolicyIcon />}
            isDisabled={createdBy === 'system' || !!compliance}
            bg="primary"
            color="white"
            onClick={() => addPolicy?.onOpen?.()}
          >
            Add Policy
          </PrimaryButton>
        )}
      </HStack>
    </HStack>
  );
};
