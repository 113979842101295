/**
 *
 * Help Menu
 *
 */
import React from 'react';

import { Box, IconButton, useDisclosure } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

import { QuestionCircleIcon } from 'components/Icons';
import { WelcomeToAriksa } from 'components/Navigation/NavBar/TopNavBar/RightComponent/WelcomeToAriksa';
import { selectApp, selectUser } from 'containers/App/selectors';

import { iconStyles } from '../styles';

interface Props {}

export const HelpMenu: React.FC<Props> = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { cloudAccounts } = useSelector(selectApp);
  const { info } = useSelector(selectUser);

  useHotkeys(['ctrl+i', 'meta+i'], () => onOpen());
  const [, , , path] = window.location.href.split('/');
  const showWelcomeScreen =
    path !== 'setup' && isEmpty(cloudAccounts.data) && info.org_id !== 'master';

  return (
    <Box>
      <IconButton
        aria-label="Event Log"
        icon={<QuestionCircleIcon />}
        onClick={() => onOpen()}
        {...iconStyles}
      />

      {/*{isOpen &&*/}
      {/*  (!!environments.length ? (*/}
      {/*    <AriksaFAQ isOpen={isOpen} onClose={onClose} onOpen={onOpen} />*/}
      {/*  ) : (*/}
      {/*    <WelcomeToAriksa isOpen={isOpen} onClose={onClose} />*/}
      {/*  ))}*/}

      {!cloudAccounts.loading && showWelcomeScreen && (
        <Box
          pos={'fixed'}
          left={0}
          top={'90px'}
          w={'full'}
          h={'full'}
          bg={'#fff'}
        >
          <WelcomeToAriksa isOpen={isOpen} onClose={onClose} />
        </Box>
      )}
    </Box>
  );
};

export default HelpMenu;
