import React, { useState, FC, useEffect, useMemo } from 'react';

import { TagCondition } from '@ariksa/inventory-core';
import { ResourceTagCondition } from '@ariksa/inventory-core/api';
import { Box, Stack, Button } from '@chakra-ui/react';
import { map, filter, isEmpty, some } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import {
  deleteIconStyles,
  valueContainerStyles,
} from 'containers/PolicyHub/AlertWorkflow/styles';

import { actions } from '../../../../slice';

import { CriteriaQuantifier } from './CriteriaQuantifier';
import { RegionCriteria } from './RegionCriteria';
import { ResourceTypeCriteria } from './ResourceTypeCriteria';
import { TagKeysCriteria } from './TagKeysCriteria';
import { TagKeyValueCriteria } from './TagKeyValueCriteria';

interface Props {
  setIsDisabled: (val: boolean) => void;
}

export const WhitelistCriteria: FC<Props> = props => {
  const { setIsDisabled } = props;
  const {
    resetCriteria,
    resourceTypeOptions,
    isDisabledCriteriaNext,
    tagKeyOptions,
    selectedConditions,
    setSelectedConditions,
  } = useWhitelistContext();
  const { formData } = useSelector(selectWhitelist);
  const dispatch = useDispatch();

  const conditions = useMemo(
    () => [
      {
        label: 'If an entity is in region',
        value: 'region',
        component: <RegionCriteria />,
      },
      {
        label: 'If an entity has tag key',
        value: 'tag_keys_condition',
        component: <TagKeysCriteria />,
      },
      {
        label: 'If an entity has tag key and value',
        value: 'tag_key_values_condition',
        component: <TagKeyValueCriteria />,
      },
      {
        label: 'If an entity has resource type',
        value: 'resource_type_criteria',
        component: <ResourceTypeCriteria />,
      },
    ],
    [],
  );

  useEffect(() => {
    const resource_type =
      (some(selectedConditions, { value: 'resource_type_criteria' }) ||
        some(selectedConditions, { value: 'tag_key_values_condition' })) &&
      isDisabledCriteriaNext;
    const region =
      some(selectedConditions, { value: 'region' }) &&
      isEmpty(formData?.region);
    const tagKey =
      some(selectedConditions, { value: 'tag_keys_condition' }) &&
      isEmpty(formData?.criteria?.tag_keys_condition?.key);
    setIsDisabled(
      region || isEmpty(selectedConditions) || resource_type || tagKey,
    );
  }, [formData, selectedConditions, isDisabledCriteriaNext, setIsDisabled]);

  //set selected conditions
  useEffect(() => {
    const options: Record<string, any>[] = [];
    if (!isEmpty(formData?.region)) options.push(conditions[0]);
    if (!isEmpty(formData?.criteria?.tag_keys_condition?.key))
      options.push(conditions[1]);
    if (!isEmpty(formData?.criteria?.tag_key_values_condition))
      options.push(conditions[2]);
    if (!isEmpty(formData?.criteria?.resource_type_criteria))
      options.push(conditions[3]);
    setSelectedConditions(options);
  }, [conditions, formData, setSelectedConditions]);

  return (
    <Stack>
      <CriteriaQuantifier />

      <Stack>
        <Box mb={2}>
          <Select
            options={conditions}
            value={selectedConditions}
            isMulti
            showTotalSelected={selectedConditions.length > 0}
            placeholder="Choose conditions..."
            onChange={selected => {
              setSelectedConditions(selected);
              if (
                some(selected, { value: 'resource_type_criteria' }) &&
                isEmpty(formData?.criteria?.resource_type_criteria)
              )
                dispatch(
                  actions.updateResourceTypeCriteria({
                    id: nanoid(),
                    value: {
                      resource_type: resourceTypeOptions[0]?.value,
                      key_condition: ResourceTagCondition.HasResourceType,
                    },
                  }),
                );
              else if (
                some(selected, { value: 'tag_key_values_condition' }) &&
                isEmpty(formData?.criteria?.tag_key_values_condition)
              )
                dispatch(
                  actions.updateTagKeyValuesCriteria({
                    id: nanoid(),
                    value: {
                      key: tagKeyOptions[0]?.value,
                      key_condition: TagCondition.Tagged,
                      value: [],
                      value_condition: TagCondition.Tagged,
                    },
                  }),
                );
            }}
          />
        </Box>
        {map(selectedConditions, each => (
          <Stack
            {...valueContainerStyles}
            key={each?.value + '-environment-condition'}
          >
            <Box w="full">{each?.component}</Box>
            <Button
              {...deleteIconStyles}
              onClick={() => {
                setSelectedConditions(
                  filter(selectedConditions, o => o.value !== each.value),
                );
                resetCriteria(each.value);
              }}
            >
              <TrashIcon />
            </Button>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
