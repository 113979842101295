import React, { FC, useCallback, useEffect, useState } from 'react';

import { CloudProvider } from '@ariksa/inventory-core';
import { Box, Flex } from '@chakra-ui/react';
import * as d3 from 'd3';
import { isArray } from 'lodash';

import { WithParentSize } from 'components/Container/WithParentSize';
import { getIconColor } from 'components/Icons/Components/getIconColor';
import './map.css';

export interface IGeoLocation {
  pos: [number, number];
  count: number;
  provider: CloudProvider;
  name: string;
  code: string;
}

interface IGeoLocationMap {
  locations: IGeoLocation[];
}

export const cloudProviderColor = {
  [CloudProvider.Aws]: '#E78600',
  [CloudProvider.Azure]: '#007FFF',
  [CloudProvider.Gcp]: '#4285F4',
  [CloudProvider.Snowflake]: '#00A1E0',
};

export const GeoLocationMap: FC<IGeoLocationMap> = props => {
  const { locations } = props;
  const [geoJson, setGeoJson] = useState<any>({});
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState<any>(null);
  const [tooltipLabel, setTooltipLabel] = useState<any>(null);

  const update = useCallback(() => {
    if (!geoJson || !isArray(geoJson.features)) return;

    const projection = d3.geoMercator();
    projection
      .scale(90)
      .translate([width / 2, height / 2 + 30]) // 30 is a found by trial
      .center([0, 0])
      .rotate([0.1, 0, 0]);

    const geoGenerator = d3.geoPath().projection(projection) as any;

    let u = d3.select('g.map').selectAll('path').data(geoJson.features);
    u.enter().append('path').attr('d', geoGenerator);

    let geoCircle = (size: number = 1) => {
      return d3
        .geoCircle()
        .radius(5 * size)
        .precision(1);
    };

    const maxCount = Math.max(...locations.map(l => l.count));
    // const color = cloudProviderColor[provider];
    const circleRadius = count => Math.log2(count) / Math.log2(maxCount);

    const old = d3.select('.circles').selectAll('path');
    old.remove();

    const loc = d3
      .select('.circles')
      .selectAll('path')
      .remove()
      .data(
        locations?.map(function (d) {
          const circle = geoCircle(circleRadius(d.count) || 0.2);
          circle.center(d.pos);
          return circle();
        }),
      );

    loc
      .enter()
      .append('path')
      .attr('d', geoGenerator)
      .attr('fill', (a, index) => {
        return getIconColor(locations[index].provider);
      })
      .attr('opacity', 0.5)
      .attr('stroke', (a, index) => {
        return getIconColor(locations[index].provider);
      })
      .attr('data-index', (a, index) => {
        return index;
      })
      .on('mouseover', function (e, shape) {
        const index = d3.select(this).attr('data-index');
        const data = locations[index];
        const proj = projection(data.pos);
        if (!proj) return;

        const radius = circleRadius(data.count);

        setTooltipPosition({
          left: proj[0] + 'px',
          top: proj[1] + 2 * radius + 'px',
        });
        setTooltipLabel(
          <div
            style={{
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              padding: '4px',
            }}
          >
            <div style={{ color: 'orange', display: 'flex' }}>{data.name}</div>
            <div style={{ color: 'orange' }}>{data.code}</div>
            <div>
              {data.count} source{data.count > 1 && 's'}
            </div>
          </div>,
        );
      })
      .on('mouseout', function (e, shape) {
        setTooltipPosition(null);
      });
  }, [geoJson, width, height, locations]);

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    d3.json(
      'https://gist.githubusercontent.com/d3indepth/f28e1c3a99ea6d84986f35ac8646fac7/raw/c58cede8dab4673c91a3db702d50f7447b373d98/ne_110m_land.json',
    ).then(function (json) {
      setGeoJson(json);
    });
  }, []);

  return (
    <Box h={'full'} pos={'relative'}>
      <WithParentSize>
        {(height, width) => {
          setWidth(width);
          setHeight(height);
          return (
            <svg width={width + 'px'} height={height + 'px'}>
              <g className="map"></g>
              <g className="circles"></g>
            </svg>
          );
        }}
      </WithParentSize>
      {tooltipPosition && (
        <Flex
          pos={'absolute'}
          {...tooltipPosition}
          px={2}
          transform={'translate(-50%, 16px)'}
          bg={'#555'}
          borderRadius={'md'}
          color={'white'}
          fontSize={'xs'}
        >
          {tooltipLabel}
        </Flex>
      )}
    </Box>
  );
};
