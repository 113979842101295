import React, { useEffect } from 'react';

import { Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { CloudInsightItem } from 'containers/App/CloudInsight/CloudInsightItem';
import { selectApp } from 'containers/App/selectors';
import { actions } from 'containers/App/slice';

interface CloudInsightListProps {}

export const CloudInsightList = (props: CloudInsightListProps) => {
  const { cloudInsights } = useSelector(selectApp);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      actions.getCloudInsights({
        q: {
          accountId: [],
          pageSize: 20,
        },
      }),
    );
  }, [dispatch]);

  return (
    <WithSpinner loadStatus={{ loading: cloudInsights.isLoading }}>
      <Stack spacing={8} py={6}>
        {cloudInsights.data.map((insight, index) => {
          return (
            <CloudInsightItem
              item={insight}
              key={
                insight.event_time +
                '/' +
                insight.insight_id +
                '/' +
                insight.account_id
              }
              isLast={index === cloudInsights.data.length - 1}
            />
          );
        })}
      </Stack>
    </WithSpinner>
  );
};
