import React, { FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { selectUserProfile } from 'containers/UserProfile/selectors';

import { Form, Tag } from '../../../components';
import { form_styles } from '../styles';

export const Permissions: FC = () => {
  const { permissions } = useSelector(selectUserProfile);

  /*const handleSubmit = data => {
    const { roles = [], userGroups = [], name } = data;

    const { firstName, lastName } = name;

    dispatch(
      userSetupSlice.actions.updateUser({
        q: {
          user: {
            ...activeUser,
            firstName,
            lastName,
            roles: roles.map(r => ({ name: r.value.name, id: r.value.id })),
            userGroups: userGroups.map(g => ({
              name: g.value.name,
              id: g.value.id,
            })),
          },
        },
      }),
    );
  };*/

  const renderList = values => (
    <Box
      border="1px solid"
      borderColor={customTheme.colors.gray['100']}
      borderRadius={6}
      p={3}
    >
      {map(values, o => (
        <Tag label={o?.name} styles={{ tag: { m: 1 } }} />
      ))}
    </Box>
  );

  return (
    <Stack>
      <Box>
        <Form
          schema={{
            memberships: {
              type: 'custom-with-form-control',
              label: 'Memberships',
              component: renderList(permissions.data.user_groups),
              isDisabled: true,
            },
            roles: {
              type: 'custom-with-form-control',
              label: 'Roles',
              component: renderList(permissions.data.roles),
              isDisabled: true,
            },
            /*token: {
              type: 'text',
              label: 'API token',
              isDisabled: true,
            },*/
          }}
          formOptions={{
            defaultValues: {},
          }}
          styles={form_styles}
          buttonOptions={{
            submit: {
              name: 'Generate token',
              isVisible: false,
            },
          }}
          handleSubmit={() => {}}
        />
      </Box>
    </Stack>
  );
};
