import React, { useEffect, useState } from 'react';

import { Box, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AddButton,
  Card,
  FilterComponent,
  getResourceTypeOptions,
  PageHeaderWithIcon,
  RoleIcon,
  usePageContentContext,
} from 'app/components';
import { selectApp } from 'containers/App/selectors';
import { DeleteRoleModal } from 'containers/Setup/Roles/components/DeleteRoleModal';
import { useTrackApiCall } from 'hooks/useTrackApiCall';

import { selectRoles } from '../selectors';
import { actions } from '../slice';

import { RoleList } from './RoleList';

export function RolesTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { contentHeight } = usePageContentContext();
  const { roles: rolesData, deleteRole } = useSelector(selectRoles);
  const { resourceTypes } = useSelector(selectApp);
  const [resourceTypeOptions, setResourceTypeOptions] = useState<
    Record<string, any>[]
  >([]);
  const deleteRoleDisclosure = useDisclosure();
  const [activeRole, setActiveRole] = useState<any>(null);

  const { success: deleted } = useTrackApiCall(deleteRole);

  useEffect(() => {
    dispatch(actions.getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (deleted) {
      dispatch(actions.getRoles());
    }
  }, [deleted, dispatch]);

  useEffect(() => {
    setResourceTypeOptions(getResourceTypeOptions(resourceTypes.data));
  }, [resourceTypes]);

  const openAddRoleForm = () => {
    navigate('/setup/roles/add');
  };

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon label="Roles" icon={<RoleIcon />} />
          <Flex>
            <FilterComponent filterPath={'/setup/roles/add'}>
              <AddButton label="Add Role" onClick={openAddRoleForm} />
            </FilterComponent>
          </Flex>
        </Stack>

        <Box flex={1} overflow={'auto'}>
          <RoleList
            roles={rolesData.roles}
            accounts={[]}
            resourcesTypes={resourceTypeOptions}
            onUpdateScope={(role, resources) =>
              dispatch(actions.updateRoleScope({ role, resources }))
            }
            onUpdateName={(role, name) =>
              dispatch(actions.updateRoleName({ role, name }))
            }
            onUpdateResources={(role, resources) =>
              dispatch(actions.updateRoleResources({ role, resources }))
            }
            onDelete={role => {
              deleteRoleDisclosure.onToggle();
              setActiveRole(role);
            }}
            onUpdate={role => dispatch(actions.updateRole(role))}
            onEdit={role => dispatch(actions.startEdit(role))}
            onDuplicate={role => dispatch(actions.createCopyRole(role))}
            onSave={role => dispatch(actions.saveCopy(role))}
            onToggle={role => dispatch(actions.onToggle(role))}
            withActions
            isLoading={rolesData.loading}
          />
        </Box>
        {deleteRoleDisclosure.isOpen && (
          <DeleteRoleModal
            isOpen={deleteRoleDisclosure.isOpen}
            onClose={deleteRoleDisclosure.onClose}
            role={activeRole}
          />
        )}
      </Stack>
    </Card>
  );
}
