import React, { FC, ReactNode } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { isNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { NodeMetadataDrawer } from 'containers/ActiveCloudResource/Components/NodeMetadataDrawer';
import { ActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { complianceSaga } from 'containers/Compliance/saga';
import * as complianceSlice from 'containers/Compliance/slice';
import { policyHubSaga } from 'containers/PolicyHub/saga';
import * as policyHubSlice from 'containers/PolicyHub/slice';
import { useInjector } from 'utils/inject';

import { activeResourceSaga } from '../saga';
import { actions, reducer, sliceKey } from '../slice';

interface IActiveResourceProvider {
  hideDrawer?: boolean;
  children: ReactNode | ReactNode[];
}

export const ActiveResourceProvider: FC<IActiveResourceProvider> = props => {
  useInjector(sliceKey, reducer, activeResourceSaga);
  useInjector(policyHubSlice.sliceKey, policyHubSlice.reducer, policyHubSaga);
  useInjector(
    complianceSlice.sliceKey,
    complianceSlice.reducer,
    complianceSaga,
  );
  const dispatch = useDispatch();

  const { children, hideDrawer } = props;

  const handleOpenMetadataDrawer = (index?: number) => {
    onOpen();
    if (index === undefined) {
      dispatch(actions.setActiveTabName(''));
      dispatch(actions.setActiveTabIndex(0));
      return;
    }
    if (isNumber(index)) {
      dispatch(actions.setActiveTabName(''));
      dispatch(actions.setActiveTabIndex(index));
    } else {
      dispatch(actions.setActiveTabName(index));
      dispatch(actions.setActiveTabIndex(0));
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    resourceDetails,
    resourceInsight,
    resourceType,
    resourceUuid,
    resourceId,
    activeTabIndex,
  } = useSelector(selectActiveResource);

  return (
    <ActiveResourceContext.Provider
      value={{
        resourceId,
        resourceType,
        resourceUuid,
        resourceDetails,
        resourceInsight,
        tabIndex: activeTabIndex,
        isOpenMetadata: isOpen,
        onOpenMetadata: handleOpenMetadataDrawer,
        onCloseMetadata: onClose,
      }}
    >
      {children}
      {!hideDrawer && <NodeMetadataDrawer />}
    </ActiveResourceContext.Provider>
  );
};
