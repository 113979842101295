import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.identityOverview || initialState;

export const selectIdentityOverview = createSelector(
  [selectDomain],
  state => state,
);
