import { pizzlyPkey } from '../config';
import { PIZZLY_SERVICE } from '../service_urls';
import { withPrefixArgs } from '../utils';

import { IGitHubGetReposUrl, IPizzlyPkey, IRepoName, IUserName } from './types';

const withArgs = withPrefixArgs(PIZZLY_SERVICE);

export const githubApis = {
  getGitHubRepos(params: IGitHubGetReposUrl) {
    return withArgs<IGitHubGetReposUrl & IPizzlyPkey>(
      `/proxy/github/user/repos`,
    )({
      per_page: 100,
      pizzly_pkey: pizzlyPkey,
      ...params,
    });
  },
  getGitHubUserInfo() {
    return withArgs<IPizzlyPkey>(`/proxy/github/user`)({
      pizzly_pkey: pizzlyPkey,
    });
  },
  getGitHubRepoBranches(params: IUserName & IRepoName) {
    return withArgs<IPizzlyPkey & IUserName & IRepoName>(
      `/proxy/github/repos/{userName}/{repoName}/branches`,
    )({
      pizzly_pkey: pizzlyPkey,
      ...params,
    });
  },
};
