import { NativeResources } from '@ariksa/inventory-core/api';

import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { LIMIT_INNER_NAME_LEN } from 'components/Visualization/PixiGraph/style';
import { limitedString } from 'utils/string';

export const intoNodeTitle = (node: AGraphNode) => {
  const { data } = node;
  if (['IAMUser', 'IAMRole', 'IAMPolicy'].includes(data.native_name)) {
    return data?.name ?? '-';
  }

  if (data.header === 'Resource Type') {
    return data?.name ?? '-';
  }

  return data?.name ?? '-';
};

export const nodeTitle = (node: AGraphNode) => {
  const title = intoNodeTitle(node);
  const { data } = node;
  if (data.header === 'Resource Type') {
    return `${limitedString(title, LIMIT_INNER_NAME_LEN)} (${data.count})`;
  }

  return `${limitedString(title, LIMIT_INNER_NAME_LEN)}`;
};

const intoNodeInfo = (node: AGraphNode) => {
  const { data } = node;
  if (['IAMUser', 'IAMRole', 'IAMPolicy'].includes(data.native_name)) {
    return '';
  } else if ([NativeResources.AwsAccount].includes(data.native_name)) {
    return data?.resource_id;
  } else if (data.header === 'Resource Type') {
    return data.ariksaType?.cloud_agnostic_name;
  }

  return (
    data.ariksaType?.resource_alias ??
    data.native_name ??
    data.agonistic_name ??
    data.ariksaType?.cloud_native_name
  );
};

export const nodeInfo = (node: AGraphNode) => {
  return intoNodeInfo(node);
};

export const nodeProps = data => {
  return {};
};
