import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { NativeResources, PackageCategory } from '@ariksa/inventory-core/api';
import {
  VulnerabilitySeverity,
  VulnerabilityType,
} from '@ariksa/scan-analysis';
import { Vulnerability } from '@ariksa/scan-analysis/api';
import { Box, Center, useDisclosure } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import round from 'lodash/round';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { PagedQueryState } from 'services/utils/PagedQueryState';

import {
  renderSeverityBar,
  StackedCell,
  timeLabel,
  timeTooltipLabel,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import {
  Sorted,
  TableColumnProps,
} from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { getPublishedTime } from 'components/DataEntry';
import { PageInfo } from 'components/Navigation';
import { Drawer } from 'components/Overlay';
import { MetadataHeader } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataHeader';
import { CVEDetails } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/CVEDetails';
import { resourceVulnerabilityKey } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Vulnerability/utils';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

interface Props {
  vulnerabilityType: VulnerabilityType;
  total: number;
  containerId?: string;
  containerImageId?: string;
  instanceId?: string;
  isExpanded?: boolean;
}

export const VulnerabilityDetails: FC<Props> = props => {
  const {
    vulnerabilityType,
    total,
    containerId,
    instanceId,
    containerImageId,
    isExpanded,
  } = props;
  const {
    vulnerabilityDetails,
    resourceUuid,
    libraryType,
    vulnerabilityPublishedTime,
    showResolvedVulnerabilities,
    imageVulnerabilities,
    vulnerabilitySeverity,
    isPatchable,
    resourceType,
  } = useSelector(selectActiveResource);

  const dispatch = useDispatch();
  const [sortInfo, setSortInfo] = useState<Sorted>({
    sortOrder: 'desc',
    sortField: 'severity',
  });
  const [currentRecord, setCurrentRecord] = useState<Vulnerability>({});
  const detailsDisclosure = useDisclosure();

  const isMachineImages = useMemo(
    () => resourceType === NativeResources.AmazonMachineImage,
    [resourceType],
  );

  const isContainer = useMemo(() => {
    return !!containerId;
  }, [containerId]);

  const getVulnerabilities = useCallback(
    (pageInfo: PageInfo = INIT_PAGE_INFO) => {
      const published = getPublishedTime(vulnerabilityPublishedTime?.value);
      const resolved = showResolvedVulnerabilities;

      const patchable =
        isPatchable === 'yes' ? true : isPatchable === 'no' ? false : undefined;
      const severityFilter = !!vulnerabilitySeverity
        ? { severity: vulnerabilitySeverity as VulnerabilitySeverity }
        : undefined;

      if (resourceType === NativeResources.ElasticContainerRegistry) {
        dispatch(
          actions.getECRContainerImageVulnerability({
            q: {
              instanceId,
              sortBy:
                sortInfo.sortField === 'cvss_v3.score'
                  ? 'cvss_score'
                  : sortInfo.sortField,
              sortOrder: sortInfo.sortOrder,
              page: pageInfo.page_number,
              pageSize: pageInfo.page_size,
            },
          }),
        );
      } else {
        const payload = {
          libraryType: libraryType,
          ...(resolved
            ? { resolved_date: published }
            : { effective_start: published }),
          resolved,
          ...pageInfo,
          isPatchable: patchable,
          ...severityFilter,
          sortBy:
            sortInfo.sortField === 'cvss_v3.score'
              ? 'cvss_score'
              : sortInfo.sortField,
          sortOrder: sortInfo.sortOrder,
          page: pageInfo.page_number,
          pageSize: pageInfo.page_size,
        };

        if (!total) return;

        if (isMachineImages) {
          let packageCategory: PackageCategory =
            PackageCategory.ApplicationPackage;
          if (vulnerabilityType === VulnerabilityType.Os)
            packageCategory = PackageCategory.OsPackage;
          else if (vulnerabilityType === VulnerabilityType.Library)
            packageCategory = PackageCategory.LibraryPackage;
          dispatch(
            actions.getAMIVulnerabilityDetails({
              q: {
                resourceId: resourceUuid,
                nativeResource: NativeResources.AmazonMachineImage,
                packageCategory,
                ...payload,
              },
              page: {
                page_number: pageInfo.page_number,
                page_size: pageInfo.page_size,
              },
            }),
          );
        } else {
          dispatch(
            actions.getVulnerabilityDetails({
              q: {
                instanceId,
                vulnerabilityType: vulnerabilityType,
                containerId,
                ...payload,
              },
              page: {
                page_number: pageInfo.page_number,
                page_size: pageInfo.page_size,
              },
            }),
          );
        }
      }
    },
    [
      vulnerabilityPublishedTime?.value,
      showResolvedVulnerabilities,
      isPatchable,
      vulnerabilitySeverity,
      dispatch,
      resourceUuid,
      sortInfo,
      libraryType,
      total,
      isMachineImages,
      vulnerabilityType,
      instanceId,
      containerId,
      resourceType,
    ],
  );

  useEffect(() => {
    getVulnerabilities();
  }, [getVulnerabilities]);
  const vulnerabilities = useMemo(() => {
    if (resourceType === NativeResources.ElasticContainerRegistry)
      return imageVulnerabilities?.[instanceId!] ?? PagedQueryState.init([]);
    if (isMachineImages) {
      return (
        vulnerabilityDetails[vulnerabilityType]?.[resourceUuid!] ??
        PagedQueryState.init([])
      );
    }

    const resourceKey = resourceVulnerabilityKey(
      instanceId ?? '',
      containerId ?? '',
    );

    return (
      vulnerabilityDetails[vulnerabilityType]?.[resourceKey!] ??
      PagedQueryState.init([])
    );
  }, [
    containerId,
    //containerImageId,
    imageVulnerabilities,
    instanceId,
    isMachineImages,
    vulnerabilityDetails,
    vulnerabilityType,
    resourceType,
    resourceUuid,
  ]);

  const padding = {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  };

  const detectedIn = {
    header:
      resourceType === NativeResources.ElasticContainerRegistry
        ? 'Name'
        : 'Detected In',
    accessor: 'package_name',
    align: 'left',
    render: ({ row, value }) => (
      <StackedCell
        upper={value}
        lower={row?.installed_version ?? row?.package_version}
      />
    ),
    styles: {
      width: '200px',
      cell: { maxWidth: '200px' },
    },
  };

  const columns: TableColumnProps[] = [
    {
      header: (
        <Box pl="thLeftPaddingWithIcon">
          {resourceType === NativeResources.ElasticContainerRegistry
            ? 'CVE-ID'
            : 'Finding'}
        </Box>
      ),
      accessor: 'cve_id',
      align: 'left',
      styles: {
        width: '200px',
        ...padding,
        cell: {
          maxWidth: '200px',
          ...padding,
        },
      },
      render: ({ value, row }) => (
        <WithResourceIcon
          resourceType="ExclamationTriangle"
          bgColor={row.severity}
        >
          <CustomTooltip label={row?.cve_id ?? row?.name}>
            {row?.cve_id ?? row?.name}
          </CustomTooltip>
        </WithResourceIcon>
      ),
    },
    ...(resourceType === NativeResources.ElasticContainerRegistry
      ? [detectedIn]
      : []),
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      sortKey: 'severity',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      styles: {
        width: '90px',
        cell: { maxWidth: '90px' },
      },
    },
    {
      header: containerImageId ? 'CVSS Score' : 'CVSS V3 Score',
      accessor: 'cvss_v3.score',
      sortKey: 'score',
      align: 'center',
      styles: {
        width: '130px',
        cell: { maxWidth: '130px' },
      },
      render: ({ row }) => {
        const value = row?.cvss_v3?.score ?? row?.CVSS2_SCORE;

        return <Center>{value || value === 0 ? round(value, 1) : '-'}</Center>;
      },
    },
    ...(resourceType === NativeResources.ElasticContainerRegistry
      ? []
      : [detectedIn]),
    ...(containerImageId ||
    showResolvedVulnerabilities ||
    resourceType === NativeResources.ElasticContainerRegistry
      ? []
      : [
          {
            header: 'Fixed In',
            align: 'left',
            render: ({ row }) => (
              <StackedCell
                upper={row?.fixed_in_version}
                lower={'Published: ' + timeLabel(row?.published_date)}
                lowerTooltip={timeTooltipLabel(row?.published_date)}
              />
            ),
            styles: {
              width: '150px',
              cell: { maxWidth: '150px' },
            },
          },
        ]),
    ...(containerImageId ||
    resourceType === NativeResources.ElasticContainerRegistry
      ? [
          {
            header: 'CVSS Vector',
            accessor: 'cvss_v3',
            align: 'left',
            render: ({ value }) => value?.vector,
          },
        ]
      : []),
    {
      header: 'Detection',
      accessor: '',
      align: 'left',
      render: () => (containerImageId ? 'AWS' : 'Base Package'),
      styles: {
        width: '150px',
        minW: '150px',
        pr: 0,
        cell: { maxWidth: '60px', minW: '60px', pr: 0 },
      },
    },
  ];

  return (
    <>
      <Box h={isExpanded ? 'full' : '400px'}>
        <Table
          data={vulnerabilities.data ?? []}
          columns={columns}
          isLoading={vulnerabilities.isLoading}
          onRowClick={row => {
            detailsDisclosure.onOpen();
            setCurrentRecord(row);
          }}
          {...(containerImageId
            ? {}
            : {
                pagination: {
                  pageInfo: vulnerabilities?.page.info,
                  onChange: info => {
                    getVulnerabilities(info);
                  },
                  totalCount: vulnerabilities?.page.totalCount,
                },
              })}
          onSort={s => {
            if (!isEqual(sortInfo, s)) {
              setSortInfo(s);
            }
          }}
          sortBy={sortInfo}
        />
      </Box>
      <Drawer
        isOpen={detailsDisclosure.isOpen}
        onClose={detailsDisclosure.onClose}
        header={<MetadataHeader />}
        body={<CVEDetails currentTechInstance={currentRecord} />}
        styles={{ content: { maxW: '1100px' } }}
      />
    </>
  );
};
