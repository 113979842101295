import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, Center, Checkbox, HStack } from '@chakra-ui/react';
import { flatten, sortBy } from 'lodash';
import { BiChevronDown } from 'react-icons/all';
import { components } from 'react-select';
import { colorMap, customTheme } from 'theme';

import {
  defaultCheckBoxStyles,
  defaultSelectStyles,
  Select,
} from 'components/DataEntry';
import { FilterComponent } from 'components/FilterView';
import { EnvironmentIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { useNavBarContext } from 'components/Navigation/NavBar/context';
import { EnvironmentTypes } from 'containers/App/types';
import { limitedString } from 'utils/string';

export interface SelectCloudAccountProps {}

const SELECTED_ENV_OR_ACCOUNT = 'selected/env_or_account';

const CLOUD_PROVIDERS = ['aws'];
const ENV_ORDER: Record<string, number> = {};
const ENV_LABEL: Record<string, string> = {
  azure_ad: 'Azure AD',
  snowflake: 'DATA SASS',
  okta: 'Okta',
};

const saveSelectedAccount = (orgId, targetAccountOption) => {
  localStorage.setItem(
    `${orgId}/${SELECTED_ENV_OR_ACCOUNT}`,
    JSON.stringify(targetAccountOption),
  );
};

const getSelectedAccount = orgId => {
  return localStorage.getItem(`${orgId}/${SELECTED_ENV_OR_ACCOUNT}`);
};

export function SelectCloudEnvironmentOrAccount(
  props: SelectCloudAccountProps,
) {
  const {
    user,
    environments: cloudEnvironmentsState,
    accounts: cloudAccountsState,
    onSelectEnvironmentOrAccount,
  } = useNavBarContext();

  const [
    selectedEnvironmentOption,
    setSelectedEnvironmentOption,
  ] = useState<any>(null);
  const orgId = user.info.org_id;

  const handleAccountSelection = useCallback(
    (data: { label: ReactNode | string; value: any } | undefined) => {
      if (!data) return;
      onSelectEnvironmentOrAccount(data.value);
      setSelectedEnvironmentOption(data);
      saveSelectedAccount(orgId, data);
    },
    [onSelectEnvironmentOrAccount, orgId],
  );

  const handleAutoAccountSelection = useCallback(
    account => {
      if (
        selectedEnvironmentOption &&
        getSelectedAccount(orgId) === JSON.stringify(account)
      ) {
        return;
      }
      onSelectEnvironmentOrAccount(account.value);
      setSelectedEnvironmentOption(account);
      saveSelectedAccount(orgId, account);
    },
    [onSelectEnvironmentOrAccount, orgId, selectedEnvironmentOption],
  );

  const createEnvironmentOptions = useCallback(() => {
    if (cloudAccountsState.loading || cloudEnvironmentsState.loading) return [];
    const environments = cloudEnvironmentsState.data;
    if (environments?.length === 0) {
      return [];
    }

    const options: any[] = environments.map((e, envIndex) => {
      return [
        ...(e.id
          ? [
              {
                label: `ENV: ${e.name}`,
                value: {
                  type: EnvironmentTypes.Environment,
                  id: e.name,
                  cloudId: e.name,
                  name: e.name,
                  cloud: '',
                  environmentId: e.id,
                  environmentName: e.name,
                  environment: e,
                },
                key: e.id,
                order: `${envIndex}.`,
              },
            ]
          : []),
        ...Array.from(e.account_ids ?? [])
          .map(id => {
            return cloudAccountsState.data.find(a => a.uuid === id);
          })
          // .filter(identity)
          .map((a, accountIndex) => ({
            label: `${limitedString(a?.name ?? '', 20)} (${
              a?.cloud_account_id
            })`,
            icon: getIcon(a?.cloud_type!),
            value: {
              isAccount: true,
              type: EnvironmentTypes.Account,
              id: a?.uuid,
              cloudId: a?.cloud_account_id,
              name: a?.name,
              cloud: a?.cloud_type,
              environmentId: e.id,
              environmentName: e.name,
              account: { id: a?.uuid, ...a },
            },
            key: `${a?.cloud_account_id}-${e.id ?? '-'}`,
            order: `${envIndex}.${accountIndex}`,
          })),
      ];
    });
    return sortBy(flatten(options), e => `${e.order}`);
  }, [
    cloudAccountsState.loading,
    cloudAccountsState.data,
    cloudEnvironmentsState.loading,
    cloudEnvironmentsState.data,
  ]);

  // console.log(
  //   'cloud accounts',
  //   cloudAccountsState.data.map(a => a.uuid),
  // );
  // console.log(
  //   'cloud environments',
  //   cloudEnvironmentsState.data.map(e => e.account_ids),
  // );

  useEffect(() => {
    if (cloudEnvironmentsState.loaded) {
      const entities = createEnvironmentOptions();
      // environments are unavailable
      if (entities.length === 0) {
        handleAutoAccountSelection({
          label: '',
          value: { type: EnvironmentTypes.Unknown },
        });
        return;
      }

      // set previously selected env/account
      const selectedEnvironmentOrAccount = JSON.parse(
        getSelectedAccount(orgId) ?? '{}',
      );
      // console.log('previouslySelected', orgId, selectedEnvironmentOrAccount);
      const previouslySelected = entities.find(
        e => e.key === selectedEnvironmentOrAccount?.key,
      );

      // console.log(previouslySelected, entities);
      if (previouslySelected) {
        handleAutoAccountSelection(previouslySelected);
        return;
      }
      // select the default env
      const targetAccountOption = entities[0];
      handleAutoAccountSelection(targetAccountOption);
    }
  }, [
    createEnvironmentOptions,
    cloudEnvironmentsState.loaded,
    handleAutoAccountSelection,
    orgId,
  ]);

  const CustomOption = props => {
    const { data } = props;

    const isChecked = useMemo(() => {
      if (selectedEnvironmentOption.value.type === EnvironmentTypes.Account) {
        return selectedEnvironmentOption.key === data.key;
      }

      return (
        selectedEnvironmentOption.value.environmentId ===
        data.value.environmentId
      );
    }, [data.key, data.value.environmentId]);

    return (
      <components.Option
        {...props}
        isSelected={data.key === selectedEnvironmentOption.key}
      >
        {data.value.isAccount && (
          <Box pl={3} w="full">
            <Checkbox
              isChecked={isChecked}
              // isDisabled={isDisabled}
              value={data.label}
              alignItems="center"
              {...defaultCheckBoxStyles}
            >
              <HStack spacing={2} align="flex-start">
                <Box boxSize={6} pb={1}>
                  {data?.icon}
                </Box>
                <Box flex={1}>{data.label}</Box>
              </HStack>
            </Checkbox>
          </Box>
        )}
        {!data.value.isAccount && (
          <Box
            fontWeight={600}
            pl={data.value.type === EnvironmentTypes.Account ? 3 : 0}
          >
            {data.label}
          </Box>
        )}
      </components.Option>
    );
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <BiChevronDown color="white" size={20} />
      </components.DropdownIndicator>
    );
  };

  const SingleValue = props => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <HStack>
          <Center h="full" boxSize={5}>
            <Center color="white">
              {data?.icon ?? <EnvironmentIcon color="white" />}
            </Center>
          </Center>
          <Box overflow="hidden" textOverflow="ellipsis">
            {props.children}
          </Box>
        </HStack>
      </components.SingleValue>
    );
  };

  const customFilter = (option, searchText) => {
    return (
      option.value?.cloudId?.toLowerCase().includes(searchText.toLowerCase()) ||
      option.value?.name?.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <Box className="cloud_accounts">
      <FilterComponent excludePaths={['setup', 'policy-hub', 'log', 'home']}>
        <FilterComponent filterKey={'select_cloud_account'}>
          <Select
            isLoading={
              cloudEnvironmentsState.loading || cloudAccountsState.loading
            }
            isSearchable
            filterOption={customFilter}
            onChange={handleAccountSelection}
            options={createEnvironmentOptions()}
            value={selectedEnvironmentOption}
            components={{
              Option: CustomOption,
              DropdownIndicator,
              SingleValue,
            }}
            styles={{
              menu: provided => ({
                ...provided,
                width: 'max-content',
                minWidth: '100%',
                right: 0,
              }),
              control: (styles, props) => ({
                ...defaultSelectStyles().control(styles, props),
                color: '#fff',
                borderColor: customTheme.colors.primary,
                backgroundColor: customTheme.colors.topNavSearchBg,
                borderRadius: '5px',
              }),
              container: (styles, props) => ({
                ...defaultSelectStyles('32px').container(styles, props),
                width: '300px',
                height: '30px',
              }),
              valueContainer: (styles, props) => ({
                ...defaultSelectStyles().valueContainer(styles, props),
                color: 'white',
                borderRadius: '5px',
              }),
              indicatorsContainer: (styles, props) => ({
                ...defaultSelectStyles().indicatorsContainer(styles),
                borderRadius: '5px',
              }),
              singleValue: (styles, props) => ({
                ...styles,
                color: 'white',
              }),
              placeholder: provided => ({
                ...provided,
                color: 'white',
                opacity: 1,
              }),
              option: (provided, { isSelected, isDisabled }) => {
                return {
                  ...provided,
                  '&:active': {
                    backgroundColor: colorMap.primary(100),
                  },

                  '&:hover': {
                    // backgroundColor: isSelected
                    //   ? colorMap.primary(300)
                    //   : 'hover',
                    color: isSelected ? '#fff' : '#000',
                  },

                  color: isSelected ? '#fff' : '#000',
                  cursor: isDisabled ? 'not-allowed' : 'default',
                  backgroundColor: isSelected ? colorMap.primary(300) : '#fff',
                  marginBottom: '1px',
                };
              },
            }}
            placeholder="Select account"
          />
        </FilterComponent>
      </FilterComponent>
    </Box>
  );
}
