/**
 *
 * Asynchronously loads the component for Blueprints
 *
 */

import { lazy } from 'react';

export const Blueprints = lazy(() =>
  import('./index').then(m => ({ default: m.Blueprints })),
);
