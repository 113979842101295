import React, { useState, useEffect } from 'react';

import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { LineChart } from 'components/Visualization/Charts';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { commonVulnerabilityLineChartStyles } from 'containers/Visibility/Vulnerabilities/Components/styles';
import { formatDate } from 'utils/date';

export const DataSourcesWithSensitiveDataTrend = () => {
  const { historicalTrend } = useSelector(selectDataDashboard);
  const { data_sources_trend } = historicalTrend.data;
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(
      map(data_sources_trend, o => {
        const date = formatDate(o?.created_at, 'D-MMM');
        const dataSourcesCount = o?.source_count || 0;

        return {
          Date: date,
          'Number of Data Sources': dataSourcesCount,
        };
      }),
    );
  }, [data_sources_trend]);

  return (
    <LineChart
      data={data}
      isLoading={historicalTrend.isLoading}
      xField="Date"
      yField={['Number of Data Sources']}
      legend={false}
      styles={{
        ...commonVulnerabilityLineChartStyles,
        yAxis: {
          tickLine: false,
          axisLine: false,
        },
      }}
    />
  );
};
