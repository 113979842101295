import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { AlertResponse } from '@ariksa/notification';
import { HStack, Stack, Text, Wrap } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DependencyPixiGraph } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Dependencies/DependencyPixiGraph';
import { selectAlertDrawer } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/selectors';

import { actions } from '../../../slice';

interface IEC2Dependencies {
  alert: AlertResponse;
}

export const ResourceDependencies: FC<IEC2Dependencies> = props => {
  const { alert } = props;
  const dispatch = useDispatch();

  const { resourceInsight } = useSelector(selectAlertDrawer);
  useEffect(() => {
    dispatch(
      actions.getResourceInsight({
        q: {
          insightV2Request: {
            resource_type: alert.resource as NativeResources,
            resource_uuids: [alert.entity_uuid!],
            account_id: [alert.account_id!],
          },
        },
      }),
    );
  }, [alert.account_id, alert.entity_uuid, alert.resource, dispatch]);

  return (
    <Stack>
      <HStack minH={'100px'} align={'start'}>
        <Stack flex={1}>
          <Text fontWeight={'bold'}>Ariksa Context</Text>
          <Wrap gap={2}>
            {resourceInsight.data?.contexts?.map(c => {
              return <Tag label={`${c.key ?? '-'}: ${c.value ?? '-'}`} />;
            })}
          </Wrap>
        </Stack>
        <Stack flex={1}>
          <Text fontWeight={'bold'}>Cloud tags</Text>
          <Wrap gap={2}>
            {resourceInsight.data?.tags?.slice(0, 4)?.map(t => {
              return <Tag label={`${t.name ?? ''}= ${t.value ?? ''}`} />;
            })}
            {(resourceInsight.data?.tags?.length ?? 0) > 4 && (
              <CustomTooltip
                label={
                  <Stack>
                    {resourceInsight.data?.tags?.slice(4)?.map(t => {
                      return (
                        <Tag label={`${t.name ?? ''}= ${t.value ?? ''}`} />
                      );
                    })}
                  </Stack>
                }
              >
                <Tag
                  label={resourceInsight.data?.tags?.slice(4)?.length}
                  styles={{
                    tag: {
                      opacity: 0.6,
                    },
                  }}
                />
              </CustomTooltip>
            )}
          </Wrap>
        </Stack>
      </HStack>

      <DependencyPixiGraph alert={alert} />
    </Stack>
  );
};
