import React, { memo, useMemo, useState } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { cloneDeep, map, merge, isEmpty } from 'lodash';
import {
  XAxis,
  YAxis,
  Bar,
  BarChart,
  Tooltip,
  CartesianGrid,
  Cell,
  LabelList,
} from 'recharts';
import { ResponsiveContainer } from 'recharts';
import { customTheme } from 'theme';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

import { defaultStyles } from './styles';
import { IBarChart } from './types';

export const CustomBarChart = (props: IBarChart) => {
  const {
    data,
    xField,
    yField,
    tooltip = true,
    legend = true,
    colors,
    stacked = false,
    customTooltipContent,
    styles,
    overwriteDefaultStyles = false,
    colorMap = {},
    onClick,
    cartesianGrid = false,
    isLoading = false,
    labelListKey,
  } = props;
  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);
  const [localColors] = useState(
    colors ?? yField?.map(y => colorMap?.[y]) ?? [],
  );

  return (
    <Box {...baseStyles?.root}>
      {isLoading ? (
        <CustomSpinner loading={isLoading} />
      ) : isEmpty(data) ? (
        <NoDataAvailable />
      ) : (
        <Stack w="full" h="full">
          {legend && (
            <HStack
              w="full"
              justify="flex-end"
              spacing={3}
              fontSize="14px"
              {...styles?.legends}
            >
              {yField?.map((item, index) => (
                <HStack spacing={1}>
                  <Box
                    boxSize={2.5}
                    bg={localColors?.[index % localColors?.length]}
                    borderRadius={2}
                  />
                  <Box color={customTheme.colors.gray['300']}>{item}</Box>
                </HStack>
              ))}
            </HStack>
          )}
          <ResponsiveContainer>
            <BarChart data={data} {...baseStyles?.barChart}>
              <XAxis dataKey={xField} {...baseStyles?.xAxis} />
              <YAxis {...baseStyles?.yAxis} allowDecimals={false} />
              {cartesianGrid && (
                <CartesianGrid {...(baseStyles?.cartesianGrid as any)} />
              )}
              {tooltip && (
                <Tooltip
                  {...baseStyles?.tooltip}
                  content={customTooltipContent}
                />
              )}
              {map(yField, (each, index) => {
                const barColor = colorMap?.[each];
                return (
                  <Bar
                    key={`bar-${index}`}
                    {...(baseStyles?.bar as any)}
                    dataKey={yField[index]}
                    fill={barColor}
                    stackId={stacked && 'id'}
                    width={100}
                  >
                    {labelListKey && (
                      <LabelList
                        dataKey={labelListKey}
                        position="top"
                        fill={'#000'}
                        fontSize={11}
                        {...baseStyles?.labelList}
                      />
                    )}

                    {data.map((rc, cellIndex) => {
                      const cellColor =
                        barColor ??
                        colors?.[cellIndex % colors.length] ??
                        `#${Math.floor(Math.random() * 16777215).toString(16)}`;

                      return (
                        <Cell
                          key={`cell-${cellIndex}`}
                          fill={cellColor}
                          onClick={e => onClick?.(e, yField[index], rc)}
                        />
                      );
                    })}
                  </Bar>
                );
              })}
              {/*{legend && <Legend {...(baseStyles?.legend as any)} />}*/}
              {/*{legend && (
                <Legend
                  {...(baseStyles?.legend as any)}
                  payload={yField.map((item, index) => ({
                    id: item,
                    type: 'square',
                    value: `${item}`,
                    color: localColors?.[index % localColors?.length],
                  }))}
                />
              )}*/}
            </BarChart>
          </ResponsiveContainer>
        </Stack>
      )}
    </Box>
  );
};

export const MemoBarChart = memo<IBarChart>(CustomBarChart);
