import React from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { registerLayout } from 'components/Visualization/ColumnarGraph/registerLayout';
import { AccessGraphWithContext } from 'components/Visualization/PixiGraph/components/AccessGraphWithContext';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

import 'components/Visualization/Graphs/nodes/register_graph_elements';

registerLayout();

export const AccessGraph = () => {
  const { canAccessSummary } = useSelector(selectActiveResource);
  const { data, isLoading } = canAccessSummary.accessMap;
  return (
    <Box pos={'relative'} h={'full'}>
      <AccessGraphWithContext
        data={canAccessSummary.accessMap.data}
        menu
        isLoading={canAccessSummary.accessMap.isLoading}
        isLoaded={canAccessSummary.accessMap.isSuccess}
      />
    </Box>
  );
};
