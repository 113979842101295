import React, { useState, FC, useEffect } from 'react';

import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { useWhitelistContext } from 'containers/Inventory/Whitelist/context';
import { selectWhitelist } from 'containers/Inventory/Whitelist/selector';
import { tagStyles } from 'containers/PolicyHub/AlertWorkflow/styles';
import { quantifierOptions } from 'containers/Setup/Environment/utils';

export const CriteriaQuantifier: FC = () => {
  const { formData } = useSelector(selectWhitelist);
  const [quantifier, setQuantifier] = useState<Record<string, any>>({});
  const { updateFormData } = useWhitelistContext();

  useEffect(() => {
    setQuantifier(
      quantifierOptions.find(o => o?.value === formData?.criteria_condition) ||
        quantifierOptions[0],
    );
  }, [formData?.criteria_condition]);

  return (
    <Wrap align="center">
      <WrapItem>
        <Tag label="IF" styles={tagStyles} />
      </WrapItem>
      <WrapItem>
        <Box w={24}>
          <Select
            options={quantifierOptions}
            value={quantifier}
            onChange={selected =>
              updateFormData('criteria_condition', selected)
            }
          />
        </Box>
      </WrapItem>
      <WrapItem>
        <Text>
          of the following conditions in the environment are satisfied
        </Text>
      </WrapItem>
    </Wrap>
  );
};
