import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CheckmarkCircleIcon = props => (
  <Icon viewBox="0 0 54 54" w="full" h="full" color="inherit" {...props}>
    <defs>
      <clipPath id="clip0">
        <rect x="910" y="538" width="54" height="54" />
      </clipPath>
      <clipPath id="clip1">
        <rect x="911" y="539" width="53" height="52" />
      </clipPath>
      <clipPath id="clip2">
        <rect x="911" y="539" width="53" height="52" />
      </clipPath>
      <clipPath id="clip3">
        <rect x="911" y="539" width="53" height="52" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)" transform="translate(-910 -538)">
      <g clipPath="url(#clip1)">
        <g clipPath="url(#clip2)">
          <g clipPath="url(#clip3)">
            <path
              d="M25.9886 5.41667C14.6271 5.41667 5.41667 14.6271 5.41667 25.9886 5.41667 37.3502 14.6271 46.5606 25.9886 46.5606 37.3502 46.5606 46.5606 37.3502 46.5606 25.9886 46.5606 25.9881 46.5606 25.9875 46.5606 25.987 46.5645 14.6302 37.3611 5.42057 26.0043 5.41667 25.9991 5.41667 25.9939 5.41667 25.9886 5.41667ZM30.972 26.9712C27.9748 29.963 24.9595 32.9814 21.9261 36.0263 19.3485 33.4364 16.7647 30.8526 14.1749 28.275L16.7646 25.6853 21.9261 30.8468C24.4319 28.305 26.9235 25.781 29.4011 23.2749 31.8771 20.7691 33.247 19.4242 35.815 16.9423 35.887 16.8702 35.9645 16.7987 36.0485 16.7256 36.1298 16.6566 36.2026 16.578 36.2651 16.4916L38.8911 19.0813C35.841 22.1146 33.969 23.978 30.9714 26.9696Z"
              fill="currentColor"
              fillRule="nonzero"
              fillOpacity="1"
              transform="matrix(1.01923 0 0 1 911 539)"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
