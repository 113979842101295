import React, { FC, useEffect, useState } from 'react';

import { TaskStatus } from '@ariksa/awm';
import { AccountType } from '@ariksa/cloud-account';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useWorkflowService } from 'services/Workflow/useWorkflowService';

import { Modal, PrimaryButton, CancelButton } from 'app/components';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

interface Props {
  accountType: AccountType;
  isLoading?: boolean;
}

export const RediscoverConfirmationModal: FC<Props> = props => {
  const { accountType, isLoading } = props;
  const { latestSnapshot } = useWorkflowService();
  const {
    currentRecord,
    onConfirmRediscoverInventory,
    isOpenRediscoverModal,
    onCloseRediscoverModal,
  } = useIntegrationsContext();

  useEffect(() => {
    latestSnapshot.sync({
      getLatestWorkflowRequest: { account_ids: [currentRecord?.uuid] },
    });
  }, [latestSnapshot, currentRecord]);

  const [isPendingSnapshot, setIsPendingSnapshot] = useState(false);

  useEffect(() => {
    const data = latestSnapshot.data?.[0];
    setIsPendingSnapshot(
      data?.status !== TaskStatus.Failure &&
        data?.status !== TaskStatus.Success &&
        data?.status !== TaskStatus.Timeout,
    );
  }, [latestSnapshot.data]);

  const accountDetails = () => (
    <Box mt={2}>
      <DetailsField
        label="Name"
        value={currentRecord.name}
        placeholder="not available"
      />
    </Box>
  );

  const renderProgressMessage = () => (
    <>
      <Text>Previous snapshot is in progress for</Text>
      {accountDetails()}
    </>
  );

  const renderConfirmationMessage = () => (
    <>
      <Text>Are you sure you want to rediscover the following?</Text>
      {accountDetails()}
    </>
  );

  return (
    <>
      <Modal
        isOpen={isOpenRediscoverModal}
        onClose={onCloseRediscoverModal}
        isCentered
        header={'Rediscover confirmation'}
        footer={
          <Flex justifyContent={'space-between'} w="full">
            <CancelButton onClick={onCloseRediscoverModal} />
            <PrimaryButton
              onClick={() => onConfirmRediscoverInventory(accountType)}
              isDisabled={isPendingSnapshot}
              isLoading={isLoading}
            >
              Yes
            </PrimaryButton>
          </Flex>
        }
        body={
          latestSnapshot.isLoading ? (
            <CustomSpinner loading={latestSnapshot.isLoading} />
          ) : isPendingSnapshot ? (
            renderProgressMessage()
          ) : (
            renderConfirmationMessage()
          )
        }
      />
    </>
  );
};
