import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SSMDocumentIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <ellipse fill="currentColor" cx="371.6" cy="427.2" rx="13.5" ry="13.4" />
      <ellipse fill="currentColor" cx="436.4" cy="427.2" rx="13.5" ry="13.4" />
      <path
        fill="currentColor"
        d="M242,416.5h-86.4c-6,0-10.8,4.8-10.8,10.8s4.8,10.8,10.8,10.8H242c6,0,10.8-4.8,10.8-10.8
		S247.9,416.5,242,416.5z"
      />
      <path
        fill="currentColor"
        d="M242,287.4h-86.4c-6,0-10.8,4.8-10.8,10.8s4.8,10.8,10.8,10.8H242c6,0,10.8-4.8,10.8-10.8
		S247.9,287.4,242,287.4z"
      />
      <path
        fill="currentColor"
        d="M512,190.7v-43c0-29.7-24.2-53.7-54-53.8H257.4C238.1,44.6,189.7,9.5,133.2,9.5C59.8,9.5,0,68.7,0,141.5
		c0,54.7,33.7,101.8,81.7,121.8c-1.1,4.3-1.8,8.8-1.8,13.4v43c0,17.7,8.7,33.2,21.9,43c-13.2,9.8-21.9,25.3-21.9,43v43
		c0,29.7,24.2,53.7,54,53.8H458c29.8,0,54-24.1,54-53.8v-43c0-17.7-8.7-33.2-21.9-43c13.2-9.8,21.9-25.3,21.9-43v-43
		c0-17.7-8.7-33.2-21.9-43C503.3,223.9,512,208.3,512,190.7z M10.7,141.5c0-66.9,55-121.4,122.6-121.4s122.6,54.5,122.6,121.4
		s-55,121.4-122.6,121.4S10.7,208.5,10.7,141.5z M490.4,405.7v43c0,17.8-14.5,32.2-32.4,32.3H133.9c-17.9,0-32.4-14.5-32.4-32.3v-43
		c0-17.8,14.5-32.2,32.4-32.3H458C475.9,373.5,490.4,387.9,490.4,405.7z M490.4,276.7v43c0,17.8-14.5,32.2-32.4,32.3H133.9
		c-17.9,0-32.4-14.5-32.4-32.3v-43c0-2.3,0.3-4.6,0.8-6.7c9.9,2.4,20.3,3.6,30.9,3.6c31.5,0,60.5-10.9,83.4-29.2H458
		C475.9,244.4,490.4,258.9,490.4,276.7z M490.4,190.7c0,17.8-14.5,32.2-32.4,32.3H238c17.8-22.4,28.4-50.7,28.4-81.4
		c0-9-0.9-17.7-2.6-26.2H458c17.9,0,32.4,14.5,32.4,32.3V190.7z"
      />
      <ellipse fill="currentColor" cx="371.6" cy="298.2" rx="13.5" ry="13.4" />
      <path
        fill="currentColor"
        d="M436.4,155.7c-7.5,0-13.5,6-13.5,13.4s6,13.4,13.5,13.4c7.5,0,13.5-6,13.5-13.4S443.8,155.7,436.4,155.7z"
      />
      <path
        fill="currentColor"
        d="M371.6,155.7c-7.5,0-13.5,6-13.5,13.4s6,13.4,13.5,13.4c7.5,0,13.5-6,13.5-13.4S379,155.7,371.6,155.7z"
      />
      <ellipse fill="currentColor" cx="436.4" cy="298.2" rx="13.5" ry="13.4" />
      <path
        fill="currentColor"
        d="M201.4,222.7V95.1c0-1.1-0.4-2.1-1.2-2.9L159,51c-0.8-0.8-1.8-1.2-2.9-1.2H77.9c-4.9,0-8.2,3.4-8.2,8.2v164.7
		c0,4.7,3.5,8.2,8.2,8.2h115.4C197.9,231,201.4,227.4,201.4,222.7z M160.2,63.9L187.4,91h-27.1V63.9z M77.9,58H152v37.1
		c0,2.3,1.8,4.1,4.1,4.1h37.1l0.1,123.5H77.9L77.9,58z"
      />
      <rect x="98.5" y="115.7" fill="currentColor" width="53.5" height="8.2" />
      <rect x="98.5" y="144.5" fill="currentColor" width="70" height="8.2" />
      <rect x="98.5" y="173.3" fill="currentColor" width="53.5" height="8.2" />
    </g>
  </Icon>
);
