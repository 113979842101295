import React from 'react';

import { Icon } from '@chakra-ui/react';

export const MonitoringIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M256,153.6c-56.6,0-102.4,45.8-102.4,102.4S199.4,358.4,256,358.4c56.5-0.1,102.3-45.9,102.4-102.4
	C358.4,199.4,312.6,153.6,256,153.6z M256,332.8c-42.4,0-76.8-34.4-76.8-76.8s34.4-76.8,76.8-76.8c42.4,0,76.8,34.4,76.8,76.8
	C332.8,298.4,298.4,332.8,256,332.8z M511,251C458.7,127.8,361,51.2,256,51.2S53.3,127.8,1,251c-1.4,3.2-1.4,6.8,0,10
	C53.3,384.2,151,460.8,256,460.8S458.7,384.2,511,261C512.3,257.8,512.3,254.2,511,251z M256,435.2c-93.2,0-180.6-68.5-229.2-179.2
	C75.4,145.3,162.8,76.8,256,76.8S436.6,145.3,485.2,256C436.6,366.7,349.2,435.2,256,435.2z"
    />
  </Icon>
);
