import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'app/components';
import { CloudInventory } from 'containers/Inventory/CloudInventory/Loadable';
import { InventoryContext } from 'containers/Inventory/InventoryContext/Loadable';
import { inventoryContextSaga } from 'containers/Inventory/InventoryContext/saga';
import * as contextSlice from 'containers/Inventory/InventoryContext/slice';
import { Whitelist } from 'containers/Inventory/Whitelist/Loadable';
import { whitelistSaga } from 'containers/Inventory/Whitelist/saga';
import * as whitelistSlice from 'containers/Inventory/Whitelist/slice';
import { useInjector } from 'utils/inject';

export const Inventory = memo(() => {
  useInjector(
    contextSlice.sliceKey,
    contextSlice.reducer,
    inventoryContextSaga,
  );
  useInjector(whitelistSlice.sliceKey, whitelistSlice.reducer, whitelistSaga);
  return (
    <Routes>
      <Route element={<FilterRouter path="/inventory/summary" />}>
        <Route path="summary" element={<CloudInventory />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/allowlist" />}>
        <Route path="allowlist/*" element={<Whitelist />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/context" />}>
        <Route path="context/*" element={<InventoryContext />} />
      </Route>
    </Routes>
  );
});
