import React, { FC, useEffect, useState } from 'react';

import { Entities } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';
import { filter, includes } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';

import { actions } from '../../../../slice';
import { severityOptions } from '../../../../utils';

interface Props {}

export const Severity: FC<Props> = props => {
  const { alertConditions } = useSelector(selectAlertWorkflow);
  const [selected, setSelected] = useState<Record<string, any>[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(
      filter(severityOptions, o =>
        includes(alertConditions[Entities.Severity]?.entity_list, o.value),
      ),
    );
  }, [alertConditions]);

  return (
    <Stack w="full" isInline>
      <Box whiteSpace="nowrap" pt={1}>
        If alert or policy has severity of
      </Box>
      <Box minW={48}>
        <Select
          options={severityOptions}
          defaultValue={severityOptions[0]}
          value={selected}
          onChange={selectedOptions =>
            dispatch(
              actions.updateAlertWorkflowConditionByID({
                name: Entities.Severity,
                entity_list: map(selectedOptions, each => each.value),
              }),
            )
          }
          isMulti
        />
      </Box>
    </Stack>
  );
};
