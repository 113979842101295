import React, { FC, useEffect, useState } from 'react';

import { AlertHistoryBy } from '@ariksa/notification';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { QueryStatus } from 'services/types';

import { NotificationService } from 'api/services';
import { LineChart } from 'components/Visualization';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';
import { formatDate } from 'utils/date';

import { actions } from '../../slice';

interface Props {
  alertCategory?: string;
  categoryClass?: string;
}

export const TopOpenAlertsChart: FC<Props> = props => {
  const { alertCategory, categoryClass } = props;
  const [data, setData] = useState<any>([]);
  const { withAccessBoundary, environmentId, accountId } = useAccessBoundary();
  const { alertHistoryByTrend, topOpenAlerts } = useSelector(
    selectFindingsOverview,
  );
  const dispatch = useDispatch();
  const { aiParamExtraction } = useSelector(selectSharedState);

  useEffect(() => {
    if ((!!environmentId || !!accountId) && !!alertCategory) {
      const endDate = aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD');

      const startDate = aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(6, 'day').format('YYYY-MM-DD');

      const payload = withAccessBoundary({
        startDate,
        endDate,
        historyBy: AlertHistoryBy.Trend,
        alertCategory,
        categoryClass,
      });

      dispatch(
        actions.getTopOpenAlerts({
          q: payload,
          status: QueryStatus.pending,
        }),
      );

      NotificationService.Alerts.getAlertHistory(payload).then(res => {
        dispatch(
          actions.getTopOpenAlerts({
            q: payload,
            status: QueryStatus.fulfilled,
            data: res.data,
          }),
        );
      });
    }
  }, [
    dispatch,
    accountId,
    environmentId,
    withAccessBoundary,
    alertCategory,
    categoryClass,
    aiParamExtraction.data,
  ]);

  useEffect(() => {
    const data = alertCategory
      ? topOpenAlerts[alertCategory]?.data
      : alertHistoryByTrend.data;

    const graphData = map(data?.alert_history, (v, date) => ({
      date: formatDate(date, 'D-MMM'),
      day: formatDate(date, 'DD-MMM'),
      Open: (v.CRITICAL ?? 0) + (v.HIGH ?? 0) + (v.MEDIUM ?? 0) + (v.LOW ?? 0),
    }));
    setData(graphData);
  }, [alertHistoryByTrend.data, alertCategory, topOpenAlerts]);

  const userTrend = {
    data,
    xField: 'day',
    yField: ['Open'],
  };

  return (
    <LineChart
      {...userTrend}
      isLoading={
        !!alertCategory
          ? topOpenAlerts[alertCategory]?.isLoading
          : alertHistoryByTrend.isLoading
      }
      styles={{
        lineChart: { margin: { bottom: -30, left: -10 } },
      }}
      // customTooltipContent={customTooltipContent}
    />
  );
};
