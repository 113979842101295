import {
  CategoryResponse,
  CommandsApiAttachPolicyRequest,
  CommandsApiCreateRemediationRequest,
  CommandsApiDeleteRemediationRequest,
  CommandsApiDetachPolicyRequest,
  CommandsApiGetCommandsRequest,
  CommandsApiGetPoliciesByRemediationIdRequest,
  CommandsApiGetRemediationByIdRequest,
  CommandsApiGetRemediationRequest,
  CommandsApiUpdateRemediationRequest,
  CommandsResponse,
  PageRemediationsbyCategory,
  PolicyResponse,
  RemediationInfo,
  RuleReadWithMetadata,
  RulesApiGetAllRulesWithoutPaginationRequest,
} from '@ariksa/compliance-policies/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo, SideNavKey } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the remediation container
export const initialState: ContainerState = {
  remediationList: PagedQueryState.init([]),
  remediation: QueryState.init({}),
  remediationAction: QueryState.init({}),
  commands: QueryState.init([]),
  remediationCategories: QueryState.init([]),
  alerts: QueryState.init([]),
  currentCategory: '',
};

const remediationSlice = createSlice({
  name: 'remediation',
  initialState,
  reducers: {
    getRemediationList(
      state,
      action: QueryAction<
        PageRemediationsbyCategory,
        CommandsApiGetRemediationRequest
      >,
    ) {
      state.remediationList = PagedQueryState.next(
        state.remediationList,
        action,
        {
          mapData: res => res?.items ?? [],
          mapTotalCount: res => res?.total ?? 0,
        },
      );
    },

    getRemediation(
      state,
      action: QueryAction<
        RemediationInfo,
        CommandsApiGetRemediationByIdRequest
      >,
    ) {
      state.remediation = QueryState.next(state.remediation, action);
    },

    getRemediationCategories(state, action: QueryAction<CategoryResponse[]>) {
      state.remediationCategories = QueryState.next(
        state.remediationCategories,
        action,
        {
          mapData: res => {
            state.currentCategory = res?.[0]?.category ?? '';
            return res;
          },
        },
      );
    },

    getCommands(
      state,
      action: QueryAction<CommandsResponse[], CommandsApiGetCommandsRequest>,
    ) {
      state.commands = QueryState.next(state.commands, action);
    },

    updateRemediationListPagination(state, action: PayloadAction<PageInfo>) {
      state.remediationList.page.info = action.payload;
    },

    updateRemediationCategory(state, action: PayloadAction<SideNavKey>) {
      state.currentCategory = action.payload;
    },

    addRemediation(
      state,
      action: QueryAction<any, CommandsApiCreateRemediationRequest>,
    ) {
      state.remediationAction = QueryState.next(
        state.remediationAction,
        action,
      );
    },
    updateRemediation(
      state,
      action: QueryAction<any, CommandsApiUpdateRemediationRequest>,
    ) {
      state.remediationAction = QueryState.next(
        state.remediationAction,
        action,
      );
    },
    deleteRemediation(
      state,
      action: QueryAction<any, CommandsApiDeleteRemediationRequest>,
    ) {
      state.remediationAction = QueryState.next(
        state.remediationAction,
        action,
      );
    },

    getRules(
      state,
      action: QueryAction<
        RuleReadWithMetadata[],
        RulesApiGetAllRulesWithoutPaginationRequest
      >,
    ) {
      state.alerts = QueryState.next(state.alerts, action);
    },
    getRulesAttachedToRemediation(
      state,
      action: QueryAction<
        PolicyResponse[],
        CommandsApiGetPoliciesByRemediationIdRequest
      >,
    ) {
      state.alerts = QueryState.next(state.alerts, action);
    },

    attachRemediationToAlert(
      state,
      action: QueryAction<any, CommandsApiAttachPolicyRequest>,
    ) {
      state.remediationAction = QueryState.next(
        state.remediationAction,
        action,
      );
    },
    detachRemediationFromAlert(
      state,
      action: QueryAction<any, CommandsApiDetachPolicyRequest>,
    ) {
      state.remediationAction = QueryState.next(
        state.remediationAction,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = remediationSlice;
