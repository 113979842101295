import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { DatabaseIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';
import { getSumOfValues } from 'containers/Dashboard/utils/utils';

export const DataSources: React.FC = () => {
  const { infrastructurePostureSummary, dataCount } = useSelector(
    selectDashboard,
  );
  const { data, isLoading } = infrastructurePostureSummary;
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Data Sources"
      icon={<DatabaseIcon />}
      change={{
        isLoading: false,
        first: dataCount.data.previous_count ?? 0,
        last: dataCount.data.current_count ?? 0,
        percentChange: dataCount.data.percentage_change ?? 0,
      }}
      cloudProviders={[]}
      count={getSumOfValues(data?.items?.['Data'])}
      isLoading={isLoading}
      onClick={() => navigate('/inventory/summary?tab=Data')}
      labelTooltip={formatTooltip({
        header: 'Data sources',
        content:
          'Buckets, Disks, Databases, File stores and Data warehouses in public clouds and SaaS',
        width: 48,
      })}
    />
  );
};
