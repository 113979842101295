import React, { useEffect, FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { PageHeaderWithBackButton } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CriticalDataIcon, IconTypes } from 'components/Icons';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useDataDashboardContext } from 'containers/Visibility/Data/Components/hooks/context';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  currentRecord: Record<string, any>;
  setShowDataTypeSources?(val: boolean);
}

export const SourcesByDataType: FC<Props> = props => {
  const { currentRecord, setShowDataTypeSources } = props;
  const { selectedTab, dataTypeSensitiveSources } = useSelector(
    selectDataDashboard,
  );
  const { dataTypeSourcesColumns, tableActionModals } = useDataTableColumns(
    MetadataTabs.Data,
  );
  const dispatch = useDispatch();

  const { getDataTypeSources } = useDataDashboardContext();

  useEffect(() => {
    !!currentRecord?.sensitive_data_name &&
      getDataTypeSources(currentRecord?.sensitive_data_name);
  }, [getDataTypeSources, currentRecord]);

  return (
    <Stack w={'full'} h={'full'}>
      <Box>
        <PageHeaderWithBackButton
          label={currentRecord.sensitive_data_name}
          onClickBack={() => setShowDataTypeSources?.(false)}
          fontSize="sm"
          reversed
          icon={<CriticalDataIcon />}
          iconBgColor="sensitiveDataBg"
          useCustomColor
        />
      </Box>
      <Box flex={1} pt={3} maxW="100%">
        <Table
          data={dataTypeSensitiveSources.data}
          columns={dataTypeSourcesColumns}
          isLoading={dataTypeSensitiveSources.isLoading}
          cursor={selectedTab === IconTypes.DataSources ? 'pointer' : 'default'}
          styles={{ header: { position: 'relative', zIndex: 800 } }}
          pagination={{
            totalCount: dataTypeSensitiveSources?.page.totalCount,
            pageInfo: dataTypeSensitiveSources.page.info,
            onChange: pageInfo => {
              dispatch(actions.updateDataTypeSourcesPageInfo(pageInfo));
            },
          }}
        />
      </Box>
      {tableActionModals}
    </Stack>
  );
};
