import React, { FC } from 'react';

import { Stack } from '@chakra-ui/react';

import { ResourceMetadataAndInsights } from './ResourceMetadataAndInsights';

export interface Props {}

export const ResourceOverview: FC<Props> = () => {
  return (
    <Stack spacing={5}>
      <ResourceMetadataAndInsights />
    </Stack>
  );
};
