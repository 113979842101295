import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.reports || initialState;

export const selectReports = createSelector(
  [selectDomain],
  reportsState => reportsState,
);
