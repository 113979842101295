import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  CloudAccountConfigRequest,
  CloudAccountStatus,
} from '@ariksa/cloud-account/api';
import {
  Box,
  HStack,
  IconButton,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { entries, reduce, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Dict } from 'types/utils';

import { authOptions } from 'api/auth';
import { VerifyService } from 'api/auth/api.pb';
import { Select } from 'app/components/DataEntry/Select';
import { Heading4 } from 'components/DataDisplay';
import {
  AddButton,
  CustomButton,
  FormLabel,
  PrimaryButton,
  textFieldStyles,
} from 'components/DataEntry';
import { TimePicker } from 'components/DataEntry/Time/TimePicker';
import { errorToast, infoToast, successToast } from 'components/Toast';
import { selectUser } from 'containers/App/selectors';
import { selectSettings } from 'containers/Settings/selectors';
import { actions } from 'containers/Settings/slice';

import 'react-day-picker/dist/style.css';
import { toTitleCase } from '../../../../utils/string';

import { RemoveIcon } from 'components/Icons/ReactResourceIcons/RemoveIcon';

const PREDEFINED_SLAS = [
  { label: '3 hours', value: 3 },
  { label: '6 hours', value: 6 },
  { label: '12 hours', value: 12 },
  { label: '1 day', value: 24 },
  { label: '7 days', value: 7 * 24 },
  { label: '15 days', value: 15 * 24 },
  { label: '30 days', value: 30 * 24 },
  { label: '45 days', value: 45 * 24 },
];

interface IOrganizationConfig {}

export const OrganizationConfig: FC<IOrganizationConfig> = props => {
  const dispatch = useDispatch();

  const { info } = useSelector(selectUser);
  const {
    updateAccountDiscoveryInterval,
    accounts,
    updateSLAs,
    SLAs,
    availableSLAs,
    updatePredefinedSlas,
  } = useSelector(selectSettings);
  const [discoveryInterval, setDiscoveryInterval] = useState(3 * 60);
  const [discoveryTime, setDiscoveryTime] = useState('10:10');
  const [discoveryIntervalType, setDiscoveryIntervalType] = useState({
    label: 'every',
    value: 'every',
  });
  const [sharedSecret, setSharedSecret] = useState('');
  const [generatingToken, setGeneratingToken] = useState(false);
  const [availableSlas, setAvailableSlas] = useState(PREDEFINED_SLAS);

  const [slas, setSlas] = useState<Dict<number>>({});
  const [days, setDays] = useState(NaN);
  const [hours, setHours] = useState(NaN);

  const getCloudAccounts = useCallback(() => {
    dispatch(
      actions.getCloudAccounts({
        q: {
          status: CloudAccountStatus.Success,
          page: 1,
          size: 100,
        },
        onSuccess: res => {
          setDiscoveryInterval(res?.items[0]?.discovery_interval ?? 3 * 60);
        },
      }),
    );
  }, [dispatch]);

  // Convert hours to a human readable label, e.g. 25 hours => 1 day 1 hour, 48 hours => 2 days
  const hoursToSlaLabel = useCallback((hours: number, days: number = 0) => {
    if (hours >= 24) {
      return hoursToSlaLabel(hours % 24, days + Math.floor(hours / 24));
    }

    if (days === 0 && hours === 0) return '';

    if (days === 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }

    return `${days} ${days === 1 ? 'day' : 'days'} ${hoursToSlaLabel(hours)}`;
  }, []);

  const getPredefinedSlas = useCallback(() => {
    dispatch(
      actions.getAvailableSLAs({
        q: {},
        onSuccess: res => {
          // initially the predefined SLAs are not available in the backend, so we need to set them manually
          if (res) {
            const slas = res.map(sla => ({
              label: hoursToSlaLabel(sla),
              value: sla,
            }));
            setAvailableSlas(sortBy(slas, 'value'));
          } else {
            setAvailableSlas(PREDEFINED_SLAS);
          }
        },
      }),
    );
  }, [dispatch, hoursToSlaLabel]);

  useEffect(() => {
    getPredefinedSlas();
  }, [getPredefinedSlas]);

  useEffect(() => {
    getCloudAccounts();
  }, [getCloudAccounts]);

  const doUpdatePredefinedSlas = useCallback(
    (slas: number[]) => {
      dispatch(
        actions.updatePredefinedSlas({
          q: {
            requestBody: slas,
          },
          onSuccess: () => {
            successToast({
              title: 'Updated SLAs successfully',
            });
            getPredefinedSlas();
            setHours(NaN);
            setDays(NaN);
          },
        }),
      );
    },
    [dispatch, getPredefinedSlas],
  );

  const addPredefinedSla = useCallback(
    (sla: number) => {
      const slas = new Set([...availableSlas.map(sla => sla.value), sla]);
      if (slas.size === availableSlas.length) {
        console.log('SLA already exists');
        infoToast({
          title: 'SLA already exists',
        });
        return;
      }

      doUpdatePredefinedSlas(Array.from(slas));
    },
    [availableSlas, doUpdatePredefinedSlas],
  );

  const removePredefinedSla = useCallback(
    (sla: number) => {
      const slas = new Set(availableSlas.map(sla => sla.value));
      slas.delete(sla);
      doUpdatePredefinedSlas(Array.from(slas));
    },
    [availableSlas, doUpdatePredefinedSlas],
  );

  const [intervalOptions] = useState([
    { label: '3 hours', value: 3 * 60 },
    { label: '6 hours', value: 6 * 60 },
    { label: '12 hours', value: 12 * 60 },
    { label: '24 hours', value: 24 * 60 },
  ]);

  const handleUpdate = data => {
    let payload: CloudAccountConfigRequest = {
      discovery_interval: discoveryInterval ?? 120,
      uuid: accounts.data.map(a => a.uuid),
    };

    if (discoveryIntervalType.value === 'every') {
      payload = {
        discovery_interval: discoveryInterval ?? 120,
        uuid: accounts.data.map(a => a.uuid),
      };
    } else {
      payload = {
        discovery_time: discoveryTime,
        uuid: accounts.data.map(a => a.uuid),
      };
    }
    dispatch(
      actions.updateAccountDiscoveryInterval({
        q: {
          cloudAccountConfigRequest: payload,
        },
        onSuccess: () => {
          successToast({
            title: 'Successfully updated cloud account discovery interval',
          });
          getCloudAccounts();
        },
      }),
    );
  };

  const handleGenerateToken = () => {
    setGeneratingToken(true);
    VerifyService.GetClientCredentials({}, authOptions())
      .then(res => {
        setSharedSecret(res.client_secret ?? '');
        setGeneratingToken(false);
      })
      .catch(err => {
        setGeneratingToken(false);
        errorToast({
          title: err.message,
        });
      });
  };

  useEffect(() => {
    const slas = reduce(
      SLAs.data,
      (acc, sla) => {
        acc[sla.severity] = sla.sla ?? 0;
        return acc;
      },
      {},
    );

    setSlas(slas);
  }, [SLAs.data]);

  useEffect(() => {
    dispatch(
      actions.getSLAs({
        q: {},
      }),
    );
  }, [dispatch]);

  const handleUpdateSLAs = () => {
    const payload = SLAs.data.reduce((acc, sla) => {
      return {
        ...acc,
        [`${sla.severity}_sla`]: sla.sla,
      };
    }, {});

    entries(slas).forEach(([key, value]) => {
      payload[`${key}_sla`] = value;
    });

    dispatch(
      actions.updateSLAs({
        q: {
          updateSlaRequest: payload,
        },
      }),
    );
  };

  return (
    <Stack spacing={5} w={'650px'} h={'full'}>
      <Stack
        border={'1px solid'}
        borderColor={'gray.100'}
        borderRadius={6}
        spacing={7}
        p={4}
      >
        <Stack spacing={1}>
          <FormLabel
            {...textFieldStyles.label}
            htmlFor={'org_name'}
            label={'Organization Name'}
            styles={{ fontWeight: 'bold' }}
          ></FormLabel>
          <Input
            {...textFieldStyles.input}
            value={info?.organization ?? ''}
            isDisabled={true}
          />
        </Stack>
        <Stack spacing={2}>
          <Heading4>How often do want to sync with your accounts?</Heading4>
          <HStack spacing={6} w={'full'}>
            <Text flex={1}>Perform discovery of cloud resources</Text>
            <Box w={'150px'}>
              <Select
                options={[
                  { label: 'every', value: 'every' },
                  { label: 'at time', value: 'at time' },
                ]}
                value={discoveryIntervalType}
                onChange={setDiscoveryIntervalType}
              />
            </Box>
            <Box w={'150px'}>
              {discoveryIntervalType.value === 'every' && (
                <Select
                  options={intervalOptions}
                  value={{
                    label: discoveryInterval / 60 + ' hours',
                    value: discoveryInterval,
                  }}
                  onChange={opt => {
                    setDiscoveryInterval(opt.value);
                  }}
                />
              )}
              {discoveryIntervalType.value === 'at time' && (
                <TimePicker
                  button={false}
                  value={discoveryTime}
                  onChange={time => {
                    setDiscoveryTime(time);
                  }}
                />
              )}
            </Box>
          </HStack>
          <HStack justify={'end'} pt={4}>
            <PrimaryButton
              onClick={handleUpdate}
              isLoading={updateAccountDiscoveryInterval.isLoading}
            >
              Update
            </PrimaryButton>
          </HStack>
        </Stack>
      </Stack>

      <Stack
        border={'1px solid'}
        borderColor={'gray.100'}
        borderRadius={6}
        spacing={6}
        p={4}
      >
        <Heading4>What are your SLAs for tickets (based on severity)?</Heading4>
        {SLAs.isLoading && <Spinner />}
        <HStack spacing={6}>
          <Stack align={'start'} h={'full'}>
            {entries(slas).map(([severity, value]) => {
              return (
                <HStack>
                  <Box w={'120px'}>
                    <Text>{toTitleCase(severity)}</Text>
                  </Box>
                  <Box w={'150px'}>
                    <Select
                      options={availableSlas}
                      value={{
                        label: hoursToSlaLabel(value),
                        value: value,
                      }}
                      onChange={opt => {
                        setSlas({
                          ...slas,
                          [severity]: opt.value ?? 0,
                        });
                      }}
                    />
                  </Box>
                </HStack>
              );
            })}
          </Stack>
          <Stack align={'start'} h={'full'} w={'full'}>
            <Box w={'120px'}>
              <Text>Available SLAs</Text>
            </Box>
            <Box
              border={'1px solid'}
              borderColor={'gray.200'}
              p={2}
              borderRadius={4}
              color={'gray.250'}
              w={'full'}
              minH={'120px'}
            >
              {availableSlas.map(sla => {
                return (
                  <HStack
                    justify={'space-between'}
                    _hover={{ bg: '#eee' }}
                    py={1}
                    px={2}
                  >
                    <Text>{sla.label}</Text>
                    <IconButton
                      icon={<RemoveIcon />}
                      aria-label={''}
                      size={'xs'}
                      bg={'transparent'}
                      onClick={() => removePredefinedSla(sla.value)}
                      isDisabled={
                        !!PREDEFINED_SLAS.find(s => s.value === sla.value)
                      } // Disable if it's a predefined SLA
                    />
                  </HStack>
                );
              })}

              <HStack mt={2}>
                <Input
                  placeholder={'Days'}
                  size={'sm'}
                  {...textFieldStyles.input}
                  type={'number'}
                  defaultValue={undefined}
                  value={days}
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    setDays(value);
                  }}
                />
                <Input
                  placeholder={'Hours'}
                  size={'sm'}
                  {...textFieldStyles.input}
                  type={'number'}
                  value={hours}
                  onChange={e => {
                    const value = parseInt(e.target.value);
                    setHours(value);
                  }}
                />
                <AddButton
                  w={'120px'}
                  px={6}
                  onClick={() => {
                    if (days === 0 && hours === 0) return;
                    addPredefinedSla(
                      (isNaN(days) ? 0 : days * 24) +
                        (isNaN(hours) ? 0 : hours),
                    );
                  }}
                  isLoading={updatePredefinedSlas.isLoading}
                />
              </HStack>
            </Box>
          </Stack>
        </HStack>

        <HStack justify={'end'}>
          <PrimaryButton
            onClick={handleUpdateSLAs}
            isLoading={updateSLAs.isLoading}
          >
            Update
          </PrimaryButton>
        </HStack>
      </Stack>

      <Stack
        border={'1px solid'}
        borderColor={'gray.100'}
        borderRadius={6}
        spacing={6}
        p={4}
      >
        <Stack spacing={3}>
          <Heading4>Get a token for API calls</Heading4>
          <Input
            {...textFieldStyles.input}
            value={sharedSecret ?? ''}
            placeholder={'Click on Generate Token'}
          />
        </Stack>
        <HStack justify={'end'}>
          <CustomButton
            buttonType={'cancel'}
            showIcon={false}
            isDisabled={!sharedSecret}
            onClick={() => {
              navigator.clipboard.writeText(sharedSecret).then(() => {
                successToast({
                  title: 'Copied to clipboard',
                });
              });
            }}
            label={'Copy'}
          />
          <PrimaryButton
            onClick={handleGenerateToken}
            isLoading={generatingToken}
          >
            Generate Token
          </PrimaryButton>
        </HStack>
      </Stack>
    </Stack>
  );
};
