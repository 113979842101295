import React, { useEffect, useState } from 'react';

import { Clients } from '@ariksa/notification';
import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { CreateJira } from 'containers/Setup/Integrations/Components/Jira/Components/CreateJira';
import { EditJira } from 'containers/Setup/Integrations/Components/Jira/Components/EditJira';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions } from '../../../slice';

export const JiraWizard = () => {
  const dispatch = useDispatch();
  const { onCancel, actionType, currentRecord } = useIntegrationsContext();

  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (!!currentRecord?.name) {
      dispatch(
        actions.loadClientInfo({
          q: {
            clientName: Clients.Jira,
            uuid: currentRecord?.uuid,
          },
        }),
      );
    }
  }, [dispatch, currentRecord]);

  const onUpdate = data => {
    const { name, account_id, project_id, url, user } = data;
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name,
        status: true,
        jira: {
          user,
          url,
          project_id: project_id?.value,
          project_name: project_id?.label,
          account_id: account_id?.value,
        },
      },
    };
    dispatch(
      actions.updateClientById({ q: payload, onSuccess: () => onCancel() }),
    );
  };

  return actionType === 'Add' ? (
    <CreateJira
      onSubmit={onUpdate}
      validate={validate}
      setValidate={setValidate}
    />
  ) : (
    <EditJira onSubmit={onUpdate} />
  );
};
