import G6 from '@antv/g6';
import { ModelConfig } from '@antv/g6';
import { customTheme } from 'theme';

import { getIconColor } from 'components/DataDisplay/Utils';
import { resourceIconsWhite } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';

import { ORG_GROUP_NODE } from './nodes_types';

export function registerOrgGroupNode() {
  const colors = customTheme.colors;
  G6.registerNode(
    ORG_GROUP_NODE,
    {
      setState(name, value, item) {
        setItemState(name, value, item);
      },
      //@ts-ignore
      draw(cfg?: ModelConfig, group?: IGroup) {
        // @ts-ignore
        const bg = getIconColor(cfg?.data?.resource_type);

        const container = group.addShape(
          'rect',
          {
            attrs: {
              width: 200,
              height: 45,
              fill: colors.white,
              stroke: bg,
              lineWidth: 0.5,
              shadowColor: '#999',
              shadowBlur: 21,
              shadowOffsetY: 10,
              opacity: 1,
              radius: 8,
            },
          },
          'container',
        );

        const { x = 0, y = 0, width = 0, height = 0 } = container.getBBox();

        /*group.addShape(
          'circle',
          {
            attrs: {
              x: width,
              y: y + 24,
              r: 10,
              stroke: bg,
              fill: '#fff',
            },
          },
          'circle',
        );

        group.addShape('text', {
          attrs: {
            x: width,
            y: height / 2,
            // @ts-ignore
            text: cfg?.edgeTo.length > 0 ? '-' : '+', // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            textBaseline: 'middle',
            textAlign: 'center',
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'label',
        });*/

        group.addShape(
          'rect',
          {
            attrs: {
              width: 45,
              height: 45,
              fill: bg,
              radius: [8, 0, 0, 8],
            },
          },
          'Box',
        );

        group.addShape('image', {
          attrs: {
            x: x + 13,
            y: y + 13,
            width: 20,
            height: 20,
            img: resourceIconsWhite.organizationAccount,
          },
          name: 'img',
        });

        //add resource tpe
        group.addShape('text', {
          attrs: {
            x: width / 1.8,
            y: height / 2 - 5,
            textAlign: 'center',
            textBaseline: 'start',
            // @ts-ignore
            text: `${cfg?.data?.resource_type ?? '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: colors.gray['200'],
            cursor: 'pointer',
          },
          name: 'id',
        });

        //add name of resource
        group.addShape('text', {
          attrs: {
            x: width / 1.8,
            y: height / 2 + 15,
            textAlign: 'center',
            textBaseline: 'start',
            // @ts-ignore
            text: cfg?.data?.name, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            opacity: 1,
            fill: colors.black,
            cursor: 'pointer',
          },
          name: 'label',
        });

        group.addShape('text', {
          attrs: {
            x: 0,
            y: y + 80,
            // @ts-ignore
            text: `ID:   ${cfg?.data?.resource_id || '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'id',
        });

        group.addShape('text', {
          attrs: {
            x: 0,
            y: y + 100,
            // @ts-ignore
            text: `Groups/Users: ${cfg?.data?.groups || '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'member',
        });

        group.addShape('text', {
          attrs: {
            x: 0,
            y: y + 120,
            // @ts-ignore
            text: `Policies: ${cfg?.data?.policies || '-'}`, // cfg.data : typescript throws Object is of type 'unknow'
            fontSize: 12,
            // fontStyle: 'bold',
            opacity: 1,
            fill: '#000',
            cursor: 'pointer',
          },
          name: 'policies',
        });

        return container;
      },
      getAnchorPoints(cfg) {
        return [
          [0, 0.5],
          [1, 0.5],
        ];
      },
    },

    'rect',
  );
}
