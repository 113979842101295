import React, { FC, ReactNode, useState } from 'react';

import { Flex, Text } from '@chakra-ui/react';

import {
  CancelButton,
  ConfirmationInput,
  DeleteButton,
  IModalProps,
  Modal,
} from 'components/index';

export interface DeleteConfirmationModalProps extends IModalProps {
  name: string;
  type?: string;
  confirmationText?: React.ReactNode;
  onConfirm: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: ReactNode | string;
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = props => {
  const {
    name,
    type,
    onConfirm,
    isDisabled,
    isLoading,
    onClose,
    isOpen,
    confirmationText,
    body,
    label,
    ...rest
  } = props;

  const [command, setCommand] = useState('');

  const handleDelete = () => {
    if (name === command) {
      onConfirm();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        header={'Delete confirmation'}
        body={
          body ? (
            body
          ) : (
            <ConfirmationInput
              confirmationText={name}
              label={
                confirmationText ?? (
                  <Text>
                    Please type in the {type} name <b>{name}</b> and delete.
                  </Text>
                )
              }
              command={command}
              setCommand={setCommand}
            />
          )
        }
        size="xl"
        footer={
          <Flex justifyContent={'space-between'} w="full">
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <DeleteButton
              label={label || 'Delete'}
              onClick={handleDelete}
              isDisabled={isDisabled || command !== name}
              isLoading={isLoading}
            />
          </Flex>
        }
        {...rest}
      />
    </>
  );
};
