import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { actions } from 'containers/Setup/Environment/slice';

import { WhitelistWizard } from './WhitelistWizard';

interface Props {}

export const CreateWhitelist: FC<Props> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.resetFormData());
  }, [dispatch]);

  return <WhitelistWizard actionType="Create" />;
};
