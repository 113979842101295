import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OverviewIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    color="inherit"
    w="full"
    h="full"
    p={0.5}
    {...props}
  >
    <g>
      <g>
        <path
          fill="currentColor"
          d="M467.3,512H316.4c-24.7,0-44.7-20.1-44.7-44.7V256c0-24.7,20.1-44.7,44.7-44.7h150.9
			c24.7,0,44.7,20.1,44.7,44.7v211.3C512,491.9,491.9,512,467.3,512z M316.4,240.4c-8.6,0-15.6,7-15.6,15.6v211.3
			c0,8.6,7,15.6,15.6,15.6h150.9c8.6,0,15.6-7,15.6-15.6V256c0-8.6-7-15.6-15.6-15.6H316.4z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M165.5,300.7H44.7C20.1,300.7,0,280.7,0,256V44.7C0,20.1,20.1,0,44.7,0h120.7c24.7,0,44.7,20.1,44.7,44.7V256
			C210.2,280.7,190.1,300.7,165.5,300.7z M44.7,29.1c-8.6,0-15.6,7-15.6,15.6V256c0,8.6,7,15.6,15.6,15.6h120.7
			c8.6,0,15.6-7,15.6-15.6V44.7c0-8.6-7-15.6-15.6-15.6H44.7z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M165.5,512H44.7C20.1,512,0,491.9,0,467.3v-60.4c0-24.7,20.1-44.7,44.7-44.7h120.7
			c24.7,0,44.7,20.1,44.7,44.7v60.4C210.2,491.9,190.1,512,165.5,512z M44.7,391.3c-8.6,0-15.6,7-15.6,15.6v60.4
			c0,8.6,7,15.6,15.6,15.6h120.7c8.6,0,15.6-7,15.6-15.6v-60.4c0-8.6-7-15.6-15.6-15.6H44.7z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M467.3,149.8H316.4c-24.7,0-44.7-20.1-44.7-44.7V44.7c0-24.7,20.1-44.7,44.7-44.7h150.9
			C491.9,0,512,20.1,512,44.7v60.4C512,129.7,491.9,149.8,467.3,149.8z M316.4,29.1c-8.6,0-15.6,7-15.6,15.6v60.4
			c0,8.6,7,15.6,15.6,15.6h150.9c8.6,0,15.6-7,15.6-15.6V44.7c0-8.6-7-15.6-15.6-15.6H316.4z"
        />
      </g>
    </g>
  </Icon>
);
