import React, { ReactNode, useEffect, useState } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DatabaseIcon, DataScanIcon } from 'components/Icons';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

interface Props {
  tooltip: ReactNode;
}

export const DataSourcesSummaryCard: React.FC<Props> = props => {
  const { stateOfYourCloud } = useSelector(selectDashboardOverview);
  const {
    summaryOfAllDataSources,
    analyzedDataSourcesCount,
    cloudSummary,
  } = useSelector(selectDashboard);
  const { dataCount } = stateOfYourCloud;
  const navigate = useNavigate();
  const [totalAnalyzed, setTotalAnalyzed] = useState(0);

  useEffect(() => {
    const r = analyzedDataSourcesCount.data;
    setTotalAnalyzed(
      (r.DB ?? 0) + (r?.Buckets ?? 0) + (r?.Others ?? 0) + (r?.SaaS ?? 0),
    );
  }, [analyzedDataSourcesCount.data]);

  return (
    <SummaryCard
      label="Data Sources"
      icon={<DatabaseIcon />}
      change={{
        isLoading: dataCount.isLoading,
        first: dataCount.data.previous_count,
        last: dataCount.data.current_count,
        percentChange: dataCount.data.percentage_change,
      }}
      onClick={() => navigate('/inventory/summary?tab=Data')}
      cloudProviders={summaryOfAllDataSources.data.providers}
      count={cloudSummary.data.items?.data?.total ?? 0}
      isLoading={summaryOfAllDataSources.isLoading}
      info={{
        isLoading: analyzedDataSourcesCount.isLoading,
        message: !!totalAnalyzed
          ? 'Analyzing ' + totalAnalyzed
          : 'No Resources Analyzed',
        enabled: !!totalAnalyzed,
        onClick: () =>
          !totalAnalyzed
            ? navigate('/setup/outpost/add/data')
            : navigate('/visibility/data?tab=DataSources'),
      }}
      tooltip={{
        header: 'Deploy Ariksa Outpost for Data…',
        icon: <DataScanIcon />,
        tooltip: (
          <Stack>
            <Box>
              Ariksa Outpost for Data automatically classifies data and provides
              deeper data insights and contextual correlation of risks to your
              sensitive data.
            </Box>
            <Box>
              Outpost for Data is deployed as a virtual machine in a VPC of your
              choice to scan all reachable data sources
            </Box>
          </Stack>
        ),
        footerText: 'Deploy Outpost',
      }}
      labelTooltip={props.tooltip}
    />
  );
};
