import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AccessKeys,
  AccessKeysList,
  Groups,
  MFAEnabled,
  PasswordLastUsed,
  PasswordReset,
  PermissionBoundary,
  UserAttachedPolicies,
  UserAttachedPoliciesList,
  UserInlinePolicies,
} from '../../../Components/MetadataField/MetaGridFields';

export const IAMUserMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields createdAt={insight} />
      {Groups(resource)}
      {MFAEnabled(insight)}
      {PasswordLastUsed(resource)}
      {PasswordReset(resource)}
      {AccessKeys(insight)}
      {AccessKeysList(resource)}
      {UserInlinePolicies(resource)}
      {UserAttachedPolicies(resource)}
      {UserAttachedPoliciesList(resource)}
      {PermissionBoundary(resource)}
    </Grid>
  );
};
