import React, { FC, useCallback, useMemo, useState } from 'react';

import { AccountType, RepositoryService } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';
import { ComplianceStandards } from '@ariksa/compliance-policies';
import { CloudEnvironmentType } from '@ariksa/compliance-policies/api';
import { SupportedServices } from '@ariksa/data-scanning';
import { ContextType, Resources } from '@ariksa/inventory-core';
import { Categories } from '@ariksa/inventory-core/api';
import { Clients } from '@ariksa/notification';
import { ReportTypes } from '@ariksa/reporting';
import { ApplicationEnum } from '@ariksa/scan-analysis';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { values } from 'lodash';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';

import { H3 } from 'components/DataDisplay';
import { CustomIconTypes } from 'components/Icons/types';
import { selectResourceTypes } from 'containers/App/selectors';
import { MetaIcon } from 'containers/Meta/ResourceIcons/MetaIcon';

interface IResourceIcons {
  filterTerm?: string;
}

export const AllIcons: FC<IResourceIcons> = props => {
  const { filterTerm = '' } = props;
  const resourceTypes = useSelector(selectResourceTypes);

  const [ariksaIcons] = useState(
    sortBy([
      ...values(Resources),
      ...values(CustomIconTypes),
      ...values(Clients),
      ...values(Categories),
      ...values(ComplianceStandards),
      ...values(CloudProviders),
      ...values(SupportedServices),
      ...values(ContextType),
      ...values(ApplicationEnum),
      ...values(AccountType),
      ...values(RepositoryService),
      ...values(ReportTypes),
      ...values(CloudEnvironmentType),
    ]),
  );

  const types = sortBy(
    filter(resourceTypes.data, o => !!o.cloud_native_name),
    'cloud_native_class',
  );

  // console.log(Array.from(NativeResources));

  const terms = useMemo(
    () => [
      'cloud_native_class',
      'cloud_agnostic_name',
      'cloud_native_name',
      'cloud_service',
    ],
    [],
  );

  const filterFn = useCallback(
    data => {
      return data.filter(d => {
        return terms.some(t => {
          return d[t]?.toLowerCase()?.includes(filterTerm.toLowerCase());
        });
      });
    },
    [terms, filterTerm],
  );

  const matchingField = type => {
    return (
      terms.find(k => {
        return type[k].toLowerCase()?.includes(filterTerm.toLowerCase());
      }) ?? ''
    );
  };

  const renderIcons = (image, label, imageType) => (
    <Stack>
      <H3>
        {label} ({types.length})
      </H3>
      <Grid w={'full'} gap={4} templateColumns="repeat(5, 1fr)">
        {filterFn(
          types.filter(
            n =>
              !n.name.toLowerCase().startsWith('azure') &&
              !n.name.toLowerCase().startsWith('okta') &&
              !n.name.toLowerCase().startsWith('gcp') &&
              !n.name.toLowerCase().startsWith('snowflake') &&
              !n.name.toLowerCase().startsWith('gitlab') &&
              !n.name.toLowerCase().startsWith('github') &&
              !n.name.toLowerCase().startsWith('bitbucket') &&
              n.collection_enable,
          ),
        ).map(d => (
          <GridItem colSpan={1}>
            <MetaIcon
              type={d.cloud_native_class!}
              agnostic={d.cloud_agnostic_class}
              native={d.cloud_native_class}
              field={matchingField(d)}
              image={image}
              bg={d.cloud_native_class}
              imageType={imageType}
            />
          </GridItem>
        ))}
      </Grid>
    </Stack>
  );

  const renderGridItem = (provider, columns = 5) => {
    return (
      <Stack>
        <H3>
          {provider} Icons ({types.length})
        </H3>
        <Grid w={'full'} gap={4} templateColumns={`repeat(${columns}, 1fr)`}>
          {filterFn(
            types.filter(n =>
              n.name.toLowerCase().startsWith(provider?.toLowerCase()),
            ),
          ).map(d => (
            <GridItem colSpan={1}>
              <MetaIcon
                type={d.cloud_native_class!}
                agnostic={d.cloud_agnostic_class}
                native={d.cloud_native_class}
                field={matchingField(d)}
              />
            </GridItem>
          ))}
        </Grid>
      </Stack>
    );
  };

  return (
    <Stack spacing={6}>
      {renderIcons(false, 'AWS Icons', '')}
      {renderGridItem('GCP')}
      {renderGridItem('Azure', 4)}
      {renderGridItem('Okta')}
      {renderGridItem('Snowflake')}
      {renderGridItem('GitLab')}
      {renderGridItem('GitHub')}
      {renderGridItem('BitBucket')}
      <Stack>
        <H3>Ariksa Icons ({ariksaIcons.length})</H3>
        <Grid w={'full'} gap={4} templateColumns="repeat(7, 1fr)">
          {ariksaIcons
            .filter(e => e.toLowerCase().includes(filterTerm.toLowerCase()))
            .map(d => (
              <GridItem colSpan={1}>
                <MetaIcon
                  type={d}
                  native={d}
                  iconFilled={false}
                  isInline={false}
                  textAlign="center"
                />
              </GridItem>
            ))}
        </Grid>
      </Stack>
      {renderIcons(true, 'Map Icons (Purple)', 'purple')}
    </Stack>
  );
};
