import React, { FC, ReactNode } from 'react';

import { Box, CenterProps, Flex, HStack, Text } from '@chakra-ui/react';
import { BsEye } from 'react-icons/bs';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { ExclamationTriangleIcon } from 'components/Icons';
import { ContextLabelIcon } from 'components/Icons/ReactCustomIcons/ContextLabelIcon';

export enum GraphMenuItemTypes {
  overview = 'Overview',
  relationships = 'Relationships',
  alerts = 'Alerts',
  contexts = 'Contexts',
}

export interface IGraphMenuItem {
  onClick?(e);

  type: GraphMenuItemTypes;
  styles?: CenterProps;
  children?: ReactNode | ReactNode[];
}

export const GraphMenuItem: FC<IGraphMenuItem> = props => {
  const { onClick, styles = {} } = props;

  return (
    <Flex
      pl={1}
      // w={'120px'}
      h={'30px'}
      borderRadius={4}
      cursor={'pointer'}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(e);
      }}
      _active={{ color: 'primary', bg: '#aaa' }}
      _hover={{ color: 'primary', bg: '#eee' }}
      {...styles}
    >
      {props.children}
    </Flex>
  );
};

export const OverviewItem = (props: Omit<IGraphMenuItem, 'styles'>) => {
  const { type = GraphMenuItemTypes.overview, ...rest } = props;
  return (
    <GraphMenuItem type={type} {...rest}>
      <HStack>
        <BsEye /> <Text>Overview</Text>
      </HStack>
    </GraphMenuItem>
  );
};

export const RelationshipsItem = (props: Omit<IGraphMenuItem, 'styles'>) => {
  const { type = GraphMenuItemTypes.relationships, ...rest } = props;
  return (
    <GraphMenuItem type={type} {...rest}>
      <CustomTooltip label={'Relationships'}>
        <BsEye />
      </CustomTooltip>
    </GraphMenuItem>
  );
};

export const AlertsItem = (props: Omit<IGraphMenuItem, 'styles'>) => {
  const { type = GraphMenuItemTypes.relationships, ...rest } = props;
  return (
    <GraphMenuItem type={type} {...rest}>
      <HStack align={'center'}>
        <Box w={'16px'}>
          <ExclamationTriangleIcon />
        </Box>
        <Text>Alerts</Text>
      </HStack>
    </GraphMenuItem>
  );
};

export const ContextsItem = (props: Omit<IGraphMenuItem, 'styles'>) => {
  const { type = GraphMenuItemTypes.relationships, ...rest } = props;
  return (
    <GraphMenuItem type={type} {...rest}>
      <HStack align={'center'}>
        <Box w={'16px'}>
          <ContextLabelIcon />
        </Box>
        <Text>Context</Text>
      </HStack>
    </GraphMenuItem>
  );
};
