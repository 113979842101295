import {
  EnvironmentApiCreateEnvironmentRequest,
  EnvironmentApiDeleteEnvironmentRequest,
  EnvironmentApiGetEnvironmentsByIdRequest,
  EnvironmentApiUpdateEnvironmentRequest,
  EnvironmentRead,
} from '@ariksa/compliance-policies/api';
import {
  BluePrintRead,
  EnvironmentApiGetAttachedBlueprintsOnEnvironmentRequest,
} from '@ariksa/compliance-policies/dist/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { ComplianceService } from 'api/services';

import { actions } from './slice';

export function* environmentSaga() {
  yield takeLatestAction(actions.createEnvironment, doCreateEnvironment);
  yield takeLatestAction(actions.updateEnvironment, doUpdateEnvironment);
  yield takeLatestAction(actions.deleteEnvironment, doDeleteEnvironment);
  yield takeLatestAction(actions.getEnvironment, doGetEnvironment);
  yield takeLatestAction(actions.getEnvironments, doGetEnvironments);
  yield takeLatestAction(
    actions.getAttachedBlueprints,
    doGetAttachedBlueprints,
  );
}

/*create environment*/
function* doCreateEnvironment(
  ctx: QueryContext<any, EnvironmentApiCreateEnvironmentRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Environment.createEnvironment(ctx.params),
    {
      errorMsg: 'Failed to create environment!',
      successMsg: `Successfully created environment "${ctx.params.environmentCreate.name}"!`,
    },
  );
}

/*update environment*/
function* doUpdateEnvironment(
  ctx: QueryContext<any, EnvironmentApiUpdateEnvironmentRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Environment.updateEnvironment(ctx.params),
    {
      errorMsg: 'Failed to update environment!',
      successMsg: 'Environment updated successfully.',
    },
  );
}

/*delete environment*/
function* doDeleteEnvironment(
  ctx: QueryContext<any, EnvironmentApiDeleteEnvironmentRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Environment.deleteEnvironment(ctx.params),
    {
      errorMsg: 'Failed to delete environment!',
      successMsg: 'Environment deleted successfully.',
    },
  );
}

/*get environment*/
function* doGetEnvironment(
  ctx: QueryContext<EnvironmentRead, EnvironmentApiGetEnvironmentsByIdRequest>,
) {
  yield call(
    ctx.fetch,
    () => ComplianceService.Environment.getEnvironmentsById(ctx.params),
    { map: res => res?.data?.[0], errorMsg: 'Failed to get environment!' },
  );
}

/*get environments*/
function* doGetEnvironments(ctx: QueryContext<EnvironmentRead[]>) {
  yield call(ctx.fetch, () => ComplianceService.Environment.getEnvironments(), {
    errorMsg: 'Failed to get environments!',
  });
}

/*get blueprints attached to an environment*/
function* doGetAttachedBlueprints(
  ctx: QueryContext<
    BluePrintRead[],
    EnvironmentApiGetAttachedBlueprintsOnEnvironmentRequest
  >,
) {
  yield call(
    ctx.fetch,
    () =>
      ComplianceService.Environment.getAttachedBlueprintsOnEnvironment(
        ctx.params,
      ),
    { errorMsg: 'Failed to get attached blueprints!' },
  );
}
