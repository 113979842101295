const OutpostStatus = ['Active', 'Inactive'];

export const outpostStatusOptions = OutpostStatus.map(o => ({
  label: o,
  value: o,
}));

const OutpostType = ['Data', 'Vulnerability'];

export const outpostTypeOptions = [
  { label: 'All outpost types', value: undefined },
  ...OutpostType.map(o => ({
    label: o,
    value: o,
  })),
];
