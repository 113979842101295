/**
 *
 * GovernanceChecklist
 *
 */

import React, { useCallback, useEffect, useState } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Stack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import { FiChevronRight } from 'react-icons/all';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { StackedHeader, WithResourceIcon } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { DownloadIconButton, PrimaryIconButton } from 'components/DataEntry';
import { BarChartIcon, CheckmarkCircleOutlineIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { ChecklistHistoricalTrend } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist/ChecklistHistoricalTrend';
import { getRatingBgColor } from 'containers/Setup/CloudAccounts/Components/GovernanceChecklist/utils';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';
import { saveAsPDF } from 'containers/Visibility/Network/utils';

import { actions } from '../../slice';

import { GovernanceCheck } from './GovernanceCheck';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  row: CloudAccountGetResponse;
}

export const GovernanceChecklist: React.FC<Props> = props => {
  const {
    governanceChecklist: checklist,
    governanceCheckDetails: checkDetails,
  } = useSelector(selectCloudAccounts);
  const { isOpen, onClose, row } = props;
  const [showHistory, setShowHistory] = useState(false);
  const dispatch = useDispatch();

  //load governance check details
  const getGovernanceCheckDetails = useCallback(
    (checkIds: string[]) => {
      dispatch(actions.loadGovernanceCheckDetails({ q: { ruleId: checkIds } }));
    },
    [dispatch],
  );

  //load governance checklist
  const getGovernanceChecklist = useCallback(() => {
    dispatch(
      actions.loadGovernanceChecklist({
        q: { accountId: row.uuid },
        onSuccess: res => {
          if (!isEmpty(res?.checklist)) {
            getGovernanceCheckDetails(map(res?.checklist, o => o.id));
            dispatch(
              actions.loadGovernanceCheckRemediation({
                q: { accountId: row.uuid },
              }),
            );
          }
        },
      }),
    );
  }, [row, dispatch, getGovernanceCheckDetails]);

  useEffect(() => {
    getGovernanceChecklist();
  }, [getGovernanceChecklist]);

  const downloadReport = () => {
    dispatch(
      actions.downloadChecklist({
        q: {
          accountId: row.uuid,
        },
        onSuccess: res => {
          saveAsPDF(
            res.data,
            `Governance Checklist (${row.cloud_account_id}).pdf`,
          );
        },
      }),
    );
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        styles={{ drawer: { size: 'xl' } }}
        header={
          <WithResourceIcon resourceType={'Account'} iconSize="medium">
            <StackedHeader
              upper="Governance Checklist"
              lower={row.cloud_account_id}
            />
          </WithResourceIcon>
        }
        body={
          <WithSpinner
            loadStatus={{
              loading: checklist.isLoading || checkDetails.isLoading,
              loaded: checklist.isSuccess || checkDetails.isSuccess,
              error: checklist.isError || checkDetails.isError,
            }}
          >
            <Stack spacing={6} pt={3}>
              <HStack w="full">
                <Box w="full">Score: {checklist.data.score}</Box>
                <HStack w="full">
                  <Box>Rating:</Box>
                  <Center
                    bg={getRatingBgColor(checklist.data.rating)}
                    color="white"
                    px={3}
                    borderRadius={3}
                    fontSize={12}
                  >
                    {checklist.data.rating?.toUpperCase()}
                  </Center>
                </HStack>
                <HStack>
                  <PrimaryIconButton
                    aria-label="history"
                    tooltip="Show historical trend"
                    onClick={() => setShowHistory(true)}
                  >
                    <BarChartIcon />
                  </PrimaryIconButton>
                  <DownloadIconButton
                    aria-label="download"
                    tooltip="Download the report"
                    onClick={() => downloadReport()}
                  />
                </HStack>
              </HStack>
              <Accordion allowToggle>
                {map(checklist.data.checklist, o => (
                  <AccordionItem border="none" pb={6}>
                    {({ isExpanded }) => (
                      <>
                        <AccordionButton
                          border="1px solid"
                          borderColor={customTheme.colors.gray['100']}
                          borderRadius={6}
                          fontSize="sm"
                          _hover={{ bg: 'none' }}
                          _focus={{ bg: 'none' }}
                        >
                          <HStack justify="space-between" w="full">
                            <HStack
                              align="flex-start"
                              spacing={4}
                              textAlign="left"
                            >
                              <Center boxSize={5}>
                                <CheckmarkCircleOutlineIcon
                                  color={
                                    o.status === 'Rule_Pass'
                                      ? 'green'
                                      : customTheme.colors.gray['200']
                                  }
                                />
                              </Center>
                              <Center>{o?.compliance_summary}</Center>
                            </HStack>
                            <Center>
                              {isExpanded ? (
                                <FiChevronDown />
                              ) : (
                                <FiChevronRight />
                              )}
                            </Center>
                          </HStack>
                        </AccordionButton>

                        <AccordionPanel pl={5}>
                          <GovernanceCheck check={o} account={row} />
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Accordion>
            </Stack>
            {showHistory && (
              <ChecklistHistoricalTrend
                isOpen={showHistory}
                onClose={() => setShowHistory(false)}
                row={row}
              />
            )}
          </WithSpinner>
        }
      />
    </>
  );
};
