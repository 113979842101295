import React from 'react';

import { Icon } from '@chakra-ui/react';

export const DynamoDbIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path d="M486.4,281.6L256,179.2v102.4L486.4,384V281.6z M486.4,102.4L256,0v102.4l230.4,102.4V102.4z" />
      <polygon points="25.6,102.4 256,0 256,102.4 25.6,204.8 	" />
      <polygon points="25.6,281.6 256,179.2 256,281.6 25.6,384 	" />
      <polygon points="256,409.6 342.3,371.2 457.7,422.4 256,512 	" />
    </g>
  </Icon>
);
