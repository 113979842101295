import React, { memo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from 'containers/App/selectors';
import { eventLogSaga } from 'containers/EventLog/saga';
import * as eventLogSlice from 'containers/EventLog/slice';

import { useInjector } from '../../../utils/inject';

import { UserProfileComponent } from './components/UserProfileComponent';
import { userProfileSaga } from './saga';
import { selectUserProfile } from './selectors';
import { actions, reducer, sliceKey } from './slice';

export const UserProfile = memo(() => {
  useInjector(sliceKey, reducer, userProfileSaga);
  useInjector(eventLogSlice.sliceKey, eventLogSlice.reducer, eventLogSaga);

  const userProfile = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const { info } = useSelector(selectUser);

  useEffect(() => {
    dispatch(
      actions.getUserById({
        id: info.id,
        organization: info.org_id,
      }),
    );
  }, [dispatch, info.id, info.org_id]);

  useEffect(() => {
    dispatch(
      eventLogSlice.actions.getEventLogs({
        q: {
          initiatedBy: info.id,
        },
      }),
    );
  }, [dispatch, info.id]);

  return <UserProfileComponent {...userProfile} />;
});
