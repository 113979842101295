import React, { FC, useEffect, useState } from 'react';

import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { noop, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, PageHeaderWithIcon } from 'components/DataDisplay';
import { Form, Select } from 'components/DataEntry';
import { PolicyIcon, QuestionCircleIcon } from 'components/Icons';
import { Layout, usePageContentContext } from 'components/Layout';
import {
  AriksaQueryBuilder,
  useGetQuerySuggestion,
} from 'containers/PolicyHub/Policies/AriksaQueryBuilder';
import {
  categoryClassOptions,
  policyProviderOptions,
  policySeverityOptions,
} from 'containers/PolicyHub/Policies/CreatePolicy/utils';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { toPascalCase } from 'utils/string';

interface ICreatePolicy {
  action: string;

  onSubmit?(data: any);

  updateForm?(field, value);

  config_id?: string;
  alert_description?: string;
  compliance_description?: string;
  alert_category?: string;
  category_class?: string;
  severity?: string;
  provider?: Record<string, any>;
  isLoading: boolean;
}

export const CustomPolicyDetails: FC<ICreatePolicy> = props => {
  const {
    action,
    onSubmit,
    alert_description,
    alert_category,
    config_id,
    severity,
    isLoading,
    compliance_description,
    category_class,
    provider,
    updateForm = noop,
  } = props;

  const { redirectedSearchQueryTerm } = useSelector(selectSecurityGraphNext);
  const { contentHeight } = usePageContentContext();
  const { searchQuery, ruleCategories } = useSelector(selectPolicyHub);
  const [alertCategoryOptions, setAlertCategoryOptions] = useState<
    Record<string, any>[]
  >([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(actions.resetSearchQuery());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      actions.getRuleCategories({
        q: {},
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setAlertCategoryOptions(
      ruleCategories.data.map(e => ({
        label: startCase(e.toLowerCase()),
        value: e,
      })),
    );
  }, [ruleCategories.data]);

  const { getQuerySuggestions } = useGetQuerySuggestion();

  const handleProviderChange = e => {
    updateForm('provider', e);
    //dispatch(actions.updateSearchQueryProvider(e.value));
    getQuerySuggestions(
      {
        for_field: 'relationship_resources',
        build_id: searchQuery.build.id,
      },
      e.value,
    );
  };

  return (
    <Layout
      middleComponent={
        <Box h={contentHeight + 'px'}>
          <Card styles={{ cardContent: { pl: 32, overflow: 'auto' } }}>
            <Form
              title={
                <PageHeaderWithIcon
                  label={`${toPascalCase(action)} Policy`}
                  icon={<PolicyIcon />}
                  reversed
                />
              }
              schema={{
                description: {
                  type: 'textArea',
                  label: 'Description',
                  isRequired: true,
                  placeholder: 'Description',
                  value: compliance_description,
                  onChange: val => updateForm('compliance_description', val),
                  helpIcon: <QuestionCircleIcon />,
                  helpTooltip:
                    'Enter a description regarding policy check. If this policy will be used to generate alerts, suggest describing what issue this policy uncovers.',
                },
                alertDescription: {
                  type: 'textArea',
                  label: 'Alert Description',
                  isRequired: true,
                  placeholder: 'Alert description',
                  value: alert_description,
                  onChange: val => updateForm('alert_description', val),
                  helpIcon: <QuestionCircleIcon />,
                  helpTooltip:
                    'This provides a more detailed explanation for findings associated with this policy.',
                },
                summary: {
                  type: 'text',
                  label: 'Policy ID',
                  isRequired: true,
                  placeholder: 'Policy ID',
                  defaultValue: config_id,
                  helpIcon: <QuestionCircleIcon />,
                  helpTooltip:
                    'This is the summary that will be shown on the dashboard for findings associated with this policy',
                },
                category: {
                  type: 'object',
                  label: 'Category',
                  isRequired: true,
                  styles: {
                    objectContainer: { align: 'start', isInline: true },
                    propertyContainer: { w: 'full' },
                  },
                  properties: {
                    categoryClass: {
                      type: 'react-select',
                      placeholder: 'Category',
                      options: categoryClassOptions,
                      onChange: val => {
                        updateForm('category_class', val?.value);
                      },
                      value: { label: category_class, value: category_class },
                      isLoading: ruleCategories.isLoading,
                    },
                    alertCategory: {
                      type: 'react-select',
                      placeholder: 'Category',
                      value: {
                        label: startCase(alert_category?.toLowerCase()),
                        value: alert_category,
                      },
                      options: alertCategoryOptions,
                      onChange: val => {
                        updateForm('alert_category', val?.value);
                      },
                      isLoading: ruleCategories.isLoading,
                    },
                  },
                },
                severity: {
                  type: 'react-select',
                  label: 'Severity',
                  isRequired: true,
                  placeholder: 'Severity',
                  value: { label: severity, value: severity },
                  options: policySeverityOptions,
                  onChange: val => {
                    updateForm('severity', val?.value);
                  },
                },
                query: {
                  type: 'custom-with-form-control',
                  label: (
                    <HStack w={'full'}>
                      <Text>Specify Check</Text>
                      <Box flex={1} w={'300px'} fontWeight={400}>
                        <Select
                          options={policyProviderOptions}
                          value={provider}
                          onChange={handleProviderChange}
                          showIconInValueContainer
                          isDisabled={action !== 'Create'}
                        />
                      </Box>
                    </HStack>
                  ),
                  component: (
                    <Stack minH={'100px'}>
                      <AriksaQueryBuilder action={action} />
                    </Stack>
                  ),
                },
              }}
              handleSubmit={data => onSubmit?.(data)}
              buttonOptions={{
                submit: {
                  isDisabled: !searchQuery.is_complete,
                  name: toPascalCase(action),
                  isLoading: isLoading,
                },
                reset: {
                  name: 'Cancel',
                  isVisible: true,
                  onClick: () => {
                    navigate('/policy-hub/policy');
                  },
                },
              }}
            />
          </Card>
        </Box>
      }
    />
  );
};
