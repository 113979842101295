import React, { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';

import { QuestionCircleIcon } from 'components/Icons';

interface IHelpIconButton extends BoxProps {}

export const HelpIconButton: FC<IHelpIconButton> = props => {
  return (
    <Box color={'primary'} cursor={'pointer'} {...props}>
      <QuestionCircleIcon />
    </Box>
  );
};
