import React, { FC } from 'react';

import { Stack, HStack, Text } from '@chakra-ui/react';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';
import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

export const ManualOnboardingHelp: FC = () => {
  return (
    <Stack h="full" spacing={10}>
      <Stack>
        <Stack px={2} spacing={6}>
          <HStack align={'start'}>
            <HelpCount count={1} />
            <Text>
              Create a cross-account role with the required permissions and with
              Ariksa as a trusted entity
            </Text>
          </HStack>
          <HStack align={'start'}>
            <HelpCount count={2} />
            <Text>
              Manually enter the role ARN and external ID to start onboarding
              your cloud account (standalone or management)
            </Text>
          </HStack>
          <HStack align={'start'}>
            <HelpCount count={3} />
            <Text>
              Ariksa will automatically discover resources in your individual or
              management account
            </Text>
          </HStack>
        </Stack>
      </Stack>
      <Stack>
        <HelpHeader>Onboarding member accounts</HelpHeader>
        <Stack px={2} spacing={6}>
          <HStack align={'start'}>
            <HelpCount count={1} />
            <Text>
              For management accounts, you’ll need to manually create stacks for
              each of the member accounts and onboard them separately
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
