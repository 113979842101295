import {
  AlertLogsApiGetAllTicketsRequest,
  AllTicketResponse,
} from '@ariksa/notification/api';
import { call } from 'redux-saga/effects';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { NotificationService } from 'api/index';

import { actions } from './slice';

export function* ticketSaga() {
  yield takeLatestAction(actions.getTickets, doGetTickets);
  yield takeLatestAction(
    actions.syncTicketInformation,
    doSyncTicketInformation,
  );
}

export function* doGetTickets(
  ctx: QueryContext<AllTicketResponse[], AlertLogsApiGetAllTicketsRequest>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertsLogs.getAllTickets(ctx.params),
  );
}

export function* doSyncTicketInformation(
  ctx: QueryContext<any, { ticketId: string }>,
) {
  yield call(ctx.fetch, () =>
    NotificationService.AlertsLogs.getTicketSummary(ctx.params),
  );
}
