import React from 'react';

import { Grid, GridItem, Stack } from '@chakra-ui/react';

import { formatTooltip } from 'components/DataDisplay';
import { CloudIcon, UserIcon } from 'components/Icons';
import { CloudUsers } from 'containers/Dashboard/IdentityOverview/Components/CloudUsers';
import { FederatedUsers } from 'containers/Dashboard/IdentityOverview/Components/FederatedUsers';
import { FindingsToPrioritize } from 'containers/Dashboard/IdentityOverview/Components/FindingsToPrioritize';
import { IdentitiesHistory } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesHistory';
import { IdentitiesWithAccessToSensitiveData } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithAccessToSensitiveData';
import { IdentitiesWithExcessPermissions } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithExcessPermissions';
import { IdentitiesWithHighPrivileges } from 'containers/Dashboard/IdentityOverview/Components/IdentitiesWithHighPrivileges';
import { IdentityRoles } from 'containers/Dashboard/IdentityOverview/Components/IdentityRoles';
import { InsightsForNonHumanIdentities } from 'containers/Dashboard/IdentityOverview/Components/InsightsForNonHumanIdentities';
import { JITAccessRequests } from 'containers/Dashboard/IdentityOverview/Components/JITAccessRequests';
import { NonUsers } from 'containers/Dashboard/IdentityOverview/Components/NonUsers';
import { SaaSUsers } from 'containers/Dashboard/IdentityOverview/Components/SaaSUsers';
import { Identities } from 'containers/Dashboard/Overview/Components/Identities';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';

export const IdentityAuthorization: React.FC = () => {
  return (
    <DashboardOverviewWrapperCard
      label="USER & NON-HUMAN IDENTITIES"
      height="1050px"
      content={
        <Stack spacing={OverviewCardSpacing} w="full" h="full">
          <Grid
            w="full"
            h="full"
            templateColumns="repeat(5, 1fr)"
            templateRows="repeat(3, 1fr)"
            gap={OverviewCardSpacing}
          >
            <GridItem colSpan={1} rowSpan={1}>
              <Stack spacing={OverviewCardSpacing} h="full">
                <Identities
                  showLogEnabled={false}
                  icon={<CloudIcon />}
                  label="All Identities"
                  labelTooltip={formatTooltip({
                    header: 'All Identities',
                    content:
                      'Cloud users, federated users & machine identities',
                    width: 48,
                  })}
                />
                <CloudUsers />
              </Stack>
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <IdentitiesHistory />
            </GridItem>
            <GridItem colSpan={2} rowSpan={1}>
              <FindingsToPrioritize />
            </GridItem>

            <GridItem colSpan={5} rowSpan={2}>
              <Grid
                w="full"
                h="full"
                templateColumns="repeat(5, 1fr)"
                templateRows="repeat(4, 1fr)"
                gap={OverviewCardSpacing}
              >
                <GridItem colSpan={1} rowSpan={4}>
                  <Stack spacing={OverviewCardSpacing} h="full">
                    <NonUsers />
                    <IdentityRoles />
                    <FederatedUsers />
                    <SaaSUsers />
                  </Stack>
                </GridItem>

                <GridItem colSpan={4} rowSpan={4}>
                  <Grid
                    w="full"
                    h="full"
                    templateColumns="repeat(6, 1fr)"
                    templateRows="repeat(3, 1fr)"
                    gap={OverviewCardSpacing}
                  >
                    <GridItem colSpan={2} rowSpan={2}>
                      <IdentitiesWithHighPrivileges />
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={2}>
                      <InsightsForNonHumanIdentities />
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={2}>
                      <IdentitiesWithAccessToSensitiveData />
                    </GridItem>

                    <GridItem colSpan={3} rowSpan={1}>
                      <IdentitiesWithExcessPermissions />
                    </GridItem>
                    <GridItem colSpan={3} rowSpan={1}>
                      <JITAccessRequests />
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Stack>
      }
      icon={<UserIcon />}
    />
  );
};
