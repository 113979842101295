import React, { FC } from 'react';

import { getAPIActivity } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Activity/utils';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

interface INetworkTab {}

export const ActivityTab: FC<INetworkTab> = () => {
  const { resourceType } = useActiveResourceContext();

  return <>{getAPIActivity(resourceType)}</>;
};
