import React, { lazy, Suspense } from 'react';

import { PageLoader } from './PageLoader';

export const lazyLoad = (importFunc: () => any, selectorFunc: (s) => any) => {
  return (): JSX.Element => {
    const LazyComponent = lazy(() =>
      importFunc().then(module => ({ default: selectorFunc(module) })),
    );

    return (
      <Suspense fallback={<PageLoader />}>
        <LazyComponent />
      </Suspense>
    );
  };
};

/*interface Opts {
  fallback: React.ReactNode;
}*/

// type Unpromisify<T> = T extends Promise<infer P> ? P : never;

// export const lazyLoad = <
//   T extends Promise<any>,
//   U extends React.ComponentType<any>
// >(
//   importFunc: () => T,
//   selectorFunc?: (s: Unpromisify<T>) => U,
//   fallback?: React.FC<any>,
// ) => {
//   let lazyFactory: () => Promise<{ default: U }> = importFunc;

//   if (selectorFunc) {
//     lazyFactory = () =>
//       importFunc().then(module => ({ default: selectorFunc(module) }));
//   }

//   fallback = fallback || PageLoader;

//   const LazyComponent = lazy(lazyFactory);

//   return (props: React.ComponentProps<U>): JSX.Element => (
//     <Suspense fallback={fallback ? fallback(null) : null}>
//       <LazyComponent {...props} />
//     </Suspense>
//   );
// };
