/**
 *
 * Asynchronously loads the component for PolicyHub
 *
 */

import { lazy } from 'react';

export const PolicyHub = lazy(() =>
  import('./index').then(m => ({ default: m.PolicyHub })),
);
