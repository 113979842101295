import React, { FC, useEffect, useState } from 'react';

import { Entities } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';
import { find, includes, map } from 'lodash';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Select } from 'components/DataEntry';
import { useAlertWorkflowWizardContext } from 'containers/PolicyHub/AlertWorkflow/Components/AlertWorkflowWizard/AlertWorkflowWizardContext';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { actions } from 'containers/PolicyHub/AlertWorkflow/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface Props {
  id: string;
}

export const TagKeyValueComponent: FC<Props> = props => {
  const { id } = props;
  const { tagValues, tagKeys } = useSelector(selectSharedState);
  const { tagKeyValues } = useSelector(selectAlertWorkflow);
  const { tagKeyOptions } = useAlertWorkflowWizardContext();
  const [valueOptions, setValueOptions] = useState<Record<string, any>[]>([]);
  const [selectedKey, setSelectedKey] = useState<Record<string, any>>({});
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const [selectedValues, setSelectedValues] = useState<Record<string, any>[]>(
    [],
  );
  const [updateValues, setUpdateValues] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentRecord(tagKeyValues?.[id]);
  }, [tagKeyValues, id]);

  useEffect(() => {
    setSelectedKey(tagKeyOptions[0]);
  }, [tagKeyOptions]);

  //set initial values
  useEffect(() => {
    setSelectedKey(
      find(tagKeyOptions, o => o?.value === currentRecord?.tag_key) || {},
    );
    if (updateValues) {
      setSelectedValues(
        filter(valueOptions, o =>
          includes(currentRecord?.entity_list, o?.value),
        ),
      );
    }
  }, [currentRecord, tagKeyOptions, valueOptions, updateValues]);

  //load values for selected key
  useDeepCompareEffect(() => {
    selectedKey?.value &&
      dispatch(
        sharedStateActions.getTagValues({ q: { keys: selectedKey?.value } }),
      );
  }, [selectedKey, dispatch]);

  //set value options based on key
  useEffect(() => {
    setValueOptions(
      map(tagValues.data[selectedKey?.value], o => ({ label: o, value: o })),
    );
  }, [selectedKey, tagValues]);

  const updateTags = (field, value) => {
    dispatch(
      actions.updateTagKeyValueByID({
        id,
        value: {
          name: Entities.Tags,
          tag_key: field === 'tag_key' ? value?.value : selectedKey?.value,
          entity_list:
            field === 'tag_key'
              ? []
              : map(
                  field === 'entity_list' ? value : selectedValues,
                  o => o?.value,
                ),
        },
      }),
    );
  };

  return (
    <Stack w="full">
      <Stack isInline>
        <Box whiteSpace="nowrap" pt={1}>
          If alert or policy is for entity with tag
        </Box>
        <Box w="full" flex={1}>
          <Select
            options={tagKeyOptions}
            value={selectedKey}
            onChange={selected => {
              updateTags('tag_key', selected);
              setSelectedValues([]);
            }}
            isLoading={tagKeys.isLoading}
            isDisabled={tagKeys.isLoading}
          />
        </Box>
      </Stack>
      {/*<Box whiteSpace="nowrap" pt={1}>
          and values
        </Box>*/}
      <Box w="full">
        <Select
          options={valueOptions}
          isMulti
          placeholder="Select values..."
          //showTotalSelected={!!selectedValues.length}
          value={selectedValues}
          onChange={selected => {
            //setSelectedValues(selected);
            setSelectedValues(selected);
            setUpdateValues(false);
            updateTags('entity_list', selected);
          }}
          isLoading={tagValues.isLoading}
          isDisabled={tagValues.isLoading}
        />
      </Box>
      {/*<Wrap>
        {!!tagKeyValues &&
          map(tagKeyValues?.[id]?.entity_list, (o, index) => (
            <WrapItem>
              <Tag
                key={id + o + index}
                label={o}
                styles={{ label: { whiteSpace: 'pre-wrap' } }}
                closeButton
                onClose={() => {
                  updateTags(
                    'entity_list',
                    filter(tagKeyValues?.[id]?.entity_list, l => l !== o),
                  );
                }}
              />
            </WrapItem>
          ))}
      </Wrap>*/}
    </Stack>
  );
};
