import React from 'react';

import { HStack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';

import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import {
  CloudServiceIcon,
  DatabaseIcon,
  ObjectStorageIcon,
} from 'components/Icons';
import { renderDataSecuritySummaryCount } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

export const Analyzed: React.FC = () => {
  const { analyzedSources, summaryOfAllDataSources } = useSelector(
    selectDataDashboard,
  );
  const dataSources = summaryOfAllDataSources.data?.resource_type_counts;
  const legends = [
    {
      label: 'Databases',
      analyzed: analyzedSources.data?.DB ?? 0,
      total: dataSources?.Databases ?? 0,
      icon: <DatabaseIcon />,
    },
    {
      label: 'Buckets',
      analyzed: analyzedSources.data?.Buckets ?? 0,
      total: dataSources?.Buckets ?? 0,
      icon: <ObjectStorageIcon />,
    },
    /*{
      label: 'Disks',
      analyzed: analyzedSources.data?.Others ?? 0,
      total: dataSources?.Others ?? 0,
      icon: <BlockStorageIcon />,
    },*/
    {
      label: 'SaaS',
      analyzed: analyzedSources.data?.SaaS ?? 0,
      total: dataSources?.SaaS ?? 0,
      icon: <CloudServiceIcon />,
      iconPadding: 0,
    },
  ];

  return (
    <HStack w="full" h="full" px={2} py={5} spacing={0} justify="space-between">
      {map(legends, o =>
        renderDataSecuritySummaryCount(
          o.icon,
          formatNumber(o.analyzed) + '/' + formatNumber(o.total),
          o.label,
          analyzedSources.isLoading || summaryOfAllDataSources.isLoading,
          () => {},
          o?.iconPadding,
        ),
      )}
    </HStack>
  );
};
