import { tr } from 'date-fns/locale';
import { each, identity, isEmpty, max } from 'lodash';
import { Optional } from 'types/utils';

import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { EdgeIdMap } from 'components/Visualization/PixiGraph/core/EdgeIdMap';
import { NodeEdgeMap } from 'components/Visualization/PixiGraph/core/NodeEdgeMap';
import { NodeIdMap } from 'components/Visualization/PixiGraph/core/NodeIdMap';

import { AGraph } from './Graph';
import { AGraphNode } from './Node';
import { AGraphNodeId } from './types';

export const getEdgeInCounts = (graph: AGraph<AGraphNode, AGraphEdge>) => {
  const { edgeMap } = graph;
  const edgeInCounts: Map<AGraphNodeId, number> = new Map();
  edgeMap.sourceIds().forEach(parentId => {
    each(edgeMap.destIds(parentId), childId => {
      edgeInCounts.set(childId, edgeInCounts.get(childId) ?? 0 + 1);
    });
  });

  return edgeInCounts;
};

export const getSourceNodeIds = (graph: AGraph<AGraphNode, AGraphEdge>) => {
  const edgeInCounts = getEdgeInCounts(graph);
  const { nodes } = graph;
  const sourceIds: AGraphNodeId[] = [];

  nodes.forEach(n => {
    if (!edgeInCounts.get(n.id)) {
      sourceIds.push(n.id);
    }
  });

  return {
    edgeInCounts,
    sourceIds,
  };
};

type NodeCb = (node: AGraphNode, column: number, row: number) => void;

// traverse graph in depth first order
export const traverseDFS = (
  graph: AGraph<AGraphNode, AGraphEdge>,
  cb: NodeCb,
  order: (nodes: AGraphNode[]) => AGraphNode[] = identity,
) => {
  const { sourceIds } = getSourceNodeIds(graph);
  const { edgeMap, nodeIdMap } = graph;
  const visited = new Set();

  // column changes horizontally
  // row changes vertically
  const dfs = (node: AGraphNode, column: number, row: number): number => {
    if (visited.has(node.id)) return row;
    visited.add(node.id);
    cb(node, column, row);

    const destNodes = edgeMap
      .destIds(node.id)
      .map(id => nodeIdMap.get(id))
      .filter(n => n && !visited.has(n.id)) as AGraphNode[];
    if (destNodes.length === 0) return row;
    for (let destNode of order(destNodes)) {
      if (!destNode) continue;
      row = 1 + dfs(destNode, column + 1, row);
    }

    return row - 1;
  };

  // console.log(edgeMap)
  let row = 0;
  const sourceNodes = sourceIds
    .map(id => nodeIdMap.get(id))
    .filter(n => !!n) as AGraphNode[];

  // source nodes are by default given a column of 0
  for (let destNode of order(sourceNodes)) {
    row = 1 + dfs(destNode, 0, row);
  }
};

export const traverseAcyclic = (
  graph: AGraph<AGraphNode, AGraphEdge>,
  cb: NodeCb,
) => {
  const { edgeMap, nodeIdMap, nodes, edges } = graph;
  const inMap = new Map<string, number>();
  const visited = new Set<string>();
  const depthMap = new Map<string, number>();
  each(nodes, n => {
    inMap.set(n.id, 0);
    depthMap.set(n.id, 0);
  });
  each(edges, e => {
    inMap.set(e.dest, (inMap.get(e.dest) ?? 0) + 1);
  });

  console.log(
    'InMap',
    Array.from(inMap.keys()).filter(id => !inMap.get(id)),
  );

  let Q: string[] = nodes.filter(n => !inMap.get(n.id)).map(n => n.id);
  const sourceIds = Q.slice();
  each(Q, id => {
    visited.add(id);
  });
  console.log('Node count at depth zero', Q.length);
  let loopDepth = 0;
  while (Q.length) {
    loopDepth++;
    if (loopDepth > 100) break;

    const q = Q.slice();
    Q = [];
    for (const uid of q) {
      const destIds = edgeMap.get(uid);
      for (const destId of destIds) {
        let inCount = inMap.get(destId) ?? 0;
        if (visited.has(destId)) {
          continue;
        }
        --inCount;
        if (inCount < 0) {
          console.error('In count cannot be negative');
        }

        inMap.set(destId, inCount);
        if (!inCount) {
          visited.add(destId);
          depthMap.set(destId, loopDepth);
          Q.push(destId);
        } else {
          depthMap.set(destId, loopDepth);
          Q.push(destId);
        }
      }
    }
  }

  // console.log('Remaining', Q);

  // depth changes horizontally
  // height changes vertically

  // execute callback for each node with depth and height
  visited.clear();
  const dfs = (node: AGraphNode, height: number): number => {
    // console.log(height);
    if (visited.has(node.id)) return height;
    visited.add(node.id);
    // if (!depthMap.get(node.id) && node.data.native_name !== 'AWSAccount') {
    //   // console.log('X', node);
    // }
    cb(node, depthMap.get(node.id) ?? 0, height);

    const destNodes = edgeMap
      .destIds(node.id)
      .map(id => nodeIdMap.get(id))
      .filter(n => n && !visited.has(n.id));
    if (destNodes.length === 0) return height;

    for (let destNode of destNodes) {
      if (!destNode) continue;
      height = 1 + dfs(destNode, height + 1);
    }

    return height;
  };

  let height = 0;
  sourceIds
    .map(id => nodeIdMap.get(id))
    .forEach((node, index) => {
      if (!node) return;
      height = 1 + dfs(node, height);
    });
};

export const nodeBound = (graph: AGraph<AGraphNode, AGraphEdge>) => {};

export const connectedEntities = (
  graph: AGraph<AGraphNode, AGraphEdge>,
  node: AGraphNode,
  direction: 'forward' | 'backward' | 'both' = 'forward',
) => {
  // if (!isEmpty(graph.paths)) {
  //   const edgeMap =
  //     direction === 'forward' ? graph.edgeMap : graph.edgeMap.inverse();
  //
  //   console.log('Using paths', edgeMap);
  //
  //   // if the graph has path property then use it to findPath connected entities
  //   const nodes: AGraphNodeId[] = [node.id];
  //   const visited = new Set<AGraphNodeId>();
  //
  //   const connectedNodes: NodeIdMap = new NodeIdMap();
  //   const connectedEdges: EdgeIdMap = new EdgeIdMap();
  //   while (nodes.length) {
  //     const nodeId = nodes.shift()!;
  //     if (visited.has(nodeId)) continue;
  //     visited.add(nodeId);
  //     const sourceNode = graph.nodeIdMap.get(nodeId);
  //     if (!sourceNode) continue;
  //
  //     const destIds = edgeMap.get(nodeId);
  //     if (!destIds) continue;
  //
  //     for (const destId of destIds) {
  //       const targetNode = graph.nodeIdMap.get(destId);
  //       if (!targetNode) continue;
  //       const targetEdge = graph.edgeIdMap.get(
  //         direction === 'forward'
  //           ? AGraphEdge.getId(nodeId, destId)
  //           : AGraphEdge.getId(destId, nodeId),
  //       );
  //       if (!targetEdge) continue;
  //
  //       if (!targetNode?.visible || !sourceNode?.visible) {
  //         continue;
  //       }
  //
  //       nodes.push(targetNode.id);
  //       connectedNodes.add(targetNode);
  //       connectedEdges.add(targetEdge);
  //     }
  //
  //     return {
  //       nodes: connectedNodes,
  //       edges: connectedEdges,
  //     };
  //   }
  // }

  const forward = connectedEntitiesFromEdgeMap(
    graph.edgeMap,
    graph.nodeIdMap,
    graph.edgeIdMap,
    node,
  );

  const backward = connectedEntitiesFromEdgeMap(
    graph.edgeMap.inverse(),
    graph.nodeIdMap,
    graph.edgeIdMap,
    node,
    true,
  );

  if (direction === 'forward') {
    return forward;
  }

  if (direction === 'backward') {
    return backward;
  }

  return {
    nodes: forward.nodes.merge(backward.nodes),
    edges: forward.edges.merge(backward.edges),
  };
};

const connectedEntitiesFromEdgeMap = (
  edgeMap: NodeEdgeMap,
  nodeIdMap: NodeIdMap,
  edgeIdMap: EdgeIdMap,
  node: AGraphNode,
  inverse: boolean = false,
) => {
  const connectedNodes: NodeIdMap = new NodeIdMap();
  const connectedEdges: EdgeIdMap = new EdgeIdMap();

  connectedNodes.add(node);

  const visited = new Set<string>([]);

  const S = [node.id];

  while (S.length) {
    const sourceId = S.pop()!;
    for (const destId of edgeMap.get(sourceId)) {
      const targetNode = nodeIdMap.get(destId);
      if (!targetNode?.isVisible) continue;
      const targetEdge = edgeIdMap.get(
        inverse
          ? AGraphEdge.getId(destId, sourceId)
          : AGraphEdge.getId(sourceId, destId),
      );

      if (targetEdge) {
        if (visited.has(targetEdge.id)) {
          continue;
        }
        visited.add(targetEdge.id);
        S.push(destId);
        connectedEdges.add(targetEdge);
        if (targetNode) {
          connectedNodes.add(targetNode);
        }
      }
    }
  }

  return {
    nodes: connectedNodes,
    edges: connectedEdges,
  };
};

export const getRootNodes = (
  edgeMap: NodeEdgeMap,
  nodeIdMap: NodeIdMap,
  startId: AGraphNodeId,
): AGraphNode[] => {
  const inverseEdgeMap = edgeMap.inverse();
  const visited = new Set<string>();
  const S = [startId];

  const rootNodes = new Set<string>();
  // console.log('StartId', startId);
  // console.log(inverseEdgeMap);
  while (S.length) {
    const sourceId = S.pop()!;
    const destIds = inverseEdgeMap.get(sourceId);
    // console.log('DestIds', destIds);
    if (!destIds.length) {
      rootNodes.add(sourceId);
      continue;
    }
    for (const destId of destIds) {
      const targetNode = nodeIdMap.get(destId);
      if (!targetNode) continue;
      if (visited.has(targetNode.id)) {
        continue;
      }
      visited.add(targetNode.id);
      S.push(destId);
    }
  }

  if (rootNodes.size === 0) {
    return [];
  }

  return Array.from(rootNodes)
    .map(id => nodeIdMap.get(id))
    .filter(n => n) as AGraphNode[];
};

export const findMaxDepthPath = (
  edgeMap: NodeEdgeMap,
): { path: AGraphNodeId[]; end: boolean } => {
  const sourceIds = edgeMap.sourceIds();
  let maxDepth = { path: [] as AGraphNodeId[], end: false };
  for (const sourceId of sourceIds) {
    const result = findMaxDepthPathFromSource(edgeMap, sourceId, []);
    if (result?.end) {
      if (result.path.length > maxDepth.path.length) {
        maxDepth = result;
      }
    }
  }

  return maxDepth;
};

export const findMaxDepthPathFromSource = (
  edgeMap: NodeEdgeMap,
  sourceId: AGraphNodeId,
  nodes: AGraphNodeId[],
  visited: Set<string> = new Set(),
): { path: AGraphNodeId[]; end: boolean } => {
  if (visited.has(sourceId)) {
    return { path: [], end: false };
  }

  visited.add(sourceId);

  if (nodes.includes(sourceId)) {
    return { path: [], end: false };
  }

  const destIds = edgeMap.get(sourceId);
  if (!destIds.length) {
    return {
      path: [sourceId],
      end: true,
    };
  }

  let maxDepth = { path: [] as AGraphNodeId[], end: false };

  nodes.push(sourceId);
  for (const destId of destIds) {
    const result = findMaxDepthPathFromSource(edgeMap, destId, nodes, visited);
    if (result?.end) {
      if (result.path.length > maxDepth.path.length) {
        maxDepth = result;
      }
    }
  }
  nodes.pop();

  return {
    path: [sourceId, ...maxDepth.path],
    end: maxDepth.end,
  };
};
