import { ReactNode } from 'react';

import { Layout } from 'components/Layout/Layout';
import { LayoutProps } from 'components/Layout/types';

export interface SidebarLayoutProps extends LayoutProps {
  sidebar?: LayoutProps['leftComponent'];
  content: ReactNode;
}

export const SidebarLayout = (props: SidebarLayoutProps) => {
  const { sidebar, content, ...rest } = props;
  return (
    <Layout leftComponent={sidebar} {...rest} lWidth="280px">
      {content}
    </Layout>
  );
};
