import React, { useEffect } from 'react';

import { Box } from '@chakra-ui/react';

import { CDashboard } from 'components/Visualization';
import { useDataDashboardContext } from 'containers/Visibility/Data/Components/hooks/context';

import { dashboardWidgets1 } from './Dashboard/config';

export const DataDashboard: React.FC = () => {
  const { getDashboardData } = useDataDashboardContext();

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <Box w="full">
      <CDashboard dashboardWidgets={dashboardWidgets1} />
    </Box>
  );
};
