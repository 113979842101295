import React, { FC, ReactNode } from 'react';

import { Box, StackProps, Stack, BoxProps } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';

interface Props extends StackProps {
  label: string | ReactNode;
  value?: any | ReactNode;
  styles?: { label?: BoxProps; value?: BoxProps };
  isLoading?: boolean;
}

export const DetailsIdentifier: FC<Props> = props => {
  const { label, value, styles, isLoading = false, ...rest } = props;
  return (
    <Stack spacing={2} direction="row" fontSize="15px" {...rest}>
      <Box
        fontWeight={600}
        color={customTheme.colors.gray['300']}
        {...styles?.label}
      >
        {label}:
      </Box>
      {isLoading ? (
        <Box>
          <CustomSpinner />
        </Box>
      ) : (
        <Box flex={1} color="black" {...styles?.value}>
          {value ? value : '-'}
        </Box>
      )}
    </Stack>
  );
};
