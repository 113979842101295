/**
 *
 * Organization Overview
 *
 */

import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { OrgId } from 'api/auth/types';
import { Form } from 'components/DataEntry/Form';
import { CountryPicker } from 'components/DataEntry/Form/fields/CountryPicker';
import { StatePicker } from 'components/DataEntry/Form/fields/StatePicker';
import { selectUser } from 'containers/App/selectors';
import { selectOrganizationWizard } from 'containers/Organizations/OrganizationWizard/selectors';
import { actions } from 'containers/Organizations/OrganizationWizard/slice';
import { actions as groupActions } from 'containers/Setup/Groups/slice';

import {
  NewOrganizationParams,
  OrganizationWizardStepProps,
  ADMIN_FORM_TYPE,
} from '../types';

import { styles } from './styles';

interface OrganizationOverviewProps extends OrganizationWizardStepProps {
  organization: NewOrganizationParams;
  updateOrgDetailsFieldAction(data);
  isLoadingData?: boolean;
  isLoading?: boolean;
}

export function OrganizationOverview(props: OrganizationOverviewProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    action,
    organization,
    isLoadingData,
    updateOrgDetailsFieldAction,
    handleSubmit,
    isLoading,
  } = props;

  const isUpdateForm = action === 'Update';
  const isCreateForm = action === 'Create';

  const { name, full_name, address, country, city, zip_code } = organization;

  const params = useParams<OrgId>();

  useEffect(() => {
    if (params.org_id && params.org_id !== organization.name) {
      // console.log(params, organization);
      dispatch(actions.getOrganizationById({ name: params.org_id }));
      dispatch(actions.updateUpdateFormType(ADMIN_FORM_TYPE.view_admins));
    }
  }, [dispatch, organization, params]);

  const updateOrgField = field => value => {
    dispatch(updateOrgDetailsFieldAction({ field, value }));
  };

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_.]*$/i,
        'Only alphanumeric, underscore,hyphen,period allowed!',
      ),
    full_name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9. ]*$/i,
        'Only alphanumeric, space, period allowed!',
      ),
    address: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-.:,/ ]*$/i,
        'Only alphanumeric, hyphen, comma, forward slash, period, colon, space allowed!',
      ),
  });

  // console.log(isLoadingData);

  // if (isLoadingData) {
  //   return <h1>Loading</h1>;
  // }

  return (
    <Box mt="4">
      <Form
        isLoading={isLoadingData}
        formOptions={{
          defaultValues: {
            // name,
            full_name,
            address,
            address_details: {
              // country: localCountry,
              state: city,
              // zip_code: parseInt(zip_code || '0') || '',
            },
          },
        }}
        formValidation={schemaForm}
        schema={{
          name: {
            type: 'text',
            label: 'Short name',
            placeholder: 'Short name',
            isRequired: true,
            isDisabled: isUpdateForm,
            value: name,
            // value: name,
            tooltip: 'Only alphanumeric, underscore,hyphen,period allowed',
            onChange: updateOrgField('name'),
          },
          full_name: {
            type: 'text',
            label: 'Full name',
            placeholder: 'Full Name',
            value: full_name,
            tooltip: 'Only alphanumeric, space, period allowed',
            onChange: updateOrgField('full_name'),
          },
          address: {
            type: 'text',
            label: 'Address',
            value: address,
            placeholder: 'Address',
            tooltip:
              'Only alphanumeric, hyphen, comma, forward slash, period, colon, space allowed',
            onChange: updateOrgField('address'),
          },
          address_details: {
            type: 'object',
            properties: {
              country: {
                type: 'custom',
                label: 'Country',
                component: CountryPicker,
                value: country,
                onChange: updateOrgField('country'),
                styles: {
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '6px',
                  height: '2rem',
                  padding: '0 32px 1px 12px',
                },
              },
              state: {
                type: 'custom',
                label: 'State',
                component: StatePicker,
                props: {
                  country: country,
                },
                value: city,
                onChange: updateOrgField('city'),
                styles: {
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '6px',
                  height: '2rem',
                  padding: '0 32px 1px 12px',
                },
              },
              zip_code: {
                type: 'number',
                value: parseInt(zip_code ?? '0'),
                label: 'Zip Code',
                tooltip: 'Only numbers allowed',
                onChange: updateOrgField('zip_code'),
              },
            },
          },
        }}
        buttonOptions={{
          submit: {
            name: 'Next',
            isLoading,
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: () => navigate('/organizations'),
          },
        }}
        styles={{
          objectField: {
            ...styles.objectField,
            propertyContainer: {
              width: 'full',
            },
          },
        }}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}
