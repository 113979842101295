import React, { memo, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import AIAssistant from 'containers/ConversationalAI/Components/AiAssistant';
import { ConversationalAILandingPage } from 'containers/ConversationalAI/Components/ConversationalAILandingPage';
import { useInjector } from 'utils/inject';

import { conversationalAISaga } from './saga';
import { actions, reducer, sliceKey } from './slice';

export const ConversationalAI = memo(() => {
  useInjector(sliceKey, reducer, conversationalAISaga);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCloudInsight({ q: { accountId: [] } }));
  }, [dispatch]);

  //my tasks
  useEffect(() => {
    dispatch(actions.getEOLSoftware({ q: { size: 200 } }));
  }, [dispatch]);

  return (
    <Routes>
      <Route
        element={
          <FilterRouter path="/home/ai-assistant" component={<AIAssistant />} />
        }
      >
        <Route path="ai-assistant" element={<AIAssistant />} />
      </Route>
      <Route element={<FilterRouter path="/home" />}>
        <Route index element={<ConversationalAILandingPage />} />
      </Route>
    </Routes>
  );
});
