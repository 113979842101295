import {
  RulesApiGetAllComplianceStandardRequest,
  RulesApiGetDocumentsForCheckRequest,
} from '@ariksa/compliance-policies/api';
import { Dict } from 'types/utils';

import { ComplianceService } from 'api/services';
import { useAriksaQuery } from 'utils/query/useAriksaQuery';

export function useComplianceRulesService() {
  const documentsForCheck = useAriksaQuery<
    RulesApiGetDocumentsForCheckRequest,
    any
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Rules.getDocumentsForCheck(req).then(r => r.data),
  });

  const compliance = useAriksaQuery<
    RulesApiGetAllComplianceStandardRequest,
    Dict<string[]>
  >({
    queryKey: [],
    fetcher: req =>
      ComplianceService.Rules.getAllComplianceStandard(req).then(r => r.data),
  });

  return { documentsForCheck, compliance };
}
