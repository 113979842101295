import React, { FC } from 'react';

import { Center, Flex, Text, Stack, Image } from '@chakra-ui/react';

import { AriksaIcon, getIconColor } from 'components/DataDisplay';
import { isIconFilled } from 'components/Icons/Components/isIconFilled';
import { getBlackMapIcon } from 'components/Icons/MapIcons/getBlackMapIcon';
import { getPurpleMapIcon } from 'components/Icons/MapIcons/getPurpleMapIcon';
import { getWhiteMapIcon } from 'components/Icons/MapIcons/getWhiteMapIcon';
import { IconType } from 'components/Icons/types';

interface IMetaIcon {
  type: IconType;
  image?: boolean;
  imageType?: string;
  bg?: string;
  native?: string;
  agnostic?: string;
  field?: string;
  iconFilled?: boolean;
  isInline?: boolean;
  textAlign?: string;
}

export const MetaIcon: FC<IMetaIcon> = props => {
  const {
    type,
    native,
    agnostic,
    bg,
    iconFilled,
    image = false,
    isInline = true,
    textAlign = 'left',
    imageType,
  } = props;

  return (
    <Flex>
      <Stack
        border={'1px solid #ddd'}
        p={4}
        borderRadius={5}
        w="full"
        bg="white"
        spacing={4}
        isInline={isInline}
      >
        <Center>
          {!image && (
            <AriksaIcon
              type={type}
              size="medium"
              bg={bg}
              iconFilled={iconFilled}
            />
          )}
          {image && (
            <Center
              w={10}
              h={10}
              p={2}
              bg={
                imageType === 'white' && isIconFilled(type)
                  ? getIconColor(type as string)
                  : 'white'
              }
              borderRadius={6}
            >
              <Image
                src={
                  imageType === 'purple'
                    ? getPurpleMapIcon(type)
                    : imageType === 'black'
                    ? getBlackMapIcon(type)
                    : getWhiteMapIcon(type)
                }
              />
            </Center>
          )}
        </Center>
        <Stack spacing={1} w="full" align={textAlign}>
          <Text
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            fontWeight={600}
          >
            {native ?? '-'}
          </Text>
          {agnostic && <Text fontSize={'xs'}>{agnostic ?? '-'}</Text>}
          {/*<Text fontSize={'2xs'}>{field}</Text>*/}
        </Stack>
      </Stack>
    </Flex>
  );
};
