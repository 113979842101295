import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core';
import { Accordion, Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  useNetworkResourceMaliciousEgress,
  useNetworkServiceResourcePortUsage,
} from 'services/Network/useNetworkService';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { InternetAccessMapWrapper } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/Network/InternetAccessMapWrapper';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { formatDate } from 'utils/date';

export const VPCNetworkTab: FC = () => {
  const { resourceUuid, resourceAccountId } = useSelector(selectActiveResource);
  const { resourcePortUsage } = useNetworkServiceResourcePortUsage();
  const { resourceMaliciousEgress } = useNetworkResourceMaliciousEgress(
    resourceAccountId,
    resourceUuid,
  );

  useEffect(() => {
    resourcePortUsage.sync({
      resourceId: resourceUuid,
      accountId: resourceAccountId,
    });
  }, [resourceAccountId, resourcePortUsage, resourceUuid]);

  const columns = (
    resourceType: any = NativeResources.Subnet,
  ): TableColumnProps[] => [
    {
      header: <Box pl="thLeftPaddingWithIcon">SOURCE IP</Box>,
      accessor: 'cidr',
      render: ({ value }) => {
        return (
          <WithResourceIcon resourceType={resourceType}>
            <Box>{value}</Box>
          </WithResourceIcon>
        );
      },
    },
    {
      header: 'PORT',
      accessor: 'port',
    },
    {
      header: 'PROTOCOL',
      accessor: 'protocol',
    },
    {
      header: 'TYPE',
      accessor: 'type',
    },
    {
      header: 'LAST TIMESTAMP',
      accessor: 'lastTimestamp',
      render: ({ value }) => value,
    },
  ];

  const egressColumns: TableColumnProps[] = [
    {
      header: <Box pl="thLeftPaddingWithIcon">DESTINATION IP</Box>,
      accessor: 'destination_ip',
      render: ({ value }) => {
        return (
          <WithResourceIcon resourceType={'Internet'}>
            <Box>{value}</Box>
          </WithResourceIcon>
        );
      },
    },
    {
      header: 'DESTINATION PORT',
      accessor: 'destination_port',
    },
    {
      header: 'PROTOCOL',
      accessor: 'protocol',
    },
    {
      header: 'NO. OF SOURCES',
      accessor: 'sources',
    },
    {
      header: 'LAST TIMESTAMP',
      accessor: 'last_timestamp',
      render: ({ value }) => {
        return formatDate(value);
      },
    },
  ];

  return (
    <Stack w={'full'} h={'full'} pb={5} spacing={8}>
      <Box>
        <Accordion allowMultiple defaultIndex={[0]}>
          <MetaAccordionItem
            iconType={IconTypes.NetworkMap}
            label={'NETWORK MAP'}
            panel={<InternetAccessMapWrapper />}
            iconBGColor="Network"
            height="570px"
          />
          <MetaAccordionItem
            iconType={IconTypes.Egress}
            label={'MALICIOUS EGRESS TRAFFIC FROM VIRTUAL MACHINE'}
            panel={
              <Table
                columns={egressColumns}
                data={resourceMaliciousEgress.data ?? []}
              />
            }
            totalCount={resourceMaliciousEgress.data?.length ?? 0}
            iconBGColor="critical"
          />
          <MetaAccordionItem
            iconType={IconTypes.Internet}
            label={'INGRESS TRAFFIC FROM INTERNET'}
            panel={
              <Table
                columns={columns('Internet')}
                data={resourcePortUsage.data?.internet ?? []}
              />
            }
            totalCount={resourcePortUsage.data?.internet?.length ?? 0}
          />
          <MetaAccordionItem
            iconType={NativeResources.VirtualPrivateCloud}
            label={'INGRESS TRAFFIC FROM VIRTUAL PRIVATE CLOUD (VPC)'}
            panel={
              <Table
                columns={columns()}
                data={resourcePortUsage.data?.vpc ?? []}
              />
            }
            totalCount={resourcePortUsage.data?.vpc?.length ?? 0}
          />
        </Accordion>
      </Box>
    </Stack>
  );
};
