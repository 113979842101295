import React from 'react';

import { Icon } from '@chakra-ui/react';

export const HAProxyIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <g>
      <g>
        <g>
          <g>
            <rect
              x="228.1"
              y="119.3"
              transform="matrix(0.749 -0.6626 0.6626 0.749 -48.1209 191.8723)"
              fill="#284A6A"
              width="2.1"
              height="80.3"
            />
          </g>
          <g>
            <polygon
              fill="#284A6A"
              points="129.2,204.4 87.6,143.6 88.4,143 130.1,203.8 				"
            />
          </g>
          <g>
            <g>
              <path
                fill="#284A6A"
                d="M308.2,381.7l-52.4-58.1l-52.4,58.1l-1.5-1.4l53.2-59l0.8,0.7l0.8-0.7l53.2,59L308.2,381.7z M381.2,312.3
						l-62.5-54.9l0.7-0.7l-0.6-0.8l60.7-51.2l-123.7-14.2l-123.6,14.2l58.4,51.1l-0.7,0.8l0.7,0.8L130.4,310l-1.4-1.6l59.3-51.9
						L129,204.8l0.7-0.8l-0.1-1.1l125.8-14.4l52.3-60.4l1.6,1.4l-51.3,59.2L382,202.9l-0.1,1.1l0.7,0.8L321,256.7l61.5,54.1
						L381.2,312.3z"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="310,381 308,380.9 318.3,256.6 307.4,128.9 309.4,128.7 320.4,256.5 319.4,256.6 320.4,256.7
											"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="255.7,323.1 129.6,310.3 129.8,308.2 255.7,321 255.7,320.9 381.8,310.5 381.9,312.6 255.9,323
						255.8,322.1 					"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="201.5,381.1 188.7,256.7 189.9,256.6 188.7,256.5 201.5,129.3 203.6,129.5 190.8,256.6
						203.6,380.9 					"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#284A6A"
                d="M72,220.7l-0.3-1l57.8-16.2l0.3,1L72,220.7z M202.3,129.8l-60.8-43.4l0.6-0.8l60.1,42.9l13.7-69.7l0.5,0.1
						l0.3-0.4l91.9,70l-0.6,0.8l-91.3-69.5l-13.7,69.7l-0.4-0.1L202.3,129.8z"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="440.7,220.7 381.7,204.5 381.8,204 381.4,203.7 422.5,143.7 308.3,129.3 308.4,128.9 308.3,128.8
						307.9,128.9 294,58.9 295,58.7 308.7,127.9 368.8,85.6 369.4,86.4 309.8,128.4 423.6,142.8 423.5,143.2 423.9,143.5
						382.7,203.7 441,219.7 					"
              />
            </g>
            <g>
              <path
                fill="#284A6A"
                d="M439.3,292.2l-57.8-87.9l0.9-0.6l57.8,87.9L439.3,292.2z M202.9,129.8l-0.6-0.8l92-70.6l0.6,0.8
						L202.9,129.8z"
              />
            </g>
            <g>
              <path
                fill="#284A6A"
                d="M382,312l-0.3-1l57.8-19.7l0.3,1L382,312z M381.3,204l-12.8-118l1-0.1l12.8,118L381.3,204z"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="368.8,425.3 308.7,381.4 309.3,380.5 368.6,423.9 381.3,311.5 381.8,311.5 381.4,311.3
						440.4,219.9 441.3,220.5 382.5,311.5 423.9,367.3 423.1,368 382.2,312.9 369.6,424.9 369.1,424.9 					"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="216.8,452.4 216.6,452.1 215.9,452.3 202.1,381.1 203.1,380.9 216.8,451.1 308.7,380.5 309,381
						308.9,380.4 423.4,367.1 423.5,368.2 309.4,381.4 295.7,452.1 294.7,451.9 308.2,382.2 					"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="294.9,452.4 202.6,381.6 142.2,425.3 141.6,424.5 201.3,381.3 87.9,368.7 88,368.2 87.6,367.9
						129.3,308.9 130.1,309.5 88.9,367.8 202.7,380.4 202.7,380.8 202.9,380.5 295.5,451.6 					"
              />
            </g>
            <g>
              <polygon
                fill="#284A6A"
                points="141.3,425 129.2,309.6 71.2,291.8 71.3,291.3 70.9,291 129.3,203.7 130.1,204.3 72.1,291
						129.9,308.7 129.7,309.2 130.2,309.2 142.4,424.9 					"
              />
            </g>
            <g>
              <path
                fill="#284A6A"
                d="M129.3,309.5l-57.8-89.1l0.9-0.6l57.8,89.1L129.3,309.5z M130.2,204.1l-1-0.1l12.1-118l1,0.1L130.2,204.1z
						"
              />
            </g>
            <g>
              <rect
                x="87.6"
                y="135.8"
                transform="matrix(0.9927 -0.1203 0.1203 0.9927 -15.3489 18.468)"
                fill="#284A6A"
                width="115.3"
                height="1"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <polygon
              fill="#24405D"
              points="141.7,86.4 97.1,65.6 97.5,64.8 141.4,85.4 138,37.5 138.4,37.4 138.5,37 216.6,58.4 216.4,59.2
					138.8,38 142.2,86 141.8,86 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="142.2,86.2 141.5,85.8 182.6,17.6 182.9,17.8 183.2,17.5 216.3,58 229.9,9.5 230.3,9.6 230.6,9.3
					294.8,58.5 294.3,59.2 230.6,10.3 216.8,58.9 216.5,58.8 216.1,59.1 183,18.5 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="368.8,86.2 328,18.5 294.8,59.1 294.5,58.8 294.1,58.9 279.8,8.6 216.6,59.1 216.1,58.5 279.7,7.6
					280,7.9 280.4,7.8 294.7,58 327.8,17.5 328,17.7 328.3,17.6 369.5,85.8 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="448.3,98.6 369.1,86.4 369.1,86 368.7,86 372.7,38 294.6,59.2 294.4,58.4 373.1,37 373.2,37.4
					373.6,37.5 369.6,85.3 414.5,62.5 414.9,63.2 370.4,85.8 448.4,97.8 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="494.5,184.1 423.3,143.6 423.5,143.3 423.1,143.3 414.4,62.9 415.2,62.8 423.8,142 448,98
					448.7,98.4 424.2,142.8 475.5,138.8 475.6,139.6 424.8,143.6 494.9,183.4 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="440,292.2 439.4,291.6 500.1,232.6 440.8,220.6 440.8,220.2 440.5,220 475.2,139.1 475.9,139.4
					441.7,219.1 494.4,183.4 494.8,184.1 441.9,220 501.1,231.9 501,232.3 501.3,232.6 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="423.7,368 423.3,367.3 492.7,327.7 439.4,292.2 439.7,291.9 439.6,291.5 501.9,280.1 440.6,220.5
					441.1,219.9 503,280 502.7,280.3 502.8,280.7 440.7,292.1 493.7,327.4 493.5,327.7 493.7,328.1 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="414.7,447.5 413.9,447.4 423.2,367.6 423.5,367.6 423.5,367.3 474.3,372.4 439.4,292 440.1,291.7
					475.3,372.7 475,372.8 474.9,373.2 424.3,368.1 448.2,413.1 447.5,413.4 423.8,369 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="328.3,494.5 327.6,494.1 368.8,424.7 369.1,424.9 369.1,424.5 447.7,412.9 447.8,413.7
					370.5,425.1 414.4,447.1 414.1,447.8 369.6,425.6 373,475.3 372.2,475.3 368.8,426.2 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="230.7,504.4 230.2,503.7 294.9,451.7 295.2,452 295.3,451.6 372.8,474.7 372.6,475.5 296.3,452.8
					328.3,494 327.7,494.5 295.4,452.9 280.4,504.2 279.6,503.9 294.4,453.1 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="279.7,504.4 217.2,453.1 230.7,503.9 229.9,504.1 216.3,452.9 183.8,494.5 183.6,494.3
					183.1,494.5 141.5,425.1 142.2,424.7 183.5,493.5 216.1,451.8 216.5,452 216.7,451.7 280.2,503.7 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="137.9,476.7 137.8,476.4 137.4,476.3 141.4,425.6 96.9,450.1 96.7,449.8 96.3,449.8 87.6,368.3
					88.4,368.2 97,449.1 141.7,424.5 141.8,424.9 142.2,424.9 138.3,475.7 216.3,451.6 216.6,452.4 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="141.8,425.3 62.6,414.3 62.6,413.9 62.2,413.6 87.3,368.7 36,373.8 35.9,373 86.7,367.9
					16.2,328.7 16.4,328.3 16.1,328 70.2,291.5 8.8,280.7 8.9,279.9 71.4,290.9 71.3,291.3 71.5,291.6 17.1,328.3 88.2,367.9
					88,368.2 88.3,368.4 63.1,413.6 141.9,424.5 				"
            />
          </g>
          <g>
            <path
              fill="#24405D"
              d="M36.5,373.6l-0.7-0.3l35.2-82.1l0.7,0.3L36.5,373.6z M9.1,280.6L8.6,280l63-60.1l0.6,0.6L9.1,280.6z"
            />
          </g>
          <g>
            <path
              fill="#24405D"
              d="M71,291.6l-62.5-59l0.6-0.6l62.5,59L71,291.6z M71.7,220.5l-53.8-35.9l0.4-0.7l53.8,35.9L71.7,220.5z"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="8.9,232.7 8.8,231.9 71.3,219.9 35.6,139.4 36.4,139 72.2,220 71.9,220.2 71.9,220.6 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="18.3,184.7 17.9,184 86.7,143.6 35.9,139.6 36,138.8 87.3,142.8 62.8,100.7 63.5,100.3 87.7,142
					96.8,65.1 97.6,65.2 88.4,143.3 88,143.3 88.2,143.6 				"
            />
          </g>
          <g>
            <polygon
              fill="#24405D"
              points="63.3,100.9 63.1,100.1 141.8,85.6 141.9,86.4 				"
            />
          </g>
        </g>
      </g>
      <path
        fill="#256EA5"
        d="M158.9,286.7l0.4-60.8l60.8,0.4l-0.4,60.8L158.9,286.7z M224.7,219.7l0.4-60.8l60.8,0.4l-0.4,60.8L224.7,219.7
		z M224.7,353.1l0.4-60.8l60.8,0.4l-0.4,60.8L224.7,353.1z M291.1,286.1l0.4-60.8l60.8,0.4l-0.4,60.8L291.1,286.1z"
      />
      <path
        fill="#3378BC"
        d="M287.6,150l0.3-42.2l42.4,0.3l-0.3,42.2L287.6,150z M181.6,149.9l0.3-42.2l42.4,0.3l-0.3,42.2L181.6,149.9z
		 M108.2,225.1l0.3-42.2l42.4,0.3l-0.3,42.2L108.2,225.1z M108.2,331.2l0.4-42.2l42.4,0.4l-0.4,42.2L108.2,331.2z M361,330.4
		l0.3-42.2l42.4,0.3l-0.3,42.2L361,330.4z M361.8,225.4l0.4-42.2l42.4,0.4l-0.4,42.2L361.8,225.4z"
      />
      <path
        fill="#169BD6"
        d="M73.9,157l0.2-27.8l27.7,0.2l-0.2,27.8L73.9,157z M128,100.5l0.2-27.8l27.7,0.2l-0.2,27.8L128,100.5z
		 M202.6,72.6l0.2-27.8l27.7,0.2l-0.2,27.8L202.6,72.6z M57.5,233.9l0.2-27.8l27.5,0.2l-0.2,27.8L57.5,233.9z M409.5,129.7l27.8-0.2
		l0.2,27.7l-27.8,0.2L409.5,129.7z M355.3,72.2l27.8-0.2l0.2,27.7l-27.8,0.2L355.3,72.2z M280.5,45l27.8-0.2l0.2,27.7l-27.8,0.2
		L280.5,45z M425.6,206.5l27.8-0.2l0.2,27.7l-27.8,0.2L425.6,206.5z"
      />
      <path
        fill="#3378BC"
        d="M181,403.2l0.3-42.2l42.4,0.3l-0.3,42.2L181,403.2z M287,403.2l0.3-42.2l42.4,0.3l-0.3,42.2L287,403.2z"
      />
      <path
        fill="#169BD6"
        d="M409.3,381.8l0.2-27.8l27.7,0.2L437,382L409.3,381.8z M355.7,438.9l0.2-27.8l27.7,0.2l-0.2,27.8L355.7,438.9z
		 M281.2,466.3l0.2-27.8l27.7,0.2l-0.2,27.8L281.2,466.3z M425.6,305l0.2-27.8l27.7,0.2l-0.2,27.8L425.6,305z M73.9,354.1l27.8-0.2
		l0.2,27.7l-27.8,0.2L73.9,354.1z M128.1,410.9l27.8-0.2l0.2,27.7l-27.8,0.2L128.1,410.9z M202.3,438.2l27.8-0.2l0.2,27.7l-27.8,0.2
		L202.3,438.2z M57.8,277.3l27.8-0.2l0.2,27.7L57.9,305L57.8,277.3z"
      />
      <g>
        <g>
          <path
            id="B"
            fill="#00A8DA"
            d="M467.5,146.7l0.1-15l15.3,0.1l-0.1,15L467.5,146.7z"
          />
        </g>
        <g>
          <path
            id="B_00000021829788733405667910000011029921708835180727_"
            fill="#00A8DA"
            d="M487.3,192.1l0.1-15l15.3,0.1l-0.1,15
				L487.3,192.1z"
          />
        </g>
        <g>
          <path
            id="B_00000108287236144494032390000017040499183731487120_"
            fill="#00A8DA"
            d="M440.8,106.5l0.1-15l15.3,0.1l-0.1,15
				L440.8,106.5z"
          />
        </g>
        <g>
          <path
            id="B_00000094589209174023739670000008393346633402008241_"
            fill="#00A8DA"
            d="M89.6,72.1l0.1-15l15.3,0.1l-0.1,15L89.6,72.1z
				"
          />
        </g>
        <g>
          <path
            id="B_00000168089442304528020430000005896045663588768415_"
            fill="#00A8DA"
            d="M1.6,239.8l0.1-15l15.3,0.1l-0.1,15L1.6,239.8z
				"
          />
        </g>
        <g>
          <path
            id="B_00000097494382301531086650000012225015255353695627_"
            fill="#00A8DA"
            d="M407,72.1l0.1-15l15.3,0.1l-0.1,15L407,72.1z"
          />
        </g>
        <g>
          <path
            id="B_00000040558442980778573070000001597918009521017535_"
            fill="#00A8DA"
            d="M10.4,192.1l0.1-15l15.3,0.1l-0.1,15
				L10.4,192.1z"
          />
        </g>
        <g>
          <path
            id="B_00000057849884853795372320000014392548175738438825_"
            fill="#00A8DA"
            d="M175.8,25.5l0.1-15l15.3,0.1l-0.1,15
				L175.8,25.5z"
          />
        </g>
        <g>
          <path
            id="B_00000126323757493163936270000002269732853183267767_"
            fill="#00A8DA"
            d="M29,146.6l0.1-15l15.3,0.1l-0.1,15L29,146.6z"
          />
        </g>
        <g>
          <path
            id="B_00000127037397959402459870000009766666033549045397_"
            fill="#00A8DA"
            d="M56.4,105.9l0.1-15L71.8,91l-0.1,15L56.4,105.9
				z"
          />
        </g>
        <path
          fill="#00A8DA"
          d="M130.6,29.8l15-0.1l0.1,15.3l-15,0.1L130.6,29.8z M223.5,0.3l15-0.1l0.1,15.3l-15,0.1L223.5,0.3z"
        />
        <g>
          <path
            id="B_00000047043303398490109460000010502117117373331590_"
            fill="#00A8DA"
            d="M366.2,45.3l0.1-15l15.3,0.1l-0.1,15
				L366.2,45.3z"
          />
        </g>
        <g>
          <path
            id="B_00000084521897660121267280000011547323329684346288_"
            fill="#00A8DA"
            d="M494.9,239.2l0.1-15l15.3,0.1l-0.1,15
				L494.9,239.2z"
          />
        </g>
        <path fill="#00A8DA" d="M272.4,15l0.1-15l15.3,0.1l-0.1,15L272.4,15z" />
        <g>
          <path
            id="B_00000097478180673267965350000011225340774899119014_"
            fill="#00A8DA"
            d="M320.2,24.9l0.1-15l15.3,0.1l-0.1,15
				L320.2,24.9z"
          />
        </g>
        <g>
          <path
            id="B_00000093873787424534812930000005532678619039092135_"
            fill="#00A8DA"
            d="M407,455.3l0.1-15l15.3,0.1l-0.1,15L407,455.3z
				"
          />
        </g>
        <g>
          <path
            id="B_00000075884934560434844910000003675585874353166511_"
            fill="#00A8DA"
            d="M494.9,287.6l0.1-15l15.3,0.1l-0.1,15
				L494.9,287.6z"
          />
        </g>
        <g>
          <path
            id="B_00000111185971563812243540000009910078323479941252_"
            fill="#00A8DA"
            d="M89.6,455.3l0.1-15l15.3,0.1l-0.1,15
				L89.6,455.3z"
          />
        </g>
        <g>
          <path
            id="B_00000183248653071143382240000010792213269414710194_"
            fill="#00A8DA"
            d="M487.3,336l0.1-15l15.3,0.1l-0.1,15L487.3,336z
				"
          />
        </g>
        <g>
          <path
            id="B_00000047753307665981845670000007439196764385260943_"
            fill="#00A8DA"
            d="M320.2,502.5l0.1-15l15.3,0.1l-0.1,15
				L320.2,502.5z"
          />
        </g>
        <g>
          <path
            id="B_00000059307692002003635720000015437829973393867171_"
            fill="#00A8DA"
            d="M467.5,381.3l0.1-15l15.3,0.1l-0.1,15
				L467.5,381.3z"
          />
        </g>
        <g>
          <path
            id="B_00000107549430976672827570000004031235109068927385_"
            fill="#00A8DA"
            d="M440.8,422.1l0.1-15l15.3,0.1l-0.1,15
				L440.8,422.1z"
          />
        </g>
        <path
          fill="#00A8DA"
          d="M365.6,468.3l15-0.1l0.1,15.3l-15,0.1L365.6,468.3z M273.3,496.7l15-0.1l0.1,15.3l-15,0.1L273.3,496.7z"
        />
        <g>
          <path
            id="B_00000078027564817371995930000000360251947545033089_"
            fill="#00A8DA"
            d="M130.9,483.8l0.1-15l15.3,0.1l-0.1,15
				L130.9,483.8z"
          />
        </g>
        <g>
          <path
            id="B_00000145750192517715398380000011805386413349780393_"
            fill="#00A8DA"
            d="M28.4,380.8l0.1-15l15.3,0.1l-0.1,15
				L28.4,380.8z"
          />
        </g>
        <g>
          <path
            id="B_00000036952912131126790130000003005535976304999094_"
            fill="#00A8DA"
            d="M10.4,335.9l0.1-15l15.3,0.1l-0.1,15
				L10.4,335.9z"
          />
        </g>
        <g>
          <path
            id="B_00000163056774587121524520000015826234439230952894_"
            fill="#00A8DA"
            d="M55.8,422.1l0.1-15l15.3,0.1l-0.1,15
				L55.8,422.1z"
          />
        </g>
        <g>
          <path
            id="B_00000016780027849574504300000001661022836796283300_"
            fill="#00A8DA"
            d="M1.6,287.6l0.1-15l15.3,0.1l-0.1,15L1.6,287.6z
				"
          />
        </g>
        <g>
          <path
            id="B_00000069377418888962948060000004247285049838226063_"
            fill="#00A8DA"
            d="M224.1,511.8l0.1-15l15.3,0.1l-0.1,15
				L224.1,511.8z"
          />
        </g>
        <g>
          <path
            id="B_00000129173736912540749180000013456754503084568991_"
            fill="#00A8DA"
            d="M175.2,501.9l0.1-15l15.3,0.1l-0.1,15
				L175.2,501.9z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
