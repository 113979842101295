import React from 'react';

import { BiChevronDown } from 'react-icons/all';
import { components } from 'react-select';
import { customTheme } from 'theme';

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <BiChevronDown color={customTheme.colors.gray['400']} />
    </components.DropdownIndicator>
  );
};
