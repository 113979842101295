import { camelCase, snakeCase, upperFirst } from 'lodash';

export const toPascalCase = (str: string | undefined) =>
  upperFirst(camelCase(str));

export const toWordCase = (str: string) =>
  str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

export const toTitleCase = (str: string | undefined) =>
  toWordCase(snakeCase(str).split('_').join(' '));

export function randomString(length = 10) {
  const result: string[] = [];
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength)),
    );
  }

  return result.join('');
}

const UPPER_CASE = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export function limitedString(str: string = '', len = 20) {
  // assuming upper case takes 1.2 times space
  // count upper case letters
  let upperCaseCount =
    str
      ?.slice(0, len)
      ?.split('')
      ?.filter(c => UPPER_CASE.includes(c)).length ?? 0;
  len -= upperCaseCount * 0.2;

  if (str == null) return '-';
  if (str.length <= len) {
    return str;
  }
  return str.slice(0, len) + '...';
}
