import { inlineObjectFieldStyles } from 'app/components/DataEntry/Form';

export const form_styles = {
  form: {
    container: { w: 'full', maxW: 'formWidth', pl: 14 },
  },
  objectField: {
    ...inlineObjectFieldStyles,
    propertyContainer: { w: '50%' },
  },
};
