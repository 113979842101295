import React, { useState } from 'react';

import { Box, Center, Stack } from '@chakra-ui/react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import {
  renderStackedCloudNameAndIcon,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { ActionButton } from 'components/DataEntry';
import { PieChartIcon } from 'components/Icons';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { EnvironmentName } from 'containers/App/utils';
import {
  isEnabledSummaryIcon,
  renderLocatedIn,
} from 'containers/Inventory/CloudInventory/Components/utils';
import { selectInventory } from 'containers/Inventory/CloudInventory/selector';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

export const useCategoryTableColumns = () => {
  const { currentCategory } = useSelector(selectInventory);
  const { toCloudAccountId } = useCloudAccountId();

  const [isOpenEC2Summary, setIsOpenEC2Summary] = useState(false);
  const [type, setType] = useState('');
  const { getCloudNativeName, getCloudAgnosticName } = useResourceType();

  let categoryTableColumns: TableColumnProps[];

  const renderActions = ({ row }) => (
    <Center>
      <ActionButton
        label="Show Summary"
        icon={<PieChartIcon color="primary" />}
        p={1}
        isDisabled={!isEnabledSummaryIcon(row.native_resource)}
        onClick={e => {
          e.stopPropagation();
          setIsOpenEC2Summary(true);
          setType(row?.native_resource);
        }}
      />
    </Center>
  );

  const cloud = {
    header: 'Cloud',
    align: 'left',
    render: ({ row }) =>
      renderStackedCloudNameAndIcon(row?.cloud_provider?.toLowerCase()),
  };

  const actions = { header: 'Action', accessor: '', render: renderActions };

  const renderEntityType = ({ value }) => {
    return (
      <WithResourceIcon
        resourceType={value}
        iconSize="sm"
        iconTooltip={<ResourceTypeIconTooltip resourceType={value} />}
      >
        <StackedCell
          upper={getCloudNativeName(value)}
          lower={getCloudAgnosticName(value)}
        />
      </WithResourceIcon>
    );
  };

  const entityType = {
    header: <Box pl="thLeftPaddingWithIcon">Entity Type</Box>,
    accessor: 'native_resource',
    render: renderEntityType,
  };

  const renderEnvironmentsAccounts = ({ row }) => {
    const accountsLength = row?.accounts?.length;
    const envsLength = row?.environments?.length;
    return (
      <StackedCell
        upper={envsLength + ' environment' + (envsLength > 1 ? 's' : '')}
        lower={accountsLength + ' account' + (accountsLength > 1 ? 's' : '')}
        lowerTooltip={
          <Stack>
            <Box>Accounts:</Box>
            {map(row?.accounts, (o, index) => (
              <Box pl={2}>
                {index + 1}. {toCloudAccountId(o)}
              </Box>
            ))}
          </Stack>
        }
        upperTooltip={
          <Stack>
            <Box>Environments:</Box>
            {map(row?.environments, (o, index) => (
              <Box pl={2}>
                {index + 1}. <EnvironmentName environmentId={o} />
              </Box>
            ))}
          </Stack>
        }
      />
    );
  };

  const deployedInAccounts = {
    header: 'Deployed In',
    align: 'left',
    render: renderEnvironmentsAccounts,
  };

  const entityCount = {
    header: 'Entity Count',
    accessor: 'total_resources',
    align: 'left',
    render: ({ value }) => value + (value > 1 ? ' entities' : ' entity'),
  };

  const deploymentType = {
    header: 'Deployment Type',
    accessor: 'deployment_type',
    align: 'left',
  };

  const identity = [
    entityType,
    cloud,
    deployedInAccounts,
    entityCount,
    deploymentType,
  ];

  const compute = [
    entityType,
    cloud,
    deployedInAccounts,
    entityCount,
    deploymentType,
    //actions,
  ];

  const other = [
    entityType,
    cloud,
    deployedInAccounts,
    {
      header: 'Located In',
      accessor: 'regions',
      align: 'left',
      render: renderLocatedIn,
    },
    entityCount,
    deploymentType,
  ];

  switch (currentCategory) {
    case 'Identity':
      categoryTableColumns = identity;
      break;
    case 'Compute':
      categoryTableColumns = compute;
      break;
    default:
      categoryTableColumns = other;
      break;
  }

  return {
    categoryTableColumns,
    isOpenEC2Summary,
    setIsOpenEC2Summary,
    type,
    deployedInAccounts,
    entityCount,
    cloud,
    deploymentType,
  };
};
