import { StepsStyles, StepStyles } from './types';

export const GRAY = 'gray.300';
export const WHITE = 'white';

export const defaultStyles: StepStyles = {
  bar: {
    common: {
      display: 'inline-block',
      //zIndex: -1,
    },
    vertical: {
      w: 'full',
      pos: 'relative',
      minH: 8,
      h: 'full',
      ml: 4,
    },
    horizontal: {
      w: `calc(100% - 50px)`,
      pos: 'absolute',
      minH: '8px',
      left: '50%',
      ml: 6,
      mt: 2,
    },
  },
  iconItem: {
    common: {
      display: 'inline-block',
      rounded: '50%',
      padding: 1,
      marginY: 'auto',
      marginRight: '10px',
      marginLeft: 'auto',
      width: '34px',
      height: '34px',
      fontSize: '16px',
      zIndex: 1,
      color: GRAY,
      borderWidth: '1px',
      borderColor: GRAY,
      cursor: 'pointer',
      /*_hover: {
        borderWidth: '1px',
        borderColor: 'primary',
        color: 'primary',
      },*/
    },
    disabled: {
      cursor: 'inherit',
      borderColor: GRAY,
      color: GRAY,
    },
    active: {
      color: WHITE,
      backgroundColor: 'primary',
      borderColor: 'primary',
    },
    completed: {
      color: 'primary',
      borderColor: 'primary',
    },
  },
};

export const defaultStepsStyles: StepsStyles = {
  root: {
    // justifyContent: 'center',
    flex: 1,
  },
  rootStepsWrapper: {
    marginTop: 4,
  },
  stepsWrapper: {
    flex: 1,
    margin: 0,
    padding: 0,
    textAlign: 'initial',
    marginBottom: 8,
  },
  componentWrapper: {
    w: `calc(100% - 150px)`,
    // px: '80px',
  },
  description: {
    fontSize: 'sm',
    mt: 2,
    mx: 4,
    border: '1px solid',
    borderColor: 'primary',
    color: 'primary',
    p: 2,
  },
};
