import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SecurityGraphStack = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        fill="#6563ff"
        d="M2.2,10.4l9.5,5.5c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l9.5-5.5c0.1,0,0.1-0.1,0.2-0.2c0.1-0.2,0.1-0.5-0.2-0.7l-9.5-5.5c-0.2-0.1-0.3-0.1-0.5,0L2.2,9.6c-0.1,0-0.1,0.1-0.2,0.2C1.9,10,2,10.3,2.2,10.4z M12,5.1l8.5,4.9L12,14.9L3.5,10L12,5.1z M21.2,13.6L12,18.9l-9.2-5.4c-0.2-0.1-0.5-0.1-0.7,0.2C1.9,14,2,14.3,2.2,14.4l9.5,5.5c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l9.5-5.5c0.2-0.1,0.3-0.4,0.2-0.7C21.8,13.5,21.5,13.4,21.2,13.6z"
      />
    </g>
  </Icon>
);
