import {
  CloudAccountCreate,
  CloudAccountGetResponse,
  GetTemplateResponse,
} from '@ariksa/cloud-account';
import {
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiReadConfigRequest,
  CloudProviders,
  CodeRepositoryApiGetRepoBranchesRequest,
  CodeRepositoryApiGetRepositoriesByUuidRequest,
  CodeRepositoryApiOnboardCodeRepositoryRequest,
  OnboardingConfigResponse,
  RepositoryInfo,
} from '@ariksa/cloud-account/api';
import { IACApiValidateTfStateRequest } from '@ariksa/inventory-core/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { QueryState } from 'services/utils/QueryState';

import { CloudAccountUUID } from 'app/api/inventory/types';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  ContainerState,
  MemberAccountCreateRequest,
  TemplateType,
} from './types';

// The initial state of the CloudAccountWizard container
export const initialState: ContainerState = {
  //templates: QueryState.init([]),
  config: QueryState.init({ cloud_provider: CloudProviders.Aws }),
  onboardingAccount: QueryState.init({} as CloudAccountCreate),
  onboardingMemberAccount: QueryState.init({}),
  onboardedAccount: QueryState.init({} as CloudAccountGetResponse),
  templateType: TemplateType.AnalyzeS3,
  templatePermissions: QueryState.init<GetTemplateResponse[]>([]),

  onboardMemberAccounts: QueryState.init({}),
  repositories: QueryState.init([]),
  onboardRepository: QueryState.init(''),
  validateS3Arn: QueryState.init(''),
  branches: QueryState.init([]),
};

const cloudAccountWizardSlice = createSlice({
  name: 'cloudAccountWizard',
  initialState,
  reducers: {
    resetOnboardingState(state) {
      state.templateType = TemplateType.AnalyzeS3;
    },
    /*onboardAllMemberAccounts(
      state,
      action: QueryAction<
        any,
        CloudAccountApiOnboardAllSubAccountOfParentAccountRequest
      >,
    ) {
      state.onboardMemberAccounts = QueryState.next(
        state.onboardMemberAccounts,
        action,
      );
    },*/

    /*Get AWS CF Template*/
    /*getTemplates(state, action: QueryAction<any>) {
      state.templates = QueryState.next(state.templates, action);
    },*/

    getAccountConfig(
      state,
      action: QueryAction<
        OnboardingConfigResponse,
        CloudAccountApiReadConfigRequest
      >,
    ) {
      state.config = QueryState.next(state.config, action);
    },

    /*OnboardCloudAccountForm*/
    onboardCloudAccount(
      state,
      action: QueryAction<
        CloudAccountCreate,
        CloudAccountApiOnboardCloudAccountRequest
      >,
    ) {
      state.onboardingAccount = QueryState.next(
        state.onboardingAccount,
        action,
      );
    },

    /*OnboardCloudMemberAccountForm*/
    onboardCloudMemberAccount(
      state,
      action: QueryAction<any, MemberAccountCreateRequest>,
    ) {
      state.onboardingMemberAccount = QueryState.next(
        state.onboardingMemberAccount,
        action,
      );
    },

    // load currently onboarding account status
    getOnboardedCloudAccount(
      state,
      action: QueryAction<CloudAccountGetResponse, CloudAccountUUID>,
    ) {
      state.onboardedAccount = QueryState.next(state.onboardedAccount, action);
    },

    /*Send SQS query*/
    loadSQSMessage(state, action: PayloadAction<any>) {},

    /*OnboardCloudAccountForm*/
    loadTemplatePermissions(state, action: QueryAction<GetTemplateResponse[]>) {
      state.templatePermissions = QueryState.next(
        state.templatePermissions,
        action,
      );
    },

    updateTemplateType(state, action: PayloadAction<TemplateType>) {
      state.templateType = action.payload;
    },

    getRepositories(
      state,
      action: QueryAction<
        RepositoryInfo[],
        CodeRepositoryApiGetRepositoriesByUuidRequest
      >,
    ) {
      state.repositories = QueryState.next(state.repositories, action);
    },
    validateS3Arn(
      state,
      action: QueryAction<boolean, IACApiValidateTfStateRequest>,
    ) {
      state.validateS3Arn = QueryState.next(state.validateS3Arn, action);
    },

    onboardRepositories(
      state,
      action: QueryAction<any, CodeRepositoryApiOnboardCodeRepositoryRequest>,
    ) {
      state.onboardRepository = QueryState.next(
        state.onboardRepository,
        action,
      );
    },

    getBranches(
      state,
      action: QueryAction<string[], CodeRepositoryApiGetRepoBranchesRequest>,
    ) {
      state.branches = QueryState.next(state.branches, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = cloudAccountWizardSlice;
