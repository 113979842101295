import { map, sum, values } from 'lodash';

import { customTheme } from '../../../../../theme';

export const severityColors = [
  customTheme.colors.critical,
  customTheme.colors.medium,
  customTheme.colors.low,
];
export const failedChecksColors = [
  customTheme.colors.red['300'],
  customTheme.colors.green['200'],
];
export const outerPieProps = {
  dataKey: 'value',
  outerRadius: '70%',
  innerRadius: '59%',
  color: severityColors,
  label: true,
};
export const innerPieProps = {
  dataKey: 'value',
  outerRadius: '57%',
  innerRadius: '46%',
  color: failedChecksColors,
  legend: true,
};

export const getSeverityData = data => {
  return (
    map(
      {
        'Critical (Failed)': data?.failed?.CRITICAL,
        'Medium (Failed)': data?.failed?.MEDIUM,
        'Low (Failed)': data?.failed?.LOW,
        'Critical (Passed)': data?.passed_details?.CRITICAL,
        'Medium (Passed)': data?.passed_details?.MEDIUM,
        'Low (Passed)': data?.passed_details?.LOW,
      },
      (v, k) => ({ name: k, value: v }),
    ) || []
  );
};

export const getChecksData = data => {
  return [
    {
      name: 'Failed',
      value: getTotalChecks(data?.failed),
    },
    { name: 'Passed', value: getTotalChecks(data?.passed_details) },
  ];
};

export const getTotalChecks = d => sum(values(d));
export const getTotalFailed = row => getTotalChecks(row?.check_stats?.failed);
export const getTotalPassed = row =>
  getTotalChecks(row?.check_stats?.passed_details);
