import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  ContainerImages,
  EncryptionType,
  ImageTagMutability,
  KMSKeyID,
  RegistryID,
  ScanOnPush,
  Url,
} from '../../Components/MetadataField/MetaGridFields';

export const ElasticContainerRegistryMetadata: FC = () => {
  const { resourceDetails, resourceInsight } = useActiveResourceContext();
  const { data: resource } = resourceDetails;
  const { data: insight } = resourceInsight;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" vpc="None" state="None" />
      {Url(resource)}
      {RegistryID(resource)}
      {ImageTagMutability(resource)}
      {ScanOnPush(resource)}
      {EncryptionType(resource)}
      {KMSKeyID(resource)}
      {ContainerImages(insight)}
    </Grid>
  );
};
