import { TagCondition } from '@ariksa/compliance-policies';

export const quantifierOptions = [
  { label: 'any', value: 'any' },
  { label: 'all', value: 'all' },
];

export const tagQuantifierOptions = [
  { label: 'is', value: TagCondition.Is },
  { label: 'contains', value: TagCondition.Contains },
];
