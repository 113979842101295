import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { Container } from 'pixi.js';

import { AGraphEdge } from '../../../core/Edge';
import { AGraph, AGraphProps } from '../../../core/Graph';
import { AGraphNode } from '../../../core/Node';

import { AccessMapEdge } from './Edge';
import {
  AccessMapHeaderNode,
  AccessMapPaginationNode,
  AccessMapResourceNode,
} from './Node';

interface AccessMapProps<N extends AGraphNode, G extends AGraphEdge>
  extends AGraphProps<N, G> {
  headers: AccessMapHeaderNode[];
  pagination: AccessMapPaginationNode[];
  findings?: boolean;
}

export class AccessMap extends AGraph<AccessMapResourceNode, AccessMapEdge> {
  headers: AccessMapHeaderNode[];
  pagination: AccessMapPaginationNode[];
  findings: boolean | undefined;

  constructor(props: AccessMapProps<AccessMapResourceNode, AccessMapEdge>) {
    super(props);
    const { headers, pagination } = props;
    this.headers = headers;
    this.pagination = pagination;
    this.findings = props.findings;
  }

  updateData(
    data: Omit<AccessMapProps<AccessMapResourceNode, AccessMapEdge>, 'layout'>,
  ) {
    const { nodes, headers, pagination, edges, paths } = data;
    super.update(nodes, edges, paths ?? []);
    this.headers = headers;
    this.pagination = pagination;
    this.findings = data.findings;
  }

  executeLayout() {
    super.executeLayout();
    this.pagination.forEach((n, index) => n.addInteractiveElement(this, index));
  }

  renderEdges(g: Graphics, container: Container) {
    this.edges
      .filter(e => {
        return (
          this.nodeIdMap.get(e.source)?.visible &&
          this.nodeIdMap.get(e.dest)?.visible
        );
      })
      .forEach(e => e.render(g));
  }

  renderNodes(g: Graphics, container: Container) {
    this.headers.forEach(n => n.render(g, container));
    this.nodes.filter(n => n.visible).forEach(n => n.render(g, container));
    // console.log('pagination nodes', this.pagination);
    this.pagination.filter(n => n.visible).forEach(n => n.render(g, container));
  }
}
