import React, { FC, useCallback, useEffect, useState } from 'react';

import { Status, UserTagCreate } from '@ariksa/inventory-core';
import { NativeResources } from '@ariksa/inventory-core/api';
import { find, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'components/DataEntry/Form';
import { useInventoryContext } from 'containers/Inventory/CloudInventory/hooks/context';
import { useInventory } from 'containers/Inventory/CloudInventory/hooks/useInventory';
import { selectInventoryContext } from 'containers/Inventory/InventoryContext/selector';
import * as contextSlice from 'containers/Inventory/InventoryContext/slice';

import { selectInventory } from '../../selector';
import { actions } from '../../slice';

interface Props {
  isMulti: boolean;
  data: Record<string, any>[];
  onClose: () => void;
}

export const AddWhitelistForm: FC<Props> = props => {
  const { isMulti = false, onClose } = props;
  const dispatch = useDispatch();
  const { contextList } = useSelector(selectInventoryContext);
  const { instanceResourceType, createTag } = useSelector(selectInventory);
  const [selectedContext, setSelectedContext] = useState<Record<string, any>>(
    {},
  );
  const [contextOptions, setContextOptions] = useState<Record<string, any>[]>(
    [],
  );
  //const [notes, setNotes] = useState('');
  const { currentInstance } = useInventoryContext();
  const { getResourceData } = useInventory();

  //get context list
  useEffect(() => {
    dispatch(
      contextSlice.actions.getContextList({
        q: {},
      }),
    );
  }, [dispatch, instanceResourceType]);

  const getLabelId = useCallback(() => {
    return find(contextList.data, o => o.key === 'Allowlist');
  }, [contextList.data]);

  //set context list
  useEffect(() => {
    const defaultOptions = [
      { label: 'Approved', value: Status.Approved },
      { label: 'End of life', value: Status.EndOfLife },
      { label: 'Unwanted', value: Status.Unwanted },
      { label: 'Patch pending', value: Status.PatchPending },
    ];
    setContextOptions(defaultOptions);
    setSelectedContext(defaultOptions[0]);
  }, [contextList.data]);

  const handleSubmit = data => {
    const labelId = getLabelId();
    if (!labelId) return;
    const { notes, context } = data;
    const payload: UserTagCreate[] = isMulti
      ? map(data, o => ({
          label_id: labelId.id,
          resource_id: o?.uuid,
          account_id: o?.account,
          native_resource: instanceResourceType as NativeResources,
          value: context?.value,
        }))
      : [
          {
            label_id: labelId.id,
            resource_id: currentInstance?.uuid,
            account_id: currentInstance?.account,
            native_resource: instanceResourceType as NativeResources,
            value: context?.value,
            notes,
          },
        ];
    dispatch(
      actions.createTags({
        q: {
          userTagCreate: payload,
        },
        onSuccess: () => {
          getResourceData();
          onClose();
        },
      }),
    );
  };

  return (
    <Form
      schema={{
        context: {
          type: 'react-select',
          isRequired: true,
          label: 'Choose allow list status',
          options: contextOptions,
          value: selectedContext,
          onChange: setSelectedContext,
          isLoading: contextList.isLoading,
        },
        Notes: {
          type: 'textArea',
          label: 'Notes',
          //onChange: value => setNotes(value),
        },
      }}
      buttonOptions={{
        submit: {
          name: 'Submit',
          isLoading: createTag.isLoading,
          //isDisabled: !notes,
        },
        reset: {
          isVisible: true,
          name: 'Cancel',
          onClick: onClose,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
