import { useState } from 'react';

const getParams = () => {
  const href = new URL(window.location.href);
  const map = {};
  for (const entry of href.searchParams.entries()) {
    map[entry[0]] = entry[1];
  }

  return map;
};

export function useSearchParams<T = any>() {
  const [href, setHref] = useState('');
  const [params, setParams] = useState<T & any>(getParams());

  if (href !== window.location.href) {
    // console.log(window.location.href);
    setHref(window.location.href);
    setParams(getParams());
  }

  return params;
}
