/**
 *
 * Cloud Accounts
 *
 */

import React, { memo, useEffect, useState } from 'react';

import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card } from 'app/components/DataDisplay/Card';
import { AddButton } from 'app/components/DataEntry/Button';
import { usePageContentContext } from 'app/components/Layout';
import { PageHeaderWithIcon } from 'components/DataDisplay';
import {
  FilterComponent,
  useUserPermissionsContext,
} from 'components/FilterView';
import {
  AccountIcon,
  AwsIcon,
  AzureIcon,
  BitbucketIcon,
  GithubIcon,
  GitLabIcon,
  GoogleCloudIcon,
} from 'components/Icons';
import { Menu } from 'components/Overlay';
import { useCloudAccountsFunctions } from 'containers/Setup/CloudAccounts/Components/hooks/useCloudAccountsFunctions';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { CloudAccountsTable } from './Components/CloudAccountsTable';

export const CloudAccounts = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { contentHeight } = usePageContentContext();
  const { accounts } = useSelector(selectCloudAccounts);
  const { userPermission } = useUserPermissionsContext();

  const [refresh, setRefresh] = useState(true);
  //const toggleRefresh = () => setRefresh(!refresh);
  const { getCloudAccounts } = useCloudAccountsFunctions();

  useEffect(() => {
    if (refresh && !accounts.isLoading) {
      setRefresh(false);
      getCloudAccounts();
    }
  }, [accounts, dispatch, refresh, getCloudAccounts]);

  const menuItems = [
    {
      key: CloudProviders.Aws,
      label: 'Amazon Web Services',
      icon: <AwsIcon />,
      onClick: () => navigate('/setup/accounts/add/aws'),
    },
    {
      key: CloudProviders.Gcp,
      label: 'Google Cloud',
      icon: <GoogleCloudIcon />,
      onClick: () => navigate('/setup/accounts/add/gcp'),
    },
    {
      key: CloudProviders.Azure,
      label: 'Microsoft Azure',
      icon: <AzureIcon />,
      onClick: () => navigate('/setup/accounts/add/azure'),
    },
    {
      key: CloudProviders.GitlabIac,
      label: 'GitLab',
      icon: <GitLabIcon />,
      onClick: () => navigate('/setup/accounts/add/gitlab_iac'),
    },
    {
      key: CloudProviders.GithubIac,
      label: 'GitHub',
      icon: <GithubIcon />,
      onClick: () => navigate('/setup/accounts/add/github_iac'),
    },
    {
      key: CloudProviders.BitbucketIac,
      label: 'BitBucket',
      icon: <BitbucketIcon />,
      onClick: () => navigate('/setup/accounts/add/bitbucket_iac'),
    },
  ];

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon label="Accounts" icon={<AccountIcon />} />
          <Stack>
            <FilterComponent show={userPermission.ariksa.tenancy.create()}>
              <Menu
                menuItems={menuItems}
                buttonLabel={<AddButton label="Add Cloud" />}
              />
            </FilterComponent>
          </Stack>
        </Stack>
        <Box flex={1}>
          <CloudAccountsTable />
        </Box>
      </Stack>
    </Card>
  );
});
