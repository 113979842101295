import React, { FC, useEffect, useReducer, useState } from 'react';

import { AlertWorkflowResponse } from '@ariksa/notification/api';
import { Box } from '@chakra-ui/react';
import { filter, find, forEach, includes, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Form, IconTypes, Modal, StackedHeader } from 'components/index';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import { formStyles } from 'containers/SharedState/Components/ExportAlerts/styles';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

interface Props {
  isOpen: boolean;
  onClose();
  loadData();
  currentRecord: Record<string, any>;
  attachedWorkflows: AlertWorkflowResponse[];
}

export const AttachWorkflow: FC<Props> = props => {
  const { isOpen, onClose, loadData, currentRecord, attachedWorkflows } = props;
  const dispatch = useDispatch();
  const { attachWorkflow } = useSelector(selectPolicyHub);
  const { blueprint } = useSelector(selectBlueprints);
  const { workflows } = useSelector(selectSharedState);
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      workflowOptions: [],
      selectedWorkflow: {},
    },
  );
  const [attachedEnvironments, setAttachedEnvironments] = useState([]);

  useEffect(() => {
    dispatch(sharedStateActions.getWorkflows({}));
  }, [dispatch]);

  //set workflow options
  useEffect(() => {
    const options: Record<string, any>[] = [];
    const attachedWorkflowIds = map(attachedWorkflows, o => o.uuid);
    forEach(
      filter(workflows.data, w => !includes(attachedWorkflowIds, w.uuid)),
      o => {
        options.push({ label: o?.name, value: o?.uuid, data: o });
      },
    );
    updateState({ workflowOptions: options, selectedWorkflow: options[0] });
  }, [workflows.data, attachedWorkflows]);

  //set attached environments
  useEffect(() => {
    const policy = find(
      state.selectedWorkflow?.data?.policy,
      o => o.policy_id === blueprint.data.id,
    );
    setAttachedEnvironments(policy?.environment_ids || []);
  }, [state.selectedWorkflow, blueprint.data]);

  const handleSubmit = () => {
    dispatch(
      actions.attachWorkflow({
        q: {
          workflowId: state.selectedWorkflow.value,
          editAlertWorkflow: {
            policy: [
              ...filter(
                state.selectedWorkflow?.data?.policy,
                o => o.policy_id !== blueprint.data.id,
              ),
              {
                policy_id: blueprint.data.id,
                environment_ids: [...attachedEnvironments, currentRecord.id],
              },
            ],
          },
        },
        onSuccess: () => {
          loadData();
          onClose();
        },
      }),
    );
  };

  const renderValue = value => (
    <Box
      border="1px solid"
      borderRadius={3}
      borderColor="gray.200"
      px={3}
      py={1}
    >
      {value}
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      styles={{ content: { zIndex: 10000 } }}
      header={
        <StackedHeader
          upper={'Edit Workflow'}
          lower={blueprint.data?.name}
          type={IconTypes.Automation}
        />
      }
      body={
        <Form
          schema={{
            type: {
              type: 'custom-with-form-control',
              label: 'Blueprint',
              component: renderValue(blueprint.data?.name),
            },
            environment: {
              type: 'custom-with-form-control',
              label: 'Environment Name',
              component: renderValue(currentRecord.name),
            },
            workflow: {
              type: 'react-select',
              label: 'Workflow',
              options: state.workflowOptions,
              value: state.selectedWorkflow,
              isLoading: workflows.isLoading,
              onChange: selected => updateState({ selectedWorkflow: selected }),
              isRequired: true,
            },
          }}
          buttonOptions={{
            submit: {
              name: 'Attach',
              isLoading: workflows.isLoading || attachWorkflow.isLoading,
              isDisabled:
                workflows.isLoading ||
                attachWorkflow.isLoading ||
                !state.selectedWorkflow?.value,
            },
            reset: {
              name: 'Cancel',
              isVisible: true,
              onClick: () => onClose(),
            },
          }}
          handleSubmit={handleSubmit}
          styles={formStyles}
        />
      }
    />
  );
};
