import { NativeResources } from '@ariksa/inventory-core/api';

import { AriksaIconMap, IconType, IconTypes } from '../types';

import Account from './svg/account.svg';
import ActiveLogs from './svg/activity_logs.svg';
import Analytics from './svg/analytics.svg';
import ApiGateway from './svg/api-gateway-api.svg';
import AutoScale from './svg/autoscale.svg';
import CloudFront from './svg/aws-cloudfront-svgrepo-com.svg';
import Backup from './svg/backup.svg';
import BlockStorage from './svg/block_storage.svg';
import Bug from './svg/bug.svg';
import Certificate from './svg/certificate.svg';
import Clock from './svg/clock.svg';
import CloudDatabaseTree from './svg/cloud-database-tree.svg';
import CloudInternalNetwork from './svg/cloud_internal_network.svg';
import CloudWatchLog from './svg/cloud_watch_log.svg';
import CloudFormation from './svg/cloudformation.svg';
import CloudTrail from './svg/cloudtrail.svg';
import Cluster from './svg/cluster.svg';
import Code from './svg/code.svg';
import Configuration from './svg/configuration.svg';
import Crown from './svg/crown.svg';
import Database from './svg/database.svg';
import DataWarehouse from './svg/datawarehouse.svg';
import Dns from './svg/dns.svg';
import DocumentdbMongodb from './svg/documentdb_mongodb.svg';
import Encrypted from './svg/encrypted.svg';
import ExclamationTriangle from './svg/exclamation-triangle.svg';
import Firewall from './svg/firewall.svg';
import Folder from './svg/folder.svg';
import Group from './svg/group.svg';
import History from './svg/history.svg';
import Interface from './svg/interface.svg';
import Internet from './svg/internet.jpeg';
import InternetGateway from './svg/internet_gateway.svg';
import Internet_NAT_VPC_Gateway from './svg/Internet_NAT_VPC_gateway.svg';
import KeySkeletonAlt from './svg/key-skeleton-alt.svg';
import Kubernetes from './svg/kubernetes.svg';
import Link from './svg/link.svg';
import LoadBalancer from './svg/load-balancer.svg';
import Local from './svg/local.svg';
import Logs from './svg/logs.svg';
import MessageNotification from './svg/message_notification.svg';
import Monitoring from './svg/monitoring.svg';
import NetworkPortSVGRepoCom from './svg/network-port-svgrepo-com.svg';
import NetworkACL from './svg/network_ACL.svg';
import NoSqlFlexible from './svg/nosql-flexible.svg';
import NotificationSNS1 from './svg/notification(sns)-1.svg';
import NotificationSNS2 from './svg/notification(sns)-2.svg';
import ObjectGroup from './svg/object-group.svg';
import Okta from './svg/okta.svg';
import OrganizationUnit2 from './svg/organization-unit-2.svg';
import OrganizationAccount from './svg/organization_account.svg';
import OrganizationUnit from './svg/organization_unit.svg';
import P2P from './svg/p2p.svg';
import Password from './svg/password.svg';
import PermissionAction from './svg/Permissons-Actions.svg';
import PII from './svg/pii.svg';
import Policies from './svg/policies.svg';
import Queue from './svg/queue.svg';
import RDSPostgresSql from './svg/RDS_Postgres_SQL.svg';
import Region from './svg/region.svg';
import Role1 from './svg/role-1.svg';
import Role2 from './svg/role-2.svg';
import RouteTable from './svg/route-table.svg';
import Route from './svg/route.svg';
import Rules from './svg/rules.svg';
import SecurityGroup2 from './svg/security_group-2.svg';
import SecurityGroup from './svg/security_group-2.svg';
import Serverless from './svg/serverless.svg';
import Services from './svg/services.svg';
import Share from './svg/share.svg';
import SimpleStorageService from './svg/SimpleStorageService.svg';
import Smile from './svg/smile.svg';
import SqlDatabase from './svg/sql-database.svg';
import StopBan from './svg/stop-ban.svg';
import Stopwatch from './svg/stopwatch.svg';
import Subnet from './svg/Subnet.svg';
import SupernetVPC from './svg/supernet-vpc.svg';
import ThumbsDown from './svg/thumbs-down.svg';
import ThumbsUp from './svg/thumbs-up.svg';
import TransitGatewayAttachment from './svg/transit-gateway-attachment.svg';
import Trash from './svg/trash-alt.svg';
import Upload from './svg/upload.svg';
import User from './svg/user.svg';
import VirtualMachine from './svg/virtual_machine.svg';
import VPC from './svg/vpc.svg';
import Vulnerability from './svg/vulnerability.svg';

export const resourceIcons: AriksaIconMap = {
  Internet: Internet,
  internetGateway: InternetGateway,
  cloudTrail: CloudTrail,
  s3: SimpleStorageService,
  activeLogs: ActiveLogs,
  analytics: Analytics,
  blockStorage: BlockStorage,
  autoScale: AutoScale,
  bug: Bug,
  certificate: Certificate,
  cloudInternalNetwork: CloudInternalNetwork,
  cloudDatabaseTree: CloudDatabaseTree,
  cluster: Cluster,
  clock: Clock,
  code: Code,
  configuration: Configuration,
  database: Database,
  DataWarehouse: DataWarehouse,
  dns: Dns,
  encrypted: Encrypted,
  exclamationTriangle: ExclamationTriangle,
  firewall: Firewall,
  folder: Folder,
  group: Group,
  history: History,
  interface: Interface,
  internet: Internet,
  internet_nat_vpc_gateway: Internet_NAT_VPC_Gateway,
  keySkeletonAlt: KeySkeletonAlt,
  kubernetes: Kubernetes,
  link: Link,
  loadBalancer: LoadBalancer,
  logs: Logs,
  messageNotification: MessageNotification,
  monitoring: Monitoring,
  networkPortSVGRepoCom: NetworkPortSVGRepoCom,
  networkACL: NetworkACL,
  noSqlFlexible: NoSqlFlexible,
  NotificationSNS1: NotificationSNS1,
  NotificationSNS2: NotificationSNS2,
  objectGroup: ObjectGroup,
  organizationAccount: OrganizationAccount,
  organizationUnit: OrganizationUnit,
  organizationUnit2: OrganizationUnit2,
  p2p: P2P,
  permissionAction: PermissionAction,
  password: Password,
  pii: PII,
  policies: Policies,
  queue: Queue,
  rdsPostgresSql: RDSPostgresSql,
  region: Region,
  role1: Role1,
  role2: Role2,
  route: Route,
  routeTable: RouteTable,
  rules: Rules,
  securityGroup: SecurityGroup,
  share: Share,
  smile: Smile,
  sqlDatabase: SqlDatabase,
  stopBan: StopBan,
  stopwatch: Stopwatch,
  subnet: Subnet,
  supernetVPC: SupernetVPC,
  vpc: VPC,
  thumbsDown: ThumbsDown,
  thumbsUp: ThumbsUp,
  trash: Trash,
  upload: Upload,
  user: User,
  virtualMachine: VirtualMachine,
  vulnerability: Vulnerability,
  account: Account,
  local: Local,

  [IconTypes.CloudWatchLogs]: CloudWatchLog,
  [IconTypes.CloudWatchLogGroup]: CloudWatchLog,

  [IconTypes.CloudFormation]: CloudFormation,
  [IconTypes.InfrastructureasCode]: CloudFormation,

  [IconTypes.TransitGatewayAttachment]: TransitGatewayAttachment,

  [IconTypes.Account]: Account,

  Access: SecurityGroup2,
  [IconTypes.ComputeFirewall]: SecurityGroup2,

  [IconTypes.SecurityGroup]: SecurityGroup,
  [IconTypes.ComputeFirewall]: SecurityGroup,

  //route table
  [IconTypes.SubnetRouteTable]: RouteTable,

  [IconTypes.AccessControl]: NetworkACL,
  [IconTypes.NetworkAcl]: NetworkACL,

  //interfaces
  [IconTypes.NetworkInterface]: Interface,
  [IconTypes.Interface]: Interface,
  [IconTypes.VirtualInterface]: Interface,

  [IconTypes.CloudFront]: CloudFront,
  [IconTypes.ContentDeliveryNetwork]: CloudFront,

  //gateways
  [IconTypes.InternetGateway]: InternetGateway,
  [IconTypes.NetworkGateway]: InternetGateway,

  //cloudtrail
  [IconTypes.ActionLog]: CloudTrail,
  [IconTypes.CloudTrail]: CloudTrail,

  //db
  [IconTypes.DocumentDb]: DocumentdbMongodb,

  //account
  [IconTypes.Account]: Account,
  [IconTypes.AwsAccount]: Account,
  [IconTypes.AccountRoot]: Account,

  //group
  [IconTypes.Group]: Group,
  [IconTypes.IamGroup]: Group,

  //group
  [IconTypes.Role]: Role1,
  [IconTypes.IamRole]: Role1,

  //group
  [IconTypes.ApiGatewayRestApi]: ApiGateway,
  [IconTypes.ApiGatewayDomain]: ApiGateway,

  Crown: Crown,
  services: Services,
};

export const iconSwitch = (type: IconType, icons: AriksaIconMap) => {
  let img = icons[type.toString()];

  switch (type) {
    case NativeResources.ElasticIp:
      img = icons.elasticIp;
      break;
    case IconTypes.Crown:
      img = icons.Crown;
      break;
    case IconTypes.Internet:
      img = icons.Internet;
      break;
    case NativeResources.Secret:
      img = icons.secretsPassword;
      break;
    case NativeResources.ElasticBeanstalk:
      img = icons.elasticBeanstalk;
      break;
    case 'Permission':
      img = icons.permissionAction;
      break;
    case 'Access':
    case 'VirtualMachineAccess':
    case 'ComputeFirewall':
    case 'SecurityGroup':
    case 'DatabaseAccess':
      img = icons.securityGroup;
      break;
    case 'Account':
    case 'AWSAccount':
    case 'AccountRoot':
      img = icons.account;
      break;
    case 'ActionLogs':
    case 'VPCFlowLogs':
      img = icons.activeLogs;
      break;
    case 'Analytics':
    case 'ElasticSearch':
      img = icons.analytics;
      break;
    case 'AutoScaling':
      img = icons.autoScale;
      break;
    case 'Backup':
    case 'VMBackup':
    case 'RDSClusterSnapshot':
    case 'BackupPlan':
      img = icons.backup;
      break;
    case 'BlockStorage':
    case 'ElasticBlockStorage':
      img = icons.blockStorage;
      break;
    case 'Certificate':
    case 'ACMCertificate':
    case 'RDSCertificate':
      img = icons.certificate;
      break;
    case 'Configuration':
      img = icons.configuration;
      break;
    case 'Container':
      img = icons.services;
      break;
    case 'Credential':
    case 'AccessKey':
      img = icons.keySkeletonAlt;
      break;
    case 'DNS':
      img = icons.dns;
      break;
    case 'DatabaseCluster':
    case 'ECSCluster':
    case 'Cluster':
    case 'RDSCluster':
    case 'RedshiftCluster':
    case 'NeptuneCluster':
    case 'DocumentDBCluster':
      img = icons.cluster;
      break;
    case 'DatabaseInstance':
    case 'Data':
    case 'Database':
    case 'DynamoDB':
    case 'DocumentDB':
    case 'Neptune':
      img = icons.database;
      break;
    case 'Distribution':
      img = icons.services;
      break;
    case 'Environment':
      img = icons.services;
      break;
    case 'File':
    case 'ElasticFileSystem':
      img = icons.folder;
      break;
    case 'Firewall':
      img = icons.firewall;
      break;
    case 'APIGateway':
    case 'Gateway':
      img = icons.internet_nat_vpc_gateway;
      break;
    case 'Group':
    case 'IAMGroup':
      img = icons.group;
      break;
    case 'Interface':
    case 'NetworkInterface':
    case 'VirtualInterface':
      img = icons.interface;
      break;
    case 'Kubernetes':
    case 'ElasticKubernetesService':
      img = icons.kubernetes;
      break;
    case 'LoadBalancer':
    case 'ELB':
    case 'ElasticLoadBalancer':
    case 'ElasticLoadBalancerv2':
      img = icons.loadBalancer;
      break;
    case 'Monitoring':
      img = icons.monitoring;
      break;
    case 'NetworkLogs':
      img = icons.logs;
      break;
    case 'Network':
    case 'NetworkACL':
      img = icons.networkACL;
      break;
    case 'NoSQL':
      img = icons.noSqlFlexible;
      break;
    case 'Notification':
    case 'SNS':
    case 'SimpleNotificationService':
      img = icons.NotificationSNS1;
      break;
    case 'ObjectStorage':
    case 'SimpleStorageService':
    case 'S3':
    case 'S3Object':
      img = icons.s3;
      break;
    case 'OrgUnit':
    case 'OrganizationUnit':
      img = icons.organizationUnit;
      break;
    case 'Organization':
    case 'AWSOrganization':
      img = icons.organizationAccount;
      break;
    case 'P2PConnectInterface':
      img = icons.p2p;
      break;
    case IconTypes.ResourcePolicy:
    case IconTypes.Policy:
    case IconTypes.IamInlinePolicy:
    case 'SecretManagerResourcePolicy':
    case 'SQSResourcePolicy':
    case 'SNSResourcePolicy':
    case 'LambdaResourcePolicy':
    case 'EFSResourcePolicy':
    case 'S3ResourcePolicy':
    case 'OrganizationPolicy':
    case 'ASGResourcePolicy':
    case 'KMSResourcePolicy':
    case 'GroupIdentityPolicy':
    case 'CustomerManagedIdentityPolicy':
    case 'AccountPasswordIdentityPolicy':
    case 'RoleIdentityPolicy':
    case 'UserIdentityPolicy':
    case 'AWSManagedIdentityPolicy':
      img = icons.policies;
      break;
    case 'Queue':
    case 'SimpleQueueService':
      img = icons.queue;
      break;
    case 'Region':
      img = icons.region;
      break;
    case 'Role':
    case 'IAMRole':
      img = icons.role1;
      break;
    case 'RouteTable':
    case 'TransitGatewayRouteTable':
    case 'SubnetRouteTable':
      img = icons.routeTable;
      break;
    case 'Route':
    case 'Route53':
      img = icons.route;
      break;
    case 'Serverless':
    case 'ServerlessCompute':
    case 'Lambda':
    case 'LambdaFunction':
      img = icons.serverless;
      break;
    case 'Subnet':
      img = icons.subnet;
      break;
    case 'SuperNet':
      img = icons.supernetVPC;
      break;
    case 'VirtualPrivateCloud':
      img = icons.vpc;
      break;
    case 'User':
    case 'Identity':
    case 'IAMUser':
      img = icons.user;
      break;
    case 'VirtualMachine':
    case 'EC2Instance':
    case 'EC2ReservedInstance':
      img = icons.virtualMachine;
      break;
    case 'Okta':
    case 'OktaUser':
    case 'OktaRoleSubscription':
    case 'OktaFeature':
    case 'OktaClientApp':
    case 'OktaLinkedObjects':
    case 'OktaAuthorizationServer':
    case 'OktaIdentityProvider':
    case 'OktaTrustedOrigin':
    case 'OktaThreatConfig':
    case 'OktaPolicy':
    case 'OktaZone':
    case 'OktaGroup':
    case 'OktaApplication':
      img = icons.okta;
      break;
    case NativeResources.CloudFront:
      img = icons.cloudfront;
      break;
    case NativeResources.SagemakerEndpoints:
      img = icons.machineLearning;
      break;
    case NativeResources.SagemakerNotebookInstances:
      img = icons.sagemakerNotebookInstance;
      break;
    case NativeResources.SystemsManager:
      img = icons.systemsManager;
      break;
    case NativeResources.AzureAdDomain:
      img = icons.azureADDomainServices;
      break;
    case NativeResources.AzureAdsamlRole:
    case NativeResources.AzureAdDirectoryRoles:
      img = icons.azureADRoles;
      break;
    case NativeResources.SshKeyPair:
      img = icons.sSHKeyPair;
      break;
    case 'Warehouse':
      img = icons.datawarehouse;
      break;
    case 'Local':
      img = icons.local;
      break;
    case 'NetworkAttachment':
    case 'TransitGatewayAttachment':
      img = icons.TransitGatewayAttachment;
      break;
  }

  return img ?? icons.services;
};
