/**
 *
 * AlertWorkflow
 *
 */

import React, { useEffect, useState } from 'react';

import { AlertWorkflowResponse } from '@ariksa/notification/api';
import {
  Box,
  Flex,
  Stack,
  Center,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { forEach, toArray } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Card,
  getIcon,
  PageHeaderWithIcon,
  renderTimeStackedCell,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { AddButton } from 'components/DataEntry';
import {
  EditIcon,
  ExportIcon,
  IconTypes,
  PolicyIcon,
  TrashIcon,
  AutomationIcon,
} from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { DeleteConfirmationModal, TableActionsMenu } from 'components/Overlay';
import { selectApp } from 'containers/App/selectors';
import { AttachWorkflow } from 'containers/PolicyHub/AlertWorkflow/Components/AttachWorkflow';
import { ExportWorkflowAlertsModal } from 'containers/PolicyHub/AlertWorkflow/Components/ExportWorkflowAlertsModal';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';
import { toTitleCase } from 'utils/string';

import { actions } from '../slice';

export const AlertWorkflowTable = () => {
  const { contentHeight } = usePageContentContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { workflows, alertWorkflow } = useSelector(selectAlertWorkflow);
  const { environments } = useSelector(selectApp);
  const attachWorkflow = useDisclosure();
  const [currentRecord, setCurrentRecord] = useState<AlertWorkflowResponse>(
    {} as AlertWorkflowResponse,
  );
  const deleteConfirmation = useDisclosure();

  const exportWorkflow = useDisclosure();

  useEffect(() => {
    dispatch(actions.getAlertWorkflows({}));
    dispatch(actions.resetFormData());
  }, [dispatch]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Name</Box>,
      accessor: 'name',
      render: ({ value }) => (
        <WithResourceIcon resourceType={IconTypes.Automation}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Description',
      accessor: 'description',
      align: 'left',
    },
    {
      header: 'Blueprints',
      accessor: 'policy',
      align: 'left',
      render: ({ value }) => value?.length,
    },
    {
      header: 'Environments',
      accessor: 'policy',
      align: 'left',
      render: ({ row }) => {
        const totalEnvs = environments.data?.length;
        const envs = new Set();
        forEach(row?.policy, o => {
          forEach(o?.environment_ids, e => envs.add(e));
        });
        return (
          <StackedCell
            upper={(envs.size ?? 0) + ' out of'}
            lower={totalEnvs + ' environment' + (totalEnvs === 1 ? '' : 's')}
            showLowerTooltip={false}
            showUpperTooltip={false}
          />
        );
      },
    },
    {
      header: 'Targets',
      accessor: 'clients',
      align: 'left',
      render: ({ value }) => {
        let clients = new Set();
        forEach(value, o => clients.add(o?.client_name));
        return (
          <HStack spacing={1}>
            {map(toArray(clients), o => (
              <CustomTooltip label={toTitleCase(o as string)}>
                <Center boxSize={5}>{getIcon(o as string)}</Center>
              </CustomTooltip>
            ))}
          </HStack>
        );
      },
    },
    {
      header: 'Created',
      accessor: 'created_at',
      align: 'left',
      render: renderTimeStackedCell,
    },
    {
      header: 'Updated',
      accessor: 'updated_at',
      align: 'left',
      render: renderTimeStackedCell,
    },
    {
      header: 'Actions',
      accessor: 'actions',
      render: ({ row }) => {
        const menuItems = [
          {
            label: 'Attach blueprint',
            icon: <PolicyIcon />,
            onClick: () => {
              setCurrentRecord(row);
              attachWorkflow.onOpen();
            },
          },
          {
            label: 'Edit workflow',
            icon: <EditIcon />,
            onClick: () => navigate(`/policy-hub/workflow/edit/${row?.uuid}`),
          },
          {
            label: 'Export alert',
            icon: <ExportIcon />,
            onClick: () => {
              setCurrentRecord(row);
              exportWorkflow.onOpen();
            },
          },
          {
            label: 'Delete',
            icon: <TrashIcon color={'red'} />,
            onClick: () => {
              deleteConfirmation.onOpen();
              setCurrentRecord(row);
            },
          },
        ];

        return <TableActionsMenu menuItems={menuItems} />;
      },
      align: 'center',
    },
  ];

  return (
    <Card styles={{ card: { h: contentHeight + 'px' } }}>
      <Stack h="full" spacing={5} w="full">
        <Stack w="full" justify="space-between" isInline>
          <PageHeaderWithIcon label="Workflows" icon={<AutomationIcon />} />
          <Flex>
            <AddButton
              label="Add Workflow"
              onClick={() => {
                dispatch(actions.resetFormData());
                navigate('/policy-hub/workflow/add');
              }}
            />
          </Flex>
        </Stack>
        <Box flex={1}>
          <Table
            data={workflows.data}
            columns={columns}
            isLoading={workflows.isLoading}
          />
        </Box>
        {attachWorkflow.isOpen && (
          <AttachWorkflow
            isOpen={attachWorkflow.isOpen}
            onClose={attachWorkflow.onClose}
            loadData={() => dispatch(actions.getAlertWorkflows({}))}
            workflow={currentRecord}
          />
        )}
        {exportWorkflow.isOpen && currentRecord && (
          <ExportWorkflowAlertsModal
            workflow={currentRecord}
            isOpen={exportWorkflow.isOpen}
            onClose={exportWorkflow.onClose}
          />
        )}
        {deleteConfirmation.isOpen && (
          <DeleteConfirmationModal
            name={currentRecord?.name}
            onConfirm={() =>
              dispatch(
                actions.deleteAlertWorkflow({
                  q: { workflowId: currentRecord?.uuid },
                  onSuccess: () => {
                    deleteConfirmation.onClose();
                    dispatch(actions.getAlertWorkflows({}));
                  },
                }),
              )
            }
            type="workflow"
            isOpen={deleteConfirmation.isOpen}
            onClose={deleteConfirmation.onClose}
            isLoading={alertWorkflow.isLoading}
          />
        )}
      </Stack>
    </Card>
  );
};
