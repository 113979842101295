import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SMSIcon = props => (
  <Icon viewBox="0 0 48 48" w="full" h="full" color="inherit" {...props}>
    <path d="M37,39H11l-6,6V11c0-3.3,2.7-6,6-6h26c3.3,0,6,2.7,6,6v22C43,36.3,40.3,39,37,39z" />
    <g fill="#fff">
      <circle cx="24" cy="22" r="3" />
      <circle cx="34" cy="22" r="3" />
      <circle cx="14" cy="22" r="3" />
    </g>
  </Icon>
);
