import React, { FC, useEffect, useMemo, useState } from 'react';

import { Box, Center, Checkbox, HStack, Stack, Wrap } from '@chakra-ui/react';
import { cloneDeep, isArray, keys, uniq, isString, map } from 'lodash';
import filter from 'lodash/filter';
import { AiOutlineClose } from 'react-icons/all';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customTheme } from 'theme';

import { Tag } from 'components/DataDisplay';
import { DropdownIndicator } from 'components/DataEntry/Select/utils';
import { FilterIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';

import { defaultCheckBoxStyles, defaultSelectStyles } from './styles';
import { SelectProps } from './types';

export const CustomSelect: FC<SelectProps> = props => {
  const {
    styles = {},
    overwriteDefaultStyles,
    checkBoxStyles,
    isMulti,
    value,
    showTotalSelected,
    height,
    options,
    onChange: propOnChange,
    onClickGroupHeading,
    allOption,
    creatable = false,
    showSelectedAtBottom = false,
    onCreateOption,
    placeholder = 'Select...',
    alwaysShowPlaceholder = false,
    showIconInValueContainer = false,
    valueContainerIcon,
    selectedMessage,
    leftMessage,
    ...rest
  } = props;

  const [selected, setSelected] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    isMulti && setSelected((value as Record<string, any>[]) ?? []);
  }, [value, isMulti]);

  const baseSelectStyles = useMemo(() => {
    const props = cloneDeep(defaultSelectStyles(height, showTotalSelected));

    const mergeStyles = () => {
      const styleNames = uniq([...keys(props), ...keys(styles)]);

      return styleNames.reduce(
        (o, name) => ({
          ...o,
          [name]: (...args) => {
            const defaultStyle = props[name] ? props[name](...args) : {};
            const customStyle = styles[name] ? styles[name](...args) : {};

            return {
              ...defaultStyle,
              ...customStyle,
            };
          },
        }),
        {} as any,
      );
    };

    return overwriteDefaultStyles ? styles : mergeStyles();
  }, [height, overwriteDefaultStyles, styles, showTotalSelected]);

  const renderLabel = props => {
    const { isSelected, data } = props;
    return (
      <HStack w="full">
        {data?.icon && (
          <Center color={isSelected ? 'white' : 'primary'}>
            <Center boxSize={4}>
              {isString(data?.icon) ? getIcon(data?.icon) : data?.icon}
            </Center>
          </Center>
        )}
        <Box flex={1}>{data?.label}</Box>
      </HStack>
    );
  };

  const SingleValue = props => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <HStack w="full" spacing={0}>
          {showIconInValueContainer && (
            <Center h="full" boxSize={6} pr={2}>
              <Center color="primary" w="full">
                {valueContainerIcon ?? data?.icon ?? <FilterIcon />}
              </Center>
            </Center>
          )}
          {leftMessage && <Box pr={1}>{leftMessage}</Box>}
          <Box flex={1} overflow="hidden" textOverflow="ellipsis">
            {props.children}
          </Box>
        </HStack>
      </components.SingleValue>
    );
  };

  const renderFilterIcon = (size = 6) => (
    <Center h="full" boxSize={size}>
      <Center color="primary" w="full">
        <FilterIcon />
      </Center>
    </Center>
  );

  const Placeholder = props => {
    return (
      <components.Placeholder {...props}>
        <HStack w="full" h="full">
          {showIconInValueContainer && (
            <Center boxSize={5}>
              <Center color="primary" w="full">
                <FilterIcon />
              </Center>
            </Center>
          )}
          <Box w="full" overflow="hidden" textOverflow="ellipsis">
            {props.children}
          </Box>
        </HStack>
      </components.Placeholder>
    );
  };

  // render options
  const Option = props => {
    const { isSelected, data, isDisabled } = props;

    return (
      <components.Option {...props}>
        {isMulti ? (
          // @ts-ignore
          <Checkbox
            isChecked={isSelected}
            isDisabled={isDisabled}
            value={data.label}
            {...defaultCheckBoxStyles}
            {...checkBoxStyles}
          >
            {renderLabel(props)}
          </Checkbox>
        ) : (
          <Box opacity={isDisabled ? 0.4 : 1}>{renderLabel(props)}</Box>
        )}
      </components.Option>
    );
  };

  const ClearIndicator = props => {
    return alwaysShowPlaceholder ? null : (
      <components.DropdownIndicator {...props}>
        <AiOutlineClose color={customTheme.colors.gray['400']} size={12} />
      </components.DropdownIndicator>
    );
  };

  const MultiValue = props => {
    const { children, index, getValue } = props;
    let length = getValue()?.length;

    const maxToShow = 0;

    if (showTotalSelected || alwaysShowPlaceholder) {
      return index < maxToShow ? (
        <components.MultiValue {...props} />
      ) : index === maxToShow ? (
        <Box
          {...(alwaysShowPlaceholder ? baseSelectStyles?.placeholder() : {})}
        >
          {alwaysShowPlaceholder ? (
            placeholder
          ) : (
            <HStack>
              {showIconInValueContainer && renderFilterIcon(4)}
              <Center py={0} h="full">
                {length} {selectedMessage || 'selected'}
              </Center>
            </HStack>
          )}
        </Box>
      ) : null;
    }

    return (
      <components.MultiValue {...props}>
        <HStack>
          {showIconInValueContainer && renderFilterIcon()}
          <Box>{children}</Box>
        </HStack>
      </components.MultiValue>
    );
  };

  const formatGroupLabel = data => (
    <HStack
      justify="space-between"
      onClick={() => onClickGroupHeading?.(data?.options)}
      cursor={onClickGroupHeading ? 'pointer' : 'inherit'}
      borderBottom="1px solid"
      borderColor="gray.100"
      pb={1}
      fontSize="13px"
    >
      <Box>{data.label}</Box>
      <Box>{data.options.length}</Box>
    </HStack>
  );

  const values = {
    openMenuOnFocus: true,
    isMulti,
    closeMenuOnSelect: !isMulti,
    hideSelectedOptions: false,
    components: {
      ClearIndicator,
      DropdownIndicator,
      Option,
      MultiValue,
      SingleValue,
      Placeholder,
    },
    placeholder: placeholder,
    formatGroupLabel: formatGroupLabel,
    styles: baseSelectStyles,
    options: allOption ? [allOption, ...(options || [])] : options,
    onChange: (selected: any, event: any) => {
      if (isArray(selected)) {
        let result: any[] = [];
        if (selected.length > 0 && allOption) {
          if (selected[selected.length - 1].value === allOption.value) {
            result = [allOption, ...(options || [])];
            setSelected([result]);
            return propOnChange?.(result, event);
          }

          if (selected.length === options?.length) {
            if (selected.some(({ label }) => label === '*')) {
              result = selected.filter(
                option => option?.value !== allOption?.value,
              );
            } else if (
              event.action === 'select-option' &&
              event?.action?.option?.value !== '*'
            ) {
              result = [allOption, ...options];
            }
            setSelected(result);
            return propOnChange?.(result, event);
          }
        }
      }

      //if all option is present in list
      if (isMulti && selected.find(o => o.value === 'all'))
        return propOnChange?.(options?.slice(1) ?? [], event);

      return propOnChange?.(selected, event);
    },
    //menuPortalTarget={document.body} // commented because valueContainer is not fixed with the parent container on scroll. This is a react-select bug reported over github.
    //menuPlacement: 'auto',
    //blurInputOnSelect={!isMulti}
    //autoBlur
    ...rest,
    value: isMulti && showSelectedAtBottom ? selected : value,
  };

  return (
    <Stack>
      {creatable ? (
        <CreatableSelect
          menuPlacement="auto"
          onCreateOption={onCreateOption}
          {...values}
        />
      ) : (
        <Select menuPlacement="auto" {...values} />
      )}
      {isMulti && showSelectedAtBottom && (
        <Wrap>
          {map(selected, o => (
            <Tag
              label={o.label}
              closeButton
              onClose={() =>
                setSelected(filter(selected, s => s.value !== o.value))
              }
              styles={{
                tag: {
                  fontSize: 'sm',
                  py: 0,
                  px: 2,
                  mr: 1,
                  bg: 'hover',
                  border: '1px solid',
                  borderColor: 'primary',
                },
                closeButton: { borderRadius: 'full', bg: 'white' },
              }}
            />
          ))}
        </Wrap>
      )}
    </Stack>
  );
};
