import React, { useEffect, useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { each } from 'lodash';
import { useSelector } from 'react-redux';

import { formatTooltip } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CriticalDataIcon, IconTypes } from 'components/Icons';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { CountCards } from 'containers/Dashboard/utils/CountCards';

export const IdentitiesWithAccessToSensitiveData: React.FC = () => {
  const { identitiesWithAccessToSensitiveData } = useSelector(
    selectIdentityOverview,
  );
  const [data, setData] = useState<Record<string, any>>({});
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  useEffect(() => {
    let items = {};
    each(
      identitiesWithAccessToSensitiveData.data?.items,
      o => (items[o.provider_or_source_type + ' ' + o.agnostic] = o.count),
    );
    setData(items);
  }, [identitiesWithAccessToSensitiveData.data]);

  const categories = [
    {
      label: 'Users',
      iconType: IconTypes.IamUser,
      count: formatNumber(data?.['Cloud User'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconColor: 'blue.300',
      borderColor: 'gray.200',
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataUsers,
          iconType: IconTypes.IamUser,
          total: data?.['Cloud User'] || 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_access_to_sensitive_data,
            ['User'],
          )}`,
        );*/
      },
      tooltip: formatTooltip({
        header: 'Users',
        content: 'Cloud-native and federated users',
        width: 32,
      }),
    },
    {
      label: 'Roles',
      iconType: IconTypes.Role,
      count: formatNumber(data?.['Cloud Role'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconColor: 'primary',
      borderColor: 'gray.200',
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataRoles,
          iconType: IconTypes.IamUser,
          total: data?.['Cloud Role'] || 0,
        });
        /*navigate(
          `/visibility/security-graph?query=${getSearchQuery(
            SearchQueryMapping.Show_resource_type_with_access_to_sensitive_data,
            ['Role'],
          )}`,
        )*/
      },
      tooltip: formatTooltip({
        header: 'Roles',
        content: 'Cloud-native roles for public clouds, SaaS',
        width: 32,
      }),
    },
    {
      label: 'Cross-Account',
      iconType: IconTypes.CrossAccount,
      count: formatNumber(data?.['Cross Account Role'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconColor: 'primary',
      borderColor: 'gray.200',
      tooltip: formatTooltip({
        header: 'Cross account',
        content: 'Other accounts that have been onboarded',
        width: 32,
      }),
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataCrossAccount,
          iconType: IconTypes.CrossAccount,
          total: data?.['Cross Account Role'] || 0,
        });
      },
    },
    {
      label: 'Third-party',
      iconType: IconTypes.ThirdParty,
      count: formatNumber(data?.['Third Party Account'] || 0),
      isLoading: identitiesWithAccessToSensitiveData.isLoading,
      iconColor: 'primary',
      borderColor: 'gray.200',
      tooltip: formatTooltip({
        header: 'Third-party',
        content: 'Other accounts that have not been onboarded',
        width: 32,
      }),
      onClick: () => {
        allEntitiesDisclosure.onOpen();
        setDetails({
          panel: PanelsEnum.SensitiveDataThirdParty,
          iconType: IconTypes.ThirdParty,
          total: data?.['Third Party Account'] || 0,
        });
      },
    },
  ];

  return (
    <>
      <CountCards
        label="Identities with access to sensitive data"
        icon={<CriticalDataIcon />}
        iconBgColor="primary"
        items={categories}
        stackAllVertically
        spacing={6}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
