import React, { useState, useEffect, useRef } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { CustomTooltip, renderTooltipFieldValue } from 'components/DataDisplay';
import { VMImageIcon } from 'components/Icons';
import { SunburstChart } from 'components/Visualization';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const MostDeployedVulnerableImages = () => {
  const { vulnerableImages } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<any>([]);
  const ref = useRef<any>(null);
  const colors = [
    customTheme.colors.skyBlue['300'],
    customTheme.colors.primary,
    customTheme.colors.yellow['300'],
    customTheme.colors.blue['300'],
    customTheme.colors.red['300'],
    customTheme.colors.indigo['300'],
    customTheme.colors.green['300'],
    customTheme.colors.orange['300'],
    customTheme.colors.gray['300'],
  ];

  useEffect(() => {
    const items = map(vulnerableImages.data?.items, (o, index) => ({
      name: o.image,
      color: colors[index % colors.length],
      ...o,
      ...((o?.image_count || 0) > 1 ? { value: o.image_count } : {}),
      children: [
        { value: o?.resource_count, color: colors[index % colors.length] },
      ],
    }));

    setData(items);
  }, [vulnerableImages.data]);

  const renderLegend = item => (
    <HStack w="full">
      <Box>
        <Box boxSize={2.5} bg={item?.color} borderRadius={2} />
      </Box>
      <CustomTooltip
        label={
          <Stack>
            {renderTooltipFieldValue('Name', item.name)}
            {renderTooltipFieldValue('Image Count', item.image_count)}
            {renderTooltipFieldValue('Resources Count', item.resource_count)}
          </Stack>
        }
      >
        <Box
          flex={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {item.name}
        </Box>
      </CustomTooltip>
    </HStack>
  );

  return (
    <DashboardOverviewCard
      label="Most deployed vulnerable images"
      icon={<VMImageIcon />}
      iconBgColor="critical"
      isLoading={vulnerableImages.isLoading}
      //rightHeader={renderCloudIcons(vulnerableImages.data?.clouds)}
      content={
        <Box
          w="full"
          h="95%"
          pr={2}
          py={2}
          overflow="hidden"
          display="inline-block"
          position="relative"
          mb={10}
        >
          <SunburstChart data={data} renderLegend={renderLegend} ref={ref} />
        </Box>
      }
    />
  );
};
