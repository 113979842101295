import React, { FC, useCallback } from 'react';

import { Level } from '@ariksa/compliance-policies/api';
import { Box, UseDisclosureProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ComplianceSubControls } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/ComplianceBlueprint/ComplianceSubControls';

import { selectBlueprints } from '../../../../selectors';
import { actions } from '../../../../slice';

interface Props {
  searchTerm?: string;
  addPolicy?: UseDisclosureProps;
  selectedPolicies?: string[];
  setSelectedPolicies?(value: string[]);
  action?: 'Create' | 'Show' | 'Update';
}

export const ComplianceBlueprintControls: FC<Props> = props => {
  const {
    searchTerm,
    addPolicy,
    selectedPolicies,
    setSelectedPolicies,
    action = 'Show',
  } = props;
  const dispatch = useDispatch();
  const { complianceControls, blueprint } = useSelector(selectBlueprints);

  //get policies
  const getComplianceControls = useCallback(() => {
    dispatch(
      actions.getComplianceControls({
        q: { blueprintId: blueprint.data?.id, level: Level.Control },
      }),
    );
  }, [dispatch, blueprint.data]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Control</Box>,
      accessor: 'control',
      render: ({ value }) => (
        <WithResourceIcon
          resourceType={blueprint.data.compliance!}
          iconFilled={false}
          iconSize="regular"
        >
          {value}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Description',
      accessor: 'control_description',
      align: 'left',
    },
    {
      header: '# of Policies',
      accessor: 'rules',
      render: ({ value }) => value.total ?? '-',
      align: 'left',
    },
    {
      header: 'Entities',
      accessor: 'check_stats.entities_with_issues',
      align: 'left',
    },
    {
      header: 'Policy Exceptions',
      accessor: 'rules',
      render: ({ value }) => {
        if (value?.total && value?.selected) {
          return value?.total - value?.selected;
        } else {
          return '-';
        }
      },
      align: 'left',
    },
    /* {
      header: 'Actions',
      render: ({ row }) => (
        <Center>
          <ActionButton
            label="Exclude control"
            isDisabled={
              !blueprint.data.created_by ||
              blueprint.data.created_by === 'system'
            }
            icon={<PolicyExceptionIcon />}
          />
        </Center>
      ),
    },*/
  ];

  useDeepCompareEffect(() => {
    getComplianceControls();
  }, [getComplianceControls]);

  return (
    <Box h="full" flex={1}>
      <Table
        data={complianceControls.data}
        columns={columns}
        isLoading={complianceControls.isLoading}
        cursor="pointer"
        styles={{ header: { zIndex: 800 } }}
        subComponent={({ row, expanded }) =>
          expanded && (
            <ComplianceSubControls
              control={row}
              addPolicy={addPolicy}
              setSelectedPolicies={setSelectedPolicies}
              selectedPolicies={selectedPolicies}
              action={action}
            />
          )
        }
        pagination={{
          totalCount: complianceControls.page.totalCount,
          pageInfo: complianceControls.page.info,
          onChange: pageInfo =>
            dispatch(actions.updateComplianceControlsPagination(pageInfo)),
        }}
      />
    </Box>
  );
};
