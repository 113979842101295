import { useEffect } from 'react';

import { forEach, toArray } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useSearchResult } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchResult';

export const useCallRiskContextApi = () => {
  const dispatch = useDispatch();

  const { searchResult } = useSearchResult();
  //get risk context
  useEffect(() => {
    if (!isEmpty(searchResult.data.nodes)) {
      let uuidsSet = new Set();
      forEach(
        searchResult?.data.nodes,
        o => !!o?.uuid && uuidsSet.add(o?.uuid),
      );
      const uuids = toArray(uuidsSet);
      dispatch(
        sharedStateActions.getRiskContext({
          q: {
            riskContextRequest: { uuids: uuids as string[] },
          },
        }),
      );
    }
  }, [dispatch, searchResult.data.nodes]);
};
