import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';

export const useRedirectInfo = () => {
  const { redirectInfo, isRedirected } = useSelector(selectSecurityGraphNext);
  const prevRedirectInfo = usePrevious(redirectInfo);
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const isQueryChanged = useMemo(() => {
    return (
      redirectInfo?.query !== prevRedirectInfo?.query &&
      redirectInfo?.query_name !== prevRedirectInfo?.query_name &&
      redirectInfo?.search_uuid !== prevRedirectInfo?.search_uuid
    );
  }, [prevRedirectInfo, redirectInfo]);

  return {
    redirectInfo,
    prevRedirectInfo,
    isRedirected,
    location,
    prevLocation,
    isQueryChanged,
  };
};
