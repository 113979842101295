export class ADimension {
  x = 0;
  y = 0;
  w = 0;
  h = 0;

  static default() {
    return new ADimension();
  }
}
