import React from 'react';

import { Icon } from '@chakra-ui/react';

export const NeptuneClusterIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M508.3,78.8C508.3,35.4,473,0,429.5,0c-43.4,0-78.8,35.4-78.8,78.8c0,7.4,1,14.5,2.9,21.3L142.5,212.2
		c-14.5-17-36-27.9-60.1-27.9c-43.5,0-78.8,35.4-78.8,78.8s35.3,78.8,78.8,78.8c22.3,0,42.5-9.4,56.9-24.4l146.9,89.8
		c-2.8,8.1-4.4,16.8-4.4,25.8c0,43.4,35.4,78.8,78.8,78.8s78.8-35.4,78.8-78.8c0-32.4-19.7-60.3-47.7-72.4l39.5-203.3
		C474,156.6,508.3,121.7,508.3,78.8z M82.5,308.4c-25,0-45.3-20.3-45.3-45.3s20.3-45.3,45.3-45.3c24.9,0,45.3,20.3,45.3,45.3
		S107.4,308.4,82.5,308.4z M406,433.2c0,25-20.3,45.3-45.3,45.3c-25,0-45.3-20.3-45.3-45.3c0-24.9,20.3-45.3,45.3-45.3
		C385.6,387.9,406,408.3,406,433.2z M358.9,354.4c-21.6,0.5-41.1,9.7-55.1,24.3L156.9,289c2.8-8.1,4.4-16.8,4.4-25.8
		c0-7.4-1-14.5-2.9-21.3l211.1-112.1c7.8,9.2,17.7,16.6,28.9,21.5L358.9,354.4z M384.2,78.8c0-25,20.3-45.3,45.3-45.3
		c25,0,45.3,20.3,45.3,45.3s-20.3,45.3-45.3,45.3C404.6,124.1,384.2,103.8,384.2,78.8z"
      />
      <path
        d="M395.4,167.5c0-21.1-17.2-38.3-38.3-38.3c-21.1,0-38.3,17.2-38.3,38.3c0,3.6,0.5,7.1,1.4,10.3l-102.6,54.5
		c-7-8.3-17.5-13.6-29.2-13.6c-21.2,0-38.3,17.2-38.3,38.3c0,21.1,17.2,38.3,38.3,38.3c10.9,0,20.7-4.6,27.7-11.8l71.3,43.6
		c-1.3,3.9-2.1,8.2-2.1,12.6c0,21.1,17.2,38.3,38.3,38.3c21.2,0,38.3-17.2,38.3-38.3c0-15.7-9.6-29.3-23.2-35.2l19.2-98.8
		C378.6,205.3,395.4,188.3,395.4,167.5z M188.4,279.1c-12.2,0-22-9.9-22-22c0-12.1,9.9-22,22-22c12.1,0,22,9.9,22,22
		C210.4,269.2,200.5,279.1,188.4,279.1z M345.6,339.7c0,12.1-9.9,22-22,22c-12.1,0-22-9.9-22-22c0-12.1,9.9-22,22-22
		C335.8,317.7,345.6,327.6,345.6,339.7z M322.8,301.4c-10.5,0.2-20,4.7-26.8,11.8l-71.3-43.6c1.3-3.9,2.1-8.2,2.1-12.6
		c0-3.6-0.5-7.1-1.4-10.3l102.6-54.5c3.8,4.5,8.6,8.1,14.1,10.4L322.8,301.4z M335.1,167.5c0-12.1,9.9-22,22-22c12.2,0,22,9.9,22,22
		c0,12.1-9.9,22-22,22C345,189.5,335.1,179.6,335.1,167.5z"
      />
    </g>
  </Icon>
);
