import React from 'react';

import { useSelector } from 'react-redux';

import { AggregatedAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/AggregatedAlerts';
import { IndividualAlerts } from 'containers/Findings/Alerts/Components/AlertsTable/IndividualAlerts';
import { selectAlerts } from 'containers/Findings/Alerts/selectors';

export const OpenAlerts = () => {
  const { groupByResource } = useSelector(selectAlerts);

  return groupByResource ? <AggregatedAlerts /> : <IndividualAlerts />;
};
