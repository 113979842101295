import React, { FC } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { IdeaGlitterIcon, RightArrowIcon } from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  isSaaSAccount,
  useCloudAccountId,
} from 'containers/Setup/CloudAccounts/utils';

export const NoVulnerabilityScannerDeployed: FC = () => {
  const navigate = useNavigate();
  const { accountId } = useAccessBoundary();
  const { accountMapping } = useCloudAccountId();

  const renderIcon = () => (
    <Box boxSize={10} color="primary">
      <Center w={8} h={8}>
        <IdeaGlitterIcon />
      </Center>
    </Box>
  );

  return (
    <Center maxH="600px" h="full">
      {!!accountId && isSaaSAccount(accountMapping[accountId]) ? (
        <HStack fontSize="lg" spacing={4}>
          {renderIcon()}
          <Box>Vulnerability Management is not Supported for this account.</Box>
        </HStack>
      ) : (
        <Stack spacing={4} w={'sm'}>
          <HStack fontWeight={600} fontSize="lg" spacing={4}>
            {renderIcon()}
            <Box>Vulnerability Management not enabled</Box>
          </HStack>
          <Box pl={14}>
            Ariksa provides vulnerability management for OS, apps and databases
            running in virtual machine and containers.
          </Box>
          <Box pl={14}>
            Ariksa also automatically prioritizes risks due to vulnerabilities
            by providing holistic risk context such as Internet exposure.
          </Box>
          <HStack
            w="full"
            justify="flex-end"
            pt={3}
            color="primary"
            cursor="pointer"
            onClick={() => navigate('/setup/outpost/add/vulnerability')}
          >
            <Box>Enable now</Box>
            <Box
              boxSize={4}
              border="1px solid"
              borderColor="primary"
              borderRadius="full"
              p="1px"
            >
              <RightArrowIcon />
            </Box>
          </HStack>
        </Stack>
      )}
    </Center>
  );
};
