import { cloneDeep, identity } from 'lodash';

import { PageInfo } from 'components/Navigation';

import { QueryAction, QueryError, QueryParams, QueryStatus } from '../types';

export type MapFn<A, B> = (d: A) => B;

export interface IQueryPage {
  totalCount: number;
  info: PageInfo;
}

export type IQueryState<T> = {
  initState: T;
  data: T;
  status: QueryStatus;

  error?: QueryError;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

// class QueryState.

export interface QueryStateOpts {
  page?: IQueryPage;
  status?: QueryStatus;
  error?: QueryError;
}

export interface QueryStateNextOpts<P, D> {
  mapData?: MapFn<P, D>;
}

export class QueryState<D> {
  initState: D;

  data: D;

  status: QueryStatus;

  error?: QueryError;

  static emptyFromAction(action: QueryAction<any>) {
    return new QueryState(
      {},
      {},
      {
        status: action.payload.status,
        error: action.payload.error,
      },
    ).toJSON();
  }

  public static init<T>(data: T, opts: QueryStateOpts = {}) {
    return new QueryState(data, data, opts).toJSON();
  }

  // create next state from current state,
  // optionally using a mapper
  public static next<P, D, Q>(
    state: IQueryState<D>,
    action: QueryAction<P, Q>,
    opts?: QueryStateNextOpts<P, D>,
  ): IQueryState<D> {
    const { mapData = identity } = opts ?? {};

    const { payload = {} as any } = action;
    let { data: payloadData, status, error, reset } = payload;

    let data = state.initState;
    if (payloadData) {
      // @ts-ignore
      data = mapData(payloadData);
    }

    if (reset) {
      status = QueryStatus.uninitialized;
    }

    return new QueryState(state.initState, data, {
      status,
      error,
    }).toJSON();
  }

  // WARNING: Does not work
  private static update<P, D, Q>(
    state: IQueryState<D>,
    action: QueryAction<P, Q>,
    opts?: QueryStateNextOpts<P, D>,
  ) {
    // @ts-ignore
    state = QueryState.next(state, action, opts);
  }

  protected toJSON(): IQueryState<D> {
    const {
      initState,
      data,
      error,
      status,
      isLoading,
      isSuccess,
      isError,
    } = this;

    return {
      initState,
      data,
      error,
      status,
      isLoading,
      isSuccess,
      isError,
    };
  }

  get isUninitialized() {
    return this.status === QueryStatus.uninitialized;
  }

  get isLoading() {
    return this.status === QueryStatus.pending;
  }

  get isError() {
    return this.status === QueryStatus.failed;
  }

  get isSuccess() {
    return this.status === QueryStatus.fulfilled;
  }

  get isUnknown() {
    return this.status === QueryStatus.unknown;
  }

  get errorMsg() {
    return this.error?.title ?? '';
  }

  protected constructor(initState: D, data: D, opts: QueryStateOpts = {}) {
    this.initState = initState;

    const { status, error } = opts;

    this.data = data;
    this.status = status ?? QueryStatus.uninitialized;

    const { title = '', description = '' } = error ?? {};
    this.error = {
      title,
      description,
    };
  }

  private clone<P>(data: D, payload?: QueryParams<P>) {
    const initState = cloneDeep(this.initState);
    return new QueryState(initState, data, {
      status: payload?.status ?? QueryStatus.uninitialized,
      error: payload?.error,
    });
  }

  private static reset<D>(state: IQueryState<D>): IQueryState<D> {
    return new QueryState(state.initState, state.initState, {
      status: QueryStatus.uninitialized,
    }).toJSON();
  }
}
