import { IDashboardLayout } from 'components/Visualization/CDashboard/types';

import { availableWidgets } from './widget';

export const inventorySummaryWidgets: IDashboardLayout = {
  id: 'dashboard_1',
  title: 'Dashboard Title',
  widgets: [
    {
      type: 'pie-chart',
      i: 'byCloudResources',
      x: 0,
      y: 0,
      w: 6,
      h: 8,
      minH: 2,
      minW: 2,
      isResizable: false,
    },
    {
      type: 'pie-chart',
      i: 'cloudResourcesByRegion',
      x: 6,
      y: 0,
      w: 6,
      h: 8,
      minH: 2,
      minW: 2,
      isResizable: false,
    },
    {
      type: 'pie-chart',
      i: 'byIdentitiesOnCloud',
      x: 0,
      y: 6,
      w: 6,
      h: 8,
      isResizable: false,
    },
    {
      type: 'pie-chart',
      i: 'byCloudType',
      x: 6,
      y: 6,
      w: 6,
      h: 8,
      minH: 4,
      minW: 2,
      isResizable: false,
    },
  ],
  available: availableWidgets,
};
