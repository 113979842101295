import React, { FC, useEffect, useReducer, useRef } from 'react';

import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  dateLabel,
  DetailsIdentifier,
  StackedCell,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import { IndividualEOLSoftwareDrawer } from 'containers/Dashboard/VulnerabilityOverview/Components/EOLSoftware/IndividualEOLSoftwareDrawer';
import {
  osAndTechnologyOptions,
  technologyCloudOptions,
} from 'containers/Dashboard/VulnerabilityOverview/Components/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { actions } from 'containers/Dashboard/VulnerabilityOverview/slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AllEOLSoftwareDrawer: FC<Props> = props => {
  const { isOpen, onClose } = props;
  const { allEolSoftware } = useSelector(selectVulnerabilityOverview);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const { environmentId, accountId } = useAccessBoundary();
  const detailsDisclosure = useDisclosure();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      currentRecord: {},
      osAndTechnology: osAndTechnologyOptions[0],
      cloud: technologyCloudOptions[0],
    },
  );

  useEffect(() => {
    dispatch(
      actions.getAllEOLSoftware({
        q: {
          size: allEolSoftware.page.info.page_size,
          page: allEolSoftware.page.info.page_number,
          environmentId,
          accountId: !!accountId ? [accountId] : undefined,
          osAndTech: state?.osAndTechnology?.value,
          cloud: state?.cloud?.value,
        },
      }),
    );
  }, [
    allEolSoftware.page.info,
    environmentId,
    accountId,
    dispatch,
    state?.osAndTechnology,
    state?.cloud,
  ]);

  const columns = [
    {
      header: 'Name',
      render: ({ row }) => (
        <WithResourceIcon resourceType={row?.name} iconFilled={false}>
          <StackedCell upper={row?.name} lower={row?.version} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'EOL Date',
      render: ({ row }) =>
        !!row?.end_of_life ? dateLabel(row?.end_of_life) : '-',
      align: 'left',
    },
    {
      header: 'Latest',
      accessor: 'latest_version',
      align: 'left',
    },
    {
      header: 'Deployed In',
      render: ({ row }) => (
        <StackedCell
          upper={
            <Center>
              {renderCloudIcons(
                filter(
                  Object.keys(row?.entity_summary),
                  o => !!row?.entity_summary?.[o],
                ),
              )}
            </Center>
          }
          lower={
            row?.account_uuids?.length +
            ' account' +
            (row?.account_uuids?.length === 1 ? '' : 's')
          }
          showLowerTooltip={false}
          showUpperTooltip={false}
        />
      ),
    },
    {
      header: 'Occurrences',
      accessor: 'occurrences',
    },
    /*{
      header: 'Vulnerabilities',
      accessor: '',
    },*/
  ];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper="Vulnerabilities"
          lower="End-of-life software (OS and Apps)"
          type={IconTypes.EOL}
          bg="critical"
          useCustomColor
        />
      }
      styles={{
        drawer: { portalProps: { containerRef: ref as any } },
        content: { maxW: '1600px' },
      }}
      closeButton
      body={
        <Stack h="full" pt={3} spacing={4}>
          <HStack spacing={6}>
            <DetailsIdentifier
              label="Description"
              value="When you deploy Outpost for Vulnerabilities, Ariksa automatically gathers info for software
              deployed in your cloud environment and assesses whether they have reached end-of-life.
              For software beyond its EOL date, vendors do not deliver security patches or other updates
              that may be required for security, performance or other factors. Existing and new exploits
              will remain unaddressed, increasing risk of exploitability. We recommend patching all EOL
              software as a baseline for security best practices in your cloud environment."
              direction="column"
              w="55%"
              pb={1}
              styles={{ label: { color: 'black' } }}
            />
            <Divider orientation="vertical" borderColor="gray.200" />
            <DetailsIdentifier
              label="More information"
              value={
                <Center h="full" w="full">
                  No details
                </Center>
              }
              direction="column"
              h="full"
              w="45%"
              pb={1}
              styles={{ label: { color: 'black' } }}
            />
          </HStack>
          <Divider borderColor="primary" />
          <HStack justify="space-between">
            <Box fontWeight={600} fontSize="15px">
              Entities
            </Box>
            <HStack>
              <Box color="primary">FILTERS:</Box>
              <Box w={64} zIndex={900}>
                <Select
                  options={osAndTechnologyOptions}
                  value={state.osAndTechnology}
                  onChange={s => {
                    updateState({ osAndTechnology: s });
                  }}
                  showIconInValueContainer
                />
              </Box>
              <Box w={48} zIndex={900}>
                <Select
                  options={technologyCloudOptions}
                  value={state.cloud}
                  onChange={s => {
                    updateState({ cloud: s });
                  }}
                  showIconInValueContainer
                />
              </Box>
            </HStack>
          </HStack>
          <Box flex={1}>
            <Table
              data={allEolSoftware.data}
              isError={allEolSoftware.isError}
              columns={columns}
              isLoading={allEolSoftware.isLoading}
              styles={{ header: { position: 'relative', zIndex: 800 } }}
              onRowClick={row => {
                updateState({ currentRecord: row });
                detailsDisclosure.onOpen();
              }}
              pagination={{
                pageInfo: allEolSoftware.page.info,
                onChange: info =>
                  dispatch(actions.updateEOLSoftwarePagination(info)),
                totalCount: allEolSoftware.page.totalCount,
              }}
            />
          </Box>
          {detailsDisclosure.isOpen && (
            <IndividualEOLSoftwareDrawer
              isOpen={detailsDisclosure.isOpen}
              onClose={detailsDisclosure.onClose}
              currentRecord={state?.currentRecord}
            />
          )}
        </Stack>
      }
    />
  );
};
