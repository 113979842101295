import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VpcFlowLogIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <g>
        <rect x="224.8" y="284.8" width="51.2" height="7.1" />
        <rect x="224.8" y="328.9" width="51.2" height="7.1" />
        <rect x="224.8" y="314.2" width="51.2" height="7.1" />
        <polygon points="220.4,263.3 280.4,263.3 280.4,295.6 287.8,295.6 287.8,256.2 213,256.2 213,295.5 220.4,295.5 		" />
        <polygon
          points="280.4,342.8 220.4,342.8 220.4,310.8 213,310.8 213,349.9 287.8,349.9 287.8,349.9 287.8,311.3
			280.4,311.3 		"
        />
        <rect x="224.8" y="270.1" width="51.2" height="7.1" />
        <polygon points="323.7,303.1 301.5,285.7 301.5,299 188.3,299 188.3,307.1 301.5,307.1 301.5,321.1 		" />
      </g>
      <path
        d="M443.6,351.4c-19.7,0-37.4,8.4-49.9,21.7l-53.5-32c5.2-11.6,8.2-24.5,8.2-38.1c0-48-36.4-87.6-83.1-92.6v-50.7
		c33-4.9,58.4-33.4,58.4-67.7c0-37.7-30.7-68.4-68.4-68.4s-68.4,30.7-68.4,68.4c0,34.3,25.4,62.8,58.4,67.7v50.7
		c-46.7,5-83.1,44.6-83.1,92.6c0,13.9,3.1,27.1,8.6,39l-52.2,31.1c-12.5-13.3-30.3-21.7-49.9-21.7C30.7,351.4,0,382.1,0,419.9
		s30.7,68.4,68.4,68.4s68.4-30.7,68.4-68.4c0-10.8-2.5-21.1-7-30.2l51.1-30.4c17,22.4,43.9,36.9,74.2,36.9
		c30.6,0,57.8-14.9,74.8-37.7l52.2,31.2c-4.5,9.1-7.1,19.4-7.1,30.2c0,37.7,30.7,68.4,68.4,68.4s68.4-30.7,68.4-68.4
		S481.3,351.4,443.6,351.4z M68.4,468.3c-26.7,0-48.4-21.7-48.4-48.4c0-26.7,21.7-48.4,48.4-48.4s48.4,21.7,48.4,48.4
		C116.8,446.6,95.1,468.3,68.4,468.3z M206.8,92.1c0-26.7,21.7-48.4,48.4-48.4c26.7,0,48.4,21.7,48.4,48.4s-21.7,48.4-48.4,48.4
		C228.5,140.5,206.8,118.8,206.8,92.1z M255.1,376.1c-40.3,0-73.1-32.8-73.1-73.1c0-40.3,32.8-73.1,73.1-73.1
		c40.3,0,73.1,32.8,73.1,73.1C328.2,343.4,295.4,376.1,255.1,376.1z M443.6,468.3c-26.7,0-48.4-21.7-48.4-48.4
		c0-26.7,21.7-48.4,48.4-48.4c26.7,0,48.4,21.7,48.4,48.4C491.9,446.6,470.2,468.3,443.6,468.3z"
      />
    </g>
  </Icon>
);
