import React, { FC } from 'react';

import { ComplianceStandards } from '@ariksa/compliance-policies/api';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  formatNumberWithComma,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { History } from 'containers/Compliance/Components/History';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';

import {
  renderExceptionsHeader,
  renderPassedChecks,
  renderPassedChecksHeader,
} from '../utils';

import { SubControlTable } from './SubControlTable';

export const ControlTable: FC = () => {
  const {
    controlStandardRules: standardRules,
    selectedBlueprint,
  } = useSelector(selectCompliance);

  const {
    renderRulesActions,
    toggleShowRules,
    rulesRowIndex,
    isOpenSeverityModal,
    isOpenHistory,
  } = useCompliance();

  const dispatch = useDispatch();

  const columns = [
    {
      header: <Box pl={10}>Control</Box>,
      accessor: 'control',
      align: 'left',
      render: ({ value }) => {
        const isAWS =
          selectedBlueprint?.compliance === ComplianceStandards.CisAws14;
        return (
          <WithResourceIcon
            resourceType={
              isAWS ? IconTypes.AWSWhite : selectedBlueprint?.compliance!
            }
            bgColor={isAWS ? 'primary' : 'none'}
            iconFilled={isAWS}
            iconSize="regular"
          >
            <Box>{value}</Box>
          </WithResourceIcon>
        );
      },
    },
    {
      header: 'Name',
      accessor: 'control_description',
      align: 'left',
    },
    {
      header: 'Policies',
      accessor: 'rules',
      render: ({ value }) => value.total ?? '-',
    },
    {
      header: 'Entities',
      accessor: 'check_stats.total_entities',
      render: ({ value }) => formatNumberWithComma(value),
    },
    {
      header: renderExceptionsHeader,
      accessor: 'rules',
      render: ({ value }) => {
        if (value?.total && value?.selected) {
          return value?.total - value?.selected;
        } else {
          return '-';
        }
      },
    },
    {
      header: renderPassedChecksHeader,
      accessor: 'check_stats',
      render: renderPassedChecks,
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Actions',
      render: renderRulesActions,
      styles: { width: '150px', cell: { maxWidth: '150px' } },
    },
  ];

  return (
    <>
      <Table
        data={standardRules.data}
        rowIndex={rulesRowIndex}
        columns={columns}
        cursor="pointer"
        isLoading={standardRules.isLoading}
        subComponent={({ row, expanded, index }) =>
          expanded && (
            <SubControlTable
              control={row}
              rowIndex={index}
              isExpanded={expanded}
            />
          )
        }
        onRowClick={toggleShowRules}
        pagination={{
          totalCount: standardRules.page.totalCount,
          pageInfo: standardRules.page.info,
          onChange: pageInfo => {
            dispatch(actions.updateStandardRulesPageInfo(pageInfo));
          },
        }}
        headerClassName="compliance_by_standards_rules"
      />
      {isOpenSeverityModal && <SeverityComponent />}
      {isOpenHistory && <History />}
    </>
  );
};
