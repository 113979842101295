import React, { FC } from 'react';

import { Wrap, WrapItem } from '@chakra-ui/react';
import { isArray, map } from 'lodash';

import { Tag } from 'components/DataDisplay';
import { limitedString } from 'utils/string';

interface IFilterField {
  field: string;
  value: string | { name: string; value: string }[];
}

export const FilterField: FC<IFilterField> = props => {
  const { field, value } = props;

  const renderTag = label => (
    <Tag
      label={limitedString(label, 25)}
      styles={{ label: { color: 'white' }, tag: { bg: 'primary' } }}
    />
  );
  return (
    <Wrap align="center">
      <WrapItem>{field}</WrapItem>
      {isArray(value) ? (
        map(value, o => (
          <WrapItem key={o.name}>
            {renderTag(
              o?.name +
                (!!o?.value && o?.value !== 'True' ? ` = ${o?.value}` : ''),
            )}
          </WrapItem>
        ))
      ) : (
        <WrapItem>{renderTag(value)}</WrapItem>
      )}
    </Wrap>
  );
};
