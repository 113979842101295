import React, { useEffect, useState } from 'react';

import {
  DataClassificationGroupRead,
  SupportedServices,
} from '@ariksa/data-scanning/api';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, Center } from '@chakra-ui/react';
import { useDataScanningService } from 'services/DataScanning';

import {
  renderTimeStackedCell,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { ActionButton } from 'components/DataEntry';
import { RefreshIcon, SummaryIcon } from 'components/Icons';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';
import {
  renderDataFound,
  renderDataScanned,
  renderFileExtensions,
  renderObjectsScanned,
} from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassifcationTable/utils';

interface Props {
  row: DataClassificationGroupRead;
  isExpanded: boolean;
}

export const DataSources: React.FC<Props> = props => {
  const { row, isExpanded } = props;
  const { dataClassificationSources } = useDataScanningService();
  const { toCloudAccountId } = useCloudAccountId();
  const { getTabIndex } = useMetadataDrawerTabs();
  const [dataTabIndex, setDataTabIndex] = useState(0);
  const [resourceType, setResourceType] = useState('');
  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();

  useEffect(() => {
    isExpanded &&
      dataClassificationSources.sync({ classificationGroupId: row.id });
  }, [dataClassificationSources, row, isExpanded]);

  useEffect(() => {
    setResourceType(row?.data_classifications?.[0]?.service);
  }, [row]);

  useEffect(() => {
    setDataTabIndex(getTabIndex(resourceType, MetadataTabs.Data));
  }, [getTabIndex, row, resourceType]);

  const handleRowClick = (row, tabIndex) => {
    let resourceId = row?.resource_id;
    let uuid = row?.source_UUID;
    let resourceType = row?.resource_type;

    if (row?.resource_type === SupportedServices.GitLabBranch)
      resourceType = NativeResources.GitLabProject;
    else if (row?.resource_type === SupportedServices.GitHubBranch)
      resourceType = NativeResources.GitHubRepository;
    else if (row?.resource_type === SupportedServices.BitBucketBranch)
      resourceType = NativeResources.BitBucketRepository;

    updateActiveResource({
      tabIndex: tabIndex,
      resourceType: resourceType as NativeResources,
      resourceId,
      uuid,
      accountId: row?.account_id,
    });
    onOpenMetadata();
  };

  const renderAction = ({ row }) => {
    return (
      <Center
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ActionButton
          label="Show details"
          icon={<SummaryIcon />}
          onClick={() => {
            handleRowClick(row, dataTabIndex);
          }}
        />
        <ActionButton
          label="Refresh status"
          icon={<RefreshIcon />}
          onClick={() =>
            dataClassificationSources.sync({ classificationGroupId: row.id })
          }
        />
      </Center>
    );
  };

  const getHeader = () => {
    const type: string = row?.data_classifications?.[0]?.service;
    switch (type) {
      case NativeResources.SimpleStorageService:
        return 'Objects';
      case NativeResources.SnowflakeDatabases:
        return 'Tables';
      case NativeResources.GitLabProject:
      case NativeResources.GitHubRepository:
      case NativeResources.BitBucketRepository:
        return 'Files';
      default:
        return 'Databases';
    }
  };

  const columns = [
    {
      header: <Box pl={0}>Name</Box>,
      align: 'left',
      render: ({ row }) => {
        return (
          <WithResourceIcon resourceType={row?.resource_type} bgColor="primary">
            <StackedCell
              upper={row?.bucket_name}
              lower={toCloudAccountId(row?.account_id)}
            />
          </WithResourceIcon>
        );
      },
    },
    {
      header: getHeader() + ' Scanned',
      align: 'left',
      render: renderObjectsScanned,
    },
    {
      header: 'Data Scanned',
      align: 'left',
      render: renderDataScanned,
    },
    {
      header: 'Last Scanned At',
      accessor: 'last_scanned',
      align: 'left',
      render: renderTimeStackedCell,
    },
    {
      header: 'Added At',
      align: 'left',
      accessor: 'created_at',
      render: renderTimeStackedCell,
    },
    ...(row?.data_classifications?.[0]?.service ===
    NativeResources.SimpleStorageService
      ? [
          {
            header: 'Scanned Extensions',
            align: 'left',
            accessor: 'file_extensions',
            render: renderFileExtensions,
          },
        ]
      : []),
    {
      header: 'Data Found',
      align: 'left',
      accessor: 'labels',
      render: renderDataFound,
    },
    {
      header: 'Actions',
      render: renderAction,
    },
  ];

  return (
    <Box h="300px">
      <Table
        data={dataClassificationSources.data ?? []}
        columns={columns}
        isLoading={dataClassificationSources.isLoading}
        onRowClick={row => handleRowClick(row, 0)}
        cursor="pointer"
      />
    </Box>
  );
};
