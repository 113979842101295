import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { Tags } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/Tags';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  Name,
  AccountID,
  CloudID,
  ARN,
  CreatedAt,
  Owner,
  Region,
  State,
} from '../../Components/MetadataField/MetaGridFields';

export const ConfigurationMetadata: FC = () => {
  const { resourceInsight, resourceDetails } = useActiveResourceContext();
  const { data: insight } = resourceInsight;
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {Name(insight)}
      {AccountID(insight)}
      {Region(insight)}
      {State(resource)}
      {CloudID(insight)}
      {ARN(insight)}
      {Tags(resource)}
      {CreatedAt(resource, 'dayjs')}
      {Owner(insight)}
    </Grid>
  );
};
