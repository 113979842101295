import {
  RemediationCommandsProps,
  ResourceHealthCheckProps,
} from 'api/inventory/types';
import {
  DeleteBlueprintParams,
  DownloadComplianceRequest,
  GetBlueprintParams,
  RemediationByAlertRuleIdParams,
} from 'api/policy-hub/types';
import { POLICY_HUB_SERVICE } from 'api/service_urls';
import { withPrefixArgs, withPrefixNoArgs } from 'api/utils';

const PREFIX = POLICY_HUB_SERVICE;
const withArgs = withPrefixArgs(PREFIX);
const withNoArgs = withPrefixNoArgs(PREFIX);

export const policyHubApis = {
  getAlertRemediationCommands: withArgs<RemediationCommandsProps>(
    `/remediation/`,
  ),
  getBlueprints: withNoArgs(`/blueprint/`),
  complianceDownload: withArgs<DownloadComplianceRequest>(
    `/compliance/report/`,
  ),
  createBlueprint: withNoArgs(`/blueprint/`),
  getBlueprint: withArgs<GetBlueprintParams>(`/blueprint/{blueprint_id}`),
  updateBlueprint: withArgs<GetBlueprintParams>(`/blueprint/{blueprint_id}`),
  deleteBlueprint: withArgs<DeleteBlueprintParams>(`/blueprint/{blueprint_id}`),
  includeRules: withArgs<GetBlueprintParams>(`/blueprint/{blueprint_id}/rule`),
  excludeRules: withArgs<GetBlueprintParams>(`/blueprint/{blueprint_id}/rule`),
  applyBaseline: withArgs<GetBlueprintParams>(
    `/blueprint/{blueprint_id}/baseline`,
  ),

  getAllRulesCategory: withNoArgs(`/rules/category`),

  getResourceHealthStatus: withArgs<ResourceHealthCheckProps>('/health/'),
  getRemediationByAlertRuleId: withArgs<RemediationByAlertRuleIdParams>(
    `/remediation/aggregate/`,
  ),

  getCheckList: withArgs<{ account_id: string }>('/checklist/'),
};
