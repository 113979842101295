import { IGroup } from '@antv/g-base/lib/interfaces';
import G6, { ModelConfig } from '@antv/g6';
import { startCase } from 'lodash';
import { customTheme } from 'theme';

import { iconSwitchWhite, resourceIconsWhite } from 'components/Icons';
import { setItemState } from 'components/Visualization/Graphs/nodes/setItemState';
import { limitedString } from 'utils/string';

export const COMBO_NODE = 'COMBO_NODE';
export const COMBO_RESOURCE_NODE = 'COMBO_RESOURCE_NODE';
export const ASSUME_ROLE = 'ASSUME_ROLE';

G6.registerNode(COMBO_NODE, {
  //@ts-ignore
  draw(cfg?: ModelConfig, group?: IGroup) {
    // const colors = customTheme.colors;
    // const primaryColor = colors['primary'];
    // const data: any = cfg?.data ?? {};

    const nodeWidth = 1000;
    const nodeHeight = 80;

    const container = group?.addShape('rect', {
      attrs: {
        // @ts-ignore
        width: cfg?.width ?? nodeWidth,
        height: nodeHeight,
        stroke: '#ddd',
        opacity: 1,
        radius: 8,
        cursor: 'pointer',
      },
      name: 'node',
    });

    return container;
  },
});

G6.registerNode(COMBO_RESOURCE_NODE, {
  setState(name, value, item) {
    setItemState(name, value, item);
  },
  //@ts-ignore
  draw(cfg?: ModelConfig, group?: IGroup) {
    const colors = customTheme.colors;
    const primaryColor = colors['primary'];
    const data: any = cfg?.data ?? {};
    const { active, shape = 'rect', strLimit = 10, severities = {} } = data;

    const nodeWidth = 26;
    const nodeHeight = 26;
    const container = group?.addShape('rect', {
      attrs: {
        width: nodeWidth,
        height: nodeHeight,
        fill: primaryColor,
        // lineWidth: 1,
        opacity: 1,
        radius: 8,
        // r: 10,
        cursor: 'pointer',
      },
      name: 'node',
    });

    const { x = 0, y = 0 } = container?.getBBox();
    const nodeGroup = group?.addGroup({
      name: 'node-group',
    });
    let img = iconSwitchWhite('IAMUser', resourceIconsWhite);
    //add image
    nodeGroup?.addShape('image', {
      attrs: {
        x: x + 3,
        y: y + 3,
        width: 20,
        height: 20,
        img: img,
      },
      name: 'img',
    });

    nodeGroup?.addShape('text', {
      attrs: {
        x: x + 34,
        y: 20,
        text: limitedString(data.cloud_account_id ?? '-', 13),
        fontSize: 12,
        opacity: 1,
        fill: '#000',
        cursor: 'pointer',
      },
      name: 'resource_name',
    });

    nodeGroup?.addShape('text', {
      attrs: {
        x: x + 34,
        y: 50,
        text: limitedString(data.cloud_account_name ?? '-', 13),
        fontSize: 12,
        opacity: 1,
        fill: '#000',
        cursor: 'pointer',
      },
      name: 'resource_name',
    });

    return container;
  },
});

const roleColorMap = {
  create: '#5b9bd5',
  read: '#70ad47',
  update: '#ed7d31',
  delete: '#c00001',
  list: '#4472c4',
  permissions: '#ffc000',
  tagging: '#00b0f0',
  credential: '#000000',
  assume_role: '#702fa0',
  failed_operation: '#c00001',
};

G6.registerNode(ASSUME_ROLE, {
  setState(name, value, item) {
    setItemState(name, value, item);
  },
  //@ts-ignore
  draw(cfg?: ModelConfig, group?: IGroup) {
    const colors = customTheme.colors;
    const primaryColor = colors['primary'];
    const data: any = cfg?.data ?? {};
    const { active, shape = 'rect', strLimit = 10, severities = {} } = data;

    const nodeWidth = 16;
    const nodeHeight = 16;
    const container = group?.addShape('rect', {
      attrs: {
        width: nodeWidth,
        height: nodeHeight,
        fill: roleColorMap[data.permissionType?.toLowerCase()] ?? primaryColor,
        // lineWidth: 1,
        opacity: 1,
        radius: 4,
        // r: 10,
        cursor: 'pointer',
      },
      name: 'node',
    });

    const { x = 0, y = 0 } = container?.getBBox();
    const nodeGroup = group?.addGroup({
      name: 'node-group',
    });

    if (data.label) {
      nodeGroup?.addShape('text', {
        attrs: {
          x: x + 30,
          y: 16,
          text: startCase(data.label),
          fontSize: 12,
          opacity: 1,
          fill: '#000',
          cursor: 'pointer',
        },
        name: 'resource_name',
      });
    }

    return container;
  },
});
