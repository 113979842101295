import React, { ReactNode } from 'react';

import {
  AiOutlineDashboard,
  AiOutlineSetting,
  AiOutlineApartment,
} from 'react-icons/ai';
import { FaHubspot } from 'react-icons/fa';
import { GrCompliance } from 'react-icons/gr';
import { RiFileListLine } from 'react-icons/ri';

export interface MainMenuProps {
  key: string;
  url: string;
  title: string | ReactNode;
  icon: ReactNode;
}

export const getMainMenuItems = () => {
  // console.log(paths);

  // const defaultUrl = root =>
  //   findPath(paths, path => {
  //     return path.indexOf(root) === 0 && filter(path);
  //   });

  return [
    {
      key: '/dashboard',
      url: '/dashboard',
      title: 'DASHBOARD',
      icon: <AiOutlineDashboard />,
    },
    {
      key: '/findings',
      url: '/findings',
      title: 'FINDINGS',
      icon: <AiOutlineApartment />,
    },
    {
      key: '/visibility',
      url: '/visibility',
      title: 'VISIBILITY',
      icon: <AiOutlineApartment />,
    },
    {
      key: '/inventory',
      url: '/inventory',
      title: 'INVENTORY',
      icon: <AiOutlineApartment />,
    },
    {
      key: '/policy-hub',
      url: '/policy-hub',
      title: 'POLICY HUB',
      icon: <FaHubspot />,
    },
    {
      key: '/compliance',
      url: '/compliance',
      title: 'COMPLIANCE',
      icon: <GrCompliance />,
    },
    {
      key: '/reports',
      url: '/reports',
      title: 'REPORTING',
      icon: <RiFileListLine />,
    },
    {
      key: '/organizations',
      url: '/organizations',
      title: 'ORGANIZATIONS',
      icon: <RiFileListLine />,
    },
    {
      key: '/setup',
      url: '/setup',
      title: 'SETUP',
      icon: <AiOutlineSetting />,
    },
  ] as Record<string, any>[];
};
