/**
 *
 * Identity And Access
 *
 */

import React, { memo, useEffect } from 'react';

import { AccountType } from '@ariksa/cloud-account';
import { CloudProviders } from '@ariksa/cloud-account/api';
import { Box, Stack, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ActionButton,
  EditIcon,
  DeleteActionButton,
  renderTime,
  WithResourceIcon,
  RediscoverIcon,
} from 'app/components';
import { CustomTable2 as Table } from 'app/components/DataDisplay/NewTable/Table';
import { renderStatus } from 'containers/Setup/CloudAccounts/utils';
import { RediscoverConfirmationModal } from 'containers/Setup/Integrations/Components/RediscoverConfirmationModal';
import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';
import { selectSharedState } from 'containers/SharedState/selectors';

import { AzureActiveDirectory } from './Components/AzureActiveDirectory';
import { MicrosoftAD } from './Components/MicrosoftAD';
import { Okta } from './Components/Okta';

export const IdentityAndAccess = memo(() => {
  const { account, rediscoverAccount } = useSelector(selectIntegrations);
  const { accounts } = useSelector(selectSetup);
  const { snapshotStatus } = useSelector(selectSharedState);
  const dispatch = useDispatch();

  const {
    isOpen,
    onEditProvider,
    onConfirmDeleteAccount,
    selectedProvider,
    onClickRediscoverInventory,
    isOpenRediscoverModal,
    getAccounts,
  } = useIntegrationsContext();

  useEffect(() => {
    getAccounts(AccountType.IdentityProvider);
  }, [getAccounts]);

  const columns = [
    {
      header: <Box pl={10}>Name</Box>,
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.cloud_type}
          iconFilled={false}
          iconSize="regular"
        >
          <Box>{row?.name}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'Created By',
      accessor: 'created_by',
      align: 'left',
    },
    {
      header: 'Created At',
      accessor: 'created_date',
      render: renderTime,
      align: 'left',
    },
    {
      header: 'Status',
      accessor: 'status.code',
      align: 'center',
      render: renderStatus,
    },
    {
      header: 'Last Snapshot',
      render: ({ row }) =>
        renderTime({ value: snapshotStatus.data?.[row.uuid]?.created_at }),
      align: 'left',
    },
    {
      header: 'Actions',
      accessor: 'actions',
      align: 'center',
      render: ({ row }) => {
        return (
          <Center>
            <ActionButton
              label={'Rediscover'}
              icon={<RediscoverIcon />}
              onClick={() => onClickRediscoverInventory(row)}
            />
            <ActionButton
              label={'Edit'}
              icon={<EditIcon />}
              onClick={() => onEditProvider(row)}
            />
            <DeleteActionButton
              type="identity provider"
              name={row?.name}
              onConfirm={() =>
                onConfirmDeleteAccount(row?.uuid, AccountType.IdentityProvider)
              }
            />
          </Center>
        );
      },
    },
  ];

  const getComponent = () => {
    let component = <Box />;
    switch (selectedProvider) {
      case 'microsoftAD':
        component = <MicrosoftAD />;
        break;
      case CloudProviders.AzureAd:
        component = <AzureActiveDirectory />;
        break;
      case CloudProviders.Okta:
        component = <Okta />;
        break;
    }
    return component;
  };

  return (
    <Stack spacing={4} h="full">
      {isOpen ? (
        getComponent()
      ) : (
        <>
          <Table
            columns={columns}
            isLoading={
              accounts.isLoading ||
              account.isLoading ||
              snapshotStatus.isLoading
            }
            data={accounts.data}
            pagination={{
              totalCount: accounts.page.totalCount,
              pageInfo: accounts.page.info,
              onChange: pageInfo => {
                dispatch(setupActions.updateAccountsPageInfo(pageInfo));
              },
            }}
          />
          {isOpenRediscoverModal && (
            <RediscoverConfirmationModal
              accountType={AccountType.IdentityProvider}
              isLoading={rediscoverAccount.isLoading}
            />
          )}
        </>
      )}
    </Stack>
  );
});
