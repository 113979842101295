export class APoint {
  x: number;
  y: number;

  static origin() {
    return new APoint(0, 0);
  }

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
