/**
 *
 * NavBarComponent
 *
 */
import React, { FC, ReactNode } from 'react';

import { Box, BoxProps, Flex, Center } from '@chakra-ui/react';
import { customTheme } from 'theme';

interface Props {
  leftComponent?: () => ReactNode;
  rightComponent?: () => ReactNode;
  mobileMenuButton?: () => ReactNode;
  logo?: ReactNode;
  height?: number | string;
  wrapperStyles?: BoxProps;
}

export const NavBarComponent: FC<Props> = props => {
  const {
    leftComponent,
    logo,
    rightComponent,
    height,
    wrapperStyles,
    //mobileMenuButton,
  } = props;
  const menuDisplay = 'block';
  //const mobileMenuDisplay = isWide ? 'none' : 'block';

  return (
    <Center pr={customTheme.sizes.bodyPadding} pl={2} {...wrapperStyles}>
      <Flex
        align="center"
        justify="space-between"
        h={height}
        //maxW="7xl"
        w="full"
        mx="auto"
        px={{ base: 3, sm: 0 }}
      >
        <Center h="full">
          {logo}
          {/*<Box display={mobileMenuDisplay}>
            {mobileMenuButton ? mobileMenuButton() : ''}
          </Box>*/}
          <Box display={menuDisplay} h="full">
            <Flex h="full">{leftComponent ? leftComponent() : ''}</Flex>
          </Box>
        </Center>
        <Box ml={[4, 6]} display={menuDisplay}>
          <Center>{rightComponent ? rightComponent() : ''}</Center>
        </Box>
      </Flex>
    </Center>
  );
};
