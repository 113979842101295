import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';

import { CreateWhitelist } from './Components/CreateWhitelist';
import { EditWhitelist } from './Components/EditWhitelist';
import { WhitelistTable } from './Components/WhitelistTable';

interface Props {}

export const Whitelist = memo((props: Props) => {
  return (
    <Routes>
      <Route element={<FilterRouter path="/inventory/allowlist/add" />}>
        <Route path="add" element={<CreateWhitelist />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/allowlist/edit/:uuid" />}>
        <Route path="edit/:uuid" element={<EditWhitelist />} />
      </Route>
      <Route element={<FilterRouter path="/inventory/allowlist/add" />}>
        <Route index element={<WhitelistTable />} />
      </Route>
    </Routes>
  );
});
