import React, { FC } from 'react';

import { DataTypeForES, Status } from '@ariksa/reporting';
import { Center, useDisclosure } from '@chakra-ui/react';

import { ExportIcon, PrimaryIconButton } from 'components/index';
import { ExportAlertsModal } from 'containers/SharedState/Components/ExportAlerts/ExportAlertsModal';

interface Props {
  type: DataTypeForES;
  alertStatus?: Status;
  tooltip: string;
  tooltipW?: string | number;
}

export const ExportAlerts: FC<Props> = props => {
  const { tooltip, tooltipW } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Center>
      <PrimaryIconButton
        aria-label="Export-alerts"
        onClick={onOpen}
        icon={<ExportIcon />}
        tooltip={tooltip}
        tooltipW={tooltipW}
      />

      <ExportAlertsModal isOpen={isOpen} onClose={onClose} {...props} />
    </Center>
  );
};
