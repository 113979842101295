import React, { FC, useEffect } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import { find, includes, map, filter } from 'lodash';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';

import { useDataClassificationWizard } from 'containers/Setup/DataConfiguration/Components/DataClassification/DataClassificationWizard/useDataClassificationWizard';
import { useDataConfigurationContext } from 'containers/Setup/DataConfiguration/context';

import { SnowflakeDataClassificationForm } from './SnowflakeDataClassificationForm';

interface Props {
  handleSubmit(value: Record<string, any>);
  handleRedirect();
}

export const SnowflakeDataClassification: FC<Props> = props => {
  const { handleSubmit, handleRedirect } = props;
  const { currentRecord, actionType } = useDataConfigurationContext();
  const { inventoryInsights } = useInventoryInsightService();

  const { state, updateState } = useDataClassificationWizard();

  //get snowflake databases
  useEffect(() => {
    !!state.selectedAccount?.value &&
      inventoryInsights.sync({
        insightV2RequestBase: {
          resource_type: NativeResources.SnowflakeDatabases,
          account_id: [state.selectedAccount?.value],
        },
      });
  }, [inventoryInsights, state.selectedAccount]);

  //set snowflake database options
  useEffect(() => {
    const options = map(inventoryInsights.data?.items, o => ({
      label: o?.name,
      value: o?.name,
      data: o,
    }));
    updateState({ databaseOptions: options });
  }, [inventoryInsights.data, updateState]);

  //set selected snowflake database
  useEffect(() => {
    if (actionType === 'Update') {
      updateState({
        selectedDatabases: filter(state.databaseOptions, o =>
          includes(
            currentRecord?.data_classifications?.[0]?.data_sources,
            o?.value,
          ),
        ),
      });
    } else updateState({ selectedDatabases: state.databaseOptions });
  }, [actionType, state.databaseOptions, currentRecord, updateState]);

  /*set selected scanner*/
  useEffect(() => {
    if (actionType === 'Update')
      updateState({
        selectedScanner: find(
          state.scannerOptions,
          o => o?.value === currentRecord?.scanner_ids?.[0],
        ),
      });
  }, [actionType, currentRecord, state.scannerOptions, updateState]);

  return (
    <SnowflakeDataClassificationForm
      handleSubmit={handleSubmit}
      handleRedirect={handleRedirect}
      updateValue={updateState}
      {...state}
    />
  );
};
