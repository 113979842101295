import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RedshiftIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M197.8,220.5c12.2-29,33.4-54.4,62.7-71.4c22.6-13,48.3-19.9,74.4-19.9c21.6,0,42.7,5,61.8,13.7V73.6
	C396.6,33,314.2,0,212.7,0C111,0,28.7,33,28.7,73.6v73.6C28.6,185.9,103.1,217.5,197.8,220.5z M212.6,18.4
	c101.1,0,165.6,32.7,165.6,55.2c0,22.5-64.5,55.2-165.6,55.2C111.5,128.8,47,96.1,47,73.6C47,51.1,111.5,18.4,212.6,18.4z
	 M28.6,257.6V184c0,37.3,69.2,67.9,159,72.8c-3.6,24.5-1.1,49.9,8.1,74C102,327.4,28.6,296,28.6,257.6z M324,426.5
	c-30.9,9.4-69.4,15.1-111.3,15.1c-101.6,0-184-33-184-73.6v-73.6c0,40.7,82.3,73.6,184,73.6c1.2,0,2.3-0.1,3.4-0.1
	C241.8,401.9,281.2,423.2,324,426.5z M445.7,214.2c-35.3-61.1-113.6-82.1-174.7-46.8C210,202.7,189,281,224.2,342.1
	c35.3,61.1,113.7,82.1,174.7,46.8C460,353.7,481,275.3,445.7,214.2z M242.6,331.5c-29.4-50.9-11.9-116.3,39.1-145.8
	c51-29.4,116.4-11.9,145.8,39.1c29.4,51,11.9,116.4-39.1,145.8C337.4,400.1,272,382.5,242.6,331.5z M473.4,454.4l-55,31.8
	l-42.4-73.3l55-31.8L473.4,454.4z M467.5,507.7c-15.2,8.8-34.6,3.6-43.4-11.6l55.1-31.8C487.9,479.6,482.7,499,467.5,507.7z"
    />
  </Icon>
);
