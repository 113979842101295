/**
 *
 * PolicyHub
 *
 */

import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { AlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/Loadable';
import { Blueprints } from 'containers/PolicyHub/Blueprints/Loadable';
import { blueprintsSaga } from 'containers/PolicyHub/Blueprints/saga';
import * as blueprintsSlice from 'containers/PolicyHub/Blueprints/slice';
import { CreatePolicy } from 'containers/PolicyHub/Policies/CreatePolicy/CreatePolicy';
import { UpdatePolicy } from 'containers/PolicyHub/Policies/CreatePolicy/UpdatePolicy';
import { Policies } from 'containers/PolicyHub/Policies/Policies';
import { Remediation } from 'containers/PolicyHub/Remediation/Loadable';

import { useInjector } from '../../../utils/inject';

import { policyHubSaga } from './saga';
import { reducer, sliceKey } from './slice';
import './styles.css';

export const PolicyHub = memo(() => {
  useInjector(sliceKey, reducer, policyHubSaga);
  useInjector(
    blueprintsSlice.sliceKey,
    blueprintsSlice.reducer,
    blueprintsSaga,
  );

  return (
    <Routes>
      <Route path="policy/add" element={<CreatePolicy />} />
      <Route path="policy/edit/:policy_id" element={<UpdatePolicy />} />
      <Route path="policy" element={<Policies />} />
      <Route path="remediation/*" element={<Remediation />} />
      <Route path="workflow/*" element={<AlertWorkflow />} />
      <Route path="blueprint/*" element={<Blueprints />} />
      <Route path="*" element={<Blueprints />} />
    </Routes>
  );
});
