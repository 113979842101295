import React, { useState, FC, useEffect } from 'react';

import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Tag } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { tagStyles } from 'containers/PolicyHub/AlertWorkflow/styles';

import { useEnvironmentContext } from '../../../../context';
import { selectEnvironment } from '../../../../selectors';
import { quantifierOptions } from '../../../../utils';

interface Props {}

export const CriteriaQuantifier: FC<Props> = props => {
  const { formData } = useSelector(selectEnvironment);
  const { criteria } = formData;
  const [quantifier, setQuantifier] = useState<Record<string, any>>({});
  const { updateCriteria } = useEnvironmentContext();

  useEffect(() => {
    setQuantifier(
      quantifierOptions.find(o => o.value === criteria?.criteria_condition) ||
        quantifierOptions[0],
    );
  }, [criteria?.criteria_condition]);

  return (
    <Wrap align="center">
      <WrapItem>
        <Tag label="IF" styles={tagStyles} />
      </WrapItem>
      <WrapItem>
        <Box w={24}>
          <Select
            options={quantifierOptions}
            value={quantifier}
            onChange={selected =>
              updateCriteria('criteria_condition', selected.value)
            }
          />
        </Box>
      </WrapItem>
      <WrapItem>
        <Text>
          of the following conditions in the environment are satisfied
        </Text>
      </WrapItem>
    </Wrap>
  );
};
