import React, { FC, useEffect, useState } from 'react';

import { Entities } from '@ariksa/notification';
import { Box, Stack } from '@chakra-ui/react';
import { filter, includes } from 'lodash';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';

import { getResourceTypeOptions } from 'components/DataDisplay';
import { Select } from 'components/DataEntry';
import { selectApp } from 'containers/App/selectors';
import { selectAlertWorkflow } from 'containers/PolicyHub/AlertWorkflow/selectors';

import { actions } from '../../../../slice';

export const IdentityTypes: FC = () => {
  const { resourceTypes } = useSelector(selectApp);
  const { alertConditions } = useSelector(selectAlertWorkflow);
  const [categories, setCategories] = useState<Record<string, any>[]>([]);
  const [selected, setSelected] = useState<Record<string, any>[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setCategories(getResourceTypeOptions(resourceTypes.data));
  }, [resourceTypes]);

  useEffect(() => {
    setSelected(
      filter(categories, o =>
        includes(alertConditions[Entities.Resource]?.entity_list, o.value),
      ),
    );
  }, [categories, alertConditions]);

  return (
    <Stack w="full" isInline>
      <Box whiteSpace="nowrap" pt={1}>
        If alert or policy is for entity type
      </Box>
      <Box w="full">
        <Select
          options={categories}
          isMulti
          value={selected}
          onChange={selectedOptions =>
            dispatch(
              actions.updateAlertWorkflowConditionByID({
                name: Entities.Resource,
                entity_list: map(selectedOptions, each => each.value),
              }),
            )
          }
        />
      </Box>
    </Stack>
  );
};
