import React, { FC } from 'react';

import { Stack, HStack, Text } from '@chakra-ui/react';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';

export const AutomaticOnboardingHelp: FC = () => {
  return (
    <Stack px={2} spacing={6} h="full">
      <HStack align={'start'}>
        <HelpCount count={1} />
        <Text>You’ll be redirected to AWS console for login</Text>
      </HStack>
      <HStack align={'start'}>
        <HelpCount count={2} />
        <Text>
          Please review and approve AWS stack set creation for your individual
          or management account
        </Text>
      </HStack>
      <Stack>
        <HStack align={'start'}>
          <HelpCount count={3} />
          <Text>
            For standalone accounts, you don’t need to any further inputs before
            stack creation.
          </Text>
        </HStack>
        <Text pl={8}>
          For management accounts, specify root ID (starting with r-) or OU ID
          (starting with ou-) to onboard
        </Text>
      </Stack>
      <HStack align={'start'}>
        <HelpCount count={4} />
        <Text>
          Ariksa will automatically onboard management and all accounts under
          root or OU specified by you
        </Text>
      </HStack>
    </Stack>
  );
};
