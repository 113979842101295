import React from 'react';

import { Icon } from '@chakra-ui/react';

export const AzureADDomainIcon = props => (
  <Icon viewBox="0 0 18 18" w="full" h="full" color="inherit" {...props}>
    <defs>
      <linearGradient
        id="f92ae47d-0835-4bac-a4f5-649f41e19a24"
        x1="13.25"
        y1="13.06"
        x2="8.62"
        y2="4.29"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#1988d9" />
        <stop offset="0.9" stopColor="#54aef0" />
      </linearGradient>
      <linearGradient
        id="f418fd20-54c9-41f7-a889-8b791ff60efa"
        x1="11.26"
        y1="10.51"
        x2="14.46"
        y2="16.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="#54aef0" />
        <stop offset="0.29" stopColor="#4fabee" />
        <stop offset="0.51" stopColor="#41a2e9" />
        <stop offset="0.74" stopColor="#2a93e0" />
        <stop offset="0.88" stopColor="#1988d9" />
      </linearGradient>
    </defs>
    <title>Icon-identity-222</title>
    <polygon
      points="1.01 10.23 8.93 15.37 16.99 10.21 18 11.38 8.93 17.23 0 11.39 1.01 10.23"
      fill="#50e6ff"
    />
    <polygon
      points="1.61 9.57 8.93 0.85 16.4 9.58 8.93 14.3 1.61 9.57"
      fill="#fff"
    />
    <polygon points="8.93 0.85 8.93 14.3 1.61 9.57 8.93 0.85" fill="#50e6ff" />
    <polygon
      points="8.93 0.85 8.93 14.3 16.4 9.58 8.93 0.85"
      fill="url(#f92ae47d-0835-4bac-a4f5-649f41e19a24)"
    />
    <polygon points="8.93 7.8 16.4 9.58 8.93 14.3 8.93 7.8" fill="#53b1e0" />
    <polygon points="8.93 14.3 1.61 9.57 8.93 7.8 8.93 14.3" fill="#9cebff" />
    <polygon
      points="8.93 17.23 18 11.38 16.99 10.21 8.93 15.37 8.93 17.23"
      fill="url(#f418fd20-54c9-41f7-a889-8b791ff60efa)"
    />
    <polygon
      points="13.53 9.04 9.06 15.3 13.53 17.12 18 15.3 13.53 9.04"
      fill="#773adc"
    />
    <polygon
      points="13.53 9.04 9.06 15.3 13.53 17.12 13.53 9.04"
      fill="#f9f9f9"
      opacity="0.5"
    />
  </Icon>
);
