import React, { ReactNode } from 'react';

import { Box, Center, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { values } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { components } from 'react-select';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';

import {
  ActionButton,
  CheckmarkIcon,
  EditIcon,
  getIcon,
  SearchIcon,
  Tag,
} from 'app/components';
import { EnableButton } from 'components/DataEntry/Button/EnableButton';
import { CloseIcon } from 'components/Icons';
import { TableAlerts } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/TableAlerts';
import { DeleteOrganization } from 'containers/Organizations/Components/DeleteOrganization';

import { colorMap } from '../../../theme';

export const CustomOption = props => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <Stack _hover={{ cursor: 'pointer' }}>
        <HStack>
          <HStack flex={1}>
            <Center p={1} w={6} h={6} color="primary">
              {getIcon(
                data.value?.native_resource || data.value?.agnostic_resource,
              )}
            </Center>
            <Text>{data.label ?? '-'}</Text>
          </HStack>
          <Flex
            px={3}
            bg={
              data.value?.is_custom_policy ? 'green.300' : colorMap.primary(300)
            }
            borderRadius={4}
            color="white"
            opacity={0.7}
            fontSize="xs"
            w="70px"
          >
            <Center w="full">
              {data.value?.is_custom_policy ? 'Custom' : 'Ariksa'}
            </Center>
          </Flex>
        </HStack>
        {/*<Text px={8} color={'#aaa'}>
          {data.value.query ?? '-'}
        </Text>*/}
      </Stack>
    </components.Option>
  );
};

export const CustomValueContainer = (props: ValueContainerProps) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <HStack>
          <Box color={'gray'}>
            <SearchIcon />
          </Box>
          {props.children}
        </HStack>
      </components.ValueContainer>
    )
  );
};

export const renderResourceAlerts = (
  severities,
  uuid,
  resourceType = '',
  resourceId = '',
) => {
  if (isEmpty(severities)) return '-';

  return (
    <>
      <TableAlerts
        severities={severities}
        uuid={uuid}
        resourceType={resourceType}
        resourceId={resourceId}
      />
    </>
  );
};

export const renderOrganizationActions = ({
  row,
  onEnableChange,
  onEdit,
  onDelete,
}) => (
  <Stack isInline spacing={0} justifyContent="center">
    <EnableButton
      label={row.enabled ? 'Disable' : 'Enable'}
      icon={row.enabled ? <CloseIcon /> : <CheckmarkIcon />}
      onConfirm={onEnableChange}
      isDisabled={row.name === 'master'}
    />

    <ActionButton
      label="Edit"
      icon={<EditIcon />}
      onClick={onEdit}
      isDisabled={row.name === 'master'}
    />

    <DeleteOrganization onDelete={onDelete} row={row} />
  </Stack>
);

export const renderOrganizationStatus = ({ value }) => (
  <Tag label={value ? 'Enabled' : 'Disabled'} styles={{ tag: { m: 0 } }} />
);

export const formatTooltipBoolean = (label, value) => {
  let display = value ? 'Yes' : 'No';
  if (value === undefined) {
    display = 'No data available';
  }

  return (
    <Box>
      {label}: {display}
    </Box>
  );
};

export const profileIcon = (icon: ReactNode, name: any) => (
  <Stack isInline>
    {/*<Center*/}
    {/*  size={4}*/}
    {/*  border="1px solid"*/}
    {/*  borderColor="blue.100"*/}
    {/*  borderRadius="full"*/}
    {/*  color="primary"*/}
    {/*  p={1}*/}
    {/*>*/}
    {/*  {icon}*/}
    {/*</Center>*/}
    <Box>{name ? truncate(name, { length: 15, omission: '...' }) : '-'}</Box>
  </Stack>
);

export const isAllFieldsSet = (object: Record<string, any>) =>
  values(object).every(v => v !== undefined && v.toString() !== '');

/*export const sliceWidth = (arr, start, end = arr.length) =>
  sliceSum(arr, start, end) + 'px';*/

/*export const sliceSum = (arr, start, end) =>
  sum(arr.slice(start, end).map(parseFloat));*/
