import React, {
  BaseSyntheticEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  FormControl,
  FormErrorMessage,
  Textarea,
  FormHelperText,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useErrorMessage } from '../index';
import { useStyles } from '../index';
import { FieldProps, TextAreaFieldSchema, TextAreaFieldStyles } from '../types';

import { FormLabel } from './FormLabel';

export const TextAreaField: FC<FieldProps<TextAreaFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    placeholder,
    helpIcon,
    helperText,
    isRequired,
    shouldDisplay,
    isDisabled,
    value,
    styles = {},
    helpTooltip,
    onClickHelpIcon,
    onBlur,
    onChange,
    defaultValue,
  } = field;

  const fieldStyles = useStyles<TextAreaFieldStyles>('textAreaField', styles);

  const { register, watch, setValue } = useFormContext();

  const errorMessage = useErrorMessage(name, label);

  const values = watch({ nest: true });

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnChange = useCallback(
    (e: BaseSyntheticEvent) => {
      onChange?.(e.target.value, e);
    },
    [onChange],
  );

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [name, setValue, value]);

  const textInputProps = useCallback(
    () => ({
      onChange: handleOnChange,
      onBlur: e => {
        onBlur?.(e.target.value);
      },
    }),
    [onBlur, handleOnChange],
  );

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}
      <Textarea
        id={id}
        data-testid={id}
        name={name}
        placeholder={placeholder}
        isDisabled={isDisabled}
        ref={register}
        defaultValue={defaultValue}
        {...fieldStyles.textArea}
        {...textInputProps()}
      />
      {!!helperText && (
        <FormHelperText {...fieldStyles.helperText}>
          {helperText}
        </FormHelperText>
      )}
      <FormErrorMessage {...fieldStyles.errorMessage}>
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  ) : null;
};
