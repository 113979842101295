import React, { useEffect } from 'react';

import { Center, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterIcon, Form, Modal, PrimaryIconButton } from 'app/components';
import { selectEnvironmentOptions } from 'containers/App/selectors';
import { selectEventLog } from 'containers/EventLog/selectors';
import { actions } from 'containers/EventLog/slice';
import { setupUsersSaga } from 'containers/Setup/Users/saga';
import {
  selectSetupUsers,
  selectUsersOptions,
} from 'containers/Setup/Users/selectors';
import * as userSlice from 'containers/Setup/Users/slice';
import { actions as userActions } from 'containers/Setup/Users/slice';
import { useInjector } from 'utils/inject';

export const EventLogFilters = () => {
  useInjector(userSlice.sliceKey, userSlice.reducer, setupUsersSaga);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { eventLogs, eventLogFilters } = useSelector(selectEventLog);
  const { users } = useSelector(selectSetupUsers);
  const userOptions = useSelector(selectUsersOptions);
  const envOptions = useSelector(selectEnvironmentOptions);

  useEffect(() => {
    dispatch(userActions.loadUsers());
  }, [dispatch]);
  const handleSubmit = data => {
    dispatch(
      actions.updateFilters({
        cloud: data.cloud,
        user: data.user,
        environment: data.environment,
      }),
    );

    onClose();
  };

  const handleReset = () => {
    dispatch(
      actions.updateFilters({
        cloud: null,
        user: null,
        environment: null,
      }),
    );
  };

  return (
    <Center>
      <PrimaryIconButton
        aria-label="filters"
        onClick={onOpen}
        icon={<FilterIcon />}
        isDisabled={eventLogs.isLoading}
        tooltip="Filters"
      ></PrimaryIconButton>

      {/*Filter*/}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        styles={{ content: { zIndex: 10000 } }}
        header={'Alert Filters'}
        body={
          <Form
            schema={{
              cloud: {
                type: 'react-select',
                label: 'Cloud',
                options: [{ value: 'AWS', label: 'AWS' }],
                value: eventLogFilters.cloud ?? {},
              },
              user: {
                type: 'react-select',
                label: 'Action by',
                isLoading: users.loading,
                options: userOptions,
                value: eventLogFilters.user ?? {},
              },
              /*environment: {
                type: 'react-select',
                label: 'Environment',
                options: envOptions,
                value: eventLogFilters.environment ?? {},
              },*/
            }}
            buttonOptions={{
              submit: { name: 'Apply' },
              reset: { isVisible: true, name: 'Reset', onClick: handleReset },
            }}
            handleSubmit={handleSubmit}
          />
        }
      />
    </Center>
  );
};
