import React, { FC, ReactNode } from 'react';

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  HStack,
  useDisclosure,
  Stack,
  Center,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/all';
import { FiChevronDown } from 'react-icons/fi';

import { getIcon } from 'components/DataDisplay';
import { PrimaryIconButton } from 'components/DataEntry';
import { GraphIcon } from 'components/Icons';
import { IconTypes } from 'components/Icons/types';
import { Drawer } from 'components/Overlay';
import { MetadataHeader } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataHeader';
import { metaAccordionStyles } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/styles';

import {
  MetaHeaderWithIcon,
  MetaHeaderWithIconProps,
} from './MetaHeaderWithIcon';

export interface MetaAccordionItemProps extends MetaHeaderWithIconProps {
  panel: React.ReactNode;
  expandedPanel?: React.ReactNode;
  onClickDownload?: () => void;
  expandOnClickViewAll?: boolean;

  onExpand?();

  onClose?();

  onShowGraph?();

  expandIconTooltip?: string;
  rightHeader?: (isExpanded) => ReactNode;
  header?: ReactNode;
  height?: any;
}

export const MetaAccordionItem: FC<MetaAccordionItemProps> = props => {
  const expandedView = useDisclosure();
  const {
    onClickDownload,
    panel,
    expandedPanel = panel,
    expandOnClickViewAll = true,
    onExpand,
    onClose,
    rightHeader,
    height,
    expandIconTooltip = 'View all',
    onShowGraph,
    ...rest
  } = props;

  const handleOnClose = () => {
    expandedView.onClose();
    onClose?.();
  };

  return (
    <>
      <AccordionItem {...metaAccordionStyles.item}>
        {({ isExpanded }) => (
          <Box {...metaAccordionStyles.innerWrapper}>
            <AccordionButton {...metaAccordionStyles.button}>
              <HStack justifyContent="space-between" w="full">
                <Box flex={1}>
                  <MetaHeaderWithIcon {...rest} />
                </Box>
                <HStack>
                  <Box>{rightHeader?.(isExpanded)}</Box>
                  <PrimaryIconButton
                    aria-label="view all"
                    tooltip={expandIconTooltip}
                    p={2}
                    onClick={e => {
                      e.stopPropagation();
                      expandedView.onOpen();
                      onExpand?.();
                    }}
                  >
                    {getIcon(IconTypes.ViewAll)}
                  </PrimaryIconButton>
                  {onShowGraph && (
                    <PrimaryIconButton
                      aria-label="show access graph"
                      tooltip={'Show access graph'}
                      p={2}
                      onClick={e => {
                        e.stopPropagation();
                        onShowGraph?.();
                      }}
                      icon={<GraphIcon />}
                    ></PrimaryIconButton>
                  )}
                </HStack>
                <Center>
                  {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
                </Center>
              </HStack>
            </AccordionButton>
            {isExpanded && (
              <AccordionPanel mt={2} h={height ?? '510px'}>
                {panel}
              </AccordionPanel>
            )}
          </Box>
        )}
      </AccordionItem>
      <Drawer
        isOpen={expandedView.isOpen}
        onClose={handleOnClose}
        header={<MetadataHeader />}
        body={
          <Stack h="full" spacing={5} pt={3}>
            <MetaHeaderWithIcon {...rest} />
            <Box flex={1} h="full">
              {expandedPanel}
            </Box>
          </Stack>
        }
        closeButton
        styles={{ content: { maxW: expandOnClickViewAll ? 'full' : '65%' } }}
      />
    </>
  );
};
