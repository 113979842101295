import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IdentityProviderName } from 'api/auth/api.pb';
import { Form } from 'components/DataEntry';
import { selectUser } from 'containers/App/selectors';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';
import { actions } from 'containers/Setup/Integrations/slice';
import { formStyles } from 'containers/Setup/Integrations/styles';

interface ISSOAddProvider {}

export const SSOAddProvider: FC<ISSOAddProvider> = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { info } = useSelector(selectUser);
  const { sso } = useSelector(selectIntegrations);
  const { providerNames, providerForm } = sso;
  const { createProvider, formData } = providerForm;

  const [url] = useState(new URL(window.location.origin));

  useEffect(() => {
    dispatch(actions.getProviderNames());
    return () => {
      dispatch(actions.updateProviderForm({ providerConfig: {} }));
    };
  }, [dispatch]);

  const onCancel = () => {
    dispatch(actions.updateProviderForm({ providerConfig: {} }));
    navigate('/setup/integrations');
  };

  useEffect(() => {}, []);

  const handleSubmit = data => {
    const {
      client_id = '',
      client_secret = '',
      config_uri = '',
      tenant_id = '',
    } = data;
    const payload = {
      provider: formData.providerConfig.provider,
      config: { client_id, client_secret, config_uri: '', tenant_id },
      organization: info.org_id,
      enabled: true,
      onRedirect: onCancel,
    };

    if (formData.providerConfig.provider === 'Okta') {
      payload.config.config_uri = config_uri;
    }

    dispatch(actions.createSSOProvider(payload));
  };

  return (
    <Form
      isLoading={providerNames.loading}
      schema={{
        provider: {
          type: 'react-select',
          label: 'Provider Name',
          options: providerNames.data
            // .filter(p => p.provider !== IdentityProviderName.Okta)
            .map(p => ({
              label: p.provider,
              value: p,
            })),
          value: {
            label: formData.providerConfig.provider,
            value: formData.providerConfig,
          },
          onChange: o =>
            dispatch(actions.updateProviderForm({ providerConfig: o.value })),
          isRequired: true,
        },
        signInRedirectURL: {
          type: 'text',
          label: 'Sign-in Redirect URI',
          value: `${url.protocol}//${url.host}${formData?.providerConfig?.redirect_uri}`,
          isDisabled: true,
          showCopy: true,
        },

        ...(formData.providerConfig.provider === IdentityProviderName.Okta
          ? {
              signOutRedirectURL: {
                type: 'text',
                label: 'Sign-out Redirect URI',
                value: `${url.protocol}//${url.host}${formData?.providerConfig?.redirect_uri}/logout_response`,
                isDisabled: true,
                showCopy: true,
              },
              config_uri: {
                type: 'text',
                label: 'Config URI',
                isRequired: true,
              },
            }
          : null),
        client_id: {
          type: 'text',
          label: 'Client ID',
          isRequired: true,
        },
        client_secret: {
          type: 'text',
          label: 'Client Secret',
          isRequired: true,
          htmlInputType: 'password',
          passwordShow: true,
        },
        ...(formData.providerConfig.provider === IdentityProviderName.Microsoft
          ? {
              tenant_id: {
                type: 'text',
                label: 'Tenant ID',
              },
            }
          : null),
      }}
      showHelp
      styles={formStyles}
      buttonOptions={{
        submit: {
          name: 'Enable',
          isLoading: createProvider?.loading,
          isDisabled: createProvider?.loading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: createProvider?.loading,
          onClick: onCancel,
        },
      }}
      handleSubmit={handleSubmit}
    />
  );
};
