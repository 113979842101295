import React from 'react';

import {
  Box,
  Stack,
  OrderedList,
  ListItem,
  Image,
  Center,
  Button,
} from '@chakra-ui/react';

import { CloseIcon, grayIconButtonStyles } from 'app/components';
import five from 'app/images/Teams/five.png';
import four from 'app/images/Teams/four.png';
import one from 'app/images/Teams/one.png';
import seven from 'app/images/Teams/seven.png';
import six from 'app/images/Teams/six.png';
import three from 'app/images/Teams/three.png';
import two from 'app/images/Teams/two.png';

interface Props {
  onClose: () => void;
}

export const TeamsHelp = (props: Props) => {
  const { onClose } = props;
  return (
    <Box>
      <Stack>
        <Stack isInline justify="space-between">
          <Box fontSize="lg" fontWeight="md">
            Obtaining an incoming webhook of Teams channel
          </Box>
          <Button {...grayIconButtonStyles} onClick={onClose}>
            <CloseIcon />
          </Button>
        </Stack>
      </Stack>
      <OrderedList spacing={2} pl={1}>
        <ListItem>
          Open MS Teams and click on Teams in the left navigation menu.
          <Center>
            <Image src={one} mt={4} h={64} />
          </Center>
        </ListItem>
        <ListItem>
          Navigate to the channel where you want to add the webhook andselect
          (•••) More Options from the top navigation bar.
          <Center>
            <Image src={two} mt={2} h={24} />
          </Center>
        </ListItem>
        <ListItem>
          Choose Connectors from the drop-down menu and search for Incoming
          Webhook.
          <Center>
            <Image src={three} mt={2} h={64} />
          </Center>
          <Center>
            <Image src={four} mt={4} />
          </Center>
        </ListItem>
        <ListItem>
          Select the Configure button, provide a name, and, optionally,upload an
          image avatar for your webhook.
          <Center>
            <Image src={five} mt={2} />
          </Center>
          <Center>
            <Image src={six} mt={4} />
          </Center>
        </ListItem>
        <ListItem>
          Click on create.
          <Center>
            <Image src={seven} mt={4} />
          </Center>
        </ListItem>
        <ListItem>
          The dialog window will present a unique URL that will map to the
          channel. Copy and save the URL.
        </ListItem>
        <ListItem>
          Select the Done button. The webhook will be available in the team
          channel.
        </ListItem>
      </OrderedList>
    </Box>
  );
};
