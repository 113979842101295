import React, { FC, useEffect, useState } from 'react';

import { RiskRatingSeverity } from '@ariksa/compliance-policies/api';
import { Box, HStack, Stack, Flex } from '@chakra-ui/react';
import { map } from 'lodash';
import { Radar } from 'recharts';
import { customTheme } from 'theme';

import { RadarChart } from 'components/Visualization';

interface Props {
  data: Record<string, any>;
  isLoading?: boolean;
}

export const S3RiskAnalysisRadarChart: FC<Props> = props => {
  const { data, isLoading } = props;
  const [riskAnalysis, setRiskAnalysis] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const getRiskRating = type => {
      switch (type) {
        case RiskRatingSeverity.HighRisk:
          return 4;
        case RiskRatingSeverity.MediumRisk:
          return 3;
        case RiskRatingSeverity.LowRisk:
          return 2;
      }
      return 1;
    };
    setRiskAnalysis(
      map(data?.risk_ratings, o => ({
        ...o,
        Rating: getRiskRating(o?.risk_rating),
      })),
    );
  }, [data]);

  const getRatingColor = type => {
    switch (type) {
      case RiskRatingSeverity.HighRisk:
      case 4:
        return customTheme.colors.critical;
      case RiskRatingSeverity.MediumRisk:
      case 3:
        return customTheme.colors.medium;
      case RiskRatingSeverity.LowRisk:
      case 2:
        return customTheme.colors.green['300'];
      default:
        return customTheme.colors.gray['100'];
    }
  };

  const CustomizedDot = props => {
    const { cx, cy, payload } = props;

    return (
      <svg x={cx - 5} y={cy - 5} width={10} height={10} viewBox="0 0 100 100">
        <circle cx={50} cy={50} r={35} fill={getRatingColor(payload.value)} />
      </svg>
    );
  };

  return (
    <HStack w="full" h="full" align="start">
      <Flex w="80%" h="full" maxH="320px" alignItems="flex-start" pt={4}>
        <RadarChart
          data={riskAnalysis}
          angleAxis="name"
          domain={[0, 4]}
          radar={
            <Radar
              dataKey={'Rating'}
              fill="none"
              stroke={customTheme.colors.primary}
              dot={<CustomizedDot />}
            />
          }
          legend={false}
          styles={{
            grid: {
              radialLines: false,
            },
            radiusAxis: {
              tickCount: 5,
              tick: false,
              axisLine: false,
              orientation: 'left',
            },
            tooltip: {
              formatter: (value, name, entry) => {
                let risk = 'NA';
                if (value === 2) risk = 'Low Risk';
                else if (value === 3) risk = 'Medium Risk';
                else if (value === 4) risk = 'High Risk';
                return <text color={getRatingColor(value)}>{risk}</text>;
              },
            },
          }}
        />
      </Flex>
      <Stack
        align="flex-start"
        pr={6}
        pt={6}
        spacing={1}
        w="110px"
        right={0}
        position="relative"
      >
        {map(RiskRatingSeverity, o => (
          <HStack fontSize="xs">
            <Box boxSize={1.5} bg={getRatingColor(o)} borderRadius="full" />
            <Box>{o}</Box>
          </HStack>
        ))}
      </Stack>
    </HStack>
  );
};
