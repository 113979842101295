import React, { FC } from 'react';

import { Grid } from '@chakra-ui/react';

import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import {
  DHCPOptionID,
  IPv4CIDRBlock,
  IPv4CIDRBlockState,
  VPCDefault,
} from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

export const VPCMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {VPCDefault(resource)}
      {IPv4CIDRBlock(resource)}
      {IPv4CIDRBlockState(resource)}
      {DHCPOptionID(resource)}
    </Grid>
  );
};
