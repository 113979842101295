/**
 *
 * Add IP Whitelist
 *
 */

import React, { memo } from 'react';

import { useNavigate } from 'react-router-dom';

import { Form } from 'app/components/DataEntry/Form';
import { Card } from 'components/DataDisplay/Card';
import { Layout } from 'components/Layout';

interface Props {}

export const IPWhitelistingWizard = memo((props: Props) => {
  const navigate = useNavigate();
  const handleReset = () => {
    navigate('/setup/ip-whitelisting');
  };
  const handleSubmit = data => {};
  return (
    <Layout
      middleComponent={
        <Card
          styles={{ card: { pl: 32 } }}
          contentComponent={
            <Form
              title="Add New IP-Whitelist"
              schema={{
                name: {
                  type: 'text',
                  label: 'Name',
                },
                cidr_ip_address: {
                  type: 'text',
                  label: 'CIDR or IP Address',
                },
                ports: {
                  type: 'text',
                  label: 'Ports (Comma Separated)',
                },
              }}
              styles={{
                form: { container: { pl: 14 } },
              }}
              buttonOptions={{
                submit: {
                  name: 'Okay',
                },
                reset: {
                  name: 'Cancel',
                  isVisible: true,
                  onClick: handleReset,
                },
              }}
              handleSubmit={handleSubmit}
            />
          }
        />
      }
    />
  );
});
