import React, { FC, ReactNode } from 'react';

import { Box, HStack } from '@chakra-ui/react';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { IconType } from 'components/Icons';
import { HeaderTable } from 'containers/Inventory/CloudInventory/Components/HeaderTable';

interface Props {
  title: ReactNode | string;
  value: ReactNode;
  iconType: IconType;
  onOpenAddWhitelist?: () => void;
}

export const InventoryTableTitle: FC<Props> = props => {
  const { title, value, iconType, onOpenAddWhitelist } = props;
  return (
    <HStack w="full" spacing={6} justify="space-between">
      <Box w="full">
        <PageHeaderWithIcon
          label={
            <HStack>
              <Box>{title}:</Box>
              <Box fontWeight={400} color="gray.250">
                {value}
              </Box>
            </HStack>
          }
          iconType={iconType}
          reversed
        />
      </Box>
      <HeaderTable onOpenAddWhitelist={onOpenAddWhitelist} />
    </HStack>
  );
};
