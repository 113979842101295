/**
 *
 * Setup Groups
 *
 */

import React, { memo } from 'react';

import { Route, Routes } from 'react-router-dom';

import { FilterRouter } from 'app/components';
import { GroupsTable } from 'containers/Setup/Groups/GroupsTable';
import { CreateGroup } from 'containers/Setup/Groups/GroupsWizard/CreateGroup';
import { EditGroup } from 'containers/Setup/Groups/GroupsWizard/EditGroup';
import { ViewGroup } from 'containers/Setup/Groups/GroupsWizard/ViewGroup';

export const Groups = memo(() => {
  return (
    <Routes>
      <Route element={<FilterRouter path="/setup/groups/edit/:group_id" />}>
        <Route path="edit/:group_id" element={<EditGroup />} />
      </Route>
      <Route element={<FilterRouter path="/setup/groups/view/:group_id" />}>
        <Route path="view/:group_id" element={<ViewGroup />} />
      </Route>
      <Route element={<FilterRouter path="/setup/groups/add" />}>
        <Route path="add" element={<CreateGroup />} />
      </Route>
      <Route element={<FilterRouter path="/setup/groups" />}>
        <Route index element={<GroupsTable />} />
      </Route>
    </Routes>
  );
});
