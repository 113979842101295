import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIconColor } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { UnencryptedDiskIcon } from 'components/Icons';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import {
  AllEntitiesDetailsProps,
  SearchQueryMapping,
} from 'containers/Dashboard/types';
import { getSearchQuery } from 'containers/Dashboard/utils';
import { CountCards } from 'containers/Dashboard/utils/CountCards';

export const InfrastructureThatLacksEncryption: React.FC = () => {
  const { infrastructureThatLacksEncryption } = useSelector(
    selectCloudPostureOverview,
  );
  const navigate = useNavigate();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <CountCards
        isLoading={infrastructureThatLacksEncryption.isLoading}
        label="Top entities lacking encryption at rest or in motion"
        icon={<UnencryptedDiskIcon />}
        iconBgColor="critical"
        items={map(infrastructureThatLacksEncryption.data.slice(0, 4), o => ({
          label: o.agnostic_name!,
          iconType: o.agnostic_class!,
          count: formatNumber(o?.resource_count),
          onClick: () => {
            allEntitiesDisclosure.onOpen();
            setDetails({
              panel: PanelsEnum.UnencryptedInfraEntities,
              iconType: o.agnostic_class!,
              resourceType: o.agnostic_class,
              resourceTypeClass: 'agnostic',
              total: o?.resource_count ?? 0,
            });
          },
        }))}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
