import React, { FC, useEffect, useState } from 'react';

import {
  TagCondition,
  TagKeyValuesCondition,
} from '@ariksa/compliance-policies';
import { Box, Button, Stack } from '@chakra-ui/react';
import { omit, isEmpty, map } from 'lodash';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton } from 'components/DataEntry';
import { TrashIcon } from 'components/Icons';
import { deleteIconStyles } from 'containers/PolicyHub/AlertWorkflow/styles';
import { useEnvironmentContext } from 'containers/Setup/Environment/context';
import { selectEnvironment } from 'containers/Setup/Environment/selectors';
import { actions } from 'containers/Setup/Environment/slice';

import { TagKeyValueComponent } from './TagKeyValueComponent';

export const TagKeyValueCriteria: FC = () => {
  const { tagKeyValueCriteria } = useSelector(selectEnvironment);
  const { tagKeyOptions } = useEnvironmentContext();
  const [criteria, setCriteria] = useState<
    Record<string, TagKeyValuesCondition>
  >({});
  const dispatch = useDispatch();

  useEffect(() => {
    setCriteria(tagKeyValueCriteria);
    !isEmpty(tagKeyValueCriteria) &&
      dispatch(
        actions.updateCriteria({
          field: 'tag_key_values_condition',
          value: map(tagKeyValueCriteria, o => o),
        }),
      );
  }, [tagKeyValueCriteria, dispatch]);

  return (
    <Stack spacing={4}>
      <Box>
        <PrimaryButton
          onClick={() => {
            const id = nanoid();
            dispatch(
              actions.updateKeyValueCriteria({
                id,
                value: {
                  key: tagKeyOptions[0].value,
                  key_condition: TagCondition.Is,
                  value: [],
                  value_condition: TagCondition.Is,
                },
              }),
            );
          }}
          size="xs"
        >
          + Add
        </PrimaryButton>
      </Box>
      {map(Object.keys(criteria).reverse(), id => (
        <Box
          border="1px solid"
          py={2}
          pl={2}
          borderRadius={6}
          borderColor="componentBorder"
          key={id + 'tag-key-value'}
        >
          <Stack isInline>
            <TagKeyValueComponent id={id} />
            <Button
              {...deleteIconStyles}
              onClick={() => {
                dispatch(
                  actions.updateKeyValueCriterion(
                    omit(tagKeyValueCriteria, id),
                  ),
                );
              }}
            >
              <TrashIcon />
            </Button>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};
