import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.inventoryContext || initialState;

export const selectInventoryContext = createSelector(
  [selectDomain],
  inventoryContextState => inventoryContextState,
);
