import React, { useEffect } from 'react';

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from 'components/DataDisplay';
import { FootprintBox } from 'components/DataDisplay/Utils/FootprintBox';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { AnalysisOfDataSources } from 'containers/Visibility/Data/Components/AnalysisOfDataSources';
import { Analyzed } from 'containers/Visibility/Data/Components/Analyzed';
import { DataFootprint } from 'containers/Visibility/Data/Components/DataFootprint';
import { useDataDashboardContext } from 'containers/Visibility/Data/Components/hooks/context';
import { Records } from 'containers/Visibility/Data/Components/Records';
import { renderDataSecurityHeader } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';

import { DataTable } from './DataTable';

interface Props {
  showDataTypeSources?: boolean;
  setShowDataTypeSources?(val: boolean);
}

export const DataTables: React.FC<Props> = props => {
  const { selectedTab } = useSelector(selectDataDashboard);
  const { getTableData, getDataFootprint } = useDataDashboardContext();
  const dispatch = useDispatch();

  useEffect(() => {
    getTableData();
  }, [getTableData, selectedTab]);

  useEffect(() => {
    getDataFootprint();
  }, [getDataFootprint]);

  useEffect(() => {
    dispatch(sharedStateActions.getRegions({ q: {} }));
  }, [dispatch]);

  return (
    <Stack w="full" h="full">
      {selectedTab === 'DataSources' && (
        <Box>
          <Card styles={{ card: { px: 2 } }}>
            <Stack w="full" spacing={6}>
              {renderDataSecurityHeader(selectedTab)}
              <HStack w="full" h="full">
                <Box w="38%" h="full">
                  <FootprintBox
                    content={<DataFootprint />}
                    label="Data Footprint"
                  />
                </Box>
                <Box w="23%" h="full">
                  <FootprintBox content={<Analyzed />} label="Analyzed" />
                </Box>
                <Box w="20%" h="full">
                  <FootprintBox
                    content={<AnalysisOfDataSources />}
                    label="Analysis of data sources"
                  />
                </Box>
                <Box w="19%" h="full">
                  <FootprintBox content={<Records />} label="Records" />
                </Box>
              </HStack>
            </Stack>
          </Card>
        </Box>
      )}
      <Box flex={1}>
        <ActiveResourceProvider>
          <DataTable {...props} />
        </ActiveResourceProvider>
      </Box>
    </Stack>
  );
};
