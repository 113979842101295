import React from 'react';

import { Icon } from '@chakra-ui/react';

export const IamPolicyIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M155.5,257.2h163.4c6.4,0,11.7-5.2,11.7-11.7c0-6.5-5.2-11.7-11.7-11.7H155.5c-6.4,0-11.7,5.2-11.7,11.7
		C143.8,252,149.1,257.2,155.5,257.2z"
      />
      <path
        d="M155.5,163.7h46.7c6.4,0,11.7-5.2,11.7-11.7c0-6.5-5.2-11.7-11.7-11.7h-46.7c-6.4,0-11.7,5.2-11.7,11.7
		C143.8,158.4,149.1,163.7,155.5,163.7z"
      />
      <path
        d="M423.9,314.1V152c0-1.6-0.3-3.1-0.9-4.5c-0.6-1.4-1.4-2.7-2.5-3.8L280.4,3.4c-1.1-1.1-2.3-1.9-3.8-2.5
		c-1.4-0.6-2.9-0.9-4.5-0.9H120.5c-38.6,0-70,31.4-70,70.1v327.3c0,38.7,31.4,70.1,70,70.1h138.7c20.7,27,53.1,44.4,89.5,44.4
		c62.2,0,112.9-50.9,112.9-113.4C461.5,365,447,334.9,423.9,314.1z M283.8,39.9L384,140.3H315c-17.2,0-31.1-14-31.1-31.2V39.9z
		 M120.5,444.3c-25.8,0-46.6-21-46.7-46.8V70.1c0-25.8,20.9-46.7,46.7-46.8h140v85.7c0,30.1,24.4,54.5,54.4,54.6h85.6v134.2
		c-15.5-8.1-33.2-12.7-51.9-12.7c-35.4,0-67.1,16.5-87.8,42.2H155.5c-6.4,0-11.7,5.2-11.7,11.7c0,6.5,5.2,11.7,11.7,11.7h90.8
		c-6.8,14.5-10.6,30.8-10.6,47.9c0,16.2,3.4,31.7,9.6,45.7H120.5z M348.6,507c-59.5,0-107.9-48.6-107.9-108.4
		s48.4-108.4,107.9-108.4s107.9,48.6,107.9,108.4S408.1,507,348.6,507z"
      />
      <path
        d="M348.1,396.2c18.4,0,33.4-15,33.4-33.5s-15-33.5-33.4-33.5c-18.4,0-33.4,15-33.4,33.5S329.7,396.2,348.1,396.2
		z M348.1,339.7c12.6,0,22.8,10.3,22.8,23s-10.2,23-22.8,23s-22.8-10.3-22.8-23S335.5,339.7,348.1,339.7z"
      />
      <path
        d="M376.2,414H320c-18.4,0-33.4,15-33.4,33.5v14.1c0,2.9,2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3v-14.1
		c0-12.7,10.2-23,22.8-23h56.2c12.6,0,22.8,10.3,22.8,23v14.1c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3v-14.1
		C409.6,429,394.6,414,376.2,414z"
      />
    </g>
  </Icon>
);
