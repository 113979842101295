import React from 'react';

import { useSelector } from 'react-redux';

import { DeleteActionButton } from 'components/DataEntry';
import { selectOrganizations } from 'containers/Organizations/selectors';

interface Props {
  onDelete: () => void;
  row: Record<string, any>;
}

export const DeleteOrganization = (props: Props) => {
  const { onDelete, row } = props;
  const { deleteOrganization } = useSelector(selectOrganizations);

  return (
    <DeleteActionButton
      onConfirm={onDelete}
      isDisabled={row?.name === 'master'}
      name={row?.name}
      type="organization"
      body={row?.enabled && 'Please disable the organization first to delete.'}
      isLoading={deleteOrganization.loading}
    />
  );
};
