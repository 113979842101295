import React, { useState, useEffect } from 'react';

import { CloudAccountGetResponse } from '@ariksa/cloud-account/api';
import { Stack, Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { forEach, map } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { customTheme } from 'theme';

import { LineChart } from 'app/components/Visualization/Charts';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { Modal } from 'components/Overlay';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp } from 'containers/App/selectors';
import { selectCloudAccounts } from 'containers/Setup/CloudAccounts/selectors';

import { actions } from '../../slice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  row: CloudAccountGetResponse;
}

export const ChecklistHistoricalTrend: React.FC<Props> = props => {
  const { isOpen, onClose, row } = props;
  const dispatch = useDispatch();
  const { checklistHistory } = useSelector(selectCloudAccounts);
  const { user } = useSelector(selectApp);
  const { withAccessBoundary } = useAccessBoundary();
  const [chartData, setChartData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    dispatch(
      actions.getChecklistHistoricalTrend({
        q: {
          startDate: dayjs().subtract(6, 'days').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        },
      }),
    );
  }, [dispatch, withAccessBoundary, user.info, row]);

  useEffect(() => {
    const d: Record<string, any> = {};
    forEach(checklistHistory.data, (o, date) => {
      forEach(o, item => {
        !d[date]
          ? (d[date] = { [item.account_id]: item.score })
          : (d[date][item.account_id] = item.score);
      });
    });
    const data = map(d, (o, date) => {
      const score = o?.[row.uuid] ?? 0;
      return {
        Date: dayjs(date).format('DD-MMM'),
        Score: score,
      };
    });
    setChartData(data);
  }, [row, checklistHistory.data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Historical Trend - Governance Score"
      styles={{
        modal: { size: '5xl' },
      }}
      body={
        <Stack>
          <DetailsField label="Account Name" value={row.name} />
          <DetailsField label="Account ID" value={row.cloud_account_id} />
          <Box h="300px">
            <LineChart
              data={chartData}
              isLoading={checklistHistory.isLoading}
              xField="Date"
              yField={['Score']}
              styles={{
                lineColors: [customTheme.colors.primary],
                yAxis: {
                  domain: [0, 10],
                  tickCount: 6,
                },
              }}
            />
          </Box>
        </Stack>
      }
    />
  );
};
