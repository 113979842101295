import React from 'react';

import { Box } from '@chakra-ui/react';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';

import {
  renderSeverityBar,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import {
  renderPolicyContext,
  renderPolicyCreated,
} from 'containers/PolicyHub/utils';
import { toTitleCase } from 'utils/string';

export const useBlueprintPolicyColumns = () => {
  const { getResourceAlias, getCloudAgnosticName } = useResourceType();
  const { blueprint } = useSelector(selectBlueprints);

  const policiesCommonColumns = [
    {
      header: 'Category',
      accessor: 'alert_category',
      align: 'left',
      render: ({ value }) => toTitleCase(value),
      styles: { width: '200px', cell: { maxWidth: '200px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      align: 'left',
      render: ({ value }) => renderSeverityBar({ value, showLabel: false }),
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Resource Type',
      accessor: 'resource_type',
      render: ({ value }) => (
        <StackedCell
          upper={getResourceAlias(value)}
          lower={getCloudAgnosticName(value)}
        />
      ),
      align: 'left',
      styles: { width: '170px', cell: { maxWidth: '170px' } },
    },
  ];

  const created = {
    header: 'Created',
    accessor: 'created_by',
    render: renderPolicyCreated,
    align: 'left',
    styles: { width: '200px', cell: { maxWidth: '200px' } },
  };
  const allPoliciesColumns = [...policiesCommonColumns, created];

  const context = {
    header: 'Context',
    render: ({ row }) => {
      return renderPolicyContext({
        value: filter(
          row.policy_metadata,
          o => o.blueprint_id === blueprint.data.id || !o.blueprint_id,
        ),
      });
    },
    styles: { width: '80px', cell: { maxWidth: '80px' } },
  };

  const policiesTableColumns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Description</Box>,
      accessor: 'compliance_description',
      render: ({ row }) => (
        <WithResourceIcon resourceType={row.resource_type}>
          {row?.compliance_description ?? row?.alert_description}
        </WithResourceIcon>
      ),
    },
    ...policiesCommonColumns,
    context,
    created,
  ];

  return {
    policiesTableColumns,
    policiesCommonColumns,
    allPoliciesColumns,
    context,
  };
};
