// map entry internally have the key
interface IdMapEntry<K> {
  id: K;
}

// manage [key => value] map
export class IdMap<K, V extends IdMapEntry<K>> {
  protected entries: Map<K, V> = new Map();

  get size() {
    return this.entries.size;
  }

  forEach(cb: (v: V) => void) {
    this.entries.forEach(cb);
  }

  merge(other: IdMap<K, V>): IdMap<K, V> {
    const map: IdMap<K, V> = new IdMap<K, V>();
    this.entries.forEach(n => {
      map.add(n);
    });

    other.entries.forEach(n => {
      map.add(n);
    });

    return map;
  }

  get(key: K) {
    return this.entries.get(key);
  }

  add(value: V) {
    this.entries.set(value.id, value);
  }

  remove(key: K) {
    this.entries.delete(key);
  }

  has(key: K) {
    return this.entries.has(key);
  }

  clear() {
    this.entries.clear();
  }
}
