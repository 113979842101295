/**
 *
 * Step
 *
 */
import React, { FC } from 'react';

import { Box, Divider, Flex, Stack, Text } from '@chakra-ui/react';

import { defaultStyles } from '../styles';
import { StepProps } from '../types';

interface IStep extends StepProps {
  stepIndex: number;
  isLast?: boolean;
  isFirst?: boolean;
  vertical?: boolean;
  active?: boolean;
  completed?: boolean;
  onStepClick?: (current: number) => void;
}

export const Step: FC<IStep> = props => {
  const {
    label,
    icon,
    stepIndex,
    isLast,
    vertical,
    active,
    isFirst,
    completed,
    disabled,
    onStepClick,
  } = props;
  const handleOnClick: React.MouseEventHandler = () => {
    onStepClick?.(stepIndex);
  };
  const { bar, iconItem } = defaultStyles;

  const progressBar = () => (
    <Divider
      {...bar?.common}
      {...(vertical ? bar?.vertical : bar?.horizontal)}
      orientation={vertical ? 'vertical' : 'horizontal'}
      borderColor={(vertical && active) || completed ? 'primary' : 'gray.300'}
    />
  );

  return (
    <Stack
      direction={!vertical ? 'row' : 'column'}
      position="relative"
      flex={1}
      flexDirection="column"
      verticalAlign="top"
      spacing={0}
      textAlign={vertical ? 'inherit' : 'center'}
    >
      <Box minW={'150px'}>
        {((!isFirst && vertical) || (!vertical && !isLast)) && progressBar()}
        <Box
          {...iconItem?.common}
          {...(active ? iconItem?.active : {})}
          {...(completed ? iconItem?.completed : {})}
          {...(disabled ? iconItem?.disabled : {})}
          onClick={handleOnClick}
        >
          <Flex justify="space-around" pt={icon ? 'inherit' : 'initial'}>
            {icon || stepIndex + 1}
          </Flex>
        </Box>
        <Flex mt="8px" display={vertical ? 'inline-flex' : 'inherit'}>
          <Text fontSize="sm" px={0}>
            {label}
          </Text>
        </Flex>
      </Box>
    </Stack>
  );
};
