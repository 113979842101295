import { PayloadAction } from '@reduxjs/toolkit';
import { original } from 'immer';
import { cloneDeep } from 'lodash';

import {
  onApiCall,
  onApiCallError,
  onApiCallSuccess,
} from 'app/api/call_status';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { randomString } from 'utils/string';

import { ContainerState } from './types';

// The initial state of the SetupUsers container
export const initialState: ContainerState = {
  apiCallStatus: {
    loading: false,
    error: null,
  },
  deleteRole: {
    loading: false,
    loaded: false,
    error: null,
  },
  roles: {
    loading: false,
    error: null,
    roles: [],
  },
  roleForm: {
    loading: false,
    loaded: false,
    error: null,
    role: {
      name: 'Cloud Admin',
      organization: '',
      id: '1asfasz',
      expanded: true,
      editing: true,
      editingCopy: true,
      system: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      tenancy: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      cloud_onboarding: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      visibility: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      iam: {
        view: true,
        create: true,
        update: true,
        delete: true,
      },
      reporting: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      remediation: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      policy: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      compliance: {
        view: false,
        create: false,
        update: false,
        delete: false,
      },
      resources: [],
    },
  },
  saveCopy: {
    roleId: '',
    loading: false,
    error: null,
  },
  updateRole: {
    roleId: '',
    loading: false,
    error: null,
  },
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRole(state) {
      state.roleForm = initialState.roleForm;
    },
    updateFormScope(state, action: PayloadAction<any>) {
      state.roleForm.role = { ...state.roleForm.role, ...action.payload };
    },
    updateFormName(state, action: PayloadAction<any>) {
      state.roleForm.role.name = action.payload;
    },
    onUpdateFormResources(state, action: PayloadAction<any>) {
      state.roleForm.role.resources = action.payload;
    },

    createRole(state, action: PayloadAction<any>) {
      onApiCall(state.roleForm);
    },

    createRoleSuccess(state) {
      onApiCallSuccess(state.roleForm);
      state.roleForm = initialState.roleForm;
    },

    createRoleError(state, action: PayloadAction<any>) {
      onApiCallError(state.roleForm, action.payload);
    },

    getRoles(state) {
      onApiCall(state.roles);
    },
    getRolesSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.roles);
      state.roles.roles = action.payload.roles;
    },
    getRolesError(state, action: PayloadAction<any>) {
      onApiCallError(state.roles, action.payload);
    },

    deleteRole(state, action: PayloadAction<any>) {
      onApiCall(state.deleteRole);
    },
    deleteRoleSuccess(state) {
      onApiCallSuccess(state.deleteRole);
    },
    deleteRoleError(state, action: PayloadAction<any>) {
      onApiCallError(state.deleteRole, action.payload);
    },

    updateRoleScope(state, action: PayloadAction<any>) {
      const { role, resources } = action.payload;
      state.roles.roles = state.roles.roles.map(r => {
        return r.id !== role.id ? r : { ...r, ...resources, editing: true };
      });
    },
    updateRoleName(state, action: PayloadAction<any>) {
      const { role, name } = action.payload;
      state.roles.roles = state.roles.roles.map(r => {
        return r.id !== role.id ? r : { ...r, name };
      });
    },
    updateRoleAccounts(state, action: PayloadAction<any>) {
      const { role, accounts } = action.payload;
      state.roles.roles = state.roles.roles.map(r => {
        return r.id !== role.id ? r : { ...r, accounts };
      });
    },
    updateRoleResources(state, action: PayloadAction<any>) {
      const { role, resources } = action.payload;
      // console.log(resources);

      state.roles.roles = state.roles.roles.map(r => {
        return r.id !== role.id ? r : { ...r, resources };
      });
    },

    createCopyRole(state, action: PayloadAction<any>) {
      const found = findRole(state, action.payload);

      const role = { ...found, name: `${found.name}-copy` };

      if (found) {
        state.roles.roles = [
          ...state.roles.roles,
          { ...cloneDeep(role), id: randomString(), editingCopy: true },
        ];
      }
    },

    saveCopy(state, action: PayloadAction<any>) {
      const found = findRole(state, action.payload);
      if (found) {
        state.saveCopy.roleId = found.id;
        found.editing = false;
        found.editingCopy = false;
        found.expanded = false;
        onApiCall(state.saveCopy);
      }
    },

    saveCopySuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.saveCopy);
    },
    saveCopyError(state, action: PayloadAction<any>) {
      onApiCallError(state.saveCopy, action.payload);
      state.saveCopy.roleId = '';
    },

    onToggle(state, action: PayloadAction<any>) {
      // console.log(action.payload);
      const found = findRole(state, action.payload);
      if (found) {
        found.expanded = !found.expanded;
      }
    },

    startEdit(state, action: PayloadAction<any>) {
      const found = findRole(state, action.payload);
      found.editing = true;
    },

    updateRole(state, action: PayloadAction<any>) {
      const found = findRole(state, action.payload);
      if (found) {
        state.updateRole.roleId = found.id;
        found.editing = false;
        onApiCall(state.updateRole);
      }
    },
    updateRoleSuccess(state, action: PayloadAction<any>) {
      onApiCallSuccess(state.updateRole);
    },
    updateRoleError(state, action: PayloadAction<any>) {
      onApiCallError(state.updateRole, action.payload);
      state.updateRole.roleId = '';
    },
  },
});

function findRole(state, role) {
  return state.roles.roles.find(r => original(r).id === role.id);
}

export const { actions, reducer, name: sliceKey } = rolesSlice;
