export const updateCommandWithVariables = (
  command: string,
  field: string,
  key: string,
  value: string,
) => {
  // console.log('Update command', field, key, value, command);
  const index = command.indexOf(field);
  const before = command.slice(0, index);
  const after = command.slice(index);
  const keyIndex = after.indexOf(key);
  const beforeKey = after.slice(0, keyIndex);
  const afterKey = after.slice(keyIndex).slice(key.length);
  return `${before}${beforeKey}${value}${afterKey}`;
};
