import { EventEmitter } from 'events';

type Listener = (...args: any[]) => void;

export class Notification extends EventEmitter {
  subscriptions: Map<string, Listener> = new Map<string, Listener>();

  singleton(event: string, cb: Listener) {
    const fn = this.subscriptions.get(event);
    if (fn) {
      this.off(event, fn);
    }
    this.on(event, cb);
  }
}
