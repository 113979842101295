import React from 'react';

import { Categories, Resources } from '@ariksa/inventory-core';
import { sum } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formatTooltip } from 'components/DataDisplay';
import { UserIcon } from 'components/Icons';
import { selectIdentityOverview } from 'containers/Dashboard/IdentityOverview/selectors';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { SummaryCard } from 'containers/Dashboard/utils/SummaryCard';

export const CloudUsers: React.FC = () => {
  const { inventorySummary } = useSelector(selectDashboard);
  const { cloudUsersChange } = useSelector(selectIdentityOverview);
  const navigate = useNavigate();

  return (
    <SummaryCard
      label="Cloud Users"
      icon={<UserIcon color="blue.300" />}
      change={{
        isLoading: cloudUsersChange.isLoading,
        first: cloudUsersChange.data?.previous_count,
        last: cloudUsersChange.data?.current_count,
        percentChange: cloudUsersChange.data?.percentage_change,
      }}
      cloudProviders={
        !!inventorySummary.data.items?.['User']?.['Cloud']
          ? Object.keys(inventorySummary.data.items?.['User']?.['Cloud'])
          : []
      }
      count={
        !!inventorySummary.data.items?.['User']?.['Cloud']
          ? sum(Object.values(inventorySummary.data.items?.['User']?.['Cloud']))
          : 0
      }
      isLoading={inventorySummary.isLoading}
      onClick={() =>
        navigate(
          '/inventory/summary?tab=' +
            Categories.Identity +
            '_' +
            Resources.User,
        )
      }
      labelTooltip={formatTooltip({
        header: 'Cloud users',
        content: 'Cloud-native users in public clouds',
      })}
    />
  );
};
