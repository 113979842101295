import React, { FC, useEffect, useState } from 'react';

import G6, { Graph } from '@antv/g6';
import { AssumeRoleSessionEvents } from '@ariksa/compliance-policies';
import { Trail } from '@ariksa/compliance-policies/api';
import { NativeResources } from '@ariksa/inventory-core';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { each } from 'lodash';
import { Dict } from 'types/utils';

import { ComplianceService, NotificationService } from 'api/services';
import { WithParentSize } from 'components/Container/WithParentSize';
import { AbsoluteSpinner } from 'components/DataDisplay/Spinner/AbsoluteSpinner';
import { ROLE_CHAINING } from 'components/Visualization/RoleChainingTimeline/registerCombo';
import {
  ASSUME_ROLE,
  COMBO_NODE,
  COMBO_RESOURCE_NODE,
} from 'components/Visualization/RoleChainingTimeline/registerNodes';
import { Timeline } from 'components/Visualization/RoleChainingTimeline/Timeline';

function useRoleChainingGraph(ref, w, h) {
  const [graph, setGraph] = useState<Graph | null>(null);

  useEffect(() => {
    if (!ref || !w || !h || graph) return;

    const newGraph = new G6.Graph({
      container: ref,
      width: w,
      height: h,
      groupByTypes: false,
      plugins: [],
      defaultCombo: {
        type: ROLE_CHAINING,
      },
      modes: {
        default: ['drag-canvas'],
      },
      defaultEdge: {
        type: 'cubic-horizontal',
        style: {
          lineWidth: 1,
          stroke: 'red',
        },
        zIndex: 100,
      },
      defaultNode: {
        anchorPoints: [
          [0, 0.5],
          [1, 0.5],
        ],
      },
    });
    setGraph(newGraph);
  }, [graph, h, ref, w]);

  return graph;
}

interface TrailNode extends Trail {
  // {session_id}:{index}
  id: string;

  // unix timestamp in millisecond
  // https://day.js.org/docs/en/parse/unix-timestamp-milliseconds
  timestamp: number;

  // {session_id}:{index}
  assumed_session_id: string | null;
}

interface AccountNode {
  account_name: string;
  account_id: string;
  trails: TrailNode[];
}

interface IRoleChainingTimeline {
  accountId: string;
  entityId: string;
  resourceType: string;
}

export const RoleChainingTimeline: FC<IRoleChainingTimeline> = props => {
  const { accountId, entityId, resourceType } = props;
  const ref = React.useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const graph = useRoleChainingGraph(ref.current, width, height);
  const [accounts, setAccounts] = useState<Dict<AccountNode>>({});
  const [accountIds, setAccountIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false); // in seconds
  const [startTime, setStartTime] = useState(0); // in seconds
  const [windowStartTime, setWindowStartTime] = useState(0); // in seconds
  const [endTime, setEndTime] = useState(0); // in seconds
  const [timeSpan] = useState(4 * 60); //in seconds

  let left = 10;
  const legends = [
    'create',
    'read',
    'update',
    'delete',
    'list',
    'permissions',
    'tagging',
    'credential',
    'assume_role',
    'failed_operation',
  ].map((permissionType, i) => {
    const x = left;
    left += 30 + permissionType.length * 6 + 30;
    return {
      id: permissionType,
      type: ASSUME_ROLE,
      x: x,
      y: 20,
      data: {
        permissionType: permissionType,
        label: permissionType,
      },
    };
  });

  useEffect(() => {
    setIsLoading(true);
    let alertRuleId = '';
    switch (resourceType) {
      case NativeResources.IamUser:
        alertRuleId = 'IAM-USER-ROLE-CHAINED';
        break;
      case NativeResources.IamRole:
        alertRuleId = 'IAM-ROLE-CHAINED';
        break;
      case NativeResources.Ec2Instance:
        alertRuleId = 'EC2-ROLE-CHAINED';
        break;
      default:
        alertRuleId = '';
    }

    // NotificationService.Alerts.getAlerts({
    //   accountId,
    //   entityUuid: entityId,
    //   alertRuleId: [alertRuleId],
    // })
    //   .then(res => {
    //     const { extras = {} as any } = res.data.results?.[0] ?? {};
    //     const { security_tokens = [] } = extras;
    //     if (security_tokens.length === 0) {
    //       throw Error(
    //         `there is no security token for the resource: ${resourceType}`,
    //       );
    //     }
    //     ComplianceService.AuditTrail.getTrail({
    //       sessionToken: security_tokens,
    //     })
    //       .then(res => {
    //         const data: AssumeRoleSessionEvents[] = res.data ?? [];
    //         const accounts: Record<string, AccountNode> = {};
    //         const accountIds: string[] = [];
    //         let startTime = 99999999999999999;
    //         let endTime = -99999999999999999;
    //
    //         // console.log(res.data);
    //         each(data, session => {
    //           if (!accountIds.includes(session.account_id)) {
    //             accountIds.push(session.account_id);
    //           }
    //           if (!accounts[session.account_id]) {
    //             accounts[session.account_id] = {
    //               account_id: session.account_id,
    //               account_name: session.account_name,
    //               trails: [],
    //             };
    //           }
    //
    //           // add ids to trails
    //           // assumed_session_id is the target session trail id
    //           const trails: TrailNode[] = session.trail.map((t, i) => {
    //             const time = dayjs(t.time);
    //             const timestamp = time.unix();
    //             startTime = Math.min(timestamp, startTime);
    //             endTime = Math.max(timestamp, endTime);
    //             return {
    //               ...t,
    //               date: t.time,
    //
    //               id: session.session_id + `:${i}` ?? '-',
    //               assumed_session_id: t.assumed_session
    //                 ? t.assumed_session + ':0'
    //                 : null,
    //               timestamp: timestamp,
    //             };
    //           });
    //           accounts[session.account_id].trails.push(...trails);
    //         });
    //
    //         setAccounts(accounts);
    //         setAccountIds(accountIds);
    //         setStartTime(startTime);
    //         setEndTime(endTime);
    //       })
    //       .finally(() => {
    //         setIsLoading(false);
    //       });
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //   });

    ComplianceService.AuditTrail.getCrossAccountActivity({
      accountId,
      resourceType: resourceType as any,
      cloudResourceId: entityId,
    })
      .then(res => {
        const data: AssumeRoleSessionEvents[] = res.data ?? [];
        const accounts: Record<string, AccountNode> = {};
        const accountIds: string[] = [];
        let startTime = 99999999999999999;
        let endTime = -99999999999999999;

        // console.log(res.data);
        each(data, session => {
          if (!accountIds.includes(session.account_id)) {
            accountIds.push(session.account_id);
          }
          if (!accounts[session.account_id]) {
            accounts[session.account_id] = {
              account_id: session.account_id,
              account_name: session.account_name,
              trails: [],
            };
          }

          // add ids to trails
          // assumed_session_id is the target session trail id
          const trails: TrailNode[] = session.trail.map((t, i) => {
            const time = dayjs(t.time);
            const timestamp = time.unix();
            startTime = Math.min(timestamp, startTime);
            endTime = Math.max(timestamp, endTime);
            return {
              ...t,
              date: t.time,

              id: session.session_id + (`:${i}` ?? '-'),
              assumed_session_id: t.assumed_session
                ? t.assumed_session + ':0'
                : null,
              timestamp: timestamp,
            };
          });
          accounts[session.account_id].trails.push(...trails);
        });

        setAccounts(accounts);
        setAccountIds(accountIds);
        setStartTime(startTime);
        setEndTime(endTime);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [accountId, resourceType, entityId]);

  useEffect(() => {
    const nodes: any[] = [];
    const edges: any[] = [];

    // insert account and trail nodes
    each(accountIds, (aid, i) => {
      const account = accounts[aid];
      //calculate account y position
      const accountY = 70 + i * 100;
      {
        const comboNode = {
          id: `account-role-chain-node-${aid}`,
          x: 0,
          y: accountY,
          type: COMBO_NODE,
          width,
        };

        const accountNode = {
          id: `account-node-${aid}`,
          type: COMBO_RESOURCE_NODE,
          x: 10,
          y: accountY + 10,
          data: {
            cloud_account_id: aid,
            cloud_account_name: account.account_name,
          },
        };

        nodes.push(comboNode);
        nodes.push(accountNode);
      }

      const actionNodes: any[] = [];
      // calculate permissions position as per time stamp and insert trail nodes
      account.trails.forEach((t, j) => {
        // const x = 200 + i * 150 + j * 40; //(t.seconds / 86400) * (width - 150);
        const posInSeconds = t.timestamp - startTime;

        // console.log(posInSeconds);
        const relativePosInFraction =
          (posInSeconds - windowStartTime) / timeSpan;
        if (relativePosInFraction < 0) return;

        const availableWidthForPermissionNodes = width - 300;
        const x =
          160 + relativePosInFraction * availableWidthForPermissionNodes;
        const actionNode = {
          id: t.id,
          time: t.time,
          x: x,
          y: accountY + 32,
          type: ASSUME_ROLE,
          data: {
            permissionType: t.permissionType,
          },
        };
        actionNodes.push(actionNode);

        // t.assumed_session exists only when the new role was assumed
        if (t.assumed_session) {
          edges.push({ source: t.id, target: t.assumed_session_id });
        }
      });

      nodes.push(...actionNodes);
    });

    const data = {
      nodes: [...legends, ...nodes],
      edges,
    };

    graph?.data(data);
    graph?.render();
  }, [
    graph,
    legends,
    width,
    accountIds,
    accounts,
    endTime,
    startTime,
    windowStartTime,
    timeSpan,
  ]);

  // add timeline
  return (
    <Box h={'full'} px={0} pos={'relative'}>
      {
        <WithParentSize>
          {(h, w) => {
            setHeight(h);
            setWidth(w);
            return <Box ref={ref} h={h + 'px'} w={w + 'px'} />;
          }}
        </WithParentSize>
      }
      <Timeline
        startTime={startTime}
        timeSpan={timeSpan}
        totalTimeSpan={endTime - startTime}
        onChange={setWindowStartTime}
      />
      {isLoading && (
        <Box h={'full'} w={'full'} pos={'absolute'} top={0}>
          <AbsoluteSpinner isLoading={isLoading} envelop />
        </Box>
      )}
    </Box>
  );
};
