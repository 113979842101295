import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.conversationalAI || initialState;

export const selectConversationalAI = createSelector(
  [selectDomain],
  conversationalAIState => conversationalAIState,
);
