/**
 *
 * Asynchronously loads the component for Outpost
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Outpost = lazyLoad(
  // @ts-ignore
  () => import('./index.tsx'),
  module => module.Outpost,
);
