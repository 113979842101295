import React, { BaseSyntheticEvent, FC } from 'react';

import { NativeResources } from '@ariksa/inventory-core/api';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { isArray, map, slice } from 'lodash';

import {
  AlertCounts,
  StatusMap,
} from 'components/DataDisplay/Misc/DrawerAlertCounts';
import {
  CancelButton,
  PrimaryButton,
  Select,
  SelectOption,
  textAreaFieldStyles,
} from 'components/DataEntry';
import {
  CustomTooltip,
  DetailsIdentifier,
  Modal,
  StackedHeader,
  Tag,
} from 'components/index';
import { IModalProps } from 'components/Overlay/Modal/types';
import { EnvironmentName } from 'containers/App/utils';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

import { formatDate } from '../../../../utils/date';

export interface AddExceptionModalProps extends IModalProps {
  resourceType: NativeResources;
  resourceName?: string;
  resourceId?: string;
  onConfirm: (e: BaseSyntheticEvent, data: any) => void;
  isLoading?: boolean;
  isMulti?: boolean;
  description?: string;
  blueprintName?: string;
  blueprintOptions?: Record<string, any>[];
  selectedBlueprint?: Record<string, any>[];

  setSelectedBlueprint?(value: Record<string, any>[]);

  environment: string;
  accountId: string | string[];
  alertCount: StatusMap;
  resourceTypeOptions?: Record<string, any>[];
  setSelectedResourceType?: (value: NativeResources) => void;
  riskContext?: Record<string, any>;
  actionType?: 'Add' | 'Remove';
  exceptionDescription: string;

  setExceptionDescription?(value: string);

  setExceptionApplyTime?(value: SelectOption);

  exceptionApplyTime?: SelectOption;

  onClick?();
}

export const AddExceptionModal: FC<AddExceptionModalProps> = props => {
  const {
    onConfirm,
    onClose,
    isLoading,
    resourceName,
    resourceId,
    isMulti = false,
    description,
    resourceType,
    environment,
    accountId,
    alertCount,
    resourceTypeOptions,
    setSelectedResourceType,
    riskContext,
    selectedBlueprint,
    actionType = 'Add',
    exceptionDescription = '',
    setExceptionDescription,
    blueprintOptions,
    setSelectedBlueprint,
    setExceptionApplyTime,
    exceptionApplyTime,
    blueprintName,
    ...rest
  } = props;

  const applyTimeOptions = [
    { label: '1 day', value: dayjs().utc().add(1, 'day').format() },
    { label: '1 week', value: dayjs().utc().add(1, 'week').format() },
    { label: '1 month', value: dayjs().utc().add(1, 'month').format() },
    { label: '3 months', value: dayjs().utc().add(3, 'month').format() },
    { label: '6 months', value: dayjs().utc().add(6, 'month').format() },
    { label: '12 months', value: dayjs().utc().add(12, 'month').format() },
    { label: 'Forever', value: dayjs().utc().add(100, 'year').format() },
  ];

  return (
    <>
      <Modal
        onClose={onClose}
        isCentered
        header={
          <StackedHeader
            upper={actionType + ' Exception'}
            lower={
              isMulti
                ? 'Multiple Entities'
                : resourceName + ' (' + resourceId + ')'
            }
            type={resourceType}
          />
        }
        body={
          <Box w="full" h="full">
            <Stack spacing={5}>
              {description && (
                <DetailsIdentifier
                  label="Description"
                  value={description}
                  direction="column"
                  spacing={0}
                />
              )}

              <HStack w={'full'} align={'start'} spacing={6}>
                <Stack flex={1} spacing={4} pr={4}>
                  <DetailsIdentifier
                    label="Environment"
                    isInline={false}
                    value={<EnvironmentName environmentId={environment} />}
                  />
                  <DetailsIdentifier
                    label="Policy blueprint"
                    isInline={false}
                    value={
                      blueprintName ? (
                        blueprintName
                      ) : (
                        <Box w="full">
                          <Select
                            options={blueprintOptions}
                            value={selectedBlueprint}
                            onChange={setSelectedBlueprint}
                            isMulti
                          />
                        </Box>
                      )
                    }
                    flex={1}
                  />
                </Stack>
                <Stack flex={1}>
                  <DetailsIdentifier
                    label="Account ID"
                    isInline={false}
                    value={
                      isArray(accountId) ? (
                        <Stack spacing={0}>
                          {accountId?.[0] && (
                            <CloudAccountName accountId={accountId?.[0]} />
                          )}
                          <HStack>
                            {accountId?.[1] && (
                              <CloudAccountName accountId={accountId?.[1]} />
                            )}
                            {accountId?.length > 2 && (
                              <CustomTooltip
                                label={
                                  <Stack spacing={1}>
                                    {map(
                                      slice(accountId, 2, accountId?.length),
                                      o => (
                                        <CloudAccountName accountId={o} />
                                      ),
                                    )}
                                  </Stack>
                                }
                              >
                                <Tag
                                  label={'+' + (accountId?.length - 2)}
                                  styles={{
                                    tag: { bg: 'primary' },
                                    label: { color: 'white' },
                                  }}
                                />
                              </CustomTooltip>
                            )}
                          </HStack>
                        </Stack>
                      ) : (
                        <CloudAccountName accountId={accountId} />
                      )
                    }
                  />
                  <DetailsIdentifier
                    label="Exception applied to severities"
                    isInline={false}
                    value={<AlertCounts statusMap={alertCount} />}
                  />
                </Stack>
                {/*<Stack flex={1}>*/}
                {/*  <DetailsIdentifier*/}
                {/*    label="Risk context"*/}
                {/*    value={riskContext ? renderRiskContext([riskContext]) : ''}*/}
                {/*    flex={1}*/}
                {/*  />*/}
                {/*</Stack>*/}
              </HStack>

              <Divider />

              <HStack>
                <HStack flex={1} spacing={4}>
                  <Flex fontWeight={600} whiteSpace="nowrap">
                    Applies for{' '}
                    <Text color={'red'} pl={1}>
                      *
                    </Text>
                  </Flex>
                  <Box w={'200px'}>
                    <Select
                      value={exceptionApplyTime}
                      options={applyTimeOptions}
                      onChange={opt => {
                        setExceptionApplyTime?.(opt);
                      }}
                      menuPortalTarget={document.querySelector('body')}
                    />
                  </Box>
                </HStack>
                <HStack flex={1}>
                  <Text fontWeight={600}>Expires at:</Text>
                  <Text>
                    {formatDate(
                      exceptionApplyTime?.value ?? '',
                      'MM/DD/YYYY hh:mm A',
                    )}
                  </Text>
                </HStack>
              </HStack>

              <Stack pt={2}>
                <Flex fontWeight={600} whiteSpace="nowrap">
                  Reason for exception{' '}
                  <Text color={'red'} pl={1}>
                    *
                  </Text>
                </Flex>
                <Textarea
                  {...textAreaFieldStyles.textArea}
                  isDisabled={actionType === 'Remove'}
                  onChange={e => {
                    setExceptionDescription?.(e.target.value.trim());
                  }}
                />
              </Stack>

              <Stack>
                <Text color={'red'}>NOTE:</Text>
                <Text>
                  After the exception expires, Ariksa will automatically raise
                  this issue if it persists in this environment and account.
                </Text>
              </Stack>
            </Stack>
          </Box>
        }
        footer={
          <Flex justifyContent={'space-between'} w="full">
            <CancelButton onClick={onClose}>Cancel</CancelButton>
            <PrimaryButton
              onClick={e =>
                onConfirm(e, {
                  description: exceptionDescription,
                })
              }
              isLoading={isLoading}
              isDisabled={
                exceptionDescription.trim() === '' && actionType === 'Add'
              }
            >
              Okay
            </PrimaryButton>
          </Flex>
        }
        styles={{ modal: { size: '3xl' } }}
        {...rest}
      />
    </>
  );
};
