import React, { FC, useEffect, useState } from 'react';

import { each, forEach } from 'lodash';
import { useSelector } from 'react-redux';

import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';

import { AlertCount } from './AlertCount';

export const AlertCountForAllCategories: FC = () => {
  const { alertCategories } = useSelector(selectFindingsOverview);
  const [severities, setSeverities] = useState({
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  });

  useEffect(() => {
    const severities = { critical: 0, medium: 0, low: 0, high: 0 };
    each(alertCategories.data, o =>
      forEach(o, s => {
        severities.critical += s.severity.CRITICAL ?? 0;
        severities.high += s.severity.HIGH ?? 0;
        severities.medium += s.severity.MEDIUM ?? 0;
        severities.low += s.severity.LOW ?? 0;
      }),
    );
    setSeverities(severities);
  }, [alertCategories]);

  return <AlertCount {...severities} />;
};
