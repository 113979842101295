import React, { FC } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { PageHeaderWithIcon } from 'components/DataDisplay';
import { ComplianceCheckPassedIcon } from 'components/Icons';
import { ComplianceBlueprintControls } from 'containers/PolicyHub/Blueprints/Components/Blueprint/BlueprintPolicies/ComplianceBlueprint/ComplianceBlueprintControls';
import { AllPoliciesTable } from 'containers/PolicyHub/Blueprints/Components/BlueprintWizard/CreatePolicyBlueprint/Steps/Policies/Blueprint/AllPoliciesTable';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';

interface IBlueprintRules {
  selectedPolicies: string[];
  setSelectedPolicies(ruleIds: string[]);
  buildBlueprint?: boolean;
}

export const BlueprintRules: FC<IBlueprintRules> = props => {
  const {
    selectedPolicies,
    setSelectedPolicies,
    buildBlueprint = false,
  } = props;
  const { blueprint } = useSelector(selectBlueprints);

  return (
    <Stack h="full" spacing={4}>
      <Box mb={6}>
        <PageHeaderWithIcon
          label={
            'Add Blueprint:' +
            ' ' +
            (buildBlueprint ? 'Build Blueprint' : 'Use Existing Blueprint')
          }
          reversed
          icon={<ComplianceCheckPassedIcon />}
        />
      </Box>
      {(!blueprint.data?.compliance || buildBlueprint) && (
        <Box h="full" flex={1}>
          <AllPoliciesTable
            selectedPolicies={selectedPolicies}
            setSelectedPolicies={setSelectedPolicies}
          />
        </Box>
      )}
      {!!blueprint.data.compliance && !buildBlueprint && (
        <Box h="full" flex={1}>
          <ComplianceBlueprintControls
            selectedPolicies={selectedPolicies}
            setSelectedPolicies={setSelectedPolicies}
            action="Create"
          />
        </Box>
      )}
    </Stack>
  );
};
