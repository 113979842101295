import React from 'react';

import { NativeResources, PackageCategory } from '@ariksa/inventory-core/api';
import { HStack, Box, Stack } from '@chakra-ui/react';
import {
  forEach,
  isEmpty,
  join,
  map,
  slice,
  sum,
  toArray,
  truncate,
  values,
} from 'lodash';
import { customTheme } from 'theme';

import {
  renderTooltipFieldValue,
  StackedCell,
  Tag,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { IconTypes } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { ResourceTypeIconTooltip } from 'containers/App/hooks/useResourceType';
import { toTitleCase } from 'utils/string';

const renderCloudEntity = (type, count) => (
  <HStack spacing={1}>
    <Box boxSize={5}>{getIcon(type)}</Box>
    <Box>({count})</Box>
  </HStack>
);

export const renderCloudEntities = ({ value }) => {
  if (!sum(values(value)))
    return <Box color={customTheme.colors.gray['100']}>No Entities</Box>;
  return (
    <HStack>
      {!!value?.aws && renderCloudEntity(IconTypes.Aws, value?.aws)}
      {!!value?.azure && renderCloudEntity(IconTypes.Azure, value?.azure)}
      {!!value?.gcp && renderCloudEntity(IconTypes.Gcp, value?.gcp)}
    </HStack>
  );
};

export const renderCvssV3Score = ({ row }) => (
  <StackedCell
    upper={row?.cvss_v3_score?.toFixed(1)}
    lower={toTitleCase(row?.severity)}
  />
);

export const renderDeployedInVPCTooltip = (vpc, account) => (
  <Stack>
    <Box>VPC: {vpc || '-'}</Box>
    <Box>Account: {account}</Box>
  </Stack>
);

export const renderPackagesImpacted = ({ value }) => {
  const data = new Set();
  forEach(value, o => data.add(o));
  const renderTag = item => (
    <CustomTooltip label={item}>
      <Tag label={truncate(item, { length: 20 })} />
    </CustomTooltip>
  );
  const packages = toArray(data);
  return !isEmpty(data) ? (
    <Stack spacing={1}>
      <HStack spacing={1}>
        {packages?.[0] && renderTag(packages?.[0])}
        {packages?.[1] && renderTag(packages?.[1])}
      </HStack>
      <HStack spacing={1}>
        {packages?.[2] && renderTag(packages?.[2])}
        {packages?.length > 3 && (
          <CustomTooltip
            label={
              <Stack>
                {map(slice(packages, 3), o => (
                  <Box>{o as string}</Box>
                ))}
              </Stack>
            }
          >
            <Tag label={<Box>+ {packages?.length - 3}</Box>} />
          </CustomTooltip>
        )}
      </HStack>
    </Stack>
  ) : (
    '-'
  );
};

export const renderPackageType = value =>
  value === PackageCategory.ApplicationPackage
    ? 'Technology'
    : value === PackageCategory.LibraryPackage
    ? 'Library'
    : 'OS';

export const renderImageName = (row, resourceType) => {
  const name = row?.name ?? join(row?.image_tags, ', ');
  const id =
    resourceType === NativeResources.ElasticContainerRegistry
      ? row?.uri
      : row?.resource_id;
  return (
    <WithResourceIcon
      resourceType={resourceType as string}
      iconTooltip={<ResourceTypeIconTooltip resourceType={resourceType} />}
      tooltip={
        <Stack>
          {renderTooltipFieldValue('Name', name)}
          {renderTooltipFieldValue(
            resourceType === NativeResources.ElasticContainerRegistry
              ? 'URI'
              : 'ID',
            id,
          )}
        </Stack>
      }
    >
      <StackedCell
        upper={name}
        lower={id}
        showUpperTooltip={false}
        showLowerTooltip={false}
      />
    </WithResourceIcon>
  );
};
