import React from 'react';

import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/all';
import { customTheme } from 'theme';

import { CustomToastProps } from 'components/Toast/types';

export const CustomToast = (props: CustomToastProps) => {
  const {
    title,
    description,
    status = 'info',
    isClosable = true,
    onClose,
  } = props;
  return (
    <Box
      p={3}
      bg="white"
      borderRadius={6}
      border="1px solid"
      borderColor={customTheme.colors.componentBorder}
      h="100%"
    >
      <Stack justify="space-between" isInline spacing={4}>
        <HStack
          spacing={5}
          borderLeft="4px solid"
          borderColor={customTheme.colors?.[status]}
          pl={4}
        >
          <Stack>
            <Box fontSize="sm">{title}</Box>
            {description && (
              <Box fontSize="xs" color={customTheme.colors.gray['300']}>
                {description}
              </Box>
            )}
          </Stack>
        </HStack>
        <Flex h="full" justify="flex-start">
          {isClosable && (
            <Box
              boxSize={3}
              color="primary"
              cursor="pointer"
              onClick={() => onClose()}
              as={AiOutlineClose}
            />
          )}
          {/*<CloseButton
            size="xs"
            fontWeight="xs"
            border="none"
            _hover={{ border: 'none', bg: 'none', color: 'primary' }}
            _focus={{ border: 'none', bg: 'none', color: 'primary' }}
            _active={{ border: 'none', bg: 'none', color: 'primary' }}
          />*/}
        </Flex>
      </Stack>
    </Box>
  );
};
