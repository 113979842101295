import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RdsIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    p={0.5}
    {...props}
  >
    <path
      d="M499.2,0H12.8C5.7,0,0,5.7,0,12.8v486.4c0,7.1,5.7,12.8,12.8,12.8h486.4c7.1,0,12.8-5.7,12.8-12.8V12.8
	C512,5.7,506.3,0,499.2,0z M166.4,486.4H25.6V345.6h140.8V486.4z M166.4,320H25.6V192h140.8V320z M166.4,166.4H25.6V25.6h140.8
	V166.4z M320,486.4H192V345.6h128V486.4z M320,320H192V192h128V320z M320,166.4H192V25.6h128V166.4z M486.4,486.4H345.6V345.6h140.8
	V486.4z M486.4,320H345.6V192h140.8V320z M486.4,166.4H345.6V25.6h140.8V166.4z"
    />
  </Icon>
);
