import React from 'react';

import { Icon } from '@chakra-ui/react';

export const RouteIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        d="M507.8,245.9L361.7,99.7c-5.6-5.6-14.5-5.6-20.1,0c-5.6,5.6-5.6,14.5,0,20.1l122,122H14.2
		C6.3,241.8,0,248.1,0,256s6.3,14.2,14.2,14.2h449.4l-122,122c-5.6,5.6-5.6,14.5,0,20.1c2.7,2.7,6.4,4.2,10,4.2
		c3.6,0,7.3-1.4,10-4.2l146.1-146.1C513.4,260.4,513.4,251.4,507.8,245.9z"
      />
    </g>
  </Icon>
);
