import React, {
  BaseSyntheticEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { get, throttle } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { customTheme } from 'theme';

import {
  CopyToClipboardIconButton,
  PasswordFieldIconButton,
} from 'components/DataEntry';

import { useStyles } from '../index';
import { FieldProps, TextFieldSchema, TextFieldStyles } from '../types';

import { FormLabel } from './FormLabel';

export const TextField: FC<FieldProps<TextFieldSchema>> = ({
  id,
  name,
  field,
  isLoading,
}) => {
  const {
    label,
    onChange,
    onBlur,
    onKeyDown,
    placeholder,
    htmlInputType,
    //helperText,
    isRequired,
    isDisabled,
    helpIcon,
    leftInputAddon,
    rightInputAddon,
    leftInputElement,
    rightInputElement,
    shouldDisplay,
    defaultValue,
    styles = {},
    tooltip,
    passwordShow = true,
    showCopy = false,
    button,
    buttonDisable = true,
    value,
    error = '',
    helpTooltip,
    onClickHelpIcon,
  } = field;

  const [focused, setFocused] = React.useState(false);
  const fieldStyles = useStyles<TextFieldStyles>('textField', styles);
  const { register, watch, errors, setValue } = useFormContext();
  const values = watch({ nest: true });

  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    setErrorMessage(error ? error : get(errors, name)?.message);
  }, [error, errors, name, value]);

  // const errorMessage = useErrorMessage(name, label);

  const [show, setShow] = useState(false);
  //const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value, name, setValue]);

  const isVisible = useMemo(() => {
    return shouldDisplay ? shouldDisplay(values) : true;
  }, [values, shouldDisplay]);

  const handleOnChange = useCallback(
    (e: BaseSyntheticEvent) => {
      //setShowIcon(e.target.value.length !== 0);
      onChange?.(e.target.value, e);
    },
    [onChange],
  );

  const handleOnKeyDown = useCallback(
    e => {
      setValue(name, e.target.value);
      onKeyDown?.(e.target.value, e);
    },
    [name, onKeyDown, setValue],
  );

  const showPassword = () => {
    setShow(!show);
  };

  // console.log(isLoading);

  const getInputProps = useCallback(
    () => ({
      type: htmlInputType || 'text',
      id: id,
      name: name,
      key: id || `${name}-input`,
      'data-test': name,
      'aria-label': name,

      defaultValue,

      placeholder: placeholder,
      onChange: handleOnChange,
      isDisabled: isDisabled || isLoading,
      isInvalid: !!errorMessage,

      onFocus: () => setFocused(true),
      onBlur: e => {
        onBlur?.(e.target.value);
        setFocused(false);
      },

      onKeyDown: throttle(handleOnKeyDown, 200),
      _hover: { borderColor: errorMessage ? 'error' : 'primary' },
      _active: { borderColor: errorMessage ? 'error' : 'primary' },
      _focus: {
        borderWidth: '1',
        borderColor: errorMessage ? 'error' : 'primary',
      },
      ...fieldStyles.input,
      borderColor: errorMessage ? 'error' : 'formFieldBorder',
    }),
    [
      htmlInputType,
      id,
      name,
      defaultValue,
      placeholder,
      handleOnChange,
      isDisabled,
      isLoading,
      errorMessage,
      handleOnKeyDown,
      fieldStyles.input,
      onBlur,
    ],
  );

  return isVisible ? (
    <FormControl
      key={`${name}-control`}
      isRequired={isRequired}
      isInvalid={!!errorMessage}
      {...fieldStyles.control}
      //mb={tooltip ? 0 : 3}
    >
      {!!label && (
        <FormLabel
          htmlFor={name}
          label={label}
          helpIcon={helpIcon}
          styles={fieldStyles.label}
          helpTooltip={helpTooltip}
          isRequired={isRequired}
          onClickHelpIcon={onClickHelpIcon}
        />
      )}

      {!!(
        leftInputAddon ||
        rightInputAddon ||
        leftInputElement ||
        rightInputElement
      ) ? (
        <Box flex="1" h={12}>
          <Skeleton isLoaded={!isLoading}>
            <InputGroup {...fieldStyles.inputGroup}>
              {!!leftInputAddon && <InputLeftAddon {...leftInputAddon} />}
              {!!leftInputElement && <InputLeftElement {...leftInputElement} />}
              <Input ref={register} {...getInputProps()} />
              {!!rightInputAddon && <InputRightAddon {...rightInputAddon} />}
              {!!rightInputElement && (
                <InputRightElement {...rightInputElement} />
              )}
            </InputGroup>
          </Skeleton>

          {!(error || errorMessage) && tooltip && focused && (
            <Text color={customTheme.colors.gray['200']} mt="1" fontSize="12">
              {tooltip}
            </Text>
          )}
          {(error || errorMessage) && (
            <Text color="error" mt="1" fontSize="12">
              {error || errorMessage}
            </Text>
          )}
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          {htmlInputType === 'password' ? (
            <>
              <Box display="flex" flexDirection="column" flex="1" h="12">
                <InputGroup size="md" display="flex" alignItems="center">
                  <Skeleton isLoaded={!isLoading} w={'full'}>
                    <Input
                      pr="4rem"
                      ref={register}
                      {...getInputProps()}
                      type={show ? 'text' : 'password'}
                    />
                  </Skeleton>
                  {passwordShow && !isDisabled && (
                    <InputRightElement h="8" width="2.5rem">
                      <PasswordFieldIconButton
                        show={show}
                        toggleShowPassword={showPassword}
                      />
                    </InputRightElement>
                  )}
                </InputGroup>
                {!(error || errorMessage) && focused && (
                  <Text color="gray.200" mt="1" fontSize="12">
                    {tooltip}
                  </Text>
                )}
                {(error || errorMessage) && (
                  <Text color="error" mt="1" fontSize="12">
                    {error || errorMessage}
                  </Text>
                )}
              </Box>
            </>
          ) : (
            <>
              <Box flex="1" h={(!buttonDisable && '20') || (tooltip && '12')}>
                <Skeleton isLoaded={!isLoading}>
                  <InputGroup size="md" display="flex" alignItems="center">
                    <Input
                      autoComplete="off"
                      ref={register}
                      {...getInputProps()}
                    />

                    {showCopy && (
                      <InputRightElement h="8" width="2.5rem">
                        <CopyToClipboardIconButton
                          show={show}
                          value={value?.toString() ?? '-'}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Skeleton>

                {htmlInputType === 'email' && button && !buttonDisable && (
                  <Button
                    isDisabled={buttonDisable}
                    size="sm"
                    mt="2"
                    onClick={() => button(values)}
                  >
                    Validate
                  </Button>
                )}

                {!(error || errorMessage) && tooltip && focused && (
                  <Text color="gray.200" mt="1" fontSize="12">
                    {tooltip}
                  </Text>
                )}
                {(error || errorMessage) && (
                  <Text color="error" mt="1" fontSize="12">
                    {error || errorMessage}
                  </Text>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </FormControl>
  ) : null;
};
