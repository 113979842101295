import React, { FC } from 'react';

import { BoxProps, Flex, Center } from '@chakra-ui/react';

import { OptionItem } from 'components/DataEntry/Button/types';

import { optionsButtonGroupStyles } from './styles';

export interface OptionsButtonGroupStyles {
  option?: BoxProps;
  root?: BoxProps;
}

export interface IOptionsButtonGroup {
  items: OptionItem[];
  value?: OptionItem;
  styles?: OptionsButtonGroupStyles;
  onClick?(value: OptionItem, key?: string);
  keyField?: string;
  activeKey?: string;
}

export const OptionsButtonGroup: FC<IOptionsButtonGroup> = props => {
  const {
    items,
    value,
    styles,
    onClick,
    keyField = '',
    activeKey = '',
  } = props;

  const handleClick = item => {
    onClick?.(item, item[keyField]);
  };

  const isActive = item => {
    if (activeKey && item[keyField] === activeKey) {
      return { 'data-active': true };
    }
    return item.value === value?.value ? { 'data-active': true } : {};
  };

  return (
    <Flex {...optionsButtonGroupStyles.root} {...styles?.root}>
      {items.map(item => (
        <Center
          key={item[keyField] ?? item.label}
          onClick={() => handleClick(item)}
          {...isActive(item)}
          {...optionsButtonGroupStyles.option}
          {...styles?.option}
        >
          {item.label}
        </Center>
      ))}
    </Flex>
  );
};
