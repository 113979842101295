import React from 'react';

import { Icon } from '@chakra-ui/react';

export const VmwareIcon = props => (
  <Icon viewBox="0 0 190.25 31" color="#696566" {...props}>
    <g id="g12" transform="matrix(1.25, 0, 0, -1.25, -375.855, 370.03)">
      <g id="g164">
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_1_"
                x="-193.841"
                y="-199.548"
                width="956.25"
                height="1237.498"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use xlinkHref="#SVGID_1_" overflow="visible" />
            </clipPath>
            <g id="g166" clipPath="url(#SVGID_2_)">
              <g id="g172" transform="translate(388.349, 250.856)">
                <path
                  id="path174"
                  d="M-21.716,23.2l-6.571,18.298c-0.084,0.253-0.209,0.587-0.209,0.88        c0,0.793,0.67,1.546,1.63,1.546c0.838,0,1.384-0.544,1.634-1.337l5.445-15.767l5.484,15.811        c0.25,0.75,0.753,1.294,1.586,1.294h0.17c0.881,0,1.425-0.544,1.675-1.294l5.439-15.811l5.531,15.85        c0.209,0.673,0.666,1.255,1.548,1.255c0.92,0,1.591-0.708,1.591-1.505c0-0.292-0.127-0.625-0.209-0.88L-3.585,23.2        c-0.338-0.958-1.005-1.461-1.76-1.461H-5.47c-0.838,0-1.467,0.503-1.762,1.461l-5.397,15.549L-18.073,23.2        c-0.293-0.958-0.923-1.461-1.758-1.461h-0.088C-20.712,21.739-21.379,22.242-21.716,23.2"
                />
              </g>
              <g id="g176" transform="translate(436.249, 269.985)">
                <path
                  id="path178"
                  d="M-21.717,23.2c0,0.883,0.712,1.637,1.591,1.637c0.927,0,1.637-0.713,1.637-1.637v-3.713        c1.592,3.606,5.123,5.308,7.463,5.308c0.969,0,1.598-0.712,1.598-1.634c0-0.882-0.584-1.508-1.43-1.634        c-4.195-0.503-7.63-3.65-7.63-9.9V4.274c0-0.881-0.67-1.634-1.592-1.634c-0.923,0-1.636,0.716-1.636,1.634L-21.717,23.2        L-21.717,23.2z"
                />
              </g>
              <g id="g180" transform="translate(458.898, 249.221)">
                <path
                  id="path182"
                  d="M-21.716,23.2c-5.837,0-10.603,4.527-10.603,11.166v0.086        c0,6.183,4.316,11.164,10.192,11.164c6.283,0,9.861-5.184,9.861-10.873c0-0.874-0.705-1.499-1.481-1.499h-15.362        c0.448-4.645,3.692-7.259,7.477-7.259c2.631,0,4.556,1.038,6.12,2.491c0.247,0.205,0.534,0.371,0.906,0.371        c0.778,0,1.397-0.623,1.397-1.373c0-0.372-0.164-0.747-0.497-1.034C-15.717,24.491-18.103,23.2-21.716,23.2 M-15.43,35.611        c-0.33,3.905-2.55,7.305-6.783,7.305c-3.698,0-6.492-3.111-6.898-7.305H-15.43z"
                />
              </g>
              <g id="g184" transform="translate(413.36, 255.941)">
                <path
                  id="path186"
                  d="M-21.717,23.2v0.084c0,4.556,3.764,6.984,9.239,6.984c2.761,0,4.725-0.376,6.652-0.923        v0.756c0,3.885-2.39,5.895-6.441,5.895c-2.175,0-3.441-0.277-5.033-0.986c-0.206-0.084-0.417-0.127-0.58-0.127        c-0.753,0-1.425,0.629-1.425,1.38c0,0.667,0.291,1.08,0.88,1.341c2.129,0.924,3.734,1.231,6.492,1.231        c3.05,0,5.392-0.792,6.978-2.382c1.466-1.463,2.219-3.553,2.219-6.314V18.211c0-0.917-0.669-1.588-1.552-1.588        c-0.916,0-1.537,0.67-1.537,1.505v2.062c-1.508-1.969-4.019-3.722-7.816-3.722C-17.657,16.471-21.717,18.768-21.717,23.2         M-5.785,24.873v2.091c-1.592,0.459-3.727,0.92-6.359,0.92c-4.05,0-6.311-1.758-6.311-4.473v-0.083        c0-2.717,2.506-4.306,5.436-4.306C-9.049,19.022-5.785,21.448-5.785,24.873"
                />
              </g>
              <g id="g188" transform="translate(329.779, 270.223)">
                <path
                  id="path190"
                  d="M-21.716,23.2c-0.708,1.552-2.458,2.255-4.092,1.529        c-1.636-0.727-2.238-2.541-1.499-4.094l6.861-14.913c1.079-2.337,2.216-3.562,4.35-3.562c2.28,0,3.274,1.334,4.352,3.562        c0,0,5.984,13.03,6.044,13.166c0.061,0.138,0.253,0.559,0.862,0.555c0.515-0.003,0.948-0.414,0.948-0.966V5.735        c0-1.964,1.089-3.575,3.185-3.575c2.094,0,3.226,1.611,3.226,3.575v10.427c0,2.011,1.441,3.316,3.405,3.316        s3.271-1.352,3.271-3.316V5.735c0-1.964,1.093-3.575,3.185-3.575c2.094,0,3.232,1.611,3.232,3.575v10.427        c0,2.011,1.435,3.316,3.4,3.316c1.961,0,3.273-1.352,3.273-3.316V5.735c0-1.964,1.092-3.575,3.186-3.575        c2.093,0,3.229,1.611,3.229,3.575v11.867c0,4.361-3.507,7.416-7.727,7.416c-4.215,0-6.855-2.916-6.855-2.916        c-1.403,1.819-3.337,2.912-6.61,2.912c-3.454,0-6.477-2.912-6.477-2.912c-1.404,1.819-3.794,2.912-5.773,2.912        c-3.061,0-5.492-1.346-6.975-4.737l-4.381-10.327L-21.716,23.2z"
                />
              </g>
              <g id="g192" transform="translate(468.609, 268.931)">
                <path
                  id="path194"
                  d="M-21.716,23.2v0.018c0,1.459,1.184,2.688,2.672,2.688c1.509,0,2.677-1.216,2.677-2.677        v-0.011c0-1.464-1.181-2.693-2.677-2.693C-20.553,20.525-21.716,21.737-21.716,23.2 M-16.881,23.218v0.007        c0,1.2-0.927,2.184-2.163,2.184c-1.225,0-2.166-1.001-2.166-2.191v-0.014c0-1.197,0.93-2.181,2.166-2.181        C-17.823,21.023-16.881,22.022-16.881,23.218"
                />
              </g>
              <g id="g196" transform="translate(470.257, 270.007)">
                <path
                  id="path198"
                  d="M-21.715,23.2c0,0.159,0.127,0.286,0.288,0.286h0.892c0.336,0,0.595-0.095,0.756-0.265        c0.147-0.143,0.227-0.35,0.227-0.59v-0.008c0-0.408-0.206-0.657-0.517-0.788l0.394-0.48c0.055-0.072,0.092-0.131,0.092-0.219        c0-0.158-0.134-0.262-0.27-0.262c-0.127,0-0.213,0.065-0.28,0.147l-0.555,0.702h-0.455v-0.564        c0-0.159-0.123-0.284-0.283-0.284c-0.161,0-0.288,0.126-0.288,0.284V23.2z M-20.567,22.228c0.278,0,0.431,0.145,0.431,0.366        v0.009c0,0.241-0.164,0.371-0.441,0.371h-0.569v-0.745H-20.567z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
