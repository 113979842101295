import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.userProfile || initialState;

export const selectUserProfile = createSelector(
  [selectDomain],
  userProfileState => userProfileState,
);
