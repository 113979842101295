import { customTheme } from '../../../../../theme';

import { ICardStyles } from './index';

export const defaultCardStyle: ICardStyles = {
  card: {
    px: 4,
    pb: 4,
    w: 'full',
    h: 'full',
    position: 'relative',
    border: '2px solid',
    borderColor: 'transparent',
    borderWidth: '1px',
    rounded: customTheme.radii.cardBorderRadius,
    direction: 'column' as any,
    bg: 'cardBg',
  },
  header: {
    //fontSize: 'xl',
    pt: 4,
    pos: 'sticky',
    top: 0,
    zIndex: 10,
    bg: 'cardBg',
    pb: 4,
  },
  cardContent: { flex: 1, m: 0 },
};
