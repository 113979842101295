import React, { FC } from 'react';

import { NotificationFor } from '@ariksa/notification';

import { CreateNotification } from 'containers/Findings/Alerts/Components/CreateNotification';
import { CreateTicket } from 'containers/Findings/Alerts/Components/CreateTicket';

interface IAlertAction {
  alert: any;
  action: string;
  notification_for: NotificationFor;
  isOpen: boolean;

  onCreate?(data: any, alert);

  onClose();
}

export const NotificationActions: FC<IAlertAction> = props => {
  const { action } = props;
  console.log(props.alert);
  return (
    <>
      {action === 'ticket' && <CreateTicket {...props} />}
      {action === 'notification' && <CreateNotification {...props} />}
    </>
  );
};
