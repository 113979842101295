import React, { useState } from 'react';

import { Box, Flex, HStack } from '@chakra-ui/react';
import { map, truncate } from 'lodash';

import { Tag } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { DownIcon, UpIcon } from 'components/Icons';
import { MetaGridField } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataField/MetaGridField';

export const Tags = (metadata: Record<string, any>) => {
  const [showMore, setShowMore] = useState(true);
  const toggleShowMore = () => setShowMore(!showMore);

  const tagStyles = {
    tag: {
      mr: 1,
      mb: 1,
      fontSize: 'sm',
    },
  };

  const tag = index => {
    const key = metadata?.tags[index]?.Key ?? metadata?.tags[index]?.key;
    const value = metadata?.tags[index]?.Value ?? metadata?.tags[index]?.value;

    const label = key + ': ' + value;
    return (
      <Box onClick={toggleShowMore}>
        <CustomTooltip label={label}>
          <Tag
            label={
              showMore
                ? truncate(label, { length: 32, separator: '...' })
                : label
            }
            styles={tagStyles}
          />
        </CustomTooltip>
      </Box>
    );
  };

  const tags = () => (
    <Box fontSize={12}>
      {showMore ? (
        <Flex>
          {!!metadata?.tags?.length && tag(0)}
          {metadata?.tags?.length > 1 && tag(1)}
          {metadata?.tags?.length > 2 && tag(2)}
          <Box onClick={toggleShowMore} cursor="pointer">
            {metadata?.tags?.length > 3 && (
              <Tag
                label={'+ ' + (metadata?.tags?.length - 3) + ' more'}
                styles={tagStyles}
              />
            )}
          </Box>
        </Flex>
      ) : (
        map(metadata?.tags, (o, index) => tag(index))
      )}
    </Box>
  );

  return (
    <MetaGridField
      label={
        <HStack onClick={toggleShowMore} cursor="pointer">
          <Box>{metadata.display_name ?? 'Tags'}</Box>
          {!!metadata?.tags?.length && (
            <Box pt={1}>{showMore ? <DownIcon /> : <UpIcon />}</Box>
          )}
        </HStack>
      }
      value={<Box>{!!metadata?.tags?.length ? tags() : 'None'}</Box>}
      custom
      colSpan={4}
    />
  );
};
