import React, { FC, ReactNode } from 'react';

import { Outlet } from 'react-router-dom';

import { useUserPermissionsContext } from './UserPermissionsContext';

interface IFilterRouter {
  isLoading?: (() => boolean) | boolean;
  component?: ReactNode;
  path?: string;
  exact?: boolean;
  show?: boolean;
}

export const FilterRouter: FC<IFilterRouter> = props => {
  const { path = '', show = true } = props;

  const { userPermission } = useUserPermissionsContext();
  // console.log(path);

  if (!userPermission.allowedRoute(path) || !show) {
    // console.log(path, null);
    return <></>;
  }

  return <Outlet />;

  /*return <Route {...props} element={component} key={path} />;*/
};
