import React, { FC, useEffect, useRef, useState } from 'react';

import { ResourceMapping } from '@ariksa/inventory-core/api';
import {
  Box,
  Stack,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
  Center,
} from '@chakra-ui/react';
import { forEach, map, sum } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { StackedHeader, WithResourceIcon } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import { PermissionsActionsIcon } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectApp } from 'containers/App/selectors';
import { selectSecurityGraph } from 'containers/Visibility/SecurityGraph/selectors';
import { actions } from 'containers/Visibility/SecurityGraph/slice';

interface Props {
  onClose();
  isOpen: boolean;
}

export const PermissionsModal: FC<Props> = props => {
  const { onClose, isOpen } = props;
  const { sourceNodeInfo, accessPermissions } = useSelector(
    selectSecurityGraph,
  );
  const { cloudAccounts: accounts } = useSelector(selectApp);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const [data, setData] = useState<Record<string, any>>({});
  const [accountMap, setAccountMap] = useState<Record<string, any>>({});

  const [type, setType] = useState<ResourceMapping>({} as ResourceMapping);
  const { getResourceType, getResourceAlias } = useResourceType();

  useEffect(() => {
    setType(
      getResourceType(sourceNodeInfo?.resourceType) || ({} as ResourceMapping),
    );
  }, [getResourceType, sourceNodeInfo]);

  useEffect(() => {
    const accountMapping: Record<string, any> = {};
    forEach(accounts.data, o => (accountMapping[o.cloud_account_id!] = o.uuid));
    setAccountMap(accountMapping);
  }, [accounts.data]);

  // load resource data only when the drawer is opened
  useEffect(() => {
    const account = accountMap[sourceNodeInfo.cloud_account_id];
    !!account &&
      dispatch(
        actions.getPermissions({
          q: {
            actionsRequest: {
              uuids: [sourceNodeInfo.uuid],
              account_id: account,
              native_type: sourceNodeInfo.resourceType,
            },
          },
        }),
      );
  }, [sourceNodeInfo, dispatch, accountMap]);

  useEffect(() => {
    const options: Record<string, any> = {};
    forEach(
      accessPermissions.data,
      o => (options[o.crud_level ?? ''] = o.permissions),
    );
    setData(options);
  }, [accessPermissions.data]);

  const renderPermission = permissionType => {
    const permissions = data[permissionType?.toLowerCase()];
    const count = sum(map(permissions, o => o.permissions?.length));

    return (
      <AccordionItem border="none" py={2} px={0} w="full">
        <AccordionButton
          fontSize="sm"
          fontWeight={600}
          _active={{}}
          _hover={{ cursor: 'pointer' }}
          p={0}
        >
          <HStack
            justify="space-between"
            border="1px solid"
            borderRadius={6}
            borderColor={customTheme.colors.gray['100']}
            p={2}
            w="full"
          >
            <HStack>
              <Box
                color="primary"
                as={PermissionsActionsIcon}
                boxSize={4}
              ></Box>
              <Box alignItems="center">
                {permissionType}: {count}
              </Box>
            </HStack>
            <AccordionIcon />
          </HStack>
        </AccordionButton>

        <AccordionPanel px={0}>
          <Box
            bg={customTheme.colors.gray['50']}
            px={3}
            py={2}
            fontSize="xs"
            borderRadius={6}
          >
            <Grid templateColumns="repeat(5, 1fr)" gap={6}>
              <GridItem colSpan={2}>RESOURCE TYPE</GridItem>
              <GridItem colSpan={3}>PERMISSION</GridItem>
            </Grid>
          </Box>
          <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={4} px={2}>
            {map(permissions, o =>
              map(o.permissions, p => (
                <>
                  <GridItem colSpan={2}>
                    <WithResourceIcon
                      resourceType={o.service}
                      iconFilled={false}
                      iconStyles={{ w: 4, h: 4, color: 'primary' }}
                      spacing={2}
                    >
                      <Center pb={1}>{getResourceAlias(o.service)}</Center>
                    </WithResourceIcon>
                  </GridItem>
                  <GridItem colSpan={3}>{p}</GridItem>
                </>
              )),
            )}
          </Grid>
        </AccordionPanel>
      </AccordionItem>
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      header={
        <StackedHeader
          upper={sourceNodeInfo?.name || sourceNodeInfo?.resource_id}
          lower={`${type.cloud_native_name} / ${type.cloud_agnostic_name}`}
          type={sourceNodeInfo?.resourceType}
        />
      }
      body={
        <WithSpinner loadStatus={{ loading: accessPermissions.isLoading }}>
          <Stack h="full" w="full" pt={3}>
            <Box>
              Showing total access permissions for this user from all attached
              policies
            </Box>
            <Accordion allowMultiple allowToggle>
              {map(
                [
                  'Create',
                  'Read',
                  'Update',
                  'Delete',
                  'Tagging',
                  'Permissions management',
                ],
                o => renderPermission(o),
              )}
            </Accordion>
          </Stack>
        </WithSpinner>
      }
      closeButton
      styles={{
        content: { maxW: '2xl' },
        drawer: { portalProps: { containerRef: ref as any } },
      }}
    />
  );
};
