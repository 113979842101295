import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CertificateIcon = props => (
  <Icon
    viewBox="0 0 512 512"
    w="full"
    h="full"
    color="inherit"
    fillRule="evenodd"
    clipRule="evenodd"
    {...props}
  >
    <g>
      <path
        d="M140.8,25.6c-21.2,0-38.4,17.2-38.4,38.4v332.8c0,21.2,17.2,38.4,38.4,38.4h128v25.6h-128
		c-35.3,0-64-28.7-64-64V64c0-35.3,28.7-64,64-64h230.4c35.3,0,64,28.7,64,64v332.8c0,35.3-28.7,64-64,64h-25.6v-25.6h25.6
		c21.2,0,38.4-17.2,38.4-38.4V64c0-21.2-17.2-38.4-38.4-38.4H140.8z"
      />
      <path
        d="M179.2,89.6c0-7.1,5.7-12.8,12.8-12.8h128c7.1,0,12.8,5.7,12.8,12.8c0,7.1-5.7,12.8-12.8,12.8H192
		C184.9,102.4,179.2,96.7,179.2,89.6z"
      />
      <path
        d="M128,166.4c0-7.1,5.7-12.8,12.8-12.8h230.4c7.1,0,12.8,5.7,12.8,12.8c0,7.1-5.7,12.8-12.8,12.8H140.8
		C133.7,179.2,128,173.5,128,166.4z"
      />
      <path
        d="M128,217.6c0-7.1,5.7-12.8,12.8-12.8h230.4c7.1,0,12.8,5.7,12.8,12.8c0,7.1-5.7,12.8-12.8,12.8H140.8
		C133.7,230.4,128,224.7,128,217.6z"
      />
      <path
        d="M128,268.8c0-7.1,5.7-12.8,12.8-12.8h230.4c7.1,0,12.8,5.7,12.8,12.8c0,7.1-5.7,12.8-12.8,12.8H140.8
		C133.7,281.6,128,275.9,128,268.8z"
      />
      <path
        d="M307.2,409.6c14.1,0,25.6-11.5,25.6-25.6s-11.5-25.6-25.6-25.6c-14.1,0-25.6,11.5-25.6,25.6
		S293.1,409.6,307.2,409.6z M307.2,435.2c28.3,0,51.2-22.9,51.2-51.2c0-28.3-22.9-51.2-51.2-51.2S256,355.7,256,384
		C256,412.3,278.9,435.2,307.2,435.2z"
      />
      <path d="M307.2,466.9l12.8,5.7v-37.4h-25.6v37.4L307.2,466.9z M268.8,512V409.6h76.8V512l-38.4-17.1L268.8,512z" />
    </g>
  </Icon>
);
