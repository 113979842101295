/**
 *
 * Blueprint card
 *
 */
import React, { BaseSyntheticEvent, FC, useEffect } from 'react';

import { BluePrintsRead } from '@ariksa/compliance-policies/api';
import { Avatar, Box, HStack, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { H2 } from 'components/DataDisplay';
import { Card, CardProps } from 'components/DataDisplay/Card';
import { PrimaryIconButton } from 'components/DataEntry';
import {
  ConfigurationIcon,
  CopyIcon,
  OptionsIcon,
  PolicyIcon,
  TrashIcon,
} from 'components/Icons';
import { DeleteConfirmationModal, Menu } from 'components/Overlay';
import { renderCustomTag } from 'containers/PolicyHub/Blueprints/Components/utils';
import { Configure } from 'containers/PolicyHub/Blueprints/Components/Utils/Configure';
import { CopyBlueprint } from 'containers/PolicyHub/Blueprints/Components/Utils/CopyBlueprint';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

import { BlueprintCardFooter } from './BlueprintCardFooter';

interface IBlueprintCard extends CardProps {
  data: BluePrintsRead;
  onClick?: (record: object, e?: BaseSyntheticEvent) => void;
}

export const BlueprintCard: FC<IBlueprintCard> = props => {
  const { data, title, description, image, imageAlt, onClick, icon } = props;
  const copyBlueprint = useDisclosure();
  const updateAlerting = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const dispatch = useDispatch();
  const { blueprintAction } = useSelector(selectBlueprints);

  useEffect(() => {
    updateAlerting.isOpen &&
      dispatch(actions.getBlueprintByID({ q: { blueprintId: data.id } }));
  }, [data, dispatch, updateAlerting.isOpen]);

  useEffect(() => {
    updateAlerting.isOpen &&
      dispatch(
        actions.getAttachedWorkflows({
          q: { policyId: data?.id },
        }),
      );
  }, [dispatch, data, updateAlerting.isOpen]);

  const menuItems = [
    {
      key: 'Configure',
      label: 'Configure',
      icon: <ConfigurationIcon />,
      onClick: e => {
        e.stopPropagation();
        updateAlerting.onOpen();
      },
    },
    {
      key: 'Edit',
      label: 'Edit policies',
      icon: <PolicyIcon />,
      isDisabled: data.created_by === 'system',
      onClick: e => {
        e.stopPropagation();
        onClick?.(data);
      },
    },
    {
      key: 'Copy blueprint',
      label: 'Copy blueprint',
      icon: <CopyIcon />,
      onClick: e => {
        e.stopPropagation();
        copyBlueprint.onOpen();
      },
    },
    {
      key: 'Delete',
      label: 'Delete blueprint',
      icon: <TrashIcon color="red" />,
      isDisabled: data.created_by === 'system',
      onClick: e => {
        e.stopPropagation();
        deleteDisclosure.onOpen();
      },
    },
  ];

  const handleDelete = () => {
    dispatch(
      actions.deleteBlueprint({
        q: { blueprintId: data?.id },
        onSuccess: () => {
          deleteDisclosure.onClose();
          dispatch(actions.getBlueprints({ q: {} }));
        },
      }),
    );
  };

  return (
    <Card
      onClick={onClick}
      record={data}
      styles={{
        header: {
          p: 0,
        },

        card: {
          border: '1px solid',
          borderColor: customTheme.colors.gray['200'],
          h: 56,
          _hover: {
            cursor: 'pointer',
            //boxShadow: `0 0 0 1px ${customTheme.colors.primary}`,
            borderColor: 'primary',
          },
        },
      }}
      headerComponent={
        <HStack
          justify="space-between"
          w="full"
          overflow="scroll"
          borderBottom="1px solid"
          borderColor="primary"
        >
          <HStack py={4} spacing={4}>
            <Avatar
              src={image}
              name={imageAlt}
              icon={icon}
              bg="cardBg"
              size="sm"
            />
            <H2 fontSize="sm">{title}</H2>
            {renderCustomTag(data.created_by)}
          </HStack>
          <Menu
            menuItems={menuItems}
            buttonLabel={
              <PrimaryIconButton
                aria-label="actions"
                size="xs"
                borderRadius="full"
                p={1}
                bg={customTheme.colors.gray['50']}
                border="none"
              >
                <OptionsIcon />
              </PrimaryIconButton>
            }
          />
        </HStack>
      }
      contentComponent={
        <Box w="full">
          <Box
            py={2}
            fontSize="sm"
            color={customTheme.colors.gray['700']}
            h="90px"
            overflow="hidden"
          >
            {description}
          </Box>
          {copyBlueprint.isOpen && (
            <CopyBlueprint
              isOpen={copyBlueprint.isOpen}
              onClose={copyBlueprint.onClose}
              selectedBlueprint={data}
            />
          )}
          {updateAlerting.isOpen && (
            <Configure
              isOpen={updateAlerting.isOpen}
              onClose={updateAlerting.onClose}
              onSuccess={() => {
                dispatch(
                  actions.getBlueprintByID({ q: { blueprintId: data.id } }),
                );
                dispatch(
                  actions.getAttachedWorkflows({ q: { policyId: data.id } }),
                );
              }}
            />
          )}
          {deleteDisclosure.isOpen && (
            <DeleteConfirmationModal
              {...deleteDisclosure}
              onConfirm={handleDelete}
              name={data?.name}
              type={'blueprint'}
              isLoading={blueprintAction.isLoading}
            />
          )}
        </Box>
      }
      footerComponent={
        <Box>
          <BlueprintCardFooter data={data} />
        </Box>
      }
    />
  );
};
