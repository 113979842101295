import React, { FC, useEffect, useReducer, useRef } from 'react';

import { Box, Center, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { DetailsIdentifier, StackedHeader } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Select } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { IconTypes } from 'components/Icons';
import { Drawer } from 'components/Overlay';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useDataTableColumns } from 'containers/Visibility/Data/Components/hooks/useDataTableColumns';
import { cloudOptions } from 'containers/Visibility/Data/Components/utils';
import { selectDataDashboard } from 'containers/Visibility/Data/selectors';
import { actions } from 'containers/Visibility/Data/slice';

interface Props {
  isOpen: boolean;
  onClose();
  currentRecord: Record<string, any>;
  type: 'type' | 'sub-type';
}

export const DocumentTypeDataSources: FC<Props> = props => {
  const { isOpen, onClose, currentRecord, type } = props;
  const { dataSourcesByType } = useSelector(selectDataDashboard);
  const { riskContext } = useSelector(selectSharedState);
  const ref = useRef(document.querySelector('.portal-container'));
  const dispatch = useDispatch();
  const {
    dataSourcesByDocTypeColumns,
    tableActionModals,
  } = useDataTableColumns(MetadataTabs.Data);
  const { accountId, environmentId } = useAccessBoundary();
  const { resourceTypeAliasOptionsWithAll } = useResourceType();
  const { getTabIndex } = useMetadataDrawerTabs();

  const { updateActiveResource } = useActiveResourceActions();
  const { onOpenMetadata } = useActiveResourceContext();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      cloudOptions: [
        { label: 'All clouds', value: undefined },
        ...cloudOptions,
      ],
      selectedCloud: { label: 'All clouds', value: undefined },
      resourceType: { label: 'All resource types', value: undefined },
      resourceTypeOptions: [],
      currentRecord: {},
      searchTerm: '',
    },
  );

  useEffect(() => {
    updateState({ resourceTypeOptions: resourceTypeAliasOptionsWithAll });
  }, [resourceTypeAliasOptionsWithAll]);

  useEffect(() => {
    const payload = {
      accountIds: !!accountId ? [accountId] : [],
      environmentId,
      page: dataSourcesByType.page.info.page_number,
      size: dataSourcesByType.page.info.page_size,
      cloud: state.selectedCloud?.value,
      resource: state.resourceType?.value,
      searchTerm: state.searchTerm || undefined,
    };
    if (type === 'type')
      dispatch(
        actions.getDataSourcesByDocumentType({
          q: {
            id: currentRecord?.type_id,
            ...payload,
          },
          /*onSuccess: res => {
          !!res?.total &&
            dispatch(
              actions.getRiskContextForDataTypes({
                q: {
                  accountId: !!accountId ? [accountId] : [],
                  environmentId: environmentId!,
                  dataType: map(res?.items, o => o.subtype_id!),
                },
              }),
            );
        },*/
        }),
      );
    else
      dispatch(
        actions.getDataSourcesByDocumentSubType({
          q: {
            id: currentRecord?.subtype_id,
            ...payload,
          },
          /*onSuccess: res => {
        !!res?.total &&
          dispatch(
            actions.getRiskContextForDataTypes({
              q: {
                accountId: !!accountId ? [accountId] : [],
                environmentId: environmentId!,
                dataType: map(res?.items, o => o.subtype_id!),
              },
            }),
          );
      },*/
        }),
      );
  }, [
    accountId,
    environmentId,
    dataSourcesByType.page.info,
    state.selectedCloud,
    state.resourceType,
    state.searchTerm,
    currentRecord,
    dispatch,
    type,
  ]);

  const styles = {
    menu: provided => ({ ...provided, width: 'max-content', minWidth: '100%' }),
  };

  return (
    <Box>
      <Drawer
        styles={{
          drawer: {
            portalProps: { containerRef: ref as any },
          },
          content: { px: 0, maxW: '1350px', h: 'full' },
        }}
        isOpen={isOpen}
        onClose={onClose}
        closeButton
        header={
          <StackedHeader
            upper={currentRecord?.type_name}
            lower={currentRecord?.subtype_name}
            type={IconTypes.Policy}
          />
        }
        body={
          <Stack h="full" pt={3} spacing={4}>
            <HStack spacing={6}>
              <DetailsIdentifier
                label="Description"
                value={
                  <Stack>
                    <Text>
                      Ariksa has classified this{' '}
                      {type === 'sub-type' ? currentRecord?.type_name : ''}{' '}
                      document as being a{' '}
                      {type === 'type'
                        ? currentRecord?.type_name
                        : currentRecord?.subtype_name}
                      .
                    </Text>
                    <Text>
                      We have detected{' '}
                      {currentRecord?.count +
                        ' ' +
                        (type === 'type'
                          ? currentRecord?.type_name
                          : currentRecord?.subtype_name)}{' '}
                      documents.
                    </Text>
                  </Stack>
                }
                direction="column"
                w="55%"
                pb={1}
                styles={{ label: { color: 'black' } }}
              />
              <Divider orientation="vertical" borderColor="gray.200" />
              <DetailsIdentifier
                label="More information"
                value={
                  <Center h="full" w="full">
                    No details
                  </Center>
                }
                direction="column"
                h="full"
                w="45%"
                pb={1}
                styles={{ label: { color: 'black' } }}
              />
            </HStack>
            <Divider borderColor="primary" />
            <HStack justify="space-between">
              <Box w={96}>
                <SearchBox onChange={s => updateState({ searchTerm: s })} />
              </Box>
              <HStack>
                <Box color="primary">FILTERS:</Box>
                {/* <Box w={48}>
                  <Select
                    options={state.resourceTypeOptions}
                    value={state.resourceType}
                    onChange={val => updateState({ resourceType: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>*/}
                <Box w={48}>
                  <Select
                    options={state.cloudOptions}
                    value={state.selectedCloud}
                    onChange={val => updateState({ selectedCloud: val })}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    showIconInValueContainer
                    styles={styles}
                  />
                </Box>
              </HStack>
            </HStack>
            <Box flex={1}>
              <Table
                data={dataSourcesByType.data ?? []}
                isError={dataSourcesByType.isError}
                columns={dataSourcesByDocTypeColumns}
                isLoading={dataSourcesByType.isLoading}
                styles={{ header: { position: 'relative', zIndex: 800 } }}
                onRowClick={row => {
                  const tabIndex = getTabIndex(
                    row?.resource,
                    MetadataTabs.Alerts,
                  );
                  updateActiveResource({
                    resourceType: row?.source_type,
                    uuid: row.source_id,
                    accountId: row.account,
                    //resourceId: row.resource_id,
                  });
                  onOpenMetadata(tabIndex);
                }}
                pagination={{
                  pageInfo: dataSourcesByType.page.info,
                  onChange: info =>
                    dispatch(actions.updateDataSourcesByTypePageInfo(info)),
                  totalCount: dataSourcesByType.page.totalCount,
                }}
              />
            </Box>
            {tableActionModals}
          </Stack>
        }
      />
    </Box>
  );
};
