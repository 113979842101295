import React from 'react';

import { Icon } from '@chakra-ui/react';

export const ElasticIPIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M171.5,119.4h39.2v172.8h-39.2V119.4z M297.3,118.1c-23.8,0-40.7,1.5-52.8,3.6v170.4h38.7v-61.8
	c3.6,0.5,8.2,0.8,13.3,0.8c23.1,0,42.8-5.7,56.1-18.2c10.2-9.8,15.9-24.1,15.9-41c0-16.9-7.5-31.2-18.4-40
	C338.6,122.7,321.4,118.1,297.3,118.1z M296.3,201.2c-5.6,0-9.7-0.3-13.1-1v-51c2.8-0.8,8.2-1.5,16.2-1.5c19.5,0,30.5,9.5,30.5,25.4
	C329.9,190.7,317.1,201.2,296.3,201.2z M256,0C139.8,0,45.5,94.2,45.5,210.4S256,512,256,512s210.5-185.3,210.5-301.5S372.2,0,256,0
	z M256.5,338.4c-77.2,0-139.7-62.6-139.7-139.8c0-77.2,62.6-139.7,139.7-139.7c77.2,0,139.7,62.6,139.7,139.7
	C396.3,275.8,333.7,338.4,256.5,338.4z"
    />
  </Icon>
);
