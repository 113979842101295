import React, { memo, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Stack,
  Center,
} from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { getIcon } from 'components/Icons/Components';

import { defaultStyles } from './styles';
import { CustomTabsProps } from './types';

export const CustomTabs = memo((props: CustomTabsProps) => {
  const {
    items,
    styles,
    overwriteDefaultStyles,
    defaultIndex,
    currentIndex,
    componentHeader,
    onChange,
    className,
  } = props;

  const [currentTabIndex, setCurrentTabIndex] = useState(
    defaultIndex ?? currentIndex ?? 0,
  );

  useEffect(() => {
    setCurrentTabIndex(defaultIndex ?? currentIndex ?? 0);
  }, [currentIndex, defaultIndex]);

  const baseStyles = useMemo(() => {
    const props = cloneDeep(defaultStyles);
    return overwriteDefaultStyles ? styles : merge(props, styles);
  }, [styles, overwriteDefaultStyles]);

  const handleOnchange = (index: number) => {
    onChange?.(index);
    setCurrentTabIndex(index);
  };

  return (
    <Tabs
      isLazy
      {...baseStyles?.tabs}
      index={currentTabIndex}
      defaultIndex={defaultIndex}
      onChange={handleOnchange}
    >
      <TabList {...baseStyles?.tabList} pos={'relative'} className={className}>
        {/* <Stack isInline justify="space-between" w="full" h="full"> */}
        {/* <Stack isInline> */}
        {items.map(each => (
          <Tab
            key={`${each.key}-tab`}
            {...baseStyles?.tab}
            isDisabled={each.isDisabled}
          >
            <Stack spacing={1}>
              {(each?.icon || each?.iconType) && (
                <Center color="primary">
                  <Box boxSize={5}>
                    {!!each?.iconType ? getIcon(each?.iconType) : each?.icon}
                  </Box>
                </Center>
              )}
              <Center>{each.title}</Center>
            </Stack>
          </Tab>
        ))}
        {/* </Stack> */}
        {/* {rightComponent && (
            <Box py={3} px={4}>
              {rightComponent}
            </Box>
          )} */}
        {/* </Stack> */}
        {componentHeader}
      </TabList>

      <TabPanels flex={1} {...baseStyles?.tabPanels}>
        {props.items.map(each => (
          <TabPanel
            key={`${each.key}-tab-panel`}
            pos={'relative'}
            {...baseStyles?.tabPanel}
            h={'full'}
          >
            <Box h={'full'}>{each.component}</Box>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
});
