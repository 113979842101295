import React from 'react';

import { Box, Flex, Stack, HStack } from '@chakra-ui/react';

export const formatTooltip = ({ header, content, width = 32, spacing = 2 }) => {
  return (
    <Stack w={width}>
      <Box
        borderBottom="1px solid"
        borderColor="orange"
        color="orange"
        pb={spacing}
        w="full"
        textAlign="left"
      >
        {header}
      </Box>
      <Box w="full" textAlign="left">
        {content}
      </Box>
    </Stack>
  );
};

export const renderTooltipFieldValue = (field: string, value: any) => {
  return (
    <>
      <HStack align="flex-start" spacing={1}>
        <Flex color="orange" display="inline-block" whiteSpace="nowrap">
          {field}:
        </Flex>
        <Flex display="inline-block">{value}</Flex>
      </HStack>
    </>
  );
};
