import { ReactNode } from 'react';

import { Box, HStack, Square, Stack, Text } from '@chakra-ui/react';
import { entries } from 'lodash';
import { parse } from 'marked';
import HTMLRenderer from 'react-html-renderer';
import { useNavigate } from 'react-router-dom';

import { Tag } from 'components/DataDisplay';
import { MonitoringIcon } from 'components/Icons';
import { AriksaLogoOnlyIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoOnlyIcon';
import { securityGraphRedirectUrl } from 'containers/Visibility/SecurityGraphNext/utils/securityGraphRedirectUrl';

interface BotResponseProps {
  item: {
    message: string;
    query?: string;
    wrapper?: Object;
    metadata?: {
      ai_search?: string;
      generated_query?: string;
    };
  };
}

export const BotResponse = (props: BotResponseProps) => {
  const htmlString = parse(props.item.message);
  const { metadata, message, query } = props.item;
  const navigate = useNavigate();

  // console.log(props.item);

  const sanitizeQuery = (query: string) => {
    return entries({
      SHOW: 'Show',
      THAT: 'That',
    }).reduce((acc, [k, v]) => {
      return acc?.replace(k, v);
    }, query);
  };

  return (
    <Stack
      bg={'#fff'}
      borderRadius={6}
      p={6}
      px={6}
      spacing={6}
      boxShadow={'0 1px 2px rgba(0, 0, 0, 0.1)'}
    >
      <HStack>
        <Square size={6}>
          <AriksaLogoOnlyIcon />
        </Square>
        <Box>ASSISTANT</Box>
      </HStack>
      <Stack spacing={4}>
        <Text fontWeight={'bold'} fontSize={'md'}>
          {sanitizeQuery(props.item.query ?? '')}
        </Text>
        <Stack px={4} fontSize={'md'}>
          <HTMLRenderer html={htmlString} />
        </Stack>

        <HStack spacing={6} pt={4}>
          {/*<ResponseTag*/}
          {/*  label={'Findings'}*/}
          {/*  leftIcon={<ExclamationTriangleIcon color={'red'} />}*/}
          {/*/>*/}
          {/*<ResponseTag*/}
          {/*  label={'Inventory'}*/}
          {/*  leftIcon={<ServicesIcon color={'primary'} />}*/}
          {/*/>*/}
          {metadata?.generated_query && (
            <ResponseTag
              label={'Investigate'}
              leftIcon={<MonitoringIcon />}
              onClick={() => {
                const url = securityGraphRedirectUrl({
                  query: metadata?.generated_query,
                  redirected_url: '/home/ai-assistant',
                  investigate: true,
                  ai_search: metadata?.ai_search,
                });

                navigate(url, {
                  wrapper: props.item.wrapper,
                });
              }}
            />
          )}
          {/*<ResponseTag*/}
          {/*  label={'Remediate'}*/}
          {/*  leftIcon={<RemediateIcon color={'green'} size={'lg'} />}*/}
          {/*/>*/}
        </HStack>
      </Stack>
    </Stack>
  );
};

const ResponseTag = (props: {
  label: string;
  leftIcon?: ReactNode;
  onClick: Function;
}) => {
  const leftIcon = props.leftIcon ? props.leftIcon : null;
  const onClick = props.onClick;
  return (
    <Tag
      label={props.label}
      styles={{
        tag: {
          cursor: 'pointer',
          borderColor: 'rgba(93,62,219,0.4)',
          size: 'md',
          fontSize: 'sm',
        },
        leftIcon: { mr: 2 },
      }}
      leftIcon={leftIcon}
      onClick={() => {
        onClick?.();
      }}
    />
  );
};
