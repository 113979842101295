import React, { FC, useEffect, useRef, useState } from 'react';

import { ResourceMapping } from '@ariksa/inventory-core/api';
import { Stack, Box, HStack, Divider } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  formatNumberWithComma,
  StackedCell,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { Drawer } from 'components/Overlay';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { AddException } from 'containers/Compliance/Components/AddException';
import {
  renderPassedChecks,
  renderTableHeaderWithTooltip,
  renderViolations,
} from 'containers/Compliance/Components/ComplianceTables/utils';
import { History } from 'containers/Compliance/Components/History';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

interface Props {
  row: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
}

export const ByResourceTypeSubComponent: FC<Props> = props => {
  const { row, isOpen, onClose } = props;
  const { byResourceTypeSummary, selectedBlueprint } = useSelector(
    selectCompliance,
  );
  const { toCloudAccountId } = useCloudAccountId();
  const {
    renderRulesActions,
    isOpenSeverityModal,
    isOpenSuppress,
    onCloseSuppress,
    selectedResource,
    isOpenHistory,
  } = useCompliance();

  const dispatch = useDispatch();
  const [summary, setSummary] = useState<{
    data: Record<string, any>[];
    total: number;
  }>({ data: [], total: 0 });
  const [pageInfo, setPageInfo] = useState(INIT_PAGE_INFO);
  const [type, setType] = useState<ResourceMapping>({} as ResourceMapping);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortByField, setSortByField] = useState<Sorted>({
    sortField: '',
    sortOrder: '',
  });
  const { getResourceType, getCloudAgnosticName } = useResourceType();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const ref = useRef(document.querySelector('.portal-container'));
  const { getTabIndex } = useMetadataDrawerTabs();
  const [alertTabIndex, setAlertTabIndex] = useState(0);

  useEffect(() => {
    setType(getResourceType(row?.type ?? '') || ({} as ResourceMapping));
  }, [getResourceType, row]);

  useEffect(() => {
    setAlertTabIndex(
      getTabIndex(type.cloud_native_class!, MetadataTabs.Alerts),
    );
  }, [getTabIndex, type]);

  useDeepCompareEffect(() => {
    dispatch(
      actions.getSummaryByResourceType({
        q: {
          blueprintId: selectedBlueprint?.id,
          page: pageInfo.page_number,
          size: pageInfo.page_size,
          resourceType: row?.type,
          accountId: row?.account_id,
          searchTerm,
        },
        onSuccess: res => {
          setSummary({ data: res?.items || [], total: res?.total || 0 });
        },
      }),
    );
  }, [row, dispatch, pageInfo, selectedBlueprint, searchTerm]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Entity</Box>,
      accessor: 'name',
      sortKey: 'name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row.type}
          iconTooltip={<ResourceTypeIconTooltip resourceType={row?.type} />}
        >
          <StackedCell upper={row?.name} lower={row?.cloud_resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Type',
      accessor: 'cloud_resource_id',
      align: 'left',
      render: ({ row }) => {
        const type = getResourceType(row?.type);
        return (
          <StackedCell
            upper={type?.cloud_native_name}
            lower={type?.cloud_agnostic_name}
          />
        );
      },
    },
    {
      header: 'Deployed In',
      accessor: 'account_id',
      align: 'left',
      render: ({ value }) => toCloudAccountId(value),
    },
    {
      header: renderTableHeaderWithTooltip(
        'Exceptions',
        'All exceptions applied to this entity',
        'center',
      ),
      accessor: 'check_stats.resource_exceptions',
    },
    {
      header: 'Violations',
      accessor: 'check_stats',
      align: 'left',
      render: renderViolations,
    },
    {
      header: renderTableHeaderWithTooltip(
        'Passed Policies',
        'All checks for this entity that passed',
        'left',
      ),
      accessor: 'check_stats',
      render: renderPassedChecks,
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Actions',
      accessor: 'resource_id',
      render: renderRulesActions,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        header={
          <HStack justify="space-between">
            <StackedHeader
              upper={type?.cloud_native_name}
              lower={type?.cloud_agnostic_name}
              type={type.cloud_native_class}
            />
            <Box w={96} pr={10}>
              <SearchBox
                onChange={value => setSearchTerm(value)}
                value={searchTerm}
                placeholder="Search entities, names, etc..."
              />
            </Box>
          </HStack>
        }
        styles={{
          content: { maxW: '1400px' },
          drawer: { portalProps: { containerRef: ref as any } },
        }}
        closeButton
        body={
          <Stack w="full" h="full" pt={3} spacing={5}>
            <HStack spacing={6} align="start">
              <Stack spacing={1} w="md">
                <DetailsField
                  label="Account"
                  value={toCloudAccountId(row?.account_id)}
                />
                <DetailsField
                  label="Policy Checks"
                  value={formatNumberWithComma(
                    row?.check_stats?.total_performed,
                  )}
                />
                <DetailsField
                  label="Exceptions"
                  value={formatNumberWithComma(
                    row?.check_stats?.resource_exceptions,
                  )}
                />
              </Stack>
              <Stack spacing={1}>
                <DetailsField
                  label="Violations"
                  value={renderViolations({ value: row.check_stats })}
                />
                <DetailsField
                  label="Passed Checks"
                  value={
                    formatNumberWithComma(row?.check_stats?.passed) +
                    ' / ' +
                    formatNumberWithComma(row?.check_stats?.total_performed) +
                    ' (' +
                    Number(
                      (row?.check_stats?.passed /
                        row?.check_stats?.total_performed) *
                        100,
                    ).toFixed(0) +
                    '%)'
                  }
                />
              </Stack>
            </HStack>
            <Divider color="gray.200" />
            <Table
              data={summary.data}
              columns={columns}
              cursor="pointer"
              isLoading={byResourceTypeSummary.isLoading}
              onRowClick={row => {
                updateActiveResource({
                  resourceType: row?.type,
                  uuid: row.resource_id,
                  accountId: row.account_id,
                  resourceId: row.cloud_resource_id,
                  tabIndex: alertTabIndex,
                });
                onOpenMetadata();
              }}
              onSort={sortInfo => {
                if (!isEqual(sortByField, sortInfo)) {
                  setSortByField(sortInfo);
                }
              }}
              sortBy={sortByField}
              pagination={{
                totalCount: summary.total,
                pageInfo,
                onChange: setPageInfo,
              }}
            />
          </Stack>
        }
      />
      {isOpenSeverityModal && <SeverityComponent />}
      {isOpenSuppress && (
        <AddException
          resource={selectedResource}
          isOpen={isOpenSuppress}
          onClose={onCloseSuppress}
        />
      )}
      {isOpenHistory && <History resourceType={row?.type} />}
    </>
  );
};
