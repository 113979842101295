export const TimeRange = {
  W1: '1W',
  M1: '1M',
  M3: '3M',
  Y1: '1Y',
};

export interface OptionItem {
  label: string;
  value: any;
  key?: string;
  isDisabled?: boolean;
}
