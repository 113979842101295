import React, { FC, useEffect, useReducer, useState } from 'react';

import {
  Box,
  Center,
  Text,
  Stack,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { find, forEach, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  ActionButton,
  CancelButton,
  PrimaryButton,
} from 'components/DataEntry';
import { SwitchOnOff } from 'components/Elements/SwitchIO/SwitchOnOff';
import { AutomationIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { DeleteConfirmationModal } from 'components/Overlay';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';
import { AccountsUnderEnvironment } from 'containers/Setup/Environment/Components/AccountsUnderEnvironment';

import { AttachWorkflow } from './AttachWorkflow';

interface IUpdateAccounts {
  onCancel();
  onSuccess();
}

export const UpdateConfig: FC<IUpdateAccounts> = props => {
  const dispatch = useDispatch();
  const { onCancel, onSuccess } = props;
  const {
    blueprint,
    attachEnvironment,
    blueprintAction,
    attachedWorkflows,
  } = useSelector(selectBlueprints);
  const { environments } = useSelector(selectApp);
  const detachEnv = useDisclosure();
  const attachWorkflow = useDisclosure();
  const showAccounts = useDisclosure();
  const [envAccountsMapping, setEnvAccountsMapping] = useState({});
  const { getEnvironmentAccountsMapping } = useEnvironmentOptions();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      envs: {},
      data: [],
      workflows: {},
      currentRecord: {},
      checked: false,
      disableAll: false,
      action: '',
    },
  );

  useEffect(() => {
    setEnvAccountsMapping(getEnvironmentAccountsMapping());
  }, [getEnvironmentAccountsMapping]);

  //set attached environments
  useEffect(() => {
    const options: Record<string, any> = {};
    forEach(blueprint.data?.configs, o => (options[o?.environment_id!] = o));
    updateState({ envs: options });
  }, [blueprint.data]);

  //set data
  useEffect(() => {
    updateState({
      data: map(environments.data, o => ({
        ...o,
        config: state.envs[o.id],
      })),
    });
  }, [environments.data, state.envs]);

  //set attached workflows
  useEffect(() => {
    const options: Record<string, any> = {};
    forEach(attachedWorkflows.data, o => {
      const policy = find(o.policy, p => p.policy_id === blueprint.data.id);
      forEach(policy?.environment_ids, e => {
        !!options?.[e] ? (options[e] = [...options[e], o]) : (options[e] = [o]);
      });
    });
    updateState({ workflows: options });
  }, [attachedWorkflows.data, blueprint.data]);

  const handleOnSuccess = () => {
    onSuccess();
    updateState({ disableAll: !state.disableAll, action: '' });
  };

  const handleDetachEnvironment = () => {
    dispatch(
      actions.detachEnvironment({
        q: { configId: state.currentRecord.config.id },
        onSuccess: handleOnSuccess,
      }),
    );
  };

  const handleAttachEnvironment = () => {
    dispatch(
      actions.attachEnvironment({
        q: {
          blueprintId: blueprint.data?.id!,
          blueprintConfigCreate: { environment_id: state.currentRecord.id },
        },
        onSuccess: handleOnSuccess,
      }),
    );
  };

  const toggleAlerts = (row, value, action) => {
    updateState({
      currentRecord: row,
      checked: value,
      disableAll: !state.disableAll,
      action: action === state.action ? '' : action,
    });
  };

  const handleUpdateAlerting = () => {
    dispatch(
      actions.updateAlertingConfig({
        q: {
          configId: state.currentRecord.config.id,
          blueprintConfigUpdate: {
            ...state.currentRecord?.config,
            raise_alerts: state.checked,
          },
        },
        onSuccess: handleOnSuccess,
      }),
    );
  };

  const handleOnClickOK = () => {
    if (state.action === 'attach') {
      if (state.checked) handleAttachEnvironment();
      else detachEnv.onOpen();
    } else handleUpdateAlerting();
  };

  const columns = [
    {
      header: 'Environments',
      accessor: 'name',
    },
    /*{
      header: 'cloud',
      accessor: 'cloud',
      align: 'left',
      render: ({ value }) => <Box boxSize={5}>{getIcon(value)}</Box>,
      styles: {
        width: '60px',
        cell: { maxWidth: '60px' },
      },
    },*/
    {
      header: 'Accounts',
      accessor: 'account_ids',
      render: ({ value }) => value?.length,
      styles: {
        width: '60px',
        cell: { maxWidth: '60px' },
      },
    },
    {
      header: 'Workflows',
      accessor: 'id',
      render: ({ value }) =>
        state.workflows[value]?.length ? (
          <CustomTooltip
            label={
              <Stack spacing={0}>
                <Box color="orange">Workflows</Box>
                {map(state.workflows[value], (o, index) => (
                  <Box>
                    {index + 1}. {o.name}
                  </Box>
                ))}
              </Stack>
            }
          >
            {state.workflows[value]?.length}
          </CustomTooltip>
        ) : (
          0
        ),
      styles: {
        width: '60px',
        cell: { maxWidth: '60px' },
      },
    },
    {
      header: 'Attach',
      render: ({ row }) => {
        const value = !!state.envs[row.id];
        return (
          <Center onClick={e => e.stopPropagation()}>
            <SwitchOnOff
              value={value ? 1 : 0}
              onChange={value => toggleAlerts(row, value, 'attach')}
              showNumber
              size="xs"
              isDisabled={
                (state.disableAll && row?.id !== state.currentRecord.id) ||
                state.action === 'alerting'
              }
            />
          </Center>
        );
      },
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Generate Alerts',
      accessor: 'config.raise_alerts',
      render: ({ row }) => {
        const config = row?.config;
        const value = config?.raise_alerts ?? false;
        return (
          <Center onClick={e => e.stopPropagation()}>
            <SwitchOnOff
              value={value ? 1 : 0}
              onChange={value => toggleAlerts(row, value, 'alerting')}
              showNumber
              size="xs"
              isDisabled={
                (state.disableAll && row?.id !== state.currentRecord.id) ||
                state.action === 'attach' ||
                !config
              }
            />
          </Center>
        );
      },
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Action',
      align: 'center',
      render: ({ row }) => {
        const config = row?.config;
        return (
          <Center>
            <ActionButton
              label={
                !config
                  ? 'Attach workflow is disabled. Please attach environment first.'
                  : 'Attach workflow'
              }
              icon={<AutomationIcon />}
              isDisabled={!config}
              onClick={() => {
                updateState({ currentRecord: row });
                attachWorkflow.onOpen();
              }}
            />
          </Center>
        );
      },
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
  ];

  return (
    <Stack w="full" h="full">
      <Box w="full" h="400px">
        <Table
          data={state.data}
          columns={columns}
          isLoading={blueprint.isLoading}
          onRowClick={row => {
            updateState({ currentRecord: row });
            showAccounts.onOpen();
          }}
        />
      </Box>
      <Stack>
        <Box fontSize="xs" color="primary" pb={6}>
          NOTE: Any changes to this blueprint will automatically apply to all
          attached environments and may impact alerting
        </Box>
        <HStack justifyContent={'space-between'} w="full">
          <CancelButton onClick={onCancel} label="CANCEL" />
          <PrimaryButton
            onClick={() => handleOnClickOK()}
            isLoading={attachEnvironment.isLoading || blueprint.isLoading}
            isDisabled={!state.disableAll}
          >
            SAVE
          </PrimaryButton>
          {detachEnv.isOpen && (
            <DeleteConfirmationModal
              isOpen={detachEnv.isOpen}
              onClose={detachEnv.onClose}
              label="Detach"
              onConfirm={() => handleDetachEnvironment()}
              isLoading={blueprintAction.isLoading}
              confirmationText={
                <Text>
                  Please type <b>DETACH_ENVIRONMENT</b> and detach.
                </Text>
              }
              name="DETACH_ENVIRONMENT"
              type="environment id"
            />
          )}
          {attachWorkflow.isOpen && (
            <AttachWorkflow
              isOpen={attachWorkflow.isOpen}
              onClose={attachWorkflow.onClose}
              loadData={onSuccess}
              currentRecord={state.currentRecord}
              attachedWorkflows={state.workflows[state.currentRecord.id] ?? []}
            />
          )}
          {showAccounts.isOpen && (
            <AccountsUnderEnvironment
              accounts={envAccountsMapping[state.currentRecord?.id]}
              isOpen={showAccounts.isOpen}
              onClose={showAccounts.onClose}
              environment={state.currentRecord}
            />
          )}
        </HStack>
      </Stack>
    </Stack>
  );
};
