import React, { FC, useEffect, useRef, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { flatten, map, union } from 'lodash';

import { Modal } from 'components/index';
import { getWidgetChartType } from 'containers/Reports/ReportsDashboard/Components/config';
import { ReportWidgetHeader } from 'containers/Reports/ReportsDashboard/Components/ReportWidget/ReportWidgetHeader';
import { availableWidgetMap } from 'containers/Reports/ReportsDashboard/Components/widget';
import { useReportsDashboardWidgetConfigs } from 'containers/Reports/ReportsDashboard/hooks/useReportsDashboardWidgetConfigs';

export interface IReportWidget extends BoxProps {
  onClickRemove?: () => void;
  onClickEdit: () => void;
  currentRecord: Record<string, any>;
  isLoading?: boolean;
  getReportingChartDetails(
    chart: Record<string, any>,
    index: number,
    timeRange: string,
  ): void;
}

export const ReportWidget: FC<IReportWidget> = props => {
  const {
    onClickRemove,
    onClickEdit,
    currentRecord,
    isLoading = false,
    getReportingChartDetails,
    ...rest
  } = props;
  const [accounts, setAccounts] = useState<string[]>([]);
  const { getWidgetConfig } = useReportsDashboardWidgetConfigs();
  const [expandChart, setExpandChart] = useState(false);
  const ref = useRef(document.querySelector('.portal-container'));

  useEffect(() => {
    setAccounts(union(flatten(map(currentRecord?.account_ids, o => o))));
  }, [currentRecord?.account_ids]);

  // console.log(currentRecord);

  const renderChart = expanded => {
    return (
      <Box w="full" py={3} h="full" {...rest}>
        <ReportWidgetHeader
          expandChart={expanded}
          setExpandChart={setExpandChart}
          {...props}
        />
        <Box w="full" h="full" pos="relative">
          <Box
            as={
              availableWidgetMap[getWidgetChartType(currentRecord?.chart_type)]
            }
            {...getWidgetConfig(currentRecord)}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    );
  };
  return (
    <>
      {renderChart(false)}
      {expandChart && (
        <Box>
          <Modal
            portalProps={{ containerRef: ref as any }}
            isOpen={expandChart}
            onClose={() => setExpandChart(false)}
            size="6xl"
            body={
              <Box w="full" h={'430px'} py={4}>
                <Box h={'400px'}>{renderChart(expandChart)}</Box>
              </Box>
            }
            styles={{ body: { overflow: 'visible' } }}
          />
        </Box>
      )}
    </>
  );
};
