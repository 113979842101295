import React from 'react';

import { Icon } from '@chakra-ui/react';

export const OperatingSystemIcon = props => (
  <Icon viewBox="0 0 122.88 92.44" w="full" h="full" color="inherit" {...props}>
    <g>
      <path
        className="st0"
        d="M22.24,46.25c0-6.86,1.75-12.2,5.26-16.02c3.51-3.83,8.4-5.73,14.66-5.73c6.42,0,11.37,1.88,14.84,5.63 c3.47,3.76,5.21,9.02,5.21,15.78c0,4.91-0.75,8.93-2.27,12.07c-1.52,3.14-3.72,5.59-6.58,7.34c-2.87,1.75-6.45,2.62-10.73,2.62 c-4.35,0-7.95-0.75-10.8-2.26c-2.85-1.52-5.16-3.9-6.93-7.17C23.13,55.25,22.24,51.16,22.24,46.25L22.24,46.25z M34.15,46.28 c0,4.24,0.73,7.29,2.17,9.14c1.45,1.85,3.43,2.78,5.93,2.78c2.56,0,4.55-0.91,5.95-2.72c1.41-1.82,2.11-5.07,2.11-9.78 c0-3.95-0.74-6.84-2.2-8.66c-1.47-1.83-3.46-2.74-5.97-2.74c-2.41,0-4.35,0.93-5.8,2.78C34.87,38.93,34.15,42,34.15,46.28 L34.15,46.28z M65.68,53.33l11.31-0.77c0.24,2,0.74,3.52,1.5,4.56c1.23,1.69,2.98,2.54,5.26,2.54c1.7,0,3.01-0.43,3.92-1.3 c0.92-0.87,1.38-1.88,1.38-3.02c0-1.08-0.43-2.06-1.3-2.92c-0.87-0.86-2.9-1.66-6.09-2.44c-5.22-1.27-8.94-2.98-11.17-5.1 c-2.25-2.11-3.37-4.82-3.37-8.1c0-2.15,0.57-4.19,1.72-6.11c1.15-1.93,2.87-3.43,5.18-4.53c2.31-1.1,5.47-1.64,9.48-1.64 c4.93,0,8.68,1,11.27,3c2.59,2,4.12,5.18,4.62,9.54L88.2,37.76c-0.3-1.91-0.93-3.3-1.88-4.16c-0.96-0.87-2.28-1.3-3.96-1.3 c-1.38,0-2.43,0.32-3.13,0.96c-0.7,0.64-1.05,1.42-1.05,2.34c0,0.67,0.29,1.26,0.85,1.8c0.55,0.56,1.88,1.07,3.98,1.55 c5.21,1.22,8.93,2.46,11.18,3.72c2.25,1.25,3.9,2.8,4.92,4.66c1.02,1.85,1.53,3.92,1.53,6.22c0,2.69-0.68,5.18-2.05,7.46 c-1.37,2.27-3.28,4-5.74,5.17c-2.45,1.18-5.54,1.76-9.27,1.76c-6.55,0-11.1-1.38-13.62-4.13C67.44,61.06,66.01,57.57,65.68,53.33 L65.68,53.33z"
      />
    </g>
  </Icon>
);
