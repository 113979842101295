import { customTheme } from 'theme';

const colors = customTheme.colors;
export const setItemState = (name, value, item) => {
  const group = item?.getContainer(); //node-container
  const shape = group?.find(e => e.get('name') === 'node');
  const model = item?.get('model');
  const { data } = model;

  const isResourceNode = data.shape === 'square';
  let type = data?.service ?? data?.resource_type;
  if (data?.resource_type === 'Route' || data?.resource_type === 'Local') {
    type = data?.resource_type;
  }

  const { active } = data;
  // const mapType = data?.mapType;
  // const color = hasTraffic ? colorMap.primary() : getResourceColor(type);
  if (data.selected || (name === 'selected' && value)) {
    shape?.attr({
      fill: isResourceNode ? colors['aws'] : '#eddfff',
    });

    return;
  }

  if (active) {
    return;
  }

  if (name === 'hover' && value) {
    shape?.attr({
      shadowOffsetX: 0,
      shadowOffsetY: 5,
      shadowColor: '#ccc',
      shadowBlur: 5,
    });
    return;
  }

  if (name === 'hover' && !value) {
    shape?.attr({
      stroke: '#ccc',
      shadowColor: 'transparent',
    });
    return;
  }

  // if ([MapType.Access, MapType.Posture].includes(data.mapType)) {
  //   if (name === 'selected') {
  //     if (value) {
  //       shape?.attr({
  //         lineWidth: 2,
  //         cursor: 'pointer',
  //       });
  //       return;
  //     }
  //   }
  //   if (name === 'hover') {
  //     if (value) {
  //       return;
  //     }
  //   }
  //   shape?.attr({
  //     fill: 'white',
  //     lineWidth: hasTraffic ? 2 : 0.5,
  //   });
  //   return;
  // }

  // const textHoverStyles = text => ({
  //   text: text,
  //   fill: value
  //     ? color
  //     : name === 'resource_id_active'
  //     ? hasTraffic
  //       ? colorMap.primary(300)
  //       : customTheme.colors.gray['300']
  //     : 'inherit',
  //   fontWeight: value ? 800 : 400,
  // });

  /*state styles when we hover over resource name*/
  // if (name === 'resource_name_active') {
  //   const resource_name_group = group?.findPath(
  //     e => e.get('name') === 'resource_name',
  //   );
  //   let resourceName = data.name ?? '-';
  //   if (mapType === MapType.Network) {
  //     if (resourceType === 'Route' || resourceType === 'Local')
  //       resourceName = resourceType;
  //   }
  //   resource_name_group.attr(
  //     textHoverStyles(value ? resourceName : limitedString(resourceName, 16)),
  //   );
  //   hoverStyles(value);
  // }

  /*state styles when we hover over resource id
   * show full string when we hover over resource_id*/
  // if (name === 'resource_id_active') {
  //   const resource_id_group = group?.findPath(e => e.get('name') === 'resource_id');
  //   const id = getResourceId(data);
  //   resource_id_group.attr(textHoverStyles(value ? id : limitedString(id, 16)));
  //   hoverStyles(value);
  // }
};
