import React, { FC, useEffect } from 'react';

import { Box, Accordion } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { renderTime, WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { CustomIconTypes, IconTypes } from 'components/Icons/types';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { S3RiskAnalysis } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Data/ObjectStorage/S3RiskAnalysis';
import { renderActionPerformed } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/utils';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';

export const S3Permissions: FC = () => {
  const { permissions, resourceUuid, resourceAccountId } = useSelector(
    selectActiveResource,
  );
  const { data, isLoading } = permissions.insecureAcl;
  const { getPermissions } = useActiveResourceActions();

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Account ID</Box>,
      accessor: 'account_id',
      render: ({ value }) => (
        <WithResourceIcon resourceType={IconTypes.Account}>
          <Box>{value}</Box>
        </WithResourceIcon>
      ),
    },
    {
      header: 'TIMESTAMP',
      accessor: 'timestamp',
      align: 'left',
      render: renderTime,
    },
    {
      header: 'Action Performed',
      accessor: 'action',
      align: 'left',
      render: renderActionPerformed,
    },
    {
      header: 'Region',
      accessor: 'region',
      align: 'left',
    },
    {
      header: 'ACL Used',
      accessor: 'acl_used',
      align: 'left',
    },
  ];

  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      <S3RiskAnalysis
        accountId={resourceAccountId}
        resourceUuid={resourceUuid}
        isLoading={isLoading}
      />
      <MetaAccordionItem
        iconType={CustomIconTypes.Permission}
        label="INSECURE ACL USAGE DURING THE PAST 90 DAYS"
        totalCount={data?.length ?? 0}
        isLoading={isLoading}
        onClickDownload={() => {}}
        panel={<Table columns={columns} data={data} isLoading={isLoading} />}
        iconBGColor="critical"
      />
    </Accordion>
  );
};
