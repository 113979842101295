/**
 *
 * Asynchronously loads the component for Tickets
 *
 */

import { lazy } from 'react';

export const Tickets = lazy(() =>
  import('./index').then(m => ({ default: m.Tickets })),
);
