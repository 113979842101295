import {
  SearchResponse,
  SearchResponse as CoreSearchResponse,
  SearchResponseLevel,
  SourceItems,
} from '@ariksa/inventory-core';
import {
  ResourceBase,
  RiskContextResponse,
  SearchQuery,
  SearchTerm,
  SearchTermSubCategory,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import {
  AlertResponse,
  EntitySeverityListResponse,
} from '@ariksa/notification/api';
import { IQueryState } from 'services/utils/QueryState';
import { Optional } from 'types/utils';

import { SelectOption } from 'components/DataEntry';
import { NodePagination } from 'components/Visualization/PixiGraph/maps/AccessMap/hooks/useAccessMapContext';
import { ContextFilterItemEntry } from 'containers/Visibility/Components/Filter/ContextFilterItem';
import { ResourceFilterItemEntry } from 'containers/Visibility/Components/Filter/ResourceFilterItem';
import {
  NetworkTrafficOverview,
  NetworkTrafficPortInfo,
  NetworkTrafficProcessInfo,
} from '@ariksa/ebpf-data-collector';

export interface SecurityGraphNextState {
  viewType: ViewType;
  mapType: SecurityGraphMapType;

  redirectInfo: Optional<RedirectInfo>;

  isRedirected: boolean;
  isSelected: boolean;

  responseLevel: SearchResponseLevel;

  getStartedSearchOptions: IQueryState<SearchWrapper[]>;
  recentSearchOptions: IQueryState<SearchWrapper[]>;
  searchTermOptions: Array<{ label: string; value: SearchTerm }>;

  // current query is the query that is being displayed in the search bar
  currentSearchQuery: Optional<SearchQuery>;
  // prevActiveSearchQueryTerm is the search query term that was active before the search query was changed
  prevActiveSearchQueryTerm: Optional<SearchTerm>;
  // redirectedSearchQueryTerm is the current redirected search query term
  redirectedSearchQueryTerm: Optional<SearchTerm>;
  // selectedSearchQueryTerm is the search query term that is currently selected from the search bar
  selectedSearchQueryTerm: Optional<SearchTerm>;
  // activeSearchQueryTerm is the search query term that is currently active
  // when the data is fetched from the server the active search query term is set to the selected search query term
  activeSearchQueryTerm: Optional<SearchTerm>;

  sourceNodeOptions: SourceNodeOptions;
  selectedSourceNodeOption: Optional<SelectOption>;
  initialSourceNodeId: Optional<string>;
  queryChanged: boolean;
  filterChanged: boolean;

  hiddenNodeIds: Set<NodeId>;
  enrichInsights: {
    alertingEntities: boolean;
    filteringByContext: boolean;
  };
  resourceAlerts: IQueryState<EntitySeverityListResponse>;
  filters: {
    resource: ResourceFilterItemEntry[];
    context: ContextFilterItemEntry[];
    resourceType: ResourceTypeFilterItemEntry[];
  };

  sourceNodes: IQueryState<SourceItems[]>;
  compactMap: IQueryState<SearchResponse>;
  fullMap: IQueryState<SearchResponse>;
  resourceRedirectMap: IQueryState<SearchResponse>;

  evidenceMap: IQueryState<InventorySearchResponse>;
  dependencyMap: IQueryState<InventorySearchResponse>;

  table: IQueryState<SearchResponse>;
  resourceRedirectTable: IQueryState<SearchResponse>;
  activeRow: Optional<{
    source_uuid: string;
    resource_name: string;
  }>;
  evidence: {
    alertDetails: IQueryState<AlertResponse>;
    riskContext: IQueryState<RiskContextResponse[]>;
    compliance: IQueryState<string[]>;
    alertStatus: IQueryState<any>;
    evidenceAction: IQueryState<any>;
  };
  showVulnerability: boolean;
  showDependencies: boolean;
  pagination: Record<string, NodePagination>;
  mapContext: IQueryState<SearchResponse>;

  vmTrafficSummary: IQueryState<NetworkTrafficOverview>;
  trafficByPorts: IQueryState<NetworkTrafficPortInfo[]>;
  trafficByProcess: IQueryState<NetworkTrafficProcessInfo[]>;
}

export enum ViewType {
  Table = 'table',
  Map = 'map',
}

export interface RedirectInfo {
  account_id?: string;
  source_id?: string;

  query_name?: string;
  ai_search?: string;
  search_uuid?: string;

  resource_type?: string;
  source_uuid?: string;
  resource_uuid?: string;
  alert_rule_id?: string;
  map_type?: Optional<string>;
  // when identity is true map_type is determined by API response
  identity?: boolean;
  alert_id?: string;
  check_id?: string;
  source_tab?: string;
  redirect_url?: string;
  alert?: AlertResponse;
  response_level?: SearchResponseLevel;
  sub_category?: SearchTermSubCategory;

  query?: string;
}

export enum SecurityGraphMapType {
  Access = 'identity-access',
  Posture = 'posture',
  Unknown = 'unknown',
}

export type SourceNodeOptions = {
  label?: string;
  value?: { display_name?: string; native_name?: string };
  options?: {
    label?: string;
    value?: ResourceBase;
  }[];
}[];

// show/hide node by resource type filter
export interface ResourceTypeFilterItemEntry {
  resourceType: string;
}

export type InventorySearchResponse = CoreSearchResponse;

export type NodeId = string;

export type ContainerState = SecurityGraphNextState;
