import React, { FC, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import { SideNavKey } from 'components/Navigation';

import { Overview } from './components/Overview';
import { Setup } from './components/Setup';

interface IFeatureDemo {
  onClose();
}

export const FeatureDemo: FC<IFeatureDemo> = props => {
  const { onClose } = props;
  const [activeTab, setActiveTab] = useState<SideNavKey>(0);

  const handleNext = () => {
    setActiveTab(1);
  };

  const handlePrev = () => {
    setActiveTab(0);
  };

  return (
    <Flex h={window.innerHeight + 'px'} pl={10}>
      {activeTab === 0 ? (
        <Overview next={handleNext} />
      ) : (
        <Setup onClose={onClose} prev={handlePrev} />
      )}
    </Flex>
  );
};
