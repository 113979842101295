import React, { FC, useEffect, useRef, useState } from 'react';

import { ResourceMapping } from '@ariksa/inventory-core';
import { Box, Stack, HStack } from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { INIT_PAGE_INFO } from 'services/utils/constants';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import {
  StackedCell,
  StackedHeader,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { Sorted } from 'components/DataDisplay/NewTable/types';
import { DetailsField } from 'components/DataDisplay/Utils/DetailsField';
import { Select } from 'components/DataEntry';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { Drawer } from 'components/Overlay';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { MetadataTabs } from 'containers/ActiveCloudResource/types';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import {
  ResourceTypeIconTooltip,
  useResourceType,
} from 'containers/App/hooks/useResourceType';
import { AddException } from 'containers/Compliance/Components/AddException';
import {
  complianceExceptionOptions,
  complianceStatusOptions,
  renderComplianceStatus,
} from 'containers/Compliance/Components/ComplianceTables/utils';
import { History } from 'containers/Compliance/Components/History';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';
import { SeverityComponent } from 'containers/Compliance/Components/SeverityComponent';
import { useCloudAccountId } from 'containers/Setup/CloudAccounts/utils';

interface Props {
  row: Record<string, any>;
  subControl?: Record<string, any>;
  isOpen: boolean;
  onClose: () => void;
}

export const PoliciesSubComponent: FC<Props> = props => {
  const { row, isOpen, onClose, subControl } = props;
  const dispatch = useDispatch();
  const { rulesResources, selectedBlueprint } = useSelector(selectCompliance);

  const {
    renderRulesActions,
    isOpenSeverityModal,
    isOpenSuppress,
    onCloseSuppress,
    selectedResource,
    isOpenHistory,
  } = useCompliance();
  const { withAccessBoundary } = useAccessBoundary();
  const { toCloudAccountId } = useCloudAccountId();
  const [summary, setSummary] = useState<{
    data: Record<string, any>[];
    total: number;
  }>({ data: [], total: 0 });
  const [pageInfo, setPageInfo] = useState(INIT_PAGE_INFO);
  const ref = useRef(document.querySelector('.portal-container'));
  const [status, setStatus] = useState(complianceStatusOptions[0]);
  const [exception, setException] = useState(complianceExceptionOptions[0]);
  const [sortByField, setSortByField] = useState<Sorted>({
    sortField: '',
    sortOrder: '',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [resourceType, setResourceType] = useState<ResourceMapping>(
    {} as ResourceMapping,
  );

  const { getResourceType } = useResourceType();
  const { onOpenMetadata } = useActiveResourceContext();
  const { updateActiveResource } = useActiveResourceActions();
  const { getTabIndex } = useMetadataDrawerTabs();
  const [alertTabIndex, setAlertTabIndex] = useState(0);

  //get rules
  useDeepCompareEffect(() => {
    !!selectedBlueprint?.id &&
      dispatch(
        actions.getComplianceSummaryByRule({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            ruleId: row?.rule_id,
            standardId: row?.id,
            page: pageInfo.page_number,
            size: pageInfo.page_size,
            show: status?.value,
            sortBy: sortByField.sortField,
            sortOrder: sortByField.sortOrder,
            searchTerm,
          }),
          onSuccess: res => {
            setSummary({ data: res?.items || [], total: res?.total || 0 });
          },
        }),
      );
  }, [
    dispatch,
    row,
    selectedBlueprint,
    withAccessBoundary,
    pageInfo,
    status,
    searchTerm,
  ]);

  //set resource type
  useEffect(() => {
    setResourceType(getResourceType(row?.native_resource)!);
  }, [getResourceType, row]);

  //set metadata drawer alerts tab index
  useEffect(() => {
    setAlertTabIndex(
      getTabIndex(resourceType.cloud_native_class!, MetadataTabs.Alerts),
    );
  }, [getTabIndex, resourceType]);

  const columns = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Entity</Box>,
      accessor: 'name',
      align: 'left',
      sortKey: 'name',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.type}
          iconTooltip={<ResourceTypeIconTooltip resourceType={row?.type} />}
        >
          <StackedCell upper={row?.name} lower={row?.cloud_resource_id} />
        </WithResourceIcon>
      ),
    },
    {
      header: 'Type',
      accessor: 'cloud_resource_id',
      align: 'left',
      render: ({ row }) => {
        const type = getResourceType(row?.type);
        return (
          <StackedCell
            upper={type?.cloud_native_name}
            lower={type?.cloud_agnostic_name}
          />
        );
      },
    },
    {
      header: 'Deployed In',
      accessor: 'account_id',
      align: 'left',
      render: ({ row }) => (
        <StackedCell
          upper={toCloudAccountId(row?.account_id)}
          lower={row?.region}
        />
      ),
    },
    {
      header: 'Exception',
      accessor: 'check_stats.resource_exceptions',
      align: 'center',
      render: ({ value }) => (!!value ? 'Yes' : 'No'),
    },
    {
      header: 'Status',
      accessor: 'check_stats.failed',
      align: 'left',
      render: renderComplianceStatus,
    },
    {
      header: 'Actions',
      render: renderRulesActions,
      styles: { width: '150px', cell: { maxWidth: '150px' } },
    },
  ];

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        header={
          <HStack justify="space-between">
            <StackedHeader
              upper={resourceType.cloud_native_name}
              lower={resourceType?.cloud_agnostic_name}
              type={row?.native_resource}
            />
            <Box w={96} pr={10}>
              <SearchBox
                onChange={value => setSearchTerm(value)}
                value={searchTerm}
                placeholder="Search entities, names, etc..."
              />
            </Box>
          </HStack>
        }
        body={
          <Stack w="full" h="full" pt={3} spacing={5}>
            <DetailsField
              label="Description"
              value={subControl?.sub_control_description}
            />
            <HStack w="full" justify="flex-end">
              <Box w={48}>
                <Select
                  options={complianceStatusOptions}
                  value={status}
                  onChange={setStatus}
                  showIconInValueContainer
                  menuPortalTarget={document.body}
                />
              </Box>
              {/*<Box w={48}>
                <Select
                  options={complianceExceptionOptions}
                  value={exception}
                  onChange={setException}
                  showIconInValueContainer
                  menuPortalTarget={document.body}
                />
              </Box>*/}
            </HStack>
            <Table
              data={summary.data}
              columns={columns}
              cursor="pointer"
              isLoading={rulesResources.isLoading}
              pagination={{
                totalCount: summary.total,
                pageInfo,
                onChange: setPageInfo,
              }}
              onRowClick={row => {
                updateActiveResource({
                  resourceType: row?.type,
                  uuid: row.resource_id,
                  accountId: row.account_id,
                  resourceId: row.cloud_resource_id,
                  tabIndex: alertTabIndex,
                });
                onOpenMetadata();
              }}
              onSort={sortInfo => {
                if (!isEqual(sortByField, sortInfo)) {
                  setSortByField(sortInfo);
                }
              }}
              sortBy={sortByField}
            />
            {isOpenHistory && (
              <History
                ruleId={row?.rule_id}
                control={subControl?.control}
                subControl={subControl?.sub_control}
              />
            )}
            {isOpenSeverityModal && <SeverityComponent />}
            {isOpenSuppress && (
              <AddException
                resource={selectedResource}
                isOpen={isOpenSuppress}
                onClose={onCloseSuppress}
              />
            )}
          </Stack>
        }
        closeButton
        styles={{
          content: { maxW: '1400px' },
          drawer: { portalProps: { containerRef: ref as any } },
        }}
      />
    </>
  );
};
