import { useCallback } from 'react';

import { PageBluePrintsRead } from '@ariksa/compliance-policies/api';
import { useDispatch } from 'react-redux';
import { SuccessFn } from 'services/types';

import { actions } from 'containers/SharedState/slice';

interface ApiCallOptions<D> {
  onSuccess?: SuccessFn<D>;
}

export const useSharedApis = () => {
  const dispatch = useDispatch();

  const getBlueprints = useCallback(
    (props?: ApiCallOptions<PageBluePrintsRead>) => {
      dispatch(actions.getBlueprints({ q: {}, onSuccess: props?.onSuccess }));
    },
    [dispatch],
  );

  return {
    getBlueprints,
  };
};
