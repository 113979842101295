import React, { FC } from 'react';

import { VMCredentials } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/VMCredentials';
//import { useActiveResourceContext } from 'containers/ActiveCloudResource/context';

interface INetworkTab {}

export const CredentialsTab: FC<INetworkTab> = () => {
  //const { resourceType } = useActiveResourceContext();

  return <VMCredentials />;
};
