/**
 *
 * Secondary NavBar
 *
 */
import React, { useEffect } from 'react';

import { Center, Flex, Stack } from '@chakra-ui/react';
import map from 'lodash/map';
import { useLocation, useNavigate } from 'react-router-dom';

import { useNavBarContext } from 'components/Navigation/NavBar/context';
import { SecondaryNavBarRightComponent } from 'components/Navigation/NavBar/SecondaryNavBar/SecondaryNavBarRightComponent';
import {
  matchUrlBase,
  matchUrlPattern,
  sanitizeUrl,
} from 'components/Navigation/NavBar/SecondaryNavBar/utils';
import { NavBarConfigProps } from 'hooks/RouteConfig/types';

import {
  FilterComponent,
  useUserPermissionsContext,
} from '../../../FilterView';
import MenuItem from '../../MenuItem';
import { NavBarComponent } from '../NavBarComponent';

import { selectedTabStyles, tabStyles, wrapperStyle } from './styles';
interface Props {
  config?: NavBarConfigProps;
}

export const SecondaryNavBar: React.FC<Props> = props => {
  const {
    config = {
      list: [],
      base: '',
    },
  } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userPermission } = useUserPermissionsContext();
  const { showWelcomeScreen } = useNavBarContext();

  useEffect(() => {
    // if there is a sub-route redirect to that
    if (config.list && config.base === pathname) {
      const redirectTo = userPermission.firstIncludedPath(
        config.base,
        config.list.map(l => `${config.base}${l.url}`),
      );

      if (redirectTo !== pathname) {
        navigate(redirectTo, { replace: true });
      }
    }
  }, [config.base, config.list, navigate, pathname, userPermission]);

  const LeftComponent = () => {
    if (showWelcomeScreen) return;
    return (
      <Flex pl={6} ml={32} w="full" h="full">
        <Flex pr={10}>
          {config?.list &&
            map(config?.list, each => {
              const url = sanitizeUrl(`${config?.base}${each.url}`);
              const matched = matchUrlPattern(config?.base, each, pathname);

              return (
                <FilterComponent
                  filterPath={url}
                  show={matchUrlBase(config.base, pathname)}
                  key={each.url + '-secondary-nav-bar'}
                >
                  <MenuItem
                    url={url}
                    selectedStyles={selectedTabStyles}
                    selected={matched}
                    badge={each?.badge}
                    badgeLeft={each?.badgeLeft}
                    badgeTop="-43px"
                    onClick={() => {
                      localStorage.setItem(
                        config?.base + '-top-nav-bar',
                        each.url,
                      );
                    }}
                    {...tabStyles}
                  >
                    <Center h="full" p={0}>
                      {each.title}
                    </Center>
                  </MenuItem>
                </FilterComponent>
              );
            })}
        </Flex>
      </Flex>
    );
  };

  const RightComponent = () => (
    <SecondaryNavBarRightComponent.Target as={Stack} isInline />
  );

  return (
    <NavBarComponent
      leftComponent={LeftComponent}
      rightComponent={RightComponent}
      height={10}
      wrapperStyles={wrapperStyle}
    />
  );
};

export default SecondaryNavBar;
