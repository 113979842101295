import React from 'react';

import { SummaryType } from '@ariksa/inventory-core';
import { Categories } from '@ariksa/inventory-core/api';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { SummaryByResource } from 'containers/Inventory/CloudInventory/Components/Summary/SummaryByResource';

import { widgetStyles } from '../styles';

export const availableWidgets: Record<string, WidgetProps> = {
  byDataSourcesOnCloud: {
    name: 'By Data Sources on Cloud',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.ResourceType}
          category={Categories.Data}
        />
      ),
    },
  },
  byRegion: {
    name: 'By Region',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.Region}
          category={Categories.Data}
        />
      ),
    },
  },
  byDataClassification: {
    name: 'By Data Classification',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.DataClassification}
          category={Categories.Data}
        />
      ),
    },
  },
  byCloudType: {
    name: 'By Cloud Type',
    styles: { ...widgetStyles },
    components: {
      content: (
        <SummaryByResource
          summaryType={SummaryType.CloudProvider}
          category={Categories.Data}
        />
      ),
    },
  },
};
