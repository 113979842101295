import React, { useContext } from 'react';

interface PageContentContextPropsr {
  contentHeight: number;
  contentWidth: number;
}

export const PageContentContext = React.createContext<PageContentContextPropsr>(
  {
    contentHeight: 0,
    contentWidth: 0,
  },
);

export const PageContentContextProvider = PageContentContext.Provider;

export const usePageContentContext = () => useContext(PageContentContext);
