import React from 'react';

import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { colorOpacity, customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { VulnerabilityIcon } from 'components/Icons';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { OverviewCardSpacing } from 'containers/Dashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';
import { Containers } from 'containers/Dashboard/VulnerabilityOverview/Components/Containers';
import { ContainerService } from 'containers/Dashboard/VulnerabilityOverview/Components/ContainerService';
import { EndOfLifeSoftware } from 'containers/Dashboard/VulnerabilityOverview/Components/EndOfLifeSoftware';
import { InternetExposedHosts } from 'containers/Dashboard/VulnerabilityOverview/Components/InternetExposedHosts';
import { MostWidelyDeployedLibraries } from 'containers/Dashboard/VulnerabilityOverview/Components/MostWidelyDeployedLibraries';
import { NoVulnerabilityScannerDeployed } from 'containers/Dashboard/VulnerabilityOverview/Components/NoVulnerabilityScannerDeployed';
import { Serverless } from 'containers/Dashboard/VulnerabilityOverview/Components/Serverless';
import { TopNewVulnerabilities } from 'containers/Dashboard/VulnerabilityOverview/Components/TopNewVulnerabilities';
import { VirtualMachines } from 'containers/Dashboard/VulnerabilityOverview/Components/VirtualMachines';
import { VMImages } from 'containers/Dashboard/VulnerabilityOverview/Components/VMImages';
import { VulnerabilitiesPrioritization } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerabilitiesPrioritization';
import { Vulnerability } from 'containers/Dashboard/VulnerabilityOverview/Components/Vulnerability';
import { VulnerableHostsWithAccessToSensitiveData } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerableHostsWithAccessToSensitiveData';

interface Props {
  showDashboard: boolean;
  vulnerabilityScannerCount: number;
}

export const Vulnerabilities: React.FC<Props> = props => {
  const { showDashboard, vulnerabilityScannerCount } = props;
  const { vulnerabilityScannerInfo } = useSelector(selectDashboard);
  return (
    <DashboardOverviewWrapperCard
      label="VULNERABILITIES & WORKLOADS"
      titleBgColor={colorOpacity(customTheme.colors.critical, 0.2)}
      iconBgColor="critical"
      titleFontColor="critical"
      content={
        vulnerabilityScannerInfo.isLoading ? (
          <CustomSpinner size="xl" />
        ) : showDashboard ? (
          <Stack w="full" h="full" spacing={OverviewCardSpacing}>
            <Grid
              w="full"
              h="full"
              templateColumns="repeat(5, 1fr)"
              gap={OverviewCardSpacing}
            >
              <GridItem colSpan={1}>
                <Stack spacing={OverviewCardSpacing} h="full">
                  <Vulnerability
                    vulnerabilityScannerCount={vulnerabilityScannerCount}
                    isOutpostEnabled={showDashboard}
                  />
                  <VirtualMachines />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <VulnerabilitiesPrioritization />
              </GridItem>
              <GridItem colSpan={2}>
                <TopNewVulnerabilities />
              </GridItem>
            </Grid>
            <Grid
              w="full"
              h="full"
              templateColumns="repeat(5, 1fr)"
              gap={OverviewCardSpacing}
            >
              <GridItem colSpan={1}>
                <Stack spacing={OverviewCardSpacing} h="full">
                  <Containers />
                  <Serverless />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <InternetExposedHosts />
              </GridItem>

              <GridItem colSpan={2}>
                <VulnerableHostsWithAccessToSensitiveData
                  scannerDeployed={showDashboard}
                />
              </GridItem>
            </Grid>
            <Grid
              w="full"
              h="full"
              templateColumns="repeat(5, 1fr)"
              gap={OverviewCardSpacing}
            >
              <GridItem colSpan={1}>
                <Stack spacing={OverviewCardSpacing} h="full">
                  <VMImages />
                  <ContainerService />
                </Stack>
              </GridItem>
              <GridItem colSpan={2}>
                <MostWidelyDeployedLibraries />
              </GridItem>
              <GridItem colSpan={2}>
                <EndOfLifeSoftware />
              </GridItem>
            </Grid>
          </Stack>
        ) : (
          <NoVulnerabilityScannerDeployed />
        )
      }
      icon={<VulnerabilityIcon />}
    />
  );
};
