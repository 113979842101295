import React from 'react';

import { Icon } from '@chakra-ui/react';

export const SSHKeyPairIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" color="inherit" {...props}>
    <path
      d="M370.4,10c-58.2-25.9-126.7,0.3-152.7,58.6c-6,13.4-9.1,27.4-9.7,41.2c-18.1,2.4-34.6,13.7-42.5,31.5
	l-16.8,37.8c-12.2,27.3,0.2,59.4,27.5,71.6l22.4,10l-51,114.6c-0.2-0.1-34.3-15.3-34.3-15.3c-4.8-2.1-10.5,0-12.6,4.8
	c-2.1,4.8,0,10.5,4.8,12.6c0,0,34.1,15.2,34.3,15.2L120,437L86.5,422c-4.8-2.1-10.5,0-12.6,4.8c-2.1,4.8,0,10.5,4.8,12.6l42.3,18.8
	c1.3,0.6,2.6,0.8,3.9,0.8c3.7,0,7.2-2.1,8.7-5.7l82.4-185l19.2,8.6c1.5,3.8,3.3,7.5,5.4,11.1c6.2,10.2,14.8,19,25.4,25.5
	l-78.5,176.2L154,474.9c-4.8-2.1-10.5,0-12.6,4.8c-2.1,4.8,0,10.5,4.8,12.6l42.3,18.8c1.3,0.6,2.6,0.8,3.9,0.8
	c3.7,0,7.2-2.1,8.7-5.7l82.3-184.8c36.8,11.6,77.5-6.1,93.5-42.2c9.1-20.4,8.5-42.6,0.3-61.7c22.1-11.7,40.7-30.5,51.6-55
	C454.9,104.4,428.6,35.9,370.4,10z M224.8,178.3c-4.3,9.7,0,21.1,9.8,25.4c2.6,1.1,5.3,1.6,7.9,1.6c-2.4,3.5-4.6,7.2-6.4,11.2
	c-4.9,11.1-7,22.7-6.5,34.1l-44.6-19.9c-16.4-7.3-23.8-26.6-16.5-42.9l16.8-37.8c5.2-11.6,16.3-18.6,28.2-19.1c0,0,0,0,0,0
	c5.3,0,10.7,1.1,15.9,3.2l61.4,27.4c1.9,0.9,3.7,1.9,5.3,3.1c2.5,1.4,4.1,3.9,5.1,6.5c-2.8,0.2-5.7,0.5-8.4,1
	c-11.3,2.1-21.9,6.7-31.3,13.3c-0.3-7.1-4.4-13.8-11.4-16.9C240.5,164.2,229.1,168.6,224.8,178.3z M357.2,270.6
	c-12.5,27.9-45.3,40.5-73.2,28.1c-5.4-2.4-10.2-5.6-14.4-9.4c-3.2-2.9-6-6.1-8.4-9.6c-2.4-3.4-4.4-7-5.9-10.9
	c-5.5-13.5-5.7-29.1,0.7-43.4c1.9-4.2,4.2-8,6.9-11.5c0.5-0.6,1-1.1,1.5-1.7c2.5,2.3,5,4.6,7.8,6.7c5.8,4.5,12,8.2,18.3,11.4
	c3.4,1.7,6.7,3.2,10.2,4.5c0.4,0.1,0.7,0.2,1.1,0.4c1.6,3,4,5.5,7.4,7c7.8,3.5,17,0,20.5-7.9c3.5-7.8,0-17-7.9-20.5
	c-4.5-2-9.5-1.6-13.6,0.5c-1.6-0.6-3.1-1.2-4.6-1.9c-6.3-2.9-12.5-6.4-18.2-10.8c-1.3-1-2.5-2.1-3.7-3.1c7.6-3.8,16-6,24.6-6.1
	c3.7,0,7.4,0.3,11.1,1c3.7,0.7,7.4,1.9,11.1,3.4c0.2,0.1,0.5,0.2,0.7,0.3c5.6,2.5,10.6,5.9,14.9,9.8c5.8,5.3,10.3,11.8,13.4,18.8
	C363.3,239.7,363.8,255.8,357.2,270.6z M409.2,153.8c-9.1,20.4-24.6,35.9-43,45.3c-7.4-9-16.9-16.6-28.2-21.6
	c-3.4-1.5-6.9-2.7-10.4-3.7c-0.6-3.6-1.5-7.1-2.7-8.9c-5.5-9.9-14.1-18.2-25.2-23.1L237,113.9c-0.6-0.3-3.8-1.7-7.4-3.2
	c0.5-11.2,3.1-22.4,7.9-33.3c21.1-47.3,76.7-68.7,124-47.6C408.9,50.9,430.3,106.5,409.2,153.8z"
    />
  </Icon>
);
