import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CloudIcon = props => (
  <Icon viewBox="0 0 24 24" w="full" h="full" color="inherit" {...props}>
    <path
      d="M18.4257812,8.1904297C17.442749,5.9173584,15.3306885,4.3338623,12.87323,
    4.0274048c-3.8320923-0.4779053-7.3259888,2.2411499-7.803894,6.0732422c-1.7984009,
    0.4309082-3.0652466,2.0407104-3.0613403,3.8899536c0.0046387,2.2044067,1.7954102,
    3.9876709,3.9998169,3.9830322h10.999939c2.2166748,0.0029297,4.1699219-1.4557495,
    4.7964478-3.5820312C22.5836792,11.7462769,21.071106,8.9699097,18.4257812,
    8.1904297z M17.0078125,16.9735718h-11c-1.5003662,
    0.0038452-2.7716064-1.104126-2.9727173-2.5910034c-0.2214355-1.6375732,
    0.9265137-3.1445312,2.5640259-3.3660278c0.2345581-0.0324097,0.4142456-0.2247314,
    0.4306641-0.4609375c0.2249146-3.1383057,2.831665-5.5723877,
    5.9780273-5.5819702c2.4730225-0.0081787,4.694458,1.5108643,
    5.5839844,3.8183594c0.0599976,0.1518555,0.1900024,0.2650757,
    0.3486328,0.3036499c1.8008423,0.4292603,3.0704956,2.039917,
    3.0673828,3.8911743C21.0042114,15.1923218,19.2133179,16.9772339,17.0078125,16.9735718z"
    />
  </Icon>
);
