// @ts-ignore
const {
  VITE_NODE_ENV = '',
  VITE_KEYCLOAK_CLIENT = '',
  VITE_APP_VERSION = '',
  VITE_SENTRY_DSN = '',
  VITE_BUILT_AT = '',
} = import.meta.env || {};

export const NODE_ENV = VITE_NODE_ENV.toString();
export const KEYCLOAK_CLIENT = VITE_KEYCLOAK_CLIENT.toString();
export const VERSION = VITE_APP_VERSION.toString();
export const SENTRY_DSN = VITE_SENTRY_DSN.toString();
export const BUILT_AT = VITE_BUILT_AT.toString();

export function isDevEnv(): boolean {
  return NODE_ENV === 'development';
}

export function valueByEnv(prod: any, dev: any) {
  return isDevEnv() ? dev : prod;
}

export const isTestEnv = () => {
  // @ts-ignore
  return (window && window.global && window.global.__TEST__) || window.Cypress;
};
