import { useCallback, useEffect } from 'react';

import { SearchResponse, SearchResponseLevel } from '@ariksa/inventory-core';
import {
  SearchResponseNode,
  SearchTermCategory,
} from '@ariksa/inventory-core/api';
import { useDispatch, useSelector } from 'react-redux';
import { INIT_PAGE_INFO } from 'services/utils/constants';
import { Optional } from 'types/utils';

import { getIcon } from 'components/Icons/Components';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { useRedirectInfo } from 'containers/Visibility/SecurityGraphNext/hooks/useRedirectInfo';
import { useSearchTerm } from 'containers/Visibility/SecurityGraphNext/hooks/useSearchTerm';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';
import { ViewType } from 'containers/Visibility/SecurityGraphNext/types';
import { getSecurityGraphType } from 'containers/Visibility/SecurityGraphNext/utils/graph_type';
import { useSearchParams } from 'hooks/useSearchParams';

export const useCallSearchApi = () => {
  const dispatch = useDispatch();
  const { selectedSearchQueryTerm, activeSearchQueryTerm } = useSearchTerm();
  const { isRedirected, redirectInfo } = useRedirectInfo();
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const {
    responseLevel,
    selectedSourceNodeOption,
    viewType,
    evidence,
    showDependencies,
    filterChanged,
    queryChanged,
    filters,
  } = useSelector(selectSecurityGraphNext);
  const params = useSearchParams();

  const updateMapNodeContext = useCallback(
    (
      nodes: SearchResponseNode[],
      onSuccess: (nodes: SearchResponseNode[]) => void,
    ) => {
      dispatch(
        actions.getMapContext({
          q: {
            getContextRequest: { nodes },
          },
          onSuccess: res => {
            onSuccess(res?.nodes ?? []);
          },
        }),
      );
    },
    [dispatch],
  );

  const updateSelectedSourceNode = useCallback(
    (res: Optional<SearchResponse>) => {
      if (!res) return;

      let sourceId = res?.initial_source_uuid || res?.source_uuids?.[0];

      console.log('updateSelectedSourceNode', sourceId, res, filters);

      if (sourceId) {
        dispatch(actions.updateInitialSourceNodeId(sourceId));
        const node = res?.nodes?.find(n => n.uuid === sourceId);
        if (node) {
          dispatch(
            actions.updateSelectedSourceNodeOption({
              label: (node.name || node.resource_id) ?? '-',
              value: node,
              icon: getIcon(node?.native_name!),
            }),
          );
        } else {
          dispatch(actions.updateSelectedSourceNodeOption(null));
        }
      } else {
        dispatch(actions.updateSelectedSourceNodeOption(null));
      }
    },
    [dispatch, filters],
  );

  // get initial compat map
  // useEffect(() => {
  //   if (isRedirected) return;
  //   if (viewType !== ViewType.Map) return;
  //   if (responseLevel === SearchResponseLevel.Full) return;
  //   if (!selectedSearchQueryTerm) return;
  //
  //   if (!filterChanged && !queryChanged) return;
  //   dispatch(actions.resetFilterAndQueryChanged());
  //
  //   console.log('get initial compat map', selectedSearchQueryTerm?.uuid);
  //
  //   dispatch(
  //     actions.getCompactMap({
  //       q: {
  //         searchQuery: {
  //           environment_id: environmentId,
  //           source_id: params?.source_resource_uuid ?? '',
  //           uuid: selectedSearchQueryTerm?.uuid,
  //           query: selectedSearchQueryTerm?.query,
  //           category: selectedSearchQueryTerm?.category,
  //           response_level: SearchResponseLevel.Compact,
  //         },
  //       },
  //       onSuccess: res => {
  //         dispatch(actions.updateMapType(getSecurityGraphType(res)));
  //         updateMapNodeContext(res?.nodes ?? [], nodes => {
  //           dispatch(actions.updateCompatMapContext(nodes));
  //         });
  //         updateSelectedSourceNode(res);
  //       },
  //     }),
  //   );
  // }, [
  //   dispatch,
  //   environmentId,
  //   filterChanged,
  //   isRedirected,
  //   params?.source_resource_uuid,
  //   queryChanged,
  //   responseLevel,
  //   selectedSearchQueryTerm,
  //   updateMapNodeContext,
  //   updateSelectedSourceNode,
  //   viewType,
  // ]);

  // get map for selected source id
  useEffect(() => {
    if (isRedirected) return;
    if (viewType !== ViewType.Map) return;
    if (responseLevel === SearchResponseLevel.Full) return;

    if (!filterChanged && !queryChanged) return;
    dispatch(actions.resetFilterAndQueryChanged());

    dispatch(
      actions.updateInitialSourceNodeId(selectedSourceNodeOption?.value.uuid),
    );
    dispatch(
      actions.getCompactMap({
        q: {
          searchQuery: {
            uuid: selectedSearchQueryTerm?.uuid,
            query: selectedSearchQueryTerm?.query,
            category: selectedSearchQueryTerm?.category,
            environment_id: environmentId,
            response_level: SearchResponseLevel.Compact,
            source_id: params?.source_resource_uuid ?? '',
          },
        },
        onSuccess: res => {
          dispatch(actions.updateMapType(getSecurityGraphType(res)));
          updateMapNodeContext(res?.nodes ?? [], nodes => {
            dispatch(actions.updateCompatMapContext(nodes));
          });
          updateSelectedSourceNode(res);
        },
      }),
    );
  }, [
    updateSelectedSourceNode,
    dispatch,
    environmentId,
    filterChanged,
    isRedirected,
    queryChanged,
    responseLevel,
    selectedSearchQueryTerm,
    selectedSourceNodeOption?.value.uuid,
    updateMapNodeContext,
    viewType,
    params,
  ]);

  // get full map
  useEffect(() => {
    if (isRedirected) return;
    if (viewType !== ViewType.Map) return;
    if (!filterChanged && !queryChanged) {
      return;
    }

    if (selectedSearchQueryTerm && responseLevel === SearchResponseLevel.Full) {
      dispatch(
        actions.getFullMap({
          q: {
            searchQuery: {
              uuid: selectedSearchQueryTerm?.uuid,
              query: selectedSearchQueryTerm?.query,
              category: selectedSearchQueryTerm?.category,
              environment_id: environmentId,
              source_id: selectedSourceNodeOption?.value.uuid,
              response_level: SearchResponseLevel.Full,
            },
          },
          onSuccess: res => {
            // remove filter_changed param from url after fetching full map
            dispatch(actions.resetFilterAndQueryChanged());

            dispatch(actions.updateMapType(getSecurityGraphType(res)));
            updateMapNodeContext(res?.nodes ?? [], nodes => {
              dispatch(actions.updateFullMapContext(nodes));
            });
            if (!selectedSourceNodeOption?.value.uuid) {
              updateSelectedSourceNode(res);
            }
          },
        }),
      );
    }
  }, [
    dispatch,
    environmentId,
    filterChanged,
    isRedirected,
    queryChanged,
    responseLevel,
    selectedSearchQueryTerm,
    selectedSourceNodeOption?.value.uuid,
    updateMapNodeContext,
    updateSelectedSourceNode,
    viewType,
  ]);

  // get table data
  useEffect(() => {
    if (isRedirected) return;
    if (viewType !== ViewType.Table) return;
    dispatch(
      actions.getTable({
        q: {
          searchQuery: {
            query: selectedSearchQueryTerm?.query,
            uuid: selectedSearchQueryTerm?.uuid,
            category: selectedSearchQueryTerm?.category,
            environment_id: environmentId,
            response_level: SearchResponseLevel.Table,
          },
        },
      }),
    );
  }, [
    dispatch,
    accountId,
    activeSearchQueryTerm,
    environmentId,
    isRedirected,
    viewType,
    redirectInfo,
    selectedSearchQueryTerm,
  ]);

  // get redirected table
  useEffect(() => {
    if (!isRedirected) return;
    if (viewType !== ViewType.Table) return;
    dispatch(
      actions.getResourceRedirectTable({
        q: {
          mapQuery: {
            category: (redirectInfo?.map_type ?? '') as SearchTermCategory,
            account_id: [redirectInfo?.account_id ?? ''],
            source_id: redirectInfo?.source_uuid ?? '',
            resource_name: redirectInfo?.resource_type ?? '',
            response_level: SearchResponseLevel.Table,
          },
        },
        page: INIT_PAGE_INFO,
        onSuccess: res => {
          console.log('onSuccess', res);
        },
      }),
    );
  }, [dispatch, isRedirected, redirectInfo, viewType]);

  // get dependency map
  useEffect(() => {
    if (!isRedirected) return;
    if (!showDependencies) return;
    if (responseLevel === SearchResponseLevel.Full) return;

    dispatch(
      actions.getDependencyMap({
        q: {
          nativeResource: redirectInfo?.resource_type!,
          searchQuery: {
            uuid: redirectInfo?.resource_uuid!,
            check_id: redirectInfo?.alert_rule_id!,
            environment_id: environmentId,
            response_level: SearchResponseLevel.Compact,
          },
        },
        onSuccess: res => {
          updateMapNodeContext(res?.nodes ?? [], nodes => {
            dispatch(actions.updateDependencyMapContext(nodes));
          });
        },
      }),
    );
  }, [
    dispatch,
    environmentId,
    evidence.alertDetails,
    isRedirected,
    redirectInfo,
    responseLevel,
    showDependencies,
    updateMapNodeContext,
  ]);

  const getEvidenceMap = useCallback(() => {
    if (!isRedirected) return;
    if (responseLevel === SearchResponseLevel.Full) return;

    console.log('get evidence map', redirectInfo);
    dispatch(
      actions.getEvidenceMap({
        q: {
          nativeResource: redirectInfo?.resource_type!,
          searchQuery: {
            uuid: redirectInfo?.resource_uuid!,
            check_id: redirectInfo?.alert_rule_id!,
            environment_id: environmentId,
            response_level: SearchResponseLevel.Compact,
          },
        },
        onSuccess: res => {
          updateMapNodeContext(res?.nodes ?? [], nodes => {
            dispatch(actions.updateEvidenceMapContext(nodes));
          });
        },
      }),
    );
  }, [
    dispatch,
    environmentId,
    isRedirected,
    redirectInfo,
    responseLevel,
    updateMapNodeContext,
  ]);

  // get map from raw query text
  const getMapFromQuery = useCallback(() => {
    if (!isRedirected) return;
    console.log('get map from query', redirectInfo);

    dispatch(
      actions.getMapFromQuery({
        q: {
          searchQuery: {
            uuid: redirectInfo?.search_uuid,
            query: redirectInfo?.query,
            environment_id: environmentId,
            source_id: redirectInfo?.source_uuid ?? '',
            response_level:
              redirectInfo?.response_level ??
              responseLevel ??
              SearchResponseLevel.Compact,
          },
        },
        onSuccess: res => {
          dispatch(actions.updateMapType(getSecurityGraphType(res)));
          updateMapNodeContext(res?.nodes ?? [], nodes => {
            dispatch(actions.updateFullMapContext(nodes));
          });

          updateSelectedSourceNode(res);
        },
      }),
    );
  }, [
    dispatch,
    environmentId,
    isRedirected,
    redirectInfo,
    responseLevel,
    updateMapNodeContext,
    updateSelectedSourceNode,
  ]);

  // get redirected map
  useEffect(() => {
    if (!isRedirected) return;
    if (viewType !== ViewType.Map) return;

    if (!queryChanged && !filterChanged) return;
    dispatch(actions.resetFilterAndQueryChanged());

    console.log('get redirected map', redirectInfo);

    if (redirectInfo?.query) {
      return getMapFromQuery();
    }

    // redirected from alert page to map page to show evidence map
    if (redirectInfo?.alert_id) {
      return getEvidenceMap();
    }

    const account_id = redirectInfo?.account_id
      ? [redirectInfo.account_id]
      : [];

    dispatch(
      actions.getResourceRedirectMap({
        q: {
          identity: !!redirectInfo?.identity,
          mapQuery: {
            account_id,
            source_id: redirectInfo?.source_uuid ?? '',
            resource_name: redirectInfo?.resource_type ?? '',
            sub_category: redirectInfo?.sub_category ?? undefined,
            category: (redirectInfo?.map_type ?? '') as SearchTermCategory,
            response_level:
              redirectInfo?.response_level ?? SearchResponseLevel.Compact,
          },
        },
        onSuccess: res => {
          dispatch(
            actions.updateMapType(getSecurityGraphType(res?.search_response)),
          );
          updateMapNodeContext(res?.search_response.nodes ?? [], nodes => {
            dispatch(actions.updateResourceRedirectMapContext(nodes));
          });
          updateSelectedSourceNode(res?.search_response);
        },
      }),
    );
  }, [
    dispatch,
    filterChanged,
    getEvidenceMap,
    getMapFromQuery,
    isRedirected,
    queryChanged,
    redirectInfo,
    updateMapNodeContext,
    updateSelectedSourceNode,
    viewType,
  ]);
};
