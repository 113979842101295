import React from 'react';

import { WidgetProps } from 'components/Visualization/CDashboard/types';
import { CriticalOrHighVulnerabilities } from 'containers/Visibility/Vulnerabilities/Components/CriticalOrHighVulnerabilities';
import { HostsWithCriticalAndHighVulnerabilities } from 'containers/Visibility/Vulnerabilities/Components/HostsWithCriticalAndHighVulnerabilities';
import { HostsWithUnpatchedOSAndTechnology } from 'containers/Visibility/Vulnerabilities/Components/HostsWithUnpatchedOSAndTechnology';
import { MostDeployedVulnerableImages } from 'containers/Visibility/Vulnerabilities/Components/MostDeployedVulnerableImages';
import { PatchablePackages } from 'containers/Visibility/Vulnerabilities/Components/PatchablePackages';
import { VulnerabilitiesBySeverity } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilitiesBySeverity';
import { VulnerabilitiesBySources } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilitiesBySources';
import { VulnerabilitiesPrioritization } from 'containers/Visibility/Vulnerabilities/Components/VulnerabilitiesPrioritization';

const styles = { root: { p: 0 } };

export const availableWidgets1: { [key: string]: WidgetProps } = {
  vulnerabilitiesPrioritization: {
    flex: true,
    components: {
      content: <VulnerabilitiesPrioritization />,
    },
    styles,
  },
  patchablePackages: {
    flex: true,
    components: {
      content: <PatchablePackages />,
    },
    styles,
  },
  vulnerabilitiesBySeverity: {
    flex: true,
    components: {
      content: <VulnerabilitiesBySeverity />,
    },
    className: 'vulnerabilitiesBySeverity',
    styles,
  },
  vulnerabilitiesBySources: {
    flex: true,
    components: {
      content: <VulnerabilitiesBySources />,
    },
    className: 'vulnerabilitiesBySources',
    styles,
  },
  mostDeployedVulnerableImages: {
    flex: true,
    components: {
      content: <MostDeployedVulnerableImages />,
    },
    className: 'mostDeployedVulnerableImages',
    styles,
  },
  criticalAndHighVulnerabilities: {
    flex: true,
    components: {
      content: <CriticalOrHighVulnerabilities />,
    },
    className: 'criticalAndHighVulnerabilities',
    styles,
  },
  hostsWithUnpatchedOSAndTechnology: {
    flex: true,
    components: {
      content: <HostsWithUnpatchedOSAndTechnology />,
    },
    className: 'hostsWithUnpatchedOSAndTechnology',
    styles,
  },
  hostsWithCriticalOrHighVulnerabilities: {
    flex: true,
    components: {
      content: <HostsWithCriticalAndHighVulnerabilities />,
    },
    className: 'hostsWithCriticalOrHighVulnerabilities',
    styles,
  },
};
