import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  AuthToken,
  AutoMinorVersionUpgrade,
  ElastiCacheEngine,
  EncryptAtRest,
  EncryptInTransit,
  ParameterGroups,
  PreferredAZ,
  PreferredMaintenanceWindow,
  ReplicationGroupID,
  SecurityGroups,
  SnapshotRetention,
  SnapshotWindow,
  TopicARN,
  TopicStatus,
} from '../../../Components/MetadataField/MetaGridFields';

export const ElastiCacheMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const cacheNodesColumns = [
    { header: 'Type', accessor: '' },
    { header: 'Node ID', accessor: '' },
    { header: 'Endpoint', accessor: '' },
    { header: 'Source node ID', accessor: '' },
  ];
  const cacheNodes = [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields availabilityZone="None" state="None" />
      {ElastiCacheEngine(resource)}
      {PreferredAZ(resource)}
      {ParameterGroups(resource)}
      {SecurityGroups(resource)}
      {ReplicationGroupID(resource)}
      {SnapshotWindow(resource)}
      {SnapshotRetention(resource)}
      {TopicARN(resource)}
      {TopicStatus(resource)}
      {AuthToken(resource)}
      {EncryptInTransit(resource)}
      {EncryptAtRest(resource)}
      {PreferredMaintenanceWindow(resource)}
      {AutoMinorVersionUpgrade(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={cacheNodesColumns} data={cacheNodes} />}
            iconType={IconTypes.Route}
            label="Cache nodes"
            totalCount={cacheNodes?.length}
            height="300px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
