/**
 *
 * Teams Form
 *
 */

import React, { FC, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';
import { selectIntegrations } from 'containers/Setup/Integrations/selectors';

import {
  QuestionCircleIcon,
  Form,
  FormSchema,
} from '../../../../../components';
import { formStyles } from '../../styles';

import { TeamsHelp } from './TeamsHelp';

interface Props {
  handleSubmit: (data: any) => void;
}

export const TeamsForm: FC<Props> = props => {
  const { handleSubmit } = props;
  const { client } = useSelector(selectIntegrations);
  const { actionType, onCancel, currentRecord } = useIntegrationsContext();

  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const toggleIsHelpOpen = () => setIsHelpOpen(!isHelpOpen);

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
    ccard: yup.string().url('Url is not valid!'),
  });

  const ccard: FormSchema =
    actionType === 'Update'
      ? {}
      : {
          ccard: {
            type: 'text',
            htmlInputType: 'password',
            passwordShow: true,
            label: 'Connector Card',
            isRequired: true,
            isDisabled: client.isLoading,
            tooltip: 'Enter valid url',
            /*helpIcon: (
        <Box onClick={toggleIsHelpOpen} _hover={{ cursor: 'pointer' }}>
          <QuestionCircleIcon />
        </Box>
      ),*/
            isHelpOpen,
            helpComponent: <TeamsHelp onClose={() => setIsHelpOpen(false)} />,
          },
        };

  return (
    <Form
      formValidation={schemaForm}
      schema={{
        name: {
          type: 'text',
          label: 'Name',
          isRequired: true,
          defaultValue: currentRecord?.name,
          isDisabled: client.isLoading,
          tooltip: 'Only alphanumeric, hyphen, underscore and space is allowed',
        },
        team: {
          type: 'text',
          label: 'Team',
          isRequired: true,
          defaultValue: currentRecord?.teams?.team,
          isDisabled: client.isLoading,
          tooltip: 'We are not validating these against the webhook',
        },
        channel: {
          type: 'text',
          label: 'Channel',
          isRequired: true,
          defaultValue: currentRecord?.teams?.channel,
          isDisabled: client.isLoading,
          tooltip: 'We are not validating these against the webhook',
        },
        ...ccard,
      }}
      buttonOptions={{
        submit: {
          name: 'Okay',
          isLoading: client.isLoading,
          isDisabled: client.isLoading,
        },
        reset: {
          name: 'Cancel',
          isVisible: true,
          isDisabled: client.isLoading,
          onClick: onCancel,
        },
      }}
      showHelp
      styles={formStyles}
      handleSubmit={handleSubmit}
    />
  );
};
