import { CloudAccountId } from 'api/inventory/types';

import { ALERT_SERVICE } from '../service_urls';
import { withPrefixArgs } from '../utils';

import { IGetAlertByUserUrl, IGetAlertSummary } from './types';

const withArgs = withPrefixArgs(ALERT_SERVICE);

export const alertsApis = {
  getHistoricalTrend: withArgs<CloudAccountId>(`/alerts/alert-trends`),
  getTopByEntityType: withArgs<CloudAccountId>(`/alerts/top-issues`),
  getTopByAge: withArgs<CloudAccountId>(`/alerts/top-issues`),
  getHighestAge: withArgs<any>(`/alerts/alert-trends`),
  getDownloadReport: withArgs<any>(`/alerts/report/alert-report`),

  getAlertsSummary: withArgs<IGetAlertSummary>(`/alerts/summary/`),

  getAlertsByUserId: withArgs<IGetAlertByUserUrl>(`/alerts/`),

  getNetworkDownloadReport: withArgs<CloudAccountId>(`/alerts/report/network`),
};
