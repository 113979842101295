import React from 'react';

import { useSelector } from 'react-redux';

import { selectDashboard } from 'containers/Dashboard/selectors';
import { FindingsWithLineChart } from 'containers/Dashboard/utils/FindingsWithLineChart';

export const DataSecurityFindingsHistory: React.FC = () => {
  const { dataSecurityOpenFindingsHistory } = useSelector(selectDashboard);

  return (
    <FindingsWithLineChart
      history={dataSecurityOpenFindingsHistory.data}
      isLoadingChart={dataSecurityOpenFindingsHistory.isLoading}
      label="Critical and high severity findings"
    />
  );
};
