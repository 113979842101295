import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/index';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.inventory || initialState;

export const selectInventory = createSelector(
  [selectDomain],
  inventoryTableState => inventoryTableState,
);
