import React from 'react';

import { Icon } from '@chakra-ui/react';

export const CentosIcon = props => (
  <Icon viewBox="0 0 512 512" w="full" h="full" {...props}>
    <g>
      <g>
        <g>
          <path
            fill="#9CCD2A"
            d="M244.5,244.5H75.4V75.4h169.1V244.5z M102.4,217.6h115.2V102.4H102.4V217.6z"
          />
          <path
            d="M245,245H75V75h170V245z M75.9,244.1h168.2V75.9H75.9V244.1z M218,218H101.9V101.9H218V218z M102.8,217.1h114.3V102.8
				H102.8V217.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#932279"
            d="M436.6,244.5H267.5V75.4h169.1V244.5z M294.4,217.6h115.2V102.4H294.4V217.6z"
          />
          <path
            d="M437,245H267V75h170V245z M267.9,244.1h168.2V75.9H267.9V244.1z M410.1,218H294V101.9h116.1V218z M294.9,217.1h114.3
				V102.8H294.9V217.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#262577"
            d="M244.5,436.6H75.4V267.5h169.1V436.6z M102.4,409.6h115.2V294.4H102.4V409.6z"
          />
          <path
            d="M245,437H75V267h170V437z M75.9,436.1h168.2V267.9H75.9V436.1z M218,410.1H101.9V294H218V410.1z M102.8,409.2h114.3
				V294.9H102.8V409.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#EFA724"
            d="M436.6,436.6H267.5V267.5h169.1V436.6z M294.4,409.6h115.2V294.4H294.4V409.6z"
          />
          <path
            d="M437,437H267V267h170V437z M267.9,436.1h168.2V267.9H267.9V436.1z M410.1,410.1H294V294h116.1V410.1z M294.9,409.2h114.3
				V294.9H294.9V409.2z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#EFA724"
            points="256,38.7 292.7,75.4 330.8,75.4 256,0.6 136.4,120.2 217.6,201.3 217.6,163.3 174.5,120.2 			"
          />
          <path
            d="M218,202.4l-82.2-82.2L256,0l75.9,75.9h-39.3L256,39.3l-80.8,80.8l42.9,42.9V202.4z M137.1,120.2l80.1,80.1v-36.8
				l-43.3-43.3L256,38.1L292.9,75h36.8L256,1.3L137.1,120.2z"
          />
        </g>
        <g>
          <polygon
            fill="#EFA724"
            points="357.7,102.4 319.6,102.4 337.5,120.2 256,201.7 244.5,190.2 244.5,228.3 256,239.8 375.6,120.2
				"
          />
          <path
            d="M256,240.4l-11.9-11.9v-39.3L256,201l80.8-80.8l-18.3-18.3h39.3l18.3,18.3L256,240.4z M245,228.1l11,11l118.9-118.9
				l-17.4-17.4h-36.8l17.4,17.4L256,202.3l-11-11V228.1z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#932279"
            points="120.2,337.5 38.7,256 75.4,219.3 75.4,181.2 0.6,256 120.2,375.6 201.3,294.4 163.3,294.4 			"
          />
          <path
            d="M120.2,376.2L0,256l75.9-75.9v39.3L39.3,256l80.8,80.8l42.9-42.9h39.3L120.2,376.2z M1.3,256l118.9,118.9l80.1-80.1
				h-36.8l-43.3,43.3L38.1,256L75,219.1v-36.8L1.3,256z"
          />
        </g>
        <g>
          <polygon
            fill="#932279"
            points="120.2,136.4 102.4,154.3 102.4,192.4 120.2,174.5 201.7,256 190.2,267.5 228.3,267.5 239.8,256
				"
          />
          <path
            d="M228.5,267.9h-39.3L201,256l-80.8-80.8l-18.3,18.3v-39.3l18.3-18.3L240.4,256L228.5,267.9z M191.3,267h36.8l11-11
				L120.2,137.1l-17.4,17.4v36.8l17.4-17.4l82.1,82.1L191.3,267z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#9CCD2A"
            points="174.5,391.8 256,310.3 267.5,321.8 267.5,283.7 256,272.2 136.4,391.8 154.3,409.6 192.4,409.6
				"
          />
          <path
            d="M193.4,410.1h-39.3l-18.3-18.3L256,271.6l11.9,11.9v39.3L256,311l-80.8,80.8L193.4,410.1z M154.5,409.2h36.8l-17.4-17.4
				l82.1-82.1l11,11v-36.8l-11-11L137.1,391.8L154.5,409.2z"
          />
        </g>
        <g>
          <polygon
            fill="#9CCD2A"
            points="294.4,310.7 294.4,348.7 337.5,391.8 256,473.3 219.3,436.6 181.2,436.6 256,511.4 375.6,391.8
				"
          />
          <path
            d="M256,512l-75.9-75.9h39.3l36.5,36.5l80.8-80.8L294,348.9v-39.3l82.2,82.2L256,512z M182.3,437l73.7,73.7l118.9-118.9
				l-80.1-80.1v36.8l43.3,43.3L256,473.9L219.1,437H182.3z"
          />
        </g>
      </g>
      <g>
        <g>
          <polygon
            fill="#262577"
            points="310.3,256 321.8,244.5 283.7,244.5 272.2,256 391.8,375.6 409.6,357.7 409.6,319.6 391.8,337.5
				"
          />
          <path
            d="M391.8,376.2L271.6,256l11.9-11.9h39.3L311,256l80.8,80.8l18.3-18.3v39.3L391.8,376.2z M272.9,256l118.9,118.9l17.4-17.4
				v-36.8l-17.4,17.4L309.7,256l11-11h-36.8L272.9,256z"
          />
        </g>
        <g>
          <polygon
            fill="#262577"
            points="436.6,330.8 511.4,256 391.8,136.4 310.7,217.6 348.7,217.6 391.8,174.5 473.3,256 436.6,292.7
				"
          />
          <path
            d="M436.1,331.9v-39.3l36.5-36.5l-80.8-80.8L348.9,218h-39.3l82.2-82.2L512,256L436.1,331.9z M437,292.9v36.8l73.7-73.7
				L391.8,137.1l-80.1,80.1h36.8l43.3-43.3l82.1,82.1L437,292.9z"
          />
        </g>
      </g>
    </g>
  </Icon>
);
