import { ApiCallStatus } from './types';

export function onApiCall(call: ApiCallStatus) {
  call.loading = true;
  call.error = null;
  if (call.loaded !== undefined) {
    call.loaded = false;
  }
}

export function onApiCallSuccess(call: ApiCallStatus) {
  call.loading = false;
  if (call.loaded !== undefined) {
    call.loaded = true;
  }
}

export function onApiCallError(call: ApiCallStatus, error: any) {
  call.loading = false;
  call.error = error;
  if (call.loaded !== undefined) {
    call.loaded = false;
  }
}
