/**
 *
 * Email Integration
 *
 */

import React, { FC, useCallback, useEffect, useState } from 'react';

import { ClientsApiEditClientRequest } from '@ariksa/notification/api';
import { useDispatch } from 'react-redux';

import { useIntegrationsContext } from 'containers/Setup/Integrations/context';

import { actions as clientActions } from '../../slice';

import { EmailForm } from './EmailForm';

export const EditEmail: FC = () => {
  const dispatch = useDispatch();
  const [emailIDs, setEmailIDs] = useState<string[]>([]);
  const { onCancel, currentRecord } = useIntegrationsContext();

  useEffect(() => {
    setEmailIDs(currentRecord?.email?.email_group ?? []);
  }, [currentRecord]);

  const setEmails = useCallback(emails => {
    setEmailIDs(emails);
  }, []);

  const onSubmit = data => {
    const payload: ClientsApiEditClientRequest = {
      uuid: currentRecord?.uuid,
      editClientConfig: {
        name: data.name,
        status: true,
        email: {
          email_group: emailIDs,
        },
      },
    };
    dispatch(
      clientActions.updateClientById({
        q: payload,
        onSuccess: () => onCancel(),
      }),
    );
  };

  return (
    <EmailForm
      onSubmit={onSubmit}
      emailIDs={emailIDs}
      setEmailIDs={setEmails}
    />
  );
};
