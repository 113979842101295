import React, { FC, useState } from 'react';

import { ComplianceStandards } from '@ariksa/compliance-policies/api';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { selectCompliance } from 'app/containers/Compliance/selectors';
import { actions } from 'app/containers/Compliance/slice';
import { WithResourceIcon } from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { IconTypes } from 'components/Icons';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { SubControlDrawer } from 'containers/Compliance/Components/ComplianceTables/ComplianceControlTable/SubControlDrawer';
import { SubComponentContainer } from 'containers/Compliance/Components/ComplianceTables/SubComponentContainer';
import { useCompliance } from 'containers/Compliance/Components/hooks/context';

import {
  renderPassedChecksHeader,
  renderPassedFailedChecks,
  renderTableHeaderWithTooltip,
} from '../utils';

interface Props {
  control: Record<string, any>;
  rowIndex: number;
  isExpanded: boolean;
}

export const SubControlTable: FC<Props> = props => {
  const { control, rowIndex, isExpanded } = props;
  const { subControlStandardRules, selectedBlueprint } = useSelector(
    selectCompliance,
  );

  const { renderRulesActions, rulesRowIndex } = useCompliance();
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});

  const dispatch = useDispatch();
  const { withAccessBoundary } = useAccessBoundary();
  const [summary, setSummary] = useState<{
    data: Record<string, any>[];
    total: number;
  }>({ data: [], total: 0 });
  const subControl = useDisclosure();

  useDeepCompareEffect(() => {
    isExpanded &&
      dispatch(
        actions.getSubControlStandardRules({
          q: withAccessBoundary({
            blueprintId: selectedBlueprint?.id,
            level: 'sub_control',
            control: control?.control,
          }),
          onSuccess: res => {
            setSummary({ data: res?.items || [], total: res?.total || 0 });
          },
        }),
      );
  }, [dispatch, control]);

  const columns = [
    {
      header: <Box pl={10}>Sub-Control</Box>,
      accessor: 'sub_control',
      align: 'left',
      render: ({ value }) => {
        const isAWS =
          selectedBlueprint?.compliance === ComplianceStandards.CisAws14;
        return (
          <WithResourceIcon
            resourceType={
              isAWS ? IconTypes.AWSWhite : selectedBlueprint?.compliance!
            }
            bgColor={isAWS ? 'primary' : 'none'}
            iconFilled={isAWS}
            iconSize="regular"
          >
            <Box>{value}</Box>
          </WithResourceIcon>
        );
      },
      styles: { width: '220px', cell: { maxWidth: '220px' } },
    },
    {
      header: 'Description',
      accessor: 'sub_control_description',
      align: 'left',
    },
    {
      header: 'Policies',
      accessor: 'rules',
      render: ({ value }) => value.total || 0,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: 'Entities',
      accessor: 'check_stats',
      render: ({ value }) => value?.total_entities || 0,
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: renderTableHeaderWithTooltip(
        'Exceptions',
        'Total entities that have policy exceptions',
        'center',
      ),
      accessor: 'rules',
      render: ({ value }) => {
        if (value?.total && value?.selected) {
          return value?.total - value?.selected;
        } else {
          return 0;
        }
      },
      styles: { width: '60px', cell: { maxWidth: '60px' } },
    },
    {
      header: renderPassedChecksHeader,
      accessor: 'check_stats',
      render: renderPassedFailedChecks,
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Actions',
      render: renderRulesActions,
      styles: { width: '150px', cell: { maxWidth: '150px' } },
    },
  ];

  return (
    <>
      <SubComponentContainer>
        <Table
          data={summary?.data}
          columns={columns}
          cursor="pointer"
          isLoading={
            subControlStandardRules.isLoading && rowIndex === rulesRowIndex
          }
          onRowClick={row => {
            setCurrentRecord(row);
            subControl.onOpen();
          }}
        />
      </SubComponentContainer>
      {subControl.isOpen && (
        <SubControlDrawer
          subControl={currentRecord}
          isOpen={subControl.isOpen}
          onClose={subControl.onClose}
        />
      )}
    </>
  );
};
