import React, { ReactNode } from 'react';

import { Box, Center, Flex, HStack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { CustomTooltip } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { getIcon } from 'components/Icons/Components';

export interface CountCardProps {
  label: string;
  count: ReactNode;
  iconType?: string;
  icon?: ReactNode;
  iconColor?: string;
  borderColor?: string;
  onClick?();
  isLoading?: boolean;
  rightAlignNumbers?: boolean;
  tooltip?: ReactNode;
}

export const CountCard: React.FC<CountCardProps> = props => {
  const {
    iconColor,
    label,
    icon,
    iconType,
    onClick,
    count,
    isLoading = false,
    rightAlignNumbers = false,
    tooltip,
  } = props;

  const renderData = () => (
    <HStack
      px={4}
      my="auto"
      spacing={0}
      onClick={onClick}
      border="1px solid"
      borderColor={customTheme.colors.gray['200']}
      borderRadius={6}
      w="full"
      h="full"
      pr={rightAlignNumbers ? 10 : 4}
      cursor={onClick ? 'pointer' : 'inherit'}
    >
      <HStack w="80%" spacing={3}>
        <Box boxSize={6} color={iconColor ?? 'primary'}>
          <Center>{iconType ? getIcon(iconType) : icon}</Center>
        </Box>
        <Box fontSize="md">{label}</Box>
      </HStack>
      <Flex
        fontSize="lg"
        fontWeight={600}
        w="20%"
        justify={rightAlignNumbers ? 'flex-end' : 'flex-start'}
      >
        {isLoading ? <CustomSpinner /> : count}
      </Flex>
    </HStack>
  );

  return tooltip ? (
    <CustomTooltip label={tooltip} styles={{ text: { h: 'full' } }}>
      {renderData()}
    </CustomTooltip>
  ) : (
    renderData()
  );
};
