import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { VulnerabilitiesPrioritizationComponent } from 'containers/Dashboard/utils/VulnerabilitiesPrioritizationComponent';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';

export const VulnerabilitiesPrioritization: FC = () => {
  const { vulnerabilityPrioritization } = useSelector(
    selectVulnerabilityOverview,
  );

  return (
    <VulnerabilitiesPrioritizationComponent
      vulnerabilityPrioritization={vulnerabilityPrioritization}
    />
  );
};
